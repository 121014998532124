import React from "react";

const Purpose=({groupData,setGroupData})=>{
    return(
        <>
        <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
            <label>Purpose*</label>
              <div className="">
                <textarea
                  rows={3}
                  className={"form-control"}
                  name="description"
                  placeholder="Enter a purpose"
                  onChange={(e)=>setGroupData({...groupData,purpose : e.target.value})}
                  value={groupData?.purpose}
                />
                
              </div>
            </div>
          </div>
        </div>
      </div>
        </>
    )
}

export default Purpose