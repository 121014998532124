import {
  GET_RECRUITER_REGISTRATION_BEGIN,
  GET_RECRUITER_REGISTRATION_SUCCESS,
  GET_RECRUITER_REGISTRATION_FAILURE,
  GET_RECRUITER_REGISTRATION_COUNT_BEGIN,
  GET_RECRUITER_REGISTRATION_COUNT_SUCCESS,
  GET_RECRUITER_REGISTRATION_COUNT_FAILURE,
} from "../../actions/dashboard/RecruiterRegistration";

const initialState = {
  loading: true,
  countLoading: true,
  recruiterData: [],
  registrationCount: [],
};

const getRecruiterRegistrationReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECRUITER_REGISTRATION_BEGIN:
      return {
        ...state,
        loading: true,
        recruiterData: [],
      };
    case GET_RECRUITER_REGISTRATION_SUCCESS:
      return {
        ...state,
        recruiterData: payload,
        loading: false,
      };
    case GET_RECRUITER_REGISTRATION_FAILURE:
      return {
        ...state,
        recruiterData: [],
        loading: false,
      };
    case GET_RECRUITER_REGISTRATION_COUNT_BEGIN:
      return {
        ...state,
        countLoading: true,
        registrationCount: [],
      };
    case GET_RECRUITER_REGISTRATION_COUNT_SUCCESS:
      return {
        ...state,
        registrationCount: payload,
        countLoading: false,
      };
    case GET_RECRUITER_REGISTRATION_COUNT_FAILURE:
      return {
        ...state,
        registrationCount: [],
        countLoading: false,
      };
    default:
      return state;
  }
};

export default getRecruiterRegistrationReducer;
