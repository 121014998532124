import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import Footer from "./Footer";
import TopNavbar from "./TopNavBar";
import JobList from "./jobList/Index";
import { useDispatch, useSelector } from "react-redux";

const Index = () => {
  const { id, poolId, tenant, reffererid, recruiterid } = useParams();
  const dispatch = useDispatch();

  return (
    <div>
      <TopNavbar />
      <JobList />
      <Footer recruiterId={recruiterid} parent="careerPage" />
    </div>
  );
};

export default Index;
