import moment from "moment";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Pagination from "../../../../components_v3/pagination/pagination";

function Filter({
  filterKeys,
  filterInfo,
  setFilterInfo,
  sendFilter,
  resetFilter,
  setFilterCount,
}) {
  const [memberFrom, setMemberFrom] = useState(["", ""]);
  const [memberFromType, setMemberFromType] = useState("");

  const [recipientsEnabled, setRecipientsEnabled] = useState(false);
  const [eventsEnabled, setEventsEnabled] = useState(false);
  const [frequencyEnabled, setFrequencyEnabled] = useState(false);
  const [memberFromEnabled, setMemberFromEnabled] = useState(false);

  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [selectedFrequencies, setSelectedFrequencies] = useState([]);

  const [eventsData, setEventsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    setSelectedRecipients([...filterInfo?.recipients]);
    setSelectedEvents([...filterInfo?.events]);

    setSelectedFrequencies([...filterInfo?.frequency]);
    setMemberFrom([...filterInfo?.memberFrom]);
    setMemberFromType(filterInfo?.memberFromType);
    // setSelectedStatus([...props.filterInfo.selectedStatus]);
    // setSelectedCompany([...props.filterInfo.selectedCompany]);
    // setMemberFromType(props.filterInfo.memberFromType);
    // setMemberFrom([...props.filterInfo.memberFrom]);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filterKeys && filterKeys?.events && filterKeys?.events?.length > 0) {
      let events = filterKeys?.events.sort();
      const startIndex = (currentPage - 1) * limit;
      const slicedData = events.slice(startIndex, startIndex + limit);
      setEventsData([...slicedData]);
    }
  }, [currentPage]);
  useEffect(() => {}, [memberFrom, memberFromType]);
  const clearAll = () => {
    setMemberFrom(["", ""]);
    setMemberFromType("");
    setSelectedRecipients([]);
    setSelectedEvents([]);

    setSelectedFrequencies([]);
    resetFilter();
    setFilterCount(0);
    // props.resetFilter();
  };

  const toggleHandler = (checked, value, filterName) => {
    if (checked) {
      if (filterName === "recipients") {
        setSelectedRecipients([...selectedRecipients, value]);
      }
      if (filterName === "events") {
        setSelectedEvents([...selectedEvents, value]);
      }

      if (filterName === "frequency") {
        setSelectedFrequencies([...selectedFrequencies, value]);
      }
    } else {
      if (filterName === "recipients") {
        setSelectedRecipients(selectedRecipients.filter((t) => t !== value));
      }
      if (filterName === "events") {
        setSelectedEvents(selectedEvents.filter((t) => t !== value));
      }

      if (filterName === "frequency") {
        setSelectedFrequencies(selectedFrequencies.filter((t) => t !== value));
      }
    }
  };

  const handleDateChange = () => {
    let startDate = moment(new Date(memberFrom[0])).format("MM/DD/YYYY");
    let endDate = moment(new Date(memberFrom[1])).format("MM/DD/YYYY");

    if (
      memberFromType === "between" &&
      (memberFrom[0] === "" || memberFrom[1] === "")
    ) {
      if (memberFrom[0] === "" && memberFrom[1] === "") {
        toast.error(`Please select valid 'From' and 'To' dates`);
        return false;
      } else if (memberFrom[0] === "") {
        toast.error(`Please select a valid 'From' date`);
        return false;
      } else if (memberFrom[1] === "") {
        toast.error(`Please select a valid 'To' date`);
        return false;
      } else {
        toast.error(`Please select a valid date`);
        return false;
      }
    } else if (
      new Date(startDate) > new Date() &&
      (memberFromType === "after" || memberFromType === "before")
    ) {
      toast.error(`'Date' cannot be future date`);
      return false;
    } else if (new Date(startDate) > new Date()) {
      toast.error(`'From Date' cannot be future date`);
      return false;
    } else if (new Date(endDate) > new Date()) {
      toast.error(`'To Date' cannot be future date`);
      return false;
    } else if (new Date(startDate) > new Date(endDate)) {
      toast.error(`'From Date' should not be greater than 'To Date'`);
      return false;
    }
    return true;
  };
  const applyFilter = (e) => {
    e.preventDefault();
    if (handleDateChange()) {
      const filterInfo = {
        memberFromType: memberFromType,
        memberFrom: memberFrom,
        recipients: selectedRecipients,
        events: selectedEvents,

        frequency: selectedFrequencies,
      };
      setFilterInfo(filterInfo);
      sendFilter(filterInfo);
    }
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  return (
    <>
      <div className="view-panel show font-12">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={(e) => applyFilter(e)}
                type="submit"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>
                Filter /{" "}
                {selectedRecipients.length +
                  selectedEvents.length +
                  selectedFrequencies.length +
                  (memberFrom[0] || memberFrom[1] ? 1 : 0)}
              </h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear all
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setRecipientsEnabled(!recipientsEnabled)}
            >
              <i
                className={
                  recipientsEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Recipient Persona</small>
                {selectedRecipients.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {recipientsEnabled &&
              (filterKeys &&
              filterKeys?.recipients &&
              filterKeys?.recipients?.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  {filterKeys?.recipients?.map((i) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        <span>{i}</span>
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedRecipients.includes(i)}
                            onChange={(e) =>
                              toggleHandler(e.target.checked, i, "recipients")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedRecipients([])}
                    >
                      <small>Clear Recipient Persona</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div className="text-center p-3">
                    <div className="avatar avatar-lg">
                      <i className="fad fa-users-slash"></i>
                    </div>
                    <div className="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setEventsEnabled(!eventsEnabled)}
            >
              <i
                className={
                  eventsEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Template Name</small>
                {selectedEvents.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {eventsEnabled &&
              (filterKeys &&
              filterKeys?.events &&
              filterKeys?.events?.length > 0 ? (
                <div className="ml-4 pl-1 pt-1">
                  {eventsData &&
                    eventsData?.map((i) => (
                      <div className="d-flex align-items-center m-1">
                        <div>
                          <span>{i}</span>
                        </div>
                        <div className="ml-auto">
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={selectedEvents.includes(i)}
                              onChange={(e) =>
                                toggleHandler(e.target.checked, i, "events")
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedEvents([])}
                    >
                      <small>Clear Template Name</small>
                    </button>
                  </div>

                  {eventsEnabled &&
                    filterKeys &&
                    filterKeys?.events &&
                    filterKeys?.events?.length !== 0 && (
                      <Pagination
                        currentPage={currentPage}
                        totalItems={filterKeys?.events.length}
                        itemsPerPage={limit}
                        onPageChange={handlePageChange}
                        limit={limit}
                      />
                    )}
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div className="text-center p-3">
                    <div className="avatar avatar-lg">
                      <i className="fad fa-users-slash"></i>
                    </div>
                    <div className="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setFrequencyEnabled(!frequencyEnabled)}
            >
              <i
                className={
                  frequencyEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Frequency</small>
                {selectedFrequencies.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {frequencyEnabled &&
              (filterKeys &&
              filterKeys?.frequency &&
              filterKeys?.frequency?.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  {filterKeys?.frequency?.map((i) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        <span>{i}</span>
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedFrequencies.includes(i)}
                            onChange={(e) =>
                              toggleHandler(e.target.checked, i, "frequency")
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedFrequencies([])}
                    >
                      <small>Clear Frequency</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div className="text-center p-3">
                    <div className="avatar avatar-lg">
                      <i className="fad fa-users-slash"></i>
                    </div>
                    <div className="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setMemberFromEnabled(!memberFromEnabled)}
            >
              <i
                className={
                  memberFromEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Last Modified</small>
                {(memberFrom[0] || memberFrom[1] || memberFromType) && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {memberFromEnabled && (
              <div>
                <div className="d-flex mt-2">
                  <select
                    onChange={(e) => {
                      setMemberFromType(e.target.value);
                      setMemberFrom(["", ""]);
                      //   handleDateChange();
                    }}
                    className="form-select w-50 m-3"
                  >
                    <option disabled selected={!memberFromType}>
                      Select Range
                    </option>
                    <option value="after" selected={memberFromType === "after"}>
                      After
                    </option>
                    <option
                      value="before"
                      selected={memberFromType === "before"}
                    >
                      Before
                    </option>
                    <option
                      value="between"
                      selected={memberFromType === "between"}
                    >
                      Between
                    </option>
                  </select>
                  {memberFromType !== "" ? (
                    memberFromType === "after" ||
                    memberFromType === "before" ? (
                      <input
                        type="date"
                        onChange={(e) => {
                          let a = ["", ""];
                          memberFromType === "before"
                            ? (a[1] = e.target.value)
                            : (a[0] = e.target.value);
                          setMemberFrom(a);
                          //   handleDateChange();
                        }}
                        style={{ maxHeight: "41px", margin: "14px 0px" }}
                        className="form-control date"
                        value={
                          memberFromType === "before"
                            ? memberFrom[1]
                            : memberFrom[0]
                        }
                        max={new Date()}
                        required
                      />
                    ) : (
                      <>
                        <label className="mr-2 mt-2">From</label>
                        <input
                          type="date"
                          id="fromDate"
                          style={{ maxHeight: "41px", margin: "14px 0px" }}
                          className="form-control date mr-2"
                          placeholder="From Date"
                          onChange={(e) => {
                            let a = [...memberFrom];
                            a[0] = e.target.value;
                            setMemberFrom(a);
                            // handleDateChange();
                          }}
                          value={memberFrom[0]}
                          max={new Date()}
                        />
                        <label className="mr-2 mt-2">To</label>
                        <input
                          type="date"
                          id="toDate"
                          style={{ maxHeight: "41px", margin: "14px 0px" }}
                          className="form-control date"
                          placeholder="To Date"
                          onChange={(e) => {
                            let a = [...memberFrom];
                            a[1] = e.target.value;
                            setMemberFrom(a);
                            // handleDateChange();
                          }}
                          value={memberFrom[1]}
                          max={new Date()}
                        />
                      </>
                    )
                  ) : null}
                </div>
                <div className="d-flex justify-content-end py-2 px-1">
                  <button
                    className="btn btn-text"
                    onClick={() => {
                      setMemberFromType("");
                      setMemberFrom(["", ""]);
                    }}
                  >
                    <span className="font-12">
                      <small>Clear Last Modified</small>
                    </span>
                  </button>
                </div>
                <hr className="p-0" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default Filter;
