import React, {useEffect, useState} from "react";
import Modal from 'react-bootstrap/Modal';
import CandidateRtr from './CandidateRtr'
import moment from 'moment';

function Rtr(props) {

   // console.log("RTR",props)
    const [jobData, setJobData] = useState({})
    const [candidateData, setCandidateData] = useState({})
    const [fullText, setFullText] = useState({})
    const [candidateRtrPopUp, setCandidateRtrPopUp] = useState(false)
    const closeModel = () => {
        setCandidateRtrPopUp(false) 
    }

    const setRtrClick = () => {
       
        setCandidateRtrPopUp(true)
    }

    useEffect(() => {
       setJobData(props.jobData)
       setCandidateData(props.candidateData)
       setFullText(props.fullText)
       console.log(props)
    }, [props])


    return (
        <>
            {props.candidateData.rtrAcceptedDate === null ? (
                <div>
                    <div className="card card-lg card-borderless card-flat  w-100">
                        <div className="card-body text-center">
                            <div className="avatar avatar-lg">
                                <i className="fad fa-fw fa-folder-open"></i>
                            </div>
                            <div className="mt-2">
                                No Record Found
                            </div>
                        </div>
                    </div>
                </div>
            ) :
                <div className="card card-flat mb-2">
                    <div className='card-body'>            
                        <div className="d-flex align-items-center d-block-600">
                            <div className="mr-2">
                                <span>Accepted Date: </span>
                                <span>{moment(props.candidateData.rtrAcceptedDate).format("MM/DD/YYYY")}</span>
                            </div>                
                            <div className='ml-auto'>
                                <button type="button" className="btn btn-sm btn-gray" onClick={setRtrClick}>View/Download</button>
                            </div>
                        </div>
                                              
                    </div>
                    <Modal size="lg" show={candidateRtrPopUp} onHide={closeModel}>
                    <div className="bl-modal-header">
                        <h6 className="bl-modal-title">{'Approval'}</h6>
                        <button type="button" data-dismiss="modal" aria-label="Close" onClick={closeModel} className="close p-0 bl-modal-close-btn"  >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <Modal.Body>
                     <CandidateRtr JobData={jobData} FullText={fullText} CandidateData={candidateData} handleSuccess={closeModel}/>
                    </Modal.Body>
                    </Modal>
                </div>

            }
        </>
    );

}

export default Rtr;