import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const FETCH_SEND_SMS_BY_USER_BEGIN = "FETCH_SEND_SMS_BY_USER_BEGIN";
export const FETCH_SEND_SMS_BY_USER_SUCCESS = "FETCH_SEND_SMS_BY_USER_SUCCESS";
export const FETCH_SEND_SMS_BY_USER_FAILURE = "FETCH_SEND_SMS_BY_USER_FAILURE";

export const SEND_SMS_BEGIN = "SEND_SMS_BEGIN";
export const SEND_SMS_SUCCESS = "SEND_SMS_SUCCESS";
export const SEND_SMS_FAILURE = "SEND_SMS_FAILURE";

export const CREATE_SMS_TEMP_BEGIN = "CREATE_SMS_TEMP_BEGIN";
export const CREATE_SMS_TEMP_SUCCESS = "CREATE_SMS_TEMP_SUCCESS";
export const CREATE_SMS_TEMP_FAILURE = "CREATE_SMS_TEMP_FAILURE";

export const CREATE_EMAIL_TEMP_BEGIN = "CREATE_EMAIL_TEMP_BEGIN";
export const CREATE_EMAIL_TEMP_SUCCESS = "CREATE_EMAIL_TEMP_SUCCESS";
export const CREATE_EMAIL_TEMP_FAILURE = "CREATE_EMAIL_TEMP_FAILURE";

export const FETCH_SEND_EMAILS_BY_USER_BEGIN =
  "FETCH_SEND_EMAILS_BY_USER_BEGIN";
export const FETCH_SEND_EMAILS_BY_USER_SUCCESS =
  "FETCH_SEND_EMAILS_BY_USER_SUCCESS";
export const FETCH_SEND_EMAILS_BY_USER_FAILURE =
  "FETCH_SEND_EMAILS_BY_USER_FAILURE";

export const FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_BEGIN =
  "FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_BEGIN";
export const FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_SUCCESS =
  "FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_SUCCESS";
export const FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_FAILURE =
  "FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_FAILURE";

export const FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_BEGIN =
  "FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_BEGIN";
export const FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_SUCCESS =
  "FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_SUCCESS";
export const FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_FAILURE =
  "FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_FAILURE";

export const GET_RECIPIENTS_LIST_SUCCESS = "GET_RECIPIENTS_LIST_SUCCESS";
export const GET_RECIPIENTS_LIST_BEGIN = "GET_RECIPIENTS_LIST_BEGIN";
export const GET_RECIPIENTS_LIST_FAILURE = "GET_RECIPIENTS_FAILURE";

export const WHATSAPP_TEMPLATES_BEGIN = "WHATSAPP_TEMPLATES_BEGIN";
export const WHATSAPP_TEMPLATES_SUCCESS = "WHATSAPP_TEMPLATES_SUCCESS";
export const WHATSAPP_TEMPLATES_FAILURE = "WHATSAPP_TEMPLATES_FAILURE";

export const SEND_WHATSAPP_MESSAGE_BEGIN = "SEND_WHATSAPP_MESSAGE_BEGIN";
export const SEND_WHATSAPP_MESSAGE_SUCCESS = "SEND_WHATSAPP_MESSAGE_SUCCESS";
export const SEND_WHATSAPP_MESSAGE_FAILURE = "SEND_WHATSAPP_MESSAGE_FAILURE";

export const WHATSAPP_RECENT_MESSAGES_BEGIN = "WHATSAPP_RECENT_MESSAGES_BEGIN";
export const WHATSAPP_RECENT_MESSAGES_SUCCESS =
  "WHATSAPP_RECENT_MESSAGES_SUCCESS";
export const WHATSAPP_RECENT_MESSAGES_FAILURE =
  "WHATSAPP_RECENT_MESSAGES_FAILURE";

export const GET_WHATSAPP_MESSAGES_BEGIN = "GET_WHATSAPP_MESSAGES_BEGIN";
export const GET_WHATSAPP_MESSAGES_SUCCESS = "GET_WHATSAPP_MESSAGES_SUCCESS";
export const GET_WHATSAPP_MESSAGES_FAILURE = "GET_WHATSAPP_MESSAGES_FAILURE";

export const WHATSAPP_MESSAGE_COUNT_BEGIN = "WHATSAPP_MESSAGE_COUNT_BEGIN";
export const WHATSAPP_MESSAGE_COUNT_SUCCESS = "WHATSAPP_MESSAGE_COUNT_SUCCESS";
export const WHATSAPP_MESSAGE_COUNT_FAILURE = "WHATSAPP_MESSAGE_COUNT_FAILURE";

export const FETCH_LAST_SMS_BY_USER_BEGIN = "FETCH_LAST_SMS_BY_USER_BEGIN";
export const FETCH_LAST_SMS_BY_USER_SUCCESS = "FETCH_LAST_SMS_BY_USER_SUCCESS";
export const FETCH_LAST_SMS_BY_USER_FAILURE = "FETCH_LAST_SMS_BY_USER_FAILURE";

export const FETCH_LAST_MAIL_BY_USER_BEGIN = "FETCH_LAST_MAIL_BY_USER_BEGIN";
export const FETCH_LAST_MAIL_BY_USER_SUCCESS =
  "FETCH_LAST_MAIL_BY_USER_SUCCESS";
export const FETCH_LAST_MAIL_BY_USER_FAILURE =
  "FETCH_LAST_MAIL_BY_USER_FAILURE";
export const UPDATE_MESSAGE_READ_STATUS_BEGIN =
  "UPDATE_MESSAGE_READ_STATUS_BEGIN";
export const UPDATE_MESSAGE_READ_STATUS_SUCCESS =
  "UPDATE_MESSAGE_READ_STATUS_SUCCESS";
export const UPDATE_MESSAGE_READ_STATUS_FAILURE =
  "UPDATE_MESSAGE_READ_STATUS_FAILURE";
export const FetchSendSmsByUser = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_SEND_SMS_BY_USER_BEGIN,
    });
    const res = await post("/smsbroadcast/fetchSendSms", inputData);
    if (res.status === 200) {
      dispatch({
        type: FETCH_SEND_SMS_BY_USER_SUCCESS,
        payload: res.data,
      });
    }
    console.log("@24 :", res.data);
  } catch (error) {
    dispatch({
      type: FETCH_SEND_SMS_BY_USER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const SendSms = (inputData, isFrom) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_SMS_BEGIN,
    });
    const res = await post("/smsbroadcast/sendSms", inputData);
    if (res.status === 200) {
      dispatch({
        type: SEND_SMS_SUCCESS,
        payload: res.data,
      });
      if (isFrom !== "chatbox") {
        toast.success("SMS sent successfully!");
      }
    }
  } catch (error) {
    dispatch({
      type: SEND_SMS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const CreateSmsTemplate = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SMS_TEMP_BEGIN,
    });
    const res = await post("/smsbroadcast/createSmsTemplateByUser", inputData);
    if (res.status === 200) {
      dispatch({
        type: CREATE_SMS_TEMP_SUCCESS,
        payload: res.data,
      });
      toast.success("SMS template created successfully!");
    }
  } catch (error) {
    dispatch({
      type: CREATE_SMS_TEMP_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const FetchSendEmailsByUser = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_SEND_EMAILS_BY_USER_BEGIN,
    });
    const res = await post("/broadCast/getSentMail", inputData);
    if (res.status === 200) {
      dispatch({
        type: FETCH_SEND_EMAILS_BY_USER_SUCCESS,
        payload: res.data,
      });
    }
    console.log("@24 :", res.data);
  } catch (error) {
    dispatch({
      type: FETCH_SEND_EMAILS_BY_USER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const CreateEmailTemplate = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_SMS_TEMP_BEGIN,
    });
    const res = await post("/broadCast/createEmailTemplateByUser", inputData);
    if (res.status === 200) {
      dispatch({
        type: CREATE_SMS_TEMP_SUCCESS,
        payload: res.data,
      });
      toast.success("Email template created successfully!");
    }
  } catch (error) {
    dispatch({
      type: CREATE_SMS_TEMP_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const FetchEmailsByUserToCandidate = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_BEGIN,
    });
    const res = await post(
      "/broadCast/fetchSendEmailByUserToCandidate",
      inputData
    );
    if (res.status === 200) {
      dispatch({
        type: FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_SUCCESS,
        payload: res.data,
      });
    }
    console.log("@24 :", res.data);
  } catch (error) {
    dispatch({
      type: FETCH_SEND_EMAILS_BY_USER_TO_CANDIDATE_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const FetchSmsByUserToCandidate = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_BEGIN,
    });
    const res = await post(
      "/smsbroadcast/fetchUserAndCandidateChatHistory",
      inputData
    );
    if (res.status === 200) {
      dispatch({
        type: FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_SEND_SMS_BY_USER_TO_CANDIDATE_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const FetchLastMessageSentByUser = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_LAST_SMS_BY_USER_BEGIN,
    });
    const res = await post("/smsbroadcast/lastMessageSentByUser", inputData);
    if (res.status === 200) {
      dispatch({
        type: FETCH_LAST_SMS_BY_USER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_LAST_SMS_BY_USER_FAILURE,
      payload: error?.response?.data,
    });
  }
};
export const UpdateMessageReadStatus = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_MESSAGE_READ_STATUS_BEGIN,
    });
    const res = await post("/smsbroadcast/trueSmsReadStatus", inputData);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_MESSAGE_READ_STATUS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_MESSAGE_READ_STATUS_FAILURE,
      payload: error?.response?.data,
    });
  }
};
export const FetchLastMailSentByUser = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_LAST_MAIL_BY_USER_BEGIN,
    });
    const res = await post("/communication/lastMailSentByUser", inputData);
    if (res.status === 200) {
      dispatch({
        type: FETCH_LAST_MAIL_BY_USER_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_LAST_MAIL_BY_USER_FAILURE,
      payload: error?.response?.data,
    });
  }
};

export const getRecipientsList = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECIPIENTS_LIST_BEGIN,
    });
    const res = await post("/broadCast/getRecipients", inputData);
    if (res.status === 200) {
      dispatch({
        type: GET_RECIPIENTS_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RECIPIENTS_LIST_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getWhatsAppTemplates = () => async (dispatch) => {
  try {
    dispatch({
      type: WHATSAPP_TEMPLATES_BEGIN,
    });
    const res = await get("/whatsapp/getCandidateTemplates");
    if (res.status === 200) {
      dispatch({
        type: WHATSAPP_TEMPLATES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: WHATSAPP_TEMPLATES_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const sendWhatsAppMessage = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_WHATSAPP_MESSAGE_BEGIN,
    });
    const res = await post("/whatsapp/sent", inputData);
    if (res.status === 200) {
      dispatch({
        type: SEND_WHATSAPP_MESSAGE_SUCCESS,
        payload: res.data,
      });
      toast.success("Message sent Successfully.");
    }
  } catch (error) {
    dispatch({
      type: SEND_WHATSAPP_MESSAGE_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getRecentWhatsAppMessages = () => async (dispatch) => {
  try {
    dispatch({
      type: WHATSAPP_RECENT_MESSAGES_BEGIN,
    });
    const res = await get("/whatsapp/getRecentMessages");
    if (res.status === 200) {
      dispatch({
        type: WHATSAPP_RECENT_MESSAGES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: WHATSAPP_RECENT_MESSAGES_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getWhatsAppMessages = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: GET_WHATSAPP_MESSAGES_BEGIN,
    });
    const res = await post("/whatsapp/getSentMessages", inputData);
    if (res.status === 200) {
      dispatch({
        type: GET_WHATSAPP_MESSAGES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_WHATSAPP_MESSAGES_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getMessageCount = () => async (dispatch) => {
  try {
    dispatch({
      type: WHATSAPP_MESSAGE_COUNT_BEGIN,
    });
    const res = await get("/whatsapp/getMessageCount");
    if (res.status === 200) {
      dispatch({
        type: WHATSAPP_MESSAGE_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: WHATSAPP_MESSAGE_COUNT_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
