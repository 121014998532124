import React, { useState, useEffect } from "react";
import moment from "moment";

const TemplatePagination = ({
  publicTemplates,
  totalCount,
  userCount,
  data,
  pageLimit,
  dataLimit,
  searchValue,
  searchData,
  openSidePanel,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    // return data.slice(startIndex, endIndex);
    return searchValue
      ? searchData
          .filter((i) =>
            publicTemplates
              ? i.createdBy == 1
              : i.createdBy != 1
          )
          .slice(startIndex, endIndex)
      : data
          .filter((i) =>
            publicTemplates
              ? i.createdBy == 1
              : i.createdBy != 1
          )
          .slice(startIndex, endIndex);
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(data.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(data.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    setCurrentPage(1);
  }, [dataLimit, searchData]);

  useEffect(() => {
    setPaginationArray(
      new Array(Math.ceil(data.length / dataLimit)).fill().map((_, i) => i + 1)
    );
    setPaginationGroup(
      new Array(Math.ceil(data.length / dataLimit))
        .fill()
        .map((_, i) => i + 1)
        .slice(0, pageLimit)
    );
  }, [data]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    } else {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  const splitMatchedText = (str) => {
    const regExp = new RegExp(searchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  return (
    <>
      <div className="px-3 pt-0 table-cards-1024">
        <table className="table table-hoverable-cells table-cards-1024">
          <thead>
            <tr>
              <th scope="col">Job Title</th>
              <th scope="col">Job Type</th>
              <th scope="col">Template Name</th>
              <th scope="col">Category</th>
              <th scope="col">Created On</th>
              <th scope="col">Last Used</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          {getPaginatedData().map((item) => (
            <tbody>
              <tr
                onClick={() => {
                  openSidePanel(item);
                }}
              >
                <td>{item.jobTitle}</td>
                <td>{item.jobType}</td>
                <td>{item.templateName}</td>
                <td>{item.category}</td>
                <td>{moment(item.createdDate).format("MM/DD/YYYY")}</td>
                <td>
                  {item.lastUsed
                    ? moment(item.createdDateTime).format("MM/DD/YYYY")
                    : "Not yet used"}
                </td>
                <td>
                  <span class={item.status==='Active'?"tag tag-green1":"tag tag-red1"}>{item.status}</span>
                </td>
              </tr>
            </tbody>
          ))}
        </table>
      </div>
      {getPaginatedData().length === 0 && (
        <div>
          <div class="text-center p-3">
            <div class="avatar avatar-lg">
              <i class="fad fa-users-slash"></i>
            </div>
            <div class="mt-2">
              No records found {searchValue && "for " + searchValue}
            </div>
          </div>
        </div>
      )}
      <hr />
      <div className="d-flex p-2">
        <div className="w-100-370 mb-point5-370">
          {/* Showing { currentPage === paginationArray.slice(-1)[0] ? 
                        getPaginatedData().length : 
                    (searchValue ? 
                    (searchData.length < dataLimit ? searchData.length : dataLimit) : (data.length < dataLimit ? data.length : dataLimit))} {" "}
                    of {totalCount} */}
          Showing{" "}
          {(getPaginatedData().length < dataLimit
            ? getPaginatedData().length
            : dataLimit) + " "}
          of {publicTemplates ? totalCount : userCount}
        </div>
        <div class="ml-auto mx-auto-370 d-flex align-items-center">
          <button
            onClick={() => {
              setPageType("down");
              setCurrentPage((page) => page - 1);
            }}
            type="button"
            disabled={
              currentPage === 1 || getPaginatedData().length === 0
                ? true
                : false
            }
            className="btn btn-icon px-2"
          >
            <i className="fal fa-chevron-circle-left"></i>
          </button>

          {paginationGroup.map((item) => (
            <button
              type="button"
              className={
                currentPage === item
                  ? "btn btn-text px-2 bg-secondary text-white"
                  : "btn btn-text px-2"
              }
              onClick={changePage}
            >
              {item}
            </button>
          ))}

          <button
            onClick={() => {
              setPageType("up");
              setCurrentPage((page) => page + 1);
            }}
            type="button"
            disabled={
              currentPage === paginationArray.slice(-1)[0] ||
              getPaginatedData().length === 0
                ? true
                : false
            }
            className="btn btn-icon px-2"
          >
            <i className="fal fa-chevron-circle-right"></i>
          </button>
        </div>
      </div>
    </>
  );
};

export default TemplatePagination;
