import React, { useState, useEffect } from "react";
import TopNavBar from "../../layout/TopNavBar";
import SideNavBar from "../../layout/SideNavBar";
import Overview from "./Overview";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";

function Index() {
  const [toggle, setToggle] = useState(true);

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };

  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar isFrom={"SAEAnew"} handleToggale={toggleDiv} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm ">
            <div class=" ">
              <div class="row justify-content-center">
                <Overview />
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
}
export default Index;
