import React, { useEffect, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";

const FilterJobs = (props) => {
  let dataLimit = 4;
  let pageLimit = 5;
  const [requestTitleEnabled, setRequestTitleEnabled] = useState(false);
  const [requestTypeEnabled, setRequestTypeEnabled] = useState(false);
  const [daysLeftEnabled, setDaysLeftEnabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [daysLeft, setDaysLeft] = useState("");
  const [daysLeftValue, setDaysLeftValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);

  useEffect(() => {
    console.log(props);
    setRequestTitles(props.requestTitles);
    setRequestTypes(props.requestTypes);
    setSelectedTitles(props.selectedTitles);
    setSelectedTypes(props.selectedTypes);
    setDaysLeft(props.daysLeft);
    setDaysLeftValue(props.daysLeftValue);
  }, []);

  const titleHandler = (checked, title) => {
    if (checked) {
      setSelectedTitles([...selectedTitles, title]);
    } else {
      setSelectedTitles(selectedTitles.filter((t) => t !== title));
    }
  };

  const typeHandler = (checked, type) => {
    if (checked) {
      setSelectedTypes([...selectedTypes, type]);
    } else {
      setSelectedTypes(selectedTypes.filter((t) => t !== type));
    }
  };

  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : requestTitles.slice(startIndex, endIndex);
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(requestTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(requestTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    setCurrentPage(1);
  }, [requestTitles, searchData]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  const splitMatchedText = (str) => {
    const regExp = new RegExp(searchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchData(
      requestTitles.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const clearAll = () => {
    setSelectedTypes([]);
    setSelectedTitles([]);
    setDaysLeftValue("");
    setDaysLeft("");
  };

  const titleArray = (title) => {
    return selectedTitles.length > 0 ? selectedTitles.includes(title) : true;
  };

  const typeArray = (type) => {
    return selectedTypes.length > 0 ? selectedTypes.includes(type) : true;
  };

  const daysLeftArray = (title, day) => {
    // let days = Number(
    //   moment(new Date(day), "YYYYMMDD")
    //     .fromNow()
    //     .match(/\d+/g)
    // );

    let days = Number(moment(day).diff(new Date(), "days"));

    if (daysLeftValue !== "") {
      let value = +daysLeftValue;

      console.log(title, "days", days, "value", value, days < value, day);
      if (days > 0) {
        console.log(daysLeft);
        switch (daysLeft) {
          case "equals":
            return days === value;
          case "lessthan":
            return days < value;
          case "greaterthan":
            return days > value;
          case "lessthanequalto":
            return days <= value;
          default:
            return days >= value;
        }
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const applyFilter = () => {
    const filteredData = props.jobData.filter((job) => {
      return (
        titleArray(job.jobTitle) &&
        typeArray(job.jobType) &&
        daysLeftArray(job.jobTitle, job.expiresOn)
      );
    });
    props.applyFilter(
      filteredData,
      selectedTitles,
      selectedTypes,
      daysLeft,
      daysLeftValue
    );
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={applyFilter}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>
                Filter /{" "}
                {selectedTitles.length +
                  selectedTypes.length +
                  (daysLeftValue ? 1 : 0)}
              </h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear All
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() =>
                requestTitleEnabled
                  ? setRequestTitleEnabled(false)
                  : setRequestTitleEnabled(true)
              }
            >
              <i
                className={
                  requestTitleEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Job Title</small>
                {selectedTitles.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTitleEnabled &&
              (requestTitles.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  {getPaginatedData().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedTitles.includes(j)}
                            className="form-check-input"
                            onChange={(e) => titleHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No records found for `'${searchValue}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage === paginationArray.slice(-1)[0]
                        ? getPaginatedData().length
                        : searchValue
                        ? searchData.length < dataLimit
                          ? searchData.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {requestTitles.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === 1 || getPaginatedData().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage === item
                              ? "btn btn-text px-2 bg-secondary text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === paginationArray.slice(-1)[0] ||
                          getPaginatedData().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue("");
                        setSelectedTitles([]);
                      }}
                    >
                      <small>Clear Job Title</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              ))}
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setRequestTypeEnabled(!requestTypeEnabled)}
            >
              <i
                className={
                  requestTypeEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Job Type</small>
                {selectedTypes.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTypeEnabled &&
              (requestTypes.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  {requestTypes.map((i) => (
                    <div className="d-flex align-items-center m-1">
                      <div>{i}</div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedTypes.includes(i)}
                            onChange={(e) => typeHandler(e.target.checked, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedTypes([])}
                    >
                      <small>Clear Job Type</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              ))}
          </div>
          <div className="mb-2">
            <button
              type="button"
              class="btn btn-collapsible"
              onClick={() =>
                daysLeftEnabled
                  ? setDaysLeftEnabled(false)
                  : setDaysLeftEnabled(true)
              }
            >
              <i
                className={
                  daysLeftEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Days Left To Fill</small>
                {daysLeftValue !== "" && <span className="jewel"></span>}
              </span>
            </button>
            {daysLeftEnabled && (
              <div className="mb-4 ml-4 pl-1 pt-2">
                <div className="d-flex">
                  <select
                    className="form-select w-50 mx-1"
                    onChange={(e) => setDaysLeft(e.target.value)}
                  >
                    <option selected={daysLeft === ""} disabled>
                      Condition
                    </option>
                    <option selected={daysLeft === "equals"} value="equals">
                      Equals
                    </option>
                    <option selected={daysLeft === "lessthan"} value="lessthan">
                      Less Than
                    </option>
                    <option
                      selected={daysLeft === "greaterthan"}
                      value="greaterthan"
                    >
                      Greater Than
                    </option>
                    <option
                      selected={daysLeft === "lessthanequalto"}
                      value="lessthanequalto"
                    >
                      Less than Or Equal To
                    </option>
                    <option
                      selected={daysLeft === "greaterthanequalto"}
                      value="greaterthanequalto"
                    >
                      Greater than Or Equal To
                    </option>
                  </select>
                  <input
                    type="number"
                    disabled={!daysLeft}
                    min="1"
                    value={daysLeftValue}
                    placeholder={"Value"}
                    class="form-control w-50 mx-1"
                    onChange={(e) => {
                      if (+e.target.value < 0) {
                        toast.error("Negative numbers are not allowed");
                      } else if (+e.target.value > 60) {
                        toast.error("Maximum allowed value is 60");
                      } else {
                        setDaysLeftValue(Math.round(+e.target.value));
                      }
                    }}
                  />
                </div>
                <div className="d-flex justify-content-end mt-2 px-1">
                  <button
                    className="btn btn-text"
                    onClick={() => {
                      setDaysLeftValue("");
                      setDaysLeft("");
                    }}
                  >
                    <small>Clear Days Left To Fill</small>
                  </button>
                </div>
                <hr className="p-0" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterJobs;
