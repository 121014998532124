import React, { useState } from "react";
import Vetting from "../vettingplans/index";
import { Modal } from "react-bootstrap";
import ShortUniqueId from "short-unique-id";
import { post } from "../../../../../service/ApiService";
import { toast } from "react-toastify";
import {
  getTenantType,
  getUserTenant,
  getUserTenantID,
} from "../../../../../service/AuthService";
import Loader from "../../../commoncomponents/Loader/Loader";
import { getPoolDispositionDetails } from "../../../../../actions/directsourcing/Disposition";
import { useDispatch } from "react-redux";

const VettingInviteModal = ({
  jobData,
  showVettingModal,
  setShowVettingModal,
  selectedTalents,
  setSelectedTalents,
  view,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const uid = new ShortUniqueId({ length: 10 });
  const [vettingData, setVettingData] = useState({});
  const [addJobData, setAddJobData] = useState({
    experienceLevel: jobData.experienceLevel,
    primarySkills: jobData?.primarySkills
      ? JSON.parse(jobData?.primarySkills)
      : [],
    vettingDetails: jobData?.vettingDetails,
    jobTitle: jobData?.jobTitle,
  });
  const [createVettingFrom, setCreateVettingFrom] = useState("");
  const [questionList, setQuestionList] = useState([]);
  const difficultyData = [
    { exp: "Intern", val: "Simple" },
    { exp: "Less than one year", val: "Simple" },
    { exp: "1-3 years", val: "Medium" },
    { exp: "3-5 years", val: "Medium" },
    { exp: "5-10 years", val: "Advanced" },
    { exp: "10+ years", val: "Advanced" },
  ];

  const createVetting = async () => {
    if (questionList?.length > 0) {
      let difficulty = "";
      difficultyData.forEach((obj) => {
        if (obj.exp === addJobData.experienceLevel) difficulty = obj.val;
      });
      const tempQuestion = questionList?.map((e) => {
        delete e?._id;
        return e;
      });
      const assessmentType = tempQuestion?.filter((e) => e?.type === "MCQ")
        ?.length;
      let skillsObj = {
        Skills: Object.keys(vettingData).length
        ? Array.isArray(vettingData?.skills)
          ? vettingData?.skills
          : vettingData?.skills?.split(",").map((item) => item?.trim())
        : addJobData?.primarySkills?.flat(1),
        Duration: tempQuestion?.length*2,
        Difficulty: difficulty,
        AssessmentType:
          assessmentType === tempQuestion.length ? "MCQ" : "General",
        JobTitle: `${addJobData.jobTitle}_high5_${uid()}`,
        PassingScore: 45,
        createdFrom: "talentPool",
        questions: tempQuestion,
      };
      setIsLoading(true);
      const vetRes = addJobData.vettingDetails
        ? ""
        : await post(
            "/vetIntegration/createAssessmentWithQuestions",
            skillsObj
          );

      const payload = {
        poolId: jobData?.poolId,
        vettingDetails: JSON.stringify(vetRes?.data),
      };
      let date = new Date();
      const vettingDetails = vetRes?.data;
      const payloadFilter = {
        currentPage: 1,
        limit: 15,
        status: view,
        poolId: jobData?.poolId,
        filterObject: "",
      };
      await post("/talent/updateTalentPooVetting", payload).then(
        async (res) => {
          await post(
            "/bulkTalentAction/getPoolDispositionFilterList",
            payloadFilter
          ).then(async (res) => {
            let data = Array.isArray(res.data) ? res.data : [];
            let paramObj = data?.map((item) => ({
              isPoolCandidate: false,
              tenantName: getUserTenant(),
              jobTitle: jobData.jobTitle,
              candidateInfo: {
                firstName: item.firstName,
                lastName: item.lastName,
                email: item.email,
                phone: item.homePhone,
                newSletter: item.newSletter,
              },
              reviewerFirstName: jobData?.reviewerFirstName,
              reviewerLastName: jobData?.reviewerLastName,
              reviewerEmail: jobData?.reviewerEmail,
              poolowner: jobData?.poolowner,
              expiryDate: date.setDate(date.getDate() + 7),
              createdFrom: "talentPool",
              testAssign: vettingDetails._id,
              vettingDetails: vettingDetails,
              uniqueCode: uid(),
              testStatus: "Invited",
              recruiterId: item?.recruiterId,
              candidateId: item?.userId,
              jobId: jobData?.poolId,
              fk_tenant: getUserTenantID(),
              companyName: getUserTenant(),
            }));
            if (paramObj?.length > 0) {
              await post("/vetIntegration/candidate/bulkInvite", paramObj).then(
                (resp) => {
                  setShowVettingModal(false);
                  toast.success("Vetting created successfully!");
                  setIsLoading(false);
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }
              );
            }
          });
        }
      );
    } else {
      toast.error("Add Questions");
    }
  };
  return (
    <>
      {isLoading && <Loader />}
      <Modal show={showVettingModal} size="lg">
        <div className="modal-header" id="NewCandidateModal">
          <h6>
            Create Assessment{" "}
            {/* <i
              className="fa fa-info-circle mr-2 ml-2"
              style={{ color: "grey" }}
            ></i> */}
          </h6>

          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowVettingModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <Vetting
            addJobData={addJobData}
            questionList={questionList}
            setQuestionList={setQuestionList}
            setVettingData={setVettingData}
          />
          <hr />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            data-dismiss="modal"
            onClick={() => setShowVettingModal(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-primary "
            onClick={() => createVetting()}
          >
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VettingInviteModal;
