import React, { useState } from "react";
import { getInitial, dataDiff } from "../../function/CommonFunction";
import vettedIcon from '../../../images/high5-mark-circle-dark.svg'
import Avatar from "react-avatar";
import moment from "moment";

function SourceCandidateTableView(props) {

    const handleSource = (e, candidate) => {
        props.setSourceTalentButton(true)
        props.setSouceCandidateData(candidate)
    }

    return (
        <>
            <div class="row d-block-force d-none-1200">
                <div class="col">
                    <table class="table table-sortable  table-hoverable">
                        <tbody>
                            {
                                props.arrayList.length !== 0 ? (
                                    props.arrayList.map((candidate, i) => (
                                        <tr>
                                            <td class="p-relative" style={{ "width": "50px" }}>
                                                <div>
                                                    {
                                                        (candidate.MCQ === false || candidate.onewayvetting === true || candidate.twowayvetting === true || candidate.codesignalvetting === true) && (
                                                            <div class="tag tag-coral tag-vertical-status"></div>
                                                        )
                                                    }
                                                    {/* <div class="form-check-square ml-1">
                                                    <input class="form-check-input" type="checkbox" checked={candidate.isSelect} onChange={(e) => props.handleChange(e, i)}/>
                                                    </div> */}
                                                </div>
                                            </td>
                                            <td class="p-relative">
                                                <a href="#" class="font-primary no-hover">
                                                    <div class="avatar-name-candidatestableview d-flex align-items-center">
                                                        <div>
                                                            {
                                                                candidate.ImageURL ? (
                                                                    <span class="avatar avatar-bordered">
                                                                        <img alt="" src={candidate.ImageURL} />
                                                                    </span>
                                                                ) : (
                                                                    <span >
                                                                        <Avatar size="55" name={candidate.fullText.firstName + " " + candidate.fullText.lastName}  round="80px" />
                                                                        {/* {getInitial(`${candidate.fullText.firstName} ${candidate.fullText.lastName}`)} */}
                                                                    </span>
                                                                )
                                                            }
                                                        </div>
                                                        <div class="ml-3">
                                                            <h6 class="mb-0 me-2">{candidate.fullText.firstName} {candidate.fullText.middleName} {candidate.fullText.lastName}</h6>
                                                            {
                                                                (candidate.MCQ === true || candidate.onewayvetting === true || candidate.twowayvetting === true || candidate.codesignalvetting === true) && (
                                                                    <span class="tag tag-coral d-inline-flex mt-1">
                                                                        <img width="16px" src={vettedIcon} alt="" />
                                                                        <span class="mx-2">Vetted</span>
                                                                    </span>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </a>
                                            </td>
                                            <td >
                                                <div>
                                                    {
                                                        candidate.designation && (
                                                            <a href="#" class="font-primary no-hover font-regular">
                                                                {candidate.designation}
                                                            </a>
                                                        )
                                                    }
                                                </div>
                                            </td>
                                            <td >
                                                <div>
                                                    {
                                                      
                                                            <a href="#" class="font-primary no-hover font-regular">{candidate.fullText.cityName || 'Remote'} {candidate.fullText.stateName && ','} {candidate.fullText.countryName}</a>
                                                        
                                                    }
                                                </div>
                                            </td>
                                            <td >
                                                <div>
                                                    {
                                                        candidate.updatedDate !== '' && new Date() > new Date(candidate.updatedDate) && (
                                                            <a href="#" class="font-primary no-hover font-regular">
                                                                <span>{moment(candidate.updatedDate).format("MM/DD/YYYY")}</span>&nbsp;
                                                                {/* {dataDiff(candidate.updatedDate, new Date()) > 1 ? 'Days' : 'Day'} &nbsp;ago */}
                                                            </a>
                                                        )
                                                    }
                                                </div>
                                            </td>
                                            <td class="text-right">
                                                <button type="text"
                                                    onClick={() => props.openSubmitModal(candidate)}
                                                    class="btn btn-sm btn-secondary">
                                                    Source
                                                </button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr class="text-center p-3">
                                        <td colspan="6">
                                            <div class="avatar avatar-lg">
                                                <i class="fad fa-users-slash"></i>
                                            </div>
                                            <div class="mt-2">
                                                No  Candidates Found
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default SourceCandidateTableView;
