import React, { useEffect } from "react";

function QandAResponse({ QandA }) {
  useEffect(() => {
    console.log('q & a--->',QandA);
  }, []);

  return (
    <>
      {QandA.length > 0 ? (
        <div className="d-flex mb-2 pl-1 ml-4">
          <ul>
            {QandA.map((item, index) => (
              <li className="d-flex m-3">
                <div className="">
                  <span className="col-lg-1">{index + 1}</span>
                </div>
                <div className="ml-3 mb-2">
                  <span className="col-lg-11 mb-1">{item.question}</span>
                  <br />
                  {item.name === "Industry Experience" ? (
                    <small>
                      Answer: <strong>{item.answer}</strong>
                    </small>
                  ) : (
                    <small>
                      Answer: <strong>{item.answer ? "Yes" : "No"}</strong>
                    </small>
                  )}
                  {/* { (item.name === 'Industry Experience' || item.name === 'Education' ) && <small><strong>{item.input}</strong><br /> </small>}
                                    { item.name !== 'Language' && <small>Answer: <strong>{item.answer}</strong></small>}
                                    { item.name === 'Language' && 
                                        <div>
                                            {
                                                item.answer.map(item2 => 
                                                    <div className='m-2'>
                                                        <small>{item2.name}: <strong>{item2.proficiency}</strong></small>
                                                    </div>
                                                )
                                            }
                                            
                                        </div>
                                    } */}
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="mb-3">
          <div className="text-center p-3">
            <div className="avatar avatar-lg">
              <i className="fad fa-users-slash"></i>
            </div>
            <div className="mt-2">No Screening Questions Added</div>
          </div>
        </div>
      )}
    </>
  );
}

export default QandAResponse;
