import React, { useState, useEffect } from "react";
import CronJobsTable from "./CronJobsTable";
import SideNavBar from "../../../layout/SideNavBar";
import TopNavBar from "../../../layout/TopNavBar";
import Loader from "../../../Loader/Loader";
import Pagination from "../../../components_v3/pagination/pagination";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getCronJobLog,
  getCronJobs,
  reTriggerCronJob,
  updateCronJob,
} from "../../../../actions/adminconsole/CronJobs";
import { getTenantType } from "../../../../service/AuthService";
import Footer from "../../../layout/Footer";

const CronJobs = () => {
  const dispatch = useDispatch();
  const {
    cronJobsLoading,
    cronJobsData,
    cronJobUpdated,
    cronJobLog,
  } = useSelector(
    (state) => ({
      cronJobsLoading: state.cronJobsReducer.cronJobsLoading,
      cronJobsData: state.cronJobsReducer.cronJobsData,
      cronJobUpdated: state.cronJobsReducer.cronJobUpdated,
      cronJobLog: state.cronJobsReducer.cronJobLog,
    }),
    shallowEqual
  );

  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  useEffect(() => {
    dispatch(getCronJobs());
  }, []);

  useEffect(() => {
    dispatch(getCronJobs());
  }, [cronJobUpdated]);

  const filteredCronJobs = searchValue
    ? cronJobsData.filter((cronJob) =>
        cronJob.cronJobName.toLowerCase().includes(searchValue.toLowerCase())
      )
    : cronJobsData;

  const handleSearch = (e) => {
    setCurrentPage(1);
    setSearchValue(e.target.value);
  };

  const handleClearInput = () => {
    setCurrentPage(1);
    setSearchValue("");
  };

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(parseInt(event.target.value));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleUpdateCronJob = (cronJob) => {
    const data = {
      id: cronJob.cronJobId,
      status: cronJob.cronJobStatus,
    };
    dispatch(updateCronJob(data));
  };
  const handleManualTrigger = (cronJob) => {
    dispatch(reTriggerCronJob(cronJob));
  };
  const handleViewModalShow = (cronJob) => {
    dispatch(getCronJobLog(cronJob));
  };
  const startIndex = (currentPage - 1) * limit;
  const slicedData = filteredCronJobs.slice(startIndex, startIndex + limit);

  return (
    <>
      {cronJobsLoading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar parent="adminConsole" handleToggale={setToggle} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 px-5">
                  <div className="mt-3">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-md-4 col-sm-12">
                        <h5 className="text-left">
                          Cron Jobs ({cronJobsData.length})
                        </h5>
                      </div>
                      <div className="col-md-3"></div>
                      <div className="col-md-3 col-sm-12 d-flex justify-content-end align-items-center mb-2">
                        <form className="d-flex w-100">
                          <div
                            className="input-group"
                            style={{ border: "0.5px solid #ddd" }}
                          >
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search by Title"
                              aria-label="Search"
                              value={searchValue}
                              style={{ fontSize: "14px" }}
                              onChange={handleSearch}
                            />
                            {searchValue && (
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={handleClearInput}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                      <div className="col-md-2 col-sm-12 d-flex justify-content-md-end align-items-center mb-2">
                        <div className="d-flex align-items-center text-left text-nowrap me-2">
                          <span>Show Records:</span>
                          <select
                            className="form-select form-select-sm mx-2"
                            value={limit}
                            onChange={handleLimitChange}
                          >
                            <option value={cronJobsData.length}>All</option>
                            <option value={10}>10</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <CronJobsTable
                    cronJobs={slicedData}
                    handleUpdateCronJob={handleUpdateCronJob}
                    handleManualTrigger={handleManualTrigger}
                    handleViewModalShow={handleViewModalShow}
                    cronJobLog={cronJobLog}
                  />
                  {cronJobsData.length !== 0 && (
                    <Pagination
                      currentPage={currentPage}
                      totalItems={filteredCronJobs.length}
                      itemsPerPage={limit}
                      onPageChange={handlePageChange}
                      limit={limit}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
};

export default CronJobs;
