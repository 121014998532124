import React, { useEffect } from "react";

function ReadyToHireQA({ QandA }) {
  return (
    <>
      {QandA?.length > 0 ? (
        <div className="d-flex mb-2 pl-1 ml-4">
          <ul>
            {QandA.map((item, index) => (
              <li className="d-flex m-3">
                <div className="">
                  <span className="col-lg-1">{index + 1}</span>
                </div>
                <div className="ml-3 mb-2">
                  <span className="col-lg-11 mb-1">{item?.question}</span>
                  <br />
                    <small>
                      Answer:{" "}
                      <strong>{item.answer == "" ? "NA" : item.answer}</strong>
                    </small>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="mb-3">
          <div className="text-center p-3">
            <div className="avatar avatar-lg">
              <i className="fad fa-users-slash"></i>
            </div>
            <div className="mt-2">No Screening Questions Added</div>
          </div>
        </div>
      )}
    </>
  );
}

export default ReadyToHireQA;
