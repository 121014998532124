import { useState } from "react";
import { Modal } from "react-bootstrap";
import FromA from "../../disposition/applyJob_v2/index";
import FromB from "../../disposition/applyJob_v2_Parsing/index";
import SignUp from "./SignUp";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const JobDetails = ({
  job,
  setSocialMediaApply,
  setLoginData,
  isLoginModal,
}) => {
  const { recruiterid } = useParams();
  const FROMTYPES = ["A", "B"];
  const [RANDOM, setRANDOM] = useState(
    FROMTYPES[Math.floor(Math.random() * FROMTYPES.length)]
  );
  const [apply, setApply] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [modalSize, setModalSize] = useState("login");

  const handleLogin = () => {
    setModalSize("login");
    setApply(true);
    setLoggedIn(true);
  };
  const handleCancel = () => {
    //if(sessionStorage.getItem("token") == null ){
    setApply(false);
    setModalSize("apply");
  };
  const renderForm = () => {
    return RANDOM === "A" ? (
      <FromA
        job={job}
        setModalSize={setModalSize}
        setApply={setApply}
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
      />
    ) : (
      <FromB
        job={job}
        setModalSize={setModalSize}
        setApply={setApply}
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
      />
    );
  };
  return (
    <>
      <div className="main_job_requirements_part_1a mt-2 mb-2 ml-3">
        <span>
          <h6>Job Requirement</h6>
        </span>

        <p dangerouslySetInnerHTML={{ __html: job.jobDescription }}></p>
      </div>
      <div className="main_job_requirements_part_1a ml-3">
        <span>
          <h6>Required Skills</h6>
        </span>
        <div className="main_container-key_points mt-2">
          {job?.primarySkills &&
            JSON.parse(job?.primarySkills)?.map((item, i) => (
              <div className="main_container-key_points_style" key={i}>
                <span className="main_container-key_points_style_text">
                  {item}
                </span>
              </div>
            ))}
        </div>
      </div>
      <div className="main_job_requirements_part_2a ml-3 mt-2">
        <p style={{ fontWeight: "bold", fontSize: "10px" }}>
          Apply now to explore this exciting job opportunity.
        </p>
        <button
          className="main_apply_container_apply_btn1"
          style={{ border: "none" }}
          onClick={() => {
            sessionStorage.getItem("userId") == null
              ? setApply(true)
              : handleLogin();
          }}
        >
          <span>Apply Now</span>
        </button>

        <Modal
          show={apply}
          size={modalSize == "apply" ? "xl" : "xl"}
          onHide={() => setApply(false)}
        >
          <Modal.Header>
            <div style={{ zIndex: "1000" }}>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleCancel(false)}
                className="close p-0 bl-modal-close-btn mx-1"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>{renderForm()}</Modal.Body>
        </Modal>
      </div>
      {sessionStorage.getItem("token") == null && (
        <SignUp
          setSocialMediaApply={setSocialMediaApply}
          setLoginData={setLoginData}
          isLoginModal={isLoginModal}
          isFrom={"jobDetails"}
        />
      )}
      <div className="main_footer-heading ml-3 mt-3">
        <span>We’re always on the lookout for Talent</span>
        <div className="main_frame">
          <div className="main_footer-sub_heading">
            <span>
              If you are thinking about a future with us, sign up to stay
              connected and be informed of any new opportunities
            </span>
          </div>
          <div className="">
            {!sessionStorage.getItem("token") && (
              <button
                className="main_footer-button_style"
                // onClick={() => {
                //   setCheckEmail(true);
                // }}
                onClick={() => {
                  window.open(
                    `/careers/8/recruiter/${recruiterid}/newjoincommunity`,
                    "_blank"
                  );
                }}
              >
                <span>Talent Community</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobDetails;
