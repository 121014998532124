import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { get, post } from "../../../../../service/ApiService";
import moment from "moment";
import Loader from "../../../commoncomponents/Loader/Loader";
import NoRecord from "../../../commoncomponents/norecord/NoRecord";
import { toast } from "react-toastify";
const SavedSearchList = ({
  showSavedSearch,
  setShowSavedSearch,
  setSkillInputList,
  setInputList,
  setSelectedData,
  applyFilter,
}) => {
  const [data, setData] = useState([]);
  const [state, setState] = useState([]);
  const [loading, setLoading] = useState(false);

  const [rename, setRename] = useState({ show: false, item: null });
  const [deleteConfirm, setDeleteConfirm] = useState({
    show: false,
    item: null,
  });
  const [newName, setNewName] = useState("");

  useEffect(() => {
    getSearches();
  }, []);

  const getSearches = async () => {
    try {
      setLoading(true);
      let res = await get("/bulkTalentAction/getSaveSearchData");
      setData(res.data);
      setLoading(false);

      console.log(res.data);
    } catch (error) {
      setLoading(false);

      console.log(error);
    }
  };

  useEffect(() => {
    if (state.length === 0) return;
    applyFilter("savedSearch", "savedSearch");
    setShowSavedSearch(false);
  }, [state]); // Dependency array ensures this effect runs after these states have been updated

  const openSearch = (item) => {
    let data = JSON.parse(item?.info);
    let inputList = data.inputList;
    let skillInputList = data.skillInputList;
    let selectedData = data.selectedData;

    setSkillInputList(skillInputList);
    setInputList(inputList);
    setSelectedData(selectedData);
    setState([inputList, skillInputList, selectedData]);
  };

  // Function to handle form submission
  const handleRename = async (e) => {
    e.preventDefault();
    if (newName === "") return toast.error("Name is a mandatory field");
    // You can perform further actions here, like sending the new name to the backend
    setLoading(true);
    try {
      await post("/bulkTalentAction/renameData", {
        id: rename.item.id,
        name: newName,
      });
      toast.success("Search name changed successfully.");
      setRename({ show: false, item: null });
      setNewName("");
      getSearches();
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
      setLoading(false);
    }
  };
  const handleDelete = async (e) => {
    e.preventDefault();
    // You can perform further actions here, like sending the new name to the backend
    setLoading(true);
    try {
      await post("/bulkTalentAction/deleteSearchData", {
        id: deleteConfirm.item.id,
      });
      toast.success("Search deleted successfully.");
      setDeleteConfirm({ show: false, item: null });
      getSearches();
    } catch (error) {
      console.log(error);
      toast.error("something went wrong");
      setLoading(false);
    }
  };

  const renderTable = () => (
    <div className="table-responsive p-3">
      <p>Saved searches enables you to access your saved filter selections.</p>
      <table
        className="table text-center table-bordered table-hover mx-auto"
        style={{ maxWidth: "800px" }}
      >
        <thead className="table-light">
          <tr>
            <th className="col-1">S.No</th>
            <th className="col-2">Name</th>
            <th className="col-2">Created On</th>
            <th colSpan={4} className="text-center col-3">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{item.name}</td>
              <td>{moment(item.createdAt).format("MM/DD/YYYY")}</td>
              <td className="">
                <button
                  href={"#"}
                  onClick={() => {
                    openSearch(item);
                  }}
                  className=" btn btn-xs btn-secondary border-0 text-black "
                >
                  <i class="far fa-search"></i> Open
                </button>
              </td>
              <td className="">
                <button
                  href={`#`}
                  onClick={() => {
                    setRename({
                      show: true,
                      item: item,
                    });
                  }}
                  className="btn btn-xs btn-secondary border-0 text-black "
                >
                  <i class="far fa-pencil"></i> Rename
                </button>
              </td>{" "}
              <td className="">
                <button
                  href={`#`}
                  onClick={() => {
                    setDeleteConfirm({
                      show: true,
                      item: item,
                    });
                  }}
                  className="btn btn-xs btn-secondary border-0 text-black "
                >
                  <i class="far fa-trash"></i> Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!data?.length && <NoRecord />}
    </div>
  );

  const renameUI = () => (
    <div className="container p-4">
      <div className="row">
        <div className="col-md-12">
          <p>
            <strong>Name:</strong> {rename?.item?.name}
          </p>
          {/* <p><strong>Created By:</strong> {rename?.item?.createdBy}</p> */}
          <p className="mb-0">
            <strong>Created On:</strong>{" "}
            {moment(rename?.item?.createdAt).format("MM/DD/YYYY")}
          </p>
        </div>
      </div>
      <div className="col-md-12">
        {/* <h2>Rename Object</h2>
         */}
        <hr />
        <div className=" ">
          <input
            type="text"
            className="form-control "
            id="newName"
            placeholder="Enter new name*"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
  const deleteUI = () => (
    <div className="m-3">
      <div class="text-center">
        <div class="avatar avatar-lg avatar-red2">
          <i class="fas fa-exclamation-triangle fa-fw" aria-hidden="true"></i>
        </div>
        <p class="text-center lead mt-3">
          Are you sure you want to delete this search
        </p>
        <p>
          <strong>Name:</strong> {deleteConfirm?.item?.name}
        </p>
        <p className="mb-0">
          <strong>Created On:</strong>{" "}
          {moment(deleteConfirm?.item?.createdAt).format("MM/DD/YYYY")}
        </p>
      </div>
      {/* <div className="container p-4">
      <div className="row">
        <div className="col-md-12">
          <p>
            <strong>Name:</strong> {deleteConfirm?.item?.name}
          </p>
          <p className="mb-0">
            <strong>Created On:</strong>{" "}
            {moment(deleteConfirm?.item?.createdAt).format("MM/DD/YYYY")}
          </p>
        </div>
      </div>
      <div className="col-md-12">
        <hr />
        <div className=" ">
          <strong> Are you sure you want to delete this search</strong>
        </div>
      </div>
    </div> */}
    </div>
  );

  return (
    <Modal show={showSavedSearch} size="lg">
      <div className="modal-header" id="NewCandidateModal">
        {rename.show ? (
          <h6>Rename Search</h6>
        ) : deleteConfirm.show ? (
          <h6>Delete Search</h6>
        ) : (
          <h6>Saved Searches</h6>
        )}
        {showSavedSearch && (
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowSavedSearch(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
      <Modal.Body>
        <div className="card card-flat">
          {loading && <Loader />}
          {rename.show && rename.item
            ? renameUI()
            : deleteConfirm.show && deleteConfirm.item
            ? deleteUI()
            : renderTable()}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => setShowSavedSearch(false)}
        >
          Close
        </button>{" "}
        {rename.show && (
          <button type="submit" onClick={handleRename} className="btn ml-2">
            Rename
          </button>
        )}{" "}
        {deleteConfirm.show && (
          <button type="submit" onClick={handleDelete} className="btn ml-2">
            Delete
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default SavedSearchList;
