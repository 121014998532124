import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import TopNavbar from "../../../candidatecareers/TopNavBar";
import PoolInfo from "./PoolInfo";
import PoolDescription from "./PoolDescription";
import Footer from "../Footer";
import ApplyNowPool from "./ApplyNowPool";
import { domain } from "../../../../../constants/Constants";
import { get, post } from "../../../../../service/ApiService";
import ApplyJob from "../../../applyjob/ApplyJob";
import Loader from "../../../../Loader/Loader";

const Index = () => {
  const { id, recruiterid } = useParams();
  const [tenantData, setTenantData] = useState({});
  const [loading, setLoading] = useState(false);
  const [poolInfo, setPoolInfo] = useState({});
  const [renderTopNavBar, setRenderTopNavBar] = useState(false);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [jobCategory, setJobCategory] = useState("");

  const getPoolById = async () => {
    const params = { poolId: id };
    setLoading(true);
    await post("/talent/getPoolInfo", params).then((res) => {
      // setTenantData(res.data[0]);
      setPoolInfo(res.data[0]);
      if (res.data) {
        let jobCategory = JSON.parse(res.data[0].industry);
        setJobCategory(jobCategory[0]);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    getPoolById();
  }, []);
  return (
    <div>
      {loading && <Loader />}
      {/* <div>
        <TopNavbar
          renderTopNavBar={renderTopNavBar}
          isFrom="jobDescription"
          setUserLoggedIn={setUserLoggedIn}
        />
        <PoolInfo status="jobApply" poolInfo={poolInfo} />
      </div> */}
      {/* <ApplyNowPool
        job={poolInfo}
        setRenderTopNavBar={setRenderTopNavBar}
        setUserLoggedIn={setUserLoggedIn}
        userLoggedIn={userLoggedIn}
      /> */}
      {jobCategory && (
        <ApplyJob
          job={poolInfo}
          setRenderTopNavBar={setRenderTopNavBar}
          setUserLoggedIn={setUserLoggedIn}
          userLoggedIn={userLoggedIn}
          jobCategory={jobCategory}
        />
      )}
      {/* <PoolDescription job={poolInfo} />

      <Footer recruiterId={recruiterid} /> */}
    </div>
  );
};

export default Index;
