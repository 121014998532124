import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const GET_CRONJOB_DATA_BEGIN = "GET_CRONJOB_DATA_BEGIN";
export const GET_CRONJOB_DATA_SUCCESS = "GET_CRONJOB_DATA_SUCCESS";
export const GET_CRONJOB_DATA_FAILURE = "GET_CRONJOB_DATA_FAILURE";

export const UPDATE_CRONJOB_DATA_BEGIN = "UPDATE_CRONJOB_DATA_BEGIN";
export const UPDATE_CRONJOB_DATA_SUCCESS = "UPDATE_CRONJOB_DATA_SUCCESS";
export const UPDATE_CRONJOB_DATA_FAILURE = "UPDATE_CRONJOB_DATA_FAILURE";

export const TRIGGER_CRONJOB_DATA_BEGIN = "TRIGGER_CRONJOB_DATA_BEGIN";
export const TRIGGER_CRONJOB_DATA_SUCCESS = "TRIGGER_CRONJOB_DATA_SUCCESS";
export const TRIGGER_CRONJOB_DATA_FAILURE = "TRIGGER_CRONJOB_DATA_FAILURE";

export const GET_CRONJOBLOG_DATA_BEGIN = "GET_CRONJOBLOG_DATA_BEGIN";
export const GET_CRONJOBLOG_DATA_SUCCESS = "GET_CRONJOBLOG_DATA_SUCCESS";
export const GET_CRONJOBLOG_DATA_FAILURE = "GET_CRONJOBLOG_DATA_FAILURE";

export const getCronJobs = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_CRONJOB_DATA_BEGIN,
    });
    const res = await get("/cronJobs/list");
    if (res.status === 200) {
      dispatch({
        type: GET_CRONJOB_DATA_SUCCESS,
        payload: res.data,
      });
    }
    console.log("@24 :", res.data);
  } catch (error) {
    dispatch({
      type: GET_CRONJOB_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const updateCronJob = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CRONJOB_DATA_BEGIN,
    });
    const res = await post("/cronJobs/update", data);
    if (res.status === 200) {
      toast.success("Cron Job updated successfully!");
      dispatch({
        type: UPDATE_CRONJOB_DATA_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_CRONJOB_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getCronJobLog = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CRONJOBLOG_DATA_BEGIN,
    });
    const res = await post("/cronJobs/logs", data);
    if (res.status === 200) {
      dispatch({
        type: GET_CRONJOBLOG_DATA_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CRONJOBLOG_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
export const reTriggerCronJob = (data) => async (dispatch) => {
  try {
    dispatch({
      type: TRIGGER_CRONJOB_DATA_BEGIN,
    });
    const res = await post("/cronJobs/re-trigger", data);
    if (res.status === 200) {
      toast.success("Cron Job re-triggered successfully!");
      dispatch({
        type: TRIGGER_CRONJOB_DATA_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: TRIGGER_CRONJOB_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
