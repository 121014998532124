import {
  GET_AUDIT_LOG_DATA_BEGINS,
  GET_AUDIT_LOG_DATA_SUCCESS,
  GET_AUDIT_LOG_DATA_FAILURE,
  GET_AUDIT_LOG_ANALYTICS_DATA_BEGINS,
  GET_AUDIT_LOG_ANALYTICS_DATA_SUCCESS,
  GET_AUDIT_LOG_ANALYTICS_DATA_FAILURE,
} from "../../actions/auditlog/getAuditLogData";

const initialState = {
  loading: false,
  auditLogData: [],
  analyticsLoading: false,
  analyticsData: [],
};

const getAuditLogDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AUDIT_LOG_DATA_BEGINS:
      return {
        ...state,
        loading: true,
        auditLogData: [],
      };
    case GET_AUDIT_LOG_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        auditLogData: payload,
      };
    case GET_AUDIT_LOG_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        auditLogData: payload,
      };
    case GET_AUDIT_LOG_ANALYTICS_DATA_BEGINS:
      return {
        ...state,
        analyticsLoading: true,
        analyticsData: [],
      };
    case GET_AUDIT_LOG_ANALYTICS_DATA_SUCCESS:
      return {
        ...state,
        analyticsLoading: false,
        analyticsData: payload,
      };
    case GET_AUDIT_LOG_ANALYTICS_DATA_FAILURE:
      return {
        ...state,
        analyticsLoading: false,
        analyticsData: payload,
      };
    default:
      return state;
  }
};

export default getAuditLogDataReducer;
