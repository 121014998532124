import React, { useState, useEffect } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import { ButtonLoader } from "../../../constants/Constants";
import PreferredLocationComp from "../../location/PreferredLocation";
import { currency_list } from "../../function/CommonFunction";

function PreferredCandidate({ data }) {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [showExtraLocations, setShowExtraLocations] = useState(false);
  const [preferedLocations, setPreferredLocation] = useState([]);
  const [tempPreferedLocations, setTempPreferredLocation] = useState([]);
  const [preferredPositionType, setPreferredPositionType] = useState([]);
  const [tempPreferredPositionType, setTempPreferredPositionType] = useState(
    []
  );
  const [currency, setCurrency] = useState({
    minContractRate: "",
    preferredSalary: "",
    preferredSalaryCurrency: "",
  });
  const [tempCurrency, setTempCurrency] = useState({});
  const [readOnlyModeCurrency, setReadOnlyModeCurrency] = useState(true);
  const [submittingFormCurrency, setSubmittingFromCurrency] = useState(false);
  const [showSuccessMessageCurrency, setShowSuccessMessageCurrency] = useState(
    false
  );
  const [saveClickedCurrency, setSaveClickedCurrency] = useState(true);
  const [remote, setRemote] = useState(false);
  const [tempRemote, setTempRemote] = useState(false);

  const editPreferred = () => {
    setReadOnlyMode(false);
    setSaveClicked(true);
  };

  const editCurrency = () => {
    setReadOnlyModeCurrency(false);
    setSaveClickedCurrency(true);
  };

  const cancelPreferred = () => {
    setPreferredPositionType(tempPreferredPositionType);
    setPreferredLocation(tempPreferedLocations);
    setRemote(tempRemote);
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
  };

  const cancelCurrency = () => {
    setTempCurrency(currency);
    setReadOnlyModeCurrency(true);
    setShowSuccessMessageCurrency(false);
    setSubmittingFromCurrency(false);
    setSaveClickedCurrency(true);
  };

  const handleChange = (value, checked) => {
    let jobTypes = [...preferredPositionType];
    if (checked) {
      jobTypes.push(value);
    } else {
      jobTypes.splice(
        jobTypes.findIndex((type) => type === value),
        1
      );
    }
    setPreferredPositionType(jobTypes);
  };

  const deleteLocation = (index) => {
    let current = [...preferedLocations];
    current.splice(index, 1);
    setPreferredLocation(current);
  };

  const savePreferred = async () => {
    setSaveClicked(false);
    setSubmittingFrom(true);
    try {
      const parameters = {
        clickName: "candidatePreferences",
        preferredLocations: remote
          ? [...preferedLocations, "Remote"]
          : preferedLocations,
        preferredPositionTypes: preferredPositionType,
      };
      const res = await post("/candidateProfile/updatePreferences", parameters);
      if (res.status === 200) {
        setTempPreferredLocation(preferedLocations);
        setTempPreferredPositionType(preferredPositionType);
        setTempRemote(remote);
        setSubmittingFrom(false);
        setShowSuccessMessage(true);
        setTimeout(() => {
          setReadOnlyMode(true);
          setShowSuccessMessage(false);
        }, 1000);
      } else {
        toast.error("Some Error Occured. Please Try Again");
      }
    } catch (error) {
      toast.error("Error Occured. Please Try Again");
    }
  };

  const saveCurrency = async () => {
    setSaveClickedCurrency(false);
    setSubmittingFromCurrency(true);
    try {
      const parameters = {
        clickName: "Currency",
        minContractRate: tempCurrency.minContractRate,
        preferredSalary: tempCurrency.preferredSalary,
        preferredSalaryCurrency: tempCurrency.preferredSalaryCurrency,
      };
      const res = await post("/candidateProfile/updatePreferences", parameters);
      if (res.status === 200) {
        setCurrency(tempCurrency);
        setSubmittingFromCurrency(false);
        setShowSuccessMessageCurrency(true);
        setTimeout(() => {
          setReadOnlyModeCurrency(true);
          setShowSuccessMessageCurrency(false);
        }, 1000);
      } else {
        toast.error("Some Error Occured. Please Try Again");
      }
    } catch (error) {
      toast.error("Error Occured. Please Try Again");
    }
  };

  useEffect(() => {
    let tempObj = {
      minContractRate: data.minContractRate,
      preferredSalary: data.preferredSalary,
      preferredSalaryCurrency: data.preferredSalaryCurrency,
    };
    if (data.preferedLocations.includes("Remote")) {
      setRemote(true);
      setTempRemote(true);
    }
    setPreferredLocation(data.preferedLocations.filter((i) => i !== "Remote"));
    setTempPreferredLocation(
      data.preferedLocations.filter((i) => i !== "Remote")
    );
    setPreferredPositionType(data.preferredPositionType);
    setTempPreferredPositionType(data.preferredPositionType);
    setCurrency(tempObj);
    setTempCurrency(tempObj);
  }, []);

  return (
    <>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div className="d-flex align-items-center justify-content-between">
            <h6>Preferences</h6>
            {readOnlyMode === true ? (
              <button
                type="button"
                class="btn btn-text"
                onClick={editPreferred}
              >
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={cancelPreferred}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={() => savePreferred()}
                  class="btn btn-sm btn-primary"
                >
                  {saveClicked && <span>Save Changes</span>}

                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img width="20px" src={ButtonLoader} alt="" />
                    </span>
                  )}
                  {showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          <br />
          {readOnlyMode ? (
            <div>
              {/* <div class="d-flex align-items-center justify-content-between pb-3">
            <label class="p-0">Show My Profile Pic to Recruiters and Companies</label>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" />
            </div>
          </div> */}
              {/* <hr class="m-0" /> */}
              <div class="d-flex align-items-center justify-content-between py-3">
                <label class="p-0">I'm interested in Full-Time Jobs</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={
                      preferredPositionType.length > 0
                        ? preferredPositionType.findIndex((type) =>
                            type.toLowerCase().includes("full")
                          ) === -1
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </div>
              <hr class="m-0" />
              <div class="d-flex align-items-center justify-content-between py-3">
                <label class="p-0">I'm interested in Contract Jobs</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={
                      preferredPositionType.length > 0
                        ? preferredPositionType.findIndex((type) =>
                            type.toLowerCase().includes("contract")
                          ) === -1
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </div>
              <hr class="m-0" />
              <div class="d-flex align-items-center justify-content-between py-3">
                <label class="p-0">I'm interested in Part-Time Jobs</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={
                      preferredPositionType.length > 0
                        ? preferredPositionType.findIndex((type) =>
                            type.toLowerCase().includes("part")
                          ) === -1
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </div>
              <hr class="m-0" />
              <div class="d-flex align-items-center justify-content-between py-3">
                <label class="p-0">I'm interested in Temp to Hire Jobs</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={
                      preferredPositionType.length > 0
                        ? preferredPositionType.findIndex((type) =>
                            type.toLowerCase().includes("temp")
                          ) === -1
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </div>
              <hr class="m-0" />
              <div class="d-flex align-items-center justify-content-between py-3">
                <label class="p-0">I'm interested in Volunteer Jobs</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    checked={
                      preferredPositionType.length > 0
                        ? preferredPositionType.findIndex((type) =>
                            type.toLowerCase().includes("volunteer")
                          ) === -1
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
          ) : (
            <div>
              {/* <div class="d-flex align-items-center justify-content-between pb-3">
            <label class="p-0">Show My Profile Pic to Recruiters and Companies</label>
            <div class="form-check form-switch">
              <input class="form-check-input" type="checkbox" />
            </div>
          </div> */}
              {/* <hr class="m-0" /> */}
              <div class="d-flex align-items-center justify-content-between py-3">
                <label class="p-0">I'm interested in Full-Time Jobs</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    onChange={(e) => handleChange("Fulltime", e.target.checked)}
                    checked={
                      preferredPositionType.length > 0
                        ? preferredPositionType.findIndex((type) =>
                            type.toLowerCase().includes("full")
                          ) === -1
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </div>
              <hr class="m-0" />
              <div class="d-flex align-items-center justify-content-between py-3">
                <label class="p-0">I'm interested in Contract Jobs</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    onChange={(e) => handleChange("Contract", e.target.checked)}
                    checked={
                      preferredPositionType.length > 0
                        ? preferredPositionType.findIndex((type) =>
                            type.toLowerCase().includes("contract")
                          ) === -1
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </div>
              <hr class="m-0" />
              <hr class="m-0" />
              <div class="d-flex align-items-center justify-content-between py-3">
                <label class="p-0">I'm interested in Part-Time Jobs</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    onChange={(e) =>
                      handleChange("Part Time", e.target.checked)
                    }
                    checked={
                      preferredPositionType.length > 0
                        ? preferredPositionType.findIndex((type) =>
                            type.toLowerCase().includes("part")
                          ) === -1
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </div>
              <hr class="m-0" />
              <div class="d-flex align-items-center justify-content-between py-3">
                <label class="p-0">I'm interested in Temp to Hire Jobs</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    onChange={(e) =>
                      handleChange("Temp to Hire", e.target.checked)
                    }
                    checked={
                      preferredPositionType.length > 0
                        ? preferredPositionType.findIndex((type) =>
                            type.toLowerCase().includes("temp")
                          ) === -1
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </div>
              <hr class="m-0" />
              <div class="d-flex align-items-center justify-content-between py-3">
                <label class="p-0">I'm interested in Volunteer Jobs</label>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    onChange={(e) =>
                      handleChange("Volunteer", e.target.checked)
                    }
                    checked={
                      preferredPositionType.length > 0
                        ? preferredPositionType.findIndex((type) =>
                            type.toLowerCase().includes("volunteer")
                          ) === -1
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <hr class="m-0" />
          {readOnlyMode ? (
            <div class="d-flex d-block-768 align-items-center justify-content-between py-3">
              <label class="p-0">Preferred Locations</label>
              <div class="w-50 w-100-768 text-right">
                <div class="form-inline-edit">
                  <div class="toggle">
                    <div class="d-flex flex-wrap align-items-center justify-content-end">
                      <div>
                        {showExtraLocations
                          ? preferedLocations.map((item) => (
                              <span className="tag tag-blue3 mb-1 mr-1">
                                {item}
                              </span>
                            ))
                          : preferedLocations
                              .slice(0, 2)
                              .map((item) => (
                                <span className="tag tag-blue3 mb-1 mr-1">
                                  {item}
                                </span>
                              ))}
                      </div>
                      {preferedLocations.length > 2 && (
                        <button
                          onClick={() =>
                            showExtraLocations
                              ? setShowExtraLocations(false)
                              : setShowExtraLocations(true)
                          }
                          type="button"
                          class="btn btn-text ml-1 my-1 no-change"
                        >
                          <span class="tag tag-blue1 no-change">
                            {showExtraLocations ? (
                              <span class="no-change">Show Less</span>
                            ) : (
                              <span class="no-change">
                                {preferedLocations.length - 2}+
                              </span>
                            )}
                          </span>
                        </button>
                      )}
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div class="row mb-12 mt-2">
              <div class="col-12">
                <div class="mb-2 d-flex">
                  <label>Preferred Locations* </label>
                  <div
                    class="form-floating ml-auto"
                    style={{ margin: "15px 0px 0px 50px" }}
                  >
                    <div className="form-check form-switch d-flex align-items-center">
                      <label
                        className="form-check-label pt-0"
                        htmlFor="remoteSwitch"
                      >
                        Open For Remote
                      </label>
                      <input
                        className="form-check-input ml-3"
                        type="checkbox"
                        id="isFresher"
                        checked={remote}
                        onChange={(e) => setRemote(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-inline-edit">
                    <div>
                      <div className="mt-2">
                        {preferedLocations.map((item, index) => (
                          <>
                            <span className="tag tag-blue3 mb-1 mr-1">
                              {item}
                              <button
                                className="btn btn-text"
                                onClick={() => deleteLocation(index)}
                              >
                                <strong className="font-18 font-black">
                                  &times;
                                </strong>
                              </button>
                            </span>
                          </>
                        ))}
                      </div>
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <div className="form-floating w-100">
                          <PreferredLocationComp
                            setLocation={(loc) => {
                              if (preferedLocations.includes(loc)) {
                                toast.error(
                                  "You Have Already Added This Location"
                                );
                              } else {
                                setPreferredLocation((cur) => [...cur, loc]);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div className="d-flex align-items-center justify-content-between">
            <h6>Currency and Rates</h6>
            {readOnlyModeCurrency ? (
              <button type="button" class="btn btn-text" onClick={editCurrency}>
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={cancelCurrency}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={saveCurrency}
                  class="btn btn-sm btn-primary"
                >
                  {saveClickedCurrency && <span>Save Changes</span>}

                  {submittingFormCurrency && (
                    <span>
                      Saving Changes
                      <img width="20px" src={ButtonLoader} alt="" />
                    </span>
                  )}
                  {showSuccessMessageCurrency && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          {readOnlyModeCurrency ? (
            <div className="mt-4">
              <div className="d-flex align-items-center justify-content-between">
                <label class="p-0">Preferred Currency</label>
                <label class="p-0">
                  {tempCurrency.preferredSalaryCurrency &&
                  Object.keys(currency_list).includes(
                    tempCurrency.preferredSalaryCurrency
                  )
                    ? tempCurrency.preferredSalaryCurrency
                    : "USD"}
                </label>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <label class="p-0">Minimum Hourly Rate</label>
                <label class="p-0">
                  <span>
                    {tempCurrency.preferredSalaryCurrency &&
                    Object.keys(currency_list).includes(
                      tempCurrency.preferredSalaryCurrency
                    )
                      ? currency_list[tempCurrency.preferredSalaryCurrency]
                          .symbol
                      : "$"}
                  </span>
                  {tempCurrency.minContractRate}
                  <span className="ml-1">/hr</span>
                </label>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <label class="p-0">Minimum Annual Salary</label>
                <label class="p-0">
                  <span>
                    {tempCurrency.preferredSalaryCurrency &&
                    Object.keys(currency_list).includes(
                      tempCurrency.preferredSalaryCurrency
                    )
                      ? currency_list[tempCurrency.preferredSalaryCurrency]
                          .symbol
                      : "$"}
                  </span>
                  {tempCurrency.preferredSalary}
                  <span className="ml-1">/annual</span>
                </label>
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <div className="form-floating">
                <select
                  className="form-control"
                  onChange={(e) =>
                    setTempCurrency({
                      ...tempCurrency,
                      preferredSalaryCurrency: e.target.value,
                    })
                  }
                >
                  <option
                    selected={tempCurrency.preferredSalaryCurrency === ""}
                  >
                    {}
                  </option>
                  {Object.entries(currency_list).map((item) => (
                    <option
                      value={item[0]}
                      selected={
                        ((tempCurrency.preferredSalaryCurrency === "" ||
                          !Object.keys(currency_list).includes(
                            tempCurrency.preferredSalaryCurrency
                          )) &&
                          item[0] === "USD") ||
                        item[0] === tempCurrency.preferredSalaryCurrency
                      }
                    >{`${item[1].name}, ${item[0]} (${item[1].symbol})`}</option>
                  ))}
                </select>
                <label>Preferred Currency</label>
              </div>
              <div className="form-floating mt-2">
                <input
                  type="number"
                  min="1"
                  className="form-control"
                  value={tempCurrency.minContractRate}
                  placeholder="Enter Minimum Hourly Rate"
                  onChange={(e) =>
                    setTempCurrency({
                      ...tempCurrency,
                      minContractRate: e.target.value,
                    })
                  }
                />
                <label>Minimum Hourly Rate</label>
              </div>
              <div className="form-floating mt-2">
                <input
                  type="number"
                  min="1"
                  className="form-control"
                  value={tempCurrency.preferredSalary}
                  placeholder="Enter Minimum Annual Salary"
                  onChange={(e) =>
                    setTempCurrency({
                      ...tempCurrency,
                      preferredSalary: e.target.value,
                    })
                  }
                />
                <label>Minimum Annual Salary</label>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default PreferredCandidate;
