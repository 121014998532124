import React from "react";
const EditTemplate = (props) => {
  const { emailData, Header, Footer } = props;
  console.log("emailData", emailData.mailContent);
  return (
    <>
      <div className="email-preview-container">
        <div
          dangerouslySetInnerHTML={{ __html: `${Header}` }}
          className="w-60"
        />
        <h4>{emailData?.mailSubject}</h4>
        <br />
        <div
          className="w-60"
          dangerouslySetInnerHTML={{
            __html: `<body style="background: #e1e1e1;font-family:Arial, Helvetica, sans-serif; font-size:1em;">
                      <style type="text/css">
                          div.preheader {
                              display: none !important;
                          }
                      </style>
                      <table id="backgroundTable" width="100%" cellspacing="0" cellpadding="0" border="0" style="background:#e1e1e1;">
                          <tr>
                              <td class="body" align="center" valign="top" style="background:#e1e1e1;" width="100%">
                                  <table cellpadding="0" cellspacing="0">
                                      <tr>
                                          <td width="640">
                                          </td>
                                      </tr>
                                      <tr>
                                          <td class="main" width="640" align="center" style="padding: 0 10px;">
                                              <table style="min-width: 100%; " class="stylingblock-content-wrapper" width="100%"
                                                  cellspacing="0" cellpadding="0">
                                                  <tr>
                                                      <td class="stylingblock-content-wrapper camarker-inner">
                                                          <table cellspacing="0" cellpadding="0">
                                                              <tr>
                                                                  <td width="640" align="left">
                                                                      <table width="100%" cellspacing="0" cellpadding="0">
                                                                          <tr>
                                                                              <td class="header header--left" style="padding: 20px 10px;"
                                                                                  align="left">
                                                                                  <a href="https://high5hire.com"><img
                                                                                          class="header__logo"
                                                                                          src="http://cdn.mcauto-images-production.sendgrid.net/95fd13153fcc4fa0/4c5b87fd-f905-4130-8404-3c303397a08b/247x112.png"
                                                                                          alt="High5Hire"
                                                                                          style="display: block; border: 0;" width="158"
                                                                                          height="59"></a>
                                                                              </td>
                                                                          </tr>
                                                                      </table>
                                                                  </td>
                                                              </tr>
                                                          </table>
                                                      </td>
                                                  </tr>
                                              </table>
                                              <table style="min-width: 100%; " class="stylingblock-content-wrapper" width="100%"
                                                  cellspacing="0" cellpadding="0">
                                                  <tr>
                                                      <td class="stylingblock-content-wrapper camarker-inner">
                                                          <table class="featured-story featured-story--top" cellspacing="0"
                                                              cellpadding="0">
                                                              <tr>
                                                                  <td style="padding-bottom: 20px;">
                                                                      <table cellspacing="0" cellpadding="0">
                                                                          <tr>
                                                                              <td class="featured-story__inner" style="background: #fff;">
                                                                                  <table cellspacing="0" cellpadding="0">
                      
                                                                                      <tr>
                                                                                          <td class="featured-story__content-inner"
                                                                                              style="padding: 5px 21px 36px;">
                                                                                              <table cellspacing="0" cellpadding="0">
                      
                                                                                                  <tr>
                                                                                                      <td class="featured-story__copy"
                                                                                                          style="background: #fff;"
                                                                                                          width="640" align="center">
                                                                                                          <table cellspacing="0"
                                                                                                              cellpadding="0">
                                                                                                              <tr>
                                                                                                                  <td style="font-family: Geneva, Tahoma, Verdana, sans-serif; font-size: 16px; line-height: 22px; color: #555555; padding-top: 16px;"
                                                                                                                      align="left">
                                                                                                                     
                                                                                                                      <div className="email-preview-content mb-3">
                                                                                                                        ${emailData?.mailContent}
                                                                                                                      </div>
                                                                                                                      <br/>
                                                                                                                      <div>${emailData?.footer}</div>
                                                                                                                      <div>${emailData?.unsubscribeContent}</div>
                                                                                                  `,
          }}
        />
        <div
          className="d-flex justify-content-center align-items-center w-60"
          dangerouslySetInnerHTML={{ __html: `${Footer}` }}
        />
      </div>
    </>
  );
};

export default EditTemplate;
