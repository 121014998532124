import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ButtonLoader } from "../../../../../constants/Constants";
import { post } from "../../../../../service/ApiService";

function Prefereneces({ data, getData, setFlag, flag, updateDetails, setUpdateDetails }) {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [fulltime, setFullTime] = useState(data.preferredSalary ? true : false);
  const [contract, setContract] = useState(data.minContractRate ? true : false);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [preference, setPreference] = useState({
    minContractRate: data.minContractRate,
    minContractRateCurrency: data.minContractRateCurrency,
    preferredSalaryCurrency: data.preferredSalaryCurrency,
    preferredSalary: data.preferredSalary,
    preferedLocations: JSON.parse(data?.preferredLocation),
    isRemote: data.isRemote,
  });

  const [tempPreference, setTempPreference] = useState({
    minContractRate: data.minContractRate,
    minContractRateCurrency: data.minContractRateCurrency,
    preferredSalaryCurrency: data.preferredSalaryCurrency,
    preferredSalary: data.preferredSalary,
    preferedLocations: JSON.parse(data?.preferredLocation),
    isRemote: data.isRemote,
  });

  useEffect(() => {
    !fulltime && setPreference({ ...preference, preferredSalary: "" });
    !contract && setPreference({ ...preference, minContractRate: "" });
  }, [fulltime, contract]);

  const savePreference = async () => {
    if (fulltime && preference.preferredSalary === "") {
      toast.error("please enter expected annual salary");
      return;
    }
    if (contract && preference.minContractRate === "") {
      toast.error("please enter expected hourly salary");
      return;
    }
    setTempPreference(preference);
    setSaveClicked(false);
    setSubmittingFrom(true);

    try {
      const parameters = {
        ...data,
        zipcode: data.zipCode,
        // experiences: data.experience,
        // educations: data.education,
        minContractRate: preference.minContractRate,
        minContractRateCurrency: preference.minContractRateCurrency,
        preferredSalaryCurrency: preference.preferredSalaryCurrency,
        preferredSalary: !fulltime ? 0 : preference.preferredSalary,
        preferredLocation: JSON.stringify(preference?.preferedLocations),
        isRemote: preference?.isRemote,
      };

      const res = await post("/candidate/updatetalent", parameters);
      if (res.status === 200) {
        setSubmittingFrom(false);
        setShowSuccessMessage(true);
        // getData();
        setUpdateDetails(!updateDetails);
        setTimeout(() => {
          setReadOnlyMode(true);
          setShowSuccessMessage(false);
        }, 1000);
        // setFlag(!flag);
        toast.success("Updated successfully!");
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };

  // const deleteExperience = (index) => {
  //   let ex = [...experience];
  //   ex.splice(index, 1);
  //   setExperience(ex);
  // };

  const editExperience = () => {
    setReadOnlyMode(false);
    setSaveClicked(true);
  };

  const cancelExperience = () => {
    setPreference({
      minContractRate:
        data.salaryType === "Hourly"
          ? data.preferredSalary
          : data.minContractRate,
      minContractRateCurrency: data.minContractRateCurrency,
      preferredSalaryCurrency: data.preferredSalaryCurrency,
      preferredSalary: data.preferredSalary,
      preferedLocations: JSON.parse(data?.preferredLocation),
      isRemote: data.isRemote,
    });
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
    setFullTime(data.preferredSalary ? true : false);
    setContract(data.minContractRate ? true : false);
  };

  const handleChange = (e) => {
    setPreference({ ...preference, [e.target.name]: e.target.value });
  };

  // const handleChange = (e) => {
  //   if (
  //     e.target.name === "preferredSalary" ||
  //     e.target.name === "minContractRate"
  //   ) {
  //     const { value, name } = e.target;
  //     if (name === "minContractRate") {
  //       const minContractRate = parseFloat(value);
  //       const preferredSalary = parseFloat(
  //         preference.preferredSalary || 0
  //       );
  //       if (minContractRate > preferredSalary) {
  //         toast.error("Minimum contract rate cannot exceed preferred salary");
  //         return;
  //       }
  //     }
  //         } setPreference({ ...preference, [e.target.name]: e.target.value });
  //       };

  var currency_list = [
    { code: "AFA" },
    { code: "ALL" },
    { code: "DZD" },
    { code: "AOA" },
    { code: "ARS" },
    { code: "AMD" },
    { code: "AWG" },
    { code: "AUD" },
    { code: "AZN" },
    { code: "BSD" },
    { code: "BHD" },
    { code: "BDT" },
    { code: "BBD" },
    { code: "BYR" },
    { code: "BEF" },
    { code: "BZD" },
    { code: "BMD" },
    { code: "BTN" },
    { code: "BTC" },
    { code: "BOB" },
    { code: "BAM" },
    { code: "BWP" },
    { code: "BRL" },
    { code: "GBP" },
    { code: "BND" },
    { code: "BGN" },
    { code: "BIF" },
    { code: "KHR" },
    { code: "CAD" },
    { code: "CVE" },
    { code: "KYD" },
    { code: "XOF" },
    { code: "XAF" },
    { code: "XPF" },
    { code: "CLP" },
    { code: "CNY" },
    { code: "COP" },
    { code: "KMF" },
    { code: "CDF" },
    { code: "CRC" },
    { code: "HRK" },
    { code: "CUC" },
    { code: "CZK" },
    { code: "DKK" },
    { code: "DJF" },
    { code: "DOP" },
    { code: "XCD" },
    { code: "EGP" },
    { code: "ERN" },
    { code: "EEK" },
    { code: "ETB" },
    { code: "EUR" },
    { code: "FKP" },
    { code: "FJD" },
    { code: "GMD" },
    { code: "GEL" },
    { code: "DEM" },
    { code: "GHS" },
    { code: "GIP" },
    { code: "GRD" },
    { code: "GTQ" },
    { code: "GNF" },
    { code: "GYD" },
    { code: "HTG" },
    { code: "HNL" },
    { code: "HKD" },
    { code: "HUF" },
    { code: "ISK" },
    { code: "INR" },
    { code: "IDR" },
    { code: "IRR" },
    { code: "IQD" },
    { code: "ILS" },
    { code: "ITL" },
    { code: "JMD" },
    { code: "JPY" },
    { code: "JOD" },
    { code: "KZT" },
    { code: "KES" },
    { code: "KWD" },
    { code: "KGS" },
    { code: "LAK" },
    { code: "LVL" },
    { code: "LBP" },
    { code: "LSL" },
    { code: "LRD" },
    { code: "LYD" },
    { code: "LTL" },
    { code: "MOP" },
    { code: "MKD" },
    { code: "MGA" },
    { code: "MWK" },
    { code: "MYR" },
    { code: "MVR" },
    { code: "MRO" },
    { code: "MUR" },
    { code: "MXN" },
    { code: "MDL" },
    { code: "MNT" },
    { code: "MAD" },
    { code: "MZM" },
    { code: "MMK" },
    { code: "NAD" },
    { code: "NPR" },
    { code: "ANG" },
    { code: "TWD" },
    { code: "NZD" },
    { code: "NIO" },
    { code: "NGN" },
    { code: "KPW" },
    { code: "NOK" },
    { code: "OMR" },
    { code: "PKR" },
    { code: "PAB" },
    { code: "PGK" },
    { code: "PYG" },
    { code: "PEN" },
    { code: "PHP" },
    { code: "PLN" },
    { code: "QAR" },
    { code: "RON" },
    { code: "RUB" },
    { code: "RWF" },
    { code: "SVC" },
    { code: "WST" },
    { code: "SAR" },
    { code: "RSD" },
    { code: "SCR" },
    { code: "SLL" },
    { code: "SGD" },
    { code: "SKK" },
    { code: "SBD" },
    { code: "SOS" },
    { code: "ZAR" },
    { code: "KRW" },
    { code: "XDR" },
    { code: "LKR" },
    { code: "SHP" },
    { code: "SDG" },
    { code: "SRD" },
    { code: "SZL" },
    { code: "SEK" },
    { code: "CHF" },
    { code: "SYP" },
    { code: "STD" },
    { code: "TJS" },
    { code: "TZS" },
    { code: "THB" },
    { code: "TOP" },
    { code: "TTD" },
    { code: "TND" },
    { code: "TRY" },
    { code: "TMT" },
    { code: "UGX" },
    { code: "UAH" },
    { code: "AED" },
    { code: "UYU" },
    { code: "USD" },
    { code: "UZS" },
    { code: "VUV" },
    { code: "VEF" },
    { code: "VND" },
    { code: "YER" },
    { code: "ZMK" },
  ];

  return (
    <>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Preferences</h6>
            {readOnlyMode === true ? (
              <button
                type="button"
                class="btn btn-text"
                onClick={editExperience}
              >
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={cancelExperience}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={savePreference}
                  class="btn btn-sm btn-primary"
                >
                  {saveClicked && <span>Save Changes</span>}

                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img width="20px" src={ButtonLoader} alt="" />
                    </span>
                  )}
                  {showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          <hr />
          {readOnlyMode ? (
            <div class="">
              <div class="mr-3 d-flex flex-column align-items-center">
                <div class="view-panel-spacer mt-1"></div>
              </div>
              <div class=" pb-3 ">
                <div class="d-flex mb-1 border-bottom">
                  <div className="col-10">
                    <p>Preferred annual salary</p>
                  </div>{" "}
                  <div className="col-2 text-end">
                    <p>
                      {preference.preferredSalary && fulltime
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: preference.preferredSalaryCurrency
                              ? `${preference.preferredSalaryCurrency.slice(
                                  0,
                                  3
                                )}`
                              : "USD",
                            maximumSignificantDigits: 3,
                          }).format(preference.preferredSalary)
                        : "NA"}{" "}
                    </p>
                  </div>{" "}
                  <span class="font-regular ml-1 col-1">
                    {/* {e.industry ? `(${e.industry})` : null} */}
                  </span>
                </div>
                <div class="d-flex mb-1 border-bottom">
                  <div className="col-10">
                    <p>Preferred hourly salary</p>
                  </div>{" "}
                  <div className="col-2 text-end">
                    <p>
                      {preference.minContractRate
                        ? new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: preference.minContractRateCurrency
                              ? `${preference.minContractRateCurrency.slice(
                                  0,
                                  3
                                )}`
                              : "USD",
                            maximumSignificantDigits: 3,
                          }).format(preference.minContractRate)
                        : "NA"}
                    </p>
                  </div>{" "}
                  <span class="font-regular ml-1 col-1"></span>
                </div>

                <div class="d-flex mb-1 border-bottom">
                  <div className="col-10">
                    <p> Open for remote?</p>
                  </div>{" "}
                  <div className="col-2 text-end">
                    <p>{preference?.isRemote ? "Yes" : "No"}</p>
                  </div>{" "}
                </div>
              </div>
            </div>
          ) : (
            <div class="work-experience">
              <div class="d-flex">
                <div className="w-100">
                  <div class=" d-flex align-items-center">
                    <div className="mr-2 mt-3 col-11">
                      <p>I'm intersted in Full-Time Job Types</p>
                    </div>
                    <div className="form-check form-switch col-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="isSelect"
                        checked={fulltime}
                        onChange={(e) => setFullTime(e.target.checked)}
                      />
                    </div>
                  </div>
                  {fulltime && (
                    <div class="row mb-2">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            name="preferredSalary"
                            placeholder="Preferred Salary"
                            value={preference.preferredSalary}
                            onChange={handleChange}
                          />
                          <label>
                            Let us know your preferred annual salary*
                          </label>
                        </div>
                      </div>{" "}
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <select
                            class="form-select"
                            id="preferredSalaryCurrency"
                            name="preferredSalaryCurrency"
                            onChange={handleChange}
                            value={preference.preferredSalaryCurrency}
                            disabled={
                              preference.preferredSalary > 0 ? false : true
                            }
                          >
                            <option value="" selected disabled>
                              Select Type
                            </option>

                            {currency_list.map((item) => (
                              <option value={item.code}>{item.code}</option>
                            ))}
                          </select>
                          <label>
                            Currency Type
                            {preference.preferredSalary > 0 && "*"}
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                  <div class=" d-flex align-items-center">
                    <div className="mr-2 mt-3 col-11">
                      <p>I'm intersted in Contract Job Types</p>
                    </div>
                    <div className="form-check form-switch col-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="isSelect"
                        checked={contract}
                        onChange={(e) => setContract(e.target.checked)}
                      />
                    </div>
                  </div>
                  {contract && (
                    <div class="row mb-2">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            name="minContractRate"
                            placeholder="Employer Name"
                            value={preference.minContractRate}
                            onChange={handleChange}
                          />
                          <label>
                            Let us know your preferred hourly salary*
                          </label>
                        </div>
                      </div>{" "}
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <select
                            class="form-select"
                            id="minContractRateCurrency"
                            name="minContractRateCurrency"
                            onChange={handleChange}
                            value={preference.minContractRateCurrency}
                            disabled={
                              preference.minContractRate > 0 ? false : true
                            }
                          >
                            <option value="" selected disabled>
                              Select Type
                            </option>

                            {currency_list.map((item) => (
                              <option value={item.code}>{item.code}</option>
                            ))}
                          </select>
                          <label>
                            Currency Type
                            {preference.minContractRate > 0 && "*"}
                          </label>
                        </div>
                      </div>
                      {/* <hr className="mt-4" /> */}
                    </div>
                  )}

                  <div class=" d-flex align-items-center">
                    <div className=" mr-2 mt-3 col-11">
                      <p>Open for remote?</p>
                    </div>
                    <div className="form-check form-switch col-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="isSelect"
                        checked={preference?.isRemote}
                        onChange={(e) =>
                          setPreference({
                            ...preference,
                            isRemote: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Prefereneces;
