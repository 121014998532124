import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";
import { Modal } from "react-bootstrap";
import sparkles from "../../../../assets/lottie/sparkles.json";
import checkMark from "../../../../assets/lottie/checkmark.json";
import DeactivateEmployee from "./DeactivateEmployee";
import { getEmployeeDetails } from "../../../../actions/employees/Employees";
import { getUserTenantID } from "../../../../service/AuthService";

const { Anime } = ReactAnime;
const Index = (props) => {
  const { deletePopup, employeeData, roleNames } = props;
  const dispatch = useDispatch();
  const [submit, setsubmit] = useState(false);
  const [loop] = useState(false);
  const [empDetails, setEmpDetails] = useState({});
  const submitfun = (empdata) => {
    setEmpDetails(empdata);
    setsubmit(true);
  };

  const close = () => {
    props.handleSuccess();
  };

  const closeSuccessModel = () => {
    props.handleSuccess();
    const params = {
      tenantId: getUserTenantID(),
    };
    dispatch(getEmployeeDetails(params));
  };

  return (
    <Modal show={deletePopup} size="delete">
      <div className="bl-modal-header pb-4">
        {!submit && (
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={close}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>

      <Modal.Body>
        {submit == false ? (
          <DeactivateEmployee
            close={close}
            submitfun={submitfun}
            employeeDelete={"employeedelete"}
            employeeData={employeeData}
          />
        ) : (
          <div
            className="section section-sm pb-0 d-block"
            style={{ display: "none" }}
          >
            <div
              id="EmployeeManageSuccessAnimation"
              className="success-animation"
            >
              <div className="checkmark">
                <Lottie loop={loop} animationData={checkMark} play></Lottie>
              </div>
              <Anime
                initial={[
                  {
                    targets: "#LottieSparkles",
                    opacity: 0,
                    delay: 1000,
                  },
                ]}
              >
                <div className="sparkles">
                  <Lottie
                    loop={loop}
                    animationData={sparkles}
                    id="LottieSparkles"
                    play
                  ></Lottie>
                </div>
              </Anime>
            </div>
            <div className="success-message" style={{ opacity: "1" }}>
              <div className="container">
                <div className="col-lg-12">
                  <h4 className="text-center">User Deactivated!</h4>
                  <div className="text-center">
                    The selected user has been Deactivated
                  </div>
                  <div className="card card-flat card-lg">
                    <div className="card-body">
                      <div className="mb-2">
                        <label className="d-block font-bold pt-0">
                          User Name
                        </label>
                        <div>{empDetails.name}</div>
                      </div>
                      <div className="mb-2">
                        <label className="d-block font-bold pt-0">Email</label>
                        <div>{empDetails.email}</div>
                      </div>
                      <div className="mb-2">
                        <label className="d-block font-bold pt-0">Role</label>
                        <div>
                          {" "}
                          {empDetails.isOwner
                            ? `${roleNames[empDetails.designation]} Admin`
                            : roleNames[empDetails.designation]}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center py-4">
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-secondary mr-2"
                      onClick={closeSuccessModel}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default Index;
