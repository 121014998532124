import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getUserRole } from "../../../../../../service/AuthService";
import DisqualifyPopUp from "../disqualified/DisqualifyPopUp";
import ResumeDownload from "../resume/Index";
import { getDispositionDetails } from "../../../../../../actions/disposition/Disposition";
import { getDispositionDisqualify } from "../../../../../../actions/disposition/DispositionDisqualified";
import TalentProfile from "../../../../talent/talentprofile/TalentProfile";
import EmailStatus from "../../../../EmailStatus/EmailStatus";
import { getDispositionCount } from "../../../../../../actions/disposition/DispositionCount";
import ProfileImage from "../../../../commoncomponents/profileimage/ProfileImage";

const Offered = (props) => {
  const { offeredData, jobData, view } = props;
  const dispatch = useDispatch();
  const { Id } = useParams();
  const [disqualifyPopup, setDisqualifyPopup] = useState(false);
  const [disqualifyData, setDisqualifyData] = useState({});
  const [resume, setResume] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState({});

  const handleResume = (data) => {
    setResumeData(data);
    setResume(true);
  };

  const onDisqualifyClick = (data) => {
    setDisqualifyPopup(true);
    setDisqualifyData(data);
  };

  const reloadDisposition = () => {
    const payload = {
      jobId: Id,
    };
    const payloadDisposition = {
      jobId: Id,
      status: view,
      limit: 15,
      currentPage: 1,
    };
    const payloadDisqualify = {
      jobId: Id,
      status: "disqualified",
      limit: 15,
      currentPage: 1,
    };
    dispatch(getDispositionDisqualify(payloadDisqualify));
    dispatch(getDispositionDetails(payloadDisposition));
    dispatch(getDispositionCount(payload));
    setDisqualifyPopup(false);
  };

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setCandidateData(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };

  return (
    <>
      {offeredData.map((item, i) => (
        <tr key={i}>
          {/* <td onClick={() => onProfileClick(item)}> */}
          <td
            onClick={() => onProfileClick(item)}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ProfileImage
              imageSrc={item?.profileImagePath}
              imageWidth={"35"}
              imageHeight={"35"}
              avatarSize={"35"}
              avatarRound={"80px"}
              firstName={item?.firstName}
              lastName={item?.lastName}
            />
            <span className="ml-2 font-16">
              {item?.firstName.charAt(0).toUpperCase() +
                item?.firstName.slice(1)}{" "}
              {item?.lastName.charAt(0).toUpperCase() + item?.lastName.slice(1)}
            </span>
          </td>
          <td className="no-hover">
            <label className="d-none d-block-1024">
              <strong>Updated On</strong>
            </label>
            {moment(new Date(item.offeredDate)).format("MM/DD/YYYY")}
          </td>
          <td>Pending</td>
          <td className="no-hover">
            <label className="d-none d-block-1024">
              <strong>Resumes</strong>
            </label>
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Resume Download"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewResumeDocModal"
                className="btn btn-square btn-gray"
                onClick={() => handleResume(item)}
              >
                <i className="fas fa-file-user" />
              </button>
            </div>
          </td>{" "}
          {getUserRole() === "HiringManager" && (
            <td className="no-hover">
              <div
                className="d-inline-block"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View Email Status"
              >
                <div className="btn-container">
                  <button
                    type="button"
                    className="btn btn-square btn-gray"
                    onClick={() => {
                      setEmailData(item?.email);
                      setEmailStatus(true);
                      setSelectedTalent(item);
                    }}
                  >
                    <i className="fa fa-envelope-o" />
                  </button>

                  {item?.latestEmailStatus &&
                    item?.latestEmailStatus !== "noMail" && (
                      <span
                        className={`${
                          item?.latestEmailStatus === "delivered"
                            ? "dot-success"
                            : "dot-failure"
                        }`}
                      />
                    )}
                </div>
              </div>
            </td>
          )}
          <td className="no-hover">
            {
              <span className="">
                {props.calculateScore(jobData, item) + "%"}
              </span>
            }
          </td>
          <td className="no-hover text-right font-16">
            {(getUserRole() === "High5Coordinator" ||
              getUserRole() === "HiringManager") &&
              jobData.jobStatus !== 23 &&
              jobData.jobStatus !== 26 &&
              jobData.jobStatus !== 39 && (
                <button
                  type="button"
                  onClick={() => onDisqualifyClick(item)}
                  className="btn btn-sm"
                >
                  Disqualify
                </button>
              )}
          </td>
        </tr>
      ))}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="Offered"
          invitedDate={selectedTalent?.invitedDate}
          jobData={jobData}
        />
      )}
      {resume && (
        <ResumeDownload
          resume={resume}
          setResume={setResume}
          resumeData={resumeData}
        />
      )}

      {disqualifyPopup && (
        <DisqualifyPopUp
          disqualifyPopup={disqualifyPopup}
          setDisqualifyPopup={setDisqualifyPopup}
          disqualifyData={disqualifyData}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          candidateData={candidateData}
          handleSuccess={handleModel}
          jobData={jobData}
          onDisqualifyClick={onDisqualifyClick}
          parent="offerTab"
          isFrom="offerTab"
        />
        // parent="submitTab"
        // isFrom="submitTab"/>
      )}
    </>
  );
};

export default Offered;
