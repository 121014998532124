import moment from "moment";
import React, { useEffect, useState } from "react";
import Pagination from "../../../../components_v3/pagination/pagination";
import Sort from "../../../commoncomponents/sort/Index";
import { jobSummaryObj, orderList } from "../../../pagedata/PageData";

const JobTable = ({
  isFrom,
  prefix,
  jobs,
  dataTotal,
  dataSize,
  handleSizeChange,
  handlePageChange,
  currentPage,
  handleSearch,
  searchData,
  fetchData,
  sortArray,
  setSortArray,
  applySort,
  tenantId,
}) => {
  const [searchValue, setSearchValue] = useState("");
  //sort
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);

  useEffect(() => {
    const tempArray = jobSummaryObj.map((item) => item.value);
    setSorttype(tempArray);
  }, []);

  const handleClearInput = () => {
    setSearchValue("");
  };

  useEffect(() => {
    resetSort();
  }, [prefix]);

  const resetSort = () => {
    setSortArray([]);
    const tempArray = jobSummaryObj?.map((item) => item.value);
    setSorttype(tempArray);
    setSortCount(0);
    setSortApplied(false);
    setSort(false);
  };

  const sortClearAll = () => {
    resetSort();
    fetchData(prefix, 1, 10, tenantId, searchData, []);
  };

  return (
    <div className="card mt-3">
      <div className="card-body">
        <div class="p-3">
          <div class=" mb-2 ">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h6 class="m-0">{!isFrom && prefix}</h6>
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <select
                  class="form-select mb-2"
                  onChange={handleSizeChange}
                  value={dataSize}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
                <div class="mb-2 mr-1 d-flex align-items-center justify-content-end col-md-8">
                  <div className="search-input-container">
                    <input
                      type="text"
                      placeholder="Search Jobs"
                      className="form-control small font-14"
                      value={searchData}
                      onChange={handleSearch}
                    />
                  </div>
                  <Sort
                    sort={sort}
                    setSort={setSort}
                    sortCount={sortCount}
                    sortData={jobs}
                    orderArray={orderList}
                    sortObjects={jobSummaryObj}
                    applySort={applySort}
                    setSortCount={setSortCount}
                    setSortArray={setSortArray}
                    sortArray={sortArray}
                    sortingFor={
                      "Sort by Job Title, Job Type, Created On, and Expires On"
                    }
                    sortType={sortType}
                    setSorttype={setSorttype}
                    sortApplied={sortApplied}
                    setSortApplied={setSortApplied}
                    sortClearAll={sortClearAll}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="pt-0 mb-3 table-cards-1024">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Job title</th>
                  <th scope="col">Job type</th>
                  <th scope="col">Work place</th>
                  <th scope="col" style={{ textAlign: "center" }}>Openings</th>
                  <th scope="col">Created on</th>
                  <th scope="col">Expires on</th>
                </tr>
              </thead>

              <tbody>
                {jobs &&
                  jobs?.map((item) => (
                    <tr>
                      <td>
                        <span>{item.jobTitle}</span>
                      </td>
                      <td>{item.jobType}</td>
                      <td>{item.workPlaceType}</td>
                      <td style={{ textAlign: "center" }}>{item.positionCount}</td>
                      <td>
                        <span>
                          {moment(new Date(item.createdDateTime)).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </td>{" "}
                      <td>
                        <span>
                          {moment(new Date(item.expiresOn)).format(
                            "MM/DD/YYYY"
                          )}
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
            {jobs && jobs?.length === 0 && (
              <div>
                <div class="text-center p-3">
                  <div class="avatar avatar-lg">
                    <i aria-hidden="true" class="fad fa-folder-open"></i>
                  </div>{" "}
                  <div class="mt-6">No records found</div>
                </div>
              </div>
            )}
            <hr class="m-0" />
            {jobs && jobs?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalItems={dataTotal}
                itemsPerPage={dataSize}
                onPageChange={handlePageChange}
                limit={10}
              />
            )}
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default JobTable;
