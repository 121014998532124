import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const FETCH_EMAIL_OVERVIEW_STATS_BEGIN =
  "FETCH_EMAIL_OVERVIEW_STATS_BEGIN";
export const FETCH_EMAIL_OVERVIEW_STATS_SUCCESS =
  "FETCH_EMAIL_OVERVIEW_STATS_SUCCESS";
export const FETCH_EMAIL_OVERVIEW_STATS_FAILURE =
  "FETCH_EMAIL_OVERVIEW_STATS_FAILURE";

export const FETCH_RECENT_EMAILS_BEGIN = "FETCH_RECENT_EMAILS_BEGIN";
export const FETCH_RECENT_EMAILS_SUCCESS = "FETCH_RECENT_EMAILS_SUCCESS";
export const FETCH_RECENT_EMAILS_FAILURE = "FETCH_RECENT_EMAILS_FAILURE";

export const getEmailOverviewStats = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_EMAIL_OVERVIEW_STATS_BEGIN,
    });
    const res = await post("/communication/fetchEmailOverview", inputData);
    if (res.status === 200) {
      dispatch({
        type: FETCH_EMAIL_OVERVIEW_STATS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_EMAIL_OVERVIEW_STATS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getEmailFetchRecentMails = (inputData) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_RECENT_EMAILS_BEGIN,
    });
    const res = await post("/communication/fetchRecentEmails", inputData);
    if (res.status === 200) {
      dispatch({
        type: FETCH_RECENT_EMAILS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_RECENT_EMAILS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
