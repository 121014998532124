import React, { useState } from "react";
import Loader from "../../../Loader/Loader";

const JobSearch = (props) => {
  const { searchText, setSearchText, getJob } = props;
  const [loading, setLoading] = useState(false);

  const handleSearchButtonClick = () => {
    getJob(1, [], searchText);
  };

  return (
    <div className="w-100 d-flex justify-content-center">
      <div className="w-50">
        {loading && <Loader />}
        <div id="SiteSearchField" className="search-field">
          <div className="field-wrap">
            <input
              style={{
                background: "#FFFFFF",
                boxShadow: "1px 1px 6px rgba(0, 0, 0, 0.25)",
                borderRadius: "3px",
                height: "37px",
                backgroundColor: "lightgray",
              }}
              type="text"
              className="form-control"
              placeholder="Search Jobs"
              aria-label="Username"
              aria-describedby="basic-addon1"
              list="jobSuggestions"
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && searchText !== "") {
                  handleSearchButtonClick();
                }
              }}
            ></input>
            <span className="icon">
              <i aria-hidden="true" className="fas fa-search"></i>
            </span>
          </div>
          <button
            className="btn btn-sm btn-primary ml-3"
            onClick={handleSearchButtonClick}
          >
            Search
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobSearch;
