import React, { useState, useEffect } from "react";
import imageLoader from "../../../../images/button-loader.gif";
import { post } from "../../../../service/ApiService";
import {
  getUserRole,
  getCandidateName,
  getUserName,
  getUserTenant,
} from "../../../../service/AuthService";
import { toast } from "react-toastify";
import { getInitial } from "../../../function/CommonFunction";
import { currency_list } from "../../../function/CommonFunction";
import { domain } from "../../../../constants/Constants";
import Loader from "../../../Loader/Loader";
import Avatar from "react-avatar";
import { ROW_SELECT_DISABLED } from "react-bootstrap-table-next";
import moment from "moment";

function MakeAnOfferPopUP(props) {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [loading, setLoading] = useState(false);
  const [talentEmailChecked, setTalentEmailChecked] = useState(false);
  const [formData, setFormData] = useState({
    expectedJoiningDate: new Date().setDate(new Date().getDate() + 30),
    salaryOffered: props.jobData.annualSalary,
    currencyType: props.jobData.placementCurrency,
    paymentType: props.jobData.salaryType,
  });
  const [errors, setError] = useState({});

  const jobData = props.jobData;
  const talentData = props.talentdata;
  const tenant = props.userTenant;
  const role = getUserRole();
  const userName = getCandidateName();

  let curDate = new Date();

  useEffect(() => {
    setCandidateData(props.selectCandidate);
  }, [props]);

  // const handleCheck = (e) => {
  //   if (e.target.checked) {
  //     setTalentEmailChecked(true);
  //   } else {
  //     setTalentEmailChecked(false);
  //   }
  // };

  const closeModal = () => {
    props.handleSuccess();
  };

  const candidateEmail = candidateData?.candidateEmail;
  const candidateName =
    candidateData?.candidateFname + " " + candidateData?.candidateLname;
  const clientName = jobData?.jobTenant;
  const jobId = jobData?.jobId;
  const jobTitle = jobData?.jobTitle;
  const candidateTenant = candidateData?.candidateTenant;
  //console.log(candidateEmail,candidateName,"email")

  // const MailOfferAPI = async (e) => {
  //   const params = {
  //     tenant,
  //     candidateEmail,
  //     candidateName,
  //     clientName,
  //     jobId,
  //     jobTitle,
  //     talentEmailChecked,
  //     candidateTenant,
  //   };
  //   try {
  //     const res = await post("/makeanoffer/getdataformail", params);
  //     if (res.status === 200) {
  //       props.setSuccessFormOpen(true);
  //       props.refreshPage();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  let submittedToClientBy = getCandidateName();
  let candidateId = candidateData?.candidateId;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isValidFunction = () => {
    let errorData = {};
    let formIsValid = true;

    if (formData.salaryOffered === "") {
      errorData["salaryOffered"] = "Field cannot be empty";
      formIsValid = false;
    }
    if (
      formData.expectedJoiningDate === "" ||
      formData.expectedJoiningDate === null
    ) {
      errorData["expectedJoiningDate"] = "Please select a date";
      formIsValid = false;
    }
    setError(errorData);
    return formIsValid;
  };

  const submitMakeAnOffer = async (e) => {
    const isValid = isValidFunction();

    if (isValid) {
      let path = `/careers/${candidateData?.tenantId}/profile/?tab=Offers`;
      let candidateProfileLink = `${window.location.protocol}//${window.location.host}${path}`;

      const payload = {
        jobId: jobData.jobId,
        jobTitle: jobData.jobTitle,
        candidateId: candidateId,
        offeredRole: role,
        offeredBy: submittedToClientBy,
        offeredEmail: getUserName(),
        OfferedDate: new Date().toISOString(),
        talentData,
        talentEmailChecked,
        candidateEmail,
        candidateName,
        recruiterMail: candidateData.recruiterEmail,
        recruiterName: candidateData.recruiterName,
        clientName: clientName,
        domainName: candidateProfileLink,
        joiningDate: moment(formData.expectedJoiningDate).format("YYYY-MM-DD"),
        salaryOffered: formData.salaryOffered,
        salaryCurrency: formData.currencyType,
        salaryType: formData.paymentType,
        candidateTenant: candidateTenant,
      };

      try {
        setLoading(true);
        const res = await post(
          "/jobcandidate/updateofferpendingstatus",
          payload
        );
        if (res.status === 200) {
          toast.success("Job offered successfully");
          props.refreshPage();
          props.handleSuccess();
          setTimeout(() => {
            window.location.reload();
          }, 5000);
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Error Occurred while accessing Talent Details");
        }
      } catch (error) {
        console.log(error, "err@MakeAnOffer");
        setLoading(false);
        toast.error("An error occurred");
      }
      setSubmitClicked(true);
      // const tenant = { tenant: props.userTenant }
      //  const candidateEmail = {candidateEmail: talentData.candidateEmail}
      //MailOfferAPI(tenant, candidateEmail, candidateName);
      setSubmitClicked(true);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="bl-modal-header">
        <h6 className="bl-modal-title">
          {props.isFrom === "DisqualifiedCandidates"
            ? "Re-Offer"
            : "Make an Offer"}
        </h6>
        <button
          type="button"
          onClick={closeModal}
          className="close p-0 bl-modal-close-btn"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div>
        <div className="text-center">
          {candidateData?.ImageURL ? (
            <span>
              <img className="avatar" alt="" src={props.talentdata.ImageURL} />
              <span className="ml-2">
                {candidateData?.firstName} {candidateData?.lastName}{" "}
              </span>
            </span>
          ) : (
            <span>
              <Avatar
                className="mb-1"
                size="55"
                name={
                  candidateData?.candidateFname +
                  " " +
                  candidateData?.candidateLname
                }
                round="90px"
              />
              {/* {getInitial(`${talentData[0].candidateFname} ${talentData[0].candidateLname}`)} */}
            </span>
          )}
        </div>
      </div>
      <div>
        <div className="text-center">
          <h6 className="mt-3">
            {candidateData?.candidateFname} {candidateData?.candidateLname}{" "}
          </h6>
          <p>
            {jobData.isRemote === true ? (
              <span className="mb-1 mr-1">Remote</span>
            ) : (
              <span className="mb-1 mr-1">{props.jobData.location.city}</span>
            )}{" "}
            /
            {
              <span className="mb-1 mr-1">
                {} {jobData.jobTenant}
              </span>
            }{" "}
            /<span className="mb-1 mr-1">{} </span>
            {/* {jobData.jobType === "Full Time"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: jobData.placementCurrency
                    ? `${jobData.placementCurrency}`
                    : "USD",
                  maximumSignificantDigits: 3,
                }).format(jobData.annualSalary)
              : new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: jobData.placementCurrency
                    ? `${jobData.placementCurrency}`
                    : "USD",
                  maximumSignificantDigits: 3,
                }).format(jobData.hourlyRate)} */}
            {
              <span className="mb-1 mr-1">
                {" "}
                {} {jobData.jobType}
              </span>
            }
          </p>
        </div>
      </div>

      <div style={{ padding: "1px 2px 35px 0px" }}></div>

      <div v-if="showMakeAnOfferForm">
        <div className="bl-modal-body pt-0 makeAnOfferPopUp">
          <p className="text-center">
            <strong>
              Please confirm that you wish to submit an offer to this candidate.
            </strong>
          </p>
        </div>
        <div className="card card-flat bg-gray4 mb-5 ml-2  mr-2">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-4">
                <div className="form-floating form-date">
                  <label>Currency</label>
                  <input
                    type="text"
                    className="form-control"
                    name="currencyType"
                    defaultValue={formData.currencyType}
                    disabled={true}
                  />
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-floating form-date">
                  <label>Offered Salary*</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter the salary"
                    name="salaryOffered"
                    onChange={handleChange}
                    defaultValue={formData.salaryOffered}
                  />
                  <div className="color">{errors["salaryOffered"]}</div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="form-floating form-date">
                  <label>Payment Type</label>
                  <input
                    type="text"
                    className="form-control"
                    name="salaryType"
                    defaultValue={formData.paymentType}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6">
                <div className="form-floating form-date">
                  <label>Joining Date*</label>
                  <input
                    type="date"
                    name="expectedJoiningDate"
                    value={moment(formData.expectedJoiningDate).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={handleChange}
                    className="form-control"
                    min={moment(new Date()).format("YYYY-MM-DD")}
                    max={moment(
                      new Date(curDate.setDate(curDate.getDate() + 90))
                    ).format("YYYY-MM-DD")}
                  />

                  <div className="color">{errors["expectedJoiningDate"]}</div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-floating form-date">
                  <label>Gig Recruiter/Recruitment Firm</label>
                  <input
                    type="text"
                    className="form-control"
                    name="recruiterName"
                    defaultValue={
                      candidateData?.recruiterTenant !== undefined &&
                      candidateData?.recruiterTenant.includes("GIG - ")
                        ? candidateData?.recruiterTenant.replace("GIG - ", "")
                        : candidateData?.recruiterTenant
                    }
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            data-dismiss="modal"
            onClick={closeModal}
            disabled={submitClicked}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary btn-sm">
            {submitClicked === false ? (
              <span onClick={submitMakeAnOffer}>Submit Offer</span>
            ) : (
              <span>
                Submitting Offer
                <img width="20px" src={imageLoader} alt="blank" />
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  );
}
export default MakeAnOfferPopUP;
