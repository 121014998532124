import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { domain } from "../../../constants/Constants";
import { post } from "../../../service/ApiService";
import Footer from "../../Footer/Footer";
import JoinCommunity from "../../Footer/JoinCommunity";
import ApplyNow from "./ApplyNow";
import JobDescription from "./JobDescription";
import TermsAndConditions from "./TermsAndConditions";
import Modal from "react-bootstrap/Modal";
import GoogleLogin from "react-google-login";
import { FcGoogle } from "react-icons/fc";
import { LoginSocialFacebook } from "reactjs-social-login";
import { BsFacebook } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import PhoneInput from "react-phone-input-2";
import { AsYouType, parse } from "libphonenumber-js";
import TagsInput from "react-tagsinput";
import Autosuggest from "react-autosuggest";
import { dropDownData } from "../../directsourcing/Edit Talent DS/dropDownData";
import ApplyJob from "../../directsourcing/career/ApplyJob";
import AddTalentDS from "../../directsourcing/career/AddTalentDS";
import AutoMatchScoreCardDS from "../../directsourcing/career/AutoMatchScoreCardDS";
import { useHistory } from "react-router-dom";
import {
  getCandidateName,
  getUserEmail,
  getUserName,
  getUserTenantID,
} from "../../../service/AuthService";
import axios from "axios";
import EditTalentDS from "../../directsourcing/Edit Talent DS/EditTalentDS";
import TopNavbar from "../../directsourcing/career/TopNavbar";
import Loader from "../../Loader/Loader";
import GoogleLocationCityAPI from "../../location/GoogleLocationCityAPI";

const JobApplyComponent = () => {
  const history = useHistory();
  const { id, poolId, tenant, reffererid, recruiterid } = useParams();
  const searchInput = useRef(null);
  localStorage.setItem("reffererid", reffererid);

  var token = sessionStorage.getItem("token");
  const recruiterName = getCandidateName();
  const recruiterEmail = getUserName();
  const [job, setJob] = useState({});
  const [skillsList, setSkillsList] = useState([]);
  const [jobValid, setJobValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [login, setLogin] = useState(false);
  const [joinCommunity, setJoinCommunity] = useState(false);
  const [isLogIn, setIsLogIn] = useState(false);
  const [tenantName, setTenantName] = useState("");
  const [isApplyJobModal, setIsApplyJobModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [askResume, setAskResume] = useState(false);
  const [editTalent, setEditTalent] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [addTalentData, setAddTalentData] = useState({});
  const [scoreCard, setScoreCard] = useState(false);
  const [applyWithEmail, setApplyWithEmail] = useState(false);
  const [loginWithEmail, setLoginWithEmail] = useState(false);
  const [emailExist, setEmailExist] = useState(false);
  const [newUser, setNewUser] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [password, setPassword] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);
  const [tenantData, setTenantData] = useState({});
  const [legalAuth, setLegalAuth] = useState(false);
  const [parsedData, setParsedData] = useState({});
  const [parsed, setParsed] = useState(false);
  const [email, setEmail] = useState("");
  const [reffererName, setReffererName] = useState("");
  const [sourceChannel, setSourceChannel] = useState("");
  const [recruiterAgency, setRecruiterAgency] = useState("");
  const [fetchVettingData, setFetchVettingData] = useState([]);
  const [vettingData, setVettingData] = useState([]);
  const [vettingLater, setVettingLater] = useState(false);
  const [screeningQuestions, setScreeningQuestions] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [vettingNow, setVettingNow] = useState(false);
  const [isVetting, setIsVetting] = useState(false);
  const [recruiterData, setRecruiterData] = useState({});
  const [candidateId, setCandidateId] = useState("");
  const [createProfile, setCreateProfile] = useState(false);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  // const [resumeFile, setResumeFile] = useState("");
  const [screeningAnswers, setScreeningAnswers] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [newInvite, setNewInvite] = useState(false);
  const [vettingIdName, setVettingIdName] = useState([]);

  // TrackingApplyProcess

  const [credentials, setCredentials] = useState({
    userName: "",
    password: "",
  });
  const data = {
    fName: "",
    lName: "",
    email: "",
    country: "United States",
    phone: "",
    phoneValidation: true,
    alternatePhone: "",
    highestEducation: "Bachelor's Degree",
    city: "",
    location: "",
    currentPosition: "",
    currentEmployer: "",
    experienceYear: "0",
    experienceMonth: "0",
    legallyAuthorized: false,
    requireSponsorship: false,
    primarySkills: [],
  };
  const educationTypes = [
    "Bachelor's Degree",
    "Certification",
    "Doctorate",
    "Highschool or Equivalent",
    "Master's Degree",
    "NA",
    "Professional",
    "Some College Coursework Completed",
    "Some Highschool Course Work",
    "Vocational",
  ];
  const [candidateDetails, setCandidateDetails] = useState(data);
  const [candidateStatusInput, setCandidateStatusInput] = useState("");

  const [trackedData, setTrackedData] = useState({
    jobId: id,
    recruiterId: recruiterid,
    tenantId: tenant,
    userType: "Candidate",
    navigatedPages: window.location.href, //+ "apply" + "profile" + "scorecard",
    action: "applyJob",
    automatched: false,
    firstName: "",
    lastName: "",
    data: { ...candidateDetails, resumeFileName: "" },
  });

  useEffect(() => {
    getJob();
    getTenant();
    getRecruiter();
    // eslint-disable-next-line
  }, []);

  const getTenant = async () => {
    const params = { tenantId: tenant };
    setLoading(true);
    const res = await post("/career/gettenant", params).then((res) => {
      setTenantData(res.data[0]);
      setLoading(false);
    });
  };

  const getJob = async () => {
    const params = { domain: domain, jobId: id };
    const params1 = { id_talentPool: poolId };
    if (poolId) {
      const resp = await post("/talentpool/getPoolByPoolId", params1);
      setJob(resp?.data[0]);
      setSkillsList(JSON.parse(resp?.data[0]?.skills));
    } else {
      const res = await post("/career/getrequestdetailsbyIdDS", params);
      setJobValid(true);
      setJob(res.data.jobData);
    }
  };

  const reviewer = {
    firstName: recruiterData?.firstName,
    lastName: recruiterData?.lastName,
    reviewerEmail: recruiterData?.email,
    instructions: "",
  };

  const vettingMail = async (event, vetIds, id) => {
    setLoading(true);
    if (fetchVettingData.length > 0) {
      let body = {
        reviewer: reviewer,
        createdBy: {
          id: recruiterData.ID_user,
          firstName: recruiterData.firstName,
          lastName: recruiterData.lastName,
          role: "user",
        },
        modifiedBy: {
          id: "62fb9ad4cb6714dd6540e2cf",
          firstName: "John",
          lastName: "Wang",
          role: "user",
        },
        companyInfo: { companyName: tenantData.tenantname },
        name: addTalentData.firstName,
        firstName: addTalentData.firstName,
        lastName: addTalentData.lastName,
        email: addTalentData.email,
        recruiterEmail: "",
        high5hireCandidateId:
          sessionStorage.getItem("token") == null
            ? id
            : sessionStorage.getItem("userId"),
        jobId: job.jobId,
        jobTitle: job.jobTitle,
        testAssign: {
          // id: fetchVettingData[0],
        },

        testsAssign: vettingData,
        testInvited: true,
        expiryDays: 7,
        takesForTest: 3,
        tenantid: tenant,
      };
      const res = await post(`/vetting/candidate/add`, body);
      if (res.status === 200) {
        setLoading(false);
        event === "now" ? setVettingNow(true) : setVettingLater(true);
      }
    }
  };

  const applyNowOnClick = () => {
    if (sessionStorage.getItem("token") == null) {
      setLogin(true);
      setIsChecked(false);
      setIsApplyJobModal(false);
      setAskResume(false);
    } else {
      setLoading(true);
      let payload = { email: sessionStorage.getItem("userName"), jobId: id };
      post("/dsTalent/fetchNewTalentDetails", payload).then((res) => {
        if (res.data.status == "Invited" || res.data.status == "success") {
          if (res.data.candidateFname && res.data.candidateLname) {
            setNewInvite(false);
          } else {
            setNewInvite(true);
          }
          setEditTalent(true);
          fetchCandidate();
        } else if (res.data.status == "exist") {
          toast.error("You have already applied for this job");
          setLoading(false);
        } else {
          toast.error("Please try again");
          setLoading(false);
        }
      });
    }
  };

  const _onChangeText = (value, country) => {
    let a = country.name;
    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    const formattedNumber = asYouType.input(
      value.replace(country.dialCode, "")
    );
    const isValid = asYouType.isValid();
    setCandidateDetails({
      ...candidateDetails,
      country: a,
      phone: value,
      phoneValidation: isValid,
    });
  };

  const getRecruiter = async () => {
    const params = { recruiterid: recruiterid };
    setLoading(true);
    const res = await post("/career/getrecruiterbyid", params).then((res) => {
      setRecruiterData(res.data[0]);
      setLoading(false);
    });
  };

  const submitAddTalent = () => {
    if (sessionStorage.getItem("token") == null) {
      submitAddTalent2();
    } else {
      applyJob(sessionStorage.getItem("userId"));
    }
  };

  const onClickLater = (event, id) => {
    const arr = fetchVettingData.map((elem) => {
      return { testId: elem };
    });
    setVettingIdName(arr);
    vettingMail(event, arr, id);
  };

  const addRefferedCand = async () => {
    let date = new Date();
    setLoading(true);
    const params = {
      FirstName: addTalentData.firstName,
      LastName: addTalentData.lastName,
      Email: addTalentData.email,
      ref_candUniqueId: reffererid,
      jobTitle: job?.jobTitle,
      refDate: date,
      jobId: id,
    };
    const res = await post("/canReferal/updateCandidate", params)
      .then((res) => {
        setLoading(false);
        toast.success("Referal Candidate added");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Unable to add candidate");
      });
  };

  const fetchCandidate = async () => {
    setLoading(true);
    try {
      const payload = {
        domain: domain,
        candidateId: sessionStorage.getItem("userId"),
      };
      let res = await post("/dsTalent/searchtalentbyidDS", payload);
      // res.data.resumeURL && setResumeDownload(res.data.resumeURL);
      res.data && setCandidateData(res.data);
      setLoading(false);
    } catch (error) {
      toast.error("Error Occurred while accessing candidate details ");
      setLoading(false);
    }
  };

  const handleLogin = (name, url, email) => {
    setLogin(false);
    //toast.success("Logged In Successfully");
    localStorage.setItem("isLoggedIn", "Yes");
    localStorage.setItem("name", name);
    localStorage.setItem("url", url);
    localStorage.setItem("email", email);
    setToggle(false);
    setIsLogIn(true);
  };

  // Auto suggestion in tags input
  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 14px",
        width: "300px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.SourceName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue?.length;

      const suggestValues =
        inputLength === 0
          ? []
          : dropDownData.CandidateSkills.filter((item) =>
              item.SourceName.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion.SourceName}</div>;

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.SourceName);
        }}
        theme={theme}
      />
    );
  };

  const handleChangePrimarySkills = (skills) => {
    setCandidateDetails({ ...candidateDetails, primarySkills: skills });
  };

  const handleChangeInputPrimarySkills = (skills) => {
    setPrimarySkillInput(skills);
  };

  const showPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  // forgot password
  const forgetPassword = async (e) => {
    e.preventDefault();
    window.open(`/careers/forgot-passwordDS`, "_blank");
  };

  // Login user

  const loginUser = async () => {
    if (candidateDetails.email == "" || password == "") {
      toast.error(
        "There are a few incomplete required fields. Please complete them to continue. "
      );
    } else {
      const params = { userName: candidateDetails.email, password: password };
      setLoading(true);
      const res = await post("/candidateLogin/", params)
        .then((res) => {
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("name", res.data.name);
          sessionStorage.setItem("userId", res.data.userId);
          sessionStorage.setItem("userName", res.data.userName);
          setLogin(false);
          setEmailExist(false);
          setEditTalent(true);
          fetchCandidate();
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Please check credentials");
        });
    }
  };

  // CHeck email exists or not on apply
  const validate = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(mailformat) ? true : false;
  };

  const handleChange = (e) => {
    setCandidateDetails({
      ...candidateDetails,
      [e.target.name]: e.target.value,
    });
  };

  const updatedParsed = (data, value) => {
    setParsedData(data);
    setParsed(value);
  };

  const emailExists = async () => {
    if (candidateDetails.email == "") {
      toast.error("Please enter your email");
    } else if (!validate(candidateDetails?.email)) {
      toast.error("Enter a valid email address");
    } else {
      const params = { tenantId: tenant, email: candidateDetails.email };

      setLoading(true);
      await post("/checkEmail/isEmailExist", params)
        .then((res) => {
          if (res.data.isExist === true) {
            let payload = { email: candidateDetails.email, jobId: id };
            post("/dsTalent/fetchNewTalentDetails", payload).then((res) => {
              if (
                res.data.status == "Invited" ||
                res.data.status == "success"
              ) {
                if (res.data.candidateFname && res.data.candidateLname) {
                  setNewInvite(false);
                } else {
                  setNewInvite(true);
                }
                setEmailExist(true);
                setLoading(false);
              } else if (res.data.status == "exist") {
                toast.error("You have already applied for this job");
                setLoading(false);
              } else {
                toast.error("Please try again");
                setLoading(false);
              }
            });
          } else if (res.data.isExist === false) {
            let payload = { email: candidateDetails.email, jobId: id };
            post("/dsTalent/fetchNewTalentDetails", payload).then(
              async (res) => {
                if (res.data.status == "Invited") {
                  if (res.data.candidateFname && res.data.candidateLname) {
                    setCandidateDetails({
                      ...candidateDetails,
                      fName: res.data.candidateFname,
                      lName: res.data.candidateLname,
                    });

                    setTrackedData({
                      ...trackedData,
                      jobId: job.jobId,
                      navigatedPages: trackedData.navigatedPages + "/apply", // + "profile" + "scorecard",
                      firstName: res.data.candidateFname,
                      lastName: res.data.candidateLname,
                      data: {
                        ...candidateDetails,
                        fName: res.data.candidateFname,
                        lName: res.data.candidateLname,
                        resumeFileName: "",
                      },
                    });
                    setNewInvite(false);
                    setLoading(false);
                    setApplyWithEmail(false);
                    setAskResume(true);
                  } else {
                    setNewInvite(true);
                    setLoading(false);
                    setApplyWithEmail(false);
                    setAskResume(true);
                  }
                } else if (res.data.status == "success") {
                  fetchExistingCandidate();
                  setLoading(false);
                } else if (res.data.status == "exist") {
                  toast.error("You have already applied for this job");
                  setLoading(false);
                } else {
                  toast.error("Please try again");
                  setLoading(false);
                }
              }
            );
          }
        })
        .catch(() => {
          toast.error("Please try again");
          setLoading(false);
        });
    }
  };

  const fetchExistingCandidate = async () => {
    setLoading(true);
    try {
      let res = await post("/dsTalent/fetchExistingTalentFromUserIndex", {
        email: candidateDetails.email,
      });
      // res.data.resumeURL && setResumeDownload(res.data.resumeURL);
      if (res?.data.length !== 0) {
        setCandidateStatusInput("tempCandidate");
        sessionStorage.setItem("userId", res?.data[0]?.candidateID);
        setEditTalent(true);
        res.data && setCandidateData(res.data[0]);
        setLoading(false);
      } else {
        setNewInvite(true);
        setLoading(false);
        setApplyWithEmail(false);
        setAskResume(true);
      }
    } catch (error) {
      toast.error("Error Occurred while accessing candidate details ");
      setLoading(false);
    }
  };

  // JOin our community on score card
  const joinCommunityScoreCard = async () => {
    setLoading(true);
    try {
      const res = await post("/dsTalent/addDsTalent", {
        ...addTalentData,
        FK_Tenant: parseInt(tenant),
        tenantName: tenantData.tenantname,
        jobId: id,
        recruiterId: recruiterid,
        tenantId: tenant,
        createdByName: recruiterData.firstName + " " + recruiterData.lastName,
        sourceChannel: sourceChannel,
        recruiterAgency: recruiterAgency,
        createdBy: recruiterid,
        createdByTenant: tenantData.tenantname,
        reffererName: reffererName,
        experienceYear: profileData.experienceYear?.toString(),
        experienceMonth: profileData.experienceMonth?.toString(),
        preferredLocation: profileData.isRemote
          ? [...profileData.preferredLocation, "Remote"]
          : [profileData.preferredLocation],
      }).then((res) => {
        toast.success("Successfully added to our community");
        setLoading(false);
      });
    } catch (error) {
      setLoading(false);
      setScoreCard(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };
  const submitAddTalent2 = async () => {
    setLoading(true);

    try {
      let url =
        candidateStatusInput !== "tempCandidate"
          ? "/dsTalent/addTalentViaInvite"
          : "/dsTalent/addExistingTalent";
      const res = await post(url, {
        ...addTalentData,
        FK_Tenant: parseInt(tenant),
        tenantName: tenantData.tenantname,
        jobId: id,
        createdBy: recruiterid,
        createdByName: recruiterData.firstName + " " + recruiterData.lastName,
        createdByTenant: tenantData.tenantname,
        sourceChannel: sourceChannel,
        recruiterId: recruiterid?.toString(),
        tenantId: tenant?.toString(),
        recruiterAgency: recruiterAgency,
        reffererName: reffererName,
        legallyAuthorized: candidateDetails.legallyAuthorized,
        requireSponsorship: candidateDetails.requireSponsorship,
        experienceYear: profileData.experienceYear?.toString(),
        experienceMonth: profileData.experienceMonth?.toString(),
        preferredLocation: profileData.isRemote
          ? [...profileData.preferredLocation, "Remote"]
          : [profileData.preferredLocation],
      });

      setCredentials({
        userName: res.data.userName,
        password: res.data.password,
      });

      if (res.status === 200) {
        setCandidateId(res.data.data.value[0].key);
        applyJob(res.data.data.value[0].key, res);
      }

      if (reffererid !== undefined) {
        addRefferedCand();
      }
    } catch (error) {
      toast.error("Some Error Occurred. Please Try Again");
      setLoading(false);
    }
  };

  const applyJob = async (id, resp) => {
    setLoading(true);
    const params = {
      jobId: poolId ? job.id_talentPool : job?.jobId?.toString(),
      jobTitle: job.jobTitle,
      jobTenant: job.jobTenant,
      jobLocation: job.workPlaceType,
      primarySkills: job.primarySkills,
      jobType: job.jobType,
      recruiterId: recruiterData?.userId?.toString(),
      recruiterName: `${recruiterData.firstName} ${recruiterData.lastName}`,
      recruiterEmail: recruiterData.email,
      recruiterTenant: tenantData.tenantname,
      candidateId: id, // id
      candidateFname: addTalentData.firstName,
      candidateMname: "",
      candidateLname: addTalentData.lastName,
      candidateEmail: addTalentData.email,
      candidateLocation: `${addTalentData.addressCity},${addTalentData.country}`,
      candidateImageURL: "",
      candidateTenant: tenantData.tenantname,
      payRate: addTalentData.preferredSalary,
      payRateCurrency: addTalentData.preferredSalaryCurrency,
      jobStatus: "active", // jobStatus
      candidateStatus: "active", // candidateStatus
      tenantId: tenantData.id_tenant,
      QandA: screeningAnswers,
      newInvite: newInvite,
      tenant_id: getUserTenantID(),
      highestEducation: candidateDetails.highestEducation,
      password: resp.data.password,
      userName: resp.data.userName,
    };
    let url = poolId
      ? "/applyJob/invitedUserForPool"
      : recruiterid !== undefined
      ? "/applyJob/invitedUser"
      : "/applyJob";

    const res = await post(url, params)
      .then(async (res) => {
        toast.success("You have successfully applied for the job.");
        if (job.vettingDetails == null) {
          setCreateProfile(false);

          setScoreCard(false);
          setCandidateDetails(data);
          // Add talent to Candidate login
          if (sessionStorage.getItem("token") == null) {
            const response = await post("/candidateLogin/", {
              password: resp.data.password,
              userName: resp.data.userName,
            }).then((res) => {
              sessionStorage.setItem("token", res.data.token);
              sessionStorage.setItem("name", res.data.name);
              sessionStorage.setItem("userId", res.data.userId);
              sessionStorage.setItem("userName", res.data.userName);
              setLogin(false);
              setEmailExist(false);
              setTimeout(() => {
                setLoading(false);
                history.push(`/careers/${tenant}/profile`);
              }, 5000);
              // window.location.reload();
            });
          } else {
            history.push(`/careers/${tenant}/profile`);
          }
        } else {
          onClickLater("now", id);
          setCreateProfile(false);
          setScoreCard(false);
          setCandidateDetails(data);
        }
      })
      .catch((err) => {
        setLoading(false);
        setCandidateDetails(data);
      });
  };

  // useEffect(() => {
  //   if(candidateDetails?.city) {
  //     searchInput?.current?.value = candidateDetails.city;
  //   }
  // }, [askResume]);

  const setLocation = (address, searchInput) => {
    const city = address.locality || address.city;
    setCandidateDetails({
      ...candidateDetails,
      city: city,
      country: address.country,
      location: searchInput?.current?.value,
    });
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <TopNavbar tenantData={tenantData} />
        <JobDescription title={job?.jobTitle} status="jobApply" jobData={job} />
      </div>
      <div className="main_apply_container">
        <div className="main_apply_container_apply">
          <span style={{ marginBottom: "10px" }}>
            <h6>Apply</h6>
          </span>
          <button
            className="main_apply_container_apply_btn1"
            onClick={() => applyNowOnClick()}
            disabled={
              job.jobStatus == "pending" ||
              job.jobStatus == "Deactivated" ||
              job.jobStatus == "expired"
            }
          >
            <span>Apply Now</span>
          </button>
          <hr className="line"></hr>
        </div>
      </div>
      <div className="main_job_requirements">
        <div className="main_job_requirements_part_1">
          <span>
            <h6>Job Requirement</h6>
          </span>
          <ol
            className="pr-3"
            dangerouslySetInnerHTML={{ __html: job.jobDescription }}
          ></ol>
        </div>
        <div className="main_job_requirements_part_2">
          <span>
            <h6>Required Skills</h6>
          </span>
          <div className="main_container-key_points mt-2">
            {job?.primarySkills?.map((item, i) => (
              <div className="main_container-key_points_style" key={i}>
                <span className="main_container-key_points_style_text">
                  {item}
                </span>
              </div>
            ))}
          </div>
        </div>
        <div className="main_job_requirements_part_3"></div>
        <div className="main_job_requirements_part_4"></div>
      </div>

      {sessionStorage.getItem("token") == null && (
        <div>
          <JoinCommunity
            recruiterId={recruiterid}
            setJoinCommunity={setJoinCommunity}
          ></JoinCommunity>
        </div>
      )}
      <div>
        <Footer></Footer>
      </div>
      {/* Edit Tslent */}
      <Modal show={editTalent} size="lg">
        <Modal.Header>
          <h6 class="">Edit Talent</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setEditTalent(false);
              setCandidateDetails(data);
              setParsedData({});
              setParsed(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <EditTalentDS
            closeModal={() => {
              setEditTalent(false);
              // setCandidateDetails(data);
              setParsedData({});
              setParsed(false);
            }}
            data={candidateData}
            setAddTalentData={setAddTalentData}
            setScoreCard={setScoreCard}
            setCandidateDetails={setCandidateDetails}
            jobs={job}
            // data={data}
          />
        </Modal.Body>
      </Modal>
      {/* Apply Modal */}
      <Modal show={login} size={askResume && "lg"}>
        <Modal.Header>
          <div>{askResume ? <h6>Apply</h6> : <h6>Apply With</h6>}</div>
          <div>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setApplyWithEmail(false);
                setLoginWithEmail(false);
                setLogin(false);
                setEmailExist(false);
                setNewUser(false);
                setCandidateDetails(data);
                setIsFormValid(false);
                setCandidateDetails(data);
                setParsedData({});
                setParsed(false);
              }}
              class="close p-0 bl-modal-close-btn mx-1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal.Header>
        {askResume === false && (
          <Modal.Body>
            <div className="d-flex flex-column mx-5 mb-3 "></div>
            <div>
              <div className="d-flex flex-column mx-5 my-3">
                <div className="form-floating ml-1">
                  <input
                    type="text"
                    className={"form-control"}
                    name="groupName"
                    placeholder="Enter a group name"
                    onChange={(e) =>
                      setCandidateDetails({
                        ...candidateDetails,
                        email: e.target.value,
                      })
                    }
                  />
                  <label>Email*</label>
                </div>
              </div>
              {emailExist && (
                <div className="d-flex flex-column mx-5 my-3">
                  <div className="form-floating ml-1 form-password">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={"form-control"}
                      name="groupName"
                      placeholder="Enter a group name"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <label>Password*</label>
                    {password.length > 0 && (
                      <button
                        //   v-on:click="revealPassword('security')"
                        onClick={showPasswordClick}
                        type="button"
                        className="btn btn-sm btn-text btn-text-accent h-100"
                      >
                        <i
                          className={
                            showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                          aria-hidden="true"
                        ></i>
                      </button>
                    )}
                  </div>
                  <p className="text-right my-1">
                    <a href="" onClick={forgetPassword}>
                      Forgot Password?
                    </a>
                  </p>
                </div>
              )}
              {emailExist && (
                <div className="row mx-5 mb-3">
                  <button
                    className="btn btn-secondary m-2 w-100"
                    onClick={loginUser}
                  >
                    Login
                  </button>
                </div>
              )}
              {emailExist === false && (
                <div className="row mx-5 mb-2">
                  <button
                    className="btn btn-secondary m-2 w-100"
                    onClick={emailExists}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
            {newUser && (
              <div>
                <div className="row mx-5">
                  <div className="col-lg-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={"form-control"}
                        name="groupName"
                        placeholder="Enter a group name"
                        onChange={(e) =>
                          setCandidateDetails({
                            ...candidateDetails,
                            fName: e.target.value,
                          })
                        }
                      />
                      <label>First Name*</label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={"form-control"}
                        name="groupName"
                        placeholder="Enter a group name"
                        onChange={(e) =>
                          setCandidateDetails({
                            ...candidateDetails,
                            lName: e.target.value,
                          })
                        }
                      />
                      <label>Last Name*</label>
                    </div>
                  </div>
                </div>
                <div className="row mx-5">
                  <div className="col-lg-12">
                    <div className="form-floating my-3">
                      <input
                        type="text"
                        className={"form-control"}
                        name="groupName"
                        placeholder="Enter a group name"
                        onChange={(e) =>
                          setCandidateDetails({
                            ...candidateDetails,
                            email: e.target.value,
                          })
                        }
                        value={candidateDetails.email}
                      />
                      <label>Email*</label>
                    </div>
                  </div>
                </div>
                <div className="row mx-5 mb-2">
                  <button
                    className="btn btn-secondary m-2 w-100 "
                    onClick={() => {
                      if (
                        candidateDetails.fName == "" ||
                        candidateDetails.lName == "" ||
                        candidateDetails.email == "" ||
                        candidateDetails.city == "" ||
                        candidateDetails.currentPosition == "" ||
                        candidateDetails.experienceMonth == "0" ||
                        candidateDetails.experienceYear == "0"
                      ) {
                        toast.error(
                          "There are a few incomplete required fields. Please complete them to continue. "
                        );
                      } else if (parsed === false) {
                        toast.error("Please upload your resume");
                      } else {
                        setAskResume(true);
                      }
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}
          </Modal.Body>
        )}

        {askResume && (
          <Modal.Body>
            <div>
              <div className="row mx-5">
                <div className="col-lg-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={"form-control"}
                      name="fName"
                      placeholder="Enter a group name"
                      onChange={handleChange}
                      value={candidateDetails.fName}
                    />
                    <label>First Name*</label>
                  </div>

                  {isFormValid && candidateDetails.fName == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>

                <div className="col-lg-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={"form-control"}
                      name="lName"
                      placeholder="Enter a group name"
                      onChange={handleChange}
                      value={candidateDetails.lName}
                    />
                    <label>Last Name*</label>
                  </div>
                  {isFormValid && candidateDetails.lName == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>
                <div className="col-lg-4">
                  <div className="form-floating ">
                    <input
                      type="text"
                      className={"form-control"}
                      name="email"
                      disabled
                      placeholder="Enter a group name"
                      onChange={handleChange}
                      value={candidateDetails.email}
                    />
                    <label>Email*</label>
                  </div>
                  {isFormValid && candidateDetails.email == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>
              </div>
              <div></div>
              <div className="row mx-5">
                <div className="col-lg-6">
                  <div className="mb-2">
                    <label>What is your preferred phone?*</label>
                  </div>
                  <div className="d-flex">
                    <PhoneInput
                      style={{ zIndex: "999" }}
                      containerClass="w-100"
                      inputClass="w-100"
                      value={candidateDetails.phone}
                      name="mobilePhone"
                      country={"us"}
                      onChange={(value, country) => {
                        _onChangeText(value, country);
                      }}
                    />
                  </div>
                  {isFormValid && candidateDetails.phone == "" ? (
                    <small className="validation">*Required Field</small>
                  ) : (
                    !candidateDetails.phoneValidation && (
                      <small className="validation">Invalid Phone</small>
                    )
                  )}
                </div>
                <div class="col-6">
                  <div class=" " style={{ marginBottom: "8px" }}>
                    <label>What are your primary skills?* </label>
                  </div>
                  <div>
                    <TagsInput
                      className="inputHeight"
                      renderInput={autocompleteRenderInput}
                      value={candidateDetails.primarySkills}
                      onChange={handleChangePrimarySkills}
                      inputValue={primarySkillinput}
                      onChangeInput={handleChangeInputPrimarySkills}
                      placeholder="Type a location and hit enter"
                      inputProps={{
                        placeholder: "Add Skill",
                      }}
                    />
                    {isFormValid &&
                      candidateDetails.primarySkills.length == 0 && (
                        <small className="validation">*Required Field</small>
                      )}
                  </div>
                </div>
              </div>

              <div className="row mx-5 my-3">
                <div className="col-lg-6">
                  <div className="form-floating ">
                    <GoogleLocationCityAPI
                      setLocation={setLocation}
                      searchInput={searchInput}
                    />
                    <label>In which city do you live?*</label>
                  </div>
                  {isFormValid && searchInput?.current?.value == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={"form-control"}
                      name="country"
                      placeholder="Enter a group name"
                      value={candidateDetails.country}
                      disabled
                      // onChange={handleChange}
                    />
                    <label>Which country do you live?*</label>
                  </div>
                  {isFormValid && candidateDetails.country == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>
              </div>

              <div className="row mx-5">
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={"form-control"}
                      name="currentPosition"
                      placeholder="Enter a group name"
                      onChange={handleChange}
                      value={candidateDetails.currentPosition}
                    />
                    <label>What is your current job title?*</label>
                  </div>
                  {isFormValid && candidateDetails.currentPosition == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={"form-control"}
                      name="currentEmployer"
                      placeholder="Enter a group name"
                      onChange={handleChange}
                      value={candidateDetails.currentEmployer}
                    />
                    <label>Who is your current employer?</label>
                  </div>
                </div>
              </div>

              <div class="row mx-5">
                <div class="col-lg-6">
                  <label>How many years of experience do you have?*</label>
                </div>
              </div>

              <div class="row mx-5">
                <div class="col-lg-6">
                  <div class="form-floating">
                    <div className="d-flex">
                      <div className="mr-3 form-floating">
                        <input
                          type="number"
                          className="form-control"
                          min="0"
                          max="100"
                          style={{
                            height: "57px",
                            border: "1px solid #ced4da",
                            fontSize: "15px",
                          }}
                          value={candidateDetails.experienceYear}
                          name="experienceYear"
                          defaultValue="0"
                          onChange={(e) => {
                            if (e.target.value === "0") {
                              toast.error("Cannot Be Zero");
                            } else if (+e.target.value < 0) {
                              toast.error("Negative numbers are not allowed");
                            } else if (e.target.value > 100) {
                              return;
                            } else {
                              handleChange(e);
                            }
                          }}
                          placeholder="Year"
                        />
                        <label>Years</label>
                        {isFormValid &&
                          candidateDetails.experienceYear == "0" &&
                          candidateDetails.experienceMonth == "0" && (
                            <small className="validation">
                              *Required Field
                            </small>
                          )}
                      </div>

                      <div className=" form-floating ">
                        <input
                          type="number"
                          className="form-control "
                          min="0"
                          style={{
                            height: "57px",
                            border: "1px solid #ced4da",
                            fontSize: "15px",
                          }}
                          name="experienceMonth"
                          value={candidateDetails.experienceMonth}
                          defaultValue="0"
                          onChange={(e) => {
                            if (+e.target.value < 0) {
                              toast.error("Negative numbers are not allowed");
                            } else if (e.target.value > 11) {
                              return;
                            } else {
                              handleChange(e);
                            }
                          }}
                          placeholder="Months"
                        />
                        <label>Months</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 ">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="educationType"
                      name="highestEducation"
                      onChange={handleChange}
                      value={candidateDetails.highestEducation}
                    >
                      {educationTypes.map((item, i) => (
                        <option key={i} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <label>Highest Level of Education</label>
                  </div>
                </div>
              </div>

              <div class="row mx-5">
                <div class="form-floating my-2">
                  <div className=" form-switch d-flex align-items-center pl-0">
                    <label
                      className="form-check-label pt-0"
                      htmlFor="remoteSwitch"
                    >
                      Are you legally authorized to work in the United States?
                    </label>
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="isFresher"
                      value={candidateDetails.legallyAuthorized}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setLegalAuth(true);
                          setCandidateDetails({
                            ...candidateDetails,
                            legallyAuthorized: true,
                          });
                        } else {
                          setLegalAuth(false);
                          setCandidateDetails({
                            ...candidateDetails,
                            legallyAuthorized: false,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
              {
                <div class="row mx-5">
                  <div class="form-floating my-2">
                    <div className=" form-switch d-flex align-items-center pl-0">
                      <label
                        className="form-check-label pt-0 w-75"
                        htmlFor="remoteSwitch"
                      >
                        Do you now or in the future require sponsorship for an
                        immigration-related benefit?
                      </label>

                      <input
                        className="form-check-input ml-3"
                        type="checkbox"
                        id="isFresher"
                        value={candidateDetails.requireSponsorship}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setCandidateDetails({
                              ...candidateDetails,
                              requireSponsorship: true,
                            });
                          } else {
                            setCandidateDetails({
                              ...candidateDetails,
                              requireSponsorship: false,
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              }

              {/* Resume */}
              <div className="row mx-5">
                <div className="col-md-12">
                  <ApplyJob
                    updateParsedData={(data, value) =>
                      updatedParsed(data, value)
                    }
                    setTrackedData={setTrackedData}
                    trackedData={trackedData}
                    parsedData={parsedData}
                    email={candidateDetails.email}
                  />
                </div>
              </div>
              <div className="row mx-5 mb-2">
                <hr />
                <div className="col-md-8"></div>
                <div className="col-md-4 " style={{ marginLeft: "79%" }}>
                  {
                    <button
                      className="btn btn-primary m-2 "
                      onClick={() => {
                        if (
                          candidateDetails.fName == "" ||
                          candidateDetails.lName == "" ||
                          candidateDetails.email == "" ||
                          candidateDetails.phone == "" ||
                          candidateDetails.city == "" ||
                          searchInput?.current?.value == "" ||
                          candidateDetails.currentPosition == "" ||
                          (candidateDetails.experienceYear == "0" &&
                            candidateDetails.experienceMonth == "0") ||
                          candidateDetails.primarySkills.length == 0
                        ) {
                          setIsFormValid(true);
                          toast.error(
                            "There are a few incomplete required fields. Please complete them to continue. "
                          );
                          // } else if (!parsedData.fileName) {
                          //   toast.error("Please upload resume");
                        } else if (!candidateDetails.phoneValidation) {
                          toast.error("Please enter valid phone number");
                        } else if (!parsedData) {
                          toast.error(
                            "Our primary parsing engine is busy, we are taking you to secondary parsing engine."
                          );
                        } else {
                          setTrackedData({
                            ...trackedData,
                            jobId: job.jobId,
                            navigatedPages:
                              trackedData.navigatedPages + "/profile",

                            data: {
                              ...candidateDetails,
                              resumeFileName: trackedData.data.resumeFileName,
                            },
                          });
                          setIsFormValid(false);
                          setCreateProfile(true);
                          setLogin(false);
                        }
                      }}
                      // disabled={!parsedData.fileName}
                    >
                      Continue
                    </button>
                  }
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>

      {/* {createProfile && ( */}
      <AddTalentDS
        createProfile={createProfile}
        setCreateProfile={setCreateProfile}
        setTrackedData={setTrackedData}
        trackedData={trackedData}
        email={email}
        reffererid={reffererid}
        parsedData={parsedData}
        parsed={parsed}
        candidateDetails={candidateDetails}
        job={job}
        setCandidateData={setAddTalentData}
        setScoreCard={setScoreCard}
        profileData={profileData}
        setProfileData={setProfileData}
        data={data}
        setCandidateDetails={setCandidateDetails}
      />

      {/* AutoMatch Score Card */}
      <Modal show={scoreCard} size="lg">
        <Modal.Header>
          <h6 class="">
            {screeningQuestions === true ? "Screening Questions" : "Score Card"}
          </h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setScoreCard(false);
              setCandidateDetails(data);
              setParsedData({});
              setParsed(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <AutoMatchScoreCardDS
            jobData={job}
            candidateData={addTalentData}
            trackedData={trackedData}
            setTrackedData={setTrackedData}
            setScreeningQuestions={setScreeningQuestions}
            applyJob={submitAddTalent}
            setScoreCard={setScoreCard}
            setIsVetting={setIsVetting}
            setFetchVettingData={setFetchVettingData}
            setVettingData={setVettingData}
            candidateDetails={candidateDetails}
            setLogin={setLogin}
            setAskResume={setAskResume}
            joinCommunityScoreCard={joinCommunityScoreCard}
            poolId={poolId}
            data={data}
            setCandidateDetails={setCandidateDetails}
            setIsChecked={setIsChecked}
            screeningAnswers={screeningAnswers}
            setScreeningAnswers={setScreeningAnswers}
            setEditTalent={setEditTalent}
            fetchCandidate={fetchCandidate}
            setCreateProfile={setCreateProfile}
          />
        </Modal.Body>
      </Modal>

      {/* Vettinog */}
      <Modal show={isVetting} size="lg">
        <Modal.Header>
          <h6 class="">Vetting Requirement</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setIsVetting(false);
              setCandidateDetails(data);
              setParsedData({});
              setParsed(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        {vettingNow === false && vettingLater === false && (
          <Modal.Body>
            <div className="mx-4 my-4">
              <div className="row">
                {/* <h4 className="text-center " style={{ color: "#FF5F57" }}>
                  {" "}
                  Congratulations!
                  <br />
                </h4>
                <br />
                <h5 className="text-center mt-2">
                  {" "}
                  You have successfully applied for the job.
                </h5>
                <br />
                <br /> */}
                <strong className="text-center mt-2">
                  One last step! The hiring manager is looking for the vetted
                  talent.
                  <br /> To proceed
                  <button
                    className="btn btn-sm btn-primary mx-2 my-2"
                    // onClick={() => onClickLater("now")}
                    onClick={() => submitAddTalent()}
                  >
                    Click here
                  </button>{" "}
                  {/* or
                  <button
                    className="btn btn-sm btn-secondary ml-2 mx-2 my-2"
                    onClick={() => onClickLater("later")}
                  >
                    Skip
                  </button>
                  <br />
                  to receive email with the assessment detail, however you must
                  complete the assessment in 24 hours. */}
                </strong>
              </div>
              {/* <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-center">
                      <strong>Want to give assessment ?</strong>
                      <br />
                      <button className="btn btn-sm btn-primary mx-2">
                        Now
                      </button>
                      <strong>or</strong>
                      <button className="btn btn-sm btn-secondary ml-2 mx-2">
                        Later
                      </button>
                    </div>
                  </div>
                </div> */}
            </div>
          </Modal.Body>
        )}
        {vettingNow && (
          <Modal.Body>
            <div className="mx-4 my-4">
              <div className="row">
                <strong className="text-center mt-2">
                  We have mailed you the details of all the assigned
                  assessments.
                  <br /> Please check and complete the assessment.
                  <br />
                  <button
                    className="btn btn-sm btn-primary mx-2 mt-2"
                    onClick={() => {
                      setVettingNow(false);
                      setVettingLater(false);
                      setIsVetting(false);
                      if (sessionStorage.getItem("token") == null) {
                        const response =
                          credentials.userName !== ""
                            ? post("/candidateLogin/", credentials).then(
                                (res) => {
                                  sessionStorage.setItem(
                                    "token",
                                    res.data.token
                                  );
                                  sessionStorage.setItem("name", res.data.name);
                                  sessionStorage.setItem(
                                    "userId",
                                    res.data.userId
                                  );
                                  sessionStorage.setItem(
                                    "userName",
                                    res.data.userName
                                  );
                                  history.push(`/careers/${tenant}/profile`);
                                }
                              )
                            : "";
                      } else {
                        history.push(`/careers/${tenant}/profile`);
                      }
                    }}
                  >
                    Close
                  </button>{" "}
                </strong>
              </div>
            </div>
          </Modal.Body>
        )}
        {vettingLater && (
          <Modal.Body>
            <div className="mx-4 my-4">
              <div className="row">
                <strong className="text-center mt-2">
                  We have sent you the link of assessment along with unique code
                  for assessment on your registered email.
                  <br /> Please check them and complete the assessment within 24
                  hours.
                  <br />
                  <button
                    className="btn btn-sm btn-primary mx-2"
                    onClick={() => {
                      setVettingNow(false);
                      setVettingLater(false);
                      setIsVetting(false);
                      if (sessionStorage.getItem("token") == null) {
                        setLoading(true);
                        const response =
                          credentials.userName !== ""
                            ? post("/candidateLogin/", credentials).then(
                                (res) => {
                                  sessionStorage.setItem(
                                    "token",
                                    res.data.token
                                  );
                                  sessionStorage.setItem("name", res.data.name);
                                  sessionStorage.setItem(
                                    "userId",
                                    res.data.userId
                                  );
                                  sessionStorage.setItem(
                                    "userName",
                                    res.data.userName
                                  );
                                  history.push(`/careers/${tenant}/profile`);
                                  setLoading(false);
                                }
                              )
                            : "";
                      } else {
                        history.push(`/careers/${tenant}/profile`);
                      }
                    }}
                  >
                    Close
                  </button>{" "}
                </strong>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};

export default JobApplyComponent;
