import { checkEmptyObject } from "../../../../function/CommonFunction";
import Validator from "email-validator";

export const emailValidation = (param) => {
  const validationObj = [];
  let emailError = "";
  if (param?.email?.trim()?.length === 0) {
    emailError = "*Required Field";
    validationObj.push("Please Enter Email");
  }
  if (param?.email?.trim()?.length > 0) {
    if (!Validator.validate(param.email)) {
      emailError = "*Enter Valid Email";
      validationObj.push("Please Enter Valid Email");
    }
  }
  const validation = {
    email: emailError,
  };
  return { validation, validationObj };
};

export const contactInfoValidation = (contactInfo, setContactVal) => {
  const validationObj = [];

  let firstNameError = "";
  let lastNameError = "";
  let emailError = "";
  let zipcodeError = "";
  let addressError = "";
  let addressCityError = "";
  let addressStateError = "";
  let countryError = "";
  let phoneError = "";
  var regexs = /^[A-Z a-z\s]+$/;
  if (contactInfo?.firstName?.trim()?.length === 0) {
    firstNameError = "*Required Field";
    validationObj.push("Please Enter First Name");
  }
  if (contactInfo?.firstName?.trim()?.length > 0) {
    if (!regexs?.test(contactInfo?.firstName)) {
      firstNameError = "Special characters & numbers not allowed";
      validationObj.push("Please Enter Valid First Name");
    }
  }
  if (contactInfo?.lastName?.trim()?.length === 0) {
    lastNameError = "*Required Field";
    validationObj.push("Please Enter Last Name");
  }
  if (contactInfo?.lastName?.trim()?.length > 0) {
    if (!regexs.test(contactInfo?.lastName)) {
      lastNameError = "Special characters & numbers not allowed";
      validationObj.push("Please Enter Valid Last Name");
    }
  }
  if (contactInfo?.email?.trim()?.length === 0) {
    emailError = "*Required Field";
    validationObj.push("Please Enter Email");
  }
  if (contactInfo?.email?.trim()?.length > 0) {
    if (!Validator.validate(contactInfo.email)) {
      emailError = "*Enter Valid Email";
      validationObj.push("Please Enter Valid Email");
    }
  }
  if (contactInfo.homePhone === ("" || undefined)) {
    phoneError = "*Required Field";
    validationObj.push("Please Enter Primary Phone");
  }
  // if (contactInfo?.address?.trim()?.length === 0) {
  //   addressError = "*Required Field";
  //   validationObj.push("Please Enter Primary Address");
  // }

  if (contactInfo?.city?.trim()?.length === 0) {
    addressCityError = "*Required Field";
    validationObj.push("Please Enter City");
  }
  if (contactInfo?.city?.trim()?.length > 0) {
    if (!regexs.test(contactInfo?.city)) {
      addressCityError = "Special characters & numbers not allowed";
      validationObj.push("Please Enter Valid City");
    }
  }
  // if (contactInfo?.state?.trim()?.length === 0) {
  //   addressStateError = "*Required Field";
  //   validationObj.push("Please Enter State");
  // }
  if (contactInfo?.country?.trim()?.length === 0) {
    countryError = "*Required Field";
    validationObj.push("Please Enter Country");
  }
  if (contactInfo?.country?.trim()?.length > 0) {
    if (!regexs.test(contactInfo?.country)) {
      countryError = "Special characters & numbers not allowed";
      validationObj.push("Please Enter Valid Country");
    }
  }
  const validation = {
    firstName: firstNameError,
    lastName: lastNameError,
    email: emailError,
    zipcode: zipcodeError,
    address: addressError,
    city: addressCityError,
    state: addressStateError,
    country: countryError,
    phone: phoneError,
  };
  return { validation, validationObj };
};

export const profileInfoValidation = (profileData, job, isFrom) => {
  const validationObj = [];

  let preferredSalaryCurrencyError = "";
  let minContractRateCurrencyError = "";
  let preferredLocationError = "";
  let primarySkillsError = "";
  let visaStatusError = "";
  let workAuthCountryError = "";
  let currentJobTitleError = "";
  let experienceMonthError = "";
  let experienceYearError = "";
  let preferredSalaryError = "";
  let minContractRateError = "";
  var regexs = /^[A-Z a-z\s]+$/;
  if (profileData.isFresher === false) {
    if (
      profileData?.designation.trim()?.length === 0 ||
      !profileData?.designation
    ) {
      currentJobTitleError = "*Required Field";
      validationObj.push("Please enter your current job title");
    }
  }
  // if (profileData?.designation?.trim()?.length > 0) {
  //   if (!regexs?.test(profileData?.designation)) {
  //     currentJobTitleError = "Special characters & numbers not allowed";
  //     validationObj.push("Please Enter Valid Current Job Title");
  //   }
  // }
  if (profileData.isFresher === false) {
    if (profileData.experienceYear === "") {
      experienceYearError = "*Required Field";
      validationObj.push("Please Enter Experience Year");
    }
  }
  if (
    profileData?.visaStatus?.trim()?.length === 0 ||
    !profileData?.visaStatus
  ) {
    visaStatusError = "*Required Field";
    validationObj.push("Please Enter Visa Status");
  }
  console.log(
    ["Permanent Resident", "Citizen", "Authorised to work"].includes(
      profileData?.visaStatus
    ),
    profileData?.workAuthCountry?.trim()?.length === 0 ||
      !profileData?.workAuthCountry
  );
  if (
    ["Permanent Resident", "Citizen", "Authorised to work"].includes(
      profileData?.visaStatus
    ) &&
    (profileData?.workAuthCountry?.trim()?.length === 0 ||
      !profileData?.workAuthCountry)
  ) {
    workAuthCountryError = "*Required Field";
    validationObj.push("Please Enter Work Authorization Country");
    console.log(workAuthCountryError);
  }
  // if (profileData.isFresher === false) {
  //   if (!/^[0-9]+$/.test(profileData?.experienceYear)) {
  //     experienceYearError = "Invalid Experience Year";
  //     validationObj.push("Please Enter Valid Experience Year");
  //   }
  // }

  if (profileData.isFresher === false) {
    if (profileData.experienceMonth === "") {
      experienceMonthError = "*Required Field";
      validationObj.push("Please Enter Experience Month");
    }
    if (profileData?.designation?.length === 0) {
      currentJobTitleError = "*Required Field";
      validationObj.push("Please enter your current job title");
    }
  }
  // if (profileData.isFresher === false) {
  //   if (!/^[0-9]+$/.test(profileData?.experienceMonth)) {
  //     experienceMonthError = "Invalid Experience Month";
  //     validationObj.push("Please Enter Valid Experience Month");
  //   }
  // }

  if (
    isFrom !== "ApplyJob" &&
    profileData?.source !== "Career Page" &&
    profileData?.preferredLocation?.length === 0
  ) {
    preferredLocationError = "*Required Field";
    validationObj.push("Please Enter Preferred Locations");
  }
  if (profileData?.primarySkills?.length === 0) {
    primarySkillsError = "*Required Field";
    validationObj.push("Please Enter Primary Skills");
  }
  if (
    profileData.minContractRate > 0 &&
    (profileData.minContractRateCurrency === "" ||
      !profileData.minContractRateCurrency)
  ) {
    minContractRateCurrencyError = "*Field cannot be empty";
    validationObj.push("Please Choose Currency For Minimum Contract Rate");
  }
  if (
    profileData.preferredSalary > 0 &&
    (profileData.preferredSalaryCurrency === "" ||
      !profileData.preferredSalaryCurrency)
  ) {
    preferredSalaryCurrencyError = "*Field cannot be empty";
    validationObj.push("Please Choose Currency For Preferred Salary");
  }
  if (profileData.preferredSalary > 0) {
    if (!/^[0-9]+$/.test(profileData.preferredSalary)) {
      preferredSalaryError = "Invalid Preferred Salary";
      validationObj.push("Please Enter Valid Preferred Salary");
    }
  }
  if (
    (profileData?.preferredSalary?.length === 0 ||
      !profileData?.preferredSalary) &&
    (profileData?.minContractRate?.length === 0 ||
      !profileData?.minContractRate) &&
    isFrom !== "ApplyJob" &&
    isFrom !== "JoinTalentCommunity"
  ) {
    preferredSalaryError = "*Required Field";
    validationObj.push("Please enter preferred salary");
  }
  if (
    profileData?.preferredSalary?.length === 0 &&
    profileData?.minContractRate?.length === 0 &&
    isFrom == "ApplyJob"
  ) {
    preferredSalaryError = "*Required Field";
    validationObj.push("Please Enter Expected salary");
  }
  if (
    profileData?.preferredSalary?.length === 0 &&
    isFrom == "JoinTalentCommunity"
  ) {
    preferredSalaryError = "*Required Field";
    validationObj.push("Please Enter Expected salary");
  }
  if (profileData.minContractRate > 0) {
    if (!/^[0-9]+$/.test(profileData.minContractRate)) {
      minContractRateError = "Invalid Minimum Contract Rate";
      validationObj.push("Please Enter Valid Minimum Contract Rate");
    }
  }
  if (isFrom == "ApplyJob" && job !== undefined) {
    if (job.minMaxPaymentType == "Per hour") {
      if (profileData?.minContractRate.length === 0) {
        minContractRateError = "*Required Field";
        validationObj.push("Please Enter Expected salary");
      }
    }
    if (job.minMaxPaymentType == "Annual") {
      if (profileData?.preferredSalary.length === 0) {
        preferredSalaryError = "*Required Field";
        validationObj.push("Please Enter Expected salary");
      }
    }
  }
  const validation = {
    preferredSalaryCurrency: preferredSalaryCurrencyError,
    minContractRateCurrency: minContractRateCurrencyError,
    preferredLocation: preferredLocationError,
    primarySkills: primarySkillsError,
    designation: currentJobTitleError,
    experienceYear: experienceYearError,
    experienceMonth: experienceMonthError,
    preferredSalary: preferredSalaryError,
    minContractRate: minContractRateError,
    visaStatus: visaStatusError,
    workAuthCountry: workAuthCountryError,
  };
  return { validation, validationObj };
};

export const experienceValidation = (experiences) => {
  let validation = [];
  const validationObject = [];
  experiences?.forEach((item) => {
    let validationObj = {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    };

    if (checkEmptyObject(item)) {
      validation.push(validationObj);
    } else {
      if (item.employerName === "") {
        validationObj = { ...validationObj, employerName: "*Required Field" };
        validationObject.push("Please Enter Employer Name");
      }
      if (item.industry === "") {
        validationObj = { ...validationObj, industry: "*Required Field" };
        validationObject.push("Please Enter Industry");
      }
      if (item.jobTitle === "") {
        validationObj = { ...validationObj, jobTitle: "*Required Field" };
        validationObject.push("Please Enter Job Title");
      }
      if (item.startDate === "") {
        validationObj = { ...validationObj, startDate: "*Required Field" };
        validationObject.push("Please Enter Start Date");
      }
      if (item.endDate === "" && item.isSelect === false) {
        validationObj = { ...validationObj, endDate: "*Required Field" };
        validationObject.push("Please Enter End Date");
      }
      validation.push(validationObj);
    }
  });
  return { validation, validationObj: validationObject };
};

export const educationValidation = (educations) => {
  let validation = [];
  const validationObject = [];
  educations?.forEach((item) => {
    let validationObj = {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    };
    if (checkEmptyObject(item)) {
      validation.push(validationObj);
    } else {
      if (item.graduatedYear === "") {
        validationObj = { ...validationObj, graduatedYear: "*Required Field" };
        validationObject.push("Please Enter Graduation Year");
      }
      if (item.educationType === "") {
        validationObj = { ...validationObj, educationType: "*Required Field" };
        validationObject.push("Please Enter Education Type");
      }
      if (item.educationProgram === "") {
        validationObj = {
          ...validationObj,
          educationProgram: "*Required Field",
        };
        validationObject.push("Please Enter Education Program");
      }
      if (item.school === "") {
        validationObj = { ...validationObj, school: "*Required Field" };
        validationObject.push("Please Enter School");
      }
      if (item.major === "") {
        validationObj = { ...validationObj, major: "*Required Field" };
        validationObject.push("Please Enter Major");
      }
      validation.push(validationObj);
    }
  });
  return { validation, validationObj: validationObject };
};

export const certificationValidation = (certifications) => {
  let validation = [];
  const validationObject = [];
  certifications?.forEach((item) => {
    let validationObj = { certificationName: "", issueDate: "" };
    if (checkEmptyObject(item)) {
      validation.push(validationObj);
    } else {
      if (item.certificationName.trim().length === 0) {
        validationObj = {
          ...validationObj,
          certificationName: "*Required Field",
        };
        validationObject.push("Please Enter Certification Name");
      }
      if (item.certificate.trim().length === 0) {
        validationObj = { ...validationObj, certificate: "*Required Field" };
        validationObject.push("Please Enter Certificate");
      }
      if (item.issueDate.trim().length === 0) {
        validationObj = { ...validationObj, issueDate: "*Required Field" };
        validationObject.push("Please Enter Issue Date");
      }
      if (item.expiryDate.trim().length === 0) {
        validationObj = { ...validationObj, expiryDate: "*Required Field" };
        validationObject.push("Please Enter Expiry Date");
      }
      validation.push(validationObj);
    }
  });
  return { validation, validationObj: validationObject };
};

export const licenseValidation = (licenses) => {
  let validation = [];
  const validationObject = [];
  licenses?.forEach((item) => {
    let validationObj = {
      licenseName: "",
      license: "",
      state: "",
      expiryDate: "",
    };
    if (checkEmptyObject(item)) {
      validation.push(validationObj);
    } else {
      if (item.licenseName.trim().length === 0) {
        validationObj = { ...validationObj, licenseName: "*Required Field" };
        validationObject.push("Please Enter Licence Name");
      }
      // if (item.license.trim().length === 0) {
      //   validationObj = { ...validationObj, license: "*Required Field" };
      //   validationObject.push("Please Enter Licence Number");
      // }
      // if (item.state.trim().length === 0) {
      //   validationObj = { ...validationObj, state: "*Required Field" };
      //   validationObject.push("Please Enter State");
      // }
      // if (item.expiryDate.trim().length === 0) {
      //   validationObj = { ...validationObj, expiryDate: "*Required Field" };
      //   validationObject.push("Please Enter Expiry Date");
      // }
      validation.push(validationObj);
    }
  });
  return { validation, validationObj: validationObject };
};
