import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Loader from "../../../../Loader/Loader";
import { getUserRole } from "../../../../../service/AuthService";
import SidePanelHeader from "./sidepanelheader/Index";
import SidepanelDetails from "./sidepaneldetails/Index";
import DispositionHeader from "../dispositionheader/Index";
import DispositionDetails from "../dispositiondetails/Index";
import InviteTalent from "../Invite/Index";
import PoolDetailsTitle from "./PoolDetailsTitle";
import { get } from "../../../../../service/ApiService";
import { getPoolDispositionDetails } from "../../../../../actions/directsourcing/Disposition";
import { getPoolDispositionCount } from "../../../../../actions/directsourcing/PoolDispositionCount";
import { getPoolDispositionDisqualify } from "../../../../../actions/directsourcing/PoolDispositionDisqualify";
import Search from "../../../commoncomponents/search/search";
import { Modal } from "react-bootstrap";
import Filter from "../../../newFilter/SubcommunityFilter";
import BreadCrumbs from "../../../commoncomponents/breadcrumbs/BreadCrumbs";

const PoolDetails = ({ showDetails, setShowDetails }) => {
  const dispatch = useDispatch();
  const { poolId, Tab } = useParams();
  const [poolData, setPoolData] = useState({});
  const [error, setError] = useState(false);
  const [invite, setInvite] = useState(false);
  const [sideView, setSideView] = useState("overview");
  const [dispositionView, setDispositionView] = useState("");
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTalents, setSelectedTalents] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const [filterParams, setFilterParams] = useState(null);
  const [selectedData, setSelectedData] = useState({
    certifications: [],
    nursingSpecialty: [],
    experienceLevel: [],
    country: "",
    state: "",
    address: [],
    licenseStates: [],
    sourceChannel: [],
    internationalPlacement: [],
    talentAcquisition: [],
  });
  const [inputList, setInputList] = useState({
    term1: [],
    term2: [],
    term3: [],
  });

  const [skillInputList, setSkillInputList] = useState({
    term1: [],
    term2: [],
    term3: [],
  });

  const {
    dispositionDetails,
    dispositionLoading,
    dispositionDisqualifyLoading,
    dispositionDisqualify,
    dispositionCount,
    dispositionCountLoading,
  } = useSelector(
    (state) => ({
      dispositionDetails: state.poolDisposition.dispositionDetails,
      dispositionLoading: state.poolDisposition.dispositionDetailsLoading,
      dispositionDisqualifyLoading:
        state.poolDispositionDisqualify.dispositionDisqualifyLoading,
      dispositionDisqualify:
        state.poolDispositionDisqualify.dispositionDisqualify,
      dispositionCount: state.poolDispositionCount.dispositionCount,
      dispositionCountLoading:
        state.poolDispositionCount.dispositionCountLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    setValue("");
    setCurrentPage(1);
    getPoolById();
    const payload = {
      jobId: poolId,
      filterObject: filterParams ? filterParams : getFilterParamsNew("", ""),
      searchText: value,
    };
    dispatch(getPoolDispositionCount(payload));
    const payloadDisqualify = {
      poolId: poolId,
      status: "disqualified",
      limit: 15,
      currentPage: 1,
      filterObject: filterParams ? filterParams : getFilterParamsNew("", ""),
      searchText: value,
    };
    dispatch(getPoolDispositionDisqualify(payloadDisqualify));
    if (dispositionView) {
      const payloadDisposition = {
        poolId: poolId,
        status: dispositionView,
        limit: 15,
        currentPage: 1,
        filterObject: filterParams ? filterParams : getFilterParamsNew("", ""),
        searchText: value,
      };
      dispatch(getPoolDispositionDetails(payloadDisposition));
    }
  }, [dispositionView]);

  useEffect(() => {
    setFilterData(dispositionDetails);
  }, [dispositionDetails]);

  useEffect(() => {
    setCurrentPage(1);
    getPoolById();
    const delay = 1000;
    const debounceTimer = setTimeout(() => {
      if (value.length === 0 || value.length >= 2) {
        const payload = {
          jobId: poolId,
          filterObject: filterParams
            ? filterParams
            : getFilterParamsNew("", ""),
          searchText: value,
        };
        dispatch(getPoolDispositionCount(payload));

        const payloadDisqualify = {
          poolId: poolId,
          status: "disqualified",
          limit: 15,
          currentPage: 1,
          filterObject: filterParams
            ? filterParams
            : getFilterParamsNew("", ""),

          searchText: value,
        };
        dispatch(getPoolDispositionDisqualify(payloadDisqualify));

        if (dispositionView) {
          const payloadDisposition = {
            poolId: poolId,
            status: dispositionView,
            limit: 15,
            currentPage: 1,
            filterObject: filterParams
              ? filterParams
              : getFilterParamsNew("", ""),
            searchText: value,
          };
          dispatch(getPoolDispositionDetails(payloadDisposition));
        }
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [value]);

  const getPoolById = async () => {
    setLoading(true);
    await get(`/talent/getPoolById?poolId=${poolId}`).then((res) => {
      let setData = res.data;
      const payload = {
        poolId: setData?.poolId,
      };
      setPoolData(setData);
      setLoading(false);
    });
  };

  const handleSkillStructure = (inputParam) => {
    let newData = Object.keys(inputParam).map((term) => ({
      keys: inputParam[term],
    }));
    return newData;
  };

  const getFilterParamsNew = (val, status) => {
    let keywords = [];
    let skills = [];

    if (status === "keywordSearch") {
      keywords = Object.keys(val).map((term) => ({
        keys: val[term],
      }));
    }
    if (status === "skills") {
      skills = Object.keys(val).map((term) => ({
        keys: val[term],
      }));
    }

    const filterParam = {
      experienceLevel:
        status == ""
          ? []
          : status == "experienceLevel"
          ? val
          : selectedData.experienceLevel,
      country:
        status == ""
          ? ""
          : status == "country"
          ? val
          : status == "state"
          ? ""
          : status == "address"
          ? ""
          : status == "clearCountry"
          ? ""
          : selectedData.country,
      state:
        status == ""
          ? ""
          : status == "state"
          ? val
          : status == "address"
          ? ""
          : status == "country"
          ? ""
          : status == "clearState"
          ? ""
          : selectedData.state,
      address:
        status == ""
          ? ""
          : status == "address"
          ? val
          : status == "country"
          ? ""
          : status == "state"
          ? ""
          : status == "clearAddress"
          ? ""
          : selectedData.address,
      sourceChannel:
        status == ""
          ? []
          : status == "sourceChannel"
          ? Array.isArray(val)
            ? val
            : [val]
          : selectedData.sourceChannel,
      internationalPlacement:
        status == ""
          ? []
          : status == "internationalPlacement"
          ? val
          : selectedData.internationalPlacement,
      talentAcquisition:
        status == ""
          ? []
          : status == "talentAcquisition"
          ? val
          : selectedData.talentAcquisition,
      keywords:
        status === ""
          ? []
          : status == "keywordSearch"
          ? keywords
          : handleSkillStructure(inputList),
      skills:
        status === ""
          ? []
          : status == "skills"
          ? skills
          : handleSkillStructure(skillInputList),
      certifications:
        status == ""
          ? []
          : status == "certifications"
          ? val
          : selectedData.certifications,
      nursingSpecialty:
        status == ""
          ? []
          : status == "nursingSpecialty"
          ? val
          : selectedData.nursingSpecialty,
      licenseStates:
        status == ""
          ? []
          : status == "licenseStates"
          ? val
          : selectedData.licenseStates,
    };
    setFilterParams(filterParam);
    return filterParam;
  };

  const applyFilter = (val, status) => {
    setCurrentPage(1);
    const payloadDisposition = {
      poolId: poolId,
      status: dispositionView,
      limit: 15,
      currentPage: 1,
      filterObject: getFilterParamsNew(val, status),
      searchText: value,
    };
    dispatch(getPoolDispositionDetails(payloadDisposition));
    const payload = {
      jobId: poolId,
      filterObject: getFilterParamsNew(val, status),
      searchText: value,
    };
    dispatch(getPoolDispositionCount(payload));
    let payloadDisqualify = {
      currentPage: 1,
      limit: 15,
      status: "disqualified",
      poolId: poolId,
      filterObject: filterParams ? filterParams : getFilterParamsNew("", ""),
      searchText: value,
    };
    dispatch(getPoolDispositionDisqualify(payloadDisqualify));
    setSelectedTalents([]);
  };

  const fetchData = (currentPage, param) => {
    setCurrentPage(1);
    const payloadDisposition = {
      poolId: poolId,
      status: dispositionView,
      limit: 15,
      currentPage: 1,
      filterObject: filterParams ? filterParams : getFilterParamsNew("", ""),
      searchText: value,
    };

    dispatch(getPoolDispositionDetails(payloadDisposition));
    const payload = {
      jobId: poolId,
      filterObject: filterParams ? filterParams : getFilterParamsNew("", ""),
      searchText: value,
    };
    dispatch(getPoolDispositionCount(payload));
    let payloadDisqualify = {
      currentPage: 1,
      limit: 15,
      status: "disqualified",
      poolId: poolId,
      filterObject: filterParams ? filterParams : getFilterParamsNew("", ""),
      searchText: value,
    };
    dispatch(getPoolDispositionDisqualify(payloadDisqualify));
  };

  const filterLength =
    selectedData?.sourceChannel.length +
    selectedData?.experienceLevel.length +
    (selectedData?.country ? 1 : 0) +
    (selectedData?.state ? 1 : 0) +
    selectedData?.address.length +
    selectedData?.certifications.length +
    selectedData?.licenseStates.length +
    selectedData?.nursingSpecialty.length +
    selectedData?.internationalPlacement.length +
    selectedData?.talentAcquisition.length +
    inputList?.term1.length +
    inputList?.term2.length +
    inputList?.term3.length +
    skillInputList?.term1.length +
    skillInputList?.term2.length +
    skillInputList?.term3.length;

  const renderFilter = () => {
    return (
      <div className="hero-card-header ">
        <div className=" card card-flat card-lg ">
          <div className="card-body pb-0 d-flex flex-column h-100">
            <Filter
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              inputList={inputList}
              setInputList={setInputList}
              skillInputList={skillInputList}
              setSkillInputList={setSkillInputList}
              isFilterApplied={false}
              filterParams={filterParams}
            />
          </div>
        </div>
        <div className="row mt-3">
          <SidePanelHeader
            jobData={poolData}
            setSideView={setSideView}
            sideView={sideView}
          />
          <SidepanelDetails
            jobData={poolData}
            sideView={sideView}
            setLoading={setLoading}
            setPoolData={setPoolData}
          />
        </div>
      </div>
    );
  };

  return (
    <>
      {(loading ||
        dispositionLoading ||
        dispositionCountLoading ||
        dispositionDisqualifyLoading) && <Loader />}
      <div id="RequestView">
        <div className="hero">
          <div className="section-100 pb-0">
            <div className=" container-fluid">
              {poolData && Object.keys(poolData)?.length > 0 && (
                <PoolDetailsTitle jobData={poolData} />
              )}

              {getUserRole() == "Recruiter" && (
                <>
                  <div className="row">
                    <div
                      className="col-6 d-flex"
                      style={{ justifyContent: "start" }}
                    >
                      {/* <BreadCrumbs /> */}
                    </div>
                    <div
                      className="col-6 mb-1 d-flex"
                      style={{ justifyContent: "end" }}
                    >
                      <Search
                        dispositionDetails={dispositionDetails}
                        filterData={filterData}
                        setFilterData={setFilterData}
                        view={dispositionView}
                        value={value}
                        setValue={setValue}
                      />
                      <button
                        className="btn btn-info ml-1"
                        onClick={() => setInvite(true)}
                      >
                        <i
                          aria-hidden="true"
                          class="far fa-plus fa-fw mr-1"
                        ></i>
                        Invite Talent
                      </button>
                    </div>
                  </div>
                </>
              )}
              <div class="row justify-content-center">
                <div class="col-xl-12">
                  <div className="row d-flex justify-content-between">
                    <div className="col-xl-4 d-none-1200">
                      {<div className="">{renderFilter()}</div>}
                    </div>

                    {/* <SidePanelHeader
                      jobData={poolData}
                      setSideView={setSideView}
                      sideView={sideView}
                    /> */}

                    <DispositionHeader
                      view={dispositionView}
                      setView={setDispositionView}
                      jobData={poolData}
                      dispositionDetails={dispositionDetails}
                      isFrom="TalentPool"
                      filterData={filterData}
                      setFilterData={setFilterData}
                      dispositionCount={dispositionCount}
                      dispositionCountLoading={dispositionCountLoading}
                      fetchData={fetchData}
                      filterLength={filterLength}
                      selectedTalents={selectedTalents}
                      setSelectedTalents={setSelectedTalents}
                      Tab={Tab}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-5">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-xl-12 ">
                <div class="row d-flex justify-content-between">
                  <div class="col-xl-3">
                    {/* <SidepanelDetails jobData={poolData} sideView={sideView} /> */}
                  </div>
                  {
                    <DispositionDetails
                      view={dispositionView}
                      jobData={poolData}
                      dispositionDetails={dispositionDetails}
                      dispositionDisqualify={dispositionDisqualify}
                      filterData={filterData}
                      setFilterData={setFilterData}
                      filterParams={filterParams}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                      filterLength={filterLength}
                      selectedTalents={selectedTalents}
                      setSelectedTalents={setSelectedTalents}
                    />
                  }
                  {/* Disposition comes here */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {invite && (
          <InviteTalent
            shareButton={invite}
            setShareButton={setInvite}
            jobData={poolData}
            isFrom="TalentPool"
          />
        )}{" "}
        {showDetails && (
          <Modal show={showDetails} onHide={() => {}} size="lg">
            <div className="bl-modal-header">
              <h5 className="bl-modal-title">{"Job detail"}</h5>
              <div className="bl-modal-header">
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowDetails(false)}
                  className="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Modal.Body>
                <div className="row ">
                  <SidePanelHeader
                    jobData={poolData}
                    setSideView={setSideView}
                    sideView={sideView}
                  />
                  <SidepanelDetails
                    jobData={poolData}
                    sideView={sideView}
                    setLoading={setLoading}
                    setPoolData={setPoolData}
                  />
                </div>
                <hr />
                <div className="row text-end">
                  <div className="w-75"></div>
                  <div className="w-25">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => setShowDetails(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default PoolDetails;
