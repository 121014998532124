import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PoolList from "../../directsourcing/talentcommunity/poollist/PoolList";

const TalentCommunity = () => {
  const [poolsTotal, setPoolsTotal] = useState(0);
  const { loading, talentPoolData } = useSelector(
    (state) => ({
      loading: state.getTalentCommunityReducer.loading,
      talentPoolData: state.getTalentCommunityReducer.talentPoolData,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (talentPoolData) {
      setPoolsTotal(talentPoolData.total);
    }
  }, [talentPoolData]);
  return (
    <div className="m-2">
      <div className="mb-2 mt-4">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <h6 className="m-0">Communities ({poolsTotal})</h6>
        </div>
      </div>
      <PoolList />
    </div>
  );
};

export default TalentCommunity;
