import React, { useState, useEffect } from "react";
import TopNavBar from "../../layout/TopNavBar";
import SideNav from "../../layout/SideNavBar";
import Modal from "react-bootstrap/Modal";
import CandidateRequestDetails from "./CandidateRequestDetails";
import { get, post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import {
  High5DarkIcon,
  domain,
  countryObj,
} from "../../../constants/Constants";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import Sort from "../../common/Sort";
import FilterJobs from "./FilterJobs";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";

function CandidateRequestList() {
  const [toggle, setToggle] = useState(true);
  const [matched, setMatched] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [matchedTab, setMatchedTab] = useState(true);
  const [favoritedTab, setFavoritedTab] = useState(false);
  const [appliedTab, setAppliedTab] = useState(false);
  const [rtrTab, setRTRTab] = useState(false);
  const [listView, setListView] = useState(true);
  const [gridView, setGridView] = useState(false);
  const [jobDetails, setJobDetails] = useState([]);
  const [openJobPopUP, setOpenJobPopUp] = useState(false);
  const [type, setType] = useState("");
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [jobListData, setJobListData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortModal, setSortModal] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );

  const sortObject = [
    {
      id: 1,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Type",
      value: "jobType",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Location",
      value: "location.city",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },

    // {
    //     id: 4,
    //     label: "Preferred Start Date",
    //     value: "location.city",
    //     icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    // },
    // {
    //     id: 5,
    //     label: " New Candidates",
    //     value: "location.city",
    //     icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    // },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  const fetchAutoMatchedJobs = async () => {
    setLoading(true);
    let requestTitle = [];
    let requestType = [];
    try {
      let res = await get("/CandidateDashboard/postJobautomatchMatch");
      setMatched(res.data);
      setFiltered(res.data);
      res.data.forEach((job) => {
        if (!requestTitle.includes(job.jobTitle)) {
          requestTitle.push(job.jobTitle);
        }
        if (!requestType.includes(job.jobType)) {
          requestType.push(job.jobType);
        }
      });
      setRequestTitles(requestTitle);
      setRequestTypes(requestType);
      getRequestList(res.data);
      setLoading(false);
    } catch (err) {
      toast.error("Failed to get Candidate Jobs");
      setLoading(false);
    }
  };

  const getRequestList = async (automatch) => {
    setLoading(true);
    const params = {
      skip: 0,
      domain: domain,
    };
    try {
      const res = await post(`/requestlist/getallrequestslist`, params);
      const result = res.data.jobData
        .filter(
          (item) => ["open", "active"].includes(item.jobStatus) && item.isPublic
        )
        .sort((a, b) => {
          let fa = new Date(a.jobPostDate),
            fb = new Date(b.jobPostDate);
          if (fa > fb) {
            return -1;
          }
          if (fa < fb) {
            return 1;
          }
          return 0;
        });
      let finalResult = [...result];
      if (automatch.length > 0) {
        let ids = automatch.map((i) => i.jobId);
        finalResult = [...finalResult.filter((i) => !ids.includes(i.jobId))];
      }
      setJobListData(finalResult);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const fetchRTR = async () => {
    setLoading(true);
    let requestTitle = [];
    let requestType = [];
    try {
      let res = await post("/CandidateDashboard/fetchRTR", {
        filterValue: "Submitted",
      });
      setMatched([...matched, ...res.data]);
      setFiltered([...matched, ...res.data]);
      res.data.forEach((job) => {
        if (!requestTitle.includes(job.jobTitle)) {
          requestTitle.push(job.jobTitle);
        }
        if (!requestType.includes(job.jobType)) {
          requestType.push(job.jobType);
        }
      });
      setRequestTitles([...requestTitles, ...requestTitle]);
      setRequestTypes([...requestTypes, ...requestType]);
      setLoading(false);
    } catch (err) {
      toast.error("Failed to load");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAutoMatchedJobs();
    // fetchRTR()
  }, []);

  const setStatusView = (type) => {
    if (type === "matched") {
      setMatchedTab(true);
      setFavoritedTab(false);
      setAppliedTab(false);
      setRTRTab(false);
    }

    if (type === "favorited") {
      setFavoritedTab(true);
      setMatchedTab(false);
      setAppliedTab(false);
      setRTRTab(false);
    }

    if (type === "applied") {
      setAppliedTab(true);
      setMatchedTab(false);
      setFavoritedTab(false);
      setRTRTab(false);
    }

    if (type === "rtr") {
      setRTRTab(true);
      setMatchedTab(false);
      setFavoritedTab(false);
      setAppliedTab(false);
    }
  };

  const onTabClick = (type) => {
    if (type === "list") {
      setListView(true);
      setGridView(false);
    } else {
      setGridView(true);
      setListView(false);
    }
  };

  const openModal = (item, type) => {
    setJobDetails(item);
    setType(type);
    setOpenSidePanel(true);
  };

  const applySort = (sortedData, close) => {
    close && setSortModal(false);
    setFiltered(sortedData);
    setSelectedTitles([]);
    setSelectedTypes([]);
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(jobListData, true);
  };

  const applyFilter = (selectedTitle, selectedType) => {
    // sortClearAll();
    setSortArray([]);
    setSortCount(0);
    setSelectedTitles(selectedTitle);
    setSelectedTypes(selectedType);
    if (selectedTitle.length > 0 && selectedType.length > 0) {
      setFiltered(
        matched.filter(
          (job) =>
            selectedTitle.includes(job.jobTitle) &&
            selectedType.includes(job.jobType)
        )
      );
    } else if (selectedTitle.length > 0 && selectedType.length === 0) {
      setFiltered(
        matched.filter((job) => selectedTitle.includes(job.jobTitle))
      );
    } else if (selectedType.length > 0 && selectedTitle.length === 0) {
      setFiltered(matched.filter((job) => selectedType.includes(job.jobType)));
    } else {
      setFiltered(matched);
    }
    setFilter(false);
  };

  const formatCurrency = (currency) =>
    currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
    if (value) {
      setMatchedTab(false);
      setAppliedTab(false);
      setFavoritedTab(false);
    } else {
      setMatchedTab(true);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div class="body-decorator body-decorator-top"></div>
        <div class="body-decorator body-decorator-bottom"></div>
        <TopNavBar handleToggale={() => setToggle(!toggle)} />
        <SideNav />
        <div id="Content">
          <section id="AssessmentsApp" class="section section-sm">
            <div class="hero">
              <div class="section pt-5">
                <div class="container">
                  <div class="row justify-content-center mb-5">
                    <div class="d-flex justify-content-between align-items-center col-8 font-white">
                      <h5 class="mb-0">Jobs</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-8">
                  <div
                    statusstart="matched"
                    viewstart="listview"
                    id="CandidateJobs"
                    class="card translate-up-100"
                  >
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-end">
                        <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                          <div class="d-none d-xl-flex nav nav-tabs">
                            <div class="nav-item w-25 d-flex justify-content-center mr-3">
                              <button
                                onClick={() => {
                                  onTabClick("list");
                                }}
                                type="button"
                                className={
                                  listView
                                    ? "nav-link pb-3 bg-transparent active"
                                    : "nav-link pb-3 bg-transparent"
                                }
                              >
                                <i class="fas fa-list"></i>
                              </button>
                            </div>
                            <div class="nav-item w-25 d-flex justify-content-center">
                              <button
                                onClick={() => {
                                  onTabClick("grid");
                                }}
                                type="button"
                                className={
                                  gridView
                                    ? "nav-link pb-3 bg-transparent active"
                                    : "nav-link pb-3 bg-transparent"
                                }
                              >
                                <i class="fas fa-th-large"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end">
                          <ul class="nav nav-tabs nav-tabs-tablet pt-1 w-100 justify-content-center">
                            <li class="nav-item">
                              <button
                                type="button"
                                onClick={() => {
                                  setStatusView("matched");
                                }}
                                className={
                                  matchedTab
                                    ? "nav-link pb-3 mx-2 bg-transparent active"
                                    : "nav-link pb-3 mx-2 bg-transparent"
                                }
                              >
                                <span class="tag mb-1">
                                  {
                                    filtered.filter(
                                      (job) =>
                                        job.status !== "Applied" &&
                                        !job.favorited
                                    ).length
                                  }
                                  {filtered.filter(
                                    (job) =>
                                      job.status !== "Applied" && !job.favorited
                                  ).length > 0 && <span class="jewel"></span>}
                                </span>
                                <div>Matched</div>
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                type="button"
                                onClick={() => {
                                  setStatusView("favorited");
                                }}
                                className={
                                  favoritedTab
                                    ? "nav-link pb-3 mx-2 bg-transparent active"
                                    : "nav-link pb-3 mx-2 bg-transparent"
                                }
                              >
                                <span class="tag mb-1">
                                  {
                                    filtered.filter((job) => job.favorited)
                                      .length
                                  }
                                  {filtered.filter((job) => job.favorited)
                                    .length > 0 && <span class="jewel"></span>}
                                </span>
                                <div>Favorited</div>
                              </button>
                            </li>
                            {/* <li class="nav-item">
                              <button
                                type="button"
                                onClick={() => {
                                  setStatusView("rtr")
                                }}
                                className={
                                  rtrTab
                                    ? "nav-link pb-3 mx-2 bg-transparent active"
                                    : "nav-link pb-3 mx-2 bg-transparent"
                                }
                              >
                                <span class="tag mb-1">0</span>
                                <div>RTR</div>
                              </button>
                            </li> */}
                            <li class="nav-item">
                              <button
                                type="button"
                                onClick={() => {
                                  setStatusView("applied");
                                }}
                                className={
                                  appliedTab
                                    ? "nav-link pb-3 mx-2 bg-transparent active"
                                    : "nav-link pb-3 mx-2 bg-transparent"
                                }
                              >
                                <span class="tag mb-1">
                                  {
                                    filtered.filter(
                                      (job) => job.status === "Applied"
                                    ).length
                                  }
                                  {filtered.filter(
                                    (job) => job.status === "Applied"
                                  ).length > 0 && <span class="jewel"></span>}
                                </span>
                                <div>Applied</div>
                              </button>
                            </li>
                          </ul>
                        </div>

                        <div class="d-flex mb-2 align-items-center justify-content-center justify-content-even-400">
                          <div class="d-flex align-items-center justify-content-end w-100 justify-content-even-400">
                            <div class="dropdown-sort dd-filter mx-1">
                              <button
                                type="button"
                                className="btn btn-sm btn-text-accent"
                                onClick={() => setSortModal(true)}
                              >
                                <i className="fad fa-sort-down font-16 mr-1"></i>{" "}
                                Sort / {sortCount}
                              </button>
                              {sortModal && (
                                <OutsideClickHandler
                                  onOutsideClick={() => setSortModal(false)}
                                >
                                  <Sort
                                    sortData={filtered}
                                    orderArray={orderList}
                                    sortObjects={sortObject}
                                    applySort={applySort}
                                    setSortCount={setSortCount}
                                    sortCount={sortCount}
                                    setSortArray={setSortArray}
                                    sortArray={sortArray}
                                    sortingFor={
                                      "Sort by Job Title, Type, and Location of Requests"
                                    }
                                  />
                                </OutsideClickHandler>
                              )}
                            </div>
                            <div class="dropdown position-relative">
                              <button
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Filter"
                                onClick={() => {
                                  setFilter(true);
                                  setMatchedTab(true);
                                }}
                                type="button"
                                class="btn btn-sm btn-text-accent w-auto"
                              >
                                <i class="fas fa-filter"></i>
                                <span>Filter</span> /
                                <span>
                                  {selectedTitles.length + selectedTypes.length}
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr class="mt-0 mb-2" />
                      <div>
                        {!search && matched.length !== 0 ? (
                          <>
                            <div
                              className={
                                gridView
                                  ? "row d-none d-flex-1200 d-flex-force"
                                  : "row d-none d-flex-1200"
                              }
                            >
                              {matchedTab &&
                                (filtered.filter(
                                  (job) =>
                                    job.status !== "Applied" && !job.favorited
                                ).length === 0 ? (
                                  selectedTitles.length > 0 ||
                                  selectedTypes.length > 0 ? (
                                    <h6 className="text-center my-5">
                                      No Automatches Found Matching Filter
                                      Criteria
                                    </h6>
                                  ) : (
                                    <h6 className="text-center my-5">
                                      No Automatches Found
                                    </h6>
                                  )
                                ) : (
                                  filtered
                                    .filter(
                                      (job) =>
                                        job.status !== "Applied" &&
                                        !job.favorited
                                    )
                                    .map((request) => (
                                      <div class="col-lg-4  d-flex">
                                        <a
                                          class="card card-flat col card-tag-bottom mb-4 card-lg"
                                          onClick={() => {
                                            openModal(request, "matched");
                                          }}
                                          href="#top"
                                        >
                                          <div class="card-body pb-1">
                                            <h6>{request.jobTitle}</h6>
                                            <div class="mb-2">
                                              <span class="tag tag-coral d-inline-flex">
                                                <img
                                                  width="16px"
                                                  src={High5DarkIcon}
                                                />
                                                <span class="mx-2">
                                                  Matched
                                                </span>
                                              </span>{" "}
                                              {"  "}
                                              <small class="font-regular font-primary">
                                                {request.jobType}
                                              </small>
                                            </div>
                                            <hr class="mt-0 mb-2" />
                                            {request.fullText.location?.city &&
                                            request.fullText.location?.state &&
                                            request.fullText.location
                                              ?.country ? (
                                              <>
                                                <p class="mb-1">
                                                  <i class="fal fa-fw fa-map-marker-alt mr-3"></i>
                                                  {
                                                    request.fullText.location
                                                      .city
                                                  }
                                                  ,{" "}
                                                  {
                                                    request.fullText.location
                                                      ?.state
                                                  }
                                                  ,{" "}
                                                  {
                                                    request.fullText.location
                                                      .country
                                                  }
                                                </p>
                                              </>
                                            ) : (
                                              <>
                                                <i class="fal fa-fw fa-map-marker-alt mr-3"></i>
                                                <span>{"Remote"}</span>
                                              </>
                                            )}
                                            <p class="mb-1">
                                              <i class="fal fa-piggy-bank fa-map-marker-alt mr-3"></i>
                                              {formatCurrency(
                                                request.minimumPay
                                              ) +
                                                "-" +
                                                formatCurrency(
                                                  request.maximumPay
                                                ) +
                                                " " +
                                                request.salaryCurrency}
                                            </p>
                                            <p class="mb-1">
                                              <i class="fal fa-fw fa-clock mr-3"></i>
                                              {moment(
                                                new Date(request.updatedDate),
                                                "YYYYMMDD"
                                              ).fromNow()}
                                            </p>
                                          </div>
                                          {/* {request.autoMatchCnt > 0 ? (
                                              <div class="tag tag-footer tag-green3 justify-content-center">
                                                New
                                              </div>
                                            ) : (
                                              <div class="tag tag-footer font-regular justify-content-center">
                                                Viewed on 1/1/2021
                                              </div>
                                          )} */}
                                        </a>
                                      </div>
                                    ))
                                ))}

                              {favoritedTab &&
                                (filtered.filter((job) => job.favorited)
                                  .length === 0 ? (
                                  selectedTitles.length > 0 ||
                                  selectedTypes.length > 0 ? (
                                    <h6 className="text-center my-5">
                                      No Favorites Found Matching Filter
                                      Criteria
                                    </h6>
                                  ) : (
                                    <h6 className="text-center my-5">
                                      No Favorites Found
                                    </h6>
                                  )
                                ) : (
                                  filtered
                                    .filter((job) => job.favorited)
                                    .map((request) => (
                                      <div class="col-lg-4  d-flex">
                                        <a
                                          class="card card-flat col card-tag-bottom mb-4 card-lg"
                                          onClick={() => {
                                            openModal(request, "favorited");
                                          }}
                                          href="#top"
                                        >
                                          <div class="card-body pb-1">
                                            <h6>{request.jobTitle}</h6>
                                            <div class="mb-2">
                                              <span class="tag tag-purple1 d-inline-flex mr-2">
                                                <i
                                                  aria-hidden="true"
                                                  class="fad fa-heart-circle"
                                                ></i>
                                                <span class="mx-2">
                                                  Favorited
                                                </span>
                                              </span>{" "}
                                              {"  "}
                                              <small class="font-regular font-primary">
                                                {request.jobType}
                                              </small>
                                            </div>
                                            <hr class="mt-0 mb-2" />
                                            {request.fullText.location?.city &&
                                            request.fullText.location?.state &&
                                            request.fullText.location
                                              ?.country ? (
                                              <>
                                                <p class="mb-1">
                                                  <i class="fal fa-fw fa-map-marker-alt mr-1"></i>
                                                  {
                                                    request.fullText.location
                                                      .city
                                                  }
                                                  ,{" "}
                                                  {
                                                    request.fullText.location
                                                      ?.state
                                                  }
                                                  ,{" "}
                                                  {
                                                    request.fullText.location
                                                      .country
                                                  }
                                                </p>
                                              </>
                                            ) : (
                                              <>
                                                <i class="fal fa-fw fa-map-marker-alt mr-3"></i>
                                                <span>{"Remote"}</span>
                                              </>
                                            )}
                                            {
                                              <p class="mb-1">
                                                <i class="fal fa-piggy-bank fa-map-marker-alt mr-1"></i>
                                                {formatCurrency(
                                                  request.minimumPay
                                                ) +
                                                  "-" +
                                                  formatCurrency(
                                                    request.maximumPay
                                                  ) +
                                                  " " +
                                                  request.salaryCurrency}
                                              </p>
                                            }
                                            <p class="mb-1">
                                              <i class="fal fa-fw fa-clock mr-3"></i>
                                              {moment(
                                                new Date(request.updatedDate),
                                                "YYYYMMDD"
                                              ).fromNow()}
                                            </p>
                                          </div>
                                          {/* {request.autoMatchCnt > 0 ? (
                                      <div class="tag tag-footer tag-green3 justify-content-center">
                                        New
                                      </div>
                                    ) : (
                                      <div class="tag tag-footer font-regular justify-content-center">
                                        Viewed on 1/1/2021
                                      </div>
                                    )} */}
                                        </a>
                                      </div>
                                    ))
                                ))}

                              {appliedTab &&
                                (filtered.filter(
                                  (job) => job.status === "Applied"
                                ).length === 0 ? (
                                  selectedTitles.length > 0 ||
                                  selectedTypes.length > 0 ? (
                                    <h6 className="text-center my-5">
                                      No Applied Jobs Found Matching Filter
                                      Criteria
                                    </h6>
                                  ) : (
                                    <h6 className="text-center my-5">
                                      You have Not Applied For Any Jobs Yet
                                    </h6>
                                  )
                                ) : (
                                  filtered
                                    .filter((job) => job.status === "Applied")
                                    .map((request) => (
                                      <div class="col-lg-4 d-flex">
                                        <a
                                          class="card card-flat col card-tag-bottom mb-4 card-lg"
                                          onClick={() => {
                                            openModal(request, "applied");
                                          }}
                                          href="#top"
                                        >
                                          <div class="card-body pb-1">
                                            <h6>{request.jobTitle}</h6>
                                            <div class="mb-2">
                                              <span class="tag tag-green1">
                                                Applied
                                              </span>{" "}
                                              {"  "}
                                              <small class="font-regular font-primary">
                                                {request.jobType}
                                              </small>
                                            </div>
                                            <hr class="mt-0 mb-2" />
                                            {request.fullText.location?.city &&
                                            request.fullText.location?.state &&
                                            request.fullText.location
                                              ?.country ? (
                                              <>
                                                <p class="mb-1">
                                                  <i class="fal fa-fw fa-map-marker-alt mr-1"></i>
                                                  {
                                                    request.fullText.location
                                                      .city
                                                  }
                                                  ,{" "}
                                                  {
                                                    request.fullText.location
                                                      ?.state
                                                  }
                                                  ,{" "}
                                                  {
                                                    request.fullText.location
                                                      .country
                                                  }
                                                </p>
                                              </>
                                            ) : (
                                              <>
                                                <i class="fal fa-fw fa-map-marker-alt mr-1"></i>
                                                <span>{"Remote"}</span>
                                              </>
                                            )}
                                            {
                                              <p class="mb-1">
                                                <i class="fal fa-piggy-bank fa-map-marker-alt mr-3"></i>
                                                {formatCurrency(
                                                  request.minimumPay
                                                ) +
                                                  "-" +
                                                  formatCurrency(
                                                    request.maximumPay
                                                  ) +
                                                  " " +
                                                  request.salaryCurrency}
                                              </p>
                                            }
                                            <p class="mb-1">
                                              <i class="fal fa-fw fa-clock mr-3"></i>
                                              {moment(
                                                new Date(request.updatedDate),
                                                "YYYYMMDD"
                                              ).fromNow()}
                                            </p>
                                          </div>
                                          {/* {request.autoMatchCnt > 0 ? (
                                              <div class="tag tag-footer tag-green3 justify-content-center">
                                                New
                                              </div>
                                            ) : (
                                              <div class="tag tag-footer font-regular justify-content-center">
                                                Viewed on 1/1/2021
                                              </div>
                                          )} */}
                                        </a>
                                      </div>
                                    ))
                                ))}
                            </div>

                            <div
                              className={
                                listView
                                  ? "row d-none d-none-1200 d-block-force"
                                  : "row d-none d-none-1200"
                              }
                            >
                              <div class="col">
                                {matchedTab &&
                                  (filtered.filter(
                                    (job) =>
                                      job.status !== "Applied" && !job.favorited
                                  ).length === 0 ? (
                                    selectedTitles.length > 0 ||
                                    selectedTypes.length > 0 ? (
                                      <h6 className="text-center my-5">
                                        No Automatches Found Matching Filter
                                        Criteria
                                      </h6>
                                    ) : (
                                      <h6 className="text-center my-5">
                                        No Automatches Found
                                      </h6>
                                    )
                                  ) : (
                                    filtered
                                      .filter(
                                        (job) =>
                                          job.status !== "Applied" &&
                                          !job.favorited
                                      )
                                      .map((item) => (
                                        <a
                                          onClick={() => {
                                            openModal(item, "matched");
                                          }}
                                          class="card card-flat p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
                                        >
                                          <div class="card-body">
                                            <span class="tag tag-coral d-inline-flex mr-2">
                                              <img
                                                width="16px"
                                                src={High5DarkIcon}
                                              />
                                              <span class="mx-2">Matched</span>
                                            </span>
                                            <h6 class="mt-1">
                                              {item.jobTitle}
                                            </h6>
                                            <div class="d-flex flex-wrap align-items-center">
                                              <span class="d-flex align-items-center">
                                                <i
                                                  aria-hidden="true"
                                                  class="far fa-suitcase me-2 font-brandBlue"
                                                ></i>
                                                <span class="font-regular font-primary font-gray1">
                                                  {item.jobType}
                                                </span>
                                              </span>
                                              {item.fullText.location?.city &&
                                              item.fullText.location?.state &&
                                              item.fullText.location
                                                ?.country ? (
                                                <>
                                                  <p class="mb-1">
                                                    <i class="fal fa-fw fa-map-marker-alt mr-1"></i>
                                                    {
                                                      item.fullText.location
                                                        .city
                                                    }
                                                    ,{" "}
                                                    {
                                                      item.fullText.location
                                                        ?.state
                                                    }
                                                    ,{" "}
                                                    {
                                                      item.fullText.location
                                                        .country
                                                    }
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  <i class="fal fa-fw fa-map-marker-alt mr-1"></i>
                                                  <span>{"Remote"}</span>
                                                </>
                                              )}
                                              <span class="d-flex align-items-center ml-3">
                                                <i
                                                  aria-hidden="true"
                                                  class="far fa-piggy-bank me-2 font-brandBlue"
                                                ></i>
                                                <span class="font-regular font-primary font-gray1">
                                                  {formatCurrency(
                                                    item.minimumPay
                                                  ) +
                                                    "-" +
                                                    formatCurrency(
                                                      item.maximumPay
                                                    ) +
                                                    " " +
                                                    item.salaryCurrency}
                                                </span>
                                              </span>
                                            </div>{" "}
                                            <div>
                                              <span class="d-flex align-items-center mt-2">
                                                <span class="font-regular">
                                                  {moment(
                                                    new Date(item.updatedDate),
                                                    "YYYYMMDD"
                                                  ).fromNow()}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </a>
                                      ))
                                  ))}

                                {favoritedTab &&
                                  (filtered.filter((job) => job.favorited)
                                    .length === 0 ? (
                                    selectedTitles.length > 0 ||
                                    selectedTypes.length > 0 ? (
                                      <h6 className="text-center my-5">
                                        No Favorites Found Matching Filter
                                        Criteria
                                      </h6>
                                    ) : (
                                      <h6 className="text-center my-5">
                                        No Favorites Found
                                      </h6>
                                    )
                                  ) : (
                                    filtered
                                      .filter((job) => job.favorited)
                                      .map((item) => (
                                        <a
                                          onClick={() => {
                                            openModal(item, "favorited");
                                          }}
                                          class="card card-flat p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
                                        >
                                          <div class="card-body">
                                            <span class="tag tag-purple1 d-inline-flex mr-2">
                                              <i
                                                aria-hidden="true"
                                                class="fad fa-heart-circle"
                                              ></i>
                                              <span class="mx-2">
                                                Favorited
                                              </span>
                                            </span>
                                            <h6 class="mt-1">
                                              {item.jobTitle}
                                            </h6>
                                            <div class="d-flex flex-wrap align-items-center">
                                              <span class="d-flex align-items-center">
                                                <i
                                                  aria-hidden="true"
                                                  class="far fa-suitcase me-2 font-brandBlue"
                                                ></i>
                                                <span class="font-regular font-primary font-gray1">
                                                  {item.jobType}
                                                </span>
                                              </span>
                                              {item.location.address && (
                                                <span class="d-flex align-items-center ml-3">
                                                  <i
                                                    aria-hidden="true"
                                                    class="far fa-map-marker-alt me-2 font-brandBlue"
                                                  ></i>
                                                  <span class="font-regular font-primary font-gray1">
                                                    {item.location.address}
                                                  </span>
                                                </span>
                                              )}
                                              <span class="d-flex align-items-center ml-3">
                                                <i
                                                  aria-hidden="true"
                                                  class="far fa-piggy-bank me-2 font-brandBlue"
                                                ></i>
                                                <span class="font-regular font-primary font-gray1">
                                                  {formatCurrency(
                                                    item.minimumPay
                                                  ) +
                                                    "-" +
                                                    formatCurrency(
                                                      item.maximumPay
                                                    ) +
                                                    " " +
                                                    item.salaryCurrency}
                                                </span>
                                              </span>
                                            </div>{" "}
                                            <div>
                                              <span class="d-flex align-items-center mt-2">
                                                <span class="font-regular">
                                                  {moment(
                                                    new Date(item.updatedDate),
                                                    "YYYYMMDD"
                                                  ).fromNow()}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </a>
                                      ))
                                  ))}

                                {appliedTab &&
                                  (filtered.filter(
                                    (job) => job.status === "Applied"
                                  ).length === 0 ? (
                                    selectedTitles.length > 0 ||
                                    selectedTypes.length > 0 ? (
                                      <h6 className="text-center my-5">
                                        No Applied Jobs Found Matching Filter
                                        Criteria
                                      </h6>
                                    ) : (
                                      <h6 className="text-center my-5">
                                        You have Not Applied For Any Jobs Yet
                                      </h6>
                                    )
                                  ) : (
                                    filtered
                                      .filter((job) => job.status === "Applied")
                                      .map((item) => (
                                        <a
                                          onClick={() => {
                                            openModal(item, "applied");
                                          }}
                                          class="card card-flat p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
                                        >
                                          <div class="card-body">
                                            <span class="tag tag-green1">
                                              Applied
                                            </span>{" "}
                                            {"  "}
                                            <h6 class="mt-1">
                                              {item.jobTitle}
                                            </h6>
                                            <div class="d-flex flex-wrap align-items-center">
                                              <span class="d-flex align-items-center">
                                                <i
                                                  aria-hidden="true"
                                                  class="far fa-suitcase me-2 font-brandBlue"
                                                ></i>
                                                <span class="font-regular font-primary font-gray1">
                                                  {item.jobType}
                                                </span>
                                              </span>
                                              {item.fullText.location?.city &&
                                              item.fullText.location?.state &&
                                              item.fullText.location
                                                ?.country ? (
                                                <>
                                                  <p class="mb-1">
                                                    <i class="fal fa-fw fa-map-marker-alt mr-1"></i>
                                                    {
                                                      item.fullText.location
                                                        .city
                                                    }
                                                    ,{" "}
                                                    {
                                                      item.fullText.location
                                                        ?.state
                                                    }
                                                    ,{" "}
                                                    {
                                                      item.fullText.location
                                                        .country
                                                    }
                                                  </p>
                                                </>
                                              ) : (
                                                <>
                                                  <i class="fal fa-fw fa-map-marker-alt mr-2"></i>
                                                  <span className="font-14">
                                                    {"Remote"}
                                                  </span>
                                                </>
                                              )}
                                              <span class="d-flex align-items-center ml-3">
                                                <i
                                                  aria-hidden="true"
                                                  class="far fa-piggy-bank me-2 font-brandBlue"
                                                ></i>
                                                <span class="font-regular font-primary font-gray1">
                                                  {formatCurrency(
                                                    item.minimumPay
                                                  ) +
                                                    "-" +
                                                    formatCurrency(
                                                      item.maximumPay
                                                    ) +
                                                    " " +
                                                    item.salaryCurrency}
                                                </span>
                                              </span>
                                            </div>{" "}
                                            <div>
                                              <span class="d-flex align-items-center mt-2">
                                                <span class="font-regular">
                                                  {moment(
                                                    new Date(item.updatedDate),
                                                    "YYYYMMDD"
                                                  ).fromNow()}
                                                </span>
                                              </span>
                                            </div>
                                          </div>
                                        </a>
                                      ))
                                  ))}
                              </div>
                            </div>
                          </>
                        ) : (
                          !search && (
                            <div>
                              <div class="text-center p-3">
                                <div class="avatar avatar-lg">
                                  <i class="fad fa-folder-open"></i>
                                </div>
                                <div class="mt-2">No Jobs Found</div>
                              </div>
                            </div>
                          )
                        )}
                        {search && jobListData.length > 0 && (
                          <>
                            <div
                              className={
                                listView
                                  ? "row d-none d-none-1200 d-block-force"
                                  : "row d-none d-none-1200"
                              }
                            >
                              <div class="col">
                                {jobListData
                                  .filter((item) =>
                                    item.jobTitle
                                      .toLowerCase()
                                      .includes(search.toLowerCase())
                                  )
                                  .map((item) => (
                                    <a
                                      onClick={() => {
                                        openModal(item, "search");
                                      }}
                                      class="card card-flat p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
                                    >
                                      <div class="card-body">
                                        <h6 class="mt-1">{item.jobTitle}</h6>
                                        <div class="d-flex flex-wrap align-items-center">
                                          <span class="d-flex align-items-center">
                                            <i
                                              aria-hidden="true"
                                              class="far fa-suitcase me-2 font-brandBlue"
                                            ></i>
                                            <span class="font-regular font-primary font-gray1">
                                              {item.jobType}
                                            </span>
                                          </span>
                                          {item.fullText.location?.city &&
                                          item.fullText.location?.state &&
                                          item.fullText.location?.country ? (
                                            <>
                                              <p class="mb-1">
                                                <i class="fal fa-fw fa-map-marker-alt mr-3"></i>
                                                {item.fullText.location.city},{" "}
                                                {item.fullText.location?.state},{" "}
                                                {item.fullText.location.country}
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <i class="fal fa-fw fa-map-marker-alt mr-2"></i>
                                              <span className="font-14">
                                                {"Remote"}
                                              </span>
                                            </>
                                          )}
                                          <span class="d-flex align-items-center ml-3">
                                            <i
                                              aria-hidden="true"
                                              class="far fa-piggy-bank me-2 font-brandBlue"
                                            ></i>
                                            <span class="font-regular font-primary font-gray1">
                                              {item.minimumPay +
                                                "-" +
                                                item.maximumPay +
                                                " " +
                                                item.salaryCurrency}
                                            </span>
                                          </span>
                                        </div>{" "}
                                        <div>
                                          <span class="d-flex align-items-center mt-2">
                                            <span class="font-regular">
                                              {moment(
                                                new Date(item.jobPostDate),
                                                "YYYYMMDD"
                                              ).fromNow()}
                                            </span>
                                          </span>
                                        </div>
                                      </div>
                                    </a>
                                  ))}
                              </div>
                            </div>
                            <div
                              className={
                                gridView
                                  ? "row d-none d-flex-1200 d-flex-force"
                                  : "row d-none d-flex-1200"
                              }
                            >
                              {jobListData
                                .filter((item) =>
                                  item.jobTitle
                                    .toLowerCase()
                                    .includes(search.toLowerCase())
                                )
                                .map((request) => (
                                  <div class="col-lg-4 d-flex">
                                    <a
                                      class="card card-flat col card-tag-bottom mb-4 card-lg"
                                      onClick={() => {
                                        openModal(request, "applied");
                                      }}
                                      href="#top"
                                    >
                                      <div class="card-body pb-1">
                                        <h6>{request.jobTitle}</h6>
                                        <div class="mb-2">
                                          <small class="font-regular font-primary">
                                            {request.jobType}
                                          </small>
                                        </div>
                                        <hr class="mt-0 mb-2" />
                                        {request.location && (
                                          <p class="mb-1">
                                            <i class="fal fa-fw fa-map-marker-alt mr-3"></i>
                                            {request.location.address ||
                                              "Remote"}
                                          </p>
                                        )}
                                        {
                                          <p class="mb-1">
                                            <i class="fal fa-piggy-bank fa-map-marker-alt mr-3"></i>
                                            {formatCurrency(
                                              request.minimumPay
                                            ) +
                                              "-" +
                                              formatCurrency(
                                                request.maximumPay
                                              ) +
                                              " " +
                                              request.salaryCurrency}
                                          </p>
                                        }
                                        <p class="mb-1">
                                          <i class="fal fa-fw fa-clock mr-3"></i>
                                          {moment(
                                            new Date(request.jobPostDate),
                                            "YYYYMMDD"
                                          ).fromNow()}
                                        </p>
                                      </div>
                                    </a>
                                  </div>
                                ))}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
      {openSidePanel && (
        <CandidateRequestDetails
          type={type}
          job={jobDetails}
          closeModal={() => setOpenSidePanel(false)}
          fetchData={fetchAutoMatchedJobs}
        />
      )}
      {filter && (
        <FilterJobs
          requestTitles={requestTitles}
          requestTypes={requestTypes}
          selectedTitles={selectedTitles}
          selectedTypes={selectedTypes}
          applyFilter={applyFilter}
        />
      )}
    </>
  );
}
export default CandidateRequestList;
