import React, { useState, useEffect } from "react";
import sparkles from "../../../assets/lottie/sparkles.json";
import checkMark from "../../../assets/lottie/checkmark.json";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";
import Select from "react-select";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";

const { Anime } = ReactAnime;

const customStyles = {
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#dcedff",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999, width: "500px" }),
};

function AssignUsers(props) {
  //console.log(props,"assign")
  const [submit, setsubmit] = useState(false);
  const [loop] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tenantData, setTenantData] = useState([]);
  const [dropDownData, setDropdownData] = useState([]);
  const [value, setValue] = useState([]);

  useEffect(() => {
    fetchTenant();
  }, []);

  const fetchTenant = async () => {
    setLoading(true);
    const payload = {
      userId: props.userData.userid,
    };
    const res = await post("/assigntenant/fetchtenant", payload);
    console.log("res.data[0]", res.data[0]);
    if (props.userData.type === "High5Coordinator") {
      let filterData = [];
      if (res.data[0]) {
        filterData = res.data[0].filter((item) => item.fk_tenanttype === 1);
      }
      setDropdownData(filterData);
    }
    if (props.userData.type === "CommunityManager") {
      let filterData = [];
      if (res.data[0]) {
        filterData = res.data[0].filter((item) => item.fk_tenanttype === 2);
      }
      setDropdownData(filterData);
    }
    setLoading(false);
  };
  //     setLoading(false);
  //   };

  const close = () => {
    props.handleClose(true);
  };

  const submitfun = async () => {
    setLoading(true);
    try {
      const payload = {
        userId: props.userData.userid,
        email: props.userData.email,
        name: props.userData.name,
        userType: props.userData.type,
        tenantData,
      };
      const res = await post("/assigntenant/assigntenant", payload);
      if (res.status === 200) {
        setLoading(false);
        setsubmit(true);
      } else {
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  const handleChange = (e) => {
    setValue(e);
    setTenantData(
      e.map((tenant) => {
        return {
          tenantId: tenant.value,
          tenantName: tenant.label,
          tenantEmail: tenant.tenantEmail,
          tenantFirstName: tenant.tenantFirstName,
        };
      })
    );
  };

  const option = dropDownData.map((item) => {
    const obj = {
      value: item.id_tenant,
      label: item.tenantname.replace("GIG - ", ""),
      tenantEmail: item.tenantemail,
      tenantFirstName: item.firstName,
    };
    return obj;
  });

  return (
    <>
      {loading && <Loader />}
      <div class="bl-modal reveal-overlay reveal-card">
        <div class="bl-modal-overlay"></div>
        <div class="bl-modal-container">
          <div class="bl-modal-card bl-modal-card-md">
            <div class="bl-modal-header">
              <h6 class="bl-modal-title">Assign Tenant</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                class="close p-0 bl-modal-close-btn"
                onClick={close}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            {submit === false ? (
              <>
                <div class="bl-modal-body pb-0">
                  <div class="card card-flat card-borderless bg-gray4 text-left">
                    <div class="card-body">
                      <div class="container">
                        <div class="row">
                          <div class="col-lg-12 text-left text-left">
                            <label class="pt-0">Select Tenants</label>
                          </div>
                          <Select
                            isMulti
                            value={value}
                            options={option}
                            styles={customStyles}
                            onChange={handleChange}
                            isSearchable={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer mt-4 mb-0">
                  <button
                    type="button"
                    data-dismiss="modal"
                    class="btn btn-secondary mr-2"
                    onClick={close}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    class="btn btn-primary"
                    onClick={submitfun}
                    disabled={value.length === 0}
                  >
                    <span>Submit</span>
                  </button>
                </div>
              </>
            ) : (
              <div
                class="section section-sm pb-0 d-block"
                style={{ display: "none" }}
              >
                <div
                  id="EmployeeManageSuccessAnimation"
                  class="success-animation"
                >
                  <div class="checkmark">
                    <Lottie loop={loop} animationData={checkMark} play></Lottie>
                    {/* <lottie-player id="LottieCheckmark" background="transparent" src="/lottie/checkmark.json" speed="1" autoplay=""></lottie-player> */}
                  </div>
                  <Anime
                    initial={[
                      {
                        targets: "#LottieSparkles",
                        opacity: 0,
                        delay: 1000,
                      },
                    ]}
                  >
                    <div class="sparkles">
                      <Lottie
                        loop={loop}
                        animationData={sparkles}
                        id="LottieSparkles"
                        play
                      ></Lottie>
                    </div>
                  </Anime>
                </div>
                <div class="success-message" style={{ opacity: "1" }}>
                  <div class="container">
                    <div class="col-lg-12">
                      <h4 class="text-center">Tenants Assigned!</h4>
                      <div class="text-center"></div>
                      <div class="card card-flat card-lg">
                        <div class="card-body">
                          <div class="mb-2">
                            <label class="d-block font-bold pt-0">Name</label>
                            <div>{props.userData.name}</div>
                          </div>
                          <div class="mb-2">
                            <label class="d-block font-bold pt-0">
                              Tenants
                            </label>
                            <div>
                              <ul>
                                {value?.map((item, index) => (
                                  <li key={index}>{item.label}</li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="text-center py-4">
                        <button
                          type="button"
                          data-dismiss="modal"
                          class="btn btn-secondary mr-2"
                          onClick={close}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default AssignUsers;
