import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import SideNavBar from "../../../layout/SideNavBar";
import ReportTopNavBar from "../../../report/ReportTopNavBar";
import Filter from "./Filter";
import { reportHeaders, sortObject, orderList } from "./reportHeaders";
import ReportHeader from "./ReportHeader";
import ReportTable from "./ReportTable";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import TopNavBar from "../../../layout/TopNavBar";
import { getTenantType } from "../../../../service/AuthService";
import Footer from "../../../layout/Footer";

function Index() {
  const [pageLimit, setPageLimit] = useState(3);
  const [toggle, setToggle] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [columns, setColumns] = useState([]);
  const [dateSearch, setDateSearch] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [toDate, setToDate] = useState(new Date());
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [vetting, setVetting] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [primarySkills, setPrimarySkills] = useState([]);
  const [candidateLocations, setCandidateLocations] = useState([]);
  const [candidateTitles, setCandidateTitles] = useState([]);
  const [candidateSkills, setCandidateSkills] = useState([]);
  const [selectedClient, setSelectedClient] = useState([]);
  const [candidateClients, setCandidateClients] = useState([]);

  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [gigRecruiters, setGigRecruiters] = useState([]);
  const [filterBy, setFilterBy] = useState(reportData);
  const [dataa, setDataa] = useState([]);

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };
  useEffect(() => {
    getCandidateData();
  }, []);

  const getCandidateData = async () => {
    setLoading(true);
    let params = {
      toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
      fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
    };
    let res = await post("/reports/candidate", params);
    if (res.status === 200) {
      let result = res.data;
      if (result.length == 0) {
        toast.error("No record found. Please select another date range");
      }
      // result = result.reduce((accumulator, current) => {
      //   const existingItem = accumulator.find(
      //     (item) =>
      //       item.email === current.email &&
      //       item.jobTitle.trim() === current.jobTitle.trim()
      //   );
      //   if (existingItem) {
      //   } else {
      //     accumulator.push(current);
      //   }
      //   return accumulator;
      // }, []);

      setFilteredData(result);
      setData(result);
      setDataa(result);

      setPaginationArray(
        new Array(Math.ceil(result.length / perPage))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(result.length / perPage))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageLimit)
      );
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);
  //! Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }
  const handleDateSearch = () => {
    setSelectedLocations([]);
    setSelectedClient([]);
    setSelectedTitles([]);
    setPrimarySkills([]);
    setVetting(false);
    //setMounted(true)
    let startDate = moment(new Date(fromDate)).format("MM/DD/YYYY");
    let endDate = moment(new Date(toDate)).format("MM/DD/YYYY");
    if (new Date(startDate) > new Date()) {
      toast.error(`'From Date' cannot be future date`);
    } else if (new Date(endDate) > new Date()) {
      toast.error(`'To Date' cannot be future date`);
    } else if (new Date(startDate) > new Date(endDate)) {
      toast.error(`'From Date' should not be greater than 'To Date'`);
    } else {
      let result = [];

      let title = [];
      let location = [];
      let skills = [];
      setCurrentPage(1);
      result.forEach((item) => {
        if (item.currentJobTitle) {
          if (!title.includes(item.currentJobTitle)) {
            title.push(item.currentJobTitle.trim());
          }
        }
        if (item.primarySkills) {
          if (!skills.includes(item.primarySkills)) {
            skills.push(...item.primarySkills);
          }
        }
        if (item.addressState && item.country) {
          if (!location.includes(`${item.addressState}, ${item.country}`)) {
            location.push(`${item.addressState}, ${item.country}`);
          }
        }
      });
      getCandidateData();
      setCandidateTitles(title);
      setCandidateSkills([...new Set(skills)]);
      setCandidateLocations(location);
      setDateSearch(result);
      setFilteredData(result);
      setDataa(result);
      setPaginationArray(
        new Array(Math.ceil(filteredData.length / perPage))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(filteredData.length / perPage))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageLimit)
      );
    }
  };

  const onChangeSelect = (e) => {
    if (e.target.value === "All") {
      setPerPage(filteredData.length);
      setPaginationArray([1]);
      setPaginationGroup([1]);
      setCurrentPage(1);
    } else {
      // const pageGroup = new Array(Math.ceil(dateSearch.length / e.target.value))
      const pageGroup = new Array(
        Math.ceil(filteredData.length / e.target.value)
      )
        .fill()
        .map((_, i) => i + 1);
      // .slice(0, pageLimit);
      setPaginationArray(pageGroup);
      setPaginationGroup(pageGroup.slice(0, pageLimit));
      setPerPage(Number(e.target.value));
      setCurrentPage(1);
    }
  };
  const getPaginatedData = () => {
    const startIndex = currentPage * perPage - perPage;
    const endIndex = startIndex + +perPage;
    return filteredData.slice(startIndex, endIndex);
  };
  const applySort = (sortedData, close) => {
    close && setSort(false);
    let data = sortedData?.length === 0 ? dataa : sortedData;
    setFilterBy(data);
    setFilteredData(data);
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(filteredData, true);
  };

  const openFilter = () => {
    setFilter(true);
  };
  const applyFilter = (
    finalData,
    selectedTitles,
    selectedLocations,
    selectedClient,
    vetted,
    primarySkills
  ) => {
    sortClearAll();
    setFilter(false);
    setVetting(vetted);
    setSelectedTitles(selectedTitles);
    setSelectedLocations(selectedLocations);
    setSelectedClient(selectedClient);
    // setReportData(finalData)
    setFilteredData(finalData);
    setFilterBy(finalData);
    setPrimarySkills(primarySkills);
    if (finalData.length === 0) {
      return toast.error("No Record Found");
    }
    setCurrentPage(1);
    setPaginationGroup(
      new Array(Math.ceil(finalData.length / perPage))
        .fill()
        .map((_, i) => i + 1)
        .slice(0, pageLimit)
    );
    setPaginationArray(
      new Array(Math.ceil(finalData.length / perPage))
        .fill()
        .map((_, i) => i + 1)
      // .slice(0, pageLimit)
    );
  };

  const recruiterAdd = (name) => {
    let fArr = gigRecruiters?.filter(
      (f) => `${f.firstName} ${f.LastName}` == name
    );
    let address = `${fArr[0]?.city}, ${fArr[0]?.state}, ${fArr[0]?.country}`;
    return address == "undefined, undefined, undefined" ? "NA" : address;
  };
  return (
    <div>
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        {/* <ReportTopNavBar handleToggale={toggleDiv} /> */}
        <TopNavBar isFrom={"Report"} handleToggale={toggleDiv} />
        <SideNavBar handleToggale={toggleDiv} />
        <div id="Content">
          <div className="section section-s">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="card card-flat card-borderless p-4 mb-3">
                    <ReportHeader
                      dataa={dataa}
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      toDate={toDate}
                      setToDate={setToDate}
                      handleDateSearch={handleDateSearch}
                      setSort={setSort}
                      sort={sort}
                      filteredData={filteredData}
                      sortCount={sortCount}
                      orderList={orderList}
                      sortObject={sortObject}
                      applySort={applySort}
                      setSortCount={setSortCount}
                      setSortArray={setSortArray}
                      openFilter={openFilter}
                      selectedTitles={selectedTitles}
                      selectedLocations={selectedLocations}
                      selectedClient={selectedClient}
                      vetting={vetting}
                      primarySkills={primarySkills}
                      onChangeSelect={onChangeSelect}
                      sortArray={sortArray}
                    />
                    {filteredData.length > 0 ? (
                      <ReportTable
                        getPaginatedData={getPaginatedData}
                        reportHeaders={reportHeaders}
                        currentPage={currentPage}
                        perPage={perPage}
                        recruiterAdd={recruiterAdd}
                      />
                    ) : (
                      <NoRecord />
                    )}
                    {getPaginatedData().length > 0 && (
                      <div className="d-flex justify-content-end pt-4 px-1">
                        <div className="w-100-370 mb-point5-370">
                          Showing{" "}
                          {(getPaginatedData().length === perPage
                            ? perPage * currentPage
                            : perPage * (currentPage - 1) +
                              getPaginatedData().length) + " "}
                          of {filteredData?.length}
                        </div>
                        <div
                          className="ml-auto mx-auto-370 d-flex align-items-center"
                          // style={{ position: 'absolute', left: '85%' }}
                        >
                          <button
                            onClick={() => {
                              setPageType("down");
                              setCurrentPage((page) => page - 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === 1 ||
                              getPaginatedData().length === 0
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-left"></i>
                          </button>
                          {getPaginatedData().length > 0
                            ? paginationGroup.map((item) => (
                                <button
                                  type="button"
                                  className={
                                    currentPage === item
                                      ? "btn btn-text px-2 bg-secondary text-white"
                                      : "btn btn-text px-2"
                                  }
                                  onClick={changePage}
                                >
                                  {item}
                                </button>
                              ))
                            : ""}
                          <button
                            onClick={() => {
                              setPageType("up");
                              setCurrentPage((page) => page + 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === paginationArray.slice(-1)[0] ||
                              getPaginatedData().length === 0
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-right"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
      {filter && (
        <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
          <Filter
            reportData={data}
            selectedTitles={selectedTitles}
            candidateTitles={[...new Set(data.map((e) => e.jobTitle))]}
            selectedLocations={selectedLocations}
            // status
            candidateLocations={[...new Set(data.map((e) => e.statusName))]}
            // gig rec
            primarySkills={primarySkills}
            candidateSkills={[...new Set(data.map((e) => e?.recruiterName))]}
            vetting={vetting}
            selectedClient={selectedClient}
            candidateClients={[...new Set(data.map((e) => e?.tenantname))]}
            // fetchHotlistData={fetchHotlistData}
            applyFilter={applyFilter}
          />
        </OutsideClickHandler>
      )}
    </div>
  );
}
export default Index;
