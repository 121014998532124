import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { SidebarLogo, roleNames } from "../../../constants/Constants";
import moment from "moment";

function SideNavBarForCandidateModule({ candidateData }) {
  let path = window.location.href.split("/").slice(-1)[0];

  const [userdetails, setUser] = useState({
    user: "",
    role: "",
    tanent: "",
    userid: "",
  });
  const { user, role, tanent } = userdetails;
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [showAddTalentModal, setShowAddTalentModal] = useState(false);
  const [successData, setSuccessData] = useState({
    jobId: "",
    position: "",
    startDate: "",
    location: "",
    salary: "",
    skills: "",
  });

  const closeAddJobSucces = () => {
    setSuccessFormOpen(false);
  };

  return (
    <>
      {candidateData && Object.keys(candidateData).length > 0 && (
        <div id="Nav">
          <div className="top">
            <div className="d-flex align-items-center">
              <div
                className="brand-logo"
                style={{ backgroundImage: `url(${SidebarLogo})` }}
              ></div>
              {candidateData?.firstName && (
                <div className="title px-2">
                  <h6 className="mb-0">{`${candidateData?.firstName
                    ?.charAt(0)
                    .toUpperCase() +
                    candidateData?.firstName?.slice(
                      1
                    )} ${candidateData?.lastName?.charAt(0).toUpperCase() +
                    candidateData?.lastName?.slice(1)}`}</h6>
                  {/* <small className="mb-0">{`Last login : ${moment(
                  sessionStorage.getItem("lastLogInTime")
                ).format("MM/DD/YYYY")}`}</small> */}
                  <small className="mb-0">
                    {sessionStorage.getItem("lastLogInTime") == null
                      ? `Last login : ${moment(new Date()).fromNow()}`
                      : `Last login : ${moment(
                          sessionStorage.getItem("lastLogInTime")
                        ).fromNow()}`}
                  </small>
                </div>
              )}
            </div>
          </div>

          <div className="body">
            <ul className="menu">
              <li>
                <a
                  href="/careers/profile"
                  className={path === "high5Hire" && "active"}
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Dashboard"
                >
                  <i className="fad fa-fw fa-home-lg-alt mr-3"></i>
                  <span className="nav-text">Dashboard</span>
                </a>
                <a
                  href="/career/home"
                  className={path === "high5Hire" && "active"}
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Search Job"
                >
                  <i className="fad fa-fw fa-briefcase mr-3"></i>
                  <span className="nav-text">Browse Jobs</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default SideNavBarForCandidateModule;
