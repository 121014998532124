import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import ScreeningQuestions from "./ScreeningQuestions";
import "react-tagsinput/react-tagsinput.css";
import Autosuggest from "react-autosuggest";
import { get, post } from "../../../../../service/ApiService";
import ShowVettingModal from "./ShowVettingModal";
import ShowQnAModal from "./ShowQnAModal";
import VettingRequirement from "./VettingRequirement";
//import VettingRequirement from "./OGVettingReq";
//import VettingRequirement from "./VettingFunction";
import { getAssessments } from "../../../../../actions/vetting/getAssessments";

function InterviewPlan({
  jobCategoryOptions,
  addJobData,
  setAddJobData,
  mandatorySkills,
  screeningQuestions,
  setScreeningQuestions,
  selectedLanguages,
  setSelectedLanguages,
  skillSetOption,
  QualificationType,
  vettingCheck,
  // vettingData,
  //setVettingToSend,
  checkedAssessments,
  setCheckedAssessments,
  assessmentDetails,
  setAssessmentDetails,

  selectedSkill,
  setSelectedSkill,
  selectedTestCategory,
  setSelectedTestCategory,
  selectedDifficulty,
  setSelectedDifficulty,
  assessmentList,
  setAssessmentList,
  secondarySkills,
  setSecondarySkills,
  selectedDuration,
  setSelectedDuration,
  difficulty,
  setDifficulty,
  selectedEducation,
  setSelectedEducation,
  selectedShiftPreference,
  setSelectedShiftPreference,
  selectedCertification,
  setSelectedCertification,
  addJobVal,
}) {
  const dispatch = useDispatch();
  const [displayScreening, setDisplayScreening] = useState(false);
  const [addSkill, setAddSkill] = useState(false);
  const [skillInput, setSkillInput] = useState({ name: "", type: "MCQ" });
  const [skillArray, setSkillArray] = useState([]);
  const [dropDownSkills, setDropDownSkills] = useState([]);
  const [showQnAModal, setShowQnAModal] = useState(false);
  const [showVettingModal, setShowVettingModal] = useState(false);
  // const [selectedVettingList, setSelectedVettingList] = useState([]);
  const [vetSkills, setVetSkills] = useState(mandatorySkills);

  const { assessmentData, assessmentCount, loading } = useSelector(
    (state) => ({
      assessmentData: state.vettingAssessmentReducer.assessmentData,
      assessmentCount: state.vettingAssessmentReducer.assessmentCount,
      loading: state.vettingAssessmentReducer.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    setVetSkills(mandatorySkills);
    vetHandler(mandatorySkills);
  }, [mandatorySkills]);

  useEffect(() => {
    fetchDropDown();
    // const uniqueName = [
    //   ...new Map(vettingCheck.map((item) => [item["name"], item])).values(),
    // ];
    // setSelectedVettingList(uniqueName);
  }, []);

  const fetchDropDown = async () => {
    const res = await get("/talent/dropdownlist");
    setDropDownSkills(
      res?.data?.CandidateSkills?.map((skill) => skill.SourceName)
    );
  };

  const vetHandler = async (mandatorySkills) => {
    dispatch(getAssessments({ Skills: mandatorySkills }));
  };

  const skillHandler = (item) => {
    mandatorySkills.push(item);
    setVetSkills(mandatorySkills);
    dispatch(getAssessments({ Skills: mandatorySkills }));
  };

  useEffect(() => {
    if (assessmentData) {
      // setVettingDetails(assessmentData);
      setAssessmentDetails(assessmentData);
    }
  }, [assessmentData]);

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };
    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };
    const getSuggestionValue = (suggestion) => suggestion.PositionName;
    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropDownSkills.filter(
              (item) =>
                item.toLowerCase().includes(inputValue) &&
                !addJobData.vettingDetails.map((i) => i.name).includes(item)
            );
      return suggestValues;
    };
    const renderSuggestion = (suggestion) => <div>{suggestion}</div>;
    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        theme={theme}
      />
    );
  };

  const handleVetting = (e, i) => {
    //  const { name, value } = e.target;
    //   let temp = [...assessmentDetails];
    // if (name === "testCategory") {
    // }
    // let temp = [...addJobData.vettingDetails];
    // if (name === "type") {
    //   temp[i] = {
    //     ...temp[i],
    //     [name]: value,
    //     duration: "",
    //     difficulty: "",
    //   };
    //   setAddJobData({
    //     ...addJobData,
    //     vettingDetails: temp,
    //   });
    // } else if (name === "difficulty") {
    //   temp[i] = {
    //     ...temp[i],
    //     [name]: value,
    //     duration: "",
    //   };
    //   setAddJobData({
    //     ...addJobData,
    //     vettingDetails: temp,
    //   });
    // } else {
    //   temp[i] = {
    //     ...temp[i],
    //     [name]: value,
    //   };
    //   setAddJobData({
    //     ...addJobData,
    //     vettingDetails: temp,
    //   });
    // }
  };

  // const [vettingDetails, setVettingDetails] = useState([]);

  const [showHeader, setShowHeader] = useState(false);
  // useEffect(() => {
  //   vettingDetails?.filter((skill) => !skill.status === true)?.length > 0
  //     ? setShowHeader(true)
  //     : setShowHeader(false);
  // }, [addJobData?.vettingDetails, vettingDetails]);

  const [missingVettingFor, setMissingVettingFor] = useState([]);

  // useEffect(() => {
  //   const resp2 = addJobData?.vettingDetails?.map((s) => {
  //     let temp = vettingData?.find(
  //       (x) => x.testName.toLowerCase() === s.name.toLowerCase()
  //     );
  //     return temp ? { ...s, status: false } : { ...s, status: true };
  //   });
  //   setVettingDetails(resp2);
  //   setVettingToSend(resp2.filter((s) => s.status === false));
  //   const resp = resp2.filter((i) => vetSkills.includes(i.name));
  //   setMissingVettingFor(resp);
  // }, [addJobData.vettingDetails]);

  const handleScreening = () => {
    setShowQnAModal(true);
    setDisplayScreening(false);
  };

  return (
    <>
      <div className="card card-flat m-3">
        <div className="card-body">
          <div className="mx-4">
            The screening will only apply if you allow screening requirements
            for the job.
          </div>
          <div className="card card-flat bg-gray4 m-3">
            <div className="card-body">
              <div className="row m-4 d-flex align-items-center">
                <div className="col-lg-8">
                  Do you have a screening requirement?
                </div>
                <div className="col-lg-2">
                  <select
                    className="form-select"
                    value={addJobData?.screeningRequired ? "Yes" : "No"}
                    onChange={(e) => {
                      if (e.target.value === "Yes") {
                        setDisplayScreening(true);
                        setAddJobData({
                          ...addJobData,
                          screeningRequired: true,
                        });
                      } else {
                        if (screeningQuestions?.length > 0) {
                          setShowQnAModal(true);
                        } else {
                          setAddJobData({
                            ...addJobData,
                            screeningRequired: false,
                          });
                        }
                      }
                    }}
                  >
                    <option selected={addJobData.screeningRequired} value="Yes">
                      Yes
                    </option>
                    <option selected={!addJobData.screeningRequired} value="No">
                      No
                    </option>
                  </select>
                </div>
              </div>
              {addJobData.screeningRequired && (
                <div className="mb-2 m-4 ml-5">
                  <button
                    type="button"
                    className="btn btn-collapsible"
                    onClick={() => setDisplayScreening(!displayScreening)}
                  >
                    <i
                      className={
                        displayScreening
                          ? "fal fa-angle-down fa-fw"
                          : "fal fa-angle-up fa-fw"
                      }
                    ></i>
                    <span>
                      <small>Screening Requirement</small>
                    </span>
                  </button>
                </div>
              )}

              {/* que */}
              {addJobData.screeningRequired && (
                <div className={displayScreening ? "ml-5 mb-4" : "d-none"}>
                  <ScreeningQuestions
                    jobCategoryOptions={jobCategoryOptions}
                    selectedLanguages={selectedLanguages}
                    setSelectedLanguages={setSelectedLanguages}
                    skillSetOption={skillSetOption}
                    screeningQuestions={screeningQuestions}
                    setScreeningQuestions={setScreeningQuestions}
                    addJobData={addJobData}
                    addJobVal={addJobVal}
                  />
                </div>
              )}
              <div className="row m-4 d-flex align-items-center mt-5">
                <div className="col-lg-8">
                  Do you want pre-vetted talent to be submitted?
                </div>
                <div className="col-lg-2">
                  <select
                    className="form-select"
                    value={addJobData?.vettingRequired ? "Yes" : "No"}
                    onChange={(e) => {
                      if (e.target.value === "Yes") {
                        setAddJobData({ ...addJobData, vettingRequired: true });
                      } else {
                        setCheckedAssessments([]);
                        setShowVettingModal(true);
                      }
                    }}
                  >
                    <option selected={addJobData.vettingRequired} value="Yes">
                      Yes
                    </option>
                    <option selected={!addJobData.vettingRequired} value="No">
                      No
                    </option>
                  </select>
                </div>
              </div>
              {addJobData.vettingRequired && (
                <VettingRequirement
                  handleVetting={handleVetting}
                  //vettingData={vettingData}
                  showHeader={showHeader}
                  mandatorySkills={mandatorySkills}
                  vetSkills={vetSkills}
                  setAddSkill={setAddSkill}
                  autocompleteRenderInput={autocompleteRenderInput}
                  addSkill={addSkill}
                  setSkillArray={setSkillArray}
                  setSkillInput={setSkillInput}
                  skillArray={skillArray}
                  setAddJobData={setAddJobData}
                  addJobData={addJobData}
                  // vettingDetails={vettingDetails}
                  skillInput={skillInput}
                  missingVettingFor={missingVettingFor}
                  setMissingVettingFor={setMissingVettingFor}
                  skillHandler={skillHandler}
                  checkedAssessments={checkedAssessments}
                  setCheckedAssessments={setCheckedAssessments}
                  setAssessmentDetails={setAssessmentDetails}
                  assessmentDetails={assessmentDetails}
                  vetHandler={vetHandler}
                  selectedSkill={selectedSkill}
                  setSelectedSkill={setSelectedSkill}
                  selectedTestCategory={selectedTestCategory}
                  setSelectedTestCategory={setSelectedTestCategory}
                  selectedDifficulty={selectedDifficulty}
                  setSelectedDifficulty={setSelectedDifficulty}
                  assessmentList={assessmentList}
                  setAssessmentList={setAssessmentList}
                  secondarySkills={secondarySkills}
                  setSecondarySkills={setSecondarySkills}
                  selectedDuration={selectedDuration}
                  setSelectedDuration={setSelectedDuration}
                  difficulty={difficulty}
                  setDifficulty={setDifficulty}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {showQnAModal && (
        <ShowQnAModal
          setScreeningQuestions={setScreeningQuestions}
          setAddJobData={setAddJobData}
          addJobData={addJobData}
          setShowQnAModal={setShowQnAModal}
          setDisplayScreening={setDisplayScreening}
        />
      )}
      {showVettingModal && (
        <ShowVettingModal
          setAddJobData={setAddJobData}
          addJobData={addJobData}
          setShowVettingModal={setShowVettingModal}
          setCheckedAssessments={setCheckedAssessments}
        />
      )}
    </>
  );
}
export default InterviewPlan;
