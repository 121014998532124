import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const GET_ANALYTICS_BEGIN = "GET_ANALYTICS_BEGIN";
export const GET_ANALYTICS_SUCCESS = "GET_ANALYTICS_SUCCESS";
export const GET_ANALYTICS_FAILURE = "GET_ANALYTICS_FAILURE";

export const GET_GROWTH_EXPANSION_ANALYTICS_BEGIN =
  "GET_GROWTH_EXPANSION_ANALYTICS_BEGIN";
export const GET_GROWTH_EXPANSION_ANALYTICS_SUCCESS =
  "GET_GROWTH_EXPANSION_ANALYTICS_SUCCESS";
export const GET_GROWTH_EXPANSION_ANALYTICS_FAILURE =
  "GET_GROWTH_EXPANSION_ANALYTICS_FAILURE";

export const GET_FORM_ANALYTICS_BEGIN = "GET_FORM_ANALYTICS_BEGIN";
export const GET_FORM_ANALYTICS_SUCCESS = "GET_FORM_ANALYTICS_SUCCESS";
export const GET_FORM_ANALYTICS_FAILURE = "GET_FORM_ANALYTICS_FAILURE";

export const GET_RECRUITER_ANALYTICS_BEGIN = "GET_RECRUITER_ANALYTICS_BEGIN";
export const GET_RECRUITER_ANALYTICS_SUCCESS =
  "GET_RECRUITER_ANALYTICS_SUCCESS";
export const GET_RECRUITER_ANALYTICS_FAILURE =
  "GET_RECRUITER_ANALYTICS_FAILURE";

export const getAnalyticsData = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ANALYTICS_BEGIN,
    });
    const res = await post("/analytics/getAnalyticsData");
    if (res.status === 200) {
      dispatch({
        type: GET_ANALYTICS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ANALYTICS_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getGrowthExpansionAnalytics = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_GROWTH_EXPANSION_ANALYTICS_BEGIN,
    });
    const res = await post("/analytics/getGrowthExpansionAnalytics");
    if (res.status === 200) {
      dispatch({
        type: GET_GROWTH_EXPANSION_ANALYTICS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_GROWTH_EXPANSION_ANALYTICS_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getFormAnalytics = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_FORM_ANALYTICS_BEGIN,
    });
    const res = await post("/analytics/getFormAnalytics");
    if (res.status === 200) {
      dispatch({
        type: GET_FORM_ANALYTICS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_FORM_ANALYTICS_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getRecruiterAnalytics = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECRUITER_ANALYTICS_BEGIN,
    });
    const res = await post("/analytics/getRecruiterPipelineAnalytics");
    if (res.status === 200) {
      dispatch({
        type: GET_RECRUITER_ANALYTICS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RECRUITER_ANALYTICS_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
