import React from "react";
import Curators from "../../../curator/CuratorsList/CuratorsList";
const CuratorComponent = () => {
  return (
    <div class="mt-4">
      <div className="card">
        <div className="card-body">
          <Curators isFrom="High5Admin" />
        </div>
      </div>
    </div>
  );
};

export default CuratorComponent;
