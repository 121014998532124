import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getUserRole } from "../../../../../../service/AuthService";
import Disqualified from "./Disqualified";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";
import Pagination from "../../../../commoncomponents/pagination/Pagination";
import { getPoolDispositionDisqualify } from "../../../../../../actions/directsourcing/PoolDispositionDisqualify";
import { useParams } from "react-router";
import PaginationComponent from "../../../../pagination/pagination";

const Index = (props) => {
  const { jobData, currentPage, setCurrentPage, view } = props;
  const [disqualifiedCandidates, setDisqualifiedCandidates] = useState(false);
  const [disqualifiedData, setDisqualifiedData] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const { poolId } = useParams();
  const dispatch = useDispatch();
  /*** Sort Param  * */
  const [nameSortSelected, setNameSortSelected] = useState(null);
  const [dateSortSelected, setDateSortSelected] = useState(null);
  const [sortSelected, setSortSelected] = useState({
    firstName: false,
    date: false,
  });
  const [sortArray, setSortArray] = useState([]);
  /*** Sort Param  * */
  const {
    dispositionDetails,
    dispositionDisqualify,
    dispositionCount,
  } = useSelector(
    (state) => ({
      dispositionDetails: state.poolDispositionDisqualify.dispositionDisqualify,
      dispositionDisqualifyLoading:
        state.poolDispositionDisqualify.dispositionDisqualifyLoading,
      dispositionCount: state.dispositionCount.dispositionCount,
      dispositionLoading: state.poolDisposition.dispositionDetailsLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let data = dispositionDetails.filter(
      (item) => item.dispositionStatus == 12 || item.dispositionStatus == 29
    );
    setDisqualifiedData(data);
  }, [dispositionDetails]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };
  useEffect(() => {
    fetchData(1, true);
  }, []);
  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: "disqualified",
      poolId: poolId,
      filterObject: props.filterParams,
    };
    dispatch(getPoolDispositionDisqualify(payload));
  };
  /*** Sort Param  * */
  useEffect(() => {
    dispatch(
      getPoolDispositionDisqualify({
        currentPage: currentPage,
        limit: 15,
        status: "disqualified",
        poolId: poolId,
        filterObject: props.filterParams,
        searchText: props.value,
        sortParam: sortArray.length > 0 ? sortArray : [],
      })
    );
  }, [sortArray, sortSelected]);

  const handleSort = (sortValue) => {
    let sortType = "";
    let temp = { ...sortSelected };
    if (sortValue === "firstName") {
      sortType = !nameSortSelected
        ? "ASC"
        : nameSortSelected === "ASC"
        ? "DESC"
        : "ASC";
      setNameSortSelected(sortType);
      setDateSortSelected(null);
      temp = {
        firstName: true,
        date: false,
      };
      setSortSelected(temp);
    } else {
      sortType = !dateSortSelected
        ? "ASC"
        : nameSortSelected === "ASC"
        ? "DESC"
        : "ASC";
      setDateSortSelected(sortType);
      setNameSortSelected(null);
      temp = {
        firstName: false,
        date: true,
      };
      setSortSelected(temp);
    }
    setSortArray(sortValue ? [{ sortValue, sortType }] : []);
  };

  /*** Sort Param  * */
  return (
    <div className="card card-flat card-lg mt-3">
      <div className="card-body">
        <button
          type="button"
          className="btn btn-collapsible"
          onClick={() => {
            setDisqualifiedCandidates(!disqualifiedCandidates);
          }}
        >
          <div className="d-flex align-items-center px-1">
            <p className="mb-0 font-14">
              Ineligible Candidates (
              {
                dispositionCount?.filter((e) => e.Status == "Disqualified")[0]
                  ?.Count
              }
              )
            </p>
            <div className="ml-auto">
              <i
                aria-hidden="true"
                className={
                  disqualifiedCandidates
                    ? "fal fa-angle-up fa-fw"
                    : "fal fa-angle-down fa-fw"
                }
              />
            </div>
          </div>
        </button>
        {disqualifiedCandidates && (
          <div className="table-wrap mt-3">
            {disqualifiedData.length > 0 ? (
              <>
                <table className="table table-hoverable-cells table-sortable table-cards-1024">
                  <thead>
                    <tr>
                      <th className="align-middle">
                        <div
                          class="d-flex align-items-center"
                          onClick={() => {
                            handleSort("firstName");
                          }}
                        >
                          Candidate
                          {!nameSortSelected ? (
                            <>
                              <i
                                className="fad fa-sort-down ml-2 font-16"
                                style={{ color: "grey" }}
                              ></i>
                            </>
                          ) : nameSortSelected === "ASC" ? (
                            <>
                              <i
                                className="fad fa-sort-down ml-2 font-16"
                                style={{ color: "orange" }}
                              ></i>
                            </>
                          ) : (
                            <>
                              <i
                                className="fad fa-sort-up font-16"
                                style={{ color: "orange" }}
                              ></i>
                            </>
                          )}
                        </div>
                      </th>
                      {getUserRole() !== "Recruiter" && <th>Submitted By </th>}
                      <th className="align-middle">
                        <div
                          class="d-flex align-items-center"
                          onClick={() => {
                            handleSort("disqualifiedDate");
                          }}
                        >
                          Last updated
                          {!dateSortSelected ? (
                            <>
                              <i
                                className="fad fa-sort-down ml-2 font-16"
                                style={{ color: "grey" }}
                              ></i>
                            </>
                          ) : dateSortSelected === "ASC" ? (
                            <>
                              <i
                                className="fad fa-sort-down ml-2 font-16"
                                style={{ color: "orange" }}
                              ></i>
                            </>
                          ) : (
                            <>
                              <i
                                className="fad fa-sort-up font-16"
                                style={{ color: "orange" }}
                              ></i>
                            </>
                          )}
                        </div>
                      </th>
                      <th className="align-middle">
                        Reason for Disqualification
                      </th>
                      {/* <th>Score</th> */}
                      <th className="align-middle no-hover" />
                    </tr>
                  </thead>
                  <tbody>
                    <Disqualified
                      jobData={jobData}
                      disqualifiedData={disqualifiedData}
                      calculateScore={props.calculateScore}
                      filterParams={props?.filterParams}
                      view={view}
                    />
                  </tbody>
                </table>
                <PaginationComponent
                  currentPage={currentPage}
                  totalItems={
                    dispositionCount?.filter(
                      (e) => e.Status == "Disqualified"
                    )[0]?.Count
                  }
                  itemsPerPage={15}
                  onPageChange={handlePageChange}
                  limit={15}
                />
              </>
            ) : (
              <NoRecord />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
