import React, { useState, useEffect } from "react";

const Pagination = (props) => {
  const { dataSize, setPaginationArray, getTab, pageSize, data } = props;
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationData, setPaginationData] = useState([]);
  const [pageType, setPageType] = useState("");

  useEffect(() => {
    if (pageType === "up") {
      if ((props.currentPage - 1) % pageSize === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          paginationData.slice(
            props.currentPage - 1,
            props.currentPage + (pageSize - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (props.currentPage % pageSize === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          paginationData.slice(props.currentPage - pageSize, props.currentPage)
        );
      }
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (data?.length > 0) {
      setPaginationData(
        new Array(Math.ceil(data?.length / dataSize))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(data?.length / dataSize))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageSize)
      );
    }
  }, [data, dataSize]);

  useEffect(() => {
    setPaginationArray(getPaginatedData());
  }, [data]);

  useEffect(() => {
    setPaginationArray(getPaginatedData());
  }, [props.currentPage]);
  useEffect(() => {
    setPaginationArray(getPaginatedData());
  }, [dataSize]);

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    if (pageNumber > props.currentPage) {
      setPageType("up");
    } else {
      setPageType("down");
    }
    props.setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = props.currentPage * dataSize - dataSize;
    const endIndex = startIndex + +dataSize;
    return data?.slice(startIndex, endIndex);
  };

  return (
    <>
      {data?.length > 0 && (
        <div className="d-flex justify-content-end pt-4 px-1">
          <div className="d-flex">
            <div class="ml-auto mx-auto-370 d-flex align-items-center">
              <button
                onClick={() => {
                  setPageType("down");
                  props.setCurrentPage((page) => page - 1);
                }}
                type="button"
                disabled={props.currentPage === 1 ? true : false}
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-left"></i>
              </button>
              {paginationGroup.map((item) => (
                <button
                  type="button"
                  className={
                    props.currentPage === item
                      ? "btn btn-text px-2 bg-secondary text-white"
                      : "btn btn-text px-2"
                  }
                  onClick={changePage}
                >
                  {item}
                </button>
              ))}
              <button
                onClick={() => {
                  setPageType("up");
                  props.setCurrentPage((page) => page + 1);
                }}
                type="button"
                disabled={
                  props.currentPage === paginationData.slice(-1)[0]
                    ? true
                    : false
                }
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-right"></i>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Pagination;
