import React from "react";

const QuestionList = ({questionList, handleEdit,deleteQuestion})=>{

    return(
        <>
        <div className="table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">Question</th>
                    <th scope="col" className="text-center">
                      Type
                    </th>
                    <th scope="col" className="text-center">
                      Difficulty
                    </th>
                    <th scope="col" className="text-center">
                      Skills
                    </th>
                    <th scope="col" className="text-center">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {questionList.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {index + 1}. {item.question}
                      </td>
                      <td className="text-center">
                        <span className="tag tag-blue3">{item.type}</span>
                      </td>
                      <td className="text-center">{item.difficulty}</td>
                      <td className="text-center">{item.skill}</td>
                      <td className="text-center">
                        <i
                          className="fas fa-pencil-alt text-primary mr-3"
                          aria-label="Edit"
                          onClick={() => handleEdit(item,index)}
                          style={{ cursor: "pointer" }}
                        ></i>
                        <i
                          className="fas fa-trash-alt text-danger"
                          aria-label="Delete"
                          onClick={() => deleteQuestion(index)}
                          style={{ cursor: "pointer" }}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
        </>
    )
}

export default QuestionList