import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import ProgressBar from "@ramonak/react-progress-bar";
import { post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import JobAlerts from "./EditTalent/JobAlerts";
import ProfileImage from "../commoncomponents/profileimage/ProfileImage";

const ProfileInfoCard = ({ candidateData, talentCommunityList }) => {
  const [resumeFile, setResumeFile] = useState("");
  const [valid, setValid] = useState(false);
  const [percentage, setPercentage] = useState(35);
  const [loading, setLoading] = useState(false);

  const onUploadHandler = (file) => {
    var re = /(?:\.([^.]+))?$/;

    var ext = re.exec(file[0].name)[1]; // "
    if (ext === "pdf" || ext === "docx" || ext === "doc") {
      setValid(true);
      const { name } = file[0];
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (event) => {
        setResumeFile({ fileName: name, base64: event.target.result });
      };
    } else {
      setValid(false);
    }
  };
  const uploadResume = async () => {
    try {
      let params = {
        ...candidateData,
        oldFileName: candidateData.fileName,
        fileName: resumeFile.fileName,
        base64: resumeFile.base64,
        activity: "Resume updated",
      };
      if (valid) {
        setLoading(true);
        const result = await post("/candidate/updatetalent", params);
        if (result.status == 200) {
          toast.success("Resume uploaded successfully");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } else {
        toast.error("Please Upload resume first");
      }
    } catch (error) {
      setResumeFile("");
      // toast.error("Something went wrong, Please try again");
    }
  };
  useEffect(() => {
    valid && uploadResume();
    setValid(false);
  }, [resumeFile]);
  const profileCompletionCalculator = (data) => {
    let fields = 0;
    resumeFile !== "" && fields++;
    data.firstName !== "" && fields++;
    data.lastName !== "" && fields++;
    data.email !== "" && fields++;
    data.zipCode !== "" && fields++;
    data.addressCity !== "" && fields++;
    data.addressState !== "" && fields++;
    data.country !== "" && fields++;
    data.mobilePhone !== "" && fields++;
    data.homePhone !== "" && fields++;
    data.currentJobTitle !== "" && fields++;
    data.currentEmployer !== "" && fields++;
    data.experienceMonth !== "" && fields++;
    data.experienceYear !== "" && fields++;
    data.linkedIn !== "" && fields++;
    data.description !== "" && fields++;
    JSON.parse(data?.skillSet)?.length > 0 && fields++;
    JSON.parse(data?.educations)?.length > 0 && fields++;
    JSON.parse(data?.experiences)?.length > 0 && fields++;
    data.minContractRate !== "" && fields++;
    data.minContractRateCurrency !== "" && fields++;
    data.preferredSalaryCurrency !== "" && fields++;
    data.preferredSalary !== "" && fields++;
    JSON.parse(data?.preferredLocation) !== "" && fields++;
    let per = Math.round((fields / 24) * 100);
    setPercentage(per);
  };
  useEffect(() => {
    candidateData != null &&
      Object.keys(candidateData).length > 0 &&
      profileCompletionCalculator(candidateData);
  }, [candidateData]);

  const uniqueJobTenants = new Set();

  const filteredData = candidateData?.dispositionData?.filter(item => {
    if (item.id_disposition) {
      if (uniqueJobTenants.has(item.jobTenant)) {
        return false;
      }
      uniqueJobTenants.add(item.jobTenant);
    }
    return true;
  });
  return (
    <>
      {loading && <Loader />}
      <div className="col-xxl-3">
        <div className="card mb-2">
          <div className="card-body ">
            <div className="d-flex align-items-center mb-2">
              <button
                type="button"
                className="btn btn-text avatar avatar-lg avatar-edit "
              >
                <ProfileImage
                  imageSrc={candidateData?.profileImagePath}
                  imageWidth={"30"}
                  imageHeight={"30"}
                  avatarSize={"60"}
                  avatarRound={"90px"}
                  firstName={candidateData?.firstName}
                  lastName={candidateData?.lastName}
                />
              </button>
              <div className="w-100 ml-1">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <h6 className="mb-0">{`${candidateData?.firstName
                    .charAt(0)
                    .toUpperCase() +
                    candidateData?.firstName?.slice(
                      1
                    )} ${candidateData?.lastName?.charAt(0).toUpperCase() +
                    candidateData?.lastName?.slice(1)}`}</h6>
                  <a
                    href="/careers/profile/edittalent"
                    type="button"
                    className="btn btn-icon"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Edit Profile"
                  >
                    <i className="fal fa-fw fa-edit"></i>
                  </a>
                </div>
                <p className="mb-0">{candidateData?.designation}</p>
                <p className="">
                  <small>{`${candidateData?.city}, ${candidateData?.country}`}</small>
                </p>
              </div>
            </div>
            <ProgressBar
              completed={percentage}
              bgColor={
                percentage < 30
                  ? "red"
                  : percentage < 70
                  ? "#FF5F57"
                  : "#2F8D46"
              }
              customLabel={`${percentage}%`}
            />
            <small className="d-flex justify-content-center">
              Profile Completion
            </small>
            <hr />
            <div>
              {/* <p className="mb-2">
              <strong>My Resume</strong>
            </p> */}
              <div className="d-flex align-items-center justify-content-between mb-2">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <p className="mb-2">
                    <strong>My Resume</strong>
                  </p>
                  <Dropzone onDrop={onUploadHandler}>
                    {({ getRootProps, getInputProps }) => (
                      <div
                        {...getRootProps(
                          resumeFile && {
                            onClick: (event) => event.stopPropagation(),
                          }
                        )}
                        className="ml-auto"
                      >
                        <button
                          type="button"
                          // disabled={resumeFile}
                          className=" btn btn-text-accent mr-1"
                        >
                          <i
                            aria-hidden="true"
                            className="far fa-cloud-upload mr-1 fa-fw"
                          ></i>
                          {candidateData?.fileName ? "Update" : "Upload"}
                        </button>
                        <input
                          type="file"
                          {...getInputProps()}
                          accept=".docx, .pdf, application/msword"
                        />
                      </div>
                    )}
                  </Dropzone>
                  {/* <button type="button" className="btn btn-text-accent mr-1">
                  <i
                    className="far fa-cloud-upload mr-1 fa-fw"
                    style={{ width: "59px" }}
                  >
                    Upload
                  </i>
                </button> */}
                  {/* <button type="button" className="btn btn-text-accent">
                  <i
                    className="far fa-pencil-alt mr-1 fa-fw"
                    style={{ width: "59px" }}
                  >
                    Edit
                  </i>
                </button> */}
                </div>
              </div>
              <hr />
              <div>
                <div className="mb-1">
                  <a className="pe-none" href="#">
                    {candidateData?.fileName}
                  </a>
                </div>
                {/* <div className="mb-1">
                <a href="#">John Doe Resume 2020.docx</a>
              </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="card mb-2">
          <div className="card-body">
            <div className="mb-2 mt-1">
              <label className="pt-0 mb-2">
                <strong>Talent Community Joined</strong>
              </label>
              {/* <a href="#">
                <i className="fas fa-external-link-square ml-1"></i>
              </a> */}
            </div>
            {filteredData?.map((list) => {
              return (
               !list?.id_disposition && <a href="#" className="card card-flat w-100 mb-1 pe-none">
                  <div className="card-body">
                    <div>
                      <div className="d-flex justify-content-between align-items-center">
                        {/* {list?.id_disposition ?<p className="mb-1">{`${list?.jobTenant}  Talent Pool`}</p>
                      : */}
                       <p className="mb-1">{`${list?.jobTenant} ${list?.poolname}  Talent Community`}</p>
                      {/*  } */}
                      </div>
                      <div>
                      </div>
                    </div>
                  </div>
                </a>
              );
            })}
            {/* <a href="#" className="card card-flat w-100 mb-1 pe-none">
            <div className="card-body">
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <p className="mb-1">Microsoft Talent Community</p>
                </div>
                <div>
                  <div className="mb-1">
                    <span className="font-regular font-primary font-gray1">
                      8/1/2021
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </a> */}
          </div>
        </div>

        {/* <div className="card mb-2">
          {candidateData !== undefined &&
            candidateData !== null &&
            Object.keys(candidateData).length > 0 && (
              <JobAlerts data={candidateData} />
            )}
        </div> */}
      </div>
    </>
  );
};

export default ProfileInfoCard;
