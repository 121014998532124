import {
  GET_JOBTITLE_CURATOR_FILTER_BEGIN,
  GET_JOBTITLE_CURATOR_FILTER_SUCCESS,
  GET_JOBTITLE_CURATOR_FILTER_FAILURE,
  GET_STATE_CURATOR_FILTER_BEGIN,
  GET_STATE_CURATOR_FILTER_SUCCESS,
  GET_STATE_CURATOR_FILTER_FAILURE,
  GET_COUNTRY_CURATOR_FILTER_BEGIN,
  GET_COUNTRY_CURATOR_FILTER_SUCCESS,
  GET_COUNTRY_CURATOR_FILTER_FAILURE,
  GET_ROLES_CURATOR_FILTER_BEGIN,
  GET_ROLES_CURATOR_FILTER_SUCCESS,
  GET_ROLES_CURATOR_FILTER_FAILURE,
  GET_CATEGORY_CURATOR_FILTER_BEGIN,
  GET_CATEGORY_CURATOR_FILTER_SUCCESS,
  GET_CATEGORY_CURATOR_FILTER_FAILURE,
} from "../../actions/curator/AssignCuratorFilter";

const initialState = {
  loading: false,
  jobTitleData: null,
  stateLoading: false,
  stateData: null,
  countryLoading: false,
  countryData: null,
  rolesLoading: false,
  rolesData: [],
  jobCategory: [],
  categoryLoading: false,
};

const getCuratorFilterReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_JOBTITLE_CURATOR_FILTER_BEGIN:
      return {
        ...state,
        loading: true,
        jobTitleData: null,
      };
    case GET_JOBTITLE_CURATOR_FILTER_SUCCESS:
      return {
        ...state,
        jobTitleData: payload,
        loading: false,
      };
    case GET_JOBTITLE_CURATOR_FILTER_FAILURE:
      return {
        ...state,
        jobTitleData: payload,
        loading: false,
      };
    case GET_STATE_CURATOR_FILTER_BEGIN:
      return {
        ...state,
        stateData: null,
        stateLoading: true,
      };
    case GET_STATE_CURATOR_FILTER_SUCCESS:
      return {
        ...state,
        stateData: payload,
        stateLoading: false,
      };
    case GET_STATE_CURATOR_FILTER_FAILURE:
      return {
        ...state,
        stateData: payload,
        stateLoading: false,
      };
    case GET_COUNTRY_CURATOR_FILTER_BEGIN:
      return {
        ...state,
        countryData: null,
        countryLoading: true,
      };
    case GET_COUNTRY_CURATOR_FILTER_SUCCESS:
      return {
        ...state,
        countryData: payload,
        countryLoading: false,
      };
    case GET_COUNTRY_CURATOR_FILTER_FAILURE:
      return {
        ...state,
        countryData: payload,
        countryLoading: false,
      };
    case GET_ROLES_CURATOR_FILTER_BEGIN:
      return {
        ...state,
        rolesData: null,
        rolesLoading: true,
      };
    case GET_ROLES_CURATOR_FILTER_SUCCESS:
      return {
        ...state,
        rolesData: payload,
        rolesLoading: false,
      };
    case GET_ROLES_CURATOR_FILTER_FAILURE:
      return {
        ...state,
        rolesData: payload,
        rolesLoading: false,
      };
    case GET_CATEGORY_CURATOR_FILTER_BEGIN:
      return {
        ...state,
        jobCategory: null,
        categoryLoading: true,
      };
    case GET_CATEGORY_CURATOR_FILTER_SUCCESS:
      return {
        ...state,
        jobCategory: payload,
        categoryLoading: false,
      };
    case GET_CATEGORY_CURATOR_FILTER_FAILURE:
      return {
        ...state,
        jobCategory: payload,
        categoryLoading: false,
      };
    default:
      return state;
  }
};

export default getCuratorFilterReducer;
