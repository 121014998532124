import React, { useState } from "react";
const ECTable = ({
  data,
  setData,
  checkAllSelected,
  selectAll,
  selectOne,
  allSelected,
  apiCanCalled,
  setAllSelected,
  selected,
  loading,
}) => {
  const onSkillClick = (e, val) => {
    setData(
      data.map((item) => {
        if (item?.email === val?.email) {
          item.ViewAllSkill = !item.ViewAllSkill;
        }
        return item;
      })
    );
  };
  return (
    <div className="container overflow-auto">
      {" "}
      <div className="row">
        <div className="col-md-12">
          <div
            className="table-responsive report-table"
            style={{ maxHeight: "600px" }}
          >
            <table
              id="reportTable"
              className="table custom-table-style table-hover table-bordered"
            >
              <thead>
                <tr className="report-heading">
                  <th>Select</th>
                  <th> First name</th>
                  <th>Last name</th>
                  <th>Email</th>
                  <th>Skills</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody>
                {!loading && apiCanCalled && data.length === 0 && (
                  <tr className="text-center">
                    <td colSpan={9}>
                      <h4>No Record found.</h4>
                    </td>
                  </tr>
                )}
                {loading && (
                  <tr className="text-center">
                    <td colSpan={9}>
                      <h4>Loading...</h4>
                    </td>
                  </tr>
                )}
                {!loading && selected && (
                  <tr className="text-center">
                    <td colSpan={6}>
                      <h6>Select any filters & Search.</h6>
                    </td>
                  </tr>
                )}
                {!loading &&
                  data.map((item, index) => (
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem", borderRadius: "100" }}
                          checked={item.isSelected}
                          onClick={() => selectOne(item, index)}
                        />
                      </td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td>{item.email}</td>
                      <td>
                        {!item.primarySkills && (
                          <span className="mb-1 mr-1">NA</span>
                        )}
                        {item.primarySkills &&
                          JSON.parse(item.primarySkills)
                            .slice(0, 2)
                            .map((item) => (
                              <span className="tag tag-blue3 mb-1 mr-1">
                                {item}
                              </span>
                            ))}
                        {JSON.parse(item.primarySkills)?.length > 2 &&
                          item.ViewAllSkill === true &&
                          JSON.parse(item.primarySkills)
                            .slice(2, JSON.parse(item.primarySkills)?.length)
                            .map((item) => (
                              <>
                                <span class="tag tag-blue3 mb-1 mr-1">
                                  {item}
                                </span>
                              </>
                            ))}
                        {JSON.parse(item.primarySkills)?.length > 2 && (
                          <button
                            onClick={(e) => onSkillClick(e, item)}
                            type="button"
                            class="btn btn-text"
                          >
                            {!item.ViewAllSkill ? (
                              <span>View all skills</span>
                            ) : (
                              <span>Show Less</span>
                            )}
                          </button>
                        )}
                      </td>
                      <td>
                        {item.city && item.country
                          ? item?.city + "," + item?.country
                          : "NA"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ECTable;
