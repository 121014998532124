import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { roleNames } from "../../../constants/Constants";
import TopNavBar from "../../layout/TopNavBar";
import SideNavBar from "../../layout/SideNavBar";
import Preferences from "../../common/Preferences/Preferences";
import ContactInfo from "./ContactInfo";
import ContactInfoCandidate from "./ContactInfoCandidate";
import SocialMedia from "./SocialMedia";
import StoryInfo from "./StoryInfo";
import Skills from "./Skills";
import Education from "./Education";
import Experience from "./Experience";
import Certifications from "./Certifications";
import License from "./License";
import CustomSection from "./CustomSection";
import Languages from "./Languages";
import Security from "./Security";
import AboutUs from "./ComanyProfile";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Awards from "./Awards";
import { get } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import {
  getUserRole,
  getCandidateId,
  getCandidateName,
  getUserId,
  getUserTenant,
  getTenantType,
} from "../../../service/AuthService";
import Notifications from "./Notifications";
import RecruitmentDetails from "./RecruitmentDetails";
import Terms from "./Terms";
import { getDropdownValues } from "../../../actions/common/GetDropdownValues";
import EditLogo from "./EditLogo";
import Footer from "../../layout/Footer";
import AvatarUpload from "../../components_v3/commoncomponents/fileupload/AvatarUpload";

function MyProfile(props) {
  const dispatch = useDispatch();
  const [accountDeatils, setAccountDetails] = useState("accountDeatils");
  const [preference, setPreferences] = useState(false);
  const [userdetails, setUser] = useState({
    user: "",
    role: "",
    tenant: "",
    tenantType: "",
    userid: "",
  });
  const [toggle, setToggle] = useState(false);
  const { user, role, tenant, userid } = userdetails;
  const [candidateData, setCandidateData] = useState([]);
  const [createdBy, setCreatedBy] = useState(null);
  const [dropdownList, setDropdownList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [recData, setRecData] = useState({});
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [imageData, setImageData] = useState({
    fileName: "",
    base64: "",
  });

  const { dropdownLoading, dropdownData, logoLoading } = useSelector(
    (state) => ({
      dropdownLoading: state.dropdownValuesReducer.dropdownLoading,
      dropdownData: state.dropdownValuesReducer.dropdownData,
      logoLoading: state.getTenantLogoReducer.logoLoading,
    }),
    shallowEqual
  );

  const toggleDiv = () => {
    setToggle(!toggle);
  };

  const UploadProfileImage = async () => {
    try {
      let parameters = {
        ...imageData,
        userId: getCandidateId(),
        role: role,
        oldProfilePath: role === "Recruiter" ? recData?.profileImagePath : "",
      };

      const res = await post(
        "/common/contactinfo/saveProfileImage",
        parameters
      );
      if (res.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.response.data.errMessage);
    }
  };
  useEffect(() => {
    if (imageData.base64 && imageData.fileName) {
      UploadProfileImage();
    }
  }, [imageData]);

  useEffect(() => {
    if (recData) {
      setImageData({
        ...imageData,
        base64: recData.profileImagePath,
      });
      const userTokenJSON = localStorage.getItem("userToken");
      const userTokenObject = JSON.parse(userTokenJSON);
      userTokenObject.profileImagePath = recData.profileImagePath;
      const updatedUserTokenJSON = JSON.stringify(userTokenObject);
      localStorage.setItem("userToken", updatedUserTokenJSON);
    }
  }, [recData]);

  const profileType = (type) => {
    if (type === "accountDeatils") {
      if (accountDeatils === false) {
        setAccountDetails(true);
      } else {
        setAccountDetails(false);
      }
      setPreferences(false);
    }

    if (type === "preference") {
      if (preference === false) {
        setPreferences(true);
      } else {
        setPreferences(false);
      }
      setAccountDetails(false);
    }
  };

  const getProfile = () => {
    const username = getCandidateName();
    const userrole = getUserRole();
    const iduser = getUserId();
    setUser({
      user: username,
      role: userrole,
      userid: iduser,
      tenant: getUserTenant(),
      tenantType: getTenantType(),
    });
  };

  const getCandidateData = async () => {
    setLoading(true);
    try {
      const res = await get("/candidateProfile/fetchCandidateData");
      setCandidateData(res.data);
      setCreatedBy(res.data[0].createdBy);
      setLoading(false);
    } catch (err) {
      toast.error("Some Error Occurred while Fetching Candidate Details");
      setLoading(false);
    }
  };

  const getRecrutierData = async (params) => {
    setLoading(true);
    try {
      const res = await post("/common/contactInfo", params);
      setLoading(false);
      if (res.status === 200) {
        setRecData(res.data[0]);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error occured while accessing account detailss");
    }
  };

  const fetchDropDown = async () => {
    setLoading(true);
    const res = await get("/talent/dropdownlist")
      .then((res) => {
        setDropdownList(
          res.data.CandidateSkills.map((skill) => skill.SourceName)
        );
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  useEffect(() => {
    dispatch(getDropdownValues());
    if (getUserRole() === "Candidate") {
      getCandidateData();
    }
    const userid = getCandidateId();
    getRecrutierData({
      userid,
    });
    getProfile();
    fetchDropDown();
  }, []);

  return (
    <>
      {((getTenantType() == 1 && logoLoading) ||
        loading ||
        dropdownLoading ||
        categoryLoading) && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <SideNavBar />
        <TopNavBar handleToggale={toggleDiv} component={"Profile"} />
        <div id="Content">
          {role === "Candidate" && candidateData.length === 0 ? (
            <Loader />
          ) : (
            <>
              <div className="section-sm section" style={{ width: "100%" }}>
                <AvatarUpload
                  imageData={imageData}
                  setImageData={setImageData}
                  user={user}
                  role={role}
                  roleNames={roleNames}
                  UploadProfileImage={UploadProfileImage}
                />
                <Tabs style={{ borderBottom: "revert" }} className="mt-4">
                  <div className="translate-up-100">
                    <div className="d-flex justify-content-center">
                      <TabList>
                        <ul className="nav nav-tabs nav-tabs-white mt-3 flex-wrap flex-nowrap-md justify-content-center">
                          <Tab
                            onClick={() => {
                              profileType("accountDeatils");
                            }}
                            className={
                              accountDeatils
                                ? "nav-link pb-3 active"
                                : "nav-link pb-3"
                            }
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            Account Details{" "}
                          </Tab>
                          {(role === "Recruiter" || role === "Candidate") && (
                            <Tab
                              onClick={() => {
                                profileType("preference");
                              }}
                              className={
                                preference
                                  ? "nav-link pb-3 active"
                                  : "nav-link pb-3"
                              }
                              style={{
                                cursor: "pointer",
                              }}
                            >
                              {" "}
                              Preferences
                            </Tab>
                          )}
                          {/* {((role === "Recruiter" && tenant.includes("GIG - ")) ||
                          (role === "Admin" && tenantType === 2)) && (
                          <Tab
                            onClick={() => {
                              profileType("Terms and conditions");
                            }}
                            className={
                              terms ? "nav-link pb-3 active" : "nav-link pb-3"
                            }
                            style={{
                              cursor: "pointer",
                            }}
                          >
                            {" "}
                            Terms of Service
                          </Tab>
                        )} */}
                        </ul>
                      </TabList>
                    </div>

                    <TabPanel>
                      <div className="container">
                        <div className="row justify-content-center">
                          <div className="col-lg-8">
                            {role === "Candidate" ? (
                              <ContactInfoCandidate
                                basicInfo={candidateData[0]}
                                getProfile={getProfile}
                              />
                            ) : (
                              <>
                                <ContactInfo
                                  getProfile={getProfile}
                                  setLoading={setLoading}
                                />
                                {recData.yearsOfExperience !== null &&
                                  recData.industries !== null && (
                                    <RecruitmentDetails
                                      data={recData}
                                      dropdownData={dropdownData}
                                      setLoading={setLoading}
                                      setCategoryLoading={setCategoryLoading}
                                    />
                                  )}
                              </>
                            )}

                            {role === "Admin" && <AboutUs />}
                            {role === "Candidate" && (
                              <>
                                <SocialMedia
                                  role={role}
                                  linkedIn={candidateData[0].linkedIn}
                                  website={candidateData[0].website}
                                />
                                <StoryInfo
                                  role={role}
                                  storyinfo={candidateData[0].storyInfo}
                                />
                                {dropdownList.length > 0 && (
                                  <Skills
                                    role={role}
                                    skills={candidateData[0].primarySkills}
                                    industry={candidateData[0].skillSet[0]}
                                    dropdownList={dropdownList}
                                  />
                                )}
                                <Education
                                  role={role}
                                  education={candidateData[0].education}
                                  createdBy={createdBy}
                                />
                                <Experience
                                  role={role}
                                  experience={candidateData[0].experience}
                                />
                                <Certifications
                                  role={role}
                                  certificates={candidateData[0].certificates}
                                />
                                <Awards
                                  role={role}
                                  awardandhonors={
                                    candidateData[0].AwardsandHonors
                                  }
                                />
                                <Languages
                                  role={role}
                                  languages={candidateData[0].language}
                                />
                                <License
                                  role={role}
                                  license={candidateData[0].license}
                                />
                                {/* <Resume role={role} resumeURL={resume} /> */}
                                <CustomSection
                                  role={role}
                                  section={candidateData[0].customsection}
                                />
                              </>
                            )}
                            <div></div>
                            {role !== "SuperAdmin" && <Notifications />}
                            <Security userid={userid} />
                            {getTenantType() == 1 && <EditLogo />}
                            {role === "Recruiter" &&
                              tenant.includes("GIG - ") && <Terms />}
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    {(preference || role === "Recruiter") && (
                      <TabPanel>
                        <Preferences
                          dropdownList={dropdownList}
                          data={candidateData[0]}
                          loading={loading}
                          setLoading={setLoading}
                          recData={recData}
                        />
                      </TabPanel>
                    )}
                    <TabPanel></TabPanel>
                  </div>
                </Tabs>
              </div>
              {getTenantType() == 1 && <Footer />}
            </>
          )}
        </div>
      </body>
    </>
  );
}

export default MyProfile;
