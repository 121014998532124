import moment from "moment";
import React, { useEffect, useState } from "react";

const SearchParameters = (props) => {
  const {
    jobExpArr,
    jobTenantArr,
    jobTypeArr,
    jobWorkplaceArr,
    setJobExpArr,
    setJobTenantArr,
    setJobTypeArr,
    setJobWorkplaceArr,
    getJob,
    selectedDate,
    setSelectedDate,
    selectedExp,
    setSelectedExp,
    selectedTenant,
    setSelectedTenant,
    selectedType,
    setSelectedType,
    selectedWorkplace,
    setSelectedWorkplace,
    searchText,
  } = props;

  const [searchSkillValue, setSearchSkillValue] = useState("");
  const [optionsEnabled, setOptionsEnabled] = useState(false);
  const [jobExpEnabled, setJobExpEnabled] = useState(false);
  const [jobTenantEnabled, setJobTenantEnabled] = useState(false);
  const [jobTypeEnabled, setJobTypeEnabled] = useState(false);
  const [jobWorkplaceEnabled, setJobWorkplaceEnabled] = useState(false);

  const [options, setOptions] = useState([
    { value: "past month", label: "Past month", isToggle: false },
    { value: "past week", label: "Past week", isToggle: false },
    { value: "past 24 hours", label: "Past 24 hours", isToggle: false },
  ]);

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    if (props.filterData) {
      setJobExpArr(props.filterData?.experienceLevel);
      setJobTenantArr(props.filterData?.company);
      setJobTypeArr(props.filterData?.jobType);
      setJobWorkplaceArr(props.filterData?.workPlaceType);
    }
  }, [props.filterData]);

  const splitMatchedText = (str) => {
    let regExp;
    regExp = new RegExp(searchSkillValue, "gi");
    if (searchSkillValue) {
    }
    return str.replace(regExp, (match) => `<a href=''>${match}</a>`);
  };

  const dateHandler = (checked, title) => {
    if (checked) {
      setSelectedDate([title]);
    } else {
      setSelectedDate(selectedDate.filter((t) => t !== title));
    }
  };

  const expHandler = (checked, title) => {
    if (checked) {
      setSelectedExp([...selectedExp, title]);
    } else {
      setSelectedExp(selectedExp.filter((t) => t !== title));
    }
  };
  const tenantHandler = (checked, title) => {
    if (checked) {
      setSelectedTenant([...selectedTenant, title]);
    } else {
      setSelectedTenant(selectedTenant.filter((t) => t !== title));
    }
  };

  const typeHandler = (checked, title) => {
    if (checked) {
      setSelectedType([...selectedType, title]);
    } else {
      setSelectedType(selectedType.filter((t) => t !== title));
    }
  };

  const workPlaceHandler = (checked, title) => {
    if (checked) {
      setSelectedWorkplace([...selectedWorkplace, title]);
    } else {
      setSelectedWorkplace(selectedWorkplace.filter((t) => t !== title));
    }
  };

  const applyFilter = () => {
    props.setCurrentPage(1);
    const object = {
      experienceLevel: selectedExp,
      jobType: selectedType,
      tenantname: selectedTenant,
      workPlaceType: selectedWorkplace,
      jobPostDate: selectedDate,
    };
    getJob(1, object, searchText);
    setJobWorkplaceEnabled(false);
    setJobTypeEnabled(false);
    setJobTenantEnabled(false);
    setJobExpEnabled(false);
    setOptionsEnabled(false);
  };

  const clearFilter = () => {
    setSelectedWorkplace([]);
    setSelectedType([]);
    setSelectedTenant([]);
    setSelectedExp([]);
    setSelectedDate([]);
    setJobWorkplaceEnabled(false);
    setJobTypeEnabled(false);
    setJobTenantEnabled(false);
    setJobExpEnabled(false);
    setOptionsEnabled(false);
    props.setJobs(props.jobData);
    getJob(1, []);
  };

  return (
    <div className="d-flex gap-2 d-block-768 m-2">
      <div className={!matches ? "col-2 " : ""}>
        <div
          className="mb-2 border rounded p-2"
          style={{ backgroundColor: "white" }}
        >
          <button
            type="button"
            className="btn btn-collapsible"
            onClick={() => setOptionsEnabled(!optionsEnabled)}
          >
            <i
              className={
                optionsEnabled
                  ? "fal fa-angle-down fa-fw"
                  : "fal fa-angle-up fa-fw"
              }
            />
            <span
              className="p-relative"
              onClick={() => setOptionsEnabled(!optionsEnabled)}
            >
              <small>Posted on</small>
              {selectedDate.length > 0 && <span className="jewel"></span>}
            </span>
          </button>
          {optionsEnabled &&
            (options?.length > 0 ? (
              <div
                className="mb-2 pl-1 pt-1"
                style={{ backgroundColor: "white" }}
              >
                <div style={{ maxHeight: "250px", overflowY: "auto" }}>
                  {options?.map((i, index) => (
                    <div className="d-flex align-items-center m-1" key={index}>
                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: splitMatchedText(i.label),
                          }}
                        ></span>
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedDate.includes(i.label)}
                            onChange={(e) =>
                              dateHandler(e.target.checked, i.label)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <hr className="p-0" />
              </div>
            ) : (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-users-slash"></i>
                  </div>
                  <div className="mt-2">No Records found</div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* Exp */}
      <div className={!matches ? "col-2 " : ""}>
        <div
          className="mb-2 border rounded p-2"
          style={{ backgroundColor: "white" }}
        >
          <button
            type="button"
            className="btn btn-collapsible"
            onClick={() => setJobExpEnabled(!jobExpEnabled)}
          >
            <i
              className={
                jobExpEnabled
                  ? "fal fa-angle-down fa-fw"
                  : "fal fa-angle-up fa-fw"
              }
            />
            <span className="p-relative">
              <small>Experience Level</small>
              {selectedExp.length > 0 && <span className="jewel"></span>}
            </span>
          </button>
          {jobExpEnabled &&
            (jobExpArr?.length > 0 ? (
              <div
                className="mb-2 pl-1 pt-1"
                style={{ backgroundColor: "white" }}
              >
                <div style={{ maxHeight: "250px", overflowY: "auto" }}>
                  {jobExpArr?.map((i, index) => (
                    <div className="d-flex align-items-center m-1" key={index}>
                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: splitMatchedText(i),
                          }}
                        ></span>
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedExp.includes(i)}
                            onChange={(e) => expHandler(e.target.checked, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <hr className="p-0" />
              </div>
            ) : (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-users-slash"></i>
                  </div>
                  <div className="mt-2">No Records found</div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* tenant */}
      <div className={!matches ? "col-2 " : ""}>
        <div
          className="mb-2 border rounded p-2"
          style={{ backgroundColor: "white" }}
        >
          <button
            type="button"
            className="btn btn-collapsible"
            onClick={() => setJobTenantEnabled(!jobTenantEnabled)}
          >
            <i
              className={
                jobTenantEnabled
                  ? "fal fa-angle-down fa-fw"
                  : "fal fa-angle-up fa-fw"
              }
            />
            <span className="p-relative">
              <small>Company</small>
              {selectedTenant.length > 0 && <span className="jewel"></span>}
            </span>
          </button>
          {jobTenantEnabled &&
            (jobTenantArr?.length > 0 ? (
              <div
                className="mb-2 pl-1 pt-1"
                style={{ backgroundColor: "white" }}
              >
                <div style={{ maxHeight: "250px", overflowY: "auto" }}>
                  {jobTenantArr?.map((i, index) => (
                    <div className="d-flex align-items-center m-1" key={index}>
                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: splitMatchedText(i),
                          }}
                        ></span>
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedTenant.includes(i)}
                            onChange={(e) => tenantHandler(e.target.checked, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <hr className="p-0" />
              </div>
            ) : (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-users-slash"></i>
                  </div>
                  <div className="mt-2">No Records found</div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* jobType */}
      <div className={!matches ? "col-2 " : ""}>
        <div
          className="mb-2 border rounded p-2"
          style={{ backgroundColor: "white" }}
        >
          <button
            type="button"
            className="btn btn-collapsible"
            onClick={() => setJobTypeEnabled(!jobTypeEnabled)}
          >
            <i
              className={
                jobTypeEnabled
                  ? "fal fa-angle-down fa-fw"
                  : "fal fa-angle-up fa-fw"
              }
            />
            <span className="p-relative">
              <small>Job Type</small>
              {selectedType.length > 0 && <span className="jewel"></span>}
            </span>
          </button>
          {jobTypeEnabled &&
            (jobTypeArr?.length > 0 ? (
              <div
                className="mb-2 pl-1 pt-1"
                style={{ backgroundColor: "white" }}
              >
                <div style={{ maxHeight: "250px", overflowY: "auto" }}>
                  {jobTypeArr?.map((i, index) => (
                    <div className="d-flex align-items-center m-1" key={index}>
                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: splitMatchedText(i),
                          }}
                        ></span>
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedType.includes(i)}
                            onChange={(e) => typeHandler(e.target.checked, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <hr className="p-0" />
              </div>
            ) : (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-users-slash"></i>
                  </div>
                  <div className="mt-2">No Records found</div>
                </div>
              </div>
            ))}
        </div>
      </div>
      {/* workplaceTypre */}
      <div className={!matches ? "col-2 " : ""}>
        <div
          className="mb-2 border rounded p-2"
          style={{ backgroundColor: "white" }}
        >
          <button
            type="button"
            className="btn btn-collapsible"
            onClick={() => setJobWorkplaceEnabled(!jobWorkplaceEnabled)}
          >
            <i
              className={
                jobWorkplaceEnabled
                  ? "fal fa-angle-down fa-fw"
                  : "fal fa-angle-up fa-fw"
              }
            />
            <span className="p-relative">
              <small>Workplace Type</small>
              {selectedWorkplace.length > 0 && <span className="jewel"></span>}
            </span>
          </button>
          {jobWorkplaceEnabled &&
            (jobWorkplaceArr?.length > 0 ? (
              <div
                className="mb-2 pl-1 pt-1"
                style={{ backgroundColor: "white" }}
              >
                <div style={{ maxHeight: "250px", overflowY: "auto" }}>
                  {jobWorkplaceArr?.map((i, index) => (
                    <div className="d-flex align-items-center m-1" key={index}>
                      <div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: splitMatchedText(i),
                          }}
                        ></span>
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedWorkplace.includes(i)}
                            onChange={(e) =>
                              workPlaceHandler(e.target.checked, i)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <hr className="p-0" />
              </div>
            ) : (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-users-slash"></i>
                  </div>
                  <div className="mt-2">No Records found</div>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div
        className={!matches ? "col-2 " : ""}
        style={{ marginRight: "-72px" }}
      >
        <button
          className="btn btn-sm btn-secondary"
          onClick={() => applyFilter()}
        >
          Filter
        </button>
        <button
          className="btn btn-sm btn-secondary"
          style={{ marginLeft: "5px" }}
          onClick={() => clearFilter()}
          disabled={
            selectedExp.length +
              selectedType.length +
              selectedWorkplace.length +
              selectedTenant.length +
              selectedDate.length ==
            0
          }
        >
          Clear All
        </button>
      </div>
    </div>
  );
};

export default SearchParameters;
