import React, { useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useLocation } from "react-router-dom";
import { getSourceDetails } from "../../../../../../actions/curator/CuratorSideview";
import Loader from "../../../../../Loader/Loader";

const Source = () => {
  const location = useLocation();
  const poolId = location.pathname.split("/").pop();

  const dispatch = useDispatch();

  const { sourceDetailsLoading, sourceDetails } = useSelector(
    (state) => ({
      sourceDetailsLoading: state.curatorSideViewDetailsReducer.sourceDetailsLoading,
      sourceDetails: state.curatorSideViewDetailsReducer.sourceDetails,
    }),
    shallowEqual
  );

  useEffect(() => {
    const payload = {
      poolId: poolId,
    };
    dispatch(getSourceDetails(payload));
  }, [poolId, dispatch]);

  const formatDateTime = (datetime) => {
    const dateObj = new Date(datetime);
    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();
    return `${month}/${day}/${year}`;
  };

  if (sourceDetailsLoading) {
    return <Loader />;
  }

  return (
    <div>
      <table className="table">
        <thead>
          <tr>
            <th scope="col" style={{ fontSize: '11px' }}>Sl No</th> 
            <th scope="col" style={{ fontSize: '11px' }}>Source Channel</th>
            <th scope="col" style={{ fontSize: '11px' }}>Created Date</th>
          </tr>
        </thead>
        <tbody>
          {sourceDetails.map((source, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                {source.publishTo === "Company Recruiters (Curator)" ? (
                  "Branded Talent Pool"
                ) : (
                  source.publishTo
                )}
              </td>
              <td>{formatDateTime(source.createdDateTime)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Source;
