import React, { useEffect, useState } from "react";
import Autosuggest from "react-autosuggest";
import TagsInput from "react-tagsinput";
import { post } from "../../../../service/ApiService";
import Loader from "../Loader/Loader";
import { debounce } from "lodash";

const SkillsSearchable = ({
  onChange,
  disabled,
  inputArr,
  skillinput,
  onChangeInput,
  inputProps,
  isValid,
  isFrom,
  addJobData,
}) => {
  const [dropDownList, setDropDownList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [apiCounter, setApiCounter] = useState(0);

  const fetchSkills = async (skillinput) => {
    try {
      setLoading(true);
      setApiCounter((prev) => prev + 1);
      let searchResp = await post("/searchSkills/search", { text: skillinput });
      if (searchResp.status === 200) {
        let allSkills = searchResp?.data;
        setDropDownList(allSkills);
        setApiCounter((prev) => prev - 1);
      }
    } catch (error) {
      console.log(error);
      setApiCounter((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (apiCounter === 0) setLoading(false);
  }, [apiCounter]);

  useEffect(() => {
    const delay = 500; // Adjust this value to set the debounce time in milliseconds
    const debounceTimer = setTimeout(() => {
      if (skillinput?.length > 1) fetchSkills(skillinput);
    }, delay);

    // Cleanup the previous timer on each render to avoid multiple requests
    return () => clearTimeout(debounceTimer);
  }, [skillinput]);

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "45%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 999,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0 || Object.keys(dropDownList).length === 0
          ? []
          : dropDownList.filter(
              (item) =>
                item.toLowerCase().includes(inputValue) &&
                !inputArr.includes(item)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        theme={theme}
      />
    );
  };

  return (
    <>
      {loading && <Loader />}
      <TagsInput
        renderInput={autocompleteRenderInput}
        value={inputArr}
        onChange={(skills) => onChange(skills)}
        disabled={disabled}
        inputValue={skillinput}
        onChangeInput={(skills) => onChangeInput(skills)}
        placeholder=""
        inputProps={inputProps}
        className={
          isFrom === "addJob" &&
          isValid &&
          addJobData.primarySkills.length === 0
            ? "border border-danger rounded p-2"
            : ""
        }
        // className="inputHeight2"
      />
    </>
  );
};

export default SkillsSearchable;
