import React, { Fragment, useState } from "react";
import {
  SetPasswordSideImage,
  LoginAppLogo,
  domain,
} from "../../constants/Constants";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { post } from "../../service/ApiService";
import Loader from "../Loader/Loader";
function ResetPassword() {
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [passwordLength, setPasswordLength] = useState(false);
  let [passwordValidation, setPasswordValidation] = useState(false);
  let [enableConfirm, setEnableConfirm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let [enableCondition, setEnableCondition] = useState(false);
  let [isChecked, setIsChecked] = useState("");
  let [matchPassword, setMatchPassword] = useState(false);
  let [enableSubmit, setEnableSubmit] = useState(false);
  let history = useHistory();
  const [loading, setLoading] = useState(false);
  const showPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  const ResetPasswordAPI = async (params) => {
    setLoading(true);
    try {
      const res = await post("/resetpassword", params);
      if (res.status === 200) {
        toast.success(
          "You've successfully updated your password.We are taking you to your 'High5' login page now"
        );
        setTimeout(() => history.push("/login"), 5000);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Error");
    }
  };
  const validatePassword = (e) => {
    if (e.target.id === "password") {
      setPassword(e.target.value);
      passwordLength = e.target.value;
    }
    if (passwordLength.length >= 8) {
      setPasswordLength(true);
    } else {
      setPasswordLength(false);
    }
    if (e.target.id === "confirmPassword") {
      setConfirmPassword(e.target.value);
      confirmPassword = e.target.value;
    }
    let specialCharacterPresent = /^(?=.*[!@#$%^&*])/;
    let numberPresent = /^(?=.*[0-9])/;
    if (
      numberPresent.test(password) ||
      specialCharacterPresent.test(password)
    ) {
      setPasswordValidation(true);
      setPasswordLength(true);
    } else {
      setPasswordValidation(false);
      setPasswordLength(false);
    }
    if (
      passwordLength.length >= 8 &&
      (numberPresent.test(password) || specialCharacterPresent.test(password))
    ) {
      setEnableConfirm(true);
    }
    if (e.target.id === "password") {
      if (password === confirmPassword) {
        setMatchPassword(false);
        // setEnableCondition(true)
      } else {
        setMatchPassword(true);
        // setIsChecked(false)
        // setEnableCondition(false)
        setEnableSubmit(false);
      }
    }
    if (e.target.id === "confirmPassword") {
      if (password === confirmPassword) {
        setMatchPassword(false);
        // setEnableCondition(true)
      } else {
        setMatchPassword(true);
        // setIsChecked(false)
        // setEnableCondition(false)
        setEnableSubmit(false);
      }
    }
    return password === confirmPassword;
  };
  const validateAgreement = (e) => {
    if (e.target.checked) {
      // setIsChecked(true)
      setEnableSubmit(true);
    } else {
      // setIsChecked(false)
      setEnableSubmit(false);
    }
    if (e.target.id === "termsAndConditions") {
      if (enableSubmit === false) {
        setEnableSubmit(true);
      } else {
        setEnableSubmit(false);
      }
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!validatePassword(e)) {
      toast.error("Passwords Do Not Match");
    } else {
      let Id = window.location.href.toLowerCase();
      Id = Id.split("#")[1];
      const params = {
        UUID: Id,
        NewPassword: password,
        domainName: domain,
      };
      ResetPasswordAPI(params);
    }
  };
  return (
    <Fragment>
      {loading && <Loader />}
      <div id="PasswordSetup" className="login-layout">
        <div className="col-left d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-7">
                <div class="success-animation"></div>
                <div className="{ 'd-none': formComplete }">
                  <a className="logo" href="/">
                    <img src={LoginAppLogo} alt="" />
                  </a>
                  <div className="section section-sm">
                    <h2>Set Your New Password</h2>
                    <p>
                      Enter the password you would like to set for your account.
                    </p>
                    <div className="mb-3">
                      <p className="mt-2">
                        <small>
                          <strong className="mr-2 mb-1 d-block">
                            Password Requirements
                          </strong>
                        </small>
                        <span
                          className={
                            passwordLength !== true
                              ? "tag mr-2"
                              : "tag mr-2 tag-green2"
                          }
                        >
                          Min 8 Characters
                          {passwordLength !== true ? (
                            <i className="ml-1 far fa-circle"></i>
                          ) : (
                            <i className="ml-1 fas fa-check-circle"></i>
                          )}
                        </span>
                        <span
                          className={
                            passwordValidation !== true
                              ? "tag mr-2"
                              : "tag mr-2 tag-green2"
                          }
                        >
                          1 Number Or Special Character
                          {passwordValidation !== true ? (
                            <i className="ml-1 far fa-circle"></i>
                          ) : (
                            <i className="ml-1 fas fa-check-circle"></i>
                          )}
                        </span>
                      </p>
                    </div>
                    <div className="form-floating mb-2 form-password">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="password"
                        onChange={validatePassword}
                        aria-describedby="newPasswordSetup"
                        placeholder="New Password"
                      />
                      <label>New Password*</label>
                      {password.length > 0 && (
                        <button
                          //   v-on:click="revealPassword('security')"
                          onClick={showPasswordClick}
                          type="button"
                          className="btn btn-sm btn-text btn-text-accent h-100"
                        >
                          <i
                            className={
                              showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                            }
                            aria-hidden="true"
                          ></i>
                        </button>
                      )}
                      {matchPassword === true}
                    </div>
                    <div className="form-floating mb-2">
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        id="confirmPassword"
                        onChange={validatePassword}
                        disabled={!enableConfirm}
                        aria-describedby="newPasswordConfirmation"
                        placeholder="Confirm Password"
                      />
                      <label>Confirm Password*</label>
                      {matchPassword === true && (
                        <small className="text-danger mb-2">
                          Passwords do not match
                        </small>
                      )}
                    </div>
                    {/* <div class="form my-3">
                                            <input type="checkbox" class="form-check-input form-check-input--primary d-inline-block" style={{ width: "20px" }} onChange={validateAgreement} checked={isChecked} disabled={!enableCondition} value="I agree" id="termsAndConditions" aria-describedby="termsAndConditionsAgreement" />
                                            <label class="p-1 pl-2" for="termsAndConditions">I agree to the <a href="/termsandconditions">Terms and Conditions</a></label>
                                        </div> */}
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="btn btn-dark w-100"
                    >
                      <span>Confirm Password</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-right bg-cover bg-center"
          style={{
            backgroundImage: `url(${SetPasswordSideImage})`,
            height: "auto",
          }}
        ></div>
      </div>
    </Fragment>
  );
}
export default ResetPassword;
