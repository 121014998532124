import React, { useEffect, useState } from "react";
import ReactToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";
import ReportTopNavBar from "../../../report/ReportTopNavBar";
import SideNavBar from "../../../layout/SideNavBar";
import { get, post } from "../../../../service/ApiService";
import moment from "moment";
import Filter from "./Filter";
import Sort from "../../../common/Sort";
import OutsideClickHandler from "react-outside-click-handler";
import { getTenantType, getUserId } from "../../../../service/AuthService";
import ReportHeader from "./ReportHeader";
import ReportTable from "./ReportTable";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import TopNavBar from "../../../layout/TopNavBar";
import Footer from "../../../layout/Footer";

function UnsubUserReport() {
  const reportHeaders = [
    { id: 0, name: "First name" },
    { id: 1, name: "Last name" },
    { id: 2, name: "Email" },
    { id: 3, name: "User type" },
    { id: 4, name: "Reason for unsubscribe" },
  ];
  const [pageLimit, setPageLimit] = useState(3);
  const [toggle, setToggle] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [columns, setColumns] = useState([]);
  const [dateSearch, setDateSearch] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [toDate, setToDate] = useState(new Date());
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedJobLocation, setSelectedJobLocation] = useState([]);
  const [selectedPrimarySkills, setSelectedPrimarySkills] = useState([]);
  const [candidateTitles, setCandidateTitles] = useState([]);
  const [candidateTypes, setCandidateTypes] = useState([]);
  const [candidatePrimarySkills, setCandidatePrimarySkills] = useState([]);
  const [candidateJobLocation, setCandidateJobLocation] = useState([]);
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [gigData, setGigData] = useState([]);
  const [empData, setEmpData] = useState([]);
  const [tenantData, setTenantData] = useState([]);
  const [dataa, setDataa] = useState([]);

  const sortObject = [
    {
      id: 1,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Type",
      value: "jobType",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Candidate Name",
      value: "candidateName",
      icon: "fal fa-fw fa-signature  mr-2 dd-sort",
    },
    {
      id: 4,
      label: "Job Location",
      value: "city",
      icon: "fal fa-fw fa-signature  mr-2 dd-sort",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };

  useEffect(() => {
    fetchMasterData();
  }, []);

  const fetchMasterData = async () => {
    setLoading(true);
    let params = {
      toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
      fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
    };
    try {
      let res = await get("/inviteunsubscribed/fetchUnsubscribedUsers");
      if (res.status === 200) {
        let result = res.data;
        if (result.length == 0) {
          // toast.error("No record found. Please select another date range");
        }
        setGigData(res.data);
        setEmpData(res.data);
        setTenantData(res.data);
        setReportData(res.data);
        setFilteredData(result);
        setDateSearch(result);
        setDataa(result);

        const pageArray = new Array(Math.ceil(result.length / perPage))
          .fill()
          .map((_, i) => i + 1);
        setPaginationArray(pageArray);
        setPaginationGroup(pageArray.slice(0, pageLimit));
        let title = [];
        let type = [];
        let jobLocations = [];
        let Skill = [];
        result.forEach((item) => {
          if (item.jobTitle) {
            if (!title.includes(item.jobTitle)) {
              title.push(item.jobTitle.trim());
            }
          }
        });
        result.forEach((item) => {
          if (item.jobType) {
            if (!type.includes(item.jobType)) {
              type.push(item.jobType.trim());
            }
          }
        });
        result.forEach((item) => {
          if (item.city) {
            jobLocations.push(item.city);
          } else {
            jobLocations.push("Remote");
          }
        });
        result.forEach((item) => {
          if (
            JSON.parse(item.primarySkills) !== null &&
            JSON.parse(item.primarySkills).length !== 0
          ) {
            let SkillSet = JSON.parse(item.primarySkills)
              .map((skill) => skill)
              .join("- ");
            Skill.push(...SkillSet.split("- "));
          }
        });
        setCandidateTitles(title);
        setCandidateTypes(type);
        setCandidateJobLocation([...new Set(jobLocations)]);
        setCandidatePrimarySkills([...new Set(Skill)]);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again");
    }
  };

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  //! Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const handleDateSearch = () => {
    setSelectedTitles([]);
    setSelectedTypes([]);
    setSelectedJobLocation([]);
    setSelectedPrimarySkills([]);
    setSortCount(0);
    setSortArray([]);
    let startDate = moment(new Date(fromDate)).format("MM/DD/YYYY");
    //.toString();
    let endDate = moment(new Date(toDate)).format("MM/DD/YYYY");
    //.toString();
    if (new Date(startDate) > new Date()) {
      toast.error(`'From Date' cannot be future date`);
    } else if (new Date(endDate) > new Date()) {
      toast.error(`'To Date' cannot be future date`);
    } else if (new Date(startDate) > new Date(endDate)) {
      toast.error(`'From Date' should not be greater than 'To Date'`);
    } else {
      setCurrentPage(1);
      fetchMasterData();
    }
  };

  const onChangeSelect = (e) => {
    if (e.target.value === "All") {
      setPerPage(filteredData.length);
      setPaginationArray([1]);
      setPaginationGroup([1]);
      setCurrentPage(1);
    } else {
      // setPageLimit(filteredData.length < 10 ? 1 : 3)
      const pageGroup = new Array(
        Math.ceil(filteredData.length / Number(e.target.value))
      )
        .fill()
        .map((_, i) => i + 1);
      setPaginationArray(pageGroup);
      setPaginationGroup(pageGroup.slice(0, pageLimit));
      setPerPage(Number(e.target.value));
      setCurrentPage(1);
    }
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * perPage - perPage;
    const endIndex = startIndex + +perPage;
    return filteredData.slice(startIndex, endIndex);
  };

  {
    /* Sort and Filter */
  }
  const applySort = (sortedData, close) => {
    setSelectedTitles([]);
    setSelectedTypes([]);
    setSelectedJobLocation([]);
    setSelectedPrimarySkills([]);
    close && setSort(false);
    setFilteredData(sortedData);
    const pageGroup = new Array(Math.ceil(sortedData.length / perPage))
      .fill()
      .map((_, i) => i + 1);
    setPaginationArray(pageGroup);
    setPaginationGroup(pageGroup.slice(0, pageLimit));
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(
      dateSearch.map((i) => ({
        ...i,
        jobLocation: i.jobLocation ? i.jobLocation.trim() : "Remote",
      })),
      true
    );
  };

  const applyFilter = (finalData) => {
    //sortClearAll()
    setFilter(false);
    setSortCount(0);
    setCurrentPage(1);
    setSortArray([]);
    if (finalData.length === 0) {
      toast.error("No Record Found Matching Your Filter Criteria");
    } else {
      setFilteredData(finalData);
      const pageGroup = new Array(Math.ceil(finalData.length / perPage))
        .fill()
        .map((_, i) => i + 1);
      setPaginationArray(pageGroup);
      setPaginationGroup(pageGroup.slice(0, pageLimit));
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar isFrom={"Report"} handleToggale={toggleDiv} />
        {/* <ReportTopNavBar handleToggale={toggleDiv} /> */}
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="card card-flat card-borderless p-4 mb-3">
                    <ReportHeader
                      dataa={dataa}
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      toDate={toDate}
                      setToDate={setToDate}
                      handleDateSearch={handleDateSearch}
                      getPaginatedData={getPaginatedData}
                      setSort={setSort}
                      sort={sort}
                      filteredData={filteredData}
                      sortCount={sortCount}
                      orderList={orderList}
                      sortObject={sortObject}
                      applySort={applySort}
                      setSortCount={setSortCount}
                      setSortArray={setSortArray}
                      onChangeSelect={onChangeSelect}
                      sortArray={sortArray}
                      selectedTitles={selectedTitles}
                      setFilter={setFilter}
                      selectedTypes={selectedTypes}
                      selectedPrimarySkills={selectedPrimarySkills}
                      selectedJobLocation={selectedJobLocation}
                      dateSearch={dateSearch}
                    />
                    {filteredData.length > 0 ? (
                      <ReportTable
                        getPaginatedData={getPaginatedData}
                        reportHeaders={reportHeaders}
                      />
                    ) : (
                      <NoRecord />
                    )}

                    {getPaginatedData().length > 0 && (
                      <div className="d-flex justify-content-end pt-4 px-1">
                        <div
                          className="ml-auto mx-auto-370 d-flex align-items-center"
                          // style={{ position: 'absolute', left: '85%' }}
                        >
                          <button
                            onClick={() => {
                              setPageType("down");
                              setCurrentPage((page) => page - 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === 1 ||
                              getPaginatedData().length === 0
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-left"></i>
                          </button>

                          {getPaginatedData().length > 0
                            ? paginationGroup.map((item) => (
                                <button
                                  type="button"
                                  className={
                                    currentPage === item
                                      ? "btn btn-text px-2 bg-secondary text-white"
                                      : "btn btn-text px-2"
                                  }
                                  onClick={changePage}
                                >
                                  {item}
                                </button>
                              ))
                            : ""}

                          <button
                            onClick={() => {
                              setPageType("up");
                              setCurrentPage((page) => page + 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === paginationArray.slice(-1)[0] ||
                              getPaginatedData().length === 0
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-right"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
        {filter && (
          <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
            <Filter
              reportData={dateSearch}
              selectedTitles={selectedTitles}
              setSelectedTitles={setSelectedTitles}
              candidateTitles={candidateTitles}
              selectedTypes={selectedTypes}
              setSelectedTypes={setSelectedTypes}
              candidateTypes={candidateTypes}
              selectedJobLocation={selectedJobLocation}
              setSelectedJobLocation={setSelectedJobLocation}
              candidateJobLocation={candidateJobLocation}
              candidatePrimarySkills={candidatePrimarySkills}
              selectedPrimarySkills={selectedPrimarySkills}
              setSelectedPrimarySkills={setSelectedPrimarySkills}
              applyFilter={applyFilter}
            />
          </OutsideClickHandler>
        )}
      </body>
    </div>
  );
}

export default UnsubUserReport;
