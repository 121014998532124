import React, { useState } from "react";
import moment from "moment";

function App(props) {
  const {
    jobData,
    loading,
    error,
    searched,
    setSelectedJob,
    selectedJob,
  } = props;

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive report-table">
            <table
              id="reportTable"
              className="table custom-table-style table-hover table-bordered"
            >
              <thead>
                <tr className="report-heading">
                  <th>Job ID</th>
                  <th>Job Title</th>
                  <th>Published Date</th>
                  <th>Select</th>
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr className="text-center">
                    <td colSpan={4}>
                      <h4>Loading...</h4>
                    </td>
                  </tr>
                )}
                {error && (
                  <tr className="text-center">
                    <td colSpan={4}>
                      <h4>Failed to fetch data</h4>
                    </td>
                  </tr>
                )}
                {!searched && (
                  <tr className="text-center">
                    <td colSpan={4}>
                      <h6 className="mt-3">Select any filters & Search.</h6>
                    </td>
                  </tr>
                )}
                {!loading && jobData && jobData.length === 0 && (
                  <tr className="text-center">
                    <td colSpan={4}>
                      <div className="mb-3">
                        <div className="text-center p-3">
                          <div className="avatar avatar-lg">
                            <i className="fad fa-users-slash"></i>
                          </div>
                          <div className="mt-2">No Record Found</div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
                {!loading && jobData && jobData.length > 0 && (
                  <>
                    {jobData.map((item, key) => (
                      <tr key={key}>
                        <td>{item.jobId}</td>
                        <td>{item.jobTitle}</td>
                        <td>
                          {moment(item.publishedDate).format("MM/DD/YYYY")}
                        </td>
                        <td>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value={item.jobId}
                              id={item.jobId}
                              style={{ width: "20px", height: "20px" }}
                              checked={
                                selectedJob && selectedJob?.jobId === item.jobId
                              }
                              onChange={() => setSelectedJob(item)}
                            />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
