import React, { useState, useEffect } from "react";
import { get } from "../../service/ApiService";
import { domain } from "../../constants/Constants";
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "../Loader/Loader";
import { Row, Col } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import Sort from "../common/Sort";
import ShareRecFilter from "./ShareRecFilter";

const SelectRecruiters = (props) => {
  const [recruiterList, setRecruiterList] = useState([]);
  const [recruiterLists, setRecruiterLists] = useState([]);
  const [MasterChecked, setMasterChecked] = useState(false);
  const [paginationArray, setPaginationArray] = useState([]);
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [filterBy, setFilterBy] = useState([]);
  const [sortModal, setSortModal] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [name, setName] = useState([]);
  let pageLimit = 10;
  let dataLimit = 10;

  // getAllDataFromJobIndex
  useEffect(() => {
    fetchGigRecruiters();
  }, []);

  const sortObject = [
    {
      id: 1,
      label: "Name",
      //value: "tenantname",
      value: "firstName",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "User Type",
      value: "userType",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  const fetchGigRecruiters = async () => {
    setLoading(true);
    const params = {
      domain: domain,
    };
    try {
      let name = [];
      const res = await get(`/requestlist/getRecuitersList`, params);
      let filtered = res.data.map((item) => {
        return {
          ...item,
          userType:
            item.fk_role === 1 ? "Recruitment Firm" : "Individual Recruiter",
        };
      });

      if (res.status === 200) {
      }
      filtered.forEach((item) => {
        if (item.tenantname && !name.includes(item.tenantname)) {
          if (item.tenantname.includes("GIG -")) {
            name.push(item.tenantname.replace("GIG -", "").trim());
          } else {
            name.push(item.tenantname.trim());
          }
        }
      });
      filtered = filtered.filter(
        (item) => item.fk_role === 1 || item.tenantname.includes("GIG -")
      );
      filtered = filtered.map((item) => {
        return {
          ...item,
          firstName: item.firstName.charAt(0).toUpperCase() + item.firstName.slice(1)
        };
      });
      
      setRecruiterList(filtered);
      setName(name);
      setFilterBy(filtered);
      setRecruiterLists(filtered);
      props.setRecruiterCount(filtered.length);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Some Error Occurred while Fetching Gig Recruiters");
    }
  };
  const onMasterCheck = async (e) => {
    if (e.target.checked) {
      props.setRecruiterChecked([...recruiterList]);
      setMasterChecked(!MasterChecked);
    } else {
      props.setRecruiterChecked([]);
      setMasterChecked(false);
    }
  };

  // Update List Item's state and Master Checkbox State
  const onItemCheck = async (checked, users) => {
    if (checked) {
      setMasterChecked(false);
      if (users.fk_role === 1) {
        props.setRecruiterChecked([
          ...props.recruiterChecked,
          ...recruiterList.filter((item) => item.fk_tenant === users.fk_tenant),
        ]);
      } else {
        props.setRecruiterChecked([...props.recruiterChecked, users]);
      }
    } else {
      setMasterChecked(false);
      props.setRecruiterChecked(
        props.recruiterChecked.filter((t) => t.fk_tenant !== users.fk_tenant)
      );
    }
  };
  useEffect(() => {
    if (pageType !== "") {
      if (pageType === "up") {
        if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
          setPaginationGroup(
            paginationArray.slice(
              currentPage - 1,
              currentPage + (pageLimit - 1)
            )
          );
        }
      }
      //if (pageType === "down") {
      else {
        if (currentPage % 10 === 0 && currentPage !== 1) {
          setPaginationGroup(
            paginationArray.slice(currentPage - 10, currentPage)
          );
        }
      }
    } else {
      setPaginationArray(
        new Array(Math.ceil(recruiterLists.length / dataLimit))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(recruiterLists.length / dataLimit))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageLimit)
      );
    }
  }, [recruiterLists, currentPage]);

  const getPaginatedData = () => {
    const startIndex = currentPage * perPage - perPage;
    const endIndex = startIndex + +perPage;
    return filterBy.slice(startIndex, endIndex);
  };

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }
  const applySort = (sortedData, close) => {
    close && setSortModal(false);
    setFilterBy(sortedData);
  };
  //useEffect(() => {}, [props.recruiterChecked]);

  const openFilter = () => {
    setFilter(true);
  };

  const applyFilter = (data, selectedName, selectedUserType) => {
    setFilter(false);
    setSelectedName(selectedName);
    setSelectedUserType(selectedUserType);
    setFilterBy(data);
    if (data.length === 0) {
      toast.error("No Record Found Matching Your Filter Criteria");
    } else {
      const pageGroup = new Array(Math.ceil(data.length / perPage))
        .fill()
        .map((_, i) => i + 1);
      setPaginationArray(pageGroup);
      setPaginationGroup(pageGroup.slice(0, pageLimit));
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Row>
        <Col>
          <div className="row">
            <div className="col-lg-8">
              <strong className="mb-4">Recruiters</strong>
            </div>
            <div className="col-lg-2">
              <div class="dropdown-sort dd-sort">
                <button
                  type="button"
                  onClick={() => setSortModal(!sortModal)}
                  class="btn btn-sm w-auto btn-text-accent dd-sort"
                >
                  <i
                    aria-hidden="true"
                    class="fad fa-sort-down font-16 dd-sort mr-1"
                  ></i>
                  Sort / <span class="dd-sort">{sortCount}</span>
                </button>
                {
                  <OutsideClickHandler
                    onOutsideClick={() => setSortModal(false)}
                  >
                    {sortModal && (
                      <Sort
                        sortData={recruiterList}
                        orderArray={orderList}
                        sortObjects={sortObject}
                        applySort={applySort}
                        setSortCount={setSortCount}
                        sortCount={sortCount}
                        setSortArray={setSortArray}
                        sortArray={sortArray}
                        sortingFor={"Sort by Name and User Type"}
                      />
                    )}
                  </OutsideClickHandler>
                }
              </div>
            </div>
            <div className="col-lg-2">
              <div class="dropdown position-relative">
                <button
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Filter"
                  onClick={openFilter}
                  type="button"
                  class="btn btn-sm btn-text-accent w-auto"
                >
                  <i class="fas fa-filter"></i>
                  <span>Filter</span> /<span>{selectedName.length}</span>
                </button>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="row">
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={MasterChecked}
                          id="mastercheck"
                          style={{ height: "1.2rem", width: "1.2rem" }}
                          onChange={(e) => onMasterCheck(e)}
                        />
                      </div>
                    </th>
                    <th scope="col">Name</th>
                    {/* <th scope="col">Email</th> */}
                    <th scope="col">User Type</th>
                    {/* <th scope="col">Source</th> */}
                    <th scope="col">Added on</th>
                    {/* <th scope="col" className='ml-4'>Status</th> */}
                  </tr>
                </thead>
                <tbody>
                  {loading && <Loader />}
                  {getPaginatedData().map((user) => (
                    <tr key={user.id}>
                      <th scope="row">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            checked={props.recruiterChecked
                              .map((item) => item.fk_tenant)
                              .includes(user.fk_tenant)}
                            className="form-check-input"
                            // id="rowcheck{user.id}"
                            style={{ height: "1.2rem", width: "1.2rem" }}
                            onChange={(e) =>
                              onItemCheck(e.target.checked, user)
                            }
                          />
                        </div>
                      </th>
                      <td>
                        <td>
                          <span>
                            <span className="font-bold d-inline-1024 d-none">
                              Name:
                            </span>
                            {
                              <strong>
                                {user.tenantname.includes("GIG - ")
                                  ? user.tenantname.replace("GIG - ", "")
                                  : user.tenantname}
                              </strong>
                            }
                          </span>
                        </td>
                      </td>
                      <td>
                        <span>
                          <span className="font-bold d-inline-1024 d-none">
                            User Type:
                          </span>
                          {user.userType}
                          {/* {user.fk_role === 1
                            ? "Recruitment Firm"
                            : "Individual Recruiter"} */}
                        </span>
                      </td>
                      <td>
                        <span>
                          <span className="font-bold d-inline-1024 d-none">
                            Added on:
                          </span>
                          {moment(user.createddatetime).format("MM/DD/YYYY")}
                        </span>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {getPaginatedData().length > 0 && (
                <div className="d-flex justify-content-end pt-4 px-1">
                  <div
                    className="ml-auto mx-auto-370 d-flex align-items-center"
                    // style={{ position: 'absolute', left: '85%' }}
                  >
                    <button
                      onClick={() => {
                        setPageType("down");
                        setCurrentPage((page) => page - 1);
                      }}
                      type="button"
                      disabled={
                        currentPage === 1 || getPaginatedData().length === 0
                          ? true
                          : false
                      }
                      className="btn btn-icon px-2"
                    >
                      <i className="fal fa-chevron-circle-left"></i>
                    </button>

                    {/* {getPaginatedData().length > 0
                      ?  */}
                    {paginationGroup.map((item) => (
                      <button
                        type="button"
                        className={
                          currentPage === item
                            ? "btn btn-text px-2 bg-secondary text-white"
                            : "btn btn-text px-2"
                        }
                        onClick={changePage}
                      >
                        {item}
                      </button>
                    ))}
                    <button
                      onClick={() => {
                        setPageType("up");
                        setCurrentPage((page) => page + 1);
                      }}
                      type="button"
                      disabled={
                        currentPage === paginationArray.slice(-1)[0] ||
                        (getPaginatedData().length === 0 &&
                          getPaginatedData().length < 0)
                          ? true
                          : false
                      }
                      className="btn btn-icon px-2"
                    >
                      <i className="fal fa-chevron-circle-right"></i>
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      {filter && (
        <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
          <ShareRecFilter
            reportData={recruiterList}
            selectedName={selectedName}
            candidateName={name}
            selectedUserType={selectedUserType}
            candidateUserType={["Individual Recruiter", "Recruitment Firm"]}
            applyFilter={applyFilter}
          />
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default SelectRecruiters;
