import React, { useState } from "react";
import CandateAssessmentFullView from "./CandidateAssessmentFullView";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

function CandidateAssesmentListView(props) {
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [openAssessmentDetailsModel, setOpenAssessmentDetailsModel] = useState(
    false
  );

  const openAssessmentDetails = (module) => {
    setAssessmentDetails(module);
    setOpenAssessmentDetailsModel(true);
  };

  const closeAssessmentModal = () => {
    setOpenAssessmentDetailsModel(false);
  };

  return (
    <>
      <div class="row d-none d-none-1200 d-block-force">
        {(props.all || props.assigned) &&
          (props.assessmentDetails.length > 0 ||
          props.codingTests.filter((item) => item.Status === "pending").length >
            0 ? (
            <div>
              {props.assessmentDetails.map((item, index) => (
                <div className="d-flex-force w-100 mb-1">
                  <a
                    // onClick={() =>{openPopUP(item,item.status)}}
                    className="card card-flat w-100"
                  >
                    <div className="card-body">
                      <div>
                        <div>
                          <h6 className="mt-2">{item.reqTitle}</h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="d-flex align-items-center me-3">
                            <i
                              aria-hidden="true"
                              className="far fa-suitcase me-2 font-brandBlue"
                            ></i>
                            <span className="font-regular font-primary font-gray1">
                              {item.AssessmentType}
                            </span>
                          </span>
                        </div>
                        <div className="mt-1">
                          <small className="font-regular">
                            {moment(
                              new Date(item.created_date),
                              "YYYYMMDD"
                            ).format("MM/DD/YYYY")}{" "}
                            By {item.RecruiterFname + " " + item.RecruiterLname}
                          </small>
                        </div>
                        {/* <div>
                          <p className="mb-1">
                            <small>Progress</small>
                            <p><strong>{ item.status === 'Open' ? 'Not Yet Started' : item.status}</strong></p>
                          </p>       
                          <div className="alert-progress alert-progress-static bg-gray3">
                            <div className="alert-progress-bar" style={{ "width": "0%" }}></div>
                          </div>
                        </div> */}
                      </div>
                      <div className="mt-2">
                        <button
                          type="button"
                          className="btn btn-sm mr-1"
                          onClick={() =>
                            window.open(item.InterviewUrl, "_blank")
                          }
                        >
                          <span>Start</span>
                        </button>
                        {/* <button 
                          type="button" 
                          className="btn btn-secondary btn-sm btn-blue-disabled"
                          onClick={() => props.rejectOneWay(item.id_InterviewApplication, index)}
                        >
                          Reject
                          
                        </button> */}
                      </div>
                    </div>
                  </a>
                </div>
              ))}
              {props.codingTests
                .filter((item) => item.Status === "pending")
                .map((item, index) => (
                  <div className="d-flex-force w-100 mb-1">
                    <a
                      // onClick={() =>{openPopUP(item,item.status)}}
                      className="card card-flat w-100"
                    >
                      <div className="card-body">
                        <div>
                          <div>
                            <h6 className="mt-2">{item.reqNumber}</h6>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i
                                aria-hidden="true"
                                className="far fa-suitcase me-2 font-brandBlue"
                              ></i>
                              <span className="font-regular font-primary font-gray1">
                                Coding Test
                              </span>
                            </span>
                          </div>
                          <div className="mt-1">
                            <small className="font-regular">
                              {moment(
                                new Date(item.created_date),
                                "YYYYMMDD"
                              ).format("MM/DD/YYYY")}{" "}
                              By{" "}
                              {item.RecruiterFname + " " + item.RecruiterLname}
                            </small>
                          </div>
                          {/* <div>
                          <p className="mb-1">
                            <small>Progress</small>
                            <p><strong>{ item.status === 'Open' ? 'Not Yet Started' : item.status}</strong></p>
                          </p>       
                          <div className="alert-progress alert-progress-static bg-gray3">
                            <div className="alert-progress-bar" style={{ "width": "0%" }}></div>
                          </div>
                        </div> */}
                        </div>
                        <div className="mt-2">
                          <button
                            type="button"
                            className="btn btn-sm mr-1"
                            onClick={() =>
                              window.open(item.InvitationUrl, "_blank")
                            }
                          >
                            <span>Start</span>
                          </button>
                          {/* <button 
                          type="button" 
                          className="btn btn-secondary btn-sm btn-blue-disabled"
                          onClick={() => props.rejectOneWay(item.id_InterviewApplication, index)}
                        >
                          Reject
                          
                        </button> */}
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          ) : (
            !props.all && (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-comment-slash"></i>
                  </div>
                  <div className="mt-2">No Assessments Found at this time</div>
                </div>
              </div>
            )
          ))}

        {(props.all || props.completed) &&
          (props.completed1Way.length > 0 ||
          props.codingTests.filter((item) => item.Status === "Completed")
            .length > 0 ? (
            <div>
              {props.completed1Way.map((item, index) => (
                <div className="d-flex-force w-100 mb-1">
                  <a className="card card-flat w-100">
                    <div className="card-body">
                      <div>
                        <div>
                          <h6 className="mt-2">{item.reqTitle}</h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <span className="d-flex align-items-center me-3">
                            <i
                              aria-hidden="true"
                              className="far fa-suitcase me-2 font-brandBlue"
                            ></i>
                            <span className="font-regular font-primary font-gray1">
                              MCQ
                            </span>
                          </span>
                        </div>
                        <div className="mt-1">
                          <small className="font-regular">
                            {moment(
                              new Date(item.created_date),
                              "YYYYMMDD"
                            ).format("MM/DD/YYYY")}{" "}
                            By {item.RecruiterFname + " " + item.RecruiterLname}
                          </small>
                        </div>
                        <div>
                          <p className="mb-1">
                            <small>Progress</small>
                            <p>
                              <strong className="tag tag-green1 mt-1">
                                Completed
                              </strong>
                            </p>
                          </p>
                          <div className="alert-progress alert-progress-static bg-gray3">
                            <div
                              className="alert-progress-bar"
                              style={{ width: "0%" }}
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
              {props.codingTests
                .filter((item) => item.Status === "Completed")
                .map((item, index) => (
                  <div className="d-flex-force w-100 mb-1">
                    <a className="card card-flat w-100">
                      <div className="card-body">
                        <div>
                          <div>
                            <h6 className="mt-2">{item.reqNumber}</h6>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i
                                aria-hidden="true"
                                className="far fa-suitcase me-2 font-brandBlue"
                              ></i>
                              <span className="font-regular font-primary font-gray1">
                                Coding Test
                              </span>
                            </span>
                          </div>
                          <div className="mt-1">
                            <small className="font-regular">
                              {moment(
                                new Date(item.created_date),
                                "YYYYMMDD"
                              ).format("MM/DD/YYYY")}{" "}
                              By{" "}
                              {item.RecruiterFname + " " + item.RecruiterLname}
                            </small>
                          </div>
                          <div>
                            <p className="mb-1">
                              <small>Progress</small>
                              <p>
                                <strong className="tag tag-green1 mt-1">
                                  Completed
                                </strong>
                              </p>
                            </p>
                            <div className="alert-progress alert-progress-static bg-gray3">
                              <div
                                className="alert-progress-bar"
                                style={{ width: "0%" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
            </div>
          ) : (
            !props.all && (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-comment-slash"></i>
                  </div>
                  <div className="mt-2">
                    You Have Not Completed Any Assessments Yet
                  </div>
                </div>
              </div>
            )
          ))}
      </div>
      <Modal
        size="lg"
        show={openAssessmentDetailsModel === true}
        // dialogClassName="view-panel show"
      >
        <CandateAssessmentFullView
          assessmentDetails={assessmentDetails}
          closePopUP={closeAssessmentModal}
        />
      </Modal>
    </>
  );
}

export default CandidateAssesmentListView;
