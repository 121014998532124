import React from "react";
import EducationDetails from "./EducationDetails";
import IndustriesDetails from "./IndustriesDetails";
import SubCategory from "./SubCategory";

const Index = (props) => {
  const { jobData } = props;

  return (
    <div>
      <EducationDetails jobData={jobData} />
      <IndustriesDetails jobData={jobData} />
      <SubCategory jobData={jobData} />
    </div>
  );
};

export default Index;
