import React, { useState } from "react";
import { getCandidateId } from "../../../service/AuthService"
import { post } from "../../../service/ApiService"
import { toast } from "react-toastify"
import { ButtonLoader } from "../../../constants/Constants";
import moment from "moment";

function Certifications(props) {
  const [ readOnlyMode, setReadOnlyMode ] = useState(true)
  const [ submittingForm, setSubmittingFrom ] = useState(false)
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false)
  const [ saveClicked, setSaveClicked ] = useState(true)
  const [ certificates, setCertificates ] = useState(props.certificates)
  const [ tempCertificates, setTempCertificates ] = useState(props.certificates)
  
  const addCertificates = () => {
    let c = [...certificates]
    c.push({
      certificationName: "",
      certificate: "",
      issueDate: "",
      expiryDate: "",
      issuedby: ""
    })
    setCertificates(c)
  }

  const deleteCertificates = (index) => {
    let c = [...certificates]
    c.splice(index, 1)
    setCertificates(c)
  }

  const saveCertificates = async () => {
    let emptyFieldscondition = true
    let dateCondition = true
    certificates.forEach(c => {
      if(Object.values(c).findIndex((i, j) => j!== 1 && j!== 3 && i === "") !== -1)
        emptyFieldscondition = false
        return false
    })
    certificates.forEach(c => {
      if(new Date(c.issueDate) > new Date(c.expiryDate))
        dateCondition = false
        return false
    })
    if(!emptyFieldscondition) {
      toast.error('Fields marked with *(asterisk) are mandatory')
    }
    else if(!dateCondition) {
      toast.error('Issued Date cannot be greater than Expiry Date')
    }
    else {
      setSaveClicked(false)
      setSubmittingFrom(true)
      try {
        const parameters = {
          candidateID: getCandidateId(),
          clickName: 'certificates',
          userCertificationInfo: certificates
        }
        const res = await post('/candidateProfile/postCertification', parameters)
        if (res.status === 200) {
          setTempCertificates(certificates) 
          setSubmittingFrom(false)
          setShowSuccessMessage(true)      
          setTimeout(() => { 
            setReadOnlyMode(true)
            setShowSuccessMessage(false)
          }, 1000)
        }
        else {
          toast.error("Some Error Occured. Please Try Again")
        }
      } catch (error) {
        toast.error("Error Occured. Please Try Again")
      }
    }
  }

  const editCertification = () => {
    setReadOnlyMode(false)
    setSaveClicked(true)
  }

  const cancelCertification = () => {
    setCertificates(tempCertificates)
    setReadOnlyMode(true)
    setShowSuccessMessage(false)
    setSubmittingFrom(false)
    setSaveClicked(true)
  }

  const handleChange = (name, value, index, value2) => {
    let c = [...certificates]
    if(name === 'issueDate') {
      if(new Date(value) > new Date()) {
        toast.error('Issued Date can not be future date')
      }
      else {
        c[index] = { ...c[index], [name]: value }
      }
    }
    else if(name === 'expiryDate') {
      console.log(new Date(value) < new Date(value2))
      if(new Date(value) < new Date(value2)) {
        toast.error('Expiry Date can not be less than Issued Date')
      }
      else {
        c[index] = { ...c[index], [name]: value }
      }
    }
    else {
      c[index] = { ...c[index], [name]: value }
    }
    // console.log(c)
    setCertificates(c)
  }

  return (
    <>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Certifications</h6>
            {readOnlyMode === true ? (
              <button
                type="button"
                class="btn btn-text"
                onClick={editCertification}
              >
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={cancelCertification}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={saveCertificates}
                  class="btn btn-sm btn-primary"
                >
                  {saveClicked && <span>Save Changes</span>}

                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img
                        width="20px"
                        src={ButtonLoader}
                        alt=""
                      />
                    </span>
                  )}
                  {showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          <hr />
          {readOnlyMode ? (
            <div>
              {
                certificates.map(e => 
                <div class="d-flex mb-2">
                  <div class="mr-3 d-flex flex-column align-items-center">
                    <i class="fad fa-award fa-fw mt-1" aria-hidden="true"></i>
                  </div>
                  <div>
                    <p class="mb-1">
                      <strong>{e.certificationName}</strong>
                    </p>
                    <p>
                      Certificate No: <strong>{e.certificate || 'Not Added'}</strong> <span className="ml-3">Issued By: <strong> {e.issuedby}</strong></span>
                      <div className='d-flex'>
                        <span><small><strong>Issue Date:</strong> {moment(e.issueDate).format("MM/DD/YYYY")}</small></span>
                        <span className='ml-3'><small><strong>Expiry Date:</strong> {moment(e.expiryDate).format("MM/DD/YYYY") || 'Not Added'}</small></span>
                      </div>
                    </p>                  
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div class="certfication">
              {
                certificates.map((cert, index) => 
                <div class="d-flex">
                  <div class="mr-3">
                    <div class="avatar avatar-sm">{index+1}</div>
                  </div>
                  <div class="flex-grow-1">
                    <div class="row mb-2">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            name="certificationName"
                            placeholder="Certification Name"
                            value={cert.certificationName}
                            onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                          />
                          <label>Certification Name*</label>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            name="certificate"
                            placeholder="Certificate ID"
                            value={cert.certificate}
                            onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                          />
                          <label>Certificate ID</label>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-lg-12">
                        <div class="form-floating form-date">
                          <label>Issued By*</label>
                          <input
                            type="text"
                            class="form-control"
                            name="issuedby"
                            placeholder="Issued By"
                            value={cert.issuedby}
                            onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                          />
                        </div>
                      </div>                    
                    </div>
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-floating form-date">
                          <label>Issue Date*</label>
                          <input
                            type="date"
                            class="form-control date"
                            name="issueDate"
                            placeholder="Issue Date"
                            value={cert.issueDate}
                            onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating form-date">
                          <label>Expiry Date</label>
                          <input
                            type="date"
                            class="form-control date"
                            name="expiryDate"
                            placeholder="Expiry Date"
                            value={cert.expiryDate}
                            disabled={!cert.issueDate}
                            onChange={(event) => handleChange(event.target.name, event.target.value, index, cert.issueDate)}
                          />
                        </div>
                      </div>
                    </div>                  
                    <div class="row mt-2">
                      <div class="col-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="ml-3">
                    <button type="button" class="btn btn-icon" onClick={() => deleteCertificates(index)}>
                      <i class="fal fa-fw fa-trash-alt"></i>
                    </button>
                  </div>
                </div>)
              }
              <div className="ml-5">
                <button
                  type="button"
                  class="btn btn-sm btn-text"
                  onClick={addCertificates}
                >
                  <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                  <strong>Add Certification</strong>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Certifications
