import React, { useEffect, useState } from "react";
import { ButtonLoader } from "../../../constants/Constants";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import { getUserEmail, getUserName } from "../../../service/AuthService";
const Notifications = () => {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [saveClicked, setSaveClicked] = useState(true);
  // let [enableSubmit, setEnableSubmit] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const [submittingForm, setSubmittingFrom] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [toggleTemp, setToggleTemp] = useState(true);
  const [loading, setLoading] = useState(false);
  const changeReadOnly = () => {
    setReadOnlyMode(!readOnlyMode);
    setSaveClicked(true);
    setToggleTemp(toggle);
  };
  const email = getUserEmail();

  useEffect(() => {
    setLoading(true);
    try {
      post("/inviteunsubscribed/findSubscribedStatusForUsers", {
        email: getUserName(),
      })
        .then((res) => {
          console.log(res.data[0].subscriptionStatus);
          res.data[0].subscriptionStatus !== "Unsubscribed"
            ? setToggle(true)
            : setToggle(false);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  const savePref = () => {
    setSaveClicked(false);
    setSubmittingFrom(true);
    try {
      let url = "/inviteunsubscribed";
      url = toggleTemp ? `${url}/isSubscribed` : `${url}/isUnsubscribed`;

      setLoading(true);
      console.log(url);
      post(url, { email: email })
        .then((response) => {
          console.log(response);
          setLoading(false);
          setSubmittingFrom(false);
          setShowSuccessMessage(true);
          setToggle(toggleTemp);
          setSaveClicked(true);
          setTimeout(() => {
            setReadOnlyMode(true);
            setShowSuccessMessage(false);
          }, 1000);
          toast.success("Updated successfully!");
          // setSaveClicked(true);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setSubmittingFrom(false);
          setSaveClicked(true);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setSubmittingFrom(false);
      setSaveClicked(true);
    }
  };
  return (
    <div className={"read-only mb-2"}>
      <div className="card card-lg">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="mb-0">Email notifications</h6>
            {readOnlyMode ? (
              <button
                type="button"
                v-if="form.security.readOnly"
                className="btn btn-text"
                onClick={changeReadOnly}
              >
                <i className="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={changeReadOnly}
                  className="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={savePref}
                  className="btn btn-sm btn-primary"
                  // disabled={!enableSubmit}
                >
                  {saveClicked && <span>Save Changes</span>}

                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img width="20px" src={ButtonLoader} alt="" />
                    </span>
                  )}
                </button>
              </div>
            )}
          </div>{" "}
          <hr />
          {readOnlyMode ? (
            <div class="">
              <div class="mr-3 d-flex flex-column align-items-center">
                <div class="view-panel-spacer mt-1"></div>
              </div>
              <div class=" ">
                <div class="d-flex mb-1 ">
                  <div className="col-10">
                    <p>Do you want to recieve emails?</p>
                  </div>{" "}
                  <div className="col-2 text-end">
                    {toggle ? <p>Yes</p> : <p>No</p>}
                  </div>{" "}
                </div>
              </div>
            </div>
          ) : (
            <div class="work-experience">
              <div class="d-flex">
                <div className="w-100">
                  <div class="  d-flex align-items-center">
                    <div className="mr-2 mt-3 col-11">
                      <p>Do you want to recieve emails?</p>
                    </div>
                    <div className="form-check form-switch col-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="isSelect"
                        checked={toggleTemp}
                        onChange={(e) => setToggleTemp(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
