import React, { useState, useEffect } from "react";
// import TopNavBar from "../layout/TopNavbar";
import SideNavBar from "../../../../layout/SideNavBar";
import "react-tabs/style/react-tabs.css";
import ReportTopNavBar from "../../../../report/ReportTopNavBar";
import { getTenantType, getUserRole } from "../../../../../service/AuthService";
import TopNavBar from "../../../../layout/TopNavBar";
import GroupList from "./GroupList";
import Footer from "../../../../layout/Footer";

const Index = () => {
  const [toggle, setToggle] = useState(true);
  const role = getUserRole();

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };

  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <SideNavBar handleToggale={setToggle} />
        {role === "CommunityManager" ? (
          <ReportTopNavBar handleToggale={toggleDiv} />
        ) : (
          <TopNavBar handleToggale={toggleDiv} />
        )}
        <div id="Content">
          <div className="section section-sm">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <GroupList />
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
};

export default Index;
