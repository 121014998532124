import React from "react";

const PreviewContent = ({ isFrom, closeModal, data }) => {
  const handleTogglePreview = () => {
    closeModal();
  };

  return (
    <div className="email-preview-container">
      <div className="email-preview-header">
        {isFrom === "EmailBroadcast" ? (
          <p>
            <strong>To:</strong>{" "}
            {[
              ...(data?.selectedCandidates.length > 0
                ? data?.selectedCandidates
                : []),
            ].join(", ")}
          </p>
        ) : (
          <p>
            <strong>To:</strong> {data?.to}
          </p>
        )}

        <p>
          <strong>Subject:</strong> {data?.subject}
        </p>
      </div>

      <div className="email-preview-body">
        <div
          className="email-content"
          dangerouslySetInnerHTML={{
            __html: data?.content,
          }}
        />
        <div className="d-flex gap-3">
          <div
            dangerouslySetInnerHTML={{
              __html: data?.footer,
            }}
          />
        </div>
      </div>
      <div
        className="email-preview-footer"
        style={{ textAlign: "right", marginTop: "20px" }}
      >
        <button onClick={handleTogglePreview} className="btn btn-secondary">
          Close{" "}
        </button>
      </div>
    </div>
  );
};

export default PreviewContent;
