import React from 'react'
import moment from 'moment'
import RequestSideBar from '../../common/Jobs/ViewJobDetails/RequestSideBar'

function SubmitTalentSidePanel({ closePanel, details, openSubmitModal }) {
    return (       
        <div class="view-panel show">
            <div class="view-panel-header">
                <div class="px-3 py-2">
                    <button type="button" class="btn btn-icon" onClick={closePanel}>
                        <i aria-hidden="true" class="fal fa-times"></i>
                    </button>
                </div> 
                <div class="px-3 mb-3">
                    <div class="d-flex align-items-start flex-wrap-reverse">
                        <div class="d-flex">
                            <div class="ml-3">
                                <h5 class="mr-2 mb-1">{details.fullText.jobTitle}</h5> 
                                <div class="mb-2 d-flex">
                                    <a href='' onClick={(e) => e.preventDefault()}>id: {details.jobId}</a>&nbsp; / &nbsp;
                                    <span class="mr-2">{details.jobType.charAt(0).toUpperCase() + details.jobType.slice(1)} / { details.location.city ?                                                                                    
                                        <span className="font-regular font-primary font-gray1">
                                            {details.location.city}
                                        </span> :                                                                                 
                                        <span className="font-regular font-primary font-gray1">
                                            Remote                                       
                                        </span>}
                                    </span>
                                </div> 
                                <div className='d-flex'>
                                    { 
                                        details.isHotJob &&
                                        <>
                                            <span className="tag tag-coral mr-2 p-1">HOT</span>
                                        </> 
                                    }
                                    <span className="d-flex align-items-center me-3">
                                        { 
                                            moment(new Date(details.expiresOn), "YYYYMMDD").fromNow().match(/\d+/g) < 6 ? 
                                            <span className="tag tag-red1">
                                                {moment(new Date(details.expiresOn), "YYYYMMDD").fromNow().match(/\d+/g)} days left to fill
                                            </span> :
                                            <span className="font-regular">
                                                {moment(new Date(details.expiresOn), "YYYYMMDD").fromNow().match(/\d+/g)} days left to fill
                                            </span> 
                                        }
                                                                   
                                    </span>
                                </div>
                            </div>
                        </div> 
                        <div class="ml-auto d-flex align-items-center">
                            <div>
                                <button type="button" class="btn btn-sm" onClick={() => openSubmitModal(details)}>Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> 
            <div class="view-panel-body">
                <RequestSideBar
                    jobData={details}
                    fullText={details.fullText}
                    overview={true}
                    details={true}  
                    component={'Submit'}                 
                />
            </div>
        </div>
    )
}

export default SubmitTalentSidePanel
