import React, { useEffect, useState } from "react";
import { getUserTenant } from "../../../../service/AuthService";
import { post } from "../../../../service/ApiService";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";

function DisplayResume({ close, candidateData }) {
  const [resumeURL, setResumeURL] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchResume = async () => {
    setLoading(true);
    const payload = {
      candidateID: candidateData.candidateId,
      tenant: candidateData.candidateTenant,
    };
    try {
      let res = await post("/candidateProfile/getResumeTC", payload);
      setResumeURL(res.data);
      console.log(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  const fetchJobResume = async () => {
    setLoading(true);
    const payload = {
      jobId: candidateData.jobId,
      candidateId: candidateData.candidateId,
    };
    try {
      let res = await post("/submitcandidate/getResume", payload);
      setResumeURL(
        res.data.map((item) => ({ ...item, fileURL: item.ImageURL }))
      );
      console.log(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  useEffect(() => {
    if (candidateData.resumeURL[0]) {
      let { fileName, resumeURL } = candidateData.resumeURL[0];
      if (candidateData.sourcedBy === "Applied") {
        let resume = { fileName, fileURL: resumeURL };
        console.log(resume);
        setResumeURL([resume]);
      } else {
        if (resumeURL) {
          let array = resumeURL.split("/");
          console.log(array[4]);
          if (array[4] === "Resume") {
            fetchResume();
          } else {
            fetchJobResume();
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    fetchResume();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="bl-modal-body text-center">
          {resumeURL.length > 0 ? (
            <ul class="list-unstyled mt-2">
              {resumeURL.map((item) => (
                <li class="d-flex align-items-center justify-content-between mb-2">
                  <strong>{item.fileName}</strong>
                  <br />
                  <br />
                  <a
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(item.fileURL, "_blank");
                    }}
                    className="btn btn-sm btn-secondary d-inline-block"
                  >
                    <i className="fal fa-cloud-download-alt mr-2"></i>Download
                  </a>
                </li>
              ))}
            </ul>
          ) : (
            <div className="bl-modal-body text-center">
              <strong>No Resume Found</strong>
            </div>
          )}
        </div>
        <div className="text-center my-4">
          <button
            type="button"
            className="btn btn-secondary mr-3"
            data-dismiss="modal"
            onClick={close}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
}

export default DisplayResume;
