import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { get, post } from "../../../../service/ApiService";
import { domain } from "../../../../constants/Constants";
import { toast } from "react-toastify";
import RecFilters from "./RecFilters";
import CanFilters from "./CanFilters";
import GoogleLocationCountryAPI from "../../../location/GoogleLocationCountryAPI";
import moment from "moment";
import SkillsSearchable from "../../commoncomponents/skills/SkillsSearchable";
import { Col, Form, Row } from "react-bootstrap";

const ERHeader = ({
  loading,
  setLoading,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  filter,
  setFilter,
  fetchData,
  modalFor,
  name,
  setName,
  recEmail,
  setRecEmail,
  selectedRegion,
  setSelectedRegion,
  selectedCategory,
  setSelectedCategory,
  selectedSubCategory,
  setSelectedSubCategory,
  skills,
  setSkills,
  location,
  setLocation,
  experience,
  setExperience,
  dispositionStatus,
  setDispositionStatus,
  setCity,
  setCountry,
  setTableStructureCan,
  selected,
  setSelected,
}) => {
  const searchInput = useRef(null);
  const [primarySkillInput, setPrimarySkillInput] = useState("");
  const [skillSetOption, setSkillSetOption] = useState([]);
  const [dropDownList, setDropDownList] = useState([]);
  const [regions, setRegions] = useState([]);

  const [selectedIndustry, setSelectedIndustry] = useState([]);

  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCategoryOptionsAll, setSubCategoryOptionsAll] = useState([]);

  // const splitMatchedText = (str) => {
  //   let regExp = new RegExp(searchIndustry, "gi");
  //   if (searchIndustry) {
  //   }
  //   return str.replace(regExp, (match) => `<a href=''>${match}</a>`);
  // };
  // const industryHandler = (checked, industry) => {
  //   if (checked) {
  //     setSelectedIndustry([...selectedIndustry, industry]);
  //   } else {
  //     setSelectedIndustry(selectedIndustry.filter((t) => t !== industry));
  //   }
  // };

  const handleChangeInputPrimarySkills = (skill) => {
    setPrimarySkillInput(skill);
  };

  const handleChangePrimarySkills = (s) => {
    setSkills(s);
  };
  const fetchDropData = async () => {
    try {
      setLoading(true);
      const res = await get(`/job/dropdownvalues`, { domainName: domain });
      setDropDownList(res.data[0].skillSet);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const fetchRegionData = async () => {
    try {
      setLoading(true);
      const res = await get(`/common/dropdownlist`);
      let rgns = res.data
        .filter((r) => r.typeName === "regions")
        .map((r) => {
          return {
            value: r.name,
            label: r.name,
          };
        });
      setRegions(rgns);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    let x =
      Object.keys(dropDownList).length > 0
        ? dropDownList.map((item) => {
            const skillSetObj = {
              value: item.PositionName,
              label: item.PositionName,
            };
            return skillSetObj;
          })
        : [];

    setSkillSetOption(x);
  }, [dropDownList]);

  useEffect(() => {
    fetchDropData();
    fetchRegionData();
  }, []);

  const handleChangeIndustry = async (val) => {
    if (val.length > 0) {
      setSelectedCategory(val);
      let industry = dropDownList?.filter((item) => val == item.PositionName);
      industry = industry.map((item) => item.id_JobPositiontype);
      const payload = {
        industry,
      };
      try {
        setLoading(true);
        const res = await post("/common/subcategory", payload);
        setLoading(false);
        if (res.status == 200) {
          let subCategoryData = [
            {
              value: "All",
              label: "All",
            },
          ];
          res.data.map((item) => {
            subCategoryData = [
              ...subCategoryData,
              {
                value: item.categoryName,
                label: item.categoryName,
              },
            ];
          });
          setSubCategoryOptions(subCategoryData);
          setSubCategoryOptionsAll(subCategoryData);
        } else {
          toast.error("Oops! something went wrong. please try again");
        }
      } catch (error) {
        toast.error("Oops! something went wrong. please try again");
        setLoading(false);
      }
    } else {
      setSubCategoryOptions([]);
      setSubCategoryOptionsAll([]);
      setSelectedCategory(val);
      setSelectedSubCategory(val);
    }
  };

  const handleChangeRegion = async (val) => {
    setSelectedRegion(val);
  };

  const handleChangeMultiSelect = (value) => {
    if (value.includes("All")) {
      setSelectedSubCategory(
        subCategoryOptionsAll
          .filter((item) => item.value !== "All")
          .map((i) => i.label)
      );

      setSubCategoryOptions([]);
    } else {
      setSubCategoryOptions(
        subCategoryOptionsAll.filter((item) => !value.includes(item.value))
      );
      setSelectedSubCategory(value);
    }
  };
  const handleChange = (e) => {
    e.target.name === "name"
      ? setName(e.target.value)
      : setRecEmail(e.target.value);
  };

  const setLocations = (address) => {
    setCity(address.city);
    setCountry(address.country);
    setLocation(address.city + (address.city ? ", " : "") + address.country);
  };

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.value = location;
    }
  }, [location]);
  const handleExperienceChange = (e) => {
    setExperience(e.target.value);
  };

  const clearFilters = () => {
    setFromDate("");
    setToDate("");
    setName("");
    setRecEmail("");
    setLocation("");
    setSkills([]);
    setExperience("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchData();
    setSelected(false);
  };
  return (
    <div className="container">
      <div>
        <Row>
          <Col className="col-md-2">
            <label>Created From</label>
            <input
              type="date"
              name="fromDate"
              value={fromDate || ""}
              onChange={(e) => setFromDate(e.target.value)}
              placeholder="MM/DD/YYYY"
              className="form-control"
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
          </Col>
          <Col className="col-md-2">
            <label>Created To</label>
            <input
              type="date"
              name="fromDate"
              value={toDate || ""}
              onChange={(e) => setToDate(e.target.value)}
              placeholder="MM/DD/YYYY"
              className="form-control"
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
          </Col>
          <Col className="col-sm-1 d-flex align-items-centre">
            <label className="mt-4 ml-4">Or</label>
          </Col>
          {(modalFor === "addRecruiter" || modalFor === "addCandidate") && (
            <Col className="col-md-3">
              <label>Name</label>
              <div className="">
                <input
                  value={name}
                  name="name"
                  onChange={handleChange}
                  className="form-control small font-14"
                  type="text"
                />
              </div>
            </Col>
          )}
          <Col className="col-md-1">
            <label className="mt-4 ml-4">Or</label>
          </Col>
          <Col className="col-md-3">
            <label>Email</label>
            <div className="">
              <input
                value={recEmail}
                onChange={handleChange}
                name="email"
                className="form-control small font-14"
                type="email"
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-2">
          {modalFor === "addCandidate" && (
            <>
              <Col className="col-md-2">
                <label>Experience</label>
                {/* <select
                  className="form-control small font-14 mr-2"
                  id="experience"
                  value={experience}
                  onChange={handleExperienceChange}
                >
                  <option value="">Select</option>
                  <option value="Intern">Intern</option>
                  <option value="Less than one year">Less than one year</option>
                  <option value="1-3 years">1-3 years</option>
                  <option value="3-5 years">3-5 years</option>
                  <option value="5-10 years">5-10 years</option>
                  <option value="10+ years">10+ years</option>
                </select> */}
                <select
                  className="font-14 form-select"
                  name="experienceLevel"
                  style={{ width: "120%" }}
                  onChange={handleExperienceChange}
                >
                  <option value="" selected>
                    Select
                  </option>
                  <option value="Intern">Intern</option>
                  <option value="Less than one year">Less than one year</option>
                  <option value="1-3 years">1-3 years</option>
                  <option value="3-5 years">3-5 years</option>
                  <option value="5-10 years">5-10 years</option>
                  <option value="10+ years">10+ years</option>
                </select>
              </Col>
              <Col className="col-md-1">
                <label className="mt-4 ml-4">Or</label>
              </Col>
              <Col className="col-md-3">
                <label>Skills</label>
                <SkillsSearchable
                  onChange={handleChangePrimarySkills}
                  inputArr={skills}
                  skillinput={primarySkillInput}
                  onChangeInput={handleChangeInputPrimarySkills}
                  inputProps={{
                    placeholder: "Enter skills",
                  }}
                />
              </Col>
              <Col className="col-md-1">
                <label className="mt-4 ml-4">Or</label>
              </Col>
              {modalFor === "addCandidate" && (
                <Col className="col-md-3">
                  <div className="py-0">
                    <label>Location</label>
                    <div className="d-flex">
                      <GoogleLocationCountryAPI
                        setLocation={setLocations}
                        searchInput={searchInput}
                        className="form-control"
                      />
                    </div>
                  </div>
                </Col>
              )}
            </>
          )}
        </Row>
        <Row>
          <Col className="col-12 mt-3 d-flex align-items-center justify-content-end">
            <button className="btn btn-secondary mr-2" onClick={clearFilters}>
              Clear
            </button>
            <button className="btn btn-primary mr-3" onClick={handleSubmit}>
              Search
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default ERHeader;
