import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const DeleteClient = (props) => {
    
    return (       
        <Modal show={true} size="md" animation={true}>

            <Modal.Header>
                <button
                    type="button"
                    onClick={props.closeDeleteClient}
                    className="close p-0 bl-modal-close-btn closable"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <i className="fal fa-times closable"></i>
                </button>
            </Modal.Header>
        
            <Modal.Body>
                <div className='m-5'>
                    <div class="text-center">
                        <div class="avatar avatar-lg avatar-red2">
                            <i class="fas fa-exclamation-triangle fa-fw" aria-hidden="true"></i>
                        </div> 
                        <p class="text-center lead mt-3">
                            Are you sure you want to delete this Clients?  
                        </p> 
                        <p>Name: High5 </p>
                    </div>
                </div>
                
            </Modal.Body>

            <Modal.Footer>
                <div class="d-flex align-items-center justify-content-center">
                    <Button variant="secondary" onClick={props.closeDeleteClient}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={props.closeDeleteClient} className='ml-2'>
                        Delete
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteClient
