import {
    GET_RECRUITER_DATA_BEGIN,
    GET_RECRUITER_DATA_SUCCESS,
    GET_RECRUITER_DATA_FAILURE
  } from "../../actions/executiveadmin/RecruiterData";
  
  const initialState = {
    recruiterDataLoading: true,
    recruiterData: [],
  };
  
  const recruiterDataReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_RECRUITER_DATA_BEGIN:
        return {
          ...state,
          recruiterDataLoading: true,
          recruiterData: [],
        };
      case GET_RECRUITER_DATA_SUCCESS:
        return {
          ...state,
          recruiterData: payload,
          recruiterDataLoading: false,
        };
      case GET_RECRUITER_DATA_FAILURE:
        return {
          ...state,
          recruiterData: [],
          recruiterDataLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default recruiterDataReducer;
  