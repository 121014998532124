import moment from "moment";
import React, { useState } from "react";

const LatestActivity = ({ data }) => {
  const [isShowMore, setShowMore] = useState(false);
  const [colorChange, setColorChange] = useState(false);

  const reportHeaders = [
    { id: 0, name: "Date" },
    { id: 0, name: "Time" },
    { id: 1, name: "Action" },
    // { id: 2, name: "Browser" },
    // { id: 4, name: "IP Address" },
    // { id: 5, name: "Email" },
    // { id: 6, name: "Status" },
    // { id: 8, name: "Job Title" },
    // { id: 9, name: "Job Client" },
    // { id: 10, name: "Gig Recruiter" },
    // { id: 11, name: "Recruiter Address" },
  ];
  console.log("data", data);
  return (
    <div>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Activities</h6>{" "}
            {/* <button type="button" class="btn btn-text">
                              <i
                                aria-hidden="true"
                                class="fas fa-pencil-alt mr-1"
                              ></i>
                              Edit
                            </button> */}
          </div>{" "}
          <hr />{" "}
          <div>
            {/* {data?.talentActivity.map((elem,idx) => (
              <div class="d-flex">
                <div class="mr-3 d-flex flex-column align-items-center">
                  {idx + 1}.
                </div>{" "}
                <div>
                  <p class="mb-1">{elem}</p>{" "}
               
                </div>
              </div>
            ))} */}
            {/*  */}
            <div>
              <div
                className="px-3 pt-0 table-cards-1024 mt-3"
                // style={{ overflowX: "scroll" }}
              >
                <table
                  className="table table-hoverabletable-cards-1024"
                  id="table-to-xls"
                  style={{
                    whiteSpace: "nowrap",
                  }}
                >
                  <thead>
                    <tr>
                      {reportHeaders.map((header) => (
                        <th>{header.name}</th>
                      ))}
                    </tr>
                  </thead>
                  {(isShowMore
                    ? data?.talentActivity
                    : data?.talentActivity.slice(0, 3)
                  ).map((activity) => {
                    return (
                      <tbody>
                        <tr>
                          <td>
                            {moment(activity.createdDateTime).format(
                              "MM/DD/YY"
                            )}
                          </td>
                          <td>
                            {moment(activity.createdDateTime).format("hh:mm a")}
                          </td>
                          <td>{activity.activity}</td>
                          {/* <td>{activity.browser}</td>
                          <td>{activity.ipAddress}</td> */}
                        </tr>
                      </tbody>
                    );
                  })}
                </table>
              </div>
              <div className="row">
                <div className="text-end" style={{ marginTop: "5px" }}>
                  <strong
                    className="underline-hover"
                    style={{
                      cursor: "pointer",
                      marginRight: "30px",
                      color: colorChange ? "blue" : "gray",
                    }}
                    onClick={() => setShowMore(!isShowMore)}
                    onMouseEnter={() => setColorChange(true)}
                    onMouseLeave={() => setColorChange(false)}
                  >
                    {isShowMore ? "Show less" : "Show more"}
                  </strong>
                </div>
              </div>

              <div className="mt-3" style={{ visibility: "hidden" }}>
                <div class="text-center p-5">
                  <div className="avatar avatar-lg">
                    <i aria-hidden="true"></i>
                  </div>
                  <div class="mt-2"></div>
                </div>
              </div>
              {/* )} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LatestActivity;
