import React, { useEffect, useState } from "react";

const TalentFilter = (props) => {
  let dataLimit = 4;
  let pageLimit = 5;
  const [candidateTitle, setCandidateTitle] = useState(false);
  const [candidateLocationEnabled, setCandidateLocationEnabled] = useState(
    false
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [candidateTitles, setCandidateTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [candidateLocations, setCandidateLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [vetting, setVetting] = useState(false);
  const [vettingEnabled, setVettingEnabled] = useState(false);
  const [skillsEnabled, setSkillsEnabled] = useState(false);
  const [skillSetEnabled, setSkillSetEnabled] = useState(false);
  const [createdByEnabled, setCreatedByEnabled] = useState(false);
  const [candidateSkills, setCandidateSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [candidateSkillSet, setCandidateSkillSet] = useState([]);
  const [selectedSkillSet, setSelectedSkillSet] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [lastUpdatedEnabled, setLastUpdatedEnabled] = useState(false);
  const [lastUpdatedType, setlastUpdatedType] = useState("");
  const [lastUpdatedDate, setLastUpdatedDate] = useState(["", ""]);
  const [searchLocationValue, setSearchLocationValue] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [searchSkillValue, setSearchSkillValue] = useState("");
  const [searchSkill, setSearchSkill] = useState([]);

  useEffect(() => {
    setVetting(props.vetting);
    setCandidateTitles(props.candidateTitles);
    setCandidateLocations(props.candidateLocations);
    setSelectedTitles(props.selectedTitles);
    setSelectedLocations(props.selectedLocations);
    setLastUpdatedDate(props.lastUpdatedDate);
    setlastUpdatedType(props.lastUpdatedType);
    setCandidateSkills(props.candidateSkills);
    setSelectedSkills(props.selectedSkills);
    setCandidateSkillSet(props.candidateSkillSet);
    setSelectedSkillSet(props.selectedSkillSet);
    setCreatedBy(props.createdBy);
    setSelectedCreatedBy(props.selectedCreatedBy);
  }, []);

  const titleHandler = (checked, title) => {
    if (checked) {
      setSelectedTitles([...selectedTitles, title]);
    } else {
      setSelectedTitles(selectedTitles.filter((t) => t !== title));
    }
  };

  const locatioHandler = (checked, location) => {
    if (checked) {
      setSelectedLocations([...selectedLocations, location]);
    } else {
      setSelectedLocations(selectedLocations.filter((t) => t !== location));
    }
  };

  const skillHandler = (checked, skill) => {
    if (checked) {
      setSelectedSkills([...selectedSkills, skill]);
    } else {
      setSelectedSkills(selectedSkills.filter((t) => t !== skill));
    }
  };

  const skillSetHandler = (checked, skill) => {
    if (checked) {
      setSelectedSkillSet([...selectedSkillSet, skill]);
    } else {
      setSelectedSkillSet(selectedSkillSet.filter((t) => t !== skill));
    }
  };

  const createdByHandler = (checked, createdBy) => {
    if (checked) {
      setSelectedCreatedBy([...selectedCreatedBy, createdBy]);
    } else {
      setSelectedCreatedBy(selectedCreatedBy.filter((c) => c !== createdBy));
    }
  };

  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : candidateTitles.slice(startIndex, endIndex);
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(candidateTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(candidateTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    setCurrentPage(1);
  }, [candidateTitles, searchData]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  const splitMatchedText = (str) => {
    let regExp;
    if (searchValue) {
      regExp = new RegExp(searchValue, "gi");
    } else if (searchLocationValue) {
      regExp = new RegExp(searchLocationValue, "gi");
    } else {
      regExp = new RegExp(searchSkillValue, "gi");
    }
    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchData(
      candidateTitles.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearchLocation = (e) => {
    setSearchLocationValue(e.target.value);
    setSearchLocation(
      candidateLocations.filter((item) =>
        item.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearchSkill = (e) => {
    setSearchSkillValue(e.target.value);
    setSearchSkill(
      candidateSkills.filter((item) =>
        item.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const titleArray = (title) => {
    return selectedTitles.length > 0 ? selectedTitles.includes(title) : true;
  };

  const locationArray = (location) => {
    return selectedLocations.length > 0
      ? selectedLocations.includes(location)
      : true;
  };

  const vettedArray = (vetted) => {
    return vetting ? vetted : true;
  };

  const lastUpdatedArray = (date) => {
    if (lastUpdatedType === "after") {
      return lastUpdatedDate[0]
        ? new Date(date) > new Date(lastUpdatedDate[0])
        : true;
    } else if (lastUpdatedType === "before") {
      return lastUpdatedDate[0]
        ? new Date(date) < new Date(lastUpdatedDate[0])
        : true;
    } else {
      return lastUpdatedDate[0] && lastUpdatedDate[1]
        ? new Date(lastUpdatedDate[0]) <
            new Date(date) <
            new Date(lastUpdatedDate[1])
        : true;
    }
  };

  const skillArray = (skills) => {
    if (selectedSkills.length > 0) {
      return skills
        .map((skill) => selectedSkills.includes(skill))
        .some((skill) => skill);
    } else {
      return true;
    }
  };

  const createdByArray = (createdBy) => {
    return selectedCreatedBy.length > 0
      ? selectedCreatedBy.includes(createdBy)
      : true;
  };

  const skillSetArray = (industry) => {
    if (selectedSkillSet.length > 0) {
      return industry
        .map((skill) => selectedSkillSet.includes(skill))
        .some((skill) => skill);
    } else {
      return true;
    }
  };

  const clearAll = () => {
    setSelectedLocations([]);
    setSelectedTitles([]);
    setSelectedSkills([]);
    setSelectedSkillSet([]);
    setSelectedCreatedBy([]);
    setVetting(false);
    setLastUpdatedDate(["", ""]);
    setlastUpdatedType("");
    setSelectedCreatedBy([]);
    setSearchValue("");
    setSearchLocationValue("");
    setSearchSkillValue("");
  };

  const applyFilter = () => {
    let filteredData = props.candidateData.filter((item) => {
      return (
        titleArray(item.designation) &&
        locationArray(`${item.city}, ${item.state}`) &&
        vettedArray(item.isVetted) &&
        lastUpdatedArray(item.updatedDate) &&
        skillArray(item.primarySkills) &&
        createdByArray(item.createdByName) &&
        skillSetArray(item.skillSet)
      );
    });
    props.applyFilter(
      filteredData,
      selectedTitles,
      selectedLocations,
      vetting,
      lastUpdatedDate,
      lastUpdatedType,
      selectedCreatedBy,
      selectedSkills,
      selectedSkillSet
    );
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={applyFilter}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>
                Filter /{" "}
                {selectedTitles.length +
                  selectedLocations.length +
                  (vetting ? 1 : 0) +
                  (lastUpdatedDate[0] ? 1 : 0) +
                  selectedCreatedBy.length +
                  selectedSkills.length +
                  selectedSkillSet.length}
              </h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear All
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          {/* <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setVettingEnabled(!vettingEnabled)}
            >
              <i
                className={
                  vettingEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Vetted?</small>
                {vetting && <span className="jewel"></span>}
              </span>
            </button>
            {vettingEnabled && (
              <div className="mb-4 ml-4 pl-1 pt-1">
                <div className="d-flex align-items-center m-1">
                  <div>Show Only Vetted Candidates</div>
                  <div className="ml-auto">
                    <div className="form-check form-switch">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={vetting}
                        onChange={(e) => setVetting(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-2 px-1">
                  <button
                    className="btn btn-text"
                    onClick={() => setVetting(false)}
                  >
                    <small>Clear Vetted?</small>
                  </button>
                </div>
                <hr className="p-0" />
              </div>
            )}
          </div> */}
          {false && (
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => setCandidateTitle(!candidateTitle)}
              >
                <i
                  className={
                    candidateTitle
                      ? "fal fa-angle-down fa-fw"
                      : "fal fa-angle-up fa-fw"
                  }
                />
                <span className="p-relative">
                  <small>Job Title</small>
                  {selectedTitles.length > 0 && <span className="jewel"></span>}
                </span>
              </button>
              {candidateTitle &&
                (candidateTitles.length > 0 ? (
                  <div className="mb-4 ml-4 pl-1">
                    <input
                      type="text"
                      placeholder="Type to filter list"
                      className="form-control form-control-filter mt-2"
                      onChange={handleSearch}
                      value={searchValue}
                    />
                    {getPaginatedData().map((j) => (
                      <div className="d-flex align-items-center m-1">
                        <div>
                          {searchValue ? (
                            <span
                              dangerouslySetInnerHTML={{
                                __html: splitMatchedText(j),
                              }}
                            ></span>
                          ) : (
                            j
                          )}
                        </div>
                        <div className="ml-auto">
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              checked={selectedTitles.includes(j)}
                              className="form-check-input"
                              onChange={(e) =>
                                titleHandler(e.target.checked, j)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                    {getPaginatedData().length === 0 && (
                      <div>
                        <div class="text-center p-3">
                          <div class="avatar avatar-lg">
                            <i class="fad fa-users-slash"></i>
                          </div>
                          <div class="mt-2">
                            No records found for `'${searchValue}'`
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="d-flex p-2 mt-2">
                      <div className="w-100-370 mb-point5-370">
                        Showing{" "}
                        {currentPage === paginationArray.slice(-1)[0]
                          ? getPaginatedData().length
                          : searchValue
                          ? searchData.length < dataLimit
                            ? searchData.length
                            : dataLimit
                          : dataLimit}{" "}
                        of {candidateTitles.length}
                      </div>
                      <div class="ml-auto mx-auto-370 d-flex align-items-center">
                        <button
                          onClick={() => {
                            setPageType("down");
                            setCurrentPage((page) => page - 1);
                          }}
                          type="button"
                          disabled={
                            currentPage === 1 || getPaginatedData().length === 0
                              ? true
                              : false
                          }
                          className="btn btn-icon px-2"
                        >
                          <i className="fal fa-chevron-circle-left"></i>
                        </button>

                        {paginationGroup.map((item) => (
                          <button
                            type="button"
                            className={
                              currentPage === item
                                ? "btn btn-text px-2 bg-dark text-white"
                                : "btn btn-text px-2"
                            }
                            onClick={changePage}
                          >
                            {item}
                          </button>
                        ))}

                        <button
                          onClick={() => {
                            setPageType("up");
                            setCurrentPage((page) => page + 1);
                          }}
                          type="button"
                          disabled={
                            currentPage === paginationArray.slice(-1)[0] ||
                            getPaginatedData().length === 0
                              ? true
                              : false
                          }
                          className="btn btn-icon px-2"
                        >
                          <i className="fal fa-chevron-circle-right"></i>
                        </button>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end mt-2 px-1">
                      <button
                        className="btn btn-text"
                        onClick={() => {
                          setSearchValue("");
                          setSelectedTitles([]);
                        }}
                      >
                        <small>Clear Job Title</small>
                      </button>
                    </div>
                    <hr className="p-0" />
                  </div>
                ) : (
                  <div>
                    <div class="text-center p-3">
                      <div class="avatar avatar-lg">
                        <i class="fad fa-users-slash"></i>
                      </div>
                      <div class="mt-2">No records found</div>
                    </div>
                  </div>
                ))}
            </div>
          )}
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() =>
                setCandidateLocationEnabled(!candidateLocationEnabled)
              }
            >
              <i
                className={
                  candidateLocationEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Location</small>
                {selectedLocations.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {candidateLocationEnabled &&
              (candidateLocations.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearchLocation}
                    value={searchLocationValue}
                  />
                  {searchLocationValue
                    ? searchLocation.map((i) => (
                        <div className="d-flex align-items-center m-1">
                          <div>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: splitMatchedText(i),
                              }}
                            ></span>
                          </div>
                          <div className="ml-auto">
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedLocations.includes(i)}
                                onChange={(e) =>
                                  locatioHandler(e.target.checked, i)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    : candidateLocations.map((i) => (
                        <div className="d-flex align-items-center m-1">
                          <div>
                            <span>{i}</span>
                          </div>
                          <div className="ml-auto">
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedLocations.includes(i)}
                                onChange={(e) =>
                                  locatioHandler(e.target.checked, i)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchLocationValue("");
                        setSelectedLocations([]);
                      }}
                    >
                      <small>Clear Location</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              ))}
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCreatedByEnabled(!createdByEnabled)}
            >
              <i
                className={
                  createdByEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Created by</small>
                {selectedCreatedBy.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {createdByEnabled &&
              (createdBy.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  {createdBy.map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        <span>{j}</span>
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedCreatedBy.includes(j)}
                            className="form-check-input"
                            onChange={(e) =>
                              createdByHandler(e.target.checked, j)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedCreatedBy([])}
                    >
                      <small>Clear Created by</small>
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              ))}
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setLastUpdatedEnabled(!lastUpdatedEnabled)}
            >
              <i
                className={
                  lastUpdatedEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Last updated</small>
                {lastUpdatedDate[0] && <span className="jewel"></span>}
              </span>
            </button>
            {lastUpdatedEnabled && (
              <div>
                <div className="d-flex mt-2">
                  <select
                    onChange={(e) => {
                      setlastUpdatedType(e.target.value);
                    }}
                    className="form-select w-50 m-3"
                  >
                    <option disabled selected={!lastUpdatedType}>
                      Select Range
                    </option>
                    <option
                      value="after"
                      selected={lastUpdatedType === "after"}
                    >
                      After
                    </option>
                    <option
                      value="before"
                      selected={lastUpdatedType === "before"}
                    >
                      Before
                    </option>
                    <option
                      value="between"
                      selected={lastUpdatedType === "between"}
                    >
                      Between
                    </option>
                  </select>
                  {lastUpdatedType !== "" ? (
                    lastUpdatedType === "after" ||
                    lastUpdatedType === "before" ? (
                      <input
                        type="date"
                        onChange={(e) => {
                          let a = [...lastUpdatedDate];
                          a[0] = e.target.value;
                          setLastUpdatedDate(a);
                        }}
                        style={{ maxHeight: "41px", margin: "14px 0px" }}
                        className="form-control date"
                        value={lastUpdatedDate[0]}
                        required
                      />
                    ) : (
                      <>
                        <input
                          type="date"
                          style={{ maxHeight: "41px", margin: "14px 0px" }}
                          className="form-control date mr-2"
                          onChange={(e) => {
                            let a = [...lastUpdatedDate];
                            a[0] = e.target.value;
                            setLastUpdatedDate(a);
                          }}
                          value={lastUpdatedDate[0]}
                        />
                        <input
                          type="date"
                          style={{ maxHeight: "41px", margin: "14px 0px" }}
                          className="form-control date"
                          onChange={(e) => {
                            let a = [...lastUpdatedDate];
                            a[1] = e.target.value;
                            setLastUpdatedDate(a);
                          }}
                          value={lastUpdatedDate[1]}
                        />
                      </>
                    )
                  ) : null}
                </div>
                <div className="d-flex justify-content-end py-2 px-1">
                  <button
                    className="btn btn-text"
                    onClick={() => {
                      setlastUpdatedType("");
                      setLastUpdatedDate(["", ""]);
                    }}
                  >
                    <span className="font-12">Clear Last updated</span>
                  </button>
                </div>
                <hr className="p-0" />
              </div>
            )}
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setSkillsEnabled(!skillsEnabled)}
            >
              <i
                className={
                  skillsEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Skills</small>
                {selectedSkills.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {skillsEnabled &&
              (candidateSkills.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearchSkill}
                    value={searchSkillValue}
                  />
                  {searchSkillValue
                    ? searchSkill.map((i) => (
                        <div className="d-flex align-items-center m-1">
                          <div>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: splitMatchedText(i),
                              }}
                            ></span>
                          </div>
                          <div className="ml-auto">
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedSkills.includes(i)}
                                onChange={(e) =>
                                  skillHandler(e.target.checked, i)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ))
                    : candidateSkills.map((i) => (
                        <div className="d-flex align-items-center m-1">
                          <div>
                            <span>{i}</span>
                          </div>
                          <div className="ml-auto">
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedSkills.includes(i)}
                                onChange={(e) =>
                                  skillHandler(e.target.checked, i)
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchSkillValue("");
                        setSelectedSkills([]);
                      }}
                    >
                      <small>Clear Skills</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              ))}
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setSkillSetEnabled(!skillSetEnabled)}
            >
              <i
                className={
                  skillSetEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Industry</small>
                {selectedSkillSet.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {skillSetEnabled &&
              (candidateSkillSet.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  {candidateSkillSet.map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        <span>{j}</span>
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedSkillSet.includes(j)}
                            className="form-check-input"
                            onChange={(e) =>
                              skillSetHandler(e.target.checked, j)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedSkillSet([])}
                    >
                      <small>Clear Industry</small>
                    </button>
                  </div>
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default TalentFilter;
