import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// import 'react-circular-progressbar/dist/styles.css'

const AutoMatchScoreCard = ({ jobData, candidateData, parent }) => {
  let column1 = "col-lg-3 text-left";
  let column1Skills = "col-lg-6 text-left";
  let column2Skills = "col-lg-6 text-left";
  let column2 = "col-lg-4 text-left";
  let column3 = "d-none col-lg-2 text-center";
  let column3General = "col-lg-4 text-left";
  const [automatch, setAutomatch] = useState(false);

  const [general, setGeneral] = useState(false);

  const [matched, setMatched] = useState({
    primary: 0,
    secondary: 0,
    industry: 0,
    location: 0,
  });
  const [average, setAverage] = useState([
    { name: "Overall", score: 0 },
    { name: "Mandatory Skills", score: 0 },
    // { name: "Good To Have Skills", score: 0 },
    { name: "Location", score: 0 },
    // { name: "Industry", score: 0 },
    { name: "Education", score: 0 },
    { name: "Experience", score: 0 },
    { name: "Job Title", score: 0 },
  ]);

  const calculateScore = (jobData, candidateData) => {
    let primary = 0;
    // let secondary = 0;
    // let skillSet = 0;
    let location = 0;
    let education = 0;
    let jobTitle = 0;
    let experience = 0;
    let sum = 0;
    let count =
      jobData.primarySkills.length +
      // jobData.secondarySkills.length +
      // jobData.skillSet.length +
      jobData.education.length +
      3;
    jobData.primarySkills.forEach((skill) => {
      if (candidateData?.fullText.candidatePrimarySkills.includes(skill)) {
        sum++;
        primary++;
      }
    });
    // jobData.secondarySkills.forEach((skill) => {
    //   if (candidateData.secondarySkills.includes(skill)) {
    //     sum++;
    //     secondary++;
    //   }
    // });
    // jobData.industries.forEach((skill) => {
    //   if (candidateData.skillSet.includes(skill)) {
    //     sum++;
    //     skillSet++;
    //   }
    // });
    if (parent == "submitted" && candidateData.highestEducation !== null) {
      if (
        candidateData?.highestEducation?.toLowerCase() ==
        jobData.education[0].toLowerCase()
      ) {
        sum++;
        education++;
      }
    } else {
      if (
        candidateData.education
          .map((item) => item.educationType && item.educationType.toLowerCase())
          .includes(jobData.education[0].toLowerCase())
      ) {
        sum++;
        education++;
      }
    }
    if (parent == "submitted") {
      sum++;
      location++;
    } else {
      if (
        [
          candidateData.addressCity,
          ...candidateData.preferedLocations,
        ].includes(jobData.location.city)
      ) {
        sum++;
        location++;
      }
      if (
        jobData.isRemote &&
        candidateData.preferedLocations.includes("Remote")
      ) {
        sum++;
        location++;
      }
    }
    if (candidateData.currentJobTitle === jobData.jobTitle) {
      sum++;
      jobTitle++;
    }
    if (candidateData.experienceLevel === jobData.experienceLevel) {
      sum++;
      experience++;
    } else if (
      candidateData?.experienceYear >= jobData?.experienceLevel?.charAt(0)
    ) {
      sum++;
      experience++;
    }
    let primaryAverage =
      jobData.primarySkills.length > 0
        ? Math.round((primary * 100) / jobData.primarySkills.length)
        : 0;
    // let secondaryAverage =
    //   jobData.secondarySkills.length > 0
    //     ? Math.round((secondary * 100) / jobData.secondarySkills.length)
    //     : 0;
    // let industryAverage =
    //   jobData.skillSet.length > 0
    //     ? Math.round((skillSet * 100) / jobData.skillSet.length)
    //     : 0;
    let locationAverage = location ? 100 : 0;
    let jobTitleAverage = jobTitle ? 100 : 0;
    let experienceAvg = experience ? 100 : 0;
    let educationAvg = education ? 100 : 0;
    let total = 0;
    if (parent == "submitted") {
      total = Math.round(
        (primaryAverage +
          locationAverage +
          jobTitleAverage +
          experienceAvg +
          educationAvg) /
          5
      );
    } else {
      total = Math.round((sum * 100) / count);
    }

    setMatched({
      primary,
      // secondary,
      location,
      // industry: skillSet,
      education,
      experience,
      jobTitle,
    });
    setAverage([
      { name: "Overall", score: total },
      { name: "Mandatory", score: primaryAverage },
      // { name: "Good To Have", score: secondaryAverage },
      { name: "Location", score: locationAverage },
      // { name: "Industry", score: industryAverage },
      { name: "Education", score: educationAvg },
      { name: "Experience", score: experienceAvg },
      { name: "Job Title", score: jobTitleAverage },
    ]);
  };

  useEffect(() => {
    calculateScore(jobData, candidateData);
  }, [candidateData]);

  const circularProgress = (value) => {
    let a = (
      <div className="col-lg-3">
        <div className="mt-4 align-items-center justify-content-center">
          {/* <p className='ml-1 mb-4'>Average:</p>                                              
                <div style={{ width: '5rem' }}>
                    <CircularProgressbar value={value} text={`${value}%`} />
                </div>                       */}
        </div>
      </div>
    );
    return a;
  };

  const hr = () => {
    let line = (
      <div className="row">
        <div className="col-lg-10">
          <hr className="m-1" />
        </div>
      </div>
    );
    return line;
  };

  return (
    <div>
      <div className="mb-2">
        <button
          type="button"
          className="btn btn-collapsible"
          onClick={() => setAutomatch(!automatch)}
        >
          <i
            className={
              automatch ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"
            }
          ></i>
          <span>
            <small>Automatching Parameters</small>
          </span>
        </button>
      </div>

      <div className={automatch ? "ml-4 d-none mb-4" : "ml-4"}>
        <div className="m-3">
          <table>
            <tr>
              {average.map((item) => (
                <td className="p-3a vertical-align: top">
                  <div className="d-flex flex-column align-items-center justify-content-end">
                    {item.name !== "Overall" ? (
                      <div style={{ width: "3rem", marginLeft: -20 }}>
                        <CircularProgressbar
                          strokeWidth={3}
                          value={item.score}
                          text={`${item.score}%`}
                          styles={buildStyles({
                            textColor: item.score >= 50 ? "green" : "black",
                            alignItems: "top",
                            pathColor: item.score >= 50 ? "green" : "orange",
                          })}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "4rem", marginLeft: -20 }}>
                        <CircularProgressbar
                          value={item.score}
                          text={`${item.score}%`}
                          background
                          backgroundPadding={6}
                          styles={buildStyles({
                            backgroundColor:
                              item.score >= 50 ? "green" : "orange",
                            textColor: "#fff",
                            pathColor: "#fff",
                            trailColor: "transparent",
                          })}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        whiteSpace: "wrap",
                        marginLeft: -5,
                      }}
                    >
                      <small
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          marginLeft: -5,
                          fontSize: 9,
                        }}
                      >
                        {item.name}
                      </small>
                    </div>
                  </div>
                </td>
              ))}
            </tr>
          </table>
        </div>

        <div className="row mt-3">
          <div className="col-lg-9">
            <p className="mt-4 mb-3">
              <strong>Mandatory Skills: </strong>
              <span className="ml-2 tag tag-blue3">
                Matched {matched.primary} of {jobData.primarySkills.length}
              </span>{" "}
            </p>
            <div className="row mb-1 mt-3">
              <div className={column1Skills}>Skills Mentioned in Job</div>
              <div className={column2}>Candidate Skills</div>
              <div className={column3}>Result</div>
            </div>
            {hr()}
            {jobData.primarySkills.map((skill) => (
              <div className="row mb-1">
                <div className={column1Skills}>
                  <small>{skill}</small>
                </div>
                <div className={column2Skills}>
                  <small className="ml-1">
                    {candidateData?.fullText.candidatePrimarySkills.includes(skill) ? (
                      skill
                    ) : (
                      <small class="tag tag-red2">No</small>
                    )}
                  </small>
                </div>
                <div className={column3}>
                  <small>
                    {candidateData?.fullText.candidatePrimarySkills.includes(skill)
                      ? "100%"
                      : "0%"}
                  </small>
                </div>
              </div>
            ))}
            {/* {candidateData?.fullText.candidatePrimarySkills
              .filter((i) => !jobData.primarySkills.includes(i))
              .map((skill) => (
                <div className="row mb-1">
                  <div className={column1Skills}>
                    <small>
                      <span class="tag tag-red2">No</span>
                    </small>
                  </div>
                  <div className={column2Skills}>
                    <small className="ml-1">{skill}</small>
                  </div>
                  <div className={column3}>
                    <small>{"0%"}</small>
                  </div>
                </div>
              ))} */}
          </div>
        </div>

        {/* <div className="row mt-3">
          <div className="col-lg-9">
            <p className="mt-4 mb-2">
              <strong>Good to have Skills:</strong>
              <span className="ml-2 tag tag-blue3">
                Matched {matched.secondary} of {jobData.secondarySkills.length}
              </span>
            </p>
            <div className="row mb-1 font-14">
              <div className={column1Skills}>Skills Mentioned in Job</div>
              <div className={column2}>Candidate Skills</div>
              <div className={column3}>Result</div>
            </div>
            {hr()}
            
          </div>
          {circularProgress(average.secondary)}
        </div> */}

        {/* <div className="row mt-3">
          <div className="col-lg-9">
            <p className="mt-4 mb-2">
              <strong>Industry: </strong>
              <span className="ml-2 tag tag-blue3">
                Matched {matched.industry} of {jobData.skillSet.length}
              </span>
            </p>
            <div className="row mb-1 font-14">
              <div className={column1Skills}>Job</div>
              <div className={column2Skills}>Candidate</div>
              <div className={column3}>Result</div>
            </div>
            {hr()}
            {jobData.skillSet.map((skill) => (
              <div className="row mb-1">
                <div className={column1Skills}>
                  <small>{skill}</small>
                </div>
                <div className={column2Skills}>
                  <small className="ml-1">
                    {candidateData.skillSet.includes(skill) ? (
                      skill
                    ) : (
                      <small class="tag tag-red2">No</small>
                    )}
                  </small>
                </div>
                <div className={column3}>
                  <small>
                    {candidateData.skillSet.includes(skill) ? "100%" : "0%"}
                  </small>
                </div>
              </div>
            ))}
            {candidateData.skillSet
              .filter((i) => !jobData.skillSet.includes(i))
              .map((skill) => (
                <div className="row mb-1">
                  <div className={column1Skills}>
                    <small>{<small class="tag tag-red2">No</small>}</small>
                  </div>
                  <div className={column2Skills}>
                    <small className="ml-1">{skill}</small>
                  </div>
                  <div className={column3}>
                    <small>{"0%"}</small>
                  </div>
                </div>
              ))}
            {jobData.skillSet.length === 0 &&
              candidateData.skillSet.length === 0 && (
                <div className="row mb-1">
                  <div className={column1Skills}>
                    <small>{"No Data Found"}</small>
                  </div>
                  <div className="col-lg-4">
                    <small className="ml-1">{"No Data Found"}</small>
                  </div>
                  <div className={column3}>
                    <small>{<small class="tag tag-red2">No</small>}</small>
                  </div>
                </div>
              )}
          </div>
        </div> */}

        <div className="row mt-3 mb-4">
          <div className="col-lg-9">
            <p className="mt-4 mb-2">
              <strong>Location: </strong>
              <span className="ml-2 tag tag-blue3">
                Matched {matched.location} of 1
              </span>
            </p>
            <div className="row mb-1 font-14">
              <div className={column1Skills}>Job</div>
              <div className={column2Skills}>Candidate</div>
              <div className={column3}>Result</div>
            </div>
            {hr()}

            {parent == "submitted" && (
              <div className="row mb-1">
                <div className={column1Skills}>
                  <small>{jobData.workPlaceType}</small>
                </div>
                <div className={column2Skills}>
                  <small>{jobData.workPlaceType}</small>
                </div>
                <div className={column3}>
                  <small>{false ? "100%" : "0%"}</small>
                </div>
              </div>
            )}

            {parent !== "submitted" && (
              <div className="row mb-1">
                <div className={column1Skills}>
                  <small>
                    {jobData.isRemote ? "Remote" : jobData.location.city}
                  </small>
                </div>
                <div className={column2Skills}>
                  <small>
                    {jobData.isRemote ? (
                      candidateData.preferedLocations.includes("Remote") ? (
                        "Remote"
                      ) : (
                        <small class="tag tag-red2">No</small>
                      )
                    ) : [
                        ...candidateData.preferedLocations,
                        candidateData.addressCity,
                      ].includes(jobData.location.city) ? (
                      jobData.location.city
                    ) : (
                      <small class="tag tag-red2">No</small>
                    )}
                  </small>
                </div>
                <div className={column3}>
                  <small>{false ? "100%" : "0%"}</small>
                </div>
              </div>
            )}

            {/* {[...candidateData.preferedLocations, candidateData.addressCity].length > 0 &&
                    [...new Set([...candidateData.preferedLocations, candidateData.addressCity])].filter(i => !jobData.location.city.includes(i) && i  !== 'Remote').map(skill => 
                        <div className='row mb-1'>
                            <div className={column1Skills}>
                                <small>{<small class="tag tag-red2">No</small>}</small>
                            </div>
                            <div className={column2Skills}>
                                <small>{skill}</small>
                            </div>
                            <div className={column3}>
                                <small>{"0%"}</small>
                            </div>
                        </div>
                    )}  */}
          </div>
        </div>

        {/* //<div className={general ? "ml-4 d-none mb-4" : "ml-4"}>  */}
        <p className="mt-4 mb-2 ">
          <strong>General: </strong>
        </p>
        <div className="row mt-3">
          <div className={column1}>Parameter</div>
          <div className={column2}>Job</div>
          <div className={column3General}>Candidate</div>
        </div>
        {hr()}
        <div className="row mb-1 font-14">
          <div className={column1}>
            <small>Job Title</small>
          </div>
          <div className={column2}>
            <small>{jobData.jobTitle}</small>
          </div>
          <div className={column3General}>
            <small>
              {candidateData.currentJobTitle?.toLowerCase() ===
              jobData.jobTitle.toLowerCase() ? (
                jobData.jobTitle
              ) : (
                <small class="tag tag-red2">No</small>
              )}
            </small>
          </div>
        </div>
        {jobData.education.length > 0 && (
          <div className="row mb-1 font-14 ">
            <div className={column1}>
              <small>Education</small>
            </div>
            <div className={column2}>
              <small>{jobData.education[0]}</small>
            </div>
            <div className={column3General}>
              <small>
                {matched.education ? (
                  jobData.education[0]
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )}
        {jobData.certifications.length > 0 && (
          <div className="row mb-1 font-14 ">
            <div className={column1}>
              <small>Certification</small>
            </div>
            <div className={column2}>
              <small>{jobData.certifications[0]}</small>
            </div>
            <div className={column3General}>
              <small>
                {candidateData.certificates.filter((i) => i).length > 0 ? (
                  candidateData.certificates
                    .map((i) => i.certificationName)
                    .includes(jobData.certifications[0]) ? (
                    jobData.certifications[0]
                  ) : (
                    <small class="tag tag-red2">No</small>
                  )
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )}
        {/* {jobData.visaRequirement && (
          <div className="row mb-1 font-14">
            <div className={column1}>
              <small>Visa Requirement</small>
            </div>
            <div className={column2}>
              <small>
                {jobData.visaRequirement.charAt(0) === "["
                  ? JSON.parse(jobData.visaRequirement).join(", ")
                  : jobData.visaRequirement}
              </small>
            </div>
            <div className={column3General}>
              <small>
                {(jobData.visaRequirement.charAt(0) === "[" ? (
                  JSON.parse(jobData.visaRequirement).includes(
                    candidateData.visaStatus
                  )
                ) : (
                  candidateData.visaStatus === jobData.visaRequirement
                )) ? (
                  candidateData.visaStatus
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )} */}
        {/* <div className="row mb-1 font-14 ">
          <div className={column1}>
            <small>Position Type</small>
          </div>
          <div className={column2}>
            <small>{jobData.jobType}</small>
          </div>
          <div className={column3General}>
            <small>
              {candidateData.preferredPositionType
                .map((i) => i.slice(0, 3).toLowerCase())
                .includes(jobData.jobType.slice(0, 3).toLowerCase()) ? (
                jobData.jobType
              ) : (
                <small class="tag tag-red2">No</small>
              )}
            </small>
          </div>
        </div> */}
        {jobData.experienceLevel && (
          <div className="row mb-1 font-14">
            <div className={column1}>
              <small>Experience Level</small>
            </div>
            <div className={column2}>
              <small>{jobData.experienceLevel}</small>
            </div>
            <div className={column3General}>
              <small>
                {/* else if (
      candidateData?.experienceYear >= jobData?.experienceLevel?.charAt(0)
    ) {
      sum++;
      experience++;
    } */}
                {candidateData.experienceLevel?.includes(
                  jobData.experienceLevel
                ) ||
                candidateData?.experienceYear >=
                  jobData?.experienceLevel?.charAt(0) ? (
                  jobData.experienceLevel
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )}
        <div className="mb-8"></div>
        {/* </div> */}
        <div className="m-3">
          <h6 className="p-2">{null}</h6>
        </div>
      </div>
    </div>
  );
};

export default AutoMatchScoreCard;
