import React, { useState, useEffect } from "react";

import TopNavBar from "../../layout/TopNavBar";
import SideNavBar from "../../layout/SideNavBar";
import "react-tabs/style/react-tabs.css";
import TopNavbar from "../careers/TopNavBar";
import ProfileInfoCard from "./ProfileInfoCard";
import TodoCard from "./ToDoCard/TodoCard";
import MyJobsCard from "./MyJobsCard/MyJobsCard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCandidateDetails } from "../../../actions/candidate/getCandidateDetails";
import Loader from "../../Loader/Loader";
import SideNavBarForCandidateModule from "./SideNavBarForCandidateModule";
import { post } from "../../../service/ApiService";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";

function CandidateModule() {
  const [toggle, setToggle] = useState(false);
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem("token"));
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [favJobs, setFavJobs] = useState([]);
  const [talentCommunityList, setTalentCommunityList] = useState([]);
  const [matchedJobs, setMatchedJobs] = useState([]);
  const toggleDiv = (type) => {
    setToggle(!toggle);
  };
  const { tenantData, candidateDetailsLoading, candidateData } = useSelector(
    (state) => ({
      tenantLoading: state.getTenantReducer.tenantLoading,
      tenantData: state.getTenantReducer.tenantData,
      candidateDetailsLoading:
        state.candidateDetailsReducer.candidateDetailsLoading,
      candidateData: state.candidateDetailsReducer.candidateData,
    }),
    shallowEqual
  );

  const getFavJobs = async () => {
    const params = {
      candidateId: parseInt(sessionStorage.getItem("userId")),
    };
    setLoading(true);
    await post("/favourites/fetchFavouritejobs", params).then((res) => {
      setFavJobs(res.data);
    });
  };
  const talentCommunitylist = async () => {
    const params = {
      candidateId: parseInt(sessionStorage.getItem("userId")),
    };
    setLoading(true);
    await post("/candidate/gettalentcommunitylist", params).then((res) => {
      setTalentCommunityList(res?.data);
    });
  };

  const getMatchedJobs = async () => {
    const params = {
      candidateId: parseInt(sessionStorage.getItem("userId")),
    };
    setLoading(true);
    await post("/candidate/getMatchedJobs", params).then((res) => {
      setMatchedJobs(res.data);
      // setTalentCommunityList(res?.data);
    });
  };

  useEffect(() => {
    getFavJobs();
    talentCommunitylist();
    getMatchedJobs();
  }, []);

  useEffect(() => {
    const payload = {
      candidateId: sessionStorage.getItem("userId"),
    };

    !candidateData && dispatch(getCandidateDetails(payload));
  }, [candidateData]);

  useEffect(() => {
    if (candidateData) {
      setData(candidateData);
      setOffers(
        candidateData?.dispositionData?.filter(
          (f) =>
            f.statusname === "Offered" ||
            f.statusname === "Hired" ||
            f.statusname === "Offer Rejected"
        )
      );
    }
  }, [candidateData]);

  const fetchCandidateDetails = () => {
    const payload = {
      candidateId: sessionStorage.getItem("userId"),
    };

    dispatch(getCandidateDetails(payload));
  };
  return (
    <>
      {candidateDetailsLoading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        {/* <TopNavBar isFrom={"CandidateProfile"} handleToggale={toggleDiv} /> */}
        <TopNavbar
          // parent="CandidateProfile"
          isLoggedIn={isLoggedIn}
          setIsLoggedIn={setIsLoggedIn}
          handleToggale={toggleDiv}
          tab={new URLSearchParams(window.location.search).get("tab")}
        />
        <SideNavBarForCandidateModule candidateData={candidateData} />
        <div id="Content">
          <div className="section section-sm">
            {isLoggedIn && (
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-12">
                    <div class="mb-5 text-center">
                      {candidateData?.firstName && (
                        <h1>
                          <span className="wave">👋</span> Hi,{" "}
                          {candidateData?.firstName?.charAt(0)?.toUpperCase() +
                            candidateData?.firstName?.slice(1)}
                          . Welcome!{" "}
                        </h1>
                      )}
                      {/* <p>What can we help you work on today?</p> */}
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-xxl-12 col-lg-8">
                    <div className="row justify-content-center">
                      {/*  */}
                      <ProfileInfoCard
                        candidateData={candidateData}
                        talentCommunityList={talentCommunityList}
                      />
                      <TodoCard candidateData={candidateData} />
                      <MyJobsCard
                        candidateData={candidateData}
                        favJobs={favJobs}
                        matchedJobs={matchedJobs}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
}
export default CandidateModule;
