import React from "react";
import five from "../../../src/assets/images/high5-std.png";
import "./FooterStyle.css";

const Footer = (props) => {
  return (
    <>
      <div
        className={props.status === "career" ? "main_footer" : "main_footer2"}
      >
        <div className="main_footer-powered">
          <snap className="main_footer-powered-poweredBy">Powered by</snap>
          <br />

          <div className="main_footer-powered-logo">
            {/* <img className="main_footer-powered-logo-1" src={high}></img> */}
            <img className="main_footer-powered-logo-2" src={five}></img>
          </div>
        </div>

        <span className="main_footer-privacy">
          Privacy Policy and Terms of Service
        </span>
      </div>
    </>
  );
};

export default Footer;
