import React, { useState, useEffect } from "react";
import axios from "axios";
import Autosuggest from "react-autosuggest";
import phoneCode from "../config/PhoneCode";
import currencyCode from "../config/CurrencyCode";

const hereMapAPIKey = "vi41ExssTfSjOFFqiwoOa4vBq5CtvYlmaYTmk7kSyVU";
const hereMapAutoCompleteURL =
  "https://autocomplete.search.hereapi.com/v1/autocomplete";

function HereMap(props) {
  const [autoCompleteData, setAutoCompleteData] = useState([]);
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);

  const theme = {
    suggestionsContainerOpen: {
      display: "block",
      position: "absolute",
      width: "100%",
      border: "1px solid #aaa",
      listStyle: "none",
      zIndex: 10,
      backgroundColor: "rgb(255, 255, 255)",
      fontSize: 14,
      fontFamily: "sans-serif",
      maxHeight: "250px",
      overflow: "auto",
      padding: "5px 15px",
    },
    suggestionsList: {
      listStyleType: "none",
    },
    suggestion: {
      cursor: "pointer",
      padding: "5px 0px",
    },
    suggestionHighlighted: {
      backgroundColor: "rgba(114, 112, 112, 0.125)",
    },
    input: {
      border: "1px solid #ced4da",
      padding: "1rem .75rem",
      height: "calc(3.5rem + 2px)",
      borderRadius: "5px",
    },
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const suggestValues =
      inputLength === 0
        ? []
        : autoCompleteData?.filter((item) =>
            item.name.toLowerCase().includes(inputValue)
          );
    return suggestValues;
  };

  const renderSuggestion = (suggestion) => <div>{suggestion.name}</div>;

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  const onSuggestionsClearRequested = () => setSuggestions([]);

  const inputProps = {
    placeholder: "Search Address",
    value,
    onChange,
  };

  const onRemoteSwitch = (e) => {
    props.setIsRemote(e.target.checked);
  };

  const onSuggestionSelected = (event, { suggestion, method }) => {
    const address = suggestion.address;
    const address2 = address.street ? address.street : "";
    const zipCode = address.postalCode ? address.postalCode : "";
    const city = address.city ? address.city : "";
    const state = address.state ? address.state : "";
    const country = address.countryName ? address.countryName : "";
    props.setForm({
      ...props.form,
      address: address2,
      zipCode: zipCode,
      cityName: city,
      stateName: state,
      CountryName: country,
      country: country,
      addressCity: city,
      addressState: state,
      zipcode: zipCode,
    });
    props.setFormChanged && props.setFormChanged(true);
  };

  useEffect(() => {
    if (value.length > 3) {
      const params = {
        apiKey: hereMapAPIKey,
        q: value,
        maxresults: 10,
      };
      axios.get(hereMapAutoCompleteURL, { params }).then(function(response) {
        const suggestData = response.data.items;
        if (suggestData && suggestData.length > 0) {
          setAutoCompleteData(
            suggestData.map((item) => {
              return { name: item?.address?.label, address: item.address };
            })
          );
        }
      });
    }
  }, [value]);

  return (
    <>
      <div
        className={
          props.component
            ? "card card-flat card-borderless mb-3"
            : "card card-flat card-borderless mb-3"
        }
      >
        <div className="card-body">
          <div className="row">
            <Autosuggest
              suggestions={suggestions}
              onSuggestionsFetchRequested={onSuggestionsFetchRequested}
              onSuggestionsClearRequested={onSuggestionsClearRequested}
              getSuggestionValue={getSuggestionValue}
              renderSuggestion={renderSuggestion}
              onSuggestionSelected={onSuggestionSelected}
              inputProps={inputProps}
              theme={theme}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default HereMap;
