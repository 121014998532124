import { toast } from "react-toastify";
import { post } from "../../service/ApiService";
import { getUserRole, getUserTenantID } from "../../service/AuthService";

export const GET_NOTIFICATION_LIST_BEGIN = "GET_NOTIFICATION_LIST_BEGIN";
export const GET_NOTIFICATION_LIST_SUCCESS = "GET_NOTIFICATION_LIST_SUCCESS";
export const GET_NOTIFICATION_LIST_FAILURE = "GET_NOTIFICATION_LIST_FAILURE";

export const getNotificationList = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_NOTIFICATION_LIST_BEGIN,
    });
    const res = await post("/notifications", {
      userType: getUserRole().toLowerCase(),
      tenant: getUserTenantID(),
    });
    if (res.status === 200) {
      dispatch({
        type: GET_NOTIFICATION_LIST_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_NOTIFICATION_LIST_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
