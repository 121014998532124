import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Col, Row, Card } from "react-bootstrap";
import EmailOverview from "./EmailOverview";
import MessageOverview from "./MessageOverview";
import WhatsAppOverview from "./WhatsAppOverview";
import {
  getEmailOverviewStats,
  getEmailFetchRecentMails,
} from "../../../../../../actions/communication/GetEmailOverviewStats";
import {
  getMessageOverviewStats,
  getFetchRecentMessages,
} from "../../../../../../actions/communication/GetMessageOverviewStats";
import { getCandidateId } from "../../../../../../service/AuthService";
import Loader from "../../../../../Loader/Loader";
import {
  getMessageCount,
  getRecentWhatsAppMessages,
} from "../../../../../../actions/communication/Communication";

const Overview = ({ setMethod }) => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState(true);
  const [sms, setSms] = useState(false);
  const [whatsApp, setWhatsApp] = useState(false);
  const [emailOverViewInfo, setEmailOverViewInfo] = useState([]);
  const [recentEmails, setRecentEmails] = useState([]);
  const [recentMessages, setRecentMessages] = useState([]);

  const {
    emailOverViewData,
    loading,
    recentEmailList,
    recentEmailListLoading,
    messageOverViewDataLoading,
    messageOverViewData,
    recentMessageList,
    recentMessageListLoading,
    whatsAppRecentMessagesLoading,
    whatsAppRecentMessages,
    whatsAppMessageCountLoading,
    whatsAppMessageCount,
  } = useSelector(
    (state) => ({
      emailOverViewData: state.getEmailOverviewReducer.emailOverViewData,
      loading: state.getEmailOverviewReducer.loading,
      recentEmailList: state.getEmailOverviewReducer.recentEmailList,
      recentEmailListLoading:
        state.getEmailOverviewReducer.recentEmailListLoading,
      messageOverViewDataLoading:
        state.getMessageOverviewReducer.messageOverViewDataLoading,
      messageOverViewData: state.getMessageOverviewReducer.messageOverViewData,
      recentMessageList: state.getMessageOverviewReducer.recentMessageList,
      recentMessageListLoading:
        state.getMessageOverviewReducer.recentMessageListLoading,
      whatsAppRecentMessagesLoading:
        state.communicationReducer.whatsAppRecentMessagesLoading,
      whatsAppRecentMessages: state.communicationReducer.whatsAppRecentMessages,
      whatsAppMessageCountLoading:
        state.communicationReducer.whatsAppMessageCountLoading,
      whatsAppMessageCount: state.communicationReducer.whatsAppMessageCount,
    }),
    shallowEqual
  );

  useEffect(() => {
    setEmailOverViewInfo(emailOverViewData);
  }, [emailOverViewData]);

  useEffect(() => {
    const payload = {
      userId: getCandidateId(),
    };
    dispatch(getEmailOverviewStats(payload));
    dispatch(getEmailFetchRecentMails(payload));
    dispatch(getFetchRecentMessages(payload));
    dispatch(getMessageOverviewStats(payload));
    dispatch(getRecentWhatsAppMessages());
    dispatch(getMessageCount());
  }, []);

  const handleView = (view) => {
    if (view === "email") {
      setEmail(true);
      setSms(false);
      setWhatsApp(false);
    } else if (view === "sms") {
      setEmail(false);
      setSms(true);
      setWhatsApp(false);
    } else {
      setEmail(false);
      setSms(false);
      setWhatsApp(true);
    }
  };

  return (
    <>
      {(loading ||
        recentEmailListLoading ||
        messageOverViewDataLoading ||
        recentMessageListLoading) && <Loader />}
      <div
        className="container-fluid w-100 m-0 p-0"
        style={{ minHeight: "70vh" }}
        id="communicationOverview"
      >
        <Row>
          <div className="d-flex">
            <ul className="nav nav-tabs  ">
              <li className="nav-item">
                <button
                  type="button"
                  className={email ? "nav-link pb-2 active" : "nav-link pb-2"}
                  onClick={() => handleView("email")}
                >
                  {" "}
                  <span>Email</span>
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className={sms ? "nav-link pb-2 active" : "nav-link pb-2"}
                  onClick={() => handleView("sms")}
                >
                  {" "}
                  <span>Message</span>
                </button>
              </li>
              {/* <li className="nav-item">
                <button
                  type="button"
                  className={
                    whatsApp ? "nav-link pb-2 active" : "nav-link pb-2"
                  }
                  onClick={() => handleView("whatsApp")}
                >
                  {" "}
                  <span>WhatsApp</span>
                </button>
              </li> */}
            </ul>
          </div>
          <hr />
        </Row>
        <Row>
          <Col md={12} sm={12} lg={12}>
            {email && (
              <EmailOverview
                emailOverViewInfo={emailOverViewInfo}
                recentEmailList={recentEmailList}
                setMethod={setMethod}
              />
            )}
            {sms && (
              <MessageOverview
                recentMessageList={recentMessageList}
                messageOverViewData={messageOverViewData}
                setMethod={setMethod}
              />
            )}
            {whatsApp && (
              <WhatsAppOverview
                whatsAppRecentMessagesLoading={whatsAppRecentMessagesLoading}
                whatsAppRecentMessages={whatsAppRecentMessages}
                whatsAppMessageCount={whatsAppMessageCount}
                setMethod={setMethod}
              />
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Overview;
