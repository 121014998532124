import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Hired from "./Hired";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";
import { getUserRole } from "../../../../../../service/AuthService";
import Loader from "../../../../commoncomponents/Loader/Loader";
import { getPoolDispositionDetails } from "../../../../../../actions/directsourcing/Disposition";
import { useParams } from "react-router";
import { post } from "../../../../../../service/ApiService";
import { toast } from "react-toastify";
import PaginationComponent from "../../../../pagination/pagination";

const Index = (props) => {
  const {
    jobData,
    //dispositionDetails,
    fetchLatestEmailStatus,
    currentPage,
    setCurrentPage,
    view,
    filterLength,
    selectedTalents,
    setSelectedTalents,
  } = props;

  const {
    dispositionDetails,
    dispositionLoading,
    dispositionDisqualifyLoading,
    dispositionDisqualify,
    dispositionCount,
    dispositionCountLoading,
  } = useSelector(
    (state) => ({
      dispositionDetails: state.poolDisposition.dispositionDetails,
      dispositionLoading: state.poolDisposition.dispositionDetailsLoading,
      dispositionDisqualifyLoading:
        state.poolDispositionDisqualify.dispositionDisqualifyLoading,
      dispositionDisqualify:
        state.poolDispositionDisqualify.dispositionDisqualify,
      dispositionCount: state.poolDispositionCount.dispositionCount,
      dispositionCountLoading:
        state.poolDispositionCount.dispositionCountLoading,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const selectAllRef = useRef(null);
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [hiredData, setHiredData] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [toggleActions, setToggleActions] = useState(false);
  const { poolId } = useParams();
  /*** Sort Param  * */
  const [nameSortSelected, setNameSortSelected] = useState(null);
  const [dateSortSelected, setDateSortSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sortSelected, setSortSelected] = useState({
    firstName: false,
    date: false,
  });
  const [sortArray, setSortArray] = useState([]);

  /*** Sort Param  * */
  useEffect(() => {
    let isCancelled = false;

    const timeoutId = setTimeout(() => {
      if (!isCancelled) {
        if (
          paginationArray.length > 0 &&
          !paginationArray[0].hasOwnProperty("latestEmailStatus")
        ) {
          fetchLatestEmailStatus(
            paginationArray,
            setPaginationArray,
            "ReadyToHire"
          );
        }
      }
    }, 1000); // 1 seconds timeout

    return () => {
      isCancelled = true;
      clearTimeout(timeoutId);
    };
  }, [paginationArray]);

  useEffect(() => {
    setHiredData(
      dispositionDetails.filter((item) => item.dispositionStatus == 40)
    );
    setCandidateProfile(false);
  }, [dispositionDetails]);
  /*** Sort Param  * */
  useEffect(() => {
    dispatch(
      getPoolDispositionDetails({
        currentPage,
        limit: 15,
        status: view,
        poolId,
        filterObject: props.filterParams,
        searchText: props.value,
        sortParam: sortArray.length > 0 ? sortArray : [],
      })
    );
  }, [sortArray, sortSelected]);

  const handleSort = (sortValue) => {
    let sortType = "";
    let temp = { ...sortSelected };
    if (sortValue === "firstName") {
      sortType = !nameSortSelected
        ? "ASC"
        : nameSortSelected === "ASC"
        ? "DESC"
        : "ASC";
      setNameSortSelected(sortType);
      setDateSortSelected(null);
      temp = {
        firstName: true,
        date: false,
      };
      setSortSelected(temp);
    } else {
      sortType = !dateSortSelected
        ? "ASC"
        : nameSortSelected === "ASC"
        ? "DESC"
        : "ASC";
      setDateSortSelected(sortType);
      setNameSortSelected(null);
      temp = {
        firstName: false,
        date: true,
      };
      setSortSelected(temp);
    }
    setSortArray(sortValue ? [{ sortValue, sortType }] : []);
  };

  /*** Sort Param  * */

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: view,
      poolId: poolId,
      filterObject: props.filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionDetails(payload));
  };

  const toggleActionsDropdown = () => {
    if (toggleActions === false) {
      setToggleActions(true);
    } else {
      setToggleActions(false);
    }
  };

  const handleSelectAll = () => {
    try {
      let payload = {
        currentPage: currentPage,
        limit: 15,
        status: view,
        poolId: poolId,
        filterObject: props.filterParams,
      };
      setIsLoading(true);
      post("/bulkTalentAction/getpooldispositionfilterdetails", payload).then(
        (res) => {
          let data = Array.isArray(res.data) ? res.data : [];
          setSelectedTalents(data);
          setIsLoading(false);
        }
      );
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };
  return (
    <div>
      {(dispositionLoading ||
        dispositionCountLoading ||
        dispositionDisqualifyLoading) && <Loader />}
      {hiredData.length > 0 ? (
        <>
          <table className="table table-hoverable table-sortable table-cards-1024">
            <thead>
              <tr>
                <th>
                  {dispositionCount?.filter((e) => e.Status == "Hired")[0]
                    ?.Count > 0 && (
                    // filterLength > 0 &&
                    <div
                      className="d-flex align-items-start mb-1 justify-content-center h-100 dropdown"
                      ref={selectAllRef}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        style={{ width: "1.5rem", borderRadius: "100" }}
                        checked={
                          selectedTalents.length ===
                          dispositionCount?.filter(
                            (e) => e.Status == "Hired"
                          )[0]?.Count
                        }
                        onClick={toggleActionsDropdown}
                      />
                      {toggleActions && (
                        <div
                          className="dropdown-menu"
                          style={{
                            display: "revert",
                            left: "-4px",
                            top: "32px",
                            zIndex: 89,
                          }}
                        >
                          <div>
                            <button
                              onClick={() => {
                                handleSelectAll();
                              }}
                              disabled={
                                selectedTalents.length ===
                                dispositionCount?.filter(
                                  (e) => e.Status == "Hired"
                                )[0]?.Count
                              }
                              className="dropdown-item btn-xs"
                            >
                              Select All
                            </button>
                            <hr className="p-0 m-1" />
                          </div>{" "}
                          <div>
                            <button
                              onClick={() => {
                                setSelectedTalents([]);
                              }}
                              disabled={selectedTalents.length === 0}
                              className="dropdown-item btn-xs"
                            >
                              Deselect All
                            </button>
                          </div>{" "}
                        </div>
                      )}
                    </div>
                  )}
                </th>
                <th className="">
                  <div
                    class="d-flex align-items-center ml-2"
                    onClick={() => {
                      handleSort("firstName");
                    }}
                  >
                    Candidate
                    {!nameSortSelected ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "grey" }}
                        ></i>
                      </>
                    ) : nameSortSelected === "ASC" ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    ) : (
                      <>
                        <i
                          className="fad fa-sort-up font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    )}
                  </div>
                </th>
                <th>Vetted</th>
                <th className="align-middle">
                  <div
                    class="d-flex align-items-center ml-2"
                    onClick={() => {
                      handleSort("offerAcceptedDate");
                    }}
                  >
                    {" "}
                    Date
                    {!dateSortSelected ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "grey" }}
                        ></i>
                      </>
                    ) : dateSortSelected === "ASC" ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    ) : (
                      <>
                        <i
                          className="fad fa-sort-up font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    )}
                  </div>
                </th>
                <th>Screening</th>
                <th>Resumes </th>
                {/* <th>Open Link</th> */}
                {/* <th>Vetted</th> */}
                {/* <th>Score </th> */}
              </tr>
            </thead>
            <tbody>
              <Hired
                jobData={jobData}
                hiredData={hiredData}
                calculateScore={props.calculateScore}
                selectedTalents={selectedTalents}
                setSelectedTalents={setSelectedTalents}
                dispositionCount={dispositionCount}
                filterLength={filterLength}
                setCandidateProfile={setCandidateProfile}
                candidateProfile={candidateProfile}
                view={view}
                filterParams={props?.filterParams}
              />
            </tbody>
          </table>
          <PaginationComponent
            currentPage={currentPage}
            totalItems={
              dispositionCount?.filter((e) => e.Status == "Hired")[0]?.Count
            }
            itemsPerPage={15}
            onPageChange={handlePageChange}
            limit={15}
          />
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

export default Index;
