import React, { useState, useEffect, useRef } from "react";
import TagsInput from "react-tagsinput";
import moment from "moment";
import "react-tagsinput/react-tagsinput.css";
import Select from "react-select";
import { post } from "../../../service/ApiService";
import {
  getCandidateId,
  getUserId,
  getUserName,
} from "../../../service/AuthService";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import Autosuggest from "react-autosuggest";
import Unsubscribe from "./Unsubscribe";
import { preferredContactOption } from "../../../constants/Constants";
// import GoogleLocationCountryAPI from "../../location/GoogleLocationCountryAPI";
import GoogleLocationCityAPI from "../../location/GoogleLocationCityAPI";
import EmailPreference from "./EmailPreference";

function Preferred(props) {
  console.log("data at Preferred", props);

  const searchInput = useRef(null);
  const curDate = new Date();
  const [topSkillEdit, setTopSkillEdit] = useState(false);
  const [showExtraSkills, setShowExtraSkills] = useState(false);
  const [dropdownList, setDropdownList] = useState([]);
  const [topLoactionEdit, setTopLocationEdit] = useState(false);
  const [topContactEdit, setTopContactEdit] = useState(false);
  const [email, setEmail] = useState(false);

  const [skillSetList, setSkillSetList] = useState([]);
  const [skillSetTemp, setSkillSetTemp] = useState([]);
  const [skillSet, setSkillSet] = useState("");

  const [preferredLocation, setPreferredLocation] = useState([]);
  const [locationTemp, setLocationTemp] = useState([]);
  const [preferredContact, setPreferredContact] = useState([]);
  const [preferredContactTemp, setPreferredContactTemp] = useState([]);
  const [contact, setContact] = useState([]);
  const [currentvalue, setCurrentvalue] = useState(true);
  const [displayList] = useState([]);

  //email preference

  const REASON_LIST = {
    noWish: "I no longer wish to receive these emails",
    notRelavent: "These emails are not relavent to me",
    neverSigned: "I never signed up for this mailing list",
  };

  const [resubscribeDate, setResubscribeDate] = useState("");
  const initialReason = {
    noWish: false,
    notRelavent: false,
    neverSigned: false,
    other: false,
  };
  const [reason, setReason] = useState({
    noWish: true,
    notRelavent: false,
    neverSigned: false,
    other: false,
  });
  const [commentText, setCommentText] = useState("");
  const handleReason = (e) => {
    let p = { ...initialReason, [e.target.name]: true };
    setReason(p);
  };
  const initialPref = {
    scheduleSub: false,
    unsubscribe: false,
    subscribe: false,
  };
  const [preference, setPreference] = useState({
    scheduleSub: false,
    unsubscribe: false,
    subscribe: false,
  });

  const [notificationType, setNotificationType] = useState({
    //realTime: true,
    daily: true,
    weekly: false,
  });

  const [selectedNotificationType, setSelectedNotificationType] = useState(
    "daily"
  );

  const [scheduleSubDate, setScheduleSubDate] = useState(
    moment(new Date(curDate.setDate(curDate.getDate() + 1))).format(
      "YYYY-MM-DD"
    )
  );
  const [status, setStatus] = useState(false);

  const findStatus = async () => {
    try {
      props.setLoading(true);
      const res = await post("/inviteunsubscribed/fetchStatus", {
        email: getUserName(),
      });

      if (res.data.status === "Unsubscribed") {
        setStatus(false);
        setPreference({ ...preference, unsubscribe: true });
      } else {
        setStatus(true);
        if (props?.recData?.notificationType) {
          let tempType = { ...notificationType };

          if (props.recData?.notificationType === "weekly") {
            tempType["weekly"] = true;
            //tempType["realTime"] = false;
            tempType["daily"] = false;
          }
          if (props.recData?.notificationType === "daily") {
            tempType["weekly"] = false;
            //tempType["realTime"] = false;
            tempType["daily"] = true;
          }

          setNotificationType(tempType);
        }
        let temp = { ...preference };
        temp["subscribe"] = true;
        temp["unsubscribe"] = false;
        temp["scheduleSub"] = false;
        setPreference(temp);
      }

      setResubscribeDate(moment(res.data.date).format("YYYY-MM-DD"));
      res.data.status === "Unsubscribed" &&
        setPreference({ ...preference, subscribe: false });
      props.setLoading(false);
    } catch (error) {
      console.log(error);
      props.setLoading(false);
    }
  };
  useEffect(() => {
    findStatus();
  }, []);

  // useEffect(() => {
  //   props.setLoading(true);
  //   try {
  //     post("/inviteunsubscribed/findSubscribedStatusForUsers", {
  //       email: getUserName(),
  //     })
  //       .then((res) => {
  //         if (res.data[0].subscriptionStatus !== "Unsubscribed") {
  //           setPreferredContact(["Email", "SMS"]);
  //           setPreferredContactTemp(["Email", "SMS"]);
  //         } else {
  //           setPreferredContact([]);
  //           setPreferredContactTemp([]);
  //         }
  //         props.setLoading(false);
  //       })
  //       .catch((err) => {
  //         props.setLoading(false);
  //       });
  //   } catch (err) {
  //     props.setLoading(false);
  //   }
  // }, []);

  const handleChangeLocation = (tags) => {
    setPreferredLocation(tags);
  };
  const handleChangeInputContact = (location) => {
    setContact(location);
  };

  const handleChangeContact = (tags) => {
    setPreferredContact(tags);
  };

  const handleChangeInput = (skillSet) => {
    setSkillSet(skillSet);
  };

  const handleChange = (tags) => {
    setSkillSetList(tags);
  };

  const toggleEditState = (type) => {
    if (type === "skillSet") {
      if (topSkillEdit === false) {
        setTopSkillEdit(true);
      } else {
        setTopSkillEdit(false);
      }
    }

    if (type === "location") {
      if (topLoactionEdit === false) {
        setTopLocationEdit(true);
      } else {
        setTopLocationEdit(false);
      }
    }
    if (type === "contact") {
      if (topContactEdit === false) {
        setTopContactEdit(true);
      } else {
        setTopContactEdit(false);
      }
    }
  };

  const toggleExtra = (type) => {
    if (type === "skillSet") {
      setShowExtraSkills(true);
    }
    if (type === "location") {
      setTopLocationEdit(true);
    }
    if (type === "contact") {
      setTopLocationEdit(true);
    }
  };

  const GetList = async (params) => {
    try {
      props.setLoading(true);
      const res = await post("/preferences/", params);
      if (res.status === 200) {
        const result = res.data[0];
        result.primarySkills &&
          setSkillSetList(JSON.parse(result.primarySkills));
        result.primarySkills &&
          setSkillSetTemp(JSON.parse(result.primarySkills));
        result.preferredLocation &&
          setPreferredLocation(JSON.parse(result.preferredLocation));
        result.preferredLocation &&
          setLocationTemp(JSON.parse(result.preferredLocation));
        props.setLoading(false);
      }
    } catch (error) {
      props.setLoading(false);
      toast.error("Error Occured while accessing Skills and Locations");
    }
  };

  useEffect(() => {
    setDropdownList(props.dropdownList);
    const params = {
      userid: getCandidateId(),
    };
    GetList(params);
  }, []);

  const UpdateSkill = async (params) => {
    try {
      props.setLoading(true);
      const res = await post("/preferences/updateSkills", params);
      if (res.status === 200) {
        setSkillSetTemp(skillSetList);
        props.setLoading(false);
        toast.success(`Skills updated successfully`);
        setTopSkillEdit(false);
      }
    } catch (error) {
      props.setLoading(false);
      toast.error("Error Occured while saving Skills");
    }
  };

  const UpdateLocation = async (params) => {
    try {
      props.setLoading(true);
      const res = await post("/preferences/updateLocation", params);
      if (res.status === 200) {
        setLocationTemp(preferredLocation);
        props.setLoading(false);
        toast.success(`Location updated successfully`);
        setTopLocationEdit(false);
      }
    } catch (error) {
      props.setLoading(false);
      toast.error("Error Occured while saving  Location");
    }
  };

  const done = async (type) => {
    const userid = getCandidateId();
    const params = {
      preferredLocation,
      skillSetList,
      userid,
    };

    if (type === "skillSet") {
      if (skillSetList.length === 0) {
        toast.error("No Skills Added");
      } else {
        UpdateSkill(params, type);
      }
    }

    if (type === "location") {
      if (preferredLocation.length === 0) {
        toast.error("No Locations Added");
      } else {
        UpdateLocation(params, type);
      }
    }
    if (type === "emailPreference") {
      if (
        // !notificationType.realTime &&
        !notificationType.daily &&
        !notificationType.weekly &&
        preference.subscribe
      ) {
        toast.error("Select a notification type");
      } else {
        props.setLoading(true);
        let payload = preference.subscribe
          ? {
              email: getUserName(),
              notificationType: selectedNotificationType,
              subscribe: preference.subscribe,
            }
          : {
              email: getUserName(),
              unsubscribedReason: reason.other
                ? commentText
                : reason.noWish
                ? REASON_LIST["noWish"]
                : reason.notRelavent
                ? REASON_LIST["notRelavent"]
                : REASON_LIST["neverSigned"],
            };

        if (preference.scheduleSub) {
          payload["subscriptionDate"] = scheduleSubDate;
          payload["scheduleSub"] = preference.scheduleSub;
        }
        const res = await post(
          "/inviteunsubscribed/updateEmailPreference",
          payload
        );
        props.setLoading(false);
        setEditEmail(false);
        toast.success(`Preference updated successfully`);
      }
    }

    // if (type === "contact") {
    //   if (preferredContact.length === 0) {
    //     setEmail(true);
    //     setTopContactEdit(false);
    //   } else {
    //     if (
    //       preferredContact.includes("Email") ||
    //       preferredContact.includes("SMS")
    //     )
    //       try {
    //         setTopContactEdit(false);
    //         let url = "/inviteunsubscribed";
    //         url = `${url}/isSubscribed`;

    //         props.setLoading(true);
    //         post(url, { email: getUserName() })
    //           .then((response) => {
    //             props.setLoading(false);
    //           })
    //           .catch((err) => {
    //             props.setLoading(false);
    //           });
    //       } catch (err) {
    //         props.setLoading(false);
    //       }
    //     else {
    //       toast.error("Invalid entry");
    //     }
    //   }
    // }
  };

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropdownList.filter((item) =>
              item.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

    let suggestions = getSuggestions(skillSet);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        theme={theme}
      />
    );
  };

  const handleSkillCancel = () => {
    setTopSkillEdit(false);
    setSkillSetList(skillSetTemp);
  };

  const handleLocationCancel = () => {
    setTopLocationEdit(false);
    setPreferredLocation(locationTemp);
  };

  const handleContactCancel = () => {
    setTopContactEdit(false);
    setPreferredContact(preferredContactTemp);
  };

  const handleChangeMultiSelect = (val) => {
    setPreferredContact(val);
  };

  const handleDelete = (val) => {
    let tempArray = [...preferredLocation];
    const index = tempArray.indexOf(val);
    if (index > -1) {
      tempArray.splice(index, 1);
    }
    setPreferredLocation(tempArray);
  };

  const handleAddLocation = () => {
    if (
      !preferredLocation.includes(searchInput.current.value) &&
      searchInput.current.value != ""
    ) {
      setPreferredLocation([...preferredLocation, searchInput.current.value]);
      searchInput.current.value = "";
    }
  };

  const setLocation = (address, searchInput) => {
    if (!preferredLocation.includes(address.city)) {
      setPreferredLocation([...preferredLocation, address.city]);
      searchInput.current.value = "";
    }
  };

  const [checked, setChecked] = useState(false);

  const [editEmail, setEditEmail] = useState(false);

  const onClickEmailEdit = () => {
    setEditEmail(true);
  };

  //Email Preference
  const handlePreference = (e) => {
    let p = { ...initialPref, [e.target.name]: true };
    setPreference(p);
  };

  const handleNotificationType = (e) => {
    let temp = { ...notificationType };
    // if (e.target.name === "realTime") {
    //   temp["realTime"] = true;
    //   temp["daily"] = false;
    //   temp["weekly"] = false;
    // }
    if (e.target.name === "daily") {
      //temp["realTime"] = false;
      temp["daily"] = true;
      temp["weekly"] = false;
    }
    if (e.target.name === "weekly") {
      //temp["realTime"] = false;
      temp["daily"] = false;
      temp["weekly"] = true;
    }
    setNotificationType(temp);
    setSelectedNotificationType(e.target.name);
  };

  return (
    <>
      <div className="card card-lg mb-2">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between">
            <label>Preferred Skills</label>
            {!topSkillEdit ? (
              <button
                type="button"
                className="btn btn-text"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  toggleEditState("skillSet");
                }}
              >
                <i className="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={handleSkillCancel}
                  className="btn btn-sm btn-secondary mr-3"
                  style={{ fontSize: "12px" }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  style={{ fontSize: "12px" }}
                  onClick={() => {
                    done("skillSet");
                  }}
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <div className="mt-2">
            <div className="w-100-768">
              <div className="form-inline-edit">
                {topSkillEdit === false ? (
                  <div>
                    <div className="toggle justify-content-start">
                      <div>
                        {skillSetList.length > 0 ? (
                          skillSetList.map((item) => (
                            <span className="tag tag-blue3 mb-1 mr-1">
                              {item}
                            </span>
                          ))
                        ) : (
                          <span className="tag tag-red2 mb-1 mr-1">
                            No data found
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div>
                      <TagsInput
                        renderInput={autocompleteRenderInput}
                        value={skillSetList}
                        onChange={handleChange}
                        inputValue={skillSet}
                        onChangeInput={handleChangeInput}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr className="mb-2" />
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <label className="p-0 mt-2">
                Preferred Locations (
                <small>Note: Type a country and hit Enter</small>)
              </label>
              {!topLoactionEdit ? (
                <button
                  type="button"
                  className="btn btn-text"
                  style={{ fontSize: "12px" }}
                  onClick={() => {
                    toggleEditState("location");
                  }}
                >
                  <i className="fas fa-pencil-alt mr-1"></i>
                  Edit
                </button>
              ) : (
                <div>
                  <button
                    type="button"
                    onClick={handleLocationCancel}
                    className="btn btn-sm btn-secondary mr-3"
                    style={{ fontSize: "12px" }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      done("location");
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <div className="mt-2">
              <div className="">
                <div className="form-inline-edit">
                  {topLoactionEdit === false ? (
                    <div>
                      <div className="toggle justify-content-start">
                        <div>
                          {preferredLocation.length > 0 ? (
                            preferredLocation.map((item) => (
                              <span className="tag tag-blue3 mb-1 mr-1">
                                {item}
                              </span>
                            ))
                          ) : (
                            <span className="tag tag-red2 mb-1 mr-1">
                              No data found
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="d-flex align-items-center">
                        <ul>
                          {preferredLocation.map((item) => (
                            <li
                              className="tag tag-blue3 mr-2 mt-1"
                              style={{ height: "22px" }}
                            >
                              {item}
                              <i
                                class="fa fa-close ml-1"
                                style={{ cursor: "pointer" }}
                                aria-hidden="true"
                                onClick={() => handleDelete(item)}
                              ></i>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="d-flex align-items-center">
                        <GoogleLocationCityAPI
                          setLocation={setLocation}
                          searchInput={searchInput}
                          className="countryPreferred"
                        />
                        <button
                          type="button"
                          className="btn btn-sm btn-primary ml-1"
                          style={{ fontSize: "12px" }}
                          onClick={handleAddLocation}
                        >
                          Add
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <hr className="mb-2" />
          <div className="d-flex align-items-center justify-content-between">
            <label className="p-0 mt-2">
              Preferred way of contact (<small>Example: Email</small>)
            </label>
            {!topContactEdit ? (
              <button
                type="button"
                className="btn btn-text"
                style={{ fontSize: "12px" }}
                onClick={() => {
                  toggleEditState("contact");
                }}
              >
                <i className="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={handleContactCancel}
                  className="btn btn-sm btn-secondary mr-3"
                  style={{ fontSize: "12px" }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-sm btn-primary"
                  style={{ fontSize: "12px" }}
                  onClick={() => {
                    done("contact");
                  }}
                >
                  Save
                </button>
              </div>
            )}
          </div>
          <div className="mt-2">
            <div className="">
              <div className="form-inline-edit">
                {topContactEdit === false ? (
                  <div>
                    <div className="toggle justify-content-start">
                      <div>
                        {preferredContact.length > 0 ? (
                          preferredContact.map((item) => (
                            <span className="tag tag-blue3 mb-1 mr-1">
                              {item}
                            </span>
                          ))
                        ) : (
                          <div>
                            <span className="tag tag-blue3 mb-1 mr-1">
                              Email
                            </span>
                            <span className="tag tag-blue3 mb-1 mr-1">SMS</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="form-floating Hover-Dropdown">
                    <Select
                      isMulti
                      options={preferredContactOption}
                      value={
                        preferredContact.length > 0
                          ? preferredContactOption.filter((f) =>
                              preferredContact.includes(f.label)
                            )
                          : []
                      }
                      name="preferredContact"
                      className="border"
                      isSearchable={true}
                      onChange={(value) =>
                        handleChangeMultiSelect(value?.map((i) => i.label))
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr className="mb-2" />
          <div>
            <div className="d-flex align-items-center justify-content-between">
              <label className="p-0 mt-2">Communication Preference</label>
              {editEmail === false ? (
                <button
                  type="button"
                  className="btn btn-text"
                  style={{ fontSize: "12px" }}
                  onClick={() => {
                    onClickEmailEdit();
                  }}
                >
                  <i className="fas fa-pencil-alt mr-1"></i>
                  Edit
                </button>
              ) : (
                <div>
                  <button
                    type="button"
                    onClick={() => setEditEmail(false)}
                    className="btn btn-sm btn-secondary mr-3"
                    style={{ fontSize: "12px" }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-sm btn-primary"
                    style={{ fontSize: "12px" }}
                    onClick={() => {
                      done("emailPreference");
                    }}
                  >
                    Save
                  </button>
                </div>
              )}
            </div>
            <EmailPreference
              preference={preference}
              handlePreference={handlePreference}
              scheduleSubDate={scheduleSubDate}
              setScheduleSubDate={setScheduleSubDate}
              status={status}
              notificationType={notificationType}
              handleNotificationType={handleNotificationType}
              editEmail={editEmail}
              handleReason={handleReason}
              reason={reason}
              commentText={commentText}
              setCommentText={setCommentText}
            />
          </div>
        </div>
      </div>
    </>
  );
}
export default Preferred;
