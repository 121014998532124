import React from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  getUserTenantID,
  getCandidateId,
} from "../../../../../../service/AuthService";
import { emailDomain } from "../../../../../../constants/Constants";

const ShareJob = (props) => {
  const { jobData, share, setShare } = props;
  const tenantId = jobData.fk_tenant;

  const userId = props.isFrom === "favJobs" ? "698131846" : getCandidateId();

  const link = `${emailDomain}/career/jobs/${jobData.jobId}/recruiter/${userId}`;

  const handleClickCopy = () => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard.");
  };

  return (
    <Modal show={share} onHide={() => setShare(false)} size="lg">
      <div className="bl-modal-header">
        <h5 className="bl-modal-title">{"Share Job"}</h5>
        <div className="bl-modal-header">
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShare(false)}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <h6>Job page link</h6>
          <div class="input-group input-group-append ml-2 w-95 mb-2">
            <input
              type="text"
              class="form-control"
              placeholder="Copy Share Link"
              aria-label="Copy Share Link"
              aria-describedby="button-addon2"
              disabled
              value={link}
            />
            <button class="btn btn-secondary" onClick={handleClickCopy}>
              Copy
            </button>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ShareJob;
