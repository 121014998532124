import moment from "moment";
import React, { useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
// import "../style.css";

const JobCard = ({ job, matches400, i }) => {
  const { tenant } = useParams();
  const [testId, setTestId] = useState({});
  const recruiterId = sessionStorage.getItem("recruiterId");

  const removeItemFromArray = (item) => {
    const temp = { ...testId };
    delete temp[item];
    setTestId(temp);
  };

  return (
    // <div className="card card-body">
    //   <div
    //     className={
    //       matches400
    //         ? "d-flex flex-column"
    //         : " d-flex flex-row  p-2 w-100  mx-auto"
    //     }
    //   >
    //     <div className={matches400 ? "" : "col-10"}>
    //       <div className="main_cardClass-frame-left">
    //         <div className="main_cardClass-frame-left_jobHeading">
    //           <span className="main_cardClass-frame-left_jobHeading-text">
    //             {job.jobTitle}
    //             <svg
    //               width="24"
    //               height="24"
    //               viewBox="0 0 24 24"
    //               fill="none"
    //               xmlns="http://www.w3.org/2000/svg"
    //             >
    //               <path
    //                 d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
    //                 fill="currentColor"
    //               />
    //             </svg>
    //           </span>
    //         </div>
    //         <div className="main_cardClass-frame-left_jobDetails">
    //           <span className="d-flex align-items-center me-3">
    //             <i className="far fa-suitcase me-2 font-brandBlue"></i>
    //             <small className="font-regular font-primary font-gray1">
    //               {job.jobType}
    //             </small>
    //           </span>
    //           <span className="d-flex align-items-center me-3">
    //             <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
    //             <small className="font-regular font-primary font-gray1">
    //               {job.workPlaceType}
    //             </small>
    //           </span>
    //           <span className="d-flex align-items-center me-3">
    //             <i className="far fa-clock me-2 font-brandBlue"></i>
    //             <small className="font-regular font-primary font-gray1">
    //               {moment(
    //                 new Date(job.createdDateTime),
    //                 "MMDDYYYY"
    //               ).fromNow() === "a month ago"
    //                 ? "A month ago"
    //                 : moment(
    //                     new Date(job.createdDateTime),
    //                     "MMDDYYYY"
    //                   ).fromNow() === "an hour ago"
    //                 ? "An hour ago"
    //                 : moment(
    //                     new Date(job.createdDateTime),
    //                     "MMDDYYYY"
    //                   ).fromNow()}
    //             </small>
    //           </span>
    //           {/* {job?.visaStatus && (
    //             <span
    //               className="d-flex align-items-center "
    //               style={{ maxWidth: '325px'}}
    //             >
    //               <i class="far fa-plane-departure" ></i>{" "}
    //               <span className="font-regular " style={{ marginLeft: "8px" }}>
    //                 <small className="font-regular font-primary font-gray1">
    //                   {JSON.parse(job?.visaStatus).join(", ")}
    //                 </small>
    //               </span>
    //             </span>
    //           )} */}
    //           {job?.visaStatus && (
    //             <div className="mt-1 d-block" style={{ width: "50%" }}>
    //               <i class="far fa-plane-departure mr-1"></i>{" "}
    //               {JSON.parse(job?.visaStatus).map(
    //                 (item, index) =>
    //                   (index < 2 || testId[i] == "visastatus") && (
    //                     <span className="tag tag-blue3 mr-1 pt-1 px-2 mt-1">
    //                       {item}
    //                     </span>
    //                   )
    //               )}
    //               {job?.visaStatus &&
    //                 (testId[i] == "visastatus" ? (
    //                   <div
    //                     className="tag"
    //                     onClick={() => removeItemFromArray(i)}
    //                     style={{ margin: "2px", cursor: "pointer" }}
    //                   >
    //                     Show less
    //                   </div>
    //                 ) : (
    //                   JSON.parse(job?.visaStatus).length > 2 && (
    //                     <div
    //                       className="tag"
    //                       onClick={() => {
    //                         setTestId({ ...testId, [i]: "visastatus" });
    //                       }}
    //                       style={{ margin: "2px", cursor: "pointer" }}
    //                     >
    //                       +{JSON.parse(job?.visaStatus).length - 2} more
    //                     </div>
    //                   )
    //                 ))}
    //             </div>
    //           )}
    //         </div>
    //       </div>
    //     </div>
    //     <div
    //       className={
    //         matches400
    //           ? ""
    //           : "col-2 d-flex align-items-center justify-content-end pr-3"
    //       }
    //     >
    //       {/* <Link
    //         to={`/careers/${job.fk_tenant}/joblist/jobdescription/${job.jobId}/recruiter/698131846`}
    //       > */}
    //       <button
    //         className={
    //           matches400 ? "btn btn-sm w-100 mb-3" : "btn btn-sm  mx-auto  "
    //         }
    //         style={{ backgroundColor: "#7fba00" }}
    //         onClick={() =>
    //           window.open(
    //             `/careers/${job.fk_tenant}/joblist/jobdescription/${job.jobId}/recruiter/698131846`,
    //             "_self"
    //           )
    //         }
    //       >
    //         <span className="">Apply Now</span>
    //       </button>
    //       {/* </Link> */}
    //     </div>
    //   </div>
    // </div>

    <div
      className={`card p-0 d-flex w-100 align-items-center 
    justify-content-between job-listview flex-row overflow-hidden mb-1  `}
    >
      <div className="card-body">
        <div className="d-flex align-items-center">
          <span className="mr-2 mt-1">
            <h6>{job.jobTitle}</h6>
          </span>
          {/* <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5858 6.34317L12 4.92896L19.0711 12L12 19.0711L10.5858 17.6569L16.2427 12L10.5858 6.34317Z"
              fill="currentColor"
            />
          </svg> */}
        </div>
        <div className="d-flex align-items-center jus">
          {job.jobType && (
            <span className="d-flex align-items-center me-3">
              <i className="far fa-suitcase me-2 font-brandBlue"></i>
              <small className="font-regular font-primary font-gray1">
                {job.jobType}
              </small>
            </span>
          )}{" "}
          {job.workPlaceType && (
            <span className="d-flex align-items-center me-3">
              <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
              <small className="font-regular font-primary font-gray1">
                {job.workPlaceType}
              </small>
            </span>
          )}{" "}
          {job.jobPostDate && (
            <span
              className={`${
                window.innerWidth < 460
                  ? "hide-on-small-screen"
                  : "d-flex align-items-center me-3 "
              }`}
            >
              <i className="far fa-clock me-2 font-brandBlue"></i>
              <small className="font-regular font-primary font-gray1">
                {moment(new Date(job.jobPostDate), "MMDDYYYY").fromNow() ===
                "a month ago"
                  ? "A month ago"
                  : moment(new Date(job.jobPostDate), "MMDDYYYY").fromNow() ===
                    "an hour ago"
                  ? "An hour ago"
                  : moment(new Date(job.jobPostDate), "MMDDYYYY").fromNow()}
              </small>
            </span>
          )}
          {job?.visaStatus && (
            <div className="mt-1 d-block" style={{ width: "50%" }}>
              <i class="far fa-plane-departure mr-1"></i>{" "}
              {JSON.parse(job?.visaStatus).map(
                (item, index) =>
                  (index < 2 || testId[i] == "visastatus") && (
                    <span className="tag tag-blue3 mr-1 pt-1 px-2 mt-1">
                      {item}
                    </span>
                  )
              )}
              {job?.visaStatus &&
                (testId[i] == "visastatus" ? (
                  <div
                    className="tag"
                    onClick={() => removeItemFromArray(i)}
                    style={{ margin: "2px", cursor: "pointer" }}
                  >
                    Show less
                  </div>
                ) : (
                  JSON.parse(job?.visaStatus).length > 2 && (
                    <div
                      className="tag"
                      onClick={() => {
                        setTestId({ ...testId, [i]: "visastatus" });
                      }}
                      style={{ margin: "2px", cursor: "pointer" }}
                    >
                      +{JSON.parse(job?.visaStatus).length - 2} more
                    </div>
                  )
                ))}
            </div>
          )}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-end pr-3">
        <button
          className={
            matches400 ? "btn btn-sm w-100 mb-3" : "btn btn-sm  mx-auto  "
          }
          style={{ backgroundColor: "#7fba00" }}
          onClick={() =>
            window.open(
              `/careers/${job.fk_tenant}/joblist/jobdescription/${job.jobId}/recruiter/698131846`,
              "_self"
            )
          }
        >
          <span className="">Apply Now</span>
        </button>
      </div>
    </div>
  );
};

export default JobCard;
