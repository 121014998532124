import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";

const ClientTalentCommunity = ({
  communityData,
  communityLoading,
  fetchCommunityData,
  setClientData,
  clientData,
  validation,
  tenant,
}) => {
  const isFirstRender = useRef(true);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (communityData) {
      setOptions(
        communityData.map((item) => {
          return {
            label: `${item.poolname} (${item.poolId})`,
            value: item.poolId,
          };
        })
      );
    }
  }, [communityData]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchValue.length === 0 || searchValue.length >= 2) {
        fetchCommunityData(searchValue);
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchValue]);

  const styleSheet = {
    input: (base) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
    control: (base) => ({
      ...base,
      borderColor: validation.community ? "red" : base.borderColor,
      "&:hover": {
        borderColor: validation.community ? "red" : base["&:hover"].borderColor,
      },
    }),
    dropdownIndicator: (provided) => ({ ...provided, display: "none" }),
    indicatorSeparator: () => ({
      display: "none", // Hide the indicator separator
    }),
  };

  const handleChange = (val) => {
    setClientData(val);
  };

  const handleInputChange = (val) => {
    setSearchValue(val);
  };

  return (
    <Select
      options={communityLoading ? [] : options}
      isSearchable={true}
      onChange={(val) => {
        handleChange(val);
      }}
      styles={styleSheet}
      value={clientData}
      isMulti={false}
      onInputChange={(val) => {
        handleInputChange(val);
      }}
      isLoading={communityLoading}
      placeholder={"Select Community*"}
      isClearable
      onMenuOpen={() => {
        fetchCommunityData(searchValue);
      }}
      isDisabled={tenant == null}
    />
  );
};

export default ClientTalentCommunity;
