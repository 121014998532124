import React, { useEffect, useState } from "react";
import { get, post } from "../../../../../service/ApiService";
import { ButtonLoader, domain } from "../../../../../constants/Constants";
import { toast } from "react-toastify";
function Security({ data }) {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [showPassword, setShowpassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [ConfirmPassword, setConfirmPassword] = useState("");
  const [passwordLength, setPasswordLength] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [id, setId] = useState(sessionStorage.getItem("userId"));
  const [enableConfirm, setEnableConfirm] = useState(false);
  const [EnableCondition, setEnableCondition] = useState(true);
  const [matchPassword, setMatchPassword] = useState(false);
  let [enableSubmit, setEnableSubmit] = useState(false);
  useEffect(() => {}, [passwordLength, passwordValidation]);

  const showPasswordClick = () => {
    if (showPassword === false) {
      setShowpassword(true);
    } else {
      setShowpassword(false);
    }
  };
  const showConfirmPasswordClick = () => {
    if (showConfirmPassword === false) {
      setShowConfirmPassword(true);
    } else {
      setShowConfirmPassword(false);
    }
  };

  const changePassword = () => {
    setReadOnlyMode(false);
  };

  const cancelChangePassword = () => {
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
    setConfirmPassword("");
    setPassword("");
    setPasswordValidation(false);
    setPasswordLength(false);
    setEnableSubmit(false);
  };

  const saveChangePassword = async () => {
    // console.log(submittingForm, saveClicked, showSuccessMessage, submittingForm)

    if (submittingForm == false) {
      setSubmittingFrom(true);
      setSaveClicked(false);

      // call api to send data to server side
      const pasData = {
        newPassword: ConfirmPassword,
        id_candidateLogin: sessionStorage.getItem("userName"),
        candidateID: data.userId,
      };
      const res = await post(`/mapCanData/updateCanPassword`, pasData);
      if (res.status === 200) {
        setTimeout(() => {
          setShowSuccessMessage(true);
          setSubmittingFrom(false);
          setSaveClicked(true);

          setTimeout(() => {
            setReadOnlyMode(true);
            setShowSuccessMessage(false);
          }, 1000);
          toast.success("Password updated successfully");
          // console.log("yey")
        }, 1000);
      } else {
        // console.log("error")
        toast.error("Something went wrong");
        //  setReadOnlyMode(true);
      }
    }
  };

  const handlePassword = (e) => {
    let passwordLength = "";
    let confirmPassword = "";

    if (e.target.id === "confirmPassword") {
      setConfirmPassword(e.target.value);
      confirmPassword = e.target.value;
    }

    if (e.target.id === "newPassword") {
      setPassword(e.target.value);
      passwordLength = e.target.value;

      if (passwordLength.length >= 8) {
        setPasswordLength(true);
      } else {
        setPasswordLength(false);
      }

      var specialCharacterPresent = /^(?=.*[!@#$%^&*])/;
      var numberPresent = /^(?=.*[0-9])/;

      if (
        numberPresent.test(e.target.value) ||
        specialCharacterPresent.test(e.target.value)
      ) {
        setPasswordValidation(true);
      } else {
        setPasswordValidation(false);
      }

      if (
        passwordLength.length >= 8 &&
        (numberPresent.test(password) || specialCharacterPresent.test(password))
      ) {
        setEnableConfirm(true);
        setEnableCondition(true);
      } else {
        setEnableConfirm(false);
        setEnableCondition(false);
      }
    }

    if (password == confirmPassword && password !== "") {
      setEnableCondition(false);
      setMatchPassword(false);
      setEnableSubmit(true);
    } else {
      setEnableSubmit(false);
      setMatchPassword(true);
      setEnableCondition(true);
    }
  };

  return (
    <>
      {/* <div v-bind:className="{'read-only':form.security.readOnly}"> */}
      <div className={readOnlyMode && "read-only"}>
        <div className="card card-lg">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="mb-0">Security</h6>
              {readOnlyMode ? (
                <button
                  type="button"
                  v-if="form.security.readOnly"
                  className="btn btn-text"
                  onClick={changePassword}
                >
                  <i className="fas fa-pencil-alt mr-1"></i>
                  Change Password
                </button>
              ) : (
                <div>
                  {/* <button type="button" className="btn btn-sm btn-secondary" v-on:click.stop.prevent="toggleForm('contact')"> */}
                  <button
                    type="button"
                    onClick={cancelChangePassword}
                    className="btn btn-sm btn-secondary mr-1"
                  >
                    Cancel
                  </button>
                  {/* <button type="button" v-on:click="submitForm('contact')"  className="btn btn-sm btn-primary"> */}
                  <button
                    type="button"
                    onClick={saveChangePassword}
                    className="btn btn-sm btn-primary"
                    disabled={!enableSubmit}
                  >
                    {saveClicked && <span>Save Changes</span>}

                    {submittingForm && (
                      <span>
                        Saving Changes
                        <img width="20px" src={ButtonLoader} alt="" />
                      </span>
                    )}
                    {/* {showSuccessMessage && <span>Changes Saved!</span>} */}
                  </button>
                </div>
              )}
            </div>
            {readOnlyMode ? (
              <div v-if="form.security.readOnly">
                {/* <div className="form-floating form-password mb-2 w-100 ">
                  <button
                    onClick={showPasswordClick}
                    type="button"
                    className="btn btn-sm btn-text btn-text-accent"
                  ></button>
                </div> */}
              </div>
            ) : (
              <div>
                <div className="mb-3">
                  <p className="mt-2">
                    <small>
                      <strong className="mr-2 mb-1 d-block">
                        Password Requirements
                      </strong>
                    </small>
                    <span
                      className={
                        !passwordLength ? "tag mr-2" : "tag mr-2 tag-green2"
                      }
                    >
                      Min 8 Characters{" "}
                      {!passwordLength ? (
                        <i
                          v-if="!form.security.passwordLength"
                          className="ml-1 far fa-circle"
                        ></i>
                      ) : (
                        <i className="ml-1 fas fa-check-circle"></i>
                      )}
                    </span>
                    <span
                      //   className="tag mr-2"
                      className={
                        !passwordValidation ? "tag mr-2" : "tag mr-2 tag-green2"
                      }
                    >
                      1 Number Or Special Character{" "}
                      {!passwordValidation ? (
                        <i
                          v-if="!form.security.passwordCondition"
                          className="ml-1 far fa-circle"
                        ></i>
                      ) : (
                        <i className="ml-1 fas fa-check-circle"></i>
                      )}
                    </span>
                  </p>
                </div>
                <div className="form-floating form-password mb-2">
                  <input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    id="newPassword"
                    aria-describedby="newPasswordSetup"
                    placeholder="New Password"
                    onChange={handlePassword}
                    value={password}
                  />
                  <label htmlFor="newPassword">New Password</label>

                  <button
                    //   v-on:click="revealPassword('security')"
                    onClick={showPasswordClick}
                    type="button"
                    className="btn btn-sm btn-text btn-text-accent h-100"
                  >
                    <i
                      className={showPassword ? "fa fa-eye" : "fa fa-eye-slash"}
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>

                <div className="form-floating form-password mb-2">
                  <input
                    v-model="form.security.passwordConfirmation"
                    type={showConfirmPassword ? "text" : "password"}
                    disabled={
                      // !enableConfirm ||
                      submittingForm || !passwordValidation || !passwordLength
                    }
                    className="form-control"
                    id="confirmPassword"
                    aria-describedby="newPasswordConfirmation"
                    placeholder="Confirm Password"
                    onChange={handlePassword}
                    value={ConfirmPassword}
                  />
                  <label htmlFor="confirmPassword">Confirm Password</label>
                  <button
                    //   v-on:click="revealPassword('security')"
                    onClick={showConfirmPasswordClick}
                    type="button"
                    className="btn btn-sm btn-text btn-text-accent h-100"
                  >
                    <i
                      className={
                        showConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"
                      }
                      aria-hidden="true"
                    ></i>
                  </button>
                </div>
                {matchPassword === true && ConfirmPassword && (
                  <small className="text-danger mb-2">
                    Passwords do not match
                  </small>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Security;
