import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import SourceCandidateListView from "./SourceCandidateListView";
import SourceCandidateTableView from "./SourceCandidateTableView";
import SourceCandidateGridView from "./SourceCandidateGridView";
import TopNavBar from "../../layout/TopNavBar";
import SideNav from "../../layout/SideNavBar";
import SourceCandidatePopup from "./SourceCandidatePopUp";
import SourceCandidateBulkPopUp from "./SourceCandidateBulkPopUp";
import RequestSideBar from "../../common/Jobs/ViewJobDetails/RequestSideBar";
import { post, get } from "../../../service/ApiService";
import { domain } from "../../../constants/Constants";
import Loader from "../../Loader/Loader";
import FormSubmitSuccess from "../../modal/result/FormSubmitSuccess";
import ShareViaEmail from "../../common/Jobs/ViewJobDetails/ShareViaEmail";
import PublicShareLink from "../../common/Jobs/ViewJobDetails/PublicShareLink";
import DeleteRequest from "../../common/Jobs/ViewJobDetails/DeleteRequest";
import {
  sourceTalentSuccess,
  sourceTalentBulkSuccess,
} from "./FormSuccessHtml";
// import EditRequest from '../../HiringManager/Model/EditRequest';
// import CandidateSort from "../../HiringManager/Model/CandidatesTabs/CandidateSort";
// import CandidateFilter from "../../HiringManager/Model/CandidatesTabs/CandidateFilter";
// import '../../../sass/_action-panel.scss'
import SubmitTalentModal from "./SubmitTalentModal";
import Avatar from "react-avatar";
import TalentFilter from "../../talent/TalentFilter";
import OutsideClickHandler from "react-outside-click-handler";
import Sort from "../../common/Sort";
import { toast } from "react-toastify";
import { getTenantType, getUserTenant } from "../../../service/AuthService";
import AutoMatchScoreCard from "../../common/Jobs/ViewTalentDetails/AutoMatchScoreCard";
import ExportJobDetails from "../../common/Jobs/ViewJobDetails/ExportJobDetails";
import Footer from "../../layout/Footer";

function SourceTalent(props) {
  const [checkedFn, setcheckedFn] = useState(false);
  const [overview, setOverview] = useState(true);
  const [details, setDetails] = useState(false);
  const [activity, setActivity] = useState(false);
  const [stats, setStatus] = useState(false);
  const [skills, setSkills] = useState(false);
  const [description, setDescription] = useState(false);
  const [education, setEducation] = useState(false);
  const [certifications, setCertifications] = useState(false);
  const [industries, setIndustries] = useState(false);
  const [workHours, setWorkHours] = useState(false);
  const [travel, setTravel] = useState(false);
  const [drugTest, setDrugTest] = useState(false);
  const [backgroundCheck, setBackgroundCheck] = useState(false);
  const [securityClearance, setSecurityClearance] = useState(false);
  const [editButton, setedit] = useState(false);
  const [shareButton, setshareButton] = useState(false);
  const [PublicShareLinkValue, setPublicShareLinkButton] = useState(false);
  const [DeleteRequestValue, setDeleteRequestButton] = useState(false);
  const [sourceTalentValue, setSourceTalentButton] = useState(false);
  const [sourceTalentBulkValue, setSourceTalentBulkButton] = useState(false);
  const [listview, setlistview] = useState(true);
  const [gridview, setgridview] = useState(false);
  const [tableview, settableview] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [sortingPopUp, setSorting] = useState(false);
  const [sorting, setAddsorting] = useState(false);
  let [count, setcount] = useState(0);
  const [filter, setFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [candidateList, setCandidateList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [totalCandidate, setTotalCandidate] = useState(0);
  const [currencyList, setCurrencyList] = useState([]);
  const [souceCandidateData, setSouceCandidateData] = useState({});
  const [jobData, setJobData] = useState({
    fullText: {},
  });
  const [fullText, setFullText] = useState({
    location: {},
  });
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [successFormBulkOpen, setSuccessFormBulkOpen] = useState(false);
  const [successData, setSuccessData] = useState({
    title: "Talent Submitted",
    subTitle: "",
    htmlTag: "",
  });
  const [submitModal, setSubmitModal] = useState(false);
  const [candidateDetails, setCandidateDetails] = useState({});
  const [candidateTitles, setCandidateTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [candidateLocations, setCandidateLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [vetting, setVetting] = useState(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState(["", ""]);
  const [lastUpdatedType, setlastUpdatedType] = useState("");
  const [candidateSkills, setCandidateSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [candidateSkillSet, setCandidateSkillSet] = useState([]);
  const [selectedSkillSet, setSelectedSkillSet] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const [intakeData, setIntakeData] = useState({ documents: [] });
  const [score, setScore] = useState(false);
  const [jdDocument, setJdDocument] = useState([]);
  const [qnA, setQnA] = useState(false);
  const [notes, setNotes] = useState(false);
  const [document, setDocument] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );
  const [exportButton, setexportButton] = useState(false);
  const [tenant, setTenant] = useState("");

  useEffect(() => {
    window
      .matchMedia("(max-width: 1200px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const sortObject = [
    {
      id: 1,
      label: "Name",
      value: "firstName",
      icon: "fal fa-fw fa-signature  mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Location",
      value: "city",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Created by",
      value: "createdByName",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
    {
      id: 4,
      label: "Last updated",
      value: "lastUpdated",
      icon: "fal fa-fw fa-calendar-alt mr-2 dd-sort",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  useEffect(() => {
    fetchCurrency();
    fetchJobData();
    fetchJdDocument();
  }, []);

  // Fetch currency dropdown
  const fetchJobData = async () => {
    const jobId = props.match.params.Id;
    setLoading(true);
    const payload = {
      domain: domain,
      jobId: jobId,
    };
    let result = await post("/job/getrequestdetailsbyId", payload);
    setJobData(result.data.jobData);
    setFullText(result.data.jobData.fullText);
    fetchCandidateList(result.data.jobData);
    setTenant(result.data.tenantData);
    setLoading(false);
  };

  // Fetch Job Data
  const fetchCurrency = async () => {
    setLoading(true);
    let res = await get("/sourcecandidate/ratedropdown");
    setCurrencyList(res.data);
    setLoading(false);
  };

  // Fetch Candidate Data
  const fetchCandidateList = async (jobData) => {
    const jobId = props.match.params.Id;
    setLoading(true);
    const payload = {
      domain: domain,
      skip: 0,
      jobId,
      jobData,
    };
    let res = await post("/sourcecandidate/getcandidate", payload);
    let title = [];
    let location = [];
    let skill = [];
    let skillSet = [];
    let createdBy = [];
    let data = res.data.candidateData.filter((item) => item.eligible);
    setCandidateList(data);
    setFilterData(data);
    setTotalCandidate(res.data.totalCandidates);
    data.forEach((item) => {
      if (item.designation) {
        if (!title.includes(item.designation)) {
          title.push(item.designation.trim());
        }
      }
      if (item.city && item.state) {
        if (!location.includes(`${item.city}, ${item.state}`)) {
          location.push(`${item.city}, ${item.state}`);
        }
      }
      if (item.createdByName && !createdBy.includes(item.createdByName)) {
        createdBy.push(item.createdByName);
      }
      if (item.primarySkills.length > 0) {
        skill.push(...item.primarySkills);
      }
      if (item.skillSet.length > 0 && !skillSet.includes(item.skillSet)) {
        skillSet.push(...item.skillSet);
      }
    });
    setCandidateTitles(title);
    setCandidateLocations(location);
    setCreatedBy(createdBy);
    setCandidateSkills([...new Set(skill)]);
    setCandidateSkillSet([...new Set(skillSet)]);
    setLoading(false);
  };

  const onTabClick = (category, val) => {
    resetTabs([category]);
    updateTab([category], val);
  };

  // Tab change
  const updateTab = (category, type) => {
    if (type === "listview") {
      setlistview(true);
      setgridview(false);
      settableview(false);
    }
    if (type === "gridview") {
      setgridview(true);
      setlistview(false);
      settableview(false);
    }
    if (type === "tableview") {
      settableview(true);
      setlistview(false);
      setgridview(false);
    }

    if (type === "overview") {
      setOverview(true);
      setDetails(false);
      setNotes(false);
      setAdditionalInfo(false);
      setQnA(false);
    }

    if (type === "details") {
      setDetails(true);
      setOverview(false);
      setNotes(false);
      setAdditionalInfo(false);
      setQnA(false);
    }
    if (type === "Notes") {
      setNotes(true);
      setDocument(false);
      setOverview(false);
      setDetails(false);
      setAdditionalInfo(false);
      setQnA(false);
    }

    if (type === "Document") {
      setDocument(true);
      setNotes(false);
      setOverview(false);
      setDetails(false);
      setAdditionalInfo(false);
      setQnA(false);
      // setJdDocument(true)
    }

    if (type === "QnA") {
      setDocument(false);
      setNotes(false);
      setOverview(false);
      setDetails(false);
      setAdditionalInfo(false);
      setQnA(true);
    }
  };

  const resetTabs = (type) => {
    if (type === "listview" && listview === true) {
      setlistview(false);
    }
    if (type === "gridview") {
      setgridview(false);
    }
    if (type === "tableview") {
      settableview(false);
    }
    if (type === "overview" && overview === true) {
      setOverview(false);
    }
    if (type === "details") {
      setDetails(false);
    }
    if (type === "activity") {
      setActivity(false);
    }
  };

  // source talent bulk pop up show
  const SourceTalentBulk = () => {
    setSourceTalentBulkButton(true);
  };

  // source talent bulk pop up close
  const SourceTalentBulkClose = () => {
    setSuccessFormBulkOpen(false);
    setSourceTalentBulkButton(false);
    setcheckedFn(false);
  };

  // source talent single pop up close
  const SourceTalentClose = () => {
    setSuccessFormOpen(false);
    setSourceTalentButton(false);
    setcheckedFn(false);
  };

  const DeleteRequestFun = () => {
    setDeleteRequestButton(true);
  };

  const DeleteRequestClose = (e) => {
    setDeleteRequestButton(false);
  };

  const PublicShare = () => {
    setPublicShareLinkButton(true);
  };

  const PublicShareLinkClose = (e) => {
    setPublicShareLinkButton(false);
  };

  const setshare = () => {
    setshareButton(true);
  };

  const shareClose = (e) => {
    setSuccessFormOpen(false);
    setshareButton(false);
  };

  const editRequest = () => {
    setedit(true);
  };

  const handleClose = (e) => {
    setedit(false);
  };

  const closeExportModalPopup = () => {
    setexportButton(false);
  };

  const openExportModalPopup = () => {
    setexportButton(true);
  };

  // Navbar toggle
  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  // bulk pop up handle change
  const handleAllChange = (e) => {
    const candidateTempList = candidateList.map((item) => {
      item.isSelect = e.target.checked;
      return item;
    });
    setCandidateList(candidateTempList);
    setcheckedFn(e.target.checked);
    setSelectedList(candidateTempList.filter((item) => item.isSelect === true));
  };

  // single pop up handle change
  const handleChange = (e, i) => {
    candidateList[i].isSelect = e.target.checked;
    setCandidateList(candidateList);
    setSelectedList(candidateList.filter((item) => item.isSelect === true));
  };

  const sortFn = () => {
    if (sortingPopUp === false) {
      setSorting(true);
    } else {
      setSorting(false);
    }
  };

  const addSorting = () => {
    setAddsorting(true);
    if (count !== 5) {
      count = count + 1;
      setcount(count);
    }
  };

  const clearAll = () => {
    setAddsorting(false);
    setcount(0);
  };

  const requestFilter = () => {
    if (filter === false) {
      setFilter(true);
    } else {
      setFilter(false);
    }
  };

  const fetchJdDocument = async (doc) => {
    setLoading(true);
    const jobId = props.match.params.Id;
    const payload = {
      jobId: jobId,
    };
    try {
      let res = await post("/request/JobDescription", payload);
      let docs = [...res.data];
      if (doc) {
        let docsList = JSON.parse(doc);
        let finalDoc = docs.map((i) => ({
          ...i,
          ...docsList.find((j) => j.documentName === i.fileName),
        }));
        docs = [...finalDoc];
      }
      setJdDocument(docs);
      console.log(docs);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      //toast.error('Some Error Occurred. Please Try Again')
    }
  };

  const successFormBulk = (payrateData) => {
    const htmlTag = sourceTalentBulkSuccess(selectedList, payrateData, jobData);
    setSuccessData({ ...successData, htmlTag: htmlTag });
    setSuccessFormBulkOpen(true);
  };

  const requestClose = () => {
    setFilter(false);
  };

  const closeModal = () => {
    setSuccessFormOpen(false);
  };

  const applySort = (sortedData, close) => {
    close && setSorting(false);
    setFilterData(sortedData);
  };

  const applyFilter = (
    data,
    selectedTitles,
    selectedLocations,
    vetted,
    lastUpdated,
    lastUpdatedType,
    createdBy,
    skills,
    skillSet
  ) => {
    setFilter(false);
    setVetting(vetted);
    setLastUpdatedDate(lastUpdated);
    setSelectedTitles(selectedTitles);
    setSelectedLocations(selectedLocations);
    setFilterData(data);
    setlastUpdatedType(lastUpdatedType);
    setSelectedSkills(skills);
    setSelectedSkillSet(skillSet);
    setSelectedCreatedBy(createdBy);
  };

  return (
    <>
      {/* {loading && <Loader />} */}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div class="body-decorator body-decorator-top"></div>
        <div class="body-decorator body-decorator-bottom"></div>
        <TopNavBar handleToggale={toggleDiv} />
        <SideNav />
        <div id="Content">
          <div class="hero">
            <div class="section pb-0">
              <div class="container-fluid">
                <div class="row justify-content-center mb-5">
                  <div class="col-xl-12">
                    <div class="text-center">
                      <div class="font-white">
                        <h4>{fullText.jobTitle}</h4>
                        <p>
                          {jobData.jobType}
                          {fullText.location.city && fullText.location.state
                            ? ` / ${fullText.location.city}, ${fullText.location.state}`
                            : fullText.location.state
                            ? ` / ${fullText.location.state}`
                            : fullText.location.city &&
                              ` / ${fullText.location.city}`}
                          <br />
                          {getUserTenant().slice(0, 6) === "GIG - " && (
                            <p className="mt-1">
                              Placement Fee: {jobData.placementFee}
                            </p>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-xl-12">
                    <div class="row">
                      <div className="col-xl-4">
                        <div className="hero-card-header card card-flat card-lg card-bottom-straight">
                          <div className="card-body pb-0">
                            <div className="d-flex align-items-start">
                              <div>
                                <p className="mb-0">Request ID</p>
                                <p>
                                  <strong>{jobData.jobId}</strong>
                                </p>
                              </div>
                              <div className="ml-auto">
                                <div
                                  className="dropdown px-1"
                                  style={{ fontSize: "17px" }}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Export"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <OverlayTrigger
                                    trigger="click"
                                    rootClose
                                    placement="bottom-end"
                                    overlay={
                                      <div
                                        className="dropdown-menu dropdown-menu-right"
                                        aria-labelledby="requestMoreMenu"
                                      >
                                        <button
                                          type="button"
                                          className="dropdown-item"
                                          onClick={() => {
                                            openExportModalPopup();
                                          }}
                                        >
                                          <i className="fal fa-file-pdf mr-3"></i>
                                          PDF
                                        </button>
                                      </div>
                                    }
                                  >
                                    <i
                                      aria-hidden="true"
                                      className="fal fa-file-export fa-fw"
                                    ></i>
                                  </OverlayTrigger>
                                </div>

                                <div
                                  className="dropdown px-1"
                                  style={{ fontSize: "17px" }}
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="top"
                                  title="Share"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <i
                                    className="fal fa-share-alt fa-fw-dropdown"
                                    onClick={() => {
                                      setshare();
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <hr className="m-0 hr-white" />
                            <ul
                              className="nav nav-tabs"
                              style={{ overflowX: "scroll" }}
                            >
                              <li className="nav-item">
                                <button
                                  type="button"
                                  className={
                                    overview
                                      ? "nav-link pb-3 active"
                                      : "nav-link pb-3"
                                  }
                                  onClick={() => {
                                    onTabClick("infoTabs", "overview");
                                  }}
                                >
                                  Overview
                                </button>
                              </li>
                              <li className="nav-item">
                                <button
                                  type="button"
                                  className={
                                    details
                                      ? "nav-link pb-3 active"
                                      : "nav-link pb-3"
                                  }
                                  onClick={() => {
                                    onTabClick("infoTabs", "details");
                                  }}
                                >
                                  Details
                                </button>
                              </li>
                              <li className="nav-item">
                                <button
                                  type="button"
                                  className={
                                    qnA
                                      ? "nav-link pb-3 active"
                                      : "nav-link pb-3"
                                  }
                                  onClick={() => {
                                    onTabClick("infoTabs", "QnA");
                                  }}
                                >
                                  Screening
                                </button>
                              </li>
                              <li className="nav-item">
                                <button
                                  type="button"
                                  className={
                                    notes
                                      ? "nav-link pb-3 active"
                                      : "nav-link pb-3"
                                  }
                                  onClick={() => {
                                    onTabClick("infoTabs", "Notes");
                                  }}
                                >
                                  Notes
                                </button>
                              </li>
                              <li className="nav-item">
                                <button
                                  type="button"
                                  className={
                                    document
                                      ? "nav-link pb-3 active"
                                      : "nav-link pb-3"
                                  }
                                  onClick={() => {
                                    onTabClick("infoTabs", "Document");
                                  }}
                                >
                                  Document
                                </button>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-xl-8 d-none-1200">
                        <div class="hero-card-header card card-lg card-flat card-bottom-straight">
                          <div class="card-body pb-0 d-flex flex-column justify-content-between">
                            <div className="d-flex">
                              <div>
                                <h6>Source Talent</h6>
                                <p className="mb-1">
                                  {candidateList.length} Talents
                                </p>
                                <small>
                                  Please note: The search result displays
                                  candidates matching to the submission
                                  parameter, rest will be ignored.
                                </small>
                              </div>
                              <button
                                type="button"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() =>
                                  window.open(
                                    `${window.location.origin}/high5Hire/requests/${jobData.jobId}`,
                                    "_self"
                                  )
                                }
                                class="close p-0 bl-modal-close-btn"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div class="d-flex justify-content-end w-100 justify-content-even-400">
                              <div class="dropdown-sort dd-sort">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-text-accent"
                                  onClick={() => {
                                    setSorting(true);
                                  }}
                                >
                                  <i className="fad fa-sort-down font-16 mr-1"></i>{" "}
                                  Sort / {sortCount}
                                </button>
                                {sortingPopUp && (
                                  <OutsideClickHandler
                                    onOutsideClick={() => setSorting(false)}
                                  >
                                    <Sort
                                      sortData={candidateList}
                                      orderArray={orderList}
                                      sortObjects={sortObject}
                                      applySort={applySort}
                                      setSortCount={setSortCount}
                                      sortCount={sortCount}
                                      setSortArray={setSortArray}
                                      sortArray={sortArray}
                                      sortingFor={
                                        "Sort by Name, Location, Created by, and Last updated"
                                      }
                                    />
                                  </OutsideClickHandler>
                                )}
                              </div>
                              <div class="dropdown position-relative">
                                <button
                                  data-bs-toggle="tooltip"
                                  data-bs-placement="left"
                                  title="Filter"
                                  onClick={requestFilter}
                                  type="button"
                                  class="btn btn-sm btn-text-accent w-auto"
                                >
                                  <i class="fas fa-filter"></i>
                                  <span>Filter</span> /
                                  <span>
                                    {" "}
                                    {selectedTitles.length +
                                      selectedLocations.length +
                                      (vetting ? 1 : 0) +
                                      (lastUpdatedDate[0] ? 1 : 0) +
                                      selectedCreatedBy.length +
                                      selectedSkills.length +
                                      selectedSkillSet.length}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pb-5">
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="col-xl-12 ">
                  <div class="row">
                    <div class="col-xl-4">
                      <RequestSideBar
                        overview={overview}
                        details={details}
                        activity={activity}
                        additionalInfo={additionalInfo}
                        jobData={jobData}
                        fullText={fullText}
                        intakeData={intakeData}
                        jdDocument={jdDocument}
                        notes={notes}
                        document={document}
                        qnA={qnA}
                        QandADetails={{
                          QandA: jobData.QandA ? JSON.parse(jobData.QandA) : [],
                          additionalInfo: jobData.additionalInfo
                            ? JSON.parse(jobData.additionalInfo)
                            : [],
                        }}
                      />
                    </div>
                    <div class="col-xl-8">
                      <div class="card card-lg card-flat card-bottom-straight d-none d-block-1200 mt-3">
                        <div class="card-body">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="px-1 mb-0">Talent / {totalCandidate}</h6>
                          </div>
                        </div>
                      </div>
                      <div
                        class="card card-flat card-lg card-top-straight"
                        style={{ "border-top": "none" }}
                      >
                        <div class="card-body">
                          {listview && !matches ? (
                            <SourceCandidateListView
                              arrayList={filterData}
                              handleChange={handleChange}
                              setSourceTalentButton={setSourceTalentButton}
                              setSouceCandidateData={setSouceCandidateData}
                              openSubmitModal={(candidate) => {
                                setCandidateDetails(candidate);
                                setSubmitModal(true);
                              }}
                              jobData={jobData}
                            />
                          ) : gridview || matches ? (
                            <SourceCandidateGridView
                              arrayList={filterData}
                              handleChange={handleChange}
                              setSourceTalentButton={setSourceTalentButton}
                              setSouceCandidateData={setSouceCandidateData}
                              openSubmitModal={(candidate) => {
                                setCandidateDetails(candidate);
                                setSubmitModal(true);
                              }}
                            />
                          ) : tableview ? (
                            <SourceCandidateTableView
                              arrayList={filterData}
                              handleChange={handleChange}
                              setSourceTalentButton={setSourceTalentButton}
                              setSouceCandidateData={setSouceCandidateData}
                              openSubmitModal={(candidate) => {
                                setCandidateDetails(candidate);
                                setSubmitModal(true);
                              }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <Modal show={editButton === true} onHide={handleClose} size="edit">

                        <div class="bl-modal-header">
                            <h6 class="bl-modal-title">{'Edit Request'}</h6>
                            <button type="button" data-dismiss="modal" aria-label="Close" onClick={handleClose} class="close p-0 bl-modal-close-btn"  >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <Modal.Body>
                            <EditRequest handleSuccess={handleClose} />
                        </Modal.Body>

                    </Modal> */}
          <Modal show={shareButton === true} onHide={shareClose} size="lg">
            <div className="bl-modal-header">
              {" "}
              <h5 className="bl-modal-title">{"Share"}</h5>
              <div className="bl-modal-header">
                <h6
                  className="bl-modal-title"
                  style={{ marginLeft: 20, justifyContent: "center" }}
                >
                  {"Public Share Link"}
                </h6>

                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={shareClose}
                  className="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Modal.Body>
                <PublicShareLink
                  handleSuccess={PublicShareLinkClose}
                  jobData={jobData}
                />

                <h6
                  className="bl-modal-title"
                  style={{
                    marginLeft: 30,
                    marginBottom: -10,
                    justifyContent: "center",
                  }}
                >
                  {"Share via Email"}
                </h6>
                {successFormOpen ? (
                  <FormSubmitSuccess
                    successData={successData}
                    closeModal={shareClose}
                  />
                ) : (
                  <ShareViaEmail
                    handleSuccess={shareClose}
                    jobData={jobData}
                    setSuccessFormOpen={setSuccessFormOpen}
                    handleSuccessForm={props.handleSuccessForm}
                  />
                )}
              </Modal.Body>
            </div>
          </Modal>

          <Modal
            show={DeleteRequestValue === true}
            onHide={DeleteRequestClose}
            size="delete"
          >
            <div class="bl-modal-header">
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={DeleteRequestClose}
                class="close p-0 bl-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Modal.Body>
              <DeleteRequest handleSuccess={DeleteRequestClose} />
            </Modal.Body>
          </Modal>

          <Modal show={submitModal} size="lg">
            <Modal.Header>
              <h6 class="">Source Talent</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => setSubmitModal(false)}
                class="close p-0 bl-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              {/* {
                                successFormOpen ?
                                    <FormSubmitSuccess
                                        successData={successData}
                                        closeModal={SourceTalentClose}
                                    /> :
                                    <SourceCandidatePopup
                                        handleClose={SourceTalentClose}
                                        checkedFn={checkedFn}
                                        currencyList={currencyList}
                                        candidate={souceCandidateData}
                                        jobData={jobData}
                                        successForm={successForm}
                                        setLoading={setLoading}
                                    />
                            } */}
              <div className="text-center mb-5">
                <Avatar
                  size="55"
                  name={
                    candidateDetails.firstName + " " + candidateDetails.lastName
                  }
                  round="80px"
                />
              </div>
              <div className="mx-3 my-4">
                <SubmitTalentModal
                  candidate={candidateDetails}
                  job={jobData}
                  closeModal={() => setSubmitModal(false)}
                  setScore={(job, talent) => {
                    setJobData(job);
                    setCandidateDetails(talent);
                    setSubmitModal(false);
                    setScore(true);
                  }}
                />
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            show={exportButton === true}
            onHide={closeExportModalPopup}
            size="lg"
          >
            <div className="bl-modal-header">
              <h6 className="bl-modal-title">{"Export"}</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeExportModalPopup}
                className="close p-0 bl-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <Modal.Body>
              <ExportJobDetails
                handleSuccess={closeExportModalPopup}
                jobData={jobData}
                fullText={fullText}
                tenant={tenant}
              />
            </Modal.Body>
          </Modal>

          <Modal show={score} size="lg">
            <Modal.Header>
              <h6 class="">Automatch Score Card</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setScore(false);
                  setSubmitModal(true);
                }}
                class="close p-0 bl-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="p-3">
                <AutoMatchScoreCard
                  jobData={jobData}
                  candidateData={candidateDetails}
                />
              </div>
            </Modal.Body>
            <div className="d-flex justify-content-end m-2">
              <button
                className="btn btn-secondary mr-1"
                onClick={() => {
                  setScore(false);
                  setSubmitModal(true);
                }}
              >
                Close
              </button>
            </div>
          </Modal>

          <Modal
            show={sourceTalentBulkValue === true}
            onHide={SourceTalentBulkClose}
            size="delete"
          >
            <div class="modal-content" style={{ width: "650px" }}>
              <div class="bl-modal-header">
                <h6 class="bl-modal-title">{"Source Talent"}</h6>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={SourceTalentBulkClose}
                  class="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Modal.Body>
                {successFormBulkOpen ? (
                  <FormSubmitSuccess
                    successData={successData}
                    closeModal={SourceTalentBulkClose}
                  />
                ) : (
                  <SourceCandidateBulkPopUp
                    handleClose={SourceTalentBulkClose}
                    candidateList={selectedList}
                    checkedFn={checkedFn}
                    currencyList={currencyList}
                    jobData={jobData}
                    successFormBulk={successFormBulk}
                    setLoading={setLoading}
                  />
                )}
              </Modal.Body>
            </div>
          </Modal>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
      {filter && (
        <TalentFilter
          candidateData={candidateList}
          candidateTitles={candidateTitles}
          candidateLocations={candidateLocations}
          selectedLocations={selectedLocations}
          selectedTitles={selectedTitles}
          vetting={vetting}
          candidateSkills={candidateSkills}
          selectedSkills={selectedSkills}
          candidateSkillSet={candidateSkillSet}
          selectedSkillSet={selectedSkillSet}
          createdBy={createdBy}
          selectedCreatedBy={selectedCreatedBy}
          lastUpdatedDate={lastUpdatedDate}
          lastUpdatedType={lastUpdatedType}
          applyFilter={applyFilter}
        />
      )}
    </>
  );
}

export default SourceTalent;
