import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import { useHistory } from "react-router";
import moment from "moment";
import { SidebarLogo } from "../../../../../constants/Constants";
import ProfileImage from "../../../commoncomponents/profileimage/ProfileImage";

const GridView = ({
  pool,
  setCurrentPage,
  currentPage,
  pageSize,
  setPageSize,
}) => {
  const history = useHistory();

  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    setPaginatedData(pool);
  }, [currentPage, pageSize, pool]);

  useEffect(() => {
    setPageSize(9);
  }, []);

  return (
    <>
      <div className="grid-container">
        {paginatedData && paginatedData?.length > 0 ? (
          paginatedData?.map((item, index) => {
            return (
              <>
                <Card
                  key={index}
                  style={{
                    width: "100%",
                  }}
                >
                  <Card.Body>
                    <Row className="d-flex justify-content-between">
                      <Col md={2} onSubmit={2}>
                        <Card.Title>
                          <img
                            src={item?.logourl || SidebarLogo}
                            width="75"
                            height="75"
                            className="p-2 ml-2 mt-2"
                            style={{
                              borderRadius: "50%",
                              objectFit: "contain",
                              boxShadow:
                                "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                            }}
                          />
                        </Card.Title>
                      </Col>
                      <Col md={9} sm={9}>
                        <Card.Title>
                          <span style={{ fontSize: "15px" }}>
                            <b>
                              <a
                                className="text-black"
                                href={`/high5hire/talentcommunity/pools/${item?.poolId}`}
                              >
                                <strong title="Community Name">
                                  {item?.poolname}
                                </strong>
                              </a>
                            </b>
                          </span>
                        </Card.Title>
                        <Card.Text>
                          <span
                            className="text-center"
                            style={{ fontSize: "13px", cursor: "default" }}
                            title="Location"
                          >
                            <IoLocationOutline
                              color="#009eff"
                              size={18}
                              className="mr-1"
                            />
                            {item?.country}
                          </span>
                        </Card.Text>

                        <Card.Text>
                          {item?.CountDetails?.totalCount == 0 ? (
                            <span className="">
                              <small>No talent pooled</small>
                            </span>
                          ) : (
                            <>
                              <div
                                class="font-small font-primary mb-2"
                                style={{ cursor: "default" }}
                              >
                                Talent(s)
                              </div>
                              <span className="">
                                <small
                                  title="Talent(s)"
                                  style={{ cursor: "default" }}
                                >
                                  {item?.candidateName
                                    ?.slice(0, 3)
                                    ?.map((obj, i) => (
                                      <div
                                        key={i}
                                        className="avatar-name-candidatestableview d-inline-flex align-items-center"
                                      >
                                        <span
                                          style={{
                                            display: "inline-block",
                                          }}
                                        >
                                          <ProfileImage
                                            imageSrc={obj.profileUrl}
                                            imageWidth={"35"}
                                            imageHeight={"35"}
                                            avatarSize={"35"}
                                            avatarRound={"80px"}
                                            firstName={obj.name.split(" ")[0]}
                                            lastName={obj?.name.split(" ")[1]}
                                          />
                                        </span>
                                      </div>
                                    ))}
                                  {item?.CountDetails?.totalCount > 3 && (
                                    <span className="">
                                      +
                                      {new Intl.NumberFormat().format(
                                        item?.CountDetails?.totalCount - 3
                                      )}
                                    </span>
                                  )}
                                </small>
                              </span>
                            </>
                          )}
                        </Card.Text>
                      </Col>
                    </Row>
                  </Card.Body>
                  <Card.Footer className="bg-white font-gray1">
                    <div className="row">
                      <div className="col-md-6" style={{ textAlign: "start" }}>
                        <span
                          style={{ fontSize: "13px", cursor: "default" }}
                          title="Created date"
                        >
                          Created on:{" "}
                          {moment(item?.createddatetime).format("MM/DD/YYYY")}
                        </span>
                      </div>
                      <div className="col-md-6" style={{ textAlign: "end" }}>
                        <span
                          style={{ fontSize: "13px", cursor: "default" }}
                          title="Created by"
                        >
                          Created by: {item?.poolowner}
                        </span>
                      </div>
                    </div>
                  </Card.Footer>

                  <div
                    className="row d-flex flex-inline mt-0 w-100"
                    style={{ marginLeft: "0.5px" }}
                  >
                    <div
                      className="text-center py-1 col-md-6"
                      style={{
                        backgroundColor:
                          item?.CountDetails?.AutomatchCount > 0
                            ? "#229954"
                            : "#ccc",
                        color:
                          item?.CountDetails?.AutomatchCount > 0
                            ? "white"
                            : "#000",
                        borderBottomLeftRadius: "6px",
                        borderBottomRightRadius: "6px",
                        cursor: "pointer",
                        fontSize: "12px",
                      }}
                      onClick={(e) => {
                        history.push(
                          `/high5hire/talentcommunity/pools/${item?.poolId}/38`
                        );
                      }}
                    >
                      {item?.CountDetails?.AutomatchCount === 0
                        ? "No New Automatched"
                        : item?.CountDetails?.AutomatchCount +
                          " New Automatched"}
                    </div>

                    <div
                      className="p-0 m-0 col-md-6 text-center py-1"
                      style={{
                        backgroundColor:
                          item?.CountDetails?.EngagedCount > 0
                            ? "#AED6F1"
                            : "#ccc",
                        color: "#000",
                        borderBottomLeftRadius: "6px",
                        borderBottomRightRadius: "6px",
                        fontSize: "12px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => {
                        history.push(
                          `/high5hire/talentcommunity/pools/${item?.poolId}/42`
                        );
                      }}
                    >
                      {item?.CountDetails?.EngagedCount &&
                      item?.CountDetails?.EngagedCount === 0
                        ? "No New Engaged"
                        : item?.CountDetails?.EngagedCount + " New Engaged"}
                    </div>
                  </div>
                </Card>
              </>
            );
          })
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default GridView;
