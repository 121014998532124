import React, { useState } from "react";

function CandidateInterviewDetails(props) {
  let details = props.interviewdetails;

  const [skill] = useState(["CSS", "HTML"]);
  const [education] = useState([
    "High School Degree",
    "Bachelors In Software Related Degrees",
  ]);
  const [certifications] = useState([
    "Java Masters Course",
    "Toast Masters",
    "PMP",
  ]);
  const [industry] = useState(["Aviation", "Big Data"]);

  const [interviewDetails, setInterviewDetails] = useState(false);
  const [jobDetails, setJobDetails] = useState(false);
  const [requiredSkills, setRequiredSkills] = useState(false);
  const [description, setDescription] = useState(false);
  const [educationTab, setEducationTab] = useState(false);
  const [certificationsTab, setCertificationTab] = useState(false);
  const [travel, setTravel] = useState(false);
  const [drugTest, setDrugtest] = useState(false);
  const [backgroundCheck, setBackgroundCheck] = useState(false);
  const [securityClearance, setSecrityClearance] = useState(false);
  const [industryDetails, setIndustryDetails] = useState(false);
  const [workHours, setWorkhours] = useState(false);

  const onCollapsibleClick = (type) => {
    if (type === "interviewdetails") {
      if (interviewDetails === false) {
        setInterviewDetails(true);
      } else {
        setInterviewDetails(false);
      }
    }

    if (type === "job") {
      if (jobDetails === false) {
        setJobDetails(true);
      } else {
        setJobDetails(false);
      }
    }

    if (type === "recSkills") {
      if (requiredSkills === false) {
        setRequiredSkills(true);
      } else {
        setRequiredSkills(false);
      }
    }

    if (type === "description") {
      if (description === false) {
        setDescription(true);
      } else {
        setDescription(false);
      }
    }

    if (type === "education") {
      if (educationTab === false) {
        setEducationTab(true);
      } else {
        setEducationTab(false);
      }
    }

    if (type === "certification") {
      if (certificationsTab === false) {
        setCertificationTab(true);
      } else {
        setCertificationTab(false);
      }
    }

    if (type === "industries") {
      if (industryDetails === false) {
        setIndustryDetails(true);
      } else {
        setIndustryDetails(false);
      }
    }

    if (type === "workHr") {
      if (workHours === false) {
        setWorkhours(true);
      } else {
        setWorkhours(false);
      }
    }

    if (type === "travel") {
      if (travel === false) {
        setTravel(true);
      } else {
        setTravel(false);
      }
    }

    if (type === "drugTest") {
      if (drugTest === false) {
        setDrugtest(true);
      } else {
        setDrugtest(false);
      }
    }

    if (type === "backgroundcheck") {
      if (backgroundCheck === false) {
        setBackgroundCheck(true);
      } else {
        setBackgroundCheck(false);
      }
    }

    if (type === "security") {
      if (securityClearance === false) {
        setSecrityClearance(true);
      } else {
        setSecrityClearance(false);
      }
    }
  };

  const close = () => {
    props.closeModal(true);
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header">
          <div className="p-relative">
            <div className="px-3 py-2 d-flex">
              <button onClick={close} type="button" className="btn btn-icon">
                <i className="fal fa-times" aria-hidden="true"></i>
              </button>
              <div className="px-1 py-2text-center font-regular p-relative ml-auto w-50">
                <small>
                  {" "}
                  Interview Scheduled for{" "}
                  {new Date(details.interviewDate).toLocaleString()}
                </small>
              </div>
            </div>
          </div>

          <div className="px-3 mb-3">
            <div className="d-flex align-items-start flex-wrap-reverse">
              <div className="d-flex ">
                <div className="ml-3">
                  <h5 className="mr-2 mb-1">{details.title}</h5>
                  {/* <div className="mb-2">
                    <span className="mr-2">
                      {props.interviewdetails.type} /{" "}
                      {props.interviewdetails.location}
                    </span>
                  </div> */}
                  <div>
                    <span className="tag tag-orange1">
                      <strong>Job Interview</strong>
                    </span>
                  </div>
                </div>
              </div>
              {/* <div
                v-if="!item.showStatusProgress"
                className="ml-auto d-flex align-items-center"
              >
                <div>
                  <button
                    v-if="item.rejecting === false && item.rejected === false"
                    type="button"
                    // v-on:click.stop.prevent="parent.scheduleInterview(item)"
                    className="btn btn-sm mr-1"
                  >
                    Schedule
                  </button>
                  <button
                    // v-bind:className="{'bg-blue5': item.rejecting === true || item.rejected === true}"
                    // v-on:click.stop.prevent="parent.rejectInterview(item)"
                    // :disabled="item.rejecting === true || item.rejected === true"
                    type="button"
                    className="btn btn-secondary btn-sm btn-blue-disabled"
                  >
                    <span v-if="item.rejecting === false && item.rejected === false">
                      Reject
                    </span>
                    <img
                      v-if="item.rejecting"
                      style={{ width: "18px; height: 18px;" }}
                      src="/images/button-loader-white.gif"
                    />
                    <i
                      v-if="item.rejected"
                      className="fas fa-check-circle fa-fw font-18 mt-1 font-white"
                    ></i>
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="view-panel-body">
          {/* <div v-if="item.statusMsg.length">
            <label className="pt-0">Add To</label>
            <div className="mt-2 mb-3 d-flex justify-content-between">
              <a
                href="#top"
                target="_blank"
                className="btn btn-sm btn-secondary w-100"
              >
                Google Calendar
              </a>
              &nbsp;&nbsp;
              <a href="#top" className="btn btn-sm btn-secondary w-100">
                Outlook Calendar (.ics)
              </a>
              &nbsp;&nbsp;
              <a
                href="#top"
                target="_blank"
                className="btn btn-sm btn-secondary w-100"
              >
                Yahoo Calendar
              </a>
            </div>
            <div className="mb-2">
              <button
                onClick={() => {
                  onCollapsibleClick("interviewdetails");
                }}
                type="button"
                className="btn btn-collapsible"
              >
                {interviewDetails === false ? (
                  <i className="fal fa-angle-down fa-fw"></i>
                ) : (
                  <i  className="fal fa-angle-up fa-fw"></i>
                )}
                <span>
                  <small>Interview Details</small>
                </span>
              </button>
            </div>

            <div
              className={
                interviewDetails === true ? "mb-4 ml-4" : "mb-4 ml-4 d-none"
              }
            >
              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fad fa-fw fa-alarm-clock"></i>
                </div>
                <div className="ml-2">
                  <p className=" mb-0">Time</p>
                  <p className="mb-0">
                    <small>{new Date(details.interviewDate).toLocaleString()}</small>
                  </p>
                </div>
              </div>

              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fad fa-fw fa-map"></i>
                </div>
                <div className="ml-2">
                  <p className=" mb-0">Location</p>
                  <p className="mb-0">
                    <small>1450 Duval St. Jacksonville FL. 32323</small>
                  </p>
                </div>
              </div>

              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fad fa-fw fa-feather"></i>
                </div>
                <div className="ml-2">
                  <p className=" mb-0">Instructions</p>
                  <p className="mb-0">
                    <small>
                      Please be ready to present on your portolio of work
                    </small>
                  </p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="mb-2">
            <button
              onClick={() => {
                onCollapsibleClick("job");
              }}
              type="button"
              className="btn btn-collapsible"
            >
              {jobDetails === false ? (
                <i className="fal fa-angle-down fa-fw"></i>
              ) : (
                <i className="fal fa-angle-up fa-fw"></i>
              )}
              <span>
                <small>Job Details</small>
              </span>
            </button>
          </div>

          <div
            className={jobDetails === true ? "mb-4 ml-4" : "mb-4 ml-4 d-none"}
          >
            <div className="d-flex mb-2 pl-1">
              <div>
                <i className="fad fa-fw fa-user"></i>
              </div>
              <div className="ml-2">
                <p className=" mb-0">Chuck Young @eTeam</p>
                <p className="mb-0">
                  <small>Requested By</small>
                </p>
              </div>
            </div>
            <div className="d-flex mb-2 pl-1">
              <div>
                <i className="fad fa-fw fa-piggy-bank"></i>
              </div>
              <div className="ml-2">
                <p className=" mb-0">Full Time</p>
                <p className="mb-0">
                  <small>Job Type</small>
                </p>
              </div>
            </div>

            <div className="d-flex mb-2 pl-1">
              <div>
                <i className="fad fa-fw fa-calendar-day"></i>
              </div>
              <div className="ml-2">
                <p className="mb-0">11/01/2021</p>
                <p className="mb-0">
                  <small>Target Start</small>
                </p>
              </div>
            </div>

            <div className="d-flex mb-2 pl-1">
              <div>
                <i className="fad fa-fw fa-map-marked-alt"></i>
              </div>
              <div className="ml-2">
                <p className=" mb-0">Jacksoville Fl.</p>
                <p className="mb-0">
                  <small>Location</small>
                </p>
              </div>
            </div>

            <div className="d-flex mb-2 pl-1">
              <div>
                <i className="fad fa-fw fa-piggy-bank"></i>
              </div>
              <div className="ml-2">
                <p className=" mb-0">$80K - $90K</p>
                <p className="mb-0">
                  <small>Maximum Annual Salary</small>
                </p>
              </div>
            </div>
          </div>

          <div className="mb-2">
            <button
              // v-on:click="onCollapsibleClick('skills')"
              onClick={() => {
                onCollapsibleClick("recSkills");
              }}
              type="button"
              className="btn btn-collapsible"
            >
              {!requiredSkills ? (
                <i className="fal fa-angle-down fa-fw"></i>
              ) : (
                <i className="fal fa-angle-up fa-fw"></i>
              )}
              <span>
                <small>Required Skills</small>
              </span>
            </button>
          </div>
          <div
            className={
              requiredSkills ? "mb-4 ml-4 pl-1" : "mb-4 ml-4 pl-1 d-none"
            }
          >
            {skill.map((data) => (
              <span
                className="tag tag-blue3 mb-1 mr-1"
                v-for="(skill, i) in skills"
              >
                {data}
              </span>
            ))}
          </div>

          <div className="mb-2">
            <button
              // v-on:click="onCollapsibleClick('description')"
              onClick={() => {
                onCollapsibleClick("description");
              }}
              type="button"
              className="btn btn-collapsible"
            >
              {!description ? (
                <i
                  v-if="!collapsible.description"
                  className="fal fa-angle-down fa-fw"
                ></i>
              ) : (
                <i className="fal fa-angle-up fa-fw"></i>
              )}

              <span>
                <small>Description</small>
              </span>
            </button>
          </div>

          <div
            //  v-bind:className="{'d -none': collapsible.description}"
            className={
              description
                ? "list-style-regular ml-4 pl-1 collapsible-overflow"
                : "list-style-regular ml-4 pl-1 collapsible-overflow d-none"
            }
            v-html="description"
          ></div>

          <div className="mb-2">
            <button
              // v-on:click="onCollapsibleClick('education')"
              onClick={() => {
                onCollapsibleClick("education");
              }}
              type="button"
              className="btn btn-collapsible"
            >
              {!educationTab ? (
                <i className="fal fa-angle-down fa-fw"></i>
              ) : (
                <i className="fal fa-angle-up fa-fw"></i>
              )}

              <span>
                <small>Education</small>
              </span>
            </button>
          </div>
          <div className={educationTab ? "mb-3 ml-4" : "mb-3 ml-4 d-none"}>
            {education.map((data) => (
              <span
                className="tag tag-blue3 mb-1 mr-1"
                v-for="(text, i) in educations"
              >
                {data}
              </span>
            ))}
          </div>
          <div className="mb-2">
            <button
              // v-on:click="onCollapsibleClick('certifications')"
              onClick={() => {
                onCollapsibleClick("certifications");
              }}
              type="button"
              className="btn btn-collapsible"
            >
              {!certificationsTab ? (
                <i
                  v-if="!collapsible.certifications"
                  className="fal fa-angle-down fa-fw"
                ></i>
              ) : (
                <i className="fal fa-angle-up fa-fw"></i>
              )}

              <span>
                <small>Certifications</small>
              </span>
            </button>
          </div>
          <div className={certificationsTab ? "mb-3 ml-4" : "mb-3 ml-4 d-none"}>
            {certifications.map((data) => (
              <span
                className="tag tag-blue3 mb-1 mr-1"
                v-for="(text, i) in certifications"
              >
                {data}
              </span>
            ))}
          </div>
          <div className="mb-2">
            <button
              onClick={() => {
                onCollapsibleClick("industries");
              }}
              type="button"
              className="btn btn-collapsible"
            >
              {!industryDetails ? (
                <i
                  v-if="!collapsible.industries"
                  className="fal fa-angle-down fa-fw"
                ></i>
              ) : (
                <i className="fal fa-angle-up fa-fw"></i>
              )}

              <span>
                <small>Industry</small>
              </span>
            </button>
          </div>
          <div className={industryDetails ? "mb-3 ml-4" : "mb-3 ml-4 d-none"}>
            {industry.map((data) => (
              <span
                className="tag tag-blue3 mb-1 mr-1"
                v-for="(text, i) in industries"
              >
                {data}
              </span>
            ))}
          </div>
          <div className="mb-2">
            <button
              // v-on:click="onCollapsibleClick('workHours')"
              onClick={() => {
                onCollapsibleClick("workHr");
              }}
              type="button"
              className="btn btn-collapsible"
            >
              {!workHours ? (
                <i
                  v-if="!collapsible.workHours"
                  className="fal fa-angle-down fa-fw"
                ></i>
              ) : (
                <i className="fal fa-angle-up fa-fw"></i>
              )}

              <span>
                <small>Work Hours</small>
              </span>
            </button>
          </div>
          <div className={workHours ? "mb-3 ml-4" : "mb-3 ml-4 d-none"}>
            <p>{"Flexible"}</p>
          </div>
          <div className="mb-2">
            <button
              // v-on:click="onCollapsibleClick('travel')"
              onClick={() => {
                onCollapsibleClick("travel");
              }}
              type="button"
              className="btn btn-collapsible"
            >
              {!travel ? (
                <i className="fal fa-angle-down fa-fw"></i>
              ) : (
                <i className="fal fa-angle-up fa-fw"></i>
              )}
              <span>
                <small>Travel</small>
              </span>
            </button>
          </div>
          <div className={travel ? "mb-3 ml-4" : "mb-3 ml-4 d-none"}>
            <p>{"15%"}</p>
          </div>
          <div className="mb-2">
            <button
              // v-on:click="onCollapsibleClick('drugTest')"
              onClick={() => {
                onCollapsibleClick("drugTest");
              }}
              type="button"
              className="btn btn-collapsible"
            >
              {!drugTest ? (
                <i
                  v-if="!collapsible.drugTest"
                  className="fal fa-angle-down fa-fw"
                ></i>
              ) : (
                <i className="fal fa-angle-up fa-fw"></i>
              )}
              <span>
                <small>Drug Test</small>
              </span>
            </button>
          </div>
          <div className={drugTest ? "mb-3 ml-4" : "mb-3 ml-4 d-none"}>
            <span v-if="drugTest" className="tag tag-green2">
              <i className="far fa-check mr-2"></i>Yes
            </span>
            {/* <span >No</span> */}
          </div>
          <div className="mb-2">
            <button
              // v-on:click="onCollapsibleClick('backgroundCheck')"
              onClick={() => {
                onCollapsibleClick("backgroundcheck");
              }}
              type="button"
              className="btn btn-collapsible"
            >
              {!backgroundCheck ? (
                <i className="fal fa-angle-down fa-fw"></i>
              ) : (
                <i className="fal fa-angle-up fa-fw"></i>
              )}

              <span>
                <small>Background Check</small>
              </span>
            </button>
          </div>
          <div className={backgroundCheck ? "mb-3 ml-4" : "mb-3 ml-4 d-none"}>
            <span v-if="drugTest" className="tag tag-green2">
              <i className="far fa-check mr-2"></i>Yes
            </span>
            {/* <span >No</span> */}
          </div>
          <div className="mb-2">
            <button
              // v-on:click="onCollapsibleClick('securityClearance')"
              onClick={() => {
                onCollapsibleClick("security");
              }}
              type="button"
              className="btn btn-collapsible"
            >
              {!securityClearance ? (
                <i className="fal fa-angle-down fa-fw"></i>
              ) : (
                <i className="fal fa-angle-up fa-fw"></i>
              )}

              <span>
                <small>Security Clearance Level</small>
              </span>
            </button>
          </div>
          <div className={securityClearance ? "mb-3 ml-4" : "mb-3 ml-4 d-none"}>
            <p>{"confidential"}</p>
          </div>
        </div>
      </div>
    </>
  );
}
export default CandidateInterviewDetails;
