import React, { useState, useEffect } from "react";
import CandidateAssesmentListView from "../assessment/CandidateAssessmentListView";
import CandidateAssesmentGridView from "../assessment/CandidateAssessmentGridView";
import CandidateAssesmentTableView from "../assessment/CandidateAssessmentTableView";
import SelfAssessmentPopUp from "./SelfAssessmentPopUp";
import TopNavBar from "../../layout/TopNavBar";
import SideNav from "../../layout/SideNavBar";
import Modal from "react-bootstrap/Modal";
import Loader from "../../Loader/Loader";
import { get, post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";

function CandateAssessmentList() {
  const [listView, setListView] = useState(true);
  const [gridView, setGridview] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [openSelfAssessment, setOpenSelfAssessment] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [mcqDetails, setMcqDetails] = useState([]);
  const [courseDetails, setCourseDetails] = useState([]);
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rejectIndex, setRejectIndex] = useState("");
  const [tovutiId, setTovutiId] = useState("");
  const [assessmentList] = useState([
    {
      difficulty: "1 Way Video",
      title: "User Centered Design",
      duration: "2hr",
      lastUpdated: "2",
      status: "inprogress",
      allStatus: "all",
    },
    {
      difficulty: "MCQ",
      title: "React Developer II",
      duration: "6hr",
      lastUpdated: "5",
      status: "completed",
      allStatus: "all",
    },
    {
      difficulty: "1 Way Video",
      title: "Webpack and Modules",
      duration: "2hr",
      lastUpdated: "2",
      status: "completed",
      allStatus: "all",
    },
    {
      difficulty: "1 Way Video",
      title: "Full Stack Engineer Tools",
      duration: "2hr",
      lastUpdated: "2",
      status: "notstarted",
      allStatus: "all",
    },
    {
      difficulty: "1 Way Video",
      title: "Designer Thinking",
      duration: "2hr",
      lastUpdated: "2",
      status: "inprogress",
      allStatus: "all",
    },
    {
      difficulty: "MCQ",
      title: "Hadoop",
      duration: "6hr",
      lastUpdated: "5",
      status: "inprogress",
      allStatus: "all",
    },

    {
      difficulty: "1 Way Video",
      title: "User Centered Design",
      duration: "2hr",
      lastUpdated: "2",
      status: "inprogress",
      allStatus: "all",
    },
    {
      difficulty: "1 Way Video",
      title: "Full Stack Engineer Tools",
      duration: "2hr",
      lastUpdated: "2",
      status: "inprogress",
      allStatus: "all",
    },
    {
      difficulty: "2 Way Video",
      title: "Scrum Master 101",
      duration: "6hr",
      lastUpdated: "5",
      status: "completed",
      allStatus: "all",
    },
    {
      difficulty: "MCQ",
      title: "User Centered Design",
      duration: "6hr",
      lastUpdated: "5",
      status: "completed",
      allStatus: "all",
    },

    {
      difficulty: "2 Way Video",
      title: "HTML 101",
      duration: "6hr",
      lastUpdated: "5",
      status: "inprogress",
      allStatus: "all",
    },
    {
      difficulty: "2 Way Video",
      title: "Product Strategies",
      duration: "6hr",
      lastUpdated: "5",
      status: "inprogress",
      allStatus: "all",
    },
    {
      difficulty: "2 Way Video",
      title: "Webpack and Modules",
      duration: "3hr",
      lastUpdated: "2",
      status: "notstarted",
      allStatus: "all",
    },
    {
      difficulty: "MCQ",
      title: "Project Manager Tools",
      duration: "6hr",
      lastUpdated: "5",
      status: "notstarted",
      allStatus: "all",
    },
    {
      difficulty: "MCQ",
      title: "Design Principals",
      duration: "6hr",
      lastUpdated: "5",
      status: "inprogress",
      allStatus: "all",
    },
    {
      difficulty: "MCQ",
      title: "Agile Development",
      duration: "6hr",
      lastUpdated: "5",
      status: "notstarted",
      allStatus: "all",
    },
    {
      difficulty: "2 Way Video",
      title: "OOP Principals",
      duration: "3hr",
      lastUpdated: "2",
      status: "notstarted",
      allStatus: "all",
    },
  ]);
  const [codingTests, setCodingTests] = useState([]);

  const [allTabView, setAllTabView] = useState(true);
  const [assignedTabView, setAssignedTabView] = useState(false);
  const [inprogressTabView, setInProgressTabView] = useState(false);
  const [completedTabView, setCompletedTabView] = useState(false);

  const getSectionOfList = (array, property, value) => {
    var list = [];
    for (var i = 0; i < array.length; i++) {
      if (array[i][property] === value) {
        list.push(array[i]);
      }
      if (i === array.length - 1) {
        return list;
      }
    }
  };

  let newApplicationsSourced;
  newApplicationsSourced = getSectionOfList(assessmentList, "allStatus", "all");

  const fetchAssessments = async () => {
    setLoading(true);
    try {
      const res = await get(`/CandidateDashboard/getModernHire`);
      setAssessmentDetails(res.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // toast.error('Failed to get Assessments')
    }
  };

  const fetchCodingTests = async () => {
    setLoading(true);
    try {
      const res = await get(`/CandidateDashboard/getCodingTests`);
      setCodingTests(res.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // toast.error('Failed to get Assessments')
    }
  };

  const fetchTovutiId = async () => {
    setLoading(true);
    try {
      let res = await get("/candidateProfile/getTovutiUserFromIndex");
      if (res.status === 200) {
        if (res.data.length > 0) {
          fetchTovutiProgress(res.data[0].id);
        }
      } else {
        toast.error("Some Error Occurred while Fetching TovutiID");
      }
    } catch (err) {
      toast.error("Failed to get TovutiID");
    }
  };

  const fetchTovutiProgress = async (id) => {
    setLoading(true);
    try {
      const res = await post("/tovuti/getUsersTovutiCourses", {
        userId: id,
      });
      setCourseDetails(res.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Failed to get Assessments");
    }
  };

  useEffect(() => {
    fetchAssessments();
    fetchCodingTests();
  }, []);

  // useEffect(() => {
  //   tovutiId && fetchTovutiProgress()
  // }, [tovutiId])

  let [dataArray, setDataArray] = useState(newApplicationsSourced);

  const setStatusView = (type) => {
    if (type === "all") {
      //  newApplicationsSourced = getSectionOfList(
      //   assessmentList,
      //   "allStatus",
      //   "all"
      // );
      // setDataArray(newApplicationsSourced);

      setAllTabView(true);
      setAssignedTabView(false);
      // setInProgressTabView(false);
      setCompletedTabView(false);
    }

    if (type === "notstarted") {
      //  newApplicationsSourced = getSectionOfList(
      //   assessmentList,
      //   "status",
      //   "notstarted"
      // );
      // setDataArray(newApplicationsSourced);
      setAssignedTabView(true);
      setAllTabView(false);
      setInProgressTabView(false);
      setCompletedTabView(false);
    }

    if (type === "inprogress") {
      newApplicationsSourced = getSectionOfList(
        assessmentList,
        "status",
        "inprogress"
      );
      setDataArray(newApplicationsSourced);
      if (inprogressTabView === false) {
        setInProgressTabView(true);
      } else {
        setInProgressTabView(false);
      }
      setAllTabView(false);
      setAssignedTabView(false);
      setCompletedTabView(false);
    }

    if (type === "completed") {
      //  newApplicationsSourced = getSectionOfList(
      //   assessmentList,
      //   "status",
      //   "completed"
      // );
      // setDataArray(newApplicationsSourced);
      setCompletedTabView(true);
      setAssignedTabView(false);
      setAllTabView(false);
    }
  };

  const toggleView = (type) => {
    if (type === "list") {
      if (listView === false) {
        setListView(true);
      } else {
        setListView(false);
      }
      setGridview(false);
      setTableView(false);
    }

    if (type === "grid") {
      if (gridView === false) {
        setGridview(true);
      } else {
        setGridview(false);
      }
      setListView(false);
      setTableView(false);
    }

    if (type === "table") {
      if (tableView === false) {
        setTableView(true);
      } else {
        setTableView(false);
      }
      setListView(false);
      setGridview(false);
    }
  };

  const assessmentHide = () => {
    setOpenSelfAssessment(false);
  };

  const rejectMCQ = async (userId, id_candidatecourse, index) => {
    setLoading(true);
    const res = await post("/CandidateDashboard/rejectMCQ", {
      userId,
      id_candidatecourse,
    });
    if (res.status === 200) {
      setLoading(false);
      toast.success("MCQ Rejected Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      setLoading(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  const rejectOneWay = async (id_InterviewApplication, index) => {
    setLoading(true);
    const res = await post("/CandidateDashboard/rejectOneWay", {
      id_InterviewApplication,
    });
    if (res.status === 200) {
      setLoading(false);
      toast.success("OneWay Video Interview Rejected Successfully");
      setTimeout(() => {
        window.location.reload();
      }, 5000);
    } else {
      setLoading(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <body
          className={toggle ? "layout1 layout1-closed close-menu" : "layout1"}
        >
          <div class="body-decorator body-decorator-top"></div>
          <div class="body-decorator body-decorator-bottom"></div>
          <TopNavBar handleToggale={() => setToggle(!toggle)} />
          <SideNav />
          <div id="Content">
            <div id="AssessmentsApp" class="section section-sm">
              <div class="hero">
                <div class="section pt-5">
                  <div class="container">
                    <div class="row justify-content-center mb-5">
                      <div class="d-flex justify-content-between align-items-center col-10 font-white">
                        <h5 class="mb-0">Assessments</h5>
                        {/* <div>
                          <button
                            onClick={openSelfAssessmentPopUp}
                            type="button"
                            class="btn btn-sm btn-light"
                          >
                            <i class="fal fa-plus mr-1"></i> Take Self
                            Assessment
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-10">
                    <div class="card translate-up-100">
                      <div class="card-body">
                        <div class="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end mb-3 justify-content-between border-bottom-gray2">
                          <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                            <div class="d-none d-xl-flex nav nav-tabs justify-content-between">
                              <div class="nav-item d-flex justify-content-center">
                                <button
                                  onClick={() => {
                                    toggleView("list");
                                  }}
                                  type="button"
                                  class="nav-link pb-3 bg-transparent"
                                  className={
                                    listView
                                      ? "nav-link pb-3 bg-transparent"
                                      : "nav-link pb-3 bg-transparent"
                                  }
                                >
                                  {/* <i class="fas fa-list"></i> */}
                                </button>
                              </div>
                              <div class="nav-item d-flex justify-content-center">
                                <button
                                  onClick={() => {
                                    toggleView("grid");
                                  }}
                                  type="button"
                                  className={
                                    gridView
                                      ? "nav-link pb-3 bg-transparent active"
                                      : "nav-link pb-3 bg-transparent"
                                  }
                                >
                                  {/* <i class="fas fa-th-large"></i> */}
                                </button>
                              </div>
                              <div class="nav-item d-flex justify-content-center">
                                <button
                                  onClick={() => {
                                    toggleView("table");
                                  }}
                                  type="button"
                                  className={
                                    tableView
                                      ? "nav-link pb-3 bg-transparent active"
                                      : "nav-link pb-3 bg-transparent"
                                  }
                                >
                                  {/* <i class="fas fa-table"></i> */}
                                </button>
                              </div>
                            </div>
                          </div>
                          <ul class="text-center nav nav-tabs nav-tabs-tablet pt-1 justify-content-center justify-content-lg-start">
                            <li class="nav-item">
                              <button
                                type="button"
                                onClick={() => {
                                  setStatusView("all");
                                }}
                                className={
                                  allTabView
                                    ? "nav-link pb-3 mx-2 bg-transparent active"
                                    : "nav-link pb-3 mx-2 bg-transparent"
                                }
                              >
                                <span class="tag mb-1">
                                  {assessmentDetails.length +
                                    codingTests.length}
                                </span>
                                <div>All</div>
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                type="button"
                                className={
                                  assignedTabView
                                    ? "nav-link pb-3 mx-2 bg-transparent active"
                                    : "nav-link pb-3 mx-2 bg-transparent"
                                }
                                onClick={() => {
                                  setStatusView("notstarted");
                                }}
                              >
                                <span class="tag mb-1">
                                  {assessmentDetails.filter(
                                    (i) => i.Status !== "Completed"
                                  ).length +
                                    codingTests.filter(
                                      (i) => i.Status !== "Completed"
                                    ).length}
                                </span>
                                <div>Assigned</div>
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                type="button"
                                onClick={() => {
                                  setStatusView("completed");
                                }}
                                className={
                                  completedTabView
                                    ? "nav-link pb-3 mx-2 bg-transparent active"
                                    : "nav-link pb-3 mx-2 bg-transparent"
                                }
                              >
                                <span class="tag mb-1">
                                  {assessmentDetails.filter(
                                    (a) => a.Status === "Completed"
                                  ).length +
                                    codingTests.filter(
                                      (a) => a.Status === "Completed"
                                    ).length}
                                </span>
                                <div>Completed</div>
                              </button>
                            </li>
                          </ul>
                          <div class="my-2 d-none d-block-1200"></div>
                          <div class="d-flex mb-2 align-items-center justify-content-center justify-content-even-400">
                            <div class="d-flex justify-content-end w-100 justify-content-even-400"></div>
                          </div>
                        </div>
                        {listView && (
                          <CandidateAssesmentListView
                            assessmentDetails={assessmentDetails.filter(
                              (i) => i.Status !== "Completed"
                            )}
                            completed1Way={assessmentDetails.filter(
                              (i) => i.Status === "Completed"
                            )}
                            codingTests={codingTests}
                            all={allTabView}
                            completed={completedTabView}
                            assigned={assignedTabView}
                            loading={loading}
                          />
                        )}

                        {gridView && (
                          <CandidateAssesmentGridView
                            assessmentList={dataArray}
                          />
                        )}

                        {tableView && (
                          <CandidateAssesmentTableView
                            assessmentList={dataArray}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {getTenantType() == 1 && <Footer />}
          </div>
        </body>
        <Modal show={openSelfAssessment === true}>
          <SelfAssessmentPopUp hideAssessment={assessmentHide} />
        </Modal>
      </div>
    </>
  );
}

export default CandateAssessmentList;
