import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const GET_JOB_LIST_BEGIN = "GET_JOB_LIST_BEGIN";
export const GET_JOB_LIST_SUCCESS = "GET_JOB_LIST_SUCCESS";
export const GET_JOB_LIST_FAILURE = "GET_JOB_LIST_FAILURE";

export const GET_COPY_CLONE_JOB_LIST_BEGIN = "GET_COPY_CLONE_JOB_LIST_BEGIN";
export const GET_COPY_CLONE_JOB_LIST_SUCCESS =
  "GET_JCOPY_CLONE_OB_LIST_SUCCESS";
export const GET_COPY_CLONE_JOB_LIST_FAILURE =
  "GET_COPY_CLONE_JOB_LIST_FAILURE";

export const GET_JOB_LIST_HM_BEGIN = "GET_JOB_LIST_HM_BEGIN";
export const GET_JOB_LIST_HM_SUCCESS = "GET_JOB_LIST_HM_SUCCESS";
export const GET_JOB_LIST_HM_FAILURE = "GET_JOB_LIST_HM_FAILURE";

export const getJobList = (param) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_LIST_BEGIN,
    });
    const res = await post("/job/getJobListDataAll", param);
    if (res.status === 200) {
      dispatch({
        type: GET_JOB_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOB_LIST_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getCopyCloneJobList = (param) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COPY_CLONE_JOB_LIST_BEGIN,
    });
    const res = await post("/job/getJobListDataAll", param);
    if (res.status === 200) {
      dispatch({
        type: GET_COPY_CLONE_JOB_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_COPY_CLONE_JOB_LIST_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getJobListHM = (param) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_LIST_HM_BEGIN,
    });
    const res = await post("/job/getJobListHM", param);
    if (res.status === 200) {
      dispatch({
        type: GET_JOB_LIST_HM_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOB_LIST_HM_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getAllJobs = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_LIST_BEGIN,
    });
    const res = await get("/job/getAllJobs");
    if (res.status === 200) {
      dispatch({
        type: GET_JOB_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOB_LIST_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

// export const getJobListCandidate = (params) => async (dispatch) => {
//   try {
//     dispatch({
//       type: GET_JOB_LIST_BEGIN,
//     });
//     const res = await post("/job/getjoblist", params);
//     if (res.status === 200) {
//       dispatch({
//         type: GET_JOB_LIST_SUCCESS,
//         payload: res.data,
//       });
//     }
//   } catch (error) {
//     dispatch({
//       type: GET_JOB_LIST_FAILURE,
//       payload: error?.response?.data,
//     });
//     toast.error(error?.response?.data?.errMessage);
//   }
// };
