import React, { useState } from "react";
import { post } from '../../../service/ApiService';
import { getCandidateName } from '../../../service/AuthService'
import { domain } from "../../../constants/Constants"

function SourceCandidateBulkPopUp(props) {

    const [payrateData, setPayRateData] = useState({
        payrate: '',
        payrateCurrency: ''
    });
    const [validation, setValidation] = useState({
        payrate: '',
        payrateCurrency: ''
    })

    const close = () => {
        props.handleClose(true)
    }

    const handleChange = e => {
        setPayRateData({ ...payrateData, [e.target.id]: e.target.value })
    }

    const sendRtrApi = async () => {
        const payload = {
            candidateList: props.candidateList,
            jobData: props.jobData,
            payrate: payrateData.payrate,
            payrateCurrency: payrateData.payrateCurrency,
            recruiterName: getCandidateName(),
            domain: domain
        }

        let res = await post('/sourcecandidate/sendrtr', payload)
        return (res)
    }

    const handleSubmit = async () => {
        let payrateError = ''; let payrateCurrencyError = '';
        if (payrateData.payrate.length === 0) {
            payrateError = '*Required Field'
        }
        if (payrateData.payrateCurrency.length === 0) {
            payrateCurrencyError = '*Required Field'
        }
        if (payrateError || payrateCurrencyError) {
            setValidation({ ...validation, payrate: payrateError, payrateCurrency: payrateCurrencyError })
        } else {
            props.setLoading(true)
            setValidation({
                payrate: '',
                payrateCurrency: ''
            })
            const response = await sendRtrApi()
            props.setLoading(false)
            if (response.status === 200) {
                props.successFormBulk(payrateData)
            }
        }
    }

    return (
        <>
            <div>
                <div>
                    <div class="text-center">
                        <h6><small>For: {props.jobData.fullText.jobTitle}</small></h6>
                        <p>
                            {
                                props.jobData.fullText.location.city !== '' && props.jobData.fullText.location.state !== '' ? (
                                    `${props.jobData.fullText.location.city}, ${props.jobData.fullText.location.state}`
                                ) : props.jobData.fullText.location.cityName === '' ? (
                                    `${props.jobData.fullText.location.state}`
                                ) : (
                                    `${props.jobData.fullText.location.city}`
                                )
                            }
                            {
                                props.jobData.jobType.length !== 0 && ` /  ${props.jobData.jobType}`
                            }
                        </p>
                    </div>
                </div>
                <div class="bl-modal-body">
                    <div class="card card-flat">
                        <div class="card-body">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Talent Name</th>
                                        <th>Title</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.candidateList.map((candidate) => (
                                            <tr>
                                                <td>{candidate.fullText.firstName} {candidate.fullText.middleName} {candidate.fullText.lastName}</td>
                                                <td>{candidate.designation}</td>
                                            </tr>

                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="bl-modal-body pt-0">
                    <div class="d-flex">
                        <div class="form-floating w-100">
                            <input type="number" min="1" class="form-control" placeholder="Enter Payrate" id="payrate" style={{ border: validation.payrate && '2px solid #ff0000' }} value={payrateData.payrate} onChange={handleChange} />
                            <label >Pay Rate*</label>
                            <small className="validation">{validation.payrate}</small>
                        </div>
                        <div class="form-floating w-100 ml-1">
                            <select class="form-select" id="payrateCurrency" style={{ border: validation.payrateCurrency && '2px solid #ff0000' }} value={payrateData.payrateCurrency} onChange={handleChange}>
                                <option value>Select</option>
                                {
                                    props.currencyList.map((item) => (
                                        <option value={item.CurrencyNameTime}>{item.CurrencyNameTime}</option>
                                    ))
                                }
                            </select>
                            <label>Currency *</label>
                            <small className="validation">{validation.payrateCurrency}</small>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" onClick={close}>Cancel</button>
                    <button type="button" class="btn btn-primary btn-sm" onClick={handleSubmit}>
                        <span>Submit Talent</span>
                    </button>
                </div>
            </div>
        </>
    );
}

export default SourceCandidateBulkPopUp;
