import React, { useState } from "react"
import { ButtonLoader, domain } from "../../../constants/Constants"
import { toast } from 'react-toastify'
import { post } from '../../../service/ApiService'
import { getCandidateId } from "../../../service/AuthService"

function RecruiterStoryInfo({ role, storyinfo }) {
  const [readOnlyMode, setReadOnlyMode] = useState(true)
  const [submittingForm, setSubmittingFrom] = useState(false)
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [saveClicked, setSaveClicked] = useState(true)
  const [ bio, setBio ] = useState(storyinfo)
  const [ temoBio, setTempBio ] = useState(storyinfo)

  const editStoryInfo = () => {
    setReadOnlyMode(false)
    setSaveClicked(true)
  }

  const cancelStoryInfo = () => {
    setBio(temoBio)
    setReadOnlyMode(true)
    setShowSuccessMessage(false)
    setSubmittingFrom(false)
    setSaveClicked(true)
  }

  const saveStoryInfo = async () => {
    setTempBio(bio)
    setSaveClicked(false)
    setSubmittingFrom(true)
    try {
      const parameters = {
          candidateID: getCandidateId(),
          clickName: 'storyinfos',
          userStoryInfo: {
            storyInfo: bio,
            domainName: domain
          }
        }
        // console.log('Parameters', parameters)
        const res = await post('/candidateProfile/postStoryInfo', parameters)
        // console.log(res)
        if (res.status === 200) {
        setSubmittingFrom(false)
        setShowSuccessMessage(true)      
          setTimeout(() => { 
            setReadOnlyMode(true)
            setShowSuccessMessage(false)
          }, 1000)
        }
    } catch (error) {
        toast.error("Error Occured")
    }
  }
  
  return (
    <>
      <div>
        <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
          <div class="card card-lg">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between">
                <h6 class="mb-0">Story Info</h6>
                {readOnlyMode ? (
                  <button
                    type="button"
                    class="btn btn-text"
                    onClick={editStoryInfo}
                  >
                    <i class="fas fa-pencil-alt mr-1"></i>
                    Edit
                  </button>
                ) : (
                  <div>                  
                    <button
                      type="button"
                      onClick={cancelStoryInfo}
                      class="btn btn-sm btn-secondary mr-1"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={saveStoryInfo}
                      class="btn btn-sm btn-primary"
                    >
                      {saveClicked && <span>Save Changes</span>}

                      {submittingForm && (
                        <span>
                          Saving Changes
                          <img
                            width="20px"
                            src={ButtonLoader}
                            alt=""
                          />
                        </span>
                      )}
                      {showSuccessMessage && <span>Changes Saved!</span>}
                    </button>
                  </div>
                )}
              </div>
              <hr />
              <div class="col-lg-12 col-md-12 col-sm-12">
                {
                  role === 'Recruiter' &&
                  <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                      <div class="form-floating mb-1 ">
                          <select
                          class="form-control"
                          aria-describedby="RecruiterPosition"
                          >
                          <option value="">Select Position</option>
                          <option value="Contract">Contract</option>
                          <option value="Temp To Hire">Temp To Hire</option>
                          <option value="Full Time">Full Time</option>
                          <option value="Part Time">Part Time</option>
                          <option value="Volunteer">Volunteer</option>
                          </select>
                          <label>Position Type</label>
                      </div>
                  </div>
                }    
                {
                  readOnlyMode ? <p className="m-2">{bio ? bio : <span>Story Info Not Yet Added. Please update it by clicking <strong>Edit</strong> Button Above</span>}</p> :
                  <div class="col-lg-12">
                    {/* <label>My Story</label> */}
                    <div class="form-floating mt-2">
                      <textarea
                        class="form-control"                     
                        placeholder="Type Here"
                        value={bio}
                        onChange={(e) => setBio(e.target.value)}
                      >                     
                      </textarea>
                    </div>
                  </div>
                }                       
                
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  )
}
export default RecruiterStoryInfo
