import React from "react";
import { toast } from "react-toastify";
import { emailDomain } from '../../../../constants/Constants'


function PublicShareLink(props) {

    const jobId = props.jobData.jobId;
    let sendMailLink = `${emailDomain}/sharejob/Id:${jobId}`;

    const handleCopy = (e) => {
        navigator.clipboard.writeText(sendMailLink)
        toast.success('Link Copied Successfully!!!')
    }

    return (
        <>
            <div id="showPublicShareLink">
                <div className="bl-modal-body pt-0">
                    <div className="input-group input-group-append mb-1">
                        <input type="text" className="form-control" value={sendMailLink} disabled placeholder="Copy Share Link" aria-label="Copy Share Link" aria-describedby="button-addon2" />
                        <button className="btn btn-secondary" onClick={(e) => handleCopy()} type="button" id="button-addon2">Copy</button>
                    </div>
                    <div className="text-center animate animate-in">
                        <div className="tag d-block tag-green1">
                            Link Copied!
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PublicShareLink;
