import React, { useState } from "react";
import { getInitial } from "../../function/CommonFunction";
import { post } from '../../../service/ApiService';
import { getCandidateName } from '../../../service/AuthService'
import { domain } from "../../../constants/Constants"
// import '../../../sass/_action-panel.scss'

function SourceCandidatePopup(props) {

    const[payrateData, setPayRateData] = useState({
        payrate: '',
        payrateCurrency: ''
    });
    const[validation, setValidation] = useState({
        payrate: '',
        payrateCurrency: ''
    })

    const close = () => {
        props.handleClose(true)
    }

    const handleChange = e => {
        setPayRateData({ ...payrateData, [e.target.id]: e.target.value })
    }

    const sendRtrApi = async() => {
        const payload = {
            candidateList: [props.candidate],
            jobData: props.jobData,
            payrate: payrateData.payrate,
            payrateCurrency: payrateData.payrateCurrency,
            recruiterName: getCandidateName(),
            domain: domain
        }
        let res = await post('/sourcecandidate/sendrtr', payload)
        return(res)
    }

    const handleSubmit = async() => {
        let payrateError = ''; let payrateCurrencyError = '';
        if(payrateData.payrate.length ===0 ) {
            payrateError = '*Required Field'
        }
        if (payrateData.payrateCurrency.length === 0) {
            payrateCurrencyError = '*Required Field'
        }
        if(payrateError || payrateCurrencyError) {
            setValidation({ ...validation, payrate: payrateError, payrateCurrency: payrateCurrencyError })
        } else {
            props.setLoading(true)
            setValidation({
                payrate: '',
                payrateCurrency: ''
            })
            const response = await sendRtrApi()
            props.setLoading(false)
            if (response.status === 200) {
                props.successForm(payrateData)
            }
        }
    }

    return (
        <>
            <div>
                <div>
                    <div class="text-center">
                        {
                            props.candidate.ImageURL ? (
                                <span class="avatar avatar-bordered">
                                    <img class="avatar avatar-lg" src="candidate.ImageURL" alt='' />
                                </span>
                            ) : (
                                <span class="avatar avatar-bordered avatar-purple1">
                                    {getInitial(`${props.candidate.fullText.firstName} ${props.candidate.fullText.lastName}`)}
                                </span>
                            )
                        }
                    </div>
                </div>
                <div>
                    <div class="text-center">
                        <h6 class="mt-3">{props.candidate.fullText.firstName} {props.candidate.fullText.middleName} {props.candidate.fullText.lastName}</h6>
                        <h6><small>For: {props.jobData.fullText.jobTitle}</small></h6>
                        <p>
                            {
                                props.jobData.fullText.location.city !== '' && props.jobData.fullText.location.state !== '' ? (
                                    `${props.jobData.fullText.location.city}, ${props.jobData.fullText.location.state}`
                                ) : props.jobData.fullText.location.cityName === '' ? (
                                    `${props.jobData.fullText.location.state}`
                                ) : (
                                    `${props.jobData.fullText.location.city}`
                                )
                            }
                            {
                                props.jobData.jobType.length !== 0 && ` /  ${props.jobData.jobType}`
                            }
                        </p>
                    </div>
                </div>
                <div class="bl-modal-body pt-0">
                    <div class="d-flex">
                        <div class="form-floating w-100">
                            <input type="number" min="1" class="form-control" placeholder="Enter Payrate" id="payrate" style={{ border: validation.payrate && '2px solid #ff0000' }} value={payrateData.payrate} onChange={handleChange}/>
                            <label >Pay Rate*</label>
                            <small className="validation">{validation.payrate}</small>
                        </div>
                        <div class="form-floating w-100 ml-1">
                            <select class="form-select" id="payrateCurrency" style={{ border: validation.payrateCurrency && '2px solid #ff0000' }} value={payrateData.payrateCurrency} onChange={handleChange}>
                                <option value>Select</option>
                                {
                                    props.currencyList.map((item)=> (
                                        <option value={item.CurrencyNameTime}>{item.CurrencyNameTime}</option>
                                    ))
                                }
                            </select>
                            <label>Currency *</label>
                            <small className="validation">{validation.payrate}</small>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary btn-sm" data-dismiss="modal" onClick={close}>Cancel</button>
                    <button type="button" class="btn btn-primary btn-sm" onClick={handleSubmit}>
                        <span>Submit Talent</span>
                    </button>
                </div>
            </div>
        </>
    );
}

export default SourceCandidatePopup;
