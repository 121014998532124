import React from "react";
import TagsInput from "react-tagsinput";
import SkillsSearchable from "../../commoncomponents/skills/SkillsSearchable";

const TagsInputComponent = (props) => {
  const {} = props;
  return (
    <>
      <div
        class="row "
        style={{
          fontSize: "15px",
          margin: "0 1px 0 1px",
          border:
            props.isFrom === "JoinCommunity"
              ? props.isFormValid &&
                props.profileInfoValidation &&
                props.primarySkills.length == 0 &&
                "2px solid #ff0000"
              : props.profileInfoValidation && "2px solid #ff0000",
          borderRadius: "5px",
        }}
      >
        {/* <TagsInput
          renderInput={props.renderInput}
          value={props.value}
          onChange={props.onChange}
          inputValue={props.inputValue}
          onChangeInput={props.onChangeInput}
          placeholder={props.placeholder}
          inputProps={props.inputProps}
        /> */}

        <SkillsSearchable
          onChange={props.onChange}
          inputArr={props.value}
          skillinput={props.inputValue}
          onChangeInput={props.onChangeInput}
          inputProps={props.inputProps}
        />
      </div>
    </>
  );
};
export default TagsInputComponent;
