import {
    GET_TOVUTI_BEGIN,
    GET_TOVUTI_SUCCESS,
    GET_TOVUTI_FAILURE,
} from '../../actions/tovuti/tovuti';

const initialState = {
    loading: false,
    tovutiUserDetails: null,
    tovutiErrors: null,
};

const tovutiReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_TOVUTI_BEGIN:
            return {
                ...state,
                loading: true,
                tovutiUserDetails: null
            };
        case GET_TOVUTI_SUCCESS:
            localStorage.setItem('tovutiUsers', JSON.stringify(payload))
            return {
                ...state,
                tovutiUserDetails: payload,
                loading: false
            };
        case GET_TOVUTI_FAILURE:
            return {
                ...state,
                tovutiErrors: payload,
                loading: false
            };
        default:
            return state;
    }
}

export default tovutiReducer