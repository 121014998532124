import React, { Fragment, useEffect, useState } from "react";
import Validator from "email-validator";
import { toast } from "react-toastify";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  LoginAppLogo,
  domain,
  redirectTologinRtR,
} from "../../constants/Constants";
import { commonLogin } from "../../actions/auth/Login";
import { useHistory } from "react-router-dom";
import Event from '../../googleanalytic/Tracking'

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    password: "",
    domain: "",
    redirectTologinRtR: "",
  });
  const { userName, password } = formData;
  const params = { userName, password, domain, redirectTologinRtR };
  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // Get data from reducers
  const { loading } = useSelector(
    (state) => ({
      loading: state.Login.loading,
    }),
    shallowEqual
  );
  const showPasswordClick = () => {
    setShowPassword(!showPassword);
  };
  // Call Action Login
  const onSubmit = async (e) => {
    e.preventDefault();
    if (!Validator.validate(userName)) {
      toast.error("Please Enter Valid Email");
    } else {
      dispatch(commonLogin(params, history));
    }
  };

  return (
    <Fragment>
      <div id="Login" className="login-layout">
        <div className="col-left d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <a className="logo" href="/">
                  <img src={LoginAppLogo} alt="Login logo" />
                </a>
                <div className="section section-sm">
                  <h1>Sign In.</h1>
                  <p>Welcome! Sign in to get started.</p>
                  <form className="login-form" onSubmit={(e) => onSubmit(e)}>
                    <div className="form-floating mb-2">
                      <input
                        type="email"
                        className="form-control"
                        name="userName"
                        aria-describedby="emailHelp"
                        placeholder="Enter email"
                        value={userName}
                        onChange={(e) => onChange(e)}
                        autoComplete="new-userName"
                      />
                      <label>Email Address</label>
                      <input type="hidden" name="domain" value={domain} />
                      <input
                        type="hidden"
                        name="redirectTologinRtR"
                        value={redirectTologinRtR}
                      />
                    </div>
                    <div className="form-floating mb-2 form-password">
                      <input
                        type={showPassword ? "text" : "password"}
                        ondrop="return false"
                        className="form-control"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onCopy={(e) => {
                          e.preventDefault();
                          return false;
                        }}
                        onChange={(e) => onChange(e)}
                        autoComplete="new-password"
                      />
                      <label>Password</label>
                      {password.length > 0 && (
                        <button
                          //   v-on:click="revealPassword('security')"
                          onClick={showPasswordClick}
                          type="button"
                          className="btn btn-sm btn-text btn-text-accent h-100"
                        >
                          <i
                            className={
                              showPassword ? "fa fa-eye" : "fa fa-eye-slash"
                            }
                            aria-hidden="true"
                          ></i>
                        </button>
                      )}
                    </div>
                    <p className="text-right mb-4">
                      <a href="/forgot-password">Forgot Password?</a>
                    </p>
                    <button
                      type="submit"
                      className="btn btn-dark w-100"
                      disabled={loading}
                      onClick={() => Event('Login' , 'Login button clicked ')}
                    >
                      Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-right"></div>
      </div>
    </Fragment>
  );
}

export default Login;
