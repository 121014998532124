import React, { useEffect, useState } from "react";

import moment from "moment";
import { toast } from "react-toastify";
import { areAllKeysEmpty } from "../Validations/ApplyJobValidations";

function Experience(props) {
  const {
    experiences,
    setExperiences,

    setExperience,
    experienceValidation,
    setExperienceValidation,
    dropDownData,
  } = props;
  const inputDataObj = {
    employerName: "",
    industry: "",
    jobTitle: "",
    startDate: "",
    endDate: "",
    description: "",
    isSelect: false,
  };

  const [temp, setTemp] = useState([inputDataObj]);
  const [invalid, setInvalid] = useState(false);
  useEffect(() => {
    experiences.length > 0 && setTemp(experiences);
  }, [experiences]);

  const inputValObj = {
    employerName: "",
    industry: "",
    jobTitle: "",
    startDate: "",
    endDate: "",
  };

  const [hasShownError, setHasShownError] = useState(false);
  const handleChange = (e, i, startDate) => {
    setHasShownError(false);
    if (e.target.id === "isSelect") {
      let newArray = temp.map((item) => {
        item.isSelect = false;
        return item;
      });
      newArray = [...newArray];
      newArray[i] = {
        ...newArray[i],
        [e.target.id]: e.target.checked,
        endDate: "",
      };
      setTemp(newArray);
    } else if (e.target.id === "startDate") {
      let newArray = [...temp];
      newArray[i] = {
        ...newArray[i],
        [e.target.id]: e.target.value,
        endDate: "",
      };
      setTemp(newArray);
    } else if (
      !hasShownError &&
      e.target.id === "endDate" &&
      startDate === ""
    ) {
      setHasShownError(true);
      return toast.error("Please Choose Start Date before choosing End Date");
    } else if (
      !hasShownError &&
      e.target.id === "endDate" &&
      e.target.value === startDate
    ) {
      setHasShownError(true);
      return toast.error("End Date and Start Date Cannot be the Same");
    } else if (
      !hasShownError &&
      e.target.id === "endDate" &&
      e.target.value < startDate
    ) {
      setHasShownError(true);
      return toast.error("End Date Cannot Be Less Than Start Date");
    } else {
      let newArray = [...temp];
      newArray[i] = { ...newArray[i], [e.target.id]: e.target.value };
      setTemp(newArray);
    }
  };

  // Delete Experence
  const handleDelete = (e, i) => {
    setTemp(temp.filter((item, index) => index !== i));
    setExperienceValidation(
      experienceValidation.filter((item, index) => index !== i)
    );
  };

  // Add Experence
  const handleAdd = () => {
    setTemp([...temp, inputDataObj]);
    setExperienceValidation([...experienceValidation, inputValObj]);
  };
  const handleDone = () => {
    const hasError = temp.some(
      (item) => item.startDate && !item.endDate && !item.isSelect
    );

    if (hasError) {
      // Display a toast error message.\
      setInvalid(true);
      toast.error("Please select end date");
    } else {
      let ex = temp.filter((x) => !areAllKeysEmpty(x));

      setExperiences(ex.length > 0 ? ex : [inputDataObj]);
      setExperience(false);
    }
  };

  return (
    <div>
      <div class="work-experience">
        {temp.map((experience, i) => (
          <div class={"d-flex gap-2 px-3"} v-for="item in list">
            <div class="">
              <div class="avatar avatar-sm">{i + 1}</div>
            </div>
            <div className="w-100">
              <div class="row mb-3">
                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Employer Name"
                      id="employerName"
                      style={{
                        border:
                          experienceValidation[i].employerName &&
                          "2px solid #ff0000",
                      }}
                      value={experience.employerName}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <label>Employer name</label>
                  </div>
                  <small className="validation">
                    {experienceValidation[i].employerName}
                  </small>
                </div>
                <div class="col-lg-6">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="industry"
                      style={{
                        border:
                          experienceValidation[i].industry &&
                          "2px solid #ff0000",
                      }}
                      value={experience.industry}
                      onChange={(e) => handleChange(e, i)}
                    >
                      <option value="" selected>
                        Select Industry
                      </option>
                      {dropDownData?.skillSet
                        ?.sort((a, b) => (a.SourceName > b.SourceName ? 1 : -1))
                        .map((item) => (
                          <option value={item.SourceName}>
                            {item.SourceName}
                          </option>
                        ))}
                    </select>
                    <label>Industry</label>
                  </div>
                  <small className="validation">
                    {experienceValidation[i].industry}
                  </small>
                </div>
              </div>

              <div class="row mb-3">
                <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Job Title"
                      id="jobTitle"
                      style={{
                        border:
                          experienceValidation[i].jobTitle &&
                          "2px solid #ff0000",
                      }}
                      value={experience.jobTitle}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <label>Job Title</label>
                  </div>
                  <small className="validation">
                    {experienceValidation[i].jobTitle}
                  </small>
                </div>
                <div class="col-lg-4">
                  <div class="form-floating form-date">
                    <label>Start Date</label>
                    <input
                      type="date"
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      class="form-control"
                      placeholder="Start Date"
                      id="startDate"
                      style={{
                        border:
                          experienceValidation[i].startDate &&
                          "2px solid #ff0000",
                      }}
                      value={experience.startDate}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </div>
                  <small className="validation">
                    {experienceValidation[i].startDate}
                  </small>
                </div>
                <div class="col-lg-4">
                  <div class="form-floating form-date">
                    <label>End Date</label>
                    <input
                      type="date"
                      min={moment(
                        new Date(experience?.startDate).setDate(
                          new Date(experience?.startDate).getDate() + 1
                        )
                      ).format("YYYY-MM-DD")}
                      max={moment(new Date()).format("YYYY-MM-DD")}
                      disabled={!experience?.startDate || experience.isSelect}
                      class="form-control"
                      placeholder="End Date"
                      id="endDate"
                      style={{
                        border:
                          experienceValidation[i].endDate ||
                          (experience?.startDate &&
                            !experience.isSelect &&
                            !experience.endDate &&
                            invalid &&
                            "2px solid #ff0000"),
                      }}
                      value={experience.endDate}
                      onChange={(e) => handleChange(e, i, experience.startDate)}
                    />
                  </div>
                  <small className="validation">
                    {experienceValidation[i].endDate}
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-floating">
                    <textarea
                      class="form-control"
                      placeholder="Experience Description"
                      id="description"
                      value={experience.description}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <label>Experience Description</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 d-flex align-items-center">
                <div className="mr-2 mt-3" style={{ width: "134px" }}>
                  <p>Current Employer </p>
                </div>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="isSelect"
                    checked={experience.isSelect}
                    onChange={(e) => handleChange(e, i)}
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12" style={{ marginTop: "-25px" }}>
                  <hr />
                </div>
              </div>
            </div>
            <div class="">
              {temp.length !== 1 && (
                <button
                  type="button"
                  class="btn btn-icon"
                  onClick={(e) => handleDelete(e, i)}
                >
                  <i class="fal fa-fw fa-trash-alt"></i>
                </button>
              )}
            </div>
          </div>
        ))}

        <div>
          <button type="button" onClick={handleAdd} class="btn btn-sm btn-text">
            <i class="fal fa-plus mr-1" aria-hidden="true"></i>
            <strong>Add Experience</strong>
          </button>
        </div>
      </div>
      <hr />
      <div className="row mx-auto my-3 ">
        <div className="col-md-12 d-flex gap-2 justify-content-end">
          <button
            className={`btn btn-sm ${"btn-secondary"} `}
            onClick={() => {
              setExperience(false);
            }}
          >
            Cancel
          </button>
          <button
            className={`btn btn-sm ${"btn-primary"} `}
            onClick={handleDone}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
}
export default Experience;
