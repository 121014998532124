import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { FaPencilAlt, FaInbox, FaPaperPlane, FaEnvelope } from "react-icons/fa"; // Import the icons

const LeftSideMenu = ({
  onSelectOption,
  getAllSentEmails,
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className="menu-container mt-3">
      <div className="menu-options-row">
        <div
          className={`menu-option ${activeTab === "compose" ? "active" : ""}`}
          onClick={() => {
            onSelectOption("compose");
            setActiveTab("compose");
          }}
          style={{ fontSize: "14px" }}
        >
          <FaPencilAlt /> Compose New Mail
        </div>
        {/* <div
          className={`menu-option ${activeTab === "inbox" ? "active" : ""}`}
          onClick={() => {
            onSelectOption("inbox");
            setActiveTab("inbox");
          }}
        >
          <FaInbox /> <small>Inbox</small>
        </div> */}
        <div
          className={`menu-option ${activeTab === "sent" ? "active" : ""}`}
          onClick={() => {
            onSelectOption("sent");
            setActiveTab("sent");
            getAllSentEmails();
          }}
          style={{ fontSize: "14px" }}
        >
          <FaPaperPlane /> Sent
        </div>
        <div
          className={`menu-option ${activeTab === "templates" ? "active" : ""}`}
          onClick={() => {
            onSelectOption("templates");
            setActiveTab("templates");
          }}
          style={{ fontSize: "14px" }}
        >
          <FaEnvelope /> Templates
        </div>
      </div>
    </div>
  );
};

export default LeftSideMenu;
