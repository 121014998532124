import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const GET_EMAIL_DATA_BEGIN = "GET_EMAIL_DATA_BEGIN";
export const GET_EMAIL_DATA_SUCCESS = "GET_EMAIL_DATA_SUCCESS";
export const GET_EMAIL_DATA_FAILURE = "GET_EMAIL_DATA_FAILURE";

export const UPDATE_EMAIL_DATA_BEGIN = "UPDATE_EMAIL_DATA_BEGIN";
export const UPDATE_EMAIL_DATA_SUCCESS = "UPDATE_EMAIL_DATA_SUCCESS";
export const UPDATE_EMAIL_DATA_FAILURE = "UPDATE_EMAIL_DATA_FAILURE";

export const TEST_MAIL_BEGIN = "TEST_MAIL_BEGIN";
export const TEST_MAIL_SUCCESS = "TEST_MAIL_SUCCESS";
export const TEST_MAIL_FAILURE = "TEST_MAIL_FAILURE";

export const ACTIVATE_EMAIL_BEGIN = "ACTIVATE_EMAIL_BEGIN";
export const ACTIVATE_EMAIL_SUCCESS = "ACTIVATE_EMAIL_SUCCESS";
export const ACTIVATE_EMAIL_FAILURE = "ACTIVATE_EMAIL_FAILURE";

export const getEmailData = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_EMAIL_DATA_BEGIN,
    });
    const res = await get("/emailtemplate");
    if (res.status === 200) {
      dispatch({
        type: GET_EMAIL_DATA_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_EMAIL_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const updateEmailTemplate = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_EMAIL_DATA_BEGIN,
    });
    const res = await post("/emailtemplate/update", data);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_EMAIL_DATA_SUCCESS,
        payload: res.data,
      });

      toast.success("Email Template updated successfully!");
    }
  } catch (error) {
    dispatch({
      type: UPDATE_EMAIL_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const testMail = (data) => async (dispatch) => {
  try {
    dispatch({
      type: TEST_MAIL_BEGIN,
    });
    const res = await post("/emailtemplate/test_mail", data);
    if (res.status === 200) {
      dispatch({
        type: TEST_MAIL_SUCCESS,
        payload: res.data,
      });
      toast.success("Email Sent Successfully!");
    }
  } catch (error) {
    dispatch({
      type: TEST_MAIL_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const activateEmailTemplate = (data) => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVATE_EMAIL_BEGIN,
    });
    const res = await post("/emailtemplate/activate", data);
    if (res.status === 200) {
      dispatch({
        type: ACTIVATE_EMAIL_SUCCESS,
        payload: res.data,
      });
      if (data.status === "Active") {
        toast.success("Email Template activated successfully!");
      } else {
        toast.success("Email Template paused successfully!");
      }
    }
  } catch (error) {
    dispatch({
      type: ACTIVATE_EMAIL_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
