import moment from "moment";
import React, { useState } from "react";
// import ReactApexChart from "react-apexcharts";
import Avatar from "react-avatar";
import { Card, Col, Row, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaPaperPlane, FaPencilAlt } from "react-icons/fa";
import { MdOutgoingMail } from "react-icons/md";
import { MdMarkEmailRead } from "react-icons/md";
import { RiMailCloseFill } from "react-icons/ri";
import { IoMdMailOpen } from "react-icons/io";
import ModalView from "../../../../commoncomponents/modal/Modal";
import ProfileImage from "../../../../commoncomponents/profileimage/ProfileImage";

const EmailOverview = ({ emailOverViewInfo, recentEmailList, setMethod }) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const [openFullViewEmail, setOpenFullViewEmail] = useState(false);
  const [selectedMail, setSelectedMail] = useState({});

  const renderTooltip = (item) => (
    <Tooltip
      id="question-tooltip"
      style={{ fontSize: "14px", maxWidth: "400px", padding: "5px" }}
    >
      {item}
    </Tooltip>
  );

  // const options = {
  //   xaxis: {
  //     categories: [],
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   chart: {
  //     type: "line",
  //     zoom: {
  //       enabled: false,
  //     },
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "straight",
  //     width: 2,
  //   },

  //   title: {
  //     text: "Email Delivery Rate",
  //     align: "left",
  //     fontFamily: '"Noto Sans", "Arial", sans-serif',
  //   },
  //   grid: {
  //     row: {
  //       colors: ["#ffff", "transparent"], // alternating row colors
  //       opacity: 0.5,
  //     },
  //   },
  //   xaxis: {
  //     categories: [
  //       "Monday",
  //       "Tuesday",
  //       "Wednesday",
  //       "Thursday",
  //       "Friday",
  //       "Saturday",
  //       "Sunday",
  //     ],
  //     labels: {
  //       styles: {
  //         fontFamily: '"Noto Sans", "Arial", sans-serif',
  //       },
  //     },
  //   },
  // };

  // const series = [
  //   {
  //     name: "Emails",
  //     data: [30, 40, 35, 50, 49, 60, 70],
  //     color: "#0D6EFD",
  //   },
  // ];
  const handleRowToggle = (rowIndex) => {
    if (expandedRow === rowIndex) {
      setExpandedRow(null);
    } else {
      setExpandedRow(rowIndex);
    }
  };
  // const secondOptions = {
  //   chart: {
  //     type: "line",
  //     height: 250,
  //     toolbar: {
  //       show: true,
  //     },
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   title: {
  //     text: "Open and Click Rates by Month",
  //     align: "left",
  //   },
  //   xaxis: {
  //     categories: [
  //       "Jan",
  //       "Feb",
  //       "Mar",
  //       "Apr",
  //       "May",
  //       "Jun",
  //       "Jul",
  //       "Aug",
  //       "Sep",
  //       "Oct",
  //       "Nov",
  //       "Dec",
  //     ],
  //     title: {
  //       text: "Month",
  //     },
  //   },
  //   yaxis: {
  //     title: {
  //       text: "Rate (%)",
  //     },
  //     min: 0,
  //     max: 100,
  //   },
  //   stroke: {
  //     width: 2,
  //     curve: "straight", // Makes the line smooth
  //   },
  //   markers: {
  //     size: 3,
  //   },
  //   legend: {
  //     position: "top",
  //     horizontalAlign: "right",
  //     floating: true,
  //     offsetY: -25,
  //     offsetX: -5,
  //   },
  // };

  // const secondSeries = [
  //   {
  //     name: "Click Rate",
  //     data: [25, 28, 26, 30, 27, 33, 31, 36, 34, 32, 37, 39],
  //     color: "#FF5F57",
  //   },
  //   {
  //     name: "Open Rate",
  //     data: [55, 60, 57, 65, 61, 70, 67, 75, 73, 68, 76, 78],
  //     color: "#229954",
  //   },
  // ];
  return (
    <>
      <Row className="w-100">
        {/* <Col md={6} sm={12} lg={6} className="mt-4 mb-4">
          <Card className="pt-3">
            <ButtonGroup
              aria-label="Basic example"
              className="ml-auto mr-3"
              style={{ width: "160px", fontSize: "13px", float: "right" }}
            >
              <Button className="btn-sm" variant="secondary">
                Weekly
              </Button>

              <Button className="btn-sm" variant="secondary">
                Monthly
              </Button>
            </ButtonGroup>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={250}
              width={"100%"}
            />
          </Card>
        </Col> */}
        {/* <Col md={6} sm={12} lg={6} className="mt-4 mb-4">
          <Card className="pt-3">
            <ButtonGroup
              aria-label="Basic example"
              className="ml-auto mr-3"
              style={{ width: "160px", fontSize: "13px", float: "right" }}
            >
              <Button className="btn-sm" variant="secondary">
                Weekly
              </Button>

              <Button className="btn-sm" variant="secondary">
                Monthly
              </Button>
            </ButtonGroup>
            <ReactApexChart
              options={secondOptions}
              series={secondSeries}
              type="line"
              height={250}
              width={"100%"}
            />
          </Card>
        </Col> */}
        <Col
          md={12}
          sm={12}
          lg={12}
          className="w-100 d-flex justify-content-start flex-wrap align-content-center gap-3 ml-2"
        >
          <Card
            className="emailOverviewCards"
            style={{ border: "1px solid #0D6EFD", backgroundColor: "#d0e3ff" }}
          >
            <Card.Body>
              <Card.Title>
                <span style={{ fontSize: "14px" }}>
                  <b>Total Email Sent</b>
                </span>
                <span style={{ float: "right" }}>
                  <MdOutgoingMail size={25} />
                </span>
              </Card.Title>
              <Card.Text>{emailOverViewInfo?.TotalSent || 0}</Card.Text>
            </Card.Body>
          </Card>
          {/* <Card
            className="emailOverviewCards"
            style={{ border: "1px solid #FF5F57", backgroundColor: "#ffe2e0" }}
          >
            <Card.Body>
              <Card.Title>
                <span style={{ fontSize: "14px" }}>
                  <b>Click Rate</b>
                </span>
                <span style={{ float: "right" }}>
                  <MdMarkEmailRead size={22} />
                </span>
              </Card.Title>
              <Card.Text>{emailOverViewInfo?.ClickRate || 0}%</Card.Text>
            </Card.Body>
          </Card>
          <Card
            className="emailOverviewCards"
            style={{
              border: "1px solid #229954",
              backgroundColor: "#d7f6e4",
            }}
          >
            <Card.Body>
              <Card.Title>
                <span style={{ fontSize: "14px" }}>
                  <b>Open Rate</b>
                </span>
                <span style={{ float: "right" }}>
                  <IoMdMailOpen size={22} />
                </span>
              </Card.Title>
              <Card.Text>{emailOverViewInfo?.OpenRate || 0}%</Card.Text>
            </Card.Body>
          </Card> */}
        </Col>
        <Col md={12} sm={12} lg={12} className="mt-3">
          <button
            type="button"
            className="btn btn-sm"
            style={{ float: "right" }}
            onClick={() => {
              // setActiveTab("compose");
              setMethod("email");
            }}
          >
            <span>
              <FaPencilAlt className="mr-2" />
              Compose New Mail
            </span>
          </button>
        </Col>
        <Col md={12} sm={12} lg={12} className="mt-3">
          <Card className="p-3">
            <span>
              <FaPaperPlane size={18} className="mr-2" />
              <b>Recent Sent Emails</b>
            </span>
            <hr />
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Recipient</th>
                  <th scope="col">Email Id</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Sent Date</th>
                  <th class="no-hover"></th>
                </tr>
              </thead>
              <tbody>
                {recentEmailList && recentEmailList?.length > 0 ? (
                  recentEmailList?.map((email, index) => (
                    <React.Fragment key={index}>
                      <tr>
                        <td
                          className="text-start"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="avatar-name-candidatestableview d-flex align-items-center">
                            <div>
                              <span>
                                <ProfileImage
                                  imageSrc={email?.profileImagePath}
                                  imageWidth={"20"}
                                  imageHeight={"20"}
                                  avatarSize={"30"}
                                  avatarRound={"500px"}
                                  firstName={email?.firstName}
                                  lastName={email?.lastName}
                                />
                              </span>
                            </div>
                            <div className="ml-3">
                              <strong className="mb-0 me-2">
                                {email?.firstName} {email?.lastName}
                              </strong>
                            </div>
                          </div>
                        </td>
                        <td className="text-start">
                          <OverlayTrigger
                            placement="bottom"
                            overlay={renderTooltip(email?.email)}
                          >
                            <span
                              style={{
                                maxWidth: "200px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                display: "inline-block",
                              }}
                            >
                              {email?.email.length > 35
                                ? `${email?.email.substring(0, 35)}...`
                                : email?.email}
                            </span>
                          </OverlayTrigger>
                        </td>
                        <td>
                          <td
                            className="p-relative"
                            style={{ cursor: "pointer" }}
                          >
                            <OverlayTrigger
                              placement="bottom"
                              overlay={renderTooltip(email.MailSubject)}
                            >
                              <span
                                style={{
                                  maxWidth: "200px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                  display: "inline-block",
                                }}
                              >
                                {email?.MailSubject.length > 50
                                  ? `${email?.MailSubject.substring(0, 50)}...`
                                  : email?.MailSubject}
                              </span>
                            </OverlayTrigger>
                          </td>

                          {/* {expandedRow === index && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: email?.MailContent,
                              }}
                            ></p>
                          )} */}
                          {/* <span
                            onClick={() => handleRowToggle(index)}
                            style={{ cursor: "pointer", color: "#0073e6" }}
                          >
                            <b> {expandedRow === index ? "Close" : "View"}</b>
                          </span> */}
                        </td>
                        <td>
                          {moment(email?.createdDateTime).format(
                            "MM/DD/YYYY hh:mm A"
                          )}
                        </td>
                        <td>
                          <span className="font-primary font-regular">
                            <button
                              style={{ color: "#000" }}
                              onClick={() => {
                                setOpenFullViewEmail(true);
                                setSelectedMail(email);
                              }}
                              className="btn btn-text"
                            >
                              <i className="fad fa-eye"></i>
                            </button>
                          </span>
                        </td>
                      </tr>
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={4}
                      className="text-center"
                      style={{
                        color: "grey",
                        border: "1px solid rgb(240 240 240)",
                      }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <span
                          className="d-flex justify-content-center align-items-center mb-1"
                          style={{
                            width: "70px",
                            height: "70px",
                            border: "1px solid transparent",
                            borderRadius: "50%",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                          }}
                        >
                          <RiMailCloseFill size={35} color="#0073e6" />
                        </span>
                        <span>
                          <b>No Emails!</b>
                        </span>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Card>
        </Col>
      </Row>
      {openFullViewEmail && (
        <ModalView
          modalSize={"lg"}
          modalTitle={"Email Preview"}
          isModalOpen={openFullViewEmail}
          setModalClose={setOpenFullViewEmail}
          isFrom="EmailOverview"
          modalFor="preview"
          data={{
            subject: selectedMail.MailSubject,
            to: selectedMail.email,
            content: selectedMail.MailContent,
            footer: selectedMail?.footer || "",
          }}
        />
      )}
    </>
  );
};

export default EmailOverview;
