import React, { useEffect } from "react";
import { Container, Row, Col, Button, Card } from "react-bootstrap";
import TalentCommunityDetails from "./TalentCommunity";
import Footer from "../../../layout/Footer";
import { MdLocationOn } from "react-icons/md";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  getCuratorData,
  getCuratorInviteDetails,
  updateCurator,
} from "../../../../actions/curator/CuratorInvite";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";
import high5Logo from "../../../../images/high5-mark-circle.svg";

const CuratorAcceptAndReject = () => {
  const history = useHistory();
  const { recruiterId, clientId, communityId } = useParams();
  const dispatch = useDispatch();
  const {
    inviteDetailsLoading,
    inviteDetails,
    curatorData,
    curatorUpdateLoading,
    curatorUpdate,
  } = useSelector(
    (state) => ({
      inviteDetailsLoading: state.curatorInviteReducer.inviteDetailsLoading,
      inviteDetails: state.curatorInviteReducer.inviteDetails,
      curatorData: state.curatorInviteReducer.curatorData,
      curatorUpdateLoading: state.curatorInviteReducer.curatorUpdateLoading,
      curatorUpdate: state.curatorInviteReducer.curatorUpdate,
    }),
    shallowEqual
  );
  useEffect(() => {
    const data = {
      clientId: clientId,
      communityId: communityId,
      gigRecruiterId: recruiterId,
    };
    dispatch(getCuratorInviteDetails(data));
    const data2 = {
      communityId: communityId,
      fk_userAccessDetails: recruiterId,
    };
    dispatch(getCuratorData(data2));
  }, []);

  useEffect(() => {
    if (curatorData && curatorData?.length > 0) {
      if (curatorData[0]?.status === 21) {
        toast.success("The invitation is already accepted!");
      }
      if (curatorData[0]?.status === 22) {
        toast.error("The invitation is already declined!");
      }
    }
  }, [curatorData]);

  const handleInvitation = (data) => {
    let reqData = {
      fk_userAccessDetails:
        inviteDetails?.gigRecruiterDetails[0]?.FK_useraccessdetails,
      curatorStatus: data,
      curatorName:
        inviteDetails?.gigRecruiterDetails[0]?.firstName +
        " " +
        inviteDetails?.gigRecruiterDetails[0]?.lastName,
      communityName: inviteDetails?.communityDetails[0]?.poolname,
      clientName: inviteDetails?.clientDetails[0]?.tenantname,
      high5AdminName: "",
      high5AdminEmail: "",
      communityId: inviteDetails?.communityDetails[0]?.poolId,
    };

    dispatch(updateCurator(reqData));
  };

  return (
    <>
      {inviteDetailsLoading ? (
        <Loader />
      ) : (
        <Container
          fluid
          style={{
            minHeight: "100vh",
            backgroundImage:
              "linear-gradient(to bottom, #FF5F57 55vh,#FFFF 30vh)",
          }}
        >
          <Row className="d-flex justify-content-center p-0 m-0 align-content-center">
            <Col md={3} sm={12} className="mt-5 m-0">
              <Card
                style={{
                  minHeight: "30vh",
                  fontFamily: '"Noto Sans", sans-serif',
                  marginTop: "60%",
                }}
                className="p-4"
              >
                <span className="mb-4">Client </span>
                <div className="mt-2">
                  <span className="mr-3">
                    <img
                      src={
                        inviteDetails?.clientDetails[0]?.logourl
                          ? inviteDetails?.clientDetails[0]?.logourl
                          : high5Logo
                      }
                      style={{
                        width: "60px",
                        height: "60px",
                        background: "white",
                        borderRadius: "50%",
                        padding: "10px",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      }}
                    ></img>
                  </span>
                  <span style={{ fontSize: "18px" }}>
                    <b>{inviteDetails?.clientDetails[0]?.tenantname}</b>
                  </span>
                  <br />
                  <span style={{ paddingLeft: "54px", fontSize: "13px" }}>
                    <MdLocationOn size={18} />{" "}
                    {inviteDetails?.clientDetails[0]?.addressCity && (
                      <>
                        {inviteDetails?.clientDetails[0]?.addressCity},{" "}
                        {inviteDetails?.clientDetails[0]?.country}
                      </>
                    )}
                    {!inviteDetails?.clientDetails[0]?.addressCity &&
                      inviteDetails?.clientDetails[0]?.country && (
                        <>{inviteDetails?.clientDetails[0]?.country}</>
                      )}
                  </span>
                </div>
              </Card>
            </Col>
            <Col md={8} sm={8} lg={8} className="mt-5 m-0">
              <Card
                style={{
                  minHeight: "80vh",
                  backgroundImage:
                    "linear-gradient(to bottom, #001b38 35vh,#FFFF 30vh)",
                  fontFamily: '"Noto Sans", sans-serif',
                  borderTop: "1px solid #001b38",
                  borderBottom: "1px solid #FFFF",
                }}
                className="p-5"
              >
                <Card.Title>
                  <span className="font-white">
                    <b>
                      Hi,{" "}
                      {inviteDetails?.gigRecruiterDetails[0]?.firstName +
                        " " +
                        inviteDetails?.gigRecruiterDetails[0]?.lastName}{" "}
                      !{" "}
                    </b>
                  </span>
                </Card.Title>
                <Card.Subtitle style={{ fontSize: "13px" }}>
                  <p className="font-white mt-2">
                    {/* {" "}
                 We are delighted to extend a warm invitation for you to join
                 the <b>React Developers</b> Talent Community, an esteemed
                 network curated by <b>Apple Inc</b> ! */}
                    We are excited to inform you that our client has shown
                    interest in you to be the curator for the following
                    community. Please review the details below.
                  </p>
                  <p className="font-white mt-2 mb-3">
                    If you are interested in becoming the curator for this job
                    and believe you are the right fit to manage this recruitment
                    process, please click "Accept" below. Otherwise, you can
                    decline the request by clicking "Decline."
                  </p>
                </Card.Subtitle>
                <Card.Body className="mt-5">
                  <TalentCommunityDetails
                    data={inviteDetails?.communityDetails}
                  />
                </Card.Body>

                <Card.Footer className="bg-white">
                  {curatorData && curatorData[0]?.status === 32 && (
                    <div className="w-100 d-flex justify-content-center mt-3">
                      <Button
                        className="btn btn-sm btn-primary mr-3"
                        onClick={() => handleInvitation("accept")}
                        disabled={curatorUpdate !== ""}
                      >
                        Accept
                      </Button>
                      <Button
                        className="btn btn-sm btn-warning"
                        style={{
                          background: "#ff0000",
                          border: "1px solid transparent",
                        }}
                        onClick={() => handleInvitation("decline")}
                        disabled={curatorUpdate !== ""}
                      >
                        Decline
                      </Button>
                    </div>
                  )}
                </Card.Footer>
              </Card>
            </Col>
          </Row>
          <div className="mt-3"></div>
          <Footer />
          {curatorUpdateLoading && <Loader />}
        </Container>
      )}
    </>
  );
};

export default CuratorAcceptAndReject;
