import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getUserRole } from "../../../../../service/AuthService";

const StatusData = (props) => {
  const { candidate } = props;
  const [canId, setCanId] = useState(null);

  return (
    <div class="candidates-grid-request font-regular">
      {candidate.statusData?.length !== 0 && (
        <>
          <div>
            {candidate.statusData?.map((item, index) => {
              if (index < 2 || candidate.userId === canId) {
                if (item.status == 33) {
                  return;
                } else if (item.status == 6) {
                  return (
                    <Link
                      onClick={
                        getUserRole() == "High5Coordinator" &&
                        ((e) => e.preventDefault())
                      }
                      to={`/High5hire/requests/${item.jobId}`}
                      style={{
                        cursor:
                          getUserRole() == "High5Coordinator" && "default",
                      }}
                    >
                      <div class="mb-1">
                        <div class="tag tag-green3">
                          <strong>
                            {item.statusname === "Offered"
                              ? item?.jobId
                                ? "Offered"
                                : "Ready To Hire"
                              : item.statusname === "Offer Rejected"
                              ? "Offer Declined"
                              : item.statusname === "Rejected"
                              ? "Disqualified"
                              : item.statusname === "Submitted"
                              ? item?.jobId
                                ? "Submitted"
                                : "Engaged"
                              : item?.statusname === "Vetting"
                              ? "Vetted"
                              : item?.statusname}
                            : {item.jobTitle}
                          </strong>
                        </div>
                      </div>
                    </Link>
                  );
                } else if (item.status == 38) {
                  return (
                    <Link
                      onClick={
                        getUserRole() == "High5Coordinator" &&
                        ((e) => e.preventDefault())
                      }
                      to={`/High5hire/requests/${item.jobId}`}
                      style={{
                        cursor:
                          getUserRole() == "High5Coordinator" && "default",
                      }}
                    >
                      <div class="mb-1">
                        <div class="tag tag-green1 mr-1">
                          <strong>
                            {item.statusname === "Offered"
                              ? "Offered"
                              : item.statusname === "Offer Rejected"
                              ? "Offer Declined"
                              : item.statusname === "Rejected"
                              ? "Disqualified"
                              : item.statusname}
                            : {item.jobTitle}
                          </strong>
                        </div>
                      </div>
                    </Link>
                  );
                } else {
                  return (
                    <Link
                      onClick={
                        getUserRole() == "High5Coordinator" &&
                        ((e) => e.preventDefault())
                      }
                      to={`/High5hire/requests/${item.jobId}`}
                      style={{
                        cursor:
                          getUserRole() == "High5Coordinator" && "default",
                      }}
                    >
                      <div class="mb-1">
                        <div class="tag tag-blue3">
                          <strong>
                            {item.statusname === "Offered"
                              ? "Offered"
                              : item.statusname === "Offer Rejected"
                              ? "Offer Declined"
                              : item.statusname === "Rejected"
                              ? "Disqualified"
                              : item?.statusname === "Vetting"
                              ? "Vetted"
                              : item?.statusname}
                            : {item.jobTitle}
                          </strong>
                        </div>
                      </div>
                    </Link>
                  );
                }
              }
            })}

            {candidate.userId !== canId ? (
              candidate.statusData?.filter((elem) => elem.status !== 33)
                .length > 2 && (
                <div class="tag" onClick={() => setCanId(candidate.userId)}>
                  +
                  {candidate.statusData.filter((elem) => elem.status !== 33)
                    .length - 2}{" "}
                  more
                </div>
              )
            ) : candidate.userId == canId ? (
              <div class="tag" onClick={() => setCanId(null)}>
                show less
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default StatusData;
