import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import Loader from "../../Loader/Loader";
import Footer from "./jobdetails/Footer";
import TopNavbar from "./TopNavBar";
import JobList from "../careers/jobList/Index";
import { getTenant } from "../../../actions/tenant/getTenant";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import {
  fetchCareerJobList,
  fetchFilterParam,
} from "../../../actions/jobList/CareerJobList";

const Index = () => {
  const dispatch = useDispatch();
  const candidate = sessionStorage.getItem("userId") ?? "";
  const { recruiterid } = useParams();
  const location = useLocation();
  const parameterValue = location?.state?.parameter;
  const [jobData, setJobs] = useState([]);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchJob, setSearchJob] = useState("");
  const {
    tenantData,
    tenantLoading,
    jobList,
    jobListLoading,
    filterDataLoading,
    filterData,
  } = useSelector((state) => ({
    tenantData: state.getTenantReducer.tenantData,
    tenantLoading: state.getTenantReducer.tenantLoading,
    jobList: state.getCareerJobListReducer.jobList,
    jobListLoading: state.getCareerJobListReducer.loading,
    filterDataLoading: state.getCareerJobListReducer.filterDataLoading,
    filterData: state.getCareerJobListReducer.filterData,
  }));
  const [currentPage, setCurrentPage] = useState(1);

  const getJob = async (currentPage, filterObjects, searchText) => {
    const payload = {
      candidateId: candidate,
      pageNumber: currentPage,
      pageSize: 10,
      filterObjects: filterObjects,
      searchText: searchText ? searchText : "",
    };
    dispatch(fetchCareerJobList(payload));
  };

  useEffect(() => {
    getJob(1, [], "");
  }, []);

  useEffect(() => {
    if (!filterData) dispatch(fetchFilterParam({ candidateId: candidate }));
  }, [filterData]);

  useEffect(() => {
    if (jobListLoading || filterDataLoading) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [jobListLoading, filterDataLoading]);

  useEffect(() => {
    if (jobList) {
      setJobs(jobList.data);
      setTotal(jobList.total);
    }
  }, [jobList]);

  useEffect(() => {
    let id = window.location.href.split("/")[4];
    dispatch(getTenant({ tenantId: id }));
  }, []);
  const filterJobs = (e) => {
    console.log("e", e, searchJob);
    const filterJobs = searchJob.filter((val) => {
      if (e.target.value == "") {
        return val;
      } else if (
        val?.jobTitle?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
        // val?.type?.toLowerCase()?.includes(e.target.value?.toLowerCase()) ||
        val?.workPlaceType
          ?.toLowerCase()
          ?.includes(e.target.value?.toLowerCase())
      ) {
        return val;
      }
    });
    console.log("filterJobs", filterJobs);

    const preFilter = searchJob.filter((val) => {
      if (parameterValue == "") {
        return val;
      } else if (
        val?.jobTitle?.toLowerCase()?.includes(parameterValue?.toLowerCase()) ||
        // val?.type?.toLowerCase()?.includes(parameterValue?.toLowerCase()) ||
        val?.workPlaceType
          ?.toLowerCase()
          ?.includes(parameterValue?.toLowerCase())
      ) {
        return val;
      }
    });

    setJobs(filterJobs);
  };
  const preFilterJobs = (searchJob) => {
    console.log("searchJob", searchJob);
    const preFilter = searchJob.filter((val) => {
      if (parameterValue == "") {
        return val;
      } else if (
        val?.jobTitle?.toLowerCase()?.includes(parameterValue?.toLowerCase()) ||
        // val?.type?.toLowerCase()?.includes(parameterValue?.toLowerCase()) ||
        val?.workPlaceType
          ?.toLowerCase()
          ?.includes(parameterValue?.toLowerCase())
      ) {
        return val;
      }
    });
    console.log("preFilter", preFilter);
    setJobs(preFilter);
  };

  return (
    <div>
      {loading && <Loader />}
      <TopNavbar
        tenantData={tenantData}
        setJobs={setJobs}
        jobData={jobData}
        setSearchJob={setSearchJob}
        filterJobs={filterJobs}
        isFrom="newCareerPage"
      />

      <JobList
        isFrom="newCareerPage"
        setJobs={setJobs}
        jobData={jobData}
        setSearchJob={setSearchJob}
        filterJobs={filterJobs}
        parameterValue={parameterValue}
        getJob={getJob}
        total={total}
        setCurrentPage={setCurrentPage}
        currentPage={currentPage}
        loading={loading}
        filterData={filterData}
      />
      <Footer recruiterId={recruiterid} isFrom="newCareerPage" />
    </div>
  );
};

export default Index;
