import React, { useState } from "react";
import { post } from "../../../../../../service/ApiService";
import { toast } from "react-toastify";
import {
  getUserTenantID,
  getUserTenant,
  getCandidateName,
  getCandidateId,
} from "../../../../../../service/AuthService";
import { emailDomain } from "../../../../../../constants/Constants";
import Loader from "../../../../../Loader/Loader";

const ResendMail = (props) => {
  const { talentData, jobData } = props;

  const [loading, setLoading] = useState(false);
  const resendMail = async () => {
    try {
      const tenant_id = getUserTenantID();
      const usertenant = getUserTenant();
      const loggedinusername = getCandidateName();
      const userid = getCandidateId();

      const sendMailLink = `${emailDomain}/careers/${tenant_id}/joblist/pooldescription/${jobData.poolId}/recruiter/${userid}`;
      const getInviteContent = async (event) => {
        try {
          let inviteContent = "";
          const inviteReq = {
            event,
            recipient: '["Candidate"]',
          };
          const response = await post("/msgtwoway/getSmsContent", inviteReq);
          if (response.status === 200) {
            inviteContent = response.data;
          }
          return inviteContent;
        } catch (error) {
          console.error("Error fetching invite content:", error);
          return "";
        }
      };
      const inviteContent = await getInviteContent(
        "Reminder Invite (Manual - After 48 Hours auto reminder)"
      );

      const payloadWaSms = [
        {
          recieverNumber: [
            `${talentData.homePhoneCode?.substring(1)}${talentData.homePhone}`,
          ],
          // message: `Hi ${talentData.firstName},It has been 24 hours since ${loggedinusername} sent the invite to apply for the ${jobData.jobTitle} role, and ${loggedinusername} has not received your submission. Please login and apply ASAP.\n${sendMailLink}\nThe High5 Team`,
          message: `${inviteContent?.[0]?.smsContent} \n ${inviteContent?.[0]?.footer}`,
        },
      ];

      const payloadSms = [
        {
          recieverNumber: [
            `${talentData.homePhoneCode}${talentData.homePhone}`,
          ],
          message: `${inviteContent?.[0]?.smsContent} \n ${inviteContent?.[0]?.footer}`,
        },
      ];
    

      const res = await post("/disposition/poolresendmail", {
        sendMailLink,
        jobTitle: jobData.jobTitle,
        candidateEmail: talentData.email,
        candidateFname: talentData.firstName,
        candidateId:talentData.userId,
        jobId: jobData.poolId,
        candidateTenant: usertenant,
        recruiterName: loggedinusername,
        recruiterId: userid,
        isFrom: "TalentCommunity",
      });

      const phoneCode = payloadSms[0].recieverNumber[0]?.slice(0, -10);
      const smsEndpoint =
        phoneCode === "+1"
          ? "/msgtwoway/telSms"
          : "/massages/sentMassageWhatsapp";

      await post(smsEndpoint, phoneCode === "+1" ? payloadSms : payloadWaSms);

      setLoading(false);
      if (res.status === 200) {
        toast.success(`Reminder email has been sent successfully`);
      } else {
        toast.error("Error occurred while updating talent details");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! Something went wrong. Please try again later");
    }
  };
  return (
    <>
      {loading && <Loader />}
      <button type="button" onClick={() => resendMail()} className="btn btn-sm">
        Resend
      </button>
    </>
  );
};

export default ResendMail;
