import React, { useEffect, useState } from "react";
import RecruiterPerformance from "./RecruiterPerformance";
import TalentPerformance from "./TalentPerformance";
import JobPerformance from "./JobPerformance";
import ReportTopNavBar from "../../report/ReportTopNavBar";
import SideNavBar from "../../layout/SideNavBar";
import Loader from "../../Loader/Loader";
import TopNavBar from "../../layout/TopNavBar";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";
const Performance = () => {
  const [recLoading, setRecLoading] = useState(false);
  const [talLoading, setTalLoading] = useState(false);
  const [jobLoading, setJobLoading] = useState(false);
  const [toggle, setToggle] = useState(true);

  return (
    <>
      {(recLoading || talLoading || jobLoading) && <Loader />}
      <div>
        <body
          className={
            toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
          }
        >
          <div className="body-decorator body-decorator-top" />
          <div className="body-decorator body-decorator-bottom" />
          {/* <ReportTopNavBar handleToggale={() => setToggle(!toggle)} /> */}
          <TopNavBar
            isFrom={"Report"}
            handleToggale={() => setToggle(!toggle)}
          />
          <SideNavBar />
          <div id="Content">
            <div className="section section-sm">
              <div className="row justify-content-center ml-3">
                <div className="d-flex align-items-center">
                  <h5 className="ml-2.5">Performance Reports</h5>
                  {/* <button className="btn-sm ml-2">
                  <span
                    className=""
                    style={{
                      cursor: "pointer",
                      padding: "0px 0px 5px 0px",
                    }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="download"
                    onClick={handleDownload}
                    data-html2canvas-ignore="true"
                  >
                    <i class="fas fa-file-download"></i>{" "} Download
                  </span>
                  </button> */}
                </div>
                <div className="container" id="download">
                  <RecruiterPerformance setLoading={setRecLoading} />
                  <TalentPerformance setLoading={setTalLoading} />
                  <JobPerformance setLoading={setJobLoading} />
                </div>
              </div>
            </div>
            {getTenantType() == 1 && <Footer />}
          </div>
        </body>
      </div>
    </>
  );
};

export default Performance;
