import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { toast } from "react-toastify";
import Validator from "email-validator";
import { Form } from "react-bootstrap";
import HereMape from "../../location/CommonHereMap";
import { get, post } from "../../../service/ApiService";
import { domain } from "../../../constants/Constants";
import Loader from "../../Loader/Loader";
import { AsYouType } from "libphonenumber-js";
import PhoneInput from "react-phone-input-2";

function AddUser(props) {
  const [formComplete, setFormComplete] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(true);

  const [form, setForm] = useState({
    ID_user: "",
    email: "",
    firstName: "",
    middleName: "",
    lastname: "",
    addressState: "",
    country: "",
    addressCity: "",
    address: "",
    zipcode: "",
  });
  const [errors, setErrors] = useState({});
  const [submitClicked, setSubmitClicked] = useState(false);
  let [type, settype] = useState("");
  const [loading, setLoading] = useState(false);

  const GetUserInfo = async () => {
    try {
      setLoading(true);
      const params = {
        userid: props.userlist.userid,
      };
      const res = await post("/users/getUser", params);
      if (res.status === 200) {
        props.getusersData();
        const result = res.data;
        setForm(result);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error occured while accessing account details");
    }
  };
  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country?.countryCode?.toUpperCase());
    asYouType.input(value.replace(country?.dialCode, ""));
    const isValid = asYouType.isValid();
    setPhoneValidation(isValid);
  };

  useEffect(() => {
    GetUserInfo();
  }, []);

  const findFormErrors = () => {
    const {
      lastName,
      email,
      firstName,
      zipcode,
      addressCity,
      addressState,
      country,
    } = form;
    const newErrors = {};
    // name errors

    if (!firstName || firstName === "")
      newErrors.firstName = "First Name  cannot be blank!";
    else if (!/^[A-Z a-z\s]+$/.test(firstName))
      newErrors.firstName = "Invalid First Name ";
    if (!lastName || lastName === "")
      newErrors.lastName = "Last Name  cannot be blank!";
    else if (!/^[A-Z a-z\s]+$/.test(lastName))
      newErrors.lastName = "Invalid Last Name ";
    if (!zipcode || zipcode === "")
      newErrors.zipcode = "ZipCode cannot be Blank";
    else if (!/^[0-9a-zA-Z ]+$/.test(zipcode))
      newErrors.zipcode = "Invalid ZipCode";
    if (!addressCity || addressCity === "")
      newErrors.addressCity = "City cannot be Blank";
    else if (!/^[A-Z a-z\s]+$/.test(addressCity))
      newErrors.addressCity = "Invalid City Name ";
    if (!addressState || addressState === "")
      newErrors.addressState = "State cannot be Blank";
    else if (!/^[A-Z a-z\s]+$/.test(addressState))
      newErrors.addressState = "Invalid State Name ";
    if (!country || country === "")
      newErrors.country = "Country cannot be Blank";
    else if (!/^[A-Z a-z\s]+$/.test(country))
      newErrors.country = "Invalid Country Name ";
    if (!email || email === "") newErrors.email = "Email id cannot be Blank";
    else if (!Validator.validate(email)) newErrors.email = "Invalid Email id !";
    return newErrors;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    //console.log(form, Object.keys(newErrors).length)
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
    } else {
      // No errors! Put any logic here for the form submission!
      try {
        setLoading(true);
        const paramters = {
          ...form,
          domainName: domain,
        };
        const res = await post("/users/updateadminUser", paramters);
        if (res.status === 200) {
          setLoading(false);
          //   toast.success("You've successfully updated your password.We are taking you to your 'High5' login page now")
          //  setTimeout(() => history.push('/login'), 8000)
          const result = res.data;
          toast.success("User details updated successfully");
          props.getusersData();
          close();
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error Occured While updating  User  Details");
      }
    }
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const close = () => {
    props.closeAdd(true);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  if (props.userlist) {
    type = props.userlist.type;
  }

  return (
    <>
      {loading && <Loader />}
      <div class="bl-modal reveal-overlay reveal-card">
        <div class="bl-modal-overlay"></div>
        <div class="bl-modal-container">
          <div class="bl-modal-card bl-modal-card-lg">
            <div class="bl-modal-header">
              <h6 v-if="title" class="bl-modal-title">
                {props.userlist
                  ? "Edit User"
                  : props.addusersCondition === true
                  ? "Edit User"
                  : "Add Tenant"}
              </h6>
              <button
                type="button"
                class="close p-0 bl-modal-close-btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={close}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="showBody" class="bl-modal-body pb-0">
              <div
                v-if="isAssign === false"
                class="card card-flat card-borderless bg-gray4 text-center"
              >
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Enter role "
                            value={
                              form.roleText == "Customer Success"
                                ? "High5 Admin"
                                : form.roleText == "High5 Coordinator"
                                ? "Customer Success Manager"
                                : form.roleText == "Community Manager"
                                ? "Marketplace Manager"
                                : ""
                            }
                            onChange={(e) =>
                              setField("rolename", e.target.value)
                            }
                            isInvalid={!!errors.rolename}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.Usertype}
                          </Form.Control.Feedback>
                          <label>User Type*</label>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            disabled
                            placeholder="Enter email "
                            value={form.email}
                            onChange={(e) => setField("email", e.target.value)}
                            isInvalid={!!errors.email}
                          />
                          <label>Email ID*</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter First Name"
                            value={form.firstName}
                            onChange={(e) =>
                              setField("firstName", e.target.value)
                            }
                            isInvalid={!!errors.firstName}
                          />
                          <label>First Name*</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.firstName}
                          </Form.Control.Feedback>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter Last Name"
                            value={form.lastName}
                            onChange={(e) =>
                              setField("lastName", e.target.value)
                            }
                            isInvalid={!!errors.lastName}
                          />
                          <label>Last Name*</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.lastName}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>

                    {(form.rolename === "High5Coordinator" ||
                      form.rolename === "CommunityManager" ||
                      form.rolename === "CustomerSuccess") && (
                      <div className="row mt-4 ">
                        <div className="col-lg-12">
                          <div className="">
                            <PhoneInput
                              inputClass={"form-control font-14 w-100"}
                              required="true"
                              placeholder="mobile"
                              id="homePhone"
                              country={"us"}
                              value={form.mobilePhone}
                              name="homePhone"
                              onChange={(value, country, e) => {
                                _onChangeText(value, country);
                                console.log(value, country.dialCode);
                                setForm({
                                  ...form,
                                  mobilePhone: value,
                                  mobilePhoneCode: country.dialCode,
                                });
                              }}
                            />
                          </div>
                          {!phoneValidation && (
                            <small className="validation">Invalid Phone</small>
                          )}
                        </div>
                      </div>
                    )}

                    <div class="row mt-4">
                      <div className="form-floating">
                        <HereMape setForm={setForm} form={form} />
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <label className="mb-2"> Address </label>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-4">
                          {/* <div className="form-floating">
                            <Form.Control
                              type="text"
                              placeholder="Enter zipcode "
                              id="zipcode"
                              value={form.zipcode}
                              onChange={(e) =>
                                setField("zipcode", e.target.value)
                              }
                              isInvalid={!!errors.zipcode}
                            />
                            <label>Zipcode* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.zipcode}
                            </Form.Control.Feedback>
                          </div> */}
                        </div>
                        <div className="col-lg-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder="Address"
                              value={form.address}
                              onChange={handleChange}
                              isInvalid={!!errors.address}
                            />
                            <label>Address </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="addressCity"
                              placeholder="Address City"
                              value={form.addressCity}
                              onChange={(e) =>
                                setField("addressCity", e.target.value)
                              }
                              isInvalid={!!errors.zipcode}
                            />
                            <label>City* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.addressCity}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="addressState"
                              placeholder="Address State"
                              value={form.addressState}
                              onChange={(e) =>
                                setField("addressState", e.target.value)
                              }
                              isInvalid={!!errors.addressState}
                            />
                            <label> State* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.addressState}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="country"
                              placeholder="Address Country"
                              value={form.country}
                              onChange={(e) =>
                                setField("country", e.target.value)
                              }
                              isInvalid={!!errors.country}
                            />
                            <label> Country* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.country}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div v-bind:class="{ 'd-none': formComplete }" class="modal-footer mt-4 mb-0 d-none"> */}
            <div class="modal-footer mt-4 mb-0">
              <button
                type="button"
                class="btn btn-secondary mr-2"
                data-dismiss="modal"
                onClick={close}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleSubmit}
              >
                {!submitClicked && !formComplete && <span>Submit</span>}

                {submitClicked && (
                  <span v-if="submittingForm">
                    {/* <span v-if="isAssign === false">
                        Submitting User
                    </span> */}
                    <span v-if="isAssign === true">Adding Users</span>
                    <img width="20px" src="/images/button-loader.gif" />
                  </span>
                )}
                {formComplete && !submitClicked && (
                  <span v-if="showSuccessMessage">
                    {/* <span v-if="isAssign === false">User Added!</span> */}
                    <span v-if="isAssign === true">Users Added</span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddUser;
