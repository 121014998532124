import React, { useState, useEffect } from "react";
import imageLoader from "../../../../images/button-loader.gif";
import { post } from "../../../../service/ApiService";
import { getUserRole, getCandidateName } from "../../../../service/AuthService";
import { toast } from "react-toastify";
import { getInitial } from "../../../function/CommonFunction";
import { domain } from "../../../../constants/Constants";
import Avatar from "react-avatar";

function OfferAcceptedPopUp(props) {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [loading, setLoading] = useState(false);
  const [talentEmailChecked, setTalentEmailChecked] = useState(false);
  const jobData = props.jobData;
  const talent = props.talentdata;
  const role = getUserRole();
  const userName = getCandidateName();
  // const tenant = props.userTenant
  const [talentData, setTalentData] = useState({});

  useEffect(() => {
    setTalentData(props.selectCandidate);
  }, [props]);

  useEffect(() => {
    fetchCandidate();
  }, []);

  const fetchCandidate = async () => {
    setLoading(true);
    const payload = {
      domain: domain,
      candidateId: props.candidateId,
    };
    let res = await post("/talentdata/searchtalentbyid", payload);
    setCandidateData(res.data);
    setLoading(false);
  };

  const handleCheck = (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setTalentEmailChecked(true);
    } else {
      setTalentEmailChecked(false);
    }
  };

  const closeModal = () => {
    props.handleSuccess();
  };
  const tenant = props.userTenant;
  const candidateEmail = talentData.candidateEmail;
  const candidateFname = talentData.candidateFname;
  const candidateLname = talentData.candidateLname;
  const candidateTenant = talentData.candidateTenant
  const jobId = jobData.jobId;
  const jobTitle = jobData.jobTitle;
  const jobTenant = jobData.jobTenant;
  //console.log(candidateEmail,"email")
  const MailOfferAPI = async (e) => {
    const params = {
      tenant,
      candidateEmail,
      candidateFname,
      candidateLname,
      jobId,
      jobTitle,
      jobTenant,
      talentEmailChecked,
      candidateTenant
    };
    try {
      const res = await post("/makeanoffer/getdataforhiredmail", params);
      if (res.status === 200) {
        props.setSuccessFormOpen(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let submittedToClientBy = getCandidateName();
  let candidateId = talentData.candidateId;

  const submitOffer = async (e) => {
    const payload = {
      jobId: jobData.jobId,
      candidateId: candidateId,
      hiredRole: role,
      hiredBy: submittedToClientBy,
      offeredBy: submittedToClientBy,
      OfferedDate: new Date().toISOString(),
      talentData,
      talentEmailChecked,
    };
    try {
      const res = await post(
        "/jobcandidate/updateofferacceptedstatus",
        payload
      );
      if (res.status === 200) {
        toast.success("Job offer accepted");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
      props.handleSuccess();
      props.refreshPage();
    } catch (error) {
      toast.error("An error occurred");
    }
    setSubmitClicked(true);
    // const tenant = { tenant: props.userTenant }
    //  const candidateEmail = {candidateEmail: talentData.candidateEmail}
    MailOfferAPI(tenant);
    setSubmitClicked(true);
  };

  return (
    <>
      <div className="bl-modal-header">
        <h6 className="bl-modal-title"> Offer Accepted</h6>
        <button
          type="button"
          onClick={closeModal}
          className="close p-0 bl-modal-close-btn"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div>
        <div className="text-center">
          {talentData.ImageURL ? (
            <span>
              <img className="avatar" alt="" src={candidateData.ImageURL} />
              {/* <span className="ml-2">{candidateData.firstName} {candidateData.lastName}</span> */}
            </span>
          ) : (
            // <span className="avatar avatar-green1 avatar-sm">
            //     {getInitial(`${talentData[0].candidateFname} ${talentData[0].candidateLname}`)}
            // </span>
            <span>
              <Avatar
                size="35"
                name={
                  talentData.candidateFname + " " + talentData.candidateLname
                }
                round="80px"
              />
            </span>
          )}
        </div>
      </div>
      <div>
        <div className="text-center">
          <h6 className="mt-3">
            {talentData.candidateFname} {talentData.candidateLname}
          </h6>
          <p>
            {jobData.isRemote === true ? (
              <span className="mb-1 mr-1">Remote</span>
            ) : (
              <span className="mb-1 mr-1">{jobData.location.city}</span>
            )}{" "}
            /{<span className="mb-1 mr-1">{jobData.jobTenant}</span>} /
            {jobData.jobType === "Full Time"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: jobData.placementCurrency
                    ? `${jobData.placementCurrency}`
                    : "USD",
                  maximumSignificantDigits: 3,
                }).format(jobData.annualSalary)
              : new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: jobData.placementCurrency
                    ? `${jobData.placementCurrency}`
                    : "USD",
                  maximumSignificantDigits: 3,
                }).format(jobData.hourlyRate)}
          </p>
        </div>
      </div>

      <div style={{ padding: "1px 2px 35px 0px" }}></div>

      <div v-if="showMakeAnOfferForm">
        <div className="bl-modal-body pt-0">
          <p className="text-center">
            <strong>
              Please confirm that you wish to submit an offer to this candidate.
            </strong>
          </p>
          <div className="col-lg-8 d-flex align-items-center  mb-2 mb-lg-0 justify-content-left justify-content-lg-start">
            <div className="form-check form-switch d-flex align-items-center text-center">
              <label className="form-check-label pt-3" htmlFor="mailSwitch">
                <p> Send email to candidate</p>
              </label>
              <input
                name="candidateEmail"
                className="form-check-input ml-3"
                type="checkbox"
                onChange={(e) => handleCheck(e)}
                checked={talentEmailChecked ? true : false}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            data-dismiss="modal"
            onClick={closeModal}
            disabled={submitClicked}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-primary btn-sm">
            {submitClicked === false ? (
              <span onClick={submitOffer}>Submit Offer</span>
            ) : (
              <span>
                Submitting Offer
                <img width="20px" src={imageLoader} alt="blank" />
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  );
}
export default OfferAcceptedPopUp;
