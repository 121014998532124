import React, { useEffect, useState } from "react";
import LoginOverview from "./LoginOverview";
import RegistrationOverview from "./RegistrationOverview";
import RecruitmentPipeline from "./RecruitmentPipeline";
import UsersData from "./UsersData";
import { getUserId, getUserRole } from "../../../../../service/AuthService";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { validate } from "email-validator";
import { post } from "../../../../../service/ApiService";
import { getRecruiterData } from "../../../../../actions/executiveadmin/RecruiterData";
import Loader from "../../../../Loader/Loader";
import InviteRecruiter from "./InviteRecruiter";
import ShareLink from "./ShareLink";

const Recruiters = ({ getRecruiters }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [recruiterTab, setRecruiterTab] = useState("users");
  const [currentPage, setCurrentPage] = useState(1);
  const [smShow, setSmShow] = useState(false);
  const [PublicShareLinkValue, setPublicShareLinkButton] = useState(false);
  const [formModel, setFormModel] = useState({
    firstName: "",
    lastName: "",
    Email: "",
    mobile: "",
    mobilePhoneCode: "",
  });
  const [Modelvalidation, setModelValidation] = useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
    mobileValidation: true,
    mobileError: "",
  });

  const onTabClick = (type) => {
    setRecruiterTab(type);
    setCurrentPage(1);
  };
  let sendMailLink = `${
    window.location.origin
  }/RecruiterRegistrationForm/${getUserId()}`;

  const handleCopy = (e) => {
    navigator.clipboard.writeText(sendMailLink);
    toast.success("Link Copied Successfully!!!");
  };

  const handlePhoneChange = (value, country) => {
    const mobile = value.replace(country.dialCode, "");
    setFormModel({
      ...formModel,
      mobile: mobile,
      mobilePhoneCode: `+${country.dialCode}`,
    });
  };
  const handleModelClick = async (e) => {
    e.preventDefault();
    let validationObj = [];
    let validationObj2 = {
      firstNameError: "",
      lastNameError: "",
      emailError: "",
      mobileError: "",
    };
    if (formModel.firstName.trim() === "") {
      validationObj2 = { ...validationObj2, firstNameError: "Required Field" };
      validationObj.push("First Name Cannot be blank");
    }
    if (formModel.lastName.trim() === "") {
      validationObj2 = { ...validationObj2, lastNameError: "Required Field" };
      validationObj.push("Last Name cannot be blank");
    }
    if (formModel.Email.trim() === "") {
      validationObj2 = {
        ...validationObj2,
        emailError: "Email Cannot be blank",
      };
      validationObj.push("invalid email");
    }
    if (formModel.Email && validate(formModel.Email) === false) {
      validationObj2 = { ...validationObj2, emailError: "Invalid Email" };
      validationObj.push("Invalid email");
    }
    if (!Modelvalidation.mobileValidation) {
      validationObj2 = {
        ...validationObj2,
        mobileError: "Invalid phone number",
      };
      validationObj.push("Invalid phone number");
    }
    if (validationObj.length > 0) {
      setModelValidation(validationObj2);
    } else {
      setModelValidation({
        firstNameError: "",
        lastNameError: "",
        emailError: "",
        mobileError: "",
      });
      try {
        setLoading(true);
        const res = await post("/gigrecruiter/invite", formModel);
        if (res.data.ReturnText === "Invited Successfully") {
          setFormModel({
            firstName: "",
            lastName: "",
            Email: "",
          });
          setSmShow(false);
          toast.success("Recruiter invited successfully");
          dispatch(getRecruiterData());
        } else {
          toast.error(res.data.ReturnText);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error("Some Error Occurred while Inviting");
      }
    }
  };
  return (
    <div className="py-5">
      {loading && <Loader />}
      <div className="d-flex d-block-1200 justify-content-between">
        <div className="d-flex justify-content-between mt-3">
          <ul class="nav nav-tabs  ">
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("users");
                }}
                type="button"
                class={
                  recruiterTab == "users"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
              >
                <div>Gig Recruiters</div>
              </button>
            </li>
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("regOverview");
                }}
                type="button"
                class={
                  recruiterTab == "regOverview"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
              >
                {" "}
                <div>Registration Overview</div>
              </button>
            </li>{" "}
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("loginOverview");
                }}
                type="button"
                class={
                  recruiterTab == "loginOverview"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
              >
                <div>Login Overview</div>
              </button>
            </li>
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("recruitmentPipe");
                }}
                type="button"
                class={
                  recruiterTab == "recruitmentPipe"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
              >
                <div>Recruitment Pipeline</div>
              </button>
            </li>
          </ul>
        </div>{" "}
        <hr className="d-none d-block-1200 mt-0" />
        {getUserRole() === "CommunityManager" && (
          <div className="d-flex align-items-end justify-content-center mb-1 gap-2">
            <div className="flex-item">
              <button
                type="button"
                class="btn btn-sm"
                onClick={() => setSmShow(true)}
              >
                <i class="fal fa-user-plus mr-2" aria-hidden="true"></i>
                Invite
              </button>
            </div>

            <div className="flex-item">
              <button
                type="button"
                className="btn btn-sm"
                onClick={() => setPublicShareLinkButton(true)}
              >
                <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                Invitation Link
              </button>
            </div>
          </div>
        )}
      </div>
      <hr className="mt-0 d-none-1200" />
      <div className="d-none d-block-1200 mb-3"></div>
      {recruiterTab == "loginOverview" && (
        <LoginOverview getRecruiters={getRecruiters} />
      )}
      {recruiterTab == "regOverview" && (
        <RegistrationOverview getRecruiters={getRecruiters} />
      )}
      {recruiterTab == "recruitmentPipe" && <RecruitmentPipeline />}
      {recruiterTab == "users" && <UsersData getRecruiters={getRecruiters} />}
      {PublicShareLinkValue && (
        <ShareLink
          PublicShareLinkValue={PublicShareLinkValue}
          setPublicShareLinkButton={setPublicShareLinkButton}
          sendMailLink={sendMailLink}
          handleCopy={handleCopy}
        />
      )}

      {smShow && (
        <InviteRecruiter
          smShow={smShow}
          setSmShow={setSmShow}
          formModel={formModel}
          setFormModel={setFormModel}
          Modelvalidation={Modelvalidation}
          setModelValidation={setModelValidation}
          handleModelClick={handleModelClick}
        />
      )}
    </div>
  );
};

export default Recruiters;
