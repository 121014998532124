import React, { useState } from "react";
import { FcCallback } from "react-icons/fc";

const CallNotes = ({ candidateData }) => {
  const [callNotesValue, setCallNotesValue] = useState("");
  const [openCallDetails, setOpenCallDetails] = useState(false);
  const [openResponses, setOpenResponses] = useState(false);
  const [openMessages, setOpenMessages] = useState(false);
  const [openOthers, setOpenOthers] = useState(false);

  const openCallDetail = () => {
    setOpenCallDetails(true);
    setOpenResponses(false);
    setOpenMessages(false);
    setOpenOthers(false);
  };
  const openResponse = () => {
    setOpenResponses(true);
    setOpenCallDetails(false);
    setOpenMessages(false);
    setOpenOthers(false);
  };
  const openMessage = () => {
    setOpenMessages(true);
    setOpenResponses(false);
    setOpenCallDetails(false);
    setOpenOthers(false);
  };
  const openOther = () => {
    setOpenOthers(true);
    setOpenCallDetails(false);
    setOpenResponses(false);
    setOpenMessages(false);
  };
  return (
    <div className="card card-flat bg-gray4 m-3">
      <div className="card-body">
        <div className="row">
          <div className="col-lg-12">
            <h6>Call Notes</h6>
          </div>
        </div>
        <hr></hr>
        <div className="card card-flat m-3">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-5">
                <FcCallback />
                &nbsp;
                {candidateData.mobilePhone}
              </div>
              <div className="col-lg-7 d-flex justify-content-around ">
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => openCallDetail()}
                >
                  <a href="javascript:void(0)">Called</a>
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => openResponse()}
                >
                  <a href="javascript:void(0)">No Response</a>
                </span>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => openMessage()}
                >
                  <a href="javascript:void(0)">Left Message</a>
                </span>
                <span style={{ cursor: "pointer" }} onClick={() => openOther()}>
                  <a href="javascript:void(0)">Other</a>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="form-floating">
          <textarea
            onChange={(e) => setCallNotesValue(e.target.value)}
            value={callNotesValue}
            className="form-control"
          />
        </div>
        {openCallDetails && (
          <div className="mt-2">Called at October 10th 2022, 5:47:31 pm</div>
        )}
        {openResponses && (
          <div className="mt-2">
            No Response at October 10th 2022, 6:01:10 pm
          </div>
        )}
        {openMessages && (
          <div className="mt-2">
            Left Message at October 10th 2022, 6:11:49 pm
          </div>
        )}
        {openOthers && (
          <div className="mt-2">At October 10th 2022, 6:16:36 pm</div>
        )}
        <div className="d-flex">
          <button
            className="btn btn-sm ml-auto mt-2"
            disabled={callNotesValue ? false : true}
            // onClick={() => {
            //     setNotesDetails([ ...notesDetails, obj ])
            //     setNotesValue('')
            // }}
          >
            Add Call Notes
          </button>
        </div>
        {/* <div className="row m-2">
                <div className="col-lg-12">
                    {
                       
                            <div className="d-flex mb-2">
                                <Avatar size="30" name={item.name} round="60px" />
                                <div className="ml-2">
                                    <p className="m-0">
                                        <strong>{item.name}</strong>
                                        <small className="ml-2">{moment(item.createdDate).format('MM/DD/YYYY')}</small>
                                        <p className="">
                                            <small>{item.comments}</small>
                                        </p>
                                    </p>                                                   
                                </div>
                            </div>
                       
                    }                                                                                        
                </div>
            </div> */}
      </div>
    </div>
  );
};

export default CallNotes;
