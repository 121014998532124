import React, { useState } from "react";

// import '../../../sass/_action-panel.scss'
function CandidateProfileHistory(props) {
  const candidateData = props.candidateData;
  const [interviewComments, setinterviewComments] = useState(false);
  const [disqualifyComments, setdisqualifyComments] = useState(false);
  //console.log("History", candidateData);

  const onCollapsibleClick = (val) => {
    if (val === "interviewComments") {
      interviewComments
        ? setinterviewComments(false)
        : setinterviewComments(true);
    }
    if (val === "disqualifyComments") {
      disqualifyComments
        ? setdisqualifyComments(false)
        : setdisqualifyComments(true);
    }
  };

  return (
    <>
      <div>
        {candidateData.interviewComments !== "" && (
          <>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => onCollapsibleClick("interviewComments")}
              >
                <i
                  className={
                    interviewComments
                      ? "fal fa-angle-down fa-fw"
                      : "fal fa-angle-up fa-fw"
                  }
                ></i>
                <span>
                  <small>Interview Comments</small>
                </span>
              </button>
            </div>
            <div className={interviewComments ? "ml-4 d-none" : "ml-4"}>
              <div className="d-flex mb-2 pl-1">
                {candidateData.interviewComments && (
                  <>
                    <div className="d-flex mb-2 pl-1">
                      <div class="ml-2">
                        <p class=" mb-0">{candidateData.interviewComments}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {candidateData.disqualifyComments !== "" && (
          <>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => onCollapsibleClick("disqualifyComments")}
              >
                <i
                  className={
                    disqualifyComments
                      ? "fal fa-angle-down fa-fw"
                      : "fal fa-angle-up fa-fw"
                  }
                ></i>
                <span>
                  <small>Disqualify Comments</small>
                </span>
              </button>
            </div>
            <div className={disqualifyComments ? "ml-4 d-none" : "ml-4"}>
              <div className="d-flex mb-2 pl-1">
                {candidateData.disqualifyComments && (
                  <>
                    <div className="d-flex mb-2 pl-1">
                      <div class="ml-2">
                        <p class=" mb-0">{candidateData.disqualifyComments}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}

        {candidateData.interviewComments === "" &&
          candidateData.disqualifyComments === "" && (
            <div>
              <div className="card card-lg card-borderless card-flat  w-100">
                <div className="card-body text-center">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-fw fa-folder-open"></i>
                  </div>
                  <div className="mt-2">No Comment Found</div>
                </div>
              </div>
            </div>
          )}
      </div>
    </>
  );
}

export default CandidateProfileHistory;
