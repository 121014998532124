import React from "react";
import { currency_list } from "../../../../function/CommonFunction";

const PlacementAndReferral = (props) => {
  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body py-0">
          <div className="row">
            <div className="col-lg-7 mb-2">
              <div className="d-flex mb-1 justify-content-between">
                <label className="mb-1">Placement fee*</label>
                <div className="d-flex align-items-center ml-auto mb-1">
                <label className="form-check-label mr-2 ml-3">
                    Show fee in percentage?
                  </label>
                  <div className="form-check form-switch mt-3">
                    <input
                      name="isFeePercentage"
                      className="form-check-input"
                      type="checkbox"
                      id="Switch"
                      onChange={(e) => {
                        props?.setAddJobData({
                          ...props?.addJobData,
                          isFeePercentage: e.target.checked,
                          placementFee: "",
                        });
                      }}
                      checked={props?.addJobData.isFeePercentage}
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex">
                <div className="form-floating w-50">
                  <input
                    name="placementFee"
                    type="number"
                    min="1"
                    value={props?.addJobData.placementFee}
                    // defaultValue={isFeePercentage && " " || !isFeePercentage && " "}
                    max={props?.addJobData.isFeePercentage && "100"}
                    className={
                      props?.isFormValid && props?.addJobData.placementFee == ""
                        ? "form-control border border-danger"
                        : "form-control"
                    }
                    placeholder={
                      props?.addJobData.isFeePercentage
                        ? "Enter Amount %"
                        : "Enter Amount Fee"
                    }
                    onChange={props?.handleChange}
                  />
                  <label>
                    {" "}
                    {props?.addJobData.isFeePercentage
                      ? "Percentage %"
                      : "Amount"}
                  </label>
                  {props?.isFormValid &&
                    props?.addJobData.placementFee == "" && (
                      <small className="validation ml-2">*Required field</small>
                    )}
                </div>
                {!props?.addJobData.isFeePercentage && (
                  <div className="form-floating w-50 ml-1">
                    <select
                      className="form-select"
                      name="placementCurrency"
                      onChange={props?.handleChange}
                    >
                      {/* <option value="" selected>
                    Select
                  </option> */}
                      {Object.keys(currency_list).map((item, i) => (
                        <option
                          key={i}
                          selected={
                            item === props?.addJobData.placementCurrency
                          }
                          value={item}
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                    <label>Currency</label>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-5 mb-2">
              <div className="d-flex mb-1">
                {props?.openForReferral && (
                  <label className="mb-1">Candidate Referral Bonus</label>
                )}
                <div className="d-flex align-items-center ml-auto mb-1">
                  <label className="form-check-label mr-2">
                    Open for referral?
                  </label>
                  <div className="form-check form-switch mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={(e) =>
                       { props?.setOpenForReferral(e.target.checked);
                        props?.setAddJobData({...props?.addJobData,openForReferral:e.target.checked});}
                      }
                      checked={props?.openForReferral}
                    />
                  </div>
                </div>
              </div>

              {props?.openForReferral && (
                <>
                  <div className="d-flex">
                    <div className="form-floating">
                      <input
                        name="referralFee"
                        type="number"
                        min="1"
                        className="form-control"
                        placeholder="Enter Referral Fee"
                        onChange={props?.handleChange}
                        value={props?.addJobData.referralFee}
                      />
                      <label>Amount</label>
                    </div>
                    <div className="form-floating w-50 ml-1">
                      <select
                        className="form-select"
                        name="referralCurrency"
                        onChange={props?.handleChange}
                      >
                        {Object.keys(currency_list).map((item, i) => (
                          <option
                            key={i}
                            selected={
                              item === props?.addJobData.referralCurrency
                            }
                            value={item}
                          >
                            {item}
                          </option>
                        ))}
                      </select>
                      <label>Currency</label>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PlacementAndReferral;
