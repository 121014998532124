import React, { useEffect, useRef, useState } from "react";
import Pagination from "../../../../components_v3/pagination/pagination";
import NoRecord from "../../../commoncomponents/norecord/NoRecord";
import { toast } from "react-toastify";
import StatusData from "../../../talent/talentcommunity/listview/StatusData";
import { AsYouType } from "libphonenumber-js";
import { CurrencySymbols } from "../../../../config/CurrencySymbols";
import moment from "moment";
import TalentProfile from "../../../talent/talentprofile/TalentProfile";
import {
  getUserRole,
  getUserTenantID,
  getTenantType,
} from "../../../../../service/AuthService";
import { useHistory } from "react-router-dom";
import { FaCommentAlt } from "react-icons/fa";
import BulkCommunicationModal from "../../../talent/talentcommunity/communication/BulkCommunicationModal";
import ProfileImage from "../../../commoncomponents/profileimage/ProfileImage";
import { IoLogoWhatsapp } from "react-icons/io";
import { MdMarkChatUnread } from "react-icons/md";

const TalentsList = ({
  setCurrentPage,
  arrayList,
  setTalentDetails,
  getTalentList,
  handleSelectAll,
  parent,
  setGetTab,
  matches,
  isFilterApplied,
  selectedData,
  talentDetails,
  currentPage,
  dataTotal,
  handlePageChange,
  filterLength,
  selectedTalents,
  setSelectedTalents,
  isFilterEnabled,
  handleClear,
  isFrom,
  isFor,
  showCandidateProfile,
  setShowCandidateProfile,
  parentTab,
}) => {
  const dropdownRef = useRef(null);
  const selectAllRef = useRef(null);
  const [isCandidateFrom, setIsCandidateFrom] = useState("");
  const [toggleActions, setToggleActions] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [paginationArray, setPaginationArray] = useState([]);
  const dataSize = 10;
  const pageSize = 5;
  const [candidateId, setCandidateId] = useState("");
  const [candidateData, setCandidateData] = useState({});
  const [data, setData] = useState(
    arrayList.map((x) => ({ ...x, showMail: false }))
  );

  const history = useHistory();

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      showCandidateProfile &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setShowCandidateProfile(false);
    }
    if (selectAllRef.current && !selectAllRef.current.contains(event.target)) {
      setToggleActions(false);
    }
  };

  useEffect(() => {
    let list = arrayList.map((x) => {
      return { ...x, showAllRecs: false };
    });

    setData(list);
  }, [arrayList]);

  const jobBoard = [
    "Facebook",
    "LinkedIn",
    "Indeed",
    "CareerBuilder",
    "Dice",
    "Twitter",
  ];

  const handleModel = () => {
    setShowCandidateProfile(false);
  };

  const onProfileClick = (candidate) => {
    setCandidateData(candidate);
    setShowCandidateProfile(true);
    setCandidateId(candidate.userId);
  };

  const onSkillClick = (e, candidate) => {
    setTalentDetails(
      arrayList.map((item) => {
        if (item.userId === candidate.userId) {
          item.ViewAllSkill = !item.ViewAllSkill;
        }
        return item;
      })
    );
  };

  const onTagViewClick = (e, candidate) => {
    setTalentDetails(
      arrayList.map((item) => {
        if (item.userId === candidate.userId) {
          item.ViewAllTags = !item.ViewAllTags;
        }
        return item;
      })
    );
  };

  const handleCopy = (candidate) => {
    navigator.clipboard.writeText(candidate.email);
    toast.success("Email Copied Successfully!!!");
  };

  // // Function to toggle selected talent state
  const toggleSelectedTalent = (candidate) => {
    let userId = candidate?.userId;
    let firstName = candidate?.firstName;
    let lastName = candidate?.lastName;
    let email = candidate?.email;
    let newSletter = candidate?.newSletter;
    let designation = candidate?.designation;
    let homePhoneCode = candidate?.homePhoneCode;
    let workPhoneCode = candidate?.workPhoneCode;
    let homePhone = candidate?.homePhone;
    let workPhone = candidate?.workPhone;

    let data = {
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      newSletter: newSletter,
      designation: designation,
      homePhoneCode: homePhoneCode,
      workPhoneCode: workPhoneCode,
      homePhone: homePhone,
      workPhone: workPhone,
    };

    // setSelectedTalents((prevData) =>
    //   prevData.includes(id)
    //     ? prevData.filter((x) => x != id)
    //     : [...prevData, id]
    // );

    setSelectedTalents((prevData) =>
      prevData.some((x) => x.userId === userId)
        ? prevData.filter((c) => c.userId != userId)
        : [...prevData, data]
    );
  }; // Function to toggle selected talent state

  const toggleShowRec = (candidate) => {
    setData((prevData) =>
      prevData.map((c) =>
        c.userId === candidate.userId ? { ...c, showAllRecs: true } : c
      )
    );
  };
  // useEffect(() => {
  //   if (parent === "jobList" || parent === "talentList") {
  //     let x = data?.filter((c) => c.isSelected);
  //     setSelectedTalents(x);
  //   }
  // }, [data]);

  const handleBroadcastEmail = (candidate) => {
    let obj = {
      talentData: [{ ...candidate }],
      isFrom: "talentCommunity",
      for: "email",
    };
    history.push({
      pathname: "/high5hire/broadcast",
      state: obj,
    });
  };
  const handleContactCopy = (candidate) => {
    let obj = {
      contactData: [{ ...candidate }],
      isFrom: "talentCommunity",
      for: "sms",
    };
    history.push({
      pathname: "/high5hire/broadcast",
      state: obj,
    });
  };

  const handleShowMail = (candidate) => {
    setData(
      data.map((c) => {
        if (c.email === candidate.email)
          return { ...candidate, showMail: !candidate.showMail };
        else return c;
      })
    );
  };

  const toggleActionsDropdown = () => {
    if (toggleActions === false) {
      setToggleActions(true);
    } else {
      setToggleActions(false);
    }
  };

  return (
    <div class="row d-block-force mt-2">
      {arrayList?.length === 0 ? (
        <NoRecord />
      ) : (
        <div className="col ">
          {(getUserRole() == "Recruiter" ||
            getUserRole() == "CustomerSuccess") &&
            isFilterEnabled() && (
              <div
                className="d-flex  align-items-center  mb-2 "
                style={{ paddingLeft: 10 }}
              >
                <div
                  className="d-flex align-items-start mb-1 justify-content-center h-100 dropdown "
                  ref={selectAllRef}
                >
                  <input
                    type="checkbox"
                    className="form-check-input "
                    style={{ width: "1.5rem", borderRadius: "100" }}
                    checked={selectedTalents.length > 0}
                    // onClick={() => {
                    //   selectedTalents.length === dataTotal
                    //     ? setSelectedTalents([])
                    //     : handleSelectAll();
                    // }}
                    onClick={toggleActionsDropdown}
                  />
                  {toggleActions && (
                    <div
                      className="dropdown-menu"
                      style={{
                        display: "revert",
                        left: "-4px",
                        top: "32px",
                        zIndex: 89,
                      }}
                    >
                      <div>
                        <button
                          onClick={() => {
                            handleSelectAll();
                          }}
                          disabled={selectedTalents.length === dataTotal}
                          className="dropdown-item btn-xs"
                        >
                          Select All
                        </button>
                        <hr className="p-0 m-1" />
                      </div>{" "}
                      <div>
                        <button
                          onClick={() => {
                            setSelectedTalents([]);
                          }}
                          disabled={selectedTalents.length === 0}
                          className="dropdown-item btn-xs"
                        >
                          Deselect All
                        </button>
                      </div>{" "}
                    </div>
                  )}
                </div>

                <div className="px-2">
                  {" "}
                  <strong>
                    {selectedTalents?.length}{" "}
                    {selectedTalents?.length === 1 ? "Talent" : "Talents"}{" "}
                    selected
                  </strong>{" "}
                </div>
                {/* <div className="px-2">
                <button
                  type="button"
                  disabled={selectedTalents.length === dataTotal}
                  className="btn btn-text"
                >
                  <span className="font-bold font-14" onClick={handleSelectAll}>
                    Select All
                  </span>
                </button>
              </div>
              {selectedTalents.length > 0 && (
                <div className="px-2">
                  <button type="button" className="btn btn-text">
                    <span
                      className="font-bold font-14"
                      onClick={() => {
                        setSelectedTalents([]);
                      }}
                    >
                      Deselect All
                    </span>
                  </button>
                </div>
              )} */}
              </div>
            )}
          {arrayList?.map((candidate, i) => {
            return (
              <div className="d-flex gap-2" key={candidate.userId}>
                <div className="d-block card mb-1 w-100">
                  <div className="d-flex flex-row align-items-stretch justify-content-between   p-relative  d-block-768">
                    <div
                      className={`  ${
                        matches
                          ? "card-body font-regular d-flex align-items-center"
                          : " card-body font-regular d-flex align-items-center col-8"
                      } `}
                    >
                      {(getUserRole() == "Recruiter" ||
                        getUserRole() == "CustomerSuccess") &&
                        isFilterEnabled() && (
                          <div className="d-flex align-items-start mb-1 justify-content-center h-100">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              style={{ width: "1.5rem", borderRadius: "100" }}
                              checked={selectedTalents.some(
                                (c) => c.userId === candidate?.userId
                              )}
                              onClick={() => toggleSelectedTalent(candidate)}
                            />
                          </div>
                        )}
                      <div className="mx-2">
                        <span>
                          <ProfileImage
                            imageSrc={candidate?.profileImagePath}
                            imageWidth={"55"}
                            imageHeight={"55"}
                            avatarSize={"55"}
                            avatarRound={"80px"}
                            firstName={candidate?.firstName}
                            lastName={candidate?.lastName}
                          />
                        </span>
                      </div>
                      <div className="w-100 ml-2">
                        <div
                          className="d-flex align-items-center mb-1"
                          onClick={() => {
                            onProfileClick(candidate);
                          }}
                        >
                          <h6
                            className="mb-0 me-2"
                            style={{ cursor: "pointer" }}
                          >
                            {candidate.firstName?.charAt(0)?.toUpperCase() +
                              candidate.firstName?.slice(1)}{" "}
                            {candidate.lastName?.charAt(0)?.toUpperCase() +
                              candidate.lastName?.slice(1)}
                          </h6>
                        </div>
                        <div className="font-regular font-primary font-gray1">
                          {candidate.designation && (
                            <span>{candidate.designation}</span>
                          )}{" "}
                          {candidate.designation &&
                            (candidate.city || candidate.state) &&
                            "/"}{" "}
                          {candidate?.city ? (
                            <span>{candidate.city ? candidate.city : ""}</span>
                          ) : (
                            ""
                          )}
                          {candidate?.country ? (
                            <span>
                              {candidate.country
                                ? (candidate?.city ? ", " : "") +
                                  candidate.country
                                : ""}
                            </span>
                          ) : (
                            ""
                          )}
                          <div className="mt-1 d-flex">
                            <span>
                              {candidate?.experienceLevel ===
                              "Less than one year"
                                ? "Fresher"
                                : candidate?.experienceLevel}
                            </span>
                          </div>
                          <div className="mt-1 d-flex">
                            <div className="mt-1 d-block">
                              {candidate.primarySkills &&
                                candidate.primarySkills
                                  .slice(0, 2)
                                  .map((item) => (
                                    <span className="tag tag-blue3 mb-1 mr-1">
                                      {item}
                                    </span>
                                  ))}
                              {candidate.primarySkills?.length > 2 &&
                                candidate.ViewAllSkill === true &&
                                candidate.primarySkills
                                  .slice(2, candidate.primarySkills?.length)
                                  .map((item) => (
                                    <>
                                      <span class="tag tag-blue3 mb-1 mr-1">
                                        {item}
                                      </span>
                                    </>
                                  ))}
                              {candidate.primarySkills?.length > 2 && (
                                <button
                                  onClick={(e) => onSkillClick(e, candidate)}
                                  type="button"
                                  class="btn btn-text"
                                >
                                  {!candidate.ViewAllSkill ? (
                                    <span>View all skills</span>
                                  ) : (
                                    <span>Show Less</span>
                                  )}
                                </button>
                              )}
                            </div>
                          </div>
                          <StatusData candidate={candidate} />
                        </div>
                      </div>
                    </div>
                    <div
                      class={`  ${
                        matches
                          ? "card-body font-regular d-flex flex-column align-items-end justify-content-center"
                          : "card-body font-regular d-flex flex-column align-items-end justify-content-center col-4"
                      }`}
                    >
                      {candidate.homePhone ? (
                        <div class="mb-1">
                          Contact no:
                          {candidate?.homePhone
                            ? new AsYouType().input(
                                candidate.homePhone[0] === "+"
                                  ? candidate.homePhone
                                  : candidate.homePhoneCode &&
                                    candidate.homePhoneCode.includes("+")
                                  ? `${candidate.homePhoneCode}${candidate.homePhone}`
                                  : `+${candidate.homePhone}`
                              )
                            : ""}
                          {/* {
                          (candidate.homePhone[0] === "+1" ||
                            candidate.homePhoneCode.includes("+1")) && ( */}
                          <button
                            type="button"
                            title="Send SMS"
                            class="btn btn-text"
                            onClick={() => {
                              setSelectedTalents([candidate]);
                              setIsCandidateFrom("sms");
                              setCandidateId(candidate.userId);
                              setCandidateData(candidate);
                              setShowCandidateProfile(true);
                              // setShowEmailModal(true);
                            }}
                          >
                            {candidate?.readStatus ? (
                              <FaCommentAlt />
                            ) : (
                              <div className="notification-icon">
                                <FaCommentAlt />
                                <span className="message-status"></span>
                              </div>
                            )}
                          </button>
                          {/* )} */}
                          {/* {((candidate.homePhone[0] === "+" &&
                            candidate.homePhone[0] !== "+1") ||
                            (candidate.homePhoneCode.includes("+") &&
                              !candidate.homePhoneCode.includes("+1"))) && (
                            <button
                              type="button"
                              title="Send SMS"
                              class="btn btn-text"
                              onClick={() => {
                                setSelectedTalents([candidate]);
                                setIsCandidateFrom("whatsApp");
                                setShowEmailModal(true);
                              }}
                            >
                              <IoLogoWhatsapp size={18} color="#2DC109" />
                            </button>
                          )} */}
                        </div>
                      ) : (
                        candidate?.workPhone && (
                          <div class="mb-1">
                            Contact no:{" "}
                            {candidate?.workPhone
                              ? new AsYouType().input(
                                  candidate.workPhone[0] === "+"
                                    ? candidate.workPhone
                                    : candidate.workPhoneCode &&
                                      candidate.workPhoneCode.includes("+")
                                    ? `${candidate.workPhoneCode}${candidate.workPhone}`
                                    : `+${candidate.workPhone}`
                                )
                              : ""}
                          </div>
                        )
                      )}

                      {candidate?.email !== "" && candidate?.email && (
                        <div
                          class="mb-1"
                          // style={{ maxWidth: matches ? "200px" : "250px" }}
                        >
                          Email:{" "}
                          <span
                            onClick={() => {
                              handleShowMail(candidate);
                              setCandidateData(candidate);
                            }}
                            className=""
                            title={candidate?.email}
                          >
                            {candidate.email.length > 16 && !candidate.showMail
                              ? candidate?.email.slice(0, 16) + "..."
                              : candidate?.email}
                          </span>
                          <button
                            type="button"
                            title="Send email"
                            class="btn btn-text"
                            onClick={() => {
                              setSelectedTalents([candidate]);
                              setIsCandidateFrom("email");
                              setCandidateId(candidate.userId);
                              setCandidateData(candidate);
                              setShowCandidateProfile(true);
                            }}
                          >
                            <i class="fa fa-envelope"></i>
                          </button>
                        </div>
                      )}
                      {candidate.salaryType && (
                        <div className="mb-1">
                          {candidate.salaryType === "Hourly"
                            ? "Expected hourly salary:"
                            : candidate.salaryType === "Weekly"
                            ? "Expected weekly salary:"
                            : candidate.salaryType === "Monthly"
                            ? "Expected monthly salary:"
                            : "Expected annual salary:"}{" "}
                          {candidate.salaryType === "Hourly" ||
                          candidate.salaryType === "Weekly" ||
                          candidate.salaryType === "Monthly" ? (
                            <>
                              {CurrencySymbols[
                                candidate.minContractRateCurrency.slice(0, 3)
                              ] || ""}
                              {Number(
                                candidate.minContractRate
                              ).toLocaleString()}{" "}
                              {!CurrencySymbols[
                                candidate.minContractRateCurrency.slice(0, 3)
                              ]
                                ? candidate.minContractRateCurrency.slice(0, 3)
                                : ""}
                            </>
                          ) : (
                            <>
                              {CurrencySymbols[
                                candidate.preferredSalaryCurrency.slice(0, 3)
                              ] || ""}
                              {Number(
                                candidate.preferredSalary
                              ).toLocaleString()}{" "}
                              {!CurrencySymbols[
                                candidate.preferredSalaryCurrency.slice(0, 3)
                              ]
                                ? candidate.preferredSalaryCurrency.slice(0, 3)
                                : ""}
                            </>
                          )}
                        </div>
                      )}

                      <div class="mb-1">
                        Source channel:{" "}
                        {getTenantType() == 2
                          ? getUserTenantID() == 5788
                            ? jobBoard.includes(candidate.source)
                              ? "High5 Job Board"
                              : candidate.source == "Job Board"
                              ? "Crintell"
                              : candidate.source == "JobDiva"
                              ? "JobDiva"
                              : "Internal"
                            : candidate.source == "Job Board"
                            ? "Crintell"
                            : candidate.source == "JobDiva"
                            ? "JobDiva"
                            : "Internal"
                          : getTenantType() == 1
                          ? candidate.tenantId &&
                            candidate.tenantId
                              .map(Number)
                              .includes(getUserTenantID())
                            ? "Internal"
                            : candidate.fk_tenant &&
                              candidate.fk_tenant == getUserTenantID()
                            ? "Internal"
                            : "External"
                          : candidate.source == "Job Board"
                          ? "Crintell"
                          : candidate.source == "JobDiva"
                          ? "JobDiva"
                          : "Internal"}
                      </div>
                      {/* <div class="mb-1">
                        Source channel:{" "}
                        {getUserRole() === "HiringManager"
                          ? getUserTenantID() == candidate.fk_tenant
                            ? "Internal"
                            : "External"
                          : candidate.source === "Crintell"
                          ? "Crintell"
                          : careerPage.includes(candidate.source)
                          ? "Career Page"
                          : "Internal"}
                      </div> */}

                      {candidate.RecruiterName !== "" &&
                        candidate.RecruiterName && (
                          <div class="mb-1">
                            Recruiter name:{" "}
                            {candidate.RecruiterName.length > 1 ? (
                              !candidate.showAllRecs ? (
                                <span
                                  style={{ cursor: "pointer" }}
                                  className=" "
                                  onClick={() => toggleShowRec(candidate)}
                                >
                                  {candidate.RecruiterName[0] + "..."}{" "}
                                </span>
                              ) : (
                                candidate.RecruiterName.join(", ")
                              )
                            ) : (
                              candidate.RecruiterName
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="p-2 border-top d-flex justify-content-between">
                    <div className="mt-1 ml-2 d-flex">
                      {(getUserRole() === "Recruiter" ||
                        selectedData.tags.length > 0) && (
                        <div className="mt-1 d-block">
                          {JSON.parse(candidate?.candidateTags)?.length > 0
                            ? "Tags: "
                            : ""}
                          {candidate?.candidateTags &&
                            JSON.parse(candidate?.candidateTags)
                              ?.slice(0, 2)
                              ?.map((item) => (
                                <span className="tag mb-1 mr-1">{item}</span>
                              ))}
                          {JSON.parse(candidate.candidateTags)?.length > 2 &&
                            candidate.ViewAllTags === true &&
                            JSON.parse(candidate?.candidateTags)
                              .slice(
                                2,
                                JSON.parse(candidate.candidateTags)?.length
                              )
                              .map((item) => (
                                <>
                                  <span class="tag mb-1 mr-1">{item}</span>
                                </>
                              ))}

                          {JSON.parse(candidate.candidateTags)?.length > 2 && (
                            <button
                              onClick={(e) => onTagViewClick(e, candidate)}
                              type="button"
                              class="btn btn-text"
                            >
                              {!candidate.ViewAllTags ? (
                                <span>View all tags</span>
                              ) : (
                                <span>Show Less</span>
                              )}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      {candidate.updatedDateTime !== "" &&
                        new Date() > new Date(candidate.updatedDateTime) && (
                          <div class="text-muted my-auto p-0">
                            Last updated: <i className="far fa-clock mr-2"></i>
                            <span>
                              {moment(
                                new Date(candidate.updatedDateTime),
                                "MMDDYYYY"
                              )
                                .fromNow()
                                .charAt(0) === "a"
                                ? moment(
                                    new Date(candidate.updatedDateTime),
                                    "MMDDYYYY"
                                  )
                                    .fromNow()
                                    .charAt(0)
                                    .toLocaleUpperCase() +
                                  moment(
                                    new Date(candidate.updatedDateTime),
                                    "MMDDYYYY"
                                  )
                                    .fromNow()
                                    .slice(1)
                                : moment(
                                    new Date(candidate.updatedDateTime),
                                    "MMDDYYYY"
                                  ).fromNow()}
                            </span>
                            &nbsp;
                          </div>
                        )}

                      <span className=" ">
                        {candidate?.newSletter === "Unsubscribed" ? (
                          <i
                            title="Unsubscribed"
                            class="fas fa-bell-slash"
                            style={{ color: " #b70000" }}
                          />
                        ) : (
                          <i
                            title="Subscribed "
                            class="fas fa-bell"
                            style={{ color: " #007100" }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}{" "}
          {talentDetails && talentDetails.length > 0 && (
            <>
              <Pagination
                currentPage={currentPage}
                totalItems={dataTotal}
                itemsPerPage={10}
                onPageChange={handlePageChange}
                limit={10}
              />
            </>
          )}
          {/* <div class="d-flex align-items-center mt-3 mb-2 flex-wrap-370">
            <div class="text-center-370 w-100-370 mb-point5-370">
              Showing{" "}
              {paginationArray?.length === dataSize
                ? dataSize * currentPage
                : dataSize * (currentPage - 1) + paginationArray?.length}{" "}
              of {data?.length}
            </div>
            <div class="ml-auto mx-auto-370 d-flex align-items-center">
              {" "}
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setPaginationArray={setPaginationArray}
                data={data}
                dataSize={dataSize}
                getTab=""
                pageSize={pageSize}
              />
            </div>
          </div> */}
        </div>
      )}

      {showCandidateProfile && (
        <TalentProfile
          dropdownRef={dropdownRef}
          candidateId={candidateId}
          candidateData={candidateData}
          setCandidateData={setCandidateData}
          handleSuccess={handleModel}
          parent="TalentListView"
          setShowCandidateProfile={setShowCandidateProfile}
          getTalentList={getTalentList}
          handleClear={handleClear}
          isFrom={isFrom}
          isFor={isFor}
          parentTab={parentTab}
          isCandidateFrom={isCandidateFrom}
          setIsCandidateFrom={setIsCandidateFrom}
        />
      )}
      {/* {showEmailModal && (
        <BulkCommunicationModal
          isCandidateFrom={isCandidateFrom}
          isModalOpen={showEmailModal}
          setModalClose={setShowEmailModal}
          selectedTalents={selectedTalents}
          isFrom={"TalentProfileEdit"}
          modalSize={"xl"}
        />
      )} */}
    </div>
  );
};

export default TalentsList;
