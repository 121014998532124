import React, { useState, useEffect } from "react";
import Select from "react-select";
import { post } from "../../../../service/ApiService";
import { toast } from "react-toastify";
import { googleApiKey } from "../../../../constants/Constants";

const LocationFilter = ({
  handleFilterToggle,
  handleAPICalls,
  filterStatus,
  stateArr,
  countryArr,
  selectedCountry,
  setSelectedCountry,
  fetchCountries,
  selectedState,
  setSelectedState,
  fetchStates,
  selectedData,
  setSelectedData,
  applyFilter,
  isLoadingCountry,
  isLoadingState,
  selectedCity,
  setSelectedCity,
  cityEnabled,
  stateEnabled,
  countryEnabled,
  isFrom,
}) => {
  // Declare necessary state variables
  // const [selectedRadius, setSelectedRadius] = useState("10"); // Default radius option

  // Define options for radius dropdown
  // const radiusOptions = [
  //   { value: "10", label: "Less than 10 miles........." },
  //   { value: "20", label: "Less than 20 miles" },
  //   { value: "50", label: "Less than 50 miles" },
  // ];

  // useEffect(() => {
  //   if (selectedData.address.length === 0) {
  //     setSelectedRadius("10");
  //   }
  // }, [selectedData.address]);
  // Event handler for radius dropdown change
  // const handleRadiusChange = (selectedOption) => {
  //   const radiusValue = selectedOption.value;
  //   if (selectedData.address.length === 0) {
  //     // If no city is selected, show an error toast
  //     toast.error("Please select a city");
  //     return;
  //   }
  //   const cities = selectedData.address.map((city) => ({
  //     label: `${city.label}`,
  //     value: `${city.value}`,
  //     latitude: city.latitude,
  //     longitude: city.longitude,
  //     radius: radiusValue,
  //   }));

  //   setSelectedRadius(radiusValue);
  //   applyFilter(cities, "address");
  // };

  //const googleApiKey = "AIzaSyDI6_bChtt67mHuGbjUH2be_da6ohX1RhA";
  // Replace with your actual API key
  // const [selectedRadius, setSelectedRadius] = useState(null);

  // const radiusOptions = Array.from({ length: 21 }, (_, index) => index * 5); // Generates [0, 5, 10, ..., 100]

  // const handleRadiusChange = (event) => {
  //   const value = event.target.value;
  //   setSelectedRadius(value === "NoRadius" ? null : parseInt(value, 10));
  // };

  const [options, setOptions] = useState([]);
  const [loadingCity, setLoadingCity] = useState(false);
  // const [radiusValue, setRadiusValue] = useState([]);
  const fetchCities = async (inputValue) => {
    try {
      const response = await post(`/jobfilterData/googleCities`, {
        text: inputValue ?? "",
        key: googleApiKey,
      });

      const cities = response.data.map((city) => ({
        label: `${city.cityName}`,
        value: city.cityName,
        // latitude: city.latitude,
        // longitude: city.longitude,
        // radius: selectedRadius,
        city: city.city, // Update the radius value here
      }));

      setOptions(cities);
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
  };

  const handleInputChange = (inputValue) => {
    setInputValueCity(inputValue);
  };

  const [inputValueCity, setInputValueCity] = useState([]);
  const [inputValueState, setInputValueState] = useState("");
  const [inputValueCountry, setInputValueCountry] = useState("");

  const handleInputChangeState = (searchValue) => {
    setInputValueState(searchValue);
  };
  const handleInputChangeCountry = (searchValue) => {
    setInputValueCountry(searchValue);
  };

  useEffect(() => {
    let timeoutId;
    const fetchData = async () => {
      try {
        await fetchStates(inputValueState);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };
    const delay = 1000;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fetchData();
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [inputValueState]);

  useEffect(() => {
    let timeoutId;
    const fetchData = async () => {
      try {
        await fetchCountries(inputValueCountry);
      } catch (error) {
        console.error("Error fetching options:", error);
      }
    };

    const delay = 1000;

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fetchData();
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [inputValueCountry]);

  useEffect(() => {
    let timeoutId;
    const fetchData = async () => {
      try {
        setLoadingCity(true);
        await fetchCities(inputValueCity);
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
        setLoadingCity(false);
      }
    };

    const delay = 1000;

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fetchData();
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [inputValueCity]);

  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),

    dropdownIndicator: (provided) => ({ ...provided, display: "none" }),
    indicatorSeparator: () => ({
      display: "none", // Hide the indicator separator
    }),
  };
  const handleChange = (val, filterStatus) => {
    setSelectedData({
      ...selectedData,
      address: [],
      state: "",
      country: "",
      [filterStatus]: val,
    });
    applyFilter(val, filterStatus);
  };

  const handleChangeCity = (val) => {
    setSelectedData({
      ...selectedData,
      state: "",
      country: "",
      address: val,
    });
    applyFilter(val, "address");
  };

  useEffect(() => {
    if (selectedState.value) {
      setSelectedCity("");
      setSelectedCountry("");
    }
  }, [selectedState]);
  useEffect(() => {
    if (selectedCity.value) {
      setSelectedState("");
      setSelectedCountry("");
    }
  }, [selectedCity]);
  useEffect(() => {
    if (selectedCountry.value) {
      setSelectedCity("");
      setSelectedState("");
    }
  }, [selectedCountry]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && filterStatus !== "sourceChannel") {
      event.preventDefault(); // Prevent form submission or any default behavior

      const inputValue = event.target.value.toLowerCase().trim();
      const matchedOptions = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue)
      );

      let data =
        (selectedData[filterStatus] && selectedData[filterStatus]?.slice()) ||
        [];
      matchedOptions.forEach((option) => {
        if (
          !data.some((existingOption) => existingOption.value === option.value)
        ) {
          data.push(option); // Add only if it's not already present
        }
      });
      setSelectedData({
        ...selectedData,
        state: "",
        country: "",
        address: data,
      });
      applyFilter(data, "address");
    }
  };

  const getOptionLabel = (option) => {
    return option.value === selectedCountry.value ? option.value : option.label; // Display the value instead of the label
  };
  const getOptionLabelState = (option) => {
    return option.value === selectedState.value ? option.value : option.label; // Display the value instead of the label
  };
  return (
    <>
      <div className="mb-2">
        <button
          type="button"
          className="btn btn-collapsible"
          onClick={() => handleFilterToggle("country")}
        >
          <i
            className={
              countryEnabled
                ? "fal fa-angle-up fa-fw"
                : "fal fa-angle-down fa-fw"
            }
          />
          <span className="p-relative">
            <small>Country</small>
            {selectedData?.country !== "" && <span className="jewel"></span>}
          </span>
        </button>
        {countryEnabled && (
          <div className="d-flex flex-column gap-2 p-2">
            <div className="">
              <Select
                options={isLoadingCountry ? [] : countryArr}
                isSearchable={true}
                onChange={(val) => {
                  handleChange(val?.value ?? "", "country");
                  setSelectedCountry(val ?? "");
                }}
                styles={styleSheet}
                value={
                  selectedCountry.value === selectedData.country
                    ? selectedCountry
                    : selectedData.country
                    ? {
                        label: selectedData.country,
                        value: selectedData.country,
                      }
                    : ""
                }
                getOptionLabel={getOptionLabel}
                isMulti={false}
                onInputChange={handleInputChangeCountry}
                isLoading={isLoadingCountry}
                placeholder={"Country"}
                isClearable
                onMenuOpen={() => {
                  // Trigger your API call here
                  handleAPICalls("country");
                }}
              />
            </div>
            <div class="d-flex justify-content-end mt-2 px-1">
              <button
                disabled={selectedData.country === ""}
                className="btn btn-text"
                onClick={() => {
                  setSelectedData({
                    ...selectedData,
                    country: "",
                  });
                  applyFilter("", "country");
                }}
              >
                <span className="font-12">
                  <small>Clear Country</small>
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
      <div className="mb-2">
        <button
          type="button"
          className="btn btn-collapsible"
          onClick={() => handleFilterToggle("state")}
        >
          <i
            className={
              stateEnabled ? "fal fa-angle-up fa-fw" : "fal fa-angle-down fa-fw"
            }
          />
          <span className="p-relative">
            <small>State</small>
            {selectedData?.state !== "" && <span className="jewel"></span>}
          </span>
        </button>
        {stateEnabled && (
          <div className="d-flex flex-column gap-2 p-2">
            <div className="">
              <Select
                options={isLoadingState ? [] : stateArr}
                isSearchable={true}
                onChange={(val) => {
                  handleChange(val?.value ?? "", "state");
                  setSelectedState(val ?? "");
                }}
                styles={styleSheet}
                value={
                  selectedState.value === selectedData.state
                    ? selectedState
                    : ""
                }
                getOptionLabel={getOptionLabelState}
                isMulti={false}
                onInputChange={handleInputChangeState}
                isLoading={isLoadingState}
                placeholder={"State"}
                isClearable
                onMenuOpen={() => {
                  // Trigger your API call here
                  handleAPICalls("state");
                }}
              />
            </div>
            <div class="d-flex justify-content-end mt-2 px-1">
              <button
                disabled={selectedData.state === ""}
                className="btn btn-text"
                onClick={() => {
                  setSelectedData({
                    ...selectedData,
                    state: "",
                  });
                  applyFilter("", "state");
                }}
              >
                <span className="font-12">
                  <small>Clear State</small>
                </span>
              </button>
            </div>
          </div>
        )}
      </div>{" "}
      <div className="mb-2">
        <button
          type="button"
          className="btn btn-collapsible"
          onClick={() => handleFilterToggle("city")}
        >
          <i
            className={
              cityEnabled ? "fal fa-angle-up fa-fw" : "fal fa-angle-down fa-fw"
            }
          />
          <span className="p-relative">
            <small>City</small>
            {selectedData?.address?.length > 0 && (
              <span className="jewel"></span>
            )}
          </span>
        </button>
        {cityEnabled && (
          <div className="d-flex flex-column gap-2 p-2">
            <div className="">
              <Select
                options={loadingCity ? [] : options}
                isSearchable={true}
                styles={styleSheet}
                onChange={(val) => {
                  handleChangeCity(val);
                  // if (!val || val.length === 0) {
                  //   setSelectedRadius("10"); // Reset selected radius value to default if no city is selected
                  // }
                }}
                value={selectedData.address}
                onInputChange={handleInputChange}
                isLoading={loadingCity}
                placeholder="City"
                isMulti={true}
                onKeyDown={handleKeyDown}
                isClearable
                onMenuOpen={() => {
                  // Trigger your API call here
                  handleAPICalls("city");
                }}
              />
            </div>
            {isFrom != "assignCurator" && (
              <div className="mt-2">
                {/* <Select
                  options={radiusOptions}
                  value={radiusOptions.find(
                    (option) => option.value === selectedRadius
                  )}
                  onChange={handleRadiusChange}
                  placeholder="Select Radius"
                /> */}
              </div>
            )}

            <div class="d-flex justify-content-end mt-2 px-1">
              <button
                disabled={selectedData.address.length === 0}
                className="btn btn-text"
                onClick={() => {
                  setSelectedData({
                    ...selectedData,
                    address: [],
                  });
                  applyFilter("", "address");
                  // setSelectedRadius("10"); // Reset selected radius value to default
                }}
              >
                <span className="font-12">
                  <small>Clear City</small>
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default LocationFilter;
