import React, { useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";

function Experience(props) {
  const { dropDownData } = props;

  const inputDataObj = {
    employerName: "",
    industry: "",
    jobTitle: "",
    startDate: "",
    endDate: "",
    description: "",
    isSelect: false,
  };

  const inputValObj = {
    employerName: "",
    industry: "",
    jobTitle: "",
    startDate: "",
    endDate: "",
  };

  const handleChange = (e, i, startDate) => {
    if (e.target.id === "isSelect") {
      let newArray = props.experiences.map((item) => {
        item.isSelect = false;
        return item;
      });
      newArray = [...newArray];
      newArray[i] = {
        ...newArray[i],
        [e.target.id]: e.target.checked,
        endDate: "",
      };
      props.setExperiences(newArray);
      // props.setProfileInfo({ ...props.profileInfo, currentEmployer: newArray[i].employerName, designation: newArray[i].jobTitle })
    } else if (e.target.id === "startDate") {
      let newArray = [...props.experiences];
      newArray[i] = {
        ...newArray[i],
        [e.target.id]: e.target.value,
        endDate: "",
      };
      props.setExperiences(newArray);
    } else if (e.target.id === "endDate" && startDate === "") {
      toast.error("Please Choose Start Date before choosing End Date");
    } else if (e.target.id === "endDate" && e.target.value === startDate) {
      toast.error("End Date and Start Date Cannot be the Same");
    } else if (e.target.id === "endDate" && e.target.value < startDate) {
      toast.error("End Date Cannot Be Less Than Start Date");
    } else {
      let newArray = [...props.experiences];
      newArray[i] = { ...newArray[i], [e.target.id]: e.target.value };
      props.setExperiences(newArray);
    }
  };

  // Delete Experence
  const handleDelete = (e, i) => {
    props.setExperiences(
      props.experiences.filter((item, index) => index !== i)
    );
    props.setExperienceValidation(
      props.experienceValidation.filter((item, index) => index !== i)
    );
  };

  // Add Experence
  const handleAdd = () => {
    props.setExperiences([...props.experiences, inputDataObj]);
    props.setExperienceValidation([...props.experienceValidation, inputValObj]);
  };

  return (
    <div>
      <div class="work-experience">
        {props?.experiences?.map((experience, i) => (
          <div class="d-flex" v-for="item in list">
            <div class="mr-3">
              <div class="avatar avatar-sm">{i + 1}</div>
            </div>
            <div>
              <div class="row mb-2">
                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Employer Name"
                      id="employerName"
                      style={{
                        border:
                          props.experienceValidation[i]?.employerName &&
                          "2px solid #ff0000",
                      }}
                      value={experience.employerName}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <label>Employer Name</label>
                  </div>
                  <small className="validation">
                    {props.experienceValidation[i]?.employerName}
                  </small>
                </div>
                <div class="col-lg-6">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="industry"
                      style={{
                        border:
                          props.experienceValidation[i]?.industry &&
                          "2px solid #ff0000",
                      }}
                      value={experience.industry}
                      onChange={(e) => handleChange(e, i)}
                    >
                      <option value="" selected>
                        Select Industry
                      </option>
                      {dropDownData?.Department?.sort((a, b) =>
                        a.SourceName > b.SourceName ? 1 : -1
                      ).map((item) => (
                        <option value={item.SourceName}>
                          {item.SourceName}
                        </option>
                      ))}
                    </select>
                    <label>Industry</label>
                  </div>
                  <small className="validation">
                    {props.experienceValidation[i]?.industry}
                  </small>
                </div>
              </div>

              <div class="row mb-2">
                <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Job Title"
                      id="jobTitle"
                      style={{
                        border:
                          props.experienceValidation[i]?.jobTitle &&
                          "2px solid #ff0000",
                      }}
                      value={experience.jobTitle}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <label>Job Title</label>
                  </div>
                  <small className="validation">
                    {props.experienceValidation[i]?.jobTitle}
                  </small>
                </div>
                <div class="col-lg-4">
                  <div class="form-floating form-date">
                    <label>Start Date</label>
                    <input
                      type="date"
                      max="2050-12-31"
                      class="form-control"
                      placeholder="Start Date"
                      id="startDate"
                      style={{
                        border:
                          props.experienceValidation[i]?.startDate &&
                          "2px solid #ff0000",
                      }}
                      value={experience.startDate}
                      onChange={(e) => handleChange(e, i)}
                    />
                  </div>
                  <small className="validation">
                    {props.experienceValidation[i]?.startDate}
                  </small>
                </div>
                <div class="col-lg-4">
                  <div class="form-floating form-date">
                    <label>End Date</label>
                    <input
                      type="date"
                      max="2050-12-31"
                      disabled={experience.isSelect}
                      class="form-control"
                      placeholder="End Date"
                      id="endDate"
                      style={{
                        border:
                          props.experienceValidation[i]?.endDate &&
                          "2px solid #ff0000",
                      }}
                      value={experience.endDate}
                      onChange={(e) => handleChange(e, i, experience.startDate)}
                    />
                  </div>
                  <small className="validation">
                    {props.experienceValidation[i]?.endDate}
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="form-floating">
                    <textarea
                      class="form-control"
                      placeholder="Experience Description"
                      id="description"
                      value={experience.description}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <label>Description</label>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 d-flex align-items-center">
                <div className="mr-2 mt-3">
                  <p>Current Employer </p>
                </div>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="isSelect"
                    checked={experience.isSelect}
                    onChange={(e) => handleChange(e, i)}
                  />
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <hr />
                </div>
              </div>
            </div>
            <div class="ml-3">
              {props.experiences.length !== 1 && (
                <button
                  type="button"
                  class="btn btn-icon"
                  onClick={(e) => handleDelete(e, i)}
                >
                  <i class="fal fa-fw fa-trash-alt"></i>
                </button>
              )}
            </div>
          </div>
        ))}

        <div>
          <button type="button" onClick={handleAdd} class="btn btn-sm btn-text">
            <i class="fal fa-plus mr-1" aria-hidden="true"></i>
            <strong>Add Experience</strong>
          </button>
        </div>
      </div>
    </div>
  );
}
export default Experience;
