import {
    GET_USER_LIST_BEGIN,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_FAILURE
  } from "../../actions/executiveadmin/High5User";
  
  const initialState = {
    userListLoading: true,
    userList: [],
  };
  
  const userListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_USER_LIST_BEGIN:
        return {
          ...state,
          userListLoading: true,
          userList: [],
        };
      case GET_USER_LIST_SUCCESS:
        return {
          ...state,
          userList: payload,
          userListLoading: false,
        };
      case GET_USER_LIST_FAILURE:
        return {
          ...state,
          userList: [],
          userListLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default userListReducer;
  