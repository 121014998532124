import React from "react";
// import "../style.css";

const JobInfo = (props) => {
  return (
    <>
      <div
        className={
          props.status !== "career" ? "main_container" : "main_container_1"
        }
      >
        <div className="main_container-overview">
          <div className="main_container-content">
            <div className="main_container-content_heading">
              <span
                className={
                  props.status === "career"
                    ? "main_container-content_heading_text"
                    : "main_container-content_heading_text_1"
                }
              >
                {props.title}
              </span>
              <span class="tag tag-lg tag-font_accent d-inline-flex text-capitalize">
                <span class="mx-2">{props?.jobData?.jobStatusName}</span>
              </span>
            </div>
            {props.status === "career" && (
              <div className="main_container-content_sub-heading">
                <span className="main_container-content_sub-heading_text">
                  {props.description}
                </span>
              </div>
            )}
            {props.status === "jobApply" && (
              <>
                <div className="main_container-key_points">
                  {props?.jobData?.tenantName && (
                    <div className="main_container-key_points_style bg-powderblue">
                      <span className="main_container-key_points_style_text font-bold ">
                        Company Name : {props?.jobData?.tenantName}
                      </span>
                    </div>
                  )}
                  {props?.jobData?.workPlaceType == "Remote" && (
                    <div className="main_container-key_points_style">
                      <span className="main_container-key_points_style_text">
                        {props?.jobData?.workPlaceType}
                      </span>
                    </div>
                  )}
                  {props?.jobData?.workPlaceType !== "Remote" && (
                    <div className="main_container-key_points_style">
                      <span className="main_container-key_points_style_text text-capitalize">
                        {`${props?.jobData?.workPlaceType} : ${props?.jobData?.city},${props?.jobData?.state},${props?.jobData?.country}`}
                      </span>
                    </div>
                  )}
                  <div className="main_container-key_points_style">
                    <span className="main_container-key_points_style_text">
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: props?.jobData?.salaryCurrency
                          ? `${props?.jobData?.salaryCurrency}`
                          : "USD",
                        maximumSignificantDigits: 4,
                      }).format(props?.jobData?.minimumPay)}{" "}
                      to{" "}
                      {new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: props?.jobData?.salaryCurrency
                          ? `${props?.jobData?.salaryCurrency}`
                          : "USD",
                        maximumSignificantDigits: 3,
                      }).format(props?.jobData?.maximumPay)}{" "}
                      {props?.jobData?.minMaxPaymentType == "Annual"
                        ? "Annual"
                        : "Hourly"}
                    </span>
                  </div>
                  <div className="main_container-key_points_style">
                    <span className="main_container-key_points_style_text">
                      {props?.jobData?.jobType}
                    </span>
                  </div>
                  <div className="main_container-key_points_style">
                    <span className="main_container-key_points_style_text">
                      {props?.jobData?.experienceLevel}
                    </span>
                  </div>
                  <div className="main_container-key_points_style">
                    <span className="main_container-key_points_style_text">
                    {props.jobData?.visaStatus && (
                        <span
                          className="d-flex align-items-center "
                          // style={{ marginLeft: "764px" }}
                        >
                          <i class="far fa-plane-departure"></i>{" "}
                          <span
                            className="font-regular "
                            style={{ marginLeft: "8px" }}
                          >
                            {JSON.parse(props.jobData?.visaStatus).join(", ")}
                          </span>
                        </span>
                      )}
                    </span>
                  </div>
                </div>
                
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default JobInfo;
