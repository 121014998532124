import React, { useState } from "react";
import moment from "moment";
import TalentProfile from "../../../../talent/talentprofile/TalentProfile";
import ProfileImage from "../../../../commoncomponents/profileimage/ProfileImage";
import { FaCommentAlt } from "react-icons/fa";

const Qualified = (props) => {
  const { jobData, qualifiedData } = props;

  const [candidateProfile, setCandidateProfile] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [isCandidateFrom, setIsCandidateFrom] = useState("");

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };

  const checkDuplicate = (item) => {
    let data = qualifiedData.filter((f) => f.email == item.email);
    return data.length > 1 ? true : false;
  };
  return (
    <>
      {qualifiedData.map((item, i) => (
        <tr key={i} className="mb-2">
          <td
            onClick={() => onProfileClick(item)}
            style={{ display: "flex", alignItems: "center" }}
          >
            <div className="d-flex flex-inline w-70">
              <ProfileImage
                imageSrc={item?.profileImagePath}
                imageWidth={"35"}
                imageHeight={"35"}
                avatarSize={"35"}
                avatarRound={"80px"}
                firstName={item?.firstName}
                lastName={item?.lastName}
              />
              <span className="ml-2 font-16">
                {item?.firstName.charAt(0).toUpperCase() +
                  item?.firstName.slice(1)}{" "}
                {item?.lastName.charAt(0).toUpperCase()}
                {checkDuplicate(item) ? (
                  <span class="ml-2 tag font-bold tag-red1">D</span>
                ) : (
                  ""
                )}
              </span>
            </div>
            <div className="d-flex justify-content-start">
              {!item?.readStatus && (
                <button
                  type="button"
                  title="Send SMS"
                  class="btn btn-text"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    setCandidateProfile(true);
                    setIsCandidateFrom("sms");
                  }}
                >
                  <div className="notification-icon">
                    <FaCommentAlt />
                    <span className="message-status"></span>
                  </div>
                </button>
              )}
            </div>
          </td>
          <td className="no-hover">
            <span className="ml-2">
              {item.invitedDate
                ? moment(item.invitedDate).format("MM/DD/YYYY")
                : moment(item.automatchDate).format("MM/DD/YYYY")}
            </span>
          </td>
          <td className="text-left p-1">
            {item.dispositionStatus == 33 && <span>Invited</span>}
            {item.dispositionStatus == 38 && <span>Automatched</span>}
          </td>
          <td>{item.URLOpenCount ? item.URLOpenCount : 0}</td>
        </tr>
      ))}
      {candidateProfile && (
        <TalentProfile
          candidateData={selectedCandidate}
          handleSuccess={handleModel}
          jobData={jobData}
          parent="inviteTab"
          isFrom="inviteTab"
          // poolId={poolId}
          isCandidateFrom={isCandidateFrom}
          setIsCandidateFrom={setIsCandidateFrom}
        />
      )}
    </>
  );
};

export default Qualified;
