import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

const ResumeParsed = ({ resumeFile, setResumeFile, matches, isFormValid }) => {
  const [hideResumeBtn, setHideResumeBtn] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(()=>{
    if(resumeFile.fileName){
      setHideResumeBtn(true);
    }
  },[resumeFile])
  const onDragDropChangeHandler = (file) => {
    var re = /(?:\.([^.]+))?$/;

    var ext = re.exec(file[0].name)[1]; // "

    if (ext === "pdf" || ext === "docx" || ext === "doc") {
      const { name } = file[0];
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (event) => {
        setResumeFile({ fileName: name, base64: event.target.result });
      };
      toast.success("Your resume uploaded successfully");
      setHideResumeBtn(true);
    } else {
      toast.error("Invalid file type");
    }
  };

  const onRemove = (e) => {
    setResumeFile({ fileName: "", base64: "" });
    setHideResumeBtn(false);
    setShowConfirmation(false);
    toast.success("Resume deleted successfully ");
  };

  return (
    <div className="w-100">
      <div
        className={`d-flex justify-content-start align-items-center  w-100 ${
          resumeFile && resumeFile.fileName ? " " : ""
        }`}
      >
        <div className={` mx-2 ${!matches ? "w-50  " : ""}`}>
          <Dropzone
            onDrop={(file) => onDragDropChangeHandler(file)}
            multiple={false}
            disabled={resumeFile && resumeFile.fileName}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="">
                <button
                  type="button"
                  className="btn btn-sm btn-secondary "
                  disabled={hideResumeBtn}
                >
                  <i className="far fa-cloud-upload mr-1 fa-fw"></i>
                  Upload Resume*
                </button>

                <input type="file" {...getInputProps()} accept=".docx, .pdf" />
              </div>
            )}
          </Dropzone>
        </div>
        {resumeFile && resumeFile.fileName && (
          <div className="d-flex gap-2 ">
            <button
              type="button"
              className="btn btn-icon  "
              aria-hidden="true"
              style={{ cursor: "default" }}
            >
              <span className="font-22">
                {" "}
                <i class="fa fa-check-circle" style={{ color: "green" }}></i>
              </span>
            </button>

            <button
              type="button"
              className="btn btn-icon  "
              aria-hidden="true"
              style={{ cursor: "default" }}
            >
              <span className="font-22">
                {" "}
                <i
                  class="fal fa-fw fa-trash-alt"
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={() => {
                    setShowConfirmation(true);
                  }}
                ></i>
              </span>
            </button>
          </div>
        )}
      </div>
      {isFormValid && resumeFile.fileName == "" && (
        <small className="validation mx-2">*Required</small>
      )}
      <Modal show={showConfirmation} size="md" animation={true}>
        <Modal.Header>
          <button
            type="button"
            onClick={() => setShowConfirmation(false)}
            className="close p-0 bl-modal-close-btn closable"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fal fa-times closable"></i>
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="m-5">
            <div class="text-center">
              <div class="avatar avatar-lg avatar-red2">
                <i
                  class="fas fa-exclamation-triangle fa-fw"
                  aria-hidden="true"
                ></i>
              </div>
              <p class="text-center lead mt-3">
                Are you sure you want to delete resume?
                <br />
                {resumeFile.fileName}
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div class="d-flex align-items-center justify-content-center">
            <Button
              variant="secondary"
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={onRemove} className="ml-2">
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ResumeParsed;
