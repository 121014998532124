import React, { useState, useEffect } from "react";
import TopNavBar from "../../layout/TopNavBar";
import SideNavBar from "../../layout/SideNavBar";
import "react-tabs/style/react-tabs.css";
import { getTenantType, getUserRole } from "../../../service/AuthService";
import Dashboard from "./Dashboard/Dashboard";
import MPMDashboard from "./Dashboard/MPMDashboard";
import Footer from "../../layout/Footer";
import SADashboard from "./Dashboard/SuperAdminDasboard";

function Index() {
  const [toggle, setToggle] = useState(true);

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };

  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar isFrom={"SAEAnew"} handleToggale={toggleDiv} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm ">
            <div class="container ">
              <div class="row justify-content-center">
                {getUserRole() === "CommunityManager" ? (
                  <MPMDashboard />
                ) : getUserRole() === "SuperAdmin" ? (
                  <SADashboard />
                ) : (
                  <Dashboard />
                )}
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
}
export default Index;
