import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const JobFilter = ({
  jobs,
  setFilteredData,
  selectedData,
  setSelectedData,
  applyFilter,
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedJobTypes, setSelectedJobTypes] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  const [showCategoryChecklist, setShowCategoryChecklist] = useState(false);
  const [showJobTypeChecklist, setShowJobTypeChecklist] = useState(false);
  const [showLocationChecklist, setShowLocationChecklist] = useState(false);
  const [showCityChecklist, setShowCityChecklist] = useState(false);

  useEffect(() => {
    let data = jobs?.filter(
      (j) =>
        (selectedCategories.length === 0 ||
          selectedCategories.includes(JSON.parse(j.industries)[0])) &&
        (selectedJobTypes.length === 0 ||
          selectedJobTypes.includes(j.jobType)) &&
        (selectedLocations.length === 0 ||
          selectedLocations.includes(j.workPlaceType)) &&
        (selectedCities.length === 0 || selectedCities.includes(j.city))
    );
    setFilteredData(data);
  }, [selectedCategories, selectedJobTypes, selectedLocations, selectedCities]);

  const categories = [
    "Information Technology (IT)",
    "Healthcare",
    "Accounting and Finance",
    "Administrative and Office Support",
    "Clinical and Scientific",
    "Customer Service and Call Center",
    "Data Entry and Clerical",
    "Engineering",
    "Human Resources (HR)",
    "Legal",
    "Manufacturing and Production",
    "Marketing and Creative",
  ];
  const jobTypes = [
    "Full Time",
    "Part Time",
    "Contract",
    "Temp to Hire",
    "Volunteer",
  ];

  const locations = ["Remote", "On-Site", "Hybrid"];

  const uniqueCitiesSet = new Set(
    jobs
      ?.filter((j) => j.city)
      ?.map((j) => JSON.stringify([j.city, j.state, j.country]))
  );

  const cities = Array.from(uniqueCitiesSet).map((cityString) =>
    JSON.parse(cityString)
  );

  const filterItems = (items, searchText) => {
    return items.filter((item) =>
      item.toLowerCase().includes(searchText.toLowerCase())
    );
  };
  const filterCities = (items, searchText) => {
    return items.filter((item) =>
      item[0].toLowerCase().includes(searchText.toLowerCase())
    );
  };

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleCheckboxChange = (
    selectedItems,
    selectedItem,
    setSelectedItems,
    type
  ) => {
    if (selectedItems.includes(selectedItem)) {
      setSelectedItems((prevItems) =>
        prevItems.filter((item) => item !== selectedItem)
      );
      let data = {
        ...selectedData,
        [type]: selectedData[type].filter((item) => item !== selectedItem),
      };
      setSelectedData(data);
      applyFilter(
        data[type].filter((item) => item !== selectedItem),
        type
      );
    } else {
      setSelectedItems((prevItems) => [...prevItems, selectedItem]);

      let data = {
        ...selectedData,
        [type]: [...selectedData[type], selectedItem],
      };
      setSelectedData(data);
      applyFilter([...data[type], selectedItem], type);
    }
  };

  useEffect(() => {
    console.log("select data--->", selectedData);
  }, [selectedData]);

  const handleToggleChecklist = (setShowChecklist) => {
    setShowChecklist((prevShowChecklist) => !prevShowChecklist);
  };

  const handleClear = () => {
    setSelectedCategories([]);
    setSelectedJobTypes([]);
    let data = {
      industries: [],
      jobLocation: [],
      jobType: [],
    };
    setSelectedData(data);
    applyFilter(data, "");
  };

  return (
    <>
      {" "}
      <div className="d-flex justify-content-between px-3">
        <h6>Filter Jobs</h6>
        <div className="">
          <button
            disabled={
              selectedCategories.length === 0 && selectedJobTypes?.length === 0
            }
            type="button"
            className="btn btn-text"
            onClick={handleClear}
          >
            <span className="font-bold">Clear All</span>
          </button>
        </div>
      </div>
      <div
        className="container mt-4 mb-1"
        style={{
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          background: "#F9F9F9",
        }}
      >
        {/* Category */}
        <div className="d-flex justify-content-between align-items-center px-2 ">
          <label htmlFor="searchCategory" className="mr-2">
            Category
          </label>
          <button
            className="btn btn-link btn-sm mt-3 "
            onClick={() => handleToggleChecklist(setShowCategoryChecklist)}
          >
            <FontAwesomeIcon icon={showCategoryChecklist ? faMinus : faPlus} />
          </button>
        </div>
        {showCategoryChecklist && (
          <div>
            {/* <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="searchCategory"
                  value={searchText}
                  onChange={handleSearchChange}
                  placeholder="Select Category"
                />
              </div>
            </div>
          </div> */}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <ul className="list-group" id="categoryList">
                    {categories.map((category) => (
                      <li key={category} className="list-group-item">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            style={{ width: "20px", height: "20px" }}
                            className="form-check-input mt-3"
                            id={`category${category}`}
                            value={category}
                            checked={selectedCategories.includes(category)}
                            onChange={() =>
                              handleCheckboxChange(
                                selectedCategories,
                                category,
                                setSelectedCategories,
                                "industries"
                              )
                            }
                          />
                          <label
                            className="form-check-label ml-2"
                            htmlFor={`category${category}`}
                          >
                            {category}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <hr
          style={{
            margin: "15px 0",
            marginBottom: "0",
            borderTop: "1px solid #B7AFAF",
          }}
        />
        {/* Job Type */}
        {/* Add similar code as for Category, replacing relevant names and data */}
        <div className="d-flex justify-content-between align-items-center px-2 ">
          <label htmlFor="searchJobType" className="mr-2">
            Job Type
          </label>
          <button
            className="btn btn-link btn-sm mt-3 "
            onClick={() => handleToggleChecklist(setShowJobTypeChecklist)}
          >
            <FontAwesomeIcon icon={showJobTypeChecklist ? faMinus : faPlus} />
          </button>
        </div>
        {showJobTypeChecklist && (
          <div>
            {/* <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="searchJobType"
                  value={searchText}
                  onChange={handleSearchChange}
                  placeholder="Select Job Type"
                />
              </div>
            </div>
          </div> */}
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <ul className="list-group" id="jobTypeList">
                    {filterItems(jobTypes, "").map((jobType) => (
                      <li key={jobType} className="list-group-item">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            style={{ width: "20px", height: "20px" }}
                            className="form-check-input mt-3"
                            id={`jobType${jobType}`}
                            value={jobType}
                            checked={selectedJobTypes.includes(jobType)}
                            onChange={() =>
                              handleCheckboxChange(
                                selectedJobTypes,
                                jobType,
                                setSelectedJobTypes,
                                "jobType"
                              )
                            }
                          />
                          <label
                            className="form-check-label ml-2"
                            htmlFor={`jobType${jobType}`}
                          >
                            {jobType}
                          </label>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
        <hr
          style={{
            margin: "15px 0",
            marginBottom: "0",
            borderTop: "1px solid #B7AFAF",
          }}
        />
        {/* Location */}
        {/* Add similar code as for Category, replacing relevant names and data */}
        {/* <div className="d-flex justify-content-between align-items-center px-2 ">
        <label htmlFor="searchLocation" className="mr-2">
          Work Place Type
        </label>
        <button
          className="btn btn-link btn-sm mt-3 "
          onClick={() => handleToggleChecklist(setShowLocationChecklist)}
        >
          <FontAwesomeIcon icon={showLocationChecklist ? faMinus : faPlus} />
        </button>
      </div>
      {showLocationChecklist && (
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="searchLocation"
                  value={searchText}
                  onChange={handleSearchChange}
                  placeholder="Select Work Place Type"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <ul className="list-group" id="locationList">
                  {filterItems(locations, searchText).map((location) => (
                    <li key={location} className="list-group-item">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          style={{ width: "20px", height: "20px" }}
                          className="form-check-input mt-3"
                          id={`location${location}`}
                          value={location}
                          checked={selectedLocations.includes(location)}
                          onChange={() =>
                            handleCheckboxChange(
                              selectedLocations,
                              location,
                              setSelectedLocations
                            )
                          }
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor={`location${location}`}
                        >
                          {location}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      <hr style={{ margin: "10px 0", borderTop: "1px solid #B7AFAF" }} /> */}

        {/* <div className="d-flex justify-content-between align-items-center px-2 ">
        <label htmlFor="searchCity" className="mr-2">
          Location
        </label>
        <button
          className="btn btn-link btn-sm mt-3 "
          onClick={() => handleToggleChecklist(setShowCityChecklist)}
        >
          <FontAwesomeIcon icon={showCityChecklist ? faMinus : faPlus} />
        </button>
      </div>
      {showCityChecklist && (
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  className="form-control"
                  id="searchCity"
                  value={searchText}
                  onChange={handleSearchChange}
                  placeholder="Select Location"
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <ul className="list-group" id="cityList">
                  {filterCities(cities, searchText).map((city) => (
                    <li key={city} className="list-group-item">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          style={{ width: "20px", height: "20px" }}
                          className="form-check-input mt-3"
                          id={`city${city[0]}`}
                          value={city[0]}
                          checked={selectedCities.includes(city[0])}
                          onChange={() =>
                            handleCheckboxChange(
                              selectedCities,
                              city[0],
                              setSelectedCities
                            )
                          }
                        />
                        <label
                          className="form-check-label ml-2"
                          htmlFor={`city${city}`}
                        >
                          {city.join(", ")}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      )} */}
        {/* <hr
        style={{
          margin: "15px 0",
          marginBottom: "0",
          borderTop: "1px solid #B7AFAF",
        }}
      /> */}
      </div>
    </>
  );
};

export default JobFilter;
