import React, { useState } from "react";
import "./style.css";
import { toast } from "react-toastify";
import QuestionList from "./QuestionList";

const DisplayQuestions = ({
  displayVetting,
  setCreateAssessment,
  setCreateAssessmentAI,
  generateAssessment,
  questionList,
  handleEdit,
  deleteQuestion,
  createAssessmentUsingAI,
  addJobData,
}) => {
  const [num, setNum] = useState(10);
  const [skills, setSkills] = useState(
    addJobData?.primarySkills
      ? addJobData?.primarySkills
          ?.flat(1)
          ?.filter((skill) => skill && skill.trim() !== "")
          ?.map((e) => e)
      : ""
  );
  const difficultyData = [
    { exp: "Intern", val: "Simple" },
    { exp: "Less than one year", val: "Simple" },
    { exp: "1-3 years", val: "Medium" },
    { exp: "3-5 years", val: "Medium" },
    { exp: "5-10 years", val: "Advanced" },
    { exp: "10+ years", val: "Advanced" },
  ];

  let difficulty = "";
  difficultyData.forEach((obj) => {
    if (obj.exp === addJobData.experienceLevel) difficulty = obj.val;
  });
  const [generated, setGenerated] = useState(false);
  const [instruction, setInstruction] = useState("");
  const [type, setType] = useState("All");
  const [difficultyLevel, setDifficultyLevel] = useState(difficulty);
  const handleClick = () => {
    const item = {
      num: num,
      skills: skills,
      instructions: instruction,
      type: type,
      difficulty: difficultyLevel,
    };
    if (skills === "" || num < 0) {
      toast.error("Fill all the required fields");
    } else {
      createAssessmentUsingAI(item);
      setGenerated(true);
    }
  };
  console.log("question list", questionList);
  return (
    <>
      <div className={displayVetting ? "m-3 mb-4" : "d-none"}>
        <div className="py-2 mx-3 mb-3">
          {/* <h6>Create Custom Vetting Criteria</h6> */}
          {generateAssessment === "AI" && (
            <>
              <form id="assessmentForm">
                <div class="form-group">
                  <label className="labelAI" for="assessmentType">
                    Assessment Type*:
                  </label>
                  <select
                    className="selectAI"
                    id="assessmentType"
                    required
                    onChange={(e) => setType(e.target.value)}
                    value={type}
                  >
                    <option value="All">All</option>
                    <option value="MCQ">Multiple-Choice questions</option>
                    <option value="Text">Short-Answer questions</option>
                    <option value="Video">Video-Answer questions</option>
                  </select>
                </div>
                <div class="form-group">
                  <label className="labelAI" for="topic">
                    Topic/Area/Skills*:
                  </label>
                  <input
                    className={skills === "" ? "inputAIError" : "inputAI"}
                    type="text"
                    id="topic"
                    value={skills}
                    required
                    onChange={(e) => {
                      if (e.target.value !== " ") {
                        setSkills(e.target.value);
                      }
                    }}
                  />
                </div>
                <div class="form-group">
                  <label className="labelAI" for="difficulty">
                    Difficulty Level*:
                  </label>
                  <select
                    className="selectAI"
                    id="difficulty"
                    required
                    onChange={(e) => setDifficultyLevel(e.target.value)}
                    value={difficultyLevel}
                  >
                    <option value="Simple">
                      Simple (Intern or Less than one year)
                    </option>
                    <option value="Medium">Medium (1-5 years)</option>
                    <option value="Advanced">Advance (5+ years)</option>
                  </select>
                </div>

                <div class="form-group">
                  <label className="labelAI" for="numQuestions">
                    Number of Questions*:
                  </label>
                  <input
                    className={
                      num < 0 || num == "" || num > 20
                        ? "inputAIError"
                        : "inputAI"
                    }
                    type="number"
                    id="numQuestions"
                    value={num}
                    min="3"
                    required
                    style={{ width: "15%" }}
                    onChange={(e) => {
                      const newValue = e.target.value.trim();
                      if (newValue !== "" && newValue <= 20) {
                        setNum(newValue);
                      }
                    }}
                  />
                  <span style={{ marginLeft: "10px" }}>
                    <input
                      className="inputAI"
                      type="text"
                      id="duration"
                      value={2 * num + " mins"}
                      readonly
                      disabled
                      style={{ width: "%" }}
                    />
                  </span>
                </div>

                <div class="form-group">
                  <label className="labelAI" for="additionalInstructions">
                    Additional Instructions:
                  </label>
                  <textarea
                    className="textareaAI"
                    id="additionalInstructions"
                    onClick={(e) => setInstruction(e.target.value)}
                  ></textarea>
                </div>
                {!generated && (
                  <>
                    <button
                      className="btn btn-primary m4-2"
                      disabled={generated}
                      onClick={() => handleClick()}
                    >
                      <i class="fas fa-cogs"></i> Generate{" "}
                    </button>{" "}
                    <span
                      className="ml-2"
                      style={{
                        border: "solid 1px gray",
                        paddingLeft: "4px",
                        paddingRight: "4px",
                      }}
                    >
                      BETA
                    </span>
                    <i
                      className="far fa-info-circle mr-2 ml-2"
                      // style={{ color: "grey" }}
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="This is an AI feature and can provider inaccurate or biased responses"
                    ></i>
                  </>
                )}
              </form>
            </>
          )}
          {generateAssessment === "Manual" && (
            <p>Click 'Add Question' to create a new assessment.</p>
          )}
          {questionList?.length > 0 && (
            <QuestionList
              questionList={questionList}
              handleEdit={handleEdit}
              deleteQuestion={deleteQuestion}
            />
          )}
          {generateAssessment === "Manual" && (
            <div className="mt-3">
              <p
                className="p-0 m-0 mt-1"
                // style={{ textAlign: "center" }}
              >
                <button
                  className="btn btn-sm btn-primary ml-2"
                  onClick={() => setCreateAssessment(true)}
                >
                  <i className="fas fa-plus mr-2"></i>
                  Add Question
                </button>
              </p>
            </div>
          )}
          {generateAssessment === "AI" && questionList?.length > 0 && (
            <>
              <div className="mt-3">
                <p
                  className="p-0 m-0 mt-1"
                  // style={{ textAlign: "center" }}
                >
                  {" "}
                  <button
                    className="btn btn-sm btn-warning "
                    onClick={() => setCreateAssessment(true)}
                  >
                    <i className="fas fa-plus mr-2"></i>
                    Add Question
                  </button>
                  <button
                    className="btn btn-sm btn-primary ml-2"
                    onClick={() => handleClick()}
                  >
                    <i class="fas fa-sync-alt"></i> Regenerate{" "}
                  </button>
                  <span
                    className="ml-2"
                    style={{
                      border: "solid 1px gray",
                      paddingLeft: "4px",
                      paddingRight: "4px",
                    }}
                  >
                    BETA
                  </span>
                  <i
                    className="far fa-info-circle mr-2 ml-2"
                    // style={{ color: "grey" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="This is an AI feature and can provider inaccurate or biased responses"
                  ></i>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DisplayQuestions;
