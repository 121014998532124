import React, { useState } from "react";
import sparkles from "../../../assets/lottie/sparkles.json";
import checkMark from "../../../assets/lottie/checkmark.json";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";
import { post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { getUserRole } from "../../../service/AuthService";

const { Anime } = ReactAnime;
function ActivateUser(props) {
  const [deleteValue, setdelete] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loop] = useState(false);

  const close = () => {
    props.handleSuccess(false);
  };

  const handleSuccess = () => {
    props.getusersData(findRole());
    props.handleSuccess(true);
  };

  const deleteFun = async () => {
    // call api
    setLoading(true);
    const userid = props.employee.userid;
    const paramters = {
      email: props.employee.email,
      name: props.employee.name,
      usertype: props.employee.typename,
      userid,
    };
    const res = await post("/users/enablebyname", paramters);

    if (res.status === 200) {
      setLoading(false);
      setdelete(true);
    } else {
      setLoading(false);
      toast.error("Error occured while updating record");
    }
  };

  const findRole = () => {
    if (props.employee.type == "CustomerSuccess") {
      return "ea";
    } else if (props.employee.type == "High5Coordinator") {
      return "csm";
    } else if (props.employee.type == "CommunityManager") {
      return "mp";
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div v-if="showDeleteRequestForm">
        {props.employeeDelete !== "employeedelete" ? (
          <div className="bl-modal-body  text-center">
            <div className="avatar avatar-lg avatar-red2 mr-3">
              <i className="fas fa-exclamation-triangle fa-fw"></i>
            </div>
            <p className="text-center lead mt-3">
              Are you sure you want to Activate this Employee?
            </p>
            <div className="text-center my-4">
              <button
                type="button"
                className="btn btn-secondary mr-3"
                data-dismiss="modal"
                onClick={close}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Confirm Activate
              </button>
            </div>
          </div>
        ) : deleteValue === false ? (
          <div className="bl-modal-body">
            <div className="text-center">
              <div className="avatar avatar-lg avatar-red2">
                <i
                  className="fas fa-exclamation-triangle fa-fw"
                  aria-hidden="true"
                ></i>
              </div>
              {props.users === "users" ? (
                <p className="text-center lead mt-3">
                  Are you sure you want to Activate this user?
                </p>
              ) : (
                <p className="text-center lead mt-3">
                  Are you sure you want to Deactivate this Employee?
                </p>
              )}

              <p>Name: {props.employee.name}</p>
            </div>
            <div className="text-center my-4">
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-secondary mr-2"
                onClick={close}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={deleteFun}
              >
                <span>Activate</span>{" "}
              </button>
            </div>
          </div>
        ) : (
          <div
            className="section section-sm pb-0 d-block"
            style={{ display: "none" }}
          >
            <div
              id="EmployeeManageSuccessAnimation"
              className="success-animation"
            >
              <div className="checkmark">
                <Lottie loop={loop} animationData={checkMark} play></Lottie>
              </div>
              <Anime
                initial={[
                  {
                    targets: "#LottieSparkles",
                    opacity: 0,
                    delay: 1000,
                  },
                ]}
              >
                <div className="sparkles">
                  <Lottie
                    loop={loop}
                    animationData={sparkles}
                    id="LottieSparkles"
                    play
                  ></Lottie>
                </div>
              </Anime>
            </div>
            <div className="success-message" style={{ opacity: "1" }}>
              <div className="container">
                <div className="col-lg-12">
                  <h4 className="text-center">
                    {props.users === "users"
                      ? "User Activated!"
                      : "Employee Activated!"}
                  </h4>
                  <div className="text-center">
                    {props.users === "users" ? (
                      <p>The selected User has been Activated</p>
                    ) : (
                      <p>The selected employee has been Activated</p>
                    )}
                  </div>
                  <div className="card card-flat card-lg">
                    <div className="card-body">
                      <div className="mb-2">
                        <label className="d-block font-bold pt-0">
                          {props.users !== "users" ? "Employee" : "Name"}
                        </label>
                        <div>{props.employee.name}</div>
                      </div>
                      {props.users == "users" ? (
                        <>
                          <div className="mb-2">
                            <label className="d-block font-bold pt-0">
                              Email
                            </label>
                            <div>{props.employee.email}</div>
                          </div>
                          <div className="mb-2">
                            <label className="d-block font-bold pt-0">
                              Role
                            </label>
                            <div>
                              {props.employee.type == "High5Coordinator"
                                ? "Customer Success Manager"
                                : props.employee.type == "CommunityManager"
                                ? "Marketplace Manager"
                                : props.employee.type == "CustomerSuccess"
                                ? "High5 Admin"
                                : props.employee.type}
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="text-center py-4">
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-secondary mr-2"
                      onClick={handleSuccess}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ActivateUser;
