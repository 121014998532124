import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { Modal, Button, Form } from "react-bootstrap";
import Loader from "../../../Loader/Loader";
import { post } from "../../../../service/ApiService";
import { FaUsersCog } from "react-icons/fa";
import { getTalentSubPoolCount } from "../../../../actions/talent/TalentSubPoolList";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDashboardCount } from "../../../../actions/dashboard/DashboardCount";
import { getDropdownValues } from "../../../../actions/common/GetDropdownValues";

const CreateSubPool = ({
  selectedData,
  dataTotal,
  isFilterApplied,
  inputList,
  skillInputList,
  subPool,
  clearFilterState,
  handleTalentClick,
}) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [name, setName] = useState("");
  const [confirmation, setConfirmation] = useState(false);
  const [toggleActions, setToggleActions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState({});
  const [industriesOptions, setIndustriesOptions] = useState([]);

  const industryArr = [
    "Information Technology (IT)",
    "Healthcare",
    "Accounting and Finance",
    "Administrative and Office Support",
    "Human Resources (HR)",
    "Other",
  ];

  const { dropdownLoading, dropdownData } = useSelector(
    (state) => ({
      dropdownLoading: state.dropdownValuesReducer.dropdownLoading,
      dropdownData: state.dropdownValuesReducer.dropdownData,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getDropdownValues());
  }, []);

  useEffect(() => {
    let industriesData = [];
    const filteredIndustries = dropdownData?.filter((industry) =>
      industryArr.includes(industry.name)
    );

    filteredIndustries.forEach((element) => {
      if (element.typeName == "skillSet") {
        industriesData = [
          ...industriesData,
          {
            value: element.name,
            label: element.name,
          },
        ];
      }
    });
    setIndustriesOptions(industriesData);
  }, [dropdownData]);

  const saveSearch = async () => {
    if (name === "") {
      return toast.error("Please provide a name for this search.");
    }
    if (selectedIndustry && Object.keys(selectedIndustry)?.length === 0) {
      return toast.error("Please select industry.");
    }

    try {
      setLoading(true);
      let payload = {
        name: name,
        filterObject: {
          ...selectedData,
          skills: [skillInputList],
          keywords: [inputList],
        },
        industry: selectedIndustry?.value,
      };

      let res = await post("/talentSubPool/createTalentSubPool", payload);
      if (
        res?.data?.originalError?.info?.message ===
        "Error: Record already exists."
      ) {
        setLoading(false);
        return toast.error("Talent Sub Pool with this name already exists");
      } else {
        handleTalentClick("subPool");
        setName("");
        setLoading(false);
        setConfirmation(false);
        clearFilterState();
        dispatch(getDashboardCount());
        toast.success("Talent Sub Pool created successfully.");
        setIndustriesOptions([]);
        setSelectedIndustry([]);
      }
    } catch (error) {
      setLoading(false);
      setConfirmation(false);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setToggleActions(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleChangeIndustry = (selectedOptions) => {
    setSelectedIndustry(selectedOptions);
  };

  return (
    <div className="d-block mr-2" ref={dropdownRef}>
      {(loading || dropdownLoading) && <Loader />}
      <div className="dropdown">
        {isFilterApplied && !subPool && dataTotal > 0 && (
          <Button
            variant="success"
            size="sm"
            onClick={() => {
              setName("");
              setConfirmation(true);
            }}
          >
            <FaUsersCog size={20} className="mr-2" />
            Create Talent Sub Pool
          </Button>
        )}
      </div>
      <Modal
        show={confirmation}
        onHide={() => setConfirmation(false)}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ fontSize: "14px" }}>
            <b>Create Sub Pool</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="m-3">
            By creating this sub pool, you can quickly access this later.
          </div>
          <div className="card card-flat bg-gray4 m-3">
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="card-body py-0">
                  <label className="mb-1">
                    Please provide a name for this sub pool.*
                  </label>
                  <div className=" mb-2" style={{ zIndex: "997" }}>
                    <Form.Control
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      value={name}
                      placeholder="Name"
                      style={{ fontSize: "14px" }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="card-body py-0 ">
                  <label className="mb-1">Industry*</label>
                  <div
                    className="form-floating Hover-Dropdown mb-2"
                    style={{ zIndex: "997" }}
                  >
                    <Select
                      options={industriesOptions}
                      name="Industry"
                      onChange={handleChangeIndustry}
                      isSearchable={true}
                      value={selectedIndustry}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary btn-sm"
            onClick={() => {
              setName("");
              setConfirmation(false);
              setSelectedIndustry([]);
            }}
          >
            Close
          </Button>
          <Button
            variant="primary btn-sm"
            disabled={name === "" ? true : false}
            onClick={saveSearch}
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CreateSubPool;
