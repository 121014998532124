import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Sort from "../../common/Sort";
import FilterJobs from "../../modal/addjob/FilterJobs";
import SelectJob from "./SelectJobs";
import { getJobList } from "../../../actions/job/JobList";
import Loader from "../../Loader/Loader";

const ExistingJob = (props) => {
  const [sortModal, setSortModal] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [JobList, setJobList] = useState([]);
  const [jobListData, setJobListData] = useState([]);
  const [sortArray, setSortArray] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [requestTitles, setRequestTitles] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const dispatch = useDispatch();
  const { jobListLoading, jobList } = useSelector(
    (state) => ({
      jobListLoading: state.JobList.jobListLoading,
      jobList: state.JobList.jobList,
    }),
    shallowEqual
  );

  useEffect(() => {
    let paramObj = {
      sortObject: [],
      currentPage: 1,
      tab: "all",
      filterQuery: {
        address: [],
        isHotJob: 0,
        jobId: [],
        jobTitle: [],
        jobType: [],
        visaStatus: [],
      },
    };
    dispatch(getJobList(paramObj));
  }, []);
  useEffect(() => {
    let title = [];
    let type = [];
    let result = jobList.sort((a, b) => {
      let fa = a.jobPostDate,
        fb = b.jobPostDate;
      if (fa > fb) {
        return -1;
      }
      if (fa < fb) {
        return 1;
      }
      return 0;
    });
    result.forEach((job) => {
      if (job.jobTitle && !title.includes(job.jobTitle)) {
        title.push(job.jobTitle);
      }
      if (job.jobType && !type.includes(job.jobType)) {
        type.push(job.jobType);
      }
    });

    result = result.map((job) =>
      job.workPlaceType === "Remote"
        ? job
        : { ...job, workPlaceType: job.workPlaceType }
    );

    setJobList(result);
    setJobListData(result);
    setRequestTitles(title);
    setRequestTypes(type);
  }, [jobList]);

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];
  const sortObject = [
    {
      id: 1,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Type",
      value: "jobType",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Location",
      value: `workPlaceType`,
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
  ];

  const applySort = (sortedData, close) => {
    setSelectedTitles([]);
    setSelectedTypes([]);
    close && setSortModal(false);
    setJobListData(sortedData);
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(jobListData, true);
  };

  const applyFilter = (data, selectedTitle, selectedType) => {
    sortClearAll();
    setSelectedTitles(selectedTitle);
    setSelectedTypes(selectedType);
    setJobListData(data);
    setShowFilter(false);
  };

  return (
    <>
      {jobListLoading && <Loader />}
      <div className="mt-4">
        <div className="m-3">
          <div className="d-flex align-items-center">
            <div className="ml-auto d-flex mb-2 justify-content-center justify-content-lg-start">
              <div class="dropdown-sort dd-sort">
                <button
                  type="button"
                  className="btn btn-sm btn-text-accent"
                  onClick={() => setSortModal(true)}
                >
                  <i className="fad fa-sort-down font-16 mr-1"></i> Sort /{" "}
                  {sortCount}
                </button>
                {sortModal && (
                  <OutsideClickHandler
                    onOutsideClick={() => setSortModal(false)}
                  >
                    <Sort
                      sortData={props?.JobList}
                      orderArray={orderList}
                      sortObjects={sortObject}
                      applySort={applySort}
                      setSortCount={setSortCount}
                      sortCount={sortCount}
                      setSortArray={setSortArray}
                      sortArray={sortArray}
                      sortingFor={"Sort by Job Title, Job Type and Location"}
                    />
                  </OutsideClickHandler>
                )}
              </div>
              <div className="dropdown position-relative">
                <button
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title="Filter"
                  onClick={() => setShowFilter(true)}
                  type="button"
                  className="btn btn-sm btn-text-accent w-auto"
                >
                  <i className="fas fa-filter mr-1"></i>
                  Filter /
                  <span> {selectedTitles.length + selectedTypes.length} </span>
                </button>
              </div>
            </div>
          </div>

          <hr className="m-0 mb-3" />
          <SelectJob
            data={jobListData}
            pageLimit={5}
            dataLimit={10}
            searchData={[]}
            searchValue={searchValue}
            setJobDetails={props?.setJobDetails}
          />
        </div>
      </div>
      {showFilter && (
        <OutsideClickHandler onOutsideClick={() => setShowFilter(false)}>
          <FilterJobs
            requestTitles={requestTitles}
            requestTypes={requestTypes}
            selectedTitles={selectedTitles}
            selectedTypes={selectedTypes}
            applyFilter={applyFilter}
            jobData={JobList}
          />
        </OutsideClickHandler>
      )}
    </>
  );
};

export default ExistingJob;
