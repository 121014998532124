import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { toast } from 'react-toastify'
import Modal from 'react-bootstrap/Modal'
import {post} from "../../service/ApiService"
import {getCandidateName} from "../../service/AuthService"
import Loader from '../Loader/Loader'
import SendToHM from '../modal/intakeform/SendToHM'

function Pagination({ data, pageLimit, dataLimit, searchValue, searchData, openSidePanel, onAddJobClick }) {

    const [currentPage, setCurrentPage] = useState(1)
    const [pageType, setPageType] = useState('')
    const [details, setDetails] = useState({})
    const [paginationGroup, setPaginationGroup] = useState([])
    const [paginationArray, setPaginationArray] = useState([])
    const [loading, setLoading] = useState(false)
    const [ showModal, setShowModal ] = useState(false)
    const userName = getCandidateName()

    function changePage(event) {
        const pageNumber = Number(event.target.textContent)
        setCurrentPage(pageNumber)
    }

    const getPaginatedData = () => {
        const startIndex = (currentPage * dataLimit) - dataLimit
        const endIndex = startIndex + (+dataLimit)
        return searchValue ? searchData.slice(startIndex, endIndex) : data.slice(startIndex, endIndex);
    }

    useEffect(() => {
        searchValue ?
            setPaginationArray(new Array(Math.ceil(searchData.length / dataLimit)).fill().map((_, i) => i + 1)) :
            setPaginationArray(new Array(Math.ceil(data.length / dataLimit)).fill().map((_, i) => i + 1))
        searchValue ?
            setPaginationGroup(new Array(Math.ceil(searchData.length / dataLimit)).fill().map((_, i) => i + 1).slice(0, pageLimit)) :
            setPaginationGroup(new Array(Math.ceil(data.length / dataLimit)).fill().map((_, i) => i + 1).slice(0, pageLimit))
        setCurrentPage(1)
    }, [dataLimit, searchData])

    useEffect(() => {
        if (pageType === 'up') {
            if ((currentPage - 1) % pageLimit === 0 && (currentPage !== 1)) {
                setPaginationGroup(paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1)))
            }
        }
        else {
            if ((currentPage % pageLimit === 0) && (currentPage !== 1)) {
                setPaginationGroup(paginationArray.slice(currentPage - pageLimit, currentPage))
            }
        }
    }, [currentPage])

    const splitMatchedText = (str) => {
        const regExp = new RegExp(searchValue, 'gi');
        return str.replace(regExp, (match) => `<span style='color: #FF5F57'>${match}</span>`);
    }

    const onAddJobClicked = async (item) => {
        try {
            setLoading(true)
            let result = await post('/request/addRequestIntake', item)
            if (result.status === 200) {
                UpdateJobStatusToIndex(item.intakeId)
            } else {
                setLoading(false)
                toast.error("Error occured while creating Job");
            }
        }
        catch (error) {
            setLoading(false)
            //toast.error('Some Error occured while creating Job')
            console.log(error)
        }
    }

    const UpdateJobStatusToIndex = async (id) => {
        try {
            const payload = {
                intakeId: id,
                name: userName
            }
            let result = await post('/intakedetail/updatejobstatus', payload)
            if (result.status === 200) {
                setLoading(false)
                toast.success("Job Created Successfully!!!")
                setTimeout(() => {
                    window.location.reload()
                }, 5000)
            }
        }
        catch (error) {
            setLoading(false)
            toast.error("Error Occured while updating job Status")
        }
    }
    const onClick = (data) => {
        setDetails(data)
        setShowModal(true)
    }

    // useEffect(() => {
    //     console.log(details)
    // }, [details])


    return (
        <>
            { loading && <Loader /> }
            <div class="px-3 pt-0 table-cards-1024">
                <table class="table table-hoverable-cells table-hoverabletable-cards-1024 font-14">
                    <thead>
                        <tr>
                            <th scope="col">Job Title</th>
                            <th scope="col">Client Name</th>
                            <th scope="col">Created Date</th>
                            <th scope="col" className='text-center'>Status</th>
                            <th scope="col" className='text-center'>Activity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            getPaginatedData().map((item) => (
                                <tr >
                                    <td onClick={() => openSidePanel(item)}>
                                        <span>
                                            <span class="font-bold d-inline-1024 d-none">Job Title: </span>
                                            {searchValue ? <strong dangerouslySetInnerHTML={{ __html: splitMatchedText(item.jobDetails[0].jobTitle) }}></strong> :
                                                <strong>{item.jobDetails[0].jobTitle}</strong>}
                                        </span>
                                    </td>
                                    <td onClick={() => openSidePanel(item)}>
                                        <span>
                                            <span class="font-bold d-inline-1024 d-none">Client Name: </span>
                                            {item.clientName}
                                        </span>
                                    </td>
                                    <td onClick={() => openSidePanel(item)}>
                                        <span>
                                            <span class="font-bold d-inline-1024 d-none">Type: </span>
                                            {/* {item.jobDetails[0].jobType} */}
                                            {moment(item.createdDate).format("MM/DD/YYYY")}

                                        </span>
                                    </td>
                                    <td 
                                        className="font-weight-bold"
                                        onClick={() => openSidePanel(item)}
                                    >                                       
                                        {item.status[0].intakeStatus === 'Pending' && (
                                            <span className="w-100 tag tag-orange1">
                                                <span className='mx-auto my-0'>
                                                    <span>{item.status[0].intakeStatus}</span>
                                                </span>
                                            </span>
                                        )
                                        }
                                        {(item.status[0].intakeStatus === 'Approved') && (
                                            <span class="w-100 tag tag-green1">
                                                <span className='mx-auto my-0'>
                                                    <span>Client Approved</span>
                                                </span>
                                            </span>
                                        )
                                        }
                                        {(item.status[0].intakeStatus === 'Delivery Approved') && (
                                            <span class="w-100 tag tag-green1">
                                                <span className='mx-auto my-0'>
                                                    <span>{item.status[0].intakeStatus}</span>
                                                </span>  
                                            </span>
                                        )
                                        }
                                        {item.status[0].intakeStatus === 'Rejected' && (
                                            <span class="w-100 tag tag-red1">
                                                <span className='mx-auto my-0'>
                                                    <span>{item.status[0].intakeStatus}</span>
                                                </span>  
                                            </span>
                                        )
                                        }                                       
                                    </td>
                                    <td className='text-center'>
                                        { item.status[0].isApproved === 'Sent To Clients' ?
                                            <span>
                                                <strong>Sent To Clients</strong><i class="ml-1 fas fa-check-square"></i>
                                            </span> :
                                            <span>
                                                    <span class="font-bold d-inline-1024 d-none">Activity: </span>
                                                    {(item.status[0].isApproved && item.status[0].intakeStatus !== 'Rejected' && item.status[0].isJobCreated !== 'true') &&
                                                        <button
                                                            type="button"
                                                            className="btn btn-sm btn-secondaryp"
                                                            onClick={() => onAddJobClicked(item)}
                                                        >
                                                            Create Job
                                                        </button>
                                                    }
                                                    {
                                                        item.status[0].isJobCreated === 'true' &&
                                                        <span>
                                                            <strong>Job Created</strong><i class="ml-1 fas fa-check-square"></i>
                                                        </span>
                                                    }
                                                    {item.status[0].intakeStatus === 'Delivery Approved' &&
                                                        <button
                                                            type="button"
                                                            className=" btn btn-sm btn-warning"
                                                            onClick={() => onClick(item) }                                      
                                                        >
                                                            Send To Clients
                                                        </button>
                                                    }    
                                            </span> 
                                        }
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
            {
                getPaginatedData().length === 0 &&
                <div>
                    <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                            <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                            No  Records found for {`'${searchValue}'`}
                        </div>
                    </div>
                </div>
            }
            <hr />
            <div className='d-flex p-2'>
                <div className="w-100-370 mb-point5-370">
                    Showing {currentPage === paginationArray.slice(-1)[0] ?
                        getPaginatedData().length :
                        (searchValue ?
                            (searchData.length < dataLimit ? searchData.length : dataLimit) : dataLimit)} {" "}
                    of {data.length}
                </div>
                <div class="ml-auto mx-auto-370 d-flex align-items-center">
                    <button
                        onClick={() => {
                            setPageType('down')
                            setCurrentPage(page => page - 1)
                        }}
                        type="button"
                        disabled={(currentPage === 1 || getPaginatedData().length === 0) ? true : false}
                        className="btn btn-icon px-2"
                    >
                        <i className="fal fa-chevron-circle-left"></i>
                    </button>

                    {
                        paginationGroup.map((item) => (
                            <button
                                type="button"
                                className={currentPage === item ? "btn btn-text px-2 bg-dark text-white" : "btn btn-text px-2"}
                                onClick={changePage}
                            >
                                {item}
                            </button>
                        ))
                    }

                    <button
                        onClick={() => {
                            setPageType('up')
                            setCurrentPage(page => page + 1)
                        }}
                        type="button"
                        disabled={(currentPage === paginationArray.slice(-1)[0] || getPaginatedData().length === 0) ? true : false}
                        className="btn btn-icon px-2"
                    >
                        <i className="fal fa-chevron-circle-right"></i>
                    </button>
                </div>
            </div>
            <Modal size="lg" show={showModal}>
                <Modal.Body>
                    <SendToHM 
                        handleSuccess={() => setShowModal(false)} 
                        details={details}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Pagination
