import React, { useEffect, useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";

import { MdAttachMoney, MdOutlineMonitor } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { ImCalendar } from "react-icons/im";
import { BsBriefcase } from "react-icons/bs";
import { GiMoneyStack } from "react-icons/gi";
import moment from "moment";
import { useParams } from "react-router";
import { GoLocation } from "react-icons/go";
import { BsBuilding, BsArrowRight } from "react-icons/bs";
import TalentCommunity from "./TalentCommunity";
import high5Logo from "../../../images/high5-logo.jpg";
import Refer from "./Refer";
import { post } from "../../../service/ApiService";
import TopNavbar from "./TopNavbar";
import Modal from "react-bootstrap/Modal";
import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import {
  LoginSocialGoogle,
  LoginSocialLinkedin,
  LoginSocialFacebook,
} from "reactjs-social-login";
import GoogleLogin from "react-google-login";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import ApplyJob from "./ApplyJob";
import AddTalentManualEntry from "../../modal/addtalent/manualentry/AddTalentManualEntry";
import CandidateProfile from "./CandidateProfile";
import {
  AiFillLinkedin,
  AiOutlinePlusSquare,
  AiOutlineMinusSquare,
} from "react-icons/ai";
import { RiShareBoxLine } from "react-icons/ri";

const AddTalentDS = (props) => {
  const { id, tenant, reffererid } = useParams();
  console.log("parsedData", props.parsedData);
  console.log("candidateDetails", props.candidateDetails);
  // console.log("reffererid", reffererid);
  const [isRefer, setIsRefer] = useState(false);
  const [job, setJob] = useState({});
  const [jobs, setJobs] = useState([]);
  const [tenantData, setTenantData] = useState({});
  const [loading, setLoading] = useState(false);
  const [parsedData, setParsedData] = useState({});
  const [parsed, setParsed] = useState(false);
  const [isLogIn, setIsLogIn] = useState(false);
  const [joinCommunity, setJoinCommunity] = useState(false);
  const [applyWithEmail, setApplyWithEmail] = useState(false);
  const [loginWithEmail, setLoginWithEmail] = useState(false);
  const [personalInfo, setPersonalInfo] = useState(false);
  const [electronicMessages, setElectronicMessages] = useState(false);
  const [isApplyJobModal, setIsApplyJobModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  // Apply job
  const [login, setLogin] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    url: "",
    email: "",
  });

  const updatedParsed = (data, value) => {
    setParsedData(data);
    setParsed(value);
  };

  useEffect(() => {
    console.log(localStorage.getItem("isLoggedIn"));
  }, []);

  const handleLogin = (name, url, email) => {
    setLogin(false);
    toast.success("Logged In Successfully");
    localStorage.setItem("isLoggedIn", "Yes");
    localStorage.setItem("name", name);
    localStorage.setItem("url", url);
    localStorage.setItem("email", email);
    setToggle(false);
    setIsLogIn(true);
  };

  const isLoggedIn = () => localStorage.getItem("isLoggedIn");

  const handleLogout = () => {
    setToggle(false);
    localStorage.setItem("isLoggedIn", "No");
    console.log(isLoggedIn());
  };

  const getName = () => localStorage.getItem("name");
  const getURL = () => localStorage.getItem("url");
  const getEmail = () => localStorage.getItem("email");

  // useEffect(() => {
  // }, [details]);

  useEffect(() => {
    getTenant();
    // getJob();
  }, []);

  const getTenant = async () => {
    const params = { tenantId: tenant };
    setLoading(true);
    const res = await post("/career/gettenant", params).then((res) => {
      console.log("res.data", res.data);
      setTenantData(res.data[0]);
      setLoading(false);
    });
  };

  //   const getJob = async () => {
  //     const params = { tenantId: tenant };
  //     const res = await post("/career/getjobs", params);
  //     let jobDetails = res.data.find((i) => i.jobId === +id);
  //     setJob({
  //       ...jobDetails,
  //       primarySkills: JSON.parse(jobDetails.primarySkills),
  //     });
  //     // setJobs(res.data);
  //   };

  return (
    <>
      {loading && <Loader />}
      <div className="">
        {/* Header */}

        {/* <TopNavbar tenantData={tenantData} /> */}

        {/* Job Header */}

        {/* For talent community */}
        {/* <div className="row mx-auto" style={{ width: "85%" }}>
          <div className="col-md-12 mx-auto mt-5 ">
            <h2 className="text-center">Fill the form to apply for a job</h2>

            <div className="row  mt-3">
              <div className="col-md-12 text-secondary ">
                <p className="text-center">
                  Fill the form given below to get the username and password to
                  apply for a job
                </p>
              </div>
            </div>

            <div className=" w-70 mt-3"></div>
          </div>
        </div> */}

        {/* <div style={{ height: "50px" }}></div> */}

        {/* Job Description */}

        <>
          {/* <div className="row mx-5 my-3" style={{ width: "90%" }}>
            <CandidateProfile
              // handleSuccessForm={props.handleSuccessForm}
              parent="JobDecription"
              //   message="JoinCommunity"
              updatedParsed={updatedParsed}
              parsedData={parsedData}
              parsed={parsed}
              setJoinCommunity={setJoinCommunity}
              setIsLogIn={setIsLogIn}
              reffererid={reffererid}
              // fetchCandidate={props.fetchCandidate}
              // closeModal={props.closeModal}
              // existingCandidateList={existingCandidateList}
            />
          </div> */}
        </>

        {/* Footer */}
        {/* <div className="row mx-auto mb-3" style={{ width: "85%" }}>
          <div className="col-md-7">
            <footer>
              <div>
                <span className="text-secondary">Powered by</span>
                <a>
                  <img
                    style={{
                      width: 40,
                      height: 35,
                      paddingBottom: 10,
                      marginLeft: 5,
                    }}
                    src={high5Logo}
                  />
                </a>
                <p className=" text-secondary">
                  Privacy Policy and Terms of Service
                </p>
              </div>
            </footer>
          </div>
        </div>
        {isRefer && <Refer setIsRefer={setIsRefer} isRefer={isRefer} />} */}
      </div>

      <Modal
        show={props.createProfile}
        size="lg"
        className="modal fade center"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div
          className="modal-header"
          style={{ padding: "5px" }}
          id="CreateQuestionModal"
        >
          <h4 className="modal-title mx-3" id="CreateQuestionModalLabel">
            Profile
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              props.setCreateProfile(false);
              props.setCandidateDetails(props.data);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          {/* <div className="row mx-5 my-3" style={{ width: "90%" }}> */}
          <CandidateProfile
            // handleSuccessForm={props.handleSuccessForm}
            parent="JobDecription"
            //   message="JoinCommunity"
            updatedParsed={updatedParsed}
            // parsedData={parsedData}
            parsedData={props.parsedData}
            parsed={props.parsed}
            candidateDetails={props.candidateDetails}
            setJoinCommunity={setJoinCommunity}
            setIsLogIn={setIsLogIn}
            reffererid={reffererid}
            setCreateProfile={props.setCreateProfile}
            email={props.email}
            job={props.job}
            setCandidateData={props.setCandidateData}
            setScoreCard={props.setScoreCard}
            setProfileData={props.setProfileData}
            // fetchCandidate={props.fetchCandidate}
            // closeModal={props.closeModal}
            // existingCandidateList={existingCandidateList}
          />
          {/* </div> */}
        </Modal.Body>
        {/* <Modal.Footer style={{ backgroundColor: "#F3F5F7" }}> */}
        {/* <button
            style={{ float: "right", width: 120 }}
            className="mb-4 btn btn-sm btn-secondary"
            onClick={() => {
              setIsChecked(false);
              setIsApplyJobModal(false);
            }}
          >
            Decline
          </button>

          <button
            style={{ float: "right", width: 120 }}
            className=" mb-4 btn btn-sm btn-primary"
            disabled={!isChecked ? true : false}
            onClick={() => {
              // setLogin(true);
              setIsChecked(false);
              setIsApplyJobModal(false);
            }}
          >
            Accept
          </button> */}
        {/* </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default AddTalentDS;
