import React, { useState } from "react";
import TalentPool from "./TalentPool";
import JobSummary from "./JobSummary";
import UsersData from "./UsersData";

const Clients = ({ clientList }) => {
  const [tenantID, setTenantID] = useState(0);
  const [talentPool, setTalentPool] = useState(false);
  const [jobSummary, setJobSummary] = useState(false);
  const [users, setUsers] = useState(true);
  const onTabClick = (type) => {
    if (type === "talentPool") {
      setTalentPool(true);
      setJobSummary(false);
      setUsers(false);
    }
    if (type === "jobSummary") {
      setTalentPool(false);
      setJobSummary(true);
      setUsers(false);
    }

    if (type === "users") {
      setTalentPool(false);
      setJobSummary(false);
      setUsers(true);
    }
  };
  return (
    <div className="py-5">
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-between mt-3">
          <ul class="nav nav-tabs  ">
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("users");
                }}
                type="button"
                class={users ? "nav-link pb-3 active" : "nav-link pb-3"}
              >
                <div>Clients</div>
              </button>
            </li>
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("talentPool");
                }}
                type="button"
                class={talentPool ? "nav-link pb-3 active" : "nav-link pb-3"}
              >
                <div>Talent</div>
              </button>
            </li>
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("jobSummary");
                }}
                type="button"
                class={jobSummary ? "nav-link pb-3 active" : "nav-link pb-3"}
              >
                {" "}
                <div>Job Summary</div>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <hr className="mt-0" />
      {talentPool && <TalentPool clientList={clientList} />}
      {jobSummary && <JobSummary clientList={clientList} />}
      {users && <UsersData clientList={clientList} />}
    </div>
  );
};

export default Clients;
