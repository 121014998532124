import axios from "axios";
import { toast } from "react-toastify";
import { post } from "../../service/ApiService";
import Event from "../../googleanalytic/Tracking";

export const GET_LOGIN_BEGIN = "GET_LOGIN_BEGIN";
export const GET_LOGIN_SUCCESS = "GET_LOGIN_SUCCESS";
export const GET_LOGIN_FAILURE = "GET_LOGIN_FAILURE";

export const commonLogin = (params, history) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LOGIN_BEGIN,
    });
    const res = await post("/login", params);

    if (res.status === 200) {
      let jobId = localStorage.getItem("jobId");
      localStorage.removeItem("jobId");
      if (
        (res.data.role === "Recruiter" &&
          res.data.userTenant.includes("GIG - ")) ||
        (res.data.tenantType === 2 && res.data.role === "Admin")
      ) {
        const res2 = await post("/login/fetchTermsAccepted", {
          email: res.data.userName,
        });
        let accepted = res2.data[0].isTermsAccepted;
        if (accepted === null || accepted === "Declined") {
          let tempData = {
            loginTime: res.data.loginTime,
            userToken: JSON.stringify(res.data),
            user: res.data.candidateName,
            role: res.data.role,
            tenant: res.data.userTenant,
            userid: res.data.candidateId,
            email: res.data.userName,
          };
          localStorage.setItem("tempData", JSON.stringify(tempData));

          history.push("/terms&conditions");
          try {
            let url = "/inviteunsubscribed";
            url = `${url}/isSubscribed`;
            post(url, { email: res.data.userName })
              .then((response) => console.log(response))
              .catch((error) => console.log(error));
          } catch (err) {
            console.log(err);
          }
        } else {
          localStorage.setItem("loginTime", res.data.loginTime);
          localStorage.setItem("userToken", JSON.stringify(res.data));
          localStorage.setItem("user", res.data.candidateName);
          localStorage.setItem("role", res.data.role);
          localStorage.setItem("tenant", res.data.userTenant);
          localStorage.setItem("userid", res.data.candidateId);
          localStorage.setItem("profileImagePath", res.data.profileImagePath);

          !jobId
            ? history.go("/high5Hire")
            : history.go(`/high5Hire/requests/${jobId}`);
          Event("Login", "Login successful");
          try {
            let url = "/inviteunsubscribed";
            url = `${url}/isSubscribed`;
            post(url, { email: res.data.userName })
              .then((response) => console.log(response))
              .catch((error) => console.log(error));
          } catch (err) {
            console.log(err);
          }
        }
      } else {
        localStorage.setItem("loginTime", res.data.loginTime);
        localStorage.setItem("userToken", JSON.stringify(res.data));
        localStorage.setItem("user", res.data.candidateName);
        localStorage.setItem("role", res.data.role);
        localStorage.setItem("tenant", res.data.userTenant);
        localStorage.setItem("userid", res.data.candidateId);
        !jobId
          ? history.go("/high5Hire")
          : history.go(`/high5Hire/requests/${jobId}`);
        try {
          let url = "/inviteunsubscribed";
          url = `${url}/isSubscribed`;
          post(url, { email: res.data.userName })
            .then((response) => console.log(response))
            .catch((error) => console.log(error));
        } catch (err) {
          console.log(err);
        }
        Event("Login", "Login successful");
      }
      dispatch({
        type: GET_LOGIN_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_LOGIN_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
