import React, { useState, useEffect } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import { post } from "../../../../../service/ApiService";

const Industry = (props) => {
  const { setCategoryLoading } = props;
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCategoryOptionsAll, setSubCategoryOptionsAll] = useState([]);
  const [count, setCount] = useState(true);
  const [selectedJobCategory, setSelectedJobCategory] = useState(null);

  useEffect(async () => {
    if (
      props?.dropdownList &&
      props?.dropdownList.length > 0 &&
      props?.addJobData.skillSet &&
      count
    ) {
      let industries = props?.addJobData.skillSet;
      const result = industries.map((name) => {
        const matchedObject = props?.dropdownList?.find(
          (obj) => obj.PositionName === name
        );
        return matchedObject ? matchedObject.id_JobPositiontype : null;
      });
      setCategoryLoading(true);
      const payload = {
        industry: result,
      };
      try {
        const res = await post("/common/subcategory", payload);
        setCount(false);
        setCategoryLoading(false);
        if (res.status == 200) {
          let subCategoryData = [
            {
              value: "All",
              label: "All",
            },
          ];
          res.data.map((item) => {
            subCategoryData = [
              ...subCategoryData,
              {
                value: item.categoryName,
                label: item.categoryName,
              },
            ];
          });
          setSubCategoryOptions(subCategoryData);
          setSubCategoryOptionsAll(subCategoryData);
        } else {
          toast.error("Oops! something went wrong. please try again");
        }
      } catch (error) {
        toast.error("Oops! something went wrong. please try again");
        setCategoryLoading(false);
      }
    }
  }, [props?.dropdownList]);

  const handleChangeMultiSelect = (value) => {
    if (value.includes("All")) {
      props?.setAddJobData({
        ...props?.addJobData,
        subCategory: subCategoryOptionsAll
          .filter((item) => item.value !== "All")
          .map((i) => i.label),
      });
      setSubCategoryOptions([]);
    } else {
      setSubCategoryOptions(
        subCategoryOptionsAll.filter((item) => !value.includes(item.value))
      );
      props?.setAddJobData({ ...props?.addJobData, subCategory: value });
    }
  };

  const handleChangeIndustry = async (val) => {
    setSelectedJobCategory(val);
    if (val.length > 0) {
      props?.setAddJobData({
        ...props?.addJobData,
        skillSet: [val],
        subCategory: [],
      });
      let industry = props?.dropdownList?.filter(
        (item) => val == item.PositionName
      );
      industry = industry.map((item) => item.id_JobPositiontype);
      const payload = {
        industry,
      };
      try {
        setCategoryLoading(true);
        const res = await post("/common/subcategory", payload);
        setCategoryLoading(false);
        if (res.status == 200) {
          let subCategoryData = [
            {
              value: "All",
              label: "All",
            },
          ];
          res.data.map((item) => {
            subCategoryData = [
              ...subCategoryData,
              {
                value: item.categoryName,
                label: item.categoryName,
              },
            ];
          });
          setSubCategoryOptions(subCategoryData);
          setSubCategoryOptionsAll(subCategoryData);
        } else {
          toast.error("Oops! something went wrong. please try again");
        }
      } catch (error) {
        toast.error("Oops! something went wrong. please try again");
        setCategoryLoading(false);
      }
    } else {
      setSubCategoryOptions([]);
      setSubCategoryOptionsAll([]);
      props?.setAddJobData({
        ...props?.addJobData,
        skillSet: val,
        subCategory: val,
      });
    }
  };

  return (
    <div className="card card-flat bg-gray4 m-3">
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="card-body py-0 ">
            <label>Job Category*</label>
            <div
              className="form-floating Hover-Dropdown mb-2"
              style={{ zIndex: "997" }}
            >
              <Select
                options={props?.skillSetOption.filter(
                  (item) => item.value !== selectedJobCategory
                )}
                name="placementSkill"
                className={
                  props?.isFormValid &&
                  props?.addJobData.skillSet.length == 0 &&
                  "border border-danger rounded"
                }
                onChange={(value) => {
                  handleChangeIndustry(value.value);
                }}
                isSearchable={true}
                value={props?.skillSetOption.filter((item) =>
                  props?.addJobData.skillSet.includes(item.value)
                )}
              />
            </div>
            {props?.isFormValid && props?.addJobData.skillSet.length == 0 && (
              <small className="validation ml-2">*Required field</small>
            )}
          </div>
        </div>
        <div className="col-lg-6">
          <div className="card-body py-0">
            <label>Sub Category*</label>
            <div
              className="form-floating Hover-Dropdown mb-2"
              style={{ zIndex: "997" }}
            >
              <Select
                isMulti
                options={subCategoryOptions.sort((a, b) => b.label - a.label)}
                name="subCategory"
                isSearchable={true}
                className={
                  props?.isFormValid &&
                  props?.addJobData.subCategory.length == 0 &&
                  "border border-danger rounded"
                }
                value={
                  props?.addJobData.subCategory.length > 0
                    ? subCategoryOptionsAll.length - 1 ==
                      props?.addJobData.subCategory.length
                      ? [{ label: "All", value: "All" }]
                      : subCategoryOptionsAll.filter((f) =>
                          props?.addJobData.subCategory.includes(f.value)
                        )
                    : []
                }
                onChange={(value) =>
                  handleChangeMultiSelect(value?.map((i) => i.value))
                }
                isDisabled={props?.addJobData.skillSet.length == 0}
              />
            </div>
            {props?.isFormValid &&
              props?.addJobData.subCategory.length == 0 && (
                <small className="validation ml-2">*Required field</small>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Industry;
