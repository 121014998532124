import React, { useState, useEffect } from "react";
import CandidateProfileOverview from "./CandidateProfileOverview";
// import CandidateProfileScreenings from '../Model/CandidateProfileTabs/CandidateProfileScreenings'
// import CandidateProfileResume from '../Model/CandidateProfileTabs/CandidateProfileResume'
// import CandidateProfileInterviews from '../Model/CandidateProfileTabs/CandidateProfileInterviews'
// import CandidateProfileHistory from '../Model/CandidateProfileTabs/CandidateProfileHistory'
import vettedIcon from "../../../images/high5-mark-circle-dark.svg";
import { domain } from "../../../constants/Constants";
import { post } from "../../../service/ApiService";
import Modal from "react-bootstrap/Modal";
// import Loader from '../../Loader/Loader'
import { getInitial } from "../../function/CommonFunction";
import {
  getUserRole,
  getUserTenant,
  getTenantType,
} from "../../../service/AuthService";
import CandidateRequests from "./CandidateRequests";
import CandidateResume from "./CandidateResume";
import CandidateAssessments from "./CandidateAssessments";
import AssignAssessment from "../reruiter/AssignAssessment";
import EditTalent from "./Edit Talent/EditTalent";
import Avatar from "react-avatar";
import { toast } from "react-toastify";

function CandidateView(props) {
  const [overview, setOverview] = useState(true);
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState(false);
  let candidateData = props.candidateData;
  const [fullText, setFullText] = useState({});
  const [resume, setResume] = useState(false);
  const [assessments, setAssessments] = useState(false);
  const [resumeDownload, setResumeDownload] = useState([]);
  const [assignAssessmentPop, setAssignAssessmentPop] = useState(false);
  const [editTalent, setEditTalent] = useState(false);
  const [userRole, setUserRole] = useState();
  const [resumeList, setResumeList] = useState([]);
  const [tenantType, setTenantType] = useState("");

  const handleModel = () => {
    setAssignAssessmentPop(false);
  };

  const setAssignAssessmentClick = () => {
    setAssignAssessmentPop(true);
  };

  useEffect(() => {
    const role = getUserRole();
    setUserRole(role);
    setTenantType(getTenantType());
    fetchCandidateResume();
  }, []);

  useEffect(() => {
    fetchCandidateResume();
  }, [candidateData]);

  const fetchCandidateResume = async () => {
    setLoading(true);
    const payload = {
      candidateID: candidateData.candidateID,
      tenant:
        getUserRole === "SuperAdmin"
          ? getUserTenant()
          : candidateData.fullText.tenent,
    };
    try {
      let res = await post("/candidateProfile/getResumeTC", payload);
      setResumeList(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  const handleClose = () => {
    props.handleSuccess(true);
  };

  const onTabClick = (type) => {
    if (type === "overview") {
      setOverview(true);
      setRequests(false);
      setResume(false);
      setAssessments(false);
    } else if (type === "assessments") {
      setOverview(false);
      setResume(false);
      setRequests(false);
      setAssessments(true);
    } else if (type === "resume") {
      setOverview(false);
      setResume(true);
      setRequests(false);
      setAssessments(false);
    } else {
      setRequests(true);
      setOverview(false);
      setResume(false);
      setAssessments(false);
    }
  };

  return (
    <>
      {/* {loading && <Loader />} */}
      <div className="view-panel show">
        <div className="view-panel-header mb-3">
          <div className="px-3 py-2">
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-icon"
            >
              <i className="fal fa-times"></i>
            </button>
          </div>
          <div className="px-3">
            <div className="d-flex align-items-center flex-wrap-reverse">
              <div className="d-flex">
                {candidateData.ImageURL ? (
                  <span>
                    <img class="avatar" alt="" src={candidateData.ImageURL} />
                  </span>
                ) : (
                  // <span class="avatar avatar-green2">
                  //     {/* {Object.keys(candidateData.fullText).length !== 0 && getInitial(`${candidateData.fullText.firstName} ${candidateData.fullText.lastName}`)} */}
                  //     {getInitial(`${candidateData.fullText.firstName} ${candidateData.fullText.lastName}`)}
                  // </span>
                  <Avatar
                    size="55"
                    name={
                      candidateData.fullText.firstName +
                      " " +
                      candidateData.fullText.lastName
                    }
                    round="80px"
                  />
                )}
                <div className="ml-3 mb-1">
                  {props.parent == "TalentCommunity" ? (
                    <h6 className="mb-1 mr-2">
                      {candidateData.fullText.firstName
                        .charAt(0)
                        .toUpperCase() +
                        candidateData.fullText.firstName.slice(1)}{" "}
                      {candidateData.fullText.lastName.charAt(0).toUpperCase() +
                        candidateData.fullText.lastName.slice(1)}
                      {candidateData.isRecruiterAssignedVet && (
                        <span class="tag tag-lg tag-coral ml-2">
                          <img width="14px" src={vettedIcon} />
                          <span class="mx-2">VETTED</span>
                        </span>
                      )}
                    </h6>
                  ) : (
                    <h6 className="mb-1 mr-2">
                      {candidateData.fullText.firstName
                        .charAt(0)
                        .toUpperCase() +
                        candidateData.fullText.firstName.slice(1)}{" "}
                      {candidateData.fullText.lastName.charAt(0).toUpperCase() +
                        candidateData.fullText.lastName.slice(1)}
                      {/*(candidateData.MCQ === true ||
                        candidateData.onewayvetting === true ||
                        candidateData.twowayvetting === true ||
                        candidateData.codesignalvetting === true) && (
                        <span class="tag tag-lg tag-coral ml-2">
                          <img width="14px" src={vettedIcon} />
                          <span class="mx-2">VETTED</span>
                        </span>
                      )} */}
                    </h6>
                  )}
                  {/* <small>{candidateData.email}</small> */}
                  <div class="d-flex align-items-center ">
                    {
                      <span>
                        {candidateData.experience.length > 0 &&
                          candidateData.experience[0].jobTitle}
                      </span>
                    }
                  </div>
                </div>
              </div>
            </div>
            {
              <div class="ml-auto d-flex align-items-center mt-2">
                <div class="mr-1">
                  {/* {userRole === "Recruiter" && (
                    <div
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title=""
                      data-original-title="Assign Assessment"
                      class="mr-1 d-inline-block"
                    >
                      <button
                        type="button"
                        class="btn btn-sm btn-gray"
                        onClick={setAssignAssessmentClick}
                      >
                        <i
                          class="fas fa-fw fa-spell-check"
                          aria-hidden="true"
                        ></i>
                        <span class="d-none-600 ml-1">Assign</span>
                      </button>
                    </div>
                  )} */}
                  <div
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title=""
                    data-original-title="Download Resume"
                    class="d-inline-block"
                  >
                    <button
                      type="button"
                      class="btn btn-sm btn-gray"
                      disabled={loading}
                      onClick={() => onTabClick("resume")}
                      // onClick={() => // console.log('resume')}
                    >
                      <i class="fas fa-file-download" aria-hidden="true"></i>
                      <span class="d-none-600 ml-1">Resume</span>
                    </button>
                  </div>
                </div>
                {/* {userRole === "Recruiter" && (
                  <div className="mr-1">
                    <a
                      href={`/high5hire/SubmitCandidate/${candidateData.candidateID}`}
                      type="button"
                      class="btn btn-sm"
                    >
                      Invite
                    </a>
                  </div>
                )} */}
                {userRole === "Recruiter" && (
                  <div>
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm"
                      onClick={() => setEditTalent(true)}
                    >
                      <i class="fas fa-pencil-alt mr-1"></i>
                      Edit Profile
                    </button>
                  </div>
                )}
              </div>
            }
            <ul className="nav nav-tabs mt-3">
              <li className="nav-item">
                <button
                  className={
                    overview ? " nav-link pb-3 active" : "nav-link pb-3"
                  }
                  onClick={() => onTabClick("overview")}
                  type="button"
                >
                  Profile
                </button>
              </li>
              {/* {
                // (userRole === 'Recruiter' || tenantType === 2) &&
                <li className="nav-item">
                  <button
                    onClick={() => onTabClick("assessments")}
                    type="button"
                    className="nav-link pb-3"
                  >
                    Assessments
                  </button>
                </li>
              } */}
              {(userRole === "Recruiter" ||
                userRole === "HiringManager" ||
                userRole === "Admin") && (
                <li className="nav-item">
                  <button
                    className={
                      requests ? "nav-link pb-3 active" : "nav-link pb-3"
                    }
                    onClick={() => onTabClick("requests")}
                    type="button"
                  >
                    Requests
                  </button>
                </li>
              )}
            </ul>
          </div>
        </div>
        <div className="view-panel-body">
          {overview && (
            <CandidateProfileOverview
              resumeURL={resumeDownload}
              fullText={candidateData}
              candidateData={candidateData}
            />
          )}
          {assessments && (
            <CandidateAssessments
              fullText={candidateData.fullText}
              candidateData={props.candidateData}
            />
          )}
          {requests && (
            <CandidateRequests candidateId={candidateData.candidateID} />
          )}
          {resume && <CandidateResume resumeURL={resumeList} />}
        </div>
        {/* <Modal show={assignAssessmentPop} size="lg">
          <AssignAssessment
            handleSuccess={handleModel}
            candidateData={props.candidateData}
          />
        </Modal> */}
        <Modal show={editTalent} size="lg">
          <Modal.Header>
            <h6 class="">Edit Talent</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setEditTalent(false)}
              class="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <EditTalent
              closeModal={() => setEditTalent(false)}
              data={props.candidateData}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default CandidateView;
