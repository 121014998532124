import React, { useState } from "react"
import LearningFullView from "./LearningFullView"
import Modal from "react-bootstrap/Modal"
import moment from 'moment'

const LearningGridView = (props) => {

  const [ learningDetails, setLearningDetails ] = useState([])
  const [ openLearningDetailsModal, setOpenLearningDetailsModal ] = useState(false)

  const openLearningDetails = (module) => {
    setLearningDetails(module)
    setOpenLearningDetailsModal(true)
  };

  const closeLearningModal = () => {
    setOpenLearningDetailsModal(false)
  }

  return (
    <>
      <div class="row d-none d-flex-1200 d-flex-force">
        {
          props.learningList.length === 0 ? <p>No Learning Modules</p> :
          props.learningList.map((module) => (
          <div
            className="col-lg-4 col-md-6 col-sm-12 d-flex"
          >
            <a
              href=""
              onClick={(e) => {
                e.preventDefault()
                openLearningDetails(module)
              }}
              className="card card-flat col card-tag-bottom mb-4 card-lg card-modules-grid p-relative"
            >
              <div className="card-body">
                <div className="d-flex mt-2 mb-2 align-items-center">
                  <h6 className="mb-0">{module.CourseName}</h6>
                </div>
                <div className="p-2 bg-blue4 font-regular font-primary font-gray1 mt-1">
                  <div>
                    <i className="far fa-fw fa-clock me-2"></i>
                    <span>{ module.LastActivityDate ? moment(module.LastActivityDate, "YYYYMMDD").fromNow() : "Not Yet Started" }</span> 
                  </div>
                </div>
                <div className="mt-2">
                  <p className="mb-1">
                      <small>Progress</small>
                  </p>
                  { module.ProgressStatus === "In Progress" ? module.PercentComplete === 0 ? 
                    (
                      <div>
                        <small>Not Started</small>
                        <div className="alert-progress alert-progress-static bg-gray3">
                          <div className="alert-progress-bar bg-green1"></div>
                        </div>
                      </div>
                    )
                  : (
                      <div>
                          <small>{module.PercentComplete+'%'} Completed</small>
                          <div className="alert-progress alert-progress-static bg-gray3">
                              <div
                              style={{ transform: "translateX(-80%)" }}
                              className="alert-progress-bar"
                              >
                                  
                              </div>
                          </div>
                      </div>
                  ) : 
                      <div>
                        <small>100% Completed</small>
                        <div className="alert-progress alert-progress-static bg-green1"></div>
                      </div>
                  }                           
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
      <Modal
        size="lg"
        show={openLearningDetailsModal}
      >
        <LearningFullView
          learningDetails={learningDetails}
          closeModal={closeLearningModal}
        />
      </Modal>
    </>
  )
}

export default LearningGridView
