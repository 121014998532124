export const reportHeaders = [
  { id: 0, name: "S.No" },
  { id: 1, name: "Invited on" },
  { id: 2, name: "Candidate Name" },
  { id: 4, name: "Phone No." },
  { id: 5, name: "Email" },
  { id: 6, name: "Status" },
  { id: 8, name: "Job Title" },
  { id: 9, name: "Job Client" },
  { id: 10, name: "Gig Recruiter" },
  { id: 11, name: "Recruiter Address" },
];

export const sortObject = [
  {
    id: 1,
    label: "Candidate Name",
    value: "candidateName",
    icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
  },
  {
    id: 2,
    label: "Job Title",
    value: "jobTitle",
    icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
  },
  // {
  //   id: 3,
  //   label: "Primary Skill",
  //   value: "primarSkills",
  //   icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  // },
];
export const orderList = [
  {
    value: "asc",
    label: "Ascending",
    icon: "fal fa-sort-amount-down mr-2 dd-sort",
  },
  {
    value: "desc",
    label: "Descending",
    icon: "fal fa-sort-amount-up mr-2 dd-sort",
  },
];
