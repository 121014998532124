import React, { useEffect, useState } from "react";
import ScreeningQuestions from "./ScreeningQuestions";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Autosuggest from "react-autosuggest";
import { get } from "../../../service/ApiService";
import Modal from "react-bootstrap/Modal";
function InterviewPlan({
  addJobData,
  setAddJobData,
  mandatorySkills,
  screeningQuestions,
  setScreeningQuestions,
  selectedLanguages,
  setSelectedLanguages,
  skillSetOption,
  QualificationType,
  vettingCheck,
  vettingData,
  setVettingToSend,
}) {
  const [displayScreening, setDisplayScreening] = useState(false);
  // const [resumeMandatory, setResumeMandatory] = useState(false);
  const [addSkill, setAddSkill] = useState(false);
  const [skillInput, setSkillInput] = useState({ name: "", type: "MCQ" });
  const [skillArray, setSkillArray] = useState([]);
  const [dropDownSkills, setDropDownSkills] = useState([]);
  const [showQnAModal, setShowQnAModal] = useState(false);
  // const [confirmQnA, showConfirmQnA] = useState(false);
  const [showVettingModal, setShowVettingModal] = useState(false);
  // const [confirmVetting, showConfirmVetting] = useState(false);
  const [selectedVettingList, setSelectedVettingList] = useState([]);
  const [vetSkills, setVetSkills] = useState(mandatorySkills);

  useEffect(() => {
    setVetSkills(mandatorySkills);
  }, [mandatorySkills]);

  useEffect(() => {
    fetchDropDown();
    const uniqueName = [
      ...new Map(vettingCheck.map((item) => [item["name"], item])).values(),
    ];
    setSelectedVettingList(uniqueName);
    // eslint-disable-next-line
  }, []);

  const fetchDropDown = async () => {
    const res = await get("/talent/dropdownlist");
    setDropDownSkills(
      res.data.CandidateSkills.map((skill) => skill.SourceName)
    );
  };

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };
    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };
    const getSuggestionValue = (suggestion) => suggestion.PositionName;
    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropDownSkills.filter(
              (item) =>
                item.toLowerCase().includes(inputValue) &&
                !addJobData.vettingDetails.map((i) => i.name).includes(item)
            );
      return suggestValues;
    };
    const renderSuggestion = (suggestion) => <div>{suggestion}</div>;
    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);
    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        theme={theme}
      />
    );
  };

  const handleVetting = (e, i) => {
    const { name, value } = e.target;
    let temp = [...addJobData.vettingDetails];
    if (name === "type") {
      temp[i] = {
        ...temp[i],
        [name]: value,
        duration: "",
        difficulty: "",
      };
      setAddJobData({
        ...addJobData,
        vettingDetails: temp,
      });
    } else if (name === "difficulty") {
      temp[i] = {
        ...temp[i],
        [name]: value,
        duration: "",
      };
      setAddJobData({
        ...addJobData,
        vettingDetails: temp,
      });
    } else {
      temp[i] = {
        ...temp[i],
        [name]: value,
      };
      setAddJobData({
        ...addJobData,
        vettingDetails: temp,
      });
    }
  };

  const [vettingDetails, setVettingDetails] = useState(
    addJobData.vettingDetails
  );

  const [showHeader, setShowHeader] = useState(false);
  useEffect(() => {
    vettingDetails?.filter((skill) => !skill.status === true)?.length > 0
      ? setShowHeader(true)
      : setShowHeader(false);
  }, [addJobData?.vettingDetails, vettingDetails]);

  const [missingVettingFor, setMissingVettingFor] = useState([]);
  useEffect(() => {
    const resp2 = addJobData.vettingDetails.map((s) => {
      let temp = vettingData?.find(
        (x) => x.testName.toLowerCase() === s.name.toLowerCase()
      );
      return temp ? { ...s, status: false } : { ...s, status: true };
    });
    setVettingDetails(resp2);
    setVettingToSend(resp2.filter((s) => s.status === false));
    const resp = resp2.filter((i) => vetSkills.includes(i.name));
    setMissingVettingFor(resp);
  }, [addJobData.vettingDetails]);

  const removeMissingSkill = (skill) => {
    setMissingVettingFor((state) => state.map((x) => x !== skill));
  };

  return (
    <>
      <div className="card card-flat m-3">
        <div className="card-body">
          <div className="mx-4">
            The vetting plan will allow you to plan the vetting requirements
            needed for the job.
          </div>
          <div className="card card-flat bg-gray4 m-3">
            <div className="card-body">
              <div className="row m-4 d-flex align-items-center">
                <div className="col-lg-8">
                  Do you have a screening requirement?
                </div>
                <div className="col-lg-2">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      if (e.target.value === "Yes") {
                        setDisplayScreening(true);
                        setAddJobData({
                          ...addJobData,
                          screeningRequired: true,
                        });
                      } else {
                        if (screeningQuestions?.length > 0)
                          setShowQnAModal(true);
                        setDisplayScreening(false);
                        setAddJobData({
                          ...addJobData,
                          screeningRequired: false,
                        });
                      }
                    }}
                  >
                    <option selected={addJobData.screeningRequired} value="Yes">
                      Yes
                    </option>
                    <option selected={!addJobData.screeningRequired} value="No">
                      No
                    </option>
                  </select>
                </div>
              </div>
              {addJobData.screeningRequired && (
                <div className="mb-2 m-4 ml-5">
                  <button
                    type="button"
                    className="btn btn-collapsible"
                    onClick={() => setDisplayScreening(!displayScreening)}
                  >
                    <i
                      className={
                        displayScreening
                          ? "fal fa-angle-down fa-fw"
                          : "fal fa-angle-up fa-fw"
                      }
                    ></i>
                    <span>
                      <small>Screening Requirement</small>
                    </span>
                  </button>
                </div>
              )}

              {/* que */}
              {addJobData.screeningRequired && (
                <div className={displayScreening ? "ml-5 mb-4" : "d-none"}>
                  <ScreeningQuestions
                    selectedLanguages={selectedLanguages}
                    setSelectedLanguages={setSelectedLanguages}
                    skillSetOption={skillSetOption}
                    QualificationType={QualificationType}
                    screeningQuestions={screeningQuestions}
                    setScreeningQuestions={setScreeningQuestions}
                  />
                </div>
              )}
              {/* <div className='row m-4'>
                            <div className='col-lg-6'>
                                Do you want candidate to submit Video Pitch/Resume
                            </div>
                            <div className='col-lg-6'>
                                <select className='form-select' onChange={(e) => setResumeMandatory(e.target.value === 'Mandatory' ? true : false) }>
                                    <option selected={!resumeMandatory} value='Optional'>Preferred but not mandatory</option>
                                    <option selected={resumeMandatory} value='Mandatory'>Mandatory</option>
                                </select>
                            </div>
                        </div>    */}
              {/* PRE VETTING REQUIREMENT */}
              <div className="row m-4 d-flex align-items-center mt-5">
                {/* <div className='col-lg-1'>
                                <i className='avatar avatar-sm'><strong>3</strong></i>
                            </div> */}
                <div className="col-lg-8">
                  Do you want pre-vetted talent to be submitted?
                </div>
                <div className="col-lg-2">
                  <select
                    className="form-select"
                    onChange={(e) => {
                      if (e.target.value === "Yes") {
                        setAddJobData({ ...addJobData, vettingRequired: true });
                      } else {
                        setShowVettingModal(true);
                      }
                    }}
                  >
                    <option selected={addJobData.vettingRequired} value="Yes">
                      Yes
                    </option>
                    <option selected={!addJobData.vettingRequired} value="No">
                      No
                    </option>
                  </select>
                </div>
              </div>
              {addJobData.vettingRequired && (
                <div className="card card-flat m-2">
                  <div className="card-body">
                    <h6 className="m-1 mb-5">Suggested Vetting</h6>
                    <div className="m-4">
                      {vetSkills.length > 0 ? (
                        <div>
                          <p>
                            Choose Skills for Vetting{" "}
                            <small>(You can add maximum 3 skills)</small>
                          </p>
                          <div className="ml-2">
                            {mandatorySkills.map((i, index) => (
                              <button
                                key={index}
                                disabled={addJobData.vettingDetails
                                  .map((j) => j.name)
                                  .includes(i)}
                                className="btn btn-sm btn-secondary m-1"
                                onClick={() => {
                                  setAddSkill(false);
                                  setAddJobData({
                                    ...addJobData,
                                    vettingDetails: [
                                      ...addJobData.vettingDetails,
                                      { name: i, type: "MCQ", status: false },
                                    ],
                                  });
                                }}
                              >
                                {i}
                              </button>
                            ))}
                          </div>
                        </div>
                      ) : (
                        addJobData.vettingDetails.length === 0 && (
                          <div class="text-center p-2">
                            <div class="avatar avatar-lg">
                              <i
                                aria-hidden="true"
                                class="fad fa-users-slash"
                              ></i>
                            </div>
                            <div class="mt-2">No Skills Added</div>
                          </div>
                        )
                      )}
                    </div>
                    <div className="m-4">
                      {addJobData?.vettingDetails?.length > 0 && (
                        <>
                          {showHeader && (
                            <div className="row mb-3">
                              <div className="col-lg-2">
                                <strong>Skill</strong>
                              </div>
                              <div className="col-lg-3">
                                <strong>Type</strong>
                              </div>
                              <div className="col-lg-3">
                                <strong>Difficulty</strong>
                              </div>
                              <div className="col-lg-3">
                                <strong>Duration</strong>
                              </div>
                            </div>
                          )}

                          {vettingDetails?.map(
                            (skill, index) =>
                              !skill.status && (
                                <div className="row mb-3" key={index}>
                                  <div className="col-lg-2">{skill.name}</div>
                                  <div className="col-lg-3">
                                    <select
                                      className="form-select font-14"
                                      name="type"
                                      defaultValue=""
                                      value={
                                        addJobData?.vettingDetails[index]?.type
                                      }
                                      onChange={(e) => {
                                        handleVetting(e, index);
                                      }}
                                    >
                                      <option value="">Select</option>
                                      {Array.from(
                                        new Set(
                                          vettingData?.map(
                                            (a) => a?.testCategory
                                          )
                                        )
                                      )
                                        ?.map((id) => {
                                          return vettingData?.find(
                                            (a) =>
                                              a?.testCategory === id &&
                                              a.skills[0] === skill.name
                                          );
                                        })
                                        ?.filter((e) => e !== undefined)
                                        ?.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item?.testCategory}
                                          >
                                            {item?.testCategory}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  <div className="col-lg-3">
                                    <select
                                      className="form-select font-14"
                                      name="difficulty"
                                      value={
                                        addJobData?.vettingDetails[index]
                                          ?.difficulty
                                      }
                                      onChange={(e) => {
                                        handleVetting(e, index);
                                      }}
                                    >
                                      <option value="" selected>
                                        Select
                                      </option>
                                      {Array.from(
                                        new Set(
                                          vettingData?.map((a) => a?.difficulty)
                                        )
                                      )
                                        ?.map((id) => {
                                          return vettingData?.find(
                                            (a) =>
                                              a?.difficulty === id &&
                                              a.skills[0] === skill.name &&
                                              a?.testCategory ===
                                                addJobData?.vettingDetails[
                                                  index
                                                ]?.type
                                          );
                                        })
                                        ?.filter((e) => e !== undefined)
                                        ?.map((item, index) => (
                                          <option
                                            key={index}
                                            value={item?.difficulty}
                                          >
                                            {item?.difficulty}
                                          </option>
                                        ))}
                                    </select>
                                  </div>
                                  <div className="col-lg-3 d-flex">
                                    <div>
                                      <select
                                        className="form-select font-14"
                                        value={
                                          addJobData?.vettingDetails[index]
                                            ?.duration
                                        }
                                        name="duration"
                                        onChange={(e) => {
                                          handleVetting(e, index);
                                        }}
                                      >
                                        <option value="" selected>
                                          Select
                                        </option>
                                        {Array.from(
                                          new Set(
                                            vettingData?.map(
                                              (a) => a.details?.duration
                                            )
                                          )
                                        )
                                          .map((id) => {
                                            return vettingData?.find(
                                              (a) =>
                                                a.details?.duration === id &&
                                                a.skills[0] === skill.name &&
                                                a?.difficulty ===
                                                  addJobData?.vettingDetails[
                                                    index
                                                  ]?.difficulty &&
                                                a?.testCategory ===
                                                  addJobData?.vettingDetails[
                                                    index
                                                  ]?.type
                                            );
                                          })
                                          .filter((e) => e !== undefined)
                                          ?.sort((a, b) =>
                                            +a.details?.duration >
                                            +b.details?.duration
                                              ? 1
                                              : +b.details?.duration >
                                                +a.details?.duration
                                              ? -1
                                              : 0
                                          )
                                          .map((item, i) => (
                                            <option
                                              value={item.details?.duration}
                                              key={i}
                                            >
                                              {item.details?.duration} mins
                                            </option>
                                          ))}
                                      </select>
                                    </div>
                                    <div className="mt-1">
                                      <button
                                        type="button"
                                        className="btn btn-icon ml-2"
                                        onClick={() =>
                                          setAddJobData({
                                            ...addJobData,
                                            vettingDetails: addJobData?.vettingDetails?.filter(
                                              (item2, index2) =>
                                                index !== index2
                                            ),
                                          })
                                        }
                                      >
                                        <i
                                          className="fal fa-fw fa-trash-alt"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                          )}
                        </>
                      )}
                    </div>

                    {vettingDetails?.find((x) => x.status === true)?.status && (
                      // missingVettingFor.length > 0 &&
                      <div className="text-center mt-2 px-2">
                        <div className="avatar avatar-lg avatar-red2 mr-3">
                          <i class="fas fa-exclamation-triangle fa-fw"></i>
                        </div>
                        <p className="text-center lead mt-3">
                          Vetting for following skills not available for now
                        </p>
                        {/* {vettingDetails.map((i) => {
                            console.log("i ", i);
                            vetSkills.includes(i.name) && i.status && (
                              <div>{i.name}</div>
                            );
                          })} */}

                        {/* All mandatory skills whose vetting is not available
                          for now */}
                        {missingVettingFor?.map(
                          (i, index) =>
                            i.status && (
                              <div
                                className="d-flex justify-content-center align-items-center"
                                key={index}
                              >
                                <div className="mr-2 ">{i.name}</div>
                                {/* <div className="">
                                  <button
                                    type="button"
                                    className="btn btn-icon"
                                    onClick={(e) => {
                                      removeMissingSkill(i.name);
                                    }}
                                  >
                                    <i
                                      className="fal fa-fw fa-trash-alt"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </div> */}
                              </div>
                            )
                        )}
                      </div>
                    )}
                    <hr />

                    {addSkill && (
                      <div className="row m-3">
                        <p>Add a new skill</p>
                        <div className="col-lg-8">
                          <div className="form-group">
                            {skillArray.length === 0 ? (
                              <TagsInput
                                renderInput={autocompleteRenderInput}
                                inputClass="mb-1"
                                containerClass=""
                                value={skillArray}
                                onChange={(skills) => {
                                  setSkillArray(skills);
                                  setVetSkills([...vetSkills, skills[0]]);
                                }}
                                inputValue={skillInput.name}
                                onChangeInput={(skill) => {
                                  setSkillInput({ ...skillInput, name: skill });
                                }}
                                disabled={skillArray.length === 1}
                                maxTags={1}
                                inputProps={{
                                  placeholder:
                                    skillInput.name || skillArray.length === 1
                                      ? ""
                                      : "Select Skills*",
                                }}
                              />
                            ) : (
                              <div className="border rounded p-2">
                                {skillArray[0]}
                              </div>
                            )}
                            {"" && (
                              <small className="validation ml-2">{""}</small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-2 mt-2 ">
                          <button
                            type="button"
                            className="btn btn-icon ml-1"
                            onClick={() => {
                              setSkillInput({ name: "", type: "MCQ" });
                              setSkillArray([]);
                              setAddSkill(false);
                            }}
                          >
                            <i
                              className="fal fa-fw fa-trash-alt"
                              aria-hidden="true"
                            ></i>
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="d-flex align-items-center m-3">
                      <button
                        type="button"
                        className="btn btn-sm btn-text"
                        disabled={
                          addSkill || addJobData.vettingDetails.length === 3
                        }
                        onClick={() => {
                          setAddSkill(true);
                        }}
                      >
                        <i className="fal fa-plus mr-2"></i>
                        Add Skill
                      </button>
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={() => {
                          setAddJobData({
                            ...addJobData,
                            vettingDetails: [
                              ...addJobData.vettingDetails,
                              {
                                name: skillArray[0],
                                type: "MCQ",
                                duration: "15 mins",
                                difficulty: "Simple",
                              },
                            ],
                          });
                          setSkillInput({
                            name: "",
                            type: "MCQ",
                            duration: "15 mins",
                          });
                          setSkillArray([]);
                          setAddSkill(false);
                        }}
                        disabled={skillArray.length === 0}
                      >
                        <span>Save</span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {showQnAModal && (
        <div className="bg-gray3">
          <Modal show={true}>
            <Modal.Body>
              <div className="text-center mt-2 px-2">
                <div className="avatar avatar-lg avatar-red2 mr-3">
                  <i class="fas fa-exclamation-triangle fa-fw"></i>
                </div>
                <p className="text-center lead mt-3">
                  Existing data will be erased
                </p>
                <div class="text-center my-4">
                  <button
                    type="button"
                    className="btn btn-secondary mr-3"
                    onClick={() => {
                      setShowQnAModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setShowQnAModal(false);
                      setDisplayScreening(false);
                      setAddJobData({
                        ...addJobData,
                        screeningRequired: false,
                      });
                      setScreeningQuestions([]);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
      {showVettingModal && (
        <div className="bg-gray3">
          <Modal show={true}>
            <Modal.Body>
              <div className="text-center mt-2 px-2">
                <div className="avatar avatar-lg avatar-red2 mr-3">
                  <i class="fas fa-exclamation-triangle fa-fw"></i>
                </div>
                <p className="text-center lead mt-3">
                  Existing data will be erased
                </p>
                <div class="text-center my-4">
                  <button
                    type="button"
                    className="btn btn-secondary mr-3"
                    onClick={() => {
                      setShowVettingModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      setShowVettingModal(false);
                      setAddJobData({ ...addJobData, vettingRequired: false });
                      setScreeningQuestions([]);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </>
  );
}
export default InterviewPlan;
