import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const EmailTemplateSelectorPreview = (props) => {
  const {
    setSubject,
    setContent,
    setFooter,
    setActiveTab,
    setSelectedOption,
    template,
  } = props.data;
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateBody, setTemplateBody] = useState("");
  const [templateSignature, setTemplateSignature] = useState("");
  const [errorData, setErrorData] = useState({});

  useEffect(() => {
    setSelectedTemplate(template);
    setTemplateSubject(template.subject);
    setTemplateBody(template.mailContent);
    setTemplateSignature(template.signature);
  }, [template]);

  const onSubjectChange = (newSubject) => {
    setTemplateSubject(newSubject);
  };

  const onBodyChange = (newBody) => {
    console.log("newBody", newBody);
    setTemplateBody(newBody);
  };

  const onSignatureChange = (newSignature) => {
    setTemplateSignature(newSignature);
  };

  const validationFunction = () => {
    let errorObj = {};
    let isValid = true;
    if (templateSignature === "" || templateSignature === "<p><br></p>") {
      errorObj["templateSignature"] = "Please enter email signature";
      isValid = false;
    }
    if (templateBody === "" || templateBody === "<p><br></p>") {
      errorObj["templateBody"] = "Please enter the email body ";
      isValid = false;
    }
    if (templateSubject === "") {
      errorObj["templateSubject"] = "Please enter email subject ";
      isValid = false;
    }
    setErrorData(errorObj);
    return isValid;
  };

  const handleApply = () => {
    let isValidObj = validationFunction();
    if (isValidObj) {
      setSubject(templateSubject);
      setContent(templateBody);
      setFooter(templateSignature);
      setActiveTab("compose");
      setSelectedOption("compose");
      props.closeModal();
    } else {
      toast.error("Please  fill the mandatory fields");
    }
  };

  return (
    <div className="ml-3 mr-3" style={{ fontSize: "14px" }}>
      <div className="email-preview mt-4" style={{ overflowY: "auto" }}>
        {selectedTemplate && (
          <>
            <div>
              <p>
                {" "}
                <strong>Use this Template : </strong>
              </p>
            </div>
            <div>
              <label>Subject:</label>
              <input
                type="text"
                value={templateSubject}
                onChange={(e) => onSubjectChange(e.target.value)}
              />
              <div className="color">{errorData["templateSubject"]}</div>
            </div>
            <div>
              <label>Email Body:</label>
              <ReactQuill value={templateBody} onChange={onBodyChange} />
              <div className="color">{errorData["templateBody"]}</div>
            </div>
            <div>
              <label>Signature:</label>
              <ReactQuill
                value={templateSignature}
                onChange={onSignatureChange}
              />
              <div className="color">{errorData["templateSignature"]}</div>
            </div>
          </>
        )}
      </div>
      <div className="mt-3 mb-2 text-right">
        <button onClick={props.closeModal} className="button  mr-2">
          Cancel
        </button>
        <button
          onClick={() => {
            handleApply();
          }}
          className="btn btn-primary mr-2"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default EmailTemplateSelectorPreview;
