import {
  FETCH_EMAIL_OVERVIEW_STATS_BEGIN,
  FETCH_EMAIL_OVERVIEW_STATS_SUCCESS,
  FETCH_EMAIL_OVERVIEW_STATS_FAILURE,
  FETCH_RECENT_EMAILS_BEGIN,
  FETCH_RECENT_EMAILS_SUCCESS,
  FETCH_RECENT_EMAILS_FAILURE,
} from "../../actions/communication/GetEmailOverviewStats";

const initialState = {
  loading: false,
  emailOverViewData: null,
  recentEmailList: null,
  recentEmailListLoading: false,
};

const getEmailOverviewReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_EMAIL_OVERVIEW_STATS_BEGIN:
      return {
        ...state,
        loading: true,
        emailOverViewData: null,
      };
    case FETCH_EMAIL_OVERVIEW_STATS_SUCCESS:
      return {
        ...state,
        emailOverViewData: payload,
        loading: false,
      };
    case FETCH_EMAIL_OVERVIEW_STATS_FAILURE:
      return {
        ...state,
        emailOverViewData: payload,
        loading: false,
      };

    case FETCH_RECENT_EMAILS_BEGIN:
      return {
        ...state,
        recentEmailListLoading: true,
        recentEmailList: null,
      };
    case FETCH_RECENT_EMAILS_SUCCESS:
      return {
        ...state,
        recentEmailList: payload,
        recentEmailListLoading: false,
      };
    case FETCH_RECENT_EMAILS_FAILURE:
      return {
        ...state,
        recentEmailList: payload,
        recentEmailListLoading: false,
      };

    default:
      return state;
  }
};

export default getEmailOverviewReducer;
