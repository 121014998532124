import React, { useState, useEffect } from "react";
import { getInitial } from "../../../function/CommonFunction";
import { get, post } from "../../../../service/ApiService";
import {
  getUserRole,
  getUserName,
  getCandidateName,
  getTenantType,
} from "../../../../service/AuthService";
import { toast } from "react-toastify";
import imageLoader from "../../../../images/button-loader.gif";
import { domain } from "../../../../constants/Constants";
import Loader from "../../../Loader/Loader";
import Avatar from "react-avatar";

function DisqualifyCandidatePopup(props) {
  const [closeClicked, setCloseClicked] = useState(false);
  const [items, setItems] = useState([]);
  const [commentText, setCommentText] = useState("");
  let [selectedValue, setSelectedValue] = useState("");
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [talentData, setTalentData] = useState({});

  const talent = props.talentdata;

  const jobData = props.jobData;
  useEffect(() => {
    GetDropdownList();
  }, []);

  useEffect(() => {
    setTalentData(props.selectCandidate);
  }, [props]);

  const close = () => {
    props.handleSuccess();
  };

  const GetDropdownList = async () => {
    setLoading(true);
    try {
      const res = await get("/job/getdisqualifyreasonlist");
      if (res.status === 200) {
        const result = res.data;
        setItems(result);
      }
    } catch (error) {
      toast.error("Error Occured While fetching dropdown list");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    selectedValue = e.target.value;
    setSelectedValue(selectedValue);
  };

  const tenantType = getTenantType();
  const rejectedbyclient = getCandidateName();
  let candidateId = talentData?.candidateID;
  let candidateEmail = talentData?.candidateEmail;
  let candidateFname = talentData.candidateFname;
  let candidateLname = talentData.candidateLname;
  let candidateTenant = talentData?.candidateTenant;
  let jobTitle = talentData?.jobTitle;
  let disqualifyComments = talentData.disqualifyComments;
  let recruiterEmail = talentData?.recruiterEmail;

  let recruiterTenant = talentData?.recruiterTenant;
  let recruiterName = talentData?.recruiterName;
  let tenantName = talentData?.recruiterTenant;
  let domainName = talentData?.domainName;
  const role = getUserRole();
  //const userName = getCandidateName()
  const handleDisableClick = async (e) => {
 
    const payload = {
      jobId: jobData.jobId,
      disqualifyComments: commentText + " " + selectedValue,
      candidateId: candidateId,
      candidateFname: candidateFname,
      candidateLname: candidateLname,
      candidateTenant: candidateTenant,
      jobTitle: jobTitle,
      rejectedbyclient: rejectedbyclient,
      rejectedRole: role,
      candidateEmail: candidateEmail,
      recruiterEmail: recruiterEmail,
      tenantName: tenantName,
      recruiterTenant: recruiterTenant,
      recruiterName: recruiterName,
      domainName: domainName,
      tenantType: tenantType,
      jobTenant: jobData.jobTenant,
      selectedDisqualifyReason: selectedValue,
    };
    const paramForAutomatchDisqualify = {
      
      disqualifyComments: commentText + " " + selectedValue,
      candidateId: candidateId,
      candidateFname: candidateFname,
      candidateLname: candidateLname,
      candidateTenant: candidateTenant,
      jobTitle: jobTitle,
      rejectedbyclient: rejectedbyclient,
      rejectedRole: role,
      candidateEmail: candidateEmail,
      recruiterEmail: recruiterEmail,
      tenantName: tenantName,
      recruiterTenant: recruiterTenant,
      recruiterName: recruiterName,
      domainName: domainName,
      tenantType: tenantType,
      selectedDisqualifyReason: selectedValue,
      ...jobData,
      ...talentData,
      jobId: jobData.jobId,
    };
    try {
      setLoading(true);
      const disqualifyData =
        props.isFrom == "Automatch"
          ? await post("/jobcandidate/updateDisqualifyReasonForAutomatched", paramForAutomatchDisqualify)
          : await post("/jobcandidate/updatedisqualifyreason", payload);
      if (disqualifyData.status === 200) {
        setLoading(false);
        toast.success("Candidate Disqualified Successfully!!!");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        setLoading(false);
        toast.error("Error Occured while accessing Talent Details");
      }
      props.handleSuccess();
      props.refreshPage();
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred");
    }
    setCloseClicked(true);
  };

  const handleSelect = () => {
    setSelectedValue("Candidate not interested");
    return "Candidate not interested";
  };

  return (
    <>
      {loading && <Loader />}
      <div className="bl-modal-header">
        <h6 className="bl-modal-title">Disqualify Candidate</h6>
        <button
          type="button"
          onClick={close}
          className="close p-0 bl-modal-close-btn"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div>
        <div className="text-center">
          {
            <Avatar
              size="35"
              name={talentData.candidateFname + " " + talentData.candidateLname}
              round="80px"
            />
          }
        </div>
      </div>
      <div>
        <div className="text-center">
          <h6 className="mt-3">
            {props.candidateData.candidateFname}{" "}
            {props.candidateData.candidateLname}
          </h6>
          <p>
            {jobData.isRemote === true ? (
              <span className="mb-1 mr-1">Remote</span>
            ) : (
              <span className="mb-1 mr-1">{props.jobData.location.city}</span>
            )}{" "}
            /
            {
              <span className="mb-1 mr-1">
                {} {jobData.jobTenant}
              </span>
            }{" "}
            /
            {jobData.jobType === "Full Time"
              ? new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: jobData.placementCurrency
                    ? `${jobData.placementCurrency}`
                    : "USD",
                  maximumSignificantDigits: 3,
                }).format(jobData.annualSalary)
              : new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: jobData.placementCurrency
                    ? `${jobData.placementCurrency}`
                    : "USD",
                  maximumSignificantDigits: 3,
                }).format(jobData.hourlyRate)}
            {
              // <span className="mb-1 mr-1">
              //   {" "}
              //   {} {jobData.salaryCurrency}
              // </span>
            }
          </p>
        </div>
      </div>

      <div style={{ padding: "1px 2px 35px 0px" }}></div>

      <div>
        <div className="bl-modal-body pt-0">
          <div className="text-right mb-2"> *Denotes mandatory field</div>
          <div className="form-floating">
            <select
              onChange={handleChange}
              placeholder="Please provide the reason for disqualification"
            >
              <option selected>
                Please provide the reason for disqualification
              </option>
              {items.map((data) => (
                <option key={data.Id} value={data.ReasonForDisqualification}>
                  {data.ReasonForDisqualification}
                </option>
              ))}
            </select>
          </div>
          <div className="form-floating mt-3">
            <textarea
              className="form-control"
              placeholder="Leave a comment here"
              maxLength={250}
              id="floatingTextarea2"
              style={{ height: "100px" }}
              value={commentText}
              onChange={(e) => setCommentText(e.target.value)}
            ></textarea>
            <label htmlFor="floatingTextarea2">
              Additional comments or feedback for candidate
            </label>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={close}
            disabled={closeClicked}
            className="btn btn-secondary btn-sm"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            type="button"
            disabled={!selectedValue && !commentText}
            className="btn btn-primary btn-sm"
            onClick={handleDisableClick}
          >
            {closeClicked === false ? (
              <span>Disqualify</span>
            ) : (
              <span>
                Disqualifying Candidate
                <img width="20px" src={imageLoader} alt="blank" />
              </span>
            )}
          </button>
        </div>
      </div>
    </>
  );
}
export default DisqualifyCandidatePopup;
