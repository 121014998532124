import React, { useState } from "react";
import Messages from "./Messages";
import Emails from "./Emails";

const Communications = () => {
  const [activeTab, setActiveTab] = useState("messages");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={`nav-link ${
                  activeTab === "messages" ? "active" : ""
                }`}
                onClick={() => handleTabChange("messages")}
              >
                Messages
              </button>
            </li>
            <li className="nav-item">
              <button
                className={`nav-link ${activeTab === "emails" ? "active" : ""}`}
                onClick={() => handleTabChange("emails")}
              >
                Emails
              </button>
            </li>
          </ul>

          <hr className="pt-0 mt-0" />
        </div>
      </div>
      <div className="row">
        <div className="col">
          {activeTab === "messages" && <Messages />}
          {activeTab === "emails" && <Emails />}
        </div>
      </div>
    </div>
  );
};

export default Communications;
