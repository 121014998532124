import React, { useState } from "react";
import AddGroup from "../directsourcing/talentcommunity/addgroup/Index";
import Modal from "react-bootstrap/Modal";
import OptionModal from "../directsourcing/talentcommunity/addpoolmodal/OptionModal";
import FormSubmitSuccess from "../../common/Jobs/ViewJobDetails/FormSubmitSuccess";
import { getTenantType } from "../../../service/AuthService";
import { getTalentCommunity } from "../../../actions/directsourcing/talentcommunity/TalentCommunity";
import { useDispatch } from "react-redux";

const TCHeader = ({ clientId, type }) => {
  const dispatch = useDispatch();
  const [showAddGroup, setShowAddGroup] = useState(false);
  const [showAddPool, setShowAddPool] = useState(false);
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [successData, setSuccessData] = useState({
    title: `Community ${
      getTenantType() === 1 ? "Published" : "Created"
    } Successfully!`,
    subTitle: ``,
    htmlTag: "",
    poolId: "",
  });

  const shareClose = (e) => {
    setSuccessFormOpen(false);
    setShowAddPool(false);
    // window.location.reload();
    const param = {
      currentPage: 1,
      sortQuery: "",
      pageSize: 9,
      searchText: "",
      type: type ? type : "",
      clientId: clientId ? clientId : null,
    };
    dispatch(getTalentCommunity(param));
  };

  return (
    <>
      <div class="d-flex align-items-center justify-content-end">
        <div
          style={{ fontSize: "14px" }}
          data-bs-placement="top"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <button
            type="button"
            onClick={() => {
              setShowAddPool(true);
            }}
            className="btn btn-sm"
          >
            <i class="fas fa-plus"></i> Community
          </button>
        </div>
      </div>
      <Modal show={showAddGroup} size="lg">
        <Modal.Header className="bg-gray4">
          <buttons
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowAddGroup(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </buttons>
        </Modal.Header>
        <Modal.Body className="mt-3 p-2">
          <AddGroup setShowAddGroup={setShowAddGroup} />
        </Modal.Body>
      </Modal>
      <Modal show={showAddPool} size="lg">
        <Modal.Header>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={shareClose}
            class="close p-0 bl-modal-close-btn"
            style={{ zIndex: "9999" }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {successFormOpen ? (
            <FormSubmitSuccess
              successData={successData}
              handleSuccess={shareClose}
              isFrom="CreateSubCommunity"
            />
          ) : (
            <OptionModal
              setSuccessData={setSuccessData}
              setSuccessFormOpen={setSuccessFormOpen}
              setShowAddPool={setShowAddPool}
              type={type}
              clientId={clientId}
            ></OptionModal>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TCHeader;
