import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";

export const GET_SC_ANALYTICS_BEGIN = "GET_SC_ANALYTICS_BEGIN";
export const GET_SC_ANALYTICS_SUCCESS = "GET_SC_ANALYTICS_SUCCESS";
export const GET_SC_ANALYTICS_FAILURE = "GET_SC_ANALYTICS_FAILURE";

export const getSCAnalytics = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SC_ANALYTICS_BEGIN,
    });
    const res = await post("/analytics/subCommunityEmailAnalytics", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_SC_ANALYTICS_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SC_ANALYTICS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
