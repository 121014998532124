import {
    FETCH_SAVED_CAREER_FILTER_JOB_LIST_BEGIN,
    FETCH_SAVED_CAREER_FILTER_JOB_LIST_SUCCESS,
    FETCH_SAVED_CAREER_FILTER_JOB_LIST_FAILURE,
  } from "../../actions/jobList/CareerFiterSavedJobList";
  
  const initialState = {
    loading: true,
    savedJobFilterList: null,
  };
  
  const getCareerFilterSavedJobListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case FETCH_SAVED_CAREER_FILTER_JOB_LIST_BEGIN:
        return {
          ...state,
          loading: true,
          savedJobFilterList: null,
        };
      case FETCH_SAVED_CAREER_FILTER_JOB_LIST_SUCCESS:
        return {
          ...state,
          savedJobFilterList: payload,
          loading: false,
        };
      case FETCH_SAVED_CAREER_FILTER_JOB_LIST_FAILURE:
        return {
          ...state,
          savedJobFilterList: payload,
          loading: false,
        };
  
      default:
        return state;
    }
  };
  
  export default getCareerFilterSavedJobListReducer;
  