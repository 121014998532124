import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

const CustomProgressBar = ({ item }) => {
  const calculation = (
    (item?.candidateName.filter((x) => x.status === "Engaged")?.length /
      item?.targetSize) *
    100
  ).toFixed(2);
  const progressValue = calculation <= 100 ? calculation : 100.0;

  // Calculate color based on percentage
  const getColor = (percentage) => {
    const hue = (percentage * 1.2) % 360; // Adjust the multiplier for the color range
    return `hsl(${hue}, 70%, 50%)`;
  };

  return (
    <div style={{ width: "100%" }}>
      <LinearProgress
        variant="determinate"
        value={progressValue}
        sx={{
          height: 10,
          borderRadius: 5,
          backgroundColor: "#d6d6d6",
          "& .MuiLinearProgress-bar": {
            backgroundColor: getColor(progressValue),
          },
        }}
      />
      <Typography variant="body2" color="textSecondary" align="center">
        {`${progressValue}% Filled`}
      </Typography>
    </div>
  );
};

export default CustomProgressBar;
