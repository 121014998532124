import {
    GET_EMPLOYEES_BEGIN,
    GET_EMPLOYEES_SUCCESS,
    GET_EMPLOYEES_FAILURE,
  } from "../../actions/employees/Employees";
  
  const initialState = {
    employeesDetailsLoading: true,
    employeesDetails: [],
  };
  
  const employeesDetailsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_EMPLOYEES_BEGIN:
        return {
          ...state,
          employeesDetailsLoading: true,
          employeesDetails: [],
        };
      case GET_EMPLOYEES_SUCCESS:
        return {
          ...state,
          employeesDetails: payload,
          employeesDetailsLoading: false,
        };
      case GET_EMPLOYEES_FAILURE:
        return {
          ...state,
          employeesDetails: [],
          employeesDetailsLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default employeesDetailsReducer;
  