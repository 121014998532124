import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { emailDomain } from "../../../constants/Constants";
import { post } from "../../../service/ApiService";
import {
  getCandidateId,
  getCandidateName,
  getUserEmail,
  getUserName,
  getUserTenant,
  getUserTenantID,
} from "../../../service/AuthService";
import imageLoader from "../../../images/button-loader.gif";

const InviteCandidate = (props) => {
  const [shareClicked, setShareClicked] = useState(false);
  const MailMergedAPI = async (payload) => {
    setShareClicked(true);
    try {
      const response = await post("/inviteTalent/inviteCandidate", payload);
      if (response.status === 200) {
        setShareClicked(false);
        props.setInviteTalent(!props.inviteTalent);
        toast.success(
          `Mail has been shared successfully with selected candidates`
        );
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setShareClicked(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      setShareClicked(false);
      toast.error("Oops! something went wrong. please try again later");
    }
  };
  const inviteTalent = (e) => {
    if (!shareClicked) {
      let jobId = props.jobData.jobId;
      let jobTitle = props.jobData.jobTitle;
      let tenant_id = getUserTenantID();
      const usertenant = getUserTenant();
      const loggedinusername = getCandidateName();
      const userid = getCandidateId();
      const recruiterEmail = getUserEmail();
      // let sendMailLink = `${emailDomain}/careers/${tenant_id}/joblist/jobdescription/${jobId}/recruiter/${userid}`;
      let sendMailLink = `${emailDomain}/career/jobs/${jobId}/recruiter/${userid}`;

      let tempObj = [];
      const resObj = {
        mailList: props?.candidateDetails.email,
        sendMailLink: sendMailLink,
        jobTitle: jobTitle,
        candidateEmail: props?.candidateDetails?.email,
        candidateId: props?.candidateDetails?.candidateID,
        candidateFname: props?.candidateDetails?.firstName,
        candidateLname: props?.candidateDetails?.lastName,
        jobId: jobId,
        candidateTenant: usertenant,
        recruiterName: loggedinusername,
        recruiterId: `${userid}`,
        recruiterEmail: recruiterEmail,
      };
      tempObj.push(resObj);
      if (tempObj.length !== 0) {
        MailMergedAPI(tempObj);
      } else {
        toast.error("Select atleast one candidate");
      }
    }
  };

  return (
    <>
      <Modal
        size="md"
        show={props.inviteTalent}
        aria-labelledby="example-modal-sizes-title-md"
      >
        <Modal.Header>
          <h6 class="">Invite Talent</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => props.setInviteTalent(!props.inviteTalent)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="pl-2 m-2">
            Inviting{" "}
            <strong>
              {props?.candidateDetails?.firstName}{" "}
              {props?.candidateDetails?.lastName}{" "}
            </strong>
            for {props?.jobData?.jobTitle} job. <br />
            Are you sure?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-sm"
            variant="secondary"
            onClick={() => props.setInviteTalent(!props.inviteTalent)}
          >
            Cancel
          </Button>
          <Button
            className="btn-sm"
            variant="primary"
            onClick={() => inviteTalent()}
          >
            {!shareClicked ? (
              <span>Invite</span>
            ) : (
              <span>
                Sending
                <img width="20px" src={imageLoader} alt="blank" />
              </span>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default InviteCandidate;
