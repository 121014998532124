import React, { useEffect, useState } from "react";
import GridView from "./views/GridView";
import ListView from "./views/ListView";
import TableView from "./views/TableView";
import NoRecord from "../../commoncomponents/norecord/NoRecord";

const TalentView = ({
  pool,
  view,
  setCurrentPage,
  currentPage,
  pageSize,
  setPageSize,
  isFrom,
}) => {
  return (
    <>
      {(!view || view == "grid") && (
        <GridView
          pool={pool}
          isFrom={isFrom}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
      {view == "list" && (
        <ListView
          pool={pool}
          isFrom={isFrom}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
      {view == "table" && (
        <TableView
          pool={pool}
          isFrom={isFrom}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      )}
      {pool.length === 0 && <NoRecord />}
    </>
  );
};

export default TalentView;
