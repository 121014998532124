import React, { useState, useEffect } from "react";
import CandidateProfileOverview from "../../../modal/talentprofile/CandidateProfileOverview";
import CandidateProfileScreenings from "./CandidateProfileScreening";
import CandidateProfileInterviews from "./CandidateProfileInterview";
import CandidateProfileHistory from "./CandidateProfileHistory";
import Rtr from "./Rtr";
import vettedIcon from "../../../../images/high5-mark-circle-dark.svg";
import notVettedIcon from "../../../../images/high5-mark-circle-dark.svg";
import { domain } from "../../../../constants/Constants";
import { post } from "../../../../service/ApiService";
import Loader from "../../../Loader/Loader";
import { getInitial } from "../../../function/CommonFunction";
import MakeAnOfferPopUp from "../ViewJobDetails/MakeAnOfferPopUp";
import Modal from "react-bootstrap/Modal";
import FormSubmitSuccess from "../ViewJobDetails/FormSubmitSuccess";
import DisqualifyCandidatePopup from "../ViewJobDetails/DisqualifyCandidatePopUp";
import ShortListPopup from "../ViewJobDetails/ShortlistPopUp";
import OfferAcceptPopUp from "../ViewJobDetails/OfferAcceptPopUp";
import OfferPopUp from "../ViewJobDetails/OfferPopUp";
import SkillMatrix from "./SkillMatrix";
import ShortListPopUp from "../ViewJobDetails/ShortlistPopUp";

import {
  getCandidateName,
  getUserRole,
  getUserTenant,
  getActualRole,
} from "../../../../service/AuthService";

import Avatar from "react-avatar";
import SubmitTalentModal from "../../../candidate/sourcecandidate/SubmitTalentModal";
import AutoMatchScoreCard from "./AutoMatchScoreCard";
import QandAResponse from "./QandAResponse";
import { toast } from "react-toastify";
import moment from "moment";
import axios from "axios";
import VettingResendMail from "../ViewJobDetails/VettingResendMail";
import AutoMatchScoreCardDS from "../../../directsourcing/career/AutoMatchScoreCardDS";
import OutsideClickHandler from "react-outside-click-handler";

function CandidateProfile(props) {
  const role = localStorage.getItem("role");
  const successData = props.successData;
  const [jobData, setJobData] = useState({});
  const [overview, setOverview] = useState(true);
  const [screenings, setScreenings] = useState(false);
  const [resumes, setResumes] = useState(false);
  const [skill, setSkill] = useState(false);
  const [resumeDownload, setResumeDownload] = useState([]);
  const [interviews, setInterviews] = useState(false);
  const [rtr, setRtr] = useState(false);
  const [history, setHistory] = useState(false);
  const [loading, setLoading] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [openDropDownDiv, setOpenDropDownDiv] = useState(false);
  const [submitOpenDropDownDiv, setSubmitOpenDropDownDiv] = useState(false);
  const [offerOpenDropDownDiv, setOfferOpenDropDownDiv] = useState(false);
  // const [jobDetails, setJobDetails] = useState({})
  const [makeAnOffer, setMakeAnOffer] = useState(false);
  const [DisqualifyCandidate, setDisqualifyCandidate] = useState(false);
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [isVettingComplete, setIsVettingComplete] = useState(false);
  const [shortList, setShortList] = useState(false);
  const userTenant = getUserTenant();
  const [interviewSchedular, setInterviewSchedular] = useState(false);

  const [vettingAssessmentData, setVettingAssessmentData] = useState({
    mcq: [],
    oneWay: [],
  });
  const talentdata = props.talentdata;
  const [sourceTalent, setSourceTalent] = useState(false);
  const [offered, setOffered] = useState(false);
  let candidateData1 = props.candidateProfileData;
  let candId = candidateData1.candidateId;
  const [offers, setOffers] = useState(false);
  const [selectCandidate, setSelectedCandidate] = useState({});
  const [score, setScore] = useState(false);
  const [offerAcepted, setOfferAccepted] = useState(false);
  const [sourceClicked, setSourceClicked] = useState(false);
  const [MCQInterview, setMCQInterview] = useState(false);
  const [resumeList, setResumeList] = useState([]);
  const [mcqVetting, setMcqVetting] = useState([]);
  const [oneWayVetting, setOneWayVetting] = useState([]);
  const [generalVetting, setGeneralVetting] = useState([]);
  const [candidateVettingDetails, setCandidateVettingDetails] = useState([]);
  const [vettingStatus, setVettingStatus] = useState([]);
  const [vettingCheck, setVettingCheck] = useState(false);
  const [statusClick, setStatusClick] = useState("");
  const [vettingLoading, setVettingLoading] = useState(false);
  const [matchingScore, setMatchingScore] = useState(false);

  useEffect(() => {
    if (props.component === "SourceTalent") {
      setOverview(false);
      setScore(true);
    }
    setJobData(props.jobData);
    fetchVettingAssessmentDetail();
    fetchResume();
  }, [props]);

  useEffect(() => {
    setMcqVetting(
      props?.candidateVettingDetails?.filter(
        (elem) => elem?.testAssign?.testCategory == "MCQ"
      )
    );
    setOneWayVetting(
      props?.candidateVettingDetails?.filter(
        (elem) => elem?.testAssign?.testCategory == "OneWay"
      )
    );
    setGeneralVetting(
      props?.candidateVettingDetails?.filter(
        (elem) => elem?.testAssign?.testCategory == "General"
      )
    );
  }, [props?.candidateVettingDetails]);

  useEffect(async () => {
    const body = {
      high5hireCandidateId: props?.candidateVettingDetails
        ? props?.candidateVettingDetails[0]?.high5hireCandidateId
        : "",
      jobId: props?.candidateVettingDetails
        ? props?.candidateVettingDetails[0]?.jobId
        : "",
    };
    let arrMCQ = [];
    let arrOneWay = [];
    let arrGeneral = [];
    if (props?.candidateVettingDetails) {
      if (props?.candidateVettingDetails[0]?.high5hireCandidateId) {
        setVettingLoading(true);
        const data = await post("/vetting/candidate/high5hire", body);
        let idList = [];
        idList = data?.data.map((element) => {
          return { candidateId: element._id };
        });

        if (idList.length > 0) {
          const res = await post("/vetting/result/list", idList);
          data?.data.forEach(async (element) => {
            res?.data.forEach(async (item) => {
              if (element._id == item.candidateId) {
                if (element?.testAssign?.testCategory == "OneWay") {
                  element.isReviewed = item.isReviewed;
                  arrOneWay = [...arrOneWay, element];
                } else if (element?.testAssign?.testCategory == "General") {
                  element.isReviewed = item.isReviewed;
                  arrGeneral = [...arrGeneral, element];
                } else if (element?.testAssign?.testCategory == "MCQ") {
                  if (element.testCompleted == true) {
                    element.isReviewed = true;
                    arrMCQ = [...arrMCQ, element];
                  } else {
                    element.isReviewed = false;
                    arrMCQ = [...arrMCQ, element];
                  }
                }
              }
            });
          });
          let temp = [...arrMCQ, ...arrGeneral, ...arrOneWay];
          setVettingStatus(temp);
          setMcqVetting(arrMCQ);
          setGeneralVetting(arrGeneral);
          setOneWayVetting(arrOneWay);
          setVettingLoading(false);
        }
      }
    }
    setVettingLoading(false);
  }, [props?.candidateVettingDetails]);

  const getCandidateVettingDetails = async (item) => {
    setLoading(true);
    const params = {
      high5hireCandidateId: item.candidateID,
      jobId: props.jobData.jobId,
      email: item.email,
    };
    const res = await post(`/vetting/candidate/high5hire`, params);
    if (res.status == 200) {
      setCandidateVettingDetails(res.data);
      setLoading(false);
    }
  };

  const fetchVettingAssessmentDetail = async () => {
    try {
      setLoading(true);
      const candidateEmail = props?.candidateVettingDetails?.email;
      const candidateID = props?.candidateVettingDetails?.high5hireCandidateId;
      let payload = {
        email: candidateEmail,
        id: candidateID,
      };
      let response = await post("/view/vettingViewAssessment", payload);
      if (response.status === 200) {
        setLoading(false);
        setVettingAssessmentData({
          ...vettingAssessmentData,
          mcq: response.data?.mcq,
          oneWay: response.data?.oneWay,
        });
      }
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  const handleClose = () => {
    props.handleSuccess(true);
  };

  const onTabClick = (type) => {
    if (type === "offers") {
      setOverview(false);
      setScreenings(false);
      setInterviews(false);
      setRtr(false);
      setHistory(false);
      setSkill(false);
      setOffers(true);
      setScore(false);
      setMatchingScore(false);
    }
    if (type === "overview") {
      setOverview(true);
      setScreenings(false);
      setInterviews(false);
      setRtr(false);
      setHistory(false);
      setSkill(false);
      setOffers(false);
      setScore(false);
      setMatchingScore(false);
    }
    if (type === "screenings") {
      setScreenings(true);
      setOverview(false);
      setInterviews(false);
      setRtr(false);
      setHistory(false);
      setSkill(false);
      setOffers(false);
      setScore(false);
      setMatchingScore(false);
    }
    if (type === "interviews") {
      setScreenings(false);
      setOverview(false);
      setInterviews(true);
      setRtr(false);
      setHistory(false);
      setSkill(false);
      setOffers(false);
      setScore(false);
      setMatchingScore(false);
    }
    if (type === "score") {
      setScreenings(false);
      setOverview(false);
      setInterviews(false);
      setScore(false);
      setRtr(false);
      setHistory(false);
      setSkill(false);
      setOffers(false);
      setMatchingScore(true);
    }
    if (type === "rtr") {
      setScreenings(false);
      setOverview(false);
      setInterviews(false);
      setRtr(true);
      setHistory(false);
      setSkill(false);
      setOffers(false);
      setScore(false);
      setMatchingScore(false);
    }
    if (type === "history") {
      setScreenings(false);
      setOverview(false);
      setInterviews(false);
      setRtr(false);
      setHistory(true);
      setSkill(false);
      setOffers(false);
      setScore(false);
      setMatchingScore(false);
    }
    if (type === "skill") {
      setScreenings(false);
      setOverview(false);
      setInterviews(false);
      setRtr(false);
      setHistory(false);
      setSkill(true);
      setOffers(false);
      setScore(false);
      setMatchingScore(false);
    }
  };

  const openDropDown = (candidate) => {
    setSelectedCandidate(candidate);
    if (openDropDownDiv === true) {
      setOpenDropDownDiv(false);
    } else {
      setOpenDropDownDiv(true);
    }
  };

  const submitOpenDropDown = (candidate) => {
    setSelectedCandidate(candidate);
    if (submitOpenDropDownDiv === true) {
      setSubmitOpenDropDownDiv(false);
    } else {
      setSubmitOpenDropDownDiv(true);
    }
  };

  const offerDropDown = (candidate) => {
    setSelectedCandidate(candidate);
    if (offerOpenDropDownDiv === true) {
      setOfferOpenDropDownDiv(false);
    } else {
      setOfferOpenDropDownDiv(true);
    }
  };

  const ReOfferOpen = (candidate) => {
    setMakeAnOffer(true);
    setSelectedCandidate(candidate);
  };

  const makeAnOfferOpen = (candidate) => {
    setSelectedCandidate(candidate);
    setOpenDropDownDiv(false);
    let temp = false;

    if (
      props.jobData.vettingDetails != null &&
      props?.candidateVettingDetails?.length != 0
    ) {
      vettingStatus.forEach((element) => {
        if (element.isReviewed == true || element.isReviewed == undefined) {
          if (element.testCompleted) {
            temp = true;
          }
        }
      });
      if (temp) {
        setMakeAnOffer(true);
      } else {
        setIsVettingComplete(true);
      }
    } else {
      setMakeAnOffer(true);
    }
    setStatusClick("makeAnOffer");
  };

  const onDisqualifyClick = (candidate) => {
    setSelectedCandidate(candidate);
    setDisqualifyCandidate(true);
    setOpenDropDownDiv(false);
    setSubmitOpenDropDownDiv(false);
  };

  const offerAcceptedOpen = (candidate) => {
    setSelectedCandidate(candidate);
    setOfferAccepted(true);
    setOpenDropDownDiv(false);
  };

  const openShortList = (candidate) => {
    setSelectedCandidate(candidate);
    setShortList(true);
  };

  const shortlistCandidate = async (item) => {
    setCandidateData(item);
    let temp = false;

    if (
      props.jobData.vettingDetails != null &&
      props?.candidateVettingDetails?.length != 0
    ) {
      vettingStatus.forEach((element) => {
        if (element.isReviewed == true || element.isReviewed == undefined) {
          if (element.testCompleted) {
            temp = true;
          }
        }
      });
      if (temp) {
        openShortList(candidateData1);
      } else {
        setIsVettingComplete(true);
      }
    } else {
      openShortList(candidateData1);
    }
  };

  const setOfferedDate = (candidate) => {
    setSelectedCandidate(candidate);
    setOffered(true);
  };

  const handleModel = (type) => {
    setDisqualifyCandidate(false);
    setMakeAnOffer(false);
    setSuccessFormOpen(false);
    setShortList(false);
    setOffered(false);
    setOfferAccepted(false);
    setInterviewSchedular(false);
  };

  const onCollapsibleClick = (val) => {
    if (val === "MCQInterview") {
      setMCQInterview(true);
    }
    if (val === "MCQInterviewReset") {
      setMCQInterview(false);
    }
  };

  const handleCopy = (eachdata) => {
    navigator.clipboard.writeText(eachdata);
    toast.success("Link Copied Successfully!!!");
  };

  const resendRTR = async () => {
    setLoading(true);
    try {
      let params = {
        firstName: candidateData1.candidateFname,
        UUID: candidateData1.UUID,
        jobTitle: props.jobData.jobTitle,
        email: candidateData1.candidateEmail,
      };
      const res = await post("/submitcandidate/resendRTR", params);
      if (res.status === 200) {
        toast.success("Resent Successfully");
      } else {
        toast.error("Some Error Occurred");
      }
    } catch (err) {
      toast.error("Some Error Occurred. Please Try Again");
    }
    setLoading(false);
  };

  const submit = async (mapperId) => {
    setLoading(true);
    try {
      let params = { mapperId: candidateData1.mapperId };
      const res = await post("/submitcandidate/apply", params);
      if (res.status === 200) {
        toast.success("Candidate Submitted Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        toast.error("Some Error Occurred");
      }
    } catch (err) {
      toast.error("Some Error Occurred. Please Try Again");
    }
    setLoading(false);
  };

  const fetchResume = async () => {
    const payload = {
      candidateID: candidateData1.candidateID,
      tenant: candidateData1.createdByTenant,
    };
    try {
      let res = await post("/candidateProfile/getResumeTC", payload);
      setResumeList(res.data);
      setLoading(false);
    } catch (err) {
      // setLoading(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  const openAnotherRequest = (candidate) => {
    setInterviewSchedular(true);
    setOpenDropDownDiv(false);
  };

  const handleButton = (item) => {
    if (item?.disqualifyComments.includes("Offer Rejected")) {
      ReOfferOpen(item);
    } else {
      shortlistCandidate(item);
    }
  };

  const buttonHandler = (item) => {
    if (
      getActualRole() === "High5Coordinator" &&
      getUserRole() === "HiringManager" &&
      item.candidateId
    ) {
      return (
        <button
          type="button"
          style={{ borderRadius: "5px" }}
          onClick={() => handleButton(item)}
          disabled={item.candidateId == null ? true : false}
        >
          {item?.disqualifyComments.includes("Offer Rejected")
            ? "Re-Offer"
            : "Shortlist"}
        </button>
      );
    } else if (
      getUserRole() === "HiringManager" &&
      item?.disqualifyComments.includes("Offer Rejected")
    ) {
      return (
        <button
          type="button"
          style={{ borderRadius: "5px" }}
          onClick={() => handleButton(item)}
          disabled={item.candidateId == null ? true : false}
        >
          Re-Offer
        </button>
      );
    } else {
      return null;
    }
  };

  return (
    <>
      {(loading || vettingLoading) && <Loader />}
      <div className="view-panel show" style={{ width: "652px" }}>
        <div className="view-panel-header">
          <div className="px-3 py-2">
            <button
              type="button"
              onClick={handleClose}
              className="btn btn-icon"
            >
              <i className="fal fa-times"></i>
            </button>
          </div>
          <div className="px-3">
            <div className="d-flex align-items-center flex-wrap-reverse">
              <div className="d-flex align-items-start">
                <span className="avatar-lg">
                  {candidateData1.ImageURL ? (
                    <span>
                      <img
                        className="avatar"
                        alt=""
                        src={candidateData1.ImageURL}
                      />
                    </span>
                  ) : (
                    <Avatar
                      size="35"
                      name={
                        candidateData1.candidateFname +
                        " " +
                        candidateData1.candidateLname
                      }
                      round="80px"
                    />
                  )}
                </span>
                <div className="ml-3">
                  <div className="d-flex">
                    <h6 className="mt-1 mr-3">
                      {props.isFrom ==
                      ("QualifiedCandidates" || "inviteTalent") ? (
                        <>
                          {candidateData1.candidateFname
                            .charAt(0)
                            .toUpperCase() +
                            candidateData1.candidateFname.slice(1)}{" "}
                          {candidateData1.candidateLname
                            .charAt(0)
                            .toUpperCase()
                          // +
                          // candidateData1.candidateLname.slice(1)
                          }
                        </>
                      ) : (
                        <>
                          {candidateData1.candidateFname
                            .charAt(0)
                            .toUpperCase() +
                            candidateData1.candidateFname.slice(1)}{" "}
                          {candidateData1.candidateLname
                            .charAt(0)
                            .toUpperCase() +
                            candidateData1.candidateLname.slice(1)}
                        </>
                      )}
                    </h6>
                    <div className="ml-auto d-flex align-items-center mb-2">
                      <div className="btn-group btn-group-sm btn-split show">
                        <hr className="d-none d-block-1024 my-2" />
                        {candidateData1.statusName === "Shortlisted" &&
                          (getUserRole() === "HiringManager" ||
                            role === "High5Coordinator") && (
                            <div className="btn-group btn-group-sm btn-split">
                              <button
                                type="button"
                                onClick={() => makeAnOfferOpen(candidateData1)}
                                className="btn btn-sm"
                              >
                                Make an Offer
                              </button>
                              <button
                                type="button"
                                onClick={() => openDropDown()}
                                className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="true"
                              >
                                <span className="sr-only">Toggle Dropdown</span>
                              </button>
                            </div>
                          )}

                        {candidateData1.statusName === "Submitted" &&
                          role === "High5Coordinator" && (
                            <div className="btn-group btn-group-sm btn-split">
                              <button
                                type="button"
                                onClick={() =>
                                  shortlistCandidate(candidateData1)
                                }
                                className="btn btn-sm"
                              >
                                Shortlist
                              </button>
                              <button
                                type="button"
                                onClick={() => submitOpenDropDown()}
                                className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span className="sr-only">Toggle Dropdown</span>
                              </button>
                            </div>
                          )}

                        {candidateData1.statusName === "Rejected" &&
                          props.isFrom === "DisqualifiedCandidates" && (
                            <div className="no-hover text-right font-16">
                              {buttonHandler(candidateData1)}
                            </div>
                          )}
                        {candidateData1.statusName === "Shortlisted" &&
                          props.isFrom === "DisqualifiedCandidates" && (
                            <div className="no-hover text-right font-16">
                              {buttonHandler(candidateData1)}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  {props.component === "SourceTalent" && (
                    <div class="font-regular font-primary font-gray1 text-truncate">
                      {candidateData1.designation && (
                        <span>{candidateData1.designation}</span>
                      )}
                      {candidateData1.designation &&
                        (candidateData1?.fullText?.cityName !== "" ||
                          candidateData1?.fullText?.stateName !== "") &&
                        " / "}
                      {
                        <span>
                          {candidateData1?.fullText?.cityName}
                          {candidateData1?.fullText?.stateName && ", "}
                          {candidateData1?.fullText?.countryName}
                        </span>
                      }
                    </div>
                  )}
                  <div className="d-flex align-items-start">
                    {/* getUserRole() !== "HiringManager" && */}
                    {props.component !== "SourceTalent" && (
                      <span className="tag tag-lg">
                        {candidateData1.statusName && (
                          <span>
                            {candidateData1.statusName === "Sourced"
                              ? "Approval Pending"
                              : candidateData1.statusName == "Pending"
                              ? "Offer Pending"
                              : candidateData1.statusName === "Rejected"
                              ? "Disqualified"
                              : candidateData1.statusName}
                          </span>
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {candidateData1.statusName === "Automatched" ||
            candidateData1.statusName === "Applied" ? (
              <div className="d-flex">
                <ul className="nav nav-tabs mt-3">
                  <li className="nav-item">
                    <button
                      type="button"
                      className={
                        overview ? "nav-link pb-3 active" : "nav-link pb-3"
                      }
                      onClick={() => onTabClick("overview")}
                    >
                      Profile
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      type="button"
                      className={
                        score ? "nav-link pb-3 active" : "nav-link pb-3"
                      }
                      onClick={() => {
                        setScore(true);
                        setOverview(false);
                      }}
                    >
                      Matching Score
                    </button>
                  </li>
                </ul>
                {props.component === "SourceTalent" && (
                  <div className="ml-auto">
                    <button
                      type="button"
                      disabled={sourceClicked}
                      onClick={() => {
                        setSourceClicked(true);
                        setSourceTalent(true);
                      }}
                      className="btn btn-sm"
                    >
                      Source
                    </button>
                  </div>
                )}
              </div>
            ) : (
              <ul className="nav nav-tabs mt-3">
                <li className="nav-item">
                  <button
                    type="button"
                    className={
                      overview ? "nav-link pb-3 active" : "nav-link pb-3"
                    }
                    onClick={() => onTabClick("overview")}
                  >
                    Profile
                  </button>
                </li>
                {props.isFrom !== "QualifiedCandidates" &&
                  props.isFrom !== "inviteTalent" &&
                  candidateData1.candidateId && (
                    <li className="nav-item">
                      <button
                        type="button"
                        className={
                          screenings ? "nav-link pb-3 active" : "nav-link pb-3"
                        }
                        onClick={() => {
                          onTabClick("screenings");
                        }}
                      >
                        {"Screening"}
                      </button>
                    </li>
                  )}
                <li className="nav-item">
                  {props.tabName === "invited" ||
                  props.isFrom === "QualifiedCandidates" ? (
                    <></>
                  ) : (
                    candidateData1.candidateId && (
                      <button
                        type="button"
                        className={
                          interviews ? "nav-link pb-3 active" : "nav-link pb-3"
                        }
                        onClick={() => {
                          onTabClick("interviews");
                        }}
                      >
                        Assessment
                      </button>
                    )
                  )}
                </li>
                {props?.tabName === "invited" ||
                props.isFrom === "QualifiedCandidates" ? (
                  <></>
                ) : (
                  candidateData1.candidateId && (
                    <li className="nav-item">
                      <button
                        type="button"
                        className={
                          score ? "nav-link pb-3 active" : "nav-link pb-3"
                        }
                        onClick={() => onTabClick("score")}
                      >
                        Matching Score
                      </button>
                    </li>
                  )
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="view-panel-body">
          {overview && (
            <CandidateProfileOverview
              resumeURL={resumeDownload}
              fullText={candidateData1}
              candidateData={candidateData1}
              disposition={true}
              resumeList={resumeList}
              isFrom={props.isFrom}
            />
          )}
          {screenings && (
            // <CandidateProfileScreenings screen={props.candidateProfileData.screeningQuestions} />
            <QandAResponse
              QandA={
                candidateData1.QandA ? JSON.parse(candidateData1.QandA) : []
              }
            />
          )}
          {skill && candidateData1.statusName !== "Automatched" && (
            <SkillMatrix
              primary={props.candidateProfileData?.primarySkillMatrix}
              secondary={props.candidateProfileData?.secondarySkillMatrix}
            />
          )}

          {interviews && props.mcqVetting?.length > 0 && (
            <div>
              <div className="mb-2">
                <button type="button" className="btn btn-collapsible">
                  {MCQInterview !== true ? (
                    <i
                      className="fal fa-angle-down fa-fw"
                      onClick={() => onCollapsibleClick("MCQInterview")}
                    ></i>
                  ) : (
                    <i
                      className="fal fa-angle-up fa-fw mt-1"
                      onClick={() => onCollapsibleClick("MCQInterviewReset")}
                    ></i>
                  )}
                  <span>
                    <small>MCQ</small>
                  </span>
                </button>
              </div>
              {props.mcqVetting?.map((elem) => (
                <div
                  className={
                    MCQInterview === true
                      ? " card ml-4 mb-4 d-none"
                      : "card ml-4 mb-4"
                  }
                >
                  <div className="card-body">
                    <table className="table table-hoverable">
                      <tbody>
                        <tr className="mb-1">
                          {/* {props.candidateVettingDetails.testCompleted ===
                        "Completed" ? 
                        ( */}
                          <td>
                            <div className="col-lg-12 d-flex justify-content-between">
                              <h6 className="mb-1">
                                {elem?.testAssign?.testName}
                              </h6>
                              {/* <button
                                type="button"
                                className="btn btn-sm btn-gray mb-1"
                                onClick={() =>
                                  window.open(
                                    `https://vetuat.high5hire.app/assessmentResult/${props.candidateVettingDetails._id}`,
                                    "_blank"
                                  )
                                }
                              >
                                Certificate
                              </button> */}
                              {elem?.testCompleted && (
                                <>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1 ml-5"
                                    onClick={() =>
                                      handleCopy(
                                        `https://vetuat.high5hire.app/assessmentResult/${elem._id}`
                                      )
                                    }
                                  >
                                    <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                                    Share Result
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1"
                                    // onClick={() =>
                                    //   window.open(elem.ReviewURL, "_blank")
                                    // }
                                    onClick={() =>
                                      window.open(
                                        `https://vetuat.high5hire.app/assessmentResult/${elem._id}`,
                                        "_blank"
                                      )
                                    }
                                  >
                                    <i className="fal fa-list-alt mr-2" />
                                    View Result
                                  </button>
                                </>
                              )}
                            </div>
                            <div class="py-2 mt-2 w-100">
                              <span
                                class={
                                  elem.testCompleted
                                    ? "mr-2 tag font-bold tag-green1 mb-2"
                                    : "mr-2 tag font-bold tag-orange1 mb-2"
                                }
                              >
                                {" "}
                                Status :{" "}
                                {elem.testCompleted ? "Completed" : "Invited"}
                              </span>
                              <span class="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-2"
                                ></i>
                                Assigned By :{" "}
                                {`${elem?.companyInfo?.companyName} `}{" "}
                              </span>
                              <span
                                class="tag mb-2"
                                style={{ marginLeft: "5px" }}
                              >
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>{" "}
                                Assigned:{" "}
                                {elem.createdAt
                                  ? moment(elem.createdAt).format("MM/DD/YYYY")
                                  : "N/A"}{" "}
                              </span>
                            </div>
                          </td>
                          {/* ) 
                        : 
                        (
                          <td>
                            <h6 class="mb-1">{eachdata.title}</h6>
                            <div class=" py-2 mt-2">
                              <span class="mr-2 tag font-bold tag-orange1 mb-2">
                                {" "}
                                Status : {eachdata.status}
                              </span>
                              <span class="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-1"
                                ></i>
                                Assigned By : {eachdata.assignedBy}{" "}
                              </span>
                              <span class="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>{" "}
                                Assigned:{" "}
                                {eachdata.recordedTime
                                  ? moment(eachdata.recordedTime).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "N/A"}{" "}
                              </span>
                            </div>
                          </td>
                        )
                        } */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          )}
          {interviews && props.oneWayVetting?.length > 0 && (
            <div>
              <div className="mb-2">
                <button type="button" className="btn btn-collapsible">
                  {MCQInterview !== true ? (
                    <i
                      className="fal fa-angle-down fa-fw"
                      onClick={() => onCollapsibleClick("MCQInterview")}
                    ></i>
                  ) : (
                    <i
                      className="fal fa-angle-up fa-fw mt-1"
                      onClick={() => onCollapsibleClick("MCQInterviewReset")}
                    ></i>
                  )}
                  <span>
                    <small>One Way</small>
                  </span>
                </button>
              </div>
              {props.oneWayVetting?.map((elem) => (
                <div
                  className={
                    MCQInterview === true
                      ? " card ml-4 mb-4 d-none"
                      : "card ml-4 mb-4"
                  }
                >
                  <div className="card-body">
                    <table className="table table-hoverable">
                      <tbody>
                        <tr className="mb-1">
                          {/* {props.candidateVettingDetails.testCompleted ===
                        "Completed" ? 
                        ( */}
                          <td>
                            <div className="col-lg-12 d-flex justify-content-between">
                              <h6 className="mb-1">
                                {elem?.testAssign?.testName}
                              </h6>
                              {/* <button
                                type="button"
                                className="btn btn-sm btn-gray mb-1"
                                onClick={() =>
                                  window.open(
                                    `https://vetuat.high5hire.app/assessmentResult/${props.candidateVettingDetails._id}`,
                                    "_blank"
                                  )
                                }
                              >
                                Certificate
                              </button> */}
                              {elem?.isReviewed && (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-gray mb-1 ml-5"
                                  onClick={() =>
                                    handleCopy(
                                      `https://vetuat.high5hire.app/assessmentResult/${elem?._id}`
                                    )
                                  }
                                >
                                  <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                                  Share Result
                                </button>
                              )}
                              {elem?.isReviewed && (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-gray mb-1"
                                  onClick={() =>
                                    window.open(
                                      `https://vetuat.high5hire.app/assessmentResult/${elem?._id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  <i className="fal fa-list-alt mr-2" />
                                  View Result
                                </button>
                              )}
                            </div>

                            <div class="py-2 mt-2 w-100">
                              <span
                                class={
                                  elem?.isReviewed
                                    ? "mr-2 tag font-bold tag-green1 mb-2"
                                    : elem?.testCompleted
                                    ? "mr-2 tag font-bold tag-lightBlue mb-2"
                                    : "mr-2 tag font-bold tag-orange1 mb-2"
                                }
                              >
                                {" "}
                                Status :{" "}
                                {elem?.isReviewed
                                  ? "Completed"
                                  : elem?.testCompleted
                                  ? "Under Review"
                                  : "Invited"}
                              </span>
                              <span class="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-2"
                                ></i>
                                Assigned By :{" "}
                                {`${elem?.companyInfo?.companyName} `}{" "}
                              </span>
                              <span
                                class="tag mb-2"
                                style={{ marginLeft: "5px" }}
                              >
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>{" "}
                                Assigned:{" "}
                                {elem.createdAt
                                  ? moment(elem.createdAt).format("MM/DD/YYYY")
                                  : "N/A"}{" "}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          )}
          {interviews && props.generalVetting?.length > 0 && (
            <div>
              <div className="mb-2">
                <button type="button" className="btn btn-collapsible">
                  {MCQInterview !== true ? (
                    <i
                      className="fal fa-angle-down fa-fw"
                      onClick={() => onCollapsibleClick("MCQInterview")}
                    ></i>
                  ) : (
                    <i
                      className="fal fa-angle-up fa-fw mt-1"
                      onClick={() => onCollapsibleClick("MCQInterviewReset")}
                    ></i>
                  )}
                  <span>
                    <small>General</small>
                  </span>
                </button>
              </div>
              {props.generalVetting?.map((elem) => (
                <div
                  className={
                    MCQInterview === true
                      ? " card ml-4 mb-4 d-none"
                      : "card ml-4 mb-4"
                  }
                >
                  <div className="card-body">
                    <table className="table table-hoverable">
                      <tbody>
                        <tr className="mb-1">
                          {/* {props.candidateVettingDetails.testCompleted ===
                        "Completed" ? 
                        ( */}
                          <td>
                            <div className="col-lg-12 d-flex justify-content-between">
                              <h6 className="mb-1">
                                {elem?.testAssign?.testName}
                              </h6>

                              {elem?.isReviewed && (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-gray mb-1 ml-5"
                                  onClick={() =>
                                    handleCopy(
                                      `https://vetuat.high5hire.app/assessmentResult/${elem?._id}`
                                    )
                                  }
                                >
                                  <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                                  Share Result
                                </button>
                              )}
                              {elem?.isReviewed && (
                                <button
                                  type="button"
                                  className="btn btn-sm btn-gray mb-1"
                                  onClick={() =>
                                    window.open(
                                      `https://vetuat.high5hire.app/assessmentResult/${elem?._id}`,
                                      "_blank"
                                    )
                                  }
                                >
                                  <i className="fal fa-list-alt mr-2" />
                                  View Result
                                </button>
                              )}
                            </div>
                            <div class="py-2 mt-2 w-100">
                              <span
                                class={
                                  elem?.isReviewed
                                    ? "mr-2 tag font-bold tag-green1 mb-2"
                                    : elem?.testCompleted
                                    ? "mr-2 tag font-bold tag-lightBlue mb-2"
                                    : "mr-2 tag font-bold tag-orange1 mb-2"
                                }
                              >
                                {" "}
                                Status :{" "}
                                {elem?.isReviewed
                                  ? "Completed"
                                  : elem?.testCompleted
                                  ? "Under Review"
                                  : "Invited"}
                              </span>
                              <span class="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-2"
                                ></i>
                                Assigned By :{" "}
                                {`${elem?.companyInfo?.companyName} `}{" "}
                              </span>
                              <span
                                class="tag mb-2"
                                style={{ marginLeft: "5px" }}
                              >
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>{" "}
                                Assigned:{" "}
                                {elem.createdAt
                                  ? moment(elem.createdAt)?.format("MM/DD/YYYY")
                                  : "N/A"}{" "}
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
          )}
          {interviews &&
            props.generalVetting?.length === 0 &&
            props.oneWayVetting?.length === 0 &&
            props.mcqVetting?.length === 0 && (
              <div className="card card-lg card-borderless card-flat  w-100">
                <div className="card-body text-center">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-fw fa-folder-open"></i>
                  </div>
                  <div className="mt-2">No Assessments Found</div>
                </div>
              </div>
            )}
          {rtr && (
            <Rtr
              jobData={jobData}
              fullText={candidateData1}
              candidateData={candidateData1}
            />
          )}
          {history && (
            <CandidateProfileHistory candidateData={candidateData1} />
          )}

          {offers && (
            <div>
              {candidateData1.userInfo?.offersInPipeline ||
              candidateData1.userInfo?.noticePeriod?.trim() ? (
                <div className="card">
                  <div className="card-body">
                    Offers In Pipeline -{" "}
                    {candidateData1.userInfo?.offersInPipeline ||
                      "Not Data Found"}
                    <br />
                    Notice Period -{" "}
                    {candidateData1.userInfo?.noticePeriod?.trim() ||
                      "Not Data Found"}
                  </div>
                </div>
              ) : (
                <div className="text-center mt-4">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-fw fa-folder-open"></i>
                  </div>
                  <div className="mt-2">No Record Found</div>
                </div>
              )}
            </div>
          )}
          {score && (
            <AutoMatchScoreCard
              candidateData={candidateData1}
              jobData={jobData}
            />
          )}
          {matchingScore && (
            <AutoMatchScoreCard
              candidateData={candidateData1}
              jobData={jobData}
              parent="submitted"
            />
          )}
        </div>

        {openDropDownDiv && (
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              display: "revert",
              right: "290px",
              top: "110px",
              // left: "155px",
            }}
          >
            {(getUserRole() == "HiringManager" ||
              role == "High5Coordinator") && (
              <button
                type="button"
                onClick={() => openAnotherRequest(candidateData1)}
                className="dropdown-item"
              >
                Request Interview
              </button>
            )}
            <button
              type="button"
              onClick={() => onDisqualifyClick(candidateData1)}
              className="dropdown-item"
            >
              Disqualify
            </button>
          </div>
        )}

        {submitOpenDropDownDiv && (
          <OutsideClickHandler
            onOutsideClick={() => setSubmitOpenDropDownDiv(false)}
          >
            <div
              className="dropdown-menu dropdown-menu-right"
              style={{
                display: "revert",
                right: "450px",
                top: "110px",
                left: "145px",
              }}
            >
              <button
                type="button"
                onClick={() => onDisqualifyClick(candidateData1)}
                className="dropdown-item"
              >
                Disqualify
              </button>
            </div>
          </OutsideClickHandler>
        )}

        {offerOpenDropDownDiv && (
          <div
            className="dropdown-menu dropdown-menu-right"
            style={{
              display: "revert",
              right: "300px",
              top: "110px",
              // left: "135px",
            }}
          >
            <button
              type="button"
              onClick={() => {
                offerAcceptedOpen(candidateData1);
              }}
              className="dropdown-item"
            >
              Offer Accepted
            </button>
            <button
              type="button"
              onClick={() => {
                onDisqualifyClick(candidateData1);
              }}
              className="dropdown-item"
            >
              Offer Declined
            </button>
          </div>
        )}

        <Modal
          size="lg"
          show={makeAnOffer}
          aria-labelledby="example-modal-sizes-title-lg"
          dialogClassName="interviewPopUpModal"
        >
          <Modal.Body>
            {successFormOpen ? (
              <FormSubmitSuccess
                successData={successData}
                handleSuccess={handleModel}
              />
            ) : (
              <MakeAnOfferPopUp
                handleSuccess={handleModel}
                userTenant={userTenant}
                candidateData={candidateData1}
                jobData={jobData}
                talentdata={talentdata}
                selectCandidate={candidateData1}
                setSuccessFormOpen={setSuccessFormOpen}
                refreshPage={props.refreshPage}
                isFrom={props.isFrom}
              />
            )}
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={shortList}
          aria-labelledby="example-modal-sizes-title-lg"
          dialogClassName="interviewPopUpModal"
        >
          <div class="bl-modal-header">
            <h6 class="bl-modal-title">{"Shortlist Candidate"}</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleModel}
              class="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            <ShortListPopup
              handleSuccess={handleModel}
              handleClose={handleClose}
              candidateData={candidateData1}
              jobData={jobData}
              talentdata={talentdata}
              userTenant={userTenant}
              selectCandidate={selectCandidate}
              parent={"Submitted"}
              refreshPage={props.refreshPage}
              isFrom={"candidateProfile"}
              setActiveTab={props.setActiveTab}
            />
          </Modal.Body>
        </Modal>

        {/* If candidate has not completed the vetting requirements */}
        <Modal
          size="lg"
          show={isVettingComplete}
          aria-labelledby="example-modal-sizes-title-lg"
          dialogClassName="interviewPopUpModal"
        >
          <Modal.Body>
            <VettingResendMail
              handleSuccess={handleModel}
              userTenant={userTenant}
              candidateData={candidateData1}
              jobData={jobData}
              talentdata={talentdata}
              selectCandidate={selectCandidate}
              setSuccessFormOpen={setSuccessFormOpen}
              refreshPage={props.refreshPage}
              setIsVettingComplete={setIsVettingComplete}
              candidateVettingDetails={candidateVettingDetails}
            />
            {/* <h1>sdf</h1> */}
            {/* <>
              <div className="row">
                <div className="col-12 text-center">
                  <strong>
                    Vetting Requirements of the job is not completed yet so
                    can't shortlist the candidate.
                  </strong>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-12 text-center">
                  <strong>Send a reminder mail to candidate</strong>
                  <br />
                  <button className="btn btn-sm  mr-2">Send mail</button>
                  <button className="btn btn-sm btn-secondary">Cancel</button>
                </div>
              </div>
            </> */}
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={DisqualifyCandidate}
          aria-labelledby="example-modal-sizes-title-lg"
          dialogClassName="interviewPopUpModal"
        >
          <Modal.Body>
            {successFormOpen ? (
              <FormSubmitSuccess
                successData={successData}
                handleSuccess={handleModel}
              />
            ) : (
              <DisqualifyCandidatePopup
                handleSuccess={handleModel}
                jobData={jobData}
                talentdata={talentdata}
                candidateData={candidateData1}
                selectCandidate={selectCandidate}
                setSuccessFormOpen={setSuccessFormOpen}
                refreshPage={props.refreshPage}
              />
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={offerAcepted}
          handleSuccess={handleModel}
          aria-labelledby="example-modal-sizes-title-lg"
          size="lg"
        >
          <Modal.Body>
            {successFormOpen ? (
              <FormSubmitSuccess
                successData={successData}
                handleSuccess={handleModel}
              />
            ) : (
              <OfferAcceptPopUp
                handleSuccess={handleModel}
                // userTenant={userTenant}
                jobData={jobData}
                talentdata={talentdata}
                candidateData={candidateData1}
                selectCandidate={selectCandidate}
                setSuccessFormOpen={setSuccessFormOpen}
                refreshPage={props.refreshPage}
              />
            )}
          </Modal.Body>
        </Modal>

        <Modal
          show={offered}
          handlesuccess={handleModel}
          aria-labelledby="example-modal-sizes-title-lg"
          size="lg"
        >
          <Modal.Body>
            <OfferPopUp
              handlesuccess={handleModel}
              jobData={jobData}
              candidateData={talentdata}
              selectCandidate={selectCandidate}
            />
          </Modal.Body>
        </Modal>

        <Modal show={sourceTalent} size="lg">
          <Modal.Header>
            <h6 class="">Source Talent</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setSourceTalent(false)}
              class="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center mb-5">
              <Avatar
                size="55"
                name={props.candidateProfileData.firstName}
                round="80px"
              />
            </div>
            <div className="mx-3 my-4">
              <SubmitTalentModal
                candidate={candidateData1}
                job={jobData}
                closeModal={() => setSourceTalent(false)}
                component="Automatch"
              />
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          size="lg"
          show={interviewSchedular}
          aria-labelledby="example-modal-sizes-title-lg"
          dialogClassName="interviewPopUpModal"
        >
          <div className="bl-modal-header">
            <h6 className="bl-modal-title">{"Schedule Interview"}</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleModel}
              className="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            <ShortListPopUp
              handleSuccess={handleModel}
              jobData={jobData}
              talentdata={props?.talentdata}
              userTenant={userTenant}
              selectCandidate={candidateData1}
              parent={"Shortlist"}
              refreshPage={props.refreshPage}
              vettingStatus={vettingStatus}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default CandidateProfile;
