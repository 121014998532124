import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import "react-tagsinput/react-tagsinput.css";
import { toast } from "react-toastify";
import Autosuggest from "react-autosuggest";
import _ from "lodash";
import {
  currency_list,
  dropDownData,
} from "../../../../directsourcing/Edit Talent DS/dropDownData";

const customStyles = {
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#dcedff",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

function Preferences(props) {
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [form, setForm] = useState(null);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  const [secondarySkillsInput, setSecondarySkillSInput] = useState("");
  const [positionTypes, setPositionTypes] = useState([]);
  const [skillSet, setSkillSet] = useState([]);

  // length 164

  let positionTypeOption = dropDownData.PositionType?.map((item) => {
    const obj = { value: item.SourceName, label: item.SourceName };
    return obj;
  });
  positionTypeOption = _.sortBy(positionTypeOption, ["value"]);

  let skillSetOption = dropDownData.skillSet?.map((item) => {
    const obj = { value: item.SourceName, label: item.SourceName };
    return obj;
  });
  skillSetOption = _.sortBy(skillSetOption, ["value"]);

  const handleChange = (e) => {
    if (e.target.id === "preferredSalary" || e.target.id === "minContractRate")
      // {
      //   const { value, id } = e.target;
      // if (id === "minContractRate") {
      //   const minContractRate = parseFloat(value);
      //   const preferredSalary = parseFloat(
      //     props.profileInfo.preferredSalary || 0
      //   );
      //   if (minContractRate > preferredSalary) {
      //     toast.error("Minimum contract rate cannot exceed preferred salary");
      //     return;
      //   }
      // }
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.value.slice(0, 7),
      });
    else
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.value,
      });
  };

  //File upload block
  const setFileForSending = (file) => {
    const { name } = file;
    const type = name.split(".")[1];
    if (type === "pdf" || type === "docx" || type === "doc") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        props.setProfileInfo({
          ...props.profileInfo,
          fileName: name,
          base64: event.target.result,
        });
        toast.success("File uploaded successfully");
      };
    } else {
      toast.error("Please Upload PDF or WORD Document");
    }
  };

  return (
    <>
      <div>
        <div class="card-body" style={{ padding: "10px" }}>
          <div class="row mb-2" style={{ marginTop: "-13px" }}>
            <div class="col-lg-12">
              <label>Let us know your expected salary* </label>
            </div>
          </div>
          {/* For talent community */}
          {props.message === "JoinCommunity" ? (
            <div class="row mb-2">
              <div class="col-lg-4">
                <div class="form-floating">
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    placeholder="Preferred Salary"
                    id="preferredSalary"
                    style={{
                      border:
                        props.isFormValid &&
                        props.profileInfoValidation.preferredSalary &&
                        props.profileInfo.preferredSalary == "" &&
                        "2px solid #ff0000",
                    }}
                    value={props.profileInfo.preferredSalary}
                    //onChange={handleChange}
                    onChange={(e) => {
                      if (e.target.value === "0") {
                        toast.error("Cannot Be Zero");
                      } else if (+e.target.value < 0) {
                        toast.error("Negative numbers are not allowed");
                      } else {
                        handleChange(e);
                      }
                    }}
                  />
                  <label>Expected Annual Salary*</label>
                </div>
                {props.isFormValid &&
                  props.profileInfoValidation.preferredSalary &&
                  props.profileInfo.preferredSalary == "" && (
                    <small className="validation">
                      {props.profileInfoValidation.preferredSalary}
                    </small>
                  )}
              </div>

              <div class="col-lg-4">
                <div class="form-floating">
                  <input
                    type="number"
                    min="1"
                    class="form-control"
                    placeholder="Minimum Contract Rate"
                    id="minContractRate"
                    value={props.profileInfo.minContractRate}
                    //onChange={handleChange}
                    onChange={(e) => {
                      if (e.target.value === "0") {
                        toast.error("Cannot Be Zero");
                      } else if (+e.target.value < 0) {
                        toast.error("Negative numbers are not allowed");
                      } else {
                        handleChange(e);
                      }
                    }}
                  />
                  <label>Expected Hourly Rate</label>
                </div>
                <small className="validation">
                  {props.profileInfoValidation.minContractRate}
                </small>
              </div>

              {/* Currency type */}
              <div class="col-lg-4">
                <div class="form-floating">
                  <select
                    class="form-select"
                    disabled={!props.profileInfo.preferredSalary > 0}
                    id="preferredSalaryCurrency"
                    style={{
                      border:
                        props.profileInfoValidation.preferredSalaryCurrency &&
                        "2px solid #ff0000",
                    }}
                    value={
                      props.profileInfo.preferredSalaryCurrency &&
                      props.profileInfo.preferredSalaryCurrency.split("/")[0]
                    }
                    onChange={handleChange}
                  >
                    <option value="" selected disabled>
                      Select Type
                    </option>
                    {currency_list.map((item) => (
                      <option value={item.code}>{item.code}</option>
                    ))}
                  </select>
                  <label>
                    Currency Type
                    {props.profileInfo.preferredSalary > 0 && "*"}
                  </label>
                </div>
                <small className="validation">
                  {props.profileInfoValidation.preferredSalaryCurrency}
                </small>
              </div>
            </div>
          ) : (
            
            <div class="row mb-2">
              {(props?.job?.minMaxPaymentType=== "Annual") ? (
                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="number"
                      min="1"
                      class="form-control"
                      placeholder="Preferred Salary"
                      id="preferredSalary"
                      style={{
                        border:
                          props.isFormValid &&
                          props.profileInfoValidation.preferredSalary &&
                          props.profileInfo.preferredSalary == "" &&
                          "2px solid #ff0000",
                      }}
                      value={props.profileInfo.preferredSalary}
                      //onChange={handleChange}
                      onChange={(e) => {
                        if (e.target.value === "0") {
                          toast.error("Cannot Be Zero");
                        } else if (+e.target.value < 0) {
                          toast.error("Negative numbers are not allowed");
                        } else {
                          handleChange(e);
                        }
                      }}
                    />
                    <label>Expected Annual Salary*</label>
                  </div>
                  {props.isFormValid &&
                    props.profileInfoValidation.preferredSalary &&
                    props.profileInfo.preferredSalary == "" && (
                      <small className="validation">
                        {props.profileInfoValidation.preferredSalary}
                      </small>
                    )}
                </div>
              ) : (
                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="number"
                      min="1"
                      class="form-control"
                      placeholder="Minimum Contract Rate"
                      id="minContractRate"
                      value={props.profileInfo.minContractRate}
                      //onChange={handleChange}
                      onChange={(e) => {
                        if (e.target.value === "0") {
                          toast.error("Cannot Be Zero");
                        } else if (+e.target.value < 0) {
                          toast.error("Negative numbers are not allowed");
                        } else {
                          handleChange(e);
                        }
                      }}
                    />
                    <label>Expected Hourly Rate*</label>
                  </div>
                  <small className="validation">
                    {props.profileInfoValidation.minContractRate}
                  </small>
                </div>
              )}
              {/* Currency type */}
              <div class="col-lg-6">
                <div class="form-floating">
                  <select
                    class="form-select"
                    disabled={!props.profileInfo.preferredSalary > 0}
                    id="preferredSalaryCurrency"
                    style={{
                      border:
                        props.profileInfoValidation.preferredSalaryCurrency &&
                        "2px solid #ff0000",
                    }}
                    value={
                      props.profileInfo.preferredSalaryCurrency &&
                      props.profileInfo.preferredSalaryCurrency.split("/")[0]
                    }
                    onChange={handleChange}
                  >
                    <option value="" selected disabled>
                      Select Type
                    </option>
                    {currency_list.map((item) => (
                      <option value={item.code}>{item.code}</option>
                    ))}
                  </select>
                  <label>
                    Currency Type
                    {props.profileInfo.preferredSalary > 0 && "*"}
                  </label>
                </div>
                <small className="validation">
                  {props.profileInfoValidation.preferredSalaryCurrency}
                </small>
              </div>
              {/*  */}
            </div>
          )}
          {/* Ask for location */}
        </div>
      </div>
    </>
  );
}

export default Preferences;
