import React, { useEffect, useState } from "react";
import Select from "react-select";
import { get,post } from "../../../../../service/ApiService";
import Loader from "../../../../Loader/Loader";

const Pools =({groupData,setGroupData}) =>{

  const [pools, setPools] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPoolsFromDB();
  }, []);

  const getPoolsFromDB = async () => {
    setLoading(true);
    await post("/talent/getpoollist").then((res) => {
      let data = res?.data?.map((item) => ({
        value: item.id_pool,
        label: item.poolname,
      }));
      setPools(data);
      setLoading(false);
    });
  };
  
    return (
        <>
        {loading && <Loader/>}
        <div className="card card-flat bg-gray4 m-3">
        {pools.length > 0 && (
          <div className="card-body py-0">
            <div className="row mb-2">
              <label>Include Community</label>
              <Select
                isMulti
                options={pools}
                name="poollist"
                onChange={(e) => {
                  setGroupData({
                    ...groupData,
                    poollist: e.map((g) => g.value),
                  });
                }}
                isSearchable={true}
                value={pools?.filter((item) =>
                  groupData?.poollist.includes(item.value)
                )}
              ></Select>
            </div>
          </div>
        )}
      </div>
        </>
    )
}

export default Pools