import React, { useState, useEffect } from "react";
import { languages } from "../../../../../constants/Constants";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  healthCareQA,
  screeningArray,
} from "../../../../../constants/Constants";

function ScreeningQuestions({
  selectedLanguages,
  setSelectedLanguages,
  screeningQuestions,
  setScreeningQuestions,
  skillSetOption,
  addJobVal,
  addJobData,
}) {
  const [screeningList, setScreeningList] = useState([]);
  const [customQuestion, setCustomQuestion] = useState("");
  const [customQuestionValidate, setCustomQuestionValidate] = useState("");
  const [dropDownTextValue, setDropDowntextValue] = useState({});

  useEffect(() => {
    if (addJobData?.skillSet.filter((e) => e == "Healthcare").length > 0) {
      let arr = [...healthCareQA, ...screeningArray];
      setScreeningList(arr.filter((item) => item.name != "License State"));
    } else {
      setScreeningList(
        screeningArray.filter((item) => item.name != "License State")
      );
    }
  }, [addJobData]);

  const handleChange = (value, name, indexValue) => {
    let temp = [...screeningQuestions];
    let index;
    if (name === "Custom Question" && indexValue) {
      index = temp.findIndex((i) => i.index === indexValue);
    } else {
      index = temp.findIndex((i) => i.name === name);
    }
    if (value == "Drop-down" || value == "Drop-down(Multiselect)") {
      temp[index] = { ...temp[index], input: value, dropdownOptions: [] };
    } else {
      temp[index] = { ...temp[index], input: value };
      if (temp[index].hasOwnProperty("dropdownOptions")) {
        const { dropdownOptions, ...rest } = temp[index];
        temp[index] = rest;
      }
    }
    setScreeningQuestions(temp);
  };

  const handleChangeMandatory = (checked, name, indexValue) => {
    let temp = [...screeningQuestions];
    let index;
    if (name === "Custom Question" && indexValue) {
      index = temp.findIndex((i) => i.index === indexValue);
    } else {
      index = temp.findIndex((i) => i.name === name);
    }
    temp[index] = { ...temp[index], isMandatory: checked };
    setScreeningQuestions(temp);
  };

  const handleAddDropdownOption = (item) => {
    if (dropDownTextValue[item.index]) {
      const temp = [...screeningQuestions];
      const index = temp.findIndex((i) => i.index === item.index);
      temp[index].dropdownOptions.push(dropDownTextValue[item.index]);
      setDropDowntextValue({ ...dropDownTextValue, [item.index]: "" });
      setScreeningQuestions(temp);
    }
  };

  const handleRemoveDropdownOption = (item, optionIndex) => {
    const temp = [...screeningQuestions];
    const index = temp.findIndex((i) => i.index === item.index);
    temp[index].dropdownOptions.splice(optionIndex, 1);
    setScreeningQuestions(temp);
  };

  const handleAdd = (item) => {
    if (customQuestion) {
      let temp = screeningQuestions?.filter(
        (i) =>
          i.name !== "Custom Question" ||
          (i.name === "Custom Question" && i.question !== "")
      );
      temp.push({
        ...screeningQuestions?.filter(
          (i) => i.name === "Custom Question" && i.question === ""
        )[0],
        question: customQuestion,
      });
      setScreeningQuestions(temp);
      setCustomQuestion("");
    } else {
      setCustomQuestionValidate("*Required Field");
    }
  };

  console.log("screeningList", screeningList);

  return (
    <div className="card card-flat bg-gray4 m-3">
      <div className="card-body">
        <div className="px-3">
          <div className="d-flex align-items-center justify-content-between">
            <div className="mb-1">
              <h6>Add Screening Questions</h6>
            </div>
            <hr />
          </div>
          {screeningQuestions?.length > 0 &&
            screeningQuestions
              ?.filter((i) => i.name !== "Custom Question")
              .map((item) => (
                <div className="card card-flat m-2">
                  <div className="card-body">
                    <div
                      className="d-flex p-3 mb-2"
                      style={{ backgroundColor: "#dcdcdc" }}
                    >
                      <div className="flex-item">
                        <medium>{item.question}</medium>
                      </div>
                      <button className="ml-auto btn btn-icon">
                        <i
                          className="fa fa-close"
                          onClick={() => {
                            setScreeningQuestions([
                              ...screeningQuestions?.filter(
                                (i) => i.name !== item.name
                              ),
                            ]);
                          }}
                        ></i>
                      </button>
                    </div>
                    <div className="d-flex align-items-center justify-content-center">
                      {item.name === "Industry Experience" && (
                        <div className="form-floating w-50 ml-1">
                          <select
                            className="form-select font-14"
                            name="referralCurrency"
                            onChange={(e) =>
                              handleChange(e.target.value, item.name)
                            }
                          >
                            {skillSetOption
                              .map((i) => i.value)
                              .map((item2) => (
                                <option
                                  selected={item2 === item.input}
                                  value={item2}
                                >
                                  {item2}
                                </option>
                              ))}
                          </select>
                          <label>Industry</label>
                        </div>
                      )}
                      {item.name === "Language" && (
                        <div className="col-6">
                          <label className="mb-2">Language</label>
                          <div className="form-floating font-14 Hover-Dropdown">
                            <Select
                              isMulti
                              options={languages.map((lang) => ({
                                value: lang,
                                label: lang,
                              }))}
                              value={selectedLanguages.map((i) => ({
                                value: i,
                                label: i,
                              }))}
                              name="Language"
                              onChange={(e) => {
                                setSelectedLanguages(
                                  Array.isArray(e) ? e.map((x) => x.value) : []
                                );
                              }}
                              isSearchable={true}
                            ></Select>
                            {addJobVal.screeningLanguage &&
                              selectedLanguages.length == 0 && (
                                <small className="validation ml-2">
                                  *{addJobVal.screeningLanguage}
                                </small>
                              )}
                          </div>
                        </div>
                      )}
                      {item.name === "Work Experience" && (
                        <div className="form-floating ml-1">
                          <span>Response Type: Numeric</span>
                        </div>
                      )}
                      {(item.name === "Visa Requirement" ||
                        item.name === "Preferred Location" ||
                        item.name === "Certification" ||
                        item.name === "Shift Preference" ||
                        item.name === "Work Preference" ||
                        item.name === "Nursing License" ||
                        item.name === "Willing to relocate" ||
                        item.name === "Nursing Specialty") && (
                        <div className="form-floating ml-1">
                          <span>Response Type: Multiselect</span>
                        </div>
                      )}
                      {item.name === "Education" && (
                        <div className="form-floating ml-1">
                          <span>Response Type: Selective</span>
                        </div>
                      )}
                      {item.name === "License State" && (
                        <div className="form-floating ml-1">
                          <span>Response Type: Text</span>
                        </div>
                      )}
                      {item.input === "Yes/No" && (
                        <div className="form-floating ml-1">
                          <span>Response Type: Yes/No</span>
                        </div>
                      )}
                      <div className="ml-auto">
                        <div className="form-check form-switch d-flex align-items-center">
                          <label className="form-check-label pt-0">
                            Must answer this question
                          </label>
                          <input
                            className="form-check-input ml-2"
                            type="checkbox"
                            checked={item.isMandatory}
                            onChange={(e) =>
                              handleChangeMandatory(e.target.checked, item.name)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          {screeningQuestions
            ?.map((i) => i.name)
            .includes("Custom Question") && (
            <div className="mt-3">
              <div className="m-1">Custom Questions: </div>
              {screeningQuestions
                ?.filter((i) => i.name === "Custom Question")
                .map((item) => (
                  <div className="card card-flat m-2">
                    <div className={"card-body"}>
                      {item.question ? (
                        <div
                          className="d-flex p-3 mb-2"
                          style={{ backgroundColor: "#dcdcdc" }}
                        >
                          <div className="flex-item">
                            <medium>{item.question}</medium>
                          </div>
                          <button className="ml-auto btn btn-icon">
                            <i
                              className="fa fa-close"
                              onClick={() => {
                                setScreeningQuestions([
                                  ...screeningQuestions?.filter(
                                    (i) => i.index !== item.index
                                  ),
                                ]);
                              }}
                            ></i>
                          </button>
                        </div>
                      ) : (
                        <div className="row">
                          <div className="col-lg-8">
                            <div className="form-floating mb-2">
                              <input
                                type="text"
                                className={
                                  customQuestionValidate
                                    ? "form-control border border-danger"
                                    : "form-control"
                                }
                                placeholder="Type Question"
                                onChange={(e) => {
                                  if (customQuestionValidate) {
                                    setCustomQuestionValidate("");
                                  }
                                  setCustomQuestion(e.target.value);
                                }}
                                value={customQuestion}
                              />
                              <label>Question</label>
                              {customQuestionValidate && (
                                <small className="validation ml-2">
                                  {customQuestionValidate}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="d-flex">
                              <button
                                className="btn btn-sm"
                                onClick={() => handleAdd(item)}
                              >
                                Add
                              </button>
                              <button
                                className="btn btn-sm btn-secondary ml-1"
                                onClick={() => {
                                  setCustomQuestion("");
                                  setCustomQuestionValidate("");
                                  setScreeningQuestions(
                                    screeningQuestions?.filter(
                                      (i) =>
                                        i.name !== "Custom Question" ||
                                        (i.name === "Custom Question" &&
                                          i.question !== "")
                                    )
                                  );
                                }}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="form-floating w-50">
                          <select
                            className="form-select font-14"
                            onChange={(e) =>
                              handleChange(
                                e.target.value,
                                item.name,
                                item.index
                              )
                            }
                          >
                            {[
                              "Yes/No",
                              "Text",
                              "Numeric",
                              "Long Text",
                              "Drop-down",
                              "Drop-down(Multiselect)",
                            ].map((item2) => (
                              <option
                                selected={item2 === item.input}
                                value={item2}
                              >
                                {item2}
                              </option>
                            ))}
                          </select>
                          <label>Response Type</label>
                        </div>
                        <div className="ml-auto">
                          <div className="form-check form-switch d-flex align-items-center">
                            <label className="form-check-label pt-0">
                              Must answer this question
                            </label>
                            <input
                              className="form-check-input ml-2"
                              type="checkbox"
                              checked={item.isMandatory}
                              onChange={(e) =>
                                handleChangeMandatory(
                                  e.target.checked,
                                  item.name,
                                  item.index
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                      {(item.input === "Drop-down" ||
                        item.input === "Drop-down(Multiselect)") && (
                        <>
                          {item.dropdownOptions.map((option, optionIndex) => (
                            <div className="row ml-1 mt-3">
                              <div className="col-6">
                                <div key={optionIndex} className="mb-1">
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={option}
                                    onChange={(e) => {
                                      const temp = [...screeningQuestions];
                                      const index = temp.findIndex(
                                        (i) => i.name === item.name
                                      );
                                      temp[index].dropdownOptions[optionIndex] =
                                        e.target.value;
                                      setScreeningQuestions(temp);
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-2">
                                <i
                                  onClick={() =>
                                    handleRemoveDropdownOption(
                                      item,
                                      optionIndex
                                    )
                                  }
                                  className="fal fa-fw fa-trash-alt pointer"
                                  aria-hidden="true"
                                ></i>
                              </div>
                            </div>
                          ))}
                          <div className="row ml-1 mt-3">
                            <div className="col-6">
                              <input
                                className="form-control"
                                type="text"
                                value={dropDownTextValue[item.index]}
                                onChange={(e) =>
                                  setDropDowntextValue({
                                    ...dropDownTextValue,
                                    [item.index]: e.target.value,
                                  })
                                }
                              />
                            </div>
                            <div className="col-2">
                              <button
                                className="btn btn-sm"
                                onClick={() => handleAddDropdownOption(item)}
                              >
                                Add
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          )}
          {screeningQuestions?.length > 0 && <hr className="m-2"></hr>}

          <div>
            {screeningList.map((item) => (
              <button
                className="btn btn-sm m-1"
                onClick={() => {
                  if (
                    item.name === "Custom Question" &&
                    screeningQuestions?.filter(
                      (i) => i.name === "Custom Question" && i.question === ""
                    ).length > 0
                  ) {
                    toast.error("Please complete the Custom Question Info");
                  } else {
                    let temp = [...screeningQuestions];
                    let item2 = {};
                    if (item.name === "Custom Question") {
                      let maxIndex =
                        screeningQuestions?.filter(
                          (i) => i.name === "Custom Question"
                        ).length + 1;
                      item2 = { ...item, index: maxIndex };
                      temp.push(item2);
                    } else {
                      item2 = { ...item };
                      temp.push(item2);
                    }
                    setScreeningQuestions(temp);
                  }
                }}
                style={{ background: "none", color: "#333" }}
                disabled={screeningQuestions
                  ?.filter((i) => i.name && i.name !== "Custom Question")
                  .map((val) => val.name)
                  .includes(item.name)}
              >
                <i className="fas fa-fw mr-2 fa-plus" aria-hidden="true"></i>
                {item.name}
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ScreeningQuestions;
