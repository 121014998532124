import React from "react";
import Dropzone from "react-dropzone";

const FileUpload = (props) => {
  const { uploadData, setFileUpload } = props;

  const onDragDropChangeHandler = (e) => {
    e.length === 1 && setFileUpload(e[0]);
  };

  return (
    <>
      <div className="col-sm-12">
        <Dropzone onDrop={onDragDropChangeHandler}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps()}>
                <div class="talentuploadmaindiv">
                  <div class="talentuploadicon">
                    <label>
                      <i class="fas fa-cloud-upload-alt"></i>
                    </label>
                    <input type="file" {...getInputProps()} />
                  </div>
                  {uploadData?.fileName ? (
                    <div class="talentuploadtext">{uploadData?.fileName}</div>
                  ) : (
                    <div class="talentuploadtext">
                      Click or Drag and Drop to Upload
                    </div>
                  )}
                </div>
              </div>
            </section>
          )}
        </Dropzone>
      </div>
    </>
  );
};
export default FileUpload;
