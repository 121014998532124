import {
  FETCH_CAREER_JOB_LIST_BEGIN,
  FETCH_CAREER_JOB_LIST_SUCCESS,
  FETCH_CAREER_JOB_LIST_FAILURE,
  FETCH_FILTER_DATA_BEGIN,
  FETCH_FILTER_DATA_SUCCESS,
  FETCH_FILTER_DATA_FAILURE,
} from "../../actions/jobList/CareerJobList";

const initialState = {
  loading: true,
  jobList: null,
  filterDataLoading: true,
  filterData: null,
};

const getCareerJobListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_CAREER_JOB_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        jobList: null,
      };
    case FETCH_CAREER_JOB_LIST_SUCCESS:
      return {
        ...state,
        jobList: payload,
        loading: false,
      };
    case FETCH_CAREER_JOB_LIST_FAILURE:
      return {
        ...state,
        jobList: payload,
        loading: false,
      };

    case FETCH_FILTER_DATA_BEGIN:
      return {
        ...state,
        filterDataLoading: true,
        filterData: null,
      };
    case FETCH_FILTER_DATA_SUCCESS:
      return {
        ...state,
        filterData: payload,
        filterDataLoading: false,
      };
    case FETCH_FILTER_DATA_FAILURE:
      return {
        ...state,
        filterData: payload,
        filterDataLoading: false,
      };
    default:
      return state;
  }
};

export default getCareerJobListReducer;
