import React, { useState, useEffect } from "react";
import { post } from "../../../../service/ApiService";
import { getUserRole, getCandidateName } from "../../../../service/AuthService";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";
import moment from "moment";

function OfferPopUP(props) {
  console.log(props);
  const candidate = props.candidateData;
  const jobData = props.jobData;
  //const [candidate, setcandidate] = useState({})
  const [offerDate, setOfferDate] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const role = getUserRole();
  const userName = getCandidateName();

  useEffect(() => {
    setCandidateData(props.selectCandidate);
    //setcandidate(props.candidateData)
  }, [props]);

  const close = () => {
    props.handlesuccess(true);
  };

  const handleChange = (e) => {
    let val = e.target.value;
    console.log("val", val);
    setOfferDate(val);
    //submitOfferDate(val)
    setSubmitClicked(true);
  };

  let submittedToClientBy = getCandidateName();
  let candidateId = "";

  if (candidate.length !== 0) {
    candidate.map((item) => {
      // submittedToClientBy = item.recruiterName || item.sourcedBy || item.submittedToClientBy
      candidateId = item.candidateId;
    });
  }

  const submitOfferDate = async () => {
    const payload = {
      jobId: jobData.jobId,
      candidateId: candidateId,
      offeredRole: role,
      offeredBy: submittedToClientBy,
      OfferedDate: offerDate,
    };
    console.log(payload, "pp");
    try {
      setLoading(true);
      const res = await post("/jobcandidate/updateofferpendingstatus", payload);
      if (res.status === 200) {
        toast.success("Job offered date saved successfully");
        setLoading(true);
        close();
      }
    } catch (error) {
      toast.error("An error occurred");
      setLoading(true);
    }
    //setSubmitClicked(true)
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        {/* {candidateData.statusName !== "Pending" ? ( */}
        <div className="bl-modal-body  text-center">
          <div style={{ padding: "1px 2px 20px 0px" }}></div>
          <div className="bl-modal-body pt-0 text-center">
            <p className="text-center">
              {/* <strong>Please choose the offered date for the candidate.</strong> */}
              Offered Date for the candidate
              <br />
              <br />{" "}
              <strong>
                {moment(new Date(props.selectCandidate.OfferedDate)).format(
                  "MM/DD/YYYY"
                )}
              </strong>
            </p>
            {/* <div className="col-lg-4" style={{ margin: "auto" }}>
              <div className="text-center form-floating form-date">
                <label>Offered Date</label>

                <input
                  type="date"
                  placeholder="Start Date"
                  className="form-control date"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div> */}
          </div>
          <div className="text-center ">
            <button
              type="button"
              className="btn btn-secondary "
              data-dismiss="modal"
              onClick={close}
              disabled={submitClicked}
            >
              Close
            </button>
            {/* <button
              type="button"
              className="btn btn-primary mr-3"
              onClick={submitOfferDate}
              disabled={!offerDate}
            >
              Offered Date
            </button> */}
          </div>
        </div>
        {/* ) : (
                    <div className="bl-modal-body  text-center">
                        <div style={{ padding: '1px 2px 35px 0px' }}></div>
                        <div className="bl-modal-body pt-0 text-center">
                            <p className="text-center">
                                <strong>{candidateData.firstName + " " + candidateData.lastName}</strong> has been offered by <strong>{candidateData.offeredBy}</strong> on date <strong>{moment(new Date(candidateData.OfferedDate)).format("MM/DD/YYYY")}</strong>
                           
                            </p>
                            
                        </div>
                    </div>
                )} */}
      </div>
    </>
  );
}
export default OfferPopUP;
