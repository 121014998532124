import React, { useState } from "react";
import { getInitial, dataDiff } from "../../function/CommonFunction";
import vettedIcon from "../../../images/high5-mark-circle-dark.svg";
import Avatar from "react-avatar";
import moment from "moment";
import CandidateProfile from "../../common/Jobs/ViewTalentDetails/CandidateProfile";
import OutsideClickHandler from "react-outside-click-handler";
import { countryObj } from "../../../constants/Constants";

function SourceCandidateListView(props) {
  const [showProfile, setShowProfile] = useState(false);
  const [candidateData, setCandidateData] = useState({});

  return (
    <>
      <div class="row d-block-force d-none-1200">
        <div class="col">
          {props.arrayList.length !== 0 ? (
            props.arrayList.map((candidate, i) => (
              <div class="card d-flex flex-row align-items-stretch justify-content-between mb-1 p-relative">
                {/* <div>
                                        <div class="form-check-square p-absolute-top-left">
                                            <input class="form-check-input" type="checkbox" checked={candidate.isSelect} onChange={(e) => props.handleChange(e, i)} />
                                        </div>
                                    </div> */}
                {(candidate.MCQ === true ||
                  candidate.onewayvetting === true ||
                  candidate.twowayvetting === true ||
                  candidate.codesignalvetting === true) && (
                  <div class="tag tag-coral tag-vertical-status" />
                )}
                <div class="card-body d-flex align-items-center ">
                  <div class="ml-4 mr-2">
                    {candidate.ImageURL ? (
                      <span class="avatar avatar-bordered">
                        <img alt="" src={candidate.ImageURL} />
                      </span>
                    ) : (
                      <span>
                        <Avatar
                          size="55"
                          name={
                            candidate.fullText.firstName +
                            " " +
                            candidate.fullText.lastName
                          }
                          round="80px"
                        />
                        {/* {getInitial(`${candidate.fullText.firstName} ${candidate.fullText.lastName}`)} */}
                      </span>
                    )}
                  </div>
                  <div class="w-100 ml-2">
                    <div class="d-flex align-items-center mb-1">
                      <h6 class="mb-0 me-2 font-primary">
                        {candidate.fullText.firstName}{" "}
                        {candidate.fullText.lastName}
                      </h6>
                      {(candidate.MCQ === true ||
                        candidate.onewayvetting === true ||
                        candidate.twowayvetting === true ||
                        candidate.codesignalvetting === true) && (
                        <span class="tag tag-coral d-inline-flex">
                          <img width="16px" src={vettedIcon} alt="" />
                          <span class="mx-2">Vetted</span>
                        </span>
                      )}
                    </div>
                    <div class="font-regular font-primary font-gray1 text-truncate">
                      {candidate.designation && (
                        <span>{candidate.designation}</span>
                      )}
                      {candidate.designation &&
                        (candidate.fullText.cityName !== "" ||
                          candidate.fullText.stateName !== "") &&
                        " / "}
                      {candidate?.addressCity &&
                      candidate?.addressState &&
                      candidate?.country ? (
                        <span>
                          {candidate.addressCity},{candidate.addressState},
                          {candidate.country}
                        </span>
                      ) : (
                        <span>{""}</span>
                      )}
                    </div>
                    {candidate.updatedDate !== "" &&
                      new Date() > new Date(candidate.updatedDate) && (
                        <div class="font-regular mt-1 font-primary">
                          <small>
                            <i class="far fa-clock mr-1"></i>Last Updated:&nbsp;
                            <span>
                              {moment(candidate.updatedDate).format(
                                "MM/DD/YYYY"
                              )}
                            </span>
                            &nbsp;
                            {/* <span>{dataDiff(candidate.updatedDate, new Date()) > 1 ? 'Days' : 'Day'}</span> &nbsp;ago */}
                          </small>
                        </div>
                      )}
                  </div>
                </div>
                <div class="card-body font-regular d-flex align-items-start justify-content-end">
                  <div class="pr-3">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      <div className="tag tag-green1 mb-2 w-100">
                        <strong className="my-0 mx-auto">
                          {candidate.score + "%"}
                        </strong>
                      </div>
                      <div>
                        <button
                          type="text"
                          onClick={() => {
                            setCandidateData({
                              ...candidate,
                              candidateFname: candidate.fullText.firstName,
                              candidateLname: candidate.fullText.lastName,
                              statusName: "Automatched",
                            });
                            setShowProfile(true);
                          }}
                          class="btn btn-sm btn-secondary mb-1"
                        >
                          View
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div v-if="candidates.length == 0">
              <div class="text-center p-3">
                <div class="avatar avatar-lg">
                  <i class="fad fa-users-slash"></i>
                </div>
                <div class="mt-2">No Candidates Found</div>
              </div>
            </div>
          )}
        </div>
      </div>

      {showProfile && (
        <div className="view-panel show">
          <CandidateProfile
            handleSuccess={() => setShowProfile(false)}
            candidateProfileData={candidateData}
            jobData={props.jobData}
            component={"SourceTalent"}
            setShowProfile={setShowProfile}
          />
        </div>
      )}
    </>
  );
}

export default SourceCandidateListView;
