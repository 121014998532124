import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { get, post } from "../../../service/ApiService";
import { commonFunction } from "./CommonFunction";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";

const JobPerformance = (props) => {
  const { setLoading } = props;
  const [jobData, setJobData] = useState([]);
  const [jobStatus, setJobStatus] = useState({});
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 27))
  );
  const [toDate, setToDate] = useState(new Date());

  const getJobData = async () => {
    setLoading(true);
    try {
      let params = {
        toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
        fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
      };
      let res = await post("/reports/job", params);
      if (res.status === 200) {
        setLoading(false);
        let array = [];
        let temp = commonFunction(res.data);
        for (const i of temp) {
          const finalObj = {
            deactivated: 0,
            active: 0,
            expired: 0,
            opened: 0,
          };
          i.value.map((item) => {
            if (item.statusname == "Open") {
              finalObj.opened = finalObj.opened + 1;
            }
            if (item.statusname == "Active") {
              finalObj.active = finalObj.active + 1;
            }
            if (item.statusname == "Expired") {
              finalObj.expired = finalObj.expired + 1;
            }
            if (item.statusname == "Deactivated") {
              finalObj.deactivated = finalObj.deactivated + 1;
            }
          });
          finalObj.date = i.date;
          finalObj.key = i.key;
          array = [...array, JSON.stringify(finalObj)];
        }
        let arrayList = array.map((e) => JSON.parse(e));
        let arr = arrayList.map((e) => ({
          ...e,
          total: e.deactivated + e.active + e.expired + e.opened,
        }));
        setJobData(
          arr.sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
        );
        if (arr.length > 1) {
          let data = [];
          if (arr.length == 2) {
            data = [...arr];
          } else if (arr.length == 3) {
            data = arr
              .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
              .slice(1, 3);
          } else {
            data = arr
              .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
              .slice(2, 4);
          }

          setJobStatus((prev) => ({
            ...prev,
            deactivated:
              data[0].deactivated == 0
                ? data[1].deactivated > 0
                  ? 100
                  : 0
                : Math.round(
                    ((data[1].deactivated - data[0].deactivated) * 100) /
                      data[0].deactivated
                  ),
            active:
              data[0].active == 0
                ? data[1].active > 0
                  ? 100
                  : 0
                : Math.round(
                    ((data[1].active - data[0].active) * 100) / data[0].active
                  ),
            expired:
              data[0].expired == 0
                ? data[1].expired > 0
                  ? 100
                  : 0
                : Math.round(
                    ((data[1].expired - data[0].expired) * 100) /
                      data[0].expired
                  ),
            opened:
              data[0].opened == 0
                ? data[1].opened > 0
                  ? 100
                  : 0
                : Math.round(
                    ((data[1].opened - data[0].opened) * 100) / data[0].opened
                  ),
          }));
        } else {
          setJobStatus((prev) => ({
            ...prev,
            expired: 0,
            deactivated: 0,
            active: 0,
            opened: 0,
          }));
        }
      }
    } catch (err) {
      setLoading(false);
      toast.error("Some Error Occurred while Fetching Gig Recruiters");
    }
  };
  useEffect(() => {
    getJobData();
  }, [fromDate]);

  const handleDate = (event) => {
    var d = new Date(event);
    let toDate = new Date(event);
    d.setDate(1);
    toDate.setDate(1);
    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
      toDate.setDate(toDate.getDate() + 1);
    }
    toDate.setDate(toDate.getDate() + 27);
    setToDate(toDate);
    setFromDate(d);
  };

  return (
    <div className="card card-flat card-borderless p-4 mb-3">
      <div>
        <div
          className="d-flex align-items-center d-block-600"
          style={{ justifyContent: "space-between" }}
        >
          <div style={{ display: "flex" }}>
            <h5 className="ml-2.5">Job Report</h5>
            <span
              className="ml-2"
              style={{
                cursor: "pointer",
                padding: "0px 0px 5px 0px",
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
            >
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn-sm"
                table="job-table"
                filename="JobReport"
                sheet="tablexls"
                buttonText={
                  <span>
                    <i className="fas fa-file-download mr-2"></i>
                    Download
                  </span>
                }
              />
            </span>
          </div>
          <div
            className="p-2 bd-highlight"
            style={{ display: "flex", width: "175px" }}
          >
            <span style={{ marginTop: "14px", width: "100px" }}>
              {/* <i className="fas fa-filter"></i> */}
              Month:
            </span>
            <div>
              <DatePicker
                dateFormat="MMM yyyy"
                showMonthYearPicker
                selected={fromDate}
                onChange={(e) => handleDate(e)}
              />
              <span
                className="fa fa-search "
                style={{
                  float: "right",
                  marginRight: "9px",
                  marginTop: "-28px",
                  position: "relative",
                  zIndex: "200",
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 pt-0  mt-3">
        <table className="table table-sortable table-hoverable" id="job-table">
          <thead
            style={{
              backgroundColor: "burlywood",
              pointerEvents: "none",
            }}
          >
            <tr>
              <th className="no-hover">
                <div className="d-flex align-items-center">Title</div>
              </th>
              {jobData.map((item, i) => (
                <th className="no-hover" key={i}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      display: "flex!important",
                      justifyContent: "center",
                    }}
                  >
                    {item.date}
                  </div>
                </th>
              ))}{" "}
              {jobData.length > 0 && (
                <th className="no-hover">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      display: "flex!important",
                      justifyContent: "center",
                    }}
                  >
                    Status
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-relative">Closed</td>
              {jobData.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.deactivated}
                </td>
              ))}
              {console.log(jobStatus)}
              {jobData.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(jobStatus.deactivated) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(jobStatus.deactivated) + "%"
                  )}{" "}
                  {jobStatus.deactivated > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {jobStatus.deactivated < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Active</td>
              {jobData.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.active}
                </td>
              ))}
              {jobData.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(jobStatus.active) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(jobStatus.active) + "%"
                  )}{" "}
                  {jobStatus.active > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {jobStatus.active < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Halted</td>
              {jobData.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.expired}
                </td>
              ))}
              {jobData.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(jobStatus.expired) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(jobStatus.expired) + "%"
                  )}{" "}
                  {jobStatus.expired > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {jobStatus.expired < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Open</td>
              {jobData.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.opened}
                </td>
              ))}
              {jobData.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(jobStatus.opened) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(jobStatus.opened) + "%"
                  )}{" "}
                  {jobStatus.opened > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {jobStatus.opened < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td className="p-relative">Grand Total</td>
              {jobData.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.total}
                </td>
              ))}
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default JobPerformance;
