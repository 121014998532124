import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { withRouter } from "react-router-dom";
import Loader from "../../Loader/Loader";
import logo from "../../../assets/images/High5Logo.png";
import ResumeParsed from "../../components_v3/disposition/applyJob_v2_Parsing/newtalentform/ResumeParsed";
import high5Logo from "../../../images/high5-logo.jpg";
import { post } from "../../../service/ApiService";
import ApplyPageProfileNew from "./ApplyPageProfileNew";
import high5people from "../../../assets/images/high5people.jpg";
import { googleApiKey } from "../../../constants/Constants";

//const googleApiKey = "AIzaSyDI6_bChtt67mHuGbjUH2be_da6ohX1RhA";

const UpdateProfileInfo = ({}) => {
  const history = useHistory();

  const { recruiterid } = useParams();
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    mobilePhoneCode: "",
    location: "",
    city: "",
    state: "",
    country: "",
    experienceLevel: "",
    generalQuestion: [],
  });
  const [formIsValid, setFormIsValid] = useState(false);
  const [resumeFile, setResumeFile] = useState({ fileName: "", base64: "" });
  const [parsedData, setParsedData] = useState({});
  const [parsed, setParsed] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(true);

  const [dispositionID, setDispositionID] = useState({
    dispositionID: "",
    candidateID: "",
  });
  const candidateLogin = async (resp) => {
    if (sessionStorage.getItem("token") == null) {
      const response = await post("/candidateLogin/", {
        password: resp.data.password,
        userName: formData.email,
      }).then((res) => {
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("name", res.data.name);
        sessionStorage.setItem("userId", res.data.userId);
        sessionStorage.setItem("userName", res.data.userName);

        setTimeout(() => {
          setLoading(false);
          history.push(`/career/home`);
        }, 5000);
      });
    } else {
      history.push(`/career/home`);
    }
  };

  const getLongLatFromCity = async (city, state, country) => {
    try {
      const address = `${city}, ${state}, ${country}`;
      const encodedAddress = encodeURIComponent(address);

      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${googleApiKey}`
      );

      const data = await response.json();
      if (data.status === "OK" && data.results.length > 0) {
        const location = data.results[0].geometry.location;
        return { longitude: location.lng, latitude: location.lat };
      } else {
        throw new Error(
          "Unable to retrieve longitude and latitude for the given address"
        );
      }
    } catch (error) {
      console.error("Error fetching longitude and latitude:", error);
      throw error;
    }
  };

  const handleAgree = async () => {
    setAgree(!agree);
  };

  const validation = () => {
    setFormIsValid(true);
    if (resumeFile?.fileName === "") {
      toast.error("Please upload the resume");
      return false;
    } else if (formData.firstName === "") {
      toast.error("Please enter first name");
      return false;
    } else if (formData.lastName === "") {
      toast.error("Please enter last name");
      return false;
    } else if (formData.email === "") {
      toast.error("Please enter first name");
      return false;
    }
    // else if (formData.mobile === "") {
    //   toast.error("Please enter phone number");
    //   return false;
    // }
    else if (formData.experienceLevel === "") {
      toast.error("Please select experience");
      return false;
    } else if (formData.city === "") {
      toast.error("Please enter city name");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    const isValid = validation();

    if (isValid) {
      setFormIsValid(false);
      setLoading(true);
      try {
        // Fetch longitude and latitude
        const { longitude, latitude } = await getLongLatFromCity(
          formData.city,
          formData.state,
          formData.country
        );

        const resa = await post("/applyjob/isemailexist", {
          email: formData.email,
        }).then(async (res) => {
          let paramObj = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            email: formData.email,
            address: "",
            city: formData.city,
            state: formData.state,
            country: formData.country,
            zipcode: "",
            homePhoneCode: formData.mobilePhoneCode,
            homePhone: formData.mobile,
            facebook: "",
            twitter: "",
            linkedIn: formData.linkedIn ?? "",
            preferredSalary: "",
            preferredSalaryCurrency: "",
            minContractRate: "",
            minContractRateCurrency: "",
            description: "",
            positionTypes: JSON.stringify([]),
            base64: resumeFile.base64,
            preferredLocation: JSON.stringify([]),
            primarySkills: JSON.stringify([]),
            secondarySkills: JSON.stringify([]),
            skillSet: JSON.stringify([]),
            source: "Join Community",
            visaStatus: "",
            designation: "",
            currentEmployer: "",
            experienceMonth: "",
            experienceYear: "",
            isFresher: "",
            isRemote: "",
            fileName: resumeFile.fileName,
            experiences: JSON.stringify([]),
            educations: JSON.stringify([]),
            experienceLevel: formData.experienceLevel,
            recruiterId: recruiterid,
            tenantName: "",
            highestEducation: "",
            dispositionStatus: 6,
            longitude: longitude,
            latitude: latitude,
          };

          if (res.data.candidateStatus == "Login Talent") {
            toast.success("You already have joined our talent community");
          } else if (
            res.data.candidateStatus === "Existing Talent" &&
            res.data.source !== "Join Community"
          ) {
            // paramObj.oldFileName = props.data?.fileName;
            paramObj.candidateId =
              sessionStorage.getItem("id_user") || res?.data?.id_user;
            try {
              setLoading(true);
              const res = await post("/applyjob/updatetalent", paramObj);
              if (res.status === 200) {
                toast.success("Talent Community joined Successfully");
                candidateLogin(res);
              }
            } catch (error) {
              toast.error(error?.response?.data?.errMessage);
            }
          } else if (res.data.candidateStatus === "New Talent") {
            try {
              setLoading(true);

              const res = await post("/applyjob/addtalent", paramObj);
              if (res.status === 200) {
                toast.success("Talent Community joined Successfully");
                candidateLogin(res);
              }
            } catch (error) {
              toast.error(error?.response?.data?.errMessage);
            }
          }
        });
      } catch (error) {
        // Handle error
        console.error("Error:", error);
        // Stop loading and handle error state
        setLoading(false);
        // You can also notify the user about the error
        toast.error("Error occurred while fetching longitude and latitude");
      }
    }
  };

  const ResumeComponent = () => {
    return (
      <ResumeParsed
        resumeFile={resumeFile}
        setResumeFile={setResumeFile}
        parsed={parsed}
        setParsedData={setParsedData}
        setParsed={setParsed}
      />
    );
  };

  return (
    <>
      {loading && <Loader />}

      <div className="d-flex justify-content-center align-items-center vh-200">
        <div className="section section-sm pt-0" style={{ width: "100%" }}>
          <div className="ml-3 my-2">
            <img
              className="ml-2 my-auto"
              style={{ height: "40px", width: "75px" }}
              src={logo}
              alt="logo"
            />
          </div>

          <div className=" hero-container">
            {
              <div className="hero-overlay" style={{height:'370px'}}>
                <img src={high5people} alt="high5people" style={{height:'650px', marginTop:'225px'}} />

                <div className="section pt-7 hero-content">
                  <div className="row">
                    <div className="col-12">
                      <div className=" mb-5">
                        <h5 className="font-22 font-white">
                          Join High5 talent community!
                        </h5>
                        <p className="font-14 font-white">
                          We're passionate about connecting professionals with
                          incredible opportunities!
                        </p>
                        <p className="font-14 font-white">
                          Sign up for our talent community to receive updates on
                          job opportunities, news, and recruiting events at
                          High5.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }
            <div
              style={{
                margin: "10px 100px",
                marginTop: `${"-55px"}`,
                zIndex: "3",
              }}
            >
              <div className="card card-lg">
                <div className="card-body pb-0">
                  <hr />

                  <div className="row">
                    {/* <UpdateProfileFaq /> */}
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-column text-left   mb-3">
                            <h6>
                              Complete your application by uploading your
                              Resume/CV
                            </h6>
                            <div> {ResumeComponent()}</div>
                            <small className=" mt-2">
                              Upload either .pdf or .docx file (upto 2mb)
                            </small>
                          </div>

                          <ApplyPageProfileNew
                            formData={formData}
                            setFormData={setFormData}
                            setPhoneValidation={setPhoneValidation}
                            formIsValid={formIsValid}
                          />
                          {/* && job.generalQuestion  */}

                          <div className="row mt-3 ">
                            <div className="form-check form-switch d-flex align-items-center ml-2 mt-2 px-1">
                              <input
                                className=" mr-2"
                                style={{ width: 15, height: 15 }}
                                type="checkbox"
                                id="isFresher"
                                onChange={handleAgree}
                              />
                              <label
                                className="form-check-label pt-0 "
                                htmlFor="remoteSwitch"
                              >
                                By submitting this form, you agree to receive
                                job alerts, newsletters, and updates from High5
                                Talent Community.
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer pl-0">
                    <button
                      type="submit"
                      className="btn btn-primary btn btn-lg mb-3"
                      onClick={handleSubmit}
                      disabled={!agree}
                    >
                      <span style={{ fontSize: "bolder" }}>Join Now</span>
                    </button>
                    <div className="row mx-auto " style={{ width: "100%" }}>
                      <div className="col-md-7">
                        <footer>
                          <div>
                            <span className="text-secondary">Powered by</span>{" "}
                            <a
                              href="https://www.high5hire.com/"
                              target="_blank"
                            >
                              <img
                                style={{
                                  width: 40,
                                  height: 35,
                                  paddingBottom: 10,
                                }}
                                src={high5Logo}
                              />
                            </a>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(UpdateProfileInfo);
