import React from "react";
import { Col, Row } from "react-bootstrap";
import "react-pagination-js/dist/styles.css";
import "./CustomPagination.css";

const PaginationComponent = ({
  currentPage,
  totalItems,
  itemsPerPage,
  onPageChange,
  limit,
}) => {
  const numberFormatter = new Intl.NumberFormat("en-US");
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  let pagesToShow;
  if (limit === 5) {
    pagesToShow = 5;
  } else if (limit === 3) {
    pagesToShow = 3;
  } else if (totalItems / 10 > 1) {
    pagesToShow = 10;
  } else {
    pagesToShow = 2;
  }

  const renderPageNumbers = () => {
    const pageNumbers = [];

    const startPage = Math.max(1, currentPage - Math.floor(pagesToShow / 2));
    const endPage = Math.min(totalPages, startPage + pagesToShow - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? "active" : ""}`}
        >
          <a
            className="page-link"
            href="#"
            onClick={() => onPageChange(i)}
            style={{ border: "none", textDecoration: "none" }}
          >
            <small>{i}</small>
          </a>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <>
      {totalItems !== 0 ? (
        <Row
          className="d-flex justify-content-between mt-2 "
          id="customPagination"
        >
          <Col md={4} sm={12} className="mt-3 px-2 pageCol1">
            <small className=" ml-2">
              Showing{" "}
              {Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)} -{" "}
              {Math.min(currentPage * itemsPerPage, totalItems)} of{" "}
              {numberFormatter.format(totalItems)}
            </small>
          </Col>
          <Col md={8} sm={12} className="mt-3 px-1 d-flex pageCol2">
            <nav aria-label="...">
              <ul className="pagination">
                <li
                  className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => onPageChange(currentPage - 1)}
                    style={{
                      border: "none",
                      backgroundColor: "none",
                      textDecoration: "none",
                    }}
                  >
                    <small>|⟨</small>
                  </a>
                </li>
                {renderPageNumbers()}
                <li
                  className={`page-item ${
                    currentPage === totalPages ? "disabled" : ""
                  }`}
                >
                  <a
                    className="page-link"
                    href="#"
                    onClick={() => onPageChange(currentPage + 1)}
                    style={{
                      border: "none",
                      backgroundColor: "none",
                      textDecoration: "none",
                    }}
                  >
                    <small>⟩|</small>
                  </a>
                </li>
              </ul>
            </nav>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default PaginationComponent;
