import React, { useEffect, useState } from "react";
import AddJobNewGoogleMaps from "../../location/AddJobNewGoogleMaps";
import TagsInput from "react-tagsinput";
import Select from "react-select";
import Autosuggest from "react-autosuggest";
import { domain } from "../../../constants/Constants";
import { getUserTenantID } from "../../../service/AuthService";
import { get } from "../../../service/ApiService";
import SubmittalDetailsInfo from "../addjob/SubmittalDetailsInfo";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";

const TalentPool = (props) => {
  const today = new Date();
  const userId = getUserTenantID();

  const experienceLevel = [
    "Intern",
    "Less than one year",
    "1-3 years",
    "3-5 years",
    "5-10 years",
    "10+ years",
  ];

  // const list = ["--select--", "Alex Costa", "Will Smith"];
  const [list, setlist] = useState([]);
  const [candidates, setCandidates] = useState([]);
  const [userJson, setUserJson] = useState([]);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  let jobData =
    props.edit === true
      ? {
          userId: userId,
          poolName: props.editPoolDetails.poolName,
          poolOwner: props.editPoolDetails.ownerName,
          jobTitle: "",
          purpose: props.editPoolDetails.purpose,
          selectedCandidates: [],
          isRemote: true,
          location: {
            address: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
          },
          numberOfPositions: 10,
          allowedSubmittals: 100,
          duration: "",

          primarySkills: [],
          industry: [],
          education: ["Bachelors Degree"],
          skillSet: [],
          experienceLevel: "1-3 years",
          onsiteWorkDays: "",
          weightage: {
            primarySkills: [],
            secondarySkills: [],
            jobTitle: true,
            location: true,
            experienceLevel: true,
            education: [],
            industries: [],
          },
          autoTagging: "",
          grouping: "",
        }
      : {
          userId: userId,
          poolName: "",
          poolOwner: "",
          jobTitle: "",
          purpose: "",
          selectedCandidates: [],
          isRemote: true,
          location: {
            address: "",
            city: "",
            state: "",
            zipCode: "",
            country: "",
          },
          numberOfPositions: 10,
          allowedSubmittals: 100,
          duration: "",

          primarySkills: [],
          industry: [],
          education: ["Bachelors Degree"],
          skillSet: [],
          experienceLevel: "1-3 years",
          onsiteWorkDays: "",
          weightage: {
            primarySkills: [],
            secondarySkills: [],
            jobTitle: true,
            location: true,
            experienceLevel: true,
            education: [],
            industries: [],
          },
          autoTagging: "",
          grouping: "",
        };

  const [addJobData, setAddJobData] = useState(jobData);
  const [loading, setLoading] = useState(false);
  const [dropdownList, setDropDown] = useState({});
  const [addJobVal, setAddJobVal] = useState({
    poolName: "",
    poolOwner: "",
    title: "",
    primary: "",
    purpose: "",
    numberOfPositions: "",
    allowedSubmittals: "",
  });

  const getEmpList = async () => {
    const params = { id_tenant: userId };
    // setLoading(true);
    const res = await post(`/talentpool/getEmpList`, params);
    setlist(res.data);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await get(`/request/dropdownvalues`, { domainName: domain });
      setDropDown(res.data[0]);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  let candidateList = candidates.map((item) => {
    const skillSetObj = {
      id: item.ID_user,
      value: `${item.firstName} ${item.lastName}`,
      label: `${item.firstName} ${item.lastName}`,
    };
    return skillSetObj;
  });

  let skillSetOption =
    Object.keys(dropdownList).length > 0
      ? dropdownList.skillSet.map((item) => {
          const skillSetObj = {
            value: item.PositionName,
            label: item.PositionName,
          };
          return skillSetObj;
        })
      : [];

  let QualificationType =
    Object.keys(dropdownList).length > 0
      ? dropdownList.QualificationType.filter(
          (item) => item.PositionName !== "Select" && item.PositionName !== "NA"
        ).map((item) => {
          return item.PositionName;
        })
      : [];

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 999,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.PositionName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0 || Object.keys(dropdownList).length === 0
          ? []
          : dropdownList.skills.filter(
              (item) =>
                item.PositionName.toLowerCase().includes(inputValue) &&
                ![...addJobData.primarySkills].includes(item.PositionName)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => (
      <div>{suggestion.PositionName}</div>
    );

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.PositionName);
        }}
        theme={theme}
      />
    );
  };

  const getCandidates = async () => {
    setLoading(true);
    const res = await get("/talentpool/getCandidates").then((res) => {
      console.log("res.data", res.data);
      setCandidates(res.data);
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchData();
    getCandidates();
    getEmpList();
  }, []);

  const handleChange = (e) => {
    e.target.name === "numberOfPositions"
      ? setAddJobData({
          ...addJobData,
          [e.target.name]: e.target.value,
          allowedSubmittals: (+e.target.value * 10).toString(),
        })
      : setAddJobData({ ...addJobData, [e.target.name]: e.target.value });
  };

  const validate = () => {
    let validationArray = [];
    let validation = {
      poolName: "",
      poolOwner: "",
      title: "",
      primary: "",
      purpose: "",
      numberOfPositions: "",
      allowedSubmittals: "",
    };
    if (addJobData.jobTitle === "") {
      validation = { ...validation, jobTitle: "Required Field" };
      validationArray.push("Please Enter Job Title");
    }
    if (addJobData.poolName === "") {
      validation = { ...validation, poolName: "Required Field" };
      validationArray.push("Please Enter Talent Pool Name");
    }
    if (addJobData.poolOwner === "") {
      validation = { ...validation, poolOwner: "Required Field" };
      validationArray.push("Please Enter Talent Pool Owner Name");
    }
    if (addJobData.primarySkills.length === 0) {
      validation = { ...validation, primary: "Required Field" };
      validationArray.push("Please Enter Mandatory Skills");
    }
    if (addJobData.purpose == "") {
      validation = { ...validation, purpose: "Required Field" };
      validationArray.push("Please Enter Purpose");
    }
    return { validation, validationArray };
  };

  const convert = () => {
    let setArr = [];
    addJobData.selectedCandidates.forEach((elem) => {
      let obj = {};
      obj["id"] = elem;
      setArr = [...setArr, obj];
      // setUserJson([...userJson, obj]);
      console.log("obj", obj);
    });
    console.log("setArr", setArr);
    let newArr = setArr;
    // setUserJson(newArr);
    console.log("poolJson", userJson);
    return setArr;
  };

  const addPoolInDB = async () => {
    setLoading(true);
    let brr = convert();

    const params = { ...addJobData, userJson: brr };

    const res = await post("/talentpool/addTalentPool", params)
      .then((res) => {
        // setTenantData(res.data[0]);
        console.log("res", res);
        props.getPoolsFromDB();
        toast.success("Pool successfully added !");
        props.setShowAddPool(false);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error(err);
      });
  };

  const submit = () => {
    console.log("addJobData", addJobData);

    let result = validate();
    console.log(result);
    if (result.validationArray.length > 0) {
      toast.error(result.validationArray[0]);
      setAddJobVal(result.validation);
    } else {
      // toast.success("Validation Success");
      addPoolInDB();
    }
  };
  return (
    <>
      {loading && <Loader />}
      <div
        className="d-flex mb-2 align-items-center"
        style={{ marginTop: "-15px" }}
      >
        <h5 className="ml-3">Create Talent Pooll</h5>
        <label className="ml-auto mr-4">*denotes required field</label>
      </div>

      {/* OWNER AND NAME */}
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-floating">
                <input
                  type="text"
                  className={"form-control"}
                  name="poolName"
                  // pattern="[A-Za-z]"
                  placeholder="Enter a owner name"
                  onChange={(e) =>
                    setAddJobData({
                      ...addJobData,
                      poolName: e.target.value,
                    })
                  }
                  value={addJobData.poolName}
                />
                <label>Talent Pool Name*</label>
              </div>
              {addJobVal.poolName && (
                <small className="validation ml-2">{addJobVal.poolName}</small>
              )}
            </div>
            <div className="col-lg-6">
              <div className="form-floating">
                <select
                  className="font-14 form-select"
                  name="poolOwner"
                  onChange={(e) =>
                    setAddJobData({ ...addJobData, poolOwner: e.target.value })
                  }
                >
                  {/* {list.map((item) => (
                    onChange={(e) =>
                    setAddJobData({
                      ...addJobData,
                      experienceLevel: e.target.value,
                    })
                    <option value={item}>{item}</option>
                  ))} */}
                  {/* <option disabled>Select</option> */}
                  {/* {props.edit === true
                    ? list.map((e) => (
                        <option
                          value={e}
                          selected={e === addJobData.poolOwner}
                          disabled={e === "--select--"}
                        >
                          {e}
                        </option>
                      ))
                    : list.map((e) => <option value={e}>{e}</option>)} */}
                  <option selected>Select</option>
                  {list.map((e) => (
                    <option
                      value={e.ID_user}
                      // selected={e === addJobData.poolOwner}
                      // disabled={e === "--select--"}
                    >
                      {`${e.firstName} ${e.lastName}`}
                    </option>
                  ))}
                </select>
                {/* <input
                  type="text"
                  className={"form-control"}
                  name="poolOwner"
                  placeholder="Enter a owner name"
                  onChange={handleChange}
                  value={addJobData.poolOwner}
                /> */}
                <label> Talent Pool Owner Name*</label>
              </div>
              {addJobVal.poolOwner && (
                <small className="validation ml-2">{addJobVal.poolOwner}</small>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* HIRES */}
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <p>
              <strong>Talent Pool Target</strong>
            </p>
            <hr />
            <div className="col-lg-2">
              <label>Hires*</label>
            </div>
            <div className="col-lg-3">
              <label>Duration*</label>
            </div>
            <div className="col-lg-2">
              <label>Target Size*</label>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <div className="">
                <input
                  type="number"
                  className={"form-control"}
                  name="numberOfPositions"
                  min={"0"}
                  //placeholder="Enter a owner name"
                  onChange={handleChange}
                  value={addJobData.numberOfPositions}
                />
              </div>
              {addJobVal.numberOfPositions && (
                <small className="validation ml-2">
                  {addJobVal.numberOfPositions}
                </small>
              )}
            </div>
            <div className="col-lg-3">
              <div className="">
                <select className="form-select">
                  {["Per year", "Per quarter", "Per month"].map((i) => (
                    <option value={i}>{i}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="">
                <input
                  type="number"
                  className={"form-control"}
                  name="allowedSubmittals"
                  onChange={handleChange}
                  min={"0"}
                  value={addJobData.allowedSubmittals}
                />
              </div>
              {addJobVal.allowedSubmittals && (
                <small className="validation ml-2">
                  {addJobVal.allowedSubmittals}
                </small>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Add Candidates */}
      {/* <div className="card card-flat bg-gray4 m-3">
        <div className="card-body py-0">
          <label>Add Candidates</label>
          <div
            className="form-floating Hover-Dropdown mb-2"
            style={{ zIndex: "998" }}
          >
            <Select
              isMulti
              options={candidateList}
              name="industry"
              onChange={(e) => {
                setAddJobData({
                  ...addJobData,
                  selectedCandidates: e.map((skillSet) => skillSet.id),
                });
              }}
              isSearchable={true}
              value={candidateList.filter((item) =>
                addJobData.selectedCandidates.includes(item.id)
              )}
            ></Select>
          </div>
        </div>
      </div> */}

      {/* PURPOSE */}
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <span>Purpose*</span>
            <div className="col-lg-12">
              <textarea
                name="purpose"
                onChange={handleChange}
                value={addJobData.purpose}
                rows={4}
                className="form-control"
              />
            </div>
            {addJobVal.purpose && (
              <small className="validation ml-2">{addJobVal.purpose}</small>
            )}
          </div>
        </div>
      </div>

      {/* JOB TITLE AND LOCATION */}
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <p>
              <strong>Candidate Attribute</strong>
            </p>
            <hr />
            <div className="col-lg-5">
              <div className="form-floating">
                <input
                  type={"text"}
                  className="form-control"
                  placeholder="Enter Job Title"
                  value={addJobData.jobTitle}
                  name="jobTitle"
                  onChange={handleChange}
                />
                <label>Job Title*</label>
              </div>
              {addJobVal.jobTitle && (
                <small className="validation ml-2">{addJobVal.jobTitle}</small>
              )}
            </div>
            <div className="col-lg-7">
              {/* <label>Location</label> */}
              <AddJobNewGoogleMaps
                isRemote={addJobData.isRemote}
                setIsRemote={(value) =>
                  setAddJobData({ ...addJobData, isRemote: value })
                }
                addJobData={addJobData}
                setAddJobData={setAddJobData}
              />
            </div>
          </div>
        </div>
      </div>

      {/* WORK PLACE TYPE */}
      <div className="card card-flat bg-gray4 mx-3">
        <div className="card-body">
          <p className="mb-2">Choose the workplace type</p>
          <hr />
          <div className="row mt-2">
            <div className="col-lg-4">
              <div className="d-flex">
                <div className="flex-item">
                  <p className="mb-0">
                    <strong>Remote</strong>
                  </p>
                  <span>
                    <medium>Employee work off-site</medium>
                  </span>
                </div>
                <div className="flex-item mt-2">
                  <div className="form-check form-switch d-flex align-items-center p-0">
                    <input
                      name="isRemote"
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="RemoteSwitch"
                      checked={addJobData.isRemote}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAddJobData({
                            ...addJobData,
                            workPlaceType: "Remote",
                            isRemote: true,
                          });
                        } else {
                          setAddJobData({
                            ...addJobData,
                            workPlaceType: "On-Site",
                            isRemote: false,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex">
                <div className="flex-item">
                  <p className="mb-0">
                    <strong>Hybrid</strong>
                  </p>
                  <span>
                    <medium>Employee work both on-site & off-site</medium>
                  </span>
                </div>
                <div className="flex-item mt-2">
                  <div className="form-check form-switch d-flex align-items-center p-0">
                    <input
                      name="isHybrid"
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="HybridSwitch"
                      checked={addJobData.workPlaceType === "Hybrid"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAddJobData({
                            ...addJobData,
                            onsiteWorkDays: "3 days",
                            workPlaceType: "Hybrid",
                            isRemote: false,
                          });
                        } else {
                          setAddJobData({
                            ...addJobData,
                            onsiteWorkDays: "",
                            workPlaceType: "Remote",
                            isRemote: true,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="d-flex">
                <div className="flex-item">
                  <p className="mb-0" style={{ fontWeight: "bold" }}>
                    On-Site
                  </p>
                  <span>
                    <medium>Employee comes to work in person</medium>
                  </span>
                </div>
                <div className="flex-item mt-2">
                  <div className="form-check form-switch d-flex align-items-center p-0">
                    <input
                      name="isRemote"
                      className="form-check-input ml-3"
                      type="checkbox"
                      checked={addJobData.workPlaceType === "On-Site"}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setAddJobData({
                            ...addJobData,
                            workPlaceType: "On-Site",
                            isRemote: false,
                          });
                        } else {
                          setAddJobData({
                            ...addJobData,
                            workPlaceType: "Remote",
                            isRemote: true,
                          });
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {addJobData.workPlaceType === "Hybrid" && (
            <>
              <hr />
              <div className="d-flex align-items-center mt-3">
                <p className="m-0">Number of days for on-site</p>
                <div className="col-lg-2 ml-3">
                  <select
                    className="font-14 form-select"
                    name="onsiteWorkDays"
                    onChange={(e) =>
                      setAddJobData({
                        ...addJobData,
                        onsiteWorkDays: e.target.value,
                      })
                    }
                  >
                    {["1 day", "2 days", "3 days", "4 days", "5 days"].map(
                      (v) => (
                        <option
                          selected={addJobData.onsiteWorkDays === v}
                          value={v}
                        >
                          {v}
                        </option>
                      )
                    )}
                  </select>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      {/* SKILLS */}
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body py-0">
          <div className="form-floating mb-2" style={{ zIndex: "999" }}>
            <div className="">
              <label className="mb-1">
                Skills* <small>(Suggested to add top 3 skills)</small>
              </label>
              <TagsInput
                renderInput={autocompleteRenderInput}
                value={addJobData.primarySkills}
                onChange={(skills) => {
                  setAddJobData({
                    ...addJobData,
                    weightage: {
                      ...addJobData.weightage,
                      primarySkills: skills,
                    },
                    primarySkills: skills,
                  });
                }}
                inputValue={primarySkillinput}
                onChangeInput={(skills) => setPrimarySkillInput(skills)}
                placeholder="Type a location and hit enter"
                inputProps={{
                  placeholder: primarySkillinput ? "" : "Enter Skills*",
                }}
              />
            </div>
            {addJobVal.primary && (
              <small className="validation ml-2">{addJobVal.primary}</small>
            )}
          </div>
        </div>
      </div>

      {/* INDUSTRY */}
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body py-0">
          <label>Industry</label>
          <div
            className="form-floating Hover-Dropdown mb-2"
            style={{ zIndex: "997" }}
          >
            <Select
              isMulti
              options={skillSetOption}
              name="industry"
              onChange={(e) => {
                // setAddJobData({
                //   ...addJobData,
                //   weightage: {
                //     ...addJobData.weightage,
                //     industries: e.map((skillSet) => skillSet.value),
                //   },
                //   industry: e.map((skillSet) => skillSet.value),
                // });
                // setAddJobData({
                //   ...addJobData,
                //   weightage: {
                //     ...addJobData.weightage,
                //     primarySkills: skills,
                //   },
                //   primarySkills: skills,
                // });
                setAddJobData({
                  ...addJobData,
                  industry: e.map((skillSet) => skillSet.value),
                  weightage: {
                    ...addJobData.weightage,
                    industries: e.map((skillSet) => skillSet.value),
                  },
                });
              }}
              isSearchable={true}
              value={skillSetOption.filter((item) =>
                addJobData.industry.includes(item.value)
              )}
            ></Select>
          </div>
        </div>
      </div>

      {/* REQUIRED EXPERIENCE and REQUIRED EDUCATION */}
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body m-0">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-floating">
                <select
                  className="font-14 form-select"
                  name="experienceLevel"
                  onChange={(e) =>
                    setAddJobData({
                      ...addJobData,
                      experienceLevel: e.target.value,
                    })
                  }
                >
                  <option disabled>Select</option>
                  {experienceLevel.map((e) => (
                    <option
                      selected={e === addJobData.experienceLevel}
                      value={e}
                    >
                      {e}
                    </option>
                  ))}
                </select>
                <label>Required Experience*</label>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-floating">
                <select
                  className="font-14 form-select"
                  onChange={(e) =>
                    setAddJobData({
                      ...addJobData,
                      education: [e.target.value],
                    })
                  }
                >
                  <option disabled>Select</option>
                  {QualificationType.map((e) => (
                    <option selected={addJobData.education[0] === e}>
                      {e}
                    </option>
                  ))}
                  <option
                    selected={
                      addJobData.education &&
                      addJobData.education[0] === "Bachelors Degree"
                    }
                  >
                    Bachelors Degree
                  </option>
                </select>
                <label>Required Education*</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body py-0">
          <SubmittalDetailsInfo
            parent="DirectSourcing"
            addJobData={addJobData}
            weightage={addJobData.weightage}
            setWeightage={(data) =>
              setAddJobData({ ...addJobData, weightage: data })
            }
            component={"TalentPool"}
          />
        </div>
      </div>

      {/* AUTO TAGGING */}
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body py-0">
          <label>Auto Tagging</label>
          <div className="row">
            <div className="col-lg-12 mb-2">
              <input
                name="autoTagging"
                value={addJobData.autoTagging}
                onChange={handleChange}
                type={"text"}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      {/* GROUPING */}
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body py-0">
          <label>Grouping</label>
          <div className="row">
            <div className="col-lg-12 mb-2">
              <input
                name="grouping"
                value={addJobData.grouping}
                onChange={handleChange}
                type={"text"}
                className="form-control"
              />
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div className="text-right mb-2 mr-2">
        <button
          className="btn btn-sm btn-secondary mr-2"
          onClick={() => {
            props.setShowAddPool(false);
          }}
        >
          Cancel
        </button>
        <button className="btn btn-sm btn-primary" onClick={submit}>
          Submit
        </button>
      </div>
    </>
  );
};

export default TalentPool;
