import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import DisqualifyPopUp from "../disqualified/DisqualifyPopUp";
import ResumeDownload from "../resume/Index";
import ShortlistPopUp from "../shortlisted/ShortlistPopUp";
import TalentProfile from "../../../../talent/talentprofile/TalentProfile";
import VettingResendMail from "../shortlisted/VettingResendMail";
import EmailStatus from "../../../../EmailStatus/EmailStatus";
import { getPoolDispositionDetails } from "../../../../../../actions/directsourcing/Disposition";
import { getPoolDispositionCount } from "../../../../../../actions/directsourcing/PoolDispositionCount";
import ScreeningQuestions from "../Screening";
import VettingRequirement from "../Vetting";
import ReadyToHireQA from "../ReadyToHireQA";
import ProfileImage from "../../../../commoncomponents/profileimage/ProfileImage";
import vettedIcon from "../../../../../../../src/images/high5-mark-circle-dark.svg";

import { FaCommentAlt } from "react-icons/fa";

const Vetting = (props) => {
  const {
    jobData,
    submittedData,
    filterParams,
    view,
    setCandidateProfile,
    candidateProfile,
  } = props;
  const dispatch = useDispatch();
  const { poolId } = useParams();
  const [openDropDown, setOpenDropDown] = useState(false);
  const [disqualifyPopup, setDisqualifyPopup] = useState(false);
  const [disqualifyData, setDisqualifyData] = useState(false);
  const [resume, setResume] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const [shortlistPopup, setShortlistPopup] = useState(false);
  const [vettingResendPopup, setVettingResendPopup] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [assessmentTab, setAssessmentTab] = useState(false);

  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState({});
  const [qna, setQNA] = useState(false);
  const [screeningQuestion, setScreeningQuestion] = useState();
  const [vetting, setVetting] = useState(false);
  const [readyToHireQA, setReadyToHireQA] = useState(false);
  const [isCandidateFrom, setIsCandidateFrom] = useState("");

  const onDisqualifyClick = () => {
    setOpenDropDown(false);
    setDisqualifyPopup(true);
  };

  const openDisqualify = (data) => {
    setDisqualifyData(data);
    setOpenDropDown(true);
  };

  const handleResume = (data) => {
    setResumeData(data);
    setResume(true);
  };

  const reloadDisposition = () => {
    const payloadDisposition = {
      poolId: poolId,
      status: view,
      limit: 15,
      currentPage: 1,
      filterObject: filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionDetails(payloadDisposition));
    const payload = {
      jobId: poolId,
      filterObject: filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionCount(payload));
    setShortlistPopup(false);
    setDisqualifyPopup(false);
  };

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
    setAssessmentTab(false);
  };

  const handleModel = () => {
    setCandidateProfile(false);
    setAssessmentTab(false);
  };

  return (
    <>
      {submittedData.map((item, index) => (
        <tr key={index}>
          <td className="no-hover" onClick={() => onProfileClick(item)}>
            <div className="d-flex flex-inline w-70">
              <ProfileImage
                imageSrc={item?.profileImagePath}
                imageWidth={"35"}
                imageHeight={"35"}
                avatarSize={"35"}
                avatarRound={"80px"}
                firstName={item?.firstName}
                lastName={item?.lastName}
              />
              <span className="ml-2 font-16">
                {item?.firstName.charAt(0).toUpperCase() +
                  item?.firstName.slice(1)}{" "}
                {item?.lastName.charAt(0).toUpperCase() +
                  item?.lastName.slice(1)}
              </span>
              <div className="justify-content-start">
                {!item?.readStatus && (
                  <button
                    type="button"
                    title="Send SMS"
                    class="btn btn-text"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      setCandidateProfile(true);
                      setIsCandidateFrom("sms");
                    }}
                  >
                    <div className="notification-icon">
                      <FaCommentAlt />
                      <span className="message-status"></span>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </td>
          <td>
            <span class="tag tag-lg tag-coral ml-2">
              <img width="14px" src={vettedIcon} alt="" />
              <span class="mx-2">Yes</span>
            </span>
          </td>
          <td className="no-hover">
            {moment(new Date(item?.vettedDate)).fromNow()}
          </td>
          <td class="no-hover">
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Knockout Assessment"
              class="d-inline-block"
              data-original-title="Knockout Assessment"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                class="btn btn-square btn-gray"
                disabled={
                  (item?.screeningQuestions
                    ? JSON.parse(item?.screeningQuestions)
                    : []
                  ).length === 0
                    ? true
                    : false
                }
                onClick={() => {
                  setQNA(true);
                  setScreeningQuestion(item);
                }}
              >
                <i aria-hidden="true" class="fad fa-webcam"></i>
              </button>
            </div>{" "}
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Vetting"
              class="d-inline-block"
              data-original-title="Vetting"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                disabled={item?.vettingDetails?.length === 0 ? true : false}
                class="btn btn-square btn-gray"
                onClick={() => {
                  setVetting(true);
                  setScreeningQuestion(item);
                }}
              >
                <i aria-hidden="true" class="fas fa-walkie-talkie"></i>
              </button>
            </div>{" "}
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Ready To Hire"
              class="d-inline-block"
              data-original-title="Ready To Hire"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                disabled={item?.readyToHireQA ? false : true}
                class="btn btn-square btn-gray"
                onClick={() => {
                  setReadyToHireQA(true);
                  setScreeningQuestion(item);
                }}
              >
                <i aria-hidden="true" class="fas fa-ballot-check"></i>
              </button>
            </div>
          </td>
          <td className="no-hover">
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Resume Download"
              style={{ padding: "2px" }}
            >
              <button
                type="button"
                onClick={() => handleResume(item)}
                data-toggle="modal"
                data-target="#ViewResumeDocModal"
                className="btn btn-square btn-gray"
              >
                <i className="fas fa-file-user" />
              </button>
            </div>
          </td>
          <td>
            <span className="ml-2">
              {item.URLOpenCount ? item.URLOpenCount : 0}
            </span>
          </td>
          <td>
            {item.status && item.status === 21 && (
              <span className="ml-2">Active</span>
            )}
          </td>
        </tr>
      ))}
      {disqualifyPopup && (
        <DisqualifyPopUp
          disqualifyPopup={disqualifyPopup}
          setDisqualifyPopup={setDisqualifyPopup}
          disqualifyData={disqualifyData}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
        />
      )}
      {resume && (
        <ResumeDownload
          resume={resume}
          setResume={setResume}
          resumeData={resumeData}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          submittedData={submittedData}
          setDisqualifyPopup={setDisqualifyPopup}
          setOpenDropDown={setOpenDropDown}
          setSelectedCandidate={setSelectedCandidate}
          setShortlistPopup={setShortlistPopup}
          openDisqualify={openDisqualify}
          disqualifyData={disqualifyData}
          openDropDown={openDropDown}
          onDisqualifyClick={onDisqualifyClick}
          candidateData={selectedCandidate}
          handleSuccess={handleModel}
          jobData={jobData}
          parent="submitTab"
          isFrom="submitPooled"
          component="TalentPool"
          assessment={assessmentTab}
          disqualifyPopup={disqualifyPopup}
          reloadDisposition={reloadDisposition}
          setVettingResendPopup={setVettingResendPopup}
          poolId={poolId}
          isCandidateFrom={isCandidateFrom}
          setIsCandidateFrom={setIsCandidateFrom}
        />
      )}
      {shortlistPopup && (
        <ShortlistPopUp
          selectedCandidate={selectedCandidate}
          shortlistPopup={shortlistPopup}
          setShortlistPopup={setShortlistPopup}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
        />
      )}

      {vettingResendPopup && (
        <VettingResendMail
          candidateData={selectedCandidate}
          jobData={jobData}
          vettingResendPopup={vettingResendPopup}
          setVettingResendPopup={setVettingResendPopup}
        />
      )}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="Vetting"
          invitedDate={selectedTalent?.invitedDate}
          jobData={jobData}
        />
      )}
      <ScreeningQuestions
        QandA={
          screeningQuestion?.screeningQuestions
            ? JSON?.parse(screeningQuestion?.screeningQuestions)
            : []
        }
        setQNA={setQNA}
        qna={qna}
      />

      <VettingRequirement
        vettingDetails={screeningQuestion?.vettingDetails}
        setVetting={setVetting}
        vetting={vetting}
        jobData={jobData}
      />

      <ReadyToHireQA
        QandA={
          screeningQuestion?.readyToHireQA
            ? JSON?.parse(screeningQuestion?.readyToHireQA)
            : []
        }
        setQNA={setReadyToHireQA}
        qna={readyToHireQA}
      />
    </>
  );
};

export default Vetting;
