import React, { useState } from "react";
import FormSubmitSuccess from "../../../modal/result/FormSubmitSuccess";
import AddTalentManualEntry from "../../talent/addtalent/manualentry/Index";
import Preview from "../../broadcast/Preview";

import Modal from "react-bootstrap/Modal";
import EmailConfirmationPopup from "../../broadcast/EmailConfirmationPopup";
import EmailRecipients from "../../broadcast/EmailRecipients/EmailRecipientsNew";
import SelectTemplate from "../../broadcast/SelectTemplate";
import SelectJob from "../../broadcast/SelectJob";
import ScreeningFilter from "../../commoncomponents/talentcommunity/ScreeningFilter";
import SelectSMSTemplate from "../../broadcast/smsBroadCast/SelectSMSTemplate";
import ChatGPT from "../../chatgpt/ChatGPTNew";

const ModalView = (props) => {
  const {
    modalSize,
    modalTitle,
    isModalOpen,
    setModalClose,
    setSmsContent,
    successData,
    parentModalClose,
    data,
    modalFor,
    setFilter,
    setData,
    Recipientdata,
    tableStructureCan,
    handleClear,
    isFrom,
    isFor,
    fetchData,
  } = props;
  const [selected, setSelected] = useState(true);
  const onClose = () => {
    setModalClose(false);
    if (isFrom === "ManualEntry") {
      parentModalClose();
    }
    if (isFrom === "TalentProfileEdit") {
      parentModalClose(false);
    }
  };

  return (
    <>
      <Modal size={modalSize} show={isModalOpen}>
        <div class="bl-modal-header">
          <h6 class="bl-modal-title">{modalTitle}</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={onClose}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          {isFrom === "TalentCommunity" && (
            <FormSubmitSuccess
              successData={successData}
              isFrom="TalentCommunity"
              closeModal={onClose}
            />
          )}
          {isFrom === "ManualEntry" && (
            <FormSubmitSuccess
              successData={successData}
              isFrom={isFrom}
              closeModal={onClose}
            />
          )}

          {isFrom === "TalentProfileEdit" && (
            <AddTalentManualEntry
              isFrom={isFrom}
              isFor={isFor}
              closeModal={onClose}
              data={data}
              handleClear={handleClear}
              fetchData={fetchData}
            />
          )}
          {isFrom === "EmailBroadcast" && modalFor === "selectTemplate" && (
            <SelectTemplate
              isModalOpen={isModalOpen}
              setModalClose={setModalClose}
              //emailTemplates={emailTemplates}
              isFrom={isFrom}
              closeModal={onClose}
              data={data}
              // previewEmail={previewEmail}
              // setPreviewEmail={setPreviewEmail}
              // selectedTemplate={selectedTemplate}
              // setSelectedTemplate={setSelectedTemplate}
              // handleTemplateChange={handleTemplateChange}
            />
          )}
          {isFrom === "SmsBroadcast" && modalFor === "selectTemplate" && (
            <SelectSMSTemplate
              isModalOpen={isModalOpen}
              setModalClose={setModalClose}
              isFrom={isFrom}
              closeModal={onClose}
              data={data}
              setSmsContent={setSmsContent}
            />
          )}
          {isFrom === "EmailBroadcast" && modalFor === "selectJob" && (
            <SelectJob
              isModalOpen={isModalOpen}
              setModalClose={setModalClose}
              isFrom={isFrom}
              closeModal={onClose}
            />
          )}
          {isFrom === "SMSBroadcast" && modalFor === "selectJob" && (
            <SelectJob
              isModalOpen={isModalOpen}
              setModalClose={setModalClose}
              isFrom={isFrom}
              closeModal={onClose}
            />
          )}
          {isFrom === "EmailBroadcast" && modalFor === "preview" && (
            <Preview isFrom={isFrom} closeModal={onClose} data={data} />
          )}

          {isFrom === "EmailOverview" && modalFor === "preview" && (
            <Preview isFrom={isFrom} closeModal={onClose} data={data} />
          )}
          {isFrom === "EmailBroadcast" &&
            modalFor === "sendEmailConfirmation" && (
              <EmailConfirmationPopup
                closeModal={onClose}
                handleSendClick={data.handleSendClick}
              />
            )}
          {isFrom === "EmailBroadcast" &&
            (modalFor === "addRecruiter" || modalFor === "addCandidate") && (
              <EmailRecipients
                handleApplyClick={data.handleApplyClick}
                setModalClose={setModalClose}
                modalFor={modalFor}
                selectedJob={data.selectedJob}
                setFilter={setFilter}
                closeModal={onClose}
                setRecipientData={setData}
                Recipientdata={Recipientdata}
                tableStructureCan={tableStructureCan}
                setSelected={setSelected}
                selected={selected}
                data={data}
              />
            )}

          {isFrom === "talentPool" && modalFor === "dispositionFilter" && (
            <ScreeningFilter
              isModalOpen={isModalOpen}
              setModalClose={setModalClose}
              isFrom={isFrom}
              closeModal={onClose}
            />
          )}

          {isFrom === "AddSubCommunity" && modalFor === "jobDescription" && (
            <ChatGPT
              isModalOpen={isModalOpen}
              setModalClose={setModalClose}
              isFrom={isFrom}
              closeModal={onClose}
              data={data}
            />
          )}

          {isFrom === "CreateSubCommunity" && modalFor === "subCommunityJD" && (
            <ChatGPT
              isModalOpen={isModalOpen}
              setModalClose={setModalClose}
              isFrom={isFrom}
              closeModal={onClose}
              data={data}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default ModalView;
