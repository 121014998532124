import React, { useState, useEffect } from "react";
import moment from "moment";

const Filter = (props) => {
  let pageSize = 5;
  let dataSize = 4;
  const jobListData = props.jobListData;
  const applyFilterParent = props.applyFilter;
  const changeFilterByTitle = props.changeFilterByTitle;
  const changeFilterByType = props.changeFilterByType;
  const requestTitleLength = Object.keys(props.requestTitle).length;
  const requestTitleArray = new Array(Math.ceil(requestTitleLength / dataSize))
    .fill()
    .map((_, i) => i + 1);
  const [requestTitleEnabled, setRequestTitleEnabled] = useState(false);
  const [requestTypeEnabled, setRequestTypeEnabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchEnabled, setSearchEnabled] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [daysLeftEnabled, setDaysLeftEnabled] = useState(false);
  const [paginationStart, setPaginationStart] = useState(0);
  const [paginationGroup, setPaginationGroup] = useState(
    new Array(Math.ceil(props.jobListData.length / dataSize))
      .fill()
      .map((_, i) => i + 1)
      .slice(0, pageSize)
  );
  const [pageType, setPageType] = useState("");

  const titleHandler = (checked, title) => {
    if (checked) {
      changeFilterByTitle({ ...props.requestTitle, [title]: true });
    } else {
      changeFilterByTitle({ ...props.requestTitle, [title]: false });
    }
  };

  const typeHandler = (checked, type) => {
    if (checked) {
      changeFilterByType({ ...props.requestType, [type]: true });
    } else {
      changeFilterByType({ ...props.requestType, [type]: false });
    }
  };

  const clearTitle = () => {
    let requestTitles = {};
    let uniqueTitles = [
      ...new Set(jobListData.map((i) => i.fullText.jobTitle)),
    ];
    uniqueTitles.forEach((i) => (requestTitles[i] = false));
    changeFilterByTitle(requestTitles);
  };

  const clearType = () => {
    let requestTypes = {};
    let uniqueTypes = [...new Set(jobListData.map((i) => i.jobType))];
    uniqueTypes.forEach((i) => (requestTypes[i] = false));
    changeFilterByType(requestTypes);
  };

  const clearDaysLeftToFill = () => {
    props.changeDaysLeftValue("");
    props.changeDaysLeft("");
  };

  const clearAll = () => {
    clearTitle();
    clearType();
    clearDaysLeftToFill();
  };

  const titleArray = (title) => {
    let finalValue =
      Object.entries(props.requestTitle).filter((title) => title[1]).length > 0
        ? Object.entries(props.requestTitle)
            .filter((title) => title[1])
            .map((title) => title[0])
            .includes(title)
        : true;
    return finalValue;
  };

  const typeArray = (type) => {
    return Object.entries(props.requestType).filter((type) => type[1]).length >
      0
      ? Object.entries(props.requestType)
          .filter((type) => type[1])
          .map((type) => type[0])
          .includes(type)
      : true;
  };

  const daysLeftArray = (date) => {
    let days = moment(new Date(date), "YYYYMMDD")
      .fromNow()
      .split(" ");
    console.log(days);
    if (days[2] !== "ago") {
      switch (props.daysLeft) {
        case "":
          return true;
        case "equals":
          return +days[1] === props.daysLeftValue;
        case "lessthan":
          return +days[1] < props.daysLeftValue;
        case "greaterthan":
          return +days[1] > props.daysLeftValue;
        case "lessthanequalto":
          return +days[1] <= props.daysLeftValue;
        default:
          return +days[1] >= props.daysLeftValue;
      }
    } else {
      return props.daysLeft ? false : true;
    }
  };

  const applyFilter = () => {
    if (
      Object.entries(props.requestTitle).filter((title) => title[1]).length >
        0 ||
      Object.entries(props.requestType).filter((type) => type[1]).length > 0 ||
      props.daysLeft !== ""
    ) {
      let filtered = jobListData.filter(
        (i) =>
          titleArray(i.fullText.jobTitle) &&
          typeArray(i.jobType) &&
          daysLeftArray(i.expiresOn)
      );
      applyFilterParent(filtered);
    } else {
      applyFilterParent(jobListData);
    }
  };

  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * 4 - 4;
    const endIndex = startIndex + 4;
    return searchEnabled
      ? searchData.slice(startIndex, endIndex)
      : Object.entries(props.requestTitle).slice(startIndex, endIndex);
  };

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % 5 === 0 && currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(currentPage - 1, currentPage + (pageSize - 1))
        );
      }
    } else {
      if (currentPage % 5 === 0 && currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(currentPage - 5, currentPage)
        );
      }
    }
  }, [currentPage]);

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={applyFilter}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>
                Filter /{" "}
                {Object.entries(props.requestTitle).filter((title) => title[1])
                  .length +
                  Object.entries(props.requestType).filter((type) => type[1])
                    .length +
                  (props.daysLeftValue !== "" ? 1 : 0)}
              </h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear All
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() =>
                requestTitleEnabled
                  ? setRequestTitleEnabled(false)
                  : setRequestTitleEnabled(true)
              }
            >
              <i
                className={
                  requestTitleEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Job Title</small>
                {Object.values(props.requestTitle).filter((i) => i).length >
                  0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTitleEnabled && (
              <div className="mb-4 ml-4 pl-1">
                <input
                  type="text"
                  placeholder="Type to filter list"
                  className="form-control form-control-filter mt-2"
                  onChange={(e) => {
                    setSearchEnabled(true);
                    setSearchData(
                      Object.entries(props.requestTitle).filter((i) =>
                        i[0]
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      )
                    );
                  }}
                />
                {getPaginatedData().length === 0 ? (
                  <p className="m-4">You Have Reached End</p>
                ) : (
                  getPaginatedData().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>{j[0]}</div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={props.requestTitle[j]}
                            className="form-check-input"
                            onChange={(e) =>
                              titleHandler(e.target.checked, j[0])
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))
                )}
                <div className="d-flex justify-content-end pt-4 px-1">
                  <div className="d-flex">
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage((page) => page - 1);
                        }}
                        type="button"
                        disabled={currentPage === 1 ? true : false}
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage === item
                              ? "btn btn-text px-2 bg-secondary text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === requestTitleArray.slice(-1)[0]
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <hr className="p-0" />
              </div>
            )}
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() =>
                requestTypeEnabled
                  ? setRequestTypeEnabled(false)
                  : setRequestTypeEnabled(true)
              }
            >
              <i
                className={
                  requestTypeEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Job Type</small>
                {Object.values(props.requestType).filter((i) => i).length >
                  0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTypeEnabled && (
              <div className="mb-4 ml-4 pl-1 pt-1">
                {Object.keys(props.requestType).map((i) => (
                  <div className="d-flex align-items-center m-1">
                    <div>{i.charAt(0).toUpperCase() + i.slice(1)}</div>
                    <div className="ml-auto">
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={props.requestType[i]}
                          onChange={(e) => typeHandler(e.target.checked, i)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
                <div className="d-flex justify-content-end mt-2 px-1">
                  <button className="btn btn-text" onClick={clearType}>
                    <small>Clear Job Type</small>
                  </button>
                </div>
                <hr className="p-0" />
              </div>
            )}
          </div>
          <div className="mb-2">
            <button
              type="button"
              class="btn btn-collapsible"
              onClick={() =>
                daysLeftEnabled
                  ? setDaysLeftEnabled(false)
                  : setDaysLeftEnabled(true)
              }
            >
              <i
                className={
                  daysLeftEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Days Left To Fill</small>
                {props.daysLeftValue !== "" && <span className="jewel"></span>}
              </span>
            </button>
            {daysLeftEnabled && (
              <div className="mb-4 ml-4 pl-1 pt-2">
                <div className="d-flex">
                  <select
                    className="form-select w-50 mx-1"
                    onChange={(e) => props.changeDaysLeft(e.target.value)}
                  >
                    <option
                      selected={props.daysLeft === "" ? true : false}
                      disabled
                      value="condition"
                    >
                      Condition
                    </option>
                    <option
                      selected={props.daysLeft === "equals" ? true : false}
                      value="equals"
                    >
                      Equals
                    </option>
                    <option
                      selected={props.daysLeft === "lessthan" ? true : false}
                      value="lessthan"
                    >
                      Less Than
                    </option>
                    <option
                      selected={props.daysLeft === "greaterthan" ? true : false}
                      value="greaterthan"
                    >
                      Greater Than
                    </option>
                    <option
                      selected={
                        props.daysLeft === "lessthanequalto" ? true : false
                      }
                      value="lessthanequalto"
                    >
                      Less than Or Equal To
                    </option>
                    <option
                      selected={
                        props.daysLeft === "greaterthanequalto" ? true : false
                      }
                      value="greaterthanequalto"
                    >
                      Greater than Or Equal To
                    </option>
                  </select>
                  <input
                    type="number"
                    value={props.daysLeftValue}
                    placeholder={"Value"}
                    class="form-control w-50 mx-1"
                    onChange={(e) => props.changeDaysLeftValue(+e.target.value)}
                  />
                </div>
                <div className="d-flex justify-content-end mt-2 px-1">
                  <button
                    className="btn btn-text"
                    onClick={clearDaysLeftToFill}
                  >
                    <small>Clear Days Left To Fill</small>
                  </button>
                </div>
                <hr className="p-0" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
