import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const FETCH_ALL_SENT_MAILS_BEGIN = "FETCH_ALL_SENT_MAILS_BEGIN";
export const FETCH_ALL_SENT_MAILS_SUCCESS = "FETCH_ALL_SENT_MAILS_SUCCESS";
export const FETCH_ALL_SENT_MAILS_FAILURE = "FETCH_ALL_SENT_MAILS_FAILURE";

export const fetchAllSentMails = (param) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_ALL_SENT_MAILS_BEGIN,
    });
    const res = await post("/broadCast/getSentMail", param);
    if (res.status === 200) {
      dispatch({
        type: FETCH_ALL_SENT_MAILS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_ALL_SENT_MAILS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};
