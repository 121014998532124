import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import { post } from "../../service/ApiService";
import { getPreciseDateDiff } from "../function/CommonFunction";
import { domain, TenantImage } from "../../constants/Constants";
import moment from "moment";
import { getCandidateName, getTenantType } from "../../service/AuthService";
import sparkles from "../../assets/lottie/sparkles.json";
import checkMark from "../../assets/lottie/checkmark.json";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";
import axios from "axios";
import Footer from "../layout/Footer";
const { Anime } = ReactAnime;

function AcceptRejectRTR(props) {
  const [loop] = useState(false);
  const [jobData, setJobData] = useState({ fullText: {} });
  const [loading, setLoading] = useState(false);
  const [tenantImage, setTenantImage] = useState("");
  const [uuidData, setUuidData] = useState({});
  const [candidateData, setCandidateData] = useState({ fullText: {} });
  const [recruiterData, setRecruiterData] = useState({});
  const [payrateObj, setPayrateObj] = useState({});
  const [tenantData, setTenantData] = useState({});
  const [resultData, setResultData] = useState({});
  const [status, setStatus] = useState("");
  const [jobDetails, setJobDetails] = useState({});
  const [pendingVetting, setPendingVetting] = useState([]);

  useEffect(() => {
    fetchVetting();
  }, []);

  const fetchVetting = async () => {
    setLoading(true);
    const res = await axios.get(
      "https://high5vettinguat-api.azurewebsites.net/candidate/all",
      {
        headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
      }
    );
    console.log("vetting", res);
    fetchJobData(res.data);
  };

  const fetchJobData = async (data) => {
    const payload = {
      uuid: props.match.params.Id,
    };
    let result = await post("/acceptrejectrtr/getjobdatafromuuid", payload);
    // setJobData(result.data.jobData);
    // setUuidData(result.data.uuidData);
    // setTenantImage(result.data.imageURL);
    // setCandidateData(result.data.candidateData);
    // setRecruiterData(result.data.recruiterData);
    // setPayrateObj({
    //   ...result.data.payrateObj,
    //   payRate: result.data.payrateObj.payRate.replace(
    //     /\B(?=(\d{3})+(?!\d))/g,
    //     ","
    //   ),
    // });
    // setTenantData(result.aboutus);
    // setResultData(result.data);
    console.log("result", result.data);
    let final = data.filter(
      (item) =>
        item.high5hireCandidateId === result.data.jobData.jobId &&
        item.email === result.data.candidateData.email
    );
    console.log(final);
    if (result.data.payrateObj.statusName === "Vetting Pending") {
      if (final.length > 0) {
        if (final.map((i) => i.testCompleted).some((i) => i === true)) {
          handleAccept(
            result.data.uuidData,
            result.data.jobData,
            result.data.candidateData,
            result.data.recruiterData
          );
        } else {
          setStatus("Vetting Pending");
          setPendingVetting(final.map((i) => i.testAssign.skills[0]));
          setLoading(false);
        }
      }
    } else if (result.data.payrateObj.statusName === "Submitted") {
      setStatus("Submitted");
      setLoading(false);
    } else if (final.length > 0) {
      if (final.map((i) => i.testCompleted).some((i) => i === true)) {
        handleAccept(
          result.data.uuidData,
          result.data.jobData,
          result.data.candidateData,
          result.data.recruiterData
        );
      } else {
        let result2 = await post("/submitcandidate/changeVettingStatus", {
          mapperId: result.data.payrateObj.mapperId,
        });
        if (result2.status === 200) {
          setStatus("Vetting Pending");
          setPendingVetting(final.map((i) => i.testAssign.skills[0]));
          setLoading(false);
        }
      }
    } else {
      handleAccept(
        result.data.uuidData,
        result.data.jobData,
        result.data.candidateData,
        result.data.recruiterData
      );
    }
    setJobData(result.data.jobData);
  };

  const handleAccept = async (
    uuidData,
    jobData,
    candidateData,
    recruiterData
  ) => {
    // console.log(payrateObj.statusName)
    // if()
    const payload = {
      uuid: props.match.params.Id,
      email: uuidData.candidateemail,
      CandidateID: uuidData.CandidateID,
    };
    // console.log()
    console.log(uuidData);
    let result = await post("/uuidValidate/userExists", payload);
    console.log(result);

    if (
      result.status === 200 &&
      result.data.PermanentCandidate === "NotExists"
    ) {
      const payload = {
        jobData: jobData,
        candidateData: candidateData,
        recruiterData: recruiterData,
        domainName: domain,
        recruiterName: getCandidateName(),
      };
      try {
        let response = await post("/acceptrejectrtr/rtrApprove", payload);
        if (response.status === 200) {
          setStatus("Submitted Now");
          //setPayrateObj({ ...payrateObj, statusName: "Responded" });
          // toast.success("RTR Submitted Successfully");
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
        } else {
          toast.error("Oops! something went wrong. please try again later");
        }
      } catch (error) {
        console.log(error);
        toast.error("Oops! something went wrong. please try again later");
      }
    } else {
      const payload = {
        jobData: jobData,
        candidateData: candidateData,
        recruiterData: recruiterData,
        domainName: domain,
        recruiterName: getCandidateName(),
      };
      try {
        let response = await post(
          "/acceptrejectrtr/rtrApproveExistUsers",
          payload
        );
        //let response = 200
        if (response.status === 200) {
          setStatus("Submitted Now");
          // toast.success("RTR Submitted Successfully");
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
        } else {
          toast.error("Oops! something went wrong. please try again later");
        }
      } catch (error) {
        toast.error("Oops! something went wrong. please try again later");
      }
    }
    setLoading(false);
  };

  const handleReject = async () => {
    console.log(payrateObj.statusName);
    setLoading(true);
    const payload = {
      jobData: jobData,
      candidateData: candidateData,
      recruiterData: recruiterData,
      domainName: domain,
    };
    try {
      let response = await post("/acceptrejectrtr/rtrReject", payload);
      if (response.status === 200) {
        setPayrateObj({ ...payrateObj, statusName: "Responded" });
        setLoading(false);
        toast.success("RTR Rejected Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        setLoading(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  let jobLocation = "";
  if (Object.keys(jobData.fullText).length !== 0) {
    jobData.fullText.location.city && jobData.fullText.location.state
      ? (jobLocation = `${jobData.fullText.location.city}, ${jobData.fullText.location.state}`)
      : jobData.fullText.location.state
      ? (jobLocation = `${jobData.fullText.location.state}`)
      : jobData.fullText.location.city &&
        (jobLocation = `${jobData.fullText.location.city}`);
  }

  const dateDiff = getPreciseDateDiff(
    new Date(),
    new Date(jobData.jobPostDate)
  );

  return (
    <>
      {loading && <Loader />}
      {false && (
        <body>
          <div id="Content">
            <section id="TestApp">
              <div className="hero">
                <div className="section pl-0" style={{ padding: "50px" }}>
                  <div className="row justify-content-center mb-0">
                    <div className="d-flex justify-content-between align-items-center col-3 font-white">
                      <h4 className="mb-0">{jobData.fullText.jobTitle}</h4>
                    </div>
                  </div>
                </div>
              </div>
              <div class="page-content">
                <div class="container">
                  <div class="row">
                    <div class="col-md-9">
                      <div class="page-content-wrapper">
                        <div class="Sspace">
                          {payrateObj.statusName === "Submitted" && (
                            <div className="d-flex align-item-center justify-content-center tag tag-green1">
                              RTR Accepted -{" "}
                              {moment(
                                new Date(payrateObj.submittedToClientDate),
                                "YYYYMMDD"
                              ).fromNow()}
                            </div>
                          )}
                          {payrateObj.statusName === "RTR Rejected" && (
                            <div className="d-flex align-item-center justify-content-center tag tag-red1">
                              RTR Rejected -{" "}
                              {moment(
                                new Date(payrateObj.rtrRejectedDate),
                                "YYYYMMDD"
                              ).fromNow()}
                            </div>
                          )}
                        </div>
                        <div class="jobsort">
                          <div className="row">
                            <div className="col-md-12">
                              Hi {candidateData.fullText.firstName},<br />
                              <br />
                              As per our conversation, I would like to submit
                              your profile for the position of
                              <strong> {jobData.fullText.jobTitle}</strong> with
                              <strong>
                                {" "}
                                {jobData.fullText.tenantName}
                              </strong>{" "}
                              based in
                              <strong> {jobLocation}</strong> on Payrate
                              <strong>
                                {" "}
                                {payrateObj.payRate}{" "}
                                {payrateObj.payRateCurrency}
                              </strong>
                              . As a requirement, please read the email content
                              carefully and approve the request for your profile
                              to the client In case you have applied for the
                              same job earlier, please inform us immediately.
                            </div>
                          </div>
                          <div class="Sspace"></div>
                          <div className="row">
                            <div class="col-md-12">
                              {payrateObj.statusName === "Sourced" && (
                                <div class="jobviewbtndiv">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-primary me-2"
                                    style={{ margin: "0px 10px" }}
                                    onClick={handleAccept}
                                  >
                                    <span> Approve </span>{" "}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-danger"
                                    onClick={handleReject}
                                  >
                                    <span> Reject </span>
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div class="Sspace"></div>
                          <div class="jobsortheading">
                            <div class="row">
                              <div class="col-md-12">
                                <h5>{jobData.fullText.jobTitle}</h5>
                              </div>
                            </div>
                          </div>
                          <div class="clientdiv">
                            <div class="clienttext">
                              <h6>{jobData.fullText.jobTenant}</h6>
                            </div>
                            <div class="jobview"></div>
                            <ul></ul>
                          </div>
                          <div class="jobviewpostdiv">
                            <div class="row">
                              <div class="col-md-4">
                                <div class="jobviewpostopen">
                                  Posted on : &nbsp;
                                  <span>
                                    {moment(jobData.createdDate).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </span>
                                </div>
                              </div>
                              <div class="col-md-2">
                                <div class="jobviewpostopen">
                                  Opening : &nbsp;
                                  <span>{jobData.positionCount}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="Sspace"></div>
                          <div class="deptdetails">
                            <div class="jobdetails">
                              <h5>Job Description</h5>
                            </div>
                            <div class="jobdetaillist">
                              <ul>
                                <li>
                                  <p>
                                    <ol
                                      className="pr-3"
                                      dangerouslySetInnerHTML={{
                                        __html: jobData.jobDescription,
                                      }}
                                    ></ol>
                                  </p>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="linediv"></div>
                          <div class="jdexpandiv">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="jobviewpostopen">
                                  Job Title : &nbsp;
                                  <span>
                                    <strong>{jobData.fullText.jobTitle}</strong>
                                  </span>
                                </div>
                              </div>
                              <div class="col-md-12">
                                <div class="jobviewpostopen">
                                  Position Type : &nbsp;
                                  <span>
                                    <strong>{jobData.jobType}</strong>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="linediv"></div>
                          <div class="Sspace"></div>
                          <div class="jdexpandiv">
                            <div class="deptdetails">
                              <div class="row">
                                <div class="col-md-12">
                                  <div class="jobviewpostopen">
                                    Skills : &nbsp;
                                    <span>
                                      <strong>
                                        {jobData.fullText.primarySkills &&
                                          jobData.fullText.primarySkills.join(
                                            ", "
                                          )}
                                      </strong>
                                    </span>
                                  </div>
                                </div>
                                {jobData.fullText.secondarySkills > 0 && (
                                  <div class="col-md-12">
                                    <div class="jobviewpostopen">
                                      Secondary Skills : &nbsp;
                                      <span>
                                        <strong>
                                          {jobData.fullText.secondarySkills &&
                                            jobData.fullText.secondarySkills.join(
                                              ", "
                                            )}
                                        </strong>
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {jobData.skillSet > 0 && (
                                  <div className="col-md-12">
                                    <div className="jobviewpostopen">
                                      Skill set : &nbsp;
                                      <span>
                                        <strong>
                                          {jobData.skillSet &&
                                            jobData.skillSet.join(", ")}
                                        </strong>
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                          {/* <div class="Sspace"></div>
                                                    <div class="col-md-12">
                                                        {payrateObj.statusName === 'Sourced' && (<div class="jobviewbtndiv">
                                                            <button type="button" className="btn btn-secondary btn-sm" style={{ margin: '0px 10px' }} onClick={handleAccept}><span> Approve </span> </button>
                                                            <button type="button" className="btn btn-secondary btn-sm" onClick={handleReject}><span> Reject </span></button>
                                                        </div>)}
                                                    </div> */}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="Sspace"></div>
                      <div class="about company">
                        <div class="clienttext">
                          <h5>About Company</h5>
                        </div>
                        <div class="aboutcompanylogo">
                          <img src={TenantImage} alt="blank"></img>
                        </div>
                        <div class="aboutcompanyname">
                          <h5>{jobData.fullText.jobTenant}</h5>
                          <div class="Sspace"></div>
                          <h5>Company Info</h5>
                          <p>{resultData.aboutus}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {getTenantType() == 1 && <Footer />}
          </div>
        </body>
      )}

      {status &&
        (status === "Submitted Now" ? (
          <div className="bl-modal reveal-overlay reveal-card">
            <div className="bl-modal-overlay"></div>
            <div className="bl-modal-container">
              <div className="bl-modal-card bl-modal-card-md">
                <div className="bl-modal-body">
                  <div className="success-animation">
                    <div className="checkmark mt-3">
                      <Lottie
                        loop={loop}
                        animationData={checkMark}
                        play
                      ></Lottie>
                    </div>
                    <Anime
                      initial={[
                        {
                          targets: "#LottieSparkles",
                          opacity: 0,
                          delay: 1000,
                        },
                      ]}
                    >
                      <div className="sparkles">
                        <Lottie
                          loop={loop}
                          animationData={sparkles}
                          id="LottieSparkles"
                          play
                        ></Lottie>
                      </div>
                    </Anime>
                  </div>
                  <div className="text-center mb-3">
                    <h4 className="text-center">
                      Your Profile is Submitted Successfully
                    </h4>
                    <div className="text-center mt-4">
                      <div className="card card-flat bg-gray4">
                        <div className="card-body">
                          <h6>Job Title: {jobData.jobTitle}</h6>
                          <h6>Job Type: {jobData.jobType}</h6>
                          <h6>
                            Location:{" "}
                            {jobData.isRemote
                              ? "Remote"
                              : (jobData.city, jobData.state, jobData.country)}
                          </h6>
                          <h6>
                            Pay Rate: {jobData.minimumPay} -{" "}
                            {jobData.maximumPay} {jobData.salaryCurrency}{" "}
                            {jobData.salaryType === "Annual"
                              ? "per year"
                              : "per hour"}
                            <br />
                          </h6>
                          <h6>
                            Start Date:{" "}
                            {moment(jobData.prefferedStartDate).format(
                              "MM/DD/YYYY"
                            )}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : status === "Submitted" ? (
          <>
            <div className="bl-modal reveal-overlay reveal-card">
              <div className="bl-modal-overlay"></div>
              <div className="bl-modal-container">
                <div className="bl-modal-card bl-modal-card-md">
                  <div className="bl-modal-body text-center">
                    <div className="avatar avatar-lg avatar-red2 mr-3 mb-3">
                      <i class="fas fa-exclamation-triangle fa-fw"></i>
                    </div>
                    <div className="mb-3">
                      <h4>Your Profile is Already Submitted</h4>
                      <div className="text-center mt-4">
                        <div className="card card-flat bg-gray4">
                          <div className="card-body">
                            <h6>Job Title: {jobData.jobTitle}</h6>
                            <h6>Job Type: {jobData.jobType}</h6>
                            <h6>
                              Location:{" "}
                              {jobData.isRemote
                                ? "Remote"
                                : (jobData.city,
                                  jobData.state,
                                  jobData.country)}
                            </h6>
                            <h6>
                              Pay Rate: {jobData.minimumPay} -{" "}
                              {jobData.maximumPay} {jobData.salaryCurrency}{" "}
                              {jobData.salaryType === "Annual"
                                ? "per year"
                                : "per hour"}
                              <br />
                            </h6>
                            <h6>
                              Start Date:{" "}
                              {moment(jobData.prefferedStartDate).format(
                                "MM/DD/YYYY"
                              )}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          status === "Vetting Pending" && (
            <>
              <div className="bl-modal reveal-overlay reveal-card">
                <div className="bl-modal-overlay"></div>
                <div className="bl-modal-container">
                  <div className="bl-modal-card bl-modal-card-md">
                    <div className="bl-modal-body text-center">
                      <div className="avatar avatar-lg avatar-red2 mr-3 mb-3">
                        <i class="fas fa-exclamation-triangle fa-fw"></i>
                      </div>
                      <div className="mb-3">
                        <h4>Vetting is Pending</h4>
                        <div className="text-center mt-4">
                          <div className="card card-flat bg-gray4 m-2">
                            <div className="card-body">
                              Skills:{" "}
                              <strong>
                                {pendingVetting.length > 0
                                  ? pendingVetting.join(", ")
                                  : pendingVetting[0]}
                              </strong>
                            </div>
                          </div>
                          <p>Please complete the test and come back here</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        ))}
    </>
  );
}
export default AcceptRejectRTR;
