import React, { useEffect, useState } from "react"
import moment from 'moment'

function SubmitCandidateTableView(props) {

  const [ newRequestList, setnewRequestList ] = useState([])
  const [ currentPage, setCurrentPage] = useState(1)
  // const [ requestTitleLength, setRequestTitleLength ] = useState(0)
  const [ requestTitleArray, setRequestTitleArray ] = useState([])
  const [ pageType, setPageType ] = useState('')
  let pageSize = 5
  let dataSize = 4
  const [ paginationGroup, setPaginationGroup ] = useState([])

  useEffect(() => {
    if(pageType !== '') {
      if(pageType === 'up') {      
        if((currentPage-1)%5 === 0 && (currentPage !== 1)) {
          setPaginationGroup(requestTitleArray.slice(currentPage-1, currentPage+(pageSize-1)))
        }
      }
      else {      
        if((currentPage%5 === 0) && (currentPage !== 1)) {
          setPaginationGroup(requestTitleArray.slice(currentPage-5, currentPage))
        }
      }
    }
    else {
      setnewRequestList(props.requestListData)
      setRequestTitleArray(new Array(Math.ceil(props.requestListData.length/dataSize)).fill().map((_, i) => i+1))
      setPaginationGroup(new Array(Math.ceil(props.requestListData.length/dataSize)).fill().map((_, i) => i+1).slice(0, pageSize))
    }   
  }, [props, currentPage])

  function changePage(event) {
      const pageNumber = Number(event.target.textContent)
      console.log(pageNumber)
      setCurrentPage(pageNumber)
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * 4 - 4
    const endIndex = startIndex + 4
    return newRequestList.slice(startIndex, endIndex)
  }

  return (
    <>
      <div className="row">
        <div class="col">
            <table class="table table-sortable table-hoverable">
                <thead>
                    <tr>                       
                        <td>
                            <h6>Title</h6>
                        </td>
                        <td>
                            <h6>Type</h6>
                        </td>
                        <td>
                            <h6>Location</h6>
                        </td>
                        <td>
                            <h6>Expiry Date</h6>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    {
                        newRequestList.length !== 0 ? (
                            getPaginatedData().map((request, i) => (
                                <tr>
                                    {/* <td class="p-relative" style={{ "width": "50px" }}>
                                        <div>                        
                                            <div class="form-check-square ml-1">
                                            <input 
                                                class="form-check-input" 
                                                type="checkbox" 
                                                checked={request.isSelect} 
                                                onChange={(e) => props.handleChange(e, i)}
                                            />
                                            </div>
                                        </div>
                                    </td> */}
                                    <td class="p-relative">
                                        <a 
                                            onClick={() => window.open(`/high5Hire/requests/${request.jobId}`, '_self')}
                                            class="font-primary no-hover font-regular"
                                        >
                                            <h6>
                                            {(request.isHotJob &&
                                                <span className="tag tag-coral p-1 mr-1">hot</span>)
                                            } {request.fullText.jobTitle}
                                            </h6>
                                        </a>
                                    </td>
                                    <td >
                                        <div>
                                            {                                               
                                                <a 
                                                    onClick={() => window.open(`/high5Hire/requests/${request.jobId}`, '_self')}
                                                    class="font-primary no-hover font-regular"
                                                >
                                                    {request.jobType.charAt(0).toUpperCase() + request.jobType.slice(1) }
                                                </a>                                               
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <a 
                                            onClick={() => window.open(`/high5Hire/requests/${request.jobId}`, '_self')}
                                            class="font-primary no-hover font-regular"
                                        >
                                            { request.location.city ? 
                                            <span className="d-flex align-items-center me-3">
                                                <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                                                <span className="font-regular font-primary font-gray1">
                                                {request.location.city}
                                                </span>
                                            </span> : 
                                            <span className="d-flex align-items-center me-3">
                                                <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                                                <span className="font-regular font-primary font-gray1">
                                                    Remote
                                                </span>
                                            </span> }
                                        </a>
                                    </td>
                                    <td >
                                        <a 
                                            onClick={() => window.open(`/high5Hire/requests/${request.jobId}`, '_self')}
                                            class="font-primary no-hover font-regular"
                                        >
                                        {
                                            moment(request.expiresOn).diff(new Date(), 'days') < 0 &&
                                                <span className="tag tag-red1">
                                                    <strong> Expired </strong>
                                                </span>
                                            }
                                            {
                                            moment(request.expiresOn).diff(new Date(), 'days') === 0 &&
                                                <span className="tag tag-red1">
                                                    <strong> Expires Today </strong>
                                                </span>
                                            }
                                            {
                                            moment(request.expiresOn).diff(new Date(), 'days') === 1 &&
                                                <span className="tag tag-red1">
                                                    <strong> 1 Day Left To Fill </strong>
                                                </span>
                                            }
                                            {
                                            moment(request.expiresOn).diff(new Date(), 'days') > 1 &&
                                            (moment(request.expiresOn).diff(new Date(), 'days') <= 5 ?
                                                <span className="tag tag-red1">
                                                    <strong> {moment(request.expiresOn).diff(new Date(), 'days')} Days Left To Fill </strong>
                                                </span> :
                                                <span className="font-regular">
                                                    <strong> {moment(request.expiresOn).diff(new Date(), 'days')} Days Left To Fill </strong>
                                                </span>)
                                        }
                                        </a>
                                    </td>                                   
                                    <td class="text-right">
                                        <button type="text"
                                            onClick={() => props.openSubmitModal(request)}
                                            class="btn btn-sm btn-secondary"
                                        >
                                            Invite
                                        </button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr class="text-center p-3">
                                <td colspan="6">
                                    <div class="avatar avatar-lg">
                                        <i class="fad fa-users-slash"></i>
                                    </div>
                                    <div class="mt-2">
                                        No Jobs Found
                                    </div>
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </table>
        </div>
        <div className='d-flex justify-content-end pt-4 px-1'>
          <div className="d-flex">  
              <div class="ml-auto mx-auto-370 d-flex align-items-center">    
                  <button 
                      onClick={() => {
                        setPageType('down')
                        setCurrentPage(page => page - 1)                       
                      }}
                      type="button" 
                      disabled={ currentPage === 1 ? true : false } 
                      className="btn btn-icon px-2"
                  >
                      <i className="fal fa-chevron-circle-left"></i>
                  </button>

                  {
                    paginationGroup.map((item) => (
                      <button 
                          type="button" 
                          className={ currentPage === item ? "btn btn-text px-2 bg-secondary text-white" : "btn btn-text px-2"}
                          onClick={changePage}
                      >
                        {item}
                      </button>
                    ))
                  }

                  <button 
                      onClick={() => {
                        setPageType('up')
                        setCurrentPage(page => page + 1)                       
                      }}
                      type="button" 
                      disabled={ currentPage === requestTitleArray.slice(-1)[0] ? true : false } 
                      className="btn btn-icon px-2"
                  >
                      <i className="fal fa-chevron-circle-right"></i>
                  </button>
              </div>
          </div>                           
        </div> 
      </div>
    </>
  )
}

export default SubmitCandidateTableView
