import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
// import HereMap from "../../../../location/CommonHereMap";
import Autosuggest from "react-autosuggest";
import { get } from "../../../service/ApiService";
import axios from "axios";
import PreferredLocation from "../../location/PreferredLocation";
// import PreferredLocation from "../../../../location/PreferredLocation";

const customStyles = {
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#dcedff",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

function ProfileInfoDS(props) {
  const [location, setLocation] = useState("");
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [form, setForm] = useState(null);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  const [secondarySkillsInput, setSecondarySkillSInput] = useState("");
  const [positionTypes, setPositionTypes] = useState([]);
  const [skillSet, setSkillSet] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState({
    Source: [],
    CurrencyTime: [],
    CandidateSkills: [],
    skillSet: [],
    ExperienceLevel: [],
  });
  const [positionTypeOption, setPositionTypeOption] = useState([]);
  const [skillSetOption, setSkillSetOption] = useState([]);
  const [experienceYear, setExperienceYear] = useState("");
  const [experienceMonth, setExperienceMonth] = useState("");

  useEffect(() => {
    setDropDownData(props.dropDownData);
    setPositionTypeOption(
      props.dropDownData.PositionType.map((item) => ({
        value: item.SourceName,
        label: item.SourceName,
      }))
    );
    setSkillSetOption(
      props.dropDownData.skillSet.map((item) => ({
        value: item.SourceName,
        label: item.SourceName,
      }))
    );
    // setPreferredLocation(props.profileInfo.preferedLocations)
  }, [props]);

  const visaStatus = [
    "Green Card",
    "H1B",
    "Citizen",
    "H4 EAD",
    "GC EAD",
    "L2EAD",
    "OPT EAD",
    "TN1",
    "TN2",
    "Not Required",
  ];

  const deleteLocation = (index) => {
    let current = [...props.profileInfo.preferedLocations];
    current.splice(index, 1);
    setPreferredLocation(current);
    props.changePreferredLocation(current);
  };

  // useEffect(() => {
  //     setSelectedValue(props.profileInfo.skillSet)
  //     setSelectedPosition(props.profileInfo.preferredPositionType)
  //   }, [])

  const handleLocation = () => {
    if (form !== null) {
      if (
        props.profileInfo.preferedLocations.findIndex(
          (location) => location === form.stateName + ", " + form.country
        ) === -1
      ) {
        let currentLocation = [...props.profileInfo.preferedLocations];
        currentLocation.push(form.stateName + ", " + form.country);
        // setPreferredLocation(currentLocation)
        props.changePreferredLocation(currentLocation);
      } else {
        toast.error("Oops! You have already added this Location");
      }
    } else {
      toast.error("Please Choose Location");
    }
  };

  // const handlePositiontype = (e) => {
  //     setPositionTypes(e)
  //     props.setProfileInfo({ ...props.profileInfo, positionTypes: e.map((positionTypes => positionTypes.value)) })
  // }

  const handleSkillSet = (e) => {
    setSkillSet(e);
    props.setProfileInfo({
      ...props.profileInfo,
      skillSet: e.map((skillSet) => skillSet.value),
    });
  };

  const handleChangePrimarySkills = (skills) => {
    props.setProfileInfo({ ...props.profileInfo, primarySkills: skills });
  };

  const handleChangeSecondarySkills = (skills) => {
    props.setProfileInfo({ ...props.profileInfo, secondarySkills: skills });
  };

  const handleChangeExperienceYear = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      experienceYear: e.target.value,
    });
  };

  const handleChangeExperienceMonth = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      experienceMonth: e.target.value,
    });
  };

  const handleChangeInput = (location) => {
    setLocation(location);
  };

  const handleChangeInputPrimarySkills = (skills) => {
    setPrimarySkillInput(skills);
  };

  const handleChangeInputSecondarySkills = (skills) => {
    setSecondarySkillSInput(skills);
  };

  const handleChange = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      [e.target.id]: e.target.value,
    });
  };

  const onRemoteSwitch = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      [e.target.id]: e.target.checked,
    });
  };

  const onIsfresherSwitch = (e) => {
    if (e.target.id == "isFresher" && e.target.checked === true) {
      // console.log(e.target.id,e.target.checked)
      props.setProfileInfo({
        ...props.profileInfo,
        experienceLevel: "Junior :0-3 years",
      });
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceLevel: "Junior :0-3 years",
      });
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceYear: "",
        experienceMonth: "",
        currentJobTitle: "",
      });
    } else {
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceLevel: "",
        experienceYear: "",
        experienceMonth: "",
        currentJobTitle: "",
      });
    }
  };

  //File upload block
  const setFileForSending = (file) => {
    const { name } = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      props.setProfileInfo({
        ...props.profileInfo,
        fileName: name,
        base64: event.target.result,
      });
      toast.success("File uploaded successfully");
    };
  };

  const onRemove = (e) => {
    props.setProfileInfo({ ...props.profileInfo, fileName: "", base64: "" });
    toast.success("File removed successfully");
  };

  // File upload on drag
  const onDragDropChangeHandler = (e) => {
    setFileForSending(e[0]);
  };

  // Auto suggestion in tags input
  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.SourceName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropDownData.CandidateSkills.filter((item) =>
              item.SourceName.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion.SourceName}</div>;

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.SourceName);
        }}
        theme={theme}
      />
    );
  };

  // useEffect(() => {
  //     console.log(form)
  // }, [form])

  return (
    <>
      <div>
        {loading ? (
          <h6 className="m-5">Loading...</h6>
        ) : (
          <div class="card-body">
            {/* <div class="row mb-2">
              <div class="col-lg-4">
                <div class="form-floating">
                  <select
                    id="source"
                    class="form-select"
                    onChange={handleChange}
                    value={props.profileInfo.source}
                  >
                    <option value="" selected>
                      Select Source
                    </option>
                    {dropDownData.Source.map((item) => (
                      <option value={item.SourceName}>{item.SourceName}</option>
                    ))}
                  </select>
                  <label>Source</label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-floating">
                  <select
                    id="visaStatus"
                    class="form-select"
                    onChange={handleChange}
                    value={props.profileInfo.visaStatus}
                  >
                    <option value="" selected>
                      Select Status
                    </option>
                    {visaStatus.map((item) => (
                      <option value={item}>{item}</option>
                    ))}
                  </select>
                  <label>Visa Status</label>
                </div>
              </div>
            </div> */}
            <div className="row mb-2">
              <div class="col-lg-6">
                <div class="form-floating ">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Job Title"
                    id="jobtitle"
                    disabled={props.profileInfo.isFresher}
                    value={props.profileInfo.currentJobTitle}
                    onChange={(e) =>
                      props.setProfileInfo({
                        ...props.profileInfo,
                        currentJobTitle: e.target.value,
                      })
                    }
                  />
                  <label>Your current job title*</label>
                </div>
                {props.profileInfo.isFresher === false && (
                  <small className="validation">
                    {props.profileInfoValidation.currentJobTitle}
                  </small>
                )}
              </div>
              <div class="col-lg-6">
                <div class="form-floating ">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Job Title"
                    id="jobtitle"
                    disabled={props.profileInfo.isFresher}
                    value={props.profileInfo.currentEmployer}
                    onChange={(e) =>
                      props.setProfileInfo({
                        ...props.profileInfo,
                        currentEmployer: e.target.value,
                      })
                    }
                  />
                  <label>Your current employer</label>
                </div>
                {/* {props.profileInfo.isFresher === false && (
                  <small className="validation">
                    {props.profileInfoValidation.currentJobTitle}
                  </small>
                )} */}
              </div>
            </div>
            {/* <div class="row mb-2">
              <div class="col-lg-12">
                <label>Social </label>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-4">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Faceebook ID"
                    id="facebook"
                    value={props.profileInfo.facebook}
                    onChange={handleChange}
                  />
                  <label>Facebook ID</label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Twitter ID"
                    id="twitter"
                    value={props.profileInfo.twitter}
                    onChange={handleChange}
                  />
                  <label>Twitter ID</label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Linkedin ID"
                    id="linkedIn"
                    value={props.profileInfo.linkedIn}
                    onChange={handleChange}
                  />
                  <label>Linkedin ID</label>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-12">
                <label>Rates </label>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-4">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Preferred Salary"
                    id="preferredSalary"
                    value={props.profileInfo.preferredSalary}
                    onChange={handleChange}
                  />
                  <label>Preferred Salary</label>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-floating">
                  <select
                    class="form-select"
                    id="preferredSalaryCurrency"
                    style={{
                      border:
                        props.profileInfoValidation.preferredSalaryCurrency &&
                        "2px solid #ff0000",
                    }}
                    value={props.profileInfo.preferredSalaryCurrency}
                    onChange={handleChange}
                  >
                    <option value="" selected>
                      Select Type
                    </option>
                    {dropDownData.CurrencyTime.map((item) => (
                      <option value={item.SourceName}>{item.SourceName}</option>
                    ))}
                  </select>
                  <label>Currency Type</label>
                </div>
                <small className="validation">
                  {props.profileInfoValidation.preferredSalaryCurrency}
                </small>
              </div>
            </div>
            <div class="row mb-2">
              <div class="col-lg-6 mt-2">
                <div
                  class="form-floating ml-auto"
                  style={{ margin: "15px 0px 0px 50px" }}
                >
                  <div className="form-check form-switch d-flex align-items-center ">
                    <label
                      className="form-check-label pt-0"
                      htmlFor="remoteSwitch"
                    >
                      Is Fresher?
                    </label>
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="isFresher"
                      checked={props.profileInfo.isFresher}
                      value={props.profileInfo.isFresher}
                      onChange={(e) => onIsfresherSwitch(e)}
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div class="row mb-12">
              <div class="col-lg-6">
                <label>Your total work experience*</label>
              </div>
              <div class="col-lg-6">
                <label>Social Media Link</label>
              </div>
            </div>
            <div class="row mb-12">
              <div class="col-lg-6">
                <div class="form-floating">
                  <div className="d-flex">
                    <div className="mr-3 form-floating">
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        max="100"
                        style={{
                          height: "57px",
                          border: "1px solid #ced4da",
                          fontSize: "15px",
                        }}
                        defaultValue={props.profileInfo.experienceYear}
                        name="years"
                        onChange={handleChangeExperienceYear}
                        placeholder="Year"
                        disabled={props.profileInfo.isFresher}
                      />
                      <label>Years</label>
                      {props.profileInfo.isFresher === false && (
                        <small className="validation">
                          {props.profileInfoValidation.experienceYear}
                        </small>
                      )}
                    </div>
                    <div className="mr-3 form-floating">
                      <input
                        type="number"
                        className="form-control"
                        min="0"
                        style={{
                          height: "57px",
                          border: "1px solid #ced4da",
                          fontSize: "15px",
                          width: "108%",
                        }}
                        name="months"
                        defaultValue={props.profileInfo.experienceMonth}
                        onChange={handleChangeExperienceMonth}
                        placeholder="Months"
                        disabled={props.profileInfo.isFresher}
                      />
                      <label>Months</label>
                      {props.profileInfo.isFresher === false && (
                        <small className="validation">
                          {props.profileInfoValidation.experienceMonth}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-floating">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Linkedin ID"
                    id="linkedIn"
                    value={props.profileInfo.linkedIn}
                    onChange={handleChange}
                  />
                  <label>Linkedin ID</label>
                </div>
              </div>
              {/* <div class="col-lg-6">
                <div class="form-floating ">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Job Title"
                    id="jobtitle"
                    disabled={props.profileInfo.isFresher}
                    value={props.profileInfo.currentJobTitle}
                    onChange={(e) =>
                      props.setProfileInfo({
                        ...props.profileInfo,
                        currentJobTitle: e.target.value,
                      })
                    }
                  />
                  <label>Job Title</label>
                </div>
                {props.profileInfo.isFresher === false && (
                  <small className="validation">
                    {props.profileInfoValidation.currentJobTitle}
                  </small>
                )}
              </div> */}
            </div>
            {/* <div class="row mb-2">
              <div class="col-12">
                <div class="mb-2">
                  <label>Position Types </label>
                </div>
                <Select
                  isMulti
                  // value={positionTypes}
                  value={positionTypeOption.filter((obj) =>
                    props.multiSelect.selectedTypes.includes(obj.value)
                  )}
                  //onChange={handlePositiontype}
                  onChange={(e) => {
                    // setSelectedPosition(Array.isArray(e) ? e.map(x => x.value) : [])
                    props.multiSelect.setSelectedTypes(e.map((x) => x.value));
                  }}
                  options={positionTypeOption}
                  styles={customStyles}
                  isSearchable={false}
                />
              </div>
            </div> */}
            {/* <div class="row mb-12 mt-2">
              <div class="col-12">
                <div class="mb-2 d-flex">
                  <label>Preferred Locations* </label>
                  <div
                    class="form-floating ml-auto"
                    style={{ margin: "15px 0px 0px 50px" }}
                  >
                    <div className="form-check form-switch d-flex align-items-center">
                      <label
                        className="form-check-label pt-0"
                        htmlFor="remoteSwitch"
                      >
                        Open For Remote
                      </label>
                      <input
                        className="form-check-input ml-3"
                        type="checkbox"
                        id="isFresher"
                        checked={props.profileInfo.isRemote}
                        onChange={(e) =>
                          props.setProfileInfo({
                            ...props.profileInfo,
                            isRemote: e.target.checked,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div class="form-inline-edit">
                    <div>
                      <div>
                        <div className="m-2">
                          {props.profileInfo.preferedLocations
                            .filter((i) => i !== "Remote")
                            .map((item, index) => (
                              <>
                                <span className="tag tag-blue3 mb-1 mr-1">
                                  {item}
                                  <button
                                    className="btn btn-text"
                                    onClick={() => deleteLocation(index)}
                                  >
                                    <strong className="font-18 font-black">
                                      &times;
                                    </strong>
                                  </button>
                                </span>
                              </>
                            ))}
                        </div>
                        <div className="d-flex align-items-center justify-content-center w-100">
                          <div className="form-floating w-100">
                         
                            <PreferredLocation
                              setLocation={(loc) => {
                                if (
                                  props.profileInfo.preferedLocations.includes(
                                    loc
                                  )
                                ) {
                                  toast.error(
                                    "You Have Already Added This Location"
                                  );
                                } else {
                                  setPreferredLocation((cur) => [...cur, loc]);
                                  props.changePreferredLocation([
                                    ...props.profileInfo.preferedLocations,
                                    loc,
                                  ]);
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <small className="validation">
                  {props.profileInfoValidation.preferredLocation}
                </small>
              </div>
            </div> */}
            {/* <div class="row mb-12">
                            <div class="col-12">
                                <div class="mb-2">
                                    <label>Preferred Locations* </label>
                                </div>
                                <div>
                                    <div class="form-inline-edit">                
                                    <div>
                                        <div>                   
                                        <div className="m-2">
                                            {
                                                props.profileInfo.preferedLocations.map((item, index) => (
                                                <>
                                                <span className="tag tag-blue3 mb-1 mr-1">
                                                    {item}
                                                    <button className="btn btn-text" onClick={() => deleteLocation(index)}>
                                                        <strong className="font-18 font-black">&times;</strong>
                                                    </button>
                                                </span>                            
                                                </>     
                                            ))
                                            }
                                        </div>
                                        <div className="d-flex align-items-center justify-content-center w-100">
                                            <div className="form-floating w-100">
                                                <HereMap
                                                    setForm={setForm}
                                                    form={form}   
                                                    component={'Candidate'}           
                                                />                       
                                            </div>
                                            <div className="ml-auto mr-1">
                                            <button
                                                onClick={() => {
                                                    // fetchDropDown()
                                                handleLocation()
                                                }}
                                                type="button"
                                                class="ml-1 btn btn-primary d-flex align-items-center"
                                            >
                                                <i class="fal fa-fw fa-check mr-1 mt-1"></i>
                                                Add
                                            </button>
                                            </div>                       
                                        </div>                                                               
                                        </div>                  
                                    </div>               
                                    </div>
                                </div>
                                <small className="validation">{props.profileInfoValidation.preferredLocation}</small>
                            </div>
                        </div> */}
            <div class="row mb-2">
              <div class="col-12">
                <div class="mb-2">
                  <label>Skill Sets* </label>
                </div>
                <TagsInput
                  renderInput={autocompleteRenderInput}
                  value={props.profileInfo.primarySkills}
                  onChange={handleChangePrimarySkills}
                  inputValue={primarySkillinput}
                  onChangeInput={handleChangeInputPrimarySkills}
                  placeholder="Type a location and hit enter"
                  inputProps={{
                    placeholder: "Add a skill",
                  }}
                />
              </div>
              <small className="validation">
                {props.profileInfoValidation.primarySkills}
              </small>
            </div>
            {/* <div class="row mb-2">
              <div class="col-12">
                <div class="mb-2">
                  <label>Secondary Skills </label>
                </div>
                <TagsInput
                  renderInput={autocompleteRenderInput}
                  value={props.profileInfo.secondarySkills}
                  onChange={handleChangeSecondarySkills}
                  inputValue={secondarySkillsInput}
                  onChangeInput={handleChangeInputSecondarySkills}
                  inputProps={{
                    placeholder: "Add a skill",
                  }}
                />
              </div>
            </div> */}
            {/* <div class="row mb-2">
              <div class="col-12">
                <div class="mb-2">
                  <label>Skillsets </label>
                </div>
                <Select
                  isMulti
                  // value={skillSet}
                  value={skillSetOption.filter((obj) =>
                    props.multiSelect.selectedValue.includes(obj.value)
                  )}
                  options={skillSetOption}
                  //onChange={handleSkillSet}
                  onChange={(e) => {
                    // setSelectedValue(Array.isArray(e) ? e.map(x => x.value) : [])
                    props.multiSelect.setSelectedValue(e.map((x) => x.value));
                  }}
                  styles={customStyles}
                  isSearchable={true}
                />
              </div>
            </div> */}
            <div class="row my-2">
              <div className="mb-2">
                <label>Description</label>
              </div>
              <div class="col-12">
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Description"
                    id="description"
                    value={props.profileInfo.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ProfileInfoDS;
