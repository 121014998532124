import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Loader from "../../../../Loader/Loader";
import { post } from "../../../../../service/ApiService";
import { toast } from "react-toastify";
import { domain, countryObj } from "../../../../../constants/Constants";
import moment from "moment";
import Avatar from "react-avatar";
import { getUserRole } from "../../../../../service/AuthService";
import NoRecord from "../../../commoncomponents/norecord/NoRecord";
import PaginationComponent from "../../../pagination/pagination";

function RecSidePanel(props) {
  let details = props.details;
  let prefix = props.prefix;
  let skill = details?.skills ? JSON.parse(details?.skills) : [];
  let languages = details?.languages ? JSON.parse(details?.languages) : [];
  let jobTitles = details?.jobTitle ? JSON.parse(details?.jobTitle) : [];
  let industries = details?.industries ? JSON.parse(details?.industries) : [];
  let subCategories = details?.subCategory
    ? JSON.parse(details?.subCategory)
    : [];
  let region = details?.regions ? JSON.parse(details?.regions) : [];
  let certification = details?.certification
    ? JSON.parse(details?.certification)
    : [];

  let client = details?.clients
    ? JSON.parse(details.clients).map((item) => item.tenantname)
    : [];

  let pools = details?.Pools ? JSON.parse(details.Pools) : [];

  let education = details?.education;

  const [generalDetails, setGeneralDetails] = useState(true);
  const [linkedIn, setLinkedIn] = useState(false);
  const [skills, setSkills] = useState(false);
  const [regions, setRegions] = useState(false);
  const [clients, setClients] = useState(false);
  const [community, setCommunity] = useState(true);
  const [certifications, setCertification] = useState(false);
  const [qualification, setQualifications] = useState(false);
  const [language, setLanguage] = useState(false);
  const [experience, setExperience] = useState(false);
  const [industry, setIndustry] = useState(false);
  const [subCategory, setsubCategory] = useState(false);
  const [jobTitle, setJobTitle] = useState(false);
  const [referred, setReferred] = useState(false);
  const [company, setCompany] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reject, setReject] = useState(false);
  const [remove, setDelete] = useState(false);
  const [deactivate, setDeactivate] = useState(false);
  const [activate, setActivate] = useState(false);
  const [rejectionReason, setRejectionReason] = useState("");
  const [reason, setReason] = useState(false);
  const [removeChecked, setRemoveChecked] = useState("");
  const [approveModal, setApproveModal] = useState(false);
  const [testId, setTestId] = useState([]);
  const [general, setGeneral] = useState(true);
  const [communities, setCommunities] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const startIndex = (currentPage - 1) * 5;
  const endIndex = startIndex + 5;
  const currentData = pools.slice(startIndex, endIndex);

  let status = details.status;

  const onCollapsibleClick = (category) => {
    if (category === "generalDetails") {
      setGeneralDetails(!generalDetails);
    }
    if (category === "linkedIn") {
      setLinkedIn(!linkedIn);
    }
    if (category === "skills") {
      setSkills(!skills);
    }
    if (category === "status") {
      setLinkedIn(!linkedIn);
    }
    if (category === "language") {
      setLanguage(!language);
    }
    if (category === "experience") {
      setExperience(!experience);
    }
    if (category === "industry") {
      setIndustry(!industry);
    }
    if (category === "subCategory") {
      setsubCategory(!subCategory);
    }
    if (category === "jobTitle") {
      setJobTitle(!jobTitle);
    }
    if (category === "regions") {
      setRegions(!regions);
    }
    if (category === "certifications") {
      setCertification(!certifications);
    }

    if (category === "referred") {
      setReferred(!referred);
    }
    if (category === "clients") {
      setClients(!clients);
    }
    if (category === "qualification") {
      setQualifications(!qualification);
    }
    if (category === "community") {
      setCommunity(!community);
    }
  };

  const activateRecruiter = async () => {
    setLoading(true);
    const res = await post("/gigrecruiter/activate", {
      email: details.email,
      firstName: details.firstName,
    });
    if (res.status === 200) {
      toast.success("Activated Successfully");
      props.getRecruiters();
      props.closePanel();
    }
  };

  const approve = async () => {
    try {
      setLoading(true);
      let res;
      if (details.companyName) {
        res = await post("/gigrecruiter/approveStaffing", {
          ...details,
          domainName: domain,
        });
      } else {
        res = await post("/gigrecruiter/approve", {
          ...details,
          domainName: domain,
        });
      }
      if (res.status === 200) {
        activate
          ? toast.success("Activated Successfully")
          : toast.success("Approved Successfully");
        props.getRecruiters();
        props.closePanel();
      }
      setApproveModal(false);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Some Error Occurred while Approving");
    }
  };

  const updateStatus = async (status) => {
    try {
      setLoading(true);
      let res = await post("/gigrecruiter/updateStatus", {
        ...details,
        status,
        rejectionReason,
        removeChecked,
      });
      if (res.status === 200) {
        toast.success(
          `${status === "Deactivate" ? "Deactivated" : status} Successfully`
        );
        props.getRecruiters();
        props.closePanel();
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  const checkLastLogin = (lastLoginDate) => {
    const currentDate = new Date();
    const threeWeeksAgo = new Date();
    threeWeeksAgo.setDate(currentDate.getDate() - 21);

    const lastLogin = new Date(lastLoginDate);

    if (lastLogin >= threeWeeksAgo && lastLogin <= currentDate) {
      return "active";
    } else {
      return "inActive";
    }
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      setRemoveChecked("Accepted");
    } else {
      setRemoveChecked("Rejected");
    }
  };

  const removeItemFromArray = (item) => {
    let tempArray = [...testId];
    const index = tempArray.indexOf(item);
    tempArray.splice(index, 1);
    setTestId(tempArray);
  };

  const updateTab = (type) => {
    if (type === "general") {
      setGeneral(true);
      setCommunities(false);
    } else {
      setGeneral(false);
      setCommunities(true);
    }
  };

  const handlePageChange = (currentPage) => {
    setCurrentPage(currentPage);
  };

  return (
    <>
      {loading && <Loader />}
      <div
        class="view-panel show"
        style={{ backgroundColor: "white", zIndex: "9999999" }}
      >
        {/* view-panel-header */}
        <div>
          <div class="px-3 py-2">
            <button
              type="button"
              class="btn btn-icon"
              onClick={props.closePanel}
            >
              <i aria-hidden="true" class="fal fa-times"></i>
            </button>
          </div>
          {details && (
            <div class="px-3 mt-2 mb-3">
              <div class="d-flex align-items-start flex-wrap-reverse">
                <div class="d-flex">
                  <Avatar
                    size="40"
                    name={details.firstName + " " + details.LastName}
                    round="80px"
                  />
                  <div class="ml-3">
                    <h5 class="mr-2 mt-2 mb-2">
                      {details.firstName + " " + details.LastName}
                    </h5>
                    {industries.length !== 0 && (
                      <>
                        {" "}
                        {props.isFrom == "LoginOverview" ? (
                          <div className="my-2">
                            {prefix === "Hyperactive" ? (
                              <span class="tag tag-green4">Hyperactive</span>
                            ) : prefix === "Active" ? (
                              <span class="tag tag-blue2">Active</span>
                            ) : prefix === "Inactive" ? (
                              <span class="tag tag-orange1">Inactive</span>
                            ) : prefix === "Unsubscribed" ? (
                              status === "Deactivate" ? (
                                <span class="tag tag-red2">Deactivated</span>
                              ) : (
                                <span class="tag tag-red2">Unsubscribed</span>
                              )
                            ) : prefix === "Others" ? (
                              <span class="tag tag-red2">Never Logged In</span>
                            ) : prefix === "Total" ? (
                              status === "Deactivate" ? (
                                <span class="tag tag-red2">Deactivated</span>
                              ) : status === "Invited" ? (
                                <span class="tag tag-blue3">Invited</span>
                              ) : status === "Pending" ? (
                                <span class="tag tag-orange1">Pending</span>
                              ) : status === "Approved" && details.LastLogin ? (
                                checkLastLogin(details.LastLogin) ==
                                "active" ? (
                                  <span class="tag tag-blue2">Active</span>
                                ) : (
                                  <span class="tag tag-orange1">Inactive</span>
                                )
                              ) : status === "Approved" ? (
                                <span class="tag tag-green4">Approved</span>
                              ) : (
                                <span class="tag">{status}</span>
                              )
                            ) : (
                              <span class="tag">{status}</span>
                            )}
                          </div>
                        ) : props.isFrom == "HighAdmin" ? (
                          status === "Deactivate" ? (
                            <span className="tag tag-red2">Deactivated</span>
                          ) : status === "Invited" ? (
                            <span className="tag tag-blue3">Invited</span>
                          ) : status === "Pending" ? (
                            <span className="tag tag-orange1">Pending</span>
                          ) : status === "Approved" ? (
                            details.recruiterStatus == "Hyperactive" ? (
                              <span class="tag tag-green4">Hyperactive</span>
                            ) : details.recruiterStatus == "Active" ? (
                              <span class="tag tag-blue2">Active</span>
                            ) : details.recruiterStatus == "Inactive" ? (
                              <span class="tag tag-orange1">Inactive</span>
                            ) : details.recruiterStatus == "Never Logged In" ? (
                              <span class="tag tag-red2">Never Logged In</span>
                            ) : (
                              <span className="tag tag-green4">Approved</span>
                            )
                          ) : (
                            <span className="tag">{status}</span>
                          )
                        ) : (
                          <div>
                            {status === "Deactivate" ? (
                              <span class="tag tag-red2">Deactivated</span>
                            ) : status === "Invited" ? (
                              <span class="tag tag-blue3">Invited</span>
                            ) : status === "Pending" ? (
                              <span class="tag tag-orange1">Pending</span>
                            ) : status === "Approved" && details.LastLogin ? (
                              checkLastLogin(details.LastLogin) == "active" ? (
                                <span class="tag tag-blue2">Active</span>
                              ) : (
                                <span class="tag tag-orange1">Inactive</span>
                              )
                            ) : status === "Approved" ? (
                              <span class="tag tag-green4">Approved</span>
                            ) : (
                              <span class="tag ">{status}</span>
                            )}
                          </div>
                        )}
                        <div>
                          {status === "Invited" && (
                            <span className="mt-2">
                              Invited on{" "}
                              {moment(details.createddatetime).format(
                                "MM/DD/YYYY"
                              )}
                            </span>
                          )}
                          {status === "Pending" && (
                            <span className="mt-2">
                              Form Filled on{" "}
                              {moment(details.updateddatetime).format(
                                "MM/DD/YYYY"
                              )}
                            </span>
                          )}
                          {status === "Rejected" && (
                            <span className="mt-2">
                              Rejected on{" "}
                              {moment(details.updateddatetime).format(
                                "MM/DD/YYYY"
                              )}
                            </span>
                          )}
                          {status === "Deactivate" && (
                            <span className="mt-2">
                              Deactivated on{" "}
                              {moment(details.updateddatetime).format(
                                "MM/DD/YYYY"
                              )}
                            </span>
                          )}
                          {status === "Approved" && details.LastLogin && (
                            <span className="mt-2">
                              Last logged in:{" "}
                              {moment(details.LastLogin).format("MM/DD/YYYY")}
                            </span>
                          )}{" "}
                        </div>
                      </>
                    )}
                  </div>
                </div>

                {getUserRole() === "CommunityManager" && (
                  <div className="ml-auto">
                    {status === "Pending" && (
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-sm btn-primary"
                          //onClick={approve}
                          onClick={() => setApproveModal(true)}
                        >
                          Approve
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm btn-danger ml-1"
                          onClick={() => setReject(true)}
                        >
                          Reject
                        </button>
                      </div>
                    )}
                    {status === "Invited" && (
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-sm btn-danger"
                          // onClick={() => updateStatus('Deleted')}
                          onClick={() => setDelete(true)}
                        >
                          Delete Invitation
                        </button>
                      </div>
                    )}
                    {status === "Approved" && !details.isFromRegRec && (
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-sm btn-danger"
                          //onClick={() => updateStatus('Deactivated')}
                          onClick={() => setDeactivate(true)}
                        >
                          Deactivate
                        </button>
                      </div>
                    )}
                    {status === "Deactivate" && (
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-sm "
                          onClick={() => setActivate(true)}
                        >
                          <i class="fa fa-key mr-2" aria-hidden="true"></i>{" "}
                          Activate
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="w-100" style={{ borderBottom: "1px solid #ccc" }}>
          <ul className="nav nav-tabs">
            <li className="nav-item mb-0 pb-0">
              <button
                type="button"
                className={general ? "nav-link active" : "nav-link"}
                onClick={() => {
                  updateTab("general");
                }}
              >
                <span>General</span>
              </button>
            </li>
            {pools.length > 0 && getUserRole() !== "CommunityManager" && (
              <li className="nav-item mb-0 pb-0">
                <button
                  type="button"
                  className={communities ? "nav-link active" : "nav-link"}
                  onClick={() => {
                    updateTab("communinties");
                  }}
                >
                  <span>Communities</span>
                </button>
              </li>
            )}
          </ul>
        </div>

        {general && details && (
          <div class="view-panel-body">
            <button
              onClick={() => {
                onCollapsibleClick("generalDetails");
              }}
              type="button"
              className="btn btn-collapsible mb-2"
            >
              {!generalDetails ? (
                <i className="fal fa-angle-up fa-fw mt-1"></i>
              ) : (
                <i className="fal fa-angle-down fa-fw"></i>
              )}
              <span>
                <small>General</small>
              </span>
            </button>
            <div
              className={generalDetails ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}
            >
              <div class="d-flex mb-2 pl-1 ml-2">
                <div>
                  <i className="fa fa-envelope"></i>
                </div>
                <div class="ml-2">
                  <p class=" mb-0">{details.email}</p>
                  <p class="mb-0">
                    <small>Email</small>
                  </p>
                </div>
              </div>
              {details.country && (
                <div class="d-flex mb-2 pl-1 ml-2">
                  <div>
                    <i className="fad fa-fw fa-map-marked-alt"></i>
                  </div>
                  <div class="ml-2">
                    <p class=" mb-0">
                      {details.city ? details.city : ""}
                      {details.state
                        ? (details.city ? ", " : "") + details.state
                        : ""}
                      {details.country
                        ? (details.city || details.state ? ", " : "") +
                          details.country
                        : ""}
                    </p>
                    <p class="mb-0">
                      <small>User Location</small>
                    </p>
                  </div>
                </div>
              )}
              {details.phoneNumber && (
                <div class="d-flex mb-2 pl-1 ml-2">
                  <div>
                    <i class="fas fa-phone"></i>
                  </div>
                  <div class="ml-2">
                    <p class="mb-0">
                      {details.phoneCode
                        ? `${details.phoneCode} ${details.phoneNumber}`
                        : `+${details.phoneNumber}`}
                    </p>
                    <p class="mb-0">
                      <small>Phone Number</small>
                    </p>
                  </div>
                </div>
              )}
              {details?.homePhone && (
                <div class="d-flex mb-2 pl-1 ml-2">
                  <div>
                    <i class="fas fa-phone"></i>
                  </div>
                  <div class="ml-2">
                    <p class="mb-0">
                      {details.homePhoneCode
                        ? `${details.homePhoneCode} ${details.homePhone}`
                        : `+${details.homePhone}`}
                    </p>
                    <p class="mb-0">
                      <small>Home Phone</small>
                    </p>
                  </div>
                </div>
              )}
              {details.companyName && (
                <div class="d-flex mb-2 pl-1 ml-2">
                  <div>
                    <i class="fas fa-building"></i>
                  </div>
                  <div class="ml-2">
                    <p class="mb-0">{details.companyName}</p>
                    <p class="mb-0">
                      <small>Company Name</small>
                    </p>
                  </div>
                </div>
              )}
            </div>
            {details.linkedInAddress && (
              <button
                onClick={() => {
                  onCollapsibleClick("linkedIn");
                }}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!linkedIn ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>LinkedIn URL</small>
                </span>
              </button>
            )}
            <div className={linkedIn ? "ml-2 ml-0-600 mt-2 mb-2" : "d-none"}>
              <div class="d-flex pl-1 ml-4">
                <p>
                  <a target="_blank" href={details.linkedInAddress}>
                    {details.linkedInAddress}
                  </a>
                </p>
              </div>
            </div>
            {skill.length > 0 && (
              <button
                onClick={() => {
                  onCollapsibleClick("skills");
                }}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!skills ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>Roles</small>
                </span>
              </button>
            )}

            <div className={skills ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
              <div class="d-flex mb-2 pl-1 ml-4  ">
                <div>
                  {skill?.map(
                    (item, index) =>
                      (index < 2 || testId.includes("skills")) && (
                        <div
                          key={index}
                          className="tag tag-blue3"
                          style={{ margin: "2px" }}
                        >
                          <span>{item}</span>
                        </div>
                      )
                  )}

                  {!testId.includes("skills") ? (
                    skill?.length > 2 && (
                      <div
                        className="tag"
                        onClick={() => setTestId([...testId, "skills"])}
                        style={{ margin: "2px", cursor: "pointer" }}
                      >
                        +{skill?.length - 2} more
                      </div>
                    )
                  ) : (
                    <div
                      className="tag"
                      onClick={() => removeItemFromArray("skills")}
                      style={{ margin: "2px", cursor: "pointer" }}
                    >
                      Show less
                    </div>
                  )}
                </div>
              </div>
            </div>

            {education && (
              <button
                onClick={() => {
                  onCollapsibleClick("qualification");
                }}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!qualification ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>Education</small>
                </span>
              </button>
            )}
            <div
              className={qualification ? "ml-2 ml-0-600 mt-2 mb-1" : "d-none"}
            >
              <div class="d-flex mb-1 pl-1 ml-4">
                <p>{education}</p>
              </div>
            </div>

            {certification.length > 0 && (
              <button
                onClick={() => {
                  onCollapsibleClick("certifications");
                }}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!certifications ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>Certifications</small>
                </span>
              </button>
            )}
            <div
              className={certifications ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}
            >
              <div class="d-flex mb-2 pl-1 ml-4">
                <div>
                  {certification?.map(
                    (item, index) =>
                      (index < 2 || testId.includes("certifications")) && (
                        <div
                          key={index}
                          className="tag tag-blue3"
                          style={{ margin: "2px" }}
                        >
                          <span>{item}</span>
                        </div>
                      )
                  )}
                  {!testId.includes("certifications") ? (
                    certification?.length > 2 && (
                      <div
                        className="tag"
                        onClick={() => setTestId([...testId, "certifications"])}
                        style={{ margin: "2px", cursor: "pointer" }}
                      >
                        +{certification?.length - 2} more
                      </div>
                    )
                  ) : (
                    <div
                      className="tag"
                      onClick={() => removeItemFromArray("certifications")}
                      style={{ margin: "2px", cursor: "pointer" }}
                    >
                      Show less
                    </div>
                  )}
                </div>
              </div>
            </div>

            {region.length > 0 && (
              <button
                onClick={() => {
                  onCollapsibleClick("regions");
                }}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!regions ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>Regions</small>
                </span>
              </button>
            )}
            <div className={regions ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
              <div class="d-flex mb-2 pl-1 ml-4">
                <div>
                  {region?.map(
                    (item, index) =>
                      (index < 2 || testId.includes("regions")) && (
                        <div
                          key={index}
                          className="tag tag-blue3"
                          style={{ margin: "2px" }}
                        >
                          <span>{item}</span>
                        </div>
                      )
                  )}

                  {!testId.includes("regions") ? (
                    region?.length > 2 && (
                      <div
                        className="tag"
                        onClick={() => setTestId([...testId, "regions"])}
                        style={{ margin: "2px", cursor: "pointer" }}
                      >
                        +{region?.length - 2} more
                      </div>
                    )
                  ) : (
                    <div
                      className="tag"
                      onClick={() => removeItemFromArray("regions")}
                      style={{ margin: "2px", cursor: "pointer" }}
                    >
                      Show less
                    </div>
                  )}
                </div>
              </div>
            </div>

            {props.isFrom === "High5Admin" && (
              <div>
                {client?.length > 0 && (
                  <button
                    onClick={() => {
                      onCollapsibleClick("clients");
                    }}
                    type="button"
                    className="btn btn-collapsible mb-2"
                  >
                    {!clients ? (
                      <i className="fal fa-angle-up fa-fw mt-1"></i>
                    ) : (
                      <i className="fal fa-angle-down fa-fw"></i>
                    )}
                    <span>
                      <small>Clients</small>
                    </span>
                  </button>
                )}
                <div className={clients ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
                  <div class="d-flex mb-2 pl-1 ml-4">
                    <div>
                      {client?.map(
                        (item, index) =>
                          (index < 2 || testId.includes("clients")) && (
                            <div
                              key={index}
                              className="tag tag-blue3"
                              style={{ margin: "2px" }}
                            >
                              <span>{item}</span>
                            </div>
                          )
                      )}

                      {!testId.includes("clients") ? (
                        client?.length > 2 && (
                          <div
                            className="tag"
                            onClick={() => setTestId([...testId, "clients"])}
                            style={{ margin: "2px", cursor: "pointer" }}
                          >
                            +{client?.length - 2} more
                          </div>
                        )
                      ) : (
                        <div
                          className="tag"
                          onClick={() => removeItemFromArray("clients")}
                          style={{ margin: "2px", cursor: "pointer" }}
                        >
                          Show less
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {languages.length > 0 && (
              <button
                onClick={() => {
                  onCollapsibleClick("language");
                }}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!language ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>Language</small>
                </span>
              </button>
            )}
            <div className={language ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
              <div class="d-flex mb-2 pl-1 ml-4">
                <div>
                  {languages?.map(
                    (item, index) =>
                      (index < 2 || testId.includes("languages")) && (
                        <div
                          key={index}
                          className="tag tag-blue3"
                          style={{ margin: "2px" }}
                        >
                          <span>{item}</span>
                        </div>
                      )
                  )}

                  {!testId.includes("languages") ? (
                    languages?.length > 2 && (
                      <div
                        className="tag"
                        onClick={() => setTestId([...testId, "languages"])}
                        style={{ margin: "2px", cursor: "pointer" }}
                      >
                        +{languages?.length - 2} more
                      </div>
                    )
                  ) : (
                    <div
                      className="tag"
                      onClick={() => removeItemFromArray("languages")}
                      style={{ margin: "2px", cursor: "pointer" }}
                    >
                      Show less
                    </div>
                  )}
                </div>
              </div>
            </div>
            {status !== "Invited" && details.yearsOfExperience && (
              <button
                onClick={() => {
                  onCollapsibleClick("experience");
                }}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!experience ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>Experience</small>
                </span>
              </button>
            )}
            <div className={experience ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
              <div class="d-flex mb-2 pl-1 ml-4">
                <div>
                  {details.yearsOfExperience === "0" && (
                    <span>1-3 years (Junior Recruiter)</span>
                  )}
                  {details.yearsOfExperience === "0-1" && (
                    <span>1-3 years (Junior Recruiter)</span>
                  )}
                  {details.yearsOfExperience === "1-3" && (
                    <span>1-3 years (Junior Recruiter)</span>
                  )}
                  {details.yearsOfExperience === "1-5" && (
                    <span>3-5 years (Midlevel Recruiter)</span>
                  )}
                  {details.yearsOfExperience === "3-5" && (
                    <span>3-5 years (Midlevel Recruiter)</span>
                  )}
                  {details.yearsOfExperience === "5-10" && (
                    <span>5+ years (Senior Recruiter)</span>
                  )}
                  {details.yearsOfExperience === "10-20" && (
                    <span>5+ years (Senior Recruiter)</span>
                  )}
                  {details.yearsOfExperience === "5+" && (
                    <span>5+ years (Senior Recruiter)</span>
                  )}
                  {details.yearsOfExperience === "7+" && (
                    <span>5+ years (Senior Recruiter)</span>
                  )}
                  {details.yearsOfExperience === "20+" && (
                    <span>5+ years (Senior Recruiter)</span>
                  )}
                </div>
              </div>
            </div>

            {industries.length > 0 && (
              <button
                onClick={() => {
                  onCollapsibleClick("industry");
                }}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!industry ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>Job Category</small>
                </span>
              </button>
            )}
            <div className={industry ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
              <div class="d-flex mb-2 pl-1 ml-4">
                <div>
                  {industries?.map(
                    (item, index) =>
                      (index < 2 || testId.includes("industries")) && (
                        <div
                          key={index}
                          className="tag tag-blue3"
                          style={{ margin: "2px" }}
                        >
                          <span>{item}</span>
                        </div>
                      )
                  )}

                  {!testId.includes("industries") ? (
                    industries?.length > 2 && (
                      <div
                        className="tag"
                        onClick={() => setTestId([...testId, "industries"])}
                        style={{ margin: "2px", cursor: "pointer" }}
                      >
                        +{industries?.length - 2} more
                      </div>
                    )
                  ) : (
                    <div
                      className="tag"
                      onClick={() => removeItemFromArray("industries")}
                      style={{ margin: "2px", cursor: "pointer" }}
                    >
                      Show less
                    </div>
                  )}
                </div>
              </div>
            </div>
            {subCategories.length > 0 && (
              <button
                onClick={() => {
                  onCollapsibleClick("subCategory");
                }}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!subCategory ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>Sub Category</small>
                </span>
              </button>
            )}
            <div className={subCategory ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
              <div class="d-flex mb-2 pl-1 ml-4">
                <div>
                  {subCategories?.map(
                    (item, index) =>
                      (index < 2 || testId.includes("subCategories")) && (
                        <div
                          key={index}
                          className="tag tag-blue3"
                          style={{ margin: "2px" }}
                        >
                          <span>{item}</span>
                        </div>
                      )
                  )}

                  {!testId.includes("subCategories") ? (
                    subCategories?.length > 2 && (
                      <div
                        className="tag"
                        onClick={() => setTestId([...testId, "subCategories"])}
                        style={{ margin: "2px", cursor: "pointer" }}
                      >
                        +{subCategories?.length - 2} more
                      </div>
                    )
                  ) : (
                    <div
                      className="tag"
                      onClick={() => removeItemFromArray("subCategories")}
                      style={{ margin: "2px", cursor: "pointer" }}
                    >
                      Show less
                    </div>
                  )}
                </div>
              </div>
            </div>
            {typeof details?.isreferred === "string" &&
              details.isreferred.trim().length > 0 && (
                <button
                  onClick={() => {
                    onCollapsibleClick("referred");
                  }}
                  type="button"
                  className="btn btn-collapsible mb-2"
                >
                  {!referred ? (
                    <i className="fal fa-angle-up fa-fw mt-1"></i>
                  ) : (
                    <i className="fal fa-angle-down fa-fw"></i>
                  )}
                  <span>
                    <small>Referred By</small>
                  </span>
                </button>
              )}

            <div className={referred ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
              <div class="d-flex mb-2 pl-1 ml-4">{details.isreferred}</div>
            </div>
            {jobTitles &&
              ((typeof jobTitles.title1 === "string" &&
                jobTitles.title1.trim().length > 0) ||
                (typeof jobTitles.title2 === "string" &&
                  jobTitles.title2.trim().length > 0) ||
                (typeof jobTitles.title3 === "string" &&
                  jobTitles.title3.trim().length > 0)) && (
                <button
                  onClick={() => {
                    onCollapsibleClick("jobTitle");
                  }}
                  type="button"
                  className="btn btn-collapsible mb-2"
                >
                  {!jobTitle ? (
                    <i className="fal fa-angle-up fa-fw mt-1"></i>
                  ) : (
                    <i className="fal fa-angle-down fa-fw"></i>
                  )}
                  <span>
                    <small>Job Title</small>
                  </span>
                </button>
              )}
            {jobTitles &&
              ((typeof jobTitles.title1 === "string" &&
                jobTitles.title1.trim().length > 0) ||
                (typeof jobTitles.title2 === "string" &&
                  jobTitles.title2.trim().length > 0) ||
                (typeof jobTitles.title3 === "string" &&
                  jobTitles.title3.trim().length > 0)) && (
                <div
                  className={jobTitle ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}
                >
                  <div className="d-flex mb-2 pl-1 ml-4">
                    <div>
                      {typeof jobTitles.title1 === "string" &&
                        jobTitles.title1.trim().length > 0 && (
                          <div className="mb-1">
                            <span>
                              {((typeof jobTitles.title2 === "string" &&
                                jobTitles.title2.trim().length > 0) ||
                                (typeof jobTitles.title3 === "string" &&
                                  jobTitles.title3.trim().length > 0)) &&
                                "1: "}
                              {jobTitles.title1.trim()}
                            </span>
                          </div>
                        )}
                      {typeof jobTitles.title2 === "string" &&
                        jobTitles.title2.trim().length > 0 && (
                          <div className="mb-1">
                            <span>2: {jobTitles.title2.trim()}</span>
                          </div>
                        )}
                      {typeof jobTitles.title3 === "string" &&
                        jobTitles.title3.trim().length > 0 && (
                          <div className="mb-1">
                            <span>3: {jobTitles.title3.trim()}</span>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}

            {details.rejectionReason && (
              <button
                onClick={() => setReason(!reason)}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!reason ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>Reason for Rejection</small>
                </span>
              </button>
            )}
            <div className={reason ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
              <div class="d-flex mb-2 pl-1 ml-4">
                <p>{details.rejectionReason}</p>
              </div>
            </div>
          </div>
        )}
        {communities && (
          <>
            <div className="mt-3 p-2">
              {pools.length > 0 ? (
                <Table hover>
                  <thead>
                    <tr>
                      <th>Client Name</th>
                      <th>Community Name</th>
                      <th>Community Id</th>
                      <th>Assigned on</th>
                    </tr>
                  </thead>
                  <tbody style={{ overflow: "auto" }}>
                    {currentData.map((item, index) => (
                      <tr key={index}>
                        <td>{item?.tenantname}</td>
                        <td>
                          <span className="tag tag-blue3">
                            {item?.poolname}
                          </span>
                        </td>
                        <td>{item?.poolId}</td>
                        <td>
                          {moment(item?.createddatetime).format("MM/DD/YYYY")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <NoRecord />
              )}
              <PaginationComponent
                currentPage={currentPage}
                totalItems={pools.length}
                itemsPerPage={5}
                onPageChange={handlePageChange}
                limit={5}
              />
            </div>
          </>
        )}
      </div>

      <Modal show={reject}>
        <Modal.Header>
          <h6>Reject</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setRejectionReason("");
              setReject(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="mx-3">
            <label>Reason for Rejection</label>
            <div className="form-floating mt-2">
              <textarea
                className="form-control"
                value={rejectionReason}
                onChange={(e) => {
                  setRejectionReason(e.target.value);
                }}
              />
            </div>
          </div>
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-end m-3">
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              setRejectionReason("");
              setReject(false);
            }}
          >
            Cancel
          </button>

          <button
            disabled={rejectionReason === ""}
            className="btn btn-sm btn-primary ml-2"
            onClick={() => {
              updateStatus("Rejected");
            }}
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal show={remove}>
        <Modal.Header>
          <h6>Delete Invitation</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setDelete(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="bl-modal-body  text-center">
            <div className="avatar avatar-lg avatar-red2 mr-3">
              <i class="fas fa-exclamation-triangle fa-fw"></i>
            </div>
            <p className="text-center lead mt-3">
              Are you sure you want to delete this invitation
            </p>
          </div>
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center m-3">
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              setDelete(false);
            }}
          >
            Cancel
          </button>

          <button
            className="btn btn-sm btn-primary ml-2"
            onClick={() => {
              updateStatus("Deleted");
            }}
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal show={deactivate}>
        <Modal.Header>
          <h6>Deactivate</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setDeactivate(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="bl-modal-body  text-center">
            <div className="avatar avatar-lg avatar-red2 mr-3">
              <i class="fas fa-exclamation-triangle fa-fw"></i>
            </div>
            <p className="text-center lead mt-3">
              You're about to deactivate this user, and all of its data.
            </p>
            <div className="col-lg-12 d-flex align-items-center  mb-2 mb-lg-0 justify-content-end justify-content-lg-start">
              <div className="form-check form-switch d-flex align-items-center text-center">
                <label className="form-check-label pt-3" htmlFor="removeSwitch">
                  <p>Do you want to remove this recruiter from the list?</p>
                </label>
                <input
                  name="recruiterrEMOVE"
                  className="form-check-input ml-3"
                  type="checkbox"
                  onChange={(e) => handleCheck(e)}
                  checked={removeChecked === "Accepted" ? true : false}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center m-3">
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              setDeactivate(false);
            }}
          >
            Cancel
          </button>

          <button
            className="btn btn-sm btn-primary ml-2"
            onClick={() => {
              updateStatus("Deactivate");
            }}
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal show={activate}>
        <Modal.Header>
          <h6>Activate</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setActivate(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="bl-modal-body  text-center">
            <div className="avatar avatar-lg avatar-red2 mr-3">
              <i class="fas fa-exclamation-triangle fa-fw"></i>
            </div>
            <p className="text-center lead mt-3">
              Are you sure you want to activate this user?
            </p>
          </div>
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center m-3">
          <button
            className="btn btn-sm btn-secondary"
            onClick={() => {
              setActivate(false);
            }}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-primary ml-2"
            onClick={activateRecruiter}
          >
            Activate
          </button>
        </div>
      </Modal>

      <Modal show={approveModal}>
        <Modal.Header>
          <h6>Approve Gig Recruiter</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setApproveModal(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="mx-3">
            <div className="text-center">
              <div className="avatar avatar-lg avatar-red2 mr-3">
                <i className="fas fa-exclamation-triangle fa-fw"></i>
              </div>
            </div>
            <p className="text-center lead mt-3">
              Are you sure you want to approve this Recruiter?
            </p>
            <div className="text-center my-4">
              <button
                type="button"
                className="btn btn-secondary mr-3"
                data-dismiss="modal"
                onClick={() => {
                  setApproveModal(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={approve}
              >
                Confirm Approve
              </button>
            </div>
            <div className="form-floating mt-2">
              {/* <textarea
                className="form-control"
                value={rejectionReason}
                onChange={(e) => {
                  // setRejectionReason(e.target.value);
                }}
              /> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RecSidePanel;
