import React, { useEffect, useState } from "react";
import SideNavBar from "../../layout/SideNavBar";
import TopNavBar from "../../layout/TopNavBar";
import CronJob from "./cronjob/Index";
import EmailTemplate from "./emailtemplate/Index";
import FAQ from "./FaqSetup/FaqIndex";
import JobTemplate from "./JobTemplate/Index";
import SMSTemplate from "./smsTemplate/Index";
function AdminConsole() {
  // const [adminConsole, setAdminConsole] = useState(true);
  // const [faq, setFaq] = useState(false);

  // const onTabClick = (type) => {
  //   if (type === "adminConsole") {
  //     setAdminConsole(true);
  //     setFaq(false);
  //   }
  //   if (type === "faq") {
  //     setAdminConsole(false);
  //     setFaq(true);
  //   }
  // };

  return (
    <div className="row">
      {/* <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-between mt-3">
          <ul class="nav nav-tabs  ">
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("adminConsole");
                }}
                type="button"
                class={adminConsole ? "nav-link pb-3 active" : "nav-link pb-3"}
              >
                {" "}
                <div>Admin Console</div>
              </button>
            </li>{" "}
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("faq");
                }}
                type="button"
                class={faq ? "nav-link pb-3 active" : "nav-link pb-3"}
              >
                <div>FAQ Setup</div>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <hr className="mt-0" /> */}

      <>
        <CronJob />
        <EmailTemplate />
        <FAQ />
        <JobTemplate />
        <SMSTemplate />
      </>
    </div>
  );
}

export default AdminConsole;
