import React, { useEffect, useState, useRef } from "react";
import JobTable from "./JobTable";
import Select from "react-select";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getClientJobSummary,
  getClientJobSummaryCount,
  getClientNameData,
} from "../../../../../actions/dashboard/JobSummary";
import Loader from "../../../../Loader/Loader";

const JobSummary = ({ clientList }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [active, setActive] = useState("Total");
  const [options, setOptions] = useState([{ value: "0", label: "All" }]);
  const [selectedOption, setSelectedOption] = useState({
    value: "0",
    label: "All",
  });
  const [dataTotal, setDataTotal] = useState(0);
  const [dataSize, setDataSize] = useState(10);
  const [selectedClient, setSelectedClient] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [searchData, setSearchData] = useState("");
  const isFirstRender = useRef(true);
  const [sortArray, setSortArray] = useState([]);

  const {
    jobData,
    loading,
    countLoading,
    jobSummaryCount,
    clientName,
    nameLoading,
  } = useSelector(
    (state) => ({
      loading: state.getJobSummary.loading,
      countLoading: state.getJobSummary.countLoading,
      nameLoading: state.getJobSummary.nameLoading,
      jobData: state.getJobSummary.jobData,
      jobSummaryCount: state.getJobSummary.jobSummaryCount,
      clientName: state.getJobSummary.clientName,
    }),
    shallowEqual
  );

  useEffect(() => {
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchText.length === 0 || searchText.length >= 2) {
        let payload = {
          searchText: searchText,
        };
        dispatch(getClientNameData(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchText]);

  useEffect(() => {
    fetchJobData("Total", 1, 10, null, "", sortArray);
    const payload = {
      tenantId: null,
      searchText: "",
    };
    dispatch(getClientJobSummaryCount(payload));
  }, []);

  const fetchJobData = (
    status,
    pageNumber,
    limit,
    tenantId,
    searchVal,
    sortArray
  ) => {
    const payload = {
      status: status,
      currentPage: pageNumber,
      limit: limit,
      tenantId: tenantId,
      searchText: searchVal,
      sortQuery: sortArray,
    };
    dispatch(getClientJobSummary(payload));
  };

  useEffect(() => {
    setCurrentPage(1);
    if (active == "Total") {
      jobSummaryCount.length > 0 && setDataTotal(getCount("Total"));
      setDataSize(10);
    } else if (active == "Openings") {
      jobSummaryCount.length > 0 && setDataTotal(getCount("Openings"));
      setDataSize(10);
    } else if (active == "No Response") {
      jobSummaryCount.length > 0 && setDataTotal(getCount("No Response"));
      setDataSize(10);
    } else if (active == "Active") {
      jobSummaryCount.length > 0 && setDataTotal(getCount("Active"));
      setDataSize(10);
    } else if (active == "Covered") {
      jobSummaryCount.length > 0 && setDataTotal(getCount("Covered"));
      setDataSize(10);
    } else if (active == "Uncovered") {
      jobSummaryCount.length > 0 && setDataTotal(getCount("Uncovered"));
      setDataSize(10);
    } else if (active == "Closed") {
      jobSummaryCount.length > 0 && setDataTotal(getCount("Closed"));
      setDataSize(10);
    } else if (active == "Halted") {
      jobSummaryCount.length > 0 && setDataTotal(getCount("Halted"));
      setDataSize(10);
    } else if (active == "Unfilled") {
      jobSummaryCount.length > 0 && setDataTotal(getCount("Unfilled"));
      setDataSize(10);
    } else {
      jobSummaryCount.length > 0 && setDataTotal(getCount("Filled"));
      setDataSize(10);
    }
  }, [active, jobSummaryCount]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchData.length === 0 || searchData.length >= 2) {
        const tenantId = selectedClient == 0 ? null : selectedClient;
        fetchJobData(active, 1, dataSize, tenantId, searchData, sortArray);
        const payload = {
          tenantId: tenantId,
          searchText: searchData,
        };
        dispatch(getClientJobSummaryCount(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchData]);

  useEffect(() => {
    if (clientName.length > 0) {
      let op = clientName?.map((item, index) => ({
        value: item.id,
        label: item?.tenantname.replace("GIG - ", ""),
      }));
      setOptions([{ value: 0, label: "All" }, ...op]);
    }
  }, [clientName]);

  const getCount = (status) => {
    let count = 0;
    if (jobSummaryCount.length > 0) {
      count = jobSummaryCount.filter((item) => item.Type == status)[0]?.Count;
    }
    return count;
  };

  const tabChange = (status) => {
    setCurrentPage(1);
    setActive(status);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchJobData(status, 1, 10, tenantId, searchData, []);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchJobData(active, pageNumber, dataSize, tenantId, searchData, sortArray);
  };

  const handleSizeChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchJobData(active, 1, ds, tenantId, searchData, sortArray);
  };

  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
  };

  const handleChange = (selected) => {
    isFirstRender.current = true;
    setSelectedOption(selected);
    setSearchData("");
    const tenantId = selected.value == 0 ? null : selected.value;
    fetchJobData(active, 1, 10, tenantId, "", []);
    const payload = {
      tenantId: tenantId,
      searchText: "",
    };
    dispatch(getClientJobSummaryCount(payload));
    setSelectedClient(tenantId);
  };

  const handleChangeInput = (e) => {
    setSearchText(e);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
  };

  const applySort = (sortedData) => {
    setCurrentPage(1);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchJobData(active, 1, 10, tenantId, searchData, sortedData);
  };

  return (
    <div>
      {(loading || countLoading || nameLoading) && <Loader />}
      <div className=" card">
        <div className="card-body px-4 pb-4  ">
          <div className="d-flex justify-content-between mb-4 border-bottom align-items-center pb-2">
            <h6>Job Summary</h6>
            <div className="d-flex gap-2 align-items-center">
              <div style={{ width: 200 }}>
                <Select
                  options={options}
                  isSearchable={true}
                  onChange={handleChange}
                  styles={styleSheet}
                  value={selectedOption}
                  onInputChange={handleChangeInput}
                />
              </div>
            </div>
          </div>
          <div className="d-flex  justify-content-between">
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Overall number of job listings created on the High5Hire portal."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Total" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Total");
              }}
            >
              <div>
                <span className="tag">{getCount("Total")}</span>
              </div>
              <span className="">Total</span>
            </a>
            <div className="d-flex flex-column gap-1">
              <span className=""></span>
              <div>
                <span></span>
              </div>
            </div>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Total count of job vacancies available across all the job listings."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Openings" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Openings");
              }}
            >
              <div>
                <div>
                  <span className="tag">{getCount("Openings")}</span>
                </div>
              </div>
              <span className="">Openings</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Job listings for which recruiters have not shown any response or engagement."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "No Response" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("No Response");
              }}
            >
              <div>
                <span className="tag">{getCount("No Response")}</span>
              </div>
              <span className="">No Response</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Job listings that are currently active and accepting applications from candidates."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Active" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Active");
              }}
            >
              <div>
                <span className="tag">{getCount("Active")}</span>
              </div>
              <span className="">Active</span>{" "}
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Job listings that have received applications or interest from potential candidates.

              C = Openings x 2
              T =  Count of Shortlisted talents + Count of Offered talents + Count of Hired talents
              if T >= C then job is considered covered.
              "
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Covered" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Covered");
              }}
            >
              <div>
                <span className="tag">{getCount("Covered")}</span>
              </div>
              <span className="">Covered</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Jobs with no application or interest from potential candidates.
              C = Openings x 2
              T =  Count of Shortlisted talents + Count of Offered talents + Count of Hired talents
              if T < C then job is considered uncovered.
              "
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Uncovered" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Uncovered");
              }}
            >
              <div>
                <span className="tag">{getCount("Uncovered")}</span>
              </div>
              <span className="">Uncovered</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Job listings that have been temporarily paused or halted "
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Halted" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Halted");
              }}
            >
              <div>
                <span className="tag">{getCount("Halted")}</span>
              </div>
              <span className="">Halted</span>
            </a>
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Job listings that have reached their closing date or have been deactivated. "
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Closed" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Closed");
              }}
            >
              <div>
                <span className="tag">{getCount("Closed")}</span>
              </div>
              <span className="">Closed</span>
            </a>{" "}
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Job listings that have successfully filled the specified number of available positions."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Filled" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Filled");
              }}
            >
              <div>
                <span className="tag">{getCount("Filled")}</span>
              </div>
              <span className="">Filled</span>
            </a>{" "}
            <a
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="These job listings still have vacant positions remaining."
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Unfilled" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Unfilled");
              }}
            >
              <div>
                <span className="tag">{getCount("Unfilled")}</span>
              </div>
              <span className="">Unfilled</span>
            </a>
          </div>
        </div>
      </div>
      <JobTable
        jobs={jobData}
        isFrom={"LoginOverview"}
        prefix={active}
        handlePageChange={handlePageChange}
        handleSizeChange={handleSizeChange}
        dataTotal={dataTotal}
        dataSize={dataSize}
        currentPage={currentPage}
        handleSearch={handleSearch}
        searchData={searchData}
        sortArray={sortArray}
        setSortArray={setSortArray}
        applySort={applySort}
        fetchData={fetchJobData}
        tenantId={selectedClient == 0 ? null : selectedClient}
      />
    </div>
  );
};

export default JobSummary;
