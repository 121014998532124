import React, { useState, useEffect, useRef } from "react";
import SearchHistory from "../modal/SearchHistory";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiDomain } from "../../constants/Constants";
import {
  getCandidateName,
  getUserToken,
  getUserRole,
} from "../../service/AuthService";
import { get } from "../../service/ApiService";
import SupportModal from "./SupportModal";
import useOutsideClick from "../function/useOutsideClick";
import Avatar from "react-avatar";
import Loader from "../Loader/Loader";

function TopNavBar(props) {
  const dispatch = useDispatch;
  const history = useHistory;
  const [toggle, setToggle] = useState(false);
  const [toggleProfile, setToggleProfile] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const [userprofile, setProfile] = useState({ name: "" });
  const [support, setSupport] = useState([]);
  const [supportModal, setSupportModal] = useState(false);
  const [supportType, setSupportType] = useState(0);
  const [supportSearchValue, setSupportSearchValue] = useState("");
  const [enableSupportSearch, setEnableSupportSearch] = useState(false);
  const [supportData, setSupportData] = useState([]);
  const [openDropDownDiv, setOpenDropDownDiv] = useState(false);
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const ref = useRef;
  const ref1 = useRef();
  const ref2 = useRef();
  useOutsideClick(ref, () => {
    setOpenDropDownDiv(false);
  });
  useOutsideClick(ref1, () => {
    setToggleProfile(false);
  });
  useOutsideClick(ref2, () => {
    setToggleHelp(false);
  });

  const toglleDiv = () => {
    if (toggle === false) {
      props.handleToggale(true);
      setToggle(true);
    } else {
      props.handleToggale(false);
      setToggle(false);
    }
  };

  const toggleProfileDropdown = () => {
    console.log(toggleProfile);
    if (toggleProfile === false) {
      setToggleProfile(true);
      setToggleHelp(false);
    } else {
      setToggleProfile(false);
    }
  };

  const onClickLogout = (e) => {
    e.preventDefault();
    const token = getUserToken();
    const headers = { authorization: token };
    dispatch(handleLogout({ headers }));
  };

  const handleLogout = (header) => async (dispatch) => {
    try {
      const res = await axios.put(`${apiDomain}/logout`, null, header);
      if (res.status === 200) {
        localStorage.clear();
        history.push("/login");
      }
    } catch (error) {
      toast.error("Unable to Logout!");
      console.log(error);
    }
  };

  const fetchSupportAPI = async () => {
    setLoading(true);
    let id = JSON.parse(localStorage.getItem("userToken")).roleId;
    const res = await get("/support");
    setSupport(res.data.filter((s) => s.fk_role.includes(id)));
    setLoading(false);
  };

  useEffect(() => {
    const role = getUserRole();
    setUserRole(role);
    //fetchSupportAPI()
  }, []);

  const openSupportModal = (e) => {
    e.preventDefault();
    setSupportType(+Object.values(e.target)[0].key);
    setSupportModal(true);
  };

  const handleSupportSearch = (e) => {
    setSupportSearchValue(e.target.value);
    setEnableSupportSearch(true);
    setSupportData(
      support.filter((i) =>
        i.Title.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const closeSupportModal = () => {
    setSupportModal(false);
  };

  const splitMatchedText = (str) => {
    const regExp = new RegExp(supportSearchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='background: blue; color: white;'>${match}</span>`
    );
  };

  return (
    <>
      {loading && <Loader />}
      <div id="TopBar">
        <div className="nav-toggle-wrap">
          <button
            id="MainMenuToggle"
            onClick={toglleDiv}
            type="button"
            className="btn btn-icon nav-toggle btn-nav"
          >
            <i className="fal fa-angle-left"></i>
          </button>
          <div className="divider"></div>
        </div>

        {/* SEARCH JOB by Candidates */}
        {
          <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-6">
                <div className="search-field">
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Search Jobs"
                      onChange={(e) => props.setSearch(e.target.value)}
                      value={props.search || ""}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pl-0">
                <div className="search-field">
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Location"
                      onChange={(e) => props.setSearch(e.target.value)}
                    ></input>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        {/* search bar div ended */}
        <div className="extras ml-auto text-right">
          {/* Help div started  */}
          <button
            type="button"
            title="Support"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
            onClick={() => setToggleHelp(!toggleHelp)}
            // onClick={() => alert('Hi')}
          >
            <i className="fal fa-fw fa-life-ring"></i>
          </button>
          {toggleHelp && (
            <div
              className="action-panel"
              style={{
                display: "revert",
                right: "0px",
                top: "50px",
                textAlign: "left",
              }}
            >
              <OutsideClickHandler
                onOutsideClick={() => !supportModal && setToggleHelp(false)}
              >
                <div
                  className="action-panel"
                  style={{
                    display: "revert",
                    right: "0px",
                    top: "50px",
                    textAlign: "left",
                  }}
                >
                  <div className="py-3 px-4">
                    <p className="lead mb-0">Hello {getCandidateName()}</p>
                    <strong>What can we help you with today?</strong>
                    <div className="search-field mb-3 mt-2">
                      <div className="field-wrap">
                        <input
                          type="search"
                          onChange={handleSupportSearch}
                          placeholder="Search Support"
                        />
                        <span className="icon">
                          <i aria-hidden="true" className="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                    <strong>
                      <small>Common Questions</small>
                    </strong>
                    <ul className="mt-1 ml-0 list-unstyled">
                      {enableSupportSearch
                        ? supportData.length !== 0
                          ? supportData.map((s, i) => (
                              <li>
                                <a
                                  key={i}
                                  onClick={openSupportModal}
                                  href=""
                                  dangerouslySetInnerHTML={{
                                    __html: splitMatchedText(s.Title),
                                  }}
                                ></a>
                              </li>
                            ))
                          : "No Questions available "
                        : support.length !== 0
                        ? support.map((s, i) => (
                            <li>
                              <a key={i} onClick={openSupportModal} href="">
                                {s.Title}
                              </a>
                            </li>
                          ))
                        : "No Questions available "}
                    </ul>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          )}{" "}
          {/* Help div ended  */}
          {/* Profile div started  */}
          <div className="dropdown ml-3">
            <button
              className="btn btn-icon dropdown-toggle"
              type="button"
              ref={ref1}
              onClick={toggleProfileDropdown}
            >
              <Avatar
                className="avatar avatar-sm "
                name={userprofile.name}
                round="80px"
              />
            </button>
            <div>
              {toggleProfile && (
                <div
                  className="dropdown-menu"
                  style={{ display: "revert", right: "-14px", top: "30px" }}
                >
                  <a href="/high5hire/myprofile" className="dropdown-item">
                    <i className="fal fa-user-circle mr-3"></i>Profile
                  </a>

                  <hr />
                  <a
                    href="#top"
                    className="dropdown-item"
                    onClick={onClickLogout}
                  >
                    <i className="fal fa-sign-out mr-3"></i> Logout{" "}
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {supportModal && (
        <SupportModal
          supportData={support[supportType]}
          closeSupportModal={closeSupportModal}
        />
      )}
    </>
  );
}

export default TopNavBar;
