import React, { useState } from "react";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";
import DocumentDetails from "./DocumentDetails";

const Index = (props) => {
  const { jobData } = props;
  const [documentCollapse, setDocumentCollapse] = useState(false);
  return (
    <>
      {jobData.filePath ? (
        <div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setDocumentCollapse(!documentCollapse)}
            >
              <i
                aria-hidden="true"
                className={
                  documentCollapse
                    ? "fal fa-angle-up fa-fw"
                    : "fal fa-angle-down fa-fw"
                }
              />
              <span>
                <small>Document</small>
              </span>
            </button>
          </div>
          {documentCollapse === true && <DocumentDetails jobData={jobData} />}
        </div>
      ) : (
        <NoRecord />
      )}
    </>
  );
};

export default Index;
