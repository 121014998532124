import React, { useState, useEffect } from "react";
import Loader from "../../Loader/Loader";
import { get, post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import moment from "moment";
import TalentSummaryCard from "./TalentSummaryCard";

function SummaryCard(props) {
  const [loading, setLoading] = useState(false);
  const [totRecuiter, setTotRecruiter] = useState(false);
  const [RecruitersCount, setRecruitersCount] = useState([]);
  const [recPercentage, setRecPercentage] = useState(0);

  useEffect(() => {
    fetchRecruiterCount();
  }, []);

  //today's date
  let todayDate = moment(new Date()).format("YYYY-MM-DD");
  //Last Month Date
  let lastMonthDate = moment(
    new Date(new Date().setDate(new Date().getDate() - 30))
  ).format("YYYY-MM-DD");

  const fetchRecruiterCount = async () => {
    try {
      setLoading(true);
      let res = await post("/gigsummary/fetchRecCount");
      if (res.status === 200) {
        setTotRecruiter(res.data.recData);
        setRecruitersCount(res.data.totRecCount);
        let totalRecInlastMonth = res.data.recData.filter(
          (talent) =>
            moment(talent.createddatetime).format("YYYY-MM-DD") >
              lastMonthDate &&
            moment(talent.createddatetime).format("YYYY-MM-DD") <= todayDate
        );
        if (totalRecInlastMonth.length === 0 && res.data.totRecCount === 0) {
          setRecPercentage(0);
        } else {
          const percentage = Math.round(
            (totalRecInlastMonth.length / res.data.totRecCount) * 100
          );
          setRecPercentage(percentage);
        }
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="col-lg-6">
        <div className="card card-lg mb-3 summary" style={{ height: "252px" }}>
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <h6>Summary</h6>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-6">
                <div className="w-100 d-flex align-items-center">
                  <div>
                    <h2 className="font-light mb-0 flex-item">
                      {RecruitersCount}
                    </h2>
                    <p className="mb-0 flex-item">
                      Recruiters Count{" "}
                      <span
                        className=""
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title="Total no. of approved Gig recruiters+ total no. of recruiters added by recruiter admin."
                      >
                        <i class="fal fa-info-circle"></i>
                      </span>
                    </p>{" "}
                  </div>
                </div>
              </div>
              <div className="col-6">
                {recPercentage > 0 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="w-70 text-right font-green">
                        <h2 className="mb-1 ml-5 text-left ">
                          <i className="fad fa-sm fa-arrow-up" />
                        </h2>
                        <p className="mb-0" style={{ width: "120px" }}>
                          <small>{recPercentage}% in last 30 days</small>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {recPercentage === 0 && (
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="w-70 text-center font-red mb-1">
                        <p className="mb-0" style={{ width: "120px" }}>
                          <small>No change in last 30 days</small>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <TalentSummaryCard talentData={props.talentData} />
          </div>
        </div>
      </div>
    </>
  );
}

export default SummaryCard;
