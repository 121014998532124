import React, { useState, useEffect } from "react";
import Avatar from "react-avatar";
import moment from "moment";
import { countryObj } from "../../../../../constants/Constants";
import StatusData from "./StatusData";
import TalentProfile from "../../talentprofile/TalentProfile";
import NoRecord from "../../../commoncomponents/norecord/NoRecord";
import Pagination from "../../../commoncomponents/pagination/Pagination";
import ProfileImage from "../../../commoncomponents/profileimage/ProfileImage";

function TalentGridView(props) {
  const { currentPage, setCurrentPage, arrayList } = props;
  const [showCandidateProfile, setShowCandidateProfile] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [candidateData, setCandidateData] = useState({});
  const [paginationArray, setPaginationArray] = useState([]);
  const [createdByToggle, setCreatedByToggle] = useState({});
  let pageSize = 3;
  let dataSize =
    props.parent == "hmdashboard"
      ? 3
      : props.parent == "recruiterdashboard"
      ? 3
      : 12;

  const handleModel = () => {
    setShowCandidateProfile(false);
  };

  const onProfileClick = (candidate) => {
    setCandidateData(candidate);
    setShowCandidateProfile(true);
    setCandidateId(candidate.userId);
  };

  const capitalizeFirstLetter = (sentence) => {
    var words = sentence.split(" ");
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      var capitalizedWord = word.charAt(0).toUpperCase() + word.slice(1);
      words[i] = capitalizedWord;
    }
    var capitalizedSentence = words.join(" ");
    return capitalizedSentence;
  };

  const checkSubmittedCandidate = (candidate) => {
    if (candidate?.statusData) {
      return candidate?.statusData.filter((val) => val.status == 6);
    } else {
      return [];
    }
  };

  return (
    <>
      {arrayList.length === 0 ? (
        <NoRecord />
      ) : (
        <div class="row  d-flex-1200">
          {arrayList.map((candidate, i) => (
            <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 d-flex">
              <a
                onClick={(e) => e.preventDefault()}
                class="card col card-tag-bottom mb-4 card-lg card-candidates-grid p-relative"
              >
                <div class="card-body">
                  <div
                    class="d-flex mb-2 align-items-center"
                    onClick={() => onProfileClick(candidate)}
                  >
                    <div class="mr-3">
                      <span>
                        <ProfileImage
                          imageSrc={candidate?.profileImagePath}
                          imageWidth={"55"}
                          imageHeight={"55"}
                          avatarSize={"55"}
                          avatarRound={"80px"}
                          firstName={candidate?.firstName}
                          lastName={candidate?.lastName}
                        />
                      </span>
                    </div>
                    <div>
                      <h6 class="mb-0">
                        {candidate.firstName?.charAt(0)?.toUpperCase() +
                          candidate.firstName?.slice(1)}{" "}
                        {candidate.lastName?.charAt(0)?.toUpperCase() +
                          candidate.lastName?.slice(1)}
                      </h6>
                    </div>
                  </div>
                  <div class="designation-city-status font-regular font-primary font-gray1 mt-1">
                    <div class="mb-2">
                      <i class="far fa-map-marker-alt me-2"></i>
                      {candidate.city},{" "}
                      {candidate.state !== "NA" && candidate.state !== ""
                        ? `${candidate.state}, `
                        : ""}
                      {candidate.country}
                    </div>
                    {candidate.updatedDateTime !== "" &&
                      new Date() > new Date(candidate.updatedDateTime) && (
                        <div class="mb-2">
                          <i class="far fa-clock me-2"></i>Last updated: &nbsp;
                          <span>
                            {moment(
                              new Date(candidate.updatedDateTime),
                              "MMDDYYYY"
                            ).format("MM/DD/YYYY")}
                          </span>
                          &nbsp;
                        </div>
                      )}
                    {candidate.RecruiterName !== "" &&
                      candidate.RecruiterName &&
                      (!createdByToggle[i] ? (
                        <div class="mb-2">
                          Created by:{" "}
                          {candidate.RecruiterName.length > 1 ? (
                            <>
                              {capitalizeFirstLetter(
                                candidate.RecruiterName[0]
                              )}
                              <span
                                onClick={() => {
                                  setCreatedByToggle({
                                    ...createdByToggle,
                                    [i]: true,
                                  });
                                }}
                              >
                                ...
                              </span>
                            </>
                          ) : (
                            candidate.RecruiterName.map((item) =>
                              capitalizeFirstLetter(item)
                            ).join(", ")
                          )}
                        </div>
                      ) : (
                        <div class="mb-2">
                          Created by:
                          {candidate.RecruiterName.map((item) =>
                            capitalizeFirstLetter(item)
                          ).join(", ")}
                        </div>
                      ))}
                  </div>
                  <StatusData candidate={candidate} />
                </div>

                {props.parent == "hmdashboard" && (
                  <span
                    className={`tag tag-footer ${checkSubmittedCandidate(
                      candidate
                    ).length > 0 && "tag-green3"} w-100 text-center`}
                  >
                    {checkSubmittedCandidate(candidate).length > 0 && "New!"}
                  </span>
                )}
              </a>
            </div>
          ))}
        </div>
      )}
      {showCandidateProfile && (
        <TalentProfile
          candidateId={candidateId}
          candidateData={candidateData}
          handleSuccess={handleModel}
          parent="TalentListView"
        />
      )}
    </>
  );
}

export default TalentGridView;
