import React, { useEffect, useState } from "react";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import JobFilterTable from "./JobFilterTable";
import { post } from "../../../../service/ApiService";
import { toast } from "react-toastify";
import { Button, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../Loader/Loader";
import { set } from "react-ga";
import { updateJob } from "../../../../actions/jobList/jobList";
import { useDispatch } from "react-redux";
import moment from "moment";

function App(props) {
  const dispatch = useDispatch();
  const [filters, setFilters] = useState({
    fromDate: "",
    toDate: "",
    jobId: "",
    jobTitle: "",
  });

  const [searchResults, setSearchResults] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [searched, setSearched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [total, setTotal] = useState(0);
  const [selectedJob, setSelectedJob] = useState(null);
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "jobId" && isNaN(value)) return;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const validationFunction = () => {
    let isValid = true;
    if (
      filters.fromDate === "" &&
      filters.toDate === "" &&
      filters.jobId === "" &&
      filters.jobTitle === ""
    ) {
      isValid = false;
      toast.error("Select at least one filter");
    }
    if (filters.fromDate !== "" && filters.toDate === "") {
      toast.error("Select the To Date");
      isValid = false;
    }
    if (filters.fromDate === "" && filters.toDate !== "") {
      toast.error("Select the From Date");
      isValid = false;
    }
    if (filters.jobId !== "" && filters.jobId.length < 8) {
      isValid = false;
      toast.error("Enter a valid JobId");
    }
    return isValid;
  };

  const handleSearch = async () => {
    const isValid = validationFunction();
    if (isValid) {
      setLoading(true);
      setSearched(true);
      const paramObj = {
        publishedFrom: filters.fromDate || null,
        publishedTo: filters.toDate || null,
        jobId: filters.jobId || "",
        jobTitle: filters.jobTitle || "",
        pageNumber: currentPage,
        per_page: 10,
      };

      const jobRes = await post("/broadCastHM/getJobListHM", paramObj);
      if (jobRes.status === 200) {
        setSearchResults(
          jobRes?.data?.result.sort((a, b) => {
            return b.publishedDate > a.publishedDate ? 1 : -1;
          })
        );
        setTotal(jobRes?.data?.total);
        setLoading(false);
      }
    } else {
      setSearched(false);
    }
  };

  const clearFilters = (filterClear) => {
    if (filterClear) {
      setFilters({
        fromDate: "",
        toDate: "",
        jobId: "",
        jobTitle: "",
      });
    }
    setSelectedJob(null);
    setSearched(false);
    setSearchResults(null);
    dispatch(updateJob({}));
  };

  const changeCurrentPage = async (number) => {
    setCurrentPage(number);
    setLoading(true);
    setSearched(true);
    const paramObj = {
      publishedFrom: filters.fromDate || null,
      publishedTo: filters.toDate || null,
      jobId: filters.jobId || "",
      jobTitle: filters.jobTitle || "",
      pageNumber: number,
      per_page: 10,
    };

    const jobRes = await post("/broadCastHM/getJobListHM", paramObj);
    if (jobRes.status === 200) {
      setSearchResults(
        jobRes?.data?.result.sort((a, b) => {
          return b.publishedDate > a.publishedDate ? 1 : -1;
        })
      );
      setTotal(jobRes?.data?.total);
      setLoading(false);
    }
  };
  useEffect(() => {
    console.log("selected job :", selectedJob);
  }, [selectedJob]);
  // Function to handle the "Add" button click
  const handleAddButtonClick = () => {
    dispatch(updateJob(selectedJob));
    setSelectedJob(null);
    props.handleClose();
  };
  return (
    <>
      {loading && <Loader />}
      <div className="container ">
        <div className="row mt-2 ml-1">
          <div className="col-md-2">
            <label>Published From Date</label>
            <input
              type="date"
              name="fromDate"
              value={filters.fromDate}
              onChange={handleFilterChange}
              placeholder="From Date"
              className="form-control"
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
          </div>
          <div className="col-md-2">
            <label>Published To Date</label>
            <input
              type="date"
              name="toDate"
              value={filters.toDate}
              onChange={handleFilterChange}
              placeholder="To Date"
              className="form-control"
              max={moment(new Date()).format("YYYY-MM-DD")}
            />
          </div>
          <div className="col-sm-1 d-flex align-items-centre">
            <label className="mt-4 ml-4">Or</label>
          </div>
          <div className="col-md-2">
            <label>Job ID</label>
            <input
              type="text"
              name="jobId"
              value={filters.jobId}
              onChange={handleFilterChange}
              placeholder="e.g. 526272718"
              className="form-control"
              pattern="^[0-9]*$"
              maxLength={9}
            />
          </div>
          <div className="col-md-1">
            <label className="mt-4 ml-4">Or</label>
          </div>
          <div className="col-md-3">
            <label>Job Title</label>
            <input
              type="text"
              name="jobTitle"
              value={filters.jobTitle}
              onChange={handleFilterChange}
              placeholder="Enter Job Title"
              className="form-control"
            />
          </div>
        </div>

        <div className="row mt-3 d-flex align-items-center justify-content-end">
          <div className="col-md-4 mt-3 d-flex align-items-center justify-content-end">
            <button
              className="btn btn-secondary mr-2"
              onClick={() => clearFilters(true)}
            >
              Clear
            </button>
            <button className="btn btn-primary mr-3" onClick={handleSearch}>
              Search
            </button>
          </div>
        </div>

        <div className="row mt-3 ml-2 d-flex align-items-center justify-content-end">
          <div className="d-flex gap-2 px-2 mb-2 align-items-start">
            {searchResults && searchResults.length > 0 && (
              <span>
                {total > 1 ? "Total Jobs: " : "Total Job: "}
                <strong>{total}</strong>
              </span>
            )}
          </div>
        </div>

        <div className="mt-3">
          <JobFilterTable
            jobData={searchResults}
            searched={searched}
            loading={loading}
            error={error}
            setSelectedJob={setSelectedJob}
            selectedJob={selectedJob}
          />
        </div>
        {searchResults && searchResults.length !== 0 ? (
          <div className="d-flex justify-content-between">
            <div className="mt-3 px-2">
              Showing {Math.min((currentPage - 1) * 10 + 1, total)} -{" "}
              {Math.min(currentPage * 10, total)} of {total}
            </div>
            <div className="mt-3 px-2">
              <Pagination
                currentPage={currentPage}
                totalSize={total}
                sizePerPage={10}
                firstPageText="|⟨"
                lastPageText="⟩|"
                showFirstLastPages={true}
                changeCurrentPage={changeCurrentPage}
                theme="border-bottom"
              />
            </div>
          </div>
        ) : null}
        <div className=" mt-3 mb-3 mr-3 d-flex justify-content-end">
          {selectedJob && Object.keys(selectedJob).length !== 0 ? (
            <>
              <button
                className="btn btn-secondary mr-2"
                onClick={() => props.handleClose()}
              >
                Cancel
              </button>
              <button
                className="btn btn-md btn-secondary mr-2"
                onClick={() => clearFilters(false)}
              >
                Clear{" "}
              </button>
              <button type="btn btn-primary" onClick={handleAddButtonClick}>
                Add
              </button>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default App;
