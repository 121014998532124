import React from "react";
import Select from "react-select";
import { get, post } from "../../../../service/ApiService";
import { domain } from "../../../../constants/Constants";
import { toast } from "react-toastify";

const RecFilters = ({
  skillSetOption,
  handleChangeIndustry,
  handleChangeRegion,
  selectedCategory,
  subCategoryOptions,
  subCategoryOptionsAll,
  selectedSubCategory,
  handleChangeMultiSelect,
  fetchData,
  regions,
  selectedRegion,
  name,
  setName,
  recEmail,
  setRecEmail,
}) => {
  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
  };
  const handleChange = (e) => {
    e.target.name === "name"
      ? setName(e.target.value)
      : setRecEmail(e.target.value);
  };
  return (
    <div className="d-flex gap-2 px-2">
      <div className="col-2">
        <div className=" py-0">
          <label>Name</label>
          <div className="">
            <input
              value={name}
              name="name"
              onChange={handleChange}
              className="form-control small font-14"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="col-2">
        <div className=" py-0">
          <label>Email</label>
          <div className="">
            <input
              value={recEmail}
              onChange={handleChange}
              name="email"
              className="form-control small font-14"
              type="text"
            />
          </div>
        </div>
      </div>
      <div className="col-2">
        <div className=" py-0 ">
          <label>Region</label>
          <div className="form-floating Hover-Dropdown mb-2">
            <Select
              options={regions}
              name="placementSkill"
              onChange={(value) => handleChangeRegion(value.value)}
              isSearchable={true}
              value={regions.filter((item) =>
                selectedRegion.includes(item.value)
              )}
              styles={styleSheet}
            />
          </div>
        </div>
      </div>
      <div className="col-2">
        <div className=" py-0 ">
          <label>Job Category</label>
          <div className="form-floating Hover-Dropdown mb-2">
            <Select
              options={skillSetOption}
              name="placementSkill"
              onChange={(value) => handleChangeIndustry(value.value)}
              isSearchable={true}
              value={skillSetOption.filter((item) =>
                selectedCategory.includes(item.value)
              )}
              styles={styleSheet}
            />
          </div>
        </div>
      </div>
      <div className="col-4 pr-4">
        <div className=" pr-2">
          <label>Sub category</label>
          <div className="form-floating Hover-Dropdown mb-2">
            <Select
              isMulti
              options={subCategoryOptions.sort((a, b) => b.label - a.label)}
              name="subCategory"
              isSearchable={true}
              styles={styleSheet}
              value={
                selectedSubCategory.length > 0
                  ? subCategoryOptionsAll.length - 1 ==
                    selectedSubCategory.length
                    ? [{ label: "All", value: "All" }]
                    : subCategoryOptionsAll.filter((f) =>
                        selectedSubCategory.includes(f.value)
                      )
                  : []
              }
              onChange={(value) =>
                handleChangeMultiSelect(value?.map((i) => i.value))
              }
              isDisabled={selectedCategory.length == 0}
            />
          </div>
        </div>
      </div>{" "}
    </div>
  );
};

export default RecFilters;
