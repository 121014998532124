import React, { useState, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import GoogleLocationAPI from "../../../location/GoogleLocationAPI";
import Select from "react-select";
import { getClientNameData } from "../../../../actions/dashboard/JobSummary";

const AddNewCurator = ({
  newCuratorData,
  setNewCuratorData,
  validation,
  phoneValidation,
  setPhoneValidation,
  closeAddNewCurator,
  handleAddNewCurator,
  clearData,
}) => {
  const dispatch = useDispatch();
  const searchInput = useRef(null);
  const [clientOptions, setClientOptions] = useState([]);
  const [clientSearchText, setClientSearchText] = useState("");
  const [selectedClient, setSelectedClient] = useState(null);

  const { clientName, nameLoading } = useSelector(
    (state) => ({
      clientName: state.getJobSummary.clientName,
      nameLoading: state.getJobSummary.nameLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (clientName) {
      setClientOptions(
        clientName.map((item) => {
          return {
            label: item?.tenantname.replace("GIG - ", ""),
            value: item.id,
            tenantemail: item.tenantemail,
            tenantName: item.clientName,
          };
        })
      );
    }
  }, [clientName]);

  useEffect(() => {
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (clientSearchText.length === 0 || clientSearchText.length >= 2) {
        let payload = {
          searchText: clientSearchText,
        };
        dispatch(getClientNameData(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [clientSearchText]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewCuratorData({
      ...newCuratorData,
      [name]: value,
    });
  };
  const setLocation = (address, searchInput) => {
    const city = address.locality || address.city;
    setNewCuratorData({
      ...newCuratorData,
      city: city,
      state: address.state,
      country: address.country,
      searchLocation: searchInput?.current?.value,
    });
  };

  const handlePhoneChange = (phone, codeId, value, country) => {
    const mobile = value.replace(country.dialCode, "");
    setNewCuratorData({
      ...newCuratorData,
      [phone]: mobile,
      [codeId]: `+${country.dialCode}`,
    });
  };

  const _onChangeText = (value, code) => {
    if (value) {
      const asYouType = new AsYouType(code);
      asYouType.input(value);
      setPhoneValidation(asYouType.isValid());
    } else {
      setPhoneValidation(true);
    }
  };

  const close = () => {
    closeAddNewCurator(false);
    clearData();
  };
  const handleClientChange = (val) => {
    console.log("val", val);
    setSelectedClient(val);
  };

  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
  };

  const handleChangeInput = (e) => {
    setClientSearchText(e);
  };

  return (
    <div className="bl-modal reveal-overlay reveal-card">
      <div class="bl-modal-overlay"></div>
      <div class="bl-modal-container">
        <div class="bl-modal-card bl-modal-card-lg">
          <div class="bl-modal-header">
            <h6 v-if="title" class="bl-modal-title">
              Add New Curator
            </h6>
            <button
              type="button"
              class="close p-0 bl-modal-close-btn"
              data-dismiss="modal"
              aria-label="Close"
              onClick={close}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="showBody" class="bl-modal-body pb-0">
            <div
              v-if="isAssign === false"
              class="card card-flat card-borderless bg-gray4 text-center"
            >
              <div
                class="card-body"
                style={{ width: "100%", zIndex: "99999999" }}
              >
                <div class="container">
                  <div className="row mb-2">
                    <div class="col-lg-4 ">
                      <div
                        style={{
                          border: validation.client && "1px solid #ff0000",
                        }}
                      >
                        <Select
                          options={nameLoading ? [] : clientOptions}
                          isSearchable={true}
                          onChange={handleClientChange}
                          styles={styleSheet}
                          value={selectedClient}
                          onInputChange={handleChangeInput}
                          className="small font-14"
                          placeholder={"Select Client*"}
                        />
                      </div>
                      <div className="text-left">
                        <small className="validation">
                          {validation.client}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="card-body">
                <div class="container">
                  <div className="row mb-2">
                    <div class="col-lg-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          className="form-control mr-2 "
                          style={{
                            border: validation.firstName && "1px solid #ff0000",
                          }}
                          name="firstName"
                          value={newCuratorData.firstName}
                          onChange={handleInputChange}
                          placeholder="First Name*"
                        />
                        <label>First Name*</label>
                      </div>
                      <div className="text-left">
                        <small className="validation">
                          {validation.firstName}
                        </small>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          style={{
                            border: validation.lastName && "1px solid #ff0000",
                          }}
                          name="lastName"
                          value={newCuratorData.lastName}
                          onChange={handleInputChange}
                          placeholder="Last Name*"
                        />
                        <label>Last Name*</label>
                      </div>
                      <div className="text-left">
                        <small className="validation">
                          {validation.lastName}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div class="col-lg-6">
                      <div class="form-floating">
                        <input
                          type="email"
                          className="form-control mr-2"
                          style={{
                            border: validation.email && "1px solid #ff0000",
                          }}
                          name="email"
                          value={newCuratorData.email}
                          onChange={handleInputChange}
                          placeholder="Email*"
                        />
                        <label>Email*</label>
                      </div>
                      <div className="text-left">
                        <small className="validation">{validation.email}</small>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div
                        class="form-floating"
                        style={{
                          border: validation.phoneNumber && "1px solid #ff0000",
                        }}
                      >
                        <PhoneInput
                          inputClass={`form-control font-14 w-100 ht-100 ${
                            !phoneValidation ? "phone-input-error" : ""
                          }`}
                          required="true"
                          placeholder="mobile"
                          id="homePhone"
                          country={"us"}
                          value={
                            newCuratorData.phoneCode
                              ? `${newCuratorData.phoneCode}${newCuratorData.phoneNumber}`
                              : newCuratorData.phoneNumber
                          }
                          name="homePhone"
                          onChange={(value, country, e) => {
                            _onChangeText(
                              value.replace(country.dialCode, ""),
                              country.countryCode.toUpperCase()
                            );
                            handlePhoneChange(
                              "phoneNumber",
                              "phoneCode",
                              value,
                              country
                            );
                          }}
                        />
                      </div>
                      <div className="text-left">
                        <small className="validation">
                          {validation.phoneNumber}
                        </small>
                      </div>
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div class="col-lg-12">
                      <div class="form-floating">
                        <GoogleLocationAPI
                          setLocation={setLocation}
                          searchInput={searchInput}
                        />
                        <label>Search Location</label>
                      </div>
                    </div>
                  </div>
                  <div iv className="row mb-2">
                    <div class="col-lg-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          style={{
                            border: validation.city && "1px solid #ff0000",
                          }}
                          name="city"
                          value={newCuratorData.city}
                          onChange={handleInputChange}
                          placeholder="City*"
                        />
                        <label>City*</label>
                      </div>
                      <div className="text-left">
                        <small className="validation">{validation.city}</small>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          className="form-control "
                          style={{
                            border: validation.state && "1px solid #ff0000",
                          }}
                          name="state"
                          value={newCuratorData.state}
                          onChange={handleInputChange}
                          placeholder="State*"
                        />
                        <label>State*</label>
                      </div>
                      <div className="text-left">
                        <small className="validation">{validation.state}</small>
                      </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="form-floating">
                        <input
                          type="text"
                          className="form-control "
                          style={{
                            border: validation.country && "1px solid #ff0000",
                          }}
                          name="country"
                          value={newCuratorData.country}
                          onChange={handleInputChange}
                          placeholder="Country*"
                        />
                        <label>Country*</label>
                      </div>
                      <div className="text-left">
                        <small className="validation">
                          {validation.country}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer mt-4 mb-0">
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                handleAddNewCurator(selectedClient);
              }}
            >
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewCurator;
