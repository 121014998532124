import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import ScreeningQuestions from "./ScreeningQuestions";
import "react-tagsinput/react-tagsinput.css";
import { post } from "../../../../../../service/ApiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSync } from "@fortawesome/free-solid-svg-icons";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

function InterviewPlan({
  addJobData,
  setAddJobData,
  screeningQuestions,
  setScreeningQuestions,
  selectedLanguages,
  setSelectedLanguages,
  skillSetOption,
  addJobVal,
  setAssessmentDetails,
  skills,
  setSkills,
  certification,
  setCertification,
  showCustomizedAssessment,
  edit,
}) {
  const [displayScreening, setDisplayScreening] = useState(true);
  const [displayVetting, setDisplayVetting] = useState(true);
  const [showVettingModal, setShowVettingModal] = useState(false);
  const [jobTitle, setJobTitle] = useState("");
  const { assessmentData } = useSelector(
    (state) => ({
      assessmentData: state.vettingAssessmentReducer.assessmentData,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (assessmentData) {
      setAssessmentDetails(assessmentData);
    }
  }, [assessmentData]);

  useEffect(() => {
    setAddJobData({
      ...addJobData,
    });
  }, []);

  const handleRefreshClick = async () => {
    let paramObj = {
      jobTitle: jobTitle,
    };
    await post("/vetIntegration/getCustomCreatedAssessment", paramObj);
  };

  const toolTip = () => {
    return (
      <Tooltip style={{ zIndex: 99999999, maxWidth: "300px" }}>
        <div style={{ textAlign: "left", marginLeft: "12px" }}>
          <h6>What to do:</h6>
          <ol>
            <li>Select whether to include knockout assessment or not.</li>
            <li>
              Choose whether to use system recommended questions or create
              custom questions.
            </li>
            <li>
              If defining custom questions, enter the questions text and
              expected answers.
            </li>
            <li>
              Review system recommended or custom questions to ensure they meet
              your knockout assessment criteria.
            </li>
            <li>
              Once you have reviewed the questions, click next to apply the
              settings and move the next section .
            </li>
          </ol>
        </div>
      </Tooltip>
    );
  };

  return (
    <>
      <div className="card-body">
        <div className="card card-flat bg-gray4 m-3">
          <div className="card-body">
            <div className="d-flex">
              <div className=""></div>
              <span>
                Consider adding Knockout Assessment to streamline candidate
                selection
              </span>
              <OverlayTrigger placement="bottom" overlay={toolTip()}>
                <label
                  className="ml-auto mr-4"
                  style={{ color: "red", cursor: "pointer", padding: "0" }}
                >
                  What to do...
                </label>
              </OverlayTrigger>
            </div>
            <div>
              <small className="ml-1">
                -Select up to five questions that candidates must answer.
              </small>
            </div>

            {
              <div className={displayScreening ? " mb-4" : "d-none"}>
                <ScreeningQuestions
                  selectedLanguages={selectedLanguages}
                  setSelectedLanguages={setSelectedLanguages}
                  skillSetOption={skillSetOption}
                  screeningQuestions={screeningQuestions}
                  setScreeningQuestions={setScreeningQuestions}
                  addJobData={addJobData}
                  addJobVal={addJobVal}
                  setAddJobData={setAddJobData}
                  skills={skills}
                  setSkills={setSkills}
                  certification={certification}
                  setCertification={setCertification}
                  edit={edit}
                />
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
export default InterviewPlan;
