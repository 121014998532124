import {
  GET_CHATGPT_RESPONSE_BEGIN,
  GET_CHATGPT_RESPONSE_SUCCESS,
  GET_CHATGPT_RESPONSE_FAILURE,
} from "../../actions/chatgpt/ChatGPT";

const initialState = {
  loading: false,
  chatGPTData: [],
};

const ChatGPTReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CHATGPT_RESPONSE_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_CHATGPT_RESPONSE_SUCCESS:
      return {
        ...state,
        chatGPTData: payload,
        loading: false,
      };
    case GET_CHATGPT_RESPONSE_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default ChatGPTReducer;
