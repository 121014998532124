import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { post } from "../../../service/ApiService";
import { get } from "../../../service/ApiService";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import SupportModal from "../../layout/SupportModal";
import LoginUser from "./LoginUser";
import Event from "../../../googleanalytic/Tracking";
import ReactGA from "react-ga";
import { getUserName, getUserRole } from "../../../service/AuthService";
import JobSearch from "../candidatecareers/jobdetails/JobSearch";
import { browserName } from "react-device-detect";
import axios from "axios";
import Notifications from "../../layout/Notifications";

const TopNavbar = (props) => {
  const TRACKING_ID = "UA-223748254-1";
  ReactGA.initialize(TRACKING_ID);
  const { tenant } = useParams();
  const history = useHistory();
  const [login, setLogin] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggleNotification, setToggleNotification] = useState(false);
  const [loginToggle, setLoginToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [pageReRender, setPageReRender] = useState(true); //use this state in to rerender the page , setPageReRender to !pageReRender and use in useEffect's dependency
  const [toggleHelp, setToggleHelp] = useState(false);
  const [supportModal, setSupportModal] = useState(false);
  const [supportSearchValue, setSupportSearchValue] = useState("");
  const [enableSupportSearch, setEnableSupportSearch] = useState(false);
  const [supportData, setSupportData] = useState([]);
  const [support, setSupport] = useState([]);
  const [supportType, setSupportType] = useState(0);
  const [candidateName, setCandidateName] = useState("");
  const [getActiveTab, setActiveTab] = useState(props.tab);
  const [ip, setIp] = useState("");
  const [isFetching, setIsFetching] = useState(false);
  const [notificationData, setNotificationData] = useState([]);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  const handleLogin = (name, url, email) => {
    setLogin(false);
    setEmail("");
    setPassword("");
    localStorage.setItem("isLoggedIn", "Yes");
    localStorage.setItem("name", name);
    localStorage.setItem("url", url);
    localStorage.setItem("email", email);
    setPageReRender(!pageReRender);
    setToggle(false);
    setLoginToggle(false);
  };

  const fetchNotifications = async () => {
    if (isFetching) {
      // A fetch is already in progress, so return early
      return;
    }

    try {
      setIsFetching(true); // Set the flag to indicate that a fetch is in progress
      const res = await post("/notifications/candidate", {
        userType: "candidate",
        userID: sessionStorage.getItem("userId"),
      });
      if (res.status === 200) {
        setNotificationData(res?.data);
      }
      // dispatch(getNotificationList());
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(false); // Reset the flag after the fetch is complete
    }
  };
  useEffect(() => {
    fetchNotifications();

    // Set up an interval to fetch notifications repeatedly
    const interval = setInterval(fetchNotifications, 60000); // Fetch every 60 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setCandidateName(props.candidateName);
  }, [props.candidateName]);

  useEffect(() => {
    if (!props?.isLoggedIn && props?.tab === "Offers") {
      setLogin(true);
    }
  }, [props?.isLoggedIn, props?.tab]);

  const splitMatchedText = (str) => {
    const regExp = new RegExp(supportSearchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='background: blue; color: white;'>${match}</span>`
    );
  };

  const openSupportModal = (e) => {
    e.preventDefault();
    setSupportType(+Object.values(e.target)[0].key);
    setSupportModal(true);
  };

  useEffect(() => {}, [pageReRender]);

  useEffect(() => {
    fetchSupportAPI(false);
  }, []);

  const handleLogout = async () => {
    Event(
      "Logout",
      `${sessionStorage.getItem("name")},Candidate`,
      window.location.pathname
    );
    setToggle(false);
    setLoginToggle(false);
    setLogin(false);
    ReactGA.event({
      user: getUserName(),
      role: getUserRole(),
      time: new Date(),
    });
    const params = {
      candidateId: sessionStorage.getItem("userId"),
      activity: "Logged Out",
      browser: browserName,
      ipAddress: ip,
    };
    setLoading(true);
    try {
      await post("/favourites/candidateLogout", params);
      setLoading(false);
      sessionStorage.clear();
      localStorage.clear();
      history.push(`/career/home`);
      props?.parent === "CandidateProfile" && props.setIsLoggedIn(false);
      props?.parent === "CandidateProfile" && history.push(`/career/home`);
    } catch (err) {
      setLoading(false);
    }
  };

  const getURL = () => sessionStorage.getItem("url");

  const closeSupportModal = () => {
    setSupportModal(false);
  };

  const fetchSupportAPI = async (isAdmin) => {
    setLoading(true);
    const res = await get("/support");
    if (res.status == 200) {
      setSupport(res.data.filter((s) => s.fk_role.includes(2)));
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  // Login user

  const loginUser = async () => {
    const params = { userName: email, password: password };

    setLoading(true);
    const res = await post("/candidateLogin/", params)
      .then((res) => {
        setLoading(false);
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("name", res.data.name);
        sessionStorage.setItem("userId", res.data.userId);
        sessionStorage.setItem("id", res.data.id);
        sessionStorage.setItem("userName", res.data.userName);
        props?.parent === "CandidateProfile" && props.setIsLoggedIn(true);
        setLogin(false);
        setEmail("");
        setPassword("");

        if (getActiveTab === "Offers") {
          window.open(`/careers/1/profile/?tab=Offers`);
        } else {
          window.open(`/careers/${tenant}/profile`, "_self");
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Please provide correct email and password");
      });
  };

  const handleSupportSearch = (e) => {
    setSupportSearchValue(e.target.value);
    setEnableSupportSearch(true);
    setSupportData(
      support.filter((i) =>
        i.Title.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };
  // forgot password
  const forgetPassword = async (e) => {
    e.preventDefault();
    window.open(`/careers/forgot-passwordDS`, "_blank");
  };
  const toglleDiv = () => {
    if (toggle === false) {
      props.handleToggale(true);
      setToggle(true);
    } else {
      props.handleToggale(false);
      setToggle(false);
    }
  };
  const getIpData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res.data.ip);
  };
  useEffect(() => {
    getIpData();
  }, []);
  return (
    <>
      <div id="TopBar">
        {/* **** toggle div for main menu  ***** */}
        <div style={{ width: 200 }} className="nav-toggle-wrap">
          <button
            id="MainMenuToggle"
            onClick={toglleDiv}
            type="button"
            className="btn btn-icon nav-toggle btn-nav"
          >
            <i className="fal fa-angle-left"></i>
          </button>
          <div className="divider"></div>
        </div>
        {loading && <Loader />}
        {(window?.location.pathname?.includes("/careers/profile"))? "":  <JobSearch matches768={matches} isFrom="candidateModule" />}

        <div style={{ width: 250 }} className="extras ml-auto text-right mr-3">
          <div className="">
            {sessionStorage.getItem("token") === null &&
            localStorage.getItem("url") === null ? (
              <div className="extras text-right">
                <button
                  className="btn btn-sm  my-auto"
                  onClick={() => setLogin(true)}
                >
                  Login
                </button>
              </div>
            ) : (
              <div
              //  className="extras ml-auto text-right "
              >
                {
                  <button
                    type="button"
                    title="Notifications"
                    className="btn btn-action-panel btn-icon btn-nav "
                    onClick={() => setToggleNotification(!toggleNotification)}
                  >
                    <div class="notification-icon mr-2">
                      {" "}
                      <i class="fal fa-fw fa-bell"></i>{" "}
                      {notificationData?.data?.filter(
                        (n) => n.notificationStatus === "unread"
                      )?.length > 0 && (
                        <span class="notification-count">
                          {
                            notificationData?.data?.filter(
                              (n) => n.notificationStatus === "unread"
                            )?.length
                          }
                        </span>
                      )}
                    </div>
                  </button>
                }
                {toggleNotification && (
                  <Notifications
                    supportModal={supportModal}
                    notificationData={notificationData}
                    setToggleNotification={setToggleNotification}
                    isFrom={"candidate"}
                    userID={sessionStorage.getItem("userId")}
                    fetchNotifications={fetchNotifications}
                  />
                )}{" "}
                <button
                  type="button"
                  title="Support"
                  className="btn btn-action-panel btn-icon btn-nav mr-2"
                  onClick={() => setToggleHelp(!toggleHelp)}
                >
                  <i className="fal fa-fw fa-life-ring"></i>
                </button>
                {toggleHelp && (
                  <div
                    className="action-panel"
                    style={{
                      display: "revert",
                      right: "0px",
                      top: "50px",
                      textAlign: "left",
                    }}
                  >
                    <OutsideClickHandler
                      onOutsideClick={() =>
                        !supportModal && setToggleHelp(false)
                      }
                    >
                      <div></div>
                      <div
                        className="action-panel"
                        style={{
                          display: "revert",
                          right: "0px",
                          top: "50px",
                          textAlign: "left",
                        }}
                      >
                        <div className="py-3 px-4">
                          <p className="lead mb-0">Hello {candidateName}</p>
                          <strong>What can we help you with today?</strong>

                          <div className="search-field mb-3 mt-2">
                            <div className="field-wrap">
                              <input
                                type="search"
                                onChange={handleSupportSearch}
                                placeholder="Search Support"
                              />
                              <span className="icon">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-search"
                                ></i>
                              </span>
                            </div>
                          </div>
                          <strong>
                            <small>Common Questions</small>
                          </strong>
                          <ul className="mt-1 ml-0 list-unstyled">
                            {enableSupportSearch
                              ? supportData.length !== 0
                                ? supportData.map((s, i) => (
                                    <li>
                                      <a
                                        key={i}
                                        onClick={openSupportModal}
                                        href=""
                                        dangerouslySetInnerHTML={{
                                          __html: splitMatchedText(s.Title),
                                        }}
                                      ></a>
                                    </li>
                                  ))
                                : "No Questions available "
                              : support.length !== 0
                              ? support.map((s, i) => (
                                  <li>
                                    <a
                                      key={i}
                                      onClick={openSupportModal}
                                      href=""
                                    >
                                      {s.Title}
                                    </a>
                                  </li>
                                ))
                              : "No Questions available "}
                          </ul>
                        </div>
                      </div>
                    </OutsideClickHandler>
                  </div>
                )}{" "}
                <button
                  className="btn btn-icon dropdown-toggle"
                  type="button"
                  onClick={() => {
                    setLoginToggle(!loginToggle);
                  }}
                >
                  {!sessionStorage.getItem("profileImagePath") ||
                  sessionStorage.getItem("profileImagePath") == "null" ? (
                    <Avatar
                      className="avatar avatar-sm"
                      name={
                        sessionStorage.getItem("token") == null
                          ? localStorage.getItem("name")
                          : sessionStorage.getItem("name")
                      }
                      round="80px"
                      color="green"
                      src={getURL()}
                    />
                  ) : (
                    <img
                      src={sessionStorage.getItem("profileImagePath")}
                      alt=""
                      width="30"
                      height="30"
                      style={{
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                  )}
                </button>
                {loginToggle && (
                  <OutsideClickHandler
                    onOutsideClick={() => setLoginToggle(false)}
                  >
                    <div
                      className="dropdown-menu"
                      style={{
                        display: "revert",
                        right: "-23px",
                        top: "50px",
                      }}
                    >
                      {props.parent !== "CandidateProfile" &&
                        props.isFrom !== "editTalent" && (
                          <>
                            <Link
                              to={`/careers/profile/edittalent`}
                              className="dropdown-item"
                            >
                              <i className="fal fa-user mr-3"></i> Profile{" "}
                            </Link>
                            <hr />
                          </>
                        )}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleLogout();
                        }}
                        href="/careers/edittalent"
                        className="dropdown-item"
                      >
                        <i className="fal fa-sign-out mr-3"></i> Logout{" "}
                      </a>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {supportModal && (
        <SupportModal
          supportData={support[supportType]}
          closeSupportModal={closeSupportModal}
        />
      )}
      <LoginUser
        login={login}
        setLogin={setLogin}
        getActiveTab={getActiveTab}
        setIsLoggedIn={props.setIsLoggedIn}
      />
    </>
  );
};

export default TopNavbar;
