import React, { useRef, useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import phoneCode from "../../../../config/PhoneCode";
import GoogleLocationCityAPI from "../../../../location/GoogleLocationCityAPI";

function Contact(props) {
  const searchInput = useRef(null);
  const [isRemote, setIsRemote] = useState(true);
  const phCode = Object.entries(phoneCode).map((item) => item[1].code);

  const handleChange = (e) => {
    props.setContactInfo({
      ...props.contactInfo,
      [e.target.id]: e.target.value,
    });
  };

  useEffect(() => {
    if (props?.contactInfo?.city) {
      searchInput.current.value = props.contactInfo.city;
    }
  }, [props.contactInfo]);

  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    const formattedNumber = asYouType.input(
      value.replace(country.dialCode, "")
    );

    const isValid = asYouType.isValid() || value !== "";
    props.setContactInfo({
      ...props.contactInfo,
      homePhone: value,
      homePhoneCode: country,
      phoneValidation: isValid,
    });
  };

  const _onChangeText2 = (value, country) => {
    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    const formattedNumber = asYouType.input(
      value.replace(country.dialCode, "")
    );
    const isValid = asYouType.isValid();
    props.setContactInfo({
      ...props.contactInfo,
      workPhone: value,
      workPhoneCode: country,
      phoneValidation2: isValid,
    });
  };

  const setLocation = (address, searchInput) => {
    const city = address.locality || address.city;
    props.setContactInfo({
      ...props.contactInfo,
      city: city,
      state: address.state,
      country: address.country,
    });
    searchInput.current.value = city;
  };

  return (
    <>
      <div>
        <div className="card-body">
          <div className="row mb-2">
            <div className="col-lg-4">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="firstName"
                  placeholder="First Name*"
                  value={props.contactInfo.firstName}
                  style={{
                    border:
                      props.isFormValid &&
                      props.contactInfo.firstName == "" &&
                      props.contactInfoValidation.firstName &&
                      "2px solid #ff0000",
                  }}
                  onChange={(e) =>
                    props.setContactInfo({
                      ...props.contactInfo,
                      firstName: e.target.value.replace(/[^a-zA-Z\s]/, ""),
                    })
                  }
                />
                <label>First Name*</label>
              </div>
              {props.isFormValid &&
                props.contactInfo.firstName == "" &&
                props.contactInfoValidation.firstName && (
                  <small className="validation">
                    {props.contactInfoValidation.firstName}
                  </small>
                )}
            </div>
            <div className="col-lg-4">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="lastName"
                  placeholder="Last Name*"
                  value={props.contactInfo.lastName}
                  style={{
                    border:
                      props.isFormValid &&
                      props.contactInfo.lastName == "" &&
                      props.contactInfoValidation.lastName &&
                      "2px solid #ff0000",
                  }}
                  onChange={(e) =>
                    props.setContactInfo({
                      ...props.contactInfo,
                      lastName: e.target.value.replace(/[^a-zA-Z\s]/, ""),
                    })
                  }
                />
                <label>Last Name*</label>
              </div>
              {props.isFormValid &&
                props.contactInfo.lastName == "" &&
                props.contactInfoValidation.lastName && (
                  <small className="validation">
                    {props.contactInfoValidation.lastName}
                  </small>
                )}
            </div>
            <div className="col-lg-4">
              <div className="form-floating">
                <input
                  type="text"
                  className="form-control"
                  id="email"
                  placeholder="Email*"
                  value={
                    props.message == "JoinCommunity"
                      ? sessionStorage.getItem("email")
                      : props.contactInfo.email
                  }
                  disabled={props.message !== "JoinCommunity"}
                  onChange={handleChange}
                />
                <label>Email*</label>
              </div>
              {props.isFormValid && props.contactInfo.email == "" && (
                <small className="validation">*Required Field</small>
              )}
            </div>
          </div>
          <div className="row mb-2" style={{ marginTop: "-6px" }}>
            <div className="col-lg-6">
              <div className="mb-2">
                <label className="pt-1">Primary Phone*</label>
              </div>
              <div className="d-flex">
                <PhoneInput
                  containerClass="w-100"
                  inputClass="w-100"
                  value={`${props.contactInfo.homePhoneCode}${props.contactInfo.homePhone}`}
                  name="homePhone"
                  country={"us"}
                  style={{
                    border:
                      ((props.isFormValid &&
                        props.contactInfo.homePhone === ("" || undefined)) ||
                        (!props.contactInfo.phoneValidation &&
                          props.contactInfo.homePhone.length < 1)) &&
                      "2px solid #ff0000",
                  }}
                  onChange={(value, country) => {
                    _onChangeText(value, country);
                  }}
                />
              </div>
              {props.isFormValid &&
              props.contactInfo.homePhone === ("" || undefined) &&
              !props.contactInfo.phoneValidation ? (
                <small className="validation">*Required Field</small>
              ) : (
                !props.contactInfo.phoneValidation &&
                props.contactInfo.homePhone.length > 1 && (
                  <small className="validation">Invalid Phone</small>
                )
              )}
            </div>
            <div className="col-lg-6">
              <div className="mb-2">
                <label style={{ marginTop: "-14px" }}>Alternative Phone</label>
              </div>
              <div className="d-flex">
                <PhoneInput
                  containerClass="w-100"
                  inputClass="w-100"
                  name="workPhone"
                  country={"us"}
                  value={props.contactInfo.workPhone}
                  onChange={(value, country) => {
                    _onChangeText2(value, country);
                  }}
                />
              </div>
            </div>
          </div>

          {isRemote && (
            <>
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="form-floating">
                    <GoogleLocationCityAPI
                      isFormValid={props.isFormValid}
                      contactInfo={props.contactInfo}
                      contactInfoValidation={props.contactInfoValidation}
                      setLocation={setLocation}
                      searchInput={searchInput}
                    />
                    <label>City*</label>
                  </div>
                  {props.isFormValid &&
                    props.contactInfo.city == "" &&
                    props.contactInfoValidation.city && (
                      <small className="validation">*Required Field</small>
                    )}
                </div>
                {/* <div className="col-lg-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      placeholder="Address State"
                      value={props.contactInfo.state}
                      onChange={handleChange}
                      disabled
                    />
                    <label>State*</label>
                  </div>
                  {props.isFormValid && props.contactInfo.state == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div> */}
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      id="country"
                      placeholder="Address Country"
                      value={props.contactInfo.country}
                      style={{
                        border:
                          props.isFormValid &&
                          props.contactInfo.country == "" &&
                          "2px solid #ff0000",
                      }}
                      onChange={handleChange}
                      disabled
                    />
                    <label>Country*</label>
                  </div>
                  {props.isFormValid && props.contactInfo.country == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
export default Contact;
