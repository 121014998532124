import React from "react";

const NoRecord = () => {
  return (
    <div class="text-center p-5">
      <div class="avatar avatar-lg">
        <i aria-hidden="true" class="fad fa-bell"></i>
      </div>
      <div class="mt-2">No new notifications</div>
    </div>
  );
};

export default NoRecord;
