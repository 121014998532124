import React, { useState, useEffect } from "react";
import TopNavBar from "../../layout/TopNavBar";
import SideNav from "../../layout/SideNavBar";
import Modal from "react-bootstrap/Modal";
import CandidateInterviewDetails from "../interviews/CandidateInterviewDetails";
import { get } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import moment from "moment";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";

function CandidateInterviews(props) {
  const [toggle, setToggle] = useState(true);
  const [interviewdetails, setInterviewDetails] = useState(false);
  const [interview, setInterview] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [all, setAll] = useState(true);
  const [upcoming, setUpcoming] = useState(false);
  const [loading, setLoading] = useState(false);

  const toggleDiv = (type) => {
    toggle ? setToggle(false) : setToggle(true);
  };

  useEffect(() => {
    fetchInterviews();
  }, []);

  const fetchInterviews = async () => {
    setLoading(true);
    try {
      const res = await get(`/CandidateDashboard/getModernHireInterviews`);
      setInterviews(res.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      // toast.error('Failed to get Assessments')
    }
  };

  const updateTab = (type) => {
    if (type === "all") {
      setAll(true);
      setUpcoming(false);
    } else {
      setAll(false);
      setUpcoming(true);
    }
  };

  const openInterviewDetails = (item) => {
    setInterviewDetails(true);
    setInterview(item);
  };

  const closePopUP = () => {
    setInterviewDetails(false);
  };

  return (
    <>
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar handleToggale={toggleDiv} />
        <SideNav />
        <div id="Content">
          <section id="AssessmentsApp" className="section section-sm">
            <div className="hero">
              <div className="section pt-5">
                <div className="container">
                  <div className="row justify-content-center mb-5">
                    <div className="d-flex justify-content-between align-items-center col-7 font-white">
                      <h5 className="mb-0">Interviews</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-7">
                  <div
                    id="CandidateInterviews"
                    className="card mb-2 translate-up-100"
                  >
                    <div className="card-body">
                      <div class="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end mb-3 border-bottom-gray2">
                        <ul class="nav nav-tabs nav-tabs-tablet pt-1 w-100 justify-content-center">
                          <li class="nav-item">
                            <button
                              type="button"
                              onClick={() => {
                                updateTab("all");
                              }}
                              class={
                                all
                                  ? "active nav-link pb-3 mx-2 bg-transparent"
                                  : "nav-link pb-3 mx-2 bg-transparent"
                              }
                            >
                              <span class="tag mb-1">
                                {interviews.length}
                                {/* { matchedList.length > 0 && <span class="jewel"></span>} */}
                              </span>
                              <div>All</div>
                            </button>
                          </li>
                          <li class="nav-item">
                            <button
                              type="button"
                              onClick={() => updateTab("upcoming")}
                              class={
                                upcoming
                                  ? "nav-link pb-3 mx-2 bg-transparent active"
                                  : "nav-link pb-3 mx-2 bg-transparent"
                              }
                            >
                              <span class="tag mb-1">
                                {
                                  interviews.filter(
                                    (job) =>
                                      new Date(job.created_date) < new Date() ||
                                      job.Status !== "Completed"
                                  ).length
                                }
                                {/* { favoriteList.length > 0 && <span class="jewel"></span>} */}
                              </span>
                              <div>Upcoming</div>
                            </button>
                          </li>
                        </ul>
                      </div>
                      {all &&
                        (interviews.length !== 0 ? (
                          interviews.map((item) => (
                            <div className="d-flex-force w-100">
                              <a
                                // onClick={ () => openInterviewDetails(item) }
                                className="card card-flat w-100 mb-1"
                              >
                                <div className="card-body">
                                  <div>
                                    {item.Status === "Completed" ? (
                                      <div className="mb-1">
                                        <span class="tag tag-green1 font-bold">
                                          Completed
                                        </span>
                                      </div>
                                    ) : (
                                      <div className="mb-1">
                                        <span class="tag tag-orange1 font-bold">
                                          Upcoming
                                        </span>
                                      </div>
                                    )}
                                    <div className="d-flex justify-content-between align-items-center">
                                      <p className="mb-1">
                                        <strong>{item.reqTitle}</strong>
                                      </p>
                                    </div>
                                    <div>
                                      <div className="d-flex align-items-center my-1">
                                        <span className="d-flex align-items-center me-3">
                                          <i
                                            aria-hidden="true"
                                            className="far fa-suitcase me-2 font-brandBlue"
                                          ></i>
                                          <span className="font-regular font-primary font-gray1">
                                            {item.AssessmentType}
                                          </span>
                                        </span>
                                      </div>
                                      <div className="mb-1">
                                        <span className="font-regular">
                                          Scheduled On:{" "}
                                          <strong>
                                            {moment(item.created_date).format(
                                              "MM/DD/YYYY"
                                            )}
                                          </strong>
                                        </span>
                                      </div>
                                      <div className="mb-1">
                                        <span className="font-regular">
                                          Assigned By:{" "}
                                          <strong>
                                            {item.RecruiterFname +
                                              " " +
                                              item.RecruiterLname}
                                          </strong>
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </a>
                            </div>
                          ))
                        ) : (
                          <div>
                            <div className="text-center p-3">
                              <div className="avatar avatar-lg">
                                <i className="fad fa-comment-slash"></i>
                              </div>
                              <div className="mt-2">
                                No Inteviews at this time
                              </div>
                            </div>
                          </div>
                        ))}
                      {upcoming &&
                        (interviews.filter((job) => job.Status !== "Completed")
                          .length !== 0 ? (
                          interviews
                            .filter((job) => job.Status !== "Completed")
                            .map((item) => (
                              <div className="d-flex-force w-100">
                                <a
                                  // onClick={ () => openInterviewDetails(item) }
                                  className="card card-flat w-100 mb-1"
                                >
                                  <div className="card-body">
                                    <div>
                                      {
                                        <div className="mb-1">
                                          <span class="tag tag-orange1 font-bold">
                                            Upcoming
                                          </span>
                                        </div>
                                      }
                                      <div className="d-flex justify-content-between align-items-center">
                                        <p className="mb-1">
                                          <strong>{item.reqTitle}</strong>
                                        </p>
                                      </div>
                                      <div>
                                        <div className="d-flex align-items-center my-1">
                                          <span className="d-flex align-items-center me-3">
                                            <i
                                              aria-hidden="true"
                                              className="far fa-suitcase me-2 font-brandBlue"
                                            ></i>
                                            <span className="font-regular font-primary font-gray1">
                                              {item.AssessmentType}
                                            </span>
                                          </span>
                                        </div>
                                        <div className="mb-1">
                                          <span className="font-regular">
                                            Scheduled On:{" "}
                                            <strong>
                                              {moment(item.created_date).format(
                                                "MM/DD/YYYY"
                                              )}
                                            </strong>
                                          </span>
                                        </div>
                                        <div className="mb-1">
                                          <span className="font-regular">
                                            Assigned By:{" "}
                                            <strong>
                                              {item.RecruiterFname +
                                                " " +
                                                item.RecruiterLname}
                                            </strong>
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </a>
                              </div>
                            ))
                        ) : (
                          <div>
                            <div className="text-center p-3">
                              <div className="avatar avatar-lg">
                                <i className="fad fa-comment-slash"></i>
                              </div>
                              <div className="mt-2">
                                No Inteviews Scheduled at this time
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
      <Modal
        // size="lg"
        show={interviewdetails}
        dialogClassName="view-panel show"
      >
        <CandidateInterviewDetails
          interviewdetails={interview}
          closeModal={closePopUP}
        />
      </Modal>
    </>
  );
}

export default CandidateInterviews;
