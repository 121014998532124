import React, { useState, useEffect } from "react";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import StarRatings from "react-star-ratings";
import { ButtonLoader } from "../../../../../constants/Constants";
import { toast } from "react-toastify";
import { post } from "../../../../../service/ApiService";
import Autosuggest from "react-autosuggest";
import { dropDownData } from "../../../../directsourcing/Edit Talent DS/dropDownData";
import SkillsSearchable from "../../../commoncomponents/skills/SkillsSearchable";

function Skills({ data, getData, ip, browserName,updateDetails,
  setUpdateDetails, }) {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [skillSetList, setSkillSetList] = useState(
    JSON.parse(data.primarySkills)
  );

  const [tempSkillSetList, setTempSetSkillSetList] = useState(
    JSON.parse(data.primarySkills)
  );
  const [skillSet, setSkillSet] = useState("");

  const handleChangeInput = (skillSet) => {
    setSkillSet(skillSet);
  };

  const handleChange = (tags) => {
    setSkillSetList(tags);
  };

  const editSkills = () => {
    setReadOnlyMode(false);
    setSaveClicked(true);
  };

  const cancelSkills = () => {
    setSkillSetList(tempSkillSetList);
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
  };

  const saveSkills = async () => {
    if (skillSetList.length === 0) {
      toast.error("Atleast one skill is mandatory");
    } else {
      setSaveClicked(false);
      setSubmittingFrom(true);
      try {
        const parameters = {
          ...data,
          zipcode: data.zipcode,
          experiences: data.experiences,
          educations: data.educations,
          primarySkills: JSON.stringify(skillSetList),
          activity: "Skills updated",
          candidateId: data.userId,
          ipAddress: ip,
          browser: browserName,
        };
        const res = await post("/candidate/updatetalent", parameters);
        if (res.status === 200) {
          setTempSetSkillSetList(skillSetList);
          setSubmittingFrom(false);
          setShowSuccessMessage(true);
          // getData();
          setUpdateDetails(!updateDetails);
          setTimeout(() => {
            setReadOnlyMode(true);
            setShowSuccessMessage(false);
          }, 1000);
          toast.success("Updated successfully!");
        }
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };
  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 14px",
        width: "300px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.SourceName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue?.length;

      const suggestValues =
        inputLength === 0
          ? []
          : dropDownData.CandidateSkills.filter((item) =>
              item.SourceName.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion.SourceName}</div>;

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.SourceName);
        }}
        theme={theme}
      />
    );
  };

  return (
    <>
      <div>
        <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
          <div class="card card-lg">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between">
                <h6 class="mb-0">Skills*</h6>
                {readOnlyMode ? (
                  <button
                    type="button"
                    class="btn btn-text"
                    onClick={editSkills}
                  >
                    <i class="fas fa-pencil-alt mr-1"></i>
                    Edit
                  </button>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={cancelSkills}
                      class="btn btn-sm btn-secondary mr-1"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={saveSkills}
                      class="btn btn-sm btn-primary"
                    >
                      {saveClicked && <span>Save Changes</span>}

                      {submittingForm && (
                        <span>
                          Saving Changes
                          <img width="20px" src={ButtonLoader} alt="" />
                        </span>
                      )}
                      {showSuccessMessage && <span>Changes Saved!</span>}
                    </button>
                  </div>
                )}
              </div>
              <hr />
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="col-lg-12">
                  {readOnlyMode ? (
                    <>
                      <div class="form-floating mb-4">
                        <label>Skills</label>
                        <div class="pt-5 px-3">
                          {skillSetList.map((skill) => (
                            <span class="tag tag-blue3 mr-1 mt-1">{skill}</span>
                          ))}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div class="w-100 mb-4">
                        <label className="mb-1">Skills*</label>

                        <SkillsSearchable
                          onChange={handleChange}
                          inputArr={skillSetList}
                          skillinput={skillSet}
                          onChangeInput={handleChangeInput}
                          inputProps={{
                            placeholder: "Add Skill",
                          }}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  );
}

export default Skills;
