import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { LoginAppLogo } from "../../constants/Constants";
import { post } from "../../service/ApiService";
import Loader from "../Loader/Loader";

const CandidateForgotPassword = () => {
  let [email, setEmail] = useState("");
  let [loading, setLoading] = useState(false);

  const forgetPassword = async (e) => {
    e.preventDefault();
    if (email == "") {
      toast.error("Enter your email");
    } else {
      const params = {
        email: email,
      };

      setLoading(true);
      const res = await post("/forgotpassword/candidate", params)
        .then((res) => {
          toast.success(
            `Password reset link has been successfully sent to '${email}'`
          );
          setLoading(false);
          setEmail("");
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Email not found");
        });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div id="ForgotPassword" className="login-layout">
        <div className="col-left d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-6 col-lg-8">
                <div>
                  <a className="logo" href="/">
                    <img src={LoginAppLogo} alt="" />
                  </a>
                  <div className="section section-sm">
                    <h1>Forgot Password?</h1>
                    <p>
                      Enter the email address of your account and we’ll send you
                      the instructions to reset your password
                    </p>
                    <form>
                      <div className="form-floating mb-3">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <label>Email address*</label>
                      </div>
                      <button
                        type="submit"
                        onClick={forgetPassword}
                        className="btn btn-dark w-100"
                        disabled={loading}
                      >
                        <span>Reset Password</span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-right"></div>
      </div>
    </>
  );
};

export default CandidateForgotPassword;
