
export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const GET_RECRUITER_DATA_REQUEST = 'GET_RECRUITER_DATA_REQUEST';
export const GET_RECRUITER_DATA_SUCCESS = 'GET_RECRUITER_DATA_SUCCESS';
export const GET_RECRUITER_DATA_FAILURE = 'GET_RECRUITER_DATA_FAILURE';

export const GET_DROPDOWN_LIST_REQUEST = 'GET_DROPDOWN_LIST_REQUEST';
export const GET_DROPDOWN_LIST_SUCCESS = 'GET_DROPDOWN_LIST_SUCCESS';
export const GET_DROPDOWN_LIST_FAILURE = 'GET_DROPDOWN_LIST_FAILURE';