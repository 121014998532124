import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import SubPoolCard from "./Card";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loader from "../../commoncomponents/Loader/Loader";
import {
  getTalentSubPoolList,
  getTalentSubPoolCount,
} from "../../../../actions/talent/TalentSubPoolList";
import Pagination from "../../../components_v3/pagination/pagination";
import Sort from "../../commoncomponents/sort/Index";
import { debounce } from "lodash";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const TalentSubPoolList = ({
  setPool,
  setSubPool,
  setSelectedPool,
  industry,
  setSelectedIndustry,
  isFrom,
}) => {
  const dispatch = useDispatch();
  const [talentSubPools, setTalentSubPools] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  /****************Sort Params******************/
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortType, setSortType] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);

  /****************Sort Params******************/
  const [searchText, setSearchText] = useState("");
  const dataSize = 9;

  const {
    talentSubPoolData,
    talentSubPoolCountLoading,
    talentSubPoolCount,
    talentSubPoolLoading,
  } = useSelector(
    (state) => ({
      talentSubPoolData: state.getTalentSubPoolListReducer.talentSubPoolData,
      talentSubPoolCount: state.getTalentSubPoolListReducer.talentSubPoolCount,
      talentSubPoolLoading:
        state.getTalentSubPoolListReducer.talentSubPoolLoading,
      talentSubPoolCountLoading:
        state.getTalentSubPoolListReducer.talentSubPoolCountLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let data = {
      currentPage: 1,
      limit: 9,
      sortParam: sortArray,
      searchText: searchText,
      industry: industry,
    };
    dispatch(getTalentSubPoolList(data));
    let subData = {
      searchText: "",
      industry: industry,
    };
    dispatch(getTalentSubPoolCount(subData));
  }, []);

  useEffect(() => {
    let sortData = sortObject;
    const tempArray = sortData.map((item) => item.value);
    setSortType(tempArray);
  }, []);

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    let data = {
      currentPage: pageNumber,
      limit: 9,
      sortParam: sortArray,
      searchText: searchText,
      industry: industry,
    };
    dispatch(getTalentSubPoolList(data));
  };

  const sortObject = [
    {
      id: 1,
      label: "Name",
      value: "name",
    },
    {
      id: 2,
      label: "Created On",
      value: "createdOn",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];
  const applySort = (sortedData) => {
    setSort(false);
    setCurrentPage(1);
    let payload = {
      currentPage: 1,
      limit: 9,
      sortParam: sortArray,
      searchText: searchText,
      industry: industry,
      // filterObject: getFilterParamsNew("", ""),
    };
    dispatch(getTalentSubPoolList(payload));
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    setSortApplied(false);
    let sortData = sortObject;
    const tempArray = sortData.map((item) => item.value);
    setSortType(tempArray);
    setSort(false);
    setCurrentPage(1);
    let payload = {
      currentPage: 1,
      limit: 10,
      searchText: searchText,
      sortParam: [],
      industry: industry,
    };
    dispatch(getTalentSubPoolList(payload));
  };
  const debouncedSearch = useCallback(
    debounce((searchText) => {
      let payload = {
        currentPage: 1,
        limit: 9,
        sortParam: sortArray, // Ensure sortArray is defined or passed appropriately
        searchText: searchText,
        industry: industry,
      };
      dispatch(getTalentSubPoolList(payload));
      let subData = {
        searchText: searchText,
        industry: industry,
      };
      dispatch(getTalentSubPoolCount(subData));
    }, 500),
    []
  );
  const handleSearchText = (val) => {
    setSearchText(val);
    setCurrentPage(1);
    debouncedSearch(val);
  };

  // Clean up the debounce function on component unmount
  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const handleBackClick = () => {
    setSelectedIndustry(null);
  };

  return (
    <>
      {talentSubPoolCountLoading || talentSubPoolLoading ? (
        <Loader />
      ) : (
        <div>
          <Row>
            <Col md={12} sm={12} lg={12}>
              <Row className="d=flex justify-content-end">
                <Col
                  md={6}
                  sm={12}
                  lg={6}
                  className="d-flex justify-content-start pr-4 mt-3"
                >
                  {isFrom === "Industry" && (
                    <FontAwesomeIcon
                      icon={faArrowLeft}
                      className="ml-3"
                      style={{ cursor: "pointer" }}
                      onClick={handleBackClick}
                    />
                  )}
                </Col>
                <Col
                  md={6}
                  sm={12}
                  lg={6}
                  className="d-flex justify-content-end pr-4"
                >
                  <input
                    type="search"
                    placeholder="Search by Sub Pool Name"
                    value={searchText}
                    style={{
                      width: "200px",
                      border: "1px solid rgb(221, 221, 221)",
                    }}
                    onChange={(e) => handleSearchText(e.target.value)}
                  />
                  <Sort
                    sort={sort}
                    setSort={setSort}
                    sortCount={sortCount}
                    sortData={talentSubPoolData}
                    orderArray={orderList}
                    sortObjects={sortObject}
                    applySort={applySort}
                    setSortCount={setSortCount}
                    setSortArray={setSortArray}
                    sortArray={sortArray}
                    sortingFor={"Sort by Name and Created On"}
                    sortType={sortType}
                    setSorttype={setSortType}
                    sortApplied={sortApplied}
                    setSortApplied={setSortApplied}
                    sortClearAll={sortClearAll}
                  />
                </Col>
              </Row>
            </Col>
            <Col md={12} sm={12} lg={12}>
              {talentSubPoolData && talentSubPoolData?.length > 0 ? (
                <Row className="d-flex gap-4 p-4">
                  {talentSubPoolData?.map((item) => {
                    return (
                      <>
                        <SubPoolCard
                          data={item}
                          setPool={setPool}
                          setSubPool={setSubPool}
                          setSelectedPool={setSelectedPool}
                        />
                      </>
                    );
                  })}
                </Row>
              ) : (
                <Row
                  style={{ height: "60vh" }}
                  className="d-flex justify-content-center align-content-center"
                >
                  <Col md={12} sm={12} lg={12} className="text-center">
                    <NoRecord />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={12} sm={12} lg={12} className="p-4">
                  {talentSubPoolCount && talentSubPoolCount[0] && (
                    <>
                      <Pagination
                        currentPage={currentPage}
                        totalItems={talentSubPoolCount[0]?.subPoolCount}
                        itemsPerPage={dataSize}
                        onPageChange={handlePageChange}
                        limit={9}
                      />
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default TalentSubPoolList;
