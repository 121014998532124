import {
  GET_AUDIT_LOG_JOB_ID_FILTER_DATA_BEGINS,
  GET_AUDIT_LOG_JOB_ID_FILTER_DATA_SUCCESS,
  GET_AUDIT_LOG_JOB_ID_FILTER_DATA_FAILURE,
} from "../../actions/auditlog/getAuditLogJobIdFilterData";

const initialState = {
  loading: false,
  auditLogJobIdFilterData: [],
};

const auditLogJobIdFilterDataReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_AUDIT_LOG_JOB_ID_FILTER_DATA_BEGINS:
      return {
        ...state,
        loading: true,
        auditLogJobIdFilterData: [],
      };
    case GET_AUDIT_LOG_JOB_ID_FILTER_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        auditLogJobIdFilterData: payload,
      };
    case GET_AUDIT_LOG_JOB_ID_FILTER_DATA_FAILURE:
      return {
        ...state,
        loading: false,
        auditLogJobIdFilterData: payload,
      };
    default:
      return state;
  }
};

export default auditLogJobIdFilterDataReducer;
