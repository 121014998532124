import React, { useEffect, useState } from "react";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Loader from "../../Loader/Loader";
import PoolList from "../directsourcing/talentcommunity/poollist/PoolList";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getTenant } from "../../../actions/tenant/getTenant";
import TalentList from "../talent/talentcommunity/Index";

const CommunityCards = () => {
  const { client } = useParams();
  const [showTab, setShowTab] = useState("comm");
  const [analytics, setAnalytics] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const passedData = location.state;
  const dispatch = useDispatch();
  const [tenantDetails, setTenantDetails] = useState({});
  const [showAddPool, setShowAddPool] = useState(false);

  const { tenantData, tenantLoading } = useSelector(
    (state) => ({
      tenantData: state.getTenantReducer.tenantData,
      tenantLoading: state.getTenantReducer.tenantLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getTenant({ tenantId: client }));
  }, [client, dispatch]);

  useEffect(() => {
    if (tenantData && tenantData.length > 0) {
      setTenantDetails(tenantData[0]);
    }
  }, [tenantData]);

  const onTabClick = (tab) => {
    switch (tab) {
      case "comm":
        setShowTab("comm");
        break;
      case "subPool":
        setShowTab("subPool");
        break;
      case "pool":
        setShowTab("pool");
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (passedData?.tab) {
      onTabClick(passedData.tab);
    }
  }, [passedData]);

  return (
    <>
      {tenantLoading && <Loader />}
      <div className="row justify-content-center">
        <div className="d-flex mb-2" style={{ alignItems: "center" }}>
          {tenantDetails?.logourl && (
            <img
              src={tenantDetails?.logourl || ""}
              width="65"
              height="65"
              className="p-2"
              style={{
                borderRadius: "50%",
                objectFit: "contain",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
              }}
            />
          )}
          <h5 className="ml-3">{tenantDetails?.tenantname}</h5>
        </div>
        <div className="col-lg-12">
          <div className="card card-flat card-borderless p-4 p-0-600 mb-3">
            <div className="row">
              <div className="col-lg-10">
                <div className="d-flex mr-3 mb-0 mb-xl-0">
                  <div className="d-flex justify-content-between align-items-center mt-3">
                    <ul className="nav nav-tabs">
                      <li className="nav-item">
                        <button
                          onClick={() => onTabClick("pool")}
                          type="button"
                          className={
                            showTab === "pool"
                              ? "nav-link pb-1 active"
                              : "nav-link pb-1"
                          }
                        >
                          <p>Talent Pool </p>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          onClick={() => onTabClick("subPool")}
                          type="button"
                          className={
                            showTab === "subPool"
                              ? "nav-link pb-1 active"
                              : "nav-link pb-1"
                          }
                        >
                          <p>Talent Sub Pool </p>
                        </button>
                      </li>
                      <li className="nav-item">
                        <button
                          onClick={() => onTabClick("comm")}
                          type="button"
                          className={
                            showTab === "comm"
                              ? "nav-link pb-1 active"
                              : "nav-link pb-1"
                          }
                        >
                          <p>Communities</p>
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <hr className="mt-0 pt-0" />
            {showTab === "comm" && <PoolList type="client" clientId={client} />}
            {showTab === "pool" && (
              <TalentList curation={true} setShowTab={setShowTab} />
            )}
            {showTab === "subPool" && (
              <TalentList
                admin={true}
                curation={true}
                setShowTab={setShowTab}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommunityCards;
