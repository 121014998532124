import React, { useEffect, useState } from "react";
import SideNavBar from "../../layout/SideNavBar";
import TopNavBar from "../../layout/TopNavBar";
import AdminConsole from "./AdminConsole";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";

function Index() {
  const [toggle, setToggle] = useState(true);

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  return (
    <body
      className={
        toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
      }
    >
      <div className="body-decorator body-decorator-top"></div>
      <div className="body-decorator body-decorator-bottom"></div>
      <TopNavBar parent="adminConsole" handleToggale={toggleDiv} />
      <SideNavBar />
      <div id="Content">
        <div className="section section-sm">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 px-5">
                <h5 className="mb-4">Admin Console</h5>
                <AdminConsole />
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </div>
    </body>
  );
}

export default Index;
