import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useHistory, useLocation, useParams } from "react-router";
import { toast } from "react-toastify";
import PoolScreeningQuesRes from "./PoolScreeningQuesRes";
import { post } from "../../../../service/ApiService";
import Loader from "../../../Loader/Loader";
import ShortUniqueId from "short-unique-id";

const PoolScreeningQues = ({
  job,
  setAddTalent,
  setIsVetting,
  setScreeningAnswers,
  submitAddTalent,
  candidateDetails,
  setApply,
  dispositionID,
  screeningStatus,
  setSocialMediaApply,
}) => {
  console.log("dispositionID", dispositionID);
  const jobData = job;
  const { tenant, recruiterid } = useParams();
  const history = useHistory();
  const [count, setCount] = useState(0);
  const [applyDisable, setApplyDisable] = useState(true);
  const [inputList, setInputList] = useState([{ value: "" }]);
  const [certificationList, setCertificationList] = useState([]);
  const [QandACompleted, setQandACompleted] = useState([]);
  const [disabledSelect, setDisabledSelect] = useState(false);
  const [showTextBox, setShowTextBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const uid = new ShortUniqueId({ length: 10 });
  const search = useLocation().recruiter;
  useEffect(() => {
    setQandACompleted((prevQandACompleted) => {
      // Your existing logic for QandACompleted
      const updatedQandACompleted = JSON.parse(jobData?.screeningQuestion).map(
        (item, idx) => {
          if (item.name === "Language") {
            const languages = JSON.parse(item.input);
            // Create an array of objects with default answers
            const defaultAnswers = languages.map((language) => ({
              [language]: "",
            }));
            return {
              ...item,
              answer: defaultAnswers,
            };
          } else if (item.name === "Certification") {
            const arr = Array.isArray(item?.input)
              ? item.input.map((e) => ({
                  value: e,
                  label: e,
                }))
              : JSON.parse(item.input).map((e) => ({
                  value: e,
                  label: e,
                }));
            arr.push(
              { value: "Other", label: "Other" },
              { value: "None", label: "None" }
            );
            // Update certificationList state outside the rendering cycle
            setCertificationList(arr);
            return {
              ...item,
              answer: [],
            };
          } else if (
            item.name === "Shift Preference" ||
            item.name === "Preferred Location" ||
            item.name === "Work Preference" ||
            item.name === "Willing to relocate" ||
            item.name === "Nursing License" ||
            item.name === "Nursing Specialty" ||
            item.name == "License State" ||
            item.input == "Drop-down" ||
            item.input == "Drop-down(Multiselect)"
          ) {
            return {
              ...item,
              answer: [],
            };
          } else if (item.input === "Numeric") {
            return {
              ...item,
              answer: "0",
            };
          } else {
            return {
              ...item,
              answer: "",
            };
          }
        }
      );
      return updatedQandACompleted;
    });
  }, [jobData?.screeningQuestion]);
  const onChangeFunc = (e, question, idx, i, type) => {
    setQandACompleted(
      QandACompleted.map((item, id) => {
        if (idx === id) {
          if (
            question.name === "Background Check" ||
            question.name === "Drug Check" ||
            question.name === "Driving License" ||
            question.name === "Location" ||
            question.name === "Remote Work" ||
            // question.name === "Work Experience" ||
            question.name === "Urgent Hiring Need"
          ) {
            e.target.checked ? setCount(count + 1) : setCount(count - 1);
            return { ...question, answer: e.target.value };
          } else if (
            question.name === "Custom Question" &&
            question.input === "Yes/No"
          ) {
            setCount(count + 1);
            return { ...question, answer: e.target.value };
          } else if (question.name === "Education") {
            setCount(count + 1);
            return { ...question, answer: [e.target.value] };
          } else if (question.name === "Certification") {
            setCount(count + 1);
            if (type !== "other" && e?.some((o) => o.value === "None")) {
              setDisabledSelect(true);
              setShowTextBox(false);
              setInputList([{ value: "" }]);
              return { ...question, answer: ["None"] };
            } else if (
              type !== "other" &&
              e?.some((o) => o.value === "Other")
            ) {
              setShowTextBox(true);
              setInputList([{ value: "" }]);
              return {
                ...question,
                answer: e.map((option) => option.value),
              };
            } else if (type == "other") {
              const { name, value } = e.target;
              const list = [...inputList];
              list[i][name] = value;
              setInputList(list);
              let arr = list.map((option) => option.value);
              return {
                ...question,
                other: [...arr],
              };
            } else {
              setDisabledSelect(false);
              setShowTextBox(false);
              return { ...question, answer: e.map((option) => option.value) };
            }
          } else if (
            question.name === "Shift Preference" ||
            item.name === "Preferred Location" ||
            item.name === "Work Preference" ||
            item.name === "Willing to relocate" ||
            item.name === "Nursing License" ||
            item.name === "Nursing Specialty" ||
            item.name === "License State" ||
            item.input == "Drop-down" ||
            item.input == "Drop-down(Multiselect)"
          ) {
            setCount(count + 1);
            if (item.input === "Drop-down") {
              return { ...question, answer: [e.value] };
            } else {
              return { ...question, answer: e.map((option) => option.value) };
            }
          } else {
            setCount(count + 1);
            return { ...question, answer: e.target.value };
          }
        } else {
          return item;
        }
      })
    );
  };

  useEffect(() => {
    let screeningQues = QandACompleted?.filter((item) => item.isMandatory);
    let counter = 0;
    screeningQues.forEach((element) => {
      if (element.answer == "" || element.answer === false) {
        counter = counter + 1;
      } else if (element.name == "Language") {
        let hasEmptyValue = element.answer.some(
          (item) => Object.values(item)[0] === ""
        );
        if (hasEmptyValue) {
          counter = counter + 1;
        }
      }
    });
    setApplyDisable(counter > 0 ? true : false);
  }, [count, QandACompleted]);

  const screeningValidation = () => {
    let errorData = [];
    let isValid = true;
    QandACompleted.map((elem) => {
      if (
        (elem.answer == "" || elem.answer === false) &&
        elem.isMandatory == true
      ) {
        errorData.push("Please answer the mandatory questions");
      }
    });
    if (errorData.length > 0) {
      toast.error(errorData[0]);
      isValid = false;
    }
    return isValid;
  };

  const handleProceed = () => {
    let isValid = screeningValidation();
    if (isValid) {
      // setScreeningAnswers(QandACompleted);
      const data = {
        screeningQuestions: JSON.stringify(QandACompleted),
        dispositionID: dispositionID?.dispositionID,
      };
      setLoading(true);
      post("/careerspool/updateDispositionScreening", data).then(
        async (res) => {
          await InviteTalentWithVet();
          toast.success(
            `${
              QandACompleted.length > 1
                ? "Screening questions completed successfully"
                : "Screening question completed successfully"
            }`
          );
        }
      );
      // setIsVetting(true);
      // setAddTalent(true);
      // submitAddTalent();
    }
  };

  const InviteTalentWithVet = async () => {
    try {
      var date = new Date();
      let vettingDetails = JSON.parse(jobData.vettingDetails);

      let paramObj = {
        isPoolCandidate: false,
        tenantName: jobData.tenantName,
        jobTitle: jobData.jobTitle,
        candidateInfo: {
          firstName: candidateDetails.firstName,
          lastName: candidateDetails.lastName,
          email: candidateDetails.email,
          phone: candidateDetails.homePhone,
        },
        expiryDate: date.setDate(date.getDate() + 7),
        createdFrom: "talentPool",
        testAssign: vettingDetails._id,
        vettingDetails: vettingDetails,
        uniqueCode: uid(),
        testStatus: "Invited",
        recruiterId: recruiterid,
        candidateId: dispositionID?.candidateID,
        jobId: jobData?.poolId,
        fk_tenant: tenant,
        reviewerFirstName: job?.reviewerFirstName,
        reviewerLastName: job?.reviewerLastName,
        reviewerEmail: job?.reviewerEmail,
        poolowner: job.poolowner,
      };

      const vetRes = await post("/vetIntegration/candidate/Invite", paramObj);
      if (vetRes.status === 200) {
        setLoading(false);
        setApply(false);
        setTimeout(() => {
          history.push(`/career/home`);
          window.location.reload();
        }, 5000);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div style={{ marginTop: "-14px" }}>
        {screeningStatus ? (
          <div
            className="row mx-3 text-center"
            style={{
              height: "300px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h6 className="text-danger">
              <i className="fas fa-exclamation-circle mr-2"></i>

              {QandACompleted.length > 1
                ? "You've have been already answered the questions"
                : "You've have been already answered the question"}
            </h6>
          </div>
        ) : (
          <div
            className={`bl-modal-body `}
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <div className="d-flex px-2 justify-content-between my-2">
              <h5 className=" ml-4">Screening Questions </h5>
            </div>
            <hr className="p-0 m-0" />

            <div className="mb-4 p-3 ">
              <div className="row mx-3 ">
                <h6>
                  You are almost there, please answer the following screening
                  questions and complete your application
                </h6>
              </div>
              <div className="row mx-3"></div>
              <PoolScreeningQuesRes
                QandACompleted={QandACompleted}
                onChangeFunc={onChangeFunc}
                setQandACompleted={setQandACompleted}
                count={count}
                setCount={setCount}
                disabledSelect={disabledSelect}
                inputList={inputList}
                setInputList={setInputList}
                certificationList={certificationList}
                showTextBox={showTextBox}
                setShowTextBox={setShowTextBox}
              />

              <div className="float-end my-3 mr-4">
                <button
                  className="btn btn-sm btn-secondary "
                  onClick={() => handleProceed()}
                  disabled={applyDisable || screeningStatus}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PoolScreeningQues;
