import { toast } from "react-toastify";
import { get } from "../../service/ApiService";

export const GET_RECRUITER_DATA_BEGIN = "GET_RECRUITER_DATA_BEGIN";
export const GET_RECRUITER_DATA_SUCCESS = "GET_RECRUITER_DATA_SUCCESS";
export const GET_RECRUITER_DATA_FAILURE = "GET_RECRUITER_DATA_FAILURE";


export const getRecruiterData = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECRUITER_DATA_BEGIN,
    });
    const res = await get("/dashboard/csm/getrecruiterdata");
    if (res.status === 200) {
      dispatch({
        type: GET_RECRUITER_DATA_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RECRUITER_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};