import {
  GET_TALENT_SUB_POOL_LIST_BEGIN,
  GET_TALENT_SUB_POOL_LIST_SUCCESS,
  GET_TALENT_SUB_POOL_LIST_FAILURE,
  GET_TALENT_SUB_POOL_COUNT_BEGIN,
  GET_TALENT_SUB_POOL_COUNT_SUCCESS,
  GET_TALENT_SUB_POOL_COUNT_FAILURE,
} from "../../actions/talent/TalentSubPoolList";

const initialState = {
  talentSubPoolLoading: false,
  talentSubPoolData: [],
  talentSubPoolCount: 0,
  talentSubPoolCountLoading: false,
};

const getTalentSubPoolListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TALENT_SUB_POOL_LIST_BEGIN:
      return {
        ...state,
        talentSubPoolLoading: true,
        talentSubPoolData: [],
      };
    case GET_TALENT_SUB_POOL_LIST_SUCCESS:
      return {
        ...state,
        talentSubPoolData: payload,
        talentSubPoolLoading: false,
      };
    case GET_TALENT_SUB_POOL_LIST_FAILURE:
      return {
        ...state,
        talentSubPoolData: payload,
        talentSubPoolLoading: false,
      };
    case GET_TALENT_SUB_POOL_COUNT_BEGIN:
      return {
        ...state,
        talentSubPoolCount: 0,
        talentSubPoolCountLoading: true,
      };
    case GET_TALENT_SUB_POOL_COUNT_SUCCESS:
      return {
        ...state,
        talentSubPoolCount: payload,
        talentSubPoolCountLoading: false,
      };
    case GET_TALENT_SUB_POOL_COUNT_FAILURE:
      return {
        ...state,
        talentSubPoolCount: 0,
        talentSubPoolCountLoading: false,
      };
    default:
      return state;
  }
};

export default getTalentSubPoolListReducer;
