import React from "react";
import Pdf from "react-to-pdf";
import { getPreciseDateDiff } from "../../../function/CommonFunction";
import { TenantImage } from "../../../../constants/Constants";
import moment from "moment";

const ref = React.createRef();

const ExportJobPdf = (props) => {
  const jobData = props.jobData;
  const fullText = props.fullText;
  const tenant = props.tenant;
  const fileName = "JobId:" + jobData.jobId;

  return (
    <>
      {
        <body>
          <div ref={ref}>
            <div className="card card-flat m-3 p-3">
              <div id="Content border border-primary">
                <section id="TestApp">
                  <div className="hero">
                    <div className="section pt-1 pb-2">
                      <div className="row justify-content-center mb-0 ">
                        <div className="text-center mt-4 mb-4 font-white text-capitalize">
                          <h5 className="mb-0"> {fullText.jobTitle}</h5>
                          <p>
                            {fullText.location.city !== undefined
                              ? fullText.location.city  || "Remote"
                              : ""}{" "}
                            {fullText.location.city !== "" && ","} 
                            {fullText.location.state !== undefined
                              ? fullText.location.state
                              : ""}{" "}
                            {fullText.location.state !== "" && ","}
                            {fullText.location.country !== undefined
                              ?  fullText.location.country
                              : ""}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="page-content">
                    <div className="container">
                      <div className="row">
                        <div className="col-md-9">
                          <div className="page-content-wrapper">
                            <div className="Sspace"></div>
                            <div className="jobsort">
                              <div className="jobsortheading"></div>
                              <div className="clientdiv">
                                <div className="clienttext">
                                  <h5>{fullText.jobTenant}</h5>
                                </div>
                                <div className="jobview"></div>
                                <ul></ul>
                              </div>
                              <div className="jobviewpostdiv">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className="jobviewpostopen">
                                      Posted on :{" "}
                                      <span>
                                        {moment(jobData.jobPostDate).format(
                                          "MM/DD/YYYY"
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="jobviewpostopen">
                                      Openings:{" "}
                                      <span>{jobData.positionCount}</span>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="jobviewpostopen"></div>
                                  </div>
                                </div>
                              </div>
                              <div className="Sspace"></div>
                              
                              <div className="linediv"></div>
                              <div className="jdexpandiv">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="jobviewpostopen">
                                      Job Title : &nbsp;
                                      <span>
                                        <strong>{fullText.jobTitle}</strong>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-12">
                                    <div className="jobviewpostopen">
                                      Position Type : &nbsp;
                                      <span>
                                        <strong>{jobData.jobType}</strong>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="linediv"></div>
                              <div className="Sspace"></div>
                              <div className="jdexpandiv">
                                <div className="deptdetails">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="jobviewpostopen">
                                        Skills : &nbsp;
                                        <span>
                                          <strong>
                                            {fullText.primarySkills &&
                                              fullText.primarySkills.join(", ")}
                                          </strong>
                                        </span>
                                      </div>
                                    </div>
                                    {fullText.secondarySkills.length > 0 && (
                                      <div className="col-md-12">
                                        <div className="jobviewpostopen">
                                          Secondary Skills : &nbsp;
                                          <span>
                                            <strong>
                                              {fullText.secondarySkills &&
                                                fullText.secondarySkills.join(
                                                  ", "
                                                )}
                                            </strong>
                                          </span>
                                        </div>
                                      </div>
                                    )}
                                    {jobData.skillSet.length > 0 ? (
                                      <div className="col-md-12">
                                        <div className="jobviewpostopen">
                                          Skill set : &nbsp;
                                          <span>
                                            <strong>
                                              {jobData.skillSet &&
                                                jobData.skillSet.join(", ")}
                                            </strong>
                                          </span>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="Sspace"></div>
                              <div className="deptdetails">
                                <div className="jobdetails">
                                  <h5>Job Description</h5>
                                </div>
                                <div className="jobdetaillist">
                                  <ul>
                                    <li>
                                      <p>
                                        <ol
                                          className="pr-3"
                                          dangerouslySetInnerHTML={{
                                            __html: jobData.jobDescription,
                                          }}
                                        ></ol>
                                      </p>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="col-md-3">
                                                    <div className="Sspace"></div>
                                                    <div className="about company">
                                                        <div className="clienttext">
                                                            <h5>About Company</h5>
                                                        </div>
                                                        <div className="aboutcompanylogo">
                                                            <img src={TenantImage} alt='blank'></img>
                                                        </div>
                                                        <div className="aboutcompanyname">
                                                            <h5>{fullText.jobTenant}</h5>
                                                            <div className="Sspace"></div>
                                                            {tenant && <h5>Company Info</h5>}
                                                            <p>
                                                                {tenant}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div> */}
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          <div className="bl-modal-footer text-center">
            <Pdf targetRef={ref} filename={fileName}>
              {({ toPdf }) => (
                <button
                  type="button"
                  className="btn btn-sm btn-gray mb-3 mt-3 ml-3"
                  onClick={toPdf}
                >
                  Save As PDF
                </button>
              )}
            </Pdf>
          </div>
        </body>
      }
    </>
  );
};

export default ExportJobPdf;
