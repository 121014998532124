import React, { useEffect, useState } from "react";

const GigFilter = (props) => {
  // console.log(props)
  let dataLimit = 4;
  let pageLimit = 5;
  const [candidateNameEnabled, setCandidateNameEnabled] = useState(false);
  const [candidateName, setCandidateName] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [candidateUserTypeEnabled, setCandidateUserTypeEnabled] = useState(
    false
  );
  const [candidateUserType, setCandidateUserType] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [candidateSourceEnabled, setCandidateSourceEnabled] = useState(false);
  const [candidateSource, setCandidateSource] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [candidateStatusEnabled, setCandidateStatusEnabled] = useState(false);
  const [candidateStatus, setCandidateStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [candidateCityEnabled, setCandidateCityEnabled] = useState(false);
  const [candidateCity, setCandidateCity] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage1, setCurrentPage1] = useState(1);
  const [searchData1, setSearchData1] = useState([]);
  const [searchValue1, setSearchValue1] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [searchData2, setSearchData2] = useState([]);
  const [searchValue2, setSearchValue2] = useState("");
  const [currentPage3, setCurrentPage3] = useState(1);
  const [searchData3, setSearchData3] = useState([]);
  const [searchValue3, setSearchValue3] = useState("");
  const [currentPage4, setCurrentPage4] = useState(1);
  const [searchData4, setSearchData4] = useState([]);
  const [searchValue4, setSearchValue4] = useState("");
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [paginationGroup1, setPaginationGroup1] = useState([]);
  const [paginationArray1, setPaginationArray1] = useState([]);
  const [paginationGroup2, setPaginationGroup2] = useState([]);
  const [paginationArray2, setPaginationArray2] = useState([]);
  const [paginationGroup3, setPaginationGroup3] = useState([]);
  const [paginationArray3, setPaginationArray3] = useState([]);
  const [paginationGroup4, setPaginationGroup4] = useState([]);
  const [paginationArray4, setPaginationArray4] = useState([]);

  useEffect(() => {
    setSelectedName(props.selectedName);
    setCandidateName(props.candidateName);
    setSelectedUserType(props.selectedUserType);
    setCandidateUserType(props.candidateUserType);
    setSelectedSource(props.selectedSource);
    setCandidateSource(props.candidateSource);
    setSelectedStatus(props.selectedStatus);
    setCandidateStatus(props.candidateStatus);
    setSelectedCity(props.selectedCity);
    setCandidateCity(props.candidateCity);
    console.log(props);
  }, [props]);

  const titleHandler = (checked, name) => {
    if (checked) {
      setSelectedName([...selectedName, name]);
    } else {
      setSelectedName(selectedName.filter((t) => t !== name));
    }
  };

  const userTypeHandler = (checked, userType) => {
    if (checked) {
      setSelectedUserType([...selectedUserType, userType]);
    } else {
      setSelectedUserType(selectedUserType.filter((t) => t !== userType));
    }
  };

  const sourceHandler = (checked, source) => {
    if (checked) {
      setSelectedSource([...selectedSource, source]);
    } else {
      setSelectedSource(selectedSource.filter((t) => t !== source));
    }
  };

  const statusHandler = (checked, status) => {
    if (checked) {
      setSelectedStatus([...selectedStatus, status]);
    } else {
      setSelectedStatus(selectedStatus.filter((t) => t !== status));
    }
  };

  const cityHandler = (checked, city) => {
    if (checked) {
      setSelectedCity([...selectedCity, city]);
    } else {
      setSelectedCity(selectedCity.filter((t) => t !== city));
    }
  };

  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }
  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  function changePage1(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage1(pageNumber);
  }
  function changePage1(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage1(pageNumber);
  }

  function changePage2(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage2(pageNumber);
  }
  function changePage2(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage2(pageNumber);
  }

  function changePage3(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage3(pageNumber);
  }
  function changePage3(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage3(pageNumber);
  }

  function changePage4(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage4(pageNumber);
  }
  function changePage4(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage4(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : candidateName.slice(startIndex, endIndex);
  };

  const getPaginatedData1 = () => {
    const startIndex = currentPage1 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue1
      ? searchData1.slice(startIndex, endIndex)
      : candidateUserType.slice(startIndex, endIndex);
  };

  const getPaginatedData2 = () => {
    const startIndex = currentPage2 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue2
      ? searchData2.slice(startIndex, endIndex)
      : candidateSource.slice(startIndex, endIndex);
  };

  const getPaginatedData3 = () => {
    const startIndex = currentPage3 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue3
      ? searchData3.slice(startIndex, endIndex)
      : candidateStatus.slice(startIndex, endIndex);
  };

  const getPaginatedData4 = () => {
    const startIndex = currentPage4 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue4
      ? searchData4.slice(startIndex, endIndex)
      : candidateCity.slice(startIndex, endIndex);
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(candidateName?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(candidateName?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue1
      ? setPaginationArray1(
          new Array(Math.ceil(searchData1?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray1(
          new Array(Math.ceil(candidateUserType?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue1
      ? setPaginationGroup1(
          new Array(Math.ceil(searchData1?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup1(
          new Array(Math.ceil(candidateUserType?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue2
      ? setPaginationArray2(
          new Array(Math.ceil(searchData2?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray2(
          new Array(Math.ceil(candidateSource?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue2
      ? setPaginationGroup2(
          new Array(Math.ceil(searchData2?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup2(
          new Array(Math.ceil(candidateSource?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue3
      ? setPaginationArray3(
          new Array(Math.ceil(searchData3?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray3(
          new Array(Math.ceil(candidateStatus?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue3
      ? setPaginationGroup3(
          new Array(Math.ceil(searchData3?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup3(
          new Array(Math.ceil(candidateStatus?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue4
      ? setPaginationArray4(
          new Array(Math.ceil(searchData4?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray4(
          new Array(Math.ceil(candidateCity?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue4
      ? setPaginationGroup4(
          new Array(Math.ceil(searchData4?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup4(
          new Array(Math.ceil(candidateCity?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    setCurrentPage(1);
    setCurrentPage1(1);
    setCurrentPage2(1);
    setCurrentPage3(1);
    setCurrentPage4(1);
  }, [
    candidateName,
    searchData,
    candidateUserType,
    searchData1,
    candidateSource,
    searchData2,
    candidateStatus,
    searchData3,
    candidateCity,
    searchData4,
  ]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      } else if ((currentPage1 - 1) % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(
            currentPage1 - 1,
            currentPage1 + (pageLimit - 1)
          )
        );
      } else if ((currentPage2 - 1) % pageLimit === 0 && currentPage2 !== 1) {
        setPaginationGroup2(
          paginationArray2.slice(
            currentPage2 - 1,
            currentPage2 + (pageLimit - 1)
          )
        );
      } else if ((currentPage3 - 1) % pageLimit === 0 && currentPage3 !== 1) {
        setPaginationGroup3(
          paginationArray3.slice(
            currentPage3 - 1,
            currentPage3 + (pageLimit - 1)
          )
        );
      } else if ((currentPage4 - 1) % pageLimit === 0 && currentPage4 !== 1) {
        setPaginationGroup4(
          paginationArray4.slice(
            currentPage4 - 1,
            currentPage4 + (pageLimit - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      } else if (currentPage1 % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(currentPage1 - pageLimit, currentPage1)
        );
      } else if (currentPage2 % pageLimit === 0 && currentPage2 !== 1) {
        setPaginationGroup2(
          paginationArray2.slice(currentPage2 - pageLimit, currentPage2)
        );
      } else if (currentPage3 % pageLimit === 0 && currentPage3 !== 1) {
        setPaginationGroup3(
          paginationArray3.slice(currentPage3 - pageLimit, currentPage3)
        );
      } else if (currentPage4 % pageLimit === 0 && currentPage4 !== 1) {
        setPaginationGroup4(
          paginationArray4.slice(currentPage4 - pageLimit, currentPage4)
        );
      }
    }
  }, [currentPage, currentPage1, currentPage2, currentPage3, currentPage4]);
  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      } else if ((currentPage1 - 1) % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(
            currentPage1 - 1,
            currentPage1 + (pageLimit - 1)
          )
        );
      } else if ((currentPage2 - 1) % pageLimit === 0 && currentPage2 !== 1) {
        setPaginationGroup2(
          paginationArray2.slice(
            currentPage2 - 1,
            currentPage2 + (pageLimit - 1)
          )
        );
      } else if ((currentPage3 - 1) % pageLimit === 0 && currentPage3 !== 1) {
        setPaginationGroup3(
          paginationArray3.slice(
            currentPage3 - 1,
            currentPage3 + (pageLimit - 1)
          )
        );
      } else if ((currentPage4 - 1) % pageLimit === 0 && currentPage4 !== 1) {
        setPaginationGroup4(
          paginationArray4.slice(
            currentPage4 - 1,
            currentPage4 + (pageLimit - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      } else if (currentPage1 % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(currentPage1 - pageLimit, currentPage1)
        );
      } else if (currentPage2 % pageLimit === 0 && currentPage2 !== 1) {
        setPaginationGroup2(
          paginationArray2.slice(currentPage2 - pageLimit, currentPage2)
        );
      } else if (currentPage3 % pageLimit === 0 && currentPage3 !== 1) {
        setPaginationGroup3(
          paginationArray3.slice(currentPage3 - pageLimit, currentPage3)
        );
      } else if (currentPage4 % pageLimit === 0 && currentPage4 !== 1) {
        setPaginationGroup4(
          paginationArray4.slice(currentPage4 - pageLimit, currentPage4)
        );
      }
    }
  }, [currentPage, currentPage1, currentPage2, currentPage3, currentPage4]);

  const splitMatchedText = (str) => {
    let regExp;
    if (searchValue) {
      regExp = new RegExp(searchValue, "gi");
    } else if (searchValue1) {
      regExp = new RegExp(searchValue1, "gi");
    } else if (searchValue2) {
      regExp = new RegExp(searchValue2, "gi");
    } else if (searchValue3) {
      regExp = new RegExp(searchValue3, "gi");
    } else if (searchValue4) {
      regExp = new RegExp(searchValue4, "gi");
    }

    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchData(
      candidateName.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch1 = (e) => {
    setSearchValue1(e.target.value);
    setSearchData1(
      candidateUserType.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch2 = (e) => {
    setSearchValue2(e.target.value);
    setSearchData2(
      candidateSource.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch3 = (e) => {
    setSearchValue3(e.target.value);
    setSearchData3(
      candidateStatus.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch4 = (e) => {
    setSearchValue4(e.target.value);
    setSearchData4(
      candidateCity.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const titleArray = (name) => {
    //console.log((selectedName?.length > 0 ? selectedName.includes(name) : true))
    return selectedName?.length > 0 ? selectedName.includes(name) : true;
  };

  const userTypeArray = (userType) => {
    //console.log((selectedUserType?.length > 0 ? selectedUserType.includes(userType) : true),"usertype")
    if (selectedUserType?.length === 2 || selectedUserType?.length === 0) {
      return true;
    } else {
      if (selectedUserType[0] === "Individual Recruiter") {
        return userType ? false : true;
      } else {
        return userType ? true : false;
      }
    }
  };

  const sourceArray = (source) => {
    if (selectedSource?.length === 2 || selectedSource?.length === 0) {
      return true;
    } else {
      if (selectedSource[0] === "Invite") {
        return source ? true : false;
      } else {
        return source ? false : true;
      }
    }
  };

  const statusArray = (status) => {
    return selectedStatus?.length > 0 ? selectedStatus.includes(status) : true;
  };

  const cityArray = (city) => {
    return selectedCity?.length > 0 ? selectedCity.includes(city) : true;
  };

  const clearAll = () => {
    setSelectedName([]);
    setSelectedUserType([]);
    setSelectedSource([]);
    setSelectedStatus([]);
    setSelectedCity([]);
    setSearchValue("");
    setSearchValue1("");
    setSearchValue2("");
    setSearchValue3("");
    setSearchValue4("");
  };

  const applyFilter = () => {
    let filteredData = props.reportData.filter((item) => {
      return (
        titleArray(item.firstName) &&
        userTypeArray(item.companyName) &&
        sourceArray(item.source) &&
        statusArray(item.status) &&
        cityArray(item.city)
      );
      //return titleArray(item.firstName )
    });
    props.applyFilter(
      filteredData,
      selectedName,
      selectedUserType,
      selectedSource,
      selectedStatus,
      selectedCity
    );
    // console.log(props.reportData,"filter")
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={applyFilter}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>
                Filter /{" "}
                {selectedName?.length +
                  selectedUserType?.length +
                  selectedSource?.length +
                  selectedStatus?.length +
                  selectedCity?.length}
              </h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear All
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateNameEnabled(!candidateNameEnabled)}
            >
              <i
                className={
                  candidateName
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Name</small>
                {selectedName?.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateNameEnabled &&
              (candidateName?.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Search name"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  {getPaginatedData().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedName.includes(j)}
                            className="form-check-input"
                            onChange={(e) => titleHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData()?.length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage === paginationArray.slice(-1)[0]
                        ? getPaginatedData()?.length
                        : searchValue
                        ? searchData?.length < dataLimit
                          ? searchData?.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateName?.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === 1 || getPaginatedData()?.length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage}
                        >
                          {item}
                        </button>
                      ))}
                      {paginationGroup.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === paginationArray.slice(-1)[0] ||
                          getPaginatedData()?.length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue("");
                        setSelectedName([]);
                      }}
                    >
                      <small>Clear Name</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() =>
                setCandidateUserTypeEnabled(!candidateUserTypeEnabled)
              }
            >
              <i
                className={
                  candidateUserType
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>User Type</small>
                {selectedUserType?.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {candidateUserTypeEnabled &&
              (candidateUserType?.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Search user type"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch1}
                    value={searchValue1}
                  />
                  {getPaginatedData1().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue1 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedUserType.includes(j)}
                            className="form-check-input"
                            onChange={(e) =>
                              userTypeHandler(e.target.checked, j)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData1()?.length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue1}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage1 === paginationArray1.slice(-1)[0]
                        ? getPaginatedData1()?.length
                        : searchValue1
                        ? searchData1?.length < dataLimit
                          ? searchData1?.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateUserType?.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage1((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage1 === 1 ||
                          getPaginatedData1()?.length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup1.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage1 === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage1}
                        >
                          {item}
                        </button>
                      ))}
                      {paginationGroup1.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage1 === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage1}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage1((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage1 === paginationArray1.slice(-1)[0] ||
                          getPaginatedData1()?.length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue1("");
                        setSelectedUserType([]);
                      }}
                    >
                      <small>Clear User Type</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateSourceEnabled(!candidateSourceEnabled)}
            >
              <i
                className={
                  candidateSource
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Source Channel</small>
                {selectedSource?.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateSourceEnabled &&
              (candidateSource?.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Search source channel"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch2}
                    value={searchValue2}
                  />
                  {getPaginatedData2().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue2 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedSource.includes(j)}
                            className="form-check-input"
                            onChange={(e) => sourceHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData2()?.length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue2}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage2 === paginationArray2.slice(-1)[0]
                        ? getPaginatedData2()?.length
                        : searchValue2
                        ? searchData2?.length < dataLimit
                          ? searchData2?.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateSource?.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage2((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage2 === 1 ||
                          getPaginatedData2()?.length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup2.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage2 === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage2}
                        >
                          {item}
                        </button>
                      ))}
                      {paginationGroup2.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage2 === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage2}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage2((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage2 === paginationArray2.slice(-1)[0] ||
                          getPaginatedData2()?.length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue2("");
                        setSelectedSource([]);
                      }}
                    >
                      <small>Clear Source</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateStatusEnabled(!candidateStatusEnabled)}
            >
              <i
                className={
                  candidateStatus
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Status</small>
                {selectedStatus?.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateStatusEnabled &&
              (candidateStatus?.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch3}
                    value={searchValue3}
                  />
                  {getPaginatedData3().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue3 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedStatus.includes(j)}
                            className="form-check-input"
                            onChange={(e) => statusHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData3()?.length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue3}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage3 === paginationArray3.slice(-1)[0]
                        ? getPaginatedData3()?.length
                        : searchValue3
                        ? searchData3?.length < dataLimit
                          ? searchData3?.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateStatus?.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage3((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage3 === 1 ||
                          getPaginatedData3()?.length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup3.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage3 === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage3}
                        >
                          {item}
                        </button>
                      ))}
                      {paginationGroup3.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage3 === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage3}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage3((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage3 === paginationArray3.slice(-1)[0] ||
                          getPaginatedData3()?.length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue3("");
                        setSelectedStatus([]);
                      }}
                    >
                      <small>Clear Status</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateCityEnabled(!candidateCityEnabled)}
            >
              <i
                className={
                  candidateCity
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Location</small>
                {selectedCity?.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateCityEnabled &&
              (candidateCity?.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch4}
                    value={searchValue4}
                  />
                  {getPaginatedData4().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue4 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedCity.includes(j)}
                            className="form-check-input"
                            onChange={(e) => cityHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData4()?.length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue4}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage4 === paginationArray4.slice(-1)[0]
                        ? getPaginatedData4()?.length
                        : searchValue4
                        ? searchData4?.length < dataLimit
                          ? searchData4?.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateCity?.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage4((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage4 === 1 ||
                          getPaginatedData4()?.length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup4.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage4 === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage4}
                        >
                          {item}
                        </button>
                      ))}
                      {paginationGroup4.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage4 === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage4}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage4((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage4 === paginationArray4.slice(-1)[0] ||
                          getPaginatedData4()?.length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue4("");
                        setSelectedCity([]);
                      }}
                    >
                      <small>Clear Location</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default GigFilter;
