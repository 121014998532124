import moment from "moment";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

function UserFilter(props) {
  const {
    setShowFilter,
    selectedYesOrNoStatus,
    setSelectedYesOrNoStatus,
    dispositionDetails,
    setFilterData,
    selectedNumericAnswers,
    setSelectedNumericAnswers,
    setSelectedTextAnswers,
    selectedTextAnswers,
    languagePreference,
    setLanguagePreference,
    setSelectedArrayAnswers,
    selectedArrayAnswers,
  } = props;

  const [questionsData, setQuestionData] = useState([]);
  const [textQuestions, setTextQuestions] = useState([]);
  const [textEnabled, setTextEnabled] = useState({});
  const [numQuestions, setNumQuestions] = useState([]);
  const [numericEnabled, setNumericEnabled] = useState({});
  const [arrayQuestions, setArrayQuestions] = useState([]);
  const [arrayEnabled, setArrayEnabled] = useState({});
  const [languageEnabled, setLanguageEnabled] = useState(false);
  const [industryEnabled, setIndustryEnabled] = useState(false);
  const [miscellaneousEnabled, setMiscellaneousEnabled] = useState({});

  const stringifyData = ["Shift Preference", "Education", "Language"];

  useEffect(() => {
    if (props.jobData) {
      let questions = JSON.parse(props?.jobData.screeningQuestion);
      questions = questions.filter(
        (item) => item.input === "Yes/No" || item.input === undefined
      );
      setQuestionData(questions);
    }
  }, [props.jobData]);

  const clearAll = () => {
    setSelectedYesOrNoStatus([]);
    setSelectedTextAnswers([]);
    setLanguagePreference([]);
    setSelectedNumericAnswers([]);
    setSelectedArrayAnswers([]);
  };

  const getAllTextNumQuestion = () => {
    let x = dispositionDetails
      .filter((x) => x.screeningQuestions)
      .map((i) => JSON.parse(i.screeningQuestions));
    const txMap = new Map();
    const numMap = new Map();
    const arrayMap = new Map();
    x.forEach((qArr) => {
      qArr.forEach((qData) => {
        if (qData.input === "Text" || qData.input === "Long Text") {
          const keyToCheck = qData.question;
          if (!txMap.has(keyToCheck)) {
            let data = qData.answer.split(",");
            txMap.set(keyToCheck, { ...qData, answer: data.map((e) => e) });
          } else {
            let data = qData.answer.split(",");
            const existingData = txMap.get(keyToCheck);
            data.forEach((e) => {
              existingData.answer.push(e);
            });
          }
        } else if (
          qData.input === "Numeric" ||
          qData.name === "Industry Experience"
        ) {
          const keyToCheck = qData.question;
          if (!numMap.has(keyToCheck)) {
            numMap.set(keyToCheck, { ...qData, answer: [qData.answer] });
          } else {
            const existingData = numMap.get(keyToCheck);
            !existingData.answer.includes(qData.answer) &&
              existingData.answer.push(qData.answer);
          }
        } else if (
          Array.isArray(qData.input) ||
          stringifyData.includes(qData.name) ||
          qData.input == "Drop-down" ||
          qData.input == "Drop-down(Multiselect)"
        ) {
          const keyToCheck = qData.question;
          if (!arrayMap.has(keyToCheck)) {
            let data = qData.answer;
            arrayMap.set(keyToCheck, { ...qData, answer: data.map((e) => e) });
          } else {
            let data = qData.answer;
            const existingData = arrayMap.get(keyToCheck);
            data &&
              data.forEach((e) => {
                existingData.answer.push(e);
              });
          }
        }
      });
    });

    const tx = Array.from(txMap.values());
    const num = Array.from(numMap.values());
    const arr = Array.from(arrayMap.values());
    const uniqueAnswers = getUniqueAnswersForArray(tx);
    setTextQuestions(uniqueAnswers);
    setNumQuestions(num);
    setArrayQuestions(getUniqueAnswersForArray(arr));
  };
  const getUniqueAnswersForArray = (data) => {
    if (!Array.isArray(data) || data.length === 0) {
      return [];
    }
    const result = data.map((obj) => {
      if (!obj.answer || !Array.isArray(obj.answer)) {
        return obj;
      }
      let uniqueAnswers = [];
      if (obj.name == "Language") {
        uniqueAnswers = obj.answer.filter(
          (obj, index, self) =>
            index ===
            self.findIndex((o) => JSON.stringify(o) === JSON.stringify(obj))
        );
      } else {
        uniqueAnswers = [
          ...new Set(obj.answer.map((answer) => answer.trim().toUpperCase())),
        ];
      }
      return {
        ...obj,
        answer: uniqueAnswers,
      };
    });

    return result;
  };

  useEffect(() => {
    getAllTextNumQuestion();
  }, [dispositionDetails]);

  const applyFilter = () => {
    if (
      selectedYesOrNoStatus.length > 0 ||
      selectedTextAnswers.length > 0 ||
      languagePreference.length > 0 ||
      selectedNumericAnswers.length > 0 ||
      selectedArrayAnswers.length > 0
    ) {
      const filterParam = {
        selectedYesOrNoStatus: selectedYesOrNoStatus,
        selectedTextAnswers: selectedTextAnswers,
        languagePreference: languagePreference,
        selectedNumericAnswers: selectedNumericAnswers,
        selectedArrayAnswers: selectedArrayAnswers,
      };
      props.handleFilter(filterParam);
    } else {
      setFilterData(dispositionDetails);
      setShowFilter(false);
    }
  };

  const YesOrNoQuestionHandler = (checked, question, value) => {
    if (checked) {
      const index = selectedYesOrNoStatus.findIndex(
        (item) => item.question === question
      );
      const temp = [...selectedYesOrNoStatus];
      if (index !== -1) {
        temp[index].answer = value;
      } else {
        temp.push({ question, answer: value });
      }
      setSelectedYesOrNoStatus(temp);
    } else {
      setSelectedYesOrNoStatus((prevStatus) =>
        prevStatus.filter((item) => item.question !== question)
      );
    }
  };

  const TextQuestionHandler = (checked, question, answer) => {
    if (checked) {
      const existingQuestion = selectedTextAnswers.find(
        (el) => el.question === question
      );

      if (existingQuestion) {
        const updatedAnswers = [...existingQuestion.answer, answer];
        setSelectedTextAnswers((prevStatus) => [
          ...prevStatus.filter((item) => item.question !== question),
          { question, answer: updatedAnswers },
        ]);
      } else {
        setSelectedTextAnswers([
          ...selectedTextAnswers,
          { question, answer: [answer] },
        ]);
      }
    } else {
      setSelectedTextAnswers((prevStatus) =>
        prevStatus
          .map((item) => {
            if (item.question === question) {
              const updatedAnswers = item.answer.filter(
                (ans) => ans !== answer
              );
              return updatedAnswers.length > 0
                ? { question, answer: updatedAnswers }
                : null;
            }
            return item;
          })
          .filter(Boolean)
      );
    }
  };

  const numQuestionsHandler = (checked, questionData, value, index) => {
    let question = questionData.question;
    let selected = selectedNumericAnswers;
    if (checked)
      setSelectedNumericAnswers([
        ...selected,
        { question: question, answer: value, id: index },
      ]);
    else {
      let x = selected.filter(
        (obj) =>
          !(
            obj.question === question &&
            obj.answer === value &&
            obj.id === index
          )
      );
      setSelectedNumericAnswers(x);
    }
  };

  const arrayQuestionsHandler = (checked, question, answer) => {
    if (checked) {
      const existingQuestion = selectedArrayAnswers.find(
        (el) => el.question === question
      );

      if (existingQuestion) {
        const updatedAnswers = [...existingQuestion.answer, answer];
        setSelectedArrayAnswers((prevStatus) => [
          ...prevStatus.filter((item) => item.question !== question),
          { question, answer: updatedAnswers },
        ]);
      } else {
        setSelectedArrayAnswers([
          ...selectedArrayAnswers,
          { question, answer: [answer] },
        ]);
      }
    } else {
      setSelectedArrayAnswers((prevStatus) =>
        prevStatus
          .map((item) => {
            if (item.question === question) {
              const updatedAnswers = item.answer.filter(
                (ans) => ans !== answer
              );
              return updatedAnswers.length > 0
                ? { question, answer: updatedAnswers }
                : null;
            }
            return item;
          })
          .filter(Boolean)
      );
    }
  };

  const languageHandler = (checked, status, language) => {
    let alreadyExists =
      languagePreference &&
      languagePreference.some((item) => item[language] === status);
    if (checked && !alreadyExists) {
      setLanguagePreference([
        ...languagePreference,
        {
          [language]: status,
        },
      ]);
    } else if (!checked && alreadyExists) {
      setLanguagePreference((prevLanguagePreference) =>
        prevLanguagePreference.filter((item) => item[language] !== status)
      );
    }
  };

  return (
    <>
      <div className="view-panel show ">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={applyFilter}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>
                Filter /{" "}
                {selectedYesOrNoStatus.length +
                  languagePreference.length +
                  selectedNumericAnswers.length +
                  (selectedTextAnswers[0]?.answer
                    ? selectedTextAnswers[0].answer.length
                    : 0) +
                  (selectedArrayAnswers[0]?.answer
                    ? selectedArrayAnswers[0].answer.length
                    : 0)}
              </h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear all
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <div className="mb-2">
            <strong>Screening Questions</strong>
            {questionsData && questionsData?.length > 0 ? (
              <div className="mb-4 ml-4 pl-1 pt-1 mt-2">
                <div className="m-1">
                  {textQuestions?.map((item, index) => (
                    <div className="row">
                      <button
                        type="button"
                        className="btn btn-collapsible"
                        onClick={() =>
                          setTextEnabled({
                            ...textEnabled,
                            [item.question]: textEnabled[item.question]
                              ? !textEnabled[item.question]
                              : true,
                          })
                        }
                      >
                        <i
                          className={
                            textEnabled[item.question] != true
                              ? "fal fa-angle-down fa-fw"
                              : "fal fa-angle-up fa-fw"
                          }
                        />
                        <span class="p-relative">
                          <strong style={{ fontSize: "14px" }}>
                            {item.question}
                          </strong>
                          {selectedTextAnswers.filter(
                            (val) => val.question == item.question
                          ).length > 0 && <span className="jewel"></span>}
                        </span>
                      </button>
                      {textEnabled[item.question] &&
                        item.answer.map((ed) => (
                          <div className="d-flex align-items-center m-1">
                            <div>
                              <span>{ed ? ed : "NOT AVAILABLE"}</span>
                            </div>

                            <div className="ml-auto">
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectedTextAnswers.some(
                                    (obj, i) =>
                                      obj["answer"].includes(ed) &&
                                      obj["question"] === item.question
                                  )}
                                  onChange={(e) =>
                                    TextQuestionHandler(
                                      e.target.checked,
                                      item.question,
                                      ed
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      {textEnabled[item.question] && (
                        <div className="d-flex justify-content-end mt-2 px-1">
                          <button
                            className="btn btn-text"
                            disabled={selectedTextAnswers.length === 0}
                            onClick={() => {
                              setSelectedTextAnswers(
                                selectedTextAnswers.filter(
                                  (val) => val.question != item.question
                                )
                              );
                            }}
                          >
                            <small>
                              Clear{" "}
                              {item.name != "Custom Question" && item.name}
                            </small>
                          </button>
                        </div>
                      )}
                      <p className="p-0" />
                    </div>
                  ))}
                </div>
                {numQuestions.map((item, index) => (
                  <div className="m-1">
                    {item.name == "Industry Experience" ? (
                      <div className="row">
                        <button
                          type="button"
                          className="btn btn-collapsible"
                          onClick={() => setIndustryEnabled(!industryEnabled)}
                        >
                          <i
                            className={
                              !industryEnabled
                                ? "fal fa-angle-down fa-fw"
                                : "fal fa-angle-up fa-fw"
                            }
                          />
                          <span class="p-relative">
                            <strong style={{ fontSize: "14px" }}>
                              {item.question}{" "}
                            </strong>
                            {selectedNumericAnswers.filter(
                              (val) => val.question == item.question
                            ).length > 0 && <span className="jewel"></span>}
                          </span>
                        </button>

                        {item.name == "Industry Experience" &&
                          industryEnabled &&
                          item.answer?.sort().map((ed, edi) => (
                            <div className="d-flex align-items-center m-1">
                              <div>
                                <span>
                                  {ed
                                    ? ed == 1 || ed == 0
                                      ? ed + " year"
                                      : ed + " years"
                                    : "NOT AVAILABLE"}
                                </span>
                              </div>
                              <div className="ml-auto">
                                <div className="form-check form-switch">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={selectedNumericAnswers.some(
                                      (obj, i) =>
                                        obj["answer"] === ed &&
                                        obj["question"] === item.question
                                    )}
                                    onChange={(e) =>
                                      numQuestionsHandler(
                                        e.target.checked,
                                        item,
                                        ed,
                                        index
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        {item.name == "Industry Experience" && industryEnabled && (
                          <div className="d-flex justify-content-end mt-2 px-1">
                            <button
                              className="btn btn-text"
                              disabled={
                                selectedNumericAnswers.filter(
                                  (val) => val.question == item.question
                                ).length === 0
                              }
                              onClick={() => {
                                setSelectedNumericAnswers(
                                  selectedNumericAnswers.filter(
                                    (val) => val.question != item.question
                                  )
                                );
                              }}
                            >
                              <small>
                                Clear{" "}
                                {item.name != "Custom Question" && item.name}
                              </small>
                            </button>
                          </div>
                        )}
                        <p className="p-0" />
                      </div>
                    ) : (
                      <div className="row">
                        <button
                          type="button"
                          className="btn btn-collapsible"
                          onClick={() =>
                            setNumericEnabled({
                              ...numericEnabled,
                              [item.question]: numericEnabled[item.question]
                                ? !numericEnabled[item.question]
                                : true,
                            })
                          }
                        >
                          <i
                            className={
                              numericEnabled[item.question] != true
                                ? "fal fa-angle-down fa-fw"
                                : "fal fa-angle-up fa-fw"
                            }
                          />
                          <span class="p-relative">
                            <strong style={{ fontSize: "14px" }}>
                              {item.question}
                            </strong>
                            {selectedNumericAnswers.filter(
                              (val) => val.question == item.question
                            ).length > 0 && <span className="jewel"></span>}
                          </span>
                        </button>

                        {numericEnabled[item.question] &&
                          item.answer?.sort().map((ed, edi) => (
                            <div className="d-flex align-items-center m-1">
                              <div>
                                <span>
                                  {item.name == "Custom Question"
                                    ? ed
                                    : ed
                                    ? ed == 1 || ed == 0
                                      ? ed + " year"
                                      : ed + " years"
                                    : "NOT AVAILABLE"}
                                </span>
                              </div>
                              <div className="ml-auto">
                                <div className="form-check form-switch">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={selectedNumericAnswers.some(
                                      (obj, i) =>
                                        obj["answer"] === ed &&
                                        obj["question"] === item.question
                                    )}
                                    onChange={(e) =>
                                      numQuestionsHandler(
                                        e.target.checked,
                                        item,
                                        ed,
                                        index
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        {numericEnabled[item.question] && (
                          <div className="d-flex justify-content-end mt-2 px-1">
                            <button
                              className="btn btn-text"
                              disabled={
                                selectedNumericAnswers.filter(
                                  (val) => val.question == item.question
                                ).length === 0
                              }
                              onClick={() => {
                                setSelectedNumericAnswers(
                                  selectedNumericAnswers.filter(
                                    (val) => val.question != item.question
                                  )
                                );
                              }}
                            >
                              <small>
                                Clear{" "}
                                {item.name != "Custom Question" && item.name}
                              </small>
                            </button>
                          </div>
                        )}
                        <p className="p-0" />
                      </div>
                    )}
                  </div>
                ))}
                {arrayQuestions.map((item, index) => (
                  <div className="m-1">
                    {item.name === "Language" ? (
                      <div className="row">
                        <button
                          type="button"
                          className="btn btn-collapsible"
                          onClick={() => setLanguageEnabled(!languageEnabled)}
                        >
                          <i
                            className={
                              !languageEnabled
                                ? "fal fa-angle-down fa-fw"
                                : "fal fa-angle-up fa-fw"
                            }
                          />
                          <span class="p-relative">
                            <strong style={{ fontSize: "14px" }}>
                              {item.question}
                            </strong>
                            {languagePreference.length > 0 && (
                              <span className="jewel"></span>
                            )}
                          </span>
                        </button>
                        {languageEnabled &&
                          JSON.parse(item.input).map((ed, edIndex) => (
                            <div key={edIndex} className="m-1">
                              <div>
                                <div className="mt-1">
                                  <strong>
                                    <span>{ed}</span>
                                  </strong>
                                </div>
                                <div>
                                  {ed &&
                                    [
                                      "Not Proficient",
                                      "Beginner",
                                      "Intermediate",
                                      "Expert",
                                    ].map((level, levelIndex) => (
                                      <div
                                        key={levelIndex}
                                        className="d-flex align-items-center m-1 ml-auto"
                                      >
                                        <div className="mr-3">
                                          <span>{level}</span>
                                        </div>
                                        <div className="ml-auto">
                                          <div className="form-check form-switch">
                                            <input
                                              type="checkbox"
                                              className="form-check-input"
                                              checked={languagePreference.some(
                                                (obj) => obj[ed] === level
                                              )}
                                              onChange={(e) =>
                                                languageHandler(
                                                  e.target.checked,
                                                  level,
                                                  ed
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          ))}
                        {languageEnabled && (
                          <div className="d-flex justify-content-end mt-2 px-1">
                            <button
                              className="btn btn-text"
                              disabled={languagePreference.length === 0}
                              onClick={() => {
                                setLanguagePreference([]);
                              }}
                            >
                              <small>
                                Clear{" "}
                                {item.name != "Custom Question" && item.name}
                              </small>
                            </button>
                          </div>
                        )}
                        <p className="p-0" />
                      </div>
                    ) : (
                      <div className="row">
                        <button
                          type="button"
                          className="btn btn-collapsible"
                          onClick={() =>
                            setArrayEnabled({
                              ...arrayEnabled,
                              [item.question]: arrayEnabled[item.question]
                                ? !arrayEnabled[item.question]
                                : true,
                            })
                          }
                        >
                          <i
                            className={
                              arrayEnabled[item.question] != true
                                ? "fal fa-angle-down fa-fw"
                                : "fal fa-angle-up fa-fw"
                            }
                          />
                          <span class="p-relative">
                            <strong style={{ fontSize: "14px" }}>
                              {item.question}
                            </strong>
                            {selectedArrayAnswers.filter(
                              (val) => val.question == item.question
                            ).length > 0 && <span className="jewel"></span>}
                          </span>
                        </button>

                        {arrayEnabled[item.question] &&
                          item.answer?.sort().map((ed, edi) => (
                            <div className="d-flex align-items-center m-1">
                              <div>
                                <span>{ed ? ed : "NOT AVAILABLE"}</span>
                              </div>
                              <div className="ml-auto">
                                <div className="form-check form-switch">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={selectedArrayAnswers.some(
                                      (obj, i) =>
                                        obj["answer"].includes(ed) &&
                                        obj["question"] == item.question
                                    )}
                                    onChange={(e) =>
                                      arrayQuestionsHandler(
                                        e.target.checked,
                                        item.question,
                                        ed
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        {arrayEnabled[item.question] && (
                          <div className="d-flex justify-content-end mt-2 px-1">
                            <button
                              className="btn btn-text"
                              disabled={
                                selectedArrayAnswers.filter(
                                  (val) => val.question == item.question
                                ).length === 0
                              }
                              onClick={() => {
                                setSelectedArrayAnswers(
                                  selectedArrayAnswers.filter(
                                    (val) => val.question != item.question
                                  )
                                );
                              }}
                            >
                              <small>
                                Clear{" "}
                                {item.name != "Custom Question" && item.name}
                              </small>
                            </button>
                          </div>
                        )}
                        <p className="p-0" />
                      </div>
                    )}
                  </div>
                ))}
                {questionsData.map((item, index) => (
                  <div className="m-1">
                    {(item.input === "Yes/No" || item.input === undefined) && (
                      <div className="row">
                        <button
                          type="button"
                          className="btn btn-collapsible"
                          onClick={() =>
                            setMiscellaneousEnabled({
                              ...miscellaneousEnabled,
                              [item.question]: miscellaneousEnabled[
                                item.question
                              ]
                                ? !miscellaneousEnabled[item.question]
                                : true,
                            })
                          }
                        >
                          <i
                            className={
                              miscellaneousEnabled[item.question] != true
                                ? "fal fa-angle-down fa-fw"
                                : "fal fa-angle-up fa-fw"
                            }
                          />
                          <span class="p-relative">
                            <strong style={{ fontSize: "14px" }}>
                              {item.question}
                            </strong>
                          </span>
                        </button>

                        {miscellaneousEnabled[item.question] && (
                          <div className="row">
                            <div className="p-0 d-flex">
                              <div className="col-md-9 ml-2 mt-2">
                                <span>Yes</span>
                              </div>
                              <div className="col-md-3 d-flex align-items-center m-1">
                                <div className="ml-auto mr-2">
                                  <div className="form-check form-switch">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={selectedYesOrNoStatus.some(
                                        (obj) =>
                                          obj["answer"] === "Yes" &&
                                          obj["question"] === item.question
                                      )}
                                      onChange={(e) =>
                                        YesOrNoQuestionHandler(
                                          e.target.checked,
                                          item.question,
                                          "Yes"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="p-0 d-flex">
                              <div className="col-md-9 ml-2 mt-2">
                                <span>No</span>
                              </div>
                              <div className="col-md-3 d-flex align-items-center m-1">
                                <div className="ml-auto mr-2">
                                  <div className="form-check form-switch">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      checked={selectedYesOrNoStatus.some(
                                        (obj) =>
                                          obj["answer"] === "No" &&
                                          obj["question"] === item.question
                                      )}
                                      onChange={(e) =>
                                        YesOrNoQuestionHandler(
                                          e.target.checked,
                                          item.question,
                                          "No"
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                        {miscellaneousEnabled[item.question] && (
                          <div className="d-flex justify-content-end mt-2 px-1">
                            <button
                              className="btn btn-text"
                              disabled={
                                selectedYesOrNoStatus.filter(
                                  (val) => val.question == item.question
                                ).length === 0
                              }
                              onClick={() => {
                                setSelectedYesOrNoStatus(
                                  selectedYesOrNoStatus.filter(
                                    (val) => val.question != item.question
                                  )
                                );
                              }}
                            >
                              <small>
                                Clear{" "}
                                {item.name != "Custom Question" && item.name}
                              </small>
                            </button>
                          </div>
                        )}
                        <p className="p-0" />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-users-slash"></i>
                  </div>
                  <div className="mt-2">No Records found</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default UserFilter;
