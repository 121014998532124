import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import ModalView from "../commoncomponents/modal/Modal";

const EmailTemplates = (props) => {
  const {
    setSubject,
    setContent,
    setFooter,
    setActiveTab,
    broadCastTemplates,
    setSelectedOption,
  } = props.data;
  const [templateModal, setTemplateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const templatesPerPage = 6;

  useEffect(() => {
    setEmailTemplates(broadCastTemplates);
  }, [broadCastTemplates]);

  const indexOfLastTemplate = currentPage * templatesPerPage;
  const indexOfFirstTemplate = indexOfLastTemplate - templatesPerPage;
  const currentTemplates = emailTemplates.slice(
    indexOfFirstTemplate,
    indexOfLastTemplate
  );

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderMailContent = (template) => {
    const maxContentLength = 150;
    if (template.mailContent.length <= maxContentLength) {
      return <div dangerouslySetInnerHTML={{ __html: template.mailContent }} />;
    } else {
      const truncatedContent = `${template.mailContent.substring(
        0,
        maxContentLength
      )}...`;
      return (
        <div>
          <div
            dangerouslySetInnerHTML={{ __html: truncatedContent }}
            style={{
              marginBottom: "10px",
              fontWeight: "lighter",
              fontSize: "14px",
            }}
          />
        </div>
      );
    }
  };
  const cardStyles = [
    // { width: "200px", height: "250px" },
    { width: "250px", height: "300px" },
    // { width: "300px", height: "350px" },
  ];

  return (
    <>
      <div className="ml-3 mr-3" style={{ fontSize: "14px" }}>
        <div className="email-templates">
          <div className="row d-flex justify-content-start mt-3">
            {currentTemplates.map((template, index) => (
              <div
                className="col-md-4"
                key={template.Id_BroadcastTemplates}
                style={cardStyles[index % cardStyles.length]}
              >
                <div className="card">
                  <div className="card-header">
                    <small
                      className="card-title"
                      style={{
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontWeight: "bold",
                      }}
                    >
                      {template.subject}
                    </small>
                  </div>
                  <div className="card-body">
                    <p className="card-text">
                      <div className="card-text">
                        {renderMailContent(template)}
                      </div>
                    </p>
                    <button
                      className="btn btn-primary btn-small p-2"
                      onClick={() => {
                        setSelectedTemplate(template);
                        setTemplateModal(true);
                      }}
                    >
                      View
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="pagination d-flex justify-content-end mb-5">
          <button
            className="btn btn-icon px-2"
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <i className="fal fa-chevron-circle-left"></i>
          </button>
          {Array.from({
            length: Math.ceil(emailTemplates.length / templatesPerPage),
          }).map((_, index) => (
            <button
              key={index}
              className={`btn btn-icon px-2 ${
                currentPage === index + 1 ? "active" : ""
              }`}
              style={{
                fontSize: "12px",
                color: currentPage === index + 1 ? "blue" : "grey",
              }}
              onClick={() => paginate(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="btn btn-icon px-2"
            onClick={() => paginate(currentPage + 1)}
            disabled={indexOfLastTemplate >= emailTemplates.length}
          >
            <i className="fal fa-chevron-circle-right"></i>
          </button>
        </div>
        {templateModal && (
          <ModalView
            modalSize={"lg"}
            modalTitle={"Select Template"}
            isModalOpen={templateModal}
            setModalClose={setTemplateModal}
            isFrom="EmailBroadcast"
            modalFor="selectTemplate"
            data={{
              setSubject: setSubject,
              setContent: setContent,
              setFooter: setFooter,
              setActiveTab: setActiveTab,
              setSelectedOption: setSelectedOption,
              template: selectedTemplate,
            }}
          />
        )}
      </div>
    </>
  );
};

export default EmailTemplates;
