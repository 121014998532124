import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const AutoMatchScoreCard = ({ jobData, candidateData, parent }) => {
  let column1 = "col-lg-3 text-left";
  let column1Skills = "col-lg-6 text-left";
  let column2Skills = "col-lg-6 text-left";
  let column2 = "col-lg-4 text-left";
  let column3 = "d-none col-lg-2 text-center";
  let column3General = "col-lg-4 text-left";
  const [automatch, setAutomatch] = useState(false);
  const [matched, setMatched] = useState({
    primary: 0,
    secondary: 0,
    industry: 0,
    location: 0,
  });
  const weightage = JSON.parse(jobData?.weightage);
  const [average, setAverage] = useState([
    { name: "Mandatory Skills", score: 0 },
    { name: "Location", score: 0 },
    { name: "Education", score: 0 },
    { name: "Experience", score: 0 },
    { name: "Job Title", score: 0 },
  ]);

  useEffect(() => {
    calculateScore(jobData, candidateData);
  }, [candidateData]);

  const calculateScore = (jobData, candidateData) => {
    let primary = 0;
    let location = 0;
    let education = 0;
    let jobTitle = 0;
    let experience = 0;
    let visaStatus = 0;
    let country = 0;
    let sum = 1;

    jobData?.primarySkills.forEach((skill) => {
      if (
        candidateData?.primarySkills?.some(
          (candidateSkill) =>
            candidateSkill.toLowerCase() === skill.toLowerCase()
        )
      ) {
        primary++;
      }
    });
    if (weightage?.education?.length > 0) {
      if (jobData?.education.includes(candidateData.highestEducation)) {
        education++;
      }
      if (
        candidateData.highestEducation === "Master's Degree" ||
        candidateData.highestEducation === "Doctorate"
      ) {
        education++;
      }
      sum++;
    }

    if (
      (jobData?.visaStatus ? JSON.parse(jobData.visaStatus) : [])
        .map((item) => item?.replace(/\s+/g, "").toLowerCase())
        ?.includes("notrequired")
    ) {
      visaStatus++;
    } else if (
      (jobData?.visaStatus ? JSON.parse(jobData?.visaStatus) : [])
        .map((item) => item?.replace(/\s+/g, "")?.toLowerCase())
        ?.includes(candidateData?.visaStatus?.replace(/\s+/g, "")?.toLowerCase())
    ) {
      visaStatus++;
    }

    const candidateCountry = [
      candidateData?.country,
      candidateData?.city,
      candidateData?.state,
    ];

    const jobCountry = [jobData?.city, jobData?.state, jobData?.country];

    if (weightage?.location) {
      if (jobData?.isGlobal) {
        country++;
        location++;
      } else if (
        jobData?.isRemote &&
        (jobData?.preferredLocation
          ? JSON.parse(jobData?.preferredLocation)
          : []
        )
          ?.map((item) => item?.replace(/\s+/g, "").toLowerCase())
          .some((r) =>
            candidateCountry
              ?.map((c) => c?.replace(/\s+/g, "").toLowerCase())
              ?.includes(r)
          )
      ) {
        country++;
        location++;
      } else if (
        jobData?.country.replace(/\s+/g, "").toLowerCase() ==
        candidateData?.country?.replace(/\s+/g, "").toLowerCase()
      ) {
        country++;
        location++;
      }
      // sum++;
    }
    if (weightage?.jobTitle) {
      if (
        candidateData?.designation?.toLowerCase().trim() ===
        jobData?.jobTitle.toLowerCase().trim()
      ) {
        jobTitle++;
      }
      sum++;
    }
    if (weightage?.experienceLevel) {
      if (candidateData?.experienceLevel === jobData?.experienceLevel) {
        experience++;
      } else if (
        candidateData?.experienceYear.length > 1 &&
        candidateData?.experienceYear.charAt(0) == 0
          ? candidateData?.experienceYear.replace("0", "") >=
            jobData?.experienceLevel?.charAt(0)
          : candidateData?.experienceYear >= jobData?.experienceLevel?.charAt(0)
      ) {
        experience++;
      }
      sum++;
    }
    let primaryAverage =
      jobData?.primarySkills.length > 0
        ? Math.round((primary * 100) / jobData?.primarySkills.length)
        : 0;
    let locationAverage = location ? 100 : 0;
    let jobTitleAverage = jobTitle ? 100 : 0;
    let experienceAvg = experience ? 100 : 0;
    let educationAvg = education ? 100 : 0;
    let visaStatusAvg = visaStatus ? 100 : 0;
    let countryStatusAvg = country ? 100 : 0;
    let total = Math.round(
      (primaryAverage + jobTitleAverage + experienceAvg + educationAvg) / sum
    );
    // } else {
    // total = Math.round((sum * 100) / count);
    // }

    setMatched({
      primary,
      location,
      education,
      experience,
      jobTitle,
      visaStatus,
    });
    setAverage([
      { name: "Mandatory Skills", score: primaryAverage },
      { name: "Location", score: locationAverage },
      { name: "Education", score: educationAvg },
      { name: "Experience", score: experienceAvg },
      { name: "Job Title", score: jobTitleAverage },
    ]);
  };

  const hr = () => {
    let line = (
      <div className="row">
        <div className="col-lg-10">
          <hr className="m-1" />
        </div>
      </div>
    );
    return line;
  };

  return (
    <div>
      <div className="mb-2">
        <button
          type="button"
          className="btn btn-collapsible"
          onClick={() => setAutomatch(!automatch)}
        >
          <i
            className={
              automatch ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"
            }
          ></i>
          <span>
            <small>Automatching Parameters</small>
          </span>
        </button>
      </div>

      <div className={automatch ? "ml-4 d-none mb-4" : "ml-4"}>
        {/* <div className="m-3">
          <table>
            <tr>
              {average.map((item) => (
                <td className="p-3a vertical-align: top">
                  <div className="d-flex flex-column align-items-center justify-content-end">
                    {item.name !== "Overall" ? (
                      <div style={{ width: "3rem", marginLeft: -20 }}>
                        <CircularProgressbar
                          strokeWidth={3}
                          value={item.score}
                          text={`${item.score}%`}
                          styles={buildStyles({
                            textColor: item.score >= 50 ? "green" : "black",
                            alignItems: "top",
                            pathColor: item.score >= 50 ? "green" : "orange",
                          })}
                        />
                      </div>
                    ) : (
                      <div style={{ width: "4rem", marginLeft: -20 }}>
                        <CircularProgressbar
                          value={item.score}
                          text={`${item.score}%`}
                          background
                          backgroundPadding={6}
                          styles={buildStyles({
                            backgroundColor:
                              item.score >= 50 ? "green" : "orange",
                            textColor: "#fff",
                            pathColor: "#fff",
                            trailColor: "transparent",
                          })}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        whiteSpace: "wrap",
                        marginLeft: -5,
                      }}
                    >
                      <small
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          whiteSpace: "nowrap",
                          marginLeft: -5,
                          fontSize: 9,
                        }}
                      >
                        {item.name}
                      </small>
                    </div>
                  </div>
                </td>
              ))}
            </tr>
          </table>
        </div> */}

        <div className="row mt-3">
          <div className="col-lg-9">
            <p className="mt-4 mb-3">
              <strong>Mandatory Skills: </strong>
              <span className="ml-2 tag tag-blue3">
                Matched {matched.primary} of {jobData?.primarySkills.length}
              </span>{" "}
            </p>
            <div className="row mb-1 mt-3">
              <div className={column1Skills}>Skills required</div>
              <div className={column2}>Candidate skills</div>
              <div className={column3}>Result</div>
            </div>
            {hr()}
            {jobData?.primarySkills.map((skill) => (
              <div className="row mb-1">
                <div className={column1Skills}>
                  <small>{skill}</small>
                </div>
                <div className={column2Skills}>
                  <small className="ml-1">
                    {candidateData?.primarySkills?.some(
                      (candidateSkill) =>
                        candidateSkill.toLowerCase().replace(/\s+/g, "") === skill.toLowerCase().replace(/\s+/g, "")
                    ) ? (
                      skill
                    ) : (
                      <small className="tag tag-red2">No</small>
                    )}
                  </small>
                </div>
                <div className={column3}>
                  <small>
                    {candidateData?.primarySkills?.some(
                      (candidateSkill) =>
                        candidateSkill.toLowerCase().replace(/\s+/g, "") === skill.toLowerCase().replace(/\s+/g, "")
                    )
                      ? "100%"
                      : "0%"}
                  </small>
                </div>
              </div>
            ))}
          </div>
        </div>

        {weightage?.location && (
          <div className="row mt-3 mb-4">
            <div className="col-lg-9">
              <p className="mt-4 mb-2">
                <strong>Location: </strong>
                <span className="ml-2 tag tag-blue3">
                  Matched {matched.location} of 1
                </span>
              </p>
              <div className="row mb-1 font-14">
                <div className={column1Skills}>Job</div>
                <div className={column2Skills}>Candidate</div>
                <div className={column3}>Result</div>
              </div>
              {hr()}

              {
                <div className="row mb-1">
                  <div className={column1Skills}>
                    <small>
                      {jobData?.isGlobal
                        ? "Remote(Global)"
                        : jobData?.isRemote
                        ? JSON.parse(jobData?.preferredLocation)?.join(",")
                        : jobData?.country}
                    </small>
                  </div>
                  <div className={column2Skills}>
                    <small>
                      {jobData?.isGlobal && "Remote(Global)"}
                      {!jobData?.isGlobal &&
                        jobData?.isRemote &&
                        ((jobData?.preferredLocation
                          ? JSON.parse(jobData?.preferredLocation)
                          : []
                        )
                          ?.filter((skill) => candidateData?.country == skill)
                          .map((i) => i).length > 0 ? (
                          candidateData?.country
                        ) : (
                          <small class="tag tag-red2">No</small>
                        ))}
                      {!jobData?.isRemote &&
                        (jobData?.country?.replace(/\s+/g, "").toLowerCase() ==
                          candidateData?.country
                            ?.replace(/\s+/g, "")
                            .toLowerCase() && candidateData?.country ? (
                          jobData?.country
                        ) : (
                          <small class="tag tag-red2">No</small>
                        ))}
                    </small>
                  </div>
                  <div className={column3}>
                    <small>{false ? "100%" : "0%"}</small>
                  </div>
                </div>
              }
            </div>
          </div>
        )}

        <p className="mt-4 mb-2 ">
          <strong>General: </strong>
        </p>
        <div className="row mt-3">
          <div className={column1}>Parameter</div>
          <div className={column2}>Job</div>
          <div className={column3General}>Candidate</div>
        </div>
        {hr()}
        {weightage?.jobTitle && (
        <div>
          <div className={column2}>
            <small>{jobData?.jobTitle}</small>
          </div>
          <div className={column3General}>
            <small>
              {candidateData?.designation?.toLowerCase()?.replace(/\s+/g, "") ===
              jobData?.jobTitle?.toLowerCase()?.replace(/\s+/g, "") ? (
                jobData?.jobTitle
              ) : (
                <small class="tag tag-red2">No</small>
              )}
            </small>
          </div>
        </div>)}

        {weightage?.education?.length > 0 && jobData?.education?.length > 0 && (
          <div className="row mb-1 font-14 ">
            <div className={column1}>
              <small>Education</small>
            </div>
            <div className={column2}>
              <small>{jobData?.education[0]}</small>
            </div>
            <div className={column3General}>
              <small>
                {matched.education ? (
                  jobData?.education[0]
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )}
        {/* {jobData.certifications.length > 0 && (
          <div className="row mb-1 font-14 ">
            <div className={column1}>
              <small>Certification</small>
            </div>
            <div className={column2}>
              <small>{jobData.certifications[0]}</small>
            </div>
            <div className={column3General}>
              <small>
                {candidateData.certificates.filter((i) => i).length > 0 ? (
                  candidateData.certificates
                    .map((i) => i.certificationName)
                    .includes(jobData.certifications[0]) ? (
                    jobData.certifications[0]
                  ) : (
                    <small class="tag tag-red2">No</small>
                  )
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )} */}

        {weightage?.experienceLevel && jobData?.experienceLevel && (
          <div className="row mb-1 font-14">
            <div className={column1}>
              <small>Experience Level</small>
            </div>
            <div className={column2}>
              <small>{jobData.experienceLevel}</small>
            </div>
            <div className={column3General}>
              <small>
                {candidateData?.experienceLevel?.includes(
                  jobData?.experienceLevel
                ) ||
                candidateData?.experienceYear >=
                  jobData?.experienceLevel?.charAt(0) ? (
                  jobData?.experienceLevel
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )}
        {parent !== "TalentPool" && (
          <div className="row mb-1 font-14">
            <div className={column1}>
              <small>Visa Status</small>
            </div>
            <div className={column2}>
              <small>
                {jobData?.visaStatus.length > 0
                  ? JSON.parse(jobData?.visaStatus).join(",")
                  : ""}
              </small>
            </div>
            <div className={column3General}>
              <small>
                {(jobData?.visaStatus ? JSON.parse(jobData?.visaStatus) : [])
                  .map((item) => item?.replace(/\s+/g, "").toLowerCase())
                  ?.includes("notrequired") ? (
                  candidateData?.visaStatus
                ) : (jobData?.visaStatus ? JSON.parse(jobData?.visaStatus) : [])
                    .map((item) => item?.replace(/\s+/g, "").toLowerCase())
                    ?.includes(
                      candidateData?.visaStatus
                        ?.replace(/\s+/g, "")
                        .toLowerCase()
                    ) ? (
                  candidateData?.visaStatus
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )}

        <div className="mb-8"></div>
        <div className="m-3">
          <h6 className="p-2">{null}</h6>
        </div>
      </div>
    </div>
  );
};

export default AutoMatchScoreCard;
