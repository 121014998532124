import React from "react";
import { getCandidateName } from "../../../../../service/AuthService";
import { ProgressBar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

const NameAndOwner = ({ setAddJobData, addJobData, addJobVal }) => {
  const name = getCandidateName();
  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-floating">
                <input
                  type="text"
                  className={
                    addJobVal.poolName && addJobData.poolName.trim() === "" 
                      ? "form-control border border-danger w-100"
                      : "form-control"
                  }
                  name="poolName"
                  placeholder="Enter a owner name"
                  onChange={(e) =>
                    setAddJobData({
                      ...addJobData,
                      poolName: e.target.value,
                    })
                  }
                  value={addJobData.poolName}
                />
                <label>Community Name*</label>
              </div>
              {addJobVal.poolName && addJobData.poolName.trim() === "" && (
                <small className="validation ml-2">*Required field</small>
              )}
            </div>
            {/* <div className="col-lg-6">
              <div className="form-floating">
                <input
                  type="number"
                  className="form-control"
                  name="targetSize"
                  onChange={(e) => {
                    setAddJobData({
                      ...addJobData,
                      targetSize: e.target.value,
                    });
                  }}
                  min={"0"}
                  value={addJobData.targetSize}
                />
                <label>Target Size*</label>
              </div>
              {addJobVal.targetSize && addJobData.targetSize > 0 && (
                <small className="validation ml-2">
                  {addJobVal.targetSize}
                </small>
              )}
            </div> */}
            {/* <div className="col-lg-5">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Progress</span>
                <span>0/{addJobData.targetSize}</span>
              </div>

              <div
                style={{
                  border: "solid #ced4da",
                  borderRadius: "4px",
                  height: "38px",
                }}
              >
                <ProgressBar
                  style={{
                    marginTop: "10px",
                    width: "260px",
                    marginLeft: "14px",
                  }}
                  now={0}
                />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NameAndOwner;
