import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import buttonLoader from "../../../images/button-loader.gif";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
const Resume = ({
  resumeFile,
  setResumeFile,
  setH5Parsed,
  setAffparsed,
  setGptParsed,
  selectedJobId,
}) => {
  const [valid, setValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const onDragDropChangeHandler = (file) => {
    var re = /(?:\.([^.]+))?$/;

    var ext = re.exec(file[0].name)[1]; // "
    console.log(file[0].name, ext);

    if (ext === "pdf" || ext === "docx" || ext === "doc") {
      setValid(true);
      const { name } = file[0];
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (event) => {
        setResumeFile({ fileName: name, base64: event.target.result });
      };
    } else {
      setValid(false);
    }
  };
  useEffect(() => {}, [resumeFile]);

  const onRemove = (e) => {
    setResumeFile({ fileName: "", base64: "" });
    setAffparsed(false);
    setH5Parsed(false);
    setGptParsed(false);
    setValid(false);
    setShowConfirmation(false);
    toast.success("Resume deleted successfully");
  };

  return (
    <div className=" my-1 mb-3  ">
      <div className="d-flex  align-items-center  mt-2  ">
        <div className="d-flex justify-content-center align-items-center ">
          <Dropzone
            onDrop={(file) => onDragDropChangeHandler(file)}
            multiple={false}
            disabled={!selectedJobId || resumeFile?.fileName}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="">
                <button
                  style={{ width: "150px" }}
                  type="button"
                  disabled={!selectedJobId || resumeFile?.fileName}
                  className="btn btn-secondary"
                >
                  <i className="far fa-cloud-upload mr-1 fa-fw"></i>
                  Select Resume
                </button>
                <input type="file" {...getInputProps()} accept=".docx, .pdf" />
              </div>
            )}
          </Dropzone>{" "}
          <div>
            {resumeFile === "" ? (
              <p className="m-0 ml-3  ">Upload your resume*</p>
            ) : (
              <p className="m-0 ml-3 ">{resumeFile?.fileName}</p>
            )}
          </div>
          {resumeFile && resumeFile.fileName && submitClicked !== false && (
            <button type="button" class="btn btn-sm btn-primary ml-2">
              <span v-if="submittingForm">
                Uploading
                <img width="20px" alt="" src={buttonLoader} />
              </span>
            </button>
          )}
          {resumeFile && resumeFile.fileName && (
            <div className="mb-2 ">
              <button
                type="button"
                className="btn btn-icon ml-4 mt-1"
                aria-hidden="true"
                style={{ cursor: "default" }}
                // onClick={() => setShowConfirmation(true)}
              >
                <i
                  class="fal fa-fw fa-trash-alt"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowConfirmation(true);
                  }}
                ></i>
                <i
                  class="fa fa-check-circle"
                  style={{ color: "green", marginLeft: 30 }}
                ></i>
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal show={showConfirmation} size="md" animation={true}>
        <Modal.Header>
          <button
            type="button"
            onClick={() => setShowConfirmation(false)}
            className="close p-0 bl-modal-close-btn closable"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fal fa-times closable"></i>
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="m-5">
            <div class="text-center">
              <div class="avatar avatar-lg avatar-red2">
                <i
                  class="fas fa-exclamation-triangle fa-fw"
                  aria-hidden="true"
                ></i>
              </div>
              <p class="text-center lead mt-3">
                Are you sure you want to delete resume?
                <br />
                {resumeFile.fileName}
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div class="d-flex align-items-center justify-content-center">
            <Button
              variant="secondary"
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={onRemove} className="ml-2">
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Resume;
