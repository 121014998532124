import React, { useState } from "react";
import LearningFullView from "./LearningFullView";
import Modal from "react-bootstrap/Modal";
import moment from "moment";

const LearningListView = (props) => {
  const [learningDetails, setLearningDetails] = useState([]);
  const [openLearningDetailsModal, setOpenLearningDetailsModal] = useState(
    false
  );

  const openLearningModal = (module) => {
    setLearningDetails(module);
    setOpenLearningDetailsModal(true);
  };

  const closeLearningModal = () => {
    setOpenLearningDetailsModal(false);
  };

  return (
    <>
      <div className="row d-none d-flex-1200 d-block-force">
        {props.learningList.length === 0 ? (
          <p>No Learning Modules</p>
        ) : (
          props.learningList.map((module) => (
            <div className="col-lg-12 col-sm-12">
              <a
                href=""
                className="card card-flat d-flex flex-row align-items-stretch justify-content-between mb-1 p-relative"
                onClick={(e) => {
                  e.preventDefault();
                  openLearningModal(module);
                }}
              >
                <div className="card-body ">
                  <div className="w-100">
                    <div className="d-flex align-items-center mb-1 mt-2">
                      <h6 className="mb-0 me-2">{module.CourseName}</h6>
                    </div>
                  </div>
                  <div className="bg-blue4 p-2 mt-2">
                    <span className="mr-2 font-regular">
                      <i className="fal fa-fw fa-clock"></i> Last updated:{" "}
                      {module.LastActivityDate
                        ? moment(module.LastActivityDate, "YYYYMMDD").fromNow()
                        : "Not Yet Started"}
                    </span>
                  </div>
                  <div className="mt-2">
                    <p className="mb-1">
                      <small>Progress</small>
                    </p>
                    {module.ProgressStatus === "In Progress" ? (
                      module.PercentComplete === 0 ? (
                        <div>
                          <small>Not Started</small>
                          <div className="alert-progress alert-progress-static bg-gray3">
                            <div className="alert-progress-bar bg-green1"></div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          <small>
                            {module.PercentComplete + "%"} Completed
                          </small>
                          <div className="alert-progress alert-progress-static bg-gray3">
                            <div
                              style={{ transform: "translateX(-80%)" }}
                              className="alert-progress-bar"
                            ></div>
                          </div>
                        </div>
                      )
                    ) : (
                      <div>
                        <small>100% Completed</small>
                        <div className="alert-progress alert-progress-static bg-green1"></div>
                      </div>
                    )}
                  </div>
                </div>
              </a>
            </div>
          ))
        )}
      </div>
      <Modal show={openLearningDetailsModal}>
        <LearningFullView
          learningDetails={learningDetails}
          closeModal={closeLearningModal}
        />
      </Modal>
    </>
  );
};

export default LearningListView;
