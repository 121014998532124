import React, { useState } from "react";
import ShareJob from "./ShareJob";

const Index = (props) => {
  const [share, setShare] = useState(false);
  return (
    <>
      <div
        className="dropdown px-1"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Share"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => setShare(true)}
        style={{ cursor: "pointer", fontSize: "large" }}
      >
        <i className="fal fa-share-alt fa-fw-dropdown" />
      </div>
      {share && (
        <ShareJob jobData={props.jobData} share={share} setShare={setShare} />
      )}
    </>
  );
};

export default Index;
