import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { getTenantType, isCurator } from "../../../../service/AuthService";

const ClientData = ({
  clientName,
  nameLoading,
  fetchClientDetails,
  validation,
  setTenant,
  tenant,
  setClientData,
}) => {
  const isFirstRender = useRef(true);
  const [options, setOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (clientName && clientName.length > 0) {
      let op = clientName?.map((item, index) => ({
        value: item.id,
        label: item?.tenantname.replace("GIG - ", ""),
      }));
      setOptions([...op]);
    }
  }, [clientName]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchValue.length === 0 || searchValue.length >= 2) {
        fetchClientDetails(searchValue);
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchValue]);

  const styleSheet = {
    input: (base) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
    control: (base) => ({
      ...base,
      borderColor: validation.client ? "red" : base.borderColor,
      "&:hover": {
        borderColor: validation.client ? "red" : base["&:hover"].borderColor,
      },
    }),
    dropdownIndicator: (provided) => ({ ...provided, display: "none" }),
    indicatorSeparator: () => ({
      display: "none", // Hide the indicator separator
    }),
  };

  const handleChange = (val) => {
    setTenant(val);
    setClientData(null);
  };

  const handleInputChange = (val) => {
    setSearchValue(val);
  };

  return (
    <Select
      options={nameLoading ? [] : options}
      isSearchable={true}
      onChange={(val) => {
        handleChange(val);
      }}
      styles={styleSheet}
      value={tenant}
      isMulti={false}
      onInputChange={(val) => {
        handleInputChange(val);
      }}
      isLoading={nameLoading}
      placeholder={"Select Client*"}
      isClearable
      onMenuOpen={() => {
        fetchClientDetails(searchValue);
      }}
      isDisabled={isCurator() && getTenantType() == 1}
    />
  );
};

export default ClientData;
