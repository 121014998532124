import React, { useEffect, useState } from "react";
import { post } from "../../service/ApiService";
import { useHistory, Redirect } from "react-router-dom";
import { toast } from "react-toastify";
import Avatar from "react-avatar";
import moment from "moment";
import { getTenantType, getUserRole } from "../../service/AuthService";
import { countryObj } from "../../constants/Constants";

function SearchHistory(props) {
  const [searchInput, setSearchInput] = useState(false);
  const [getSearchData, setSerchData] = useState([]);
  const [jobData, setJobData] = useState([]);
  const [talentData, settalentData] = useState([]);
  const [count, setCount] = useState({
    Job: 0,
    Candidate: 0,
  });
  let history = useHistory();
  // function to call
  const viewDetails = async (data, type) => {
    if (type === "Job") {
      // store data in localstorage
      // redirect to onetr page
      window.open(`/high5hire/requests/${data.jobId}`, "_self");
      // return   <Redirect to={`/high5hire/requests/${data.jobId}`} />
    } else if (type === "Talent") {
      window.open(`/high5hire/talent/${data.candidateID}`, "_self");
    }
  };

  const SearchglobalAPI = async (searchtext) => {
    try {
      const params = {
        domain: "https://uat.high5hire.com",
        searchText: searchtext,
      };
      const result = await post(
        "/globalsearch/searchtalentbyusertenant",
        params
      );
      console.log("result", result);
      setJobData(result.data.JobData);
      settalentData(result.data.candidateData);
      // setCount({ ...count, Job: result.data.totalJobs, Candidate: result.data.totalCandidates })
    } catch (error) {
      toast.error("Error Occured ");
    }
  };

  useEffect(() => {
    if (props.searchInput !== "") {
      setSearchInput(true);
    } else {
      setSearchInput(false);
    }
  }, [props]);

  useEffect(() => {
    SearchglobalAPI(props.searchInput);
  }, []);

  return (
    <>
      <div id="SearchResults">
        <div className="results">
          {searchInput !== true ? (
            <>
              {/* <div>
                                <div className="px-3 pt-3">
                                    <p className="mb-3 text-uppercase font-muted">Recently Viewed</p>
                                </div>
                            </div> */}

              {/* <div v-if="!showSearchResults">
                                <div className="px-3 pt-3">
                                    <p className="mb-3 text-uppercase font-muted">Recently Viewed</p>
                                </div>
                                <div className="pb-1" style={{ textAlign: 'left' }}>
                                    <ul className="list-unstyled">
                                        <li className="m-0">
                                            <a href="/hiringmanager/request/12321" className="d-flex justify-content-between px-3">
                                                <div className="d-flex">
                                                    <span className="avatar avatar-sm mr-2"><i className="fad fa-briefcase"></i></span>
                                                    <div>
                                                        <h6 className="mb-1 font-14">Development Manager</h6>
                                                        <span className="tag tag-green1 mr-2">open</span><small className="font-primary font-regular">#123432 / Contract / Jacksonville FL.</small>
                                                    </div>
                                                </div>
                                                <div className="d-none-1024">
                                                    <small className="font-primary font-regular">Today at 3:11</small>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="px-2"><hr className="m-0" /></li>
                                        <li className="m-0">
                                            <a href="/hiringmanager/candidate/12321" className="d-flex justify-content-between px-3">
                                                <div className="d-flex">
                                                    <span className="avatar avatar-sm mr-2 avatar-green1">SF</span>
                                                    <div>
                                                        <h6 className="mb-0 font-14">Sarah Farlow </h6>
                                                        <small className="font-primary font-regular">Director of User Experience / Jacksonville FL.</small>
                                                    </div>
                                                </div>
                                                <div className="d-none-1024">
                                                    <small className="font-primary font-regular">Today at 3:08</small>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="px-2"><hr className="m-0" /></li>
                                        <li className="m-0">
                                            <a href="/hiringmanager/request/12321" className="d-flex justify-content-between px-3">
                                                <div className="d-flex">
                                                    <span className="avatar avatar-sm mr-2"><i className="fad fa-briefcase"></i></span>
                                                    <div>
                                                        <h6 className="mb-1 font-14">Sr. Hadoop Architect </h6>
                                                        <span className="tag tag-orange1 mr-2 ">active</span><small className="font-primary font-regular">#452345 / SOW / Jacksonville FL.</small>
                                                    </div>
                                                </div>
                                                <div className="d-none-1024">
                                                    <small className="font-primary font-regular">Today at 12:33</small>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="px-2"><hr className="m-0" /></li>
                                        <li className="m-0">
                                            <a href="/hiringmanager/request/12321" className="d-flex justify-content-between px-3">
                                                <div className="d-flex">
                                                    <span className="avatar avatar-sm mr-2"><i className="fad fa-briefcase"></i></span>
                                                    <div>
                                                        <h6 className="mb-1 font-14">Sr. Java Developer </h6>
                                                        <span className="tag tag-orange1 mr-2">active</span><small className="font-primary font-regular">#393938 / SOW / Jacksonville FL.</small>
                                                    </div>
                                                </div>
                                                <div className="d-none-1024">
                                                    <small className="font-primary font-regular">Today at 12:30</small>
                                                </div>
                                            </a>
                                        </li>
                                        <li className="px-2"><hr className="m-0" /></li>
                                        <li className="m-0">
                                            <a href="/hiringmanager/candidate/12321" className="d-flex justify-content-between px-3">
                                                <div className="d-flex">
                                                    <span className="avatar avatar-sm avatar-bordered mr-2"><img src="https://randomuser.me/api/portraits/men/49.jpg" alt='' /> </span>
                                                    <div>
                                                        <h6 className="mb-0 font-14">Jason Jamison </h6>
                                                        <small className="font-primary font-regular">Interaction Designer / Jacksonville FL.</small>
                                                    </div>
                                                </div>
                                                <div className="d-none-1024">
                                                    <small className="font-primary font-regular">Today at 3:08</small>
                                                </div>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div> */}
            </>
          ) : (
            <>
              <div>
                <div>
                  {searchInput &&
                    jobData.filter(
                      (item) =>
                        item.jobTitle
                          ?.toLowerCase()
                          ?.includes(props.searchInput) ||
                        item.jobId?.includes(props.searchInput)
                    ).length === 0 &&
                    talentData.filter(
                      (item) =>
                        item.firstName
                          ?.toLowerCase()
                          ?.includes(props.searchInput) ||
                        item.lastName
                          ?.toLowerCase()
                          ?.includes(props.searchInput) ||
                        (
                          item.firstName?.toLowerCase() +
                          " " +
                          item.lastName?.toLowerCase()
                        )?.includes(props.searchInput)
                    )?.length === 0 && (
                      <div>
                        <div className="px-3 pt-3">
                          <p className="mb-3 text-uppercase font-muted">
                            No Record Present
                          </p>
                        </div>
                      </div>
                    )}
                </div>
                <div>
                  {jobData.filter(
                    (item) =>
                      item.jobTitle
                        ?.toLowerCase()
                        ?.includes(props.searchInput) ||
                      item.jobId?.includes(props.searchInput)
                  )?.length > 0 ? (
                    <div className="mb-1">
                      <div className="px-3 pt-3 d-flex ">
                        <p className="mb-3 text-uppercase font-muted">
                          Requests
                        </p>
                        <p className=" text-uppercase font-muted ml-auto my-0">
                          {
                            jobData
                              .filter(
                                (item) =>
                                  item.jobTitle
                                    ?.toLowerCase()
                                    ?.includes(props.searchInput) ||
                                  item.jobId?.includes(props.searchInput)
                              )
                              .filter((item) => {
                                if (
                                  item.jobStatus?.toLowerCase() == "pending" ||
                                  item.jobStatus?.toLowerCase() == "expired"
                                ) {
                                  return (
                                    getUserRole() == "HiringManager" ||
                                    getTenantType() == 1 ||
                                    getUserRole() == "High5Coordinator" ||
                                    (item.jobStatus?.toLowerCase() ==
                                      "expired" &&
                                      getUserRole() == "SuperAdmin")
                                  );
                                } else return true;
                              })?.length
                          }
                        </p>
                      </div>
                      <div className="pb-1">
                        <ul className="list-unstyled">
                          {jobData
                            .filter(
                              (item) =>
                                item.jobTitle
                                  ?.toLowerCase()
                                  .includes(props.searchInput) ||
                                item.jobId?.includes(props.searchInput)
                            )
                            ?.filter((item) => {
                              if (
                                item.jobStatus?.toLowerCase() == "pending" ||
                                item.jobStatus?.toLowerCase() == "expired"
                              ) {
                                return (
                                  getUserRole() == "HiringManager" ||
                                  getTenantType() == 1 ||
                                  // getUserRole() == "SuperAdmin" ||
                                  getUserRole() == "High5Coordinator" ||
                                  (item.jobStatus?.toLowerCase() == "expired" &&
                                    getUserRole() == "SuperAdmin")
                                );
                              } else return true;
                            })
                            ?.map((eachJob) => (
                              <li className="m-0">
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    viewDetails(eachJob, "Job");
                                  }}
                                  className="d-flex justify-content-between px-3"
                                >
                                  <div className="d-flex">
                                    <span className="avatar avatar-sm mr-2">
                                      <i className="fad fa-briefcase"></i>
                                    </span>
                                    <div>
                                      <h6 className="mb-1 font-14">
                                        {eachJob.jobTitle}
                                      </h6>
                                      {/* <span className="tag tag-orange1 mr-2">active</span> */}
                                      {eachJob.jobStatus === "open" ? (
                                        <span className="tag tag-green1 mr-3">
                                          {eachJob.jobStatus}{" "}
                                        </span>
                                      ) : eachJob.jobStatus === "active" ? (
                                        <span className="tag tag-orange1 mr-3">
                                          {" "}
                                          {eachJob.jobStatus}{" "}
                                        </span>
                                      ) : eachJob.jobStatus === "closed" ? (
                                        <span className="tag tag-font_accent mr-3">
                                          {eachJob.jobStatus}{" "}
                                        </span>
                                      ) : (
                                        <span className="tag tag-font_accent mr-3">
                                          {eachJob.jobStatus}
                                        </span>
                                      )}
                                      <small className="font-primary font-regular">
                                        #{eachJob.jobId} / {eachJob.jobType} /{" "}
                                        {eachJob.isRemote !== true
                                          ? eachJob.location.city +
                                            " - " +
                                            eachJob.location.country
                                          : "Remote"}
                                      </small>
                                    </div>
                                  </div>
                                  {eachJob.jobPostDate && (
                                    <div className="d-none-1024">
                                      <small className="font-primary font-regular">
                                        {" "}
                                        Posted :&nbsp;
                                        {moment(
                                          new Date(eachJob.jobPostDate),
                                          "YYYYMMDD"
                                        ).fromNow()}
                                      </small>
                                    </div>
                                  )}
                                </a>
                              </li>
                            ))}
                          <li className="px-2">
                            <hr className="m-0" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {searchInput &&
                  jobData.filter((item) =>
                    item.jobId?.includes(Number(props.searchInput))
                  ).length > 0 ? (
                    <div className="mb-1">
                      <div className="px-3 pt-3 d-flex ">
                        <p className="mb-3 text-uppercase font-muted">
                          Requests
                        </p>
                        <p className=" text-uppercase font-muted ml-auto my-0">
                          {
                            jobData.filter((item) =>
                              item.jobId.includes(Number(props.searchInput))
                            ).length
                          }
                        </p>
                      </div>
                      <div className="pb-1">
                        <ul className="list-unstyled">
                          {jobData
                            .filter((item) =>
                              item.jobId?.includes(Number(props.searchInput))
                            )
                            .map((eachJob) => (
                              <li className="m-0">
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    viewDetails(eachJob, "Job");
                                  }}
                                  className="d-flex justify-content-between px-3"
                                >
                                  <div className="d-flex">
                                    <span className="avatar avatar-sm mr-2">
                                      <i className="fad fa-briefcase"></i>
                                    </span>
                                    <div>
                                      <h6 className="mb-1 font-14">
                                        {eachJob.jobTitle}
                                      </h6>
                                      {/* <span className="tag tag-orange1 mr-2">active</span> */}
                                      {eachJob.jobStatus === "open" ? (
                                        <span className="tag tag-green1 mr-3">
                                          {eachJob.jobStatus}{" "}
                                        </span>
                                      ) : eachJob.jobStatus === "active" ? (
                                        <span className="tag tag-orange1 mr-3">
                                          {" "}
                                          {eachJob.jobStatus}{" "}
                                        </span>
                                      ) : eachJob.jobStatus === "closed" ? (
                                        <span className="tag tag-font_accent mr-3">
                                          {eachJob.jobStatus}{" "}
                                        </span>
                                      ) : (
                                        <span className="tag tag-font_accent mr-3">
                                          {eachJob.jobStatus}
                                        </span>
                                      )}
                                      <small className="font-primary font-regular">
                                        #{eachJob.jobId} / {eachJob.jobType} /{" "}
                                        {eachJob.isRemote !== true
                                          ? eachJob.location.city +
                                            " - " +
                                            eachJob.location.country
                                          : "Remote"}
                                      </small>
                                    </div>
                                  </div>
                                  {eachJob.jobPostDate && (
                                    <div className="d-none-1024">
                                      <small className="font-primary font-regular">
                                        {" "}
                                        Posted :&nbsp;
                                        {moment(
                                          new Date(eachJob.jobPostDate),
                                          "YYYYMMDD"
                                        ).fromNow()}
                                      </small>
                                    </div>
                                  )}
                                </a>
                              </li>
                            ))}
                          <li className="px-2">
                            <hr className="m-0" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  {talentData.filter(
                    (item) =>
                      item.firstName
                        ?.toLowerCase()
                        .includes(props.searchInput) ||
                      item.lastName
                        ?.toLowerCase()
                        ?.includes(props.searchInput) ||
                      (
                        item.firstName?.toLowerCase() +
                        " " +
                        item.lastName?.toLowerCase()
                      )?.includes(props.searchInput)
                  )?.length > 0 ? (
                    <div className="mb-1">
                      <div className="px-3 pt-3 d-flex">
                        <p className="mb-3 text-uppercase font-muted">
                          Candidates
                        </p>
                        <p className=" text-uppercase font-muted ml-auto my-0">
                          {
                            talentData.filter(
                              (item) =>
                                item.firstName
                                  ?.toLowerCase()
                                  ?.includes(props.searchInput) ||
                                item.lastName
                                  ?.toLowerCase()
                                  ?.includes(props.searchInput) ||
                                (
                                  item.firstName?.toLowerCase() +
                                  " " +
                                  item.lastName?.toLowerCase()
                                )?.includes(props.searchInput)
                            )?.length
                          }
                        </p>
                      </div>
                      {talentData
                        ?.filter(
                          (item) =>
                            item.firstName
                              ?.toLowerCase()
                              ?.includes(props.searchInput) ||
                            item.lastName
                              ?.toLowerCase()
                              ?.includes(props.searchInput) ||
                            (
                              item.firstName?.toLowerCase() +
                              " " +
                              item.lastName?.toLowerCase()
                            )?.includes(props.searchInput)
                        )
                        ?.map((eachTalent) => (
                          <div className="pb-1">
                            <ul className="list-unstyled">
                              <li className="m-0">
                                <a
                                  href=""
                                  onClick={(e) => {
                                    e.preventDefault();
                                    viewDetails(eachTalent, "Talent");
                                  }}
                                  className="d-flex justify-content-between px-3"
                                >
                                  <div className="d-flex">
                                    {/* <span className="avatar avatar-sm avatar-bordered mr-2"><img src="https://randomuser.me/api/portraits/men/49.jpg" alt='' />

                                                                        </span> */}

                                    <Avatar
                                      // size="60"
                                      className="avatar avatar-sm avatar-bordered mr-2"
                                      // src={profileImg}
                                      name={
                                        eachTalent.firstName +
                                        " " +
                                        eachTalent.lastName
                                      }
                                      //  round="90px"
                                    />
                                    <div>
                                      <h6 className="mb-0 font-14">
                                        {eachTalent.firstName}{" "}
                                        {eachTalent.lastName}
                                      </h6>
                                      <small className="font-primary font-regular">
                                        {eachTalent.currentJobTitle === ""
                                          ? "Not Updated"
                                          : eachTalent.currentJobTitle}{" "}
                                        {eachTalent.address
                                          ? "/  Location : " +
                                            eachTalent.addressCity +
                                            ", " +
                                            eachTalent.country
                                          : "/ Location : Not Avialable"}
                                      </small>
                                    </div>
                                  </div>
                                  {eachTalent.updatedDate !== "" && (
                                    <div className="d-none-1024">
                                      <small className="font-primary font-regular">
                                        Updated :{" "}
                                        {moment(
                                          new Date(eachTalent.updatedDate),
                                          "YYYYMMDD"
                                        ).fromNow()}
                                      </small>
                                    </div>
                                  )}
                                </a>
                              </li>
                            </ul>
                          </div>
                        ))}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default SearchHistory;
