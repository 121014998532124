import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { MdEmail } from "react-icons/md";
import five from "../../../../../src/assets/images/high5-std.png";
// import "./FooterStyle.css";
import Loader from "../../../Loader/Loader";
import { post } from "../../../../service/ApiService";

const Footer = (props) => {
  const { tenant } = useParams();
  const [checkEmail, setCheckEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [applyWithEmail, setApplyWithEmail] = useState(false);

  const validate = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(mailformat) ? true : false;
  };

  const emailExists = async () => {
    if (email == "") {
      toast.error("Please enter your email");
    } else if (!validate(email)) {
      toast.error("Enter a valid email address");
    } else {
      const params = { tenantId: tenant, email: email };

      setLoading(true);
      const res = await post("/applyjob/isemailexist", params).then((res) => {
        if (res.data.candidateStatus == "Login Talent") {
          toast.success("You already have joined our talent community");
          setLoading(false);
          setCheckEmail(false);
          setApplyWithEmail(false);
          setEmail("");
        } else if (
          res.data.candidateStatus === "Existing Talent" &&
          res.data.source !== "Join Community"
        ) {
          setCheckEmail(false);
          sessionStorage.setItem("email", email);
          sessionStorage.setItem("id_user", res.data.id_user);
          sessionStorage.setItem("candidateStatus", "Existing Talent");
          window.open(
            `/careers/${tenant}/recruiter/${props.recruiterId}/joincommunity`,
            "_self"
          );
          setLoading(false);
          setApplyWithEmail(false);
        } else if (res.data.candidateStatus === "New Talent") {
          setCheckEmail(false);
          sessionStorage.setItem("email", email);
          props.isFrom == "newCareerPage"
            ? window.open(
                `/careers/8/recruiter/698131846/joincommunity`,
                "_self"
              )
            : window.open(
                `/careers/${tenant}/recruiter/${props.recruiterId}/joincommunity`,
                "_self"
              );
          setLoading(false);
          setApplyWithEmail(false);
        } else {
          toast.success("You already have joined our talent community");
          setLoading(false);
          setCheckEmail(false);
          setApplyWithEmail(false);
          setEmail("");
        }
      });
    }
  };
  return (
    <>
      {loading && <Loader />}
      {sessionStorage.getItem("token") == null && (
        <div className="">
          <div
            className={
              props.status === "career" ? "main_footer" : "main_footer2"
            }
            style={{
              marginLeft: props.isFrom == "newCareerPage" ? "18%" : "7%",
              marginRight: props.isFrom == "newCareerPage" ? "19%" : "0%",
              width: "93%",
            }}
          >
            <div className="main_footer-heading">
              <span>We’re always on the lookout for Talent</span>
              <div className="main_frame">
                <div className="main_footer-sub_heading">
                  <span>
                    If you are thinking about a future with us, sign up to stay
                    connected and be informed of any new opportunities
                  </span>
                </div>
                <div className="">
                  {!sessionStorage.getItem("token") && (
                    <button
                      className="main_footer-button_style"
                      onClick={() => {
                        setCheckEmail(true);
                      }}
                    >
                      <span>Talent Community</span>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div>
        <div
          className={props.status === "career" ? "main_footer" : "main_footer2"}
          style={{
            marginLeft: props.isFrom == "newCareerPage" ? "18%" : "7%",
            marginRight: props.isFrom == "newCareerPage" ? "19%" : "0%",
            width: "93%",
          }}
        >
          <div className="main_footer-powered">
            <snap className="main_footer-powered-poweredBy">Powered by </snap>
            <br />

            <div className="main_footer-powered-logo">
              <img className="main_footer-powered-logo-2" src={five}></img>
            </div>
          </div>

          <span className="main_footer-privacy">
            Privacy Policy and Terms of Service
          </span>
        </div>
        <Modal show={checkEmail}>
          <Modal.Header>
            <div>
              <h6>Join Talent Community</h6>
            </div>
            <div>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setCheckEmail(false);
                  setApplyWithEmail(false);
                }}
                class="close p-0 bl-modal-close-btn mx-1"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex flex-column mx-5 mb-3 ">
              {/* <button
                className="btn btn-secondary m-2 w-100"
                onClick={() => {
                  setApplyWithEmail(true);
                }}
              >
                <MdEmail className="" /> Join with Email
              </button> */}
            </div>
            {/* {applyWithEmail && ( */}
            <div>
              <div className="d-flex flex-column mx-5 my-3">
                <div className="form-floating ml-1">
                  <input
                    type="text"
                    className={"form-control"}
                    name="groupName"
                    placeholder="Enter a group name"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <label>Email*</label>
                </div>
              </div>
              <div className="row mx-5 mb-2">
                <button
                  className="btn btn-secondary m-2 w-100"
                  onClick={() => emailExists()}
                  disabled={email == "" && true}
                >
                  Submit
                </button>
              </div>
            </div>
            {/* )} */}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default Footer;
