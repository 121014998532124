import React, { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Avatar from "react-avatar";
import { countryObj } from "../../../constants/Constants";
import { CurrencySymbols } from "../../config/CurrencySymbols";

function RequestTableView(props) {
  let temporaryRequestList = useState([]);
  const history = useHistory();
  const [newRequestList, setnewRequestList] = useState([]);
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [requestTitleArray, setRequestTitleArray] = useState([]);
  const [pageType, setPageType] = useState("");
  let pageSize = 10;
  let dataSize = 10;

  useEffect(() => {
    if (pageType === "up") {
      if ((props.currentPage - 1) % 5 === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(
            props.currentPage - 1,
            props.currentPage + (pageSize - 1)
          )
        );
      }
    } else {
      if (props.currentPage % 5 === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(props.currentPage - 5, props.currentPage)
        );
      }
    }
  }, [props.currentPage]);

  useEffect(() => {
    if (props.originalStatus) {
      temporaryRequestList = props.requestListData.filter(function(e) {
        return e.jobStatus === props.originalStatus;
      });
      setnewRequestList(temporaryRequestList);
      setRequestTitleArray(
        new Array(Math.ceil(props.requestListData.length / dataSize))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(props.requestListData.length / dataSize))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageSize)
      );
    } else {
      setnewRequestList(props.requestListData);
      setRequestTitleArray(
        new Array(Math.ceil(props.requestListData.length / dataSize))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(props.requestListData.length / dataSize))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageSize)
      );
    }
  }, [props]);

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    props.setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = props.currentPage * dataSize - dataSize;
    const endIndex = startIndex + dataSize;
    return newRequestList.slice(startIndex, endIndex);
  };

  const requestSidePanel = () => {
    history.push(`/high5Hire/requests/${props.jobId}`);
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <table className="table table-sortable  table-hoverable">
            <thead>
              <tr>
                <th className="d-flex align-items-center">Job Title</th>
                <th>Job type </th>
                <th>Location </th>
                <th>End Date </th>
                <th className="no-hover">Shortlisted</th>
                <th>New Candidates </th>
              </tr>
            </thead>
            <tbody>
              {getPaginatedData().map((request) => (
                <tr
                  onClick={() =>
                    history.push(`/high5Hire/requests/${request.jobId}`)
                  }
                >
                  <td>
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0 mr-3">{request.fullText.jobTitle}</h6>
                      {request.isHotJob && (
                        <span className="tag tag-coral mr-9">HOT</span>
                      )}
                    </div>
                    <div className="d-flex mt-1">
                      {request.jobStatus === "open" ? (
                        <span className="tag tag-green1 mb-2">
                          {request.jobStatus.charAt(0).toUpperCase() +
                            request.jobStatus.slice(1)}
                        </span>
                      ) : request.jobStatus === "active" ? (
                        <span className="tag tag-orange1 mb-2">
                          {request.jobStatus.charAt(0).toUpperCase() +
                            request.jobStatus.slice(1)}
                        </span>
                      ) : request.jobStatus === "expired" ? (
                        <span className="tag tag-font_accent mb-2">
                          {request.jobStatus.charAt(0).toUpperCase() +
                            request.jobStatus.slice(1)}
                        </span>
                      ) : (
                        <span className="tag tag-font_accent mb-2">
                          {request.jobStatus.charAt(0).toUpperCase() +
                            request.jobStatus.slice(1)}
                        </span>
                      )}
                      <div className="d-flex ml-1">
                        <span className="d-flex align-items-center tag tag-blue2 font-regular font-primary font-gray1 mb-2">
                          <span className="font-regular font-primary font-gray1 mr-1">
                            Placement Fee:
                          </span>
                          {/* <i class="fa fa-credit-card-alt" aria-hidden="true"></i> */}
                          {/* <small className="font-regular font-primary font-gray1"> */}
                          {"  "}{" "}
                          {request.isFeePercentage
                            ? `${request.placementFee}%`
                            : `${
                                CurrencySymbols[request.placementCurrency]
                                  ? CurrencySymbols[request.placementCurrency]
                                  : request.placementCurrency
                              }${Number(
                                request.placementFee
                              ).toLocaleString()}`}
                          {/* </small> */}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td>{request.jobType}</td>
                  <td>
                    {request.location?.city &&
                    request.location?.state &&
                    request.location?.country &&
                    !request.isRemote ? (
                      <>
                        <span className="font-regular font-primary font-gray1">
                          {request.location.city}, {request.location?.state},
                          {request.location?.country}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="font-regular font-primary font-gray1">
                          Remote
                        </span>
                      </>
                    )}
                  </td>
                  <td>
                    {moment(request.expiresOn).diff(new Date(), "days") < 0 && (
                      <span className="tag tag-red1">
                        <strong> Expired </strong>
                      </span>
                    )}
                    {moment(request.expiresOn).diff(new Date(), "days") ===
                      0 && (
                      <span className="tag tag-red1">
                        <strong> Expires Today </strong>
                      </span>
                    )}
                    {moment(request.expiresOn).diff(new Date(), "days") ===
                      1 && (
                      <span className="tag tag-red1">
                        <strong> 1 Day Left To Fill </strong>
                      </span>
                    )}
                    {moment(request.expiresOn).diff(new Date(), "days") > 1 &&
                      (moment(request.expiresOn).diff(new Date(), "days") <=
                      5 ? (
                        <span className="tag tag-red1">
                          <strong>
                            {" "}
                            {moment(request.expiresOn).diff(
                              new Date(),
                              "days"
                            )}{" "}
                            Days Left To Fill{" "}
                          </strong>
                        </span>
                      ) : (
                        <span className="font-regular">
                          <strong>
                            {" "}
                            {moment(request.expiresOn).diff(
                              new Date(),
                              "days"
                            )}{" "}
                            Days Left To Fill{" "}
                          </strong>
                        </span>
                      ))}
                  </td>

                  <td>
                    {/* <div className="d-flex flex-row-reverse justify-content-end pl-2">
                        {request.candidateNames.length > 3 &&
                          <span className="avatar avatar-bordered ml-neg-10"> {`${request.candidateNames.slice(3, 100).length}+`} </span>
                        }
                        <span>
                          {request.candidateNames.slice(0, 3).map((name, i) => (
                            <span className="avatar avatar-pink1 avatar-bordered  ml-neg-10" key={i}>
                              {name.candidateFname.charAt().toUpperCase()}{name.candidateLname.charAt().toUpperCase()}
                            </span>
                          ))}
                        </span>
                      </div> */}
                    <div className="d-flex flex-row-reverse justify-content-end mx-2">
                      {request.candidateNames.length > 3 && (
                        <span className="avatar avatar-bordered ml-neg-10">
                          {" "}
                          {`${
                            request.candidateNames.slice(3, 100).length
                          }+`}{" "}
                        </span>
                      )}
                      <span>
                        {request.candidateNames.slice(0, 3).map((name, i) => (
                          <span key={i}>
                            <Avatar
                              size="45"
                              name={
                                name.candidateFname + " " + name.candidateLname
                              }
                              round="80px"
                            />
                            {/* {name.candidateFname.charAt()}{name.candidateLname.charAt()} */}
                          </span>
                        ))}
                      </span>
                    </div>
                  </td>

                  {request.totalSubmisions === 0 ? (
                    <td className="p-0 bg-gray3 text-center">
                      All Candidates Viewed
                    </td>
                  ) : (
                    <td
                      className="p-0 bg-green_primary font-white text-center"
                      style={{ "background-color": "#3AB549" }}
                    >
                      {request.totalSubmisions === 1
                          ? `${request.totalSubmisions} New Candidate`
                          : `${request.totalSubmisions}  New Candidates`}
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {getPaginatedData().length > 0 && (
            <div className="d-flex justify-content-end pt-4 px-1">
              <div className="d-flex">
                <div class="ml-auto mx-auto-370 d-flex align-items-center">
                  <button
                    onClick={() => {
                      setPageType("down");
                      props.setCurrentPage((page) => page - 1);
                    }}
                    type="button"
                    disabled={props.currentPage === 1 ? true : false}
                    className="btn btn-icon px-2"
                  >
                    <i className="fal fa-chevron-circle-left"></i>
                  </button>
                  {paginationGroup.map((item) => (
                    <button
                      type="button"
                      className={
                        props.currentPage === item
                          ? "btn btn-text px-2 bg-secondary text-white"
                          : "btn btn-text px-2"
                      }
                      onClick={changePage}
                    >
                      {item}
                    </button>
                  ))}
                  <button
                    onClick={() => {
                      setPageType("up");
                      props.setCurrentPage((page) => page + 1);
                    }}
                    type="button"
                    disabled={
                      props.currentPage === requestTitleArray.slice(-1)[0]
                        ? true
                        : false
                    }
                    className="btn btn-icon px-2"
                  >
                    <i className="fal fa-chevron-circle-right"></i>
                  </button>
                </div>
              </div>
            </div>
          )}
          {newRequestList.length === 0 && (
            <div class="text-center p-5">
              <div class="avatar avatar-lg">
                <i aria-hidden="true" class="fad fa-users-slash"></i>
              </div>
              <div class="mt-2">No records found</div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default RequestTableView;
