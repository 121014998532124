import {
    TOTAL_RECRUITER_BEGIN,
    TOTAL_RECRUITER_SUCCESS,
    TOTAL_RECRUITER_FAILURE
  } from "../../actions/executiveadmin/TotalRecruiter";
  
  const initialState = {
    totalRecruiterLoading: true,
    totalRecruiter: [],
  };
  
  const totalRecruiterReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case TOTAL_RECRUITER_BEGIN:
        return {
          ...state,
          totalRecruiterLoading: true,
          totalRecruiter: [],
        };
      case TOTAL_RECRUITER_SUCCESS:
        return {
          ...state,
          totalRecruiter: payload,
          totalRecruiterLoading: false,
        };
      case TOTAL_RECRUITER_FAILURE:
        return {
          ...state,
          totalRecruiter: [],
          totalRecruiterLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default totalRecruiterReducer;
  