import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import ResumeDownload from "../resume/Index";
import EditJoiningDate from "./EditJoiningDate";
import { getDispositionDetails } from "../../../../../../actions/disposition/Disposition";
import TalentProfile from "../../../../talent/talentprofile/TalentProfile";
import EmailStatus from "../../../../EmailStatus/EmailStatus";
import ScreeningQuestions from "../Screening";
import VettingRequirement from "../Vetting";
import ReadyToHireQA from "../ReadyToHireQA";
import ProfileImage from "../../../../commoncomponents/profileimage/ProfileImage";
import vettedIcon from "../../../../../../../src/images/high5-mark-circle-dark.svg";

import { FaCommentAlt } from "react-icons/fa";

const Hired = (props) => {
  const {
    jobData,
    hiredData,
    setCandidateProfile,
    candidateProfile,
    filterParams,
    view,
    setSelectedTalents,
    selectedTalents,
    dispositionCount,
  } = props;
  const dispatch = useDispatch();
  const { Id } = useParams();
  const [resume, setResume] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const [showEditJoiningDate, setShowEditJoiningDate] = useState(false);
  const [candidateData, setCandidateData] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState({});
  const [qna, setQNA] = useState(false);
  const [screeningQuestion, setScreeningQuestion] = useState();
  const [vetting, setVetting] = useState(false);
  const [readyToHireQA, setReadyToHireQA] = useState(false);
  const [isCandidateFrom, setIsCandidateFrom] = useState("");

  const handleResume = (data) => {
    setResumeData(data);
    setResume(true);
  };

  const reloadDisposition = () => {
    const payload = {
      jobId: Id,
    };
    dispatch(getDispositionDetails(payload));
    setShowEditJoiningDate(false);
  };

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };

  // // Function to toggle selected talent state
  const toggleSelectedTalent = (candidate) => {
    let userId = candidate?.userId;
    let firstName = candidate?.firstName;
    let lastName = candidate?.lastName;
    let email = candidate?.email;
    let newSletter = candidate?.newSletter;
    let designation = candidate?.designation;
    let homePhoneCode = candidate?.homePhoneCode;
    let workPhoneCode = candidate?.workPhoneCode;
    let homePhone = candidate?.homePhone;
    let workPhone = candidate?.workPhone;
    let recruiterId = candidate?.recruiterId;
    let fk_tenant = candidate?.fk_tenant;
    let createdBy = candidate?.createdBy;

    let data = {
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      newSletter: newSletter,
      designation: designation,
      homePhoneCode: homePhoneCode,
      workPhoneCode: workPhoneCode,
      homePhone: homePhone,
      workPhone: workPhone,
      recruiterId: recruiterId,
      fk_tenant: fk_tenant,
      createdBy: createdBy,
    };

    // setSelectedTalents((prevData) =>
    //   prevData.includes(id)
    //     ? prevData.filter((x) => x != id)
    //     : [...prevData, id]
    // );

    setSelectedTalents((prevData) =>
      prevData.some((x) => x.userId === userId)
        ? prevData.filter((c) => c.userId != userId)
        : [...prevData, data]
    );
  }; // Function to toggle selected talent state

  return (
    <>
      {hiredData.map((item, i) => (
        <tr key={i}>
          <td className="no-hover">
            {dispositionCount?.filter((e) => e.Status == "Hired")[0]?.Count >
              0 && (
              // filterLength > 0 &&
              <div className="d-flex align-items-start mb-1 justify-content-center h-100">
                <input
                  type="checkbox"
                  className="form-check-input"
                  style={{ width: "1.5rem", borderRadius: "100" }}
                  checked={selectedTalents?.some(
                    (c) => c.userId === item?.userId
                  )}
                  onClick={() => toggleSelectedTalent(item)}
                />
              </div>
            )}
          </td>
          <td onClick={() => onProfileClick(item)}>
            <div className="d-flex flex-inline w-70">
              <ProfileImage
                imageSrc={item?.profileImagePath}
                imageWidth={"35"}
                imageHeight={"35"}
                avatarSize={"35"}
                avatarRound={"80px"}
                firstName={item?.firstName}
                lastName={item?.lastName}
              />
              <span className="ml-2 font-16">
                {item?.firstName.charAt(0).toUpperCase() +
                  item?.firstName.slice(1)}{" "}
                {item?.lastName.charAt(0).toUpperCase() +
                  item?.lastName.slice(1)}
              </span>
              <div className="justify-content-start">
                {!item?.readStatus && (
                  <button
                    type="button"
                    title="Send SMS"
                    class="btn btn-text"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      setCandidateProfile(true);
                      setIsCandidateFrom("sms");
                    }}
                  >
                    <div className="notification-icon">
                      <FaCommentAlt />
                      <span className="message-status"></span>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </td>
          <td>
            <span class="tag tag-lg tag-coral ml-2">
              <img width="14px" src={vettedIcon} alt="" />
              <span class="mx-2">Yes</span>
            </span>
          </td>

          <td>
            {item?.offeredDate
              ? moment(new Date(item?.offeredDate)).fromNow()
              : moment(new Date(item?.updatedDateTime)).fromNow()}
          </td>
          <td class="no-hover">
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Knockout Assessment"
              class="d-inline-block"
              data-original-title="Knockout Assessment"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                class="btn btn-square btn-gray"
                disabled={
                  (item?.screeningQuestions
                    ? JSON.parse(item?.screeningQuestions)
                    : []
                  ).length === 0
                    ? true
                    : false
                }
                onClick={() => {
                  setQNA(true);
                  setScreeningQuestion(item);
                }}
              >
                <i aria-hidden="true" class="fad fa-webcam"></i>
              </button>
            </div>{" "}
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Vetting"
              class="d-inline-block"
              data-original-title="Vetting"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                disabled={item?.vettingDetails?.length === 0 ? true : false}
                class="btn btn-square btn-gray"
                onClick={() => {
                  setVetting(true);
                  setScreeningQuestion(item);
                }}
              >
                <i aria-hidden="true" class="fas fa-walkie-talkie"></i>
              </button>
            </div>{" "}
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Ready To Hire"
              class="d-inline-block"
              data-original-title="Ready To Hire"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                disabled={item?.readyToHireQA ? false : true}
                class="btn btn-square btn-gray"
                onClick={() => {
                  setReadyToHireQA(true);
                  setScreeningQuestion(item);
                }}
              >
                <i aria-hidden="true" class="fas fa-ballot-check"></i>
              </button>
            </div>
          </td>
          {/* Resume and interview */}
          <td>
            <label className="d-none d-block-1024">
              <strong>Resumes</strong>
            </label>
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Resume Download"
              style={{ padding: "2px" }}
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewResumeDocModal"
                className="btn btn-square btn-gray"
                onClick={() => handleResume(item)}
              >
                <i className="fas fa-file-user" />
              </button>
            </div>
          </td>
          {/* {getUserRole() === "Recruiter" && (
            <td className="no-hover">
              <span className="ml-2">
                {item.URLOpenCount ? item.URLOpenCount : 0}
              </span>
            </td>
          )} */}
          {/* <td class="no-hover">
            <span class="tag tag-lg tag-coral ml-2">
              <img width="14px" src={vettedIcon} alt="" />
              <span class="mx-2">YES</span>
            </span>
          </td> */}
          {/* <td>
            <label className="d-none d-block-1024">
              <strong>Interviews</strong>
            </label>
          </td> */}
          {/* <td className="no-hover">
            {
              <span className="">
                {props.calculateScore(jobData, item) + "%"}
              </span>
            }
          </td> */}
        </tr>
      ))}
      {resume && (
        <ResumeDownload
          resume={resume}
          setResume={setResume}
          resumeData={resumeData}
        />
      )}
      {showEditJoiningDate && (
        <EditJoiningDate
          candidateData={candidateData}
          showEditJoiningDate={showEditJoiningDate}
          setShowEditJoiningDate={setShowEditJoiningDate}
          reloadDisposition={reloadDisposition}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          candidateData={selectedCandidate}
          handleSuccess={handleModel}
          jobData={jobData}
          parent="hiredTab"
          isFrom="hiredPooled"
          component="TalentPool"
          // poolId={poolId}
          isCandidateFrom={isCandidateFrom}
          setIsCandidateFrom={setIsCandidateFrom}
        />
      )}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="Hired"
          invitedDate={selectedTalent?.invitedDate}
          jobData={jobData}
        />
      )}
      <ScreeningQuestions
        QandA={
          screeningQuestion?.screeningQuestions
            ? JSON?.parse(screeningQuestion?.screeningQuestions)
            : []
        }
        setQNA={setQNA}
        qna={qna}
      />

      <VettingRequirement
        vettingDetails={screeningQuestion?.vettingDetails}
        setVetting={setVetting}
        vetting={vetting}
        jobData={jobData}
      />

      <ReadyToHireQA
        QandA={
          screeningQuestion?.readyToHireQA
            ? JSON?.parse(screeningQuestion?.readyToHireQA)
            : []
        }
        setQNA={setReadyToHireQA}
        qna={readyToHireQA}
      />
    </>
  );
};

export default Hired;
