import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";
import { ButtonLoader } from "../../../../constants/Constants";
import { post } from "../../../../service/ApiService";
const JobAlerts = ({ data, getData }) => {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [saveClicked, setSaveClicked] = useState(true);
  let [enableSubmit, setEnableSubmit] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [jobAlerts, setJobAlerts] = useState([]);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [toggle, setToggle] = useState(data?.newSletter !== "Unsubscribed");
  const [loading, setLoading] = useState(false);
  const changeReadOnly = () => {
    setReadOnlyMode(!readOnlyMode);
    setSaveClicked(true);
    
  };
  const [alert, setAlert] = useState({});
  const email = sessionStorage.getItem("userName");
  const savePref = () => {
    setSaveClicked(false);
    setSubmittingFrom(true);
    try {
      // let url = "/inviteunsubscribed";
      let url = "/candidate/edit";

      setLoading(true);
      post(url, {
        isToggle: !alert.isToggle,
        candidateId: sessionStorage.getItem("userId"),
        keywords: alert.keyword,
      })
        .then((response) => {
          setLoading(false);
          setSubmittingFrom(false);
          setShowSuccessMessage(true);
          // setSaveClicked(false);
          setTimeout(() => {
            setReadOnlyMode(true);
            setShowSuccessMessage(false);
            getData();
          }, 1000);
          toast.success("Updated successfully!");
          // setSaveClicked(true);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setSubmittingFrom(false);
          setSaveClicked(true);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setSubmittingFrom(false);
      setSaveClicked(true);
    }
  };
  const deleteAlert = (alert) => {
    setSaveClicked(false);
    setSubmittingFrom(true);
    try {
      // let url = "/inviteunsubscribed";
      let url = "/candidate/delete";

      setLoading(true);
      post(url, {
        isToggle: alert.isToggle,
        candidateId: sessionStorage.getItem("userId"),
        keywords: alert.keyword,
      })
        .then((response) => {
          setLoading(false);
          setSubmittingFrom(false);
          setShowSuccessMessage(true);
          // setSaveClicked(false);
          setTimeout(() => {
            setReadOnlyMode(true);
            setShowSuccessMessage(false);
            getData();
          }, 1000);
          toast.success("Deleted successfully!");
          window.location.reload();
          // setSaveClicked(true);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          setSubmittingFrom(false);
          setSaveClicked(true);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
      setSubmittingFrom(false);
      setSaveClicked(true);
    }
  };

  useEffect(() => {
    if (data?.jobAlerts?.length > 0) {
      const unique2 = data.jobAlerts.filter((obj, index) => {
        return (
          index ===
          data.jobAlerts.findIndex(
            (o) =>
              obj.candidateId === o.candidateId &&
              obj.keyword === o.keyword
          )
        );
      });
      setJobAlerts(unique2);
    }
  }, [data]);
  return (
    <div className={"read-only mb-2"}>
      {loading && <Loader />}
      <div className="card card-lg">
        <div className="card-body">
          <div className="d-flex align-items-center justify-content-between">
            <label className="pt-0 mb-2">
              <strong>Job Alerts</strong>
            </label>
            {readOnlyMode ? (
              data?.jobAlerts.length > 0 &&
              data?.jobAlerts[0].keyword != null && (
                <button
                  type="button"
                  v-if="form.security.readOnly"
                  className="btn btn-text"
                  onClick={changeReadOnly}
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                </button>
              )
            ) : (
              <div>
                <button
                  type="button"
                  onClick={() => {
                    changeReadOnly();
                    setToggle(data.newSletter !== "Unsubscribed");
                    if (data?.jobAlerts?.length > 0) {
                      const unique2 = data.jobAlerts.filter((obj, index) => {
                        return (
                          index ===
                          data.jobAlerts.findIndex(
                            (o) =>
                              obj.candidateId === o.candidateId &&
                              obj.keywords === o.keywords &&
                              obj.jobs === o.jobs
                          )
                        );
                      });
                      setJobAlerts(unique2);
                    }
                  }}
                  className="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={savePref}
                  className="btn btn-sm btn-primary"
                  disabled={enableSubmit}
                >
                  {saveClicked && <span>Save Changes</span>}

                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img width="20px" src={ButtonLoader} alt="" />
                    </span>
                  )}
                  {showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>{" "}
          <hr />
          {readOnlyMode ? (
            data?.jobAlerts.length > 0 && data?.jobAlerts[0].keyword == null ? (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-comment-slash"></i>
                  </div>
                  <div className="mt-2">No Record Found</div>
                </div>
              </div>
            ) : (
              <div class="">
                <div class="mr-3 d-flex flex-column align-items-center">
                  <div class="view-panel-spacer mt-1"></div>
                </div>

                {data?.jobAlerts[0].keyword != null &&
                  jobAlerts?.map((elem) => {
                    return (
                      <div class=" ">
                        <div class="d-flex mb-1 ">
                          <div className="col-10">
                            <p>
                              {elem?.keyword[0]?.toUpperCase() +
                                elem?.keyword?.slice(1)}
                            </p>
                          </div>{" "}
                          <div className="col-2 text-end">
                            <p>{elem?.isToggle ? "Yes" : "No"}</p>
                          </div>{" "}
                        </div>
                      </div>
                    );
                  })}
              </div>
            )
          ) : (
            <div class="work-experience">
              {data?.jobAlerts.length > 0 &&
                data?.jobAlerts[0].keyword != null &&
                jobAlerts.map((elem, idx) => {
                  return (
                    <div class="d-flex">
                      <div className="w-100">
                        <div class="  d-flex align-items-center">
                          <div className="mr-2 mt-3 col-11">
                            <p>
                              {" "}
                              {elem?.keyword[0].toUpperCase() +
                                elem.keyword.slice(1)}
                            </p>
                          </div>
                          <div className="form-check form-switch col-1 d-flex align-items-center">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              name="isSelect"
                              checked={elem.isToggle}
                              onChange={(e) => {
                                setToggle(e.target.checked);
                                setJobAlerts(
                                  jobAlerts.map((e, id) => {
                                    if (idx === id) {
                                      setAlert(e);
                                      return {
                                        ...e,
                                        isToggle: !e.isToggle,
                                      };
                                    }

                                    return e;
                                  })
                                );
                              }}
                            />
                            <i
                              class="fas fa-trash-alt fa-lg ml-2 mt-1 "
                              onClick={() => deleteAlert(elem)}
                            ></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobAlerts;
