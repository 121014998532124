import React, { useState } from "react";
import { toast } from "react-toastify";
import { ButtonLoader } from "../../../../../constants/Constants";
import { post } from "../../../../../service/ApiService";
import { isValidUrl } from "../../../../function/CommonFunction";

function SocialMedia({ data, getData, ip, browserName,updateDetails,setUpdateDetails }) {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [linkedInURL, setLinkedInURL] = useState(data.linkedIn);
  const [githubURL, setGithubURL] = useState(data.github);

  const editMediaInfo = () => {
    setReadOnlyMode(false);
    setSaveClicked(true);
  };

  const cancelSocialMediaInfo = () => {
    setLinkedInURL(data.linkedIn);
    setGithubURL(data.github);
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
  };

  const saveSocialMediaInfo = async () => {
    if (linkedInURL && !isValidUrl(linkedInURL)) {
      toast.error("Invalid LinkedIn URL");
    } else if (githubURL && !isValidUrl(githubURL)) {
      toast.error("Invalid GitHub URL");
    } else {
      setSaveClicked(false);
      setSubmittingFrom(true);
      try {
        const parameters = {
          ...data,
          zipcode: data.zipcode,
          experiences: data.experiences,
          educations: data.educations,
          linkedIn: linkedInURL,
          github: githubURL,
          activity: "Social media updated",
          candidateId: data.userId,
          ipAddress: ip,
          browser: browserName,
        };
        const res = await post("/candidate/updatetalent", parameters);
        if (res.status === 200) {
          setSubmittingFrom(false);
          setShowSuccessMessage(true);
          setUpdateDetails(!updateDetails);
          setTimeout(() => {
            setReadOnlyMode(true);
            setShowSuccessMessage(false);
          }, 1000);
          toast.success("Updated successfully!");
        }
      } catch (error) {
        toast.error("Error Occurred");
      }
    }
  };

  return (
    <>
      <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
        <div className="card card-lg">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="mb-0">Social Media</h6>
              {readOnlyMode ? (
                <button
                  type="button"
                  onClick={editMediaInfo}
                  className="btn btn-text"
                >
                  <i className="fas fa-pencil-alt mr-1"></i>
                  Edit
                </button>
              ) : (
                <div>
                  <button
                    onClick={cancelSocialMediaInfo}
                    type="button"
                    className="btn btn-sm btn-secondary mr-1"
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    onClick={saveSocialMediaInfo}
                    className="btn btn-sm btn-primary"
                  >
                    {saveClicked && <span>Save Changes</span>}
                    {submittingForm && (
                      <span>
                        Saving Changes
                        <img width="20px" src={ButtonLoader} alt="" />
                      </span>
                    )}
                    {showSuccessMessage && <span>Changes Saved!</span>}
                  </button>
                </div>
              )}
            </div>
            <hr />
            <div>
              <div className="row">
                <div className="col-lg-11">
                  <div className="form-floating mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Paste URL"
                      value={linkedInURL}
                      disabled={readOnlyMode}
                      onChange={(e) => setLinkedInURL(e.target.value)}
                    />
                    <label>LinkedIn URL</label>
                  </div>
                </div>
                <div className="col-lg-1">
                  <div className={readOnlyMode ? "d-none" : "ml-3"}>
                    <button
                      type="button"
                      className="btn btn-icon"
                      style={{ marginTop: "17px" }}
                      onClick={() => setLinkedInURL("")}
                    >
                      <i className="fal fa-fw fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              {/* GitHub */}
              <div className="row">
                <div className="col-lg-11">
                  <div className="form-floating mb-2">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Paste URL"
                      value={githubURL}
                      disabled={readOnlyMode}
                      onChange={(e) => setGithubURL(e.target.value)}
                    />
                    <label>Github URL</label>
                  </div>
                </div>
                <div className="col-lg-1">
                  <div className={readOnlyMode ? "d-none" : "ml-3"}>
                    <button
                      type="button"
                      className="btn btn-icon"
                      style={{ marginTop: "17px" }}
                      onClick={() => setGithubURL("")}
                    >
                      <i className="fal fa-fw fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SocialMedia;
