import { useEffect, useState } from "react";
import { timeOptions } from "../../../../constants/Constants";
import Select from "react-select";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getJobList } from "../../../../actions/job/JobList";
import { getJobListCount } from "../../../../actions/job/JobListHeaderCounts";
import { getTenantType, getUserRole } from "../../../../service/AuthService";
import Loader from "../../commoncomponents/Loader/Loader";
import ListView from "../../job/joblist/ListView.js";
import Footer from "../../../layout/Footer.js";

const NewTalentPoolList = () => {
  const dispatch = useDispatch();

  const {
    jobListLoading,
    jobList,
    jobListCounts,
    jobListCountsLoading,
  } = useSelector(
    (state) => ({
      jobListLoading: state.JobList.jobListLoading,
      jobList: state.JobList.jobList,
      jobListCounts: state.jobListCountReducer.jobListCounts,
      jobListCountsLoading: state.jobListCountReducer.jobListCountsLoading,
    }),
    shallowEqual
  );

  const [jobListCount, setJobListCount] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const [viewJobType, setViewJobType] = useState("myjobs");
  const [selectedTimeOption, setSelectedTimeOption] = useState(timeOptions[0]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setJobListCount(jobListCounts);
    if (viewJobType === "myjobs") {
      setDataTotal(jobListCounts[0]?.MyJobCounts);
    } else if (viewJobType === "all") {
      setDataTotal(jobListCounts[0]?.AllJobs);
    } else if (viewJobType === "contract") {
      setDataTotal(jobListCounts[0]?.ContractJobs);
    } else if (viewJobType === "expired") {
      setDataTotal(jobListCounts[0]?.ExpiredJobs);
    } else if (viewJobType === "fullTime") {
      setDataTotal(jobListCounts[0]?.FullTimeJobs);
    } else if (viewJobType === "deactivated") {
      setDataTotal(jobListCounts[0]?.DeactivatedJobs);
    }
  }, [viewJobType, jobListCounts]);

  useEffect(() => {
    let jobType = viewJobType;
    fetchData(currentPage, [], jobType);
    fetchJobCount(currentPage, [], jobType);
  }, [viewJobType, selectedTimeOption]);

  const fetchJobCount = (currentPage, sortArray, viewJobType) => {
    const filterParam = {
      jobTitle: [],
      address: [],
      jobId: [],
      visaStatus: [],
      isHotJob: false,
      jobType: [],
    };

    let days = selectedTimeOption?.value;
    let today = new Date();
    let fromDate = new Date();
    if (days !== "") {
      fromDate.setDate(today.getDate() - days);
      fromDate.toISOString();
      filterParam["fromDate"] = fromDate;
    }

    let paramObj = {
      sortObject: sortArray,
      currentPage: currentPage,
      tab: viewJobType === "fullTime" ? "Full Time" : viewJobType,
      filterQuery: filterParam,
    };
    dispatch(getJobListCount(paramObj));
  };

  const fetchData = (currentPage, sortArray, viewJobType) => {
    const filterParam = {
      jobTitle: [],
      address: [],
      jobId: [],
      visaStatus: [],
      isHotJob: false,
      jobType: [],
    };

    let days = selectedTimeOption?.value;
    let today = new Date();
    let fromDate = new Date();
    if (days !== "") {
      fromDate.setDate(today.getDate() - days);
      fromDate.toISOString();
      filterParam["fromDate"] = fromDate;
    }

    let paramObj = {
      sortObject: sortArray,
      currentPage: currentPage,
      tab: viewJobType === "fullTime" ? "Full Time" : viewJobType,
      filterQuery: filterParam,
    };
    dispatch(getJobList(paramObj));
  };

  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
  };
  const selectWrapperStyle = {
    minWidth: "200px",
  };

  const onChangeClick = (type) => {
    setCurrentPage(1);
    setViewJobType(type);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, [], viewJobType);
  };
  const handleTimeChange = (e) => {
    setCurrentPage(1);
    setSelectedTimeOption(e);
  };

  return (
    <>
      <div className=" w-100" style={{ minHeight: "60vh" }}>
        {(jobListLoading || jobListCountsLoading) && <Loader />}
        <div class="justify-content-between align-items-center">
          <div>
            <label>
              <strong>Jobs Activity</strong>
            </label>{" "}
            {/* <small className="ml-2 text-muted bold">
            ({selectedTimeOption.label})
          </small> */}
          </div>
          <div class="d-flex justify-content-between d-block-768 align-items-end mb-3 border-bottom-gray2">
            <ul class="nav nav-tabs nav-tabs-tablet pt-1 w-100 justify-content-start">
              <li class="nav-item">
                <button
                  type="button"
                  class={
                    viewJobType === "myjobs"
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("myjobs")}
                >
                  <span class="tag mb-1">
                    {jobListCounts[0]?.MyJobCounts ?? 0}
                  </span>{" "}
                  <div>My Jobs</div>
                </button>
              </li>{" "}
              {/* <li class="nav-item">
              <button
                type="button"
                class={
                  viewJobType === "all"
                    ? "nav-link pb-3 mx-2 bg-transparent active"
                    : "nav-link pb-3 mx-2 bg-transparent"
                }
                onClick={() => onChangeClick("all")}
              >
                <span class="tag mb-1">{jobListCounts[0]?.AllJobs ?? 0}</span>{" "}
                <div>All</div>
              </button>
            </li>{" "} */}
              <li class="nav-item">
                <button
                  type="button"
                  class={
                    viewJobType === "contract"
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("contract")}
                >
                  <span class="tag mb-1">
                    {" "}
                    {jobListCounts[0]?.ContractJobs ?? 0}
                  </span>{" "}
                  <div>Contract</div>
                </button>
              </li>{" "}
              <li class="nav-item">
                <button
                  type="button"
                  class={
                    viewJobType === "fullTime"
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("fullTime")}
                >
                  <span class="tag mb-1">
                    {" "}
                    {jobListCounts[0]?.FullTimeJobs ?? 0}
                  </span>{" "}
                  <div>Full Time</div>
                </button>
              </li>{" "}
              <li class="nav-item">
                <button
                  type="button"
                  class={
                    viewJobType === "expired"
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("expired")}
                >
                  <span class="tag mb-1">
                    {jobListCounts[0]?.ExpiredJobs ?? 0}
                  </span>{" "}
                  <div>Halted</div>
                </button>
              </li>{" "}
              <li class="nav-item">
                <button
                  type="button"
                  class={
                    viewJobType === "deactivated"
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("deactivated")}
                >
                  <span class="tag mb-1">
                    {jobListCounts[0]?.DeactivatedJobs ?? 0}
                  </span>{" "}
                  <div>Closed</div>
                </button>
              </li>{" "}
            </ul>
            <div className="pb-3" style={selectWrapperStyle}>
              <Select
                options={timeOptions}
                isSearchable={false}
                onChange={handleTimeChange}
                // onInputChange={handleInputChange}
                styles={styleSheet}
                value={selectedTimeOption}
                isMulti={false}
                //isLoading={isLoading}
              />
            </div>
          </div>
          <div className="d-flex gap-2 mt-2">
            <div className="col-12" style={{ minHeight: "40vh" }}>
              <ListView
                jobList={jobList}
                setJobData={() => {}}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                handlePageChange={handlePageChange}
                jobListCount={jobListCount}
                viewJobType={viewJobType}
                dataTotal={dataTotal}
              />
            </div>
          </div>
        </div>
        {getTenantType() == 1 && <Footer />}
      </div>
    </>
  );
};

export default NewTalentPoolList;
