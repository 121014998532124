import React, { useState } from "react";

const Search = ({
  dispositionDetails,
  view,
  setFilterData,
  filterData,
  value,
  setValue,
}) => {
  // const handleSearch = (e) => {
  //   let searchValue = e.target.value.toLowerCase();
  //   setValue(e.target.value);
  //   let data = dispositionDetails.map((e) => ({
  //     ...e,
  //     name: e.firstName.toLowerCase() + " " + e.lastName.toLowerCase(),
  //   }));
  //   let filter = [];
  //   const regex = new RegExp(searchValue, "i");
  //   if (view == "automatched") {
  //     filter = data.filter((item) => {
  //       if (item.dispositionStatus === 38) {
  //         return regex.test(item.name);
  //       } else {
  //         return item;
  //       }
  //     });
  //   } else if (view == "invite") {
  //     filter = data.filter((item) => {
  //       if (item.dispositionStatus === 33) {
  //         return regex.test(item.name);
  //       } else {
  //         return item;
  //       }
  //     });
  //   } else if (view == "submitted") {
  //     filter = data.filter((item) => {
  //       if (item.dispositionStatus === 6) {
  //         return regex.test(item.name);
  //       } else {
  //         return item;
  //       }
  //     });
  //   } else if (view == "hired") {
  //     filter = data.filter((item) => {
  //       if (item.dispositionStatus === 40) {
  //         return regex.test(item.name);
  //       } else {
  //         return item;
  //       }
  //     });
  //   }

  //   return setFilterData(filter);
  // };
  const handleSearch = (e) => {
    let searchValue = e.target.value.toLowerCase();
    setValue(searchValue);
  };
  const handleClearSearch = () => {
    setValue(""); // Reset the search value
    setFilterData(dispositionDetails); // Reset the filter data
  };
  return (
    <>
      <div
        id="SiteSearchField"
        className="search-field"
        style={{ width: "30%", minWidth: "150px" }}
      >
        <div className="field-wrap">
          <div className="search-input-container d-flex flex-inline">
            <input
              type="text"
              placeholder="Search Candidates"
              className="form-control small font-14"
              onChange={(e) => handleSearch(e)}
              value={value}
              style={{
                borderRadius: "4px",
              }}
            />
            {value && ( // Render the clear button only when there's a search value
              <button
                className="clear-search"
                onClick={handleClearSearch}
                aria-label="Clear search"
                style={{
                  backgroundColor: "#F1F1F1",
                  border: "1px solid #F1F1F1",
                  color: "grey",
                  boxShadow: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginLeft: "-4px",
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            )}
          </div>
          <span className="icon">
            <i aria-hidden="true" className="fas fa-search"></i>
          </span>
        </div>
      </div>
    </>
  );
};

export default Search;
