import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import ModalView from "../../commoncomponents/modal/Modal";
import { get } from "../../../../service/ApiService";
import Loader from "../../../Loader/Loader";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";

const SMSTemplates = (props) => {
  const {
    setActiveTab,
    setSelectedOption,
    selectedTemplate,
    setSelectedTemplate,
  } = props.data;
  const [loading, setLoading] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTemp, setCurrentTemp] = useState(null);

  const dataSize = 10;
  const pageSize = 5;
  // Calculate the index range of jobs to display on the current page
  // const indexOfLastJob = currentPage * dataSize;
  // const indexOfFirstJob = indexOfLastJob - dataSize;
  const startIndex = (currentPage - 1) * dataSize;
  const endIndex = startIndex + dataSize;

  const currentData = emailTemplates.slice(startIndex, endIndex);
  // const currentData = emailTemplates.slice(indexOfFirstJob, indexOfLastJob);

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const response = await get("/smsbroadcast/fetchSmsTemplate");
      if (response.status === 200) {
        setLoading(false);
        setEmailTemplates(response.data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const renderMailContent = (template) => {
    const maxContentLength = 150;
    const truncatedContent =
      template.mailContent.length > maxContentLength
        ? `${template.mailContent.substring(0, maxContentLength)}...`
        : template.mailContent;

    return (
      <div className="card-text" style={{ fontWeight: "lighter" }}>
        {truncatedContent}
      </div>
    );
  };

  return (
    <>
      {loading && <Loader />}
      <Container className="mt-4 vh-80" style={{ fontSize: "14px" }}>
        <Row className="d-flex">
          {currentData.map((template) => (
            <Col md={10} lg={4} sm={10} key={template.Id_BroadcastTemplates}>
              <Card
                className="smsTempCards"
                style={{
                  width: "100%",
                  minHeight: "200px",
                  margin: "10px",
                }}
              >
                <Card.Header>{template.subject}</Card.Header>
                <Card.Body>
                  {renderMailContent(template)}
                  <Button
                    variant="primary"
                    size="sm"
                    style={{
                      position: "absolute",
                      bottom: "11px",
                      right: "11px",
                      padding: "6px",
                      // fontSize: "14px",
                    }}
                    onClick={() => {
                      setCurrentTemp(template);
                      setTemplateModal(true);
                    }}
                  >
                    View
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      {currentData && currentData?.length > 0 && (
        <Row className="d-flex justify-content-between smsSendBoxPag mb-5">
          <Col md={6} sm={12} className="mt-5 text-md-left text-sm-center">
            Showing{" "}
            {Math.min((currentPage - 1) * 10 + 1, emailTemplates?.length)} -{" "}
            {Math.min(currentPage * 10, emailTemplates?.length)} of{" "}
            {emailTemplates?.length}
          </Col>
          <Col md={6} sm={12} className="mt-5 text-md-right text-sm-center">
            <Pagination
              currentPage={currentPage}
              totalSize={emailTemplates?.length}
              sizePerPage={10}
              firstPageText="|⟨"
              lastPageText="⟩|"
              showFirstLastPages={true}
              changeCurrentPage={handlePageChange}
              theme="border-bottom"
            />
          </Col>
        </Row>
      )}

      {templateModal && (
        <ModalView
          modalSize="lg"
          //   modalTitle="Select Template"
          isModalOpen={templateModal}
          setModalClose={setTemplateModal}
          isFrom="SmsBroadcast"
          modalFor="selectTemplate"
          data={{
            currentTemp: currentTemp,
            setTemplate: setSelectedTemplate,
            setActiveTab: setActiveTab,
            setSelectedOption: setSelectedOption,
          }}
        />
      )}
    </>
  );
};

export default SMSTemplates;
