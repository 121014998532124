import React from "react";

const MatchedData = ({ jobData, candidateData, candidateDetails }) => {
  let column1 = "col-lg-3 text-left";
  let column2 = "col-lg-4 text-left";
  const hr = () => {
    let line = (
      <div className="row">
        <div className="col-lg-12">
          <hr className="m-1" />
        </div>
      </div>
    );
    return line;
  };
  return (
    <>
      <p className="mt-1  ">
        <strong>General: </strong>
      </p>
      <div className="row mt-1">
        <div className={column1}>Parameter</div>
        <div className="col-lg-4 text-left ml-1">Matched</div>
        <div className="col-lg-4 text-left ml-1">Gap</div>
      </div>
      {hr()}
      {console.log('matchinggggg',jobData)}
      {(jobData?.primarySkills
            ? JSON.parse(jobData?.primarySkills)
            : [] )&&<div className="row mb-1 font-14">
        <div className={column1}>
          <small>Mandatory Skills</small>
        </div>
        <div className={column2}>
          {(jobData?.primarySkills
            ? JSON.parse(jobData?.primarySkills)
            : []
          )?.map(
            (skill) =>
              JSON.parse(candidateData?.primarySkills)?.includes(skill) && (
                <small className="ml-1 mb-1 tag tag-green1"> {skill} </small>
              )
          )}
        </div>
        <div
          className="col-lg-4 text-left"
          style={{ width: "40%", marginLeft: "8px" }}
        >
          {(jobData?.primarySkills
            ? JSON.parse(jobData?.primarySkills)
            : []
          )?.map(
            (skill) =>
              !JSON.parse(candidateData?.primarySkills)?.includes(skill) && (
                <small class="ml-1 mb-1 tag tag-red1 ">{skill}</small>
              )
          )}
        </div>
      </div>}

      {JSON.parse(jobData?.weightage)?.location && (
        <div className="row mb-1 font-14">
          <div className={column1}>
            <small>Location</small>
          </div>
          <div className={column2}>
            <small>
              <small className="ml-1 tag tag-green1">
                {" "}
                {jobData?.isRemote?"Remote":jobData?.country}{" "}
              </small>
            </small>
          </div>
          <div className={column2}>
            {/* <small className="ml-1">
            {jobData?.isRemote
              ? candidateData?.isRemote !== true && (
                  <small class="ml-1  tag tag-red1">Remote</small>
                )
              : null}
            {jobData?.workPlaceType == "Hybrid" &&
              candidateData?.isHybrid == false && (
                <small class="ml-1 tag tag-red1">Hybrid</small>
              )}
          </small> */}
          </div>
        </div>
      )}

      {JSON.parse(jobData?.weightage)?.jobTitle && (
        <div className="row mb-1 font-14">
          <div className={column1}>
            <small>Job Title</small>
          </div>
          <div className={column2}>
            <small className="ml-1 ">
              {candidateData?.designation?.toLowerCase().trim() ===
                jobData?.jobTitle?.toLowerCase().trim() && (
                <small className="tag tag-green1">{jobData?.jobTitle}</small>
              )}
            </small>
          </div>
          <div className={column2}>
            <small className="ml-1 ">
              {candidateData?.designation?.toLowerCase().trim() !==
                jobData?.jobTitle?.toLowerCase().trim() && (
                <small class="ml-1  tag tag-red1">{jobData?.jobTitle}</small>
              )}
            </small>
          </div>
        </div>
      )}

      {(JSON.parse(jobData.weightage).length > 0 && jobData?.education
        ? JSON.parse(jobData?.education)
        : []?.length > 0) && (
        <div className="row mb-1 font-14 ">
          <div className={column1}>
            <small>Education</small>
          </div>

          <div className={column2}>
            {(jobData?.education ? JSON.parse(jobData?.education) : [])?.map(
              (skill) => (
                <small className="">
                  {candidateDetails?.highestEducation?.includes(skill) ? (
                    <small className="ml-1 tag tag-green1 "> {skill} </small>
                  ) : (
                    candidateDetails.highestEducation === "Master's Degree" && (
                      <small className="ml-1 tag tag-green1 "> {skill} </small>
                    )
                  )}
                </small>
              )
            )}
            {candidateDetails?.highestEducation === "Doctorate" &&
              (jobData?.education
                ? JSON.parse(jobData?.education)
                : []
              )?.map((skill) => (
                <small className="ml-1">
                  {candidateDetails?.highestEducation?.includes(skill) ? (
                    <small className="tag tag-green1 "> {skill} </small>
                  ) : (
                    <small className="tag tag-green1 "> {skill} </small>
                  )}
                </small>
              ))}
          </div>

          <div className={column2}>
            {candidateDetails?.highestEducation !== "Master's Degree" &&
              candidateDetails?.highestEducation !== "Doctorate" &&
              (jobData?.education
                ? JSON.parse(jobData?.education)
                : []
              )?.map((skill) => (
                <small className="ml-1">
                  {!candidateDetails?.highestEducation?.includes(skill) && (
                    <small class="ml-1 tag tag-red1 ">{skill}</small>
                  )}
                </small>
              ))}
          </div>
        </div>
      )}

      {JSON.parse(jobData?.weightage)?.experienceLevel &&
        jobData?.experienceLevel && (
          <div className="row mb-1 font-14">
            <div className={column1}>
              <small>Experience Level</small>
            </div>

            <div className={column2}>
            <small className="ml-1 ">
              {candidateData?.experienceLevel?.toLowerCase().trim() ===
                jobData?.experienceLevel?.toLowerCase().trim() && (
                <small className="tag tag-green1">{jobData?.experienceLevel}</small>
              )}
            </small>
          </div>
          <div className={column2}>
            <small className="ml-1 ">
              {candidateData?.experienceLevel?.toLowerCase().trim() !==
                jobData?.experienceLevel?.toLowerCase().trim() && (
                <small class="ml-1  tag tag-red1">{jobData?.experienceLevel}</small>
              )}
            </small>
          </div>
          </div>
        )}
    </>
  );
};

export default MatchedData;
