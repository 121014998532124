import React, { useState, useEffect, useRef } from "react";
import Validator from "email-validator";
import { toast } from "react-toastify";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Select from "react-select";
import PaginationComponent from "../../pagination/pagination.js";
import moment from "moment";
import _ from "lodash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  getCuratorsDetails,
  getCuratorsDataCount,
} from "../../../../actions/curator/Curator.js";
import Loader from "../../../Loader/Loader.js";
import Sort from "../../commoncomponents/sort/Index";
import { orderList, curatorSortObject } from "../../pagedata/PageData.js";
import RecSidePanel from "../../superAdmin/Dashboard/Recruiters/RecSidePanel.js";
import { getClientNameData } from "../../../../actions/dashboard/JobSummary";
import AddNewCurator from "../assigncurator/AddNewCurator.js";
import { post } from "../../../../service/ApiService";
import { getUserRole } from "../../../../service/AuthService.js";
import Index from "./filter/Index.js";
import { getDropdownValues } from "../../../../actions/common/GetDropdownValues";

const Curators = ({ isFrom }) => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);
  const [searchData, setSearchData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const isFirstRender = useRef(true);
  const [dataSize, setDataSize] = useState(10);
  const [curatorData, setCuratorData] = useState([]);
  const [totalCount, setTotalCount] = useState([]);
  const [sidePanel, setSidePanel] = useState(false);
  const [options, setOptions] = useState([{ value: "0", label: "All" }]);
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    value: "0",
    label: "All",
  });

  const [addCurator, setAddCurator] = useState(false);
  const [selectedClient, setSelectedClient] = useState(0);

  const [phoneValidation, setPhoneValidation] = useState(true);
  const [validation, setValidation] = useState({
    community: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    city: "",
    state: "",
    country: "",
    client: "",
  });
  const [newCuratorData, setNewCuratorData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneCode: "",
    phoneNumber: "",
    searchLocation: "",
    city: "",
    state: "",
    country: "",
  });
  const [filterParams, setFilterParams] = useState({});
  const [selectedData, setSelectedData] = useState({
    country: "",
    state: "",
    address: [],
    language: [],
    region: [],
    jobTitle: [],
    activity: [],
    roles: [],
    jobCategory: [],
    experience: [],
  });

  const [commonLoading, setCommonLoading] = useState(false);

  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
  };

  useEffect(() => {
    const tempArray = curatorSortObject.map((item) => item.value);
    setSorttype(tempArray);
  }, []);

  useEffect(() => {
    fetchCuratorData(1, 10, "", sortArray, null);
    const payload = {
      searchText: "",
      clientId: null,
    };
    dispatch(getCuratorsDataCount(payload));
    dispatch(getDropdownValues());
  }, []);

  const {
    employees,
    curatorDataCount,
    countLoading,
    clientName,
    nameLoading,
    curatorDetailsLoading,
  } = useSelector(
    (state) => ({
      employees: state.curatorDetailsReducer.curatorDetails,
      curatorDetailsLoading: state.curatorDetailsReducer.curatorDetailsLoading,
      curatorDataCount: state.curatorDetailsReducer.curatorDataCount,
      countLoading: state.curatorDetailsReducer.countLoading,
      clientName: state.getJobSummary.clientName,
      nameLoading: state.getJobSummary.nameLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (employees) {
      setCuratorData(employees);
    }
  }, [employees]);

  useEffect(() => {
    if (curatorDataCount) {
      setTotalCount(curatorDataCount[0]?.TotalCount);
    }
  }, [curatorDataCount]);

  useEffect(() => {
    if (isFrom == "High5Admin") {
      const delay = 500;
      const debounceTimer = setTimeout(() => {
        if (searchText.length === 0 || searchText.length >= 2) {
          let payload = {
            searchText: searchText,
          };
          dispatch(getClientNameData(payload));
        }
      }, delay);
      return () => clearTimeout(debounceTimer);
    }
  }, [searchText]);

  useEffect(() => {
    if (clientName.length > 0) {
      let op = clientName?.map((item, index) => ({
        value: item.id,
        label: item?.tenantname.replace("GIG - ", ""),
      }));
      setOptions([{ value: 0, label: "All" }, ...op]);
    }
  }, [clientName]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchData.length === 0 || searchData.length >= 2) {
        const tenantId = selectedClient == 0 ? null : selectedClient;
        fetchCuratorData(1, dataSize, searchData, sortArray, tenantId);
        const payload = {
          searchText: searchData,
          clientId: tenantId,
        };
        dispatch(getCuratorsDataCount(payload));
        setCurrentPage(1);
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchData]);

  const fetchCuratorData = async (
    pageNumber,
    limit,
    searchVal,
    sortArray,
    clientId,
    filter = null
  ) => {
    const payload = {
      currentPage: pageNumber,
      limit: limit,
      searchText: searchVal,
      sortQuery: sortArray,
      clientId: clientId ? clientId : null,
      filterObject: filter ? filter : filterParams,
    };
    dispatch(getCuratorsDetails(payload));
  };

  const applySort = (sortedData, close) => {
    close && setSort(false);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchCuratorData(1, dataSize, searchData, sortedData, tenantId);
  };

  const handleSizeChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchCuratorData(1, ds, searchData, sortArray, tenantId);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
  };

  const handleClick = (item, type) => {
    setSelectedItem(item);
    setSidePanel(true);
  };

  const handlePageChange = (currentPage) => {
    setCurrentPage(currentPage);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchCuratorData(currentPage, dataSize, searchData, sortArray, tenantId);
  };

  const sortClearAll = () => {
    setSortArray([]);
    const tempArray = curatorSortObject?.map((item) => item.value);
    setSorttype(tempArray);
    setSortCount(0);
    setSortApplied(false);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    fetchCuratorData(1, dataSize, "", [], tenantId);
  };

  const handleChange = (selected) => {
    isFirstRender.current = true;
    setSelectedOption(selected);
    setSearchData("");
    setSortArray([]);
    setDataSize(10);
    const tenantId = selected.value == 0 ? null : selected.value;
    fetchCuratorData(1, 10, "", [], tenantId, {});
    const payload = {
      searchText: "",
      clientId: tenantId,
      filterObject: {},
    };
    dispatch(getCuratorsDataCount(payload));
    setSelectedClient(tenantId);
    clearData();
  };

  const handleChangeInput = (e) => {
    setSearchText(e);
  };

  const clearData = () => {
    setNewCuratorData({
      firstName: "",
      lastName: "",
      email: "",
      phoneCode: "",
      phoneNumber: "",
      searchLocation: "",
      city: "",
      state: "",
      country: "",
    });
    setValidation({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      city: "",
      state: "",
      country: "",
      client: "",
    });
    setSelectedData({
      country: "",
      state: "",
      address: [],
      language: [],
      region: [],
      jobTitle: [],
      activity: [],
      roles: [],
      jobCategory: [],
      experience: [],
    });
  };

  const addNewCuratorValidate = (selectedCuratorClient) => {
    let newErrors = {};
    if (!selectedCuratorClient || !selectedCuratorClient?.value) {
      toast.error("Please select client");
      newErrors.client = "*Required field";
    }
    if (!newCuratorData.firstName || newCuratorData.firstName === "")
      newErrors.firstName = "*Required field";
    else if (
      !/^[A-Z a-z\s]+$/.test(newCuratorData.firstName) ||
      /^\s*$/.test(newCuratorData.firstName)
    )
      newErrors.firstName = "Invalid First Name ";

    if (!newCuratorData.lastName || newCuratorData.lastName === "")
      newErrors.lastName = "*Required field";
    else if (
      !/^[A-Z a-z\s]+$/.test(newCuratorData.lastName) ||
      /^\s*$/.test(newCuratorData.lastName)
    )
      newErrors.lastName = "Invalid Last Name ";

    if (!newCuratorData.email || newCuratorData.email === "")
      newErrors.email = "*Required field";
    else if (!Validator.validate(newCuratorData.email))
      newErrors.email = "Invalid Email Id !";

    if (!newCuratorData.city || newCuratorData.city === "")
      newErrors.city = "*Required field";
    else if (
      !/^[A-Z a-z\s]+$/.test(newCuratorData.city) ||
      /^\s*$/.test(newCuratorData.city)
    )
      newErrors.city = "Invalid City";

    if (!newCuratorData.state || newCuratorData.state === "")
      newErrors.state = "*Required field";
    else if (
      !/^[A-Z a-z\s]+$/.test(newCuratorData.state) ||
      /^\s*$/.test(newCuratorData.state)
    )
      newErrors.state = "Invalid State";

    if (!newCuratorData.country || newCuratorData.country === "")
      newErrors.country = "*Required field";
    else if (
      !/^[A-Z a-z\s]+$/.test(newCuratorData.country) ||
      /^\s*$/.test(newCuratorData.country)
    )
      newErrors.country = "Invalid Country";
    if (!newCuratorData.phoneNumber || newCuratorData.phoneNumber === "")
      newErrors.phoneNumber = "*Required field";
    else if (!phoneValidation) newErrors.phoneNumber = "Invalid Phone number";

    return newErrors;
  };

  const handleAddNewCurator = async (selectedCuratorClient) => {
    const newErrors = addNewCuratorValidate(selectedCuratorClient);
    if (Object.keys(newErrors).length > 0) {
      setValidation({
        ...validation,
        firstName: newErrors.firstName,
        lastName: newErrors.lastName,
        email: newErrors.email,
        phoneNumber: newErrors.phoneNumber,
        city: newErrors.city,
        state: newErrors.state,
        country: newErrors.country,
        client: newErrors.client,
      });
    } else {
      setCommonLoading(true);
      const payload = {
        recruiterDetails: newCuratorData,
        tenantId: selectedCuratorClient?.value,
        tenantEmail: selectedCuratorClient.tenantemail,
        clientName: selectedCuratorClient?.label,
        tenantName: selectedCuratorClient.tenantName,
      };

      const res = await post("/curator/addnewcurator", payload);
      setCommonLoading(false);
      if (res?.data?.message == "Email Id Already Exist") {
        toast.error(res?.data?.message);
      } else {
        setAddCurator(false);
        toast.success("Curator added successfully");
        const tenantId = selectedClient == 0 ? null : selectedClient;
        fetchCuratorData(
          currentPage,
          dataSize,
          searchData,
          sortArray,
          tenantId,
          {}
        );
        const payload = {
          searchText: searchData,
          clientId: tenantId,
          filterObject: {},
        };
        dispatch(getCuratorsDataCount(payload));
        clearData();
      }
    }
  };

  const getFilterParamsNew = (val, status) => {
    const filterParam = {
      jobTitle:
        status == "" ? [] : status == "jobTitle" ? val : selectedData.jobTitle,
      language:
        status == "" ? [] : status == "language" ? val : selectedData.language,
      region:
        status == "" ? [] : status == "region" ? val : selectedData.region,
      jobCategory:
        status == ""
          ? []
          : status == "jobCategory"
          ? val
          : selectedData.jobCategory,
      roles: status == "" ? [] : status == "roles" ? val : selectedData.roles,
      activity:
        status == "" ? [] : status == "activity" ? val : selectedData.activity,
      experience:
        status == ""
          ? []
          : status == "experience"
          ? val
          : selectedData.experience,
      country:
        status == ""
          ? ""
          : status == "country"
          ? val
          : status == "state"
          ? ""
          : status == "address"
          ? ""
          : selectedData.country,
      state:
        status == ""
          ? ""
          : status == "state"
          ? val
          : status == "address"
          ? ""
          : status == "country"
          ? ""
          : selectedData.state,
      address:
        status == ""
          ? []
          : status == "address"
          ? val
          : status == "country"
          ? []
          : status == "state"
          ? []
          : selectedData.address,
    };

    setFilterParams(filterParam);
    return filterParam;
  };

  const applyFilter = (val, status) => {
    setSidePanel(false);
    setCurrentPage(1);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    const payload = {
      currentPage: 1,
      limit: dataSize,
      searchText: searchData,
      sortQuery: sortArray,
      clientId: tenantId ? tenantId : null,
      filterObject: getFilterParamsNew(val, status),
    };
    dispatch(getCuratorsDetails(payload));
    const payloadCount = {
      searchText: "",
      clientId: tenantId ? tenantId : null,
      filterObject: getFilterParamsNew(val, status),
    };
    dispatch(getCuratorsDataCount(payloadCount));
  };

  return (
    <>
      {(curatorDetailsLoading ||
        countLoading ||
        nameLoading ||
        commonLoading) && <Loader />}
      <div>
        <div className="fontSizeChange px-3 mb-5">
          <div className="container mb-2 mt-4">
            <div className="row align-items-center justify-content-between mb-4">
              <div className="col">
                <h6 className="m-0">
                  Curators {totalCount != null ? `(${totalCount})` : ""}
                </h6>
              </div>
              {getUserRole() == "CustomerSuccess" && (
                <div className="col-auto">
                  <button
                    onClick={() => {
                      setAddCurator(true);
                    }}
                    type="button"
                    className="btn btn-sm"
                  >
                    <i className="fal fa-user-plus mr-2"></i>
                    Add Curator
                  </button>
                </div>
              )}
            </div>
            <div className="row align-items-center justify-content-between flex-wrap">
              <div
                className="col-lg-2 col-md-4 col-sm-6 mb-2"
                style={{ zIndex: "0" }}
              >
                <select className="form-select" onChange={handleSizeChange}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="All">All</option>
                </select>
              </div>
              <div className="col-lg-10 col-md-8 col-sm-6 d-flex flex-wrap justify-content-end">
                <div className="col-lg-5 col-md-6 col-sm-12 mb-2 search-input-container mr-2">
                  <input
                    type="text"
                    placeholder="Search Curators by Name and Email"
                    className="form-control small font-14"
                    value={searchData}
                    onChange={handleSearch}
                    handleSearch={handleSearch}
                  />
                </div>

                <div className="col-lg-5 col-md-6 col-sm-12 mb-1 d-flex justify-content-end">
                  <Sort
                    sort={sort}
                    setSort={setSort}
                    sortCount={sortCount}
                    sortData={curatorData}
                    orderArray={orderList}
                    sortObjects={curatorSortObject}
                    applySort={applySort}
                    setSortCount={setSortCount}
                    setSortArray={setSortArray}
                    sortArray={sortArray}
                    sortingFor={"Sort by Name, Email and Created On"}
                    sortType={sortType}
                    setSorttype={setSorttype}
                    sortApplied={sortApplied}
                    setSortApplied={setSortApplied}
                    sortClearAll={sortClearAll}
                  />
                  {isFrom === "High5Admin" && (
                    <div style={{ width: "50%" }}>
                      <Select
                        options={options}
                        isSearchable={true}
                        onChange={handleChange}
                        styles={styleSheet}
                        value={selectedOption}
                        onInputChange={handleChangeInput}
                        className="small font-14"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-4">
              <Index
                setSelectedData={setSelectedData}
                selectedData={selectedData}
                applyFilter={applyFilter}
                closePanel={() => setSidePanel(false)}
              />
            </div>
            <div className="col-8">
              <div className="px-3 pt-0 table-cards-1024">
                <table className="table table-hoverabletable-cards-1024">
                  <thead>
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      {isFrom == "High5Admin" && <th scope="col">Clients</th>}
                      <th scope="col">Assigned On</th>
                    </tr>
                  </thead>
                  <tbody>
                    {curatorData?.map((item) => (
                      <tr key={item?.email}>
                        <td
                          style={{ cursor: "pointer" }}
                          onClick={() => handleClick(item, "employee")}
                        >
                          <a href="javascript:void(0)">
                            {item?.firstName} {item?.LastName}
                          </a>
                        </td>
                        <td>{item?.email}</td>
                        {isFrom == "High5Admin" && (
                          <td>
                            {item.clients &&
                              JSON.parse(item.clients).map((client, index) => (
                                <div key={index} className="tag tag-blue3">
                                  {client.tenantname}
                                </div>
                              ))}
                          </td>
                        )}
                        <td>
                          {item?.createddatetime
                            ? moment(new Date(item?.createddatetime)).format(
                                "MM/DD/YYYY"
                              )
                            : "Never"}
                        </td>
                        <td className="no-hover text-right">
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="right"
                            overlay={
                              <div
                                className="dropdown-menu dropdown-menu-right show"
                                style={{
                                  display: "revert",
                                  right: "0px",
                                  top: "10px",
                                }}
                              ></div>
                            }
                          >
                            <button
                              type="button"
                              className="btn btn-icon dropdown-toggle"
                            ></button>
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {curatorData?.length === 0 && (
                  <div>
                    <div className="text-center p-3 m-5">
                      <div className="avatar avatar-lg">
                        <i className="fad fa-users-slash"></i>
                      </div>
                      <div className="mt-2">
                        No records found{" "}
                        {searchData ? `for "${searchData}"` : ""}
                      </div>
                    </div>
                  </div>
                )}
                <hr className="m-0" />
                <div>
                  {curatorData?.length > 0 && (
                    <>
                      <PaginationComponent
                        currentPage={currentPage}
                        totalItems={totalCount}
                        itemsPerPage={dataSize}
                        onPageChange={handlePageChange}
                        limit={dataSize}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {sidePanel && (
          <RecSidePanel
            details={selectedItem}
            closePanel={() => setSidePanel(false)}
          />
        )}

        {addCurator && (
          <AddNewCurator
            newCuratorData={newCuratorData}
            setNewCuratorData={setNewCuratorData}
            validation={validation}
            phoneValidation={phoneValidation}
            setPhoneValidation={setPhoneValidation}
            closeAddNewCurator={setAddCurator}
            handleAddNewCurator={handleAddNewCurator}
            clearData={clearData}
          />
        )}
      </div>
    </>
  );
};

export default Curators;
