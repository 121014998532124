import React, { useEffect, useState } from "react";
import PaginationFilter from "../../commoncomponents/pagination/PaginationFilter";

const Filter = (props) => {
  //console.log(props)
  let dataLimit = 4;
  let pageLimit = 5;
  //const [ jobTitle, setJobTitle ] = useState(false)
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [candidateLocationEnabled, setCandidateLocationEnabled] = useState(
    false
  );
  const [candidateLocations, setCandidateLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [candidateSkillsEnabled, setCandidateSkillsEnabled] = useState(false);
  const [candidateSkills, setCandidateSkills] = useState([]);
  const [primarySkills, setPrimarySkills] = useState([]);
  const [vetting, setVetting] = useState(false);
  const [vettingEnabled, setVettingEnabled] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [paginationGroup1, setPaginationGroup1] = useState([]);
  const [paginationArray1, setPaginationArray1] = useState([]);
  const [paginationGroup2, setPaginationGroup2] = useState([]);
  const [paginationArray2, setPaginationArray2] = useState([]);
  const [searchLocationValue, setSearchLocationValue] = useState("");
  const [searchSkillsValue, setSearchSkillsValue] = useState("");
  const [searchSkills, setSearchSkills] = useState("");
  const [searchLocation, setSearchLocation] = useState("");
  const [candidateTitles, setCandidateTitles] = useState([]);
  const [candidateTitle, setCandidateTitle] = useState(false);

  useEffect(() => {
    setVetting(props.vetting);
    setSelectedTitles(props.selectedTitles);
    setCandidateTitles(props.candidateTitles);
    setSelectedLocations(props.selectedLocations);
    setCandidateLocations(props.candidateLocations);
    setPrimarySkills(props.primarySkills);
    setCandidateSkills(props.candidateSkills);
    console.log(props);
  }, [props]);

  const titleHandler = (checked, title) => {
    if (checked) {
      setSelectedTitles([...selectedTitles, title]);
    } else {
      setSelectedTitles(selectedTitles.filter((t) => t !== title));
    }
  };

  const skillsHandler = (checked, skills) => {
    if (checked) {
      setPrimarySkills([...primarySkills, skills]);
    } else {
      setPrimarySkills(primarySkills.filter((t) => t !== skills));
    }
  };

  const locatioHandler = (checked, location) => {
    if (checked) {
      setSelectedLocations([...selectedLocations, location]);
    } else {
      setSelectedLocations(selectedLocations.filter((t) => t !== location));
    }
  };

  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  function changePage1(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage1(pageNumber);
  }

  function changePage2(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage2(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : candidateTitles.slice(startIndex, endIndex);
  };

  const getPaginatedData1 = () => {
    const startIndex1 = currentPage1 * 4 - 4;
    const endIndex1 = startIndex1 + +4;
    return searchLocationValue
      ? searchLocation.slice(startIndex1, endIndex1)
      : candidateLocations.slice(startIndex1, endIndex1);
  };

  const getPaginatedData2 = () => {
    const startIndex = currentPage2 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchSkillsValue
      ? searchSkills.slice(startIndex, endIndex)
      : candidateSkills.slice(startIndex, endIndex);
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(candidateTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(candidateTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchLocationValue
      ? setPaginationArray1(
          new Array(Math.ceil(searchLocation.length / 4))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray1(
          new Array(Math.ceil(candidateLocations.length / 4))
            .fill()
            .map((_, i) => i + 1)
        );
    searchLocationValue
      ? setPaginationGroup1(
          new Array(Math.ceil(searchLocation.length / 4))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, 5)
        )
      : setPaginationGroup1(
          new Array(Math.ceil(candidateLocations.length / 4))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, 5)
        );

    searchSkillsValue
      ? setPaginationArray2(
          new Array(Math.ceil(searchSkills.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray2(
          new Array(Math.ceil(candidateSkills.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchSkillsValue
      ? setPaginationGroup2(
          new Array(Math.ceil(searchSkills.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup2(
          new Array(Math.ceil(candidateSkills.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    setCurrentPage(1);
    setCurrentPage1(1);
    setCurrentPage2(1);
  }, [
    candidateTitles,
    searchData,
    searchLocation,
    candidateLocations,
    searchSkills,
    candidateSkills,
  ]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      } else if ((currentPage1 - 1) % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(currentPage1 - 1, currentPage1 + (5 - 1))
        );
      } else if ((currentPage2 - 1) % pageLimit === 0 && currentPage2 !== 1) {
        setPaginationGroup2(
          paginationArray2.slice(
            currentPage2 - 1,
            currentPage2 + (pageLimit - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      } else if (currentPage1 % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(currentPage1 - 5, currentPage1)
        );
      } else if (currentPage2 % pageLimit === 0 && currentPage2 !== 1) {
        setPaginationGroup2(
          paginationArray2.slice(currentPage2 - pageLimit, currentPage2)
        );
      }
    }
  }, [currentPage, currentPage1, currentPage2]);

  const splitMatchedText = (str) => {
    let regExp;
    if (searchValue) {
      regExp = new RegExp(searchValue, "gi");
    } else {
      regExp = new RegExp(searchLocationValue, "gi");
    }
    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchData(
      candidateTitles.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearchSkills = (e) => {
    setSearchSkillsValue(e.target.value);
    setSearchSkills(
      candidateSkills.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearchLocation = (e) => {
    setSearchLocationValue(e.target.value);
    setSearchLocation(
      candidateLocations.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const titleArray = (title) => {
    //let title2 = title ? title.jobTitle : null
    return selectedTitles.length > 0 ? selectedTitles.includes(title) : true;
  };

  const skillsArray = (skill) => {
    if (candidateSkills.length > 0) {
      return skill
        .map((skills) => candidateSkills.includes(skills))
        .some((skills) => skills);
    } else {
      return true;
    }
  };

  const locationArray = (location) => {
    return selectedLocations.length > 0
      ? selectedLocations.includes(location)
      : true;
  };

  const vettedArray = (vetted) => {
    return vetting ? vetted : true;
  };

  const clearAll = () => {
    setSelectedLocations([]);
    setSelectedTitles([]);
    setPrimarySkills([]);
    setVetting(false);
    setSearchValue("");
    setSearchLocationValue("");
    setSearchSkillsValue("");
  };

  const applyFilter = () => {
    let filteredData = props.reportData.filter((item) => {
      return (
        titleArray(item.designation) &&
        locationArray(`${item.city}, ${item.state}, ${item.country}`) &&
        vettedArray(item.isVetted) &&
        skillsArray(JSON.parse(item.primarySkills))
      );
    });
    props.applyFilter(
      filteredData,
      selectedTitles,
      selectedLocations,
      vetting,
      primarySkills
    );
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={applyFilter}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>
                Filter /{" "}
                {selectedTitles.length +
                  selectedLocations.length +
                  (vetting ? 1 : 0) +
                  primarySkills.length}
              </h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear All
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <div className="mb-2">
            {/* <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setVettingEnabled(!vettingEnabled)}
            >
              <i
                className={
                  vettingEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Vetted?</small>
                {vetting && <span className="jewel"></span>}
              </span>
            </button> */}
            {vettingEnabled && (
              <div className="mb-4 ml-4 pl-1 pt-1">
                <div className="d-flex align-items-center m-1">
                  <div>Show Only Vetted Candidates</div>
                  <div className="ml-auto">
                    <div className="form-check form-switch">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={vetting}
                        onChange={(e) => setVetting(e.target.checked)}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-2 px-1">
                  <button
                    className="btn btn-text"
                    onClick={() => setVetting(false)}
                  >
                    <small>Clear Vetted?</small>
                  </button>
                </div>
                <hr className="p-0" />
              </div>
            )}
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateTitle(!candidateTitle)}
            >
              <i
                className={
                  candidateTitle
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Job Title</small>
                {selectedTitles.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateTitle &&
              (candidateTitles.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  {getPaginatedData().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedTitles.includes(j)}
                            className="form-check-input"
                            onChange={(e) => titleHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage === paginationArray.slice(-1)[0]
                        ? getPaginatedData().length
                        : searchValue
                        ? searchData.length < dataLimit
                          ? searchData.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateTitles.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setPaginationArray={setPaginationGroup}
                        data={props.candidateTitles}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue("");
                        setSelectedTitles([]);
                      }}
                    >
                      <small>Clear Job Title</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() =>
                setCandidateLocationEnabled(!candidateLocationEnabled)
              }
            >
              <i
                className={
                  candidateLocationEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Location</small>
                {selectedLocations.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {candidateLocationEnabled &&
              (candidateLocations.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearchLocation}
                    value={searchLocationValue}
                  />
                  {getPaginatedData1().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchLocationValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedLocations.includes(j)}
                            className="form-check-input"
                            onChange={(e) =>
                              locatioHandler(e.target.checked, j)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData1().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchLocationValue}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage1 === paginationArray1.slice(-1)[0]
                        ? getPaginatedData1().length
                        : searchLocationValue
                        ? searchLocation.length < 4
                          ? searchLocation.length
                          : 4
                        : 4}{" "}
                      of {candidateLocations.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage1}
                        setCurrentPage={setCurrentPage1}
                        setPaginationArray={setPaginationGroup1}
                        data={props.candidateLocations}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchLocationValue("");
                        setSelectedLocations([]);
                      }}
                    >
                      <small>Clear Location</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateSkillsEnabled(!candidateSkillsEnabled)}
            >
              <i
                className={
                  candidateSkillsEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Primary Skills</small>
                {primarySkills.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateSkillsEnabled &&
              (candidateSkills.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearchSkills}
                    value={searchSkillsValue}
                  />
                  {getPaginatedData2().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchSkillsValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={primarySkills.includes(j)}
                            className="form-check-input"
                            onChange={(e) => skillsHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData2().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchSkillsValue}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage2 === paginationArray2.slice(-1)[0]
                        ? getPaginatedData2().length
                        : searchSkillsValue
                        ? searchSkills.length < 4
                          ? searchSkills.length
                          : 4
                        : 4}{" "}
                      of {candidateSkills.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage2}
                        setCurrentPage={setCurrentPage2}
                        setPaginationArray={setPaginationGroup2}
                        data={props.candidateSkills}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchSkillsValue("");
                        setPrimarySkills([]);
                      }}
                    >
                      <small>Clear Primary Skills</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          {/* <div className='mb-2'>  
                    <button type="button" className="btn btn-collapsible" onClick={() => setCandidateLocationEnabled(!candidateLocationEnabled)}>                 
                        <i className={ candidateLocationEnabled ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"}/>           
                        <span class="p-relative">
                            <small>Location</small>
                            { selectedLocations.length > 0 && <span className="jewel"></span>}
                        </span>
                    </button>    
                    {   
                        candidateLocationEnabled &&
                        ( candidateLocations.length > 0 ?
                        <div className='mb-4 ml-4 pl-1 pt-1'>   
                            <input 
                                type="text" 
                                placeholder="Type to filter list" 
                                className="form-control form-control-filter mt-2" 
                                onChange={handleSearchLocation}
                            />                                    
                            { searchLocationValue ?
                                searchLocation.map(i => 
                                    <div className='d-flex align-items-center m-1'>
                                        <div>
                                            <span dangerouslySetInnerHTML={{__html: splitMatchedText(i)}}></span>
                                        </div>
                                        <div className='ml-auto'>
                                            <div className='form-check form-switch'>
                                                <input 
                                                    type='checkbox' 
                                                    className='form-check-input' 
                                                    checked={selectedLocations.includes(i)} 
                                                    onChange={(e) => locatioHandler(e.target.checked, i)}
                                                />
                                            </div>
                                        </div>
                                    </div>  
                                ) :
                                candidateLocations.map(i => 
                                    <div className='d-flex align-items-center m-1'>
                                        <div>
                                            <span>{i}</span>
                                        </div>
                                        <div className='ml-auto'>
                                            <div className='form-check form-switch'>
                                                <input 
                                                    type='checkbox' 
                                                    className='form-check-input' 
                                                    checked={selectedLocations.includes(i)} 
                                                    onChange={(e) => locatioHandler(e.target.checked, i)}
                                                />
                                            </div>
                                        </div>
                                    </div>  
                                )
                            }                    
                            <div className='d-flex justify-content-end mt-2 px-1'>
                                <button className='btn btn-text' onClick={() => setSelectedLocations([])}>
                                <small>Clear Location</small>
                                </button>
                            </div> 
                            <hr className='p-0' />                    
                        </div> :
                        <div>
                            <div class="text-center p-3">
                                <div class="avatar avatar-lg">
                                    <i class="fad fa-users-slash"></i>
                                </div>
                                <div class="mt-2">
                                    No  Records found
                                </div>
                            </div>
                        </div> )
                    }                                                            
                </div> */}

          {/* <div className='mb-2'>
                        <button type="button" className="btn btn-collapsible" onClick={() => setCndidateSkillsEnabled(!candidateSkillsEnabled)}>
                            <i className={candidateSkillsEnabled ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"} />
                            <span class="p-relative">
                                <small>Primary Skills</small>
                                {primarySkills.length > 0 && <span className="jewel"></span>}
                            </span>
                        </button>
                        {
                            candidateSkillsEnabled &&
                            (candidateSkills.length > 0 ?
                                <div className='mb-4 ml-4 pl-1 pt-1'>
                                    <input
                                        type="text"
                                        placeholder="Type to filter list"
                                        className="form-control form-control-filter mt-2"
                                        onChange={handleSearchSkills}
                                    />
                                    {searchSkillsValue ?
                                        searchSkills.map(i =>
                                            <div className='d-flex align-items-center m-1'>
                                                <div>
                                                    <span dangerouslySetInnerHTML={{ __html: splitMatchedText(i) }}></span>
                                                </div>
                                                <div className='ml-auto'>
                                                    <div className='form-check form-switch'>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input'
                                                            checked={primarySkills.includes(i)}
                                                            onChange={(e) => skillsHandler(e.target.checked, i)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        ) :
                                        candidateSkills.map(i =>
                                            <div className='d-flex align-items-center m-1'>
                                                <div>
                                                    <span>{i}</span>
                                                </div>
                                                <div className='ml-auto'>
                                                    <div className='form-check form-switch'>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input'
                                                            checked={primarySkills.includes(i)}
                                                            onChange={(e) => skillsHandler(e.target.checked, i)}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className='d-flex justify-content-end mt-2 px-1'>
                                        <button className='btn btn-text' onClick={() => setPrimarySkills([])}>
                                            <small>Clear PrimarySkills</small>
                                        </button>
                                    </div>
                                    <hr className='p-0' />
                                </div> :
                                <div>
                                    <div class="text-center p-3">
                                        <div class="avatar avatar-lg">
                                            <i class="fad fa-users-slash"></i>
                                        </div>
                                        <div class="mt-2">
                                            No  Records found
                                        </div>
                                    </div>
                                </div>)
                        }
                    </div> */}
        </div>
      </div>
    </>
  );
};

export default Filter;
