import React, { useEffect, useState } from "react";
import CandidateRequestDetails from "../job/CandidateRequestDetails";
import moment from "moment";
import { countryObj, High5DarkIcon } from "../../../constants/Constants";
import { toast } from "react-toastify";
import { get, post } from "../../../service/ApiService";
import _ from "lodash";
import OutsideClickHandler from "react-outside-click-handler";
import Sort from "../../common/Sort";
import FilterJobs from "../job/FilterJobs";
import { domain } from "../../../constants/Constants";

function CandidateJobs({ details }) {
  const [matched, setMatched] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [RTR, setRTR] = useState([]);
  const [filteredRTR, setFilteredRTR] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [matchedTab, setMatchedTab] = useState(true);
  const [favoritedTab, setFavoritedTab] = useState(false);
  const [appliedTab, setAppliedTab] = useState(false);
  const [rtrTab, setRTRTab] = useState(false);
  const [jobDetails, setJobDetails] = useState([]);
  const [type, setType] = useState("");
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [requestRTRTitles, setRequestRTRTitles] = useState([]);
  const [requestRTRTypes, setRequestRTRTypes] = useState([]);
  const [jobListData, setJobListData] = useState([]);
  const [search, setSearch] = useState("");
  const [sortModal, setSortModal] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );

  const sortObject = [
    {
      id: 1,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Type",
      value: "jobType",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Location",
      value: "location.city",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },

    // {
    //     id: 4,
    //     label: "Preferred Start Date",
    //     value: "location.city",
    //     icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    // },
    // {
    //     id: 5,
    //     label: " New Candidates",
    //     value: "location.city",
    //     icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    // },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  const fetchAutoMatchedJobs = async () => {
    setLoading(true);
    let requestTitle = [];
    let requestType = [];
    try {
      let res = await get("/CandidateDashboard/postJobautomatchMatch");
      setMatched(res.data);
      setFiltered(res.data);
      res.data.forEach((job) => {
        if (!requestTitle.includes(job.jobTitle)) {
          requestTitle.push(job.jobTitle);
        }
        if (!requestType.includes(job.jobType)) {
          requestType.push(job.jobType);
        }
      });
      setRequestTitles(requestTitle);
      setRequestTypes(requestType);
      getRequestList(res.data);
    } catch (err) {
      toast.error("Failed to get Candidate Jobs");
    }
    setLoading(false);
  };

  const fetchRTR = async () => {
    setLoading(true);
    let requestTitle = [];
    let requestType = [];
    try {
      let res = await post("/CandidateDashboard/fetchRTR", {
        filterValue: "Submitted",
      });
      setRTR(res.data);
      setFilteredRTR(res.data);
      res.data.forEach((job) => {
        if (!requestTitle.includes(job.jobTitle)) {
          requestTitle.push(job.jobTitle);
        }
        if (!requestType.includes(job.jobType)) {
          requestType.push(job.jobType);
        }
      });
      setRequestRTRTitles(requestTitle);
      setRequestRTRTypes(requestType);
    } catch (err) {
      toast.error("Failed to get Details");
    }
    setLoading(false);
  };

  const getRequestList = async (automatch) => {
    setLoading(true);
    const params = {
      skip: 0,
      domain: domain,
    };
    try {
      const res = await post(`/requestlist/getallrequestslist`, params);
      const result = res.data.jobData
        .filter(
          (item) => ["open", "active"].includes(item.jobStatus) && item.isPublic
        )
        .sort((a, b) => {
          let fa = new Date(a.jobPostDate),
            fb = new Date(b.jobPostDate);
          if (fa > fb) {
            return -1;
          }
          if (fa < fb) {
            return 1;
          }
          return 0;
        });
      let finalResult = [...result];
      if (automatch.length > 0) {
        let ids = automatch.map((i) => i.jobId);
        finalResult = [...finalResult.filter((i) => !ids.includes(i.jobId))];
      }
      setJobListData(finalResult);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    fetchAutoMatchedJobs();
    fetchRTR();
  }, []);

  const updateTab = (type) => {
    setSearch("");
    if (type === "matched") {
      setMatchedTab(true);
      setFavoritedTab(false);
      setAppliedTab(false);
      setRTRTab(false);
    }

    if (type === "favorited") {
      setFavoritedTab(true);
      setMatchedTab(false);
      setAppliedTab(false);
      setRTRTab(false);
    }

    if (type === "applied") {
      setAppliedTab(true);
      setMatchedTab(false);
      setFavoritedTab(false);
      setRTRTab(false);
    }

    if (type === "rtr") {
      setRTRTab(true);
      setMatchedTab(false);
      setFavoritedTab(false);
      setAppliedTab(false);
    }
  };

  const openModal = (item, type) => {
    setOpenSidePanel(true);
    setJobDetails(item);
    setType(type);
  };
  const applySort = (sortedData, close) => {
    close && setSortModal(false);
    setFiltered(sortedData);
    setSelectedTitles([]);
    setSelectedTypes([]);
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(jobListData, true);
  };

  const applyFilter = (selectedTitle, selectedType) => {
    setSortArray([]);
    sortClearAll();
    setSortCount(0);
    setSelectedTitles(selectedTitle);
    setSelectedTypes(selectedType);
    if (selectedTitle.length > 0 && selectedType.length > 0) {
      setFiltered(
        matched.filter(
          (job) =>
            selectedTitle.includes(job.jobTitle) &&
            selectedType.includes(job.jobType)
        )
      );
      setFilteredRTR(
        RTR.filter(
          (job) =>
            selectedTitle.includes(job.jobTitle) &&
            selectedType.includes(job.jobType)
        )
      );
    } else if (selectedTitle.length > 0 && selectedType.length === 0) {
      setFiltered(
        matched.filter((job) => selectedTitle.includes(job.jobTitle))
      );
      setFilteredRTR(RTR.filter((job) => selectedTitle.includes(job.jobTitle)));
    } else if (selectedType.length > 0 && selectedTitle.length === 0) {
      setFiltered(matched.filter((job) => selectedType.includes(job.jobType)));
      setFilteredRTR(RTR.filter((job) => selectedType.includes(job.jobType)));
    } else {
      setFiltered(matched);
      setFilteredRTR(RTR);
    }
    setFilter(false);
  };

  const handleSearch = (e) => {
    let value = e.target.value;
    setSearch(value);
    if (value) {
      setMatchedTab(false);
      setAppliedTab(false);
      setRTRTab(false);
      setFavoritedTab(false);
    } else {
      setMatchedTab(true);
    }
  };

  return (
    <>
      {
        <div class="col-xxl-5 mb-3">
          <div
            statusstart="matched"
            viewstart="listview"
            id="CandidateJobs"
            class="card"
          >
            <div class="card-body">
              {/* <div class="row justify-content-between align-items-center">
                                <div className="row">

                                    <div className="col-lg-6">
                                        <label class="pt-0 mb-2"><strong>My Jobs</strong></label>
                                        <a href="/high5Hire/candidatejobs">&nbsp;
                                            <i class="fas fa-external-link-square"></i>
                                        </a>
                                    </div>


                                </div>
                                <div class="d-flex mb-2 align-items-center justify-content-center justify-content-even-400">
                          <div class="d-flex align-items-center justify-content-end w-100 justify-content-even-400">
                          <div class="dropdown-sort dd-filter mx-1">
                                           
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-text-accent"
                                            onClick={() => setSortModal(true)}
                                        >
                                            <i className="fad fa-sort-down font-16 mr-1"></i>{" "}
                                            Sort / {sortCount}
                                        </button>
                                        { sortModal &&
                                            <OutsideClickHandler
                                                onOutsideClick={() => setSortModal(false)}
                                            >
                                                <Sort
                                                    sortData={jobListData}
                                                    orderArray={orderList}
                                                    sortObjects={sortObject}
                                                    applySort={applySort}
                                                    setSortCount={setSortCount}
                                                    sortCount={sortCount}
                                                    setSortArray={setSortArray}
                                                    sortArray={sortArray}
                                                    sortingFor={
                                                    "Sort by Job Title, Type and Location of Requests"
                                                    }
                                                />  
                                            </OutsideClickHandler>                                               
                                        }                      
                                    </div>
                            <div class="dropdown position-relative">
                              
                              <button
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Filter"
                                onClick={() => {         
                                  setFilter(true)
                                  setMatchedTab(true)
                                }}
                                type="button"
                                class="btn btn-sm btn-text-accent w-auto"
                              >
                                <i class="fas fa-filter"></i>
                                <span>Filter</span> /<span>{ selectedTitles.length + selectedTypes.length }</span>
                              </button>
                            </div>
                          </div>
                        </div>
                                
                            </div> */}
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <label class="pt-0 mb-2">
                    <strong>My Jobs</strong>
                  </label>
                  <a href="/high5Hire/candidatejobs">
                    &nbsp;
                    <i class="fas fa-external-link-square"></i>
                  </a>
                </div>
                {/* <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                                <div className="mb-2 d-flex align-items-center justify-content-center">
                                    <div className="d-flex justify-content-end w-100">
                                        <div className="dropdown-sort dd-filter mx-1">
                                            <button type="button" className="btn btn-sm w-auto btn-text-accent dd-filter">
                                                <i aria-hidden="true" className="fas fa-filter font-14 dd-filter"></i> 
                                                <span className="dd-filter d-none-1024">Filter</span> /
                                                <span className="dd-filter"> 0</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                <div class="d-flex mb-2 align-items-center justify-content-center justify-content-even-400">
                  <div class="d-flex align-items-center justify-content-end w-100 justify-content-even-400">
                    <div class="dropdown-sort dd-filter mx-1">
                      <button
                        type="button"
                        className="btn btn-sm btn-text-accent"
                        onClick={() => setSortModal(true)}
                      >
                        <i className="fad fa-sort-down font-16 mr-1"></i> Sort /{" "}
                        {sortCount}
                      </button>
                      {sortModal && (
                        <OutsideClickHandler
                          onOutsideClick={() => setSortModal(false)}
                        >
                          <Sort
                            sortData={filtered}
                            orderArray={orderList}
                            sortObjects={sortObject}
                            applySort={applySort}
                            setSortCount={setSortCount}
                            sortCount={sortCount}
                            setSortArray={setSortArray}
                            sortArray={sortArray}
                            sortingFor={
                              "Sort by Job Title, Type, and Location of Requests"
                            }
                          />
                        </OutsideClickHandler>
                      )}
                    </div>
                    <div class="dropdown position-relative">
                      <button
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="Filter"
                        onClick={() => {
                          setFilter(true);
                          setMatchedTab(true);
                        }}
                        type="button"
                        class="btn btn-sm btn-text-accent w-auto"
                      >
                        <i class="fas fa-filter"></i>
                        <span>Filter</span> /
                        <span>
                          {selectedTitles.length + selectedTypes.length}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <hr class="m-0" />
              <div>
                <div class="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end mb-3 border-bottom-gray2">
                  <ul class="nav nav-tabs nav-tabs-tablet pt-1 w-100 justify-content-center">
                    <li class="nav-item">
                      <button
                        type="button"
                        onClick={() => {
                          updateTab("matched");
                        }}
                        class={
                          matchedTab
                            ? "active nav-link pb-3 mx-2 bg-transparent"
                            : "nav-link pb-3 mx-2 bg-transparent"
                        }
                      >
                        <span class="tag mb-1">
                          {
                            filtered.filter(
                              (job) =>
                                job.status !== "Applied" && !job.favorited
                            ).length
                          }
                          {filtered.filter(
                            (job) => job.status !== "Applied" && !job.favorited
                          ).length > 0 && <span class="jewel"></span>}
                        </span>
                        <div>Matched</div>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button
                        type="button"
                        onClick={() => updateTab("favorited")}
                        class={
                          favoritedTab
                            ? "nav-link pb-3 mx-2 bg-transparent active"
                            : "nav-link pb-3 mx-2 bg-transparent"
                        }
                      >
                        <span class="tag mb-1">
                          {filtered.filter((job) => job.favorited).length}
                          {filtered.filter((job) => job.favorited).length >
                            0 && <span class="jewel"></span>}
                        </span>
                        <div>Favorited</div>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button
                        type="button"
                        onClick={() => updateTab("applied")}
                        class={
                          appliedTab
                            ? "nav-link pb-3 mx-2 bg-transparent active"
                            : "nav-link pb-3 mx-2 bg-transparent"
                        }
                      >
                        <span class="tag mb-1">
                          {
                            filtered.filter((job) => job.status === "Applied")
                              .length
                          }
                          {filtered.filter((job) => job.status === "Applied")
                            .length > 0 && <span class="jewel"></span>}
                        </span>
                        <div>Applied</div>
                      </button>
                    </li>
                    <li class="nav-item">
                      <button
                        type="button"
                        onClick={() => updateTab("rtr")}
                        class={
                          rtrTab
                            ? "nav-link pb-3 mx-2 bg-transparent active"
                            : "nav-link pb-3 mx-2 bg-transparent"
                        }
                      >
                        <span class="tag mb-1">
                          {filteredRTR.length}
                          {filteredRTR.length > 0 && (
                            <span class="jewel"></span>
                          )}
                        </span>
                        <div>Approval History</div>
                      </button>
                    </li>
                  </ul>
                </div>
                {loading ? (
                  <h6 className="text-center my-5">Fetching Jobs...</h6>
                ) : (
                  <div class="col">
                    {matchedTab &&
                      (filtered.filter(
                        (job) => job.status !== "Applied" && !job.favorited
                      ).length === 0 ? (
                        selectedTitles.length > 0 ||
                        selectedTypes.length > 0 ? (
                          <div>
                            <div className="text-center p-3">
                              <div className="avatar avatar-lg">
                                <i className="fad fa-comment-slash"></i>
                              </div>
                              <div className="mt-2">
                                No Automatches Found Matching Filter Criteria
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="text-center p-3">
                              <div className="avatar avatar-lg">
                                <i className="fad fa-comment-slash"></i>
                              </div>
                              <div className="mt-2">No Automatches Found</div>
                            </div>
                          </div>
                        )
                      ) : (
                        filtered
                          .filter(
                            (job) => job.status !== "Applied" && !job.favorited
                          )
                          .map((item) => (
                            <a
                              onClick={() => {
                                openModal(item, "matched");
                              }}
                              class="card card-flat p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
                            >
                              <div class="card-body">
                                <span class="tag tag-coral d-inline-flex mr-2">
                                  <img width="16px" src={High5DarkIcon} />
                                  <span class="mx-2">Matched</span>
                                </span>
                                <h6 class="mt-1">{item.jobTitle}</h6>
                                <div class="d-flex flex-wrap align-items-center">
                                  <span class="d-flex align-items-center">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-suitcase me-2 font-brandBlue"
                                    ></i>
                                    <span class="font-regular font-primary font-gray1">
                                      {item.jobType}
                                    </span>
                                  </span>
                                  {item.fullText.location?.city &&
                                  item.fullText.location?.state &&
                                  item.fullText.location?.country ? (
                                    <span class="d-flex align-items-center ml-3">
                                      <i
                                        aria-hidden="true"
                                        class="far fa-map-marker-alt me-2 font-brandBlue ml-2"
                                      ></i>
                                      <span class="font-regular font-primary font-gray1">
                                        {item.fullText.location?.city},{" "}
                                        {item.fullText.location.state},{" "}
                                        {item.fullText.location.country}
                                      </span>
                                    </span>
                                  ) : (
                                    <>
                                      <i
                                        aria-hidden="true"
                                        class="far fa-map-marker-alt me-2 font-brandBlue ml-2"
                                      ></i>
                                      <span class="font-regular font-primary font-gray1 mr-2">
                                        {"Remote"}
                                      </span>
                                    </>
                                  )}
                                  <span class="d-flex align-items-center ml-3">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-piggy-bank me-2 font-brandBlue"
                                    ></i>
                                    <span class="font-regular font-primary font-gray1">
                                      {item.minimumPay +
                                        "-" +
                                        item.maximumPay +
                                        " " +
                                        item.salaryCurrency}
                                    </span>
                                  </span>
                                </div>{" "}
                                <div>
                                  <span class="d-flex align-items-center mt-2">
                                    <span class="font-regular">
                                      {moment(
                                        new Date(item.updatedDate),
                                        "YYYYMMDD"
                                      ).fromNow()}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </a>
                          ))
                      ))}

                    {favoritedTab &&
                      (filtered.filter((job) => job.favorited).length === 0 ? (
                        selectedTitles.length > 0 ||
                        selectedTypes.length > 0 ? (
                          <div>
                            <div className="text-center p-3">
                              <div className="avatar avatar-lg">
                                <i className="fad fa-comment-slash"></i>
                              </div>
                              <div className="mt-2">
                                No Favorites Found Matching Filter Criteria
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="text-center p-3">
                              <div className="avatar avatar-lg">
                                <i className="fad fa-comment-slash"></i>
                              </div>
                              <div className="mt-2">No Favorites Found</div>
                            </div>
                          </div>
                        )
                      ) : (
                        filtered
                          .filter((job) => job.favorited)
                          .map((item) => (
                            <a
                              onClick={() => {
                                openModal(item, "favorited");
                              }}
                              class="card card-flat p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
                            >
                              <div class="card-body">
                                <span class="tag tag-purple1 d-inline-flex mr-2">
                                  <i
                                    aria-hidden="true"
                                    class="fad fa-heart-circle"
                                  ></i>
                                  <span class="mx-2">Favorited</span>
                                </span>
                                <h6 class="mt-1">{item.jobTitle}</h6>
                                <div class="d-flex flex-wrap align-items-center">
                                  <span class="d-flex align-items-center">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-suitcase me-2 font-brandBlue"
                                    ></i>
                                    <span class="font-regular font-primary font-gray1">
                                      {item.jobType}
                                    </span>
                                  </span>
                                  {item.fullText.location?.city &&
                                  item.fullText.location?.state &&
                                  item.fullText.location?.country ? (
                                    <span class="d-flex align-items-center ml-3">
                                      <i
                                        aria-hidden="true"
                                        class="far fa-map-marker-alt me-2 font-brandBlue ml-2"
                                      ></i>
                                      <span class="font-regular font-primary font-gray1 me-2">
                                        {item.fullText.location?.city},{" "}
                                        {item.fullText.location.state},{" "}
                                        {item.fullText.location.country}
                                      </span>
                                    </span>
                                  ) : (
                                    <>
                                      <i
                                        aria-hidden="true"
                                        class="far fa-map-marker-alt me-2 font-brandBlue ml-2"
                                      ></i>
                                      <span class="font-regular font-primary font-gray1 mr-2">
                                        {"Remote"}
                                      </span>
                                    </>
                                  )}
                                  <span class="d-flex align-items-center ml-3">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-piggy-bank me-2 font-brandBlue"
                                    ></i>
                                    <span class="font-regular font-primary font-gray1">
                                      {item.minimumPay +
                                        "-" +
                                        item.maximumPay +
                                        " " +
                                        item.salaryCurrency}
                                    </span>
                                  </span>
                                </div>{" "}
                                <div>
                                  <span class="d-flex align-items-center mt-2">
                                    <span class="font-regular">
                                      {moment(
                                        new Date(item.updatedDate),
                                        "YYYYMMDD"
                                      ).fromNow()}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </a>
                          ))
                      ))}

                    {rtrTab &&
                      (filteredRTR.length === 0 ? (
                        selectedTitles.length > 0 ||
                        selectedTypes.length > 0 ? (
                          <div>
                            <div className="text-center p-3">
                              <div className="avatar avatar-lg">
                                <i className="fad fa-comment-slash"></i>
                              </div>
                              <div className="mt-2">
                                No Approval History Found Matching Filter
                                Criteria
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="text-center p-3">
                              <div className="avatar avatar-lg">
                                <i className="fad fa-comment-slash"></i>
                              </div>
                              <div className="mt-2">
                                No Approval History Found
                              </div>
                            </div>
                          </div>
                        )
                      ) : (
                        filteredRTR.map((item) => (
                          <div className="card card-flat w-100 mb-1">
                            <div className="card-body">
                              <a
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  openModal(item, "rtr");
                                }}
                              >
                                <div>
                                  <div>
                                    <div>
                                      <span className="tag tag-coral mr-1">
                                        Approval
                                      </span>
                                    </div>
                                    <h6 className="mt-2">{item.jobTitle}</h6>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <span className="d-flex align-items-center me-3">
                                      <i
                                        aria-hidden="true"
                                        className="far fa-suitcase me-2 font-brandBlue"
                                      ></i>
                                      <span className="font-regular font-primary font-gray1">
                                        {item.jobType}
                                      </span>
                                    </span>
                                    {item.fullText.location?.city &&
                                    item.fullText.location?.state &&
                                    item.fullText.location?.country ? (
                                      <span class="d-flex align-items-center ml-3">
                                        <i
                                          aria-hidden="true"
                                          class="far fa-map-marker-alt me-2 font-brandBlue ml-2"
                                        ></i>
                                        <span class="font-regular font-primary font-gray1">
                                          {item.fullText.location?.city},{" "}
                                          {item.fullText.location.state},{" "}
                                          {item.fullText.location.country}
                                        </span>
                                      </span>
                                    ) : (
                                      <>
                                        <i
                                          aria-hidden="true"
                                          class="far fa-map-marker-alt me-2 font-brandBlue ml-2"
                                        ></i>
                                        <span class="font-regular font-primary font-gray1 mr-2">
                                          {"Remote"}
                                        </span>
                                      </>
                                    )}
                                    {item.payRate && (
                                      <span className="d-flex align-items-center me-3">
                                        <i
                                          aria-hidden="true"
                                          className="far fa-piggy-bank me-2 font-brandBlue"
                                        ></i>
                                        <span className="font-regular font-primary font-gray1">
                                          {item.payRate +
                                            " " +
                                            item.payRateCurrency}
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                  <div className="mt-1">
                                    <small className="font-regular">
                                      {moment(
                                        new Date(item.sourcedDate),
                                        "YYYYMMDD"
                                      ).fromNow()}{" "}
                                      By {item.sourcedBy} @
                                      {item.recruiterTenant.replace(
                                        "GIG - ",
                                        ""
                                      )}
                                    </small>
                                  </div>
                                </div>
                              </a>
                              <hr className="mb-2" />
                              {
                                <h6>
                                  Approval{" "}
                                  {item.statusName === "Submitted"
                                    ? "Accepted"
                                    : "Rejected"}{" "}
                                  -{" "}
                                  {moment(
                                    new Date(item.updatedDate),
                                    "YYYYMMDD"
                                  ).fromNow()}
                                </h6>
                              }
                            </div>
                          </div>
                        ))
                      ))}

                    {appliedTab &&
                      (filtered.filter((job) => job.status === "Applied")
                        .length === 0 ? (
                        selectedTitles.length > 0 ||
                        selectedTypes.length > 0 ? (
                          <div>
                            <div className="text-center p-3">
                              <div className="avatar avatar-lg">
                                <i className="fad fa-comment-slash"></i>
                              </div>
                              <div className="mt-2">
                                No Applied Found Matching Filter Criteria
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div>
                            <div className="text-center p-3">
                              <div className="avatar avatar-lg">
                                <i className="fad fa-comment-slash"></i>
                              </div>
                              <div className="mt-2">
                                You Have Not Applied For Any Jobs Yet
                              </div>
                            </div>
                          </div>
                        )
                      ) : (
                        filtered
                          .filter((job) => job.status === "Applied")
                          .map((item) => (
                            <a
                              onClick={() => {
                                openModal(item, "applied");
                              }}
                              class="card card-flat p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
                            >
                              <div class="card-body">
                                <span class="tag tag-green1">Applied</span>{" "}
                                {"  "}
                                <h6 class="mt-1">{item.jobTitle}</h6>
                                <div class="d-flex flex-wrap align-items-center">
                                  <span class="d-flex align-items-center">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-suitcase me-2 font-brandBlue"
                                    ></i>
                                    <span class="font-regular font-primary font-gray1">
                                      {item.jobType}
                                    </span>
                                  </span>
                                  {item.fullText.location?.city &&
                                  item.fullText.location?.state &&
                                  item.fullText.location?.country ? (
                                    <span class="d-flex align-items-center ml-3">
                                      <i
                                        aria-hidden="true"
                                        class="far fa-map-marker-alt me-2 font-brandBlue ml-2"
                                      ></i>
                                      <span class="font-regular font-primary font-gray1">
                                        {item.fullText.location?.city},{" "}
                                        {item.fullText.location.state},{" "}
                                        {item.fullText.location.country}
                                      </span>
                                    </span>
                                  ) : (
                                    <>
                                      <i
                                        aria-hidden="true"
                                        class="far fa-map-marker-alt me-2 font-brandBlue ml-2"
                                      ></i>
                                      <span class="font-regular font-primary font-gray1 mr-2">
                                        {"Remote"}
                                      </span>
                                    </>
                                  )}
                                  <span class="d-flex align-items-center ml-3">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-piggy-bank me-2 font-brandBlue"
                                    ></i>
                                    <span class="font-regular font-primary font-gray1">
                                      {item.minimumPay +
                                        "-" +
                                        item.maximumPay +
                                        " " +
                                        item.salaryCurrency}
                                    </span>
                                  </span>
                                </div>{" "}
                                <div>
                                  <span class="d-flex align-items-center mt-2">
                                    <span class="font-regular">
                                      {moment(
                                        new Date(item.updatedDate),
                                        "YYYYMMDD"
                                      ).fromNow()}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            </a>
                          ))
                      ))}

                    {search &&
                      jobListData.length > 0 &&
                      jobListData
                        .filter((item) =>
                          item.jobTitle
                            .toLowerCase()
                            .includes(search.toLowerCase())
                        )
                        .map((item) => (
                          <a
                            onClick={() => {
                              openModal(item, "search");
                            }}
                            class="card card-flat p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
                          >
                            <div class="card-body">
                              <h6 class="mt-1">{item.jobTitle}</h6>
                              <div class="d-flex flex-wrap align-items-center">
                                <span class="d-flex align-items-center">
                                  <i
                                    aria-hidden="true"
                                    class="far fa-suitcase ml-2 me-2 font-brandBlue"
                                  ></i>
                                  <span class="font-regular font-primary font-gray1 me-2">
                                    {item.jobType}
                                  </span>
                                </span>
                                {item.fullText.location?.city &&
                                item.fullText.location?.state &&
                                item.fullText.location?.country ? (
                                  <span class="d-flex align-items-center ml-3">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-map-marker-alt me-2 font-brandBlue ml-2"
                                    ></i>
                                    <span class="font-regular font-primary font-gray1">
                                      {item.fullText.location?.city},{" "}
                                      {item.fullText.location.state},{" "}
                                      {item.fullText.location.country}
                                    </span>
                                  </span>
                                ) : (
                                  <>
                                    <i
                                      aria-hidden="true"
                                      class="far fa-map-marker-alt me-2 font-brandBlue ml-2"
                                    ></i>
                                    <span class="font-regular font-primary font-gray1 mr-2">
                                      {"Remote"}
                                    </span>
                                  </>
                                )}
                                <span class="d-flex align-items-center ml-3">
                                  <i
                                    aria-hidden="true"
                                    class="far fa-piggy-bank me-2 font-brandBlue"
                                  ></i>
                                  <span class="font-regular font-primary font-gray1">
                                    {item.minimumPay +
                                      "-" +
                                      item.maximumPay +
                                      " " +
                                      item.salaryCurrency}
                                  </span>
                                </span>
                              </div>{" "}
                              <div>
                                <span class="d-flex align-items-center mt-2">
                                  <span class="font-regular">
                                    {moment(
                                      new Date(item.jobPostDate),
                                      "YYYYMMDD"
                                    ).fromNow()}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </a>
                        ))}
                  </div>
                )}
              </div>
            </div>
          </div>
          {openSidePanel && (
            <CandidateRequestDetails
              type={type}
              job={jobDetails}
              closeModal={() => setOpenSidePanel(false)}
              details={details}
            />
          )}
          {filter && (
            <FilterJobs
              requestTitles={[
                ...new Set([...requestTitles, ...requestRTRTitles]),
              ]}
              requestTypes={[...new Set([...requestTypes, ...requestRTRTypes])]}
              selectedTitles={selectedTitles}
              selectedTypes={selectedTypes}
              applyFilter={applyFilter}
            />
          )}
        </div>
      }
    </>
  );
}

export default CandidateJobs;
