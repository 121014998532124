import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getTenantType, getUserRole } from "../../../../service/AuthService";
import LocationFilter from "../locationFilter/LocationFilter";
import DDMS from "../dropDownMSFilter/DDMSFilter";
import { post } from "../../../../service/ApiService";
import {
  visaStatus,
  countryList,
  jobTypeOptions,
  talentAcquisitionJobType,
} from "../../../../constants/Constants";

import { getTalentTagList } from "../../../../actions/talent/TalentTagFilter";

import Loader from "../../../Loader/Loader";
import { getTalentDesignationList } from "../../../../actions/talent/TalentDesignationFilter";
import { getTalentStateList } from "../../../../actions/talent/talentStateFilter";
import { getTalentCountryList } from "../../../../actions/talent/talentCountryFilter";
import { getTalentSourceList } from "../../../../actions/talent/TalentSourceFilter";

import { getTalentCertificationList } from "../../../../actions/talent/Certification";
import { getTalentNursingSpecialtyList } from "../../../../actions/talent/NursingSpecialty";
import { getTalentLicenseStateList } from "../../../../actions/talent/LicenseState";
import {
  fetchJobIdFilterData,
  fetchJobLocationFilterData,
  fetchJobTitleFilters,
} from "../../../../actions/jobList/jobList";

const JobFilter = (props) => {
  const dispatch = useDispatch();
  const { selectedData, setSelectedData, applyFilter } = props;

  const [collapsible, setCollapsible] = useState({
    jobTitle: false,
    jobId: false,
    jobLocation: false,
    workAuthorization: false,
    hotJob: false,
    workType: false,
  });

  const additionalVisaStatus = [
    { value: "Not Required", label: "Not Applicable" },
    { value: "Need Sponsorship", label: "Need Sponsorship" },
  ];

  const combinedVisaStatus = [...visaStatus, ...additionalVisaStatus];
  const uniqueVisaStatus = Array.from(
    new Set(combinedVisaStatus.map(JSON.stringify))
  ).map(JSON.parse);

  const visaStatusOptions = uniqueVisaStatus;

  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [jobLocationOptions, setJobLocationOptions] = useState([]);
  const [jobIdOptions, setJobIdOptions] = useState([]);

  const {
    jobTitleFilters,
    jobLocationFilters,
    jobIdFilters,
    loading,
  } = useSelector(
    (state) => ({
      jobTitleFilters: state.getJobListReducer.jobTitleFilters,
      jobLocationFilters: state.getJobListReducer.jobLocationFilters,
      jobIdFilters: state.getJobListReducer.jobIdFilters,
      loading: state.getJobListReducer.loading,
    }),
    shallowEqual
  );

  const handleFilterToggle = (filter) => {
    setCollapsible({
      jobTitle: false,
      jobId: false,
      jobLocation: false,
      workAuthorization: false,
      hotJob: false,
      workType: false,
      [filter]: !collapsible[filter],
    });
  };

  const handleAPICalls = (filter) => {
    const payload = {
      role: getUserRole(),
      pageSize: 10,
      pageNumber: 1,
      searchText: "",
    };
    if (filter == "jobTitle") {
      dispatch(fetchJobTitleFilters(payload));
    }
    if (filter == "jobLocation") {
      dispatch(fetchJobLocationFilterData(payload));
    }
    if (filter == "jobId") {
      dispatch(fetchJobIdFilterData(payload));
    }
  };

  const fetchJobTitles = async (searchValue, filter) => {
    if (filter == "jobTitle" && collapsible[filter]) {
      const payload = {
        role: getUserRole(),
        pageSize: 10,
        pageNumber: 1,
        searchText: searchValue ?? "",
      };
      dispatch(fetchJobTitleFilters(payload));
    }
  };
  const fetchJobIds = async (searchValue, filter) => {
    if (filter == "jobId" && collapsible[filter]) {
      const payload = {
        role: getUserRole(),
        pageSize: 10,
        pageNumber: 1,
        searchText: searchValue ?? "",
      };
      dispatch(fetchJobIdFilterData(payload));
    }
  };
  const fetchJobLocations = async (searchValue, filter) => {
    if (filter == "jobLocation" && collapsible[filter]) {
      const payload = {
        role: getUserRole(),
        pageSize: 10,
        pageNumber: 1,
        searchText: searchValue ?? "",
      };
      dispatch(fetchJobLocationFilterData(payload));
    }
  };

  useEffect(() => {
    if (jobTitleFilters && jobTitleFilters?.data?.length > 0) {
      setJobTitleOptions(
        jobTitleFilters?.data?.map((item) => {
          return {
            value: item.jobTitle,
            label: item.jobTitle,
          };
        })
      );
    }
    if (jobIdFilters && jobIdFilters?.data?.length > 0) {
      setJobIdOptions(
        jobIdFilters?.data?.map((item) => {
          return {
            value: item.jobId,
            label: item.jobId,
          };
        })
      );
    }
    if (jobLocationFilters && jobLocationFilters?.data?.length > 0) {
      let locationOptions =
        jobLocationFilters?.data?.map((item) => ({
          value: item.address,
          label: item.address,
        })) || [];

      // Find the index of the "Remote" option
      const remoteIndex = locationOptions.findIndex(
        (option) => option.value === "Remote"
      );

      // If "Remote" option is found, move it to the front
      if (remoteIndex !== -1) {
        const remoteOption = locationOptions.splice(remoteIndex, 1)[0];
        locationOptions = [remoteOption, ...locationOptions];
      }

      setJobLocationOptions(locationOptions);
    }
  }, [jobTitleFilters, jobLocationFilters, jobIdFilters]);

  const handleClear = () => {
    setSelectedData({
      jobTitle: [],
      jobLocation: [],
      jobId: [],
      workAuthorization: [],
      hotJob: false,
      workType: [],
    });
    // setIsFilterApplied(false);
    applyFilter("", "");
  };

  const filterLength =
    selectedData?.jobTitle.length +
    selectedData?.jobLocation.length +
    selectedData?.jobId.length +
    selectedData?.workAuthorization.length +
    (selectedData?.hotJob ? 1 : 0) +
    selectedData?.workType.length;
  // +isFilterApplied
  //   ? 1
  //   : 0;

  return (
    <>
      {/* { <Loader />} */}
      <div className="card-flat">
        <div className="card-body">
          <div className="view-panel-header view-panel-header-sm">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="px-3"></div>
              <div className="px-3">
                <button
                  disabled={filterLength == 0}
                  type="button"
                  className="btn btn-text"
                  onClick={handleClear}
                >
                  <span className="font-bold">Clear All</span>
                </button>
              </div>
            </div>
          </div>
          <div className="view-panel-body view-panel-body-sm">
            {/* Job Title */}
            <DDMS
              // fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.jobTitle}
              filterStatus={"jobTitle"}
              filterName={"Job Title"}
              options={jobTitleOptions}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              isMulti={true}
              fetchOptions={fetchJobTitles}
              applyFilter={applyFilter}
              hideDropdown={true}
              isLoading={loading}
            />

            {/* Job Location */}
            <DDMS
              fetchOptions={fetchJobLocations}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.jobLocation}
              filterStatus={"jobLocation"}
              filterName={"Job Location"}
              options={jobLocationOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={loading}
            />

            {/* Job ID */}
            <DDMS
              fetchOptions={fetchJobIds}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.jobId}
              filterStatus={"jobId"}
              filterName={"Job ID"}
              options={jobIdOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={loading}
            />
            {/* Work Authorisation */}
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.workAuthorization}
              filterStatus={"workAuthorization"}
              filterName={"Work Authorization"}
              options={visaStatusOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
            />

            {/* Hot Job */}
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => handleFilterToggle("hotJob")}
              >
                <i
                  className={
                    collapsible.hotJob
                      ? "fal fa-angle-up fa-fw"
                      : "fal fa-angle-down fa-fw"
                  }
                />
                <span className="p-relative">
                  <small>Hot Job</small>

                  {selectedData.hotJob && <span className="jewel"></span>}
                </span>
              </button>
              {collapsible.hotJob && (
                <div className=" d-flex flex-column gap-2  p-2">
                  <div class="form-floating ">
                    <div className=" d-flex align-items-center  justify-content-between  px-2">
                      <label
                        className="form-check-label pt-0"
                        htmlFor="remoteSwitch"
                      >
                        Is Hot Job?
                      </label>

                      {/* <div className="d-flex  align-items-center align-self-end justify-content-end mb-1"> */}
                      <input
                        type="checkbox"
                        className="form-check-input"
                        style={{ width: "1.5rem", borderRadius: "100" }}
                        checked={selectedData.hotJob}
                        onClick={() => {
                          let status = !selectedData.hotJob;
                          setSelectedData((prev) => ({
                            ...prev,
                            hotJob: status,
                          }));
                          applyFilter(status, "hotJob");
                        }}
                      />
                      {/* </div> */}
                      {/* <input
                        className="form-check-input ml-3"
                        type="checkbox"
                        
                      /> */}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Work Type */}
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.workType}
              filterStatus={"workType"}
              filterName={"Work Type"}
              options={jobTypeOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default JobFilter;
