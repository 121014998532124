import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Shortlisted from "./Shortlisted";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";
import Pagination from "../../../../commoncomponents/pagination/Pagination";
import { getUserRole } from "../../../../../../service/AuthService";
import { useParams } from "react-router";
import { getDispositionDetails } from "../../../../../../actions/disposition/Disposition";
import PaginationComponent from "../../../../pagination/pagination";

const Index = (props) => {
  const { jobData, value, fetchLatestEmailStatus, view } = props;
  const [shortlistedData, setShortlistedData] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { Id } = useParams();
  const dispatch = useDispatch();
  const {
    dispositionDetails,
    dispositionCount,
    dispositionCountLoading,
  } = useSelector(
    (state) => ({
      dispositionDetails: state.dispositionDetails.dispositionDetails,
      dispositionCount: state.dispositionCount.dispositionCount,
      dispositionCountLoading: state.dispositionCount.dispositionCountLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let isCancelled = false;

    const timeoutId = setTimeout(() => {
      if (!isCancelled) {
        if (
          paginationArray.length > 0 &&
          !paginationArray[0].hasOwnProperty("latestEmailStatus")
        ) {
          fetchLatestEmailStatus(
            paginationArray,
            setPaginationArray,
            "Shortlisted"
          );
        }
      }
    }, 1000); // 1 seconds timeout

    return () => {
      isCancelled = true;
      clearTimeout(timeoutId);
    };
  }, [paginationArray]);

  useEffect(() => {
    if (dispositionDetails.length > 0) {
      if (value !== "" && value !== null) {
        let data = dispositionDetails.map((e) => ({
          ...e,
          name: e.firstName.toLowerCase() + " " + e.lastName.toLowerCase(),
        }));
        const regex = new RegExp(value, "i");
        let filter = data
          .filter(
            (item) => item.dispositionStatus == 8 && regex.test(item.name)
          )
          .sort((a, b) => b.shortlistedDate.localeCompare(a.shortlistedDate));
        setShortlistedData(filter);
      } else {
        setShortlistedData(
          dispositionDetails
            .filter((item) => item.dispositionStatus == 8)
            .sort((a, b) => b.shortlistedDate.localeCompare(a.shortlistedDate))
        );
      }
    } else {
      setShortlistedData(
        dispositionDetails
          .filter((item) => item.dispositionStatus == 8)
          .sort((a, b) => b.shortlistedDate.localeCompare(a.shortlistedDate))
      );
    }
  }, [dispositionDetails, value]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: view,
      jobId: Id,
    };
    dispatch(getDispositionDetails(payload));
  };
  return (
    <div>
      {shortlistedData.length > 0 ? (
        <>
          <table className="table table-hoverable-cells table-sortable table-cards-1024">
            <thead>
              <tr>
                <th className="d-flex align-items-start">Candidate</th>
                <th>Last updated </th>
                <th className="no-hover">Resumes </th>
                {/* <th className="no-hover">Interviews</th> */}{" "}
                {getUserRole() === "HiringManager" && (
                  <th className="no-hover">Email Status </th>
                )}
                <th className="no-hover">Score </th>
                <th className="no-hover"></th>
              </tr>
            </thead>
            <tbody>
              <Shortlisted
                jobData={jobData}
                shortlistedData={shortlistedData}
                calculateScore={props.calculateScore}
                view={view}
              />
            </tbody>
          </table>
          <PaginationComponent
            currentPage={currentPage}
            totalItems={
              dispositionCount?.filter((e) => e.Status == "Shortlist")[0]?.Count
            }
            itemsPerPage={15}
            onPageChange={handlePageChange}
            limit={15}
          />
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

export default Index;
