import {
    GET_CERTIFICATION_FILTER_BEGIN,
    GET_CERTIFICATION_FILTER_SUCCESS,
    GET_CERTIFICATION_FILTER_FAILURE,
  } from "../../actions/talent/Certification";
  
  const initialState = {
    loading: false,
    certificationData: null,
  };
  
  const getTalentCertificationReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_CERTIFICATION_FILTER_BEGIN:
        return {
          ...state,
          loading: true,
          certificationData: null,
        };
      case GET_CERTIFICATION_FILTER_SUCCESS:
        return {
          ...state,
          certificationData: payload,
          loading: false,
        };
      case GET_CERTIFICATION_FILTER_FAILURE:
        return {
          ...state,
          certificationData: payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default getTalentCertificationReducer;
  