import React, { useState, useEffect, useRef } from "react";
// import getSymbolFromCurrency from "currency-symbol-map";
// import cc from 'currency-codes'
import CountrySelector from "../modal/addjob/CountrySelector";
import { googleApiKey } from "../../constants/Constants";

//const googleApiKey = "AIzaSyDI6_bChtt67mHuGbjUH2be_da6ohX1RhA";
const googleMapApiJs = "https://maps.googleapis.com/maps/api/js";

function AddJobNewGoogleMaps(props) {
  const { setLocation, searchInput } = props;

  const [addressData, setAddressData] = useState(null);
  const [countryCode, setCountryCode] = useState("");

  const loadAsyncScript = (url, callback) => {
    let script = document.createElement("script");
    script.type = "text/javascript";

    if (script.readyState) {
      script.onreadystatechange = function() {
        if (
          script.readyState === "loaded" ||
          script.readyState === "complete"
        ) {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    document.getElementsByTagName("head")[0].appendChild(script);
  };

  const extractAddress = (place) => {
    const address = {
      address1: "",
      address2: "",
      address3: "",
      street: "",
      route: "",
      locality: "",
      city: "",
      state: "",
      zip: "",
      country: "",
    };

    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("sublocality_level_1")) {
        address.address1 = value;
      }
      if (types.includes("sublocality_level_2" || "sublocality")) {
        address.address2 = value;
      }
      if (
        types.includes("point_of_interest" || "establishment" || "neighborhood")
      ) {
        address.address3 = value;
      }
      if (types.includes("street_number")) {
        address.street = value;
      }
      if (types.includes("route")) {
        address.route = value;
      }
      if (types.includes("locality" || "political")) {
        address.locality = value;
      }
      if (types.includes("administrative_area_level_2" || "political")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }
      if (types.includes("postal_code")) {
        address.zip = value;
      }
      if (types.includes("country")) {
        address.country = value;
      }
    });
    return address;
  };

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    let addressData = extractAddress(place);
    setAddressData(addressData);
  };

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;
    var options = {
      types: ["address"],
      componentRestrictions: { country: countryCode?.toLowerCase() },
    };
    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current,
      options
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  useEffect(() => {
    if (addressData) {
      setLocation(addressData, searchInput);
    }
  }, [addressData]);

  useEffect(() => {
    loadAsyncScript(
      `${googleMapApiJs}?key=${googleApiKey}&libraries=places`,
      () => initAutocomplete()
    );
    if (searchInput.current) {
      searchInput.current.value = "";
    }
  }, [searchInput, countryCode]);

  return (
    <>
      {props?.isFrom !== "SubCommunity" ? (
        <div className="card card-flat card-borderless bg-gray4 mt-3">
          <div className="card-body">
            <div className="row mx-2">
              <div className="col-lg-12 font-14">
                <div className="form-floating">
                  <div className="searchLocation d-flex gap-1">
                    <CountrySelector setCountryCode={setCountryCode} />
                    <input
                      className="form-control"
                      ref={searchInput}
                      type="text"
                      placeholder="Search location"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <input
          type="text"
          className={props?.className ?? "form-control"}
          name="city"
          ref={searchInput}
          style={{
            border:
              props.isFormValid &&
              props.contactInfo.city == "" &&
              props.contactInfoValidation.city &&
              "2px solid #ff0000",
          }}
          placeholder="Enter a city name"
        />
      )}
    </>
  );
}

export default AddJobNewGoogleMaps;
