import React, { useState } from 'react'
import { ButtonLoader } from '../../../constants/Constants'
import { toast } from 'react-toastify';
import { post } from '../../../service/ApiService';
import { getCandidateId } from "../../../service/AuthService";
import moment from 'moment';

function Awards({ awardandhonors }) {

    const [ readOnlyMode, setReadOnlyMode ] = useState(true)
    const [ submittingForm, setSubmittingFrom ] = useState(false)
    const [ showSuccessMessage, setShowSuccessMessage ] = useState(false)
    const [ saveClicked, setSaveClicked ] = useState(true)
    const [ awards, setAwards ] = useState(awardandhonors)
    const [ tempAwards, setTempAwards ] = useState(awardandhonors)

    const addAwards = () => {
        let a = [...awards]
        a.push({
          awardname: "",
          issueddate: "",
          IssuedBy: "",
        })
        setAwards(a)
    }

    const deleteAwards = (index) => {
        let a = [...awards]
        a.splice(index, 1)
        setAwards(a)
    }

    const editAward = () => {
        setReadOnlyMode(false)
        setSaveClicked(true)
    }

    const cancelAward = () => {
        setAwards(tempAwards)
        setReadOnlyMode(true)
        setShowSuccessMessage(false)
        setSubmittingFrom(false)
        setSaveClicked(true)
    }

    const saveAwards = async () => {
        let condition = true
        awards.forEach(award => { 
            if(Object.values(award).findIndex(i => i === "") !== -1) {
                condition = false
                return false
            }
        })
        if(!condition) {
            toast.error("Fields marked with *(Asterisk) are mandatory")
        }
        else {    
            setSaveClicked(false)
            setSubmittingFrom(true)
            try {
            const parameters = {
                    candidateID: getCandidateId(),
                    clickName: 'AwardsandHonors',
                    userAwardsandHonorsInfo: awards
                }
                const res = await post('/candidateProfile/postAwardHonorsData', parameters)
                if (res.status === 200) {
                    setTempAwards(awards)
                    setSubmittingFrom(false)
                    setShowSuccessMessage(true)      
                    setTimeout(() => { 
                        setReadOnlyMode(true)
                        setShowSuccessMessage(false)
                    }, 1000)
                }
            } catch (error) {
                setSubmittingFrom(false)
                setSaveClicked(true)
                toast.error("Some Error Occured. Please Try Again")
            }
        }
    }

    const handleChange = (name, value, index) => {
        let a = [...awards]
        a[index] = { ...a[index], [name]: value }
        setAwards(a)
    }

    return (
        <>
           <div class="card card-lg mb-2">
                <div class="card-body">
                <div class="d-flex align-items-center justify-content-between">
                    <h6 class="mb-0">Awards and Honors</h6>
                    { readOnlyMode === true ? (
                    <button
                        type="button"
                        class="btn btn-text"
                        onClick={editAward}
                    >
                        <i class="fas fa-pencil-alt mr-1"></i>
                        Edit
                    </button>
                    ) : (
                    <div>
                        <button
                            type="button"
                            onClick={cancelAward}
                            class="btn btn-sm btn-secondary mr-1"
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            onClick={saveAwards}
                            class="btn btn-sm btn-primary"
                        >
                            {saveClicked && <span>Save Changes</span>}

                            {submittingForm && (
                                <span>
                                Saving Changes
                                    <img
                                        width="20px"
                                        src={ButtonLoader}
                                        alt=""
                                    />
                                </span>
                            )}
                            {showSuccessMessage && <span>Changes Saved!</span>}
                        </button>
                    </div>
                    )}
                </div>
                <hr />
                {readOnlyMode ? (
                    <div>
                        { awards.map(award => 
                            <div class="d-flex">
                                <div class="mr-3 d-flex flex-column align-items-center">
                                    <i class="fad fa-award fa-fw mt-1" aria-hidden="true"></i>
                                </div>
                                <div>
                                <p class="mb-1">
                                    <strong>{award.awardname}</strong>
                                </p>
                                <p class="mb-1">
                                    Issued By: <strong>{award.IssuedBy}</strong>
                                </p>
                                <p>
                                    <small>{moment(award.issueddate).format("MM/DD/YYYY")}</small>
                                </p>
                                </div>
                            </div>)
                        }
                    </div>
                ) : (
                    <div class="certfication">
                        { awards.map( (award, index) => <div class="d-flex">
                            <div class="mr-3">
                            <div class="avatar avatar-sm">{index+1}</div>
                            </div>
                            <div class="flex-grow-1">
                            <div class="row mb-2">
                                <div class="col-lg-6">
                                    <div class="form-floating">
                                        <input
                                            type="text"
                                            class="form-control"
                                            placeholder="Award Name"
                                            name="awardname"
                                            value={award.awardname}
                                            onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                                        />
                                        <label>Award Name*</label>
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <div class="form-floating form-date">
                                    <label>Issued Date*</label>
                                    <input
                                        type="date"
                                        class="form-control"
                                        name="issueddate"
                                        placeholder="Issue Date"
                                        value={award.issueddate}
                                        onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                                    />
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-floating">
                                        <input
                                            type="text"
                                            class="form-control"
                                            name='IssuedBy'
                                            placeholder="Issued By"
                                            value={award.IssuedBy}
                                            onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                                        />
                                        <label>Issued By*</label>
                                    </div>                              
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12">
                                <hr />
                                </div>
                            </div>
                            </div>
                            <div class="ml-3">
                            <button type="button" class="btn btn-icon" onClick={() => deleteAwards(index)}>
                                <i class="fal fa-fw fa-trash-alt"></i>
                            </button>
                            </div>
                        </div>)}
                        <div className="ml-5">
                            <button
                                type="button"                      
                                class="btn btn-sm btn-text"
                                onClick={addAwards}
                            >
                                <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                                <strong>Add Award</strong>
                            </button>
                        </div>
                    </div>
                )}
                </div>
            </div>    
        </>
    )   
}

export default Awards
