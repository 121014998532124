import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";

import App from "./App";
import "react-phone-input-2/lib/bootstrap.css";
import "./css/custom.css";
import "./css/App.css";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";
import Footer from "./components/layout/Footer";
import { getTenantType } from "./service/AuthService";

ReactDOM.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId="823728117580-ksfsb75eplt0hiqbqu2lpv5hmbode7dj.apps.googleusercontent.com">
      <Provider store={store}>
        <App />
      </Provider>
    </GoogleOAuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
