import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Invite from "./Invite";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";
import Pagination from "../../../../commoncomponents/pagination/Pagination";
import { getUserRole } from "../../../../../../service/AuthService";
import PaginationComponent from "../../../../pagination/pagination";
import { getPoolDispositionDetails } from "../../../../../../actions/directsourcing/Disposition";
import { useParams } from "react-router";
import Loader from "../../../../commoncomponents/Loader/Loader";
import { getPoolDispositionCount } from "../../../../../../actions/directsourcing/PoolDispositionCount";

const Index = (props) => {
  const {
    jobData,
    // dispositionDetails,
    fetchLatestEmailStatus,
    view,
    currentPage,
    setCurrentPage,
  } = props;
  const [invitedData, setInvitedData] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const dispatch = useDispatch();
  const { poolId } = useParams();
  /*** Sort Param  * */
  const [nameSortSelected, setNameSortSelected] = useState(null);
  const [dateSortSelected, setDateSortSelected] = useState(null);
  const [sortSelected, setSortSelected] = useState({
    firstName: false,
    date: false,
  });
  const [sortArray, setSortArray] = useState([]);
  /*** Sort Param  * */
  const {
    dispositionCount,
    dispositionCountLoading,
    dispositionDetails,
    dispositionLoading,
    dispositionDisqualifyLoading,
    dispositionDisqualify,
  } = useSelector(
    (state) => ({
      dispositionCount: state.poolDispositionCount.dispositionCount,
      dispositionCountLoading:
        state.poolDispositionCount.dispositionCountLoading,
      dispositionDetails: state.poolDisposition.dispositionDetails,
      dispositionLoading: state.poolDisposition.dispositionDetailsLoading,
      dispositionDisqualifyLoading:
        state.poolDispositionDisqualify.dispositionDisqualifyLoading,
      dispositionDisqualify:
        state.poolDispositionDisqualify.dispositionDisqualify,
    }),
    shallowEqual
  );

  useEffect(() => {
    let isCancelled = false;

    const timeoutId = setTimeout(() => {
      if (!isCancelled) {
        if (
          paginationArray.length > 0 &&
          !paginationArray[0].hasOwnProperty("latestEmailStatus")
        ) {
          fetchLatestEmailStatus(
            paginationArray,
            setPaginationArray,
            "Invited"
          );
        }
      }
    }, 1000); // 1 seconds timeout

    return () => {
      isCancelled = true;
      clearTimeout(timeoutId);
    };
  }, [paginationArray]);

  useEffect(() => {
    let data = dispositionDetails.filter(
      (item) => item.dispositionStatus == 33
    );
    setInvitedData(data);
  }, [dispositionDetails]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: view,
      poolId: poolId,
      filterObject: props.filterParams,
      searchText: props.value,
      sortParam: sortArray.length > 0 ? sortArray : [],
    };
    dispatch(getPoolDispositionDetails(payload));
  };
  /*** Sort Param  * */
  useEffect(() => {
    dispatch(
      getPoolDispositionDetails({
        currentPage,
        limit: 15,
        status: view,
        poolId,
        filterObject: props.filterParams,
        searchText: props.value,
        sortParam: sortArray.length > 0 ? sortArray : [],
      })
    );
  }, [sortArray, sortSelected]);

  const handleSort = (sortValue) => {
    let sortType = "";
    let temp = { ...sortSelected };
    if (sortValue === "firstName") {
      sortType = !nameSortSelected
        ? "ASC"
        : nameSortSelected === "ASC"
        ? "DESC"
        : "ASC";
      setNameSortSelected(sortType);
      setDateSortSelected(null);
      temp = {
        firstName: true,
        date: false,
      };
      setSortSelected(temp);
    } else {
      sortType = !dateSortSelected
        ? "ASC"
        : nameSortSelected === "ASC"
        ? "DESC"
        : "ASC";
      setDateSortSelected(sortType);
      setNameSortSelected(null);
      temp = {
        firstName: false,
        date: true,
      };
      setSortSelected(temp);
    }
    setSortArray(sortValue ? [{ sortValue, sortType }] : []);
  };

  /*** Sort Param  * */
  return (
    <div>
      {(dispositionLoading ||
        dispositionCountLoading ||
        dispositionDisqualifyLoading) && <Loader />}
      {invitedData.length > 0 ? (
        <>
          <table className="table table-hoverable table-sortable table-cards-1024">
            <thead>
              <tr>
                <th className="d-flex align-items-start">
                  Candidate
                  <div
                    // class="d-flex align-items-center ml-2"
                    onClick={() => {
                      handleSort("firstName");
                    }}
                  >
                    {!nameSortSelected ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "grey" }}
                        ></i>
                      </>
                    ) : nameSortSelected === "ASC" ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    ) : (
                      <>
                        <i
                          className="fad fa-sort-up font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    )}
                  </div>
                </th>
                {/* <th className="hover">Vetted </th> */}
                <th>
                  {/* Invited on */}
                  <div
                    class="d-flex align-items-center ml-2"
                    onClick={() => {
                      handleSort("invitedDate");
                    }}
                  >
                    Date
                    {!dateSortSelected ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "grey" }}
                        ></i>
                      </>
                    ) : dateSortSelected === "ASC" ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    ) : (
                      <>
                        <i
                          className="fad fa-sort-up font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    )}
                  </div>
                </th>
                {/* <th className="hover">Screening</th> */}
                {/* <th className="no-hover">Resumes </th> */}
                <th className="no-hover">Open Link</th>
                <th className="hover">Recruiter Name</th>
                <th className="hover">Source Channel</th>
                <th className="no-hover" />
              </tr>
            </thead>
            <tbody>
              <Invite
                jobData={jobData}
                invitedData={invitedData}
                filterParams={props?.filterParams}
                view={view}
              />
            </tbody>
          </table>
          {/* <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setPaginationArray={setPaginationArray}
            data={invitedData}
            dataSize={15}
            getTab=""
            pageSize={5}
          /> */}
          <PaginationComponent
            currentPage={currentPage}
            totalItems={
              dispositionCount?.filter((e) => e.Status == "Invite")[0]?.Count
            }
            itemsPerPage={15}
            onPageChange={handlePageChange}
            limit={15}
          />
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

export default Index;
