import React, { Fragment, useState, useEffect } from "react";
import { toast } from "react-toastify";
import AddJobFullTime from "./AddjobFullTime";
import AddJobContract from "./AddJobContract";
import AddJobPartTime from "./AddJobPartTime";
import AddJobTempToHire from "./AddJobTempToHire";
import AddJobVolunteer from "./AddJobVolunteer";
import Loader from "../../Loader/Loader";
import moment from "moment";

import { domain } from "../../../constants/Constants";
import { checkEmptyObject } from "../../../components/function/CommonFunction";
import {
  addJobFullTimeValidation,
  addJobContractValidation,
  addJobPartTimeValidation,
  addJobTempToHireValidation,
  addJobVolunteerValidation,
  genrateTierData,
} from "../../../components/validation/AddJob";
import { get, post } from "../../../service/ApiService";
import { getUserTenant } from "../../../service/AuthService";

function AddJob(props) {
  const today = new Date();
  const [loading, setLoading] = useState(false);
  const requestValue = props.requestValue;
  const [addJobDropdownResponse, setAddJobDropdownResponse] = useState(null);
  const [submitClicked, setSubmitClicked] = useState(false);
  const params = { domainName: domain };
  const tenantName = getUserTenant();

  const closeAddJobModalPopup = () => {
    props.handleSuccess(false);
  };

  useEffect(() => {
    if (requestValue === true) {
      const fetchData = async () => {
        try {
          setLoading(true);
          const res = await get(`/job/dropdownvalues`, params);
          setAddJobDropdownResponse(res.data[0]);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, []);

  const [addJobType, setAddJobType] = useState({
    typeSelected: false,
    fulltimeSelected: false,
    contractSelected: false,
    //sowSelected: false,
    partTimeSelected: false,
    tempToHireSelected: false,
    volunteerSelected: false,
    disableSubmit: true,
  });

  const [addJobData, setAddJobData] = useState({
    type: "",
    title: "",
    startDate: new Date(today.setDate(today.getDate() + 15)),
    companyName: getUserTenant(),
    companyJobId: "",
    isRemote: true,
    isHotJob: false,
    isFeePercentage: false,
    isPublic: true,
    isFexible: true,
    location: {
      address: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      currSymbol: "",
      currCode: "",
    },
    numberOfPositions: 1,
    allowedSubmittals: 2,
    salary: "",
    salaryCurrency: "USD",
    activeFrom: new Date(),
    expiresOn: new Date(today.setMonth(today.getMonth() + 6)),
    placementFee: "",
    placementCurrency: "USD",
    editPreferred: "",
    completionDate: "",
    durationMonths: "0",
    durationDays: "0",
    hourlyRate: "",
    hourlyRateCurrency: "USD",
    budget: "",
    referralFee: "",
    referralCurrency: "",
    Submittal: "",
    description: "",
    primarySkills: [],
    secondarySkills: [],
    skillSet: [],
    education: [],
    certifications: [],
    industries: [],
    tierData: [],
    workStart: "",
    workEnd: "",
    travel: "",
    drugTest: true,
    backgroundCheck: true,
    securityClearance: "",
    fileName: "",
    domainName: domain,
    tenantName: tenantName,
    visaRequirement: "Not Required",
    licenceRequirement: "",
    experienceLevel: "Junior :0-3 years",
    jobStatus: "open",
  });

  const [addJobFullTimeVal, setAddJobFullTimeVal] = useState({
    title: "",
    startDate: "",
    salary: "",
    salaryCurrency: "",
    activeFrom: "",
    expiresOn: "",
    primarySkills: "",
    tierError: "",
  });

  const [addJobContractVal, setAddJobContractVal] = useState({
    title: "",
    durationDays: "",
    durationMonths: "",
    hourlyRate: "",
    activeFrom: "",
    expiresOn: "",
    primarySkills: "",
    tierError: "",
  });

  const [addJobPartTimeVal, setAddJobPartTimeVal] = useState({
    title: "",
    durationDays: "",
    durationMonths: "",
    hourlyRate: "",
    activeFrom: "",
    expiresOn: "",
    primarySkills: "",
    tierError: "",
  });

  const [addJobTempToHireVal, setAddJobTempToHireVal] = useState({
    title: "",
    durationDays: "",
    durationMonths: "",
    hourlyRate: "",
    activeFrom: "",
    expiresOn: "",
    primarySkills: "",
    tierError: "",
  });

  const [addJobVolunteerVal, setAddJobVolunteerVal] = useState({
    title: "",
    durationDays: "",
    durationMonths: "",
    hourlyRate: "",
    activeFrom: "",
    expiresOn: "",
    primarySkills: "",
    tierError: "",
  });

  const onTypeClick = (value) => {
    if (value === "fulltime") {
      setAddJobType({
        ...addJobType,
        typeSelected: true,
        volunteerSelected: false,
        tempToHireSelected: false,
        partTimeSelected: false,
        //sowSelected: false,
        fulltimeSelected: true,
        contractSelected: false,
      });
      setAddJobData({ ...addJobData, type: "Full Time" });
    }
    if (value === "contract") {
      setAddJobType({
        ...addJobType,
        typeSelected: true,
        volunteerSelected: false,
        tempToHireSelected: false,
        partTimeSelected: false,
        //sowSelected: false,
        fulltimeSelected: false,
        contractSelected: true,
      });
      setAddJobData({ ...addJobData, type: "Contract" });
    }

    if (value === "parttime") {
      setAddJobType({
        ...addJobType,
        typeSelected: true,
        volunteerSelected: false,
        tempToHireSelected: false,
        partTimeSelected: true,
        //sowSelected: false,
        fulltimeSelected: false,
        contractSelected: false,
      });
      setAddJobData({ ...addJobData, type: "Part Time" });
    }

    if (value === "temptohire") {
      setAddJobType({
        ...addJobType,
        typeSelected: true,
        volunteerSelected: false,
        tempToHireSelected: true,
        partTimeSelected: false,
        // sowSelected: false,
        fulltimeSelected: false,
        contractSelected: false,
      });
      setAddJobData({ ...addJobData, type: "Temp To Hire" });
    }

    if (value === "volunteer") {
      setAddJobType({
        ...addJobType,
        typeSelected: true,
        volunteerSelected: true,
        tempToHireSelected: false,
        partTimeSelected: false,
        // sowSelected: false,
        fulltimeSelected: false,
        contractSelected: false,
      });
      setAddJobData({ ...addJobData, type: "Volunteer" });
    }
  };

  const classes = (dynamic, classes = "") => {
    return Object.entries(dynamic)
      .filter((entry) => entry[1])
      .map((entry) => entry[0])
      .join(" ")
      .concat(" ")
      .concat(classes);
  };

  const onSubmitaddJobType = async (e) => {
    e.preventDefault();

    const fullTimeValid = addJobFullTimeValidation(addJobData);
    const contractValid = addJobContractValidation(addJobData);
    const partTimeValid = addJobPartTimeValidation(addJobData);
    const tempHireValid = addJobTempToHireValidation(addJobData);
    const volunteerValid = addJobVolunteerValidation(addJobData);
    //  check if jobType is not public

    if (
      !checkEmptyObject(fullTimeValid) &&
      !checkEmptyObject(contractValid) &&
      !checkEmptyObject(partTimeValid) &&
      !checkEmptyObject(tempHireValid) &&
      !checkEmptyObject(volunteerValid)
    ) {
      setAddJobFullTimeVal(fullTimeValid);
      setAddJobContractVal(contractValid);
      setAddJobPartTimeVal(partTimeValid);
      setAddJobTempToHireVal(tempHireValid);
      setAddJobVolunteerVal(volunteerValid);
    } else {
      setSubmitClicked(true);

      // generate tier data
      //  if not public job then validate

      // let
      let data = {
        azureTiervalues: [],
        dbTiervalues: {},
      };
      if (addJobData.isPublic === false) {
        const threshold = addJobDropdownResponse.vendorthreshold;
        data = genrateTierData(addJobData.tierData, threshold);
        //  setAddJobData({ ...addJobData, azuretierData: data.azureTiervalues, dbTiervalues: data.dbTiervalues })
      }

      let finalAddJobData = {
        ...addJobData,
        salary: addJobData.salary + " " + addJobData.salaryCurrency,
        hourlyRate: addJobData.hourlyRate + " " + addJobData.hourlyRateCurrency,
        azuretierData: data.azureTiervalues,
        dbTiervalues: data.dbTiervalues,
        placementFee: !addJobData.isFeePercentage
          ? addJobData.placementFee
          : addJobData.placementFee + "%",
      };
      try {
        setLoading(true);
        const res = await post("/request/addRequest", finalAddJobData);
        // const res = { status: 200 }
        if (res.status === 200) {
          //On successful creation of job send data back to AutoMatch
          setLoading(false);
          props.getRequestList();
          toast.success("Job Created Successfully.");
          closeAddJobModalPopup();
          props.successFormOpen(true);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
          props.handleSuccess(false);
          props.setSuccessData({
            ...props.successData,
            jobId: res.data.indexResult.value[0].key,
            position: finalAddJobData.numberOfPositions,
            startDate: finalAddJobData.startDate,
            location: finalAddJobData.location.city,
            state: finalAddJobData.location.state,
            country: finalAddJobData.location.country,
            salary: finalAddJobData.salary,
            isRemote: finalAddJobData.isRemote,
            title: finalAddJobData.title,
            skills: finalAddJobData.primarySkills,
          });
        }
      } catch (error) {
        setLoading(false);
        //toast.error("Error in insertion!");
      }
    }
  };

  return (
    <Fragment>
      <div>
        <div className="modal-body">
          <div
            className="{ 'd-block': formComplete }"
            style={{ display: "none" }}
          >
            <div className="success-animation">
              <div className="checkmark">
                <lottie-player
                  id="LottieCheckmark"
                  background="transparent"
                  src="/lottie/checkmark.json"
                  speed={1}
                />
              </div>
              <div className="sparkles">
                <lottie-player
                  id="LottieSparkles"
                  background="transparent"
                  src="/lottie/sparkles.json"
                  speed={1}
                />
              </div>
            </div>
          </div>
          {loading && <Loader />}
          <div className="{ 'd-none': formComplete }">
            <div className="container-fluid">
              <div className="row">
                <div className="col-xl-12 mb-3">
                  <p className="lead">
                    What type of work are you looking to get done?
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-12 d-flex flex-column flex-lg-row">
                  <button
                    onClick={() => {
                      onTypeClick("fulltime");
                    }}
                    type="button"
                    className={classes(
                      { selected: addJobType.fulltimeSelected },
                      "card card-flat p-0 mr-2 mb-2 mb-lg-0"
                    )}
                  >
                    <div className="card-body text-left text-left">
                      <div className="d-flex">
                        {addJobType.fulltimeSelected === true ? (
                          <div className="mr-3 font-green font-16">
                            <i className="fad fa-check-circle"></i>
                          </div>
                        ) : (
                          <div className="mr-3 font-muted font-16">
                            <i className="fad fa-circle"></i>
                          </div>
                        )}

                        <div>
                          <div>
                            <strong className="font-blue_primary">
                              Full Time
                            </strong>
                          </div>
                          <p className="mb-0">Hire for a full time position</p>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      onTypeClick("contract");
                    }}
                    type="button"
                    className={classes(
                      { selected: addJobType.contractSelected },
                      "card card-flat p-0 mr-2 mb-2 mb-lg-0"
                    )}
                  >
                    <div className="card-body text-left text-left">
                      <div className="d-flex">
                        {addJobType.contractSelected === true ? (
                          <div className="mr-3 font-green font-16">
                            <i className="fad fa-check-circle" />
                          </div>
                        ) : (
                          <div className="mr-3 font-muted font-16">
                            <i className="fad fa-circle" />
                          </div>
                        )}
                        <div>
                          <div>
                            <strong className="font-blue_primary">
                              Contract
                            </strong>
                          </div>
                          <p className="mb-0">
                            Hire a contractor for a specified duration
                          </p>
                        </div>
                      </div>
                    </div>
                  </button>
                  {/* <button
                    onClick={() => {
                      onTypeClick("sow");
                    }}
                    type="button"
                    className={classes(
                      { selected: addJobType.sowSelected },
                      "card card-flat p-0 mr-2 mb-2 mb-lg-0"
                    )}
                  >
                    <div className="card-body text-left">
                      <div className="d-flex">
                        <div
                          v-if="sowSelected"
                          className="mr-3 font-green font-16"
                        >
                          <i className="fad fa-check-circle" />
                        </div>
                         <div  className="mr-3 font-muted font-16">
                                            <i className="fad fa-circle" />
                                        </div> 
                        <div>
                          <div>
                            <strong className="font-blue_primary">SOW</strong>
                          </div>
                          <p className="mb-0">
                            Complete a project with requirements
                          </p>
                        </div>
                      </div>
                    </div>
                  </button> */}
                </div>
                <div
                  id="addjobtype-2"
                  className="col-12 d-flex flex-column flex-lg-row"
                >
                  <button
                    onClick={() => {
                      onTypeClick("parttime");
                    }}
                    type="button"
                    className={classes(
                      { selected: addJobType.partTimeSelected },
                      "card card-flat p-0 mr-2 mb-2 mb-lg-0"
                    )}
                  >
                    <div className="card-body text-left">
                      <div className="d-flex">
                        {addJobType.partTimeSelected === true ? (
                          <div className="mr-3 font-green font-16">
                            <i className="fad fa-check-circle" />
                          </div>
                        ) : (
                          <div className="mr-3 font-muted font-16">
                            <i className="fad fa-circle" />
                          </div>
                        )}
                        <div>
                          <div>
                            <strong className="font-blue_primary">
                              Part Time
                            </strong>
                          </div>
                          <p className="mb-0">
                            Complete a project with requirements
                          </p>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      onTypeClick("temptohire");
                    }}
                    type="button"
                    className={classes(
                      { selected: addJobType.tempToHireSelected },
                      "card card-flat p-0 mr-2 mb-2 mb-lg-0"
                    )}
                  >
                    <div className="card-body text-left">
                      <div className="d-flex">
                        {addJobType.tempToHireSelected === true ? (
                          <div className="mr-3 font-green font-16">
                            <i className="fad fa-check-circle" />
                          </div>
                        ) : (
                          <div className="mr-3 font-muted font-16">
                            <i className="fad fa-circle" />
                          </div>
                        )}
                        <div>
                          <div>
                            <strong className="font-blue_primary">
                              Temp To Hire
                            </strong>
                          </div>
                          <p className="mb-0">
                            Complete a project with requirements
                          </p>
                        </div>
                      </div>
                    </div>
                  </button>
                  <button
                    onClick={() => {
                      onTypeClick("volunteer");
                    }}
                    type="button"
                    className={classes(
                      { selected: addJobType.volunteerSelected },
                      "card card-flat p-0 mr-2 mb-2 mb-lg-0"
                    )}
                  >
                    <div className="card-body text-left">
                      <div className="d-flex">
                        {addJobType.volunteerSelected === true ? (
                          <div className="mr-3 font-green font-16">
                            <i className="fad fa-check-circle" />
                          </div>
                        ) : (
                          <div className="mr-3 font-muted font-16">
                            <i className="fad fa-circle" />
                          </div>
                        )}
                        <div>
                          <div>
                            <strong className="font-blue_primary">
                              Volunteer
                            </strong>
                          </div>
                          <p className="mb-0">
                            Complete a project with requirements
                          </p>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </div>
              {addJobType.typeSelected && (
                <div v-if="typeSelected" className="section section-xs pb-0">
                  <div className="row">
                    <div className="col-xl-12">
                      <p className="mt-3 pb-0">
                        Please complete the form below to create your{" "}
                        <strong>{addJobData.type}</strong> request.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <form className="form" onSubmit={(e) => onSubmitaddJobType(e)}>
                {addJobType.fulltimeSelected && (
                  <>
                    <AddJobFullTime
                      addJobData={addJobData}
                      setAddJobData={setAddJobData}
                      addJobFullTimeVal={addJobFullTimeVal}
                      dropdownList={addJobDropdownResponse}
                    />
                  </>
                )}

                {(addJobType.contractSelected ||
                  addJobType.partTimeSelected ||
                  addJobType.tempToHireSelected ||
                  addJobType.volunteerSelected) && (
                  <>
                    <AddJobContract
                      addJobData={addJobData}
                      setAddJobData={setAddJobData}
                      dropdownList={addJobDropdownResponse}
                      addJobContractVal={addJobContractVal}
                      onChangeDescription={(d) =>
                        setAddJobData({ ...addJobData, description: d })
                      }
                    />
                  </>
                )}

                {/* {addJobType.sowSelected && (
                  <>
                    <AddJobSOW
                      addJobData={addJobData}
                      setAddJobData={setAddJobData}
                      dropdownList={addJobDropdownResponse}
                    />
                  </>
                )} */}

                {false && addJobType.partTimeSelected && (
                  <>
                    <AddJobPartTime
                      addJobData={addJobData}
                      setAddJobData={setAddJobData}
                      dropdownList={addJobDropdownResponse}
                      addJobPartTimeVal={addJobPartTimeVal}
                    />
                  </>
                )}

                {false && addJobType.tempToHireSelected && (
                  <>
                    <AddJobTempToHire
                      addJobData={addJobData}
                      setAddJobData={setAddJobData}
                      dropdownList={addJobDropdownResponse}
                      addJobTempToHireVal={addJobTempToHireVal}
                    />
                  </>
                )}

                {false && addJobType.volunteerSelected && (
                  <>
                    <AddJobVolunteer
                      addJobData={addJobData}
                      setAddJobData={setAddJobData}
                      dropdownList={addJobDropdownResponse}
                      addJobVolunteerVal={addJobVolunteerVal}
                    />
                  </>
                )}

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                    onClick={closeAddJobModalPopup}
                  >
                    {" "}
                    Cancel{" "}
                  </button>

                  {(addJobType.typeSelected ||
                    addJobType.fulltimeSelected ||
                    addJobType.contractSelected ||
                    addJobType.partTimeSelected ||
                    addJobType.tempToHireSelected ||
                    addJobType.volunteerSelected) && (
                    <button type="submit" className="btn btn-primary btn-sm">
                      <span>Create</span>
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AddJob;
