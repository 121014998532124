import React from "react";
import moment from "moment";

const SearchEmployee = (props) => {
  const {
    placeholder,
    setPageCount,
    setShowingCount,
    setTotalEmployee,
    setEmployeesDetails,
    setSearchValue,
    searchValue,
    sortData,
    pageShowCount,
  } = props;

  const filterEmployee = (e) => {
    setSearchValue(e.target.value);
    const filterData = sortData.filter((val) => {
      if (e.target.value === "") {
        return val;
      } else if (
        val.name
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(e.target.value.replace(/\s/g, "").toLowerCase()) ||
        val.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val.role.toLowerCase().includes(e.target.value.toLowerCase()) ||
        e.target.value.toLowerCase() ==
          val.statusname.toLowerCase().slice(0, e.target.value.length) ||
        moment(val.lastLogin, "YYYYMMDD")
          .fromNow()
          .includes(e.target.value.toLowerCase())
      ) {
        return val;
      }
    });
    const finalData = filterData.slice(0, pageShowCount);
    setEmployeesDetails(finalData);
    setPageCount(Math.ceil(filterData.length / pageShowCount));
    setShowingCount(finalData.length);
    setTotalEmployee(filterData.length);
  };

  return (
    <div className="mb-2">
      <input
        type="text"
        value={searchValue}
        placeholder={placeholder}
        className="form-control small font-14"
        onChange={filterEmployee}
      />
    </div>
  );
};

export default SearchEmployee;
