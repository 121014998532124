import {
    GET_JOB_TEMPLATE_LIST_BEGIN,
    GET_JOB_TEMPLATE_LIST_SUCCESS,
    GET_JOB_TEMPLATE_LIST_FAILURE,
  } from "../../actions/job/JobTemplate";
  
  const initialState = {
    jobTemplateListLoading: true,
    jobTemplateList: [],
  };
  
  const jobTemplateListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_JOB_TEMPLATE_LIST_BEGIN:
        return {
          ...state,
          jobTemplateListLoading: true,
          jobTemplateList: [],
        };
      case GET_JOB_TEMPLATE_LIST_SUCCESS:
        return {
          ...state,
          jobTemplateListLoading: false,
          jobTemplateList: payload,
        };
      case GET_JOB_TEMPLATE_LIST_FAILURE:
        return {
          ...state,
          jobTemplateList: [],
          jobTemplateListLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default jobTemplateListReducer;
  