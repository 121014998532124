import React, { useEffect, useState } from "react";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import { getUserTenantID } from "../../../service/AuthService";
import { post } from "../../../service/ApiService";
import BootstrapTable from "react-bootstrap-table-next";
import filterFactory, { textFilter } from "react-bootstrap-table2-filter";
import "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css";

function PreferredSupplierTable(props) {
  const [tenantData, setTenantData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [supplierData, setSupplierData] = useState([]);

  const handleSave = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const tenant = getUserTenantID();
      const payload = {
        domainName: "https://uat.high5hire.com",
        tenantId: tenant,
        tenantData,
      };
      const res = await post("/preferredSupplier/update", payload);
      if (res.status === 200) {
        toast.success(res.data.returnText);
      } else {
        toast.error("Oops! something went wrong. please try again later");
      }
      props.updateAPI();
      setLoading(false);
      props.getprefferedSupplier();
      close();
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again later");
    }

    // api call
  };
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setTenantData((oldArray) => [...oldArray, row]);
    } else {
      let tempData = [...tenantData];
      tempData = tempData.filter((item) => item.id_tenant !== row.id_tenant);
      setTenantData(tempData);
    }
    return true; // return true or dont return to approve current select action
  };

  const handleOnAllSelect = (isSelect, rows, e) => {
    if (isSelect) {
      setTenantData(rows);
    } else {
      setTenantData([]);
    }
    return true;
  };

  const close = () => {
    props.closeAddPreferredSupplier(true);
  };

  const items = [];
  const selectedRow = {
    mode: "checkbox",
    onSelect: handleOnSelect,
    onSelectAll: handleOnAllSelect,
  };

  const columns = [
    {
      dataField: "tenantname",
      text: "Tenant",
      width: "40px",
    },
    {
      dataField: "Name",
      text: "Admin",
    },
  ];

  useEffect(() => {
    const result = props.suplierData.map(function(eachVal) {
      return {
        ...eachVal,
        primarySkills: JSON.parse(eachVal.primarySkills).join(", "),
        CountryList: JSON.parse(eachVal.CountryList)
          .map((country) => country.label)
          .join(", "),
      };
    });
    setSupplierData(result);
  }, [props]);

  const noData = (
    <div>
      <div class="text-center p-3">
        <div class="avatar avatar-lg">
          <i aria-hidden="true" class="fad fa-folder-open"></i>
        </div>{" "}
        <div class="mt-6">No records found</div>
      </div>
    </div>
  );
  
  return (
    <>
      {loading && <Loader />}
      <BootstrapTable
        keyField="userId"
        data={supplierData}
        selectRow={selectedRow}
        columns={columns}
        filter={filterFactory()}
        //filterPosition="top"
        selectableRows
        rowClasses="custom-row-class"
        noDataIndication={noData}
      />

      <div class="modal-footer mt-4 mb-0">
        <button
          type="button"
          class="btn btn-secondary mr-2"
          data-dismiss="modal"
          aria-label="Close"
          onClick={close}
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-primary"
          disabled={tenantData.length === 0}
        >
          <span onClick={handleSave}>Save</span>
        </button>
      </div>
    </>
  );
}
export default PreferredSupplierTable;
