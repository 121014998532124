import React, { useState } from "react";
import { useDispatch } from "react-redux";
import DeactivateJob from "./DeactivateJob";
import { getJobDetails } from "../../../../../../actions/job/JobDetails";

const Index = (props) => {
  const [deactivate, setDeactivate] = useState(false);
  const dispatch = useDispatch();

  const handleClose = (value) => {
    setDeactivate(false);
    if (value) {
      const payload = {
        jobId: props.jobData.jobId,
      };
      dispatch(getJobDetails(payload));
    }
  };

  return (
    <>
      <div
        className={"dropdown px-1"}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Deactivate"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => setDeactivate(true)}
        style={{ cursor: "pointer", fontSize: "large" }}
      >
        <i className="fal fa-trash-alt fa-fw"></i>
      </div>
      {deactivate && (
        <DeactivateJob
          deactivate={deactivate}
          handleClose={handleClose}
          jobData={props.jobData}
        />
      )}
    </>
  );
};

export default Index;
