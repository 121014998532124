import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { get, post } from "../../../service/ApiService";
import axios from "axios";
import { toast } from "react-toastify";
import { ProgressBar } from "react-bootstrap";
import buttonLoader from "../../../images/button-loader.gif";

const ResumeUploadAddTalent = (props) => {
  const [resumeFile, setResumeFile] = useState();
  const [valid, setValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const onDragDropChangeHandler = (file) => {
    var re = /(?:\.([^.]+))?$/;

    var ext = re.exec(file[0].name)[1]; // "
    if (ext === "pdf" || ext === "docx" || ext === "doc") {
      setValid(true);
      const { name } = file[0];
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (event) => {
        setResumeFile({ fileName: name, base64: event.target.result });
        //	toast.success("File uploaded successfully")
        // api call here to get uploaded
        // incrementUploadValue()
      };
    } else {
      setValid(false);
    }
  };

  const onRemove = (e) => {
    setResumeFile(undefined);
    toast.success("File removed successfully");
  };

  const uploadResume = async () => {
    try {
      if (valid) {
        setSubmitClicked(true);
        const result = await post("/talent/resume", resumeFile);
        if (result.status === 200) {
          if (result?.data?.data) {
            const finalresult = {
              ...result.data,
              ...resumeFile,
              h5h: false,
            };
            props.updateParsedData(finalresult, true, false);
          } else {
            console.log("ENTERD else-----------------------------------");
            toast.warn(
              "Our primary parsing engine is busy, we are taking you to secondary parsing engine."
            );
            const payload = {
              filename: resumeFile.fileName,
              base64: resumeFile.base64,
            };
            const resultPy = await post("/talent/parseuploadresume", payload);
            console.log("python-rwsudhhcbeiudfhvbiuewf", resultPy.data.data);
            const finalresultPy = {
              ...resultPy.data,
              ...resumeFile,
              h5h: true,
            };
            props.updateParsedData(finalresultPy, true, true);
          }
        }
      } else {
        toast.error("Please Uplaod resume first");
        //setSubmitClicked(false)
      }
    } catch (error) {
      console.log("error___", error);
      toast.error("Something went wrong");
    }
    //incrementUploadValue(1)
  };

  useEffect(() => {
    setSubmitClicked(false);
  }, []);

  return (
    <>
      <div>
        <div class="row">
          <div class="col-12">
            <div class="p-5 p-0-600">
              {/* <h6> Upload Resumes</h6>
							<p>Accepted File Type: .docx, .pdf</p> */}
              <div className=" d-flex">
                <div>
                  {" "}
                  <h6> Upload Resumes</h6>{" "}
                  <p className={valid ? "text-primary" : ""}>
                    Accepted File Type: .docx, .pdf{" "}
                  </p>
                </div>
                {/* <div className="ml-auto">
                  {" "}
                  <p className={valid ? "text-primary" : "text-danger"}>
                    Please upload .docx .doc, .pdf file
                  </p>
                </div> */}
              </div>
              <div class="col-sm-12">
                <Dropzone onDrop={onDragDropChangeHandler}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <div class="talentuploadmaindiv">
                          <div class="talentuploadicon">
                            <label>
                              <i class="fas fa-cloud-upload-alt"></i>
                            </label>
                            <input type="file" {...getInputProps()} />
                          </div>
                          {resumeFile ? (
                            <div class="talentuploadtext">
                              {resumeFile.fileName}
                            </div>
                          ) : (
                            <div class="talentuploadtext">
                              Click or Drag and Drop to Upload
                            </div>
                          )}
                          {/* <div class="talentuploadtext">Click or Drag and Drop To Upload</div>  */}
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
                {resumeFile && resumeFile.fileName && submitClicked === false && (
                  <div className="mb-2 ">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={onRemove}
                    >
                      Remove
                    </button>
                  </div>
                )}
              </div>
              <div class="modal-footer">
                {valid && (
                  <div class="">
                    <button
                      type="button"
                      class="btn btn-secondary btn-sm "
                      disabled={submitClicked}
                      onClick={props.closeModal}
                    >
                      Cancel
                    </button>
                    {resumeFile && resumeFile.fileName && (
                      <button type="button" class="btn btn-primary btn-sm ml-2">
                        {submitClicked === false ? (
                          <span onClick={uploadResume}>Upload</span>
                        ) : (
                          <span v-if="submittingForm">
                            Uploading
                            <img width="20px" alt="" src={buttonLoader} />
                          </span>
                        )}
                      </button>
                    )}
                  </div>
                )}

                {/* //uncomment when add functionality */}

                {/* <div v-if="uploadComplete" class="flex-grow-1 ml-2">
									<div class="font-green">Upload Complete!</div>
								</div> */}
              </div>

              {/* <div className="flex" >
								<div>
									<ProgressBar animated variant="success" now={progress.value} label={"uploading resume"} />

									<ProgressBar animated variant="danger" now={0} />
								</div>
							</div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ResumeUploadAddTalent;
