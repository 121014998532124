import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const GET_CURATOR_INVITE_DETAILS_BEGIN =
  "GET_CURATOR_INVITE_DETAILS_BEGIN";
export const GET_CURATOR_INVITE_DETAILS_SUCCESS =
  "GET_CURATOR_INVITE_DETAILS_SUCCESS";
export const GET_CURATOR_INVITE_DETAILS_FAILURE =
  "GET_CURATOR_INVITE_DETAILS_FAILURE";

export const UPDATE_CURATOR_DATA_BEGIN = "UPDATE_CURATOR_DATA_BEGIN";
export const UPDATE_CURATOR_DATA_SUCCESS = "UPDATE_CURATOR_DATA_SUCCESS";
export const UPDATE_CURATOR_DATA_FAILURE = "UPDATE_CURATOR_DATA_FAILURE";

export const GET_CURATOR_DATA_BEGIN = "GET_CURATOR_DATA_BEGIN";
export const GET_CURATOR_DATA_SUCCESS = "GET_CURATOR_DATA_SUCCESS";
export const GET_CURATOR_DATA_FAILURE = "GET_CURATOR_DATA_FAILURE";

export const getCuratorInviteDetails = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CURATOR_INVITE_DETAILS_BEGIN,
    });
    const res = await post("/curatorInvite/inviteDetails", data);
    if (res.status === 200) {
      dispatch({
        type: GET_CURATOR_INVITE_DETAILS_SUCCESS,
        payload: res.data,
      });
    }
    console.log("@24 :", res.data);
  } catch (error) {
    dispatch({
      type: GET_CURATOR_INVITE_DETAILS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const updateCurator = (data) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_CURATOR_DATA_BEGIN,
    });
    const res = await post("/curatorInvite/updateDetails", data);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_CURATOR_DATA_SUCCESS,
        payload: res.data,
      });
      if (data.curatorStatus === "accept") {
        toast.success("Invitation accepted successfully!");
      } else {
        toast.error("Invitation declined successfully!");
      }
      setTimeout(() => {
        window.location.replace("/");
      }, 1500);
    }
  } catch (error) {
    dispatch({
      type: UPDATE_CURATOR_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getCuratorData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CURATOR_DATA_BEGIN,
    });
    const res = await post("/curatorInvite/curatorInviteStatus", data);
    if (res.status === 200) {
      dispatch({
        type: GET_CURATOR_DATA_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CURATOR_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
