import {
  GET_CANDIDATE_DROPDOWN_BEGIN,
  GET_CANDIDATE_DROPDOWN_SUCCESS,
  GET_CANDIDATE_DROPDOWN_FAILURE,
} from "../../actions/talent/AddTalent";

const initialState = {
  loading: true,
  dropDownData: null,
};

const addCandidateReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CANDIDATE_DROPDOWN_BEGIN:
      return {
        ...state,
        loading: true,
        dropDownData: null,
      };
    case GET_CANDIDATE_DROPDOWN_SUCCESS:
      return {
        ...state,
        dropDownData: payload,
        loading: false,
      };
    case GET_CANDIDATE_DROPDOWN_FAILURE:
      return {
        ...state,
        dropDownData: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default addCandidateReducer;
