import React, { useEffect, useState } from "react";
import SideNavBar from "../../layout/SideNavBar";
import TopNavBar from "../../layout/TopNavBar";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";

const Inbox = () => {
  const [toggle, setToggle] = useState(true);

  const InMessages = [
    {
      recievedOn: "11/11/2022",
      id: "1",
      from: "joshua@yopmail.com",
      subject: "python developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 4 python developers to start our new project, kindly fullfuill the requirement immediately.",
    },
    {
      recievedOn: "11/11/2022",
      id: "2",
      from: "john@yopmail.com",
      subject: "java developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 3 java developers to start our new project, kindly do the needful.",
    },
    {
      recievedOn: "11/11/2022",
      id: "3",
      from: "joshua@yopmail.com",
      subject: "python developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 4 python developers to start our new project, kindly fullfuill the requirement immediately.",
    },
    {
      recievedOn: "11/11/2022",
      id: "4",
      from: "john@yopmail.com",
      subject: "java developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 3 java developers to start our new project, kindly do the needful.",
    },
    {
      recievedOn: "11/11/2022",
      id: "5",
      from: "joshua@yopmail.com",
      subject: "python developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 4 python developers to start our new project, kindly fullfuill the requirement immediately.",
    },
    {
      recievedOn: "11/11/2022",
      id: "6",
      from: "john@yopmail.com",
      subject: "java developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 3 java developers to start our new project, kindly do the needful.",
    },
    {
      recievedOn: "11/11/2022",
      id: "7",
      from: "joshua@yopmail.com",
      subject: "python developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 4 python developers to start our new project, kindly fullfuill the requirement immediately.",
    },
    {
      recievedOn: "11/11/2022",
      id: "8",
      from: "john@yopmail.com",
      subject: "java developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 3 java developers to start our new project, kindly do the needful.",
    },
  ];

  const OutMessages = [
    {
      sentOn: "11/11/2022",
      id: "1",
      from: "joshua@yopmail.com",
      subject: "python developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 4 python developers to start our new project, kindly fullfuill the requirement immediately.",
    },
    {
      sentOn: "11/11/2022",
      id: "2",
      from: "john@yopmail.com",
      subject: "java developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 3 java developers to start our new project, kindly do the needful.",
    },
    {
      sentOn: "11/11/2022",
      id: "3",
      from: "joshua@yopmail.com",
      subject: "python developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 4 python developers to start our new project, kindly fullfuill the requirement immediately.",
    },
    {
      sentOn: "11/11/2022",
      id: "4",
      from: "john@yopmail.com",
      subject: "java developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 3 java developers to start our new project, kindly do the needful.",
    },
    {
      sentOn: "11/11/2022",
      id: "5",
      from: "joshua@yopmail.com",
      subject: "python developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 4 python developers to start our new project, kindly fullfuill the requirement immediately.",
    },
    {
      sentOn: "11/11/2022",
      id: "6",
      from: "john@yopmail.com",
      subject: "java developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 3 java developers to start our new project, kindly do the needful.",
    },
    {
      sentOn: "11/11/2022",
      id: "7",
      from: "joshua@yopmail.com",
      subject: "python developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 4 python developers to start our new project, kindly fullfuill the requirement immediately.",
    },
    {
      sentOn: "11/11/2022",
      id: "8",
      from: "john@yopmail.com",
      subject: "java developers are required.",
      to: "user@yopmail.com",
      message:
        "hello user, we need 3 java developers to start our new project, kindly do the needful.",
    },
  ];

  const [activeMessage, setActiveMessage] = useState(InMessages[0]);

  const [messages, setmessages] = useState(InMessages);
  const [tabs, setTabs] = useState([
    {
      value: "Recieved",
      selected: true,
    },
    {
      value: "Sent",
      selected: false,
    },
  ]);
  const [recievedSelected, setRecievedSelected] = useState(true);

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };
  const handleTabClick = (item) => {
    setTabs((state) =>
      state.map((tab) =>
        tab.value === item.value
          ? { ...tab, selected: true }
          : { ...tab, selected: false }
      )
    );
  };

  useEffect(() => {
    recievedSelected ? setmessages(InMessages) : setmessages(OutMessages);
    recievedSelected
      ? setActiveMessage(InMessages[0])
      : setActiveMessage(OutMessages[0]);
  }, [recievedSelected]);

  useEffect(() => {
    setRecievedSelected(tabs[0].selected);
  }, [tabs]);

  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar handleToggale={toggleDiv} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div className="my-5">
                    <div
                      style={{ width: "100%" }}
                      className={`${"d-flex flex-row gap-4  my-5 justify-content-center "}`}
                    >
                      {tabs.map((item) => (
                        <div
                          key={item.value}
                          onClick={() => handleTabClick(item)}
                          className={`h-100 d-flex px-1 ${
                            item.selected
                              ? "activeTabCandidate1200 pb-2"
                              : "inActiveTabCandidate1200  pb-2"
                          }`}
                        >
                          <h6 className="my-auto">{item.value}</h6>
                        </div>
                      ))}
                    </div>
                  </div>
                  {/* ___________________________________________ INBOX _________________________________________________ */}
                  <div className="card card-body ">
                    {/* <h6>Inbox</h6> */}

                    {true ? (
                      <div
                        style={{ height: "75vh" }}
                        className="  d-flex flex-row"
                      >
                        <div
                          style={{ overflowY: "scroll " }}
                          className=" w-25 mr-4 d-flex flex-column gap-1 p-1"
                        >
                          {messages.map((item, index) => (
                            <div
                              className={`card card-hoverable p-0 py-2 ${
                                item.id === activeMessage.id
                                  ? "card-body border-secondary border-2"
                                  : "card-body  card-flat"
                              }`}
                              onClick={() => {
                                setActiveMessage(messages[index]);
                              }}
                            >
                              <span className="mb-1 px-2">
                                <span className="bold">From:</span> <br />
                                <span className="tag tag-blue_primary">
                                  {item.from}
                                </span>
                              </span>
                              <hr className="my-1 " />
                              <span className="px-2">
                                <span className="bold">Subject: </span>
                                <span>{item.subject}</span>
                              </span>
                            </div>
                          ))}
                        </div>
                        {/* {messages.filter((item) => item.id === activeMessage)[0].message} */}

                        <div className="w-75 border">
                          <div className="border-bottom d-flex flex-row">
                            {recievedSelected ? (
                              <div className="w-50 border-end p-2">
                                <span className="mb-1">
                                  <span className="bold">From: </span>
                                  <span>{activeMessage.from}</span>
                                </span>
                              </div>
                            ) : (
                              <div className="w-50 border-end p-2">
                                <span className="mb-1">
                                  <span className="bold">To: </span>
                                  <span>{activeMessage.to}</span>
                                </span>
                              </div>
                            )}
                            <div className="w-50  p-2 ">
                              <span className="mb-1">
                                <span className="bold">
                                  {" "}
                                  {recievedSelected
                                    ? "Recieved on:"
                                    : "Sent on"}
                                </span>
                                <span className="tag tag-green1">
                                  {console.log(
                                    "recievedSelected",
                                    recievedSelected,
                                    activeMessage
                                  )}
                                  {recievedSelected
                                    ? activeMessage.recievedOn
                                    : activeMessage.sentOn}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div className="border-bottom p-2">
                            <span className="mb-1">
                              <span className="bold">Subject: </span>
                              <span>{activeMessage.subject}</span>
                            </span>
                          </div>
                          <div className=" p-2">
                            <span className="mb-1">
                              <span className="bold">Message: </span>
                              <span>{activeMessage.message}</span>
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{ height: "75vh" }}
                        className="card card-flat text-center "
                      >
                        <div className="my-auto d-flex flex-column">
                          <i
                            style={{ fontSize: 100 }}
                            class="fal fa-inbox "
                          ></i>
                          <h6>You have no messages, Please come back later.</h6>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
};

export default Inbox;
