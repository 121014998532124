import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Logo from "../../../images/high5logo.png";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import moment from "moment";
import logo from "../../../assets/images/high5-logo.jpg";
import { emailDomain } from "../../../constants/Constants";
const Index = () => {
  const curDate = new Date();
  const [subbed, setSubbed] = useState(true);
  const [status, setStatus] = useState(false);
  const [end, setEnd] = useState(false);
  const [loading, setLoading] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [resubscribeDate, setResubscribeDate] = useState("");
  const [isChangePreference, setIsChangePreference] = useState(false);
  
  const [scheduleSubDate, setScheduleSubDate] = useState("");

  const REASON_LIST = {
    noWish: "I no longer wish to receive these emails",
    notRelavent: "These emails are not relavent to me",
    neverSigned: "I never signed up for this mailing list",
  };

  const findStatus = async () => {
    try {
      setLoading(true);
      const res = await post("/inviteunsubscribed/fetchStatus", {
        email: email,
      });
 res.data.status === "Unsubscribed" ? setStatus(false) : setStatus(true);
 setResubscribeDate(moment(res.data.date).format("MM/DD/YYYY"))

 res.data.status === "Unsubscribed" && setPreference({...preference,subscribe:false});
      setSubbed(status);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    findStatus();
  }, []);

  const { email } = useParams();
  const initialPref = {
    subscribe: false,
    scheduleSub: false,
    unsubscribe: false,
  };
  const initialReason = {
    noWish: false,
    notRelavent: false,
    neverSigned: false,
    other: false,
  };
  const [preference, setPreference] = useState({
    subscribe: true,
    scheduleSub: false,
    unsubscribe: false,
  });
  const [reason, setReason] = useState({
    noWish: true,
    notRelavent: false,
    neverSigned: false,
    other: false,
  });

  const handlePreference = (e) => {
    let p = { ...initialPref, [e.target.name]: true };
    setPreference(p);
  };
  const handleReason = (e) => {
    let p = { ...initialReason, [e.target.name]: true };
    setReason(p);
  };
  const handleSubmit = async (e) => {
    // console.log("preference", preference);
    // console.log("reason", reason);
    // console.log("date", scheduleSubDate);
    // console.log("commentText", commentText);
    if(preference?.scheduleSub && scheduleSubDate === ""){
      return toast.error("Please select a Date")
    }
    let url = preference.subscribe ? "/isSubscribed" : "/isUnsubscribed";

    try {
      setLoading(true);

      let payload = preference.subscribe
        ? {
            email: email,
          }
        : {
            email: email,
            unsubscribedReason: reason.other
              ? commentText
              : reason.noWish
              ? REASON_LIST["noWish"]
              : reason.notRelavent
              ? REASON_LIST["notRelavent"]
              : REASON_LIST["neverSigned"],
          };

      if (preference.scheduleSub) payload["subscriptionDate"] = scheduleSubDate;
      const res = await post(`/inviteunsubscribed/${url}`, payload);
      setEnd(true);
      setSubbed(preference.subscribe);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal show={true} size="md">
        <div className="bl-modal-header">
          <h5 className="bl-modal-title">{"Email preferences"}</h5>
          {/* <div className="brand-logo">
            <img src={Logo} alt="" width="35" height="35" />
          </div> */}
          <div className="bl-modal-header py-0 my-0">
            <div className="d-flex align-self-center p-0 my-0">
              <img src={logo} width="110" className="high5logo mx-auto" />
            </div>
          </div>
          <Modal.Body>
            {!end ? (
              <>
                <div className=" d-flex flex-column align-items-center justify-content-center pb-2 pt-0 ">
                  <div className=" d-flex flex-column justify-content-center gap-2 px-2">
                    {/* <img src={logo} width="150" className="high5logo mx-auto" /> */}
                    
                    <div className=" d-flex flex-column justify-content-center  ">
                      {/* <div className=" d-flex align-items-center ">
                        <input
                          type="radio"
                          style={{ width: "15px" }}
                          className="d-inline-block mr-1"
                          onClick={handlePreference}
                          name="subscribe"
                          checked={preference.subscribe}
                        />
                        <a
                          href="#"
                          style={{ color: "black" }}
                          className="font-regular"
                          // className="individualLabel"
                          onClick={handlePreference}
                          name="subscribe"
                          for=""
                        >
                          Subscribe to job opportunities
                        </a>
                      </div> */}
                      {status ? (<>
                        <p>
                      You are currently {isChangePreference && "not"} receiving job opportunities at{" "}
                      <strong>{`${email}`}</strong>. You can update your email
                      preferences below.
                    </p>{" "}
                      <div className="d-flex  align-items-center">
                        <div
                        // className={preference?.scheduleSub ? "w-50" : "w-50"}
                        >
                          <input
                            type="radio"
                            style={{ width: "15px" }}
                            className="d-inline-block mr-1"
                            onClick={handlePreference}
                            name="scheduleSub"
                            checked={preference.scheduleSub}
                          />
                          <a
                            href="#"
                            style={{ color: "black" }}
                            className="font-regular"
                            onClick={handlePreference}
                            name="scheduleSub"
                            //className="individualLabel"
                            for=""
                          >
                            Unsubscribe then resubscribe on
                          </a>
                        </div>
                        {(preference?.scheduleSub || true) && (
                          <div className=" px-2">
                            <input
                              type="date"
                              disabled={!preference?.scheduleSub}
                              value={scheduleSubDate}
                              onChange={(e) => {
                                setScheduleSubDate(e.target.value);
                              }}
                              // min={moment(new Date()).format("YYYY-MM-DD")}
                              // min={moment(
                              //   new Date(curDate.setDate(curDate.getDate() + 1))
                              // ).format("MM/DD/YYYY")}
                              min={moment(curDate).add(1, "day").format("YYYY-MM-DD")}
                              className=""
                            />
                          </div>
                        )}
                      </div>
                      {status && (<div className="p-0">
                        <input
                          type="radio"
                          style={{ width: "15px" }}
                          className="d-inline-block mr-1"
                          name="unsubscribe"
                          onClick={handlePreference}
                          checked={preference.unsubscribe}
                        />
                        <a
                          href="#"
                          style={{ color: "black" }}
                          className="font-regular"
                          name="unsubscribe"
                          onClick={handlePreference}
                          //className="individualLabel"
                          for=""
                        >
                          Unsubscribe
                        </a>
                      </div>)}
                      {(preference.unsubscribe || preference.scheduleSub) && (
                    <div className="d-flex flex-column justify-content-center  gap-2 mt-4">
                      <p>
                        If you have a moment, please let us know why you are
                        unsubscribing.
                      </p>{" "}
                      <div className="">
                        <input
                          type="radio"
                          style={{ width: "15px" }}
                          className="d-inline-block mr-1"
                          onClick={handleReason}
                          id=""
                          name="noWish"
                          checked={reason.noWish}
                        />
                        <a
                          href="#"
                          style={{ color: "black" }}
                          className="font-regular"
                          //className="individualLabel"
                          name="noWish"
                          onClick={handleReason}
                          for=""
                        >
                          I no longer wish to receive these emails
                        </a>
                      </div>
                      <div className="">
                        <input
                          type="radio"
                          name="notRelavent"
                          style={{ width: "15px" }}
                          className="d-inline-block mr-1"
                          onClick={handleReason}
                          checked={reason.notRelavent}
                        />
                        <a
                          href="#"
                          style={{ color: "black" }}
                          className="font-regular"
                          name="notRelavent"
                          onClick={handleReason}
                          //className="individualLabel"
                          for=""
                        >
                          These emails are not relavent to me
                        </a>
                      </div>
                      <div className="">
                        <input
                          type="radio"
                          name="neverSigned"
                          style={{ width: "15px" }}
                          className="d-inline-block mr-1"
                          onClick={handleReason}
                          checked={reason.neverSigned}
                        />
                        <a
                          href="#"
                          style={{ color: "black" }}
                          className="font-regular"
                          name="neverSigned"
                          onClick={handleReason}
                          //className="individualLabel"
                          for=""
                        >
                          I never signed up for this mailing list
                        </a>
                      </div>
                      <div className="">
                        <input
                          type="radio"
                          name="other"
                          style={{ width: "15px" }}
                          className="d-inline-block mr-1"
                          onClick={handleReason}
                          checked={reason.other}
                        />
                        <a
                          href="#"
                          style={{ color: "black" }}
                          className="font-regular"
                          name="other"
                          onClick={handleReason}
                          //className="individualLabel"
                          for=""
                        >
                          Other (please specify):
                        </a>
                      </div>
                      {reason.other && (
                        <div className="form-floating mt-3">
                          <textarea
                            className="form-control"
                            maxLength={250}
                            placeholder="Leave a comment here"
                            id="floatingTextarea2"
                            style={{ height: "100px" }}
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                          ></textarea>
                          <label for="floatingTextarea2">Enter Comments</label>
                        </div>
                      )}
                    </div>
                  )}
                      <hr className="mt-5" />
                <div className="text-center pt-1 pb-1  ">
                  <button
                    type="button"
                    className="btn "
                    data-dismiss="modal"
                    //   disabled={shareClicked}
                    onClick={handleSubmit}
                  >
                    Save Preferences
                  </button>
                </div>
                      </>) : (<>
                        {/* <p>
                      You are currently not receiving email at{" "}
                      <strong>{`${email}`}</strong>. You can update your email
                      preferences below.
                    </p>{" "} */}
                     <p className="text-center">
                      You have been{" "}
                      {preference.subscribe
                        ? "subscribed to receive"
                        : resubscribeDate != 'Invalid date'
                        ? `unsubscribed until ${resubscribeDate} from receiving`
                        : "unsubscribed from receiving"}{" "}
                      job opportunities from high5hire.
                    </p>
                    <hr className="mt-5" />
                <div className="text-center pt-1 pb-1  ">
                <button
                    type="button"
                    className="btn "
                    data-dismiss="modal"
                    onClick={()=>{setStatus(true)
                    setIsChangePreference(true)
                    }}
                  >
                    Change Preference
                  </button>
                </div>
                      </>)}
                    </div>{" "}
                  </div>{" "}
                  
                </div>
                
              </>
            ) : (
              <>
                <div className=" d-flex flex-column align-items-center justify-content-center mt-2">
                  <div className=" d-flex flex-column justify-content-center w-75 gap-2">
                    <div className="d-flex flex-column justify-content-center align-items-center">
                      {/* <img src={logo} width="150" className="high5logo" /> */}
                    </div>
                    <p className="text-center">
                      You have been{" "}
                      {preference.subscribe
                        ? "subscribed to receive"
                        : preference.scheduleSub
                        ? `unsubscribed until ${moment(scheduleSubDate).format("MM/DD/YYYY")} from receiving`
                        : "unsubscribed from receiving"}{" "}
                      job opportunities from high5hire.
                    </p>
                  </div>
                </div>
                <hr />
                <div className="w-100 d-flex flex-column justify-content-center">
                  <div className=" d-flex justify-content-center">
                    <a onClick={() => setLoading(true)} href={`${emailDomain}`}>
                      <button
                        type="button"
                        className="btn "
                        data-dismiss="modal"
                      >
                        Close
                      </button>
                    </a>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
