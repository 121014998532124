import React, { useState, useEffect } from "react";
// import TopNavBar from "../layout/TopNavbar";
import SideNavBar from "../../../../layout/SideNavBar";
import "react-tabs/style/react-tabs.css";
import ReportTopNavBar from "../../../../report/ReportTopNavBar";
import {
  getTenantType,
  getUserRole,
  getUserTenantID,
} from "../../../../../service/AuthService";
import TopNavBar from "../../../../layout/TopNavBar";
import PoolList from "./PoolList";
import Footer from "../../../../layout/Footer";
import TCHeader from "./TCHeader";

const Index = () => {
  const [toggle, setToggle] = useState(true);
  const role = getUserRole();

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };

  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <SideNavBar handleToggale={setToggle} />
        {role === "CommunityManager" ? (
          <ReportTopNavBar handleToggale={toggleDiv} />
        ) : (
          <TopNavBar handleToggale={toggleDiv} />
        )}
        <div id="Content">
          <div className="section section-sm">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div className="card card-flat card-borderless p-1 p-0-600 mb-3">
                    <div class="d-flex align-items-center justify-content-between">
                      <h6 class="mr-3 mb-0 mb-xl-0">
                        <div className="d-flex justify-content-between mt-3">
                          {getTenantType() === 1 ? (
                            <h6>Talent Communities</h6>
                          ) : (
                            <h6>Branded Talent Communities</h6>
                          )}
                        </div>
                      </h6>
                      <TCHeader
                        type={getTenantType() === 1 ? "" : "public"}
                        clientId={
                          getTenantType() === 1 ? getUserTenantID() : null
                        }
                      />
                    </div>
                    <hr className="mt-0 pt-0" />
                    <PoolList
                      clientId={
                        getTenantType() === 1 ? getUserTenantID() : null
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
};

export default Index;
