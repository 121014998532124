import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ModalView from "../commoncomponents/modal/Modal";
import { FaUserPlus, FaUserFriends } from "react-icons/fa";

const Recipients = (props) => {
  const {
    selectedRecruiter,
    setSelectedRecruiter,
    selectedCandidates,
    setSelectedCandidates,
    isFrom,
    role,
    selectedJob,
    addCandidate,
    setAddCandidate,
    addRecruiter,
    setAddRecruiter,
    setFilter,
    setData,
    data,
  } = props;
  const [ViewAllEmailList, setViewAllEmailList] = useState(false);
  const [tableStructureCan, setTableStructureCan] = useState(false);
  const handleApplyClick = (selectedData, recipientType) => {
    if (recipientType === "addRecruiter") {
      const mergedArray = [...new Set(selectedRecruiter.concat(selectedData))];
      setSelectedRecruiter(mergedArray);
    } else {
      const mergedArray = [...new Set(selectedCandidates.concat(selectedData))];
      setSelectedCandidates(mergedArray);
    }
  };

  const handleFilterRemove = (e, email, isFrom) => {
    e.preventDefault();
    if (isFrom === "Recruiter") {
      setSelectedRecruiter(selectedRecruiter.filter((s) => s !== email));
      setData(data?.filter((item) => item.email !== email));
    } else {
      setSelectedCandidates(selectedCandidates.filter((s) => s !== email));
      setData(data?.filter((item) => item.email !== email));
    }
  };

  const onSkillClick = (e, val) => {
    setViewAllEmailList(!ViewAllEmailList);
  };

  return (
    <div className="">
      {isFrom === "talentCommunity" &&
      selectedCandidates.length > 0 &&
      selectedRecruiter.length === 0 ? (
        <div>
          <div>
            {selectedCandidates?.length > 0 && (
              <small>
                Selected{" "}
                {selectedCandidates?.length > 1 ? "Recipients" : "Recipient"}{" "}
                {": "}
                {selectedCandidates?.length}
              </small>
            )}
          </div>
          <div className="recipient-user-list">
            {selectedCandidates?.length > 0 &&
              selectedCandidates?.slice(0, 10).map((item) => (
                <span className="tag tag-blue3 mb-1 mr-1">
                  {item}
                  <a
                    href="#"
                    className="font-black"
                    onClick={(e) => handleFilterRemove(e, item, "Candidate")}
                  >
                    <i className="fas fa-times ml-2 "></i>
                  </a>
                </span>
              ))}
            {selectedCandidates?.length > 10 &&
              ViewAllEmailList === true &&
              selectedCandidates
                .slice(10, selectedCandidates?.length)
                .map((item) => (
                  <>
                    <span class="tag tag-blue3 mb-1 mr-1">
                      {item}
                      <a
                        href="#"
                        className="font-black"
                        onClick={(e) =>
                          handleFilterRemove(e, item, "Candidate")
                        }
                      >
                        <i className="fas fa-times ml-2 "></i>
                      </a>
                    </span>
                  </>
                ))}
            {selectedCandidates?.length > 10 && (
              <button
                onClick={(e) => onSkillClick(e)}
                type="button"
                class="btn btn-text"
              >
                {!ViewAllEmailList ? (
                  <span>View all</span>
                ) : (
                  <span>Show Less</span>
                )}
              </button>
            )}
          </div>
        </div>
      ) : (
        <div>
          <Row>
            <Col sm={12}>
              <Row>
                {selectedRecruiter?.length > 0 && (
                  <label>
                    {" "}
                    <small>
                      Selected{" "}
                      {selectedRecruiter?.length > 1
                        ? "Recipients"
                        : "Recipient"}
                      {": "}
                      {selectedRecruiter?.length}
                    </small>
                  </label>
                )}
                <div className="recipient-user-list">
                  {selectedRecruiter?.length > 0 &&
                    selectedRecruiter?.slice(0, 10).map((item) => (
                      <span className="tag tag-blue3 mb-1 mr-1">
                        {item}
                        <a
                          href="#"
                          className="font-black"
                          onClick={(e) =>
                            handleFilterRemove(e, item, "Recruiter")
                          }
                        >
                          <i className="fas fa-times ml-2 "></i>
                        </a>
                      </span>
                    ))}
                  {selectedRecruiter?.length > 10 &&
                    ViewAllEmailList === true &&
                    selectedRecruiter
                      .slice(10, selectedRecruiter?.length)
                      .map((item) => (
                        <>
                          <span class="tag tag-blue3 mb-1 mr-1">
                            {item}
                            <a
                              href="#"
                              className="font-black"
                              onClick={(e) =>
                                handleFilterRemove(e, item, "Recruiter")
                              }
                            >
                              <i className="fas fa-times ml-2 "></i>
                            </a>
                          </span>
                        </>
                      ))}
                  {selectedRecruiter?.length > 10 && (
                    <button
                      onClick={(e) => onSkillClick(e)}
                      type="button"
                      class="btn btn-text"
                    >
                      {!ViewAllEmailList ? (
                        <span>View all</span>
                      ) : (
                        <span>Show Less</span>
                      )}
                    </button>
                  )}
                  {selectedRecruiter?.length > 0 && (
                    <button
                      type="button"
                      className="btn btn-text"
                      onClick={() => {
                        setSelectedRecruiter([]);
                        setData({});
                      }}
                    >
                      <span className="font-bold font-14">Clear All</span>
                    </button>
                  )}
                </div>
              </Row>
              <Row>
                {selectedCandidates?.length > 0 && (
                  <small>
                    Selected{" "}
                    {selectedCandidates?.length > 1
                      ? "Recipients"
                      : "Recipient"}
                  </small>
                )}
                <div className="recipient-user-list">
                  {selectedCandidates?.length > 0 &&
                    selectedCandidates?.slice(0, 10).map((item) => (
                      <span className="tag tag-blue3 mb-1 mr-1">
                        {item}
                        <a
                          href="#"
                          className="font-black"
                          onClick={(e) =>
                            handleFilterRemove(e, item, "Candidate")
                          }
                        >
                          <i className="fas fa-times ml-2 "></i>
                        </a>
                      </span>
                    ))}
                  {selectedCandidates?.length > 10 &&
                    ViewAllEmailList === true &&
                    selectedCandidates
                      .slice(10, selectedCandidates?.length)
                      .map((item) => (
                        <>
                          <span class="tag tag-blue3 mb-1 mr-1">
                            {item}
                            <a
                              href="#"
                              className="font-black"
                              onClick={(e) =>
                                handleFilterRemove(e, item, "Candidate")
                              }
                            >
                              <i className="fas fa-times ml-2 "></i>
                            </a>
                          </span>
                        </>
                      ))}
                  {selectedCandidates?.length > 10 && (
                    <button
                      onClick={(e) => onSkillClick(e)}
                      type="button"
                      class="btn btn-text"
                    >
                      {!ViewAllEmailList ? (
                        <span>View all</span>
                      ) : (
                        <span>Show Less</span>
                      )}
                    </button>
                  )}
                  {selectedCandidates?.length > 0 && (
                    <button
                      type="button"
                      className="btn btn-text "
                      onClick={() => {
                        setSelectedCandidates([]);
                        setData({});
                      }}
                    >
                      <span className="font-bold font-14">Clear All</span>
                    </button>
                  )}
                </div>
              </Row>
            </Col>
          </Row>
          <div className="ml-0 mb-2 mt-2">Add Recipients</div>
          <Row className="ml-0">
            <Button
              className="recipient-button mr-2 mt-2"
              onClick={() => {
                setAddRecruiter(true);
              }}
              style={{
                backgroundColor: "#17a2b8",
                color: "white",
                border: "none",
              }}
              disabled={
                (role === "HiringManager" &&
                  Object.keys(selectedJob).length === 0) ||
                selectedCandidates?.length > 0
              }
            >
              <FaUserPlus className="mr-2" /> Recruiter
            </Button>
            <Button
              className="recipient-button mt-2"
              style={{
                backgroundColor: "#17a2b8",
                color: "white",
                border: "none",
              }}
              onClick={() => {
                setTableStructureCan(true);
                setAddCandidate(true);
              }}
              disabled={
                (role === "HiringManager" &&
                  Object.keys(selectedJob).length === 0) ||
                selectedRecruiter?.length > 0
              }
            >
              <FaUserPlus className="mr-2" /> Candidate
            </Button>
          </Row>
        </div>
      )}

      {addRecruiter && (
        <ModalView
          setData={setData}
          modalSize={"xl"}
          Recipientdata={data}
          modalTitle={"Select Recruiter"}
          isModalOpen={addRecruiter}
          setModalClose={setAddRecruiter}
          isFrom="EmailBroadcast"
          modalFor="addRecruiter"
          data={{
            handleApplyClick: handleApplyClick,
            selectedJob: selectedJob,
          }}
        />
      )}
      {addCandidate && (
        <ModalView
          modalSize={"xl"}
          setData={setData}
          Recipientdata={data}
          modalTitle={"Select Candidate"}
          isModalOpen={addCandidate}
          setModalClose={setAddCandidate}
          isFrom="EmailBroadcast"
          modalFor="addCandidate"
          tableStructureCan={tableStructureCan}
          data={{
            handleApplyClick: handleApplyClick,
            selectedJob: selectedJob,
          }}
        />
      )}
    </div>
  );
};

export default Recipients;
