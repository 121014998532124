import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { post } from "../../../../../../service/ApiService";
import {
  getUserRole,
  getActualRole,
  getTenantType,
  getUserTenantID,
  getUserId,
  getCandidateId,
} from "../../../../../../service/AuthService";
import TalentProfile from "../../../../talent/talentprofile/TalentProfile";
import ShortlistPopUp from "../shortlisted/ShortlistPopUp";
import Loader from "../../../../../Loader/Loader";
import MakeAnOfferPopup from "../offered/MakeAnOfferPopup";
import { emailDomain } from "../../../../../../constants/Constants";
import VettingResendMail from "../shortlisted/VettingResendMail";
import { getPoolDispositionDetails } from "../../../../../../actions/directsourcing/Disposition";
import { getPoolDispositionDisqualify } from "../../../../../../actions/directsourcing/PoolDispositionDisqualify";
import { getPoolDispositionCount } from "../../../../../../actions/directsourcing/PoolDispositionCount";
import axios from "axios";
import ProfileImage from "../../../../commoncomponents/profileimage/ProfileImage";
import { FaCommentAlt } from "react-icons/fa";

const Disqualified = (props) => {
  const { jobData, disqualifiedData, filterParams, view } = props;
  const [selectedCandidate, setSelectedCandidate] = useState();
  const [candidateProfile, setCandidateProfile] = useState(false);
  const dispatch = useDispatch();
  const { poolId } = useParams();
  const [showShortlistPopup, setShowShortlistPopup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [makeAnOfferPopup, setMakeAnOfferPopup] = useState(false);
  const userid = getCandidateId();
  const [vettingResendPopup, setVettingResendPopup] = useState(false);
  const [isCandidateFrom, setIsCandidateFrom] = useState("");

  // let sendMailLink = `${emailDomain}/careers/${jobData.fk_tenant}/joblist/jobdescription/${jobData.jobId}/recruiter/${userid}`;

  // Function to make a request to the URL shortening API
  async function shortenUrl(longUrl) {
    const headers = {
      Accept: "application/json",
      Authorization:
        "Bearer WJwUfBqQhE8ORpWMhoyae6IlvSPq2eIc3rS21kjth1Q8tY4yi3hkY9Zfvpqv",
      "Content-Type": "application/json",
    };
    const requestBody = {
      url: longUrl,
      domain: "high5hire.live",
    };

    try {
      const response = await axios.post(
        "https://api.tinyurl.com/create",
        requestBody,
        { headers }
      );
      if (response.data && response.data.data && response.data.data.tiny_url) {
        const shortenedUrl = response.data.data.tiny_url;
        return shortenedUrl;
      } else {
        return "";
      }
    } catch (error) {
      console.error("Error shortening URL:", error);
    }
  }
  const SMS = async (items) => {
    const getContent = async (event) => {
      const req = { event, recipient: '["Candidate"]' };
      const res = await post("/msgtwoway/getSmsContent", req);
      return res.status === 200 ? res.data[0] : null;
    };

    try {
      // let sendMailLink = `${emailDomain}/careers/${jobData.fk_tenant}/joblist/jobdescription/${jobData.jobId}/recruiter/${userid}`;
      const sendMailLink = `${emailDomain}/careers/${jobData?.createdby}/joblist/pooldescription/${jobData?.poolId}/recruiter/${userid}`;

      const shortenedUrl = await shortenUrl(sendMailLink);
      const payload = await Promise.all(
        items.map(async (i) => {
          const inviteContent = await getContent("Invite Talent");

          if (inviteContent?.length !== 0) {
            let content = inviteContent[0].smsContent;
            content = content.replace("[FirstName]", i.candidateFname);
            content = content.replace("[JobTitle]", i.jobTitle);
            content = content.replace("[LinkForDetails]", shortenedUrl);
            inviteContent[0].smsContent = content;
          }

          return {
            toUserName: i.candidateFname,
            recieverNumber: [
              i?.candidatePhone
                ? `${i.candidatePhoneCode}${i.candidatePhone.replaceAll(
                    " ",
                    ""
                  )}`
                : "",
            ],
            // message: `Hi ${i.candidateFname}, Heads up! Your recruiter has invited you to apply for the ${i.jobTitle} role.\nClick below to see details.\n${sendMailLink}\nThe High5 Team `,
            message: `${inviteContent?.[0]?.smsContent} \n ${inviteContent?.[0]?.footer}`,
          };
        })
      );

      const payloadSms = payload.filter(
        (i) =>
          i.recieverNumber[0].charAt(0) == "+" &&
          i.recieverNumber[0].charAt(1) == "1" &&
          i.recieverNumber[0].length > 10
      );

      const payloadWA = payload
        .map((item) => ({
          ...item,
          recieverNumber: item.recieverNumber[0]?.substring(1),
        }))
        .filter(
          (item) =>
            item.recieverNumber[0].charAt(0) !== "1" &&
            item.recieverNumber[0].length > 10
        );

      if (payloadSms.length > 0) {
        await post("/msgtwoway/telSms", payloadSms);
      }

      if (payloadWA.length > 0) {
        await post("/massages/sentMassageWhatsapp", payloadWA);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const SMS2 = async (items) => {
    const getInviteContent = async () => {
      const inviteReq = {
        event: "Invite Talent",
        recipient: '["Recruiter"]',
      };
      const res = await post("/msgtwoway/getSmsContent", inviteReq);
      return res.status === 200 ? res.data : null;
    };

    try {
      const tenant = {
        createdBy: getUserId(),
        jobId: jobData.jobId,
      };
      const numbers = await post("/msgtwoway/getNumbersForOffer", tenant);
      const data = numbers.data.filter(
        (n) => n.fk_Role !== 8 && n.fk_Role !== 4
      );

      if (data.length === 0) return;

      const recepientsDataSms = [];
      const recepientsDataWhatsApp = [];

      const inviteContent = await getInviteContent();

      if (!inviteContent || inviteContent.length === 0) return;

      data.forEach((obj) => {
        const recepientDataSms = [];
        const recepientDataWhatsApp = [];

        const home = obj.homePhone ? `+${obj.homePhone}` : "";
        const mobile = obj.mobilePhone ? `+${obj.mobilePhone}` : "";
        const work = obj.workPhone ? `+${obj.workPhone}` : "";

        if (
          home.charAt(0) === "+" &&
          home.charAt(1) !== "1" &&
          home.length > 10
        )
          recepientDataWhatsApp.push(home.replaceAll(" ", "")?.substring(1));
        if (
          mobile.charAt(0) === "+" &&
          mobile.charAt(1) !== "1" &&
          mobile.length > 10
        )
          recepientDataWhatsApp.push(mobile.replaceAll(" ", "")?.substring(1));
        if (
          work.charAt(0) === "+" &&
          work.charAt(1) !== "1" &&
          work.length > 10
        )
          recepientDataWhatsApp.push(work.replaceAll(" ", "")?.substring(1));

        if (home.charAt(0) === "+" && home.charAt(1) === "1")
          recepientDataSms.push(home.replaceAll(" ", ""));
        if (mobile.charAt(0) === "+" && mobile.charAt(1) === "1")
          recepientDataSms.push(mobile.replaceAll(" ", ""));
        if (work.charAt(0) === "+" && work.charAt(1) === "1")
          recepientDataSms.push(work.replaceAll(" ", ""));

        items.forEach(async (i) => {
          const content = inviteContent[0].smsContent
            .replace("[FirstName]", obj.firstName)
            .replace(
              "[CandidateName]",
              i.candidateFname + " " + i.candidateLname
            )
            .replace("[JobTitle]", i.jobTitle)
            .replace(
              "[LinkForJobDetails]",
              `${emailDomain}/high5Hire/requests/${i.jobId}`
            );

          recepientsDataSms.push({
            recieverNumber: recepientDataSms,
            userId: Math.floor(Math.random() * 1000000),
            message: `${content} \n ${inviteContent[0].footer}`,
            // message: `Hi ${obj.firstName}, Just letting you know ${i.candidateFname} ${i.candidateLname} has been invited for the ${i.jobTitle} job.\nClick below to see details.\n${emailDomain}/high5Hire/requests/${i.jobId}\nThe High5 Team `,
            // message: `Hi ${obj.firstName}, Just letting you know ${i.candidateFname} ${i.candidateLname} has been invited for the ${i.jobTitle} job.\nClick below to see details.\n${emailDomain}/high5Hire/requests/${i.jobId}\nThe High5 Team `,
          });

          recepientsDataWhatsApp.push({
            recieverNumber: recepientDataWhatsApp,
            message: `${content} \n ${inviteContent[0].footer}`,
          });
        });
      });

      if (recepientsDataSms.length > 0) {
        await post("/msgtwoway/telSms", recepientsDataSms);
      }

      if (recepientsDataWhatsApp.length > 0) {
        await post("/massages/sentMassageWhatsapp", recepientsDataWhatsApp);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const inviteTalent = async (candidate) => {
    let phone = candidate.homePhone;
    let code = candidate.homePhoneCode;

    setLoading(true);
    try {
      const payload = {
        status: 33,
        id_disposition: candidate.id_candidateTalentPool,
        jobId: jobData.poolId,
        jobTitle: jobData.jobTitle,
        candidateEmail: candidate?.email,
        candidateFname: candidate?.firstName,
        candidateLname: candidate?.lastName,
        createdBy: candidate?.createdBy,
        recruiterFname: candidate.recruiterFname,
        recruiterLName: candidate.recruiterLname,
        recruiterId: candidate.recruiterId,
        tenantName: candidate.tenantName,
        tenantType: getTenantType(),
        jobLink: `${emailDomain}/careers/${jobData?.createdby}/joblist/pooldescription/${jobData?.poolId}/recruiter/${userid}`,

        tenantId: getUserTenantID(),
      };
      const res = await post("/disposition/poolupdatedisposition", payload);
      if (res.status === 200) {
        await SMS([
          {
            candidatePhone: phone,
            candidatePhoneCode: code,
            ...payload,
          },
        ]);
        await SMS2([payload]);
        toast.success("Candidate Invited Successfully!!!");
        setCandidateProfile(false);
        reloadDisposition();
      }
    } catch (error) {
      toast.error("Oops! something went wrong. please try again later");
    }
    setLoading(false);
  };

  const handleButton = async (item, status) => {
    if (status == "Shortlist") {
      setSelectedCandidate(item);

      let vettingDetails = item?.vettingDetails;

      if (
        vettingDetails?.length > 0 &&
        vettingDetails[0]?.status === "Invited"
      ) {
        setVettingResendPopup(true);
      } else {
        setShowShortlistPopup(true);
      }
    } else if (status == "Invite") {
      inviteTalent(item);
    } else if (status == "offer") {
      setSelectedCandidate(item);
      setMakeAnOfferPopup(true);
    }
  };

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };

  const buttonHandler = (item) => {
    if (
      item?.prevStatus == 6 &&
      jobData.jobStatus !== 23 &&
      jobData.jobStatus !== 26 &&
      jobData.jobStatus !== 39 &&
      getActualRole() == "High5Coordinator"
    ) {
      return (
        <button
          type="button"
          className="btn btn-sm"
          onClick={() => handleButton(item, "Shortlist")}
        >
          Shortlist
        </button>
      );
    } else if (
      item?.prevStatus === 33 &&
      jobData.jobStatus !== 23 &&
      jobData.jobStatus !== 26 &&
      jobData.jobStatus !== 39 &&
      getUserRole() === "Recruiter"
    ) {
      return (
        <button
          type="button"
          className="btn btn-sm"
          onClick={() => handleButton(item, "Invite")}
        >
          Invite
        </button>
      );
    } else if (
      (item?.prevStatus === 8 ||
        item?.prevStatus === 29 ||
        item?.prevStatus === 37) &&
      jobData.jobStatus !== 23 &&
      jobData.jobStatus !== 26 &&
      jobData.jobStatus !== 39 &&
      getUserRole() === "HiringManager"
    ) {
      return (
        <button
          type="button"
          className="btn btn-sm"
          onClick={() => handleButton(item, "offer")}
        >
          {item?.disqualifyComments.includes("Offer Rejected")
            ? "Re-Offer"
            : item.prevStatus == "37"
            ? "Re-Offer"
            : "Offer"}
        </button>
      );
    }
  };

  const reloadDisposition = () => {
    const payloadDisposition = {
      poolId: poolId,
      status: view,
      limit: 15,
      currentPage: 1,
      filterObject: props.filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionDetails(payloadDisposition));
    const payloadDispositionDisqualify = {
      poolId: poolId,
      status: "disqualified",
      limit: 15,
      currentPage: 1,
      filterObject: props.filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionDisqualify(payloadDispositionDisqualify));
    const payload = {
      jobId: poolId,
      filterObject: filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionCount(payload));
    setShowShortlistPopup(false);
  };

  return (
    <>
      {loading && <Loader />}
      {disqualifiedData.map((item) => (
        <tr>
          <td
            className="d-flex"
            onClick={() => onProfileClick(item)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="d-flex flex-inline w-70">
              <ProfileImage
                imageSrc={item?.profileImagePath}
                imageWidth={"35"}
                imageHeight={"35"}
                avatarSize={"35"}
                avatarRound={"80px"}
                firstName={item?.firstName}
                lastName={item?.lastName}
              />

              <span className="ml-1 font-16 mt-2">
                {item?.firstName.charAt(0).toUpperCase() +
                  item?.firstName.slice(1)}{" "}
                {item?.lastName.charAt(0).toUpperCase() +
                  item?.lastName.slice(1)}
              </span>
            </div>

            <div className="d-flex justify-content-start">
              {!item?.readStatus && (
                <button
                  type="button"
                  title="Send SMS"
                  class="btn btn-text"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    setCandidateProfile(true);
                    setIsCandidateFrom("sms");
                  }}
                >
                  <div className="notification-icon">
                    <FaCommentAlt />
                    <span className="message-status"></span>
                  </div>
                </button>
              )}
            </div>
          </td>
          {getUserRole() !== "Recruiter" && (
            <td v-else className="no-hover">
              <span v-html="item.avatar" />
              <span className="ml-2 font-16">
                {item?.tenantName?.substring(0, 6) == "GIG - "
                  ? item?.tenantName.substring(6)
                  : item?.tenantName}
              </span>
            </td>
          )}
          <td className="no-hover">
            <span v-html="item.supplierAvatar" />
            <span className="ml-2">
              {item.dispositionStatus == 29
                ? moment(item.offerRejectedDate).fromNow()
                : moment(item.disqualifiedDate).fromNow()}
            </span>
          </td>
          <td className="no-hover">
            {item?.disqualifyComments
              ? item?.disqualifyComments.replace("Rejected", "Declined")
              : item?.comments}
          </td>
          {/* <td className="no-hover">
            {
              <span className="">
                {item.prevStatus == "33"
                  ? "NA"
                  : props.calculateScore(jobData, item) + "%"}
              </span>
            }
          </td> */}
          <td className="no-hover text-right font-14">{buttonHandler(item)}</td>
        </tr>
      ))}

      {candidateProfile && (
        <TalentProfile
          setSelectedCandidate={setSelectedCandidate}
          setShowShortlistPopup={setShowShortlistPopup}
          inviteTalent={inviteTalent}
          setMakeAnOfferPopup={setMakeAnOfferPopup}
          candidateData={selectedCandidate}
          handleSuccess={handleModel}
          buttonHandler={buttonHandler}
          jobData={jobData}
          parent="disqualified"
          isFrom="disqualified"
          component="TalentPool"
          poolId={poolId}
          isCandidateFrom={isCandidateFrom}
          setIsCandidateFrom={setIsCandidateFrom}
        />
      )}
      {showShortlistPopup && (
        <ShortlistPopUp
          selectedCandidate={selectedCandidate}
          shortlistPopup={showShortlistPopup}
          setShortlistPopup={setShowShortlistPopup}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
        />
      )}
      {makeAnOfferPopup && (
        <MakeAnOfferPopup
          selectedCandidate={selectedCandidate}
          makeAnOfferPopup={makeAnOfferPopup}
          setMakeAnOfferPopup={setMakeAnOfferPopup}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
          isFrom={"DisqualifiedCandidates"}
        />
      )}

      {vettingResendPopup && (
        <VettingResendMail
          candidateData={selectedCandidate}
          jobData={jobData}
          vettingResendPopup={vettingResendPopup}
          setVettingResendPopup={setVettingResendPopup}
        />
      )}
    </>
  );
};

export default Disqualified;
