import React, { useState, useEffect } from 'react';
import Pdf from 'react-to-pdf'
import { getPreciseDateDiff } from "../../../function/CommonFunction";
import moment from "moment";

const ref = React.createRef();

function CertificateRtr(props) {
    //console.log("rtrPDF", props)
    const [jobData, setJobData] = useState({})
    const [candidateData, setCandidateData] = useState({})
    const [fullText, setFullText] = useState({})
    const dateDiff = getPreciseDateDiff(new Date(), new Date(jobData.jobPostDate))

    useEffect(() => {
        setJobData(props.JobData)
        setCandidateData(props.CandidateData)
        setFullText(props.FullText)
    }, [props])

    return (
        <>
            {
                <body>
                    <div ref={ref}>
                        <div className='card card-flat m-3 p-3'>

                            <div id="Content border border-primary">
                                <section id="TestApp">
                                    <div className="hero">
                                        <div className="section pl-0" style={{ padding: '50px' }}>
                                            <div className="row justify-content-center mb-0">
                                                <div className="text-center font-white">
                                                    <h4 className="mb-0">{props.JobData.fullText.jobTitle}</h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="page-content">
                                        <div class="container">
                                            <div class="row">
                                                <div class="col-md-9">
                                                    <div class="page-content-wrapper">
                                                        <div class="Sspace"></div>
                                                        <div class="jobsort">
                                                            <div className="row">
                                                                <div className="col-md-12">
                                                                    Hi {candidateData.candidateFname},<br /><br />
                                                                    As per our conversation, I would like to submit your profile for the position of <strong>{props.JobData.fullText.jobTitle}</strong> with <strong>{fullText.tenent}</strong> based in <strong>{props.JobData.location.city}</strong> on Payrate <strong>{props.CandidateData.payRate} {candidateData.payRateCurrency}</strong>.
                                                                    As a requirement, please read the email content carefully and approve the request for your profile to the client In case you have applied for the same job earlier, please inform us immediately
                                                                </div>
                                                            </div>
                                                            <div class="Sspace"></div>
                                                            <div class="jobsortheading">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <h5>{fullText.jobTitle}</h5>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="clientdiv">
                                                                <div class="clienttext">
                                                                    <h6>{fullText.tenent}</h6>
                                                                </div>
                                                                <div class="jobview"></div>
                                                                <ul></ul>
                                                            </div>
                                                            <div class="jobviewpostdiv">
                                                                <div class="row">
                                                                    <div class="col-md-4">
                                                                        <div class="jobviewpostopen">
                                                                            Posted on : &nbsp;
                                                                            {/* <span>{dateDiff}</span> */}
                                                                            {moment(new Date(jobData.jobPostDate)).format("MM/DD/YYYY")}
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-4">
                                                                        <div class="jobviewpostopen">
                                                                            Opening :&nbsp;{props.JobData.positionCount}

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="Sspace"></div>
                                                            <div class="deptdetails">
                                                                <div class="jobdetails">
                                                                    <h5>Job Description</h5>
                                                                </div>
                                                                <div class="jobdetaillist">
                                                                    <ul>
                                                                        <li>
                                                                            <p>
                                                                            <ol className='pr-3' dangerouslySetInnerHTML={{ __html: jobData.jobDescription }}></ol>
                                                                            </p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                            <div class="linediv"></div>
                                                            <div class="jdexpandiv">
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="jobviewpostopen">
                                                                            Job Title : &nbsp;
                                                                            <span><strong>{props.JobData.fullText.jobTitle}</strong></span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-12">
                                                                        <div class="jobviewpostopen">
                                                                            Position Type : &nbsp;
                                                                            <span><strong>{jobData.jobType}</strong></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="linediv"></div>
                                                            <div class="Sspace"></div>
                                                            <div class="jdexpandiv">
                                                                <div class="deptdetails">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="jobviewpostopen">
                                                                                Skills : &nbsp;
                                                                                <span><strong>{props.JobData.fullText.primarySkills && props.JobData.fullText.primarySkills.join(', ')}</strong></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="jobviewpostopen">
                                                                                Secondary Skills : &nbsp;
                                                                                <span><strong>{props.JobData.fullText.secondarySkills && props.JobData.fullText.secondarySkills.join(', ')}</strong></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <di class="jobviewpostopen">
                                                                                Skill set : &nbsp;
                                                                                <span><strong>{props.JobData.skillSet && props.JobData.skillSet.join(', ')}</strong></span>
                                                                            </di>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="Sspace"></div>
                                                            <div class="linediv"></div>
                                                            <div class="jdexpandiv">
                                                                <div class="deptdetails">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="jobviewpostopen">
                                                                                Accepted Date : &nbsp;
                                                                                {moment(props.CandidateData.rtrAcceptedDate).format("MM/DD/YYYY")}
                                                                                {/* <span> {new Date(props.CandidateData.rtrAcceptedDate).toDateString().split(" ").slice(1).join(" ")}</span> */}
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="jobviewpostopen">
                                                                                Accepted By : &nbsp;
                                                                                <span>{candidateData.candidateFname}{" "}{candidateData.candidateLname}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>                                               
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                    <div className="bl-modal-footer text-center">
                        <Pdf targetRef={ref} filename="Approval.pdf">
                            {({ toPdf }) => <button type="button" className="btn btn-sm btn-gray mb-3 mt-3 ml-3" onClick={toPdf}>Download</button>}
                        </Pdf>
                    </div>
                </body>
            }
        </>
    );

}

export default CertificateRtr;