import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getUserRole } from "../../../../../../service/AuthService";
import Disqualified from "./Disqualified";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";
import Pagination from "../../../../commoncomponents/pagination/Pagination";
import PaginationComponent from "../../../../pagination/pagination";
import { useParams } from "react-router";
import { getDispositionDisqualify } from "../../../../../../actions/disposition/DispositionDisqualified";

const Index = (props) => {
  const { jobData, view } = props;
  const [disqualifiedCandidates, setDisqualifiedCandidates] = useState(false);
  const [disqualifiedData, setDisqualifiedData] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { Id } = useParams();
  const dispatch = useDispatch();
  const { dispositionDetails, dispositionCount } = useSelector(
    (state) => ({
      dispositionDetails: state.dispositionDisqualify.dispositionDisqualify,
      dispositionCount: state.dispositionCount.dispositionCount,
    }),
    shallowEqual
  );

  useEffect(() => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: "disqualified",
      jobId: Id,
    };
    dispatch(getDispositionDisqualify(payload));
  }, []);

  useEffect(() => {
    setDisqualifiedData(
      dispositionDetails
    );
  }, [dispositionDetails]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: "disqualified",
      jobId: Id,
    };
    dispatch(getDispositionDisqualify(payload));
  };

  return (
    <div className="card card-flat card-lg mt-3">
      <div className="card-body">
        <button
          type="button"
          className="btn btn-collapsible"
          onClick={() => {
            setDisqualifiedCandidates(!disqualifiedCandidates);
          }}
        >
          <div className="d-flex align-items-center px-1">
            <p className="mb-0 font-14">
              Disqualified Candidates (
              {
                dispositionCount?.filter((e) => e.Status == "Disqualified")[0]
                  ?.Count
              }
              )
            </p>
            <div className="ml-auto">
              <i
                aria-hidden="true"
                className={
                  disqualifiedCandidates
                    ? "fal fa-angle-up fa-fw"
                    : "fal fa-angle-down fa-fw"
                }
              />
            </div>
          </div>
        </button>
        {disqualifiedCandidates && (
          <div className="table-wrap mt-3">
            {disqualifiedData.length > 0 ? (
              <>
                <table className="table table-hoverable-cells table-sortable table-cards-1024">
                  <thead>
                    <tr>
                      <th className="d-flex align-items-start">Candidate</th>
                      {getUserRole() !== "Recruiter" && (
                        <th className="" style={{ minWidth: 100 }}>
                          Submitted By{" "}
                        </th>
                      )}
                      <th style={{ minWidth: 90 }}>Last updated</th>
                      <th style={{ minWidth: 200 }}>
                        Reason for Disqualification
                      </th>
                      <th>Score</th>
                      <th className="no-hover" />
                    </tr>
                  </thead>
                  <tbody>
                    <Disqualified
                      jobData={jobData}
                      disqualifiedData={disqualifiedData}
                      calculateScore={props.calculateScore}
                      view={view}
                    />
                  </tbody>
                </table>
                <PaginationComponent
                  currentPage={currentPage}
                  totalItems={
                    dispositionCount?.filter(
                      (e) => e.Status == "Disqualified"
                    )[0]?.Count
                  }
                  itemsPerPage={15}
                  onPageChange={handlePageChange}
                  limit={15}
                />
              </>
            ) : (
              <NoRecord />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
