import React, { useState, useEffect } from "react";
import TopNavBar from "../../layout/TopNavBar";
import SideNav from "../../layout/SideNavBar";
import Loader from "../../Loader/Loader";
import { domain } from "../../../constants/Constants";
import { post } from "../../../service/ApiService";
import Avatar from "react-avatar";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import TalentSideDetails from "./TalentSideDetails";
import moment from "moment";
import { getTenantType, getUserRole } from "../../../service/AuthService";
import EditTalent from "../../modal/talentprofile/Edit Talent/EditTalent";
import { countryObj } from "../../../constants/Constants";
import Footer from "../../layout/Footer";
function ViewTalentDetails(props) {
  const [requests, setRequests] = useState([]);
  const [toggle, setToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resumeDownload, setResumeDownload] = useState([]);
  const [candidateData, setCandidateData] = useState({});
  const [loggedInUserRole, setLoggedInUserRole] = useState("");
  const [editTalent, setEditTalent] = useState(false);
  const [tabStatus, setTabStatus] = useState({
    Profile: true,
    Assessments: false,
    requests: false,
  });
  const [statusID, setStatusID] = useState([]);
  const disqualified = ["3", "4", "7", "12", "16", "27", "29"];

  const getRequests = async (candidateId) => {
    setLoading(true);
    const res = await post("/talentrequests", {
      candidateId,
    });
    setLoading(false);
    let final;
    if (getUserRole() === "Recruiter") {
      final = res.data.value.filter(
        (i) => i.statusId !== "36" && !disqualified.includes(i.statusId)
      );
    } else {
      final = res.data.value.filter(
        (i) => i.statusId !== "36" && i.statusId !== "1"
      );
    }
    setRequests(final);
    // console.log(final.map(i => i.statusId))
  };

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const updateTab = (category, val) => {
    const tempTabStatus = tabStatus;
    Object.keys(tempTabStatus).forEach((key) => {
      console.log(tempTabStatus[key], key);
      if (key === val) {
        tempTabStatus[key] = true;
      } else {
        tempTabStatus[key] = false;
      }
    });
    console.log(tempTabStatus);
    setTabStatus({ ...tempTabStatus });
  };

  const onTabClick = (category, val) => {
    console.log(category, val);
    updateTab(category, val);
  };

  //  get candidate details
  useEffect(() => {
    fetchCandidate();
    getRequests(props.match.params.Id);
    setLoggedInUserRole(getUserRole());
    if (getUserRole() === "Recruiter") {
      setStatusID(["1"]);
    } else {
      setStatusID(["6"]);
    }
  }, [props]);

  const fetchCandidate = async () => {
    setLoading(true);
    try {
      const payload = {
        domain: domain,
        candidateId: props.match.params.Id,
      };
      let res = await post("/talentdata/searchtalentbyid", payload);
      res.data.resumeURL && setResumeDownload(res.data.resumeURL);
      res.data && setCandidateData(res.data);
      // console.log(res.data)
      setLoading(false);
    } catch (error) {
      toast.error("Error Occured while accessing candidate details ");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
        id="body"
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar handleToggale={toggleDiv} />
        <SideNav />
        {Object.entries(candidateData).length != 0 && (
          <div id="Content">
            <div id="RequestView">
              <div className="hero">
                <div className="section pb-0">
                  <div className="container-fluid">
                    <div className="row justify-content-center">
                      <div className="col-xl-12">
                        <div className="text-center mb-4 font-white">
                          <Avatar
                            size="55"
                            name={
                              candidateData.firstName +
                              " " +
                              candidateData.lastName
                            }
                            round="80px"
                          />
                          {/* <span className="avatar avatar-lg avatar-bordered mb-2">
                                                    <img src={"https://uifaces.co/our-content/donated/9-mQNf2U.jpg"} />
                                                  
                                                </span> */}
                          <h4>
                            {candidateData.firstName} {candidateData.lastName}
                          </h4>
                          {candidateData?.addressCity &&
                          candidateData?.addressState &&
                          candidateData?.country ? (
                            <p className=" mb-0">
                              {candidateData.addressCity}
                              {candidateData.addressState !== "NA"
                                ? `, ${candidateData.addressState}`
                                : ""}
                              , {candidateData.country}
                            </p>
                          ) : (
                            ""
                          )}
                          {/* <p>{candidateData && candidateData.experience[0].jobTitle} { (candidateData.experience[0].jobTitle && candidateData.address) ? '/' : null } {candidateData.address}</p> */}
                          {/* <p>{candidateData.experience.length}</p> */}
                          {/* <p>
                                                    <span className="tag tag-lg tag-font_accent d-inline-flex">
                                                        <img width="16px" src="/images/high5-mark-circle.svg" />
                                                        <span className="mx-2">Vetted</span>
                                                    </span>
                                                </p> */}
                        </div>
                        <div className="row">
                          <div className="col-xl-4">
                            <div className="hero-card-header card card-flat card-lg card-bottom-straight">
                              <div className="card-body pb-0">
                                <div className="d-flex align-items-start">
                                  <div>
                                    <p className="mb-0">Profile Details</p>
                                    <p className="mb-3">
                                      {" "}
                                      {candidateData.updatedDate !== "" && (
                                        <small>
                                          Last updated :{" "}
                                          {moment(
                                            candidateData.updatedDate
                                          ).format("MM/DD/YYYY")}
                                        </small>
                                      )}
                                    </p>
                                  </div>
                                  {loggedInUserRole === "Recruiter" && (
                                    <button
                                      onClick={() => setEditTalent(true)}
                                      className="ml-auto btn btn-icon"
                                      title="Edit"
                                    >
                                      <i className="fal fa-pencil fa-fw"></i>
                                    </button>
                                  )}
                                </div>
                                <hr className="m-0 hr-white" />
                                <ul className="nav nav-tabs pt-1">
                                  <li className="nav-item">
                                    <button
                                      type="button"
                                      className={
                                        tabStatus.Profile
                                          ? "nav-link pb-3 active"
                                          : "nav-link pb-3"
                                      }
                                      onClick={() => {
                                        onTabClick("infoTabs", "Profile");
                                      }}
                                    >
                                      Profile
                                    </button>
                                  </li>
                                  {/* <li className="nav-item">
                                    <button
                                      type="button"
                                      className={
                                        tabStatus.Assessments
                                          ? "nav-link pb-3 active"
                                          : "nav-link pb-3"
                                      }
                                      onClick={() => {
                                        onTabClick("infoTabs", "Assessments");
                                      }}
                                    >
                                      Assessments
                                    </button>
                                  </li> */}
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-8 d-none-1200">
                            <div className="hero-card-header card card-lg card-flat card-bottom-straight">
                              <div className="card-body pb-0 d-flex flex-column justify-content-between">
                                <div className="d-flex">
                                  <p className="px-1">
                                    Requests ({requests.length})
                                  </p>
                                </div>
                                <ul className="nav nav-tabs justify-content-between">
                                  {loggedInUserRole === "Recruiter" && (
                                    <li className="nav-item w-25 d-flex justify-content-center">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setStatusID(["1"]);
                                        }}
                                        className={
                                          statusID.includes("1")
                                            ? "nav-link pb-3 active"
                                            : "nav-link pb-3"
                                        }
                                      >
                                        <span className="tag mb-1">
                                          {
                                            requests.filter(
                                              (i) => i.statusId === "1"
                                            ).length
                                          }
                                        </span>
                                        <div>Invited</div>
                                      </button>
                                    </li>
                                  )}
                                  <li className="nav-item w-25 d-flex justify-content-center">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setStatusID(["6"]);
                                      }}
                                      className={
                                        statusID.includes("6")
                                          ? "nav-link pb-3 active"
                                          : "nav-link pb-3"
                                      }
                                    >
                                      <span className="tag mb-1">
                                        {
                                          requests.filter(
                                            (i) => i.statusId === "6"
                                          ).length
                                        }
                                      </span>
                                      <div>Submitted</div>
                                    </button>
                                  </li>
                                  <li className="nav-item w-25 d-flex justify-content-center">
                                    <button
                                      type="button"
                                      onClick={() => setStatusID(["8"])}
                                      className={
                                        statusID.includes("8")
                                          ? "nav-link pb-3 active"
                                          : "nav-link pb-3"
                                      }
                                    >
                                      <span className="tag mb-1">
                                        {
                                          requests.filter(
                                            (i) => i.statusId === "8"
                                          ).length
                                        }
                                      </span>
                                      <div>Shortlisted</div>
                                    </button>
                                  </li>
                                  <li className="nav-item w-25 d-flex justify-content-center">
                                    <button
                                      type="button"
                                      onClick={() => setStatusID(["32"])}
                                      className={
                                        statusID.includes("32")
                                          ? "nav-link pb-3 active"
                                          : "nav-link pb-3"
                                      }
                                    >
                                      <span className="tag mb-1">
                                        {
                                          requests.filter(
                                            (i) => i.statusId === "32"
                                          ).length
                                        }
                                      </span>
                                      <div>Offered</div>
                                    </button>
                                  </li>
                                  <li className="nav-item w-25 d-flex justify-content-center">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setStatusID(["11"]);
                                      }}
                                      className={
                                        statusID.includes("11")
                                          ? "nav-link pb-3 active"
                                          : "nav-link pb-3"
                                      }
                                    >
                                      <span className="tag mb-1">
                                        {
                                          requests.filter(
                                            (i) => i.statusId === "11"
                                          ).length
                                        }
                                      </span>
                                      <div>Hired</div>
                                    </button>
                                  </li>
                                  {(loggedInUserRole === "HiringManager" ||
                                    loggedInUserRole === "Admin") && (
                                    <li className="nav-item w-25 d-flex justify-content-center">
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setStatusID(disqualified);
                                        }}
                                        className={
                                          statusID.includes(...disqualified)
                                            ? "nav-link pb-3 active"
                                            : "nav-link pb-3"
                                        }
                                      >
                                        <span className="tag mb-1">
                                          {
                                            requests.filter((i) =>
                                              disqualified.includes(i.statusId)
                                            ).length
                                          }
                                        </span>
                                        <div>Disqualified</div>
                                      </button>
                                    </li>
                                  )}
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pb-5">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-xl-12">
                      <div className="row">
                        <TalentSideDetails
                          tabStatus={tabStatus}
                          candidateData={candidateData}
                        />
                        <div className="col-xl-8">
                          <div
                            className="card card-flat card-lg card-top-straight"
                            style={{ borderTop: "none" }}
                          >
                            <div className="card-body">
                              {requests.filter((i) =>
                                statusID.includes(i.statusId)
                              ).length === 0 ? (
                                <div className="text-center mt-4">
                                  <div className="avatar avatar-lg">
                                    <i className="fad fa-fw fa-folder-open"></i>
                                  </div>
                                  <div className="mt-2">No Record Found</div>
                                </div>
                              ) : (
                                <>
                                  {requests
                                    .filter((i) =>
                                      statusID.includes(i.statusId)
                                    )
                                    .map((request) => (
                                      <div className="card card-flat mb-2">
                                        <div className="card-body">
                                          <div className="d-flex align-items-center d-block-600">
                                            <div className="mr-2">
                                              <span>{request.jobTitle}</span>
                                            </div>
                                            <div>
                                              {statusID.includes("6") ? (
                                                <span className="tag tag-green3">
                                                  {request.statusName}
                                                </span>
                                              ) : statusID.includes(
                                                  ...disqualified
                                                ) ? (
                                                <span className="tag tag-red1">
                                                  {request.statusName}
                                                </span>
                                              ) : (
                                                <span className="tag tag-blue3">
                                                  {statusID.includes("32")
                                                    ? "Offered"
                                                    : request.statusName}
                                                </span>
                                              )}
                                              <span className="ml-1">
                                                ID: {request.jobId}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {getTenantType() == 1 && <Footer />}
          </div>
        )}
      </body>
      <Modal show={editTalent} size="lg">
        <Modal.Header>
          <h6 class="">Edit Talent</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setEditTalent(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <EditTalent
            closeModal={() => setEditTalent(false)}
            data={candidateData}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ViewTalentDetails;
