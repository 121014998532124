import React, { useEffect, useState } from "react";
import { shallowEqual, useSelector } from "react-redux";
import Loader from "../../../../Loader/Loader";
import ExistingJob from "./ExistingJobs";
import AddJobDetails from "./JobDetails";
import CreateJobWithTemplate from "./CreateJobWithTemplate";

const MainAddJobType = (props) => {
  const { template } = useSelector(
    (state) => ({ template: state.AddJob.templateSelected }),
    shallowEqual
  );

  // const [loading, setLoading] = useState(false);
  // const [chooseJobDetails, setChooseJobDetails] = useState({});
  const [chooseTemplateDetails, setChooseTemplateDetails] = useState({});
  // const [jobListData, setJobListData] = useState([]);

  return (
    <>
      {/* {loading && <Loader></Loader>} */}

      {!template && props.createJobWithExisting && (
        <ExistingJob
          templateSelected={props.templateSelected}
          createJobWithExisting={props.createJobWithExisting}
          setTemplateSelected={props.setTemplateSelected}
          // jobListData={jobListData}
          // setChooseJobDetails={setChooseJobDetails}
        />
      )}

      {!template && props.createJobWithTemplate && (
        <CreateJobWithTemplate
          setTemplateSelected={props.setTemplateSelected}
          setChooseTemplateDetails={setChooseTemplateDetails}
          templateSelected={props.templateSelected}
          createJobWithExisting={props.createJobWithExisting}
          // setChooseJobDetails={setChooseJobDetails}
          // jobListData={jobListData}
        />
      )}

      {template && (
        <AddJobDetails
          closePanel={props?.closePanel}
          setSuccessData={props?.setSuccessData}
          setSuccessFormOpen={props?.setSuccessFormOpen}
          isFrom={props.isFrom}
          getAllJobs={props.getAllJobs}
          setLoadingPC={props.setLoadingPC}
          setSelectedJobId={props.setSelectedJobId}
          templateSelected={props.templateSelected}
          chooseTemplateDetails={chooseTemplateDetails}
          editTemplate={null}
          addTemplate={null}
        />
      )}
    </>
  );
};

export default MainAddJobType;
