import React, { useEffect, useState } from "react";
import Card from "./Card";
import Talents from "../../talent/talentcommunity/Index";
import Recruiters from "./Recruiters/Recruiters";
import Clients from "./Clients/Clients";
import Users from "./Users/Users";
import CuratorComponent from "./Curators/Curators";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDashboardCount } from "../../../../actions/dashboard/DashboardCount";
import Loader from "../../../Loader/Loader";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import CuratorPool from "../../curator/curatorpool/Index";
import Index from "./talentcommunity/Index";

const Dashboard = () => {
  const dispatch = useDispatch();
  const cardStyle = {
    width:
      window.innerWidth >= 1024
        ? "calc(25% - 20px)"
        : window.innerWidth <= 360
        ? "100%"
        : "50%",
  };

  const [view, setView] = useState({
    high5TalentPool: true,
    talents: false,
    subPool: false,
    talentCommunities: false,
    recruiters: false,
    clients: false,
    users: false,
    curators: false, // New state for Curators
    curatorPool: false,
  });

  const location = useLocation();
  const tab = location?.state?.tab;
  window.history.replaceState({}, document.title);

  useEffect(() => {
    if (tab === "users") {
      setView({
        high5TalentPool: false,
        talents: false,
        subPool: false,
        talentCommunities: false,
        recruiters: true,
        clients: false,
        users: false,
        curators: false,
      });
    }
  }, [tab]);

  const { loading, dashboardCount } = useSelector(
    (state) => ({
      loading: state.getDashboardCountReducer.loading,
      dashboardCount: state.getDashboardCountReducer.dashboardCount,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getDashboardCount());
  }, []);

  const getCount = (type) => {
    const count =
      dashboardCount && dashboardCount.filter((item) => item.Type === type)[0];
    return count?.TotalCount;
  };
  const getCountUsersCreated = (type) => {
    const count =
      dashboardCount && dashboardCount.filter((item) => item.Type === type)[0];
    let str =
      count?.NumberOfUsersCreated == 0
        ? "No talent"
        : count?.NumberOfUsersCreated > 0
        ? `${count?.NumberOfUsersCreated} talent`
        : "";
    return count?.NumberOfUsersCreated;
  };

  const getPercentage = (type) => {
    const count =
      dashboardCount && dashboardCount.filter((item) => item.Type === type)[0];
    if (count) {
      return Math.round(
        (count?.NumberOfUsersCreated / count?.TotalCount) * 100
      );
    } else {
      return 0;
    }
  };
  const getRecruiters = () => {
    window.location.reload();
  };

  return (
    <>
      <div
        style={{ paddingLeft: "30px", paddingRight: "74px" }}
        className="mb-3"
      >
        <div
          className="w-100 d-flex justify-content-start align-items-center p-2"
          style={{
            height: "100px",
            backgroundColor: "#e4e7e9",
            border: "1px solid transparent",
            borderRadius: "4px",
          }}
        >
          <span>
            <h3 className="font-light mb-0">
              {new Intl.NumberFormat().format(
                getCount("TotalTalentCount") || 0
              )}
            </h3>
            <span className="d-flex">
              <h5>Total Talent Count</h5>{" "}
              <i
                class="fal fa-info-circle ml-2"
                title={
                  "Total number of talent including High5 talent and GIG Recruiters"
                }
              ></i>
            </span>
          </span>
        </div>
      </div>
      <div className="px-5">
        {loading && <Loader />}
        <div className="row w-100 gap-3 mb-2 d-flex justify-content-start align-content-center">
          {/* <div className="row mb-2 mb-xl-0"> */}
          <Card
            view={view}
            // cardStyle={cardStyle}
            // className="col-md-3 col-sm-12 col-lg-3"
            setView={setView}
            elem={{
              heading: "High5 Talent Pool",
              title: "High5 Talent",
              count: getCount("H5TalentCount") || 0,
              changes: `${getPercentage("H5TalentCount") || 0}%`,
              analyticToolTip: `${getCountUsersCreated(
                "H5TalentCount"
              )} talent added in last 30 days`,
              tooltip: "Total number of High5 Talents.",
              icon: "user",
              bgColor: "antiquewhite",
            }}
          />
          <Card
            view={view}
            // cardStyle={cardStyle}
            // className="col-md-3 col-sm-12"
            setView={setView}
            elem={{
              heading: "High5 Talent Sub Pools",
              title: "Sub Pool",
              count: getCount("SubPoolCount") || 0,
              changes: `${getPercentage("SubPoolCount") || 0}%`,
              analyticToolTip: `${getCountUsersCreated(
                "SubPoolCount"
              )} sub pool created in last 30 days`,
              tooltip:
                "Total number of High5 Talent Sub Pools (excluding Gig Recruiters and client's talent sub pools).",
              bgColor: "antiquewhite",
            }}
          />
          <Card
            view={view}
            // cardStyle={cardStyle}
            // className="col-md-3 col-sm-12"
            setView={setView}
            elem={{
              heading: "Clients",
              title: "Clients",
              count: getCount("ClientCount") || 0,
              changes: `${getPercentage("ClientCount") || 0}%`,
              analyticToolTip: `${getCountUsersCreated(
                "ClientCount"
              )} client added in last 30 days`,
              tooltip: "Total number of Clients.",
              bgColor: "#fefff0",
            }}
          />
          <Card
            // cardStyle={cardStyle}
            // className="col-md-3 col-sm-12"
            view={view}
            setView={setView}
            elem={{
              heading: "Client Talent Communities",
              title: "Talent Communities",
              count: getCount("CommunityCount") || 0,
              changes: `${getPercentage("CommunityCount") || 0}%`,
              analyticToolTip: `${getCountUsersCreated(
                "CommunityCount"
              )} talent community created in last 30 days`,
              tooltip: "Total number of clients talent communities.",
              bgColor: "#fefff0",
            }}
          />
          {/* </div>
        <div className="d-flex gap-2 mb-2 mb-xl-0"> */}

          <Card
            view={view}
            // cardStyle={cardStyle}
            setView={setView}
            elem={{
              heading: "Gig Recruiter Talent Pool",
              title: "Talent",
              count: getCount("TalentCount") || 0,
              changes: `${getPercentage("TalentCount") || 0}%`,
              analyticToolTip: `${getCountUsersCreated(
                "TalentCount"
              )} talent added in last 30 days`,
              tooltip:
                "Total number of Gig recruiter's talent registered on High5Hire.",
              bgColor: "#daeaf8",
            }}
          />
          <Card
            view={view}
            // cardStyle={cardStyle}
            setView={setView}
            elem={{
              heading: "Gig Recruiters",
              title: "Gig Recruiters",
              count: getCount("RecruiterCount") || 0,
              changes: `${getPercentage("RecruiterCount") || 0}%`,
              analyticToolTip: `${getCountUsersCreated(
                "RecruiterCount"
              )} Gig recruiter added in last 30 days`,
              tooltip:
                "Total Gig recruiter registered on the High5Hire platform.",
              bgColor: "#daeaf8",
            }}
          />
          <Card
            view={view}
            // cardStyle={cardStyle}
            setView={setView}
            elem={{
              heading: "Curator Pool",
              title: "Curator Pool",
              // count: getCount("CuratorCount") || 0,
              count: 2719 || 0,
              changes: `${getPercentage("RecruiterCount") || 0}%`,
              analyticToolTip: `0 curators added in last 30 days`,
              tooltip: "Total number of curator pool.",
              bgColor: "rgb(194 238 235)",
            }}
          />
          <Card
            view={view}
            // cardStyle={cardStyle}
            setView={setView}
            elem={{
              heading: "Assigned Curators",
              title: "Curators",
              count: getCount("CuratorCount") || 0,
              changes: `${getPercentage("CuratorCount") || 0}%`,
              analyticToolTip: `${getCountUsersCreated(
                "CuratorCount"
              )} curators added in last 30 days`,
              tooltip: "Total number of curators assigned.",
              bgColor: "rgb(194 238 235)",
            }}
          />
        </div>
        <div>
          {view?.talentCommunities && <Index />}
          {view?.high5TalentPool && <Talents isFrom="high5talentpool" />}
          {view?.talents && <Talents isFrom="gigtalentpool" />}
          {view?.subPool && <Talents isFrom="high5talentpool" admin={true} />}
          {view?.recruiters && <Recruiters getRecruiters={getRecruiters} />}
          {view?.clients && <Clients clientList={[]} />}
          {view?.users && <Users userList={[]} />}
          {view?.curators && <CuratorComponent />}
          {view?.curatorPool && <CuratorPool getRecruiters={getRecruiters} />}
        </div>
      </div>
    </>
  );
};

export default Dashboard;
