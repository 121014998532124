import React, { useState, useEffect, useRef, Component } from "react";
import RequestGridView from "./RequestGridView";
import RequestListView from "./RequestListView";
import RequestTableView from "./RequestTableView";
import RequestsFilter from "./RequestsFilter";
import TopNavBar from "../../layout/TopNavBar";
import SideNav from "../../layout/SideNavBar";
import AddJob from "../../modal/addjob/AddJob";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import { domain } from "../../../constants/Constants";
import _ from "lodash";
import Filter from "./Filter";
import Sort from "../../common/Sort";
import moment from "moment";
import FilterJobs from "./FilterJobs";
import Loader from "../../Loader/Loader";
import OutsideClickHandler from "react-outside-click-handler";
import AddJobNew from "../../modal/addjob/AddJobNew";
import AddJobSuccess from "../../modal/addjob/AddJobSuccess";
import { getTenantType } from "../../../service/AuthService";

function RequestList(props) {
  const [listview, setlistview] = useState(true);
  const [gridview, setgridview] = useState(false);
  const [tableview, settableview] = useState(false);
  const [allRequests, setallRequests] = useState(true);
  const [openRequests, setopenRequests] = useState(false);
  const [activeRequests, setactiveRequests] = useState(false);
  const [closedRequests, setclosedRequests] = useState(false);
  const [deletedRequests, setdeletedRequests] = useState(false);
  const [sortModal, setSortModal] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [toggle, setToggle] = useState(true);
  const [filter, setFilter] = useState(false);
  const [jobListData, setJobListData] = useState([]);
  const [oldJobListData, setOldJobListData] = useState([]);
  const [showAddJobModal, setShowAddJobModal] = useState(false);
  const [filterBy, setFilterBy] = useState(jobListData);
  const [filterData, setFilterData] = useState(jobListData);
  const [daysLeft, setDaysLeft] = useState("");
  const [daysLeftValue, setDaysLeftValue] = useState("");
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );
  const tenantType = getTenantType();

  const sortObject = [
    {
      id: 1,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Type",
      value: "jobType",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Location",
      value: "location.city",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  useEffect(() => {
    window
      .matchMedia("(max-width: 1200px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  // getAllDataFromJobIndex
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [addJobNewModal, setAddJobNewModal] = useState(false);
  const [successData, setSuccessData] = useState({
    jobId: "",
    position: "",
    startDate: "",
    location: "",
    salary: "",
    skills: "",
  });
  const [successFormOpen, setSuccessFormOpen] = useState(false);

  const getRequestList = async () => {
    setLoading(true);
    const params = {
      skip: 0,
      domain: domain,
    };
    try {
      const res = await post(`/requestlist/getrequestslist`, params);

      let title = [];
      let type = [];
      const result = res.data.jobData.sort((a, b) => {
        let fa = a.jobPostDate,
          fb = b.jobPostDate;
        if (fa > fb) {
          return -1;
        }
        if (fa < fb) {
          return 1;
        }
        return 0;
      });
      result.forEach((job) => {
        if (job.jobTitle && !title.includes(job.jobTitle)) {
          title.push(job.jobTitle);
        }
        if (job.jobType && !type.includes(job.jobType)) {
          type.push(job.jobType);
        }
      });
      setJobListData(result);
      setFilterBy(result);
      setFilterData(result);
      setOldJobListData(result);
      setRequestTitles(title);
      setRequestTypes(type);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    getRequestList();
  }, []);

  const closeAddJobModalPopup = () => {
    setShowAddJobModal(false);
  };

  const onTabClick = (category, val) => {
    setCurrentPage(1);
    resetTabs([category]);
    updateTab([category], val);
  };

  const updateTab = (category, type) => {
    if (type === "listview") {
      setlistview(true);
      setgridview(false);
      settableview(false);
    }
    if (type === "gridview") {
      setgridview(true);
      setlistview(false);
      settableview(false);
    }
    if (type === "tableview") {
      settableview(true);
      setlistview(false);
      setgridview(false);
    }

    if (type === "all") {
      setFilterData(filterBy);
      setallRequests(true);
      setopenRequests(false);
      setactiveRequests(false);
      setclosedRequests(false);
      setdeletedRequests(false);
    }
    if (type === "open") {
      setFilterData(filterBy.filter((data) => data.jobStatus === "open"));
      setallRequests(false);
      setopenRequests(true);
      setactiveRequests(false);
      setclosedRequests(false);
      setdeletedRequests(false);
    }
    if (type === "active") {
      setFilterData(filterBy.filter((data) => data.jobStatus === "active"));
      setallRequests(false);
      setopenRequests(false);
      setactiveRequests(true);
      setclosedRequests(false);
      setdeletedRequests(false);
    }
    if (type === "expired") {
      setFilterData(filterBy.filter((data) => data.jobStatus === "expired"));
      setallRequests(false);
      setopenRequests(false);
      setactiveRequests(false);
      setclosedRequests(true);
      setdeletedRequests(false);
    }
    if (type === "Deactivate") {
      setFilterData(
        filterBy.filter((data) => data.jobStatus === "Deactivated")
      );
      setallRequests(false);
      setopenRequests(false);
      setactiveRequests(false);
      setclosedRequests(false);
      setdeletedRequests(true);
    }
  };

  const resetTabs = (type) => {
    if (type === "listview" && listview === true) {
      setlistview(false);
    }
    if (type === "gridview") {
      setgridview(false);
    }
    if (type === "tableview") {
      settableview(false);
    }
  };

  const jobStatus = (data) => {
    if (allRequests) {
      setFilterData(data);
    } else if (openRequests) {
      setFilterData(data.filter((data) => data.jobStatus === "open"));
    } else if (activeRequests) {
      setFilterData(data.filter((data) => data.jobStatus === "active"));
    } else if (closedRequests) {
      setFilterData(data.filter((data) => data.jobStatus === "expired"));
    } else {
      setFilterData(data.filter((data) => data.jobStatus === "Deactivated"));
    }
  };

  const openFilter = () => {
    setFilter(true);
  };

  const applySort = (sortedData, close) => {
    close && setSortModal(false);
    setFilterBy(sortedData);
    jobStatus(sortedData);
    clearFilter();
  };
  const clearFilter = () => {
    setSelectedTypes([]);
    setSelectedTitles([]);
    setDaysLeftValue("");
    setDaysLeft("");
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(jobListData, true);
    clearFilter();
  };

  const applyFilter = (
    data,
    selectedTitle,
    selectedType,
    daysLeft,
    daysLeftValue
  ) => {
    sortClearAll();
    onTabClick("toggleView", "all");
    setSelectedTitles(selectedTitle);
    setSelectedTypes(selectedType);
    setDaysLeft(daysLeft);
    setDaysLeftValue(daysLeftValue);
    setFilterBy(data);
    setFilterData(data);
    setFilter(false);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="card card-flat card-borderless p-4 mb-3">
            <div className="d-flex-column d-lg-flex flex-lg-row align-items-end mb-3 justify-content-between border-bottom-gray2">
              <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                <h6 className="mr-3 mb-xl-0">Requests</h6>
                <div className=" d-none d-xl-flex nav nav-tabs justify-content-between">
                  <div className="nav-itemd-flex justify-content-center">
                    <button
                      className={
                        listview === true
                          ? "nav-link pb-3 bg-transparent active"
                          : "nav-link pb-3 bg-transparent"
                      }
                      onClick={() => {
                        onTabClick("toggleView", "listview");
                      }}
                      type="button"
                    >
                      <i className="fas fa-list"></i>
                    </button>
                  </div>
                  <div className="nav-itemd-flex justify-content-center">
                    <button
                      className={
                        gridview === true
                          ? "nav-link pb-3 bg-transparent active"
                          : "nav-link pb-3 bg-transparent"
                      }
                      onClick={() => {
                        onTabClick("toggleView", "gridview");
                      }}
                      type="button"
                    >
                      <i className="fas fa-th-large"></i>
                    </button>
                  </div>
                  <div className="nav-itemd-flex justify-content-center">
                    <button
                      onClick={() => {
                        onTabClick("toggleView", "tableview");
                      }}
                      type="button"
                      className={
                        tableview === true
                          ? "nav-link pb-3 bg-transparent active"
                          : "nav-link pb-3 bg-transparent"
                      }
                    >
                      <i className="fas fa-table"></i>
                    </button>
                  </div>
                </div>
              </div>
              <ul className="nav nav-tabs nav-tabs-tablet pt-1 justify-content-center justify-content-lg-start">
                <li className="nav-item">
                  <button
                    type="button"
                    onClick={() => {
                      onTabClick("toggleView", "all");
                    }}
                    className={
                      allRequests === true
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                  >
                    <span className="tag mb-1">{filterBy.length}</span>
                    <div>All</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    onClick={() => {
                      onTabClick("toggleView", "open");
                    }}
                    className={
                      openRequests === true
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                  >
                    <span className="tag mb-1">
                      {filterBy.filter((i) => i.jobStatus === "open").length}
                    </span>
                    <div>Open</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    onClick={() => {
                      onTabClick("toggleView", "active");
                    }}
                    className={
                      activeRequests === true
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                  >
                    <span className="tag mb-1">
                      {filterBy.filter((i) => i.jobStatus === "active").length +
                        filterBy.filter((i) => i.jobStatus === "Active").length}
                    </span>
                    <div>Active</div>
                  </button>
                </li>
                {getTenantType() !== 2 && (
                  <li className="nav-item">
                    <button
                      type="button"
                      onClick={() => {
                        onTabClick("toggleView", "expired");
                      }}
                      className={
                        closedRequests === true
                          ? "nav-link pb-3 mx-2 bg-transparent active"
                          : "nav-link pb-3 mx-2 bg-transparent"
                      }
                    >
                      <span className="tag mb-1">
                        {
                          filterBy.filter((i) => i.jobStatus === "expired")
                            .length
                        }
                      </span>
                      <div>Expired</div>
                    </button>
                  </li>
                )}
                <li className="nav-item">
                  <button
                    type="button"
                    onClick={() => {
                      onTabClick("toggleView", "Deactivate");
                    }}
                    className={
                      deletedRequests === true
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                  >
                    <span className="tag mb-1">
                      {
                        filterBy.filter((i) => i.jobStatus === "Deactivated")
                          .length
                      }
                    </span>
                    <div>Deactivate</div>
                  </button>
                </li>
              </ul>

              <div className="d-flex mb-2 justify-content-center justify-content-lg-start">
                <div class="dropdown-sort dd-sort">
                  <button
                    type="button"
                    className="btn btn-sm btn-text-accent"
                    onClick={() => setSortModal(true)}
                  >
                    <i className="fad fa-sort-down font-16 mr-1"></i> Sort /{" "}
                    {sortCount}
                  </button>
                  {sortModal && (
                    <OutsideClickHandler
                      onOutsideClick={() => setSortModal(false)}
                    >
                      <Sort
                        sortData={jobListData}
                        orderArray={orderList}
                        sortObjects={sortObject}
                        applySort={applySort}
                        setSortCount={setSortCount}
                        sortCount={sortCount}
                        setSortArray={setSortArray}
                        sortArray={sortArray}
                        sortingFor={
                          "Sort by Job Title, Type, and Location of Requests"
                        }
                      />
                    </OutsideClickHandler>
                  )}
                </div>

                <div className="dropdown position-relative">
                  <button
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Filter"
                    onClick={openFilter}
                    type="button"
                    className="btn btn-sm btn-text-accent w-auto"
                  >
                    <i className="fas fa-filter"></i>
                    Filter /
                    <span>
                      {" "}
                      {selectedTitles.length +
                        selectedTypes.length +
                        (daysLeftValue ? 1 : 0)}{" "}
                    </span>
                  </button>
                </div>
                {/* {getTenantType() === 1 && (
                  <button
                    type="button"
                    className="btn  btn-sm w-auto d-sm-block ml-3"
                    onClick={() => {
                      // setShowAddJobModal(true)
                      setAddJobNewModal(true);
                    }}
                    data-toggle="modal"
                    data-target="#NewRequestModal"
                  >
                    <i className="fas fa-plus mr-2"></i>
                    <span className="d-none d-sm-inline">New Request</span>
                  </button>
                )} */}
                {tenantType !== 2 && (
                  <button
                    type="button"
                    className="btn  btn-sm w-auto d-sm-block ml-3"
                    onClick={() => {
                      // setShowAddJobModal(true)
                      setAddJobNewModal(true);
                    }}
                    data-toggle="modal"
                    data-target="#NewRequestModal"
                  >
                    <i className="fas fa-plus mr-2"></i>
                    <span className="d-none d-sm-inline">New Job</span>
                  </button>
                )}
              </div>
            </div>
            {allRequests === true ? (
              gridview || matches ? (
                <RequestGridView
                  requestListData={filterData.sort((a, b) => {
                    return new Date(b.expiresOn) - new Date(a.expiresOn);
                  })}
                  component={props.component || " "}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : listview ? (
                <RequestListView
                  requestListData={filterData.sort((a, b) => {
                    return new Date(b.expiresOn) - new Date(a.expiresOn);
                  })}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : tableview ? (
                <RequestTableView
                  requestListData={filterData.sort((a, b) => {
                    return new Date(b.expiresOn) - new Date(a.expiresOn);
                  })}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : null
            ) : openRequests === true ? (
              gridview || matches ? (
                <RequestGridView
                  originalStatus={"open"}
                  requestListData={filterData}
                  component={props.component || " "}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : listview ? (
                <RequestListView
                  originalStatus={"open"}
                  requestListData={filterData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : tableview ? (
                <RequestTableView
                  originalStatus={"open"}
                  requestListData={filterData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : null
            ) : activeRequests === true ? (
              gridview || matches ? (
                <RequestGridView
                  originalStatus={"active"}
                  requestListData={filterData}
                  component={props.component || " "}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : listview ? (
                <RequestListView
                  originalStatus={"active"}
                  requestListData={filterData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : tableview ? (
                <RequestTableView
                  originalStatus={"active"}
                  requestListData={filterData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : null
            ) : closedRequests === true ? (
              gridview || matches ? (
                <RequestGridView
                  originalStatus={"expired"}
                  requestListData={filterData}
                  component={props.component || " "}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : listview ? (
                <RequestListView
                  originalStatus={"expired"}
                  requestListData={filterData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : tableview ? (
                <RequestTableView
                  originalStatus={"expired"}
                  requestListData={filterData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : null
            ) : deletedRequests === true ? (
              gridview || matches ? (
                <RequestGridView
                  originalStatus={"Deactivate"}
                  requestListData={filterData}
                  component={props.component || " "}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : listview ? (
                <RequestListView
                  originalStatus={"Deactivate"}
                  requestListData={filterData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : tableview ? (
                <RequestTableView
                  originalStatus={"Deactivate"}
                  requestListData={filterData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              ) : null
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        show={showAddJobModal}
        size="lg"
        className="modal fade"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-header" id="NewRequestModal">
          <h6 className="modal-title" id="exampleModalLabel">
            Create Request For Work
          </h6>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowAddJobModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <AddJob
            handleSuccess={closeAddJobModalPopup}
            getRequestList={getRequestList}
            requestValue={showAddJobModal}
          />
        </Modal.Body>
      </Modal>

      <Modal show={addJobNewModal} size="lg">
        <Modal.Header>
          <h6 class="">New Job</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setAddJobNewModal(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <AddJobNew
            setSuccessData={setSuccessData}
            getData={getRequestList}
            setSuccessFormOpen={setSuccessFormOpen}
            closePanel={() => setAddJobNewModal(false)}
          />
        </Modal.Body>
      </Modal>

      <Modal show={successFormOpen}>
        <div class="modal-content" style={{ width: "500px" }}>
          <div class="bl-modal-header">
            <h6 class="bl-modal-title">{"Job Created!"}</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setSuccessFormOpen(false)}
              class="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            <AddJobSuccess
              successData={successData}
              closeModal={() => setSuccessFormOpen(false)}
            />
          </Modal.Body>
        </div>
      </Modal>
      {filter && (
        <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
          <FilterJobs
            requestTitles={requestTitles}
            requestTypes={requestTypes}
            selectedTitles={selectedTitles}
            selectedTypes={selectedTypes}
            applyFilter={applyFilter}
            daysLeft={daysLeft}
            daysLeftValue={daysLeftValue}
            jobData={jobListData}
          />
        </OutsideClickHandler>
      )}
    </>
  );
}

export default RequestList;
