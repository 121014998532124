import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import Sort from "./Sort";
import _ from "lodash";
import { toast } from "react-toastify";

const SortIndex = (props) => {
  const {
    sort,
    setSort,
    sortData,
    orderArray,
    sortObjects,
    applySort,
    setSortCount,
    sortCount,
    setSortArray,
    sortArray,
    sortingFor,
    fetchData,
    currentPage,
    selectedData,
    sortType,
    setSorttype,
    sortApplied,
    setSortApplied,
    sortClearAll,
  } = props;

  const [id, setId] = useState(0);

  const handleSortValue = (e, i) => {
    let newArray = [...sortArray];
    newArray[i] = {
      ...newArray[i],
      [e.target.id]: e.target.value,
    };

    if (sortArray?.length > 0) {
      sortArray.map((item) => {
        sortObjects.includes(item);
      });
    }
    setSortArray(newArray);
    let array3 = sortObjects.filter(
      (entry1) => !newArray.some((entry2) => entry1.value === entry2.sortValue)
    );
    let tempArray = array3.map((item) => item.value);
    setSorttype(tempArray);
  };

  const apply = () => {
    // let invalidData = sortArray.filter((item) => item.sortValue === "");
    // let validData = sortArray.filter((item) => item.sortValue !== "");
    // console.log("sortArray----", sortArray);
    // if (invalidData.length > 0) {
    //   toast.error("Please fill all the fields");
    // } else {
    //   applySort(validData);
    //   setSortApplied(true);
    //   setSortArray(validData);
    //   setSort(false);
    // }

    const { validData, invalidData } = sortArray.reduce(
      (acc, item) => {
        if (item.sortValue === "") {
          acc.invalidData.push(item);
        } else {
          acc.validData.push(item);
        }
        return acc;
      },
      { validData: [], invalidData: [] }
    );

    if (invalidData.length > 0) {
      toast.error("Please fill all the fields");
    } else {
      applySort(validData);
      setSortApplied(true);
      setSortArray(validData);
      setSort(false);
    }
  };

  const addSortHandle = () => {
    const tempId = id + 1;
    const sortObj = {
      id: tempId,
      sortValue: "",
      order: "asc",
    };
    setSortArray([...sortArray, sortObj]);
    setSortCount(sortCount + 1);
    setId(tempId);
  };

  const deleteSort = (data) => {
    let tempArray = sortArray.filter((item) => item.id != data.id);
    setSortArray(tempArray);
    setSorttype([...sortType, data.sortValue]);
    sortCount === 1 && setSortApplied(false);
    setSortCount(sortCount - 1);
  };

  const handleOutsideClick = () => {
    setSort(false);
    let tempArray = sortArray?.filter((item) => item.sortValue !== "");
    setSortArray(tempArray);
    setSortCount(tempArray.length);
  };

  return (
    <>
      <div class="dropdown-sort dd-sort">
        <button
          type="button"
          onClick={() => setSort(true)}
          class="btn btn-sm w-auto btn-text-accent dd-sort"
        >
          <i
            aria-hidden="true"
            class="fad fa-sort-down font-16 dd-sort mr-1"
          ></i>
          <strong>
            Sort{" "}
            {sortApplied && !sort && (
              <>
                / <span class="dd-sort">{sortCount}</span>
              </>
            )}
          </strong>
        </button>
        {sort && (
          <OutsideClickHandler onOutsideClick={handleOutsideClick}>
            <Sort
              orderArray={orderArray}
              sortObjects={sortObjects}
              sortArray={sortArray}
              sortingFor={sortingFor}
              sortApplied={sortApplied}
              apply={apply}
              sortType={sortType}
              addSortHandle={addSortHandle}
              handleSortValue={handleSortValue}
              deleteSort={deleteSort}
              sortClearAll={sortClearAll}
            />
          </OutsideClickHandler>
        )}
      </div>
    </>
  );
};

export default SortIndex;
