import React from "react";
import { toast } from "react-toastify";

const Salary=(props)=>{
    return(<>
    <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-3">
              <div className="form-floating pl-1">
                <select
                  className="font-14 form-select"
                  name="minMaxCurrency"
                  onChange={props?.handleChange}
                >
                  <option value="" selected>
                    Select
                  </option>
                  {Object.keys(props?.currency_list).map((item, i) => (
                    <option
                      key={i}
                      selected={item === props?.addJobData.minMaxCurrency}
                      value={item}
                    >
                      {item}
                    </option>
                  ))}
                </select>
                <label>Currency</label>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-floating">
                <input
                  value={props?.addJobData.minimumPay}
                  type="number"
                  min="1"
                  className={
                    props?.isFormValid && props?.addJobData.minimumPay == ""
                      ? "form-control border border-danger"
                      : "form-control"
                  }
                  name="minimumPay"
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value) {
                      if (+value === 0) {
                        toast.error("Cannot Be Zero");
                      } else if (+value < 0 || +value === -0) {
                        toast.error("Negative numbers are not allowed");
                      } else {
                        props?.handleChange(e);
                      }
                    } else {
                      props?.handleChange(e);
                    }
                  }}
                  placeholder="US$"
                />
                <label>Minimum pay*</label>
                {props?.isFormValid && props?.addJobData.minimumPay == 0 && (
                  <small className="ml-2 validation">*Required field</small>
                )}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-floating">
                <input
                  value={props?.addJobData.maximumPay}
                  type="number"
                  min="1"
                  className={
                    props?.isFormValid && props?.addJobData.maximumPay == ""
                      ? "form-control border border-danger"
                      : "form-control"
                  }
                  name="maximumPay"
                  placeholder="US$"
                  onChange={(e) => {
                    if (e.target.value === "0") {
                      toast.error("Cannot Be Zero");
                    } else if (+e.target.value < 0) {
                      toast.error("Negative numbers are not allowed");
                    } else {
                      props?.handleChange(e);
                    }
                  }}
                />
                <label>Maximum pay*</label>
                {props?.isFormValid && props?.addJobData.maximumPay == 0 && (
                  <small className="ml-2 validation">*Required field</small>
                )}
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-floating">
                <select
                  disabled={
                    props?.addJobType.typeSelected && !props?.addJobType.fulltimeSelected
                    || props?.addJobType.typeSelected && props?.addJobType.fulltimeSelected
                  }
                  className="font-14 form-select"
                  name="minMaxPaymentType"
                  //onChange={props?.handleChange}
                >
                  {/* <option value="" selected>
                    Select
                  </option> */}
                  {(props.addJobType.typeSelected && props.addJobType.fulltimeSelected) && 
                  <option disabled
                    selected={props?.addJobData.minMaxPaymentType === "Annual"}
                    value="Annual"
                  >
                    Annual
                  </option>}
                  {(props.addJobType.typeSelected && !props.addJobType.fulltimeSelected) &&
                  <option disabled
                    selected={props?.addJobData.minMaxPaymentType === "Per hour"}
                    value="Per hour"
                  >
                    Per hour
                  </option>}
                </select>
                <label>Payment type</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
}

export default Salary