import React, { useEffect, useState } from "react";
import TopNavbar from "../../careers/TopNavBar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCandidateDetails } from "../../../../actions/candidate/getCandidateDetails";
import SideNavBarForCandidateModule from "../SideNavBarForCandidateModule";
import Loader from "../../../Loader/Loader";
import "react-tabs/style/react-tabs.css";
import PersonalInfo from "../../candidate/profile/home/PersonalInfo";
import AdditionalInfo from "../../candidate/profile/home/AdditionalInfo";
import Skills from "../../candidate/profile/home/Skills";
import SocialMedia from "../../candidate/profile/home/SocialMedia";
import Description from "../../candidate/profile/home/Description";
import Experience from "../../candidate/profile/home/Experience";
import Education from "../../candidate/profile/home/Education";
import Prefereneces from "../../candidate/profile/home/Preferences";
import Security from "../../candidate/profile/home/Security";
import Dropzone from "react-dropzone";
import LatestActivity from "./LatestActivity";
import Certifications from "./Certifications";
import Licenses from "./Licenses";
import { post } from "../../../../service/ApiService";
import { toast } from "react-toastify";
import axios from "axios";
import { browserName } from "react-device-detect";
import JobAlerts from "./JobAlerts";
import Communication from "./Communication";
import { getTenantType } from "../../../../service/AuthService";
import Footer from "../../../layout/Footer";
import AvatarUpload from "../../commoncomponents/fileupload/AvatarUpload";

const Index = () => {
  const [toggle, setToggle] = useState(false);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateDetails, setUpdateDetails] = useState(false);
  const dispatch = useDispatch();
  const [offers, setOffers] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem("token"));
  const [data, setData] = useState({});
  const [ip, setIp] = useState("");
  const toggleDiv = (type) => {
    setToggle(!toggle);
  };
  const [resumeFile, setResumeFile] = useState({ fileName: "", base64: "" });
  const [isProfile, setIsProfile] = useState(true);
  const [isLog, setIsLog] = useState(false);

  //profile pic upload
  const [imageData, setImageData] = useState({
    fileName: "",
    base64: "",
  });

  const { candidateData } = useSelector(
    (state) => ({
      candidateData: state.candidateDetailsReducer.candidateData,
    }),
    shallowEqual
  );

  const UploadProfileImage = async () => {
    try {
      let parameters = {
        ...imageData,
        userId: sessionStorage.getItem("userId"),
        role: "Candidate",
        oldProfilePath: candidateData?.profileImagePath,
      };
      const res = await post(
        "/common/contactinfo/saveProfileImage",
        parameters
      );
      if (res.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      toast.error(error.response.data.errMessage);
    }
  };

  useEffect(() => {
    if (imageData.base64 && imageData.fileName) {
      UploadProfileImage();
    }
  }, [imageData]);

  useEffect(() => {
    if (candidateData) {
      setImageData({
        ...imageData,
        base64: candidateData?.profileImagePath,
      });

      sessionStorage.setItem(
        "profileImagePath",
        candidateData?.profileImagePath
      );
    }
  }, [candidateData]);

  const onUploadHandler = (file) => {
    try {
      var re = /(?:\.([^.]+))?$/;
      var ext = re.exec(file[0].name)[1];

      if (ext === "pdf" || ext === "docx" || ext === "doc") {
        setValid(true);

        const { name } = file[0];
        const reader = new FileReader();

        reader.readAsDataURL(file[0]);

        reader.onload = (event) => {
          setResumeFile({ fileName: name, base64: event.target.result });
        };
      } else {
        setValid(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const uploadResume = async () => {
    try {
      let params = {
        ...candidateData,
        oldFileName: candidateData.fileName,
        fileName: resumeFile.fileName,
        base64: resumeFile.base64,
        activity: "Resume updated",
      };

      if (valid) {
        setLoading(true);
        const result = await post("/candidate/updatetalent", params);
        if (result.status == 200) {
          toast.success("Resume uploaded successfully");
          dispatch(
            getCandidateDetails({
              candidateId: sessionStorage.getItem("userId"),
            })
          );
          setLoading(false);
        }
      } else {
        toast.error("Please Upload resume first");
      }
    } catch (error) {
      setResumeFile({ fileName: "", base64: "" });
      // toast.error("Something went wrong, Please try again");
    }
  };
  useEffect(() => {
    valid && uploadResume();
    setValid(false);
  }, [resumeFile]);

  useEffect(() => {
    const payload = {
      candidateId: sessionStorage.getItem("userId"),
    };
    dispatch(getCandidateDetails(payload));
  }, []);

  const candidateDetails = async () => {
    const payload = {
      candidateId: sessionStorage.getItem("userId"),
    };
    setLoading(true);
    const res = await post("/candidate/getcandidateprofile", payload);
    let candidateData = res?.data;
    setData(candidateData);
    setResumeFile(candidateData);
    setOffers(
      candidateData?.dispositionData?.filter(
        (f) =>
          f.statusname === "Offered" ||
          f.statusname === "Hired" ||
          f.statusname === "Offer Rejected"
      )
    );
    setLoading(false);
  };
  useEffect(() => {
    candidateDetails();
  }, []);
  useEffect(() => {
    candidateDetails();
  }, [updateDetails]);
  // useEffect(() => {
  //   if (candidateData) {
  //     setData(candidateData);
  //     setOffers(
  //       candidateData?.dispositionData?.filter(
  //         (f) =>
  //           f.statusname === "Offered" ||
  //           f.statusname === "Hired" ||
  //           f.statusname === "Offer Rejected"
  //       )
  //     );
  //   }
  // }, [candidateData]);
  // useEffect(() => {}, [data]);
  // const fetchCandidateDetails = () => {
  //   const payload = {
  //     candidateId: sessionStorage.getItem("userId"),
  //   };

  //   dispatch(getCandidateDetails(payload));
  // };

  const onTabClick = (type) => {
    if (type === "profile") {
      setIsProfile(true);
      setIsLog(false);
    }
    if (type === "log") {
      setIsProfile(false);
      setIsLog(true);
    }
  };
  const getData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res.data.ip);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      {loading && <Loader />}
      {Object.keys(data).length > 0 && (
        <body
          className={
            toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
          }
        >
          <div className="body-decorator body-decorator-top"></div>
          <div className="body-decorator body-decorator-bottom"></div>
          <TopNavbar
            isLoggedIn={isLoggedIn}
            setIsLoggedIn={setIsLoggedIn}
            handleToggale={toggleDiv}
            tab={new URLSearchParams(window.location.search).get("tab")}
            isFrom="editTalent"
            candidateData={data}
          />
          <SideNavBarForCandidateModule
            handleToggale={toggleDiv}
            component={"Profile"}
            candidateData={data}
          />
          <div id="Content">
            <div className="section section-sm" style={{ width: "100%" }}>
              {/* <div className="hero">
                <div className="section pt-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-12">
                        <div className="d-flex flex-column align-items-center">
                          <div className="d-flex align-items-center mb-3">
                            <button
                              type="button"
                              className="btn btn-text-accent avatar avatar-lg  avatar-edit"
                            >
                              <Avatar
                                size="55"
                                name={`${data?.firstName} ${data?.lastName}`}
                                round="80px"
                              />
                            </button>

                            <span className="">
                              <h6
                                className="font-white"
                                style={{ marginBottom: "-2px" }}
                              >{`${data?.firstName} ${data?.lastName}`}</h6>

                              <span style={{}}>
                                <small className="font-white">
                                  {data?.designation}
                                </small>
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              <AvatarUpload
                imageData={imageData}
                setImageData={setImageData}
                UploadProfileImage={UploadProfileImage}
                isFrom="CandidateProfile"
                candidateName={`${data?.firstName} ${data?.lastName}`}
                candidateDesignation={data?.designation}
              />
              {/* <Tabs style={{ borderBottom: "revert" }}>
              <div className="translate-up-100">
                <div className="d-flex justify-content-center">
                  <TabList>
                    <ul className="nav nav-tabs nav-tabs-white mt-3 flex-wrap flex-nowrap-md justify-content-center">
                      <Tab
                        onClick={() => {}}
                        className={"nav-link pb-3 active"}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Account Details{" "}
                      </Tab>
                    </ul>
                  </TabList>
                </div>
              </div>
            </Tabs> */}

              {/* Candidate info */}
              {Object.keys(data).length > 0 && (
                <div
                  className="container"
                  style={{ width: "1000px", marginTop: "-78px" }}
                >
                  <div className="card row justify-content-center">
                    <div className="card-body mx-5">
                      <div
                        className="d-flex flex-column gap-2 pb-5"
                        style={{ marginLeft: "-43px", marginRight: "54px" }}
                      >
                        {/* Nav */}
                        <div className="d-flex justify-content-center mt-3">
                          <ul class="nav nav-tabs ">
                            <li class="nav-item">
                              {/* <button
                              // onClick={() => {
                              //   // onTabClick("profile");
                              // }}
                              type="button"
                              // class={
                              //   regOverview
                              //     ? "nav-link pb-3 active"
                              //     : "nav-link pb-3"
                              // }
                            > */}{" "}
                              <div
                                class={
                                  isProfile
                                    ? "nav-link pb-3 active"
                                    : "nav-link pb-3"
                                }
                                onClick={() => onTabClick("profile")}
                              >
                                Profile
                              </div>
                              {/* </button> */}
                            </li>{" "}
                            <li class="nav-item ml-2">
                              {/* <button
                              onClick={() => {
                                // onTabClick("loginOverview");
                              }}
                              type="button"
                              // class={loginOverview ? "nav-link pb-3 active" : "nav-link pb-3"}
                            > */}
                              {/* <div
                                class={
                                  isLog
                                    ? "nav-link pb-3 active"
                                    : "nav-link pb-3"
                                }
                                onClick={() => onTabClick("log")}
                              >
                                My Activities
                              </div> */}
                              {/* </button> */}
                            </li>
                            {/* <li class="nav-item">
                            <button
                              onClick={() => {
                                // onTabClick("recruitmentPipe");
                              }}
                              type="button"
                              // class={
                              //   recruitmentPipe ? "nav-link pb-3 active" : "nav-link pb-3"
                              // }
                            >
                              <div>Recruitment Pipeline</div>
                            </button>
                          </li>
                          <li class="nav-item">
                            <button
                              onClick={() => {
                                // onTabClick("users");
                              }}
                              type="button"
                              // class={users ? "nav-link pb-3 active" : "nav-link pb-3"}
                            >
                              <div>Users</div>
                            </button>
                          </li> */}
                          </ul>
                        </div>

                        {/* Nav end */}
                        {isProfile && (
                          <div>
                            <PersonalInfo
                              data={data}
                              ip={ip}
                              browserName={browserName}
                              updateDetails={updateDetails}
                              setUpdateDetails={setUpdateDetails}
                            />
                            <AdditionalInfo
                              data={data}
                              ip={ip}
                              browserName={browserName}
                              updateDetails={updateDetails}
                              setUpdateDetails={setUpdateDetails}
                            />
                            <Skills
                              data={data}
                              ip={ip}
                              browserName={browserName}
                              updateDetails={updateDetails}
                              setUpdateDetails={setUpdateDetails}
                            />
                            <SocialMedia
                              data={data}
                              ip={ip}
                              browserName={browserName}
                              updateDetails={updateDetails}
                              setUpdateDetails={setUpdateDetails}
                            />
                            <Description
                              data={data}
                              ip={ip}
                              browserName={browserName}
                              updateDetails={updateDetails}
                              setUpdateDetails={setUpdateDetails}
                            />
                            <Experience
                              data={data}
                              ip={ip}
                              browserName={browserName}
                              updateDetails={updateDetails}
                              setUpdateDetails={setUpdateDetails}
                            />
                            <Education
                              data={data}
                              ip={ip}
                              browserName={browserName}
                              updateDetails={updateDetails}
                              setUpdateDetails={setUpdateDetails}
                            />
                            {/* Certification */}
                            {/* <div class="card card-lg mb-2">
                        <div class="card-body">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-0">Certifications</h6>{" "}
                            <button type="button" class="btn btn-text">
                              <i
                                aria-hidden="true"
                                class="fas fa-pencil-alt mr-1"
                              ></i>
                              Edit
                            </button>
                          </div>{" "}
                          <hr />{" "}
                          <div>
                            <div class="d-flex">
                              <div class="mr-3 d-flex flex-column align-items-center">
                                <i
                                  aria-hidden="true"
                                  class="fad fa-award fa-fw mt-1"
                                ></i>
                              </div>{" "}
                              <div>
                                <p class="mb-1">
                                  <strong>Node js</strong>
                                </p>{" "}
                                <p>
                                  <small>June 2023</small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                            <Certifications
                              data={data}
                              ip={ip}
                              browserName={browserName}
                              updateDetails={updateDetails}
                              setUpdateDetails={setUpdateDetails}
                            />
                            {/* License */}
                            <Licenses
                              data={data}
                              ip={ip}
                              browserName={browserName}
                              updateDetails={updateDetails}
                              setUpdateDetails={setUpdateDetails}
                            />
                            {/* <JobAlerts
                              data={data}
                              ip={ip}
                              browserName={browserName}
                            /> */}
                            {/* <div class="card card-lg mb-2">
                        <div class="card-body">
                          <div class="d-flex align-items-center justify-content-between">
                            <h6 class="mb-0">Licenses</h6>{" "}
                            <button type="button" class="btn btn-text">
                              <i
                                aria-hidden="true"
                                class="fas fa-pencil-alt mr-1"
                              ></i>
                              Edit
                            </button>
                          </div>{" "}
                          <hr />{" "}
                          <div>
                            <div class="d-flex">
                              <div class="mr-3 d-flex flex-column align-items-center">
                                <i
                                  aria-hidden="true"
                                  class="fad fa-award fa-fw mt-1"
                                ></i>
                              </div>{" "}
                              <div>
                                <p class="mb-1">
                                  <strong>Azure Beginner License</strong>
                                </p>{" "}
                                <p>
                                  <small>June 2023</small>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> */}
                            {/* <NoticePeriod data={data} /> */}
                            {/* Latest */}
                            {/* <LatestActivity
                            data={data}
                            ip={ip}
                            browserName={browserName}
                          /> */}
                            <Prefereneces
                              data={data}
                              ip={ip}
                              browserName={browserName}
                              updateDetails={updateDetails}
                              setUpdateDetails={setUpdateDetails}
                            />
                            <Communication
                              data={data}
                              ip={ip}
                              browserName={browserName}
                            />
                            {/* <Notifications
                              data={data}
                              ip={ip}
                              browserName={browserName}
                            /> */}
                            {/* resume */}
                            <div class="card card-lg mb-2">
                              <div class="card-body">
                                <div class="d-flex align-items-center justify-content-between">
                                  <h6 class="mb-0">Resume</h6>{" "}
                                  <Dropzone onDrop={onUploadHandler}>
                                    {({ getRootProps, getInputProps }) => (
                                      <div
                                        {...getRootProps()}
                                        // resumeFile && {
                                        //   onClick: (event) =>
                                        //     event.stopPropagation(),
                                        // }
                                        className="ml-auto"
                                      >
                                        <button
                                          type="button"
                                          // disabled={resumeFile}
                                          className=" btn btn-text-accent mr-1"
                                        >
                                          <i
                                            aria-hidden="true"
                                            className="far fa-cloud-upload mr-1 fa-fw"
                                          ></i>
                                          Upload
                                        </button>
                                        <input
                                          type="file"
                                          {...getInputProps()}
                                          accept=".docx, .pdf, application/msword"
                                        />
                                      </div>
                                    )}
                                  </Dropzone>
                                </div>{" "}
                                <hr />{" "}
                                <div>
                                  <div class="mb-1 pe-none">
                                    <a href="#">{resumeFile?.fileName}</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <Security data={data} />
                          </div>
                        )}
                        {isLog && <LatestActivity data={data} />}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {getTenantType() == 1 && <Footer />}
          </div>
        </body>
      )}
    </>
  );
};

export default Index;
