import React, { useState, useRef } from "react";

import Modal from "react-bootstrap/Modal";
import ActivateUser from "./ActivateUser";
import DeactiveUser from "./DeactiveUser";
import EditUser from "../dashboard/EditUser";
import AssignUsers from "./AssignUsers";
import useOutsideClick from "../../function/useOutsideClick";
import { countryObj } from "../../../constants/Constants";
import { parsePhoneNumber } from "libphonenumber-js";
function UserDetailsPopUp(props) {
  const [openDropDownDiv, setOpenDropDownDiv] = useState(false);
  const [editUser, setEditUser] = useState(false);
  const [DeleteUserValue, setDeleteUserButton] = useState(false);
  const [activateUserValue, setActivateUserValue] = useState(false);
  const [selecteduser, setSelectedUser] = useState();
  const [selecteduserid, setSelectedUserid] = useState();
  const [assignUsers, setassignUsers] = useState(false);
  const [assignTenantData, setAssignTenantData] = useState({});

  const ref = useRef();
  useOutsideClick(ref, () => setOpenDropDownDiv(false));

  const close = () => {
    props.closeModal(true);
  };

  const openDropDown = () => {
    openDropDownDiv ? setOpenDropDownDiv(false) : setOpenDropDownDiv(true);
  };

  const openuserEdit = () => {
    setEditUser(true);
  };

  const closeuserEdit = () => {
    setEditUser(false);
  };

  const DeleteUserFun = (tenantName, userid) => {
    setSelectedUser(tenantName);
    setSelectedUserid(userid);
    setDeleteUserButton(true);
  };

  const EnableuserFun = (tenantName, userid) => {
    setSelectedUser(tenantName);
    setSelectedUserid(userid);
    setActivateUserValue(true);
  };

  const DeleteUserClose = (e) => {
    setDeleteUserButton(false);
  };

  const EnableUserClose = (e) => {
    setActivateUserValue(false);
  };

  const assignUsersFun = (e, type) => {
    setAssignTenantData(type);
    setassignUsers(true);
  };

  const assignUsersClose = (e) => {
    setassignUsers(false);
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header">
          <div className="px-3 py-2">
            <button type="button" onClick={close} className="btn btn-icon">
              <i aria-hidden="true" className="fal fa-times"></i>
            </button>
          </div>
          <div className="px-3 mb-3">
            <div className="d-flex align-items-start flex-wrap-reverse">
              <div className="d-flex">
                <div className="ml-3">
                  <h5 className="mr-2 mb-2">{props.employee.name} </h5>
                  <div className="mb-2">
                    {props.employee.status === "Activate" ? (
                      <span className="tag tag-green2">
                        {props.employee.status}
                      </span>
                    ) : props.employee.status === "Deactive" ? (
                      <span className="tag tag-red2">Deactivate</span>
                    ) : props.employee.status === "Deactivated" ? (
                      <span className="tag tag-red2">
                        {props.employee.status}
                      </span>
                    ) : (
                      <span className="tag tag-green2">
                        {props.employee.status}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="ml-auto d-flex align-items-center">
                <div
                  className={
                    props.employee.status === "Active"
                      ? "btn-group btn-group-sm btn-split"
                      : ""
                  }
                  ref={ref}
                >
                  {props.employee.status === "Active" ? (
                    <>
                      <button
                        type="button"
                        className="btn btn-sm"
                        onClick={openuserEdit}
                      >
                        Edit
                      </button>

                      <button
                        type="button"
                        onClick={openDropDown}
                        className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="sr-only">Toggle Dropdown</span>
                      </button>
                    </>
                  ) : (
                    <button
                      class="btn btn-sm"
                      onClick={(e) => {
                        EnableuserFun(
                          e,
                          props.employee.tenantname,
                          props.employee.userid
                        );
                      }}
                    >
                      <i class="fa fa-key mr-2" aria-hidden="true"></i> Activate
                    </button>
                  )}
                  {openDropDownDiv && (
                    <div
                      className="dropdown-menu dropdown-menu-right"
                      style={{ display: "revert", right: "0px", top: "30px" }}
                    >
                      {/* {props.employee.type !== "SalesAdmin" && (
                        <button
                          type="button"
                          class="dropdown-item"
                          onClick={(e) => assignUsersFun(e, props.employee)}
                        >
                          Assignnn
                        </button>
                      )} */}
                      {props.employee.status === "Active" ? (
                        <button
                          type="button"
                          class="dropdown-item"
                          onClick={(e) => {
                            DeleteUserFun(
                              e,
                              props.employee.tenantname,
                              props.employee.userid
                            );
                          }}
                        >
                          Deactivate
                        </button>
                      ) : (
                        <button
                          type="button"
                          class="dropdown-item"
                          onClick={(e) => {
                            EnableuserFun(
                              e,
                              props.employee.tenantname,
                              props.employee.userid
                            );
                          }}
                        >
                          Activate
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="view-panel-body">
          <div className="mb-2">
            <button type="button" className="btn btn-collapsible">
              <i className="fal fa-angle-down fa-fw" aria-hidden="true"></i>
              <span>
                <small>General</small>
              </span>
            </button>
          </div>

          <div className="mb-4 ml-4">
            {props.employee.name ? (
              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fas  fa-fw fa-building" aria-hidden="true"></i>
                </div>
                <div className="ml-2">
                  <p className="mb-0">{props.employee.name} </p>
                  <p className="mb-0">
                    <small>Name</small>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {props.employee.mobilePhone ? (
              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fas fa-fw fa-envelope" aria-hidden="true"></i>
                </div>
                <div className="ml-2">
                  <p className="mb-0">
                    {/* {props.employee.mobilePhone} */}

                    {parsePhoneNumber(
                      props.employee.mobilePhone[0] === "+"
                        ? props.employee.mobilePhone
                        : `+${props.employee.mobilePhone}`
                    )?.formatInternational()}
                  </p>
                  <p className="mb-0">
                    <small>Contact no</small>
                  </p>
                </div>
              </div>
            ) : (
              " "
            )}

            {props.employee.email ? (
              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fas fa-fw fa-envelope" aria-hidden="true"></i>
                </div>
                <div className="ml-2">
                  <p className="mb-0">{props.employee.email} </p>
                  <p className="mb-0">
                    <small>Email</small>
                  </p>
                </div>
              </div>
            ) : (
              " "
            )}

            {props.employee.type ? (
              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fas  fa-fw fa-shapes" aria-hidden="true"></i>
                </div>
                <div className="ml-2">
                  <p className="mb-0">
                    {props.employee.type === "CommunityManager"
                      ? "Marketplace Manager"
                      : props.employee.type === "High5Coordinator"
                      ? "Customer Success Manager"
                      : props.employee.type === "CustomerSuccess"
                      ? "High5 Admin"
                      : props.employee.type}{" "}
                  </p>
                  <p className="mb-0">
                    <small>User Type</small>
                  </p>
                </div>
              </div>
            ) : (
              " "
            )}
            {(props.employee.city || props.employee.country) && (
              <div className="d-flex mb-2 pl-1">
                <div>
                  <i
                    className="fad fa-fw fa-map-marked-alt"
                    aria-hidden="true"
                  ></i>
                </div>
                <div className="ml-2">
                  <p className="mb-0">
                    {props.employee.city && props.employee.city + ", "}
                    {props.employee.country && props.employee.country}{" "}
                  </p>
                  <p className="mb-0">
                    <small>Location</small>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <Modal show={editUser} onHide={props.closeEditPopUp} size="lg">
          <EditUser
            closeAdd={closeuserEdit}
            userlist={props.employee}
            getusersData={props.getusersData}
          />
        </Modal>

        <Modal
          show={DeleteUserValue === true}
          onHide={DeleteUserClose}
          size="delete"
        >
          <div class="bl-modal-header">
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={DeleteUserClose}
              class="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            <DeactiveUser
              handleSuccess={DeleteUserClose}
              employeeDelete={"employeedelete"}
              employee={props.employee}
              users={"users"}
              getusersData={props.getusersData}
              parent={props.parent}
              setopenUserPopUP={props.setopenUserPopUP}
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={activateUserValue === true}
          onHide={EnableUserClose}
          size="active"
        >
          <div class="bl-modal-header">
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={EnableUserClose}
              class="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            <ActivateUser
              handleSuccess={EnableUserClose}
              employeeDelete={"employeedelete"}
              employee={props.employee}
              users={"users"}
              getusersData={props.getusersData}
              parent={props.parent}
              setopenUserPopUP={props.setopenUserPopUP}
            />
          </Modal.Body>
        </Modal>

        <Modal show={assignUsers}>
          <AssignUsers
            handleClose={assignUsersClose}
            userData={assignTenantData}
          />
        </Modal>
      </div>
    </>
  );
}
export default UserDetailsPopUp;
