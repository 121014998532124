import React, { useEffect, useState } from "react";
import SelectTemplate from "./SelectTemplate";
import { getUserTenant } from "../../../../../service/AuthService";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getJobTemplateList } from "../../../../../actions/job/JobTemplate";
import Loader from "../../../../Loader/Loader";
import { getUserId } from "../../../../../service/AuthService";

const CreateJobWithTemplate = (props) => {
  const dispatch = useDispatch();
  const userId = getUserId();
  const [publicTemplates, setPublicTemplates] = useState(true);
  const [userTemplates, setUserTemplates] = useState(false);
  const [templateDetails, setTemplateDetails] = useState({});
  const [category, setCategory] = useState("all");
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const [tabData, setTabData] = useState([]);
  const [catTabData, setCatTabData] = useState([]);

  const [publicTemplateData, setPublicTemplateData] = useState([]);
  const [userTemplateData, setUserTemplateData] = useState([]);

  const { jobTemplateListLoading, jobTemplateList } = useSelector(
    (state) => ({
      jobTemplateList: state.jobTemplate.jobTemplateList,
      jobTemplateListLoading: state.jobTemplate.jobTemplateListLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getJobTemplateList());
    // eslint-disable-next-line
  }, []);

  const handleSearch = (value) => {
    setSearchValue(value);
    setFilteredData(
      tabData
        .filter((item) =>
          category === "all" ? item : item.category === category
        )
        .filter((item) =>
          item.templateName.toLowerCase().includes(value.toLowerCase())
        )
    );
  };

  const handleSelectCategory = (category) => {
    let tempData = [];
    if (category === "all" && publicTemplates) {
      tempData = publicTemplateData;
    } else if (category === "all" && userTemplates) {
      tempData = userTemplateData;
    } else {
      if (publicTemplates) {
        tempData = publicTemplateData.filter(
          (item) => item.category === category
        );
      } else {
        tempData = userTemplateData.filter(
          (item) => item.category === category
        );
      }
    }
    setFilteredData(tempData);
  };

  useEffect(() => {
    let pubData = jobTemplateList.filter(
      (x) => String(x.createdBy) !== String(userId) && x.status === "Active"
    );
    let userData = jobTemplateList.filter(
      (x) => String(x.createdBy) === String(userId) && x.status == "Active"
    );
    setFilteredData(pubData);
    setTabData(pubData);
    setPublicTemplateData(pubData);
    setUserTemplateData(userData);
    // eslint-disable-next-line
  }, [jobTemplateList]);

  const onClickTab = (value) => {
    let pubData = jobTemplateList.filter(
      (x) => String(x.createdBy) !== String(userId) && x.status == "Active"
    );
    let userData = jobTemplateList.filter(
      (x) => String(x.createdBy) === String(userId) && x.status == "Active"
    );

    if (value === "public") {
      setFilteredData(pubData);
      setTabData(pubData);
    }
    if (value === "user") {
      setFilteredData(userData);
      setTabData(userData);
    }
  };

  return (
    <>
      {jobTemplateListLoading && <Loader />}
      <div className="m-3">
        <div className="m-2">
          {props?.isFrom !== "SubCommunity" ? (
            <label>Choose a Template</label>
          ) : (
            <h5>Choose a Template</h5>
          )}
          <div className="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end mb-3 border-bottom-gray2">
            {props?.isFrom !== "SubCommunity" && (
              <ul className="nav nav-tabs nav-tabs-tablet pt-1 w-100 justify-content-center">
                <li className="nav-item">
                  <button
                    type="button"
                    onClick={() => {
                      setPublicTemplates(true);
                      setUserTemplates(false);
                      onClickTab("public");
                    }}
                    className={
                      publicTemplates
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                  >
                    <span className="tag mb-1">
                      {publicTemplateData?.length}
                    </span>
                    <div>Public Templates</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    onClick={() => {
                      setPublicTemplates(false);
                      setUserTemplates(true);
                      onClickTab("user");
                    }}
                    className={
                      userTemplates
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                  >
                    <span className="tag mb-1">{userTemplateData?.length}</span>
                    <div>My Templates</div>
                  </button>
                </li>
              </ul>
            )}
          </div>
          <div className="p-3">
            <div className="mt-2 d-flex align-items-center justify-content-between flex-wrap">
              <div className="col-lg-5 col-md-4 col-sm-12 col-12">
                <select
                  className="form-select mb-2 font-14"
                  onChange={(e) => handleSelectCategory(e.target.value)}
                  defaultValue={"all"}
                >
                  <option value="all">All</option>
                  {[...new Set(tabData.map((item) => item.category))]?.map(
                    (item, index) => (
                      <option
                        key={index}
                        selected={item === category}
                        value={item}
                      >
                        {item}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-12">
                <input
                  onChange={(e) => handleSearch(e.target.value)}
                  type="text"
                  className="form-control small font-14"
                  placeholder="Search Templates"
                />
              </div>
            </div>
          </div>

          <SelectTemplate
            data={filteredData?.filter((item) =>
              category === "all" ? item : item.templateCategory === category
            )}
            pageLimit={5}
            dataLimit={25}
            searchData={filteredData?.filter((item) =>
              item.templateName
                .toLowerCase()
                .includes(searchValue.trim().toLowerCase())
            )}
            openSidePanel={(item) => setTemplateDetails(item)}
            category={category}
            searchValue={searchValue}
            totalCount={filteredData.length}
            userCount={filteredData.length}
            publicTemplates={filteredData}
            templateDetail={templateDetails}
            setTemplateDetails={setTemplateDetails}
            setChooseTemplateDetails={props?.setChooseTemplateDetails}
            isFrom={props?.isFrom}
            setCreateWithTemplate={props?.setCreateWithTemplate}
            setCreateWithTemplateSCForm={props?.setCreateWithTemplateSCForm}
            setPoolData={props?.setPoolData}
          />
        </div>
      </div>
    </>
  );
};

export default CreateJobWithTemplate;
