import React, { useState, useEffect } from "react";
import buttonLoader from "../../../images/button-loader.gif";
import ApplyPopUp from "./ApplyPopUp";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import {
  countryObj,
  countryPhoneCode,
  High5DarkIcon,
} from "../../../constants/Constants";
import { post } from "../../../service/ApiService";
import ApplySuccess from "./ApplySuccess";
import { toast } from "react-toastify";
import QandAResponse from "../../common/Jobs/ViewTalentDetails/QandAResponse";
import JobDetails from "../../modal/addjob/JobDetails";

function CandidateRequestDetails({ job, closeModal, type, details }) {
  const [generaljob, setGeneraljob] = useState(false);
  const [description, setDescription] = useState(false);
  const [weightage, setWeightage] = useState(false);
  const [primarySkills, setPrimarySkills] = useState(false);
  const [secondarySkills, setSecondarySkills] = useState(false);
  const [education, setEducation] = useState(false);
  const [certificates, setCertificates] = useState(false);
  const [industry, setIndustry] = useState(false);
  const [workHours, setWorkHours] = useState(false);
  const [travel, setTravel] = useState(false);
  const [drugTest, setDrugTest] = useState(false);
  const [backgroundCheck, setBackgroundCheck] = useState(false);
  const [securityClearance, setSecurityClearance] = useState(false);
  const [openSharePopUp, setOpenSharePopUP] = useState(false);
  const [favoriteClicked, setFavoriteClicked] = useState(false);
  const [addedToFavorite, setAddedToFavorite] = useState(false);
  const [approving, setApporving] = useState(false);
  const [approved, setApproved] = useState(false);
  const [discarding, setDiscarding] = useState(false);
  const [discarded, setDiscarded] = useState(false);
  const [applyPopUP, setapplyPopUp] = useState(false);
  const [applySuccess, setApplySuccess] = useState(false);
  const [reason, setReason] = useState("");
  const [QandA, setQandA] = useState(false);
  const [displayResume, setDisplayResume] = useState(false);

  const onCollapsibleClick = (category) => {
    if (category === "generaljob") {
      setGeneraljob(!generaljob);
    }
    if (category === "description") {
      setDescription(!description);
    }
    if (category === "primarySkills") {
      setPrimarySkills(!primarySkills);
    }
    if (category === "education") {
      setEducation(!education);
    }
    if (category === "certificates") {
      setCertificates(!certificates);
    }
    if (category === "industry") {
      setIndustry(!industry);
    }
    if (category === "workHours") {
      setWorkHours(!workHours);
    }
    if (category === "drugTest") {
      setDrugTest(!drugTest);
    }
    if (category === "backgroundCheck") {
      setBackgroundCheck(!backgroundCheck);
    }
    if (category === "securityClearance") {
      setSecurityClearance(!securityClearance);
    }
    if (category === "secondarySkills") {
      setSecondarySkills(!secondarySkills);
    }
    if (category === "travel") {
      setTravel(!travel);
    }
  };

  const openShare = () => {
    if (openSharePopUp === false) {
      setOpenSharePopUP(true);
    } else {
      setOpenSharePopUP(false);
    }
  };

  const approve = () => {
    setApporving(true);
    setTimeout(function() {
      setApproved(true);
      setApporving(false);
    }, 3000);
    setapplyPopUp(true);
  };

  const applyclosePopUP = () => {
    setapplyPopUp(false);
  };

  const calculateExperience = [
    { name: "Intern", range: [0] },
    { name: "1-3 years", range: [1, 2] },
    { name: "3-5 years", range: [3, 4] },
    { name: "5-10 years", range: [5, 6, 7, 8, 9] },
    { name: "10+ years", range: [] },
  ];

  const findRange = (years, months) => {
    let totalMonths = Number(years) * 12 + Number(months);
    if (totalMonths >= 120) {
      return "10+ years";
    } else if (totalMonths < 12) {
      if (totalMonths === 0) {
        return "Intern";
      } else {
        return "Less than one year";
      }
    } else {
      let yearsExperience = Math.floor(totalMonths / 12);
      let finalValue = calculateExperience.find((i) =>
        i.range.includes(yearsExperience)
      ).name;
      return finalValue;
    }
  };

  const checkEligiblity = () => {
    const weightage =
      typeof job.weightage === "string"
        ? JSON.parse(job.weightage)
        : job.weightage;
    let user = { ...details };
    const {
      primarySkills,
      secondarySkills,
      jobTitle,
      education,
      experienceLevel,
      industries,
      location,
    } = weightage;
    let condition = true;
    let unmatched = {
      primarySkills: false,
      secondarySkills: false,
      jobTitle: false,
      education: false,
      experienceLevel: false,
      industries: false,
      location: false,
    };

    if (primarySkills && primarySkills.length > 0) {
      if (!primarySkills.every((item) => user.primarySkills.includes(item))) {
        condition = false;
        unmatched = { ...unmatched, primarySkills: true };
      }
    }

    if (secondarySkills && secondarySkills.length > 0) {
      if (
        !secondarySkills.every((item) => user.secondarySkills.includes(item))
      ) {
        condition = false;
        unmatched = { ...unmatched, secondarySkills: true };
      }
    }

    if (industries && industries.length > 0) {
      if (!industries.every((item) => user.skillSet.includes(item))) {
        condition = false;
        unmatched = { ...unmatched, industries: true };
      }
    }

    if (education && education.length > 0) {
      if (
        !user.education
          .map((item) => item.educationType.toLowerCase())
          .includes(education[0].toLowerCase())
      ) {
        condition = false;
        unmatched = { ...unmatched, education: true };
      }
    }

    if (location) {
      if (job.isRemote) {
        if (!user.preferedLocations.includes("Remote")) {
          condition = false;
          unmatched = { ...unmatched, location: true };
        }
      } else {
        if (
          ![user.addressCity, ...user.preferedLocations]
            .map((i) => i.toLowerCase())
            .includes(job.location.city.toLowerCase())
        ) {
          condition = false;
          unmatched = { ...unmatched, location: true };
        }
      }
    }

    if (jobTitle) {
      if (
        user.currentJobTitle?.trim().toLowerCase() !==
        job.jobTitle.trim().toLowerCase()
      ) {
        condition = false;
        unmatched = { ...unmatched, jobTitle: true };
      }
    }

    if (experienceLevel) {
      if (
        findRange(user.experienceYear, user.experienceMonth) !==
        job.experienceLevel
      ) {
        condition = false;
        unmatched = { ...unmatched, experienceLevel: true };
      }
    }

    return condition;
  };

  const handleApply = () => {
    if (job.weightage) {
      let condition = checkEligiblity();
      if (condition) {
        setapplyPopUp(true);
      } else {
        toast.error("You are not eligible to apply for this job");
      }
    } else {
      setapplyPopUp(true);
    }
  };

  const applyJob = (comments) => {
    setReason(comments);
    setapplyPopUp(false);
    setApplySuccess(true);
  };

  const addToFavorite = async (value) => {
    setFavoriteClicked(true);
    try {
      const res = await post("/CandidateDashboard/postFavorited", {
        favorited: value,
        id: job.id,
      });
      if (res.status === 200) {
        setFavoriteClicked(false);
        setAddedToFavorite(true);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      toast.error("Some Error Occurred. Please Try Again");
      setFavoriteClicked(false);
    }
  };

  const discard = async () => {
    setDiscarding(true);
    try {
      const res = await post("/CandidateJobApply/selfJobDiscardByCandidate", {
        id: job.id,
      });
      if (res.status === 200) {
        setDiscarded(true);
        setDiscarding(false);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      toast.error("Some Error Occurred. Please Try Again");
      setDiscarding(false);
    }
  };

  const close = () => {
    closeModal(true);
  };

  return (
    <>
      <div class="view-panel show">
        <div class="view-panel-header">
          <div class="p-relative">
            <div class="px-3 py-2 d-flex">
              <button onClick={close} type="button" class="btn btn-icon">
                <i class="fal fa-times" aria-hidden="true"></i>
              </button>
              {(type === "favorited" || type === "applied") && (
                <div class="px-1 py-2text-center font-regular p-relative ml-auto">
                  <small>
                    Job {type === "favorited" ? "Favorited" : "Applied"} -
                    &nbsp;
                    {moment(new Date(job.updatedDate), "YYYYMMDD").fromNow()}
                  </small>
                </div>
              )}
            </div>
          </div>
          <div class="px-3 mb-3">
            <div class="d-flex align-items-start flex-wrap-reverse">
              <div class="d-flex ">
                <div class="ml-3">
                  <h5 class="mr-2 mb-1">{job.jobTitle}</h5>
                  <div class="mb-2">
                    {job.fullText.location?.city &&
                    job.fullText.location?.state &&
                    job.fullText.location?.country ? (
                      <span class="mr-2">
                        {job.jobType} / {job.fullText.location?.city} ,{" "}
                        {job.fullText.location?.state} ,{" "}
                        {job.fullText.location?.country}
                      </span>
                    ) : (
                      <span className="mr-2">{"Remote"}</span>
                    )}
                  </div>
                  <div>
                    {type === "rtr" && (
                      <span class="tag tag-coral font-bold">Approval</span>
                    )}
                    {job.status === "hot" && (
                      <span class="tag tag-orange1 font-bold ">
                        <i class="fad fa-flame mr-1"></i>Hot
                      </span>
                    )}
                    {type === "favorited" && (
                      <span class="tag tag-purple1 font-bold ">
                        <i class="fad fa-heart mr-1"></i>Favorited
                      </span>
                    )}
                    {type === "applied" && (
                      <span class="tag tag-green1 font-bold ">Applied</span>
                    )}
                    {job.status === "ScheduleInterview" && (
                      <span class="tag tag-orange1">
                        <strong>Job Interview</strong>
                      </span>
                    )}

                    {type === "matched" && (
                      <span class="tag tag-coral d-inline-flex mr-2">
                        <img width="16px" src={High5DarkIcon} />
                        <span class="mx-2">Matched</span>
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div class="ml-auto d-flex align-items-center">
                <div class="dropdown">
                  {/* {
                    type === 'rtr' ?
                      (
                        <div><button type="button" class="btn btn-sm btn-blue-disabled mr-1"><span>
                          Accept
                        </span></button> <button type="button" class="btn btn-secondary btn-sm btn-blue-disabled"><span>
                          Reject
                        </span></button></div>
                      ) : null
                  } */}
                  {job.status === "ScheduleInterview" ? (
                    <div>
                      <button
                        type="button"
                        class="btn btn-sm btn-blue-disabled mr-1"
                      >
                        <span>Schedule</span>
                      </button>{" "}
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm btn-blue-disabled"
                      >
                        <span>Reject</span>
                      </button>
                    </div>
                  ) : null}
                </div>
                {type === "favorited" && (
                  <>
                    <button
                      className="btn btn-sm btn-danger mr-1"
                      onClick={() => addToFavorite(false)}
                    >
                      {favoriteClicked ? (
                        <img
                          style={{ width: "18px", height: "19px" }}
                          src={buttonLoader}
                          alt=""
                        />
                      ) : (
                        !addedToFavorite && "Unfavorite"
                      )}
                      {addedToFavorite && (
                        <i class="fas fa-check-circle fa-fw font-18 mt-1"></i>
                      )}
                    </button>
                    <button
                      onClick={() => setapplyPopUp(true)}
                      type="button"
                      class="btn btn-sm btn-blue-disabled mr-1"
                    >
                      Apply
                    </button>
                    <button
                      onClick={discard}
                      type="button"
                      class="btn btn-secondary btn-sm btn-blue-disabled"
                    >
                      {!discarding && !discarded && <span>Discard</span>}
                      {discarding && (
                        <img
                          style={{ width: "18px", height: "19px" }}
                          src={buttonLoader}
                          alt=""
                        />
                      )}

                      {discarded && (
                        <i class="fas fa-check-circle fa-fw font-18 mt-1"></i>
                      )}
                    </button>
                  </>
                )}
                {(type === "matched" ||
                  type === "Applied" ||
                  type === "search") && (
                  <div>
                    {type !== "search" && (
                      <button
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Favorite"
                        onClick={() => addToFavorite(true)}
                        type="button"
                        class="btn btn-square btn-gray"
                      >
                        {!favoriteClicked && !addedToFavorite && (
                          <span>
                            <i class="fas fa-heart"></i>
                          </span>
                        )}

                        {favoriteClicked && (
                          <img
                            style={{ width: "18px", height: "19px" }}
                            src={buttonLoader}
                            alt=""
                          />
                        )}

                        {addedToFavorite && (
                          <i class="fas fa-check-circle fa-fw font-18 mt-1"></i>
                        )}
                      </button>
                    )}

                    <button
                      onClick={handleApply}
                      type="button"
                      class="btn btn-sm btn-blue-disabled ml-2 mr-1"
                    >
                      Apply
                    </button>
                    {type !== "search" && (
                      <button
                        onClick={discard}
                        type="button"
                        class="btn btn-secondary btn-sm"
                      >
                        {!discarding && !discarded && <span>Discard</span>}
                        {discarding && (
                          <img
                            style={{ width: "18px", height: "19px" }}
                            src={buttonLoader}
                            alt=""
                          />
                        )}

                        {discarded && (
                          <i class="fas fa-check-circle fa-fw font-18 mt-1"></i>
                        )}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div class="view-panel-body">
          <button
            onClick={() => {
              onCollapsibleClick("generaljob");
            }}
            type="button"
            className="btn btn-collapsible mb-2"
          >
            {!generaljob ? (
              <i className="fal fa-angle-up fa-fw mt-1"></i>
            ) : (
              <i className="fal fa-angle-down fa-fw"></i>
            )}
            <span>
              <small>General</small>
            </span>
          </button>
          <div className={generaljob ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
            <div class="d-flex mb-2 pl-1 ml-2">
              <div>
                <i class="fad fa-fw fa-piggy-bank" aria-hidden="true"></i>
              </div>
              <div class="ml-2">
                <p class=" mb-0">
                  {job.jobType.charAt(0).toUpperCase() + job.jobType.slice(1)}
                </p>
                <p class="mb-0">
                  <small>Job Type</small>
                </p>
              </div>
            </div>
            <div class="d-flex mb-2 pl-1 ml-2">
              <div>
                <i class="fad fa-fw fa-calendar-day" aria-hidden="true"></i>
              </div>
              <div class="ml-2">
                <p class="mb-0">
                  {moment(job.prefferedStartDate).format("MM/DD/YYYY")}
                </p>
                <p class="mb-0">
                  <small>Target Start</small>
                </p>
              </div>
            </div>
            <div class="d-flex mb-2 pl-1 ml-2">
              <div>
                <i class="fad fa-fw fa-map-marked-alt" aria-hidden="true"></i>
              </div>
              <div class="ml-2">
                <p class=" mb-0">
                  {job.fullText.location?.city &&
                  job.fullText.location?.state &&
                  job.fullText.location?.country ? (
                    <span className="font-regular font-primary font-gray1">
                      {job.fullText.location.city},{" "}
                      {job.fullText.location.state},{" "}
                      {job.fullText.location.country}
                    </span>
                  ) : (
                    <span className="font-regular font-primary font-gray1">
                      Remote
                    </span>
                  )}
                </p>
                <p class="mb-0">
                  <small>Location</small>
                </p>
              </div>
            </div>
            <div class="d-flex mb-2 pl-1 ml-2">
              <div>
                <i class="fad fa-fw fa-piggy-bank" aria-hidden="true"></i>
              </div>
              <div class="ml-2">
                <p class=" mb-0">
                  {job.minimumPay +
                    "-" +
                    job.maximumPay +
                    " " +
                    job.salaryCurrency}
                </p>
                <p class="mb-0">
                  <small>Pay</small>
                </p>
              </div>
            </div>
          </div>
          <button
            onClick={() => {
              onCollapsibleClick("description");
            }}
            type="button"
            className="btn btn-collapsible mb-2"
          >
            {!description ? (
              <i className="fal fa-angle-up fa-fw mt-1"></i>
            ) : (
              <i className="fal fa-angle-down fa-fw"></i>
            )}
            <span>
              <small>Description</small>
            </span>
          </button>
          <div className={description ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
            <div class="d-flex mb-2 pl-1 ml-4">
              <p dangerouslySetInnerHTML={{ __html: job.jobDescription }}></p>
            </div>
          </div>
          {job.primarySkills && (
            <button
              onClick={() => {
                onCollapsibleClick("primarySkills");
              }}
              type="button"
              className="btn btn-collapsible mb-2"
            >
              {!primarySkills ? (
                <i className="fal fa-angle-up fa-fw mt-1"></i>
              ) : (
                <i className="fal fa-angle-down fa-fw"></i>
              )}
              <span>
                <small>Primary Skills</small>
              </span>
            </button>
          )}
          <div className={primarySkills ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
            <div class="d-flex mb-2 pl-1 ml-4">
              {job.primarySkills.map((skill) => (
                <span className="tag tag-blue3 mr-1">{skill}</span>
              ))}
            </div>
          </div>
          {job.secondarySkills > 0 && (
            <button
              onClick={() => {
                onCollapsibleClick("secondarySkills");
              }}
              type="button"
              className="btn btn-collapsible mb-2"
            >
              {!secondarySkills ? (
                <i className="fal fa-angle-up fa-fw mt-1"></i>
              ) : (
                <i className="fal fa-angle-down fa-fw"></i>
              )}
              <span>
                <small>Secondary Skills</small>
              </span>
            </button>
          )}
          <div
            className={secondarySkills ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}
          >
            <div class="d-flex mb-2 pl-1 ml-4">
              {job.secondarySkills.map((skill) => (
                <span className="tag tag-blue3 mr-1">{skill}</span>
              ))}
            </div>
          </div>
          {job.education.length > 0 && (
            <button
              onClick={() => {
                onCollapsibleClick("education");
              }}
              type="button"
              className="btn btn-collapsible mb-2"
            >
              {!education ? (
                <i className="fal fa-angle-up fa-fw mt-1"></i>
              ) : (
                <i className="fal fa-angle-down fa-fw"></i>
              )}
              <span>
                <small>Education</small>
              </span>
            </button>
          )}
          <div className={education ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
            <div class="d-flex mb-2 pl-1 ml-4">
              {job.education.map((e) => (
                <span className="tag tag-blue3 mr-1">{e}</span>
              ))}
            </div>
          </div>
          {job.certifications.length > 0 && (
            <button
              onClick={() => {
                onCollapsibleClick("certificates");
              }}
              type="button"
              className="btn btn-collapsible mb-2"
            >
              {!certificates ? (
                <i className="fal fa-angle-up fa-fw mt-1"></i>
              ) : (
                <i className="fal fa-angle-down fa-fw"></i>
              )}
              <span>
                <small>Certificates</small>
              </span>
            </button>
          )}
          <div className={certificates ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
            <div class="d-flex mb-2 pl-1 ml-4">
              {job.certifications.map((e) => (
                <span className="tag tag-blue3 mr-1">{e}</span>
              ))}
            </div>
          </div>
          {job.industries.length > 0 && (
            <button
              onClick={() => {
                onCollapsibleClick("industry");
              }}
              type="button"
              className="btn btn-collapsible mb-2"
            >
              {!industry ? (
                <i className="fal fa-angle-up fa-fw mt-1"></i>
              ) : (
                <i className="fal fa-angle-down fa-fw"></i>
              )}
              <span>
                <small>Industry</small>
              </span>
            </button>
          )}
          <div className={industry ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
            <div class="d-flex mb-2 pl-1 ml-4">
              {job.industries.map((e) => (
                <span className="tag tag-blue3 mr-1">{e}</span>
              ))}
            </div>
          </div>
          {job.workStart && job.workEnd && (
            <button
              onClick={() => {
                onCollapsibleClick("workHours");
              }}
              type="button"
              className="btn btn-collapsible mb-2"
            >
              {!workHours ? (
                <i className="fal fa-angle-up fa-fw mt-1"></i>
              ) : (
                <i className="fal fa-angle-down fa-fw"></i>
              )}
              <span>
                <small>Work Hours</small>
              </span>
            </button>
          )}
          {job.weightage.length > 0 && (
            <button
              onClick={() => setWeightage(!weightage)}
              type="button"
              className="btn btn-collapsible mb-2"
            >
              {!weightage ? (
                <i className="fal fa-angle-up fa-fw mt-1"></i>
              ) : (
                <i className="fal fa-angle-down fa-fw"></i>
              )}
              <span>
                <small>Mandatory Requirements</small>
              </span>
            </button>
          )}
          <div className={weightage ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
            <div class="mb-2 pl-1 ml-4">
              {job.weightage.primarySkills.length > 0 && (
                <div className="m-1">
                  Primary Skills:{" "}
                  {job.weightage.primarySkills.map((i) => (
                    <span className="tag tag-blue3 m-1">{i}</span>
                  ))}
                </div>
              )}
              {job.weightage.secondarySkills.length > 0 && (
                <div className="m-1">
                  Secondary Skills:{" "}
                  {job.weightage.secondarySkills.map((i) => (
                    <span className="tag tag-blue3 m-1">{i}</span>
                  ))}
                </div>
              )}
              {job.weightage.education.length > 0 && (
                <div className="m-1">
                  Education Level:{" "}
                  {job.weightage.education.map((i) => (
                    <span className="tag tag-blue3 m-1">{i}</span>
                  ))}
                </div>
              )}
              {job.weightage.industries && job.weightage.industries.length > 0 && (
                <div className="m-1">
                  Industry:{" "}
                  {job.weightage.industries.map((i) => (
                    <span className="tag tag-blue3 m-1">{i}</span>
                  ))}
                </div>
              )}
              {job.weightage.experienceLevel && (
                <div className="m-1">
                  Experience Level:{" "}
                  <span className="tag tag-blue3 m-1">
                    {job.experienceLevel}
                  </span>
                </div>
              )}
              {job.weightage.jobTitle && (
                <div className="m-1">
                  Job Title:{" "}
                  <span className="tag tag-blue3 m-1">{job.jobTitle}</span>
                </div>
              )}
              {job.weightage.location && (
                <div className="m-1">
                  Location:{" "}
                  <span className="tag tag-blue3 m-1">
                    {job.location.city || "Remote"}
                  </span>
                </div>
              )}
            </div>
          </div>
          <div className={workHours ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
            <div class="d-flex mb-2 pl-1 ml-4">
              {<span>{job.workStart + " to " + job.workEnd}</span>}
            </div>
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setDrugTest(!drugTest)}
            >
              {!drugTest ? (
                <i className="fal fa-angle-up fa-fw" />
              ) : (
                <i className="fal fa-angle-down fa-fw" />
              )}
              <span>
                <small>Drug Test</small>
              </span>
            </button>
          </div>

          {drugTest === true ? (
            <div className="mb-3 ml-4">
              <span>
                {job.drugTest === true ? (
                  <span className="tag tag-green2">Yes</span>
                ) : (
                  <span className="tag tag-red1">No</span>
                )}
              </span>
            </div>
          ) : null}
          {job.backgroundCheck !== "true" && (
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() =>
                  backgroundCheck
                    ? onCollapsibleClick("backgroundCheckReset")
                    : onCollapsibleClick("backgroundCheck")
                }
              >
                {!backgroundCheck ? (
                  <i
                    if="!collapsible.backgroundCheck"
                    className="fal fa-angle-up fa-fw"
                    onClick={() => {
                      onCollapsibleClick("backgroundCheckReset");
                    }}
                  />
                ) : (
                  <i
                    className="fal fa-angle-down fa-fw"
                    onClick={() => {
                      onCollapsibleClick("backgroundCheck");
                    }}
                  />
                )}
                <span>
                  <small>Background Check</small>
                </span>
              </button>
            </div>
          )}
          {backgroundCheck === true ? (
            <div className="mb-3 ml-4">
              <span>
                {job.backgroundCheck === true ? (
                  <span className="tag tag-green2">Yes</span>
                ) : (
                  <span className="tag tag-red1">No</span>
                )}
              </span>
            </div>
          ) : null}
          {job.securityClearance && (
            <button
              onClick={() => {
                onCollapsibleClick("securityClearance");
              }}
              type="button"
              className="btn btn-collapsible mb-2"
            >
              {!securityClearance ? (
                <i className="fal fa-angle-up fa-fw mt-1"></i>
              ) : (
                <i className="fal fa-angle-down fa-fw"></i>
              )}
              <span>
                <small>Security Clearance</small>
              </span>
            </button>
          )}
          <div
            className={securityClearance ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}
          >
            <div className="d-flex mb-2 pl-1 ml-4">
              <span className="">{job.securityClearance}</span>
            </div>
          </div>
          {job.travel && (
            <button
              onClick={() => {
                onCollapsibleClick("travel");
              }}
              type="button"
              className="btn btn-collapsible mb-2"
            >
              {!travel ? (
                <i className="fal fa-angle-up fa-fw mt-1"></i>
              ) : (
                <i className="fal fa-angle-down fa-fw"></i>
              )}
              <span>
                <small>Travel</small>
              </span>
            </button>
          )}
          <div className={travel ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
            <div className="d-flex mb-2 pl-1 ml-4">
              <span className="">{job.travel}</span>
            </div>
          </div>
          {job.status === "Applied" && (
            <div>
              <button
                onClick={() => setQandA(!QandA)}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!QandA ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>QandA</small>
                </span>
              </button>
              <div className={QandA ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
                <QandAResponse QandA={job.QandA ? JSON.parse(job.QandA) : []} />
              </div>
              <button
                onClick={() => setDisplayResume(!displayResume)}
                type="button"
                className="btn btn-collapsible mb-2"
              >
                {!displayResume ? (
                  <i className="fal fa-angle-up fa-fw mt-1"></i>
                ) : (
                  <i className="fal fa-angle-down fa-fw"></i>
                )}
                <span>
                  <small>Resume</small>
                </span>
              </button>
              <div
                className={displayResume ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}
              >
                <strong>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      window.open(job.resumeURL[0].resumeURL, "_blank");
                    }}
                    className="underlined ml-4"
                    title="View Document"
                  >
                    {/* {eachFile.fileName} */}
                    {job.resumeURL[0].fileName}
                  </a>
                </strong>
              </div>
            </div>
          )}
        </div>
        <Modal show={applyPopUP} onHide={applyclosePopUP} size="lg">
          <div class="bl-modal-header">
            <h6 class="bl-modal-title">{"Apply"}</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={applyclosePopUP}
              class="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <Modal.Body>
            <ApplyPopUp
              type={type}
              applyJob={applyJob}
              handleSuccess={applyclosePopUP}
              details={job}
            />
          </Modal.Body>
        </Modal>
      </div>
      <Modal show={applySuccess} size="lg">
        <ApplySuccess
          reason={reason}
          job={job}
          closeModal={() => setApplySuccess(false)}
        />
      </Modal>
    </>
  );
}

export default CandidateRequestDetails;
