import React, { useState } from "react";
import HereMape from "../../location/HereMapAddtalent";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import phoneCode from "../../config/PhoneCode";

function ContactInfoDS(props) {
  const [isRemote, setIsRemote] = useState(true);

  const phCode = Object.entries(phoneCode).map((item) => item[1].code);

  const handleChange = (e) => {
    props.setContactInfo({
      ...props.contactInfo,
      [e.target.id]: e.target.value,
    });
  };

  const _onChangeText = (value, country) => {
    // console.log(value, country)
    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    const formattedNumber = asYouType.input(
      value.replace(country.dialCode, "")
    );
    const isValid = asYouType.isValid();
    props.setContactInfo({
      ...props.contactInfo,
      mobilePhone: value,
      phoneValidation: isValid,
    });
  };

  const _onChangeText2 = (value, country) => {
    console.log(value, country);
    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    const formattedNumber = asYouType.input(
      value.replace(country.dialCode, "")
    );
    const isValid = asYouType.isValid();
    props.setContactInfo({
      ...props.contactInfo,
      homePhone: value,
      phoneValidation2: isValid,
    });
  };

  return (
    <>
      <div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  placeholder="First Name*"
                  style={{
                    border:
                      props.contactInfoValidation.firstName &&
                      "2px solid #ff0000",
                  }}
                  value={props.contactInfo.firstName}
                  onChange={handleChange}
                />
                <label>First Name*</label>
              </div>
              <small className="validation">
                {props.contactInfoValidation.firstName}
              </small>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  placeholder="Last Name*"
                  style={{
                    border:
                      props.contactInfoValidation.lastName &&
                      "2px solid #ff0000",
                  }}
                  value={props.contactInfo.lastName}
                  onChange={handleChange}
                />
                <label>Last Name*</label>
              </div>
              <small className="validation">
                {props.contactInfoValidation.lastName}
              </small>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  disabled
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="Email*"
                  style={{
                    border:
                      props.contactInfoValidation.email && "2px solid #ff0000",
                  }}
                  value={props.contactInfo.email}
                  onChange={handleChange}
                />
                <label>Email*</label>
              </div>
              <small className="validation">
                {props.contactInfoValidation.email}
              </small>
            </div>
          </div>
          <div className="row mb-2" style={{ marginTop: "-17px" }}>
            <div className="col-lg-6">
              <div className="mb-2">
                <label>Primary Phone*</label>
              </div>
              <div className="d-flex">
                <PhoneInput
                  classsName="form-control"
                  value={props.contactInfo.mobilePhone}
                  name="mobilePhone"
                  containerClass="w-100"
                  inputClass="w-100"
                  country={"us"}
                  onChange={(value, country) => {
                    _onChangeText(value, country);
                  }}
                />
              </div>
              {!props.contactInfo.phoneValidation && (
                <small className="validation">Invalid Phone</small>
              )}
            </div>
            <div className="col-lg-6">
              <div className="mb-2">
                <label>Alternative Phone</label>
              </div>
              <div className="d-flex">
                <PhoneInput
                  classsName="form-control"
                  name="homePhone"
                  containerClass="w-100"
                  inputClass="w-100"
                  country={"us"}
                  value={props.contactInfo.homePhone}
                  onChange={(value, country) => {
                    _onChangeText2(value, country);
                  }}
                />
              </div>
              {!props.contactInfo.phoneValidation2 && (
                <small className="validation">Invalid Phone</small>
              )}
            </div>
          </div>
          {/* <div class="row mb-2"> */}
          {/* <div class="col-lg-4">
                            <div class="form-floating">
                                <input type="text" class="form-control" id="email" placeholder="Email*" style={{ border: props.contactInfoValidation.email && '2px solid #ff0000' }} value={props.contactInfo.email} onChange={handleChange} />
                                <label >Email*</label>
                            </div>
                            <small className="validation">{props.contactInfoValidation.email}</small>
                        </div> */}
          {/* <div class="col-lg-4">
                            <div class="form-floating">
                                <select class="form-select">
                                    <option selected>US</option>
                                </select>
                                <label >Country</label>
                            </div>
                        </div> */}
          {/* </div> */}
          {/* <HereMape
            isRemote={isRemote}
            setIsRemote={setIsRemote}
            contactInfo={props.contactInfo}
            setContactInfo={props.setContactInfo}
            profileInfo={props.profileInfo}
            setProfileInfo={props.setProfileInfo}
          /> */}
          {
            <>
              {/* <div class="row mb-2">
                <div class="col-lg-12">
                  <label>Primary Address*</label>
                </div>
              </div> */}
              <div class="row mb-2">
                {/* <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="zipcode"
                      placeholder="Zipcode"
                      style={{
                        border:
                          props.contactInfoValidation.zipcode &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo.zipcode}
                      onChange={handleChange}
                    />
                    <label>Zipcode*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation.zipcode}
                  </small>
                </div> */}
                {/* <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      placeholder="Address"
                      value={props.contactInfo.address}
                      onChange={handleChange}
                    />
                    <label>Address</label>
                  </div>
                </div> */}
                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="addressCity"
                      placeholder="Address City"
                      style={{
                        border:
                          props.contactInfoValidation.addressCity &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo.addressCity}
                      onChange={handleChange}
                    />
                    <label>Your City*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation.addressCity}
                  </small>
                </div>

                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="country"
                      placeholder="Address Country"
                      style={{
                        border:
                          props.contactInfoValidation.country &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo.country}
                      onChange={handleChange}
                    />
                    <label>Address Country*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation.country}
                  </small>
                </div>
              </div>
              {/* <div class="row mb-2">
                <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="addressState"
                      placeholder="Address State"
                      style={{
                        border:
                          props.contactInfoValidation.addressState &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo.addressState}
                      onChange={handleChange}
                    />
                    <label>Address State*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation.addressState}
                  </small>
                </div>
                <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="country"
                      placeholder="Address Country"
                      style={{
                        border:
                          props.contactInfoValidation.country &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo.country}
                      onChange={handleChange}
                    />
                    <label>Address Country*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation.country}
                  </small>
                </div>
               
              </div> */}
            </>
          }
          {/* <div className="row mb-2">
            <div className="col-lg-6">
              <div className="mb-2">
                <label>Primary Phone*</label>
              </div>
              <div className="d-flex">
                <PhoneInput
                  classsName="form-control"
                  value={props.contactInfo.mobilePhone}
                  name="mobilePhone"
                  country={"us"}
                  onChange={(value, country) => {
                    _onChangeText(value, country);
                  }}
                />
              </div>
              {!props.contactInfo.phoneValidation && (
                <small className="validation">Invalid Phone</small>
              )}
            </div>
            <div className="col-lg-6">
              <div className="mb-2">
                <label>Alternative Phone</label>
              </div>
              <div className="d-flex">
                <PhoneInput
                  classsName="form-control"
                  name="homePhone"
                  country={"us"}
                  value={props.contactInfo.homePhone}
                  onChange={(value, country) => {
                    _onChangeText2(value, country);
                  }}
                />
              </div>
              {!props.contactInfo.phoneValidation2 && (
                <small className="validation">Invalid Phone</small>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default ContactInfoDS;
