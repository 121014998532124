import React, { useState } from "react"
import { ButtonLoader } from '../../../constants/Constants'
import { toast } from 'react-toastify'
import { post } from '../../../service/ApiService'
import { getCandidateId } from "../../../service/AuthService"

function CustomSection({ section }) {
  
  const [ readOnlyMode, setReadOnlyMode ] = useState(true)
  const [ submittingForm, setSubmittingFrom ] = useState(false)
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false)
  const [ saveClicked, setSaveClicked ] = useState(true)
  const [ customSection, setCustomSection ] = useState(section)
  const [ tempCustomSection, setTempCustomSection ] = useState(section)

  const addCustomSection = () => {
    let cs = [...customSection]
    cs.push({
      title: "",
      subheading: "",
      sampledesc: "",
    })
    setCustomSection(cs)
  }

  const deleteCustomSection = (index) => {
    let cs = [...customSection]
    cs.splice(index, 1)
    setCustomSection(cs)
  }

  const editCustomSection = () => {
    setReadOnlyMode(false)
    setSaveClicked(true)
  }

  const cancelCustomSection = () => {
    setCustomSection(tempCustomSection)
    setReadOnlyMode(true)
    setShowSuccessMessage(false)
    setSubmittingFrom(false)
    setSaveClicked(true)
  }

  const saveCustomSection = async () => {
    let condition = true
    customSection.forEach(cs => {
      if(Object.values(cs).findIndex(i => i === "") !== -1) {
        condition = false
        return false
      }
    })
    if(!condition) {
      toast.error("All Fields are Mandatory")
    }
    else { 
      setSaveClicked(false)
      setSubmittingFrom(true)
      try {
        const parameters = {
            candidateID: getCandidateId(),
            clickName: 'customsections',
            userCustomSectionInfo: customSection
          }
          const res = await post('/candidateProfile/postCustomSectionData', parameters)
          if (res.status === 200) {
            setTempCustomSection(customSection)
            setSubmittingFrom(false)
            setShowSuccessMessage(true)      
            setTimeout(() => { 
              setReadOnlyMode(true)
              setShowSuccessMessage(false)
            }, 1000)
          }
      } catch (error) {
          toast.error("Error Occured")
      }
    }
  }

  const handleChange = (name, value, index) => {
    let cs = [...customSection]
    cs[index] = { ...cs[index], [name]: value }
    setCustomSection(cs)
  }

  return (
    <>
      <div>
        <div className={readOnlyMode === true ? "mb-2 read-only" : "mb-2"}>
          <div class="card card-lg">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between">
                <h6 class="mb-0">Custom Section</h6>
                {readOnlyMode === true ? (
                  <button
                    type="button"
                    class="btn btn-text"
                    onClick={editCustomSection}
                  >
                    <i class="fas fa-pencil-alt mr-1"></i>
                    Edit
                  </button>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={cancelCustomSection}
                      class="btn btn-sm btn-secondary mr-1"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={saveCustomSection}
                      class="btn btn-sm btn-primary"
                    >
                      {saveClicked && <span>Save Changes</span>}

                      {submittingForm && (
                        <span>
                          Saving Changes
                          <img
                            width="20px"
                            src={ButtonLoader}
                            alt=""
                          />
                        </span>
                      )}
                      {showSuccessMessage && <span>Changes Saved!</span>}
                    </button>
                  </div>
                )}
              </div>
              <hr />
              {
                  readOnlyMode ?
                  <>                   
                    { customSection.map(custom => <div class="d-flex">
                        <div class="mr-3 d-flex flex-column align-items-center">
                            <i class="fad fa-fw fa-briefcase mt-1" aria-hidden="true"></i>
                            <div class="view-panel-spacer mt-1"></div>
                        </div>
                        <div class="pb-3">
                            <p class="mb-1">
                              <strong>
                                {custom.title}
                              </strong>
                            </p>
                            <p class="mb-0">
                              <small>
                                <strong>{custom.subheading}</strong>
                              </small>
                            </p>                        
                            <p className='mt-1'>
                              {custom.sampledesc}
                            </p>
                        </div>
                    </div>)}                     
                  </>               
                  :
                  <div>
                  { customSection.map((custom, index) => 
                  <div class="d-flex">
                    <div class="mr-3">
                      <div class="avatar avatar-sm">{index+1}</div>
                    </div>
                    <div class="flex-grow-1">
                      <div class="row">                       
                        <div class="col-lg-12">
                          <div class="form-floating">
                            <input
                              type="text"
                              className="form-control mb-2"
                              name="title"
                              value={custom.title}
                              onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                            />
                            <label>Title*</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">                       
                        <div class="col-lg-12">
                          <div class="form-floating">
                          <input
                            type="text"
                            className="form-control mb-2"
                            name="subheading"
                            value={custom.subheading}
                            onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                          />
                          <label>Sub Heading*</label>
                          </div>
                        </div>
                      </div>
                      <div class="row">                       
                        <div class="col-lg-12">
                          <label>Description*</label>
                          <div class="form-floating mt-1">
                            <textarea
                              class="form-control"
                              name="description"
                              aria-describedby=""
                              name="sampledesc"
                              value={custom.sampledesc}
                              onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                            >                       
                            </textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <hr />
                        </div>
                      </div>
                    </div>
                    <div class="ml-3">
                      <button type="button" class="btn btn-icon" onClick={() => deleteCustomSection(index)}>
                        <i class="fal fa-fw fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>)}
                  <div className='ml-5'>
                    <button
                      type="button"
                      class="btn btn-sm btn-text"
                      onClick={addCustomSection}  
                    >
                      <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                      <strong>Add Custom Section</strong>
                    </button>
                  </div>
                </div>
              }             
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  )
}

export default CustomSection
