import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { union } from "lodash";
import {
  areAllKeysEmpty,
  contactInfoValidation,
  profileInfoValidation,
} from "../Validations/ApplyJobValidations";
import { domain } from "../../../../../constants/Constants";
import Contact from "./Contact";
import Loader from "../../../../Loader/Loader";
import { checkEmptyObject } from "../../../../function/CommonFunction";
import { post } from "../../../../../service/ApiService";
import { getCandidateDropdown } from "../../../../../actions/talent/AddTalent";
import Profile from "./Profile";
import Education from "./Education";
import Experience from "./Experience";
import Preferences from "./Preference";
import { parsePhoneNumber } from "libphonenumber-js";
import { isValidUrl } from "../../../../function/CommonFunction";
import ResumeParsed from "./ResumeParsed";
import SkillMatrix from "./SkillMatrix";
import { Modal } from "react-bootstrap";
import ScreeningQues from "../ScreeningQues";
import Vetting from "../Vetting";
import PoolScreeningQues from "../PoolScreeningQues";

const Index = (props) => {
  const {
    job,
    setModalSize,
    parsed,
    parsedData,
    candidateDetails,
    setCandidateDetails,
    resumeFile,
    setResumeFile,
    setTrackedData,
    trackedData,
    isFrom,
    isScreening,
    setIsScreening,
    setScreeningAnswers,
    setIsVetting,
    setAddTalent,
    isVetting,
    dispositionID,
    // vettingNow,
    // setVettingNow,
    setApply,
    loadding,
    component,
    setRadiusValue,
    setSocialMediaApply,
    editTalent,
    educations,
    setEducations,
    educationVal,
    setEducationVal,
    experiences,
    setExperiences,
    experienceVal,
    setExperienceVal,
  } = props;
  const { tenant } = useParams();

  const dispatch = useDispatch();
  const [contact, setContact] = useState(true);
  // tab view states
  const [experience, setExperience] = useState(false);
  const [education, setEducation] = useState(false);
  const [preferenceTab, setPreferenceTab] = useState(true);
  // tab view states end here
  const [profileInfo, setProfileInfo] = useState(true);
  const [skillMatrix, setSkillMatrix] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [load, setLoading] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 1200px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const { loading, dropDownData } = useSelector(
    (state) => ({
      loading: state?.AddCandidate?.loading,
      dropDownData: state?.AddCandidate?.dropDownData,
    }),
    shallowEqual
  );

  // Experience child Component
  // const [experiences, setExperiences] = useState([
  //   {
  //     employerName: "",
  //     industry: "",
  //     jobTitle: "",
  //     startDate: "",
  //     endDate: "",
  //     description: "",
  //     isSelect: false,
  //   },
  // ]);
  // const [experienceVal, setExperienceVal] = useState([
  //   {
  //     employerName: "",
  //     industry: "",
  //     jobTitle: "",
  //     startDate: "",
  //     endDate: "",
  //   },
  // ]);
  // // Education Child Component
  // const [educations, setEducations] = useState([
  //   {
  //     graduatedYear: "",
  //     educationType: "",
  //     educationProgram: "",
  //     school: "",
  //     major: "",
  //   },
  // ]);
  // const [educationVal, setEducationVal] = useState([
  //   {
  //     graduatedYear: "",
  //     educationType: "",
  //     educationProgram: "",
  //     school: "",
  //     major: "",
  //   },
  // ]);

  // Contact Info child  Component
  const [contactInfo, setContactInfo] = useState({
    longitude: candidateDetails?.longitude ?? "",
    latitude: candidateDetails?.latitude ?? "",

    firstName: candidateDetails?.firstName ? candidateDetails?.firstName : "",
    middleName: "",
    lastName: candidateDetails?.lastName ? candidateDetails?.lastName : "",
    email: candidateDetails?.email ? candidateDetails?.email : "",
    zipCode: "",
    address: candidateDetails?.address ? candidateDetails?.address : "NA",
    city: candidateDetails?.city ? candidateDetails?.city : "",
    state: candidateDetails?.state ? candidateDetails?.state : "NA",
    country: candidateDetails?.country ? candidateDetails?.country : "",
    workPhoneCode: "",
    workPhone: candidateDetails?.workPhone
      ? parsePhoneNumber(
          candidateDetails.workPhone[0] === "+"
            ? candidateDetails.workPhone
            : `+${candidateDetails.workPhone}`
        ).formatInternational()
      : "",
    homePhoneCode: candidateDetails?.homePhoneCode,
    homePhone: candidateDetails?.homePhone,

    phoneValidation: true,
    phoneValidation2: true,
    location: candidateDetails?.location ? candidateDetails.location : {},
  });
  const [contactVal, setContactVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zipcode: "",
    address: "",
    city: "",
    state: "",
    country: "",
  });
  // Profile Info child  Component
  const [salaryType, setSalaryType] = useState(
    candidateDetails.salaryType ? candidateDetails.salaryType : "Select"
  );

  const [profileData, setProfileData] = useState({
    facebook: "",
    twitter: "",
    linkedIn: "",
    workAuthCountry: "",
    preferredSalary: candidateDetails.preferredSalary
      ? candidateDetails.preferredSalary
      : "",
    preferredSalaryCurrency: candidateDetails.preferredSalaryCurrency
      ? candidateDetails.preferredSalaryCurrency
      : "USD",
    minContractRate: candidateDetails.minContractRate
      ? candidateDetails.minContractRate
      : "",
    minContractRateCurrency: candidateDetails.minContractRateCurrency
      ? candidateDetails.minContractRateCurrency
      : "USD",
    description: "",
    fileName: candidateDetails?.fileName ? candidateDetails?.fileName : "",
    base64: candidateDetails?.base64 ? candidateDetails?.base64 : "",
    positionTypes: [],
    preferredLocation: sessionStorage.getItem("email") == null ? "" : "",
    primarySkills:
      candidateDetails?.primarySkills.length > 0
        ? candidateDetails?.primarySkills
        : [],
    secondarySkills: [],
    skillSet: [],
    source: "Career Page",
    visaStatus: "",
    legallyAuthorized: candidateDetails?.legallyAuthorized
      ? candidateDetails?.legallyAuthorized
      : false,
    requireSponsorship: candidateDetails?.requireSponsorship
      ? candidateDetails?.requireSponsorship
      : false,
    designation: candidateDetails?.designation
      ? candidateDetails?.designation
      : "",
    experienceLevel: candidateDetails?.experienceLevel
      ? candidateDetails?.experienceLevel
      : "",
    // experienceYear: candidateDetails?.experienceYear
    //   ? candidateDetails?.experienceYear
    //   : "",
    experienceMonth: candidateDetails?.experienceMonth
      ? candidateDetails?.experienceMonth
      : "",
    currentJobTitle: candidateDetails?.designation
      ? candidateDetails?.designation
      : "",
    isFresher: false,
    isRemote: candidateDetails?.isRemote ? candidateDetails.isRemote : false,
    isHybrid: candidateDetails?.isHybrid ? candidateDetails.isHybrid : false,
    isOnsite: candidateDetails?.isOnsite ? candidateDetails?.isOnsite : false,
    highestEducation: candidateDetails?.highestEducation
      ? candidateDetails?.highestEducation
      : "Select",
  });
  const [profileVal, setProfileVal] = useState({
    preferredSalaryCurrency: "",
    minContractRateCurrency: "",
    primarySkills: "",
    //experienceYear: "",
    experienceMonth: "",
    currentJobTitle: "",
  });

  useEffect(() => {
    //getTenant();
    setModalSize("apply");
  }, []);

  useEffect(() => {
    if (!dropDownData) {
      dispatch(getCandidateDropdown());
    }
  }, [dropDownData]);

  useEffect(() => {
    // check if props data is present then set to values
    if (parsed) {
      //  profileInfo
      let location = parsedData?.location || "";
      if (parsedData?.isAddedByCrintell) {
        if (parsedData?.profile?.Locations?.length) {
          location = parsedData?.profile?.Locations[0];
        }
      } else if (parsedData?.h5h) {
        if (parsedData?.location?.length) {
          location = parsedData?.location;
        }
      } else {
        if (parsedData?.location) {
          location = parsedData?.location;
        }
      }

      // if added by crintell we are checking this data else previous data
      let name1 = parsedData?.name?.split(" ");
      const contactInfoObject = parsedData?.h5h
        ? {
            email: parsedData?.email,
            address: location ? location?.address : "",
            country: location ? location?.country : "",
            state: location ? location?.state : "",
            city: location ? location?.city : "",
            zipcode: "",
            currentJobTitle: parsedData?.designation,
            firstName: parsedData?.name ? name1[0] : "",
            lastName: parsedData?.name ? name1[1] : "",
            homePhone: parsedData?.phone ? parsedData?.phone : "",
          }
        : {
            address: location?.rawInput,
            email: parsedData?.data?.emails.toString(),
            country: location?.country ? location?.country : "",
            state: location?.state ? location?.state : "",
            city: location?.city ? location?.city : "",
            zipcode: "",
            firstName: parsedData?.data?.name?.first,
            lastName: parsedData?.data?.name?.last,
            workPhone: parsedData?.data?.phoneNumbers[0],
            homePhone: parsedData?.data?.phoneNumbers[1],
          };
      // : [];
      setContactInfo({
        ...contactInfo,
        ...contactInfoObject,
      });
      let Skill = parsedData.h5h
        ? parsedData?.primary_skills?.length
          ? parsedData?.primary_skills?.map((item) => {
              return item;
            })
          : []
        : parsedData?.data?.skills?.length
        ? parsedData?.data?.skills?.map((item) => {
            return item.name;
          })
        : [];

      Skill = Skill.filter((SkillItem) => SkillItem);
      // if addedby crintell we are adding this below
      const profileInfoObject = parsedData?.h5h
        ? {
            primarySkills: parsedData?.primary_skills,
            secondarySkills: parsedData?.secondary_skills,
            linkedIn: parsedData?.linkedin || "",
            facebook: parsedData?.websites || "",
            skillSet: [],
            description: "",
            experienceYear: parsedData?.experience || "",
            fileName: parsedData?.fileName,
            base64: parsedData?.base64,
            currentJobTitle: parsedData?.designation,
          }
        : {
            primarySkills: Skill,
            linkedIn: parsedData.data?.linkedin,
            facebook: parsedData.data?.websites[0],
            skillSet: [parsedData.data?.profession],
            description: parsedData.data?.summary,
            experienceYear: parsedData.data?.totalYearsExperience?.toString(),
            fileName: parsedData?.fileName,
            base64: parsedData?.base64,
          };

      setProfileData(Skill);
      setProfileData({
        ...profileData,
        ...profileInfoObject,
      });

      let experience = [];
      let experienceVal2 = [];
      if (!parsedData.h5h) {
        experience =
          parsedData.data?.workExperience &&
          parsedData.data?.workExperience.map((item) => ({
            employerName: item.organization,
            jobTitle: item.jobTitle,
          }));
        experienceVal2 =
          parsedData.data?.workExperience &&
          parsedData.data?.workExperience.map((item) => ({
            employerName: "",
            industry: "",
            jobTitle: "",
            startDate: "",
            endDate: "",
          }));
      }

      experienceVal2 && setExperienceVal(experienceVal2);
      experience && setExperiences(experience);

      let education = [];
      let educationVal2 = [];
      let objArray = [];

      if (parsedData?.education !== (null || undefined)) {
        Object.keys(parsedData?.education[0])?.forEach((key) =>
          objArray.push({
            index: key,
            edu: parsedData?.education[0][key],
          })
        );
      }

      if (parsedData?.h5h) {
        education =
          parsedData?.education?.length > 0
            ? objArray?.map((item) => ({
                graduatedYear: item?.edu?.year,
                educationProgram: item?.edu?.program,
                school: item.edu?.school,
                major: item.edu?.major,
              }))
            : [];
        educationVal2 =
          parsedData?.education?.length > 0 &&
          objArray?.map((item) => ({
            graduatedYear: "",

            program: "",
            school: "",
            major: "",
          }));
      } else {
        education =
          parsedData.data?.education &&
          parsedData.data?.education.map((item) => ({
            educationProgram: item.accreditation?.education,
            school: item.organization,
            major: item.accreditation?.inputStr,
          }));
        educationVal2 =
          parsedData?.data?.education &&
          parsedData?.data?.education.map((item) => ({
            graduatedYear: "",
            educationType: "",
            educationProgram: "",
            school: "",
            major: "",
          }));
      }

      educationVal2 && setEducationVal(educationVal2);
      education && setEducations(education);
    }
  }, [props]);

  useEffect(() => {
    setContactInfo({
      longitude: candidateDetails?.longitude ?? "",
      latitude: candidateDetails?.latitude ?? "",
      firstName: candidateDetails?.firstName ? candidateDetails?.firstName : "",
      middleName: "",
      lastName: candidateDetails?.lastName ? candidateDetails?.lastName : "",
      email: candidateDetails?.email ? candidateDetails?.email : "",
      zipCode: candidateDetails?.zipCode ? candidateDetails?.zipCode : "",
      address: candidateDetails?.address ? candidateDetails?.address : "",
      city: candidateDetails?.city ? candidateDetails?.city : "",
      state: candidateDetails?.state ? candidateDetails?.state : "",
      country: candidateDetails?.country ? candidateDetails?.country : "",
      workPhoneCode: "",
      workPhone: candidateDetails?.workPhone
        ? parsePhoneNumber(
            candidateDetails.workPhone[0] === "+"
              ? candidateDetails.workPhone
              : `+${candidateDetails.workPhone}`
          ).formatInternational()
        : "",
      homePhoneCode: candidateDetails?.homePhoneCode,
      homePhone: candidateDetails?.homePhone,

      phoneValidation: true,
      phoneValidation2: true,
      location: candidateDetails?.location ? candidateDetails.location : {},
    });
    let Skill = parsedData.h5h
      ? parsedData?.primary_skills?.length
        ? parsedData?.primary_skills?.map((item) => {
            return item;
          })
        : []
      : parsedData?.data?.skills?.length
      ? parsedData?.data?.skills?.map((item) => {
          return item.name;
        })
      : [];
    setProfileData({
      workAuthCountry: candidateDetails.workAuthCountry ?? "",
      facebook: candidateDetails?.facebook ? candidateDetails?.facebook : "",
      twitter: candidateDetails?.twitter ? candidateDetails?.twitter : "",
      linkedIn: candidateDetails?.linkedIn ? candidateDetails?.linkedIn : "",
      preferredSalary:
        salaryType === "Hourly"
          ? candidateDetails?.minContractRate
          : candidateDetails?.preferredSalary
          ? candidateDetails?.preferredSalary
          : "",
      preferredSalaryCurrency: candidateDetails?.preferredSalaryCurrency
        ? candidateDetails?.preferredSalaryCurrency
        : "USD",
      minContractRate: candidateDetails?.minContractRate
        ? candidateDetails?.minContractRate
        : "",
      minContractRateCurrency: "USD",
      description: "",
      fileName: candidateDetails?.fileName ? candidateDetails?.fileName : "",
      base64: candidateDetails?.base64 ? candidateDetails?.base64 : "",
      positionTypes:
        candidateDetails?.positionTypes?.length > 0
          ? candidateDetails?.positionTypes
          : [],
      preferredLocation:
        candidateDetails?.preferredLocation?.length > 0
          ? candidateDetails?.preferredLocation
          : sessionStorage.getItem("email") == null
          ? ""
          : "",
      primarySkills: union(candidateDetails?.primarySkills || [], Skill),
      secondarySkills:
        candidateDetails?.secondarySkills?.length > 0
          ? candidateDetails?.secondarySkills
          : [],
      skillSet:
        candidateDetails?.skillSet?.length > 0
          ? candidateDetails?.skillSet
          : [],
      source: candidateDetails?.source
        ? candidateDetails?.source
        : "Career Page",
      visaStatus: candidateDetails?.visaStatus
        ? candidateDetails?.visaStatus
        : "",
      legallyAuthorized: candidateDetails?.legallyAuthorized
        ? candidateDetails?.legallyAuthorized
        : false,
      requireSponsorship: candidateDetails?.requireSponsorship
        ? candidateDetails?.requireSponsorship
        : false,
      designation: candidateDetails?.designation
        ? candidateDetails?.designation
        : "",

      experienceLevel: candidateDetails?.experienceLevel
        ? candidateDetails?.experienceLevel
        : "",
      experienceYear: candidateDetails?.experienceYear
        ? candidateDetails?.experienceYear
        : "",
      experienceMonth: candidateDetails?.experienceMonth
        ? candidateDetails?.experienceMonth
        : "0",
      currentJobTitle: candidateDetails?.designation
        ? candidateDetails?.designation
        : "",
      isFresher: candidateDetails?.isFresher
        ? candidateDetails?.isFresher
        : false,
      isRemote: candidateDetails?.isRemote ? candidateDetails.isRemote : false,
      isHybrid: candidateDetails?.isHybrid ? candidateDetails.isHybrid : false,
      isOnsite: candidateDetails?.isOnsite ? candidateDetails?.isOnsite : false,
      highestEducation: candidateDetails?.highestEducation
        ? candidateDetails?.highestEducation
        : "Select",
    });
    setResumeFile({
      fileName: candidateDetails?.fileName,
      base64: candidateDetails?.base64,
    });
    setSalaryType(
      candidateDetails?.salaryType ? candidateDetails?.salaryType : "Select"
    );
  }, [candidateDetails]);

  const getTenant = async () => {
    const params = { tenantId: tenant };
    setLoading(true);
    const res = await post("/career/gettenant", params).then((res) => {
      setLoading(false);
    });
  };

  const calculateExperience = [
    { name: "Intern", range: [0] },
    { name: "1-3 years", range: [1, 2, 3] },
    { name: "3-5 years", range: [4, 5] },
    { name: "5-10 years", range: [6, 7, 8, 9, 10] },
    { name: "10+ years", range: [] },
  ];

  const findRange = (years, months) => {
    let totalMonths = Number(years) * 12 + Number(months);
    if (totalMonths >= 120) {
      return "10+ years";
    } else if (totalMonths < 12) {
      if (totalMonths === 0) {
        return "Intern";
      } else {
        return "Less than one year";
      }
    } else {
      let yearsExperience = Math.floor(totalMonths / 12);
      let finalValue = calculateExperience.find((i) =>
        i.range.includes(yearsExperience)
      ).name;
      return finalValue;
    }
  };

  const validationFun = () => {
    let isValid = true;
    let resumes = false;
    let isContactValidated = false;
    let isProfileValidated = false;
    let isExperienceValidated = false;
    let isEducationValidated = false;
    let isCertificationValidated = false;
    let isLicenseValidated = false;
    let skMatrixValidFail = !skillMatrix.every((s) => s.experience !== "");
    /*****************************ContactInfo ***********************/
    let contactInfoVal = contactInfoValidation(contactInfo);
    if (!checkEmptyObject(contactInfoVal.validation)) {
      setContactVal(contactInfoVal.validation);
      setContact(true);
      setLoading(false);
      isValid = false;
      let profileInfoVal = profileInfoValidation(profileData);
      if (!checkEmptyObject(profileInfoVal.validation)) {
        setProfileVal(profileInfoVal.validation);
        setProfileInfo(true);
        setLoading(false);
        isValid = false;
      }
      toast.error(contactInfoVal.validationObj[0]);
    } else if (contactInfo.homePhone == "") {
      isValid = false;
      toast.error("Please Enter Phone Number");
    } else if (!contactInfo.phoneValidation) {
      isValid = false;
      toast.error("Please Enter Valid Primary Phone");
    } else {
      setContactVal(contactInfoVal.validation);
      isContactValidated = true;
      setProfileInfo(true);
      setContact(true);
    }

    /*****************************ProfileInfo ***********************/

    if (isContactValidated) {
      let profileInfoVal = {};
      if (isFrom == "JoinTalentCommunity") {
        profileInfoVal = profileInfoValidation(
          profileData,
          job,
          "JoinTalentCommunity"
        );
      } else {
        profileInfoVal = profileInfoValidation(profileData, job, "ApplyJob");
      }
      if (!checkEmptyObject(profileInfoVal.validation)) {
        setProfileVal(profileInfoVal.validation);
        setProfileInfo(true);
        setLoading(false);
        isValid = false;
        toast.error(profileInfoVal.validationObj[0]);
      } else if (
        profileData.preferredSalary == "" &&
        profileData.minContractRate == ""
      ) {
        isValid = false;
        toast.error("Please Enter Expected Salary....");
      }
      // else if (
      //   isFrom &&
      //   isFrom !== "JoinTalentCommunity" &&
      //   ((job.workPlaceType == "Remote" && !profileData.isRemote) ||
      //     (job.workPlaceType == "Hybrid" && !profileData.isHybrid) ||
      //     (job.workPlaceType == "On-Site" && !profileData.isOnsite))
      // ) {
      //   isValid = false;
      //   toast.error("Please Select Required Location");
      // }
      else if (skMatrixValidFail) {
        toast.error("Please Enter Experience In SkillMatrix");
      } else if (salaryType === "Select") {
        toast.error("Please select rate type");
      } else if (resumeFile.fileName === "") {
        toast.error("Please Upload Your Resume");
      } else if (profileData.linkedIn && !isValidUrl(profileData.linkedIn)) {
        toast.error("Invalid LinkedIn URL");
      } else {
        isProfileValidated = true;
        setProfileVal(profileInfoVal.validation);
        setContact(true);

        resumes = true;
      }
    }

    let obj = {
      isContactValidated,
      isProfileValidated,
      isExperienceValidated,
      isEducationValidated,
      isCertificationValidated,
      isLicenseValidated,
      isValid,
      resumes,
    };
    return obj;
  };

  // Submit Add talent

  const handleSubmit = () => {
    setIsFormValid(true);
    let validObj = validationFun();
    const { isContactValidated, isProfileValidated, resumes } = validObj;
    if (isContactValidated && isProfileValidated && resumes) {
      let screeningQuestions = job?.screeningQuestions
        ? JSON.parse(job?.screeningQuestions)
        : [];
      if (screeningQuestions.length > 0 || component == "SubCommunity") {
        post("/auditLog/candidateData", {
          ...trackedData,

          navigatedPages: trackedData.navigatedPages + "/screening",
          jobId: job.jobId,
          formType: "A",
          poolId: job.poolId,
          resume: resumeFile.fileName ? true : false,
          data: JSON.stringify({
            ...candidateDetails,
            firstName: contactInfo.firstName,
            lastName: contactInfo.lastName,
          }),
        });

        setTrackedData({
          ...trackedData,
          navigatedPages: trackedData.navigatedPages + "/screening",
          jobId: job.jobId,
          poolId: job.poolId,
          resume: resumeFile.fileName ? true : false,
          data: JSON.stringify({
            ...candidateDetails,
            firstName: contactInfo.firstName,
            lastName: contactInfo.lastName,
          }),
        });
        if (component == "SubCommunity") {
          submitAddTalent();
          setIsScreening(true);
        } else {
          setIsScreening(true);
        }
      } else {
        submitAddTalent();
      }
    }
  };

  const submitAddTalent = async () => {
    let talentData = {
      ...contactInfo,
      ...profileData,
      ...educations,
    };

    let experience =
      experiences.length > 1
        ? experiences.filter((item) => checkEmptyObject(item) === false)
        : experiences;
    let education =
      educations.length > 1
        ? educations.filter((item) => checkEmptyObject(item) === false)
        : educations;

    talentData.experiences = experience;
    talentData.educations = education;

    talentData.domainName = domain;
    // talentData.experienceLevel = findRange(
    //   profileData.experienceYear,
    //   profileData.experienceMonth
    // );
    talentData.skillMatrix = skillMatrix.filter(
      (s) => s.skill.trim() !== "none"
    );
    talentData.primarySkills = skillMatrix
      .filter((item) => item.skill.trim() !== "none" || item.experience > 0)
      .map((s) => s.skill);
    talentData.currentEmployer = candidateDetails.currentEmployer;
    talentData.filePath = candidateDetails?.filePath;
    talentData.salaryType = salaryType;
    if (salaryType === "Yearly") {
      talentData.preferredSalaryCurrency = `${talentData.preferredSalaryCurrency}/Year`;
      talentData.minContractRateCurrency = "";
      talentData.minContractRate = "";
    } else if (salaryType === "Monthly") {
      talentData.minContractRateCurrency = `${talentData.minContractRateCurrency}/Month`;
      talentData.preferredSalaryCurrency = "";
      talentData.preferredSalary = "";
    } else if (salaryType === "Hourly") {
      talentData.minContractRateCurrency = `${talentData.minContractRateCurrency}/Hour`;
      talentData.preferredSalaryCurrency = "";
      talentData.preferredSalary = "";
    } else if (salaryType === "Weekly") {
      talentData.minContractRateCurrency = `${talentData.minContractRateCurrency}/Week`;
      talentData.preferredSalaryCurrency = "";
      talentData.preferredSalary = "";
    }

    // post("/auditLog/candidateData", {
    //   ...trackedData,
    //   navigatedPages: trackedData.navigatedPages + "/autoMatchCard",
    //   jobId: job.jobId,
    //   data: JSON.stringify({
    //     ...candidateDetails,
    //   }),
    // });

    // setTrackedData({
    //   ...trackedData,
    //   navigatedPages: trackedData.navigatedPages + "/autoMatchCard",
    //   jobId: job.jobId,
    //   data: JSON.stringify({
    //     ...candidateDetails,
    //   }),
    // });

    setCandidateDetails(talentData);
    setAddTalent(true);
  };

  return (
    <>
      {isScreening ? (
        component == "SubCommunity" ? (
          <PoolScreeningQues
            job={job}
            setAddTalent={setAddTalent}
            setIsVetting={setIsVetting}
            setIsScreening={setIsScreening}
            setScreeningAnswers={setScreeningAnswers}
            candidateDetails={candidateDetails}
            trackedData={trackedData}
            setTrackedData={setTrackedData}
            submitAddTalent={submitAddTalent}
            component={component}
            setApply={setApply}
            setSocialMediaApply={setSocialMediaApply}
            dispositionID={dispositionID}
          />
        ) : (
          <ScreeningQues
            job={job}
            setAddTalent={setAddTalent}
            setIsVetting={setIsVetting}
            setIsScreening={setIsScreening}
            setScreeningAnswers={setScreeningAnswers}
            candidateDetails={candidateDetails}
            trackedData={trackedData}
            setTrackedData={setTrackedData}
            submitAddTalent={submitAddTalent}
            component={component}
          />
        )
      ) : (
        <div style={{ marginTop: "-14px" }}>
          {loading || load || (loadding && <Loader />)}
          <div
            className={`bl-modal-body ${
              education || experience ? "d-none" : ""
            }`}
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <div className="d-flex px-2 justify-content-between my-2">
              <h5 className=" ml-4">Apply </h5>
            </div>
            <hr className="p-0 m-0" />

            <div class=" ">
              <div class="p-2 pb-0 ">
                <div class="">
                  <div className="px-2" v-if="item.isExpanded">
                    <div className="pb-0 mb-0">
                      <div
                        className={
                          true && contact === true
                            ? "ml-0-600 mt-2 mb-1"
                            : "d-none"
                        }
                      >
                        <div class="card  card-lg card-lg  ml-0-600">
                          <div class=" card-body">
                            <h6 className="mb-0">Contact Info</h6>
                            <hr />
                            <Contact
                              contactInfo={contactInfo}
                              setContactInfo={setContactInfo}
                              contactInfoValidation={contactVal}
                              isFormValid={isFormValid}
                              job={job}
                              setRadiusValue={setRadiusValue}
                              editTalent={editTalent}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className={
                          true && profileInfo === true
                            ? " ml-0-600 mt-2 mb-1"
                            : "d-none"
                        }
                      >
                        <div class="card card-lg  ml-0-600">
                          <div class=" card-body ">
                            <h6 className="mb-0">Profile Info</h6>
                            <hr />
                            <Profile
                              profileInfo={profileData}
                              setProfileInfo={setProfileData}
                              profileInfoValidation={profileVal}
                              isFormValid={isFormValid}
                              dropDownData={dropDownData}
                            />
                            <Preferences
                              profileInfo={profileData}
                              setProfileInfo={setProfileData}
                              profileInfoValidation={profileVal}
                              job={job}
                              isFormValid={isFormValid}
                              salaryType={salaryType}
                              setSalaryType={setSalaryType}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={`${!matches ? "d-flex" : ""}`}>
                        <div
                          className={`ml-0-600 mt-2 mb-4 ${
                            !matches ? "col-9" : ""
                          }`}
                        >
                          <div class="card  card-lg  ml-0-600">
                            <div class="card-body pb-0">
                              <h6 className="mb-0">Skill Matrix</h6>
                              <hr />
                              <SkillMatrix
                                matches={matches}
                                job={job}
                                skillMatrix={skillMatrix}
                                setSkillMatrix={setSkillMatrix}
                                isFormValid={isFormValid}
                              />
                            </div>
                          </div>
                        </div>

                        <div
                          className={`d-flex justify-content-around ${
                            !matches
                              ? "flex-column  align-items-start  col-3 py-5 px-4 ml-3 "
                              : ""
                          }`}
                        >
                          <div className=" d-flex align-items-center justify-content-start w-100">
                            <button
                              type="button"
                              className={`btn btn-sm btn-secondary mx-2${
                                !matches ? " w-50 " : ""
                              }`}
                              onClick={() => {
                                setExperience(true);
                              }}
                            >
                              Add Experience
                            </button>{" "}
                            {(experiences?.length > 1 ||
                              !areAllKeysEmpty(experiences[0])) && (
                              <span className="text-success font-22 ">
                                <i class="fas fa-check-circle"></i>
                              </span>
                            )}
                          </div>
                          <div className=" d-flex align-items-center justify-content-start w-100">
                            <button
                              type="button"
                              className={`btn btn-sm btn-secondary mx-2${
                                !matches ? " w-50 " : ""
                              }`}
                              onClick={() => {
                                setEducation(true);
                              }}
                            >
                              Add Education
                            </button>
                            {(educations?.length > 1 ||
                              !areAllKeysEmpty(educations[0])) && (
                              <span className="text-success font-22 ">
                                <i class="fas fa-check-circle"></i>
                              </span>
                            )}
                          </div>
                          {/* <div className="d-flex align-items-center justify-content-center w-100"> */}
                          <ResumeParsed
                            resumeFile={resumeFile}
                            setResumeFile={setResumeFile}
                            matches={matches}
                            isFormValid={isFormValid}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="p-0 m-0" />
            <div className="row mx-auto my-3 px-4 ">
              <div className="col-md-12 d-flex justify-content-end ">
                <button
                  className="btn btn-sm btn-secondary mr-2"
                  onClick={() => {
                    setApply(false);
                    setSocialMediaApply(false);

                    setIsFormValid(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className={`btn btn-sm ${
                    preferenceTab ? "btn-primary" : ""
                  } `}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          {education && (
            <Modal size={"xl"} show={education} backdrop="static">
              <div class="bl-modal-header">
                <h6 class="bl-modal-title">Add Education</h6>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setEducation(false);
                  }}
                  class="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Modal.Body>
                <div>
                  <hr />
                  <Education
                    educations={educations}
                    setEducations={setEducations}
                    educationValidation={educationVal}
                    setEducationValidation={setEducationVal}
                    preferenceTab={preferenceTab}
                    setEducation={setEducation}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )}
          {experience && (
            <Modal size={"xl"} show={experience}>
              <div class="bl-modal-header">
                <h6 class="bl-modal-title">Add Experience</h6>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setExperience(false);
                  }}
                  class="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Modal.Body>
                <div>
                  <hr />
                  <Experience
                    experiences={experiences}
                    setExperiences={setExperiences}
                    experienceValidation={experienceVal}
                    setExperienceValidation={setExperienceVal}
                    dropDownData={dropDownData}
                    preferenceTab={preferenceTab}
                    setExperience={setExperience}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default Index;
