import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import moment from "moment";
import { toast } from "react-toastify";
import Pagination from "../../../../commoncomponents/pagination/Pagination";
import { post } from "../../../../../../service/ApiService";
import Loader from "../../../../../Loader/Loader";
import { inActiveOptions } from "../../../../../../constants/Constants";

const Index = (props) => {
  const { bulkDeactivate, setBulkDeactivate, closeModal } = props;
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [filterOption, setFilterOption] = useState("neverLoggedIn");
  const [lastNWeeks, setLastNWeeks] = useState(3);
  const dataSize = 10;
  const pageSize = 10;
  const [recruiterData, setRecruiterData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);

  useEffect(() => {
    getRecruiter(null);
  }, []);

  const getRecruiter = async (fromDate) => {
    setLoading(true);
    const params = {
      fromDate: fromDate,
    };
    try {
      const res = await post("/gigrecruiter/getinactiverecruiter", params);
      if (res.status === 200) {
        setRecruiterData(res.data);
        setFilteredData(res.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (data, checked) => {
    if (checked) {
      setSelectedData([...selectedData, data]);
    } else {
      const filterData = selectedData.filter(
        (item) => item.id_addgig !== data.id_addgig
      );
      setSelectedData(filterData);
    }
  };

  const handleCheckboxAllChange = (checked) => {
    if (checked) {
      const result = [...selectedData, ...filteredData].filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.id_addgig === item.id_addgig)
      );
      setSelectedData(result);
    } else {
      setSelectedData(
        selectedData.filter(
          (itemA) =>
            !filteredData.some((itemB) => itemA.id_addgig === itemB.id_addgig)
        )
      );
    }
  };

  const handleFilterChange = (option) => {
    setFilterOption(option);
    setBulkDeactivate(true);
  };

  const handleLastNWeeksChange = (e) => {
    setLastNWeeks(parseInt(e.target.value));
  };

  const handleDeactivate = async () => {
    setLoading(true);
    const params = {
      jsonData: selectedData,
    };
    const res = await post("/gigrecruiter/bulkdeactivaterecruiter", params);
    setLoading(false);
    if (res.status === 200) {
      closeModal(true);
      toast.success("Recruiters have been deactivated successfuly");
    } else {
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  const handleSearch = (val) => {
    if (val) {
      setSearchTerm(val);
      const searchData = recruiterData.filter(
        (item) =>
          item.email.toLowerCase().includes(val.toLowerCase()) ||
          item.firstName.toLowerCase().includes(val.toLowerCase()) ||
          item.LastName.toLowerCase().includes(val.toLowerCase())
      );
      setFilteredData(searchData);
    } else {
      setSearchTerm(val);
      setFilteredData(recruiterData);
    }
    setCurrentPage(1);
  };

  const handleSubmit = () => {
    if (filterOption == "neverLoggedIn") {
      getRecruiter(null, null);
    } else {
      const today = new Date();
      let specificDate = new Date();
      specificDate.setDate(today.getDate() - lastNWeeks * 7);
      const fromDate = moment(new Date(specificDate)).format("YYYY-MM-DD");
      getRecruiter(fromDate);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        size="lg"
        show={bulkDeactivate}
        onHide={() => closeModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Deactivate Recruiters</Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body className="p-2">
          <div
            className="row d-flex justify-content-start mb-3 mr-2"
            id="deActivateFilters"
          >
            <div className="col-lg-3">
              <div className="form-check d-flex align-items-baseline">
                <input
                  type="radio"
                  name="filterOption"
                  id="neverLoggedIn"
                  className="form-check-input custom-radio ml-2"
                  value="neverLoggedIn"
                  checked={filterOption === "neverLoggedIn"}
                  onChange={() => handleFilterChange("neverLoggedIn")}
                />
                <label className="form-check-label d-flex align-items-center ml-3">
                  Never logged in
                </label>
              </div>
            </div>

            <div className="col-lg-5">
              <div className="form-check d-flex align-items-baseline justify-content-between w-100">
                <input
                  type="radio"
                  name="filterOption"
                  id="lastNWeeks"
                  className="form-check-input custom-radio"
                  value="lastNWeeks"
                  checked={filterOption === "lastNWeeks"}
                  onChange={() => handleFilterChange("lastNWeeks")}
                />
                <label
                  htmlFor="lastNWeeks"
                  className="form-check-label d-flex align-items-centerml-2"
                >
                  Not logged in for last{"  "}
                </label>
                <select
                  className="form-select"
                  value={lastNWeeks}
                  onChange={handleLastNWeeksChange}
                  style={{
                    width: "42%",
                    fontSize: "13.5px",
                    marginLeft: "2.5px",
                  }}
                >
                  {inActiveOptions.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="col-lg-1">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                onClick={() => handleSubmit()}
                style={{
                  // width: "100%",
                  fontSize: "14px",
                  marginTop: "12px",
                }}
              >
                Go
              </button>
            </div>

            <div className="col-lg-3">
              <div
                className="d-flex align-items-baseline justify-content-start w-100"
                style={{
                  marginTop: "10.5px",
                }}
              >
                <input
                  type="search"
                  placeholder="Search..."
                  style={{
                    fontSize: "14px",
                    // width: "100px",
                  }}
                  value={searchTerm}
                  onChange={(e) => handleSearch(e.target.value)}
                  className="form-control"
                />
              </div>
            </div>
          </div>

          <table className="mt-2" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th className="text-center">
                  <input
                    type="checkbox"
                    className="pointer"
                    checked={
                      filteredData.length > 0 &&
                      selectedData.length == filteredData.length
                    }
                    onChange={(e) => handleCheckboxAllChange(e.target.checked)}
                  />
                </th>
                <th>Name</th>
                <th>Email ID</th>
                <th>Last logged in</th>
              </tr>
            </thead>
            <tbody>
              {filterOption === "" ? (
                <tr>
                  <td class="text-center p-3" colSpan={4}>
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">Select any filter</div>
                  </td>
                </tr>
              ) : recruiterData.length > 0 ? (
                paginationArray.map((row) => (
                  <tr
                    key={row.id_addgig}
                    style={{
                      borderBottom: "1px solid #f1f1f1",
                      height: "40px",
                    }}
                  >
                    <td className="w-10">
                      <input
                        type="checkbox"
                        className="pointer"
                        checked={selectedData.some(
                          (item) => item.id_addgig === row.id_addgig
                        )}
                        onChange={(e) =>
                          handleCheckboxChange(row, e.target.checked)
                        }
                      />
                    </td>
                    <td>{`${row.firstName} ${row.LastName}`}</td>
                    <td>{row.email}</td>
                    <td>
                      {row.uadLastLogInTime
                        ? moment(
                            new Date(row.uadLastLogInTime),
                            "YYYYMMDD"
                          ).format("MM/DD/YYYY")
                        : "Never"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td class="text-center p-3" colSpan={4}>
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="ml-auto">
            <Pagination
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              setPaginationArray={setPaginationArray}
              data={filteredData}
              dataSize={dataSize}
              getTab=""
              pageSize={pageSize}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal(false)}>
            Close
          </Button>
          {filterOption === "" ? (
            <Button variant="primary" disabled>
              Deactivate
            </Button>
          ) : (
            <Button
              variant="primary"
              onClick={handleDeactivate}
              disabled={selectedData.length == 0}
            >
              Deactivate
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Index;
