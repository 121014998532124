import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router";
import LocationFilter from "./locationFilter/LocationFilter";
import DDMS from "./dropDownMSFilter/DDMSFilter";
import {
  visaStatus,
  countryList,
  experienceOptions,
  talentAcquisitionJobType,
} from "../../../constants/Constants";
import BooleanFilter from "./booleanFilter/BooleanFilter";

import Loader from "../../Loader/Loader";
import { getTalentCommunitySourceList } from "../../../actions/directsourcing/talentcommunity/TalentSourceFilter";
import Suggestion from "./inputSuggestion/Suggestion";
import { getSCTalentCertificationList } from "../../../actions/directsourcing/talentcommunity/Certification";
import { getSCTalentLicenseStateList } from "../../../actions/directsourcing/talentcommunity/LicenseState";
import { getSCTalentNursingSpecialtyList } from "../../../actions/directsourcing/talentcommunity/NursingSpecialty";
import { getTalentStateSubCommList } from "../../../actions/directsourcing/talentcommunity/talentStateSubCommFilter";
import { getTalentCountrySubCommList } from "../../../actions/directsourcing/talentcommunity/talentCountrySubCommFilter";

const SubcommunityFilter = (props) => {
  const { poolId } = useParams();
  const dispatch = useDispatch();
  const {
    selectedData,
    setSelectedData,
    applyFilter,
    inputList,
    setInputList,
    skillInputList,
    setSkillInputList,
    isFilterApplied,
    selectedTalents,
    setSelectedTalents,
    filterParams,
  } = props;
  const [
    internationalPlacementOption,
    setInternationalPlacementOption,
  ] = useState([]);
  const [talentAcquisitionOption, setTalentAcquisitionOption] = useState([]);
  const [collapsible, setCollapsible] = useState({
    city: false,
    state: false,
    country: false,
    // candidateLocation: false,
    experienceLevel: false,
    skills: false,
    keywordSearch: false,
    certifications: false,
    resumeLastUpdate: false,
    referralStatus: false,
    sourceChannel: false,
    relocationPreference: false,
    recruiterName: false,
    internationalPlacement: false,
    nursingSpecialty: false,
    talentAcquisition: false,
  });

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCertification, setSelectedCertification] = useState("");
  const [selectedNursingSpecialty, setSelectedNursingSpecialty] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [sourceChannelsOptions, setSourceChannelsOptions] = useState([]);

  const additionalVisaStatus = [
    { value: "Not Required", label: "Not Applicable" },
    { value: "Need Sponsorship", label: "Need Sponsorship" },
  ];

  const combinedVisaStatus = [...visaStatus, ...additionalVisaStatus];
  const uniqueVisaStatus = Array.from(
    new Set(combinedVisaStatus.map(JSON.stringify))
  ).map(JSON.parse);

  const visaStatusOptions = uniqueVisaStatus;

  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [certificationsOptions, setCertificationsOptions] = useState([]);
  const [licenseStateOptions, setLicenseStateOptions] = useState([]);
  const [nursingSpecialtyOptions, setNursingSpecialtyOptions] = useState([]);

  const {
    stateLoading,
    stateData,
    countryLoading,
    countryData,
    sourceLoading,
    sourceData,
    certificationData,
    certificationLoading,
    nursingSpecialtyData,
    nursingSpecialtyLoading,
    licenseStateData,
    licenseStateLoading,
  } = useSelector(
    (state) => ({
      stateLoading: state.stateSubCommData.loading,
      stateData: state.stateSubCommData.stateData,
      countryLoading: state.countrySubCommData.loading,
      countryData: state.countrySubCommData.countryData,
      sourceLoading: state.getTalentCommunitySource.loading,
      sourceData: state.getTalentCommunitySource.sourceData,
      certificationData: state.SCTalentCertification.certificationData,
      certificationLoading: state.SCTalentCertification.loading,
      nursingSpecialtyData: state.SCTalentNursingSpecialty.nursingSpecialtyData,
      nursingSpecialtyLoading: state.SCTalentNursingSpecialty.loading,
      licenseStateData: state.SCTalentLicenseState.licenseStateData,
      licenseStateLoading: state.SCTalentLicenseState.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    setInternationalPlacementOption(
      countryList.map((e) => ({ value: e, label: e }))
    );
    setTalentAcquisitionOption(
      talentAcquisitionJobType.map((e) => ({ value: e, label: e }))
    );
  }, []);

  const handleFilterToggle = (filter) => {
    setCollapsible({
      candidateLocation: false,
      experienceLevel: false,
      skills: false,
      keywordSearch: false,
      certifications: false,
      resumeLastUpdate: false,
      referralStatus: false,
      sourceChannel: false,
      relocationPreference: false,
      recruiterName: false,
      internationalPlacement: false,
      nursingSpecialty: false,
      talentAcquisition: false,
      [filter]: !collapsible[filter],
    });
  };

  const handleAPICalls = (filter) => {
    if (filter == "sourceChannel") {
      const payload = {
        poolId: poolId,
        filterParams: filterParams,
      };
      dispatch(getTalentCommunitySourceList(payload));
    }
    if (filter == "state") {
      const payload = {
        text: "",
        poolId: poolId,
        filterParams: filterParams,
      };
      dispatch(getTalentStateSubCommList(payload));
    }
    if (filter == "country") {
      const payload = {
        text: "",
        poolId: poolId,
        filterParams: filterParams,
      };
      dispatch(getTalentCountrySubCommList(payload));
    }
    if (filter == "certifications") {
      const payload = {
        text: "",
        poolId: poolId,
        filterParams: filterParams,
      };
      dispatch(getSCTalentCertificationList(payload));
      dispatch(getSCTalentLicenseStateList(payload));
    }
    if (filter == "nursingSpecialty") {
      const payload = {
        text: "",
        poolId: poolId,
        filterParams: filterParams,
      };
      dispatch(getSCTalentNursingSpecialtyList(payload));
    }
  };

  const fetchStates = async (searchValue) => {
    if (collapsible.state) {
      const payload = {
        text: searchValue ?? "",
        poolId: poolId,
      };
      dispatch(getTalentStateSubCommList(payload));
    }
  };

  const fetchCountries = async (searchValue) => {
    if (collapsible.country) {
      const payload = {
        text: searchValue ?? "",
        poolId: poolId,
      };
      dispatch(getTalentCountrySubCommList(payload));
    }
  };

  const fetchCertification = async (searchValue) => {
    if (collapsible.certifications) {
      const payload = {
        text: searchValue ?? "",
        poolId: poolId,
      };
      dispatch(getSCTalentCertificationList(payload));
    }
  };

  const fetchLicenseState = async (searchValue) => {
    if (collapsible.certifications) {
      const payload = {
        text: searchValue ?? "",
        poolId: poolId,
        filterParams: filterParams,
      };
      dispatch(getSCTalentLicenseStateList(payload));
    }
  };

  const fetchNursingSpecialty = async (searchValue) => {
    if (collapsible.nursingSpecialty) {
      const payload = {
        text: searchValue ?? "",
        poolId: poolId,
        filterParams: filterParams,
      };
      dispatch(getSCTalentNursingSpecialtyList(payload));
    }
  };

  useEffect(() => {
    if (stateData && stateData?.length > 0) {
      const formattedStates = stateData?.map((item) => ({
        label: item.state + " " + `(${item.talentCount})`,
        value: item.state,
        count: item.talentCount,
      }));
      setStateOptions(formattedStates);
    }
    if (countryData && countryData?.length > 0) {
      const formattedCountries = countryData?.map((item) => ({
        label: item.country + " " + `(${item.talentCount})`,
        value: item.country,
        count: item.talentCount,
      }));
      setCountryOptions(formattedCountries);
    }
    if (sourceData && Object.keys(sourceData).length > 0) {
      let sourceArray = [];

      for (const key in sourceData) {
        if (Object.hasOwnProperty.call(sourceData, key)) {
          const value = sourceData[key];
          sourceArray = [
            ...sourceArray,
            {
              label: `${key} (${value})`,
              value: key,
            },
          ];
        }
      }
      setSourceChannelsOptions(sourceArray);
    }
    if (certificationData && certificationData?.length > 0) {
      const formattedCountries = certificationData?.map((item) => ({
        label: item.certificate + " " + `(${item.talentCount})`,
        value: item.certificate,
        count: item.talentCount,
      }));
      setCertificationsOptions(formattedCountries);
    }
    if (licenseStateData && licenseStateData?.length > 0) {
      const formattedCountries = licenseStateData?.map((item) => ({
        label: item.License,
        value: item.License,
        count: item.talentCount,
      }));
      setLicenseStateOptions(formattedCountries);
    }
    if (nursingSpecialtyData && nursingSpecialtyData?.length > 0) {
      const formattedCountries = nursingSpecialtyData?.map((item) => ({
        label: item.NursingSpecialty + " " + `(${item.talentCount})`,
        value: item.NursingSpecialty,
        count: item.talentCount,
      }));
      setNursingSpecialtyOptions(formattedCountries);
    }
  }, [
    countryData,
    stateData,
    certificationData,
    licenseStateData,
    nursingSpecialtyData,
    sourceData,
  ]);

  const handleClear = () => {
    setSelectedData({
      certifications: [],
      experienceLevel: [],
      country: "",
      state: "",
      address: [],
      sourceChannel: [],
      internationalPlacement: [],
      talentAcquisition: [],
      nursingSpecialty: [],
      licenseStates: [],
    });
    setInputList({
      term1: [],
      term2: [],
      term3: [],
    });
    setSkillInputList({
      term1: [],
      term2: [],
      term3: [],
    });
    applyFilter("", "");
  };

  const filterLength =
    selectedData?.sourceChannel.length +
    selectedData?.experienceLevel.length +
    (selectedData?.country ? 1 : 0) +
    (selectedData?.state ? 1 : 0) +
    selectedData?.address.length +
    selectedData?.certifications.length +
    selectedData?.licenseStates.length +
    selectedData?.nursingSpecialty.length +
    selectedData?.internationalPlacement.length +
    selectedData?.talentAcquisition.length +
    inputList?.term1.length +
    inputList?.term2.length +
    inputList?.term3.length +
    skillInputList?.term1.length +
    skillInputList?.term2.length +
    skillInputList?.term3.length +
    isFilterApplied
      ? 1
      : 0;

  return (
    <>
      <div className="card-flat">
        <div className="card-body">
          <div className="view-panel-header view-panel-header-sm">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="px-3"></div>
              <div className="px-3">
                <button
                  disabled={filterLength == 0}
                  type="button"
                  className="btn btn-text"
                  onClick={handleClear}
                >
                  <span className="font-bold">Clear All</span>
                </button>
              </div>
            </div>
          </div>
          <div className="view-panel-body view-panel-body-sm">
            {/* Skills */}
            <BooleanFilter
              handleFilterToggle={handleFilterToggle}
              // setFilterEnabled={setKeywordSearchEnabled}
              filterEnabled={collapsible.skills}
              filterName={"Skills"}
              selectedData={selectedData}
              filterStatus={"skills"}
              inputList={skillInputList}
              setInputList={setSkillInputList}
              applyFilter={applyFilter}
              // options={skillsOptions}
            />
            {/* Keyword Search */}
            <BooleanFilter
              handleFilterToggle={handleFilterToggle}
              // setFilterEnabled={setKeywordSearchEnabled}
              filterEnabled={collapsible.keywordSearch}
              filterName={"Keywords"}
              selectedData={selectedData}
              filterStatus={"keywordSearch"}
              inputList={inputList}
              setInputList={setInputList}
              applyFilter={applyFilter}
              // options={skillsOptions}
            />

            {/* Certifications */}
            {/* <Suggestion
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              setCandidateLocationEnabled={collapsible.certifications}
              candidateLocationEnabled={collapsible.certifications}
              filterName={"Certification/License"}
              filterStatus={"certifications"}
              stateArr={stateOptions}
              countryArr={certificationsOptions}
              licenseStateArr={licenseStateOptions}
              selectedCountry={selectedCertification}
              setSelectedCountry={setSelectedCertification}
              fetchCountries={fetchCertification}
              fetchLicenseState={fetchLicenseState}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              fetchStates={fetchStates}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoadingCountry={certificationLoading}
              isLoadingLS={licenseStateLoading}
              isLoadingState={stateLoading}
              // options={skillsOptions}
            /> */}

            {/* Nursing Specialty */}
            {/* <Suggestion
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              setCandidateLocationEnabled={collapsible.nursingSpecialty}
              candidateLocationEnabled={collapsible.nursingSpecialty}
              filterName={"Specialties"}
              filterStatus={"nursingSpecialty"}
              stateArr={stateOptions}
              countryArr={nursingSpecialtyOptions}
              selectedCountry={selectedNursingSpecialty}
              setSelectedCountry={setSelectedNursingSpecialty}
              fetchCountries={fetchNursingSpecialty}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              fetchStates={fetchStates}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoadingCountry={nursingSpecialtyLoading}
              isLoadingState={stateLoading}
              // options={skillsOptions}
            /> */}

            {/*Location*/}
            <LocationFilter
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              cityEnabled={collapsible.city}
              stateEnabled={collapsible.state}
              countryEnabled={collapsible.country}
              filterStatus={"candidateLocation"}
              stateArr={stateOptions}
              countryArr={countryOptions}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
              fetchCountries={fetchCountries}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              fetchStates={fetchStates}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoadingCountry={countryLoading}
              isLoadingState={stateLoading}
            />

            {/* Experience Level */}

            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.experienceLevel}
              filterName={"Experience Level"}
              filterStatus={"experienceLevel"}
              options={experienceOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              selected={selectedExperience}
              setSelected={setSelectedExperience}
              applyFilter={applyFilter}
              isLoading={false}
            />
            {/* International Placement */}
            {/* <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.internationalPlacement}
              filterName={"International Placement (Countries)"}
              filterStatus={"internationalPlacement"}
              options={internationalPlacementOption}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
            /> */}
            {/* Talent Acquisition */}
            {/* <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.talentAcquisition}
              filterName={"Talent Acquisition (Job Type)"}
              filterStatus={"talentAcquisition"}
              options={talentAcquisitionOption}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
            /> */}

            {/* Source Channel */}
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.sourceChannel}
              filterName={"Source Channel"}
              options={sourceChannelsOptions}
              filterStatus={"sourceChannel"}
              isMulti={false}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={sourceLoading}
              hideDropdown={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SubcommunityFilter;
