import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const CSMDashboardAnalytics = (props) => {
  const { CSM_daily, CSM_monthly, CSM_total, CSM_weekly } = props.data;
  const [csmTimeInterval, setCsmTimeInterval] = useState("daily");
  const [loggedInRecruiters, setLoggedInRecruiters] = useState(0);
  const [loggedInCount, setLoggedInCount] = useState(0);
  const [chartSeries, setChartSeries] = useState([
    {
      name: "Customer Success Managers Login",
      data: [],
      color: "#55c3c3",
    },
  ]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
    },
    dataLabels: {
      enabled: false,
    },
  });
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day} ${month}`;
  }

  const generateChartData = (timeInterval) => {
    let categories = [];
    let data = [];
    let count = 0;
    if (timeInterval === "daily") {
      categories = CSM_daily?.map((item) => formatDate(item.category));
      data = CSM_daily?.map((item) => item.data);
      CSM_daily?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInRecruiters(CSM_daily && CSM_daily[CSM_daily?.length - 1].data);
    } else if (timeInterval === "weekly") {
      categories = CSM_weekly?.map((item) => formatDate(item.category));
      data = CSM_weekly?.map((item) => item.data);
      CSM_weekly?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInRecruiters(
        CSM_weekly && CSM_weekly[CSM_weekly?.length - 1].data
      );
    } else if (timeInterval === "monthly") {
      categories = CSM_monthly?.map((item) => item.category);
      data = CSM_monthly?.map((item) => item.data);
      CSM_monthly?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInRecruiters(
        CSM_monthly && CSM_monthly[CSM_monthly?.length - 1].data
      );
    }

    setChartSeries([
      {
        name: "Customer Success Managers Login",
        data: data,
      },
    ]);
    setChartOptions({
      ...chartOptions,
      xaxis: {
        categories: categories,
      },
    });
  };

  useEffect(() => {
    generateChartData(csmTimeInterval);
  }, [csmTimeInterval]);

  useEffect(() => {
    generateChartData("daily");
  }, []);
  return (
    <>
      {/* Customer Success Manager Login */}
      <div className="card analyticCards custom-card">
        <h6 className="text-center mt-3 mb-3">
          Customer Success Manager Login
        </h6>
        <div className="d-flex flex-wrap justify-content-center">
          <button
            onClick={() => setCsmTimeInterval("daily")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                csmTimeInterval === "daily" ? "#17a2b8" : "white",
              color: csmTimeInterval === "daily" ? "white" : "#17a2b8",
            }}
          >
            Daily
          </button>
          <button
            onClick={() => setCsmTimeInterval("weekly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                csmTimeInterval === "weekly" ? "#17a2b8" : "white",
              color: csmTimeInterval === "weekly" ? "white" : "#17a2b8",
            }}
          >
            Weekly
          </button>
          <button
            onClick={() => setCsmTimeInterval("monthly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                csmTimeInterval === "monthly" ? "#17a2b8" : "white",
              color: csmTimeInterval === "monthly" ? "white" : "#17a2b8",
            }}
          >
            Monthly
          </button>
        </div>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={250}
        />
        <div className="card m-2 align-items-center">
          <p className="mt-2 fw-light">
            {`Total Customer Success Manager${
              CSM_total !== 1 ? "s" : ""
            }: ${CSM_total}`}
          </p>
          <p className="fw-light">
            {`Logged In Customer Success Manager${
              loggedInCount !== 1 ? "s" : ""
            }: ${loggedInCount}`}
          </p>
        </div>
      </div>
    </>
  );
};

export default CSMDashboardAnalytics;
