import React from "react";
import { Modal } from "react-bootstrap";

const ShowVettingModal=(props)=>{
    return(<>

<div className="bg-gray3">
          <Modal show={true}>
            <Modal.Body>
              <div className="text-center mt-2 px-2">
                <div className="avatar avatar-lg avatar-red2 mr-3">
                  <i class="fas fa-exclamation-triangle fa-fw"></i>
                </div>
                <p className="text-center lead mt-3">
                  Existing data will be erased
                </p>
                <div class="text-center my-4">
                  <button
                    type="button"
                    className="btn btn-secondary mr-3"
                    onClick={() => {
                      props?.setShowVettingModal(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => {
                      props?.setCheckedAssessments([]);
                      props?.setShowVettingModal(false);
                      props?.setAddJobData({ ...props?.addJobData, vettingRequired: false });
                      props?.setScreeningQuestions([]);
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
    </>)
}


export default ShowVettingModal