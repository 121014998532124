import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import TopNavBar from "../../layout/TopNavBar";
import SideNavBar from "../../layout/SideNavBar";
import EmailBroadCast from "./EmailBroadCast";
import { fetchAllSentMails } from "../../../actions/broadcast/broadcast";
import { getCandidateId, getTenantType } from "../../../service/AuthService";
import Loader from "../../Loader/Loader";
import Footer from "../../layout/Footer";

const Index = () => {
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState(true);
  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const { broadCastLoading, broadCastData } = useSelector(
    (state) => ({
      broadCastLoading: state.broadCastReducer.broadCastLoading,
      broadCastData: state.broadCastReducer.broadCastData,
    }),
    shallowEqual
  );

  useEffect(() => {
    getAllSentEmails();
  }, []);

  const getAllSentEmails = () => {
    dispatch(fetchAllSentMails({ userId: getCandidateId() }));
  };

  return (
    <body
      className={
        toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
      }
    >
      <div className="body-decorator body-decorator-top"></div>
      <div className="body-decorator body-decorator-bottom"></div>
      <TopNavBar handleToggale={toggleDiv} />
      <SideNavBar />
      {broadCastLoading && <Loader />}
      <div id="Content">
        <div className="section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div id="TenantDashboard" className="row mb-3">
                  <div className="col-lg-12">
                    <div className="card">
                      <h6 className="mb-xl-0">
                        <EmailBroadCast
                          broadCastData={broadCastData}
                          getAllSentEmails={getAllSentEmails}
                        />
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {getTenantType() == 1 && <Footer />}
      </div>
    </body>
  );
};

export default Index;
