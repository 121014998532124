import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Button, Modal } from "react-bootstrap";
import { getUserRole } from "../../../../service/AuthService";
import { post } from "../../../../service/ApiService";
import Loader from "../../../Loader/Loader";
import Pagination from "react-pagination-js";
import ERHeader from "./ERHeader";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import ERTable from "./ERTable";
import ECTable from "./ECTable";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";

const EmailRecipients = (props) => {
  const history = useHistory();
  const {
    selectedJob,
    handleApplyClick,
    setModalClose,
    modalFor,
    setFilter,
    closeModal,
    setRecipientData,
    Recipientdata,
    tableStructureCan,
    selected,
    setSelected,
  } = props;
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [skills, setSkills] = useState([]);
  const [location, setLocation] = useState("");
  const [experience, setExperience] = useState("");
  const [dispositionStatus, setDispositionStatus] = useState("");
  const [allSelected, setAllSelected] = useState(false);
  const [apiRecCalled, setApiRecCalled] = useState(false);
  const [apiCanCalled, setApiCanCalled] = useState(false);
  const [confirm1000, setConfirm1000] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [total, setTotal] = useState(0);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [toDate, setToDate] = useState("");
  const [data, setData] = useState([]);
  const [recEmail, setRecEmail] = useState("");
  const [selectedRegion, setSelectedRegion] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState([]);
  const pageSize = 10;
  const handleClear = () => {
    setData([]);
    setTotal(0);
    setSelectedRecipients([]);
    setSelectedCandidates([]);
    // setSelected(true);
  };

  const checkAllSelected = () => {
    let val = true;
    data.forEach((x) => {
      if (!x?.isSelected) val = false;
    });
    setAllSelected(val);
  };
  const selectAll = () => {
    let d = data.map((x) => {
      return { ...x, isSelected: allSelected ? false : true };
    });
    setData(d);
  };
  const selectOne = (item, index) => {
    let d = data.map((x, i) => {
      return index === i ? { ...x, isSelected: !x.isSelected } : x;
    });
    modalFor === "addRecruiter"
      ? setSelectedRecipients(d.filter((x) => x.isSelected))
      : setSelectedCandidates(d.filter((x) => x.isSelected));
    setData(d);
  };
  const changeCurrentPage = async (number) => {
    setCurrentPage(number);
    setLoading(true);
    const params =
      modalFor === "addRecruiter"
        ? {
            createdDateFrom: fromDate || null,
            createdDateTo: toDate || null,
            firstName: name.split(" ")[0] || "",
            lastName:
              name.split(" ").length > 1 ? name.split(" ").pop() : "" || "",
            email: recEmail || "",
            // region: [selectedRegion] || [],
            // jobCategory: [selectedCategory] || [],
            // subCategory: selectedSubCategory || [],
            userType: modalFor,
            pageNumber: number,
            pageSize: pageSize,
            jobId: selectedJob.jobId,
          }
        : {
            createdDateFrom: fromDate || null,
            createdDateTo: toDate || null,
            userType: modalFor,
            firstName: name.split(" ")[0] || "",
            lastName:
              name.split(" ").length > 1 ? name.split(" ").pop() : "" || "",
            skills: skills || [],
            city: city || "",
            email: recEmail || "",

            country: country || "",
            experience: experience || "",
            userType: modalFor,
            pageNumber: number,
            pageSize: pageSize,
            jobId: selectedJob.jobId,
            // dispositionStatus: dispositionStatus,
          };

    const res = await post("/broadCast/fetchRecipients", params);
    if (res.status === 200) {
      setLoading(false);
      let r = res?.data?.result.map((d) => {
        return { ...d, isSelected: false };
      });
      modalFor === "addRecruiter"
        ? setApiRecCalled(true)
        : setApiCanCalled(true);
      setTotal(res?.data?.total);

      setData(r);
    }
  };

  useEffect(() => {
    checkAllSelected();
  }, [data]);

  const validationFunction = () => {
    let isValid = true;

    if (
      fromDate === "" &&
      toDate === "" &&
      name === "" &&
      location === "" &&
      recEmail === "" &&
      skills?.length === 0 &&
      experience === ""
    ) {
      isValid = false;
      toast.error("Select at least one filter");
    }
    if (fromDate !== "" && toDate === "") {
      isValid = false;
      toast.error("Select the To Date");
    }
    if (fromDate === "" && toDate !== "") {
      isValid = false;
      toast.error("Select the From Date");
    }
    if (fromDate > toDate) {
      isValid = false;
      toast.error("Created from date cannot be greater than Created to date");
    }
    return isValid;
  };
  const fetchData = async (selectAll) => {
    try {
      const isValid = validationFunction();
      if (isValid) {
        const params =
          modalFor === "addRecruiter"
            ? selectAll
              ? {
                  createdDateFrom: fromDate || null,
                  createdDateTo: toDate || null,
                  firstName: name.split(" ")[0] || "",
                  lastName:
                    name.split(" ").length > 1
                      ? name.split(" ").pop()
                      : "" || "",
                  email: recEmail || "",
                  // region: [selectedRegion] || [],
                  // jobCategory: [selectedCategory] || [],
                  // subCategory: selectedSubCategory || [],
                  userType: modalFor,
                  selectAll: true,
                  jobId: selectedJob.jobId,
                }
              : {
                  createdDateFrom: fromDate || null,
                  createdDateTo: toDate || null,
                  firstName: name.split(" ")[0] || "",
                  lastName:
                    name.split(" ").length > 1
                      ? name.split(" ").pop()
                      : "" || "",
                  email: recEmail || "",
                  // region: [selectedRegion] || [],
                  // jobCategory: [selectedCategory] || [],
                  // subCategory: selectedSubCategory || [],
                  userType: modalFor,
                  pageNumber: currentPage,
                  pageSize: pageSize,
                  jobId: selectedJob.jobId,
                }
            : modalFor === "addCandidate"
            ? selectAll
              ? {
                  createdDateFrom: fromDate || null,
                  createdDateTo: toDate || null,
                  userType: modalFor,
                  firstName: name.split(" ")[0] || "",
                  lastName:
                    name.split(" ").length > 1
                      ? name.split(" ").pop()
                      : "" || "",
                  skills: skills || [],
                  city: city || "",
                  country: country || "",
                  email: recEmail || "",

                  experience: experience || "",
                  userType: modalFor,
                  selectAll: true,
                  jobId: selectedJob.jobId,

                  // dispositionStatus: dispositionStatus,
                }
              : {
                  createdDateFrom: fromDate || null,
                  createdDateTo: toDate || null,
                  userType: modalFor,
                  firstName: name.split(" ")[0] || "",
                  lastName:
                    name.split(" ").length > 1
                      ? name.split(" ").pop()
                      : "" || "",
                  skills: skills || [],
                  city: city || "",
                  email: recEmail || "",

                  country: country || "",
                  experience: experience || "",
                  userType: modalFor,
                  pageNumber: currentPage,
                  pageSize: pageSize,
                  jobId: selectedJob.jobId,

                  // dispositionStatus: dispositionStatus,
                }
            : "";
        setLoading(true);
        setConfirm1000(false);
        const res = await post("/broadCast/fetchRecipients", params);
        if (res.status === 200) {
          setLoading(false);

          let r = res?.data?.result.map((d, index) => {
            return {
              ...d,
              isSelected: selectAll && index < 1000 ? true : false,
            };
          });

          setAllSelected(true);
          modalFor === "addRecruiter"
            ? setSelectedRecipients(r.filter((x) => x.isSelected))
            : setSelectedCandidates(r.filter((x) => x.isSelected));
          modalFor === "addRecruiter"
            ? setApiRecCalled(true)
            : setApiCanCalled(true);
          setTotal(res?.data?.total);
          setData(r);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const broadcastAll = () => {
    let emails = Recipientdata.map((r) => r?.email);
    let rdata = Recipientdata;

    modalFor === "addRecruiter"
      ? selectedRecipients.forEach((e) => {
          if (!emails.includes(e.email)) rdata.push(e);
        })
      : selectedCandidates.forEach((e) => {
          if (!emails.includes(e.email)) rdata.push(e);
        });
    const obj = {
      talentData: rdata,
      isFrom: modalFor,
    };
    setRecipientData(obj);
    closeModal();
  };

  return (
    <div className="py-2">
      {loading && <Loader />}
      <ERHeader
        loading={loading}
        setLoading={setLoading}
        fromDate={fromDate}
        setFromDate={setFromDate}
        toDate={toDate}
        setToDate={setToDate}
        fetchData={fetchData}
        setFilter={setFilter}
        modalFor={modalFor}
        name={name}
        setName={setName}
        recEmail={recEmail}
        setRecEmail={setRecEmail}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        selectedSubCategory={selectedSubCategory}
        setSelectedSubCategory={setSelectedSubCategory}
        skills={skills}
        setSkills={setSkills}
        location={location}
        setLocation={setLocation}
        experience={experience}
        setExperience={setExperience}
        dispositionStatus={dispositionStatus}
        setDispositionStatus={setDispositionStatus}
        setCity={setCity}
        setCountry={setCountry}
        setSelected={setSelected}
      />
      {modalFor === "addRecruiter" && (
        <>
          <hr />
          <div className="d-flex gap-2 px-2 mb-2 align-items-center">
            {" "}
            {data?.length > 1 && (
              <button
                onClick={() => {
                  total > 1000 ? setConfirm1000(true) : fetchData(true);
                }}
                className="btn btn-sm "
              >
                Select all recipients
              </button>
            )}
            {apiRecCalled && (
              <span>
                {data?.length > 1 ? "Total Recipients: " : "Total Recipient: "}
                <strong>{total}</strong>
              </span>
            )}
          </div>
          <div className="mt-2 ml-2">
            {apiRecCalled && selectedRecipients.length > 0 && (
              <span>
                Selected{" "}
                {data.filter((x) => x.isSelected)?.length > 1
                  ? "Recipients"
                  : "Recipient"}{" "}
                : <strong>{selectedRecipients.length}</strong>
              </span>
            )}
          </div>
          <ERTable
            data={data}
            checkAllSelected={checkAllSelected}
            selectAll={selectAll}
            selectOne={selectOne}
            allSelected={allSelected}
            apiRecCalled={apiRecCalled}
            loading={loading}
          />
          {apiRecCalled && data?.length > 0 && data.length !== total && (
            <div className="d-flex justify-content-between">
              <div className="mt-3 px-2">
                Showing {Math.min((currentPage - 1) * 10 + 1, total)} -{" "}
                {Math.min(currentPage * 10, total)} of {total}
              </div>
              <div className="mt-3 px-2">
                <Pagination
                  currentPage={currentPage}
                  totalSize={total}
                  sizePerPage={pageSize}
                  firstPageText="|⟨"
                  lastPageText="⟩|"
                  showFirstLastPages={true}
                  changeCurrentPage={changeCurrentPage}
                  theme="border-bottom"
                />
              </div>
            </div>
          )}
          <hr />
          <div className="text-right px-2">
            <button
              className="btn btn-md btn-secondary mr-2"
              onClick={closeModal}
            >
              Cancel{" "}
            </button>
            <button
              className="btn btn-md btn-secondary mr-2"
              onClick={handleClear}
            >
              Clear{" "}
            </button>
            <button
              className="btn btn-md btn-primary"
              disabled={selectedRecipients.length === 0}
              onClick={broadcastAll}
            >
              Done{" "}
            </button>
          </div>{" "}
        </>
      )}
      {tableStructureCan && (
        <>
          <hr />
          <div className="d-flex gap-2 px-2 mb-2 align-items-center">
            {" "}
            {total > 1 && (
              <button
                onClick={() => {
                  total > 1000 ? setConfirm1000(true) : fetchData(true);
                }}
                className="btn btn-sm "
              >
                Select all recipients
              </button>
            )}
            {data?.length > 1 && (
              <span>
                Total {data?.length > 1 ? "Recipients" : "Recipient"} :{" "}
                <strong>{total}</strong>
              </span>
            )}
          </div>
          <div className="mt-2 ml-2">
            {selectedCandidates.length > 0 && (
              <span>
                Selected{" "}
                {data?.filter((x) => x.isSelected)?.length > 1
                  ? "Recipients"
                  : "Recipient"}{" "}
                : <strong>{selectedCandidates.length}</strong>
              </span>
            )}
          </div>
          <ECTable
            data={data}
            setData={setData}
            checkAllSelected={checkAllSelected}
            selectAll={selectAll}
            selectOne={selectOne}
            allSelected={allSelected}
            setAllSelected={setAllSelected}
            apiCanCalled={apiCanCalled}
            selected={selected}
            loading={loading}
          />{" "}
          {data?.length > 0 && data.length !== total && (
            <div className="d-flex justify-content-between">
              <div className="mt-3 px-2">
                Showing {Math.min((currentPage - 1) * 10 + 1, total)} -{" "}
                {Math.min(currentPage * 10, total)} of {total}
              </div>
              <div className="mt-3 px-2">
                <Pagination
                  currentPage={currentPage}
                  totalSize={total}
                  sizePerPage={pageSize}
                  firstPageText="|⟨"
                  lastPageText="⟩|"
                  showFirstLastPages={true}
                  changeCurrentPage={changeCurrentPage}
                  theme="border-bottom"
                />
              </div>
            </div>
          )}
          <hr />
          <div className="text-right px-2">
            <button
              className="btn btn-md btn-secondary mr-2"
              onClick={closeModal}
            >
              Cancel{" "}
            </button>
            <button
              className="btn btn-md btn-secondary mr-2"
              onClick={handleClear}
            >
              Clear{" "}
            </button>
            <button
              className="btn btn-md btn-primary"
              disabled={selectedCandidates.length === 0}
              onClick={broadcastAll}
            >
              Done{" "}
            </button>
          </div>{" "}
        </>
      )}
      {confirm1000 && (
        <Modal show={true} size="md" animation={true}>
          <Modal.Header>
            <button
              type="button"
              onClick={() => setConfirm1000(false)}
              className="close p-0 bl-modal-close-btn closable"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fal fa-times closable"></i>
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className="m-5">
              <div class="text-center">
                <div class="avatar avatar-lg avatar-red2">
                  <i
                    class="fas fa-exclamation-triangle fa-fw"
                    aria-hidden="true"
                  ></i>
                </div>
                <p class="text-center lead mt-3">
                  Broadcast Limitation: Maximum 1,000 candidates per email.
                </p>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div class="d-flex align-items-center justify-content-center">
              <Button variant="secondary" onClick={() => setConfirm1000(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => fetchData(true)}
                className="ml-2"
              >
                Proceed
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default EmailRecipients;
