import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import TalentListView from "./TalentListView";
import TalentGridView from "./TalentGridView";
import TalentTableView from "./TalentTableView";
import TalentFilter from "./TalentFilter";
import { domain } from "../../constants/Constants";
import { post } from "../../service/ApiService";
import Loader from "../Loader/Loader";
import AddTalent from "../modal/addtalent/AddTalent";
import FormSubmitSuccess from "../modal/result/FormSubmitSuccess";
import Sort from "../common/Sort";
import OutsideClickHandler from "react-outside-click-handler";
import moment from "moment";

function TalentList(props) {
  const [workList, setWorkList] = useState(false);
  const [listview, setListview] = useState(true);
  const [gridview, setGridView] = useState(false);
  const [tableview, setTableView] = useState(false);
  const [allCandidates, setAllCandidates] = useState(true);
  const [automatchCandidate, setAutomatchCandidate] = useState(false);
  const [worklistCandidates, setWorkListCandidates] = useState(false);
  const [invitedCandidates, setInvitedCandidates] = useState(false);
  const [sourcedCandidates, setSourcedCandidates] = useState(false);
  const [submittedCandidates, setSubmittedCandidates] = useState(false);
  const [shortlistedCandidates, setShortlistedCandidates] = useState(false);
  const [offeredCandidates, setOfferedCandidates] = useState(false);
  const [hiredCandidates, setHiredCandidates] = useState(false);
  const [disqualifiedCandidates, setDisqualifiedCandidates] = useState(false);
  const [candidateData, setCandidateData] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [status, setStatus] = useState("all");
  const [addTalentOpen, setAddTalentOpen] = useState(false);
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successData, setSuccessData] = useState({
    title: "Talent Created",
    subTitle: "Your talent was created successfully",
    htmlTag: "",
  });
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [filter, setFilter] = useState(false);
  const [filterBy, setFilterBy] = useState(candidateData);
  const [filterData, setFilterData] = useState(candidateData);
  const [candidateTitles, setCandidateTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [candidateLocations, setCandidateLocations] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [vetting, setVetting] = useState(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState(["", ""]);
  const [lastUpdatedType, setlastUpdatedType] = useState("");
  const [candidateSkills, setCandidateSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [candidateSkillSet, setCandidateSkillSet] = useState([]);
  const [selectedSkillSet, setSelectedSkillSet] = useState([]);
  const [createdBy, setCreatedBy] = useState([]);
  const [selectedCreatedBy, setSelectedCreatedBy] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const sortObject = [
    {
      id: 1,
      label: "Name",
      value: "firstName",
      icon: "fal fa-fw fa-signature  mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Location",
      value: "city",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Created by",
      value: "createdByName",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
    {
      id: 4,
      label: "Last updated",
      value: "lastUpdated",
      icon: "fal fa-fw fa-calendar-alt mr-2 dd-sort",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  useEffect(() => {
    window
      .matchMedia("(max-width: 1200px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  useEffect(() => {
    fetchCandidate();
  }, []);

  const fetchCandidate = async () => {
    const payload = {
      domain: domain,
      skip: 0,
    };
    setLoading(true);
    try {
      const res = await post("/talentlist/searchtalentbyusertenant", payload);
      if (res.status === 200) {
        const data = res.data.candidateData.sort(
          (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
        );
        let title = [];
        let location = [];
        let skill = [];
        let skillSet = [];
        let createdBy = [];
        setCandidateData(data);
        setFilterBy(data);
        setFilterData(data);
        data.forEach((item) => {
          if (item.designation) {
            if (!title.includes(item.designation)) {
              title.push(item.designation.trim());
            }
          }
          if (item.city && item.state) {
            if (!location.includes(`${item.city}, ${item.state}`)) {
              location.push(`${item.city}, ${item.state}`);
            }
          }
          if (item.createdByName && !createdBy.includes(item.createdByName)) {
            createdBy.push(item.createdByName);
          }
          if (item.primarySkills.length > 0) {
            skill.push(...item.primarySkills);
          }
          if (item.skillSet.length > 0) {
            skillSet.push(...item.skillSet);
          }
        });
        setCandidateTitles(title);
        setCandidateLocations(location);
        setCreatedBy(createdBy);
        setCandidateSkills([...new Set(skill)]);
        setCandidateSkillSet([...new Set(skillSet)]);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      setLoading(false);
      //console.log(error)
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  const onTabClick = (category, val) => {
    setCurrentPage(1);
    val === "worklist" ? setWorkList(true) : setWorkList(false);
    resetTabs([category]);
    updateTab(val);
  };

  const updateTab = (type) => {
    if (type === "listview") {
      setListview(true);
      setGridView(false);
      setTableView(false);
    }
    if (type === "gridview") {
      setGridView(true);
      setListview(false);
      setTableView(false);
    }
    if (type === "tableview") {
      setTableView(true);
      setListview(false);
      setGridView(false);
    }
    if (type === "all") {
      setFilterData(filterBy);
      setAllCandidates(true);
      setWorkListCandidates(false);
      setAutomatchCandidate(false);
      setSourcedCandidates(false);
      setSubmittedCandidates(false);
      setShortlistedCandidates(false);
      setOfferedCandidates(false);
      setHiredCandidates(false);
      setDisqualifiedCandidates(false);
      setInvitedCandidates(false);
      setStatus("all");
    }
    if (type === "worklist") {
      let worklist = getSectionOfWorkList(filterBy);
      setFilterData(worklist);
      setAllCandidates(false);
      setWorkListCandidates(true);
      setAutomatchCandidate(false);
      setSourcedCandidates(false);
      setSubmittedCandidates(false);
      setShortlistedCandidates(false);
      setOfferedCandidates(false);
      setHiredCandidates(false);
      setDisqualifiedCandidates(false);
      setInvitedCandidates(false);
      setStatus("worklist");
    }
    if (type === "sourced") {
      let sourced = getSectionOfList(filterBy, "1");
      setFilterData(sourced);
      setAllCandidates(false);
      setWorkListCandidates(false);
      setSourcedCandidates(true);
      setSubmittedCandidates(false);
      setShortlistedCandidates(false);
      setOfferedCandidates(false);
      setHiredCandidates(false);
      setDisqualifiedCandidates(false);
      setAutomatchCandidate(false);
      setInvitedCandidates(false);
    }

    if (type === "invited") {
      let invited = getSectionOfList(filterBy, "33");
      setFilterData(invited);
      setAllCandidates(false);
      setWorkListCandidates(false);
      setSourcedCandidates(true);
      setSubmittedCandidates(false);
      setShortlistedCandidates(false);
      setOfferedCandidates(false);
      setHiredCandidates(false);
      setDisqualifiedCandidates(false);
      setAutomatchCandidate(false);
      setInvitedCandidates(true);
      setStatus("invited");
    }
    if (type === "automatch") {
      let automatched = getSectionOfList(filterBy, "Auto");
      setFilterData(automatched);
      setAutomatchCandidate(true);
      setAllCandidates(false);
      setWorkListCandidates(false);
      setSourcedCandidates(false);
      setSubmittedCandidates(false);
      setShortlistedCandidates(false);
      setOfferedCandidates(false);
      setHiredCandidates(false);
      setDisqualifiedCandidates(false);
      setInvitedCandidates(false);
    }
    if (type === "submitted") {
      let submitted = getSectionOfList(filterBy, "6");
      setFilterData(submitted);
      setAllCandidates(false);
      setAutomatchCandidate(false);
      setSourcedCandidates(false);
      setWorkListCandidates(false);
      setSubmittedCandidates(true);
      setShortlistedCandidates(false);
      setOfferedCandidates(false);
      setHiredCandidates(false);
      setDisqualifiedCandidates(false);
      setInvitedCandidates(false);
      setStatus("submitted");
    }
    if (type === "shortlist") {
      let shortlistedList = getSectionOfList(filterBy, "8");
      setFilterData(shortlistedList);
      setAllCandidates(false);
      setAutomatchCandidate(false);
      setSourcedCandidates(false);
      setWorkListCandidates(false);
      setSubmittedCandidates(false);
      setShortlistedCandidates(true);
      setOfferedCandidates(false);
      setHiredCandidates(false);
      setDisqualifiedCandidates(false);
      setStatus("shortlist");
    }
    if (type === "offered") {
      let offeredList = getSectionOfList(filterBy, "32");
      setFilterData(offeredList);
      setAllCandidates(false);
      setAutomatchCandidate(false);
      setSourcedCandidates(false);
      setWorkListCandidates(false);
      setSubmittedCandidates(false);
      setShortlistedCandidates(false);
      setOfferedCandidates(true);
      setHiredCandidates(false);
      setDisqualifiedCandidates(false);
      setInvitedCandidates(false);
      setStatus("offered");
    }
    if (type === "hired") {
      let hiredList = getSectionOfList(filterBy, "11");
      setFilterData(hiredList);
      setAllCandidates(false);
      setAutomatchCandidate(false);
      setSourcedCandidates(false);
      setWorkListCandidates(false);
      setSubmittedCandidates(false);
      setShortlistedCandidates(false);
      setOfferedCandidates(false);
      setHiredCandidates(true);
      setDisqualifiedCandidates(false);
      setInvitedCandidates(false);
      setStatus("hired");
    }
    if (type === "disqualified") {
      let disqualifiedList = getSectionOfDisqualify(filterBy);
      setFilterData(disqualifiedList);
      setAllCandidates(false);
      setAutomatchCandidate(false);
      setSourcedCandidates(false);
      setWorkListCandidates(false);
      setSubmittedCandidates(false);
      setShortlistedCandidates(false);
      setOfferedCandidates(false);
      setHiredCandidates(false);
      setDisqualifiedCandidates(true);
      setInvitedCandidates(false);
      setStatus("disqualified");
    }
  };

  const resetTabs = (type) => {
    if (type === "listview" && listview === true) {
      setListview(false);
    }
    if (type === "gridview") {
      setGridView(false);
    }
    if (type === "tableview") {
      setTableView(false);
    }
  };

  const closeModal = () => {
    setAddTalentOpen(false);
    setSuccessFormOpen(false);
  };

  const handleSuccessForm = (data) => {
    const htmlTag = `
                        <div class="mb-2">
                            <label class="d-block font-bold pt-0">Created date</label>
                            <p>${moment().format("MM/DD/YYYY")}</p>
                        </div>`;
    setSuccessData({ ...successData, htmlTag: htmlTag });
    setSuccessFormOpen(true);
  };

  const getSectionOfList = (array, value) => {
    const result = array.filter((item) =>
      item.statusData.find(({ statusId }) => statusId === value)
    );
    return result;
  };

  const getSectionOfWorkList = (array) => {
    const filterarray = [
      "1",
      "6",
      "8",
      "32",
      "11",
      "12",
      "7",
      "3",
      "4",
      "27",
      "16",
      "29",
    ];
    const result = array.filter((item) =>
      item.statusData.find(({ statusId }) => filterarray.includes(statusId))
    );
    return result;
  };

  const getSectionOfDisqualify = (array) => {
    const filterarray = ["12", "7", "3", "4", "27", "16", "29"];
    const result = array.filter((item) =>
      item.statusData.find(({ statusId }) => filterarray.includes(statusId))
    );
    return result;
  };

  let allCandidateLen = filterBy.length;
  let allTalentLen = getSectionOfWorkList(filterBy).length;
  let automatchedLen = getSectionOfList(filterBy, "Auto").length;
  let sourcedLen = getSectionOfList(filterBy, "1").length;
  let submittedLen = getSectionOfList(filterBy, "6").length;
  let shortilistedLen = getSectionOfList(filterBy, "8").length;
  let offeredLen = getSectionOfList(filterBy, "32").length;
  let hiredLen = getSectionOfList(filterBy, "11").length;
  let disqualifiedLen = getSectionOfDisqualify(filterBy).length;
  let inviteLen = getSectionOfList(filterBy, "33").length;

  const talentStatus = (data) => {
    if (allCandidates) {
      setFilterData(data);
    } else if (sourcedCandidates) {
      let sourced = getSectionOfList(data, "1");
      setFilterData(sourced);
    } else if (submittedCandidates) {
      let submitted = getSectionOfList(data, "6");
      setFilterData(submitted);
    } else if (shortlistedCandidates) {
      let shortlistedList = getSectionOfList(data, "8");
      setFilterData(shortlistedList);
    } else if (offeredCandidates) {
      let offeredList = getSectionOfList(data, "32");
      setFilterData(offeredList);
    } else if (hiredCandidates) {
      let hiredList = getSectionOfList(filterBy, "11");
      setFilterData(hiredList);
    } else if (invitedCandidates) {
      let inviteList = getSectionOfList(filterBy, "33");
      setFilterData(inviteList);
    } else {
      let disqualifiedList = getSectionOfDisqualify(filterBy);
      setFilterData(disqualifiedList);
    }
  };

  const applySort = (sortedData, close) => {
    close && setSort(false);
    setFilterBy(sortedData);
    talentStatus(sortedData);
    clearAll();
  };

  const openFilter = () => {
    setFilter(true);
    setWorkList(false);
  };

  const clearAll = () => {
    setSelectedLocations([]);
    setSelectedTitles([]);
    setSelectedSkills([]);
    setSelectedSkillSet([]);
    setSelectedCreatedBy([]);
    setVetting(false);
    setLastUpdatedDate(["", ""]);
    setlastUpdatedType("");
    setSelectedCreatedBy([]);
  };

  const sortValues = [
    "fullText.firstName",
    "fullText.cityName",
    "lastUpdated",
    "createdByName",
  ];
  const changeSortArray = (data) => setSortArray(data);
  const changeSortCount = (count) => setSortCount(count);
  const [addSort, setAddSort] = useState(
    new Array(sortValues.length).map((_) => false)
  );
  const [selectSortOrder, setSelectSortOrder] = useState(
    new Array(sortValues.length).fill().map((_) => false)
  );
  const sortClearAll = () => {
    changeSortCount(0);
    changeSortArray([]);
    setAddSort(new Array(sortValues.length).fill().map((_) => false));
    setSelectSortOrder(new Array(sortValues.length).fill().map((_) => false));
    applySort(candidateData, false);
  };

  const applyFilter = (
    data,
    selectedTitles,
    selectedLocations,
    vetted,
    lastUpdated,
    lastUpdatedType,
    createdBy,
    skills,
    skillSet
  ) => {
    console.log("data", data);
    sortClearAll();
    updateTab("all");
    setFilter(false);
    setVetting(vetted);
    setLastUpdatedDate(lastUpdated);
    setSelectedTitles(selectedTitles);
    setSelectedLocations(selectedLocations);
    setFilterBy(data);
    setFilterData(data);
    setDataArray(data);
    setlastUpdatedType(lastUpdatedType);
    setSelectedSkills(skills);
    setSelectedSkillSet(skillSet);
    setSelectedCreatedBy(createdBy);
  };

  return (
    <>
      {loading && <Loader />}
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="card card-flat card-borderless p-4 p-0-600 mb-3">
            <div class="d-flex align-items-center justify-content-between">
              <h6 class="mr-3 mb-0 mb-xl-0">Talent Community</h6>
              <div class="d-flex align-items-center">
                <button
                  type="button"
                  class="btn btn-sm"
                  onClick={() => setAddTalentOpen(true)}
                >
                  <i aria-hidden="true" class="fas fa-fw mr-2 fa-plus "></i>
                  Add Talent
                </button>
              </div>
            </div>
            <div class="d-none d-block-768 mb-2"></div>
            <hr class="mt-0 d-none d-block-1300 d-none-768" />
            <div class="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end mb-3 justify-content-between border-bottom-gray2">
              <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
                <div class="viewOptionButtons d-none d-xl-flex nav nav-tabs justify-content-between">
                  <div class="nav-itemd-flex justify-content-center">
                    <button
                      onClick={() => {
                        onTabClick("toggleView", "listview");
                      }}
                      type="button"
                      class={
                        listview
                          ? "nav-link pb-3 bg-transparent active"
                          : "nav-link pb-3 bg-transparent"
                      }
                    >
                      <i class="fas fa-list"></i>
                    </button>
                  </div>
                  <div class="nav-itemd-flex justify-content-center">
                    <button
                      onClick={() => {
                        onTabClick("toggleView", "gridview");
                      }}
                      type="button"
                      class={
                        gridview
                          ? "nav-link pb-3 bg-transparent active"
                          : "nav-link pb-3 bg-transparent"
                      }
                    >
                      <i class="fas fa-th-large"></i>
                    </button>
                  </div>
                  <div class="nav-itemd-flex justify-content-center">
                    <button
                      onClick={() => {
                        onTabClick("toggleView", "tableview");
                      }}
                      type="button"
                      class={
                        tableview
                          ? "nav-link pb-3 bg-transparent active"
                          : "nav-link pb-3 bg-transparent"
                      }
                    >
                      <i class="fas fa-table"></i>
                    </button>
                  </div>
                </div>
              </div>
              <ul class="nav nav-tabs nav-tabs-tablet pt-1 justify-content-center justify-content-lg-start">
                <li className="nav-item">
                  <button
                    className={
                      allCandidates === true
                        ? " nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    type="button"
                    onClick={() => {
                      onTabClick("toggleView", "all");
                    }}
                  >
                    <span className="tag mb-1">{allCandidateLen}</span>
                    <div>All</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className={
                      worklistCandidates === true
                        ? " nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    onClick={() => {
                      onTabClick("toggleView", "worklist");
                    }}
                  >
                    <span className="tag mb-1">{allTalentLen}</span>
                    <div>Work List</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className={
                      automatchCandidate === true
                        ? " nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    onClick={() => {
                      onTabClick("toggleView", "automatch");
                    }}
                  >
                    <span className="tag mb-1">
                      {automatchedLen}
                      {/* <span className="jewel"></span> */}
                    </span>
                    <div>Automatched</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className={
                      invitedCandidates === true
                        ? " nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    onClick={() => {
                      onTabClick("toggleView", "invited");
                    }}
                  >
                    <span className="tag mb-1">
                      {inviteLen}
                      {/* <span className="jewel"></span> */}
                    </span>
                    <div>Invited</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className={
                      submittedCandidates === true
                        ? " nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    onClick={() => {
                      onTabClick("toggleView", "submitted");
                    }}
                  >
                    <span className="tag mb-1">{submittedLen}</span>
                    <div>Submitted</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    className={
                      shortlistedCandidates === true
                        ? " nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    onClick={() => {
                      onTabClick("toggleView", "shortlist");
                    }}
                  >
                    <span className="tag mb-1">{shortilistedLen}</span>
                    <div>Shortlisted</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={
                      offeredCandidates === true
                        ? " nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    type="button"
                    onClick={() => {
                      onTabClick("toggleView", "offered");
                    }}
                  >
                    <span className="tag mb-1">{offeredLen}</span>
                    <div>Offered</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={
                      hiredCandidates === true
                        ? " nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    onClick={() => {
                      onTabClick("toggleView", "hired");
                    }}
                    type="button"
                  >
                    <span className="tag mb-1">{hiredLen}</span>
                    <div>Hired</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    className={
                      disqualifiedCandidates === true
                        ? " nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    onClick={() => {
                      onTabClick("toggleView", "disqualified");
                    }}
                    type="button"
                  >
                    <span className="tag mb-1">{disqualifiedLen}</span>
                    <div>Disqualified</div>
                  </button>
                </li>
              </ul>
              <div class="my-2 d-none d-block-1200"></div>
              <div class="d-flex mb-2 align-items-center justify-content-center justify-content-even-400">
                <div class="d-flex justify-content-end w-100 justify-content-even-400">
                  <div class="dropdown-sort dd-sort">
                    <button
                      type="button"
                      onClick={() => setSort(true)}
                      class="btn btn-sm w-auto btn-text-accent dd-sort"
                    >
                      <i
                        aria-hidden="true"
                        class="fad fa-sort-down font-16 dd-sort mr-1"
                      ></i>
                      Sort / <span class="dd-sort">{sortCount}</span>
                    </button>
                    {sort && (
                      <OutsideClickHandler
                        onOutsideClick={() => setSort(false)}
                      >
                        <Sort
                          sortData={candidateData}
                          orderArray={orderList}
                          sortObjects={sortObject}
                          applySort={applySort}
                          setSortCount={setSortCount}
                          sortCount={sortCount}
                          setSortArray={setSortArray}
                          sortArray={sortArray}
                          sortingFor={
                            "Sort by Name, Location, Created by, and Last updated"
                          }
                        />
                      </OutsideClickHandler>
                    )}
                  </div>
                  <div class="dropdown position-relative">
                    <button
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title="Filter"
                      onClick={openFilter}
                      type="button"
                      class="btn btn-sm btn-text-accent w-auto"
                    >
                      <i class="fas fa-filter mr-1"></i>
                      Filter /
                      <span>
                        {" "}
                        {selectedTitles.length +
                          selectedLocations.length +
                          (vetting ? 1 : 0) +
                          (lastUpdatedDate[0] ? 1 : 0) +
                          selectedCreatedBy.length +
                          selectedSkills.length +
                          selectedSkillSet.length}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {gridview === true && (
              <TalentGridView
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                arrayList={filterData}
              />
            )}
            {matches && (
              <TalentGridView
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                arrayList={filterData}
              />
            )}
            {listview === true && (
              <TalentListView
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                arrayList={filterData}
              />
            )}
            {tableview === true && (
              <TalentTableView
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                arrayList={filterData}
              />
            )}

            {filterData.length === 0 && (
              <>
                <div>
                  <div className="text-center p-3">
                    <div className="avatar avatar-lg">
                      <i className="fad fa-users-slash"></i>
                    </div>
                    <div className="mt-2">No talent found</div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <Modal size="lg" show={addTalentOpen}>
        <div class="bl-modal-header">
          <h6 class="bl-modal-title">{"Add Talent"}</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          {successFormOpen ? (
            <FormSubmitSuccess
              successData={successData}
              closeModal={closeModal}
            />
          ) : (
            <AddTalent
              handleSuccessForm={handleSuccessForm}
              fetchCandidate={fetchCandidate}
              closeModal={closeModal}
            />
          )}
        </Modal.Body>
      </Modal>
      {filter && (
        <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
          <TalentFilter
            candidateData={candidateData}
            candidateTitles={candidateTitles}
            candidateLocations={candidateLocations}
            selectedLocations={selectedLocations}
            selectedTitles={selectedTitles}
            vetting={vetting}
            candidateSkills={candidateSkills}
            selectedSkills={selectedSkills}
            candidateSkillSet={candidateSkillSet}
            selectedSkillSet={selectedSkillSet}
            createdBy={createdBy}
            selectedCreatedBy={selectedCreatedBy}
            lastUpdatedDate={lastUpdatedDate}
            lastUpdatedType={lastUpdatedType}
            applyFilter={applyFilter}
          />
        </OutsideClickHandler>
      )}
    </>
  );
}

export default TalentList;
