import React, { useEffect } from "react";
import moment from "moment";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCandidateId } from "../../../../service/AuthService";
import {
  FetchSmsByUserToCandidate,
  UpdateMessageReadStatus,
} from "../../../../actions/communication/Communication";
import Loader from "../../../Loader/Loader";

const Messages = ({ candidateData }) => {
  const dispatch = useDispatch();
  const {
    sendSMSCandidateLoading,
    sendSMSCandidateList,
    readStatusLoading,
  } = useSelector(
    (state) => ({
      sendSMSCandidateLoading:
        state.communicationReducer.sendSMSCandidateLoading,
      sendSMSCandidateList: state.communicationReducer.sendSMSCandidateList,
      readStatusLoading: state.communicationReducer.readStatusLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    const reqPayload = {
      userId: getCandidateId(),
      candidateNumber: candidateData?.homePhoneCode + candidateData?.homePhone,
    };
    dispatch(FetchSmsByUserToCandidate(reqPayload));
    const payload = {
      userId: candidateData?.userId,
    };
    dispatch(UpdateMessageReadStatus(payload));
  }, []);

  // Function to check if the date is different from the previous message's date
  const isNewDate = (index) => {
    if (index === 0) return true;
    const currentDate = moment(sendSMSCandidateList[index].timestamp).format(
      "MM/DD/YYYY"
    );
    const previousDate = moment(
      sendSMSCandidateList[index - 1].timestamp
    ).format("MM/DD/YYYY");
    return currentDate !== previousDate;
  };
  const messageIndicatorStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    gap: "2px",
  };

  const indicatorBoxStyle = {
    width: "12px",
    height: "12px",
  };

  const indicatorTextStyle = {
    fontSize: "16px",
    marginRight: "5px",
  };

  return (
    <>
      <div className="w-100">
        {sendSMSCandidateLoading && (
          <div className="loading-text-container">
            <div className="loading-text">
              <i>Loading...</i>
            </div>
          </div>
        )}
        {sendSMSCandidateList && sendSMSCandidateList?.length === 0 && (
          <div class="text-center p-5">
            <div class="avatar avatar-lg">
              <i aria-hidden="true" class="fad fa-users-slash"></i>
            </div>
            <div class="mt-2">No records found</div>
          </div>
        )}
        <div className="mb-5 w-100 p-2">
          <div className="row mb-5">
            {sendSMSCandidateList &&
              sendSMSCandidateList?.map((message, index) => (
                <div
                  key={index}
                  className={` d-flex flex-column col-md-12 ${
                    message?.direction === "outbound"
                      ? "text-right"
                      : "text-left"
                  }`}
                >
                  {isNewDate(index) && (
                    <div className="date-heading" style={dateHeadingStyle}>
                      {moment(
                        message?.received_at ? message?.received_at : new Date()
                      ).format("MM/DD/YYYY")}
                    </div>
                  )}{" "}
                  <div
                    style={
                      message?.direction === "outbound" ||
                      message?.direction === null
                        ? sentMessageStyle
                        : receivedMessageStyle
                    }
                  >
                    <p className="mb-0">{message?.messageBody}</p>
                    <div className="d-flex flex-inline justify-content-between">
                      {message?.direction === "outbound" ? (
                        <span style={{ float: "left", marginRight: "10px" }}>
                          <small>
                            {message?.senderRole + " : " + message?.senderName}
                          </small>
                        </span>
                      ) : (
                        <span style={{ float: "left", marginRight: "10px" }}>
                          <small>
                            {candidateData?.firstName +
                              " " +
                              candidateData?.lastName}
                          </small>
                        </span>
                      )}

                      <span
                        style={
                          message?.direction === "outbound"
                            ? whiteTimestampStyle
                            : timestampStyle
                        }
                      >
                        <small>
                          {message?.received_at
                            ? moment(message?.received_at).format("hh:mm A")
                            : "Not delivered"}
                        </small>
                      </span>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="row mb-5"></div>
        </div>
      </div>
    </>
  );
};

// Inline CSS objects
const receivedMessageStyle = {
  backgroundColor: "#e1e1e1",
  borderRadius: "10px",
  padding: "10px",
  marginBottom: "10px",
  maxWidth: "70%",
  alignSelf: "flex-start",
};

const sentMessageStyle = {
  backgroundColor: "#007bff",
  color: "#fff",
  borderRadius: "10px",
  padding: "10px",
  marginBottom: "10px",
  maxWidth: "70%",
  alignSelf: "flex-end",
  textAlign: "left",
};

const timestampStyle = {
  fontSize: "12px",
  color: "#6c757d",
  marginTop: "0px",
  display: "block",
};
const whiteTimestampStyle = {
  fontSize: "12px",
  color: "#fff",
  marginTop: "0px",
  display: "block",
};
const dateHeadingStyle = {
  //   backgroundColor: "#f0f0f0",
  borderRadius: "0px",
  borderBottom: "4px solid #f0f0f0",
  padding: "5px",
  marginBottom: "10px",
  textAlign: "center",
};

export default Messages;
