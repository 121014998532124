import React from "react";
import moment from "moment";

function License(props) {

    const inputDataObj = {
        licenseName: '',
        license: '',
        state: '',
        expiryDate: '',
    }

    const inputValObj = { licenseName: '', license: '', state: '', expiryDate: '' }

    const handleChange = (e, i) => {
        const newArray = [...props.licenses]
        newArray[i] = { ...newArray[i], [e.target.id]: e.target.value }
        props.setLicenses(newArray);
    }

    // Delete License
    const handleDelete = (e, i) => {
        props.setLicenses(props.licenses.filter((item, index) => index !== i))
        props.setLicenseValidation(props.licenseValidation.filter((item, index) => index !== i))
    }

    // Add License
    const handleAdd = () => {
        props.setLicenses([...props.licenses, inputDataObj])
        props.setLicenseValidation([...props.licenseValidation, inputValObj])
    }

    return (
        <div>
            <div class="licenses">
                {
                    props.licenses.map((license, i) => (
                        <div class="d-flex" v-for="item in list">
                            <div class="mr-3">
                                <div class="avatar avatar-sm">{i + 1}</div>
                            </div>
                            <div class="flex-grow-1">
                                <div class="row mb-2">
                                    <div class="col-lg-6">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" placeholder="License Name" id="licenseName" style={{border: props.licenseValidation[i].licenseName && '2px solid #ff0000'}} value={license.licenseName} onChange={(e) => handleChange(e, i)} />
                                            <label>License Name</label>
                                        </div>
                                        <small className="validation">{props.licenseValidation[i].licenseName}</small>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" placeholder="License #" id="license" style={{border: props.licenseValidation[i].license && '2px solid #ff0000'}} value={license.license} onChange={(e) => handleChange(e, i)} />
                                            <label>License #</label>
                                        </div>
                                        <small className="validation">{props.licenseValidation[i].license}</small>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-floating">
                                            <input type="text" class="form-control" placeholder="State" id="state" style={{border: props.licenseValidation[i].state && '2px solid #ff0000'}} value={license.state} onChange={(e) => handleChange(e, i)} />
                                            <label>State</label>
                                        </div>
                                        <small className="validation">{props.licenseValidation[i].state}</small>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-floating form-date">
                                            <label >Expiry Date</label>
                                            <input min={moment().format("YYYY-MM-DD")} max="2050-12-31" type="date" class="form-control" placeholder="Expiry Date" id="expiryDate" style={{border: props.licenseValidation[i].expiryDate && '2px solid #ff0000' }} value={license.expiryDate} onChange={(e) => handleChange(e, i)} />
                                        </div>
                                        <small className="validation">{props.licenseValidation[i].expiryDate}</small>
                                    </div>
                                </div>
                                <div class="row mt-2">
                                    <div class="col-12">
                                        <hr />
                                    </div>
                                </div>
                            </div>
                            {
                                props.licenses.length !== 1 && (
                                    <div class="ml-3">
                                        <button type="button" class="btn btn-icon" onClick={(e) => handleDelete(e, i)}>
                                            <i class="fal fa-fw fa-trash-alt"></i>
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                    ))
                }

                <div >
                    <button
                        type="button"
                        onClick={handleAdd}
                        class="btn btn-sm btn-text">
                        <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                        <strong>Add License</strong>
                    </button>
                </div>

            </div>
        </div>
    );
}
export default License;
