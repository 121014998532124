import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
  getUserRole,
  getActualName,
  getActualEmail,
} from "../../../../../../service/AuthService";
import Avatar from "react-avatar";
import { Modal } from "react-bootstrap";
import { post } from "../../../../../../service/ApiService";
import Loader from "../../../../../Loader/Loader";
import { emailDomain } from "../../../../../../constants/Constants";
import ScheduleInterview from "./ScheduleInterview";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId({ length: 10 });

function ShortlistPopUp(props) {
  const [loading, setLoading] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [talentData, setTalentData] = useState({});
  const [jobData, setJobData] = useState({});
  const [showInterview, setShowInterview] = useState(false);
  const role = getUserRole();

  const [assessmentPlatform, setAssessmentPlatform] = useState("");
  const [vettingData, setVettingData] = useState([]);
  const [interviewScheduleData, setInterviewScheduleData] = useState({});
  const [vetSelectedSkill, setVetSelectedSkill] = useState("");
  const [duration, setDuration] = useState("");
  const [vettingDifficulty, setVettingDifficulty] = useState("");
  const [formData, setFormData] = useState({
    testCategory: "",
  });

  const close = () => {
    props.setShortlistPopup(false);
  };

  const SMS = async () => {
    try {
      const getContent = async (request) => {
        try {
          let content = "";
          const response = await post("/msgtwoway/getSmsContent", request);
          if (response.status === 200) {
            content = response.data;
          }
          return content;
        } catch (error) {
          console.error("Error fetching content:", error);
          return "";
        }
      };

      const smsContent = (phone, phoneCode) => {
        return phone
          ? `+${phoneCode?.substring(1)}${phone.replaceAll(" ", "")}`
          : "";
      };

      const phoneNumber =
        smsContent(
          props.selectedCandidate?.homePhone,
          props.selectedCandidate?.homePhoneCode
        ) ||
        smsContent(
          props.selectedCandidate?.workPhone,
          props.selectedCandidate?.workPhoneCode
        );

      const name = props.selectedCandidate?.firstName;
      const jobName = jobData.jobTitle;

      const offeredReq = {
        event: "Make an Offer/Re-offer",
        recipient: '["Candidate"]',
      };

      const offeredContent = await getContent(offeredReq);

      if (offeredContent?.length !== 0) {
        let content = offeredContent[0].smsContent;
        content = content.replace("[First Name]", name);
        content = content.replace("[Job Title]", jobName);
        content = content.replace(
          "CareerPageLinkToLogin]",
          emailDomain + "/careers/jobList"
        );
        offeredContent[0].smsContent = content;
      }

      const payload = [
        {
          recieverNumber: [phoneNumber.replace("+", "")],
          // message: `Hi ${name}, Congratulations! You are shortlisted for the ${jobName} role. We will connect with you soon on next steps.\nThe High5 Team`,
          message: `${offeredContent?.[0]?.smsContent} \n ${offeredContent?.[0]?.footer}`,
        },
      ];

      const payloadTelSms = [
        {
          recieverNumber: [phoneNumber],
          message: `${offeredContent?.[0]?.smsContent} \n ${offeredContent?.[0]?.footer}`,
        },
      ];

      setLoading(true);

      if (phoneNumber.includes("+1")) {
        await post("/msgtwoway/telSms", payloadTelSms);
      } else {
        await post("/massages/sentMassageWhatsapp", payload);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred");
    }
  };

  const SMS2 = async () => {
    try {
      const name = props.selectedCandidate?.firstName;
      const jobName = jobData?.jobTitle;
      const jobId = jobData.jobId;

      const tenant = {
        tenant: JSON.parse(localStorage.getItem("userToken"))?.tenantid,
        createdBy: props?.selectedCandidate?.createdBy,
      };

      const numbers = await post("/msgtwoway/getAllNumbers", tenant);
      const data = numbers.data;

      const getSmsContentRec = async (event) => {
        try {
          const response = await post("/msgtwoway/getSmsContent", {
            event,
            recipient: '["Recruiter"]',
          });

          if (response.status === 200) {
            return response.data;
          }
        } catch (error) {
          console.error("Error fetching SMS content:", error);
        }

        return null;
      };
      const getSmsContentHM = async (event) => {
        try {
          const response = await post("/msgtwoway/getSmsContent", {
            event,
            recipient: '["Hiring Manager"]',
          });

          if (response.status === 200) {
            return response.data;
          }
        } catch (error) {
          console.error("Error fetching SMS content:", error);
        }

        return null;
      };
      const getSmsContentCSM = async (event) => {
        try {
          const response = await post("/msgtwoway/getSmsContent", {
            event,
            recipient: '["Customer Success Manager"]',
          });

          if (response.status === 200) {
            return response.data;
          }
        } catch (error) {
          console.error("Error fetching SMS content:", error);
        }

        return null;
      };
      const getSmsContentMPM = async (event) => {
        try {
          const response = await post("/msgtwoway/getSmsContent", {
            event,
            recipient: '["Marketplace Manager"]',
          });

          if (response.status === 200) {
            return response.data;
          }
        } catch (error) {
          console.error("Error fetching SMS content:", error);
        }

        return null;
      };

      const formatPhoneNumbers = (obj) => {
        const formatNumber = (number) =>
          number.charAt(0) != "+" && number.length > 10 ? `+${number}` : number;

        return {
          home: formatNumber(obj.homePhone || ""),
          mobile: formatNumber(obj.mobilePhone || ""),
          work: formatNumber(obj.workPhone || ""),
        };
      };

      if (data.length === 0) return;

      const recepientsDataSms = [];
      const recepientsDataWhatsApp = [];

      const shortListedContentRec = await getSmsContentRec("Shortlist");
      const shortListedContentHM = await getSmsContentHM("Shortlist");
      const shortListedContentCSM = await getSmsContentCSM("Shortlist");
      const shortListedContentMPM = await getSmsContentMPM("Shortlist");

      data.forEach((obj) => {
        const recepientDataSms = [];
        const recepientDataWhatsApp = [];

        const { home, mobile, work } = formatPhoneNumbers(obj);

        if (
          obj.fk_Role === 3 &&
          shortListedContentRec &&
          shortListedContentRec?.length !== 0
        ) {
          let content = shortListedContentRec[0].smsContent;
          content = content.replace("[FirstName]", obj.firstName);
          content = content.replace("[CandidateName]", name);
          content = content.replace("[JobTitle]", jobName);
          content = content.replace(
            "[LinkForDetails]",
            `${emailDomain}/high5Hire/requests/${jobId}`
          );
          shortListedContentRec[0].smsContent = content;
          if (home.charAt(0) == "+" && home.charAt(1) == "1")
            recepientDataSms.push(home.replaceAll(" ", ""));
          if (mobile.charAt(0) == "+" && mobile.charAt(1) == "1")
            recepientDataSms.push(mobile.replaceAll(" ", ""));
          if (work.charAt(0) == "+" && work.charAt(1) == "1")
            recepientDataSms.push(work.replaceAll(" ", ""));

          if (recepientDataSms.length > 0) {
            recepientsDataSms.push({
              recieverNumber: recepientDataSms,
              userId: Math.floor(Math.random() * 1000000),
              // message: `Hi ${obj.firstName}, Just letting you know ${name} has been shortlisted for the ${jobName} role.\nClick below to see details.\n${emailDomain}/high5Hire/requests/${jobId}\nThe High5 Team `,
              message: `${shortListedContentRec?.[0]?.smsContent} \n ${shortListedContentRec?.[0]?.footer}`,
            });
          }

          if (recepientDataWhatsApp.length > 0) {
            recepientsDataWhatsApp.push({
              toUserName: name,
              userId: Math.floor(Math.random() * 1000000),
              recieverNumber: recepientDataWhatsApp,
              message: `${shortListedContentRec?.[0]?.smsContent} \n ${shortListedContentRec?.[0]?.footer}`,
            });
          }
        }
        if (
          obj.fk_Role === 4 &&
          shortListedContentHM &&
          shortListedContentHM?.length !== 0
        ) {
          let content = shortListedContentHM[0].smsContent;
          content = content.replace("[FirstName]", obj.firstName);
          content = content.replace("[CandidateName]", name);
          content = content.replace("[JobTitle]", jobName);
          content = content.replace(
            "[LinkForDetails]",
            `${emailDomain}/high5Hire/requests/${jobId}`
          );
          shortListedContentHM[0].smsContent = content;
          if (home.charAt(0) == "+" && home.charAt(1) == "1")
            recepientDataSms.push(home.replaceAll(" ", ""));
          if (mobile.charAt(0) == "+" && mobile.charAt(1) == "1")
            recepientDataSms.push(mobile.replaceAll(" ", ""));
          if (work.charAt(0) == "+" && work.charAt(1) == "1")
            recepientDataSms.push(work.replaceAll(" ", ""));

          if (recepientDataSms.length > 0) {
            recepientsDataSms.push({
              recieverNumber: recepientDataSms,
              userId: Math.floor(Math.random() * 1000000),
              // message: `Hi ${obj.firstName}, Just letting you know ${name} has been shortlisted for the ${jobName} role.\nClick below to see details.\n${emailDomain}/high5Hire/requests/${jobId}\nThe High5 Team `,
              message: `${shortListedContentHM?.[0]?.smsContent} \n ${shortListedContentHM?.[0]?.footer}`,
            });
          }

          if (recepientDataWhatsApp.length > 0) {
            recepientsDataWhatsApp.push({
              toUserName: name,
              userId: Math.floor(Math.random() * 1000000),
              recieverNumber: recepientDataWhatsApp,
              message: `${shortListedContentHM?.[0]?.smsContent} \n ${shortListedContentHM?.[0]?.footer}`,
            });
          }
        }
        if (
          obj.fk_Role === 8 &&
          shortListedContentCSM &&
          shortListedContentCSM?.length !== 0
        ) {
          let content = shortListedContentCSM[0].smsContent;
          content = content.replace("[FirstName]", obj.firstName);
          content = content.replace("[CandidateName]", name);
          content = content.replace("[JobTitle]", jobName);
          content = content.replace(
            "[LinkForDetails]",
            `${emailDomain}/high5Hire/requests/${jobId}`
          );
          shortListedContentHM[0].smsContent = content;
          if (home.charAt(0) == "+" && home.charAt(1) == "1")
            recepientDataSms.push(home.replaceAll(" ", ""));
          if (mobile.charAt(0) == "+" && mobile.charAt(1) == "1")
            recepientDataSms.push(mobile.replaceAll(" ", ""));
          if (work.charAt(0) == "+" && work.charAt(1) == "1")
            recepientDataSms.push(work.replaceAll(" ", ""));

          if (recepientDataSms.length > 0) {
            recepientsDataSms.push({
              recieverNumber: recepientDataSms,
              userId: Math.floor(Math.random() * 1000000),
              // message: `Hi ${obj.firstName}, Just letting you know ${name} has been shortlisted for the ${jobName} role.\nClick below to see details.\n${emailDomain}/high5Hire/requests/${jobId}\nThe High5 Team `,
              message: `${shortListedContentCSM?.[0]?.smsContent} \n ${shortListedContentCSM?.[0]?.footer}`,
            });
          }

          if (recepientDataWhatsApp.length > 0) {
            recepientsDataWhatsApp.push({
              toUserName: name,
              userId: Math.floor(Math.random() * 1000000),
              recieverNumber: recepientDataWhatsApp,
              message: `${shortListedContentCSM?.[0]?.smsContent} \n ${shortListedContentCSM?.[0]?.footer}`,
            });
          }
        }
        if (
          obj.fk_Role === 9 &&
          shortListedContentMPM &&
          shortListedContentMPM?.length !== 0
        ) {
          let content = shortListedContentMPM[0].smsContent;
          content = content.replace("[FirstName]", obj.firstName);
          content = content.replace("[CandidateName]", name);
          content = content.replace("[JobTitle]", jobName);
          content = content.replace(
            "[LinkForDetails]",
            `${emailDomain}/high5Hire/requests/${jobId}`
          );
          shortListedContentMPM[0].smsContent = content;
          if (home.charAt(0) == "+" && home.charAt(1) == "1")
            recepientDataSms.push(home.replaceAll(" ", ""));
          if (mobile.charAt(0) == "+" && mobile.charAt(1) == "1")
            recepientDataSms.push(mobile.replaceAll(" ", ""));
          if (work.charAt(0) == "+" && work.charAt(1) == "1")
            recepientDataSms.push(work.replaceAll(" ", ""));

          if (recepientDataSms.length > 0) {
            recepientsDataSms.push({
              recieverNumber: recepientDataSms,
              userId: Math.floor(Math.random() * 1000000),
              // message: `Hi ${obj.firstName}, Just letting you know ${name} has been shortlisted for the ${jobName} role.\nClick below to see details.\n${emailDomain}/high5Hire/requests/${jobId}\nThe High5 Team `,
              message: `${shortListedContentMPM?.[0]?.smsContent} \n ${shortListedContentMPM?.[0]?.footer}`,
            });
          }

          if (recepientDataWhatsApp.length > 0) {
            recepientsDataWhatsApp.push({
              toUserName: name,
              userId: Math.floor(Math.random() * 1000000),
              recieverNumber: recepientDataWhatsApp,
              message: `${shortListedContentMPM?.[0]?.smsContent} \n ${shortListedContentMPM?.[0]?.footer}`,
            });
          }
        }
      });

      if (recepientsDataSms.length > 0) {
        await post("/msgtwoway/telSms", recepientsDataSms);
      }

      if (recepientsDataWhatsApp.length > 0) {
        const res = await post(
          "/massages/sentMassageWhatsapp",
          recepientsDataWhatsApp
        );
        console.log("response", res);
      }
    } catch (error) {
      setLoading(false);
      console.error("An error occurred", error);
      toast.error("An error occurred");
    }
  };

  const getCandidateVettingDetail = async (item, status) => {
    setLoading(true);
    const params = {
      candidateId: talentData.candidateID,
      jobId: jobData.jobId,
      email: talentData.email,
    };

    setLoading(false);
  };

  const assignInterView = async () => {
    let selectedAssessment = vettingData.filter(
      (item) => String(item._id) === String(vetSelectedSkill)
    );

    let reviewerData = {};
    if (formData.testCategory === "MCQ") {
      reviewerData.firstName = "";
      reviewerData.lastName = "";
      reviewerData.reviewerEmail = "";
    }

    var date = new Date();

    let paramObj = {
      tenantName: jobData.tenantName,
      jobTitle: jobData.jobTitle,
      candidateInfo: {
        firstName: talentData.firstName,
        lastName: talentData.lastName,
        email: talentData.email,
        phone: talentData.homePhone,
      },
      expiryDate: date.setDate(date.getDate() + 7),
      createdFrom: "High5hire",
      testAssign: selectedAssessment[0]._id,
      vettingDetails: selectedAssessment[0],
      uniqueCode: uid(),
      testStatus: "Invited",
      candidateId: talentData.userId,
      jobId: jobData.jobId,
      fk_tenant: jobData.fk_tenant,
      reviewer: reviewerData,
      recruiterInfo: {
        firstName: talentData.recruiterFname,
        lastName: talentData.recruiterLname,
      },
      recruiterId: talentData.recruiterId,
      vettingAssignment: showInterview,
      hiringManagerInfo: {
        fullName: getActualName(),
        email: getActualEmail(),
      },
    };
    setLoading(true);

    const vetRes = await post(
      "/vetIntegration/candidate/scheduleInterView",
      paramObj
    );

    if (vetRes.status === 200) {
      await submitShortList();
      toast.success("Vetting assessment assigned successfully");
    } else {
      setLoading(false);
      toast.error("An unexpected error occurred.please try again");
    }
  };

  const submitShortList = async (e) => {
    //checks the candidate vetting status

    try {
      setLoading(true);
      const payload = {
        status: 8,
        id_disposition: talentData.id_disposition,
        disqualifyComments: null,
        comments: commentText,
        jobId: jobData.jobId,
        jobTitle: jobData.jobTitle,
        firstName: talentData.firstName,
        lastName: talentData.lastName,
        email: talentData.email,
        tenantName: jobData.tenantName,
        role: role,
        vettingAssignment: showInterview,
        recruiterFname: talentData.recruiterFname,
        recruiterId: talentData.recruiterId,
        createdBy: props?.selectedCandidate?.createdBy,
      };

      const res = await post("/disposition/updatedisposition", payload);
      if (res.status === 200) {
        await SMS();
        await SMS2();
        toast.success("Candidate Shortlisted successfully");
        setLoading(false);
        props.reloadDisposition();
        window.location.reload();
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  useEffect(() => {
    setJobData(props.jobData);
    setTalentData(props.selectedCandidate);
  }, [props]);

  const getPhone = (phone, phoneCode) => {
    if (phoneCode) {
      let actualPhone = phone.replace("+", "");
      let actualPhoneCode = phoneCode.replace("+", "");
      let phoneNumber = "";
      if (actualPhone.substring(0, actualPhoneCode.length) == actualPhoneCode) {
        phoneNumber = phone
          .replace("+", "")
          .slice(phoneCode.replace("+", "").length);
      } else {
        phoneNumber = phone.replace("+", "");
      }
      return "+" + actualPhoneCode + phoneNumber;
    } else {
      let phoneNumber = phone.replace("+", "");
      return `+1${phoneNumber}`;
    }
  };
  const smsContent = (phone, phoneCode) => {
    if (phoneCode) {
      let actualPhone = phone.replace("+", "");
      let actualPhoneCode = phoneCode.replace("+", "");
      let phoneNumber = "";
      if (actualPhone.substring(0, actualPhoneCode.length) == actualPhoneCode) {
        phoneNumber = phone
          .replace("+", "")
          .slice(phoneCode.replace("+", "").length);
      } else {
        phoneNumber = phone.replace("+", "");
      }
      return "+" + actualPhoneCode + phoneNumber;
    } else {
      let phoneNumber = phone.replace("+", "");
      return `+1${phoneNumber}`;
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <Modal
          size="lg"
          aria-labelledby="example-modal-sizes-title-lg"
          dialogClassName="interviewPopUpModal"
          show={props.shortlistPopup}
        >
          <Modal.Header>
            <div style={{ zIndex: "1000" }}>
              <h6 class="bl-modal-title">{"Shortlist Candidate"}</h6>

              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => props.setShortlistPopup(false)}
                className="close p-0 bl-modal-close-btn mx-1"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="bl-modal-body ">
              <div>
                <div className="text-center">
                  <Avatar
                    size="55"
                    name={talentData.firstName + " " + talentData.lastName}
                    round="80px"
                  />
                </div>
              </div>
              <div>
                <div className="text-center">
                  <h6 className="mt-3">
                    {talentData?.firstName} {talentData?.lastName}
                  </h6>
                  <p>
                    {" "}
                    {jobData?.isRemote ? "Remote" : jobData?.city} /{" "}
                    {jobData?.tenantName} /{" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: jobData?.placementCurrency
                        ? `${jobData?.placementCurrency}`
                        : "USD",
                      maximumSignificantDigits: 3,
                    }).format(jobData?.maximumPay)}
                  </p>
                </div>
              </div>
              {/* <div className="d-flex align-items-center justify-content-between ">
                <div>
                  <label className="p-1 pl-2">
                    Do you want to schedule the interview ?
                  </label>
                </div>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={() => setShowInterview(!showInterview)}
                    checked={showInterview}
                  />
                </div>
              </div> */}
              {showInterview ? (
                <ScheduleInterview
                  jobData={jobData}
                  assessmentPlatform={assessmentPlatform}
                  setAssessmentPlatform={setAssessmentPlatform}
                  vettingData={vettingData}
                  setVettingData={setVettingData}
                  formData={formData}
                  setFormData={setFormData}
                  vetSelectedSkill={vetSelectedSkill}
                  setVetSelectedSkill={setVetSelectedSkill}
                  duration={duration}
                  setDuration={setDuration}
                  vettingDifficulty={vettingDifficulty}
                  setVettingDifficulty={setVettingDifficulty}
                />
              ) : (
                <div className="form-floating mt-3">
                  <textarea
                    className="form-control"
                    maxLength={250}
                    placeholder="Leave a comment here"
                    id="floatingTextarea2"
                    style={{ height: "100px" }}
                    value={commentText}
                    onChange={(e) => setCommentText(e.target.value)}
                  ></textarea>
                  <label for="floatingTextarea2">Enter Comments</label>
                </div>
              )}
            </div>
          </Modal.Body>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
              onClick={close}
            >
              Cancel
            </button>
            {showInterview ? (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={assignInterView}
                disabled={duration === "" || vettingDifficulty === ""}
              >
                Assign
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={submitShortList}
                disabled={commentText.length === 0}
              >
                Shortlist
              </button>
            )}
          </div>
        </Modal>
      </div>
    </>
  );
}

export default ShortlistPopUp;
