import React, { useEffect, useState } from 'react'
import { ButtonLoader } from '../../../constants/Constants'
import { post } from '../../../service/ApiService'
import { toast } from 'react-toastify'

const PrivacySettings = ({ value }) => {
    
    const initialPrivacy = [
        {
            name: "Contact Info",
            status: true
        },
        {
            name: "Social Media Details",
            status: true
        },
        {
            name: "Story Info",
            status: true
        },
        {
            name: "Skills",
            status: true
        },
        {
            name: "Experience",
            status: true
        },
        {
            name: "Education Details",
            status: true
        },
        {
            name: "Languages",
            status: true
        },
        {
            name: "Licences",
            status: true
        },
        {
            name: "Custom Section",
            status: true
        },
        {
            name: "Awards and Honors",
            status: true
        },
        {
            name: "Resumes",
            status: true
        },
        {
            name: "Certificates",
            status: true
        }
    ]

    const [ readOnlyMode, setReadOnlyMode ] = useState(true)
    const [ submittingForm, setSubmittingFrom ] = useState(false)
    const [ showSuccessMessage, setShowSuccessMessage ] = useState(false)
    const [ saveClicked, setSaveClicked ] = useState(true)
    const [ privacy, setPrivacy ] = useState([]) 

    useEffect(() => {
        if(value.length > 0) {
            setPrivacy(value)
        }
        else {
            setPrivacy(initialPrivacy)
        }
    }, [])

    const editPrivacy = () => {
        setReadOnlyMode(false)
        setSaveClicked(true)
    }

    const cancelPrivacy = () => {
        if(value.length > 0) {
            setPrivacy(value)
        }
        else {
            setPrivacy(initialPrivacy)
        }
        setReadOnlyMode(true)
        setShowSuccessMessage(false)
        setSubmittingFrom(false)
        setSaveClicked(true)
    }

    const handleChange = (value, checked) => {
        let privacyChange = [...privacy]
        let index = privacyChange.findIndex(p => p.name === value)
        privacyChange[index] = { name: value, status: checked }
        setPrivacy(privacyChange)
    }

    const savePrivacy = async () => {
        setSaveClicked(false)
      setSubmittingFrom(true)
      try {
        const parameters = {
          clickName: 'privacy',
          userPrivacyInfo: privacy
        }
        const res = await post('/candidateProfile/postPrivacy', parameters)
        if (res.status === 200) {
          setSubmittingFrom(false)
          setShowSuccessMessage(true)      
          setTimeout(() => { 
            setReadOnlyMode(true)
            setShowSuccessMessage(false)
          }, 1000)
        }
        else {
          toast.error("Some Error Occured. Please Try Again")
        }
      } catch (error) {
        toast.error("Error Occured. Please Try Again")
      } 
    }

    return (
        <div class="card card-lg mb-2">
            <div class="card-body"> 
                <div className='d-flex align-items-center justify-content-between'>
                    <h6>Privacy Settings</h6>
                    {readOnlyMode === true ? (
                    <button
                        type="button"
                        class="btn btn-text"
                        onClick={editPrivacy}
                    >
                        <i class="fas fa-pencil-alt mr-1"></i>
                        Edit
                    </button>
                    ) : (
                    <div>
                        <button
                        type="button"
                        onClick={cancelPrivacy}
                        class="btn btn-sm btn-secondary mr-1"
                        >
                        Cancel
                        </button>
                        <button
                        type="button"
                        onClick={savePrivacy}
                        class="btn btn-sm btn-primary"
                        >
                        {saveClicked && <span>Save Changes</span>}

                        {submittingForm && (
                            <span>
                            Saving Changes
                            <img
                                width="20px"
                                src={ButtonLoader}
                                alt=""
                            />
                            </span>
                        )}
                        {showSuccessMessage && <span>Changes Saved!</span>}
                        </button>
                    </div>
                    )}               
                </div>                           
                <br />
                <div className='mt-2'>               
                    { readOnlyMode ? 
                        privacy.map( privacyDetails => 
                        <>
                            <div class="d-flex align-items-center justify-content-between py-1">
                                <label class="p-0">Show My {privacyDetails.name} to Recruiters and Companies</label>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" checked={privacyDetails.status} />
                                </div>
                            </div><hr />
                        </> )
                    : privacy.map( privacyDetails => 
                        <>
                            <div class="d-flex align-items-center justify-content-between py-1">
                                <label class="p-0">Show My {privacyDetails.name} to Recruiters and Companies</label>
                                <div class="form-check form-switch">
                                    <input class="form-check-input" type="checkbox" onChange={(e) => handleChange(privacyDetails.name, e.target.checked)} checked={privacyDetails.status} />
                                </div>
                            </div><hr />
                        </> )
                    }
                </div>
            </div>
        </div>
    )
}

export default PrivacySettings
