import { toast } from "react-toastify";
import { get } from "../../service/ApiService";

export const GET_SUBCATEGORY_BEGIN = "GET_SUBCATEGORY_BEGIN";
export const GET_SUBCATEGORY_SUCCESS = "GET_SUBCATEGORY_SUCCESS";
export const GET_SUBCATEGORY_FAILURE = "GET_SUBCATEGORY_FAILURE";

export const getSubcategoryDetails = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_SUBCATEGORY_BEGIN,
    });
    const res = await get("/common/getsubcategoryfromtenant");
    if (res.status === 200) {
      dispatch({
        type: GET_SUBCATEGORY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SUBCATEGORY_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
