import React, { useState, useEffect } from "react";
import SearchHistory from "../modal/SearchHistory";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import {
  domain,
  apiDomain,
  BotImage,
  ChatImage,
} from "../../constants/Constants";
import {
  getCandidateName,
  getUserRole,
  getUserName,
  getUserId,
  loginUserName,
} from "../../service/AuthService";
import { get, post } from "../../service/ApiService";
import axios from "axios";
import SupportModal from "../layout/SupportModal";
import Avatar from "react-avatar";
import { Col, Row } from "react-bootstrap";
import Notification from "./Notification/Notification";
import JobPromotion from "./JobPromotion";
import Modal from "react-bootstrap/Modal";
import Event from "../../googleanalytic/Tracking";
import ReactGa from "react-ga";
import Loader from "../Loader/Loader";
import BulkCommunicationModal from "../components_v3/talent/talentcommunity/communication/BulkCommunicationModal";

function TopNavBar(props) {
  const TRACKING_ID = "UA-223748254-1";
  ReactGa.initialize(TRACKING_ID);
  const { empData } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [toggle, setToggle] = useState(true);
  const [loading, setLoading] = useState(false);
  const [toggleProfile, setToggleProfile] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const [toggleRole, setToggleRole] = useState(false);
  const [toggleNotification, setToggleNotification] = useState(false);
  const [openSearchHistory, setOpenSearchHistory] = useState(false);
  const [serchInput, setSerchInput] = useState("");
  const [userprofile, setProfile] = useState({ name: "" });
  const { name } = userprofile;
  const [support, setSupport] = useState([]);
  const [supportModal, setSupportModal] = useState(false);
  const [roleModel, setRoleModel] = useState(false);
  const [RoleType, setRoleType] = useState(0);
  const [supportType, setSupportType] = useState(0);
  const [enableSupportSearch, setEnableSupportSearch] = useState(false);
  const [supportData, setSupportData] = useState([]);
  const [supportSearchValue, setSupportSearchValue] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedTenant, setSelectedTenant] = useState("");
  const [show, setShow] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState("");
  const [bot, setBot] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [isFrom, setIsFrom] = useState("");

  useEffect(() => {
    const username = getCandidateName();
    username.substr(0, username.indexOf(" "));
    setProfile({ name: username });
    fetchSupportAPI();
  }, []);

  useEffect(() => {
    const adminTokenDetails = localStorage.getItem("adminUserToken");
    // console.log(adminTokenDetails)
    if (!adminTokenDetails) {
      const adminUserToken = localStorage.getItem("userToken");
      localStorage.setItem("adminUserToken", adminUserToken);
    }
  }, []);

  const fetchSupportAPI = async () => {
    let id = JSON.parse(localStorage.getItem("userToken")).roleId;
    const res = await get("/support");
    setSupport(res.data.filter((s) => s.fk_role.includes(id)));
  };

  const clientChange = async (e) => {
    setUserRoles([]);
    setSelectedRole("");
    setUsers([]);
    setSelectedUser("");
    if (e.target.value) {
      setSelectedTenant(e.target.value);
      const roles = new Set(
        empData
          .filter((eachdata) => eachdata.tenantid === e.target.value)
          .map((eachdata) => eachdata.rolename)
      );
      setUserRoles([...roles]);
    } else {
      setSelectedTenant(e.target.value);
    }
  };

  const handleRoleChange = async (e) => {
    setUsers([]);
    setSelectedUser("");
    if (e.target.value) {
      setSelectedRole(e.target.value);
      const employees = empData.filter((item) => {
        if (
          item.rolename === e.target.value &&
          item.tenantid === selectedTenant
        ) {
          return item;
        }
      });
      setUsers(employees);
    } else {
      setSelectedRole(e.target.value);
    }
  };

  const updateSelectedUser = async (e) => {
    if (e.target.value) {
      setSelectedUser(e.target.value);
      const employees = empData.filter((item) => {
        if (item.userId == e.target.value) {
          return item;
        }
      });
      if (employees.length > 0) {
        setSelectedEmail(employees[0].email);
      }
    } else {
      setSelectedUser(e.target.value);
    }
  };

  //console.log('USERS', users)
  const toggleProfileDropdown = () => {
    if (toggleProfile === false) {
      setToggleProfile(true);
      setToggleHelp(false);
      setToggleNotification(false);
      setToggleRole(false);
    } else {
      setToggleProfile(false);
    }
  };

  const openHelp = () => {
    if (toggleHelp === false) {
      setToggleHelp(true);
      setToggleProfile(false);
      setToggleNotification(false);
      setToggleRole(false);
    } else {
      setToggleHelp(false);
    }
  };
  const openRole = () => {
    if (toggleRole === false) {
      setToggleRole(true);
      setToggleProfile(false);
      setToggleNotification(false);
    } else {
      setToggleRole(false);
    }
  };

  const openNotification = () => {
    if (toggleNotification === false) {
      setToggleNotification(true);
      setToggleHelp(false);
      setToggleProfile(false);
    } else {
      setToggleNotification(false);
    }
  };

  const searchCliked = () => {
    setOpenSearchHistory(true);
  };

  const handleClickOutside = () => {
    setOpenSearchHistory(false);
  };

  const handleSearch = (e) => {
    setSerchInput(e.target.value);
    setOpenSearchHistory(true);
  };

  const onClickLogout = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const headers = { authorization: token };
    dispatch(handleLogout({ headers }));
  };

  const handleLogout = (header) => async (dispatch) => {
    try {
      const res = await axios.put(`${apiDomain}/logout`, null, header);
      let role = getUserRole();
      let name = loginUserName();
      let result = `${name},${role}`;

      if (res.status === 200) {
        Event("Logout", result);
        localStorage.clear();
        history.push("/login");
      }
    } catch (error) {
      toast.error("Unble to Logout!");
    }
  };

  const openSupportModal = (e) => {
    e.preventDefault();
    setSupportType(+Object.values(e.target)[0].key);
    setSupportModal(true);
  };
  const openRoleModal = (e) => {
    e.preventDefault();
    setRoleType(+Object.values(e.target)[0].key);
    setRoleModel(true);
  };

  const handleSupportSearch = (e) => {
    setSupportSearchValue(e.target.value);
    setEnableSupportSearch(true);
    setSupportData(
      support.filter((i) =>
        i.Title.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const closeSupportModal = () => {
    setSupportModal(false);
  };
  const closeRoleModal = () => {
    setRoleModel(false);
  };

  const handleSubmit = async () => {
    if (selectedUser) {
      const payload = {
        userName: selectedEmail,
        domain: domain,
        role: getUserRole(),
        actualUserName: getUserName(),
        candidateName: getCandidateName(),
        userId: getUserId(),
      };
      try {
        setLoading(true);
        let res = await post("/login/dashboardlogin", payload);
        // console.log('RESPONSE', res)
        if (res.status === 200) {
          localStorage.setItem("userToken", JSON.stringify(res.data));
          //     history.push('/high5Hire')
          window.location.reload(true);
        }
        setLoading(false);
      } catch {
        toast.error("Error!!");
        setLoading(false);
      }
    } else {
      toast.error("please select employee .");
    }
  };
  const handleShow = () => {
    setShow(true);
  };

  const splitMatchedText = (str) => {
    const regExp = new RegExp(supportSearchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='background: blue; color: white'>${match}</span>`
    );
  };
  const toglleDiv = () => {
    if (toggle === false) {
      props.handleToggale(true);
      setToggle(true);
    } else {
      props.handleToggale(false);
      setToggle(false);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div id="TopBar">
        {/* **** toggle div for main menu  ***** */}
        <div className="nav-toggle-wrap" style={{ width: "50px" }}>
          <button
            id="MainMenuToggle"
            onClick={toglleDiv}
            type="button"
            className="btn btn-icon nav-toggle btn-nav"
          >
            <i className="fal fa-angle-left"></i>
          </button>
          <div className="divider"></div>
        </div>
        <div className="container-fluid">
          {/*   <Row>
             <Col>
              <div className="form-floating d-none-1024">
                <select
                  className="font-14 form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  value={selectedTenant}
                  onChange={(e) => clientChange(e)}
                  style={{
                    fontSize: "14px",
                    height: "calc(3.2rem)",
                    borderRadius: "0px",
                    paddingTop: "1.2rem",
                  }}
                >
                  <option value="" selected>
                    Select Client
                  </option>
                  {props.tenantData.map((item) => (
                    <option value={item.id_tenant}>
                      {item.tenantname.replace("GIG - ", "")}
                    </option>
                  ))}{" "}
                  {props.freeLancers.map((item) => (
                    <option value={item.id_tenant}>
                      {item.tenantname.replace("GIG - ", "")}
                    </option>
                  ))}
                </select>
                <label for="floatingSelect">Client</label>
              </div>
            </Col>
            <Col>
              <div className="form-floating d-none-1024">
                <select
                  className="font-14 form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  onChange={(e) => handleRoleChange(e)}
                  style={{
                    fontSize: "14px",
                    height: "calc(3.2rem)",
                    borderRadius: "0px",
                    paddingTop: "1.2rem",
                  }}
                  value={selectedRole}
                >
                  <option value="" selected>
                    Select Role
                  </option>
                  {userRoles.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                <label for="floatingSelect">Role</label>
              </div>
            </Col>
            <Col>
              <div className="font-14 form-floating d-none-1024">
                <select
                  className="form-select"
                  id="floatingSelect"
                  aria-label="Floating label select example"
                  onChange={(e) => updateSelectedUser(e)}
                  value={selectedUser}
                  style={{
                    fontSize: "14px",
                    height: "calc(3.2rem)",
                    borderRadius: "0px",
                    paddingTop: "1.2rem",
                  }}
                >
                  <option value="" selected>
                    Select Employee
                  </option>
                  {users.map((item) => (
                    <option value={item.userId}>
                      {item.firstName + " " + item.lastName}
                    </option>
                  ))}
                </select>
                <label for="floatingSelect">Employee</label>
              </div>
            </Col> 
            <Col className="font-green">
              <div className="">
                <button
                  type="Submit"
                  className="btn btn-primary btn-sm d-none-1024"
                  onClick={() => handleSubmit()}
                  style={{
                    width: "52px",
                    marginTop: "12px",
                    marginLeft: "20px",
                  }}
                  disabled={selectedUser === ""}
                >
                  Go
                </button>
              </div>
            </Col>
          </Row>*/}
        </div>
        <div className="extras ml-auto text-right">
          {/* <button
            type="button"
            onClick={openNotification}
            className="btn btn-action-panel btn-icon btn-nav ml-3 "
          >
            <i className="fal fa-fw fa-bell"></i>
            <span className="jewel"></span>
          </button>
          {toggleNotification && (
            <div
              className="action-panel"
              style={{
                display: "revert",
                right: "0px",
                top: "50px",
                textAlign: "left",
              }}
            >
              {" "}
              <OutsideClickHandler
                onOutsideClick={() => {
                  setToggleNotification(false);
                }}
              >
                {" "}
                <Notification />{" "}
              </OutsideClickHandler>{" "}
            </div>
          )} */}
          <button
            className="btn btn-action-panel btn-icon btn-nav flex-item"
            id="roleSelection"
            type="button"
            onClick={openRole}
          >
            <i className="fal fa-fw fa-user-secret" />
          </button>
          {/* <button
            className="btn btn-icon flex-item"
            type="button"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Promotion"
            onClick={handleShow}
          >
            <i className="fad fa-fw fa-bullhorn ml-3" />
          </button> */}
          {toggleRole && (
            <div
              className="action-panel"
              style={{
                display: "revert",
                right: "0px",
                top: "50px",
                textAlign: "left",
              }}
            >
              <OutsideClickHandler
                onOutsideClick={() => !roleModel && setToggleRole(false)}
              >
                <>
                  <Row>
                    <Col>
                      <div className="form-floating">
                        <select
                          className="font-14 form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          onChange={(e) => clientChange(e)}
                          style={{
                            fontSize: "14px",
                            height: "calc(3.2rem)",
                            borderRadius: "0px",
                            paddingTop: "1.2rem",
                          }}
                          // disabled={selectedTenant !== undefined}
                        >
                          <option value="" selected>
                            Select Client
                          </option>
                          {props.tenantData.map((item) => (
                            <option value={item.id_tenant}>
                              {item.tenantname.replace("GIG - ", "")}
                            </option>
                          ))}{" "}
                          {props.freeLancers.map((item) => (
                            <option value={item.id_tenant}>
                              {item.tenantname.replace("GIG - ", "")}
                            </option>
                          ))}
                        </select>
                        <label for="floatingSelect">Client</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-floating">
                        <select
                          className="font-14 form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          onChange={(e) => handleRoleChange(e)}
                          style={{
                            fontSize: "14px",
                            height: "calc(3.2rem)",
                            borderRadius: "0px",
                            paddingTop: "1.2rem",
                          }}
                          // disabled={seletedRole !== undefined && selectedTenant !== undefined}
                        >
                          <option value="Select Role" selected>
                            Select Role
                          </option>
                          {userRoles.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                        <label for="floatingSelect">Role</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="font-14 form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          // onChange={(e) => props.handleChange(e)}
                          onChange={(e) => updateSelectedUser(e)}
                          style={{
                            fontSize: "14px",
                            height: "calc(3.2rem)",
                            borderRadius: "0px",
                            paddingTop: "1.2rem",
                          }}
                        >
                          <option value="" selected>
                            Select Employee
                          </option>
                          {users.map((item) => (
                            <option value={item.userId}>
                              {item.firstName + " " + item.lastName}
                            </option>
                          ))}
                        </select>
                        <label for="floatingSelect">Employee</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="font-green">
                      <div className="">
                        <input
                          type="Submit"
                          className="btn btn-primary btn-sm"
                          value={"Go"}
                          onClick={() => handleSubmit()}
                          style={{ marginTop: "12px", marginLeft: "20px" }}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              </OutsideClickHandler>
            </div>
          )}
          <button
            type="button"
            title="Communication"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
            onClick={() => {
              setShowEmailModal(true);
              setIsFrom("topNavbar");
            }}
          >
            <div class="notification-icon">
              {" "}
              <i class="fal fa-fw fa-envelope"></i>
            </div>
          </button>
          <button
            type="button"
            title="Support"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
            onClick={openHelp}
          >
            <i className="fal fa-fw fa-life-ring"></i>
          </button>
          {toggleHelp && (
            <div
              className="action-panel"
              style={{
                display: "revert",
                right: "0px",
                top: "50px",
                textAlign: "left",
              }}
            >
              <OutsideClickHandler
                onOutsideClick={() => !supportModal && setToggleHelp(false)}
              >
                <div
                  className="action-panel"
                  style={{
                    display: "revert",
                    right: "0px",
                    top: "50px",
                    textAlign: "left",
                  }}
                >
                  <div className="py-3 px-4">
                    <p className="lead mb-0">Hello {getCandidateName()}</p>
                    <strong>What can we help you with today?</strong>
                    {/* <div className="d-flex align-items-center justify-content-center m-3">
                      Chat With Us
                      <a
                        className="ml-auto"
                        onClick={(e) => {
                          e.preventDefault();
                          setBot(true);
                        }}
                      >
                        <img src={BotImage} width='55px'/>
                      </a>
                    </div> */}
                    <div className="search-field mb-3 mt-2">
                      <div className="field-wrap">
                        <input
                          type="search"
                          onChange={handleSupportSearch}
                          placeholder="Search Support"
                        />
                        <span className="icon">
                          <i aria-hidden="true" className="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                    <strong>
                      <small>Common Questions</small>
                    </strong>
                    <ul className="mt-1 ml-0 list-unstyled">
                      {enableSupportSearch
                        ? supportData.length !== 0
                          ? supportData.map((s, i) => (
                              <li>
                                <a
                                  key={i}
                                  onClick={openSupportModal}
                                  href=""
                                  dangerouslySetInnerHTML={{
                                    __html: splitMatchedText(s.Title),
                                  }}
                                ></a>
                              </li>
                            ))
                          : "No Questions available "
                        : support.length !== 0
                        ? support.map((s, i) => (
                            <li>
                              <a key={i} onClick={openSupportModal} href="">
                                {s.Title}
                              </a>
                            </li>
                          ))
                        : "No Questions available "}
                    </ul>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          )}{" "}
          {/* Help div ended  */}
          {/* Profile div started  */}
          <div className="dropdown ml-3">
            <button
              className="btn btn-icon dropdown-toggle"
              type="button"
              onClick={toggleProfileDropdown}
            >
              <Avatar
                className="avatar avatar-sm "
                name={userprofile.name}
                round="80px"
              />
            </button>
            {toggleProfile && (
              <div
                className="dropdown-menu"
                style={{ display: "revert", right: "-14px", top: "30px" }}
              >
                <a href="/high5hire/myprofile" className="dropdown-item">
                  <i className="fal fa-user-circle mr-3"></i>Profile
                </a>
                {/* <a href="#top" className="dropdown-item" ><i className="fal fa-cog mr-3"></i>Preferences</a> */}
                <hr />
                <a
                  href="#top"
                  className="dropdown-item"
                  onClick={onClickLogout}
                >
                  <i className="fal fa-sign-out mr-3"></i> Logout{" "}
                </a>
              </div>
            )}
            {/* Profile div ended */}
          </div>
        </div>
      </div>
      <OutsideClickHandler onOutsideClick={handleClickOutside}>
        {openSearchHistory && <SearchHistory serchInput={serchInput} />}
      </OutsideClickHandler>
      {supportModal && (
        <SupportModal
          supportData={support[supportType]}
          closeSupportModal={closeSupportModal}
        />
      )}
      {showEmailModal && (
        <BulkCommunicationModal
          showjobModal={showEmailModal}
          setShowJobModal={setShowEmailModal}
          isFrom={isFrom}
        />
      )}
      {/* for job promotion*/}
      <JobPromotion handleShow={handleShow} show={show} setShow={setShow} />

      <Modal show={bot}>
        <Modal.Header>
          {/* <h6>FAQ</h6> */}
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setBot(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="bl-modal-body  text-center">
            <div className="avatar avatar-lg avatar-green1 mr-3">
              <img src={ChatImage} />
            </div>
          </div>
          <iframe
            src="https://webchat.botframework.com/embed/tesktmaker-bot?s=93Oks9s9wyE.ftP4MgM9ZhhjGjWkhvwhHqZcK7mklyKAxoDi5XeGIKs"
            width="100%"
            height="350px"
          ></iframe>
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center m-3"></div>
      </Modal>
    </>
  );
}

export default TopNavBar;
