import React, { useState, useEffect } from "react";
import Automatch from "./Automatch";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";
import PaginationComponent from "../../../../pagination/pagination";
import { useParams } from "react-router";
import { getPoolDispositionDetails } from "../../../../../../actions/directsourcing/Disposition";
import Loader from "../../../../commoncomponents/Loader/Loader";

const Index = (props) => {
  const {
    jobData,
    calculateScore,
    fetchLatestEmailStatus,
    view,
    currentPage,
    setCurrentPage,
  } = props;

  const dispatch = useDispatch();
  const { poolId } = useParams();

  /*** Sort Param  * */
  const [nameSortSelected, setNameSortSelected] = useState(null);
  const [dateSortSelected, setDateSortSelected] = useState(null);
  const [sortSelected, setSortSelected] = useState({
    firstName: false,
    date: false,
  });
  const [sortArray, setSortArray] = useState([]);
  /*** Sort Param  * */

  const [automatchData, setAutomatchData] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const {
    dispositionDetails,
    dispositionLoading,
    dispositionDisqualifyLoading,
    dispositionDisqualify,
    dispositionCount,
    dispositionCountLoading,
  } = useSelector(
    (state) => ({
      dispositionCount: state.poolDispositionCount.dispositionCount,
      dispositionCountLoading:
        state.poolDispositionCount.dispositionCountLoading,
      dispositionDetails: state.poolDisposition.dispositionDetails,
      dispositionLoading: state.poolDisposition.dispositionDetailsLoading,
      dispositionDisqualifyLoading:
        state.poolDispositionDisqualify.dispositionDisqualifyLoading,
      dispositionDisqualify:
        state.poolDispositionDisqualify.dispositionDisqualify,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (
      paginationArray.length > 0 &&
      !paginationArray[0].hasOwnProperty("latestEmailStatus")
    ) {
      fetchLatestEmailStatus(
        paginationArray,
        setPaginationArray,
        "communityAutoMatched"
      );
    }
  }, [paginationArray]);
  useEffect(() => {
    setAutomatchData(
      dispositionDetails.filter((item) => item.dispositionStatus == 38)
    );
  }, [dispositionDetails]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: view,
      poolId: poolId,
      filterObject: props.filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionDetails(payload));
  };

  /*** Sort Param  * */
  useEffect(() => {
    dispatch(
      getPoolDispositionDetails({
        currentPage,
        limit: 15,
        status: view,
        poolId,
        filterObject: props.filterParams,
        searchText: props.value,
        sortParam: sortArray.length > 0 ? sortArray : [],
      })
    );
  }, [sortArray, sortSelected]);

  const handleSort = (sortValue) => {
    let sortType = "";
    let temp = { ...sortSelected };
    if (sortValue === "firstName") {
      sortType = !nameSortSelected
        ? "ASC"
        : nameSortSelected === "ASC"
        ? "DESC"
        : "ASC";
      setNameSortSelected(sortType);
      setDateSortSelected(null);
      temp = {
        firstName: true,
        date: false,
      };
      setSortSelected(temp);
    } else {
      sortType = !dateSortSelected
        ? "ASC"
        : nameSortSelected === "ASC"
        ? "DESC"
        : "ASC";
      setDateSortSelected(sortType);
      setNameSortSelected(null);
      temp = {
        firstName: false,
        date: true,
      };
      setSortSelected(temp);
    }
    setSortArray(sortValue ? [{ sortValue, sortType }] : []);
  };

  /*** Sort Param  * */

  return (
    <div>
      {(dispositionLoading ||
        dispositionCountLoading ||
        dispositionDisqualifyLoading) && <Loader />}
      <div className="table-wrap">
        {automatchData.length > 0 ? (
          <>
            <table className="table table-sortable table-hoverable-cells table-cards-1024">
              <thead className="mb-2">
                <tr className="d-none d-lg-table-row">
                  <th class="d-flex align-items-start ">
                    Candidate{" "}
                    <div
                      // class="d-flex align-items-center ml-2"
                      onClick={() => {
                        handleSort("firstName");
                      }}
                    >
                      {!nameSortSelected ? (
                        <>
                          <i
                            className="fad fa-sort-down ml-2 font-16"
                            style={{ color: "grey" }}
                          ></i>
                        </>
                      ) : nameSortSelected === "ASC" ? (
                        <>
                          <i
                            className="fad fa-sort-down ml-2 font-16"
                            style={{ color: "orange" }}
                          ></i>
                        </>
                      ) : (
                        <>
                          <i
                            className="fad fa-sort-up font-16"
                            style={{ color: "orange" }}
                          ></i>
                        </>
                      )}
                    </div>
                  </th>

                  {/* <th className="hover">Vetted </th> */}
                  <th>
                    {/* Date */}
                    <div
                      class="d-flex align-items-center ml-2"
                      onClick={() => {
                        handleSort("automatchDate");
                      }}
                    >
                      Date
                      {!dateSortSelected ? (
                        <>
                          <i
                            className="fad fa-sort-down ml-2 font-16"
                            style={{ color: "grey" }}
                          ></i>
                        </>
                      ) : dateSortSelected === "ASC" ? (
                        <>
                          <i
                            className="fad fa-sort-down ml-2 font-16"
                            style={{ color: "orange" }}
                          ></i>
                        </>
                      ) : (
                        <>
                          <i
                            className="fad fa-sort-up font-16"
                            style={{ color: "orange" }}
                          ></i>
                        </>
                      )}
                    </div>
                  </th>
                  {/* <th className="hover">Screening</th> */}
                  <th className="hover">Resume</th>
                  <th className="hover">Open Link</th>
                  <th className="hover">Recruiter Name</th>
                  <th className="hover">Source Channel</th>
                  {/* <th className="">Score</th> */}
                </tr>
              </thead>
              <tbody>
                <Automatch
                  jobData={jobData}
                  automatchData={automatchData}
                  calculateScore={calculateScore}
                  filterParams={props?.filterParams}
                  view={view}
                  value={props?.value}
                />
              </tbody>
            </table>
            <PaginationComponent
              currentPage={currentPage}
              totalItems={
                dispositionCount?.filter((e) => e.Status == "Automatched")[0]
                  ?.Count
              }
              itemsPerPage={15}
              onPageChange={handlePageChange}
              limit={15}
            />
          </>
        ) : (
          <NoRecord />
        )}
      </div>
    </div>
  );
};

export default Index;
