import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const EditClient = (props) => {
    return (
        <Modal show={true} size="lg" animation={true}>

            <Modal.Header>
                <Modal.Title className='pb-0 pt-1 font-16'><h6>Update Clients</h6></Modal.Title>
                <button
                    type="button"
                    onClick={props.closeEditClient}
                    className="close p-0 bl-modal-close-btn closable"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <i className="fal fa-times closable"></i>
                </button>
            </Modal.Header>
        
            <Modal.Body>
                <div className='pl-5 m-5'>
                    Put from here
                </div>
                
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={props.closeEditClient}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={props.closeEditClient}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditClient
