import {
  GET_RECRUITEMENT_PIPELINE_BEGIN,
  GET_RECRUITEMENT_PIPELINE_SUCCESS,
  GET_RECRUITEMENT_PIPELINE_FAILURE,
  GET_RECRUITEMENT_PIPELINE_COUNT_BEGIN,
  GET_RECRUITEMENT_PIPELINE_COUNT_SUCCESS,
  GET_RECRUITEMENT_PIPELINE_COUNT_FAILURE,
  GET_RECRUITER_NAME_BEGIN,
  GET_RECRUITER_NAME_SUCCESS,
  GET_RECRUITER_NAME_FAILURE,
} from "../../actions/dashboard/RecruitementPipeline";

const initialState = {
  loading: false,
  countLoading: false,
  talentData: [],
  recruitementPipelineCount: [],
  recruiterName: [],
  nameLoading: false,
};

const getRecruitementPipelineReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECRUITEMENT_PIPELINE_BEGIN:
      return {
        ...state,
        loading: true,
        talentData: [],
      };
    case GET_RECRUITEMENT_PIPELINE_SUCCESS:
      return {
        ...state,
        talentData: payload,
        loading: false,
      };
    case GET_RECRUITEMENT_PIPELINE_FAILURE:
      return {
        ...state,
        talentData: [],
        loading: false,
      };
    case GET_RECRUITEMENT_PIPELINE_COUNT_BEGIN:
      return {
        ...state,
        countLoading: true,
        recruitementPipelineCount: [],
      };
    case GET_RECRUITEMENT_PIPELINE_COUNT_SUCCESS:
      return {
        ...state,
        recruitementPipelineCount: payload,
        countLoading: false,
      };
    case GET_RECRUITEMENT_PIPELINE_COUNT_FAILURE:
      return {
        ...state,
        recruitementPipelineCount: [],
        countLoading: false,
      };
    case GET_RECRUITER_NAME_BEGIN:
      return {
        ...state,
        nameLoading: true,
        recruiterName: [],
      };
    case GET_RECRUITER_NAME_SUCCESS:
      return {
        ...state,
        recruiterName: payload,
        nameLoading: false,
      };
    case GET_RECRUITER_NAME_FAILURE:
      return {
        ...state,
        recruiterName: [],
        nameLoading: false,
      };
    default:
      return state;
  }
};

export default getRecruitementPipelineReducer;
