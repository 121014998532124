import {
    FETCH_CAREER_FILTER_JOB_LIST_BEGIN,
    FETCH_CAREER_FILTER_JOB_LIST_SUCCESS,
    FETCH_CAREER_FILTER_JOB_LIST_FAILURE,
  } from "../../actions/jobList/CareerFiterJobList";
  
  const initialState = {
    loading: true,
    jobFilterList: null,
  };
  
  const getCareerFilterJobListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case FETCH_CAREER_FILTER_JOB_LIST_BEGIN:
        return {
          ...state,
          loading: true,
          jobFilterList: null,
        };
      case FETCH_CAREER_FILTER_JOB_LIST_SUCCESS:
        return {
          ...state,
          jobFilterList: payload,
          loading: false,
        };
      case FETCH_CAREER_FILTER_JOB_LIST_FAILURE:
        return {
          ...state,
          jobFilterList: payload,
          loading: false,
        };
  
      default:
        return state;
    }
  };
  
  export default getCareerFilterJobListReducer;
  