import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ShowSupportQuestions from "../../../superadmin/dashboard/ShowSupportQuestions";
import EditSupportQuestions from "../../../superadmin/dashboard/EditSupportQuestions";
import { get, post } from "../../../../service/ApiService";
//import { get, post } from "../../../service/ApiService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";
import TemplateSidePanel from "../../../superadmin/dashboard/TemplateSidePanel";
import AddJobTemplate from "../../../superadmin/dashboard/AddJobTemplate";
import { getTenantType, getUserRole } from "../../../../service/AuthService";

//import Pagination from "./TemplatePagination";
// import EditJobTemplate from "./EditJobTemplate";
// import AddJobNew from "../../modal/addjob/AddJobNew";
import MainAddJob from "../../job/addjob";
import TopNavBar from "../../../layout/TopNavBar";
import SideNavBar from "../../../layout/SideNavBar";
import Footer from "../../../layout/Footer";
// import { from } from "@apollo/client/core";

function FAQSetup() {
  const [isQuestionsModel, setIsQuestionsModel] = useState(false);
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [FAQData, setFAQData] = useState({});
  const [sidePanel, setSidePanel] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);
  const [toggle, setToggle] = useState(true);

  const roleDetails = [
    // { name: "Admin", id: "1" },
    { name: "Candidate", id: "2" },
    { name: "Recruiter", id: "3" },
    { name: "Hiring Manager", id: "4" },
    // {name: "Employee", id: '5'},
    { name: "Super Admin", id: "6" },
    // {name: "Sales Admin", id: '7'},
    { name: "Customer Success Manager", id: "8" },
    { name: "Marketplace Manager", id: "9" },
    { name: "High5 Admin", id: "11" },
  ];

  const [templates, setTemplates] = useState([]);
  const [templateDetails, setTemplateDetails] = useState({});
  const [editTemplate, setEditTemplate] = useState(false);
  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  const fetchFAQ_API = async () => {
    try {
      setLoading(true);
      let res = await get("/support");
      if (res.status === 200) {
        let x = res.data.map((i) => ({
          ...i,
          ImageURL: i.ImageURL ? JSON.parse(i.ImageURL)[0].ImageURL : "",
        }));
        setFaq(x);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Error Occured While accessing FAQ's");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing FAQ's");
    }
  };

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      let res = await get("/job/fetchTemplates");
      if (res.status === 200) {
        setTemplates(
          res.data.sort(
            (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
          )
        );
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Error Occured While accessing Templates");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Some Error Occured While accessing Templates");
    }
  };

  useEffect(async () => {
    await fetchFAQ_API();
    await fetchTemplates();
  }, []);

  const addQuestionModel = () => {
    setIsQuestionsModel(true);
  };

  const close = () => {
    setIsQuestionsModel(!isQuestionsModel);
  };

  const deleteFAQ = async (data) => {
    try {
      setLoading(true);
      let res = await post("/support/deleteData", { id: data.ID_support });
      if (res.status === 200) {
        setLoading(false);
        setTimeout(() => {
          fetchFAQ_API();
        }, 4000);
        toast.success("FAQ Deleted Successfully");
      } else {
        setLoading(false);
        toast.error("Error Occured While deleting FAQ's");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing FAQ's");
    }
  };
  useEffect(() => {});
  return (
    <body
      className={
        toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
      }
    >
      <div className="body-decorator body-decorator-top"></div>
      <div className="body-decorator body-decorator-bottom"></div>
      <TopNavBar parent="adminConsole" handleToggale={toggleDiv} />
      <SideNavBar />
      <div id="Content">
        <div className="section section-sm">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-12 px-5">
                <h5 className="mb-4">FAQ Setup</h5>
                {loading && <Loader />}

                {/* {userTemplates && (
        <div className="p-3">
          <div className="mb-2">
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                <select
                  className="form-select mb-2 font-14"
                  onChange={(e) => setCategory(e.target.value)}
                >
                  <option value="all">All</option>
                  {[...new Set(templates.map((item) => item.category))].map(
                    (item) => (
                      <option selected={item === category} value={item}>
                        {item}
                      </option>
                    )
                  )}
                </select>
              </div>
              <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                <input
                  onChange={(e) => handleSearch(e.target.value)}
                  type="text"
                  className="form-control small font-14"
                  placeholder="Search Templates"
                />
              </div>
            </div>
          </div>
        </div>
      )} */}

                {/* FAQs */}
                <>
                  <div className="d-flex align-items-center justify-content-end mt-5 mb-4">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm"
                      onClick={addQuestionModel}
                    >
                      <span>
                        <i
                          className="fas fa-fw mr-2 fa-plus "
                          aria-hidden="true"
                        ></i>
                        Add FAQ
                      </span>
                    </button>
                  </div>
                  {/* <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2a-header"
              style={{ flexDirection: "row-reverse" }}
            >
              <Typography>
                <strong>FAQs</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>  </AccordionDetails>
          </Accordion>*/}{" "}
                  {faq.length !== 0 ? (
                    <div className="row">
                      <div className="col-lg-12">
                        {roleDetails.map((item1) => (
                          <Accordion>
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls="panel2a-content"
                              id="panel2a-header"
                              style={{ flexDirection: "row-reverse" }}
                            >
                              <Typography>
                                <p className="flex-item ml-10 mb-0">
                                  {item1.name}
                                </p>
                              </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                              <Typography>
                                {faq
                                  .filter((item) =>
                                    item.fk_role.includes(item1.id)
                                  )
                                  .map((que) => (
                                    <Accordion>
                                      <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                          flexDirection: "row-reverse",
                                        }}
                                      >
                                        <Typography>
                                          <strong className="mb-0">
                                            {que.Title}
                                          </strong>
                                        </Typography>
                                      </AccordionSummary>
                                      <AccordionDetails>
                                        <Typography>
                                          <div className="row">
                                            <div className="col-lg-10">
                                              <p
                                                dangerouslySetInnerHTML={{
                                                  __html: que.Content,
                                                }}
                                              ></p>
                                            </div>
                                            {que.ImageURL && (
                                              <div className="row">
                                                <div className="col-lg-4">
                                                  <img
                                                    src={que.ImageURL}
                                                    alt="Image Not Found"
                                                    width="700"
                                                    height="350"
                                                  />
                                                </div>
                                              </div>
                                            )}
                                            <div className="row">
                                              <div className="col-lg-11"></div>
                                              <div className="col-lg-1 d-flex align-items-end">
                                                <div>
                                                  <button
                                                    type="button"
                                                    className="btn btn-icon"
                                                    onClick={() => {
                                                      setFAQData(que);
                                                      setEditModal(true);
                                                    }}
                                                  >
                                                    <i
                                                      className="fal fa-pencil fa-fw"
                                                      aria-hidden="true"
                                                    />
                                                  </button>
                                                </div>
                                                <div>
                                                  <button
                                                    type="button"
                                                    className="btn btn-icon"
                                                    onClick={() => {
                                                      setFAQData(que);
                                                      deleteFAQ(que);
                                                    }}
                                                  >
                                                    <i
                                                      className="fal fa-trash-alt fa-fw ml-3"
                                                      aria-hidden="true"
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Typography>
                                      </AccordionDetails>
                                    </Accordion>
                                  ))}
                              </Typography>
                            </AccordionDetails>
                          </Accordion>
                        ))}
                      </div>
                      <div className="col-lg-2"></div>
                    </div>
                  ) : (
                    <div className="text-center p-3">
                      <div className="avatar avatar-lg">
                        <i className="fad fa-users-slash" />
                      </div>
                      <div className="mt-2">No FAQ Found</div>
                    </div>
                  )}
                </>

                <Modal show={isQuestionsModel} size="lg">
                  <Modal.Header>
                    <Modal.Title className="pt-1 font-16">
                      <h6>Add Frequently Asked Questions</h6>
                    </Modal.Title>
                    <button
                      type="button"
                      onClick={close}
                      className="close p-0 bl-modal-close-btn closable"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fal fa-times closable"></i>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <ShowSupportQuestions
                      close={close}
                      fetchFAQ_API={fetchFAQ_API}
                    />
                  </Modal.Body>
                </Modal>

                <Modal show={editModal} size="lg">
                  <Modal.Header>
                    <Modal.Title className="pt-1 font-16">
                      <h6>Edit Frequently Asked Questions</h6>
                    </Modal.Title>
                    <button
                      type="button"
                      onClick={() => setEditModal(false)}
                      className="close p-0 bl-modal-close-btn closable"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <i className="fal fa-times closable"></i>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <EditSupportQuestions
                      close={() => setEditModal(false)}
                      fetchFAQ_API={fetchFAQ_API}
                      FAQData={FAQData}
                    />
                  </Modal.Body>
                </Modal>

                <Modal show={false} size="lg">
                  <Modal.Header closeButton>
                    <h6 class="">
                      Add {getUserRole() === "SuperAdmin" ? "Template" : "Job"}
                    </h6>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setTemplateModal(false)}
                      class="close p-0 bl-modal-close-btn"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <AddJobTemplate
                      templates={templates}
                      closePanel={() => setTemplateModal(false)}
                      fetchTemplates={fetchTemplates}
                    />
                  </Modal.Body>
                  {false && (
                    <Modal.Footer>
                      <div className="row align-items-center">
                        <div className="col-lg-12 my-2 mb-lg-0">
                          <div className="form-check pl-0 form-switch d-flex align-items-center">
                            <label className="form-check-label pt-0">
                              <strong>Save as Template?</strong>
                            </label>
                            <input
                              className="form-check-input ml-3"
                              type="checkbox"
                            />
                          </div>
                        </div>
                      </div>
                      <Button
                        variant="secondary"
                        onClick={() => setTemplateModal(false)}
                      >
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => setTemplateModal(false)}
                      >
                        Save Changes
                      </Button>
                    </Modal.Footer>
                  )}
                </Modal>

                <Modal show={templateModal || editTemplate} size="lg">
                  <Modal.Header>
                    <h6 class="">{editTemplate ? "Edit" : "Add"} Template</h6>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() =>
                        editTemplate
                          ? setEditTemplate(false)
                          : setTemplateModal(false)
                      }
                      class="close p-0 bl-modal-close-btn"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    <MainAddJob
                      addTemplate={templateModal}
                      editTemplate={editTemplate}
                      closePanel={() =>
                        editTemplate
                          ? setEditTemplate(false)
                          : setTemplateModal(false)
                      }
                      fetchTemplate={fetchTemplates}
                      tempDetails={templateDetails}
                    />
                  </Modal.Body>
                </Modal>

                {sidePanel && templateDetails && (
                  <TemplateSidePanel
                    setLoading={setLoading}
                    fetchTemplates={fetchTemplates}
                    setEditTemplate={setEditTemplate}
                    closePanel={() => setSidePanel(false)}
                    details={templateDetails}
                  />
                )}

                <Modal show={false} size="lg">
                  <Modal.Header closeButton>
                    <h6 class="">
                      Edit {getUserRole() === "SuperAdmin" ? "Template" : "Job"}
                    </h6>
                    <button
                      type="button"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => setEditTemplate(false)}
                      class="close p-0 bl-modal-close-btn"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </Modal.Header>
                  <Modal.Body>
                    {/* <EditJobTemplate
            templates={templates}
            closePanel={() => setEditTemplate(false)}
            tempDetails={templateDetails}
            fetchTemplates={fetchTemplates}
          /> */}
                    <MainAddJob
                      addTemplate={templateModal}
                      editTemplate={editTemplate}
                      templates={templates}
                      closePanel={() =>
                        editTemplate
                          ? setEditTemplate(false)
                          : setTemplateModal(false)
                      }
                      fetchTemplate={fetchTemplates}
                      tempDetails={templateDetails}
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
          </div>
        </div>
        {getTenantType() == 1 && <Footer />}
      </div>
    </body>
  );
}

export default FAQSetup;
