import React from "react";
import Avatar from "react-avatar";

const StatusData = (props) => {
  const { job } = props;
  const statusData = job.statusData.filter(
    (x) => x.status * 1 != 33 && x.status * 1 != 38
  );
  return (
    <div className="d-flex flex-row-reverse justify-content-end mx-2">
      {statusData?.length > 1 && (
        <span className="avatar avatar-bordered ml-neg-10">
          {" "}
          {`${statusData?.slice(1, 100)?.length}+`}{" "}
        </span>
      )}
      <span>
        {statusData?.slice(0, 1)?.map((item, i) => (
          <span key={i}>
            <Avatar
              size="45"
              name={item?.firstName + " " + item?.lastName}
              round="80px"
            />
          </span>
        ))}
      </span>
    </div>
  );
};

export default StatusData;
