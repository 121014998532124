import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

const FullViewEmail = (props) => {
  const { openFullViewEmail, setOpenFullViewEmail, selectedMail } = props;

  const getName = (name) => {
    return name && JSON.parse(name)[0];
  };

  return (
    <div className="menu-container">
      <Modal
        show={openFullViewEmail}
        size="lg"
        className="modal fade"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-header" id="EditQuestionModal">
          <h6 className="modal-title" id="EditQuestionModalLabel">
            Email Request
          </h6>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setOpenFullViewEmail(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div className="card-body p-4 disabled-container">
            <h6>To : {getName(selectedMail?.MailTo)}</h6>
            <h6>Subject : {selectedMail?.MailSubject}</h6>

            <div
              className="mt-4 "
              dangerouslySetInnerHTML={{
                __html: selectedMail?.MailContent,
              }}
            ></div>
          </div>
          <div className="modal-footer mt-4 mb-0">
            <button
              type="button"
              className="btn btn-sm btn-secondary mr-2"
              data-dismiss="modal"
              onClick={(e) => {
                setOpenFullViewEmail(false);
              }}
            >
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default FullViewEmail;
