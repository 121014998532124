import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { skillOptions } from "../../../constants/Constants";
import { getUserName } from "../../../service/AuthService";
import { post } from "../../../service/ApiService";
// import GoogleLocationCityAPI from "../location/GoogleLocationCityAPI";

const RecruitmentDetails = (props) => {
  const { setCategoryLoading, dropdownData } = props;
  const { setLoading } = props;
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [data, setData] = useState({
    yearsOfExperience: "",
    jobTitle: { title1: "", title2: "", title3: "" },
    skills: null,
    industries: null,
    subCategory: null,
    regions: null,
    languages: null,
    education: "",
  });
  const [validation, setValidation] = useState({
    yearsOfExperience: "",
    jobTitle: "",
    skills: "",
    industries: "",
    subCategory: "",
    regions: "",
    languages: "",
    education: "",
    certification: "",
  });
  const [count, setCount] = useState(true);
  const [skillsOptions, setSkillsOption] = useState(skillOptions);
  const [dataTemp, setDataTemp] = useState([]);
  const [testId, setTestId] = useState([]);
  const [industriesOptions, setIndustriesOptions] = useState([]);
  const [industriesOptionsAll, setIndustriesOptionsAll] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [regionOption, setRegionOption] = useState([]);
  const [experienceOptions, setExperienceOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCategoryOptionsAll, setSubCategoryOptionsAll] = useState([]);
  const [educationOptions, setEducationOptions] = useState([]);
  const [certificationOptions, setCertificationOptions] = useState([]);
  useEffect(() => {
    if (dropdownData.length > 0) {
      let industriesData = [];
      let regionData = [];
      let languageData = [];
      let experienceData = [];
      let educationData = [];
      let certificationData = [];

      dropdownData.forEach((element) => {
        if (element.typeName == "skillSet") {
          industriesData = [
            ...industriesData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "languages") {
          languageData = [
            ...languageData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "regions") {
          regionData = [
            ...regionData,
            {
              value: element.name,
              label: element.name,
              disabled: false,
            },
          ];
        }
        if (element.typeName == "experienceYear") {
          experienceData = [
            ...experienceData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "qualification") {
          educationData = [
            ...educationData,
            {
              value: element.name,
              label: element.name,
              disabled: false,
            },
          ];
        }
        if (element.typeName == "certifications") {
          certificationData = [
            ...certificationData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
      });
      if (props.data.regions) {
        const regions = JSON.parse(props.data.regions);
        if (regions.includes("Global - GL")) {
          let temp = regionData.map((item) => {
            item.disabled = true;
            return item;
          });
          setRegionOption(temp);
        } else if (regions.length == 0) {
          let temp = regionData.map((item) => {
            item.disabled = false;
            return item;
          });
          setRegionOption(temp);
        } else {
          let temp = regionData.map((item) => {
            if (item.value == "Global - GL") {
              item.disabled = true;
            } else {
              item.disabled = false;
            }
            return item;
          });
          setRegionOption(temp);
        }
      } else {
        setRegionOption(regionData);
      }
      setIndustriesOptionsAll([
        ...industriesData,
        {
          value: "All",
          label: "All",
        },
      ]);
      setIndustriesOptions([
        ...industriesData,
        {
          value: "All",
          label: "All",
        },
      ]);
      setLanguageOptions(languageData);
      setExperienceOptions(experienceData);
      setEducationOptions(educationData);
      setCertificationOptions(certificationData);
    }
  }, [dropdownData, props.data]);

  useEffect(async () => {
    if (dropdownData.length > 0 && data.industries && count) {
      let industries = data.industries;
      const result = industries.map((name) => {
        const matchedObject = dropdownData.find((obj) => obj.name === name);
        return matchedObject ? matchedObject.id : null;
      });
      setCategoryLoading(true);
      const payload = {
        industry: result,
      };
      try {
        const res = await post("/common/subcategory", payload);
        setCount(false);
        setCategoryLoading(false);
        if (res.status == 200) {
          let subCategoryData = [
            {
              value: "All",
              label: "All",
            },
          ];
          res.data.map((item) => {
            subCategoryData = [
              ...subCategoryData,
              {
                value: item.categoryName,
                label: item.categoryName,
              },
            ];
          });
          setSubCategoryOptions(subCategoryData);
          setSubCategoryOptionsAll(subCategoryData);
        } else {
          toast.error("Oops! something went wrong. please try again");
        }
      } catch (error) {
        toast.error("Oops! something went wrong. please try again");
        setCategoryLoading(false);
      }
    }
  }, [dropdownData, data]);

  useEffect(() => {
    if (props.data) {
      const temp = {
        industries: props.data.industries
          ? JSON.parse(props.data.industries)
          : null,
        subCategory: props.data.subCategory
          ? JSON.parse(props.data.subCategory)
          : null,
        regions: props.data.regions ? JSON.parse(props.data.regions) : null,
        languages: props.data.languages
          ? JSON.parse(props.data.languages)
          : null,
        yearsOfExperience: props.data.yearsOfExperience,
        skills: props.data.skills ? JSON.parse(props.data.skills) : null,
        jobTitle: props.data.jobTitle ? JSON.parse(props.data.jobTitle) : null,
        education: props.data.education,
        certification: props.data.certification
          ? JSON.parse(props.data.certification)
          : null,
      };
      setDataTemp(temp);
      setData(temp);
    }
    if (
      props.data.skills &&
      skillOptions.length - 1 == JSON.parse(props.data.skills).length
    ) {
      setSkillsOption([]);
    }
  }, [props.data]);

  const editRecruitementDetails = () => {
    setReadOnlyMode(false);
  };

  const cancelRecruitementDetails = () => {
    setReadOnlyMode(true);
    setSkillsOption(skillOptions);
    setData({ ...data, ...dataTemp });
    setValidation({
      yearsOfExperience: "",
      jobTitle: "",
      skills: "",
      industries: "",
      regions: "",
      languages: "",
      education: "",
      certification: "",
    });
  };

  const validationFn = (data) => {
    let industriesError = "";
    let subCategoryError = "";
    let regionsError = "";
    let languagesError = "";
    let skillsError = "";
    let yearsOfExperienceError = "";
    let jobTitleError = "";
    let educationError = "";
    let certificationError = "";

    if (!data.industries || data.industries.length == 0) {
      industriesError = "*Required field";
    }
    if (!data.subCategory || data.subCategory.length == 0) {
      subCategoryError = "*Required field";
    }
    if (!data.regions || data.regions.length == 0) {
      regionsError = "*Required field";
    }
    if (!data.languages || data.languages.length == 0) {
      languagesError = "*Required field";
    }
    if (!data.skills || data.skills.length == 0) {
      skillsError = "*Required field";
    }
    if (!data.yearsOfExperience) {
      yearsOfExperienceError = "*Required field";
    }
    if (!data.jobTitle.title1) {
      jobTitleError = "*Required field";
    }
    if (!data.education) {
      educationError = "*Required field";
    }
    if (!data.certification || data.certification.length == 0) {
      certificationError = "*Required field";
    }
    if (
      industriesError ||
      subCategoryError ||
      regionsError ||
      languagesError ||
      skillsError ||
      yearsOfExperienceError ||
      jobTitleError ||
      educationError ||
      certificationError
    ) {
      setValidation({
        yearsOfExperience: yearsOfExperienceError,
        jobTitle: jobTitleError,
        skills: skillsError,
        industries: industriesError,
        subCategory: subCategoryError,
        regions: regionsError,
        languages: languagesError,
        education: educationError,
        certification: certificationError,
      });
      return true;
    } else {
      setValidation({
        yearsOfExperience: "",
        jobTitle: "",
        skills: "",
        industries: "",
        regions: "",
        languages: "",
        education: "",
        certification: "",
      });
      return false;
    }
  };

  const saveRecruitementDetails = async () => {
    if (validationFn(data)) {
      toast.error("Please fill all the mandatory field");
    } else {
      try {
        setLoading(true);
        const paramters = {
          industries: JSON.stringify(data.industries),
          subCategory: JSON.stringify(data.subCategory),
          regions: JSON.stringify(data.regions),
          languages: JSON.stringify(data.languages),
          yearsOfExperience: data.yearsOfExperience,
          skills: JSON.stringify(data.skills),
          jobTitle: JSON.stringify(data.jobTitle),
          email: getUserName(),
          education: data.education,
          certification: JSON.stringify(data.certification),
        };
        const res = await post("/common/recruitementdetails/save", paramters);
        setLoading(false);
        if (res.status === 200) {
          setReadOnlyMode(true);
          setDataTemp({
            industries: data.industries,
            subCategory: data.subCategory,
            regions: data.regions,
            languages: data.languages,
            yearsOfExperience: data.yearsOfExperience,
            skills: data.skills,
            jobTitle: data.jobTitle,
            education: data.education,
            certification: data.certification,
          });
          toast.success("Recruitement details has been  successfully updated.");
        } else {
          toast.error("Oops! something went wrong. please try again later");
        }
      } catch (error) {
        setLoading(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    }
  };

  const handleChangeMultiSelect = (id, value) => {
    if (id === "yearsOfExperience" && validation.experience) {
      setValidation({ ...validation, experience: "" });
    }
    if (id === "industry" && validation.industry) {
      setValidation({ ...validation, industry: "" });
    }
    if (id === "skills") {
      if (value.includes("All")) {
        setData({
          ...data,
          skills: skillOptions
            .filter((item) => item.value !== "All")
            .map((i) => i.label),
        });
        setSkillsOption([]);
      } else {
        setData({ ...data, [id]: value });
        setSkillsOption(
          skillOptions.filter((item) => !value.includes(item.value))
        );
        setData({ ...data, [id]: value });
      }
    } else if (id === "regions") {
      if (value.includes("Global - GL")) {
        setData({ ...data, [id]: ["Global - GL"] });
        let temp = regionOption.map((item) => {
          item.disabled = true;
          return item;
        });
        setRegionOption(temp);
      } else if (value.length == 0) {
        setData({ ...data, [id]: value });
        let temp = regionOption.map((item) => {
          item.disabled = false;
          return item;
        });
        setRegionOption(temp);
      } else {
        setData({ ...data, [id]: value });
        let temp = regionOption.map((item) => {
          if (item.value == "Global - GL") {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
        setRegionOption(temp);
      }
    } else if (id === "subCategory") {
      if (value.includes("All")) {
        setData({
          ...data,
          subCategory: subCategoryOptionsAll
            .filter((item) => item.value !== "All")
            .map((i) => i.label),
        });
        setSubCategoryOptions([]);
      } else {
        setSubCategoryOptions(
          subCategoryOptionsAll.filter((item) => !value.includes(item.value))
        );
        setData({ ...data, [id]: value });
      }
    } else {
      setData({ ...data, [id]: value });
    }
  };

  const removeItemFromArray = (item) => {
    let tempArray = [...testId];
    const index = tempArray.indexOf(item);
    tempArray.splice(index, 1);
    setTestId(tempArray);
  };

  const handleChangeIndustry = async (val) => {
    if (val.length > 0) {
      let industry = [];
      if (val.includes("All")) {
        industry = dropdownData.filter((item) => item.typeName == "skillSet");
        industry = industry.map((item) => item.id);
        setIndustriesOptions([]);
      } else {
        industry = dropdownData.filter(
          (item) => val.includes(item.name) && item.typeName == "skillSet"
        );
        industry = industry.map((item) => item.id);
        setIndustriesOptions(
          industriesOptionsAll.filter((item) => !val.includes(item.value))
        );
        setData({ ...data, industries: val, subCategory: [] });
      }
      const payload = {
        industry,
      };
      try {
        setCategoryLoading(true);
        const res = await post("/common/subcategory", payload);
        setCategoryLoading(false);
        if (res.status == 200) {
          let subCategoryData = [
            {
              value: "All",
              label: "All",
            },
          ];
          res.data.map((item) => {
            subCategoryData = [
              ...subCategoryData,
              {
                value: item.categoryName,
                label: item.categoryName,
              },
            ];
          });
          setSubCategoryOptions(subCategoryData);
          setSubCategoryOptionsAll(subCategoryData);
          if (val.includes("All")) {
            setData({
              ...data,
              industries: industriesOptionsAll
                .filter((item) => item.value !== "All")
                .map((i) => i.label),
              subCategory: res.data.map((item) => item.categoryName),
            });
          }
        } else {
          toast.error("Oops! something went wrong. please try again");
        }
      } catch (error) {
        toast.error("Oops! something went wrong. please try again");
        setCategoryLoading(false);
      }
    } else {
      setIndustriesOptions(industriesOptionsAll);
      setSubCategoryOptions([]);
      setSubCategoryOptionsAll([]);
      setData({ ...data, subCategory: val, industries: val });
    }
  };

  return (
    <>
      <div>
        <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
          <div className="card card-lg">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">Recruitment Details</h6>
                {readOnlyMode ? (
                  <button
                    type="button"
                    v-if="form.contact.readOnly"
                    className="btn btn-text"
                    onClick={editRecruitementDetails}
                  >
                    <i className="fas fa-pencil-alt mr-1"></i>
                    Edit
                  </button>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={cancelRecruitementDetails}
                      className="btn btn-sm btn-secondary mr-3"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className="btn btn-sm btn-primary"
                      onClick={saveRecruitementDetails}
                    >
                      <span>Save Changes</span>
                    </button>
                  </div>
                )}
              </div>
              <hr />
              <div className="card-body">
                <div className="form-floating">
                  <Container>
                    <Row>
                      <Col>
                        <label className="recruitemantLabel">Job Title*</label>
                        {readOnlyMode ? (
                          <div className="pt-2 px-2">
                            <span>
                              {data.jobTitle ? data.jobTitle.title1 : ""}
                            </span>
                          </div>
                        ) : (
                          <>
                            <div className="form-floating-sm">
                              <input
                                type="text"
                                className="form-control border"
                                value={
                                  data.jobTitle ? data.jobTitle.title1 : ""
                                }
                                onChange={(e) => {
                                  validation.title &&
                                    setValidation({ ...validation, title: "" });
                                  setData({
                                    ...data,
                                    jobTitle: {
                                      ...data.jobTitle,
                                      title1: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                            {validation.jobTitle && (
                              <small className="validation ml-2">
                                {validation.jobTitle}
                              </small>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="recruitemantLabel">Job Title 2</label>
                        {readOnlyMode ? (
                          <div className="pt-2 px-2">
                            <span>
                              {data?.jobTitle?.title2
                                ? data.jobTitle.title2
                                : "NA"}
                            </span>
                          </div>
                        ) : (
                          <>
                            <div className="form-floating-sm">
                              <input
                                type="text"
                                className="form-control border"
                                value={
                                  data?.jobTitle?.title2
                                    ? data.jobTitle.title2
                                    : ""
                                }
                                onChange={(e) => {
                                  validation.title &&
                                    setValidation({
                                      ...validation,
                                      title: "",
                                    });
                                  setData({
                                    ...data,
                                    jobTitle: {
                                      ...data.jobTitle,
                                      title2: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </>
                        )}
                      </Col>
                      <Col>
                        <label className="recruitemantLabel">Job Title 3</label>
                        {readOnlyMode ? (
                          <div className="pt-2 px-2">
                            <span>
                              {data?.jobTitle?.title3
                                ? data.jobTitle.title3
                                : "NA"}
                            </span>
                          </div>
                        ) : (
                          <>
                            <div className="form-floating-sm">
                              <input
                                type="text"
                                className="form-control border"
                                value={
                                  data?.jobTitle?.title3
                                    ? data.jobTitle.title3
                                    : ""
                                }
                                onChange={(e) => {
                                  validation.title &&
                                    setValidation({
                                      ...validation,
                                      title: "",
                                    });
                                  setData({
                                    ...data,
                                    jobTitle: {
                                      ...data.jobTitle,
                                      title3: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="recruitemantLabel">Roles*</label>
                        {readOnlyMode ? (
                          <div className="pt-2 px-2">
                            {data.skills &&
                              data.skills.map(
                                (item, index) =>
                                  (index < 2 || testId.includes("skills")) && (
                                    <span className="tag tag-blue3 mb-1 mr-1 pt-1 px-2">
                                      {item}
                                    </span>
                                  )
                              )}
                            {data.skills &&
                              (!testId.includes("skills") ? (
                                data?.skills?.length > 2 && (
                                  <div
                                    className="tag"
                                    onClick={() =>
                                      setTestId([...testId, "skills"])
                                    }
                                    style={{ margin: "2px", cursor: "pointer" }}
                                  >
                                    +{data?.skills?.length - 2} more
                                  </div>
                                )
                              ) : (
                                <div
                                  className="tag"
                                  onClick={() => removeItemFromArray("skills")}
                                  style={{ margin: "2px", cursor: "pointer" }}
                                >
                                  Show less
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div
                            className="form-floating hover-Dropdown"
                            style={{ zIndex: "1000" }}
                          >
                            <Select
                              isMulti
                              options={skillsOptions}
                              value={
                                data.skills
                                  ? skillOptions.length - 1 ==
                                    data.skills.length
                                    ? [{ label: "All", value: "All" }]
                                    : skillOptions.filter((f) =>
                                        data.skills.includes(f.value)
                                      )
                                  : []
                              }
                              name="placementSkill"
                              isSearchable={true}
                              className={
                                validation.skills && "border border-danger"
                              }
                              onChange={(value) =>
                                handleChangeMultiSelect(
                                  "skills",
                                  value?.map((i) => i.value)
                                )
                              }
                            ></Select>
                            <small className="validation ml-2">
                              {validation.skills}
                            </small>
                          </div>
                        )}
                      </Col>
                      <Col>
                        <label className="recruitemantLabel">
                          Years of experience*
                        </label>
                        {readOnlyMode ? (
                          <div className="pt-2 px-2">
                            <span>{data.yearsOfExperience}</span>
                          </div>
                        ) : (
                          <div
                            className="form-floating Hover-Dropdown"
                            style={{ zIndex: "1000" }}
                          >
                            <Select
                              options={experienceOptions}
                              value={
                                data.yearsOfExperience
                                  ? experienceOptions.filter(
                                      (f) => f.value == data.yearsOfExperience
                                    )
                                  : []
                              }
                              isDisabled={readOnlyMode}
                              name="Experience"
                              className={
                                validation.experience && "border border-danger"
                              }
                              isSearchable={true}
                              onChange={(val) =>
                                handleChangeMultiSelect(
                                  "yearsOfExperience",
                                  val.value
                                )
                              }
                            ></Select>
                            <small className="validation ml-2">
                              {validation.yearsOfExperience}
                            </small>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="recruitemantLabel">
                          Job Category*
                        </label>
                        {readOnlyMode ? (
                          <div className="pt-2 px-2">
                            {data.industries &&
                              data.industries.map(
                                (item, index) =>
                                  (index < 2 ||
                                    testId.includes("industries")) && (
                                    <span className="tag tag-blue3 mb-1 mr-1 pt-1 px-2">
                                      {item}
                                    </span>
                                  )
                              )}
                            {data.industries &&
                              (!testId.includes("industries") ? (
                                data?.industries?.length > 2 && (
                                  <div
                                    className="tag"
                                    onClick={() =>
                                      setTestId([...testId, "industries"])
                                    }
                                    style={{ margin: "2px", cursor: "pointer" }}
                                  >
                                    +{data?.industries?.length - 2} more
                                  </div>
                                )
                              ) : (
                                <div
                                  className="tag"
                                  onClick={() =>
                                    removeItemFromArray("industries")
                                  }
                                  style={{ margin: "2px", cursor: "pointer" }}
                                >
                                  Show less
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div
                            className="form-floating hover-Dropdown"
                            style={{ zIndex: "999" }}
                          >
                            <Select
                              isMulti
                              options={industriesOptions}
                              value={
                                data.industries
                                  ? industriesOptionsAll.length - 1 ==
                                    data.industries.length
                                    ? [{ label: "All", value: "All" }]
                                    : industriesOptionsAll.filter((f) =>
                                        data.industries.includes(f.value)
                                      )
                                  : []
                              }
                              name="Industry"
                              isSearchable={true}
                              className={
                                validation.industries && "border border-danger"
                              }
                              onChange={(value) =>
                                handleChangeIndustry(value?.map((i) => i.value))
                              }
                            ></Select>
                            <small className="validation ml-2">
                              {validation.industries}
                            </small>
                          </div>
                        )}
                      </Col>
                      <Col>
                        <label className="recruitemantLabel">
                          Sub category*
                        </label>
                        {readOnlyMode ? (
                          <div className="pt-2 px-2">
                            {data.subCategory &&
                              data.subCategory.map(
                                (item, index) =>
                                  (index < 2 ||
                                    testId.includes("subCategory")) && (
                                    <span className="tag tag-blue3 mb-1 mr-1 pt-1 px-2">
                                      {item}
                                    </span>
                                  )
                              )}
                            {data.subCategory &&
                              (!testId.includes("subCategory") ? (
                                data?.subCategory?.length > 2 && (
                                  <div
                                    className="tag"
                                    onClick={() =>
                                      setTestId([...testId, "subCategory"])
                                    }
                                    style={{ margin: "2px", cursor: "pointer" }}
                                  >
                                    +{data?.subCategory?.length - 2} more
                                  </div>
                                )
                              ) : (
                                <div
                                  className="tag"
                                  onClick={() =>
                                    removeItemFromArray("subCategory")
                                  }
                                  style={{ margin: "2px", cursor: "pointer" }}
                                >
                                  Show less
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div
                            className="form-floating hover-Dropdown"
                            style={{ zIndex: "999" }}
                          >
                            <Select
                              isMulti
                              options={subCategoryOptions.sort(
                                (a, b) => b.label - a.label
                              )}
                              value={
                                data.subCategory
                                  ? subCategoryOptionsAll.length - 1 ==
                                    data.subCategory.length
                                    ? [{ label: "All", value: "All" }]
                                    : subCategoryOptionsAll.filter((f) =>
                                        data.subCategory.includes(f.value)
                                      )
                                  : []
                              }
                              name="subCategory"
                              isSearchable={true}
                              className={
                                validation.subCategory && "border border-danger"
                              }
                              onChange={(value) =>
                                handleChangeMultiSelect(
                                  "subCategory",
                                  value?.map((i) => i.value)
                                )
                              }
                              isDisabled={
                                data.industries.length == 0 ||
                                industriesOptionsAll.length - 1 ==
                                  data.industries.length
                              }
                            ></Select>
                            <small className="validation ml-2">
                              {validation.subCategory}
                            </small>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <label className="recruitemantLabel">Language*</label>
                        {readOnlyMode ? (
                          <div className="pt-2 px-2">
                            {data.languages &&
                              data.languages.map(
                                (item, index) =>
                                  (index < 2 ||
                                    testId.includes("languages")) && (
                                    <span className="tag tag-blue3 mb-1 mr-1 pt-1 px-2">
                                      {item}
                                    </span>
                                  )
                              )}
                            {data.languages &&
                              (!testId.includes("languages") ? (
                                data?.languages?.length > 2 && (
                                  <div
                                    className="tag"
                                    onClick={() =>
                                      setTestId([...testId, "languages"])
                                    }
                                    style={{ margin: "2px", cursor: "pointer" }}
                                  >
                                    +{data?.languages?.length - 2} more
                                  </div>
                                )
                              ) : (
                                <div
                                  className="tag"
                                  onClick={() =>
                                    removeItemFromArray("languages")
                                  }
                                  style={{ margin: "2px", cursor: "pointer" }}
                                >
                                  Show less
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div className="form-floating Hover-Dropdown">
                            <Select
                              isMulti
                              options={languageOptions}
                              value={
                                data.languages
                                  ? languageOptions.filter((f) =>
                                      data.languages.includes(f.value)
                                    )
                                  : []
                              }
                              name="LanguageSkill"
                              isSearchable={true}
                              className={
                                validation.languages && "border border-danger"
                              }
                              onChange={(value) =>
                                handleChangeMultiSelect(
                                  "languages",
                                  value?.map((i) => i.value)
                                )
                              }
                            ></Select>
                            <small className="validation ml-2">
                              {validation.languages}
                            </small>
                          </div>
                        )}
                      </Col>
                      <Col>
                        <label className="recruitemantLabel">Regions*</label>
                        {readOnlyMode ? (
                          <div className="pt-2 px-2">
                            {data.regions &&
                              data.regions.map(
                                (item, index) =>
                                  (index < 2 || testId.includes("regions")) && (
                                    <span className="tag tag-blue3 mb-1 mr-1 pt-1 px-2">
                                      {item}
                                    </span>
                                  )
                              )}
                            {data.regions &&
                              (!testId.includes("regions") ? (
                                data?.regions?.length > 2 && (
                                  <div
                                    className="tag"
                                    onClick={() =>
                                      setTestId([...testId, "regions"])
                                    }
                                    style={{ margin: "2px", cursor: "pointer" }}
                                  >
                                    +{data?.regions?.length - 2} more
                                  </div>
                                )
                              ) : (
                                <div
                                  className="tag"
                                  onClick={() => removeItemFromArray("regions")}
                                  style={{ margin: "2px", cursor: "pointer" }}
                                >
                                  Show less
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div className="form-floating Hover-Dropdown">
                            <Select
                              isMulti
                              options={regionOption}
                              isOptionDisabled={(regionOption) =>
                                regionOption.disabled
                              }
                              value={
                                data.regions
                                  ? regionOption.filter((f) =>
                                      data.regions.includes(f.value)
                                    )
                                  : []
                              }
                              name="regionsRecruited"
                              isSearchable={true}
                              className={
                                validation.regions && "border border-danger"
                              }
                              onChange={(value) =>
                                handleChangeMultiSelect(
                                  "regions",
                                  value?.map((i) => i.value)
                                )
                              }
                            ></Select>
                            <small className="validation ml-2">
                              {validation.regions}
                            </small>
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <label className="recruitemantLabel">Education*</label>
                        {readOnlyMode ? (
                          <div className="pt-2 px-2">
                            <span>{data.education}</span>
                          </div>
                        ) : (
                          <div className="form-floating Hover-Dropdown">
                            <Select
                              options={educationOptions}
                              value={
                                data.education
                                  ? educationOptions.filter(
                                      (f) => f.value == data.education
                                    )
                                  : []
                              }
                              name="Education"
                              isSearchable={true}
                              className={
                                validation.education && "border border-danger"
                              }
                              onChange={(val) =>
                                handleChangeMultiSelect("education", val.value)
                              }
                            ></Select>
                            <small className="validation ml-2">
                              {validation.education}
                            </small>
                          </div>
                        )}
                      </Col>
                      <Col>
                        <label className="recruitemantLabel">
                          Certification*
                        </label>
                        {readOnlyMode ? (
                          <div className="pt-2 px-2">
                            {data.certification &&
                              data.certification.map(
                                (item, index) =>
                                  (index < 2 ||
                                    testId.includes("certification")) && (
                                    <span className="tag tag-blue3 mb-1 mr-1 pt-1 px-2">
                                      {item}
                                    </span>
                                  )
                              )}
                            {data.certification &&
                              (!testId.includes("certification") ? (
                                data?.certification?.length > 2 && (
                                  <div
                                    className="tag"
                                    onClick={() =>
                                      setTestId([...testId, "certification"])
                                    }
                                    style={{ margin: "2px", cursor: "pointer" }}
                                  >
                                    +{data?.certification?.length - 2} more
                                  </div>
                                )
                              ) : (
                                <div
                                  className="tag"
                                  onClick={() =>
                                    removeItemFromArray("certification")
                                  }
                                  style={{ margin: "2px", cursor: "pointer" }}
                                >
                                  Show less
                                </div>
                              ))}
                          </div>
                        ) : (
                          <div className="form-floating Hover-Dropdown">
                            <Select
                              isMulti
                              options={certificationOptions}
                              value={
                                data.certification
                                  ? certificationOptions.filter((f) =>
                                      data.certification.includes(f.value)
                                    )
                                  : []
                              }
                              name="Certification"
                              isSearchable={true}
                              className={
                                validation.certification &&
                                "border border-danger"
                              }
                              onChange={(value) =>
                                handleChangeMultiSelect(
                                  "certification",
                                  value?.map((i) => i.value)
                                )
                              }
                            ></Select>
                            <small className="validation ml-2">
                              {validation.certification}
                            </small>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RecruitmentDetails;
