import React from "react";
import Pdf from 'react-to-pdf'
import logo from "../../../assets/images/High5Logo.png";

const ref = React.createRef();

function High5Certificate(props) {
    console.log(props,"as")
  
    return (
        <body>
            <div ref={ref}>
            <div className="container pm-certificate-container" >
                <div className="outer-border"></div>
                <div className="inner-border"></div>
                <div className="pm-certificate-border col-lg-12 certificateBackground">
                    <div className="row pm-certificate-header ">
                        <div className="pm-certificate-title cursive col-lg-12 text-center sans">
                            <h2>Certificate of Completion</h2>
                        </div>
                    </div>
                    <div className="row pm-certificate-body">
                        <div className="pm-certificate-block">
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="pm-certificate-name underline margin-0 col-lg-8 text-center">
                                        <span className="pm-name-text bold">{props.candidateData.fullText.firstName} {" "} {props.candidateData.fullText.lastName}</span>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="pm-earned col-lg-8 text-center">
                                        <span className="pm-earned-text padding-0 block cursive">
                                            has earned
                                        </span>
                                    </div>
                                    <div className="col-lg-2"></div>
                                    <div className="col-lg-12"></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="pm-course-title col-lg-8 text-center">
                                        <span className="pm-earned-text completion block cursive">
                                            this cerificate for successfully completing
                                        </span>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                            <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-2"></div>
                                    <div className="pm-course-title underline col-lg-8 text-center">
                                        <span className="pm-credits-text block bold">{props.certificate.title}</span>
                                    </div>
                                    <div className="col-lg-2"></div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-12">
                            <div className="row">
                                <div className="pm-certificate-footer">
                                    <div className="col-lg-4 pm-certified col-lg-4 text-center">
                                        <span className="pm-credits-text block">Date Issued</span>
                                        <span className="bold block date">{new Date(props.certificate.recordedTime).toDateString().split(" ").slice(1).join(" ")}</span>
                                        <span className="pm-empty-space block underline"></span>
                                    </div>
                                    <img src={logo} className="high5logo" />
                                    <div className="col-lg-4 pm-certified col-lg-4 text-center">
                                        <span className="pm-credits-text block">Issued By</span>
                                        <span className="bold block date">High5Hire</span>
                                        <span style={{ fontSize: "10px", fontWeight: "800" }}>
                                            www.high5hire.com
                                        </span>
                                        <span className="pm-empty-space block underline"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div className="bl-modal-footer text-center">
            <Pdf targetRef={ref} filename="Certificate.pdf">
                {({ toPdf }) => <button type="button" className="btn btn-sm btn-gray mb-3 mt-3 ml-3" onClick={toPdf}>Download Certificate</button>}
             </Pdf>
             </div>
        </body>
    );
};

export default High5Certificate;