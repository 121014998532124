import moment from "moment";
import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { CSVLink } from "react-csv";
import OutsideClickHandler from "react-outside-click-handler";
import Sort from "../common/Sort";
import { dropDownData } from "../directsourcing/Edit Talent DS/dropDownData";
import GigRecSidePanel from "./GigRecSidePanel";
import RegisteredRecFilter from "./RegisteredRecFilter";

const RegisteredRecruiters = ({
  registeredRecruiter,
  pageLimit,
  dataLimit,
  onChangeSelect,

  status,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [userId, setUserId] = useState({
    id: "",
    l: false,
    s: false,
    i: false,
    r: false,
  });
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [sidePanel, setSidePanel] = useState(false);
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [country, setCountry] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [viewAllSkill, setViewAllSkill] = useState(false);
  const [recruiter, setRecruiters] = useState(registeredRecruiter);
  const [yearsRange, setYearsRange] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);

  console.log("registeredRecruiter", registeredRecruiter);

  const [filterBy, setFilterBy] = useState(filteredData);
  const [csvData, setCSVData] = useState([
    "Name",
    "Email",
    "UserType",
    "Source",
    "Location",
    "LastLoggedIn",
    "Status",
  ]);

  const sortObject = [
    {
      id: 1,
      label: "Name",
      value: "firstName",
      icon: "fal fa-fw fa-signature  mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Email",
      value: "email",
      icon: "fal fa-fw fa-signature mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Experience",
      value: "expYears",
      icon: "fal fa-fw fa-calendar-alt mr-2 dd-sort",
    },
    // {
    //   id: 4,
    //   label: "Industry",
    //   value: "industry",
    //   icon: "fal fa-fw fa-signature mr-2 dd-sort",
    // },
    {
      id: 5,
      label: "Added on",
      value: "createddatetime",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  const applySort = (sortedData, close) => {
    close && setSort(false);
    setFilterBy(sortedData);
    setFilteredData(sortedData);
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(filteredData, true);
  };

  useEffect(() => {
    console.log("userId", userId);
  }, [userId]);

  const openFilter = () => {
    setFilter(true);
  };

  const applyFilter = (
    data,
    selectedName,
    selectedUserType,
    selectedSource,
    selectedStatus,
    selectedCountry,
    selectedLanguages,
    selectedIndustries
  ) => {
    sortClearAll();
    setFilter(false);
    setSelectedName(selectedName);
    setSelectedUserType(selectedUserType);
    setSelectedSource(selectedSource);
    setSelectedStatus(selectedStatus);
    setSelectedCountry(selectedCountry);
    setSelectedLanguages(selectedLanguages);
    setSelectedIndustries(selectedIndustries);
    setFilteredData(data);
    setFilterBy(data);
  };

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  // const splitMatchedText = (str) => {
  //   const regExp = new RegExp(searchValue, "gi");
  //   return str.replace(
  //     regExp,
  //     (match) => `<span style='color: #FF5F57'>${match}</span>`
  //   );
  // };

  const openSidePanel = async (item) => {
    console.log("item", item);
    setDetails(item);
    setSidePanel(true);
  };

  const handleSearch = (value) => {
    let val = value?.trim().toLowerCase();
    setSearchValue(val);

    setSearchData(
      filteredData.filter((item) => {
        return (
          item.firstName.toLowerCase().includes(val) ||
          item.lastName.toLowerCase().includes(val) ||
          item.email.toLowerCase().includes(val) ||
          (item.skills !== null &&
            JSON.parse(item.skills).filter((item) =>
              item.toLowerCase().includes(val)
            ).length > 0) ||
          (item.firstName + " " + item.lastName).toLowerCase().includes(val)
        );
      })
    );
  };

  useEffect(() => {}, []);

  useEffect(() => {
    searchData.length > 0 && setRecruiters(searchData);
  }, [searchData]);

  useEffect(() => {
    if (filteredData.length > 0) {
      searchValue
        ? setPaginationArray(
            new Array(Math.ceil(searchData.length / dataLimit))
              .fill()
              .map((_, i) => i + 1)
          )
        : setPaginationArray(
            new Array(Math.ceil(filteredData.length / dataLimit))
              .fill()
              .map((_, i) => i + 1)
          );
      searchValue
        ? setPaginationGroup(
            new Array(Math.ceil(searchData.length / dataLimit))
              .fill()
              .map((_, i) => i + 1)
              .slice(0, pageLimit)
          )
        : setPaginationGroup(
            new Array(Math.ceil(filteredData.length / dataLimit))
              .fill()
              .map((_, i) => i + 1)
              .slice(0, pageLimit)
          );
      setCurrentPage(1);
    }
  }, [filteredData, dataLimit, searchData]);
  useEffect(() => {
    let data = registeredRecruiter.map((elem) => {
      return {
        ...elem,
        expYears:
          elem.yearsOfExperience == null
            ? 0
            : elem.yearsOfExperience[elem.yearsOfExperience.length - 1] === "+"
            ? Number(elem.yearsOfExperience.split("+")[0])
            : Number(elem.yearsOfExperience.split("-")[0]),
      };
    });
    setFilteredData(data);
    let brr = [];
    let abc = data.map((elem) => {
      elem.yearsOfExperience == null
        ? brr.push({ key: "0", value: elem.expYears })
        : brr.push({ key: elem.yearsOfExperience, value: elem.expYears });
    });
    let crr = [];
    brr.forEach((item) => {
      crr.filter((i) => i.value === item.value).length === 0 && crr.push(item);
    });
    setYearsRange(
      crr.sort((a, b) => {
        if (a.value < b.value) return -1;
        else if (a.value > b.value) return 1;
        else return 0;
      })
    );
    let indArr = data
      .map((i) => JSON.parse(i.industries))
      .filter((elem) => elem !== null && elem.length > 0);

    let allIndustries = [];

    indArr.forEach((elem) => {
      elem.forEach((item) => {
        !allIndustries.includes(item) && allIndustries.push(item);
      });
    });
    setIndustries(allIndustries);
    let skillArr = data
      .map((i) => JSON.parse(i.skills))
      .filter((elem) => elem !== null && elem.length > 0);

    let allSkills = [];

    skillArr.forEach((elem) => {
      elem.forEach((item) => {
        !allSkills.includes(item) && allSkills.push(item);
      });
    });
    setSkills(allSkills);

    let regionArr = data
      .map((i) => JSON.parse(i.regions))
      .filter((elem) => elem !== null && elem.length > 0);

    let allRegions = [];

    regionArr.forEach((elem) => {
      elem.forEach((item) => {
        !allRegions.includes(item) && allRegions.push(item);
      });
    });
    setCountry(allRegions);

    let langArr = data
      .map((i) => JSON.parse(i.languages))
      .filter((elem) => elem !== null && elem.length > 0);

    let allLang = [];

    langArr.forEach((elem) => {
      elem.forEach((item) => {
        !allLang.includes(item) && allLang.push(item);
      });
    });
    setLanguages(allLang);

    console.log("langArr", langArr, "allLang ", allLang);
  }, [registeredRecruiter]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    } else {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  let gigTabData = [];
  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "Experience", key: "experience" },
    { label: "Language", key: "language" },
    // { label: "Skills", key: "skills" },
    // { label: "Industry", key: "industry" },
    // { label: "Region", key: "region" },
    { label: "Added on", key: "addedOn" },
  ];

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : filteredData.slice(startIndex, endIndex);
    //return filteredData
  };

  const newdata = getPaginatedData().map((item) => {
    const dataObj = {
      name: item.firstName + " " + item.lastName,
      email: item.email,
      experience:
        item.yearsOfExperience == null
          ? "0 years"
          : `${item.yearsOfExperience} years`,
      language: JSON.parse(item.languages),
      //   skills: JSON.parse(item.skills),
      //   industry: JSON.parse(item.industries),
      //   region: JSON.parse(item.regions),
      addedOn: moment(new Date(item.createddatetime)).format("MM/DD/YYYY"),
    };
    gigTabData.push(dataObj);
  });

  return (
    <div>
      <div>
        <div className="p-3 mb-2">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h6 className="m-0">Registered Recruiters</h6>
            <div className="d-flex bd-highlight ml-2.5">
              <div className="py-2 align-self-end bd-highlight">
                {recruiter && recruiter.length > 0 && (
                  <CSVLink
                    data={gigTabData}
                    headers={headers}
                    className="btn btn-sm"
                    filename="GigRecruiterListExcel"
                    target="_blank"
                  >
                    <span>
                      <i className="fas fa-file-download mr-2"></i>
                      Download
                    </span>
                  </CSVLink>
                )}
              </div>
              <div class="py-2 align-self-end">
                <div class="dropdown-sort dd-sort">
                  <button
                    type="button"
                    onClick={() => setSort(!sort)}
                    class="btn btn-sm w-auto btn-text-accent dd-sort"
                  >
                    <i
                      aria-hidden="true"
                      class="fad fa-sort-down font-16 dd-sort mr-1"
                    ></i>
                    Sort / <span class="dd-sort">{sortCount}</span>
                  </button>
                  {
                    <OutsideClickHandler onOutsideClick={() => setSort(false)}>
                      {sort && (
                        <Sort
                          sortData={filteredData}
                          orderArray={orderList}
                          sortObjects={sortObject}
                          applySort={applySort}
                          setSortCount={setSortCount}
                          sortCount={sortCount}
                          setSortArray={setSortArray}
                          sortArray={sortArray}
                          sortingFor={
                            "Sort by Name, Email, Experience, Industry, Added on"
                          }
                        />
                      )}
                    </OutsideClickHandler>
                  }
                </div>
              </div>
              <div class="py-2 align-self-end">
                <div class="dropdown position-relative">
                  <button
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Filter"
                    onClick={openFilter}
                    type="button"
                    class="btn btn-sm btn-text-accent w-auto"
                  >
                    <i class="fas fa-filter"></i>
                    <span>Filter</span> /{" "}
                    <span>
                      {selectedName.length +
                        selectedUserType.length +
                        selectedSource.length +
                        selectedStatus.length +
                        selectedCountry.length +
                        selectedLanguages.length +
                        selectedIndustries.length}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div>
              <select
                className="form-select font-14"
                onChange={onChangeSelect}
                defaultValue={10}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="All">All</option>
              </select>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
              <input
                type="text"
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search"
                className="form-control small font-14"
              />
            </div>
          </div>
          <hr></hr>
        </div>
      </div>
      <div className="px-3 pt-0 table-cards-1024 mt-3">
        <table className="table table-hoverable-cells table-hoverabletable-cards-1024 font-14">
          <thead>
            <tr>
              <th className="col-md-3">Name</th>
              <th className="col-md-3">Email</th>
              <th className="col-md-2">Experience</th>
              <th className="col-md-2">Language</th>
              {/* <th className="col-md-1">Skills</th>
              <th className="col-md-1">Industry</th>
              <th className="col-md-2">Region</th> */}
              <th className="col-md-2">Added on</th>
            </tr>
          </thead>
          <tbody>
            {getPaginatedData().map((item) => (
              <tr onClick={() => openSidePanel(item)}>
                <td>
                  <Avatar
                    size="35"
                    name={item.firstName + " " + item.lastName}
                    round="80px"
                  />
                  <span className="ml-3">
                    <span className="font-bold d-inline-1024 d-none">
                      Name:
                    </span>
                    <strong
                    // dangerouslySetInnerHTML={{
                    //   __html: splitMatchedText(
                    //     item.firstName + " " + item.LastName
                    //   ),
                    // }}
                    >
                      {item.firstName + " " + item.lastName}
                    </strong>
                  </span>
                </td>
                <td>
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      Email:
                    </span>
                    <span
                    // dangerouslySetInnerHTML={{
                    //     __html: splitMatchedText(
                    //       item.email
                    //     ),
                    //   }}
                    >
                      {item.email}
                    </span>
                  </span>
                </td>
                <td>
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      Experience:
                    </span>
                    <span>
                      {item.yearsOfExperience == null
                        ? "0 years"
                        : `${item.yearsOfExperience} years`}
                    </span>
                  </span>
                </td>{" "}
                <td>
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      Language:
                    </span>

                    <span>
                      {item.languages !== null &&
                        JSON.parse(item.languages).map((lang, index) => {
                          if (
                            index < 2 ||
                            (item.ID_user === userId.id && userId.l === true)
                          )
                            return (
                              <span className="tag tag-blue3 m-1">{lang}</span>
                            );
                        })}
                      {userId.l !== true ? (
                        JSON.parse(item.languages)?.length > 2 && (
                          <div
                            class="tag"
                            onClick={() =>
                              setUserId({
                                ...userId,
                                id: item.ID_user,
                                l: true,
                              })
                            }
                          >
                            +{JSON.parse(item.languages).length - 2} more
                          </div>
                        )
                      ) : item.ID_user === userId.id && userId.l === true ? (
                        <div
                          class="tag"
                          onClick={() => setUserId({ ...userId, l: false })}
                        >
                          show less
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                  </span>
                </td>
                {/* <td>
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      Skills:
                    </span>

                    <span>
                      {item.skills !== null &&
                        JSON.parse(item.skills).map((s, index) => {
                          if (
                            index < 2 ||
                            (item.ID_user === userId.id && userId.s === true)
                          )
                            return (
                              <span className="tag tag-blue3 m-1">{s}</span>
                            );
                        })}
                      {userId.s !== true ? (
                        JSON.parse(item.skills)?.length > 2 && (
                          <div
                            class="tag"
                            onClick={() =>
                              setUserId({
                                ...userId,
                                id: item.ID_user,
                                s: true,
                              })
                            }
                          >
                            +{JSON.parse(item.skills).length - 2} more
                          </div>
                        )
                      ) : item.ID_user === userId.id && userId.s === true ? (
                        <div
                          class="tag"
                          onClick={() => setUserId({ ...userId, s: false })}
                        >
                          show less
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                  </span>
                </td>{" "} */}
                {/* <td>
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      Industry:
                    </span>
                    <span>
                      {item.industries !== null &&
                        JSON.parse(item.industries).map((i, index) => {
                          if (
                            index < 2 ||
                            (item.ID_user === userId.id && userId.i === true)
                          )
                            return (
                              <span className="tag tag-blue3 m-1">{i}</span>
                            );
                        })}
                      {userId.i !== true ? (
                        JSON.parse(item.industries)?.length > 2 && (
                          <div
                            class="tag"
                            onClick={() =>
                              setUserId({
                                ...userId,
                                id: item.ID_user,
                                i: true,
                              })
                            }
                          >
                            +{JSON.parse(item.industries).length - 2} more
                          </div>
                        )
                      ) : item.ID_user === userId.id && userId.i === true ? (
                        <div
                          class="tag"
                          onClick={() => setUserId({ ...userId, i: false })}
                        >
                          show less
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                  </span>
                </td> */}
                {/* <td>
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      Region:
                    </span>
                    <span>
                      {item.regions !== null &&
                        JSON.parse(item.regions).map((i, index) => {
                          if (
                            index < 2 ||
                            (item.ID_user === userId.id && userId.r === true)
                          )
                            return (
                              <span className="tag tag-blue3 m-1">{i}</span>
                            );
                        })}
                      {userId.r !== true ? (
                        JSON.parse(item.regions)?.length > 2 && (
                          <div
                            class="tag"
                            onClick={() =>
                              setUserId({
                                ...userId,
                                id: item.ID_user,
                                r: true,
                              })
                            }
                          >
                            +{JSON.parse(item.regions).length - 2} more
                          </div>
                        )
                      ) : item.ID_user === userId.id && userId.r === true ? (
                        <div
                          class="tag"
                          onClick={() => setUserId({ ...userId, r: false })}
                        >
                          show less
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                  </span>
                </td> */}
                <td>
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      Added on:
                    </span>
                    {moment(new Date(item.createddatetime)).format(
                      "MM/DD/YYYY"
                    )}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {getPaginatedData().length === 0 && (
        <div>
          <div class="text-center p-3">
            <div class="avatar avatar-lg">
              <i class="fad fa-users-slash"></i>
            </div>
            <div class="mt-2">
              No records found {searchValue && `for '${searchValue}'`}
            </div>
          </div>
        </div>
      )}
      <hr />
      {getPaginatedData().length > 0 && (
        <div className="d-flex p-2">
          <div className="w-100-370 mb-point5-370">
            Showing{" "}
            {currentPage === paginationArray.slice(-1)[0]
              ? getPaginatedData().length
              : searchValue
              ? searchData.length < dataLimit
                ? searchData.length
                : dataLimit
              : dataLimit}{" "}
            of {filteredData.length>0 ? filteredData.length : registeredRecruiter.length}
          </div>
          {getPaginatedData().length > 0 && (
            <div class="ml-auto mx-auto-370 d-flex align-items-center">
              <button
                onClick={() => {
                  setPageType("down");
                  setCurrentPage((page) => page - 1);
                }}
                type="button"
                disabled={
                  currentPage === 1 || getPaginatedData().length === 0
                    ? true
                    : false
                }
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-left"></i>
              </button>

              {paginationGroup.map((item) => (
                <button
                  type="button"
                  className={
                    currentPage === item
                      ? "btn btn-text px-2 bg-secondary text-white"
                      : "btn btn-text px-2"
                  }
                  onClick={changePage}
                >
                  {item}
                </button>
              ))}

              <button
                onClick={() => {
                  setPageType("up");
                  setCurrentPage((page) => page + 1);
                }}
                type="button"
                disabled={
                  currentPage === paginationArray.slice(-1)[0] ||
                  getPaginatedData().length === 0
                    ? true
                    : false
                }
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-right"></i>
              </button>
            </div>
          )}
        </div>
      )}
      {filter && (
        <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
          <RegisteredRecFilter
            reportData={registeredRecruiter.map((elem) => {
              return {
                ...elem,
                expYears:
                  elem.yearsOfExperience == null
                    ? 0
                    : elem.yearsOfExperience[
                        elem.yearsOfExperience.length - 1
                      ] === "+"
                    ? Number(elem.yearsOfExperience.split("+")[0])
                    : Number(elem.yearsOfExperience.split("-")[0]),
              };
            })}
            selectedName={selectedName}
            candidateName={registeredRecruiter.map(
              (candidate) => candidate.firstName
            )}
            selectedSource={selectedSource}
            candidateSource={["Invite", "Website"]}
            selectedStatus={selectedStatus}
            candidateStatus={skills}
            selectedCountry={selectedCountry}
            candidateCountry={country}
            applyFilter={applyFilter}
            candidateUserType={yearsRange}
            selectedUserType={selectedUserType}
            industries={industries}
            selectedIndustries={selectedIndustries}
            languages={languages}
            selectedLanguages={selectedLanguages}
          />
        </OutsideClickHandler>
      )}
      {sidePanel && (
        <GigRecSidePanel
          details={{
            ...details,
            skills: details.skills !== null ? JSON.parse(details.skills) : [],
            regions:
              details.regions !== null ? JSON.parse(details.regions) : [],
            languages:
              details.languages !== null ? JSON.parse(details.languages) : [],
            industries:
              details.industries !== null ? JSON.parse(details.industries) : [],
            isreferred: "",
            LastName: details.lastName,
            city: details.addressCity,
            state: details.addressState,
            country: details.country,
            phoneCode: details.homePhone,
            status: "Approved",
            isFromRegRec: true,
          }}
          closePanel={() => setSidePanel(false)}
          //   fetchGigRecruiters={fetchGigRecruiters}
          //   getTenantData={getTenantData}
        />
      )}
    </div>
  );
};

export default RegisteredRecruiters;
