import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const FETCH_CURATOR_CLIENT_DATA_BEGIN =
  "FETCH_CURATOR_CLIENT_DATA_BEGIN";
export const FETCH_CURATOR_CLIENT_DATA_SUCCESS =
  "FETCH_CURATOR_CLIENT_DATA_SUCCESS";
export const FETCH_CURATOR_CLIENT_DATA_FAILURE =
  "FETCH_CURATOR_CLIENT_DATA_FAILURE";

export const GET_CURATOR_DATA_COUNT_BEGIN = "GET_CURATOR_DATA_COUNT_BEGIN";
export const GET_CURATOR_DATA_COUNT_SUCCESS = "GET_CURATOR_DATA_COUNT_SUCCESS";
export const GET_CURATOR_DATA_COUNT_FAILURE = "GET_CURATOR_DATA_COUNT_FAILURE";

export const getCuratorData = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_CURATOR_CLIENT_DATA_BEGIN,
    });
    const res = await post("/curator/getCuratorClientData", payload);
    if (res.status === 200) {
      dispatch({
        type: FETCH_CURATOR_CLIENT_DATA_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_CURATOR_CLIENT_DATA_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getCuratorDataCount = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CURATOR_DATA_COUNT_BEGIN,
    });
    const res = await post("/curator/getCuratorClientDataCount", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_CURATOR_DATA_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CURATOR_DATA_COUNT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
