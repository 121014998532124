import React, { useState, useEffect } from "react";
import { domain, emailDomain } from '../../../../constants/Constants'
import { post } from '../../../../service/ApiService'
import { toast } from 'react-toastify';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css'
import imageLoader from '../../../../images/button-loader.gif';
import Validator from 'email-validator';
function ShareViaEmail(props) {
    const tenantName = props.jobData.jobTenant
    const [talentData, setTalentData] = useState([])
    const [email, setEmail] = useState([])
    const [value, setValue] = useState('')
    const [shareClicked, setShareClicked] = useState(false)
    const [checkedItems, setCheckedItems] = useState([])
    useEffect(() => {
        fetchTalentDetails()
    }, []);
    const fetchTalentDetails = async () => {
        const payload = {
            tenantName: tenantName,
            domain: domain
        }
        //let talentData = await post('/talentdataformail/getmailmergedetail', payload)
        // setTalentData(talentData.data);
    }
    const MailMergedAPI = async (payload) => {
        try {
            const response = await post('/sendmail/insert', payload)
            if (response.status === 200 ) {
                // if(checkedItems.length > 0){
                props.setSuccessFormOpen(true)
                toast.success(`Mail has been shared sucessfully with selected candidates`)
            } else {
                toast.error('Oops! something went wrong. please try again later')
            }
        } catch (error) {
            toast.error('Oops! something went wrong. please try again later')
        }
    }
    const handleMailMerge = (e) => {
        let jobId = props.jobData.jobId
        let jobTitle = props.jobData.jobTitle
        let sendMailLink = `${emailDomain}/sharejob/Id:${jobId}`;
        let totalEmailData = []
        totalEmailData = [...checkedItems, ...email]
        const resObj = {
            mailList: totalEmailData,
            sendMailLink: sendMailLink,
            jobTitle: jobTitle
        }

        if(checkedItems.length !== 0 || email.length !== 0){
          console.log("length = " ,checkedItems.length )
          console.log("clicked")
             MailMergedAPI(resObj)
             setShareClicked(true)
             }
        else {             
            toast.error('Select atleast one candidate')
            setShareClicked(false)
            }
    }
    
    const handleCheck = (e) => {
       console.log(e.target.checked)
        if (e.target.checked ) {
        
            if((email.length + checkedItems.length) >= 10){
                toast.error('Job can be shared with only 10 candidates at a time')
            }else {
                setCheckedItems([...checkedItems, e.target.value]);
            }
        } else {
               const newCheckedItems = [...checkedItems]
            newCheckedItems.splice(newCheckedItems.indexOf(e.target.value), 1)
            setCheckedItems(newCheckedItems)
        }
    }
    const close = () => {
        props.handleSuccess(true)
    }
    const handleEmailChange = (tags) => {
        if (Validator.validate(tags.slice(-1)[0])) {
            if ((tags.length + checkedItems.length) <= 10) {
                setEmail(tags)
            } else {
                toast.error('Job can be shared with only 10 candidates at a time')
            }
        }
        else {
            toast.error('Please enter a valid Email ID')
        }
    }
    const handleChangeInput = (value) => {
        setValue(value)
    }
    return (
        <>
            <div className="bl-modal-body " style={{ paddingBottom: "5px", paddingTop: "5px" }}>
                <label>Enter email address and hit share to send multiple emails(Max 10)</label>
                <br />
                <div className="form-floating">
                    <br />
                    <TagsInput
                        id="emailtag"
                        value={email}
                        maxTags={10}
                        onChange={handleEmailChange}
                        inputValue={value}
                        onChangeInput={handleChangeInput}
                        inputProps={{
                            placeholder: 'Enter Email',
                        }}
                    />
                </div>
            </div>
            <div className="showEmailForm">
                {/* <div className="bl-modal-body " style={{ paddingTop: "5px" }}>
                    {talentData.length !== 0 ? (
                        <>
                            <div className="table-wrap">
                                <h6>Select the talents to share a job description via mail!</h6>
                                <table className="table table-sortable table-hoverable-cells table-cards-1024">
                                    <thead>
                                        <tr className="d-none d-lg-table-row">
                                            <th></th>
                                            <th>Email Id</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                        </tr>
                                    </thead>
                                    {
                                        talentData.map((item) => (
                                            <tbody>
                                                <td>
                                                    <input type="checkbox"
                                                        className="form-check-input"
                                                        style={{ width: "20px" }}
                                                        value={item.email}
                                                        onChange={(e) => handleCheck(e)}
                                                        checked={checkedItems.includes(item.email)? true : false}
                                                    />
                                                </td>
                                                <td className="no-hover">{item.email}</td>
                                                <td className="no-hover">{item.CandidateFirstName}</td>
                                                <td className="no-hover">{item.CandidateLastName}</td>
                                                <hr />
                                            </tbody>
                                        ))
                                    }
                                </table>
                            </div>
                        </>
                    )
                        : null}
                </div> */}
                <div className="modal-footer">
                    <button type="button"
                        className="btn btn-secondary btn-sm"
                        data-dismiss="modal"
                        onClick={close}
                        disabled={shareClicked}>
                        Cancel
                    </button>
                    <button type="button" className="btn btn-primary btn-sm">
                        {shareClicked === false ? (
                            <span onClick={handleMailMerge}>Share</span>
                        ) : (
                            <span>
                                Sending Email
                                <img width="20px" src={imageLoader} alt="blank" />
                            </span>
                        )}
                    </button>
                </div>
            </div>
        </>
    )
}
export default ShareViaEmail