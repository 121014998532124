import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import Select from "react-select";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import { get, post } from "../../../service/ApiService";
import RichTextEditor from "react-rte";

const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const EditSupportQuestions = ({ close, fetchFAQ_API, FAQData }) => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [faqInfo, setFaqInfo] = useState({ fileName: "", base64: "" });
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [previewShow, setPreviewShow] = useState(false);
  const [formData, setFormData] = useState({
    Question: "",
    // Answer: "",
    Roles: [],
  });
  const [selectedImage, setSelectedImage] = useState(null);
  const [URL, setURL] = useState(false);
  const [selected, setSelected] = useState([]);

  const findFormErrors = () => {
    const { Question, Roles } = formData;
    const newErrors = {};
    if (!Question || Question === "") {
      newErrors.Question = "Question cannot be blank!";
    }
    // if (!Answer || Answer === "") {
    //   newErrors.Answer = "Answer cannot be blank!"
    // }
    if (selected.length === 0) {
      newErrors.Roles = "Roles cannot be blank!";
    }
    return newErrors;
  };

  const handleClose = () => setPreviewShow(false);
  const handleShow = () => setPreviewShow(true);

  const changeContent = (name, e) => {
    console.log(e);
    setFormData({
      ...formData,
      [name]: e,
    });
  };

  const onRTEChange = (value) => {
    setValue(value);
  };

  const getData = async () => {
    setLoading(true);
    const response = await get("/support/getRoles");
    if (response.status === 200) {
      setOptions(
        response.data
          .filter((d) => d.rolename !== "Admin")
          .map((role) => ({
            value:
              role.rolename === "CustomerSuccess"
                ? "High5 Admin"
                : role.rolename,
            label:
              role.rolename === "HiringManager"
                ? "Hiring Manager"
                : role.rolename === "SuperAdmin"
                ? "Super Admin"
                : role.rolename === "SalesAdmin"
                ? "Sales Admin"
                : role.rolename === "High5Coordinator"
                ? "Customer Success Manager"
                : role.rolename === "CommunityManager"
                ? "Marketplace Manager"
                : role.rolename === "CustomerSuccess"
                ? "High5 Admin"
                : role.rolename,
            id: role.id_role,
          }))
      );
      let responseData = response.data;
      let faqRole = JSON.parse(FAQData.fk_role);
      let selection = responseData
        .filter((i) => faqRole.includes(i.id_role))
        .map((i) => i.rolename);
      selection = selection.map((item) =>
        item === "CustomerSuccess" ? "High5 Admin" : item
      );
      setSelected(selection);
      setLoading(false);
    } else {
      toast.error("Some Error Occurred. Please Try Again");
      setLoading(false);
    }
  };

  useEffect(() => {
    console.log(FAQData);
    setFormData({ ...formData, Question: FAQData.Title });
    setURL(FAQData.ImageURL);
    setValue(RichTextEditor.createValueFromString(FAQData.Content, "html"));
    getData();
  }, []);

  const saveData = async () => {
    const newErrors = findFormErrors();
    const imgID = Math.floor(Math.random() * 650000000) + 300000000;
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors(newErrors);
      // alert("No Errors Found")
      setLoading(true);
      const params = {
        ...formData,
        fileName: faqInfo.fileName + "_" + imgID,
        base64: faqInfo.base64,
        Answer: value.toString("html"),
        role: options.filter((i) => selected.includes(i.value)),
        ID: FAQData.ID_support,
        URL,
      };
      //   console.log('Support', params)
      //   setLoading(false)
      const result = await post("/support/updateData", params);
      console.log("data", result);
      if (result.status === 200) {
        setLoading(false);
        toast.success("FAQ updated successfully");
        close();
        setTimeout(() => fetchFAQ_API(), 4000);
      }
    }
  };

  const changeRoles = async (e) => {
    console.log(e);
    setSelected(e.map((i) => i.value));
  };

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  const onPreviewClick = (name, e) => {
    setPreviewShow(true);
  };

  const setFileForSending = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setSelectedImage(file);
    const { name } = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setFaqInfo({ fileName: name, base64: event.target.result });
    };
  };

  const handleModalClose = () => setPreviewShow(false);

  return (
    <>
      {loading && <Loader />}
      <div className=" pb-5">
        <div class="bl-modal-body">
          <div class="card card-flat card-borderless bg-gray4">
            <div class="card-body">
              <div className="row">
                <div class="col-md-12">
                  <div class="form-floating">
                    <p className="m-0 mb-1">Write the question*</p>
                    <Form.Control
                      type="text"
                      placeholder="Enter Question"
                      value={formData.Question}
                      name="Question"
                      onChange={(e) =>
                        changeContent("Question", e.target.value)
                      }
                    />
                    <small className="validation">{errors.Question}</small>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  {/* <ReactQuill
                    placeholder="Write Something Amazing"
                    onChange={(e) => changeContent("Answer", e)}
                    modules={modules}
                    value={formData.Answer}
                  /> */}
                  <p className="m-0 mb-1">Answer</p>
                  <RichTextEditor
                    toolbarConfig={toolbarConfig}
                    value={value}
                    onChange={onRTEChange}
                  />
                </div>
                <small className="validation">{errors.Answer}</small>
              </div>
              {faqInfo.base64 || URL ? (
                <div className="row mt-4">
                  <div className="col-lg-4 mt-3">
                    <p className="mb-2">Uploaded Image</p>
                    <img
                      src={faqInfo.base64 ? faqInfo.base64 : URL}
                      alt=""
                      width="700"
                      height="350"
                    />
                    <div className="mt-2">
                      <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => {
                          setFaqInfo({ fileName: "", base64: "" });
                          setURL("");
                        }}
                      >
                        Delete Image
                      </button>
                    </div>
                  </div>
                  {/* {faqInfo.base64 && (
                <div className="row d-flex mt-2">
                  <div className="col-lg-4">
                    <img alt="Image Not found" width={"250px"} src={faqInfo.base64} />
                  </div>
                  <div className="col-lg-2 ml-auto mt-auto"><button className="btn btn-secondary" onClick={() => setSelectedImage(null)}>Remove</button></div>
                </div>
              )} */}
                  <div className="row mt-3">
                    <div className="col-lg-6">
                      <label className="p-0 mb-2">Upload New</label>
                      <input
                        type="file"
                        accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                        onChange={setFileForSending}
                      />
                      <small className="mt-1">
                        Uploading new Image will replace the existing one
                      </small>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row mt-3">
                  <div className="col-lg-6">
                    <p>
                      <strong>Do you have Supporting screenshot?</strong>
                    </p>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <label className="p-0 mb-1">Upload here</label>
                      <input
                        type="file"
                        accept="image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp"
                        onChange={setFileForSending}
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="mt-4 col-md-12">
                  <p className="m-0">Who can view this question?*</p>
                  <Select
                    options={options}
                    onChange={(e) => changeRoles(e)}
                    isMulti={true}
                    value={options.filter((obj) =>
                      selected.includes(obj.value)
                    )}
                  />
                </div>
                <small className="validation">{errors.Roles}</small>
              </div>
              <div className="d-flex mt-4">
                <button onClick={onPreviewClick}>Preview</button>
                <button onClick={saveData} className="ml-3">
                  Update
                </button>
                <button onClick={close} className="ml-3">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={previewShow} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <h6>Preview</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12">
              <div className="card m-2">
                <div className="card-body">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      value={formData.Question}
                    />
                    <label className="m-0">Question</label>
                  </div>
                  <div className="form-floating mt-2">
                    <div className="row mt-4">
                      <div className="col-md-12">
                        <p className="m-0 mb-1">Answer</p>
                        <RichTextEditor
                          toolbarConfig={toolbarConfig}
                          value={value}
                          // onChange={onRTEChange}
                          disabled
                        />
                      </div>
                      <small className="validation">{errors.Answer}</small>
                    </div>
                  </div>
                  {(faqInfo.base64 || URL) && (
                    <div className="col-lg-4 mt-2">
                      <p className="mb-2">Uploaded Image</p>
                      <img
                        src={faqInfo.base64 ? faqInfo.base64 : URL}
                        alt=""
                        width="400"
                        height="300"
                      />
                    </div>
                  )}
                  <div className="col-lg-10">
                    <p className="mb-0 mt-3">Roles:</p>
                    <medium className="mb-2">{selected.join(", ")}</medium>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditSupportQuestions;
