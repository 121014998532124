import React from "react";

import PoolList from "../../../directsourcing/talentcommunity/poollist/PoolList";

function Index() {
  return (
    <>
      <div className="card card-flat card-borderless bg-gray4 p-4 mb-3">
        <div className="d-flex align-items-centermb-3 mb-3">
          <div className="d-md-flex align-items-center">
            <h6 className="mr-3 mb-0">Communities </h6>
            <a href="/high5Hire/talentcommunity/pools">Go To Full View</a>
          </div>
        </div>
        <>
          <PoolList isFrom="HiringManagerDashBoard" />
        </>
      </div>
    </>
  );
}

export default Index;
