import React, { useState } from "react";
import {
  BsCheckCircleFill,
  BsEye,
  BsFillPauseCircleFill,
} from "react-icons/bs";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import moment from "moment";

const CronJobsTable = ({
  cronJobs,
  cronJobLog,
  handleUpdateCronJob,
  handleManualTrigger,
  handleViewModalShow,
}) => {
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th className="no-hover pt-0">
              <div className="d-flex align-items-center text-center">Sr.No</div>
            </th>
            <th className="no-hover pt-0">
              <div className="d-flex align-items-center text-center">
                Cron Job Title
              </div>
            </th>
            <th>
              <div className="d-flex align-items-center text-center">
                Details
              </div>
            </th>
            <th>
              <div className="d-flex align-items-center text-center">
                Frequency
              </div>
            </th>
            <th>
              <div className="d-flex align-items-center text-center">
                Last run time
              </div>
            </th>
            <th>Action</th>
          </tr>
        </thead>

        <tbody className="table-bordered table responsiveTable">
          {cronJobs &&
            cronJobs.length > 0 &&
            cronJobs.map((cronJob, index) => (
              <tr key={cronJob.cronJobId}>
                <td scope="row">{index + 1}</td>
                <td>{cronJob.cronJobName}</td>
                <td>{cronJob?.details}</td>
                <td>{cronJob.schedulerTime}</td>
                <td>
                  {cronJob?.lastRunTime
                    ? moment(cronJob?.lastRunTime).format("MM/DD/YYYY HH:mm:ss")
                    : ""}
                </td>
                <td className="d-flex flex-inline justify-content-center align-content-center">
                  <button
                    type="button"
                    style={{
                      width: "100px",
                      // height: "30px",
                      fontSize: "12px",
                      textAlign: "center",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                      boxShadow: "none",
                    }}
                    className="btn btn-sm btn-warning p-1"
                    onClick={() => handleManualTrigger(cronJob)}
                    disabled={cronJob.cronJobStatus !== 1 ? true : false}
                  >
                    <>
                      Re-trigger
                      {/* <i className="ml-1">
                        <BsEye />
                      </i> */}
                    </>
                  </button>
                  <button
                    type="button"
                    style={{
                      width: "90px",
                      fontSize: "12px",
                      textAlign: "center",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                    }}
                    className={
                      cronJob.cronJobStatus === 1
                        ? "btn btn-sm btn-outline-danger ml-2 p-1"
                        : "btn btn-sm btn-primary ml-2 p-1"
                    }
                    onClick={() => handleUpdateCronJob(cronJob)}
                  >
                    {cronJob.cronJobStatus === 1 ? (
                      <>
                        Pause{" "}
                        <i className="ml-2">
                          <BsFillPauseCircleFill />
                        </i>
                      </>
                    ) : (
                      <>
                        Activate{" "}
                        <i className="ml-1">
                          <BsCheckCircleFill />
                        </i>
                      </>
                    )}
                  </button>
                  <button
                    type="button"
                    style={{
                      width: "70px",
                      // height: "30px",
                      fontSize: "12px",
                      textAlign: "center",
                      display: "inline-flex",
                      justifyContent: "center",
                      alignItems: "baseline",
                    }}
                    className="btn btn-sm btn-secondary ml-2 p-1"
                    onClick={() => {
                      handleViewModalShow(cronJob);
                      setShowModal(true);
                    }}
                  >
                    <>
                      View{" "}
                      <i className="ml-1">
                        <BsEye />
                      </i>
                    </>
                  </button>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Email Data</Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body className="p-3">
          {cronJobLog && (
            <table className="table">
              <thead>
                <tr>
                  <th>Sr. No</th>
                  <th>Date</th>
                  {/* <th>Status</th> */}
                  <th>Entity Count</th>
                  <th>Email Count</th>
                </tr>
              </thead>
              <tbody>
                {cronJobLog && cronJobLog.length > 0 ? (
                  cronJobLog.map((data, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {moment(data.lastRunTime).format("MM/DD/YYYY HH:mm:ss")}
                      </td>
                      {/* <td>{data.status}</td> */}
                      <td>{data.entityCount}</td>
                      <td>{data.emailSent}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4} className="text-center p-5">
                      No records found!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CronJobsTable;
