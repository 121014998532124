import React, { useState } from "react";

const SkillDetails = (props) => {
  const { jobData } = props;
  const [viewAllMandatory, setViewAllMandatory] = useState(false);
  const [viewAllSecondary, setViewAllSecondary] = useState(false);
  return (
    <div className="mb-4 ml-4">
      <div className="d-flex mb-2 pl-1">
        <div className="ml-2">
          {jobData?.primarySkills &&
            JSON.parse(jobData?.primarySkills).map(
              (item, index) =>
                (index < 2 || viewAllMandatory) && (
                  <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
                )
            )}
          {jobData?.primarySkills &&
            (viewAllMandatory ? (
              <div
                className="tag"
                onClick={() => setViewAllMandatory(false)}
                style={{ margin: "2px", cursor: "pointer" }}
              >
                Show less
              </div>
            ) : (
              JSON.parse(jobData?.primarySkills).length > 2 && (
                <div
                  className="tag"
                  onClick={() => setViewAllMandatory(true)}
                  style={{ margin: "2px", cursor: "pointer" }}
                >
                  +{JSON.parse(jobData?.primarySkills).length - 2} more
                </div>
              )
            ))}
          <p className="mb-0">
            <small>Mandatory Skills</small>
          </p>
        </div>
      </div>
      {jobData.goodToHaveSkills &&
        JSON.parse(jobData.goodToHaveSkills).length > 0 && (
          <div className="d-flex mb-2 pl-1">
            <div className="ml-2">
              {JSON.parse(jobData.goodToHaveSkills).map(
                (item, index) =>
                  (index < 2 || viewAllSecondary) && (
                    <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
                  )
              )}
              {jobData?.goodToHaveSkills &&
                (viewAllSecondary ? (
                  <div
                    className="tag"
                    onClick={() => setViewAllSecondary(false)}
                    style={{ margin: "2px", cursor: "pointer" }}
                  >
                    Show less
                  </div>
                ) : (
                  JSON.parse(jobData?.goodToHaveSkills).length > 2 && (
                    <div
                      className="tag"
                      onClick={() => setViewAllSecondary(true)}
                      style={{ margin: "2px", cursor: "pointer" }}
                    >
                      +{JSON.parse(jobData?.goodToHaveSkills).length - 2} more
                    </div>
                  )
                ))}
              <p className="mb-0">
                <small>Good to have skills</small>
              </p>
            </div>
          </div>
        )}
    </div>
  );
};

export default SkillDetails;
