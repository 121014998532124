import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import ShortUniqueId from "short-unique-id";
import DisqualifyPopUp from "../disqualified/DisqualifyPopUp";
import ResumeDownload from "../resume/Index";
import ShortlistPopUp from "../shortlisted/ShortlistPopUp";
import TalentProfile from "../../../../talent/talentprofile/TalentProfile";
import VettingResendMail from "../shortlisted/VettingResendMail";
import EmailStatus from "../../../../EmailStatus/EmailStatus";
import { getPoolDispositionDetails } from "../../../../../../actions/directsourcing/Disposition";
import { getPoolDispositionCount } from "../../../../../../actions/directsourcing/PoolDispositionCount";
import { post } from "../../../../../../service/ApiService";
import Loader from "../../../../commoncomponents/Loader/Loader";
import ScreeningQuestions from "../Screening";
import VettingRequirement from "../Vetting";
import ReadyToHireQA from "../ReadyToHireQA";
import ProfileImage from "../../../../commoncomponents/profileimage/ProfileImage";
import { FaCommentAlt } from "react-icons/fa";

const Submitted = (props) => {
  const {
    jobData,
    submittedData,
    fetchLatestEmailStatus,
    filterParams,
    view,
    setCandidateProfile,
    candidateProfile,
    setSelectedTalents,
    selectedTalents,
    dispositionCount,
    filterLength,
  } = props;
  const dispatch = useDispatch();
  const { poolId } = useParams();
  const uid = new ShortUniqueId({ length: 10 });
  const [qna, setQNA] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  const [disqualifyPopup, setDisqualifyPopup] = useState(false);
  const [disqualifyData, setDisqualifyData] = useState(false);
  const [resume, setResume] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const [shortlistPopup, setShortlistPopup] = useState(false);
  const [vettingResendPopup, setVettingResendPopup] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [assessmentTab, setAssessmentTab] = useState(false);
  const [loading, setLoading] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState({});
  const [screeningQuestion, setScreeningQuestion] = useState();
  const [vetting, setVetting] = useState(false);
  const [readyToHireQA, setReadyToHireQA] = useState(false);
  const [isCandidateFrom, setIsCandidateFrom] = useState("");

  const onDisqualifyClick = () => {
    setOpenDropDown(false);
    setDisqualifyPopup(true);
  };

  const openDisqualify = (data) => {
    setDisqualifyData(data);
    setOpenDropDown(true);
  };

  const handleResume = (data) => {
    setResumeData(data);
    setResume(true);
  };

  const shortlistCandidate = async (item) => {
    setSelectedCandidate(item);

    let vettingDetails = item?.vettingDetails;

    if (vettingDetails?.length > 0 && vettingDetails[0]?.status === "Invited") {
      setVettingResendPopup(true);
    } else {
      setShortlistPopup(true);
    }
  };

  const reloadDisposition = () => {
    const payloadDisposition = {
      poolId: poolId,
      status: view,
      limit: 15,
      currentPage: 1,
      filterObject: filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionDetails(payloadDisposition));
    const payload = {
      jobId: poolId,
      filterObject: filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionCount(payload));
    setShortlistPopup(false);
    setDisqualifyPopup(false);
  };

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
    setAssessmentTab(false);
  };

  const openAssessment = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
    setAssessmentTab(true);
  };

  const handleModel = () => {
    setCandidateProfile(false);
    setAssessmentTab(false);
  };

  // // Function to toggle selected talent state
  const toggleSelectedTalent = (candidate) => {
    let userId = candidate?.userId;
    let firstName = candidate?.firstName;
    let lastName = candidate?.lastName;
    let email = candidate?.email;
    let newSletter = candidate?.newSletter;
    let designation = candidate?.designation;
    let homePhoneCode = candidate?.homePhoneCode;
    let workPhoneCode = candidate?.workPhoneCode;
    let homePhone = candidate?.homePhone;
    let workPhone = candidate?.workPhone;
    let recruiterId = candidate?.recruiterId;

    let data = {
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      newSletter: newSletter,
      designation: designation,
      homePhoneCode: homePhoneCode,
      workPhoneCode: workPhoneCode,
      homePhone: homePhone,
      workPhone: workPhone,
      recruiterId: recruiterId,
    };

    // setSelectedTalents((prevData) =>
    //   prevData.includes(id)
    //     ? prevData.filter((x) => x != id)
    //     : [...prevData, id]
    // );

    setSelectedTalents((prevData) =>
      prevData.some((x) => x.userId === userId)
        ? prevData.filter((c) => c.userId != userId)
        : [...prevData, data]
    );
  }; // Function to toggle selected talent state

  const InviteTalentWithVet = async (candidateId) => {
    try {
      setLoading(true);
      var date = new Date();
      let vettingDetails = JSON.parse(jobData.vettingDetails);

      let paramObj = {
        isPoolCandidate: false,
        tenantName: jobData.tenantName,
        jobTitle: jobData.jobTitle,
        candidateInfo: {
          firstName: candidateId.firstName,
          lastName: candidateId.lastName,
          email: candidateId.email,
          phone: candidateId.homePhoneCode + "" + candidateId.homePhone,
        },
        expiryDate: date.setDate(date.getDate() + 7),
        createdFrom: "talentPool",
        testAssign: vettingDetails._id,
        vettingDetails: vettingDetails,
        uniqueCode: uid(),
        testStatus: "Invited",
        recruiterId: candidateId?.recruiterId,
        candidateId: candidateId?.userId,
        jobId: jobData?.poolId,
        reviewerFirstName: jobData?.reviewerFirstName,
        reviewerLastName: jobData?.reviewerLastName,
        reviewerEmail: jobData?.reviewerEmail,
        poolowner: jobData?.poolowner,
        // fk_tenant: tenant,
        // companyName: tenant === 0 ? jobData.tenantName : "High5Hire",
      };

      const vetRes = await post("/vetIntegration/candidate/Invite", paramObj);
      if (vetRes.status === 200) {
        let data = { dispositionId: candidateId.id_candidateTalentPool };
        // await post("/disposition/updatePoolDispositionVetting",data)
        reloadDisposition();
        setLoading(false);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleInvite = async (item) => {
    await InviteTalentWithVet(item);
  };
  return (
    <>
      {loading && <Loader />}
      {submittedData.map((item, index) => (
        <tr key={index}>
          <td className="no-hover">
            {dispositionCount?.filter((e) => e.Status == "Submitted")[0]
              ?.Count > 0 && (
              // filterLength > 0 &&
              <div className="d-flex align-items-start mb-1 justify-content-center h-100">
                <input
                  type="checkbox"
                  className="form-check-input"
                  style={{ width: "1.5rem", borderRadius: "100" }}
                  checked={selectedTalents?.some(
                    (c) => c.userId === item?.userId
                  )}
                  onClick={() => toggleSelectedTalent(item)}
                />
              </div>
            )}
          </td>
          <td className="no-hover" onClick={() => onProfileClick(item)}>
            <div className="d-flex w-70">
              <ProfileImage
                imageSrc={item?.profileImagePath}
                imageWidth={"35"}
                imageHeight={"35"}
                avatarSize={"35"}
                avatarRound={"80px"}
                firstName={item?.firstName}
                lastName={item?.lastName}
              />

              <span className="m-2 font-16">
                {item?.firstName.charAt(0).toUpperCase() +
                  item?.firstName.slice(1)}{" "}
                {item?.lastName.charAt(0).toUpperCase() +
                  item?.lastName.slice(1)}
              </span>
              <div className="justify-content-end mt-2">
                {!item?.readStatus && (
                  <button
                    type="button"
                    title="Send SMS"
                    class="btn btn-text"
                    style={{ marginLeft: "auto" }}
                    onClick={() => {
                      setCandidateProfile(true);
                      setIsCandidateFrom("sms");
                    }}
                  >
                    <div className="notification-icon">
                      <FaCommentAlt />
                      <span className="message-status"></span>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </td>
          {/* <td className="no-hover">
            <span class="tag tag-lg tag-gray">
              <img width="14px" src={vettedIcon} alt="" />
              <span class="mx-2">No</span>
            </span>
          </td> */}
          <td className="no-hover">
            {moment(new Date(item.submittedDate)).fromNow()}
          </td>
          <td class="no-hover">
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Knockout Assessment"
              class="d-inline-block"
              data-original-title="Knockout Assessment"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                class="btn btn-square btn-gray"
                disabled={
                  (item?.screeningQuestions
                    ? JSON.parse(item?.screeningQuestions)
                    : []
                  ).length === 0
                    ? true
                    : false
                }
                onClick={() => {
                  setQNA(true);
                  setScreeningQuestion(item);
                }}
              >
                <i aria-hidden="true" class="fad fa-webcam"></i>
              </button>
            </div>{" "}
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Vetting"
              class="d-inline-block"
              data-original-title="Vetting"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                disabled={item?.vettingDetails?.length === 0 ? true : false}
                class="btn btn-square btn-gray"
                onClick={() => {
                  setVetting(true);
                  setScreeningQuestion(item);
                }}
              >
                <i aria-hidden="true" class="fas fa-walkie-talkie"></i>
              </button>
            </div>{" "}
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Ready To Hire"
              class="d-inline-block"
              data-original-title="Ready To Hire"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                disabled={item?.readyToHireQA ? false : true}
                class="btn btn-square btn-gray"
                onClick={() => {
                  setReadyToHireQA(true);
                  setScreeningQuestion(item);
                }}
              >
                <i aria-hidden="true" class="fas fa-ballot-check"></i>
              </button>
            </div>
          </td>
          <td className="no-hover">
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Resume Download"
              style={{ padding: "2px" }}
            >
              <button
                type="button"
                onClick={() => handleResume(item)}
                data-toggle="modal"
                data-target="#ViewResumeDocModal"
                className="btn btn-square btn-gray"
              >
                <i className="fas fa-file-user" />
              </button>
            </div>
          </td>
          <td>
            <span className="ml-2">
              {item.URLOpenCount ? item.URLOpenCount : 0}
            </span>
          </td>
          {jobData?.vettingDetails && (
            <td>
              {/* <button className="btn btn-sm" onClick={()=>handleInvite(item)}>Invite</button> */}
              {item?.vettingDetails[0]?.status ? (
                item?.vettingDetails[0]?.status === "underReview" ? (
                  <span className="mr-2 tag font-bold tag-lightBlue mb-2">
                    Under Review
                  </span>
                ) : (
                  <span className="mr-2 tag font-bold tag-orange1 mb-2">
                    {item?.vettingDetails[0]?.status}
                  </span>
                )
              ) : (
                <span className="mr-2 tag font-bold tag-orange1 mb-2">
                  Invited
                </span>
              )}
            </td>
          )}
        </tr>
      ))}
      {disqualifyPopup && (
        <DisqualifyPopUp
          disqualifyPopup={disqualifyPopup}
          setDisqualifyPopup={setDisqualifyPopup}
          disqualifyData={disqualifyData}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
        />
      )}
      {resume && (
        <ResumeDownload
          resume={resume}
          setResume={setResume}
          resumeData={resumeData}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          submittedData={submittedData}
          setDisqualifyPopup={setDisqualifyPopup}
          setOpenDropDown={setOpenDropDown}
          setSelectedCandidate={setSelectedCandidate}
          setShortlistPopup={setShortlistPopup}
          openDisqualify={openDisqualify}
          disqualifyData={disqualifyData}
          openDropDown={openDropDown}
          onDisqualifyClick={onDisqualifyClick}
          candidateData={selectedCandidate}
          handleSuccess={handleModel}
          jobData={jobData}
          parent="submitTab"
          isFrom="submitPooled"
          component="TalentPool"
          assessment={assessmentTab}
          disqualifyPopup={disqualifyPopup}
          reloadDisposition={reloadDisposition}
          setVettingResendPopup={setVettingResendPopup}
          poolId={poolId}
          isCandidateFrom={isCandidateFrom}
          setIsCandidateFrom={setIsCandidateFrom}
        />
      )}
      {shortlistPopup && (
        <ShortlistPopUp
          selectedCandidate={selectedCandidate}
          shortlistPopup={shortlistPopup}
          setShortlistPopup={setShortlistPopup}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
        />
      )}

      {vettingResendPopup && (
        <VettingResendMail
          candidateData={selectedCandidate}
          jobData={jobData}
          vettingResendPopup={vettingResendPopup}
          setVettingResendPopup={setVettingResendPopup}
        />
      )}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="Submitted"
          invitedDate={selectedTalent?.invitedDate}
          jobData={jobData}
        />
      )}
      <ScreeningQuestions
        QandA={
          screeningQuestion?.screeningQuestions
            ? JSON?.parse(screeningQuestion?.screeningQuestions)
            : []
        }
        setQNA={setQNA}
        qna={qna}
      />

      <VettingRequirement
        vettingDetails={screeningQuestion?.vettingDetails}
        setVetting={setVetting}
        vetting={vetting}
        jobData={jobData}
      />

      <ReadyToHireQA
        QandA={
          screeningQuestion?.readyToHireQA
            ? JSON?.parse(screeningQuestion?.readyToHireQA)
            : []
        }
        setQNA={setReadyToHireQA}
        qna={readyToHireQA}
      />
    </>
  );
};

export default Submitted;
