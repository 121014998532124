import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getTenantType, getUserRole } from "../../../service/AuthService";
import LocationFilter from "./locationFilter/LocationFilter";
import DDMS from "./dropDownMSFilter/DDMSFilter";
import { post } from "../../../service/ApiService";
import {
  visaStatus,
  dispositionOptions,
  countryList,
  experienceOptions,
  jobTypeOptions,
  educationOptions,
  usStateArr,
  talentAcquisitionJobType,
} from "../../../constants/Constants";
import BooleanFilter from "./booleanFilter/BooleanFilter";
import SearchFilter from "./searchFilter/SearchFilter";
import PayRateFilter from "./payRateFilter/PayRateFilter";
// import ResumeDateFilter from "./resumeDateFilter/ResumeDateFilter";
import { getTalentTagList } from "../../../actions/talent/TalentTagFilter";
// import {
//   getTalentList,
//   getTalentListCount,
// } from "../../../actions/talent/talentList";
import Loader from "../../Loader/Loader";
import { getTalentDesignationList } from "../../../actions/talent/TalentDesignationFilter";
import { getTalentStateList } from "../../../actions/talent/talentStateFilter";
import { getTalentCountryList } from "../../../actions/talent/talentCountryFilter";
import { getTalentSourceList } from "../../../actions/talent/TalentSourceFilter";
import Suggestion from "./inputSuggestion/Suggestion";
import { getTalentCertificationList } from "../../../actions/talent/Certification";
import { getTalentNursingSpecialtyList } from "../../../actions/talent/NursingSpecialty";
import { getTalentLicenseStateList } from "../../../actions/talent/LicenseState";
import { getTalentRecruiterList } from "../../../actions/talent/TalentRecruiterFilter";

const Index = (props) => {
  const dispatch = useDispatch();
  const TAGS_FILTER_FOR = ["Recruiter", "SuperAdmin"];

  const {
    //sortArray,
    //setCurrentPage,
    selectedData,
    setSelectedData,
    applyFilter,
    //getFilterParamsNew,
    inputList,
    setInputList,
    skillInputList,
    setSkillInputList,
    isFilterApplied,
    setIsFilterApplied,
    min,
    setMin,
    setMax,
    max,
    handleClear,
    filterParams,
    setFilterParams,
    loading,
    talentCountloading,
    isFrom,
  } = props;
  const [
    internationalPlacementOption,
    setInternationalPlacementOption,
  ] = useState([]);
  const [talentAcquisitionOption, setTalentAcquisitionOption] = useState([]);
  const [collapsible, setCollapsible] = useState({
    city: false,
    state: false,
    country: false,
    // candidateLocation: false,
    experienceLevel: false,
    educations: false,
    skills: false,
    keywordSearch: false,
    certifications: false,
    workplaceType: false,
    jobTitle: false,
    jobType: false,
    employers: false,
    jobCategory: false,
    payRate: false,
    resumeLastUpdate: false,
    referralStatus: false,
    workAuthorisation: false,
    subscriptionStatus: false,
    linkedinProfile: false,
    tags: false,
    sourceChannel: false,
    relocationPreference: false,
    recruiterName: false,
    dispositionStatus: false,
    internationalPlacement: false,
    nursingSpecialty: false,
    talentAcquisition: false,
  });

  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCertification, setSelectedCertification] = useState("");
  const [selectedNursingSpecialty, setSelectedNursingSpecialty] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedExperience, setSelectedExperience] = useState([]);
  const [tagOptions, setTagOptions] = useState([]);
  const [sourceChannelsOptions, setSourceChannelsOptions] = useState([]);

  const additionalVisaStatus = [
    { value: "Not Required", label: "Not Applicable" },
    { value: "Need Sponsorship", label: "Need Sponsorship" },
  ];
  const subscriptionStatus = [
    { value: "Subscribed", label: "Subscribed" },
    { value: "Unsubscribed", label: "Unsubscribed" },
  ];

  const combinedVisaStatus = [...visaStatus, ...additionalVisaStatus];
  const uniqueVisaStatus = Array.from(
    new Set(combinedVisaStatus.map(JSON.stringify))
  ).map(JSON.parse);

  const visaStatusOptions = uniqueVisaStatus;

  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [certificationsOptions, setCertificationsOptions] = useState([]);
  const [licenseStateOptions, setLicenseStateOptions] = useState([]);
  const [nursingSpecialtyOptions, setNursingSpecialtyOptions] = useState([]);

  const {
    tagLoading,
    tagList,
    jobTitleData,
    jobTitleLoading,
    stateLoading,
    stateData,
    countryLoading,
    countryData,
    sourceLoading,
    sourceData,
    certificationData,
    certificationLoading,
    nursingSpecialtyData,
    nursingSpecialtyLoading,
    licenseStateData,
    licenseStateLoading,
    recruiterDataLoading,
  } = useSelector(
    (state) => ({
      tagList: state.tagData.tagData,
      tagLoading: state.tagData.loading,
      jobTitleLoading: state.designationData.loading,
      jobTitleData: state.designationData.designationData,
      stateLoading: state.stateData.loading,
      stateData: state.stateData.stateData,
      countryLoading: state.countryData.loading,
      countryData: state.countryData.countryData,
      sourceLoading: state.sourceData.loading,
      sourceData: state.sourceData.sourceData,
      certificationData: state.certificationFilter.certificationData,
      certificationLoading: state.certificationFilter.loading,
      nursingSpecialtyData: state.specialtyData.nursingSpecialtyData,
      nursingSpecialtyLoading: state.specialtyData.loading,
      licenseStateData: state.licenseStateData.licenseStateData,
      licenseStateLoading: state.licenseStateData.loading,
      recruiterData: state.recruiterFilterData.recruiterData,
      recruiterDataLoading: state.recruiterFilterData.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    setInternationalPlacementOption(
      countryList.map((e) => ({ value: e, label: e }))
    );
    setTalentAcquisitionOption(
      talentAcquisitionJobType.map((e) => ({ value: e, label: e }))
    );
  }, []);

  const handleFilterToggle = (filter) => {
    setCollapsible({
      candidateLocation: false,
      experienceLevel: false,
      educations: false,
      skills: false,
      keywordSearch: false,
      certifications: false,
      workplaceType: false,
      jobTitle: false,
      jobType: false,
      employers: false,
      jobCategory: false,
      payRate: false,
      resumeLastUpdate: false,
      referralStatus: false,
      workAuthorisation: false,
      subscriptionStatus: false,
      linkedinProfile: false,
      tags: false,
      sourceChannel: false,
      relocationPreference: false,
      recruiterName: false,
      dispositionStatus: false,
      internationalPlacement: false,
      nursingSpecialty: false,
      talentAcquisition: false,
      [filter]: !collapsible[filter],
    });
  };

  const handleAPICalls = (filter) => {
    if (filter == "tags") {
      const payload = {
        currentPage: 1,
        limit: 10,
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentTagList(payload));
    }
    if (filter == "sourceChannel") {
      let payload = {
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentSourceList(payload));
    }
    if (filter == "jobTitle") {
      const payload = {
        searchText: "",
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentDesignationList(payload));
    }
    if (filter == "state") {
      const payload = {
        text: "",
        filterParams: { ...filterParams, country: "" },
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentStateList(payload));
    }
    if (filter == "country") {
      const payload = {
        text: "",
        filterParams: { ...filterParams, state: "" },
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentCountryList(payload));
    }

    if (filter == "certifications") {
      const payload = {
        text: "",
        filterParams: filterParams,
      };
      dispatch(getTalentCertificationList(payload));
    }
    if (filter == "licenseState") {
      const payload = {
        text: "",
        filterParams: filterParams,
      };
      dispatch(getTalentLicenseStateList(payload));
    }
    if (filter == "nursingSpecialty") {
      const payload = {
        text: "",
        filterParams: filterParams,
      };
      dispatch(getTalentNursingSpecialtyList(payload));
    }

    if (filter == "recruiterName") {
      const payload = {
        currentPage: 1,
        limit: 5,
        searchText: "",
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentRecruiterList(payload));
    }
  };

  const fetchJobTitles = async (searchValue, filter) => {
    if (filter == "jobTitle" && collapsible[filter]) {
      const payload = {
        searchText: searchValue ?? "",
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentDesignationList(payload));
    }
  };

  const fetchStates = async (searchValue) => {
    if (collapsible.state) {
      const payload = {
        text: searchValue ?? "",
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentStateList(payload));
    }
  };

  const fetchCountries = async (searchValue) => {
    if (collapsible.country) {
      const payload = {
        text: searchValue ?? "",
        filterParams: filterParams,
        isFrom:
          isFrom == "high5talentpool"
            ? "High5"
            : isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      dispatch(getTalentCountryList(payload));
    }
  };

  const fetchCertification = async (searchValue) => {
    if (collapsible.certifications) {
      const payload = {
        text: searchValue ?? "",
        filterParams: filterParams,
      };
      dispatch(getTalentCertificationList(payload));
    }
  };

  const fetchLicenseState = async (searchValue) => {
    if (collapsible.certifications) {
      const payload = {
        text: searchValue ?? "",
        filterParams: filterParams,
      };
      dispatch(getTalentLicenseStateList(payload));
    }
  };

  const fetchNursingSpecialty = async (searchValue) => {
    if (collapsible.nursingSpecialty) {
      const payload = {
        text: searchValue ?? "",
        filterParams: filterParams,
      };
      dispatch(getTalentNursingSpecialtyList(payload));
    }
  };

  useEffect(() => {
    if (tagList && tagList.tagsData.length > 0) {
      setTagOptions(
        tagList.tagsData.map((item) => {
          return { value: item, label: item };
        })
      );
    }
    if (jobTitleData && jobTitleData?.designationData?.length > 0) {
      setJobTitleOptions(
        jobTitleData?.designationData?.map((item) => {
          return {
            value: item.designation,
            label: item.designation + " " + `(${item.TotalCount})`,
          };
        })
      );
    }
    if (stateData) {
      const formattedStates = stateData?.map((item) => ({
        label: item.state + " " + `(${item.talentCount})`,
        value: item.state,
        count: item.talentCount,
      }));
      setStateOptions(formattedStates);
    }
    if (countryData) {
      const formattedCountries = countryData?.map((item) => ({
        label: item.country + " " + `(${item.talentCount})`,
        value: item.country,
        count: item.talentCount,
      }));
      setCountryOptions(formattedCountries);
    }
    if (sourceData && Object.keys(sourceData).length > 0) {
      let sourceArray = [];

      for (const key in sourceData) {
        if (Object.hasOwnProperty.call(sourceData, key)) {
          const value = sourceData[key];
          sourceArray = [
            ...sourceArray,
            {
              label: `${key} (${value})`,
              value: key,
            },
          ];
        }
      }
      setSourceChannelsOptions(sourceArray);
    }
    if (certificationData && certificationData?.length > 0) {
      const formattedCountries = certificationData?.map((item) => ({
        label: item.certificate + " " + `(${item.talentCount})`,
        value: item.certificate,
        count: item.talentCount,
      }));
      setCertificationsOptions(formattedCountries);
    }
    if (licenseStateData && licenseStateData?.length > 0) {
      const formattedCountries = licenseStateData?.map((item) => ({
        label: item.License,
        value: item.License,
        count: item.talentCount,
      }));
      setLicenseStateOptions(formattedCountries);
    }
    if (
      nursingSpecialtyData &&
      Array.isArray(nursingSpecialtyData) &&
      nursingSpecialtyData?.length > 0
    ) {
      const formattedCountries = nursingSpecialtyData?.map((item) => ({
        label: item.NursingSpecialty + " " + `(${item.talentCount})`,
        value: item.NursingSpecialty,
        count: item.talentCount,
      }));
      setNursingSpecialtyOptions(formattedCountries);
    }
  }, [
    tagList,
    jobTitleData,
    countryData,
    stateData,
    certificationData,
    licenseStateData,
    nursingSpecialtyData,
    sourceData,
  ]);

  const filterLength =
    selectedData?.dispositionStatus?.length +
    selectedData?.subscriptionStatus?.length +
    selectedData?.tags?.length +
    selectedData?.sourceChannel?.length +
    selectedData?.workAuthorisation?.length +
    selectedData?.experienceLevel?.length +
    selectedData?.educations?.length +
    (selectedData?.country ? 1 : 0) +
    (selectedData?.state ? 1 : 0) +
    selectedData?.address?.length +
    selectedData?.jobTitle?.length +
    selectedData?.jobType?.length +
    selectedData?.recruiterName?.length +
    selectedData?.certifications?.length +
    selectedData?.licenseStates?.length +
    selectedData?.nursingSpecialty?.length +
    selectedData?.internationalPlacement?.length +
    selectedData?.talentAcquisition?.length +
    inputList?.term1?.length +
    inputList?.term2?.length +
    inputList?.term3?.length +
    skillInputList?.term1?.length +
    skillInputList?.term2?.length +
    skillInputList?.term3?.length +
    isFilterApplied
      ? 1
      : 0;

  const recruiterFilter =
    getUserRole() !== "Recruiter"
      ? getTenantType() === 1
        ? ["38", "33", "42", "40", "43"]
        : ["42", "40"]
      : [];

  return (
    <>
      {tagLoading && <Loader />}
      <div className="card-flat">
        <div className="card-body">
          <div className="view-panel-header view-panel-header-sm">
            <div className="d-flex flex-row align-items-center justify-content-between">
              <div className="">
                <strong>Common Filter</strong>
              </div>
              <div className="px-3">
                <button
                  disabled={filterLength == 0}
                  type="button"
                  className="btn btn-text"
                  onClick={handleClear}
                >
                  <span className="font-bold">Clear All</span>
                </button>
              </div>
            </div>
            <hr />
          </div>
          <div className="view-panel-body view-panel-body-sm">
            {/* Skills */}
            <BooleanFilter
              handleFilterToggle={handleFilterToggle}
              // setFilterEnabled={setKeywordSearchEnabled}
              filterEnabled={collapsible.skills}
              filterName={"Skills"}
              selectedData={selectedData}
              filterStatus={"skills"}
              inputList={skillInputList}
              setInputList={setSkillInputList}
              applyFilter={applyFilter}
              // options={skillsOptions}
            />
            {/* Keyword Search */}
            <BooleanFilter
              handleFilterToggle={handleFilterToggle}
              // setFilterEnabled={setKeywordSearchEnabled}
              filterEnabled={collapsible.keywordSearch}
              filterName={"Keywords"}
              selectedData={selectedData}
              filterStatus={"keywordSearch"}
              inputList={inputList}
              setInputList={setInputList}
              applyFilter={applyFilter}
              // options={skillsOptions}
            />

            {/*Location*/}
            <LocationFilter
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              cityEnabled={collapsible.city}
              stateEnabled={collapsible.state}
              countryEnabled={collapsible.country}
              filterStatus={"candidateLocation"}
              stateArr={stateOptions}
              countryArr={countryOptions}
              selectedCountry={selectedCountry}
              setSelectedCountry={setSelectedCountry}
              selectedCity={selectedCity}
              setSelectedCity={setSelectedCity}
              fetchCountries={fetchCountries}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              fetchStates={fetchStates}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoadingCountry={countryLoading}
              isLoadingState={stateLoading}
              filterParams={filterParams}
              setFilterParams={setFilterParams}
            />

            {/* Experience Level */}

            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.experienceLevel}
              filterName={"Experience Level"}
              filterStatus={"experienceLevel"}
              options={experienceOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              selected={selectedExperience}
              setSelected={setSelectedExperience}
              applyFilter={applyFilter}
              isLoading={false}
              loading={loading}
              talentCountloading={talentCountloading}
            />

            {/* Job Type */}
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.jobType}
              filterStatus={"jobType"}
              filterName={"Job Type"}
              options={jobTypeOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
              loading={loading}
              talentCountloading={talentCountloading}
            />

            {/* Job Title */}
            <DDMS
              // fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.jobTitle}
              filterStatus={"jobTitle"}
              filterName={"Job Title"}
              options={jobTitleOptions}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              isMulti={true}
              fetchOptions={fetchJobTitles}
              applyFilter={applyFilter}
              hideDropdown={true}
              isLoading={jobTitleLoading}
              loading={loading}
              talentCountloading={talentCountloading}
            />

            {/* Education Level */}
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.educations}
              filterStatus={"educations"}
              filterName={"Highest Education Level"}
              options={educationOptions}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
              loading={loading}
              talentCountloading={talentCountloading}
            />

            {/* Pay Rate */}
            <PayRateFilter
              handleFilterToggle={handleFilterToggle}
              filterEnabled={collapsible.payRate}
              filterStatus={"payRate"}
              filterName={"Pay Rate"}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
              isFilterApplied={isFilterApplied}
              setIsFilterApplied={setIsFilterApplied}
              min={min}
              max={max}
              setMax={setMax}
              setMin={setMin}
            />

            {/* Source Channel */}
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.sourceChannel}
              filterName={"Source Channel"}
              options={sourceChannelsOptions}
              filterStatus={"sourceChannel"}
              isMulti={false}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={sourceLoading}
              hideDropdown={true}
              loading={loading}
              talentCountloading={talentCountloading}
            />

            {/* Tags */}
            {TAGS_FILTER_FOR.includes(getUserRole()) && (
              <DDMS
                fetchOptions={() => {}}
                handleFilterToggle={handleFilterToggle}
                handleAPICalls={handleAPICalls}
                filterEnabled={collapsible.tags}
                filterStatus={"tags"}
                filterName={"Tags"}
                options={tagOptions}
                isMulti={true}
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                applyFilter={applyFilter}
                isLoading={false}
                hideDropdown={true}
                loading={loading}
                talentCountloading={talentCountloading}
              />
            )}

            {/* Work Authorisation */}
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.workAuthorisation}
              filterStatus={"workAuthorisation"}
              filterName={"Work Authorisation"}
              options={visaStatusOptions}
              countries={internationalPlacementOption}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
              askCountry={true}
              loading={loading}
              talentCountloading={talentCountloading}
            />
            {/* Subscription Status */}
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.subscriptionStatus}
              filterStatus={"subscriptionStatus"}
              filterName={"Subscription Status"}
              options={subscriptionStatus}
              isMulti={false}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
              hideDropdown={true}
              loading={loading}
              talentCountloading={talentCountloading}
            />
            <hr />
            <div className="view-panel-header view-panel-header-sm">
              <div className="d-flex flex-row align-items-center justify-content-between">
                <div className="">
                  <strong>Additional Filter</strong>
                </div>
              </div>
              <hr />
            </div>
            {/* Certifications */}
            {/* <Suggestion
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              setCandidateLocationEnabled={collapsible.certifications}
              candidateLocationEnabled={collapsible.certifications}
              filterName={"Certification/License"}
              filterStatus={"certifications"}
              stateArr={stateOptions}
              countryArr={certificationsOptions}
              licenseStateArr={licenseStateOptions}
              selectedCountry={selectedCertification}
              setSelectedCountry={setSelectedCertification}
              fetchCountries={fetchCertification}
              fetchLicenseState={fetchLicenseState}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              fetchStates={fetchStates}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoadingCountry={certificationLoading}
              isLoadingLS={licenseStateLoading}
              isLoadingState={stateLoading}
              // options={skillsOptions}
            /> */}

            {/* Nursing Specialty */}
            {/* <Suggestion
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              setCandidateLocationEnabled={collapsible.nursingSpecialty}
              candidateLocationEnabled={collapsible.nursingSpecialty}
              filterName={"Specialties"}
              filterStatus={"nursingSpecialty"}
              stateArr={stateOptions}
              countryArr={nursingSpecialtyOptions}
              selectedCountry={selectedNursingSpecialty}
              setSelectedCountry={setSelectedNursingSpecialty}
              fetchCountries={fetchNursingSpecialty}
              selectedState={selectedState}
              setSelectedState={setSelectedState}
              fetchStates={fetchStates}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoadingCountry={nursingSpecialtyLoading}
              isLoadingState={stateLoading}
              // options={skillsOptions}
            /> */}

            {/*Disposition Status */}
            <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.dispositionStatus}
              filterName={"Disposition Status"}
              filterStatus={"dispositionStatus"}
              options={dispositionOptions.filter(
                (item) => !recruiterFilter.includes(item.value)
              )}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              isMulti={true}
              applyFilter={applyFilter}
              isLoading={false}
              loading={loading}
              talentCountloading={talentCountloading}
            />
            {/* International Placement */}
            {/* <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.internationalPlacement}
              filterName={"International Placement (Countries)"}
              filterStatus={"internationalPlacement"}
              options={internationalPlacementOption}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
              loading={loading}
              talentCountloading={talentCountloading}
            /> */}

            {/* Talent Acquisition */}
            {/* <DDMS
              fetchOptions={() => {}}
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.talentAcquisition}
              filterName={"Talent Acquisition (Job Type)"}
              filterStatus={"talentAcquisition"}
              options={talentAcquisitionOption}
              isMulti={true}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={false}
              loading={loading}
              talentCountloading={talentCountloading}
            /> */}

            {/* Recruiter Name */}
            <SearchFilter
              handleFilterToggle={handleFilterToggle}
              handleAPICalls={handleAPICalls}
              filterEnabled={collapsible.recruiterName}
              filterName={"Recruiter Name"}
              filterStatus={"recruiterName"}
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
              isLoading={recruiterDataLoading}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
