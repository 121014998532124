import React, { useState, useMemo } from "react";
import Dropzone from "react-dropzone";
import { ExcelRenderer } from "react-excel-renderer";
import candidateExcel from "../../../docs/Candidate_Template.xlsx";
// import Table from 'react-bootstrap/Table'
import { getUserTenant, getCandidateId } from "../../../service/AuthService";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import {
  getUserTenantID,
  getCandidateName,
} from "../../../service/AuthService";
import { domain } from "../../../constants/Constants";
import Loader from "../../Loader/Loader";
import buttonLoader from "../../../images/button-loader.gif";
function ExcelUpload(props) {
  const [rows, setRows] = useState();
  const [fileName, setFileName] = useState("");
  const [uploaded, setUploaded] = useState(false);
  const [data, setData] = React.useState([]);
  const [originalData] = React.useState(data);
  const [skipPageReset, setSkipPageReset] = React.useState(false);
  const [errCount, setErrCount] = useState(0);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loadExisting, setLoadExisting] = useState(true);
  const [existingCandidateList, setExistingCandidateList] = useState([]);

  const fileChange = (file) => {
    setErrCount(0);
    setFileName("");
    var re = /(?:\.([^.]+))?$/;

    var ext = re.exec(file.name)[1]; // "

    if (ext === "xlsx") {
      setFileName(file.name);
      setErrCount(0);
      //	console.log(file)
      ExcelRenderer(file, (err, res) => {
        if (err) {
          // console.log(err);
          setUploaded(false);
        } else {
          res.rows.splice(0, 1);
          //	console.log(res.rows)
          let tempcount = 0;
          const result = res.rows.map((eachrow, index) => {
            let err = 0;
            let errMessage = "";
            const [
              firstName,
              lastName,
              email,
              mobilePhone,
              primarySkills,
              jobTitle,
              experienceYear,
              experienceMonth,
              zipCode,
              city,
              state,
              country,
              preferredLocations,
            ] = eachrow;
            if (
              firstName === undefined ||
              lastName === undefined ||
              email === undefined
            ) {
              err = 1;
              errMessage = " Data Missing";
              //setErrCount(tempcount + 1)
              tempcount += 1;
            } else {
              //errMessage ="Already Exists"
              let isExists = existingCandidateList.filter(
                (row) => row.email == email
              );
              if (isExists.length > 0) {
                errMessage = "Already Exists";
                err = 1;
                tempcount += 1;
              } else {
                errMessage = "Passed";
                err = 0;
              }
            }

            return {
              err,
              errMessage,
              firstName,
              lastName,
              email,
              mobilePhone,
              primarySkills,
              jobTitle,
              experienceYear,
              experienceMonth,
              zipCode,
              city,
              state,
              country,
              preferredLocations,
            };
          });
          setErrCount(tempcount);
          setRows(result);
          setData(result);
          setUploaded(true);
        }
      });
    } else {
      toast.error("Please Upload .xlsx Files ");
    }
  };
  // const [data,setData] = useState ()

  const submitAddTalent = async (e) => {
    setSubmitClicked(true);
    setSubmitted(true);
    const usertenant = getUserTenant();
    const loggedinusername = getCandidateName();
    let today = new Date();
    const userid = getCandidateId();
    let responseData = data
      .filter((eachData) => eachData.err == 0)
      .map((eachData) => {
        const candidateID = Math.floor(Math.random() * 650000000) + 300000000;
        const result = {
          "@search.action": "upload",
          candidateID: `${candidateID}`,
          firstName: eachData.firstName,
          lastName: eachData.lastName,
          email: eachData.email,
          typeName: "tc",
          primarySkills:
            eachData.primarySkills &&
            eachData.primarySkills
              .split(",")
              .map((skill) => skill.toLowerCase().replace(/ /g, "")),
          //secondarySkills: eachData.secondarySkills && eachData.secondarySkills.split(',').map(skill => skill.toLowerCase().replace(/ /g, '')),
          mobilePhone: eachData.mobilePhone
            ? eachData.mobilePhone.toString()
            : "",
          candidateStatus: "active",
          isFresher: false,
          currentJobTitle: eachData.jobTitle ? eachData.jobTitle : "",
          experienceYear: eachData.experienceYear
            ? eachData.experienceYear.toString()
            : "",
          experienceMonth: eachData.experienceMonth
            ? eachData.experienceMonth.toString()
            : "",
          zipCode: eachData.zipCode ? eachData.zipCode.toString() : "",
          addressCity: eachData.city ? eachData.city : "",
          addressState: eachData.state ? eachData.state : "",
          country: eachData.country ? eachData.country : "",
          preferedLocations: eachData.preferredLocations
            ? [eachData.preferredLocations]
            : "",
          createdDate: today,
          updatedDate: today,
          createdByTenant: usertenant,
          candidateTenant: usertenant,
          createdByName: loggedinusername,
          createdBy: `${userid}`,
          domainName: domain,

          fullText: {
            firstName: eachData.firstName,
            lastName: eachData.lastName,
            candidatePrimarySkills:
              eachData.primarySkills &&
              eachData.primarySkills
                .split(",")
                .map((skill) => skill.toLowerCase().replace(/ /g, "")),
            //candidateSecondarySkills: eachData.secondarySkills && eachData.secondarySkills.split(',').map(skill => skill.toLowerCase().replace(/ /g, '')),
            createdByTenant: usertenant,
            tenent: usertenant,
          },
        };

        return result;
      });
    // call save api
    const result = await post("/talent/bulkupload", responseData);
    if (result.status == 200) {
      const successData = {
        name: ` `,
        email: "",
      };
      props.handleSuccessForm(successData);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 5000);
      setSubmitClicked(true);
      // toast.success("Records Inserted Successfully")
    } else {
      toast.error("Oops! Something went wrong");
    }
  };

  const gettenantcandidateEmails = async () => {
    setLoadExisting(true);
    const tenantid = getUserTenantID();
    const params = {
      tenantid,
    };
    try {
      const result = await post("/talent/gettalentemails", params);
      //setLoadExisting(true)
      if (result.status === 200) {
        setExistingCandidateList(result.data);
        setLoadExisting(false);
      } else {
        toast.error("Oops! Something went wrong");
        setLoadExisting(false);
      }
    } catch (error) {
      toast.error("Oops! Something went wrong");
      setLoadExisting(false);
    }
    //setExistingCandidateList
  };

  React.useEffect(() => {
    setSkipPageReset(false);
    gettenantcandidateEmails();
  }, []);

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...

  const uploadHandler = () => {
    setUploaded(true);
  };

  return (
    <>
      {loadExisting && <Loader />}
      <div>
        <div className="row">
          <div className="col-12">
            <div className="p-5 p-0-600">
              <h6>Step 1</h6>
              <p>
                <a href={candidateExcel} download className="btn">
                  Download the .xlxs template
                </a>
              </p>
              <h6>Step 2</h6>

              <div className="col-sm-12">
                <Dropzone onDrop={(file) => fileChange(file[0])}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <div className="talentuploadmaindiv">
                          <div className="talentuploadicon">
                            <label>
                              <i className="fas fa-cloud-upload-alt"></i>
                            </label>
                            <input {...getInputProps()} />
                          </div>
                          <div className="talentuploadtext">
                            {fileName
                              ? fileName
                              : "Click or Drag and Drop To Upload"}
                          </div>
                        </div>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>

              <div className="col-sm-12">
                {uploaded ? (
                  <div>
                    <div className=" d-flex">
                      <div>
                        {" "}
                        <h6>Step 3</h6> <p>Preview the uploaded result and add</p>
                      </div>
                      <div className="ml-auto">
                        {" "}
                        <h6>Activity </h6>{" "}
                        <p>
                          Total {data.length - errCount} of {data.length} valid
                          record found{" "}
                        </p>
                      </div>
                    </div>
                    <table class="table  ">
                      <thead>
                        <tr>
                          <th scope="col"> Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Primary Skills</th>
                          {/* <th scope="col">Secondary Skills######</th> */}
                          <th scope="col">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((eachvalue) => (
                          <tr
                            className={
                              eachvalue.err == 0
                                ? "table-primary"
                                : "table-danger"
                            }
                          >
                            <td>
                              {eachvalue.firstName} {eachvalue.lastName}{" "}
                            </td>

                            <td>{eachvalue.email}</td>
                            <td>{eachvalue.primarySkills}</td>
                            {/* <td>{eachvalue.secondarySkills}</td> */}
                            <td>{eachvalue.errMessage}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div class="modal-footer">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm"
                        disabled={submitClicked}
                        onClick={props.closeModal}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        disabled={data.length - errCount === 0}
                      >
                        {submitClicked === false && (
                          <span onClick={submitAddTalent}>Add</span>
                        )}
                        {submitClicked === true && (
                          <span v-if="submittingForm">
                            Adding
                            <img width="20px" alt="" src={buttonLoader} />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExcelUpload;
