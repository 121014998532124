import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { AsYouType } from "libphonenumber-js";
import EditUser from "../../components_v3/superAdmin/Dashboard/Users/EditUser";
import ActivateUser from "../../../components/superadmin/popups/ActivateUser";
import DeactiveUser from "../../../components/superadmin/popups/DeactiveUser";
import Avatar from "react-avatar";

function UserDetails(props) {
  const [openDropDownDiv, setOpenDropDownDiv] = useState(false);
  const [addusers, setAddUsers] = useState(false);
  const [addEmployeeValue, setaddEmployeeValue] = useState(false);
  const [DeleteRequestValue, setDeleteRequestButton] = useState(false);
  const [enableUserValue, setEnableUserButton] = useState(false);

  const close = () => {
    props.closeModal(true);
  };

  const openDropDown = () => {
    if (openDropDownDiv === true) {
      setOpenDropDownDiv(false);
    } else {
      setOpenDropDownDiv(true);
    }
  };

  const DeleteRequestClose = (val) => {
    setDeleteRequestButton(false);
    if (val) {
      props.closeModal();
    }
  };

  const EnableUserClose = (val) => {
    setEnableUserButton(false);
    if (val) {
      props.closeModal();
    }
  };

  const DeleteRequestFun = () => {
    setDeleteRequestButton(true);
  };

  const EnabletenantFun = () => {
    setEnableUserButton(true);
  };

  const addUsers = () => {
    setAddUsers(true);
  };

  const closePopUP = () => {
    setAddUsers(false);
    props.closeModal();
  };

  return (
    <>
      <div class="view-panel show">
        <div class="view-panel-header">
          <div class="px-3 py-2">
            <button type="button" onClick={close} class="btn btn-icon">
              <i aria-hidden="true" class="fal fa-times"></i>
            </button>
          </div>
          <div class="px-3 mb-3">
            <div class="d-flex align-items-start flex-wrap-reverse">
              <div class=" d-flex ">
                <Avatar size="50" name={props.employee.name} round="80px" />
                <div class="ml-3">
                  <h5 class="mr-2 mb-2 ">{props.employee.name}</h5>
                  <div class="mb-2">
                    <span class="tag tag-blue3">
                      {props.employee.type === "CustomerSuccess"
                        ? "High5 Admin"
                        : props.employee.type === "High5Coordinator"
                        ? "Customer Success Manager"
                        : props.employee.type == "CommunityManager"
                        ? "Marketplace Manager"
                        : props.employee.type}
                    </span>
                  </div>
                </div>
              </div>
              <div class="ml-auto d-flex align-items-center">
                <div>
                  {props.employee.status == "Deactivated" ? (
                    <button
                      type="button"
                      class="btn btn-sm"
                      onClick={(e) => {
                        EnabletenantFun();
                      }}
                    >
                      <i class="fa fa-key mr-2" aria-hidden="true"></i> Activate
                    </button>
                  ) : (
                    <div class="btn-group btn-group-sm btn-split">
                      <button
                        type="button"
                        class="btn btn-sm"
                        onClick={addUsers}
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={openDropDown}
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        class="btn btn-sm dropdown-toggle dropdown-toggle-split"
                      >
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      {openDropDownDiv && (
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          style={{
                            display: "revert",
                            right: "0px",
                            top: "30px",
                          }}
                        >
                          <button
                            type="button"
                            class="dropdown-item"
                            onClick={(e) => {
                              DeleteRequestFun();
                            }}
                          >
                            <i class="fa fa-trash mr-2" aria-hidden="true"></i>{" "}
                            Deactivate
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="view-panel-body">
          <div class="mb-2">
            <button type="button" class="btn btn-collapsible">
              <i class="fal fa-angle-down fa-fw" aria-hidden="true"></i>
              <span>
                <small>General</small>
              </span>
            </button>
          </div>
          <div class="mb-4 ml-4">
            <div class="d-flex mb-2 pl-1">
              <div>
                <i class="fas fa-fw fa-building" aria-hidden="true"></i>
              </div>
              <div class="ml-2">
                <p class="mb-0">{props.employee.name} </p>
                <p class="mb-0">
                  <small>Name</small>
                </p>
              </div>
            </div>
            <div class="d-flex mb-2 pl-1">
              <div>
                <i class="fas fa-fw fa-envelope" aria-hidden="true"></i>
              </div>
              <div class="ml-2">
                <p class="mb-0">{props.employee.email} </p>
                <p class="mb-0">
                  <small>Email</small>
                </p>
              </div>
            </div>
            {props.employee?.mobilePhone && (
              <div class="d-flex mb-2 pl-1">
                <div>
                  <i class="fas fa-fw fa-phone-alt" aria-hidden="true"></i>
                </div>{" "}
                <div class="ml-2">
                  <p class="mb-0">
                    {new AsYouType().input(
                      props.employee?.mobilePhone[0] === "+"
                        ? props.employee?.mobilePhone
                        : `+${props.employee?.mobilePhone}`
                    )}
                  </p>{" "}
                  <p class="mb-0">
                    <small>Contact no</small>
                  </p>
                </div>
              </div>
            )}
            {props.employee?.city && (
              <div class="d-flex mb-2 pl-1">
                <div>
                  <i class="fas fa-fw fa-map-pin" aria-hidden="true"></i>
                </div>{" "}
                <div class="ml-2">
                  <p class="mb-0">{props.employee?.city} </p>{" "}
                  <p class="mb-0">
                    <small>City</small>
                  </p>
                </div>
              </div>
            )}
            <div class="d-flex mb-2 pl-1">
              <div>
                <i class="fas fa-fw fa-map" aria-hidden="true"></i>
              </div>{" "}
              <div class="ml-2">
                <p class="mb-0">{props.employee?.country}</p>{" "}
                <p class="mb-0">
                  <small>Country</small>
                </p>
              </div>
            </div>
            {/* <hr />
            <div class="mb-2 pl-1">
              <p class="mb-1">
                <strong>Tenants</strong>
              </p>{" "}
              <ul class="ml-3">
                <li>Logitech</li> <li>TalentYeti</li>
              </ul>
            </div> */}
          </div>
        </div>

        <Modal show={DeleteRequestValue} size="delete">
          <DeactiveUser
            handleSuccess={DeleteRequestClose}
            employeeDelete={"employeedelete"}
            employee={props.employee}
            users={"users"}
            getusersData={props.getusersData}
          />
        </Modal>
        <Modal show={enableUserValue} size="delete">
          <ActivateUser
            handleSuccess={EnableUserClose}
            getusersData={props.getusersData}
            isFrom={"EADash"}
            employeeDelete={"employeedelete"}
            employee={props.employee}
            users={"users"}
          />
        </Modal>
        <Modal show={addusers} size="lg">
          <EditUser
            closeAdd={closePopUP}
            userlist={props.employee}
            getusersData={props.getusersData}
          />
        </Modal>
      </div>
    </>
  );
}
export default UserDetails;
