import {
  GET_SUB_TALENT_LIST_BEGIN,
  GET_SUB_TALENT_LIST_SUCCESS,
  GET_SUB_TALENT_LIST_FAILURE,
} from "../../actions/talent/cmSubmittedTalents";

const initialState = {
  loading: true,
  talentData: null,
};

const getSubmittedTalentListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SUB_TALENT_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        talentData: null,
      };
    case GET_SUB_TALENT_LIST_SUCCESS:
      return {
        ...state,
        talentData: payload,
        loading: false,
      };
    case GET_SUB_TALENT_LIST_FAILURE:
      return {
        ...state,
        talentData: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default getSubmittedTalentListReducer;
