import {
  GET_FORM_ANALYTICS_BEGIN,
  GET_FORM_ANALYTICS_SUCCESS,
  GET_FORM_ANALYTICS_FAILURE,
} from "../../actions/analytics/getAnalyticsData";

const initialState = {
  formAnalyticsData: null,
};

const getFormReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_FORM_ANALYTICS_BEGIN:
      return {
        ...state,
        formAnalyticsData: null,
      };
    case GET_FORM_ANALYTICS_SUCCESS:
      return {
        ...state,
        formAnalyticsData: payload,
      };
    case GET_FORM_ANALYTICS_FAILURE:
      return {
        ...state,
        formAnalyticsData: null,
      };
    default:
      return state;
  }
};

export default getFormReducer;
