import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAllBroadcastTemplates } from "../../../../../../actions/broadcast/getBroadCastTemplates";

import ReactQuill from "react-quill";
import Loader from "../../../../commoncomponents/Loader/Loader";
import Tabs from "../Tabs";
import ModalView from "../../../../commoncomponents/modal/Modal";
import RecipientsTable from "../RecipientsTable";
import { FaCommentAlt, FaUserPlus } from "react-icons/fa";
import SentSMS from "./SentSMS";
import Inbox from "./Inbox";
import SMSTemplates from "./SMSTemplates";
import { FaPaperPlane } from "react-icons/fa";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AddRecipients from "../Recipients/AddRecipients";
import { FetchLastMessageSentByUser } from "../../../../../../actions/communication/Communication";
import moment from "moment";
// import EmailTemplates from "./EmailTemplates";
// import SentMails from "./SentMails";

const SMS = ({
  selectedTalents,
  smsContent,
  setSmsContent,
  setErrorData,
  errorData,
  handleConfirmation,
  previewEmail,
  setPreviewEmail,
  isFrom,
  handleBulkCommunication,
  setShowJobModal,
  handleAddRecipients,
  selectedRecipients,
  setSelectedRecipients,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    templatesData,
    templatesDataLoading,
    lastMessageLoading,
    lastMessage,
  } = useSelector(
    (state) => ({
      templatesData: state.broadCastTemplatesReducer.templatesData,
      templatesDataLoading:
        state.broadCastTemplatesReducer.templatesDataLoading,
      lastMessageLoading: state.communicationReducer.lastMessageLoading,
      lastMessage: state.communicationReducer.lastMessage,
    }),
    shallowEqual
  );
  const [showAllRecipients, setShowAllRecipients] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // const [activeTab, setActiveTab] = useState(
  //   isFrom === "topNavbar" ? "inbox" : "compose"
  // );
  const [activeTab, setActiveTab] = useState("compose");
  const [isSending, setIsSending] = useState(false);

  const [loading, setLoading] = useState(false);
  const [addRecipientsModal, setAddRecipientsModal] = useState(false);

  const [selectedRecruiter, setSelectedRecruiter] = useState([]);
  const [broadCastTemplates, setBroadCastTemplates] = useState([]);

  useEffect(() => {
    if (selectedTalents && selectedTalents?.length > 0) {
      setSelectedRecipients([...selectedTalents]);
    }
    if (selectedTalents && selectedTalents?.length === 1) {
      dispatch(
        FetchLastMessageSentByUser({
          messageTo:
            selectedTalents[0]?.homePhoneCode + selectedTalents[0]?.homePhone,
        })
      );
    }
    dispatch(fetchAllBroadcastTemplates());
  }, []);

  useEffect(() => {
    if (templatesData) setBroadCastTemplates(templatesData);
  }, [templatesData]);

  useEffect(() => {
    if (isSending) {
      setTimeout(() => {
        setIsSending(false);
      }, 2000);
    }
  }, [isSending]);

  const [charCount, setCharCount] = useState(smsContent.length); // State to track character count

  // const handleContentChange = (value) => {
  //   // Update the character count and content
  //   if (value.length <= 622) {
  //     setSmsContent(value);
  //     setCharCount(value.length);
  //   } else {
  //     // Truncate the content if it exceeds the limit
  //     console.log(value);
  //     setSmsContent(value.slice(0, 622));
  //     setCharCount(622);
  //   }
  // };

  const handleCandidateRemove = (item) => {
    setSelectedRecipients((prev) =>
      prev.filter((candidate) => candidate.userId !== item.userId)
    );
  };
  const handleNavigate = () => {
    setShowJobModal(false);
    history.push("/high5Hire/talents");
  };
  const handleClear = () => {
    setSmsContent("");
  };
  return (
    <>
      <Container style={{ fontSize: "14px", zIndex: "3" }}>
        {(loading || templatesDataLoading) && <Loader />}

        <Tabs
          from={"SMS"}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          isFrom={isFrom}
        />

        <Row style={{ minHeight: "60vh", fontSize: "14px", marginTop: "5px" }}>
          {activeTab === "compose" &&
            (addRecipientsModal ? (
              <AddRecipients
                setAddRecipientsModal={setAddRecipientsModal}
                setSelectedRecipients={setSelectedRecipients}
                selectedRecipients={selectedRecipients}
              />
            ) : (
              <>
                <Col md={8} sm={12} lg={8}>
                  <div className="mb-3 mt-2">
                    <div className="mt-4">
                      {(!selectedRecipients ||
                        selectedRecipients ||
                        selectedRecipients?.length > 0) &&
                        isFrom !== "TalentProfileEdit" && (
                          <>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                setAddRecipientsModal(true);
                                setShowModal(true);
                              }}
                            >
                              {" "}
                              <FaUserPlus className="mr-2" /> Add Recipients
                            </button>
                          </>
                        )}
                    </div>
                    <div className="color">{errorData["recipient"]}</div>
                  </div>
                  <Row className="messageForm mb-5">
                    <Col sm={12} md={12}>
                      <div className="d-flex justify-content-between">
                        <label htmlFor="content" className="label mb-2">
                          Message Body* {/* Display remaining characters */}
                        </label>
                        <label className="label mb-2">{`( ${160 -
                          smsContent.length} Characters left out of 160 )`}</label>
                      </div>

                      <textarea
                        value={smsContent}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          if (inputValue.length <= 622) {
                            setSmsContent(inputValue);
                          }
                        }}
                        style={{
                          width: "100%",
                          minHeight: "120px",
                          overflowX: "scroll",
                          fontWeight: "normal",
                          border: "1px solid #ccc",
                          padding: "10px",
                          borderRadius: "0px",
                        }}
                        onKeyDown={(e) => {
                          if (
                            smsContent.length >= 622 &&
                            e.key !== "Backspace"
                          ) {
                            e.preventDefault();
                          }
                        }}
                        onPaste={(e) => {
                          e.preventDefault();
                          const pastedText = e.clipboardData.getData(
                            "text/plain"
                          );
                          const newText = smsContent + pastedText;
                          const trimmedText = newText.slice(0, 622);
                          setSmsContent(trimmedText);
                        }}
                      />

                      <div className="color">{errorData["msgContent"]}</div>
                    </Col>

                    <Col sm={12} md={12}>
                      <Row className="d-flex justify-content-between mt-3">
                        <Col
                          md={12}
                          sm={12}
                          className="d-flex justify-content-between"
                        >
                          <button
                            className="btn btn-sm btn-success align-items-right mt-2"
                            onClick={() => {
                              handleClear();
                            }}
                            disabled={isSending || smsContent === ""}
                          >
                            <i className="fa fa-eraser"></i> Clear
                          </button>
                          <button
                            onClick={() => {
                              handleConfirmation("sendSms");
                            }}
                            style={{ float: "right" }}
                            disabled={
                              isSending ||
                              smsContent === "" ||
                              !selectedRecipients ||
                              selectedRecipients?.length === 0
                            }
                            className="btn btn-sm btn-primary align-items-right mt-2"
                          >
                            <FaPaperPlane size={18} className="mr-2" /> Send
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  md={4}
                  sm={12}
                  lg={4}
                  style={{
                    borderLeft: "1px solid #ccc",
                    height: "80vh",
                    overflow: "scroll",
                  }}
                >
                  {/* <div className="mt-3 mb-2">
                    {!lastMessageLoading && selectedRecipients?.length === 1 && (
                      <>
                        {lastMessage?.length === 0 ? (
                          <span className="tag tag-orange1">
                            <i>No SMS ever sent</i>
                          </span>
                        ) : (
                          <span className="tag tag-green2">
                            <i>
                              Last SMS sent on :{" "}
                              {moment(lastMessage[0].createdDateTime).format(
                                "MM/DD/YYYY HH:MM"
                              )}
                            </i>
                          </span>
                        )}
                      </>
                    )}
                  </div> */}
                  <div>
                    {selectedRecipients?.length > 0 && (
                      <strong>
                        Selected
                        {selectedRecipients?.length > 1
                          ? " Recipients"
                          : " Recipient"}
                        {" : "}
                        {selectedRecipients?.length}
                      </strong>
                    )}
                  </div>
                  {/* 
                  <div className="recipient-user-list">
                    {selectedTalents?.length > 0 &&
                      selectedTalents?.slice(0, 5).map((item) => (
                        <span
                          className="tag tag-blue3 mb-1 mr-1"
                          title={item.email}
                        >
                          {item?.firstName + " " + item?.lastName}
                          <a
                            href="#"
                            className="font-black"
                            onClick={() => handleCandidateRemove(item)}
                          >
                            <i className="fas fa-times ml-2 "></i>
                          </a>
                        </span>
                      ))}

                    {selectedTalents?.length > 5 && (
                      <button type="button" class="btn btn-text">
                        <span>View all</span>
                      </button>
                    )}
                  </div> */}
                  <div className="recipient-user-list">
                    {selectedRecipients?.length > 0 &&
                      (showAllRecipients
                        ? selectedRecipients.map((item) => (
                            <span
                              className="tag tag-blue3 mb-1 mr-1"
                              title={item.email}
                              key={item.userId} // Add a unique key
                            >
                              {item?.firstName + " " + item?.lastName}
                              {isFrom !== "TalentProfileEdit" && (
                                <a
                                  href="#"
                                  className="font-black"
                                  onClick={() => handleCandidateRemove(item)}
                                >
                                  <i className="fas fa-times ml-2 "></i>
                                </a>
                              )}
                            </span>
                          ))
                        : selectedRecipients.slice(0, 5).map((item) => (
                            <span
                              className="tag tag-blue3 mb-1 mr-1"
                              title={item.email}
                              key={item.userId} // Add a unique key
                            >
                              {item?.firstName + " " + item?.lastName}
                              {isFrom !== "TalentProfileEdit" && (
                                <a
                                  href="#"
                                  className="font-black"
                                  onClick={() => handleCandidateRemove(item)}
                                >
                                  <i className="fas fa-times ml-2 "></i>
                                </a>
                              )}
                            </span>
                          )))}
                    {selectedRecipients?.length > 5 && !showAllRecipients && (
                      <button
                        type="button"
                        className="btn btn-text"
                        onClick={() => setShowAllRecipients(true)}
                      >
                        <span>View all</span>
                      </button>
                    )}
                    {selectedRecipients?.length > 5 && showAllRecipients && (
                      <button
                        type="button"
                        className="btn btn-text"
                        onClick={() => setShowAllRecipients(false)}
                      >
                        <span>View less</span>
                      </button>
                    )}
                  </div>
                </Col>
              </>
            ))}

          {/* {activeTab === "inbox" && (
            <div>
              <Inbox />
            </div>
          )} */}
          {activeTab === "sent" && (
            <SentSMS selectedRecipients={selectedRecipients} />
          )}
          {activeTab === "templates" && (
            <div>
              <SMSTemplates
                data={{
                  selectedTalents: selectedTalents,
                  setActiveTab: setActiveTab,
                  setSelectedOption: () => {},
                  setSmsContent: setSmsContent,
                }}
              />
            </div>
          )}
        </Row>

        {/* {previewEmail && (
          <ModalView
            modalSize={"lg"}
            modalTitle={"Email Preview"}
            isModalOpen={previewEmail}
            setModalClose={setPreviewEmail}
            isFrom="EmailBroadcast"
            modalFor="preview"
            data={{
              content: smsContent,
              selectedCandidates: selectedTalents.map((t) => t.email),
              selectedRecruiter: selectedRecruiter,
            }}
          />
        )} */}
      </Container>
    </>
  );
};

export default SMS;
