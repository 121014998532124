import React, { useState, useEffect } from "react";
import moment from "moment";
import { getUserTenant } from "../../../../../service/AuthService";
import { useDispatch } from "react-redux";
import {
  jobDetail,
  template,
  templateDetails,
} from "../../../../../actions/job/AddJob";

const TemplatePagination = ({
  setChooseTemplateDetails,
  publicTemplates,
  templateDetail,
  setTemplateDetails,
  totalCount,
  userCount,
  data,
  pageLimit,
  dataLimit,
  searchValue,
  searchData,
  setChooseJobDetails,
  setTemplateSelected,
  isFrom,
  setCreateWithTemplate,
  setCreateWithTemplateSCForm,
  setPoolData,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const dispatch = useDispatch();

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + dataLimit;
    let sortedData = searchValue ? [...searchData] : [...data]; // Create a copy of the data to avoid mutating the original array
    sortedData.sort((a, b) => {
      return moment(b.createdDate).valueOf() - moment(a.createdDate).valueOf();
    });
    sortedData = sortedData.slice(startIndex, endIndex);
    return sortedData;
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(data.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(data.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    setCurrentPage(1);
    // eslint-disable-next-line
  }, [dataLimit, searchData]);

  useEffect(() => {
    setPaginationArray(
      new Array(Math.ceil(data.length / dataLimit)).fill().map((_, i) => i + 1)
    );
    setPaginationGroup(
      new Array(Math.ceil(data.length / dataLimit))
        .fill()
        .map((_, i) => i + 1)
        .slice(0, pageLimit)
    );
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    } else {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const splitMatchedText = (str) => {
    const regExp = new RegExp(searchValue.trim(), "gi");
    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  const setJobDetails = (item) => {
    console.log("isFrom", isFrom);
    if (isFrom !== "SubCommunity") {
      dispatch(template(true));
      dispatch(jobDetail(item));
      setChooseTemplateDetails(item);
      // setChooseJobDetails(item);
      if (setTemplateSelected) {
        setTemplateSelected(true);
      }
    } else if (isFrom == "SubCommunity") {
      setCreateWithTemplate(false);
      setCreateWithTemplateSCForm(true);
      setPoolData( item );
    }
  };

  return (
    <>
      {false && (
        <div className="px-3 pt-0 table-cards-1024">
          <table className="table table-hoverable-cell table-cards-1024">
            <thead>
              <tr>
                <th scope="col">Template Name</th>
                <th scope="col">Category</th>
              </tr>
            </thead>
            {getPaginatedData().map((item) => (
              <tbody>
                <tr>
                  {/* <td dangerouslySetInnerHTML={{ __html: splitMatchedText(item.jobDetails.title)}}></td>                                */}
                  <td
                    dangerouslySetInnerHTML={{
                      __html: splitMatchedText(item?.templateName),
                    }}
                  ></td>
                  <td
                    dangerouslySetInnerHTML={{
                      __html: splitMatchedText(item?.category),
                    }}
                  ></td>
                  <td>
                    <span className="form-check form-switch">
                      <input
                        className="form-check-input ml-2"
                        type="checkbox"
                        onChange={() => setTemplateDetails(item)}
                        checked={
                          item?.templateName === templateDetail?.templateName
                        }
                      />
                    </span>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      )}
      {getPaginatedData().map((item, index) => (
        <div className="row mb-1" key={index}>
          <div className="col">
            <a
              href
              onClick={() => setJobDetails(item)}
              key={index}
              className="card d-flex flex-row align-items-stretch justify-content-between mb-1 p-relative"
            >
              <div className="card-body d-flex align-items-center">
                <div className="w-100 ml-2">
                  <div className="d-flex align-items-center mb-1">
                    <h6
                      className="mb-0 me-2"
                      dangerouslySetInnerHTML={{
                        __html: splitMatchedText(item?.templateName),
                      }}
                    ></h6>
                  </div>
                  <div className="font-regular font-primary font-gray1 text-truncate">
                    <div className="mt-1">
                      <span className="me-1">
                        <div className="tag tag-green3">
                          <strong>{item?.jobTitle}</strong>
                        </div>
                      </span>
                      <span className="me-1">
                        <div className="tag tag-blue3">
                          <strong>{item?.jobType || "Full Time"}</strong>
                        </div>
                      </span>
                    </div>
                    <span>
                      <i className="far fa-suitcase me-2 font-brandBlue" />
                      {item?.category}
                    </span>
                  </div>
                </div>
              </div>
              <div className="card-body font-regular d-flex flex-column align-items-end justify-content-center">
                {isFrom !== "SubCommunity" && <div className="mb-1">
                  <i className="far fa-clock mr-1" />
                  Last Used:{" "}
                  <span>
                    {item.lastUsed
                      ? moment(item?.lastUsed).format("MM/DD/YYYY")
                      : "Not yet used"}
                  </span>
                </div>}
                <div className="mb-1">
                  <i className="far fa-clock mr-1" />
                  Created Date:{" "}
                  <span>{moment(item?.createdDate).format("MM/DD/YYYY")}</span>
                </div>
              </div>
            </a>
          </div>
        </div>
      ))}
      {getPaginatedData().length === 0 && (
        <div>
          <div class="text-center p-3">
            <div class="avatar avatar-lg">
              <i class="fad fa-users-slash"></i>
            </div>
            <div class="mt-2">
              No records found {searchValue && "for " + searchValue}
            </div>
          </div>
        </div>
      )}
      <hr />

      {getPaginatedData().length > 0 && (
        <div className="d-flex p-2">
          <div className="w-100-370 mb-point5-370">
            Showing{" "}
            {(getPaginatedData().length < dataLimit
              ? getPaginatedData().length
              : dataLimit) + " "}
            of {publicTemplates ? totalCount : userCount}
          </div>
          <div class="ml-auto mx-auto-370 d-flex align-items-center">
            <button
              onClick={() => {
                setPageType("down");
                setCurrentPage((page) => page - 1);
              }}
              type="button"
              disabled={
                currentPage === 1 || getPaginatedData().length === 0
                  ? true
                  : false
              }
              className="btn btn-icon px-2"
            >
              <i className="fal fa-chevron-circle-left"></i>
            </button>

            {paginationGroup.map((item, index) => (
              <button
                key={index}
                type="button"
                className={
                  currentPage === item
                    ? "btn btn-text px-2 bg-secondary text-white"
                    : "btn btn-text px-2"
                }
                onClick={changePage}
              >
                {item}
              </button>
            ))}

            <button
              onClick={() => {
                setPageType("up");
                setCurrentPage((page) => page + 1);
              }}
              type="button"
              disabled={
                currentPage === paginationArray.slice(-1)[0] ||
                getPaginatedData().length === 0
                  ? true
                  : false
              }
              className="btn btn-icon px-2"
            >
              <i className="fal fa-chevron-circle-right"></i>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default TemplatePagination;
