import React, { useState, useEffect } from "react";
import moment from "moment";
import Avatar from "react-avatar";
import { countryObj } from "../../../constants/Constants";
import { CurrencySymbols } from "../../config/CurrencySymbols";

function RequestGridView(props) {
  let temporaryRequestList = useState([]);

  const [newRequestList, setnewRequestList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [requestTitleArray, setRequestTitleArray] = useState([]);
  const [pageType, setPageType] = useState("");
  let pageSize = 10;
  let dataSize = 12;

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % 5 === 0 && currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(currentPage - 1, currentPage + (pageSize - 1))
        );
      }
    } else {
      if (currentPage % 5 === 0 && currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(currentPage - 5, currentPage)
        );
      }
    }
  }, [currentPage]);

  useEffect(() => {
    if (props.originalStatus) {
      temporaryRequestList = props.requestListData.filter(function(e) {
        return e.jobStatus === props.originalStatus;
      });
      setnewRequestList(temporaryRequestList);
      setRequestTitleArray(
        new Array(Math.ceil(props.requestListData.length / dataSize))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(props.requestListData.length / dataSize))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageSize)
      );
    } else {
      setnewRequestList(props.requestListData);
      setRequestTitleArray(
        new Array(Math.ceil(props.requestListData.length / dataSize))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(props.requestListData.length / dataSize))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageSize)
      );
    }
  }, [props]);

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataSize - dataSize;
    const endIndex = startIndex + dataSize;
    return newRequestList.slice(startIndex, endIndex);
  };

  return (
    <>
      <div className="row">
        {getPaginatedData().map((request) => (
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex">
            <a
              href={`/high5Hire/requests/${request.jobId}`}
              className="card col card-tag-bottom mb-4 card-lg"
            >
              <div className="card-body pb-0">
                <div className="d-flex align-items-center">
                  <span className="mr-3">
                    <h6>{request.fullText.jobTitle} </h6>
                  </span>
                  {request.isHotJob && (
                    <span className="tag tag-coral mr-9">HOT</span>
                  )}
                </div>
                <div className="mb-2">
                  {request.jobStatus === "open" ? (
                    <span className="tag tag-green1">
                      {request.jobStatus?.charAt(0).toUpperCase() +
                        request.jobStatus?.slice(1)}
                    </span>
                  ) : request.jobStatus === "active" ? (
                    <span className="tag tag-orange1">
                      {request.jobStatus?.charAt(0).toUpperCase() +
                        request.jobStatus?.slice(1)}
                    </span>
                  ) : request.jobStatus === "expired" ? (
                    <span className="tag tag-font_accent">
                      {request.jobStatus?.charAt(0).toUpperCase() +
                        request.jobStatus?.slice(1)}
                    </span>
                  ) : (
                    <span className="tag tag-font_accent">
                      {request.jobStatus?.charAt(0).toUpperCase() +
                        request.jobStatus?.slice(1)}
                    </span>
                  )}
                  <i className="ml-1 far fa-suitcase me-2 font-brandBlue"></i>
                  <small className="font-regular font-primary">
                    {"  "}
                    {request.jobType}
                  </small>
                  <div className="d-flex mt-1">
                    <span className="d-flex align-items-center tag tag-blue2 font-regular font-primary font-gray1 mb-2">
                      <span className="font-regular font-primary font-gray1 mr-1">
                        Placement Fee:
                      </span>
                      {/* <i class="fa fa-credit-card-alt" aria-hidden="true"></i> */}
                      {/* <small className="font-regular font-primary font-gray1"> */}
                      {"  "}{" "}
                      {request.isFeePercentage
                        ? `${request.placementFee}%`
                        : `${
                            CurrencySymbols[request.placementCurrency]
                              ? CurrencySymbols[request.placementCurrency]
                              : request.placementCurrency
                          }${Number(request.placementFee).toLocaleString()}`}
                      {/* </small> */}
                    </span>
                  </div>
                </div>
                <hr className="mt-0 mb-2" />
                <p className="mb-1">
                  <i className="fal fa-fw fa-map-marker-alt mr-2"></i>
                  {request.isRemote === true ? (
                    <span>Remote</span>
                  ) : (
                    <span>
                      {request.location?.city &&
                        request.location?.state &&
                        request.location?.country && (
                          <>
                            <span className="font-regular font-primary font-gray1">
                              {request.location.city.length > 10
                                ? request.location.city.slice(0, 10) + ".."
                                : request.location.city}
                              , {request.location?.state},{" "}
                              {request.location?.country}
                            </span>
                          </>
                        )}
                    </span>
                  )}
                </p>
                <p className="mb-1">
                  {moment(request.expiresOn).diff(new Date(), "days") > 0 && (
                    <i className="far fa-clock me-2 font-brandBlue"></i>
                  )}
                  {/* {moment(request.expiresOn).diff(new Date(), "days") < 0 && (
                    <span className="tag tag-red1">
                      <strong> Expired </strong>
                    </span>
                  )} */}
                  {moment(request.expiresOn).diff(new Date(), "days") === 0 && (
                    <span className="tag tag-red1">
                      <strong> Expires Today </strong>
                    </span>
                  )}
                  {moment(request.expiresOn).diff(new Date(), "days") === 1 && (
                    <span className="tag tag-red1">
                      <strong> 1 Day Left To Fill </strong>
                    </span>
                  )}
                  {moment(request.expiresOn).diff(new Date(), "days") > 1 &&
                    (moment(request.expiresOn).diff(new Date(), "days") <= 5 ? (
                      <span className="tag tag-red1">
                        <strong>
                          {" "}
                          {moment(request.expiresOn).diff(
                            new Date(),
                            "days"
                          )}{" "}
                          Days Left To Fill{" "}
                        </strong>
                      </span>
                    ) : (
                      <span className="font-regular">
                        <strong>
                          {" "}
                          {moment(request.expiresOn).diff(
                            new Date(),
                            "days"
                          )}{" "}
                          Days Left To Fill{" "}
                        </strong>
                      </span>
                    ))}
                </p>
              </div>

              <div className="card-body py-2">
                {/* <div className="d-flex flex-row-reverse justify-content-end pl-2">
                  {request.candidateNames.length > 3 && (
                    <span className="avatar avatar-bordered ml-neg-10">
                      {" "}
                      {`${request.candidateNames.slice(3, 100).length}+`}{" "}
                    </span>
                  )}
                  <span>
                    {request.candidateNames.slice(0, 3).map((name, i) => (
                      <span
                        className="avatar avatar-pink1 avatar-bordered  ml-neg-10"
                        key={i}
                      >
                        {name.candidateFname.charAt().toUpperCase()}
                        {name.candidateLname.charAt().toUpperCase()}
                      </span>
                    ))}
                  </span>
                </div> */}
                <div className="d-flex flex-row-reverse justify-content-end mx-2">
                  {request.candidateNames.length > 3 && (
                    <span className="avatar avatar-bordered ml-neg-10">
                      {" "}
                      {`${request.candidateNames.slice(3, 100).length}+`}{" "}
                    </span>
                  )}
                  <span>
                    {request.candidateNames.slice(0, 3).map((name, i) => (
                      <span key={i}>
                        <Avatar
                          size="45"
                          name={name.candidateFname + " " + name.candidateLname}
                          round="80px"
                        />
                        {/* {name.candidateFname.charAt()}{name.candidateLname.charAt()} */}
                      </span>
                    ))}
                  </span>
                </div>
              </div>

              {request.totalSubmisions === 0 ? (
                <div className="tag tag-footer font-regular justify-content-center">
                  All Candidates Viewed
                </div>
              ) : (
                <div className="tag tag-footer tag-green3 justify-content-center">
                  {request.totalSubmisions === 1
                    ? `${request.totalSubmisions} New Candidate`
                    : `${request.totalSubmisions}  New Candidates`}
                </div>
              )}
            </a>
          </div>
        ))}
        {props.component === "HM" && getPaginatedData().length > 0 && (
          <div className="d-flex justify-content-end pt-4 px-1">
            <div className="d-flex">
              <div class="ml-auto mx-auto-370 d-flex align-items-center">
                <button
                  onClick={() => {
                    setPageType("down");
                    setCurrentPage((page) => page - 1);
                  }}
                  type="button"
                  disabled={currentPage === 1 ? true : false}
                  className="btn btn-icon px-2"
                >
                  <i className="fal fa-chevron-circle-left"></i>
                </button>
                {paginationGroup.map((item) => (
                  <button
                    type="button"
                    className={
                      currentPage === item
                        ? "btn btn-text px-2 bg-secondary text-white"
                        : "btn btn-text px-2"
                    }
                    onClick={changePage}
                  >
                    {item}
                  </button>
                ))}
                <button
                  onClick={() => {
                    setPageType("up");
                    setCurrentPage((page) => page + 1);
                  }}
                  type="button"
                  disabled={
                    currentPage === requestTitleArray.slice(-1)[0]
                      ? true
                      : false
                  }
                  className="btn btn-icon px-2"
                >
                  <i className="fal fa-chevron-circle-right"></i>
                </button>
              </div>
            </div>
          </div>
        )}
        {newRequestList.length === 0 && (
          <div class="text-center p-5">
            <div class="avatar avatar-lg">
              <i aria-hidden="true" class="fad fa-users-slash"></i>
            </div>
            <div class="mt-2">No records found</div>
          </div>
        )}
      </div>
    </>
  );
}

export default RequestGridView;
