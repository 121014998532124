import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Sort from "../../commoncomponents/sort/Index";
import { orderList } from "../../pagedata/PageData";
import moment from "moment";
import ProfileImage from "../../commoncomponents/profileimage/ProfileImage";
import RecSidePanel from "../../superAdmin/Dashboard/Recruiters/RecSidePanel";
import Pagination from "../../pagination/pagination";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import { post } from "../../../../service/ApiService";
import AssignCuratorFilter from "../AssignCuratorFilter/Index";

const AssignExistingCurators = ({
  recruiterData,
  dataTotal,
  currentPage,
  dataSize,
  handlePageChange,
  handleSizeChange,
  handleSearch,
  searchData,
  setCommonLoading,
  selectedValues,
  setSelectedValues,
  sortArray,
  setSortArray,
  applySort,
  fetchData,
  setSelectedData,
  selectedData,
  applyFilter,
  selectedTempValue,
  setSelectedTempValue,
  clientData,
  filterParams,
  openTenantPopUP,
  setopenTenantPopUP,
}) => {
  const [tenantObj, settenantObj] = useState({});
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);
  //sort
  const sortObject = [
    {
      id: 1,
      label: "Name",
      value: "firstName",
      icon: "fal fa-fw fa-signature  mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Location",
      value: "country",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Email",
      value: "email",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
  ];

  useEffect(() => {
    const tempArray = sortObject.map((item) => item.value);
    setSorttype(tempArray);
  }, []);

  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),

    indicatorSeparator: () => ({
      display: "none", // Hide the indicator separator
    }),
  };

  const openTenantPopUPFun = (item) => {
    setopenTenantPopUP(true);
    settenantObj(item);
  };

  const handleCheckBox = (e, item) => {
    if (clientData) {
      if (e.target.checked) {
        setSelectedValues([...selectedValues, item.id_userAccessdetail]);
      } else {
        setSelectedValues(
          selectedValues.filter((val) => item.id_userAccessdetail != val)
        );
      }
    } else {
      toast.error("Please select a Community");
    }
  };

  const handleCheckBoxAll = async (e) => {
    if (clientData) {
      if (e.target.checked) {
        if (selectedTempValue.length == 0) {
          setCommonLoading(true);
          const payload = {
            searchText: searchData,
            filterObject: filterParams,
            poolId: clientData ? clientData.value : null,
          };
          const res = await post(
            "/curator/getallrecruitersidforcurator",
            payload
          );
          setSelectedValues(res.data.map((item) => item.id_userAccessdetail));
          setSelectedTempValue(
            res.data.map((item) => item.id_userAccessdetail)
          );
          setCommonLoading(false);
        } else {
          setSelectedValues(selectedTempValue);
        }
      } else {
        setSelectedValues([]);
      }
    } else {
      toast.error("Please select a Community");
    }
  };
  const sortClearAll = () => {
    setSortArray([]);
    const tempArray = sortObject?.map((item) => item.value);
    setSorttype(tempArray);
    setSortCount(0);
    setSortApplied(false);
    fetchData(
      1,
      10,
      searchData,
      [],
      true,
      clientData ? clientData.value : null
    );
    setSort(false);
  };

  return (
    <>
      <div className="bl-modal-body">
        <div className="d-flex justify-content-between mb-3">
          <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
            <select
              class="form-select mb-2"
              value={dataSize}
              onChange={handleSizeChange}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
          <div className="d-flex justify-content-end col-6">
            <div>
              <Sort
                sort={sort}
                setSort={setSort}
                sortCount={sortCount}
                sortData={recruiterData}
                orderArray={orderList}
                sortObjects={sortObject}
                applySort={applySort}
                setSortCount={setSortCount}
                setSortArray={setSortArray}
                sortArray={sortArray}
                sortingFor={"Sort by Name, Location, and Email"}
                sortType={sortType}
                setSorttype={setSorttype}
                sortApplied={sortApplied}
                setSortApplied={setSortApplied}
                sortClearAll={sortClearAll}
              />
            </div>
            <div className="search-input-container w-60">
              <input
                type="text"
                value={searchData}
                className="form-control"
                placeholder="Search Recruiter by Name and Email"
                onChange={handleSearch}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">
            <AssignCuratorFilter
              setSelectedData={setSelectedData}
              selectedData={selectedData}
              applyFilter={applyFilter}
              closePanel={() => setopenTenantPopUP(false)}
            />
          </div>
          <div className="col-8">
            <div className="pt-0 mb-3 overflow-auto">
              <table className="table text-center">
                <thead>
                  <tr>
                    <th>
                      {clientData && (
                        <div className="d-flex align-items-start mb-1 justify-content-center h-100">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            style={{
                              width: "1.5rem",
                              borderRadius: "100",
                            }}
                            onChange={handleCheckBoxAll}
                            checked={selectedValues.length >= dataTotal}
                          />
                        </div>
                      )}
                    </th>
                    <th
                      style={{
                        textAlign: "left",
                        marginLeft: "26px !important",
                      }}
                    >
                      Recruiter Name
                    </th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {recruiterData.length > 0 ? (
                    recruiterData?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {clientData && (
                            <div className="d-flex align-items-start mb-1 justify-content-center h-100">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                style={{
                                  width: "1.5rem",
                                  borderRadius: "100",
                                }}
                                checked={selectedValues.includes(
                                  item.id_userAccessdetail
                                )}
                                onChange={(e) => handleCheckBox(e, item)}
                              />
                            </div>
                          )}
                        </td>
                        <td
                          onClick={() => openTenantPopUPFun(item)}
                          style={{ cursor: "pointer", textAlign: "left" }}
                        >
                          <div className="avatar-name-candidatestableview d-flex align-items-center">
                            <div>
                              <span>
                                <ProfileImage
                                  imageSrc={item?.profileImagePath}
                                  imageWidth={"35"}
                                  imageHeight={"35"}
                                  avatarSize={"35"}
                                  avatarRound={"80px"}
                                  firstName={item?.firstName}
                                  lastName={item?.LastName}
                                />
                              </span>
                            </div>
                            <div className="ml-3">
                              <strong className="mb-0 me-2">
                                {item.firstName} {item.LastName}
                              </strong>
                            </div>
                          </div>
                        </td>
                        <td style={{ textAlign: "left" }}>{item.email}</td>
                        <td>
                          {item.status === "Deactivate" ? (
                            <span className="tag tag-red2">Deactivated</span>
                          ) : item.status === "Invited" ? (
                            <span className="tag tag-blue3">Invited</span>
                          ) : item.status === "Pending" ? (
                            <span className="tag tag-orange1">Pending</span>
                          ) : item.status === "Approved" ? (
                            item.recruiterStatus == "Hyperactive" ? (
                              <span class="tag tag-green4">Hyperactive</span>
                            ) : item.recruiterStatus == "Active" ? (
                              <span class="tag tag-blue2">Active</span>
                            ) : item.recruiterStatus == "Inactive" ? (
                              <span class="tag tag-orange1">Inactive</span>
                            ) : item.recruiterStatus == "Never Logged In" ? (
                              <span class="tag tag-red2">Never Logged In</span>
                            ) : (
                              <span className="tag tag-green4">Approved</span>
                            )
                          ) : (
                            <span className="tag">{item.status}</span>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">
                        <div className="d-flex justify-content-center align-items-center">
                          <NoRecord />
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>

              <hr className="m-0" />
              {recruiterData.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalItems={dataTotal}
                  itemsPerPage={dataSize}
                  onPageChange={handlePageChange}
                  limit={10}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {openTenantPopUP && (
        <RecSidePanel
          details={tenantObj}
          closePanel={() => setopenTenantPopUP(false)}
          isFrom={"HighAdmin"}
        />
      )}
    </>
  );
};

export default AssignExistingCurators;
