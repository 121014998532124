import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { get, post } from "../../../service/ApiService";
import { commonFunction } from "./CommonFunction";
import moment from "moment";

const RecruiterPerformance = (props) => {
  const { setLoading } = props;
  const [RecruiterData, setRecruiterData] = useState([]);
  const [recruiterStatus, setRecruiterStatus] = useState({});
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 27))
  );
  const [toDate, setToDate] = useState(new Date());

  const getRecruiterData = async () => {
    setLoading(true);
    try {
      let params = {
        toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
        fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
      };
      let res = await post("/reports/recruiter", params);
      if (res.status === 200) {
        setLoading(false);
        let finalObj = {
          hyperactive: 0,
          active: 0,
          approved: 0,
          invited: 0,
          pending: 0,
        };
        let array = [];
        let temp = commonFunction(res.data);
        for (const i of temp) {
          let finalObj = {
            hyperactive: 0,
            active: 0,
            approved: 0,
            invited: 0,
            pending: 0,
          };
          i.value.map((item) => {
            if (item.status == "Invited") {
              finalObj.invited = finalObj.invited + 1;
            }
            if (item.status == "Approved") {
              if (item.uadlastLoginTime) {
                finalObj.active = finalObj.active + 1;
              } else {
                finalObj.approved = finalObj.approved + 1;
              }
            }
            if (item.status == "Pending") {
              finalObj.pending = finalObj.pending + 1;
            }
          });
          finalObj.date = i.date;
          finalObj.key = i.key;
          array = [...array, JSON.stringify(finalObj)];
        }
        let arrayList = array.map((e) => JSON.parse(e));
        let arr = arrayList.map((e) => ({
          ...e,
          total: e.invited + e.hyperactive + e.active + e.approved + e.pending,
        }));
        setRecruiterData(
          arr.sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
        );

        if (arr.length > 1) {
          let data = [];
          if (arr.length == 2) {
            data = [...arr];
          } else if (arr.length == 3) {
            data = arr
              .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
              .slice(1, 3);
          } else {
            data = arr
              .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
              .slice(2, 4);
          }
          setRecruiterStatus((prev) => ({
            ...prev,
            hyperactive:
              data[0].hyperactive == 0
                ? 0
                : Math.round(
                    ((data[1].hyperactive - data[0].hyperactive) * 100) /
                      data[0].hyperactive
                  ),
            active:
              data[0].active == 0
                ? 0
                : Math.round(
                    ((data[1].active - data[0].active) * 100) / data[0].active
                  ),
            approved:
              data[0].approved == 0
                ? 0
                : Math.round(
                    ((data[1].approved - data[0].approved) * 100) /
                      data[0].approved
                  ),
            invited:
              data[0].invited == 0
                ? 0
                : Math.round(
                    ((data[1].invited - data[0].invited) * 100) /
                      data[0].invited
                  ),
            pending:
              data[0].pending == 0
                ? 0
                : Math.round(
                    ((data[1].pending - data[0].pending) * 100) /
                      data[0].pending
                  ),
          }));
        } else {
          setRecruiterStatus((prev) => ({
            ...prev,
            invited: 0,
            hyperactive: 0,
            active: 0,
            pending: 0,
            approved: 0,
          }));
        }
      }
    } catch (err) {
      setLoading(false);
      toast.error("Some Error Occurred while Fetching Gig Recruiters");
    }
  };

  useEffect(() => {
    getRecruiterData();
  }, [fromDate]);

  const handleDate = (event) => {
    var d = new Date(event);
    let toDate = new Date(event);
    d.setDate(1);
    toDate.setDate(1);
    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
      toDate.setDate(toDate.getDate() + 1);
    }
    toDate.setDate(toDate.getDate() + 27);
    setToDate(toDate);
    setFromDate(d);
  };

  return (
    <div className="card card-flat card-borderless p-4 mb-3">
      <div>
        <div
          className="d-flex align-items-center d-block-600 "
          style={{ justifyContent: "space-between" }}
        >
          <div style={{ display: "flex" }}>
            <h5 className="">Recruiter Report</h5>
            <span
              className="ml-2"
              style={{
                cursor: "pointer",
                padding: "0px 0px 5px 0px",
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
            >
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn-sm"
                table="recruiter-table"
                filename="RecruiterReport"
                sheet="tablexls"
                buttonText={
                  <span>
                    <i className="fas fa-file-download mr-2"></i>
                    Download
                  </span>
                }
              />
            </span>
          </div>
          <div
            className="p-2 bd-highlight"
            style={{ display: "flex", width: "175px" }}
          >
            <span style={{ marginTop: "14px", width: "100px" }}>
              {/* <i className="fas fa-filter"></i> */}
              Month:
            </span>
            <div>
              <DatePicker
                dateFormat="MMM yyyy"
                showMonthYearPicker
                selected={fromDate}
                onChange={(e) => handleDate(e)}
              />
              <span
                className="fa fa-search "
                style={{
                  float: "right",
                  marginRight: "9px",
                  marginTop: "-28px",
                  position: "relative",
                  zIndex: "200",
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 pt-0  mt-3">
        <table className="table table-sortable" id="recruiter-table">
          <thead
            style={{
              backgroundColor: "burlywood",
              pointerEvents: "none",
            }}
          >
            <tr>
              <th className="no-hover">
                <div className="d-flex align-items-center">Title</div>
              </th>
              {RecruiterData.map((item, i) => (
                <th className="no-hover" key={i}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      display: "flex!important",
                      justifyContent: "center",
                    }}
                  >
                    {item.date}
                  </div>
                </th>
              ))}{" "}
              {RecruiterData.length > 0 && (
                <th className="no-hover">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      display: "flex!important",
                      justifyContent: "center",
                    }}
                  >
                    Status
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-relative">Hyperactive</td>
              {RecruiterData.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.hyperactive}
                </td>
              ))}
              {RecruiterData.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(recruiterStatus.hyperactive) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(recruiterStatus.active) + "%"
                  )}{" "}
                  {recruiterStatus.hyperactive > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {recruiterStatus.hyperactive < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Active</td>
              {RecruiterData.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.active}
                </td>
              ))}
              {RecruiterData.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(recruiterStatus.active) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(recruiterStatus.active) + "%"
                  )}{" "}
                  {recruiterStatus.active > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {recruiterStatus.active < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Approved</td>
              {RecruiterData.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.approved}
                </td>
              ))}
              {RecruiterData.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(recruiterStatus.approved) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(recruiterStatus.approved) + "%"
                  )}{" "}
                  {recruiterStatus.approved > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {recruiterStatus.approved < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Invited</td>
              {RecruiterData.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.invited}
                </td>
              ))}
              {RecruiterData.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(recruiterStatus.invited) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(recruiterStatus.invited) + "%"
                  )}{" "}
                  {recruiterStatus.invited > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {recruiterStatus.invited < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Pending</td>
              {RecruiterData.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.pending}
                </td>
              ))}
              {RecruiterData.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(recruiterStatus.pending) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(recruiterStatus.pending) + "%"
                  )}{" "}
                  {recruiterStatus.pending > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {recruiterStatus.pending < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td className="p-relative">Grand Total</td>
              {RecruiterData.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.total}
                </td>
              ))}
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecruiterPerformance;
