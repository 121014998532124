import React, { useState, useRef } from "react";
import moment from "moment";
import useOutsideClick from "../commoncomponents/outsideclick/OutSideClick";
import { getCandidateId } from "../../../service/AuthService";
import { getPhone } from "../../function/CommonFunction";

const EmployeePopUp = (props) => {
  const [openDropDownDiv, setOpenDropDownDiv] = useState(false);
  const { roleNames } = props;
  const ref = useRef();
  useOutsideClick(ref, () => setOpenDropDownDiv(false));

  const close = () => {
    props.closeModal(true);
  };

  const openDropDown = () => {
    openDropDownDiv ? setOpenDropDownDiv(false) : setOpenDropDownDiv(true);
  };

  return (
    <>
      <div className="view-panel show fontSizeChange">
        <div className="view-panel-header">
          <div className="px-3 py-2">
            <button type="button" onClick={close} className="btn btn-icon">
              <i aria-hidden="true" className="fal fa-times"></i>
            </button>
          </div>

          <div className="px-3 mb-3">
            <div className="d-flex align-items-start flex-wrap-reverse">
              <div className="d-flex">
                <div className="ml-3">
                  <h5 className="mr-2 mb-2">{props.employee.name}</h5>
                  <div className="mb-2">
                    <span className="tag">
                      {props.employee.isOwner
                        ? props.employee.role == "Admin"
                          ? "Client Admin"
                          : props.employee.curator == "true"
                          ? "Curator"
                          : `${roleNames[props.employee.role]}`
                        : props.employee.curator == "true"
                        ? "Curator"
                        : roleNames[props.employee.role]}
                    </span>
                  </div>
                </div>
              </div>
              {props.employee.userId != getCandidateId() && (
                <div className="ml-auto d-flex align-items-center">
                  {props.employee.statusname === "InActive" ? (
                    <button
                      type="button"
                      className="btn btn-success btn-sm"
                      onClick={() =>
                        props.EnabletenantFun(props.employee, "activate")
                      }
                    >
                      <i className="fa fa-key mr-2" aria-hidden="true"></i>{" "}
                      Activate
                    </button>
                  ) : (
                    <>
                      <div
                        className="btn-group btn-group-sm btn-split"
                        ref={ref}
                      >
                        <button
                          type="button"
                          onClick={() => props.editFun(props.employee, "edit")}
                          className="btn btn-sm"
                          disabled={
                            props.employee.statusname === "InActive"
                              ? true
                              : false
                          }
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          onClick={openDropDown}
                          className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          disabled={
                            props.employee.statusname === "InActive"
                              ? true
                              : false
                          }
                        >
                          <span className="sr-only">Toggle Dropdown</span>
                        </button>
                        {openDropDownDiv && (
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            style={{
                              display: "revert",
                              right: "0px",
                              top: "30px",
                            }}
                          >
                            {props.employee.statusname === "Active" ? (
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  props.DeleteRequestFun(
                                    props.employee,
                                    "delete"
                                  )
                                }
                              >
                                <i
                                  className="fa fa-trash mr-2"
                                  aria-hidden="true"
                                ></i>{" "}
                                Deactivate
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="dropdown-item"
                                onClick={() =>
                                  props.EnabletenantFun(
                                    props.employee,
                                    "activate"
                                  )
                                }
                              >
                                <i
                                  className="fa fa-key mr-2"
                                  aria-hidden="true"
                                ></i>{" "}
                                Activate
                              </button>
                            )}{" "}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="view-panel-body">
          <div className="mb-2">
            <button type="button" className="btn btn-collapsible">
              <i className="fal fa-angle-down fa-fw" aria-hidden="true"></i>
              <span>
                <small>General</small>
              </span>
            </button>
          </div>
          <div className="mb-4 ml-4">
            <div className="d-flex mb-2 pl-1">
              <div>
                <i className="fas fa-fw fa-envelope" aria-hidden="true"></i>
              </div>
              <div className="ml-2">
                <p className="mb-0">{props.employee.email} </p>
                <p className="mb-0">
                  <small>Email</small>
                </p>
              </div>
            </div>

            {props.employee.phone ? (
              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fas fa-mobile-alt" aria-hidden="true"></i>
                </div>
                <div className="ml-2">
                  <p className="mb-0">
                    {getPhone(props.employee.phone, props.employee.phoneCode)}
                  </p>{" "}
                  <p className="mb-0">
                    <small>Contact</small>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}

            {props.employee.role ? (
              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fas fa-id-card" aria-hidden="true"></i>
                </div>
                <div className="ml-2">
                  <p className="mb-0">
                    {" "}
                    {props.employee.isOwner
                      ? props.employee.role == "Admin"
                        ? "Client Admin"
                        : props.employee.curator == "true"
                        ? "Curator"
                        : `${roleNames[props.employee.role]}`
                      : props.employee.curator == "true"
                      ? "Curator"
                      : roleNames[props.employee.role]}
                  </p>{" "}
                  <p className="mb-0">
                    <small>Role</small>
                  </p>
                </div>
              </div>
            ) : (
              " "
            )}
            {props.employee.designation ? (
              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fas fa-fw fa-map-pin" aria-hidden="true"></i>
                </div>
                <div className="ml-2">
                  <p className="mb-0"> {props.employee.designation}</p>{" "}
                  <p className="mb-0">
                    <small>Designation</small>
                  </p>
                </div>
              </div>
            ) : (
              " "
            )}
            {props.employee.statusname ? (
              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fas fa-fw fa-user" aria-hidden="true"></i>
                </div>
                <div className="ml-2">
                  <p className="mb-0">
                    {" "}
                    {props.employee.statusname === "Active" ? (
                      <span className="tag tag-green2" v-if="item.isActive">
                        Active
                      </span>
                    ) : (
                      <span className="tag tag-red2" v-else>
                        Inactive
                      </span>
                    )}
                  </p>{" "}
                  <p className="mb-0">
                    <small>Status</small>
                  </p>
                </div>
              </div>
            ) : (
              " "
            )}

            {props.employee.createddatetime && (
              <div className="d-flex mb-2 pl-1">
                <div>
                  <i className="fas fa-fw fa-calendar" aria-hidden="true"></i>
                </div>
                <div className="ml-2">
                  <p className="mb-0">
                    {moment(new Date(props.employee.createddatetime)).format(
                      "MM/DD/YYYY"
                    )}
                  </p>{" "}
                  <p className="mb-0">
                    <small>Created On</small>
                  </p>
                </div>
              </div>
            )}

            {props.employee.role === "Recruiter" && (
              <div>
                {JSON.parse(props.employee.primarySkills).length > 0 && (
                  <div className="d-flex mb-2 pl-1">
                    <div>
                      <i className="fas fa-fw fa-book" aria-hidden="true"></i>
                    </div>
                    <div className="ml-2">
                      <p className="mb-0">
                        {JSON.parse(props.employee.primarySkills).join(", ")}
                      </p>{" "}
                      <p className="mb-0">
                        <small>Preferred Skills</small>
                      </p>
                    </div>
                  </div>
                )}
                {JSON.parse(props.employee.countryList).length > 0 && (
                  <div className="d-flex mb-2 pl-1">
                    <div>
                      <i className="fas fa-fw fa-map" aria-hidden="true"></i>
                    </div>
                    <div className="ml-2">
                      <p className="mb-0">
                        {JSON.parse(props.employee.countryList)
                          .map((country) => country.label)
                          .join(", ")}
                      </p>{" "}
                      <p className="mb-0">
                        <small>Preferred Location</small>
                      </p>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeePopUp;
