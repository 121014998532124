import React from "react";
import Avatar from "react-avatar";

const ProfileImage = ({
  imageSrc,
  imageWidth,
  imageHeight,
  avatarSize,
  avatarRound,
  firstName,
  lastName,
  isFrom,
}) => {
  return (
    <div>
      {imageSrc ? (
        <img
          src={imageSrc}
          alt=""
          width={imageWidth}
          height={imageHeight}
          style={{
            borderRadius: "50%",
            objectFit: "contain",
          }}
          title={`${firstName} ${lastName}`}
        />
      ) : (
        <Avatar
          size={avatarSize}
          name={`${firstName} ${lastName}`}
          round={avatarRound}
        />
      )}
    </div>
  );
};

export default ProfileImage;
