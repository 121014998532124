import React, { useState, useEffect } from "react";
import FullViewEmail from "./FullViewEmail";
//import Pagination from "../commoncomponents/pagination/Pagination";

import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { Col, Row, Table } from "react-bootstrap";

const SentEmailsBox = ({ broadCastData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationArray, setPaginationArray] = useState([]);
  const dataSize = 10;
  const pageSize = 5;
  const [openFullViewEmail, setOpenFullViewEmail] = useState(false);
  const [selectedMail, setSelectedMail] = useState({});

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getMonth() +
      1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * dataSize;
  const endIndex = startIndex + dataSize;

  const currentData =
    broadCastData && broadCastData?.slice(startIndex, endIndex);

  return (
    <div className="sent-emails-box">
      <h6 className="mt-5">Sent Emails</h6>
      <Table className="mt-2" bordered hover responsive id="broadCastSMSTable">
        <thead>
          <tr>
            <th>
              <strong>To</strong>
            </th>
            <th>
              <b>Subject</b>
            </th>
            <th>
              <b>Date</b>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {currentData &&
            currentData?.map((email, index) => (
              <tr
                className="email-row"
                key={index}
                style={{ fontWeight: "lighter" }}
              >
                <td>{JSON.parse(email?.MailTo)[0]}</td>
                <td>{email?.MailSubject}</td>
                <td>{formatDate(email?.createdDateTime)}</td>
                <td>
                  <span className="action-icon">
                    <button
                      style={{ color: "#000" }}
                      onClick={() => {
                        setOpenFullViewEmail(true);
                        setSelectedMail(email);
                      }}
                      className="btn btn-text"
                    >
                      <i className="fad fa-eye"></i>
                    </button>
                  </span>
                  {/* <div
                    className="d-inline-block ml-2"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="View Email Status"
                  >
                    <button
                      type="button"
                      className="btn btn-text"
                      //   onClick={() => {
                      //     setEmailData(item?.email);
                      //     setEmailStatus(true);
                      //     setSelectedTalent(item);
                      //   }}
                    >
                      <i class="fa fa-envelope-o" />
                    </button>
                  </div> */}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      {currentData && currentData?.length === 0 && (
        <div>
          <div className="text-center p-3">
            <div className="avatar avatar-lg">
              <i className="fad fa-comment-slash"></i>
            </div>
            <div className="mt-2">No records found</div>
          </div>
        </div>
      )}
      {/* <div class="ml-auto mx-auto-370 d-flex align-items-center">
        {" "} */}
      {/* <Pagination
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setPaginationArray={setPaginationArray}
        data={broadCastData}
        dataSize={dataSize}
        getTab=""
        pageSize={pageSize}
      /> */}

      {currentData && currentData?.length > 0 && (
        <Row className="d-flex justify-content-between mt-2 mb-5">
          <Col md={6} className="mt-3 px-2">
            <small>
              Showing{" "}
              {Math.min((currentPage - 1) * 10 + 1, broadCastData?.length)} -{" "}
              {Math.min(currentPage * 10, broadCastData?.length)} of{" "}
              {broadCastData?.length}
            </small>
          </Col>
          <Col md={6} className="mt-3 px-2 text-md-right">
            <small>
              <Pagination
                currentPage={currentPage}
                totalSize={broadCastData.length}
                sizePerPage={10}
                firstPageText="|⟨"
                lastPageText="⟩|"
                showFirstLastPages={true}
                changeCurrentPage={handlePageChange}
                theme="border-bottom"
              />
            </small>
          </Col>
        </Row>
      )}

      {/* </div> */}
      {openFullViewEmail && (
        <FullViewEmail
          openFullViewEmail={openFullViewEmail}
          setOpenFullViewEmail={setOpenFullViewEmail}
          selectedMail={selectedMail}
        />
      )}
    </div>
  );
};

export default SentEmailsBox;
