const getDuration = ((currDate, thresholdValue) => {
    const thresholdDate = new Date(currDate)
    let threshold = thresholdValue.split(' ')[0]
    const thresholdString = thresholdValue.split(' ')[1]
    if (thresholdString === 'Week' || thresholdString === 'Weeks') {
        thresholdDate.setDate(new Date(thresholdDate).getDate() + Number(threshold) * 7);
    } else if (thresholdValue.split(' ')[1] === 'Day' || thresholdValue.split(' ')[1] === 'Days') {
        let threshold = thresholdValue.split(' ')[0]
        thresholdDate.setDate(new Date(thresholdDate).getDate() + Number(threshold));
    } else if (thresholdValue.split(' ')[1] === 'Hour' || thresholdValue.split(' ')[1] === 'Hours') {
        thresholdDate.setHours(new Date(thresholdDate).getHours() + Number(threshold));
    }
    return thresholdDate;
})


export const genrateTierData = (tierData, threshold) => {
    let tenant1 = [], tenant2 = [], tenant3 = []
    let tenantId1 = [], tenantId2 = [], tenantId3 = []
    let recruiter1 = [], recruiter2 = [], recruiter3 = []
    let jobPostedOn1 = new Date(), jobPostedOn2 = new Date(), jobPostedOn3 = new Date()

    let azureTiervalues = []
    let dbTiervalues = tierData

    tierData.tierValue1.map((eachData) => {
        tenant1.push(eachData.id_tenant)
        recruiter1.push(eachData.userId.toString())
        tenantId1.push(eachData.tenantname)

    })
    tierData.tierValue2.map((eachData) => {
        tenant2.push(eachData.id_tenant)
        recruiter2.push(eachData.userId)
        tenantId2.push(eachData.tenantname)
    })
    tierData.tierValue3.map((eachData) => {
        tenant3.push(eachData.id_tenant)
        recruiter3.push(eachData.userId)
        tenantId3.push(eachData.tenantname)
    })
    if (tenant1.length > 0) {
        const isPublicTier = (tierData.tierLength == 0 && tierData.isTierPublic) ? true : false
        azureTiervalues.push({
            tierName: 'tier1',
            isPublic: isPublicTier,
            jobPostedOn: jobPostedOn1,
            recruiters: recruiter1.map(String),
            tenant: [...new Set(tenant1.map(String))],
            tenantid: [...new Set(tenantId1.map(String))]
        })
    }

    if (tenant2.length > 0) {

        const isPublicTier = (tierData.tierLength == 1 && tierData.isTierPublic) ? true : false
        const thresholdData1 = threshold.filter(e => e.id_JobPositiontype == tierData.tier1Duration)[0]
        jobPostedOn2 = getDuration(jobPostedOn1, thresholdData1.PositionName)
        azureTiervalues.push({
            tierName: 'tier2',
            jobPostedOn: jobPostedOn2,
            isPublic: isPublicTier,
            recruiters: recruiter2.map(String),
            tenant: [...new Set(tenant2.map(String))],
            tenantid: [...new Set(tenantId2.map(String))]
        })
    }
    if (tenant3.length > 0) {
        const isPublicTier = (tierData.tierLength == 2 && tierData.isTierPublic) ? true : false
        const thresholdData2 = threshold.filter(e => e.id_JobPositiontype == tierData.tier2Duration)[0]
        jobPostedOn3 = getDuration(jobPostedOn2, thresholdData2.PositionName)
        azureTiervalues.push({
            tierName: 'tier3',
            jobPostedOn: jobPostedOn3,
            isPublic: isPublicTier,
            recruiters: recruiter3.map(String),
            tenant: [...new Set(tenant3.map(String))],
            tenantid: [...new Set(tenantId3.map(String))]
        })
    }

    if (tierData.isTierPublic) {
        const tier = tierData.tierLength + 1
        let publicthreshold = ""
        let publicjobPostedOn = new Date()
        switch (tierData.tierLength) {
            case 1: {
                publicthreshold = threshold.filter(e => e.id_JobPositiontype == tierData.tier1Duration)[0]
                publicjobPostedOn = getDuration(jobPostedOn1, publicthreshold.PositionName)
                break;
            }
            case 2: {
                publicthreshold = threshold.filter(e => e.id_JobPositiontype == tierData.tier2Duration)[0]
                publicjobPostedOn = getDuration(jobPostedOn2, publicthreshold.PositionName)
                break;
            }
            case 3: {
                publicthreshold = threshold.filter(e => e.id_JobPositiontype == tierData.tier3Duration)[0]
                publicjobPostedOn = getDuration(jobPostedOn3, publicthreshold.PositionName)
                break;
            }
        }
        const tierName = `tier${tierData.tierLength + 1}Data`
        const tierval = `tierValue${tierData.tierLength + 1}`

        dbTiervalues[tierName] = ['Public']
        dbTiervalues[tierval] = [{ tenantname: 'Public' }]


        azureTiervalues.push({
            tierName: `tier${tier}`,
            isPublic: tierData.isTierPublic,
            jobPostedOn: publicjobPostedOn,
            recruiters: [],
            tenant: [],
            tenantid: []
        });
    }
    return ({ azureTiervalues, dbTiervalues })
    //  map 
}

export const addJobtierValidation = (addJobData) => {
    let errMessage = ""
    const tierData = addJobData.tierData
    let tierError = ""
    switch (tierData.tierLength > 0) {
        case 0: {
            tierError = "Please add Tiers "
            break;
        }
        case 1: {
            if (tierData.tier1Data.length === 0) {
                tierError = "Please fill all fields"
            }
            if (tierData.tier1Duration === "" || tierData.tier1Duration === 'Select Duration') {
                tierError = "Please Select Duration"
            }
            break;
        }
        case 2: {
            if (tierData.tier2Data.length === 0 || tierData.tier1Data.length === 0) {
                tierError = "Please fill all fields"
            }
            if (tierData.tier1Duration === "Select Duration" || tierData.tier1Duration === "" || tierData.tier2Duration === "Select Duration" || tierData.tier2Duration === "") {
                tierError = "Please Select Duration"
            }
            break;
        }
        case 3: {
            if (tierData.tier3Data.length === 0 || tierData.tier2Data.length === 0 || tierData.tier1Data.length === 0) {
                tierError = "Please fill all fields"
            }
            if (tierData.tier3Duration === "Select Duration" || tierData.tier3Duration === "" || tierData.tier1Duration === "Select Duration" || tierData.tier1Duration === "" || tierData.tier2Duration === "Select Duration" || tierData.tier2Duration === "") {
                tierError = "Please Select Duration"
            }
            break;
        }
    }

    return tierError
}


export const addJobFullTimeValidation = (addJobData) => {
    let titleError = ''; let startDateError = ''; let salaryError = ''; let salaryCurrencyError = '';
    let activeFromError = ''; let expiresOnError = ''; let primarySkillsError = ''; let cityError = ''; 
    let stateError = ''; let postalCodeError = ''; let countryError = ''; let companyError = '';  
    let allowedSubmittalsError = ''; let placementFeeError = ''


    if (addJobData.title.trim().length === 0) {
        titleError = '*Required Field'
    }
    // if (!addJobData.startDate) {
    //     startDateError = '*Required Field'
    // }
    if (addJobData.isFeePercentage && addJobData.placementFee > 100 ) {
        placementFeeError = '*Placement fee should not be greater than 100'
    }
    if (addJobData.placementFee == "") {
        placementFeeError = '*Required Field'
    }
    if (addJobData.activeFrom > addJobData.startDate) {
        activeFromError = 'Active from date should be less than preferred start date'
    }
    if (addJobData.startDate < addJobData.activeFrom) {
        startDateError = 'Preffered Start Date should be greater than Active from date'
    }
    if (addJobData.expiresOn < addJobData.startDate){
        expiresOnError = '*Expired date should be greater than prefered start date'
    }
    if (addJobData.expiresOn < addJobData.activeFrom) {
        expiresOnError = '*Expired date should be greater than active from date'
    }
    if (addJobData.salary.trim().length === 0) {
        salaryError = '*Required Field'
    }
    if (addJobData.salaryCurrency.length === 0) {
        salaryCurrencyError = '*Required Field'
    }
    if (!addJobData.activeFrom) {
        activeFromError = '*Required Field'
    }
    if (addJobData.expiresOn==''){
        expiresOnError = '*Required Field'
    }
    if (addJobData.primarySkills.length === 0) {
        primarySkillsError = '*Required Field'
    }
    if (addJobData.isRemote === false) {
        if (addJobData.location.city.length === 0) {
            cityError = '*Required Field'
        }
        if (addJobData.location.state.length === 0) {
            stateError = '*Required Field'
        }
        if (addJobData.location.postalCode.length === 0) {
            postalCodeError = '*Required Field'
        }
        if (addJobData.location.country.length === 0) {
            countryError = '*Required Field'
        }
    }
    if (addJobData.companyName.trim().length === 0) {
        companyError = '*Required Field'
    } 
    if (addJobData.allowedSubmittals < addJobData.numberOfPositions) {
        allowedSubmittalsError = 'Cannot Be less than total Positions'
    } else if (addJobData.allowedSubmittals === 0) {
        allowedSubmittalsError = 'This number cannot be zero'
    }


    const tierError = addJobtierValidation(addJobData)

    const validation = {
        title: titleError,
        startDate: startDateError,
        salary: salaryError,
        salaryCurrency: salaryCurrencyError,
        activeFrom: activeFromError,
        expiresOn: expiresOnError,
        primarySkills: primarySkillsError,
        tierError: tierError,
        city: cityError,
        state: stateError,
        placementFee: placementFeeError,
        postalCode: postalCodeError,
        country: countryError,
        companyName: companyError,
        allowedSubmittals: allowedSubmittalsError
    }
    console.log(tierError, validation)
    return validation;
}

export const addJobContractValidation = (addJobData) => {
    let titleError = ''; let durationMonthsError = ''; let durationDaysError = ''; let hourlyRateError = ''; let activeFromError = ''; let expiresOnError = ''; let primarySkillsError = ''; let cityError = ''; let stateError = ''; let postalCodeError = ''; let countryError = ''; let companyError = ''; let companyJobIdError = ''; let allowedSubmittalsError = ''; let hourlyRateCurrencyError = '';
    let startDateError = ''; let placementFeeError = ''

    if (addJobData.title.trim().length === 0) {
        titleError = '*Required Field'
    }
    if (addJobData.isFeePercentage && addJobData.placementFee > 100) {
        placementFeeError = '*Placement fee should not be greater than 100'
    }
    if (addJobData.placementFee == '') {
        placementFeeError = '*Required Field'
    }
    else if (addJobData.placementFee == '') {
        placementFeeError = '*Required Field'
    }
    if (addJobData.activeFrom > addJobData.startDate) {
        activeFromError = 'Active from date should be less than preferred start date'
    }
    if (addJobData.startDate < addJobData.activeFrom) {
        startDateError = 'Preffered Start Date should be greater than Active from date'
    }else if(!addJobData.startDate){
        startDateError = '*Required Field'
    }
    if (addJobData.expiresOn < addJobData.startDate) {
        expiresOnError = '*Expired date should be greater than prefered start date'
    }
    if (addJobData.expiresOn < addJobData.activeFrom) {
        expiresOnError = '*Expired date should be greater than active from date'
    }
    else if (!addJobData.startDate) {
        startDateError = '*Required Field'
    }
    if (addJobData.durationMonths.trim().length === 0) {
        durationMonthsError = '*Required Field'
    }
    if (addJobData.durationDays.trim().length === 0) {
        durationDaysError = '*Required Field'
    }
    if (addJobData.hourlyRate === '') {
        hourlyRateError = '*Required Field'
    }
        if (addJobData.hourlyRateCurrency.length === 0) {
        hourlyRateCurrencyError = '*Required Field'
    }
    if (addJobData.activeFrom.length === 0) {
        activeFromError = '*Required Field'
    }
    if (addJobData.primarySkills.length === 0) {
        primarySkillsError = '*Required Field'
    }
    let tierError = addJobtierValidation(addJobData)
    if (addJobData.isRemote === false) {
        if (addJobData.location.city.length === 0) {
            cityError = '*Required Field'
        }
        if (addJobData.location.state.length === 0) {
            stateError = '*Required Field'
        }
        if (addJobData.location.postalCode.length === 0) {
            postalCodeError = '*Required Field'
        }
        if (addJobData.location.country.length === 0) {
            countryError = '*Required Field'
        }
    }
    if (addJobData.companyName.trim().length === 0) {
        companyError = '*Required Field'
    }
    if (addJobData.allowedSubmittals < addJobData.numberOfPositions) {
        allowedSubmittalsError = 'Cannot Be less than total Positions'
    } else if (addJobData.allowedSubmittals === 0) {
        allowedSubmittalsError = 'This number cannot be zero'
    }

    const validation = {
        title: titleError,
        startDate: startDateError,
        durationDays: durationDaysError,
        durationMonths: durationMonthsError,
        hourlyRate: hourlyRateError,
        expiresOn: expiresOnError,
        activeFrom: activeFromError,
        primarySkills: primarySkillsError,
        tierError: tierError,
        city: cityError,
        state: stateError,
        placementFee: placementFeeError,
        postalCode: postalCodeError,
        country: countryError,
        companyName: companyError,
        companyJobId: companyJobIdError,
        hourlyRateCurrency: hourlyRateCurrencyError,
        allowedSubmittals: allowedSubmittalsError
    }

    return validation;
}


export const addJobPartTimeValidation = (addJobData) => {
    let titleError = ''; let durationMonthsError = ''; let durationDaysError = ''; let hourlyRateError = ''; let activeFromError = ''; let expiresOnError = ''; let primarySkillsError = ''; let cityError = ''; let stateError = ''; let postalCodeError = ''; let countryError = ''; let companyError = ''; let companyJobIdError = ''; let allowedSubmittalsError = ''; let hourlyRateCurrencyError = '';
    let startDateError = ''; let placementFeeError = ''

    if (addJobData.title.trim().length === 0) {
        titleError = '*Required Field'
    }
    if (addJobData.isFeePercentage && addJobData.placementFee > 100) {
        placementFeeError = '*Placement fee should not be greater than 100'
    }
    if (addJobData.placementFee == '') {
        placementFeeError = '*Required Field'
    }
    else if (addJobData.placementFee == '') {
        placementFeeError = '*Required Field'
    }
    if (addJobData.activeFrom > addJobData.startDate) {
        activeFromError = 'Active from date should be less than preferred start date'
    }
    if (addJobData.startDate < addJobData.activeFrom) {
        startDateError = 'Preffered Start Date should be greater than Active from date'
    } else if (!addJobData.startDate) {
        startDateError = '*Required Field'
    }
    if (addJobData.expiresOn < addJobData.startDate) {
        expiresOnError = '*Expired date should be greater than prefered start date'
    }
    if (addJobData.expiresOn < addJobData.activeFrom) {
        expiresOnError = '*Expired date should be greater than active from date'
    }else if(!addJobData.expiresOn){
        expiresOnError = '*Required Field'
    }
    if (addJobData.primarySkills.length === 0) {
        primarySkillsError = '*Required Field'
    }
    if (addJobData.hourlyRate === '') {
        hourlyRateError = '*Required Field'
    }
    let tierError = addJobtierValidation(addJobData)
    if (addJobData.isRemote === false) {
        if (addJobData.location.city.length === 0) {
            cityError = '*Required Field'
        }
        if (addJobData.location.state.length === 0) {
            stateError = '*Required Field'
        }
        if (addJobData.location.postalCode.length === 0) {
            postalCodeError = '*Required Field'
        }
        if (addJobData.location.country.length === 0) {
            countryError = '*Required Field'
        }
    }
    if (addJobData.companyName.trim().length === 0) {
        companyError = '*Required Field'
    }
    if (addJobData.allowedSubmittals < addJobData.numberOfPositions) {
        allowedSubmittalsError = 'Cannot Be less than total Positions'
    } else if (addJobData.allowedSubmittals === 0) {
        allowedSubmittalsError = 'This number cannot be zero'
    }

    const validation = {
        title: titleError,
        startDate: startDateError,
        durationDays: durationDaysError,
        durationMonths: durationMonthsError,
        hourlyRate: hourlyRateError,
        expiresOn: expiresOnError,
        activeFrom: activeFromError,
        primarySkills: primarySkillsError,
        tierError: tierError,
        city: cityError,
        state: stateError,
        placementFee: placementFeeError,
        postalCode: postalCodeError,
        country: countryError,
        companyName: companyError,
        hourlyRateCurrency: hourlyRateCurrencyError,
        allowedSubmittals: allowedSubmittalsError
    }

    return validation;
}

export const addJobTempToHireValidation = (addJobData) => {
    let titleError = ''; let durationMonthsError = ''; let durationDaysError = ''; let hourlyRateError = ''; let activeFromError = ''; let expiresOnError = ''; let primarySkillsError = ''; let cityError = ''; let stateError = ''; let postalCodeError = ''; let countryError = ''; let companyError = ''; let companyJobIdError = ''; let allowedSubmittalsError = ''; let hourlyRateCurrencyError = '';
    let startDateError = ''; let placementFeeError = ''

    if (addJobData.title.trim().length === 0) {
        titleError = '*Required Field'
    } if (addJobData.isFeePercentage && addJobData.placementFee > 100) {
        placementFeeError = '*Placement fee should not be greater than 100'
    }
    if (addJobData.placementFee == '') {
        placementFeeError = '*Required Field'
    }
    else if (addJobData.placementFee == '') {
        placementFeeError = '*Required Field'
    }
    if (addJobData.activeFrom > addJobData.startDate) {
        activeFromError = 'Active from date should be less than preferred start date'
    }
    if (addJobData.startDate < addJobData.activeFrom) {
        startDateError = 'Preffered Start Date should be greater than Active from date'
    } else if (!addJobData.startDate) {
        startDateError = '*Required Field'
    }
    if (addJobData.expiresOn < addJobData.startDate) {
        expiresOnError = '*Expired date should be greater than prefered start date'
    }
    if (addJobData.expiresOn < addJobData.activeFrom) {
        expiresOnError = '*Expired date should be greater than active from date'
    }else if(!addJobData.expiresOn){
        expiresOnError = '*Required Field'
    }
    if (addJobData.primarySkills.length === 0) {
        primarySkillsError = '*Required Field'
    }
    if (addJobData.hourlyRate === '') {
        hourlyRateError = '*Required Field'
    }
    let tierError = addJobtierValidation(addJobData)
    if (addJobData.isRemote === false) {
        if (addJobData.location.city.length === 0) {
            cityError = '*Required Field'
        }
        if (addJobData.location.state.length === 0) {
            stateError = '*Required Field'
        }
        if (addJobData.location.postalCode.length === 0) {
            postalCodeError = '*Required Field'
        }
        if (addJobData.location.country.length === 0) {
            countryError = '*Required Field'
        }
    }
    if (addJobData.companyName.trim().length === 0) {
        companyError = '*Required Field'
    }
    if (addJobData.allowedSubmittals < addJobData.numberOfPositions) {
        allowedSubmittalsError = 'Cannot Be less than total Positions'
    } else if (addJobData.allowedSubmittals === 0) {
        allowedSubmittalsError = 'This number cannot be zero'
    }

    const validation = {
        title: titleError,
        startDate: startDateError,
        durationDays: durationDaysError,
        durationMonths: durationMonthsError,
        hourlyRate: hourlyRateError,
        expiresOn: expiresOnError,
        activeFrom: activeFromError,
        primarySkills: primarySkillsError,
        tierError: tierError,
        city: cityError,
        state: stateError,
        postalCode: postalCodeError,
        country: countryError,
        companyName: companyError,
        placementFee: placementFeeError,
        hourlyRateCurrency: hourlyRateCurrencyError,
        allowedSubmittals: allowedSubmittalsError
    }

    return validation;
}

export const addJobVolunteerValidation = (addJobData) => {
    let titleError = ''; let durationMonthsError = ''; let durationDaysError = ''; let hourlyRateError = ''; let activeFromError = ''; let expiresOnError = ''; let primarySkillsError = ''; let cityError = ''; let stateError = ''; let postalCodeError = ''; let countryError = ''; let companyError = ''; let companyJobIdError = ''; let allowedSubmittalsError = ''; let hourlyRateCurrencyError = '';
    let startDateError = ''; let placementFeeError = '';

    if (addJobData.title.trim().length === 0) {
        titleError = '*Required Field'
    } 
    if (addJobData.isFeePercentage && addJobData.placementFee > 100) {
        placementFeeError = '*Placement fee should not be greater than 100'
    }
    if (addJobData.placementFee == '') {
        placementFeeError = '*Required Field'
    }
    else if (addJobData.placementFee == '') {
        placementFeeError = '*Required Field'
    }
    if (addJobData.activeFrom > addJobData.startDate) {
        activeFromError = 'Active from date should be less than preferred start date'
    }
    if (addJobData.startDate < addJobData.activeFrom) {
        startDateError = 'Preffered Start Date should be greater than Active from date'
    } else if (!addJobData.startDate) {
        startDateError = '*Required Field'
    }
    if (addJobData.expiresOn < addJobData.startDate) {
        expiresOnError = '*Expired date should be greater than prefered start date'
    }
    if (addJobData.expiresOn < addJobData.activeFrom) {
        expiresOnError = '*Expired date should be greater than active from date'
    } else if (!addJobData.startDate) {
        startDateError = '*Required Field'
    }
    if (addJobData.durationMonths.trim().length === 0) {
        durationMonthsError = '*Required Field'
    }
    if (addJobData.durationDays.trim().length === 0) {
        durationDaysError = '*Required Field'
    }
    if (addJobData.hourlyRate === '') {
        hourlyRateError = '*Required Field'
    }
    // else if ((!(/^[0-9\s]+$/) || !(/^\d+(\.\d{1,2})?$/)).test(addJobData.hourlyRate)) {
    //     hourlyRateError = 'Invalid Hourly Rate'
    // }
    if (addJobData.hourlyRateCurrency.length === 0) {
        hourlyRateCurrencyError = '*Required Field'
    }
    if (addJobData.activeFrom === 0) {
        activeFromError = '*Required Field'
    }
    if (addJobData.expiresOn < addJobData.activeFrom) {
        expiresOnError = 'Expire Date should be greater than Activate Date'
    } else if (addJobData.expiresOn < addJobData.startDate) {
        expiresOnError = 'Expire Date should be greater than preferred start date'
    }else if(!addJobData.expiresOn){
        expiresOnError = '*Required Field'
    }
    if (addJobData.primarySkills.length === 0) {
        primarySkillsError = '*Required Field'
    }
    let tierError = addJobtierValidation(addJobData)
    if (addJobData.isRemote === false) {
        if (addJobData.location.city.length === 0) {
            cityError = '*Required Field'
        }
        if (addJobData.location.state.length === 0) {
            stateError = '*Required Field'
        }
        if (addJobData.location.postalCode.length === 0) {
            postalCodeError = '*Required Field'
        }
        if (addJobData.location.country.length === 0) {
            countryError = '*Required Field'
        }
    }
    if (addJobData.companyName.trim().length === 0) {
        companyError = '*Required Field'
    }
    if (addJobData.allowedSubmittals < addJobData.numberOfPositions) {
        allowedSubmittalsError = 'Cannot Be less than total Positions'
    } else if (addJobData.allowedSubmittals === 0) {
        allowedSubmittalsError = 'This number cannot be zero'
    }

    const validation = {
        title: titleError,
        startDate: startDateError,
        durationDays: durationDaysError,
        durationMonths: durationMonthsError,
        hourlyRate: hourlyRateError,
        expiresOn: expiresOnError,
        activeFrom: activeFromError,
        primarySkills: primarySkillsError,
        tierError: tierError,
        city: cityError,
        state: stateError,
        postalCode: postalCodeError,
        country: countryError,
        placementFee: placementFeeError,
        companyName: companyError,
        hourlyRateCurrency: hourlyRateCurrencyError,
        allowedSubmittals: allowedSubmittalsError
    }

    return validation;
}
