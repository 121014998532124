import React, { useEffect, useState } from "react";
import { ButtonLoader, domain } from "../../../constants/Constants";
//import Moment from 'moment';
import { toast } from 'react-toastify';
import { post } from '../../../service/ApiService';
import { getCandidateId } from "../../../service/AuthService";

const Education = (props) => {
  
  const currentYear = (new Date()).getFullYear()
  const years = []
  for (let i = currentYear; i >= 0; i--) {
    years.push(i)
  }

  const educationType = ["No formal education", "Primary education", "Secondary education",
  "GED", "Vocational qualification", "Bachelor's degree", "Master's degree", "Doctorate or higher" ]

  const [ education, setEducation ] = useState([])
  const [ tempEducation, setTempEducation ] = useState([])
  const [ readOnlyMode, setReadOnlyMode ] = useState(true)
  const [ submittingForm, setSubmittingFrom ] = useState(false)
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false)
  const [ saveClicked, setSaveClicked ] = useState(true)

  const addEducation = () => {
    let ed = [...education]
    ed.push({
      educationProgram: "",
      educationType: "",
      graduatedYear: currentYear.toString(),
      major: "",
      school: "",
    })
    setEducation(ed)
  }

  const deleteEducation = (index) => {
    let ed = [...education]
    if(ed.length === 1) {
      toast.error("Atleast One Education is Required")
    }
    else {
      ed.splice(index, 1)
      setEducation(ed)
    }  
  }

  const saveEducationInfo = async () => {
    setTempEducation(education)
    console.log(education)
    let condition = []
    education.forEach(ed => { 
      let tempArray = []
      if(ed.graduatedYear === '') {
        tempArray.push(true)
      }
      if(ed.educationProgram === '') {
        tempArray.push(true)
      }
      if(ed.school === '') {
        tempArray.push(true)
      }
      condition.push(tempArray.some(i => i === true))
    })
    console.log(condition)
    if(condition.some(i => i === true)) {
      toast.error("Fields marked with *(Asterisk) are mandatory")
    }
    else { 
      setSaveClicked(false)
      setSubmittingFrom(true)
      try {
        const parameters = {
          candidateID: getCandidateId(),
          clickName: 'education',
          userEducationinfo: education
        }
        const res = await post('/candidateProfile/postEducationData', parameters)
        if (res.status === 200) {
          setSubmittingFrom(false)
          setShowSuccessMessage(true)      
          setTimeout(() => { 
            setReadOnlyMode(true)
            setShowSuccessMessage(false)
          }, 1000)
        }
        else {
          toast.error("Some Error Occured. Please Try Again")
        }
      } catch (error) {
        toast.error("Error Occured. Please Try Again")
      }
    }
  }

  const editEducationInfo = () => {
    setSaveClicked(true)
    setReadOnlyMode(false)
  }

  const cancelEducationInfo = () => {
    setEducation(tempEducation)
    setReadOnlyMode(true)
    setShowSuccessMessage(false)
    setSubmittingFrom(false)
    setSaveClicked(true)
  }

  const handleChange = (name, value, index) => {
    let ed = [...education]
    ed[index] = { ...ed[index], [name]: value }
    setEducation(ed)
  }

  useEffect(() => {
    setEducation(props.education)
    setTempEducation(props.education)   
  }, [])

  return (
    <>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Education</h6>
            { readOnlyMode === true ? (
              <button
                type="button"
                class="btn btn-text"
                onClick={editEducationInfo}
              >
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={cancelEducationInfo}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={saveEducationInfo}
                  class="btn btn-sm btn-primary"
                >
                  { saveClicked && <span>Save Changes</span>}
                  { submittingForm && (
                    <span>
                      Saving Changes
                      <img
                        width="20px"
                        src={ButtonLoader}
                        alt=""
                      />
                    </span>
                  )}
                  { showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          <hr />
          { (readOnlyMode && education?.filter(i => !Object.values(i).map(i => i ? true : false).every(i => i === false)).length === 0) ?
          <div>
              <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                      <i className="fad fa-comment-slash"></i>
                  </div>
                  <div className="mt-2">
                      No Record Found
                  </div>
              </div>
          </div> :
            (readOnlyMode ? (
            <div>
              {
                education.map(e =>
                  <div class="d-flex mb-2">
                    <div class="mr-3 d-flex flex-column align-items-center">
                      <i
                        class="fad fa-graduation-cap fa-fw mt-1"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <div>
                      <p class="mb-1">
                        <strong>{e.school}</strong>
                      </p>
                      <p>
                        <small>{e.graduatedYear} | {e.educationProgram}</small>
                      </p>
                    </div>
                  </div>
                )
              }
            </div>
          ) : (
            <div class="education">
              {
                education.map((e, j) => <div class="d-flex">
                <div class="mr-3">
                  <div class="avatar avatar-sm">{j+1}</div>
                </div>
                <div>
                  <div class="row mb-2">
                  <div class="col-lg-6">
                      <div class="form-floating">
                        <select 
                          class="form-select"
                          name="graduatedYear"                         
                          onChange={(event) => handleChange(event.target.name, event.target.value, j)}
                        >
                          <option selected={!e.graduatedYear} disabled>Select Year</option>
                          { years.map((y) => 
                            <option 
                              key={y} 
                              value={y} 
                              selected={y.toString() === e.graduatedYear}
                            >
                              {y}
                            </option>
                          ) }
                        </select>
                        <label>Graduated Year*</label>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-floating">
                        <select class="form-select"
                          name="educationType"
                          value={e.educationType}
                          onChange={(event) => handleChange(event.target.name, event.target.value, j)}
                        >
                          <option disabled>Select Type</option>
                          {
                            educationType.map(type => {
                              return <option selected={ e.educationType === type } value={type}>{type}</option>
                            })
                          }
                        </select>
                        <label>Type of Education</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-lg-12">
                      <div class="form-floating">
                      <input 
                        type="text" 
                        className='form-control' 
                        name='educationProgram' 
                        value={e.educationProgram}
                        onChange={(event) => handleChange(event.target.name, event.target.value, j)}
                      />
                      <label>Education Program*</label>
                      </div>
                    </div>                   
                  </div>
                  <div class="row mb-2">
                    <div class="col-lg-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="School"
                          name='school'
                          value={e.school}
                          onChange={(event) => handleChange(event.target.name, event.target.value, j)}
                        />
                        <label>School*</label>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-floating">
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Major"
                          name='major'
                          value={e.major}
                          onChange={(event) => handleChange(event.target.name, event.target.value, j)}
                        />
                        <label>Major</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="ml-3">
                  <button type="button" class="btn btn-icon" onClick={() => deleteEducation(j)}>
                    <i class="fal fa-fw fa-trash-alt"></i>
                  </button>
                </div>
              </div>)}

              {/* <div v-bind:class="{ 'ml-5': list.length }"> */}
              <div className="ml-5">
                <button
                  type="button"
                  onClick={addEducation}
                  class="btn btn-sm btn-text"
                >
                  <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                  <strong>Add Education</strong>
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Education
