import {
  GET_CURATORS_SIDEVIEW_BEGIN,
  GET_CURATORS_SIDEVIEW_SUCCESS,
  GET_CURATORS_SIDEVIEW_FAILURE,
  GET_SOURCE_DETAILS_BEGIN,
  GET_SOURCE_DETAILS_SUCCESS,
  GET_SOURCE_DETAILS_FAILURE
} from "../../actions/curator/CuratorSideview";

const initialState = {
  loading: true,
  curatorDetailsLoading: true,
  curatorSideViewLoading: true,
  curatorSideViewDetails: [],
  sourceDetailsLoading: true,
  sourceDetails: [],
};

const curatorDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CURATORS_SIDEVIEW_BEGIN:
      return {
        ...state,
        curatorSideViewLoading: true,
        curatorSideViewDetails: [],
      };
    case GET_CURATORS_SIDEVIEW_SUCCESS:
      return {
        ...state,
        curatorSideViewDetails: payload,
        curatorSideViewLoading: false,
      };
    case GET_CURATORS_SIDEVIEW_FAILURE:
      return {
        ...state,
        curatorSideViewDetails: [],
        curatorSideViewLoading: false,
      };
    case GET_SOURCE_DETAILS_BEGIN:
      return {
        ...state,
        sourceDetailsLoading: true,
        sourceDetails: [],
      };
    case GET_SOURCE_DETAILS_SUCCESS:
      return {
        ...state,
        sourceDetails: payload,
        sourceDetailsLoading: false,
      };
    case GET_SOURCE_DETAILS_FAILURE:
      return {
        ...state,
        sourceDetails: [],
        sourceDetailsLoading: false,
      };
    default:
      return state;
  }
};

export default curatorDetailsReducer;
