import moment from "moment";
import React from "react";

const History = ({ talentData }) => {
  console.log("talentData", talentData);
  return (
    <>
      <div class="timeline-wrap" style={{ maxHeight: "unset" }}>
      {talentData?.disqualifiedDate && (
          <div class="timeline pb-3">
            <i class="fad fa-circle fa-fw icon" aria-hidden="true"></i>{" "}
            <div>
              <small>{moment(talentData?.disqualifiedDate).fromNow()}</small>{" "}
              <p class="mb-0">
                <strong>Ineligible</strong>
              </p>{" "}
            </div>
          </div>
        )}
      {talentData?.readyToHireQA && (
          <div class="timeline pb-3">
            <i class="fad fa-circle fa-fw icon" aria-hidden="true"></i>{" "}
            <div>
              <small>{moment(talentData?.offeredDate).fromNow()}</small>{" "}
              <p class="mb-0">
                <strong>Ready To Hire</strong>
              </p>{" "}
            </div>
          </div>
        )}
        {talentData?.vettingDetails &&
          talentData?.vettingDetails[0]?.status === "completed" && (
            <div class="timeline pb-3">
              <i class="fad fa-circle fa-fw icon" aria-hidden="true"></i>{" "}
              <div>
                <small>
                  {moment(talentData?.vettedDate).fromNow()}
                </small>{" "}
                <p class="mb-0">
                  <strong>Vetted</strong>
                </p>{" "}
              </div>
            </div>
          )}
        {talentData?.submittedDate && talentData?.disqualifiedDate==null &&  (
          <div class="timeline pb-3">
            <i class="fad fa-circle fa-fw icon" aria-hidden="true"></i>{" "}
            <div>
              <small>{moment(talentData?.submittedDate).fromNow()}</small>{" "}
              <p class="mb-0">
                <strong>Engaged</strong>
              </p>{" "}
            </div>
          </div>
        )}
        {talentData?.invitedDate && (
          <div class="timeline pb-3">
            <i class="fad fa-circle fa-fw icon" aria-hidden="true"></i>{" "}
            <div>
              <small>{moment(talentData?.invitedDate).fromNow()}</small>{" "}
              <p class="mb-0">
                <strong>Invited</strong>
              </p>{" "}
            </div>
          </div>
        )}
        {talentData?.automatchDate && (
          <div class="timeline pb-3">
            <i class="fad fa-circle fa-fw icon" aria-hidden="true"></i>{" "}
            <div>
              <small>{moment(talentData?.automatchDate).fromNow()}</small>{" "}
              <p class="mb-0">
                <strong>Automatched</strong>
              </p>{" "}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default History;
