import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { FaUsersCog } from "react-icons/fa";
import {
  getTalentList,
  getTalentListCount,
} from "../../../../actions/talent/talentList";
import ModalView from "../../commoncomponents/modal/Modal";
import Sort from "../../commoncomponents/sort/Index";
import Filter from "../../newFilter/Index";
import Loader from "../../commoncomponents/Loader/Loader";
import {
  sortObject,
  sortObjectRecruiter,
  orderList,
} from "../../pagedata/PageData";
import {
  getUserRole,
  loginUserName,
  getTenantType,
} from "../../../../service/AuthService";

import AddTalent from "../addtalent/Index";
import Event from "../../../../googleanalytic/Tracking";
import TalentsList from "../../../../components/components_v3/superAdmin/Dashboard/Talents/TalentsList";
import { useHistory } from "react-router-dom";
import JobModal from "./JobModal";
import SubCommModal from "./SubCommModal";
import TagModal from "./TagModal";
import SMSModal from "./SMSModal";
import UnsubscribeModal from "./UnsubscribeModal";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import BulkCommunicationModal from "./communication/BulkCommunicationModal";
import SaveSearch from "./SaveSearch/SaveSearch";
import SavedSearchList from "./SaveSearch/SavedSearchList";
import { Button, Modal } from "react-bootstrap";
import CreateSubPool from "../talentSubPool/CreateSubPool";
import { getTalentSubPoolCount } from "../../../../actions/talent/TalentSubPoolList";

import IndustryList from "../talentSubPool/IndustryList";
import TalentSubPoolList from "../talentSubPool/TalentSubPoolList";

const TalentList = (props) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const [parentTab, setParentTab] = useState("All");
  const [selectedTalents, setSelectedTalents] = useState([]);
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [showSavedSearch, setShowSavedSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [toggleActions, setToggleActions] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [talentDetails, setTalentDetails] = useState([]);
  const [talentDetailsTemp, setTalentDetailsTemp] = useState([]);
  const [talents, setTalents] = useState([]);
  const [showAddTalentModal, setShowAddTalentModal] = useState(false);
  const [showjobModal, setShowJobModal] = useState(false);
  const [showSubCommModal, setShowSubCommModal] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmBulkSelect, setConfirmBulkSelect] = useState(true);
  const [getTab, setGetTab] = useState("all");
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const [tabStatus, setTabStatus] = useState(null);
  const [confirmSelectModal, setConfirmSelectModal] = useState(false);
  const [viewType, setViewType] = useState({
    listView: true,
    gridView: false,
    tableView: false,
  });
  const [inputList, setInputList] = useState({
    term1: [],
    term2: [],
    term3: [],
  });

  const [skillInputList, setSkillInputList] = useState({
    term1: [],
    term2: [],
    term3: [],
  });
  const [selectedData, setSelectedData] = useState({
    dispositionStatus: [],
    recruiterName: [],
    certifications: [],
    nursingSpecialty: [],
    workAuthorisation: [],
    experienceLevel: [],
    jobTitle: [],
    jobType: [],
    educations: [],
    country: "",
    state: "",
    address: [],
    licenseStates: [],
    tags: [],
    sourceChannel: [],
    internationalPlacement: [],
    talentAcquisition: [],
    payRate: {},
    workAuthCountry: "",
    subscriptionStatus: [],
  });
  const [showCandidateProfile, setShowCandidateProfile] = useState(false);

  const [dataTotal, setDataTotal] = useState(0);
  const [filterParams, setFilterParams] = useState({}); //only use for page change

  /****************Sort Params******************/
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);

  /****************Sort Params******************/

  /****************Filter Params******************/
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isFilterAppliedPay, setIsFilterAppliedPay] = useState(false);
  /****************Filter Params******************/
  const {
    talentData,
    talentCount,
    loading,
    talentCountloading,
    talentSubPoolCount,
    subPoolCountLoading,
  } = useSelector(
    (state) => ({
      talentData: state.TalentList.talentData,
      talentCount: state.TalentCount.talentCount,
      talentSubPoolCount: state.getTalentSubPoolListReducer.talentSubPoolCount,
      subPoolCountLoading:
        state.getTalentSubPoolListReducer.talentSubPoolCountLoading,
      loading: state.TalentList.loading,
      talentCountloading: state.TalentCount.loading,
    }),
    shallowEqual
  );

  const [successData] = useState({
    title: "Talent Created",
    subTitle: "Your talent was created successfully",
    htmlTag: "",
  });

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );
  const [matches768, setMatches768] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const [pool, setPool] = useState(true);
  const [subPool, setSubPool] = useState(false);
  const [subPoolList, setSubPoolList] = useState(false);

  const [selectedPool, setSelectedPool] = useState({});
  const [selectedIndustry, setSelectedIndustry] = useState(null);

  const toggleActionsDropdown = () => {
    if (toggleActions === false) {
      setToggleActions(true);
    } else {
      setToggleActions(false);
    }
  };

  const fetchData = (currentPage, keepFilterParams) => {
    let payload = {
      currentPage: currentPage,
      limit: 10,
      status: tabStatus,
      sortParam: sortArray,
      tab: parentTab,
      filterObject: keepFilterParams
        ? filterParams
        : getFilterParamsNew("", ""),
      isFrom:
        props.isFrom == "high5talentpool"
          ? "High5"
          : props.isFrom == "gigtalentpool"
          ? "TalentPool"
          : "",
    };
    dispatch(getTalentList(payload));
  };

  const changeSelectedPool = (value) => {
    let val = {
      ...value,
      visaStatus: value?.workAuthorisation,
      designation: value?.jobTitle,
      candidateTags: value?.tags,
      keywords: Object.keys(value?.keywords).map((term) => ({
        keys: value?.keywords[term],
      })),
      skills: Object.keys(value?.skills).map((term) => ({
        keys: value?.skills[term],
      })),
    };
    delete val.workAuthorisation;
    delete val.jobTitle;
    delete val.tags;
    return val;
  };

  useEffect(() => {
    if (Object.keys(selectedPool)?.length !== 0) {
      let payload = {
        currentPage: currentPage,
        limit: 10,
        status: tabStatus,
        sortParam: sortArray,
        tab: parentTab,
        filterObject: changeSelectedPool(selectedPool),
        isFrom:
          props.isFrom == "high5talentpool"
            ? "High5"
            : props.isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };

      dispatch(getTalentList(payload));
      let payload2 = {
        tab: parentTab,
        status:
          props.isFrom == "high5talentpool"
            ? "High5"
            : props.isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
        filterObject: changeSelectedPool(selectedPool),
      };
      dispatch(getTalentListCount(payload2));

      Object.keys(selectedPool)?.length > 0 &&
        setSkillInputList(...selectedPool?.skills);
      Object.keys(selectedPool)?.length > 0 &&
        setSelectedData({
          dispositionStatus: selectedPool?.dispositionStatus,
          recruiterName: selectedPool?.recruiterName,
          certifications: selectedPool?.certifications,
          workAuthorisation: selectedPool?.workAuthorisation,
          subscriptionStatus: selectedPool?.subscriptionStatus,
          experienceLevel: selectedPool?.experienceLevel,
          jobTitle: selectedPool?.jobTitle,
          jobType: selectedPool?.jobType,
          educations: selectedPool?.educations,
          country: selectedPool?.country,
          state: selectedPool?.state,
          address: selectedPool?.address,
          tags: selectedPool?.tags,
          sourceChannel: selectedPool?.sourceChannel,
          internationalPlacement: selectedPool?.internationalPlacement,
          talentAcquisition: selectedPool?.talentAcquisition,
          payRate: selectedPool?.payRate,
          nursingSpecialty: selectedPool?.nursingSpecialty,
          licenseStates: selectedPool?.licenseStates,
        });

      Object.keys(selectedPool)?.length > 0 &&
        setInputList(...selectedPool?.keywords);
    }
  }, [selectedPool]);

  const fetchTalentCount = (keepFilterParams) => {
    let payload = {
      tab: parentTab,
      status:
        props.isFrom == "high5talentpool"
          ? "High5"
          : props.isFrom == "gigtalentpool"
          ? "TalentPool"
          : "",
      filterObject: keepFilterParams
        ? filterParams
        : getFilterParamsNew("", ""),
    };
    dispatch(getTalentListCount(payload));
  };
  const handleBulkSelect = () => {
    try {
      let payload = {
        status: tabStatus,
        sortParam: sortArray,
        tab: parentTab,
        filterObject: filterParams,
        isFrom:
          props.isFrom == "high5talentpool"
            ? "High5"
            : props.isFrom == "gigtalentpool"
            ? "TalentPool"
            : "",
      };
      setIsLoading(true);
      post("/bulkTalentAction/talentAction", payload).then((res) => {
        let data = Array.isArray(res.data) ? res.data : [];
        setSelectedTalents(data);
        setIsLoading(false);
      });
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };
  const handleSelectAll = () => {
    if (dataTotal >= 1000) {
      setConfirmBulkSelect(false);
      setConfirmSelectModal(true);
    } else {
      handleBulkSelect();
    }
  };

  useEffect(() => {
    fetchTalentCount();
    fetchData(currentPage);

    let subData = {
      searchText: "",
    };
    dispatch(getTalentSubPoolCount(subData));
    window
      .matchMedia("(max-width: 1200px)")
      .addEventListener("change", (e) => setMatches(e.matches));
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener("change", (e) => setMatches768(e.matches));
  }, []);

  const handleAction = (action) => {
    if (action === "inviteToJob") setShowJobModal(true);
    if (action === "inviteToSubComm") setShowSubCommModal(true);
    if (action === "bulkTag") setShowTagModal(true);
    if (action === "email") setShowEmailModal(true);
    if (action === "sms") setShowSMSModal(true);
    if (action === "unsubscribe") setShowConfirmationModal(true);
    toggleActionsDropdown();
  };

  useEffect(() => {
    if (getTab === "all") {
      setDataTotal(getTalentCount("all"));
    } else if (getTab === "automatched") {
      setDataTotal(getTalentCount("automatched"));
    } else if (getTab === "invited") {
      setDataTotal(getTalentCount("invited"));
    } else if (getTab === "submitted") {
      setDataTotal(getTalentCount("submitted"));
    } else if (getTab === "readytohire") {
      setDataTotal(getTalentCount("readytohire"));
    } else if (getTab === "shortlisted") {
      setDataTotal(getTalentCount("shortlisted"));
    } else if (getTab === "offered") {
      setDataTotal(getTalentCount("offered"));
    } else if (getTab === "hired") {
      setDataTotal(getTalentCount("hired"));
    } else if (getTab === "disqualified") {
      setDataTotal(getTalentCount("disqualified"));
    } else if (getTab === "unsubscribe") {
      setDataTotal(getTalentCount("unsubscribe"));
    }
  }, [talentCount, getTab]);

  const getTalentCount = (val) => {
    return talentCount && talentCount[0] && talentCount[0][val];
  };

  useEffect(() => {
    if (matches) {
      onTabClick("toggleView", "listView");
    } else {
      onTabClick("toggleView", "listView");
    }
  }, [matches]);

  useEffect(() => {
    let sortData =
      getUserRole() === "Recruiter" ? sortObjectRecruiter : sortObject;
    const tempArray = sortData.map((item) => item.value);
    setSorttype(tempArray);
  }, []);

  useEffect(() => {
    if (talentData && talentData.length > 0) {
      const data = talentData;
      let emp = [];

      data?.forEach((t) => {
        t?.experiences?.forEach((e) => {
          if (
            e?.employerName &&
            !emp?.includes(e?.employerName.toLowerCase()) &&
            e.isSelect &&
            e?.employerName?.length > 2
          )
            emp?.push(e?.employerName?.toLowerCase());
        });
      });
      setTalents(data);
      setTalentDetails(
        data.map((item) => {
          return { ...item, isSelected: false };
        })
      );
      setTalentDetailsTemp(
        data.map((item) => {
          return { ...item, isSelected: false };
        })
      );
    } else {
      setTalents([]);
      setTalentDetails([]);
      setTalentDetailsTemp([]);
    }
  }, [talentData]);

  const applySort = (sortedData) => {
    setSort(false);
    setCurrentPage(1);
    let payload = {
      currentPage: 1,
      limit: 10,
      status: tabStatus,
      tab: parentTab,
      sortParam: sortArray,
      filterObject: filterParams,
      isFrom:
        props.isFrom == "high5talentpool"
          ? "High5"
          : props.isFrom == "gigtalentpool"
          ? "TalentPool"
          : "",
      // filterObject: getFilterParamsNew("", ""),
    };
    dispatch(getTalentList(payload));
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    setSortApplied(false);
    let sortData =
      getUserRole() === "Recruiter" ? sortObjectRecruiter : sortObject;
    const tempArray = sortData.map((item) => item.value);
    setSorttype(tempArray);
    setSort(false);
    setCurrentPage(1);
    let payload = {
      currentPage: 1,
      limit: 10,
      status: tabStatus,
      tab: parentTab,
      sortParam: [],
      filterObject: filterParams,
      isFrom:
        props.isFrom == "high5talentpool"
          ? "High5"
          : props.isFrom == "gigtalentpool"
          ? "TalentPool"
          : "",
    };
    dispatch(getTalentList(payload));
  };

  const clearFilterState = () => {
    setSelectedData({
      dispositionStatus: [],
      recruiterName: [],
      certifications: [],
      workAuthorisation: [],
      subscriptionStatus: [],
      experienceLevel: [],
      jobTitle: [],
      jobType: [],
      educations: [],
      country: "",
      state: "",
      address: [],
      tags: [],
      sourceChannel: [],
      internationalPlacement: [],
      talentAcquisition: [],
      payRate: {},
      nursingSpecialty: [],
      licenseStates: [],
    });
    setIsFilterApplied(false);
    setIsFilterAppliedPay(false);
    setMax("");
    setMin("");
    setInputList({
      term1: [],
      term2: [],
      term3: [],
    });
    setSkillInputList({
      term1: [],
      term2: [],
      term3: [],
    });
  };

  const handleClear = () => {
    clearFilterState();

    applyFilter("", "");
  };

  const filterLength =
    selectedData?.dispositionStatus?.length +
    selectedData?.tags?.length +
    selectedData?.sourceChannel?.length +
    selectedData?.subscriptionStatus?.length +
    selectedData?.workAuthorisation?.length +
    selectedData?.experienceLevel?.length +
    selectedData?.educations?.length +
    (selectedData?.country ? 1 : 0) +
    (selectedData?.state ? 1 : 0) +
    (selectedData?.address ? 1 : 0) +
    selectedData?.jobTitle?.length +
    selectedData?.jobType?.length +
    selectedData?.recruiterName?.length +
    selectedData?.certifications?.length +
    inputList?.term1?.length +
    inputList?.term2?.length +
    inputList?.term3?.length +
    skillInputList?.term1?.length +
    skillInputList?.term2?.length +
    skillInputList?.term3?.length;

  const onTabClick = (category, val) => {
    setCurrentPage(1);
    resetTabs([category]);
    updateTab(val);
  };

  const updateTab = (type) => {
    let temp = { ...viewType };
    if (type === "listView") {
      temp["listView"] = true;
      temp["gridView"] = false;
      temp["tableView"] = false;
    }
    if (type === "gridView") {
      temp["listView"] = false;
      temp["gridView"] = true;
      temp["tableView"] = false;
    }
    if (type === "tableView") {
      temp["listView"] = false;
      temp["gridView"] = false;
      temp["tableView"] = true;
    }
    setViewType(temp);
  };

  const resetTabs = (type) => {
    if (type === "listView" && viewType.listView === true) {
      setViewType({ ...viewType, listView: false });
    }
    if (type === "gridView") {
      setViewType({ ...viewType, gridView: false });
    }
    if (type === "tableView") {
      setViewType({ ...viewType, tableView: false });
    }
  };

  const history = useHistory();

  const broadcastAll = () => {
    let filteredCandidates = talentDetails?.filter(
      (item) => item.newSletter !== "Unsubscribed"
    );
    const obj = {
      talentData:
        selectedTalents?.length > 0 ? selectedTalents : filteredCandidates,
      isFrom: "talentCommunity",
      for: "email",
    };
    history.push({
      pathname: "/high5hire/broadcast",
      state: obj,
    });
  };
  const sendSMSAll = () => {
    let filteredCandidates = talentDetails?.filter(
      (item) => item.newSletter !== "Unsubscribed"
    );
    let obj = {
      contactData:
        selectedTalents?.length > 0 ? selectedTalents : filteredCandidates,
      isFrom: "talentCommunity",
      for: "sms",
    };
    history.push({
      pathname: "/high5hire/broadcast",
      state: obj,
    });
  };

  const onParentTabClick = (type) => {
    clearFilterState();
    setParentTab(type);
    setCurrentPage(1);
    let payload = {
      tab: type,
      filterObject: getFilterParamsNew("", ""),
      status:
        props.isFrom == "high5talentpool"
          ? "High5"
          : props.isFrom == "gigtalentpool"
          ? "TalentPool"
          : "",
    };
    dispatch(getTalentListCount(payload));
    payload = {
      currentPage: 1,
      filterObject: getFilterParamsNew("", ""),
      limit: 10,
      status: null,
      tab: type,
      sortParam: sortArray,
      isFrom:
        props.isFrom == "high5talentpool"
          ? "High5"
          : props.isFrom == "gigtalentpool"
          ? "TalentPool"
          : "",
    };
    dispatch(getTalentList(payload));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const areAllUnsubbed = () => {
    return selectedTalents.every((x) => x.newSletter === "Unsubscribed");
  };

  const handleFilterRemove = (e, key, item, index) => {
    e.preventDefault();
    const updatedSelectedData = { ...selectedData };
    if (Array.isArray(updatedSelectedData[key])) {
      if (
        key === "nursingSpecialty" ||
        key === "certifications" ||
        key === "licenseStates"
      ) {
        updatedSelectedData[key] = updatedSelectedData[key].filter((i) => {
          return i != item[index];
        });
      } else {
        updatedSelectedData[key] = updatedSelectedData[key].filter((i) => {
          return i.value != item[index].value;
        });
      }
    } else if (typeof updatedSelectedData[key] === "string") {
      updatedSelectedData[key] = "";
    } else if (key === "payRate") {
      updatedSelectedData[key] = {};
      setMax("");
      setMin("");
      setIsFilterAppliedPay(false);
    }
    applyFilter(updatedSelectedData[key], key);
    setSelectedData(updatedSelectedData);
  };

  const handleFilterRemoveFromKeyWord = (e, key, index) => {
    e.preventDefault();
    const updatedInputList = { ...inputList };
    if (Array.isArray(updatedInputList[key])) {
      updatedInputList[key] = updatedInputList[key].filter(
        (_, i) => i !== index
      );
      setInputList(updatedInputList);
    }

    applyFilter(updatedInputList, "keywordSearch");
  };
  const handleFilterRemoveFromSkill = (e, key, index) => {
    e.preventDefault();
    const updatedInputList = { ...skillInputList };
    if (Array.isArray(updatedInputList[key])) {
      updatedInputList[key] = updatedInputList[key].filter(
        (_, i) => i !== index
      );
      setSkillInputList(updatedInputList);
    }
    applyFilter(updatedInputList, "skills");
  };

  const applyFilter = (val, status) => {
    setShowCandidateProfile(false);
    setCurrentPage(1);
    let payload = {
      tab: parentTab,
      filterObject: getFilterParamsNew(val, status),
      status:
        props.isFrom == "high5talentpool"
          ? "High5"
          : props.isFrom == "gigtalentpool"
          ? "TalentPool"
          : "",
    };
    dispatch(getTalentListCount(payload));
    payload = {
      currentPage: 1,
      limit: 10,
      status: null,
      tab: parentTab,
      sortParam: sortArray,
      filterObject: getFilterParamsNew(val, status),
      isFrom:
        props.isFrom == "high5talentpool"
          ? "High5"
          : props.isFrom == "gigtalentpool"
          ? "TalentPool"
          : "",
    };
    setSelectedTalents([]);
    dispatch(getTalentList(payload));
  };

  const handleSkillStructure = (inputParam) => {
    let newData = Object.keys(inputParam).map((term) => ({
      keys: inputParam[term],
    }));
    return newData;
  };

  const getFilterParamsNew = (val, status) => {
    let keywords = [];
    let skills = [];

    if (status === "keywordSearch") {
      keywords = Object.keys(val).map((term) => ({
        keys: val[term],
      }));
    }
    if (status === "skills") {
      skills = Object.keys(val).map((term) => ({
        keys: val[term],
      }));
    }

    const filterParam = {
      dispositionStatus:
        status == ""
          ? []
          : status == "dispositionStatus"
          ? val
          : selectedData.dispositionStatus,
      visaStatus:
        status == ""
          ? []
          : status == "workAuthorisation"
          ? val
          : selectedData.workAuthorisation,

      workAuthCountry:
        status == ""
          ? ""
          : status == "workAuthCountry"
          ? val ?? ""
          : status == "workAuthorisation"
          ? val.some(
              (x) =>
                x.label === "Permanent Resident" ||
                x.label === "Citizen" ||
                x.label === "Authorised to work"
            )
            ? selectedData.workAuthCountry
            : ""
          : selectedData.workAuthCountry ?? "",
      experienceLevel:
        status == ""
          ? []
          : status == "experienceLevel"
          ? val
          : selectedData.experienceLevel,
      designation:
        status == "" ? [] : status == "jobTitle" ? val : selectedData.jobTitle,
      positionTypes:
        status == "" ? [] : status == "jobType" ? val : selectedData.jobType,
      educations:
        status == ""
          ? []
          : status == "educations"
          ? val
          : selectedData.educations,
      country:
        status == ""
          ? ""
          : status == "country"
          ? val
          : status == "state"
          ? ""
          : status == "address"
          ? ""
          : selectedData.country,
      state:
        status == ""
          ? ""
          : status == "state"
          ? val
          : status == "address"
          ? ""
          : status == "country"
          ? ""
          : selectedData.state,
      address:
        status == ""
          ? []
          : status == "address"
          ? val
          : status == "country"
          ? []
          : status == "state"
          ? []
          : selectedData.address,
      candidateTags:
        status == "" ? [] : status == "tags" ? val : selectedData.tags,
      sourceChannel:
        status == ""
          ? []
          : status == "sourceChannel"
          ? Array.isArray(val)
            ? val
            : [val]
          : selectedData.sourceChannel,
      subscriptionStatus:
        status == ""
          ? []
          : status == "subscriptionStatus"
          ? Array.isArray(val)
            ? val
            : [val]
          : selectedData.subscriptionStatus,
      internationalPlacement:
        status == ""
          ? []
          : status == "internationalPlacement"
          ? val
          : selectedData.internationalPlacement,
      talentAcquisition:
        status == ""
          ? []
          : status == "talentAcquisition"
          ? val
          : selectedData.talentAcquisition,
      payRate:
        status == "" ? [] : status == "payRate" ? val : selectedData.payRate,
      keywords:
        status === ""
          ? []
          : status == "keywordSearch"
          ? keywords
          : handleSkillStructure(inputList),
      skills:
        status === ""
          ? []
          : status == "skills"
          ? skills
          : handleSkillStructure(skillInputList),
      certifications:
        status == ""
          ? []
          : status == "certifications"
          ? val
          : selectedData.certifications,
      nursingSpecialty:
        status == ""
          ? []
          : status == "nursingSpecialty"
          ? val
          : selectedData.nursingSpecialty,
      licenseStates:
        status == "" || (status == "certifications" && val.length === 0)
          ? []
          : status == "licenseStates"
          ? val
          : selectedData.licenseStates,
      recruiterName:
        status == ""
          ? []
          : status == "recruiterName"
          ? Array.isArray(val)
            ? val
            : [val]
          : selectedData.recruiterName,
    };

    setFilterParams(filterParam);
    return filterParam;
  };

  const filterElements = Object.entries(selectedData).map(([key, value]) => {
    if (Array.isArray(value))
      return value.map((i, index) => {
        return (
          <span key={i.value} className="tag tag-blue2 p-1 px-2 m-1">
            <span>{i.label ? i.label.replace(/\s*\(\d+\)/, "") : i}</span>{" "}
            <a
              href="#"
              className="font-black"
              onClick={(e) => handleFilterRemove(e, key, value, index)}
            >
              <i className="fas fa-times ml-2 "></i>
            </a>
          </span>
        );
      });
    else if (typeof value === "string" && value !== "")
      return (
        <span key={value} className="tag tag-blue2 p-1 px-2 m-1">
          <span>{value}</span>{" "}
          <a
            href="#"
            className="font-black"
            onClick={(e) => handleFilterRemove(e, key, value)}
          >
            <i className="fas fa-times ml-2 "></i>
          </a>
        </span>
      );
    else if (key == "payRate" && isFilterAppliedPay) {
      return (
        <span key={value} className="tag tag-blue2 p-1 px-2 m-1">
          <span>
            {value.min} to {value.max} {value.payType}
          </span>{" "}
          <a
            href="#"
            className="font-black"
            onClick={(e) => handleFilterRemove(e, key, value)}
          >
            <i className="fas fa-times ml-2 "></i>
          </a>
        </span>
      );
    }
  });

  const filterKeyword = Object.entries(inputList).map(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map((skill, index) => (
        <span key={index} className="tag tag-blue2 p-1 px-2 m-1">
          <span>{skill}</span>{" "}
          <a
            href="#"
            className="font-black"
            onClick={(e) => handleFilterRemoveFromKeyWord(e, key, index)}
          >
            <i className="fas fa-times ml-2 "></i>
          </a>
        </span>
      ));
    }
    return null; // Make sure to handle the case when value is not an array
  });
  const filterSkills = Object.entries(skillInputList).map(([key, value]) => {
    if (Array.isArray(value)) {
      return value.map((skill, index) => (
        <span key={index} className="tag tag-blue2 p-1 px-2 m-1">
          <span>{skill}</span>{" "}
          <a
            href="#"
            className="font-black"
            onClick={(e) => handleFilterRemoveFromSkill(e, key, index)}
          >
            <i className="fas fa-times ml-2 "></i>
          </a>
        </span>
      ));
    }
    return null; // Make sure to handle the case when value is not an array
  });

  const isFilterEnabled = () =>
    filterElements.some((item) =>
      Array.isArray(item) ? item.length > 0 : item
    ) ||
    filterKeyword.some((x) => x?.length > 0) ||
    filterSkills.some((x) => x?.length > 0);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setToggleActions(false);
    }
  };
  useEffect(() => {
    // Attach the event listener on mount
    document.addEventListener("click", handleClickOutside);

    // Detach the event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render
  const handleTalentClick = (type) => {
    if (type === "pool") {
      setPool(true);
      setSubPool(false);
      setSubPoolList(false);
      if (props?.setShowTab) props?.setShowTab("pool");
    } else if (type === "subPool") {
      setPool(false);
      setSubPool(true);
      setSubPoolList(false);
      if (props?.setShowTab) props?.setShowTab("subPool");
    } else if (type === "subPoolList") {
      setPool(false);
      setSubPool(false);
      setSubPoolList(true);
      if (props?.setShowTab) props?.setShowTab("subPoolList");
    }
  };
  return (
    <>
      {(loading || talentCountloading || isLoading || subPoolCountLoading) && (
        <Loader />
      )}
      <div class="row justify-content-center mt-3">
        <div class="col-lg-12">
          <div class="card card-flat card-borderless  p-0-600 mb-1">
            <div class="d-flex align-items-center justify-content-between mb-1 border-bottom-gray2">
              {props.parent == "recruiterdashboard" ? (
                <h6 class="mr-3 mb-0 mb-xl-0">
                  Talent Pool ({new Intl.NumberFormat().format(dataTotal) ?? 0})
                  <a href="/high5Hire/talents">
                    <i className="fas fa-external-link-square"></i>
                  </a>
                </h6>
              ) : (
                <div className="d-flex">
                  <ul className="nav nav-tabs  ">
                    {!props?.curation && (
                      <li className="nav-item">
                        <button
                          onClick={() => {
                            handleTalentClick("pool");
                          }}
                          type="button"
                          className={
                            pool ? "nav-link pb-3 active" : "nav-link pb-3"
                          }
                        >
                          {" "}
                          {!props?.curation && (
                            <h6>
                              Talent Pool (
                              {props.isFrom == "high5talentpool"
                                ? new Intl.NumberFormat().format(dataTotal) ?? 0
                                : new Intl.NumberFormat().format(dataTotal) ??
                                  0}
                              )
                            </h6>
                          )}
                        </button>
                      </li>
                    )}

                    {props.isFrom != "gigtalentpool" &&
                      !props?.curation &&
                      (getUserRole() == "Recruiter" ||
                        getUserRole() == "CustomerSuccess") && (
                        <li className="nav-item">
                          <button
                            onClick={() => {
                              handleTalentClick("subPoolList");
                            }}
                            type="button"
                            className={
                              subPoolList
                                ? "nav-link pb-3 active"
                                : "nav-link pb-3"
                            }
                          >
                            {" "}
                            <h6> Talent Sub Pools</h6>
                          </button>
                        </li>
                      )}
                    {props.isFrom != "gigtalentpool" &&
                      !props?.curation &&
                      (getUserRole() == "Recruiter" ||
                        getUserRole() == "CustomerSuccess") && (
                        <li className="nav-item">
                          <button
                            onClick={() => {
                              handleTalentClick("subPool");
                            }}
                            type="button"
                            className={
                              subPool ? "nav-link pb-3 active" : "nav-link pb-3"
                            }
                          >
                            {" "}
                            <h6>
                              {" "}
                              Sub Pool by Industry
                              {/* (
                              {props.isFrom == "high5talentpool"
                                ? new Intl.NumberFormat().format(
                                    talentSubPoolCount &&
                                      talentSubPoolCount[0].subPoolCount
                                  ) ?? 0
                                : new Intl.NumberFormat().format(
                                    talentSubPoolCount &&
                                      talentSubPoolCount[0].subPoolCount
                                  ) ?? 0}
                              ) */}
                            </h6>
                          </button>
                        </li>
                      )}
                  </ul>
                </div>
              )}
              <div class="d-flex mb-2 align-items-center justify-content-center justify-content-even-400">
                <div class="d-flex justify-content-end justify-content-even-400">
                  {(getUserRole() === "Recruiter" ||
                    getUserRole() === "CustomerSuccess") &&
                    (subPool || subPoolList) &&
                    dataTotal &&
                    dataTotal > 0 && (
                      <Button
                        variant="success"
                        size="sm"
                        onClick={() => handleTalentClick("pool")}
                      >
                        <FaUsersCog size={20} className="mr-2" />
                        Create Talent Sub Pool
                      </Button>
                    )}
                  {(getUserRole() === "Recruiter" ||
                    getUserRole() === "CustomerSuccess") &&
                    !subPool &&
                    props.isFrom != "gigtalentpool" && (
                      <CreateSubPool
                        selectedData={selectedData}
                        inputList={inputList}
                        skillInputList={skillInputList}
                        isFilterApplied={isFilterEnabled()}
                        clearFilterState={clearFilterState}
                        dataTotal={dataTotal}
                        handleTalentClick={handleTalentClick}
                      />
                    )}
                  {pool && (
                    <Sort
                      sort={sort}
                      setSort={setSort}
                      sortCount={sortCount}
                      sortData={talents}
                      orderArray={orderList}
                      sortObjects={sortObjectRecruiter}
                      applySort={applySort}
                      setSortCount={setSortCount}
                      setSortArray={setSortArray}
                      sortArray={sortArray}
                      sortingFor={"Sort by Name, Location and Last Updated"}
                      sortType={sortType}
                      setSorttype={setSorttype}
                      sortApplied={sortApplied}
                      setSortApplied={setSortApplied}
                      sortClearAll={sortClearAll}
                    />
                  )}
                </div>
                {getUserRole() == "Recruiter" && !subPool && (
                  <div class="d-flex align-items-center">
                    <button
                      type="button"
                      class="btn btn-sm"
                      onClick={() => {
                        Event(
                          "Add Talent Clicked",
                          `${loginUserName()},${getUserRole()}`,
                          window.location.pathname
                        );
                        setShowAddTalentModal(true);
                      }}
                    >
                      <i aria-hidden="true" class="fas fa-fw mr-2 fa-plus "></i>
                      Add Talent
                    </button>
                  </div>
                )}
              </div>
            </div>
            <div class="d-none d-block-768 "></div>
            {pool && (
              <>
                {getTenantType() == 1 && (
                  <div className="d-flex justify-content-between mt-3">
                    <ul class="nav nav-tabs  ">
                      <li class="nav-item">
                        <button
                          onClick={() => {
                            onParentTabClick("All");
                          }}
                          type="button"
                          class={
                            parentTab === "All"
                              ? "nav-link pb-3 active"
                              : "nav-link pb-3"
                          }
                        >
                          <div>All</div>
                        </button>
                      </li>
                      <li class="nav-item">
                        <button
                          onClick={() => {
                            onParentTabClick("Private Talent Pool");
                          }}
                          type="button"
                          class={
                            parentTab === "Private Talent Pool"
                              ? "nav-link pb-3 active"
                              : "nav-link pb-3"
                          }
                        >
                          <div>Private Talent Pool</div>
                        </button>
                      </li>
                      <li class="nav-item">
                        <button
                          onClick={() => {
                            onParentTabClick("External Talent Pool");
                          }}
                          type="button"
                          class={
                            parentTab === "External Talent Pool"
                              ? "nav-link pb-3 active"
                              : "nav-link pb-3"
                          }
                        >
                          <div>Public Talent Pool</div>
                        </button>
                      </li>
                    </ul>
                  </div>
                )}
                <div className=" mt-1">
                  {viewType.listView === true && (
                    <div className="d-flex gap-2 mt-2">
                      <div className="col-12">
                        {isFilterApplied && (
                          <div className="d-flex justify-content-end">
                            <div>
                              {talentDetails?.length > 0 && (
                                <>
                                  <span
                                    title={
                                      selectedTalents?.length > 0
                                        ? `Send email to ${selectedTalents?.length} selected talents`
                                        : "Send email to all talents"
                                    }
                                    className="btn btn-action-panel btn-icon btn-nav ml-3"
                                  >
                                    <a
                                      onClick={broadcastAll}
                                      className="EmailBroadcastIcon"
                                    >
                                      <i
                                        class="fa fa-envelope"
                                        aria-hidden="true"
                                        style={{ color: "#1a8cff" }}
                                      ></i>
                                    </a>{" "}
                                  </span>
                                </>
                              )}
                            </div>
                          </div>
                        )}

                        <div className="d-flex d-block-768 flex-row ">
                          <div className={!matches768 ? "col-3 mr-2" : ""}>
                            <Filter
                              sortArray={sortArray}
                              setCurrentPage={setCurrentPage}
                              currentPage={currentPage}
                              selectedData={selectedData}
                              setSelectedData={setSelectedData}
                              applyFilter={applyFilter}
                              getFilterParamsNew={getFilterParamsNew}
                              inputList={inputList}
                              setInputList={setInputList}
                              skillInputList={skillInputList}
                              setSkillInputList={setSkillInputList}
                              isFilterApplied={isFilterAppliedPay}
                              setIsFilterApplied={setIsFilterAppliedPay}
                              min={min}
                              max={max}
                              setMax={setMax}
                              setMin={setMin}
                              filterLength={filterLength}
                              handleClear={handleClear}
                              filterParams={filterParams}
                              setFilterParams={setFilterParams}
                              loading={loading}
                              talentCountloading={talentCountloading}
                              isFrom={props.isFrom}
                            />
                          </div>
                          <div className={!matches768 ? "col-9" : ""}>
                            <div className="d-flex justify-content-between mb-2">
                              <div className=" d-flex justify-content-between mb-2">
                                <div className="d-block  ">
                                  {filterElements} {filterSkills}{" "}
                                  {filterKeyword}
                                </div>
                              </div>

                              {isFilterEnabled() &&
                                selectedTalents.length > 0 &&
                                (getUserRole() === "Recruiter" ||
                                  getUserRole() === "CustomerSuccess") && (
                                  <div className="d-block" ref={dropdownRef}>
                                    <div className="dropdown">
                                      <button
                                        className="btn btn-sm dropdown-toggle"
                                        type="button"
                                        onClick={toggleActionsDropdown}
                                        style={{
                                          zIndex: 90,
                                        }}
                                        disabled={areAllUnsubbed()}
                                      >
                                        Actions
                                      </button>
                                      <div>
                                        {toggleActions && (
                                          <div
                                            className="dropdown-menu"
                                            style={{
                                              display: "revert",
                                              right: "-4px",
                                              top: "32px",
                                              zIndex: 89,
                                            }}
                                          >
                                            <div>
                                              <button
                                                onClick={() =>
                                                  handleAction("inviteToJob")
                                                }
                                                className="dropdown-item btn-xs"
                                              >
                                                Invite to Job
                                              </button>
                                              <hr className="p-0 m-1" />
                                            </div>{" "}
                                            <div>
                                              <button
                                                onClick={() =>
                                                  handleAction(
                                                    "inviteToSubComm"
                                                  )
                                                }
                                                className="dropdown-item btn-xs"
                                              >
                                                Invite to Community
                                              </button>
                                              <hr className="p-0 m-1" />
                                            </div>{" "}
                                            <div>
                                              <button
                                                onClick={() =>
                                                  handleAction("bulkTag")
                                                }
                                                className="dropdown-item btn-xs"
                                              >
                                                {" "}
                                                Bulk Tag
                                              </button>
                                              <hr className="p-0 m-1" />
                                            </div>{" "}
                                            <div>
                                              <button
                                                onClick={() =>
                                                  handleAction("email")
                                                }
                                                disabled={
                                                  selectedData
                                                    ?.subscriptionStatus[0]
                                                    ?.value === "Unsubscribed"
                                                }
                                                className="dropdown-item btn-xs"
                                              >
                                                {" "}
                                                Email/SMS
                                              </button>
                                              <hr className="p-0 m-1" />
                                            </div>{" "}
                                            <div>
                                              <button
                                                onClick={() =>
                                                  handleAction("unsubscribe")
                                                }
                                                className="dropdown-item btn-xs"
                                              >
                                                {" "}
                                                Move to Unsubscribed
                                              </button>
                                            </div>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <TalentsList
                              setSelectedTalents={setSelectedTalents}
                              selectedTalents={selectedTalents}
                              selectedData={selectedData}
                              currentPage={currentPage}
                              setCurrentPage={setCurrentPage}
                              arrayList={talentDetails}
                              setTalentDetails={setTalentDetails}
                              getTalentList={getTalentList}
                              parent="jobList"
                              getTab={getTab}
                              setGetTab={setGetTab}
                              matches={matches}
                              isFilterApplied={isFilterApplied}
                              filterLength={filterLength}
                              talentDetails={talentDetails}
                              dataTotal={dataTotal}
                              handlePageChange={handlePageChange}
                              isFilterEnabled={isFilterEnabled}
                              handleSelectAll={handleSelectAll}
                              handleClear={handleClear}
                              isFor={"TalentPool"}
                              showCandidateProfile={showCandidateProfile}
                              setShowCandidateProfile={setShowCandidateProfile}
                              parentTab={parentTab}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {subPool && (
        <IndustryList
          setPool={setPool}
          setSubPool={setSubPool}
          setSelectedPool={setSelectedPool}
          selectedIndustry={selectedIndustry}
          setSelectedIndustry={setSelectedIndustry}
        />
      )}
      {subPoolList && (
        <div>
          <TalentSubPoolList
            setPool={setPool}
            setSubPool={setSubPool}
            setSelectedPool={setSelectedPool}
            setSubPoolList={setSubPoolList}
            industry={"All"}
            setSelectedIndustry={""}
            isFrom={"TalentSubPoolList"}
          />
        </div>
      )}
      {successFormOpen && (
        <ModalView
          modalSize={"lg"}
          modalTitle={"Add Talent"}
          isModalOpen={successFormOpen}
          setModalClose={setSuccessFormOpen}
          isFrom="TalentCommunity"
          dataToPass={successData}
        />
      )}
      {showAddTalentModal && (
        <AddTalent
          handleClear={handleClear}
          isFrom={"TalentPool"}
          showAddTalentModal={showAddTalentModal}
          setShowAddTalentModal={setShowAddTalentModal}
          selectedTalents={selectedTalents}
        />
      )}{" "}
      {showjobModal && (
        <JobModal
          showjobModal={showjobModal}
          setShowJobModal={setShowJobModal}
          selectedTalents={selectedTalents}
          setSelectedTalents={setSelectedTalents}
        />
      )}{" "}
      {showSubCommModal && (
        <SubCommModal
          showjobModal={showSubCommModal}
          setShowJobModal={setShowSubCommModal}
          selectedTalents={selectedTalents}
          setSelectedTalents={setSelectedTalents}
        />
      )}{" "}
      {showTagModal && (
        <TagModal
          showjobModal={showTagModal}
          setShowJobModal={setShowTagModal}
          selectedTalents={selectedTalents}
          fetchData={fetchData}
          setSelectedTalents={setSelectedTalents}
        />
      )}{" "}
      {/* {showEmailModal && (
        <EmailModal
          showjobModal={showEmailModal}
          setShowJobModal={setShowEmailModal}
          selectedTalents={selectedTalents}
          setSelectedTalents={setSelectedTalents}
        />
      )}{" "} */}
      {showEmailModal && (
        <BulkCommunicationModal
          selectedTalents={selectedTalents}
          setSelectedTalents={setSelectedTalents}
          isModalOpen={showEmailModal}
          setModalClose={setShowEmailModal}
          modalSize={"xl"}
        />
      )}
      {showSMSModal && (
        <SMSModal
          showjobModal={showSMSModal}
          setShowJobModal={setShowSMSModal}
          selectedTalents={selectedTalents}
          setSelectedTalents={setSelectedTalents}
        />
      )}
      {showConfirmationModal && (
        <UnsubscribeModal
          showConfirmationModal={showConfirmationModal}
          setShowConfirmationModal={setShowConfirmationModal}
          selectedTalents={selectedTalents}
          fetchData={fetchData}
          fetchTalentCount={fetchTalentCount}
          setSelectedTalents={setSelectedTalents}
        />
      )}
      {showSavedSearch && (
        <SavedSearchList
          showSavedSearch={showSavedSearch}
          setShowSavedSearch={setShowSavedSearch}
          setSkillInputList={setSkillInputList}
          setInputList={setInputList}
          setSelectedData={setSelectedData}
          applyFilter={applyFilter}
        />
      )}
      {confirmSelectModal && (
        <>
          <Modal
            show={confirmSelectModal}
            onHide={() => setConfirmSelectModal(false)}
          >
            <Modal.Header closeButton>
              <h6>Confirmation</h6>
            </Modal.Header>
            <Modal.Body className="p-4">
              {loading && <Loader />}
              <p>
                {dataTotal > 1000 ? (
                  <span>
                    You have selected <strong>{dataTotal}</strong> talents. The
                    maximum allowable selection is <strong>1,000</strong>{" "}
                    talents. Do you want to proceed ?
                  </span>
                ) : (
                  <span>
                    Are you sure you want to select the{" "}
                    <strong>{dataTotal}</strong> talents ?
                  </span>
                )}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setConfirmBulkSelect(true);
                  setConfirmSelectModal(false);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="primary"
                onClick={() => {
                  setConfirmSelectModal(false);
                  handleBulkSelect();
                }}
              >
                Confirm
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default TalentList;
