import {
    GET_STAFFING_LIST_BEGIN,
    GET_STAFFING_LIST_SUCCESS,
    GET_STAFFING_LIST_FAILURE
  } from "../../actions/executiveadmin/GetStaffing";
  
  const initialState = {
    staffingListLoading: true,
    staffingList: [],
  };
  
  const staffingListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_STAFFING_LIST_BEGIN:
        return {
          ...state,
          staffingListLoading: true,
          staffingList: [],
        };
      case GET_STAFFING_LIST_SUCCESS:
        return {
          ...state,
          staffingList: payload,
          staffingListLoading: false,
        };
      case GET_STAFFING_LIST_FAILURE:
        return {
          ...state,
          staffingList: [],
          staffingListLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default staffingListReducer;
  