import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "../../../../Loader/Loader";
import TalentView from "../TalentView";
import Sort from "../../../commoncomponents/sort/Index";
import { orderList } from "../../../pagedata/PageData";
import { getTalentCommunity } from "../../../../../actions/directsourcing/talentcommunity/TalentCommunity";
import { getClientNameData } from "../../../../../actions/dashboard/JobSummary";
import {
  getUserRole,
  isCurator,
  getTenantType,
} from "../../../../../service/AuthService";
import Pagination from "../../../../components_v3/pagination/pagination";
import NoRecord from "../../../commoncomponents/norecord/NoRecord";
import AssignCurator from "../../../curator/assigncurator/Index";

const sortObject = [
  {
    id: 1,
    label: "Community Name",
    value: "poolname",
    icon: "fal fa-fw fa-signature mr-2 dd-sort",
  },
  {
    id: 2,
    label: "Location",
    value: "country",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
  {
    id: 3,
    label: "Created On",
    value: "createddatetime",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
];

const PoolList = ({ type, clientId, isFrom }) => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const [pools, setPools] = useState([]);
  const [poolsTotal, setPoolsTotal] = useState(null);
  const [pageSize, setPageSize] = useState(9);
  const history = useHistory();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [options, setOptions] = useState([{ value: "0", label: "All" }]);
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState({
    value: "0",
    label: "All",
  });
  const [selectedClient, setSelectedClient] = useState(0);
  const [assignCuratorShow, setAssignCuratorShow] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const viewParam = searchParams.get("view") || "grid";
  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
  };

  //sort
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);

  const { loading, talentPoolData, clientName, nameLoading } = useSelector(
    (state) => ({
      loading: state.getTalentCommunityReducer.loading,
      talentPoolData: state.getTalentCommunityReducer.talentPoolData,
      clientName: state.getJobSummary.clientName,
      nameLoading: state.getJobSummary.nameLoading,
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   if (isFrom === "HiringManagerDashBoard") setPageSize(3);
  // }, [isFrom]);

  const applySort = (sortedData, close) => {
    setPools(sortedData);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    getPoolFromDB(currentPage, sortedData, pageSize, searchValue, tenantId);
    close && setSort(false);
  };

  const sortClearAll = () => {
    setSortArray([]);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    getPoolFromDB(currentPage, [], pageSize, searchValue, tenantId);
    const tempArray = sortObject.map((item) => item.value);
    setSorttype(tempArray);
    setSortCount(0);
    setSortApplied(false);
    setSort(false);
  };

  useEffect(() => {
    const tempArray = sortObject.map((item) => item.value);
    setSorttype(tempArray);
  }, []);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchValue.length === 0 || searchValue.length >= 2) {
        const tenantId = selectedClient == 0 ? null : selectedClient;
        getPoolFromDB(1, sortArray, pageSize, searchValue, tenantId);
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchValue]);

  useEffect(() => {
    if (getUserRole() == "CustomerSuccess") {
      const delay = 500;
      const debounceTimer = setTimeout(() => {
        if (searchText.length === 0 || searchText.length >= 2) {
          let payload = {
            searchText: searchText,
          };
          dispatch(getClientNameData(payload));
        }
      }, delay);
      return () => clearTimeout(debounceTimer);
    }
  }, [searchText]);

  useEffect(() => {
    if (clientName.length > 0) {
      let op = clientName?.map((item, index) => ({
        value: item.id,
        label: item?.tenantname.replace("GIG - ", ""),
      }));
      setOptions([{ value: 0, label: "All" }, ...op]);
    }
  }, [clientName]);

  const clearSearch = () => {
    setSearchValue("");
    setCurrentPage(1);
  };

  const getPoolFromDB = (
    currentPage,
    sortArray,
    pageSize,
    searchValue,
    tenantId
  ) => {
    const param = {
      currentPage: currentPage,
      sortQuery: sortArray,
      pageSize: isFrom === "HiringManagerDashBoard" ? 3 : pageSize,
      searchText: searchValue ?? searchValue,
      type: type ? type : "",
      clientId: tenantId ? tenantId : clientId ? clientId : null,
    };
    dispatch(getTalentCommunity(param));
  };

  useEffect(() => {
    getPoolFromDB(currentPage, sortArray, pageSize, searchValue);
  }, []);

  useEffect(() => {
    if (talentPoolData) {
      setPools(talentPoolData.data);
      setPoolsTotal(talentPoolData.total);
    }
  }, [talentPoolData]);

  const handleView = (view) => {
    history.push(`?view=${view}`);
  };

  const handleChange = (selected) => {
    isFirstRender.current = true;
    setSelectedOption(selected);
    setSearchValue("");
    setSortArray([]);
    const tenantId = selected.value == 0 ? null : selected.value;
    getPoolFromDB(1, [], pageSize, "", tenantId);
    setSelectedClient(tenantId);
  };

  const handleChangeInput = (e) => {
    setSearchText(e);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
    const tenantId = selectedClient == 0 ? null : selectedClient;
    getPoolFromDB(selectedPage, sortArray, pageSize, searchValue, tenantId);
  };

  return (
    <>
      {(loading || (getUserRole() == "CustomerSuccess" && nameLoading)) && (
        <Loader />
      )}
      <div className="d-flex justify-content-between mb-3 mt-3 border-bottom-gray2">
        <div className="d-flex align-items-center col-md-4">
          <div className="d-none d-xl-flex nav nav-tabs">
            <div className="nav-item">
              {/* <button
                type="button"
                className={`nav-link pb-3 bg-transparent ${
                  !viewParam || viewParam === "grid" ? "active" : ""
                }`}
                onClick={() => handleView("grid")}
              >
                <i className="fas fa-th-large" aria-hidden="true"></i>
              </button> */}
            </div>
            <div className="nav-item">
              {/* <button
                className={`nav-link pb-3 bg-transparent ${
                  viewParam === "list" ? "active" : ""
                }`}
                type="button"
                onClick={() => handleView("list")}
              >
                <i className="fas fa-list" aria-hidden="true"></i>
              </button> */}
            </div>
            <div className="nav-item">
              {/* <button
                className={`nav-link pb-3 bg-transparent ${
                  viewParam === "table" ? "active" : ""
                }`}
                type="button"
                onClick={() => handleView("table")}
              >
                <i className="fas fa-table" aria-hidden="true"></i>
              </button> */}
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end col-md-8 mb-2">
          {isFrom !== "HiringManagerDashBoard" && (
            <div className="d-flex">
              <div className="align-items-center" style={{ minWidth: "220px" }}>
                <input
                  type="text"
                  value={searchValue}
                  onChange={handleSearchChange}
                  className="form-control"
                  placeholder="Search by Community Name"
                  style={{
                    width: "100%",
                    fontSize: "14px",
                  }}
                />
              </div>
              <Sort
                sort={sort}
                setSort={setSort}
                sortCount={sortCount}
                sortData={pools}
                orderArray={orderList}
                sortObjects={sortObject}
                applySort={applySort}
                setSortCount={setSortCount}
                setSortArray={setSortArray}
                sortArray={sortArray}
                sortingFor={"Sort by Community Name, Location and Created On"}
                sortType={sortType}
                setSorttype={setSorttype}
                sortApplied={sortApplied}
                setSortApplied={setSortApplied}
                sortClearAll={sortClearAll}
              />
            </div>
          )}
          {isCurator() && getTenantType() == 1 && (
            <div className="d-flex align-items-center">
              <button
                type="button"
                class="btn btn-sm mr-2"
                onClick={(e) => {
                  setAssignCuratorShow(true);
                }}
              >
                <i class="fa fa-user mr-2" aria-hidden="true"></i> Manage
                Curators
              </button>
            </div>
          )}
          {getUserRole() == "CustomerSuccess" && (
            <div className="d-flex gap-2 align-items-center">
              <div style={{ width: 200, zIndex: 1000 }}>
                <Select
                  options={options}
                  isSearchable={true}
                  onChange={handleChange}
                  styles={styleSheet}
                  value={selectedOption}
                  onInputChange={handleChangeInput}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {!loading ? (
        <>
          <TalentView
            pool={pools}
            isFrom={"PoolList"}
            view={viewParam}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            pageSize={pageSize}
            setPageSize={setPageSize}
          />
          <div className="row w-100 mr-2 ml-3">
            <Pagination
              currentPage={currentPage}
              totalItems={poolsTotal}
              itemsPerPage={isFrom == "HiringManagerDashBoard" ? 3 : pageSize}
              onPageChange={handlePageChange}
              limit={isFrom == "HiringManagerDashBoard" ? 3 : pageSize}
            />
          </div>
        </>
      ) : (
        <NoRecord />
      )}
      {assignCuratorShow && (
        <AssignCurator
          assignCuratorShow={assignCuratorShow}
          setAssignCuratorShow={setAssignCuratorShow}
        />
      )}
    </>
  );
};

export default PoolList;
