import React, { useEffect, useState } from "react";
import moment from "moment";

function SubmitCandidateListView(props) {
  const [newRequestList, setnewRequestList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  // const [ requestTitleLength, setRequestTitleLength ] = useState(0)
  const [requestTitleArray, setRequestTitleArray] = useState([]);
  const [pageType, setPageType] = useState("");
  let pageSize = 5;
  let dataSize = 4;
  const [paginationGroup, setPaginationGroup] = useState([]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageSize === 0 && currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(currentPage - 1, currentPage + (pageSize - 1))
        );
      }
    } else {
      if (currentPage % pageSize === 0 && currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(currentPage - pageSize, currentPage)
        );
      }
    }
  }, [currentPage]);

  useEffect(() => {
    setnewRequestList(props.requestListData);
    setRequestTitleArray(
      new Array(Math.ceil(props.requestListData.length / dataSize))
        .fill()
        .map((_, i) => i + 1)
    );
    setPaginationGroup(
      new Array(Math.ceil(props.requestListData.length / dataSize))
        .fill()
        .map((_, i) => i + 1)
        .slice(0, pageSize)
    );
  }, [props]);

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * 4 - 4;
    const endIndex = startIndex + 4;
    return newRequestList.slice(startIndex, endIndex);
  };

  return (
    <>
      <div className="row d-block-force d-none-1200">
        <div className="col">
          {getPaginatedData().map((request, i) => (
            <div
              // href={`/high5Hire/requests/${request.jobId}`}
              className="card card-hoverable p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
            >
              {/* <div>
                      <div class="form-check-square p-absolute-top-left">
                          <input 
                            class="form-check-input" 
                            type="checkbox" 
                            checked={request.isSelect} 
                            onChange={(e) => props.handleChange(e, i)} 
                          />
                      </div>
                    </div> */}
              <div className="card-body d-flex">
                <a
                  // href={`/high5Hire/requests/${request.jobId}`}
                  onClick={() =>
                    window.open(`/high5Hire/requests/${request.jobId}`, "_self")
                  }
                >
                  <div>
                    <h6>
                      {request.isHotJob && (
                        <>
                          <span className="tag tag-coral mr-2 p-1">hot</span>
                        </>
                      )}
                      {request.jobTitle}
                    </h6>
                    <div className="d-flex align-items-center ml-1">
                      <span className="d-flex align-items-center me-3">
                        <i className="far fa-suitcase me-2 font-brandBlue"></i>
                        <small className="font-regular font-primary font-gray1">
                          {/* {request.jobType.charAt(0).toUpperCase() + request.jobType.slice(1) } */}
                          {request.jobType}
                        </small>
                      </span>
                      {request.location.city ? (
                        <span className="d-flex align-items-center me-3">
                          <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                          <span className="font-regular font-primary font-gray1">
                            {request.location.city}
                          </span>
                        </span>
                      ) : (
                        <span className="d-flex align-items-center me-3">
                          <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                          <span className="font-regular font-primary font-gray1">
                            Remote
                          </span>
                        </span>
                      )}
                      {moment(request.expiresOn).diff(new Date(), "days") <
                        0 && (
                        <span className="tag tag-red1">
                          <strong> Expired </strong>
                        </span>
                      )}
                      {moment(request.expiresOn).diff(new Date(), "days") ===
                        0 && (
                        <span className="tag tag-red1">
                          <strong> Expires Today </strong>
                        </span>
                      )}
                      {moment(request.expiresOn).diff(new Date(), "days") ===
                        1 && (
                        <span className="tag tag-red1">
                          <strong> 1 Day Left To Fill </strong>
                        </span>
                      )}
                      {moment(request.expiresOn).diff(new Date(), "days") > 1 &&
                        (moment(request.expiresOn).diff(new Date(), "days") <=
                        5 ? (
                          <span className="tag tag-red1">
                            <strong>
                              {" "}
                              {moment(request.expiresOn).diff(
                                new Date(),
                                "days"
                              )}{" "}
                              Days Left To Fill{" "}
                            </strong>
                          </span>
                        ) : (
                          <span className="font-regular">
                            <strong>
                              {" "}
                              {moment(request.expiresOn).diff(
                                new Date(),
                                "days"
                              )}{" "}
                              Days Left To Fill{" "}
                            </strong>
                          </span>
                        ))}
                    </div>
                  </div>
                </a>
              </div>
              <button
                type="text"
                onClick={() => props.openSubmitModal(request)}
                class="mr-4 btn btn-sm btn-secondary ml-auto h-50"
                disabled={
                  moment(request.expiresOn).diff(new Date(), "days") < 0
                }
              >
                Invite
              </button>
            </div>
          ))}
        </div>
        <div className="d-flex justify-content-end pt-4 px-1">
          <div className="d-flex">
            <div class="ml-auto mx-auto-370 d-flex align-items-center">
              <button
                onClick={() => {
                  setPageType("down");
                  setCurrentPage((page) => page - 1);
                }}
                type="button"
                disabled={currentPage === 1 ? true : false}
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-left"></i>
              </button>

              {paginationGroup.map((item) => (
                <button
                  type="button"
                  className={
                    currentPage === item
                      ? "btn btn-text px-2 bg-secondary text-white"
                      : "btn btn-text px-2"
                  }
                  onClick={changePage}
                >
                  {item}
                </button>
              ))}

              <button
                onClick={() => {
                  setPageType("up");
                  setCurrentPage((page) => page + 1);
                }}
                type="button"
                disabled={
                  currentPage === requestTitleArray.slice(-1)[0] ? true : false
                }
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubmitCandidateListView;
