import React, { useEffect, useState } from "react";
import Card from "./Card";
import Talents from "../../talent/talentcommunity/Index";
import Recruiters from "./Recruiters/Recruiters";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loader from "../../../Loader/Loader";
import { getDashboardCount } from "../../../../actions/dashboard/DashboardCount";
import { post } from "../../../../service/ApiService";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const MPMDashboard = () => {
  const dispatch = useDispatch();
  const cardStyle = {
    minWidth: "640px",
  };
  const [view, setView] = useState({
    talents: false,
    recruiters: true,
    clients: false,
    users: false,
  });

  const location = useLocation();
  const tab = location?.state?.tab;
  window.history.replaceState({}, document.title);
  useEffect(() => {
    if (tab === "users")
      setView({
        talents: false,
        recruiters: true,
        clients: false,
        users: false,
      });
  }, [tab]);

  const { loading, dashboardCount } = useSelector(
    (state) => ({
      loading: state.getDashboardCountReducer.loading,
      dashboardCount: state.getDashboardCountReducer.dashboardCount,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getDashboardCount());
  }, []);

  const getCount = (type) => {
    const count =
      dashboardCount && dashboardCount.filter((item) => item.Type == type)[0];
    return count?.TotalCount;
  };

  const getPercentage = (type) => {
    const count =
      dashboardCount && dashboardCount.filter((item) => item.Type == type)[0];
    if (count) {
      return Math.round(
        (count?.NumberOfUsersCreated / count?.TotalCount) * 100
      );
    } else {
      return 0;
    }
  };

  const getRecruiters = () => {};

  return (
    <div className="px-5">
      {loading && <Loader />}
      <div className="gap-3 overflow">
        <div className="d-flex gap-4 mb-2 mb-xl-0 py-4">
          <Card
            // cardStyle={cardStyle}
            view={view}
            setView={setView}
            elem={{
              heading: "Gig Recruiters",
              title: "Gig Recruiters",
              count: getCount("RecruiterCount") || 0,
              changes: `${getPercentage("RecruiterCount") || 0}% `,
              tooltip:
                "Total number of recruiters registered in High5Hire excluding recruiters who have been invited but have not responded or are pending approval.",
            }}
            isFrom="MPMDashboard"
          />{" "}
          <Card
            view={view}
            // cardStyle={cardStyle}
            setView={setView}
            elem={{
              heading: "Talents",
              title: "Talent",
              count: getCount("TalentCount") || 0,
              changes: `${getPercentage("TalentCount") || 0}% `,
              tooltip:
                "Total number of talents registered in High5Hire. This does not include the talents invited to jobs or those who have received invitations but not register on the platform.",
            }}
            isFrom="MPMDashboard"
          />
        </div>
      </div>{" "}
      <hr className="m-0 p-0" />
      <div>
        {view?.talents && <Talents />}
        {view?.recruiters && <Recruiters getRecruiters={getRecruiters} />}
      </div>
    </div>
  );
};

export default MPMDashboard;
