import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

const SkillMatrix = ({
  job,
  skillMatrix,
  setSkillMatrix,
  skillMatchDetails,
  setSkillMatchDetails,
  setSkillMatrixModal,
  component,
}) => {
  const [checkValid, setCheckValid] = useState(false);
  const [tempSkillMatrix, setTempSkillMatrix] = useState([]);

  useEffect(() => {
    let sm = [];

    component === "SubCommunity"
      ? skillMatchDetails?.unmatched?.forEach((sA) => {
          sA.forEach((s) => {
            sm.push({
              skill: s,
              competency: "Beginner",
              experience: "",
              custom: false,
            });
          });
        })
      : skillMatchDetails?.unmatched?.forEach((s) =>
          sm.push({
            skill: s,
            competency: "Beginner",
            experience: "",
            custom: false,
          })
        );

    setTempSkillMatrix(sm);
  }, [skillMatchDetails]);

  const handleChange = (e, index) => {
    if (e.target.value > 99) return toast.error("Invalid value");
    if (isNaN(e.target.value)) return;
    const updatedMatrix = [...tempSkillMatrix];
    updatedMatrix[index][e.target.name] = e.target.value;
    setTempSkillMatrix(updatedMatrix);
  };
  const removeSkiill = (index) => {
    const updatedMatrix = tempSkillMatrix.filter((x, i) => index !== i);
    setTempSkillMatrix(updatedMatrix);
  };
  const handleDone = () => {
    setCheckValid(true);
    let validationFail = !tempSkillMatrix.every((s) => s.experience !== "");
    if (validationFail) toast.error("Please enter experience.");
    else {
      if (skillMatrix.length === 0) {
        setSkillMatrix(tempSkillMatrix);
      } else {
        let newSkillMatrix = [...skillMatrix];
        let tskMap = {};
        tempSkillMatrix.forEach((item) => {
          tskMap[item.skill] = item.experience;
        });

        // Update skillMatrix based on tskMap
        newSkillMatrix.forEach((item) => {
          if (tskMap.hasOwnProperty(item.skill)) {
            item.experience = tskMap[item.skill];
          }
        });
        setSkillMatrix(newSkillMatrix);
      }
      if (component === "SubCommunity") {
        let smdetails = { ...skillMatchDetails };
        for (let i = 0; i < smdetails?.unmatched?.length; i++) {
          let subarray = smdetails?.unmatched[i];
          for (let j = 0; j < subarray?.length; j++) {
            let skillToMatch = subarray[j];

            // Check if the skill exists in tempSkillMatrix and has experience > 0
            let matchingSkill = tempSkillMatrix?.find(
              (skill) => skill?.skill === skillToMatch && skill?.experience > 0
            );

            if (matchingSkill) {
              // Move all skills from the subarray to the matched array
              smdetails.matched = smdetails?.matched?.concat(subarray);

              // Clear the subarray
              smdetails.unmatched[i] = [];
              break; // Exit the inner loop once a match is found
            }
          }
        }

        // Remove empty subarrays from the unmatched array
        smdetails.unmatched = smdetails?.unmatched?.filter(
          (subarray) => subarray.length > 0
        );

        setSkillMatchDetails(smdetails);
      } else {
        setSkillMatchDetails({
          matched: [
            ...skillMatchDetails?.matched,
            ...tempSkillMatrix
              .filter((s) => s.experience > 0)
              .map((s) => s.skill),
          ],
          unmatched: skillMatchDetails?.unmatched.filter((skill) => {
            const lowercaseSkill = skill.toLowerCase();
            return tempSkillMatrix.some(
              (s) =>
                s.skill.toLowerCase() === lowercaseSkill &&
                s.experience * 1 === 0
            );
          }),
        });
      }
      setSkillMatrixModal(false);
    }
  };
  return (
    <div className="px-4">
      <hr />

      <div class="pt-0 mb-3 ">
        <table class="table">
          <thead>
            <tr>
              <th className=" " scope="col">
                <span>Skill</span>
              </th>

              <th className=" text-center" scope="col">
                <span> Years of experience*</span>
              </th>
              <th className=" text-center" scope="col">
                <span> </span>
              </th>
            </tr>
          </thead>

          <tbody>
            {tempSkillMatrix.map((item, index) => (
              <tr key={index}>
                <td className="col-6 ">
                  {item.custom ? (
                    <input
                      type="text"
                      name="skill"
                      value={item.skill}
                      placeholder="Add a skill"
                      onChange={(e) => handleChange(e, index)}
                    ></input>
                  ) : (
                    <span>{item.skill}</span>
                  )}
                </td>

                <td className="col-5 pl-4 text-center">
                  <input
                    style={{
                      width: "130px",
                      margin: "auto",
                      border:
                        checkValid &&
                        tempSkillMatrix[index].experience === "" &&
                        "2px solid #ff0000",
                    }}
                    value={tempSkillMatrix[index].experience}
                    type="text"
                    onChange={(e) => handleChange(e, index)}
                    name="experience"
                    placeholder="Enter a value"
                    className="form-control"
                  ></input>
                </td>
                <td className="col-1 pl-4 text-center">
                  {item.custom && (
                    <button
                      type="button"
                      class="btn btn-sm btn-text ml-0 font-22 font-black text-black"
                      onClick={(e) => removeSkiill(index)}
                    >
                      <i class="fal fa-fw fa-trash-alt"></i>
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <hr />
      <div className="d-flex justify-content-end  gap-2 align-items-center p-2 mb-2">
        <button
          className="btn btn-md btn-secondary"
          onClick={() => {
            setSkillMatrixModal(false);
          }}
        >
          Cancel
        </button>
        <button className="btn btn-md btn-primary" onClick={handleDone}>
          Done
        </button>
      </div>
    </div>
  );
};

export default SkillMatrix;
