import {
    GET_RECRUITER_LIST_BEGIN,
    GET_RECRUITER_LIST_SUCCESS,
    GET_RECRUITER_LIST_FAILURE
  } from "../../actions/executiveadmin/GetRecruiterList";
  
  const initialState = {
    recruiterListLoading: true,
    recruiterList: [],
  };
  
  const recruiterListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_RECRUITER_LIST_BEGIN:
        return {
          ...state,
          recruiterListLoading: true,
          recruiterList: [],
        };
      case GET_RECRUITER_LIST_SUCCESS:
        return {
          ...state,
          recruiterList: payload,
          recruiterListLoading: false,
        };
      case GET_RECRUITER_LIST_FAILURE:
        return {
          ...state,
          recruiterList: [],
          recruiterListLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default recruiterListReducer;
  