import React, { useState } from "react";

const SearchJob = ({ value, setSearchValue }) => {
  const handleSearch = (e) => {
    let searchValue = e.target.value.toLowerCase();
    setSearchValue(searchValue);
  };
  const handleClearSearch = () => {
    setSearchValue("");
  };
  return (
    <>
      <div
        id="SiteSearchField"
        className="search-field"
        style={{ width: "16%", minWidth: "150px" }}
      >
        <div className="field-wrap">
          <div className="search-input-container d-flex flex-inline">
            <input
              type="text"
              placeholder="Search Candidates"
              className="form-control small font-14"
              onChange={(e) => handleSearch(e)}
              value={value}
              style={{
                borderRadius: "4px",
              }}
            />
            {value && ( // Render the clear button only when there's a search value
              <button
                className="clear-search"
                onClick={handleClearSearch}
                aria-label="Clear search"
                style={{
                  backgroundColor: "#F1F1F1",
                  border: "1px solid #F1F1F1",
                  color: "grey",
                  boxShadow: "none",
                  borderRadius: "0px 4px 4px 0px",
                  cursor: "pointer",
                  marginLeft: "-3px",
                }}
              >
                <i className="fas fa-times"></i>
              </button>
            )}
          </div>
          <span className="icon">
            <i aria-hidden="true" className="fas fa-search"></i>
          </span>
        </div>
      </div>
    </>
  );
};

export default SearchJob;
