import {
  GET_JOB_LIST_BEGIN,
  GET_JOB_LIST_SUCCESS,
  GET_JOB_LIST_FAILURE,
  GET_JOB_LIST_HM_BEGIN,
  GET_JOB_LIST_HM_SUCCESS,
  GET_JOB_LIST_HM_FAILURE,
  GET_COPY_CLONE_JOB_LIST_BEGIN,
  GET_COPY_CLONE_JOB_LIST_SUCCESS,
  GET_COPY_CLONE_JOB_LIST_FAILURE,
} from "../../actions/job/JobList";

const initialState = {
  jobListLoading: false,
  jobList: [],
  jobListHmDashBoardLoading: false,
  jobListHmDashboard: [],
  copyCloneJobList: [],
  copyCloneJobListLoading: false,
};

const jobListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_JOB_LIST_BEGIN:
      return {
        ...state,
        jobListLoading: true,
        jobList: [],
      };
    case GET_JOB_LIST_SUCCESS:
      return {
        ...state,
        jobList: payload,
        jobListLoading: false,
      };
    case GET_JOB_LIST_FAILURE:
      return {
        ...state,
        jobList: [],
        jobListLoading: false,
      };
    case GET_JOB_LIST_HM_BEGIN:
      return {
        ...state,
        jobListHmDashBoardLoading: true,
        jobListHmDashboard: [],
      };
    case GET_JOB_LIST_HM_SUCCESS:
      return {
        ...state,
        jobListHmDashboard: payload,
        jobListHmDashBoardLoading: false,
      };
    case GET_JOB_LIST_HM_FAILURE:
      return {
        ...state,
        jobListHmDashboard: [],
        jobListHmDashBoardLoading: false,
      };
    case GET_COPY_CLONE_JOB_LIST_BEGIN:
      return {
        ...state,
        copyCloneJobListLoading: true,
        copyCloneJobList: [],
      };
    case GET_COPY_CLONE_JOB_LIST_SUCCESS:
      return {
        ...state,
        copyCloneJobList: payload,
        copyCloneJobListLoading: false,
      };
    case GET_COPY_CLONE_JOB_LIST_FAILURE:
      return {
        ...state,
        copyCloneJobList: [],
        copyCloneJobListLoading: false,
      };
    default:
      return state;
  }
};

export default jobListReducer;
