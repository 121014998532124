import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'

function ScreeningAddInfo({ component, primarySkills, questions, addInfo, screeningQuestions, setScreeningQuestions, additionalInfoFinal, 
    setAdditionalInfoFinal, skillSetOption, QualificationType, selectedLanguages, setSelectedLanguages } ) {

    const screeningArray = [
        { name: 'Background Check', question: 'Are you willing to undergo a background check, in accordance with local law/regulations?', isMandatory: false },
        { name: 'Drug Check', question: 'Are you willing to take a drug test, in accordance with local law/regulations?', isMandatory: false },
        { name: 'Driving Licence', question: "Do you have a valid driver's license?", isMandatory: false },
        { name: 'Education', question: 'Have you completed the following level of education?', input: `Bachelor's Degree`, isMandatory: false },
        { name: 'Industry Experience', question: 'How many years of following Industry experience do you currently have?', input: 'Information Technology', isMandatory: false },
        { name: 'Language', question: 'What is your level of proficiency in following Languages?', input: '[English]', isMandatory: false },
        { name: 'Work Experience', question: 'How many years of experience do you currently have?', input: 'Numeric', isMandatory: false },
        { name: 'Location', question: "Are you comfortable commuting to job's location?", isMandatory: false },
        { name: 'Remote Work', question: 'Are you comfortable working in a remote setting?', isMandatory: false },
        { name: 'Urgent Hiring Need', question: 'We must fill this position urgently. Can you start immediately?', isMandatory: false },
        { name: 'Custom Question', index: 1, question: '', input: 'Yes/No', isMandatory: false }
    ]

    const additionalInfoArray = [
        { name: 'Interview Process Steps', question: 'Mention the steps involved in interview process', answer: '' },
        { name: '30-60-90 Day Expectations', question: 'In how many days, candidate is expected to join?', answer: '30 Days' },
        { name: 'Day to Day Responsibilities', question: 'What are the Responsibilities for this role?', answer: '' },
        { name: 'Current Team Structure', question: 'How many members are there in your current team?', answer: '' },
        { name: 'Custom Info', question: '', answer: '' }
    ]

    const languages = [
        "Abkhaz",
        "Afar",
        "Afrikaans",
        "Akan",
        "Albanian",
        "Amharic",
        "Arabic",
        "Aragonese",
        "Armenian",
        "Assamese",
        "Avaric",
        "Avestan",
        "Aymara",
        "Azerbaijani",
        "Bambara",
        "Bashkir",
        "Basque",
        "Belarusian",
        "Bengali",
        "Bihari",
        "Bislama",
        "Bosnian",
        "Breton",
        "Bulgarian",
        "Burmese",        
        "Chamorro",
        "Chechen",       
        "Chinese",
        "Chuvash",
        "Cornish",
        "Corsican",
        "Cree",
        "Croatian",
        "Czech",
        "Danish",       
        "Dutch",
        "English",
        "Esperanto",
        "Estonian",
        "Ewe",
        "Faroese",
        "Fijian",
        "Finnish",
        "French",       
        "Galician",
        "Georgian",
        "German",      
        "Guaraní",
        "Gujarati",       
        "Hausa",
        "Hebrew",
        "Hebrew",
        "Herero",
        "Hindi",
        "Hiri Motu",
        "Hungarian",
        "Interlingua",
        "Indonesian",
        "Interlingue",
        "Irish",
        "Igbo",
        "Inupiaq",
        "Ido",
        "Icelandic",
        "Italian",
        "Inuktitut",
        "Japanese",
        "Javanese",     
        "Kannada",
        "Kanuri",
        "Kashmiri",
        "Kazakh",
        "Khmer",       
        "Kinyarwanda",      
        "Komi",
        "Kongo",
        "Korean",
        "Kurdish",        
        "Latin",       
        "Luganda",       
        "Lingala",
        "Lao",
        "Lithuanian",
        "Luba-Katanga",
        "Latvian",
        "Manx",
        "Macedonian",
        "Malagasy",
        "Malay",
        "Malayalam",
        "Maltese",
        "Māori",
        "Marathi",
        "Marshallese",
        "Mongolian",
        "Nauru",
        "Navajo",
        "Norwegian Bokmål",
        "North Ndebele",
        "Nepali",
        "Ndonga",
        "Norwegian Nynorsk",
        "Norwegian",
        "Nuosu",
        "South Ndebele",
        "Occitan",
        "Ojibwe",
        "Oromo",
        "Oriya",
        "Ossetian",
        "Punjabi",
        "Pāli",
        "Persian",
        "Polish",
        "Pashto, Pushto",
        "Portuguese",
        "Quechua",
        "Romansh",
        "Kirundi",
        "Russian",
        "Sanskrit",
        "Sardinian",
        "Sindhi",
        "Northern Sami",
        "Samoan",
        "Sango",
        "Serbian",
        "Scottish Gaelic; Gaelic",
        "Shona",
        "Sinhala, Sinhalese",
        "Slovak",
        "Slovene",
        "Somali",
        "Southern Sotho",
        "Spanish",
        "Sundanese",
        "Swahili",
        "Swati",
        "Swedish",
        "Tamil",
        "Telugu",
        "Tajik",
        "Thai",
        "Tigrinya",
        "Tibetan Standard, Tibetan, Central",
        "Turkmen",
        "Tagalog",
        "Tswana",
        "Tonga (Tonga Islands)",
        "Turkish",
        "Tsonga",
        "Tatar",
        "Twi",
        "Tahitian",
        "Uighur, Uyghur",
        "Ukrainian",
        "Urdu",
        "Uzbek",
        "Venda",
        "Vietnamese",
        "Volapük",
        "Walloon",
        "Welsh",
        "Wolof",
        "Western Frisian",
        "Xhosa",
        "Yiddish",
        "Yoruba",
        "Zhuang, Chuang"
    ]

    const [ additionalInfoOpen, setAdditionalInfoOpen ] = useState(true)
    const [ qAndAOpen, setQandAOpen ] = useState(true)
    const [ vettingRequirement, setVettingRequirement ] = useState(false)
    const [ scoringFormula, setScoringFormula ] = useState(false)
    const [ customQuestion, setCustomQuestion ] = useState('')
    const [ customQuestionValidate, setCustomQuestionValidate ] = useState('')
    const [ customInfo, setCustomInfo ] = useState({ question: '', answer: '' })
    const [ customInfoClicked, setCustomInfoClicked ] = useState(false)
    const [ customInfoValidate, setCustomInfoValidate ] = useState({ question: '', answer: '' })
    const [ weightage, setWeightage ] = useState([
        { name: 'Primary Skills', value: '50'}, 
        { name: 'Secondary Skills', value: '10'}, 
        { name: 'Indusry', value: '10'}, 
        { name: 'Location', value: '20' }, 
        { name: 'Job Title', value: '10'}
    ])
    
    const handleChange = (value, name, indexValue) => {
        let temp = [...screeningQuestions]
        let index
        if(name === 'Custom Question' && indexValue) {
            index = temp.findIndex(i => i.index === indexValue)
        }
        else {
            index = temp.findIndex(i => i.name === name)
        }
        temp[index] = { ...temp[index], input: value }
        setScreeningQuestions(temp)
    }

    const handleChangeMandatory = (checked, name, indexValue) => {
        let temp = [...screeningQuestions]
        let index
        if(name === 'Custom Question' && indexValue) {
            index = temp.findIndex(i => i.index === indexValue)
        }
        else {
            index = temp.findIndex(i => i.name === name)
        }
        temp[index] = { ...temp[index], isMandatory: checked }
        setScreeningQuestions(temp)
    }

    useEffect(() => {
        console.log(addInfo)
    }, [addInfo])

    return (
        <div className="m-3"> 
            { 
                questions &&              
                <div className={ qAndAOpen ? "ml-2 ml-0-600 mt-2 mb-4" : 'd-none'} >
                    <div class="d-flex mb-2 pl-1 ml-4">
                        <div className='row mt-3'>
                            <div className='col-lg-12'>
                                <div className='car'>
                                    <div className='card-bod'>
                                        <div className='px-3'>
                                            <div className='d-flex align-items-center justify-content-between'>
                                                <div className='mb-1'>
                                                    <h6>Add Screening Questions</h6>
                                                    <p>
                                                        Note: Maximum 5 Custom Questions can be added
                                                    </p>                      
                                                </div>
                                                <hr />
                                            </div>
                                            {
                                                screeningQuestions.length > 0 &&
                                                screeningQuestions.filter(i => i.name !== 'Custom Question').map(item=> 
                                                <div className='card m-2'>
                                                    <div className='card-body'>                        
                                                        <div className='d-flex p-3 mb-2' style={{ backgroundColor: "#dcdcdc" }}>
                                                            <div className='flex-item'>
                                                                <medium>{item.question}</medium>
                                                            </div>
                                                            <button className='ml-auto btn btn-icon'>
                                                                <i className='fa fa-close' onClick={() => {
                                                                    setScreeningQuestions([...screeningQuestions.filter(i => i.name !== item.name)])
                                                                }}>

                                                                </i>
                                                            </button>
                                                        </div> 
                                                        <div className='d-flex align-items-center justify-content-center'>                                                                           
                                                            { item.name === 'Education' &&
                                                                <div className="form-floating w-50 ml-1">
                                                                    <select
                                                                        className="form-select font-14"                                                                   
                                                                        onChange={(e) => handleChange(e.target.value, item.name)}
                                                                    >
                                                                        {
                                                                            QualificationType.map(i => i.value).map(item2 => <option selected={item2 === item.input} value={item2}>{item2}</option>)
                                                                        } 
                                                                    </select>
                                                                    <label>Education</label>
                                                                </div> 
                                                            }  
                                                            { item.name === 'Industry Experience' &&
                                                                <div className="form-floating w-50 ml-1">
                                                                    <select
                                                                        className="form-select font-14"
                                                                        name="referralCurrency"
                                                                        onChange={(e) => handleChange(e.target.value, item.name)}
                                                                    >
                                                                        {
                                                                            skillSetOption.map(i => i.value).map(item2 => <option selected={item2 === item.input} value={item2}>{item2}</option>)
                                                                        } 
                                                                    </select>
                                                                    <label>Industry</label>
                                                                </div> 
                                                            }  
                                                            { item.name === 'Language' &&
                                                                <div className="form-group w-50 text-left">
                                                                    <label className="mb-2">Language</label>
                                                                    <div className="form-floating font-14 Hover-Dropdown" >
                                                                        <Select
                                                                            isMulti
                                                                            options={languages.map(lang => ({ value: lang, label: lang }))}
                                                                            value={selectedLanguages.map(i => ({ value: i, label: i }))}
                                                                            name="Qualification"
                                                                            onChange={(e) => {
                                                                                setSelectedLanguages(Array.isArray(e) ? e.map(x => x.value) : [])
                                                                            }}
                                                                            isSearchable={true}
                                                                            defaultValue={{ value: 'English' }}
                                                                        >
                                                                        </Select>
                                                                    </div>                              
                                                                </div> 
                                                            }   
                                                            { item.name === 'Work Experience' &&
                                                                <div className="form-floating w-50 ml-1">
                                                                    <select
                                                                        className="form-select font-14"
                                                                        name="referralCurrency"
                                                                        onChange={(e) => handleChange(e.target.value, item.name)}
                                                                    >
                                                                        {
                                                                            ['Numeric'].map(item2 => <option selected={item2 === item.input} value={item2}>{item2}</option>)
                                                                        } 
                                                                    </select>
                                                                    <label>Response Type</label>
                                                                </div> 
                                                            }   
                                                            { 
                                                                !['Education', 'Industry Experience', 'Language', 'Work Experience'].includes(item.name) &&
                                                                <div className="form-floating w-50 ml-1">
                                                                    <select
                                                                        className="form-select font-14"                                                                                                                                    
                                                                    >
                                                                        {
                                                                            ['Yes/No'].map(item2 => <option selected={item2 === item.input} value={item2}>{item2}</option>)
                                                                        } 
                                                                    </select>
                                                                    <label>Response Type</label>
                                                                </div>
                                                            }
                                                            <div className='ml-auto'>
                                                                <div className="form-check form-switch d-flex align-items-center">
                                                                    <label
                                                                        className="form-check-label pt-0"
                                                                    >
                                                                        Must answer this question
                                                                    </label>
                                                                    <input                                                                                   
                                                                        className="form-check-input ml-2"
                                                                        type="checkbox"
                                                                        checked={item.isMandatory}
                                                                        onChange={(e) => handleChangeMandatory(e.target.checked, item.name)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>)
                                            }   
                                            {
                                                screeningQuestions.map(i => i.name).includes('Custom Question') &&
                                                <div className='mt-3'>
                                                    <div className='m-1'>Custom Questions: </div>
                                                        {screeningQuestions.filter(i => i.name === 'Custom Question').map(item => 
                                                        <div className='card m-2'>
                                                            <div className='card-body'> 
                                                                {item.question ?
                                                                    <div className='d-flex p-3 mb-2' style={{ backgroundColor: "#dcdcdc" }}>
                                                                        <div className='flex-item'>
                                                                            <medium>{item.question}</medium>
                                                                        </div>
                                                                        <button className='ml-auto btn btn-icon'>
                                                                            <i 
                                                                                className='fa fa-close' 
                                                                                onClick={() => {
                                                                                    setScreeningQuestions([...screeningQuestions.filter(i => i.index !== item.index)])
                                                                                }}
                                                                            >

                                                                            </i>
                                                                        </button>
                                                                    </div> :                                                            
                                                                    <div className='row'>
                                                                        <div className='col-lg-9'>
                                                                            <div className='form-floating mb-2'>
                                                                                <input 
                                                                                    type='text' 
                                                                                    className={ customQuestionValidate ? 'form-control border border-danger' : 'form-control'}
                                                                                    placeholder='Type Question'
                                                                                    onChange={(e) => { 
                                                                                        if(customQuestionValidate) {
                                                                                            setCustomQuestionValidate('')
                                                                                        }                                                                                              
                                                                                        setCustomQuestion(e.target.value)
                                                                                    }}
                                                                                    value={customQuestion}
                                                                                />
                                                                                <label>Question</label>
                                                                                {
                                                                                    customQuestionValidate &&
                                                                                    <small className='validation ml-2'>{customQuestionValidate}</small>
                                                                                }
                                                                            </div>
                                                                        </div>                      
                                                                        <div className='col-lg-3'>
                                                                            <div className='d-flex mt-2'>
                                                                                <button 
                                                                                    className='btn btn-sm' 
                                                                                    onClick={() => {
                                                                                        if(customQuestion) {
                                                                                            let temp = screeningQuestions.filter(i => (i.name !== 'Custom Question') || (i.name === 'Custom Question' && i.question !== ''))
                                                                                            temp.push({...screeningQuestions.filter(i => i.name === 'Custom Question' && i.question === '')[0], question: customQuestion })                                                                                                  
                                                                                            setScreeningQuestions(temp)
                                                                                            setCustomQuestion('')
                                                                                        }
                                                                                        else {
                                                                                            setCustomQuestionValidate('*Required Field')
                                                                                        }
                                                                                    }}
                                                                                >
                                                                                    Add
                                                                                </button>
                                                                                <button 
                                                                                    className='btn btn-sm btn-secondary ml-1'
                                                                                    onClick={() => { 
                                                                                        setCustomQuestion('')
                                                                                        setCustomQuestionValidate('')
                                                                                        setScreeningQuestions(screeningQuestions.filter(i => (i.name !== 'Custom Question') || (i.name === 'Custom Question' && i.question !== '')))
                                                                                    }}
                                                                                >
                                                                                    Cancel
                                                                                </button>
                                                                            </div>
                                                                            
                                                                        </div>                                                               
                                                                    </div>    
                                                                }                                                                                                                              
                                                                <div className='d-flex align-items-center justify-content-center'>                                                                                                                                                                                                         
                                                                    <div className="form-floating w-50 ml-1">
                                                                        <select
                                                                            className="form-select font-14"                                                                  
                                                                            onChange={(e) => handleChange(e.target.value, item.name, item.index)}
                                                                        >
                                                                            {
                                                                                ['Yes/No', 'Text', 'Numeric', 'Long Text'].map(item2 => <option selected={item2 === item.input} value={item2}>{item2}</option>)
                                                                            } 
                                                                        </select>
                                                                        <label>Response Type</label>
                                                                    </div>                                                                                                                                      
                                                                    <div className='ml-auto'>
                                                                        <div className="form-check form-switch d-flex align-items-center">
                                                                            <label
                                                                                className="form-check-label pt-0"
                                                                            >
                                                                                Must answer this question
                                                                            </label>
                                                                            <input                                                                                   
                                                                                className="form-check-input ml-2"
                                                                                type="checkbox"
                                                                                checked={item.isMandatory}
                                                                                onChange={(e) => handleChangeMandatory(e.target.checked, item.name, item.index)}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    <div className='d-flex'>
                                                        <label className='ml-auto'>Remaining Custom Questions: <strong>{ 5 - screeningQuestions.filter(i => i.name === 'Custom Question').length }</strong></label>
                                                    </div>
                                                </div>
                                            }                            
                                            { screeningQuestions.length > 0 && <hr className='m-2'></hr>}
                                            <div>
                                                {screeningArray.map(item =>                                                               
                                                    <button 
                                                        className='btn btn-sm m-1' 
                                                        onClick={() => { 
                                                            if(item.name === 'Custom Question' && screeningQuestions.filter(i => i.name === 'Custom Question').length >= 5) {
                                                                toast.error('Maximum 5 Custom Questions are allowed')
                                                            }
                                                            else if(item.name === 'Custom Question' && screeningQuestions.filter(i => i.name === 'Custom Question' && i.question === '').length > 0) {
                                                                toast.error('Please complete the Custom Question Info')
                                                            }
                                                            else {
                                                                let temp = [...screeningQuestions]
                                                                let item2 = {}
                                                                if(item.name === 'Custom Question') {
                                                                    let maxIndex = screeningQuestions.filter(i => i.name === 'Custom Question').length + 1
                                                                    item2 = { ...item, index: maxIndex }
                                                                    temp.push(item2)
                                                                }
                                                                else {
                                                                    item2 = {...item}
                                                                    temp.push(item2)
                                                                }
                                                                setScreeningQuestions(temp)
                                                            }                                                       
                                                        }} 
                                                        style={{ background: 'none', color: '#333' }}
                                                        disabled={item.name !== 'Custom Question' && screeningQuestions.map(i => i.name).includes(item.name)}
                                                    >
                                                        <i className='fas fa-fw mr-2 fa-plus' aria-hidden='true'></i>
                                                        {item.name}
                                                    </button>                                                               
                                                )}
                                            </div>                                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            } 
            {   
                addInfo &&
                <>
                    { false && component !== 'Template' &&
                        <button
                            onClick={() => setAdditionalInfoOpen(!additionalInfoOpen)}
                            type="button" className="btn btn-collapsible mb-2"
                        >                           
                            <i className={additionalInfoOpen ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"} />                            
                            <span>
                                <small>Additional Info</small>
                            </span>
                        </button>
                    }
                    <div className={ additionalInfoOpen ? "ml-2 ml-0-600 mt-2 mb-4" : 'd-none'} >
                        <div class="d-flex mb-2 pl-1 ml-4">
                            <div className='row mt-3'>
                                <div className='col-lg-12'>
                                    <div className='car'>
                                        <div className='card-bod'>
                                            <div className='px-3'>
                                                <div className='d-flex align-items-center justify-content-between'>
                                                    <div className='mb-1'>
                                                        <h6>Add Additonal Info</h6>
                                                        <p>
                                                            Note: Maximum 5 Custom Info can be added
                                                        </p>
                                                    </div>                                                           
                                                </div>                                                           
                                                {
                                                    additionalInfoFinal.map((item, index) => 
                                                        <div className="card mb-2">
                                                            <div className="card-body">                                                       
                                                                <div className='d-flex p-3' style={{ backgroundColor: "#dcdcdc" }}>
                                                                    <div className='flex-item'>
                                                                        <medium>{item.question}</medium>
                                                                    </div>
                                                                    <button className='btn btn-icon ml-auto' onClick={() => setAdditionalInfoFinal(additionalInfoFinal.filter((_, i) => i !== index ))}>
                                                                        <i className='fa fa-close'></i>
                                                                    </button>
                                                                </div>                                                                              
                                                                <div>
                                                                    {
                                                                        item.name === 'Current Team Structure' &&                                                                               
                                                                        <div className='form-floating mt-2 w-25'>
                                                                            <input 
                                                                                className='form-control'
                                                                                type='number'
                                                                                value={item.answer}
                                                                                min='1'
                                                                                onChange={(e) => {
                                                                                    let add = [...additionalInfoFinal]
                                                                                    add[index] = { ...add[index], answer: e.target.value }
                                                                                    setAdditionalInfoFinal(add)
                                                                                }}
                                                                            />
                                                                            <label>Members</label>
                                                                        </div>                                                                                                                          
                                                                    }
                                                                    {
                                                                        (item.name === 'Employer Value Proposition' || item.name === 'Day to Day Responsibilities' || item.name === 'Interview Process Steps' || item.name === 'Custom Info') &&                                                                               
                                                                        <div className='form-floating mt-2'>
                                                                            <textarea 
                                                                                className='form-control'                                                                                       
                                                                                value={item.answer}                                                                                       
                                                                                onChange={(e) => {
                                                                                    let add = [...additionalInfoFinal]
                                                                                    add[index] = { ...add[index], answer: e.target.value }
                                                                                    setAdditionalInfoFinal(add)
                                                                                }}
                                                                            />                                                                                   
                                                                        </div>                                                                                                                          
                                                                    }
                                                                    {
                                                                        item.name === '30-60-90 Day Expectations' &&                                                                               
                                                                        <div className='form-floating mt-2 w-50'>
                                                                            <select className="form-select font-14" onChange={(e) => {
                                                                                let add = [...additionalInfoFinal]
                                                                                add[index] = { ...add[index], answer: e.target.value }
                                                                                setAdditionalInfoFinal(add)
                                                                            }}>
                                                                                {['30 Days', '60 Days', '90 Days'].map(item2 => {
                                                                                    return <option selected={item2 === item.answer} value={item2}>{item2}</option>
                                                                                })}
                                                                            </select>
                                                                            <label>Members</label>
                                                                        </div>                                                                                                                          
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    customInfoClicked &&
                                                    <div className='mt-2'>
                                                        <div className='row'>
                                                            <div className='col-lg-9'>
                                                                <div className='form-floating'>
                                                                    <input 
                                                                        type='text'
                                                                        className={ customInfoValidate.question ? 'form-control border border-danger' : 'form-control'}
                                                                        placeholder="Title"
                                                                        onChange={(e) => {
                                                                            setCustomInfo({ ...customInfo, question: e.target.value })
                                                                        }}
                                                                    />
                                                                    <label>Title</label>
                                                                    {
                                                                        customInfoValidate.question &&
                                                                        <small className='validation ml-2'>{customInfoValidate.question}</small>
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-3'>
                                                                <div className='d-flex mt-2'>
                                                                    <button 
                                                                        className='btn btn-sm' 
                                                                        onClick={() => {
                                                                            if(customInfo.question === '' || customInfo.answer === '') {
                                                                                toast.error('Please Complete the Custom Info')
                                                                                setCustomInfoValidate({ question: customInfo.question ? '' : '*Required Field', answer: customInfo.answer ? '' : '*Required Field'})
                                                                            }
                                                                            else {
                                                                                let temp = [...additionalInfoFinal]
                                                                                temp.push({ name: 'Custom Info', question: customInfo.question, answer: customInfo.answer })
                                                                                setAdditionalInfoFinal(temp)
                                                                                setCustomInfo({ question: '', answer: '' })
                                                                                setCustomInfoClicked(false)
                                                                                setCustomInfoValidate({ question: '', answer: '' })
                                                                            }
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </button>
                                                                    <button 
                                                                        className='btn btn-sm btn-secondary ml-1'
                                                                        onClick={() => { 
                                                                            setCustomInfo({ question: '', answer: '' })
                                                                            setCustomInfoClicked(false)
                                                                            setCustomInfoValidate({ question: '', answer: '' })
                                                                        }}
                                                                    >
                                                                        Cancel
                                                                    </button>
                                                                </div>                                                      
                                                            </div>
                                                        </div>
                                                        <div className='form-floating mt-2'>
                                                            <textarea 
                                                                className={ customInfoValidate.answer ? 'form-control border border-danger' : 'form-control'}                                                                                       
                                                                value={customInfo.answer}                                                                                       
                                                                onChange={(e) => {                                                          
                                                                    setCustomInfo({ ...customInfo, answer: e.target.value })
                                                                }}
                                                            /> 
                                                            {
                                                                customInfoValidate.answer &&
                                                                <small className='validation ml-2'>{customInfoValidate.answer}</small>
                                                            }                                                                                  
                                                        </div> 
                                                        <div className='d-flex'>
                                                            <label className='ml-auto'>Remaining Custom Info: <strong>{ (5 - additionalInfoFinal.filter(i => i.name === 'Custom Info').length) + (customInfoClicked ? -1 : 0) }</strong></label>
                                                        </div>
                                                    </div>
                                                }
                                                { additionalInfoFinal.length > 0 && <hr className='m-2'></hr>}
                                                {
                                                    additionalInfoArray.map(item => 
                                                        <button 
                                                            className='btn btn-sm mb-1 ml-3' 
                                                            onClick={() => { 
                                                                if(item.name === 'Custom Info' && additionalInfoFinal.filter(i => i.name === 'Custom Info').length >= 5) {
                                                                    toast.error('Maximum 5 Custom Info are allowed')
                                                                }
                                                                else if(customInfoClicked && item.name === 'Custom Info' && (customInfo.question === '' || customInfo.answer === '')) {
                                                                    toast.error('Please Complete the Custom Info')
                                                                }
                                                                else if(item.name === 'Custom Info') {
                                                                    setCustomInfoClicked(true)
                                                                }
                                                                else {
                                                                    setAdditionalInfoFinal([...additionalInfoFinal, item])
                                                                }                                                       
                                                            }}
                                                            style={{ background: 'none', color: '#333' }}
                                                            disabled={item.name !== 'Custom Info' && additionalInfoFinal.map(i => i.name).includes(item.name)}
                                                        >
                                                            <i className='fas fa-fw mr-2 fa-plus ' aria-hidden='true'></i>
                                                            {item.name}
                                                        </button>
                                                    )
                                                }                                                           
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                    {  false && component !== 'Template' &&
                        <button
                            onClick={() => setVettingRequirement(!vettingRequirement)}
                            type="button" className="btn btn-collapsible mb-2"
                        >                           
                            <i className={vettingRequirement ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"} />                            
                            <span>
                                <small>Vetting Requirement</small>
                            </span>
                        </button>
                    }
                    <div className={ (false && vettingRequirement && component !== 'Template') ? "ml-2 ml-0-600 mt-2 mb-4" : 'd-none'} >
                        <p>Suggested Vetting</p>
                    </div>
                    {  false && component !== 'Template' &&
                        <button
                            onClick={() => setScoringFormula(!scoringFormula)}
                            type="button" className="btn btn-collapsible mb-2"
                        >                           
                            <i className={scoringFormula ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"} />                            
                            <span>
                                <small>Scoring Formula</small>
                            </span>
                        </button>
                    }
                    <div className={ (false && scoringFormula && component !== 'Template') ? "ml-2 ml-0-600 mt-2 mb-4" : 'd-none'} >
                        <div className='m-3'>
                            <div className='mt-2'>
                                {
                                    weightage.map((item, index) => 
                                        <div className='row d-flex'>
                                            <div className='col-lg-5'>
                                                <label>{item.name}</label>
                                            </div>
                                            <div className='col-lg-3'>
                                                <div className='form-floating'>
                                                    <input
                                                        type='number'
                                                        value={item.value}
                                                        min='10'
                                                        max='100'
                                                        placeholder='weightage'
                                                        className='form-control mb-2'
                                                        onChange={(e) => {
                                                            let final = [...weightage]
                                                            final[index] = { ...final[index], value: e.target.value }
                                                            setWeightage(final)
                                                        }}
                                                    />
                                                    <label>Select</label>
                                                </div>
                                            </div>
                                            <div className='col-lg-3'>

                                            </div>
                                        </div>
                                    )                    
                                }
                            </div>
                        </div>
                    </div>
                </>
            }                                                       
        </div>
    )
}

export default ScreeningAddInfo