import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import LeftSideMenu from "./LeftSideMenu";
import ModalView from "../commoncomponents/modal/Modal";
// import SearchableEmails from "./SearchableEmails";
import { getCandidateId, getUserRole } from "../../../service/AuthService";
import ShortUniqueId from "short-unique-id";
import { post } from "../../../service/ApiService";
import SentEmailsBox from "./SendEmail";
import Loader from "../../Loader/Loader";
import { useLocation } from "react-router-dom";
import { fetchAllJobs, updateJob } from "../../../actions/jobList/jobList";
import { fetchAllBroadcastTemplates } from "../../../actions/broadcast/getBroadCastTemplates";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Validator from "email-validator";
import Recipients from "./Recipients";
// import BroadcastFilter from "./broadcastFilters/BroadcastFilter";
// import OutsideClickHandler from "react-outside-click-handler";
import EmailTemplates from "./EmailTemplates";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Col, Row, Container } from "react-bootstrap";

const uid = new ShortUniqueId({ length: 10 });

const Email = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    jobListLoading,
    jobList,
    templatesData,
    templatesDataLoading,
    selectedJob,
  } = useSelector(
    (state) => ({
      //jobListLoading: state.getJobListReducer.loading,
      //jobList: state.getJobListReducer.jobList,
      selectedJob: state.getJobListReducer.selectedJob,
      templatesData: state.broadCastTemplatesReducer.templatesData,
      templatesDataLoading:
        state.broadCastTemplatesReducer.templatesDataLoading,
    }),
    shallowEqual
  );
  const location = useLocation();

  const [data, setData] = useState({ talentData: [] });
  const [selectedOption, setSelectedOption] = useState("compose");
  const [activeTab, setActiveTab] = useState("compose");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [footer, setFooter] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [sendConfirmation, setSendConfirmation] = useState(false);
  const [previewEmail, setPreviewEmail] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [loading, setLoading] = useState(false);
  const [broadCastData, setBroadCastData] = useState([]);
  const [selectedRecruiter, setSelectedRecruiter] = useState([]);
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [broadCastTemplates, setBroadCastTemplates] = useState([]);
  const [jobModal, setJobModal] = useState(false);
  const [addRecruiter, setAddRecruiter] = useState(false);
  const [addCandidate, setAddCandidate] = useState(false);
  const [filter, setFilter] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState([]);
  const [jobSelected, setJobSelected] = useState([]);
  const [showDone, setShowDone] = useState(false);

  // useEffect(() => {
  //   dispatch(fetchAllJobs());
  // }, []);
  useEffect(() => {
    let x =
      location?.state?.isFrom === "talentCommunity"
        ? location.state
        : { talentData: [] };
    setData(x);
  }, []);
  useEffect(() => {
    dispatch(fetchAllBroadcastTemplates());
  }, []);

  useEffect(() => {
    if (templatesData) setBroadCastTemplates(templatesData);
  }, [templatesData]);

  useEffect(() => {
    if (data?.talentData && data?.talentData?.length > 0) {
      const dataList = data?.talentData?.slice(0, 1000);
      const emailLists = dataList?.map((item) => item.email);
      if (data.isFrom === "addRecruiter") setSelectedRecruiter(emailLists);
      else setSelectedCandidates(emailLists);
    }
  }, [data]);
  useEffect(() => {
    setBroadCastData(props.broadCastData);
  }, [props]);

  useEffect(() => {
    if (isSending) {
      setTimeout(() => {
        setIsSending(false);
      }, 2000);
    }
  }, [isSending]);

  const handleContentChange = (value) => {
    setContent(value);
  };

  const handleFooterChange = (value) => {
    setFooter(value);
  };

  const handleConfirmation = (text) => {
    let isValidObj = validationFunction();
    if (isValidObj) {
      if (text === "preview") setPreviewEmail(true);
      else if (text === "sendEmail") setSendConfirmation(true);
      setErrorData({});
    } else {
      toast.error("Please  fill the mandatory fields");
    }
  };

  const handleSendClick = async () => {
    setIsSending(true);
    setLoading(true);

    let paramObj = {
      mailSubject: subject,
      mailBody: content + footer,
      recipients: {
        recruiter: selectedRecruiter,
        candidates: selectedCandidates,
      },
      userId: getCandidateId(),
      broadCastId: uid(),
    };

    let sendEmailObj = await post("/broadCast/sendEmail", paramObj);
    if (sendEmailObj.status === 200) {
      toast.success("Email Sent Successfully");
      handleClear();
      setSendConfirmation(false);
      props.getAllSentEmails();
      setLoading(false);
    }
  };

  const validationFunction = () => {
    let errorObj = {};
    let isValid = true;
    if (footer === "") {
      errorObj["footer"] = "Please enter email signature";
      isValid = false;
    }
    if (content === "") {
      errorObj["content"] = "Please enter the email body ";
      isValid = false;
    }
    if (subject === "") {
      errorObj["subject"] = "Please enter email subject ";
      isValid = false;
    }
    if (selectedCandidates.length === 0 && selectedRecruiter.length === 0) {
      errorObj["recipient"] = "Please enter at least one recipient";
      isValid = false;
    }
    if (
      location?.state?.isFrom !== "talentCommunity" &&
      getUserRole() === "HiringManager" &&
      selectedJob &&
      Object.keys(selectedJob).length === 0
    ) {
      errorObj["selectJob"] = "Choose any Job";
      isValid = false;
    }
    console.log("errorObj", errorObj);
    setErrorData(errorObj);
    return isValid;
  };

  const handleClear = () => {
    setContent("");
    setSubject("");
    setFooter("");
    setSelectedCandidates([]);
    setSelectedRecruiter([]);
    setErrorData({});
    dispatch(updateJob([]));
    history.replace({ ...location, state: null });
    setData([]);
    //the body and footer is not clearing on first click. thats why this time out code added
    setTimeout(() => {
      setContent("");
      setFooter("");
    }, 0);
  };

  return (
    <>
      <Container style={{ fontSize: "14px" }}>
        {(loading || templatesDataLoading || jobListLoading) && <Loader />}
        <LeftSideMenu
          onSelectOption={setSelectedOption}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          getAllSentEmails={props.getAllSentEmails}
        />
        <Row style={{ minHeight: "60vh", fontSize: "14px" }}>
          {selectedOption === "compose" && (
            <Col md={12} sm={12}>
              <div className="mb-3 mt-2">
                {getUserRole() === "HiringManager" && (
                  // <>
                  //   <label className="label mb-2">Select Job</label>
                  //   <select
                  //     class="form-select"
                  //     id="selectJob"
                  //     name="selectJob"
                  //     value={selectedJob}
                  //     onChange={(e) => {
                  //       setSelectedJob(e.target.value);
                  //       setSelectedCandidates([]);
                  //       setSelectedRecruiter([]);
                  //     }}
                  //     disabled={
                  //       data?.isFrom === "talentCommunity" &&
                  //       selectedCandidates.length > 0
                  //     }
                  //   >
                  //     <option value="" selected>
                  //       Select Job
                  //     </option>
                  //     {jobList?.map((job) => (
                  //       <option value={job.jobId}>{job.jobTitle}</option>
                  //     ))}
                  //   </select>
                  // </>
                  <>
                    {location?.state?.isFrom !== "talentCommunity" && (
                      <div className="mt-5 mb-2">
                        <button
                          type="btn btn-primary btn-sm"
                          style={{
                            backgroundColor: "#17a2b8",
                            color: "white",
                            border: "0px",
                          }}
                          onClick={() => {
                            setJobModal(true);
                          }}
                        >
                          Select Job
                        </button>
                      </div>
                    )}
                    {Object.keys(selectedJob).length !== 0 && (
                      <div className="d-flex align-items-center">
                        <div className="d-block">
                          {selectedJob && (
                            <span
                              key={selectedJob.jobId}
                              className="tag tag-blue2 p-1 px-2 m-1 "
                            >
                              <span>{selectedJob.jobTitle}</span>{" "}
                              <a
                                href="#"
                                className="font-black"
                                onClick={(e) => {
                                  dispatch(updateJob([]));
                                }}
                              >
                                <i className="fas fa-times ml-2 "></i>
                              </a>
                            </span>
                          )}
                          <button
                            type="button"
                            className="btn btn-text py-1 "
                            onClick={() => {
                              history.replace({ ...location, state: null });
                              dispatch(updateJob([]));
                            }}
                          >
                            <span className="font-bold font-14">Clear All</span>
                          </button>
                        </div>
                      </div>
                    )}
                    <div className="color">{errorData["selectJob"]}</div>
                  </>
                )}
                <div className="mt-2">
                  <Recipients
                    setData={setData}
                    data={data.talentData || []}
                    selectedRecruiter={selectedRecruiter}
                    setSelectedRecruiter={setSelectedRecruiter}
                    selectedCandidates={selectedCandidates}
                    setSelectedCandidates={setSelectedCandidates}
                    addRecruiter={addRecruiter}
                    setAddRecruiter={setAddRecruiter}
                    addCandidate={addCandidate}
                    setAddCandidate={setAddCandidate}
                    filter={filter}
                    setFilter={setFilter}
                    isFrom={
                      data?.isFrom === "talentCommunity" ? data?.isFrom : ""
                    }
                    role={getUserRole()}
                    selectedJob={selectedJob}
                  />
                </div>
                <div className="color">{errorData["recipient"]}</div>
              </div>
              <Row className="messageForm mb-5">
                <Col sm={12} md={12}>
                  <label htmlFor="subject" className="label mb-2">
                    Subject*
                  </label>
                  <input
                    type="text"
                    id="subject"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    className="form-control"
                    style={{ fontWeight: "normal" }}
                    disabled={
                      getUserRole() === "HiringManager" &&
                      selectedJob === "" &&
                      data?.isFrom !== "talentCommunity"
                    }
                  />
                  <div className="color">{errorData["subject"]}</div>
                </Col>
                <Col sm={12} md={12}>
                  <label htmlFor="content" className="label mb-2">
                    Body*
                  </label>
                  <ReactQuill
                    value={content}
                    onChange={handleContentChange}
                    style={{
                      overflow: "auto",
                      fontWeight: "normal",
                    }}
                    readOnly={
                      getUserRole() === "HiringManager" && selectedJob === ""
                    }
                  />
                  <div className="color">{errorData["content"]}</div>
                </Col>
                <Col sm={12} md={12}>
                  <label htmlFor="footer" className="label mb-2">
                    Signature*
                  </label>
                  <ReactQuill
                    value={footer}
                    onChange={handleFooterChange}
                    style={{
                      overflow: "auto",
                      fontWeight: "normal",
                    }}
                    readOnly={
                      getUserRole() === "HiringManager" && selectedJob === ""
                    }
                  />
                  <div className="color">{errorData["footer"]}</div>
                </Col>
                <Col sm={12} md={12}>
                  <Row className="d-flex justify-content-between mt-3">
                    <Col md={6} sm={12}>
                      <button
                        onClick={handleClear}
                        disabled={isSending}
                        className="button mr-2 mt-2"
                      >
                        <i className="fa fa-eraser"></i> Clear
                      </button>
                    </Col>
                    <Col md={6} sm={12}>
                      <button
                        onClick={() => {
                          handleConfirmation("preview");
                        }}
                        disabled={isSending}
                        className="button align-items-right mr-2 mt-2"
                      >
                        <i className="fa fa-eye"></i> Preview Email
                      </button>
                      <button
                        onClick={() => {
                          handleConfirmation("sendEmail");
                        }}
                        disabled={isSending}
                        className="btn btn-primary mr-2 mt-2"
                      >
                        {isSending ? (
                          <i className="fa fa-spinner fa-spin"></i>
                        ) : (
                          <i className="fa fa-envelope"></i>
                        )}{" "}
                        {isSending ? "Sending..." : "Send Email"}
                      </button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          )}

          {selectedOption === "inbox" && (
            <div>
              <h6></h6>
              <label htmlFor="recipients" className="label mb-2">
                <small>Inbox Empty</small>
              </label>
            </div>
          )}
          {selectedOption === "sent" && (
            <div>
              <SentEmailsBox broadCastData={broadCastData} />
            </div>
          )}
          {selectedOption === "templates" && (
            <div>
              <EmailTemplates
                data={{
                  setSubject: setSubject,
                  setContent: setContent,
                  setFooter: setFooter,
                  setActiveTab: setActiveTab,
                  setSelectedOption: setSelectedOption,
                  broadCastTemplates: broadCastTemplates,
                }}
              />
            </div>
          )}
        </Row>

        {previewEmail && (
          <ModalView
            modalSize={"lg"}
            modalTitle={"Email Preview"}
            isModalOpen={previewEmail}
            setModalClose={setPreviewEmail}
            isFrom="EmailBroadcast"
            modalFor="preview"
            data={{
              footer: footer,
              content: content,
              subject: subject,
              selectedCandidates: selectedCandidates,
              selectedRecruiter: selectedRecruiter,
            }}
          />
        )}
        {sendConfirmation && (
          <ModalView
            modalSize={"md"}
            modalTitle={"Send Email confirmation"}
            isModalOpen={sendConfirmation}
            setModalClose={setSendConfirmation}
            isFrom="EmailBroadcast"
            modalFor="sendEmailConfirmation"
            data={{
              handleSendClick: handleSendClick,
            }}
          />
        )}
        {jobModal && (
          <ModalView
            modalSize={"xl"}
            modalTitle={"Select Job"}
            isModalOpen={jobModal}
            setModalClose={setJobModal}
            isFrom="EmailBroadcast"
            modalFor="selectJob"
            //data={jobList}
          />
        )}
        {/* {filter && (
        <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
          <BroadcastFilter
            selectedIndustry={selectedIndustry}
            setSelectedIndustry={setSelectedIndustry}
            showDone={showDone}
            setShowDone={setShowDone}
            isFrom={addRecruiter ? "addRecruiter" : "addCandidate"}
            filters={["industry"]}
          />
        </OutsideClickHandler>
      )} */}
      </Container>
    </>
  );
};

export default Email;
