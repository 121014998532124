import { toast } from "react-toastify";
import { get } from "../../service/ApiService";

export const FETCH_ALL_TAGS_BEGIN = "FETCH_ALL_TAGS_BEGIN";
export const FETCH_ALL_TAGS_SUCCESS = "FETCH_ALL_TAGS_SUCCESS";
export const FETCH_ALL_TAGS_FAILURE = "FETCH_ALL_TAGS_FAILURE";

export const AdminFetchAllTags = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_ALL_TAGS_BEGIN,
    });
    const res = await get("/tags/fetchAllTags");
    if (res.status === 200) {
      dispatch({
        type: FETCH_ALL_TAGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_ALL_TAGS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};
