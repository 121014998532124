import React, { useState, useEffect, useDebugValue } from "react";
import { get, post } from "../../../service/ApiService";
import Avatar from "react-avatar";

import {
  getUserName,
  getUserTenant,
  getCandidateId,
  getCandidateName,
  getUserEmail,
  loginUserName,
  loginUserEmail,
} from "../../../service/AuthService";
import { assignAssessmentValidation } from "../../validation/Common";
import { checkEmptyObject } from "../../function/CommonFunction";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import { emailDomain, domain } from "../../../constants/Constants";
// import TimezoneSelect from "react-timezone-select";
import DatePicker from "react-datepicker";
import AssessmentHistory from "../reruiter/AssessmentHistory";
import "react-datepicker/dist/react-datepicker.css";
import { gql, useMutation, useQuery } from "@apollo/client";
import Loader from "../../Loader/Loader";

/**Code Signal */
const GET_COMPANYTESTS = gql`
  query {
    companyTests(first: 100) {
      tests {
        id
        title
        internalDescription
        company {
          name
        }
      }
    }
  }
`;

const ADD_COMPANYTESTS = gql`
  mutation createCompanyTestSession(
    $testId: ID!
    $candidateFirstName: String!
    $candidateLastName: String!
    $candidateEmail: String!
  ) {
    createCompanyTestSession(
      sessionFields: {
        testId: $testId
        candidateFirstName: $candidateFirstName
        candidateLastName: $candidateLastName
        candidateEmail: $candidateEmail
      }
    ) {
      id
      invitationUrl
      testTaker {
        email
        firstName
        lastName
      }
      status
      createDate
      startDate
      finishDate
      result {
        score
        solvedTaskCount
        url
      }
      test {
        id
        title
        duration
      }
    }
  }
`;

const GET_COMPANYTESTS_RESULT = gql`
  query {
    companyTestSession(id: ID)
  }
`;

function ScheduleInterview(props) {
  const { error, data } = useQuery(GET_COMPANYTESTS);
  const [createCompanyTestSession] = useMutation(ADD_COMPANYTESTS);
  const [interviewType, setinterviewType] = useState("none");
  const [vetSelectedSkill, setVetSelectedSkill] = useState("");
  const [loading, setLoading] = useState(false);
  const [assessmentPlatform, setAssessmentPlatform] = useState("");
  const [interviewer, setinterviewer] = useState("");
  const [change, setchange] = useState(false);
  const [checkedFn, setcheckedFn] = useState(true);
  const [populatePlatform, setPopulatePlatform] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [modernHireData, setModernHireData] = useState([]);
  const [monjinOnewayData, setMonjinOnewayData] = useState([]);
  const [monjinTwowayData, setMonjinTwowayData] = useState([]);
  const [tovutiData, setTovutiData] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [duration, setDuration] = useState({});
  const [assessmentData, setAssessmentData] = useState([]);
  const [csAssessmentData, setCsAssessmentData] = useState([]);
  const [vettingData, setVettingData] = useState([]);
  const [vettingAssessmentData, setVettingAssessmentData] = useState([]);
  const [vettingDifficulty, setVettingDifficulty] = useState("");
  const [reviewerPresent, setReviewerPresent] = useState("false");
  const [expiryRange, setExpiryRange] = useState(7);
  const [notification, setNotification] = useState("");
  const [reviewer, setReviewer] = useState({
    name: loginUserName()?.split(" ")[0] || getCandidateName().split(" ")[0],
    reviewerEmail: loginUserEmail() || getUserEmail(),
    instructions: "",
  });
  // const Header = ["Name", "Age", "Size", "Phone", "Gender" ];

  const [interviewScheduleData, setInterviewScheduleData] = useState({
    interviewType: "",
    platform: "",
    assessment: "",
    templateReqNumber: "",
    reqTitle: "",
    MontageRequisitionId: "",
    interviewer: "",
    firstName: props.interviewScheduleData.firstName,
    lastName: props.interviewScheduleData.lastName,
    email: props.interviewScheduleData.email,
    duration: "",
    candidateID: props.interviewScheduleData.candidateID,
    monjinJobId: "",
    jobTitle: "",
    assignCandidateId: props.interviewScheduleData.candidateID,
    Title: "",
    candidateTenant: "",
    candidateid: "",
    course: "",
    Interview: "",
    tovutiCourseTitle: "",
    tovutiCourseId: "",
    tovutiCourseUrl: "",
    monjinJobId: "",
    jobId: props.jobData.jobId,
    monjinJobTitle: "",
    courseId: "",
    createdBy: props.interviewScheduleData.createdBy,
    tovutiListNot: "",
    assignEmail: "",
    codeSignalCourseId: "",
    codeSignalCourseTitle: "",
    vettingtestID: props.interviewScheduleData.vettingtestID,
    vettingTestName: props.interviewScheduleData.vettingTestName,
    vettingExpiredDays: 7,
    monjinTwowayData: {
      interviewerfirstName: "",
      interviewerlastName: "",
      intervieweremail: "",
      interviewdate: "",
      interviewduration: "",
      selectedTimezone: {},
    },
    TwowayData: {
      interviewerfirstName: "",
      interviewerlastName: "",
      intervieweremail: "",
    },
  });
  const Header = ["title", "AssessmentType", "status", "assignedBy"];

  const mordernTovuti = [
    { type: "modernHire", name: "Modern Hire" },
    // { type: "tovuti", name: "Tovuti" },
  ];
  const mordernMonjin = [
    { type: "modernHire", name: "Modern Hire" },
    // { type: "monjin", name: "Monjin" },
  ];

  const findtwoWayErrors = () => {
    const {
      interviewerfirstName,
      interviewerlastName,
      intervieweremail,
      interviewdate,
      interviewduration,
      selectedTimezone,
    } = props.interviewScheduleData.monjinTwowayData;
    const newErrors = {};
    // name errors
    //(Object.keys(selectedTimezone).length  === 0)
    const todayDate = new Date();
    if (interviewer == "none" || interviewer == "")
      newErrors.interviewerType = "please select Interviewer details ";
    if (!interviewdate || interviewdate === "")
      newErrors.interviewdate = "Interview Date and time cannot be Blank";
    if (Object.keys(selectedTimezone).length === 0)
      newErrors.selectedTimezone = "Timezone cannot be blank";
    if (!interviewduration || interviewduration === "select")
      newErrors.interviewduration = "Interview Duration cannot be blank";
    //  first name , last name ,email and
    if (!interviewerfirstName || interviewerfirstName === "")
      newErrors.interviewerfirstName = "First name cannot be Blank";
    if (!interviewerlastName || interviewerlastName === "")
      newErrors.interviewerlastName = "Last name cannot be Blank";
    if (!intervieweremail || intervieweremail === "")
      newErrors.intervieweremail = "Email cannot be blank";
    else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(intervieweremail))
      newErrors.intervieweremail = "Invalid Email id !";

    if (!interviewdate || interviewdate < todayDate)
      newErrors.interviewdate = "Interview Date and time cannot be old Date";

    return newErrors;
  };

  const findMHtwoWayErrors = () => {
    const {
      interviewerfirstName,
      interviewerlastName,
      intervieweremail,
    } = props.interviewScheduleData.TwowayData;
    const newErrors = {};
    // name errors
    //(Object.keys(selectedTimezone).length  === 0)
    const todayDate = new Date();

    if (interviewer == "none" || interviewer == "")
      newErrors.interviewerType = "please select Interviewer details ";
    //  first name , last name ,email and
    if (!interviewerfirstName || interviewerfirstName === "")
      newErrors.interviewerfirstName = "First name cannot be Blank";
    if (!interviewerlastName || interviewerlastName === "")
      newErrors.interviewerlastName = "Last name cannot be Blank";
    if (!intervieweremail || intervieweremail === "")
      newErrors.intervieweremail = "Email cannot be blank";
    else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(intervieweremail))
      newErrors.intervieweremail = "Invalid Email id !";
    //(newErrors)
    return newErrors;
  };

  const updatetwowaySlot = (date) => {
    const scheduleddata = { ...props.interviewScheduleData };
    scheduleddata.monjinTwowayData.interviewdate = new Date(date);

    props.setInterviewScheduleData(scheduleddata);
    const newErrors = findtwoWayErrors();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
  };

  const updatetwowayTimeZone = (timezone) => {
    const scheduleddata = { ...props.interviewScheduleData };
    scheduleddata.monjinTwowayData.selectedTimezone = timezone;
    props.setInterviewScheduleData(scheduleddata);
    const newErrors = findtwoWayErrors();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
  };
  const updatetwowayDuration = (e) => {
    const scheduleddata = { ...props.interviewScheduleData };
    scheduleddata.monjinTwowayData.interviewduration = e.target.value;
    props.setInterviewScheduleData(scheduleddata);
    const newErrors = findtwoWayErrors();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
  };

  const handletwoWayChange = (e) => {
    const scheduleddata = { ...props.interviewScheduleData };
    const id = e.target.id;
    scheduleddata.monjinTwowayData[id] = e.target.value;
    scheduleddata.TwowayData[id] = e.target.value;

    props.setInterviewScheduleData(scheduleddata);
    const newErrors = findtwoWayErrors();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
  };
  const handleChange = (e) => {
    setDisableSubmit(false);
    //(e.target.value)

    const interviewer = e.target.value;
    if (interviewer == "self") {
      // set interviwer first name  last name and email
      const scheduleddata = { ...props.interviewScheduleData };
      const recruiterNames = getCandidateName().split(" ");
      const recruiterEmail = getUserEmail();
      scheduleddata.monjinTwowayData.interviewerfirstName = recruiterNames[0];
      scheduleddata.monjinTwowayData.interviewerlastName = recruiterNames[1];
      scheduleddata.monjinTwowayData.intervieweremail = recruiterEmail;
      scheduleddata.TwowayData.interviewerfirstName = recruiterNames[0];
      scheduleddata.TwowayData.interviewerlastName = recruiterNames[1];
      scheduleddata.TwowayData.intervieweremail = recruiterEmail;
      props.setInterviewScheduleData(scheduleddata);
    } else {
      const scheduleddata = { ...props.interviewScheduleData };

      scheduleddata.monjinTwowayData.interviewerfirstName = "";
      scheduleddata.monjinTwowayData.interviewerlastName = "";
      scheduleddata.monjinTwowayData.intervieweremail = "";
      scheduleddata.TwowayData.interviewerfirstName = "";
      scheduleddata.TwowayData.interviewerlastName = "";
      scheduleddata.TwowayData.intervieweremail = "";
      props.setInterviewScheduleData(scheduleddata);
    }

    if (e.target.type === "select-one") {
      if (e.target.id === "floatingSelect") {
        //setinterviewType(e.target.value);
      }
      if (e.target.id === "floatingSelectinterviewer") {
        setinterviewer(e.target.value);
      }
      setchange(false);
    } else {
      if (e.target.type === "checkbox") {
        setcheckedFn(e.target.checked);
      } else {
        [e.target.id] = e.target.value;
      }
    }
  };

  const handleAssessmentChange = (e) => {
    //(e.length)
    if (e.length != 0) {
      let title = e[0].Title ? e[0].Title : "";

      setDisableSubmit(false);
      ////(e[0].title);
      props.setInterviewScheduleData({
        ...props.interviewScheduleData,
        assessment: title,
        templateReqNumber: e[0].RequisitionNumber,
        modernHireCourseTitle: e[0].Title,
        modernHireCourseId: e[0].MontageRequisitionId,
        tovutiCourseTitle: e[0].Title,
        tovutiCourseId: e[0].id,
        tovutiCourseUrl: e[0].Url,
        monjinJobId: e[0].monjinJobId,
        jobId: e[0].jobId,
        monjinJobTitle: e[0].title,
      });
    }
  };

  /**Code Signal */
  const handleCodeSignalAssessmentChange = (e) => {
    if (e.length !== 0) {
      setDisableSubmit(false);
      props.setInterviewScheduleData({
        ...props.interviewScheduleData,
        jobId: e[0].jobId,
        codeSignalCourseId: e[0].id,
        codeSignalCourseTitle: e[0].title,
      });
    }
  };

  /**Vetting Platform*/
  const handleVettingAssessmentChange = (e) => {
    if (e.length !== 0) {
      const testNameVet = vettingData.find((x) => x._id === e.target.value);
      setDisableSubmit(false);
      setInterviewScheduleData({
        ...interviewScheduleData,
        jobId: e.jobId,
        vettingTestName: testNameVet.testName,
        vettingtestID: testNameVet._id,
        // .filter((x) => x.testName === e.target.value)
        // .map((i) => i._id),
      });
      setVetSelectedSkill(e.target.value);

      setDuration("");
      setVettingDifficulty("");
    }
  };

  const handleInterviewTypeChange = (e) => {
    setinterviewType(e.target.value);
    setDisableSubmit(false);
    const listArray = ["2Way"];
    const listArray_new = ["CodeSignal"];
    const listArray_vetting = ["MCQ", "OneWay", "General"];

    if (listArray_vetting.includes(e.target.value)) {
      setAssessmentPlatform("vettingPlatform");
      props.setInterviewScheduleData({
        ...props.interviewScheduleData,
        interviewType: e.target.value,
        platform: "vettingPlatform",
      });
      // getVettingAssessment();
    }

    if (listArray.includes(e.target.value)) {
      setAssessmentPlatform("modernHire");
      props.setInterviewScheduleData({
        ...props.interviewScheduleData,
        interviewType: e.target.value,
        platform: "modernHire",
      });
      getMordernHireAssessment();
    } else if (listArray_new.includes(e.target.value)) {
      setAssessmentPlatform("codeSignal");
      props.setInterviewScheduleData({
        ...props.interviewScheduleData,
        interviewType: e.target.value,
        platform: "codeSignal",
      });
    }
    if (e.target.value === "MCQ") {
      setPopulatePlatform(mordernTovuti);
      getTovutiAssessment();
    }

    if (e.target.value !== "MCQ") {
      setPopulatePlatform(mordernMonjin);
      getMonjinOnewayAssessment();
      getMonjinTwowayAssessment();
    }
  };

  const getAssessments = async () => {
    try {
      setLoading(true);
      const candidateID = props.candidateID;
      const candidateEmail = props.interviewScheduleData.email;
      const assessmentResult = await get(
        `/assignAssessment/view/${candidateID}&${candidateEmail}`
      );
      setLoading(false);
      setAssessmentData([
        // ...assessmentData,
        ...assessmentResult.data.tovuti,
        ...assessmentResult.data.oneWay,
        ...assessmentResult.data.twoWay,
      ]);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getAssessments();
  }, []);

  useEffect(() => {
    //(assessmentData)
  }, [assessmentData]);

  const handleAssessmentPlatformChange = (e) => {
    setAssessmentPlatform(e.target.value);
    //(e.target)
    setDisableSubmit(false);
    props.setInterviewScheduleData({
      ...props.interviewScheduleData,
      platform: e.target.value,
    });

    if (e.target.value === "modernHire") {
      getMordernHireAssessment();
    }
    if (e.target.value === "monjin" && interviewType === "1Way") {
      getMonjinOnewayAssessment();
    }
    if (e.target.value === "monjin" && interviewType === "2Way") {
      getMonjinTwowayAssessment();
    }
    if (e.target.value === "tovuti") {
      getTovutiAssessment();
    }
  };

  // getMordernHireAssessment
  const getMordernHireAssessment = async () => {
    try {
      const res = await get(`/modernhire/requisition`);
      //("mordern hire list ...", res);
      setModernHireData(res.data);
    } catch (error) {
      toast.error(error);
    }
  };

  // const fetchVetting = async () => {
  //     const res = await axios.get('https://https://high5vettinguat-api.azurewebsites.net/test/all', {
  //       headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
  //     })
  //     setVettingDB(res.data.filter(i => i.testName.length === 1))
  //   }

  // getVettingAssessment
  const getVettingAssessment = async () => {
    setLoading(true);
    try {
      const res = await get(`/vetting/assessment`);
      setVettingData(res.data);
      setLoading(false);
    } catch (error) {
      toast.error(error);
    }
  };

  // getMonjinOnewayAssessment
  const getMonjinOnewayAssessment = async () => {
    try {
      const res = await get(`/monjin/getJObList`);
      setMonjinOnewayData(res.data);
    } catch (error) {
      toast.error(error);
    }
  };

  // getMonjinTwowayAssessment
  const getMonjinTwowayAssessment = async () => {
    try {
      const res = await get(`/monjin/getJObListTwoWay`);
      setMonjinTwowayData(res.data);
    } catch (error) {
      toast.error(error);
    }
  };

  // getTovutiAssessment
  const getTovutiAssessment = async () => {
    try {
      const res = await get(`/tovuti/getCourse`);
      setTovutiData(res.data);
    } catch (error) {
      toast.error(error);
    }
  };

  const modernHireMCQ = modernHireData.filter(function(e) {
    return e.RequisitionNumber.split("-")[1] === "MCQ";
  });

  const modernHireOneWay = modernHireData.filter(function(e) {
    return e.RequisitionNumber.split("-")[1] === "OneWay";
  });

  const modernHireTwoWay = modernHireData.filter(function(e) {
    return e.RequisitionNumber.split("-")[1] === "TwoWay";
  });

  const vettingMCQ = vettingData
    .filter((x) => x.testCategory === "MCQ")
    .map((i) => {
      return { testName: i.testName, id: i._id };
    });

  const vettingOneWay = vettingData
    .filter((x) => x.testCategory === "OneWay")
    .map((i) => {
      return { testName: i.testName, id: i._id };
    });

  const vettingGeneral = vettingData
    .filter((x) => x.testCategory === "General")
    .map((i) => {
      return { testName: i.testName, id: i._id };
    });

  let arrDuration = [];
  vettingData
    .filter((x) => x.testCategory === "MCQ")
    .forEach((item) => {
      let obj = {
        duration: item?.details?.duration,
        testName: item.testName,
        difficulty: item.difficulty,
        id: item._id,
      };
      arrDuration.push(obj);
      return arrDuration;
    });

  //setVetSelectedSkill(arrDuration)

  const testDifficulty = arrDuration.filter((x) => x.id === vetSelectedSkill);
  // .map((i) => {
  //   return i.difficulty;
  // });
  const testDuration = arrDuration.filter((x) => x.id === vetSelectedSkill);
  // .map((i) => {
  //   return i.duration;
  // });

  let arrVideo = [];
  let arrGeneral = [];
  vettingData
    .filter((x) => x.testCategory === "OneWay")
    .forEach((item) => {
      let obj = {
        duration: item?.details?.duration,
        testName: item.testName,
        difficulty: item.difficulty,
        id: item._id,
      };

      arrVideo.push(obj);
      return arrVideo;
    });

  vettingData
    .filter((x) => x.testCategory === "General")
    .forEach((item) => {
      let obj = {
        duration: item?.details?.duration,
        testName: item.testName,
        difficulty: item.difficulty,
        id: item._id,
      };

      arrGeneral.push(obj);
      return arrGeneral;
    });

  const testDifficultyV = arrVideo.filter((x) => x.id === vetSelectedSkill);
  const testDifficultyG = arrGeneral.filter((x) => x.id === vetSelectedSkill);
  // .map((i) => {
  //   return i.difficulty;
  // });
  const testDurationV = arrVideo.filter((x) => x.id === vetSelectedSkill);
  const testDurationG = arrGeneral.filter((x) => x.id === vetSelectedSkill);
  // .map((i) => {
  //   return i.duration;
  // });

  const changeFn = (e) => {
    setchange(true);
  };

  const close = () => {
    props.handleSuccess(true);
  };
  //ModernHire Assessement Insert
  const moderHireInsertData = async (url, body) => {
    setDisableSubmit(true);
    setLoading(true);
    try {
      const resultsModernhire = await post(url, body);
      if (resultsModernhire.status === 200) {
        setLoading(false);
        toast.success("Interview assigned successfully");
      } else {
        toast.error("Error Occured While assigning Interview");
      }
      props.handleSuccess(true);
    } catch (error) {
      toast.error(error);
      setLoading(false);
      props.handleSuccess(true);
    }
  };

  //Vetting Assessement Insert
  const vettingPlatformInsertData = async (url, body) => {
    setDisableSubmit(true);
    setLoading(true);

    try {
      const resultsVetting = await post(url, body);

      if (resultsVetting.status === 200) {
        setLoading(false);
        toast.success("Vetting Assessment assigned successfully");
        props.handleSuccess(true);

        return true;
      } else {
        toast.error("Error Occured While assigning Interview");
        props.handleSuccess(true);
        return false;
      }
    } catch (error) {
      toast.error(error);
      setLoading(false);
      props.handleSuccess(true);
    }
  };

  //Monjin Assessment
  const monjinOneWayDataSubmit = async (
    monjinAssessementOneWay,
    urlOneWay,
    body
  ) => {
    setDisableSubmit(true);

    try {
      const monjinResult = await post(monjinAssessementOneWay, body);

      let monjinData = monjinResult.data;

      if (monjinData.monjinJobId) {
        const bodyData = {
          ...body,
          ...monjinData,
        };

        // monjinData.candidateID =   props.interviewScheduleData.candidateID

        const assignassessmentresult = await post(urlOneWay, bodyData);
        if (assignassessmentresult.status == 200) {
          if (assignassessmentresult.data.text === "Title Exist") {
            toast.error("Already Assigned interview to candidate ");
          } else {
            toast.success("Assessment assigned to candidate");
          }
        }
      } else if (monjinData.status === "alreay data present") {
        toast.error("Already Assigned course ");
      } else {
        toast.error("Error Occured While saving Record");
      }
    } catch (error) {
      // if(error.status)
      console.log(error);
      toast.error("Error Occured While saving Record");
    }
  };

  /**Code Signal */
  const assignCodeSignalAssessment = async (e) => {
    let recruiterFirstName = getCandidateName().split(" ")[0];
    let recruiterLastName = getCandidateName()
      .split(" ")
      .slice(-1)[0];
    const recruiterEmail = getUserEmail();
    const recruiterId = getCandidateId();

    try {
      setLoading(true);
      let testSessionCreated = await createCompanyTestSession({
        variables: {
          testId: props.interviewScheduleData.codeSignalCourseId,
          candidateFirstName: props.interviewScheduleData.firstName,
          candidateLastName: props.interviewScheduleData.lastName,
          candidateEmail: props.interviewScheduleData.email,
        },
      });

      var testDate = new Date(
        testSessionCreated.data.createCompanyTestSession.createDate
      );
      const assignObj = {
        InterviewId: testSessionCreated.data.createCompanyTestSession.id,
        JobId: props.jobData.jobId,
        CandidateId: props.interviewScheduleData.candidateID,
        RecruiterId: recruiterId,
        InterviewType:
          testSessionCreated.data.createCompanyTestSession.__typename,
        InvitationUrl:
          testSessionCreated.data.createCompanyTestSession.invitationUrl,
        AssignedTo: props.interviewScheduleData.email,
        CreatedBy: recruiterEmail,
        Status: testSessionCreated.data.createCompanyTestSession.status,
        created_date: testDate,
        CandidateFname: props.interviewScheduleData.firstName,
        CandidateLname: props.interviewScheduleData.lastName,
        RecruiterFname: recruiterFirstName,
        RecruiterLname: recruiterLastName,
        reqTitle: testSessionCreated.data.createCompanyTestSession.test.id,
        reqNumber: testSessionCreated.data.createCompanyTestSession.test.title,
      };

      setLoading(true);
      try {
        let res = await post("/codesignal/csAssessmentInsert", assignObj);
        if (res.status === 200) {
          setLoading(false);
          toast.success("Code Signal Assessment assigned successfully");
        }
        close();
      } catch (error) {
        toast.error(error);
        setLoading(false);
        close();
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const validate = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return email.match(mailformat) ? true : false;
  };

  const onSubmitAssignAssessment = async (e) => {
    if (!validate(reviewer.reviewerEmail))
      return toast.error("Please enter a valid reviwer email");
    // recruiter email & name
    const recruiterNames = getCandidateName().split(" ");
    const recruiterEmail = getUserEmail();
    const recruiterId = getCandidateId();

    if (
      assessmentPlatform === "monjin" &&
      props.interviewScheduleData.monjinJobId !== ""
    ) {
      const monjinAssessementOneWay = "/monjin/ScheduleOneway";
      if (interviewType === "1Way") {
        setDisableSubmit(true);
        const body = {
          firstName: props.interviewScheduleData.firstName,
          lastName: props.interviewScheduleData.lastName,
          email: props.interviewScheduleData.email,
          monjinJobId: props.interviewScheduleData.monjinJobId,
          jobTitle: props.interviewScheduleData.monjinJobTitle,
          interviewType: "1-way",
          visibility: "",
          candidateid: props.interviewScheduleData.assignCandidateId,
          vettingType: "one-way",
          urlLink: emailDomain,
          InterviewInviteId: "",
          title: props.interviewScheduleData.monjinJobTitle,
          tenant: props.interviewScheduleData.candidateTenant,
          jobId: props.jobData.jobId,
        };
        let urlOneWay = "/scheduleInterview/oneWay";
        monjinOneWayDataSubmit(monjinAssessementOneWay, urlOneWay, body);
      } else if (
        interviewType === "2Way" &&
        props.interviewScheduleData.monjinJobId !== ""
      ) {
        setDisableSubmit(true);
        const newErrors = findtwoWayErrors();
        if (Object.keys(newErrors).length > 0) {
          // We got errors!
          setErrors(newErrors);
          //  alert("please fill all details ")
        } else {
          setErrors(newErrors);
          // no erros so call api
          const twowaydata = {
            ...props.interviewScheduleData,
            jobId: props.jobData.jobId,
            monjinJobId: props.interviewScheduleData.monjinJobId,
            monjinJobTitle: props.interviewScheduleData.monjinJobTitle,
            firstName: props.interviewScheduleData.firstName,
            lastName: props.interviewScheduleData.lastName,
            email: props.interviewScheduleData.email,
            interviewType: "2-way",
            visibility: "",
            candidateid: props.interviewScheduleData.assignCandidateId,
            vettingType: "two-way",
            urlLink: emailDomain,
            InterviewInviteId: "",
            title: props.interviewScheduleData.monjinJobTitle,
            tenant: props.interviewScheduleData.candidateTenant,
            ...props.interviewScheduleData.monjinTwowayData,
          };
          //
          // monjin twoway api
          // then assignassessment
          try {
            const twoWayResult = await post(
              "/monjin/ScheduleTwoway",
              twowaydata
            );
            if (twoWayResult.data.monjinScheduleId) {
              // call schedule api
              toast.success("Interview Assigned Successfully.");
            } else if (twoWayResult.data.status === "alreay data present") {
              toast.error("Already Course Assigned .");
              //
            } else {
              toast.error("error Occured While assigning interview.");
            }
          } catch (err) {
            toast.error("error Occured While assigning interview.");
          }
          //   props.handleSuccess(true);
        }
      } else {
        toast.error("Please Select Assessment ");
      }
    } else if (assessmentPlatform === "tovuti" && interviewType === "MCQ") {
      let tovutiList = [];
      let tovutiId = [];
      let courseId = [];
      setDisableSubmit(true);
      if (props.interviewScheduleData.tovutiCourseId) {
        // check if user exists in tovuti
        try {
          const tovutiUsersList = JSON.parse(
            localStorage.getItem("tovutiUsers")
          );
          let tovutiuser = tovutiUsersList.find(
            (record) => record.Email === props.interviewScheduleData.email
          );
          let tovutiList = [];
          if (tovutiuser) {
            // api call /tovuti/assignedUsers
            const tovutiuserDataWithId = {
              firstName: props.interviewScheduleData.firstName,
              lastName: props.interviewScheduleData.lastName,
              email: props.interviewScheduleData.email,
              id: tovutiuser.id,
            };
            tovutiList.push(tovutiuserDataWithId);
            const assignObj = {
              jobId: props.jobData.jobId,
              tovutiList: tovutiList,
              courseId: props.interviewScheduleData.tovutiCourseId,
              courseName: props.interviewScheduleData.tovutiCourseTitle,
              courseUrl: props.interviewScheduleData.tovutiCourseUrl,
              domain: domain,
              candidateid: props.interviewScheduleData.candidateID,
              firstName: props.interviewScheduleData.firstName,
              lastName: props.interviewScheduleData.lastName,
              email: props.interviewScheduleData.email,
            };

            const assignedUserUrl = "/tovuti/assignedUsers";
            const monjinResult = await post(assignedUserUrl, assignObj);

            if (monjinResult.status === 200) {
              //  store in database
              const assignObj = {
                jobId: props.jobData.jobId,
                tovutiList: tovutiList,
                courseId: props.interviewScheduleData.tovutiCourseId,
                courseName: props.interviewScheduleData.tovutiCourseTitle,
                candidateId: props.interviewScheduleData.candidateID,
                courseUrl: props.interviewScheduleData.tovutiCourseUrl,
                domain: domain,
              };
              const MCQTovutiURL = "/scheduleInterview"; //Tovuti Assigned users interview

              const assignAssessmentTovuti = await post(
                MCQTovutiURL,
                assignObj
              );
              if (assignAssessmentTovuti.status === 200) {
                toast.success("Interview assigned Successfully");
                props.handleSuccess(true);
              }
            }
          } else {
            //create user and assign to canidate
            let tovutiListNot = [];

            const tovutidata2 = {
              firstName: props.interviewScheduleData.firstName,
              lastName: props.interviewScheduleData.lastName,
              email: props.interviewScheduleData.email,
            };
            tovutiListNot.push(tovutidata2);
            const createObj = {
              tovutiListNot: tovutiListNot,
              courseId: props.interviewScheduleData.tovutiCourseId,
              courseName: props.interviewScheduleData.tovutiCourseTitle,
              courseUrl: props.interviewScheduleData.tovutiCourseUrl,
              domain: domain,
              candidateid: props.interviewScheduleData.candidateID,
            };

            const createUserurl = "/tovuti/createUser";
            const monjinResult = await post(createUserurl, createObj);
            // if creation succeeded then call assign assessment api
            if (monjinResult.status === 200 && monjinResult.data.length !== 0) {
              tovutiUsersList.push({
                id: monjinResult.id,
                FirstName: props.interviewScheduleData.firstName,
                LastName: props.interviewScheduleData.lastName,
                Email: props.interviewScheduleData.email,
              });

              // set tovutiUsersList in localstorage
              localStorage.setItem(
                "tovutiUsers",
                JSON.stringify(tovutiUsersList)
              );
              const tovutidata = {
                candidateFirstName: props.interviewScheduleData.firstName,
                candidateLastName: props.interviewScheduleData.lastName,
                candidateemail: props.interviewScheduleData.email,
                id: monjinResult.id,
              };

              tovutiList.push(tovutidata);
              // insert record in localstorage

              const assignObj = {
                jobId: props.jobData.jobId,
                tovutiList: tovutiList,
                courseId: props.interviewScheduleData.tovutiCourseId,
                courseName: props.interviewScheduleData.tovutiCourseTitle,
                candidateId: props.interviewScheduleData.candidateID,
                courseUrl: props.interviewScheduleData.tovutiCourseUrl,
                domain: domain,
                tovutiid: monjinResult.id,
              };
              const MCQTovutiURL = "/scheduleInterview"; //Tovuti Assigned users assessment

              const assignAssessmentTovuti = await post(
                MCQTovutiURL,
                assignObj
              );

              if (assignAssessmentTovuti.status === 200) {
                toast.success("Interview assigned Successfully");
                props.handleSuccess(true);
              }
              // call assingassessment for tovuti for assign assessment
            } else {
              toast.error("Error Occured while assigning Interview.");
              props.handleSuccess(true);
            }
          }
        } catch (error) {
          toast.error("Something went wrong");
          props.handleSuccess(true);
        }
      } else {
        toast.error("Please select interview details ");
      }
    } else if (assessmentPlatform === "modernHire") {
      const url = "/modernhire/workflow/job"; //ModernHire Assessment Insert api
      let body = "";
      let recruiterFirstName = getCandidateName().split(" ")[0];
      let recruiterLastName = getCandidateName()
        .split(" ")
        .slice(-1)[0];
      if (
        (interviewType === "MCQ" || interviewType === "1Way") &&
        props.interviewScheduleData.templateReqNumber !== ""
      ) {
        body = {
          candidateFirstName: props.interviewScheduleData.firstName,
          candidateLastName: props.interviewScheduleData.lastName,
          candidateEmail: props.interviewScheduleData.email,
          recruiterFirstName: recruiterFirstName,
          recruiterLastName: recruiterLastName,
          recruiterEmail: recruiterEmail,
          reqNumber: `${props.jobData.jobId}_${props.interviewScheduleData.templateReqNumber}`,
          reqTitle: props.interviewScheduleData.modernHireCourseTitle,
          templateReqNumber: props.interviewScheduleData.templateReqNumber,
          candidateId: props.candidateID,
          interviewType: interviewType,
          recruiterId: getCandidateId(),
          urlLink: emailDomain,
          tenantName: getUserTenant(),
          recruiter: getUserName(),
          JobId: props.jobData.jobId,
          reviewer: { ...reviewer },
        };
        moderHireInsertData(url, body);
        props.submitShortList();
      } else if (
        interviewType === "2Way" &&
        props.interviewScheduleData.templateReqNumber !== ""
      ) {
        //  interview type
        const newErrors = findMHtwoWayErrors();
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
          body = {
            candidateFirstName: props.interviewScheduleData.firstName,
            candidateLastName: props.interviewScheduleData.lastName,
            candidateEmail: props.interviewScheduleData.email,
            recruiterFirstName:
              props.interviewScheduleData.TwowayData.interviewerfirstName,
            recruiterLastName:
              props.interviewScheduleData.TwowayData.interviewerlastName,
            recruiterEmail:
              props.interviewScheduleData.TwowayData.intervieweremail,
            reqNumber: `${props.jobData.jobId}_${props.interviewScheduleData.templateReqNumber}`,
            reqTitle: props.interviewScheduleData.assessment,
            templateReqNumber: props.interviewScheduleData.templateReqNumber,
            candidateId: props.candidateID,
            interviewType: interviewType,
            recruiterId: getCandidateId(),
            urlLink: `${window.location.protocol}${window.location.host}`,
            tenantName: getUserTenant(),
            recruiter: getUserName(),
            JobId: props.jobData.jobId,
          };
          moderHireInsertData(url, body);
          props.submitShortList();
        }
      } else {
        body = {
          candidateFirstName: props.interviewScheduleData.firstName,
          candidateLastName: props.interviewScheduleData.lastName,
          candidateEmail: props.interviewScheduleData.email,
          recruiterFirstName: recruiterFirstName,
          recruiterLastName: recruiterLastName,
          recruiterEmail: getUserEmail(),
          reqNumber: `${props.interviewScheduleData.assessment}_${
            getUserName().split("@")[0]
          }_${props.interviewScheduleData.templateReqNumber}`,
          reqTitle: props.interviewScheduleData.assessment,
          templateReqNumber: props.interviewScheduleData.templateReqNumber,
          candidateId: props.candidateID,
          interviewType: interviewType,
          recruiterId: getCandidateId(),
          urlLink: `${window.location.protocol}${window.location.host} `,
          tenantName: getUserTenant(),
          recruiter: getUserName(),
        };
        toast.error("Please Select Interview");
      }
      //props.submitShortList()
      props.refreshPage();
    } else if (
      /**Code Signal */
      assessmentPlatform === "codeSignal" &&
      props.interviewScheduleData.codeSignalCourseId !== ""
    ) {
      assignCodeSignalAssessment();
      props.submitShortList();
    } else if (assessmentPlatform === "vettingPlatform") {
      /**Vetting Platform*/
      const url = "/vetting/workflow";
      let body = "";
      const recruiterFirstName = getCandidateName().split(" ")[0];
      const recruiterLastName = getCandidateName()
        .split(" ")
        .slice(-1)[0];
      const recruiterEmail = getUserEmail();
      const loginEmail = loginUserEmail();
      if (
        interviewType === "MCQ" ||
        interviewType === "OneWay" ||
        interviewType === "General"
      ) {
        body = {
          candidateFirstName: interviewScheduleData.firstName,
          candidateLastName: interviewScheduleData.lastName,
          candidateEmail: interviewScheduleData.email,
          recruiterFirstName:
            loginUserName()?.split(" ")[0] || recruiterFirstName,
          recruiterLastName:
            loginUserName()?.split(" ")[1] || recruiterLastName,
          recruiterEmail: loginEmail?.length > 1 ? loginEmail : recruiterEmail,
          candidateId: props.candidateID,
          interviewType: interviewType,
          recruiterId: getCandidateId(),
          urlLink: emailDomain,
          tenantName: getUserTenant(),
          recruiter: getUserName(),
          JobId: props.jobData.jobId,
          JobTitle: props.jobData.jobTitle,
          testID: interviewScheduleData?.vettingtestID,
          testName: interviewScheduleData.vettingTestName,
          expired: interviewScheduleData.vettingExpiredDays,
          reviewer: { ...reviewer },
        };

        let vetResponse = await vettingPlatformInsertData(url, body);
        if (vetResponse)
          if (
            (props.parent === "Submitted" || props.parent === "Disqualified") &&
            props.parent !== "Shortlist"
          ) {
            props.submitShortList();
            props.refreshPage();
          }
      }
    } else toast.error("Please select interview details ");
  };

  const fetchVettingAssessmentDetail = async () => {
    try {
      setLoading(true);
      const candidateEmail = props.candidateData.email;
      const candidateID = props.candidateData.candidateID;
      let payload = {
        email: candidateEmail,
        id: candidateID,
      };
      let response = await post("/view/vettingViewAssessment", payload);
      if (response.status === 200) {
        setLoading(false);
        setVettingAssessmentData([
          ...response.data.mcq,
          ...response.data.oneWay,
          ...response.data.general,
        ]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  //fetch code signal history
  const fetchCodeSignalAssessmentDetail = async () => {
    try {
      setLoading(true);
      const candidateEmail = props.candidateData.email;
      const candidateID = props.candidateData.candidateID;
      let payload = {
        email: candidateEmail,
        id: candidateID,
      };
      let response = await post("/codesignal/csViewAssessment", payload);

      if (response.status === 200) {
        setLoading(false);
        setCsAssessmentData([...response.data]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  let finalAssessmentData = [
    ...assessmentData,
    ...csAssessmentData,
    ...vettingAssessmentData,
  ];

  useEffect(() => {
    getVettingAssessment();
    fetchVettingAssessmentDetail();
    fetchCodeSignalAssessmentDetail();
    props.setErrors(errors);
  }, [errors]);

  function getUnique(vettingMCQ) {
    // loop through array
    // for (let i of vettingMCQ) {
    //   if (uniqueArr.indexOf(i) === -1) {
    //     uniqueArr.push(i);
    //   }
    // }
    // return uniqueArr;
    const uniqueArr = [
      ...new Map(vettingMCQ.map((item) => [item.testName, item])).values(),
    ];
    return uniqueArr;
  }
  const handleReviewerChange = (e) => {
    setReviewerPresent(e.target.value);
    if (e.target.value === "true") {
      setReviewer({
        name: "",
        reviewerEmail: "",
        instructions: "",
      });
    } else {
      setReviewer({
        name: getCandidateName().split(" ")[0],
        reviewerEmail: getUserEmail(),
        instructions: "",
      });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="modal-body ">
          {/* {finalAssessmentData.length > 0 && (
            <div>
              <AssessmentHistory Header={Header} data={finalAssessmentData} />
            </div>
          )} */}
          <div className="card card-flat card-borderless ">
            <div className="card-body">
              <span>
                Please complete the form below to setup your interview.
              </span>
              <div className="form-floating">
                <select
                  className="form-select"
                  id="interview-type"
                  name="interviewType"
                  aria-label="Floating label select example"
                  onChange={handleInterviewTypeChange}
                  defaultValue={interviewType}
                >
                  <option selected value="none">
                    Select Type
                  </option>
                  <option value="MCQ">Multiple Choice Questionaire(MCQ)</option>
                  <option value="OneWay">OneWay Interview(1-way)</option>
                  <option value="General">General Interview</option>
                  {/* <option value="MCQ">Multiple Choice Questionaire(MCQ)</option>
                                    <option value="1Way">MCQ Based OneWay Interview(1-way)</option>*/}
                  <option value="2Way">Expert Vetted Interview(2-way)</option>
                  <option value="CodeSignal">Coding Test</option>
                  {/* <option value="F2F">Face To Face</option>
                                    <option value="Telephone">Telephone</option> */}
                </select>
                <label for="floatingSelect">
                  Please select the interview type *
                </label>
              </div>

              {/* Vetting assessment selection*/}
              <div className="row mt-3">
                {interviewType === "MCQ" &&
                  assessmentPlatform === "vettingPlatform" && (
                    <div className="col-lg-12 mb-2 mb-9">
                      <div className="card p-2">
                        <div className="d-flex">
                          <div className="col-4 ">
                            {/* <div className="form-floating ">
                              <Select
                                style={{ paddingTop: 10 }}
                                options={getUnique(vettingMCQ).map((i) => ({
                                  value: i,
                                  label: i,
                                }))}
                                searchBy="title"
                                className="assessment-interview"
                                name="vettingMCQ"
                                placeholder="Select Assessment *"
                                searchable={true}
                                noDataLabel="No matches found"
                                onChange={handleVettingAssessmentChange}
                              />
                            </div> */}
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={vetSelectedSkill}
                                onChange={handleVettingAssessmentChange}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {getUnique(vettingMCQ).map((item) => (
                                  <option key={item?.id} value={item?.id}>
                                    {item?.testName}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Select Assessment*</label>
                            </div>
                          </div>
                          <div className="col-4 ps-2">
                            {/* <div
                              className="form-floating "
                              style={{ height: 50 }}
                            >
                              <Select
                                style={{ paddingTop: 10 }}
                                options={testDifficulty.map((i) => ({
                                  value: i,
                                  label: i,
                                }))}
                                searchBy="title"
                                className="assessment-interview"
                                name="vettingMCQ"
                                placeholder="Difficulty Level *"
                                searchable={true}
                                noDataLabel="No matches found"
                                onChange={(e) =>
                                  setVettingDifficulty(e?.target?.value)
                                }
                              />
                            </div> */}
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={vettingDifficulty}
                                onChange={(e) => {
                                  setVettingDifficulty(e?.target?.value);
                                }}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {testDifficulty.map((item) => (
                                  <option key={item.id} value={item.difficulty}>
                                    {item.difficulty}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Difficulty Level*</label>
                            </div>
                          </div>
                          <div className="col-4 ps-2">
                            {/* <div className="form-floating ">
                              <Select
                                style={{ paddingTop: 10 }}
                                options={testDuration.map((i) => ({
                                  value: i,
                                  label: i + "  mins",
                                }))}
                                searchBy="title"
                                className="assessment-interview"
                                name="vettingMCQ"
                                placeholder="Duration in mins*"
                                searchable={true}
                                noDataLabel="No matches found"
                                // onChange={handleVettingAssessmentChange}
                                onChange={(e) => setDuration(e?.target?.value)}
                              />
                            </div> */}
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={duration}
                                onChange={(e) => {
                                  setDuration(e?.target?.value);
                                }}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {testDuration.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.duration}
                                    label={`${item.duration} mins`}
                                  >
                                    {item.duration}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Duration*</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {interviewType === "OneWay" &&
                  assessmentPlatform === "vettingPlatform" && (
                    <div>
                      <div className="card p-2">
                        <div className="d-flex">
                          <div className="col-4 ">
                            {/* <div className="form-floating ">
                              <Select
                                style={{ paddingTop: 10 }}
                                options={getUnique(vettingOneWay).map((i) => ({
                                  value: i,
                                  label: i,
                                }))}
                                searchBy="title"
                                className="assessment-interview"
                                name="vettingVideo"
                                placeholder="Select Skill *"
                                searchable={true}
                                noDataLabel="No matches found"
                                onChange={handleVettingAssessmentChange}
                              />
                            </div> */}

                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={vetSelectedSkill}
                                onChange={handleVettingAssessmentChange}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {getUnique(vettingOneWay).map((item) => (
                                  <option key={item?.id} value={item?.id}>
                                    {item?.testName}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Select Assessment*</label>
                            </div>
                          </div>
                          <div className="col-4 ps-2">
                            {/* <div className="form-floating ">
                              <Select
                                style={{ paddingTop: 10 }}
                                options={testDifficultyV.map((i) => ({
                                  value: i,
                                  label: i,
                                }))}
                                searchBy="title"
                                className="assessment-interview"
                                name="vettingVideo"
                                placeholder="Difficulty Level *"
                                searchable={true}
                                noDataLabel="No matches found"
                                onChange={(e) => {
                                  setVettingDifficulty(e[0]?.value);
                                }}
                              />

                            </div> */}
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={vettingDifficulty}
                                onChange={(e) => {
                                  setVettingDifficulty(e?.target?.value);
                                }}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {testDifficultyV.map((item) => (
                                  <option key={item.id} value={item.difficulty}>
                                    {item.difficulty}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Difficulty Level*</label>
                            </div>
                          </div>
                          <div className="col-4 ps-2">
                            {/* <div className="form-floating ">
                              <Select
                                style={{ paddingTop: 10 }}
                                options={testDurationV.map((i) => ({
                                  value: i,
                                  label: i + "  mins",
                                }))}
                                searchBy="title"
                                className="assessment-interview"
                                name="vettingVideo"
                                placeholder="Duration in mins *"
                                searchable={true}
                                noDataLabel="No matches found"
                                onChange={(e) => {
                                  setDuration(e[0]?.value);
                                }}
                              />
                             
                            </div> */}
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={duration}
                                onChange={(e) => {
                                  setDuration(e?.target?.value);
                                }}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {testDurationV.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.duration}
                                    label={`${item.duration} mins`}
                                  >
                                    {item.duration}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Duration*</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p style={{ color: "red" }}>{notification}</p>
                      <div className="form-floating col-5">
                        <p className="ps-1" style={{ paddingTop: 25 }}>
                          <strong>Select Reviewer</strong>
                        </p>
                      </div>
                      <hr />
                      <div className="card p-2">
                        <div className="d-flex">
                          {
                            <div className="col-12 ">
                              <div className="form-floating   ">
                                <select
                                  className="font-14 form-select"
                                  id="category"
                                  value={reviewerPresent}
                                  onChange={(e) => {
                                    //setReviewerPresent(e.target.value);
                                    handleReviewerChange(e);
                                  }}
                                >
                                  <option value={false}>
                                    I will be the reviewer.
                                  </option>
                                  <option value={true}>
                                    I suggest a colleague to review.
                                  </option>
                                </select>
                              </div>
                            </div>
                          }{" "}
                        </div>
                      </div>

                      {reviewerPresent === "true" && (
                        <>
                          <div className="form-floating col-5">
                            {/* <p className="ps-1" style={{ paddingTop: 25 }}>
                          <strong>Reviewer</strong>
                        </p> */}
                          </div>
                          <hr />{" "}
                          <div className="card p-2">
                            <div className="d-flex justify-content-between mb-2">
                              <div className="form-floating w-50 mr-2">
                                <input
                                  type="text"
                                  placeholder="test"
                                  className="form-control"
                                  value={reviewer.name}
                                  onChange={(e) => {
                                    setReviewer({
                                      ...reviewer,
                                      name: e.target.value.replace(
                                        /^[ \t]+|[ \t]+$/gm,

                                        ""
                                      ),
                                    });
                                  }}
                                />
                                <label htmlFor="">First Name*</label>
                              </div>
                              <div className="form-floating w-50">
                                <input
                                  type="text"
                                  placeholder="test"
                                  className="form-control"
                                  value={reviewer.reviewerEmail}
                                  onChange={(e) => {
                                    setReviewer({
                                      ...reviewer,
                                      reviewerEmail: e.target.value.replace(
                                        /^[ \t]+|[ \t]+$/gm,

                                        ""
                                      ),
                                    });
                                  }}
                                />
                                <label htmlFor="">Email*</label>
                              </div>
                            </div>
                            <div className="form-floating">
                              <textarea
                                type="text"
                                rows="3"
                                placeholder="test"
                                className="form-control"
                                // value=""
                                onChange={(e) => {
                                  setReviewer({
                                    ...reviewer,
                                    instructions: e.target.value,
                                  });
                                }}
                              />
                              <label htmlFor="">Instructions to reviewer</label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}

                {interviewType === "General" &&
                  assessmentPlatform === "vettingPlatform" && (
                    <div>
                      <div className="card p-2">
                        <div className="d-flex">
                          <div className="col-4 ">
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={vetSelectedSkill}
                                onChange={handleVettingAssessmentChange}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {getUnique(vettingGeneral).map((item) => (
                                  <option key={item?.id} value={item?.id}>
                                    {item?.testName}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Select Assessment*</label>
                            </div>
                          </div>
                          <div className="col-4 ps-2">
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={vettingDifficulty}
                                onChange={(e) => {
                                  setVettingDifficulty(e?.target?.value);
                                }}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {testDifficultyG.map((item) => (
                                  <option key={item.id} value={item.difficulty}>
                                    {item.difficulty}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Difficulty Level*</label>
                            </div>
                          </div>
                          <div className="col-4 ps-2">
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={duration}
                                onChange={(e) => {
                                  setDuration(e?.target?.value);
                                }}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {testDurationG.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.duration}
                                    label={`${item.duration} mins`}
                                  >
                                    {item.duration}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Duration*</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p style={{ color: "red" }}>{notification}</p>
                      <div className="form-floating col-5">
                        <p className="ps-1" style={{ paddingTop: 25 }}>
                          <strong>Select Reviewer</strong>
                        </p>
                      </div>
                      <hr />
                      <div className="card p-2">
                        <div className="d-flex">
                          {
                            <div className="col-12">
                              <div className="form-floating">
                                <select
                                  className="font-14 form-select"
                                  id="category"
                                  value={reviewerPresent}
                                  onChange={(e) => {
                                    handleReviewerChange(e);
                                  }}
                                >
                                  <option value={false}>
                                    I will be the reviewer.
                                  </option>
                                  <option value={true}>
                                    I suggest a colleague to review.
                                  </option>
                                </select>
                              </div>
                            </div>
                          }
                        </div>
                      </div>

                      {reviewerPresent === "true" && (
                        <>
                          <div className="form-floating col-5">
                            {/* <p className="ps-1" style={{ paddingTop: 25 }}>
                          <strong>Reviewer</strong>
                        </p> */}
                          </div>
                          <hr />{" "}
                          <div className="card p-2">
                            <div className="d-flex justify-content-between mb-2">
                              <div className="form-floating w-50 mr-2">
                                <input
                                  type="text"
                                  placeholder="test"
                                  className="form-control"
                                  value={reviewer.name}
                                  onChange={(e) => {
                                    setReviewer({
                                      ...reviewer,
                                      name: e.target.value.replace(
                                        /^[ \t]+|[ \t]+$/gm,

                                        ""
                                      ),
                                    });
                                  }}
                                />
                                <label htmlFor="">First Name*</label>
                              </div>
                              <div className="form-floating w-50">
                                <input
                                  type="text"
                                  placeholder="test"
                                  className="form-control"
                                  value={reviewer.reviewerEmail}
                                  onChange={(e) => {
                                    setReviewer({
                                      ...reviewer,
                                      reviewerEmail: e.target.value.replace(
                                        /^[ \t]+|[ \t]+$/gm,

                                        ""
                                      ),
                                    });
                                  }}
                                />
                                <label htmlFor="">Email*</label>
                              </div>
                            </div>
                            <div className="form-floating">
                              <textarea
                                type="text"
                                rows="3"
                                placeholder="test"
                                className="form-control"
                                // value=""
                                onChange={(e) => {
                                  setReviewer({
                                    ...reviewer,
                                    instructions: e.target.value,
                                  });
                                }}
                              />
                              <label htmlFor="">Instructions to reviewer</label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
              </div>

              {interviewType !== "F2F" &&
                interviewType !== "Telephone" &&
                interviewType !== "none" && (
                  <div className="row mt-3">
                    {interviewType === "MCQ" &&
                      assessmentPlatform === "modernHire" && (
                        <div className="col-lg-12 mb-2 mb-lg-0">
                          <div className="form-floating">
                            <Select
                              options={modernHireMCQ}
                              className="assessment-interview"
                              name="modernHireMCQ"
                              placeholder="Select Skill *"
                              searchBy="Title"
                              labelField="Title"
                              //  searchable={true}
                              valueField="RequisitionNumber"
                              noDataLabel="No matches found"
                              onChange={handleAssessmentChange}
                              id="modernHireMCQ"
                            />
                          </div>
                        </div>
                      )}

                    {interviewType === "1Way" &&
                      assessmentPlatform === "modernHire" && (
                        <div className="col-lg-12 mb-2 mb-lg-0">
                          <div className="form-floating">
                            <Select
                              options={modernHireOneWay}
                              className="assessment-interview"
                              name="modernHireOneWay"
                              placeholder="Select Skill *"
                              searchBy="Title"
                              labelField="Title"
                              searchable={true}
                              valueField="RequisitionNumber"
                              noDataLabel="No matches found"
                              onChange={handleAssessmentChange}
                              id="modernHireOneWay"
                            />
                          </div>
                        </div>
                      )}

                    {interviewType === "2Way" &&
                      assessmentPlatform === "modernHire" && (
                        <div className="col-lg-12 mb-2 mb-lg-0">
                          <div className="form-floating">
                            <Select
                              options={modernHireTwoWay}
                              className="assessment-interview"
                              name="modernHireTwoWay"
                              placeholder="Select Skill *"
                              searchBy="Title"
                              labelField="Title"
                              searchable={true}
                              valueField="RequisitionNumber"
                              noDataLabel="No matches found"
                              onChange={handleAssessmentChange}
                              id="modernHireTwoWay"
                            />
                          </div>
                        </div>
                      )}
                    {/* assessmentPlatform === "monjin" && */}
                    {assessmentPlatform === "monjin" &&
                      interviewType === "1Way" && (
                        <div className="col-lg-6 mb-2 mb-lg-0">
                          <div className="form-floating">
                            <Select
                              options={monjinOnewayData}
                              className="assessment-interview"
                              name="monjinOnewayData"
                              placeholder="Select Interview"
                              searchBy="title"
                              labelField="title"
                              searchable={true}
                              valueField="monjinJobId"
                              noDataLabel="No matches found"
                              onChange={handleAssessmentChange}
                              id="monjinOnewayData"
                            />
                          </div>
                        </div>
                      )}
                    {/* assessmentPlatform === "monjin" && */}
                    {assessmentPlatform === "monjin" &&
                      interviewType === "2Way" && (
                        <div className="col-lg-6 mb-2 mb-lg-0">
                          <div className="form-floating">
                            <Select
                              options={monjinTwowayData}
                              className="assessment-interview"
                              name="monjinTwowayData"
                              placeholder="Select Interview"
                              searchBy="title"
                              labelField="title"
                              searchable={true}
                              valueField="monjinJobId"
                              noDataLabel="No matches found"
                              onChange={handleAssessmentChange}
                              id="monjinTwowayData"
                            />
                          </div>
                        </div>
                      )}
                    {/* && assessmentPlatform === "tovuti" */}
                    {assessmentPlatform === "tovuti" &&
                      interviewType === "MCQ" && (
                        <div className="col-lg-6 mb-2 mb-lg-0">
                          <div className="form-floating">
                            <Select
                              options={tovutiData}
                              searchBy="Title"
                              className="assessment-interview"
                              name="course"
                              placeholder="Select Interview"
                              labelField="Title"
                              searchable={true}
                              valueField="id"
                              noDataLabel="No matches found"
                              onChange={handleAssessmentChange}
                              id="tovutiData"
                            />
                          </div>
                        </div>
                      )}
                  </div>
                )}

              {/* CodeSignal assessment selection*/}
              {interviewType === "CodeSignal" && (
                <div className="col-lg-12 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <Select
                      options={data.companyTests.tests}
                      searchBy="title"
                      className="assessment-interview"
                      name="course"
                      placeholder="Select Skill *"
                      labelField="title"
                      searchable={true}
                      valueField="id"
                      noDataLabel="No matches found"
                      onChange={handleCodeSignalAssessmentChange}
                      id="CodeSignalData"
                    />
                  </div>
                </div>
              )}

              {interviewType === "2Way" && (
                <div className="form-floating mt-3">
                  <select
                    name="interviewer"
                    className="form-select"
                    id="floatingSelectinterviewer"
                    aria-label="Floating label select example"
                    value={interviewer}
                    onChange={handleChange}
                  >
                    {/* <option selected disabled value="-1">Select The Interviewer</option> */}
                    <option selected value="none">
                      Select The Interviewer
                    </option>
                    {/* <option value="expert">A High5 Expert</option> */}
                    <option value="self">
                      I will be doing the interviewing
                    </option>
                    <option value="suggested">
                      I would like to suggest a colleague
                    </option>
                    {/* <option value="group">This is a group interview</option> */}
                  </select>
                  <label for="floatingSelect">Who is the interviewer?</label>
                  <small className="validation">{errors.interviewerType}</small>
                </div>
              )}

              {interviewType &&
              interviewer === "expert" &&
              interviewer !== "Select The Interviewer" ? (
                <p v-if="shortListForm.isExpert" className="mt-1">
                  <strong>Vendor A</strong> is set as your preferred High5
                  Expert.
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={changeFn}
                  >
                    Change This
                  </button>
                  {/* <button type="button" v-on:click="toggleExpertVettedOptions(true)" className="btn btn-link">Change This</button> */}
                </p>
              ) : null}
              {interviewType && change && interviewer === "expert" ? (
                <div
                  v-if="shortListForm.showExpertVendorOptions"
                  className="form-floating mt-3"
                >
                  <select
                    className="form-select"
                    id="expert"
                    aria-label="Floating label select example"
                  >
                    <option selected value="A">
                      Vendor A
                    </option>
                    <option value="B">Vendor B</option>
                    <option value="C">Vendor C</option>
                  </select>
                  <label for="floatingSelect">Select Expert Vendor</label>
                </div>
              ) : null}
            </div>
          </div>
          {(interviewType !== "MCQ" &&
            interviewType !== "1Way" &&
            interviewer !== "self" &&
            interviewer === "suggested" &&
            interviewType === "2Way" &&
            (assessmentPlatform === "modernHire" ||
              assessmentPlatform === "monjin")) ||
          interviewType === "F2F" ||
          interviewType === "Telephone" ? (
            <div className="card card-flat card-borderless ">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="firstName"
                        id="interviewerfirstName"
                        placeholder="Enter a job title"
                        className="form-control"
                        onChange={handletwoWayChange}
                      />
                      <label>First Name*</label>
                    </div>
                    <small className="validation">
                      {errors.interviewerfirstName}
                    </small>
                  </div>
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="lastName"
                        id="interviewerlastName"
                        placeholder="Enter  last Name"
                        className="form-control"
                        onChange={handletwoWayChange}
                      />
                      <label>Last Name*</label>
                    </div>
                    <small className="validation">
                      {errors.interviewerlastName}
                    </small>
                  </div>
                </div>
                <div className="form-floating mt-3">
                  <input
                    type="email"
                    name="intervieweremail"
                    id="intervieweremail"
                    placeholder="Enter a job title"
                    className="form-control"
                    onChange={handletwoWayChange}
                  />
                  <label>Enter your colleagues email*</label>
                </div>
                <small className="validation">{errors.intervieweremail}</small>
              </div>
            </div>
          ) : null}

          {interviewer !== "self" &&
          interviewer === "suggested" &&
          interviewType === "Telephone" &&
          interviewType !== "F2F" &&
          interviewType !== "MCQ" &&
          interviewType !== "1Way" &&
          interviewType !== "2Way" ? (
            <div className="card card-flat card-borderless ">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Enter a job title"
                        className="form-control"
                        onChange={handleChange}
                      />
                      <label> Phone number </label>
                    </div>
                    <small className="validation"></small>
                  </div>
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="voiceCall"
                        placeholder="Enter a job title"
                        className="form-control"
                        onChange={handleChange}
                      />
                      <label>Voice Call Link</label>
                    </div>
                    <small className="validation"></small>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {interviewer !== "self" &&
          interviewer === "suggested" &&
          interviewType === "F2F" &&
          interviewType !== "MCQ" &&
          interviewType !== "1Way" &&
          interviewType !== "2Way" &&
          interviewType !== "Telephone" ? (
            <div className="card card-flat card-borderless ">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Enter a job title"
                        className="form-control"
                        onChange={handleChange}
                      />
                      <label> Phone number </label>
                    </div>
                    <small className="validation"></small>
                  </div>
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="address"
                        placeholder="Enter a job title"
                        className="form-control"
                        onChange={handleChange}
                      />
                      <label>Address</label>
                    </div>
                    <small className="validation"></small>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {interviewer === "group" ? (
            <div className="card card-flat card-borderless ">
              <div className="card-body">
                <div className="form-check form-switch d-flex align-items-center mt-5">
                  {checkedFn === true ? (
                    <input
                      className="form-check-input"
                      name="isHMAttending"
                      type="checkbox"
                      id="hmattend"
                      onChange={handleChange}
                      checked
                    />
                  ) : (
                    <input
                      className="form-check-input"
                      name="isHMAttending"
                      type="checkbox"
                      id="hmattend"
                      onChange={handleChange}
                    />
                  )}

                  <label className="form-check-label pt-0 ml-3" for="hmattend">
                    Will you be attending?
                    {checkedFn === true ? (
                      <span v-if="isHMAttending" className="tag tag-green2">
                        YES
                      </span>
                    ) : (
                      <span v-else className="tag">
                        NO
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
          ) : null}

          {(interviewer === "self" || interviewer === "suggested") &&
          assessmentPlatform === "monjin" &&
          interviewType === "2Way" ? (
            <div className="card card-flat card-borderless  mt-3">
              <div className="card-body">
                <p>
                  Please update available timeslot for this interview based on
                  your current availability
                </p>
                <div className="row mb-2">
                  <div className="col-lg-12">Select Timezone* </div>
                  <div className="col-lg-12 mb-2">
                    {/* <TimezoneSelect
                      id="selectedTimezone"
                      value={
                        props.interviewScheduleData.monjinTwowayData
                          .selectedTimezone
                      }
                      onChange={updatetwowayTimeZone}
                      labelStyle={"abbrev"}
                    /> */}
                    <small className="validation">
                      {errors.selectedTimezone}
                    </small>
                  </div>

                  <div className="col-lg-12 d-flex flex-column flex-md-row">
                    <div className="form-floating form-date w-60">
                      <span>Select date and time*</span>
                      <DatePicker
                        className="form-control"
                        showTimeSelect
                        dateFormat="MMMM d, yyyy h:mm aa"
                        selected={
                          props.interviewScheduleData.monjinTwowayData
                            .interviewdate
                        }
                        // onChange={(date) => setStartDate(date)}
                        onChange={(date) => {
                          updatetwowaySlot(date);
                        }}
                        placeholderText="Choose  date and time"
                      />
                      <small className="validation">
                        {errors.interviewdate}
                      </small>
                    </div>
                    <div className=" d-flex">
                      <div className=" w-2 ml-2">
                        <span>Select Duration*</span>
                        <select
                          className="form-control w-100"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          onChange={updatetwowayDuration}
                        >
                          <option value="select">Select Duration</option>
                          <option value="15">15 Min</option>
                          <option value="30">30 Min</option>
                          <option value="45">45 Min</option>
                          <option value="60">1 Hrs</option>
                          <option value="90">1.5 Hrs</option>
                          <option value="120">2 Hrs </option>
                        </select>
                        <small className="validation">
                          {errors.interviewduration}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row mb-2">
                                    <div className="col-lg-12 d-flex flex-column flex-md-row">
                                        <div className="form-floating form-date w-100">
                                            <label>Available Date Option 2*</label>{" "}
                                            <input
                                                type="date"
                                                placeholder="Enter Date"
                                                className="form-control date"
                                            />
                                        </div>
                                        <div className="w-100 d-flex">
                                            <div className="form-floating w-50">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Time"
                                                    className="form-control"
                                                />{" "}
                                                <label>Time</label>
                                            </div>
                                            <div className="form-floating w-50">
                                                <select
                                                    className="form-select"
                                                    id="floatingSelect"
                                                    aria-label="Floating label select example"
                                                >
                                                    <option selected value="AM">
                                                        AM
                                                    </option>
                                                    <option value="PM">PM</option>
                                                </select>
                                                <label for="floatingSelect">AM/PM</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-12 d-flex flex-column flex-md-row">
                                        <div className="form-floating form-date w-100">
                                            <label>Available Date Option 3*</label>{" "}
                                            <input
                                                type="date"
                                                placeholder="Enter Date"
                                                className="form-control date"
                                            />
                                        </div>
                                        <div className="w-100 d-flex">
                                            <div className="form-floating w-50">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Time"
                                                    className="form-control"
                                                />{" "}
                                                <label>Time</label>
                                            </div>
                                            <div className="form-floating w-50">
                                                <select
                                                    className="form-select"
                                                    id="floatingSelect"
                                                    aria-label="Floating label select example"
                                                >
                                                    <option selected value="AM">
                                                        AM
                                                    </option>
                                                    <option value="PM">PM</option>
                                                </select>
                                                <label for="floatingSelect">AM/PM</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-12 d-flex flex-column flex-md-row">
                                        <div className="form-floating form-date w-100">
                                            <label>Available Date Option 4*</label>{" "}
                                            <input
                                                type="date"
                                                placeholder="Enter Date"
                                                className="form-control date"
                                            />
                                        </div>
                                        <div className="w-100 d-flex">
                                            <div className="form-floating w-50">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Time"
                                                    className="form-control"
                                                />{" "}
                                                <label>Time</label>
                                            </div>
                                            <div className="form-floating w-50">
                                                <select
                                                    className="form-select"
                                                    id="floatingSelect"
                                                    aria-label="Floating label select example"
                                                >
                                                    <option selected value="AM">
                                                        AM
                                                    </option>
                                                    <option value="PM">PM</option>
                                                </select>
                                                <label for="floatingSelect">AM/PM</label>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          ) : null}
          {interviewer === "group" && checkedFn === false ? (
            <>
              <div
                v-if="isHMAttending == false && shortListForm.isGroup == true"
                className="card card card-flat card-borderless  mt-3"
              >
                <div className="card-body">
                  <div className="form-floating">
                    <input
                      type="text"
                      placeholder="Enter POC"
                      className="form-control"
                    />
                    <label>
                      Enter The Email for the point of contact for this
                      interview?
                    </label>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {interviewer === "group" ? (
            <div
              v-if="shortListForm.isGroup"
              className="card card-flat card-borderless "
            >
              <div className="card-body">
                <div className="form-floating">
                  <div className="form-group">
                    <label className="mb-2">
                      Enter the Emails of the <strong>required</strong>{" "}
                      attendes*
                    </label>
                    <input
                      type="text"
                      className="inputEditRequest"
                      placeholder="Enter emails and hit enter"
                    />
                    <vueemailsinput placeholder="Enter emails and hit enter"></vueemailsinput>
                  </div>
                </div>
                <div className="form-floating">
                  <div className="form-group">
                    <label className="mb-2">
                      Enter the Emails of <strong>optional</strong> attendees
                    </label>
                    <input
                      type="text"
                      className="inputEditRequest"
                      placeholder="Enter emails and hit enter"
                    />
                    {/* <vueemailsinput placeholder="Enter emails and hit enter"></vueemailsinput> */}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div v-if="!formComplete" className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            data-dismiss="modal"
            onClick={close}
          >
            Cancel
          </button>
          {interviewer !== "-1" && disableSubmit === false ? (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(e) => onSubmitAssignAssessment(e)}
              disabled={
                vettingDifficulty === "" || duration === ""
                  ? true
                  : reviewer.name !== "" && reviewer.reviewerEmail !== ""
                  ? false
                  : true
              }
            >
              {(props.parent === "Submitted" ||
                props.parent === "Disqualified") &&
                "Assign & ShortList"}
              {props.parent === "Shortlist" && "Assign"}
            </button>
          ) : (
            <button
              type="button"
              disabled="disableSubmit"
              className="btn btn-primary btn-sm"
            >
              Assign
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default ScheduleInterview;
