import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import OutsideClickHandler from "react-outside-click-handler";
import TopNavBar from "../../layout/TopNavBar";
import SideNav from "../../layout/SideNavBar";
import SourceCandidatePopup from "./SourceCandidatePopUp";
import SourceCandidateBulkPopUp from "./SourceCandidateBulkPopUp";
import { post, get } from "../../../service/ApiService";
import { domain, High5DarkIcon } from "../../../constants/Constants";
import Loader from "../../Loader/Loader";
import FormSubmitSuccess from "../../modal/result/FormSubmitSuccess";
import ShareViaEmail from "../../common/Jobs/ViewJobDetails/ShareViaEmail";
import PublicShareLink from "../../common/Jobs/ViewJobDetails/PublicShareLink";
import DeleteRequest from "../../common/Jobs/ViewJobDetails/DeleteRequest";
import {
  sourceTalentSuccess,
  sourceTalentBulkSuccess,
} from "./FormSuccessHtml";
import CandidateSideDetails from "../../common/candidate/TalentSideDetails";
import moment from "moment";
import { toast } from "react-toastify";
import SubmitCandidateGridView from "./SubmitCandidateGridView";
import SubmitCandidateListView from "./SubmitCandidateListView";
import SubmitCandidateTableView from "./SubmitCandidateTableView";
import Filter from "../../hiringmanager/requeststabs/Filter";
import Sort from "../../common/Sort";
import SubmitTalentSidePanel from "./SubmitTalentSidePanel";
import Avatar from "react-avatar";
import SubmitTalentModal from "./SubmitTalentModal";
import AutoMatchScoreCard from "../../common/Jobs/ViewTalentDetails/AutoMatchScoreCard";
import InviteTalentFromAutomatched from "../../common/Jobs/ViewJobDetails/InviteTalentFromAutomatched";
import InviteCandidate from "./InviteCandidate";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

function SubmitCandidate(props) {
  const [checkedFn, setcheckedFn] = useState(false);
  const [profile, setProfile] = useState(true);
  const [candidateData, setCandidateData] = useState([]);
  const [details, setDetails] = useState(false);
  const [activity, setActivity] = useState(false);
  const [editButton, setedit] = useState(false);
  const [shareButton, setshareButton] = useState(false);
  const [PublicShareLinkValue, setPublicShareLinkButton] = useState(false);
  const [DeleteRequestValue, setDeleteRequestButton] = useState(false);
  const [sourceTalentValue, setSourceTalentButton] = useState(false);
  const [sourceTalentBulkValue, setSourceTalentBulkButton] = useState(false);
  const [listview, setlistview] = useState(true);
  const [gridview, setgridview] = useState(false);
  const [tableview, settableview] = useState(false);
  const [toggle, setToggle] = useState(true);
  const [sorting, setAddsorting] = useState(false);
  let [count, setcount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [candidateList, setCandidateList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [totalCandidate, setTotalCandidate] = useState(0);
  const [currencyList, setCurrencyList] = useState([]);
  const [souceCandidateData, setSouceCandidateData] = useState({});
  const [jobData, setJobData] = useState({
    fullText: {},
  });
  const [jobListData, setJobListData] = useState([]);
  const [fullText, setFullText] = useState({
    location: {},
  });
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [successFormBulkOpen, setSuccessFormBulkOpen] = useState(false);
  const [successData, setSuccessData] = useState({
    title: "Talent Submitted",
    subTitle: "",
    htmlTag: "",
  });
  const [tabStatus, setTabStatus] = useState({
    Profile: true,
    Assessments: false,
  });
  const [filter, setFilter] = useState(false);
  const [filterBy, setFilterBy] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [requestTitle, setRequestTitle] = useState([]);
  const [requestType, setRequestType] = useState([]);
  const [daysLeft, setDaysLeft] = useState("");
  const [daysLeftValue, setDaysLeftValue] = useState("");
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  const [requests, setRequests] = useState([]);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [sidePanel, setSidePanel] = useState(false);
  const [jobDetails, setJobDetails] = useState({});
  const [submitModal, setSubmitModal] = useState(false);
  const [score, setScore] = useState(false);
  const [scoreTalentDetails, setScoreTalentDetails] = useState({});
  const [scoreClicked, setScoreClicked] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );

  const sortObject = [
    {
      id: 1,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Type",
      value: "jobType",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Location",
      value: "location.city",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  useEffect(() => {
    window
      .matchMedia("(max-width: 1200px)")
      .addEventListener("change", (e) => setMatches(e.matches));
    fetchCandidateData();
  }, []);

  const calculateExperience = [
    { name: "Intern", range: [0] },
    { name: "1-3 years", range: [1, 2] },
    { name: "3-5 years", range: [3, 4] },
    { name: "5-10 years", range: [5, 6, 7, 8, 9] },
    { name: "10+ years", range: [] },
  ];

  const findRange = (years, months) => {
    let totalMonths = Number(years) * 12 + Number(months);
    if (totalMonths >= 120) {
      return "10+ years";
    } else if (totalMonths < 12) {
      if (totalMonths === 0) {
        return "Intern";
      } else {
        return "Less than one year";
      }
    } else {
      let yearsExperience = Math.floor(totalMonths / 12);
      let finalValue = calculateExperience.find((i) =>
        i.range.includes(yearsExperience)
      ).name;
      return finalValue;
    }
  };
  const checkEligiblity = (job, candidate) => {
    if (job.weightage === null) return true;
    const weightage =
      typeof job.weightage === "string"
        ? JSON.parse(job.weightage)
        : job.weightage;
    let user = { ...candidate };
    const {
      primarySkills,
      secondarySkills,
      jobTitle,
      education,
      experienceLevel,
      industries,
      location,
    } = weightage;
    let condition = true;
    let unmatched = {
      primarySkills: false,
      secondarySkills: false,
      jobTitle: false,
      education: false,
      experienceLevel: false,
      industries: false,
      location: false,
    };

    if (primarySkills && primarySkills.length > 0) {
      if (!primarySkills.every((item) => user.primarySkills.includes(item))) {
        condition = false;
        unmatched = { ...unmatched, primarySkills: true };
      }
    }

    if (secondarySkills && secondarySkills.length > 0) {
      if (
        !secondarySkills.every((item) => user.secondarySkills.includes(item))
      ) {
        condition = false;
        unmatched = { ...unmatched, secondarySkills: true };
      }
    }

    if (industries && industries.length > 0) {
      if (!industries.every((item) => user.skillSet.includes(item))) {
        condition = false;
        unmatched = { ...unmatched, industries: true };
      }
    }

    if (education && education.length > 0) {
      if (
        !user.education
          .map((item) => item.educationType.toLowerCase())
          .includes(education[0].toLowerCase())
      ) {
        condition = false;
        unmatched = { ...unmatched, education: true };
      }
    }

    if (location) {
      if (job.isRemote) {
        if (!user.preferedLocations.includes("Remote")) {
          condition = false;
          unmatched = { ...unmatched, location: true };
        }
      } else {
        if (
          ![user.addressCity, ...user.preferedLocations]
            .map((i) => i.toLowerCase())
            .includes(job.location.city.toLowerCase())
        ) {
          condition = false;
          unmatched = { ...unmatched, location: true };
        }
      }
    }

    if (jobTitle) {
      if (
        user.currentJobTitle?.trim().toLowerCase() !==
        job.jobTitle.trim().toLowerCase()
      ) {
        condition = false;
        unmatched = { ...unmatched, jobTitle: true };
      }
    }

    if (experienceLevel) {
      if (
        findRange(user.experienceYear, user.experienceMonth) !==
        job.experienceLevel
      ) {
        condition = false;
        unmatched = { ...unmatched, experienceLevel: true };
      }
    }

    return condition;
  };

  // Fetch currency dropdown
  const fetchJobData = async (jobIds, candidate) => {
    setLoading(true);
    const params = {
      skip: 0,
      domain: domain,
    };
    try {
      const res = await post(`/requestlist/getrequestslist`, params);

      let finalResult = res.data.jobData
        .filter((i) => {
          return (
            !jobIds.includes(i.jobId) &&
            i.jobStatus !== "Deactivated" &&
            checkEligiblity(i, candidate)
          );
        })
        .map((i) => ({
          ...i,
          vettingDetails: i.vettingDetails
            ? JSON.parse(i.vettingDetails)
            : i.vettingDetails,
        }));
      console.log(finalResult);
      let requestTitles = {};
      let uniqueTitles = finalResult.map((i) => i.fullText.jobTitle);
      uniqueTitles.forEach((i) => (requestTitles[i] = false));
      setRequestTitle(requestTitles);
      let requestTypes = {};
      let uniqueTypes = finalResult.map((i) => i.jobType);
      uniqueTypes.forEach((i) => (requestTypes[i] = false));
      setRequestType(requestTypes);
      setJobListData(finalResult);
      setFilterBy(finalResult);
      setFilterData(finalResult);
      setLoading(false);
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchCandidateData = async () => {
    setLoading(true);
    const candidateId = props.match.params.Id;
    const payload = {
      domain,
      candidateId,
    };
    let result = await post("/talentdata/searchtalentbyid", payload);
    // console.log(result.data)
    setCandidateData(result.data);
    getRequests(result.data);
    setLoading(false);
  };

  const getRequests = async (candidate) => {
    setLoading(true);
    const res = await post("/talentrequests", {
      candidateId: props.match.params.Id,
    });
    fetchJobData([...new Set(res.data.value?.map((i) => i.jobId))], candidate);
    setLoading(false);
  };

  const applyFilter = (filteredData) => {
    sortClearAll();
    setFilter(false);
    setFilterBy(filteredData);
    setFilterData(filteredData);
  };

  const applySort = (sortedData, close) => {
    close && setIsComponentVisible(false);
    setFilterBy(sortedData);
    setFilterData(sortedData);
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(jobListData, true);
  };

  const updateTabTalent = (val) => {
    const tempTabStatus = { ...tabStatus };
    Object.keys(tempTabStatus).forEach((key) => {
      if (key === val) {
        tempTabStatus[key] = true;
      } else {
        tempTabStatus[key] = false;
      }
    });
    setTabStatus(tempTabStatus);
  };

  // Tab change
  const updateTab = (type) => {
    if (type === "listview") {
      setlistview(true);
      setgridview(false);
      settableview(false);
    }
    if (type === "gridview") {
      setgridview(true);
      setlistview(false);
      settableview(false);
    }
    if (type === "tableview") {
      settableview(true);
      setlistview(false);
      setgridview(false);
    }

    if (type === "profile") {
      setProfile(true);
      setDetails(false);
      setActivity(false);
    }
    if (type === "details") {
      setDetails(true);
      setProfile(false);
      setActivity(false);
    }
    if (type === "activity") {
      setActivity(true);
      setProfile(false);
      setDetails(false);
    }
  };

  // source talent bulk pop up show
  const SourceTalentBulk = () => {
    setSourceTalentBulkButton(true);
  };

  // source talent bulk pop up close
  const SourceTalentBulkClose = () => {
    setSuccessFormBulkOpen(false);
    setSourceTalentBulkButton(false);
    setcheckedFn(false);
  };

  // source talent single pop up close
  const SourceTalentClose = () => {
    setSuccessFormOpen(false);
    setSourceTalentButton(false);
    setcheckedFn(false);
  };

  const DeleteRequestFun = () => {
    setDeleteRequestButton(true);
  };

  const DeleteRequestClose = (e) => {
    setDeleteRequestButton(false);
  };

  const PublicShare = () => {
    setPublicShareLinkButton(true);
  };

  const PublicShareLinkClose = (e) => {
    setPublicShareLinkButton(false);
  };

  const setshare = () => {
    setshareButton(true);
  };

  const shareClose = (e) => {
    setshareButton(false);
  };

  const editRequest = () => {
    setedit(true);
  };

  const handleClose = (e) => {
    setedit(false);
  };

  // Navbar toggle
  const toggleDiv = () => {
    toggle ? setToggle(false) : setToggle(true);
  };

  // bulk pop up handle change
  const handleAllChange = (e) => {
    const jobTempList = jobListData.map((item) => {
      item.isSelect = e.target.checked;
      return item;
    });
    setJobListData(jobTempList);
    setFilterBy(jobTempList);
    setFilterData(jobTempList);
    setcheckedFn(e.target.checked);
    setSelectedList(jobTempList.filter((item) => item.isSelect === true));
  };

  // single pop up handle change
  const handleChange = (e, i) => {
    const jobTempList = [...jobListData];
    jobTempList[i].isSelect = e.target.checked;
    setJobListData(jobTempList);
    setFilterBy(jobTempList);
    setFilterData(jobTempList);
    setSelectedList(jobTempList.filter((item) => item.isSelect === true));
  };

  const addSorting = () => {
    setAddsorting(true);
    if (count !== 5) {
      count = count + 1;
      setcount(count);
    }
  };

  const clearAll = () => {
    setAddsorting(false);
    setcount(0);
  };

  const successForm = (payrateData) => {
    const htmlTag = sourceTalentSuccess(
      souceCandidateData,
      payrateData,
      jobData
    );
    const subTitle = `Your have successfully submitted <strong> ${souceCandidateData.fullText.firstName} ${souceCandidateData.fullText.middleName} ${souceCandidateData.fullText.lastName}</strong> for the position of <strong>${jobData.fullText.jobTitle}</strong>`;
    setSuccessData({ ...successData, htmlTag: htmlTag, subTitle: subTitle });
    setSuccessFormOpen(true);
    setcheckedFn(false);
  };

  const successFormBulk = (payrateData) => {
    const htmlTag = sourceTalentBulkSuccess(selectedList, payrateData, jobData);
    setSuccessData({ ...successData, htmlTag: htmlTag });
    setSuccessFormBulkOpen(true);
  };

  const closeModal = () => {
    setSuccessFormOpen(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <body
          className={
            toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
          }
        >
          <div className="body-decorator body-decorator-top"></div>
          <div className="body-decorator body-decorator-bottom"></div>
          <TopNavBar handleToggale={toggleDiv} />
          <SideNav />
          <div id="Content">
            <div className="hero">
              <div className="section pb-0">
                <div className="container-fluid">
                  <div className="row justify-content-center">
                    <div className="col-xl-12">
                      {!loading && (
                        <div className="text-center mb-5 font-white">
                          <Avatar
                            className="mb-1"
                            size="55"
                            name={
                              candidateData.firstName +
                              " " +
                              candidateData.lastName
                            }
                            round="90px"
                          />

                          <h4>
                            {candidateData.firstName} {candidateData.lastName}
                          </h4>
                          {/* <p>{ candidateData && (candidateData.experience[0].jobTitle === "" ? ("Not Updated") : (candidateData.experience[0].jobTitle))} {candidateData.address && `/${candidateData.address}`}</p> */}
                          {/* <p>{candidateData.experience.length}</p> */}
                          {(candidateData.MCQ ||
                            candidateData.onewayvetting ||
                            candidateData.twowayvetting ||
                            candidateData.codesignalvetting) && (
                            <p>
                              <span className="tag tag-lg tag-font_accent d-inline-flex">
                                <img width="16px" src={High5DarkIcon} />
                                <span className="mx-2">Vetted</span>
                              </span>
                            </p>
                          )}
                        </div>
                      )}
                      <div className="row">
                        <div className="col-xl-4">
                          <div className="hero-card-header card card-flat card-lg card-bottom-straight">
                            <div className="card-body pb-0">
                              <div className="d-flex align-items-start">
                                <div>
                                  <p className="mb-0">Profile Details</p>
                                  <p className="mb-3">
                                    {candidateData.updatedDate !== "" && (
                                      <small>
                                        Last updated:{" "}
                                        {moment(
                                          new Date(candidateData.updatedDate)
                                        ).format("MM/DD/YYYY")}
                                      </small>
                                    )}
                                  </p>
                                </div>
                              </div>
                              <hr className="m-0 hr-white" />
                              <ul className="nav nav-tabs pt-1">
                                <li className="nav-item">
                                  <button
                                    type="button"
                                    className={
                                      tabStatus.Profile
                                        ? "nav-link pb-3 active"
                                        : "nav-link pb-3"
                                    }
                                    onClick={() => {
                                      updateTabTalent("Profile");
                                    }}
                                  >
                                    Profile
                                  </button>
                                </li>
                                <li className="nav-item">
                                  <button
                                    type="button"
                                    className={
                                      tabStatus.Assessments
                                        ? "nav-link pb-3 active"
                                        : "nav-link pb-3"
                                    }
                                    onClick={() => {
                                      updateTabTalent("Assessments");
                                    }}
                                  >
                                    Assessments
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-8 d-none-1200">
                          <div className="hero-card-header card card-lg card-flat card-bottom-straight">
                            <div className="card-body pb-0 d-flex flex-column justify-content-between">
                              <div>
                                <p className="px-1">
                                  Jobs (
                                  {
                                    filterData.filter((i) => {
                                      return !requests.includes(i.jobId);
                                    }).length
                                  }
                                  )
                                </p>
                                <small>
                                  Please note: The search result displays jobs
                                  matching to the submission parameter, rest
                                  will be ignored.
                                </small>
                              </div>
                              <div className="d-flex justify-content-between mt-3">
                                <div className="mr-4 nav nav-tabs">
                                  <div className="nav-item d-flex justify-content-center">
                                    <button
                                      onClick={() => {
                                        updateTab("listview");
                                      }}
                                      type="button"
                                      className={
                                        listview
                                          ? "nav-link pb-3 active"
                                          : "nav-link pb-3 bg-transparent"
                                      }
                                    >
                                      <i className="fas fa-list"></i>
                                    </button>
                                  </div>
                                  <div className="nav-item d-flex justify-content-center">
                                    <button
                                      onClick={() => {
                                        updateTab("gridview");
                                      }}
                                      type="button"
                                      className={
                                        gridview
                                          ? "nav-link pb-3 bg-transparent active"
                                          : "nav-link pb-3 bg-transparent"
                                      }
                                    >
                                      <i className="fas fa-th-large"></i>
                                    </button>
                                  </div>
                                  <div className="nav-item d-flex justify-content-center">
                                    <button
                                      onClick={() => {
                                        updateTab("tableview");
                                      }}
                                      type="button"
                                      className={
                                        tableview
                                          ? "nav-link pb-3 bg-transparent active"
                                          : "nav-link pb-3 bg-transparent"
                                      }
                                    >
                                      <i className="fas fa-table"></i>
                                    </button>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <div className="mr-4 d-flex justify-content-center">
                                    <div className="d-flex justify-content-end w-100 justify-content-even-400">
                                      <div className="dropdown-sort dd-sort">
                                        <button
                                          type="button"
                                          className="btn btn-sm btn-text-accent"
                                          onClick={() =>
                                            setIsComponentVisible(true)
                                          }
                                        >
                                          <i className="fad fa-sort-down font-16 mr-1"></i>{" "}
                                          Sort / {sortCount}
                                        </button>
                                        {
                                          <div ref={ref}>
                                            {isComponentVisible && (
                                              <OutsideClickHandler
                                                onOutsideClick={() =>
                                                  setIsComponentVisible(false)
                                                }
                                              >
                                                <Sort
                                                  sortData={jobListData}
                                                  orderArray={orderList}
                                                  sortObjects={sortObject}
                                                  applySort={applySort}
                                                  setSortCount={setSortCount}
                                                  sortCount={sortCount}
                                                  setSortArray={setSortArray}
                                                  sortArray={sortArray}
                                                  sortingFor={
                                                    "Sort by Job Title, Type, and Location of Requests"
                                                  }
                                                />
                                              </OutsideClickHandler>
                                            )}
                                          </div>
                                        }
                                      </div>
                                      <div className="dropdown position-relative">
                                        <button
                                          data-bs-toggle="tooltip"
                                          data-bs-placement="left"
                                          title="Filter"
                                          onClick={() => setFilter(true)}
                                          type="button"
                                          className="btn btn-sm btn-text-accent w-auto"
                                        >
                                          <i className="fas fa-filter"></i>
                                          <span>Filter</span> /
                                          <span>
                                            {Object.entries(
                                              requestTitle
                                            ).filter((title) => title[1])
                                              .length +
                                              Object.entries(
                                                requestType
                                              ).filter((type) => type[1])
                                                .length +
                                              (daysLeftValue !== "" ? 1 : 0)}
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-5">
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-xl-12">
                    <div className="row">
                      {/* <RequestSideBar profile={profile} details={details} activity={activity} jobData={jobData} fullText={fullText} /> */}
                      {Object.keys(candidateData).length > 0 && (
                        <CandidateSideDetails
                          tabStatus={tabStatus}
                          candidateData={candidateData}
                        />
                      )}
                      <div className="col-xl-8">
                        <div className="card card-lg card-flat card-bottom-straight d-none d-block-1200 mt-3">
                          <div className="card-body">
                            <div className="d-flex align-items-center justify-content-between">
                              <p className="px-1 mb-0">
                                Job ({filterData.length})
                              </p>
                            </div>
                          </div>
                        </div>
                        <div
                          className="card card-flat card-lg card-top-straight"
                          style={{ "border-top": "none" }}
                        >
                          <div className="card-body">
                            {listview && !matches ? (
                              <SubmitCandidateListView
                                requestListData={filterData.filter((i) => {
                                  return !requests.includes(i.jobId);
                                })}
                                handleChange={handleChange}
                                openPanel={(details) => {
                                  setJobDetails(details);
                                  setSidePanel(true);
                                }}
                                openSubmitModal={(request) => {
                                  setJobDetails(request);
                                  setSubmitModal(true);
                                }}
                              />
                            ) : gridview || matches ? (
                              <SubmitCandidateGridView
                                requestListData={filterData.filter((i) => {
                                  return !requests.includes(i.jobId);
                                })}
                                handleChange={handleChange}
                                openPanel={(details) => {
                                  setJobDetails(details);
                                  setSidePanel(true);
                                }}
                                openSubmitModal={(request) => {
                                  setJobDetails(request);
                                  setSubmitModal(true);
                                }}
                              />
                            ) : tableview ? (
                              <SubmitCandidateTableView
                                requestListData={filterData.filter((i) => {
                                  return !requests.includes(i.jobId);
                                })}
                                handleChange={handleChange}
                                openPanel={(details) => {
                                  setJobDetails(details);
                                  setSidePanel(true);
                                }}
                                openSubmitModal={(request) => {
                                  setJobDetails(request);
                                  setSubmitModal(true);
                                }}
                              />
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Modal show={editButton === true} onHide={handleClose} size="edit">

                            <div className="bl-modal-header">
                                <h6 className="bl-modal-title">{'Edit Request'}</h6>
                                <button type="button" data-dismiss="modal" aria-label="Close" onClick={handleClose} className="close p-0 bl-modal-close-btn"  >
                                    <span aria-hidden="true">&times</span>
                                </button>
                            </div>
                            <Modal.Body>
                                <EditRequest handleSuccess={handleClose} />
                            </Modal.Body>

                        </Modal> */}
            <Modal show={shareButton === true} onHide={shareClose} size="mid">
              <div className="bl-modal-header">
                <h6 className="bl-modal-title">{"Share via Email"}</h6>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={shareClose}
                  className="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times</span>
                </button>
              </div>

              <Modal.Body>
                <ShareViaEmail handleSuccess={shareClose} />
              </Modal.Body>
            </Modal>

            <Modal
              show={PublicShareLinkValue === true}
              onHide={PublicShareLinkClose}
              size="delete"
            >
              <div className="bl-modal-header">
                <h6 className="bl-modal-title">{"Public Share Link"}</h6>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={PublicShareLinkClose}
                  className="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times</span>
                </button>
              </div>
              <Modal.Body>
                <PublicShareLink handleSuccess={PublicShareLinkClose} />
              </Modal.Body>
            </Modal>

            <Modal
              show={DeleteRequestValue === true}
              onHide={DeleteRequestClose}
              size="delete"
            >
              <div className="bl-modal-header">
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={DeleteRequestClose}
                  className="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times</span>
                </button>
              </div>
              <Modal.Body>
                <DeleteRequest handleSuccess={DeleteRequestClose} />
              </Modal.Body>
            </Modal>

            <Modal show={sourceTalentValue === true} onHide={SourceTalentClose}>
              <div className="modal-content" style={{ width: "650px" }}>
                <div className="bl-modal-header">
                  <h6 className="bl-modal-title">{"Source Talent"}</h6>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={SourceTalentClose}
                    className="close p-0 bl-modal-close-btn"
                  >
                    <span aria-hidden="true">&times</span>
                  </button>
                </div>
                <Modal.Body>
                  {successFormOpen ? (
                    <FormSubmitSuccess
                      successData={successData}
                      closeModal={SourceTalentClose}
                    />
                  ) : (
                    <SourceCandidatePopup
                      handleClose={SourceTalentClose}
                      checkedFn={checkedFn}
                      currencyList={currencyList}
                      candidate={souceCandidateData}
                      jobData={jobData}
                      successForm={successForm}
                      setLoading={setLoading}
                    />
                  )}
                </Modal.Body>
              </div>
            </Modal>

            <Modal
              show={sourceTalentBulkValue === true}
              onHide={SourceTalentBulkClose}
              size="delete"
            >
              <div className="modal-content" style={{ width: "650px" }}>
                <div className="bl-modal-header">
                  <h6 className="bl-modal-title">{"Source Talent"}</h6>
                  <button
                    type="button"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={SourceTalentBulkClose}
                    className="close p-0 bl-modal-close-btn"
                  >
                    <span aria-hidden="true">&times</span>
                  </button>
                </div>
                <Modal.Body>
                  {successFormBulkOpen ? (
                    <FormSubmitSuccess
                      successData={successData}
                      closeModal={SourceTalentBulkClose}
                    />
                  ) : (
                    <SourceCandidateBulkPopUp
                      handleClose={SourceTalentBulkClose}
                      candidateList={selectedList}
                      checkedFn={checkedFn}
                      currencyList={currencyList}
                      jobData={jobData}
                      successFormBulk={successFormBulk}
                      setLoading={setLoading}
                    />
                  )}
                </Modal.Body>
              </div>
            </Modal>

            {submitModal && (
              <>
                <InviteCandidate
                  candidateDetails={candidateData}
                  inviteTalent={submitModal}
                  setInviteTalent={setSubmitModal}
                  jobData={jobDetails}
                ></InviteCandidate>
              </>
            )}

            <Modal show={score} size="lg">
              <Modal.Header>
                <h6 className="">Automatch Score Card</h6>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setScore(false);
                    setSubmitModal(true);
                  }}
                  className="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </Modal.Header>
              <Modal.Body>
                <div className="p-3">
                  <AutoMatchScoreCard
                    jobData={jobDetails}
                    candidateData={candidateData}
                  />
                </div>
              </Modal.Body>
              <div className="d-flex justify-content-end m-2">
                <button
                  className="btn btn-secondary mr-1"
                  onClick={() => {
                    setScore(false);
                    setSubmitModal(true);
                  }}
                >
                  Close
                </button>
              </div>
            </Modal>
            {getTenantType() == 1 && <Footer />}
          </div>
        </body>
      )}
      {filter && (
        <Filter
          jobListData={jobListData}
          requestTitle={requestTitle}
          requestType={requestType}
          applyFilter={applyFilter}
          changeFilterByTitle={(title) => setRequestTitle(title)}
          changeFilterByType={(type) => setRequestType(type)}
          daysLeft={daysLeft}
          daysLeftValue={daysLeftValue}
          changeDaysLeft={(days) => setDaysLeft(days)}
          changeDaysLeftValue={(value) => setDaysLeftValue(value)}
        />
      )}
      {sidePanel && (
        <SubmitTalentSidePanel
          details={jobDetails}
          closePanel={() => setSidePanel(false)}
          openSubmitModal={(request) => {
            setJobDetails(request);
            setSubmitModal(true);
          }}
        />
      )}
    </>
  );
}

export default SubmitCandidate;
