import React from "react";
import Modal from "react-bootstrap/esm/Modal";

const ScreeningQuestions = (props) => {
  const { jobData, setQNA, qna } = props;

 
  const filterQuestions = (questions) => {
    return questions.filter((item) => item.question.trim() !== "");
  };

  return (
    <Modal size="lg" show={qna}>
      <Modal.Header>
        <h6>Knockout Assessment</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setQNA(false)}
          className="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {jobData.screeningQuestion &&
        JSON.parse(jobData.screeningQuestion).length > 0 ? (
          <div className="d-flex mb-2 pl-1 ml-4">
            <ul>
              {filterQuestions(JSON.parse(jobData.screeningQuestion)).map((item, index) => (
                <li className="d-flex m-3" key={index}>
                  <div className="">
                    <span className="col-lg-1">{index + 1}</span>
                  </div>
                  <div className="ml-3 mb-2">
                    <span className="col-lg-11 mb-1">
                      {item.question.replace("[X]", item?.selectedOption)}
                    </span>
                    <br />
                    <small>
                      Response Type: <strong> Yes/No </strong>
                    </small>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="mb-3">
            <div className="text-center p-3">
              <div className="avatar avatar-lg">
                <i className="fad fa-users-slash"></i>
              </div>
              <div className="mt-2">No Knockout Assessment Added</div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button variant="secondary" onClick={() => setQNA(false)}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ScreeningQuestions;
