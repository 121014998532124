import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { AsYouType } from "libphonenumber-js";
import moment from "moment";
import NoRecord from "../components_v3/commoncomponents/norecord/NoRecord";
import StatusData from "../components_v3/talent/talentcommunity/listview/StatusData";
import { CurrencySymbols } from "../config/CurrencySymbols";
import {
  getUserRole,
  getUserTenantID,
  getTenantType,
} from "../../service/AuthService";
import Pagination from "../components_v3/pagination/pagination";
import TalentProfile from "../components_v3/talent/talentprofile/TalentProfile";

const NewTalentList = ({
  arrayList,
  setTalentDetails,
  getTalentList,
  matches,
  talentDetails,
  currentPage,
  dataTotal,
  handlePageChange,
  fetchData,
}) => {
  const [showCandidateProfile, setShowCandidateProfile] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [candidateData, setCandidateData] = useState({});
  const [data, setData] = useState(
    arrayList?.map((x) => ({ ...x, showMail: false }))
  );

  useEffect(() => {
    let list = arrayList?.map((x) => {
      const maxUpdatedDateTime = x?.statusData?.reduce((maxDateTime, obj) => {
        const updatedDateTime = new Date(obj.updatedDateTime);
        return updatedDateTime > maxDateTime ? updatedDateTime : maxDateTime;
      }, new Date(0));
      x.maxUpdatedDateTime =
        x?.statusData?.length > 0 ? maxUpdatedDateTime : x.updatedDateTime;
      return { ...x, showAllRecs: false };
    });

    setData(list);
  }, [arrayList]);

  const jobBoard = [
    "Facebook",
    "LinkedIn",
    "Indeed",
    "CareerBuilder",
    "Dice",
    "Twitter",
  ];

  const handleModel = () => {
    setShowCandidateProfile(false);
  };

  const onProfileClick = (candidate) => {
    setCandidateData(candidate);
    setShowCandidateProfile(true);
    setCandidateId(candidate.userId);
  };

  const onSkillClick = (e, candidate) => {
    setTalentDetails(
      arrayList.map((item) => {
        if (item.userId === candidate.userId) {
          item.ViewAllSkill = !item.ViewAllSkill;
        }
        return item;
      })
    );
  };

  const onTagViewClick = (e, candidate) => {
    setTalentDetails(
      arrayList.map((item) => {
        if (item.userId === candidate.userId) {
          item.ViewAllTags = !item.ViewAllTags;
        }
        return item;
      })
    );
  };

  const toggleShowRec = (candidate) => {
    setData((prevData) =>
      prevData.map((c) =>
        c.userId === candidate.userId ? { ...c, showAllRecs: true } : c
      )
    );
  };

  const handleShowMail = (candidate) => {
    setData(
      data.map((c) => {
        if (c.email === candidate.email)
          return { ...candidate, showMail: !candidate.showMail };
        else return c;
      })
    );
  };

  return (
    <div class="row d-block-force mt-2" style={{ minHeight: "60vh" }}>
      {arrayList?.length === 0 ? (
        <NoRecord />
      ) : (
        <div className="col ">
          {arrayList?.map((candidate, i) => {
            return (
              <div className="d-flex gap-2" key={candidate.userId}>
                <div className="d-block card mb-1 w-100">
                  <div className="d-flex flex-row align-items-stretch justify-content-between   p-relative  d-block-768">
                    <div
                      className={`  ${
                        matches
                          ? "card-body font-regular d-flex align-items-center"
                          : " card-body font-regular d-flex align-items-center col-8"
                      } `}
                    >
                      <div className="mx-2">
                        <span>
                          <Avatar
                            size="55"
                            name={
                              candidate.firstName + " " + candidate.lastName
                            }
                            round="80px"
                          />
                        </span>
                      </div>
                      <div className="w-100 ml-2">
                        <div
                          className="d-flex align-items-center mb-1"
                          onClick={() => {
                            onProfileClick(candidate);
                          }}
                        >
                          <h6
                            className="mb-0 me-2"
                            style={{ cursor: "pointer" }}
                          >
                            {candidate.firstName?.charAt(0)?.toUpperCase() +
                              candidate.firstName?.slice(1)}{" "}
                            {candidate.lastName?.charAt(0)?.toUpperCase() +
                              candidate.lastName?.slice(1)}
                          </h6>
                        </div>
                        <div className="font-regular font-primary font-gray1">
                          {candidate.designation && (
                            <span>{candidate.designation}</span>
                          )}{" "}
                          {candidate.designation &&
                            (candidate.city || candidate.state) &&
                            "/"}{" "}
                          {candidate?.city ? (
                            <span>
                              {candidate.city ? candidate.city + ", " : ""}
                            </span>
                          ) : (
                            ""
                          )}{" "}
                          {candidate?.country ? (
                            <span>
                              {candidate.country ? candidate.country : ""}
                            </span>
                          ) : (
                            ""
                          )}
                          <div className="mt-1 d-flex">
                            <span>
                              {candidate?.experienceLevel ===
                              "Less than one year"
                                ? "Fresher"
                                : candidate?.experienceLevel}
                            </span>
                          </div>
                          <div className="mt-1 d-flex">
                            <div className="mt-1 d-block">
                              {candidate.primarySkills &&
                                candidate.primarySkills
                                  .slice(0, 2)
                                  .map((item) => (
                                    <span className="tag tag-blue3 mb-1 mr-1">
                                      {item}
                                    </span>
                                  ))}
                              {candidate.primarySkills?.length > 2 &&
                                candidate.ViewAllSkill === true &&
                                candidate.primarySkills
                                  .slice(2, candidate.primarySkills?.length)
                                  .map((item) => (
                                    <>
                                      <span class="tag tag-blue3 mb-1 mr-1">
                                        {item}
                                      </span>
                                    </>
                                  ))}
                              {candidate.primarySkills?.length > 2 && (
                                <button
                                  onClick={(e) => onSkillClick(e, candidate)}
                                  type="button"
                                  class="btn btn-text"
                                >
                                  {!candidate.ViewAllSkill ? (
                                    <span>View all skills</span>
                                  ) : (
                                    <span>Show Less</span>
                                  )}
                                </button>
                              )}
                            </div>
                          </div>
                          <StatusData candidate={candidate} />
                        </div>
                      </div>
                    </div>
                    <div
                      class={`  ${
                        matches
                          ? "card-body font-regular d-flex flex-column align-items-end justify-content-center"
                          : "card-body font-regular d-flex flex-column align-items-end justify-content-center col-4"
                      }`}
                    >
                      {candidate.homePhone ? (
                        <div class="mb-1">
                          Contact no:
                          {candidate?.homePhone
                            ? new AsYouType().input(
                                candidate.homePhone[0] === "+"
                                  ? candidate.homePhone
                                  : candidate.homePhoneCode &&
                                    candidate.homePhoneCode.includes("+")
                                  ? `${candidate.homePhoneCode}${candidate.homePhone}`
                                  : `+${candidate.homePhone}`
                              )
                            : ""}
                          {/* <button
                            type="button"
                            title="Send SMS"
                            class="btn btn-text"
                            onClick={() => {
                              handleContactCopy(candidate);
                            }}
                          >
                            <FaCommentAlt />
                          </button> */}
                        </div>
                      ) : (
                        candidate?.workPhone && (
                          <div class="mb-1">
                            Contact no:{" "}
                            {candidate?.workPhone
                              ? new AsYouType().input(
                                  candidate.workPhone[0] === "+"
                                    ? candidate.workPhone
                                    : candidate.workPhoneCode &&
                                      candidate.workPhoneCode.includes("+")
                                    ? `${candidate.workPhoneCode}${candidate.workPhone}`
                                    : `+${candidate.workPhone}`
                                )
                              : ""}
                          </div>
                        )
                      )}

                      {candidate?.email !== "" && candidate?.email && (
                        <div
                          class="mb-1"
                          // style={{ maxWidth: matches ? "200px" : "250px" }}
                        >
                          Email:{" "}
                          <span
                            onClick={() => {
                              handleShowMail(candidate);
                            }}
                            className=""
                            title={candidate?.email}
                          >
                            {candidate.email.length > 16 && !candidate.showMail
                              ? candidate?.email.slice(0, 16) + "..."
                              : candidate?.email}
                          </span>
                          {/* <button
                              type="button"
                              title="Send email"
                              class="btn btn-text"
                              onClick={() => {
                                handleBroadcastEmail(candidate);
                              }}
                            >
                              <i class="fa fa-envelope"></i>
                            </button> */}
                        </div>
                      )}
                      {candidate.preferredSalary !== 0 &&
                        candidate.preferredSalary && (
                          <div class="mb-1">
                            {candidate?.salaryType === "Hourly"
                              ? "Expected hourly salary:"
                              : candidate?.salaryType === "Weekly"
                              ? "Expected weekly salary:"
                              : candidate?.salaryType === "Monthly"
                              ? "Expected monthly salary:"
                              : "Expected annual salary:"}{" "}
                            {CurrencySymbols[
                              candidate.preferredSalaryCurrency.slice(0, 3)
                            ]
                              ? CurrencySymbols[
                                  candidate.preferredSalaryCurrency.slice(0, 3)
                                ]
                              : ""}
                            {Number(candidate.preferredSalary).toLocaleString()}{" "}
                            {CurrencySymbols[
                              candidate.preferredSalaryCurrency.slice(0, 3)
                            ]
                              ? ""
                              : candidate.preferredSalaryCurrency.slice(0, 3)}
                          </div>
                        )}
                      {candidate.minContractRate !== 0 &&
                        candidate.minContractRate && (
                          <div class="mb-1">
                            Expected hourly salary:{" "}
                            {CurrencySymbols[
                              candidate.minContractRateCurrency.slice(0, 3)
                            ]
                              ? CurrencySymbols[
                                  candidate.minContractRateCurrency.slice(0, 3)
                                ]
                              : candidate.minContractRateCurrency.slice(0, 3)}
                            {Number(candidate.minContractRate).toLocaleString()}
                            {CurrencySymbols[
                              candidate.minContractRateCurrency.slice(0, 3)
                            ]
                              ? ""
                              : candidate.minContractRateCurrency.slice(0, 3)}
                          </div>
                        )}
                      <div class="mb-1">
                        Source channel:{" "}
                        {getTenantType() == 2
                          ? getUserTenantID() == 5788
                            ? jobBoard.includes(candidate.source)
                              ? "High5 Job Board"
                              : candidate.source == "Job Board"
                              ? "Crintell"
                              : candidate.source == "JobDiva"
                              ? "JobDiva"
                              : "Internal"
                            : candidate.source == "Job Board"
                            ? "Crintell"
                            : candidate.source == "JobDiva"
                            ? "JobDiva"
                            : "Internal"
                          : getTenantType() == 1
                          ? candidate.tenantId &&
                            candidate.tenantId
                              .map(Number)
                              .includes(getUserTenantID())
                            ? "Internal"
                            : candidate.fk_tenant &&
                              candidate.fk_tenant == getUserTenantID()
                            ? "Internal"
                            : "External"
                          : candidate.source == "Job Board"
                          ? "Crintell"
                          : candidate.source == "JobDiva"
                          ? "JobDiva"
                          : "Internal"}
                      </div>

                      {candidate.RecruiterName !== "" &&
                        candidate.RecruiterName && (
                          <div class="mb-1">
                            Recruiter name:{" "}
                            {candidate.RecruiterName.length > 1 ? (
                              !candidate.showAllRecs ? (
                                <span
                                  style={{ cursor: "pointer" }}
                                  className=" "
                                  onClick={() => toggleShowRec(candidate)}
                                >
                                  {candidate.RecruiterName[0] + "..."}{" "}
                                </span>
                              ) : (
                                candidate.RecruiterName.join(", ")
                              )
                            ) : (
                              candidate.RecruiterName
                            )}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="p-2 border-top d-flex justify-content-between">
                    <div className="mt-1 ml-2 d-flex">
                      {getUserRole() === "Recruiter" && (
                        <div className="mt-1 d-block">
                          {JSON.parse(candidate?.candidateTags)?.length > 0
                            ? "Tags: "
                            : ""}
                          {candidate?.candidateTags &&
                            JSON.parse(candidate?.candidateTags)
                              ?.slice(0, 2)
                              ?.map((item) => (
                                <span className="tag mb-1 mr-1">{item}</span>
                              ))}
                          {JSON.parse(candidate.candidateTags)?.length > 2 &&
                            candidate.ViewAllTags === true &&
                            JSON.parse(candidate?.candidateTags)
                              .slice(
                                2,
                                JSON.parse(candidate.candidateTags)?.length
                              )
                              .map((item) => (
                                <>
                                  <span class="tag mb-1 mr-1">{item}</span>
                                </>
                              ))}

                          {JSON.parse(candidate.candidateTags)?.length > 2 && (
                            <button
                              onClick={(e) => onTagViewClick(e, candidate)}
                              type="button"
                              class="btn btn-text"
                            >
                              {!candidate.ViewAllTags ? (
                                <span>View all tags</span>
                              ) : (
                                <span>Show Less</span>
                              )}
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="d-flex gap-2 align-items-center">
                      {candidate.maxUpdatedDateTime !== "" &&
                        new Date() > new Date(candidate.maxUpdatedDateTime) && (
                          <div class="text-muted my-auto p-0">
                            Last updated: <i className="far fa-clock mr-2"></i>
                            <span>
                              {moment(
                                new Date(candidate.maxUpdatedDateTime),
                                "MMDDYYYY"
                              )
                                .fromNow()
                                .charAt(0) === "a"
                                ? moment(
                                    new Date(candidate.maxUpdatedDateTime),
                                    "MMDDYYYY"
                                  )
                                    .fromNow()
                                    .charAt(0)
                                    .toLocaleUpperCase() +
                                  moment(
                                    new Date(candidate.maxUpdatedDateTime),
                                    "MMDDYYYY"
                                  )
                                    .fromNow()
                                    .slice(1)
                                : moment(
                                    new Date(candidate.maxUpdatedDateTime),
                                    "MMDDYYYY"
                                  ).fromNow()}
                            </span>
                            &nbsp;
                          </div>
                        )}

                      <span className=" ">
                        {candidate?.newSletter === "Unsubscribed" ? (
                          <i
                            title="Unsubscribed"
                            class="fas fa-bell-slash"
                            style={{ color: " #b70000" }}
                          />
                        ) : (
                          <i
                            title="Subscribed "
                            class="fas fa-bell"
                            style={{ color: " #007100" }}
                          />
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}{" "}
          {talentDetails && talentDetails.length > 0 && (
            <>
              <Pagination
                currentPage={currentPage}
                totalItems={dataTotal}
                itemsPerPage={10}
                onPageChange={handlePageChange}
                limit={10}
              />
            </>
          )}
        </div>
      )}

      {showCandidateProfile && (
        <TalentProfile
          candidateId={candidateId}
          candidateData={candidateData}
          handleSuccess={handleModel}
          parent="TalentListView"
          setShowCandidateProfile={setShowCandidateProfile}
          getTalentList={getTalentList}
          fetchData={fetchData}
        />
      )}
    </div>
  );
};

export default NewTalentList;
