import React, { useEffect, useState } from "react";
import TopNavBar from "../../layout/TopNavBar";
import SideNavBar from "../../layout/SideNavBar";
import AuditLog from "./AuditLog";
import RecruiterAnalytics from "./Analytics/RecruiterAnalytics";
import { post } from "../../../service/ApiService";
import { getTenantType, getUserTenantID } from "../../../service/AuthService";
import GrowthAndExpansion from "./cardview/GrowthAndExpansion";
import UserEngagement from "./cardview/UserEngagement";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  getAuditLogData,
  getAuditLogAnalytics,
} from "../../../actions/auditlog/getAuditLogData";
import Footer from "../../layout/Footer";

const Index = () => {
  const dispatch = useDispatch();
  const {
    loading,
    auditLogData,
    analyticsLoading,
    analyticsData,
  } = useSelector(
    (state) => ({
      loading: state.getAuditLogDataReducer.loading,
      auditLogData: state.getAuditLogDataReducer.auditLogData,
      analyticsLoading: state.getAuditLogDataReducer.auditLogData,
      analyticsData: state.getAuditLogDataReducer.analyticsData,
    }),
    shallowEqual
  );
  const [toggle, setToggle] = useState(true);
  const [tab, setTab] = useState("log");
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  const onTabClick = (type) => {
    setTab(type);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    dispatch(
      getAuditLogData({
        fk_tenant: getUserTenantID(),
        filterQuery: {},
        sortQuery: [],
        currentPage: 1,
        pageSize: 10,
      })
    );
    dispatch(getAuditLogAnalytics());
  };

  useEffect(() => {
    if (auditLogData) {
      setData(auditLogData.data);
      setTab("log");
      auditLogData.data && setTotal(auditLogData.total);
    }
  }, [auditLogData]);
  return (
    <body
      className={
        toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
      }
    >
      <div className="body-decorator body-decorator-top"></div>
      <div className="body-decorator body-decorator-bottom"></div>
      <TopNavBar handleToggale={toggleDiv} />
      <SideNavBar />
      <div id="Content">
        <div className="section">
          <div className="container">
            <div
              className="heading-container"
              style={{ marginTop: "10px", padding: "20px" }}
            >
              <h1 className="main-heading" style={{ fontSize: "20px" }}>
                Analytics
              </h1>{" "}
              {/* Main heading */}
            </div>

            <div className="row justify-content-center">
              <>
                <div id="TenantDashboard" className="row mb-3">
                  <>
                    <div className="d-flex justify-content-between mt-3">
                      <ul class="nav nav-tabs  ">
                        <li class="nav-item">
                          <button
                            onClick={() => {
                              fetchData();
                              onTabClick("log");
                            }}
                            type="button"
                            class={
                              tab === "log"
                                ? "nav-link pb-3 active"
                                : "nav-link pb-3"
                            }
                          >
                            <div>Job Application</div>
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            onClick={() => {
                              onTabClick("userEngagement");
                            }}
                            type="button"
                            class={
                              tab === "userEngagement"
                                ? "nav-link pb-3 active"
                                : "nav-link pb-3"
                            }
                          >
                            <div>User Engagement</div>
                          </button>
                        </li>
                        <li class="nav-item">
                          <button
                            onClick={() => {
                              onTabClick("growthAndExpansion");
                            }}
                            type="button"
                            class={
                              tab === "growthAndExpansion"
                                ? "nav-link pb-3 active"
                                : "nav-link pb-3"
                            }
                          >
                            <div>Growth and Expansion</div>
                          </button>
                        </li>
                      </ul>
                    </div>
                    {/* this below div is added to add a separator line between tags and table */}
                    <div style={{ borderTop: "1px solid #ccc" }}></div>
                    {tab === "log" && (
                      <div className="mt-3">
                        <AuditLog
                          data={data}
                          setData={setData}
                          loading={loading}
                          tab={tab}
                          total={total}
                          pageSize={pageSize}
                          setPageSize={setPageSize}
                          analyticsData={analyticsData}
                          analyticsLoading={analyticsLoading}
                        />
                      </div>
                    )}
                    {tab === "userEngagement" && (
                      <div className="mt-3">
                        <UserEngagement />
                      </div>
                    )}

                    {tab === "growthAndExpansion" && (
                      <div className="mt-3">
                        <GrowthAndExpansion />
                      </div>
                    )}
                  </>
                </div>
              </>
            </div>
          </div>
        </div>
        {getTenantType() == 1 && <Footer />}
      </div>
    </body>
  );
};

export default Index;
