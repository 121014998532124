import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { domain } from "../../../../constants/Constants";
import { post } from "../../../../service/ApiService";
import TopNavbar from "../TopNavBar";
import JobInfo from "./JobInfo";
import JobDescription from "./JobDescription";
import Footer from "./Footer";
import ApplyNow from "./ApplyNow";
import JobCard from "../../careers/jobList/JobCard";
import Loader from "../../../Loader/Loader";

const Index = () => {
  const [jobEx, setJobEx] = useState([]);
  const { id, poolId, tenant, reffererid, recruiterid } = useParams();

  const [tenantData, setTenantData] = useState({});
  const [loading, setLoading] = useState(false);
  const [job, setJob] = useState({});
  const [renderTopNavBar, setRenderTopNavBar] = useState(false);
  const [matches400, setMatches400] = useState(
    window.matchMedia("(max-width: 400px)").matches
  );
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const getTenant = async () => {
    const params = { tenantId: tenant };
    setLoading(true);
    console.log("tenantId", params);
    await post("/career/gettenant", params).then((res) => {
      setTenantData(res.data[0]);
      setLoading(false);
    });
  };

  const getJob = async () => {
    const params = { domain: domain, jobId: id, recruiterId: recruiterid };
    const params1 = { id_talentPool: poolId };
    if (poolId) {
      const resp = await post("/talentpool/getPoolByPoolId", params1);
      setJob(resp?.data[0]);
    } else {
      const res = await post("/career/getjobdetails", params);
      setJob(res.data[0]);
      getAllJob(JSON.parse(res.data[0].primarySkills)[0]);
    }
  };
  const getAllJob = async (skill) => {
    // const candidate = sessionStorage.getItem("userId") ?? "";
    // const payload = {
    //   candidateId: candidate,
    // };
    // setLoading(true);
    // const res = await post("/career/getjoblist", payload);
    // let result = res.data.filter(
    //   (f) => f.jobStatus !== 23 && f.jobStatus !== 39
    // );
    // let similarJob = result.filter((f) => f?.jobId !== Number(id)).slice(0, 1);
    // // let re = similarJob;
    // setJobEx(similarJob);
    // setLoading(false);
  };

  useEffect(() => {
    getTenant();
    getJob();
  }, []);
  return (
    <div>
      {loading && <Loader />}
      <div>
        <TopNavbar
          tenantData={tenantData}
          renderTopNavBar={renderTopNavBar}
          isFrom="jobDescription"
          setUserLoggedIn={setUserLoggedIn}
        />
        <JobInfo title={job?.jobTitle} status="jobApply" jobData={job} />
      </div>
      <ApplyNow
        job={job}
        setRenderTopNavBar={setRenderTopNavBar}
        setUserLoggedIn={setUserLoggedIn}
        userLoggedIn={userLoggedIn}
      />
      <JobDescription job={job} />
      {/*  */}
      <div>
        {jobEx.length > 0 && (
          <div className="main_job_requirements" style={{ marginLeft: "6vw" }}>
            <h4>Similar Jobs</h4>
            {jobEx.map((job) => (
              <JobCard matches400={matches400} job={job} />
            ))}
            {/* <div className="main_job_requirements_part_1">
            <span>
              <h6>Job Requirement</h6>
            </span>
            <ol
              className="pr-3"
              dangerouslySetInnerHTML={{ __html: job?.jobDescription }}
            ></ol>
          </div>
          <div className="main_job_requirements_part_2">
            <span>
              <h6>Required Skills</h6>
            </span>
            <div className="main_container-key_points mt-2">
              {job?.primarySkills
                ? JSON.parse(job?.primarySkills)?.map((item, i) => (
                    <div className="main_container-key_points_style" key={i}>
                      <span className="main_container-key_points_style_text">
                        {item}
                      </span>
                    </div>
                  ))
                : ""}
            </div>
          </div>
          <div className="main_job_requirements_part_3"></div>
          <div className="main_job_requirements_part_4"></div> */}
          </div>
        )}
      </div>
      <div>
        <Footer recruiterId={recruiterid} />
      </div>
    </div>
  );
};

export default Index;
