import {
  GET_LOGIN_OVERVIEW_BEGIN,
  GET_LOGIN_OVERVIEW_SUCCESS,
  GET_LOGIN_OVERVIEW_FAILURE,
  GET_LOGIN_OVERVIEW_COUNT_BEGIN,
  GET_LOGIN_OVERVIEW_COUNT_SUCCESS,
  GET_LOGIN_OVERVIEW_COUNT_FAILURE,
} from "../../actions/dashboard/LoginOverview";

const initialState = {
  loading: true,
  countLoading: true,
  recruiterData: [],
  loginOverviewCount: [],
};

const getLoginOverviewReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_LOGIN_OVERVIEW_BEGIN:
      return {
        ...state,
        loading: true,
        recruiterData: [],
      };
    case GET_LOGIN_OVERVIEW_SUCCESS:
      return {
        ...state,
        recruiterData: payload,
        loading: false,
      };
    case GET_LOGIN_OVERVIEW_FAILURE:
      return {
        ...state,
        recruiterData: [],
        loading: false,
      };
    case GET_LOGIN_OVERVIEW_COUNT_BEGIN:
      return {
        ...state,
        countLoading: true,
        loginOverviewCount: [],
      };
    case GET_LOGIN_OVERVIEW_COUNT_SUCCESS:
      return {
        ...state,
        loginOverviewCount: payload,
        countLoading: false,
      };
    case GET_LOGIN_OVERVIEW_COUNT_FAILURE:
      return {
        ...state,
        loginOverviewCount: [],
        countLoading: false,
      };
    default:
      return state;
  }
};

export default getLoginOverviewReducer;
