export const sortObject = [
  {
    id: 1,
    label: "Name",
    value: "firstName",
    icon: "fal fa-fw fa-signature  mr-2 dd-sort",
  },
  {
    id: 2,
    label: "Location",
    value: "country",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
  {
    id: 3,
    label: "Recruiter name",
    value: "RecruiterName",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
  {
    id: 4,
    label: "Last updated",
    value: "updatedDateTime",
    icon: "fal fa-fw fa-calendar-alt mr-2 dd-sort",
  },
];
export const sortObjectRecruiter = [
  {
    id: 1,
    label: "Name",
    value: "firstName",
    icon: "fal fa-fw fa-signature  mr-2 dd-sort",
  },
  {
    id: 2,
    label: "Location",
    value: "country",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
  {
    id: 3,
    label: "Last updated",
    value: "updatedDateTime",
    icon: "fal fa-fw fa-calendar-alt mr-2 dd-sort",
  },
];
export const orderList = [
  {
    value: "asc",
    label: "Ascending",
    icon: "fal fa-sort-amount-down mr-2 dd-sort",
  },
  {
    value: "desc",
    label: "Descending",
    icon: "fal fa-sort-amount-up mr-2 dd-sort",
  },
];

export const jobListSortObject = [
  {
    id: 1,
    label: "Job Title",
    value: "jobTitle",
    icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
  },
  {
    id: 2,
    label: "Job Type",
    value: "jobType",
    icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
  },
  {
    id: 3,
    label: "Location",
    value: "country",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
];

export const recruiterListObj = [
  {
    id: 1,
    label: "Name",
    value: "firstName",
    icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
  },
  {
    id: 2,
    label: "Email",
    value: "email",
    icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
  },
  {
    id: 3,
    label: "Created On",
    value: "createddatetime",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
];

export const recruiterPipelineObj = [
  {
    id: 1,
    label: "Name",
    value: "firstName",
    icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
  },
  {
    id: 2,
    label: "Email",
    value: "email",
    icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
  },
  {
    id: 3,
    label: "Job Title",
    value: "jobTitle",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
];

export const jobSummaryObj = [
  {
    id: 1,
    label: "Job Title",
    value: "jobTitle",
    icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
  },
  {
    id: 2,
    label: "Job Type",
    value: "jobType",
    icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
  },
  {
    id: 3,
    label: "Created On",
    value: "createdOn",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
  {
    id: 3,
    label: "Expired On",
    value: "expiresOn",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
];

export const clientUsersObj = [
  {
    id: 1,
    label: "Client",
    value: "client",
    icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
  },
  {
    id: 2,
    label: "Admin",
    value: "admin",
    icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
  },
  {
    id: 3,
    label: "Created On",
    value: "createdOn",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
];

export const curatorSortObject = [
  {
    id: 1,
    label: "Name",
    value: "name",
    icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
  },
  {
    id: 2,
    label: "Email",
    value: "email",
    icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
  },
  {
    id: 3,
    label: "Created On",
    value: "createddatetime",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
];

export const curatorClientSortObject = [
  {
    id: 1,
    label: "Client",
    value: "client",
    icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
  },
  {
    id: 2,
    label: "Country",
    value: "country",
    icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
  },
  {
    id: 3,
    label: "Created On",
    value: "createddatetime",
    icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
  },
];
