import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";

function RemoveUser(props) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [removeData, setRemoveData] = useState([]);

  useEffect(() => {
    fetchTenant();
  }, []);

  const fetchTenant = async () => {
    setLoading(true);
    const payload = {
      userId: props.userData.userid,
    };
    const res = await post("/assigntenant/getassigntenantlist", payload);
    setData(res.data);
    setLoading(false);
  };

  const removefun = async (tenantId) => {
    setLoading(true);
    try {
      const payload = {
        userId: props.userData.userid,
        tenantId,
      };
      const res = await post("/assigntenant/removebyid", payload);
      setLoading(false);
      if (res.status === 200) {
        setRemoveData(true);
        toast.success("Removed successfully");
        fetchTenant();
        close();
      } else {
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  const close = () => {
    props.handleClose(true);
  };

  return (
    <>
      {loading && <Loader />}
      <div class="bl-modal reveal-overlay reveal-card">
        <div class="bl-modal-overlay"></div>
        <div class="bl-modal-container">
          <div class="bl-modal-card bl-modal-card-md">
            <div class="bl-modal-header">
              <h6 class="bl-modal-title">Tenant Details</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                class="close p-0 bl-modal-close-btn"
                onClick={close}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div clasName="card">
              <div className="card-body">
                <div class="pt-0 mb-3 table-cards-1024">
                  <table class="table table-hoverable-cells">
                    {data.length !== 0 && (
                      <thead>
                        <tr>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                    )}
                    <tbody>
                      {data.map((item) => (
                        <tr>
                          <td>
                            <span>{item.tenantname}</span>
                          </td>
                          <td>
                            <span>{item.tenantemail}</span>
                          </td>
                          {/* <td class="text-left no-hover">
                            <div class="d-flex align-items-center justify-content-left">
                              <button
                                type="button"
                                class="btn btn-secondary btn-sm"
                                onClick={() => removefun(item.fk_tenants)}
                              >
                                Remove
                              </button>
                            </div>
                          </td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {!loading && data.length === 0 && (
                    <div>
                      <div className="text-center p-3">
                        <div className="avatar avatar-lg">
                          <i className="fad fa-users-slash"></i>
                        </div>
                        <div className="mt-2">
                          No Tenant is Assigned To This User
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="modal-footer mt-4 mb-0">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-secondary mr-2"
                onClick={close}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RemoveUser;
