import React, { useEffect, useRef, useState } from "react";
import Validator from "email-validator";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import HereMape from "../../location/CommonHereMap";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import { getUserRole } from "../../../service/AuthService";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import Dropzone, { useDropzone } from "react-dropzone";
import logo from "../../../assets/images/LogoSquare.png";
import ImageUpload from "../../components_v3/commoncomponents/fileupload/ImageUpload";

function AddTenant(props) {
  const dropdownRef = useRef(null);
  const [formComplete, setFormComplete] = useState(false);
  const [form, setForm] = useState({
    lastname: "",
    email: "",
    name: "",
    tenant: "",
    tenanttype: "1",
    stateName: "",
    CountryName: "",
    cityName: "",
    address: "",
    zipCode: "NA",
    phone: "",
  });
  const [imageData, setImageData] = useState({
    fileName: "",
    base64: "",
  });
  const [toggleActions, setToggleActions] = useState(false);

  const [errors, setErrors] = useState({});
  const [submitClicked, setSubmitClicked] = useState(false);
  let [type, settype] = useState("");
  const [phoneValidation, setPhoneValidation] = useState(true);

  const [logoPreview, setLogoPreview] = useState(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setToggleActions(false);
    }
  };
  useEffect(() => {
    // Attach the event listener on mount
    document.addEventListener("click", handleClickOutside);

    // Detach the event listener on unmount
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []); // Empty dependency array means this effect runs once after the initial render
  useEffect(() => {
    setToggleActions(false);
  }, [logoPreview]);

  const handleFileUpload = (acceptedFiles) => {
    // Assuming only one file is uploaded
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onload = () => {
      setLogoPreview(reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleDeleteLogo = (e) => {
    e.preventDefault();
    setLogoPreview(null);
  };
  const toggleActionsDropdown = () => {
    if (toggleActions === false) {
      setToggleActions(true);
    } else {
      setToggleActions(false);
    }
  };

  const [loading, setLoading] = useState(false);
  const findFormErrors = () => {
    const {
      lastname,
      email,
      name,
      tenant,
      tenanttype,
      zipCode,
      cityName,
      stateName,
      CountryName,
      phone,
    } = form;
    const newErrors = {};
    // name errors
    if (!tenant || tenant === "") newErrors.tenant = "*Required Field";
    else if (!/^[A-Z a-z\s]+$/.test(tenant) || /^\s*$/.test(tenant))
      newErrors.tenant = "Invalid Tenant Name ";
    if (!name || name === "") newErrors.name = "*Required Field";
    else if (!/^[A-Z a-z\s]+$/.test(name) || /^\s*$/.test(name))
      newErrors.name = "Invalid First Name ";
    if (!tenanttype || tenanttype === "")
      newErrors.tenanttype = "*Required Field";
    if (!phone || phone === "") newErrors.phone = "*Required Field";
    else if (!phoneValidation) newErrors.phone = "Invalid Phone number";
    if (!lastname || lastname === "") newErrors.lastname = "*Required Field";
    else if (!/^[A-Z a-z\s]+$/.test(lastname) || /^\s*$/.test(lastname))
      newErrors.lastname = "Invalid Last Name ";
    if (!zipCode || zipCode === "") newErrors.zipCode = "*Required Field";
    else if (!/^[0-9a-zA-Z ]+$/.test(zipCode) || /^\s*$/.test(zipCode))
      newErrors.zipCode = "Invalid ZipCode";
    if (!cityName || cityName === "") newErrors.cityName = "*Required Field";
    else if (!/^[A-Z a-z\s]+$/.test(cityName) || /^\s*$/.test(cityName))
      newErrors.cityName = "Invalid City Name ";
    if (!stateName || stateName === "") newErrors.stateName = "*Required Field";
    else if (!/^[A-Z a-z\s]+$/.test(stateName) || /^\s*$/.test(stateName))
      newErrors.stateName = "Invalid State Name ";
    if (!CountryName || CountryName === "")
      newErrors.CountryName = "*Required Field";
    else if (!/^[A-Z a-z\s]+$/.test(CountryName) || /^\s*$/.test(CountryName))
      newErrors.CountryName = "Invalid Country Name ";
    if (!email || email === "") newErrors.email = "*Required Field";
    else if (!Validator.validate(email)) newErrors.email = "Invalid Email id !";

    return newErrors;
  };
  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country?.countryCode?.toUpperCase());
    asYouType.input(value.replace(country?.dialCode, ""));
    const isValid = asYouType.isValid();
    setPhoneValidation(isValid);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
      //  alert("please fill all details ")
    } else if (!phoneValidation) {
      toast.error("Please enter a valid phone number");
    } else if (form.phone == "") {
      newErrors.phone = "*Required Field";
    } else {
      // No errors! Put any logic here for the form submission!
      try {
        setLoading(true);
        const paramters = {
          ...form,
          ...imageData,
        };
        const res = await post("/tenant/savetenant", paramters);
        if (res.data.ReturnText === "Successfully added") {
          setLoading(false);
          const result = res.data;
          toast.success("Client Added Successfully ");
          props.closeAdd(true);
          props.getTenantData();
        } else {
          setLoading(false);
          toast.error(res.data.ReturnText);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error Occured While saving  tenant  Details");
        props.closeAdd(true);
      }
    }
    // api call
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    // if (!!errors[field])
    //   setErrors({
    //     ...errors,
    //     [field]: null,
    //   });
  };
  const close = () => {
    props.closeAdd(true);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
  };

  if (props.userlist) {
    type = props.userlist.type;
  }

  const role = getUserRole();

  return (
    <>
      {loading && <Loader />}
      <div class="bl-modal reveal-overlay reveal-card">
        <div class="bl-modal-overlay"></div>
        <div class="bl-modal-container">
          <div class="bl-modal-card bl-modal-card-lg">
            <div class="bl-modal-header">
              <h6 v-if="title" class="bl-modal-title">
                {props.userlist
                  ? "Update User"
                  : props.addusersCondition === true
                  ? "Add User"
                  : "Add Client"}
              </h6>
              <button
                type="button"
                class="close p-0 bl-modal-close-btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={close}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div v-if="showBody" class="bl-modal-body pb-0">
              <div
                v-if="isAssign === false"
                class="card card-flat card-borderless bg-gray4 text-center"
              >
                <div class="card-body">
                  <div class="container">
                    <div class="row mt-4">
                      {false && (
                        <div class="col-lg-4">
                          <div class="form-floating">
                            {
                              <Form.Control
                                as="select"
                                disabled
                                onChange={(e) =>
                                  setField("tenanttype", e.target.value)
                                }
                                isInvalid={!!errors.tenanttype}
                              >
                                <option value="">Select Client</option>
                                <option selected value="1">
                                  Client
                                </option>
                              </Form.Control>
                            }
                            <Form.Control.Feedback type="invalid">
                              {errors.tenanttype}
                            </Form.Control.Feedback>
                            <label>Client Type*</label>
                          </div>
                        </div>
                      )}
                      <div class="col-lg-6">
                        {/* <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter Tenant Name"
                            onChange={(e) => setField("tenant", e.target.value)}
                            isInvalid={!!errors.tenant}
                          />
                          <label>Client Name*</label>
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-left"
                          >
                            {errors.tenant}
                          </Form.Control.Feedback>
                        </div> */}
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2 "
                            style={{
                              border: errors.tenant && "1px solid #ff0000",
                            }}
                            name="tenant"
                            value={form.tenant}
                            onChange={(e) => setField("tenant", e.target.value)}
                            placeholder="Enter Tenant Name"
                          />
                          <label>Client Name*</label>
                          <small
                            className="validation"
                            style={{ float: "left" }}
                          >
                            {errors.tenant}
                          </small>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        {/* <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter email "
                            onChange={(e) => setField("email", e.target.value)}
                            isInvalid={!!errors.email}
                          />
                          <label>Email ID*</label>
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-left"
                          >
                            {errors.email}
                          </Form.Control.Feedback>
                        </div> */}
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2"
                            style={{
                              border: errors.email && "1px solid #ff0000",
                            }}
                            name="email"
                            value={form.email}
                            onChange={(e) => setField("email", e.target.value)}
                            placeholder="Enter email"
                          />
                          <label>Email ID*</label>
                          <small
                            className="validation"
                            style={{ float: "left" }}
                          >
                            {errors.email}
                          </small>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div class="col-lg-6">
                        {/* <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2 "
                            style={{
                              border: errors.name && "1px solid #ff0000",
                            }}
                            name="firstName"
                            value={form.name}
                            onChange={(e) => setField("name", e.target.value)}
                            placeholder="First Name*"
                          />
                          <label>First Name*</label>
                        </div> */}
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2"
                            style={{
                              border: errors.name && "1px solid #ff0000",
                            }}
                            name="firstName"
                            value={form.name}
                            onChange={(e) => setField("name", e.target.value)}
                            placeholder="First Name*"
                          />
                          <label>First Name*</label>
                          <small
                            className="validation"
                            style={{ float: "left" }}
                          >
                            {errors.name}
                          </small>
                        </div>
                      </div>

                      <div class="col-lg-6">
                        {/* <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter Last Name"
                            onChange={(e) =>
                              setField("lastname", e.target.value)
                            }
                            isInvalid={!!errors.lastname}
                          />
                          <label>Last Name*</label>
                          <Form.Control.Feedback
                            type="invalid"
                            className="text-left"
                          >
                            {errors.lastname}
                          </Form.Control.Feedback>
                        </div> */}
                        <div className="form-floating">
                          <input
                            type="text"
                            className="form-control mr-2"
                            style={{
                              border: errors.lastname && "1px solid #ff0000",
                            }}
                            name="lastname"
                            value={form.lastname}
                            onChange={(e) =>
                              setField("lastname", e.target.value)
                            }
                            placeholder="Enter Last Name"
                          />
                          <label>Last Name*</label>
                          {errors.lastname && (
                            <small
                              className="validation"
                              style={{ float: "left" }}
                            >
                              {errors.lastname}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4 ">
                      {/* <div className="col-lg-12">
                        <div className="">
                        <label style = {{float:"left"}}>Phone Number*</label>

                          <PhoneInput
                            inputClass={"form-control font-14 w-100"}
                            style={{
                              border: errors.phone && "1px solid #ff0000",
                            }}
                            
                            required="true"
                            placeholder="mobile"
                            id="homePhone"
                            country={"us"}
                            value={form.phone}
                            name="homePhone"
                            onChange={(value, country, e) => {
                              _onChangeText(value, country);
                              setForm({
                                ...form,
                                phone: value,
                                phoneCode: country.dialCode,
                              });
                            }}
                          />
                        </div>
                        {console.log(errors)}
                        {!phoneValidation  && errors.phone && (
                          <small className="validation" style = {{float:"left"}} >Invalid Phone</small>
                          // {errors.phone}
                        )}
                        
                      </div> */}
                      <div className="col-lg-12">
                        <div className="mb-2">
                          <label style={{ float: "left" }}>
                            Phone<span>*</span>
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="d-flex flex-column">
                          <PhoneInput
                            inputClass={"form-control font-14 w-100"}
                            style={{
                              border: errors.phone && "1px solid #ff0000",
                            }}
                            required={true}
                            placeholder="mobile"
                            id="homePhone"
                            country={"us"}
                            value={form.phone}
                            name="homePhone"
                            onChange={(value, country, e) => {
                              _onChangeText(value, country);
                              setForm({
                                ...form,
                                phone: value,
                                phoneCode: country.dialCode,
                              });
                            }}
                          />
                          {/* {!phoneValidation && errors.phone && (
                            <span  style={{ float: "left" }}>
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                Invalid Phone
                              </small>
                            </span>
                          )} */}
                          {errors.phone && (
                            <span  style={{ float: "left" }}>
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                {errors.phone}
                              </small>
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-4">
                      <div className="form-floating">
                        <HereMape setForm={setForm} form={form} />
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <label className="mb-2"> Address </label>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder="Address"
                              value={form.address}
                              onChange={handleChange}
                            />
                            <label>Address </label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-4">
                          {/* <div className="form-floating">
                            <Form.Control
                              type="text"
                              placeholder="Enter city "
                              id="cityName"
                              value={form.cityName}
                              onChange={handleChange}
                              isInvalid={!!errors.cityName}
                            />
                            <label>City* </label>
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-left"
                            >
                              {errors.cityName}
                            </Form.Control.Feedback>
                          </div> */}
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control mr-2"
                              id="cityName"
                              style={{
                                border:
                                  errors.cityName &&
                                  form.cityName === "" &&
                                  "1px solid #ff0000",
                              }}
                              name="cityName"
                              value={form.cityName}
                              onChange={handleChange}
                              placeholder="Enter City"
                            />
                            <label htmlFor="cityName">City*</label>
                            {errors.cityName && form.cityName === "" && (
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                {errors.cityName}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          {/* <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="stateName"
                              placeholder="Address State"
                              value={form.stateName}
                              onChange={handleChange}
                              isInvalid={!!errors.stateName}
                            />
                            <label> State* </label>
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-left"
                            >
                              {errors.stateName}
                            </Form.Control.Feedback>
                          </div> */}
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="stateName"
                              style={{
                                border:
                                  errors.stateName &&
                                  form.stateName === "" &&
                                  "1px solid #ff0000",
                              }}
                              name="stateName"
                              value={form.stateName}
                              onChange={handleChange}
                              placeholder="Address State"
                            />
                            <label htmlFor="stateName">State*</label>
                            {errors.stateName && form.stateName === "" && (
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                {errors.stateName}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          {/* <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="CountryName"
                              placeholder="Address Country"
                              value={form.CountryName}
                              onChange={handleChange}
                              isInvalid={!!errors.CountryName}
                            />
                            <label> Country* </label>
                            <Form.Control.Feedback
                              type="invalid"
                              className="text-left"
                            >
                              {errors.CountryName}
                            </Form.Control.Feedback>
                          </div> */}
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="CountryName"
                              style={{
                                border:
                                  errors.CountryName &&
                                  form.CountryName === "" &&
                                  "1px solid #ff0000",
                              }}
                              name="CountryName"
                              value={form.CountryName}
                              onChange={handleChange}
                              placeholder="Address Country"
                            />
                            <label htmlFor="CountryName">Country*</label>
                            {errors.CountryName && form.CountryName === "" && (
                              <small
                                className="validation"
                                style={{ float: "left" }}
                              >
                                {errors.CountryName}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2 ml-2 mr-3">
                        <ImageUpload
                          imageData={imageData}
                          setImageData={setImageData}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer mt-4 mb-0">
              <button
                type="button"
                class="btn btn-secondary mr-2"
                data-dismiss="modal"
                onClick={close}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleSubmit}
              >
                {!submitClicked && !formComplete && <span>Submit</span>}

                {submitClicked && (
                  <span v-if="submittingForm">
                    <span v-if="isAssign === true">Adding Tenants</span>
                    <img width="20px" src="/images/button-loader.gif" />
                  </span>
                )}
                {formComplete && !submitClicked && (
                  <span v-if="showSuccessMessage">
                    <span v-if="isAssign === true">Tenants Added</span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddTenant;
