import React, { useState } from "react";
import { toast } from "react-toastify";
import Avatar from "react-avatar";
import { Modal } from "react-bootstrap";
import { post } from "../../../../../../service/ApiService";
import Loader from "../../../../../Loader/Loader";
import {
  getUserRole,
  getActualName,
  getActualEmail,
} from "../../../../../../service/AuthService";

function VettingResendMail(props) {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const {
    candidateData,
    jobData,
    vettingResendPopup,
    setVettingResendPopup,
  } = props;

  // send reminder mail
  const reminderMail = async () => {
    setLoading(true);
    let vettingDetails = candidateData?.vettingDetails;
    let testInfo = JSON.parse(vettingDetails[0]?.testInfo);
    let candidateTestAssign = JSON.parse(vettingDetails[0]?.data);

    let payload = {
      candidateEmail: candidateData?.email,
      jobTitle: jobData?.jobTitle,
      candidateFname: candidateData?.firstName,
      testName: testInfo?.testName,
      testCategory: testInfo?.testCategory,
      duration: testInfo?.details?.duration,
      uniqueCode: candidateTestAssign?.uniqueCode,
      recruiterInfo:
        candidateData?.recruiterFname + " " + candidateData?.recruiterLname,
      tenantName: candidateData?.tenantName,
      hiringManagerInfo: {
        fullName: getActualName(),
        email: getActualEmail(),
      },
    };

    const res = await post(`/vetIntegration/email/resend`, payload);
    if (res.status == 200) {
      setLoading(false);
      setVettingResendPopup(false);
      toast.success("Sent reminder email successfully");
    }
  };

  return (
    <>
      {loading && <Loader></Loader>}
      <Modal
        size="lg"
        show={vettingResendPopup}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="interviewPopUpModal"
      >
        <Modal.Body>
          <div className="bl-modal-header">
            <h6 className="bl-modal-title"></h6>
            <button
              type="button"
              onClick={() => setVettingResendPopup(false)}
              className="close p-0 bl-modal-close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <div className="text-center">
              {candidateData.ImageURL ? (
                <span>
                  {/* <img className="avatar" alt="" src={talentdata.ImageURL} /> */}
                  <span className="ml-2">
                    {candidateData.firstName} {candidateData.lastName}{" "}
                  </span>
                </span>
              ) : (
                <span>
                  <Avatar
                    className="mb-1"
                    size="55"
                    name={
                      candidateData.firstName + " " + candidateData.lastName
                    }
                    round="90px"
                  />
                  {/* {getInitial(`${talentData[0].candidateFname} ${talentData[0].candidateLname}`)} */}
                </span>
              )}
            </div>
          </div>
          <div>
            <div className="text-center">
              <h6 className="mt-3">
                {candidateData.firstName} {candidateData.lastName}{" "}
              </h6>
              <p>
                {jobData?.isRemote === true ? (
                  <span className="mb-1 mr-1">Remote</span>
                ) : (
                  <span className="mb-1 mr-1">{jobData?.city}</span>
                )}{" "}
                /
                {
                  <span className="mb-1 mr-1">
                    {} {jobData.tenantName}
                  </span>
                }
                /<span className="mb-1 mr-1">{} </span>
                {/* {jobData.jobType === "Full Time"
              ? jobData.annualSalary
              : jobData.hourlyRate} */}
                {
                  <span className="mb-1 mr-1">
                    {" "}
                    {new Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: jobData.placementCurrency
                        ? `${jobData.placementCurrency}`
                        : "USD",
                      maximumSignificantDigits: 3,
                    }).format(jobData.maximumPay)}
                  </span>
                }
              </p>
            </div>
          </div>
          <div style={{ padding: "1px 2px 35px 0px" }}></div>
          <div v-if="showMakeAnOfferForm">
            <div className="bl-modal-body pt-0">
              <p className="text-center">
                <strong>Oops! Requested assessment is incomplete.</strong>
              </p>
              <p className="text-center">
                <p>
                  This candidate can’t be shortlisted as the requested
                  assessment is pending, you can send reminder email to
                  candidate for assessment completion. Once candidate completes
                  the assessment, you will receive an email confirmation and
                  candidate will be ready for the shortlist.
                </p>
              </p>
              {/* <div className="col-lg-8 d-flex align-items-center  mb-2 mb-lg-0 justify-content-center justify-content-lg-start">
            <div className="form-check form-switch d-flex align-items-center text-center">
              <label className="form-check-label pt-3" htmlFor="mailSwitch">
                <p> Send Vetting Reminder To Candidate</p>
              </label>
              <input
                name="candidateEmail"
                className="form-check-input ml-3"
                type="checkbox"
                onChange={(e) => handleCheck(e)}
                checked={talentEmailChecked ? true : false}
              />
              <button className="btn btn-sm ml-3">Send email</button>
            </div>
          </div> */}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
                onClick={() => setVettingResendPopup(false)}
                disabled={submitClicked}
              >
                Cancel
              </button>
              {/* <button type="button" className="btn btn-primary btn-sm">
            {submitClicked === false ? (
              <span onClick={submitMakeAnOffer}>Submit Offer</span>
            ) : (
              <span>
                Submitting Offer
                <img width="20px" src={imageLoader} alt="blank" />
              </span>
            )}
          </button> */}
              {/* <button type="button" className="btn btn-sm" onClick={() => {}}>
            Disqualiy
          </button>{" "} */}
              <button
                type="button"
                className="btn btn-primary btn-sm"
                onClick={reminderMail}
              >
                Send email
              </button>
            </div>
          </div>{" "}
        </Modal.Body>
      </Modal>
    </>
  );
}
export default VettingResendMail;
