import React, { useEffect, useState } from "react";
import TagsInput from "react-tagsinput";
import Modal from "react-bootstrap/Modal";

const VettingRequirement = (props) => {
  const handleAddButton = (checked, item) => {
    checked
      ? props.setCheckedAssessments([...props.checkedAssessments, item])
      : props.setCheckedAssessments(
          props.checkedAssessments.filter((i) => i !== item)
        );

    // props.setAddJobData({
    //   ...props.addJobData,
    //   vettingDetails: props.checkedAssessments,
    // });
  };

  return (
    <>
      <div className="card card-flat m-2">
        <div className="card-body">
          <div className="d-flex justify-content-between">
            <h6 className="m-1 mb-5">Suggested Vetting</h6>
            {props.checkedAssessments.length > 0 && (
              <small className="m-1">Maximum 3 skills can be selected</small>
            )}
          </div>
          <div className="row">
            {props.checkedAssessments.length > 0 && (
              <div className=" mb-3 px-3">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Assessment</th>
                      <th scope="col">Category</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.checkedAssessments.map((x, i) => (
                      <tr key={i}>
                        <td>
                          <strong>{i + 1}</strong>
                        </td>
                        <td>{x}</td>
                        <td>{"General"}</td>
                        <td style={{ cursor: "pointer", width: "100px" }}>
                          <button
                            type="button"
                            className="btn btn-text-accent"
                            onClick={() => {
                              let assessment = props.checkedAssessments.filter(
                                (y) => y !== x
                              );
                              props.setCheckedAssessments(assessment);
                            }}
                          >
                            <div className="d-flex ">
                              <span>
                                <i
                                  aria-hidden="true"
                                  className="fas fa-trash-alt fa-fw"
                                  style={{ color: "red" }}
                                ></i>
                              </span>
                            </div>
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}

            <div className="mt-4"></div>

            {props?.vetSkills.length > 0 && (
              <table className={`table table-sortable table-hoverable`}>
                <thead>
                  <tr>
                    <th>
                      <span className="ml-2"></span>Select
                    </th>
                    <th scope="col">Skills</th>
                    <th scope="col">Difficulty</th>
                    <th>Category</th>
                  </tr>
                </thead>

                <>
                  {props.vetSkills.map((item, key) => (
                    <tbody>
                      <tr disabled={true} className="ml-2 mr-2">
                        <td className="ml-2">
                          <center>
                            <div className="form-check form-switch ml-1">
                              <input
                                type="checkbox"
                                checked={props.checkedAssessments.includes(
                                  item
                                )}
                                disabled={
                                  !props.checkedAssessments.includes(item) &&
                                  props.checkedAssessments.length >= 3
                                }
                                className="form-check-input"
                                onChange={(e) => {
                                  handleAddButton(e.target.checked, item);
                                }}
                              />
                            </div>
                            {/* {props.checkedAssessments.length >= 3 ||
                            props.checkedAssessments.includes(item) ? (
                              <span>
                                <i
                                  onClick={() => {}}
                                  className="fa fa-plus-circle"
                                ></i>
                              </span>
                            ) : (
                              <span>
                                <i
                                  onClick={() => {
                                    handleAddButton(item);
                                  }}
                                  className="fa fa-plus-circle"
                                  style={{ color: "#318909 " }}
                                ></i>
                              </span>
                            )} */}
                          </center>
                        </td>
                        <td className="text-left">{item}</td>
                        <td className="text-left">{"Simple"}</td>
                        <td>{"General"}</td>
                      </tr>
                    </tbody>
                  ))}
                </>
              </table>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default VettingRequirement;
