import React, { useEffect, useRef } from "react";
import GoogleLocationAPI from "../../location/GoogleLocationAPI";

const Location = ({ formData, setFormData, errorData, handleChange }) => {
  const searchInput = useRef(null);
  const setLocation = (address, searchInput) => {
    const city = address.locality || address.city;
    setFormData({
      ...formData,
      city: city,
      state: address.state,
      country: address.country,
      location: address,
      address: searchInput.current?.value,
      longitude: address.lng,
      latitude: address.lat,
    });
    // searchInput.current.value = city;
  };
  useEffect(() => {}, [searchInput]);
  return (
    <>
      {/* <div className="row mb-4"> */}
        <div className="">
          <label>Location*</label>
          <div className="form-floating ">
            <GoogleLocationAPI
              className={
                errorData["city"] && formData.experienceLevel == ""
                  ? "form-control border border-danger"
                  : "form-control"
              }
              setLocation={setLocation}
              searchInput={searchInput}
              setFormData={setFormData}
              formData={formData}
            />
          </div>
          {errorData["city"] && formData.city == "" && (
            <div className="validation">{errorData["city"]}</div>
          )}
        </div>
        {/* <div className="col-md-4">
          <label>State*</label>
          <div className="form-floating">
            <input
              type="text"
              className={
                errorData["state"]
                  ? "form-control border border-danger"
                  : "form-control"
              }
              placeholder="State*"
              id="stateName"
              name="state"
              onChange={(e) => handleChange(e)}
              value={formData?.state}
            />
          </div>
          <div className="validation">{errorData["state"]}</div>
        </div> */}
        {/* <div className="col-md-4">
          <label>Country*</label>
          <div className="form-floating">
            <input
              type="text"
              className={
                errorData["country"]
                  ? "form-control border border-danger"
                  : "form-control"
              }
              placeholder="Country*"
              id="CountryName"
              name="country"
              onChange={(e) => handleChange(e)}
              value={formData?.country}
            />
          </div>
          <div className="validation">{errorData["country"]}</div>
        </div> */}
      {/* </div> */}
    </>
  );
};

export default Location;
