import React, { useState, useEffect, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiDomain, BotImage, ChatImage } from "../../constants/Constants";
import {
  getCandidateName,
  getUserToken,
  getUserRole,
  getUserName,
  getOwner,
  getActualRole,
} from "../../service/AuthService";
import { get } from "../../service/ApiService";
import SupportModal from "../layout/SupportModal";
import useOutsideClick from "../function/useOutsideClick";
import Avatar from "react-avatar";
import Loader from "../Loader/Loader";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom/cjs/react-router-dom";
import ReactGA from "react-ga";

function ReportTopNavBar(props) {
  const TRACKING_ID = "UA-223748254-1";
  ReactGA.initialize(TRACKING_ID);
  const dispatch = useDispatch();
  const history = useHistory();
  const [toggle, setToggle] = useState(true);
  const [toggleProfile, setToggleProfile] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const [toggleNotification, setToggleNotification] = useState(false);
  const [openSearchHistory, setOpenSearchHistory] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [userprofile, setProfile] = useState({ name: "" });
  const { name } = userprofile;
  const [support, setSupport] = useState([]);
  const [supportModal, setSupportModal] = useState(false);
  const [supportType, setSupportType] = useState(0);
  const [supportSearchValue, setSupportSearchValue] = useState("");
  const [enableSupportSearch, setEnableSupportSearch] = useState(false);
  const [supportData, setSupportData] = useState([]);
  const [adminUserDetails, setAdminUserDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [bot, setBot] = useState(false);
  // const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

  // ####################
  // main toggle function
  // ####################
  //  for autosearch close suggesstions
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();

  const toglleDiv = () => {
    if (toggle === false) {
      props.handleToggale(true);
      setToggle(true);
    } else {
      props.handleToggale(false);
      setToggle(false);
    }
  };

  const toggleProfileDropdown = () => {
    if (toggleProfile === false) {
      setToggleProfile(true);
      setToggleHelp(false);
      setToggleNotification(false);
    } else {
      setToggleProfile(false);
    }
  };

  const openHelp = () => {
    setToggleHelp(true);
    setToggleProfile(false);
    setToggleNotification(false);
    setToggleHelp(false);
  };

  const openNotification = () => {
    if (toggleNotification === false) {
      setToggleNotification(true);
      setToggleHelp(false);
      setToggleProfile(false);
    } else {
      setToggleNotification(false);
    }
  };

  const onClickLogout = (e) => {
    e.preventDefault();
    const token = getUserToken();
    const headers = { authorization: token };
    dispatch(handleLogout({ headers }));
  };

  const handleLogout = (header) => async (dispatch) => {
    try {
      const res = await axios.put(`${apiDomain}/logout`, null, header);
      if (res.status === 200) {
        ReactGA.event({
          user: getUserName(),
          role: getUserRole(),
          time: new Date(),
        });
        localStorage.clear();
        history.push("/login");
      }
    } catch (error) {
      toast.error("Unable to Logout!");
    }
  };

  const fetchSupportAPI = async (isAdmin) => {
    setLoading(true);
    let token = isAdmin ? "adminUserToken" : "userToken";
    let id = JSON.parse(localStorage.getItem(token)).roleId;
    const res = await get("/support");
    setSupport(res.data.filter((s) => s.fk_role.includes(id)));
    setLoading(false);
  };

  useEffect(() => {
    const username = getCandidateName();
    username.substr(0, username.indexOf(" "));
    setProfile({ name: username });
  }, []);

  useEffect(() => {
    //  change value
    const adminTokenDetails = localStorage.getItem("adminUserToken");
    if (adminTokenDetails) {
      setAdminUserDetails(adminTokenDetails);
      fetchSupportAPI(true);
    } else {
      setAdminUserDetails("");
      fetchSupportAPI(false);
    }
  }, []);

  const openSupportModal = (e) => {
    e.preventDefault();
    setSupportType(+Object.values(e.target)[0].key);
    setSupportModal(true);
  };

  const handleSupportSearch = (e) => {
    setSupportSearchValue(e.target.value);
    setEnableSupportSearch(true);
    setSupportData(
      support.filter((i) =>
        i.Title.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const closeSupportModal = () => {
    setSupportModal(false);
  };

  const splitMatchedText = (str) => {
    const regExp = new RegExp(supportSearchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='background: blue; color: white;'>${match}</span>`
    );
  };

  const ChangeUser = (e) => {
    let actRole = getActualRole();
    localStorage.setItem("userToken", adminUserDetails);
    // redirect to dashboard page
    if (
      actRole === "SuperAdmin" ||
      actRole === "CustomerSuccess" ||
      actRole === "CommunityManager"
    )
      history.push({ pathname: "/high5Hire", state: { tab: "users" } });
    else history.push("/high5Hire");
    window.location.reload(true);
  };

  return (
    <>
      {loading && <Loader />}
      <div id="TopBar">
        {/* **** toggle div for main menu  ***** */}
        <div className="nav-toggle-wrap">
          <button
            id="MainMenuToggle"
            onClick={toglleDiv}
            type="button"
            className="btn btn-icon nav-toggle btn-nav"
          >
            <i className="fal fa-angle-left"></i>
          </button>
          <div className="divider"></div>
        </div>
        <div className="extras ml-auto text-right">
          {/*Go to dashboard*/}
          {getActualRole() && (
            <button
              type="button"
              data-bs-toggle="tooltip"
              data-bs-placement="left"
              title="Back"
              className="mbtn btn-sm w-auto btn-text-accent dd-sort  "
              onClick={(e) => ChangeUser()}
            >
              Back
            </button>
          )}
          {/*Go to dashboard ended*/}
          {/* Help div started  */}
          {props.isFrom === "SubCommDisp" && (
            <button
              type="button"
              title="Details"
              className="btn btn-action-panel btn-icon btn-nav ml-3"
              onClick={() => props?.setShowDetails(true)}
            >
              <i class="fal fa-fw fa-eye"></i>{" "}
            </button>
          )}
          <button
            type="button"
            title="Support"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
            onClick={() => setToggleHelp(!toggleHelp)}
          >
            <i className="fal fa-fw fa-life-ring"></i>
          </button>
          {toggleHelp && (
            <div
              className="action-panel"
              style={{
                display: "revert",
                right: "0px",
                top: "50px",
                textAlign: "left",
              }}
            >
              <OutsideClickHandler
                onOutsideClick={() => !supportModal && setToggleHelp(false)}
              >
                <div
                  className="action-panel"
                  style={{
                    display: "revert",
                    right: "0px",
                    top: "50px",
                    textAlign: "left",
                  }}
                >
                  <div className="py-3 px-4">
                    <p className="lead mb-0">Hello {getCandidateName()}</p>
                    <strong>What can we help you with today?</strong>
                    {/* <div className="d-flex align-items-center justify-content-center m-3">
                      Chat With Us
                      <a
                        className="ml-auto"
                        onClick={(e) => {
                          e.preventDefault();
                          setBot(true);
                        }}
                      >
                        <img src={BotImage} width="55px" />
                      </a>
                    </div> */}
                    <div className="search-field mb-3 mt-2">
                      <div className="field-wrap">
                        <input
                          type="search"
                          onChange={handleSupportSearch}
                          placeholder="Search Support"
                        />
                        <span className="icon">
                          <i aria-hidden="true" className="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                    <strong>
                      <small>Common Questions</small>
                    </strong>
                    <ul className="mt-1 ml-0 list-unstyled">
                      {enableSupportSearch
                        ? supportData.length !== 0
                          ? supportData.map((s, i) => (
                              <li>
                                <a
                                  key={i}
                                  onClick={openSupportModal}
                                  href=""
                                  dangerouslySetInnerHTML={{
                                    __html: splitMatchedText(s.Title),
                                  }}
                                ></a>
                              </li>
                            ))
                          : "No Questions available "
                        : support.length !== 0
                        ? support.map((s, i) => (
                            <li>
                              <a key={i} onClick={openSupportModal} href="">
                                {s.Title}
                              </a>
                            </li>
                          ))
                        : "No Questions available "}
                    </ul>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          )}{" "}
          {/* Help div ended  */}
          {/* Profile div started  */}
          <div className="dropdown ml-3">
            <button
              className="btn btn-icon dropdown-toggle"
              type="button"
              ref={ref1}
              onClick={toggleProfileDropdown}
            >
              <Avatar
                className="avatar avatar-sm "
                name={userprofile.name}
                round="80px"
              />
            </button>
            <div>
              {toggleProfile && (
                <div
                  className="dropdown-menu"
                  style={{ display: "revert", right: "-14px", top: "30px" }}
                >
                  {props.component !== "Profile" && (
                    <a href="/high5hire/myprofile" className="dropdown-item">
                      <i className="fal fa-user-circle mr-3"></i>Profile
                      <hr />
                    </a>
                  )}
                  {getOwner() && (
                    <>
                      <a href="/high5hire/users" className="dropdown-item">
                        <i className="fal fa-user-circle mr-3"></i>Users
                      </a>
                      <hr />
                    </>
                  )}
                  <a
                    href="#top"
                    className="dropdown-item"
                    onClick={onClickLogout}
                  >
                    <i className="fal fa-sign-out mr-3"></i> Logout{" "}
                  </a>
                </div>
              )}
            </div>
            {/* Profile div ended */}
          </div>
        </div>
      </div>

      {supportModal && (
        <SupportModal
          supportData={support[supportType]}
          closeSupportModal={closeSupportModal}
        />
      )}

      <Modal show={bot}>
        <Modal.Header>
          {/* <h6>FAQ</h6> */}
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setBot(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="bl-modal-body  text-center">
            <div className="avatar avatar-lg avatar-green1 mr-3">
              <img src={ChatImage} />
            </div>
          </div>
          <iframe
            src="https://webchat.botframework.com/embed/tesktmaker-bot?s=93Oks9s9wyE.ftP4MgM9ZhhjGjWkhvwhHqZcK7mklyKAxoDi5XeGIKs"
            width="100%"
            height="350px"
          ></iframe>
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center m-3"></div>
      </Modal>
    </>
  );
}

export default ReportTopNavBar;
