import React from "react";
import moment from "moment";
import { AsYouType } from "libphonenumber-js";
import NoRecord from "../../commoncomponents/norecord/NoRecord";

const ReportTable = ({
  getPaginatedData,
  reportHeaders,
  currentPage,
  perPage,
  userId,
  setUserId,
  getCount,
}) => {
  const getPhone = (phone, phoneCode) => {
    if (phoneCode) {
      let actualPhone = phone.replace("+", "");
      let actualPhoneCode = phoneCode.replace("+", "");
      let phoneNumber = "";
      if (actualPhone.substring(0, actualPhoneCode.length) == actualPhoneCode) {
        phoneNumber = phone
          .replace("+", "")
          .slice(phoneCode.replace("+", "").length);
      } else {
        phoneNumber = phone.replace("+", "");
      }
      return "+" + actualPhoneCode + phoneNumber;
    } else {
      let phoneNumber = phone.replace("+", "");
      return `+1${phoneNumber}`;
    }
  };
  return (
    <div>
      {getPaginatedData().length > 0 ? (
        <div
          className="px-3 pt-0 table-cards-1024 mt-3"
          style={{ overflowX: "scroll" }}
        >
          <table
            className="table table-hoverabletable-cards-1024"
            id="table-to-xls"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <thead>
              <tr>
                {reportHeaders.map((header) => (
                  <th>{header.name}</th>
                ))}
              </tr>
            </thead>

            {getPaginatedData().map((item, idx) => (
              <tbody>
                <tr>
                  <td>{++idx + (currentPage - 1) * perPage}</td>
                  <td>{item.name}</td>
                  <td>{item.email}</td>
                  <td>
                    {item?.phoneNumber
                      ? new AsYouType().input(
                          getPhone(item?.phoneNumber, item?.phoneCode)
                        )
                      : "NA"}
                  </td>
                  <td>
                    {item.city || item.country
                      ? `${item.city}, ${item.country} `
                      : "NA"}
                  </td>
                  <td>
                    {item.yearsOfExperience == null
                      ? "0 years"
                      : `${item.yearsOfExperience} years`}
                  </td>
                  <td>
                    {item.languages
                      ? JSON.parse(item.languages).length !== 0 &&
                        JSON.parse(item.languages).map((lang, index) => {
                          if (
                            index < 2 ||
                            (item.ID_user === userId.id && userId.l === true)
                          )
                            return (
                              <span className="tag tag-blue3 m-1">{lang}</span>
                            );
                        })
                      : "NA"}
                    {userId.l !== true ? (
                      item.languages &&
                      JSON.parse(item?.languages)?.length > 2 && (
                        <div
                          class="tag"
                          onClick={() =>
                            setUserId({
                              ...userId,
                              id: item.ID_user,
                              l: true,
                            })
                          }
                        >
                          +
                          {item.languages &&
                            JSON.parse(item?.languages).length - 2}{" "}
                          more
                        </div>
                      )
                    ) : item.ID_user === userId.id && userId.l === true ? (
                      <div
                        class="tag"
                        onClick={() => setUserId({ ...userId, l: false })}
                      >
                        show less
                      </div>
                    ) : (
                      ""
                    )}
                  </td>
                  <td>{moment(item.createddatetime).format("MM/DD/YY")}</td>
                  <td>
                    {item.status === "Invited" && (
                      <div
                        style={{
                          width: "95%",
                        }}
                      >
                        <span className="w-100 tag tag-blue3">
                          <span className="mx-auto my-0">
                            <strong>{item.status}</strong>
                          </span>
                        </span>
                      </div>
                    )}
                    {(item.status === "Rejected" ||
                      item.status === "Deleted" ||
                      item.status === "Deactivate") && (
                      <div
                        style={{
                          // marginLeft: "2rem",
                          // marginRight: "2rem",
                          width: "95%",
                        }}
                      >
                        <span className="w-100 tag tag-red1">
                          <span className="mx-auto my-0">
                            <strong>
                              {item.status === "Deactivate"
                                ? "Deactivated"
                                : item.status === "Deleted"
                                ? "Deleted"
                                : "Rejected"}
                            </strong>
                          </span>
                        </span>
                      </div>
                    )}
                    {item.status === "Pending" && (
                      <div
                        style={{
                          // marginLeft: "2rem",
                          // marginRight: "2rem",
                          width: "95%",
                        }}
                      >
                        <span className="w-100 tag tag-orange1">
                          <span className="mx-auto my-0">
                            <strong>Pending</strong>
                          </span>
                        </span>
                      </div>
                    )}
                    {item.status === "Approved" && (
                      <div
                        style={{
                          // marginLeft: "2rem",
                          // marginRight: "2rem",
                          width: "95%",
                        }}
                      >
                        <span
                          className={
                            item.uadlastLoginTime
                              ? "w-100 tag tag-blue_primary"
                              : "w-100 tag tag-green4"
                          }
                        >
                          <span className="mx-auto my-0">
                            <strong>
                              {item.uadlastLoginTime ? "Active" : item.status}
                            </strong>
                          </span>
                        </span>
                      </div>
                    )}
                  </td>
                  <td>{item.candidateAdded ? "Yes" : "No"}</td>
                  <td>{item.noOfCandidate}</td>
                  <td>
                    {item.uadlastLoginTime
                      ? moment(
                          new Date(item.uadlastLoginTime),
                          "YYYYMMDD"
                        ).format("MM/DD/YYYY")
                      : "NA"}
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

export default ReportTable;
