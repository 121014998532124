import {
  FETCH_ALL_BROADCAST_TEMPLATES_BEGIN,
  FETCH_ALL_BROADCAST_TEMPLATES_SUCCESS,
  FETCH_ALL_BROADCAST_TEMPLATES_FAILURE,
} from "../../actions/broadcast/getBroadCastTemplates";

const initialState = {
  templatesDataLoading: true,
  templatesData: null,
};

const broadCastTemplatesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ALL_BROADCAST_TEMPLATES_BEGIN:
      return {
        ...state,
        templatesDataLoading: true,
        templatesData: null,
      };
    case FETCH_ALL_BROADCAST_TEMPLATES_SUCCESS:
      return {
        ...state,
        templatesData: payload,
        templatesDataLoading: false,
      };
    case FETCH_ALL_BROADCAST_TEMPLATES_FAILURE:
      return {
        ...state,
        templatesData: payload,
        templatesDataLoading: false,
      };
    default:
      return state;
  }
};

export default broadCastTemplatesReducer;
