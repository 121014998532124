import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { post } from "../../../service/ApiService";
import Modal from "react-bootstrap/Modal";
import { get } from "../../../service/ApiService";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";
import logo from "../../../assets/images/High5Logo.png";
import OutsideClickHandler from "react-outside-click-handler";
import SupportModal from "../../layout/SupportModal";
// import LoginUser from "./LoginUser";
import Event from "../../../googleanalytic/Tracking";
import ReactGA from "react-ga";
import { getUserName, getUserRole } from "../../../service/AuthService";
import LoginUser from "../careers/LoginUser";
// import JobSearch from "./jobdetails/JobSearch";
import { useAuth0 } from "@auth0/auth0-react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

const TopNavbar = (props) => {
  const { user, loginWithRedirect, logout, isAuthenticated } = useAuth0();

  const TRACKING_ID = "UA-223748254-1";
  ReactGA.initialize(TRACKING_ID);
  const { tenantData } = props;
  const { tenant } = useParams();
  const history = useHistory();
  const [login, setLogin] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    url: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [pageReRender, setPageReRender] = useState(true); //use this state in to rerender the page , setPageReRender to !pageReRender and use in useEffect's dependency
  const [toggleHelp, setToggleHelp] = useState(false);
  const [supportModal, setSupportModal] = useState(false);
  const [supportSearchValue, setSupportSearchValue] = useState("");
  const [enableSupportSearch, setEnableSupportSearch] = useState(false);
  const [supportData, setSupportData] = useState([]);
  const [support, setSupport] = useState([]);
  const [supportType, setSupportType] = useState(0);
  const [candidateName, setCandidateName] = useState("");
  const [getActiveTab, setActiveTab] = useState(props.tab);
  const [changeColor, setChangeColor] = useState(false);
  const [changeColor2, setChangeColor2] = useState(false);
  const handleLogin = (name, url, email) => {
    setLogin(false);
    setEmail("");
    setPassword("");
    localStorage.setItem("isLoggedIn", "Yes");
    localStorage.setItem("name", name);
    localStorage.setItem("url", url);
    localStorage.setItem("email", email);
    setPageReRender(!pageReRender);
    setToggle(false);
  };

  const showPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    setCandidateName(props.candidateName);
  }, [props.candidateName]);

  useEffect(() => {
    if (!props?.isLoggedIn && props?.tab === "Offers") {
      setLogin(true);
    }
  }, [props?.isLoggedIn, props?.tab]);

  const splitMatchedText = (str) => {
    const regExp = new RegExp(supportSearchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='background: blue; color: white;'>${match}</span>`
    );
  };

  const openSupportModal = (e) => {
    e.preventDefault();
    setSupportType(+Object.values(e.target)[0].key);
    setSupportModal(true);
  };

  useEffect(() => {}, [pageReRender]);
  const isLoggedIn = () => localStorage.getItem("isLoggedIn");

  useEffect(() => {
    fetchSupportAPI(false);
  }, []);

  const handleLogout = () => {
    Event(
      "Logout",
      `${sessionStorage.getItem("name")},Candidate`,
      window.location.pathname
    );
    setToggle(false);
    setLogin(false);
    ReactGA.event({
      user: getUserName(),
      role: getUserRole(),
      time: new Date(),
    });
    props.isFrom == "jobDescription" && props.setUserLoggedIn(false);
    sessionStorage.clear();
    localStorage.clear();
    history.push(`/careers/joblist`);
    // logout({
    //   logoutParams: { returnTo: window.location.origin },
    // });
    props?.parent === "CandidateProfile" && props.setIsLoggedIn(false);
    props?.parent === "CandidateProfile" && history.push(`/careers/joblist`);
  };

  const getName = () => sessionStorage.getItem("name");
  const getURL = () => sessionStorage.getItem("url");
  const getEmail = () => sessionStorage.getItem("email");
  const fetchSupportAPI = async (isAdmin) => {
    setLoading(true);
    const res = await get("/support");
    setSupport(res.data.filter((s) => s.fk_role.includes(2)));
    setLoading(false);
  };
  const closeSupportModal = () => {
    setSupportModal(false);
  };

  // Login user

  const loginUser = async () => {
    const params = { userName: email, password: password };

    setLoading(true);
    const res = await post("/candidateLogin/", params)
      .then((res) => {
        setLoading(false);
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("name", res.data.name);
        sessionStorage.setItem("userId", res.data.userId);
        sessionStorage.setItem("id", res.data.id);
        sessionStorage.setItem("userName", res.data.userName);
        props?.parent === "CandidateProfile" && props.setIsLoggedIn(true);
        setLogin(false);
        setEmail("");
        setPassword("");

        if (getActiveTab === "Offers") {
          window.open(`/careers/1/profile/?tab=Offers`);
        } else {
          window.open(`/careers/${tenant}/profile`, "_self");
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Please provide correct email and password");
      });
  };

  const handleSupportSearch = (e) => {
    setSupportSearchValue(e.target.value);
    setEnableSupportSearch(true);
    setSupportData(
      support.filter((i) =>
        i.Title.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };
  // forgot password
  const forgetPassword = async (e) => {
    e.preventDefault();
    window.open(`/careers/forgot-passwordDS`, "_blank");
  };

  useEffect(() => {}, [props.renderTopNavBar]);

  return (
    <>
      {loading && <Loader />}
      <div className="header">
        <div
          className="pt-2 pb-2"
          style={{
            background: "#FFFFFF",
            boxShadow: "0px 16px 30px rgba(0, 0, 0, 0.15)",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "stretch",
          }}
        >
          <div className="">
            <img
              className="ml-5 my-auto"
              style={{ height: "40px", width: "75px", flexGrow: 1 }}
              src={logo}
              alt="logo"
            />
          </div>
          <div className="" style={{ marginTop: "5px", flexGrow: 8 }}>
            <div
              className="d-flex justify-content-betweeen align-items-center"
              style={{
                gap: "80px",
                marginTop: "11px",
              }}
            >
              {props.isFrom != "newCareerPage" && (
                <div
                  style={{ cursor: "pointer" }}
                  onMouseEnter={() => setChangeColor(true)}
                  onMouseLeave={() => setChangeColor(false)}
                  onClick={() => window.location.replace("/careers/joblist")}
                >
                  <p
                    style={{
                      marginTop: "4px",
                      color: changeColor ? "blue" : "gray",
                    }}
                  >
                    Search Job
                  </p>
                </div>
              )}
              {sessionStorage.getItem("userId") !== null && (
                <div
                  style={{
                    cursor: "pointer",
                    color: changeColor2 ? "blue" : "gray",
                  }}
                  onMouseEnter={() => setChangeColor2(true)}
                  onMouseLeave={() => setChangeColor2(false)}
                  onClick={() => window.location.replace("/careers/profile")}
                >
                  <p>Dashboard</p>
                </div>
              )}
              {/* {props.isFrom !== "jobDescription" && (
                <JobSearch
                  setJobs={props.setJobs}
                  setSearchJob={props.setSearchJob}
                  filterJobs={props.filterJobs}
                />
              )} */}
            </div>
          </div>
          <div className="text-right" style={{ flexGrow: 1 }}>
            {sessionStorage.getItem("token") === null &&
            localStorage.getItem("url") === null ? (
              <div className="ml-auto my-2 mx-4">
                <button
                  className="btn btn-sm mr-5 my-auto"
                  onClick={() => setLogin(true)}
                >
                  Login
                </button>
              </div>
            ) : (
              <div className="extras ml-auto text-right mr-3">
                <button
                  type="button"
                  title="Support"
                  className="btn btn-action-panel btn-icon btn-nav mr-3"
                  onClick={() => setToggleHelp(!toggleHelp)}
                >
                  {/* <i class="fal fa-bell"></i> */}
                </button>
                {toggleHelp && (
                  <div
                    className="action-panel"
                    style={{
                      display: "revert",
                      right: "0px",
                      top: "50px",
                      textAlign: "left",
                    }}
                  >
                    <OutsideClickHandler
                      onOutsideClick={() =>
                        !supportModal && setToggleHelp(false)
                      }
                    >
                      <div></div>
                      <div
                        className="action-panel"
                        style={{
                          display: "revert",
                          right: "0px",
                          top: "50px",
                          textAlign: "left",
                        }}
                      >
                        <div className="py-3 px-4">
                          <p className="lead mb-0">Hello {candidateName}</p>
                          <strong>What can we help you with today?</strong>

                          <div className="search-field mb-3 mt-2">
                            <div className="field-wrap">
                              <input
                                type="search"
                                onChange={handleSupportSearch}
                                placeholder="Search Support"
                              />
                              <span className="icon">
                                <i
                                  aria-hidden="true"
                                  className="fas fa-search"
                                ></i>
                              </span>
                            </div>
                          </div>
                          <strong>
                            <small>Common Questions</small>
                          </strong>
                          <ul className="mt-1 ml-0 list-unstyled">
                            {enableSupportSearch
                              ? supportData.length !== 0
                                ? supportData.map((s, i) => (
                                    <li>
                                      <a
                                        key={i}
                                        onClick={openSupportModal}
                                        href=""
                                        dangerouslySetInnerHTML={{
                                          __html: splitMatchedText(s.Title),
                                        }}
                                      ></a>
                                    </li>
                                  ))
                                : "No Questions available "
                              : support.length !== 0
                              ? support.map((s, i) => (
                                  <li>
                                    <a
                                      key={i}
                                      onClick={openSupportModal}
                                      href=""
                                    >
                                      {s.Title}
                                    </a>
                                  </li>
                                ))
                              : "No Questions available "}
                          </ul>
                        </div>
                      </div>
                    </OutsideClickHandler>
                  </div>
                )}{" "}
                <button
                  className="btn btn-icon dropdown-toggle"
                  type="button"
                  onClick={() => {
                    setToggle(!toggle);
                  }}
                >
                  <Avatar
                    className="avatar avatar-md mr-1"
                    name={
                      sessionStorage.getItem("token") == null
                        ? localStorage.getItem("name")
                        : sessionStorage.getItem("name")
                    }
                    round="90px"
                    src={getURL()}
                  />
                </button>
                {toggle && (
                  <OutsideClickHandler onOutsideClick={() => setToggle(false)}>
                    <div
                      className="dropdown-menu"
                      style={{
                        display: "revert",
                        right: "-23px",
                        top: "50px",
                      }}
                    >
                      {props.parent !== "CandidateProfile" &&
                        sessionStorage.getItem("userId") !== null && (
                          <>
                            <Link
                              to={`/careers/profile/edittalent`}
                              className="dropdown-item"
                            >
                              <i className="fal fa-user mr-3"></i> Profile{" "}
                            </Link>
                            <hr />
                          </>
                        )}
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleLogout();
                        }}
                        href="/"
                        className="dropdown-item"
                      >
                        <i className="fal fa-sign-out mr-3"></i> Logout{" "}
                      </a>
                    </div>
                  </OutsideClickHandler>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      {supportModal && (
        <SupportModal
          supportData={support[supportType]}
          closeSupportModal={closeSupportModal}
        />
      )}
      <LoginUser
        login={login}
        setLogin={setLogin}
        getActiveTab={getActiveTab}
        setIsLoggedIn={props.setIsLoggedIn}
        setUserLoggedIn={props.setUserLoggedIn}
        isFrom={props.isFrom}
      />
    </>
  );
};

export default TopNavbar;
