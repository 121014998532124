import React, { useState, useEffect } from "react";
import TopNavBar from "../../layout/CandidateNavBar";
import SideNav from "../../layout/SideNavBar";
import Modal from "react-bootstrap/Modal";
import CandidateRequestDetails from "./CandidateRequestDetails";
import { get, post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { domain } from "../../../constants/Constants";
import moment from "moment";
import FilterJobs from "./FilterJobs";
import { TiArrowSortedDown } from "react-icons/ti";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";

function SearchJobs() {
  const [toggle, setToggle] = useState(true);
  const [matched, setMatched] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState(false);
  const [jobDetails, setJobDetails] = useState([]);
  const [type, setType] = useState("");
  const [openSidePanel, setOpenSidePanel] = useState(false);
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [jobListData, setJobListData] = useState([]);
  const [search, setSearch] = useState("");
  const [details, setDetails] = useState({});

  const fetchAutoMatchedJobs = async () => {
    setLoading(true);
    try {
      let res = await get("/CandidateDashboard/postJobautomatchMatch");
      setFiltered(res.data);
      getRequestList(res.data);
    } catch (err) {
      toast.error("Failed to get Candidate Jobs");
    }
    setLoading(false);
  };

  const getRequestList = async (automatch) => {
    setLoading(true);
    const params = {
      skip: 0,
      domain: domain,
    };
    try {
      const res = await post(`/requestlist/getallrequestslist`, params);
      const result = res.data.jobData
        .filter(
          (item) => ["open", "active"].includes(item.jobStatus) && item.isPublic
        )
        .sort((a, b) => {
          let fa = new Date(a.jobPostDate),
            fb = new Date(b.jobPostDate);
          if (fa > fb) {
            return -1;
          }
          if (fa < fb) {
            return 1;
          }
          return 0;
        });
      let finalResult = [...result];
      if (automatch.length > 0) {
        let ids = automatch.map((i) => i.jobId);
        finalResult = [...finalResult.filter((i) => !ids.includes(i.jobId))];
      }
      setJobListData(finalResult);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  const fetchDetails = async () => {
    try {
      const res = await get("/candidateProfile/fetchCandidateData");
      setDetails(res.data[0]);
    } catch (err) {
      toast.error("Failed to get Profile Details");
    }
  };

  useEffect(() => {
    let value = localStorage.getItem("search");
    if (value) {
      setSearch(value);
    }
    fetchAutoMatchedJobs();
    fetchDetails();
  }, []);

  const openModal = (item, type) => {
    setJobDetails({ ...item, weightage: JSON.parse(item.weightage) });
    setType(type);
    setOpenSidePanel(true);
  };

  const applyFilter = (selectedTitle, selectedType) => {
    setSelectedTitles(selectedTitle);
    setSelectedTypes(selectedType);
    if (selectedTitle.length > 0 && selectedType.length > 0) {
      setFiltered(
        matched.filter(
          (job) =>
            selectedTitle.includes(job.jobTitle) &&
            selectedType.includes(job.jobType)
        )
      );
    } else if (selectedTitle.length > 0 && selectedType.length === 0) {
      setFiltered(
        matched.filter((job) => selectedTitle.includes(job.jobTitle))
      );
    } else if (selectedType.length > 0 && selectedTitle.length === 0) {
      setFiltered(matched.filter((job) => selectedType.includes(job.jobType)));
    } else {
      setFiltered(matched);
    }
    setFilter(false);
  };

  const formatCurrency = (currency) =>
    currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <>
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div class="body-decorator body-decorator-top"></div>
        <div class="body-decorator body-decorator-bottom"></div>
        <TopNavBar
          handleToggale={() => setToggle(!toggle)}
          search={search}
          setSearch={setSearch}
        />
        <SideNav />
        <div id="Content">
          <section id="AssessmentsApp" class="section section-sm">
            <div class="container">
              <div>
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom-end"
                  overlay={
                    <div className="dropdown-menu dropdown-menu-right">
                      <div className="d-flex align-items-center mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">On-site</small>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">Remote</small>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">Hybrid</small>
                      </div>
                    </div>
                  }
                >
                  <button
                    style={{ color: "black", borderColor: "grey" }}
                    className="btn btn-secondary rounded-pill"
                  >
                    <strong>
                      On-site/Remote <TiArrowSortedDown />
                    </strong>
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom-end"
                  overlay={
                    <div className="dropdown-menu dropdown-menu-right">
                      <div className="d-flex align-items-center mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">Full Time</small>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">Contract</small>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">Part Time</small>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">Temp to Hire</small>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">Volunteer</small>
                      </div>
                    </div>
                  }
                >
                  <button
                    style={{ color: "black", borderColor: "grey" }}
                    className="btn btn-secondary rounded-pill ml-2"
                  >
                    <strong>
                      Job Type <TiArrowSortedDown />
                    </strong>
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  trigger="click"
                  rootClose
                  placement="bottom-end"
                  overlay={
                    <div className="dropdown-menu dropdown-menu-right">
                      <div className="d-flex align-items-center mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">Intern</small>
                      </div>
                      <div className="d-flex align-items-center mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">Less than one year</small>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">1-3 years</small>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">3-5 years</small>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">5-10 years</small>
                      </div>
                      <div className="d-flex align-items-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem" }}
                        />
                        <small className="m-0 ml-2">10+ years</small>
                      </div>
                    </div>
                  }
                >
                  <button
                    style={{ color: "black", borderColor: "grey" }}
                    className="btn btn-secondary rounded-pill ml-2"
                  >
                    <strong>
                      Experience Level <TiArrowSortedDown />
                    </strong>
                  </button>
                </OverlayTrigger>
              </div>
              <div className="card card-flat bg-gray4 mt-3">
                <div className="card-body">
                  <div class="col">
                    {jobListData
                      .filter((item) =>
                        item.jobTitle
                          .toLowerCase()
                          .includes(search.toLowerCase())
                      )
                      .map((item) => (
                        <a
                          onClick={() => {
                            openModal(item, "search");
                          }}
                          class="card card-flat m-2"
                        >
                          <div class="card-body">
                            <h6 class="mt-1">{item.jobTitle}</h6>
                            <div class="d-flex flex-wrap align-items-center">
                              <span class="d-flex align-items-center">
                                <i
                                  aria-hidden="true"
                                  class="far fa-suitcase me-2 font-brandBlue"
                                ></i>
                                <span class="font-regular font-primary font-gray1">
                                  {item.jobType}
                                </span>
                              </span>
                              {item.location.address && (
                                <span class="d-flex align-items-center ml-3">
                                  <i
                                    aria-hidden="true"
                                    class="far fa-map-marker-alt me-2 font-brandBlue"
                                  ></i>
                                  <span class="font-regular font-primary font-gray1">
                                    {item.location.address}
                                  </span>
                                </span>
                              )}
                              <span class="d-flex align-items-center ml-3">
                                <i
                                  aria-hidden="true"
                                  class="far fa-piggy-bank me-2 font-brandBlue"
                                ></i>
                                <span class="font-regular font-primary font-gray1">
                                  {item.minimumPay +
                                    "-" +
                                    item.maximumPay +
                                    " " +
                                    item.salaryCurrency}
                                </span>
                              </span>
                            </div>{" "}
                            <div>
                              <span class="d-flex align-items-center mt-2">
                                <span class="font-regular">
                                  {moment(
                                    new Date(item.jobPostDate),
                                    "YYYYMMDD"
                                  ).fromNow()}
                                </span>
                              </span>
                            </div>
                          </div>
                        </a>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
      {openSidePanel && (
        <CandidateRequestDetails
          type={type}
          job={jobDetails}
          closeModal={() => setOpenSidePanel(false)}
          fetchData={fetchAutoMatchedJobs}
          details={details}
        />
      )}
      {filter && (
        <FilterJobs
          requestTitles={requestTitles}
          requestTypes={requestTypes}
          selectedTitles={selectedTitles}
          selectedTypes={selectedTypes}
          applyFilter={applyFilter}
        />
      )}
    </>
  );
}
export default SearchJobs;
