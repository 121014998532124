import {
  SKILL_PAGINATION,
  LOCATION_PAGINATION,
  DESIGNATION_PAGINATION,
  SOURCE_PAGINATION,
  WORK_TYPE_PAGINATION,
  WORK_AUTH_PAGINATION,
  RECRUITER_PAGINATION,
} from "../../actions/talent/Pagination";

const initialState = {
  skillPageNumber: 1,
  locationPageNumber: 1,
  designationPageNumber: 1,
  sourcePageNumber: 1,
  workTypePageNumber: 1,
  workAuthPageNumber: 1,
  recruiterPageNumber: 1,
};

const getPageNumber = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case SKILL_PAGINATION:
      return {
        ...state,
        skillPageNumber: payload,
      };
    case LOCATION_PAGINATION:
      return {
        ...state,
        locationPageNumber: payload,
      };
    case DESIGNATION_PAGINATION:
      return {
        ...state,
        designationPageNumber: payload,
      };
    case SOURCE_PAGINATION:
      return {
        ...state,
        sourcePageNumber: payload,
      };
    case WORK_TYPE_PAGINATION:
      return {
        ...state,
        workTypePageNumber: payload,
      };
    case WORK_AUTH_PAGINATION:
      return {
        ...state,
        workAuthPageNumber: payload,
      };
    case RECRUITER_PAGINATION:
      return {
        ...state,
        recruiterPageNumber: payload,
      };

    default:
      return state;
  }
};
export default getPageNumber;
