import {
  GET_ASSESSMENT_BEGIN,
  GET_ASSESSMENT_SUCCESS,
  GET_ASSESSMENT_FAILURE,
} from "../../actions/vetting/getAssessments";

const initialState = {
  loading: false,
  assessmentData: null,
  assessmentCount: null,
  errors: null,
};

const vettingAssessmentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ASSESSMENT_BEGIN:
      return {
        ...state,
        loading: true,
        assessmentData: null,
      };
    case GET_ASSESSMENT_SUCCESS:
      return {
        ...state,
        assessmentData: payload.data,
        assessmentCount: payload.count,
        loading: false,
      };
    case GET_ASSESSMENT_FAILURE:
      return {
        ...state,
        errors: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default vettingAssessmentReducer;
