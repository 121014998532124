import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { getUserRole, getUserTenantID } from "../../../../service/AuthService";
import { get, post } from "../../../../service/ApiService";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import ShortUniqueId from "short-unique-id";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Avatar from "react-avatar";
import moment from "moment";
import CustomProgressBar from "./CustomProgressBar";
import { toast } from "react-toastify";
import Loader from "../../commoncomponents/Loader/Loader";
import Pagination from "../../../components_v3/pagination/pagination";
import { FaTimes } from "react-icons/fa";
const SubCommModal = ({
  showjobModal,
  setShowJobModal,
  selectedTalents,
  setSelectedTalents,
  tenantId,
  jobData,
}) => {
  const [pools, setPools] = useState([]);
  const [filteredPools, setFilteredPools] = useState([]);
  const [paginatedPools, setPaginatedPools] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPoolFromDB(1);
  }, [pageSize]);

  const getPoolFromDB = async (pageNumber) => {
    setLoading(true);
    const payload = {
      currentPage: pageNumber ? pageNumber : currentPage,
      sortQuery: [],
      pageSize: pageSize,
      searchText: "",
      type: "",
      clientId: tenantId,
    };

    try {
      const res = await post("/talent/getpoollist", payload);
      let poolArray = res.data.data.sort((a, b) => +b.id_pool - +a.id_pool);
      setTotalCount(res.data.total)
      setPools(poolArray);
      setFilteredPools(poolArray);
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    let searchPoolName = e.target.value;
    setSearchQuery(searchPoolName);
    const filteredArray = pools.filter(
      (pool) =>
        pool.poolname.toLowerCase().includes(searchPoolName.toLowerCase()) ||
        (pool.poolId + "").includes(searchPoolName.toLowerCase())
    );

    setFilteredPools(filteredArray);
  };

  const handleSelectAll = () => {
    let data = pools.map((x) => ({ poolId: x.poolId, poolname: x.poolname }));
    setSelectedJobs(data);
  };

  const handleInvite = () => {
    try {
      setLoading(true);

      let responseData = selectedTalents.map((eachData) => {
        let uid = new ShortUniqueId({ length: 10 });
        eachData.InviteURLId = uid();
        return eachData;
      });

      let payload = {
        candidates: responseData,
        pools: selectedJobs,
        tenantId: tenantId,
        communityName: jobData?.poolname,
        clientName: jobData?.tenantname,
      };
      post("/bulkTalentAction/bulkTalentSubCommunity", payload).then((res) => {
        setLoading(false);
        setShowJobModal(false);
        setSelectedTalents([]);
        if (res.data.message === "Talents Exists") {
          toast.success(
            `Talent${
              selectedTalents.length > 1 ? "s" : ""
            } already exist in this community.`
          );
        } else {
          toast.success(
            `Talent${
              selectedTalents.length > 1 ? "s" : ""
            } moved to community successfully.`
          );
        }
      });
    } catch (error) {
      toast.error("Something went wrong");
      setLoading(false);
    }
  };

  const togglePoolSelect = (item) => {
    let poolId = item?.poolId;
    let poolname = item?.poolname;

    let data = {
      poolId: poolId,
      poolname: poolname,
    };

    setSelectedJobs([data]);
    // setSelectedJobs((prev) =>
    //   prev.some((x) => x.poolId === poolId)
    //     ? prev.filter((c) => c.poolId != poolId)
    //     : [...prev, data]
    // );
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
    getPoolFromDB(selectedPage);
  };
  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
  };
  const clearSearch = () => {
    setSearchQuery("");
    setFilteredPools(pools);
  };
  return (
    <Modal show={showjobModal} size="lg">
      <div className="modal-header" id="NewCandidateModal">
        <h6>Select Community</h6>
        {showjobModal && (
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowJobModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
      <Modal.Body>
        <div className="row d-block-force  p-4">
          {loading && <Loader />}
          <div className=" d-flex gap-2  align-items-center justify-content-end mb-2">
            {" "}
            {/* {pools?.length > 0 && (
              <>
                {" "}
                <div className="">
                  <button
                    type="button"
                    disabled={selectedJobs.length == pools.length}
                    onClick={handleSelectAll}
                    className="btn btn-text"
                  >
                    <span className="font-bold font-14">Select All</span>
                  </button>
                </div>{" "}
                {selectedJobs.length > 0 && (
                  <div className="">
                    <button
                      type="button"
                      className="btn btn-text"
                      disabled={selectedJobs.length === 0}
                      onClick={() => setSelectedJobs([])}
                    >
                      <span className="font-bold font-14">Deselect All</span>
                    </button>
                  </div>
                )}
              </>
            )} */}
            <div className="">
              <select
                className="form-select font-14"
                onChange={handlePageSizeChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
            <div
              className="input-group"
              style={{ border: "1px solid #ddd", width: "200px" }}
            >
              <input
                type="text"
                className="form-control ml-1"
                placeholder="Search by Title, Id"
                style={{ border: "1px solid transparent" }}
                value={searchQuery}
                onChange={handleSearch}
              />

              {searchQuery && (
                <div className="input-group-append">
                  <button
                    className="btn"
                    type="button"
                    style={{
                      backgroundColor: "white",
                      border: "1px solid transparent",
                      boxShadow: "none",
                    }}
                    onClick={clearSearch}
                  >
                    <FaTimes color={"grey"} />
                  </button>
                </div>
              )}
            </div>
          </div>
          {pools?.map((item, index) => (
            <div className="mb-2" key={index}>
              <div className="card ">
                <div className="card-body p-3">
                  <div className=" d-flex align-items-start justify-content-between">
                    <div className="d-flex align-items-center justify-content-between  w-100">
                      <div className="text-left">
                        <p>
                          <a
                            className="text-black"
                            href={`/high5hire/talentcommunity/pools/${item.id_pool}`}
                          >
                            <strong>
                              {item?.poolname} ({item.poolId})
                            </strong>{" "}
                          </a>

                          <br />
                          <span>
                            <small>{item?.country}</small>
                          </span>
                        </p>
                      </div>
                      <div className=""></div>
                    </div>
                    {/* <div className="d-flex  align-items-center align-self-end justify-content-end mb-1"> */}
                    <input
                      type="checkbox"
                      className="form-check-input"
                      style={{ width: "1.5rem", borderRadius: "100" }}
                      checked={selectedJobs.some(
                        (x) => x.poolId == item.poolId
                      )}
                      onClick={() => {
                        togglePoolSelect(item);
                      }}
                    />
                  </div>

                  <div className="d-flex justify-content-between">
                    <div className="text-muted my-auto p-0">
                      <span>Created by: {item?.poolowner}</span>
                    </div>

                    <div className="text-muted my-auto p-0">
                      Created on:{" "}
                      <span>
                        {moment(item.createddatetime).format("MM/DD/YYYY")}
                      </span>
                      &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {/* {filteredPools.length > pageSize && ( */}
          <Pagination
            currentPage={currentPage}
            totalItems={totalCount}
            itemsPerPage={pageSize}
            onPageChange={handlePageChange}
            limit={5}
          />
          {/* )} */}
        </div>
        {pools?.length === 0 && <NoRecord />}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => setShowJobModal(false)}
        >
          Cancel{" "}
        </button>
        <button disabled={selectedJobs.length === 0} onClick={handleInvite}>
          Move
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default SubCommModal;
