import React, { useState, useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import countryList from "react-select-country-list";
import Validator from "email-validator";
import { AsYouType } from "libphonenumber-js";
import Select from "react-select";
import { Form, Container, Row, Col } from "react-bootstrap";
import { domain } from "../../../../constants/Constants";
import {
  getUserTenantID,
  getTenantType,
} from "../../../../service/AuthService";
import Loader from "../../../Loader/Loader";
import { post } from "../../../../service/ApiService";
import SkillsSearchable from "../../commoncomponents/skills/SkillsSearchable";
import { getPhone } from "../../../function/CommonFunction";
import { getSubCommunity } from "../../../../actions/common/GetSubCommunity";

function EditEmployee(props) {
  const dispatch = useDispatch();
  const { employeeData } = props;
  const clientid = getUserTenantID();
  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    phoneCode: "",
    clientId: clientid,
    role: "",
    designation: "",
    hostedUrl: "",
    domainName: domain,
    CountryList: [],
    Skills: [],
    isAdmin: false,
  });
  const [showOptional, setShowOptional] = useState(false);
  const option = useMemo(() => countryList().getData(), []);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [activeRoles, setActiveRoles] = useState([]);
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  const [subCommunityValues, setSubCommunityValues] = useState([]);
  const [subCommunityOptions, setSubCommunityOptions] = useState([]);
  let subCommunityData = employeeData?.subCommunityDetails
    ? JSON.parse(employeeData?.subCommunityDetails)
    : [];
  const customStyles = {
    // Add custom styles here
    control: (provided) => ({
      ...provided,
      borderColor: errors.subCommunity ? "red" : provided.borderColor,
    }),
    input: (provided) => ({
      ...provided,
      zIndex: 1000, // Set z-index to -1000
    }),
  };

  const { subCommunityLoading, subCommunityDetails } = useSelector(
    (state) => ({
      subCommunityLoading: state.getSubCommunityReducer.loading,
      subCommunityDetails: state.getSubCommunityReducer.subCommunity,
    }),
    shallowEqual
  );

  const handleChangeInputPrimarySkills = (skills) => {
    setPrimarySkillInput(skills);
  };

  const fetchData = async () => {
    try {
      let Roles = [];
      if (getTenantType() == 1) {
        Roles = ["HiringManager"];
      }
      if (getTenantType() == 2) {
        Roles = ["Recruiter"];
      }
      setActiveRoles(Roles);
    } catch (error) {
      console.log(error);
    }
  };

  const findFormErrors = () => {
    const {
      lastName,
      email,
      firstName,
      role,
      designation,
      CountryList,
      Skills,
      isAdmin,
    } = form;
    const newErrors = {};
    if (!firstName || firstName === "")
      newErrors.firstName = "First Name  cannot be blank!";
    else if (!/^[A-Z a-z\s]+$/.test(firstName))
      newErrors.firstName = "Invalid First Name ";
    if (!designation || designation === "")
      newErrors.designation = "Designation  cannot be blank!";
    else if (!/^[A-Z a-z\s]+$/.test(designation))
      newErrors.designation = "Invalid Designation ";
    // if (!role || role === "") newErrors.role = "Role is required !";
    // if (!activeRoles.includes(role)) newErrors.role = "Role is not Active !";
    if (!lastName || lastName === "")
      newErrors.lastName = "Last Name cannot be blank!";
    else if (!/^[A-Z a-z\s]+$/.test(lastName))
      newErrors.lastName = "Invalid Last Name ";
    if (!email || email === "") newErrors.email = "Email id cannot be Blank";
    else if (!Validator.validate(email)) newErrors.email = "Invalid Email id !";
    if (!phoneValidation) {
      newErrors.phone = "Invalid Phone";
    }
    if (
      (!subCommunityValues || subCommunityValues.length < 1) &&
      getTenantType() == 2 &&
      !isAdmin
    )
      newErrors.subCommunity = "Community cannot be blank";
    return newErrors;
  };

  const handleSubmit = async () => {
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setLoading(true);
        const paramters = {
          ...form,
          userId: employeeData.userId,
          subCommuntity:
            getTenantType() == 2
              ? form?.isAdmin
                ? []
                : subCommunityValues.includes("0")
                ? []
                : JSON.stringify(subCommunityValues.map((item) => item.value))
              : [],
        };
        const res = await post("/employee/updateemployee", paramters);
        setLoading(false);
        if (res.status === 200) {
          const result = res.data;
          if (result.ReturnText == "Successfully Updated") {
            toast.success("User updated successfully ");
            const empdata = {
              name: form.firstName + " " + form.lastName,
              email: form.email,
              designation: form.role,
            };
            props.submitfun(empdata);
          } else if (result.MessageText === "User Exist") {
            toast.error("User alredy exist with this email id");
          } else {
            toast.error("Error Occured while saving User.");
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error Occured While saving User.");
      }
    }
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  useEffect(() => {
    fetchData();
    dispatch(getSubCommunity());
  }, []);

  useEffect(() => {
    if (subCommunityDetails && subCommunityDetails.length > 0) {
      let subCommunityOption = [
        { value: "0", label: "None", isDisabled: false },
      ];
      if (subCommunityData.length > 0) {
        setSubCommunityValues(
          subCommunityData.map((item) => ({
            value: item.poolId,
            label: `${item.poolname} (${item.poolId})`,
          }))
        );
        subCommunityOption = [{ value: "0", label: "None", isDisabled: true }];
      }
      subCommunityOption = [
        ...subCommunityOption,
        ...subCommunityDetails.map((item) => ({
          value: item.poolId,
          label: `${item.poolname} (${item.poolId})`,
          isDisabled: false,
        })),
      ];
      setSubCommunityOptions(subCommunityOption);
    }
  }, [subCommunityDetails]);

  useEffect(() => {
    if (Object.keys(employeeData).length != 0) {
      setForm({
        firstName: employeeData.firstName,
        lastName: employeeData.lastName,
        email: employeeData.email,
        phone: employeeData.phone,
        phoneCode: employeeData.phoneCode,
        designation: employeeData.designation,
        role: employeeData.role,
        userId: employeeData.userId,
        domainName: domain,
        clientId: clientid,
        Skills: JSON.parse(employeeData.primarySkills),
        CountryList: JSON.parse(employeeData.countryList),
        isAdmin: employeeData.isOwner,
      });
    }
  }, [employeeData]);

  const _onChangeText = (value, code) => {
    const asYouType = new AsYouType(code);
    asYouType.input(value);
    setPhoneValidation(asYouType.isValid());
  };

  const setPhone = (phone, phoneCode) => {
    setForm({
      ...form,
      phone: phone,
      phoneCode: phoneCode,
    });
  };

  const handleSubcummunity = (e) => {
    if (e.length > 0) {
      const updatedOptions = subCommunityOptions.map((element) => {
        if (e[0].value === "0") {
          if (element.value !== "0") {
            return {
              ...element,
              isDisabled: true,
            };
          }
        } else {
          if (element.value === "0") {
            return {
              ...element,
              isDisabled: true,
            };
          }
        }
        return element;
      });
      setSubCommunityOptions(updatedOptions);
      setSubCommunityValues(e);
    } else {
      const updatedOptions = subCommunityOptions.map((element) => {
        return {
          ...element,
          isDisabled: false,
        };
      });
      setSubCommunityOptions(updatedOptions);
      setSubCommunityValues(e);
    }
  };

  return (
    <>
      {(loading || subCommunityLoading) && <Loader />}
      <div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="form-floating mb-2">
  <Form.Control
    type="text"
    onChange={(e) => setField("role", "Hiring Manager")}
    isInvalid={!!errors.role}
    value="Hiring Manager"
    readOnly
  />
  <label>
    Role<span className="font-red font-small">*</span>
  </label>
  <Form.Control.Feedback type="invalid">
    {errors.role}
  </Form.Control.Feedback>
</div>

          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-floating mb-2">
              <Form.Control
                type="text"
                placeholder="Enter Designation"
                onChange={(e) => setField("designation", e.target.value)}
                isInvalid={!!errors.designation}
                value={form.designation}
              />

              <label for="designation">
                Designation <span className="font-red font-small">*</span>
              </label>
              <Form.Control.Feedback type="invalid">
                {errors.designation}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="form-floating mb-2">
              <Form.Control
                type="text"
                placeholder="Enter First Name"
                onChange={(e) => setField("firstName", e.target.value)}
                isInvalid={!!errors.firstName}
                value={form.firstName}
              />

              <label for="firstName">
                First Name <span className="font-red font-small">*</span>
              </label>
              <Form.Control.Feedback type="invalid">
                {errors.firstName}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="form-floating mb-2">
              <Form.Control
                type="text"
                placeholder="Enter Last Name"
                onChange={(e) => setField("lastName", e.target.value)}
                isInvalid={!!errors.lastName}
                value={form.lastName}
              />
              <label for="lastName">
                Last Name
                <span className="font-red font-small">*</span>
              </label>
              <Form.Control.Feedback type="invalid">
                {errors.lastName}
              </Form.Control.Feedback>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="form-floating mb-2">
              <Form.Control
                type="text"
                placeholder="Enter email"
                onChange={(e) => setField("email", e.target.value)}
                isInvalid={!!errors.email}
                value={form.email}
                disabled={true}
              />

              <label>
                Email ID <span className="font-red font-small">*</span>
              </label>
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="form-floating mb-2">
              <PhoneInput
                classsName="form-control"
                placeholder="Phone"
                country={"us"}
                name="Phone No"
                value={getPhone(form.phone, form.phoneCode)}
                onChange={(value, country, e) => {
                  setPhone(value, country.dialCode);
                  _onChangeText(
                    value.replace(country.dialCode, ""),
                    country.countryCode.toUpperCase()
                  );
                }}
                isInvalid={!!errors.phone}
                inputStyle={{
                  width: "260px",
                  height: "56px",
                }}
              />
            </div>
            {!phoneValidation && (
              <small className="validation">{"Invalid Phone"}</small>
            )}
          </div>
          {showOptional && (
            <div className="row">
              <div className="col-lg-6 col-md-12 col-sm-12">
                <label class="pt-0">
                  Select Skills
                  <span className="font-red font-small">*</span>
                </label>
                {/* <TagsInput
                  renderInput={autocompleteRenderInput}
                  value={form.Skills}
                  onChange={(value) => {
                    setField("Skills", value);
                  }}
                  placeholder="Add a skill"
                  inputProps={{
                    placeholder: "Add a skill",
                  }}
                /> */}
                <SkillsSearchable
                  onChange={(value) => {
                    setField("Skills", value);
                  }}
                  inputArr={form.Skills}
                  skillinput={primarySkillinput}
                  onChangeInput={handleChangeInputPrimarySkills}
                  inputProps={{
                    placeholder: "Add Skill",
                  }}
                  isFrom="EditEmployee"
                />
                <small className="validation invalid">{errors.Skills}</small>
              </div>
              <div
                className="col-lg-6 col-md-12 col-sm-12"
                style={{ paddingLeft: "24px", paddingTop: "5px" }}
              >
                <div className="col-lg-12 text-left">
                  <label className="pt-0">
                    Select Service Country
                    <span className="font-red font-small">*</span>
                  </label>
                </div>
                <Select
                  isMulti
                  value={form.CountryList}
                  options={option}
                  onChange={(value) => {
                    setField("CountryList", value);
                  }}
                  isSearchable={true}
                  name="colors"
                  className="basic-multi-select"
                  classNamePrefix="select"
                  closeMenuOnSelect={false}
                />
                <small className="validation invalid">
                  {errors.CountryList}
                </small>
              </div>
            </div>
          )}
          {getTenantType() == 2 && !form.isAdmin && (
            <Container>
              <Row>
                <Col>
                  <label className="recruitemantLabel">Community*</label>
                  <div
                    className="form-floating hover-Dropdown"
                    style={{ zIndex: "999" }}
                  >
                    <Select
                      isMulti
                      options={subCommunityOptions}
                      value={subCommunityValues}
                      isSearchable={true}
                      styles={customStyles}
                      name="subCommunity"
                      onChange={handleSubcummunity}
                    ></Select>
                  </div>
                  <small className="validation">{errors.subCommunity}</small>
                </Col>
              </Row>
            </Container>
          )}
          <div
            class="form-switch d-flex align-items-center mt-2"
            style={{
              padding: "1em",
            }}
          >
            {/* <label class="form-check-label pt-0">Can Add Employees?</label>
            <input
              class="form-check-input ml-1"
              type="checkbox"
              onChange={(e) => setField("isAdmin", e.target.checked)}
              checked={form.isAdmin}
            /> */}
          </div>
        </div>
        <div className="row">
          <div className="modal-footer mt-4 mb-0">
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-secondary mr-2"
              onClick={() => props.close()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              <span>Update</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default EditEmployee;
