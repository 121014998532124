import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const GET_USER_DATA_BEGIN = "GET_USER_DATA_BEGIN";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const GET_USER_DATA_FAILURE = "GET_USER_DATA_FAILURE";

export const GET_USER_DATA_COUNT_BEGIN = "GET_USER_DATA_COUNT_BEGIN";
export const GET_USER_DATA_COUNT_SUCCESS = "GET_USER_DATA_COUNT_SUCCESS";
export const GET_USER_DATA_COUNT_FAILURE = "GET_USER_DATA_COUNT_FAILURE";

export const getRecruiterUserData = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_DATA_BEGIN,
    });
    const res = await post("/dashboard/sa/getrecruiteruserdata", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_USER_DATA_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_USER_DATA_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getRecruiterUserDataCount = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_DATA_COUNT_BEGIN,
    });
    const res = await post("/dashboard/sa/getrecruiteruserdatacount", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_USER_DATA_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_USER_DATA_COUNT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
