import { toast } from "react-toastify";
import { get } from "../../service/ApiService";

export const FETCH_ALL_BROADCAST_TEMPLATES_BEGIN =
  "FETCH_ALL_BROADCAST_TEMPLATES_BEGIN";
export const FETCH_ALL_BROADCAST_TEMPLATES_SUCCESS =
  "FETCH_ALL_BROADCAST_TEMPLATES_SUCCESS";
export const FETCH_ALL_BROADCAST_TEMPLATES_FAILURE =
  "FETCH_ALL_BROADCAST_TEMPLATES_FAILURE";

export const fetchAllBroadcastTemplates = (param) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_ALL_BROADCAST_TEMPLATES_BEGIN,
    });
    const res = await get("/broadCast/getEmailTemplates");
    if (res.status === 200) {
      dispatch({
        type: FETCH_ALL_BROADCAST_TEMPLATES_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_ALL_BROADCAST_TEMPLATES_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};
