import {
  GET_RECRUITER_FOR_UPDATE_CURATOR_BEGIN,
  GET_RECRUITER_FOR_UPDATE_CURATOR_SUCCESS,
  GET_RECRUITER_FOR_UPDATE_CURATOR_FAILURE,
  GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_BEGIN,
  GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_SUCCESS,
  GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_FAILURE,
  REMOVE_CURATOR_BEGIN,
  REMOVE_CURATOR_SUCCESS,
  REMOVE_CURATOR_FAILURE,
} from "../../actions/curator/UpdateCurator";

const initialState = {
  loading: false,
  countLoading: false,
  communityLoading: false,
  communityData: [],
  updateCuratorData: [],
  updateCuratorCount: [],
  removeCurator: null,
  removeCuratorLoading: false,
};

const getUpdateCuratorReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECRUITER_FOR_UPDATE_CURATOR_BEGIN:
      return {
        ...state,
        updateCuratorData: [],
        loading: true,
      };
    case GET_RECRUITER_FOR_UPDATE_CURATOR_SUCCESS:
      return {
        ...state,
        updateCuratorData: payload,
        loading: false,
      };
    case GET_RECRUITER_FOR_UPDATE_CURATOR_FAILURE:
      return {
        ...state,
        updateCuratorData: [],
        loading: false,
      };
    case GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_BEGIN:
      return {
        ...state,
        updateCuratorCount: [],
        countLoading: true,
      };
    case GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_SUCCESS:
      return {
        ...state,
        updateCuratorCount: payload,
        countLoading: false,
      };
    case GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_FAILURE:
      return {
        ...state,
        updateCuratorCount: [],
        countLoading: false,
      };
    case REMOVE_CURATOR_BEGIN:
      return {
        ...state,
        removeCurator: [],
        removeCuratorLoading: true,
      };
    case REMOVE_CURATOR_SUCCESS:
      return {
        ...state,
        removeCurator: payload,
        removeCuratorLoading: false,
      };
    case REMOVE_CURATOR_FAILURE:
      return {
        ...state,
        removeCurator: [],
        removeCuratorLoading: false,
      };
    default:
      return state;
  }
};

export default getUpdateCuratorReducer;
