import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Table, Row, Col } from "react-bootstrap";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
// import Pagination from "../../commoncomponents/pagination/Pagination";
import { post } from "../../../../service/ApiService";
import Loader from "../../commoncomponents/Loader/Loader";
import { toast } from "react-toastify";
import { shallowEqual, useSelector } from "react-redux";

function AddRecContactsModal(props) {
  const { selectedJob } = useSelector(
    (state) => ({
      selectedJob: state.getJobListReducer.selectedJob,
    }),
    shallowEqual
  );
  const [recFilter, setRecFilter] = useState({
    createdDateFrom: "",
    createdDateTo: "",
    firstName: "",
    lastName: "",
    email: "",
    pageNumber: 1,
    pageSize: 10,
    selectAll: false,
  });
  const [tableData, setTableData] = useState([]);
  const [noData, setNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [totalRecipients, setTotalRecipients] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationArray, setPaginationArray] = useState([]);
  const [btnActive, setBtnActive] = useState(false);
  const [apiCanCalled, setApiCanCalled] = useState(false);
  const [selectRecAll, setSelectRecAll] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [confirm1000, setConfirm1000] = useState(false);
  const dataSize = 10;
  const pageSize = 5;

  // Calculate the index range of jobs to display on the current page
  // const indexOfLastJob = currentPage * dataSize;
  // const indexOfFirstJob = indexOfLastJob - dataSize;
  const startIndex = (currentPage - 1) * dataSize;
  const endIndex = startIndex + dataSize;

  const currentData = tableData.slice(startIndex, endIndex);
  // const currentData = tableData.slice(indexOfFirstJob, indexOfLastJob);

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    if (tableData?.length > 0) {
      setSelectedCount(
        tableData?.filter((item) => item.isChecked === true).length
      );
    }
  }, [tableData]);
  const closeModal = () => {
    props.setShowModal(false);
  };

  const handleCancel = () => {
    closeModal();
  };
  const handleClear = () => {
    setRecFilter({
      createdDateFrom: "",
      createdDateTo: "",
      firstName: "",
      lastName: "",
      email: "",
      pageNumber: 1,
      pageSize: 10,
      selectAll: false,
    });
    setApiCanCalled(false);
  };
  const handleDone = (e) => {
    e.preventDefault();
    // if (selectRecAll) {
    //   props.setContactSelected(tableData);
    // } else {
    // console.log(tableData);
    const selectedContacts = tableData.filter(
      (item) => item.isChecked === true
    );
    props.setContactSelected(selectedContacts);
    //   console.log(selectedContacts);
    // }
    props.setShowModal(false);
  };
  const handleCheckBoxClear = () => {
    setTableData([]);
    props.setContactSelected([]);
    setPaginationArray([]);
    setApiCanCalled(false);
    setTotalRecipients(0);
  };
  const handleCheckBox = (e, item, index) => {
    let d = tableData.map((x, i) => {
      return item.email === x.email ? { ...x, isChecked: !x.isChecked } : x;
    });

    const selectedContacts = d.filter((item) => item.isChecked === true);
    // const uncheckedStatus = d.some((item) => item.isChecked === false);
    // if (uncheckedStatus) {
    //   setSelectRecAll(false);
    // } else {
    //   setSelectRecAll(true);
    // }
    setTableData(d);
    setSelectedCount(selectedContacts.length);
    props.setContactSelected(selectedContacts);
  };
  const handleAllRecipients = async (e) => {
    e.preventDefault();

    try {
      let data = {};

      data = {
        createdDateFrom: recFilter.createdDateFrom || null,
        createdDateTo: recFilter.createdDateTo || null,
        firstName: recFilter.firstName,
        lastName: recFilter.lastName,
        email: recFilter.email,
        userType: props.filterType,
        jobId: selectedJob.jobId,
        selectAll: true,
      };
      setIsLoading(true);
      setConfirm1000(false);
      const response = await post("/smsbroadcast/fetchRecipientsForSms", data);
      if (response.status === 200) {
        if (response?.data?.result?.length > 0) {
          const updatedTableData = response.data.result.map((item, index) => ({
            ...item,
            isChecked: index < 100 ? true : false,
          }));

          setTotalRecipients(response.data.total);
          setTableData(updatedTableData);
          setSelectedCount(updatedTableData.length);
          setIsLoading(false);
          setApiCanCalled(true);
          setNoData(false);
          setSelectRecAll(true);
          setSelectAllChecked(true);
        } else {
          setTotalRecipients(response.data.total);
          setTableData(response.data.result);
          setApiCanCalled(true);
          setIsLoading(false);
          setNoData(true);
          setSelectRecAll(true);
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const validationFunction = () => {
    let isValid = true;
    if (
      recFilter.createdDateFrom === "" &&
      recFilter.createdDateTo === "" &&
      recFilter.firstName === "" &&
      recFilter.lastName === "" &&
      recFilter.email === ""
    ) {
      isValid = false;
      toast.error("Select at least one filter");
    }
    if (recFilter.createdDateFrom !== "" && recFilter.createdDateTo === "") {
      isValid = false;
      toast.error("Select the To Date");
    }
    if (recFilter.createdDateFrom === "" && recFilter.createdDateTo !== "") {
      isValid = false;
      toast.error("Select the From Date");
    }
    if (
      (recFilter.createdDateFrom === "" && recFilter.createdDateTo !== "") ||
      (recFilter.createdDateFrom !== "" && recFilter.createdDateTo === "")
    ) {
      isValid = false;
      toast.error(
        "Please provide both From and To dates for created date range."
      );
    } else if (
      recFilter.createdDateFrom !== "" &&
      recFilter.createdDateTo !== "" &&
      recFilter.createdDateFrom > recFilter.createdDateTo
    ) {
      isValid = false;
      toast.error("Created from date cannot be greater than Created to date.");
    }
    return isValid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validationFunction();
    try {
      if (isValid) {
        setTotalRecipients(0);

        const data = {
          createdDateFrom: recFilter.createdDateFrom || null,
          createdDateTo: recFilter.createdDateTo || null,
          firstName: recFilter.firstName,
          lastName: recFilter.lastName,
          email: recFilter.email,
          userType: props.filterType,
          jobId: selectedJob.jobId,
          // pageNumber: 1,
          // pageSize: 10,
          selectAll: true,
        };

        setIsLoading(true);
        setTableData([]);
        const response = await post(
          "/smsbroadcast/fetchRecipientsForSms",
          data
        );
        if (response.status === 200) {
          if (response?.data?.result?.length > 0) {
            let data2 = response.data.result.map((item) => ({
              ...item,
              isChecked: false,
            }));
            setTotalRecipients(response.data.total);
            setTableData(data2);
            setIsLoading(false);
            setApiCanCalled(true);
            setNoData(false);
            setSelectRecAll(false);
          } else {
            setTotalRecipients(response.data.total);
            setTableData(response.data.result);
            setIsLoading(false);
            setApiCanCalled(true);
            setNoData(true);
            setSelectRecAll(false);
          }
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  return (
    <>
      {isLoading && <Loader />}

      <Modal size={"xl"} show={props.showModal} onHide={closeModal}>
        <div
          class="bl-modal-header"
          id="recipientsModal"
          style={{ fontSize: "14px" }}
          backdrop="static"
          keyboard={false}
        >
          <h6 class="bl-modal-title">Select Recruiter</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body className="py-3 px-3">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Row>
                <Col md={3}>
                  <Form.Group controlId="formCreatedDateFrom">
                    <Form.Label>Created From</Form.Label>
                    <Form.Control
                      type="date"
                      value={recFilter.createdDateFrom}
                      style={{
                        height: "43px",
                        fontSize: "14px",
                      }}
                      onChange={(e) =>
                        setRecFilter({
                          ...recFilter,
                          createdDateFrom: e.target.value,
                        })
                      }
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formCreatedDateTo">
                    <Form.Label>Created To</Form.Label>
                    <Form.Control
                      type="date"
                      value={recFilter.createdDateTo}
                      style={{
                        height: "43px",
                        fontSize: "14px",
                      }}
                      onChange={(e) =>
                        setRecFilter({
                          ...recFilter,
                          createdDateTo: e.target.value,
                        })
                      }
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <p>Or</p>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>Recruiter Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      style={{
                        height: "43px",
                        fontSize: "14px",
                      }}
                      value={recFilter.firstName}
                      onChange={(e) =>
                        setRecFilter({
                          ...recFilter,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <p>Or</p>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={recFilter.email}
                      style={{
                        height: "43px",
                        fontSize: "14px",
                      }}
                      onChange={(e) =>
                        setRecFilter({
                          ...recFilter,
                          email: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>

                <Col className="d-flex justify-content-end pt-5 pb-5">
                  <button
                    type="button"
                    className="btn-outline-primary py-1 mx-3"
                    onClick={() => handleClear()}
                  >
                    Clear
                  </button>
                  <Button variant="primary" type="submit">
                    Search
                  </Button>
                </Col>
              </Row>
            </Row>
            <Row>
              {totalRecipients && totalRecipients > 1 ? (
                <>
                  <p>Total Recipients: {totalRecipients}</p>
                </>
              ) : null}
              <>
                {totalRecipients && totalRecipients > 1 ? (
                  <button
                    type="button"
                    className="mb-4"
                    style={{
                      width: "200px",
                      marginLeft: "15px",
                      marginBottom: "15px",
                    }}
                    onClick={(e) => {
                      totalRecipients > 100
                        ? setConfirm1000(true)
                        : handleAllRecipients(e);
                    }}

                    // onClick={handleAllRecipients}
                  >
                    Select all Recipients
                  </button>
                ) : null}
                {tableData.length > 0 && (
                  <p>
                    {selectedCount > 1
                      ? `Selected Recipients: ${selectedCount}`
                      : `Selected Recipient: ${selectedCount}`}
                  </p>
                )}
              </>
            </Row>
          </Form>

          <div style={{ maxHeight: "450px", overflow: "scroll" }}>
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>First name</th>
                  <th>Last name</th>
                  <th style={{ width: "15%" }}>Contact number</th>
                  <th style={{ width: "25%" }}>Email</th>
                </tr>
              </thead>
              <tbody style={{ maxHeight: "300px", overflow: "scroll" }}>
                {!selectRecAll && currentData && currentData?.length === 0 ? (
                  noData && apiCanCalled ? (
                    <tr className="text-center">
                      <td colSpan="5">
                        <h6>No records found</h6>
                      </td>
                    </tr>
                  ) : (
                    <tr className="text-center">
                      <td colSpan="5">
                        <h6>Select any filters & Search.</h6>
                      </td>
                    </tr>
                  )
                ) : (
                  !selectRecAll &&
                  currentData &&
                  currentData?.map((item, index) => (
                    <tr key={index}>
                      <td className="d-flex justify-content-center align-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{
                            width: "20px",
                            height: "20px",
                            boxShadow: "none",
                          }}
                          checked={item?.isChecked}
                          onChange={(e) => handleCheckBox(e, item, index)}
                        />
                      </td>
                      <td>{item?.firstName}</td>
                      <td>{item?.lastName}</td>
                      <td style={{ width: "15%" }}>
                        {item?.mobilePhoneCode + " " + item?.mobilePhone}
                      </td>
                      <td style={{ width: "25%" }}>{item?.email}</td>
                    </tr>
                  ))
                )}
                {selectRecAll && tableData && tableData?.length === 0 ? (
                  noData && apiCanCalled ? (
                    <tr className="text-center">
                      <td colSpan="5">
                        <h6>No records found</h6>
                      </td>
                    </tr>
                  ) : (
                    <tr className="text-center">
                      <td colSpan="5">
                        <h6>Select any filters & Search.</h6>
                      </td>
                    </tr>
                  )
                ) : (
                  selectRecAll &&
                  tableData &&
                  tableData?.map((item, index) => (
                    <tr key={index}>
                      <td className="d-flex justify-content-center align-content-center">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{
                            width: "20px",
                            height: "20px",
                            boxShadow: "none",
                          }}
                          checked={item?.isChecked}
                          onChange={(e) => handleCheckBox(e, item, index)}
                        />
                      </td>
                      <td>{item?.firstName}</td>
                      <td>{item?.lastName}</td>
                      <td style={{ width: "15%" }}>
                        {item?.mobilePhoneCode + " " + item?.mobilePhone}
                      </td>
                      <td style={{ width: "25%" }}>{item?.email}</td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {/* {!selectRecAll && tableData.length > 0 ? (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setPaginationArray={setPaginationArray}
                data={tableData}
                dataSize={dataSize}
                getTab=""
                pageSize={pageSize}
              />
            ) : null} */}
            {!selectRecAll &&
              currentData?.length > 0 &&
              currentData.length !== totalRecipients && (
                <div className="d-flex justify-content-between">
                  <div className="mt-3 px-2">
                    Showing{" "}
                    {Math.min((currentPage - 1) * 10 + 1, totalRecipients)} -{" "}
                    {Math.min(currentPage * 10, totalRecipients)} of{" "}
                    {totalRecipients}
                  </div>
                  <div className="mt-3 px-2">
                    <Pagination
                      currentPage={currentPage}
                      totalSize={totalRecipients}
                      sizePerPage={10}
                      firstPageText="|⟨"
                      lastPageText="⟩|"
                      showFirstLastPages={true}
                      changeCurrentPage={handlePageChange}
                      theme="border-bottom"
                    />
                  </div>
                </div>
              )}
          </div>
          <hr />
          <div className="ml-4">
            <div className="d-flex justify-content-end mt-3">
              <button
                type="button"
                className="btn-outline-primary py-1 mx-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button mr-2 btn-clear"
                className="btn-outline-primary py-1 mx-2"
                onClick={handleCheckBoxClear}
              >
                Clear
              </button>
              <Button
                variant="primary"
                type="btn"
                className="mr-2 mx-1"
                disabled={selectedCount === 0}
                onClick={handleDone}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {confirm1000 && (
        <Modal show={true} size="md" animation={true}>
          <Modal.Header>
            <button
              type="button"
              onClick={(e) => {
                setConfirm1000(false);
                handleAllRecipients(e);
              }}
              className="close p-0 bl-modal-close-btn closable"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fal fa-times closable"></i>
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className="m-5">
              <div class="text-center">
                <div class="avatar avatar-lg avatar-red2">
                  <i
                    class="fas fa-exclamation-triangle fa-fw"
                    aria-hidden="true"
                  ></i>
                </div>
                <p class="text-center lead mt-3">
                  Broadcast Limitation: Maximum 100 candidates per SMS.
                </p>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div class="d-flex align-items-center justify-content-center">
              <Button variant="secondary" onClick={() => setConfirm1000(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleAllRecipients}
                className="ml-2"
              >
                Proceed
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default AddRecContactsModal;
