import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { get, post } from "../../../service/ApiService";
import axios from "axios";
import { toast } from "react-toastify";
import { ProgressBar } from "react-bootstrap";
import buttonLoader from "../../../images/button-loader.gif";

const ApplyJob = (props) => {
  const [resumeFile, setResumeFile] = useState("");
  const [valid, setValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);

  const onDragDropChangeHandler = (file) => {
    var re = /(?:\.([^.]+))?$/;

    var ext = re.exec(file[0].name)[1]; // "
    console.log(file[0].name, ext);

    if (ext === "pdf" || ext === "docx" || ext === "doc") {
      setValid(true);
      //	setResumeFile(file[0]);
      const { name } = file[0];
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (event) => {
        setResumeFile({ fileName: name, base64: event.target.result });
        //	toast.success("File uploaded successfully")
        // api call here to get uploaded
        // incrementUploadValue()
      };
    } else {
      setValid(false);
    }
  };
  useEffect(() => {
    valid && uploadResume();
  }, [resumeFile]);

  const uploadResume = async () => {
    try {
      console.log(valid);
      console.log("resumeFile", resumeFile);

      if (valid) {
        setSubmitClicked(true);
        const result = await post("/career/directSourcingResume", resumeFile);
        console.log(result);

        if (result.status == 200) {
          const finalresult = {
            ...result.data,
            ...resumeFile,
          };
          setSubmitClicked(false);
          props.updateParsedData(finalresult, true);
        }
      } else {
        toast.error("Please Upload resume first");
        // setSubmitClicked(false)
      }
    } catch (error) {
      toast.error(
        "Required data is missing for the document please upload another file"
      );
      setSubmitClicked(false);
    }
    //incrementUploadValue(1)
  };
  return (
    <div className="row my-1 mb-3" style={{ width: "100%" }}>
      <div className="d-flex align-items-center  mt-2  ">
        <div>
          {resumeFile === "" ? (
            <p className="m-0 ">
              {props.message === "JoinCommunity"
                ? "Upload your resume*"
                : "To check your matching score for the job*"}
            </p>
          ) : (
            <p className="m-0 mr-2">{resumeFile?.fileName}</p>
          )}
        </div>

        <div className="d-flex justify-content-center align-items-center ml-1">
          <Dropzone
            onDrop={(file) => onDragDropChangeHandler(file)}
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="">
                <button type="button" className="btn btn-sm btn-secondary">
                  <i className="far fa-cloud-upload mr-1 fa-fw"></i>
                  Upload Resume
                </button>
                <input
                  type="file"
                  {...getInputProps()}
                  accept=".docx, .doc, .pdf"
                />
              </div>
            )}
          </Dropzone>
          {/* <div className="mx-3">
            <small>Supported Formats: .docx, .doc or .pdf file</small>
          </div> */}
          {resumeFile &&
            resumeFile.fileName &&
            // <button type="button" class="btn btn-primary btn-sm ml-2">
            //   {submitClicked === false ? (
            //     <span
            //     // onClick={uploadResume}
            //     >
            //       Upload
            //     </span>
            //   ) : (
            //     <span v-if="submittingForm">
            //       Uploading
            //       <img width="20px" alt="" src={buttonLoader} />
            //     </span>
            //   )}
            // </button>
            submitClicked !== false && (
              <button type="button" class="btn btn-primary btn-sm ml-2">
                <span v-if="submittingForm">
                  Uploading
                  <img width="20px" alt="" src={buttonLoader} />
                </span>
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

export default ApplyJob;
