import React, { useEffect, useState } from "react";
import Select from "react-select";
import Autosuggest from "react-autosuggest";
import { toast } from "react-toastify";
import "react-tagsinput/react-tagsinput.css";
import _ from "lodash";
import PreferredLocation from "../../../../location/PreferredLocation";
import { dropDownData } from "../../../../directsourcing/Edit Talent DS/dropDownData";
import TagsInput from "../../../commoncomponents/tagsinput/TagsInput";
import { visaStatus } from "../../../../../constants/Constants";
const additionalVisaStatus = [
  { value: "Not Required", label: "Not Applicable" },
  { value: "Need Sponsorship", label: "Need Sponsorship" },
];

const combinedVisaStatus = [...visaStatus, ...additionalVisaStatus];
const uniqueVisaStatus = Array.from(new Set(combinedVisaStatus.map(JSON.stringify))).map(JSON.parse);

function Profile(props) {
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [visaStatusList, setVisaStatusList]=useState(uniqueVisaStatus)
  const [form, setForm] = useState(null);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  const [secondarySkillsInput, setSecondarySkillSInput] = useState("");
  const [positionTypes, setPositionTypes] = useState([]);
  const [skillSet, setSkillSet] = useState([]);
  const educationTypes = [
    "Bachelor's Degree",
    "Certification",
    "Doctorate",
    "Highschool or Equivalent",
    "Master's Degree",
    "NA",
    "Professional",
    "Some College Coursework Completed",
    "Some Highschool Course Work",
    "Vocational",
  ];

  let positionTypeOption = dropDownData.PositionType.map((item) => {
    const obj = { value: item.SourceName, label: item.SourceName };
    return obj;
  });
  positionTypeOption = _.sortBy(positionTypeOption, ["value"]);

  let skillSetOption = dropDownData.skillSet.map((item) => {
    const obj = { value: item.SourceName, label: item.SourceName };
    return obj;
  });
  skillSetOption = _.sortBy(skillSetOption, ["value"]);

  const deleteLocation = (index) => {
    let current = [...preferredLocation];
    current.splice(index, 1);
    setPreferredLocation(current);
    props.changePreferredLocation(current);
  };

  const handlePositiontype = (e) => {
    setPositionTypes(e);
    props.setProfileInfo({
      ...props.profileInfo,
      positionTypes: e.map((pType) => pType.value),
    });
  };

  const handleSkillSet = (e) => {
    setSkillSet(e);
    props.setProfileInfo({
      ...props.profileInfo,
      skillSet: e.map((skillSet) => skillSet.value),
    });
  };

  const handleChangePrimarySkills = (skills) => {
    props.setProfileInfo({ ...props.profileInfo, primarySkills: skills });
  };

  const handleChangeSecondarySkills = (skills) => {
    props.setProfileInfo({ ...props.profileInfo, secondarySkills: skills });
  };

  const handleChangeInputPrimarySkills = (skills) => {
    setPrimarySkillInput(skills);
  };

  const handleChangeInputSecondarySkills = (skills) => {
    setSecondarySkillSInput(skills);
  };

  const handleChange = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      [e.target.id]: e.target.value,
    });
  };

  const handleChangeExperienceYear = (e) => {
    let value = e.target.value === "" ? "0" : e.target.value;

    props.setProfileInfo({
      ...props.profileInfo,
      experienceYear: value,
    });
  };

  const handleChangeExperienceMonth = (e) => {
    let value = e.target.value === "" ? "0" : e.target.value;
    props.setProfileInfo({
      ...props.profileInfo,
      experienceMonth: value,
    });
  };

  const onRemoteSwitch = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      [e.target.id]: e.target.checked,
    });
  };

  const onIsfresherSwitch = (e) => {
    if (e.target.id == "isFresher" && e.target.checked === true) {
      // disable experince tab

      props.setProfileInfo({ ...props.profileInfo, experienceLevel: " " });
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceLevel: "Junior :0-3 years",
      });
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceYear: "",
        experienceMonth: "",
        currentJobTitle: "",
      });
    } else {
      // enable tab
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceLevel: "",
        experienceYear: "",
        experienceMonth: "",
        currentJobTitle: "",
      });
    }
  };

  // Auto suggestion in tags input
  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.SourceName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropDownData.CandidateSkills.filter((item) =>
              item.SourceName.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion.SourceName}</div>;

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.SourceName);
        }}
        theme={theme}
      />
    );
  };

  let yearArr = [];
  let dayArr = [];

  const filterCurrencyType = () => {
    dropDownData.CurrencyTime.map((x) => {
      let ans = x.SourceName.split("/")[1];

      if (ans === "Year") {
        yearArr.push(x);
      } else {
        dayArr.push(x);
      }
    });
  };
  filterCurrencyType();

  useEffect(() => {
    filterCurrencyType();
  }, [dropDownData]);

  return (
    <>
      <div>
        <div className="card-body">
          <div className="row ">
            <div className="col-lg-6">
              <div className="form-floating">
                <input
                  type="text"
                  className={"form-control"}
                  name="currentPosition"
                  id="designation"
                  placeholder="Enter a group name"
                  disabled={props.profileInfo.isFresher}
                  style={{
                    border:
                      props.isFormValid &&
                      props.profileInfo.designation == "" &&
                      props.profileInfoValidation.designation &&
                      "2px solid #ff0000",
                  }}
                  value={props.profileInfo.designation}
                  onChange={handleChange}
                />
                <label>Current job title*</label>
              </div>
              {props.isFormValid &&
                props.profileInfo.designation == "" &&
                props.profileInfoValidation.designation && (
                  <small className="validation">*Required Field</small>
                )}
            </div>

            <div className="col-lg-6">
              <div className="form-floating">
                <select
                  id="visaStatus"
                  className="form-select"
                  onChange={handleChange}
                  value={props.profileInfo.visaStatus}
                  style={{
                    border:
                      props.isFormValid &&
                      props.profileInfo.visaStatus == "" &&
                      props.profileInfoValidation.visaStatus &&
                      "2px solid #ff0000",
                  }}
                >
                  <option value="" selected>
                    Select Status
                  </option>
                  {visaStatusList?.map((item, i) => (
                    <option value={item.value} key={i}>
                      {item.label}
                    </option>
                  ))}
                </select>
                <label>Visa Status*</label>
              </div>
              {props.isFormValid &&
                props.profileInfo.visaStatus == "" &&
                props.profileInfoValidation.visaStatus && (
                  <small className="validation">*Required Field</small>
                )}
            </div>
          </div>

          <div className="row mb-12">
            <div className="col-lg-6">
              <label>Total work experience*</label>
            </div>

            <div className="col-lg-6">
              <label>Highest Level of Education</label>
            </div>
          </div>
          <div className="row mb-12">
            <div className="col-lg-6">
              <div className="form-floating">
                <div className="d-flex">
                  <div className="mr-3 wd-100 form-floating">
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      max="100"
                      style={{
                        height: "57px",
                        border: "1px solid #ced4da",
                        fontSize: "15px",
                      }}
                      name="years"
                      value={props.profileInfo.experienceYear}
                      onChange={(e) => {
                        if (+e.target.value < 0) {
                          toast.error("Negative numbers are not allowed");
                        } else if (+e.target.value > 99) {
                          toast.error("experience value is invalid");
                        } else {
                          handleChangeExperienceYear(e);
                        }
                      }}
                      placeholder="Year"
                      disabled={props.profileInfo.isFresher}
                    />
                    <label>Years</label>
                    {props.isFormValid &&
                      props.profileInfo.experienceYear == "" && (
                        <small className="validation">*Required Field</small>
                      )}
                  </div>

                  <div className="mr-3  wd-100  form-floating">
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      style={{
                        height: "57px",
                        border: "1px solid #ced4da",
                        fontSize: "15px",
                        width: "108%",
                      }}
                      name="months"
                      value={props.profileInfo.experienceMonth}
                      onChange={(e) => {
                        if (
                          Number(e.target.value) > 11 ||
                          Number(e.target.value) < 0
                        )
                          return;
                        else {
                          handleChangeExperienceMonth(e);
                        }
                      }}
                      placeholder="Months"
                      disabled={props.profileInfo.isFresher}
                    />
                    <label>Months</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-floating">
                <select
                  className="form-select"
                  id="highestEducation"
                  name="highestEducation"
                  onChange={handleChange}
                  value={props.profileInfo.highestEducation}
                >
                  {educationTypes.map((item, i) => (
                    <option key={i} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
                <label>Highest Level of Education</label>
              </div>
            </div>
          </div>
          <div className="row " style={{ marginTop: "-5px" }}>
            <div className="col-12">
              <div className=" ">
                <label>Social Media Link </label>
              </div>
              <div className="col-lg-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Eg. Linkedin ID"
                    id="linkedIn"
                    value={props.profileInfo.linkedIn}
                    onChange={handleChange}
                  />
                  <label>eg. LinkedIn ID</label>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mb-12 mt-2">
            <div className="col-12">
              <div className="mb-2 d-flex">
                <label>Preferred Locations* </label>
                <div
                  className="form-floating ml-auto"
                  style={{ margin: "15px 0px 0px 50px" }}
                >
                  <div className="form-check form-switch d-flex align-items-center">
                    <label
                      className="form-check-label pt-0"
                      htmlFor="remoteSwitch"
                    >
                      Open for remote
                    </label>
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="isFresher"
                      checked={props.profileInfo?.isRemote}
                      onChange={(e) =>
                        props.setProfileInfo({
                          ...props.profileInfo,
                          isRemote: e.target.checked,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div>
                <div className="form-inline-edit">
                  <div>
                    <div>
                      <div className="m-2">
                        {preferredLocation?.map((item, index) => (
                          <>
                            <span className="tag tag-blue3 mb-1 mr-1">
                              {item}
                              <button
                                className="btn btn-text"
                                onClick={() => deleteLocation(index)}
                              >
                                <strong className="font-18 font-black">
                                  &times;
                                </strong>
                              </button>
                            </span>
                          </>
                        ))}
                      </div>
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <div className="form-floating w-100">
                          <PreferredLocation
                            setLocation={(loc) => {
                              if (preferredLocation.includes(loc)) {
                                toast.error(
                                  "You Have Already Added This Location"
                                );
                              } else {
                                setPreferredLocation((cur) => [...cur, loc]);
                                props.changePreferredLocation([
                                  ...preferredLocation,
                                  loc,
                                ]);
                              }
                            }}
                            validation={
                              props.profileInfoValidation.preferredLocation
                            }
                            preferredLocation={
                              props.profileInfo.preferredLocation
                            }
                            isFormValid={props.isFormValid}
                          />
                        </div>
                        <div className="ml-auto mr-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {props.isFormValid &&
                props.profileInfo.preferredLocation == "" &&
                props.profileInfoValidation.preferredLocation && (
                  <small className="validation">
                    {props.profileInfoValidation.preferredLocation}
                  </small>
                )}
            </div>
          </div> */}
          <div className="row " style={{ marginTop: "-5px" }}>
            <div className="col-12">
              <div className=" ">
                <label>Primary Skills* </label>
              </div>
              <TagsInput
                renderInput={autocompleteRenderInput}
                value={props?.profileInfo?.primarySkills}
                onChange={handleChangePrimarySkills}
                inputValue={primarySkillinput}
                onChangeInput={handleChangeInputPrimarySkills}
                placeholder="Type a location and hit enter"
                inputProps={{
                  placeholder: "Add a skill",
                }}
                isFormValid={props.isFormValid}
                primarySkills={props.profileInfo.primarySkills}
                profileInfoValidation={
                  props.profileInfoValidation.primarySkills
                }
              />
            </div>
            {props.isFormValid &&
              props?.profileInfo?.primarySkills.length == 0 &&
              props.profileInfoValidation.primarySkills && (
                <small className="validation">
                  {props.profileInfoValidation.primarySkills}
                </small>
              )}
          </div>

          <div className="row mb-2">
            <div className="col-12" style={{ marginTop: "-5px" }}>
              <div className="mb-1">
                <label>Description</label>
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Description"
                    id="description"
                    value={props.profileInfo.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Profile;
