import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

// Action types for getCuratorListSideView
export const GET_CURATORS_SIDEVIEW_BEGIN = "GET_CURATORS_SIDEVIEW_BEGIN";
export const GET_CURATORS_SIDEVIEW_SUCCESS = "GET_CURATORS_SIDEVIEW_SUCCESS";
export const GET_CURATORS_SIDEVIEW_FAILURE = "GET_CURATORS_SIDEVIEW_FAILURE";

// Action types for getSourceDetails
export const GET_SOURCE_DETAILS_BEGIN = "GET_SOURCE_DETAILS_BEGIN";
export const GET_SOURCE_DETAILS_SUCCESS = "GET_SOURCE_DETAILS_SUCCESS";
export const GET_SOURCE_DETAILS_FAILURE = "GET_SOURCE_DETAILS_FAILURE";

// Action creators for getCuratorListSideView
export const getCuratorsSideView = (payloadObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CURATORS_SIDEVIEW_BEGIN,
    });
    const res = await post("/curatorList/getcuratorListSideView", payloadObj);
    if (res.status === 200) {
      dispatch({
        type: GET_CURATORS_SIDEVIEW_SUCCESS,
        payload: res.data,
      });
      return res.data;
    }
  } catch (error) {
    dispatch({
      type: GET_CURATORS_SIDEVIEW_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
    return [];
  }
};

// Action creators for getSourceDetails
export const getSourceDetails = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SOURCE_DETAILS_BEGIN,
    });
    const res = await post("/curatorList/getSourceDetails", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_SOURCE_DETAILS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SOURCE_DETAILS_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
