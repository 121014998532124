import React, { useEffect, useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import {
  getUserRole,
  getUserTenantID,
} from "../../../../../service/AuthService";
import moment from "moment";
import TCHeader from ".././TCHeader";
import GroupsCard from ".././GroupView";
import { get } from "../../../../../service/ApiService";
import TalentView from ".././TalentView";
import Loader from "../../../../Loader/Loader";
import Pagination from "../../../../components_v3/pagination/pagination";
import NoRecord from "../../../commoncomponents/norecord/NoRecord";
import { useHistory } from "react-router";

const GroupList = () => {
  const role = getUserRole();
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paginationArray, setPaginationArray] = useState([]);
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [subComm, setSubComm] = useState(false);
  const [analytics, setAnalytics] = useState(false);
  const [Comm, setComm] = useState(true);
  const pageSize = 10;

  const [paginatedData, setPaginatedData] = useState([]);

  const filteredPools = paginatedData.filter((group) =>
    group.groupname.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };

  useEffect(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, groups.length);
    const newData = groups.slice(startIndex, endIndex);
    setPaginatedData(newData.map((e) => ({ ...e, viewAll: false })));
  }, [currentPage, groups]);

  useEffect(() => {
    getGroupsFromDB();
  }, []);

  const getGroupsFromDB = async () => {
    setLoading(true);
    await get("/talent/getgrouplist").then((res) => {
      setGroups(res.data);
      setLoading(false);
    });
  };
  const handleOnChange = (e) => {
    if (e.target.value == "pools") {
      history.push("/high5hire/talentcommunity/pools");
    }
  };

  const onSkillClick = (candidate) => {
    setPaginatedData(
      paginatedData.map((item) => {
        if (item.id_group == candidate) {
          item.viewAll = !item.viewAll;
        }
        return item;
      })
    );
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const clearSearch = () => {
    setSearchValue("");
  };

  const onTabClick = (tab) => {
    switch (tab) {
      case "subComm":
        history.push("/high5hire/talentcommunity/pools");
        break;
      case "Comm":
        history.push("/high5hire/talentcommunity/groups");
        break;
      default:
        break;
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div class="row justify-content-center">
        <div class="col-lg-12">
          <div class="card card-flat card-borderless p-4 p-0-600 mb-3">
            <TCHeader
              role={role}
              count={groups}
              isFrom={"Group"}
              onTabClick={onTabClick}
              subComm={subComm}
              analytics={analytics}
              Comm={Comm}
            ></TCHeader>
            <hr className="mt-0 pt-0" />

            <div className="row mt-3 mb-3 mr-1">
              <div className="col-md-4">
                <div className="d-flex justify-content-start">
                  <input
                    type="text"
                    value={searchValue}
                    onChange={handleSearchChange}
                    className="form-control"
                    placeholder="Search by Community Name"
                    style={{
                      fontSize: "14px",
                      boxShadow: "none",
                      borderRightColor: searchValue ? "transparent" : "#ced4da",
                      borderTopRightRadius: searchValue ? "0px" : "4px",
                      borderBottomRightRadius: searchValue ? "0px" : "4px",
                    }}
                  />
                  {searchValue && (
                    <button
                      className="btn btn-sm btn-success ml-0"
                      onClick={clearSearch}
                      aria-label="Clear"
                      style={{
                        height: "35px",
                        borderColor: searchValue ? "#ced4da" : "transparent",
                        borderTopLeftRadius: searchValue ? "0px" : "4px",
                        borderBottomLeftRadius: searchValue ? "0px" : "4px",
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  )}
                </div>{" "}
              </div>
              <div className="col-md-8">
                <div className="d-flex justify-content-end row">
                  {/* <div className="col-md-5">
                    <input
                      type="text"
                      placeholder="Type to search"
                      className="form-control form-control-filter"
                      style={{ height: "40px" }}
                      onChange={(e) => handleSearch(e)}
                    />
                  </div> */}

                  <div className="col-md-3">
                    {/* <select
                      className="font-14 form-select "
                      style={{ height: "40px" }}
                      name="salaryCurrency"
                      onChange={(e) => handleOnChange(e)}
                    >
                      <option value="groups">Community</option>
                      <option value="pools">Sub Community</option>
                    </select> */}
                  </div>
                </div>
              </div>
            </div>

            {/* View by group Cards */}
            {filteredPools?.map((elem, idx) => {
              return (
                <GroupsCard
                  key={idx}
                  groups={elem}
                  getGroupsFromDB={getGroupsFromDB}
                  onSkillClick={onSkillClick}
                  groupId={elem.id_groups}
                />
              );
            })}
            <div className="row w-100 mr-2 ml-3">
              {filteredPools && filteredPools?.length > 0 && (
                <Pagination
                  currentPage={currentPage}
                  totalItems={filteredPools.length}
                  itemsPerPage={10}
                  onPageChange={handlePageChange}
                  limit={10}
                />
              )}
            </div>
            {groups.length === 0 && <NoRecord />}
          </div>
        </div>
      </div>
    </>
  );
};

export default GroupList;
