import React, { useState } from "react";
import Vetting from "../vettingplans/index";
import { Modal } from "react-bootstrap";
import ShortUniqueId from "short-unique-id";
import { post } from "../../../../../service/ApiService";
import { toast } from "react-toastify";
import {
  getTenantType,
  getUserId,
  getUserTenantID,
} from "../../../../../service/AuthService";
import Loader from "../../../commoncomponents/Loader/Loader";
import ReadyToHireQA from "../addpool/ReadyToHireQ&A";
import { emailDomain } from "../../../../../constants/Constants";
import { useDispatch } from "react-redux";
import { getPoolDispositionDetails } from "../../../../../actions/directsourcing/Disposition";

const ReadyToHireModal = ({
  jobData,
  showReadyToHireModal,
  setShowReadyToHireModal,
  selectedTalents,
  setSelectedTalents,
  view,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const uid = new ShortUniqueId({ length: 10 });
  const [readyToHireQAList, setReadyToHireQAList] = useState([]);
  const [addJobData, setAddJobData] = useState({
    experienceLevel: jobData.experienceLevel,
    primarySkills: jobData?.primarySkills
      ? JSON.parse(jobData?.primarySkills)
      : [],
    vettingDetails: jobData?.vettingDetails,
    jobTitle: jobData?.jobTitle,
  });

  const [questionList, setQuestionList] = useState([]);
  const difficultyData = [
    { exp: "Intern", val: "Simple" },
    { exp: "Less than one year", val: "Simple" },
    { exp: "1-3 years", val: "Medium" },
    { exp: "3-5 years", val: "Medium" },
    { exp: "5-10 years", val: "Advanced" },
    { exp: "10+ years", val: "Advanced" },
  ];

  const createVetting = async () => {
    if (
      readyToHireQAList &&
      readyToHireQAList?.length !==0 &&
      readyToHireQAList.filter((e) => e?.question?.trim() == "").length == 0
    ) {
      setIsLoading(true);
      const payload = {
        poolId: jobData?.poolId,
        readyToHireQA: JSON.stringify(readyToHireQAList),
      };
      const payloadFilter = {
        currentPage: 1,
        limit: 15,
        status: view,
        poolId: jobData?.poolId,
        filterObject: "",
      };
      await post("/talent/updateTalentPoolReadyToHireQA", payload).then(
        async (res) => {
          await post(
            "/bulkTalentAction/getPoolDispositionFilterList",
            payloadFilter
          ).then(async (res) => {
            let data = Array.isArray(res.data) ? res.data : [];
            if(data?.length>0){
              let paramObj = data?.map((e)=>({
                link:`careers/joblist/pooldescription/${jobData?.poolId}/rthQA/${e.id_candidateTalentPool}`,
                email: e.email.trim(),
                firstName: e.firstName,
                jobTitle:jobData?.jobTitle,
              }))
              if (paramObj?.length > 0) {
                await post("/reminderBroadcast/readyToHireQAMail", paramObj).then(
                (resp) => {
                  toast.success("Ready To Hire Screening Created Successfully!")
                  setShowReadyToHireModal(false);
                  setIsLoading(false);
                  setTimeout(() => {
                    window.location.reload();
                  }, 3000);
                }
              );
            }
            }
            
          });
        }
      );
    } else {
      toast.error("Please enter question");
    }
  };
  
  return (
    <>
      {isLoading && <Loader />}
      <Modal show={showReadyToHireModal} size="lg">
        <div className="modal-header" id="NewCandidateModal">
          <h6>
            Create Ready To Hire Screening{" "}
            {/* <i
              className="fa fa-info-circle mr-2 ml-2"
              style={{ color: "grey" }}
            ></i> */}
          </h6>

          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowReadyToHireModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <ReadyToHireQA
            setReadyToHireQAList={setReadyToHireQAList}
            readyToHireQAList={readyToHireQAList}
          />
          <hr />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            data-dismiss="modal"
            onClick={() => setShowReadyToHireModal(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm btn-primary "
            onClick={() => createVetting()}
          >
            Create
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReadyToHireModal;
