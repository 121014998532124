import React, { useState, useEffect } from "react";

import { shallowEqual, useDispatch, useSelector } from "react-redux";

import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";
import { getUserId } from "../../../../service/AuthService";
import { Modal } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { updateSMSTemplate } from "../../../../actions/adminconsole/SMSTemplate";
import TemplateIndex from "./Template/TemplateIndex";
import EditTemplate from "./Template/EditTemplate";

function EmailTemplateModalView(props) {
  const { SMSLoading, SMSData } = useSelector(
    (state) => ({
      SMSLoading: state.smsTemplateReducer.SMSLoading,
      SMSData: state.smsTemplateReducer.SMSData,
    }),
    shallowEqual
  );
  const sanitizeHTML = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };
  const dispatch = useDispatch();

  const [isValid, setIsValid] = useState(false);

  const [editMode, setEditMode] = useState(false);

  const [editedValues, setEditedValues] = useState({
    smsContent: props.selectedSMSData?.smsContent.replace(/\n/g, "<br>") || "",
  });

  useEffect(() => {
    console.log(props.createNew);
    props.createNew && setEditMode(true);
  }, [props.createNew]);

  // useEffect(() => {
  //   setEditMode(false);
  // }, [props.selectedSMSData]);

  const handleClose = () => {
    props.setSmsFullView(false);
    props.setCreateNew(false);
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();
    if (sanitizeHTML(editedValues.smsContent) === "") {
      setIsValid(true);
      toast.error("Please enter all the required fields");
      return;
    }

    const updatedSmsContent = editedValues.smsContent
      .replace(/<p>/g, "")
      .replace(/<\/p>/g, "")
      .replace(/<br>/g, "\n");

    const data = {
      id_smsTemplate: props.selectedSMSData.id_smsTemplate,
      smsSubject: "",
      smsContent: updatedSmsContent,
      footer: "",
      updatedBy: getUserId(),
    };

    setEditMode(false);

    let tempData = {
      smsContent: updatedSmsContent.replace(/\n/g, "<br>"),
      id_smsTemplate: props.selectedSMSData.id_smsTemplate,
      recipients: props.selectedSMSData.recipients,
      events: props.selectedSMSData.events,
      logDetails: props.selectedSMSData.logDetails,
    };

    props.setSelectedSMSData(tempData);
    dispatch(updateSMSTemplate(data));
  };

  const handleCancelClick = () => {
    setIsValid(false);
    setEditedValues({
      smsContent: props.selectedSMSData?.smsContent || "",
    });
    setEditMode(false);
  };

  return (
    <Modal show={props.smsFullView} size="xl">
      <Modal.Header className="bg-gray4">
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={handleClose}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body className="p-0">
        <div className="">
          {SMSLoading && <Loader />}

          <div className="p-3 d-flex align-items-center justify-content-between m-0">
            <div className=" d-flex align-items-center m-0">
              <i
                className="fas fa-envelope-open-text mr-4"
                style={{ fontSize: 30 }}
              ></i>
              <h6> {props.selectedSMSData?.events} </h6> {/* Add Edit button */}
            </div>
          </div>

          <hr className="m-0" />
          {/* <div className="d-flex justify-content-end pr-4 gap-1">
            {" "}
            {!editMode && (
              <button
                type="button"
                className="btn btn-secondary btn-sm mb-2"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                <FaEdit /> Edit
              </button>
            )}{" "}
          </div> */}
          <div className="pt-0 mt-0 pl-3 pr-3">
            {!editMode && (
              <TemplateIndex
                SMSData={props.selectedSMSData}
                isValid={isValid}
                sanitizeHTML={sanitizeHTML}
                setEditedValues={setEditedValues}
                editMode={editMode}
                setEditMode={setEditMode}
              />
            )}
            {editMode && (
              <EditTemplate
                selectedSmsData={props.selectedSMSData}
                setSelectedSmsData={props.setSelectedSMSData}
                handleUpdateClick={handleUpdateClick}
                editedValues={editedValues}
                isValid={isValid}
                editMode={editMode}
                handleCancelClick={handleCancelClick}
                setEditedValues={setEditedValues}
                createNew={props.createNew}
              />
            )}
          </div>
        </div>{" "}
      </Modal.Body>
      <Modal.Footer>
        <div className="text end">
          <button onClick={handleClose} className="btn  btn-secondary btn-sm">
            {"Close"}
          </button>
        </div>
        {props.createNew && (
          <div className="text end">
            <button onClick={() => {}} className="btn  btn-sm">
              {"Create"}
            </button>
          </div>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default EmailTemplateModalView;
