import React, { useEffect, useState } from "react";
import PaginationFilter from "../../commoncomponents/pagination/PaginationFilter";
import moment from "moment";
import { toast } from "react-toastify";

const Filter = (props) => {
  let dataLimit = 4;
  let pageLimit = 5;
  const [memberFrom, setMemberFrom] = useState(["", ""]);
  const [memberFromType, setMemberFromType] = useState("");
  const [candidateNameEnabled, setCandidateNameEnabled] = useState(false);
  const [candidateName, setCandidateName] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [candidateUserTypeEnabled, setCandidateUserTypeEnabled] = useState(
    false
  );
  const [candidateExpEnabled, setCandidateExpEnabled] = useState(false);
  const [candidateExp, setCandidateExp] = useState([]);
  const [selectedExp, setSelectedExp] = useState([]);
  const [memberFromEnabled, setMemberFromEnabled] = useState(false);
  const [candidateAddedOn, setCandidateAddedOn] = useState([]);
  const [selectedAddedOn, setSelectedAddedOn] = useState([]);
  const [candidatesAddedEnabled, setCandidatesAddedEnabled] = useState(false);
  const [candidatesAdded, setCandidatesAdded] = useState([]);
  const [selectedCandidatesAdded, setSelectedCandidatesAdded] = useState([]);
  const [candidateUserType, setCandidateUserType] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [candidateSourceEnabled, setCandidateSourceEnabled] = useState(false);
  const [candidateSource, setCandidateSource] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [candidateStatusEnabled, setCandidateStatusEnabled] = useState(false);
  const [candidateStatus, setCandidateStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [candidateCountryEnabled, setCandidateCountryEnabled] = useState(false);
  const [candidateCountry, setCandidateCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage1, setCurrentPage1] = useState(1);
  const [searchData1, setSearchData1] = useState([]);
  const [searchValue1, setSearchValue1] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [searchData2, setSearchData2] = useState([]);
  const [searchValue2, setSearchValue2] = useState("");
  const [currentPage3, setCurrentPage3] = useState(1);
  const [searchData3, setSearchData3] = useState([]);
  const [searchValue3, setSearchValue3] = useState("");
  const [currentPage4, setCurrentPage4] = useState(1);
  const [searchData4, setSearchData4] = useState([]);
  const [searchValue4, setSearchValue4] = useState("");
  const [currentPage5, setCurrentPage5] = useState(1);
  const [searchData5, setSearchData5] = useState([]);
  const [searchValue5, setSearchValue5] = useState("");
  const [currentPage6, setCurrentPage6] = useState(1);
  const [searchData6, setSearchData6] = useState([]);
  const [searchValue6, setSearchValue6] = useState("");
  const [currentPage7, setCurrentPage7] = useState(1);
  const [searchData7, setSearchData7] = useState([]);
  const [searchValue7, setSearchValue7] = useState("");
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [paginationGroup1, setPaginationGroup1] = useState([]);
  const [paginationArray1, setPaginationArray1] = useState([]);
  const [paginationGroup2, setPaginationGroup2] = useState([]);
  const [paginationArray2, setPaginationArray2] = useState([]);
  const [paginationGroup3, setPaginationGroup3] = useState([]);
  const [paginationArray3, setPaginationArray3] = useState([]);
  const [paginationGroup4, setPaginationGroup4] = useState([]);
  const [paginationArray4, setPaginationArray4] = useState([]);
  const [paginationGroup5, setPaginationGroup5] = useState([]);
  const [paginationArray5, setPaginationArray5] = useState([]);
  const [paginationGroup6, setPaginationGroup6] = useState([]);
  const [paginationArray6, setPaginationArray6] = useState([]);
  const [paginationGroup7, setPaginationGroup7] = useState([]);
  const [paginationArray7, setPaginationArray7] = useState([]);

  useEffect(() => {
    setSelectedName(props.selectedName);
    setCandidateName(props.candidateName);
    setSelectedUserType(props.selectedUserType);
    setSelectedExp(props.selectedExp);
    setCandidateExp(props.candidateExp);
    setSelectedAddedOn(props.selectedAddedOn);
    setCandidateAddedOn(props.candidateAddedOn);
    setCandidatesAdded(props.ccandidatesAdded);
    setSelectedCandidatesAdded(props.selectedCandidatesAdded);
    setCandidateUserType(props.candidateUserType);
    setSelectedSource(props.selectedSource);
    setCandidateSource(props.candidateSource);
    setSelectedStatus(props.selectedStatus);
    setCandidateStatus(props.candidateStatus);
    setSelectedCountry(props.selectedCountry);
    setCandidateCountry(props.candidateCountry);
    setMemberFromType(props.memberFromType);
    setMemberFrom(props.memberFrom);
  }, [props]);

  const titleHandler = (checked, name) => {
    if (checked) {
      setSelectedName([...selectedName, name]);
    } else {
      setSelectedName(selectedName.filter((t) => t !== name));
    }
  };

  const userTypeHandler = (checked, userType) => {
    if (checked) {
      setSelectedUserType([...selectedUserType, userType]);
    } else {
      setSelectedUserType(selectedUserType.filter((t) => t !== userType));
    }
  };

  const expHandler = (checked, exp) => {
    if (checked) {
      setSelectedExp([...selectedExp, exp]);
    } else {
      setSelectedExp(selectedExp.filter((t) => t !== exp));
    }
  };

  const addedOnHandler = (checked, addedOn) => {
    if (checked) {
      setSelectedAddedOn([...selectedAddedOn, addedOn]);
    } else {
      setSelectedAddedOn(selectedAddedOn.filter((t) => t !== addedOn));
    }
  };

  const candidatesAddedHandler = (checked, candidatesAdded) => {
    if (checked) {
      setSelectedCandidatesAdded([...selectedCandidatesAdded, candidatesAdded]);
    } else {
      setSelectedCandidatesAdded(
        selectedCandidatesAdded.filter((t) => t !== candidatesAdded)
      );
    }
  };

  const sourceHandler = (checked, source) => {
    if (checked) {
      setSelectedSource([...selectedSource, source]);
    } else {
      setSelectedSource(selectedSource.filter((t) => t !== source));
    }
  };

  const statusHandler = (checked, status) => {
    if (checked) {
      setSelectedStatus([...selectedStatus, status]);
    } else {
      setSelectedStatus(selectedStatus.filter((t) => t !== status));
    }
  };

  const countryHandler = (checked, country) => {
    if (checked) {
      setSelectedCountry([...selectedCountry, country]);
    } else {
      setSelectedCountry(selectedCountry.filter((t) => t !== country));
    }
  };

  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  function changePage1(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage1(pageNumber);
  }

  function changePage2(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage2(pageNumber);
  }

  function changePage3(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage3(pageNumber);
  }

  function changePage4(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage4(pageNumber);
  }

  function changePage5(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage5(pageNumber);
  }

  function changePage6(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage6(pageNumber);
  }

  function changePage7(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage7(pageNumber);
  }
  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : candidateName.slice(startIndex, endIndex);
  };

  const getPaginatedData1 = () => {
    const startIndex = currentPage1 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue1
      ? searchData1.slice(startIndex, endIndex)
      : candidateUserType.slice(startIndex, endIndex);
  };

  const getPaginatedData2 = () => {
    const startIndex = currentPage2 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue2
      ? searchData2.slice(startIndex, endIndex)
      : candidateSource.slice(startIndex, endIndex);
  };

  const getPaginatedData3 = () => {
    const startIndex = currentPage3 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue3
      ? searchData3.slice(startIndex, endIndex)
      : candidateStatus.slice(startIndex, endIndex);
  };

  const getPaginatedData4 = () => {
    const startIndex = currentPage4 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue4
      ? searchData4.slice(startIndex, endIndex)
      : candidateCountry.slice(startIndex, endIndex);
  };

  const getPaginatedData5 = () => {
    const startIndex = currentPage5 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue5
      ? searchData5.slice(startIndex, endIndex)
      : candidateExp.slice(startIndex, endIndex);
  };

  const getPaginatedData6 = () => {
    const startIndex = currentPage6 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue6
      ? searchData6.slice(startIndex, endIndex)
      : candidateAddedOn.slice(startIndex, endIndex);
  };

  const getPaginatedData7 = () => {
    const startIndex = currentPage7 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue7
      ? searchData7.slice(startIndex, endIndex)
      : candidatesAdded.slice(startIndex, endIndex);
  };
  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(candidateName.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(candidateName.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue1
      ? setPaginationArray1(
          new Array(Math.ceil(searchData1.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray1(
          new Array(Math.ceil(candidateUserType.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue1
      ? setPaginationGroup1(
          new Array(Math.ceil(searchData1.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup1(
          new Array(Math.ceil(candidateUserType.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue2
      ? setPaginationArray2(
          new Array(Math.ceil(searchData2.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray2(
          new Array(Math.ceil(candidateSource.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue2
      ? setPaginationGroup2(
          new Array(Math.ceil(searchData2.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup2(
          new Array(Math.ceil(candidateSource.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue3
      ? setPaginationArray3(
          new Array(Math.ceil(searchData3.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray3(
          new Array(Math.ceil(candidateStatus.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue3
      ? setPaginationGroup3(
          new Array(Math.ceil(searchData3.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup3(
          new Array(Math.ceil(candidateStatus.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue4
      ? setPaginationArray4(
          new Array(Math.ceil(searchData4.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray4(
          new Array(Math.ceil(candidateCountry.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue4
      ? setPaginationGroup4(
          new Array(Math.ceil(searchData4.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup4(
          new Array(Math.ceil(candidateCountry.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue5
      ? setPaginationArray5(
          new Array(Math.ceil(searchData5?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray5(
          new Array(Math.ceil(candidateExp?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue5
      ? setPaginationGroup5(
          new Array(Math.ceil(searchData5?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup5(
          new Array(Math.ceil(candidateExp?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue6
      ? setPaginationArray6(
          new Array(Math.ceil(searchData6?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray6(
          new Array(Math.ceil(candidateAddedOn?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue6
      ? setPaginationGroup6(
          new Array(Math.ceil(searchData6?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup6(
          new Array(Math.ceil(candidateAddedOn?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue7
      ? setPaginationArray7(
          new Array(Math.ceil(searchData7?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray7(
          new Array(Math.ceil(candidatesAdded?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue7
      ? setPaginationGroup7(
          new Array(Math.ceil(searchData7?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup7(
          new Array(Math.ceil(candidatesAdded?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    setCurrentPage(1);
    setCurrentPage1(1);
    setCurrentPage2(1);
    setCurrentPage3(1);
    setCurrentPage4(1);
    setCurrentPage5(1);
    setCurrentPage6(1);
    setCurrentPage7(1);
  }, [
    candidateName,
    searchData,
    candidateUserType,
    searchData1,
    candidateSource,
    searchData2,
    candidateStatus,
    searchData3,
    candidateCountry,
    searchData4,
    candidateExp,
    searchData5,
    candidateAddedOn,
    searchData6,
    candidatesAdded,
    searchData7,
  ]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      } else if ((currentPage1 - 1) % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(
            currentPage1 - 1,
            currentPage1 + (pageLimit - 1)
          )
        );
      } else if ((currentPage2 - 1) % pageLimit === 0 && currentPage2 !== 1) {
        setPaginationGroup2(
          paginationArray2.slice(
            currentPage2 - 1,
            currentPage2 + (pageLimit - 1)
          )
        );
      } else if ((currentPage3 - 1) % pageLimit === 0 && currentPage3 !== 1) {
        setPaginationGroup3(
          paginationArray3.slice(
            currentPage3 - 1,
            currentPage3 + (pageLimit - 1)
          )
        );
      } else if ((currentPage4 - 1) % pageLimit === 0 && currentPage4 !== 1) {
        setPaginationGroup4(
          paginationArray4.slice(
            currentPage4 - 1,
            currentPage4 + (pageLimit - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      } else if (currentPage1 % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(currentPage1 - pageLimit, currentPage1)
        );
      } else if (currentPage2 % pageLimit === 0 && currentPage2 !== 1) {
        setPaginationGroup2(
          paginationArray2.slice(currentPage2 - pageLimit, currentPage2)
        );
      } else if (currentPage3 % pageLimit === 0 && currentPage3 !== 1) {
        setPaginationGroup3(
          paginationArray3.slice(currentPage3 - pageLimit, currentPage3)
        );
      } else if (currentPage4 % pageLimit === 0 && currentPage4 !== 1) {
        setPaginationGroup4(
          paginationArray4.slice(currentPage4 - pageLimit, currentPage4)
        );
      }
    }
  }, [
    currentPage,
    currentPage1,
    currentPage2,
    currentPage3,
    currentPage4,
    currentPage5,
    currentPage6,
    currentPage7,
  ]);

  const splitMatchedText = (str) => {
    let regExp;
    if (searchValue) {
      regExp = new RegExp(searchValue, "gi");
    } else if (searchValue1) {
      regExp = new RegExp(searchValue1, "gi");
    } else if (searchValue2) {
      regExp = new RegExp(searchValue2, "gi");
    } else if (searchValue3) {
      regExp = new RegExp(searchValue3, "gi");
    } else if (searchValue4) {
      regExp = new RegExp(searchValue4, "gi");
    } else if (searchValue5) {
      regExp = new RegExp(searchValue5, "gi");
    } else if (searchValue6) {
      regExp = new RegExp(searchValue6, "gi");
    } else if (searchValue7) {
      regExp = new RegExp(searchValue7, "gi");
    }

    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchData(
      candidateName.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch1 = (e) => {
    setSearchValue1(e.target.value);
    setSearchData1(
      candidateUserType.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch2 = (e) => {
    setSearchValue2(e.target.value);
    setSearchData2(
      candidateSource.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch3 = (e) => {
    setSearchValue3(e.target.value);
    setSearchData3(
      candidateStatus.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch4 = (e) => {
    setSearchValue4(e.target.value);
    setSearchData4(
      candidateCountry.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch5 = (e) => {
    setSearchValue5(e.target.value);
    setSearchData5(
      candidateExp.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };
  const handleSearch6 = (e) => {
    setSearchValue6(e.target.value);
    setSearchData6(
      candidateAddedOn.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch7 = (e) => {
    setSearchValue7(e.target.value);
    setSearchData7(
      candidatesAdded.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const titleArray = (name) => {
    //console.log((selectedName.length > 0 ? selectedName.includes(name) : true))
    return selectedName.length > 0 ? selectedName.includes(name) : true;
  };

  const expArray = (exp) => {
    return selectedExp.length > 0 ? selectedExp.includes(exp) : true;
  };

  const addedOnArray = (addedOn) => {
    switch (memberFromType) {
      case "after":
        return addedOn > moment(new Date(memberFrom[0])).format("MM/DD/YYYY");
      case "before":
        return addedOn < moment(new Date(memberFrom[1])).format("MM/DD/YYYY");
      case "between":
        const startDate = moment(new Date(memberFrom[0])).format("MM/DD/YYYY");
        const endDate = moment(new Date(memberFrom[1])).format("MM/DD/YYYY");
        return startDate < addedOn && addedOn < endDate;
      default:
        return true;
    }
  };

  const candidatesAddedArray = (candidatesAdded) => {
    return selectedCandidatesAdded.length > 0
      ? selectedCandidatesAdded.includes(candidatesAdded === 0 ? "No" : "Yes")
      : true;
  };
  const userTypeArray = (userType) => {
    //console.log((selectedUserType.length > 0 ? selectedUserType.includes(userType) : true),"usertype")
    if (selectedUserType.length === 2 || selectedUserType.length === 0) {
      return true;
    } else {
      if (selectedUserType[0] === "Individual Recruiter") {
        return userType ? false : true;
      } else {
        return userType ? true : false;
      }
    }
  };

  const sourceArray = (source) => {
    if (selectedSource.length === 2 || selectedSource.length === 0) {
      return true;
    } else {
      if (selectedSource[0] === "Invite") {
        return source ? true : false;
      } else {
        return source ? false : true;
      }
    }
  };

  const statusArray = (item) => {
    if (
      selectedStatus.length > 0 &&
      selectedStatus.includes("Active") &&
      item.status === "Approved" &&
      item.uadlastLoginTime
    )
      return true;
    if (
      selectedStatus.length > 0 &&
      selectedStatus.includes("Approved") &&
      item.status === "Approved" &&
      item.uadlastLoginTime
    )
      return false;
    return selectedStatus.length > 0
      ? selectedStatus.includes(item.status)
      : true;
  };

  const countryArray = (country) => {
    return selectedCountry.length > 0
      ? selectedCountry.includes(country)
      : true;
  };

  const clearAll = () => {
    setSelectedName([]);
    setSelectedUserType([]);
    setSelectedExp([]);
    setSelectedAddedOn([]);
    setSelectedCandidatesAdded([]);
    setSelectedSource([]);
    setSelectedStatus([]);
    setSelectedCountry([]);
    setMemberFrom(["", ""]);
    setMemberFromType("");
    setSearchValue("");
    setSearchValue1("");
    setSearchValue2("");
    setSearchValue3("");
    setSearchValue4("");
    setSearchValue5("");
    setSearchValue6("");
    setSearchValue7("");
  };

  const applyFilter = () => {
    let startDate = moment(new Date(memberFrom[0])).format("MM/DD/YYYY");
    let endDate = moment(new Date(memberFrom[1])).format("MM/DD/YYYY");
    if (
      (memberFromType === "after" && memberFrom[0] === "") ||
      (memberFromType === "before" && memberFrom[1] === "")
    ) {
      toast.error(`Please select a valid date`);
    } else if (
      memberFromType === "between" &&
      (memberFrom[0] === "" || memberFrom[1] === "")
    ) {
      if (memberFrom[0] === "" && memberFrom[1] === "") {
        toast.error(`Please select valid 'From' and 'To' dates`);
      } else if (memberFrom[0] === "") {
        toast.error(`Please select a valid 'From' date`);
      } else if (memberFrom[1] === "") {
        toast.error(`Please select a valid 'To' date`);
      } else {
        toast.error(`Please select a valid date`);
      }
    } else if (
      new Date(startDate) > new Date() &&
      (memberFromType === "after" || memberFromType === "before")
    ) {
      toast.error(`'Date' cannot be future date`);
    } else if (new Date(startDate) > new Date()) {
      toast.error(`'From Date' cannot be future date`);
    } else if (new Date(endDate) > new Date()) {
      toast.error(`'To Date' cannot be future date`);
    } else if (new Date(startDate) > new Date(endDate)) {
      toast.error(`'From Date' should not be greater than 'To Date'`);
    } else {
      let filteredData = props.reportData.filter((item) => {
        return (
          titleArray(item.name) &&
          expArray(item.yearsOfExperience) &&
          addedOnArray(item.createddatetime) &&
          candidatesAddedArray(item.noOfCandidate) &&
          userTypeArray(item.companyName) &&
          sourceArray(item.source) &&
          statusArray(item) &&
          countryArray(item.country)
        );
        //return titleArray(item.firstName )
      });
      props.applyFilter(
        filteredData,
        selectedName,
        selectedExp,
        selectedAddedOn,
        selectedCandidatesAdded,
        selectedStatus,
        memberFromType,
        memberFrom
      );
    }
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={applyFilter}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>
                Filter /{" "}
                {selectedName?.length +
                  selectedExp?.length +
                  (memberFrom[0] || memberFrom[1] ? 1 : 0) +
                  selectedCandidatesAdded?.length +
                  selectedStatus?.length}
              </h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear All
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateNameEnabled(!candidateNameEnabled)}
            >
              <i
                className={
                  candidateName
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Name</small>
                {selectedName.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateNameEnabled &&
              (candidateName.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  {getPaginatedData().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedName.includes(j)}
                            className="form-check-input"
                            onChange={(e) => titleHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage === paginationArray.slice(-1)[0]
                        ? getPaginatedData().length
                        : searchValue
                        ? searchData.length < dataLimit
                          ? searchData.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateName.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setPaginationArray={setPaginationGroup}
                        data={props.candidateName}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue("");
                        setSelectedName([]);
                      }}
                    >
                      <small>Clear Name</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          {/* <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() =>
                setCandidateUserTypeEnabled(!candidateUserTypeEnabled)
              }
            >
              <i
                className={
                  candidateUserType
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>User Type</small>
                {selectedUserType.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateUserTypeEnabled &&
              (candidateUserType.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch1}
                    value={searchValue1}
                  />
                  {getPaginatedData1().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue1 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedUserType.includes(j)}
                            className="form-check-input"
                            onChange={(e) =>
                              userTypeHandler(e.target.checked, j)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData1().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue1}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage1 === paginationArray1.slice(-1)[0]
                        ? getPaginatedData1().length
                        : searchValue1
                        ? searchData1.length < dataLimit
                          ? searchData1.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateUserType.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage1((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage1 === 1 || getPaginatedData1().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup1.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage1 === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage1}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage1((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage1 === paginationArray1.slice(-1)[0] ||
                          getPaginatedData1().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue1("");
                        setSelectedUserType([]);
                      }}
                    >
                      <small>Clear User Type</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div> */}
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateExpEnabled(!candidateExpEnabled)}
            >
              <i
                className={
                  candidateExp
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Experience</small>
                {selectedExp.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateExpEnabled &&
              (candidateExp.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch5}
                    value={searchValue5}
                  />
                  {getPaginatedData5().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue5 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedExp.includes(j)}
                            className="form-check-input"
                            onChange={(e) => expHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData5().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue5}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage5 === paginationArray5.slice(-1)[0]
                        ? getPaginatedData5().length
                        : searchValue5
                        ? searchData5.length < dataLimit
                          ? searchData5.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateExp.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage5}
                        setCurrentPage={setCurrentPage5}
                        setPaginationArray={setPaginationGroup5}
                        data={props.candidateExp}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue5("");
                        setSelectedExp([]);
                      }}
                    >
                      <small>Clear Experience</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setMemberFromEnabled(!memberFromEnabled)}
            >
              <i
                className={
                  memberFromEnabled
                    ? "fal fa-angle-up fa-fw"
                    : "fal fa-angle-down fa-fw"
                }
              />
              <span className="p-relative">
                <small>Added on</small>
                {(memberFrom[0] || memberFrom[1]) && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {memberFromEnabled && (
              <div>
                <div className="d-flex mt-2">
                  <select
                    onChange={(e) => {
                      setMemberFromType(e.target.value);
                      setMemberFrom(["", ""]);
                    }}
                    className="form-select w-50 m-3"
                  >
                    <option disabled selected={!memberFromType}>
                      Select Range
                    </option>
                    <option value="after" selected={memberFromType === "after"}>
                      After
                    </option>
                    <option
                      value="before"
                      selected={memberFromType === "before"}
                    >
                      Before
                    </option>
                    <option
                      value="between"
                      selected={memberFromType === "between"}
                    >
                      Between
                    </option>
                  </select>
                  {memberFromType !== "" ? (
                    memberFromType === "after" ||
                    memberFromType === "before" ? (
                      <input
                        type="date"
                        onChange={(e) => {
                          let a = ["", ""];
                          memberFromType === "before"
                            ? (a[1] = e.target.value)
                            : (a[0] = e.target.value);
                          setMemberFrom(a);
                        }}
                        style={{ maxHeight: "41px", margin: "14px 0px" }}
                        className="form-control date"
                        value={
                          memberFromType === "before"
                            ? memberFrom[1]
                            : memberFrom[0]
                        }
                        required
                      />
                    ) : (
                      <>
                        <input
                          type="date"
                          style={{ maxHeight: "41px", margin: "14px 0px" }}
                          className="form-control date mr-2"
                          onChange={(e) => {
                            let a = [...memberFrom];
                            a[0] = e.target.value;
                            setMemberFrom(a);
                          }}
                          value={memberFrom[0]}
                        />
                        <input
                          type="date"
                          style={{ maxHeight: "41px", margin: "14px 0px" }}
                          className="form-control date"
                          onChange={(e) => {
                            let a = [...memberFrom];
                            a[1] = e.target.value;
                            setMemberFrom(a);
                          }}
                          value={memberFrom[1]}
                        />
                      </>
                    )
                  ) : null}
                </div>
                <div className="d-flex justify-content-end py-2 px-1">
                  <button
                    className="btn btn-text"
                    onClick={() => {
                      setMemberFromType("");
                      setMemberFrom(["", ""]);
                    }}
                  >
                    <span className="font-12">
                      <small>Clear added on</small>
                    </span>
                  </button>
                </div>
                <hr className="p-0" />
              </div>
            )}
          </div>

          {/* <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateSourceEnabled(!candidateSourceEnabled)}
            >
              <i
                className={
                  candidateSource
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Source</small>
                {selectedSource.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateSourceEnabled &&
              (candidateSource.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch2}
                    value={searchValue2}
                  />
                  {getPaginatedData2().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue2 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedSource.includes(j)}
                            className="form-check-input"
                            onChange={(e) => sourceHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData2().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue2}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage2 === paginationArray2.slice(-1)[0]
                        ? getPaginatedData2().length
                        : searchValue2
                        ? searchData2.length < dataLimit
                          ? searchData2.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateSource.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage2((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage2 === 1 || getPaginatedData2().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup2.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage2 === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage2}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage2((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage2 === paginationArray2.slice(-1)[0] ||
                          getPaginatedData2().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue2("");
                        setSelectedSource([]);
                      }}
                    >
                      <small>Clear Source</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div> */}

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateStatusEnabled(!candidateStatusEnabled)}
            >
              <i
                className={
                  candidateStatus
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Status</small>
                {selectedStatus.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateStatusEnabled &&
              (candidateStatus.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch3}
                    value={searchValue3}
                  />
                  {getPaginatedData3().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue3 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedStatus.includes(j)}
                            className="form-check-input"
                            onChange={(e) => statusHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData3().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue3}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage3 === paginationArray3.slice(-1)[0]
                        ? getPaginatedData3().length
                        : searchValue3
                        ? searchData3.length < dataLimit
                          ? searchData3.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateStatus.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage3}
                        setCurrentPage={setCurrentPage3}
                        setPaginationArray={setPaginationGroup3}
                        data={props.candidateStatus}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue3("");
                        setSelectedStatus([]);
                      }}
                    >
                      <small>Clear Status</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidatesAddedEnabled(!candidatesAddedEnabled)}
            >
              <i
                className={
                  candidatesAdded
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Candidates added</small>
                {selectedCandidatesAdded.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {candidatesAddedEnabled &&
              (candidatesAdded.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch7}
                    value={searchValue7}
                  />

                  {getPaginatedData7().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue7 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedCandidatesAdded.includes(j)}
                            className="form-check-input"
                            onChange={(e) =>
                              candidatesAddedHandler(e.target.checked, j)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData7().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue7}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage7 === paginationArray7.slice(-1)[0]
                        ? getPaginatedData7().length
                        : searchValue7
                        ? searchData7.length < dataLimit
                          ? searchData7.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidatesAdded.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage7}
                        setCurrentPage={setCurrentPage7}
                        setPaginationArray={setPaginationGroup7}
                        data={props.ccandidatesAdded}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue7("");
                        setSelectedCandidatesAdded([]);
                      }}
                    >
                      <small>Clear candidates added</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>
          {/* <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() =>
                setCandidateCountryEnabled(!candidateCountryEnabled)
              }
            >
              <i
                className={
                  candidateCountry
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Location</small>
                {selectedCountry.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateCountryEnabled &&
              (candidateCountry.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch4}
                    value={searchValue4}
                  />
                  {getPaginatedData4().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue4 ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedCountry.includes(j)}
                            className="form-check-input"
                            onChange={(e) =>
                              countryHandler(e.target.checked, j)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData4().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue4}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage4 === paginationArray4.slice(-1)[0]
                        ? getPaginatedData4().length
                        : searchValue4
                        ? searchData4.length < dataLimit
                          ? searchData4.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateCountry.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage4((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage4 === 1 || getPaginatedData4().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup4.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage4 === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage4}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage4((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage4 === paginationArray4.slice(-1)[0] ||
                          getPaginatedData4().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue4("");
                        setSelectedCountry([]);
                      }}
                    >
                      <small>Clear Location</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Filter;
