import React, { useEffect, useState } from "react";
import PaginationFilter from "../../commoncomponents/pagination/PaginationFilter";
import moment from "moment";
import { toast } from "react-toastify";

const Filter = (props) => {
  let dataLimit = 4;
  let pageLimit = 5;
  // const [requestTypeEnabled, setRequestTypeEnabled] = useState(false);
  // const [requestJobDateEnabled, setRequestJobDateEnabled] = useState(false);
  // const [requestJobLocationEnabled, setRequestJobLocationEnabled] = useState(
  //   false
  // );
  // const [daysLeftEnabled, setDaysLeftEnabled] = useState(false);
  const [requestTenantEnabled, setRequestTenantEnabled] = useState(false);
  const [requestTitleEnabled, setRequestTitleEnabled] = useState(false);
  const [visaStatusEnabled, setVisaStatusEnabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [searchData1, setSearchData1] = useState([]);
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [requestStatus, setRequestStatus] = useState([]);
  const [requestTenant, setRequestTenant] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState([]);
  const [selectedVisaStatus, setSelectedVisaStatus] = useState(false);
  const [requestJobDate, setRequestJobDate] = useState([]);
  const [selectedJobDate, setSelectedJobDate] = useState([]);
  const [requestJobLocation, setRequestJobLocation] = useState([]);
  const [selectedJobLocation, setSelectedJobLocation] = useState([]);
  const [daysLeft, setDaysLeft] = useState("");
  const [daysLeftValue, setDaysLeftValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchValue1, setSearchValue1] = useState("");
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [paginationGroup1, setPaginationGroup1] = useState([]);
  const [paginationArray1, setPaginationArray1] = useState([]);
  const [visaStatus, setVisaStatus] = useState(false);

  useEffect(() => {
    setRequestTitles(props.requestTitles);
    setRequestStatus(props.requestStatus);
    setRequestTenant(props.requestTenant);
    setSelectedTitles(props.selectedTitles);
    setSelectedTypes(props.selectedTypes);
    setSelectedTenant(props.selectedTenant);
    setSelectedJobDate(props.selectedJobDate);
    setRequestJobDate(props.requestJobDate);
    setRequestJobLocation(props.requestJobLocation);
    setSelectedJobLocation(props.selectedJobLocation);
    setDaysLeft(props.daysLeft);
    setDaysLeftValue(props.daysLeftValue);
    // eslint-disable-next-line
  }, []);

  const titleHandler = (checked, title) => {
    if (checked) {
      setSelectedTitles([...selectedTitles, title]);
    } else {
      setSelectedTitles(selectedTitles.filter((t) => t !== title));
    }
  };

  const tenantHandler = (checked, tenant) => {
    if (checked) {
      setSelectedTenant([...selectedTenant, tenant]);
    } else {
      setSelectedTenant(selectedTenant.filter((t) => t !== tenant));
    }
  };

  // const typeHandler = (checked, type) => {
  //   if (checked) {
  //     setSelectedTypes([...selectedTypes, type]);
  //   } else {
  //     setSelectedTypes(selectedTypes.filter((t) => t !== type));
  //   }
  // };

  // const dateHandler = (checked, jDate) => {
  //   if (checked) {
  //     setSelectedTenant([...selectedJobDate, jDate]);
  //   } else {
  //     setSelectedTenant(selectedJobDate.filter((t) => t !== jDate));
  //   }
  // };

  // const locationHandler = (checked, location) => {
  //   if (checked) {
  //     setSelectedJobLocation([...selectedJobLocation, location]);
  //   } else {
  //     setSelectedJobLocation(selectedJobLocation.filter((t) => t !== location));
  //   }
  // };

  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  function changePage1(event) {
    const pageNumber1 = Number(event.target.textContent);
    setCurrentPage1(pageNumber1);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : requestTitles.slice(startIndex, endIndex);
  };

  const getPaginatedData1 = () => {
    const startIndex = currentPage1 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue1
      ? searchData1.slice(startIndex, endIndex)
      : requestTenant.slice(startIndex, endIndex);
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(requestTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(requestTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    setCurrentPage(1);
    // eslint-disable-next-line
  }, [requestTitles, searchData]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
    // eslint-disable-next-line
  }, [currentPage]);

  useEffect(() => {
    searchValue1
      ? setPaginationArray1(
          new Array(Math.ceil(searchData1.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray1(
          new Array(Math.ceil(requestTenant.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue1
      ? setPaginationGroup1(
          new Array(Math.ceil(searchData1.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup1(
          new Array(Math.ceil(requestTenant.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    setCurrentPage1(1);
    // eslint-disable-next-line
  }, [requestTenant, searchData1]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage1 - 1) % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(
            currentPage1 - 1,
            currentPage1 + (pageLimit - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (currentPage1 % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(currentPage1 - pageLimit, currentPage1)
        );
      }
    }
    // eslint-disable-next-line
  }, [currentPage1]);

  const splitMatchedText = (str) => {
    const regExp = new RegExp(searchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };
  const splitMatchedText1 = (str) => {
    const regExp = new RegExp(searchValue1, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchData(
      requestTitles.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch1 = (e) => {
    setSearchValue1(e.target.value);
    setSearchData1(
      requestTenant.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const clearAll = () => {
    setSelectedTypes([]);
    setSelectedTenant([]);
    setSelectedTitles([]);
    setSelectedJobDate([]);
    setSelectedJobLocation([]);
    setDaysLeftValue("");
    setDaysLeft("");
  };

  const titleArray = (title) => {
    return selectedTitles.length > 0 ? selectedTitles.includes(title) : true;
  };

  const jobClient = (tenant) => {
    return selectedTenant.length > 0 ? selectedTenant.includes(tenant) : true;
  };

  // const typeArray = (type) => {
  //   return selectedTypes.length > 0 ? selectedTypes.includes(type) : true;
  // };

  // const jobDate = (jDate) => {
  //   return selectedJobDate.length > 0 ? selectedJobDate.includes(jDate) : true;
  // };
  // const jobLocation = (jLocation) => {
  //   return selectedJobLocation.length > 0
  //     ? selectedJobLocation.includes(jLocation)
  //     : true;
  // };

  // const daysLeftArray = (title, day) => {

  //   let days = Number(moment(day).diff(new Date(), "days"));

  //   if (daysLeftValue !== "") {
  //     let value = +daysLeftValue;

  //     if (days > 0) {
  //       console.log(daysLeft);
  //       switch (daysLeft) {
  //         case "equals":
  //           return days === value;
  //         case "lessthan":
  //           return days < value;
  //         case "greaterthan":
  //           return days > value;
  //         case "lessthanequalto":
  //           return days <= value;
  //         default:
  //           return days >= value;
  //       }
  //     } else {
  //       return false;
  //     }
  //   } else {
  //     return true;
  //   }
  // };

  const applyFilter = () => {
    const filteredData = props.jobData.filter((job) => {
      return (
        titleArray(job.jobTitle) && jobClient(job.tenantname)
        // typeArray(job.jobStatus) &&
        // jobDate(job.jobPostedDate) &&
        // daysLeftArray(job.jobTitle, job.expiresOn)
      );
    });
    props.applyFilter(
      filteredData,
      selectedTitles,
      selectedTypes,
      selectedTenant,
      selectedJobDate,
      selectedJobLocation,
      daysLeft,
      daysLeftValue
    );

    props.setJobData(filteredData);
    props.setRequestStatus(filteredData);
  };

  const visaStatusHandler = (checked, type) => {
    if (checked) {
      setSelectedVisaStatus([...selectedVisaStatus, type]);
    } else {
      setSelectedVisaStatus(selectedVisaStatus.filter((t) => t !== type));
    }
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={() => applyFilter()}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>
                Filter / {selectedTitles.length + selectedTenant.length}
                {/* selectedTypes.length + */}
                {/* +
                  selectedJobDate.length +
                  selectedJobLocation.length +
                  (daysLeftValue ? 1 : 0)  */}
              </h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear All
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() =>
                requestTitleEnabled
                  ? setRequestTitleEnabled(false)
                  : setRequestTitleEnabled(true)
              }
            >
              <i
                className={
                  requestTitleEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Job Title</small>
                {selectedTitles.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTitleEnabled &&
              (requestTitles.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  {getPaginatedData().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedTitles.includes(j)}
                            className="form-check-input"
                            onChange={(e) => titleHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage === paginationArray.slice(-1)[0]
                        ? getPaginatedData().length
                        : searchValue
                        ? searchData.length < dataLimit
                          ? searchData.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {requestTitles.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setPaginationArray={setPaginationGroup}
                        data={props.requestTitles}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue("");
                        setSelectedTitles([]);
                      }}
                    >
                      <small>Clear Job Title</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          {/* <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setRequestTypeEnabled(!requestTypeEnabled)}
            >
              <i
                className={
                  requestTypeEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Job Status</small>
                {selectedTypes.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTypeEnabled &&
              (requestStatus.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  {requestStatus.map((i) => (
                    <div className="d-flex align-items-center m-1">
                      <div>{i}</div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedTypes.includes(i)}
                            onChange={(e) => typeHandler(e.target.checked, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedTypes([])}
                    >
                      <small>Clear Job Status</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div> */}

          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setRequestTenantEnabled(!requestTenantEnabled)}
            >
              <i
                className={
                  requestTenantEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Job Client</small>
                {selectedTenant.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTenantEnabled &&
              (requestTenant.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  {requestTenant.map((i) => (
                    <div className="d-flex align-items-center m-1">
                      <div>{i}</div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedTenant.includes(i)}
                            onChange={(e) => tenantHandler(e.target.checked, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage1 === paginationArray1.slice(-1)[0]
                        ? getPaginatedData1().length
                        : searchValue1
                        ? searchData1.length < dataLimit
                          ? searchData1.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {requestTenant.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage1}
                        setCurrentPage={setCurrentPage1}
                        setPaginationArray={setPaginationGroup1}
                        data={props.requestTenant}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedTenant([])}
                    >
                      <small>Clear Job Client</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>

          {
            // <div className="mb-2">
            //   <button
            //     type="button"
            //     className="btn btn-collapsible"
            //     onClick={() => setVisaStatusEnabled(!visaStatusEnabled)}
            //   >
            //     <i
            //       className={
            //         visaStatusEnabled
            //           ? "fal fa-angle-down fa-fw"
            //           : "fal fa-angle-up fa-fw"
            //       }
            //     />
            //     <span class="p-relative">
            //       <small>Visa Status</small>
            //       {selectedVisaStatus.length > 0 && (
            //         <span className="jewel"></span>
            //       )}
            //     </span>
            //   </button>
            //   {visaStatusEnabled &&
            //     (visaStatus.length > 0 ? (
            //       <div className="mb-4 ml-4 pl-1 pt-2">
            //         {visaStatus.map((i) => (
            //           <div className="d-flex align-items-center m-1">
            //             <div>{i}</div>
            //             <div className="ml-auto">
            //               <div className="form-check form-switch">
            //                 <input
            //                   type="checkbox"
            //                   className="form-check-input"
            //                   checked={selectedVisaStatus.includes(i)}
            //                   onChange={(e) =>
            //                     visaStatusHandler(e.target.checked, i)
            //                   }
            //                 />
            //               </div>
            //             </div>
            //           </div>
            //         ))}
            //         <div className="d-flex justify-content-end mt-2 px-1">
            //           <button
            //             className="btn btn-text"
            //             onClick={() => setSelectedVisaStatus([])}
            //           >
            //             <small>Clear Visa Status</small>
            //           </button>
            //         </div>
            //         <hr className="p-0" />
            //       </div>
            //     ) : (
            //       <div>
            //         <div class="text-center p-3">
            //           <div class="avatar avatar-lg">
            //             <i class="fad fa-users-slash"></i>
            //           </div>
            //           <div class="mt-2">No records found</div>
            //         </div>
            //       </div>
            //     ))}
            // </div>
          }

          {/* <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setRequestJobDateEnabled(!requestJobDateEnabled)}
            >
              <i
                className={
                  requestJobDateEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Added on</small>
                {selectedJobDate.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestJobDateEnabled &&
              (requestJobDate.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-1">
                  {requestJobDate.map((i) => (
                    <div className="d-flex align-items-center m-1">
                      <div>{i}</div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedJobDate.includes(i)}
                            onChange={(e) => dateHandler(e.target.checked, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedJobDate([])}
                    >
                      <small>Clear Added on</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div> */}
        </div>
      </div>
    </>
  );
};

export default Filter;
