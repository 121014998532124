import React from "react";
import { PageNotFoundImage } from "../../constants/Constants";

const ExpiredLink = () => {
  return (
    <>
      <section
        className="section section-sm bg-gray3 text-center bg-cover bg-cover notFound"
        style={{ height: "100vh" }}
      >
        <div className="container-fluid h-100">
          <div className="row h-100 align-items-center justify-content-center">
            <div className="col-lg-7">
              <div className="notFoundIcon">
                <h3 className="font-bold brand_blue">
                  <span className="font-coral">
                    Sorry! This link has been Expired!
                  </span>
                </h3>

                <img
                  height="60px"
                  width="132px"
                  src={PageNotFoundImage}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ExpiredLink;
