import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import LocationFilter from "../../newFilter/locationFilter/LocationFilter";
import DDMS from "../../newFilter/dropDownMSFilter/DDMSFilter";
import Loader from "../../../Loader/Loader";
import {
  getCuratorCountryList,
  getCuratorJobTitleList,
  getCuratorStateList,
  getCuratorRolesList,
  getCuratorjobCategory,
} from "../../../../actions/curator/AssignCuratorFilter";
import { activityOptions } from "../../../../constants/Constants";

const AssignCuratorFilter = ({
  applyFilter,
  selectedData,
  setSelectedData,
  closePanel,
}) => {
  const dispatch = useDispatch();
  const [languageOptions, setLanguageOptions] = useState([]);
  const [regionOptions, setRegionOptions] = useState([]);
  const [rolesOptions, setRolesOptions] = useState([]);
  const [jobCategoryOptions, setJobCategoryOptions] = useState([]);
  const [experienceOptions, setExperienceOptions] = useState([]);
  const [jobTitleOptions, setJobTitleOptions] = useState([]);
  const [stateOptions, setStateOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState([]);
  const [collapsible, setCollapsible] = useState({
    city: false,
    state: false,
    country: false,
    experienceLevel: false,
    jobTitle: false,
    language: false,
    region: false,
    jobCategory: false,
    experience: false,
    activity: false,
    roles: false,
  });
  const handleFilterToggle = (filter) => {
    closePanel();
    setCollapsible({
      city: false,
      state: false,
      country: false,
      experienceLevel: false,
      jobTitle: false,
      language: false,
      region: false,
      jobCategory: false,
      roles: false,
      activity: false,
      experience: false,
      [filter]: !collapsible[filter],
    });
  };
  const {
    dropdownLoading,
    dropdownData,
    jobTitleData,
    jobTitleLoading,
    stateLoading,
    stateData,
    countryLoading,
    countryData,
    rolesData,
    rolesLoading,
    jobCategory,
    categoryLoading,
  } = useSelector(
    (state) => ({
      dropdownLoading: state.dropdownValuesReducer.dropdownLoading,
      jobTitleData: state.getCuratorFilterReducer.jobTitleData,
      jobTitleLoading: state.getCuratorFilterReducer.loading,
      dropdownData: state.dropdownValuesReducer.dropdownData,
      stateData: state.getCuratorFilterReducer.stateData,
      stateLoading: state.getCuratorFilterReducer.stateLoading,
      countryData: state.getCuratorFilterReducer.countryData,
      countryLoading: state.getCuratorFilterReducer.countryLoading,
      rolesData: state.getCuratorFilterReducer.rolesData,
      rolesLoading: state.getCuratorFilterReducer.rolesLoading,
      jobCategory: state.getCuratorFilterReducer.jobCategory,
      categoryLoading: state.getCuratorFilterReducer.categoryLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (dropdownData.length > 0) {
      let regionData = [];
      let languageData = [];
      let experienceData = [];
      dropdownData.forEach((element) => {
        if (element.typeName == "languages") {
          languageData = [
            ...languageData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "regions") {
          regionData = [
            ...regionData,
            {
              value: element.name,
              label: element.name,
              disabled: false,
            },
          ];
        }
        if (element.typeName == "experienceYear") {
          experienceData = [
            ...experienceData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
      });
      setLanguageOptions(languageData);
      setRegionOptions(regionData);
      setExperienceOptions(experienceData);
    }
  }, [dropdownData]);

  useEffect(() => {
    if (jobTitleData && jobTitleData?.length > 0) {
      setJobTitleOptions(
        jobTitleData.map((item) => {
          return {
            value: item.jobTitle,
            label: item.jobTitle,
          };
        })
      );
    }
    if (stateData) {
      const formattedStates = stateData?.map((item) => ({
        label: item.state,
        value: item.state,
      }));
      setStateOptions(formattedStates);
    }
    if (countryData) {
      const formattedCountries = countryData?.map((item) => ({
        label: item.country,
        value: item.country,
      }));
      setCountryOptions(formattedCountries);
    }
    if (rolesData) {
      const formattedRoles = rolesData?.map((item) => ({
        label: item.skill,
        value: item.skill,
      }));
      setRolesOptions(formattedRoles);
    }
    if (jobCategory) {
      const formattedCategory = jobCategory?.map((item) => ({
        label: item.jobcategory,
        value: item.jobcategory,
      }));
      setJobCategoryOptions(formattedCategory);
    }
  }, [jobTitleData, stateData, countryData, rolesData, jobCategory]);

  const handleAPICalls = (filter) => {
    if (filter == "jobTitle") {
      const payload = {
        searchText: "",
      };
      dispatch(getCuratorJobTitleList(payload));
    }
    if (filter == "state") {
      const payload = {
        searchText: "",
        filterParams: "",
      };
      dispatch(getCuratorStateList(payload));
    }
    if (filter == "country") {
      const payload = {
        searchText: "",
        // filterParams: { ...filterParams, state: "" },
      };
      dispatch(getCuratorCountryList(payload));
    }
    if (filter == "roles") {
      const payload = {
        searchText: "",
        // filterParams: { ...filterParams, state: "" },
      };
      dispatch(getCuratorRolesList(payload));
    }
    if (filter == "jobCategory") {
      const payload = {
        searchText: "",
        filterParams: "",
      };
      dispatch(getCuratorjobCategory(payload));
    }
  };

  const fetchJobTitles = async (searchValue, filter) => {
    if (filter == "jobTitle" && collapsible[filter]) {
      const payload = {
        searchText: searchValue ?? "",
      };
      dispatch(getCuratorJobTitleList(payload));
    }
  };

  const fetchRoles = async (searchValue, filter) => {
    if (filter == "roles" && collapsible[filter]) {
      const payload = {
        searchText: searchValue ?? "",
      };
      dispatch(getCuratorRolesList(payload));
    }
  };

  const fetchJobCategory = async (searchValue, filter) => {
    if (filter == "jobCategory" && collapsible[filter]) {
      const payload = {
        searchText: searchValue ?? "",
      };
      dispatch(getCuratorjobCategory(payload));
    }
  };

  const fetchStates = async (searchValue) => {
    if (collapsible.state) {
      const payload = {
        searchText: searchValue ?? "",
        // filterParams: filterParams,
      };
      dispatch(getCuratorStateList(payload));
    }
  };

  const fetchCountries = async (searchValue) => {
    if (collapsible.country) {
      const payload = {
        searchText: searchValue ?? "",
        // filterParams: filterParams,
      };
      dispatch(getCuratorCountryList(payload));
    }
  };

  const handleClear = () => {
    setSelectedData({
      country: "",
      state: "",
      address: [],
      language: [],
      region: [],
      jobTitle: [],
      activity: [],
      roles: [],
      jobCategory: [],
      experience: [],
    });
    applyFilter("", "");
  };

  return (
    <>
      {dropdownLoading && <Loader />}
      <div className="card-body">
        <div className="view-panel-header view-panel-header-sm">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="">
              <strong>Filter</strong>
            </div>
            <div className="px-3">
              <button
                type="button"
                className="btn btn-text"
                onClick={handleClear}
              >
                <span className="font-bold">Clear All</span>
              </button>
            </div>
          </div>
          <hr />
        </div>
        <LocationFilter
          handleFilterToggle={handleFilterToggle}
          handleAPICalls={handleAPICalls}
          cityEnabled={collapsible.city}
          stateEnabled={collapsible.state}
          countryEnabled={collapsible.country}
          filterStatus={"candidateLocation"}
          stateArr={stateOptions}
          countryArr={countryOptions}
          selectedCountry={selectedCountry}
          setSelectedCountry={setSelectedCountry}
          selectedCity={selectedCity}
          setSelectedCity={setSelectedCity}
          fetchCountries={fetchCountries}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          fetchStates={fetchStates}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          applyFilter={applyFilter}
          isLoadingCountry={countryLoading}
          isLoadingState={stateLoading}
          isFrom="assignCurator"
        />
        <div className="mb-2">
          <DDMS
            fetchOptions={fetchRoles}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={handleAPICalls}
            filterEnabled={collapsible.roles}
            filterName={"Roles"}
            filterStatus={"roles"}
            options={rolesOptions}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            isMulti={true}
            hideDropdown={true}
            applyFilter={applyFilter}
            isLoading={rolesLoading}
            loading={false}
            talentCountloading={false}
          />
        </div>
        <div className="mb-2">
          <DDMS
            fetchOptions={() => {}}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={handleAPICalls}
            filterEnabled={collapsible.region}
            filterName={"Region"}
            filterStatus={"region"}
            options={regionOptions}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            isMulti={true}
            applyFilter={applyFilter}
            isLoading={false}
            loading={false}
            talentCountloading={false}
          />
        </div>
        <div className="mb-2">
          <DDMS
            fetchOptions={() => {}}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={handleAPICalls}
            filterEnabled={collapsible.language}
            filterName={"Language"}
            filterStatus={"language"}
            options={languageOptions}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            isMulti={true}
            applyFilter={applyFilter}
            isLoading={false}
            loading={false}
            talentCountloading={false}
          />
        </div>
        <div className="mb-2">
          <DDMS
            fetchOptions={() => {}}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={handleAPICalls}
            filterEnabled={collapsible.experience}
            filterName={"Experience"}
            filterStatus={"experience"}
            options={experienceOptions}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            isMulti={true}
            applyFilter={applyFilter}
            isLoading={false}
            loading={false}
            talentCountloading={false}
          />
        </div>
        <div className="mb-2">
          <DDMS
            fetchOptions={fetchJobCategory}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={handleAPICalls}
            filterEnabled={collapsible.jobCategory}
            filterName={"Job Category"}
            filterStatus={"jobCategory"}
            options={jobCategoryOptions}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            isMulti={true}
            hideDropdown={true}
            applyFilter={applyFilter}
            isLoading={categoryLoading}
            loading={false}
            talentCountloading={false}
          />
        </div>
        <div className="mb-2">
          <DDMS
            fetchOptions={fetchJobTitles}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={handleAPICalls}
            filterEnabled={collapsible.jobTitle}
            filterName={"Job Title"}
            filterStatus={"jobTitle"}
            options={jobTitleOptions}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            isMulti={true}
            hideDropdown={true}
            applyFilter={applyFilter}
            isLoading={jobTitleLoading}
            loading={false}
            talentCountloading={false}
          />
        </div>
        <div className="mb-2">
          <DDMS
            fetchOptions={() => {}}
            handleFilterToggle={handleFilterToggle}
            handleAPICalls={handleAPICalls}
            filterEnabled={collapsible.activity}
            filterName={"Recruiter Activity"}
            filterStatus={"activity"}
            options={activityOptions}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            isMulti={true}
            applyFilter={applyFilter}
            isLoading={false}
            loading={false}
            talentCountloading={false}
          />
        </div>
      </div>
    </>
  );
};

export default AssignCuratorFilter;
