// import React, { useState } from "react";
// import { toast } from "react-toastify";
// import Dropzone from "react-dropzone";

// const Resume = ({ resume, setResume, setValid }) => {
//   const [resumeList, setResumeList] = useState([]);

//   const onUploadHandler = (file) => {
//     if (file) {
//       file.map((eachFile) => {
//         let type = eachFile.name.split(".").reverse()[0];
//         if (type === "pdf" || type === "doc" || type === "docx") {
//           const reader = new FileReader();
//           reader.readAsDataURL(eachFile);
//           setValid(true);
//           reader.onload = (event) => {
//             const obj = {
//               fileName: eachFile.name,
//               base64: event.target.result,
//             };
//             setResumeList([obj]);
//           };
//         } else {
//           toast.error("Please Upload PDF or WORD Document");
//           setValid(false);
//         }
//       });
//     }
//   };
//   return (
//     <>
//       <div class="card card-flat card-lg ml-4 ml-0-600">
//         <div class="card-body">
//           <div className="ml-4 mr-5 d-flex mb-2">
//             <Dropzone onDrop={onUploadHandler} multiple={false}>
//               {({ getRootProps, getInputProps }) => (
//                 <div {...getRootProps()} className="ml-auto">
//                   <button type="button" className=" btn btn-text-accent mr-1">
//                     <i
//                       aria-hidden="true"
//                       className="far fa-cloud-upload mr-1 fa-fw"
//                     ></i>
//                     Upload New
//                   </button>
//                   <input type="file" {...getInputProps()} />
//                 </div>
//               )}
//             </Dropzone>
//           </div>
//           <hr />
//           {resume ? (
//             <div>
//               <div className="text-center p-3">
//                 <div className="avatar avatar-lg">
//                   <i className="fad fa-users-slash"></i>
//                 </div>
//                 <div className="mt-2">No Resume Found</div>
//               </div>
//             </div>
//           ) : (
//             <div>
//               <div>
//                 <ul class="list-unstyled mt-2">
                  
//                     <li class="row ml-2 d-flex align-items-center">
                      
//                       <strong className="col-lg-8">{resume.fileName}</strong>
//                       <br />
//                       <br />
//                     </li>
                
//                 </ul>
//               </div>
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default Resume;
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import buttonLoader from "../../../../../images/button-loader.gif";
import { Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
const Resume = ({
  profileInfo,
  setProfileInfo,
  setResume
}) => {
  const [valid, setValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const onDragDropChangeHandler = (file) => {
    var re = /(?:\.([^.]+))?$/;
    var ext = re.exec(file[0].name)[1]; // "
    if (ext === "pdf" || ext === "docx" || ext === "doc") {
      setValid(true);
      const { name } = file[0];
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (event) => {
        setProfileInfo({...profileInfo, fileName: name, base64: event.target.result });
        setResume(true);
      };
    } else {
      setValid(false);
      setResume(false)
    }
  };

  const onRemove = (e) => {
    setProfileInfo({...profileInfo, fileName: "", base64: "" });
    setValid(false);
    setShowConfirmation(false);
    setResume(false);
    toast.success("Resume deleted successfully");
  };

  return (
    <div className=" my-1 mb-3  ">
      <div className="d-flex  align-items-center  mt-2  ">
        <div className="d-flex justify-content-center align-items-center ">
          <Dropzone
            onDrop={(file) => {onDragDropChangeHandler(file)}}
            multiple={false}
            // disabled={!selectedJobId || resumeFile?.fileName}
            disabled={profileInfo?.fileName}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="">
                <button
                  style={{ width: "200px" }}
                  type="button"
                  // disabled={!selectedJobId || resumeFile?.fileName}
                  disabled={profileInfo?.fileName}
                  className="btn btn-secondary"
                >
                  <i className="far fa-cloud-upload mr-1 fa-fw"></i>
                  Select Resume
                </button>
                <input type="file" {...getInputProps()} accept=".docx, .pdf" />
              </div>
            )}
          </Dropzone>{" "}
          <div>
            {profileInfo?.fileName === "" ? (
              <p className="m-0 ml-3  ">Upload your resume*</p>
            ) : (
              <p className="m-0 ml-3 ">{profileInfo?.fileName.length > 22 ? `${profileInfo?.fileName.substring(0, 22)}..` : profileInfo?.fileName}</p>
            )}
          </div>
          {profileInfo && profileInfo.fileName && submitClicked !== false && (
            <button type="button" class="btn btn-sm btn-primary ml-2">
              <span v-if="submittingForm">
                Uploading
                <img width="20px" alt="" src={buttonLoader} />
              </span>
            </button>
          )}
          {profileInfo && profileInfo.fileName && (
            <div className="mb-2 ">
              <button
                type="button"
                className="btn btn-icon ml-4 mt-1"
                aria-hidden="true"
                style={{ cursor: "default" }}
                // onClick={() => setShowConfirmation(true)}
              >
                <i
                  class="fal fa-fw fa-trash-alt"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setShowConfirmation(true);
                  }}
                ></i>
                <i
                  class="fa fa-check-circle"
                  style={{ color: "green", marginLeft: 30 }}
                ></i>
              </button>
            </div>
          )}
        </div>
      </div>
      <Modal show={showConfirmation} size="md" animation={true}>
        <Modal.Header>
          <button
            type="button"
            onClick={() => setShowConfirmation(false)}
            className="close p-0 bl-modal-close-btn closable"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fal fa-times closable"></i>
          </button>
        </Modal.Header>

        <Modal.Body>
          <div className="m-5">
            <div class="text-center">
              <div class="avatar avatar-lg avatar-red2">
                <i
                  class="fas fa-exclamation-triangle fa-fw"
                  aria-hidden="true"
                ></i>
              </div>
              <p class="text-center lead mt-3">
                Are you sure you want to delete resume?
                <br />
                {profileInfo?.fileName}
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div class="d-flex align-items-center justify-content-center">
            <Button
              variant="secondary"
              onClick={() => setShowConfirmation(false)}
            >
              Cancel
            </Button>
            <Button variant="primary" onClick={onRemove} className="ml-2">
              Delete
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Resume;
