import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Redirect } from "react-router-dom";
// import ReactPaginate from "react-paginate";
import PaginationComponent from "../../pagination/pagination";
import moment from "moment";
import _ from "lodash";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  getUserTenantID,
  getOwner,
  getTenantType,
  getCandidateId,
} from "../../../../service/AuthService";
import { getEmployeeDetails } from "../../../../actions/employees/Employees";
import Loader from "../../../Loader/Loader";
import SearchEmployee from "../../../../reducers/employees/SearchEmployee";
import EmployeePopUp from "../../employees/EmployeePopUp";
import AddEmployee from "../../employees/addemployee/Index";
import EditEmployee from "../../employees/editemployee/Index";
import DeactivateEmployee from "../../employees/deactivateemployee/Index";
import ActivateEmployee from "../../employees/activateemployee/Index";
import { roleNames } from "../../../../constants/Constants";
import EmailStatus from "../../EmailStatus/EmailStatus";
import Sort from "../../../common/FullDataSort";
import OutsideClickHandler from "react-outside-click-handler";

const Users = () => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [activatePopup, setActivatePopup] = useState(false);
  const [employeePopup, setEmployeePopup] = useState(false);
  const [addPopup, setAddPopup] = useState(false);
  const [employeesDetails, setEmployeesDetails] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [showingCount, setShowingCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageShowCount, setPageShowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState(null);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [sortData, setSortData] = useState([]);
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [filterBy, setFilterBy] = useState([]);
  const [dataa, setDataa] = useState([]);
  const [filteredData, setFilteredData] = useState([]);

  const startIndex = (currentPage - 1) * pageShowCount;
  const endIndex = startIndex + pageShowCount;
  const currentData = employeesDetails.slice(startIndex, endIndex);

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];
  const sortObject = [
    {
      id: 1,
      label: "Name",
      value: "name",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Email",
      value: "email",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Created On",
      value: "createddatetime",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
  ];

  const applySort = (sortedData, close) => {
    close && setSort(false);
    let data = sortedData?.length === 0 ? dataa : sortedData;
    setEmployeesDetails(data);
  };

  const { loading, employees } = useSelector(
    (state) => ({
      loading: state.employeesDetails.employeesDetailsLoading,
      employees: state.employeesDetails.employeesDetails,
    }),
    shallowEqual
  );

  useEffect(() => {
    const params = {
      tenantId: getUserTenantID(),
    };
    dispatch(getEmployeeDetails(params));
  }, [dispatch]);

  useEffect(() => {
    if (employees.length > 0) {
      // Filter out 'Admin' roles
      const filteredEmployees = employees.filter(
        (item) => item.role !== "Admin"
      );
      const result = filteredEmployees.sort(
        (a, b) => new Date(b.createddatetime) - new Date(a.createddatetime)
      );
      setSortData(result);
      setEmployeesDetails(result);
      setPageCount(Math.ceil(result.length / pageShowCount));
      setTotalEmployee(result.length);
      const showingCount =
        result.length > pageShowCount ? pageShowCount : result.length;
      setShowingCount(showingCount);
    }
  }, [employees, offset, pageShowCount, currentPage]);

  const closePopup = () => {
    setEditPopup(false);
    setDeletePopup(false);
    setActivatePopup(false);
    setEmployeePopup(false);
    setAddPopup(false);
  };

  const handleClick = (item, type) => {
    setSelectedItem(item);
    if (type === "edit") {
      setEditPopup(true);
    } else if (type === "delete") {
      setDeletePopup(true);
    } else if (type === "activate") {
      setActivatePopup(true);
    } else {
      setEmployeePopup(true);
    }
  };

  const handlePageChange = (currentPage) => {
    setCurrentPage(currentPage);
  };

  const handleChange = (e) => {
    let showCount = 0;
    if (e.target.value === "All") {
      showCount = totalEmployee;
    } else {
      showCount = Number(e.target.value);
    }
    setPageShowCount(showCount);
    const offsetValue = currentPage * showCount;
    setPageCount(Math.ceil(totalEmployee / showCount));
    setEmployeesDetails(sortData.slice(offsetValue, offsetValue + showCount));
    setSortArray([]);
    setSortCount(0);
    setOffset(offsetValue);
    const count =
      totalEmployee > (currentPage + 1) * showCount
        ? (currentPage + 1) * showCount
        : totalEmployee;
    setShowingCount(count);
  };

  if (!getOwner()) {
    return <Redirect to="/high5Hire/404" />;
  }
  const buttonStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "20px",
    width: "80px",
    padding: "0 10px",
    fontSize: "14px",
    textAlign: "center",
    paddingTop: "15px",
    backgroundColor: "transparent",
    marginRight: "15px",
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="fontSizeChange px-3">
          <div className="mb-2 mt-4">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h6 className="m-0">Users ({employeesDetails?.length || 0})</h6>
              <button
                type="button"
                className="btn btn-sm"
                onClick={() => setAddPopup(true)}
              >
                <i className="fal fa-user-plus mr-2" aria-hidden="true"></i>Add
                User
              </button>
            </div>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <select className="form-select mb-2" onChange={handleChange}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div
                className="col-lg-6 col-md-8 col-sm-12 col-xs-12 d-flex align-items-start justify-content-end"
                style={{ marginLeft: "550px", marginTop: "-50px" }}
              >
                <div className="flex-grow-1 me-3" style={{ maxWidth: "250px" }}>
                  <SearchEmployee
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    setEmployeesDetails={setEmployeesDetails}
                    sortData={sortData}
                    setPageCount={setPageCount}
                    setShowingCount={setShowingCount}
                    setTotalEmployee={setTotalEmployee}
                    pageShowCount={pageShowCount}
                    placeholder="Search Users by Name and Email"
                  />
                </div>
                <div
                  className="flex-shrink-0"
                  style={{ width: "105px", height: "38px", marginLeft: "20px" }}
                >
                  <div class="py-2 align-self-end" style={{ width: "150px" }}>
                    <div
                      class="dropdown-sort dd-sort"
                      style={{ width: "150px" }}
                    >
                      <button
                        type="button"
                        onClick={() => setSort(!sort)}
                        class="btn btn-sm w-auto btn-text-accent dd-sort"
                        disabled={sortData.length === 0}
                        style={{ marginTop: "-5px", marginLeft: "-20px" }}
                      >
                        <i
                          aria-hidden="true"
                          class="fad fa-sort-down font-16 dd-sort mr-1"
                        ></i>
                        Sort
                        <span class="dd-sort">
                          {sortCount > 0 ? ` / ${sortCount}` : ""}
                        </span>
                      </button>
                      {
                        <OutsideClickHandler
                          onOutsideClick={() => setSort(false)}
                        >
                          {sort && (
                            <Sort
                              sortData={sortData.map((e) => {
                                return {
                                  ...e,
                                  email:
                                    e.email.charAt(0).toUpperCase() +
                                    e.email.slice(1),
                                  Name:
                                    e.name.charAt(0).toUpperCase() +
                                    e.name.slice(1),
                                };
                              })}
                              orderArray={orderList}
                              sortObjects={sortObject}
                              applySort={applySort}
                              setSortCount={setSortCount}
                              sortCount={sortCount}
                              setSortArray={setSortArray}
                              sortArray={sortArray}
                              sortingFor={"Sort by  Name, Email and Created On"}
                            />
                          )}
                        </OutsideClickHandler>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 pt-0 table-cards-1024">
            <table className="table table-hoverabletable-cards-1024">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Role</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Created On</th>
                  <th scope="col">Status </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {currentData.map((item) => (
                  <tr key={item.email}>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(item, "employee")}
                    >
                      <a href="javascript:void(0)">{item.name}</a>
                    </td>
                    <td>{item.email}</td>
                    <td>
                      {item.isOwner
                        ? item.role === "Recruiter" && getTenantType() === 1
                          ? `Curator Admin`
                          : `${roleNames[item.role]} Admin`
                        : item.role === "Recruiter" && getTenantType() === 1
                        ? `Curator`
                        : roleNames[item.role]}
                    </td>
                    <td>{item.designation}</td>
                    <td>
                      {item.createddatetime
                        ? moment(new Date(item.createddatetime)).format(
                            "MM/DD/YYYY"
                          )
                        : "Never"}
                    </td>
                    <td>
                      {item.statusname === "Active" ? (
                        <span className="tag tag-green2">Active</span>
                      ) : (
                        <span className="tag tag-red2">Inactive</span>
                      )}
                    </td>
                    <td className="no-hover text-right">
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="left"
                        overlay={
                          <div className="dropdown-menu dropdown-menu-left show">
                            <div className="admindeleteedit ml-1">
                              <ul>
                                <button
                                  href="#"
                                  className="dropdown-item"
                                  style={{ marginLeft: "-10px" }}
                                  disabled={item.statusname === "InActive"}
                                  onClick={() => handleClick(item, "edit")}
                                >
                                  <i
                                    className="fas fa-edit mr-2"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Edit
                                </button>
                                {item.statusname === "Active" && (
                                  <button
                                    type="button"
                                    className="dropdown-item"
                                    style={buttonStyle}
                                    onClick={() => handleClick(item, "delete")}
                                  >
                                    <i
                                      className="fa fa-trash mr-2"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Deactivate
                                  </button>
                                )}
                                {item.statusname === "InActive" && (
                                  <button
                                    type="button"
                                    className="dropdown-item"
                                    style={buttonStyle}
                                    onClick={() =>
                                      handleClick(item, "activate")
                                    }
                                  >
                                    <i
                                      className="fa fa-key mr-2"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Activate
                                  </button>
                                )}
                              </ul>
                            </div>
                          </div>
                        }
                      >
                        <button
                          type="button"
                          className="btn btn-icon dropdown-toggle"
                        >
                          <i className="far fa-ellipsis-v"></i>
                        </button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {employeesDetails.length === 0 && (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-users-slash"></i>
                  </div>
                  <div className="mt-2">
                    No records found {searchValue ? `for "${searchValue}"` : ""}
                  </div>
                </div>
              </div>
            )}
            <hr className="m-0" />
            <div>
              {employeesDetails?.length > 0 && (
                <PaginationComponent
                  currentPage={currentPage}
                  totalItems={employeesDetails.length}
                  itemsPerPage={pageShowCount}
                  onPageChange={handlePageChange}
                  limit={pageShowCount}
                />
              )}
            </div>
          </div>
        </div>
        {emailStatus && (
          <EmailStatus
            email={emailData}
            emailStatus={emailStatus}
            setEmailStatus={setEmailStatus}
            isFrom="CSM_Users"
          />
        )}
        {employeePopup && (
          <EmployeePopUp
            roleNames={roleNames}
            employee={selectedItem}
            closeModal={closePopup}
            editFun={handleClick}
            DeleteRequestFun={handleClick}
            EnabletenantFun={handleClick}
          />
        )}
        {addPopup && (
          <AddEmployee
            roleNames={roleNames}
            handleSuccess={closePopup}
            addPopup={addPopup}
          />
        )}
        {editPopup && (
          <EditEmployee
            roleNames={roleNames}
            handleSuccess={closePopup}
            editPopup={editPopup}
            employeeData={selectedItem}
          />
        )}
        {deletePopup && (
          <DeactivateEmployee
            roleNames={roleNames}
            handleSuccess={closePopup}
            deletePopup={deletePopup}
            employeeData={selectedItem}
          />
        )}
        {activatePopup && (
          <ActivateEmployee
            roleNames={roleNames}
            handleSuccess={closePopup}
            activatePopup={activatePopup}
            employeeData={selectedItem}
          />
        )}
      </div>
    </>
  );
};

export default Users;
