import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

const SMSconfirm = ({
  phoneNumbers,
  message,
  handleSend,
  confirmation,
  setConfirmation,
}) => {
  return (
    <Modal show={true} size="md" animation={true}>
      <Modal.Header>
        <button
          type="button"
          onClick={() => {
            setConfirmation(false);
          }}
          className="close p-0 bl-modal-close-btn closable"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i className="fal fa-times closable"></i>
        </button>
        <h6 className="mt-3">Send SMS Confirmation</h6>
      </Modal.Header>
      <hr />
      <Modal.Body>
        <div className="m-5">
          <div class="text-center">
            <div class="avatar avatar-lg avatar-green2">
              <i class="fas fa-sms fa-fw" aria-hidden="true"></i>
            </div>
            <p class="text-center lead mt-3">
              Are you sure you want to send this SMS?
            </p>
            {/* <p>
              <strong>Recipients: </strong> {phoneNumbers.join(", ")}
            </p>
            <p>
              <strong>Message: </strong>{" "}
              <div dangerouslySetInnerHTML={{ __html: message }} />
            </p> */}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div class="d-flex align-items-center justify-content-center">
          <Button
            variant="secondary"
            onClick={() => {
              setConfirmation(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSend} className="ml-2">
            Send
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default SMSconfirm;
