import { useEffect, useState } from "react";
import NewTalentListHeader from "./NewTalentListHeader";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { timeOptions } from "../../constants/Constants";
import {
  getTalentList,
  getTalentListCount,
} from "../../actions/talent/talentList";
import NewTalentList from "./NewTalentList";
import Loader from "../Loader/Loader";
import { getTenantType } from "../../service/AuthService";
import Footer from "../layout/Footer";
const NewTalentPoolList = () => {
  const dispatch = useDispatch();

  const {
    talentDataloading,
    talentData,
    talentCount,
    talentCountloading,
  } = useSelector(
    (state) => ({
      talentDataloading: state.TalentList.loading,
      talentData: state.TalentList.talentData,
      talentCount: state.TalentCount.talentCount,
      talentCountloading: state.TalentCount.loading,
    }),
    shallowEqual
  );

  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
  };
  const selectWrapperStyle = {
    minWidth: "160px",
  };
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber);
  };
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedTimeOption, setSelectedTimeOption] = useState(timeOptions[0]);
  const [dataTotal, setDataTotal] = useState(0);
  const [talentDetails, setTalentDetails] = useState([]);
  const [tabType, setTabType] = useState({
    allTalents: false,
    invited: false,
    automatched: true,
    submitted: false,
    shortlisted: false,
    offered: false,
    hired: false,
    disqualified: false,
  });
  const [tab, setTab] = useState("");

  useEffect(() => {
    fetchData(currentPage);
  }, [selectedTimeOption]);

  const fetchData = (
    currentPage,
    val = Object.keys(tabType).find((key) => tabType[key] === true)
  ) => {
    const filterParam = {
      dispositionStatus: [],
      visaStatus: [],
      experienceLevel: [],
      designation: [],
      positionTypes: [],
      educations: [],
      country: "",
      state: "",
      address: "",
      candidateTags: [],
      sourceChannel: [],
      internationalPlacement: [],
      talentAcquisition: [],
      payRate: [],
      keywords: [{ keys: [] }, { keys: [] }, { keys: [] }],
      skills: [{ keys: [] }, { keys: [] }, { keys: [] }],
      certifications: [],
      nursingSpecialty: [],
      licenseStates: [],
      recruiterName: [],
    };
    let status =
      val === "automatched"
        ? [{ value: "38", label: "Automatched" }]
        : val === "invited"
        ? [{ value: "33", label: "Invited" }]
        : val === "submitted"
        ? [{ value: "6", label: "Submitted" }]
        : val === "shortlisted"
        ? [{ value: "8", label: "Shortlisted" }]
        : val === "offered"
        ? [{ value: "37", label: "Offered" }]
        : val === "hired"
        ? [{ value: "11", label: "Hired" }]
        : val === "disqualified"
        ? [{ value: "12", label: "Disqualified" }]
        : [];
    const filterParamForData = { ...filterParam };
    let days = selectedTimeOption?.value;
    let today = new Date();
    let fromDate = new Date();
    filterParamForData["dispositionStatus"] = status;
    if (days !== "") {
      fromDate.setDate(today.getDate() - days);
      fromDate.toISOString();
      filterParamForData["fromDate"] = fromDate;
    }
    let payloadData = {
      currentPage: currentPage,
      limit: 10,
      status: null,
      tab: "All",
      sortParam: [],
      filterObject: filterParamForData,
    };
    let payload = {
      currentPage: currentPage,
      limit: 10,
      status: null,
      tab: "All",
      sortParam: [],
      filterObject: filterParam,
      fromDate: fromDate,
    };
    dispatch(getTalentList(payloadData));
    dispatch(getTalentListCount(payload));
  };

  useEffect(() => {
    if (tabType.allTalents) {
      setDataTotal(getTalentCount("all"));
    } else if (tabType.automatched) {
      setDataTotal(getTalentCount("automatched"));
    } else if (tabType.invited) {
      setDataTotal(getTalentCount("invited"));
    } else if (tabType.submitted) {
      setDataTotal(getTalentCount("submitted"));
      // } else if (getTab === "readytohire") {
      //   setDataTotal(getTalentCount("readytohire"));
    } else if (tabType.shortlisted) {
      setDataTotal(getTalentCount("shortlisted"));
    } else if (tabType.offered) {
      setDataTotal(getTalentCount("offered"));
    } else if (tabType.hired) {
      setDataTotal(getTalentCount("hired"));
    } else if (tabType.disqualified) {
      setDataTotal(getTalentCount("disqualified"));
      // } else if (getTab === "unsubscribe") {
      //   setDataTotal(getTalentCount("unsubscribe"));
    }
  }, [talentCount, tabType]);

  const getTalentCount = (val) => {
    return talentCount && talentCount[0] && talentCount[0][val];
  };

  const onChangeClick = (val) => {
    setCurrentPage(1);
    if (val === "all") {
      setTabType({
        allTalents: true,
        invited: false,
        automatched: false,
        submitted: false,
        shortlisted: false,
        offered: false,
        hired: false,
        disqualified: false,
      });
      fetchData(1, "all");
    } else if (val === "automatched") {
      setTabType({
        allTalents: false,
        invited: false,
        automatched: true,
        submitted: false,
        shortlisted: false,
        offered: false,
        hired: false,
        disqualified: false,
      });
      fetchData(1, "automatched");
    } else if (val === "invited") {
      setTabType({
        allTalents: false,
        invited: true,
        automatched: false,
        submitted: false,
        shortlisted: false,
        offered: false,
        hired: false,
        disqualified: false,
      });
      fetchData(1, "invited");
    } else if (val === "submitted") {
      setTabType({
        allTalents: false,
        invited: false,
        automatched: false,
        submitted: true,
        shortlisted: false,
        offered: false,
        hired: false,
        disqualified: false,
      });
      fetchData(1, "submitted");
    } else if (val === "shortlisted") {
      setTabType({
        allTalents: false,
        invited: false,
        automatched: false,
        submitted: false,
        shortlisted: true,
        offered: false,
        hired: false,
        disqualified: false,
      });
      fetchData(1, "shortlisted");
    } else if (val === "offered") {
      setTabType({
        allTalents: false,
        invited: false,
        automatched: false,
        submitted: false,
        shortlisted: false,
        offered: true,
        hired: false,
        disqualified: false,
      });
      fetchData(1, "offered");
    } else if (val === "hired") {
      setTabType({
        allTalents: false,
        invited: false,
        automatched: false,
        submitted: false,
        shortlisted: false,
        offered: false,
        hired: true,
        disqualified: false,
      });
      fetchData(1, "hired");
    } else if (val === "disqualified") {
      setTabType({
        allTalents: false,
        invited: false,
        automatched: false,
        submitted: false,
        shortlisted: false,
        offered: false,
        hired: false,
        disqualified: true,
      });
      fetchData(1, "disqualified");
    }
  };
  const handleTimeChange = (e) => {
    setCurrentPage(1);
    // fetchData(1);
    setSelectedTimeOption(e);
  };
  return (
    <>
      {(talentDataloading || talentCountloading) && <Loader />}
      <div className=" w-100" style={{ minHeight: "60vh" }}>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <label>
              <strong>Talent Activity</strong>
            </label>{" "}
          </div>
        </div>
        <div class="d-flex justify-content-between d-block-768 align-items-end mb-3 border-bottom-gray2">
          <ul class="nav nav-tabs nav-tabs-tablet pt-1 w-100 justify-content-start">
            <li class="nav-item">
              <button
                type="button"
                class={
                  tabType.automatched
                    ? "nav-link pb-3 mx-2 bg-transparent active"
                    : "nav-link pb-3 mx-2 bg-transparent"
                }
                onClick={() => {
                  setCurrentPage(1);
                  onChangeClick("automatched");
                }}
              >
                <span class="tag mb-1">
                  {" "}
                  {(talentCount && talentCount[0].automatched) ?? 0}
                </span>{" "}
                <div>Automatched</div>
              </button>
            </li>{" "}
            <li class="nav-item">
              <button
                type="button"
                class={
                  tabType.invited
                    ? "nav-link pb-3 mx-2 bg-transparent active"
                    : "nav-link pb-3 mx-2 bg-transparent"
                }
                onClick={() => {
                  setCurrentPage(1);
                  onChangeClick("invited");
                }}
              >
                <span class="tag mb-1">
                  {(talentCount && talentCount[0].invited) ?? 0}
                </span>{" "}
                <div>Invited</div>
              </button>
            </li>{" "}
            <li class="nav-item">
              <button
                type="button"
                class={
                  tabType.submitted
                    ? "nav-link pb-3 mx-2 bg-transparent active"
                    : "nav-link pb-3 mx-2 bg-transparent"
                }
                onClick={() => {
                  setCurrentPage(1);
                  onChangeClick("submitted");
                }}
              >
                <span class="tag mb-1">
                  {(talentCount && talentCount[0].submitted) ?? 0}
                </span>{" "}
                <div>Submitted</div>
              </button>
            </li>{" "}
            <li class="nav-item">
              <button
                type="button"
                class={
                  tabType.shortlisted
                    ? "nav-link pb-3 mx-2 bg-transparent active"
                    : "nav-link pb-3 mx-2 bg-transparent"
                }
                onClick={() => {
                  setCurrentPage(1);
                  onChangeClick("shortlisted");
                }}
              >
                <span class="tag mb-1">
                  {(talentCount && talentCount[0].shortlisted) ?? 0}
                </span>{" "}
                <div>Shortlisted</div>
              </button>
            </li>{" "}
            <li class="nav-item">
              <button
                type="button"
                class={
                  tabType.offered
                    ? "nav-link pb-3 mx-2 bg-transparent active"
                    : "nav-link pb-3 mx-2 bg-transparent"
                }
                onClick={() => {
                  setCurrentPage(1);
                  onChangeClick("offered");
                }}
              >
                <span class="tag mb-1">
                  {(talentCount && talentCount[0].offered) ?? 0}
                </span>{" "}
                <div>Offered</div>
              </button>
            </li>{" "}
            <li class="nav-item">
              <button
                type="button"
                class={
                  tabType.hired
                    ? "nav-link pb-3 mx-2 bg-transparent active"
                    : "nav-link pb-3 mx-2 bg-transparent"
                }
                onClick={() => {
                  setCurrentPage(1);
                  onChangeClick("hired");
                }}
              >
                <span class="tag mb-1">
                  {(talentCount && talentCount[0].hired) ?? 0}
                </span>{" "}
                <div>Hired</div>
              </button>
            </li>{" "}
            <li class="nav-item">
              <button
                type="button"
                class={
                  tabType.disqualified
                    ? "nav-link pb-3 mx-2 bg-transparent active"
                    : "nav-link pb-3 mx-2 bg-transparent"
                }
                onClick={() => {
                  setCurrentPage(1);
                  onChangeClick("disqualified");
                }}
              >
                <span class="tag mb-1">
                  {(talentCount && talentCount[0].disqualified) ?? 0}
                </span>{" "}
                <div>Disqualified</div>
              </button>
            </li>{" "}
          </ul>
          <div className="pb-3" style={selectWrapperStyle}>
            <Select
              options={timeOptions}
              isSearchable={false}
              // onChange={setSelectedTimeOption}
              onChange={(e) => handleTimeChange(e)}
              styles={styleSheet}
              value={selectedTimeOption}
              isMulti={false}
              //isLoading={isLoading}
            />
          </div>
        </div>
        <div style={{ minHeight: "60vh" }}>
          {talentData && (
            <NewTalentList
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              arrayList={talentData}
              setTalentDetails={setTalentDetails}
              getTalentList={getTalentList}
              matches={matches}
              talentDetails={talentData}
              dataTotal={dataTotal}
              handlePageChange={handlePageChange}
              fetchData={fetchData}
            />
          )}
        </div>
        {getTenantType() == 1 && <Footer />}
      </div>
    </>
  );
};

export default NewTalentPoolList;
