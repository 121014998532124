import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getUserRole } from "../../../../../service/AuthService";

const StatusData = (props) => {
  const { candidate } = props;
  const [canId, setCanId] = useState(null);
  const role = getUserRole();
  let statusData = candidate?.statusData.filter((s) =>
    s.status != 33 ? (s.status == 42 ? role !== "HiringManager" : true) : false
  );
  return (
    <div className="mt-1 d-block">
      {statusData?.map((item, index) => {
        if (index < 2 || candidate.userId === canId) {
          if (item.status == 33) {
            return;
          } else if (item.status == 6 || item.status == 42) {
            return (
              <Link
                onClick={
                  getUserRole() == "High5Coordinator" &&
                  ((e) => e.preventDefault())
                }
                to={
                  item?.jobId
                    ? `/High5hire/requests/${item?.jobId}/${item.status}`
                    : `/high5hire/talentcommunity/pools/${item?.fk_talentPool}/${item.status}`
                }
                style={{
                  cursor: getUserRole() == "High5Coordinator" && "default",
                }}
              >
                {item.statusname !== "Invited" && (
                  <span class="tag tag-green3 mr-1 mb-1">
                    <strong>
                      {item.statusname === "Offered"
                        ? item?.jobId
                          ? "Offered"
                          : "Ready To Hire"
                        : item.statusname === "Offer Rejected"
                        ? "Offer Declined"
                        : item.statusname === "Rejected"
                        ? "Disqualified"
                        : item.statusname === "Submitted"
                        ? "Submitted"
                        : item.statusname === "Engaged"
                        ? "Engaged"
                        : item?.statusname === "Vetting"
                        ? "Vetted"
                        : item?.statusname}
                      : {item.jobTitle}
                    </strong>
                  </span>
                )}
              </Link>
            );
          } else if (item.status == 38) {
            return (
              <Link
                onClick={
                  getUserRole() == "High5Coordinator" &&
                  ((e) => e.preventDefault())
                }
                to={
                  item?.jobId
                    ? `/High5hire/requests/${item?.jobId}/${item.status}`
                    : `/high5hire/talentcommunity/pools/${item?.fk_talentPool}/${item.status}`
                }
                style={{
                  cursor: getUserRole() == "High5Coordinator" && "default",
                }}
              >
                <span class="tag tag-green1 mr-1 mb-1">
                  <strong>
                    {item.statusname === "Offered"
                      ? item?.jobId
                        ? "Offered"
                        : "Ready To Hire"
                      : item.statusname === "Offer Rejected"
                      ? "Offer Declined"
                      : item.statusname === "Rejected"
                      ? "Disqualified"
                      : item?.statusname === "Vetting"
                      ? "Vetted"
                      : item?.statusname}
                    : {item.jobTitle}
                  </strong>
                </span>
              </Link>
            );
          } else {
            return (
              <Link
                onClick={
                  getUserRole() == "High5Coordinator" &&
                  ((e) => e.preventDefault())
                }
                to={
                  item?.jobId
                    ? `/High5hire/requests/${item?.jobId}/${item.status}`
                    : `/high5hire/talentcommunity/pools/${item?.fk_talentPool}/${item.status}`
                }
                style={{
                  cursor: getUserRole() == "High5Coordinator" && "default",
                }}
              >
                {item.statusname !== "Invited" && (
                  <span class="tag tag-blue3 mr-1 mb-1">
                    <strong>
                      {item.statusname === "Offered"
                        ? item?.jobId
                          ? "Offered"
                          : "Ready To Hire"
                        : item.statusname === "Offer Rejected"
                        ? "Offer Declined"
                        : item.statusname === "Rejected"
                        ? "Disqualified"
                        : item?.statusname === "Vetting"
                        ? "Vetted"
                        : item?.statusname}
                      : {item.jobTitle}
                    </strong>
                  </span>
                )}
              </Link>
            );
          }
        }
        return true;
      })}

      {candidate?.userId != canId ? (
        statusData?.filter((elem) => elem.status != 33).length > 2 && (
          <button
            class="btn btn-text"
            type="button"
            onClick={() => setCanId(candidate?.userId)}
          >
            +{statusData?.filter((elem) => elem.status != 33).length - 2} more
          </button>
        )
      ) : candidate?.userId == canId ? (
        <button
          class="btn btn-text"
          type="button"
          onClick={() => setCanId(null)}
        >
          show less
        </button>
      ) : (
        ""
      )}
    </div>
  );
};

export default StatusData;
