import React, { useState, useEffect } from "react";
import TopNavBar from "../../layout/TopNavBar";
import SideNavBar from "../../layout/SideNavBar";
import Communications from "./Communications";

const Index = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar handleToggale={() => setToggle(!toggle)} />
        <SideNavBar />

        <div id="Content">
          <div className="section section-sm pt-0-768">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <Communications />
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
    </>
  );
};

export default Index;
