import React from "react";
import Overview from "./overview/Index";
import Screening from "./screening/Index";
import Details from "./details/Index";
import Notes from "./Notes";
import Activity from "./Activity";

import Curator from "./CuratorDetails";
import Source from "./SourceDetail";
import { getUserRole } from "../../../../../../service/AuthService";

const Index = (props) => {
  const role = getUserRole();

  const { jobData, sideView, setLoading, setPoolData } = props;

  return (
    <div>
      <div className="card card-flat card-lg card-top-straight">
        <div className="card-body">
          {sideView === "overview" && <Overview jobData={jobData} />}
          {sideView === "qna" && <Screening jobData={jobData} />}
          {sideView === "activity" && <Activity jobData={jobData} />}
          {sideView === "notes" && role !== "CustomerSuccess" && (
            <Notes jobData={jobData} setLoading={setLoading}  setPoolData={setPoolData}/>
          )}
          {/* {sideView === "details" && <Details jobData={jobData} />} */}

          {role === "CustomerSuccess" && (
            <>
              {sideView === "source" && <Source />}
              {sideView === "curator" && <Curator />}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Index;
