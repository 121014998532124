import {
  GET_JOB_SUMMARY_BEGIN,
  GET_JOB_SUMMARY_SUCCESS,
  GET_JOB_SUMMARY_FAILURE,
  GET_JOB_SUMMARY_COUNT_BEGIN,
  GET_JOB_SUMMARY_COUNT_SUCCESS,
  GET_JOB_SUMMARY_COUNT_FAILURE,
  GET_CLIENT_NAME_BEGIN,
  GET_CLIENT_NAME_SUCCESS,
  GET_CLIENT_NAME_FAILURE,
} from "../../actions/dashboard/JobSummary";

const initialState = {
  loading: false,
  countLoading: false,
  jobData: [],
  jobSummaryCount: [],
  clientName: [],
  nameLoading: false,
};

const getJobSummaryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_JOB_SUMMARY_BEGIN:
      return {
        ...state,
        loading: true,
        jobData: [],
      };
    case GET_JOB_SUMMARY_SUCCESS:
      return {
        ...state,
        jobData: payload,
        loading: false,
      };
    case GET_JOB_SUMMARY_FAILURE:
      return {
        ...state,
        jobData: [],
        loading: false,
      };
    case GET_JOB_SUMMARY_COUNT_BEGIN:
      return {
        ...state,
        countLoading: true,
        jobSummaryCount: [],
      };
    case GET_JOB_SUMMARY_COUNT_SUCCESS:
      return {
        ...state,
        jobSummaryCount: payload,
        countLoading: false,
      };
    case GET_JOB_SUMMARY_COUNT_FAILURE:
      return {
        ...state,
        jobSummaryCount: [],
        countLoading: false,
      };
    case GET_CLIENT_NAME_BEGIN:
      return {
        ...state,
        nameLoading: true,
        clientName: [],
      };
    case GET_CLIENT_NAME_SUCCESS:
      return {
        ...state,
        clientName: payload,
        nameLoading: false,
      };
    case GET_CLIENT_NAME_FAILURE:
      return {
        ...state,
        clientName: [],
        nameLoading: false,
      };
    default:
      return state;
  }
};

export default getJobSummaryReducer;
