import React, { useEffect, useState } from "react";
import PaginationFilter from "../../commoncomponents/pagination/PaginationFilter";

const Filter = (props) => {
  //console.log(props)
  let dataLimit = 4;
  let pageLimit = 5;

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [currentPage3, setCurrentPage3] = useState(1);
  const [currentPage4, setCurrentPage4] = useState(1);
  const [currentPage5, setCurrentPage5] = useState(1);
  const [currentPage6, setCurrentPage6] = useState(1);
  const [currentPage7, setCurrentPage7] = useState(1);
  const [currentPage8, setCurrentPage8] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [searchPrimarySkillsData, setSearchPrimarySkillsData] = useState([]);
  const [searchJobLocationData, setSearchJobLocationData] = useState([]);
  const [searchSourcedData, setSearchSourcedData] = useState([]);
  const [searchNameData, setSearchNameData] = useState([]);
  const [searchTypeData, setSearchTypeData] = useState([]);
  const [searchCompanyData, setSearchCompanyData] = useState([]);
  const [searchPostData, setSearchPostData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchPrimarySkillsValue, setSearchPrimarySkillsValue] = useState("");
  const [searchSkillValue, setSearchSkillValue] = useState("");
  const [searchSourceValue, setSearchSourceValue] = useState("");
  const [searchNameValue, setSearchNameValue] = useState("");
  const [searchPostValue, setSearchPostValue] = useState("");
  const [searchTypeValue, setSearchTypeValue] = useState("");
  const [searchJobLocationValue, setSearchJobLocationValue] = useState("");
  const [searchCompanyValue, setSearchCompanyValue] = useState("");
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [paginationGroup1, setPaginationGroup1] = useState([]);
  const [paginationArray1, setPaginationArray1] = useState([]);
  const [paginationGroup2, setPaginationGroup2] = useState([]);
  const [paginationArray2, setPaginationArray2] = useState([]);
  const [paginationGroup3, setPaginationGroup3] = useState([]);
  const [paginationArray3, setPaginationArray3] = useState([]);
  const [paginationGroup4, setPaginationGroup4] = useState([]);
  const [paginationArray4, setPaginationArray4] = useState([]);
  const [paginationGroup5, setPaginationGroup5] = useState([]);
  const [paginationArray5, setPaginationArray5] = useState([]);
  const [paginationGroup6, setPaginationGroup6] = useState([]);
  const [paginationArray6, setPaginationArray6] = useState([]);
  const [paginationGroup7, setPaginationGroup7] = useState([]);
  const [paginationArray7, setPaginationArray7] = useState([]);
  const [paginationGroup8, setPaginationGroup8] = useState([]);
  const [paginationArray8, setPaginationArray8] = useState([]);
  const [searchLocationValue, setSearchLocationValue] = useState("");
  const [cJobLocation, setCJobLocation] = useState(false);
  const [cPrimarySkills, setCPrimarySkills] = useState(false);
  const [candidateTitle, setCandidateTitle] = useState(false);
  const [candidateType, setCandidateType] = useState(false);

  const titleHandler = (checked, title) => {
    if (checked) {
      props.setSelectedTitles([...props.selectedTitles, title]);
    } else {
      props.setSelectedTitles(props.selectedTitles.filter((t) => t !== title));
    }
  };

  const typeHandler = (TypeChecked, type) => {
    if (TypeChecked) {
      props.setSelectedTypes([...props.selectedTypes, type]);
    } else {
      props.setSelectedTypes(props.selectedTypes.filter((t) => t !== type));
    }
  };

  const skillsHandler = (SkillsChecked, skills) => {
    if (SkillsChecked) {
      props.setSelectedPrimarySkills([...props.selectedPrimarySkills, skills]);
    } else {
      props.setSelectedPrimarySkills(
        props.selectedPrimarySkills.filter((t) => t !== skills)
      );
    }
  };

  const locationHandler = (LocationChecked, location) => {
    if (LocationChecked) {
      props.setSelectedJobLocation([...props.selectedJobLocation, location]);
    } else {
      props.setSelectedJobLocation(
        props.selectedJobLocation.filter((t) => t !== location)
      );
    }
  };

  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  function changePage1(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage1(pageNumber);
  }

  function changePage7(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage7(pageNumber);
  }
  function changePage8(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage8(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : props.candidateTitles.slice(startIndex, endIndex);
  };

  const getPaginatedData1 = () => {
    const startIndex1 = currentPage1 * dataLimit - dataLimit;
    const endIndex1 = startIndex1 + +dataLimit;
    return searchTypeValue
      ? searchTypeData.slice(startIndex1, endIndex1)
      : props.candidateTypes.slice(startIndex1, endIndex1);
  };

  const getPaginatedData7 = () => {
    const startIndex7 = currentPage7 * dataLimit - dataLimit;
    const endIndex7 = startIndex7 + +dataLimit;
    return searchJobLocationValue
      ? searchJobLocationData.slice(startIndex7, endIndex7)
      : props.candidateJobLocation.slice(startIndex7, endIndex7);
  };

  const getPaginatedData8 = () => {
    const startIndex8 = currentPage8 * dataLimit - dataLimit;
    const endIndex8 = startIndex8 + +dataLimit;
    return searchPrimarySkillsValue
      ? searchPrimarySkillsData.slice(startIndex8, endIndex8)
      : props.candidatePrimarySkills.slice(startIndex8, endIndex8);
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(props.candidateTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(props.candidateTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    searchTypeValue
      ? setPaginationArray1(
          new Array(Math.ceil(searchTypeData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray1(
          new Array(Math.ceil(props.candidateTypes.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchTypeValue
      ? setPaginationGroup1(
          new Array(Math.ceil(searchTypeData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup1(
          new Array(Math.ceil(props.candidateTypes.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    searchJobLocationValue
      ? setPaginationArray7(
          new Array(Math.ceil(searchJobLocationData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray7(
          new Array(Math.ceil(props.candidateJobLocation.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchJobLocationValue
      ? setPaginationGroup7(
          new Array(Math.ceil(searchJobLocationData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup7(
          new Array(Math.ceil(props.candidateJobLocation.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    searchPrimarySkillsValue
      ? setPaginationArray8(
          new Array(Math.ceil(searchPrimarySkillsData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray8(
          new Array(Math.ceil(props.candidatePrimarySkills.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchPrimarySkillsValue
      ? setPaginationGroup8(
          new Array(Math.ceil(searchPrimarySkillsData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup8(
          new Array(Math.ceil(props.candidatePrimarySkills.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    setCurrentPage(1);
    setCurrentPage1(1);
    setCurrentPage2(1);
    setCurrentPage3(1);
    setCurrentPage4(1);
    setCurrentPage5(1);
    setCurrentPage6(1);
    setCurrentPage7(1);
    setCurrentPage8(1);
  }, [
    props.candidateTitles,
    props.candidateTypes,
    props.candidateJobLocation,
    props.candidatePrimarySkills,
  ]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      } else if ((currentPage1 - 1) % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(currentPage1 - 1, currentPage1 + (5 - 1))
        );
      } else if ((currentPage2 - 1) % pageLimit === 0 && currentPage2 !== 1) {
        setPaginationGroup2(
          paginationArray2.slice(
            currentPage2 - 1,
            currentPage2 + (pageLimit - 1)
          )
        );
      } else if ((currentPage3 - 1) % pageLimit === 0 && currentPage3 !== 1) {
        setPaginationGroup3(
          paginationArray3.slice(
            currentPage3 - 1,
            currentPage3 + (pageLimit - 1)
          )
        );
      } else if ((currentPage4 - 1) % pageLimit === 0 && currentPage4 !== 1) {
        setPaginationGroup4(
          paginationArray4.slice(
            currentPage4 - 1,
            currentPage4 + (pageLimit - 1)
          )
        );
      } else if ((currentPage5 - 1) % pageLimit === 0 && currentPage5 !== 1) {
        setPaginationGroup5(
          paginationArray5.slice(
            currentPage5 - 1,
            currentPage5 + (pageLimit - 1)
          )
        );
      } else if ((currentPage6 - 1) % pageLimit === 0 && currentPage6 !== 1) {
        setPaginationGroup6(
          paginationArray6.slice(
            currentPage6 - 1,
            currentPage6 + (pageLimit - 1)
          )
        );
      } else if ((currentPage7 - 1) % pageLimit === 0 && currentPage7 !== 1) {
        setPaginationGroup7(
          paginationArray7.slice(
            currentPage7 - 1,
            currentPage7 + (pageLimit - 1)
          )
        );
      } else if ((currentPage8 - 1) % pageLimit === 0 && currentPage8 !== 1) {
        setPaginationGroup8(
          paginationArray8.slice(
            currentPage8 - 1,
            currentPage8 + (pageLimit - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      } else if (currentPage1 % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(currentPage1 - 5, currentPage1)
        );
      } else if (currentPage2 % pageLimit === 0 && currentPage2 !== 1) {
        setPaginationGroup2(
          paginationArray2.slice(currentPage2 - pageLimit, currentPage2)
        );
      } else if (currentPage3 % pageLimit === 0 && currentPage3 !== 1) {
        setPaginationGroup3(
          paginationArray3.slice(currentPage3 - pageLimit, currentPage3)
        );
      } else if (currentPage4 % pageLimit === 0 && currentPage4 !== 1) {
        setPaginationGroup4(
          paginationArray4.slice(currentPage4 - pageLimit, currentPage4)
        );
      } else if (currentPage5 % pageLimit === 0 && currentPage5 !== 1) {
        setPaginationGroup5(
          paginationArray5.slice(currentPage5 - pageLimit, currentPage5)
        );
      } else if (currentPage6 % pageLimit === 0 && currentPage6 !== 1) {
        setPaginationGroup6(
          paginationArray6.slice(currentPage6 - pageLimit, currentPage6)
        );
      } else if (currentPage7 % pageLimit === 0 && currentPage7 !== 1) {
        setPaginationGroup7(
          paginationArray7.slice(currentPage7 - pageLimit, currentPage7)
        );
      } else if (currentPage8 % pageLimit === 0 && currentPage8 !== 1) {
        setPaginationGroup8(
          paginationArray7.slice(currentPage8 - pageLimit, currentPage8)
        );
      }
    }
  }, [
    currentPage,
    currentPage1,
    currentPage2,
    currentPage3,
    currentPage4,
    currentPage5,
    currentPage6,
    currentPage7,
    currentPage8,
  ]);

  const splitMatchedText = (str) => {
    let regExp;
    if (searchValue) {
      regExp = new RegExp(searchValue, "gi");
    }
    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchData(
      props.candidateTitles.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearchType = (e) => {
    setSearchTypeValue(e.target.value);
    setSearchTypeData(
      props.candidateTypes.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearchSkills = (e) => {
    setSearchPrimarySkillsValue(e.target.value);
    setSearchPrimarySkillsData(
      props.candidatePrimarySkills.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearchJobLocation = (e) => {
    setSearchJobLocationValue(e.target.value);
    setSearchJobLocationData(
      props.candidateJobLocation.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const titleArray = (title) => {
    return props.selectedTitles.length > 0
      ? props.selectedTitles.includes(title.toLowerCase())
      : true;
  };

  const typeArray = (type) => {
    return props.selectedTypes.length > 0
      ? props.selectedTypes.includes(type.toLowerCase())
      : true;
  };

  const LocationArray = (Location) => {
    return props.selectedJobLocation.length > 0
      ? props.selectedJobLocation.includes(Location.toLowerCase())
      : true;
  };
  const skillsArray = (skill) => {
    return props.selectedPrimarySkills?.length > 0
      ? skill && props.selectedPrimarySkills.some((i) => skill?.includes(i))
      : true;
  };

  const clearAll = () => {
    props.setSelectedTitles([]);
    props.setSelectedTypes([]);
    props.setSelectedJobLocation([]);
    props.setSelectedPrimarySkills([]);
    setSearchValue("");
    setSearchPrimarySkillsValue("");
    setSearchJobLocationValue("");
    setSearchTypeValue("");
  };

  const applyFilter = () => {
    let finalData = props.reportData.filter((item) => {
      return (
        titleArray(item.jobTitle) &&
        typeArray(item.jobType) &&
        // item.primarySkills !== null &&
        // JSON.parse(item.primarySkills).length !== 0 &&
        skillsArray(
          item?.primarySkills &&
            JSON.parse(item?.primarySkills)?.map((skill) => skill)
        ) &&
        LocationArray(item?.jobLocation)
      );
    });
    console.log(finalData);
    props.applyFilter(finalData);
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={applyFilter}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>
                Filter /{" "}
                {props.selectedTitles.length +
                  props.selectedTypes.length +
                  props.selectedPrimarySkills.length +
                  props.selectedJobLocation.length}
              </h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear All
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          {/* JOB TITLE */}
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateTitle(!candidateTitle)}
            >
              <i
                className={
                  candidateTitle
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Job Title</small>
                {props.selectedTitles.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {candidateTitle &&
              (props.candidateTitles.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-3"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  {getPaginatedData().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={props.selectedTitles.includes(j)}
                            className="form-check-input"
                            onChange={(e) => titleHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No records found for {searchValue}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {(getPaginatedData().length === dataLimit
                        ? dataLimit * currentPage
                        : dataLimit * (currentPage - 1) +
                          getPaginatedData().length) + " "}
                      of {props.candidateTitles.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        setPaginationArray={setPaginationArray}
                        data={props.candidateTitles}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue("");
                        props.setSelectedTitles([]);
                      }}
                    >
                      <small>Clear Job Title</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              ))}
          </div>

          {/* JOB TYPE */}
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateType(!candidateType)}
            >
              <i
                className={
                  candidateType
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Job Type</small>
                {props.selectedTypes.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {candidateType &&
              (props.candidateTypes.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-3"
                    onChange={handleSearchType}
                    value={searchTypeValue}
                  />
                  {getPaginatedData1().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchTypeValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={props.selectedTypes.includes(j)}
                            className="form-check-input"
                            onChange={(e) => typeHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData1().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No records found for {searchTypeValue}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {(getPaginatedData1().length === dataLimit
                        ? dataLimit * currentPage1
                        : dataLimit * (currentPage1 - 1) +
                          getPaginatedData1().length) + " "}
                      of {props.candidateTypes.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage1}
                        setCurrentPage={setCurrentPage1}
                        setPaginationArray={setPaginationArray1}
                        data={props.candidateTypes}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchTypeValue("");
                        props.setSelectedTypes([]);
                      }}
                    >
                      <small>Clear Job Type</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              ))}
          </div>

          {/* PRIMARY SKILLS */}
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCPrimarySkills(!cPrimarySkills)}
            >
              <i
                className={
                  cPrimarySkills
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Candidate Primary Skills</small>
                {props.selectedPrimarySkills.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {cPrimarySkills &&
              (props.candidatePrimarySkills.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearchSkills}
                    value={searchPrimarySkillsValue}
                  />
                  {getPaginatedData8().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchPrimarySkillsValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={props.selectedPrimarySkills.includes(j)}
                            className="form-check-input"
                            onChange={(e) => skillsHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData8().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No records found for {searchPrimarySkillsValue}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {(getPaginatedData8().length === dataLimit
                        ? dataLimit * currentPage8
                        : dataLimit * (currentPage8 - 1) +
                          getPaginatedData8().length) + " "}
                      of {props.candidatePrimarySkills.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage8}
                        setCurrentPage={setCurrentPage8}
                        setPaginationArray={setPaginationArray8}
                        data={props.candidatePrimarySkills}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchPrimarySkillsValue("");
                        props.setSelectedPrimarySkills([]);
                      }}
                    >
                      <small>Clear Skills</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              ))}
          </div>

          {/* JOB LOCATION  */}
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCJobLocation(!cJobLocation)}
            >
              <i
                className={
                  cJobLocation
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Job Location</small>
                {props.selectedJobLocation.length > 0 && (
                  <span className="jewel"></span>
                )}
              </span>
            </button>
            {cJobLocation &&
              (props.candidateJobLocation.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearchJobLocation}
                    value={searchJobLocationValue}
                  />
                  {getPaginatedData7().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchJobLocationValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={props.selectedJobLocation.includes(j)}
                            className="form-check-input"
                            onChange={(e) =>
                              locationHandler(e.target.checked, j)
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData7().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No records found for {searchJobLocationValue}
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {(getPaginatedData7().length === dataLimit
                        ? dataLimit * currentPage7
                        : dataLimit * (currentPage7 - 1) +
                          getPaginatedData7().length) + " "}
                      of {props.candidateJobLocation.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <PaginationFilter
                        currentPage={currentPage7}
                        setCurrentPage={setCurrentPage7}
                        setPaginationArray={setPaginationArray7}
                        data={props.candidateJobLocation}
                        dataSize={dataLimit}
                        pageSize={pageLimit}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchJobLocationValue("");
                        props.setSelectedJobLocation([]);
                      }}
                    >
                      <small>Clear Job Locations</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default Filter;
