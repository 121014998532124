import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { skillOptions } from "../../../constants/Constants";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  getCandidateId,
  getUserLastUpdated,
  getUserName,
} from "../../../service/AuthService";
import { withRouter } from "react-router-dom";
import {
  updateProfile,
  fetchRecruiterData,
  fetchDropDownList,
} from "../../../actions/updateprofile/updateProfileAction";
import AddJobNewGoogleMaps from "../../location/AddJobNewGoogleMaps";
import { post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../Loader/Loader";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import moment from "moment";

const animatedComponents = makeAnimated();
const UpdateProfileInfo = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    profileData,
    recData,
    dropDownData,
    profileDataUpdate,
    loading,
    dropdownLoading,
    recruiterDataLoading,
  } = useSelector(
    (state) => ({
      profileData: state.updateProfileReducer.profileData,
      recData: state.updateProfileReducer.recData,
      dropDownData: state.updateProfileReducer.dropDownData,
      profileDataUpdate: state.updateProfileReducer.profileDataUpdate,
      loading: state.updateProfileReducer.loading,
      recruiterDataLoading: state.recruiterData.recruiterDataLoading,
      dropdownLoading: state.dropdownValuesReducer.dropdownLoading,
    }),
    shallowEqual
  );

  const initialProfileInfo = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    mobilePhoneCode: "",
    skills: [],
    location: "",
    cityName: "",
    stateName: "",
    CountryName: "",
    address1: "",
    zipcode: "",
    regions: [],
    languages: [],
    jobTitle: {
      title1: "",
      title2: "",
      title3: "",
    },
    industries: [],
    subCategory: [],
    yearsOfExperience: "",
  };
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [lastUpdated, setLastUpdated] = useState("");
  const [state, setState] = useState({
    profileInfo: initialProfileInfo,
    jobCategoryOptions: [],
    jobCategoryOptionsAll: [],
    subCategoryOptions: [],
    subCategoryOptionsAll: [],
    skills: [],
    regions: [],
    languages: [],
    experienceOptions: [],
  });

  const [validation, setValidation] = useState({
    mobile: "",
    mobilePhoneCode: "",
    skills: "",
    location: "",
    cityName: "",
    stateName: "",
    CountryName: "",
    regions: "",
    languages: "",
    jobTitle: "",
    industries: "",
    subCategory: "",
    yearsOfExperience: "",
  });
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [count, setCount] = useState(true);
  const searchInputRef = useRef(recData?.[0]?.CountryName || null);
  const styles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: "white",
      zIndex: 4,
    }),
  };
  useEffect(() => {
    let userid = getCandidateId();
    dispatch(fetchRecruiterData({ userid }));
    dispatch(fetchDropDownList());
  }, []);

  const handleKeyDown = (event) => {
    // Prevent typing space in the Select input
    if (event.key === ' ') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    if (profileData && Object.keys(profileData).length > 0) {
      setState((prevState) => ({
        ...prevState,
        profileInfo: profileData,
      }));
    }
  }, [profileData]);

  useEffect(() => {
    if (skillOptions && skillOptions.length > 0) {
      setState((prevState) => ({
        ...prevState,
        skills: skillOptions,
      }));
    }
  }, []);

  useEffect(async () => {
    if (!dropDownData || dropDownData.length === 0) {
      return;
    }
    const industriesData = dropDownData
      .filter((element) => element.typeName === "skillSet")
      .map((element) => ({ value: element.name, label: element.name }));

    const languageData = dropDownData
      .filter((element) => element.typeName === "languages")
      .map((element) => ({ value: element.name, label: element.name }));

    let regionData = dropDownData
      .filter((element) => element.typeName === "regions")
      .map((element) => ({
        value: element.name,
        label: element.name,
        disabled: false,
      }));

    const experienceData = dropDownData
      .filter((element) => element.typeName === "experienceYear")
      .map((element) => ({ value: element.name, label: element.name }));
    let updatedProfileInfo = { ...state.profileInfo };

    if (recData && recData.length > 0) {
      const normalizedData = recData.map((item) => {
        const normalizedItem = { ...item };
        // Parse stringified JSON properties
        normalizedItem.industries = JSON.parse(normalizedItem.industries);
        normalizedItem.skills = JSON.parse(normalizedItem.skills);
        normalizedItem.jobTitle = JSON.parse(normalizedItem.jobTitle);
        normalizedItem.regions = JSON.parse(normalizedItem.regions);
        normalizedItem.languages = JSON.parse(normalizedItem.languages);
        normalizedItem.subCategory = JSON.parse(normalizedItem.subCategory);
        return normalizedItem;
      });
      updatedProfileInfo = {
        ...state.profileInfo,
        ...normalizedData[0], // Assuming recData contains a single object
      };
      if (
        updatedProfileInfo.industries &&
        updatedProfileInfo.industries.length > 0
      ) {
        let industries = updatedProfileInfo.industries;
        const result = industries.map((name) => {
          const matchedObject = dropDownData.find((obj) => obj.name === name);
          return matchedObject ? matchedObject.id : null;
        });
        setCategoryLoading(true);
        const payload = {
          industry: result,
        };
        try {
          const res = await post("/common/subcategory", payload);
          setCount(false);
          setCategoryLoading(false);
          if (res.status == 200) {
            let subCategoryData = [
              {
                value: "All",
                label: "All",
              },
            ];
            res.data.map((item) => {
              subCategoryData = [
                ...subCategoryData,
                {
                  value: item.categoryName,
                  label: item.categoryName,
                },
              ];
            });
            setState({
              ...state,
              subCategoryOptions: subCategoryData,
              subCategoryOptionsAll: subCategoryData,
            });
          } else {
            toast.error("Oops! something went wrong. please try again");
          }
        } catch (error) {
          toast.error("Oops! something went wrong. please try again");
          setCategoryLoading(false);
        }
      }
    }
    if (updatedProfileInfo.regions && updatedProfileInfo.regions.length > 0) {
      const regions = updatedProfileInfo.regions;
      if (regions.includes("Global - GL")) {
        let temp = regionData.map((item) => {
          item.disabled = true;
          return item;
        });
        regionData = temp;
      } else if (regions.length == 0) {
        let temp = regionData.map((item) => {
          item.disabled = false;
          return item;
        });
        regionData = temp;
      } else {
        let temp = regionData.map((item) => {
          if (item.value == "Global - GL") {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
        regionData = temp;
      }
    }
    setState((prevState) => ({
      ...prevState,
      jobCategoryOptions: [
        ...industriesData,
        {
          value: "All",
          label: "All",
        },
      ],
      jobCategoryOptionsAll: [
        ...industriesData,
        {
          value: "All",
          label: "All",
        },
      ],
      languages: languageData,
      experienceOptions: experienceData,
      regions: regionData,
      profileInfo: updatedProfileInfo,
    }));
    setLastUpdated(
      moment(new Date(updatedProfileInfo.updatedDateTime), "YYYYMMDD").format(
        "MM/DD/YYYY"
      )
    );
  }, [dropDownData, recData]);

  const experienceOptions = [];
  for (let years = 0; years <= 20; years++) {
    experienceOptions.push({
      value: years.toString(),
      label: years === 1 ? "1 year" : `${years} years`,
    });
  }
  // Callback function to update location data
  const setLocation = (addressData) => {
    setState({
      ...state,
      profileInfo: {
        ...state.profileInfo,
        CountryName: addressData?.country,
        cityName: addressData?.city,
        stateName: addressData?.state,
        address1: addressData?.city
          ? addressData?.city +
            ", " +
            addressData?.state +
            ", " +
            addressData?.country
          : "",
        zipcode: addressData?.zip || "",
      },
    });
  };

  const handleChange = (e) => {
    if (["title1", "title2", "title3"].includes(e.target.name)) {
      if (e.target.name === "title1") {
        validation.jobTitle && setValidation({ ...validation, jobTitle: "" });
      }
      setState((prevState) => ({
        ...prevState,
        profileInfo: {
          ...prevState.profileInfo,
          jobTitle: {
            ...prevState.profileInfo.jobTitle,
            [e.target.name]: e.target.value,
          },
        },
      }));
    } else {
      setState({
        ...state,
        profileInfo: {
          ...state.profileInfo,
          [e.target.name]: e.target.value,
        },
      });
    }
  };

  const generateMultiSelectHandler = (id, value) => {
    if (id === "yearsOfExperience" && validation.experience) {
      setValidation({ ...validation, experience: "" });
    }
    if (id === "industry" && validation.industry) {
      setValidation({ ...validation, industry: "" });
    }
    if (id === "skills") {
      if (value.includes("All")) {
        setState({
          ...state,
          profileInfo: {
            ...state.profileInfo,
            skills: skillOptions
              .filter((item) => item.value !== "All")
              .map((i) => i.label),
          },
          skills: [],
        });
      } else {
        setState({
          ...state,
          profileInfo: {
            ...state.profileInfo,
            [id]: value,
          },
          skills: skillOptions.filter((item) => !value.includes(item.value)),
        });
      }
    } else if (id === "regions") {
      if (value.includes("Global - GL")) {
        let temp = state.regions.map((item) => {
          item.disabled = true;
          return item;
        });
        setState({
          ...state,
          profileInfo: {
            ...state.profileInfo,
            [id]: ["Global - GL"],
          },
          regions: temp,
        });
      } else if (value.length == 0) {
        let temp = state.regions.map((item) => {
          item.disabled = false;
          return item;
        });
        setState({
          ...state,
          profileInfo: {
            ...state.profileInfo,
            [id]: value,
          },
          regions: temp,
        });
      } else {
        let temp = state.regions.map((item) => {
          if (item.value == "Global - GL") {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
        setState({
          ...state,
          profileInfo: {
            ...state.profileInfo,
            [id]: value,
          },
          regions: temp,
        });
      }
    } else if (id === "subCategory") {
      if (value.includes("All")) {
        setState({
          ...state,
          profileInfo: {
            ...state.profileInfo,
            subCategory: state.subCategoryOptionsAll
              .filter((item) => item.value !== "All")
              .map((i) => i.label),
          },
          subCategoryOptions: [],
        });
      } else {
        setState({
          ...state,
          profileInfo: {
            ...state.profileInfo,
            [id]: value,
          },
          subCategoryOptions: state.subCategoryOptionsAll.filter(
            (item) => !value.includes(item.value)
          ),
        });
      }
    } else {
      setState({
        ...state,
        profileInfo: {
          ...state.profileInfo,
          [id]: value,
        },
      });
    }
  };

  const handleChangeIndustry = async (val) => {
    if (val.length > 0) {
      let industry = [];
      let jobCategory = [];
      if (val.includes("All")) {
        industry = dropDownData.filter((item) => item.typeName == "skillSet");
        industry = industry.map((item) => item.id);
        jobCategory = [];
      } else {
        industry = dropDownData.filter(
          (item) => val.includes(item.name) && item.typeName == "skillSet"
        );
        industry = industry.map((item) => item.id);
        jobCategory = state.jobCategoryOptionsAll.filter(
          (item) => !val.includes(item.value)
        );
      }
      const payload = {
        industry,
      };
      try {
        setCategoryLoading(true);
        const res = await post("/common/subcategory", payload);
        setCategoryLoading(false);
        if (res.status == 200) {
          let subCategoryData = [
            {
              value: "All",
              label: "All",
            },
          ];
          res.data.map((item) => {
            subCategoryData = [
              ...subCategoryData,
              {
                value: item.categoryName,
                label: item.categoryName,
              },
            ];
          });
          setState({
            ...state,
            profileInfo: {
              ...state.profileInfo,
              industries: val,
              subCategory: [],
            },
            subCategoryOptions: subCategoryData,
            subCategoryOptionsAll: subCategoryData,
            jobCategoryOptions: jobCategory,
          });
          if (val.includes("All")) {
            setState({
              ...state,
              profileInfo: {
                ...state.profileInfo,
                industries: state.jobCategoryOptionsAll
                  .filter((item) => item.value !== "All")
                  .map((i) => i.label),
                subCategory: res.data.map((item) => item.categoryName),
              },
            });
          }
        } else {
          toast.error("Oops! something went wrong. please try again");
        }
      } catch (error) {
        toast.error("Oops! something went wrong. please try again");
        setCategoryLoading(false);
      }
    } else {
      setState({
        ...state,
        profileInfo: {
          ...state.profileInfo,
          industries: val,
          subCategory: val,
        },
        jobCategoryOptions: state.jobCategoryOptionsAll,
        subCategoryOptions: [],
        subCategoryOptionsAll: [],
      });
    }
  };

  const _onChangeText = (value, code, name) => {
    if (value) {
      const asYouType = new AsYouType(code);
      asYouType.input(value);
      setPhoneValidation({ ...phoneValidation, [name]: asYouType.isValid() });
    } else {
      setPhoneValidation({ ...phoneValidation, [name]: true });
    }
  };

  const handlePhoneChange = (value, country) => {
    const mobile = value.replace(country.dialCode, "");
    setState({
      ...state,
      profileInfo: {
        ...state.profileInfo,
        mobile: mobile,
        mobilePhoneCode: `+${country.dialCode}`,
      },
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validationFn(state.profileInfo)) {
      toast.error("Please fill all the mandatory field");
    } else {
      try {
        let reqData1 = {};
        let reqData2 = {};
        let addGig = false;
        if (recData && recData?.[0]?.id_addgig) {
          addGig = true;
          reqData1 = {
            firstName: recData && recData?.[0]?.firstName,
            middleName: recData && recData?.[0]?.middleName,
            lastName: recData && recData?.[0]?.lastName,
            zipcode: state?.profileInfo?.zipcode,
            address1: state?.profileInfo?.address1,
            cityName: state?.profileInfo?.cityName,
            stateName: state?.profileInfo?.stateName,
            CountryName: state?.profileInfo?.CountryName,
            homeTelephoneNo: recData && recData?.[0]?.homeTelephoneNo,
            homeTelephoneCode: recData && recData?.[0]?.homeTelephoneCode,
            mobile: state?.profileInfo?.mobile,
            mobilePhoneCode: state?.profileInfo?.mobilePhoneCode,
            workTelephoneNo: recData && recData?.[0]?.workTelephoneNo,
            candidateId: recData && recData?.[0]?.userId,
            updatedby: recData && recData?.[0]?.ID_user,
          };
          reqData2 = {
            email: getUserName(),
            industries: JSON.stringify(state.profileInfo.industries),
            subCategory: JSON.stringify(state.profileInfo.subCategory),
            regions: JSON.stringify(state.profileInfo.regions),
            languages: JSON.stringify(state.profileInfo.languages),
            yearsOfExperience: state.profileInfo.yearsOfExperience,
            skills: JSON.stringify(state.profileInfo.skills),
            jobTitle: JSON.stringify(state.profileInfo.jobTitle),
          };
        } else {
          addGig = false;
          reqData1 = {
            firstName: recData && recData?.[0]?.firstName,
            middleName: recData && recData?.[0]?.middleName,
            lastName: recData && recData?.[0]?.lastName,
            mobile: state?.profileInfo?.mobile,
            mobilePhoneCode: state?.profileInfo?.mobilePhoneCode,
            cityName: state?.profileInfo?.cityName,
            stateName: state?.profileInfo?.stateName,
            CountryName: state?.profileInfo?.CountryName,
            zipcode: state?.profileInfo?.zipcode,
            address1: state?.profileInfo?.address1,
            homeTelephoneNo: recData && recData?.[0]?.homeTelephoneNo,
            homeTelephoneCode: recData && recData?.[0]?.homeTelephoneCode,
            workTelephoneNo: recData && recData?.[0]?.workTelephoneNo,
            candidateId: recData && recData?.[0]?.userId,
            updatedby: recData && recData?.[0]?.ID_user,
          };
        }
        dispatch(updateProfile(reqData1, reqData2, addGig));
      } catch (error) {
        toast.error("Oops! something went wrong. please try again later");
      }
    }
  };
  useEffect(() => {
    if (profileDataUpdate) {
      history.push("/high5Hire");
    }
  }, [profileDataUpdate]);

  const validationFn = (data) => {
    let industriesError = "";
    let subCategoryError = "";
    let regionsError = "";
    let languagesError = "";
    let yearsOfExperienceError = "";
    let jobTitleError = "";
    let mobileError = "";
    let skillsError = "";
    let cityNameError = "";
    let stateNameError = "";
    let CountryNameError = "";

    if (!data.mobile || data.mobile === "") {
      mobileError = "*Required field";
    }
    if (!phoneValidation.mobile) {
      mobileError = "*Invalid phone number";
    }
    if (!data.cityName || data.cityName === "") {
      cityNameError = "*Required field";
    }
    if (!data.stateName || data.stateName === "") {
      stateNameError = "*Required field";
    }
    if (!data.CountryName || data.CountryName === "") {
      CountryNameError = "*Required field";
    }

    if (recData && recData?.[0]?.id_addgig !== null) {
      if (!data?.jobTitle?.title1 || data?.jobTitle?.title1 === "") {
        jobTitleError = "*Required field";
      }
      if (!data.industries || data.industries.length === 0) {
        industriesError = "*Required field";
      }
      if (!data.subCategory || data.subCategory.length === 0) {
        subCategoryError = "*Required field";
      }
      if (!data.regions || data.regions.length === 0) {
        regionsError = "*Required field";
      }
      if (!data.languages || data.languages.length === 0) {
        languagesError = "*Required field";
      }
      if (!data.skills || data.skills.length === 0) {
        skillsError = "*Required field";
      }

      if (!data.yearsOfExperience || data.yearsOfExperience === "") {
        yearsOfExperienceError = "*Required field";
      }
    }
    if (
      industriesError ||
      subCategoryError ||
      regionsError ||
      languagesError ||
      yearsOfExperienceError ||
      jobTitleError ||
      mobileError ||
      skillsError ||
      cityNameError ||
      stateNameError ||
      CountryNameError
    ) {
      setValidation({
        mobile: mobileError,
        skills: skillsError,
        cityName: cityNameError,
        stateName: stateNameError,
        CountryName: CountryNameError,
        regions: regionsError,
        languages: languagesError,
        jobTitle: jobTitleError,
        industries: industriesError,
        subCategory: subCategoryError,
        yearsOfExperience: yearsOfExperienceError,
      });
      return true;
    } else {
      setValidation({
        mobile: "",
        skills: "",
        cityName: "",
        stateName: "",
        CountryName: "",
        regions: "",
        languages: "",
        jobTitle: "",
        industries: "",
        subCategory: "",
        yearsOfExperience: "",
      });
      return false;
    }
  };
  return (
    <>
      {(loading || recruiterDataLoading || categoryLoading) && <Loader />}
      <div className="d-flex justify-content-center align-items-center vh-200">
        <div style={{ margin: "10px 80px", width: "100%" }}>
          <div className="card card-lg">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">Enhance Your Recruitment Journey</h6>
              </div>
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <p className="mb-2">
                  Take a moment to refresh your profile, ensuring you’re
                  receiving with the most relevant job opportunities.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-9">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="firstName"
                              value={state?.profileInfo?.firstName}
                              readOnly
                            />
                            <label>First Name</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="lastName"
                              value={state?.profileInfo?.lastName}
                              readOnly
                            />
                            <label>Last Name</label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-md-6 mt-4">
                          <div className="form-floating mt-3">
                            <input
                              type="text"
                              className="form-control"
                              id="emailId"
                              value={state?.profileInfo?.email}
                              readOnly
                            />
                            <label>Email ID</label>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label>Phone Number*</label>
                          <div
                            className="form-floating"
                            style={{ zIndex: "999" }}
                          >
                            <PhoneInput
                              value={
                                state?.profileInfo?.mobilePhoneCode
                                  ? `${state?.profileInfo?.mobilePhoneCode}${state?.profileInfo?.mobile}`
                                  : state?.profileInfo?.mobile
                              }
                              name="mobile"
                              country={"us"}
                              onChange={(value, country) => {
                                _onChangeText(
                                  value.replace(country.dialCode, ""),
                                  country.countryCode.toUpperCase(),
                                  "mobile"
                                );
                                handlePhoneChange(value, country);
                              }}
                              placeholder="Phone Number*"
                              inputClass={
                                validation.mobile
                                  ? "form-control border border-danger w-100"
                                  : "form-control w-100"
                              }
                              specialLabel="Phone Number*"
                              specialLabelStyle={{ display: "block" }}
                              enableSearch={true}
                            />
                          </div>
                          {validation.mobile && (
                            <small className="validation">
                              {validation.mobile}
                            </small>
                          )}{" "}
                        </div>
                      </div>

                      {recData && recData?.[0]?.id_addgig !== null ? (
                        <>
                          <div className="row">
                            <div className="col-md-4">
                              <label htmlFor="languages">
                                What languages can you speak?*
                              </label>
                              <div
                                className="form-floating"
                                style={{ zIndex: 7 }}
                              >
                                <Select
                                  isMulti
                                  options={state.languages}
                                  styles={styles}
                                  value={
                                    state.profileInfo.languages
                                      ? state.languages.filter((f) =>
                                          state.profileInfo.languages.includes(
                                            f.value
                                          )
                                        )
                                      : []
                                  }
                                  name="LanguageSkill"
                                  isSearchable={true}
                                  className={
                                    validation.languages &&
                                    "border border-danger"
                                  }
                                  onChange={(value) =>
                                    generateMultiSelectHandler(
                                      "languages",
                                      value?.map((i) => i.value)
                                    )
                                  }
                                ></Select>
                              </div>
                              {validation.languages && (
                                <small className="validation ml-2">
                                  {validation.languages}
                                </small>
                              )}
                            </div>
                            <div className="col-md-8">
                              <label htmlFor="yearsOfExperience">
                                How many years of experience do you have in
                                recruiting?
                              </label>
                              <div
                                className="form-floating"
                                style={{ zIndex: 7 }}
                              >
                                <Select
                                  options={state.experienceOptions}
                                  value={
                                    state.profileInfo.yearsOfExperience
                                      ? state.experienceOptions.filter(
                                          (f) =>
                                            f.value ==
                                            state.profileInfo.yearsOfExperience
                                        )
                                      : []
                                  }
                                  name="Experience"
                                  className={
                                    validation.experience &&
                                    "border border-danger"
                                  }
                                  isSearchable={true}
                                  onChange={(val) =>
                                    generateMultiSelectHandler(
                                      "yearsOfExperience",
                                      val.value
                                    )
                                  }
                                ></Select>
                              </div>
                              <small className="validation ml-2">
                                {validation.yearsOfExperience}
                              </small>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                            <label htmlFor="Industry">
                                In which job category do you recruit?*
                              </label>
 <div
                                className="form-floating"
                                style={{ zIndex: 6 }}
                              >
                                <Select
                                  isMulti
                                  options={state.jobCategoryOptions}
                                  value={
                                    state.profileInfo.industries
                                      ? state.jobCategoryOptionsAll.length -
                                          1 ==
                                        state.profileInfo.industries.length
                                        ? [{ label: "All", value: "All" }]
                                        : state.jobCategoryOptionsAll.filter(
                                            (f) =>
                                              state.profileInfo.industries.includes(
                                                f.value
                                              )
                                          )
                                      : []
                                  }
                                  name="Industry"
                                  isSearchable={true}
                                  className={
                                    validation.industries &&
                                    "border border-danger"
                                  }
                                  onKeyDown={handleKeyDown}
                                  onChange={(value) =>
                                    handleChangeIndustry(
                                      value?.map((i) => i.value)
                                    )
                                  }
                                ></Select>
                              </div>
                              <small className="validation ml-2">
                                {validation.industries}
                              </small>
                            </div>
                            <div className="col-md-6">
                              <label>Sub category?*</label>
                              <div
                                className="form-floating"
                                style={{ zIndex: 6 }}
                              >
                                <Select
                                  isMulti
                                  options={state.subCategoryOptions.sort(
                                    (a, b) => b.label - a.label
                                  )}
                                  value={
                                    state.profileInfo.subCategory
                                      ? state.subCategoryOptionsAll.length -
                                          1 ==
                                        state.profileInfo.subCategory.length
                                        ? [{ label: "All", value: "All" }]
                                        : state.subCategoryOptionsAll.filter(
                                            (f) =>
                                              state.profileInfo.subCategory.includes(
                                                f.value
                                              )
                                          )
                                      : []
                                  }
                                  name="subCategory"
                                  isSearchable={true}
                                  className={
                                    validation.subCategory &&
                                    "border border-danger"
                                  }
                                  onChange={(value) =>
                                    generateMultiSelectHandler(
                                      "subCategory",
                                      value?.map((i) => i.value)
                                    )
                                  }
                                  isDisabled={
                                    state.profileInfo.industries.length == 0 ||
                                    state.jobCategoryOptionsAll.length - 1 ==
                                      state.profileInfo.industries.length
                                  }
                                ></Select>
                              </div>
                              <small className="validation ml-2">
                                {validation.subCategory}
                              </small>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <label>What regions have you recruited?*</label>
                              <div
                                className="form-floating"
                                style={{ zIndex: 5 }}
                              >
                                <Select
                                  id="regions"
                                  name="regions"
                                  className={
                                    validation.regions && "border border-danger"
                                  }
                                  closeMenuOnSelect={false}
                                  components={animatedComponents}
                                  value={
                                    state.profileInfo.regions
                                      ? state.regions.filter((f) =>
                                          state.profileInfo.regions.includes(
                                            f.value
                                          )
                                        )
                                      : []
                                  }
                                  isMulti
                                  options={state.regions}
                                  isOptionDisabled={(regionOption) =>
                                    regionOption.disabled
                                  }
                                  onChange={(value) =>
                                    generateMultiSelectHandler(
                                      "regions",
                                      value?.map((i) => i.value)
                                    )
                                  }
                                />
                              </div>
                              {validation.regions && (
                                <small className="validation ml-2">
                                  {validation.regions}
                                </small>
                              )}
                            </div>
                            <div className="col-md-6">
                              <label>
                                Which types of roles do you recruit?*
                              </label>
                              <div
                                className="form-floating"
                                style={{ zIndex: 5 }}
                              >
                                <Select
                                  isMulti
                                  options={state.skills}
                                  value={
                                    state.profileInfo.skills
                                      ? skillOptions.length - 1 ==
                                        state.profileInfo.skills.length
                                        ? [{ label: "All", value: "All" }]
                                        : skillOptions.filter((f) =>
                                            state.profileInfo.skills.includes(
                                              f.value
                                            )
                                          )
                                      : []
                                  }
                                  name="placementSkill"
                                  isSearchable={true}
                                  className={
                                    validation.skills && "border border-danger"
                                  }
                                  onChange={(value) =>
                                    generateMultiSelectHandler(
                                      "skills",
                                      value?.map((i) => i.value)
                                    )
                                  }
                                ></Select>
                              </div>
                              {validation.skills && (
                                <small className="validation ml-2">
                                  {validation.skills}
                                </small>
                              )}
                            </div>
                          </div>
                          <div className="row mt-2 mb-2">
                            <label>
                              List the most common job titles you've filled in
                              the last 12 months:
                            </label>
                            <div className="col-md-4">
                              <div className="form-floating styled-input">
                                <input
                                  type="text"
                                  id="title1"
                                  name="title1"
                                  className={
                                    validation.jobTitle
                                      ? "form-control border border-danger"
                                      : "form-control"
                                  }
                                  style={{ border: "none" }}
                                  value={state?.profileInfo?.jobTitle?.title1}
                                  onChange={(e) => handleChange(e)}
                                />
                                <label>Job Title*</label>
                              </div>
                              {validation.jobTitle && (
                                <small className="validation ml-2">
                                  {validation.jobTitle}
                                </small>
                              )}
                            </div>
                            <div className="col-md-4">
                              <div className="form-floating styled-input">
                                <input
                                  type="text"
                                  id="title2"
                                  name="title2"
                                  className="form-control"
                                  style={{ border: "none" }}
                                  value={state?.profileInfo?.jobTitle?.title2}
                                  onChange={(e) => handleChange(e)}
                                />
                                <label>Job Title 2</label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-floating styled-input">
                                <input
                                  type="text"
                                  id="title3"
                                  name="title3"
                                  className="form-control"
                                  style={{ border: "none" }}
                                  value={state?.profileInfo?.jobTitle?.title3}
                                  onChange={(e) => handleChange(e)}
                                />
                                <label>Job Title 3</label>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="row bg-white mb-4">
                        <div className="col-md-12 pl-1 pr-1 bg-white">
                          <div
                            className="form-floating bg-white"
                            id="updateLocation"
                          >
                            <AddJobNewGoogleMaps
                              setLocation={setLocation}
                              searchInput={searchInputRef}
                              className="bg-white"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className={
                                validation.cityName
                                  ? "form-control border border-danger"
                                  : "form-control"
                              }
                              placeholder="City*"
                              id="cityName"
                              name="cityName"
                              onChange={(e) => handleChange(e)}
                              value={state?.profileInfo?.cityName}
                            />
                            <label>City*</label>
                          </div>
                          {validation.cityName && (
                            <small className="validation ml-2">
                              {validation.cityName}
                            </small>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className={
                                validation.stateName
                                  ? "form-control border border-danger"
                                  : "form-control"
                              }
                              placeholder="State*"
                              id="stateName"
                              name="stateName"
                              onChange={(e) => handleChange(e)}
                              value={state?.profileInfo?.stateName}
                            />
                            <label>State*</label>
                          </div>
                          {validation.stateName && (
                            <small className="validation ml-2">
                              {validation.stateName}
                            </small>
                          )}
                        </div>
                        <div className="col-md-4">
                          <div className="form-floating">
                            <input
                              type="text"
                              className={
                                validation.CountryName
                                  ? "form-control border border-danger"
                                  : "form-control"
                              }
                              placeholder="Country*"
                              id="CountryName"
                              name="CountryName"
                              onChange={(e) => handleChange(e)}
                              value={state?.profileInfo?.CountryName}
                            />
                            <label>Country*</label>
                          </div>
                          {validation.CountryName && (
                            <small className="validation ml-2">
                              {validation.CountryName}
                            </small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3">
                  <div className="card">
                    <div className="card-header">
                      <h6 className="mb-0">Why Update Your Profile?</h6>
                    </div>
                    <div className="card-body">
                      <p className="mb-4">
                        As a recruiter on the High5 platform, your profile
                        serves as the backbone for the job opportunities you
                        receive. Keeping it current is necessary for a few
                        reasons:
                      </p>
                      <ul className="list-unstyled mb-0">
                        <li className="mb-3">
                          <span role="img" aria-label="Target Emoji">
                            🎯
                          </span>{" "}
                          <strong>Stay Relevant:</strong> By ensuring your areas
                          of expertise and interest are up-to-date, you'll
                          receive job listings that align perfectly with your
                          skills.
                        </li>
                        <li className="mb-3">
                          <span role="img" aria-label="Inbox Emoji">
                            📥
                          </span>{" "}
                          <strong>Declutter Your Inbox:</strong> Only get emails
                          that matter. Regular updates mean fewer irrelevant job
                          notifications, allowing you to focus on what truly
                          suits you.
                        </li>
                        <li>
                          <span role="img" aria-label="Refresh Emoji">
                            🔄
                          </span>{" "}
                          <strong>Keep Pace with Change:</strong> The world of
                          recruitment is dynamic. By refreshing your profile,
                          you stay in tune with industry shifts and trends.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-2">Last Updated: {lastUpdated}</div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm"
                  onClick={handleSubmit}
                >
                  <span>Update</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default withRouter(UpdateProfileInfo);
