import React, { useState } from "react";
import { FaFileInvoice, FaCopy, FaFile } from "react-icons/fa";
import { useDispatch } from "react-redux";
import Loader from "../../../Loader/Loader";
import MainAddJobType from "./addjobtype";
import { template } from "../../../../actions/job/AddJob";

const MainAddJob = (props) => {
  const [loading, setLoading] = useState(false);
  const [createJobWithTemplate, setCreateJobWithTemplate] = useState(false);
  const [templateSelected, setTemplateSelected] = useState(false);
  const [createJobWithExisting, setCreateJobWithExisting] = useState(false);
  const dispatch = useDispatch();
  const iconObj = { width: "3rem", height: "3rem", color: "gray" };

  const handleTemplateSelect = () => {
    setTemplateSelected(true);
    dispatch(template(true));
  };

  return (
    <>
      {loading && <Loader />}

      {/* TYPE OF CREATE JOB */}
      {!createJobWithTemplate && !templateSelected && !createJobWithExisting && (
        <div className="m-3">
          <div>
            <div
              className="card card-flat mb-2 mt-4 bg-gray4 card-hoverable"
              onClick={() => setCreateJobWithExisting(true)}
            >
              <div className="card-body d-flex align-items-center">
                <div>
                  <FaCopy style={iconObj} />
                </div>
                <div className="m-2 ml-3">
                  <h6>Copy / Clone an Existing Job</h6>
                  <p>
                    Use an existing job as a template and customize as needed.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="card card-flat mb-2 bg-gray4 card-hoverable"
              onClick={() => {
                setCreateJobWithTemplate(true);
              }}
            >
              <div className="card-body d-flex align-items-center">
                <div>
                  <FaFileInvoice style={iconObj} />
                </div>
                <div className="m-2 ml-3">
                  <h6>Sample Job</h6>
                  <p>
                    Use a sample High5Hire template and customize as needed.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="card card-flat mb-2 bg-gray4 card-hoverable"
              onClick={() => handleTemplateSelect()}
            >
              <div className="card-body d-flex align-items-center">
                <div>
                  <FaFile style={iconObj} />
                </div>
                <div className="m-2 ml-3">
                  <h6>Create New Job</h6>
                  <p>Use a blank template to create the job from scratch</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {(createJobWithExisting || createJobWithTemplate || templateSelected) && (
        <MainAddJobType
          createJobWithExisting={createJobWithExisting}
          createJobWithTemplate={createJobWithTemplate}
          templateSelected={templateSelected}
          setCreateJobWithExisting={setCreateJobWithExisting}
          setTemplateSelected={setTemplateSelected}
          setCreateJobWithTemplate={setCreateJobWithTemplate}
          closePanel={props?.closePanel}
          setSuccessData={props?.setSuccessData}
          setSuccessFormOpen={props?.setSuccessFormOpen}
          //! below props are for resume parsing comparison screen
          isFrom={props.isFrom}
          getAllJobs={props.getAllJobs}
          setLoadingPC={props.setLoadingPC}
          setSelectedJobId={props.setSelectedJobId}
        ></MainAddJobType>
      )}
    </>
  );
};

export default MainAddJob;
