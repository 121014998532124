export const SKILL_PAGINATION = "TEMPLATE_LOADED";
export const LOCATION_PAGINATION = "LOCATION_PAGINATION";
export const DESIGNATION_PAGINATION = "DESIGNATION_PAGINATION";
export const SOURCE_PAGINATION = "SOURCE_PAGINATION";
export const WORK_TYPE_PAGINATION = "WORK_TYPE_PAGINATION";
export const WORK_AUTH_PAGINATION = "WORK_AUTH_PAGINATION";
export const RECRUITER_PAGINATION = "RECRUITER_PAGINATION";

export const setSkillPagination = (param) => (dispatch) => {
  dispatch({
    type: SKILL_PAGINATION,
    payload: param,
  });
};
export const setLocationPagination = (param) => (dispatch) => {
  dispatch({
    type: LOCATION_PAGINATION,
    payload: param,
  });
};
export const setDesignationPagination = (param) => (dispatch) => {
  dispatch({
    type: DESIGNATION_PAGINATION,
    payload: param,
  });
};
export const setSourcePagination = (param) => (dispatch) => {
  dispatch({
    type: SOURCE_PAGINATION,
    payload: param,
  });
};
export const setWorkTypePagination = (param) => (dispatch) => {
  dispatch({
    type: WORK_TYPE_PAGINATION,
    payload: param,
  });
};
export const setWorkAuthPagination = (param) => (dispatch) => {
  dispatch({
    type: WORK_AUTH_PAGINATION,
    payload: param,
  });
};
export const setRecruiterPagination = (param) => (dispatch) => {
  dispatch({
    type: RECRUITER_PAGINATION,
    payload: param,
  });
};
