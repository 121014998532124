import React, { useEffect, useState } from "react"
import moment from 'moment'

function SubmitCandidateGridView(props) {

  const [ newRequestList, setnewRequestList ] = useState([])
  const [ currentPage, setCurrentPage] = useState(1)
  // const [ requestTitleLength, setRequestTitleLength ] = useState(0)
  const [ requestTitleArray, setRequestTitleArray ] = useState([])
  const [ pageType, setPageType ] = useState('')
  let pageSize = 5
  let dataSize = 4
  const [ paginationGroup, setPaginationGroup ] = useState([])
 
  let requestList = []

  useEffect(() => {
    if(pageType !== '') {
      if(pageType === 'up') {      
        if((currentPage-1)%5 === 0 && (currentPage !== 1)) {
          setPaginationGroup(requestTitleArray.slice(currentPage-1, currentPage+(pageSize-1)))
        }
      }
      else {      
        if((currentPage%5 === 0) && (currentPage !== 1)) {
          setPaginationGroup(requestTitleArray.slice(currentPage-5, currentPage))
        }
      }
    }
    else {
      setnewRequestList(props.requestListData)
      setRequestTitleArray(new Array(Math.ceil(props.requestListData.length/dataSize)).fill().map((_, i) => i+1))
      setPaginationGroup(new Array(Math.ceil(props.requestListData.length/dataSize)).fill().map((_, i) => i+1).slice(0, pageSize))
    }   
  }, [props, currentPage])

  function changePage(event) {
      const pageNumber = Number(event.target.textContent)
      console.log(pageNumber)
      setCurrentPage(pageNumber)
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * 4 - 4
    const endIndex = startIndex + 4
    return newRequestList.slice(startIndex, endIndex)
  }

  return (
    <>
      <div className="row">
        { 
          getPaginatedData().map((request, i) => (
          <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex">
            <div
              // href={`/high5Hire/requests/${request.jobId}`}
              className="card card-hoverable col card-tag-bottom mb-4 card-lg p-relative"
            >
              {/* <div class="form-check-square p-absolute-top-right">
                <input 
                  type="checkbox" 
                  checked={request.isSelect} 
                  onChange={(e) => props.handleChange(e, i)} 
                  class="form-check-input" 
                />
              </div> */}
              <div className="card-body pb-0">
                <a onClick={() => window.open(`/high5Hire/requests/${request.jobId}`, '_self')}>                                         
                  <h6>{request.fullText.jobTitle}</h6>
                  <div className="mb-2">
                      {request.isHotJob && (
                      <span className="tag tag-coral mr-1">hot</span>
                    )}
                    <small className="font-regular font-primary">
                      {"  "}{request.jobType.charAt(0).toUpperCase() + request.jobType.slice(1) }
                    </small>                 
                  </div>
                  <hr className="mt-0 mb-2" />
                  <p className="mb-1 d-flex align-items-center">
                    <i className="fal fa-fw fa-map-marker-alt mr-3"></i>
                    <span>{ request.location.city ? request.location.city : 'Remote' }</span>
                  </p>
                  <p className="mb-1 d-flex align-items-center">                  
                    <i className="far fa-clock me-2 font-brandBlue"></i>                                   
                      {
                        moment(request.expiresOn).diff(new Date(), 'days') < 0 &&
                        <span className="tag tag-red1">
                          <strong> Expired </strong>
                        </span>
                      }
                      {
                        moment(request.expiresOn).diff(new Date(), 'days') === 0 &&
                        <span className="tag tag-red1">
                          <strong> Expires Today </strong>
                        </span>
                      }
                      {
                        moment(request.expiresOn).diff(new Date(), 'days') === 1 &&
                        <span className="tag tag-red1">
                          <strong> 1 Day Left To Fill </strong>
                        </span>
                      }
                      {
                        moment(request.expiresOn).diff(new Date(), 'days') > 1 &&
                        (moment(request.expiresOn).diff(new Date(), 'days') <= 5 ?
                        <span className="tag tag-red1">
                          <strong> {moment(request.expiresOn).diff(new Date(), 'days')} Days Left To Fill </strong>
                        </span> :
                        <span className="font-regular">
                          <strong> {moment(request.expiresOn).diff(new Date(), 'days')} Days Left To Fill </strong>
                        </span>)
                      }                                          
                  </p>
                </a>
              </div> 
              <hr />
              <div class="card-body pt-0">
                <button type="button" class="btn btn-secondary btn-sm" onClick={() => props.openSubmitModal(request)}>Invite</button>
              </div>                                             
            </div>                     
          </div>         
        ))}
        <div className='d-flex justify-content-end pt-4 px-1'>
          <div className="d-flex">  
              <div class="ml-auto mx-auto-370 d-flex align-items-center">    
                  <button 
                      onClick={() => {
                        setPageType('down')
                        setCurrentPage(page => page - 1)                       
                      }}
                      type="button" 
                      disabled={ currentPage === 1 ? true : false } 
                      className="btn btn-icon px-2"
                  >
                      <i className="fal fa-chevron-circle-left"></i>
                  </button>

                  {
                    paginationGroup.map((item) => (
                      <button 
                          type="button" 
                          className={ currentPage === item ? "btn btn-text px-2 bg-secondary text-white" : "btn btn-text px-2"}
                          onClick={changePage}
                      >
                        {item}
                      </button>
                    ))
                  }

                  <button 
                      onClick={() => {
                        setPageType('up')
                        setCurrentPage(page => page + 1)                       
                      }}
                      type="button" 
                      disabled={ currentPage === requestTitleArray.slice(-1)[0] ? true : false } 
                      className="btn btn-icon px-2"
                  >
                      <i className="fal fa-chevron-circle-right"></i>
                  </button>
              </div>
          </div>                           
        </div> 
      </div>
    </>
  )
}

export default SubmitCandidateGridView
