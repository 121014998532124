import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import { Card, Button } from "react-bootstrap";

const DescriptionPopUp = (props) => {
  const { jobData, setShow, show } = props;
  return (
    <Modal
      size="lg"
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <h6 className="pt-o mt-3">Job Description</h6>
      </Modal.Header>
      <Modal.Body>
        <Card className="m-3">
          <Card.Text className="p-3">
            <p
              className=""
              dangerouslySetInnerHTML={{
                __html: jobData.jobDescription,
              }}
            ></p>
          </Card.Text>
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShow(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DescriptionPopUp;
