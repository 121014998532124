import React, { useState } from "react";
import { getInitial, dataDiff } from "../../function/CommonFunction";
import vettedIcon from '../../../images/high5-mark-circle-dark.svg'
import Avatar from "react-avatar";
import moment from "moment";

function SourceCandidateGridView(props) {

    const handleSource = (e, candidate) => {
        props.setSourceTalentButton(true)
        props.setSouceCandidateData(candidate)
    }

    return (
        <>
            <div class="row  d-flex-1200">
                {
                    props.arrayList.length !== 0 ? (
                        props.arrayList.map((candidate, i) => (
                            <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex">
                                <div class="card card-hoverable col card-tag-bottom mb-4 card-lg card-candidates-grid p-relative">
                                    {/* <div>
                                        <div class="form-check-square p-absolute-top-right">
                                            <input class="form-check-input" type="checkbox" checked={candidate.isSelect} onChange={(e) => props.handleChange(e, i)} />
                                        </div>
                                    </div> */}
                                    {
                                        (candidate.MCQ === false || candidate.onewayvetting === true || candidate.twowayvetting === true || candidate.codesignalvetting === true) && (
                                            <div class="tag tag-coral tag-horizontal-status justify-content-start">
                                                <img width="16px" alt="" class="mr-1" src={vettedIcon} />Vetted
                                            </div>
                                        )
                                    }
                                    <a href="#" class="card-body pb-0">
                                        <div class="d-flex mb-2 mt-1 align-items-center">
                                            <div class="mr-3">
                                                {
                                                    candidate.ImageURL ? (
                                                        <span class="avatar avatar-bordered">
                                                            <img alt="" src={candidate.ImageURL} />
                                                        </span>
                                                    ) : (
                                                        <span >
                                                            <Avatar size="55" name={candidate.fullText.firstName + " " + candidate.fullText.lastName}  round="80px" />
                                                            {/* {getInitial(`${candidate.fullText.firstName} ${candidate.fullText.lastName}`)} */}
                                                        </span>
                                                    )
                                                }
                                            </div>
                                            <div>
                                                <h6 class="mb-0 font-primary">{candidate.fullText.firstName} {candidate.fullText.middleName} {candidate.fullText.lastName}</h6>
                                            </div>
                                        </div>
                                        <div class="designation-city-status font-regular font-primary font-gray1 mt-1">
                                            {
                                                candidate.designation && (
                                                    <div class="mb-2"><i class="far fa-suitcase me-2"></i> {candidate.designation}</div>
                                                )
                                            }
                                            {
                                               
                                                    <div class="mb-2"><i class="far fa-map-marker-alt me-2"></i>{candidate.fullText.cityName || 'Remote'} {candidate.fullText.stateName && ','} {candidate.fullText.countryName}</div>
                                               
                                            }
                                            {
                                                candidate.updatedDate !== '' && new Date() > new Date(candidate.updatedDate) && (
                                                    <div class="mb-2"><i class="far fa-clock me-2"></i>Updated &nbsp;
                                                        <span>{moment(candidate.updatedDate).format("MM/DD/YYYY")}</span>&nbsp;
                                                        {/* {dataDiff(candidate.updatedDate, new Date()) > 1 ? 'Days' : 'Day'} &nbsp;ago */}
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </a>
                                    <div class="card-body pt-0">
                                        <hr />
                                        <div>
                                            <button type="text"
                                                onClick={() => props.openSubmitModal(candidate)}
                                                class="btn btn-sm btn-secondary">
                                                Source
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (   
                        <div>
                            <div class="text-center p-3">
                                <div class="avatar avatar-lg">
                                    <i class="fad fa-users-slash"></i>
                                </div>
                                <div class="mt-2">
                                    No  Candidates Found
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </>
    );
}

export default SourceCandidateGridView;
