import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import ContactInfoDS from "./ContactInfoDS";
import ProfileInfoDS from "./ProfileInfoDS";
import ExperienceDS from "./ExperienceDS";
import EducationDS from "./EducationDS";
import CertificationsDS from "./CertificationDS";
import { ButtonLoader } from "../../../constants/Constants";
import {
  contactInfoValidation,
  profileInfoValidation,
  experienceValidation,
  educationValidation,
  certificationValidation,
  licenseValidation,
} from "../../validation/EditTalent";

import { getUserTenant } from "../../../service/AuthService";
import Dropzone from "react-dropzone";
import Loader from "../../Loader/Loader";
import { domain } from "../../../constants/Constants";
import {
  checkEmptyObject,
  checkListOfEmptyObject,
} from "../../function/CommonFunction";
import { get } from "../../../service/ApiService";
import PreferencesDS from "./PreferencesDS";
import { useParams } from "react-router";
import { GiLetterBomb } from "react-icons/gi";

const EditTalentDS = ({
  data,
  closeModal,
  setAddTalentData,
  setScoreCard,
  setCandidateDetails,
}) => {
  let candidateID = sessionStorage.getItem("userId");
  const { id, tenant, reffererid, recruiterid } = useParams();
  const [contact, setContact] = useState(true);
  const [profileInfo, setProfileInfo] = useState(true);
  const [experience, setExperience] = useState(true);
  const [education, setEducation] = useState(true);
  const [certification, setCertification] = useState(false);
  const [license, setLicense] = useState(false);
  const [resume, setResume] = useState(false);
  const [preferences, setPreferences] = useState(true);
  const [job, setJob] = useState({});
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropDownData, setDropDownData] = useState({
    Source: [],
    CurrencyTime: [],
    CandidateSkills: [],
    skillSet: [],
    ExperienceLevel: [],
    PositionType: [],
    Department: [],
  });
  const [selectedValue, setSelectedValue] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  // const calculateExperience = [
  //   { name: "Junior :0-3 years", range: [0, 1, 2, 3] },
  //   { name: "Mid :4-7 years", range: [4, 5, 6, 7] },
  //   { name: "Senior :8-10 years", range: [8, 9, 10] },
  //   { name: "Guru :Subject Matter Expert", range: [] },
  // ];
  const calculateExperience = [
    { name: "Intern", range: [0] },
    { name: "1-3 years", range: [1, 2] },
    { name: "3-5 years", range: [3, 4] },
    { name: "5-10 years", range: [5, 6, 7, 8, 9] },
    { name: "10+ years", range: [] },
  ];

  const findRange = (years, months) => {
    console.log("years", years);
    console.log("months", months);
    let month = Number(years) * 12 + Number(months);
    if (month > 120) {
      return "Guru :Subject Matter Expert";
    }
    let year = Math.ceil(month / 12);
    console.log("year", year);
    let finalValue = calculateExperience.find((i) => i.range.includes(year))
      .name;
    console.log("finalValue", finalValue);
    return finalValue;
  };

  const fetchDropDown = async () => {
    setLoading(true);
    const res = await get("/talent/dropdownlist");
    setDropDownData(res.data);
    setLoading(false);
  };

  const [resumeList, setResumeList] = useState([]);
  const [resumeInfo, setResumeInfo] = useState({
    fileName: "",
    base64: "",
    fileType: "",
  });
  const [resumeUpload, setResumeUpload] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [deleteResume, setDeleteResume] = useState(false);
  const [deleteFile, setDeleteFile] = useState("");

  const fetchResume = async () => {
    setLoading(true);
    const payload = {
      candidateID: candidateID,
      tenant: getUserTenant(),
    };
    console.log("payload", payload);
    try {
      let res = await post("/dsTalent/getResumeTC", payload);
      setResumeList(res.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  const fetchCandidateDetails = async () => {
    setLoading(true);
    const payload = {
      candidateID: sessionStorage.getItem("userId"),
    };
    try {
      let res = await post("/dsTalent/fetchDetailsDS", payload);
      console.log("res.data", res.data);
      let data = res.data;
      let {
        firstName,
        middleName,
        lastName,
        email,
        zipCode,
        address,
        addressCity,
        addressState,
        country,
        workPhoneCode,
        workPhone,
        homePhoneCode,
        homePhone,
        mobilePhoneCode,
        mobilePhone,
      } = data;
      setContactInfo({
        firstName,
        middleName,
        lastName,
        email,
        zipcode: "12345",
        address,
        addressCity,
        addressState,
        country,
        workPhoneCode,
        workPhone,
        homePhoneCode,
        homePhone,
        mobilePhoneCode,
        mobilePhone,
        phoneValidation: true,
        phoneValidation2: true,
      });
      let {
        facebook,
        twitter,
        linkedIn,
        minContractRate,
        minContractRateCurrency,
        description,
        preferedLocations,

        preferredSalary,
        preferredSalaryCurrency,
        primarySkills,
        secondarySkills,
        source,
        visaStatus,
        designation,
        currentEmployer,
        experienceLevel,
        experienceYear,
        experienceMonth,
        currentJobTitle,
        isFresher,
      } = data;
      setProfileData({
        facebook,
        twitter,
        linkedIn,
        minContractRate,
        minContractRateCurrency,
        description,
        preferedLocations: preferedLocations.filter((i) => i !== "Remote"),
        preferredSalary,
        preferredSalaryCurrency,
        primarySkills,
        secondarySkills,
        source,
        visaStatus,
        designation,
        currentEmployer,
        experienceLevel,
        experienceYear,
        experienceMonth,
        currentJobTitle,
        isFresher,
        isRemote: preferedLocations.includes("Remote"),
        isHybrid: preferedLocations.includes("Hybrid"),
      });
      setSelectedValue(data.skillSet);
      setSelectedTypes(data.preferredPositionType);
      setExperiences(data.experience);
      setCertifications(data.certificates);
      setEducations(data.education);
      setLicenses(data.license);
      setLoading(false);
      setCandidateDetails({
        fName: firstName,
        lName: lastName,
        email: email,
        country: country,
        phone: mobilePhone,
        alternatePhone: homePhone,
        highestEducation: "",
        city: addressCity,
        currentPosition: currentJobTitle,
        currentEmployer: currentEmployer,
        experienceYear: experienceYear,
        experienceMonth: experienceMonth,
        legallyAuthorized: false,
        requireSponsorship: false,
      });
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  const getJob = async () => {
    const params = { domain: domain, jobId: id };

    const res = await post("/career/getrequestdetailsbyIdDS", params);
    setJob(res.data.jobData);
  };
  const getTenant = async () => {
    const params = { tenantId: tenant };
    const res = await post("/career/getjobs", params);

    let jobDetails = res.data.find((i) => i.jobId === +id);

    setJob({
      ...jobDetails,
      primarySkills: JSON.parse(jobDetails.primarySkills),
    });
    // setJobs(res.data);

    // automatch();
  };

  useEffect(() => {
    fetchCandidateDetails();
    fetchDropDown();
    getJob();
    // fetchResume();
  }, []);

  const onUploadHandler = (file) => {
    if (file) {
      file.map((eachFile) => {
        let type = eachFile.name.split(".").reverse()[0];
        if (type === "pdf" || type === "doc" || type === "docx") {
          console.log(eachFile);
          const reader = new FileReader();
          reader.readAsDataURL(eachFile);
          reader.onload = (event) => {
            const obj = {
              fileName: eachFile.name,
              base64: event.target.result,
            };
            setResumeList((current) => [...current, obj]);
          };
        } else {
          toast.error("Please Upload only PDF or WORD Document");
        }
      });
    }
  };

  const onCollapsibleClick = (type) => {
    if (type === "contact") {
      if (contact === false) {
        setContact(true);
      } else {
        setContact(false);
      }
    }

    if (type === "profile") {
      if (profileInfo === false) {
        setProfileInfo(true);
      } else {
        setProfileInfo(false);
      }
    }

    if (type === "experience") {
      if (experience === false) {
        setExperience(true);
      } else {
        setExperience(false);
      }
    }

    if (type === "education") {
      if (education === false) {
        setEducation(true);
      } else {
        setEducation(false);
      }
    }

    if (type === "certification") {
      if (certification === false) {
        setCertification(true);
      } else {
        setCertification(false);
      }
    }

    if (type === "license") {
      if (license === false) {
        setLicense(true);
      } else {
        setLicense(false);
      }
    }
    if (type === "preferences") {
      if (preferences === false) {
        setPreferences(true);
      } else {
        setPreferences(false);
      }
    }
  };

  // Experience child Component
  const [experiences, setExperiences] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      isSelect: false,
    },
  ]);

  const [experienceVal, setExperienceVal] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    },
  ]);

  // Education Child Component
  const [educations, setEducations] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);

  const [educationVal, setEducationVal] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);

  // Certificate child  Component
  const [certifications, setCertifications] = useState([
    { certificationName: "", certificate: "", issueDate: "", expiryDate: "" },
  ]);

  const [certificationVal, setCertificationVal] = useState([
    { certificationName: "", certificate: "", issueDate: "", expiryDate: "" },
  ]);

  // License child  Component
  const [licenses, setLicenses] = useState([
    { licenseName: "", license: "", state: "", expiryDate: "" },
  ]);

  const [licenseVal, setLicenseVal] = useState([
    { licenseName: "", license: "", state: "", expiryDate: "" },
  ]);

  // Contact Info child  Component
  const [contactInfo, setContactInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    zipcode: "12345",
    address: "",
    addressCity: "",
    addressState: "",
    country: "",
    workPhoneCode: "",
    workPhone: "",
    homePhoneCode: "",
    homePhone: "",
    mobilePhoneCode: "",
    mobilePhone: "",
  });

  const [contactVal, setContactVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
    zipcode: "12345",
    addressCity: "",
    addressState: "",
    country: "",
  });

  // Profile Info child  Component
  const [profileData, setProfileData] = useState({
    facebook: "",
    twitter: "",
    linkedIn: "",
    preferredSalary: "",
    preferredSalaryCurrency: "",
    minContractRate: "",
    minContractRateCurrency: "",
    description: "",
    preferedLocations: [],
    isRemote: false,
    isHybrid: false,
    primarySkills: [],
    secondarySkills: [],
    source: "",
    visaStatus: "",
    designation: "",
    currentEmployer: "",
    experienceLevel: "",
    experienceYear: "",
    experienceMonth: "",
    currentJobTitle: "",
    isFresher: false,
  });

  const [profileVal, setProfileVal] = useState({
    preferredSalaryCurrency: "",
    minContractRateCurrency: "",
    preferedLocations: "",
    primarySkills: "",
    experienceYear: "",
    experienceMonth: "",
    currentJobTitle: "",
  });

  // Submit Edit talent
  const submitEditTalent = async () => {
    const contactInfoVal = contactInfoValidation(contactInfo);
    const profileInfoVal = profileInfoValidation(profileData);

    if (
      !checkEmptyObject(contactInfoVal) ||
      !checkEmptyObject(profileInfoVal)
    ) {
      setContactVal(contactInfoVal);
      setProfileVal(profileInfoVal);
    } else {
      // validationInit()
      // setSubmitClicked(true)
      // let location = [];
      // if (profileData.isRemote === true) {
      //   location = [...profileData.preferedLocations, "Remote"];
      // } else {
      //   location = profileData.preferedLocations.filter((i) => i !== "Remote");
      // }
      // if (profileData.isHybrid) {
      //   location = [...profileData.preferedLocations, "Hybrid"];
      // } else {
      //   location = profileData.preferedLocations.filter((i) => i !== "Hybrid");
      // }
      // console.log("location", location);
      setLoading(true);
      let talentData = {
        ...contactInfo,
        ...profileData,
        candidateID: data.candidateID,
        createdDate: data.createdDate,
        // preferedLocations: location,
        // preferedLocations: profileData.preferedLocations,
        // preferedLocations: profileData.isRemote ? [...profileData.preferedLocations, "Remote"] : profileData.preferedLocations.filter((i) => i !== "Remote") ?
        //  profileData.preferedLocations.isHybrid ? [...profileData.preferedLocations, "Hybrid"] : profileData.preferedLocations.filter((i) => i !== "Hybrid")
        preferedLocations: profileData.isRemote
          ? [...profileData.preferedLocations, "Remote"]
          : profileData.preferedLocations.filter((i) => i !== "Remote"),
        // preferedLocations: profileData.isHybrid
        //   ? [...profileData.preferedLocations, "Hybrid"]
        //   : profileData.preferedLocations.filter((i) => i !== "Hybrid"),
      };
      let experience =
        experiences.length > 1
          ? experiences.filter((item) => checkEmptyObject(item) === false)
          : experiences;
      let education =
        educations.length > 1
          ? educations.filter((item) => checkEmptyObject(item) === false)
          : educations;
      let certification =
        certifications.length > 1
          ? certifications.filter((item) => checkEmptyObject(item) === false)
          : certifications;
      let license =
        licenses.length > 1
          ? licenses.filter((item) => checkEmptyObject(item) === false)
          : licenses;
      talentData.experiences = experience;
      talentData.educations = education;
      talentData.certifications = certification;
      talentData.licenses = license;
      talentData.domainName = domain;
      talentData.documents = resumeList;
      talentData.skillSet = selectedValue;
      talentData.preferredPositionType = selectedTypes;
      talentData.experienceLevel = findRange(
        profileData.experienceYear,
        profileData.experienceMonth
      );
      console.log("talentData", talentData);
      setAddTalentData({
        ...talentData,
        preferredLocation: talentData.preferedLocations,
      });
      try {
        const res = await post("/dsTalent/editTalentDS", talentData);
        if (res.status === 200) {
          console.log("res", res);
          setLoading(false);
          // const sendDataToAutoMatch = await post('/automatch/autoMatchTalent', talentData)
          setSubmitClicked(false);
          closeModal();
          setScoreCard(true);
          // toast.success("Updated Successfully");
          // setTimeout(() => {
          //   window.location.reload();
          // }, 3000);
        }
      } catch (error) {
        setLoading(false);
        toast.error("Some Error Occurred. Please Try Again");
      }
    }
  };

  const resumeDelete = async () => {
    setDeleteResume(false);
    setLoading(true);
    try {
      const params = {
        candidateId: data.candidateID,
        fileName: deleteFile,
      };
      const res = await post("/CandidateDashboard/resumeDeleteTC", params);
      if (res.status === 200) {
        setResumeList(resumeList.filter((r) => r.fileName !== deleteFile));
        setLoading(false);
        toast.success("Resume Deleted Successfully");
      }
    } catch (err) {
      setLoading(false);
      toast.error(
        "Some Error Occurred While Deleting Resume. Please Try Again"
      );
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <div
          class="bl-modal-body"
          style={{ padding: "0px 0px 0px 0px", marginTop: "-25px" }}
        >
          <div class="mb-2 mt-3">
            <div class="card mb-2">
              <div class="card-body">
                <div>
                  <div>
                    <button
                      onClick={() => {
                        onCollapsibleClick("contact");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {contact ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Contact Info</small>
                      </span>
                    </button>
                    <div
                      className={contact ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}
                    >
                      <div class="card card-flat bg-gray4 ml-4 ml-0-600">
                        <ContactInfoDS
                          contactInfo={contactInfo}
                          setContactInfo={setContactInfo}
                          contactInfoValidation={contactVal}
                          profileInfo={profileData}
                          setProfileInfo={setProfileData}
                        />
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        onCollapsibleClick("profile");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {profileInfo ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Profile Info</small>
                      </span>
                    </button>
                    <div
                      className={
                        profileInfo ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"
                      }
                    >
                      <div class="card card-flat bg-gray4 ml-4 ml-0-600">
                        {!loading && (
                          <ProfileInfoDS
                            profileInfo={profileData}
                            setProfileInfo={setProfileData}
                            profileInfoValidation={profileVal}
                            dropDownData={dropDownData}
                            multiSelect={{
                              selectedValue,
                              selectedTypes,
                              setSelectedTypes,
                              setSelectedValue,
                            }}
                            changePreferredLocation={(loc) =>
                              setProfileData({
                                ...profileData,
                                preferedLocations: loc,
                              })
                            }
                          />
                        )}
                      </div>
                    </div>

                    <button
                      onClick={() => {
                        onCollapsibleClick("education");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {education ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Education</small>
                      </span>
                    </button>
                    <div
                      className={
                        education ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"
                      }
                    >
                      <div class="card card-flat card-lg ml-4 ml-0-600">
                        <div class="card-body" style={{ padding: "10px" }}>
                          {
                            <EducationDS
                              educations={educations}
                              setEducations={setEducations}
                              educationValidation={educationVal}
                              setEducationValidation={setEducationVal}
                              dropDownData={dropDownData.QualificationType}
                            />
                          }
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        onCollapsibleClick("experience");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {experience ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Experience</small>
                      </span>
                    </button>
                    <div
                      className={
                        experience ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"
                      }
                    >
                      <div class="card card-flat card-lg ml-4 ml-0-600">
                        <div class="card-body" style={{ padding: "10px" }}>
                          {!loading && (
                            <ExperienceDS
                              experiences={experiences}
                              setExperiences={setExperiences}
                              profileInfo={profileData}
                              setProfileInfo={setProfileData}
                              experienceValidation={experienceVal}
                              setExperienceValidation={setExperienceVal}
                              dropDownData={dropDownData}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    {/* Preferences */}
                    <button
                      onClick={() => {
                        onCollapsibleClick("preferences");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {preferences ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Preferences</small>
                      </span>
                    </button>
                    <div
                      className={
                        preferences ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"
                      }
                    >
                      <div class="card card-flat bg-gray4 ml-4 ml-0-600">
                        {/* {!loading && ( */}
                        <PreferencesDS
                          profileInfo={profileData}
                          setProfileInfo={setProfileData}
                          profileInfoValidation={profileVal}
                          dropDownData={dropDownData}
                          multiSelect={{
                            selectedValue,
                            selectedTypes,
                            setSelectedTypes,
                            setSelectedValue,
                          }}
                          changePreferredLocation={(loc) =>
                            setProfileData({
                              ...profileData,
                              preferedLocations: loc,
                            })
                          }
                          job={job}
                        />
                        {/* )} */}
                      </div>
                    </div>
                    {/* <button
                      onClick={() => {
                        onCollapsibleClick("certification");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {certification ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Certifications</small>
                      </span>
                    </button>
                    <div
                      className={
                        certification ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"
                      }
                    >
                      <div class="card card-flat card-lg ml-4 ml-0-600">
                        <div class="card-body">
                          <CertificationsDS
                            certifications={certifications}
                            setCertifications={setCertifications}
                            certificationValidation={certificationVal}
                            setCertificationValidation={setCertificationVal}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => {
                        onCollapsibleClick("license");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {license ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Licenses</small>
                      </span>
                    </button>
                    <div
                      className={license ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}
                    >
                      <div class="card card-flat card-lg ml-4 ml-0-600">
                        <div class="card-body">
                          <License
                            licenses={licenses}
                            setLicenses={setLicenses}
                            setCertifications={setCertifications}
                            licenseValidation={licenseVal}
                            setLicenseValidation={setLicenseVal}
                          />
                        </div>
                      </div>
                    </div> */}
                    <button
                      onClick={() => {
                        setResume(!resume);
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {resume ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Resume</small>
                      </span>
                    </button>
                    <div
                      className={resume ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}
                    >
                      <div class="card card-flat card-lg ml-4 ml-0-600">
                        <div class="card-body">
                          <div className="ml-4 mr-5 d-flex mb-2">
                            <Dropzone onDrop={onUploadHandler} multiple={false}>
                              {({ getRootProps, getInputProps }) => (
                                <div {...getRootProps()} className="ml-auto">
                                  <button
                                    type="button"
                                    className=" btn btn-text-accent mr-1"
                                  >
                                    <i
                                      aria-hidden="true"
                                      className="far fa-cloud-upload mr-1 fa-fw"
                                    ></i>
                                    Upload New
                                  </button>
                                  <input type="file" {...getInputProps()} />
                                </div>
                              )}
                            </Dropzone>
                          </div>
                          <hr />
                          {resumeList.length === 0 ? (
                            <div>
                              <div className="text-center p-3">
                                <div className="avatar avatar-lg">
                                  <i className="fad fa-users-slash"></i>
                                </div>
                                <div className="mt-2">No Resume Found</div>
                              </div>
                            </div>
                          ) : (
                            <div>
                              <div>
                                <ul class="list-unstyled mt-2">
                                  {resumeList.map((item, index) => (
                                    <li class="row ml-2 d-flex align-items-center">
                                      <span className="col-lg-1 avatar avatar-sm mr-1">
                                        {index + 1}
                                      </span>
                                      <strong className="col-lg-8">
                                        {item.fileName}
                                      </strong>
                                      <br />
                                      <br />
                                      <button
                                        type="button"
                                        onClick={() => {
                                          setDeleteFile(item.fileName);
                                          setDeleteResume(true);
                                        }}
                                        className="btn btn-text-accent mr-2 col-lg-1"
                                        title="Delete"
                                      >
                                        <i
                                          className="fal fa-trash-alt fa-fw"
                                          aria-hidden="true"
                                        ></i>
                                      </button>
                                      {item.fileURL && (
                                        <a
                                          onClick={(e) => {
                                            e.preventDefault();
                                            window.open(item.fileURL, "_blank");
                                          }}
                                          className="btn btn-sm btn-secondary d-inline-block col-lg-2"
                                        >
                                          <i className="fal fa-cloud-download-alt mr-2"></i>
                                          View
                                        </a>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary btn-sm"
            disabled={submitClicked}
            onClick={closeModal}
          >
            Cancel
          </button>
          <button type="button" class="btn btn-primary btn-sm">
            {submitClicked === false ? (
              <span onClick={submitEditTalent}>Update</span>
            ) : (
              <span>
                Updating
                <img width="20px" src={ButtonLoader} alt="" />
              </span>
            )}
          </button>
        </div>
      </div>
      {
        <Modal show={deleteResume}>
          <div>
            <div className="bl-modal-body text-center">
              <div className="avatar avatar-lg avatar-red2 mr-3">
                <i class="fas fa-exclamation-triangle fa-fw"></i>
              </div>
              <p className="text-center lead mt-3">
                You're about to delete <h6 className="m-2">{deleteFile}</h6>
              </p>
              <div class="text-center my-4">
                <button
                  type="button"
                  className="btn btn-secondary mr-3"
                  data-dismiss="modal"
                  onClick={() => setDeleteResume(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="close p-0 bl-modal-close-btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setDeleteResume(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={resumeDelete}
                >
                  Confirm Delete
                </button>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default EditTalentDS;
