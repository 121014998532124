import {
  GET_COUNTRY_FILTER_BEGIN,
  GET_COUNTRY_FILTER_SUCCESS,
  GET_COUNTRY_FILTER_FAILURE,
} from "../../../actions/directsourcing/talentcommunity/talentCountrySubCommFilter";

const initialState = {
  loading: false,
  countryData: null,
};

const getTalentCountrySubCommReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COUNTRY_FILTER_BEGIN:
      return {
        ...state,
        loading: true,
        countryData: null,
      };
    case GET_COUNTRY_FILTER_SUCCESS:
      return {
        ...state,
        countryData: payload,
        loading: false,
      };
    case GET_COUNTRY_FILTER_FAILURE:
      return {
        ...state,
        countryData: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default getTalentCountrySubCommReducer;
