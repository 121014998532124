import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import RecruiterOverview from "./RecruiterOverview";
import Loader from "../../commoncomponents/Loader/Loader";
import {
  getRecruiterUserData,
  getRecruiterUserDataCount,
} from "../../../../actions/dashboard/UserData";
import { getDropdownValues } from "../../../../actions/common/GetDropdownValues";

const Index = ({ getRecruiters }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTotal, setDataTotal] = useState(0);
  const [dataSize, setDataSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const isFirstRender = useRef(true);
  const [sortArray, setSortArray] = useState([]);
  const [filterParams, setFilterParams] = useState({});
  const [openTenantPopUP, setopenTenantPopUP] = useState(false);
  const [selectedData, setSelectedData] = useState({
    country: "",
    state: "",
    address: [],
    language: [],
    region: [],
    jobTitle: [],
    activity: [],
    roles: [],
    jobCategory: [],
    experience: [],
  });

  useEffect(() => {
    fetchRecruiterData(1, 10, "", sortArray);
    const payload = {
      searchText: "",
      filterObject: filterParams,
    };
    dispatch(getRecruiterUserDataCount(payload));
    dispatch(getDropdownValues());
  }, []);

  const { usersData, usersCount, loading, countLoading } = useSelector(
    (state) => ({
      usersData: state.getRecruiterUserData.recruiterData,
      usersCount: state.getRecruiterUserData.userDataCount,
      loading: state.getRecruiterUserData.loading,
      countLoading: state.getRecruiterUserData.countLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    setCurrentPage(1);
    usersCount.length > 0 &&
      setDataTotal(usersCount[0].Count > 2719 ? 2719 : usersCount[0].Count);
  }, [usersCount]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchData.length === 0 || searchData.length >= 2) {
        fetchRecruiterData(1, dataSize, searchData, sortArray);
        const payload = {
          searchText: searchData,
          filterObject: filterParams,
        };
        dispatch(getRecruiterUserDataCount(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchData]);

  const fetchRecruiterData = (pageNumber, limit, searchVal, sortArray) => {
    const payload = {
      currentPage: pageNumber,
      limit: limit,
      searchText: searchVal,
      sortQuery: sortArray,
      filterObject: filterParams,
    };
    dispatch(getRecruiterUserData(payload));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchRecruiterData(pageNumber, dataSize, searchData, sortArray);
  };

  const handleSizeChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
    fetchRecruiterData(1, ds, searchData, sortArray);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
  };
  const applySort = (sortedData) => {
    setCurrentPage(1);
    fetchRecruiterData(1, dataSize, searchData, sortedData);
  };

  const getFilterParamsNew = (val, status) => {
    const filterParam = {
      jobTitle:
        status == "" ? [] : status == "jobTitle" ? val : selectedData.jobTitle,
      language:
        status == "" ? [] : status == "language" ? val : selectedData.language,
      region:
        status == "" ? [] : status == "region" ? val : selectedData.region,
      jobCategory:
        status == ""
          ? []
          : status == "jobCategory"
          ? val
          : selectedData.jobCategory,
      roles: status == "" ? [] : status == "roles" ? val : selectedData.roles,
      activity:
        status == "" ? [] : status == "activity" ? val : selectedData.activity,
      experience:
        status == ""
          ? []
          : status == "experience"
          ? val
          : selectedData.experience,
      country:
        status == ""
          ? ""
          : status == "country"
          ? val
          : status == "state"
          ? ""
          : status == "address"
          ? ""
          : selectedData.country,
      state:
        status == ""
          ? ""
          : status == "state"
          ? val
          : status == "address"
          ? ""
          : status == "country"
          ? ""
          : selectedData.state,
      address:
        status == ""
          ? []
          : status == "address"
          ? val
          : status == "country"
          ? []
          : status == "state"
          ? []
          : selectedData.address,
    };

    setFilterParams(filterParam);
    return filterParam;
  };

  const applyFilter = (val, status) => {
    setopenTenantPopUP(false);
    setCurrentPage(1);
    const payload = {
      currentPage: 1,
      limit: dataSize,
      searchText: searchData,
      sortQuery: sortArray,
      filterObject: getFilterParamsNew(val, status),
    };
    const payloadCount = {
      searchText: "",
      filterObject: getFilterParamsNew(val, status),
    };
    dispatch(getRecruiterUserData(payload));
    dispatch(getRecruiterUserDataCount(payloadCount));
  };

  return (
    <div>
      {(countLoading || loading) && <Loader />}
      <RecruiterOverview
        isFrom={"Bar"}
        userData={usersData}
        getRecruiters={getRecruiters}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        handleSizeChange={handleSizeChange}
        dataTotal={dataTotal}
        dataSize={dataSize}
        handleSearch={handleSearch}
        searchData={searchData}
        fetchRecruiterData={fetchRecruiterData}
        sortArray={sortArray}
        setSortArray={setSortArray}
        applySort={applySort}
        setSelectedData={setSelectedData}
        selectedData={selectedData}
        applyFilter={applyFilter}
        openTenantPopUP={openTenantPopUP}
        setopenTenantPopUP={setopenTenantPopUP}
      />
    </div>
  );
};

export default Index;
