import React, { useState, useEffect, useRef } from "react";
import { browserName } from "react-device-detect";
import axios from "axios";
import Validator from "email-validator";
import Event from "../../../googleanalytic/Tracking";
import { toast } from "react-toastify";
import ShortUniqueId from "short-unique-id";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useHistory, withRouter } from "react-router-dom";
import Loader from "../../Loader/Loader";
import logo from "../../../assets/images/High5Logo.png";
import high5people from "../../../assets/images/high5people.jpg";
import HealthCare1 from "../../../assets/images/HealthCare1.png";
import HealthCare2 from "../../../assets/images/HealthCare2.png";
import HealthCare3 from "../../../assets/images/HealthCare3.png";

import ResumeParsed from "../../components_v3/disposition/applyJob_v2_Parsing/newtalentform/ResumeParsed";
import high5Logo from "../../../images/high5-logo.jpg";
import ApplyPageProfile from "./ApplyPageProfile";
import { post } from "../../../service/ApiService";
import PoolScreeningQues from "./ApplyScreeningQuestion";
import Screening_questions from "./Screening_questions";
import RTH_Screening from "./RTH_Screening";
import { getUserRole } from "../../../service/AuthService";
import AdditionalQuestions from "./AdditionalInfo";

const UpdateProfileInfo = ({ job, jobCategory }) => {
  const uid = new ShortUniqueId({ length: 10 });
  const history = useHistory();
  const { tenant, recruiterid } = useParams();
  const [ip, setIp] = useState("");
  const [errors, setError] = useState({});
  const [agree, setAgree] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isScreening, setIsScreening] = useState(false);
  const [isRTHScreening, setIsRTHScreening] = useState(false);
  const [PMQuestion, setPMQuestion] = useState([{ answer: [] }]);
  const [recruiterQuestion, setRecruiterQuestion] = useState([{ answer: [] }]);
  const [BAQuestion, setBAQuestion] = useState([{ answer: [] }]);
  const [screeningQuestions, setScreeningQuestions] = useState([
    {
      name: "",
      question: "",
      answer: "",
    },
  ]);
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [salaryValues, setSalaryValues] = useState({ min: "", max: "" });
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    longitude: "",
    latitude: "",
    email: "",
    mobile: "",
    mobilePhoneCode: "",
    location: "",
    city: "",
    state: "",
    country: "",
    experienceLevel: "",
    generalQuestion:
      job?.generalQuestion &&
      JSON.parse(job?.generalQuestion).map((item, idx) => {
        if (
          item.name === "Nursing License" ||
          item.name === "Nursing Specialty"
        ) {
          return {
            ...item,
            answer: [],
          };
        } else {
          return {
            ...item,
            answer: "",
          };
        }
      }),
  });

  const [resumeFile, setResumeFile] = useState({ fileName: "", base64: "" });
  const [parsedData, setParsedData] = useState({});
  const [parsed, setParsed] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [idCandidate, setIdCandidate] = useState(null);

  const [dispositionID, setDispositionID] = useState({
    dispositionID: "",
    candidateID: "",
  });

  // useEffect(() => {
  //   if (job && Object.entries(job).length > 0) {
  //     let jobCategory = JSON.parse(job.industry);
  //     setJobCategory(jobCategory[0]);
  //   }
  // }, [job]);
  const experienceOptions = [];
  for (let years = 0; years <= 20; years++) {
    experienceOptions.push({
      value: years.toString(),
      label: years === 1 ? "1 year" : `${years} years`,
    });
  }

  useEffect(() => {
    getIpData();
  }, []);

  useEffect(() => {
    checkAndTrackClick();
  }, []);

  const checkAndTrackClick = async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const sid = urlParams.get("sid");
    if (sid) {
      const res = await post("/applyjob/trackUrlClick", {
        InviteURLId: sid,
      }).then((res) => {
        if (res.ok) {
          alert("loading apply page");
          const newUrl = window.location.href.split("?")[0];
          window.history.replaceState({}, document.title, newUrl);
          window.location.href = "YOUR_REDIRECT_URL";
        } else {
          console.error("Failed to track click");
        }
      });
    } else {
      console.log("sid parameter not found or click already tracked");
    }
  };

  const getIpData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res.data.ip);
  };

  //validation

  const validationHandler = () => {
    let formIsValid = true;
    let errorData = {};
    let screeningErrorData = [];
    if (formData.firstName === "") {
      formIsValid = false;
      errorData["firstName"] = "Please enter your first name";
    }
    if (formData.lastName === "") {
      formIsValid = false;
      errorData["lastName"] = "Please enter your last name";
    }
    // if (formData.mobile === "") {
    //   formIsValid = false;
    //   errorData["mobile"] = "Please enter your mobile number";
    // }
    if (formData.city === "") {
      formIsValid = false;
      errorData["city"] = "Please enter your location";
    }
    if (formData.state === "") {
      formIsValid = false;
      errorData["state"] = "Please enter your state";
    }
    if (formData.country === "") {
      formIsValid = false;
      errorData["country"] = "Please enter your country";
    }
    if (formData.email === "") {
      formIsValid = false;
      errorData["email"] = "Please enter your email";
    }
    if (formData.email && Validator.validate(formData.email) === false) {
      formIsValid = false;
      errorData["email"] = "Please enter valid email";
    }
    if (formData.experienceLevel === "") {
      formIsValid = false;
      errorData["experienceLevel"] = "Please enter your experience level";
    }
    if (!resumeFile?.fileName) {
      formIsValid = false;
      toast.error("Please upload resume");
    }
    if (additionalInfo.some((x) => x.answer === "")) {
      formIsValid = false;
      toast.error("Please answer all additional info questions");
    } else if (
      additionalInfo.some(
        (x) =>
          x.name === "Salary Requirements" &&
          !x.answer.split(",")[0] &&
          !x.answer.split(",")[1]
      )
    ) {
      formIsValid = false;
      toast.error("Please answer all additional info questions");
    }
    if (
      additionalInfo.some(
        (x) =>
          x.name === "Salary Requirements" &&
          !x.answer.split(",")[0] &&
          x.answer.split(",")[1]
      )
    ) {
      formIsValid = false;
      toast.error("Please enter minimum salary");
    }
    if (
      additionalInfo.some(
        (x) =>
          x.name === "Salary Requirements" &&
          x.answer.split(",")[0] &&
          !x.answer.split(",")[1]
      )
    ) {
      formIsValid = false;
      toast.error("Please enter maximum salary");
    }
    if (parseInt(salaryValues.min) >= parseInt(salaryValues.max)) {
      formIsValid = false;
      toast.error("Minimum value must be smaller than maximum value.");
    }

    // if (job?.generalQuestion && JSON.parse(job?.generalQuestion)) {
    //   formData?.generalQuestion?.map((elem) => {
    //     if (elem.answer.length == 0) {
    //       screeningErrorData.push("Please enter the mandatory questions");
    //       errorData["screening"] = "Required Field*";
    //     }
    //   });
    //   if (screeningErrorData.length > 0) {
    //     toast.error(screeningErrorData[0]);
    //     formIsValid = false;
    //   }
    // }
    // if (job?.jobTitle?.replace(/\s+/g, "").toLowerCase() === "projectmanager") {
    //   PMQuestion?.map((elem) => {
    //     if (elem.answer.length == 0) {
    //       screeningErrorData.push("Please enter the mandatory questions");
    //       errorData["project"] = "Required Field*";
    //     }
    //   });
    //   if (screeningErrorData.length > 0) {
    //     toast.error(screeningErrorData[0]);
    //     formIsValid = false;
    //   }
    // }
    // if (
    //   job?.jobTitle?.replace(/\s+/g, "").toLowerCase() === "businessanalyst"
    // ) {
    //   BAQuestion?.map((elem) => {
    //     if (elem.answer.length == 0) {
    //       screeningErrorData.push("Please enter the mandatory questions");
    //       errorData["business"] = "Required Field*";
    //     }
    //   });
    //   if (screeningErrorData.length > 0) {
    //     toast.error(screeningErrorData[0]);
    //     formIsValid = false;
    //   }
    // }
    // if (JSON.parse(job?.industry).includes("Talent Acquisition")) {
    //   recruiterQuestion?.map((elem) => {
    //     if (elem.answer.length == 0) {
    //       screeningErrorData.push("Please enter the mandatory questions");
    //       errorData["recruiter"] = "Required Field*";
    //     }
    //   });
    //   if (screeningErrorData.length > 0) {
    //     toast.error(screeningErrorData[0]);
    //     formIsValid = false;
    //   }
    // }
    setError(errorData);
    let resultObj = {
      errorData: errorData,
      isValid: formIsValid,
    };
    return resultObj;
  };

  const InviteTalentWithVet = async (candidateId) => {
    try {
      var date = new Date();
      let vettingDetails = JSON.parse(job.vettingDetails);

      let paramObj = {
        isPoolCandidate: false,
        tenantName: job.tenantName,
        jobTitle: job.jobTitle,
        candidateInfo: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          phone: formData.mobile,
        },
        expiryDate: date.setDate(date.getDate() + 7),
        createdFrom: "talentPool",
        testAssign: vettingDetails._id,
        vettingDetails: vettingDetails,
        uniqueCode: uid(),
        testStatus: "Invited",
        recruiterId: recruiterid,
        candidateId: candidateId,
        jobId: job?.poolId,
        fk_tenant: tenant,
        companyName: tenant === 0 ? job.tenantName : "High5Hire",
        reviewerFirstName: job?.reviewerFirstName,
        reviewerLastName: job?.reviewerLastName,
        reviewerEmail: job?.reviewerEmail,
        poolowner: job.poolowner,
      };

      const vetRes = await post("/vetIntegration/candidate/Invite", paramObj);
      if (vetRes.status === 200) {
        // setLoading(false);
        // if (setApply) {
        //   setApply(false);
        // }
        // setTimeout(() => {
        //   history.push(`/careers/joblist`);
        //   window.location.reload();
        // }, 5000);
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleAgree = async () => {
    setAgree(!agree);
  };

  const submitCandidate = async (status) => {
    setLoading(true);
    const params = {
      poolId: job.poolId,
      email: formData.email.trim(),
      recruiterId: recruiterid,
    };
    const url = "/careerspool/isemailexistpool";
    const candidateResponse = await post(url, params);
    if (candidateResponse.status === 200) {
      if (candidateResponse.data.message) {
        setLoading(false);
        setTimeout(() => {
          history.push(`/career/home`);
        }, 1000);
        return toast.error(candidateResponse.data.message);
      } else {
        const candidateData = candidateResponse.data;

        let paramObj = {
          isPoolCandidate: true,
          userId: candidateData ? candidateData.userId : null,
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          workPhone: "",
          workPhoneCode: "",
          city: formData.city,
          state: formData.state,
          country: formData.country,
          zipcode: "",
          FK_tenant: job.fk_tenant,
          jobTitle: job.jobTitle,
          jobId: job.poolId,
          // createdBy: "",
          address: formData?.address,
          homePhoneCode: formData.mobilePhoneCode,
          homePhone: formData.mobile,
          facebook: "",
          twitter: "",
          linkedIn: formData.linkedIn ?? "",
          preferredSalary: "",
          preferredSalaryCurrency: "",
          minContractRate: "",
          minContractRateCurrency: "",
          description: "",
          positionTypes: JSON.stringify([]),
          preferredLocation: JSON.stringify([]),
          primarySkills: JSON.stringify([
            ...parsedData?.skills?.map((e) => e.name),
          ]),
          secondarySkills: JSON.stringify([]),
          skillSet: JSON.stringify([]),
          source: "Career Page",
          visaStatus: "",
          designation: "",
          currentEmployer: "",
          experienceMonth: "",
          experienceYear: "",
          isFresher: "",
          isRemote: "",
          experiences: JSON.stringify([]),
          educations: JSON.stringify([]),
          certifications: JSON.stringify([]),
          licenses: JSON.stringify([]),
          legallyAuthorized: "",
          requireSponsorship: "",
          experienceLevel: formData.experienceLevel,
          recruiterId: recruiterid,
          candidateId: candidateData?.id_user,
          tenantName: "",
          highestEducation: "",
          oldFileName: "",
          fileName: resumeFile.fileName,
          base64: resumeFile.base64,
          skillMatrix: JSON.stringify(""),
          salaryType: "",
          location: JSON.stringify(formData?.location),
          dispositionStatus: 42,
          longitude: formData.longitude,
          latitude: formData.latitude,
        };

        const applyURL =
          candidateData.candidateStatus === "New Talent"
            ? "/applyjob/addtalent"
            : "/applyjob/updatetalent";

        const poolDispositionURL =
          candidateData.candidateStatus === "New Talent"
            ? "/careerspool/insertsubmitpool"
            : candidateData.candidateStatus === "Existing Talent" &&
              candidateData.id_disposition == null
            ? "/careerspool/insertsubmitpool"
            : candidateData.candidateStatus === "Login Talent" &&
              candidateData.id_disposition == null
            ? "/careerspool/insertsubmitpool"
            : "/careerspool/updatesubmitpool";

        const resp = await post(applyURL, paramObj).then(async (data) => {
          setLoading(true);
          if (data.status === 200) {
            const payload = {
              userId:
                sessionStorage.getItem("userId") !== null
                  ? sessionStorage.getItem("userId")
                  : candidateData.status === 21
                  ? candidateData.userId
                  : data.data.candidateId,
              jobId: job.jobId,
              poolId: job?.poolId,
              jobTitle: job.jobTitle,
              recruiterId: recruiterid,
              status: 42,
              id_disposition: candidateData?.id_disposition,
              screeningQuestions: JSON.stringify([]),
              additionalInfo: JSON.stringify(additionalInfo),
              projectManager: JSON.stringify(PMQuestion),
              BAQuestion: JSON.stringify(BAQuestion),
              recruiterQuestion: JSON.stringify(recruiterQuestion),
              disqualifyComments: null,
              fk_tenant: tenant,
              tenantName: job.tenantName,
              firstName: formData.firstName,
              lastName: formData.lastName,
              email: formData.email,
              browser: browserName,
              ipAddress: ip,
              activity: "Applied",
              applyJob: false,
              candidateStatus: candidateData.candidateStatus,
              id_user:
                candidateData.candidateStatus === "New Talent"
                  ? data.data.id_user
                  : candidateData.id_user,
            };

            let candidateId =
              candidateData.candidateStatus === "New Talent"
                ? data.data.candidateId
                : candidateData.userId;

            setIdCandidate(candidateId);
            await post(poolDispositionURL, payload).then(async (res) => {
              if (res.status === 200) {
                Event(
                  "Job Appiled",
                  `${formData.firstName} ${formData.lastName},${job.jobTitle}`,
                  window.location.pathname
                );

                let dispositionId =
                  poolDispositionURL === "/careerspool/insertsubmitpool"
                    ? res?.data[0]?.id_candidateTalentPool
                    : candidateData?.id_disposition;

                setDispositionID({
                  ...dispositionID,
                  dispositionID: dispositionId,
                  candidateID: candidateId,
                });

                if (sessionStorage.getItem("token") == null) {
                  try {
                    const response = await post("/candidateLogin/", {
                      password: data.data.password,
                      userName: formData.email,
                    }).then((res) => {
                      sessionStorage.setItem("token", res.data.token);
                      sessionStorage.setItem("name", res.data.name);
                      sessionStorage.setItem("userId", res.data.userId);
                      sessionStorage.setItem("userName", res.data.userName);
                    });
                  } catch (err) {
                    console.log("err", err);
                  }
                }
                if (JSON.parse(job?.screeningQuestion)?.length > 0) {
                  screeningQuestionsHandle(dispositionId, status, candidateId);
                } else {
                  toast.success(`Joined ${job?.jobTitle} successfully`);
                  history.push(`/career/home`);
                  setLoading(false);
                  if (
                    JSON.parse(job?.vettingDetails) !== null &&
                    JSON.parse(job?.screeningQuestion)?.length == 0
                  ) {
                    await InviteTalentWithVet(candidateId);
                  }
                }

                // await post("/auditLog/candidateData", {
                //   ...trackedData,
                //   status: "success",
                //   jobId: job.jobId,
                //   poolId: job.poolId,
                //   formType: "B",
                //   navigatedPages: trackedData.navigatedPages + "/success",
                //   firstName: candidateDetails.firstName,
                //   lastName: candidateDetails.lastName,
                //   email: candidateDetails.email,
                //   resume: resumeFile.fileName ? true : false,
                // });

                // setTrackedData({
                //   ...trackedData,
                //   status: "success",
                //   jobId: job.jobId,
                //   poolId: job.poolId,
                //   navigatedPages: trackedData.navigatedPages + "/success",
                //   firstName: candidateDetails.firstName,
                //   lastName: candidateDetails.lastName,
                //   email: candidateDetails.email,
                //   resume: resumeFile.fileName ? true : false,
                // });
              }
            });
          } else {
            setLoading(false);
            toast.error("Something went wrong, please try again ");
          }
        });
      }
    }
  };

  const screeningQuestionsHandle = async (
    dispositionId,
    status,
    candidateId
  ) => {
    // let status = 42; // Engaged
    // // await submitCandidate()
    // if (screeningQuestions.some((x) => x.answer === ""))
    //   return toast.error("Please answer all screening questions.");
    // else if (screeningQuestions.some((x) => x.answer === "No")) status = 12; //disqualified
    const data = {
      screeningQuestions: JSON.stringify(screeningQuestions),
      dispositionID: dispositionId,
      status,
    };
    setLoading(true);
    await post("/careerspool/updateDispositionScreening", data);
    toast.success(`Joined ${job?.jobTitle} successfully`);

    if (JSON.parse(job?.vettingDetails) !== null && status == 42) {
      setLoading(true);
      await InviteTalentWithVet(candidateId);
      history.push(`/career/home`);
      setLoading(false);
    } else history.push(`/career/home`);
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();

    const validObj = validationHandler();
    if (validObj.isValid) {
      try {
        //submit code
        if (JSON.parse(job?.screeningQuestion)?.length > 0) {
          toast.success(`Please complete the knockout assessment questions`);
          setIsScreening(true);
        } else {
          submitCandidate();
        }

        //perform sub community api call here
      } catch (error) {
        setLoading(false);
        console.log("error", error);
        toast.error("Oops! something went wrong. please try again later");
      }
    }
  };
  const ResumeComponent = () => {
    return (
      <ResumeParsed
        resumeFile={resumeFile}
        setResumeFile={setResumeFile}
        parsed={parsed}
        setParsedData={setParsedData}
        setParsed={setParsed}
      />
    );
  };

  const screeningValidation = () => {
    let errorData = [];
    let isValid = true;
    formData?.generalQuestion?.map((elem) => {
      if (
        (elem.answer == "" || elem.answer === false) &&
        elem.isMandatory == true
      ) {
        errorData.push("Please answer the mandatory questions");
      }
    });
    if (errorData.length > 0) {
      toast.error(errorData[0]);
      isValid = false;
    }
    return isValid;
  };

  return (
    <>
      {loading && <Loader />}

      <div className="d-flex justify-content-center align-items-center vh-200">
        <div className="section section-sm pt-0" style={{ width: "100%" }}>
          <div className="ml-3 my-2">
            <img
              className="ml-2 my-auto"
              style={{ height: "40px", width: "75px" }}
              src={logo}
              alt="logo"
            />
          </div>
          {isScreening ? (
            <>
              <hr style={{ marginTop: "20px" }} />
              {/* <PoolScreeningQues
                job={job}
                candidateDetails={formData}
                dispositionID={dispositionID}
              /> */}
              <Screening_questions
                job={job}
                idCandidate={idCandidate}
                candidateDetails={formData}
                dispositionID={dispositionID}
                screeningData={JSON.parse(job?.screeningQuestion)}
                InviteTalentWithVet={InviteTalentWithVet}
                setIsRTHScreening={setIsRTHScreening}
                setIsScreening={setIsScreening}
                submitCandidate={submitCandidate}
                screeningQuestions={screeningQuestions}
                setScreeningQuestions={setScreeningQuestions}
              />
            </>
          ) : isRTHScreening ? (
            <RTH_Screening
              job={job}
              idCandidate={idCandidate}
              candidateDetails={formData}
              dispositionID={dispositionID}
              screeningData={JSON.parse(job?.readyToHireQA)}
              InviteTalentWithVet={InviteTalentWithVet}
              setIsRTHScreening={setIsRTHScreening}
              setIsScreening={setIsScreening}
            />
          ) : (
            <div className="hero-container">
              {jobCategory === "Healthcare" ? (
                <div className="hero-overlay">
                  <img src={HealthCare2} alt="HealthCare2" />
                  <img src={HealthCare3} alt="HealthCare3" />
                  <img src={HealthCare1} alt="HealthCare1" />

                  <div className="section pt-7 hero-content">
                    {/* <div className="container"> */}
                    {/* <div className="row topHeight"> */}
                    <div className="col-12">
                      {/* <div className=" mb-5"> */}
                      <div className="mb-5">
                        <h5 className=" font-22 font-white">
                          Join our {job.poolname} talent community!
                        </h5>
                        <p className="font-14 font-white">
                          We're passionate about connecting healthcare
                          professionals with incredible opportunities!
                        </p>
                        <p className="font-14 font-white">
                          Sign up for our talent community to receive updates on
                          job opportunities, news, and recruiting events at
                          High5.
                        </p>
                      </div>
                      {/* </div> */}
                    </div>
                    {/* </div> */}
                    {/* </div> */}
                  </div>
                </div>
              ) : (
                <div className="hero-overlay">
                  <img src={high5people} alt="high5people" />

                  <div className="section pt-7 hero-content">
                    {/* <div className="container"> */}
                    <div className="row">
                      <div className="col-12">
                        <div className=" mb-5">
                          {/* <div className="mb-5"> */}
                          <h3 className="font-22 font-white">
                            Join our {job?.poolname} talent community!
                          </h3>
                          <p className="font-14 font-white">
                            We're passionate about connecting professionals with
                            incredible opportunities!
                          </p>
                          <p className="font-14 font-white">
                            Sign up for our talent community to receive updates
                            on job opportunities, news, and recruiting events at
                            High5.
                          </p>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              )}

              <div
                style={{
                  margin: "10px 100px",
                  marginTop: `${
                    jobCategory === "Healthcare" ? "-75px" : "-75px"
                  }`,
                  zIndex: "3",
                }}
              >
                <div className="card card-lg">
                  <div className="card-body pb-0">
                    <hr />

                    <div className="row">
                      {/* <UpdateProfileFaq /> */}
                      <div className="col-lg-12">
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex flex-column text-left   mb-3">
                              <h6>
                                Complete your application by uploading your
                                Resume/CV
                              </h6>
                              <div> {ResumeComponent()}</div>
                              <small>
                                Upload either .pdf or .docx file (upto 2mb)
                              </small>
                            </div>

                            <ApplyPageProfile
                              formData={formData}
                              setFormData={setFormData}
                              phoneValidation={phoneValidation}
                              setPhoneValidation={setPhoneValidation}
                              //validation={validation}
                              errorData={errors}
                              job={job}
                              candidateDetails={formData}
                              dispositionID={dispositionID}
                              setScreeningAnswers={setFormData}
                              setPMQuestion={setPMQuestion}
                              setBAQuestion={setBAQuestion}
                              setRecruiterQuestion={setRecruiterQuestion}
                              screeningQuestions={additionalInfo}
                              setScreeningQuestions={setAdditionalInfo}
                              salaryValues={salaryValues}
                              setSalaryValues={setSalaryValues}
                            />

                            <div className="row mt-3 ">
                              <div className="form-check form-switch d-flex align-items-center ml-2 mt-2 px-1">
                                <input
                                  className=" mr-2"
                                  style={{ width: 15, height: 15 }}
                                  type="checkbox"
                                  id="isFresher"
                                  onChange={handleAgree}
                                />
                                <label
                                  className="form-check-label pt-0 "
                                  htmlFor="remoteSwitch"
                                >
                                  By submitting this form, you agree to receive
                                  job alerts, newsletters, and updates from
                                  High5 Talent Community.
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="modal-footer pl-0">
                      <button
                        className="btn btn-primary btn btn-lg mb-3"
                        onClick={(e) => handleSubmit(e)}
                        disabled={!agree}
                      >
                        <strong style={{ fontSize: "bolder" }}>Join Now</strong>
                      </button>
                      <div className="row mx-auto " style={{ width: "100%" }}>
                        <div className="col-md-7">
                          <footer>
                            <div>
                              <span className="text-secondary">Powered by</span>{" "}
                              <a
                                href="https://www.high5hire.com/"
                                target="_blank"
                              >
                                <img
                                  style={{
                                    width: 40,
                                    height: 35,
                                    paddingBottom: 10,
                                  }}
                                  src={high5Logo}
                                />
                              </a>
                            </div>
                          </footer>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
export default withRouter(UpdateProfileInfo);
