import React, { useState } from "react";
import CandateAssessmentFullView from "./CandidateAssessmentFullView";
import Modal from "react-bootstrap/Modal";

function CandidateAssesmentGridView(props) {
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [openAssessmentDetailsModel, setOpenAssessmentDetailsModel] =
    useState(false);

  const openAssessmentDetails = (module) => {
    setAssessmentDetails(module);
    setOpenAssessmentDetailsModel(true);
  };

  const closeAssessmentModal = () => {
    setOpenAssessmentDetailsModel(false);
  };

  return (
    <>
      <div
        class="row d-none d-flex-1200 d-flex-force"
        // v-bind:class="{ 'd-flex-force': toggleView.gridview }"
      >
        {props.assessmentList.map((module) => (
          <div
            v-for="(module,index) in modules"
            v-if="module.isVisible"
            class="col-lg-4 col-md-6 col-sm-12 d-flex"
          >
            <a
              href="#top"
              onClick={() => {
                openAssessmentDetails(module);
              }}
              // v-on:click.stop.prevent="onItemClick(module)"
              class="card card-flat col card-tag-bottom mb-4 card-lg card-modules-grid p-relative"
            >
              <div class="card-body">
                {(module.difficulty === "1 Way Video" ||
                  module.difficulty === "beginner") && (
                  <span class="mr-2 tag font-bold tag-blue1">
                    {module.difficulty}
                  </span>
                )}

                {(module.difficulty === "2 Way Video" ||
                  module.difficulty === "intermediate") && (
                  <span class="mr-2 tag font-bold tag-orange1">
                    {module.difficulty}
                  </span>
                )}

                {(module.difficulty === "MCQ" ||
                  module.difficulty === "advanced") && (
                  <span class="mr-2 tag font-bold tag-red1">
                    {module.difficulty}
                  </span>
                )}

                {module.difficulty === "expert" && (
                  <span class="mr-2 tag font-bold tag-coral">
                    {module.difficulty}
                  </span>
                )}
                <div class="d-flex mt-2 mb-2 align-items-center">
                  <h6 class="mb-0">{module.title}</h6>
                </div>
                <div class="p-2 bg-blue4 font-regular font-primary font-gray1 mt-1">
                  <div class="mb-2">
                    <i class="far fa-fw fa-stopwatch me-2"></i>Duration
                    {module.duration}
                  </div>
                  <div>
                    <i class="far fa-fw fa-clock me-2"></i>
                    <span>{module.lastUpdated}</span> &nbsp;
                    <span v-if="module.lastUpdated > 1">days</span> &nbsp;
                    {/* <span v-if="module.lastUpdated == 1">day</span> */}
                    ago
                  </div>
                </div>

                <div class="mt-2">
                  <p class="mb-1">
                    <small>Progress</small>
                  </p>
                  {module.status === "inprogress" && (
                    <div v-if="module.status === 'inprogress'">
                      <small>20% Complete</small>
                      <div class="alert-progress alert-progress-static bg-gray3">
                        <div
                          style={{ transform: "translateX(-80%)" }}
                          class="alert-progress-bar"
                        ></div>
                      </div>
                    </div>
                  )}
                  {module.status === "completed" && (
                    <div v-if="module.status === 'completed'">
                      <small>100% Complete</small>
                      <div class="alert-progress alert-progress-static bg-green1"></div>
                    </div>
                  )}
                  {module.status !== "completed" &&
                    module.status !== "inprogress" && (
                      <div>
                        <small>Not Started</small>
                        <div class="alert-progress alert-progress-static bg-gray3">
                          <div
                            style={{ width: "0%;" }}
                            class="alert-progress-bar bg-green1"
                          ></div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </a>
          </div>
        ))}
      </div>
      <Modal
        size="lg"
        show={openAssessmentDetailsModel === true}
        // dialogClassName="view-panel show"
      >
        <CandateAssessmentFullView
          assessmentDetails={assessmentDetails}
          closePopUP={closeAssessmentModal}
        />
      </Modal>
    </>
  );
}
export default CandidateAssesmentGridView;
