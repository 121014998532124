import React, { useState } from "react";
import { ButtonLoader, domain } from "../../../../../constants/Constants";
import { toast } from "react-toastify";
import { post } from "../../../../../service/ApiService";
import { getCandidateId } from "../../../../../service/AuthService";

function Description({ data, ip, browserName, setUpdateDetails, updateDetails  }) {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  // const [bio, setBio] = useState(storyinfo);
  const [description, setDescription] = useState(data.description);
  // const [temoBio, setTempBio] = useState(storyinfo);

  const editStoryInfo = () => {
    setReadOnlyMode(false);
    setSaveClicked(true);
  };

  const cancelStoryInfo = () => {
    // setBio(temoBio);
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
    setDescription(data.description);
  };

  const saveStoryInfo = async () => {
    // setTempBio(bio);
    setSaveClicked(false);
    setSubmittingFrom(true);
    try {
      const parameters = {
        ...data,
        zipcode: data.zipcode,
        experiences: data.experiences,
        educations: data.educations,
        description: description,
        activity: "Description updated",
        candidateId: data.userId,
        ipAddress: ip,
        browser: browserName,
      };
      const res = await post("/candidate/updatetalent", parameters);
      if (res.status === 200) {
        setSubmittingFrom(false);
        setShowSuccessMessage(true);
        setUpdateDetails(!updateDetails);
        setTimeout(() => {
          setReadOnlyMode(true);
          setShowSuccessMessage(false);
        }, 1000);
        toast.success("Updated successfully!");
      }
    } catch (error) {
      toast.error("Error Occured");
    }
  };

  return (
    <>
      <div>
        <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
          <div class="card card-lg">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between">
                <h6 class="mb-0">Description</h6>
                {readOnlyMode ? (
                  <button
                    type="button"
                    class="btn btn-text"
                    onClick={editStoryInfo}
                  >
                    <i class="fas fa-pencil-alt mr-1"></i>
                    Edit
                  </button>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={cancelStoryInfo}
                      class="btn btn-sm btn-secondary mr-1"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={saveStoryInfo}
                      class="btn btn-sm btn-primary"
                    >
                      {saveClicked && <span>Save Changes</span>}

                      {submittingForm && (
                        <span>
                          Saving Changes
                          <img width="20px" src={ButtonLoader} alt="" />
                        </span>
                      )}
                      {showSuccessMessage && <span>Changes Saved!</span>}
                    </button>
                  </div>
                )}
              </div>
              <hr />
              <div class="col-lg-12 col-md-12 col-sm-12">
                {readOnlyMode ? (
                  <p className="m-2">
                    {description ? (
                      description
                    ) : (
                      <span>
                        Description not yet added. Please update it by clicking{" "}
                        <strong>Edit</strong> button above
                      </span>
                    )}
                  </p>
                ) : (
                  <div class="col-lg-12">
                    <div class="form-floating mt-2">
                      <textarea
                        class="form-control"
                        placeholder="Type Here"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Description;
