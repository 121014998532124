import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import { ButtonLoader } from "../../../../../constants/Constants";
import GoogleLocationCityAPI from "../../../../location/GoogleLocationCityAPI";
import { post } from "../../../../../service/ApiService";

function PersonalInfo({ data, getData, ip, browserName,updateDetails,
  setUpdateDetails, }) {
  const searchInput = useRef(null);
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [dialCode, setDialCode] = useState("");
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [personalInfo, setpersonalInfo] = useState({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    zipcode: data.zipcode == "" ? "NA" : data.zipcode,
    address: data.address,
    city: data.city,
    state: data.state == null ? "NA" : data.state,
    country: data.country,
    workPhoneCode: data.workPhoneCode,
    mobilePhone: data.workPhone,
    homePhoneCode: data.homePhoneCode,
    homePhone: data.homePhone,
  });
  const [temppersonalInfo, setTemppersonalInfo] = useState({
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    zipcode: data.zipcode == "" ? "NA" : data.zipcode,
    address: data.address,
    city: data.city,
    state: data.state == null ? "NA" : data.state,
    country: data.country,
    workPhoneCode: data.workPhoneCode,
    mobilePhone: data.workPhone,
    homePhoneCode: data.homePhoneCode,
    homePhone: data.homePhone,
  });
  const [phoneValidation, setPhoneValidation] = useState({});

  const setLocation = (address, searchInput) => {
    const city = address.locality || address.city;
    setpersonalInfo({
      ...personalInfo,
      city: city,
      country: address.country,
    });
    searchInput.current.value = city;
  };

  const handleChange = (e) => {
    if (e.target.name === "zipcode") {
      let x = e.target.value.replace(/[^0-9]/, "");

      setpersonalInfo({ ...personalInfo, [e.target.name]: x });
    } else
      setpersonalInfo({ ...personalInfo, [e.target.name]: e.target.value });
  };

  const editPersonalInfo = () => {
    setReadOnlyMode(false);
  };

  const cancelPersonalInfo = () => {
    setpersonalInfo(temppersonalInfo);
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
  };

  const _onChangeText = (value, code) => {
    const asYouType = new AsYouType(code);
    const formattedNumber = asYouType.input(value);
    const isValid = asYouType.isValid();
    setPhoneValidation(isValid);
  };

  const savePersonalInfo = async () => {
    if (
      !personalInfo.firstName ||
      !personalInfo.lastName ||
      // !personalInfo.state ||
      !personalInfo.city ||
      !personalInfo.country
      // ||
      // !personalInfo.zipcode
    ) {
      toast.error("Fields marked with *(Asterisk) are Mandatory");
    }
    // else if (!phoneValidation) {
    else if (personalInfo.homePhone === "") {
      return toast.error("Primary phone is required");

      // dialCode === personalInfo.homePhone
      //   ? toast.error("Primary phone is required")
      //   : toast.error("Invalid Phone Number");
    } else {
      setSaveClicked(false);
      setSubmittingFrom(true);
      try {
        const parameters = {
          ...data,

          // candidateID: getCandidateId(),

          experiences: data.experiences,
          educations: data.educations,
          firstName: personalInfo.firstName,
          middleName: "",
          lastName: personalInfo.lastName,
          zipcode: personalInfo.zipcode,
          address: personalInfo.address,
          city: personalInfo.city,
          state: personalInfo.state,
          stateName: "",
          country: personalInfo.country,
          workPhone: personalInfo.mobilePhone,
          homePhone: personalInfo.homePhone,
          candidateId: data.userId,
          activity: "Personal info updated",
          ipAddress: ip,
          browser: browserName,
        };
        const res = await post("/candidate/updatetalent", parameters);
        if (res.status === 200) {
          setTemppersonalInfo(personalInfo);
          // getData();
          setSubmittingFrom(false);
          setShowSuccessMessage(true);
          setUpdateDetails(!updateDetails);
          setTimeout(() => {
            setReadOnlyMode(true);
            setShowSuccessMessage(false);
            setSaveClicked(true);
          }, 1000);
          sessionStorage.setItem(
            "name",
            `${personalInfo.firstName} ${personalInfo.lastName}`
          );
          toast.success("Updated successfully!");
        }
      } catch (error) {
        toast.error("Error Occured");
      }
    }
  };

  useEffect(() => {
    if (personalInfo.city) {
      if (searchInput.current) {
        searchInput.current.value = personalInfo.city;
      } else {
        searchInput.current = {
          ...searchInput.current,
          ...{ value: personalInfo.city },
        };
      }
    }
  }, [personalInfo.city, readOnlyMode]);

  const getPhone = (phone, phoneCode) => {
    if (phoneCode) {
      let actualPhone = phone.replace("+", "");
      let actualPhoneCode = phoneCode.replace("+", "");
      let phoneNumber = "";
      if (actualPhone.substring(0, actualPhoneCode.length) == actualPhoneCode) {
        phoneNumber = phone
          .replace("+", "")
          .slice(phoneCode.replace("+", "").length);
      } else {
        phoneNumber = phone.replace("+", "");
      }
      return "+" + actualPhoneCode + phoneNumber;
    } else {
      let phoneNumber = phone.replace("+", "");
      return `+1${phoneNumber}`;
    }
  };

  return (
    <>
      <div>
        <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
          <div className="card card-lg">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">Personal Info</h6>
                {readOnlyMode ? (
                  <button
                    type="button"
                    className="btn btn-text"
                    onClick={editPersonalInfo}
                  >
                    <i className="fas fa-pencil-alt mr-1"></i>
                    Edit
                  </button>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={cancelPersonalInfo}
                      className="btn btn-sm btn-secondary mr-1"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={savePersonalInfo}
                      className="btn btn-sm btn-primary"
                    >
                      {saveClicked && <span>Save Changes</span>}

                      {submittingForm && (
                        <span>
                          Saving Changes
                          <img width="20px" src={ButtonLoader} alt="" />
                        </span>
                      )}
                      {showSuccessMessage && <span>Changes Saved!</span>}
                    </button>
                  </div>
                )}
              </div>
              <hr />

              <div className="row mb-2">
                <div className="col-lg-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={personalInfo.firstName}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                    />
                    <label>First Name*</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={personalInfo.lastName}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                    />
                    <label>Last Name*</label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={personalInfo.email}
                      onChange={handleChange}
                      disabled
                    />
                    <label>Email*</label>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating ">
                    {!readOnlyMode ? (
                      <GoogleLocationCityAPI
                        setLocation={setLocation}
                        searchInput={searchInput}
                      />
                    ) : (
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        value={personalInfo.city}
                        onChange={(e) => handleChange(e)}
                        disabled={readOnlyMode}
                        placeholder=""
                      />
                    )}
                    <label>City*</label>
                  </div>
                </div>

                <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={"form-control"}
                      name="country"
                      placeholder="Enter a group name"
                      value={personalInfo.country}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                    />
                    <label>Country*</label>
                  </div>
                </div>

                {/* <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="addressCity"
                      value={personalInfo.addressCity}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                      placeholder=""
                    />
                    <label>City*</label>
                  </div>
                </div>
                <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="country"
                      value={personalInfo.country}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                      placeholder=""
                    />
                    <label>Country*</label>
                  </div>
                </div> */}
                {/* <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="zipcode"
                      onChange={handleChange}
                      value={personalInfo.zipcode}
                      disabled={readOnlyMode}
                      placeholder=""
                    />
                    <label>Zip code*</label>
                  </div>
                </div> */}
              </div>

              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="mb-2">
                    <label>Primary Phone *</label>
                  </div>
                  <div className="d-flex">
                    <PhoneInput
                      classsName="form-control"
                      name="homePhone"
                      country={"us"}
                      placeholder="Primary Phone"
                      inputClass="w-100"
                      value={getPhone(
                        personalInfo.homePhone,
                        personalInfo.homePhoneCode
                      )}
                      onChange={(value, country) => {
                        setpersonalInfo({
                          ...personalInfo,
                          ["homePhone"]: value,
                        });
                        _onChangeText(
                          value.replace(country.dialCode, ""),
                          country.countryCode.toUpperCase()
                        );
                      }}
                      disabled={readOnlyMode}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-2">
                    <label>Alternative Phone</label>
                  </div>
                  <div className="d-flex">
                    <PhoneInput
                      classsName="form-control"
                      required="true"
                      placeholder="Alternative Phone"
                      country={"us"}
                      inputClass="w-100"
                      value={personalInfo.mobilePhone}
                      name="mobilePhone"
                      onChange={(value, country) => {
                        setDialCode(country.dialCode);
                        setpersonalInfo({
                          ...personalInfo,
                          ["mobilePhone"]: value,
                        });
                      }}
                      defaultErrorMessage="It doesn't works, why?"
                      disabled={readOnlyMode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PersonalInfo;
