import React, { useState, useRef } from "react";
import CropperModal from "./CropperModal";
import { toast } from "react-toastify";
import AvatarPic from "../../../../assets/images/AvatarPic1.png";
import { getTenantType, isCurator } from "../../../../service/AuthService";

const AvatarUpload = ({
  imageData,
  setImageData,
  user,
  role,
  roleNames,
  isFrom,
  candidateName,
  candidateDesignation,
}) => {
  const [showCropper, setShowCropper] = useState(false);

  // Create a reference to the file input element
  const fileInputRef = useRef(null);

  const handleSaveCrop = (croppedImage) => {
    // Handle saving the cropped image data
    setImageData({ ...imageData, base64: croppedImage });
    setShowCropper(false);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file.size > 2 * 1024 * 1024) {
      toast.error("File size exceeds 2MB limit.");
      fileInputRef.current.value = "";
      return;
    }
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        const base64Data = reader.result;
        setImageData({ ...imageData, fileName: file.name, base64: base64Data });
      };
      reader.readAsDataURL(file);
      toast.success("Logo Added");
      setShowCropper(true);
      fileInputRef.current.value = "";
    }
  };

  const handleButtonClick = () => {
    // Programmatically trigger the file input element
    fileInputRef.current.click();
  };

  return (
    <div className="form-floating">
      {/* Hero section */}
      <div className="hero">
        <div className="section pt-5">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="d-flex flex-column align-items-center">
                  <div className="d-flex align-items-center mb-3">
                    <div>
                      <img
                        src={imageData?.base64 ? imageData?.base64 : AvatarPic}
                        alt="Uploaded"
                        width="80"
                        height="80"
                        className="p-1"
                        style={{
                          borderRadius: "50%",
                          objectFit: "contain",
                        }}
                      />
                      <div className="mt-0 d-flex justify-content-center align-content-center">
                        <span
                          style={{
                            cursor: "pointer",
                            borderRadius: "50%",
                            width: "90px",
                            display: "flex",
                            justifyContent: "end",
                            transform: "translate(-10%, -100%)",
                          }}
                          onClick={() => {
                            handleButtonClick();
                          }}
                        >
                          <i
                            className="fa fa-edit"
                            aria-hidden="true"
                            style={{ color: "#fff" }}
                          ></i>
                        </span>
                      </div>
                    </div>

                    {/* Hidden file input */}
                    <input
                      type="file"
                      ref={fileInputRef}
                      style={{ display: "none" }}
                      onChange={handleFileSelect}
                      accept="image/*"
                    />
                    {isFrom !== "CandidateProfile" ? (
                      <span className="ml-2">
                        <h6 className="font-white">{user}</h6>
                        <span className="tag tag-brandblue">
                          {roleNames[role] == "Admin"
                            ? "Client Admin"
                            : isCurator()
                            ? getTenantType() === 1
                              ? "Client Curator"
                              : "Curator"
                            : roleNames[role]}
                        </span>
                      </span>
                    ) : (
                      <span className="ml-2">
                        <h6
                          className="font-white"
                          style={{ marginBottom: "-2px" }}
                        >
                          {candidateName}
                        </h6>

                        <span style={{}}>
                          <small className="font-white">
                            {candidateDesignation}
                          </small>
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Cropper Modal */}
      {showCropper && (
        <CropperModal
          imageData={imageData}
          setImageData={setImageData}
          showCropper={showCropper}
          setShowCropper={setShowCropper}
          handleSave={handleSaveCrop}
        />
      )}
    </div>
  );
};

export default AvatarUpload;
