import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import logo from "../../assets/images/High5Logo.png";
import high5Logo from "../../images/high5-logo.jpg";
import high5people from "../../assets/images/high5people.jpg";
import HealthCare1 from "../../assets/images/HealthCare1.png";
import HealthCare2 from "../../assets/images/HealthCare2.png";
import HealthCare3 from "../../assets/images/HealthCare3.png";
import { post } from "../../service/ApiService";

const UpdateAdditionalInfo = () => {
  const { poolId, dispositionId } = useParams();
  const history = useHistory();
  const [jobCategory, setJobCategory] = useState("");
  const [poolInfo, setPoolInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [salaryValues, setSalaryValues] = useState({ min: "", max: "" });
  const [visaValue, setVisaValue] = useState("");

  useEffect(() => {
    getPoolById();
  }, []);

  const getPoolById = async () => {
    const params = { poolId: poolId };
    setLoading(true);
    let res = await post("/talent/getPoolInfo", params);
    let jobCategory = JSON.parse(res.data[0].industry);
    setLoading(false);
    setJobCategory(jobCategory[0]);
    let communityData = res.data[0];
    setPoolInfo(res.data[0]);
    if (communityData?.additionalInfo) {
      const data = JSON.parse(communityData?.additionalInfo).map((x) => ({
        ...x,
        name: x.name,
        question: x?.question.replace("[X]", x?.selectedOption),
        answer: "",
      }));
      setAdditionalInfo(data);
      const handleResize = () => {
        setIsMobile(window.innerWidth < 1000);
      };
      handleResize(); // Set initial value
      window.addEventListener("resize", handleResize); // Add event listener
      return () => window.removeEventListener("resize", handleResize);
    }
  };

  const handleAnswerChange = (index, answer, item) => {
    if (item?.name === "Salary Requirements") {
      const [minValue, maxValue] = answer.split(",");
      setSalaryValues({ ...salaryValues, min: minValue, max: maxValue });
    }
    const newAnswers = [...additionalInfo];
    newAnswers[index]["answer"] = answer;
    setAdditionalInfo(newAnswers);
  };

  const validationHandler = () => {
    let formIsValid = true;
    if (additionalInfo.some((x) => x.answer === "")) {
      formIsValid = false;
      toast.error("Please answer all additional info questions");
    } else if (
      additionalInfo.some(
        (x) =>
          x.name === "Salary Requirements" &&
          !x.answer.split(",")[0] &&
          !x.answer.split(",")[1]
      )
    ) {
      formIsValid = false;
      toast.error("Please answer all additional info questions");
    }
    if (
      additionalInfo.some(
        (x) =>
          x.name === "Salary Requirements" &&
          !x.answer.split(",")[0] &&
          x.answer.split(",")[1]
      )
    ) {
      formIsValid = false;
      toast.error("Please enter minimum salary");
    }
    if (
      additionalInfo.some(
        (x) =>
          x.name === "Salary Requirements" &&
          x.answer.split(",")[0] &&
          !x.answer.split(",")[1]
      )
    ) {
      formIsValid = false;
      toast.error("Please enter maximum salary");
    }
    if (parseInt(salaryValues.min) >= parseInt(salaryValues.max)) {
      formIsValid = false;
      toast.error("Minimum value must be smaller than maximum value.");
    }
    let resultObj = {
      isValid: formIsValid,
    };
    return resultObj;
  };

  const handleSubmit = async (e) => {
    const validObj = validationHandler();
    if (validObj.isValid) {
      try {
        setLoading(true);
        const payload = {
          id: dispositionId,
          additionalInfo: JSON.stringify(additionalInfo),
        };
        await post("/common/updateadditionalinfo", payload);
        toast.success("Updated successfully");
        setLoading(false);
        setTimeout(() => {
          history.push(`/career/home`);
        }, 1000);
      } catch (error) {
        setLoading(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    }
  };

  const handleVisaStatusChange = (index, value) => {
    setVisaValue(value);
    const newAnswers = [...additionalInfo];
    if (value === "Other") {
      newAnswers[index]["answer"] = ""; // Clear the answer
    } else {
      newAnswers[index]["answer"] = value; // Set the answer to the selected option
    }
    setAdditionalInfo(newAnswers);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="d-flex justify-content-center align-items-center vh-200">
        <div className="section section-sm pt-0" style={{ width: "100%" }}>
          <div className="ml-3 my-2">
            <img
              className="ml-2 my-auto"
              style={{ height: "40px", width: "75px" }}
              src={logo}
              alt="logo"
            />
          </div>
          <div className="hero-container">
            {jobCategory === "Healthcare" ? (
              <div className="hero-overlay">
                <img src={HealthCare2} alt="HealthCare2" />
                <img src={HealthCare3} alt="HealthCare3" />
                <img src={HealthCare1} alt="HealthCare1" />

                <div className="section pt-7 hero-content">
                  <div className="col-12">
                    <div className="mb-5">
                      <h5 className=" font-22 font-white">
                        Welcome To {poolInfo.poolname} talent community!
                      </h5>
                      <p className="font-14 font-white">
                        We're passionate about connecting healthcare
                        professionals with incredible opportunities!
                      </p>
                      <p className="font-14 font-white">
                        Sign up for our talent community to receive updates on
                        job opportunities, news, and recruiting events at High5.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="hero-overlay">
                <img src={high5people} alt="high5people" />
                <div className="section pt-7 hero-content">
                  <div className="row">
                    <div className="col-12">
                      <div className=" mb-5">
                        <h3 className="font-22 font-white">
                          Welcome To {poolInfo?.poolname} talent community!
                        </h3>
                        <p className="font-14 font-white">
                          We're passionate about connecting professionals with
                          incredible opportunities!
                        </p>
                        <p className="font-14 font-white">
                          Sign up for our talent community to receive updates on
                          job opportunities, news, and recruiting events at
                          High5.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div
              style={{
                margin: "10px 100px",
                marginTop: `${
                  jobCategory === "Healthcare" ? "-75px" : "-75px"
                }`,
                zIndex: "3",
              }}
            >
              <div className="card card-lg">
                <div className="card-body pb-0">
                  <hr />

                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <div className="card-body">
                          <h6>Update Addition information</h6>
                          <div className=" mt-5">
                            <div
                              className="questions"
                              style={{
                                display: "grid",
                                gap: "20px",
                                gridTemplateColumns: "1fr",
                                gridTemplateRows: isMobile ? "auto" : "1fr",
                              }}
                            >
                              {additionalInfo.map((question, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: "grid",
                                    gridTemplateColumns: isMobile
                                      ? "1fr"
                                      : "auto auto",
                                    gap: "10px",
                                    alignItems: "center",
                                  }}
                                >
                                  <p
                                    style={{
                                      marginBottom: isMobile ? "10px" : "0",
                                    }}
                                  >
                                    <span className="mr-2">{index + 1}.</span>{" "}
                                    {question.question}
                                  </p>
                                  {question?.input?.length > 0 &&
                                    question?.name !== "Visa Status" && (
                                      <div className=" d-flex  justify-content-end   w-100">
                                        <select
                                          className="form-select"
                                          value={
                                            additionalInfo[index]["answer"]
                                          }
                                          onChange={(e) =>
                                            handleAnswerChange(
                                              index,
                                              e.target.value,
                                              question
                                            )
                                          }
                                          style={{
                                            width: isMobile ? "100%" : "300px",
                                          }}
                                        >
                                          <option value="">Select</option>
                                          {question?.input?.map((e, index) => (
                                            <option key={index}>{e}</option>
                                          ))}
                                        </select>
                                      </div>
                                    )}
                                  {question?.name === "Salary Requirements" && (
                                    <div className=" d-flex  justify-content-end   w-100">
                                      <div>
                                        <input
                                          className="form-control mr-3"
                                          type="number"
                                          value={
                                            additionalInfo[index][
                                              "answer"
                                            ].split(",")[0]
                                          } // Get the first part (min value)
                                          placeholder="min"
                                          onChange={(e) => {
                                            const newAnswer = `${
                                              e.target.value
                                            },${additionalInfo[index][
                                              "answer"
                                            ].split(",")[1] || ""}`;
                                            handleAnswerChange(
                                              index,
                                              newAnswer,
                                              question
                                            );
                                          }}
                                          style={{
                                            width: isMobile ? "100%" : "140px",
                                          }}
                                        />
                                      </div>

                                      <div>
                                        <input
                                          className="form-control"
                                          type="number"
                                          value={
                                            additionalInfo[index][
                                              "answer"
                                            ].split(",")[1]
                                          } // Get the second part (max value)
                                          placeholder="max"
                                          onChange={(e) => {
                                            const newAnswer = `${additionalInfo[
                                              index
                                            ]["answer"].split(",")[0] || ""},${
                                              e.target.value
                                            }`;
                                            handleAnswerChange(
                                              index,
                                              newAnswer,
                                              question
                                            );
                                          }}
                                          style={{
                                            width: isMobile ? "100%" : "140px",
                                          }}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {question?.name === "Visa Status" && (
                                    <div className="d-flex justify-content-end w-100">
                                      <select
                                        className="form-select"
                                        value={visaValue}
                                        onChange={(e) =>
                                          handleVisaStatusChange(
                                            index,
                                            e.target.value
                                          )
                                        }
                                        style={{
                                          width: isMobile ? "100%" : "300px",
                                        }}
                                      >
                                        <option value="">Select</option>
                                        {question?.input?.map((e, index) => (
                                          <option key={index}>{e}</option>
                                        ))}
                                      </select>
                                      {visaValue == "Other" && (
                                        <input
                                          className="form-control ml-3"
                                          type="text"
                                          value={
                                            additionalInfo[index]["answer"]
                                          }
                                          placeholder="Enter visa"
                                          onChange={(e) =>
                                            handleAnswerChange(
                                              index,
                                              e.target.value,
                                              question
                                            )
                                          }
                                          style={{
                                            width: isMobile ? "100%" : "300px",
                                          }}
                                        />
                                      )}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="modal-footer pl-0">
                    <button
                      className="btn btn-primary btn btn-lg mb-3"
                      onClick={(e) => handleSubmit(e)}
                    >
                      <strong style={{ fontSize: "bolder" }}>Update</strong>
                    </button>
                    <div className="row mx-auto " style={{ width: "100%" }}>
                      <div className="col-md-7">
                        <footer>
                          <div>
                            <span className="text-secondary">Powered by</span>{" "}
                            <a
                              href="https://www.high5hire.com/"
                              target="_blank"
                            >
                              <img
                                style={{
                                  width: 40,
                                  height: 35,
                                  paddingBottom: 10,
                                }}
                                src={high5Logo}
                              />
                            </a>
                          </div>
                        </footer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateAdditionalInfo;
