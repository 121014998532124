import React, { useEffect, useState } from "react";
import JobCard from "./JobCard";
import JobSidePanel from "./JobSidePanel";
import OutsideClickHandler from "react-outside-click-handler";
import Sort from "../../../common/Sort";
import Filter from "../ToDoCard/Filter";

const MyJobsCard = ({ candidateData, favJobs, matchedJobs }) => {
  const [tabType, setTabType] = useState({
    matched: true,
    favourite: false,
    applied: false,
  });
  const [sort, setSort] = useState(false);
  const [sortArray, setSortArray] = useState([]);
  const [sortCount, setSortCount] = useState(0);
  // FIlter
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [requestStatus, setRequestStatus] = useState([]);
  const [requestTenant, setRequestTenant] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState([]);
  const [requestJobDate, setRequestJobDate] = useState([]);
  const [selectedJobDate, setSelectedJobDate] = useState([]);
  const [requestJobLocation, setRequestJobLocation] = useState([]);
  const [selectedJobLocation, setSelectedJobLocation] = useState([]);
  const [applied, setApplied] = useState([]);
  const [favourites, setFavourites] = useState([]);
  const [filterCount, setFilterCount] = useState(0);

  const [isJobCard, setIsJobCard] = useState(false);
  const sortObject = [
    // {
    //   id: 1,
    //   label: "Job Status",
    //   value: "statusname",
    //   icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    // },
    {
      id: 1,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Client",
      value: "jobTenant",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
  ];
  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  useEffect(() => {
    setFavourites(favJobs);
  }, [favJobs]);

  const applySort = (sortedData, close) => {
    close && setSort(false);
    setApplied(sortedData);
    // setFilteredData(sortedData);
  };
  const applySort2 = (sortedData, close) => {
    close && setSort(false);
    setFavourites(sortedData);
    // setFilteredData(sortedData);
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(filteredData, true);
  };

  const applyFilter = (
    filteredData,
    selectedTitles,
    selectedTypes,
    selectedTenant,
    selectedJobDate,
    selectedJobLocation,
    daysLeft,
    daysLeftValue,
    filteredData2
  ) => {
    setFilterCount(
      selectedTitles.length +
        selectedTypes.length +
        selectedTenant.length +
        selectedJobDate.length +
        selectedJobLocation.length
    );
    // sortClearAll();
    setSelectedTitles(selectedTitles);
    setSelectedTypes(selectedTypes);
    setSelectedTenant(selectedTenant);
    setSelectedJobDate(selectedJobDate);
    setSelectedJobLocation(selectedJobLocation);
    setFavourites(
      selectedTitles.length +
        selectedTypes.length +
        selectedTenant.length +
        selectedJobDate.length +
        selectedJobLocation.length >
        0
        ? filteredData2
        : favJobs
    );
    // setDaysLeft(daysLeft);
    // setDaysLeftValue(daysLeftValue);
    // setFilterBy(filteredData);

    setApplied(
      selectedTitles.length +
        selectedTypes.length +
        selectedTenant.length +
        selectedJobDate.length +
        selectedJobLocation.length >
        0
        ? filteredData
        : candidateData?.dispositionData?.filter(
            (f) => f.statusname == "Submitted"
          )
    );

    setFilterData(filteredData);
    setFilter(false);
  };
  const onChangeClick = (type) => {
    if (type == "matched") {
      setTabType({
        matched: true,
        favourite: false,
        applied: false,
      });
    }
    if (type == "favourite") {
      setTabType({
        matched: false,
        favourite: true,
        applied: false,
      });
    }
    if (type == "applied") {
      setTabType({
        matched: false,
        favourite: false,
        applied: true,
      });
    }
  };
  useEffect(() => {
    let title = [];
    let type = [];
    let tenant = [];
    let addedon = [];
    let location = [];
    candidateData?.dispositionData.forEach((job) => {
      if (job.jobTitle && !title.includes(job.jobTitle)) {
        title.push(job.jobTitle);
      }
      if (job.jobType && !type.includes(job.jobType)) {
        type.push(job.jobType);
      }
      if (job.jobTenant && !tenant.includes(job.jobTenant)) {
        tenant.push(job.jobTenant);
      }
      // if (job.jobPostDate && !addedon.includes(job.jobPostDate)) {
      //   addedon.push(job.jobPostDate);
      // }
      if (job.workplaceType && !location.includes(job.workplaceType)) {
        location.push(job.workplaceType);
      }
      setRequestTitles(title);
      setRequestStatus(type);
      setRequestTenant(tenant);
      // setRequestJobDate(addedon);
      setRequestJobLocation(location);
    });
  }, [candidateData]);
  useEffect(() => {
    setApplied(
      candidateData?.dispositionData?.filter((f) => f.statusname == "Submitted")
    );
  }, [candidateData]);

  return (
    <div className="col-xxl-5 mb-3">
      <div className="card ">
        <div className="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <label class="pt-0 mb-2">
                <strong>My Jobs</strong>
              </label>{" "}
              {/* <a href="/candidate/jobs">
                <i aria-hidden="true" class="fas fa-external-link-square"></i>
              </a> */}
            </div>
            <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
              <div class="mb-2 d-flex align-items-center justify-content-center">
                <div class="d-flex justify-content-end w-100">
                  <div class="dropdown-sort dd-sort">
                    <button
                      type="button"
                      class="btn btn-sm w-auto btn-text-accent dd-sort"
                      onClick={() => setSort(true)}
                    >
                      <i
                        aria-hidden="true"
                        class="fad fa-sort-down font-16 dd-sort"
                      ></i>{" "}
                      <span class="dd-sort d-none-1024">Sort</span> /
                      <span class="dd-sort"> {sortCount}</span>
                    </button>{" "}
                    {
                      <OutsideClickHandler
                        onOutsideClick={() => setSort(false)}
                      >
                        {sort && (
                          <Sort
                            sortData={candidateData?.dispositionData?.filter(
                              (e) => e.statusname == "Submitted"
                            )}
                            orderArray={orderList}
                            sortObjects={sortObject}
                            applySort={applySort}
                            setSortCount={setSortCount}
                            sortCount={sortCount}
                            setSortArray={setSortArray}
                            sortArray={sortArray}
                            sortingFor={"Sort by Job Title, Job Client"}
                          />
                        )}
                        {sort && (
                          <Sort
                            isFrom="candidateprofile"
                            sortData={favJobs}
                            orderArray={orderList}
                            sortObjects={sortObject}
                            applySort={applySort2}
                            setSortCount={setSortCount}
                            sortCount={sortCount}
                            setSortArray={setSortArray}
                            sortArray={sortArray}
                            sortingFor={"Sort by Job Title, Job Client"}
                          />
                        )}
                      </OutsideClickHandler>
                    }
                  </div>{" "}
                  <div class="dropdown-sort dd-filter mx-1">
                    <button
                      type="button"
                      class="btn btn-sm w-auto btn-text-accent dd-filter"
                      onClick={() => setFilter(true)}
                    >
                      <i
                        aria-hidden="true"
                        class="fas fa-filter font-14 dd-filter"
                      ></i>{" "}
                      <span class="dd-filter d-none-1024">Filter</span> /
                      <span class="dd-filter"> {filterCount}</span>
                    </button>
                    {candidateData !== null &&
                      Object.keys(candidateData)?.length > 0 &&
                      filter && (
                        <OutsideClickHandler
                          onOutsideClick={() => setFilter(false)}
                        >
                          {/* 30 red   70 orange 70 green */}
                          <Filter
                            requestTitles={requestTitles}
                            requestStatus={requestStatus}
                            requestTenant={requestTenant}
                            // requestJobDate={requestJobDate}
                            requestJobLocation={requestJobLocation}
                            selectedTitles={selectedTitles}
                            selectedTypes={selectedTypes}
                            selectedTenant={selectedTenant}
                            selectedJobDate={selectedJobDate}
                            selectedJobLocation={selectedJobLocation}
                            applyFilter={applyFilter}
                            // daysLeft={daysLeft}
                            // daysLeftValue={daysLeftValue}
                            // jobData={jobListData}
                            setJobData={applied}
                            setRequestStatus={setRequestStatus}
                            setRequestTenant={setRequestTenant}
                            jobData={candidateData?.dispositionData?.filter(
                              (f) => f.statusname == "Submitted"
                            )}
                            jobData2={favJobs}
                            isFrom="myjobs"
                          />
                        </OutsideClickHandler>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="m-0"></hr>

          <div class="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end mb-3 border-bottom-gray2">
            <ul class="nav nav-tabs nav-tabs-tablet pt-1 w-100 justify-content-center">
              <li class="nav-item">
                <button
                  type="button"
                  class={
                    tabType.matched
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("matched")}
                >
                  <span class="tag mb-1">
                    {matchedJobs?.length}
                    {/* <span class="jewel"></span> */}
                  </span>{" "}
                  <div>Matched</div>
                </button>
              </li>{" "}
              <li class="nav-item">
                <button
                  type="button"
                  class={
                    tabType.favourite
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("favourite")}
                >
                  <span class="tag mb-1">
                    {favourites?.length == undefined ? 0 : favourites?.length}
                  </span>{" "}
                  <div>Favorited</div>
                </button>
              </li>{" "}
              {/* <li class="nav-item">
                <button type="button" class="nav-link pb-3 mx-2 bg-transparent">
                  <span class="tag mb-1">0</span> <div>RTR</div>
                </button>
              </li>{" "} */}
              <li class="nav-item">
                <button
                  type="button"
                  class={
                    tabType.applied
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("applied")}
                >
                  <span class="tag mb-1">
                    {applied == undefined ? 0 : applied?.length}
                  </span>{" "}
                  <div>Applied</div>
                </button>
              </li>
            </ul>
          </div>

          <div className="col">
            {tabType?.applied && (
              <JobCard
                job={applied}
                isJobCard={isJobCard}
                setIsJobCard={setIsJobCard}
                isFrom="applied"
              />
            )}
            {tabType.favourite && (
              <JobCard
                job={favourites}
                isJobCard={isJobCard}
                setIsJobCard={setIsJobCard}
                isFrom="favJobs"
              />
            )}
            {tabType.matched && (
              <JobCard
                job={matchedJobs}
                isFrom="matchedJobs"
                isJobCard={isJobCard}
                setIsJobCard={setIsJobCard}
              />
            )

            // <a
            //   href=""
            //   class="card card-flat p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
            //   style={{ pointerEvents: "none" }}
            // >
            //   <div class="card-body">
            //     {" "}
            //     <span class="tag tag-coral d-inline-flex mr-2">

            //       <span class="mx-2">Matched</span>
            //     </span>{" "}
            //     <h6 class="mt-1">Junior Designer</h6>{" "}
            //     <div class="d-flex flex-wrap align-items-center">
            //       <span class="d-flex align-items-center">
            //         <i
            //           aria-hidden="true"
            //           class="far fa-suitcase me-2 font-brandBlue"
            //         ></i>{" "}
            //         <span class="font-regular font-primary font-gray1">
            //           Contract
            //         </span>
            //       </span>{" "}
            //       <span class="d-flex align-items-center ml-3">
            //         <i
            //           aria-hidden="true"
            //           class="far fa-map-marker-alt me-2 font-brandBlue"
            //         ></i>{" "}
            //         <span class="font-regular font-primary font-gray1">
            //           Jacksonville FL.
            //         </span>
            //       </span>{" "}
            //       <span class="d-flex align-items-center ml-3">
            //         <i
            //           aria-hidden="true"
            //           class="far fa-piggy-bank me-2 font-brandBlue"
            //         ></i>{" "}
            //         <span class="font-regular font-primary font-gray1">
            //           {Number(20000).toLocaleString() +
            //             " - " +
            //             Number(23000).toLocaleString() +
            //             " " +
            //             "USD"}
            //         </span>
            //       </span>
            //     </div>
            //     <div>
            //       <span class="d-flex align-items-center mt-2">
            //         <span class="font-regular">
            //           2 days ago <span></span>
            //         </span>
            //       </span>
            //     </div>
            //   </div>
            // </a>
            // <a
            //   href=""
            //   class="card card-flat p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
            //   style={{ pointerEvents: "none" }}
            // >
            //   <div class="card-body">
            //     <span class="tag tag-coral d-inline-flex mr-2">

            //       <span class="mx-2">Matched</span>
            //     </span>{" "}
            //     <h6 class="mt-1">Frontend Developer</h6>{" "}
            //     <div class="d-flex flex-wrap align-items-center">
            //       <span class="d-flex align-items-center">
            //         <i
            //           aria-hidden="true"
            //           class="far fa-suitcase me-2 font-brandBlue"
            //         ></i>{" "}
            //         <span class="font-regular font-primary font-gray1">
            //           Contract
            //         </span>
            //       </span>{" "}
            //       <span class="d-flex align-items-center ml-3">
            //         <i
            //           aria-hidden="true"
            //           class="far fa-map-marker-alt me-2 font-brandBlue"
            //         ></i>{" "}
            //         <span class="font-regular font-primary font-gray1">
            //           Jacksonville FL.
            //         </span>
            //       </span>{" "}
            //       <span class="d-flex align-items-center ml-3">
            //         <i
            //           aria-hidden="true"
            //           class="far fa-piggy-bank me-2 font-brandBlue"
            //         ></i>{" "}
            //         <span class="font-regular font-primary font-gray1">
            //           {Number(15000).toLocaleString() +
            //             " - " +
            //             Number(20000).toLocaleString() +
            //             " " +
            //             "USD"}
            //         </span>
            //       </span>
            //     </div>{" "}
            //     <div>
            //       <span class="d-flex align-items-center mt-2">
            //         <span class="font-regular">
            //           2 days ago <span></span>
            //         </span>
            //       </span>
            //     </div>
            //   </div>
            // </a>
            }
            {tabType.matched && matchedJobs.length == 0 && (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-comment-slash"></i>
                  </div>
                  <div className="mt-2">No Record Found</div>
                </div>
              </div>
            )}
            {tabType.favourite && favourites.length == 0 && (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-comment-slash"></i>
                  </div>
                  <div className="mt-2">No Record Found</div>
                </div>
              </div>
            )}
            {tabType.applied && applied.length == 0 && (
              <div>
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i className="fad fa-comment-slash"></i>
                  </div>
                  <div className="mt-2">No Record Found</div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyJobsCard;
