import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import SideNavBar from "../../../layout/SideNavBar";
import ReportTopNavBar from "../../../report/ReportTopNavBar";
import Filter from "./Filter";
import ReportHeader from "./ReportHeader";
import ReportTable from "./ReportTable";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import { countryObj } from "../../../../constants/Constants";
import TopNavBar from "../../../layout/TopNavBar";
import { getTenantType } from "../../../../service/AuthService";
import Footer from "../../../layout/Footer";

function Index() {
  const reportHeaders = [
    { id: 0, name: "S.No" },
    { id: 1, name: "Job Title" },
    { id: 2, name: "Job status" },
    { id: 4, name: "Client" },
    { id: 5, name: "Added on" },
    { id: 6, name: "Job Location" },
  ];
  const [pageLimit, setPageLimit] = useState(3);
  const [toggle, setToggle] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [columns, setColumns] = useState([]);
  const [dateSearch, setDateSearch] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [daysLeft, setDaysLeft] = useState("");
  const [daysLeftValue, setDaysLeftValue] = useState("");
  const [toDate, setToDate] = useState(new Date());
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [requestStatus, setRequestStatus] = useState([]);
  const [requestTenant, setRequestTenant] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState([]);
  const [requestJobDate, setRequestJobDate] = useState([]);
  const [selectedJobDate, setSelectedJobDate] = useState([]);
  const [requestJobLocation, setRequestJobLocation] = useState([]);
  const [selectedJobLocation, setSelectedJobLocation] = useState([]);
  const [vetting, setVetting] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [jobListData, setJobListData] = useState([]);
  const [primarySkills, setPrimarySkills] = useState([]);
  const [candidateLocations, setCandidateLocations] = useState([]);
  const [candidateTitles, setCandidateTitles] = useState([]);
  const [candidateSkills, setCandidateSkills] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [gigRecruiters, setGigRecruiters] = useState([]);
  const [candidateDataLoading, setCandidateDataLoading] = useState(false);
  const [jobData, setJobData] = useState([]);
  const [invitedCandidates, setInvitedCandidates] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [filterBy, setFilterBy] = useState(reportData);
  const [dataa, setDataa] = useState([]);

  const sortObject = [
    {
      id: 1,
      label: "Job Status",
      value: "statusname",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Job Client",
      value: "tenantname",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 4,
      label: "Added on",
      value: "addedOn",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
  ];
  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];
  const toggleDiv = (type) => {
    setToggle(!toggle);
  };

  const fetchJobData = async () => {
    let params = {
      toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
      fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
    };
    setLoading(true);
    try {
      let res = await post("/reports/job", params);
      if (res.status === 200) {
        if (res.data.length == 0) {
          toast.error("No record found. Please select another date range");
        }
        setReportData(res.data);
        setLoading(false);
        let title = [];
        let type = [];
        let tenant = [];
        let addedon = [];
        let location = [];
        let filterJobs = res.data.jobData;
        let result = res.data;
        result.forEach((job) => {
          if (job.jobTitle && !title.includes(job.jobTitle)) {
            title.push(job.jobTitle);
          }
          if (job.jobStatus && !type.includes(job.jobStatus)) {
            type.push(job.jobStatus);
          }
          if (job.tenantname && !tenant.includes(job.tenantname)) {
            tenant.push(job.tenantname);
          }
          if (job.jobPostDate && !addedon.includes(job.jobPostDate)) {
            addedon.push(job.jobPostDate);
          }
          job.isRemote
            ? location.push("Remote")
            : location.push(`${job.city}, ${job.state}, ${job.country}`);
        });
        setJobListData(result);
        setFilterBy(result);
        setFilterData(result);
        setRequestTitles(title);
        setRequestStatus(type);
        setRequestTenant(tenant);
        setRequestJobDate(addedon);
        setRequestJobLocation(location);
        setFilteredData(result);
        setDataa(result);

        setPaginationArray(
          new Array(Math.ceil(result.length / perPage))
            .fill()
            .map((_, i) => i + 1)
        );
        setPaginationGroup(
          new Array(Math.ceil(result.length / perPage))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again");
    }
  };
  useEffect(() => {
    fetchJobData();
  }, []);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);
  //! Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }
  const handleDateSearch = () => {
    setSelectedLocations([]);
    setSelectedTitles([]);
    setPrimarySkills([]);
    setVetting(false);
    //setMounted(true)
    let startDate = moment(new Date(fromDate)).format("MM/DD/YYYY");
    let endDate = moment(new Date(toDate)).format("MM/DD/YYYY");
    if (new Date(startDate) > new Date()) {
      toast.error(`'From Date' cannot be future date`);
    } else if (new Date(endDate) > new Date()) {
      toast.error(`'To Date' cannot be future date`);
    } else if (new Date(startDate) > new Date(endDate)) {
      toast.error(`'From Date' should not be greater than 'To Date'`);
    } else {
      setCurrentPage(1);
      fetchJobData();
      let result = [];
      let title = [];
      let location = [];
      let skills = [];
      result.forEach((item) => {
        if (item.JobTitle) {
          if (!title.includes(item.JobTitle)) {
            title.push(item.JobTitle.trim());
          }
        }
      });
      setCandidateTitles(title);
      setCandidateSkills([...new Set(skills)]);
      setCandidateLocations(location);
      setDateSearch(result);
      setFilteredData(result);
      setPaginationArray(
        new Array(Math.ceil(result.length / perPage))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(result.length / perPage))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageLimit)
      );
    }
  };
  const onChangeSelect = (e) => {
    if (e.target.value === "All") {
      setPerPage(filteredData.length);
      setPaginationArray([1]);
      setPaginationGroup([1]);
      setCurrentPage(1);
    } else {
      const pageGroup = new Array(
        Math.ceil(filteredData.length / e.target.value)
      )
        .fill()
        .map((_, i) => i + 1);
      setPaginationArray(pageGroup);
      setPaginationGroup(pageGroup.slice(0, pageLimit));
      setPerPage(Number(e.target.value));
      setCurrentPage(1);
    }
  };
  const getPaginatedData = () => {
    const startIndex = currentPage * perPage - perPage;
    const endIndex = startIndex + +perPage;
    return filteredData.slice(startIndex, endIndex);
  };
  const applySort = (sortedData, close) => {
    close && setSort(false);
    let data = sortedData?.length === 0 ? dataa : sortedData;
    setFilterBy(data);
    setFilteredData(data);
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(filteredData, true);
  };

  const openFilter = () => {
    setFilter(true);
  };

  const applyFilter = (
    filteredData,
    selectedTitles,
    selectedTypes,
    selectedTenant,
    selectedJobDate,
    selectedJobLocation,
    daysLeft,
    daysLeftValue
  ) => {
    sortClearAll();
    setSelectedTitles(selectedTitles);
    setSelectedTypes(selectedTypes);
    setSelectedTenant(selectedTenant);
    setSelectedJobDate(selectedJobDate);
    setSelectedJobLocation(selectedJobLocation);
    setDaysLeft(daysLeft);
    setDaysLeftValue(daysLeftValue);
    setFilterBy(filteredData);
    setFilterData(filteredData);
    setFilter(false);
  };

  return (
    <div>
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar isFrom={"Report"} handleToggale={toggleDiv} />
        {/* <ReportTopNavBar handleToggale={toggleDiv} /> */}
        <SideNavBar handleToggale={toggleDiv} />
        <div id="Content">
          <div className="section section-s">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="card card-flat card-borderless p-4 mb-3">
                    <ReportHeader
                      dataa={dataa}
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      toDate={toDate}
                      setToDate={setToDate}
                      handleDateSearch={handleDateSearch}
                      setSort={setSort}
                      sort={sort}
                      filteredData={filteredData}
                      sortCount={sortCount}
                      orderList={orderList}
                      sortObject={sortObject}
                      applySort={applySort}
                      setSortCount={setSortCount}
                      setSortArray={setSortArray}
                      openFilter={openFilter}
                      onChangeSelect={onChangeSelect}
                      sortArray={sortArray}
                      reportData={reportData}
                      selectedTitles={selectedTitles}
                      selectedTypes={selectedTypes}
                      selectedTenant={selectedTenant}
                      selectedJobDate={selectedJobDate}
                      selectedJobLocation={selectedJobLocation}
                    />
                    {filteredData.length > 0 ? (
                      <ReportTable
                        getPaginatedData={getPaginatedData}
                        reportHeaders={reportHeaders}
                        currentPage={currentPage}
                        perPage={perPage}
                      />
                    ) : (
                      <NoRecord />
                    )}
                    {getPaginatedData().length > 0 && (
                      <div className="d-flex justify-content-end pt-4 px-1">
                        <div className="w-100-370 mb-point5-370">
                          Showing{" "}
                          {(getPaginatedData().length === perPage
                            ? perPage * currentPage
                            : perPage * (currentPage - 1) +
                              getPaginatedData().length) + " "}
                          of {filteredData?.length}
                        </div>
                        <div
                          className="ml-auto mx-auto-370 d-flex align-items-center"
                          // style={{ position: 'absolute', left: '85%' }}
                        >
                          <button
                            onClick={() => {
                              setPageType("down");
                              setCurrentPage((page) => page - 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === 1 ||
                              getPaginatedData().length === 0
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-left"></i>
                          </button>
                          {getPaginatedData().length > 0
                            ? paginationGroup.map((item) => (
                                <button
                                  type="button"
                                  className={
                                    currentPage === item
                                      ? "btn btn-text px-2 bg-dark text-white"
                                      : "btn btn-text px-2"
                                  }
                                  onClick={changePage}
                                >
                                  {item}
                                </button>
                              ))
                            : ""}
                          <button
                            onClick={() => {
                              setPageType("up");
                              setCurrentPage((page) => page + 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === paginationArray.slice(-1)[0] ||
                              getPaginatedData().length === 0
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-right"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
      {filter && (
        <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
          <Filter
            requestTitles={requestTitles}
            requestStatus={requestStatus}
            requestTenant={requestTenant}
            requestJobDate={requestJobDate}
            requestJobLocation={requestJobLocation}
            selectedTitles={selectedTitles}
            selectedTypes={selectedTypes}
            selectedTenant={selectedTenant}
            selectedJobDate={selectedJobDate}
            selectedJobLocation={selectedJobLocation}
            applyFilter={applyFilter}
            daysLeft={daysLeft}
            daysLeftValue={daysLeftValue}
            jobData={jobListData}
            setJobData={setFilteredData}
            setRequestStatus={setRequestStatus}
            setRequestTenant={setRequestTenant}
          />
        </OutsideClickHandler>
      )}
    </div>
  );
}
export default Index;
