import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import Supplier from "../../common/supplier/Supplier";
import { domain } from "../../../constants/Constants";
import { post } from "../../../service/ApiService";
import { getUserTenant } from "../../../service/AuthService";
import { getUserTenantID } from "../../../service/AuthService";
import { getUserId } from "../../../service/AuthService";
import Autosuggest from "react-autosuggest";
import AddSupplier from "../../modal/tenant/AddSuppliersPopUp";
import AddPreferredSupplier from "../../modal/tenant/AddPreferredSupplierPopUp";
import TierTable from "../../modal/tenant/TierTable";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import { upsert } from "../../function/CommonFunction";

function SupplierTable(props) {
  const { addJobData } = props;
  const [openAddSupplier, setOpenAddSupplier] = useState(false);
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tierDropdown, setTierDropdown] = useState([]);
  const [suggetionSuppliers, setSuggetionSuppliers] = useState([]);
  const [openAddPreferredSupplier, setOpenAddPreferredSupplier] = useState(
    false
  );
  const [supplierList, setSupplierList] = useState([]);
  const [openTierTable, setOpenTierTable] = useState(false);
  const [rows, setRows] = useState([]);
  const [isPublic, setIsPublic] = useState(false);
  const [indexVal, setIndexVal] = useState(null);
  const [selectedSupplier, setselectedSupplier] = useState([]);
  const [tierData, setTierData] = useState({
    tier1Data: [],
    tier2Data: [],
    tier3Data: [],

    tierValue1: "",
    tierValue2: "",
    tierValue3: "",

    tier1Tag: "",
    tier2Tag: "",
    tier3Tag: "",

    tier1Duration: "",
    tier2Duration: "",
    tier3Duration: "",
  });

  const getsuggconvertedval = (data) => {
    const suggessiondata = data.map(
      ({ tenantname, Name, primarySkills, countryList, userId }) => {
        const sk = JSON.parse(primarySkills);
        const cl = JSON.parse(countryList);
        const skillList = sk.map((skill) => {
          return skill;
        });
        const CountryList = cl.map((country) => {
          return country.label;
        });
        const CountryvalueList = cl.map((country) => {
          return country.value;
        });
        const container = {};
        container["tenantname"] = tenantname;
        container["Name"] = Name;
        container["primarySkills"] = skillList;
        container["countryList"] = CountryList;
        container["userId"] = userId;

        return `${tenantname} ~~ ${Name} ~~ ${skillList} ~~ ${CountryvalueList}  ~~ ${userId}`;
        //   return container;
      }
    );
    return suggessiondata;
  };

  const publicTierfun = (e) => {
    setIsPublic(e.target.checked);
  };

  const addRow = (e) => {
    e.preventDefault();
    if (rows.length < 3) {
      setRows([...rows, 0]);
    } else {
      toast.error("You can add up to 3 Tiers");
    }
  };

  const deleteRow = (i, index) => {
    i.preventDefault();
    setRows([...rows.slice(0, rows.length - 1)]);
    switch (index) {
      case 0: {
        setTierData((prevState) => ({
          ...prevState,
          tier1Data: [],
          tierValue1: [],
          tier1Duration: "",
        }));
        //     toast.success("Deleted First Tier Successfully")
        break;
      }
      case 1: {
        //statements;
        setTierData((prevState) => ({
          ...prevState,
          tier2Data: [],
          tierValue2: [],
          tier2Duration: "",
        }));
        break;
      }
      case 2: {
        //statements;
        setTierData((prevState) => ({
          ...prevState,
          tier3Data: [],
          tierValue3: [],
          tier3Duration: "",
        }));
        //  toast.success("Deleted Third Tier Successfully")
        break;
      }
      default: {
        break;
      }
    }
  };

  const inputChange = (i, index) => {
    setTags(i);
    i = i.map((val) => val.trim());
    const supplierData = supplierList.filter((record) => {
      return i.includes(record.tenantname.trim());
    });
    // update in commonselected list
    // selectedSupplier.includes(rec)
    const filtereddata = supplierList.filter(
      (record) => !i.includes(record.tenantname)
    );

    setselectedSupplier((prevData) => ({ ...prevData, filtereddata }));
    // set

    const suggresult = getsuggconvertedval(supplierList);
    setSuggetionSuppliers(suggresult);

    setselectedSupplier(supplierData);
    // set values on each tier
    switch (index) {
      case 0: {
        //tier1 data save;
        setTierData((prevState) => ({
          ...prevState,
          tier1Data: i,
          tierValue1: supplierData,
        }));
        break;
      }
      case 1: {
        //statements;
        setTierData((prevState) => ({
          ...prevState,
          tier2Data: i,
          tierValue2: supplierData,
        }));
        break;
      }
      case 2: {
        //statements;
        setTierData((prevState) => ({
          ...prevState,
          tier3Data: i,
          tierValue3: supplierData,
        }));
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  };

  const inputTag = (j, index) => {
    setTag(j);
    switch (index) {
      case 0: {
        //tier1 data save;
        setTierData((prevState) => ({
          ...prevState,
          tier1Tag: j,
        }));
        break;
      }
      case 1: {
        //statements;
        setTierData((prevState) => ({
          ...prevState,
          tier2Tag: j,
        }));
        break;
      }
      case 2: {
        //statements;
        setTierData((prevState) => ({
          ...prevState,
          tier3Tag: j,
        }));
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  };

  const tierChange = (i, index) => {
    setTags(i);

    // set values on each tier
    switch (index) {
      case 0: {
        //tier1 data save;
        //setTierData({...tierData, { ...tierData.tier1Data , tier1Data: i }})
        setTierData((prevState) => ({
          ...prevState,
          tier1Duration: i.target.value,
        }));
        break;
      }
      case 1: {
        //statements;
        setTierData((prevState) => ({
          ...prevState,
          tier2Duration: i.target.value,
        }));
        break;
      }
      case 2: {
        //statements;
        setTierData((prevState) => ({
          ...prevState,
          tier3Duration: i.target.value,
        }));
        break;
      }
      default: {
        //statements;
        break;
      }
    }
  };

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "65%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : suggetionSuppliers.filter((item) =>
              item.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => {
      const res = suggestion.split("~~");
      return (
        <div>
          {" "}
          <span class="mr-2 tag  font-bold tag-green1">{res[0]}</span>{" "}
          <span class="mr-2 tag font-bold   tag-purple1">{res[1]}</span>{" "}
        </div>
      );
    };
    ////console.log(props.value)
    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          const res = suggestion.split("~~");

          addTag(res[0]);
        }}
        theme={theme}
      />
    );
  };

  const getTierData = async () => {
    try {
      const tenantid = getUserTenantID();
      const parameters = {
        tenantid,
        domain,
      };
      const response = await post("/preferredSupplier/getTierData", parameters);
      const data = response.data;
      const temptier1 = [];
      const temptier2 = [];
      const temptier3 = [];

      let temptier1duration = "";
      let temptier2duration = "";
      let temptier3duration = "";
      const temptiervalue1 = [];
      const temptiervalue2 = [];
      const temptiervalue3 = [];
      let Publicstatus = false;
      data.map((eachdata) => {
        if (!Publicstatus && eachdata.tenantname === "Public") {
          // update public  status
          Publicstatus = true;
        } else {
          // Publicstatus = false
          switch (eachdata.tiername) {
            case "Tier1":
              temptier1.push(eachdata.tenantname);
              temptier1duration = eachdata.id_thresHoldtype;
              temptiervalue1.push(eachdata);
              break;

            case "Tier2":
              temptier2.push(eachdata.tenantname);
              temptier2duration = eachdata.id_thresHoldtype;
              temptiervalue2.push(eachdata);
              break;

            case "Tier3": {
              temptier3.push(eachdata.tenantname);
              temptier3duration = eachdata.id_thresHoldtype;
              temptiervalue3.push(eachdata);
              break;
            }
          }
        }
      });
      if (temptier3.length > 0) {
        //  setr(2)
        setRows(Array(3).fill(0));
      } else if (temptier2.length > 0) {
        setRows(Array(2).fill(0));
      } else if (temptier1.length > 0) {
        setRows(Array(1).fill(0));
      }

      setTierData({
        ...tierData,
        tier1Data: temptier1,
        tier2Data: temptier2,
        tier3Data: temptier3,
        tierValue1: temptiervalue1,
        tierValue2: temptiervalue2,
        tierValue3: temptiervalue3,
        tier1Duration: temptier1duration,
        tier2Duration: temptier2duration,
        tier3Duration: temptier3duration,
      });
      setIsPublic(Publicstatus);
    } catch (error) {}
  };

  const getDropdownData = async () => {
    try {
      setLoading(true)
      const response = await post(`/preferredSupplier/fetchTierDropDown`);
      const result = response.data[0];
      const listData = result.filter((row) => {
        return row.PType === "vendorthreshold";
      });
      setTierDropdown(listData);
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const tenantid = getUserTenantID();
      const paramters = {
        tenantid,
      };
      const response = await post(`/preferredSupplier/getTierList`, paramters);
      const data = response.data;
      setSupplierList(response.data);
      // filter suggession with autofilled data
      const suggresult = getsuggconvertedval(data);
      // ////console.log(data,'fdgd',suggessiondata);
      setSuggetionSuppliers(suggresult);
      setLoading(false);
    } catch (error) {
      ////console.log(error);
    }
    setLoading(false);
  };

  //TierList API
  useEffect(() => {
    if (addJobData.tierData && Object.keys(addJobData.tierData).length === 0) {
      getTierData();
    } else {
      tierDataExist()
    }
    getData();
    getDropdownData();
  }, []);

  const tierDataExist = () => {
    setTierData(addJobData.tierData);
    setIsPublic(addJobData.tierData.isTierPublic);
    if (addJobData.tierData.tier3Data.length > 0) {
      //  setr(2)
      setRows(Array(3).fill(0));
    } else if (addJobData.tierData.tier2Data.length > 0) {
      setRows(Array(2).fill(0));
    } else if (addJobData.tierData.tier1Data.length > 0) {
      setRows(Array(1).fill(0));
    }
  };

  useEffect(() => {
    const allNames = [
      ...new Set([
        ...tierData.tier1Data,
        ...tierData.tier2Data,
        ...tierData.tier3Data,
      ]),
    ];
    const filtereddata = supplierList.filter(
      (supplier) => !allNames.includes(supplier.tenantname)
    );
    const suggresult = getsuggconvertedval(filtereddata);
    setSuggetionSuppliers(suggresult);
    // set props tier value
    props.setTierData({
      ...tierData,
      tierLength: rows.length,
      isTierPublic: isPublic,
    });
  }, [tierData, rows, isPublic]);

  console.log(addJobData.tierData);

  return (
    <>
      {loading && <Loader />}
      <div className="card card-lg card-flat">
        <div className="card-body">
          <h6>Manage Tier</h6>
          <div className="supplier-tiers table-cards-1024">
            <table className="table">
              <thead>
                <tr>
                  <th>Tiers</th>
                  <th>Supplier</th>
                  <th>Duration</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => (
                  <tr>
                    <td style={{ width: "5%" }}>{index + 1}</td>
                    <td style={{ width: "65%" }}>
                      <TagsInput
                        renderInput={autocompleteRenderInput}
                        onChange={(i) => {
                          inputChange(i, index);
                        }}
                        value={tierData[`tier${index + 1}Data`]}
                        key={index}
                        inputValue={tierData[`tier${index + 1}Tag`]}
                        onChangeInput={(j) => {
                          inputTag(j, index);
                        }}
                        inputProps={{
                          placeholder: "Add a supplier",
                        }}
                      />
                    </td>

                    <td style={{ width: "25%", padding: ".5rem .2rem" }}>
                      <div className="d-flex">
                        <div />
                        <select
                          className="form-select"
                          onChange={(i) => {
                            tierChange(i, index);
                          }}
                          value={tierData[`tier${index + 1}Duration`]}
                        >
                          <option value="">Select Duration</option>
                          {tierDropdown.map((data) => (
                            <option
                              value={data.id_tenant}
                              label={data.PreferredSupplierName}
                            >
                              {data.PreferredSupplierName}
                            </option>
                          ))}
                        </select>
                      </div>
                    </td>
                    {rows.length === index + 1 && (
                      <td style={{ width: "5%" }}>
                        <button
                          onClick={(i) => {
                            deleteRow(i, index);
                          }}
                          type="button"
                          className="btn btn-icon"
                        >
                          <i className="fal fa-fw fa-trash-alt"></i>
                        </button>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="d-flex align-items-center mt-3 wrap-reverse-370 flex-column-370 justify-content-center-370 justify-content-between-400">
              <button
                type="button"
                onClick={addRow}
                className="btn btn-sm btn-text"
                disabled={rows.length === 3}
              >
                <i className="fal fa-plus mr-1"></i>
                Add Tier
              </button>
            </div>
            <hr />
            <div className="d-flex align-items-center justify-content-between pb-3 wrap-reverse-370">
              <div>
                <label className="p-0">
                  Release to public after last tier duration completes?
                </label>
              </div>
              <div className="form-check form-switch mb-point5-370">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isPublic}
                  value={isPublic}
                  defaultChecked={isPublic}
                  onChange={publicTierfun}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SupplierTable;
