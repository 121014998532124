import React, { useState, useEffect } from "react";
import Avatar from "react-avatar";
import TopNavBar from "../layout/TopNavBar";
import SideNavBar from "../layout/SideNavBar";
import Activity from "./Activity";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import {
  getUserRole,
  getCandidateId,
  getCandidateName,
  getUserId,
  getTenantType,
} from "../../service/AuthService";
import Footer from "../layout/Footer";

function SAdashboard() {
  const [accountDeatils, setAccountDetails] = useState("accountDeatils");
  const [preference, setPreferences] = useState(false);
  const [userdetails, setUser] = useState({
    user: "",
    role: "",
    tanent: "",
    userid: "",
    FirstName: "",
  });
  const { FirstName } = userdetails;
  const [toggle, setToggle] = useState(false);
  const { user, role, userid } = userdetails;
  const [settings, setSettings] = useState(false);

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };

  const profileType = (type) => {
    if (type === "accountDeatils") {
      if (accountDeatils === false) {
        setAccountDetails(true);
      } else {
        setAccountDetails(false);
      }
      setPreferences(false);
    }

    if (type === "preference") {
      if (preference === false) {
        setPreferences(true);
      } else {
        setPreferences(false);
      }
      setAccountDetails(false);
    }

    if (type === "settings") {
      setSettings(!settings);
    }
  };

  const getProfile = () => {
    const username = getCandidateName();
    const userrole = getUserRole();
    const iduser = getUserId();
    const FirstName = username.substr(0, username.indexOf(" "));
    setUser({
      user: username,
      role: userrole,
      userid: iduser,
      FirstName: FirstName,
    });
  };

  useEffect(() => {
    getProfile();
  }, []);

  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar handleToggale={toggleDiv} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div class="mb-5 text-center">
                    <h1>
                      <span className="wave">👋</span> Hi, {FirstName}. Welcome!{" "}
                    </h1>
                  </div>
                </div>
                <Activity />
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
}
export default SAdashboard;
