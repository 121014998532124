import React, { useState, useEffect } from "react";
import { post } from "../../../../service/ApiService";
import Loader from "../../../Loader/Loader";
import CommunicationModal from "./CommunicationModal";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { MdMarkChatUnread, MdMarkChatRead } from "react-icons/md";
import { Col, Row, Table } from "react-bootstrap";

const SendSMSBox = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const dataSize = 10;
  const [broadCastData, setBroadCastData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [recipientData, setRecipientData] = useState([]);

  const startIndex = (currentPage - 1) * dataSize;
  const endIndex = startIndex + dataSize;

  const currentData = broadCastData.slice(startIndex, endIndex);
  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getMonth() +
      1}/${date.getDate()}/${date.getFullYear()}`;
    return formattedDate;
  };
  useEffect(() => {
    getBroadcastData();
    // Set up a timer to fetch data every 2 minutes
    const timer = setInterval(() => {
      getBroadcastData();
    }, 2 * 60 * 1000); // 2 minutes in milliseconds

    // Clean up the timer on component unmount
    return () => {
      clearInterval(timer);
    };
  }, []);

  const getBroadcastData = async () => {
    const data = {
      userId: localStorage.getItem("userid"),
    };

    try {
      setLoading(true);
      const response = await post("/smsbroadcast/fetchLatestSendSms", data);

      if (response.status === 200) {
        setLoading(false);
        setBroadCastData(response.data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const openModal = async (contactNumber) => {
    try {
      const data = {
        messageTo: contactNumber,
      };
      setLoading(true);

      const response = await post("/smsbroadcast/fetchReceiveSms", data);
      if (response.status === 200) {
        setRecipientData(response?.data?.reverse());
        setLoading(false);
        setIsModalOpen(true);
      }
    } catch (error) {
      console.error("API Error:", error);
      setLoading(false);
    }
  };
  const updateChatReadStatus = async (to, from) => {
    try {
      const data = {
        messageTo: to,
        messageFrom: from,
      };
      setLoading(true);
      const response = await post("/smsbroadcast//updateSmsStatus", data);
      if (response.status === 200) {
        setLoading(false);
        getBroadcastData();
      }
    } catch (error) {
      console.error("API Error:", error);
      setLoading(false);
    }
  };
  return (
    <>
      {loading && <Loader />}
      <div className="mt-3" style={{ fontSize: "14px" }}>
        <h6>SMS</h6>
        <Table
          className="mt-2"
          bordered
          hover
          responsive
          id="broadCastSMSTable"
        >
          <thead>
            <tr>
              <th className="broadCastTableHead">To</th>
              <th className="broadCastTableHead">Contact number</th>
              {/* <th>
                <b>Subject</b>
              </th> */}
              <th className="broadCastTableHead">Date</th>
              <th className="broadCastTableHead"></th>
            </tr>
          </thead>
          <tbody>
            {currentData && currentData.length !== 0 ? (
              currentData?.map((item, index) => (
                <tr
                  className="email-row"
                  style={{ fontWeight: "lighter" }}
                  key={index}
                  id={item.userId}
                >
                  <td style={{ minWidth: "100px" }} className="p-2">
                    {item?.readStatus === false ? (
                      <MdMarkChatUnread
                        size={20}
                        color={"#3ab549"}
                        className="mr-2"
                      />
                    ) : (
                      // <MdMarkChatUnread
                      //   size={20}
                      //   color={"#3ab549"}
                      //   className="mr-2"
                      // />
                      <MdMarkChatRead
                        size={20}
                        color={"grey"}
                        className="mr-2"
                      />
                    )}
                    {"  "}
                    {item?.userType === "candidate"
                      ? item?.candidateFirstName + " " + item?.candidateLastName
                      : item?.recruiterFirstName +
                        " " +
                        item?.recruiterLastName}
                  </td>
                  <td className="p-2">
                    {item?.direction === "inbound"
                      ? item?.messageFrom
                      : item?.messageTo}
                  </td>
                  {/* <td>
                    {item?.direction === "inbound"
                      ? "Reply Message"
                      : item?.messageSubject}
                  </td> */}
                  <td className="p-2">{formatDate(item?.received_at)}</td>
                  <td
                    onClick={() => {
                      updateChatReadStatus(item?.messageTo, item?.messageFrom);
                      openModal(item?.messageTo);
                    }}
                    className="p-2"
                  >
                    <span className="action-icon">
                      <button
                        style={{ color: "#000" }}
                        className="btn btn-text"
                      >
                        <i className="fad fa-eye"></i>
                      </button>
                    </span>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="4"
                  className="p-5 text-center"
                  // className="d-flex justify-content-center align-content-center p-2"
                >
                  {" "}
                  No records found!
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      {currentData && currentData?.length > 0 && (
        <Row className="d-flex smsSendBoxPag mb-5">
          <Col md={6} sm={12} className="mt-5">
            Showing{" "}
            {Math.min((currentPage - 1) * 10 + 1, broadCastData?.length)} -{" "}
            {Math.min(currentPage * 10, broadCastData?.length)} of{" "}
            {broadCastData?.length}
          </Col>
          <Col md={6} sm={12} className="mt-5 text-md-right">
            <Pagination
              currentPage={currentPage}
              totalSize={broadCastData.length}
              sizePerPage={10}
              firstPageText="|⟨"
              lastPageText="⟩|"
              showFirstLastPages={true}
              changeCurrentPage={handlePageChange}
              theme="border-bottom"
            />
          </Col>
        </Row>
      )}
      <CommunicationModal
        isOpen={isModalOpen}
        closeModal={() => setIsModalOpen(false)}
        recipientData={recipientData}
      />
    </>
  );
};

export default SendSMSBox;
