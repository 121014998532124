import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { post } from '../../service/ApiService'
import Validator from 'email-validator';
import { toast } from 'react-toastify';
import { domain } from '../../constants/Constants'
import { LoginAppLogo } from '../../constants/Constants';

const ForgotPassword = () => {

    let [ email, setEmail ] = useState('')
    let [ loading, setLoading ] = useState(false);
    let [ waitForNotification, setWaitForNotification ] = useState(false)
    let history = useHistory()

    const notificationHandler = () => {
        setTimeout(() => {
            setWaitForNotification(false)
        }, 5000)
    }

    const ForgotPasswordAPI = async (params) => {
        try {
            setLoading(true)
            const res = await post('/forgotpassword', params)
            if (res.status === 200) {
                toast.success(`Password reset link has been successfully sent to '${email}'`)
                setTimeout(() => history.push('/login'), 8000)
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
            toast.error('Email not found')
        }
    }

    const handleReset = (e) => {
        e.preventDefault()
        if(waitForNotification) {
            return null
        }
        else {
            setWaitForNotification(true)
            notificationHandler()
            if (email === '') {
                toast.error('Please Enter Email')
            }
            else if (!Validator.validate(email)) {
                toast.error('Please Enter Valid Email')
            }
            else {
                const params = {
                    email: email,
                    domain: domain,
                    tenantName: 'High5'
                }
                ForgotPasswordAPI(params)
            }
        }  
    }

    return (
        <>
            <div id="ForgotPassword" className="login-layout">
                <div className="col-left d-flex align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-6 col-lg-8">
                                <div>
                                    <a className="logo" href="/" >
                                        <img src={LoginAppLogo} alt='' />
                                    </a>
                                    <div className="section section-sm">
                                        <h1>Forgot Password?</h1>
                                        <p>
                                            Enter the email address of your account and we’ll send you the instructions to reset your password
                                        </p>
                                        <form>
                                            <div className="form-floating mb-3">
                                                <input
                                                    type="email"
                                                    className="form-control"
                                                    name="email"
                                                    placeholder="Enter email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                                <label>Email address*</label>
                                            </div>
                                            <button type="submit" onClick={handleReset} className="btn btn-dark w-100" disabled={loading}>
                                                <span>Reset Password</span>
                                            </button>
                                            <p className="text-center mt-5">
                                                Remember Your Password? <a href="/login">Login</a>
                                            </p>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-right">
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
