import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import FromA from "../../disposition/applyJob_v2/index"; //_________________________________Form A
import FromB from "../../disposition/applyJob_v2_Parsing/index"; //______________________Form B
import { getCandidateDetails } from "../../../../actions/candidate/getCandidateDetails";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { post } from "../../../../service/ApiService";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";
import { useAuth0 } from "@auth0/auth0-react";
import {
  apiDomain,
  domain,
  emailDomain,
} from "../../../../constants/Constants";
import {
  GoogleLogin,
  GoogleOAuthProvider,
  useGoogleLogin,
} from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import axios from "axios";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import linkedin from "react-linkedin-login-oauth2/assets/linkedin.png";
import { BsLinkedin } from "react-icons/bs";
import { getAutoHeightDuration } from "@mui/material/styles/createTransitions";
import GitHubLogin from "react-github-login";

const ApplyNow = ({
  job,
  setRenderTopNavBar,
  userLoggedIn,
  setUserLoggedIn,
}) => {
  const FROMTYPES = ["A", "A"];
  const [RANDOM, setRANDOM] = useState(
    FROMTYPES[Math.floor(Math.random() * FROMTYPES.length)]
  );
  const { user, loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const [socialMediaApply, setSocialMediaApply] = useState(false);
  const { tenantData, candidateDetailsLoading, candidateData } = useSelector(
    (state) => ({
      tenantLoading: state.getTenantReducer.tenantLoading,
      tenantData: state.getTenantReducer.tenantData,
      candidateDetailsLoading:
        state.candidateDetailsReducer.candidateDetailsLoading,
      candidateData: state.candidateDetailsReducer.candidateData,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [apply, setApply] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [modalSize, setModalSize] = useState("login");
  const [ip, setIp] = useState("");
  const handleClick = () => {
    sessionStorage.getItem("token") == null
      ? setApply(true)
      : setSocialMediaApply(true);
  };

  const handleCancel = () => {
    //if(sessionStorage.getItem("token") == null ){
    setApply(false);
    setModalSize("apply");
  };
  // const handleLogin = () => {
  //   setModalSize("login");
  //   setApply(true);
  //   setLoggedIn(true);
  // };

  const addFavouriteJob = async () => {
    let jobArray = [];

    if (candidateData?.favouriteJobs == null) {
      let idString = job.jobId.toString();
      jobArray = [job.jobId];
      // props.setIsFav(true);
    } else {
      let favJobArray = JSON.parse(candidateData?.favouriteJobs);
      if (favJobArray?.includes(job.jobId)) {
        jobArray = favJobArray.filter((f) => f != job.jobId);
        // props.setIsFav(false);
      } else {
        jobArray = [...favJobArray, job.jobId];
        // props.setIsFav(true);
      }
    }
    const params = {
      candidateId: candidateData?.userId,
      favouriteJobs: JSON.stringify(jobArray),
    };
    setLoading(true);
    await post("/favourites/favouritejobs", params)
      .then((res) => {
        const payload = {
          candidateId: sessionStorage.getItem("userId"),
        };

        dispatch(getCandidateDetails(payload));
        // if (props.isFrom == "favJobs") {
        //   toast.success("Job removed as favourite!");
        //   setTimeout(() => {
        //     window.location.reload();
        //   }, 2000);
        // }
        // else {
        setTimeout(() => {
          setLoading(false);

          !candidateData?.favouriteJobs?.includes(job.jobId)
            ? toast.success("Job added as favourite!")
            : toast.success("Job removed as favourite!");
        }, 5000);
        // }
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };
  useEffect(() => {
    const payload = {
      candidateId: sessionStorage.getItem("userId"),
    };

    !candidateData && dispatch(getCandidateDetails(payload));
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      sessionStorage.setItem("name", user?.name);
      sessionStorage.setItem("userName", user?.email);
      sessionStorage.setItem("token", user?.sub);
      // window.location.reload();
    }
  }, [user]);

  const googleLogin = useGoogleLogin({
    onSuccess: async (respose) => {
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${respose.access_token}`,
            },
          }
        );

        sessionStorage.setItem("name", res.data?.name);
        sessionStorage.setItem("userName", res.data?.email);
        sessionStorage.setItem("token", res.data?.sub);
        setLoginData(res.data);
        setLoginData({
          firstName: res?.data?.given_name,
          lastName: res?.data?.family_name,
          email: res.data?.email,
        });
        setUserLoggedIn(true);
        setRenderTopNavBar(true);
        setSocialMediaApply(true);
        // window.location.reload();
      } catch (err) {
        console.log(err);
      }
    },
  });
  // const { linkedInLogin } = useLinkedIn({
  //   clientId: "86vhj2q7ukf83q",
  //   redirectUri: `${window.location.origin}/linkedin`,
  //   // for Next.js, you can use `${typeof window === 'object' && window.location.origin}/linkedin`
  //   onSuccess: (code) => {
  //     console.log(code);
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });
  // const { linkedInLogin } = useLinkedIn({
  //   clientId: "77jzp0tpfjwqkw",
  //   scope: "r_liteprofile r_emailaddress",
  //   redirectUri: `${window.location.origin}/linkedin`,
  //   onSuccess: (code) => {
  //     setLoading(true);
  //     console.log(code);
  //     let params = {
  //       grant_type: "authorization_code",
  //       code: code,
  //       client_id: "77jzp0tpfjwqkw",
  //       client_secret: "6gxeH5iz7TLO5239",
  //       redirect_uri: `${window.location.origin}/linkedin`,
  //     };
  //     axios
  //       .post("https://www.linkedin.com/oauth/v2/accessToken", params, {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       })
  //       .then(function(response) {
  //         setLoading(false);
  //         console.log("tokenss", response);
  //         axios
  //           .get("https://api.linkedin.com/v2/me", {
  //             headers: { Authorization: `Bearer ${response.access_token}` },
  //           })
  //           .then((response) => {
  //             console.log("Linkedindataaa", response);
  //           })
  //           .catch((err) => {
  //             console.log(err);
  //           });
  //       })
  //       .catch(function(error) {
  //         setLoading(false);
  //         console.log(error);
  //       });
  //   },
  //   onError: (error) => {
  //     console.log(error);
  //   },
  // });
  const { linkedInLogin } = useLinkedIn({
    clientId: "77jzp0tpfjwqkw",
    scope: "r_liteprofile r_emailaddress",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      setLoading(true);

      let params = {
        grant_type: "authorization_code",
        code: code,
        client_id: "77jzp0tpfjwqkw",
        client_secret: "6gxeH5iz7TLO5239",
        redirect_uri: `${window.location.origin}/linkedin`,
      };

      axios
        .post(`${apiDomain}/linkedInLogin/linkedin`, params)
        .then((data) => {
          let name =
            data.data.localizedFirstName + " " + data.data.localizedLastName;
          // handleLogin(name, "", "");
          sessionStorage.setItem("name", name);
          sessionStorage.setItem("userName", data.data?.email);
          sessionStorage.setItem("token", code);
          setLoginData({
            firstName: data?.data?.localizedFirstName,
            lastName: data?.data?.localizedLastName,
            email: data?.data?.email,
          });
          //
          setUserLoggedIn(true);
          setRenderTopNavBar(true);
          setSocialMediaApply(true);
          // setSocialMediaApply(true);
          setLoading(false);
        })
        .catch((err) => {
          console.log("error", err);
          setLoading(false);
        });
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // const handleLogin = (name, url, email) => {
  //   // setLogin(false);
  //   toast.success("Logged In Successfully");
  //   localStorage.setItem("isLoggedIn", "Yes");
  //   localStorage.setItem("name", name);
  //   localStorage.setItem("url", url);
  //   localStorage.setItem("email", email);
  //   // setPageReRender(!pageReRender);
  //   // setToggle(false);
  // };
  const handleLogin = () => {
    setModalSize("login");
    setApply(true);
    setLoggedIn(true);
  };
  // const onSuccess = (response) => console.log(response);
  // const onFailure = (response) => console.error(response);
  const getIpData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res.data.ip);
  };
  useEffect(() => {
    getIpData();
  }, []);

  const renderForm = () => {
    return RANDOM === "A" ? (
      <FromA
        job={job}
        setModalSize={setModalSize}
        setApply={setApply}
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
      />
    ) : (
      <FromB
        job={job}
        setModalSize={setModalSize}
        setApply={setApply}
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
      />
    );
  };
  return (
    <>
      {loading && <Loader />}
      <div className="main_apply_container" style={{ top: "32%" }}>
        <div className="main_apply_container_apply">
          <span style={{ marginBottom: "10px" }}>
            <h6>Apply</h6>
          </span>
          <button
            className="main_apply_container_apply_btn1"
            onClick={() => {
              setRANDOM(
                FROMTYPES[Math.floor(Math.random() * FROMTYPES.length)]
              );
              sessionStorage.getItem("userId") == null
                ? handleClick()
                : handleLogin();
            }}
            disabled={job?.jobStatus == 23 || job?.jobStatus == 26}
          >
            <span>Apply Now</span>
          </button>
          {!userLoggedIn && sessionStorage.getItem("token") == null && (
            <>
              {/* <GoogleOAuthProvider clientId="823728117580-ksfsb75eplt0hiqbqu2lpv5hmbode7dj.apps.googleusercontent.com">
                <GoogleLogin
                  onSuccess={(credentialResponse) => {
                    console.log(jwt_decode(credentialResponse.credential));
                    sessionStorage.setItem(
                      "name",
                      jwt_decode(credentialResponse.credential)?.name
                    );
                    sessionStorage.setItem(
                      "userName",
                      jwt_decode(credentialResponse.credential)?.email
                    );
                    sessionStorage.setItem(
                      "token",
                      jwt_decode(credentialResponse.credential)?.jti
                    );
                    window.location.reload();
                  }}
                  onError={() => {
                    console.log("Login Failed");
                  }}
                  />
                  </GoogleOAuthProvider> */}
              {/* <button
                className="main_apply_container_apply_btn1 mt-2"
                style={{ backgroundColor: "white", color: "blue" }}
                onClick={() => googleLogin()}
                disabled={job?.jobStatus == 23 || job?.jobStatus == 26}
              >
                <span>
                  <img
                    src="https://static.vecteezy.com/system/resources/previews/002/557/425/original/google-mail-icon-logo-isolated-on-transparent-background-free-vector.jpg"
                    style={{
                      marginRight: "2px",
                      height: "20px",
                      width: "22px",
                    }}
                  />
                  Gmail
                </span>
              </button>
              <button
                className="main_apply_container_apply_btn1 mt-2"
                style={{ backgroundColor: "white", color: "blue" }}
                onClick={() => linkedInLogin()}
                disabled={job?.jobStatus == 23 || job?.jobStatus == 26}
              >
                <span>
                  <img
                    src="https://e1.pngegg.com/pngimages/47/203/png-clipart-linkedin-icon-512x512-linkedin.png"
                    style={{
                      marginRight: "2px",
                      height: "20px",
                      width: "33px",
                    }}
                  />
                  Linkedin
                </span>
              </button> */}
              {/* <GitHubLogin
                className="main_apply_container_apply_btn1 mt-2"
                style={{ backgroundColor: "white", color: "blue" }}
                clientId="2909e32d8e3076b3ded6"
                onSuccess={onSuccess}
                onFailure={onFailure}
              /> */}
              {/* <button
                className="main_apply_container_apply_btn1 mt-2"
                style={{ backgroundColor: "white", color: "blue" }}
                onClick={() =>
                  sessionStorage.getItem("token") == null
                    ? logout({
                        logoutParams: { returnTo: window.location.origin },
                      })
                    : handleLogin()
                }
                disabled={job?.jobStatus == 23 || job?.jobStatus == 26}
              >
                <span>
                  <i class="fab fa-facebook-f fa-lg mr-1"></i>Facebook
                </span>
              </button> */}
            </>
          )}
          {sessionStorage.getItem("userId") !== null && (
            <button
              className="main_apply_container_apply_btn1 mt-2"
              style={{ backgroundColor: "white", color: "#7fba00" }}
              onClick={() =>
                sessionStorage.getItem("token") == null
                  ? handleClick()
                  : addFavouriteJob()
              }
              disabled={sessionStorage.getItem("userId") == null}
            >
              <span>
                {candidateData?.favouriteJobs !== null &&
                candidateData?.favouriteJobs?.includes(job?.jobId) ? (
                  <i class="fas fa-heart mr-1" style={{ color: "#7FBA00" }}>
                    {" "}
                  </i>
                ) : (
                  <i class="far fa-heart mr-1" style={{ color: "#7FBA00" }}></i>
                )}
                {candidateData?.favouriteJobs !== null &&
                candidateData?.favouriteJobs?.includes(job.jobId)
                  ? "favorited!"
                  : "Favorite this job"}
              </span>
            </button>
          )}
          <hr className="line"></hr>
        </div>
      </div>
      {/* share */}
      <div className="main_apply_container" style={{ top: "53%" }}>
        <div className="main_apply_container_apply">
          <span style={{ marginBottom: "10px" }}>
            <h6>Share via</h6>
          </span>
          <div className="w-100 d-flex justify-content-between">
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                let link = `${emailDomain}${window.location.pathname}`;
                navigator.clipboard.writeText(link);
                toast.success("Job link copied successfully!");
                setTimeout(() => {
                  window.open("https://mail.google.com/?", "_blank");
                }, 2000);
              }}
            >
              <i class="fas fa-envelope mr-1"></i>
              <span>Gmail</span>
            </span>
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                let link = `${emailDomain}${window.location.pathname}`;
                navigator.clipboard.writeText(link);
                toast.success("Job link copied successfully!");
                setTimeout(() => {
                  window.open("https://www.linkedin.com/", "_blank");
                }, 2000);
              }}
            >
              {" "}
              <i class="fab fa-linkedin-in fa-lg mr-1"></i>LinkedIn
            </span>
            {/* <span>
              {" "}
              <i class="fab fa-facebook-f fa-lg mr-1"></i>
            </span>
            <span>
              <i class="fab fa-twitter"></i>
            </span> */}
            <span
              style={{ cursor: "pointer" }}
              onClick={() => {
                let link = `${emailDomain}${window.location.pathname}`;
                navigator.clipboard.writeText(link);
                toast.success("Job link copied successfully!");
              }}
            >
              <i class="fas fa-share-alt mr-1"></i>Share
            </span>
          </div>
        </div>
      </div>
      <Modal show={apply} size={modalSize == "apply" ? "xl" : "lg"}>
        <Modal.Header>
          <div style={{ zIndex: "1000" }}>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => handleCancel()}
              className="close p-0 bl-modal-close-btn mx-1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>{renderForm()}</Modal.Body>
      </Modal>
      <Modal show={socialMediaApply} size={"xl"}>
        <Modal.Header>
          <div style={{ zIndex: "1000" }}>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                // setApply(false);
                // setModalSize("apply");
                setSocialMediaApply(false);
              }}
              className="close p-0 bl-modal-close-btn mx-1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <FromA
            job={job}
            setModalSize={setModalSize}
            setApply={setApply}
            setLoggedIn={setLoggedIn}
            loggedIn={loggedIn}
            socialMediaApply={socialMediaApply}
            isFrom="socialMediaApply"
            loginData={loginData}
            setSocialMediaApply={setSocialMediaApply}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ApplyNow;
