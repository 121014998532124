import React, { useEffect, useState } from "react";

const CreateAssessment = ({
  questionData,
  setquestionData,
  addJobData,
  addQuestionVal,
}) => {
  const [answers, setAnswers] = useState([
    {
      question: "",
      options: ["", ""],
      answer: "",
    },
  ]);
  const difficultyData = [
    { exp: "Intern", val: "Simple" },
    { exp: "Less than one year", val: "Simple" },
    { exp: "1-3 years", val: "Medium" },
    { exp: "3-5 years", val: "Medium" },
    { exp: "5-10 years", val: "Advanced" },
    { exp: "10+ years", val: "Advanced" },
  ];

  let difficulty = "";
  difficultyData.forEach((obj) => {
    if (obj.exp === addJobData.experienceLevel) difficulty = obj.val;
  });

  useEffect(() => {}, [questionData]);

  const handleAddQuestion = () => {
    setAnswers([
      ...answers,
      {
        question: "",
        options: ["", ""],
        answer: "",
      },
    ]);
  };

  const handleAddOption = () => {
    setquestionData((prevState) => ({
      ...prevState,
      options: [...prevState.options, ""],
    }));
  };

  const handleDeleteOption = (e, index) => {
    const updatedQuestions = [...questionData?.options];
    updatedQuestions.splice(index, 1);
    // setReadyToHireQAList(updatedQuestions);
    setquestionData((prevState) => ({
      ...prevState,
      options: updatedQuestions,
    }));
  };
  const cancelBtn = () => {
    setquestionData((prevState) => ({
      ...prevState,
      options: prevState.options.slice(0, -1),
    }));
  };
  return (
    <>
      {
        <div style={{ width: "90%", margin: "5% auto" }}>
          <div className={"card card-flat bg-gray4 mb-3"}>
            <div className="row m-2">
              <div className="col-4">
                <div className="d-flex form-floating">
                  <select
                    value={questionData.type}
                    onChange={(e) => {
                      setquestionData((state) => ({
                        ...state,
                        type: e.target.value,
                      }));
                    }}
                    className={
                      addQuestionVal?.type && questionData.type === ""
                        ? "form-select font-14 border border-danger"
                        : "form-select font-14"
                    }
                  >
                    <option value="">Select type</option>
                    <option value="MCQ">MCQ</option>
                    <option value="OneWay">OneWay</option>
                    <option value="Multiline">Multiline</option>
                  </select>
                  <label className="mb-2">Question type*</label>
                </div>
                {addQuestionVal?.type && questionData.type === "" && (
                  <small className="validation ml-2">
                    {addQuestionVal.type}
                  </small>
                )}
              </div>

              <div className="col-4">
                <div className="d-flex flex-column form-floating">
                  <select
                    onChange={(e) => {
                      setquestionData((state) => ({
                        ...state,
                        difficulty: e.target.value,
                      }));
                    }}
                    className="form-select font-14"
                    defaultValue={difficulty}
                    value={addJobData?.difficulty}
                  >
                    <option value="Simple">Simple</option>
                    <option value="Medium">Medium</option>
                    <option value="Advanced">Advance</option>
                  </select>
                  <label className="mb-2">Difficulty*</label>
                </div>
              </div>
              <div className="col-4">
                <div className="d-flex flex-column form-floating">
                  <select
                    className={
                      addQuestionVal?.skill && questionData.skill === ""
                        ? "form-select font-14 border border-danger"
                        : "form-select font-14"
                    }
                    value={questionData.skill}
                    onChange={(e) => {
                      setquestionData((state) => ({
                        ...state,
                        skill: e.target.value,
                      }));
                    }}
                  >
                    <option value="">Select a skill</option>
                    {addJobData?.primarySkills
                      ?.flat(1) // Flatten the array by one level if needed
                      .filter((skill) => skill && skill.trim() !== "") // Remove empty or whitespace-only elements
                      .map((skill, index) => (
                        <option key={index} value={skill}>
                          {skill}
                        </option>
                      ))}
                  </select>
                  <label className="mb-2">Select a skill*</label>
                </div>
                {addQuestionVal?.skill && questionData.skill === "" && (
                  <small className="validation ml-2">
                    {addQuestionVal.skill}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className={"d-flex flex-column mb-3 card card-flat bg-gray4 "}>
            <div className={" p-2 w-100"}>
              <div className="d-flex flex-column ">
                <label className="mb-2 pt-0">What is your question?*</label>
                <div className="form-floating ">
                  <textarea
                    type="text"
                    className={
                      addQuestionVal?.question && questionData.question === ""
                        ? "form-control border border-danger"
                        : "form-control"
                    }
                    name="question"
                    aria-describedby="emailHelp"
                    placeholder="Type your question."
                    value={questionData.question}
                    rows={3}
                    onChange={(e) => {
                      setquestionData((state) => ({
                        ...state,
                        question:
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1),
                      }));
                    }}
                  />
                  <label>Question</label>
                </div>
                {addQuestionVal?.question && questionData.question === "" && (
                  <small className="validation ml-2">
                    {addQuestionVal.question}
                  </small>
                )}
              </div>
            </div>
          </div>
          {questionData.type === "MCQ" && (
            <>
              <div className="card card-flat bg-gray4 mb-3 p-2">
                <label className="mb-2">Options (Maximum 4)*</label>
                {questionData?.options?.map((elem, idx) => (
                  <div className="mb-3" key={idx}>
                    <div
                      className="form-floating d-flex"
                      style={{ alignItems: "center" }}
                    >
                      <input
                        type="text"
                        className={
                          addQuestionVal?.options &&
                          questionData.options[idx] === ""
                            ? "form-control border border-danger"
                            : "form-control"
                        }
                        name="option"
                        placeholder="Type an answer"
                        id={idx}
                        value={elem}
                        onChange={(e) => {
                          const newOptions = [...questionData?.options];
                          newOptions[idx] = e.target.value;
                          setquestionData((prevState) => ({
                            ...prevState,
                            options: newOptions,
                          }));
                        }}
                      />
                      <label>Enter a possible answer</label>
                    </div>
                    {addQuestionVal?.options &&
                      questionData.options[idx] === "" && (
                        <small className="validation ml-2">
                          *Required Field
                        </small>
                      )}
                  </div>
                ))}
                {addQuestionVal?.options && questionData.options === "" && (
                  <small className="validation ml-2">
                    {addQuestionVal.options}
                  </small>
                )}
                <div style={{ textAlign: "center" }}>
                  <button
                    className="btn btn-primary"
                    onClick={handleAddOption}
                    disabled={questionData?.options?.length >= 4}
                  >
                    + Add options
                  </button>
                  <button
                    className="btn btn-coral ml-2"
                    disabled={questionData?.options?.length <= 2}
                    onClick={cancelBtn}
                  >
                    - Remove options
                  </button>
                </div>
              </div>
              <div className="card card-flat bg-gray4 mb-3 p-2">
                <div className="form-floating ">
                  <select
                    className={
                      addQuestionVal?.answer && questionData.answer[0] === ""
                        ? "form-select border border-danger"
                        : "form-select"
                    }
                    onChange={(e) =>
                      setquestionData({
                        ...questionData,
                        answer: [e.target.value],
                      })
                    }
                    value={questionData?.answer[0]}
                  >
                    <option value="">Select a correct answer</option>
                    {questionData?.options?.map((elem, idx) => (
                      <option value={elem} key={idx}>
                        {elem}
                      </option>
                    ))}
                  </select>
                  <label>Select correct answer. *</label>
                </div>
                {addQuestionVal?.answer &&
                  questionData.answer?.length === 0 && (
                    <small className="validation ml-2">
                      {addQuestionVal.answer}
                    </small>
                  )}
                <small>
                  From the entered option select one correct answer.
                </small>
              </div>
            </>
          )}
        </div>
      }
    </>
  );
};

export default CreateAssessment;
