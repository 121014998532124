import React, { useEffect, useState } from "react";
import TopNavBar from "../../layout/TopNavBar";
import SideNav from "../../layout/SideNavBar";
import CandidateTodos from "./CandidateTodos";
import CandidateJobs from "./CandidateJobs";
import CandidateProfileWidget from "./CandidateProfileWidget";
import CandidateInterviews from "./CandidateInterviews";
import { get, post } from "../../../service/ApiService";
import {
  getCandidateName,
  getCandidateId,
  getUserName,
} from "../../../service/AuthService";
import Loader from "../../Loader/Loader";
import { domain } from "../../../constants/Constants";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Dropzone from "react-dropzone";

const CandidateDashboard = () => {
  const [toggle, setToggle] = useState(true);
  const [profileDetails, setProfileDetails] = useState([]);
  const [loadingResume, setLoadingResume] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState([]);
  const [showDashboard, setShowDashboard] = useState(false);
  const [resumeInfo, setResumeInfo] = useState({
    fileName: "",
    base64: "",
    fileType: "",
  });
  const [disableSubmit, setDisableSubmit] = useState(false);

  const fetchDetails = async () => {
    try {
      const res = await get("/candidateProfile/fetchCandidateData");
      setProfileDetails(res.data);
    } catch (err) {
      toast.error("Failed to get Profile Details");
    }
  };

  const fetchCandidateResume = async () => {
    try {
      setLoadingResume(true);
      const payload = {
        candidateID: getCandidateId(),
      };
      let res = await post("/candidateProfile/getResume", payload);
      if (res.status === 200) {
        if (res.data.length > 0) {
          setResume(res.data);
          localStorage.setItem("ResumeURL", JSON.stringify(res.data));
          setShowDashboard(true);
          fetchDetails();
        }
      } else {
        toast.error("Some Error Occurred while Fetching Resume");
      }
    } catch (err) {
      toast.error("Failed to get Resume");
    }
    setLoadingResume(false);
  };

  useEffect(() => {
    fetchCandidateResume();
  }, []);

  const uploadResume = async () => {
    if (resumeInfo.fileName) {
      let type = resumeInfo.fileName.split(".")[1];
      if (type === "pdf" || type === "docx") {
        setLoading(true);
        try {
          let params = {
            ...resumeInfo,
            candidateId: getCandidateId(),
          };
          const res = await post("/CandidateDashboard/uploadResume", params);
          if (res.status === 200) {
            setLoading(false);
            setDisableSubmit(true);
            toast.success("Resume Uploaded Successfully");
            setTimeout(() => {
              setShowDashboard(true);
              fetchDetails();
            }, 4000);
          } else {
            toast.error("Some Error Occurred. Please Try Again");
          }
        } catch (err) {
          toast.error("Some Error Occurred. Please Try Again");
        }
        setLoading(false);
      } else {
        toast.error("Accepted File Types are PDF and DOCX");
      }
    } else {
      toast.error("Please Upload Resume");
    }
  };

  const setFileForSending = (file) => {
    const { name } = file[0];
    const reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = (event) => {
      setResumeInfo({ fileName: name, base64: event.target.result });
      // toast.success("File uploaded successfully")
    };
  };

  return (
    <>
      {(loadingResume || loading) && <Loader />}
      {showDashboard ? (
        <body
          className={toggle ? "layout1 layout1-closed close-menu" : "layout1"}
        >
          <div class="body-decorator body-decorator-top"></div>
          <div class="body-decorator body-decorator-bottom"></div>
          <TopNavBar handleToggale={() => setToggle(!toggle)} />
          <SideNav />
          <div id="Content">
            <div class="section">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-12">
                    <div class="mb-5 text-center">
                      <h3>
                        {" "}
                        <span className="wave">👋</span> Hi,{" "}
                        {profileDetails.length > 0 &&
                          profileDetails[0].firstName}
                        . Welcome!{" "}
                      </h3>
                    </div>
                  </div>
                </div>
                {profileDetails.length > 0 && (
                  <div class="row justify-content-center">
                    <div class="col-xxl-12 col-lg-8">
                      <div class="row justify-content-center">
                        <div class="col-xxl-3">
                          {!loadingResume && (
                            <CandidateProfileWidget
                              resume={false}
                              details={profileDetails[0]}
                            />
                          )}
                        </div>
                        <CandidateTodos />
                        <CandidateJobs details={profileDetails[0]} />
                        {!loadingResume && (
                          <CandidateProfileWidget
                            resume={true}
                            details={profileDetails[0]}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </body>
      ) : (
        !loadingResume && (
          <Modal show={true} size="lg">
            <Modal.Header className="bg-gray4">
              <h6 class="">Upload Resume</h6>
              {/* <button 
                            type="button" 
                            data-dismiss="modal" 
                            aria-label="Close" 
                            onClick={() => setAddJobNewModal(false)} 
                            class="close p-0 bl-modal-close-btn"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button> */}
            </Modal.Header>
            <Modal.Body>
              <div class="row">
                <div class="col-12">
                  <div class="p-5 p-0-600">
                    <p class="my-2">
                      We didn't find your resume, please upload your updated
                      resume to proceed further.
                    </p>
                    <small className="mt-2">
                      Accepted File Type: .docx, .pdf
                    </small>
                    <div class="col-sm-12 text-center mt-3">
                      {
                        <Dropzone onDrop={setFileForSending} multiple={false}>
                          {({ getRootProps, getInputProps }) => (
                            <div {...getRootProps()}>
                              <button
                                type="button"
                                className="btn btn-text-accent mr-1"
                              >
                                <i
                                  aria-hidden="true"
                                  className="far fa-cloud-upload mr-1 fa-fw"
                                ></i>
                                <span>Upload</span>
                              </button>
                              <input type="file" {...getInputProps()} />
                            </div>
                          )}
                        </Dropzone>
                      }
                    </div>
                    {resumeInfo.fileName && (
                      <div className="text-center my-3">
                        <strong className="mt-2">{resumeInfo.fileName}</strong>
                        <i className="fas fa-check ml-1"></i>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <div className="d-flex align-items-center justify-content-end m-3">
              <button
                className="btn btn-sm btn-secondary"
                onClick={() => {
                  fetchDetails();
                  setShowDashboard(true);
                }}
              >
                Skip
              </button>
              <button
                className="ml-2 btn btn-sm btn-primary"
                disabled={disableSubmit}
                onClick={uploadResume}
              >
                Submit
              </button>
            </div>
          </Modal>
        )
      )}
    </>
  );
};

export default CandidateDashboard;
