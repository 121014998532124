import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-tagsinput/react-tagsinput.css";
import imageLoader from "../../../../images/button-loader.gif";
import validator from "validator";
import {
  getCandidateId,
  getCandidateName,
  getUserEmail,
  getUserId,
  getUserTenant,
  getUserTenantID,
} from "../../../../service/AuthService";
import { post } from "../../../../service/ApiService";
import { domain, emailDomain } from "../../../../constants/Constants";
import Loader from "../../../Loader/Loader";
import PhoneInput from "react-phone-input-2";
import axios from "axios";

const InviteTalent = (props) => {
  const [shareClicked, setShareClicked] = useState(false);
  const [bulkUpload, setBulkUpload] = useState(false);
  const [phoneCode, setPhoneCode] = useState("");
  const [inputList, setInputList] = useState([
    {
      candidateEmail: "",
      candidateFname: "",
      candidateLname: "",
      candidatePhoneCode: "",
      candidatePhone: "",
    },
  ]);

  const addedList = {
    candidateEmail: "",
    candidateFname: "",
    candidateLname: "",
    candidatePhoneCode: "",
    candidatePhone: "",
  };

  let tenant_id = getUserTenantID();
  const usertenant = getUserTenant();
  const loggedinusername = getCandidateName();
  const userid = getCandidateId();
  let sendMailLink =
    props.isFrom == "TalentPool"
      ? `${emailDomain}/careers/${props.jobData.createdby}/joblist/pooldescription/${props.jobData.poolId}/recruiter/${userid}`
      : // : `${emailDomain}/careers/${props.jobData.fk_tenant}/joblist/jobdescription/${props.jobData.jobId}/recruiter/${userid}`;
        `${emailDomain}/career/jobs/${props.jobData.jobId}/recruiter/${userid}`;
  // Function to make a request to the URL shortening API
  async function shortenUrl(longUrl) {
    const headers = {
      'Accept': 'application/json',
      'Authorization': 'Bearer WJwUfBqQhE8ORpWMhoyae6IlvSPq2eIc3rS21kjth1Q8tY4yi3hkY9Zfvpqv',
      'Content-Type': 'application/json'
    };
    const requestBody = {
      url: longUrl,
      domain: 'high5hire.live'
    };
  
    try {
    const response = await axios.post('https://api.tinyurl.com/create', requestBody, { headers });
    if (response.data && response.data.data && response.data.data.tiny_url) {
      const shortenedUrl = response.data.data.tiny_url;
      console.log('Shortened URL:', shortenedUrl);
      return shortenedUrl;
    } else {
      console.log('Response does not contain a valid shortened URL.');
      return "";
    }
  } catch (error) {
      console.error("Error shortening URL:", error);

    }
  }
  const SMS = async (item) => {
    try {
      const shortenedUrl = await shortenUrl(sendMailLink);
      const payload = await Promise.all(
        item.map(async (i) => {
          let inviteContent = "";
          const inviteReq = {
            event: "Invite Talent",
            recipient: '["Candidate"]',
          };

          try {
            const res = await post("/msgtwoway/getSmsContent", inviteReq);
            if (res.status === 200) {
              inviteContent = res.data;
            }
          } catch (error) {
            console.error(
              "An error occurred while getting SMS content:",
              error
            );
          }

          if (inviteContent?.length !== 0) {
            let content = inviteContent[0].smsContent;
            content = content.replace("[FirstName]", i.candidateFname);
            content = content.replace("[JobTitle]", i.jobTitle);
            content = content.replace("[LinkForDetails]", shortenedUrl);
            inviteContent[0].smsContent = content;
          }

          return {
            recieverNumber: [
              i?.candidatePhone
                ? `${i.candidatePhoneCode}${i.candidatePhone.replaceAll(
                    " ",
                    ""
                  )}`
                : "",
            ],
            // message: `Hi ${i.candidateFname}, Heads up! Your recruiter has invited you to apply for the ${i.jobTitle} role.\nClick below for more details and apply ASAP.\n${sendMailLink}\nThe High5 Team `,
            message: `${inviteContent?.[0]?.smsContent} \n ${inviteContent?.[0]?.footer}`,
          };
        })
      );

      const payloadSms = payload.filter(
        (i) =>
          i.recieverNumber[0].charAt(0) === "+" &&
          i.recieverNumber[0].charAt(1) === "1" &&
          i.recieverNumber[0].length > 10
      );

      const payloadWA = payload
        .map((item) => {
          if (item.recieverNumber[0].charAt(0) === "+") {
            item.recieverNumber[0] = item.recieverNumber[0]?.substring(1);
          }
          return item;
        })
        .filter(
          (item) =>
            item.recieverNumber[0].charAt(0) !== "1" &&
            item.recieverNumber[0].length > 10
        );

      payloadSms.length > 0 && (await post("/msgtwoway/telSms", payloadSms));
      payloadWA.length > 0 &&
        (await post("/massages/sentMassageWhatsapp", payloadWA));
    } catch (error) {
      console.error("An error occurred:", error);
      // toast.error("An error occurred");
    }
  };

  const SMS2 = async (item) => {
    try {
      const tenant = {
        createdBy: getUserId(),
        jobId: props.jobData.jobId,
      };
      const numbers = await post("/msgtwoway/getNumbersForOffer", tenant);
      const data = numbers.data.filter(
        (n) => n.fk_Role !== 8 && n.fk_Role !== 4
      );

      if (data.length === 0) return;

      const recepientsDataSms = [];
      const recepientsDataWhatsApp = [];

      const inviteReq = {
        event: "Invite Talent",
        recipient: '["Recruiter"]',
      };

      const fetchInviteContent = async () => {
        try {
          const res = await post("/msgtwoway/getSmsContent", inviteReq);

          return res.status === 200 ? res.data : "";
        } catch (error) {
          console.error("An error occurred while getting SMS content:", error);
          return "";
        }
      };

      const createMessageContent = (inviteContent, obj, i) => {
        let content = inviteContent[0].smsContent;
        content = content.replace("[FirstName]", obj.firstName);
        content = content.replace(
          "[CandidateName]",
          i.candidateFname + " " + i.candidateLname
        );
        content = content.replace("[JobTitle]", i.jobTitle);
        content = content.replace(
          "[LinkForJobDetails]",
          `${emailDomain}/high5Hire/requests/${i.jobId}`
        );
        inviteContent[0].smsContent = content;
        return inviteContent?.[0]?.smsContent;
      };
      const inviteContent = await fetchInviteContent();
      data.forEach(async (obj) => {
        if (obj.fk_Role === 3) {
          const recepientDataSms = [];
          const recepientDataWhatsApp = [];

          let home = obj.homePhone ? obj.homePhone : "";
          let mobile = obj.mobilePhone ? obj.mobilePhone : "";
          let work = obj.workPhone ? obj.workPhone : "";

          if (home.charAt(0) !== "+" && home.length > 10)
            home = `+${obj.homePhone}`;
          if (mobile.charAt(0) !== "+" && mobile.length > 10)
            mobile = `+${obj.mobilePhone}`;
          if (work.charAt(0) !== "+" && work.length > 10)
            work = `+${obj.workPhone}`;

          if (
            home.charAt(0) === "+" &&
            home.charAt(1) !== "1" &&
            home.length > 10
          )
            recepientDataWhatsApp.push(home.replaceAll(" ", "")?.substring(1));
          if (
            mobile.charAt(0) === "+" &&
            mobile.charAt(1) !== "1" &&
            mobile.length > 10
          )
            recepientDataWhatsApp.push(
              mobile.replaceAll(" ", "")?.substring(1)
            );
          if (
            work.charAt(0) === "+" &&
            work.charAt(1) !== "1" &&
            work.length > 10
          )
            recepientDataWhatsApp.push(work.replaceAll(" ", "")?.substring(1));

          if (home.charAt(0) === "+" && home.charAt(1) === "1")
            recepientDataSms.push(home.replaceAll(" ", ""));
          if (mobile.charAt(0) === "+" && mobile.charAt(1) === "1")
            recepientDataSms.push(mobile.replaceAll(" ", ""));
          if (work.charAt(0) === "+" && work.charAt(1) === "1")
            recepientDataSms.push(work.replaceAll(" ", ""));

          try {
            // const inviteContent = await fetchInviteContent();
            // message: `Hi ${obj.firstName}, Just letting you know ${i.candidateFname} ${i.candidateLname} has been invited for the ${i.jobTitle} job.\nClick below to see details.\n${emailDomain}/high5Hire/requests/${i.jobId}\nThe High5 Team `,

            item.forEach(async (i) => {
              if (recepientDataSms.length > 0) {
                const messageContent = createMessageContent(
                  inviteContent,
                  obj,
                  i
                );
                recepientsDataSms.push({
                  recieverNumber: recepientDataSms,
                  userId: Math.floor(Math.random() * 1000000),
                  message: `${messageContent} \n ${inviteContent?.[0]?.footer}`,
                });
              }

              if (recepientDataWhatsApp.length > 0) {
                const messageContent = createMessageContent(
                  inviteContent,
                  obj,
                  i
                );
                recepientsDataWhatsApp.push({
                  recieverNumber: recepientDataWhatsApp,
                  message: `${messageContent} \n ${inviteContent?.[0]?.footer}`,
                });
              }
            });
          } catch (error) {
            console.error("An error occurred:", error);
          }
        }
      });

      if (recepientsDataSms.length > 0)
        await post("/msgtwoway/telSms", recepientsDataSms);
      if (recepientsDataWhatsApp.length > 0)
        await post("/massages/sentMassageWhatsapp", recepientsDataWhatsApp);
    } catch (error) {
      console.error("An error occurred:", error);
      // toast.error("An error occurred");
    }
  };

  const MailMergedAPI = async (payload) => {
    setShareClicked(true);
    try {
      const response = await post("/applyjob/inviteuser", payload);
      if (response.status === 200) {
        if (response.data?.candidateUnsubscribedList?.length > 0) {
          let unSubCandidates = response.data?.candidateUnsubscribedList?.map(
            (x) => x.email
          );
          unSubCandidates.forEach((x) =>
            toast.error(`${x} has unsubscribed from high5`)
          );
          // setLoading(false);
          setShareClicked(false);
        } else if (response.data.message) {
          toast.error(response.data.message);
          setShareClicked(false);
        } else if (response.data.unsubscribed) {
          toast.error(
            "Candidate has unsubscribed for receiving job opportunities."
          );
          // setLoading(false);
          setShareClicked(false);
        } else {
          props.setSuccessFormOpen(true);
          setShareClicked(false);
          await SMS(payload);
          await SMS2(payload);
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      } else {
        setShareClicked(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      setShareClicked(false);
      console.log(error);
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  const handleMailMerge = async (e) => {
    props.setCheckValidation(true);
    if (isValid()) {
      let jobId = props.jobData.jobId;
      let jobTitle = props.jobData.jobTitle;
      let tempObj = [];
      inputList.forEach((item) => {
        const resObj = {
          mailList: {
            ...item,
            candidatePhoneCode: "+" + item.candidatePhoneCode,
            candidatePhone: item.candidatePhone.slice(
              item.candidatePhoneCode.length
            ),
          },
          sendMailLink: sendMailLink,
          jobTitle: jobTitle,
          candidateEmail: item.candidateEmail,
          candidateFname: item.candidateFname,
          candidateLname: item.candidateLname,
          candidatePhone: item.candidatePhone.slice(
            item.candidatePhoneCode.length
          ),
          candidatePhoneCode: "+" + item.candidatePhoneCode,
          jobId: jobId,
          candidateTenant: usertenant,
          recruiterName: loggedinusername,
          recruiterId: `${userid}`,
          tenant_id: `${tenant_id}`,
          recruiterEmail: getUserEmail(),
        };
        tempObj.push(resObj);
      });
      setShareClicked(true);
      MailMergedAPI(tempObj);
    }
  };

  const isValid = () => {
    let returnValue = true;
    for (let items of inputList) {
      if (validator.isEmpty(items?.candidateFname + "")) {
        toast.error("Please enter first name");
        returnValue = false;
        break;
      }
      if (validator.isEmpty(items?.candidateLname + "")) {
        toast.error("Please enter last name");
        returnValue = false;
        break;
      }
      if (validator.isEmpty(items?.candidateEmail + "")) {
        toast.error("Please enter email");
        returnValue = false;
        break;
      } else if (
        !validator.isEmpty(items?.candidateEmail + "") &&
        !validator.isEmail(items?.candidateEmail + "")
      ) {
        toast.error("Please enter a valid Email ID");
        returnValue = false;
        break;
      }
    }
    var valueArr = inputList.map(function(item) {
      return item.candidateEmail;
    });
    var isDuplicate = valueArr.some(function(item, idx) {
      return valueArr.indexOf(item) != idx;
    });
    if (isDuplicate) {
      toast.error("Duplicate email id found");
      return false;
    }
    return returnValue;
  };
  // handle input change
  const handleInputChange = (e, i) => {
    let newArray = [...inputList];
    newArray[i] = {
      ...newArray[i],
      [e.target.name]: e.target.value,
    };
    setInputList(newArray);
  };

  // Handle Phone
  const handlePhoneChange = (value, data, i) => {
    let newArray = [...inputList];
    newArray[i] = {
      ...newArray[i],
      candidatePhoneCode: data.dialCode,
      candidatePhone: value,
    };
    setInputList(newArray);
  };
  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, addedList]);
  };

  const close = () => {
    props.handleSuccess(true);
  };

  useEffect(() => {
    if (
      inputList[0].candidateFname !== "" ||
      inputList[0].candidateLname !== "" ||
      inputList[0].candidateEmail !== ""
    ) {
      props.setIsBulkInvite(false);
    } else {
      props.setIsBulkInvite(true);
    }
  }, [inputList]);

  return (
    <>
      <div className="bl-modal-body ">
        <h6>Invite via Email</h6>
        <label>
          Enter the first, last name & email, and click on the Invite button
          below, If you want to invite a single talent. To invite multiple
          talents, up to three, kindly click on +Add Talent, fill in the
          details, and press the Invite button below.
        </label>
        <br />
        <div className="form-floating">
          <table className="table">
            <thead>
              <tr>
                <th className="pb-0">First Name*</th>
                <th className="pb-0">Last Name*</th>
                <th className="pb-0">Email*</th>
                <th className="pb-0">Phone</th>
                <th className="pb-0"></th>
              </tr>
            </thead>
            <tbody>
              {inputList?.map((x, i) => (
                <tr key={i}>
                  <td className="pt-1">
                    <div className="d-flex form-floating">
                      <input
                        type="text"
                        name="candidateFname"
                        className="form-control"
                        placeholder="First Name"
                        value={x.candidateFname}
                        onChange={(e) => handleInputChange(e, i)}
                      />{" "}
                      <label>First Name*</label>
                    </div>
                  </td>
                  <td className="pt-1">
                    {" "}
                    <div className="d-flex form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="candidateLname"
                        placeholder="Last Name"
                        value={x.candidateLname}
                        onChange={(e) => handleInputChange(e, i)}
                      />
                      <label>Last Name*</label>
                    </div>
                  </td>
                  <td className="pt-1">
                    <div className="d-flex form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="candidateEmail"
                        placeholder="Email"
                        value={x.candidateEmail}
                        onChange={(e) => handleInputChange(e, i)}
                      />{" "}
                      <label>Email*</label>
                    </div>
                  </td>{" "}
                  <td className="pt-1">
                    <div className="d-flex form-floating">
                      <PhoneInput
                        inputStyle={{
                          width: "100%",
                          height: 60,
                        }}
                        required="true"
                        placeholder="Phone number"
                        country={"us"}
                        id="phoneNumber"
                        value={x.candidatePhone}
                        name="phoneNumber"
                        onChange={(value, data) =>
                          handlePhoneChange(value, data, i)
                        }
                      />
                    </div>
                  </td>
                  <td
                    style={{ textAlign: "end", marginTop: "40px" }}
                    className="pt-1"
                  >
                    {inputList.length > 1 && (
                      <i
                        onClick={() => handleRemoveClick(i)}
                        className="fal fa-fw fa-trash-alt"
                        aria-hidden="true"
                      ></i>
                    )}
                  </td>
                  <br />
                </tr>
              ))}
            </tbody>
          </table>
          {inputList.length === 3 && (
            <p>
              Note: If you want to invite more talent, please use the Bulk
              Invite option below.
            </p>
          )}

          <br />
          <div className="mt-2">
            <button
              className="btn btn-sm btn-text"
              style={{ marginTop: "8px" }}
              disabled={inputList.length > 2 || props.bulkUpload}
              onClick={handleAddClick}
            >
              <i className="fal fa-plus mr-1"></i>
              Add Talent
            </button>
            {shareClicked === false ? (
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginRight: "10px" }}
                disabled={props.bulkUpload}
                onClick={handleMailMerge}
              >
                <span>Invite</span>
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                style={{ marginRight: "10px" }}
              >
                <span>
                  Sending
                  <img width="20px" src={imageLoader} alt="blank" />
                </span>
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteTalent;
