import React from "react";

const TalentTypes = (props) => {
  const { selectedTab, onCandidateAddTypeClick } = props;
  return (
    <>
      <div class="row">
        <div
          class="col-12 d-flex flex-column flex-lg-row"
          style={{ padding: "25px 30px 30px 40px" }}
        >
          <button
            onClick={() => {
              onCandidateAddTypeClick("doc");
            }}
            type="button"
            class={
              selectedTab.docSelected
                ? "card card-flat p-0 mr-2 mb-2 mb-lg-0  selected"
                : "card card-flat p-0 mr-2 mb-2 mb-lg-0"
            }
          >
            <div class="card-body text-left text-left">
              <div class="d-flex">
                {selectedTab.docSelected ? (
                  <>
                    <div class="mr-3 font-green font-16">
                      <i class="fad fa-check-circle"></i>
                    </div>
                  </>
                ) : (
                  <div class="mr-3 font-muted font-16">
                    <i class="fad fa-circle"></i>
                  </div>
                )}
                <div>
                  <div>
                    <strong class="font-blue_primary">Upload Resumes</strong>
                  </div>
                  <p class="mb-0">
                    {" "}
                    Upload candidate resumes, Accepted File Type: .doc,.docx,and
                    .pdf
                  </p>
                </div>
              </div>
            </div>
          </button>

          <button
            type="button"
            onClick={() => {
              onCandidateAddTypeClick("excel");
            }}
            class={
              selectedTab.excelSelected
                ? "card card-flat p-0 mr-2 mb-2 mb-lg-0  selected"
                : "card card-flat p-0 mr-2 mb-2 mb-lg-0"
            }
          >
            <div class="card-body text-left">
              <div class="d-flex">
                {selectedTab.excelSelected ? (
                  <div class="mr-3 font-green font-16">
                    <i class="fad fa-check-circle"></i>
                  </div>
                ) : (
                  <div class="mr-3 font-muted font-16">
                    <i class="fad fa-circle"></i>
                  </div>
                )}
                <div>
                  <div>
                    <strong class="font-blue_primary">
                      Bulk Upload With Excel
                    </strong>
                  </div>
                  <p class="mb-0">
                    Use an Excel template to upload multiple talented candidates
                    at once
                  </p>
                </div>
              </div>
            </div>
          </button>
          {/* <button
            type="button"
            onClick={() => {
              onCandidateAddTypeClick("bulkResume");
            }}
            class={
              selectedTab.resumeBulkSelected
                ? "card card-flat p-0 mr-2 mb-2 mb-lg-0  selected"
                : "card card-flat p-0 mr-2 mb-2 mb-lg-0"
            }
          >
            <div class="card-body text-left">
              <div class="d-flex">
                {selectedTab.resumeBulkSelected ? (
                  <div class="mr-3 font-green font-16">
                    <i class="fad fa-check-circle"></i>
                  </div>
                ) : (
                  <div class="mr-3 font-muted font-16">
                    <i class="fad fa-circle"></i>
                  </div>
                )}
                <div>
                  <div>
                    <strong class="font-blue_primary">
                      Bulk Upload Resume
                    </strong>
                  </div>
                  <p class="mb-0">Upload upto 10 resume at a time.</p>
                </div>
              </div>
            </div>
          </button> */}
          <button
            onClick={() => {
              onCandidateAddTypeClick("manual");
            }}
            type="button"
            class={
              selectedTab.manualSelected
                ? "card card-flat p-0 mr-2 mb-2 mb-lg-0  selected"
                : "card card-flat p-0 mr-2 mb-2 mb-lg-0"
            }
          >
            <div class="card-body text-left">
              <div class="d-flex">
                {selectedTab.manualSelected ? (
                  <div class="mr-3 font-green font-16">
                    <i class="fad fa-check-circle"></i>
                  </div>
                ) : (
                  <div class="mr-3 font-muted font-16">
                    <i class="fad fa-circle"></i>
                  </div>
                )}
                <div>
                  <div>
                    <strong class="font-blue_primary">Manual Entry</strong>
                  </div>
                  <p class="mb-0">Single talent manual entry</p>
                </div>
              </div>
            </div>
          </button>
        </div>
      </div>
    </>
  );
};
export default TalentTypes;
