import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import UserOverview from "./UserOverview";
import Index from "./bulkdeactivate/Index";
import { getRecruiterData } from "../../../../../actions/executiveadmin/RecruiterData";
import { getUserRole } from "../../../../../service/AuthService";
import {
  getLoginOverview,
  getLoginOverviewCount,
} from "../../../../../actions/dashboard/LoginOverview";
import Loader from "../../../commoncomponents/Loader/Loader";

const LoginOverview = ({ getRecruiters }) => {
  const numberFormatter = new Intl.NumberFormat("en-US");
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [active, setActive] = useState("Total");
  const [dataTotal, setDataTotal] = useState(0);
  const [dataSize, setDataSize] = useState(10);
  const [bulkDeactivate, setBulkDeactivate] = useState(false);
  const [recruiterChange, setRecruiterChange] = useState(0);
  const [searchData, setSearchData] = useState("");
  const isFirstRender = useRef(true);
  const [sortArray, setSortArray] = useState([]);

  useEffect(() => {
    fetchRecruiterData("Total", 1, 10, "", sortArray);
    const payload = {
      searchText: "",
    };
    dispatch(getLoginOverviewCount(payload));
  }, []);

  const {
    loginOverviewData,
    loginOverviewCount,
    loading,
    countLoading,
  } = useSelector(
    (state) => ({
      loginOverviewData: state.getLoginOverview.recruiterData,
      loginOverviewCount: state.getLoginOverview.loginOverviewCount,
      loading: state.getLoginOverview.loading,
      countLoading: state.getLoginOverview.countLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    setCurrentPage(1);
    if (loginOverviewCount.length > 0) {
      let count = loginOverviewCount.filter((item) => item.Type == "Total")[0]
        ?.NumberOfUsersCreated;
      setRecruiterChange(count);
    }
    if (active == "Total") {
      loginOverviewCount.length > 0 && setDataTotal(getCount("Total"));
      setDataSize(10);
    } else if (active == "Hyperactive") {
      loginOverviewCount.length > 0 && setDataTotal(getCount("Hyperactive"));
      setDataSize(10);
    } else if (active == "Active") {
      loginOverviewCount.length > 0 && setDataTotal(getCount("Active"));
      setDataSize(10);
    } else if (active == "Inactive") {
      loginOverviewCount.length > 0 && setDataTotal(getCount("Inactive"));
      setDataSize(10);
    } else if (active == "Unsubscribed") {
      loginOverviewCount.length > 0 && setDataTotal(getCount("Unsubscribed"));
      setDataSize(10);
    } else {
      loginOverviewCount.length > 0 && setDataTotal(getCount("Others"));
      setDataSize(10);
    }
  }, [loginOverviewCount, active]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchData.length === 0 || searchData.length >= 2) {
        fetchRecruiterData(active, 1, dataSize, searchData, sortArray);
        const payload = {
          searchText: searchData,
        };
        dispatch(getLoginOverviewCount(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchData]);

  const tabChange = (status) => {
    setCurrentPage(1);
    setActive(status);
    if (status == "Total") {
      fetchRecruiterData("Total", 1, 10, searchData, []);
    } else if (status == "Hyperactive") {
      fetchRecruiterData("Hyperactive", 1, 10, searchData, []);
    } else if (status == "Active") {
      fetchRecruiterData("Active", 1, 10, searchData, []);
    } else if (status == "Inactive") {
      fetchRecruiterData("Inactive", 1, 10, searchData, []);
    } else if (status == "Unsubscribed") {
      fetchRecruiterData("Unsubscribed", 1, 10, searchData, []);
    } else {
      fetchRecruiterData("Others", 1, 10, searchData, []);
    }
  };

  const fetchRecruiterData = (tab, pageNumber, limit, searchVal, sortArray) => {
    const payload = {
      tab: tab,
      currentPage: pageNumber,
      limit: limit,
      searchText: searchVal,
      sortQuery: sortArray,
    };
    dispatch(getLoginOverview(payload));
  };

  const getCount = (status) => {
    let count = 0;
    if (loginOverviewCount.length > 0) {
      count = loginOverviewCount.filter((item) => item.Type == status)[0]
        ?.Count;
    }
    return count;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchRecruiterData(active, pageNumber, dataSize, searchData, sortArray);
  };

  const handleSizeChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
    fetchRecruiterData(active, 1, ds, searchData, sortArray);
  };

  const closeModal = (val) => {
    if (val) {
      setBulkDeactivate(false);
      dispatch(getRecruiterData());
    } else {
      setBulkDeactivate(false);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
  };

  const applySort = (sortedData) => {
    setCurrentPage(1);
    fetchRecruiterData(active, 1, dataSize, searchData, sortedData);
  };

  return (
    <div>
      {(countLoading || loading) && <Loader />}
      <div className=" card">
        <div className="card-body  p-4 ">
          <div className="d-flex justify-content-between align-items-end mb-4 border-bottom">
            <h6>Login Overview</h6>
            {getUserRole() == "CommunityManager" && (
              <button
                type="button"
                class="btn btn-sm mb-1 btn-danger"
                onClick={() => setBulkDeactivate(true)}
              >
                Bulk Deactivate
              </button>
            )}
          </div>
          <div className="d-flex gap-5 overflow-auto justify-content-between">
            <a
              href="#"
              className={`d-flex flex-column gap-1  ${
                active === "Total" ? "" : " font-light font-black"
              }`}
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Total count of recruiters registered, excluding unsubscribed, never logged-in, and deactivated recruiters.
              Total Recruiter= Sum [Hyperactive+Active+Inactive] Recruiters"
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Total");
              }}
            >
              <div>
                <span className="tag ">
                  {numberFormatter.format(getCount("Total"))}
                </span>
              </div>
              <span className="">Total recruiters</span>
              <div
                className={
                  recruiterChange > 0
                    ? "text-left font-green"
                    : "text-left font-red"
                }
              >
                <p className="mb-0">
                  {recruiterChange > 0 && (
                    <small>{`+${recruiterChange}% in last 30 days`}</small>
                  )}
                  {recruiterChange === 0 && (
                    <small>{`No changes in last 30 days`}</small>
                  )}
                  {recruiterChange < 0 && (
                    <small>{`${recruiterChange} in last 30 days`}</small>
                  )}
                </p>
              </div>
            </a>
            <a
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Hyperactive" ? "" : " font-light font-black"
              }`}
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Recruiters who have invited Talent are called Hyperactive."
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Hyperactive");
              }}
            >
              <div>
                <div>
                  <span className="tag">
                    {numberFormatter.format(getCount("Hyperactive"))}
                  </span>
                </div>
              </div>
              <span className="">Hyperactive</span>
            </a>
            <a
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Active" ? "" : " font-light font-black"
              }`}
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Recruiters who have logged in once in the last 3 weeks"
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Active");
              }}
            >
              <div>
                <span className="tag">
                  {numberFormatter.format(getCount("Active"))}
                </span>
              </div>
              <span className="">Active</span>
            </a>{" "}
            <a
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Inactive" ? "" : " font-light font-black"
              }`}
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Recruiters who have not logged in the last 3 weeks."
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Inactive");
              }}
            >
              <div>
                <span className="tag">
                  {numberFormatter.format(getCount("Inactive"))}
                </span>
              </div>
              <span className="">Inactive</span>
            </a>
            <a
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Unsubscribed" ? "" : " font-light font-black"
              }`}
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Recruiters who have opted out or unsubscribed from receiving any communications or are deactivated for any policy violation or extended periods of inactivity"
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Unsubscribed");
              }}
            >
              <div>
                <span className="tag">
                  {numberFormatter.format(getCount("Unsubscribed"))}
                </span>
              </div>
              <span className="">Unsubscribed</span>{" "}
            </a>
            <a
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Others" ? "" : " font-light font-black"
              }`}
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="The approved Recruiters who have never logged in to High5"
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Others");
              }}
            >
              <div>
                <span className="tag">
                  {numberFormatter.format(getCount("Others"))}
                </span>
              </div>
              <span className="">Never Logged In</span>
            </a>
          </div>
        </div>
      </div>
      <UserOverview
        isFrom={"LoginOverview"}
        userData={loginOverviewData}
        prefix={active}
        getRecruiters={getRecruiters}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        handleSizeChange={handleSizeChange}
        dataTotal={dataTotal}
        dataSize={dataSize}
        handleSearch={handleSearch}
        searchData={searchData}
        sortArray={sortArray}
        setSortArray={setSortArray}
        applySort={applySort}
        fetchRecruiterData={fetchRecruiterData}
      />
      {bulkDeactivate && (
        <Index
          bulkDeactivate={bulkDeactivate}
          setBulkDeactivate={setBulkDeactivate}
          closeModal={closeModal}
        />
      )}
    </div>
  );
};

export default LoginOverview;
