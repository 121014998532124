import React, { useState, useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SMSconfirm from "./SMSConfirm";
import ShortUniqueId from "short-unique-id";
import { post } from "../../../../service/ApiService";
import { getUserRole, getUserTenantID } from "../../../../service/AuthService";
import { useLocation } from "react-router-dom";
import Loader from "../../../Loader/Loader";
import { Button, Col, Row, Container } from "react-bootstrap";
import { FaCommentAlt, FaUserPlus } from "react-icons/fa";
import AddRecContactsModal from "./AddRecContactsModal";
import TopMenu from "./TopMenu";
import SendSMSBox from "./SendSMSBox";
import SMSTemplates from "./SMSTemplates";
import { FcTemplate } from "react-icons/fc";
import { CgTemplate } from "react-icons/cg/index.esm";
import ReactQuill from "react-quill";
import AddCandContactsModal from "./AddCandContactsModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { updateJob } from "../../../../actions/jobList/jobList";
import ModalView from "../../commoncomponents/modal/Modal";

const BroadcastSMSUI = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { selectedJob } = useSelector(
    (state) => ({
      selectedJob: state.getJobListReducer.selectedJob,
    }),
    shallowEqual
  );
  const uid = new ShortUniqueId({ length: 10 });
  const location = useLocation();
  const data = location.state;
  const role = getUserRole();
  const [loading, setLoading] = useState(false);
  const [jobModal, setJobModal] = useState(false);
  const [selectResStatus, setSelectResStatus] = useState(false);
  const [phone, setPhone] = useState({});
  const [confirmation, setConfirmation] = useState(false);
  const [contactSelected, setContactSelected] = useState([]);
  const [recipientDetails, setRecipientDetails] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [message, setMessage] = useState("");
  const [remainingCharacters, setRemainingCharacters] = useState(140);
  const [showModal, setShowModal] = useState(false);
  const [filterType, setFilterType] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [validation, setValidation] = useState(false);
  const [selectedOption, setSelectedOption] = useState("compose");
  const [activeTab, setActiveTab] = useState("compose");
  const [errorData, setErrorData] = useState({});
  const [content, setContent] = useState("");
  const [subject, setSubject] = useState("");
  const [ViewAllContactsList, setViewAllContactsList] = useState(false);
  useEffect(() => {
    if (data?.contactData?.length > 0) {
      setFilterType("addCandidate");
      let filteredData = [];
      filteredData = data?.contactData?.map((item) => {
        if (
          item?.homePhoneCode &&
          item?.homePhoneCode !== null &&
          item?.homePhoneCode !== "" &&
          item?.homePhone &&
          item?.homePhone !== null &&
          item?.homePhone !== ""
        )
          return item;
      });
      let filteredArray1 = [];
      filteredData.forEach((item) => {
        if (item) {
          filteredArray1.push(item);
        }
      });
      setRecipientDetails([...filteredArray1]);
      setContactSelected([...filteredArray1]);
    }
  }, [data?.contactData]);
  // useEffect(() => {
  //   if (contactSelected.length === 0) {
  //     setFilterType("");
  //   }
  // }, [contactSelected]);
  useEffect(() => {
    setFilterType("addCandidate");
  }, []);
  useEffect(() => {
    if (selectedTemplate !== null) {
      setContent(selectedTemplate?.mailContent + selectedTemplate?.signature);
      setSubject(selectedTemplate?.subject);
    }
  }, [selectedTemplate]);
  const handleSend = async () => {
    const plainTextContent = content.replace(/<[^>]*>/g, "");
    let contacts = contactSelected.map((item) => {
      if (filterType === "addCandidate") {
        return {
          phone: `${item.homePhoneCode}${item.homePhone}`,
          email: item.email,
        };
      } else {
        return {
          phone: `${item.mobilePhoneCode}${item.mobilePhone}`,
          email: item.email,
        };
      }
    });
    const data = {
      recipients: [...contacts],
      messageContent: plainTextContent,
      messageSubject: subject,
      userId: localStorage.getItem("userid"),
      broadCastId: uid(),
      fk_tenant: getUserTenantID(),
      userType: filterType === "addCandidate" ? "candidate" : "recruiter",
    };
    try {
      setLoading(true);
      const response = await post("/smsbroadcast/sendSms", data);
      if (response.status === 200) {
        toast.success("SMS Sent Successfully");
        handleClear();
        setConfirmation(false);
        setSelectedTemplate(null);
        setLoading(false);
        setContent("");
        setSubject("");
        setContactSelected([]);
        dispatch(updateJob([]));
        history.replace({ ...location, state: null });
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const handleClear = () => {
    setContent("");
    setRemainingCharacters(140);
  };
  const validationFunction = () => {
    let errorObj = {};
    let isValid = true;
    if (content === "") {
      errorObj["content"] = "Please enter the message";
      isValid = false;
    }
    if (subject === "") {
      errorObj["subject"] = "Please enter message subject ";
      isValid = false;
    }
    if (contactSelected.length === 0) {
      errorObj["recipient"] = "Please enter at least one recipient";
      isValid = false;
    }
    if (
      location?.state?.isFrom !== "talentCommunity" &&
      getUserRole() === "HiringManager" &&
      selectedJob &&
      Object.keys(selectedJob).length === 0
    ) {
      errorObj["selectJob"] = "Choose any Job";
      isValid = false;
    }
    setErrorData(errorObj);
    return isValid;
  };
  const handleValidation = () => {
    let isValidObj = validationFunction();
    if (isValidObj) {
      setValidation(true);
      setConfirmation(true);
      setErrorData({});
    } else {
      setValidation(false);
      toast.error("Please  fill the mandatory fields");
    }
  };
  const handleContentChange = (value) => {
    setContent(value);
  };
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };
  useEffect(() => {
    dispatch(updateJob([]));
  }, []);
  // useEffect(() => {
  //   if (
  //     location?.state?.isFrom === "talentCommunity" &&
  //     contactSelected.length === 0
  //   ) {
  //     history.replace({
  //       ...location,
  //       state: null,
  //     });
  //   }
  // }, [contactSelected]);

  return (
    <Container>
      {loading && <Loader />}
      <TopMenu
        onSelectOption={setSelectedOption}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <Row style={{ minHeight: "60vh", fontSize: "14px" }}>
        {selectedOption === "compose" && (
          <Col md={12} sm={12}>
            <div className="mb-5 mt-5">
              <>
                {location?.state?.isFrom !== "talentCommunity" &&
                  getUserRole() === "HiringManager" &&
                  contactSelected?.length === 0 && (
                    <>
                      <div className="mt-2 mb-5">
                        <button
                          type="btn btn-primary btn-sm mb-2"
                          style={{
                            backgroundColor: "#17a2b8",
                            color: "white",
                            border: "0px",
                          }}
                          onClick={() => {
                            setJobModal(true);
                          }}
                        >
                          Select Job
                        </button>
                        <div className="color">{errorData["selectJob"]}</div>
                      </div>
                    </>
                  )}
              </>
              {Object.keys(selectedJob).length !== 0 &&
                getUserRole() === "HiringManager" && (
                  <div className="d-flex align-items-center mb-5">
                    <br />
                    <div className="d-block">
                      {selectedJob && (
                        <span
                          key={selectedJob.jobId}
                          className="tag tag-blue2 p-1 px-2 m-1"
                        >
                          <span>{selectedJob.jobTitle}</span>{" "}
                          <a
                            href="#"
                            className="font-black"
                            onClick={(e) => {
                              dispatch(updateJob([]));
                            }}
                          >
                            <i className="fas fa-times ml-2 "></i>
                          </a>
                        </span>
                      )}
                      <button
                        type="button"
                        className="btn btn-text py-1 "
                        onClick={() => {
                          history.replace({ ...location, state: null });
                          dispatch(updateJob([]));

                          setSelectResStatus(true);
                        }}
                      >
                        <span className="font-bold font-14">Clear All</span>
                      </button>
                    </div>
                  </div>
                )}
              {contactSelected && contactSelected.length > 0 ? (
                <div className="mt-3">
                  <label>
                    Selected{" "}
                    {`${
                      filterType === "addRecruiter"
                        ? contactSelected?.length > 1
                          ? "recipients"
                          : "recipient"
                        : ""
                    }`}
                    {`${
                      filterType === "addCandidate"
                        ? contactSelected?.length > 1
                          ? "recipients"
                          : "recipient"
                        : ""
                    }`}
                    {contactSelected && contactSelected?.length !== 1
                      ? " : " + contactSelected?.length
                      : ""}
                  </label>

                  <div className="d-flex align-items-center">
                    <div className="d-block">
                      {filterType && filterType === "addCandidate" ? (
                        <>
                          {contactSelected?.length > 0 &&
                            contactSelected.slice(0, 10).map((item) => {
                              return (
                                <>
                                  <span className="tag tag-blue2 p-1 px-2 m-1 ">
                                    <span>{`${item?.homePhoneCode +
                                      " " +
                                      item?.homePhone}`}</span>{" "}
                                    <custom className="font-black">
                                      <i
                                        className="fas fa-times ml-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          let contacts = contactSelected.filter(
                                            (item2) =>
                                              `${item2?.homePhoneCode}${item2?.homePhone}` !==
                                              `${item?.homePhoneCode}${item?.homePhone}`
                                          );
                                          if (
                                            location?.state?.isFrom ===
                                              "talentCommunity" &&
                                            contactSelected.length === 1
                                          ) {
                                            history.replace({
                                              ...location,
                                              state: null,
                                            });
                                          }
                                          setContactSelected([...contacts]);
                                        }}
                                      ></i>
                                    </custom>
                                  </span>
                                </>
                              );
                            })}
                          {contactSelected?.length > 10 &&
                            ViewAllContactsList === true &&
                            contactSelected
                              .slice(10, contactSelected?.length)
                              .map((item) => {
                                return (
                                  <>
                                    <span className="tag tag-blue2 p-1 px-2 m-1 ">
                                      <span>{`${
                                        item?.homePhoneCode !== null || ""
                                          ? item?.homePhoneCode +
                                            " " +
                                            item?.homePhone
                                          : item?.homePhone
                                      }`}</span>{" "}
                                      <custom className="font-black">
                                        <i
                                          className="fas fa-times ml-2"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            let contacts = contactSelected.filter(
                                              (item2) =>
                                                `${item2?.homePhoneCode}${item2?.homePhone}` !==
                                                `${item?.homePhoneCode}${item?.homePhone}`
                                            );
                                            if (
                                              location?.state?.isFrom ===
                                                "talentCommunity" &&
                                              contactSelected.length === 1
                                            ) {
                                              history.replace({
                                                ...location,
                                                state: null,
                                              });
                                            }
                                            setContactSelected([...contacts]);
                                          }}
                                        ></i>
                                      </custom>
                                    </span>
                                  </>
                                );
                              })}
                        </>
                      ) : (
                        <>
                          {contactSelected?.length > 0 &&
                            contactSelected.slice(0, 10).map((item) => {
                              return (
                                <>
                                  <span className="tag tag-blue2 p-1 px-2 m-1 ">
                                    <span>{`${item?.mobilePhoneCode}${item?.mobilePhone}`}</span>{" "}
                                    <custom className="font-black">
                                      <i
                                        className="fas fa-times ml-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          let contacts = contactSelected.filter(
                                            (item2) =>
                                              `${item2?.mobilePhoneCode}${item2?.mobilePhone}` !==
                                              `${item?.mobilePhoneCode}${item?.mobilePhone}`
                                          );
                                          setContactSelected([...contacts]);
                                        }}
                                      ></i>
                                    </custom>
                                  </span>
                                </>
                              );
                            })}
                          {contactSelected?.length > 10 &&
                            ViewAllContactsList === true &&
                            contactSelected
                              .slice(10, contactSelected?.length)
                              .map((item) => {
                                return (
                                  <>
                                    <span className="tag tag-blue2 p-1 px-2 m-1 ">
                                      <span>{`${item?.mobilePhoneCode}${item?.mobilePhone}`}</span>{" "}
                                      <custom className="font-black">
                                        <i
                                          className="fas fa-times ml-2"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            let contacts = contactSelected.filter(
                                              (item2) =>
                                                `${item2?.mobilePhoneCode}${item2?.mobilePhone}` !==
                                                `${item?.mobilePhoneCode}${item?.mobilePhone}`
                                            );

                                            setContactSelected([...contacts]);

                                            setSelectResStatus(true);
                                          }}
                                        ></i>
                                      </custom>
                                    </span>
                                  </>
                                );
                              })}
                        </>
                      )}
                      {contactSelected?.length > 10 && (
                        <button
                          onClick={(e) => {
                            setViewAllContactsList(!ViewAllContactsList);
                          }}
                          type="button"
                          class="btn btn-text p-1"
                        >
                          {!ViewAllContactsList ? (
                            <span className="font-bold font-14">
                              +{contactSelected?.length - 10} more
                            </span>
                          ) : (
                            <span className="font-bold font-14">Show Less</span>
                          )}
                        </button>
                      )}
                      <br />
                      <button
                        type="button"
                        className="btn btn-text py-1 "
                        onClick={() => {
                          setContactSelected([]);
                          if (location?.state?.isFrom === "talentCommunity") {
                            history.replace({ ...location, state: null });
                          }
                          setSelectResStatus(true);
                        }}
                      >
                        <span className="font-bold font-14">Clear</span>
                      </button>
                    </div>
                  </div>
                </div>
              ) : null}

              {contactSelected && contactSelected?.length === 0 && (
                <>
                  <div className="ml-0 mb-2 mt-2">Add Recipients*</div>
                  <Row className="d-flex justify-content-start">
                    <Col md={12} sm={12}>
                      <Button
                        className="recipient-button mr-2 mt-2"
                        onClick={() => {
                          setShowModal(true);
                          setFilterType("addRecruiter");
                          setModalTitle("Add Recruiter");
                        }}
                        style={{
                          backgroundColor: "#17a2b8",
                          color: "white",
                          border: "none",
                        }}
                        disabled={
                          (role === "HiringManager" &&
                            Object.keys(selectedJob).length === 0) ||
                          (contactSelected?.length > 0 &&
                            filterType === "addCandidate")
                        }
                      >
                        <FaUserPlus className="mr-2" /> Recruiter
                      </Button>
                      <Button
                        className="recipient-button mr-2 mt-2"
                        style={{
                          backgroundColor: "#17a2b8",
                          color: "white",
                          border: "none",
                        }}
                        disabled={
                          (role === "HiringManager" &&
                            Object.keys(selectedJob).length === 0) ||
                          (contactSelected?.length > 0 &&
                            filterType === "addRecruiter")
                        }
                        onClick={() => {
                          setShowModal(true);
                          setFilterType("addCandidate");
                          setModalTitle("Add Candidate");
                        }}
                      >
                        <FaUserPlus className="mr-2" /> Candidate
                      </Button>
                    </Col>
                  </Row>
                </>
              )}

              <div className="color">{errorData["recipient"]}</div>
              <Row className="messageForm">
                {/* <Col md={4} className="mt-2 d-flex justify-content-end ml-auto">
                <Button
                  onClick={() => {
                    setActiveTab("templates");
                    setSelectedOption("templates");
                  }}
                  style={{
                    backgroundColor: "#17a2b8",
                    color: "white",
                    border: "none",
                  }}
                  disabled={selectedTemplate !== null}
                >
                  <FaCommentAlt className="mr-2" /> Choose Template
                </Button>
              </Col> */}
                <Col sm={12} md={12}>
                  <label htmlFor="subject" className="label mb-2">
                    Subject*
                  </label>
                  <input
                    type="text"
                    value={subject}
                    className="form-control"
                    style={{ fontSize: "14px" }}
                    onChange={handleSubjectChange}
                  />
                  <div className="color">{errorData["subject"]}</div>
                </Col>
                <Col sm={12} md={12}>
                  <label htmlFor="message" className="label mb-2">
                    Message*
                  </label>
                  <ReactQuill
                    value={content}
                    onChange={handleContentChange}
                    style={{ fontWeight: "normal" }}
                  />
                  <div className="color">{errorData["content"]}</div>
                </Col>
              </Row>
              <div className="messageForm d-flex justify-content-between mt-3">
                <button
                  className="py-1"
                  style={{
                    backgroundColor: "#0073e6",
                    border: "none",
                  }}
                  onClick={() => {
                    setSelectedTemplate(null);
                    setMessage("");
                    setContent("");
                    setSubject("");
                    setErrorData({});
                    setContactSelected([]);
                  }}
                >
                  <i className="fa fa-eraser"></i> Clear
                </button>
                <button
                  className="mr-4 btn-primary"
                  disabled={
                    subject === "" ||
                    content === "" ||
                    contactSelected.length === 0 ||
                    (location?.state?.isFrom !== "talentCommunity" &&
                      getUserRole() === "HiringManager" &&
                      selectedJob &&
                      Object.keys(selectedJob).length === 0)
                  }
                  onClick={() => {
                    handleValidation();
                  }}
                >
                  <FaCommentAlt className="mr-2" /> Send SMS
                </button>
              </div>
              {showModal && filterType === "addRecruiter" && (
                <AddRecContactsModal
                  setSelectResStatus={setSelectResStatus}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  filterType={filterType}
                  setFilterType={setFilterType}
                  modalTitle={modalTitle}
                  contactSelected={contactSelected}
                  setContactSelected={setContactSelected}
                />
              )}
              {showModal && filterType === "addCandidate" && (
                <AddCandContactsModal
                  setSelectResStatus={setSelectResStatus}
                  showModal={showModal}
                  setShowModal={setShowModal}
                  filterType={filterType}
                  setFilterType={setFilterType}
                  modalTitle={modalTitle}
                  contactSelected={contactSelected}
                  setContactSelected={setContactSelected}
                />
              )}
              {confirmation && (
                <SMSconfirm
                  phoneNumbers={
                    contactSelected &&
                    contactSelected.map((item) => {
                      return `${item.mobilePhoneCode}${item.mobilePhone}`;
                    })
                  }
                  // message={""}
                  message={content}
                  handleSend={handleSend}
                  confirmation={confirmation}
                  setConfirmation={setConfirmation}
                />
              )}
            </div>
          </Col>
        )}
        {selectedOption === "sent" && (
          <Col md={12} sm={12}>
            <SendSMSBox />
          </Col>
        )}
        {selectedOption === "templates" && (
          <Col md={12} sm={12}>
            <SMSTemplates
              data={{
                setActiveTab: setActiveTab,
                setSelectedOption: setSelectedOption,
                selectedTemplate: selectedTemplate,
                setSelectedTemplate: setSelectedTemplate,
              }}
            />
          </Col>
        )}
        {jobModal && (
          <ModalView
            modalSize={"xl"}
            modalTitle={"Select Job"}
            isModalOpen={jobModal}
            setModalClose={setJobModal}
            isFrom="SMSBroadcast"
            modalFor="selectJob"
          />
        )}
      </Row>
    </Container>
  );
};

export default BroadcastSMSUI;
