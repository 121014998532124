import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const RecruiterLoginAnalytics = (props) => {
  const {
    Recruiter_daily,
    Recruiter_monthly,
    Recruiter_total,
    Recruiter_weekly,
  } = props.data;
  const [recruitersTimeInterval, setRecruitersTimeInterval] = useState("daily");
  const [loggedInRecruiters, setLoggedInRecruiters] = useState(0);
  const [loggedInCount, setLoggedInCount] = useState(0);
  const [chartSeries, setChartSeries] = useState([
    {
      name: "Recruiters Login",
      data: [],
      color: "#55c3c3",
    },
  ]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
    },
    dataLabels: {
      enabled: false,
    },
  });
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day} ${month}`;
  }

  const generateChartData = (timeInterval) => {
    let categories = [];
    let data = [];
    let count = 0;
    if (timeInterval === "daily") {
      categories = Recruiter_daily?.map((item) => formatDate(item.category));
      data = Recruiter_daily?.map((item) => item.data);
      Recruiter_daily?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInRecruiters(
        Recruiter_daily && Recruiter_daily[Recruiter_daily?.length - 1].data
      );
    } else if (timeInterval === "weekly") {
      categories = Recruiter_weekly?.map((item) => formatDate(item.category));
      data = Recruiter_weekly?.map((item) => item.data);
      Recruiter_weekly?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInRecruiters(
        Recruiter_weekly && Recruiter_weekly[Recruiter_weekly?.length - 1].data
      );
    } else if (timeInterval === "monthly") {
      categories = Recruiter_monthly?.map((item) => item.category);
      data = Recruiter_monthly?.map((item) => item.data);
      Recruiter_monthly?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInRecruiters(
        Recruiter_monthly &&
          Recruiter_monthly[Recruiter_monthly?.length - 1].data
      );
    }

    setChartSeries([
      {
        name: "Recruiters Login",
        data: data,
      },
    ]);
    setChartOptions({
      ...chartOptions,
      xaxis: {
        categories: categories,
      },
    });
  };

  useEffect(() => {
    generateChartData(recruitersTimeInterval);
  }, [recruitersTimeInterval]);

  useEffect(() => {
    generateChartData("daily");
  }, []);
  return (
    <>
      {/* Recruiters Login*/}
      <div className="card analyticCards custom-card">
        <h6 className="text-center mt-3 mb-3">Recruiters Login</h6>
        <div class="d-flex flex-wrap justify-content-center">
          <button
            onClick={() => setRecruitersTimeInterval("daily")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                recruitersTimeInterval === "daily" ? "#17a2b8" : "white",
              color: recruitersTimeInterval === "daily" ? "white" : "#17a2b8",
            }}
          >
            Daily
          </button>
          <button
            onClick={() => setRecruitersTimeInterval("weekly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                recruitersTimeInterval === "weekly" ? "#17a2b8" : "white",
              color: recruitersTimeInterval === "weekly" ? "white" : "#17a2b8",
            }}
          >
            Weekly
          </button>
          <button
            onClick={() => setRecruitersTimeInterval("monthly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                recruitersTimeInterval === "monthly" ? "#17a2b8" : "white",
              color: recruitersTimeInterval === "monthly" ? "white" : "#17a2b8",
            }}
          >
            Monthly
          </button>
        </div>

        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={250}
        />
        <div className="card m-2 align-items-center">
          <p className="mt-2 fw-light">
            {`Total Recruiter${
              Recruiter_total !== 1 ? "s" : ""
            }: ${Recruiter_total}`}
          </p>
          <p className="fw-light">
            {`Logged In Recruiter${
              loggedInCount !== 1 ? "s" : ""
            }: ${loggedInCount}`}
          </p>
        </div>
      </div>
    </>
  );
};

export default RecruiterLoginAnalytics;
