import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const GET_RECRUITER_FOR_UPDATE_CURATOR_BEGIN =
  "GET_RECRUITER_FOR_UPDATE_CURATOR_BEGIN";
export const GET_RECRUITER_FOR_UPDATE_CURATOR_SUCCESS =
  "GET_RECRUITER_FOR_UPDATE_CURATOR_SUCCESS";
export const GET_RECRUITER_FOR_UPDATE_CURATOR_FAILURE =
  "GET_RECRUITER_FOR_UPDATE_CURATOR_FAILURE";

export const GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_BEGIN =
  "GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_BEGIN";
export const GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_SUCCESS =
  "GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_SUCCESS";
export const GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_FAILURE =
  "GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_FAILURE";

export const REMOVE_CURATOR_BEGIN =
  "REMOVE_CURATOR_BEGIN";
export const REMOVE_CURATOR_SUCCESS =
  "REMOVE_CURATOR_SUCCESS";
export const REMOVE_CURATOR_FAILURE =
  "REMOVE_CURATOR_FAILURE";

export const getUpdateCurator = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECRUITER_FOR_UPDATE_CURATOR_BEGIN,
    });
    const res = await post("/curator/getrecruiterdataforupdatecurator", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_RECRUITER_FOR_UPDATE_CURATOR_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RECRUITER_FOR_UPDATE_CURATOR_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getUpdateCuratorCount = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_BEGIN,
    });
    const res = await post("/curator/getrecruitercountforupdatecurator", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RECRUITER_FOR_UPDATE_CURATOR_COUNT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const removeCuratorFromCommunity = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: REMOVE_CURATOR_BEGIN,
    });
    const res = await post("/curator/removecurator", payload);
    if (res.status === 200) {
      dispatch({
        type: REMOVE_CURATOR_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: REMOVE_CURATOR_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
