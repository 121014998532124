import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const GET_JOBTITLE_CURATOR_FILTER_BEGIN =
  "GET_JOBTITLE_CURATOR_FILTER_BEGIN";
export const GET_JOBTITLE_CURATOR_FILTER_SUCCESS =
  "GET_JOBTITLE_CURATOR_FILTER_SUCCESS";
export const GET_JOBTITLE_CURATOR_FILTER_FAILURE =
  "GET_JOBTITLE_CURATOR_FILTER_FAILURE";
export const GET_STATE_CURATOR_FILTER_BEGIN = "GET_STATE_CURATOR_FILTER_BEGIN";
export const GET_STATE_CURATOR_FILTER_SUCCESS =
  "GET_STATE_CURATOR_FILTER_SUCCESS";
export const GET_STATE_CURATOR_FILTER_FAILURE =
  "GET_STATE_CURATOR_FILTER_FAILURE";
export const GET_COUNTRY_CURATOR_FILTER_BEGIN =
  "GET_COUNTRY_CURATOR_FILTER_BEGIN";
export const GET_COUNTRY_CURATOR_FILTER_SUCCESS =
  "GET_COUNTRY_CURATOR_FILTER_SUCCESS";
export const GET_COUNTRY_CURATOR_FILTER_FAILURE =
  "GET_COUNTRY_CURATOR_FILTER_FAILURE";
export const GET_ROLES_CURATOR_FILTER_BEGIN = "GET_ROLES_CURATOR_FILTER_BEGIN";
export const GET_ROLES_CURATOR_FILTER_SUCCESS =
  "GET_ROLES_CURATOR_FILTER_SUCCESS";
export const GET_ROLES_CURATOR_FILTER_FAILURE =
  "GET_ROLES_CURATOR_FILTER_FAILURE";
export const GET_CATEGORY_CURATOR_FILTER_BEGIN =
  "GET_CATEGORY_CURATOR_FILTER_BEGIN";
export const GET_CATEGORY_CURATOR_FILTER_SUCCESS =
  "GET_CATEGORY_CURATOR_FILTER_SUCCESS";
export const GET_CATEGORY_CURATOR_FILTER_FAILURE =
  "GET_CATEGORY_CURATOR_FILTER_FAILURE";

export const getCuratorJobTitleList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOBTITLE_CURATOR_FILTER_BEGIN,
    });
    const res = await post(
      "/filter/assigncurator/getjobtitleforcurator",
      payload
    );
    if (res.status === 200) {
      dispatch({
        type: GET_JOBTITLE_CURATOR_FILTER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOBTITLE_CURATOR_FILTER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getCuratorStateList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_STATE_CURATOR_FILTER_BEGIN,
    });
    const res = await post("/filter/assigncurator/getstateforcurator", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_STATE_CURATOR_FILTER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_STATE_CURATOR_FILTER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getCuratorCountryList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_COUNTRY_CURATOR_FILTER_BEGIN,
    });
    const res = await post(
      "/filter/assigncurator/getcountryforcurator",
      payload
    );
    if (res.status === 200) {
      dispatch({
        type: GET_COUNTRY_CURATOR_FILTER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_COUNTRY_CURATOR_FILTER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getCuratorRolesList = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ROLES_CURATOR_FILTER_BEGIN,
    });
    const res = await post("/filter/assigncurator/getrolesforcurator", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_ROLES_CURATOR_FILTER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ROLES_CURATOR_FILTER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};

export const getCuratorjobCategory = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CATEGORY_CURATOR_FILTER_BEGIN,
    });
    const res = await post(
      "/filter/assigncurator/getjobcategoryforcurator",
      payload
    );
    if (res.status === 200) {
      dispatch({
        type: GET_CATEGORY_CURATOR_FILTER_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CATEGORY_CURATOR_FILTER_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
