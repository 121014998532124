import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { Col, Row, Container, Card } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";

const InviteRecruiter = (props) => {
  const {
    smShow,
    setSmShow,
    formModel,
    setFormModel,
    Modelvalidation,
    setModelValidation,
    handleModelClick,
  } = props;

  const _onChangeText = (value, code, name) => {
    if (value) {
      const asYouType = new AsYouType(code);
      asYouType.input(value);
      setModelValidation({
        ...Modelvalidation,
        mobileValidation: asYouType.isValid(),
      });
    } else {
      setModelValidation({ ...Modelvalidation, mobileValidation: true });
    }
  };

  const handlePhoneChange = (value, country) => {
    const mobile = value.replace(country.dialCode, "");
    setFormModel({
      ...formModel,
      mobile: mobile,
      mobilePhoneCode: `+${country.dialCode}`,
    });
  };

  return (
    <Modal
      size="lg"
      show={smShow}
      onHide={() => setSmShow(false)}
      animation={false}
    >
      <Modal.Header closeButton>
        <h6>Invite Gig Recruiter</h6>
      </Modal.Header>
      <Modal.Body>
        <Container fluid>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <div className="form-floating">
                    <input
                      type="text"
                      className={
                        Modelvalidation.firstNameError
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      id="firstName"
                      value={formModel.firstName}
                      onChange={(e) =>
                        setFormModel({
                          ...formModel,
                          firstName: e.target.value,
                        })
                      }
                      placeholder="First Name*"
                      required
                    />
                    <label>First Name*</label>
                    <small className="validation">
                      {Modelvalidation.firstNameError}
                    </small>
                  </div>
                </Col>
                <Col>
                  <Col>
                    <div className="form-floating">
                      <input
                        type="text"
                        className={
                          Modelvalidation.lastNameError
                            ? "form-control border border-danger"
                            : "form-control"
                        }
                        id="lastName"
                        value={formModel.lastName}
                        onChange={(e) =>
                          setFormModel({
                            ...formModel,
                            lastName: e.target.value,
                          })
                        }
                        placeholder="Last Name*"
                        required
                      />
                      <label>Last Name*</label>
                      <small className="validation">
                        {Modelvalidation.lastNameError}
                      </small>
                    </div>
                  </Col>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div class="form-floating addGigEmail">
                    <input
                      type="text"
                      class={
                        Modelvalidation.emailError
                          ? "form-control border border-danger"
                          : "form-control"
                      }
                      id="email"
                      value={formModel.Email}
                      onChange={(e) =>
                        setFormModel({
                          ...formModel,
                          Email: e.target.value,
                        })
                      }
                      placeholder="Email*"
                    />
                    <label>Email*</label>
                    <small className="validation">
                      {Modelvalidation.emailError}
                    </small>
                  </div>
                </Col>
                <Col>
                  <label>Phone Number</label>
                  <div class="form-floating" style={{ width: "100%" }}>
                    <div
                      className={
                        Modelvalidation.emailError
                          ? "form-floating border border-danger addGigPhone"
                          : "form-floating addGigPhone"
                      }
                      style={{ zIndex: "999" }}
                    >
                      <PhoneInput
                        value={
                          formModel?.mobilePhoneCode
                            ? `${formModel?.mobilePhoneCode}${formModel?.mobile}`
                            : formModel?.mobile
                        }
                        name="mobile"
                        country={"us"}
                        style={{ height: "55px" }}
                        onChange={(value, country) => {
                          _onChangeText(
                            value.replace(country.dialCode, ""),
                            country.countryCode.toUpperCase(),
                            "mobile"
                          );
                          handlePhoneChange(value, country);
                        }}
                        placeholder="Phone Number*"
                        inputClass={
                          Modelvalidation.mobileError
                            ? "form-control border border-danger"
                            : "form-control"
                        }
                        specialLabel="Phone Number"
                        specialLabelStyle={{ display: "block" }}
                        enableSearch={true}
                      />
                    </div>
                    {Modelvalidation.mobileError && (
                      <small className="validation">
                        {Modelvalidation.mobileError}
                      </small>
                    )}{" "}
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={() => {
            setSmShow(false);
            setFormModel({});
          }}
        >
          Close
        </Button>
        <Button variant="primary" onClick={handleModelClick}>
          <i className="fal fa-user-plus mr-2" aria-hidden="true" />
          Invite
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InviteRecruiter;
