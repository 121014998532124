import React, { useState } from "react";

const PoolDetailsTitle = (props) => {
  const [countryClick, setCountryClick] = useState(false);
  return (
    <div className="row justify-content-center">
      <div className="col-xl-12">
        <div className="text-center mb-4 font-white text-capitalize">
          <h4>{props?.jobData?.jobTitle}</h4>
          <p>
            {/* {props?.jobData?.workPlaceType} /{" "} */}
            {props?.jobData?.isCountry
              ? props?.jobData?.country
              : props?.jobData?.city + ", " + props?.jobData?.country}
          </p>
        </div>
      </div>
    </div>
  );
};

export default PoolDetailsTitle;
