import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import HereMap from "../../location/CommonHereMap";
import Autosuggest from "react-autosuggest";
import PreferredLocation from "../../location/PreferredLocation";
import _ from "lodash";
import { dropDownData, currency_list } from "../Edit Talent DS/dropDownData";

const customStyles = {
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#dcedff",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

function ProfileInfoDS(props) {
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [form, setForm] = useState(null);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  const [secondarySkillsInput, setSecondarySkillSInput] = useState("");
  const [positionTypes, setPositionTypes] = useState([]);
  const [skillSet, setSkillSet] = useState([]);
  // const dropDownData = {
  //   CandidateSkills: [
  //     {
  //       id_candidateSource: 1,
  //       SourceName: "AJAX",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 2,
  //       SourceName: "AML",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 3,
  //       SourceName: "ASP.NET",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 4,
  //       SourceName: "AWS",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 5,
  //       SourceName: "Azure",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 6,
  //       SourceName: "C++",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 7,
  //       SourceName: "Certified Ethical Hacker (CEH)",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 8,
  //       SourceName: "Cisco CCNA",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 9,
  //       SourceName: "CISSP",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 10,
  //       SourceName: "CompTIA Security+.",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 11,
  //       SourceName: "CSS",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 12,
  //       SourceName: "data mining and modeling",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 13,
  //       SourceName: "database management",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 14,
  //       SourceName: "Django",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 15,
  //       SourceName: "Docker",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 16,
  //       SourceName: "ETAP",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 17,
  //       SourceName: "Golang",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 18,
  //       SourceName: "Google Cloud ML Engine",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 19,
  //       SourceName: "Java",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 20,
  //       SourceName: "JavaScript",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 21,
  //       SourceName: "Joomla",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 22,
  //       SourceName: "Linux",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 23,
  //       SourceName: "macOS",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 24,
  //       SourceName: "MATLAB",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 25,
  //       SourceName: "Microsoft Hyper-V",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 26,
  //       SourceName: "Multisim",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 27,
  //       SourceName: "Node.js",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 28,
  //       SourceName: "Objective-C",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 29,
  //       SourceName: "OpenCart",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 30,
  //       SourceName: "Oracle VM VirtualBox",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 31,
  //       SourceName: "PHP",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 32,
  //       SourceName: "PrestaShop",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 33,
  //       SourceName: "Pspice",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 34,
  //       SourceName: "Python",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 35,
  //       SourceName: "QEMU",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 36,
  //       SourceName: "ReactJS",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 37,
  //       SourceName: "Ruby",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 38,
  //       SourceName: "scikit-learn",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 39,
  //       SourceName: "Shopify",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 40,
  //       SourceName: "Simulink",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 41,
  //       SourceName: "Verilog",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 42,
  //       SourceName: "WooCommerce",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 43,
  //       SourceName: "XEN",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 44,
  //       SourceName: "12-Lead ECG Interpretation and Placements",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 45,
  //       SourceName: "4D (4th Dimension)",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 46,
  //       SourceName: "A/B Testing",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 47,
  //       SourceName: "Acrobat",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 48,
  //       SourceName: "ADABAS",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 49,
  //       SourceName: "Agile",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 50,
  //       SourceName: "Altibase",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 51,
  //       SourceName: "AmazonRDS",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 52,
  //       SourceName: "Angular",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 53,
  //       SourceName: "ASP",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 54,
  //       SourceName: "AutoCAD",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 55,
  //       SourceName: "Automation",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 56,
  //       SourceName: "Backbone.js",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 57,
  //       SourceName: "Basic Statistics",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 58,
  //       SourceName: "Bootstrap",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 59,
  //       SourceName: "Budgeting",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 60,
  //       SourceName: "Business Process Management",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 61,
  //       SourceName: "Closing Sales",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 62,
  //       SourceName: "Cloudera",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 63,
  //       SourceName: "CMS Tools",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 64,
  //       SourceName: "Coding",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 65,
  //       SourceName: "Compliance",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 66,
  //       SourceName: "Corel Draw",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 67,
  //       SourceName: "Cost Management",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 68,
  //       SourceName: "Couchbase",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 69,
  //       SourceName: "CouchDB",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 70,
  //       SourceName: "CRO/CRM",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 71,
  //       SourceName: "CSS",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 72,
  //       SourceName: "Customer Needs Analysis",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 73,
  //       SourceName: "Data Structures",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 74,
  //       SourceName: "DbVisualizer",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 75,
  //       SourceName: "Delphi",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 76,
  //       SourceName: "Email Marketing",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 77,
  //       SourceName: "Ember.js",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 78,
  //       SourceName: "ES5",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 79,
  //       SourceName: "Express.js",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 80,
  //       SourceName: "FileMaker",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 81,
  //       SourceName: "Firebase",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 82,
  //       SourceName: "Free Hand",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 83,
  //       SourceName: "G-Tube Feedings and Care",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 84,
  //       SourceName: "General Marketing Skills",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 85,
  //       SourceName: "Ghostwriting",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 86,
  //       SourceName: "Glucose Checks",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 87,
  //       SourceName: "GO",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 88,
  //       SourceName: "Grant Writing",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 89,
  //       SourceName: "GraphQL",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 90,
  //       SourceName: "Groovy",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 91,
  //       SourceName: "Grunt",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 92,
  //       SourceName: "Gulp",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 93,
  //       SourceName: "Hadoop HDFS",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 94,
  //       SourceName: "Haskell",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 95,
  //       SourceName: "HTML",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 96,
  //       SourceName: "HTML DOM",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 97,
  //       SourceName: "HTML/CSS",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 98,
  //       SourceName: "HTML5",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 99,
  //       SourceName: "IBM DB2",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 100,
  //       SourceName: "Illustrator",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 101,
  //       SourceName: "InDesign",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 102,
  //       SourceName: "Informix",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 103,
  //       SourceName: "Informix Dynamic Server",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 104,
  //       SourceName: "Java",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 105,
  //       SourceName: "JavaScript",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 106,
  //       SourceName: "Journalism",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 107,
  //       SourceName: "jQuery",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 108,
  //       SourceName: "JSON",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 109,
  //       SourceName: "Kotlin",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 110,
  //       SourceName: "Kubernetes",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 111,
  //       SourceName: "Laravel",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 112,
  //       SourceName: "Layout",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 113,
  //       SourceName: "Lead Qualification",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 114,
  //       SourceName: "Less",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 115,
  //       SourceName: "Lua",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 116,
  //       SourceName: "Machine Learning",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 117,
  //       SourceName: "Magento",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 118,
  //       SourceName: "ManageEngine Applications Manager",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 119,
  //       SourceName: "MariaDB",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 120,
  //       SourceName: "MATLAB",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 121,
  //       SourceName: "Meteor.js",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 122,
  //       SourceName: "Microsoft Access",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 123,
  //       SourceName: "Microsoft SQL Server",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 124,
  //       SourceName: "Microsoft Windows",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 125,
  //       SourceName: "MongoDB",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 126,
  //       SourceName: "Monitoring Vital Signs",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 127,
  //       SourceName: "MySQL",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 128,
  //       SourceName: "Neo4j",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 129,
  //       SourceName: "NIH Stroke Scale Patient Assessment",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 130,
  //       SourceName: "Node.js",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 131,
  //       SourceName: "Objection Handling",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 132,
  //       SourceName: "Open Source Experience",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 133,
  //       SourceName: "Oracle RDBMS",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 134,
  //       SourceName: "OrientDB",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 135,
  //       SourceName: "OSCP",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 136,
  //       SourceName: "PaaS (Azure and Heroku)",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 137,
  //       SourceName: "Parse.com",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 138,
  //       SourceName: "Patient Transport",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 139,
  //       SourceName: "Perl",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 140,
  //       SourceName: "Phlebotomy",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 141,
  //       SourceName: "Photoshop",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 142,
  //       SourceName: "phpMyAdmin",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 143,
  //       SourceName: "Planning",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 144,
  //       SourceName: "PostgresSQL",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 145,
  //       SourceName: "PowerShell",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 146,
  //       SourceName: "PPC",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 147,
  //       SourceName: "Presentation",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 148,
  //       SourceName: "Product Demo",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 149,
  //       SourceName: "Product Knowledge",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 150,
  //       SourceName: "Project Evaluation",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 151,
  //       SourceName: "Project Management",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 152,
  //       SourceName: "Prospecting",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 153,
  //       SourceName: "Record Keeping",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 154,
  //       SourceName: "Redis",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 155,
  //       SourceName: "Redux",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 156,
  //       SourceName: "Rehabilitation Therapy",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 157,
  //       SourceName: "Relationship Building",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 158,
  //       SourceName: "REST",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 159,
  //       SourceName: "Risk Management",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 160,
  //       SourceName: "Robomongo",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 161,
  //       SourceName: "Rust",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 162,
  //       SourceName: "SAP Sybase ASE",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 163,
  //       SourceName: "Sass",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 164,
  //       SourceName: "Scala",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 165,
  //       SourceName: "Scientific Writing",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 166,
  //       SourceName: "Scrum",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 167,
  //       SourceName: "Security",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 168,
  //       SourceName: "SEO",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 169,
  //       SourceName: "Seqel PRO",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 170,
  //       SourceName: "Shell",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 171,
  //       SourceName: "Sketching",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 172,
  //       SourceName: "Social Media",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 173,
  //       SourceName: "SolarWinds Database Performance Analyzer",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 174,
  //       SourceName: "SQL",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 175,
  //       SourceName: "SQL Developer",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 176,
  //       SourceName: "SQLite",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 177,
  //       SourceName: "Statistical analysis",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 178,
  //       SourceName: "Storybook",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 179,
  //       SourceName: "Technical Reports",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 180,
  //       SourceName: "Technical Writing",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 181,
  //       SourceName: "TensorFlow",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 182,
  //       SourceName: "Teradata",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 183,
  //       SourceName: "Toad",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 184,
  //       SourceName: "TypeScript",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 185,
  //       SourceName: "Typography",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 186,
  //       SourceName: "Urgent Care/Emergency Care",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 187,
  //       SourceName: "UX Design",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 188,
  //       SourceName: "Visual Basic .NET",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 189,
  //       SourceName: "VMware vSphere",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 190,
  //       SourceName: "W3.CSS",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 191,
  //       SourceName: "Web Development",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 192,
  //       SourceName: "WordPress",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 193,
  //       SourceName: "Work Scheduling",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 194,
  //       SourceName: "Wound Care and Dressing",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 195,
  //       SourceName: "XML",
  //       TypeName: "CandidateSkills",
  //     },
  //     {
  //       id_candidateSource: 196,
  //       SourceName: "Yoast",
  //       TypeName: "CandidateSkills",
  //     },
  //   ],
  //   CurrencyTime: [
  //     {
  //       id_candidateSource: 272,
  //       SourceName: "AED/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 271,
  //       SourceName: "AED/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 274,
  //       SourceName: "AED/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 273,
  //       SourceName: "AED/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 275,
  //       SourceName: "AED/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 12,
  //       SourceName: "ARS/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 11,
  //       SourceName: "ARS/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 14,
  //       SourceName: "ARS/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 13,
  //       SourceName: "ARS/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 15,
  //       SourceName: "ARS/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 17,
  //       SourceName: "AUD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 16,
  //       SourceName: "AUD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 19,
  //       SourceName: "AUD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 18,
  //       SourceName: "AUD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 20,
  //       SourceName: "AUD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 27,
  //       SourceName: "BBD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 26,
  //       SourceName: "BBD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 29,
  //       SourceName: "BBD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 28,
  //       SourceName: "BBD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 30,
  //       SourceName: "BBD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 22,
  //       SourceName: "BHD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 21,
  //       SourceName: "BHD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 24,
  //       SourceName: "BHD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 23,
  //       SourceName: "BHD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 25,
  //       SourceName: "BHD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 32,
  //       SourceName: "BRL/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 31,
  //       SourceName: "BRL/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 34,
  //       SourceName: "BRL/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 33,
  //       SourceName: "BRL/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 35,
  //       SourceName: "BRL/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 42,
  //       SourceName: "CAD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 41,
  //       SourceName: "CAD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 44,
  //       SourceName: "CAD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 43,
  //       SourceName: "CAD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 45,
  //       SourceName: "CAD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 257,
  //       SourceName: "CHF/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 256,
  //       SourceName: "CHF/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 259,
  //       SourceName: "CHF/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 258,
  //       SourceName: "CHF/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 260,
  //       SourceName: "CHF/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 52,
  //       SourceName: "CLP/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 51,
  //       SourceName: "CLP/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 54,
  //       SourceName: "CLP/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 53,
  //       SourceName: "CLP/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 55,
  //       SourceName: "CLP/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 57,
  //       SourceName: "CNY/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 56,
  //       SourceName: "CNY/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 59,
  //       SourceName: "CNY/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 58,
  //       SourceName: "CNY/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 60,
  //       SourceName: "CNY/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 62,
  //       SourceName: "CYP/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 61,
  //       SourceName: "CYP/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 64,
  //       SourceName: "CYP/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 63,
  //       SourceName: "CYP/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 65,
  //       SourceName: "CYP/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 67,
  //       SourceName: "CZK/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 66,
  //       SourceName: "CZK/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 69,
  //       SourceName: "CZK/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 68,
  //       SourceName: "CZK/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 70,
  //       SourceName: "CZK/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 72,
  //       SourceName: "DKK/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 71,
  //       SourceName: "DKK/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 74,
  //       SourceName: "DKK/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 73,
  //       SourceName: "DKK/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 75,
  //       SourceName: "DKK/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 87,
  //       SourceName: "EEK/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 86,
  //       SourceName: "EEK/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 89,
  //       SourceName: "EEK/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 88,
  //       SourceName: "EEK/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 90,
  //       SourceName: "EEK/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 82,
  //       SourceName: "EGP/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 81,
  //       SourceName: "EGP/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 84,
  //       SourceName: "EGP/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 83,
  //       SourceName: "EGP/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 85,
  //       SourceName: "EGP/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 7,
  //       SourceName: "EUR/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 6,
  //       SourceName: "EUR/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 9,
  //       SourceName: "EUR/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 8,
  //       SourceName: "EUR/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 10,
  //       SourceName: "EUR/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 37,
  //       SourceName: "GBP/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 36,
  //       SourceName: "GBP/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 39,
  //       SourceName: "GBP/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 38,
  //       SourceName: "GBP/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 40,
  //       SourceName: "GBP/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 92,
  //       SourceName: "HKD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 91,
  //       SourceName: "HKD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 94,
  //       SourceName: "HKD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 93,
  //       SourceName: "HKD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 95,
  //       SourceName: "HKD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 97,
  //       SourceName: "HUF/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 96,
  //       SourceName: "HUF/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 99,
  //       SourceName: "HUF/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 98,
  //       SourceName: "HUF/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 100,
  //       SourceName: "HUF/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 112,
  //       SourceName: "IDR/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 111,
  //       SourceName: "IDR/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 114,
  //       SourceName: "IDR/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 113,
  //       SourceName: "IDR/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 115,
  //       SourceName: "IDR/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 117,
  //       SourceName: "ILS/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 116,
  //       SourceName: "ILS/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 119,
  //       SourceName: "ILS/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 118,
  //       SourceName: "ILS/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 120,
  //       SourceName: "ILS/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 107,
  //       SourceName: "INR/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 106,
  //       SourceName: "INR/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 109,
  //       SourceName: "INR/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 108,
  //       SourceName: "INR/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 110,
  //       SourceName: "INR/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 102,
  //       SourceName: "ISK/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 101,
  //       SourceName: "ISK/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 104,
  //       SourceName: "ISK/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 103,
  //       SourceName: "ISK/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 105,
  //       SourceName: "ISK/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 122,
  //       SourceName: "JMD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 121,
  //       SourceName: "JMD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 124,
  //       SourceName: "JMD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 123,
  //       SourceName: "JMD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 125,
  //       SourceName: "JMD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 132,
  //       SourceName: "JOD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 131,
  //       SourceName: "JOD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 134,
  //       SourceName: "JOD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 133,
  //       SourceName: "JOD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 135,
  //       SourceName: "JOD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 127,
  //       SourceName: "JPY/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 126,
  //       SourceName: "JPY/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 129,
  //       SourceName: "JPY/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 128,
  //       SourceName: "JPY/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 130,
  //       SourceName: "JPY/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 137,
  //       SourceName: "KES/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 136,
  //       SourceName: "KES/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 139,
  //       SourceName: "KES/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 138,
  //       SourceName: "KES/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 140,
  //       SourceName: "KES/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 242,
  //       SourceName: "KRW/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 241,
  //       SourceName: "KRW/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 244,
  //       SourceName: "KRW/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 243,
  //       SourceName: "KRW/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 245,
  //       SourceName: "KRW/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 147,
  //       SourceName: "LBP/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 146,
  //       SourceName: "LBP/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 149,
  //       SourceName: "LBP/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 148,
  //       SourceName: "LBP/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 150,
  //       SourceName: "LBP/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 247,
  //       SourceName: "LKR/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 246,
  //       SourceName: "LKR/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 249,
  //       SourceName: "LKR/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 248,
  //       SourceName: "LKR/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 250,
  //       SourceName: "LKR/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 152,
  //       SourceName: "LTL/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 151,
  //       SourceName: "LTL/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 154,
  //       SourceName: "LTL/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 153,
  //       SourceName: "LTL/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 155,
  //       SourceName: "LTL/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 142,
  //       SourceName: "LVL/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 141,
  //       SourceName: "LVL/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 144,
  //       SourceName: "LVL/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 143,
  //       SourceName: "LVL/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 145,
  //       SourceName: "LVL/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 162,
  //       SourceName: "MXN/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 161,
  //       SourceName: "MXN/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 164,
  //       SourceName: "MXN/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 163,
  //       SourceName: "MXN/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 165,
  //       SourceName: "MXN/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 157,
  //       SourceName: "MYR/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 156,
  //       SourceName: "MYR/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 159,
  //       SourceName: "MYR/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 158,
  //       SourceName: "MYR/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 160,
  //       SourceName: "MYR/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 167,
  //       SourceName: "NAD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 166,
  //       SourceName: "NAD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 169,
  //       SourceName: "NAD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 168,
  //       SourceName: "NAD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 170,
  //       SourceName: "NAD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 182,
  //       SourceName: "NOK/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 181,
  //       SourceName: "NOK/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 184,
  //       SourceName: "NOK/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 183,
  //       SourceName: "NOK/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 185,
  //       SourceName: "NOK/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 172,
  //       SourceName: "NPR/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 171,
  //       SourceName: "NPR/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 174,
  //       SourceName: "NPR/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 173,
  //       SourceName: "NPR/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 175,
  //       SourceName: "NPR/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 177,
  //       SourceName: "NZD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 176,
  //       SourceName: "NZD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 179,
  //       SourceName: "NZD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 178,
  //       SourceName: "NZD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 180,
  //       SourceName: "NZD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 187,
  //       SourceName: "OMR/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 186,
  //       SourceName: "OMR/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 189,
  //       SourceName: "OMR/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 188,
  //       SourceName: "OMR/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 190,
  //       SourceName: "OMR/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 197,
  //       SourceName: "PAB/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 196,
  //       SourceName: "PAB/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 199,
  //       SourceName: "PAB/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 198,
  //       SourceName: "PAB/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 200,
  //       SourceName: "PAB/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 202,
  //       SourceName: "PHP/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 201,
  //       SourceName: "PHP/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 204,
  //       SourceName: "PHP/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 203,
  //       SourceName: "PHP/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 205,
  //       SourceName: "PHP/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 192,
  //       SourceName: "PKR/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 191,
  //       SourceName: "PKR/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 194,
  //       SourceName: "PKR/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 193,
  //       SourceName: "PKR/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 195,
  //       SourceName: "PKR/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 207,
  //       SourceName: "PLN/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 206,
  //       SourceName: "PLN/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 209,
  //       SourceName: "PLN/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 208,
  //       SourceName: "PLN/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 210,
  //       SourceName: "PLN/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 212,
  //       SourceName: "QAR/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 211,
  //       SourceName: "QAR/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 214,
  //       SourceName: "QAR/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 213,
  //       SourceName: "QAR/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 215,
  //       SourceName: "QAR/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 217,
  //       SourceName: "RON/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 216,
  //       SourceName: "RON/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 219,
  //       SourceName: "RON/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 218,
  //       SourceName: "RON/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 220,
  //       SourceName: "RON/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 222,
  //       SourceName: "RUB/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 221,
  //       SourceName: "RUB/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 224,
  //       SourceName: "RUB/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 223,
  //       SourceName: "RUB/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 225,
  //       SourceName: "RUB/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 227,
  //       SourceName: "SAR/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 226,
  //       SourceName: "SAR/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 229,
  //       SourceName: "SAR/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 228,
  //       SourceName: "SAR/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 230,
  //       SourceName: "SAR/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 252,
  //       SourceName: "SEK/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 251,
  //       SourceName: "SEK/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 254,
  //       SourceName: "SEK/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 253,
  //       SourceName: "SEK/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 255,
  //       SourceName: "SEK/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 232,
  //       SourceName: "SGD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 231,
  //       SourceName: "SGD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 234,
  //       SourceName: "SGD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 233,
  //       SourceName: "SGD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 235,
  //       SourceName: "SGD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 262,
  //       SourceName: "THB/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 261,
  //       SourceName: "THB/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 264,
  //       SourceName: "THB/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 263,
  //       SourceName: "THB/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 265,
  //       SourceName: "THB/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 267,
  //       SourceName: "TRY/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 266,
  //       SourceName: "TRY/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 269,
  //       SourceName: "TRY/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 268,
  //       SourceName: "TRY/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 270,
  //       SourceName: "TRY/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 2,
  //       SourceName: "USD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 1,
  //       SourceName: "USD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 4,
  //       SourceName: "USD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 3,
  //       SourceName: "USD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 5,
  //       SourceName: "USD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 277,
  //       SourceName: "VEF/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 276,
  //       SourceName: "VEF/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 279,
  //       SourceName: "VEF/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 278,
  //       SourceName: "VEF/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 280,
  //       SourceName: "VEF/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 47,
  //       SourceName: "XAF/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 46,
  //       SourceName: "XAF/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 49,
  //       SourceName: "XAF/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 48,
  //       SourceName: "XAF/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 50,
  //       SourceName: "XAF/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 77,
  //       SourceName: "XCD/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 76,
  //       SourceName: "XCD/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 79,
  //       SourceName: "XCD/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 78,
  //       SourceName: "XCD/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 80,
  //       SourceName: "XCD/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 282,
  //       SourceName: "XOF/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 281,
  //       SourceName: "XOF/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 284,
  //       SourceName: "XOF/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 283,
  //       SourceName: "XOF/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 285,
  //       SourceName: "XOF/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 237,
  //       SourceName: "ZAR/Day",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 236,
  //       SourceName: "ZAR/Hour",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 239,
  //       SourceName: "ZAR/Month",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 238,
  //       SourceName: "ZAR/Week",
  //       TypeName: "CurrencyTime",
  //     },
  //     {
  //       id_candidateSource: 240,
  //       SourceName: "ZAR/Year",
  //       TypeName: "CurrencyTime",
  //     },
  //   ],
  //   DateFormatType: [
  //     {
  //       id_candidateSource: 1,
  //       SourceName: "Year",
  //       TypeName: "DateFormatType",
  //     },
  //     {
  //       id_candidateSource: 2,
  //       SourceName: "Month",
  //       TypeName: "DateFormatType",
  //     },
  //     {
  //       id_candidateSource: 3,
  //       SourceName: "Day",
  //       TypeName: "DateFormatType",
  //     },
  //   ],
  //   Department: [
  //     {
  //       id_candidateSource: 1,
  //       SourceName: "IT",
  //       TypeName: "Department",
  //     },
  //     {
  //       id_candidateSource: 2,
  //       SourceName: "Marketing",
  //       TypeName: "Department",
  //     },
  //     {
  //       id_candidateSource: 4,
  //       SourceName: "Service",
  //       TypeName: "Department",
  //     },
  //     {
  //       id_candidateSource: 3,
  //       SourceName: "Staffing",
  //       TypeName: "Department",
  //     },
  //   ],
  //   ExperienceLevel: [
  //     {
  //       id_candidateSource: 1,
  //       SourceName: "Junior :0-3 years",
  //       TypeName: "ExperienceLevel",
  //     },
  //     {
  //       id_candidateSource: 2,
  //       SourceName: "Mid :4-7 years",
  //       TypeName: "ExperienceLevel",
  //     },
  //     {
  //       id_candidateSource: 3,
  //       SourceName: "Senior :8-10 years",
  //       TypeName: "ExperienceLevel",
  //     },
  //     {
  //       id_candidateSource: 4,
  //       SourceName: "Guru :Subject Matter Expert",
  //       TypeName: "ExperienceLevel",
  //     },
  //   ],
  //   ExperiencLevel: [
  //     {
  //       id_candidateSource: 1,
  //       SourceName: "Junior:0-3 years",
  //       TypeName: "ExperiencLevel",
  //     },
  //     {
  //       id_candidateSource: 2,
  //       SourceName: "Mid:4-7 years",
  //       TypeName: "ExperiencLevel",
  //     },
  //     {
  //       id_candidateSource: 3,
  //       SourceName: "Senior:8-10 years",
  //       TypeName: "ExperiencLevel",
  //     },
  //     {
  //       id_candidateSource: 4,
  //       SourceName: "Guru:Subject Matter Expert",
  //       TypeName: "ExperiencLevel",
  //     },
  //   ],
  //   PositionTitle: [
  //     {
  //       id_candidateSource: 1,
  //       SourceName: ".Net Full Stack Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 2,
  //       SourceName: "Accountant",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 3,
  //       SourceName: "Administrative Assistant",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 4,
  //       SourceName: "Business Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 5,
  //       SourceName: "Financial Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 6,
  //       SourceName: "Digital Content Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 7,
  //       SourceName: "Call Center Customer Service Professional",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 8,
  //       SourceName: "Customer Service Representative",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 9,
  //       SourceName: "Data Entry Associate",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 10,
  //       SourceName: "Core Java Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 11,
  //       SourceName: "Java Frontend Developer ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 12,
  //       SourceName: "MuleSoft Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 13,
  //       SourceName: "Agile Coach",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 14,
  //       SourceName: "AI(Artificial Intelligence) Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 15,
  //       SourceName: "Audit Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 16,
  //       SourceName: "Backend Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 17,
  //       SourceName: "Big Data Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 18,
  //       SourceName: "Biochemist",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 19,
  //       SourceName: "Case Management Specialist",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 20,
  //       SourceName: "Chemist",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 21,
  //       SourceName: "Claims Processor",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 22,
  //       SourceName: "Clinical Data Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 23,
  //       SourceName: "Cloud Architect",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 24,
  //       SourceName: "Cloud Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 25,
  //       SourceName: "Communications Consultant",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 26,
  //       SourceName: "Compliance Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 27,
  //       SourceName: "Credit Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 28,
  //       SourceName: "Customer Success Specialists",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 29,
  //       SourceName: "Cyber Security Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 30,
  //       SourceName: "Data Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 31,
  //       SourceName: "Data Scientist",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 32,
  //       SourceName: "DataStage Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 33,
  //       SourceName: "Desktop Support",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 34,
  //       SourceName: "Devops Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 35,
  //       SourceName: "Embedded Test Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 36,
  //       SourceName: "Global Pharmacovigilance Specialist- I",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 37,
  //       SourceName: "Graphic Designer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 38,
  //       SourceName: "Guidewire Consultant",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 39,
  //       SourceName: "Hadoop Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 40,
  //       SourceName: "Help Desk Support Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 41,
  //       SourceName: "HR Generalist",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 42,
  //       SourceName: "Hyperion Consultant",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 43,
  //       SourceName: "Informatica Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 44,
  //       SourceName: "Inside Sales Associate",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 45,
  //       SourceName: "iOS Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 46,
  //       SourceName: "Java Fullstack Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 47,
  //       SourceName: "Laboratory Technician I",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 48,
  //       SourceName: "Machine Learning Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 49,
  //       SourceName: "Mail Room Assistant/Clerk",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 50,
  //       SourceName: "Manual Testing Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 51,
  //       SourceName: "Marketing/Communication Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 52,
  //       SourceName: "Medical Billing Associate",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 53,
  //       SourceName: "MicroStrategy Consultant",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 54,
  //       SourceName: "Mobile Applications Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 55,
  //       SourceName: "Network Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 56,
  //       SourceName: "Network Project Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 57,
  //       SourceName: "Network Security Engineer ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 58,
  //       SourceName: "Node.JS Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 59,
  //       SourceName: "Operations Technician I",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 60,
  //       SourceName: "Oracle Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 61,
  //       SourceName: "Paralegal -II",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 62,
  //       SourceName: "Pega Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 63,
  //       SourceName: "Power BI Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 64,
  //       SourceName: "Procedure Writer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 65,
  //       SourceName: "Product Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 66,
  //       SourceName: "Program Manager (IT)",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 67,
  //       SourceName: "Project Coordinator III",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 68,
  //       SourceName: "Project Engineer -II",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 69,
  //       SourceName: "Python Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 70,
  //       SourceName: "Quality Assurance Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 71,
  //       SourceName: "Recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 72,
  //       SourceName: "Research Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 73,
  //       SourceName: "Research Associate -II",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 74,
  //       SourceName: "RPA (Robotic Process Automation) Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 75,
  //       SourceName: "SAP BW Consultant",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 76,
  //       SourceName: "SAP HANA Consultant",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 77,
  //       SourceName: "SAS Programmer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 78,
  //       SourceName: "Scientific Project Manager - IV ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 79,
  //       SourceName: "Scientist - III",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 80,
  //       SourceName: "ServiceNow Consultant ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 81,
  //       SourceName: "SharePoint Developer - II ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 82,
  //       SourceName: "Statistician",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 83,
  //       SourceName: "Tax Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 84,
  //       SourceName: "Technical Writer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 85,
  //       SourceName: "UI/UX Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 86,
  //       SourceName: "Validation Engineer - II",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 87,
  //       SourceName: "Business Intelligence Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 88,
  //       SourceName: "IoT Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 89,
  //       SourceName: "_Technician: Manufacturing - II (Day)",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 90,
  //       SourceName: "dadcsdsd",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 91,
  //       SourceName: "NULL",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 92,
  //       SourceName: "Full stack specialist",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 93,
  //       SourceName: "Tech Lead",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 94,
  //       SourceName: "",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 95,
  //       SourceName: "Automating test job",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 96,
  //       SourceName: "Test job",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 97,
  //       SourceName: " test  job",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 98,
  //       SourceName: "Fullstack developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 99,
  //       SourceName: "office manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 100,
  //       SourceName: "Junior Eng.",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 101,
  //       SourceName: "Data Architect",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 102,
  //       SourceName: "Data Analyst  Senior",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 103,
  //       SourceName: "Ethical hacker",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 104,
  //       SourceName: "Lead Recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 105,
  //       SourceName: "Web Designer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 106,
  //       SourceName: "Software Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 107,
  //       SourceName: "Software Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 108,
  //       SourceName: "Share Point  Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 109,
  //       SourceName: "Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 110,
  //       SourceName: "Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 111,
  //       SourceName: "Residential Para legal",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 112,
  //       SourceName: "Salesman",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 113,
  //       SourceName: "Fashion Designer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 114,
  //       SourceName: "CRO",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 115,
  //       SourceName: "Scrum Master",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 116,
  //       SourceName: "Master of Fullfillment",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 117,
  //       SourceName: "ReactJS",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 118,
  //       SourceName: "candidate",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 119,
  //       SourceName: "civil Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 120,
  //       SourceName: ".NET Developer TEST",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 121,
  //       SourceName: "Data Platform Architect",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 122,
  //       SourceName: "Sr. Hadoop Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 123,
  //       SourceName: "Mr. ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 124,
  //       SourceName: "tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 125,
  //       SourceName: "Talent Sourcer (test)",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 126,
  //       SourceName: "Test Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 127,
  //       SourceName: "Test Title",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 128,
  //       SourceName: "Full Stack Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 129,
  //       SourceName: "associate",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 130,
  //       SourceName: "Sr.Tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 131,
  //       SourceName: "qa",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 132,
  //       SourceName: "senior business analyst,business analyst,software ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 133,
  //       SourceName: "customer service representative,customer service r",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 134,
  //       SourceName: "Hiring Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 135,
  //       SourceName: "Employee",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 143,
  //       SourceName: "React Js",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 144,
  //       SourceName: "React ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 146,
  //       SourceName: "Germany Job",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 147,
  //       SourceName: "Bengium Job",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 148,
  //       SourceName: "TestAdmin Job",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 149,
  //       SourceName: "Testtest",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 150,
  //       SourceName: "vbvbvbvb",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 151,
  //       SourceName: "Ritesh+Kudal",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 152,
  //       SourceName: "postdatedjob",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 153,
  //       SourceName: "testjobrefresh",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 154,
  //       SourceName: "React ABC",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 155,
  //       SourceName: "Ritesh+Ranjit",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 156,
  //       SourceName: "testing",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 157,
  //       SourceName: "Test Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 158,
  //       SourceName: "Teach Tier",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 159,
  //       SourceName: "Senior Test engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 160,
  //       SourceName: "Automation tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 161,
  //       SourceName: "software tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 162,
  //       SourceName: "Appium Tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 163,
  //       SourceName: ".net developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 164,
  //       SourceName: "Android App Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 165,
  //       SourceName: "Android Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 166,
  //       SourceName: "support engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 167,
  //       SourceName: "Mobile Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 168,
  //       SourceName: "JT_Mobile Developer ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 169,
  //       SourceName: "Test Hiring Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 170,
  //       SourceName: "Test Hiring ManagerTest Hiring Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 171,
  //       SourceName: "Test HiringManagerTestHiringManager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 172,
  //       SourceName: "Test HiringManager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 173,
  //       SourceName: "ReactJS Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 174,
  //       SourceName: "QA Tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 175,
  //       SourceName: "Automation tester test",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 176,
  //       SourceName: "Automation Test Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 177,
  //       SourceName: "Developers_April",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 178,
  //       SourceName: "ADMIN",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 179,
  //       SourceName: "Devops EngineerTest",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 180,
  //       SourceName: "Embedded Eng",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 181,
  //       SourceName: "React Native Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 182,
  //       SourceName: "Android And IOS Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 183,
  //       SourceName: "IOS App Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 184,
  //       SourceName: "QA Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 185,
  //       SourceName: "System Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 186,
  //       SourceName: "automation tester,associate",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 187,
  //       SourceName: "manager1",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 188,
  //       SourceName: "manager2",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 189,
  //       SourceName: "manager3",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 190,
  //       SourceName: "manager4",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 191,
  //       SourceName: "manager5",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 192,
  //       SourceName: "PHP Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 193,
  //       SourceName: "Automation Test Engineers",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 194,
  //       SourceName: "Sr. Java Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 195,
  //       SourceName: "Software Tester Team",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 196,
  //       SourceName: "Softwarew Testing Team",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 197,
  //       SourceName: "Test Automation Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 198,
  //       SourceName: "SDET Tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 199,
  //       SourceName: "SDET Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 200,
  //       SourceName: "Recrutier",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 201,
  //       SourceName: "Sr. SDET",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 202,
  //       SourceName: "SDET2",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 203,
  //       SourceName: "software tester TeamA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 204,
  //       SourceName: "SDET Test Automation",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 205,
  //       SourceName: "Embedded Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 206,
  //       SourceName: "Sr. Embedded Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 207,
  //       SourceName: "Mobile Applications Developer SunnyCA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 208,
  //       SourceName: "React Native Developer Emp",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 209,
  //       SourceName: "React Native Developer HM",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 210,
  //       SourceName: "Java Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 211,
  //       SourceName: "CRM",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 212,
  //       SourceName: "Associative Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 213,
  //       SourceName: "Associative EngineerA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 214,
  //       SourceName: "Associative EngineerB",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 215,
  //       SourceName: "Associative EngineerR",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 216,
  //       SourceName: "Talent",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 217,
  //       SourceName: "QA Automation Test Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 218,
  //       SourceName: "Automtion Tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 219,
  //       SourceName: "Machine Learning Engineer jr",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 220,
  //       SourceName: "Test Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 221,
  //       SourceName: "Software Engineeer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 222,
  //       SourceName: "Automation Engineert",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 223,
  //       SourceName: "Staff",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 224,
  //       SourceName: "IPL Admin",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 225,
  //       SourceName: "Mobile Tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 226,
  //       SourceName: "o3 pvt ltd",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 227,
  //       SourceName: "SR Testers",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 228,
  //       SourceName: "Sr PHP Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 229,
  //       SourceName: "Junior Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 230,
  //       SourceName: "System Analyst Grade1",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 231,
  //       SourceName: "QC Tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 232,
  //       SourceName: "QCA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 233,
  //       SourceName: "adasd",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 234,
  //       SourceName: "Software Test",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 235,
  //       SourceName: "Software Eng",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 236,
  //       SourceName: "Software Test QA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 237,
  //       SourceName: "QAA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 238,
  //       SourceName: "Mid-level React Engineer ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 239,
  //       SourceName: "QAAAA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 240,
  //       SourceName: "test lead,qa",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 241,
  //       SourceName: "FutureJob",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 242,
  //       SourceName: "FutureJob1",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 243,
  //       SourceName: " test  job123",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 244,
  //       SourceName: "H test job",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 245,
  //       SourceName: "test future b",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 246,
  //       SourceName: "FutureJob Test",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 247,
  //       SourceName: "bindu gg job",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 248,
  //       SourceName: "Network Engineer con",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 249,
  //       SourceName: "Realme pvt ltd",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 250,
  //       SourceName: "Samsung123",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 251,
  //       SourceName: "sasa",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 252,
  //       SourceName: "jgjgh",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 253,
  //       SourceName: "soft developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 254,
  //       SourceName: "Business Analitics",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 255,
  //       SourceName: "Data Analytics",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 256,
  //       SourceName: "devops",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 257,
  //       SourceName: "Fashion Designer org",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 258,
  //       SourceName: "Mobile Testers",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 259,
  //       SourceName: "Business Analitiics",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 260,
  //       SourceName: "John K",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 261,
  //       SourceName: "Project Coordinator",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 262,
  //       SourceName: "9876543210",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 263,
  //       SourceName: "Content Writer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 264,
  //       SourceName: "ETL Tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 265,
  //       SourceName: "ETL",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 266,
  //       SourceName: "mail test",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 267,
  //       SourceName: "BA & QA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 268,
  //       SourceName: "RFP",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 269,
  //       SourceName: "PM",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 270,
  //       SourceName: "Data Analytics Junior",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 271,
  //       SourceName: "High5 Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 272,
  //       SourceName: "NuventoJob1",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 273,
  //       SourceName: "NuventoJob2",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 274,
  //       SourceName: "NuventoJob3",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 275,
  //       SourceName: "NuventoJob4",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 276,
  //       SourceName: "NuventoJob5",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 277,
  //       SourceName: "NuventoJob6",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 278,
  //       SourceName: " Test  job 009 ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 279,
  //       SourceName: " Test  job 010",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 280,
  //       SourceName: "NuventoJob7",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 281,
  //       SourceName: "Test 100",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 282,
  //       SourceName: "Developers_June",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 283,
  //       SourceName: "Developers",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 284,
  //       SourceName: "Test 111",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 285,
  //       SourceName: "Test 112",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 286,
  //       SourceName: "NuventoJob8",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 287,
  //       SourceName: "Developer06",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 288,
  //       SourceName: "Test06",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 289,
  //       SourceName: "GemeOX",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 290,
  //       SourceName: "NuventoJob9",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 291,
  //       SourceName: "EY Job 1",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 292,
  //       SourceName: "EY job 2",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 293,
  //       SourceName: "NOdejs",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 294,
  //       SourceName: "NuventoJob10",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 295,
  //       SourceName: "Test 114",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 296,
  //       SourceName: "NuventoJob11",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 297,
  //       SourceName: "Contract",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 298,
  //       SourceName: "9887876554",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 299,
  //       SourceName: "SBI Branch Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 300,
  //       SourceName: "SBI Bank Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 301,
  //       SourceName: "Core Java DeveloperT",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 302,
  //       SourceName: "Java Developers",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 303,
  //       SourceName: "core java developerA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 304,
  //       SourceName: "core language",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 305,
  //       SourceName: "Core Java Developers",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 306,
  //       SourceName: "Lead iOS Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 307,
  //       SourceName: "Python DeveloperT",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 308,
  //       SourceName: "sandy",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 309,
  //       SourceName: "Test 789",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 310,
  //       SourceName: "Developer01",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 311,
  //       SourceName: "Project Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 312,
  //       SourceName: "React js Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 313,
  //       SourceName: "Ey job 3",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 314,
  //       SourceName: "software developer, scrum master",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 315,
  //       SourceName: "Advance Java Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 316,
  //       SourceName: "Core Java Developer_June",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 317,
  //       SourceName: "Advance Java Developer_J",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 318,
  //       SourceName: "Advance Java Developer_June",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 319,
  //       SourceName: "Android DeveloperT",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 320,
  //       SourceName: "t",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 321,
  //       SourceName: "y",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 322,
  //       SourceName: "Software Engineerr",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 323,
  //       SourceName: "Automating test j",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 324,
  //       SourceName: "Automation Engineer1",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 325,
  //       SourceName: "Content Marketing Manager – USA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 326,
  //       SourceName: "S/w Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 327,
  //       SourceName: "Software Test Automation Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 328,
  //       SourceName: "AssociativeEngineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 329,
  //       SourceName: "Associative Recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 330,
  //       SourceName: "Admin Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 331,
  //       SourceName: "UX Designer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 332,
  //       SourceName: "UI/UX Designer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 333,
  //       SourceName: "Chief Technology Officer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 334,
  //       SourceName: "Java Developer / Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 335,
  //       SourceName: "BPS",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 336,
  //       SourceName: "Sourcer - English,Italian",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 337,
  //       SourceName: "Coordinator",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 338,
  //       SourceName: "Data Management - Cloud Engineer  Cloud Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 339,
  //       SourceName: "USA-Application Developer II (IT)",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 340,
  //       SourceName: "UX/Visual Designer II",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 341,
  //       SourceName: "Selenium Tester ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 342,
  //       SourceName: "Senior Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 343,
  //       SourceName: "Sr.Java Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 344,
  //       SourceName: ".Net Full-Stack Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 345,
  //       SourceName: "Project Test Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 346,
  //       SourceName: "Lead Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 347,
  //       SourceName: "Associative hiring manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 348,
  //       SourceName: "Business System Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 349,
  //       SourceName: "HM",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 350,
  //       SourceName: "Product Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 351,
  //       SourceName: "Sr. Product Owner- Sales and Marketing Enablement",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 352,
  //       SourceName: "Sr. Product Owner",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 353,
  //       SourceName: "Product Hiring Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 354,
  //       SourceName: "Quant Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 355,
  //       SourceName: "UX Copywriter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 356,
  //       SourceName: "Senior QA Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 357,
  //       SourceName: "AWS Architect",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 358,
  //       SourceName: "Associative Recruiter Test",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 359,
  //       SourceName: "Project Manager/Business Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 360,
  //       SourceName: "9973662772",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 361,
  //       SourceName: "87766554422",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 362,
  //       SourceName: "Manuel QA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 363,
  //       SourceName: "Manual QA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 364,
  //       SourceName: "QA Specialist",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 365,
  //       SourceName: "IT Helpdesk",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 366,
  //       SourceName: "Performance Test Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 367,
  //       SourceName: "RF Test Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 368,
  //       SourceName: "Technical Scrum Master",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 369,
  //       SourceName: "Client Reporting Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 370,
  //       SourceName: "Dynamics Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 371,
  //       SourceName: "Senior PHP Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 372,
  //       SourceName: "Business Intelligence Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 373,
  //       SourceName: "Sr Software Test Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 374,
  //       SourceName: "Sr. Hiring Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 375,
  //       SourceName: "Sr.Hiring Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 376,
  //       SourceName: "Senior HR Executive",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 377,
  //       SourceName: "Staff 1",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 378,
  //       SourceName: "Full Stack Developer - MERN",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 379,
  //       SourceName: "Sr Quality Assurance Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 380,
  //       SourceName: "QA Automation Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 381,
  //       SourceName: "Quality Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 382,
  //       SourceName: "mobile developer,ios developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 383,
  //       SourceName: "Back End Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 384,
  //       SourceName: "Front End Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 385,
  //       SourceName: "Front&Back End Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 386,
  //       SourceName: "software",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 387,
  //       SourceName: "Hydro One Inc",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 388,
  //       SourceName: "Performance Tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 389,
  //       SourceName: "Big Data Hadoop Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 390,
  //       SourceName: "qa,service now",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 391,
  //       SourceName: "Sr.Test Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 392,
  //       SourceName: "Jr.Software Test Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 393,
  //       SourceName: "QA -Tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 394,
  //       SourceName: "QA Manual Tester",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 395,
  //       SourceName: "Sr. Payroll Analyst (Remote)",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 396,
  //       SourceName: "Jr.Java developer ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 397,
  //       SourceName: "UX/UI specialist",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 398,
  //       SourceName: "Jr.Test Analyst ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 399,
  //       SourceName: "Jr.Hiring Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 400,
  //       SourceName: "Staff4",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 401,
  //       SourceName: "Staff 3",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 402,
  //       SourceName: "BA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 404,
  //       SourceName: "CTO",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 405,
  //       SourceName: "Analyst",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 406,
  //       SourceName: "UI Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 409,
  //       SourceName: "Teacher",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 410,
  //       SourceName: "Sales Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 411,
  //       SourceName: "Marketing Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 412,
  //       SourceName: "PMO Lead",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 413,
  //       SourceName: "JavaScript Developer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 414,
  //       SourceName: "Se.HR Executive",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 415,
  //       SourceName: "Sr.HR Executive",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 416,
  //       SourceName: "Career Consultant",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 417,
  //       SourceName: "Automated Testing Software Engineer",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 418,
  //       SourceName: "Haddop ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 419,
  //       SourceName: "UI DEV",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 420,
  //       SourceName: "test",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 421,
  //       SourceName: "test HM",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 422,
  //       SourceName: "REC",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 423,
  //       SourceName: "RECuiter32423",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 424,
  //       SourceName: "etertertdfte",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 425,
  //       SourceName: "tenant admin",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 426,
  //       SourceName: "testadmin",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 427,
  //       SourceName: "testing recuiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 428,
  //       SourceName: "test recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 429,
  //       SourceName: "asa",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 430,
  //       SourceName: "gfd",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 431,
  //       SourceName: "asd",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 432,
  //       SourceName: "mern recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 433,
  //       SourceName: "Dev",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 434,
  //       SourceName: "JavaScript recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 435,
  //       SourceName: "Perl Recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 436,
  //       SourceName: "hiring manger",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 437,
  //       SourceName: "HR",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 438,
  //       SourceName: "IT head",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 439,
  //       SourceName: "IT Recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 440,
  //       SourceName: "HMForIntake",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 441,
  //       SourceName: "HM Senior",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 442,
  //       SourceName: "eTeam inc. Recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 443,
  //       SourceName: "CEO",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 444,
  //       SourceName: "QC",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 445,
  //       SourceName: "Sr Recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 446,
  //       SourceName: "Technical Lead",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 447,
  //       SourceName: "Technical recruiter ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 448,
  //       SourceName: "Technical Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 449,
  //       SourceName: "Manage",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 450,
  //       SourceName: "Sales Managera",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 451,
  //       SourceName: "Java Talent HM",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 452,
  //       SourceName: "WebDevHM",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 453,
  //       SourceName: "SAPRT",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 454,
  //       SourceName: "MongoDBRT",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 455,
  //       SourceName: "SAP DevHM",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 456,
  //       SourceName: "Technical Mgr",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 457,
  //       SourceName: "ad",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 458,
  //       SourceName: "HM LEAD",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 459,
  //       SourceName: "SAP Recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 460,
  //       SourceName: "SAP HM",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 461,
  //       SourceName: "SAPHMSA",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 462,
  //       SourceName: "Node JSR",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 463,
  //       SourceName: "SDET",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 464,
  //       SourceName: "technical hr",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 465,
  //       SourceName: "Technical Manger",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 466,
  //       SourceName: "Non technical recruiter ",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 467,
  //       SourceName: "Technical Hiring manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 468,
  //       SourceName: "NON-Technical Hiring manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 469,
  //       SourceName: "Service Delivery Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 470,
  //       SourceName: "Community Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 471,
  //       SourceName: "Sr. Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 472,
  //       SourceName: "Architect",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 473,
  //       SourceName: "Sr.Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 474,
  //       SourceName: "Sr.Recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 475,
  //       SourceName: "Test Lead",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 476,
  //       SourceName: "PMO",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 477,
  //       SourceName: "HR Manager",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 478,
  //       SourceName: "HRM",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 479,
  //       SourceName: "Sr. Recruiter",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 480,
  //       SourceName: "d",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 481,
  //       SourceName: "Senior HM",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 482,
  //       SourceName: "Node Dev",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 483,
  //       SourceName: "React Dev",
  //       TypeName: "PositionTitle",
  //     },
  //     {
  //       id_candidateSource: 484,
  //       SourceName: "n",
  //       TypeName: "PositionTitle",
  //     },
  //   ],
  //   PositionType: [
  //     {
  //       id_candidateSource: 1,
  //       SourceName: "Contract",
  //       TypeName: "PositionType",
  //     },
  //     {
  //       id_candidateSource: 2,
  //       SourceName: "Temp to Hire",
  //       TypeName: "PositionType",
  //     },
  //     {
  //       id_candidateSource: 3,
  //       SourceName: "Fulltime",
  //       TypeName: "PositionType",
  //     },
  //     {
  //       id_candidateSource: 4,
  //       SourceName: "Part Time",
  //       TypeName: "PositionType",
  //     },
  //     {
  //       id_candidateSource: 5,
  //       SourceName: "Volunteer",
  //       TypeName: "PositionType",
  //     },
  //   ],
  //   QualificationType: [
  //     {
  //       id_candidateSource: 1,
  //       SourceName: "Professional",
  //       TypeName: "QualificationType",
  //     },
  //     {
  //       id_candidateSource: 2,
  //       SourceName: "Doctorate",
  //       TypeName: "QualificationType",
  //     },
  //     {
  //       id_candidateSource: 3,
  //       SourceName: "Master's Degree",
  //       TypeName: "QualificationType",
  //     },
  //     {
  //       id_candidateSource: 4,
  //       SourceName: "Bachelor's Degree",
  //       TypeName: "QualificationType",
  //     },
  //     {
  //       id_candidateSource: 5,
  //       SourceName: "Some College Coursework Completed",
  //       TypeName: "QualificationType",
  //     },
  //     {
  //       id_candidateSource: 6,
  //       SourceName: "Vocational",
  //       TypeName: "QualificationType",
  //     },
  //     {
  //       id_candidateSource: 7,
  //       SourceName: "Certification",
  //       TypeName: "QualificationType",
  //     },
  //     {
  //       id_candidateSource: 8,
  //       SourceName: "Highschool or Equivalent",
  //       TypeName: "QualificationType",
  //     },
  //     {
  //       id_candidateSource: 9,
  //       SourceName: "Some Highschool Course Work",
  //       TypeName: "QualificationType",
  //     },
  //     {
  //       id_candidateSource: 10,
  //       SourceName: "NA",
  //       TypeName: "QualificationType",
  //     },
  //   ],
  //   skillSet: [
  //     {
  //       id_candidateSource: 1,
  //       SourceName: "Accounting/Finance",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 2,
  //       SourceName: "Marketing",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 3,
  //       SourceName: "Administrative/Clerical",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 4,
  //       SourceName: "Business Professional",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 5,
  //       SourceName: "Call Center",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 6,
  //       SourceName: "Clinical",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 7,
  //       SourceName: "Communications",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 8,
  //       SourceName: "Creative",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 9,
  //       SourceName: "Engineering",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 10,
  //       SourceName: "Hardware",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 11,
  //       SourceName: "Healthcare",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 12,
  //       SourceName: "Human Resources",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 13,
  //       SourceName: "Information Technology",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 14,
  //       SourceName: "Legal",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 15,
  //       SourceName: "Medical",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 16,
  //       SourceName: "Operations",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 17,
  //       SourceName: "Procurement",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 18,
  //       SourceName: "Professional",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 19,
  //       SourceName: "R&D",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 20,
  //       SourceName: "Sales",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 21,
  //       SourceName: "Scientific",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 22,
  //       SourceName: "Technical (Non-IT)",
  //       TypeName: "skillSet",
  //     },
  //     {
  //       id_candidateSource: 23,
  //       SourceName: "Technical Support",
  //       TypeName: "skillSet",
  //     },
  //   ],
  //   Source: [
  //     {
  //       id_candidateSource: 4,
  //       SourceName: "Bulk Uploaded Resume",
  //       TypeName: "Source",
  //     },
  //     {
  //       id_candidateSource: 5,
  //       SourceName: "Call Center Jobs",
  //       TypeName: "Source",
  //     },
  //     {
  //       id_candidateSource: 8,
  //       SourceName: "CareerBuilder",
  //       TypeName: "Source",
  //     },
  //     {
  //       id_candidateSource: 6,
  //       SourceName: "Dice",
  //       TypeName: "Source",
  //     },
  //     {
  //       id_candidateSource: 1,
  //       SourceName: "Facebook",
  //       TypeName: "Source",
  //     },
  //     {
  //       id_candidateSource: 7,
  //       SourceName: "Indeed",
  //       TypeName: "Source",
  //     },
  //     {
  //       id_candidateSource: 9,
  //       SourceName: "JobCircle",
  //       TypeName: "Source",
  //     },
  //     {
  //       id_candidateSource: 10,
  //       SourceName: "JobServeUK",
  //       TypeName: "Source",
  //     },
  //     {
  //       id_candidateSource: 2,
  //       SourceName: "LinkedIn",
  //       TypeName: "Source",
  //     },
  //     {
  //       id_candidateSource: 3,
  //       SourceName: "Twitter",
  //       TypeName: "Source",
  //     },
  //   ],
  //   Status: [
  //     {
  //       id_candidateSource: 21,
  //       SourceName: "Active",
  //       TypeName: "Status",
  //     },
  //     {
  //       id_candidateSource: 22,
  //       SourceName: "InActive",
  //       TypeName: "Status",
  //     },
  //     {
  //       id_candidateSource: 23,
  //       SourceName: "Deactive",
  //       TypeName: "Status",
  //     },
  //   ],
  // };
  console.log("dropDownData", dropDownData);

  // length 164
  // var currency_list = [
  //   { code: "AFA" },
  //   { code: "ALL" },
  //   { code: "DZD" },
  //   { code: "AOA" },
  //   { code: "ARS" },
  //   { code: "AMD" },
  //   { code: "AWG" },
  //   { code: "AUD" },
  //   { code: "AZN" },
  //   { code: "BSD" },
  //   { code: "BHD" },
  //   { code: "BDT" },
  //   { code: "BBD" },
  //   { code: "BYR" },
  //   { code: "BEF" },
  //   { code: "BZD" },
  //   { code: "BMD" },
  //   { code: "BTN" },
  //   { code: "BTC" },
  //   { code: "BOB" },
  //   { code: "BAM" },
  //   { code: "BWP" },
  //   { code: "BRL" },
  //   { code: "GBP" },
  //   { code: "BND" },
  //   { code: "BGN" },
  //   { code: "BIF" },
  //   { code: "KHR" },
  //   { code: "CAD" },
  //   { code: "CVE" },
  //   { code: "KYD" },
  //   { code: "XOF" },
  //   { code: "XAF" },
  //   { code: "XPF" },
  //   { code: "CLP" },
  //   { code: "CNY" },
  //   { code: "COP" },
  //   { code: "KMF" },
  //   { code: "CDF" },
  //   { code: "CRC" },
  //   { code: "HRK" },
  //   { code: "CUC" },
  //   { code: "CZK" },
  //   { code: "DKK" },
  //   { code: "DJF" },
  //   { code: "DOP" },
  //   { code: "XCD" },
  //   { code: "EGP" },
  //   { code: "ERN" },
  //   { code: "EEK" },
  //   { code: "ETB" },
  //   { code: "EUR" },
  //   { code: "FKP" },
  //   { code: "FJD" },
  //   { code: "GMD" },
  //   { code: "GEL" },
  //   { code: "DEM" },
  //   { code: "GHS" },
  //   { code: "GIP" },
  //   { code: "GRD" },
  //   { code: "GTQ" },
  //   { code: "GNF" },
  //   { code: "GYD" },
  //   { code: "HTG" },
  //   { code: "HNL" },
  //   { code: "HKD" },
  //   { code: "HUF" },
  //   { code: "ISK" },
  //   { code: "INR" },
  //   { code: "IDR" },
  //   { code: "IRR" },
  //   { code: "IQD" },
  //   { code: "ILS" },
  //   { code: "ITL" },
  //   { code: "JMD" },
  //   { code: "JPY" },
  //   { code: "JOD" },
  //   { code: "KZT" },
  //   { code: "KES" },
  //   { code: "KWD" },
  //   { code: "KGS" },
  //   { code: "LAK" },
  //   { code: "LVL" },
  //   { code: "LBP" },
  //   { code: "LSL" },
  //   { code: "LRD" },
  //   { code: "LYD" },
  //   { code: "LTL" },
  //   { code: "MOP" },
  //   { code: "MKD" },
  //   { code: "MGA" },
  //   { code: "MWK" },
  //   { code: "MYR" },
  //   { code: "MVR" },
  //   { code: "MRO" },
  //   { code: "MUR" },
  //   { code: "MXN" },
  //   { code: "MDL" },
  //   { code: "MNT" },
  //   { code: "MAD" },
  //   { code: "MZM" },
  //   { code: "MMK" },
  //   { code: "NAD" },
  //   { code: "NPR" },
  //   { code: "ANG" },
  //   { code: "TWD" },
  //   { code: "NZD" },
  //   { code: "NIO" },
  //   { code: "NGN" },
  //   { code: "KPW" },
  //   { code: "NOK" },
  //   { code: "OMR" },
  //   { code: "PKR" },
  //   { code: "PAB" },
  //   { code: "PGK" },
  //   { code: "PYG" },
  //   { code: "PEN" },
  //   { code: "PHP" },
  //   { code: "PLN" },
  //   { code: "QAR" },
  //   { code: "RON" },
  //   { code: "RUB" },
  //   { code: "RWF" },
  //   { code: "SVC" },
  //   { code: "WST" },
  //   { code: "SAR" },
  //   { code: "RSD" },
  //   { code: "SCR" },
  //   { code: "SLL" },
  //   { code: "SGD" },
  //   { code: "SKK" },
  //   { code: "SBD" },
  //   { code: "SOS" },
  //   { code: "ZAR" },
  //   { code: "KRW" },
  //   { code: "XDR" },
  //   { code: "LKR" },
  //   { code: "SHP" },
  //   { code: "SDG" },
  //   { code: "SRD" },
  //   { code: "SZL" },
  //   { code: "SEK" },
  //   { code: "CHF" },
  //   { code: "SYP" },
  //   { code: "STD" },
  //   { code: "TJS" },
  //   { code: "TZS" },
  //   { code: "THB" },
  //   { code: "TOP" },
  //   { code: "TTD" },
  //   { code: "TND" },
  //   { code: "TRY" },
  //   { code: "TMT" },
  //   { code: "UGX" },
  //   { code: "UAH" },
  //   { code: "AED" },
  //   { code: "UYU" },
  //   { code: "USD" },
  //   { code: "UZS" },
  //   { code: "VUV" },
  //   { code: "VEF" },
  //   { code: "VND" },
  //   { code: "YER" },
  //   { code: "ZMK" },
  // ];

  const visaStatus = [
    "Citizen",
    "Green Card",
    "GC EAD",
    "H1B",
    "H4 EAD",
    "L2EAD",
    "Not Required",
    "OPT EAD",
    "TN1",
    "TN2",
  ];

  let positionTypeOption = dropDownData.PositionType?.map((item) => {
    const obj = { value: item.SourceName, label: item.SourceName };
    return obj;
  });
  positionTypeOption = _.sortBy(positionTypeOption, ["value"]);

  let skillSetOption = dropDownData.skillSet?.map((item) => {
    const obj = { value: item.SourceName, label: item.SourceName };
    return obj;
  });
  skillSetOption = _.sortBy(skillSetOption, ["value"]);

  const deleteLocation = (index) => {
    let current = [...preferredLocation];
    current.splice(index, 1);
    setPreferredLocation(current);
    props.changePreferredLocation(current);
  };

  const handlePositiontype = (e) => {
    setPositionTypes(e);
    props.setProfileInfo({
      ...props.profileInfo,
      positionTypes: e.map((pType) => pType.value),
    });
  };

  const handleSkillSet = (e) => {
    setSkillSet(e);
    props.setProfileInfo({
      ...props.profileInfo,
      skillSet: e.map((skillSet) => skillSet.value),
    });
  };

  const handleChangePrimarySkills = (skills) => {
    props.setProfileInfo({ ...props.profileInfo, primarySkills: skills });
  };

  const handleChangeSecondarySkills = (skills) => {
    props.setProfileInfo({ ...props.profileInfo, secondarySkills: skills });
  };

  const handleChangeInputPrimarySkills = (skills) => {
    setPrimarySkillInput(skills);
  };

  const handleChangeInputSecondarySkills = (skills) => {
    setSecondarySkillSInput(skills);
  };

  const handleChange = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      [e.target.id]: e.target.value,
    });
  };

  const handleChangeExperienceYear = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      experienceYear: e.target.value,
    });
  };

  const handleChangeExperienceMonth = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      experienceMonth: e.target.value,
    });
  };

  const onRemoteSwitch = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      [e.target.id]: e.target.checked,
    });
  };

  const onIsfresherSwitch = (e) => {
    if (e.target.id == "isFresher" && e.target.checked === true) {
      // disable experince tab
      console.log(e.target.id, e.target.checked);
      props.setProfileInfo({ ...props.profileInfo, experienceLevel: " " });
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceLevel: "Junior :0-3 years",
      });
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceYear: "",
        experienceMonth: "",
        currentJobTitle: "",
      });
    } else {
      // enable tab
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceLevel: "",
        experienceYear: "",
        experienceMonth: "",
        currentJobTitle: "",
      });
    }
  };
  //File upload block
  const setFileForSending = (file) => {
    const { name } = file;
    const type = name.split(".")[1];
    if (type === "pdf" || type === "docx" || type === "doc") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        props.setProfileInfo({
          ...props.profileInfo,
          fileName: name,
          base64: event.target.result,
        });
        toast.success("File uploaded successfully");
      };
    } else {
      toast.error("Please Upload Only PDF or WORD Document");
    }
  };

  const onRemove = (e) => {
    props.setProfileInfo({ ...props.profileInfo, fileName: "", base64: "" });
    toast.success("File removed successfully");
  };
  // File upload on drag
  const onDragDropChangeHandler = (e) => {
    setFileForSending(e[0]);
  };

  // Auto suggestion in tags input
  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.SourceName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue?.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropDownData.CandidateSkills.filter((item) =>
              item.SourceName.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion.SourceName}</div>;

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.SourceName);
        }}
        theme={theme}
      />
    );
  };

  return (
    <>
      <div>
        <div class="card-body">
          {/* <div class="row mb-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <select
                  id="source"
                  class="form-select"
                  onChange={handleChange}
                  value={props.profileInfo.source}
                >
                  <option value="" selected>
                    Select Source
                  </option>
                  {dropDownData?.Source?.sort((a, b) =>
                    a.SourceName > b.SourceName ? 1 : -1
                  ).map((item) => (
                    <option value={item.SourceName}>{item.SourceName}</option>
                  ))}
                </select>
                <label>Source</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <select
                  id="visaStatus"
                  class="form-select"
                  onChange={handleChange}
                  value={props.profileInfo.visaStatus}
                >
                  <option value="" selected>
                    Select Status
                  </option>
                  {visaStatus.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                <label>Visa Status</label>
              </div>
            </div>
          </div> */}

          <div className="row ">
            <div className="col-lg-6">
              <div className="form-floating">
                <input
                  type="text"
                  className={"form-control"}
                  name="currentPosition"
                  id="currentJobTitle"
                  placeholder="Enter a group name"
                  disabled={props.profileInfo.isFresher}
                  value={props.profileInfo.currentJobTitle}
                  onChange={handleChange}
                />
                <label>Your current job title*</label>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-floating">
                <input
                  type="text"
                  className={"form-control"}
                  name="currentEmployer"
                  id="currentEmployer"
                  placeholder="Enter a group name"
                  disabled={props.profileInfo.isFresher}
                  value={props.profileInfo.currentEmployer}
                  onChange={handleChange}
                  // value={groupData?.poolName}
                />
                <label>Your current employer</label>
              </div>
            </div>
          </div>

          {/* <div class="row mb-2">
            <div class="col-lg-12">
              <label>Salary* </label>
            </div>
          </div> */}
          {/* <div class="row mb-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  placeholder="Preferred Salary"
                  id="preferredSalary"
                  value={props.profileInfo.preferredSalary}
                  
                  onChange={(e) => {
                    if (e.target.value === "0") {
                      toast.error("Cannot Be Zero");
                    } else if (+e.target.value < 0) {
                      toast.error("Negative numbers are not allowed");
                    } else {
                      handleChange(e);
                    }
                  }}
                />
                <label>Minimum Annual Salary*</label>
              </div>
              <small className="validation">
                {props.profileInfoValidation.preferredSalary}
              </small>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  placeholder="Minimum Contract Rate"
                  id="minContractRate"
                  value={props.profileInfo.minContractRate}
                  //onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value === "0") {
                      toast.error("Cannot Be Zero");
                    } else if (+e.target.value < 0) {
                      toast.error("Negative numbers are not allowed");
                    } else {
                      handleChange(e);
                    }
                  }}
                />
                <label>Minimum Hourly Rate*</label>
              </div>
              <small className="validation">
                {props.profileInfoValidation.minContractRate}
              </small>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <select
                  class="form-select"
                  disabled={!props.profileInfo.preferredSalary > 0}
                  id="preferredSalaryCurrency"
                  style={{
                    border:
                      props.profileInfoValidation.preferredSalaryCurrency &&
                      "2px solid #ff0000",
                  }}
                  value={props.profileInfo.preferredSalaryCurrency}
                  onChange={handleChange}
                >
                  <option value="" selected disabled>
                    Select Type
                  </option>
              
                  {currency_list.map((item) => (
                    <option value={item.code}>{item.code}</option>
                  ))}
                </select>
                <label>
                  Currency Type{props.profileInfo.preferredSalary > 0 && "*"}
                </label>
              </div>
              <small className="validation">
                {props.profileInfoValidation.preferredSalaryCurrency}
              </small>
            </div>
            
          </div> */}
          {/* <div class="row mb-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  placeholder="Minimum Contract Rate"
                  id="minContractRate"
                  value={props.profileInfo.minContractRate}
                  //onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value === "0") {
                      toast.error("Cannot Be Zero");
                    } else if (+e.target.value < 0) {
                      toast.error("Negative numbers are not allowed");
                    } else {
                      handleChange(e);
                    }
                  }}
                />
                <label>Minimum Contract Rate</label>
              </div>
              <small className="validation">
                {props.profileInfoValidation.minContractRate}
              </small>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <select
                  disabled={!props.profileInfo.minContractRate > 0}
                  class="form-select"
                  id="minContractRateCurrency"
                  style={{
                    border:
                      props.profileInfoValidation.minContractRateCurrency &&
                      "2px solid #ff0000",
                  }}
                  value={props.profileInfo.minContractRateCurrency}
                  onChange={handleChange}
                >
                  <option value="" selected disabled>
                    Select Type
                  </option>
                  {dropDownData?.CurrencyTime?.sort((a, b) =>
                    a.SourceName > b.SourceName ? 1 : -1
                  ).map((item) => (
                    <option value={item.SourceName}>{item.SourceName}</option>
                  ))}
                </select>
                <label>
                  Currency Type{props.profileInfo.minContractRate > 0 && "*"}
                </label>
              </div>
              <small className="validation">
                {props.profileInfoValidation.minContractRateCurrency}
              </small>
            </div>
          </div> */}
          {/* <div class="row mb-2">
            <div class="col-lg-6 mt-2">
              <div
                class="form-floating ml-auto"
                style={{ margin: "15px 0px 0px 50px" }}
              >
                <div className=" form-switch d-flex align-items-center ">
                  <label
                    className="form-check-label pt-0"
                    htmlFor="remoteSwitch"
                  >
                    Is Fresher?
                  </label>
                  <input
                    className="form-check-input ml-3"
                    type="checkbox"
                    id="isFresher"
                    value={props.profileInfo.isFresher}
                    onChange={(e) => onIsfresherSwitch(e)}
                  />
                </div>
              </div>
            </div>
          </div> */}

          <div class="row mb-12">
            <div class="col-lg-6">
              <label>Your total work experience*</label>
            </div>
            {/* <div class="col-lg-6">
              <label>Current Job Position* </label>
            </div> */}
            <div class="col-lg-6">
              <label>Social Media Link</label>
            </div>
          </div>
          <div class="row mb-12">
            <div class="col-lg-6">
              <div class="form-floating">
                <div className="d-flex">
                  <div className="mr-3 form-floating">
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      max="100"
                      style={{
                        height: "57px",
                        border: "1px solid #ced4da",
                        fontSize: "15px",
                      }}
                      name="years"
                      value={props.profileInfo.experienceYear}
                      onChange={(e) => {
                        if (e.target.value === "0") {
                          toast.error("Cannot Be Zero");
                        } else if (+e.target.value < 0) {
                          toast.error("Negative numbers are not allowed");
                        } else {
                          handleChangeExperienceYear(e);
                        }
                      }}
                      placeholder="Year"
                      disabled={props.profileInfo.isFresher}
                    />
                    <label>Years</label>
                    {props.profileInfo.isFresher === false && (
                      <small className="validation">
                        {props.profileInfoValidation.experienceYear}
                      </small>
                    )}
                  </div>

                  <div className="mr-3 form-floating">
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      style={{
                        height: "57px",
                        border: "1px solid #ced4da",
                        fontSize: "15px",
                        width: "108%",
                      }}
                      name="months"
                      value={props.profileInfo.experienceMonth}
                      onChange={(e) => {
                        if (+e.target.value < 0) {
                          toast.error("Negative numbers are not allowed");
                        } else {
                          handleChangeExperienceMonth(e);
                        }
                      }}
                      placeholder="Months"
                      disabled={props.profileInfo.isFresher}
                    />
                    <label>Months</label>
                    {props.profileInfo.isFresher === false && (
                      <small className="validation">
                        {props.profileInfoValidation.experienceMonth}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Linkedin ID"
                  id="linkedIn"
                  value={props.profileInfo.linkedIn}
                  onChange={handleChange}
                />
                <label>Linkedin ID</label>
              </div>
            </div>

            {/* <div class="col-lg-6">
              <div class="form-floating ">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Job Title"
                  id="jobtitle"
                  disabled={props.profileInfo.isFresher}
                  value={props.profileInfo.currentJobTitle}
                  onChange={(e) =>
                    props.setProfileInfo({
                      ...props.profileInfo,
                      currentJobTitle: e.target.value,
                    })
                  }
                />
                <label>Job Title</label>
              </div>
              {props.profileInfo.isFresher === false && (
                <small className="validation">
                  {props.profileInfoValidation.currentJobTitle}
                </small>
              )}
            </div> */}
          </div>

          {/* SOcial */}
          {/* <div class="row mb-2">
            <div class="col-lg-12">
              <label>Social </label>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Faceebook ID"
                  id="facebook"
                  value={props.profileInfo.facebook}
                  onChange={handleChange}
                />
                <label>Facebook ID</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Twitter ID"
                  id="twitter"
                  value={props.profileInfo.twitter}
                  onChange={handleChange}
                />
                <label>Twitter ID</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Linkedin ID"
                  id="linkedIn"
                  value={props.profileInfo.linkedIn}
                  onChange={handleChange}
                />
                <label>Linkedin ID</label>
              </div>
            </div>
          </div> */}

          {/* <div class="row mb-2">
            <div class="col-12">
              <div class="mb-2">
                <label>Position Types </label>
              </div>
              <Select
                isMulti
                value={positionTypes}
                onChange={handlePositiontype}
                options={positionTypeOption}
                styles={customStyles}
                isSearchable={false}
              />
            </div>
          </div> */}
          {/* <div class="row  mt-2">
            <div class="col-12">
              <div class="mb-2 d-flex">
                <label>Preferred Locations* </label>
                <div
                  class="form-floating ml-auto"
                  style={{ margin: "15px 0px 0px 50px" }}
                >
                  <div className="form-check form-switch d-flex align-items-center">
                    <label
                      className="form-check-label pt-0"
                      htmlFor="remoteSwitch"
                    >
                      Open For Remote
                    </label>
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="isFresher"
                      checked={props.profileInfo.isRemote}
                      onChange={(e) =>
                        props.setProfileInfo({
                          ...props.profileInfo,
                          isRemote: e.target.checked,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div>
                <div class="form-inline-edit">
                  <div>
                    <div>
                      <div className="m-2">
                        {preferredLocation.map((item, index) => (
                          <>
                            <span className="tag tag-blue3 mb-1 mr-1">
                              {item}
                              <button
                                className="btn btn-text"
                                onClick={() => deleteLocation(index)}
                              >
                                <strong className="font-18 font-black">
                                  &times;
                                </strong>
                              </button>
                            </span>
                          </>
                        ))}
                      </div>
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <div className="form-floating w-100">
                          <PreferredLocation
                            setLocation={(loc) => {
                              if (preferredLocation.includes(loc)) {
                                toast.error(
                                  "You Have Already Added This Location"
                                );
                              } else {
                                setPreferredLocation((cur) => [...cur, loc]);
                                props.changePreferredLocation([
                                  ...preferredLocation,
                                  loc,
                                ]);
                              }
                            }}
                          />
                        </div>
                        <div className="ml-auto mr-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <small className="validation">
                {props.profileInfoValidation.preferredLocation}
              </small>
            </div>
          </div> */}
          <div class="row " style={{ marginTop: "-5px" }}>
            <div class="col-12">
              <div class=" ">
                <label>Skill Sets* </label>
              </div>
              <TagsInput
                renderInput={autocompleteRenderInput}
                value={props?.profileInfo?.primarySkills}
                onChange={handleChangePrimarySkills}
                inputValue={primarySkillinput}
                onChangeInput={handleChangeInputPrimarySkills}
                placeholder="Type a location and hit enter"
                inputProps={{
                  placeholder: "Add a skill",
                }}
              />
            </div>
            <small className="validation">
              {props.profileInfoValidation.primarySkills}
            </small>
          </div>
          {/* <div class="row mb-2">
            <div class="col-12">
              <div class="mb-2">
                <label>Secondary Skills </label>
              </div>
              <TagsInput
                renderInput={autocompleteRenderInput}
                value={props.profileInfo.secondarySkills}
                onChange={handleChangeSecondarySkills}
                inputValue={secondarySkillsInput}
                onChangeInput={handleChangeInputSecondarySkills}
                inputProps={{
                  placeholder: "Add a skill",
                }}
              />
            </div>
          </div> */}
          {/* <div class="row mb-2">
            <div class="col-12">
              <div class="mb-2">
                <label>Industry</label>
                <button
                  onClick={() =>
                    console.log(dropDownData.skillSet.map((i) => i.SourceName))
                  }
                  className="btn btn-text"
                ></button>
              </div>
              <Select
                isMulti
                value={skillSet}
                options={skillSetOption}
                onChange={handleSkillSet}
                styles={customStyles}
                isSearchable={true}
              />
            </div>
          </div> */}
          <div class="row mb-2">
            <div class="col-12" style={{ marginTop: "-5px" }}>
              <div class="mb-1">
                <label>Description</label>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Description"
                    id="description"
                    value={props.profileInfo.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          {props.parent === "DirectSourcing" ? null : (
            <div class="row">
              <div class="col-12">
                <div class="p-5 p-0-600">
                  <h6 class="mt-2">Upload Resumes</h6>
                  <p>Accepted File Type: .docx, .doc, .pdf</p>
                  <div class="col-sm-12">
                    <Dropzone onDrop={onDragDropChangeHandler}>
                      {({ getRootProps, getInputProps }) => (
                        <section>
                          <div {...getRootProps()}>
                            <div class="talentuploadmaindiv">
                              <div class="talentuploadicon">
                                <label>
                                  <i class="fas fa-cloud-upload-alt"></i>
                                </label>
                                <input
                                  type="file"
                                  name="myfile"
                                  {...getInputProps()}
                                />
                              </div>
                              {props.profileInfo.fileName ? (
                                <div class="talentuploadtext">
                                  {props.profileInfo.fileName}
                                </div>
                              ) : (
                                <div class="talentuploadtext">
                                  Click or Drag and Drop To Upload
                                </div>
                              )}
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                    {props.profileInfo.fileName && (
                      <div class="modal-footer mt-4 mb-0">
                        <button
                          type="button"
                          className="btn btn-secondary mr-2"
                          onClick={onRemove}
                        >
                          Remove
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ProfileInfoDS;
