import { toast } from "react-toastify";
import { get } from "../../service/ApiService";

export const GET_JOB_TEMPLATE_LIST_BEGIN = "GET_JOB_TEMPLATE_LIST_BEGIN";
export const GET_JOB_TEMPLATE_LIST_SUCCESS = "GET_JOB_TEMPLATE_LIST_SUCCESS";
export const GET_JOB_TEMPLATE_LIST_FAILURE = "GET_JOB_TEMPLATE_LIST_FAILURE";

export const getJobTemplateList = () => async (dispatch) => {
    try {
      dispatch({
        type: GET_JOB_TEMPLATE_LIST_BEGIN,
      });
      const res = await get("/job/fetchTemplates");
      if (res.status === 200) {
        dispatch({
          type: GET_JOB_TEMPLATE_LIST_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_JOB_TEMPLATE_LIST_FAILURE,
        payload: error?.response?.data,
      });
      toast.error(error?.response?.data?.errMessage);
    }
  };