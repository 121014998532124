import React, { useEffect, useState } from "react";
import PoolScreeningQues from "./applyJob_v2/PoolScreeningQues";
import { get, post } from "../../../service/ApiService";
import { useHistory, useParams } from "react-router";
import Loader from "../../Loader/Loader";
import { Modal } from "react-bootstrap";

const ApplyScreening = () => {
  const [jobData, setJobData] = useState({});
  const { id, recruiterid, dispositionId, candidateId } = useParams();
  const [candidateDetails, setCandidateDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [apply, setApply] = useState(true);
  const history = useHistory();
  const [dispositionID, setDispositionID] = useState({
    dispositionID: dispositionId,
    candidateID: candidateId,
  });
  const [screeningStatus, setScreeningStatus] = useState(false);

  const getPoolById = async () => {
    const params = { poolId: id };
    setLoading(true);
    await post("/talent/getPoolInfo", params).then(async (res) => {
      // setTenantData(res.data[0]);
      setJobData(res.data[0]);
      const data = { candidateId: candidateId };
      await post("/candidate/getcandidatebyid", data).then((candidateData) => {
        setCandidateDetails(candidateData.data[0]);
        setLoading(false);
      });
    });
  };

  const getScreeningStatus = async () => {
    const params = { dispositionId: dispositionId };
    setLoading(true);
    await post("/talent/getscreeningstatus", params).then(async (res) => {
      const screeningQuestion = res.data[0]?.screeningQuestions;
      if (screeningQuestion && JSON.parse(screeningQuestion).length > 0) {
        setScreeningStatus(true);
      }
      getPoolById();
    });
  };

  useEffect(() => {
    getScreeningStatus();
  }, []);

  const handleCancel = () => {
    setApply(false);
    history.push(`/career/home`);
  };

  const handleHide = () => {
    // history.push(`/careers/joblist`)
  };

  return (
    <>
      {loading && <Loader />}
      {Object.keys(jobData).length !== 0 && (
        <Modal show={apply} onHide={handleHide} size="lg">
          <Modal.Header>
            <div style={{ zIndex: "1000" }}>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => handleCancel()}
                className="close p-0 bl-modal-close-btn mx-1"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          </Modal.Header>
          <Modal.Body>
            <PoolScreeningQues
              job={jobData}
              screeningStatus={screeningStatus}
              // setScreeningAnswers={setScreeningAnswers}
              setApply={setApply}
              dispositionID={dispositionID}
              candidateDetails={candidateDetails}
            />
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};

export default ApplyScreening;
