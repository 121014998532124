import React, { useEffect, useState } from "react";
import { getInitial, dataDiff } from "../../function/CommonFunction";
import vettedIcon from "../../../images/high5-mark-circle-dark.svg";
import CandidateView from "../../modal/talentprofile/CandidateProfile";
import Avatar from "react-avatar";
import moment from "moment";
import { countryObj } from "../../../constants/Constants";
import { Link } from "react-router-dom";

function CandidateGridView(props) {
  const [showCandidateProfile, setShowCandidateProfile] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [candidateData, setCandidateData] = useState({});
  const [canId, setCanId] = useState(null);

  const [newRequestList, setnewRequestList] = useState([]);
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [requestTitleArray, setRequestTitleArray] = useState([]);
  const [pageType, setPageType] = useState("");
  let pageSize = 10;
  let dataSize = 12;

  useEffect(() => {
    if (pageType === "up") {
      if ((props.currentPage - 1) % pageSize === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(
            props.currentPage - 1,
            props.currentPage + (pageSize - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (props.currentPage % pageSize === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(
            props.currentPage - pageSize,
            props.currentPage
          )
        );
      }
    }
  }, [props.currentPage]);

  useEffect(() => {
    setnewRequestList(props.arrayList);
    setRequestTitleArray(
      new Array(Math.ceil(props.arrayList.length / dataSize))
        .fill()
        .map((_, i) => i + 1)
    );
    setPaginationGroup(
      new Array(Math.ceil(props.arrayList.length / dataSize))
        .fill()
        .map((_, i) => i + 1)
        .slice(0, pageSize)
    );
  }, [props.arrayList]);

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    props.setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = props.currentPage * dataSize - dataSize;
    const endIndex = startIndex + +dataSize;
    return newRequestList.slice(startIndex, endIndex);
  };

  const handleModel = () => {
    setShowCandidateProfile(false);
  };

  const onProfileClick = (candidate) => {
    setCandidateData(candidate);
    setShowCandidateProfile(true);
  };

  const findStatusNameFromList = (status, value) => {
    let result = false;
    status.forEach((item) => {
      if (item.statusId === value) {
        result = true;
      }
    });
    return result;
  };

  return (
    <>
      <div class="row  d-flex-1200">
        {getPaginatedData().map((candidate, i) => (
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex">
            <a
              onClick={(e) => e.preventDefault()}
              class="card col card-tag-bottom mb-4 card-lg card-candidates-grid p-relative"
            >
              {/* {(candidate.MCQ === true ||
                candidate.onewayvetting === true ||
                candidate.twowayvetting === true ||
                candidate.codesignalvetting === true) && (
                <div class="tag tag-coral tag-horizontal-status">
                  <img width="16px" alt="" class="mr-1" src={vettedIcon} />
                  Vetted
                </div>
              )} */}
              <div class="card-body">
                <div class="d-flex mb-2 align-items-center">
                  <div class="mr-3">
                    {candidate.ImageURL ? (
                      <span class="avatar avatar-bordered">
                        <img alt="" src={candidate.ImageURL} />
                        {findStatusNameFromList(candidate.statusData, "6") && (
                          <span class="jewel"></span>
                        )}
                      </span>
                    ) : (
                      <span>
                        <Avatar
                          size="55"
                          name={
                            candidate.fullText.firstName +
                            " " +
                            candidate.fullText.lastName
                          }
                          round="80px"
                        />
                        {/* {getInitial(`${candidate.fullText.firstName} ${candidate.fullText.lastName}`)} */}
                        {findStatusNameFromList(candidate.statusData, "6") && (
                          <span></span>
                        )}
                      </span>
                    )}
                  </div>
                  <div onClick={() => onProfileClick(candidate)}>
                    <h6 class="mb-0">
                      {candidate.fullText.firstName?.charAt(0)?.toUpperCase() +
                        candidate.fullText.firstName?.slice(1)}{" "}
                      {candidate.fullText.middleName?.charAt(0)?.toUpperCase() +
                        candidate.fullText.middleName?.slice(1)}{" "}
                      {candidate.fullText.lastName?.charAt(0)?.toUpperCase() +
                        candidate.fullText.lastName?.slice(1)}
                    </h6>
                  </div>
                </div>

                <div class="designation-city-status font-regular font-primary font-gray1 mt-1">
                  {candidate.designation && (
                    <div class="mb-2">
                      <i class="far fa-suitcase me-2"></i>{" "}
                      {candidate.designation}
                    </div>
                  )}
                  {candidate?.addressCity &&
                  candidate?.addressState &&
                  candidate?.country ? (
                    <div class="mb-2">
                      <i class="far fa-map-marker-alt me-2"></i>
                      {candidate.addressCity},{" "}
                      {candidate.addressState !== "NA"
                        ? `${candidate.addressState}, `
                        : ""}
                      {candidate.country}
                    </div>
                  ) : (
                    ""
                  )}
                  {candidate.updatedDate !== "" &&
                    new Date() > new Date(candidate.updatedDate) && (
                      <div class="mb-2">
                        <i class="far fa-clock me-2"></i>Last updated: &nbsp;
                        <span>
                          {moment(
                            new Date(candidate.updatedDate),
                            "MMDDYYYY"
                          ).format("MM/DD/YYYY")}
                        </span>
                        &nbsp;
                        {/* {dataDiff(candidate.updatedDate, new Date()) > 1 ? 'Days' : 'Day'} &nbsp;ago */}
                      </div>
                    )}
                  {candidate.createdByName !== "" && candidate.createdByName && (
                    <div className="mb-1">
                      <i class="far fa-user me-2"></i>Created by:{" "}
                      {candidate.createdByName}
                      {/* {", "}
                      {candidate.candidateTenant} */}
                    </div>
                  )}
                </div>

                <div class="candidates-grid-request font-regular">
                  {candidate.statusData.length !== 0 && (
                    <>
                      {/* <div class="font-small font-primary mb-2">Requests</div> */}
                      <div>
                        {candidate.statusData.map((item, index) => {
                          if (index < 2 || candidate.candidateID === canId) {
                            if (
                              item.statusId === "Auto" ||
                              item.statusId == "33"
                            ) {
                              return;
                            }
                            if (item.statusId === "6") {
                              return (
                                <Link
                                  to={`requests/${item.jobId}/${
                                    item.statusId !== "Auto" ? item.statusId : 0
                                  }`}
                                >
                                  <div class="mb-1">
                                    <div class="tag tag-green3">
                                      <strong>
                                        {item.statusName === "Pending"
                                          ? "Offered"
                                          : item.statusname === "Offer Rejected"
                                          ? "Disqualified"
                                          : item.statusName}
                                        : {item.jobTitle}
                                      </strong>
                                    </div>
                                  </div>
                                </Link>
                              );
                            } else if (item.statusId === "Auto") {
                              return (
                                <Link
                                  to={`requests/${item.jobId}/${
                                    item.statusId !== "Auto" ? item.statusId : 0
                                  }`}
                                >
                                  <div class="mb-1">
                                    <div class="tag tag-green1 mr-1">
                                      <strong>
                                        {item.statusName === "Pending"
                                          ? "Offered"
                                          : item.statusname === "Offer Rejected"
                                          ? "Disqualified"
                                          : item.statusName}
                                        : {item.jobTitle}
                                      </strong>
                                    </div>
                                  </div>
                                </Link>
                              );
                            } else {
                              return (
                                <Link
                                  to={`requests/${item.jobId}/${
                                    item.statusId !== "Auto" ? item.statusId : 0
                                  }`}
                                >
                                  <div class="mb-1">
                                    <div class="tag tag-blue3">
                                      <strong>
                                        {item.statusName === "Pending"
                                          ? "Offered"
                                          : item.statusname === "Offer Rejected"
                                          ? "Disqualified"
                                          : item.statusName}
                                        : {item.jobTitle}
                                      </strong>
                                    </div>
                                  </div>
                                </Link>
                              );
                            }
                          }
                        })}

                        {candidate.candidateID !== canId ? (
                          candidate.statusData.filter(
                            (elem) =>
                              elem.statusId !== "Auto" && elem.statusId !== "33"
                          ).length > 2 && (
                            <div
                              class="tag"
                              onClick={() => setCanId(candidate.candidateID)}
                            >
                              +
                              {candidate.statusData.filter(
                                (elem) =>
                                  elem.statusId !== "Auto" &&
                                  elem.statusId !== "33"
                              ).length - 2}{" "}
                              more
                            </div>
                          )
                        ) : candidate.candidateID == canId ? (
                          <div class="tag" onClick={() => setCanId(null)}>
                            show less
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </a>
          </div>
        ))}
        <div className="d-flex justify-content-end pt-4 px-1">
          <div className="d-flex">
            <div class="ml-auto mx-auto-370 d-flex align-items-center">
              <button
                onClick={() => {
                  setPageType("down");
                  props.setCurrentPage((page) => page - 1);
                }}
                type="button"
                disabled={props.currentPage === 1 ? true : false}
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-left"></i>
              </button>
              {paginationGroup.map((item) => (
                <button
                  type="button"
                  className={
                    props.currentPage === item
                      ? "btn btn-text px-2 bg-secondary text-white"
                      : "btn btn-text px-2"
                  }
                  onClick={changePage}
                >
                  {item}
                </button>
              ))}
              <button
                onClick={() => {
                  setPageType("up");
                  props.setCurrentPage((page) => page + 1);
                }}
                type="button"
                disabled={
                  props.currentPage === requestTitleArray.slice(-1)[0]
                    ? true
                    : false
                }
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCandidateProfile && (
        <CandidateView
          candidateId={candidateId}
          candidateData={candidateData}
          handleSuccess={handleModel}
        />
      )}
    </>
  );
}

export default CandidateGridView;
