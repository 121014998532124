import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import ResumeUploadAddTalent from "./ResumeUploadAddTalent";
import AddTalentManualEntry from "./manualentry/AddTalentManualEntry";
import { getUserTenantID } from "../../../service/AuthService";
import { post } from "../../../service/ApiService";
import ExcelUplaod from "./ExcelUpload";
import Loader from "../../Loader/Loader";
import { getCandidateDropdown } from "../../../actions/talent/AddTalent";

function AddTalent(props) {
  const dispatch = useDispatch();
  const [docSelected, setDocSelected] = useState(false);
  const [excelSelected, setExcelSelected] = useState(false);
  const [manualSelected, setManualSelected] = useState(false);
  const [parsedData, setParsedData] = useState({});
  const [existingCandidateList, setExistingCandidateList] = useState([]);
  const [isAddedByHigh5, setIsAddedByHigh5] = useState(false);
  const [parsed, setParsed] = useState(false);
  const updatedParsed = (data, value, h5h) => {
    setParsedData({ ...data, isAddedByHigh5: h5h, h5h });
    setParsed(value);
  };

  const onCandidateAddTypeClick = (type) => {
    if (type === "doc") {
      setDocSelected(true);
      setExcelSelected(false);
      setManualSelected(false);
    }
    if (type === "excel") {
      setExcelSelected(true);
      setDocSelected(false);
      setManualSelected(false);
    }
    if (type === "manual") {
      setManualSelected(true);
      setExcelSelected(false);
      setDocSelected(false);
    }
  };

  const gettenantcandidateEmails = async () => {
    const tenantid = getUserTenantID();
    const params = {
      tenantid,
    };
    try {
      const result = await post("/talent/gettalentemails", params);
      if (result.status === 200) {
        setExistingCandidateList(result.data);
      } else {
        toast.error("Oops! Something went wrong");
      }
    } catch (error) {
      toast.error("Oops! Something went wrong");
    }
  };

  useEffect(() => {
    dispatch(getCandidateDropdown());
    gettenantcandidateEmails();
  }, [parsedData]);

  const { loading } = useSelector(
    (state) => ({
      loading: state.AddCandidate.loading,
    }),
    shallowEqual
  );

  return (
    <>
      {loading && <Loader />}
      <div>
        <div>
          <div class="row">
            <div
              class="col-12 d-flex flex-column flex-lg-row"
              style={{ padding: "25px 30px 30px 40px" }}
            >
              <button
                onClick={() => {
                  onCandidateAddTypeClick("doc");
                }}
                type="button"
                class={
                  docSelected
                    ? "card card-flat p-0 mr-2 mb-2 mb-lg-0  selected"
                    : "card card-flat p-0 mr-2 mb-2 mb-lg-0"
                }
              >
                <div class="card-body text-left text-left">
                  <div class="d-flex">
                    {docSelected ? (
                      <>
                        <div class="mr-3 font-green font-16">
                          <i class="fad fa-check-circle"></i>
                        </div>
                      </>
                    ) : (
                      <div class="mr-3 font-muted font-16">
                        <i class="fad fa-circle"></i>
                      </div>
                    )}
                    <div>
                      <div>
                        <strong class="font-blue_primary">
                          Upload Resumes
                        </strong>
                      </div>
                      {parsedData.email ? (
                        <p class="mb-0 ">
                          {" "}
                          Upload candidate resumes, Accepted File Type: .docx,
                          .pdf <br />
                        </p>
                      ) : (
                        <p class="mb-0">
                          {" "}
                          Upload candidate resumes, Accepted File Type: .docx,
                          .pdf
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </button>

              <button
                type="button"
                onClick={() => {
                  onCandidateAddTypeClick("excel");
                }}
                class={
                  excelSelected
                    ? "card card-flat p-0 mr-2 mb-2 mb-lg-0  selected"
                    : "card card-flat p-0 mr-2 mb-2 mb-lg-0"
                }
              >
                <div class="card-body text-left">
                  <div class="d-flex">
                    {excelSelected ? (
                      <div class="mr-3 font-green font-16">
                        <i class="fad fa-check-circle"></i>
                      </div>
                    ) : (
                      <div class="mr-3 font-muted font-16">
                        <i class="fad fa-circle"></i>
                      </div>
                    )}
                    <div>
                      <div>
                        <strong class="font-blue_primary">
                          Bulk Upload With Excel
                        </strong>
                      </div>
                      <p class="mb-0">
                        Use an Excel template to upload multiple talented
                        candidates at once
                      </p>
                    </div>
                  </div>
                </div>
              </button>
              <button
                onClick={() => {
                  onCandidateAddTypeClick("manual");
                }}
                type="button"
                class={
                  manualSelected
                    ? "card card-flat p-0 mr-2 mb-2 mb-lg-0  selected"
                    : "card card-flat p-0 mr-2 mb-2 mb-lg-0"
                }
              >
                <div class="card-body text-left">
                  <div class="d-flex">
                    {manualSelected ? (
                      <div class="mr-3 font-green font-16">
                        <i class="fad fa-check-circle"></i>
                      </div>
                    ) : (
                      <div class="mr-3 font-muted font-16">
                        <i class="fad fa-circle"></i>
                      </div>
                    )}
                    <div>
                      <div>
                        <strong class="font-blue_primary">Manual Entry</strong>
                      </div>
                      <p class="mb-0">Single talent manual entry</p>
                    </div>
                  </div>
                </div>
              </button>
            </div>
          </div>
          {docSelected && (
            <div>
              {!parsed && (
                <ResumeUploadAddTalent
                  updateParsedData={(data, value, h5h) =>
                    updatedParsed(data, value, h5h)
                  }
                  closeModal={props.closeModal}
                />
              )}
              {parsed && (
                <AddTalentManualEntry
                  handleSuccessForm={props.handleSuccessForm}
                  parsedData={parsedData}
                  parsed={parsed}
                  fetchCandidate={props.fetchCandidate}
                  closeModal={props.closeModal}
                  existingCandidateList={existingCandidateList}
                />
              )}
            </div>
          )}
          {excelSelected && (
            <ExcelUplaod
              handleSuccessForm={props.handleSuccessForm}
              closeModal={props.closeModal}
              fetchCandidate={props.fetchCandidate}
            />
          )}
          {manualSelected && (
            <AddTalentManualEntry
              handleSuccessForm={props.handleSuccessForm}
              parsedData={parsedData}
              parsed={parsed}
              fetchCandidate={props.fetchCandidate}
              closeModal={props.closeModal}
              existingCandidateList={existingCandidateList}
            />
          )}
        </div>
      </div>
    </>
  );
}
export default AddTalent;
