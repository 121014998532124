import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const AddTalentAnalytics = (props) => {
  const {
    addCandidatesDaily,
    addCandidatesMonthly,
    addCandidatesTotal,
    addCandidatesWeekly,
  } = props.data;
  const [timeInterval, setTimeInterval] = useState("daily");
  const [chartSeries, setChartSeries] = useState([
    {
      name: "Talents Added",
      data: [],
      color: "#55c3c3",
    },
  ]);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
    },
    dataLabels: {
      enabled: false,
    },
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day} ${month}`;
  }

  const generateChartData = (timeInterval) => {
    let categories = [];
    let data = [];
    if (timeInterval === "daily") {
      categories = addCandidatesDaily?.map((item) => formatDate(item.category));
      data = addCandidatesDaily?.map((item) => item.data);
    } else if (timeInterval === "weekly") {
      categories = addCandidatesWeekly?.map((item) =>
        formatDate(item.category)
      );
      data = addCandidatesWeekly?.map((item) => item.data);
    } else if (timeInterval === "monthly") {
      categories = addCandidatesMonthly?.map((item) => item.category);
      data = addCandidatesMonthly?.map((item) => item.data);
    }

    setChartSeries([
      {
        name: "Talents Added",
        data: data,
      },
    ]);
    setChartOptions({
      ...chartOptions,
      xaxis: {
        categories: categories,
      },
    });
  };

  useEffect(() => {
    // Generate charts based on the selected time intervals
    generateChartData(timeInterval);
  }, [timeInterval]);

  useEffect(() => {
    // Initial data population when the component mounts
    if (addCandidatesDaily) {
      // You can choose an appropriate default time interval here
      const initialInterval = "daily";
      setTimeInterval(initialInterval);
    }
  }, [addCandidatesDaily]);

  return (
    <>
      <div className="card analyticCards custom-card">
        <h6 className="text-center mt-3 mb-3">Number of Talents Added</h6>
        <div className="d-flex flex-wrap justify-content-center">
          <button
            onClick={() => setTimeInterval("daily")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor: timeInterval === "daily" ? "#17a2b8" : "white",
              color: timeInterval === "daily" ? "white" : "#17a2b8",
            }}
          >
            Daily
          </button>
          <button
            onClick={() => setTimeInterval("weekly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor: timeInterval === "weekly" ? "#17a2b8" : "white",
              color: timeInterval === "weekly" ? "white" : "#17a2b8",
            }}
          >
            Weekly
          </button>
          <button
            onClick={() => setTimeInterval("monthly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor: timeInterval === "monthly" ? "#17a2b8" : "white",
              color: timeInterval === "monthly" ? "white" : "#17a2b8",
            }}
          >
            Monthly
          </button>
        </div>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={250}
        />
        <div className="card m-2 align-items-center">
          <p className="mt-2 fw-light">
            Total Number of Talents Added: {addCandidatesTotal}
          </p>
        </div>
      </div>
    </>
  );
};

export default AddTalentAnalytics;
