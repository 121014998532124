import React from "react";
import TalentList from "../../../components_v3/talent/talentcommunity/Index";

const TalentPool = () => {
  return (
    <div className="m-2">
      <TalentList />
    </div>
  );
};

export default TalentPool;
