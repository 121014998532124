import React, { useEffect, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import { post } from "../../service/ApiService";
import { getUserRole, getUserTenantID } from "../../service/AuthService";
import Loader from "../Loader/Loader";
import NoRecord from "./NoNotifications";
import { getNotificationList } from "../../actions/notification/notifications";
import { useDispatch } from "react-redux";
import moment from "moment";
const Notifications = ({
  supportModal,
  setToggleNotification,
  notificationData,
  isFrom,
  userID,
  fetchNotifications,
  notificationLoading,
}) => {
  const dispatch = useDispatch();
  const [direct, setDirect] = useState(true);
  const [watching, setWatching] = useState(false);
  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [toggleSettings, setToggleSettings] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState({});
  const [data, setData] = useState([]);
  const [notiD, setNotiD] = useState([]);
  const [notiW, setNotiW] = useState([]);
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    notificationData?.data?.length > 0 && setData(notificationData?.data);
  }, [notificationData]);

  const onTabClick = (type) => {
    if (type === "direct") {
      setDirect(true);
      setWatching(false);
      updateNotifications("direct");
    }
    if (type === "watching") {
      setDirect(false);
      setWatching(true);
      updateNotifications("watching");
    }
  };

  const updateNotifications = async (str) => {
    try {
      let url =
        isFrom === "candidate"
          ? "/notifications/updateStatusCandidate"
          : "/notifications/updateStatus";
      let params =
        isFrom === "candidate"
          ? {
              filterString: str,
              userID: userID,
            }
          : {
              userType: getUserRole().toLowerCase(),
              tenant: getUserTenantID(),
              filterString: str,
            };
      // sessionStorage.getItem("userId"){}
      if (notificationData?.notificationPref) {
        const res = await post(url, params);

        if (res.status === 200) {
          isFrom === "candidate"
            ? fetchNotifications()
            : dispatch(getNotificationList());
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    direct ? updateNotifications("direct") : updateNotifications("watching");
  }, []);

  useEffect(() => {
    // setDirect(true);
    // setWatching(false);
    let d = data.filter((d) => d.alertType === "direct");
    let w = data.filter((d) => d.alertType === "watching");
    console.log(d);
    setNotiD((prev) => d);
    setNotiW((prev) => w);
  }, [data]);

  const handleDelete = async () => {
    try {
      setLoading(true);
      const res = await post("/notifications/delete", selectedNotification);
      if (res.status === 200) {
        setToggleDropdown(false);
        setToggleSettings(false);
        setSelectedNotification({});
        isFrom === "candidate"
          ? fetchNotifications()
          : dispatch(getNotificationList());
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleTurnOff = async () => {
    try {
      setLoading(true);
      const res = await post("/notifications/disable");
      if (res.status === 200) {
        setToggleDropdown(false);
        setToggleSettings(false);
        dispatch(getNotificationList());
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const handleTurnOn = async () => {
    try {
      setLoading(true);
      const res = await post("/notifications/enable");
      if (res.status === 200) {
        setToggleDropdown(false);
        setToggleSettings(false);
        dispatch(getNotificationList());
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const listHandler = (n) => {
    return (
      <div
        // href={n.r_url?.includes("high5Hire") && false ? n.r_url : `#`}
        class="card card-flat mb-1"
      >
        <div className="card-body">
          <button
            onClick={() => {
              setToggleDropdown(true);
              setSelectedNotification(n);
            }}
            type="button"
            class="btn btn-icon float-end px-1 "
          >
            <i aria-hidden="true" class="fal fa-ellipsis-v"></i>
          </button>
          {
            <div>
              {toggleDropdown &&
                selectedNotification.notificationID === n.notificationID && (
                  <div
                    className="dropdown-menu"
                    style={{
                      display: "revert",
                      right: "-14px",
                      top: "30px",
                    }}
                  >
                    <button onClick={handleDelete} className="dropdown-item">
                      Delete
                    </button>
                  </div>
                )}
            </div>
          }
          <div
            dangerouslySetInnerHTML={{
              __html: n.content,
            }}
          ></div>
          <div style={{ marginTop: "-10px" }} className="">
            <span className="text-muted ">
              {moment(n.createdDateTime)
                .fromNow()
                ?.charAt(0)
                .toUpperCase() +
                moment(n.createdDateTime)
                  .fromNow()
                  ?.slice(1)}
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="action-panel"
      style={{
        display: "revert",
        right: "0px",
        // top: windowWidth > 730 ? "50px" : "0px",
        textAlign: "left",
      }}
    >
      {loading && <Loader />}

      <OutsideClickHandler
        onOutsideClick={() => !supportModal && setToggleNotification(false)}
      >
        <div></div>
        <div
          className="action-panel"
          style={{
            width: windowWidth > 730 ? "30rem" : windowWidth,
            height: windowWidth > 730 ? "" : "100%",
            display: "revert",
            right: "0px",
            top: windowWidth > 730 ? "51px" : "0px",
            textAlign: "left",
          }}
        >
          {" "}
          <div class="py-2 px-3 h-100">
            <div className="d-flex justify-content-between">
              {" "}
              <button
                onClick={() => !supportModal && setToggleNotification(false)}
                type="button"
                class="btn btn-icon"
              >
                <i aria-hidden="true" class="fal fa-times"></i>
              </button>
              {isFrom !== "candidate" && notificationData.notificationPref && (
                <button
                  onClick={() => {
                    setToggleDropdown(false);
                    setToggleSettings((prev) => !prev);
                  }}
                  type="button"
                  class="btn btn-icon"
                >
                  <i class="fal fa-cog"></i>
                </button>
              )}
              {toggleSettings && (
                <div
                  className="dropdown-menu"
                  style={{
                    display: "revert",
                    right: "4px",
                    top: "30px",
                  }}
                >
                  <button onClick={handleTurnOff} className="dropdown-item">
                    Turn off notifications
                  </button>
                </div>
              )}
            </div>
            <div className="d-flex align-content-center justify-content-between  mt-3">
              <div className="d-flex mt-2">
                <h5 className="my-auto align-self-center ">Notifications</h5>
              </div>
              {notificationData.notificationPref && (
                <ul class="nav nav-tabs w-100 d-flex align-content-center justify-content-end gap-3">
                  <li class="nav-item">
                    <button
                      onClick={() => {
                        onTabClick("direct");
                      }}
                      type="button"
                      class={direct ? "nav-link pb-3 active" : "nav-link pb-3"}
                    >
                      <div>Direct</div>
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      onClick={() => {
                        onTabClick("watching");
                      }}
                      type="button"
                      class={
                        watching ? "nav-link pb-3 active" : "nav-link pb-3"
                      }
                    >
                      <div>Watching</div>
                    </button>
                  </li>
                </ul>
              )}
            </div>{" "}
            <hr className="mt-0 pt-0" />
            {notificationData.notificationPref ? (
              (watching && notiW?.length > 0) ||
              (direct && notiD?.length > 0) ? (
                watching ? (
                  notiW.map((n) => listHandler(n))
                ) : (
                  notiD.map((n) => listHandler(n))
                )
              ) : (
                <NoRecord />
              )
            ) : (
              <div className="d-flex align-items-center justify-content-center h-75 ">
                <div className="align-self-center d-flex flex-column align-items-center justify-content-center">
                  <p>You have turned off notifications.</p>
                  <button
                    className="btn btn-primary btn-md"
                    onClick={handleTurnOn}
                  >
                    Turn on
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};
export default Notifications;
