import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import { useHistory, withRouter } from "react-router-dom";
import Loader from "../../Loader/Loader";
const ScreeningQuestions = ({
  screeningData,
  idCandidate,
  candidateDetails,
  dispositionID,
  job,
  InviteTalentWithVet,
  setIsScreening,
  setIsRTHScreening,
  submitCandidate,
  screeningQuestions,
  setScreeningQuestions
}) => {
  const history = useHistory();
  
  const [answers, setAnswers] = useState(
    screeningQuestions.map((q) => q.answer)
  );

  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const data = screeningData.map((x) => ({
      name: x.name,
      question: x?.question.replace("[X]", x?.selectedOption),
      answer: "",
    }));
    setScreeningQuestions(data);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize); // Add event listener
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  const handleAnswerChange = (index, answer, item) => {
    if (item?.name === "Visa/Work Authorization") {
      const newAnswers = [...screeningQuestions];
      newAnswers[index]["answer"] = answer;
      const legalIndex = newAnswers.findIndex(question => question.name === "Legal");
  
      if (answer === "No") {
        const legalQuestion = {
          name: "Legal",
          question: `Do you require sponsorship to work in ${job?.country} ?`,
          answer: "",
        };
        if (legalIndex === -1) {
          newAnswers.splice(index + 1, 0, legalQuestion);
        } else {
          newAnswers.splice(legalIndex, 1, legalQuestion);
        }
      } else {
        if (legalIndex !== -1) {
          newAnswers.splice(legalIndex, 1);
        }
      }
      setScreeningQuestions(newAnswers);
    } else {
      const newAnswers = [...screeningQuestions];
      newAnswers[index]["answer"] = answer;
      setScreeningQuestions(newAnswers);
    }
  };
  

  const screeningSubmit = async () => {
    let status = 42; // Engaged
    
    if (screeningQuestions.some((x) => x.answer === ""))
      return toast.error("Please answer all knockout assessment questions.");
    else if (screeningQuestions.some((x) => x.answer === "No")) status = 12; //disqualified
    await submitCandidate(status)
    // const data = {
    //   screeningQuestions: JSON.stringify(screeningQuestions),
    //   dispositionID: dispositionID?.dispositionID,
    //   status,
    // };
    // setLoading(true);
    // await post("/careerspool/updateDispositionScreening", data);
    // // toast.success(`Joined ${job?.jobTitle} successfully`);
    // setLoading(false);
    // if (JSON.parse(job?.vettingDetails) !== null && status == 42) {
    //   setLoading(true);
    //   await InviteTalentWithVet(idCandidate);
    //   history.push(`/career/home`);
    //   setLoading(false);
    // } else history.push(`/career/home`);
  };

  return (
    <div className="mx-4 mt-2">
      {loading && <Loader />}
      <h6 className="m-2">
        You are almost there, please answer the following knockout assessment
        and complete your application
      </h6>
      <div className="px-5 mx-5">
        <div className="card  mt-3 ">
          <div className="card-body ">
            <h6 className="m-2">Knockout Assessment*</h6>
            <hr />
            <div className="p-3">
              {/* <h6>Screening Questions</h6> */}
              <div
                className="questions"
                style={{
                  display: "grid",
                  gap: "20px",
                  gridTemplateColumns: "1fr",
                  gridTemplateRows: isMobile ? "auto" : "1fr",
                }}
              >
                {screeningQuestions.map((question, index) => (
                  <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: isMobile ? "1fr" : "auto auto",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginBottom: isMobile ? "10px" : "0" }}>
                      <span className="mr-2">{index + 1}.</span>{" "}
                      {question.question}
                    </p>
                    <div className=" d-flex  justify-content-end   w-100">
                      <select
                        className="form-select"
                        value={screeningQuestions[index]["answer"]}
                        onChange={(e) =>
                          handleAnswerChange(index, e.target.value, question)
                        }
                        style={{ width: isMobile ? "100%" : "150px" }}
                      >
                        <option value="">Select</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-end  px-3">
              <button onClick={screeningSubmit} className="btn btn-md">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreeningQuestions;
