const EmailConfirmationPopup = ({ closeModal, handleSendClick }) => {
  const handleTogglePreview = () => {
    closeModal();
  };

  return (
    <div>
      <div className="email-preview-body">
        Are you sure you want to send email ?
      </div>
      <div className="email-preview-footer">
        <button onClick={handleSendClick} className="btn btn-primary mr-2">
          Confirm
        </button>
        <button onClick={handleTogglePreview} className="btn btn-secondary">
          Close
        </button>
      </div>
    </div>
  );
};

export default EmailConfirmationPopup;
