import {
    GET_LOGIN_BEGIN,
    GET_LOGIN_SUCCESS,
    GET_LOGIN_FAILURE,
} from '../../actions/auth/Login';

const initialState = {
    loading: false,
    loginDetails: null,
    loginErrors: null,
};

const loginReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_LOGIN_BEGIN:
            return {
                ...state,
                loading: true,
                loginDetails: null
            };
        case GET_LOGIN_SUCCESS:
            return {
                ...state,
                loginDetails: payload,
                loading: false
            };
        case GET_LOGIN_FAILURE:
            return {
                ...state,
                loginErrors: payload,
                loading: false
            };
        default:
            return state;
    }
}

export default loginReducer;

