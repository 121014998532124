import React, { useRef, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import GoogleLocationAPI from "../../location/GoogleLocationAPI";

const ApplyPageProfileNew = ({
  formData,
  setFormData,
  setPhoneValidation,
  formIsValid,
}) => {
  const experienceLevel = [
    "Intern",
    "Less than one year",
    "1-3 years",
    "3-5 years",
    "5-10 years",
    "10+ years",
  ];
  const searchInput = useRef(null);

  const _onChangeText = (value, code, name) => {
    if (value) {
      const asYouType = new AsYouType(code);
      asYouType.input(value);
      const isValid = asYouType.isValid();
      setPhoneValidation(isValid);
    } else {
      setPhoneValidation(true);
    }
  };
  const handlePhoneChange = (value, country) => {
    const mobile = value.replace(country.dialCode, "");
    let tempData = { ...formData };
    tempData["mobile"] = mobile;
    tempData["mobilePhoneCode"] = `+${country.dialCode}`;
    setFormData(tempData);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    let tempData = { ...formData };
    tempData[name] = value;
    setFormData(tempData);
  };
  const setLocation = (address, searchInput) => {
    const city = address.locality || address.city;
    setFormData({
      ...formData,
      city: city,
      state: address.state,
      country: address.country,
      location: address,
    });
    searchInput.current.value = city;
  };
  return (
    <div className="card">
      <div className="card-body">
        <h6>Contact Information</h6>
        <hr />
        <div className="row mb-4">
          <div className="col-md-4">
            <label>First Name*</label>
            <div className="form-floating">
              <input
                type="text"
                className={
                  formIsValid && formData.firstName == ""
                    ? "form-control border border-danger w-100 mx-auto p-3"
                    : "form-control w-100 mx-auto p-3"
                }
                id="firstName"
                name="firstName"
                onChange={(e) => handleChange(e)}
                value={formData?.firstName}
              />
            </div>
            {formIsValid && formData.firstName == "" && (
              <div className="validation">*Required Field</div>
            )}
          </div>
          <div className="col-md-4">
            <label>Last Name*</label>
            <div className="form-floating">
              <input
                type="text"
                className={
                  formIsValid && formData.lastName == ""
                    ? "form-control border border-danger w-100 mx-auto p-3"
                    : "form-control w-100 mx-auto p-3"
                }
                id="lastName"
                name="lastName"
                onChange={(e) => handleChange(e)}
                value={formData?.lastName}
              />
            </div>
            {formIsValid && formData.lastName == "" && (
              <div className="validation">*Required Field</div>
            )}
          </div>{" "}
          <div className="col-md-4 ">
            <label>Email*</label>
            <div className="form-floating ">
              <input
                type="text"
                className={
                  formIsValid && formData.email == ""
                    ? "form-control border border-danger w-100 mx-auto p-3"
                    : "form-control w-100 mx-auto p-3"
                }
                id="emailId"
                name="email"
                onChange={(e) => handleChange(e)}
                value={formData?.email}
              />
            </div>
            {formIsValid && formData.email == "" && (
              <div className="validation">*Required Field</div>
            )}
          </div>
        </div>
        <div className="row mb-4 ">
          <div className="col-md-4">
            <label>Phone Number</label>
            <div className="form-floating" style={{ zIndex: "999" }}>
              <PhoneInput
                value={
                  formData?.mobilePhoneCode
                    ? `${formData?.mobilePhoneCode}${formData?.mobile}`
                    : formData?.mobile
                }
                name="mobile"
                country={"us"}
                inputStyle={{ height: "57px" }}
                onChange={(value, country) => {
                  _onChangeText(
                    value.replace(country.dialCode, ""),
                    country.countryCode.toUpperCase(),
                    "mobile"
                  );
                  handlePhoneChange(value, country);
                }}
                placeholder="Phone Number"
                inputClass={
                  // formIsValid && formData.mobile === ""
                  //   ? "form-control border border-danger w-100"
                  //   :
                  "form-control w-100"
                }
                specialLabel="Phone Number"
                specialLabelStyle={{ display: "block" }}
                enableSearch={true}
              />
            </div>
            {/* {formIsValid && formData.mobile === "" && (
              <div className="validation">*Required Field</div>
            )} */}
          </div>
          <div className="col-md-4 ">
            <label>Linkedin Profile URL</label>
            <div className="form-floating">
              <input
                type="text"
                className="form-control mx-auto p-3"
                id="lastName"
              />
            </div>
          </div>{" "}
          <div className="col-md-4">
            <label>Experience Level*</label>
            <div className="form-floating">
              <select
                className={
                  formIsValid && formData.experienceLevel == ""
                    ? "form-control border border-danger w-100"
                    : "font-14 form-select mx-auto p-3"
                }
                name="experienceLevel"
                onChange={(e) => handleChange(e)}
              >
                <option value="">Select</option>
                {experienceLevel.map((e, i) => (
                  <option key={i} value={e}>
                    {e}
                  </option>
                ))}
              </select>
            </div>
            {formIsValid && formData.experienceLevel == "" && (
              <div className="validation">*Required Field</div>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <label>City*</label>
            <div className="form-floating">
              <GoogleLocationAPI
                className={
                  formIsValid && formData.city === ""
                    ? "form-control border border-danger"
                    : "form-control mx-auto p-3"
                }
                setLocation={setLocation}
                searchInput={searchInput}
                formData={formData}
                setFormData={setFormData}
              />
            </div>
            {formIsValid && formData.city == "" && (
              <div className="validation">*Required Field</div>
            )}
          </div>
          <div className="col-md-4">
            <label>State*</label>
            <div className="form-floating">
              <input
                type="text"
                disabled
                className={
                  formIsValid && formData.state === ""
                    ? "form-control border border-danger"
                    : "form-control mx-auto p-3"
                }
                placeholder="State*"
                id="stateName"
                name="state"
                onChange={(e) => handleChange(e)}
                value={formData?.state}
              />
            </div>
            {formIsValid && formData.state == "" && (
              <div className="validation">*Required Field</div>
            )}
          </div>
          <div className="col-md-4">
            <label>Country*</label>
            <div className="form-floating">
              <input
                type="text"
                disabled
                placeholder="Country*"
                className={
                  formIsValid && formData.country === ""
                    ? "form-control border border-danger"
                    : "form-control mx-auto p-3"
                }
                id="CountryName"
                name="country"
                onChange={(e) => handleChange(e)}
                value={formData?.country}
              />
            </div>
            {formIsValid && formData.country == "" && (
              <div className="validation">*Required Field</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplyPageProfileNew;
