import React, { useState } from "react";
import Logo from "./High5.svg";

function App() {
  return (
    <div
      style={
        {
          //fontFamily: "Arial, Helvetica, sans-serif",
        }
      }
    >
      <div className="row ml-5">
        <img
          style={{ width: 180, height: 180 }}
          src={Logo}
          alt="Right to Represent"
        />
      </div>
      <div className="d-flex justify-content-center align-items-center row mb-5 mt-3">
        <h1
          className="d-flex justify-content-center align-items-center row "
          style={{
            //fontFamily: "Arial, Helvetica, sans-serif",
            fontWeight: "bolder",
          }}
        >
          Right to Represent
        </h1>
      </div>

      <div className="d-flex justify-content-center ">
        <p
          className="text-align-center"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "70em",
            lineHeight: "1.7",
            fontSize: " 18px",
            color: "grey",
            fontFamily: "Arial, Helvetica, sans-serif",
          }}
        >
          I hereby confirm, assign and accord the permission to High5Hire to
          represent me which includes, but not limited to, permission to submit
          my resume and qualifications to Client for this requisition. I
          acknowledge and understand that it is the only responsibility of
          High5Hire to submit my candidature to the Client for the posted
          requisition and thereby, I shall have no claims against the High5Hire
          in the event that I am not selected and by no means shall this
          representation constitute an employee/employer relationship. I consent
          to the collection of my Personal Information, and I understand that
          this information will be used strictly for the purpose of submitting
          my candidature to the Client. In the event that I wish to withdraw my
          consent to the use, disclosure and retention of my Personal
          Information, I understand that it is my responsibility to inform such
          withdrawal in writing. As a candidate, the action of my applying for
          this role acknowledges my authorization for Recruitment Agency to
          represent me for this posted requisition.
        </p>
      </div>
    </div>
  );
}

export default App;
