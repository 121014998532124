import { toast } from "react-toastify";
import { post } from "../../service/ApiService";
export const FETCH_CAREER_JOB_LIST_BEGIN = "FETCH_CAREER_JOB_LIST_BEGIN";
export const FETCH_CAREER_JOB_LIST_SUCCESS = "FETCH_CAREER_JOB_LIST_SUCCESS";
export const FETCH_CAREER_JOB_LIST_FAILURE = "FETCH_CAREER_JOB_LIST_FAILURE";

export const FETCH_FILTER_DATA_BEGIN = "FETCH_FILTER_DATA_BEGIN";
export const FETCH_FILTER_DATA_SUCCESS = "FETCH_FILTER_DATA_SUCCESS";
export const FETCH_FILTER_DATA_FAILURE = "FETCH_FILTER_DATA_FAILURE";

export const fetchCareerJobList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_CAREER_JOB_LIST_BEGIN,
    });
    const res = await post("/career/getjoblist", params);
    if (res.status === 200) {
      dispatch({
        type: FETCH_CAREER_JOB_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_CAREER_JOB_LIST_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};

export const fetchFilterParam = (params) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_FILTER_DATA_BEGIN,
    });
    const res = await post("/career/getJobListFilterData", params);
    if (res.status === 200) {
      dispatch({
        type: FETCH_FILTER_DATA_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_FILTER_DATA_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};
