import React, { useState, useEffect } from 'react'
import Autosuggest from "react-autosuggest"
import Select from 'react-select'
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { domain } from '../../../../constants/Constants';
import moment from 'moment';
import {get, post } from '../../../../service/ApiService'; 
import { toast } from 'react-toastify';
import RichTextEditor from 'react-rte';
import { currency_list } from '../../../function/CommonFunction';
import Loader from '../../../Loader/Loader'
import ScreeningAddInfo from './ScreeningAddInfo';

function AddJobTemplate({ closePanel, fetchTemplates, templates }) {

    const experienceLevel = [ 'Intern', 'Less than one year', '1-3 years', '3-5 years', '5-10 years', '10+ years' ]
    const visaStatus = ['Green Card', 'H1B', 'Citizen', 'H4 EAD', 'GC EAD', 'L2EAD', 'OPT EAD', 'TN1', 'TN2', 'Not Required']
    const [ loading, setLoading ] = useState(false)
    const [ primarySkillinput, setPrimarySkillInput ] = useState("")
    const [ secondarySkillsInput, setSecondarySkillSInput ] = useState("")
    const [ drugTest, setdrugTest ] = useState(true)
    const [ backgroundCheck, setBackgroundCheck ] = useState(true)
    const [ details, setDetails ] = useState(true)
    const [ questions, setQuestions ] = useState(false)
    const [ addInfo, setAddInfo ] = useState(false)
    const [ dropdownList, setDropDown ] = useState({})
    const [ templateName, setTemplateName ] = useState('')
    const [ templateCategory, setTemplateCategory ] = useState('Information Technology')
    const [ value, setValue ] = useState(RichTextEditor.createEmptyValue())
    
    const [ selectedLanguages, setSelectedLanguages ] = useState(['English'])
    const [ additionalInfoFinal, setAdditionalInfoFinal ] =  useState([])

    const [ screeningQuestions, setScreeningQuestions ] = useState([])
    const [ disableSubmit, setDisableSubmit ] = useState(false)

    const [ addJobData, setAddJobData ] = useState({
        title: "",
        type: 'Full Time',
        minimumPay: '',
        maximumPay: '',
        salaryType: 'Annual',
        salaryCurrency: "USD",
        submittal: "",
        description: "",
        primarySkills: [],
        secondarySkills: [],
        skillSet: [],
        education: [],
        isFexible: true,
        workStart: "",
        workEnd: "",
        travel: "",
        drugTest: true,
        backgroundCheck: true,
        securityClearance: "",
        visaRequirement: 'Not Required',
        licenceRequirement: '',
        experienceLevel: '1-3 years',
    })

    const [ addJobType, setAddJobType ] = useState({
        typeSelected: true,
        fulltimeSelected: true,
        contractSelected: false,
        partTimeSelected: false,
        tempToHireSelected: false,
        volunteerSelected: false,
        disableSubmit: true,
    })

    const classes = (dynamic, classes = "") => {
        return Object.entries(dynamic)
            .filter((entry) => entry[1])
            .map((entry) => entry[0])
            .join(" ")
            .concat(" ")
            .concat(classes)
    }

    const [ addJobVal, setAddJobVal ] = useState({
        template: '',
        title: '',
        primary: '',
        placement: ''
    })

    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS', 'LINK_BUTTONS', 'BLOCK_TYPE_DROPDOWN', 'HISTORY_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
          { label: 'Bold', style: 'BOLD', className: 'custom-css-class' },
          { label: 'Italic', style: 'ITALIC' },
          { label: 'Underline', style: 'UNDERLINE' }
        ],
        BLOCK_TYPE_DROPDOWN: [
          { label: 'Normal', style: 'unstyled' },
          { label: 'Heading Large', style: 'header-one' },
          { label: 'Heading Medium', style: 'header-two' },
          { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
          { label: 'UL', style: 'unordered-list-item' },
          { label: 'OL', style: 'ordered-list-item' }
        ]
      }

    const onChange = (value) => {
        setValue(value);
        setAddJobData({ ...addJobData, description: value.toString('html') })    
    }   

    const fetchData = async () => {
        try {
          setLoading(true)
          const res = await get(`/job/dropdownvalues`, { domainName: domain })        
          setDropDown(res.data[0])
          setLoading(false)
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
    }
      
    useEffect(() => {
        fetchData()      
    }, [])

    const autocompleteRenderInput = ({ addTag, ...props }) => {
        const theme = {
          suggestionsContainerOpen: {
            display: "block",
            position: "absolute",
            width: "95%",
            border: "1px solid #aaa",
            listStyle: "none",
            zIndex: 200,
            backgroundColor: "rgb(255, 255, 255)",
            fontSize: 14,
            fontFamily: "sans-serif",
            maxHeight: "250px",
            overflow: "auto",
            padding: "5px 15px",
          },
          suggestionsList: {
            listStyleType: "none",
          },
          suggestion: {
            cursor: "pointer",
            padding: "5px 0px",
          },
          suggestionHighlighted: {
            backgroundColor: "rgba(114, 112, 112, 0.125)",
          },
        };
    
        const handleOnChange = (e, { newValue, method }) => {
          if (method === "enter") {
            e.preventDefault();
          } else {
            props.onChange(e);
          }
        };
    
        const getSuggestionValue = (suggestion) => suggestion.PositionName;
    
        const getSuggestions = (value) => {
          const inputValue = value.trim().toLowerCase();
          const inputLength = inputValue.length;
          const suggestValues =
            (inputLength === 0 || Object.keys(dropdownList).length === 0)
              ? []
              : dropdownList.skills.filter((item) =>
                item.PositionName.toLowerCase().includes(inputValue)
              );
          return suggestValues;
        };
    
        const renderSuggestion = (suggestion) => (
          <div>{suggestion.PositionName}</div>
        );
    
        const value = (props.value && props.value.trim().toLowerCase()) || "";
        let suggestions = getSuggestions(value);
    
        return (
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={() => { }}
            onSuggestionsClearRequested={() => { }}
            getSuggestionValue={getSuggestionValue}
            renderSuggestion={renderSuggestion}
            inputProps={{ ...props, onChange: handleOnChange }}
            onSuggestionSelected={(e, { suggestion }) => {
              addTag(suggestion.PositionName);
            }}
            theme={theme}
          />
        );
    }

    const handleChangePrimarySkills = (skills) => {
        setAddJobData({ ...addJobData, primarySkills: skills });
    }

    const handleChangeInputPrimarySkills = (skills) => {
        setPrimarySkillInput(skills);
    }

    const handleChange = (e) => {
        setAddJobData({
            ...addJobData,
            [e.target.name]: e.target.value,
        })
    }

    const handleChangeSecondarySkills = (skills) => {
        setAddJobData({ ...addJobData, secondarySkills: skills });
    }

    const handleChangeInputSecondarySkills = (skills) => {
        setSecondarySkillSInput(skills);
    }

    const handleSkillSet = (e) => {
        setAddJobData({
            ...addJobData,
            skillSet: e.map((skillSet) => skillSet.value),
        })
    }

    const handleEducation = (e) => {
        setAddJobData({
            ...addJobData,
            education: e.map((QualificationType) => QualificationType.value),
        })
    }

    const isPercentage = (e) => {
        setAddJobData({ ...addJobData, isFeePercentage: e.target.checked, placementFee: "" })
    }

    const handleStartTime = (e) => {
        const endtime = moment.utc(e.target.value, 'HH:mm').add(9, 'hours').format('HH:mm')
        setAddJobData({ ...addJobData, workStart: e.target.value, workEnd: endtime })
    }

    const handleEndTime = (e) => {
        setAddJobData({ ...addJobData, workEnd: e.target.value })
    }

    const skillSetOption = Object.keys(dropdownList).length > 0 ? (dropdownList.skillSet).map((item) => {
        const skillSetObj = { value: item.PositionName, label: item.PositionName };
        return skillSetObj;
    }) : []
    
    const QualificationType = Object.keys(dropdownList).length > 0 ? (dropdownList.QualificationType).filter(item => item.PositionName !== 'Select').map((item) => {
    const QualificationObj = { value: item.PositionName, label: item.PositionName }
    return QualificationObj 
    }) : []

    const onTypeClick = (value) => {

        if (value === "Full Time") {
          setAddJobType({
            ...addJobType,
            typeSelected: true,
            volunteerSelected: false,
            tempToHireSelected: false,
            partTimeSelected: false,
            fulltimeSelected: true,
            contractSelected: false,
          })
          setAddJobData({ ...addJobData, type: "Full Time" })
        }

        if (value === "Contract") {
          setAddJobType({
            ...addJobType,
            typeSelected: true,
            volunteerSelected: false,
            tempToHireSelected: false,
            partTimeSelected: false,          
            fulltimeSelected: false,
            contractSelected: true,
          })
          setAddJobData({ ...addJobData, salaryType: "Per hour", type: "Contract" })
        }

        if (value === "Part Time") {
          setAddJobType({
            ...addJobType,
            typeSelected: true,
            volunteerSelected: false,
            tempToHireSelected: false,
            partTimeSelected: true,         
            fulltimeSelected: false,
            contractSelected: false,
          })
          setAddJobData({ ...addJobData, salaryType: "Per hour", type: "Part Time" })
        }
    
        if (value === "Temp To Hire") {
          setAddJobType({
            ...addJobType,
            typeSelected: true,
            volunteerSelected: false,
            tempToHireSelected: true,
            partTimeSelected: false,          
            fulltimeSelected: false,
            contractSelected: false,
          })
          setAddJobData({ ...addJobData, salaryType: "Per hour", type: "Temp To Hire" })
        }
    
        if (value === "Volunteer") {
          setAddJobType({
            ...addJobType,
            typeSelected: true,
            volunteerSelected: true,
            tempToHireSelected: false,
            partTimeSelected: false,
            fulltimeSelected: false,
            contractSelected: false,
          })
          setAddJobData({ ...addJobData, salaryType: "Per hour", type: "Volunteer" })
        }
    }

    const submitTemplate = async () => {      
        setLoading(true)
        let questionErr = 0
        const validationArray = []
        let validation = { 
            template: '',
            title: '',
            primary: '',
            placement: '' 
        }
        if(templateName === '') {
            validation = { ...validation, template: '*Required Field'}
            validationArray.push('Please Enter Template Name')
        }
        if(templates.map(i => i.templateName).includes(templateName)) {
            validation = { ...validation, template: '*This name already exists'}
            validationArray.push('Template Name Already Exists. Please Try Another Name')
        }
        if(addJobData.title === '') {
            validation = { ...validation, title: '*Required Field'}
            validationArray.push('Please Enter Job Title')
        }
        if(addJobData.primarySkills.length === 0) {
            validation = { ...validation, primary: '*Required Field'}
            validationArray.push('Please Enter Primary Skills')
        }
        if(additionalInfoFinal.map(i => i.answer).some(i => i === '')) {
            validationArray.push('Please Enter Answers for all the Additional Info you have chosen')
            questionErr = 1
        }
        if(validationArray.length > 0) {
            toast.error(validationArray[0])
            if(questionErr !== 0 && validationArray.length === 1) {
                setDetails(false)
                setAddInfo(true)
                setQuestions(false)
            }
            else {
                setDetails(true)
                setAddInfo(false)
                setQuestions(false)
            } 
            setAddJobVal(validation)
        }
        else {
            // toast.success('Validation Success')  
            setDisableSubmit(true)       
            setAddJobVal({ 
                template: '',
                title: '',
                primary: '',
                placement: '' 
            })
            let finalQandA = [...screeningQuestions]
            if(finalQandA.map(i => i.name).includes('Language')) {
                let que = [...finalQandA.filter(i => i.name !== 'Language')]
                que.push({ ...finalQandA.filter(i => i.name === 'Language')[0], input: JSON.stringify(selectedLanguages)})
                finalQandA = [...que]
            }
            let params = {
                jobDetails: JSON.stringify({ ...addJobData, placementFee: !addJobData.isFeePercentage ? addJobData.placementFee : addJobData.placementFee + "%"}),
                screeningQuestions: JSON.stringify(finalQandA),
                additionalInfo: JSON.stringify(additionalInfoFinal),
                category: templateCategory,
                templateName
            }
            console.log('PARAMS', params)
            try { 
                const res = await post('/request/addTemplate', params)
                if(res.status === 200) {
                    toast.success('Job Template Added Successfully')
                    setTimeout(() => {
                        closePanel()
                        fetchTemplates()
                    }, 5000)
                }
            } catch(err) {
                toast.success('Some Error Occurred')
                setDisableSubmit(false)
                console.log(err)
            }
        }
        setLoading(false)
    }

    return (
        <>
            { loading && <Loader /> }    

            {/* TABS */}
            <div className='mt-3'>
                <div>
                    <ul className="nav nav-tabs pt-1 ml-3">                       
                        <li className="nav-item">
                            <button
                                onClick={() => {
                                    setDetails(true)
                                    setQuestions(false)
                                    setAddInfo(false)
                                }}
                                type="button"
                                className={
                                    details ? "nav-link pb-3 active" : "nav-link pb-3"
                                }
                            >
                                Details
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                onClick={() => {
                                    setDetails(false)
                                    setQuestions(false)
                                    setAddInfo(true)
                                }}
                                type="button"
                                className={
                                    addInfo ? "nav-link pb-3 active" : "nav-link pb-3"
                                }
                            >
                                Additional Info
                            </button>
                        </li>
                        <li className="nav-item">
                            <button
                                onClick={() => {
                                    setDetails(false)
                                    setQuestions(true)
                                    setAddInfo(false)
                                }}
                                type="button"
                                className={
                                    questions ? "nav-link pb-3 active" : "nav-link pb-3"
                                }
                            >
                                Screening
                            </button>
                        </li>                     
                    </ul>
                    <hr className="mt-0" />
                </div>
            </div>
           
            { details &&
                <div>
                    <div>                      
                        <div className="d-flex mb-1">
                            <label className="ml-auto mr-4">*Denotes mandatory field</label>
                        </div> 

                        {/* TEMPLATE NAME */}
                        <div className='card card-flat bg-gray4 ml-3 mr-3 mb-3'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className={ addJobVal.template ? "form-control border border-danger" : "form-control"}
                                                name="Template"
                                                placeholder="Enter a job title"
                                                onChange={(e) => setTemplateName(e.target.value)}
                                                value={templateName}
                                            />
                                            <label>Template Name*</label>
                                            { addJobVal.template &&
                                            <small className='validation ml-2'>
                                                {addJobVal.template}
                                            </small>}
                                        </div>
                                    </div>                                   
                                </div>
                            </div>
                        </div> 
                        
                        {/* JOB TYPE */}
                        <div className='card card-flat bg-gray4 m-3'>
                            <div className='card-body py-0'>
                                <div>
                                    <label>Choose Job Type</label>
                                </div>
                                <div className="row mt-1">                       
                                    <div className='col-lg-4'>
                                        <button
                                            onClick={() => {
                                            onTypeClick("Full Time")
                                            }}
                                            type="button"
                                            className={classes(
                                            { selected: addJobType.fulltimeSelected },
                                            "col-lg-4 card card-flat p-0 mr-2 mb-2 mb-lg-0"
                                            )}
                                        >
                                            <div className="card-body text-left text-left">
                                                <div className="d-flex">
                                                    {addJobType.fulltimeSelected === true ? (
                                                    <div className="mr-3 font-green font-16">
                                                        <i className="fad fa-check-circle"></i>
                                                    </div>
                                                    ) : (
                                                    <div className="mr-3 font-muted font-16">
                                                        <i className="fad fa-circle"></i>
                                                    </div>
                                                    )}

                                                    <div>
                                                    <div>
                                                        <strong className="font-blue_primary">
                                                        Full Time
                                                        </strong>
                                                    </div>
                                                    <p className="mb-0">Hire for full time</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    <div className='col-lg-4'>
                                        <button
                                                onClick={() => onTypeClick("Contract")}
                                                type="button"
                                                className={classes(
                                                { selected: addJobType.contractSelected },
                                                "card card-flat p-0 mr-2 mb-2 mb-lg-0"
                                                )}
                                            >
                                                <div className="card-body text-left text-left">
                                                <div className="d-flex">
                                                    {addJobType.contractSelected === true ? (
                                                    <div
                                                        className="mr-3 font-green font-16"
                                                    >
                                                        <i className="fad fa-check-circle" />
                                                    </div>
                                                    ) : (
                                                    <div className="mr-3 font-muted font-16">
                                                        <i className="fad fa-circle" />
                                                    </div>
                                                    )}
                                                    <div>
                                                    <div>
                                                        <strong className="font-blue_primary">
                                                        Contract
                                                        </strong>
                                                    </div>
                                                    <p className="mb-0">
                                                        Hire for a contract
                                                    </p>
                                                    </div>
                                                </div>
                                                </div>
                                        </button>
                                    </div>                                  
                                    <h6 className="col-lg-1 pt-3">or</h6>
                                    <div className="col-lg-3 d-flex flex-column flex-lg-row form-floating p-0">
                                        <select 
                                            onChange={(e) => onTypeClick(e.target.value)} 
                                            className="font-14 form-select" 
                                            name='jobSelection' 
                                            style={{ boxSizing: "border-box", height: "61px", marginRight: "10px", marginBottom: "10px" }}
                                        >
                                            <option>Select</option>
                                            <option selected={addJobType.partTimeSelected} value="Part Time">Part Time</option>
                                            <option selected={addJobType.tempToHireSelected} value='Temp to Hire'>Temp to Hire</option>
                                            <option selected={addJobType.volunteerSelected} value='Volunteer'>Volunteer</option>
                                        </select>
                                        <label style={{ paddingLeft: "13px", fontSize: "1rem" }}><strong className="font-inherit font-blue_primary">Job Type</strong></label>
                                    </div>                                 
                                </div>
                            </div>
                        </div>
                        
                        {/* JOB TITLE and TEMPLATE CATEGORY */}
                        <div className='card card-flat bg-gray4 m-3'>
                            <div className='card-body'>
                                <div className="row">
                                    <div className='col-lg-6'>
                                        <div className="form-floating">
                                            <input
                                                type="text"
                                                className={ addJobVal.title ? "form-control border border-danger" : "form-control"}
                                                name="title"
                                                placeholder="Enter a job title"
                                                onChange={handleChange}
                                                value={addJobData.title}
                                            />
                                            <label>Job Title*</label>
                                        </div>
                                        { addJobVal.title &&
                                        <small className='validation ml-2'>
                                            {addJobVal.title}
                                        </small>}
                                    </div>                                  
                                    <div className='col-lg-6'>
                                        <div className="form-floating ml-1">
                                            <select
                                                className="form-select"                                              
                                                onChange={(e) => setTemplateCategory(e.target.value)}
                                            >
                                                <option disabled>Select</option>
                                                {
                                                    skillSetOption.map(i => i.value).map(item => <option selected={item === templateCategory} value={item}>{item}</option>)
                                                } 
                                            </select>
                                            <label>Template Category</label>
                                        </div>                 
                                    </div>                                                                                                                                          
                                </div>
                            </div>
                        </div>                                            
                        
                        {/* SALARY */}
                        <div className="card card-flat bg-gray4 m-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="form-floating pl-1">              
                                            <select className="font-14 form-select" name='salaryCurrency' onChange={handleChange}>
                                                {
                                                    Object.keys(currency_list).map(item => <option selected={item === addJobData.salaryCurrency} value={item}>{item}</option>)
                                                }                                                                                             
                                            </select>
                                            <label>Currency</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-floating">                                          
                                            <input value={addJobData.minimumPay} type="number" min='1' className="form-control" name="minimumPay" onChange={handleChange} placeholder="US$" />
                                            <label>Minimum pay</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-floating">                                
                                            <input value={addJobData.maximumPay} type="number" min='1' className="form-control" name="maximumPay" onChange={handleChange} placeholder="US$" />
                                            <label>Maximum pay</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="form-floating">                                           
                                            <select disabled={addJobType.typeSelected && !addJobType.fulltimeSelected} className="font-14 form-select" name='salaryType' onChange={handleChange}>
                                                <option selected={addJobData.salaryType === "Annual"} value="Annual">Annual</option>
                                                <option selected={addJobData.salaryType === "Per hour"} value="Per hour">Per hour</option>
                                            </select>
                                            <label>Payment type</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* PRIMARY SKILLS */}
                        <div className="card card-flat bg-gray4 m-3">
                            <div className="card-body py-0">
                                <div className="form-group">
                                    <label>Primary Skills*</label>
                                    <div className="form-floating mt-1" style={{ zIndex: '999' }}>                                                                                  
                                        <TagsInput
                                            renderInput={autocompleteRenderInput}
                                            value={addJobData.primarySkills}
                                            onChange={handleChangePrimarySkills}
                                            inputValue={primarySkillinput}
                                            onChangeInput={handleChangeInputPrimarySkills}
                                            placeholder="Type a location and hit enter"
                                            inputProps={{
                                                placeholder: primarySkillinput ? '' : 'Enter skills*',
                                            }}                                         
                                        />                                        
                                        { addJobVal.primary &&
                                        <small className="validation ml-2">
                                            {addJobVal.primary}
                                        </small>}
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* SECONDARY SKILLS */}
                        <div className="card card-flat bg-gray4 m-3">
                            <div className="card-body py-0">
                                <div className="form-group">
                                    <label>Secondary Skills</label>
                                    <div className="form-floating mt-1" style={{ zIndex: '998' }}>                                                                            
                                    <TagsInput
                                        renderInput={autocompleteRenderInput}
                                        value={addJobData.secondarySkills}
                                        onChange={handleChangeSecondarySkills}
                                        inputValue={secondarySkillsInput}
                                        onChangeInput={handleChangeInputSecondarySkills}
                                        placeholder="Type a location and hit enter"
                                        inputProps={{
                                            placeholder: secondarySkillsInput ? '' : 'Enter skills',
                                        }}
                                    />                                
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* SKILLSETS */}
                        <div className="card card-flat bg-gray4 m-3">
                            <div className="card-body py-0">
                                <div className='form-group'>
                                    <label>SkillSets</label>
                                    <div className="form-floating mt-1 Hover-Dropdown" style={{ zIndex: '997' }}>
                                        <Select
                                            isMulti
                                            options={skillSetOption}
                                            name="placementSkill"
                                            onChange={handleSkillSet}
                                            isSearchable={true}
                                            value={skillSetOption.filter(item => addJobData.skillSet.includes(item.value))}
                                        >
                                        </Select>
                                    </div>
                                </div>                 
                            </div>
                        </div>

                        {/* DESCRIPTION */}
                        <div className="card card-flat bg-gray4 m-3">
                            <div className="card-body pt-0"> 
                                <label>Description</label>         
                                <div className="form-floating">
                                    <RichTextEditor toolbarConfig={toolbarConfig}
                                        value={value}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* EDUCATION */}
                        <div className="card card-flat bg-gray4 m-3">
                            <div className="card-body py-0">
                            <div className="form-floating">
                                <div className="form-group text-left">
                                    <label className="mb-2">Required education</label>
                                    <div className="form-floating Hover-Dropdown">
                                        <Select
                                            isMulti
                                            options={QualificationType}
                                            value={QualificationType.filter(item => addJobData.education.includes(item.value))}
                                            name="Qualification"
                                            onChange={handleEducation}
                                            isSearchable={true}
                                        >
                                        </Select>
                                    </div>                              
                                </div>
                            </div>
                            </div>
                        </div>

                        {/* EXPERIENCE LEVEL */}
                        <div className="card card-flat bg-gray4 m-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-4">
                                        <div className="form-floating">
                                            <select className="font-14 form-select" name="experienceLevel" onChange={handleChange}>
                                                {/* <option disabled>Select</option> */}
                                                {
                                                    experienceLevel.map(e => 
                                                    <option selected={e === addJobData.experienceLevel} value={e}>{e}</option>)
                                                }                                   
                                            </select>
                                            <label>Required experience</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating">
                                            <select className="font-14 form-select" name="visaRequirement" onChange={handleChange}>
                                                <option disabled>Select</option>
                                                {
                                                    visaStatus.map(v => 
                                                        <option selected={v === addJobData.visaRequirement} value={v}>{v}</option>    
                                                    )
                                                }                                              
                                            </select>
                                            <label>Visa Requirement</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div className="form-floating">
                                            <input value={addJobData.licenceRequirement} type="text" className="form-control" name="licenceRequirement" onChange={handleChange} placeholder="License Requirement" />
                                            <label>License Requirement</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* WORK SCHEDULE */}
                        <div className="card card-flat bg-gray4 m-3">
                            <div className="card-body pt-0">
                                <div className="row">
                                    <div className="col-lg-12 text-left">
                                        <label className="mb-3">Work Schedule</label>
                                        <hr className="mt-0 mb-3"></hr>
                                    </div>
                                </div>
                                <div className="row align-items-center">
                                    <div className="col-lg-4 mb-2 mb-lg-0">
                                        <div className="form-check form-switch pl-0 d-flex align-items-center">
                                            <label className="form-check-label pt-0" type="checkbox">Is Flexible?</label>
                                            <input className="form-check-input ml-3" 
                                                onChange={(e) => { 
                                                    if(e.target.checked) {
                                                        setAddJobData({ ...addJobData, isFexible: e.target.checked, workStart: '', workEnd: '' })
                                                    }
                                                    else {
                                                        setAddJobData({ ...addJobData, isFexible: e.target.checked })
                                                    }                         
                                                }} 
                                                type="checkbox" checked={addJobData.isFexible} />
                                        </div>
                                    </div>
                                    <div className="col-lg-4 mb-2 mb-lg-2">
                                    <div className="form-floating">
                                        {addJobData.isFexible === false ? (

                                        <input type="time" className="form-control" value={addJobData.workStart} onChange={(e) => handleStartTime(e)} />


                                        ) : (

                                        <input type="time" disabled className="form-control" value={addJobData.workStart} onChange={(e) => handleStartTime(e)} />

                                        )}
                                        <label>Work Hour Start</label>
                                    </div>
                                    </div>
                                    <div className="col-lg-4 mb-2 mb-lg-2">
                                    <div className="form-floating">
                                        {addJobData.isFexible === false ? (
                                        <input type="time" className="form-control" value={addJobData.workEnd} onChange={(e) => handleEndTime(e)} />
                                        ) : (
                                        <input type="time" disabled className="form-control" value={addJobData.workEnd} onChange={(e) => handleEndTime(e)} />

                                        )}
                                        <label>Work Hour End</label>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* TRAVEL */}
                        <div className="card card-flat  bg-gray4 m-3">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-floating">
                                            <select className="font-14 form-select" name='travel' onChange={handleChange}>
                                                <option disabled>Select</option>
                                                {
                                                    ['None', '15%', "25%", "50%", "75%", "100%"].map(t => 
                                                        <option selected={t === addJobData.travel} value={t}>{t}</option>
                                                    )
                                                }                                               
                                            </select>
                                            <label>Travel</label>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="form-floating">
                                            <select className="font-14 form-select" name="securityClearance" onChange={handleChange}>
                                                <option disabled>Select</option>
                                                <option selected={addJobData.securityClearance === "None"} value="None">None</option>
                                                <option selected={addJobData.securityClearance === "Required"} value="Required">Required</option>
                                                <option selected={addJobData.securityClearance === "Confidential"} value="Confidential">Confidential</option>
                                            </select>
                                            <label>Security Clearance</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* DRUG TEST and BACKGROUND CHECK */}
                        <div className="card card-flat  bg-gray4 m-3">
                            <div className="card-body">
                                <div className="row align-items-center">
                                    <div className="col-lg-6 my-2 mb-lg-0">
                                        <div className="form-check pl-0 form-switch d-flex align-items-center">
                                            <label className="form-check-label pt-0">Drug Test Required?</label>
                                            <input 
                                                className="form-check-input ml-3"
                                                type="checkbox"
                                                checked={addJobData.drugTest}
                                                onChange={(e) => setAddJobData({ ...addJobData, drugTest: e.target.checked })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 my-2 mb-lg-0">
                                        <div className="form-check pl-0 form-switch d-flex align-items-center">
                                            <label className="form-check-label pt-0">Background Check Required?</label>
                                            <input                                              
                                                className="form-check-input ml-3"
                                                type="checkbox"                                               
                                                checked={addJobData.backgroundCheck}
                                                onChange={(e) => setAddJobData({ ...addJobData, backgroundCheck: e.target.checked })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    

                        {/* SUBMITTAL INSTRUCTIONS */}
                        <div className="card card-flat bg-gray4 m-3">
                            <div className="card-body">
                                <label className='pt-o mb-1'>Submittal Instructions</label>
                                <div className="form-floating">
                                    <textarea
                                        className="form-control addjob-sumittal"
                                        placeholder="Leave submittal instructions"
                                        name="submittal"
                                        onChange={handleChange}
                                        value={addJobData.submittal}
                                    >
                                    </textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {
                (questions || addInfo) && 
                    <ScreeningAddInfo
                        screeningQuestions={screeningQuestions}
                        setScreeningQuestions={setScreeningQuestions}
                        additionalInfoFinal={additionalInfoFinal}
                        setAdditionalInfoFinal={setAdditionalInfoFinal}
                        skillSetOption={skillSetOption}
                        QualificationType={QualificationType}
                        selectedLanguages={selectedLanguages}
                        setSelectedLanguages={setSelectedLanguages}
                        questions={questions}
                        addInfo={addInfo}
                        component={'Template'}
                    />
            }

            <>
                <hr />
                { details ?
                <div 
                    className='d-flex align-items-center justify-content-end m-3'                   
                >
                    <button 
                        className='btn btn-sm btn-primary ml-2' 
                        onClick={() => {                           
                            setDetails(false)
                            setQuestions(true)
                        }}
                    >
                        Next
                    </button> 
                </div> :
                <div className='d-flex align-items-center justify-content-end m-3'>
                    {questions &&
                        <button 
                            className='btn btn-sm mr-2' 
                            onClick={() => {
                                setDetails(true)
                                setQuestions(false)
                            }}
                        >
                            Back
                        </button>
                    }
                    <button className='btn btn-sm btn-secondary' onClick={closePanel}>
                        Cancel
                    </button>
                    { questions &&
                    <button disabled={disableSubmit} className='btn btn-sm btn-primary ml-2' onClick={submitTemplate}>
                        Submit
                    </button>}
                </div> }
            </>
        </>
    )
}

export default AddJobTemplate