
import { toast } from 'react-toastify';
import { post } from '../../service/ApiService';

// Action Types
export const UPDATE_LAST_LOGIN_REQUEST = 'UPDATE_LAST_LOGIN_REQUEST';
export const UPDATE_LAST_LOGIN_SUCCESS = 'UPDATE_LAST_LOGIN_SUCCESS';
export const UPDATE_LAST_LOGIN_FAILURE = 'UPDATE_LAST_LOGIN_FAILURE';

// Action Creators
export const updateLastLoginRequest = () => ({
  type: UPDATE_LAST_LOGIN_REQUEST,
});

export const updateLastLoginSuccess = () => ({
  type: UPDATE_LAST_LOGIN_SUCCESS,
});

export const updateLastLoginFailure = (error) => ({
  type: UPDATE_LAST_LOGIN_FAILURE,
  payload: error,
});

// Async Action Creator
export const updateLastLogin = () => {
 
  return async (dispatch) => {
    dispatch(updateLastLoginRequest());
    try {
      const res = await post('/updatelastlogin/save');
      if (res.status === 200) {
        dispatch(updateLastLoginSuccess());
      }
    } catch (error) {
      dispatch(updateLastLoginFailure(error));
      toast.error(error?.response?.data?.errMessage);
    }
  };
}