import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { template, jobDetail } from "../../../actions/job/AddJob";

const SelectJob = ({
  data,
  pageLimit,
  dataLimit,
  searchValue,
  searchData,
  setJobDetails
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const dispatch = useDispatch();
  const { jobList, loading } = useSelector(
    (state) => ({
      jobList: state.AddJob.jobList,
      loading: state.AddJob.loading,
    }),
    shallowEqual
  );

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData?.slice(startIndex, endIndex)
      : data?.slice(startIndex, endIndex);
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(data?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(data?.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    setCurrentPage(1);
  }, [dataLimit, searchData]);

  useEffect(() => {
    setPaginationArray(
      new Array(Math.ceil(data?.length / dataLimit))
        ?.fill()
        ?.map((_, i) => i + 1)
    );
    setPaginationGroup(
      new Array(Math.ceil(data?.length / dataLimit))
        ?.fill()
        ?.map((_, i) => i + 1)
        ?.slice(0, pageLimit)
    );
  }, [jobList]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    } else {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  const splitMatchedText = (str) => {
    const regExp = new RegExp(searchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };


  return (
    <>
      <div className="m-3">
        {getPaginatedData()?.map((request, i) => (
          <a
            key={i}
            onClick={() => setJobDetails(request)}
            className="card card-flat bg-gray4 m-1 p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
          >
            <div className="card-body">
              <div className="d-flex align-items-center">
                <span className="mr-3">
                  <h6>{request?.jobTitle} </h6>
                </span>
                {request.isHotJob && (
                  <span className="tag tag-coral mr-9">HOT</span>
                )}
              </div>
              <div className="d-flex align-items-center">
                <span className="d-flex align-items-center me-3">
                  <i className="far fa-suitcase me-2 font-brandBlue"></i>
                  <small className="font-regular font-primary font-gray1">
                    {request.jobType}
                  </small>
                </span>
                <span className="d-flex align-items-center me-3">
                  <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                  <span className="font-regular font-primary font-gray1">
                    {request?.city || "Remote"}
                  </span>
                </span>
              </div>
            </div>
          </a>
        ))}
        {getPaginatedData()?.length === 0 && (
          <div>
            <div class="text-center p-3">
              <div class="avatar avatar-lg">
                <i class="fad fa-users-slash"></i>
              </div>
              <div class="mt-2">
                No records found {searchValue && "for " + searchValue}
              </div>
            </div>
          </div>
        )}
        <hr />
        <div className="d-flex p-2">
          <div className="w-100-370 mb-point5-370">
            Showing{" "}
            {(getPaginatedData()?.length < dataLimit
              ? getPaginatedData()?.length
              : dataLimit) + " "}
            of {data?.length}
          </div>
          <div class="ml-auto mx-auto-370 d-flex align-items-center">
            {getPaginatedData()?.length !== 0 && (<button
              onClick={() => {
                setPageType("down");
                setCurrentPage((page) => page - 1);
              }}
              type="button"
              disabled={
                currentPage === 1 || getPaginatedData()?.length === 0
                  ? true
                  : false
              }
              className="btn btn-icon px-2"
            >
              <i className="fal fa-chevron-circle-left"></i>
            </button>)}

            {paginationGroup.map((item) => (
              <button
                type="button"
                className={
                  currentPage === item
                    ? "btn btn-text px-2 bg-secondary text-white"
                    : "btn btn-text px-2"
                }
                onClick={changePage}
              >
                {item}
              </button>
            ))}

            {getPaginatedData()?.length !== 0 && (<button
              onClick={() => {
                setPageType("up");
                setCurrentPage((page) => page + 1);
              }}
              type="button"
              hidden={
                currentPage === paginationArray.slice(-1)[0] ||
                getPaginatedData()?.length === 0
                  ? true
                  : false
              }
              className="btn btn-icon px-2"
            >
              <i className="fal fa-chevron-circle-right"></i>
            </button>)}
          </div>
        </div>
      </div>
    </>
  );
};

export default SelectJob;
