import React, { useEffect } from "react";
import RichTextEditor from "react-rte";
import { toolbarRTEConfig } from "../../../../../constants/Constants";

const Description = (props) => {
  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body pt-0">
          <div className="d-flex align-items-center my-3">
            <p className="m-0 mr-3">Description*</p>
          </div>
          <div className="form-floating">
            <RichTextEditor
              toolbarConfig={toolbarRTEConfig}
              value={props?.value}
              onChange={props?.onChange}
              className={
                props?.isFormValid &&
                props?.addJobData?.description == "" &&
                "border border-danger h-25"
              }
            />
            {props?.isFormValid && props?.addJobData?.description == "" && (
              <small className="validation">*Required field</small>
            )}
          </div>
          {/* {!(props?.addTemplate || props?.editTemplate) && (
            <div className="card card-flat mt-2">
              <div className="card-body">
                {props?.documents?.findIndex((i) => i.type === "JD") !== -1 ? (
                  <div className="ml-auto mr-2">
                    <span>
                      <button
                        type="button"
                        onClick={() => {
                          props?.setDocuments(
                            props?.documents?.filter((i) => i.type !== "JD")
                          );
                        }}
                        className="btn btn-icon"
                        title="Delete"
                      >
                        <i className="fa fa-trash mr-2"></i>
                      </button>
                      Document Uploaded Successfully
                      <FaCheckCircle
                        className="ml-2"
                        style={{
                          color: "green",
                          height: "1.2rem",
                          width: "1.2rem",
                        }}
                      />
                    </span>
                  </div>
                ) : (
                  <div className="d-flex align-items-center">
                    <p className="m-0 mr-2">
                      Do you want to upload job description document?
                    </p>
                    <Dropzone
                      onDrop={(file) => props?.onUploadHandler(file, "JD")}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()} className="">
                          <button
                            type="button"
                            className="btn btn-sm btn-secondary"
                          >
                            <i className="far fa-cloud-upload mr-1 fa-fw"></i>
                            Upload
                          </button>
                          <input
                            type="file"
                            {...getInputProps()}
                            accept=".docx, .pdf, application/msword"
                          />
                        </div>
                      )}
                    </Dropzone>
                    <div className="ml-3">
                      <small>
                        Supported formats: .docx and .pdf <br /> File size: upto
                        2 MB
                      </small>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default Description;
