import {
  GET_EMAIL_DATA_BEGIN,
  GET_EMAIL_DATA_SUCCESS,
  GET_EMAIL_DATA_FAILURE,
  UPDATE_EMAIL_DATA_BEGIN,
  UPDATE_EMAIL_DATA_SUCCESS,
  UPDATE_EMAIL_DATA_FAILURE,
  TEST_MAIL_BEGIN,
  TEST_MAIL_SUCCESS,
  TEST_MAIL_FAILURE,
  ACTIVATE_EMAIL_BEGIN,
  ACTIVATE_EMAIL_SUCCESS,
  ACTIVATE_EMAIL_FAILURE,
} from "../../actions/adminconsole/EmailTemplate";

const initialState = {
  emailLoading: true,
  emailData: [],
  emailUpdated: false,
  emailSendLoading: false,
};

const emailTemplateReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_EMAIL_DATA_BEGIN:
      return {
        ...state,
        emailLoading: true,
        emailData: [],
        totalCount: 0,
      };
    case GET_EMAIL_DATA_SUCCESS:
      return {
        ...state,
        emailData: payload.emailData,
        emailLoading: false,
      };
    case GET_EMAIL_DATA_FAILURE:
      return {
        ...state,
        emailData: [],
        emailLoading: false,
      };
    case UPDATE_EMAIL_DATA_BEGIN:
      return {
        ...state,
        emailUpdated: false,
        emailLoading: true,
      };
    case UPDATE_EMAIL_DATA_SUCCESS:
      return {
        ...state,
        emailLoading: false,
        emailUpdated: true,
      };
    case UPDATE_EMAIL_DATA_FAILURE:
      return {
        ...state,
        emailLoading: false,
        emailUpdated: false,
      };
    case TEST_MAIL_BEGIN:
      return {
        ...state,
        emailLoading: true,
        emailSendLoading: true,
      };
    case TEST_MAIL_SUCCESS:
      return {
        ...state,
        emailLoading: false,
        emailSendLoading: false,
      };
    case TEST_MAIL_FAILURE:
      return {
        ...state,
        emailLoading: false,
        emailSendLoading: false,
      };
    case ACTIVATE_EMAIL_BEGIN:
      return {
        ...state,
        emailUpdated: false,
        emailLoading: true,
      };
    case ACTIVATE_EMAIL_SUCCESS:
      return {
        ...state,
        emailLoading: false,
        emailUpdated: true,
      };
    case ACTIVATE_EMAIL_FAILURE:
      return {
        ...state,
        emailLoading: false,
        emailUpdated: false,
      };

    default:
      return state;
  }
};

export default emailTemplateReducer;
