import { useEffect, useState } from "react";
import SearchFilter from "../../newFilter/searchFilter/SearchFilter";
import DDMS from "../../newFilter/dropDownMSFilter/DDMSFilter";
import { STATUS } from "../../../../constants/Constants";
import CreatedOnFilter from "../../newFilter/createdOnFilter/CreatedOnFilter";
import { getAuditLogJobIDFilterData } from "../../../../actions/auditlog/getAuditLogJobIdFilterData";
import { getTalentRecruiterList } from "../../../../actions/talent/TalentRecruiterFilter";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const NewAuditLogFilter = (props) => {
  const dispatch = useDispatch();

  const {
    jobIds,
    jobIdsLoading,
    recruiterDataLoading,
    recruiterData,
  } = useSelector((state) => {
    return {
      jobIds: state.auditLogJobIDs.auditLogJobIdFilterData,
      jobIdsLoading: state.auditLogJobIDs.loading,
      recruiterData: state.recruiterFilterData.recruiterData,
      recruiterDataLoading: state.recruiterFilterData.loading,
    };
  }, shallowEqual);

  const { selectedData, setSelectedData, applyFilter } = props;
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [jobIdOptions, setJobIdOptions] = useState([]);
  const [collapsible, setCollapsible] = useState({
    jobId: false,
    recruiterName: false,
    status: false,
    recordedOn: false,
  });

  const fetchJobIds = async (searchValue, filter) => {
    if (filter == "jobTitle" && collapsible[filter]) {
      const payload = {
        searchText: searchValue ?? "",
      };
      dispatch(getAuditLogJobIDFilterData(payload));
    }
  };

  const handleFilterToggle = (filter) => {
    const updatedCollapsible = {};
    for (const key in collapsible) {
      if (key === filter) {
        updatedCollapsible[key] = !collapsible[key]; // Toggle the state
      } else {
        updatedCollapsible[key] = false; // Close all other filters
      }
    }
    setCollapsible(updatedCollapsible);
  };

  const handleAPICalls = (filter) => {
    if (filter === "jobId") {
      const payload = {
        searchText: "",
      };
      dispatch(getAuditLogJobIDFilterData(payload));
    }
    if (filter === "recruiterName") {
      const payload = {
        currentPage: 1,
        limit: 5,
        searchText: "",
      };
      dispatch(getTalentRecruiterList(payload));
    }
  };

  const handleClear = () => {
    setSelectedData({
      jobId: [],
      recruiterName: [],
      status: [],
      memberFrom: ["", ""],
      memberFromType: "",
    });
    applyFilter("", "");
  };

  useEffect(() => {
    if (jobIds && jobIds.length > 0) {
      setJobIdOptions(
        jobIds.map((item) => {
          return { value: item.jobId, label: item.jobId };
        })
      );
    }
  }, [jobIds]);

  const filterLength =
    (selectedData.jobId.length ? 1 : 0) +
    (selectedData?.memberFromType?.length ? 1 : 0) +
    (selectedData?.status?.length ? 1 : 0) +
    (selectedData?.recruiterName?.length ? 1 : 0);
  return (
    <>
      <div className="card-flat">
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="px-3"></div>
          <div>
            <button
              disabled={filterLength == 0}
              type="button"
              className="btn btn-text"
              onClick={handleClear}
            >
              <span className="font-bold">Clear All</span>
            </button>
          </div>
        </div>
        <hr />

        <div className="view-panel-body view-panel-body-sm">
          {/* Job Id */}
          <DDMS
            fetchOptions={fetchJobIds}
            handleFilterToggle={handleFilterToggle}
            filterEnabled={collapsible.jobId}
            filterStatus={"jobId"}
            filterName={"Job Id"}
            options={jobIdOptions}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            applyFilter={applyFilter}
            selected={selectedId}
            setSelected={setSelectedId}
            isMulti={false}
            isLoading={jobIdsLoading}
            handleAPICalls={handleAPICalls}
          />{" "}
          {/* Recruiter Name */}
          <SearchFilter
            handleFilterToggle={handleFilterToggle}
            filterEnabled={collapsible.recruiterName}
            filterName={"Recruiter Name"}
            filterStatus={"recruiterName"}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            applyFilter={applyFilter}
            handleAPICalls={handleAPICalls}
            isLoading={recruiterDataLoading}
          />
          {/* Status */}
          <DDMS
            fetchOptions={() => {}}
            handleFilterToggle={handleFilterToggle}
            filterEnabled={collapsible.status}
            filterStatus={"status"}
            filterName={"Status"}
            options={STATUS}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            applyFilter={applyFilter}
            selected={selectedStatus}
            setSelected={setSelectedStatus}
            handleAPICalls={handleAPICalls}
          />
          {/* Recorded on */}
          <CreatedOnFilter
            handleFilterToggle={handleFilterToggle}
            filterEnabled={collapsible.recordedOn}
            filterName={"Recorded on"}
            filterStatus={"recordedOn"}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            applyFilter={applyFilter}
          />
        </div>
      </div>
    </>
  );
};

export default NewAuditLogFilter;
