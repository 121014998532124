import moment from "moment";
import React from "react";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import { countryObj } from "../../constants/Constants";
import Loader from "../Loader/Loader";

const EmailPreview = ({
  userChecked,
  recruiterChecked,
  loading,
  setEmailCount,
}) => {
  const [toRecruiter, setToRecruiter] = useState(
    recruiterChecked.map((i) => i.firstName)
  );
  setEmailCount(userChecked.length);
  var date = new Date();

  const customStyles = {
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#dcedff",
      };
    },
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };
  return (
    <>
      {loading && <Loader />}
      <div className="m-4">
        <div className="mt-3">
          <Row>
            <div className="col-lg-12">
              <div className="form-floating">
                <div class="mb-1">
                  <label>To* </label>
                </div>
                <Select
                  isMulti
                  value={toRecruiter.map((item) => {
                    return { value: item, label: item };
                  })}
                  styles={customStyles}
                  isSearchable={false}
                  isDisabled={true}
                />
              </div>
            </div>
          </Row>
          <hr></hr>
          <Row>
            Below are the open jobs, you can click on any job link to source the
            talent and earn placement fees.
          </Row>
          <Row className="mt-2 mb-4">
            <div className="col-lg-5">
              {userChecked.map((item) => (
                <div className="card m-2" style={{ width: "550px" }}>
                  <div className="card-body">
                    <Row>
                      <Col>
                        <h6 style={{ width: "250px" }}>{item.jobTitle}</h6>
                      </Col>
                      <Col>
                        <p>
                          Placement Fee: {`${item.placementFee}`}{" "}
                          {item.isFeePercentage ? (
                            "%"
                          ) : (
                            <span>{item.placementCurrency}</span>
                          )}
                        </p>
                      </Col>
                    </Row>
                    <div className="row m-0 p-0">
                      <div className="col-lg-4">
                        <span className="font-16">{item.jobTenant}</span>
                      </div>
                    </div>
                    <div className="row m-0 p-0">
                      <div className="col-lg-12">
                        {item.state.length > 0 && item.city.length > 0 ? (
                          <span className="font-14">{`${item.city},${item.state},${item.country}`}</span>
                        ) : (
                          <span>{""}</span>
                        )}
                      </div>
                    </div>
                    <div className="row m-0 p-0">
                      <div className="col-lg-4">
                        <span className="font-14">
                          {moment(item.jobPostDate).format("MM/DD/YYYY")}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Row>
          {/* <Row>
            <p>Best Regards,</p>
            <br />
            <p className="mt-0">Team High5Hire.</p>
          </Row>
          <Row className="mt-2 mb-2">
            <Col>
              <p>
                You are receiving this email because you are registered to{" "}
                <a href="https://www.high5hire.com/">High5Hire</a> as a
                recruiter. If you dont want to receive emails like these
                anymore, you can Unsubscribe.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <p>
                Copyright ©{" "}
                <a href="https://www.high5hire.com/">high5hire.com</a>. All
                rights reserved
              </p>
            </Col>
          </Row> */}
        </div>
      </div>
    </>
  );
};

export default EmailPreview;
