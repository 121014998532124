import React from "react";
import InviteTalentByExcel from "./InviteTalentByExcel";


const InviteBulkTalent = (props) => {
  return (
    <>
      <InviteTalentByExcel
        handleSuccessForm={props.handleSuccessForm}
        closeModal={props.closeModal}
        handleSuccess={props.shareClose}
        jobData={props.jobData}
      />
    </>
  );
};
export default InviteBulkTalent;
