import { combineReducers } from "redux";
import Login from "./auth/Login";
import AddCandidate from "./modal/AddCandidate";
import TalentList from "./talent/talentList";
import TalentCount from "./talent/talentCount.js";
import tovutiReducer from "./tovuti/tovuti.js";
import getTalentEmailReducer from "./talent/getTalentEmail";
import Register from "./auth/Register";
import AddJob from "./job/AddJob";
import getTenantReducer from "./tenant/getTenant";
import JobList from "./job/JobList";
import jobDetails from "./job/JobDetails";
import dispositionDetails from "./disposition/Disposition";
import candidateDetailsReducer from "./candidate/CandidateDetails";
import jobTemplate from "./job/JobTemplate";
import getSubmittedTalentListReducer from "./talent/cmSubmittedTalents";
import employeesDetails from "./employees/Empoyees";
import vettingAssessmentReducer from "./vetting/getAssessments";
import clientListReducer from "./executiveadmin/GetClient";
import jobSummary from "./executiveadmin/JobSummary";
import talentSummary from "./executiveadmin/TalentSummar";
import recruiterList from "./executiveadmin/GetRecruiterList";
import userList from "./executiveadmin/High5User";
import staffingList from "./executiveadmin/GetStaffingList";
import totalRecruiter from "./executiveadmin/TotalRecruiter";
import recruiterData from "./executiveadmin/RecruiterData";
import getRecruiterListReducer from "./recruiter/RecruiterList";
import emailTemplate from "./adminconsole/EmailTemplate";
import smsTemplateReducer from "./adminconsole/SMSTemplate.js";
import cronJobsReducer from "./adminconsole/CronJobs.js";
import tagReducer from "./tagmanagement/getAllTags";
import adminTagReducer from "./tagmanagement/adminFetchAllTags";
import dropdownValuesReducer from "./common/DropdownValues";
import getNotificationsReducer from "./notification/notifications";
import broadCastReducer from "./broadcast/broadcast";
import getJobListReducer from "./jobList/jobList";
import updateProfileReducer from "./updateProfile/updateProfileReducer";
import broadCastTemplatesReducer from "./broadcast/getBroadCastTemplates";
import poolDisposition from "./directsourcing/Disposition";
import getAnalyticsReducer from "./analytics/getAnalyticsData";
import getGrowthExpansionReducer from "./analytics/getGrowthExpansionAnalytics";
import getFormReducer from "./analytics/getFormAnalytics";
import getRecruiterAnalyticsReducer from "./analytics/getRecruiterAnalyticsData";
import jobListCountReducer from "./job/JobListHeaderCounts.js";
import skillData from "./talent/TalentSkillFilter";
import designationData from "./talent/TalentDesignationFilter";
import locationData from "./talent/TalentLocationFilter";
import countryData from "./talent/TalentCountryFilter";
import stateData from "./talent/TalentStateFilter";
import countrySubCommData from "./directsourcing/talentcommunity/TalentCountrySubCommFilter.js";
import stateSubCommData from "./directsourcing/talentcommunity/TalentStateSubCommFilter.js";
import positionTypeData from "./talent/TalentPositionTypeFilter";
import recruiterFilterData from "./talent/TalentRecruiterFilter";
import sourceData from "./talent/TalentSourceFilter";
import tagData from "./talent/TalentTagFilter";
import workAuthData from "./talent/TalentWorkAuthFilter";
import getPageNumber from "./talent/Pagination.js";
import getCareerJobListReducer from "./jobList/CareerJobList.js";
import certificationFilter from "./talent/Certifications";
import specialtyData from "./talent/NursingSpecialty";
import licenseStateData from "./talent/LicenseState";
import dispositionCount from "./disposition/DispositionCount";
import poolDispositionCount from "./directsourcing/PoolDispositionCount";
import dispositionDisqualify from "./disposition/DispositionDisqualified";
import poolDispositionDisqualify from "./directsourcing/PoolDispositionDisqualify";
import getAuditLogDataReducer from "./auditlog/AuditLogData.js";
import getTalentCommunitySource from "./directsourcing/talentcommunity/TalentSourceFilter.js";
import SCTalentCertification from "./directsourcing/talentcommunity/Certifications";
import SCTalentLicenseState from "./directsourcing/talentcommunity/LicenseState";
import SCTalentNursingSpecialty from "./directsourcing/talentcommunity/NursingSpecialty";
import getDashboardCountReducer from "./dashboard/DashboardCount.js";
import getRecruiterRegistration from "./dashboard/RecruiterRegistration.js";
import getLoginOverview from "./dashboard/LoginOverview.js";
import getRecruiterUserData from "./dashboard/UserData.js";
import getRecruitementPipeline from "./dashboard/RecruitementPipeline.js";
import getJobSummary from "./dashboard/JobSummary.js";
import getClientUserData from "./dashboard/ClientUserData.js";
import getTalentSummary from "./dashboard/TalentSummary.js";
import careerFilterJobList from "./jobList/CareerFilterJobList";
import careerCount from "./jobList/CareerFilterJLCount";
import careerFilterSavedJobList from "./jobList/CareerFilterSavedJobList";
import SavedCareerCount from "./jobList/CareerFilterSavedJLCount";
import getSCAnalyticsReducer from "./directsourcing/talentcommunity/Analytics.js";
import getSubcategoryReducer from "./common/GetSubcategoryDetails.js";
import auditLogJobIDs from "./auditlog/AuditLogJobIdFilterData.js";
import ChatGPTReducer from "./chatgpt/ChatGPT.js";
import communicationReducer from "./communication/Communication.js";
import getTenantLogoReducer from "./tenant/GetTenantLogo.js";
import getSubCommunityReducer from "./common/GetSubCommunity.js";
import getTalentSubPoolListReducer from "./talent/TalentSubPoolList";
import getTalentCommunityReducer from "./directsourcing/talentcommunity/TalentCommunity.js";
import getRecruiterForCuratorReducer from "./curator/AssignCurator.js";
import curatorDetailsReducer from "./curator/Curators.js";
import getCuratorFilterReducer from "./curator/AssignCuratorFilter.js";
import curatorInviteReducer from "./curator/CuratorInvite.js";
import getUpdateCuratorReducer from "./curator/UpdateCurator.js";
import curatorSideViewDetailsReducer from "./curator/CuratorSideView.js";
import getrecruiterDashboardReducer from "./dashboard/recruiter/RecruiterDashboard.js";
import getCuratorClientDataReducer from "./curationtasks/GetCuratorClients.js";
import getEmailOverviewReducer from "./communication/GetEmailOverviewStats.js";
import getMessageOverviewReducer from "./communication/GetMessageOverviewStats.js";
export default combineReducers({
  Login,
  Register,
  AddCandidate,
  TalentList,
  TalentCount,
  tovutiReducer,
  getTalentEmailReducer,
  AddJob,
  getTenantReducer,
  JobList,
  jobDetails,
  dispositionDetails,
  candidateDetailsReducer,
  jobTemplate,
  getSubmittedTalentListReducer,
  employeesDetails,
  vettingAssessmentReducer,
  clientListReducer,
  jobSummary,
  talentSummary,
  recruiterList,
  userList,
  staffingList,
  totalRecruiter,
  recruiterData,
  getRecruiterListReducer,
  emailTemplate,
  cronJobsReducer,
  tagReducer,
  adminTagReducer,
  dropdownValuesReducer,
  getNotificationsReducer,
  broadCastReducer,
  updateProfileReducer,
  getJobListReducer,
  broadCastTemplatesReducer,
  poolDisposition,
  getAnalyticsReducer,
  getGrowthExpansionReducer,
  getFormReducer,
  getRecruiterAnalyticsReducer,
  jobListCountReducer,
  skillData,
  designationData,
  locationData,
  tagData,
  sourceData,
  recruiterFilterData,
  positionTypeData,
  workAuthData,
  getPageNumber,
  getCareerJobListReducer,
  countryData,
  stateData,
  certificationFilter,
  specialtyData,
  licenseStateData,
  smsTemplateReducer,
  dispositionCount,
  poolDispositionCount,
  dispositionDisqualify,
  poolDispositionDisqualify,
  getAuditLogDataReducer,
  getTalentCommunitySource,
  SCTalentCertification,
  SCTalentLicenseState,
  SCTalentNursingSpecialty,
  countrySubCommData,
  stateSubCommData,
  getDashboardCountReducer,
  getRecruiterRegistration,
  getLoginOverview,
  getRecruiterUserData,
  getRecruitementPipeline,
  getJobSummary,
  getClientUserData,
  getTalentSummary,
  careerFilterJobList,
  careerCount,
  careerFilterSavedJobList,
  SavedCareerCount,
  getSCAnalyticsReducer,
  getSubcategoryReducer,
  auditLogJobIDs,
  ChatGPTReducer,
  communicationReducer,
  getTenantLogoReducer,
  getSubCommunityReducer,
  getTalentSubPoolListReducer,
  getTalentCommunityReducer,
  getRecruiterForCuratorReducer,
  curatorDetailsReducer,
  getCuratorFilterReducer,
  curatorInviteReducer,
  getUpdateCuratorReducer,
  curatorSideViewDetailsReducer,
  getrecruiterDashboardReducer,
  getCuratorClientDataReducer,
  getEmailOverviewReducer,
  getMessageOverviewReducer,
});
