import React, { useState } from "react";

const SkillDetails = (props) => {
  const { jobData } = props;
  const primarySkills = jobData?.primarySkills
    ? JSON.parse(jobData.primarySkills)
    : [];
  const [showAllSkills, setShowAllSkills] = useState(false);

  const renderSkills = showAllSkills
    ? primarySkills
    : primarySkills.slice(0, 2);

  const toggleSkills = () => {
    setShowAllSkills(!showAllSkills);
  };

  return (
    <div className="mb-4 ml-4">
      <div className="d-flex mb-2 pl-1">
        <div className="ml-2">
          {renderSkills.map((item, index) => (
            <span className="tag tag-blue3 mb-1 mr-1" key={index}>
              {item}
            </span>
          ))}
          {primarySkills.length > 2 && (
            <button className="btn btn-text" onClick={toggleSkills}>
              {showAllSkills ? "View Less" : "View All"}
            </button>
          )}
          <p className="mb-0">
            <small>Mandatory Skills</small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SkillDetails;
