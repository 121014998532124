import React, { useState } from "react";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import imageLoader from "../../../images/button-loader.gif";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

function EditTag({
  selectedFile,
  setShowEditDateModal,
  talentData,
  getTalentList,
}) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [tagName, setTagName] = useState(selectedFile.tagName);
  const [submitClicked, setSubmitClicked] = useState(false);

  const updateDateHandler = async (e) => {
    setLoading(true);
    const trimmedTagName = tagName.trim();
    if (!trimmedTagName) {
      toast.error("Tag name cannot be empty");
      setLoading(false);
      return;
    }
    if (talentData) {
      const isTagExist = talentData.some((item) =>
        new RegExp(`^${item.tagName}$`, "i").test(tagName)
      );
      if (isTagExist) {
        toast.error(`Tag ${tagName} already exists`);
        setLoading(false);
        return;
      }
      const params = {
        tag: tagName,
        tagId: selectedFile.Id_Tags,
      };
      setSubmitClicked(true);
      let resp = await post("/tags/updateTag", params);
      if (resp.status === 200) {
        setShowEditDateModal(false);
        setLoading(false);
        toast.success("Tags updated successfully");
        let payload = {
          currentPage: 1,
          limit: 10,
          status: null,
          tab: "All",
          sortParam: [],
        };
        dispatch(getTalentList(payload));
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="bl-modal-body text-center">
          <div className="card card-flat bg-gray4 mb-5 ml-2  mr-2">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-floating form-date">
                    <label>Tag Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="tagName"
                      value={tagName}
                      onChange={(e) => setTagName(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
              onClick={() => setShowEditDateModal(false)}
              disabled={submitClicked}
            >
              Cancel
            </button>
            <button type="button" className="btn btn-primary btn-sm">
              {submitClicked === false ? (
                <span onClick={updateDateHandler}>Update</span>
              ) : (
                <span>
                  Updating
                  <img width="20px" src={imageLoader} alt="blank" />
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditTag;
