import moment from "moment";
import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import Avatar from "react-avatar";
import { Button, ButtonGroup, Card, Col, Row } from "react-bootstrap";
import { FaPaperPlane, FaPencilAlt } from "react-icons/fa";
import { FcAbout } from "react-icons/fc";
import { BsFillChatLeftDotsFill } from "react-icons/bs";
import { BsFillChatRightDotsFill } from "react-icons/bs";
import { MdMarkChatRead } from "react-icons/md";
import { RiChatDeleteFill } from "react-icons/ri";
import ProfileImage from "../../../../commoncomponents/profileimage/ProfileImage";
const MessageOverview = ({
  recentMessageList,
  messageOverViewData,
  setMethod,
}) => {
  console.log("messageOverViewData :", messageOverViewData);
  // const [expandedRow, setExpandedRow] = useState(null);
  // const options = {
  //   xaxis: {
  //     categories: [],
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   chart: {
  //     type: "line",
  //     zoom: {
  //       enabled: false,
  //     },
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   dataLabels: {
  //     enabled: false,
  //   },
  //   stroke: {
  //     curve: "straight",
  //     width: 2,
  //   },

  //   title: {
  //     text: "Message Delivery Rate",
  //     align: "left",
  //     fontFamily: '"Noto Sans", "Arial", sans-serif',
  //   },
  //   grid: {
  //     row: {
  //       colors: ["#ffff", "transparent"], // alternating row colors
  //       opacity: 0.5,
  //     },
  //   },
  //   xaxis: {
  //     categories: [
  //       "Monday",
  //       "Tuesday",
  //       "Wednesday",
  //       "Thursday",
  //       "Friday",
  //       "Saturday",
  //       "Sunday",
  //     ],
  //     labels: {
  //       styles: {
  //         fontFamily: '"Noto Sans", "Arial", sans-serif',
  //       },
  //     },
  //   },
  // };

  // const series = [
  //   {
  //     name: "Emails",
  //     data: [30, 40, 35, 50, 49, 60, 70],
  //     //   color: "#55c3c3",
  //   },
  // ];
  // const handleRowToggle = (rowIndex) => {
  //   if (expandedRow === rowIndex) {
  //     setExpandedRow(null);
  //   } else {
  //     setExpandedRow(rowIndex);
  //   }
  // };
  return (
    <>
      <Row className="w-100">
        {/* <Col md={6} sm={12} lg={6} className="mt-4">
          <Card>
            <ButtonGroup
              aria-label="Basic example"
              className="ml-auto mr-3"
              style={{ width: "160px", fontSize: "13px", float: "right" }}
            >
              <Button className="btn-sm" variant="secondary">
                Weekly
              </Button>

              <Button className="btn-sm" variant="secondary">
                Monthly
              </Button>
            </ButtonGroup>
            <ReactApexChart
              options={options}
              series={series}
              type="line"
              height={250}
              width={"100%"}
            />
          </Card>
        </Col> */}
        <Col
          md={12}
          sm={12}
          lg={12}
          className="w-100 d-flex justify-content-start flex-wrap align-content-center gap-3"
        >
          <Card
            className="messageOverviewCards"
            style={{ border: "1px solid #0D6EFD", backgroundColor: "#d0e3ff" }}
            // style={{ backgroundColor: "#DAEAF8" }}
            // style={{ backgroundColor: "rgb(254, 255, 240)" }}
          >
            <Card.Body>
              <Card.Title>
                <span style={{ fontSize: "14px" }}>
                  <b>Total Message Sent</b>
                </span>
                <span style={{ float: "right" }}>
                  <BsFillChatLeftDotsFill size={20} />
                </span>
              </Card.Title>
              <Card.Text>{messageOverViewData?.TotalSent || 0}</Card.Text>
            </Card.Body>
          </Card>
          <Card
            className="messageOverviewCards"
            // style={{ backgroundColor: "#ecfad4" }}
            // style={{ backgroundColor: "rgb(254, 255, 240)" }}
            style={{
              border: "1px solid #229954",
              backgroundColor: "#d7f6e4",
            }}
          >
            <Card.Body>
              <Card.Title>
                <span style={{ fontSize: "14px" }}>
                  <b>Total Message Recieved</b>
                </span>
                <span style={{ float: "right" }}>
                  <BsFillChatRightDotsFill size={20} />
                </span>
              </Card.Title>
              <Card.Text>{messageOverViewData?.TotalReceived || 0}</Card.Text>
            </Card.Body>
          </Card>

          {/* <Card
            className="messageOverviewCards"
            style={{ backgroundColor: "rgb(254, 255, 240)" }}
          >
            <Card.Body>
              <Card.Title>
                <span style={{ fontSize: "14px" }}>
                  <b>Delivery Rate</b>
                </span>
                <span style={{ float: "right" }}>
                  <MdMarkChatRead size={22} />
                </span>
              </Card.Title>
              <Card.Text>60%</Card.Text>
            </Card.Body>
          </Card> */}
          {/* <Card
            className="messageOverviewCards"
            style={{ backgroundColor: "antiquewhite" }}
            // style={{ backgroundColor: "rgb(254, 255, 240)" }}
          >
            <Card.Body>
              <Card.Title>
                <span style={{ fontSize: "14px" }}>
                  <b>Failure Rate</b>
                </span>
                <span style={{ float: "right" }}>
                  <RiChatDeleteFill size={22} />
                </span>
              </Card.Title>
              <Card.Text>10%</Card.Text>
            </Card.Body>
          </Card> */}
        </Col>
        <Col md={12} sm={12} lg={12}>
          <button
            type="button"
            className="btn btn-sm"
            style={{ float: "right" }}
            onClick={() => {
              // setActiveTab("compose");
              setMethod("sms");
            }}
          >
            <span>
              <FaPencilAlt className="mr-2" />
              Compose New Message
            </span>
          </button>
        </Col>
        <Col md={12} sm={12} lg={12} className="mt-3">
          <Card className="p-3">
            <span>
              <FaPaperPlane size={18} className="mr-2" />
              <b>Recent Sent Messages</b>
            </span>
            <hr />
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Recipient</th>
                  <th scope="col">Contact Number</th>
                  <th scope="col">Message</th>
                  <th scope="col">Sent Date</th>
                </tr>
              </thead>
              <tbody>
                {recentMessageList && recentMessageList?.length > 0 ? (
                  recentMessageList?.map((sms) => (
                    <tr key={sms?.Id_Message}>
                      <td style={{ width: "150px" }} className="text-start">
                        <div className="avatar-name-candidatestableview d-flex align-items-center">
                          <div>
                            <span>
                              <ProfileImage
                                imageSrc={sms?.profileImagePath}
                                imageWidth={"20"}
                                imageHeight={"20"}
                                avatarSize={"30"}
                                avatarRound={"500px"}
                                firstName={sms?.firstName}
                                lastName={sms?.lastName}
                              />
                            </span>
                          </div>
                          <div className="ml-3">
                            <strong className="mb-0 me-2">
                              {sms?.firstName} {sms?.lastName}
                            </strong>
                          </div>
                        </div>
                      </td>
                      <td style={{ width: "150px" }}>{sms?.messageTo}</td>
                      <td>{sms?.messageContent}</td>
                      <td style={{ width: "180px" }}>
                        {moment(sms?.createdDateTime).format(
                          "MM/DD/YYYY hh:mm A"
                        )}
                      </td>
                      {/* <td>{new Date(sms?.createdDateTime).toLocaleString()}</td> */}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={4}
                      className="text-center"
                      style={{
                        color: "grey",
                        border: "1px solid rgb(240 240 240)",
                      }}
                    >
                      <div className="d-flex flex-column justify-content-center align-items-center">
                        <span
                          className="d-flex justify-content-center align-items-center mb-1"
                          style={{
                            width: "70px",
                            height: "70px",
                            border: "1px solid transparent",
                            borderRadius: "50%",
                            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                          }}
                        >
                          <FcAbout size={35} />
                        </span>
                        <span>
                          <b>No Messages!</b>
                        </span>

                        {/* <button
                          type="button"
                          className="btn btn-sm btn-primary mt-2"
                          onClick={() => {
                            setAddRecipientsModal(true);
                            setShowModal(true);
                          }}
                        >
                          <FaUserPlus className="mr-2" /> Find Recipients
                        </button> */}
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default MessageOverview;
