import React, { useState, useEffect, useCallback } from "react";
import NoRecord from "../../../commoncomponents/norecord/NoRecord";
import Loader from "../../../commoncomponents/Loader/Loader";
import { getTalentCommunity } from "../../../../../actions/directsourcing/talentcommunity/TalentCommunity";
import PaginationComponent from "../../../pagination/pagination";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const ExistingSubCommunity = ({
  setPoolId,
  setCreateExisting,
  setCreateExistingSCForm,
  clientId,
  type
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [poolsTotal, setPoolsTotal] = useState(null);
  const [sortArray, setSortArray] = useState([]);
  const [pools, setPools] = useState([]);

  const getPoolFromDB = async (
    currentPage,
    sortArray,
    pageSize,
    searchValue
  ) => {
    const param = {
      currentPage: currentPage,
      sortQuery: sortArray,
      pageSize: pageSize,
      searchText: searchValue ?? searchValue,
      type: type ? type : "",
      clientId: clientId ? clientId : null,
    };
    dispatch(getTalentCommunity(param));
  };

  const { loading, talentPoolData } = useSelector(
    (state) => ({
      loading: state.getTalentCommunityReducer.loading,
      talentPoolData: state.getTalentCommunityReducer.talentPoolData,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (talentPoolData) {
      setPools(talentPoolData.data);
      setPoolsTotal(talentPoolData.total);
    }
  }, [talentPoolData]);

  useEffect(() => {
    getPoolFromDB(currentPage, sortArray, pageSize, searchValue);
  }, []);

  useEffect(() => {
    setPageSize(10);
    setCurrentPage(1);
  }, []);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
    getPoolFromDB(selectedPage, sortArray, pageSize);
  };

  const setJobDetails = (item) => {
    setPoolId(item);
    setCreateExisting(false);
    setCreateExistingSCForm(true);
    // dispatch(template(true));
    // dispatch(jobDetail(item));
    // if (setTemplateSelected) {
    //   setTemplateSelected(true);
    // }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="mt-4">
        <div className="m-3">
          <div className="d-flex align-items-center">
            <h5 className="m-0 ml-2">Choose an existing Community</h5>
          </div>

          <hr className="m-0 mb-3" />
          <div className="m-3">
            {pools?.map((request, i) => (
              <a
                key={i}
                onClick={() => setJobDetails(request?.poolId)}
                className="card card-flat bg-gray4 m-1 p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
              >
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <span className="mr-3">
                      <h6>{request?.poolname} </h6>
                    </span>
                    {request.isHotJob && (
                      <span className="tag tag-coral mr-9">HOT</span>
                    )}
                  </div>
                  <div className="d-flex align-items-center">
                    {/* <span className="d-flex align-items-center me-3">
                      <i className="far fa-suitcase me-2 font-brandBlue"></i>
                      <small className="font-regular font-primary font-gray1">
                        {request.jobType}
                      </small>
                    </span> */}
                    <span className="d-flex align-items-center me-3">
                      <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                      <span className="font-regular font-primary font-gray1">
                        {request?.country}
                      </span>
                    </span>
                  </div>
                </div>
              </a>
            ))}

            {pools && pools?.length > 0 && (
              <PaginationComponent
                currentPage={currentPage}
                totalItems={poolsTotal}
                itemsPerPage={pageSize}
                onPageChange={handlePageChange}
                limit={pageSize}
              />
            )}
            {pools.length === 0 && <NoRecord />}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExistingSubCommunity;
