import { checkEmptyObject } from "../function/CommonFunction";
import Validator from "email-validator";
import { useState } from "react";

export const profileContactInfoValidation = (contactInfo, setContactVal) => {
  let firstNameError = "";
  let lastNameError = "";
  let emailError = "";
  let cityNameError = "";
  let stateNameError = "";
  let CountryNameError = "";
  let addressErr = "";
  var regex = /^[A-Za-z0-9 ]+$/;
  var regexs = /^[A-Z a-z\s]+$/;
  if (contactInfo.firstName.trim().length === 0) {
    firstNameError = "*Required Field";
  } else if (!/^[A-Z a-z\s]+$/.test(contactInfo.firstName)) {
    firstNameError = "Invalid First Name";
  }
  if (contactInfo.firstName.trim() && !regex.test(contactInfo.firstName)) {
    firstNameError = "Special characters not allowed";
  }
  if (contactInfo.lastName.trim().length === 0) {
    lastNameError = "*Required Field";
  } else if (!/^[A-Z a-z\s]+$/.test(contactInfo.lastName)) {
    lastNameError = "Invalid Last Name";
  }
  if (contactInfo.lastName.trim() && !regex.test(contactInfo.lastName)) {
    lastNameError = "Special characters not allowed";
  }
  if (contactInfo.email.trim().length === 0) {
    emailError = "*Required Field";
  } else if (!Validator.validate(contactInfo.email)) {
    emailError = "*Enter Valid Email";
  }

  if (contactInfo.country === null) {
    CountryNameError = "*Required Field";
  } else if (contactInfo.country.trim().length === 0) {
    CountryNameError = "*Required Field";
  } else if (!regexs.test(contactInfo.country)) {
    CountryNameError = "Special characters & numbers not allowed";
  } else if (contactInfo.country.length >= 20) {
    CountryNameError = "Invalid Country Name";
  }
  if (contactInfo.address1?.trim() && !regex.test(contactInfo.address1)) {
    addressErr = "Special characters not allowed";
  }
  const validation = {
    firstName: firstNameError,
    lastName: lastNameError,
    email: emailError,
    country: CountryNameError,
  };
  return validation;
};

export const EmailValidation = (email) => {
  let emailError = "";
  if (!Validator.validate(email)) {
    emailError = "*Enter Valid Email";
  }
  return emailError;
};

export const assignAssessmentValidation = (interviewScheduleData) => {
  let firstNameError = "";
  let lastNameError = "";
  let emailError = "";
  if (interviewScheduleData.firstName.trim().length === 0) {
    firstNameError = "*Required Field";
  }
  if (interviewScheduleData.lastName.trim().length === 0) {
    lastNameError = "*Required Field";
  }

  if (interviewScheduleData.email.trim().length === 0) {
    emailError = "*Required Field";
  } else if (!Validator.validate(interviewScheduleData.email)) {
    emailError = "*Enter Valid Email";
  }

  const validation = {
    firstName: firstNameError,
    fastName: lastNameError,
    email: emailError,
  };
  return validation;
};
