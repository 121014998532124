import React from "react";
import Lottie from "react-lottie";
import animationData from "../../../../assets/lottie/hand-loader.json";

const Loader = ({ text }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div
      className="loaderpopup"
      style={{ backgroundColor: text && "#eeeeee", opacity: text && 0.9 }}
    >
      <Lottie options={defaultOptions} height={200} width={200} />
      {text !== (undefined || null) ? (
        <div className="d-flex justify-content-center">
          <h6 className="text-muted p-1 mb-5">{text}</h6>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Loader;
