import React, { useState } from "react";

const DescriptionDetails = (props) => {
  const { jobData } = props;
  const [showAll, setShowAll] = useState(false);

  const truncatedDescription = jobData.description.length > 300 && !showAll
    ? jobData.description.slice(0, 300) + "..."
    : jobData.description;

  return (
    <div className="mb-3">
      <p className="m-3" dangerouslySetInnerHTML={{ __html: truncatedDescription }}></p>
      {jobData.description.length > 300 && (
        <div style={{ marginLeft: "10px" }}><button onClick={() => setShowAll(!showAll)} type="button" className="btn btn-text">
        {!showAll ? <span>Show More</span> : <span>Show Less</span>}
      </button></div>
      )}
    </div>
  );
};

export default DescriptionDetails;
