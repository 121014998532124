import React, { useState, useEffect } from "react";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import moment from "moment";

function CandidateRequests({ candidateId }) {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRequests();
  }, [candidateId]);

  const getRequests = async () => {
    const res = await post("/talentrequests", {
      candidateId,
    });
    setLoading(false);
    setRequests(res.data.value);
  };

  return (
    <>
      {loading && <Loader />}
      {requests.length === 0 ? (
        <div class="text-center p-3">
          <div class="avatar avatar-lg">
            <i class="fad fa-users-slash" aria-hidden="true"></i>
          </div>
          <div class="mt-2">No records found</div>
        </div>
      ) : (
        <>
          {requests.map((request) => (
            <div className="card card-flat mb-2">
              <div className="card-body">
                <button
                  type="button"
                  className="btn btn-collapsible d-flex align-items-center"
                >
                  <div className="d-flex align-items-center d-block-600">
                    <div className="mr-2">
                      <span>
                        <small className="font-primary">
                          {request.jobTitle
                            .split(" ")
                            .map((i) => i.charAt(0).toUpperCase() + i.substr(1))
                            .join(" ")}
                        </small>
                      </span>
                    </div>
                    <div className="d-none d-block-600 mb-1"></div>
                    <div>
                      <span className="tag tag-blue3 font-white ml-1">
                        {request.statusName === "RTR Rejected"
                          ? "Rejected"
                          : request.statusName}
                      </span>
                      <span className="ml-1">
                        <small>ID: {request.jobId}</small>
                      </span>
                    </div>
                    &nbsp;
                  </div>
                  <div className="ml-auto">
                    {request.updatedDate ? (
                      <small>
                        Last updated:{" "}
                        {moment(request.updatedDate).format("MM/DD/YYYY")}
                      </small>
                    ) : null}
                  </div>
                </button>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}

export default CandidateRequests;
