import React from "react";

const JobDescription = ({ job }) => {
  return (
    <div>
      <div className="main_job_requirements" style={{ marginLeft: "6vw" }}>
        <div className="main_job_requirements_part_1">
          <span>
            <h6>Job Requirement</h6>
          </span>
          <ol
            className="pr-3"
            dangerouslySetInnerHTML={{ __html: job?.jobDescription }}
          ></ol>
        </div>
        <div className="main_job_requirements_part_2">
          <span>
            <h6>Required Skills</h6>
          </span>
          <div className="main_container-key_points mt-2">
            {job?.primarySkills
              ? JSON.parse(job?.primarySkills)?.map((item, i) => (
                  <div className="main_container-key_points_style" key={i}>
                    <span className="main_container-key_points_style_text">
                      {item}
                    </span>
                  </div>
                ))
              : ""}
          </div>
        </div>
        <div className="main_job_requirements_part_3"></div>
        <div className="main_job_requirements_part_4"></div>
      </div>
    </div>
  );
};

export default JobDescription;
