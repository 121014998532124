import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddJobHereMap from "../../location/AddJobHereMap";
import Autosuggest from "react-autosuggest";
import SupplierTable from "../../common/supplier/SupplierTable";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import moment from "moment";
import RichTextEditor from "react-rte";
import { getUserTenant } from "../../../service/AuthService";

function AddJobFullTime(props) {
  let tenantName = getUserTenant();
  let dropdownList = props.dropdownList;
  let date = new Date();
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  const [certificateInput, setCertificateInput] = useState("");
  const [secondarySkillsInput, setSecondarySkillSInput] = useState("");
  const [skillSet, setSkillSet] = useState([]);
  const [education, setEducation] = useState([]);
  const [isRemote, setIsRemote] = useState(false);
  const [isHotJob, setIsHotJob] = useState(false);
  const [isFeePercentage, setIsFeePercentage] = useState(false);
  const [isFexible, setIsFexible] = useState(true);
  const [drugTest, setdrugTest] = useState(true);
  const [backgroundCheck, setBackgroundCheck] = useState(true);
  const [securityClearance, setSecurityClearance] = useState(true);
  const [isUploader, setIsUploader] = useState(true);
  const [tierData, setTierData] = useState({});
  let [numberOfPositions, setNumberOfPositions] = useState(1);
  let [allowedSubmittals, setAllowedSubmittals] = useState(2);
  const [startDate, setStartDate] = useState(new Date());
  const [expiresOn, setExpiresOn] = useState(props.addJobData.expiresOn);
  const visaStatus = [
    "Green Card",
    "H1B",
    "Citizen",
    "H4 EAD",
    "GC EAD",
    "L2EAD",
    "OPT EAD",
    "TN1",
    "TN2",
    "Not Required",
  ];

  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const onChange = (value) => {
    setValue(value);
    props.setAddJobData({
      ...props.addJobData,
      description: value.toString("html"),
    });
  };
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  const handleDate = (e) => {
    props.setAddJobData({
      ...props.addJobData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    props.setAddJobData({
      ...props.addJobData,
      [e.target.name]: e.target.value,
      isHotJob: isHotJob,
      // startDate: moment(startDate).format("DD-MMM-YYYY"),
      // activeFrom: moment(activeFrom).format("DD-MMM-YYYY"),
      // expiresOn: moment(expiresOn).format("DD-MMM-YYYY"),
    });
  };
  const isFlexibleJob = (e) => {
    props.setAddJobData({ ...props.addJobData, isFexible: e.target.checked });
    setIsFexible(e.target.checked);
  };

  const isPercentage = (e) => {
    props.setAddJobData({
      ...props.addJobData,
      isFeePercentage: e.target.checked,
      placementFee: "",
    });
    setIsFeePercentage(e.target.checked);
  };

  const handleStartTime = (e) => {
    const endtime = moment
      .utc(e.target.value, "HH:mm")
      .add(9, "hours")
      .format("HH:mm");
    props.setAddJobData({
      ...props.addJobData,
      workStart: e.target.value,
      workEnd: endtime,
    });
  };

  const handleEndTime = (e) => {
    props.setAddJobData({ ...props.addJobData, workEnd: e.target.value });
  };

  const handlePrefferedDate = (date) => {
    props.setAddJobData({ ...props.addJobData, startDate: date });
    setStartDate(date);
  };

  const handleTravel = (e) => {
    props.setAddJobData({ ...props.addJobData, travel: e.target.value });
  };

  const isDrugTested = (e) => {
    props.setAddJobData({ ...props.addJobData, drugTest: e.target.checked });
    setdrugTest(e.target.checked);
  };

  const isBackgroundChecked = (e) => {
    props.setAddJobData({
      ...props.addJobData,
      backgroundCheck: e.target.checked,
    });
    setBackgroundCheck(e.target.checked);
  };

  const handleSecurity = (e) => {
    props.setAddJobData({
      ...props.addJobData,
      securityClearance: e.target.value,
    });
    setSecurityClearance(e.target.value);
  };

  let certificate_new = [];
  const isCertified = (e) => {
    certificate_new.push(e.target.value);
    props.setAddJobData({
      ...props.addJobData,
      certifications: certificate_new,
    });
  };

  let education_new = [];
  const isEducation = (e) => {
    education_new.push(e.target.value);
    props.setAddJobData({ ...props.addJobData, education: education_new });
  };

  const handleNumberOfPositions = (e) => {
    setNumberOfPositions(e.target.value);
    setAllowedSubmittals(e.target.value * 2);

    props.setAddJobData({
      ...props.addJobData,
      numberOfPositions: e.target.value,
      allowedSubmittals: e.target.value * 2,
    });
  };

  const handleAllowSubmittals = (e) => {
    props.setAddJobData({
      ...props.addJobData,

      allowedSubmittals: e.target.value,
    });
  };

  const handleChangePrimarySkills = (skills) => {
    props.setAddJobData({ ...props.addJobData, primarySkills: skills });
  };

  const handleChangeInputPrimarySkills = (skills) => {
    setPrimarySkillInput(skills);
  };

  const handleChangeSecondarySkills = (skills) => {
    props.setAddJobData({ ...props.addJobData, secondarySkills: skills });
  };

  const handleChangeInputSecondarySkills = (skills) => {
    setSecondarySkillSInput(skills);
  };

  const SupplierChange = (e) => {
    // if supplier is public then set isPublic or else
    let isJobPublic = e.target.value;
    // (isJobPublic === "true") ? (setTableShow(true) ) :)
    if (isJobPublic === "true") {
      props.setAddJobData({
        ...props.addJobData,
        isPublic: true,
      });
    } else {
      props.setAddJobData({
        ...props.addJobData,
        isPublic: false,
      });
    }
  };

  const skillSetOption = dropdownList.skillSet.map((item) => {
    const skillSetObj = { value: item.PositionName, label: item.PositionName };
    return skillSetObj;
  });

  const QualificationType = dropdownList.QualificationType.filter(
    (item) => item.PositionName !== "Select"
  ).map((item) => {
    const QualificationObj = {
      value: item.PositionName,
      label: item.PositionName,
    };
    return QualificationObj;
  });

  const handleSkillSet = (e) => {
    setSkillSet(e);
    props.setAddJobData({
      ...props.addJobData,
      skillSet: e.map((skillSet) => skillSet.value),
    });
  };

  const handleEducation = (e) => {
    setEducation(e);
    props.setAddJobData({
      ...props.addJobData,
      education: e.map((QualificationType) => QualificationType.value),
    });
  };

  const setFileForSending = (file) => {
    const { name } = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      props.setAddJobData({
        ...props.addJobData,
        fileName: name,
        base64: event.target.result,
      });
      // toast.success("File uploaded successfully");
    };
  };

  const onRemove = (e) => {
    props.setAddJobData({
      ...props.addJobData,
      fileName: " ",
      base64: " ",
    });
    toast.success("File removed successfully");
  };

  const onDragDropChangeHandler = (e) => {
    setFileForSending(e[0]);
  };

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 200,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.PositionName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropdownList.skills.filter((item) =>
              item.PositionName.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => (
      <div>{suggestion.PositionName}</div>
    );

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.PositionName);
        }}
        theme={theme}
      />
    );
  };

  const onHotJobSwitch = (e) => {
    e.preventDefault();
    setIsHotJob(e.target.checked);
  };

  useEffect(() => {
    props.setAddJobData({ ...props.addJobData, tierData });
  }, [tierData]);

  const customStyles = {
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#dcedff",
      };
    },
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="text-right mb-1">
            <small>* Denotes Required Field</small>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="title"
                      onChange={handleChange}
                      placeholder="Enter a job title"
                    />
                    <label>Job Title*</label>
                  </div>
                  <small className="validation">
                    {props.addJobFullTimeVal.title}
                  </small>
                </div>
                <div className="col-lg-6">
                  <div className="form-floating form-date">
                    <input
                      type="date"
                      name="startDate"
                      value={moment(props.addJobData.startDate).format(
                        "YYYY-MM-DD"
                      )}
                      onChange={handleChange}
                      className="form-control"
                      min={moment(
                        new Date(date.setDate(date.getDate() + 15))
                      ).format("YYYY-MM-DD")}
                      max="2050-12-31"
                    />
                    <label>Preferred start date*</label>
                    {/* <DatePicker                     
                      name="startDate"
                      className="custom-date-input"
                      placeholder="Enter preferred start date"  
                       onChange={(date) => handleDate('startDate', date)}                  
                    /> */}
                  </div>
                  <small className="validation">
                    {props.addJobFullTimeVal.startDate}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="companyName"
                      defaultValue={tenantName}
                      //disabled
                      onChange={handleChange}
                      placeholder="Enter a Client Name"
                    />
                    <label>Client Name*</label>
                  </div>
                  <small className="validation">
                    {props.addJobFullTimeVal.companyName}
                  </small>
                </div>
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="companyJobId"
                      onChange={handleChange}
                      placeholder="Enter a Client Job Id"
                    />
                    <label>Client JobID</label>
                  </div>
                  {/* <small className="validation">
                    {props.addJobFullTimeVal.companyJobId}
                  </small> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 d-flex align-items-center mb-2 mb-lg-0 justify-content-end justify-content-lg-start">
                  <div className="form-check form-switch d-flex align-items-center">
                    <label
                      className="form-check-label pt-0"
                      htmlFor="remoteSwitch"
                    >
                      Is Hot Job?
                    </label>
                    <input
                      name="isHotJob"
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="hotJobSwitch"
                      onChange={(e) => onHotJobSwitch(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AddJobHereMap
            isRemote={isRemote}
            setIsRemote={setIsRemote}
            addJobData={props.addJobData}
            setAddJobData={props.setAddJobData}
          />

          {!props.addJobData.isRemote && (
            <div className="card card-flat card-borderless bg-gray4 mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        defaultValue={props.addJobData.location.address}
                        placeholder=""
                      />
                      <label>Address</label>
                    </div>
                  </div>
                  <div className="col-lg-2 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        defaultValue={props.addJobData.location.city}
                        placeholder=""
                      />
                      <label>City*</label>
                    </div>
                    <small className="validation">
                      {props.addJobFullTimeVal.city}
                    </small>
                  </div>
                  <div className="col-lg-2 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="state"
                        defaultValue={props.addJobData.location.state}
                        placeholder=""
                      />
                      <label>State*</label>
                    </div>
                    <small className="validation">
                      {props.addJobFullTimeVal.state}
                    </small>
                  </div>
                  <div className="col-lg-2 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="postal"
                        defaultValue={props.addJobData.location.postalCode}
                        placeholder=""
                      />
                      <label>Postal*</label>
                    </div>
                    <small className="validation">
                      {props.addJobFullTimeVal.postalCode}
                    </small>
                  </div>
                  <div className="col-lg-2 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="country"
                        defaultValue={props.addJobData.location.country}
                        placeholder=""
                      />
                      <label>Country*</label>
                    </div>
                    <small className="validation">
                      {props.addJobFullTimeVal.country}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 mb-2 mb-lg-0 d-flex">
                  <div className="form-floating w-50 mr-1">
                    <input
                      name="numberOfPositions"
                      type="number"
                      min="1"
                      className="form-control"
                      value={props.addJobData.numberOfPositions}
                      onChange={handleNumberOfPositions}
                      defaultValue={1}
                    />
                    <label>Total no. of openings</label>
                    <small>This number cannot be zero</small>
                  </div>
                  <div className="form-floating w-50">
                    <input
                      name="allowedSubmittals"
                      type="number"
                      min={2}
                      className="form-control"
                      placeholder="Enter Total Number of Openings"
                      value={props.addJobData.allowedSubmittals}
                      onChange={handleAllowSubmittals}
                    />
                    <label>Allow Submittals</label>
                    {props.addJobFullTimeVal.allowedSubmittals ? (
                      <small className="validation ml-1">
                        {props.addJobFullTimeVal.allowedSubmittals}
                      </small>
                    ) : (
                      <small>This number cannot be zero</small>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 d-flex">
                  <div className="w-50">
                    <div className="form-floating">
                      {/* <span className="input-group-text">
                        {props.addJobData.location.currSymbol}
                      </span> */}
                      <input
                        name="salary"
                        type="number"
                        min="1"
                        className="form-control"
                        aria-label="Amount (to the nearest dollar)"
                        onChange={handleChange}
                        value={props.addJobData.salary}
                      />
                      <label>Max Annual Salary*</label>
                    </div>
                    {/* <small className="">
                    Enter the maximum annual salary you are willing to pay for
                    this full time worker
                    </small> */}
                    <small className="validation">
                      {props.addJobFullTimeVal.salary}
                    </small>
                  </div>
                  <div className="form-floating w-50 ml-1">
                    <select
                      className="form-select"
                      name="salaryCurrency"
                      onChange={handleChange}
                    >
                      {/* <option disabled selected>Select</option> */}
                      {props.dropdownList.Currency.map((currency, index1) => {
                        return (
                          <option
                            key={index1}
                            selected={
                              props.addJobData.salaryCurrency ===
                              currency.PositionName
                            }
                          >
                            {currency.PositionName}
                          </option>
                        );
                      })}
                    </select>
                    <label>Currency*</label>
                    <small className="validation">
                      {props.addJobFullTimeVal.salaryCurrency}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-floating form-date">
                    <input
                      type="date"
                      name="activeFrom"
                      value={moment(
                        new Date(props.addJobData.activeFrom)
                      ).format("YYYY-MM-DD")}
                      onChange={handleChange}
                      className="form-control"
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      max="2050-12-31"
                    />
                    <label>Active from*</label>
                  </div>
                  <small className="validation">
                    {props.addJobFullTimeVal.activeFrom}
                  </small>
                </div>
                <div className="col-lg-6">
                  <div className="form-floating form-date">
                    <input
                      type="date"
                      name="expiresOn"
                      value={moment(
                        new Date(props.addJobData.expiresOn)
                      ).format("YYYY-MM-DD")}
                      onChange={handleChange}
                      className="form-control"
                      min={moment(
                        new Date(new Date().setDate(new Date().getDate() + 1))
                      ).format("YYYY-MM-DD")}
                      max="2050-12-31"
                    />
                    <label>Expires on*</label>
                    {/* <DatePicker     
                      selected={props.addJobData.expiresOn}                
                      name="expiresOn"
                      className="custom-date-input"
                      placeholder="Enter preferred start date"
                      onChange={(date) => handleDate('expiresOn', date)}
                    /> */}
                  </div>
                  <small className="validation">
                    {props.addJobFullTimeVal.expiresOn}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 mb-2 mb-lg-0">
                  <div className="d-flex mb-1">
                    <p className="mb-1">Placement fee*</p>
                    <div className="form-check form-switch d-flex align-items-center ml-auto">
                      <label className="form-check-label pt-0" htmlFor="Switch">
                        Show fee in percentage?
                      </label>
                      <input
                        name="isFeePercentage"
                        className="form-check-input ml-3"
                        type="checkbox"
                        id="Switch"
                        onChange={(e) => isPercentage(e)}
                      />
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="form-floating w-50">
                      <input
                        name="placementFee"
                        type="number"
                        min="1"
                        value={props.addJobData.placementFee}
                        // defaultValue={isFeePercentage && " " || !isFeePercentage && " "}
                        // max={isFeePercentage && "100"}
                        className="form-control"
                        placeholder={
                          isFeePercentage
                            ? "Enter Amount %"
                            : "Enter Amount Fee"
                        }
                        onChange={handleChange}
                      />
                      <label>
                        {" "}
                        {isFeePercentage ? "Percentage %" : "Amount"}
                      </label>
                      <small className="validation">
                        {props.addJobFullTimeVal.placementFee}
                      </small>
                    </div>
                    {!isFeePercentage && (
                      <div className="form-floating w-50 ml-1">
                        <select
                          className="form-select"
                          name="placementCurrency"
                          onChange={handleChange}
                        >
                          {dropdownList.Currency.length > 0 ? (
                            dropdownList.Currency.map((currency, index1) => {
                              return (
                                <option key={index1}>
                                  {currency.PositionName}
                                </option>
                              );
                            })
                          ) : (
                            <option> select </option>
                          )}
                        </select>
                        <label>Currency</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 mb-2 mb-lg-0">
                  <p className="mb-2">Candidate Referral Bonus</p>
                  <div className="d-flex">
                    <div className="form-floating">
                      <input
                        name="referralFee"
                        type="number"
                        min="1"
                        className="form-control"
                        placeholder="Enter Referral Fee"
                        onChange={handleChange}
                      />
                      <label>Amount</label>
                    </div>
                    <div className="form-floating w-50 ml-1">
                      <select
                        className="form-select"
                        name="referralCurrency"
                        onChange={handleChange}
                      >
                        {dropdownList.Currency.length > 0 ? (
                          dropdownList.Currency.map((currency, index1) => {
                            return (
                              <option key={index1}>
                                {currency.PositionName}
                              </option>
                            );
                          })
                        ) : (
                          <option> select </option>
                        )}
                      </select>
                      <label>Currency</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body">
              <div className="form-floating">
                <select
                  className="form-select"
                  onChange={(e) => SupplierChange(e)}
                  name="editPreferred"
                >
                  <option selected={props.addJobData.isPublic} value="true">
                    No release directly to public
                  </option>
                  <option value="false">Yes</option>
                </select>
                <label>Use Preferred Suppliers?</label>
              </div>

              {!props.addJobData.isPublic && (
                <div className="card card-flat">
                  <small className="validation">
                    {props.addJobFullTimeVal.tierError}
                  </small>
                  <div className="card-body">
                    {" "}
                    <SupplierTable
                      addJobData={props.addJobData}
                      setTierData={setTierData}
                    />{" "}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Skills */}

          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="form-floating">
                <div className="form-group">
                  <label>Primary Skills*</label>
                  <TagsInput
                    renderInput={autocompleteRenderInput}
                    value={props.addJobData.primarySkills}
                    onChange={handleChangePrimarySkills}
                    inputValue={primarySkillinput}
                    onChangeInput={handleChangeInputPrimarySkills}
                    placeholder="Type a location and hit enter"
                    inputProps="Type a location and hit enter"
                  />
                </div>
                <small className="validation">
                  {props.addJobFullTimeVal.primarySkills}
                </small>
              </div>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="form-floating">
                <div className="form-group">
                  <label>Secondary Skills</label>
                  <TagsInput
                    renderInput={autocompleteRenderInput}
                    value={props.addJobData.secondarySkills}
                    onChange={handleChangeSecondarySkills}
                    inputValue={secondarySkillsInput}
                    onChangeInput={handleChangeInputSecondarySkills}
                    placeholder="Type a location and hit enter"
                    inputProps="Type a location and hit enter"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3 ">
            <div className="card-body">
              <label> SkillSets</label>
              <div className="form-floating Hover-Dropdown">
                <Select
                  isMulti
                  options={skillSetOption}
                  name="placementSkill"
                  onChange={handleSkillSet}
                  isSearchable={true}
                ></Select>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body pt-0">
              <p className="mb-2">
                <label>
                  Enter the job description or
                  <button
                    type="button"
                    onClick={() => setIsUploader(!isUploader)}
                    className="btn btn-text"
                  >
                    <strong> upload a job description document</strong>
                  </button>
                </label>
              </p>
              <small>
                Accepted File Type: <strong>.docx, .pdf</strong>
              </small>
              {isUploader === true ? (
                <div className="form-floating">
                  <RichTextEditor
                    toolbarConfig={toolbarConfig}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              ) : (
                <div className="form-floating">
                  <Dropzone onDrop={onDragDropChangeHandler}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <div class="talentuploadmaindiv">
                            <div class="talentuploadicon">
                              <label>
                                <i class="fas fa-cloud-upload-alt"></i>
                              </label>
                              <input
                                type="file"
                                name="myfile"
                                {...getInputProps()}
                              />
                            </div>
                            {props.addJobData.fileName ? (
                              <div class="talentuploadtext">
                                {props.addJobData.fileName}
                              </div>
                            ) : (
                              <div class="talentuploadtext">
                                Click or Drag and Drop To Upload
                              </div>
                            )}
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {props.addJobData.fileName && (
                    <div class="modal-footer mt-4 mb-0">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        onClick={onRemove}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              )}
              {isUploader === false && (
                <div className="form-floating">
                  <RichTextEditor
                    toolbarConfig={toolbarConfig}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body">
              <label>Submittal Instructions</label>
              <div className="form-floating">
                <textarea
                  className="form-control addjob-sunmittal"
                  placeholder="Leave submittal instructions"
                  name="Submittal"
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body py-0">
              <div className="form-floating">
                <div className="form-group text-left">
                  <label className="mb-2">Required education</label>
                  <div className="form-floating Hover-Dropdown">
                    <Select
                      isMulti
                      options={QualificationType}
                      // value={props.addJobData.education}
                      name="Qualification"
                      onChange={handleEducation}
                      isSearchable={true}
                    ></Select>
                  </div>
                  {/* <input
                    name="title"
                    type="text"
                    className="form-control"
                    placeholder="Enter required education"
                    onChange={(e) => isEducation(e)}
                    value={props.addJobData.education}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body py-0">
              <div className="form-floating p-1">
                <div className="">
                  <label>Required Certifications</label>
                  <TagsInput
                    value={props.addJobData.certifications}
                    onChange={(c) =>
                      props.setAddJobData({
                        ...props.addJobData,
                        certifications: c,
                      })
                    }
                    inputValue={certificateInput}
                    onChangeInput={(input) => setCertificateInput(input)}
                    inputProps={{ placeholder: "Add Certificate" }}
                    className="form-control m-1"
                    inputClass="w-100"
                  />
                </div>
                <small className="ml-2">
                  Type a Certificate above and hit Enter
                </small>
              </div>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div class="form-floating">
                <select
                  id="visaStatus"
                  name="visaRequirement"
                  class="font-14 form-select"
                  onChange={handleChange}
                >
                  {/* <option value='' selected>Select Status</option> */}
                  {visaStatus.map((item) => (
                    <option
                      selected={item === props.addJobData.visaRequirement}
                      value={item}
                    >
                      {item}
                    </option>
                  ))}
                </select>
                <label>Visa Requirement</label>
              </div>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div class="col-lg-6">
                  <div className="form-floating">
                    <input
                      name="licenceRequirement"
                      type="text"
                      className="form-control"
                      placeholder="Enter Licence"
                      onChange={handleChange}
                      value={props.addJobData.licenceRequirement}
                    />
                    <label className="mb-2">License Requirement</label>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      name="experienceLevel"
                      value={props.addJobData.experienceLevel}
                      onChange={handleChange}
                    >
                      {dropdownList.ExperienceLevel.map((item) => (
                        <option
                          selected={props.addJobData.experienceLevel.includes(
                            item.PositionName
                          )}
                          value={item.PositionName}
                        >
                          {item.PositionName}
                        </option>
                      ))}
                    </select>
                    <label>Required experience</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body pt-0">
              <div className="row">
                <div className="col-lg-12 text-left">
                  <label className="mb-3">Work Schedule</label>
                  <hr className="mt-0 mb-3" />
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-check form-switch pl-0 d-flex align-items-center">
                    <label className="form-check-label pt-0" for="isFlexible">
                      Is Flexible?
                    </label>
                    <input
                      checked={isFexible}
                      name="isFlexible"
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="isFlexible"
                      onChange={(e) => isFlexibleJob(e)}
                    />
                  </div>
                </div>
                <div className="col-lg-4 mb-2 mb-lg-2">
                  <div className="form-floating">
                    {isFexible === false ? (
                      <input
                        type="time"
                        className="form-control"
                        value={props.addJobData.workStart}
                        onChange={(e) => handleStartTime(e)}
                      />
                    ) : (
                      <input
                        type="time"
                        disabled
                        className="form-control"
                        value={props.addJobData.workStart}
                        onChange={(e) => handleStartTime(e)}
                      />
                    )}
                    <label>Work Hour Start</label>
                  </div>
                </div>
                <div className="col-lg-4 mb-2 mb-lg-2">
                  <div className="form-floating">
                    {isFexible === false ? (
                      <input
                        type="time"
                        className="form-control"
                        value={props.addJobData.workEnd}
                        onChange={(e) => handleEndTime(e)}
                      />
                    ) : (
                      <input
                        type="time"
                        disabled
                        className="form-control"
                        value={props.addJobData.workEnd}
                        onChange={(e) => handleEndTime(e)}
                      />
                    )}
                    <label>Work Hour End</label>
                  </div>
                </div>
                <div className="card card-flat  bg-gray4 mb-3">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-lg-12 mb-2 mb-lg-0">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            onChange={(e) => handleTravel(e)}
                          >
                            <option value={props.addJobData.travel}>
                              {props.addJobData.travel}
                            </option>
                            <option value="none">None</option>
                            <option value="15">15%</option>
                            <option value="25">25%</option>
                            <option value="50">50%</option>
                            <option value="75">75%</option>
                            <option value="100">100%</option>
                          </select>
                          <label>Travel</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card card-flat  bg-gray4 mb-3">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-lg-12 my-2 mb-lg-0">
                        <div className="form-check pl-0 form-switch d-flex align-items-center">
                          <label
                            className="form-check-label pt-0"
                            for="dtestrequired"
                          >
                            Drug Test Required?
                          </label>
                          <input
                            checked={drugTest}
                            className="form-check-input ml-3"
                            type="checkbox"
                            id="dtestrequired"
                            onChange={(e) => isDrugTested(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card card-flat  bg-gray4 mb-3">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-lg-12 my-2 mb-lg-0">
                        <div className="form-check pl-0 form-switch d-flex align-items-center">
                          <label
                            className="form-check-label pt-0"
                            for="remoteSwitch"
                          >
                            Background Check Required?
                          </label>
                          <input
                            checked={backgroundCheck}
                            className="form-check-input ml-3"
                            type="checkbox"
                            id="bcheckrequired"
                            onChange={(e) => isBackgroundChecked(e)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card card-flat  bg-gray4 mb-3">
                  <div className="card-body">
                    <div className="row align-items-center">
                      <div className="col-lg-12 mb-2 mb-lg-0">
                        <div className="form-floating">
                          <select
                            className="form-select"
                            onChange={(e) => handleSecurity(e)}
                          >
                            <option value={props.addJobData.securityClearance}>
                              {props.addJobData.securityClearance}
                            </option>
                            <option value="None">None</option>
                            <option value="Require">Require</option>
                            <option value="Confidential">Confidential</option>
                          </select>
                          <label>Security Clearance</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddJobFullTime;
