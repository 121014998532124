import React, { useState } from "react";
import TopNavBar from "../../layout/TopNavBar";
import SideNavBar from "../../layout/SideNavBar";
import ParsingComparison from "./ParsingComparison";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";
const Index = () => {
  const [toggle, setToggle] = useState(true);
  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  return (
    <body
      className={
        toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
      }
    >
      <div className="body-decorator body-decorator-top"></div>
      <div className="body-decorator body-decorator-bottom"></div>
      <TopNavBar isFrom={"ParsingComparison"} handleToggale={toggleDiv} />
      <SideNavBar />
      <div id="Content">
        <div className="section">
          <div className="container">
            {/* <div className="row justify-content-center">
              <div className="col-lg-12">
                <div id="TenantDashboard" className="row mb-3">
                  <div className="col-lg-12">
                    <div className="card"> */}
            <h6 className="mr-3 mb-xl-0">
              <ParsingComparison />
            </h6>
            {/* </div>
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        {getTenantType() == 1 && <Footer />}
      </div>
    </body>
  );
};

export default Index;
