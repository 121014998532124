import React from "react";

function TalentResume(props) {
  let talentData = props.talentData;

  return (
    <>
      <h6 className="mb-4 ml-2">Resume</h6>
      <hr />
      {!talentData?.filePath ?  (
        <h6>No Resumes Found</h6>
      ) : (
        <div>
          <div className={"ml-4 mb-4"}>
            <ul class="list-unstyled mt-2">
              <li class="row ml-2 d-flex align-items-center">
                <strong className="col-lg-8 col-sm-3">
                  {talentData.fileName}
                </strong>
                <br />
                <br />

                <a
                  onClick={(e) => {
                    e.preventDefault();
                    window.open(talentData.filePath, "_blank");
                  }}
                  className="btn btn-sm btn-secondary d-inline-block col-lg-3 col-sm-3"
                >
                  <i className="fal fa-cloud-download-alt mr-2"></i>View
                </a>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
}

export default TalentResume;
