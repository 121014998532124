import React from "react";
import moment from "moment";
const ERTable = ({
  data,
  checkAllSelected,
  selectAll,
  selectOne,
  allSelected,
  apiRecCalled,
  loading,
}) => {
  return (
    <div className="container overflow-auto">
      {" "}
      <div className="row">
        <div className="col-md-12">
          <div
            className="table-responsive report-table"
            style={{ maxHeight: "600px" }}
          >
            <table
              id="reportTable"
              className="table custom-table-style table-hover table-bordered"
            >
              <thead>
                <tr className="report-heading">
                  <th>Select</th>
                  <th>First name</th> <th>Last name</th> <th>Email</th>{" "}
                </tr>
              </thead>
              <tbody>
                {loading && (
                  <tr className="text-center">
                    <td colSpan={9}>
                      <h4>Loading...</h4>
                    </td>
                  </tr>
                )}{" "}
                {!loading && apiRecCalled && data.length === 0 && (
                  <tr className="text-center">
                    <td colSpan={9}>
                      <h4>No Record found.</h4>
                    </td>
                  </tr>
                )}
                {!loading && !apiRecCalled && (
                  <tr className="text-center">
                    <td colSpan={5}>
                      <h6>Select any filters & Search.</h6>
                    </td>
                  </tr>
                )}
                {!loading &&
                  apiRecCalled &&
                  data.length > 0 &&
                  data.map((item, index) => (
                    <tr>
                      <td>
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{ width: "1.5rem", borderRadius: "100" }}
                          checked={item.isSelected}
                          onClick={() => selectOne(item, index)}
                        />
                      </td>{" "}
                      <td>{item.firstName}</td> <td>{item.lastName}</td>{" "}
                      <td>{item.email}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ERTable;
