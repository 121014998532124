import React from "react";
import { Modal } from "react-bootstrap";

const ShareLink = (props) => {
  const {
    PublicShareLinkValue,
    setPublicShareLinkButton,
    sendMailLink,
    handleCopy,
  } = props;
  return (
    <Modal show={PublicShareLinkValue === true} size="delete">
      <div className="bl-modal-header">
        <h6 className="bl-modal-title">{"Public Share Link"}</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setPublicShareLinkButton(false)}
          className="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <Modal.Body>
        <div className="bl-modal-body pt-0">
          <div className="input-group input-group-append mb-1">
            <input
              type="text"
              className="form-control"
              value={sendMailLink}
              disabled
              placeholder="Copy Share Link"
              aria-label="Copy Share Link"
              aria-describedby="button-addon2"
            />
            <button
              className="btn btn-secondary"
              onClick={handleCopy}
              type="button"
              id="button-addon2"
            >
              Copy
            </button>
          </div>
          <div className="text-center animate animate-in">
            <div className="tag d-block tag-green1">Link Copied!</div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ShareLink;
