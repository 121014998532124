import React, { useEffect } from "react";
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ShowSupportQuestions from "./ShowSupportQuestions";
import EditSupportQuestions from "./EditSupportQuestions";
import { get, post } from "../../../service/ApiService";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import TemplateSidePanel from "./TemplateSidePanel";
import AddTemplate from "./AddJobTemplate";
import { getUserRole } from "../../../service/AuthService";
import moment from "moment";
import Pagination from "./TemplatePagination";
import EditJobTemplate from "./EditJobTemplate";
import AddJobNew from "../../modal/addjob/AddJobNew";
import MainAddJob from "../../components_v3/job/addjob/addjobtype/JobDetails";

function Settings() {
  const [isQuestionsModel, setIsQuestionsModel] = useState(false);
  const [isEmailModel, setIsEmailModel] = useState(false);
  const [faq, setFaq] = useState([]);
  const [loading, setLoading] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [FAQData, setFAQData] = useState({});
  const [sidePanel, setSidePanel] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);

  const roleDetails = [
    // { name: "Admin", id: "1" },
    { name: "Candidate", id: "2" },
    { name: "Recruiter", id: "3" },
    { name: "Hiring Manager", id: "4" },
    // {name: "Employee", id: '5'},
    { name: "Super Admin", id: "6" },
    // {name: "Sales Admin", id: '7'},
    { name: "Customer Success Manager", id: "8" },
    { name: "Marketplace Manager", id: "9" },
    { name: "High5 Admin", id: "11" },
  ];

  const [templates, setTemplates] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [category, setCategory] = useState("all");
  const [templateDetails, setTemplateDetails] = useState({});
  const [editTemplate, setEditTemplate] = useState(false);
  const [publicTemplates, setPublicTemplates] = useState(true);
  const [userTemplates, setUserTemplates] = useState(false);

  const fetchFAQ_API = async () => {
    try {
      setLoading(true);
      let res = await get("/support");
      if (res.status === 200) {
        setFaq(
          res.data.map((i) => ({
            ...i,
            ImageURL: i.ImageURL ? JSON.parse(i.ImageURL)[0].ImageURL : "",
          }))
        );
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Error Occured While accessing FAQ's");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing FAQ's");
    }
  };

  const openTemplateSidePanel = (item) => {
    setTemplateDetails(item);
    setSidePanel(true);
  };

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      let res = await get("/job/fetchTemplates");
      if (res.status === 200) {
        setTemplates(
          res.data.sort(
            (a, b) => new Date(b.createdDate) - new Date(a.createdDate)
          )
        );
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Error Occured While accessing Templates");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Some Error Occured While accessing Templates");
    }
  };

  useEffect(() => {
    fetchFAQ_API();
    fetchTemplates();
  }, []);

  const handleSearch = (value) => {
    setSearchValue(value);
    setSearchData(
      templates.filter(
        (item) =>
          item?.title.toLowerCase().includes(value.toLowerCase()) ||
          item?.templateName.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  const addQuestionModel = () => {
    setIsQuestionsModel(true);
  };

  const close = () => {
    setIsQuestionsModel(!isQuestionsModel);
  };

  const addEmailModel = () => {
    setIsEmailModel(!isEmailModel);
  };

  const closeEmailModel = () => {
    setIsEmailModel(!isEmailModel);
  };

  const editFAQ = async () => {
    try {
      setLoading(true);
      let res = await post("/support/updateData");
      if (res.status === 200) {
        setFaq(res.data);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Error Occured While accessing FAQ's");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing FAQ's");
    }
  };

  const deleteFAQ = async (data) => {
    try {
      setLoading(true);
      let res = await post("/support/deleteData", { id: data.ID_support });
      if (res.status === 200) {
        setLoading(false);
        setTimeout(() => {
          fetchFAQ_API();
        }, 4000);
        toast.success("FAQ Deleted Successfully");
      } else {
        setLoading(false);
        toast.error("Error Occured While deleting FAQ's");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing FAQ's");
    }
  };
  useEffect(() => {});
  return (
    <>
      {loading && <Loader />}
      <div className="px-3">
        {/* TEMPLATES */}
        <>
          {true && (
            <div className="d-flex align-items-center justify-content-end mb-4">
              <button
                type="submit"
                className="btn btn-primary btn-sm"
                onClick={() => setTemplateModal(true)}
              >
                <span>
                  <i className="fas fa-fw mr-2 fa-plus " aria-hidden="true"></i>
                  Add Job Template
                </span>
              </button>
            </div>
          )}
          {true && (
            <div>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  style={{ flexDirection: "row-reverse" }}
                >
                  <Typography>
                    <strong>Job Templates</strong>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="row">
                    <div className="col-lg-12">
                      <div class="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end mb-3 border-bottom-gray2">
                        <ul class="nav nav-tabs nav-tabs-tablet pt-1 w-100 justify-content-center">
                          <li class="nav-item">
                            <button
                              type="button"
                              onClick={() => {
                                setPublicTemplates(true);
                                setUserTemplates(false);
                              }}
                              class={
                                publicTemplates
                                  ? "nav-link pb-3 mx-2 bg-transparent active"
                                  : "nav-link pb-3 mx-2 bg-transparent"
                              }
                            >
                              <span class="tag mb-1">
                                {
                                  templates.filter((i) => i.createdBy == 1)
                                    .length
                                }
                              </span>
                              <div>Public Templates</div>
                            </button>
                          </li>
                          <li class="nav-item">
                            <button
                              type="button"
                              onClick={() => {
                                setPublicTemplates(false);
                                setUserTemplates(true);
                              }}
                              class={
                                userTemplates
                                  ? "nav-link pb-3 mx-2 bg-transparent active"
                                  : "nav-link pb-3 mx-2 bg-transparent"
                              }
                            >
                              <span class="tag mb-1">
                                {
                                  templates.filter((i) => i.createdBy != 1)
                                    .length
                                }
                              </span>
                              <div>User Templates</div>
                            </button>
                          </li>
                        </ul>
                      </div>
                      {publicTemplates && (
                        <div className="p-3">
                          <div className="mb-2">
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <select
                                  className="form-select mb-2 font-14"
                                  onChange={(e) => setCategory(e.target.value)}
                                >
                                  <option value="all">All</option>
                                  {[
                                    ...new Set(
                                      templates
                                        ?.filter((item) => item.createdBy == 1)
                                        ?.map((item) => item.category)
                                    ),
                                  ].map((item) => (
                                    <option
                                      selected={item === category}
                                      value={item}
                                    >
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <input
                                  onChange={(e) => handleSearch(e.target.value)}
                                  type="text"
                                  className="form-control small font-14"
                                  placeholder="Search Templates"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {publicTemplates && (
                        <Pagination
                          data={templates.filter((item) =>
                            category === "all"
                              ? true
                              : item.category === category
                          )}
                          pageLimit={5}
                          dataLimit={25}
                          searchData={searchData.filter((item) =>
                            category === "all"
                              ? true
                              : item.category === category
                          )}
                          openSidePanel={(item) => {
                            setTemplateDetails(item);
                            setSidePanel(true);
                          }}
                          category={category}
                          searchValue={searchValue}
                          totalCount={
                            templates.filter((i) => i.createdBy == 1).length
                          }
                          userCount={
                            templates.filter((i) => i.createdBy != 1).length
                          }
                          publicTemplates={publicTemplates}
                        />
                      )}
                      {userTemplates && (
                        <div className="p-3">
                          <div className="mb-2">
                            <div className="d-flex align-items-center justify-content-between flex-wrap">
                              <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <select
                                  className="form-select mb-2 font-14"
                                  onChange={(e) => setCategory(e.target.value)}
                                >
                                  <option value="all">All</option>
                                  {[
                                    ...new Set(
                                      templates
                                        ?.filter((item) => item.createdBy !== 1)
                                        ?.map((item) => item.category)
                                    ),
                                  ].map((item) => (
                                    <option
                                      selected={item === category}
                                      value={item}
                                    >
                                      {item}
                                    </option>
                                  ))}
                                </select>
                              </div>
                              <div className="col-lg-3 col-md-4 col-sm-12 col-12">
                                <input
                                  onChange={(e) => handleSearch(e.target.value)}
                                  type="text"
                                  className="form-control small font-14"
                                  placeholder="Search Templates"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      {userTemplates && (
                        <Pagination
                          data={templates.filter((item) =>
                            category === "all"
                              ? true
                              : item.category === category
                          )}
                          pageLimit={5}
                          dataLimit={25}
                          searchData={searchData.filter((item) =>
                            category === "all"
                              ? true
                              : item.category === category
                          )}
                          openSidePanel={(item) => {
                            setTemplateDetails(item);
                            setSidePanel(true);
                          }}
                          category={category}
                          searchValue={searchValue}
                          totalCount={
                            templates.filter((i) => i.createdBy == 1).length
                          }
                          userCount={
                            templates.filter((i) => i.createdBy != 1).length
                          }
                          publicTemplates={publicTemplates}
                        />
                      )}
                    </div>
                  </div>
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </>

        {/* FAQs */}
        <>
          <div className="d-flex align-items-center justify-content-end mt-5 mb-4">
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              onClick={addQuestionModel}
            >
              <span>
                <i className="fas fa-fw mr-2 fa-plus " aria-hidden="true"></i>
                Add FAQ
              </span>
            </button>
          </div>
          {faq.length !== 0 ? (
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2a-content"
                id="panel2a-header"
                style={{ flexDirection: "row-reverse" }}
              >
                <Typography>
                  <strong>FAQs</strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="row">
                  <div className="col-lg-12">
                    {roleDetails.map((item1) => (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel2a-content"
                          id="panel2a-header"
                          style={{ flexDirection: "row-reverse" }}
                        >
                          <Typography>
                            <p className="flex-item ml-10 mb-0">{item1.name}</p>
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography>
                            {faq
                              .filter((item) => item.fk_role.includes(item1.id))
                              .map((que) => (
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    style={{ flexDirection: "row-reverse" }}
                                  >
                                    <Typography>
                                      <strong className="mb-0">
                                        {que.Title}
                                      </strong>
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Typography>
                                      <div className="row">
                                        <div className="col-lg-10">
                                          <p
                                            dangerouslySetInnerHTML={{
                                              __html: que.Content,
                                            }}
                                          ></p>
                                        </div>
                                        {que.ImageURL && (
                                          <div className="row">
                                            <div className="col-lg-4">
                                              <img
                                                src={que.ImageURL}
                                                alt="Image Not Found"
                                                width="700"
                                                height="350"
                                              />
                                            </div>
                                          </div>
                                        )}
                                        <div className="row">
                                          <div className="col-lg-11"></div>
                                          <div className="col-lg-1 d-flex align-items-end">
                                            <div>
                                              <button
                                                type="button"
                                                className="btn btn-icon"
                                                onClick={() => {
                                                  setFAQData(que);
                                                  setEditModal(true);
                                                }}
                                              >
                                                <i
                                                  className="fal fa-pencil fa-fw"
                                                  aria-hidden="true"
                                                />
                                              </button>
                                            </div>
                                            <div>
                                              <button
                                                type="button"
                                                className="btn btn-icon"
                                                onClick={() => {
                                                  setFAQData(que);
                                                  deleteFAQ(que);
                                                }}
                                              >
                                                <i
                                                  className="fal fa-trash-alt fa-fw ml-3"
                                                  aria-hidden="true"
                                                />
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Typography>
                                  </AccordionDetails>
                                </Accordion>
                              ))}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </div>
                  <div className="col-lg-2"></div>
                </div>
              </AccordionDetails>
            </Accordion>
          ) : (
            !loading && (
              <div className="text-center p-3">
                <div className="avatar avatar-lg">
                  <i className="fad fa-users-slash" />
                </div>
                <div className="mt-2">No FAQ Found</div>
              </div>
            )
          )}
        </>
      </div>

      <Modal show={isQuestionsModel} size="lg">
        <Modal.Header>
          <Modal.Title className="pt-1 font-16">
            <h6>Add Frequently Asked Questions</h6>
          </Modal.Title>
          <button
            type="button"
            onClick={close}
            className="close p-0 bl-modal-close-btn closable"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fal fa-times closable"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <ShowSupportQuestions close={close} fetchFAQ_API={fetchFAQ_API} />
        </Modal.Body>
      </Modal>

      <Modal show={editModal} size="lg">
        <Modal.Header>
          <Modal.Title className="pt-1 font-16">
            <h6>Edit Frequently Asked Questions</h6>
          </Modal.Title>
          <button
            type="button"
            onClick={() => setEditModal(false)}
            className="close p-0 bl-modal-close-btn closable"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fal fa-times closable"></i>
          </button>
        </Modal.Header>
        <Modal.Body>
          <EditSupportQuestions
            close={() => setEditModal(false)}
            fetchFAQ_API={fetchFAQ_API}
            FAQData={FAQData}
          />
        </Modal.Body>
      </Modal>

      <Modal show={false} size="lg">
        <Modal.Header closeButton>
          <h6 class="">
            Add {getUserRole() === "SuperAdmin" ? "Template" : "Job"}
          </h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setTemplateModal(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <AddTemplate
            templates={templates}
            closePanel={() => setTemplateModal(false)}
            fetchTemplates={fetchTemplates}
          />
        </Modal.Body>
        {false && (
          <Modal.Footer>
            <div className="row align-items-center">
              <div className="col-lg-12 my-2 mb-lg-0">
                <div className="form-check pl-0 form-switch d-flex align-items-center">
                  <label className="form-check-label pt-0">
                    <strong>Save as Template?</strong>
                  </label>
                  <input className="form-check-input ml-3" type="checkbox" />
                </div>
              </div>
            </div>
            <Button variant="secondary" onClick={() => setTemplateModal(false)}>
              Close
            </Button>
            <Button variant="primary" onClick={() => setTemplateModal(false)}>
              Save Changes
            </Button>
          </Modal.Footer>
        )}
      </Modal>

      <Modal show={templateModal || editTemplate} size="lg">
        <Modal.Header>
          <h6 class="">{editTemplate ? "Edit" : "Add"} Template</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() =>
              editTemplate ? setEditTemplate(false) : setTemplateModal(false)
            }
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <MainAddJob
            addTemplate={templateModal}
            editTemplate={editTemplate}
            closePanel={() =>
              editTemplate ? setEditTemplate(false) : setTemplateModal(false)
            }
            fetchTemplate={fetchTemplates}
            tempDetails={templateDetails}
          />
        </Modal.Body>
      </Modal>

      {sidePanel && templateDetails && (
        <TemplateSidePanel
          setLoading={setLoading}
          fetchTemplates={fetchTemplates}
          setEditTemplate={setEditTemplate}
          closePanel={() => setSidePanel(false)}
          details={templateDetails}
        />
      )}

      <Modal show={false} size="lg">
        <Modal.Header closeButton>
          <h6 class="">
            Edit {getUserRole() === "SuperAdmin" ? "Template" : "Job"}
          </h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setEditTemplate(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {/* <EditJobTemplate
            templates={templates}
            closePanel={() => setEditTemplate(false)}
            tempDetails={templateDetails}
            fetchTemplates={fetchTemplates}
          /> */}
          <MainAddJob
            addTemplate={templateModal}
            editTemplate={editTemplate}
            templates={templates}
            closePanel={() =>
              editTemplate ? setEditTemplate(false) : setTemplateModal(false)
            }
            fetchTemplate={fetchTemplates}
            tempDetails={templateDetails}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Settings;
