
import {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  GET_RECRUITER_DATA_REQUEST,
  GET_RECRUITER_DATA_SUCCESS,
  GET_RECRUITER_DATA_FAILURE,
  GET_DROPDOWN_LIST_REQUEST,
  GET_DROPDOWN_LIST_SUCCESS,
  GET_DROPDOWN_LIST_FAILURE,
} from "../../actions/updateprofile/actionTypes.js";

const initialState = {
  loading: false,
  profileData: null,
  profileDataUpdate : false,
  recData: null,
  dropDownData: null, 
  error: null,
};

const updateProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PROFILE_REQUEST:
    case UPDATE_PROFILE_REQUEST:
    case GET_RECRUITER_DATA_REQUEST:
    case GET_DROPDOWN_LIST_REQUEST: 
      return { ...state, loading: true, error: null };

    case FETCH_PROFILE_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
      return { ...state, loading: false, profileData: action.payload,profileDataUpdate:true, error: null };

    case GET_RECRUITER_DATA_SUCCESS:
      return { ...state, loading: false, recData: action.payload, error: null };

    case GET_DROPDOWN_LIST_SUCCESS: 
      return { ...state, loading: false, dropDownData: action.payload, error: null };

    case FETCH_PROFILE_FAILURE:
    case UPDATE_PROFILE_FAILURE:
    case GET_RECRUITER_DATA_FAILURE:
    case GET_DROPDOWN_LIST_FAILURE: 
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};

export default updateProfileReducer;
