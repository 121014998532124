import React from "react";

function CandateAssessmentFullView(props) {
  let assessmentDetails = props.assessmentDetails;

  const close = () => {
    props.closePopUP(true);
  };

  return (
    <>
      <div class="view-panel show">
        <div class="view-panel-header">
          <div class="p-relative">
            <div class="px-3 py-2 d-flex">
              <button
                // v-on:click="close"
                onClick={close}
                type="button"
                class="btn btn-icon"
              >
                <i class="fal fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </div>
          <div class="px-2 mb-3">
            <div class="d-flex align-items-start justify-content-between flex-wrap-reverse">
              <div class="d-flex ">
                <div class="ml-2">
                  {(assessmentDetails.difficulty === "1 Way Video" ||
                    assessmentDetails.difficulty === "beginner") && (
                    <span class="mr-2 tag font-bold tag-blue1">
                      {assessmentDetails.difficulty}
                    </span>
                  )}

                  {(assessmentDetails.difficulty === "2 Way Video" ||
                    assessmentDetails.difficulty === "intermediate") && (
                    <span class="mr-2 tag font-bold tag-orange1">
                      {assessmentDetails.difficulty}
                    </span>
                  )}

                  {(assessmentDetails.difficulty === "MCQ" ||
                    assessmentDetails.difficulty === "advanced") && (
                    <span class="mr-2 tag font-bold tag-red1">
                      {assessmentDetails.difficulty}
                    </span>
                  )}

                  {assessmentDetails.difficulty === "expert" && (
                    <span class="mr-2 tag font-bold tag-red1">
                      {assessmentDetails.difficulty}
                    </span>
                  )}

                  {/* <span 
                            v-if="!isAssessment"
                            v-bind:class="{
                                'tag-blue1': item.difficulty === 'beginner',
                                'tag-orange1': item.difficulty === 'intermediate',
                                'tag-red1': item.difficulty === 'advanced',
                                'tag-coral': item.difficulty === 'expert'
                            }"
                            class="mr-2 tag font-bold">
                                {props.assessmentDetails.difficulty}
                        </span> */}

                  <h5 class="mr-2 mt-2 mb-1">{assessmentDetails.title}</h5>
                  <div class="mb-2">
                    <span class="mr-2">
                      Duration: {assessmentDetails.duration} / Last updated:{" "}
                      {assessmentDetails.lastUpdated}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                {assessmentDetails.status !== "completed" && (
                  <a class="btn btn-primary" href="#top">
                    {assessmentDetails.status === "inprogress" && (
                      <span v-if="item.status === 'inprogress'">Resume</span>
                    )}

                    {assessmentDetails.status === "notstarted" && (
                      <span>Start</span>
                    )}
                  </a>
                )}

                {/* <a v-if="isAssessment === true" class="btn btn-primary" href="/candidate/assessments/12321">
                            <span v-if="item.status === 'inprogress'">Resume</span>
                            <span v-if="item.status !== 'inprogress' && item.status !== 'completed'">Start</span>
                        </a> */}
              </div>
            </div>
          </div>
        </div>
        <div class="view-panel-body">
          <div class="mt-2">
            <p class="mb-1">
              <strong>Progress</strong>
            </p>
            {assessmentDetails.status === "inprogress" && (
              <div>
                <small>20% Complete</small>
                <div
                  style={{ position: "revert" }}
                  class="alert-progress alert-progress-static bg-gray3"
                >
                  <div
                    style={{ transform: "translateX(-80%)" }}
                    class="alert-progress-bar"
                  ></div>
                </div>
              </div>
            )}

            {assessmentDetails.status === "completed" && (
              <div>
                <small>100% Complete</small>
                <div
                  style={{ position: "revert" }}
                  class="alert-progress alert-progress-static bg-green1"
                ></div>
              </div>
            )}
            {assessmentDetails.status === "notstarted" && (
              <div>
                <small>Not Started</small>
                <div
                  style={{ position: "revert" }}
                  class="alert-progress alert-progress-static bg-gray3"
                >
                  <div
                    style={{ width: "0%;" }}
                    class="alert-progress-bar bg-green1"
                  ></div>
                </div>
              </div>
            )}
          </div>
          <div class="mt-4">
            <p>
              <strong>Description</strong>
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}
export default CandateAssessmentFullView;
