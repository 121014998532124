import React, { useEffect, useState } from "react";
import { Form, Modal, Button } from "react-bootstrap";
import Select from "react-select";
// import ReactQuill from "react-quill";
// import "../../../../node_modules/react-quill/dist/quill.snow.css";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import { get, post } from "../../../service/ApiService";
import RichTextEditor from "react-rte";
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link", "image", "video"],
    ["clean"],
  ],
};

const ShowSupportQuestions = ({ close, fetchFAQ_API }) => {
  const [body, setBody] = useState("");
  const [question, setQuestion] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [faqInfo, setFaqInfo] = useState({ fileName: "", base64: "" });
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const [previewShow, setPreviewShow] = useState(false);
  const [formData, setFormData] = useState({
    Question: "",
    // Answer: "",
    Roles: [],
  });
  const [selectedImage, setSelectedImage] = useState(null);

  const findFormErrors = () => {
    const { Question, Roles } = formData;
    const newErrors = {};
    if (!Question || Question === "") {
      newErrors.Question = "Question cannot be blank!";
    }

    if (Roles.length === 0) {
      newErrors.Roles = "Roles cannot be blank!";
    }

    return newErrors;
  };
  const handleClose = () => setPreviewShow(false);
  const handleShow = () => setPreviewShow(true);

  const changeContent = (name, e) => {
    setFormData({
      ...formData,
      [name]: e,
    });
  };

  const onRTEChange = (value) => {
    console.log(value.toString("html"));
    setValue(value);
    //  setFormData({
    //     ...formData,
    //     Answer: value.toString('html'),
    //   });
    //props.setAddJobData({...props.addJobData ,  description : value.toString('html')})
  };

  const getData = async () => {
    setLoading(true);
    const response = await get("/support/getRoles");
    if (response.status === 200) {
      setOptions(
        response.data
          .filter((d) => d.rolename !== "Admin")
          .map((role) => ({
            value: role.rolename,
            label:
              role.rolename === "HiringManager"
                ? "Hiring Manager"
                : role.rolename === "SuperAdmin"
                ? "Super Admin"
                : role.rolename === "SalesAdmin"
                ? "Sales Admin"
                : role.rolename === "High5Coordinator"
                ? "Customer Success Manager"
                : role.rolename === "CommunityManager"
                ? "Marketplace Manager"
                : role.rolename === "CustomerSuccess"
                ? "High5 Admin"
                : role.rolename,
            id: role.id_role,
          }))
          .filter((item) => item.value !== "Sales" && item.value !== "Employee")
      );
      setLoading(false);
    } else {
      toast.error("Some Error Occurred. Please Try Again");
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const saveData = async () => {
    const newErrors = findFormErrors();
    const imgID = Math.floor(Math.random() * 650000000) + 300000000;
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      setErrors(newErrors);
      // alert("No Errors Found");
      setLoading(true);
      const params = {
        ...formData,
        fileName: faqInfo.fileName + "_" + imgID,
        base64: faqInfo.base64,
        Answer: value.toString("html"),
      };
      console.log("Support", params);
      const result = await post("/support/saveData", params);
      console.log("data", result);
      if (result.status === 200) {
        toast.success("Question Inserted Successfully");
        fetchFAQ_API();
        setFormData({
          Question: "",
          // Answer:  RichTextEditor.createEmptyValue(),
          Roles: [],
        });
        setValue(RichTextEditor.createEmptyValue());
        close();
        setLoading(false);
      }
    }
  };

  const changeRoles = async (e) => {
    console.log(e);
    setFormData({ ...formData, Roles: e });
  };

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  const onPreviewClick = (name, e) => {
    setPreviewShow(true);
  };

  const setFileForSending = (event) => {
    const file = event.target.files[0];
    console.log(file);
    setSelectedImage(file);
    const { name } = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      setFaqInfo({ fileName: name, base64: event.target.result });
    };
  };

  const handleModalClose = () => setPreviewShow(false);
  useEffect(() => {
    console.log("options", options);
  });

  return (
    <>
      {loading && <Loader />}
      <div className=" pb-5">
        <div class="bl-modal-body">
          <div class="card card-flat card-borderless bg-gray4">
            <div class="card-body">
              <div className="row">
                <div class="col-md-12">
                  <div class="form-floating">
                    <p className="m-0 mb-1">Write the question*</p>
                    <Form.Control
                      type="text"
                      placeholder="Enter Question"
                      value={formData.Question}
                      name="Question"
                      onChange={(e) =>
                        changeContent("Question", e.target.value)
                      }
                    />
                    <small className="validation">{errors.Question}</small>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-md-12">
                  {/* <ReactQuill
                    placeholder="Write Something Amazing"
                    onChange={(e) => changeContent("Answer", e)}
                    modules={modules}
                    value={formData.Answer}
                  /> */}
                  <p className="m-0 mb-1">Answer</p>
                  <RichTextEditor
                    toolbarConfig={toolbarConfig}
                    value={value}
                    onChange={onRTEChange}
                  />
                </div>
                <small className="validation">{errors.Answer}</small>
              </div>
              <div className="row mt-3">
                <div className="col-lg-6">
                  <p>
                    <strong>Do you have a supporting screenshot?</strong>
                  </p>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <label className="p-0 mb-1">
                      <span>Upload here(less than 5 MB)</span>
                      <br />
                      <span>Upload only Image file Type (JPG, PNG).</span>
                    </label>
                    <input
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={setFileForSending}
                      data-max-size="5120"
                    />
                  </div>
                </div>
              </div>

              {selectedImage && (
                <div className="card card-flat bg-gray4 m-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-10"></div>
                      <div className="col-lg-2" style={{ paddingLeft: "50px" }}>
                        <button
                          className="btn btn-secondary p-0 mb-1"
                          style={{ width: "32px" }}
                          onClick={() => {
                            setFaqInfo({ fileName: "", base64: "" });
                            setSelectedImage(null);
                          }}
                        >
                          X
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-8">
                        <img
                          alt="not found"
                          width="650px"
                          height="350px"
                          src={URL.createObjectURL(selectedImage)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="mt-4 col-md-12">
                  <p className="m-0">Who can view this question?*</p>
                  <Select
                    options={options}
                    onChange={(e) => changeRoles(e)}
                    isMulti={true}
                  />
                </div>
                <small className="validation">{errors.Roles}</small>
              </div>
              <div className="d-flex mt-4">
                <button onClick={onPreviewClick}>Preview</button>
                <button onClick={saveData} className="ml-3">
                  Submit
                </button>
                <button onClick={close} className="ml-3">
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Modal show={true} size="lg" animation={true}>

        <Modal.Header>
          <Modal.Title className='pb-0 pt-1 font-16'><h6>FAQ</h6></Modal.Title>
          <button
            type="button"
            onClick={closeSupportModal}
            className="close p-0 bl-modal-close-btn closable"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fal fa-times closable"></i>
          </button>
        </Modal.Header>

        <hr />

        <Modal.Body>
          <div className='pl-5'>
            {
              supportData.Type === 'text' ?
                <div className='m-3 mb-5'>
                  <h4>{supportData.Title} </h4> <br />
                  <div>
                    <h6>Answer: </h6>
                    <div className='pr-3 overflow-auto ml-2' dangerouslySetInnerHTML={{ __html: supportData.Content }}></div>
                  </div>

                  {URL &&
                    <div className='row'>
                      <div className='col-lg-4'>
                        <h6 className='mb-2'>Uploaded Image:</h6>
                        <img src={URL} alt='' width="400" height="300" />
                      </div>
                    </div>}
                </div>
                :
                <div className='m-5'>
                  <h3>Video Coming Soon...</h3>
                </div>
            }
          </div><hr />
          <div className='d-flex m-3'>
            For any other queries, please contact alerts@high5hire.com
            {/* <button>

                    </button> 
       <Button className='ml-auto' variant="secondary" onClick={closeSupportModal}>
              Close
            </Button>
          </div>
        </Modal.Body> 

      {/* <Modal.Footer>
                <div className='d-flex'>
                    For any other queries, please contact alerts@high5hire.com
                    <Button variant="secondary" onClick={closeSupportModal}>
                        Close
                    </Button>
                </div>            
            </Modal.Footer> */}
      {/* </Modal> */}

      <Modal show={previewShow} onHide={handleModalClose} size="xl">
        <Modal.Header closeButton>
          <Modal.Title className="pb-0 pt-1 font-16">
            <h6>Preview</h6>
          </Modal.Title>
        </Modal.Header>
        <hr></hr>
        <Modal.Body>
          <>
            <div className="row pl-5">
              <div className="col-lg-12">
                <h4>{formData.Question}</h4>
                <div className="form-floating mt-2">
                  <div>
                    <h6 className="mt-5">Answer :</h6>
                    <div
                      className="pr-3 overflow-auto ml-2"
                      dangerouslySetInnerHTML={{
                        __html: value.toString("html"),
                      }}
                    ></div>
                  </div>
                  {/* <RichTextEditor
                      toolbarConfig={toolbarConfig}
                      value={value}
                      style={{ fontFamily: "none" }}
                      // onChange={onRTEChange}
                      disabled
                    /> */}
                </div>
                {selectedImage && (
                  <div className="row mt-3 mb-3">
                    <div className="col-lg-4">
                      <h6 className="mb-2">Uploaded Image:</h6>
                      <a href={URL.createObjectURL(selectedImage)}>
                        <img
                          src={URL.createObjectURL(selectedImage)}
                          alt="not found"
                          width="700"
                          height="350"
                        />
                      </a>
                      {/* <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="not found"
                        width="700"
                        height="350"
                      /> */}
                    </div>
                  </div>
                )}

                {/* {selectedImage && (
                <div className="card card-flat bg-gray4 mt-3 mb-3">
                  <div className="card-body">
                    <div className="col-lg-4 mt-2">
                      <p className="m-0">Image</p>
                      <img alt="not found" width="700px" height="350px" src={URL.createObjectURL(selectedImage)} />
                    </div>
                  </div>
                </div>
              )} */}
                {/* <div className="col-lg-10">
                    <p className='mb-0 mt-3'>Roles:</p>
                    <medium>{formData.Roles.map((item) => item.value).join(",")}</medium>
                  </div> */}
              </div>
            </div>
            <hr className="p-0"></hr>
            <div className="d-flex m-3">
              <Button
                className="ml-auto"
                variant="secondary"
                onClick={handleClose}
              >
                Close
              </Button>
            </div>
          </>
        </Modal.Body>
        {/* <Modal.Footer>
          <div className='row'>
            <div className="col-lg-9">
              For any other queries, please contact alerts@high5hire.com
            </div>
            <div className="col-lg-3">
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </div>
          </div>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ShowSupportQuestions;
