import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../../../assets/images/Logo.png";
import poweredByLogo from "../../../../../assets/images/High5Logo.png";

const Footer = ({ FOOTER_HEIGHT }) => {
  const footerStyle = {
    backgroundColor: "#001a38",
    height: FOOTER_HEIGHT,
    color: "#fff",
    padding: "10px 0",
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  };

  const logoStyle = {
    color: "#fff",
    marginRight: "10px",
    maxWidth: "50px", // Adjust the value as needed
  };

  const linkContainerStyle = {
    textAlign: "center",
    flex: "1",
  };

  const linkStyle = {
    color: "#fff",
    textDecoration: "none",
    margin: "0 10px",
  };

  // const poweredByContainerStyle = {
  //   position: "absolute",
  //   top: `460px`, // Adjust the value to bring it higher or lower
  //   left: "50%",
  //   transform: "translateX(-50%)",
  //   display: "flex",
  //   alignItems: "center",
  // };

  return (
    <div>
      <div style={footerStyle}>
        <div>
          <img src={logo} alt="Logo" style={logoStyle} />
        </div>
        <div style={linkContainerStyle}>
          <Link to="/" style={linkStyle}>
            Copyright © 2024 High5Hire
          </Link>
          <span style={{ color: "#fff" }}>|</span>
          <Link to="/" style={linkStyle}>
            Privacy Policy
          </Link>
          <span style={{ color: "#fff" }}>|</span>
          <Link to="/" style={linkStyle}>
            Terms of Service
          </Link>
          <span style={{ color: "#fff" }}>|</span>
          <Link to="/" style={linkStyle}>
            Cookie Preference
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Footer;
