import React, { useState } from "react";

const DescriptionDetails = (props) => {
  const { jobData } = props;
  const [showAll, setShowAll] = useState(false);
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = jobData.jobDescription;
  const textWithoutHtml = tempDiv.innerText;

  const words = textWithoutHtml.split(" ");
  const truncatedWords = showAll ? words : words.slice(0, 37);
  const truncatedDescription = truncatedWords.join(" ");
  const isTruncated = words.length > 37;

  return (
    <div className="mb-3">
      <p className="m-3">
        {truncatedDescription}
        {isTruncated && !showAll ? (
          <>
            <span>...</span>{" "}
            <button
              onClick={() => setShowAll(true)}
              type="button"
              className="btn btn-text"
            >
              Show More
            </button>
          </>
        ) : (
          isTruncated && (
            <>
              {" "}
              <button
                onClick={() => setShowAll(false)}
                type="button"
                className="btn btn-text"
              >
                Show Less
              </button>
            </>
          )
        )}
      </p>
    </div>
  );
};

export default DescriptionDetails;
