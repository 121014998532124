import React, { useState } from "react"
import { ButtonLoader } from '../../../constants/Constants'
import { toast } from 'react-toastify';
import { post } from '../../../service/ApiService';
import { getCandidateId } from "../../../service/AuthService";
import moment from "moment";

function License(props) {
  const [ readOnlyMode, setReadOnlyMode ] = useState(true)
  const [ submittingForm, setSubmittingFrom ] = useState(false)
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false)
  const [ saveClicked, setSaveClicked ] = useState(true)
  const [ licences, setLicences ] = useState(props.license)
  const [ tempLicences, setTempLicences ] = useState(props.license)

  const addLicences = () => {
    let l = [...licences]
    l.push({
      licenseName: "",
      license: "",
      state: "NA",
      startDate: "",
      expiryDate: ""
    })
    setLicences(l)
  }

  const deleteLicences = (index) => {
    let l = [...licences]
    if(l.length === 1) {
      toast.error("Atleast One Licence is Mandatory")
    }
    else {
      l.splice(index, 1)
      setLicences(l)
    }
  }

  const editLicenseInfo = () => {
    setReadOnlyMode(false)
    setSaveClicked(true)
  }

  const cancelLicenseInfo = () => {
    setLicences(tempLicences)
    setReadOnlyMode(true)
    setShowSuccessMessage(false)
    setSubmittingFrom(false)
    setSaveClicked(true)
  }

  const saveLicenseInfo = async () => {
    let condition = true
    console.log(licences)
    licences.forEach(licence => {
      if(Object.values(licence).findIndex(i => i === "") !== -1) {
        condition = false
        return false
      } 
    })
    if(!condition) {
      toast.error("All Fields are Mandatory")
    }
    else {
      setSaveClicked(false)
      setSubmittingFrom(true)
      try {
        const parameters = {
            candidateID: getCandidateId(),
            clickName: 'licenses',
            userLicensesInfo: licences
          }
          const res = await post('/candidateProfile/postLicenseData', parameters)
          if (res.status === 200) {
            setTempLicences(licences)
            setSubmittingFrom(false)
            setShowSuccessMessage(true)      
            setTimeout(() => { 
              setReadOnlyMode(true)
              setShowSuccessMessage(false)
            }, 1000)
          }
      } catch (error) {
          toast.error("Error Occured. Please Try Again")
      }
    }
  }

  const handleChange = (name, value, index) => {
    let l = [...licences]
    l[index] = { ...l[index], [name]: value }
    setLicences(l)
  }

  return (
    <>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Licenses</h6>
            {readOnlyMode === true ? (
              <button
                type="button"
                class="btn btn-text"
                onClick={editLicenseInfo}
              >
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={cancelLicenseInfo}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={saveLicenseInfo}
                  class="btn btn-sm btn-primary"
                >
                  {saveClicked && <span>Save Changes</span>}

                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img width="20px" src={ButtonLoader} alt='' />
                    </span>
                  )}
                  {showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          <hr />
          {readOnlyMode ? (
            <div>
              {
                licences.map(e =>
                  <div class="d-flex">
                    <div class="mr-3 d-flex flex-column align-items-center">
                      <i class="far fa-id-badge fa-fw mt-1" aria-hidden="true"></i>
                    </div>
                    <div>
                      <p class="mb-1">
                        <strong>{e.licenseName}</strong>
                      </p>
                      <p>
                        <small>License No: {e.license}</small>
                      </p>
                      <p>
                        <small>Start Date: {moment(e.startDate).format("MM/DD/YYYY")}</small> | <small>Expiry Date: {moment(e.expiryDate).format("MM/DD/YYYY")}</small>
                      </p>
                    </div>
                  </div>
                )}
            </div>
          ) : (
            <div class="licenses">
              { licences.map((license, index) => 
              <div class="d-flex">
                <div class="mr-3">
                  <div class="avatar avatar-sm">{index+1}</div>
                </div>
                <div class="flex-grow-1">
                  <div class="row mb-2">
                    <div class="col-lg-6">
                      <div class="form-floating">
                        <input 
                          type="text" 
                          class="form-control" 
                          placeholder="License Name" 
                          name="licenseName"
                          value={license.licenseName}
                          onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                        />
                        <label>License Name*</label>
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-floating">
                        <input 
                          type="text" 
                          class="form-control" 
                          placeholder="License #" 
                          name="license"
                          value={license.license}
                          onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                        />
                        <label>License #*</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-6">
                      <div class="form-floating form-date">
                        <label>Start Date*</label>
                        <input 
                          type="date" 
                          class="form-control date" 
                          placeholder="Expiry Date" 
                          name="startDate"
                          value={license.startDate}
                          max={moment(new Date()).format('YYYY-MM-DD')}
                          onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                        />
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="form-floating form-date">
                        <label>Expiry Date*</label>
                        <input 
                          type="date" 
                          class="form-control date" 
                          placeholder="Expiry Date" 
                          name="expiryDate"
                          value={license.expiryDate}
                          disabled={!license.startDate}
                          min={moment(new Date(license.startDate)).format('YYYY-MM-DD')}
                          onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="ml-3">
                  <button type="button" class="btn btn-icon" onClick={() => deleteLicences(index)}>
                    <i class="fal fa-fw fa-trash-alt"></i>
                  </button>
                </div>
              </div>)}
              <div className='ml-5'>
                <button
                  type="button"
                  class="btn btn-sm btn-text"
                  onClick={addLicences}  
                >
                  <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                  <strong>Add License</strong>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default License
