import React, { useEffect, useState } from "react";
import { ButtonLoader } from "../../../../constants/Constants";
import { getTalentRecruiterList } from "../../../../actions/talent/TalentRecruiterFilter";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Select from "react-select";
import Loader from "../../../Loader/Loader";

const SearchFilter = ({
  handleFilterToggle,
  handleAPICalls,
  filterEnabled,
  filterName,
  filterStatus,
  selectedData,
  setSelectedData,
  applyFilter,
  isLoading,
}) => {
  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
  };
  const dispatch = useDispatch();
  const { recruiterData } = useSelector(
    (state) => ({
      recruiterData: state.recruiterFilterData.recruiterData,
    }),
    shallowEqual
  );
  const [apiLoading, setApiLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [search, setSearch] = useState(false);
  const [limit, setLimit] = useState(5);
  const fetchData = (currentPage, limit, searchText) => {
    const payload = {
      currentPage: currentPage,
      limit: limit,
      searchText: searchText,
    };
    dispatch(getTalentRecruiterList(payload));
  };
  const handleChange = (val) => {
    setSelectedData({
      ...selectedData,
      [filterStatus]: filterStatus === "recruiterName" ? [val] : val,
    });
    applyFilter(val, filterStatus);
  };
  useEffect(() => {
    if (recruiterData !== null && recruiterData !== undefined) {
      const formattedOptions = recruiterData?.recruiterData?.map((option) => ({
        label: option, // Adjust the property names based on your data structure
        value: option, // Adjust the property names based on your data structure
      }));
      setOptions(formattedOptions);
      setApiLoading(false);
    }
  }, [recruiterData]);

  useEffect(() => {
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchText.length > 0) {
        setApiLoading(true);
        fetchData(1, limit, searchText);
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchText]);

  const handleChangeInput = (e) => {
    setSearch(true);
    setSearchText(e);
  };

  return (
    <>
      {apiLoading && <Loader />}
      <div className="mb-2">
        <button
          type="button"
          className="btn btn-collapsible"
          onClick={() => handleFilterToggle(filterStatus)}
        >
          <i
            className={
              filterEnabled
                ? "fal fa-angle-up fa-fw"
                : "fal fa-angle-down fa-fw"
            }
          />
          <span className="p-relative">
            <small>{filterName}</small>
            {(Array.isArray(selectedData[filterStatus])
              ? selectedData[filterStatus].length > 0
              : selectedData[filterStatus] !== "") && (
              <span className="jewel"></span>
            )}
          </span>
        </button>
        {filterEnabled && (
          <div className="d-flex flex-column gap-2 p-2">
            <div className="form-floating">
              <Select
                options={isLoading ? [] : options}
                isSearchable={true}
                placeholder={filterName}
                styles={styleSheet}
                onChange={handleChange}
                isMulti={false}
                value={selectedData[filterStatus]}
                onInputChange={handleChangeInput}
                isLoading={isLoading}
                onMenuOpen={() => {
                  // Trigger your API call here
                  handleAPICalls(filterStatus);
                }}
              />
            </div>

            <div class="d-flex justify-content-end mt-2 px-1">
              <button
                disabled={selectedData[filterStatus].length === 0}
                className="btn btn-text"
                onClick={() => {
                  setSelectedData({
                    ...selectedData,
                    [filterStatus]: [],
                  });
                  applyFilter([], filterStatus);
                }}
              >
                <span className="font-12">
                  <small>Clear Recruiter Name </small>
                </span>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SearchFilter;
