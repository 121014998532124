import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getJobList } from "../../../../actions/job/JobList";
import { getJobListCount } from "../../../../actions/job/JobListHeaderCounts";
import ListView from "./ListView";
import GridView from "./GridView";
import TableView from "./TableView";
import Loader from "../../../Loader/Loader";
import Sort from "../../commoncomponents/sort/Index";
// import Filter from "../../filter/Index";
import Filter from "../filterTemp/index";
import { jobListSortObject, orderList } from "../../pagedata/PageData";
import JobListHeader from "./JobListHeader";
import { Modal } from "react-bootstrap";
import MainAddJob from "../addjob/index";
import { template, jobDetail } from "../../../../actions/job/AddJob";
import { getUserRole } from "../../../../service/AuthService";
import {
  fetchJobIdFilterData,
  fetchJobLocationFilterData,
  fetchJobTitleFilters,
} from "../../../../actions/jobList/jobList";
import JobFilter from "../../newFilter/jobFilter/Index";

const JobList = (props) => {
  const dispatch = useDispatch();
  const [view, setView] = useState("list");
  const [viewJobType, setViewJobType] = useState("all");
  const [jobData, setJobData] = useState([]);
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [addJob, setAddJob] = useState(false);
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);
  const [filterOpen, setFilterOpen] = useState(false);
  const [successData, setSuccessData] = useState({
    jobId: "",
    position: "",
    startDate: "",
    location: "",
    salary: "",
    skills: "",
  });
  const [selectedData, setSelectedData] = useState({
    jobTitle: [],
    jobLocation: [],
    jobId: [],
    workAuthorization: [],
    hotJob: false,
    workType: [],
  });

  const [filterParams, setFilterParams] = useState(null);
  //new states

  const [jobListCount, setJobListCount] = useState([]);
  const [dataTotal, setDataTotal] = useState(0);
  const filters = [
    "jobTitle",
    "jobLocation",
    "jobId",
    "workAuthorization",
    "hotJob",
    "workType",
  ];

  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );
  const [matches768, setMatches768] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const {
    jobListLoading,
    jobList,
    jobListCounts,
    jobListCountsLoading,
    filtersList,
  } = useSelector(
    (state) => ({
      jobListLoading: state.JobList.jobListLoading,
      jobList: state.JobList.jobList,
      jobListCounts: state.jobListCountReducer.jobListCounts,
      jobListCountsLoading: state.jobListCountReducer.jobListCountsLoading,
      filtersList: state.getJobListReducer.filtersList,
    }),
    shallowEqual
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 1200px)")
      .addEventListener("change", (e) => setMatches(e.matches));

    const data = {
      role: getUserRole(),
      pageSize: 10,
      pageNumber: 1,
      searchText: "",
    };
    // dispatch(fetchJobTitleFilters(data));
    // dispatch(fetchJobIdFilterData(data));
    // dispatch(fetchJobLocationFilterData(data));
  }, []);

  useEffect(() => {
    if (matches) {
      setView("grid");
    } else {
      setView("list");
    }
  }, [matches]);

  useEffect(() => {
    setJobListCount(jobListCounts);
    if (viewJobType === "myjobs") {
      setDataTotal(jobListCounts[0]?.MyJobCounts);
    } else if (viewJobType === "all") {
      setDataTotal(jobListCounts[0]?.AllJobs);
    } else if (viewJobType === "contract") {
      setDataTotal(jobListCounts[0]?.ContractJobs);
    } else if (viewJobType === "expired") {
      setDataTotal(jobListCounts[0]?.ExpiredJobs);
    } else if (viewJobType === "fullTime") {
      setDataTotal(jobListCounts[0]?.FullTimeJobs);
    } else if (viewJobType === "deactivated") {
      setDataTotal(jobListCounts[0]?.DeactivatedJobs);
    } else {
    }
  }, [jobListCounts, viewJobType]);

  useEffect(() => {
    if (getUserRole() === "Recruiter") setViewJobType("myjobs");
    let jobType = getUserRole() === "Recruiter" ? "myjobs" : viewJobType;
    fetchData(currentPage, sortArray, jobType);
    fetchJobCount(currentPage, sortArray, jobType);
  }, []);

  const fetchJobCount = (currentPage, sortArray, viewJobType) => {
    let paramObj = {
      sortObject: sortArray,
      currentPage: currentPage,
      tab: viewJobType === "fullTime" ? "Full Time" : viewJobType,
      filterQuery: filterParams ? filterParams : getFilterParamsNew("", ""),
    };
    dispatch(getJobListCount(paramObj));
  };

  const fetchData = (currentPage, sortArray, viewJobType) => {
    let paramObj = {
      sortObject: sortArray,
      currentPage: currentPage,
      tab: viewJobType === "fullTime" ? "Full Time" : viewJobType,
      filterQuery: filterParams ? filterParams : getFilterParamsNew("", ""),
    };
    dispatch(getJobList(paramObj));
  };

  useEffect(() => {
    const tempArray = jobListSortObject.map((item) => item.value);
    setSorttype(tempArray);
  }, []);

  const applySort = () => {
    fetchData(currentPage, sortArray, viewJobType);
  };

  const handleAddJob = () => {
    setAddJob(true);
  };

  const sortClearAll = () => {
    setSortArray([]);
    fetchData(currentPage, [], viewJobType);
    const tempArray = jobListSortObject.map((item) => item.value);
    setSorttype(tempArray);
    setSortCount(0);
    setSortApplied(false);
  };

  const closeAddJobModal = () => {
    dispatch(template(false));
    dispatch(jobDetail(null));
    setAddJob(false);
  };

  const applyFilter = (val, status) => {
    setCurrentPage(1);
    setFilterOpen(false);
    let paramObj = {
      sortObject: sortArray,
      currentPage: 1,
      tab: viewJobType === "fullTime" ? "Full Time" : viewJobType,
      filterQuery: getFilterParamsNew(val, status),
    };
    dispatch(getJobList(paramObj));
    dispatch(getJobListCount(paramObj));
  };

  const getFilterParamsNew = (val, status) => {
    const filterParam = {
      jobTitle:
        status == "" ? [] : status == "jobTitle" ? val : selectedData.jobTitle,
      address:
        status == ""
          ? []
          : status == "jobLocation"
          ? val
          : selectedData.jobLocation,
      jobId: status == "" ? [] : status == "jobId" ? val : selectedData.jobId,
      visaStatus:
        status == ""
          ? []
          : status == "workAuthorization"
          ? val
          : selectedData.workAuthorization,
      isHotJob:
        status == "" ? false : status == "hotJob" ? val : selectedData.hotJob,
      jobType:
        status == "" ? [] : status == "workType" ? val : selectedData.workType,
    };

    setFilterParams(filterParam);
    return filterParam;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, sortArray, viewJobType);
  };

  const handleFilterRemove = (e, key, item, index) => {
    e.preventDefault();
    const updatedSelectedData = { ...selectedData };
    if (Array.isArray(updatedSelectedData[key])) {
      updatedSelectedData[key] = updatedSelectedData[key].filter((i) => {
        return i.value != item[index].value;
      });
    } else if (typeof updatedSelectedData[key] === "string") {
      updatedSelectedData[key] = "";
    } else if (key === "hotJob") {
      updatedSelectedData[key] = false;
    }
    applyFilter(updatedSelectedData[key], key);
    setSelectedData(updatedSelectedData);
  };

  const tabChange = (type) => {
    setViewJobType(type);
    fetchData(1, sortArray, type);
  };

  const filterElements = Object.entries(selectedData).map(([key, value]) => {
    if (Array.isArray(value))
      return value.map((i, index) => {
        return (
          <span key={i.value} className="tag tag-blue2 p-1 px-2 m-1">
            <span>{i.label ? i.label : i}</span>{" "}
            <a
              href="#"
              className="font-black"
              onClick={(e) => handleFilterRemove(e, key, value, index)}
            >
              <i className="fas fa-times ml-2 "></i>
            </a>
          </span>
        );
      });
    else if (typeof value === "string" && value !== "")
      return (
        <span key={value} className="tag tag-blue2 p-1 px-2 m-1">
          <span>{value}</span>{" "}
          <a
            href="#"
            className="font-black"
            onClick={(e) => handleFilterRemove(e, key, value)}
          >
            <i className="fas fa-times ml-2 "></i>
          </a>
        </span>
      );
    else if (key === "hotJob" && value !== false)
      return (
        <span key={value} className="tag tag-blue2 p-1 px-2 m-1">
          <span>Is Hot Job</span>{" "}
          <a
            href="#"
            className="font-black"
            onClick={(e) => handleFilterRemove(e, key, value)}
          >
            <i className="fas fa-times ml-2 "></i>
          </a>
        </span>
      );
  });

  return (
    <>
      {(jobListLoading || jobListCountsLoading) && <Loader />}
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="card card-flat card-borderless p-4 mb-3">
            <div className="d-flex-column d-lg-flex flex-lg-row align-items-end mb-3 justify-content-between border-bottom-gray2">
              <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                {props.parent == "recruiterdashboard" ? (
                  <h6 className="mr-3 mb-xl-0">
                    Jobs
                    <a href="/high5Hire/requests">
                      <i className="fas fa-external-link-square"></i>
                    </a>
                  </h6>
                ) : (
                  <h6 className="mr-3 mb-xl-0">Jobs</h6>
                )}
                <div className=" d-none d-xl-flex nav nav-tabs justify-content-between">
                  <div className="nav-itemd-flex justify-content-center">
                    <button
                      className={
                        view == "list"
                          ? "nav-link pb-3 bg-transparent active"
                          : "nav-link pb-3 bg-transparent"
                      }
                      type="button"
                      onClick={() => {
                        setView("list");
                      }}
                    >
                      <i className="fas fa-list"></i>
                    </button>
                  </div>
                  {getUserRole() !== "Recruiter" && (
                    <div className="nav-itemd-flex justify-content-center">
                      <button
                        className={
                          view == "grid"
                            ? "nav-link pb-3 bg-transparent active"
                            : "nav-link pb-3 bg-transparent"
                        }
                        type="button"
                        onClick={() => {
                          setView("grid");
                        }}
                      >
                        <i className="fas fa-th-large"></i>
                      </button>
                    </div>
                  )}
                  <div className="nav-itemd-flex justify-content-center">
                    <button
                      type="button"
                      className={
                        view == "table"
                          ? "nav-link pb-3 bg-transparent active"
                          : "nav-link pb-3 bg-transparent"
                      }
                      onClick={() => {
                        setView("table");
                      }}
                    >
                      <i className="fas fa-table"></i>
                    </button>
                  </div>
                </div>
              </div>
              <JobListHeader
                setCurrentPage={setCurrentPage}
                setViewJobType={tabChange}
                viewJobType={viewJobType}
                jobListCount={jobListCount}
              />
              <div className="d-flex mb-2 justify-content-center justify-content-lg-start">
                {/* Sort Code Here */}
                <div class="d-flex justify-content-end justify-content-even-400">
                  <Sort
                    sort={sort}
                    setSort={setSort}
                    sortCount={sortCount}
                    sortData={jobData}
                    orderArray={orderList}
                    sortObjects={jobListSortObject}
                    applySort={applySort}
                    setSortCount={setSortCount}
                    setSortArray={setSortArray}
                    sortArray={sortArray}
                    sortingFor={"Sort by Job Title, Job Type and Location"}
                    currentPage={currentPage}
                    selectedData={selectedData}
                    sortType={sortType}
                    setSorttype={setSorttype}
                    sortApplied={sortApplied}
                    setSortApplied={setSortApplied}
                    sortClearAll={sortClearAll}
                  />
                </div>
                {getUserRole() == "HiringManager" && (
                  <button
                    type="button"
                    className="btn  btn-sm w-auto d-sm-block ml-3"
                    data-toggle="modal"
                    data-target="#NewRequestModal"
                    onClick={() => handleAddJob()}
                  >
                    <i className="fas fa-plus mr-2"></i>
                    <span className="d-none d-sm-inline">New Job</span>
                  </button>
                )}
              </div>
            </div>
            <div className="d-flex d-block-768 flex-row gap-2">
              <div className={!matches768 ? "col-3" : ""}>
                <JobFilter
                  selectedData={selectedData}
                  setSelectedData={setSelectedData}
                  applyFilter={applyFilter}
                />
              </div>
              <div className={!matches768 ? "col-9" : ""}>
                <div className=" d-flex justify-content-between mb-2">
                  <div className="d-block  ">{filterElements} </div>
                </div>
                {view == "list" && (
                  <div className="d-flex gap-2 mt-2">
                    <div className="col-12" style={{ minHeight: "60vh" }}>
                      <ListView
                        jobList={jobList}
                        setJobData={setJobData}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        handlePageChange={handlePageChange}
                        jobListCount={jobListCount}
                        viewJobType={viewJobType}
                        dataTotal={dataTotal}
                      />
                    </div>
                  </div>
                )}
                {view == "grid" && (
                  <div className="d-flex gap-2 mt-2">
                    <div className="col-12" style={{ minHeight: "60vh" }}>
                      <GridView
                        jobList={jobList}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        handlePageChange={handlePageChange}
                        jobListCount={jobListCount}
                        viewJobType={viewJobType}
                        total={dataTotal}
                      />
                    </div>
                  </div>
                )}
                {view == "table" && (
                  <div className="d-flex gap-2 mt-2">
                    <div className="col-12" style={{ minHeight: "60vh" }}>
                      <TableView
                        jobList={jobList}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        handlePageChange={handlePageChange}
                        jobListCount={jobListCount}
                        viewJobType={viewJobType}
                        dataTotal={dataTotal}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={addJob} size="lg">
        <Modal.Header>
          <h6 class="">New Job</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setAddJob(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <MainAddJob
            setSuccessData={setSuccessData}
            setSuccessFormOpen={setSuccessFormOpen}
            closePanel={() => closeAddJobModal()}
          ></MainAddJob>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default JobList;
