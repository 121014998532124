import React, { useEffect, useRef, useState } from "react";
import Chart from "react-apexcharts";
import { get } from "../../../service/ApiService";
import Loader from "../commoncomponents/Loader/Loader";

const Overview = () => {
  const tooltipRef = useRef(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    try {
      setLoading(true);
      get("/talent/getcandidateOverview").then((res) => {
        console.log(res.data);
        setData(res.data);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const [data, setData] = useState({
    totalCount: 0,
    monthlyData: {
      twomonthsAgoCount: 0,
      previousmonthcount: 0,
      thismonthcount: 0,
    },
    countryCounts: {
      Australia: 0,
      India: 0,

      "United States": 0,
    },
  });

  // Map the month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Map the data for charts
  const lineChartData = Object.values(data?.monthlyData);

  // const doughnutChartData = Object.values(data?.categoryData);
  // const doughnutChartLabels = Object.keys(data?.categoryData);

  const pieChartData = Object.values(data?.countryCounts);
  const pieChartLabels = Object.keys(data?.countryCounts);

  // Set actual month names
  const currentDate = new Date();
  const thismonthcount = monthNames[currentDate.getMonth()];
  const previousmonthcount = monthNames[(currentDate.getMonth() - 1 + 12) % 12];
  const twomonthsAgoCount = monthNames[(currentDate.getMonth() - 2 + 12) % 12];

  const sortCountriesByTalentCount = (countryCounts) => {
    const sortedCountries = Object.entries(countryCounts).sort(
      (a, b) => b[1] - a[1]
    );
    return sortedCountries;
  };

  const getTopCountriesAndOthers = (sortedCountries) => {
    const topCountries = sortedCountries.slice(0, 5);
    const othersCount = sortedCountries
      .slice(5)
      .reduce((acc, cur) => acc + cur[1], 0);
    const topCountryLabels = topCountries.map((country) => country[0]);
    const topCountryData = topCountries.map((country) => country[1]);
    return { topCountryLabels, topCountryData, othersCount };
  };

  const renderPieChart = () => {
    const sortedCountries = sortCountriesByTalentCount(data.countryCounts);
    const {
      topCountryLabels,
      topCountryData,
      othersCount,
    } = getTopCountriesAndOthers(sortedCountries);
    const pieChartLabels = [...topCountryLabels, "Others"];
    const pieChartData = [...topCountryData, othersCount];
    const colors = [
      "#008FFB",
      "#00E396",
      "#FEB019",
      "#FF4560",
      "#775DD0",
      "#F86624",
    ]; // Add more colors if needed

    return (
      <Chart
        options={{
          labels: pieChartLabels,
          colors: colors.slice(0, pieChartLabels.length),
          tooltip: {
            y: {
              formatter: function(val) {
                if (val === othersCount) {
                  // Construct the tooltip content for the "Others" section
                  const otherCountries = sortedCountries
                    .slice(5)
                    .map(([country, count]) => ({
                      country,
                      count,
                    }));
                  const tooltipContent = otherCountries
                    .sort((a, b) => b.count - a.count) // Sort countries by count
                    .map(({ country, count }) => `${country}: ${count}`) // Format each country and count
                    .map((content, index) =>
                      index % 10 === 9 ? `${content},<br />` : `${content}, `
                    )
                    .join("");
                  return `${tooltipContent}`;
                }
                return val;
              },
            },
            position: "bottom",
          },
        }}
        series={pieChartData}
        type="pie"
        width="100%"
        height="300"
        events={{
          tooltipRender: handleTooltipRender, // Call the function to handle tooltip render event
        }}
      />
    );
  };

  // Function to handle tooltip render event
  const handleTooltipRender = () => {
    const tooltipEl = tooltipRef.current;
    if (tooltipEl) {
      tooltipEl.classList.add("custom-tooltip-class"); // Add custom CSS class to the tooltip element
    }
  };
  return (
    <div className="row justify-content-center">
      {loading && <Loader />}

      <div className="col-lg-12">
        <div className="card card-flat card-borderless p-4 mb-3">
          <div className="d-flex-column d-lg-flex flex-lg-row align-items-end mb-3 justify-content-between border-bottom-gray2">
            <div className="d-flex align-items-center justify-content-between w-100">
              <h6 className="mr-3 mb-xl-0">Marketplace Overview</h6>
              <h6 className="mr-3 mb-xl-0">
                <div className="text-center ">
                  <span className="font-weight-bold">Total Talents:</span>{" "}
                  <span className="text-primary font-weight-bold">
                    {data?.totalCount}
                  </span>
                </div>
              </h6>
            </div>
          </div>

          <div className=" mt-4">
            <div className="row">
              {/* Line Chart */}
              <div className="col-lg-6 mt-4 card-flat">
                <div className="card-body">
                  <h6 className="mb-3">Talent Count Over Last 3 Months</h6>
                  <Chart
                    options={{
                      xaxis: {
                        categories: [
                          twomonthsAgoCount,
                          previousmonthcount,
                          thismonthcount,
                        ],
                      },
                    }}
                    series={[{ data: lineChartData }]}
                    type="line"
                    width="100%"
                    height="300"
                  />
                </div>
              </div>

              {/* Doughnut Chart */}
              {/* <div className="col-lg-4 mt-4 card-flat">
                  <div className="card-body">
                    <h6 className="mb-3">Talent Distribution by Category</h6>
                    <Chart
                      options={{ labels: doughnutChartLabels }}
                      series={doughnutChartData}
                      type="donut"
                      width="100%"
                      height="300"
                    />
                  </div>
                </div> */}

              {/* Pie Chart */}
              <div className="col-lg-6 mt-4 card-flat">
                <div className="card-body">
                  <h6 className="mb-3">Talent Distribution by Country</h6>
                  {renderPieChart()}
                  <div className="custom-tooltip" ref={tooltipRef}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
