import React, { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { get, post } from "../../../service/ApiService";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import AssessmentCertificate from "./AssessmentCertificate";
import Loader from "../../Loader/Loader";
//import { response } from "../../../../../Backend/Server";
function CandidateProfileScreenings(props) {
  //console.log(props,"asesm")
  const [oneWay, setOneWay] = useState(false);
  const [twoWay, setTwoWay] = useState(false);
  const [MCQInterview, setMCQInterview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assessmentData, setAssessmentData] = useState({
    mcq: [],
    oneWay: [],
    twoWay: [],
  });
  const [certificate, setCertificate] = useState({});
  const [certificatePopUp, setCertificatePopUp] = useState(false);
  const closeModel = () => {
    setCertificatePopUp(false);
  };
  const setCertificateClick = (data) => {
    setCertificate(data);
    setCertificatePopUp(true);
  };
  const [csAssessmentData, setCsAssessmentData] = useState({ codeSignal: [] });
  const [codeSignal, setCodesignal] = useState(false);
  const [vettingAssessmentData, setVettingAssessmentData] = useState({
    mcq: [],
    oneWay: [],
  });
  const [vetOneWay, setVetOneWay] = useState(false);
  const [vetMCQInterview, setVetMCQInterview] = useState(false);

  const onCollapsibleClick = (val) => {
    if (val === "MCQInterview") {
      setMCQInterview(true);
    }
    if (val === "MCQInterviewReset") {
      setMCQInterview(false);
    }
    if (val === "oneWay") {
      setOneWay(true);
    }
    if (val === "oneWayReset") {
      setOneWay(false);
    }
    if (val === "twoWay") {
      setTwoWay(true);
    }
    if (val === "twoWayReset") {
      setTwoWay(false);
    }
    if (val === "codeSignal") {
      setCodesignal(true);
    }
    if (val === "codeSignalReset") {
      setCodesignal(false);
    }
    if (val === "vetMCQInterview") {
      setVetMCQInterview(true);
    }
    if (val === "vetMCQInterviewReset") {
      setVetMCQInterview(false);
    }
    if (val === "vetOneWay") {
      setVetOneWay(true);
    }
    if (val === "vetOneWayReset") {
      setVetOneWay(false);
    }
  };

  // useEffect(async () => {

  //     try {
  //         setLoading(true)
  //         //    console.log(props.candidateData)
  //         const candidateID = props.candidateData.candidateID
  //         const candidateEmail = props.candidateData.email
  //         const assessmentResult = await get(`/assignAssessment/view/${candidateID}&${candidateEmail}`);
  //         console.log('AAA', assessmentResult)
  //         setLoading(false)
  //         // const tempassessmentData = assessmentData.mcq
  //         setAssessmentData({
  //             ...assessmentData,
  //             mcq: assessmentResult.data.tovuti,
  //             oneWay: assessmentResult.data.oneWay,
  //             twoWay: assessmentResult.data.twoWay
  //         })

  //         console.log(assessmentData)

  //     } catch (error) { toast.error(error) }

  // }, [props.candidateData]);

  //fetch modernhire details
  //fetch code signal details
  useEffect(() => {
    fetchCodeSignalAssessmentDetail();
    fetchModernHireAssessmentDetail();
    fetchVettingAssessmentDetail();
  }, [props.candidateData]);

  const fetchModernHireAssessmentDetail = async () => {
    try {
      setLoading(true);
      const candidateID = props.candidateData.candidateID;
      const candidateEmail = props.candidateData.email;
      const assessmentResult = await get(
        `/assignAssessment/view/${candidateID}&${candidateEmail}`
      );
      console.log("AAA", assessmentResult);
      if (assessmentResult.status === 200) {
        setLoading(false);
        setAssessmentData({
          ...assessmentData,
          mcq: assessmentResult.data.tovuti,
          oneWay: assessmentResult.data.oneWay,
          twoWay: assessmentResult.data.twoWay,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };
  console.log("modernhire", assessmentData);

  const fetchCodeSignalAssessmentDetail = async () => {
    try {
      setLoading(true);
      const candidateEmail = props.candidateData.email;
      const candidateID = props.candidateData.candidateID;
      let payload = {
        email: candidateEmail,
        id: candidateID,
      };
      let response = await post("/codesignal/csViewAssessment", payload);
      console.log("responsefromcodesignal", response);
      if (response.status === 200) {
        setLoading(false);
        setCsAssessmentData({
          ...response,
          codeSignal: response.data,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };
  console.log("codesignal", csAssessmentData);

  const fetchVettingAssessmentDetail = async () => {
    try {
      setLoading(true);
      const candidateEmail = props.candidateData.email;
      const candidateID = props.candidateData.candidateID;
      let payload = {
        email: candidateEmail,
        id: candidateID,
      };
      let response = await post("/view/vettingViewAssessment", payload);
      console.log("TANYA", response);
      if (response.status === 200) {
        setLoading(false);
        setVettingAssessmentData({
          ...vettingAssessmentData,
          mcq: response.data.mcq,
          oneWay: response.data.oneWay,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };
  console.log("vetting", vettingAssessmentData);

  const handleCopy = (eachdata) => {
    navigator.clipboard.writeText(eachdata.ReviewUrl);
    toast.success("Link Copied Successfully!!!");
  };

  const handleVettingCopy = (eachdata) => {
    navigator.clipboard.writeText(eachdata.ReviewURL);
    toast.success("Link Copied Successfully!!!");
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        {/* {assessmentData.mcq.length === 0 && assessmentData.oneWay.length === 0 && assessmentData.twoWay.length === 0 && csAssessmentData.codeSignal.length === 0 &&(<div >
                    <div className="text-center p-3">
                        <div className="avatar avatar-lg">
                            <i className="fad fa-users-slash"></i>
                        </div>
                        <div className="mt-2">
                            No Assessment Found
                        </div>
                    </div>
                </div>)
                }            */}
        {assessmentData.mcq.length !== 0 ||
        assessmentData.oneWay.length !== 0 ||
        assessmentData.twoWay.length !== 0 ||
        csAssessmentData.codeSignal.length !== 0 ||
        vettingAssessmentData.mcq.length !== 0 ||
        vettingAssessmentData.oneWay.length !== 0 ? (
          <div>
            {assessmentData.mcq.length > 0 && (
              <div v-if="mcq">
                <div className="mb-2">
                  <button type="button" className="btn btn-collapsible">
                    {MCQInterview !== true ? (
                      <i
                        v-if="!collapsible.skills"
                        className="fal fa-angle-down fa-fw"
                        onClick={() => onCollapsibleClick("MCQInterview")}
                      ></i>
                    ) : (
                      <i
                        v-else
                        className="fal fa-angle-up fa-fw mt-1"
                        onClick={() => onCollapsibleClick("MCQInterviewReset")}
                      ></i>
                    )}
                    <span>
                      <small>MCQ </small>
                    </span>
                  </button>
                </div>

                <div
                  className={
                    MCQInterview === true
                      ? " card ml-4 mb-4 d-none"
                      : "card ml-4 mb-4"
                  }
                >
                  <div className="card-body">
                    <table className="table table-hoverable">
                      <tbody>
                        {assessmentData.mcq.map((eachdata) => (
                          <tr>
                            {eachdata.status === "Completed" ? (
                              <td>
                                {/* <div className="col-lg-12 d-flex justify-content-between">
                                                            <h6 className="mb-1">{eachdata.title}</h6>
                                                            <button type="button" className="btn btn-sm btn-gray mb-1" onClick={() => setCertificateClick(eachdata)}>Certificate</button>
                                                        </div> */}
                                <div className="col-lg-12 d-flex justify-content-between">
                                  <h6 class="mb-1">{eachdata.title}</h6>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1 ml-5"
                                    onClick={() => handleCopy(eachdata)}
                                  >
                                    <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                                    Share Result
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1"
                                    onClick={() =>
                                      window.open(eachdata.ReviewUrl, "_blank")
                                    }
                                  >
                                    <i className="fal fa-list-alt mr-2" />
                                    View Result
                                  </button>
                                </div>
                                <div class=" py-2 mt-2">
                                  <span class="mr-2 tag font-bold tag-green1">
                                    {" "}
                                    Status : {eachdata.status}
                                  </span>
                                  <span class="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch mr-1"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span class="tag mb-1">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}{" "}
                                  </span>
                                </div>
                              </td>
                            ) : (
                              <td>
                                <h6 class="mb-1">{eachdata.title}</h6>
                                <div class=" py-2 mt-2">
                                  <span class="mr-2 tag font-bold tag-orange1 ">
                                    {" "}
                                    Status : {eachdata.status}
                                  </span>
                                  <span class="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch mr-1"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span class="tag mb-1">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}{" "}
                                  </span>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {assessmentData.oneWay.length > 0 && (
              <div v-if="1way">
                <div className="mb-2">
                  <button type="button" className="btn btn-collapsible">
                    {oneWay !== true ? (
                      <i
                        v-if="!collapsible.skills"
                        className="fal fa-angle-down fa-fw"
                        onClick={() => onCollapsibleClick("oneWay")}
                      ></i>
                    ) : (
                      <i
                        v-else
                        className="fal fa-angle-up fa-fw mt-1"
                        onClick={() => onCollapsibleClick("oneWayReset")}
                      ></i>
                    )}
                    <span>
                      <small>1 Way </small>
                    </span>
                  </button>
                </div>
                <div
                  className={
                    oneWay === true
                      ? " card ml-4 mb-4 d-none"
                      : "card ml-4 mb-4"
                  }
                >
                  <div className="card-body">
                    <table className="table table-hoverable">
                      <tbody>
                        {assessmentData.oneWay.map((eachdata) => (
                          <tr>
                            {eachdata.status === "Completed" ? (
                              <td>
                                <div className="col-lg-12 d-flex justify-content-between">
                                  <h6 class="mb-1">{eachdata.title}</h6>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1 ml-5"
                                    onClick={() => handleCopy(eachdata)}
                                  >
                                    <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                                    Share Result
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1"
                                    onClick={() =>
                                      window.open(eachdata.ReviewUrl, "_blank")
                                    }
                                  >
                                    <i className="fal fa-list-alt mr-2" />
                                    View Result
                                  </button>
                                </div>
                                <div class=" py-2 mt-2">
                                  <span class="mr-2 tag font-bold tag-green1">
                                    {" "}
                                    Status : {eachdata.status}
                                  </span>
                                  <span class="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch mr-1"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span class="tag mb-1 ml-2">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}{" "}
                                  </span>
                                </div>
                              </td>
                            ) : (
                              <td>
                                <h6 class="mb-1">{eachdata.title}</h6>
                                <div class=" py-2 mt-2">
                                  <span class="mr-2 tag font-bold tag-orange1">
                                    {" "}
                                    Status : {eachdata.status}
                                  </span>
                                  <span class="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch mr-1"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span class="tag mb-1 ml-2">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}{" "}
                                  </span>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {assessmentData.twoWay.length > 0 && (
              <div v-if="2way">
                <div className="mb-2">
                  <button type="button" className="btn btn-collapsible">
                    {twoWay !== true ? (
                      <i
                        v-if="!collapsible.skills"
                        className="fal fa-angle-down fa-fw"
                        onClick={() => onCollapsibleClick("twoWay")}
                      ></i>
                    ) : (
                      <i
                        v-else
                        className="fal fa-angle-up fa-fw mt-1"
                        onClick={() => onCollapsibleClick("twoWayReset")}
                      ></i>
                    )}
                    <span>
                      <small>2 Way </small>
                    </span>
                  </button>
                </div>

                <div
                  className={
                    twoWay === true
                      ? " card ml-4 mb-4 d-none"
                      : "card ml-4 mb-4"
                  }
                >
                  <div className="card-body">
                    <table className="table table-hoverable">
                      <tbody>
                        {assessmentData.twoWay.map((eachdata) => (
                          <tr>
                            {eachdata.status === "Completed" ? (
                              <td>
                                <div className="col-lg-12 d-flex justify-content-between">
                                  <h6 class="mb-1">{eachdata.title}</h6>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1 ml-5"
                                    onClick={() => handleCopy(eachdata)}
                                  >
                                    <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                                    Share Result
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1"
                                    onClick={() =>
                                      window.open(eachdata.ReviewUrl, "_blank")
                                    }
                                  >
                                    <i className="fal fa-list-alt mr-2" />
                                    View Result
                                  </button>
                                </div>
                                <div class=" py-2 mt-2">
                                  <span class="mr-1 tag font-bold tag-green1">
                                    {" "}
                                    Status : {eachdata.status}
                                  </span>
                                  <span class="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch mr-1"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span class="tag mb-1">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}{" "}
                                  </span>
                                </div>
                              </td>
                            ) : (
                              <td>
                                <h6 class="mb-1">{eachdata.title}</h6>
                                <div class=" py-2 mt-2">
                                  <span class="mr-1 tag font-bold tag-orange1">
                                    {" "}
                                    Status : {eachdata.status}
                                  </span>
                                  <span class="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch mr-1"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span class="tag mb-1">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}
                                  </span>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {csAssessmentData.codeSignal.length > 0 && (
              <div v-if="codeSignal">
                <div className="mb-2">
                  <button type="button" className="btn btn-collapsible">
                    {codeSignal !== true ? (
                      <i
                        v-if="!collapsible.skills"
                        className="fal fa-angle-down fa-fw"
                        onClick={() => onCollapsibleClick("codeSignal")}
                      ></i>
                    ) : (
                      <i
                        v-else
                        className="fal fa-angle-up fa-fw mt-1"
                        onClick={() => onCollapsibleClick("codeSignalReset")}
                      ></i>
                    )}
                    <span>
                      <small>Coding Test</small>
                    </span>
                  </button>
                </div>

                <div
                  className={
                    codeSignal === true
                      ? " card ml-4 mb-4 d-none"
                      : "card ml-4 mb-4"
                  }
                >
                  <div className="card-body">
                    <table className="table table-hoverable">
                      <tbody>
                        {csAssessmentData.data.map((eachdata) => (
                          <tr>
                            {eachdata.status === "Completed" ? (
                              <td>
                                <div className="col-lg-12 d-flex justify-content-between">
                                  <h6 class="mb-1">{eachdata.title} </h6>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1 ml-5"
                                    onClick={() => handleCopy(eachdata)}
                                  >
                                    <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                                    Share Result
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1"
                                    onClick={() =>
                                      window.open(eachdata.ReviewUrl, "_blank")
                                    }
                                  >
                                    <i className="fal fa-list-alt mr-2" />
                                    View Result
                                  </button>
                                </div>
                                <span className="tag mb-1 mr-1">
                                  Score : {eachdata.AssessmentScore} /{" "}
                                  {eachdata.AssessmentMaxScore}
                                </span>
                                <div className="py-2 mt-2">
                                  <span className="mr-2 tag font-bold tag-green1">
                                    {" "}
                                    Status : {eachdata.status}
                                  </span>
                                  <span className="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span className="tag mb-1">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}
                                  </span>
                                </div>
                              </td>
                            ) : (
                              <td>
                                <h6 className="mb-1">{eachdata.title}</h6>
                                <div className=" py-2 mt-2">
                                  <span className="mr-2 tag font-bold tag-orange1">
                                    {" "}
                                    Status : {eachdata.status}
                                  </span>
                                  <span className="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch mr-1"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span className="tag mb-1">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}
                                  </span>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {vettingAssessmentData.mcq.length > 0 && (
              <div v-if="mcq">
                <div className="mb-2">
                  <button type="button" className="btn btn-collapsible">
                    {vetMCQInterview !== true ? (
                      <i
                        v-if="!collapsible.skills"
                        className="fal fa-angle-down fa-fw"
                        onClick={() => onCollapsibleClick("vetMCQInterview")}
                      ></i>
                    ) : (
                      <i
                        v-else
                        className="fal fa-angle-up fa-fw mt-1"
                        onClick={() =>
                          onCollapsibleClick("vetMCQInterviewReset")
                        }
                      ></i>
                    )}
                    <span>
                      <small>MCQ </small>
                    </span>
                  </button>
                </div>

                <div
                  className={
                    vetMCQInterview === true
                      ? " card ml-4 mb-4 d-none"
                      : "card ml-4 mb-4"
                  }
                >
                  <div className="card-body">
                    <table className="table table-hoverable">
                      <tbody>
                        {vettingAssessmentData.mcq.map((eachdata) => (
                          <tr>
                            {eachdata.Status === "Completed" ? (
                              <td>
                                <div className="col-lg-12 d-flex justify-content-between">
                                  <h6 class="mb-1">{eachdata.title}</h6>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1 ml-5"
                                    onClick={() => handleVettingCopy(eachdata)}
                                  >
                                    <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                                    Share Result
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1"
                                    onClick={() =>
                                      window.open(eachdata.ReviewURL, "_blank")
                                    }
                                  >
                                    <i className="fal fa-list-alt mr-2" />
                                    View Result
                                  </button>
                                </div>
                                <div class=" py-2 mt-2">
                                  <span class="mr-2 tag font-bold tag-green1">
                                    {" "}
                                    Status : {eachdata.Status}
                                  </span>
                                  <span class="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch mr-1"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span class="tag mb-1">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}{" "}
                                  </span>
                                </div>
                              </td>
                            ) : (
                              <td>
                                <h6 class="mb-1">{eachdata.title}</h6>
                                <div class=" py-2 mt-2">
                                  <span class="mr-2 tag font-bold tag-orange1 ">
                                    {" "}
                                    Status : {eachdata.Status}
                                  </span>
                                  <span class="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch mr-1"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span class="tag mb-1">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}{" "}
                                  </span>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
            {vettingAssessmentData.oneWay.length > 0 && (
              <div v-if="1way">
                <div className="mb-2">
                  <button type="button" className="btn btn-collapsible">
                    {vetOneWay !== true ? (
                      <i
                        v-if="!collapsible.skills"
                        className="fal fa-angle-down fa-fw"
                        onClick={() => onCollapsibleClick("vetOneWay")}
                      ></i>
                    ) : (
                      <i
                        v-else
                        className="fal fa-angle-up fa-fw mt-1"
                        onClick={() => onCollapsibleClick("vetOneWayReset")}
                      ></i>
                    )}
                    <span>
                      <small>1 Way </small>
                    </span>
                  </button>
                </div>
                <div
                  className={
                    vetOneWay === true
                      ? " card ml-4 mb-4 d-none"
                      : "card ml-4 mb-4"
                  }
                >
                  <div className="card-body">
                    <table className="table table-hoverable">
                      <tbody>
                        {vettingAssessmentData.oneWay.map((eachdata) => (
                          <tr>
                            {eachdata.Status === "Completed" ? (
                              <td>
                                <div className="col-lg-12 d-flex justify-content-between">
                                  <h6 class="mb-1">{eachdata.title}</h6>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1 ml-5"
                                    onClick={() => handleVettingCopy(eachdata)}
                                  >
                                    <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                                    Share Result
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-gray mb-1"
                                    onClick={() =>
                                      window.open(eachdata.ReviewURL, "_blank")
                                    }
                                  >
                                    <i className="fal fa-list-alt mr-2" />
                                    View Result
                                  </button>
                                </div>
                                <div class=" py-2 mt-2">
                                  {/* {eachdata.Status === "Completed" ? (
                                    <span class="mr-2 tag font-bold tag-green1">
                                      {" "}
                                      Status : {eachdata.Status}
                                    </span>
                                  ) : (
                                    <span class="mr-2 tag font-bold tag-yellow">
                                      {" "}
                                      Status : {eachdata.Status}
                                    </span>
                                  )} */}
                                  <span
                                    class={
                                      eachdata?.isReviewed == "true"
                                        ? "mr-2 tag font-bold tag-green1 mb-2"
                                        : eachdata?.testCompleted == "true"
                                        ? "mr-2 tag font-bold tag-lightBlue mb-2"
                                        : "mr-2 tag font-bold tag-orange1 mb-2"
                                    }
                                  >
                                    Status :{" "}
                                    {eachdata?.isReviewed == "true"
                                      ? "Completed"
                                      : eachdata?.testCompleted == "true"
                                      ? "Under Review"
                                      : "Invited"}
                                  </span>
                                  <span class="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch mr-1"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span class="tag mb-1 ml-2">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}{" "}
                                  </span>
                                </div>
                              </td>
                            ) : (
                              <td>
                                <h6 class="mb-1">{eachdata.title}</h6>
                                <div class=" py-2 mt-2">
                                  {/* <span class="mr-2 tag font-bold tag-orange1">
                                    {" "}
                                    Status : {eachdata.Status}
                                  </span> */}
                                  <span
                                    class={
                                      eachdata?.isReviewed == "true"
                                        ? "mr-2 tag font-bold tag-green1 mb-2"
                                        : eachdata?.testCompleted == "true"
                                        ? "mr-2 tag font-bold tag-lightBlue mb-2"
                                        : "mr-2 tag font-bold tag-orange1 mb-2"
                                    }
                                  >
                                    Status :{" "}
                                    {eachdata?.isReviewed == "true"
                                      ? "Completed"
                                      : eachdata?.testCompleted == "true"
                                      ? "Under Review"
                                      : "Invited"}
                                  </span>
                                  <span class="tag mb-1 mr-1">
                                    <i
                                      aria-hidden="true"
                                      class="far fa-fw fa-stopwatch mr-1"
                                    ></i>
                                    Assigned By :{" "}
                                    {localStorage.getItem("tenant")}{" "}
                                  </span>
                                  <span class="tag mb-1 ml-2">
                                    <i
                                      aria-hidden="true"
                                      class="fal fa-fw fa-clock mr-1"
                                    ></i>{" "}
                                    Assigned:{" "}
                                    {eachdata.recordedTime
                                      ? moment(eachdata.recordedTime).format(
                                          "MM/DD/YYYY"
                                        )
                                      : "N/A"}{" "}
                                  </span>
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <div className="text-center p-3">
              <div className="avatar avatar-lg">
                <i className="fad fa-users-slash"></i>
              </div>
              <div className="mt-2">No Assessment Found</div>
            </div>
          </div>
        )}

        <Modal size="lg" show={certificatePopUp} onHide={closeModel}>
          <div className="bl-modal-header">
            <h6 className="bl-modal-title">{"Certificate"}</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModel}
              className="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            <AssessmentCertificate
              handleSuccess={closeModel}
              candidateData={props.candidateData}
              certificate={certificate}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default CandidateProfileScreenings;
