import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { fetchAllBroadcastTemplates } from "../../../../../../actions/broadcast/getBroadCastTemplates";

import ReactQuill from "react-quill";
import Loader from "../../../../commoncomponents/Loader/Loader";
import Tabs from "../Tabs";
import ModalView from "../../../../commoncomponents/modal/Modal";
import RecipientsTable from "../RecipientsTable";
import EmailTemplates from "./EmailTemplates";
import SentMails from "./SentMails";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { FaUserPlus } from "react-icons/fa";
import AddRecipients from "../Recipients/AddRecipients";
import { FetchLastMailSentByUser } from "../../../../../../actions/communication/Communication";
import moment from "moment";

const Email = ({
  selectedTalents,
  setSelectedTalents,
  emailContent,
  setEmailContent,
  setErrorData,
  errorData,
  handleConfirmation,
  previewEmail,
  setPreviewEmail,
  isFrom,
  method,
  setShowJobModal,
  handleBulkCommunication,
  selectedRecipients,
  setSelectedRecipients,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    templatesData,
    templatesDataLoading,
    lastMail,
    lastMailLoading,
  } = useSelector(
    (state) => ({
      templatesData: state.broadCastTemplatesReducer.templatesData,
      templatesDataLoading:
        state.broadCastTemplatesReducer.templatesDataLoading,
      lastMailLoading: state.communicationReducer.lastMailLoading,
      lastMail: state.communicationReducer.lastMail,
    }),
    shallowEqual
  );
  const [showAllRecipients, setShowAllRecipients] = useState(false);

  // const [activeTab, setActiveTab] = useState(
  //   isFrom === "topNavbar" ? "sent" : "compose"
  // );
  const [activeTab, setActiveTab] = useState("compose");
  const [isSending, setIsSending] = useState(false);

  const [loading, setLoading] = useState(false);

  const [selectedRecruiter, setSelectedRecruiter] = useState([]);
  const [broadCastTemplates, setBroadCastTemplates] = useState([]);
  const [addRecipientsModal, setAddRecipientsModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    if (selectedTalents && selectedTalents?.length > 0) {
      setSelectedRecipients([...selectedTalents]);
    }
    if (selectedTalents && selectedTalents?.length === 1) {
      dispatch(
        FetchLastMailSentByUser({
          mailTo: selectedTalents[0]?.email,
        })
      );
    }
    dispatch(fetchAllBroadcastTemplates());
  }, []);

  useEffect(() => {
    if (templatesData) setBroadCastTemplates(templatesData);
  }, [templatesData]);

  useEffect(() => {
    if (isSending) {
      setTimeout(() => {
        setIsSending(false);
      }, 2000);
    }
  }, [isSending]);

  const handleSubjectChange = (e) => {
    setEmailContent({
      ...emailContent,
      subject: e.target.value,
    });
  };

  const handleContentChange = (value) => {
    setEmailContent({ ...emailContent, body: value });
  };

  const handleFooterChange = (value) => {
    setEmailContent({ ...emailContent, footer: value });
  };

  const handleClear = () => {
    setEmailContent({});
    setErrorData({});
  };

  const handleCandidateRemove = (item) => {
    setSelectedRecipients((prev) =>
      prev.filter((candidate) => candidate.userId !== item.userId)
    );
  };

  return (
    <>
      <Container style={{ fontSize: "14px" }}>
        {(loading || templatesDataLoading) && <Loader />}
        <Tabs
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          method={method}
          isFrom={isFrom}
        />
        <Row style={{ minHeight: "60vh", fontSize: "14px", marginTop: "5px" }}>
          {activeTab === "compose" &&
            (addRecipientsModal ? (
              <AddRecipients
                setAddRecipientsModal={setAddRecipientsModal}
                setSelectedRecipients={setSelectedRecipients}
                selectedRecipients={selectedRecipients}
              />
            ) : (
              <>
                <Col md={8} sm={12} lg={8}>
                  <div className="mb-3 mt-2">
                    <div className="mt-4">
                      {(!selectedRecipients ||
                        selectedRecipients ||
                        selectedRecipients?.length > 0) &&
                        isFrom !== "TalentProfileEdit" && (
                          <>
                            <button
                              type="button"
                              className="btn btn-sm btn-primary"
                              onClick={() => {
                                setAddRecipientsModal(true);
                                setShowModal(true);
                              }}
                            >
                              {" "}
                              <FaUserPlus className="mr-2" /> Add Recipients
                            </button>
                          </>
                        )}
                    </div>
                    <div className="color">{errorData["recipient"]}</div>
                  </div>
                  <Row className="messageForm mb-5">
                    <Col sm={12} md={12}>
                      <label htmlFor="subject" className="label mb-2">
                        Subject*
                      </label>
                      <input
                        type="text"
                        id="subject"
                        value={emailContent?.subject}
                        onChange={handleSubjectChange}
                        className="form-control"
                        style={{ fontWeight: "normal" }}
                        // disabled={
                        //   getUserRole() === "HiringManager" &&
                        //   selectedJob === "" &&
                        //   data?.isFrom !== "talentCommunity"
                        // }
                      />
                      <div className="color">{errorData["subject"]}</div>
                    </Col>
                    <Col sm={12} md={12}>
                      <label htmlFor="content" className="label mb-2">
                        Body*
                      </label>
                      <ReactQuill
                        value={emailContent?.body}
                        onChange={handleContentChange}
                        style={{
                          overflow: "auto",
                          fontWeight: "normal",
                        }}
                        // readOnly={
                        //   getUserRole() === "HiringManager" && selectedJob === ""
                        // }
                      />
                      <div className="color">{errorData["content"]}</div>
                    </Col>
                    <Col sm={12} md={12}>
                      <label htmlFor="footer" className="label mb-2">
                        Footer*
                      </label>
                      <ReactQuill
                        value={emailContent?.footer}
                        onChange={handleFooterChange}
                        style={{
                          overflow: "auto",
                          fontWeight: "normal",
                        }}
                        // readOnly={
                        //   getUserRole() === "HiringManager" && selectedJob === ""
                        // }
                      />
                      <div className="color">{errorData["footer"]}</div>
                    </Col>
                    <Col sm={12} md={12}>
                      <Row className="d-flex justify-content-between mt-3">
                        {/* <Col md={6} sm={12}>
                      <button
                        onClick={handleClear}
                        disabled={isSending}
                        className="button mr-2 mt-2"
                      >
                        <i className="fa fa-eraser"></i> Clear
                      </button>
                    </Col> */}
                        <Col sm={12} md={12}>
                          <Row className="d-flex justify-content-between mt-3">
                            <Col
                              md={12}
                              sm={12}
                              className="d-flex justify-content-between"
                            >
                              <button
                                className="btn btn-sm btn-success align-items-right mt-2"
                                onClick={() => {
                                  handleClear();
                                }}
                                disabled={
                                  emailContent?.subject === "" &&
                                  emailContent?.body === "" &&
                                  emailContent?.footer === ""
                                }
                              >
                                <i className="fa fa-eraser"></i> Clear
                              </button>
                              <button
                                onClick={() => {
                                  handleConfirmation("preview");
                                }}
                                disabled={
                                  isSending ||
                                  emailContent?.subject === "" ||
                                  emailContent?.body === "" ||
                                  emailContent?.footer === "" ||
                                  !selectedRecipients ||
                                  selectedRecipients?.length === 0
                                }
                                className="btn btn-sm  btn-primary align-items-right mr-2 mt-2"
                              >
                                <i className="fa fa-eye"></i> Preview Email
                              </button>
                              <button
                                onClick={() => {
                                  handleConfirmation("sendEmail");
                                }}
                                style={{ float: "right" }}
                                disabled={
                                  isSending ||
                                  emailContent?.subject === "" ||
                                  emailContent?.body === "" ||
                                  emailContent?.footer === "" ||
                                  !selectedRecipients ||
                                  selectedRecipients?.length === 0
                                }
                                className="btn btn-sm btn-success mr-2 mt-2"
                              >
                                {isSending ? (
                                  <i className="fa fa-spinner fa-spin"></i>
                                ) : (
                                  <i className="fa fa-envelope"></i>
                                )}{" "}
                                {isSending ? "Sending..." : "Send Email"}
                              </button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col
                  md={4}
                  sm={12}
                  lg={4}
                  style={{
                    borderLeft: "1px solid #ccc",
                    height: "80vh",
                    overflow: "scroll",
                  }}
                >
                  {/* <div className="mt-3 mb-2">
                    {!lastMailLoading && selectedRecipients?.length === 1 && (
                      <>
                        {lastMail?.length === 0 ? (
                          <span className="tag tag-orange1">
                            <i>No email ever sent</i>
                          </span>
                        ) : (
                          <span className="tag tag-green2">
                            <i>
                              Last email sent on :{" "}
                              {moment(lastMail[0].createdDateTime).format(
                                "MM/DD/YYYY HH:MM"
                              )}
                            </i>
                          </span>
                        )}
                      </>
                    )}
                  </div> */}
                  <div>
                    {selectedRecipients?.length > 0 && (
                      <strong>
                        Selected
                        {selectedRecipients?.length > 1
                          ? " Recipients"
                          : " Recipient"}
                        {" : "}
                        {selectedRecipients?.length}
                      </strong>
                    )}
                  </div>
                  {/* <div className="recipient-user-list">
                    {selectedTalents?.length > 0 &&
                      selectedTalents?.slice(0, 5).map((item) => (
                        <span
                          className="tag tag-blue3 mb-1 mr-1"
                          title={item.email}
                        >
                          {item?.firstName + " " + item?.lastName}
                          <a
                            href="#"
                            className="font-black"
                            onClick={() => handleCandidateRemove(item)}
                          >
                            <i className="fas fa-times ml-2 "></i>
                          </a>
                        </span>
                      ))}

                    {selectedTalents?.length > 5 && (
                      <button
                        onClick={() => setActiveTab("recipients")}
                        type="button"
                        class="btn btn-text"
                      >
                        <span>View all</span>
                      </button>
                    )}
                  </div> */}
                  <div className="recipient-user-list">
                    {selectedRecipients?.length > 0 &&
                      (showAllRecipients
                        ? selectedRecipients.map((item) => (
                            <span
                              className="tag tag-blue3 mb-1 mr-1"
                              title={item.email}
                              key={item.userId} // Add a unique key
                            >
                              {item?.firstName + " " + item?.lastName}
                              {isFrom !== "TalentProfileEdit" && (
                                <a
                                  href="#"
                                  className="font-black"
                                  onClick={() => handleCandidateRemove(item)}
                                >
                                  <i className="fas fa-times ml-2 "></i>
                                </a>
                              )}
                            </span>
                          ))
                        : selectedRecipients.slice(0, 5).map((item) => (
                            <span
                              className="tag tag-blue3 mb-1 mr-1"
                              title={item.email}
                              key={item.userId} // Add a unique key
                            >
                              {item?.firstName + " " + item?.lastName}
                              {isFrom !== "TalentProfileEdit" && (
                                <a
                                  href="#"
                                  className="font-black"
                                  onClick={() => handleCandidateRemove(item)}
                                >
                                  <i className="fas fa-times ml-2 "></i>
                                </a>
                              )}
                            </span>
                          )))}
                    {selectedRecipients?.length > 5 && !showAllRecipients && (
                      <button
                        type="button"
                        className="btn btn-text"
                        onClick={() => setShowAllRecipients(true)}
                      >
                        <span>View all</span>
                      </button>
                    )}
                    {selectedRecipients?.length > 5 && showAllRecipients && (
                      <button
                        type="button"
                        className="btn btn-text"
                        onClick={() => setShowAllRecipients(false)}
                      >
                        <span>View less</span>
                      </button>
                    )}
                  </div>
                </Col>
              </>
            ))}

          {activeTab === "sent" && (
            <SentMails selectedRecipients={selectedRecipients} />
          )}
          {activeTab === "templates" && (
            <div>
              <EmailTemplates
                data={{
                  selectedTalents: selectedTalents,
                  setEmailContent: setEmailContent,
                  setActiveTab: setActiveTab,
                  setSelectedOption: () => {},
                  broadCastTemplates: broadCastTemplates,
                }}
              />
            </div>
          )}
          {activeTab === "recipients" && (
            <RecipientsTable
              recipients={selectedTalents}
              setRecipients={setSelectedTalents}
              handleCandidateRemove={handleCandidateRemove}
            />
          )}
        </Row>
        {previewEmail && (
          <ModalView
            modalSize={"lg"}
            modalTitle={"Email Preview"}
            isModalOpen={previewEmail}
            setModalClose={setPreviewEmail}
            isFrom="EmailBroadcast"
            modalFor="preview"
            data={{
              footer: emailContent?.footer,
              content: emailContent?.body,
              subject: emailContent?.subject,
              selectedCandidates: selectedRecipients.map((t) => t.email),
            }}
          />
        )}{" "}
      </Container>
    </>
  );
};

export default Email;
