import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { union } from "lodash";
import {
  areAllKeysEmpty,
  contactInfoValidation,
  profileInfoValidation,
} from "../Validations/ApplyJobValidations";
import { domain } from "../../../../../constants/Constants";
import Contact from "./Contact";
import Loader from "../../../../Loader/Loader";
import { checkEmptyObject } from "../../../../function/CommonFunction";
import { post } from "../../../../../service/ApiService";
import { getCandidateDropdown } from "../../../../../actions/talent/AddTalent";
import Education from "./Education";
import Experience from "./Experience";
import Preferences from "./Preference";
import { parsePhoneNumber } from "libphonenumber-js";
import { isValidUrl } from "../../../../function/CommonFunction";
import ResumeParsed from "./ResumeParsed";
import SkillMatrix from "./SkillMatrix";
import { Modal } from "react-bootstrap";
import SkillsSearchable from "../../../commoncomponents/skills/SkillsSearchable";
import PoolScreeningQues from "../../applyJob_v2/PoolScreeningQues";
import ScreeningQues from "../../applyJob_v2/ScreeningQues";

const Index = ({
  job,
  skillMatchDetails,
  setSkillMatchDetails,
  setModalSize,
  parsed,
  parsedData,
  candidateDetails,
  setCandidateDetails,
  resumeFile,
  setResumeFile,
  setTrackedData,
  trackedData,
  isFrom,
  isScreening,
  setIsScreening,
  setScreeningAnswers,
  setIsVetting,
  setAddTalent,
  isVetting,
  dispositionID,
  // vettingNow,
  // setVettingNow,
  setApply,
  loadding,
  setParsedData,
  setParsed,
  component,
  setRadiusValue,
  // setSocialMediaApply,
  editTalent,
  candidateStatus,

  requiredSkills,
  setRequiredSkills,
}) => {
  const { tenant } = useParams();
  const dispatch = useDispatch();
  // tab view states
  const [experience, setExperience] = useState(false);
  const [education, setEducation] = useState(false);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  // tab view states end here

  const [skillMatrix, setSkillMatrix] = useState([]);
  const [skillMatrixModal, setSkillMatrixModal] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [load, setLoading] = useState(false);
  const [matches990, setMatches990] = useState(
    window.matchMedia("(max-width: 990px)").matches
  );
  const [matches1200, setMatches1200] = useState(
    window.matchMedia("(max-width: 1200px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 990px)")
      .addEventListener("change", (e) => setMatches990(e.matches));
    window
      .matchMedia("(max-width: 1200px)")
      .addEventListener("change", (e) => setMatches1200(e.matches));
  }, []);
  const { loading, dropDownData } = useSelector(
    (state) => ({
      loading: state?.AddCandidate?.loading,
      dropDownData: state?.AddCandidate?.dropDownData,
    }),
    shallowEqual
  );
  // Experience child Component
  const [experiences, setExperiences] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      isSelect: false,
    },
  ]);
  const [experienceVal, setExperienceVal] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    },
  ]);
  // Education Child Component
  const [educations, setEducations] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);
  const [educationVal, setEducationVal] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);

  // Contact Info child  Component
  const [contactInfo, setContactInfo] = useState({
    firstName: candidateDetails?.firstName ? candidateDetails?.firstName : "",
    middleName: "",
    lastName: candidateDetails?.lastName ? candidateDetails?.lastName : "",
    email: candidateDetails?.email ? candidateDetails?.email : "",
    zipCode: "",
    address: candidateDetails?.address ? candidateDetails?.address : "NA",
    city: candidateDetails?.city ? candidateDetails?.city : "",
    state: candidateDetails?.state ? candidateDetails?.state : "NA",
    country: candidateDetails?.country ? candidateDetails?.country : "",
    workPhoneCode: "",
    workPhone: candidateDetails?.workPhone
      ? parsePhoneNumber(
          candidateDetails.workPhone[0] === "+"
            ? candidateDetails.workPhone
            : `+${candidateDetails.workPhone}`
        ).formatInternational()
      : "",
    homePhoneCode: candidateDetails?.homePhoneCode,
    homePhone: candidateDetails?.homePhone,

    phoneValidation: true,
    phoneValidation2: true,
  });
  const [contactVal, setContactVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zipcode: "",
    address: "",
    city: "",
    state: "",
    country: "",
  });
  // Profile Info child  Component
  const [salaryType, setSalaryType] = useState("Hourly");
  const [profileData, setProfileData] = useState({
    facebook: "",
    twitter: "",
    linkedIn: "",
    preferredSalary: "",
    preferredSalaryCurrency: "USD",
    minContractRate: "",
    minContractRateCurrency: "USD",
    description: "",
    fileName: candidateDetails?.fileName ? candidateDetails?.fileName : "",
    base64: "",
    positionTypes: [],
    preferredLocation: sessionStorage.getItem("email") == null ? "" : "",
    primarySkills:
      candidateDetails?.primarySkills.length > 0
        ? candidateDetails?.primarySkills
        : [],
    secondarySkills: [],
    skillSet: [],
    source: "Career Page",
    visaStatus: "",
    legallyAuthorized: candidateDetails?.legallyAuthorized
      ? candidateDetails?.legallyAuthorized
      : false,
    requireSponsorship: candidateDetails?.requireSponsorship
      ? candidateDetails?.requireSponsorship
      : false,
    designation: candidateDetails?.designation
      ? candidateDetails?.designation
      : "",

    experienceLevel: "",
    experienceYear: candidateDetails?.experienceYear
      ? candidateDetails?.experienceYear
      : "0",
    experienceMonth: candidateDetails?.experienceMonth
      ? candidateDetails?.experienceMonth
      : "0",
    currentJobTitle: candidateDetails?.designation
      ? candidateDetails?.designation
      : "",
    isFresher: false,
    isRemote: candidateDetails?.isRemote ? candidateDetails.isRemote : false,
    isHybrid: candidateDetails?.isHybrid ? candidateDetails.isHybrid : false,
    isOnsite: candidateDetails?.isOnsite ? candidateDetails?.isOnsite : false,
    highestEducation: candidateDetails?.highestEducation
      ? candidateDetails?.highestEducation
      : "Bachelor's Degree",
  });
  const [profileVal, setProfileVal] = useState({
    preferredSalaryCurrency: "",
    minContractRateCurrency: "",
    primarySkills: "",
    experienceYear: "",
    experienceMonth: "",
    currentJobTitle: "",
  });

  useEffect(() => {
    //getTenant();
    setModalSize("apply");
  }, []);
  useEffect(() => {
    console.log(skillMatrix);
  }, [skillMatrix]);

  useEffect(() => {
    if (!dropDownData) {
      dispatch(getCandidateDropdown());
    }
  }, [dropDownData]);

  useEffect(() => {
    let skills = parsedData?.primarySkills || [];
    const newSkills = [...skills];
    skillMatchDetails?.matched?.forEach((sM) => {
      const lowercaseS = sM.toLowerCase();
      if (!newSkills.some((s) => s.toLowerCase() === lowercaseS)) {
        newSkills.push(sM);
      }
    });

    setCandidateDetails({
      ...candidateDetails,
      ...contactInfo,
      ...profileData,
      primarySkills: newSkills,
    });
  }, [skillMatchDetails]);

  useEffect(() => {
    // check if props data is present then set to values
    if (parsed) {
      //  profileInfo
      let location = parsedData?.location || "";
      if (parsedData?.isAddedByCrintell) {
        if (parsedData?.profile?.Locations?.length) {
          location = parsedData?.profile?.Locations[0];
        }
      } else if (parsedData?.h5h) {
        if (parsedData?.location?.length) {
          location = parsedData?.location;
        }
      } else {
        if (parsedData?.location) {
          location = parsedData?.location;
        }
      }

      // if added by crintell we are checking this data else previous data
      // let name1 = parsedData?.name?.split(" ");
      const contactInfoObject = !parsedData?.h5h
        ? {
            // email: parsedData?.email,
            address: location ? location?.address : "",
            country: location ? location?.country : "",
            state: location ? location?.state : "",
            city: location ? location?.city : "",
            zipcode: "",
            currentJobTitle: parsedData?.designation,
            // firstName: parsedData?.name ? name1[0] : "",
            // lastName: parsedData?.name ? name1[1] : "",
            homePhone: parsedData?.phone ? parsedData?.phone : "",
          }
        : {
            address: location?.rawInput,
            // email: parsedData?.data?.emails.toString(),
            country: location?.country ? location?.country : "",
            state: location?.state ? location?.state : "",
            city: location?.city ? location?.city : "",
            zipcode: "",
            // firstName: parsedData?.data?.name?.first,
            // lastName: parsedData?.data?.name?.last,
            workPhone: parsedData?.data?.phoneNumbers[0],
            homePhone: parsedData?.data?.phoneNumbers[1],
          };
      // : [];

      setContactInfo({
        ...contactInfo,
        ...contactInfoObject,
      });

      const Skill = !parsedData?.h5h
        ? parsedData?.primary_skills?.length
          ? parsedData?.primary_skills?.map((item) => {
              return item;
            })
          : []
        : parsedData?.skills?.length
        ? parsedData?.skills?.map((item) => {
            return item.name;
          })
        : [];

      const filteredSkill = Skill.filter((SkillItem) => SkillItem);
      let mskills = [];
      let uskills = [];

      if (component == "SubCommunity") {
        requiredSkills.forEach((rA) => {
          let arrayMatched = false;
          rA.forEach((rs) => {
            const lowercaseRs = rs?.toLowerCase();
            if (filteredSkill.some((s) => s?.toLowerCase() === lowercaseRs)) {
              arrayMatched = true;
            }
          });
          if (arrayMatched) mskills = [...mskills, ...rA];
          else uskills = [...uskills, rA];
        });
      } else {
        requiredSkills.forEach((rs) => {
          const lowercaseRs = rs?.toLowerCase();
          if (filteredSkill.some((s) => s?.toLowerCase() === lowercaseRs)) {
            mskills.push(rs);
          } else {
            uskills.push(rs);
          }
        });
      }
      setSkillMatchDetails({
        matched: mskills,
        unmatched: uskills,
      });
      // if addedby crintell we are adding first otherwise second
      const profileInfoObject = !parsedData?.h5h
        ? {
            primarySkills: parsedData?.primary_skills,
            secondarySkills: parsedData?.secondary_skills,
            linkedIn: parsedData?.linkedin || "",
            facebook: parsedData?.websites || "",
            skillSet: [],
            description: "",
            experienceYear: parsedData?.experience,
            fileName: parsedData?.fileName,
            base64: parsedData?.base64,
            currentJobTitle: parsedData?.designation,
          }
        : {
            primarySkills: filteredSkill,
            linkedIn: parsedData?.linkedin,
            facebook: parsedData?.websites[0],
            skillSet: [parsedData?.profession],
            description: parsedData?.summary,
            experienceYear: parsedData?.totalYearsExperience?.toString(),
            fileName: parsedData?.fileName,
            base64: parsedData?.base64,
          };

      setProfileData({
        ...profileData,
        ...profileInfoObject,
      });

      let experience = [];
      let experienceVal2 = [];
      if (parsedData.h5h) {
        experience =
          parsedData?.workExperience &&
          parsedData?.workExperience.map((item) => ({
            employerName: item.organization,
            jobTitle: item.jobTitle,
          }));
        experienceVal2 =
          parsedData?.workExperience &&
          parsedData?.workExperience.map((item) => ({
            employerName: "",
            industry: "",
            jobTitle: "",
            startDate: "",
            endDate: "",
          }));
      }

      experienceVal2 && setExperienceVal(experienceVal2);
      experience && setExperiences(experience);
      // Education Parsing
      let education = [];
      let educationVal2 = [];
      let objArray = [];

      if (
        parsedData?.education !== null &&
        parsedData?.education !== undefined &&
        parsedData?.education?.length > 0
      ) {
        Object.keys(parsedData?.education[0])?.forEach((key) =>
          objArray.push({
            index: key,
            edu: parsedData?.education[0][key],
          })
        );
      }

      if (!parsedData?.h5h) {
        education =
          parsedData?.education?.length > 0
            ? objArray?.map((item) => ({
                graduatedYear: item?.edu?.year,
                educationProgram: item?.edu?.program,
                school: item.edu?.school,
                major: item.edu?.major,
              }))
            : [];
        educationVal2 =
          parsedData?.education?.length > 0 &&
          objArray?.map((item) => ({
            graduatedYear: "",

            program: "",
            school: "",
            major: "",
          }));
      } else {
        education =
          parsedData?.education &&
          parsedData?.education.map((item) => ({
            educationProgram: item.accreditation?.education,
            school: item.organization,
            major: item.accreditation?.inputStr,
          }));
        educationVal2 =
          parsedData?.education &&
          parsedData?.education.map((item) => ({
            graduatedYear: "",
            educationType: "",
            educationProgram: "",
            school: "",
            major: "",
          }));
      }
      educationVal2 && setEducationVal(educationVal2);
      education && setEducations(education);
    }
  }, [parsedData]);

  useEffect(() => {
    setContactInfo({
      firstName: candidateDetails?.firstName ? candidateDetails?.firstName : "",
      middleName: "",
      lastName: candidateDetails?.lastName ? candidateDetails?.lastName : "",
      email: candidateDetails?.email ? candidateDetails?.email : "",
      zipCode: candidateDetails?.zipCode ? candidateDetails?.zipCode : "",
      address: candidateDetails?.address ? candidateDetails?.address : "",
      city: candidateDetails?.city ? candidateDetails?.city : "",
      state: candidateDetails?.state ? candidateDetails?.state : "",
      country: candidateDetails?.country ? candidateDetails?.country : "",
      workPhoneCode: "",
      workPhone: candidateDetails?.workPhone
        ? parsePhoneNumber(
            candidateDetails.workPhone[0] === "+"
              ? candidateDetails.workPhone
              : `+${candidateDetails.workPhone}`
          ).formatInternational()
        : "",
      homePhoneCode: candidateDetails?.homePhoneCode,
      homePhone: candidateDetails?.homePhone,

      phoneValidation: true,
      phoneValidation2: true,
    });
    let Skill = !parsedData?.h5h
      ? parsedData?.primary_skills?.length
        ? parsedData?.primary_skills?.map((item) => {
            return item;
          })
        : []
      : parsedData?.skills?.length
      ? parsedData?.skills?.map((item) => {
          return item.name;
        })
      : [];

    setProfileData({
      facebook: candidateDetails?.facebook ? candidateDetails?.facebook : "",
      twitter: candidateDetails?.twitter ? candidateDetails?.twitter : "",
      linkedIn: candidateDetails?.linkedIn ? candidateDetails?.linkedIn : "",
      preferredSalary: candidateDetails?.preferredSalary
        ? candidateDetails?.preferredSalary
        : "",
      preferredSalaryCurrency: candidateDetails?.preferredSalaryCurrency
        ? candidateDetails?.preferredSalaryCurrency
        : "USD",
      minContractRate: candidateDetails?.minContractRate
        ? candidateDetails?.minContractRate
        : "",
      minContractRateCurrency: "USD",
      description: "",
      fileName: candidateDetails?.fileName ? candidateDetails?.fileName : "",
      base64: "",
      positionTypes:
        candidateDetails?.positionTypes?.length > 0
          ? candidateDetails?.positionTypes
          : [],
      preferredLocation:
        candidateDetails?.preferredLocation?.length > 0
          ? candidateDetails?.preferredLocation
          : sessionStorage.getItem("email") == null
          ? ""
          : "",
      primarySkills: union(candidateDetails?.primarySkills || [], Skill),
      secondarySkills:
        candidateDetails?.secondarySkills?.length > 0
          ? candidateDetails?.secondarySkills
          : [],
      skillSet:
        candidateDetails?.skillSet?.length > 0
          ? candidateDetails?.skillSet
          : [],
      source: candidateDetails?.source
        ? candidateDetails?.source
        : "Career Page",
      visaStatus: candidateDetails?.visaStatus
        ? candidateDetails?.visaStatus
        : "",
      legallyAuthorized: candidateDetails?.legallyAuthorized
        ? candidateDetails?.legallyAuthorized
        : false,
      requireSponsorship: candidateDetails?.requireSponsorship
        ? candidateDetails?.requireSponsorship
        : false,
      designation: candidateDetails?.designation
        ? candidateDetails?.designation
        : "",

      experienceLevel: candidateDetails?.experienceLevel
        ? candidateDetails?.experienceLevel
        : "",
      experienceYear: candidateDetails?.experienceYear
        ? candidateDetails?.experienceYear
        : "0",
      experienceMonth: candidateDetails?.experienceMonth
        ? candidateDetails?.experienceMonth
        : "0",
      currentJobTitle: candidateDetails?.designation
        ? candidateDetails?.designation
        : "",
      isFresher: candidateDetails?.isFresher
        ? candidateDetails?.isFresher
        : false,
      isRemote: candidateDetails?.isRemote ? candidateDetails.isRemote : false,
      isHybrid: candidateDetails?.isHybrid ? candidateDetails.isHybrid : false,
      isOnsite: candidateDetails?.isOnsite ? candidateDetails?.isOnsite : false,
      highestEducation: candidateDetails?.highestEducation
        ? candidateDetails?.highestEducation
        : "Bachelor's Degree",
    });
  }, [candidateDetails]);

  const calculateExperience = [
    { name: "Intern", range: [0] },
    { name: "1-3 years", range: [1, 2] },
    { name: "3-5 years", range: [3, 4] },
    { name: "5-10 years", range: [5, 6, 7, 8, 9] },
    { name: "10+ years", range: [] },
  ];

  const findRange = (years, months) => {
    let totalMonths = Number(years) * 12 + Number(months);
    if (totalMonths >= 120) {
      return "10+ years";
    } else if (totalMonths < 12) {
      if (totalMonths === 0) {
        return "Intern";
      } else {
        return "Less than one year";
      }
    } else {
      let yearsExperience = Math.floor(totalMonths / 12);
      let finalValue = calculateExperience.find((i) =>
        i.range.includes(yearsExperience)
      ).name;
      return finalValue;
    }
  };

  const validationFun = () => {
    let isValid = true;
    let resumes = false;
    let isContactValidated = false;
    let isProfileValidated = false;
    let isExperienceValidated = false;
    let isEducationValidated = false;
    let isCertificationValidated = false;
    let isLicenseValidated = false;
    /*****************************ContactInfo ***********************/
    let contactInfoVal = contactInfoValidation(contactInfo);
    if (!checkEmptyObject(contactInfoVal.validation)) {
      setContactVal(contactInfoVal.validation);
      setLoading(false);
      isValid = false;
      let profileInfoVal = profileInfoValidation(profileData);
      if (!checkEmptyObject(profileInfoVal.validation)) {
        setProfileVal(profileInfoVal.validation);
        // setProfileInfo(true);
        setLoading(false);
        isValid = false;
      }
      toast.error(contactInfoVal.validationObj[0]);
    } else if (contactInfo.homePhone == "") {
      isValid = false;
      toast.error("Please Enter Phone Number");
    } else if (!contactInfo.phoneValidation) {
      isValid = false;
      toast.error("Please Enter Valid Primary Phone");
    } else {
      setContactVal(contactInfoVal.validation);
      isContactValidated = true;
      // setProfileInfo(true);
    }

    /*****************************ProfileInfo ***********************/

    if (isContactValidated) {
      let profileInfoVal = {};
      if (isFrom == "JoinTalentCommunity") {
        profileInfoVal = profileInfoValidation(
          profileData,
          job,
          "JoinTalentCommunity"
        );
      } else {
        profileInfoVal = profileInfoValidation(profileData, job, "ApplyJob");
      }
      if (!checkEmptyObject(profileInfoVal.validation)) {
        setProfileVal(profileInfoVal.validation);
        // setProfileInfo(true);
        setLoading(false);
        isValid = false;
        toast.error(profileInfoVal.validationObj[0]);
      } else if (
        profileData.preferredSalary == "" &&
        profileData.minContractRate == ""
      ) {
        isValid = false;
        toast.error("Please Enter Expected Salary....");
      } else if (
        isFrom &&
        isFrom !== "JoinTalentCommunity" &&
        ((job.workPlaceType == "Remote" && !profileData.isRemote) ||
          (job.workPlaceType == "Hybrid" && !profileData.isHybrid) ||
          (job.workPlaceType == "On-Site" && !profileData.isOnsite))
      ) {
        isValid = false;
        toast.error("Please Select Required Location");
      } else if (resumeFile.fileName === "") {
        toast.error("Please Upload Your Resume");
      } else if (profileData.linkedIn && !isValidUrl(profileData.linkedIn)) {
        toast.error("Invalid LinkedIn URL");
      } else {
        isProfileValidated = true;
        setProfileVal(profileInfoVal.validation);

        resumes = true;
      }
    }

    let obj = {
      isContactValidated,
      isProfileValidated,
      isExperienceValidated,
      isEducationValidated,
      isCertificationValidated,
      isLicenseValidated,
      isValid,
      resumes,
    };
    return obj;
  };

  // Submit Add talent
  const handleChangePrimarySkills = (skills) => {
    setProfileData({ ...profileData, primarySkills: skills });
  };

  const handleChangeInputPrimarySkills = (skills) => {
    setPrimarySkillInput(skills);
  };

  const handleSubmit = () => {
    setIsFormValid(true);
    let validObj = validationFun();
    const { isContactValidated, isProfileValidated, resumes } = validObj;
    if (isContactValidated && isProfileValidated && resumes) {
      let screeningQuestions = job?.screeningQuestions
        ? JSON.parse(job?.screeningQuestions)
        : [];
      if (screeningQuestions.length > 0 || component == "SubCommunity") {
        post("/auditLog/candidateData", {
          ...trackedData,
          formType: "B",
          navigatedPages: trackedData.navigatedPages + "/screening",
          jobId: job.jobId,
          poolId: job.poolId,
          firstName: contactInfo.firstName,
          lastName: contactInfo.lastName,
          email: candidateDetails.email,
          resume: resumeFile.fileName ? true : false,
        });

        setTrackedData({
          ...trackedData,
          status: "screening",
          navigatedPages: trackedData.navigatedPages + "/screening",
          jobId: job.jobId,
          poolId: job.poolId,

          firstName: contactInfo.firstName,
          lastName: contactInfo.lastName,
          email: candidateDetails.email,
          resume: resumeFile.fileName ? true : false,
        });
        if (component == "SubCommunity") {
          submitAddTalent();
          setIsScreening(true);
        } else {
          setIsScreening(true);
        }
      } else {
        submitAddTalent();
      }
    }
  };

  const submitAddTalent = async () => {
    let talentData = {
      ...contactInfo,
      ...profileData,
      ...educations,
    };

    let experience =
      experiences.length > 1
        ? experiences.filter((item) => checkEmptyObject(item) === false)
        : experiences;
    let education =
      educations.length > 1
        ? educations.filter((item) => checkEmptyObject(item) === false)
        : educations;

    talentData.experiences = experience;
    talentData.educations = education;

    talentData.domainName = domain;
    talentData.experienceLevel = findRange(
      profileData.experienceYear,
      profileData.experienceMonth
    );
    talentData.skillMatrix = skillMatrix;

    talentData.primarySkills = union(
      profileData?.primarySkills,
      skillMatrix.filter((item) => item.experience > 0).map((s) => s.skill)
    );

    talentData.currentEmployer = candidateDetails.currentEmployer;
    talentData.filePath = candidateDetails?.filePath;
    talentData.salaryType = salaryType;

    setCandidateDetails(talentData);
    setAddTalent(true);
  };

  const ResumeComponent = () => {
    return (
      <ResumeParsed
        resumeFile={resumeFile}
        setResumeFile={setResumeFile}
        parsed={parsed}
        setParsedData={setParsedData}
        setParsed={setParsed}
      />
    );
  };
  return (
    <>
      {isScreening ? (
        component == "SubCommunity" ? (
          <PoolScreeningQues
            job={job}
            setAddTalent={setAddTalent}
            setIsVetting={setIsVetting}
            setIsScreening={setIsScreening}
            setScreeningAnswers={setScreeningAnswers}
            candidateDetails={candidateDetails}
            trackedData={trackedData}
            setTrackedData={setTrackedData}
            submitAddTalent={submitAddTalent}
            component={component}
            setApply={setApply}
            dispositionID={dispositionID}
          />
        ) : (
          <ScreeningQues
            job={job}
            setAddTalent={setAddTalent}
            setIsVetting={setIsVetting}
            setIsScreening={setIsScreening}
            setScreeningAnswers={setScreeningAnswers}
            candidateDetails={candidateDetails}
            trackedData={trackedData}
            setTrackedData={setTrackedData}
            submitAddTalent={submitAddTalent}
            component={component}
          />
        )
      ) : (
        <div style={{ marginTop: "-14px" }}>
          {loading || load || (loadding && <Loader />)}
          <div
            className={`bl-modal-body ${
              education || experience || skillMatrixModal ? "d-none" : ""
            }`}
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <div className="d-flex px-2 justify-content-between my-2">
              <h5 className=" ml-2">Apply </h5>
            </div>
            <hr className="p-0 m-0" />
            <div class=" ">
              <div class="p-2 pb-0 ">
                <div class="">
                  <div className="px-2" v-if="item.isExpanded">
                    <div className="pb-0 mb-0">
                      <div className="my-2 mb-3">
                        <h6>
                          Welcome to High5! Complete the form below to apply for
                          your desired position.
                        </h6>
                      </div>
                      <div className={"ml-0-600 mt-2 mb-1"}>
                        <div class="card card-flat card-lg card-lg  ml-0-600">
                          <div class=" card-body">
                            <h6 className="mb-0">Profile Information</h6>
                            <hr />
                            <Contact
                              contactInfo={contactInfo}
                              setContactInfo={setContactInfo}
                              contactInfoValidation={contactVal}
                              isFormValid={isFormValid}
                              parsed={parsed}
                              job={job}
                              setRadiusValue={setRadiusValue}
                            />
                            {parsed && (
                              <Preferences
                                profileInfo={profileData}
                                setProfileInfo={setProfileData}
                                profileInfoValidation={profileVal}
                                job={job}
                                isFormValid={isFormValid}
                                salaryType={salaryType}
                                setSalaryType={setSalaryType}
                              />
                            )}
                            {parsed && (
                              <div className="d-flex">
                                <div className=" col-12 ">
                                  <div className=" ">
                                    <label>
                                      What are your primary skills?*{" "}
                                    </label>
                                  </div>
                                  <div className="">
                                    <SkillsSearchable
                                      onChange={handleChangePrimarySkills}
                                      inputArr={profileData.primarySkills}
                                      skillinput={primarySkillinput}
                                      onChangeInput={
                                        handleChangeInputPrimarySkills
                                      }
                                      disabled={true}
                                      inputProps={{
                                        placeholder: "Add Skill",
                                      }}
                                      isFrom="ApplyJob"
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      {parsed && (
                        <div className={"ml-0-600 mt-2 mb-1"}>
                          <div class="card card-flat card-lg card-lg  ml-0-600">
                            <div className="card-body">
                              <div className="d-flex justify-content-between">
                                <h6>Skill Match Analysis</h6>
                                <button
                                  className="btn btn-sm btn-secondary"
                                  onClick={() => setSkillMatrixModal(true)}
                                  disabled={
                                    skillMatchDetails?.unmatched?.length === 0
                                  }
                                >
                                  <i className="fa fa-pencil fa-fw mr-2"></i>
                                  Add Skills Detail
                                </button>
                              </div>
                              <hr />

                              <table class="table">
                                <thead>
                                  <tr>
                                    <th className="" scope="col">
                                      <span>Parameter</span>
                                    </th>

                                    <th className=" text-center" scope="col">
                                      <span>Matched</span>
                                    </th>
                                    <th className=" text-center" scope="col">
                                      <span> Gap</span>
                                    </th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {" "}
                                  <tr>
                                    <td className="" scope="col">
                                      <span>Required skills</span>
                                    </td>

                                    <td className=" text-center" scope="col">
                                      <div className="d-block ">
                                        {" "}
                                        {skillMatchDetails?.matched?.map(
                                          (x, i) =>
                                            profileData?.primarySkills.some(
                                              (c) =>
                                                c?.toLowerCase() ===
                                                x?.toLowerCase()
                                            ) && (
                                              <span
                                                key={x}
                                                className={`tag tag-green4 ${
                                                  matches990 ? "mb-2" : "ml-2"
                                                } `}
                                              >
                                                {x}
                                              </span>
                                            )
                                        )}
                                      </div>
                                    </td>
                                    <td className=" text-center" scope="col">
                                      <div className="d-block ">
                                        {skillMatchDetails?.unmatched?.map(
                                          (x, i) => (
                                            <span
                                              key={x}
                                              className={`tag tag-red2 ${
                                                matches990 ? "mb-2" : "ml-2"
                                              } `}
                                            >
                                              {component === "SubCommunity"
                                                ? x.join(" OR ")
                                                : x}
                                            </span>
                                          )
                                        )}
                                      </div>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      )}
                      {!parsed && (
                        <div
                          className={
                            "mt-2 mb-1 d-flex  jusify-content-center gap-2 align-items-center"
                          }
                        >
                          {ResumeComponent()}
                        </div>
                      )}{" "}
                      {parsed && skillMatchDetails?.unmatched?.length > 0 && (
                        <div
                          className={
                            "mt-3 mb-1 d-flex  jusify-content-center gap-2 align-items-center "
                          }
                        >
                          <h6>
                            We have identified some potential skill gaps. If you
                            believe you posses these skill and they are not
                            reflected in your resume, please update by clicking
                            "Add Skill Detail" to enhance your fit.
                          </h6>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* FOOTER ADDED FOR LESS RESPONSIVE */}
            {matches990 && (
              <>
                <hr className="" />
                <div className="">
                  <div className="d-flex  justify-content-around gap-3 w-100   ">
                    {parsed && (
                      <>
                        <div className=" d-flex align-items-center justify-content-between ">
                          <button
                            type="button"
                            className="btn btn-sm  btn-secondary mr-1 "
                            onClick={() => {
                              setExperience(true);
                            }}
                          >
                            View Experience
                          </button>{" "}
                          {(experiences?.length > 1 ||
                            !areAllKeysEmpty(experiences[0])) && (
                            <span className="text-success font-22 ">
                              <i class="fas fa-check-circle"></i>
                            </span>
                          )}{" "}
                        </div>
                        <div className=" d-flex align-items-center justify-content-center ">
                          <button
                            type="button"
                            className=" btn btn-sm btn-secondary mr-1 "
                            onClick={() => {
                              setEducation(true);
                            }}
                          >
                            View Education
                          </button>{" "}
                          {(educations?.length > 1 ||
                            !areAllKeysEmpty(educations[0])) && (
                            <span className="text-success font-22 ">
                              <i class="fas fa-check-circle"></i>
                            </span>
                          )}{" "}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
            {parsed && matches1200 && (
              <div className="  ">
                <hr className="" />

                {ResumeComponent()}
              </div>
            )}
            {/* FOOTER */}
            <hr className="pb-0 mb-0" />
            <div className="row mx-auto my-3  ">
              <div
                className={` col-md-12 d-flex ${
                  !matches990
                    ? "justify-content-between"
                    : "justify-content-end"
                }`}
              >
                {!matches990 && (
                  <div className="mr-2">
                    <div className="d-flex  justify-content-around gap-3 align-items-end w-100  px-2 ">
                      {parsed && (
                        <>
                          <div className=" d-flex align-items-center justify-content-center ">
                            <button
                              type="button"
                              className="btn btn-sm  btn-secondary mr-1 "
                              onClick={() => {
                                setExperience(true);
                              }}
                            >
                              View Experience
                            </button>{" "}
                            {(experiences?.length > 1 ||
                              !areAllKeysEmpty(experiences[0])) && (
                              <span className="text-success font-22 ">
                                <i class="fas fa-check-circle"></i>
                              </span>
                            )}{" "}
                          </div>
                          <div className=" d-flex align-items-center justify-content-center ">
                            <button
                              type="button"
                              className=" btn btn-sm btn-secondary mr-1 "
                              onClick={() => {
                                setEducation(true);
                              }}
                            >
                              View Education
                            </button>{" "}
                            {(educations?.length > 1 ||
                              !areAllKeysEmpty(educations[0])) && (
                              <span className="text-success font-22 ">
                                <i class="fas fa-check-circle"></i>
                              </span>
                            )}{" "}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <div className=" d-flex justify-content-end ">
                  {parsed && !matches1200 && <>{ResumeComponent()}</>}
                  <button
                    className="btn btn-sm btn-secondary mx-2 "
                    onClick={() => {
                      setApply(false);
                      setIsFormValid(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    disabled={
                      !resumeFile?.fileName ||
                      !parsed ||
                      skillMatchDetails?.unmatched?.length > 0
                    }
                    className={`btn btn-sm ${"btn-primary"} mr-2`}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          {education && (
            <Modal size={"xl"} show={education} backdrop="static">
              <div class="bl-modal-header">
                <h6 class="bl-modal-title">Add Education</h6>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setEducation(false);
                  }}
                  class="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Modal.Body>
                <div>
                  <hr />
                  <Education
                    educations={educations}
                    setEducations={setEducations}
                    educationValidation={educationVal}
                    setEducationValidation={setEducationVal}
                    setEducation={setEducation}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )}
          {experience && (
            <Modal size={"xl"} show={experience}>
              <div class="bl-modal-header">
                <h6 class="bl-modal-title">Add Experience</h6>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setExperience(false);
                  }}
                  class="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Modal.Body>
                <div>
                  <hr />
                  <Experience
                    experiences={experiences}
                    setExperiences={setExperiences}
                    experienceValidation={experienceVal}
                    setExperienceValidation={setExperienceVal}
                    dropDownData={dropDownData}
                    setExperience={setExperience}
                  />
                </div>
              </Modal.Body>
            </Modal>
          )}{" "}
          {skillMatrixModal && (
            <Modal size={"xl"} show={skillMatrixModal}>
              <div class="bl-modal-header">
                <h5 class="bl-modal-title mt-2">Skill Matrix</h5>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setSkillMatrixModal(false);
                  }}
                  class="close p-0 bl-modal-close-btn"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <Modal.Body>
                <SkillMatrix
                  job={job}
                  skillMatrix={skillMatrix}
                  setSkillMatrix={setSkillMatrix}
                  skillMatchDetails={skillMatchDetails}
                  setSkillMatchDetails={setSkillMatchDetails}
                  setSkillMatrixModal={setSkillMatrixModal}
                  component={component}
                />
              </Modal.Body>
            </Modal>
          )}
        </div>
      )}
    </>
  );
};

export default Index;
