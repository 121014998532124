import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import EditClient from "./EditClient";
import DeleteClient from "./DeleteClient";
import { getPreciseDateDiff } from "../function/CommonFunction";
import moment from "moment";
import Avatar from "react-avatar";
import { toast } from "react-toastify";

const ListView = (props) => {
  const [editClient, setEditClient] = useState(false);
  const [deleteClient, setDeleteClient] = useState(false);
  const [listData, setListData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const closeEditClient = () => {
    setEditClient(false);
  };
  const closeDeleteClient = () => {
    setDeleteClient(false);
  };
  useEffect(() => {
    let listData =
      props.isFor === "gig"
        ? props?.data.map(
            (item) => props?.tenantData.filter((i) => i.email === item.email)[0]
          )
        : props?.tenantData.filter((i) => !i?.tenantname.includes("GIG -"));

    console.log(listData, "listData", props?.tenantData);
    setListData(listData);
  }, [props]);

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * props.dataLimit - props.dataLimit;
    const endIndex = startIndex + props.dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : listData.slice(startIndex, endIndex);
  };

  useEffect(() => {
    if (listData.length > 0) {
      searchValue
        ? setPaginationArray(
            new Array(Math.ceil(searchData.length / props.dataLimit))
              .fill()
              .map((_, i) => i + 1)
          )
        : setPaginationArray(
            new Array(Math.ceil(listData.length / props.dataLimit))
              .fill()
              .map((_, i) => i + 1)
          );
      searchValue
        ? setPaginationGroup(
            new Array(Math.ceil(searchData.length / props.dataLimit))
              .fill()
              .map((_, i) => i + 1)
              .slice(0, props.pageLimit)
          )
        : setPaginationGroup(
            new Array(Math.ceil(listData.length / props.dataLimit))
              .fill()
              .map((_, i) => i + 1)
              .slice(0, props.pageLimit)
          );
      setCurrentPage(1);
    }
  }, [listData, props.dataLimit, searchData]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % props.pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(
            currentPage - 1,
            currentPage + (props.pageLimit - 1)
          )
        );
      }
    } else {
      if (currentPage % props.pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - props.pageLimit, currentPage)
        );
      }
    }
    // eslint-disable-next-line
  }, [currentPage]);

  const splitMatchedText = (str) => {
    const regExp = new RegExp(searchValue, "gi");
    return str?.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };
  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchData(
      listData.filter((val) => {
        if (e.target.value == "") {
          return val;
        } else if (
          val?.tenantname.toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return val;
        }
      })
    );
  };
  const handleCopy = (item) => {
    navigator.clipboard.writeText(item.email);
    toast.success("Email Copied Successfully!!!");
  };
  return (
    <>
      <div class="d-flex align-items-center justify-content-between flex-wrap">
        <div class="d-flex align-items-center">
          <div class="mr-3">
            <select
              class="form-select font-14"
              onChange={props.onChangeSelectStaffing}
              defaultValue={10}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="All">All</option>
            </select>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12 col-12">
          <input
            type="text"
            onChange={handleSearch}
            placeholder="Search"
            className="form-control small font-14"
          />
        </div>
      </div>
      <hr />
      <div className="pt-0 mb-3 mt-3">
        {getPaginatedData()?.map((item) => {
          console.log("itemmmmm________________158", item);
          // let location = "";
          // item?.addressCity && item?.addressState
          //   ? (location = `${item?.addressCity}, ${item?.addressState}`)
          //   : item?.addressCity
          //   ? (location = item?.addressCity)
          //   : item.addressState  (location = item?.addressState);

          return (
            <>
              {/* <div className="card card-flat card-hoverable card-hoverable-notransform mb-1"> */}
              <div className="card  card-borderless card-hoverable mb-2">
                {/* <div className="card card-flat card-hoverable-notransform mb-1"> */}
                <div className="card-body d-flex justify-content-between">
                  <a
                    href="#"
                    className="font-primary no-hover w-100 "
                    style={{ cursor: "auto" }}
                  >
                    <div className="card-body d-flex align-items-center justify-content-between">
                      <div className="= d-flex align-items-center">
                        <div className="mx-2">
                          <span>
                            <Avatar
                              size="55"
                              name={
                                item?.tenantname.includes("GIG - ")
                                  ? item?.tenantname.replace("GIG - ", "")
                                  : item?.tenantname
                              }
                              round="80px"
                            />
                          </span>
                        </div>
                        <h6
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: splitMatchedText(
                              item?.tenantname.includes("GIG - ")
                                ? item?.tenantname.replace("GIG - ", "")
                                : item?.tenantname
                            ),
                          }}
                        ></h6>{" "}
                      </div>{" "}
                      <div className="mx-2 font-regular font-primary font-gray1">
                        <span className="mb-0">Email: {item?.email}</span>{" "}
                        <button
                          type="button"
                          title="Copy Email"
                          class="btn btn-text"
                          onClick={() => {
                            handleCopy(item);
                          }}
                        >
                          <i class="fa fa-copy"></i>
                        </button>
                      </div>
                    </div>

                    <div className="p-2 border-top text-end">
                      <div class="text-muted my-auto p-0">
                        Added on: &nbsp;
                        <i class="far fa-clock"></i>&nbsp;
                        <span>
                          {moment(item?.createdon).format("MM/DD/YYYY")}
                        </span>
                        &nbsp;
                        {/* {dataDiff(candidate.updatedDate, new Date()) > 1 ? 'Days' : 'Day'} &nbsp;ago */}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </>
          );
        })}
        {editClient && <EditClient closeEditClient={closeEditClient} />}
        {deleteClient && <DeleteClient closeDeleteClient={closeDeleteClient} />}
      </div>
      {getPaginatedData().length > 0 && (
        <div className="d-flex p-2">
          <div className="w-100-370 mb-point5-370">
            Showing{" "}
            {currentPage === paginationArray.slice(-1)[0]
              ? getPaginatedData().length
              : searchValue
              ? searchData.length < props.dataLimit
                ? searchData.length
                : props.dataLimit
              : props.dataLimit}{" "}
            of {listData.length}
          </div>
          {getPaginatedData().length > 0 && (
            <div class="ml-auto mx-auto-370 d-flex align-items-center">
              <button
                onClick={() => {
                  setPageType("down");
                  setCurrentPage((page) => page - 1);
                }}
                type="button"
                disabled={
                  currentPage === 1 || getPaginatedData().length === 0
                    ? true
                    : false
                }
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-left"></i>
              </button>

              {paginationGroup.map((item) => (
                <button
                  type="button"
                  className={
                    currentPage === item
                      ? "btn btn-text px-2 bg-secondary text-white"
                      : "btn btn-text px-2"
                  }
                  onClick={changePage}
                >
                  {item}
                </button>
              ))}

              <button
                onClick={() => {
                  setPageType("up");
                  setCurrentPage((page) => page + 1);
                }}
                type="button"
                disabled={
                  currentPage === paginationArray.slice(-1)[0] ||
                  getPaginatedData().length === 0
                    ? true
                    : false
                }
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-right"></i>
              </button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default ListView;
