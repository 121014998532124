import {
    GET_REGISTER_BEGIN,
    GET_REGISTER_SUCCESS,
    GET_REGISTER_FAILURE,
} from '../../actions/auth/Register';

const initialState = {
    loading: false,
    RegisterDetails: null,
    RegisterErrors: null,
};

const RegisterReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case GET_REGISTER_BEGIN:
            return {
                ...state,
                loading: true,
                loginDetails: null
            };
        case GET_REGISTER_SUCCESS:
            return {
                ...state,
                loginDetails: payload,
                loading: false
            };
        case GET_REGISTER_FAILURE:
            return {
                ...state,
                loginErrors: payload,
                loading: false
            };
        default:
            return state;
    }
}

export default RegisterReducer;

