import React, { useEffect, useState } from "react";

const FilterJobs = (props) => {
  let dataLimit = 4;
  let pageLimit = 5;
  const [requestTitleEnabled, setRequestTitleEnabled] = useState(false);
  const [requestTypeEnabled, setRequestTypeEnabled] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);

  useEffect(() => {
    setRequestTitles(props.requestTitles);
    setRequestTypes(props.requestTypes);
    setSelectedTitles(props.selectedTitles);
    setSelectedTypes(props.selectedTypes);
  }, []);

  const titleHandler = (checked, title) => {
    if (checked) {
      setSelectedTitles([...selectedTitles, title]);
    } else {
      setSelectedTitles(selectedTitles.filter((t) => t !== title));
    }
  };

  const typeHandler = (checked, type) => {
    if (checked) {
      setSelectedTypes([...selectedTypes, type]);
    } else {
      setSelectedTypes(selectedTypes.filter((t) => t !== type));
    }
  };

  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : requestTitles.slice(startIndex, endIndex);
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(requestTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(requestTitles.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    setCurrentPage(1);
  }, [requestTitles, searchData]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  const splitMatchedText = (str) => {
    const regExp = new RegExp(searchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchData(
      requestTitles.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={() => props.applyFilter(selectedTitles, selectedTypes)}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>Filter / {selectedTitles.length + selectedTypes.length}</h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span
                  className="font-bold font-14"
                  onClick={() => {
                    setSelectedTypes([]);
                    setSelectedTitles([]);
                  }}
                >
                  Clear All
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() =>
                requestTitleEnabled
                  ? setRequestTitleEnabled(false)
                  : setRequestTitleEnabled(true)
              }
            >
              <i
                className={
                  requestTitleEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Job Title</small>
                {selectedTitles.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTitleEnabled &&
              (requestTitles.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  {getPaginatedData().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedTitles.includes(j)}
                            className="form-check-input"
                            onChange={(e) => titleHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No Records found for `'${searchValue}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage === paginationArray.slice(-1)[0]
                        ? getPaginatedData().length
                        : searchValue
                        ? searchData.length < dataLimit
                          ? searchData.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {requestTitles.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === 1 || getPaginatedData().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === paginationArray.slice(-1)[0] ||
                          getPaginatedData().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue("");
                        setSelectedTitles([]);
                      }}
                    >
                      <small>Clear Job Title</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setRequestTypeEnabled(!requestTypeEnabled)}
            >
              <i
                className={
                  requestTypeEnabled
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span class="p-relative">
                <small>Job Type</small>
                {selectedTypes.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {requestTypeEnabled &&
              (requestTypes.length > 0 ? (
                <div className="mb-4 ml-4 pl-1 pt-2">
                  {requestTypes.map((i) => (
                    <div className="d-flex align-items-center m-1">
                      <div>{i}</div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={selectedTypes.includes(i)}
                            onChange={(e) => typeHandler(e.target.checked, i)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => setSelectedTypes([])}
                    >
                      <small>Clear Job Type</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No Records found</div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterJobs;
