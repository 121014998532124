import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import Loader from "../../../../Loader/Loader";
import { getClientUserData } from "../../../../../actions/dashboard/ClientUserData";
import { getEmployeeDetails } from "../../../../../actions/employees/Employees";
import {
  getTenantType,
  isCurator,
  getUserTenantID,
} from "../../../../../service/AuthService";

const ClientHM = ({
  addJobData,
  setAddJobData,
  addJobVal,
  edit,
  selectedClient,
  setSelectedClient,
  selectedHM,
  setSelectedHM,
}) => {
  const dispatch = useDispatch();
  const [groups, setGroups] = useState([]);
  const [clients, setClients] = useState([]);
  const [id, setId] = useState(addJobData.fkTenant ? addJobData.fkTenant : 1);

  useEffect(() => {
    const payload = {
      currentPage: 1,
      limit: 1000,
      searchText: "",
    };
    dispatch(getClientUserData(payload));
  }, []);

  useEffect(() => {
    if (id) {
      const params = {
        tenantId: getTenantType() === 1 ? getUserTenantID() : id,
      };
      dispatch(getEmployeeDetails(params));
    }
  }, [id]);

  const { usersData, employees } = useSelector(
    (state) => ({
      usersData: state.getClientUserData.clientData,
      employees: state.employeesDetails.employeesDetails,
    }),
    shallowEqual
  );
  useEffect(() => {
    if (getTenantType() === 1) {
      const user = usersData
        .filter((val) => val.statusname == "Active")
        .map((item) => ({
          ...item,
          value: item.id_tenant,
          label: item.tenantname,
        }));

      let defaultClient = user.filter(
        (item) => item.id_tenant === getUserTenantID()
      );
      const selected = user.find((e) => e.value == getUserTenantID());
      setAddJobData({
        ...addJobData,
        client: selected?.id_tenant,
        fkTenant: selected?.id_tenant,
        hm: [],
      });
      setSelectedClient(selected);
      setId(getUserTenantID());
      setClients(defaultClient);
    }
  }, [usersData]);

  useEffect(() => {
    if (usersData) {
      const user = usersData
        .filter((val) => val.statusname == "Active")
        .map((item) => ({
          ...item,
          value: item.id_tenant,
          label: item.tenantname,
        }));
      setClients(user);

      if (addJobData?.fkTenant) {
        const selected = user.find((e) => e.value == addJobData.fkTenant);
        setSelectedClient(selected);
        setId(addJobData.fkTenant);
      }
    }
  }, [usersData]);

  useEffect(() => {
    if (employees) {
      const user = employees
        .filter((val) => val.statusname == "Active")
        .map((item) => ({
          ...item,
          value: item.id_useraccessdetail,
          label: item.name,
        }));
      setGroups(user);

      if (addJobData?.hm) {
        const selectedHMs = user.filter((item) =>
          addJobData.hm.includes(item.value)
        );
        setSelectedHM(selectedHMs);
      }
    }
  }, [employees]);

  const handleClient = (e) => {
    setId(e.value);
    setSelectedClient(e);
    setSelectedHM([]); // Reset HM selection when a new client is selected
    setAddJobData({
      ...addJobData,
      client: e.value,
      fkTenant: e.value,
      hm: [],
    });
    const params = {
      tenantId: e.value,
    };
    dispatch(getEmployeeDetails(params));
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      backgroundColor: state.isDisabled ? "#e9ecef" : provided.backgroundColor,
      color: state.isDisabled ? "#323a41" : provided.color,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: state.isDisabled ? "#323a41" : provided.color,
    }),
  };

  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="row ">
          {!isCurator() && getTenantType() !== 1 && (
            <div className="col-lg-6">
              <div
                className="card-body py-0 mb-2"
                style={{ zIndex: 99999, position: "relative" }}
              >
                <label>Select Client*</label>
                <Select
                  options={clients}
                  isDisabled={edit}
                  className={
                    addJobVal.client && addJobData?.client === ""
                      ? "border border-danger border-radius"
                      : ""
                  }
                  name="client"
                  onChange={handleClient}
                  isSearchable={true}
                  value={selectedClient}
                  styles={customStyles}
                ></Select>
              </div>
              {addJobVal.client && addJobData?.client === "" && (
                <small className="validation ml-2">*Required field</small>
              )}
            </div>
          )}
          <div className="col-lg-6">
            <div
              className="card-body py-0 mb-2"
              style={{ zIndex: 99999, position: "relative" }}
            >
              <label>Select Hiring Manager</label>
              <Select
                isMulti
                value={selectedHM}
                options={groups.filter((e) => e.role === "HiringManager")}
                name="hm"
                onChange={(e) => {
                  setSelectedHM(e);
                  setAddJobData((prevData) => ({
                    ...prevData,
                    hm: e.map((g) => g.value),
                  }));
                }}
                isSearchable={true}
              ></Select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientHM;
