import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import NoRecord from "../../../../../commoncomponents/norecord/NoRecord";

const VettingRequirement = (props) => {
  const { jobData, setVetting, vetting } = props;

  let vettingDetails = JSON.parse(jobData?.vettingDetails);

  return (
    <Modal size="xl" show={vetting}>
      <Modal.Header>
        <h6 className="pt-o mt-3">Vetting Requirement</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setVetting(false)}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="card card-flat bg-gray4 m-3">
          <div className="card-body">
            {vettingDetails ? (
              <>
                <div className="row mb-3 px-3">
                  <div className="col-lg-3">
                    <strong>Assessment</strong>
                  </div>
                  <div className="col-lg-3">
                    <strong>Skills</strong>
                  </div>
                  <div className="col-lg-3">
                    <strong>Total Questions</strong>
                  </div>
                  <div className="col-lg-3">
                    <strong>Duration</strong>
                  </div>
                </div>
                <hr />
                <div className="row mb-3 px-3">
                  <div className="col-lg-3">
                    {vettingDetails?.testName?.split("_")[0]}
                  </div>
                  <div className="col-lg-3">
                    <select className="form-select font-14" disabled>
                      <option value="MCQ">
                        {vettingDetails?.skills?.join(", ")}
                      </option>
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <select className="form-select font-14" disabled>
                      <option value={vettingDetails?.questions?.length}>
                        {vettingDetails?.questions?.length}
                      </option>
                    </select>
                  </div>
                  <div className="col-lg-3">
                    <select className="form-select font-14" disabled>
                      <option>{vettingDetails?.details?.duration} mins</option>
                    </select>
                  </div>
                </div>
              </>
            ) : (
              <NoRecord />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button variant="secondary" onClick={() => setVetting(false)}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default VettingRequirement;
