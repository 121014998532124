import React, { useState, useEffect } from "react";
import Loader from "../Loader/Loader";
import { post } from "../../service/ApiService";
import { getPreciseDateDiff } from "../function/CommonFunction";
import { countryObj, domain, TenantImage } from "../../constants/Constants";
import { getTenantType } from "../../service/AuthService";
import Footer from "../layout/Footer";

function ShareJob(props) {
  const [loading, setLoading] = useState(false);

  const [fullText, setFullText] = useState({
    location: {},
    city: {},
    state: {},
    jobTitle: "",
  });
  const [tenant, setTenant] = useState("");

  const [jobData, setJobData] = useState({
    jobTitle: "",
    jobType: "",
    location: "",
    city: "",
    jobId: "",
    statusId: "",
    jobPostDate: "",
    jobDescription: "",
  });

  useEffect(() => {
    fetchJobData();
  }, []);

  const fetchJobData = async () => {
    setLoading(true);
    const jobId = props.match.params.Id;
    const JobId_Int = jobId.substring(3);
    const payload = {
      domain: domain,
      jobId: JobId_Int,
    };
    let result = await post("/sharejob/getjobdatafromindex", payload);
    console.log(result, "result");
    setJobData(result.data.jobData);
    setFullText(result.data.jobData.fullText);
    setTenant(result.data.tenantData);
    setLoading(false);
  };

  const dateDiff = getPreciseDateDiff(
    new Date(),
    new Date(jobData.jobPostDate)
  );

  return (
    <>
      {loading && <Loader />}
      <body>
        <div id="Content">
          <section id="TestApp">
            <div className="hero">
              <div className="section pt-1 pb-2">
                <div className="row justify-content-center mb-0">
                  <div className="text-center mt-4 mb-4 font-white text-capitalize">
                    <h5 className="mb-0">{fullText.jobTitle}</h5>
                    <p>
                      {fullText.location.city !== undefined
                        ? fullText.location.city || "Remote"
                        : ""}{" "}
                      {fullText.location.city !== "" && ","}
                      {fullText.location.state !== undefined
                        ? fullText.location.state
                        : ""}{" "}
                      {fullText.location.state !== "" && ","}
                      {fullText.location.country !== undefined
                        ? fullText.location.country
                        : ""}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="page-content">
              <div className="container">
                <div className="row">
                  <div className="col-md-9">
                    <div className="page-content-wrapper">
                      <div className="Sspace"></div>
                      <div className="jobsort">
                        <div className="jobsortheading"></div>
                        <div className="clientdiv">
                          <div className="clienttext">
                            <h5>{fullText.jobTenant}</h5>
                          </div>
                          <div className="jobview"></div>
                          <ul></ul>
                        </div>
                        <div className="jobviewpostdiv">
                          <div className="row">
                            <div className="col-md-4">
                              <div className="jobviewpostopen">
                                Posted on : &nbsp;
                                <span>{dateDiff}</span>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="jobviewpostopen">
                                Opening : &nbsp;
                                <span>{jobData.positionCount}</span>
                              </div>
                            </div>
                            <div className="Sspace"></div>
                          </div>
                        </div>
                        <div className="Sspace"></div>
                        <div className="linediv"></div>
                        <div className="jdexpandiv">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="jobviewpostopen">
                                Job Title : &nbsp;
                                <span>
                                  <strong>{jobData.jobTitle}</strong>
                                </span>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="jobviewpostopen">
                                Position Type : &nbsp;
                                <span>
                                  <strong>{jobData.jobType}</strong>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="linediv"></div>
                        <div className="Sspace"></div>
                        <div className="jdexpandiv">
                          <div className="deptdetails">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="jobviewpostopen">
                                  Skills : &nbsp;
                                  <span>
                                    <strong>
                                      {jobData.primarySkills &&
                                        jobData.primarySkills.join(", ")}
                                    </strong>
                                  </span>
                                </div>
                              </div>
                              <div className="col-md-12">
                                {jobData.secondarySkills > 0 && (
                                  <div className="jobviewpostopen">
                                    Secondary Skills : &nbsp;
                                    <span>
                                      <strong>
                                        {jobData.secondarySkills &&
                                          jobData.secondarySkills.join(", ")}
                                      </strong>
                                    </span>
                                  </div>
                                )}
                              </div>
                              <div className="col-md-12">
                                {jobData.skillSet > 0 && (
                                  <div className="jobviewpostopen">
                                    Skill set : &nbsp;
                                    <span>
                                      <strong>
                                        {jobData.skillSet &&
                                          jobData.skillSet.join(", ")}
                                      </strong>
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="Sspace"></div>
                        <div className="deptdetails">
                          <div className="jobdetails">
                            <h5>Job Description</h5>
                          </div>
                          <div className="jobdetaillist">
                            <ul>
                              <li>
                                <p>
                                  <ol
                                    className="pr-3"
                                    dangerouslySetInnerHTML={{
                                      __html: jobData.jobDescription,
                                    }}
                                  ></ol>
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-md-3">
                    <div className="Sspace"></div>
                    {/* <div className="about company">
                      <div className="clienttext">
                        <h5>About Company</h5>
                      </div>
                      <div className="aboutcompanylogo">
                        <img src={TenantImage} alt="blank"></img>
                      </div>
                      <div className="aboutcompanyname">
                        <h5>{fullText.jobTenant}</h5>
                        <div className="Sspace"></div>
                        <h5>Company Info</h5>
                        <p>{tenant}</p>
                        {/*<p>While it’s well known that unknown changes are the root cause of most stability issues, IT still struggles to know what actually changed.
                            Until now…Hammock lets you finally know all actual changes carried out in your environment.
                            It uses machine learning to detect and prioritize the most risky ones.
                            With Evolven IT Operations, DevOps and ITSM teams experience less incidents, faster MTTR, and improved productivity.
                            Hammock is a recognized IT Operations Analytics (ITOA) leader and was selected by Gartner as a Cool Vendor in IT Operations.
                            Hammock is also the winner of the Red Herring Top 100 North America.
                            TiE 50 Top Startup, 20 Most Promising Data Center Solution Providers, Banking CIO Outlook and ITOA50 awards For a free demo call 1 (866) 866-2320, or follow us on Twitter @hammock What People Are Saying  By offering a solution that is "change-centric",
                            Hammock has zeroed in on the dynamic nature of today's IT environments.
                        </p> */}
                  {/* </div> */}
                  {/* </div> */}
                  {/* </div> */}
                </div>
              </div>
            </div>
          </section>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
}

export default ShareJob;
