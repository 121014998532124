import {
    GET_TALENT_SUMMARY_BEGIN,
    GET_TALENT_SUMMARY_SUCCESS,
    GET_TALENT_SUMMARY_FAILURE
  } from "../../actions/executiveadmin/TalentSummary";
  
  const initialState = {
    talentSummaryLoading: true,
    talentSummary: [],
  };
  
  const TalentSummaryListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_TALENT_SUMMARY_BEGIN:
        return {
          ...state,
          talentSummaryLoading: true,
          talentSummary: [],
        };
      case GET_TALENT_SUMMARY_SUCCESS:
        return {
          ...state,
          talentSummary: payload,
          talentSummaryLoading: false,
        };
      case GET_TALENT_SUMMARY_FAILURE:
        return {
          ...state,
          talentSummary: [],
          talentSummaryLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default TalentSummaryListReducer;
  