import React, { useState } from "react";
import { countryObj } from "../../../../constants/Constants";
import { getUserRole } from "../../../../service/AuthService";

const JobDetailsTitle = (props) => {
  const [countryClick, setCountryClick] = useState(false);
  return (
    <div className="row justify-content-center">
      <div className="col-xl-12">
        <div className="text-center mb-4 font-white text-capitalize">
          <h4>{props.jobData.jobTitle}</h4>
          <p>
            {props.jobData?.jobType ? props.jobData?.jobType + "/" : ""}
            {props.jobData?.city &&
            props.jobData?.state &&
            props.jobData?.country &&
            !props.jobData?.isRemote ? (
              <>
                <span>{` ${props.jobData?.city}, ${props.jobData?.state}, ${props.jobData?.country} `}</span>
                <br />
                <p className="mt-1">
                  Placement Fee:{" "}
                  {props?.jobData?.isFeePercentage
                    ? `${props?.jobData?.placementFee}%`
                    : new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: props?.jobData?.placementCurrency
                          ? `${props?.jobData?.placementCurrency}`
                          : "USD",
                        maximumSignificantDigits: 3,
                      }).format(props?.jobData?.placementFee)}
                </p>
              </>
            ) : (
              <>
                {!countryClick ? (
                  <span
                    onClick={() => setCountryClick(true)}
                    style={{ cursor: "pointer" }}
                  >
                    {props.jobData.isRemote
                      ? `Remote 
                    (${
                      props.jobData?.isGlobal
                        ? "Global"
                        : props.jobData?.preferredLocation &&
                          JSON.parse(props.jobData.preferredLocation)?.length >
                            1
                        ? `${
                            JSON.parse(props.jobData?.preferredLocation)[0]
                          }...`
                        : JSON.parse(props.jobData.preferredLocation)
                        ? `${JSON.parse(props.jobData?.preferredLocation)[0]}`
                        : ""
                    })`
                      : ""}
                  </span>
                ) : (
                  <span
                    onClick={() => setCountryClick(false)}
                    style={{ cursor: "pointer" }}
                  >
                    {props.jobData.isRemote
                      ? `Remote 
                    (${
                      props.jobData?.isGlobal
                        ? "Global"
                        : props.jobData?.preferredLocation &&
                          JSON.parse(props.jobData.preferredLocation)?.join(
                            ", "
                          )
                    })`
                      : ""}
                  </span>
                )}
                <p>
                  Placement Fee:{" "}
                  {props.jobData.isFeePercentage
                    ? `${props.jobData.placementFee}%`
                    : new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: props.jobData.placementCurrency
                          ? `${props.jobData.placementCurrency}`
                          : "USD",
                        maximumSignificantDigits: 3,
                      }).format(props.jobData.placementFee)}
                </p>
              </>
            )}
          </p>

          <span class="tag tag-lg tag-font_accent d-inline-flex text-capitalize">
            <span class="mx-2">
              {props.jobData.jobStatusName == "Expired"
                ? "Halted"
                : props.jobData.jobStatusName == "Deactivated"
                ? "Closed"
                : props.jobData.jobStatusName}
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default JobDetailsTitle;
