import React from "react";

const Card = (props) => {
  const { elem, setView, view, cardStyle, isFrom } = props;
  return (
    <a
      style={{ background: `${elem?.bgColor}` }}
      href="#"
      onClick={(e) => {
        e.preventDefault();
        setView({
          high5TalentPool: elem.title === "High5 Talent",
          talents: elem.title === "Talent" || elem.title === "My Talent Pool",
          subPool: elem.title === "Sub Pool",
          publicTalentCommunity: elem.title === "Branded Talent Communities",
          talentCommunities: elem.title === "Talent Communities",
          recruiters: elem.title === "Gig Recruiters",
          clients: elem.title === "Clients",
          users: elem.title === "High5 Users",
          jobs: elem.title === "Jobs",
          curators: elem.title === "Curators",
          subCommunity:
            elem.title === "Talent Community" || elem.title === "Curation Task",
          curationTasks: elem.title === "Curation Tasks",
          curatorPool: elem.title === "Curator Pool",
        });
      }}
      className={`${
        isFrom == "MPMDashboard"
          ? "card card-hoverable card-lg MPMDashboardCards"
          : "card card-hoverable card-lg dashboardCards"
      }`}
    >
      <div className="card-body">
        <div className="">
          <div className={"d-flex justify-content-between align-item-center"}>
            <div className=" w-100">
              <h4 className="font-light mb-0">
                {new Intl.NumberFormat().format(elem.count)}
              </h4>{" "}
              <div className="d-flex justify-content-between w-100">
                <strong className="mb-0 font-18 "> {elem.heading}</strong>
                {isFrom === "recDashboard" && (
                  <span className="font-22">
                    {(elem.heading === "Jobs" && (
                      <i
                        className="fad fa-fw fa-briefcase "
                        aria-hidden="true"
                      ></i>
                    )) ||
                      (elem.heading === "Branded Talent Communities" && (
                        <i
                          className="fad fa-layer-group ml-1"
                          aria-hidden="true"
                        ></i>
                      )) ||
                      (elem.heading === "Curation Tasks" && (
                        <i
                          className="fad fa-layer-group ml-2"
                          aria-hidden="true"
                        ></i>
                      )) ||
                      (elem.heading === "My Talent Pool" && (
                        <i
                          className="fad fa-fw fa-users"
                          aria-hidden="true"
                        ></i>
                      ))}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-9">
          {isFrom !== "recDashboard" && (
            <div
              className={`d-flex  ${
                elem.changes[0] == "0"
                  ? "font-grey"
                  : elem.changes[0] === "-"
                  ? "font-red"
                  : "font-green"
              }`}
              style={{ alignItems: "center" }}
              title={elem?.analyticToolTip}
            >
              <h4 className="ml-2">
                <i
                  className={
                    elem.changes[0] !== "0"
                      ? `fad fa-sm ${
                          elem.changes[0] === "-"
                            ? "fa-arrow-down"
                            : "fa-arrow-up"
                        }`
                      : ""
                  }
                />
              </h4>
              <p className="ml-2">
                <small>{elem.changes} in last 30 days</small>
              </p>
            </div>
          )}
        </div>
        <div className="col-2 text-end  mx-2 mb-1 ">
          <span
            className="font-14 "
            data-bs-toggle="tooltip"
            data-bs-html="true"
            title={elem.tooltip}
          >
            <i class="fal fa-info-circle"></i>
          </span>
        </div>
      </div>

      {((view.high5TalentPool && elem.title === "High5 Talent") ||
        (view.curators && elem.title === "Curators") ||
        (view.talents && elem.title === "Talent") ||
        (view.talents && elem.heading === "Talent") ||
        (view.talents && elem.title === "My Talent Pool") ||
        (view.talents && elem.heading === "My Talent Pool") ||
        (view.talentCommunities && elem.title === "Talent Communities") ||
        (view.publicTalentCommunity &&
          elem.heading === "Branded Talent Communities") ||
        (view.recruiters && elem.title === "Gig Recruiters") ||
        (view.clients && elem.title === "Clients") ||
        (view.users && elem.title === "High5 Users") ||
        (view.subCommunity && elem.heading === "Talent Community") ||
        (view.subCommunity && elem.heading === "Curation Tasks") ||
        (view.subPool && elem.title === "Sub Pool") ||
        (view.curationTasks && elem.title === "Curation Tasks") ||
        (view.curatorPool && elem.title === "Curator Pool") ||
        (view.jobs && elem.heading === "Jobs")) && (
        <div
          className=""
          style={{
            backgroundColor: "#ff5f57", //ff5f57
            height: 3,
          }}
        ></div>
      )}
    </a>
  );
};

export default Card;
