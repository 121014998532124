import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import { post, get } from "../../../../../../service/ApiService";
import { Col, Row } from "react-bootstrap";
import RichTextEditor from "react-rte";

const Notes = (props) => {
  const { jobData, setLoading, setPoolData } = props;
  const [notes, setNotes] = useState("");
  const [noteList, setNoteList] = useState([]);

  useEffect(() => {
    if (jobData?.notes) {
      const parsedNotes = JSON.parse(jobData.notes);
      setNoteList(parsedNotes);
    }
  }, [jobData]);

  const handleDone = async () => {
    if (!notes.trim()) {
      toast.error("Please enter some notes before saving.");
      return;
    }

    setLoading(true);
    let obj = { note: notes };
    setNoteList([...noteList, obj]);
    const payload = {
      poolId: jobData?.poolId,
      notes: [...noteList, obj].filter((e) => e.note !== ""),
    };
    setNotes("");
    try {
      await post("/talent/createnotes", payload);
      let res = await get(`/talent/getPoolById?poolId=${jobData.poolId}`);
      let setData = res.data;
      setPoolData(setData);
      setLoading(false);
      toast.success("Note saved!");
    } catch (error) {
      toast.error("Oops! something went wrong. please try again later");
      setLoading(false);
    }
  };

  const handleDelete = async (index) => {
    setLoading(true);
    const updatedNotes = noteList.filter((_, i) => i !== index);
    const payload = {
      poolId: jobData.poolId,
      notes: updatedNotes,
    };
    try {
      await post("/talent/createnotes", payload).then(async (resp) => {
        if (resp.status == 200) {
          await get(`/talent/getPoolById?poolId=${jobData.poolId}`).then(
            (res) => {
              let setData = res.data;
              setPoolData(setData);
              setNoteList(updatedNotes);
              setLoading(false);
              toast.success("Note deleted!");
            }
          );
        }
      });
    } catch (error) {
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  const modules = {
    toolbar: [
      [{ size: [] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const viewModules = {
    toolbar: false, // Disable the toolbar
  };

  const handleQuillChange = (value) => {
    // If the value is <p><br></p>, set it to an empty string
    if (value === "<p><br></p>") {
      value = "";
    }
    setNotes(value);
  };

  return (
    <>
      {jobData?.notes &&
        JSON.parse(jobData?.notes)?.map((e, index) => (
          <div
            key={index}
            className="card flex-d card-flat bg-gray4 mb-2"
            style={{ flexDirection: "row", justifyContent: "space-between" }}
          >
            {/* <div
              style={{ margin: "10px 0px 0px 10px" }}
              dangerouslySetInnerHTML={{ __html: e.note }}
            ></div> */}
            <ReactQuill
              value={e.note}
              // onChange={handleQuillChange}
              disable
              style={{
                fontWeight: "normal",
                // height: "100px",
                borderRadius: "2px",
                border: "none",
                width: "90%",
              }}
              theme="snow"
              row={6}
              className="no-border mb-2 mt-3"
              modules={viewModules}
            />

            <button
              type="button"
              style={{ textAlign: "end" }}
              onClick={() => handleDelete(index)}
              className="btn btn-text-accent mr-2"
              title="Delete"
            >
              <i
                className="fal fa-trash-alt fa-fw"
                aria-hidden="true"
                style={{ color: "#ff2600" }}
              ></i>
            </button>
          </div>
        ))}
      <Row style={{ minHeight: "200px" }}>
        <Col md={12} sm={12}>
          <ReactQuill
            value={notes}
            onChange={handleQuillChange}
            style={{
              fontWeight: "normal",
              height: "100px",
              borderRadius: "2px",
            }}
            theme="snow"
            row={6}
            className="bordered mb-2 mt-3"
            modules={modules}
          />
        </Col>
        <Col md={12} sm={12}>
          <button
            type="button"
            className="btn btn-sm btn-primary"
            onClick={handleDone}
            style={{ float: "right", marginTop: "3rem" }}
          >
            <i class="fal fa-fw fa-check mr-1" aria-hidden="true"></i>
            Done
          </button>
        </Col>
      </Row>
    </>
  );
};

export default Notes;
