import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import AddJobDetails from "../../addjob/addjobtype/JobDetails";
import { getJobDetails } from "../../../../../actions/job/JobDetails";
import { getDispositionDetails } from "../../../../../actions/disposition/Disposition";

const ReopenJob = ({ jobData }) => {
  const dispatch = useDispatch();
  const [editJob, setEditJob] = useState(false);
  const handleEditClick = () => {
    setEditJob(true);
  };
  const refreshJobDetails = () => {
    setEditJob(false)
    const payload = {
      jobId: jobData.jobId
    };
    const payloadDisposition = {
      jobId: jobData.jobId,
      status:"total",
      limit:10,
      currentPage:1
    };
    dispatch(getJobDetails(payload));
   // dispatch(getDispositionDetails(payloadDisposition));
  }
  return (
    <>
      <div
        className="dropdown px-1"
        style={{
          fontSize: "17px",
          cursor: "pointer",
        }}
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Reopen Job"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={() => handleEditClick()}
      >
        <i className="far fa-redo-alt btn btn-icon"></i>
      </div>
      <Modal show={editJob} size="lg">
        <Modal.Header>
          <h6 class="">Reopen Job</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setEditJob(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <AddJobDetails
            reopen={true}
            edit = {true}
            editJobData={jobData}
            closePanel={() => setEditJob(false)}
            refreshJobDetails={refreshJobDetails}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReopenJob;
