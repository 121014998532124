import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddJobHereMap from "../../location/AddJobHereMap";
import Supplier from "../../common/supplier/Supplier";
import Autosuggest from "react-autosuggest";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import moment from "moment";
import SupplierTable from "../../common/supplier/SupplierTable";
import RichTextEditor from "react-rte";
import { getUserTenant } from "../../../service/AuthService";

function AddJobContract(props) {
  let tenantName = getUserTenant();
  const date = new Date();
  const dropdownList = props.dropdownList;
  const [startDate, setStartDate] = useState();
  const [activeFrom, setActiveFrom] = useState();
  const [expiresOn, setExpiresOn] = useState();
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  const [secondarySkillsInput, setSecondarySkillSInput] = useState("");
  const [certificateInput, setCertificateInput] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [skillSet, setSkillSet] = useState([]);
  const [isRemote, setIsRemote] = useState(false);
  const [isHotJob, setIsHotJob] = useState(false);
  const [isFeePercentage, setIsFeePercentage] = useState(false);
  const [isUploader, setIsUploader] = useState(true);
  let [numberOfPositions, setNumberOfPositions] = useState(1);
  let [allowedSubmittals, setAllowedSubmittals] = useState(2);
  const [tierData, setTierData] = useState({});
  const visaStatus = [
    "Green Card",
    "H1B",
    "Citizen",
    "H4 EAD",
    "GC EAD",
    "L2EAD",
    "OPT EAD",
    "TN1",
    "TN2",
    "Not Required",
  ];
  const [value, setValue] = useState(RichTextEditor.createEmptyValue());
  const onChange = (value) => {
    setValue(value);
    props.setAddJobData({
      ...props.addJobData,
      description: value.toString("html"),
    });
  };
  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: [
      "INLINE_STYLE_BUTTONS",
      "BLOCK_TYPE_BUTTONS",
      "LINK_BUTTONS",
      "BLOCK_TYPE_DROPDOWN",
      "HISTORY_BUTTONS",
    ],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "custom-css-class" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  const handleDate = (e) => {
    props.setAddJobData({
      ...props.addJobData,
      [e.target.name]: e.target.value,
    });
  };

  const handleChange = (e) => {
    props.setAddJobData({
      ...props.addJobData,
      [e.target.name]: e.target.value,
      isHotJob: isHotJob,
      // startDate: moment(startDate).format('DD-MMM-YYYY'),
      // activeFrom: moment(activeFrom).format('DD-MMM-YYYY'),
      // expiresOn: moment(expiresOn).format('DD-MMM-YYYY'),
    });
  };

  const SupplierChange = (e) => {
    let isJobPublic = e.target.value;

    if (isJobPublic === "true") {
      props.setAddJobData({
        ...props.addJobData,
        isPublic: true,
      });
    } else {
      props.setAddJobData({
        ...props.addJobData,
        isPublic: false,
      });
    }
  };
  const handleNumberOfPositions = (e) => {
    setNumberOfPositions(e.target.value);
    setAllowedSubmittals(e.target.value * 2);

    props.setAddJobData({
      ...props.addJobData,
      numberOfPositions: e.target.value,
      allowedSubmittals: e.target.value * 2,
    });
  };
  const handleAllowSubmittals = (e) => {
    props.setAddJobData({
      ...props.addJobData,

      allowedSubmittals: e.target.value,
    });
  };

  const handleChangePrimarySkills = (skills) => {
    props.setAddJobData({ ...props.addJobData, primarySkills: skills });
  };

  const handleChangeInputPrimarySkills = (skills) => {
    setPrimarySkillInput(skills);
  };

  const handleChangeSecondarySkills = (skills) => {
    props.setAddJobData({ ...props.addJobData, secondarySkills: skills });
  };

  const handleChangeInputSecondarySkills = (skills) => {
    setSecondarySkillSInput(skills);
  };

  const handleChangeJobDescription = (jobDescription) => {
    setJobDescription(jobDescription);
  };

  let certificate_new = [];
  const isCertified = (e) => {
    certificate_new.push(e.target.value);
    props.setAddJobData({
      ...props.addJobData,
      certifications: certificate_new,
    });
  };

  const handleChangeInputJobDescription = (jobDescription) => {
    props.setAddJobData({
      ...props.addJobData,
      jobDescription: jobDescription,
    });
  };

  const skillSetOption = dropdownList.skillSet.map((item) => {
    const skillSetObj = { value: item.PositionName, label: item.PositionName };
    return skillSetObj;
  });

  const QualificationType = dropdownList.QualificationType.filter(
    (item) => item.PositionName !== "Select"
  ).map((item) => {
    const QualificationObj = {
      value: item.PositionName,
      label: item.PositionName,
    };
    return QualificationObj;
  });

  const handleSkillSet = (e) => {
    setSkillSet(e);
    props.setAddJobData({
      ...props.addJobData,
      skillSet: e.map((skillSet) => skillSet.value),
    });
  };

  const setFileForSending = (file) => {
    const { name } = file;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      props.setAddJobData({
        ...props.addJobData,
        fileName: name,
        base64: event.target.result,
      });
      toast.success("File uploaded successfully");
    };
  };

  const onRemove = (e) => {
    props.setAddJobData({
      ...props.addJobData,
      fileName: " ",
      base64: " ",
    });
    toast.success("File removed successfully");
  };

  const onDragDropChangeHandler = (e) => {
    setFileForSending(e[0]);
  };

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 200,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.PositionName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropdownList.skills.filter((item) =>
              item.PositionName.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => (
      <div>{suggestion.PositionName}</div>
    );

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.PositionName);
        }}
        theme={theme}
      />
    );
  };

  const handleEducation = (e) => {
    props.setAddJobData({
      ...props.addJobData,
      education: e.map((QualificationType) => QualificationType.value),
    });
  };

  const isPercentage = (e) => {
    props.setAddJobData({
      ...props.addJobData,
      isFeePercentage: e.target.checked,
      placementFee: "",
    });
    setIsFeePercentage(e.target.checked);
  };

  const onHotJobSwitch = (e) => {
    e.preventDefault();
    setIsHotJob(e.target.checked);
  };

  const customStyles = {
    multiValue: (styles) => {
      return {
        ...styles,
        backgroundColor: "#dcedff",
      };
    },
  };

  useEffect(() => {
    props.setAddJobData({ ...props.addJobData, tierData });
  }, [tierData]);

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="text-right mb-1">
            <small>* Denotes Required Field</small>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      name="title"
                      type="text"
                      className="form-control"
                      placeholder="Enter a job title"
                      onChange={handleChange}
                    />
                    <label>Job Title*</label>
                  </div>
                  <small className="validation">
                    {props.addJobContractVal.title}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="companyName"
                      defaultValue={tenantName}
                      onChange={handleChange}
                      placeholder="Enter a Client Name"
                    />
                    <label>Client Name*</label>
                  </div>
                  <small className="validation">
                    {props.addJobContractVal.companyName}
                  </small>
                </div>
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="companyJobId"
                      onChange={handleChange}
                      placeholder="Enter a Client Job Id"
                    />
                    <label>Client JobID</label>
                  </div>
                  {/* <small className="validation">
                    {props.addJobContractVal.companyJobId}
                  </small> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-4 d-flex align-items-center mb-2 mb-lg-0 justify-content-end justify-content-lg-start">
                  <div className="form-check form-switch d-flex align-items-center">
                    <label
                      className="form-check-label pt-0"
                      htmlFor="remoteSwitch"
                    >
                      Is Hot Job?
                    </label>
                    <input
                      name="isHotJob"
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="hotJobSwitch"
                      onChange={(e) => onHotJobSwitch(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <AddJobHereMap
            isRemote={isRemote}
            setIsRemote={setIsRemote}
            addJobData={props.addJobData}
            setAddJobData={props.setAddJobData}
          />

          {!props.addJobData.isRemote && (
            <div className="card card-flat card-borderless bg-gray4 mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                        defaultValue={props.addJobData.location.address}
                        placeholder=""
                      />
                      <label>Address</label>
                    </div>
                  </div>
                  <div className="col-lg-2 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="city"
                        defaultValue={props.addJobData.location.city}
                        placeholder=""
                      />
                      <label>City*</label>
                    </div>
                    <small className="validation">
                      {props.addJobContractVal.city}
                    </small>
                  </div>
                  <div className="col-lg-2 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="state"
                        defaultValue={props.addJobData.location.state}
                        placeholder=""
                      />
                      <label>State*</label>
                    </div>
                    <small className="validation">
                      {props.addJobContractVal.state}
                    </small>
                  </div>
                  <div className="col-lg-2 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="postal"
                        defaultValue={props.addJobData.location.postalCode}
                        placeholder=""
                      />
                      <label>Postal*</label>
                    </div>
                    <small className="validation">
                      {props.addJobContractVal.postalCode}
                    </small>
                  </div>
                  <div className="col-lg-2 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        name="country"
                        defaultValue={props.addJobData.location.country}
                        placeholder=""
                      />
                      <label>Country*</label>
                    </div>
                    <small className="validation">
                      {props.addJobContractVal.country}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-12">
                      <p className="mb-1">Preferred start date*</p>
                      <div className="form-group form-date">
                        <input
                          type="date"
                          name="startDate"
                          value={moment(
                            new Date(props.addJobData.startDate)
                          ).format("YYYY-MM-DD")}
                          onChange={handleChange}
                          className="form-control"
                          min={moment(
                            new Date(date.setDate(date.getDate() + 15))
                          ).format("YYYY-MM-DD")}
                          max="2050-12-31"
                        />
                        {/* <DatePicker
                          selected={startDate}
                          name="startDate"
                          className="custom-date-input"
                          placeholder="Enter preferred start date"
                          onChange={(date) => setStartDate(date)}
                        /> */}
                      </div>
                      <small className="validation">
                        {props.addJobContractVal.startDate}
                      </small>
                    </div>
                  </div>
                </div>
                {props.addJobData.type === "Contract" && (
                  <div className="col-lg-6">
                    <div className="row">
                      <div className="col-lg-12">
                        <p className="mb-1">Contract Duration*</p>
                        <div className="row">
                          <div className="col-lg-6 mb-2 mb-lg-0">
                            <div className="form-floating">
                              <input
                                name="durationMonths"
                                type="number"
                                className="form-control"
                                placeholder="Months"
                                min="1"
                                onChange={handleChange}
                              />
                              <label>Months</label>
                            </div>
                            <small className="validation">
                              {props.addJobContractVal.durationMonths}
                            </small>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-floating">
                              <input
                                name="durationDays"
                                type="number"
                                className="form-control"
                                placeholder="Days"
                                min="1"
                                onChange={handleChange}
                              />
                              <label>Days</label>
                            </div>
                            <small className="validation">
                              {props.addJobContractVal.durationDays}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 mb-2 mb-lg-0 d-flex">
                  <div className="form-floating w-50 mr-1">
                    <input
                      name="numberOfPositions"
                      type="number"
                      min="1"
                      className="form-control"
                      placeholder="Enter Total Number of Openings"
                      defaultValue={props.addJobData.numberOfPositions}
                      onChange={handleNumberOfPositions}
                    />
                    <label>Number of Openings</label>
                    <small>This number cannot be zero</small>
                  </div>
                  <div className="form-floating w-50">
                    <input
                      name="allowedSubmittals"
                      type="number"
                      min="2"
                      //disabled
                      className="form-control"
                      placeholder="Enter Total Number of Allowed Submittals"
                      value={props.addJobData.allowedSubmittals}
                      onChange={handleAllowSubmittals}
                    />
                    <label>Allowed submittals </label>
                    {props.addJobContractVal.allowedSubmittals ? (
                      <small className="validation ml-1">
                        {props.addJobContractVal.allowedSubmittals}
                      </small>
                    ) : (
                      <small>This number cannot be zero</small>
                    )}
                  </div>
                </div>

                <div className="col-lg-6 d-flex">
                  <div className="w-50">
                    <div className="form-floating input-group">
                      {/* <span className="input-group-text">
                        {props.addJobData.location.currSymbol}
                      </span> */}
                      <input
                        name="hourlyRate"
                        type="number"
                        className="form-control"
                        aria-label="Amount (to the nearest dollar)"
                        onChange={handleChange}
                        value={props.addJobData.hourlyRate}
                      />
                      <label>Max Hourly Rate*</label>
                    </div>
                    <small>
                      Enter the maximum hourly rate you are willing to pay for
                      this contractor
                    </small>
                    <br />
                    <small className="validation">
                      {props.addJobContractVal.hourlyRate}
                    </small>
                  </div>
                  <div className="form-floating w-50 ml-1">
                    <select
                      className="form-select"
                      name="salaryCurrency"
                      onChange={handleChange}
                    >
                      {/* <option disabled selected>Select</option> */}
                      {props.dropdownList.Currency.map((currency, index1) => {
                        return (
                          <option
                            key={index1}
                            selected={
                              props.addJobData.hourlyRateCurrency ===
                              currency.PositionName
                            }
                          >
                            {currency.PositionName}
                          </option>
                        );
                      })}
                    </select>
                    <label>Currency*</label>
                    <small className="validation">
                      {props.addJobContractVal.hourlyRateCurrency}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-floating form-date">
                    <label>Active from*</label>
                    <input
                      type="date"
                      name="activeFrom"
                      value={moment(
                        new Date(props.addJobData.activeFrom)
                      ).format("YYYY-MM-DD")}
                      onChange={handleChange}
                      className="form-control"
                      min={moment(new Date()).format("YYYY-MM-DD")}
                      max="2050-12-31"
                    />
                    {/* <DatePicker
                      selected={activeFrom}
                      name="activeFrom"
                      className="custom-date-input"
                      placeholder="Enter the active date"
                      onChange={(date) => setActiveFrom(date)}
                    /> */}
                  </div>
                  <small className="validation">
                    {props.addJobContractVal.activeFrom}
                  </small>
                </div>
                <div className="col-lg-6">
                  <div className="form-floating form-date">
                    <label>Expires on*</label>
                    <input
                      type="date"
                      name="expiresOn"
                      value={moment(
                        new Date(props.addJobData.expiresOn)
                      ).format("YYYY-MM-DD")}
                      onChange={handleChange}
                      className="form-control"
                      min={moment(
                        new Date(new Date().setDate(new Date().getDate() + 1))
                      ).format("YYYY-MM-DD")}
                      max="2050-12-31"
                    />
                    {/* <DatePicker
                      selected={expiresOn}
                      name="expiresOn"
                      className="custom-date-input"
                      placeholder="Enter the expire date"
                      onChange={(date) => setExpiresOn(date)}
                    /> */}
                  </div>
                  <small className="validation">
                    {props.addJobContractVal.expiresOn}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 mb-2 mb-lg-0">
                  <div className="d-flex mb-1">
                    <p className="mb-1">Placement fee*</p>
                    <div className="form-check form-switch d-flex align-items-center ml-auto">
                      <label className="form-check-label pt-0" htmlFor="Switch">
                        Show fee in percentage?
                      </label>
                      <input
                        name="isFeePercentage"
                        className="form-check-input ml-3"
                        type="checkbox"
                        id="Switch"
                        onChange={isPercentage}
                      />
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="form-floating w-50">
                      <input
                        name="placementFee"
                        type="number"
                        min="1"
                        value={props.addJobData.placementFee}
                        max={isFeePercentage && "100"}
                        className="form-control"
                        placeholder={
                          isFeePercentage
                            ? "Enter Amount %"
                            : "Enter Amount Fee"
                        }
                        onChange={handleChange}
                      />
                      <label>
                        {" "}
                        {isFeePercentage ? "Percentage %" : "Amount"}
                      </label>
                      <small className="validation">
                        {props.addJobContractVal.placementFee}
                      </small>
                    </div>
                    {!isFeePercentage && (
                      <div className="form-floating w-50 ml-1">
                        <select className="form-select">
                          {dropdownList !== null ? (
                            dropdownList.Currency.map((currency, index1) => {
                              return (
                                <option key={index1}>
                                  {currency.PositionName}
                                </option>
                              );
                            })
                          ) : (
                            <option> select </option>
                          )}
                        </select>
                        <label>Currency</label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-6 mb-2 mb-lg-0">
                  <p className="mb-2">Candidate Referral Bonus</p>
                  <div className="d-flex">
                    <div className="form-floating">
                      <input
                        name="referralFee"
                        type="number"
                        min="1"
                        className="form-control"
                        placeholder="Enter Referral Fee"
                        onChange={handleChange}
                      />
                      <label>Amount</label>
                    </div>
                    <div className="form-floating w-50 ml-1">
                      <select
                        className="form-select"
                        name="placementCurrency"
                        onChange={handleChange}
                      >
                        {dropdownList !== null ? (
                          dropdownList.Currency.map((currency, index1) => {
                            return (
                              <option key={index1}>
                                {currency.PositionName}
                              </option>
                            );
                          })
                        ) : (
                          <option> select </option>
                        )}
                      </select>
                      <label>Currency</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body">
              <div className="form-floating">
                <select
                  className="form-select"
                  onChange={(e) => SupplierChange(e)}
                  name="editPreferred"
                >
                  <option selected={props.addJobData.isPublic} value="true">
                    No release directly to public
                  </option>
                  <option value="false">Yes</option>
                </select>
                <label>Use Preferred Suppliers?</label>
              </div>

              {!props.addJobData.isPublic && (
                <div className="card card-flat">
                  <small className="validation">
                    {props.addJobContractVal.tierError}
                  </small>
                  <div className="card-body">
                    {" "}
                    <SupplierTable
                      addJobData={props.addJobData}
                      setTierData={setTierData}
                    />{" "}
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* Skills */}

          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="form-floating">
                <div className="form-group">
                  <label>Primary Skills*</label>
                  {/* <input  name="primarySkills" placeholder='Type Primary Skills' value="" onChange={handleChange}/> */}
                  <TagsInput
                    renderInput={autocompleteRenderInput}
                    value={props.addJobData.primarySkills}
                    onChange={handleChangePrimarySkills}
                    inputValue={primarySkillinput}
                    onChangeInput={handleChangeInputPrimarySkills}
                    placeholder="Type a location and hit enter"
                    inputProps="Type a location and hit enter"
                  />
                </div>
              </div>
              <small className="validation">
                {props.addJobContractVal.primarySkills}
              </small>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="form-floating">
                <div className="form-group">
                  <label>Secondary Skills</label>
                  <TagsInput
                    renderInput={autocompleteRenderInput}
                    value={props.addJobData.secondarySkills}
                    onChange={handleChangeSecondarySkills}
                    inputValue={secondarySkillsInput}
                    onChangeInput={handleChangeInputSecondarySkills}
                    placeholder="Type a location and hit enter"
                    inputProps="Type a location and hit enter"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <label> SkillSets</label>
              <div className="form-floating Hover-Dropdown">
                <Select
                  isMulti
                  options={skillSetOption}
                  name="placementSkill"
                  onChange={handleSkillSet}
                  isSearchable={false}
                ></Select>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body pt-0">
              <p className="mb-2">
                <label>
                  Enter the job description or
                  <button
                    type="button"
                    onClick={() => setIsUploader(!isUploader)}
                    className="btn btn-text"
                  >
                    <strong> upload a job description document</strong>
                  </button>
                </label>
              </p>
              <small>
                Accepted File Type: <strong>.docx, .pdf</strong>
              </small>
              {isUploader === true ? (
                <div className="form-floating">
                  <RichTextEditor
                    toolbarConfig={toolbarConfig}
                    value={value}
                    onChange={onChange}
                  />
                </div>
              ) : (
                <div className="form-floating">
                  <Dropzone onDrop={onDragDropChangeHandler}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <div class="talentuploadmaindiv">
                            <div class="talentuploadicon">
                              <label>
                                <i class="fas fa-cloud-upload-alt"></i>
                              </label>
                              <input
                                type="file"
                                name="myfile"
                                {...getInputProps()}
                              />
                            </div>
                            {props.addJobData.fileName ? (
                              <div class="talentuploadtext">
                                {props.addJobData.fileName}
                              </div>
                            ) : (
                              <div class="talentuploadtext">
                                Click or Drag and Drop to Upload
                              </div>
                            )}
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {props.addJobData.fileName && (
                    <div class="modal-footer mt-4 mb-0">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        onClick={onRemove}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body">
              <label>Submittal Instructions</label>
              <div className="form-floating">
                <textarea
                  className="form-control addjob-sunmittal"
                  placeholder="Leave submittal instructions"
                  name="Submittal"
                  onChange={handleChange}
                ></textarea>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body py-0">
              <div className="form-floating">
                <div className="form-group text-left">
                  <label className="mb-2">Required education</label>
                  <div className="form-floating Hover-Dropdown">
                    <Select
                      isMulti
                      options={QualificationType}
                      // value={props.addJobData.education}
                      name="Qualification"
                      onChange={(e) => handleEducation(e)}
                      isSearchable={true}
                    ></Select>
                  </div>
                  {/* <input
                    name="title"
                    type="text"
                    className="form-control"
                    placeholder="Enter required education"
                    onChange={(e) => isEducation(e)}
                    value={props.addJobData.education}
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="card card-flat bg-gray4 mb-3">
            <div className="card-body py-0">
              <div className="form-floating p-1">
                <div className="">
                  <label>Required Certifications</label>
                  <TagsInput
                    value={props.addJobData.certifications}
                    onChange={(c) =>
                      props.setAddJobData({
                        ...props.addJobData,
                        certifications: c,
                      })
                    }
                    inputValue={certificateInput}
                    onChangeInput={(input) => setCertificateInput(input)}
                    inputProps={{ placeholder: "Add Certificate" }}
                    className="form-control m-1"
                    inputClass="w-100"
                  />
                </div>
                <small className="ml-2">
                  Type a Certificate above and hit Enter
                </small>
              </div>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3 ">
            <div className="card-body">
              <div class="form-floating">
                <select
                  id="visaStatus"
                  name="visaRequirement"
                  class="font-14 form-select"
                  onChange={handleChange}
                >
                  {/* <option value='' selected>Select Status</option> */}
                  {visaStatus.map((item) => (
                    <option
                      selected={item === props.addJobData.visaRequirement}
                      value={item}
                    >
                      {item}
                    </option>
                  ))}
                </select>
                <label>Visa Requirement</label>
              </div>
            </div>
          </div>
          <div className="card card-flat card-borderless bg-gray4 mb-3">
            <div className="card-body">
              <div className="row">
                <div class="col-lg-6">
                  <div className="form-floating">
                    <input
                      name="licenceRequirement"
                      type="text"
                      className="form-control"
                      placeholder="Enter Licence"
                      onChange={handleChange}
                      value={props.addJobData.licenceRequirement}
                    />
                    <label className="mb-2">License Requirement</label>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      name="experienceLevel"
                      value={props.addJobData.experienceLevel}
                      onChange={handleChange}
                    >
                      {dropdownList.ExperienceLevel.map((item) => (
                        <option
                          selected={props.addJobData.experienceLevel.includes(
                            item.PositionName
                          )}
                          value={item.PositionName}
                        >
                          {item.PositionName}
                        </option>
                      ))}
                    </select>
                    <label>Required experience</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddJobContract;
