import {
  GET_JOBS_HEADER_COUNT_BEGIN,
  GET_JOBS_HEADER_COUNT_SUCCESS,
  GET_JOBS_HEADER_COUNT_FAILURE,
  GET_COPY_CLONE_JOBS_HEADER_COUNT_BEGIN,
  GET_COPY_CLONE_JOBS_HEADER_COUNT_SUCCESS,
  GET_COPY_CLONE_JOBS_HEADER_COUNT_FAILURE,
} from "../../actions/job/JobListHeaderCounts";

const initialState = {
  jobListCountsLoading: true,
  jobListCounts: [],
  copyCloneJobListCounts: [],
  copyCloneJobListCountsLoading: true,
};

const jobListCountReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_JOBS_HEADER_COUNT_BEGIN:
      return {
        ...state,
        jobListCountsLoading: true,
        jobListCounts: [],
      };
    case GET_JOBS_HEADER_COUNT_SUCCESS:
      return {
        ...state,
        jobListCounts: payload,
        jobListCountsLoading: false,
      };
    case GET_JOBS_HEADER_COUNT_FAILURE:
      return {
        ...state,
        jobListCounts: [],
        jobListCountsLoading: false,
      };
    case GET_COPY_CLONE_JOBS_HEADER_COUNT_BEGIN:
      return {
        ...state,
        copyCloneJobListCountsLoading: true,
        copyCloneJobListCounts: [],
      };
    case GET_COPY_CLONE_JOBS_HEADER_COUNT_SUCCESS:
      return {
        ...state,
        copyCloneJobListCounts: payload,
        copyCloneJobListCountsLoading: false,
      };
    case GET_COPY_CLONE_JOBS_HEADER_COUNT_FAILURE:
      return {
        ...state,
        copyCloneJobListCounts: [],
        copyCloneJobListCountsLoading: false,
      };
    default:
      return state;
  }
};

export default jobListCountReducer;
