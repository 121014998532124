import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const GET_JOB_SUMMARY_BEGIN = "GET_JOB_SUMMARY_BEGIN";
export const GET_JOB_SUMMARY_SUCCESS = "GET_JOB_SUMMARY_SUCCESS";
export const GET_JOB_SUMMARY_FAILURE = "GET_JOB_SUMMARY_FAILURE";

export const GET_JOB_SUMMARY_COUNT_BEGIN = "GET_JOB_SUMMARY_COUNT_BEGIN";
export const GET_JOB_SUMMARY_COUNT_SUCCESS = "GET_JOB_SUMMARY_COUNT_SUCCESS";
export const GET_JOB_SUMMARY_COUNT_FAILURE = "GET_JOB_SUMMARY_COUNT_FAILURE";

export const GET_CLIENT_NAME_BEGIN = "GET_CLIENT_NAME_BEGIN";
export const GET_CLIENT_NAME_SUCCESS = "GET_CLIENT_NAME_SUCCESS";
export const GET_CLIENT_NAME_FAILURE = "GET_CLIENT_NAME_FAILURE";

export const getClientJobSummary = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_SUMMARY_BEGIN,
    });
    const res = await post("/dashboard/sa/getclientjobsummary", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_JOB_SUMMARY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOB_SUMMARY_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getClientJobSummaryCount = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_SUMMARY_COUNT_BEGIN,
    });
    const res = await post("/dashboard/sa/getclientjobsummarycount", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_JOB_SUMMARY_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOB_SUMMARY_COUNT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getClientNameData = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CLIENT_NAME_BEGIN,
    });
    const res = await post("/dashboard/sa/getclientnamedata", params);
    if (res.status === 200) {
      dispatch({
        type: GET_CLIENT_NAME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CLIENT_NAME_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
