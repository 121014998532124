import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactPaginate from "react-paginate";
import TopNavBar from "./TopNavBar";
import SideNavBar from "../layout/SideNavBar";
import ListView from "./ListView";
import Loader from "../Loader/Loader";
import { getCandidateName, getTenantType } from "../../service/AuthService";
import GigRecruiterList from "./GigRecruiterList";
import CMTalentList from "./CMTalentList";
import GigRecruiterCard from "./DashboardAnalyticsData/GigRecruiterCard";
import StaffingAgenciesCard from "./DashboardAnalyticsData/StaffingAgenciesCard";
import SummaryCard from "./DashboardAnalyticsData/SummaryCard";
import { isSupportedCountry } from "libphonenumber-js";
import { get, post } from "../../service/ApiService";
import moment from "moment";
import RegisteredRecruiters from "./RegisteredRecruiters";
import EmailStatus from "../components_v3/EmailStatus/EmailStatus";
import { getTalentList } from "../../actions/talent/talentList";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Footer from "../layout/Footer";

const CMDashboard = () => {
  const dispatch = useDispatch();
  const { talentData, loading } = useSelector(
    (state) => ({
      talentData: state.TalentList.talentData,
      loading: state.TalentList.loading,
    }),
    shallowEqual
  );

  const [listView, setListView] = useState(true);
  const [emailStatus, setEmailStatus] = useState(false);
  const [emailData, setEmailData] = useState("");

  const [list, setList] = useState(0);
  const [gridView, setGridView] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [addClient, setAddClient] = useState(false);
  const [client, setClient] = useState(false);
  const [talent, setTalent] = useState(false);
  const [userData, setUserData] = useState([]);
  const [candidateData, setCandidateData] = useState([]);
  const [requestData, setRequestData] = useState([]);
  const [tenantData, setTenantData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [tenantDetails, setTenantDetails] = useState([]);
  const [supplierShowCount, setSupplierShowCount] = useState(10);
  const [reviewSupplierData, setReviewSupplierData] = useState([]);
  const [tenant, setTenant] = useState("");
  const [pageShowCountView, setPageShowCountView] = useState(1);
  const [clientAllData, setClientAllData] = useState([]);
  const [searchEmployee, setSearchEmployee] = useState("");
  const [clientData, setClientData] = useState([]);
  const [totalClient, setTotalClient] = useState(0);
  const [totalTenant, setTotalTenant] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [showingCount, setShowingCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageShowCount, setPageShowCount] = useState(25);
  const [currentPage, setCurrentPage] = useState(0);
  const [gigRecruiters, setGigRecruiters] = useState([]);
  const [dataLimit, setDataLimit] = useState(10);
  const [gigrecruiter, setGigRecruiter] = useState(true);
  const [toggle, setToggle] = useState(false);
  // const [talentData, setTalentData] = useState([]);
  const [gigCount, setGigCount] = useState([]);
  const [approvedGig, setApprovedGig] = useState([]);
  const [gigStaffCount, setGigStaffCount] = useState([]);
  const [searchTenant, setSearchTenant] = useState("");
  const [staffingAgency, setStaffingAgency] = useState(0);
  const [name, setName] = useState([]);
  const [userType, setUserType] = useState([]);
  const [source, setSource] = useState([]);
  const [status, setStatus] = useState([]);
  const [country, setCountry] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [userLoading, setUserLoading] = useState(false);
  const [tenantLoading, setTenantLoading] = useState(false);
  const [gigLoading, setGigLoading] = useState(false);
  const [candidateLoading, setCandidateLoading] = useState(false);
  const [inactive, setInactive] = useState(false);
  const [inActiveGigRecruiterList, setInactiveGigRecruiterList] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [freeLancers, setFreeLancers] = useState([]);
  const [freeLancersCount, setFreeLancersCount] = useState(0);
  const [freeLancersTab, setFreeLancersTab] = useState(false);
  const [freeLancersDetails, setFreeLancersDetails] = useState([]);
  const [allRec, setAllRec] = useState([]);
  const [registeredRecruiter, setRegisteredRecruiter] = useState([]);
  const [isRegisteredRecruiter, setIsRegisteredRecruiter] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [employeeLoading, setEmployeeLoading] = useState(false);

  const inActiveGigRecruiter = gigCount.filter((val) => {
    let today = moment();
    let lastLogin = moment(val.LastLogin);
    return (
      val.status === "Approved" &&
      (val.LastLogin == null || today.diff(lastLogin, "days") > 5)
    );
  });
  useEffect(() => {
    if (!talentData) {
      let payload = {
        currentPage: 1,
        limit: 10,
        status: null,
        tab: "All",
        sortParam: [],
      };
      dispatch(getTalentList(payload));
    }
  }, [talentData]);

  useEffect(() => {
    getUsersData();
    getTenantData();
    fetchGigRecruiters();
    // fetchCandidate();
    fetchRegisteredRecruiters();
    fetchEmpDetails();
  }, []);

  useEffect(() => {
    if (list === 0) setAllChecked(false);
    else if (list === inActiveGigRecruiter.length) setAllChecked(true);
  }, [list]);

  useEffect(() => {
    inActiveGigRecruiterWithChecked();
  }, [gigCount]);

  const inActiveGigRecruiterWithChecked = () => {
    let arr = inActiveGigRecruiter.map((item) => ({ ...item, checked: false }));
    setInactiveGigRecruiterList(arr);
  };

  const checkAllForReminder = () => {
    let check = allChecked;
    setAllChecked(!check);
    let arr = inActiveGigRecruiter.map((item) => ({
      ...item,
      checked: !check,
    }));

    setInactiveGigRecruiterList(arr);
  };

  useEffect(() => {
    !allChecked && setList(0);
    allChecked && setList(inActiveGigRecruiter.length);
  }, [allChecked]);
  const checkOneForReminder = (item) => {
    let arr = inActiveGigRecruiterList.map((gig) => {
      if (item.id_addgig === gig.id_addgig) {
        setList(gig.checked ? list - 1 : list + 1);
        return {
          ...gig,
          checked: !gig.checked,
        };
      } else return gig;
    });

    setInactiveGigRecruiterList(arr);
  };

  const url = "/lastLogin/recReminder";
  const sendReminderMail = async (url, body) => {
    setGigLoading(true);
    try {
      const recData = await post(url, body);
      if (recData.status === 200) {
        toast.success("Reminder email sent successfully");
        setInactive(false);
        setGigLoading(false);
        inActiveGigRecruiterWithChecked();
        setAllChecked(false);
      }
    } catch (error) {
      setGigLoading(false);
    }
  };

  const handleSendReminder = () => {
    let recruiterList = [];
    inActiveGigRecruiterList.map((item) => {
      if (item.checked === true) {
        setList(item);
        recruiterList = [
          ...recruiterList,
          {
            recruiterFirstName: item.firstName,
            recruiterEmail: item.email,
          },
        ];
      }
    });
    sendReminderMail(url, recruiterList);
  };

  const getTenantData = async () => {
    try {
      setTenantLoading(true);
      const res = await get("/communitymanager/fetchtenantdetailforCM");
      if (res.status === 200 && res.data[0]) {
        let data = res.data[0].filter((item) => item.fk_role == 1);
        let gigData = res.data[0].filter((item) => item.fk_role == 3);
        setAllRec(res.data[0]);
        setFreeLancersCount(gigData.length);
        setFreeLancers(gigData);
        setTenantData(data);
        setSearchTenant([...data]);
        setStaffingAgency(data.length);
        setFreeLancersDetails(gigData.slice(offset, offset + pageShowCount));
        setTenantDetails(data.slice(offset, offset + pageShowCount));
        setPageCount(Math.ceil(data.length / pageShowCount));
        setTotalTenant(data.length);
        const showingCount =
          data.length > pageShowCount ? pageShowCount : data.length;
        setShowingCount(showingCount);
        setTenantLoading(false);
      } else {
        setTenantLoading(false);
        toast.error("Error Occured While accessing Tenant Details");
      }
    } catch (error) {
      setTenantLoading(false);
      toast.error("Error Occured While accessing Tenant Details");
    }
  };

  const getUsersData = async () => {
    try {
      setUserLoading(true);
      const res = await get("/communitymanager/fetchtenant");

      if (res.status === 200) {
        const result = res.data;

        const id = [];
        const newClient = result.userData.filter((item) => {
          if (!id.includes(item.fk_tenants)) {
            if (item.fk_Role === 1) {
              id.push(item.fk_tenants);
              return item;
            }
          }
        });

        const candidateData = result.userData.filter(
          (item) => item.userType === "tc"
        );
        const jobData = result.requestData;

        const client = [];
        newClient.map((item) => {
          let obj = {};
          obj.tenantName = item.tenantName;
          obj.createdon = item.createdon;
          obj.addressCity = item.addressCity;
          obj.addressState = item.addressState;

          const can = candidateData.filter(
            (data) => data.fk_tenants == item.fk_tenants
          );
          const job = jobData.filter(
            (data) => data.fk_tenants == item.fk_tenants
          );

          obj.candidateData = can;
          obj.jobData = job;
          client.push(obj);
        });

        setSearchEmployee([...client]);
        setAllData(result);
        setUserData(
          result.userData.filter((item) => item.fk_Role && item.fk_Role !== 1)
        );
        setCandidateData(
          result.userData.filter((item) => item.userType === "tc")
        );
        setReviewSupplierData(
          result.userData.filter((item) => item.fk_Role === 3)
        );
        setRequestData(result.requestData);

        setClientData(client.slice(offset, offset + pageShowCount));

        setPageCount(Math.ceil(client.length / pageShowCount));
        setTotalClient(client.length);
        // const showingCount = client.length > pageShowCount ? pageShowCount : client.length
        // setShowingCount(showingCount)
        setClientAllData(client);
        setUserLoading(false);
      }
    } catch (error) {
      setUserLoading(false);
      toast.error("Error Occured While accessing Details");
    }
  };

  const setView = (view) => {
    switch (view) {
      case "list":
        setListView(true);
        setTableView(false);
        setGridView(false);
        break;

      case "grid":
        setListView(false);
        setTableView(false);
        setGridView(true);
        break;

      default:
        setListView(false);
        setTableView(true);
        setGridView(false);
        break;
    }
  };

  const setTabView = (view) => {
    //tabChange()
    if (view === "gigrecruiter") {
      setGigRecruiter(true);
      setClient(false);
      setTalent(false);
      setFreeLancersTab(false);
      setIsRegisteredRecruiter(false);
    }
    if (view === "client") {
      setClient(true);
      setGigRecruiter(false);
      setTalent(false);
      setFreeLancersTab(false);
      setIsRegisteredRecruiter(false);
    }
    if (view === "talent") {
      setTalent(true);
      setGigRecruiter(false);
      setClient(false);
      setFreeLancersTab(false);
      setIsRegisteredRecruiter(false);
    }
    if (view === "freeLancers") {
      setFreeLancersTab(true);
      setTalent(false);
      setGigRecruiter(false);
      setClient(false);
      setIsRegisteredRecruiter(false);
    }
    if (view === "all") {
      setIsRegisteredRecruiter(true);
      setFreeLancersTab(false);
      setTalent(false);
      setGigRecruiter(false);
      setClient(false);
    }
  };

  const tabChange = () => {
    const pageShowCount = 10;
    const offset = 0;
    setOffset(offset);
    setCurrentPage(0);
    setPageShowCountView(pageShowCount);
    setPageShowCount(pageShowCount);
    setSupplierShowCount(pageShowCount);
    if (tenant) {
      const client = allData.userData.filter(
        (item) => item.fk_Role === 1 && item.FK_Tenant === tenant
      );
      setPageCount(Math.ceil(client.length / pageShowCount));
      setClientData(client.slice(offset, offset + pageShowCount));
      setClientAllData(client);
      setTotalClient(client.length);
      const supplier = allData.userData.filter(
        (item) => item.fk_Role === 3 && item.FK_Tenant === tenant
      );
      setReviewSupplierData(supplier);
    } else {
      const client = allData.userData.filter((item) => item.fk_Role === 1);
      setPageCount(Math.ceil(client.length / pageShowCount));
      setClientData(client.slice(offset, offset + pageShowCount));
      setClientAllData(client);
      setTotalClient(client.length);
      const supplier = allData.userData.filter((item) => item.fk_Role === 3);
      setReviewSupplierData(supplier);
    }
  };

  const handleChange = (e) => {
    setTenant(e.target.value);
    const offset = 0;
    setOffset(offset);
    setCurrentPage(0);

    if (e.target.value) {
      const client = allData.userData.filter(
        (item) => item.fk_Role === 1 && item.FK_Tenant === e.target.value
      );
      setPageCount(Math.ceil(client.length / pageShowCount));
      setClientData(client.slice(offset, offset + pageShowCount));
      setClientAllData(client);
      setTotalClient(client.length);
      setUserData(
        allData.userData.filter(
          (item) =>
            item.fk_Role &&
            item.fk_Role !== 1 &&
            item.FK_Tenant === e.target.value
        )
      );
      setCandidateData(
        allData.userData.filter(
          (item) => item.userType === "tc" && item.FK_Tenant === e.target.value
        )
      );
      setRequestData(
        allData.requestData.filter((item) => item.FK_Tenant === e.target.value)
      );
      const supplier = allData.userData.filter(
        (item) => item.fk_Role === 3 && item.FK_Tenant === e.target.value
      );
      setReviewSupplierData(supplier);
    } else {
      const client = allData.userData.filter((item) => item.fk_Role === 1);
      setPageCount(Math.ceil(client.length / pageShowCount));
      setClientData(client.slice(offset, offset + pageShowCount));
      setClientAllData(client);
      setTotalClient(client.length);
      setUserData(
        allData.userData.filter((item) => item.fk_Role && item.fk_Role !== 1)
      );
      setCandidateData(
        allData.userData.filter((item) => item.userType === "tc")
      );
      setRequestData(allData.requestData);
      const supplier = allData.userData.filter((item) => item.fk_Role === 3);
      setReviewSupplierData(supplier);
    }
  };

  const fetchGigRecruiters = async () => {
    try {
      setGigLoading(true);
      let name = [];
      let userType = [];
      let source = [];
      let status = [];
      let country = [];
      const res = await get("/gigrecruiter/fetchById");
      if (res.status === 200) {
        let filtered = res.data;
        setGigRecruiters(
          [
            ...filtered.filter((i) => i.status === "Invited"),
            ...filtered.filter((i) => i.status === "Pending"),
            ...filtered.filter((i) => i.status === "Approved"),
            ...filtered.filter(
              (i) => i.status === "Rejected" || i.status === "Deactivate"
            ),
          ].filter((val) => val.isRemoved !== "Accepted")
        );
        setGigCount([...filtered]);

        setApprovedGig([
          ...filtered.filter((val) => {
            return val.status === "Approved";
          }),
        ]);

        setGigStaffCount([...filtered]);
        filtered.forEach((item) => {
          if (item.firstName + " " + item.LastName) {
            if (!name.includes(item.firstName + " " + item.LastName)) {
              name.push(item.firstName.trim());
            }
          }
          if (item.companyName) {
            if (!userType.includes(item.companyName)) {
              userType.push(item.companyName);
            }
          }
          if (item.source) {
            if (!source.includes(item.source)) {
              source.push(item.source);
            }
          }
          if (item.status) {
            if (!status.includes(item.status)) {
              status.push(item.status);
            }
          }
          if (item.country) {
            if (!country.includes(item.country)) {
              country.push(item.country);
            }
          }
        });
        setName(name);
        setUserType(userType);
        setSource(source);
        setStatus(status);
        setCountry(country);
        setFilteredData(filtered);
        localStorage.setItem(
          "GIG RECRUITERS",
          JSON.stringify(
            res.data.map((i) => "GIG - " + i.firstName + " " + i.LastName)
          )
        );
      }
      setGigLoading(false);
    } catch (err) {
      setGigLoading(false);
      toast.error("Some Error Occurred while Fetching Gig Recruiters");
    }
  };

  const onChangeSelect = (e) => {
    if (e.target.value === "All") {
      setDataLimit(gigRecruiters.length);
    } else {
      setDataLimit(e.target.value);
    }
  };
  const onChangeSelectStaffing = (e) => {
    if (e.target.value === "All") {
      setDataLimit(gigRecruiters.length);
    } else {
      setDataLimit(e.target.value);
    }
  };

  const onChangeSelectFreeLancer = (e) => {
    if (e.target.value === "All") {
      setDataLimit(gigRecruiters.length);
    } else {
      setDataLimit(e.target.value);
    }
  };

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  // const fetchCandidate = async () => {
  //   try {
  //     setCandidateLoading(true);
  //     // const res = await get("/communitymanager/fetchcandidate");
  //     const res = await get("/communitymanager/fetchcandidate");
  //     if (res.status === 200) {
  //       const data = res.data;
  //       setTalentData(data);
  //       setCandidateLoading(false);
  //     } else {
  //       setCandidateLoading(false);
  //       toast.error("Oops! something went wrong. please try again later");
  //     }
  //   } catch (error) {
  //     setCandidateLoading(false);
  //     toast.error("Oops! something went wrong. please try again later");
  //   }
  // };

  const fetchEmpDetails = async () => {
    try {
      setEmployeeLoading(true);
      let res = await get("/h5coordinator/fetchemployee");
      if (res.status === 200) {
        setEmpData(res.data[0]);
        setEmployeeLoading(false);
      } else {
        setEmployeeLoading(false);
        toast.error("Some Error Occured while accessing Employee Details");
      }
    } catch (err) {
      setEmployeeLoading(false);
      toast.error("Some Error Occured while accessing Employee Details");
    }
  };

  const fetchRegisteredRecruiters = async () => {
    try {
      setCandidateLoading(true);
      const res = await get("/communitymanager/fetchRegisteredRecruitersCM");
      if (res.status === 200) {
        setRegisteredRecruiter(
          res.data.map((i) => {
            return {
              ...i,
              firstName:
                i.firstName.charAt(0).toUpperCase() + i.firstName.slice(1),
              lastName:
                i.lastName.charAt(0).toUpperCase() + i.lastName.slice(1),
            };
          })
        );
        setCandidateLoading(false);
      } else {
        setCandidateLoading(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      setCandidateLoading(false);
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  return (
    <>
      {(loading ||
        gigLoading ||
        userLoading ||
        tenantLoading ||
        candidateLoading ||
        employeeLoading) && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <SideNavBar handleToggale={toggleDiv} />
        <TopNavBar
          handleToggale={toggleDiv}
          tenantData={tenantData}
          freeLancers={freeLancers}
          handleChange={handleChange}
          empData={empData}
        />
        <div id="Content">
          <div className="section section-sm pt-0-768">
            <div className="container">
              <div className="row justify-content-center">
                <div class="col-12 text-center">
                  <div class="mb-5">
                    <h1>
                      <span className="wave">👋</span> Hi,{" "}
                      {getCandidateName().split(" ")[0]}. Welcome!{" "}
                    </h1>
                  </div>
                </div>
              </div>

              <div className="row justify-content-center ">
                <div className="col-lg-12 ">
                  <div className="mb-3 ">
                    {/* <Activity /> */}
                    <div className="row mb-3">
                      <SummaryCard talentData={talentData} />
                      <GigRecruiterCard
                        gigRecruiters={gigRecruiters}
                        gigCount={gigCount}
                        fetchGigRecruiters={fetchGigRecruiters}
                        setInactive={setInactive}
                        inactive={inactive}
                        inActiveGigCount={inActiveGigRecruiterList}
                      />
                      {/* <StaffingAgenciesCard
                        gigRecruiters={gigRecruiters}
                        gigStaffCount={gigStaffCount}
                        fetchGigRecruiters={fetchGigRecruiters}
                      /> */}
                    </div>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="card">
                          <div class="card-body">
                            <ul class="nav nav-tabs pt-1">
                              <li class="nav-item">
                                <button
                                  type="button"
                                  className={
                                    gigrecruiter
                                      ? "nav-link pb-3 active"
                                      : "nav-link pb-3"
                                  }
                                  onClick={() => setTabView("gigrecruiter")}
                                >
                                  <span class="tag mb-1">
                                    {gigRecruiters.length}
                                  </span>
                                  <div>
                                    Onboarding{" "}
                                    <span
                                      className=""
                                      data-bs-toggle="tooltip"
                                      data-bs-html="true"
                                      title=" No. of invited Recruiters + No. of Pending Recruiters + No. of approved+ No. of active Recruiters + No. of Deactivated Recruiters + No. of rejected Recruiters"
                                    >
                                      <i class="fal fa-info-circle"></i>
                                    </span>
                                  </div>
                                </button>
                              </li>
                              <li class="nav-item">
                                <button
                                  type="button"
                                  className={
                                    isRegisteredRecruiter
                                      ? "nav-link pb-3 active"
                                      : "nav-link pb-3"
                                  }
                                  onClick={() => setTabView("all")}
                                >
                                  <span class="tag mb-1">
                                    {registeredRecruiter.length}
                                  </span>
                                  <div>
                                    All{" "}
                                    <span
                                      className=""
                                      data-bs-toggle="tooltip"
                                      data-bs-html="true"
                                      title=" No. of recruiter admin (gig recruiters) +total no. of recruiters added by recruiter admin"
                                    >
                                      <i class="fal fa-info-circle"></i>
                                    </span>
                                  </div>
                                </button>
                              </li>
                              <li class="nav-item">
                                <button
                                  type="button"
                                  className={
                                    freeLancersTab
                                      ? "nav-link pb-3 active"
                                      : "nav-link pb-3"
                                  }
                                  onClick={() => setTabView("freeLancers")}
                                >
                                  <span class="tag mb-1">
                                    {approvedGig.length}
                                  </span>
                                  <div>
                                    {/* Freelancers/ */}
                                    Gig Recruiter{" "}
                                    <span
                                      className=""
                                      data-bs-toggle="tooltip"
                                      data-bs-html="true"
                                      title="No. of Approved gig recruiters + No. of Active gig recruiters"
                                    >
                                      <i class="fal fa-info-circle"></i>
                                    </span>
                                  </div>
                                </button>
                              </li>
                            </ul>
                            <hr class="mt-0"></hr>
                            {gigrecruiter === true && (
                              <GigRecruiterList
                                data={gigRecruiters}
                                pageLimit={10}
                                dataLimit={
                                  gigRecruiters.length < dataLimit
                                    ? gigRecruiters.length
                                    : dataLimit
                                }
                                setEmailData={setEmailData}
                                setEmailStatus={setEmailStatus}
                                fetchGigRecruiters={fetchGigRecruiters}
                                getTenantData={getTenantData}
                                fetchEmpDetails={fetchEmpDetails}
                                onChangeSelect={onChangeSelect}
                                name={name}
                                userType={userType}
                                source={source}
                                status={status}
                                country={country}
                                filterData={filteredData}
                              />
                            )}
                            {client && (
                              <div className="px-3">
                                {
                                  <>
                                    {listView && (
                                      <ListView
                                        pageLimit={10}
                                        onChangeSelectStaffing={
                                          onChangeSelectStaffing
                                        }
                                        dataLimit={
                                          freeLancersDetails.length < dataLimit
                                            ? freeLancersDetails.length
                                            : dataLimit
                                        }
                                        tenantData={freeLancers}
                                        data={approvedGig}
                                      />
                                    )}
                                  </>
                                }
                              </div>
                            )}
                            {freeLancersTab && (
                              <div className="px-3">
                                {
                                  <ListView
                                    pageLimit={10}
                                    onChangeSelectStaffing={
                                      onChangeSelectFreeLancer
                                    }
                                    dataLimit={
                                      freeLancers.length < dataLimit
                                        ? freeLancers.length
                                        : dataLimit
                                    }
                                    tenantData={freeLancers}
                                    data={approvedGig}
                                    isFor="gig"
                                  />
                                }
                              </div>
                            )}
                            {talent && <CMTalentList talentData={talentData} />}
                            {isRegisteredRecruiter && (
                              <RegisteredRecruiters
                                registeredRecruiter={registeredRecruiter}
                                pageLimit={10}
                                dataLimit={
                                  registeredRecruiter.length < dataLimit
                                    ? registeredRecruiter.length
                                    : dataLimit
                                }
                                onChangeSelect={onChangeSelect}
                                getTenantData={getTenantData}
                                name={name}
                                userType={userType}
                                source={source}
                                status={status}
                                country={country}
                                filterData={filteredData}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
        {emailStatus && (
          <EmailStatus
            email={emailData}
            emailStatus={emailStatus}
            setEmailStatus={setEmailStatus}
            isFrom="CMRecInvite"
            user="CM"
          />
        )}
        <Modal show={addClient} size="lg" animation={true}>
          <Modal.Header>
            <Modal.Title className="pb-0 pt-1 font-16">
              <h6>Add Clients</h6>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setAddClient(false)}
              className="close p-0 bl-modal-close-btn closable"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fal fa-times closable"></i>
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className="pl-5 m-5">Put Clients from here</div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => setAddClient(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={() => setAddClient(false)}>
              Submit
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={inactive} size="lg" animation={true}>
          <Modal.Header>
            <Modal.Title className="pb-0 pt-1 font-16">
              <h6>Inactive Gig Recruiter</h6>
            </Modal.Title>
            <button
              type="button"
              onClick={() => setInactive(false)}
              className="close p-0 bl-modal-close-btn closable"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fal fa-times closable"></i>
            </button>
          </Modal.Header>

          <Modal.Body>
            <table className="table table-hoverable-cells table-hoverabletable-cards-1024 font-14 ">
              <thead>
                <tr>
                  <td>
                    <div className="d-flex align-items-center mb-1  justify-content-center">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        style={{ width: "1.5rem" }}
                        checked={allChecked}
                        onChange={checkAllForReminder}
                      />
                    </div>
                  </td>
                  <td>
                    <h6>Name</h6>
                  </td>
                  <td>
                    <h6>Email</h6>
                  </td>
                  <td>
                    <h6>Last Login</h6>
                  </td>
                </tr>
              </thead>
              <tbody>
                {inActiveGigRecruiterList.map((item) => {
                  return (
                    <tr>
                      <td>
                        <div className="d-flex align-items-center mb-1  justify-content-center">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            style={{ width: "1.5rem" }}
                            checked={item.checked}
                            onChange={() => checkOneForReminder(item)}
                          />
                        </div>
                      </td>
                      <td>{item.firstName + " " + item.LastName}</td>
                      <td>{item.email}</td>
                      <td>
                        {moment(item.LastLogin).fromNow() !== "Invalid date"
                          ? moment(item.LastLogin).fromNow()
                          : "Not yet logged in"}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setInactive(false);
                inActiveGigRecruiterWithChecked();
                setAllChecked(false);
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSendReminder}
              disabled={
                inActiveGigRecruiter.length > 0
                  ? list == 0 && !allChecked
                  : true
              }
              variant="primary"
            >
              Send Reminder
            </Button>
          </Modal.Footer>
        </Modal>
      </body>
    </>
  );
};

export default CMDashboard;
