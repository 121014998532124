import React, { useState, useEffect } from "react";
import Loader from "../../Loader/Loader";
import { get, post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Col, Row, Container, Card } from "react-bootstrap";
import { getUserId } from "../../../service/AuthService";
import { validate } from "email-validator";
import moment from "moment";

function GigRecruiterCard(props) {
  const [smShow, setSmShow] = useState(false);
  const [PublicShareLinkValue, setPublicShareLinkButton] = useState(false);
  const [loading, setLoading] = useState(false);
  const [gigCountPercentage, setGigCountPercentage] = useState(false);

  useEffect(() => {
    CalculateGigPercent();
  }, [props]);

  //today's date
  let todayDate = moment(new Date()).format("YYYY-MM-DD");
  //Last Month Date
  let lastMonthDate = moment(
    new Date(new Date().setDate(new Date().getDate() - 30))
  ).format("YYYY-MM-DD");

  const CalculateGigPercent = () => {
    //Find gig Rec created between last month and today's date
    //Find gig Rec created between Last to Last Month Date and last month's date
    let approvedData = props.gigCount.filter(
      (item) => item.status === "Approved"
    );
    var totalLastMnthGig = approvedData.filter(
      (item) =>
        moment(item.createddatetime).format("YYYY-MM-DD") > lastMonthDate &&
        moment(item.createddatetime).format("YYYY-MM-DD") <= todayDate
    );
    //console.log("totalGiG", totalGigCount.length, totalLastMnthGig.length)
    //calculation formula
    let percentage = 0;
    if (totalLastMnthGig.length !== 0 && approvedData.length !== 0) {
      percentage = Math.round(
        (totalLastMnthGig.length / approvedData.length) * 100
      );
    }
    setGigCountPercentage(percentage);
  };

  const [formModel, setFormModel] = useState({
    firstName: "",
    lastName: "",
    Email: "",
  });
  const [Modelvalidation, setModelValidation] = useState({
    firstNameError: "",
    lastNameError: "",
    emailError: "",
  });

  let sendMailLink = `${
    window.location.origin
  }/RecruiterRegistrationForm/${getUserId()}`;

  const handleCopy = (e) => {
    navigator.clipboard.writeText(sendMailLink);
    toast.success("Link Copied Successfully!!!");
  };

  const handleModelClick = async (e) => {
    e.preventDefault();
    let validationObj = [];
    let validationObj2 = {
      firstNameError: "",
      lastNameError: "",
      emailError: "",
    };
    if (formModel.firstName.trim() === "") {
      validationObj2 = { ...validationObj2, firstNameError: "Required Field" };
      validationObj.push("First Name Cannot be blank");
    }
    if (formModel.lastName.trim() === "") {
      validationObj2 = { ...validationObj2, lastNameError: "Required Field" };
      validationObj.push("Last Name cannot be blank");
    }
    if (formModel.Email.trim() === "") {
      validationObj2 = {
        ...validationObj2,
        emailError: "Email Cannot be blank",
      };
      validationObj.push("invalid email");
    }
    if (formModel.Email && validate(formModel.Email) === false) {
      validationObj2 = { ...validationObj2, emailError: "Invalid Email" };
      validationObj.push("Invalid email");
    }
    if (validationObj.length > 0) {
      setModelValidation(validationObj2);
    } else {
      setModelValidation({
        firstNameError: "",
        lastNameError: "",
        emailError: "",
      });
      try {
        setLoading(true);
        const res = await post("/gigrecruiter/invite", formModel);
        if (res.data.ReturnText === "Invited Successfully") {
          setFormModel({
            firstName: "",
            lastName: "",
            Email: "",
          });
          setSmShow(false);
          toast.success("Recruiter invited successfully");
          setTimeout(() => {
            props.fetchGigRecruiters();
          }, 5000);
        } else {
          toast.error(res.data.ReturnText);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        //console.log(err)
        toast.error("Some Error Occurred while Inviting");
      }
    }
  };

  let approvedCount = props.gigCount.filter((val) => {
    return val.status === "Approved";
  });
  let invitedGigCount = props.gigCount.filter((val) => {
    return val.status === "Invited";
  });
  let pendingGigCount = props.gigCount.filter((val) => {
    return val.status === "Pending";
  });
  let activeGigCount = props.gigCount.filter((val) => {
    return val.status == "Approved" && val.LastLogin;
  });

  return (
    <>
      {loading && <Loader />}
      <div className="col-lg-6">
        <div className="card card-lg mb-2">
          <div className="card-body">
            <div className="row">
              <div className="col-6">
                <div className="w-100 d-flex align-items-center">
                  <div>
                    <h6 className="mb-0" style={{ width: "150px" }}>
                      Gig Recruiter{" "}
                      <span
                        className=""
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title="No. of all approved gig recruiters."
                      >
                        <i class="fal fa-info-circle"></i>
                      </span>
                    </h6>
                    <h1 className="font-light mb-0">{approvedCount.length}</h1>
                  </div>
                </div>
              </div>
              <div className="col-6">
                {gigCountPercentage > 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div className="w-70 text-right font-green">
                        <h2 className="mb-1 ml-5 text-left ">
                          <i className="fad fa-sm fa-arrow-up" />
                        </h2>
                        <p className="mb-0" style={{ width: "120px" }}>
                          <small>{gigCountPercentage}% in last 30 days</small>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {gigCountPercentage === 0 && (
                  <div className="row">
                    <div className="col-12">
                      <div className="w-70 text-center font-red mb-1">
                        <p className="mb-0" style={{ width: "100px" }}>
                          <small>No Change in last 30 days</small>
                        </p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <hr></hr>
            <div className="row">
              <div className="col-lg-12">
                <div className="d-flex justify-content-between">
                  <div className=" ml-2 mr-1">
                    <i className="fal fa fa-regular fa-user-plus"></i>
                    <span className="tag mb-1 ml-3">
                      {" "}
                      {invitedGigCount.length}
                    </span>
                    <div>
                      Invited{" "}
                      <span
                        className=""
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title="Total no. of invited gig recruiters."
                      >
                        <i class="fal fa-info-circle"></i>
                      </span>
                    </div>
                  </div>
                  <div className="ml-2 mr-1">
                    <i className="fal fas fa-hourglass"></i>
                    <span className="tag mb-1 ml-3">
                      {pendingGigCount.length}
                    </span>
                    <div>
                      Pending{" "}
                      <span
                        className=""
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title="Total no. of recruiters who submitted the registration but have 
                        not been approved/rejected yet by the marketplace manager. "
                      >
                        <i class="fal fa-info-circle"></i>
                      </span>
                    </div>
                  </div>
                  <div className="ml-2 mr-1">
                    <i className="fas fa-toggle-on"></i>
                    <span className="tag mb-1 ml-2">
                      {activeGigCount.length}
                    </span>
                    <div>
                      Active{" "}
                      <span
                        className=""
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title="No. of total active recruiters (recruiters who have logged in at least once after approval)"
                      >
                        <i class="fal fa-info-circle"></i>
                      </span>
                    </div>
                  </div>

                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      props.setInactive(true);
                    }}
                    className="ml-2 mr-1 "
                  >
                    <i className="fas fa-toggle-off"></i>
                    <span className="tag mb-1 ml-2">
                      {props.inActiveGigCount.length}
                    </span>
                    <div>
                      Inactive{" "}
                      <span
                        className=""
                        data-bs-toggle="tooltip"
                        data-bs-html="true"
                        title=" No. of approved gig recruiters who are not logged in yet + No. of Active Gig recruiters who are not logged in last 5 days."
                      >
                        <i class="fal fa-info-circle"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr></hr>
            <div className="d-flex justify-content-between">
              <div className="flex-item">
                <button
                  type="button"
                  className="btn btn-xs"
                  onClick={() => setSmShow(true)}
                >
                  <i class="fal fa-user-plus mr-2" aria-hidden="true"></i>
                  Invite
                </button>
              </div>
              <div className="flex-item">
                <button
                  type="button"
                  className="btn btn-xs"
                  onClick={() => setPublicShareLinkButton(true)}
                >
                  <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                  Invitation Link
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={PublicShareLinkValue === true} size="delete">
        <div className="bl-modal-header">
          <h6 className="bl-modal-title">{"Public Share Link"}</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setPublicShareLinkButton(false)}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div className="bl-modal-body pt-0">
            <div className="input-group input-group-append mb-1">
              <input
                type="text"
                className="form-control"
                value={sendMailLink}
                disabled
                placeholder="Copy Share Link"
                aria-label="Copy Share Link"
                aria-describedby="button-addon2"
              />
              <button
                className="btn btn-secondary"
                onClick={handleCopy}
                type="button"
                id="button-addon2"
              >
                Copy
              </button>
            </div>
            <div className="text-center animate animate-in">
              <div className="tag d-block tag-green1">Link Copied!</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        size="lg"
        show={smShow}
        onHide={() => setSmShow(false)}
        animation={false}
      >
        <Modal.Header closeButton>
          <h6>Invite Gig Recruiter</h6>
        </Modal.Header>
        <Modal.Body>
          <Container fluid>
            <Card>
              <Card.Body>
                <Row>
                  <Col>
                    <div className="form-floating">
                      <input
                        type="text"
                        className={
                          Modelvalidation.firstNameError
                            ? "form-control border border-danger"
                            : "form-control"
                        }
                        id="firstName"
                        value={formModel.firstName}
                        onChange={(e) =>
                          setFormModel({
                            ...formModel,
                            firstName: e.target.value,
                          })
                        }
                        placeholder="First Name*"
                        required
                      />
                      <label>First Name*</label>
                      <small className="validation">
                        {Modelvalidation.firstNameError}
                      </small>
                    </div>
                  </Col>
                  <Col>
                    <Col>
                      <div className="form-floating">
                        <input
                          type="text"
                          className={
                            Modelvalidation.lastNameError
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                          id="lastName"
                          value={formModel.lastName}
                          onChange={(e) =>
                            setFormModel({
                              ...formModel,
                              lastName: e.target.value,
                            })
                          }
                          placeholder="Last Name*"
                          required
                        />
                        <label>Last Name*</label>
                        <small className="validation">
                          {Modelvalidation.lastNameError}
                        </small>
                      </div>
                    </Col>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div class="form-floating mt-3">
                      <input
                        type="text"
                        class={
                          Modelvalidation.emailError
                            ? "form-control border border-danger"
                            : "form-control"
                        }
                        id="email"
                        value={formModel.Email}
                        onChange={(e) =>
                          setFormModel({
                            ...formModel,
                            Email: e.target.value,
                          })
                        }
                        placeholder="Email*"
                      />
                      <label>Email*</label>
                      <small className="validation">
                        {Modelvalidation.emailError}
                      </small>
                    </div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSmShow(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleModelClick}>
            <i className="fal fa-user-plus mr-2" aria-hidden="true" />
            Invite
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default GigRecruiterCard;
