import React, { useState, useEffect } from "react";
import moment from "moment";
import Avatar from "react-avatar";
import { countryObj } from "../../../constants/Constants";
import { CurrencySymbols } from "../../config/CurrencySymbols";
import { getTenantType, getUserRole } from "../../../service/AuthService";

function RequestListView(props) {
  let temporaryRequestList = useState([]);
  const [newRequestList, setnewRequestList] = useState([]);
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [requestTitleArray, setRequestTitleArray] = useState([]);
  const [pageType, setPageType] = useState("");
  let pageSize = 10;
  let dataSize = 10;

  useEffect(() => {
    if (pageType === "up") {
      if ((props.currentPage - 1) % 5 === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(
            props.currentPage - 1,
            props.currentPage + (pageSize - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (props.currentPage % 5 === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(props.currentPage - 5, props.currentPage)
        );
      }
    }
  }, [props.currentPage]);

  useEffect(() => {
    setnewRequestList(props.requestListData);
    setRequestTitleArray(
      new Array(Math.ceil(props.requestListData.length / dataSize))
        .fill()
        .map((_, i) => i + 1)
    );
    setPaginationGroup(
      new Array(Math.ceil(props.requestListData.length / dataSize))
        .fill()
        .map((_, i) => i + 1)
        .slice(0, pageSize)
    );
  }, [props]);

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    props.setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = props.currentPage * dataSize - dataSize;
    const endIndex = startIndex + dataSize;
    // return props.requestListData.slice(startIndex, endIndex);
    let arr = props.requestListData.slice(startIndex, endIndex);
    const role = getUserRole();
    const tenantType = getTenantType();
    if (
      role == "HiringManager" ||
      tenantType == 1 ||
      role == "High5Coordinator"
    ) {
      return arr;
    } else {
      return arr.filter((elem) => elem.jobStatus !== "expired");
    }
  };

  return (
    <>
      <div className="row d-block-force d-none-1200">
        <div className="col">
          {getPaginatedData().map((request, i) => (
            <a
              key={i}
              href={`/high5Hire/requests/${request.jobId}`}
              className="card p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
            >
              <div className="card-body">
                <div className="d-flex align-items-center">
                  <span className="mr-2">
                    <h6>{request.fullText.jobTitle} </h6>
                  </span>
                  <div className="d-flex">
                    <span className="d-flex align-items-center tag tag-blue2 font-regular font-primary font-gray1 mb-2">
                      <span className="font-regular font-primary font-gray1 mr-1">
                        Placement Fee:
                      </span>
                      {request.isFeePercentage
                        ? `${request.placementFee}%`
                        : `${
                            CurrencySymbols[request.placementCurrency]
                              ? CurrencySymbols[request.placementCurrency]
                              : request.placementCurrency
                          }${Number(request.placementFee).toLocaleString()}`}
                      {/* </small> */}
                    </span>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {request.jobStatus === "open" && (
                    <span className="tag tag-green1 mr-3">
                      {request.jobStatus.charAt(0).toUpperCase() +
                        request.jobStatus.slice(1)}
                    </span>
                  )}
                  {request.jobStatus === "active" && (
                    <span className="tag tag-orange1 mr-3">
                      {/* {request.jobStatus.charAt(0).toUpperCase() +
                        request.jobStatus.slice(1)} */}
                      Active
                    </span>
                  )}
                  {request.jobStatus === "expired" && (
                    <span className="tag tag-font_accent mr-3">
                      {request.jobStatus.charAt(0).toUpperCase() +
                        request.jobStatus.slice(1)}
                    </span>
                  )}
                  {request.jobStatus === "Deactivated" && (
                    <span className="tag tag-font_accent mr-3">
                      {request.jobStatus.charAt(0).toUpperCase() +
                        request.jobStatus.slice(1)}
                    </span>
                  )}
                  {request.jobStatus === "pending" && (
                    <span className="tag tag-font_accent mr-3">
                      {request.jobStatus.charAt(0).toUpperCase() +
                        request.jobStatus.slice(1)}
                    </span>
                  )}
                  {request.isHotJob && (
                    <span className="tag tag-coral mr-2">HOT</span>
                  )}
                  {/* {request.jobStatus === "open" &&
                  request.candidateNames.length == 0 ? (
                    <span className="tag tag-green1 mr-3">
                      {request.jobStatus.charAt(0).toUpperCase() +
                        request.jobStatus.slice(1)}
                    </span>
                  ) : request.jobStatus === "active" ||
                    request.candidateNames.length > 0 ? (
                    <span className="tag tag-orange1 mr-3">
                      {request.jobStatus.charAt(0).toUpperCase() +
                        request.jobStatus.slice(1)}
                    </span>
                  ) : request.jobStatus === "expired" ? (
                    <span className="tag tag-font_accent mr-3">
                      {request.jobStatus.charAt(0).toUpperCase() +
                        request.jobStatus.slice(1)}
                    </span>
                  ) : (
                    <span className="tag tag-font_accent mr-3">
                      {request.jobStatus.charAt(0).toUpperCase() +
                        request.jobStatus.slice(1)}
                    </span>
                  )} */}
                  <span className="d-flex align-items-center me-3">
                    <i className="far fa-suitcase me-2 font-brandBlue"></i>
                    <small className="font-regular font-primary font-gray1">
                      {request.jobType}
                    </small>
                  </span>
                  <span className="d-flex align-items-center me-3">
                    {request.location?.city &&
                    request.location?.state &&
                    request.location?.country &&
                    !request.isRemote ? (
                      <>
                        <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                        <span className="font-regular font-primary font-gray1">
                          {request.location.city}, {request.location?.state},{" "}
                          {request.location?.country}
                        </span>
                      </>
                    ) : (
                      <>
                        <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                        <span className="font-regular font-primary font-gray1">
                          Remote
                        </span>
                      </>
                    )}
                  </span>
                  <span className="d-flex align-items-center me-3">
                    {moment(request.expiresOn).diff(new Date(), "days") > 0 && (
                      <i className="far fa-clock me-2 font-brandBlue"></i>
                    )}
                    {/* {moment(request.expiresOn).diff(new Date(), "days") < 0 && (
                      <span className="tag tag-red1">
                        <strong> Expired </strong>
                      </span>
                    )} */}
                    {moment(request.expiresOn).diff(new Date(), "days") ===
                      0 && (
                      <span className="tag tag-red1">
                        <strong> Expires Today </strong>
                      </span>
                    )}
                    {moment(request.expiresOn).diff(new Date(), "days") ===
                      1 && (
                      <span className="font-regular">
                        <strong> 1 Day Left To Fill </strong>
                      </span>
                    )}
                    {moment(request.expiresOn).diff(new Date(), "days") > 1 &&
                      (moment(request.expiresOn).diff(new Date(), "days") <=
                      5 ? (
                        <span className="tag tag-red1">
                          <strong>
                            {" "}
                            {moment(request.expiresOn).diff(
                              new Date(),
                              "days"
                            )}{" "}
                            Days Left To Fill{" "}
                          </strong>
                        </span>
                      ) : (
                        <span className="font-regular">
                          <strong>
                            {" "}
                            {moment(request.expiresOn).diff(
                              new Date(),
                              "days"
                            )}{" "}
                            Days Left To Fill{" "}
                          </strong>
                        </span>
                      ))}
                  </span>
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-end">
                <div className="d-flex flex-row-reverse justify-content-end mx-2">
                  {request.candidateNames.length > 3 && (
                    <span className="avatar avatar-bordered ml-neg-10">
                      {" "}
                      {`${request.candidateNames.slice(3, 100).length}+`}{" "}
                    </span>
                  )}
                  <span>
                    {request.candidateNames.slice(0, 3).map((name, i) => (
                      <span key={i}>
                        <Avatar
                          size="45"
                          name={name.candidateFname + " " + name.candidateLname}
                          round="80px"
                        />
                        {/* {name.candidateFname.charAt()}{name.candidateLname.charAt()} */}
                      </span>
                    ))}
                  </span>
                </div>

                {request.totalSubmisions === 0 ? (
                  <div className="w-200  text-center bg-gray_disabled font-regular py-4 px-2 ">
                    All Candidates Viewed
                  </div>
                ) : (
                  <div className="w-200   text-center bg-green_primary font-white font-regular py-4 px-2">
                    {request.totalSubmisions === 1
                      ? `${request.totalSubmisions} New Candidate`
                      : `${request.totalSubmisions}  New Candidates`}
                  </div>
                )}
              </div>
            </a>
          ))}
        </div>
        {getPaginatedData().length > 0 && (
          <div className="d-flex justify-content-end pt-4 px-1">
            <div className="d-flex">
              <div class="ml-auto mx-auto-370 d-flex align-items-center">
                <button
                  onClick={() => {
                    setPageType("down");
                    props.setCurrentPage((page) => page - 1);
                  }}
                  type="button"
                  disabled={props.currentPage === 1 ? true : false}
                  className="btn btn-icon px-2"
                >
                  <i className="fal fa-chevron-circle-left"></i>
                </button>
                {paginationGroup.map((item) => (
                  <button
                    type="button"
                    className={
                      props.currentPage === item
                        ? "btn btn-text px-2 bg-secondary text-white"
                        : "btn btn-text px-2"
                    }
                    onClick={changePage}
                  >
                    {item}
                  </button>
                ))}
                <button
                  onClick={() => {
                    setPageType("up");
                    props.setCurrentPage((page) => page + 1);
                  }}
                  type="button"
                  disabled={
                    props.currentPage === requestTitleArray.slice(-1)[0]
                      ? true
                      : false
                  }
                  className="btn btn-icon px-2"
                >
                  <i className="fal fa-chevron-circle-right"></i>
                </button>
              </div>
            </div>
          </div>
        )}
        {newRequestList.length === 0 && (
          <div class="text-center p-5">
            <div class="avatar avatar-lg">
              <i aria-hidden="true" class="fad fa-users-slash"></i>
            </div>
            <div class="mt-2">No records found</div>
          </div>
        )}
      </div>
    </>
  );
}

export default RequestListView;
