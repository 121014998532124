import { toast } from "react-toastify";
import { get } from "../../service/ApiService";

export const GET_ALL_TAGS_BEGIN = "GET_ALL_TAGS_BEGIN";
export const GET_ALL_TAGS_SUCCESS = "GET_ALL_TAGS_SUCCESS";
export const GET_ALL_TAGS_FAILURE = "GET_ALL_TAGS_FAILURE";

export const getAllTags = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_ALL_TAGS_BEGIN,
    });
    const res = await get("/tags/getAllTags");
    if (res.status === 200) {
      dispatch({
        type: GET_ALL_TAGS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ALL_TAGS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};
