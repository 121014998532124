import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import { countryObj } from "../../../../constants/Constants";
import { CurrencySymbols } from "../../../config/CurrencySymbols";
import StatusData from "./StatusData";
import Pagination from "../../../components_v3/pagination/pagination";
import { getUserRole } from "../../../../service/AuthService";
import { SidebarLogo } from "../../../../constants/Constants";

const ListView = (props) => {
  const history = useHistory();
  const { currentPage, setCurrentPage, viewJobType } = props;
  let dataSize = props.parent == "HMdashboard" ? 4 : 5;
  const [testId, setTestId] = useState({});

  const onJobClick = (job) => {
    let jdata = props.jobList.map((item) => {
      if (
        (item?.jobId === job?.jobId && job.jobId) ||
        (item?.jobDivaId === job?.jobDivaId && job.jobDivaId)
      ) {
        const maxUpdatedDateTime = item?.statusData?.reduce(
          (maxDateTime, obj) => {
            const updatedDateTime = new Date(obj.updatedDateTime);
            return updatedDateTime > maxDateTime
              ? updatedDateTime
              : maxDateTime;
          },
          new Date(0)
        );
        item.maxUpdatedDateTime =
          item?.statusData?.length > 0
            ? maxUpdatedDateTime
            : item.updatedDateTime;

        return { ...item, showLocation: !job.showLocation };
      } else {
        const maxUpdatedDateTime = item?.statusData?.reduce(
          (maxDateTime, obj) => {
            const updatedDateTime = new Date(obj.updatedDateTime);
            return updatedDateTime > maxDateTime
              ? updatedDateTime
              : maxDateTime;
          },
          new Date(0)
        );
        item.maxUpdatedDateTime =
          item?.statusData?.length > 0
            ? maxUpdatedDateTime
            : item.updatedDateTime;
        return item;
      }
    });
    props.setJobData(jdata);
  };

  useEffect(() => {
    setTestId({});
  }, [currentPage]);

  const removeItemFromArray = (item) => {
    const temp = { ...testId };
    delete temp[item];
    setTestId(temp);
  };

  return (
    <div className="row d-block-force d-none-1200">
      <div className="col">
        {props.jobList?.map((job, i) => (
          <>
            <div
              className={`card p-0 d-flex w-100 align-items-center 
              justify-content-between job-listview flex-row overflow-hidden mb-1 pointer ${
                job.jobStatus === 13 ? "card-finger-shape" : "" // Add the class 'card-finger-shape' conditionally for the job name element
              }`}
              onClick={(event) => {
                if (
                  event.target.className.includes("tag") ||
                  event.target.className.includes("tag-blue3")
                ) {
                  return;
                } else {
                  history.push(`/high5Hire/requests/${job.jobId}`);
                }
              }}
            >
              <div className="card-body row w-100">
                <div className="col-md-1 p-2">
                  <img
                    src={job.logourl || SidebarLogo}
                    width="70"
                    height="70"
                    className="p-2"
                    style={{
                      borderRadius: "50%",
                      objectFit: "contain",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  />
                </div>
                <div className="col-md-11 d-flex align-items-center">
                  <div className="row p-1">
                    <div className="col-md-12 d-flex align-items-center">
                      <span className="mr-2">
                        <h6>
                          <custom>
                            {" "}
                            {job.jobTitle.length > 19
                              ? `${job.jobTitle.substring(0, 20)}..`
                              : job.jobTitle}
                          </custom>{" "}
                          (Job Id: {job.jobId})
                        </h6>
                      </span>
                      <div className="d-flex">
                        <span className="d-flex align-items-center tag tag-blue2 font-regular font-primary font-gray1 mb-2">
                          <span className="font-regular font-primary font-gray1 mr-1">
                            Placement Fee:
                          </span>
                          {job.isFeePercentage
                            ? `${job.placementFee}%`
                            : `${
                                CurrencySymbols[job.placementCurrency]
                                  ? CurrencySymbols[job.placementCurrency]
                                  : job.placementCurrency
                              }${Number(job.placementFee).toLocaleString()}`}
                        </span>
                      </div>
                      {job?.jobDivaId && (
                        <div className="d-flex ml-1">
                          <span className="d-flex align-items-center tag tag-orange1 font-regular font-primary font-gray1 mb-2">
                            <span className="font-regular font-primary font-gray1 mr-1">
                              JobDiva ID:
                            </span>
                            <strong>{job?.jobDivaId}</strong>
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="col-md-12 d-flex align-items-center">
                      {job.jobStatus === 13 && (
                        <span className="tag tag-green1 mr-3">Open</span>
                      )}
                      {job.jobStatus === 21 && (
                        <span className="tag tag-orange1 mr-3">Active</span>
                      )}
                      {job.jobStatus === 39 && (
                        <span className="tag tag-font_accent mr-3">Halted</span>
                      )}
                      {job.jobStatus === 23 && (
                        <span className="tag tag-font_accent mr-3">Closed</span>
                      )}
                      {job.isHotJob && (
                        <span className="tag tag-coral mr-2">HOT</span>
                      )}
                      {job.jobType && (
                        <span className="d-flex align-items-center me-3 w-15">
                          <i className="far fa-suitcase me-2 font-brandBlue"></i>
                          <small className="font-regular font-primary font-gray1">
                            {job.jobType}
                          </small>
                        </span>
                      )}
                      <span
                        className="d-flex align-items-center me-3"
                        onClick={() => onJobClick(job)}
                      >
                        {job?.city &&
                        job?.state &&
                        job?.country &&
                        !job.isRemote ? (
                          <>
                            <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                            <span className="font-regular font-primary font-gray1">
                              {job.showLocation
                                ? `${job?.city}, ${job?.state}, ${job?.country}`
                                : job?.city + "..."}
                            </span>
                          </>
                        ) : (
                          <>
                            <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                            <span className="font-regular font-primary font-gray1">
                              Remote
                            </span>
                          </>
                        )}
                      </span>
                      {job?.visaStatus && (
                        <div className="mt-1 d-block" style={{ width: "35%" }}>
                          <i class="far fa-plane-departure mr-1"></i>{" "}
                          {JSON.parse(job?.visaStatus).map(
                            (item, index) =>
                              (index < 1 || testId[i] == "visastatus") && (
                                <span className="tag tag-blue3 mr-1 pt-1 px-2 mt-1">
                                  {item}
                                </span>
                              )
                          )}
                          {job?.visaStatus &&
                            (testId[i] == "visastatus" ? (
                              <div
                                className="tag"
                                onClick={() => removeItemFromArray(i)}
                                style={{ margin: "2px", cursor: "pointer" }}
                              >
                                more
                              </div>
                            ) : (
                              JSON.parse(job?.visaStatus).length > 1 && (
                                <div
                                  className="tag"
                                  onClick={() => {
                                    setTestId({ ...testId, [i]: "visastatus" });
                                  }}
                                  style={{ margin: "2px", cursor: "pointer" }}
                                >
                                  +{JSON.parse(job?.visaStatus).length - 1} Show
                                  less
                                </div>
                              )
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                  {getUserRole() != "Recruiter" && (
                    <div className="d-flex align-items-center justify-content-end">
                      <StatusData job={job} />
                      {!job.totalSubmisions ? (
                        <div
                          className="flex justify-center items-center text-center bg-gray_disabled font-regular py-4 px-2"
                          style={{
                            width: "200px",
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          No New Candidates Available
                        </div>
                      ) : (
                        <div
                          className="flex justify-center items-center text-center bg-green_primary font-white font-regular py-4 px-2"
                          style={{
                            width: "200px",
                            height: "100px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {job.totalSubmisions === 1
                            ? `${job.totalSubmisions} New Candidate`
                            : `${job.totalSubmisions} New Candidates`}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                <div className="p-2 border-top text-end w-100">
                  {viewJobType == "myjobs"
                    ? job?.dispositionUpdate &&
                      new Date() > new Date(job?.dispositionUpdate) && (
                        <>
                          <div
                            class="text-muted p-0 mb-0"
                            style={{ float: "left" }}
                          >
                            Company Name : <span>{job?.tenantname}</span>
                          </div>
                          <div
                            class="text-muted p-0 mb-0"
                            style={{ float: "right" }}
                          >
                            Last updated : &nbsp;
                            <i class="far fa-clock"></i>&nbsp;
                            <span>
                              {moment(
                                new Date(job?.dispositionUpdate),
                                "MMDDYYYY"
                              )
                                .fromNow()
                                .charAt(0) === "a"
                                ? moment(
                                    new Date(job?.dispositionUpdate),
                                    "MMDDYYYY"
                                  )
                                    .fromNow()
                                    .charAt(0)
                                    .toLocaleUpperCase() +
                                  moment(
                                    new Date(job?.dispositionUpdate),
                                    "MMDDYYYY"
                                  )
                                    .fromNow()
                                    .slice(1)
                                : moment(
                                    new Date(job?.dispositionUpdate),
                                    "MMDDYYYY"
                                  ).fromNow()}
                            </span>
                            &nbsp;
                          </div>
                        </>
                      )
                    : job?.updatedDateTime &&
                      new Date() > new Date(job?.updatedDateTime) && (
                        <>
                          <div
                            class="text-muted p-0 mb-0"
                            style={{ float: "left" }}
                          >
                            Company Name : <span>{job?.tenantname}</span>
                          </div>
                          <div
                            class="text-muted p-0 mb-0"
                            style={{ float: "right" }}
                          >
                            Last updated : &nbsp;
                            <i class="far fa-clock"></i>&nbsp;
                            <span>
                              {moment(
                                new Date(job?.updatedDateTime),
                                "MMDDYYYY"
                              )
                                .fromNow()
                                .charAt(0) === "a"
                                ? moment(
                                    new Date(job?.updatedDateTime),
                                    "MMDDYYYY"
                                  )
                                    .fromNow()
                                    .charAt(0)
                                    .toLocaleUpperCase() +
                                  moment(
                                    new Date(job?.updatedDateTime),
                                    "MMDDYYYY"
                                  )
                                    .fromNow()
                                    .slice(1)
                                : moment(
                                    new Date(job?.updatedDateTime),
                                    "MMDDYYYY"
                                  ).fromNow()}
                            </span>
                            &nbsp;
                          </div>
                        </>
                      )}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>
      {props.jobList.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalItems={props.dataTotal}
          itemsPerPage={10}
          onPageChange={props.handlePageChange}
          limit={10}
        />
      )}

      {props.jobList.length === 0 && <NoRecord />}
    </div>
  );
};

export default ListView;
