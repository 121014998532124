import React, { useState } from "react";

const EducationDetails = (props) => {
  const { jobData } = props;
  const [educationCollapse, setEducationCollapse] = useState(false);
  return (
    <>
      {jobData?.education && JSON.parse(jobData?.education).length !== 0 && (
        <div className="mb-2">
          <button
            type="button"
            className="btn btn-collapsible"
            onClick={() => setEducationCollapse(!educationCollapse)}
          >
            <i
              aria-hidden="true"
              className={
                educationCollapse
                  ? "fal fa-angle-up fa-fw"
                  : "fal fa-angle-down fa-fw"
              }
            />
            <span>
              <small>Education</small>
            </span>
          </button>
        </div>
      )}

      {educationCollapse && (
        <div className="mb-3 ml-4">
          {jobData?.education &&
            JSON.parse(jobData?.education).map((item) => (
              <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
            ))}
        </div>
      )}
    </>
  );
};

export default EducationDetails;
