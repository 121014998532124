import React, { useEffect, useState } from "react"
import Instruction from "../intakeform/Instructions";

function PreIntake({ companyErr, intakeDetails, setIntakeDetails, companyName, HMDropdown, HMId, handleClientChange, clientList, handleHM }) {

    const [ checkList, setCheckList ] = useState(true)
    const [ clientTab, setClientTab ] = useState(true)
    const [ instructions, setInstructions ] = useState(false)

    useEffect(() => {
        if(companyErr) {
            setCheckList(false)
            setInstructions(false)
        }
    }, [companyErr])
    return (
        <>
            <div>
                <div className="mb-2 mt-3" >
                    <div>                     
                        <button
                            onClick={() => setCheckList(!checkList) }
                            type="button" 
                            className="btn btn-collapsible mb-2"
                        >
                            {checkList ?
                                <i className="fal fa-angle-up fa-fw mt-1" /> :
                                <i className="fal fa-angle-down fa-fw" />
                            }
                            <span><small>Pre-Intake Call Checkist (Sales/Account Director)</small></span>
                        </button>
                        <div className={checkList ? "ml-0 mt-4 mb-4 mr-4" : 'd-none'} >
                            <div className="card card-flat card-lg ml-4 ml-0-600">
                                <div className="form-check form-switch d-flex align-items-center mt-2" >
                                    <label
                                        className="form-check-label pt-0"
                                        style={{ width: '600px' }}
                                    >
                                        Job Details Received Prior to Intake?
                                    </label>                                   
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        name="jobDetailsReceived"                                     
                                        checked={intakeDetails.jobDetailsReceived}
                                        onChange={setIntakeDetails}
                                    />
                                </div>
                                <div className="form-check form-switch d-flex align-items-center mt-2" >
                                    <label
                                        className="form-check-label pt-0"
                                        style={{ width: '600px' }}>
                                        High5 Delivery Pre-Search Market Intel?</label>                                   
                                    <input
                                        //checked={isPreSearch}
                                        className="form-check-input"
                                        type="checkbox"
                                        name="preSearchMarket"
                                        checked={intakeDetails.preSearchMarket}
                                        onChange={setIntakeDetails}
                                    />
                                </div>
                                <div className="form-check form-switch d-flex align-items-center mt-2" >
                                    <label
                                        className="form-check-label pt-0"
                                        style={{ width: '600px' }}
                                    >
                                        High5 Delivery Search Approved/Accepted?
                                    </label>                                  
                                    <input
                                        //checked={isDeliverySearch}
                                        className="form-check-input"
                                        type="checkbox"
                                        name="deliverySearchApproval"
                                        checked={intakeDetails.deliverySearchApproval}
                                        onChange={setIntakeDetails}
                                    />
                                </div>
                                <div className="form-check form-switch d-flex align-items-center mt-2" >
                                    <label
                                        className="form-check-label pt-0"
                                        htmlFor="remoteSwitch"
                                        style={{ width: '600px' }}
                                    >
                                        Signed Contract?
                                    </label>                                  
                                    <input
                                        //checked={isSignedContract}
                                        className="form-check-input"
                                        type="checkbox" 
                                        name="signedContract"
                                        checked={intakeDetails.signedContract}
                                        onChange={setIntakeDetails}
                                    />
                                </div>
                                <div className="form-check form-switch d-flex align-items-center mt-2 mb-3" >
                                    <label
                                        className="form-check-label pt-0"
                                        style={{ width: '600px' }}
                                    >
                                        Email Alias Set Up?
                                    </label>                                    
                                    <input                                   
                                        className="form-check-input"
                                        type="checkbox" 
                                        name="emailAlias"
                                        checked={intakeDetails.emailAlias}
                                        onChange={setIntakeDetails}
                                    />
                                </div>
                            </div>
                        </div>
                        <button
                            onClick={() => setClientTab(!clientTab)}
                            type="button" className="btn btn-collapsible mb-2">
                            {clientTab ?
                                (
                                    <i className="fal fa-angle-up fa-fw mt-1"></i>
                                ) :
                                (
                                    <i className="fal fa-angle-down fa-fw"></i>
                                )}
                            <span><small>Client Details</small></span>
                        </button>
                        <div className={clientTab ? "ml-0 mt-4 mb-4 mr-4" : 'd-none'} >
                            <div className="card card-flat bg-gray4 ml-4 ml-0-600">
                                <div className="card-body">
                                    <div className="row mb-2">
                                        <div className="col-lg-6">                                          
                                            <div className="form-floating">
                                                <select
                                                    className={ companyErr ? "form-select border border-danger" : "form-select"}
                                                    onChange={handleClientChange}
                                                >
                                                    <option value='' selected={!companyName} disabled>Select</option>
                                                    {
                                                        clientList.map((item) => (
                                                            <option 
                                                                value={item.FK_Tenant}                    
                                                                selected={item.tenantname === companyName}
                                                            >{item.tenantname}</option>
                                                        ))
                                                    }
                                                </select>
                                                <label>Select Client*</label>
                                                <div className="m-2">
                                                    {
                                                        companyErr ?
                                                        <small className="validation">
                                                            {companyErr}
                                                        </small> :
                                                        <small>
                                                            Don't see Client in the list?<br /> Please Create Client and Hiring Manager
                                                        </small>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">                       
                                            <div className="form-floating">
                                                <select
                                                    id='hmdropdown'
                                                    className="form-select"
                                                    onChange={handleHM}
                                                    //value={props.addIntakeData.HiringManagerName}
                                                >
                                                    <option disabled>Select</option>
                                                    {/* {props.addIntakeData.HiringManagerName && <option selected value={props.addIntakeData.HiringManagerName}>{props.addIntakeData.HiringManagerName}</option>} */}


                                                    {/* <option value=''>Select</option> */}
                                                    {
                                                        HMDropdown.map((item) => (
                                                            <option value={item.userId} selected={HMId === item.userId}>{item.firstName + ' ' + item.lastName}</option>
                                                        ))
                                                    }
                                                </select>
                                                <label>Select Hiring Manager</label>
                                                {/* {props.preIntakeValidationObj.HiringManagerNameErr && <small className="validation ml-1">{props.preIntakeValidationObj.HiringManagerNameErr}</small>} */}
                                            </div>
                                        </div>                                                      
                                    </div>                                  
                                    <div className="mt-4">
                                        <div className="form-check form-switch d-flex align-items-center">
                                            <label
                                                className="form-check-label p-0"
                                            >
                                                Budget Approved?
                                            </label>
                                            <input
                                                name="budgetApproved"
                                                className="form-check-input ml-2"
                                                type="checkbox"
                                                checked={intakeDetails.budgetApproved}
                                                onChange={setIntakeDetails}
                                            />
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div> 
                        <button
                            onClick={() => setInstructions(!instructions) }
                            type="button" className="btn btn-collapsible mb-2"
                        >
                            { instructions ?                              
                                <i className="fal fa-angle-up fa-fw mt-1" /> :                              
                                <i className="fal fa-angle-down fa-fw" />
                            }
                            <span><small>Instructions</small></span>
                        </button>  
                        <div className={instructions ? "ml-0 mt-4 mb-4 mr-4" : 'd-none'}> 
                            <Instruction 
                                instructions={instructions}
                            />
                        </div>
                    </div>
                </div>
            </div>          
        </>
    )
}

export default PreIntake