import React, { useState, useEffect } from "react";
import { getCandidateName } from "../../../../service/AuthService";
import SideNavBar from "../../../layout/SideNavBar";
import TopNavBar from "../../../layout/TopNavBar";
import SearchComponent from "../recruiter/SearchComponent";
import Footer from "./footer/Footer";
import DashboardCards from "./DashboardCards";

function Index(props) {
  const FOOTER_HEIGHT = "40px";
  const [toggle, setToggle] = useState(true);
  const [userfirstName, setUserFirstName] = useState("");
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);
  const [searchData, setSearchData] = useState({
    jobData: [],
    talentData: [],
    communityData: [],
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const username = getCandidateName();
    const FirstName = username.substr(0, username.indexOf(" "));
    setUserFirstName(FirstName);
  }, []);

  const handleSearchType = (e) => {
    setSearchInput(e.target.value);
  };

  const mainDivStyle = {
    paddingTop: isSearchExpanded ? "50px " : "",
    transition: "padding 0.5s, margin 0.5s, opacity 0.5s",
  };

  return (
    <>
      <body
        className={toggle ? "layout1 layout1-closed close-menu" : "layout1"}
      >
        <div className="body-decorator body-decorator-top"></div>
        <div
          style={{ top: "auto", bottom: FOOTER_HEIGHT }}
          className="body-decorator body-decorator-bottom"
        ></div>
        <TopNavBar
          isSearchExpanded={isSearchExpanded}
          setIsSearchExpanded={setIsSearchExpanded}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          handleSearchType={handleSearchType}
          showSearch={false}
          handleToggale={() => setToggle(!toggle)}
        />
        <SideNavBar />

        <div id="Content">
          <div className="section pt-0">
            <div className="mx-3">
              <div className="row justify-content-center">
                <div className="col-lg-11">
                  <div
                    id="Content "
                    // style={{ paddingBottom: "50px" }}
                  >
                    <div className="mx-3 py-2">
                      <div
                        style={mainDivStyle}
                        className={`  row justify-content-center ${
                          isSearchExpanded ? "" : ""
                        }`}
                      >
                        <SearchComponent
                          isSearchExpanded={isSearchExpanded}
                          onClose={setIsSearchExpanded}
                          searchInput={searchInput}
                          setSearchInput={setSearchInput}
                          handleSearchType={handleSearchType}
                          isLoading={isLoading}
                          searchData={searchData}
                        />
                        <DashboardCards windowWidth={windowWidth} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </body>
      <Footer FOOTER_HEIGHT={FOOTER_HEIGHT} />
    </>
  );
}
export default Index;
