import {
  GET_TALENT_EMAILS_BEGIN,
  GET_TALENT_EMAILS_SUCCESS,
  GET_TALENT_EMAILS_FAILURE,
} from "../../actions/talent/getTalentEmail";

const initialState = {
  talentEmailsLoading: true,
  talentEmails: null,
};

const getTalentEmailReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TALENT_EMAILS_BEGIN:
      return {
        ...state,
        talentEmailsLoading: true,
        talentEmails: null,
      };
    case GET_TALENT_EMAILS_SUCCESS:
      return {
        ...state,
        talentEmails: payload,
        talentEmailsLoading: false,
      };
    case GET_TALENT_EMAILS_FAILURE:
      return {
        ...state,
        talentEmails: null,
        talentEmailsLoading: false,
      };
    default:
      return state;
  }
};

export default getTalentEmailReducer;
