import React, { useState } from "react";
import {
  FaLaptopCode,
  FaStethoscope,
  FaMoneyCheckAlt,
  FaClipboard,
  FaUserTie,
  FaEllipsisH,
} from "react-icons/fa";
import TalentSubPoolList from "../talentSubPool/TalentSubPoolList";

const industryArr = [
  "Information Technology (IT)",
  "Healthcare",
  "Accounting and Finance",
  "Administrative and Office Support",
  "Human Resources (HR)",
  "Other",
];

const iconMap = {
  "Information Technology (IT)": FaLaptopCode,
  Healthcare: FaStethoscope,
  "Accounting and Finance": FaMoneyCheckAlt,
  "Administrative and Office Support": FaClipboard,
  "Human Resources (HR)": FaUserTie,
  Other: FaEllipsisH,
};

const IndustryList = ({
  setPool,
  setSubPool,
  setSelectedPool,
  selectedIndustry,
  setSelectedIndustry,
}) => {
  const iconSize = 40;

  const handleIndustryClick = (industry) => {
    setSelectedIndustry(industry);
  };
  return (
    <>
      {!selectedIndustry && (
        <div>
          <div className="mt-3 mb-3">
            <strong>Select Industry</strong>
          </div>
          <div style={styles.gridContainer}>
            {industryArr.map((industry, index) => {
              const IconComponent = iconMap[industry];
              return (
                <div
                  key={index}
                  style={styles.gridItem}
                  onClick={() => handleIndustryClick(industry)}
                >
                  <div
                    style={{
                      ...styles.iconWrapper,
                      width: iconSize,
                      height: iconSize,
                    }}
                  >
                    <IconComponent color="#394193" size={iconSize * 0.6} />
                  </div>
                  <span style={styles.industryText}>{industry}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {selectedIndustry && (
        <div style={styles.subPoolContainer}>
          <TalentSubPoolList
            industry={selectedIndustry}
            setSelectedIndustry={setSelectedIndustry}
            setPool={setPool}
            setSubPool={setSubPool}
            setSelectedPool={setSelectedPool}
            isFrom={"Industry"}
          />
        </div>
      )}
    </>
  );
};

const styles = {
  gridContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "10px",
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    padding: "10px",
    marginTop: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
    border: "1px solid #ccc",
    borderRadius: "5px",
    backgroundColor: "#f9f9f9",
  },
  iconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "#e0f7ff",
  },
  industryText: {
    marginLeft: "10px",
    color: "#394193",
  },
};

export default IndustryList;
