import React, { useEffect, useState } from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { MdAttachMoney, MdOutlineMonitor } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";
import { ImCalendar } from "react-icons/im";
import { BsBriefcase } from "react-icons/bs";
import { GiMoneyStack } from "react-icons/gi";
import moment from "moment";
import { useLocation, useParams } from "react-router";
import { GoLocation } from "react-icons/go";
import { BsBuilding, BsArrowRight } from "react-icons/bs";
import TalentCommunity from "./TalentCommunity";
import high5Logo from "../../../images/high5-logo.jpg";
import Refer from "./Refer";
import { post } from "../../../service/ApiService";
import TopNavbar from "./TopNavbar";
import Modal from "react-bootstrap/Modal";
import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import {
  LoginSocialGoogle,
  LoginSocialLinkedin,
  LoginSocialFacebook,
} from "reactjs-social-login";
import GoogleLogin from "react-google-login";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import ApplyJob from "./ApplyJob";
import AddTalentManualEntry from "../../modal/addtalent/manualentry/AddTalentManualEntry";
import CandidateProfile from "./CandidateProfile";
import {
  AiFillLinkedin,
  AiOutlinePlusSquare,
  AiOutlineMinusSquare,
} from "react-icons/ai";
import { RiShareBoxLine } from "react-icons/ri";
import AddTalentDS from "./AddTalentDS";
import EditTalentDS from "../Edit Talent DS/EditTalentDS";
import { domain } from "../../../constants/Constants";
import PhoneInput from "react-phone-input-2";
import { AsYouType, parse } from "libphonenumber-js";
import AutoMatchScoreCardDS from "./AutoMatchScoreCardDS";
import axios from "axios";
import { getCandidateName, getUserName } from "../../../service/AuthService";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Autosuggest from "react-autosuggest";
import { dropDownData } from "../Edit Talent DS/dropDownData";
import "./style.css";

const JobDescription = () => {
  const { id, poolId, tenant, reffererid, recruiterid } = useParams();

  localStorage.setItem("reffererid", reffererid);
  var token = sessionStorage.getItem("token");
  const recruiterName = getCandidateName();
  const recruiterEmail = getUserName();

  const [isRefer, setIsRefer] = useState(false);
  const [job, setJob] = useState({});
  const [skillsList, setSkillsList] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [tenantData, setTenantData] = useState({});
  const [loading, setLoading] = useState(false);
  const [parsedData, setParsedData] = useState({});
  const [parsed, setParsed] = useState(false);
  const [isLogIn, setIsLogIn] = useState(false);
  const [joinCommunity, setJoinCommunity] = useState(false);
  const [applyWithEmail, setApplyWithEmail] = useState(false);
  const [loginWithEmail, setLoginWithEmail] = useState(false);
  const [personalInfo, setPersonalInfo] = useState(false);
  const [electronicMessages, setElectronicMessages] = useState(false);
  const [isApplyJobModal, setIsApplyJobModal] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailExist, setEmailExist] = useState(false);
  const [createProfile, setCreateProfile] = useState(false);
  const [editTalent, setEditTalent] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [resumeDownload, setResumeDownload] = useState([]);
  const [newUser, setNewUser] = useState(false);
  const [askResume, setAskResume] = useState(false);
  const [legalAuth, setLegalAuth] = useState(false);
  const [addTalentData, setAddTalentData] = useState("");
  const [scoreCard, setScoreCard] = useState(false);
  const [screeningQuestions, setScreeningQuestions] = useState(false);
  const [clientAdmin, setClientAdmin] = useState({});
  const [tenantName, setTenantName] = useState("");
  const [vettingDB, setVettingDB] = useState([]);
  const [vettingAssign, setVettingAssign] = useState([]);
  const [vettingNow, setVettingNow] = useState(false);
  const [vettingLater, setVettingLater] = useState(false);
  const [isVetting, setIsVetting] = useState(false);
  const [fetchVettingData, setFetchVettingData] = useState([]);
  const [sourceChannel, setSourceChannel] = useState("");
  const [recruiterAgency, setRecruiterAgency] = useState("");
  const [reffererName, setReffererName] = useState("");
  const [profileData, setProfileData] = useState({});
  const [candidateId, setCandidateId] = useState("");
  const [valid, setValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  const [jobValid, setJobValid] = useState(false);

  const data = {
    fName: "",
    lName: "",
    email: "",
    country: "United States",
    phone: "",
    phoneValidation: true,
    alternatePhone: "",
    highestEducation: "",
    city: "",
    currentPosition: "",
    currentEmployer: "",
    experienceYear: "0",
    experienceMonth: "0",
    legallyAuthorized: false,
    requireSponsorship: false,
    primarySkills: [],
  };
  const [candidateDetails, setCandidateDetails] = useState(data);
  const [candidateDetailsValidation, setCandidateDetailsValidation] = useState(
    data
  );

  const educationTypes = [
    "Bachelor's Degree",
    "Certification",
    "Doctorate",
    "Highschool or Equivalent",
    "Master's Degree",
    "NA",
    "Professional",
    "Some College Coursework Completed",
    "Some Highschool Course Work",
    "Vocational",
  ];

  // Apply job
  const [login, setLogin] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    url: "",
    email: "",
  });

  const handleChange = (e) => {
    setCandidateDetails({
      ...candidateDetails,
      [e.target.name]: e.target.value,
    });
  };

  const updatedParsed = (data, value) => {
    setParsedData(data);
    setParsed(value);
  };

  // useEffect(() => {
  //   console.log(localStorage.getItem("isLoggedIn"));
  // }, []);

  const handleLogin = (name, url, email) => {
    setLogin(false);
    toast.success("Logged In Successfully");
    localStorage.setItem("isLoggedIn", "Yes");
    localStorage.setItem("name", name);
    localStorage.setItem("url", url);
    localStorage.setItem("email", email);
    setToggle(false);
    setIsLogIn(true);
  };

  const isLoggedIn = () => localStorage.getItem("isLoggedIn");

  const handleLogout = () => {
    setToggle(false);
    localStorage.setItem("isLoggedIn", "No");
    // console.log(isLoggedIn());
  };

  const getName = () => localStorage.getItem("name");
  const getURL = () => localStorage.getItem("url");
  const getEmail = () => localStorage.getItem("email");

  // useEffect(() => {
  // }, [details]);

  useEffect(() => {
    getTenant();
    getJobs();
    getJob();
  }, []);

  const getTenant = async () => {
    const params = { tenantId: tenant };
    setLoading(true);
    const res = await post("/career/gettenant", params).then((res) => {
      setTenantData(res.data[0]);
      setLoading(false);
    });
  };

  const getJobs = async () => {
    const params = { tenantId: tenant };
    const params1 = { id_talentPool: poolId };
    if (poolId) {
      const resp = await post("/talentpool/getPoolByPoolId", params1);
      setJobs(resp?.data);
    } else {
      const res = await post("/career/getjobs", params);
      setJobs(res?.data[0]);
    }
  };

  const getJob = async () => {
    const params = { domain: domain, jobId: id };
    const params1 = { id_talentPool: poolId };
    if (poolId) {
      const resp = await post("/talentpool/getPoolByPoolId", params1);
      console.log("1212121212121======>", resp.data[0]);
      setJob(resp?.data[0]);
      setSkillsList(JSON.parse(resp?.data[0]?.skills));
    } else {
      const res = await post("/career/getrequestdetailsbyIdDS", params);
      console.log("res.data", res.data);
      setJobValid(true);
      setJob(res.data.jobData);
    }
  };

  // AutoMatch
  const automatch = async () => {
    const jobId = job.jobId;
    const payload = {
      domain: domain,
      jobId: jobId,
      job,
    };
    let res = await post(
      "/career/searchautomatchbyjobidDS",
      payload
    ).then((res) => console.log(res));
  };
  // useEffect(() => {
  //   automatch();
  // }, []);

  // CHeck email exists or not on apply
  const validate = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(mailformat) ? true : false;
  };
  const emailExists = async () => {
    if (
      candidateDetails.fName == "" ||
      candidateDetails.lName == "" ||
      candidateDetails.email == ""
    ) {
      toast.error("Please fill all details");
    }
    //  else if (
    //   candidateDetails.fName == "" &&
    //   !candidateDetails.lName == "" &&
    //   !candidateDetails.email == ""
    // ) {
    //   toast.error("Please fill first name");
    // }
    // else if (
    //   candidateDetails.fName == "" &&
    //   !candidateDetails.lName == "" &&
    //   !candidateDetails.email == ""
    // ) {
    //   toast.error("Please fill first name");
    // }
    else if (!validate(candidateDetails.email)) {
      toast.error("Enter a valid email address");
    } else {
      const params = { tenantId: tenant, email: candidateDetails.email };

      setLoading(true);
      const res = await post("/checkEmail/isEmailExist", params)
        .then((res) => {
          if (res.data.isExist === true) {
            setEmailExist(true);
            setLoading(false);
          } else if (res.data.isExist === false) {
            // window.open(`/careers/${tenant}/addtalent`, "_self");
            setLoading(false);
            // setLogin(false);
            setApplyWithEmail(false);
            // setNewUser(true);
            setAskResume(true);
            // setCreateProfile(true);
          }
        })
        .catch(() => {
          toast.error("Please try again");
          setLoading(false);
        });
    }
  };

  // forgot password
  const forgetPassword = async (e) => {
    e.preventDefault();
    console.log("first");
    if (candidateDetails.email == "") {
      toast.error("Enter your email");
    } else {
      const params = {
        email: candidateDetails.email,
        tenantName: tenantData.tenantname,
        tenantId: tenantData.id_tenant,
      };

      setLoading(true);
      const res = await post("/forgotpassword/candidate", params)
        .then((res) => {
          console.log("res", res);
          toast.success(
            `Reset Password Link has been successfully sent to email address '${candidateDetails.email}'`
          );
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          toast.error("Email Not Found");
        });
      // if (res.status === 200) {
      //   toast.success(
      //     `Reset Password Link has been successfully sent to email address '${email}'`
      //   );
      //   // setTimeout(() => history.push('/login'), 8000)
      //   setLoading(false);
      // } else {
      //   setLoading(false);
      //   toast.error("Email Not Found");
      // }
    }
  };

  // Login user

  const loginUser = async () => {
    if (candidateDetails.email == "" || password == "") {
      toast.error(
        "There are a few incomplete required fields. Please complete them to continue. "
      );
    } else {
      const params = { userName: candidateDetails.email, password: password };
      setLoading(true);
      const res = await post("/candidateLogin/", params)
        .then((res) => {
          setLoading(false);
          sessionStorage.setItem("token", res.data.token);
          sessionStorage.setItem("name", res.data.name);
          sessionStorage.setItem("userId", res.data.userId);
          setLogin(false);
          setEmailExist(false);
          toast.success("Login Successfull !");
        })
        .catch((err) => {
          setLoading(false);

          toast.error("Please check credentials");
        });
    }
  };

  // applyNowOnClick
  const applyNowOnClick = () => {
    if (sessionStorage.getItem("token") == null) {
      // setIsApplyJobModal(true);
      setLogin(true);
      setIsChecked(false);
      setIsApplyJobModal(false);
      setAskResume(false);
    } else {
      setEditTalent(true);
      fetchCandidate();
    }
  };

  // Fetch talent Details for edit
  const fetchCandidate = async () => {
    setLoading(true);
    try {
      const payload = {
        domain: domain,
        candidateId: sessionStorage.getItem("userId"),
      };
      let res = await post("/dsTalent/searchtalentbyidDS", payload);
      // res.data.resumeURL && setResumeDownload(res.data.resumeURL);
      res.data && setCandidateData(res.data);
      setLoading(false);
    } catch (error) {
      toast.error("Error Occured while accessing candidate details ");
    }
  };

  const _onChangeText = (value, country) => {
    let a = country.name;

    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    const formattedNumber = asYouType.input(
      value.replace(country.dialCode, "")
    );
    const isValid = asYouType.isValid();
    setCandidateDetails({
      ...candidateDetails,
      country: a,
      phone: value,
      phoneValidation: isValid,
    });
    // props.setContactInfo({
    //   ...props.contactInfo,
    //   mobilePhone: value,
    //   phoneValidation: isValid,
    // });
  };

  const _onChangeText2 = (value, country) => {
    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    const formattedNumber = asYouType.input(
      value.replace(country.dialCode, "")
    );
    const isValid = asYouType.isValid();
    setCandidateDetails({ ...candidateDetails, alternatePhone: value });
  };

  //get client admin details
  const getClientAdmin = async () => {
    const params = { tenantId: tenant };
    setLoading(true);
    const res = await post("/career/getclientadmin", params).then((res) => {
      setClientAdmin(res.data[0]);
      setLoading(false);
    });
  };

  useEffect(() => {
    getClientAdmin();
  }, []);

  // Recruiter Details
  const getRecruiter = () => {
    if (recruiterid === undefined) {
      setSourceChannel("Career Page");
    } else {
      getRecruiterDetails();
    }
  };
  const getRecruiterDetails = async () => {
    const params = { recruiterid: recruiterid };
    const res = await post("/career/getrecruiterbyid", params).then((res) => {
      let name = `${res.data[0].firstName} ${res.data[0].lastName}`;

      setSourceChannel(name);
      setRecruiterAgency(res.data[0].tenantname[0].tenantname);
    });
    // Agency(LIC) Oliver Davis
  };
  useEffect(() => {
    getRecruiter();
  }, []);

  // Reffer Details
  const getRefferer = () => {
    if (reffererid === "undefined") {
    } else {
      getRefferDetails();
    }
  };
  const getRefferDetails = async () => {
    const params = { reffererId: reffererid };
    const res = await post("/career/getrefferbyid", params).then((res) => {
      let name = `${res.data[0].refFirstName} ${res.data[0].refLastName}`;
      setReffererName(name);
    });
    // Agency(LIC) Oliver Davis
  };
  useEffect(() => {
    getRefferer();
  }, []);

  // apply for a job

  const submitAddTalent = () => {
    if (sessionStorage.getItem("token") == null) {
      submitAddTalent2();
    } else {
      applyJob(sessionStorage.getItem("userId"));
    }
  };

  useEffect(() => {
    valid && applyJob();
  }, [candidateId]);

  const submitAddTalent2 = async () => {
    setLoading(true);
    try {
      const res = await post("/dsTalent/addDsTalent", {
        ...addTalentData,
        FK_Tenant: parseInt(tenant),
        tenantName: tenantData.tenantname,
        jobId: id,
        sourceChannel: sourceChannel,
        recruiterId: recruiterid,
        recruiterAgency: recruiterAgency,
        reffererName: reffererName,
        legallyAuthorized: candidateDetails.legallyAuthorized,
        requireSponsorship: candidateDetails.requireSponsorship,
        experienceYear: profileData.experienceYear.toString(),
        experienceMonth: profileData.experienceMonth.toString(),
        preferredLocation: profileData.isRemote
          ? [...profileData.preferredLocation, "Remote"]
          : profileData.preferredLocation,
      });

      if (res.status === 200) {
        applyJob(res.data.value[0].key);
        setCandidateId(res.data.value[0].key);
        //console.log('TALAutomatch',res.data.value[0].key)

        if (res.data.value) {
          //   props.fetchCandidate();
          // const successData = {
          //   name: `${contactInfo.firstName} ${contactInfo.lastName}`,
          //   email: contactInfo.email,
          // };
          //   props.handleSuccessForm(successData);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 5000);
          // console.log("res.data", res.data);
          // props.message === "JoinCommunity"
          //   ? toast.success("You have successfully joined our talent community")
          //   : toast.success("Please check your email for password and email");
          // setLoading(false);
          // props.closeModal()
          // window.history.back();
          try {
            const sendDataToAutoMatch = await post(
              "/automatch/autoMatchTalent",
              {
                ...addTalentData,
                candidateID: res.data.value[0].key,
                recruiterName,
                recruiterEmail,
                tenantName,
              }
            );
          } catch (error) {
            console.log(error);
            setLoading(false);
          }
          // console.log('TALAutomatch',res.data.value[0].key)
        } else {
          // setSubmitClicked(false);
          //toast.error(res.data.ReturnText)
        }
      }

      if (reffererid !== undefined) {
        addRefferedCand();
      }
      // setSubmitClicked(false);
      // applyJob();
      setLoading(false);
    } catch (error) {
      // setSubmitClicked(false);
      console.log(error);
      toast.error("Some Error Occurred. Please Try Again");
      setLoading(false);
    }

    // Add talent to Candidate login
    const res = await post("/dsTalent/candidateLogin", {
      tenantId: tenant,
      userDetails: 233,
      userName: addTalentData.email,
    }).then((res) => console.log("res", res));
  };

  // JOin our community on score card
  const joinCommunityScoreCard = async () => {
    setLoading(true);
    try {
      const res = await post("/dsTalent/addDsTalent", {
        ...addTalentData,
        FK_Tenant: parseInt(tenant),
        tenantName: tenantData.tenantName,
        jobId: id,
        recruiterId: recruiterid,
        sourceChannel: sourceChannel,
        recruiterAgency: recruiterAgency,
        reffererName: reffererName,
        experienceYear: profileData.experienceYear.toString(),
        experienceMonth: profileData.experienceMonth.toString(),
        preferredLocation: profileData.isRemote
          ? [...profileData.preferredLocation, "Remote"]
          : profileData.preferredLocation,
      });

      if (res.status === 200) {
        // setLoading(false)
        //console.log('TALAutomatch',res.data.value[0].key)

        if (res.data.value) {
          //   props.fetchCandidate();
          // const successData = {
          //   name: `${contactInfo.firstName} ${contactInfo.lastName}`,
          //   email: contactInfo.email,
          // };
          //   props.handleSuccessForm(successData);
          // setTimeout(() => {
          //   window.location.reload();
          // }, 5000);
          // console.log("res.data", res.data);
          // props.message === "JoinCommunity"
          //   ? toast.success("You have successfully joined our talent community")
          //   : toast.success("Please check your email for password and email");
          // setLoading(false);
          // props.closeModal()
          // window.history.back();
          try {
            const sendDataToAutoMatch = await post(
              "/automatch/autoMatchTalent",
              {
                ...addTalentData,
                candidateID: res.data.value[0].key,
                recruiterName,
                recruiterEmail,
                tenantName,
              }
            );
          } catch (error) {
            console.log(error);
            setLoading(false);
          }
          // console.log('TALAutomatch',res.data.value[0].key)
        } else {
          // setSubmitClicked(false);
          //toast.error(res.data.ReturnText)
        }
      }

      if (reffererid !== undefined) {
        addRefferedCand();
      }
      // setSubmitClicked(false);
      setScoreCard(false);
      setLoading(false);
      toast.success("You have successfully joined our talent community");
    } catch (error) {
      // setSubmitClicked(false);
      console.log(error);
      setLoading(false);
      setScoreCard(false);
      toast.error("Some Error Occurred. Please Try Again");
    }

    // Add talent to Candidate login
    const res = await post("/dsTalent/candidateLogin", {
      tenantId: tenant,
      userDetails: 233,
      userName: addTalentData.email,
    }).then((res) => console.log("res", res));
  };
  const addRefferedCand = async () => {
    console.log("funcion entered");
    let date = new Date();
    setLoading(true);
    const params = {
      FirstName: addTalentData.firstName,
      LastName: addTalentData.lastName,
      Email: addTalentData.email,
      ref_candUniqueId: reffererid,
      jobTitle: job?.jobTitle,
      refDate: date,
      jobId: id,
    };
    const res = await post("/canReferal/updateCandidate", params)
      .then((res) => {
        setLoading(false);
        toast.success("Referal Candidate added");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Unable to add candidate");
      });
  };

  const applyJob = async (id) => {
    const params = {
      jobId: poolId ? job.id_talentPool : job.jobId.toString(),
      jobTitle: job.jobTitle,
      jobTenant: job.FK_Tenant,
      // jobLocation: job.isRemote === "true" ? "Remote" : null,
      jobLocation: job.workPlaceType,
      primarySkills: job.primarySkills,
      jobType: job.jobType,
      // location: job.workPlaceType,
      recruiterId: clientAdmin.userId.toString(),
      recruiterName: `${clientAdmin.firstName} ${clientAdmin.lastName}`,
      recruiterEmail: clientAdmin.email,
      recruiterTenant: tenantData.tenantName,
      candidateId: id, // id
      candidateFname: addTalentData.firstName,
      candidateMname: "",
      candidateLname: addTalentData.lastName,
      candidateEmail: addTalentData.email,
      candidateLocation: `${addTalentData.addressCity},${addTalentData.country}`,
      candidateImageURL: "",
      candidateTenant: job.FK_Tenant,
      payRate: addTalentData.preferredSalary,
      payRateCurrency: addTalentData.preferredSalaryCurrency,
      jobStatus: "active", // jobStatus
      candidateStatus: "", // candidateStatus
    };
    setLoading(true);

    let url = poolId
      ? "/applyJob/invitedUserForPool"
      : recruiterid !== undefined
      ? "/applyJob/invitedUser"
      : "/applyJob/";
    const res = await post(url, params)
      .then((res) => {
        // setTenantData(res.data[0]);
        setLoading(false);
        if (job.vettingRequired === false) {
          toast.success("Successfully applied for the job");
          setCreateProfile(false);
          setLoading(false);
          setScoreCard(false);
          setCandidateDetails(data);
        } else {
          setIsVetting(true);
          setScoreCard(false);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        setCandidateDetails(data);
      });
  };

  // getVetting

  const onClickLater = (event) => {
    vettingMail(event);
  };
  const fetchVetting = async () => {
    // console.log("job", job);
    let vetting =
      typeof job.vettingDetails === "string"
        ? JSON.parse(job.vettingDetails)
        : job.vettingDetails;
    // setVettingDetails(vettingData)
    // console.log("vettingData", vetting);
    // fetchVetting(vettingData);
    // setLoading(true);
    const res = await axios.get(
      "https://https://high5vettinguat-api.azurewebsites.net/test/all",
      {
        headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
      }
    );
    let vett = res.data.filter((i) => i.skills.length === 1);
    console.log("vettingResponse", res.data);
    setVettingDB(vett);
    let vettings = [];
    let skills = vett.map((i) => i.skills[0]);
    let DB =
      vetting !== undefined &&
      [...vetting]?.map((i) => ({
        ...i,
        duration: i.duration?.split(" ")[0],
        type: i.type === "Video MCQ" ? "Video" : i.type,
      }));

    DB.map((item) => {
      if (skills.includes(item.name)) {
        let obj = vett.find((i) => i.skills.includes(item.name));
        if (
          obj.testCategory === item.type &&
          obj.details.duration === item.duration
        ) {
          vettings.push(obj._id);
        }
      }
    });

    setVettingAssign(vettings);
    setFetchVettingData(vettings);
    setLoading(false);
  };
  useEffect(() => {
    if (job?.vettingRequired !== false) {
      if (poolId) {
      } else {
        jobValid && fetchVetting();
      }
    }
  }, [job]);

  const vettingMail = async (event) => {
    setLoading(true);
    console.log("VettingMails");
    console.log("fetchVettingData", fetchVettingData);
    if (fetchVettingData.length > 0) {
      let body = {
        reviewer: {
          name: "",
          email: "",
        },
        createdBy: {
          id: "62fb9ad4cb6714dd6540e2cf",
          name: "John Wang",
          role: "user",
        },
        modifiedBy: {
          id: "62fb9ad4cb6714dd6540e2cf",
          name: "John Wang",
          role: "user",
        },
        name: addTalentData.firstName,
        email: addTalentData.email,
        recruiterEmail: "",
        high5hireCandidateId: candidateId,
        jobId: job.jobId,
        testAssign: {
          id: fetchVettingData[0],
        },
        testInvited: true,
        expiryDays: 40,
        takesForTest: 3,
      };
      const res = await axios.post(
        "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
        body,
        {
          headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
        }
      );
      if (fetchVettingData.length > 1) {
        const res2 = await axios.post(
          "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
          { ...body, testAssign: { id: fetchVettingData[1] } },
          {
            headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
          }
        );
      }
      if (fetchVettingData.length > 2) {
        const res2 = await axios.post(
          "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
          { ...body, testAssign: { id: fetchVettingData[2] } },
          {
            headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
          }
        );
      }
      setLoading(false);
      event === "now" ? setVettingNow(true) : setVettingLater(true);
    }
  };

  // Auto suggestion in tags input
  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.SourceName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue?.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropDownData.CandidateSkills.filter((item) =>
              item.SourceName.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion.SourceName}</div>;

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.SourceName);
        }}
        theme={theme}
      />
    );
  };

  const handleChangePrimarySkills = (skills) => {
    setCandidateDetails({ ...candidateDetails, primarySkills: skills });
  };

  const handleChangeInputPrimarySkills = (skills) => {
    setPrimarySkillInput(skills);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="">
        {/* Header */}

        <TopNavbar tenantData={tenantData} />

        {/* Job Header */}

        {!joinCommunity ? (
          // For apply job
          <div className="row mx-auto" style={{ width: "85%" }}>
            <div className="col-md-8 mt-5 ">
              <h2>{job?.jobTitle}</h2>

              <div className="row w-100 mt-3">
                {job?.workPlaceType === "On-Site" && (
                  <div className="col-md-6 text-secondary">
                    <GoLocation className="mr-2" />
                    {`${job.location?.city}, ${job.location?.state}, ${job.location?.country}`}
                  </div>
                )}
                {job?.workPlaceType === "Hybrid" && (
                  <div className="col-md-6 text-secondary">
                    <GoLocation className="mr-2" />
                    {`${job.location?.city}, ${job.location?.state}, ${job.location?.country}`}
                  </div>
                )}
                {(job?.workPlaceType === "Remote" ||
                  job?.workplaceType === "1") && (
                  <div className="col-md-3 text-secondary">
                    <GoLocation className="mr-2" />
                    {"Remote"}
                  </div>
                )}

                <div className="col-md-3 text-secondary">
                  {" "}
                  <BsBriefcase className="mr-2" />
                  {job?.jobType}
                </div>
                <div className="col-md-3 text-secondary">
                  <AiOutlineCalendar className="mr-2" />
                  {moment(job?.createddatetime).format("MM/DD/YYYY")}
                </div>
              </div>

              <div className=" w-70 mt-3"></div>
            </div>

            <div className="col-md-4 mt-5 p-4 text-center">
              <span className="text-secondary ">Application Ends: </span>
              <span className="text-danger">
                {moment(job?.expiresOn).format("MM/DD/YYYY")}
              </span>
              <br />
              <button
                className="btn btn-sm mt-3 w-75"
                onClick={() => {
                  // sessionStorage.getItem("token") == null
                  //   ? setLogin(true)
                  //   : setIsApplyJobModal(true);
                  // sessionStorage.getItem("token") == null ?
                  // setIsApplyJobModal(true) : setEditTalent(true)
                  applyNowOnClick();
                }}
                disabled={isLogIn && true}
              >
                Apply Now
              </button>
            </div>
          </div>
        ) : (
          // For talent community
          <div className="row mx-auto" style={{ width: "85%" }}>
            <div className="col-md-12 mx-auto mt-5 ">
              <h2 className="text-center">Join our talent community</h2>

              <div className="row  mt-3">
                <div className="col-md-12 text-secondary ">
                  <p className="text-center">
                    If you are thinking about a future with us, sign up to stay
                    connected and be informed of any new opportunities
                  </p>
                </div>
              </div>

              <div className=" w-70 mt-3"></div>
            </div>

            {/* <div className="col-md-4 mt-5 p-4 text-center">
          <span className="text-secondary ">Application Ends: </span>
          <span className="text-danger">
            {moment(job?.expiresOn).format("MM/DD/YYYY")}
          </span>
          <br />
          <button
            className="btn btn-sm mt-3 w-75"
            onClick={() => setLogin(true)}
            disabled={isLogIn && true}
          >
            Apply Now
          </button>
        </div> */}
          </div>
        )}

        <div style={{ height: "50px" }}></div>

        {/* Job Description */}
        {isLogIn || joinCommunity ? (
          <>
            <div className="row mx-5 my-3" style={{ width: "90%" }}>
              <CandidateProfile
                // handleSuccessForm={props.handleSuccessForm}
                parent="JobDecription"
                updatedParsed={updatedParsed}
                parsedData={parsedData}
                parsed={parsed}
                setJoinCommunity={setJoinCommunity}
                setIsLogIn={setIsLogIn}
                reffererid={reffererid}
                email={email}
                job={job}
                // fetchCandidate={props.fetchCandidate}
                // closeModal={props.closeModal}
                // existingCandidateList={existingCandidateList}
              />
            </div>
          </>
        ) : (
          <div className="row mx-auto my-3" style={{ width: "85%" }}>
            <div className="col-md-7">
              <h6>Job Description</h6>
              <p className="text-secondary">
                <ol
                  className="pr-3"
                  dangerouslySetInnerHTML={{ __html: job.jobDescription }}
                ></ol>
              </p>

              <h6 className="mt-4">Skills</h6>
              <p className="text-secondary ">
                <ol className="ml-3">
                  {poolId
                    ? skillsList?.map((elem, idx) => <li key={idx}>{elem}</li>)
                    : job?.primarySkills?.map((elem, idx) => (
                        <li key={idx}>{elem}</li>
                      ))}
                </ol>
              </p>
            </div>

            <div className="col-md-5 ps-2 bg-light">
              <h6>Job Overview</h6>

              <p>
                <MdAttachMoney className="mr-2" color="aqua" />
                Offered Salary <br />
                <small className="text-secondary ml-4">
                  {" "}
                  ${job.minimumPay} - ${job.maximumPay} per year
                </small>
              </p>

              <p>
                <FaUserTie className="mr-2" color="aqua" />
                Experience <br />
                <small className="text-secondary ml-4">
                  {" "}
                  {job.experienceLevel}
                </small>
              </p>
              <p>
                <BsBriefcase className="mr-2" color="aqua" />
                Workplace Type <br />
                {/* <small className="text-secondary ml-4">
                  {job.workPlaceType === "On-Site" &&
                    `OnSite-${job?.location?.city},${job?.location?.state},${job?.location?.country}`}
                  {job?.workPlaceType === "Hybrid" &&
                    `Hybrid-${job?.location?.city},${job?.location?.state},${job?.location?.country}`}
                  {job?.workPlaceType === "Remote" && "Remote"}
                </small> */}
                {job.workPlaceType == "Hybrid" ? (
                  <small className="text-secondary ml-4">
                    {`${job.workPlaceType} - ${job.onsiteWorkDays}`}
                  </small>
                ) : (
                  <small className="text-secondary ml-4">
                    {job.workPlaceType}
                  </small>
                )}
              </p>

              <p>
                <MdOutlineMonitor className="mr-2" color="aqua" />
                Company Name <br />
                <small className="text-secondary ml-4">
                  {tenantData.tenantname}
                </small>
              </p>

              {/* <p>
                <BsBuilding className="mr-2" color="aqua" />
                Workplace <br />
                {job.Hybrid !== 0 ? (
                  <small className="text-secondary ml-4">Hybrid</small>
                ) : job.Onsite ? (
                  <small className="text-secondary ml-4">Onsite</small>
                ) : (
                  <small className="text-secondary ml-4">Remote</small>
                )}
              </p> */}
              <p className="text-center my-5">
                <button
                  className="btn btn-secondary btn-sm w-50"
                  onClick={() => setIsRefer(true)}
                >
                  Refer Someone
                </button>
                <br />
                {/* <button
                  className="btn mt-3 btn-sm w-50"
                  onClick={() => setLogin(true)}
                >
                  Apply for this job now <BsArrowRight />
                </button> */}
              </p>
            </div>
          </div>
        )}

        {/* Talent Community */}

        <div className="Section ">
          <div className="Container ">
            <TalentCommunity setJoinCommunity={setJoinCommunity} />
          </div>
        </div>

        {/* Footer */}
        <div className="row mx-auto mb-3" style={{ width: "85%" }}>
          <div className="col-md-7">
            <footer>
              <div>
                <span className="text-secondary">Powered by</span>
                <a>
                  <img
                    style={{
                      width: 40,
                      height: 35,
                      paddingBottom: 10,
                      marginLeft: 5,
                    }}
                    src={high5Logo}
                  />
                </a>
                <p className=" text-secondary">
                  Privacy Policy and Terms of Service
                </p>
              </div>
            </footer>
          </div>
        </div>
        {isRefer && <Refer setIsRefer={setIsRefer} isRefer={isRefer} />}
      </div>

      {/* Terms and condition */}
      <Modal
        show={isApplyJobModal}
        size="lg"
        className="modal fade center"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-header" id="CreateQuestionModal">
          <h4 className="modal-title mx-3" id="CreateQuestionModalLabel">
            Terms and Conditions
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setIsApplyJobModal(false);
              setIsChecked(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body style={{ backgroundColor: "#F3F5F7", padding: "30px" }}>
          <div className="">
            <ol className="ml-3">
              <li className="my-3 ">
                {" "}
                <strong> {`High5 Terms of Service`}</strong>
                <a href="https://www.high5hire.com/" target="_blank">
                  {" "}
                  <RiShareBoxLine />{" "}
                </a>{" "}
              </li>

              <li
                className="my-3 p-2 d-flex justify-content-between"
                style={{ backgroundColor: "#EEEEEE", cursor: "pointer" }}
                onClick={() => setPersonalInfo(!personalInfo)}
              >
                <h6> {`High5 Personal Information Collection Statement`}</h6>
                {!personalInfo ? (
                  <AiOutlinePlusSquare />
                ) : (
                  <AiOutlineMinusSquare />
                )}
              </li>
              {personalInfo && (
                <small>
                  {`High5 will collect, use and disclose your personal
                  information to connect you with companies (and their service
                  providers) for current and future recruitment and talent
                  pooling purposes, to personalise our services for you
                  (including providing job and company recommendations), and to
                  promote the business and services of High5Hire, its clients
                  and partners. Our Privacy Policy further explains how personal
                  information is collected, used and disclosed and how to
                  access, correct or complain about the handling of personal
                  information. Please note that High5Hire will process your data
                  in Australia where privacy laws may differ to where you are
                  located. Where you are an individual located in the EU or when
                  we handle your information that is subject to the EU General
                  Data Protection Regulation (GDPR) and High5Hire is the data
                  controller of your information, the terms of our Privacy
                  Policy apply to how we handle your personal information in
                  compliance with the GDPR.`}
                </small>
              )}

              <li
                className="my-3 p-2 d-flex justify-content-between"
                style={{ backgroundColor: "#EEEEEE", cursor: "pointer" }}
                onClick={() => setElectronicMessages(!electronicMessages)}
              >
                <h6> Commercial Electronic Messages Consent</h6>
                {!electronicMessages ? (
                  <AiOutlinePlusSquare />
                ) : (
                  <AiOutlineMinusSquare />
                )}
              </li>
              {electronicMessages && (
                <small>
                  {`I consent to High5 and parties with whom I am connected
                  sending me commercial electronic messages related to job
                  opportunities in the Talent Communities that I join and their
                  products and services.`}
                </small>
              )}

              <li className="my-3">
                <strong> {`${tenantData.tenantname} Privacy Statement`}</strong>
                <a href="https://www.high5hire.com/" target="_blank">
                  {" "}
                  <RiShareBoxLine />
                </a>
              </li>
              <li className="my-3">
                <strong>
                  {" "}
                  {`${tenantData.tenantname} Candidate Data Privacy Policy`}
                </strong>

                <a href="https://www.high5hire.com/" target="_blank">
                  {" "}
                  <RiShareBoxLine />
                </a>
              </li>
              <li className="my-3">
                <h6> Electronic Signature Authorisation</h6>
                <small>
                  {` I agree that the above authorisations and consents given by me
                  are valid as a signed document and shall continue to be
                  effective throughout the duration of my membership of any
                  Talent Community, the recruitment process and related
                  employment activities and until I request deletion of my
                  High5 account.`}
                </small>
              </li>
            </ol>
            <hr />
            <div className="d-flex">
              <span className="mt-4">
                <input
                  type="checkbox"
                  onClick={(e) => setIsChecked(e.target.checked)}
                />
              </span>
              <h6 className="ml-2 mt-4">
                I agree that I have read and understand the above terms and
                consent to proceeding on the basis of those terms.
              </h6>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#F3F5F7" }}>
          <button
            style={{ float: "right", width: 120 }}
            className="mb-4 btn btn-sm btn-secondary"
            onClick={() => {
              setIsChecked(false);
              setIsApplyJobModal(false);
            }}
          >
            Decline
          </button>

          <button
            style={{ float: "right", width: 120 }}
            className=" mb-4 btn btn-sm btn-primary"
            // disabled={!isChecked ? true : false}
            onClick={() => {
              setLogin(true);
              setIsChecked(false);
              setIsApplyJobModal(false);
              setAskResume(false);
            }}
          >
            Accept
          </button>
        </Modal.Footer>
      </Modal>

      {/* Apply Modal */}
      <Modal show={login} size={askResume && "lg"}>
        <Modal.Header>
          <div>{askResume ? <h6>Apply</h6> : <h6>Apply With</h6>}</div>
          <div>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setApplyWithEmail(false);
                setLoginWithEmail(false);
                setLogin(false);
                setEmailExist(false);
                setNewUser(false);
                setCandidateDetails(data);
                setIsFormValid(false);
              }}
              class="close p-0 bl-modal-close-btn mx-1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal.Header>
        {askResume === false && (
          <Modal.Body>
            <div className="d-flex flex-column mx-5 mb-3 ">
              <GoogleLogin
                clientId="519239579073-tt3kushg6kpichbf8m7u84reocsq14oh.apps.googleusercontent.com"
                onSuccess={(res) => {
                  let { name, imageUrl, email } = res.profileObj;
                  handleLogin(name, imageUrl, email);
                }}
                onFailure={(res) => console.log(res)}
                render={(renderProps) => (
                  <button
                    className="btn btn-secondary m-2 w-100"
                    onClick={renderProps.onClick}
                  >
                    <FcGoogle /> Apply with Google
                  </button>
                )}
              />
              <LoginSocialFacebook
                appId="631575145234560"
                onLoginStart={() => console.log("Starting")}
                onResolve={({ provider, data }) => {
                  let { email, name, picture } = data;
                  handleLogin(name, picture.data.url, email);
                }}
                onReject={(err) => {
                  console.log("Error", err);
                }}
                autoLoad={true}
              >
                <button className="btn btn-secondary m-2 w-100">
                  <BsFacebook /> Apply with Facebook
                </button>
              </LoginSocialFacebook>
              {/* <LoginSocialLinkedin client_id="86hjfnxy6nfook">
                <button className="btn btn-secondary m-2 w-100" disabled>
                  <BsLinkedin /> Apply with Linkedin
                </button>
              </LoginSocialLinkedin> */}
              <button
                className="btn btn-secondary m-2 w-100"
                onClick={() => {
                  setLoginWithEmail(false);
                  setApplyWithEmail(!applyWithEmail);
                  setEmailExist(false);
                }}
              >
                <MdEmail className="" /> Apply with Email
              </button>
              {/* <button
              className="btn btn-secondary m-2 w-100"
              onClick={() => {
                setApplyWithEmail(false);
                setLoginWithEmail(true);
              }}
            >
              <MdEmail className="" /> Login with Email
            </button> */}
            </div>

            {/* Ask for email */}
            {applyWithEmail && (
              <div>
                <div className="row mx-5">
                  <div className="col-lg-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={"form-control"}
                        name="groupName"
                        placeholder="Enter a group name"
                        onChange={(e) =>
                          setCandidateDetails({
                            ...candidateDetails,
                            fName: e.target.value.replace(/[^a-zA-Z\s]/, ""),
                          })
                        }
                        // value={groupData?.poolName}
                      />
                      <label>First Name*</label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={"form-control"}
                        name="groupName"
                        placeholder="Enter a group name"
                        onChange={(e) =>
                          setCandidateDetails({
                            ...candidateDetails,
                            lName: e.target.value.replace(/[^a-zA-Z\s]/, ""),
                          })
                        }
                        // value={groupData?.poolName}
                      />
                      <label>Last Name*</label>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-column mx-5 my-3">
                  <div className="form-floating ml-1">
                    <input
                      type="text"
                      className={"form-control"}
                      name="groupName"
                      placeholder="Enter a group name"
                      onChange={(e) =>
                        setCandidateDetails({
                          ...candidateDetails,
                          email: e.target.value,
                        })
                      }
                      // value={groupData?.poolName}
                    />
                    <label>Email*</label>
                  </div>
                </div>
                {/* <div className="d-flex flex-column mx-5 mb-2">
            <label>Password</label>
            <input type="text"></input>
          </div> */}
                {emailExist && (
                  <div className="d-flex flex-column mx-5 my-3">
                    <div className="form-floating ml-1">
                      <input
                        type="password"
                        className={"form-control"}
                        name="groupName"
                        placeholder="Enter a group name"
                        onChange={(e) => setPassword(e.target.value)}
                        // value={groupData?.poolName}
                      />
                      <label>Password*</label>
                    </div>
                    <p className="text-right my-1">
                      <a href="" onClick={forgetPassword}>
                        Forgot Password?
                      </a>
                    </p>
                  </div>
                )}
                {emailExist && (
                  <div className="row mx-5">
                    <button
                      className="btn btn-secondary m-2 w-100"
                      onClick={loginUser}
                    >
                      Login
                    </button>
                  </div>
                )}
                {emailExist === false && (
                  <div className="row mx-5 mb-2">
                    <button
                      className="btn btn-secondary m-2 w-100"
                      onClick={emailExists}
                    >
                      Submit
                    </button>
                  </div>
                )}

                {/* {emailExist && (
              <div className="d-flex align-items-center mx-5 my-3">
                <div>
                  <input type="checkbox"></input>
                </div>
                <div className="ml-2">Remember me</div>
                <div className="ml-auto">
                  <a href="/">Forgot Password</a>
                </div>
              </div>
            )} */}
              </div>
            )}
            {/* SignUp Page 1 */}
            {newUser && (
              <div>
                <div className="row mx-5">
                  <div className="col-lg-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={"form-control"}
                        name="groupName"
                        placeholder="Enter a group name"
                        onChange={(e) =>
                          setCandidateDetails({
                            ...candidateDetails,
                            fName: e.target.value,
                          })
                        }
                        // value={groupData?.poolName}
                      />
                      <label>First Name*</label>
                    </div>
                  </div>

                  <div className="col-lg-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className={"form-control"}
                        name="groupName"
                        placeholder="Enter a group name"
                        onChange={(e) =>
                          setCandidateDetails({
                            ...candidateDetails,
                            lName: e.target.value,
                          })
                        }
                        // value={groupData?.poolName}
                      />
                      <label>Last Name*</label>
                    </div>
                  </div>
                </div>
                <div className="row mx-5">
                  <div className="col-lg-12">
                    <div className="form-floating my-3">
                      <input
                        type="text"
                        className={"form-control"}
                        name="groupName"
                        placeholder="Enter a group name"
                        onChange={(e) =>
                          setCandidateDetails({
                            ...candidateDetails,
                            email: e.target.value,
                          })
                        }
                        value={candidateDetails.email}
                      />
                      <label>Email*</label>
                    </div>
                  </div>
                </div>
                <div className="row mx-5 mb-2">
                  <button
                    className="btn btn-secondary m-2 w-100 "
                    onClick={() => {
                      if (
                        candidateDetails.fName == "" ||
                        candidateDetails.lName == "" ||
                        candidateDetails.email == "" ||
                        candidateDetails.city == "" ||
                        candidateDetails.currentPosition == "" ||
                        candidateDetails.experienceMonth == "0" ||
                        candidateDetails.experienceYear == "0"
                      ) {
                        toast.error(
                          "There are a few incomplete required fields. Please complete them to continue. "
                        );
                      } else {
                        setAskResume(true);
                      }
                    }}
                  >
                    Continue
                  </button>
                </div>
              </div>
            )}

            {/* Page 2 */}

            {/* {loginWithEmail && (
            <div>
              <div className="d-flex flex-column mx-5 my-3">
                <div className="form-floating ml-1">
                  <input
                    type="text"
                    className={"form-control"}
                    name="groupName"
                    placeholder="Enter a group name"
                    onChange={handleChange}
                    value={groupData?.poolName}
                  />
                  <label>Email*</label>
                </div>
              </div>
              <div className="d-flex flex-column mx-5 mb-2">
            <label>Password</label>
            <input type="text"></input>
          </div>
              <div className="d-flex flex-column mx-5 my-3">
                <div className="form-floating ml-1">
                  <input
                    type="text"
                    className={"form-control"}
                    name="groupName"
                    placeholder="Enter a group name"
                    onChange={handleChange}
                    value={groupData?.poolName}
                  />
                  <label>Password*</label>
                </div>
              </div>
              <div className="row mx-5">
                <button className="btn btn-secondary m-2 w-100">Login</button>
              </div>

              <div className="d-flex align-items-center mx-5 my-3">
                <div>
                  <input type="checkbox"></input>
                </div>
                <div className="ml-2">Remember me</div>
                <div className="ml-auto">
                  <a href="/">Forgot Password</a>
                </div>
              </div>
            </div>
          )} */}

            {/* Login */}
          </Modal.Body>
        )}

        {askResume && (
          <Modal.Body>
            <div>
              <div className="row mx-5">
                <div className="col-lg-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={"form-control"}
                      name="fName"
                      placeholder="Enter a group name"
                      onChange={handleChange}
                      value={candidateDetails.fName}
                      // value={candidateDetails.fName}
                    />
                    <label>First Name*</label>
                  </div>

                  {isFormValid && candidateDetails.fName == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>

                <div className="col-lg-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={"form-control"}
                      name="lName"
                      placeholder="Enter a group name"
                      onChange={handleChange}
                      value={candidateDetails.lName}
                    />
                    <label>Last Name*</label>
                  </div>
                  {isFormValid && candidateDetails.lName == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>
                <div className="col-lg-4">
                  <div className="form-floating ">
                    <input
                      type="text"
                      className={"form-control"}
                      name="email"
                      placeholder="Enter a group name"
                      onChange={handleChange}
                      value={candidateDetails.email}
                      // initialValue={email}
                    />
                    <label>Email*</label>
                  </div>
                  {isFormValid && candidateDetails.email == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>
              </div>

              {/* <div className="row mx-5">
                <div className="col-lg-12">
                  <div className="form-floating mt-3">
                    <input
                      type="text"
                      className={"form-control"}
                      name="groupName"
                      placeholder="Enter a group name"
                      // onChange={handleChange}
                      value={email}
                    />
                    <label>Email*</label>
                  </div>
                </div>
              </div> */}
              <div></div>
              <div className="row mx-5">
                <div className="col-lg-6">
                  <div className="mb-2">
                    <label>What is your preferred phone?*</label>
                  </div>
                  <div className="d-flex">
                    <PhoneInput
                      style={{ zIndex: "999" }}
                      // classsName="form-control"
                      // value={props.contactInfo.mobilePhone}
                      containerClass="w-100"
                      inputClass="w-100"
                      value={candidateDetails.phone}
                      name="mobilePhone"
                      country={"us"}
                      onChange={(value, country) => {
                        _onChangeText(value, country);
                      }}
                    />
                  </div>
                  {/* {!candidateDetails.phoneValidation && (
                    <small className="validation">Invalid Phone</small>
                  )} */}
                  {isFormValid && candidateDetails.phone == "" ? (
                    <small className="validation">*Required Field</small>
                  ) : (
                    !candidateDetails.phoneValidation && (
                      <small className="validation">Invalid Phone</small>
                    )
                  )}
                </div>

                {/* <div className="col-lg-6">
                  <div className="mb-2">
                    <label>What is your alternative phone?</label>
                  </div>
                  <div className="d-flex">
                    <PhoneInput
                      style={{ zIndex: "999" }}
                      classsName="form-control"
                      containerClass="w-100"
                      inputClass="w-100"
                      name="homePhone"
                      country={"us"}
                      value={candidateDetails.alternatePhone}
                      onChange={(value, country) => {
                        _onChangeText2(value, country);
                      }}
                    />
                  </div>
                
                </div> */}
                {/* Primary Skills */}
                {/* <div class="row " style={{ marginTop: "-5px" }}> */}
                <div class="col-6">
                  <div class=" " style={{ marginBottom: "8px" }}>
                    <label>
                      What are your primary skills?*{" "}
                      <small>(only 3 skills)</small>{" "}
                    </label>
                  </div>
                  <div>
                    <TagsInput
                      className="inputHeight"
                      renderInput={autocompleteRenderInput}
                      value={candidateDetails.primarySkills}
                      onChange={handleChangePrimarySkills}
                      inputValue={primarySkillinput}
                      onChangeInput={handleChangeInputPrimarySkills}
                      placeholder="Type a location and hit enter"
                      maxTags="3"
                      inputProps={{
                        placeholder: "Add a skill",
                      }}
                    />
                    {isFormValid &&
                      candidateDetails.primarySkills.length == 0 && (
                        <small className="validation">*Required Field</small>
                      )}
                  </div>
                </div>
              </div>

              <div className="row mx-5 my-3">
                <div className="col-lg-6">
                  <div className="form-floating ">
                    <input
                      type="text"
                      className={"form-control"}
                      name="city"
                      value={candidateDetails.city}
                      placeholder="Enter a group name"
                      onChange={handleChange}
                      // value={groupData?.poolName}
                    />
                    <label>In which city do you live?*</label>
                  </div>
                  {isFormValid && candidateDetails.city == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={"form-control"}
                      name="country"
                      placeholder="Enter a group name"
                      value={candidateDetails.country}
                      // initialValue={candidateDetails.country}
                      onChange={handleChange}
                    />
                    <label>Which country do you live?*</label>
                  </div>
                  {isFormValid && candidateDetails.country == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>
              </div>

              <div className="row mx-5">
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={"form-control"}
                      name="currentPosition"
                      placeholder="Enter a group name"
                      onChange={handleChange}
                      value={candidateDetails.currentPosition}
                    />
                    <label>What is your current job title?*</label>
                  </div>
                  {isFormValid && candidateDetails.currentPosition == "" && (
                    <small className="validation">*Required Field</small>
                  )}
                </div>

                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={"form-control"}
                      name="currentEmployer"
                      placeholder="Enter a group name"
                      onChange={handleChange}
                      value={candidateDetails.currentEmployer}
                    />
                    <label>Who is your current employer?</label>
                  </div>
                </div>
              </div>

              <div class="row mx-5">
                <div class="col-lg-6">
                  <label>How many years of experience do you have?*</label>
                </div>
              </div>

              <div class="row mx-5">
                <div class="col-lg-6">
                  <div class="form-floating">
                    <div className="d-flex">
                      <div className="mr-3 form-floating">
                        <input
                          type="number"
                          className="form-control"
                          min="0"
                          max="100"
                          style={{
                            height: "57px",
                            border: "1px solid #ced4da",
                            fontSize: "15px",
                          }}
                          value={candidateDetails.experienceYear}
                          name="experienceYear"
                          // defaultValue={props.profileInfo.experienceYear}
                          defaultValue="0"
                          onChange={(e) => {
                            if (e.target.value === "0") {
                              toast.error("Cannot Be Zero");
                            } else if (+e.target.value < 0) {
                              toast.error("Negative numbers are not allowed");
                            } else {
                              handleChange(e);
                              // handleChangeExperienceYear(e);
                            }
                          }}
                          placeholder="Year"
                          // disabled={props.profileInfo.isFresher}
                        />
                        <label>Years</label>
                        {isFormValid &&
                          candidateDetails.experienceYear == "0" && (
                            <small className="validation">
                              *Required Field
                            </small>
                          )}
                        {/* {props.profileInfo.isFresher === false && (
                      <small className="validation">
                        {props.profileInfoValidation.experienceYear}
                      </small>
                    )} */}
                      </div>

                      <div className=" form-floating ">
                        <input
                          type="number"
                          className="form-control "
                          min="0"
                          style={{
                            height: "57px",
                            border: "1px solid #ced4da",
                            fontSize: "15px",
                            // width: "89%",
                          }}
                          name="experienceMonth"
                          value={candidateDetails.experienceMonth}
                          defaultValue="0"
                          onChange={(e) => {
                            if (+e.target.value < 0) {
                              toast.error("Negative numbers are not allowed");
                            } else {
                              handleChange(e);
                              // handleChangeExperienceMonth(e);
                            }
                          }}
                          placeholder="Months"
                          // disabled={props.profileInfo.isFresher}
                        />
                        <label>Months</label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 ">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="educationType"
                      name="highestEducation"
                      // style={{
                      //   border:
                      //     props.educationValidation[i].educationType &&
                      //     "2px solid #ff0000",
                      // }}
                      // onChange={(e) => handleChange(e, i)}
                      onChange={handleChange}
                      value={candidateDetails.highestEducation}
                    >
                      {/* <optionFApp
                        value={educationTypes.map(
                          (item) => item == candidateDetails.highestEducation
                        )}
                        selected
                      >
                        Select Type
                      </optionFApp> */}
                      {educationTypes.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <label>Highest Level of Education</label>
                  </div>
                  {/* <small className="validation">
                    {props.educationValidation[i].educationType}
                  </small> */}
                </div>
              </div>

              <div class="row mx-5">
                <div
                  class="form-floating my-2"
                  // style={{ margin: "15px 0px 0px 50px" }}
                >
                  <div className=" form-switch d-flex align-items-center pl-0">
                    <label
                      className="form-check-label pt-0"
                      htmlFor="remoteSwitch"
                    >
                      Are you legally authorized to work in the United States?
                    </label>
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="isFresher"
                      // checked={candidateDetails.legallyAuthorized}
                      value={candidateDetails.legallyAuthorized}
                      onChange={
                        (e) => {
                          if (e.target.checked) {
                            setLegalAuth(true);
                            setCandidateDetails({
                              ...candidateDetails,
                              legallyAuthorized: true,
                              requireSponsorship: false,
                            });
                          } else {
                            setLegalAuth(false);
                          }
                          // e.target.checked
                          // ? setLegalAuth(true)
                          // : setLegalAuth(false)
                        }
                        // props.setProfileInfo({
                        //   ...props.profileInfo,
                        //   isRemote: e.target.checked,
                        // })
                      }
                    />
                  </div>
                </div>
              </div>
              {!legalAuth && (
                <div class="row mx-5">
                  <div
                    class="form-floating my-2"
                    // style={{ margin: "15px 0px 0px 50px" }}
                  >
                    <div className=" form-switch d-flex align-items-center pl-0">
                      <label
                        className="form-check-label pt-0 w-75"
                        htmlFor="remoteSwitch"
                      >
                        Do you now or in the future require sponsorship for an
                        immigration-related benefit?
                      </label>

                      <input
                        className="form-check-input ml-3"
                        type="checkbox"
                        id="isFresher"
                        // checked={candidateDetails.requireSponsorship}
                        value={candidateDetails.requireSponsorship}
                        onChange={(e) => {
                          if (e.target.checked) {
                            // setLegalAuth(true)
                            setCandidateDetails({
                              ...candidateDetails,
                              legallyAuthorized: false,
                              requireSponsorship: true,
                            });
                          }
                          // else{
                          //   setLegalAuth(false)
                          // }
                          // e.target.checked
                          // ? setLegalAuth(true)
                          // : setLegalAuth(false)
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}

              {/* Resume */}

              <div className="row mx-5">
                <div className="col-md-12">
                  <ApplyJob
                    updateParsedData={(data, value) =>
                      updatedParsed(data, value)
                    }
                  />
                </div>
              </div>
              <div className="d-flex align-items-center mx-5">
                <span className="">
                  <input
                    type="checkbox"
                    onClick={(e) => setIsChecked(e.target.checked)}
                  />
                </span>
                <h6 className="ml-2 mt-4">
                  I agree that I have read and understand the High5 terms and
                  consent to proceeding on the basis of those terms.
                </h6>
              </div>
              <div className="row mx-5 mb-2">
                <hr />
                <div className="col-md-8"></div>
                <div className="col-md-4 " style={{ marginLeft: "79%" }}>
                  <button
                    className="btn btn-primary m-2 "
                    onClick={() => {
                      if (
                        candidateDetails.fName == "" ||
                        candidateDetails.lName == "" ||
                        candidateDetails.email == "" ||
                        candidateDetails.phone == "" ||
                        candidateDetails.city == "" ||
                        candidateDetails.currentPosition == "" ||
                        // candidateDetails.experienceMonth == "0" ||
                        candidateDetails.experienceYear == "0" ||
                        candidateDetails.primarySkills == []
                      ) {
                        setIsFormValid(true);
                        toast.error(
                          "There are a few incomplete required fields. Please complete them to continue. "
                        );
                      } else {
                        setIsFormValid(false);
                        setCreateProfile(true);
                        setLogin(false);
                      }
                    }}
                    disabled={!isChecked}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>

      {/* {createProfile && ( */}
      <AddTalentDS
        createProfile={createProfile}
        setCreateProfile={setCreateProfile}
        email={email}
        reffererid={reffererid}
        parsedData={parsedData}
        parsed={parsed}
        candidateDetails={candidateDetails}
        job={job}
        setCandidateData={setAddTalentData}
        setScoreCard={setScoreCard}
        setProfileData={setProfileData}
        data={data}
        setCandidateDetails={setCandidateDetails}
      />

      {/* AutoMatch Score Card */}
      <Modal show={scoreCard} size="lg">
        <Modal.Header>
          <h6 class="">
            {screeningQuestions === true ? "Screening Questions" : "Score Card"}
          </h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setScoreCard(false);
              setCandidateDetails(data);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <AutoMatchScoreCardDS
            jobData={job}
            candidateData={addTalentData}
            setScreeningQuestions={setScreeningQuestions}
            applyJob={submitAddTalent}
            setScoreCard={setScoreCard}
            setIsVetting={setIsVetting}
            setFetchVettingData={setFetchVettingData}
            candidateDetails={candidateDetails}
            setLogin={setLogin}
            setAskResume={setAskResume}
            joinCommunityScoreCard={joinCommunityScoreCard}
            poolId={poolId}
            data={data}
            setCandidateDetails={setCandidateDetails}
            setIsChecked={setIsChecked}
          />
        </Modal.Body>
      </Modal>

      {/* Vettinog */}
      <Modal show={isVetting} size="lg">
        <Modal.Header>
          <h6 class="">Vetting Requirement</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setIsVetting(false);
              setCandidateDetails(data);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        {vettingNow === false && vettingLater === false && (
          <Modal.Body>
            <div className="mx-4 my-4">
              <div className="row">
                <h4 className="text-center " style={{ color: "#FF5F57" }}>
                  {" "}
                  Congratulations!
                  <br />
                </h4>
                <br />
                <h5 className="text-center mt-2">
                  {" "}
                  You have successfully applied for the job.
                </h5>
                <br />
                <br />
                <strong className="text-center mt-2">
                  One last step! The hiring manager is looking for the vetted
                  talent.
                  <br /> If you want to take the assessment
                  <br />
                  <button
                    className="btn btn-sm btn-primary mx-2 my-2"
                    onClick={() => onClickLater("now")}
                  >
                    Start
                  </button>{" "}
                  or
                  <button
                    className="btn btn-sm btn-secondary ml-2 mx-2 my-2"
                    onClick={() => onClickLater("later")}
                  >
                    Skip
                  </button>
                  <br />
                  to receive email with the assessment detail, however you must
                  complete the assessment in 24 hours.
                </strong>
              </div>
              {/* <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-center">
                      <strong>Want to give assessment ?</strong>
                      <br />
                      <button className="btn btn-sm btn-primary mx-2">
                        Now
                      </button>
                      <strong>or</strong>
                      <button className="btn btn-sm btn-secondary ml-2 mx-2">
                        Later
                      </button>
                    </div>
                  </div>
                </div> */}
            </div>
          </Modal.Body>
        )}
        {vettingNow && (
          <Modal.Body>
            <div className="mx-4 my-4">
              <div className="row">
                <strong className="text-center mt-2">
                  We have sent you the link of assessment along with unique code
                  for assessment on your registered email.
                  <br /> Please check them and complete the assessment.
                  <br />
                  <button
                    className="btn btn-sm btn-primary mx-2"
                    onClick={() => {
                      setVettingNow(false);
                      setVettingLater(false);
                      setIsVetting(false);
                    }}
                  >
                    Close
                  </button>{" "}
                </strong>
              </div>
            </div>
          </Modal.Body>
        )}
        {vettingLater && (
          <Modal.Body>
            <div className="mx-4 my-4">
              <div className="row">
                <strong className="text-center mt-2">
                  We have sent you the link of assessment along with unique code
                  for assessment on your registered email.
                  <br /> Please check them and complete the assessment within 24
                  hours.
                  <br />
                  <button
                    className="btn btn-sm btn-primary mx-2"
                    onClick={() => {
                      setVettingNow(false);
                      setVettingLater(false);
                      setIsVetting(false);
                    }}
                  >
                    Close
                  </button>{" "}
                </strong>
              </div>
            </div>
          </Modal.Body>
        )}
      </Modal>

      {/* Edit Tslent */}
      <Modal show={editTalent} size="lg">
        <Modal.Header>
          <h6 class="">Edit Talent</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setEditTalent(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <EditTalentDS
            closeModal={() => setEditTalent(false)}
            data={candidateData}
            setAddTalentData={setAddTalentData}
            setScoreCard={setScoreCard}
            setCandidateDetails={setCandidateDetails}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default JobDescription;
