import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { post } from "../../../../../service/ApiService";
import Loader from "../../../../Loader/Loader";
import buttonLoader from "../../../../../images/button-loader.gif";
import { getCandidateId } from "../../../../../service/AuthService";
import ShortUniqueId from "short-unique-id";
const uid = new ShortUniqueId({ length: 10 });

const MAX_COUNT = 20;

const ExcelUpload = (props) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isDropzoneDisabled, setDropzoneDisabled] = useState(false);

  const getFileIcon = (fileType) => {
    if (fileType.includes("image")) {
      return <i className="fas fa-file-image" style={{ color: "red" }}></i>;
    } else if (fileType.includes("pdf")) {
      return <i className="fas fa-file-pdf" style={{ color: "red" }}></i>;
    } else if (fileType.includes("word")) {
      return <i className="fas fa-file-word" style={{ color: "blue" }}></i>;
    } else if (fileType.includes("excel")) {
      return <i className="fas fa-file-excel" style={{ color: "blue" }}></i>;
    } else {
      return <i className="fas fa-file"></i>;
    }
  };

  const formatFileSize = (sizeInBytes) => {
    const kilobyte = 1024;
    const megabyte = kilobyte * 1024;
    const gigabyte = megabyte * 1024;

    if (sizeInBytes >= gigabyte) {
      return `${(sizeInBytes / gigabyte).toFixed(2)} GB`;
    } else if (sizeInBytes >= megabyte) {
      return `${(sizeInBytes / megabyte).toFixed(2)} MB`;
    } else if (sizeInBytes >= kilobyte) {
      return `${(sizeInBytes / kilobyte).toFixed(2)} KB`;
    } else {
      return `${sizeInBytes} bytes`;
    }
  };

  const handleFileDrop = (acceptedFiles) => {
    if (acceptedFiles.length === 0) {
      toast.error(
        `Invalid file type. Only .doc, .docx, and .pdf files are allowed.`
      );
    } else {
      const filteredFiles = acceptedFiles.filter(
        (file) =>
          !selectedFiles.some((selectedFile) => selectedFile.name === file.name)
      );
      const newFiles = [...selectedFiles, ...filteredFiles];
      if (newFiles.length > MAX_COUNT) {
        toast.error(`Maximum file upload limit exceeded (${MAX_COUNT} files)`);
      } else {
        const invalidFiles = newFiles.filter(
          (file) =>
            ![
              "application/msword",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              "application/pdf",
            ].includes(file.type)
        );

        const duplicateFiles = acceptedFiles.filter((file) =>
          selectedFiles.some((selectedFile) => selectedFile.name === file.name)
        );
        const oversizedFiles = newFiles.filter(
          (file) => file.size > 2 * 1024 * 1024
        );
        if (oversizedFiles.length > 0) {
          toast.error(
            `File size exceeds the limit of 2 MB: ${oversizedFiles
              .map((file) => file.name)
              .join(", ")}`
          );
        } else if (invalidFiles.length > 0) {
          toast.error(
            `Invalid file types. Only .doc, .docx, and .pdf files are allowed.`
          );
        } else if (duplicateFiles.length > 0) {
          toast.error(
            `${
              duplicateFiles.length > 1 ? "Files" : "File"
            } already selected: ${duplicateFiles
              .map((file) => file.name)
              .join(", ")}`
          );
        } else {
          setSelectedFiles(newFiles);
          if (newFiles.length === MAX_COUNT) {
            setDropzoneDisabled(true);
          }
        }
      }
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: ".pdf,.doc,.docx",
    //maxSize: 2 * 1024 * 1024, // 2MB
    onDrop: handleFileDrop,
    //maxFiles: MAX_COUNT,
  });

  const handleRemoveFile = (fileName) => {
    const updatedFiles = selectedFiles.filter((file) => file.name !== fileName);
    setSelectedFiles(updatedFiles);
    setDropzoneDisabled(false);
  };

  const handleFileUpload = async () => {
    if (selectedFiles.length > 0) {
      props.setBulkLoading(true);
      let uploadedFileData = [];
      let uploadId = uid();
      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const base64String = await convertToBase64(file);
        var re = /(?:\.([^.]+))?$/;
        var fileType = re.exec(file.name)[1];
        let paramObj = {
          base64: base64String,
          fileName: file.name,
          fileType: fileType,
        };
        uploadedFileData.push(paramObj);
      }

      let payload = {
        fileData: uploadedFileData,
        uploadId: uploadId,
        recruiterId: getCandidateId(),
      };
      console.log("uploadedFileData", payload);
      const res = await post("/talent/bulkUploadResume", {
        ...payload,
      });
      if (res.status === 200) {
        props.setBulkLoading(false);
        setSelectedFiles([]);
        props.closeModal();
        toast.success(
          "We have received your request, you will get a confirmation mail once the request is completed"
        );
      } else {
        props.setBulkLoading(false);
      }
    }
  };

  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result;
        resolve(base64String);
      };
      reader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return (
    <>
      <div className="file-upload-container">
        <div className="row">
          <div className="col-12">
            <div className="p-5 p-0-600">
              <div>
                Uploaded {selectedFiles.length} / {MAX_COUNT}
              </div>
              <div
                {...getRootProps()}
                className={`file-drop-zone ${isDragActive ? "active" : ""} ${
                  isDragReject ? "reject" : ""
                } ${isDropzoneDisabled ? "disabled" : ""}`}
              >
                <button type="button" className="btn btn-sm btn-secondary">
                  <i className="far fa-cloud-upload mr-1 fa-fw"></i>
                  Select Files
                </button>
                <input {...getInputProps()} />
                <p className="mt-2">
                  Drag and drop files here or click to browse
                </p>
              </div>

              <p className="mt-2">
                Your files should be in .doc,.docx,and .pdf with less than{" "}
                <b>2MB</b> size
              </p>

              <div className="uploaded-files-list mt-2">
                <ul className="file-list mt-5">
                  {selectedFiles.map((file, index) => (
                    <li key={index} className="file-item">
                      <span className="file-icon">
                        {getFileIcon(file.type)}
                      </span>
                      <span className="file-name">{file.name}</span>
                      <span className="file-size">
                        {formatFileSize(file.size)}
                      </span>
                      <span
                        className="ml-3"
                        onClick={() => {
                          handleRemoveFile(file.name);
                        }}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </li>
                  ))}
                </ul>
              </div>

              <label htmlFor="fileUpload" class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm mr-3"
                  disabled={props.bulkLoading}
                  onClick={props.closeModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className={"btn btn-primary btn-sm"}
                  disabled={selectedFiles.length === 0}
                >
                  {props.bulkLoading === false && (
                    <span
                      onClick={() => {
                        handleFileUpload();
                      }}
                    >
                      Upload
                    </span>
                  )}
                  {props.bulkLoading === true && (
                    <span v-if="submittingForm">
                      Uploading
                      <img width="20px" alt="" src={buttonLoader} />
                    </span>
                  )}
                </button>
              </label>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ExcelUpload;
