import React, { useEffect, useState } from "react";
import Loader from "../../Loader/Loader";
import SearchCandidate from "./SearchCandidate";
import moment from "moment";
import Sort from "../../common/Sort";
import OutsideClickHandler from "react-outside-click-handler";
import PaginationComponent from "../pagination/pagination";
import NewAuditLogFilter from "../AuditLog/auditLogFilter/NewAuditLogFilter";
import { useDispatch } from "react-redux";
import { getAuditLogData } from "../../../actions/auditlog/getAuditLogData";
import { getUserTenantID } from "../../../service/AuthService";

const AuditLog = ({
  tab,
  data,
  loading,
  total,
  pageSize,
  setPageSize,
  analyticsData,
  analyticsLoading,
}) => {
  //-----------SORT------------
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];
  const sortObject = [
    {
      id: 1,
      label: "Recruiter Name",
      value: "recruiterName",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Recorded on",
      value: "recordedOn",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
  ];
  const applySort = (sortedData) => {
    setSort(false);
    setCurrentPage(1);
    let payload = {
      currentPage: 1,
      limit: 10,
      sortQuery: sortedData,
      filterQuery: filterParam,
      pageSize: pageSize,
    };
    dispatch(getAuditLogData(payload));
  };
  //-----------SORT------------
  const dispatch = useDispatch();
  const [candidateDetails, setCandidateDetails] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalApplyA, setTotalApplyA] = useState(0);
  const [applyA, setApplyA] = useState(0);
  const [successA, setSuccessA] = useState(0);
  const [screeningA, setScreeningA] = useState(0);
  const [filterParam, setFilterParams] = useState({});

  const [selectedData, setSelectedData] = useState({
    jobId: [],
    status: [],
    recruiterName: [],
    memberFrom: ["", ""],
    memberFromType: "",
  });

  const fetchData = (currentPage, sortArray, filterParam, pageSize) => {
    let paramObj = {
      fk_tenant: getUserTenantID(),
      sortQuery: sortArray,
      currentPage: currentPage,
      filterQuery: filterParam,
      pageSize: pageSize,
    };
    dispatch(getAuditLogData(paramObj));
  };
  const applyFilter = (val, status) => {
    setCurrentPage(1);
    fetchData(1, sortArray, getFilterParamsNew(val, status), pageSize);
  };

  const getFilterParamsNew = (val, status) => {
    const filterParam = {
      JobId:
        status == ""
          ? []
          : status == "jobId"
          ? val.value
            ? [val.value]
            : []
          : selectedData?.jobId[0]?.value === undefined
          ? []
          : [selectedData?.jobId[0]?.value],

      recruiterName:
        status == ""
          ? []
          : status == "recruiterName"
          ? val.value
            ? [val.value]
            : []
          : selectedData?.recruiterName[0]?.value === undefined
          ? []
          : [selectedData?.recruiterName[0]?.value],
      status:
        status == ""
          ? []
          : status == "status"
          ? val.value
            ? [val.value]
            : []
          : selectedData?.status[0]?.value === undefined
          ? []
          : [selectedData?.status[0]?.value],
      memberFromType:
        status == ""
          ? []
          : status == "recordedOn"
          ? val.memberFromType
            ? val.memberFromType
            : ""
          : selectedData.memberFromType,
      memberFrom:
        status == ""
          ? []
          : status == "recordedOn"
          ? val.memberFrom
            ? val.memberFrom
            : ["", ""]
          : selectedData.memberFrom,
    };
    setFilterParams(filterParam);
    return filterParam;
  };

  useEffect(() => {
    if (data) {
      setCandidateDetails(data);
    }
  }, [data]);

  useEffect(() => {
    if (analyticsData.length > 0) {
      const {
        totalApplied,
        totalSuccess,
        totalDropped,
        totalDroppedAtScreening,
      } = analyticsData[0];
      setTotalApplyA(totalApplied);
      setSuccessA(totalSuccess);
      setApplyA(totalDropped);
      setScreeningA(totalDroppedAtScreening);
    }
  }, [analyticsData]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, sortArray, filterParam, pageSize);
  };

  //hadle pageSize
  const handleChange = (e) => {
    let count = 0;
    if (e.target.value === "All") {
      count = 9999999;
    } else {
      count = e.target.value;
    }
    setPageSize(count);
    setCurrentPage(1);
    fetchData(1, sortArray, filterParam, count);
  };

  const handleFilterStatusRemove = () => {
    let temp = { ...selectedData };
    temp["status"] = [];
    setSelectedData(temp);
    applyFilter({}, "status");
  };
  const handleFilterJobIdRemove = () => {
    let temp = { ...selectedData };
    temp["jobId"] = [];
    setSelectedData(temp);
    applyFilter({}, "jobId");
  };

  const handleFilterRecruiterNameRemove = () => {
    setSelectedData({ ...selectedData, recruiterName: [] });
    applyFilter({}, "recruiterName");
  };
  return (
    <>
      {loading && analyticsLoading && <Loader />}
      <div className="fontSizeChange px-3">
        <div className="d-flex ">
          <div className="card pl-5 pr-5 m-2 align-items-center pb-3">
            <p className="mt-2 fw-bold text-center text-success">
              Talent Visits
            </p>
            <strong className="font-22"> {totalApplyA}</strong>
          </div>
          <div className="card pl-5 pr-5 m-2 align-items-center pb-3">
            <p className="mt-2 fw-bold text-center text-success">Applied</p>
            <strong className="font-22"> {successA}</strong>
          </div>
          {/* <div className="card pl-5 pr-5 m-2 align-items-center pb-3">
            <p className="mt-2 fw-bold text-center text-danger">
              Dropped at Profile page
            </p>
            <strong className="font-22"> {applyA}</strong>
          </div>
          <div className="card pl-5 pr-5 m-2 align-items-center pb-3">
            <p className="mt-2 fw-bold text-center text-danger">
              Dropped at Screening questions
            </p>
            <strong className="font-22"> {screeningA}</strong>
          </div> */}
          <div className="card pl-5 pr-5 m-2 align-items-center pb-3">
            <p className="mt-2 fw-bold text-center text-danger">Dropped</p>
            <strong className="font-22"> {applyA}</strong>
          </div>
        </div>
        <div className="row d-flex align-items-center justify-content-between mt-4">
          <div className="col-md-3 col-sm-12 d-flex">
            <h6 className="m-0">Apply Page Analytics
            <span
              className="font-14 ml-2"
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Log includes the candidate's sourcing data in terms of name,status,jobId,recruiter etc."
            >
              <i class="fal fa-info-circle mt-1"></i>
            </span>
            </h6>
          </div>
          <div className="d-flex justify-content-md-end align-items-center mb-2">
            <div className="d-flex align-items-center text-left text-nowrap me-2">
              <span>Show Records:</span>
              <select
                className="form-select form-select-sm mx-2"
                name="showRecordsLog"
                onChange={handleChange}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="All">All</option>
              </select>
            </div>
            <div className="d-flex align-items-center text-left text-nowrap me-2">
              {/* SORT */}
              <div class="py-2 align-self-end">
                <div class="dropdown-sort dd-sort">
                  <button
                    type="button"
                    onClick={() => setSort(!sort)}
                    class="btn btn-sm w-auto btn-text-accent dd-sort"
                    disabled={candidateDetails.length === 0}
                  >
                    <i
                      aria-hidden="true"
                      class="fad fa-sort-down font-16 dd-sort mr-1"
                    ></i>
                    <strong>
                      Sort / <span class="dd-sort">{sortCount}</span>
                    </strong>
                  </button>
                  {
                    <OutsideClickHandler onOutsideClick={() => setSort(false)}>
                      {sort && (
                        <Sort
                          sortData={candidateDetails}
                          orderArray={orderList}
                          sortObjects={sortObject}
                          applySort={applySort}
                          setSortCount={setSortCount}
                          sortCount={sortCount}
                          setSortArray={setSortArray}
                          sortArray={sortArray}
                          sortingFor="Sort by Recruiter Name and Recorded on"
                        />
                      )}
                    </OutsideClickHandler>
                  }
                </div>
              </div>
              {/* SORT ENDS*/}
            </div>
          </div>
        </div>
        <hr />
        <div class="row d-flex mb-3 align-items-start justify-content-between">
          {/* table-cards-1024 */}
          <div className="col-md-3 col-sm-12 p-3">
            <NewAuditLogFilter
              selectedData={selectedData}
              setSelectedData={setSelectedData}
              applyFilter={applyFilter}
            />
          </div>
          <div className="col-md-8 col-sm-12 overflow-scroll p-3">
            {Array.isArray(selectedData?.jobId) &&
              selectedData?.jobId?.map((item) => (
                <span key={item.value} className="tag tag-blue2 p-1 px-2 m-1">
                  <span>{item.value}</span>{" "}
                  <a
                    href="#"
                    className="font-black"
                    onClick={(e) => {
                      handleFilterJobIdRemove(e, item.value, item.value);
                    }}
                  >
                    <i className="fas fa-times ml-2 "></i>
                  </a>
                </span>
              ))}{" "}
            {selectedData?.status?.map((item) => (
              <span key={item.value} className="tag tag-blue2 p-1 px-2 m-1">
                <span>{item.value}</span>{" "}
                <a
                  href="#"
                  className="font-black"
                  onClick={(e) => {
                    handleFilterStatusRemove(e, item.value, item.value);
                  }}
                >
                  <i className="fas fa-times ml-2 "></i>
                </a>
              </span>
            ))}
            {selectedData?.recruiterName?.map((item) => (
              <span key={item.value} className="tag tag-blue2 p-1 px-2 m-1">
                <span>{item.value}</span>{" "}
                <a
                  href="#"
                  className="font-black"
                  onClick={(e) =>
                    handleFilterRecruiterNameRemove(e, item, item, item)
                  }
                >
                  <i className="fas fa-times ml-2 "></i>
                </a>
              </span>
            ))}
            {candidateDetails?.length > 0 && (
              // table-hoverabletable-cards-1024
              <table className="table table-hover" style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Job</th>
                    <th>Job Id</th>
                    <th>Recruiter</th>
                    <th>Status</th>
                    <th>Recorded on</th>
                  </tr>
                </thead>
                <tbody>
                  {candidateDetails?.map((item) => {
                    let x = item.navigatedPages?.split("/")[
                      item.navigatedPages?.split("/").length - 1
                    ];
                    let cand = item.data && JSON.parse(item.data);

                    return (
                      <tr>
                        <td style={{ cursor: "pointer" }}>
                          <a href="javascript:void(0)">
                            {cand?.firstName
                              ? cand?.firstName
                              : item?.firstName}{" "}
                            {cand?.lastName ? cand?.lastName : item.lastName}
                          </a>
                        </td>
                        <td>
                          {item.jobTitle}
                          <span
                            className="font-14 ml-2"
                            data-bs-toggle="tooltip"
                            data-bs-html="true"
                            title={item?.poolId ? "Sub-Community" : "Job"}
                          >
                            {/* <i class="fal fa-info-circle mt-2"></i> */}
                          </span>
                        </td>
                        <td>{item?.poolId ? item?.poolId : item?.jobId}</td>
                        <td>
                          {item.recruiterFirstName} {item.recruiterLastName}
                        </td>
                        <td>
                          <span
                            className={`tag mb-1 mr-1 px-2 ${
                              x === "success" ? "tag-green3" : "tag-red1"
                            }`}
                          >
                            {x === "success" ? "Applied" : "Dropped"}
                          </span>
                        </td>
                        <td>
                          {moment(new Date(item.createdDate)).format(
                            "MM/DD/YYYY"
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            )}
            {candidateDetails?.length === 0 && (
              <div className="d-flex justify-content-center align-content-center mb-5">
                <div className="text-center p-3">
                  <div className="avatar avatar-lg">
                    <i class="fad fa-users-slash"></i>
                  </div>

                  <div class="mt-2">No records found</div>
                </div>
              </div>
            )}
            {candidateDetails?.length > 0 && (
              <>
                <hr />
                <PaginationComponent
                  currentPage={currentPage}
                  totalItems={total}
                  itemsPerPage={pageSize}
                  onPageChange={handlePageChange}
                  limit={pageSize}
                />
              </>
            )}
          </div>
        </div>
        <hr className="m-0" />{" "}
      </div>
    </>
  );
};

export default AuditLog;
