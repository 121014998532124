import React, { useEffect } from "react";
import { Modal, Tab, Tabs } from "react-bootstrap";

const CommunicationModal = ({ isOpen, closeModal, recipientData }) => {
  useEffect(() => {
    console.log("recipientData", recipientData);
  }, [recipientData]);

  // Sort recipientData by createdDateTime in ascending order
  const sortedRecipientData = recipientData
    .slice()
    .sort((a, b) => a.createdDateTime - b.createdDateTime);

  return (
    <div className="menu-container">
      <Modal
        size={"xl"}
        show={isOpen}
        onHide={closeModal}
        className="vh-100 overflow-scroll"
      >
        <div className="bl-modal-header">
          <h6 className="bl-modal-title">Chat Details</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <Tabs defaultActiveKey="messages" className="ml-2">
            <Tab
              eventKey="messages"
              title="Messages"
              style={{
                overflow: "hidden",
                position: "relative",
                height: "400px",
              }}
            >
              <div
                className="whatsapp-chat pr-2"
                style={{
                  // overflowX: "auto",
                  // height: "400px",
                  // direction: "ltr",
                  position: "absolute",
                  bottom: "0",
                  width: "100%",
                  overflowY: "scroll",
                  height: "100%",
                  borderTop: ".01px solid #ccc",
                }}
              >
                {/* <hr /> */}
                {sortedRecipientData.length > 0 &&
                  sortedRecipientData.map((item, index) => {
                    if (item?.direction === "inbound") {
                      return (
                        <>
                          <div key={index} className={`whatsapp-message mb-2`}>
                            <div
                              className={`messageContainer whatsapp-receiver-message`}
                              style={{
                                minWidth: "100px",
                              }}
                            >
                              <div className="d-flex">
                                <div class="triangle"></div>
                                <div
                                  style={{
                                    backgroundColor: "#dcf8c6",
                                    padding: "5px",
                                    borderRadius: "4px",
                                    marginRight: "250px",
                                    borderTopLeftRadius: "0px",
                                  }}
                                >
                                  <p>{item.messageBody}</p>

                                  <small className="message-timestamp mr-auto">
                                    {new Date(item.received_at).toDateString() +
                                      " " +
                                      new Date(
                                        item.received_at
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                  </small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    } else {
                      return (
                        <>
                          <div key={index} className={`whatsapp-message mb-2`}>
                            <div
                              className={`messageContainer whatsapp-sender-message`}
                              style={{
                                minWidth: "100px",
                              }}
                            >
                              <div className="d-flex">
                                <div
                                  style={{
                                    backgroundColor: "#ddd",
                                    padding: "5px",
                                    borderRadius: "4px",
                                    marginLeft: "250px",
                                    borderTopRightRadius: "0px",
                                  }}
                                >
                                  <p>{item.messageBody}</p>

                                  <small className="message-timestamp mr-auto">
                                    {new Date(item.received_at).toDateString() +
                                      " " +
                                      new Date(
                                        item.received_at
                                      ).toLocaleTimeString([], {
                                        hour: "2-digit",
                                        minute: "2-digit",
                                      })}
                                  </small>
                                </div>
                                <div class="triangle2"></div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}
              </div>
            </Tab>
          </Tabs>
          <hr />
          <div className="d-flex justify-content-end" style={{ width: "100%" }}>
            <button type="cancel" className="mb-3 mr-3" onClick={closeModal}>
              Close
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CommunicationModal;
