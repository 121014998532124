import { get } from '../../service/ApiService';
export const GET_TOVUTI_BEGIN = 'GET_TOVUTI_BEGIN';
export const GET_TOVUTI_SUCCESS = 'GET_TOVUTI_SUCCESS';
export const GET_TOVUTI_FAILURE = 'GET_TOVUTI_FAILURE';

export const tovutiUsers = () => async dispatch => {
    try {
        dispatch({
            type: GET_TOVUTI_BEGIN,
        })
       const res = await get('/tovuti/getUser')
                   
        if (res.status === 200) {
            dispatch({
                type: GET_TOVUTI_SUCCESS,
                payload: res.data
            })
        }
    } catch (error) {
        dispatch({
            type: GET_TOVUTI_FAILURE,
            payload: error.response.data
        })
    }
}
