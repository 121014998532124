import React, { useState, useEffect } from "react";
import imageLoader from "../../../../images/button-loader.gif";
import { post } from "../../../../service/ApiService";
import { getUserRole, getCandidateName } from "../../../../service/AuthService";
import { toast } from "react-toastify";
import { getInitial } from "../../../function/CommonFunction";
import { domain } from "../../../../constants/Constants";
import Loader from "../../../Loader/Loader";
import Avatar from "react-avatar";
import { ROW_SELECT_DISABLED } from "react-bootstrap-table-next";
import axios from "axios";

function VettingResendMail(props) {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [loading, setLoading] = useState(false);
  const [talentEmailChecked, setTalentEmailChecked] = useState(false);
  const [candVettingData, setCandVettingData] = useState({});
  const jobData = props.jobData;
  const talentData = props.talentdata;
  const tenant = props.userTenant;
  const role = getUserRole();
  const userName = getCandidateName();

  useEffect(() => {
    setCandidateData(props.candidateData);
    setCandVettingData(props.candidateVettingDetails);
  }, [props]);

  const handleCheck = (e) => {
    console.log(e.target.checked);
    if (e.target.checked) {
      setTalentEmailChecked(true);
    } else {
      setTalentEmailChecked(false);
    }
  };

  const closeModal = () => {
    props.handleSuccess();
  };

  const candidateEmail = candidateData.candidateEmail;
  const candidateName =
    candidateData.candidateFname + " " + candidateData.candidateLname;
  const clientName = jobData.jobTenant;
  const jobId = jobData.jobId;
  const jobTitle = jobData.jobTitle;
  const candidateTenant = candidateData.candidateTenant;
  //console.log(candidateEmail,candidateName,"email")

  const MailOfferAPI = async (e) => {
    const params = {
      tenant,
      candidateEmail,
      candidateName,
      clientName,
      jobId,
      jobTitle,
      talentEmailChecked,
      candidateTenant,
    };
    try {
      const res = await post("/makeanoffer/getdataformail", params);
      if (res.status === 200) {
        props.setSuccessFormOpen(true);
        props.refreshPage();
      }
    } catch (error) {
      console.log(error);
    }
  };

  let submittedToClientBy = getCandidateName();
  let candidateId = candidateData.candidateId;

  const submitMakeAnOffer = async (e) => {
    const payload = {
      jobId: jobData.jobId,
      candidateId: candidateId,
      offeredRole: role,
      offeredBy: submittedToClientBy,
      OfferedDate: new Date().toISOString(),
      talentData,
      talentEmailChecked,
    };
    try {
      setLoading(true);
      const res = await post("/jobcandidate/updateofferpendingstatus", payload);
      if (res.status === 200) {
        toast.success("Job offered successfully");
        // closeModal()
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Error Occured while accessing Talent Details");
      }
      props.refreshPage();
      props.handleSuccess();
    } catch (error) {
      setLoading(false);
      toast.error("An error occurred");
    }
    setSubmitClicked(true);
    // const tenant = { tenant: props.userTenant }
    //  const candidateEmail = {candidateEmail: talentData.candidateEmail}
    MailOfferAPI(tenant, candidateEmail, candidateName);
    setSubmitClicked(true);
  };

  // send reminder mail
  const reminderMail = async () => {
    setLoading(true);

    const payload = {
      candVettingData,
      candidateData,
    };
    const res = await post(`/vetting/email/resend`, payload);
    if (res.status == 200) {
      setLoading(false);
      props.setIsVettingComplete(false);
    }

    toast.success("Sent reminder email successfully");
    // const res = axios
    //   .post(
    //     `https://https://high5vettinguat-api.azurewebsites.net/email/send`,
    //     {
    //       to: candVettingData?.email,
    //       subject: `Gentle Reminder to complete your assigned vetting assessment for ${candidateData?.jobTitle} `,
    //       body: `Assessment invitation from High5Hire.<p>Hello ${candidateData?.candidateFname},</p>
    //       <br>
    //        <table bgcolor="#EDEDED" border="0" cellpadding="20px" cellspacing="0" width="100%">
    //           <tbody>
    //             <tr>
    //               <td style="text-align: center; line-height: 1.5 ; font-size: 22px;">
    //                 You are invited to complete an assessment.
    //                 <br/><br/>
    //                  <span style="text-align: center; font-size: 14px;">Here is your unique assessment code, <strong >${candVettingData?.uniqueCode}</strong>.</span>
    //                 </td>
    //               </tr>
    //             </tbody>
    //           </table>
    //         <p style="margin-top:20px;line-height: 1.5;">You can start the process of assessment by clicking on the button below. It will redirect you to the login page. For login, you can use the given unique assessment code.</p>
    //          <a href="https://vetuat.high5hire.app"
    //           style="background: #0c99d5; color: #fff; text-decoration: none; border: 10px solid #0c99d5; border-left-width: 20px; border-right-width: 20px; text-transform: uppercase; display: inline-flex;">
    //           Click here
    //         </a>
    //         <br/><br/>
    //         <span style="font-size: 14px;">Assessment name : <strong>${candVettingData?.testAssign?.testName}</strong></span>
    //           <br/>
    //         <span style="font-size: 14px;">Assessment type : <strong>${candVettingData?.testAssign?.testCategory}</strong></span><br>
    //         <span style="font-size: 14px;">Assessment duration : <strong>${candVettingData?.testAssign?.details?.duration} minutes</strong></span>
    //         <p style="margin-top:20px;line-height: 1.5;">For any queries, please get in touch with alerts@high5hire.com.</p>
    //         <br/>
    //           <span>Regards</span>
    //         <br/>
    //        <span>${candVettingData?.createdBy?.firstName} ${candVettingData?.createdBy?.lastName}</span><br/>
    //        <span>${candVettingData?.companyInfo?.companyName}</span>`,
    //     },

    //     {
    //       headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
    //     }
    //   )
    // .then((res) => {
    //   //toast.success("Sent reminder email successfully");

    // })
    // .catch((err) => setLoading(false));
  };

  return (
    <>
      {loading && <Loader />}
      <div className="bl-modal-header">
        <h6 className="bl-modal-title"></h6>
        <button
          type="button"
          onClick={() => props.setIsVettingComplete(false)}
          className="close p-0 bl-modal-close-btn"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div>
        <div className="text-center">
          {candidateData.ImageURL ? (
            <span>
              <img className="avatar" alt="" src={props.talentdata.ImageURL} />
              <span className="ml-2">
                {candidateData.firstFname} {candidateData.lastLname}{" "}
              </span>
            </span>
          ) : (
            <span>
              <Avatar
                className="mb-1"
                size="55"
                name={
                  candidateData.candidateFname +
                  " " +
                  candidateData.candidateLname
                }
                round="90px"
              />
              {/* {getInitial(`${talentData[0].candidateFname} ${talentData[0].candidateLname}`)} */}
            </span>
          )}
        </div>
      </div>
      <div>
        <div className="text-center">
          <h6 className="mt-3">
            {candidateData.candidateFname} {candidateData.candidateLname}{" "}
          </h6>
          <p>
            {jobData.isRemote === true ? (
              <span className="mb-1 mr-1">Remote</span>
            ) : (
              <span className="mb-1 mr-1">{props.jobData.location.city}</span>
            )}{" "}
            /
            {
              <span className="mb-1 mr-1">
                {} {jobData.jobTenant}
              </span>
            }
            /<span className="mb-1 mr-1">{} </span>
            {/* {jobData.jobType === "Full Time"
              ? jobData.annualSalary
              : jobData.hourlyRate} */}
            {
              <span className="mb-1 mr-1">
                {" "}
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: jobData.placementCurrency
                    ? `${jobData.placementCurrency}`
                    : "USD",
                  maximumSignificantDigits: 3,
                }).format(jobData.maximumPay)}
              </span>
            }
          </p>
        </div>
      </div>

      <div style={{ padding: "1px 2px 35px 0px" }}></div>

      <div v-if="showMakeAnOfferForm">
        <div className="bl-modal-body pt-0">
          <p className="text-center">
            <strong>Oops! Requested assessment is incomplete.</strong>
          </p>
          <p className="text-center">
            <p>
              This candidate can’t be shortlisted as the requested assessment is
              pending, you can send reminder email to candidate for assessment
              completion. Once candidate completes the assessment, you will
              receive an email confirmation and candidate will be ready for the
              shortlist.
            </p>
          </p>
          {/* <div className="col-lg-8 d-flex align-items-center  mb-2 mb-lg-0 justify-content-center justify-content-lg-start">
            <div className="form-check form-switch d-flex align-items-center text-center">
              <label className="form-check-label pt-3" htmlFor="mailSwitch">
                <p> Send Vetting Reminder To Candidate</p>
              </label>
              <input
                name="candidateEmail"
                className="form-check-input ml-3"
                type="checkbox"
                onChange={(e) => handleCheck(e)}
                checked={talentEmailChecked ? true : false}
              />
              <button className="btn btn-sm ml-3">Send email</button>
            </div>
          </div> */}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            data-dismiss="modal"
            onClick={() => props.setIsVettingComplete(false)}
            disabled={submitClicked}
          >
            Cancel
          </button>
          {/* <button type="button" className="btn btn-primary btn-sm">
            {submitClicked === false ? (
              <span onClick={submitMakeAnOffer}>Submit Offer</span>
            ) : (
              <span>
                Submitting Offer
                <img width="20px" src={imageLoader} alt="blank" />
              </span>
            )}
          </button> */}
          {/* <button type="button" className="btn btn-sm" onClick={() => {}}>
            Disqualiy
          </button>{" "} */}
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={reminderMail}
          >
            Send email
          </button>
        </div>
      </div>
    </>
  );
}
export default VettingResendMail;
