import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { get, post } from "../../../service/ApiService";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import AssessmentCertificate from "../../modal/talentprofile/AssessmentCertificate";
import Loader from "../../Loader/Loader";

function CandidateAssessments(props) {
  const [oneWay, setOneWay] = useState(false);
  const [twoWay, setTwoWay] = useState(false);
  const [MCQInterview, setMCQInterview] = useState(false);
  const [loading, setLoading] = useState(false);
  const [assessmentData, setAssessmentData] = useState({
    mcq: [],
    oneWay: [],
    twoWay: [],
  });
  const [certificate, setCertificate] = useState({});
  const [certificatePopUp, setCertificatePopUp] = useState(false);
  const closeModel = () => {
    setCertificatePopUp(false);
  };
  const setCertificateClick = (data) => {
    setCertificate(data);
    setCertificatePopUp(true);
  };
  const [csAssessmentData, setCsAssessmentData] = useState({ codeSignal: [] });
  const [codeSignal, setCodesignal] = useState(false);

  const [vettingAssessmentData, setVettingAssessmentData] = useState({
    mcq: [],
    oneWay: [],
  });
  const [vetOneWay, setVetOneWay] = useState(false);
  const [vetMCQInterview, setVetMCQInterview] = useState(false);

  const onCollapsibleClick = (val) => {
    if (val === "MCQInterview") {
      setMCQInterview(true);
    }
    if (val === "MCQInterviewReset") {
      setMCQInterview(false);
    }
    if (val === "oneWay") {
      setOneWay(true);
    }
    if (val === "oneWayReset") {
      setOneWay(false);
    }
    if (val === "twoWay") {
      setTwoWay(true);
    }
    if (val === "twoWayReset") {
      setTwoWay(false);
    }
    if (val === "codeSignal") {
      setCodesignal(true);
    }
    if (val === "codeSignalReset") {
      setCodesignal(false);
    }
    if (val === "vetMCQInterview") {
      setVetMCQInterview(true);
    }
    if (val === "vetMCQInterviewReset") {
      setVetMCQInterview(false);
    }
    if (val === "vetOneWay") {
      setVetOneWay(true);
    }
    if (val === "vetOneWayReset") {
      setVetOneWay(false);
    }
  };

  useEffect(() => {
    fetchCodeSignalAssessmentDetail();
    fetchAssessmentDetail();
    fetchVettingAssessmentDetail();
  }, [props.candidateData]);

  const fetchAssessmentDetail = async () => {
    try {
      const candidateID = props.candidateData.candidateID;
      const candidateEmail = props.candidateData.email;
      const assessmentResult = await get(
        `/assignAssessment/view/${candidateID}&${candidateEmail}`
      );
      // const tempassessmentData = assessmentData.mcq
      setAssessmentData({
        ...assessmentData,
        mcq: assessmentResult.data.tovuti,
        oneWay: assessmentResult.data.oneWay,
        twoWay: assessmentResult.data.twoWay,
      });
      console.log(assessmentData);
    } catch (error) {
      toast.error(error);
    }
    props.setLoading(false);
  };

  const fetchCodeSignalAssessmentDetail = async () => {
    props.setLoading(true);
    try {
      const candidateID = props.candidateData.candidateID;
      const candidateEmail = props.candidateData.email;
      let payload = {
        email: candidateEmail,
        id: candidateID,
      };
      let response = await post("/codesignal/csViewAssessment", payload);
      console.log("responsefromcodesignal", response);
      if (response.status === 200) {
        setCsAssessmentData({
          ...response,
          codeSignal: response.data,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchVettingAssessmentDetail = async () => {
    try {
      setLoading(true);
      const candidateEmail = props.candidateData.email;
      const candidateID = props.candidateData.candidateID;
      let payload = {
        email: candidateEmail,
        id: candidateID,
      };
      let response = await post("/view/vettingViewAssessment", payload);
      console.log("TANYA", response);
      if (response.status === 200) {
        setLoading(false);
        setVettingAssessmentData({
          ...vettingAssessmentData,
          mcq: response.data.mcq,
          oneWay: response.data.oneWay,
        });
      }
    } catch (error) {
      toast.error(error);
    }
  };
  console.log("vetting", vettingAssessmentData);

  return (
    <>
      <div>
        {assessmentData.mcq.length === 0 &&
          assessmentData.oneWay.length === 0 &&
          assessmentData.twoWay.length === 0 &&
          csAssessmentData.codeSignal.length === 0 &&
          vettingAssessmentData.mcq.length === 0 &&
          vettingAssessmentData.oneWay.length === 0 && (
            <div>
              <div className="text-center p-3">
                <div className="avatar avatar-lg">
                  <i className="fad fa-users-slash"></i>
                </div>
                <div className="mt-2">No Assessment Found</div>
              </div>
            </div>
          )}
        {assessmentData.mcq.length > 0 && (
          <div>
            <div className="mb-2">
              <button type="button" className="btn btn-collapsible">
                {MCQInterview !== true ? (
                  <i
                    className="fal fa-angle-down fa-fw"
                    onClick={() => onCollapsibleClick("MCQInterview")}
                  ></i>
                ) : (
                  <i
                    className="fal fa-angle-up fa-fw mt-1"
                    onClick={() => onCollapsibleClick("MCQInterviewReset")}
                  ></i>
                )}
                <span>
                  <small>MCQ </small>
                </span>
              </button>
            </div>

            <div
              className={
                MCQInterview === true
                  ? " card ml-4 mb-4 d-none"
                  : "card ml-4 mb-4"
              }
            >
              <div className="card-body">
                <table className="table table-hoverable">
                  <tbody>
                    {assessmentData.mcq.map((eachdata) => (
                      <tr className="mb-1">
                        {eachdata.status === "Completed" ? (
                          <td>
                            <div className="col-lg-12 d-flex justify-content-between">
                              <h6 className="mb-1">{eachdata.title}</h6>
                              <button
                                type="button"
                                className="btn btn-sm btn-gray mb-1"
                                onClick={() => setCertificateClick(eachdata)}
                              >
                                Certificate
                              </button>
                            </div>
                            <div class="py-2 mt-2">
                              <span class="mr-2 tag font-bold tag-green1 mb-2">
                                {" "}
                                Status : {eachdata.status}
                              </span>
                              <span class="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-2"
                                ></i>
                                Assigned By : {eachdata.assignedBy}{" "}
                              </span>
                              <span class="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>{" "}
                                Assigned:{" "}
                                {eachdata.recordedTime
                                  ? moment(eachdata.recordedTime).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "N/A"}{" "}
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td>
                            <h6 class="mb-1">{eachdata.title}</h6>
                            <div class=" py-2 mt-2">
                              <span class="mr-2 tag font-bold tag-orange1 mb-2">
                                {" "}
                                Status : {eachdata.status}
                              </span>
                              <span class="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-1"
                                ></i>
                                Assigned By : {eachdata.assignedBy}{" "}
                              </span>
                              <span class="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>{" "}
                                Assigned:{" "}
                                {eachdata.recordedTime
                                  ? moment(eachdata.recordedTime).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "N/A"}{" "}
                              </span>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {assessmentData.oneWay.length > 0 && (
          <div v-if="1way">
            <div className="mb-2">
              <button type="button" className="btn btn-collapsible">
                {oneWay !== true ? (
                  <i
                    v-if="!collapsible.skills"
                    className="fal fa-angle-down fa-fw"
                    onClick={() => onCollapsibleClick("oneWay")}
                  ></i>
                ) : (
                  <i
                    v-else
                    className="fal fa-angle-up fa-fw mt-1"
                    onClick={() => onCollapsibleClick("oneWayReset")}
                  ></i>
                )}
                <span>
                  <small>1 Way </small>
                </span>
              </button>
            </div>
            <div
              className={
                oneWay === true ? " card ml-4 mb-4 d-none" : "card ml-4 mb-4"
              }
            >
              <div className="card-body">
                <table className="table table-hoverable">
                  <tbody>
                    {assessmentData.oneWay.map((eachdata) => (
                      <tr className="mb-1">
                        {eachdata.status === "Completed" ? (
                          <td>
                            <div className="col-lg-12 d-flex justify-content-between">
                              <h6 class="mb-1">{eachdata.title}</h6>
                              <button
                                type="button"
                                className="btn btn-sm btn-gray mb-1"
                                onClick={() =>
                                  window.open(eachdata.ReviewUrl, "_blank")
                                }
                              >
                                View Result
                              </button>
                            </div>
                            <div class=" py-2 mt-2">
                              <span class="mr-2 tag font-bold tag-green1 mb-2">
                                {" "}
                                Status : {eachdata.status}
                              </span>
                              <span class="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-1"
                                ></i>
                                Assigned By : {eachdata.assignedBy}{" "}
                              </span>
                              <span class="tag mb-2 ml-2">
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>{" "}
                                Assigned:{" "}
                                {eachdata.recordedTime
                                  ? moment(eachdata.recordedTime).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "N/A"}{" "}
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td>
                            <h6 class="mb-1">{eachdata.title}</h6>
                            <div class=" py-2 mt-2">
                              <span class="mr-2 tag font-bold tag-orange1">
                                {" "}
                                Status : {eachdata.status}
                              </span>
                              <span class="tag mb-1">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-1"
                                ></i>
                                Assigned By : {eachdata.assignedBy}{" "}
                              </span>
                              <span class="tag mb-2 ml-2">
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>{" "}
                                Assigned:{" "}
                                {eachdata.recordedTime
                                  ? moment(eachdata.recordedTime).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "N/A"}{" "}
                              </span>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
        {assessmentData.twoWay.length > 0 && (
          <div v-if="2way">
            <div className="mb-2">
              <button type="button" className="btn btn-collapsible">
                {twoWay !== true ? (
                  <i
                    v-if="!collapsible.skills"
                    className="fal fa-angle-down fa-fw"
                    onClick={() => onCollapsibleClick("twoWay")}
                  ></i>
                ) : (
                  <i
                    v-else
                    className="fal fa-angle-up fa-fw mt-1"
                    onClick={() => onCollapsibleClick("twoWayReset")}
                  ></i>
                )}
                <span>
                  <small>2 Way </small>
                </span>
              </button>
            </div>

            <div
              className={
                twoWay === true ? " card ml-4 mb-4 d-none" : "card ml-4 mb-4"
              }
            >
              <div className="card-body">
                <table className="table table-hoverable">
                  <tbody>
                    {assessmentData.twoWay.map((eachdata) => (
                      <tr className="mb-1">
                        {eachdata.status === "Completed" ? (
                          <td>
                            <div className="col-lg-12 d-flex justify-content-between">
                              <h6 class="mb-1">{eachdata.title}</h6>
                              <button
                                type="button"
                                className="btn btn-sm btn-gray mb-2"
                                onClick={() =>
                                  window.open(eachdata.ReviewUrl, "_blank")
                                }
                              >
                                View Result
                              </button>
                            </div>
                            <div class="py-2 mt-2">
                              <span class="mr-2 tag font-bold tag-green1 mb-2">
                                {" "}
                                Status : {eachdata.status}
                              </span>
                              <span class="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-1"
                                ></i>
                                Assigned By : {eachdata.assignedBy}{" "}
                              </span>
                              <span class="tag mb-2 ml-2">
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>{" "}
                                Assigned:{" "}
                                {eachdata.recordedTime
                                  ? moment(eachdata.recordedTime).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "N/A"}{" "}
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td>
                            <h6 class="mb-">{eachdata.title}</h6>
                            <div class=" py-2 mt-2">
                              <span class="mr-2 tag font-bold tag-orange1 mb-2">
                                {" "}
                                Status : {eachdata.status}
                              </span>
                              <span class="tag mb-1">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-1"
                                ></i>
                                Assigned By : {eachdata.assignedBy}
                              </span>
                              <span class="tag mb-1">
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>
                                Assigned:{" "}
                                {eachdata.recordedTime
                                  ? moment(eachdata.recordedTime).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "N/A"}{" "}
                              </span>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {csAssessmentData.codeSignal.length > 0 ? (
          <div>
            <div className="mb-2">
              <button type="button" className="btn btn-collapsible">
                {codeSignal !== true ? (
                  <i
                    v-if="!collapsible.skills"
                    className="fal fa-angle-down fa-fw"
                    onClick={() => onCollapsibleClick("codeSignal")}
                  ></i>
                ) : (
                  <i
                    v-else
                    className="fal fa-angle-up fa-fw mt-1"
                    onClick={() => onCollapsibleClick("codeSignalReset")}
                  ></i>
                )}
                <span>
                  <small>Coding Test</small>
                </span>
              </button>
            </div>

            <div
              className={
                codeSignal === true
                  ? " card ml-4 mb-4 d-none"
                  : "card ml-4 mb-4"
              }
            >
              <div className="card-body">
                <table className="table table-hoverable">
                  <tbody>
                    {csAssessmentData.data.map((item) => (
                      <tr className="mb-1">
                        {item.status === "Completed" ? (
                          <td>
                            <div className="col-lg-12 d-flex justify-content-between">
                              <h6 class="mb-1">{item.title} </h6>
                              <button
                                type="button"
                                className="btn btn-sm btn-gray mb-1"
                                onClick={() =>
                                  window.open(item.ReviewUrl, "_blank")
                                }
                              >
                                View Result
                              </button>
                            </div>
                            <span className="tag mb-1 mr-1">
                              Score : {item.AssessmentScore} /{" "}
                              {item.AssessmentMaxScore}
                            </span>
                            <div className="py-2 mt-2">
                              <span className="mr-2 tag font-bold tag-green1 mb-2">
                                {" "}
                                Status : {item.status}
                              </span>

                              <span className="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-1"
                                ></i>
                                Assigned By : {item.assignedBy}{" "}
                              </span>
                              <span className="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>{" "}
                                Assigned:{" "}
                                {item.recordedTime
                                  ? moment(item.recordedTime).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "N/A"}
                              </span>
                            </div>
                          </td>
                        ) : (
                          <td>
                            <h6 className="mb-1">{item.title}</h6>
                            <div className=" py-2 mt-2">
                              <span className="mr-2 tag font-bold tag-orange1 mb-2">
                                {" "}
                                Status : {item.status}
                              </span>
                              <span className="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="far fa-fw fa-stopwatch mr-1"
                                ></i>
                                Assigned By : {item.assignedBy}{" "}
                              </span>
                              <span className="tag mb-2">
                                <i
                                  aria-hidden="true"
                                  class="fal fa-fw fa-clock mr-1"
                                ></i>{" "}
                                Assigned:{" "}
                                {item.recordedTime
                                  ? moment(item.recordedTime).format(
                                      "MM/DD/YYYY"
                                    )
                                  : "N/A"}
                              </span>
                            </div>
                          </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ) : null}

        {vettingAssessmentData.mcq.length > 0 && (
          <div v-if="mcq">
            <div className="mb-2">
              <button type="button" className="btn btn-collapsible">
                {vetMCQInterview !== true ? (
                  <i
                    v-if="!collapsible.skills"
                    className="fal fa-angle-down fa-fw"
                    onClick={() => onCollapsibleClick("vetMCQInterview")}
                  ></i>
                ) : (
                  <i
                    v-else
                    className="fal fa-angle-up fa-fw mt-1"
                    onClick={() => onCollapsibleClick("vetMCQInterviewReset")}
                  ></i>
                )}
                <span>
                  <small>MCQ </small>
                </span>
              </button>
            </div>

            <div
              className={
                vetMCQInterview === true
                  ? " card ml-4 mb-4 d-none"
                  : "card ml-4 mb-4"
              }
            >
              <div className="card-body">
                <table className="table table-hoverable">
                  <tbody>
                    {vettingAssessmentData.mcq.map((eachdata) => (
                      <tr>
                        <td>
                          <h6 class="mb-1">{eachdata.title}</h6>
                          <div class=" py-2 mt-2">
                            {eachdata.Status === "Completed" ? (
                              <span class="mr-2 tag font-bold tag-green1 ">
                                {" "}
                                Status : {eachdata.Status}
                              </span>
                            ) : (
                              <span class="mr-2 tag font-bold tag-orange1">
                                {" "}
                                Status : {eachdata.Status}
                              </span>
                            )}

                            <span class="tag mb-1 mr-1 mt-1">
                              <i
                                aria-hidden="true"
                                class="far fa-fw fa-stopwatch mr-1"
                              ></i>
                              Assigned By : {eachdata.assignedBy}{" "}
                            </span>
                            <span class="tag mb-1">
                              <i
                                aria-hidden="true"
                                class="fal fa-fw fa-clock mr-1"
                              ></i>{" "}
                              Assigned:{" "}
                              {eachdata.recordedTime
                                ? moment(eachdata.recordedTime).format(
                                    "MM/DD/YYYY"
                                  )
                                : "N/A"}{" "}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {vettingAssessmentData.oneWay.length > 0 && (
          <div v-if="1way">
            <div className="mb-2">
              <button type="button" className="btn btn-collapsible">
                {vetOneWay !== true ? (
                  <i
                    v-if="!collapsible.skills"
                    className="fal fa-angle-down fa-fw"
                    onClick={() => onCollapsibleClick("vetOneWay")}
                  ></i>
                ) : (
                  <i
                    v-else
                    className="fal fa-angle-up fa-fw mt-1"
                    onClick={() => onCollapsibleClick("vetOneWayReset")}
                  ></i>
                )}
                <span>
                  <small>1 Way </small>
                </span>
              </button>
            </div>
            <div
              className={
                vetOneWay === true ? " card ml-4 mb-4 d-none" : "card ml-4 mb-4"
              }
            >
              <div className="card-body">
                <table className="table table-hoverable">
                  <tbody>
                    {vettingAssessmentData.oneWay.map((eachdata) => (
                      <tr>
                        <td>
                          <h6 class="mb-1">{eachdata.title}</h6>
                          <div class=" py-2 mt-2">
                            <span class="mr-2 tag font-bold tag-orange1">
                              {" "}
                              Status : {eachdata.Status}
                            </span>
                            <span class="tag mb-1 mr-1">
                              <i
                                aria-hidden="true"
                                class="far fa-fw fa-stopwatch mr-1"
                              ></i>
                              Assigned By : {eachdata.assignedBy}{" "}
                            </span>
                            <span class="tag mb-1 ml-2">
                              <i
                                aria-hidden="true"
                                class="fal fa-fw fa-clock mr-1"
                              ></i>{" "}
                              Assigned:{" "}
                              {eachdata.recordedTime
                                ? moment(eachdata.recordedTime).format(
                                    "MM/DD/YYYY"
                                  )
                                : "N/A"}{" "}
                            </span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        <Modal size="lg" show={certificatePopUp} onHide={closeModel}>
          <div className="bl-modal-header">
            <h6 className="bl-modal-title">{"Certificate"}</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeModel}
              className="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            <AssessmentCertificate
              handleSuccess={closeModel}
              candidateData={props.candidateData}
              certificate={certificate}
            />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
}

export default CandidateAssessments;
