import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getUserRole } from "../../../../../service/AuthService";
import { getDispositionCount } from "../../../../../actions/disposition/DispositionCount";
import { useParams } from "react-router";

const DispositionHeader = (props) => {
  const { view, setView, isFrom, value, Tab } = props;
  const [itemLength, setItemLength] = useState([]);
  const { Id } = useParams();
  const dispatch = useDispatch();
  const {
    dispositionDetails,
    dispositionCount,
    dispositionCountLoading,
  } = useSelector(
    (state) => ({
      dispositionDetails: state.dispositionDetails.dispositionDetails,
      dispositionCount: state.dispositionCount.dispositionCount,
      dispositionCountLoading: state.dispositionCount.dispositionCountLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    const payload = { jobId: Id };
    dispatch(getDispositionCount(payload));
  }, []);

  useEffect(() => {
    if (view == "automatched") {
      dataLength(38);
    } else if (view == "qualified") {
      dataLength([33, 38]);
    } else if (view == "invite") {
      dataLength(33);
    } else if (view == "submitted") {
      dataLength(6);
    } else if (view == "shortlist") {
      dataLength(8);
    } else if (view == "offered") {
      dataLength(37);
    } else if (view == "hired") {
      dataLength(11);
    }
  }, [dispositionDetails, value]);

  const handleTab = (isRecruiter, isCM, status) => {
    switch (status) {
      case 38:
        isRecruiter || isCM ? setView("automatched") : setView("qualified");
        break;
      case 33:
        setView("invite");
        break;
      case 6:
        setView("submitted");
        break;
      case 8:
        setView("shortlist");
        break;
      case 32:
        setView("offered");
        break;
      case 11:
        setView("hired");
        break;

      default:
        isRecruiter || isCM ? setView("automatched") : setView("qualified");
        break;
    }
  };

  useEffect(() => {
    const role = getUserRole();
    const isRecruiter = role === "Recruiter";
    const isCM = role === "CommunityManager";
    if (Tab) {
      handleTab(isRecruiter, isCM, Tab * 1);
    } else if (isRecruiter || isCM) {
      setView("automatched");
    } else {
      setView("qualified");
    }
  }, [Tab]);

  const dataLength = (status) => {
    let filteredItems = [];

    if (value !== "") {
      let data = dispositionDetails.map((e) => ({
        ...e,
        name: e.firstName.toLowerCase() + " " + e.lastName.toLowerCase(),
      }));
      const regex = new RegExp(props.value, "i");
      filteredItems = data.filter((item) => {
        if (
          Array.isArray(status)
            ? status.includes(item.dispositionStatus)
            : item.dispositionStatus === status
        ) {
          return regex.test(item.name);
        } else {
          return item;
        }
      });
    } else {
      filteredItems = dispositionDetails;
    }
    setItemLength(filteredItems);
  };

  const countHandler = (type) => {
    if (type === "Automatched") {
      const count = dispositionCount.filter((e) => e.Status === type);
      return count[0]?.Count;
    } else if (type === "Invite") {
      const count = dispositionCount.filter((e) => e.Status === type);
      return count[0]?.Count;
    } else if (type === "Qualified") {
      const count = dispositionCount.filter((e) => e.Status === type);
      return count[0]?.Count;
    } else if (type === "Submitted") {
      const count = dispositionCount.filter((e) => e.Status === type);
      return count[0]?.Count;
    } else if (type === "Shortlist") {
      const count = dispositionCount.filter((e) => e.Status === type);
      return count[0]?.Count;
    } else if (type === "Hired") {
      const count = dispositionCount.filter((e) => e.Status === type);
      return count[0]?.Count;
    } else if (type === "Offered") {
      const count = dispositionCount.filter((e) => e.Status === type);
      return count[0]?.Count;
    } else if (type === "Disqualified") {
      const count = dispositionCount.filter((e) => e.Status === type);
      return count[0]?.Count;
    }
  };

  return (
    <div className="card-body pb-0 d-flex flex-column justify-content-between">
      <div className="d-flex">
        {/* <p className="px-1">Active Candidates ({ActiveLength})</p> */}
        <p className="px-1">Active Candidates </p>
      </div>
      <ul
        className="nav d-flex gap-5 nav-tabs justify-content-between"
        style={{ overflowX: "auto" }}
      >
        {(getUserRole() === "Recruiter" ||
          getUserRole() === "CommunityManager") && (
          <li className="nav-item w-25 d-flex flex-column justify-content-center">
            <button
              type="button"
              className={
                view == "automatched" ? "nav-link pb-3 active" : "nav-link pb-3"
              }
              onClick={() => setView("automatched")}
            >
              <span className="tag mb-1">{countHandler("Automatched")}</span>
              <div>Automatched</div>
            </button>
          </li>
        )}
        {getUserRole() !== "Recruiter" && getUserRole() !== "CommunityManager" && (
          <li className="nav-item w-25 d-flex flex-column justify-content-center">
            <button
              type="button"
              className={
                view == "qualified" ? "nav-link pb-3 active" : "nav-link pb-3"
              }
              onClick={() => setView("qualified")}
            >
              <span className="tag mb-1">{countHandler("Qualified")}</span>
              <div>Invited</div>
            </button>
          </li>
        )}

        {(getUserRole() === "Recruiter" ||
          getUserRole() === "CommunityManager") && (
          <li className="nav-item w-25 d-flex flex-column justify-content-center">
            <button
              type="button"
              className={
                view == "invite" ? "nav-link pb-3 active" : "nav-link pb-3"
              }
              onClick={() => setView("invite")}
            >
              <span className="tag mb-1">{countHandler("Invite")}</span>
              <div>Invited</div>
            </button>
          </li>
        )}
        {
          <li className="nav-item w-25 d-flex flex-column justify-content-center">
            <button
              type="button"
              className={
                view == "submitted" ? "nav-link pb-3 active" : "nav-link pb-3"
              }
              onClick={() => setView("submitted")}
            >
              <span className="tag mb-1">
                {countHandler("Submitted")}
                {countHandler("Submitted") > 0 && <span className="jewel" />}
              </span>
              <div>Submitted</div>
            </button>
          </li>
        }
        {
          <li className="nav-item w-25 d-flex flex-column justify-content-center">
            <button
              type="button"
              className={
                view == "shortlist" ? "nav-link pb-3 active" : "nav-link pb-3"
              }
              onClick={() => setView("shortlist")}
            >
              <span className="tag mb-1">{countHandler("Shortlist")}</span>
              <div>Shortlisted</div>
            </button>
          </li>
        }
        {
          <li className="nav-item w-25 d-flex flex-column justify-content-center">
            <button
              type="button"
              className={
                view == "offered" ? "nav-link pb-3 active" : "nav-link pb-3"
              }
              onClick={() => setView("offered")}
            >
              <span className="tag mb-1">{countHandler("Offered")}</span>
              <div>Offered</div>
            </button>
          </li>
        }
        <li className="nav-item w-25 d-flex flex-column justify-content-center">
          <button
            type="button"
            className={
              view == "hired" ? "nav-link pb-3 active" : "nav-link pb-3"
            }
            onClick={() => setView("hired")}
          >
            <span className="tag mb-1">{countHandler("Hired")}</span>
            <div>Hired</div>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default DispositionHeader;
