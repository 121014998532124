import React, { Component, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { FaGithub, FaLinkedin } from "react-icons/fa";
import { useGoogleLogin } from "@react-oauth/google";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { apiDomain } from "../../../../constants/Constants";
// import FacebookLoginButton from "./Facebooklogin.js";
import GitHubLogin from "react-github-login";
import { post } from "../../../../service/ApiService";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";
const SignUp = ({
  setSocialMediaApply,
  setLoginData,
  isLoginModal,
  isFrom,
}) => {
  const client_id = "Iv1.ea6c9e6f3188d421";
  const client_secret = "a13e4fd12ef38ebb81f59ec12f8afd0cd041d80d";
  const redirect_uri = `${window.location.origin}/github`;

  const [loading, setLoading] = useState(false);
  const component = "SignUp";
  const googleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      const { access_token } = response;
      try {
        const res = await axios.get(
          "https://www.googleapis.com/oauth2/v3/userinfo",
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        console.log("res", res.data);
        // sessionStorage.setItem("name", res.data?.name);
        // sessionStorage.setItem("userName", res.data?.email);
        // sessionStorage.setItem("token", res.data?.sub);
        // setLoginData(res.data);
        sessionStorage.setItem("method", "google");
        sessionStorage.setItem("code", access_token);
        setLoginData({
          firstName: res?.data?.given_name,
          lastName: res?.data?.family_name,
          email: res.data?.email,
        });
        // setUserLoggedIn(true);
        setSocialMediaApply(true);
        // window.location.reload();
      } catch (err) {
        console.log(err);
      }
    },
  });

  const { linkedInLogin: LinkedinApply } = useLinkedIn({
    clientId: "77uhcseceaakxp",
    scope: "openid profile email",
    redirectUri: `${window.location.origin}/linkedin`,
    onSuccess: (code) => {
      console.log("isLoginModal", isLoginModal);
      if (!isLoginModal) {
        console.log("LinkedinApply", component);
        let params = {
          grant_type: "authorization_code",
          code: code,
          client_id: "77uhcseceaakxp",
          client_secret: "a71lZaOZdDapw1Vg",
          redirect_uri: `${window.location.origin}/linkedin`,
        };
        setLoading(true);
        axios
          .post(`${apiDomain}/linkedInLogin/linkedin`, params)
          .then((data) => {
            sessionStorage.setItem("method", "linkedin");
            sessionStorage.setItem("code", data.data.access_token);
            setLoginData({
              firstName: data?.data?.given_name,
              lastName: data?.data?.family_name,
              email: data?.data?.email,
            });

            setSocialMediaApply(true);

            setLoading(false);
          })
          .catch((err) => {
            console.log("error", err);
            setLoading(false);
          });
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  // const facebookLogin = (data) => {
  //   let name = data.name;
  //   let fname = name.split(" ")[0];
  //   let lname = name.split(" ")[1];
  //   // handleLogin(name, "", "");
  //   // sessionStorage.setItem("name", name);
  //   // sessionStorage.setItem("userName", data?.email);
  //   // sessionStorage.setItem("token", data?.accessToken);
  //   sessionStorage.setItem("method", "facebook");
  //   sessionStorage.setItem("code", data?.accessToken);
  //   setLoginData({
  //     firstName: fname,
  //     lastName: lname,
  //     email: data?.email,
  //   });
  //   //
  //   // setUserLoggedIn(true);
  //   setSocialMediaApply(true);
  //   setLoading(false);
  // };
  const onSuccessGit = (response) => {
    console.log("GitHub login successful", response);

    const { code } = response;

    if (code) {
      exchangeCodeForToken(code);
    } else {
      console.error(
        "GitHub login failed. Authorization code not found in response:",
        response
      );
    }
  };

  const onFailureGit = (err) => {
    console.error("GitHub login failed", err);
  };

  const exchangeCodeForToken = async (code) => {
    try {
      setLoading(true);
      await axios
        .post(`${apiDomain}/githubLogin/github`, {
          client_id: client_id,
          client_secret: client_secret,
          code: code,
          redirect_uri: redirect_uri,
        })
        .then((response) => {
          const data = response.data;
          const accessToken = data.access_token;
          const email = data?.email;
          // Now, you can use the accessToken for authenticated requests or save it as needed
          console.log("Access token obtained:", accessToken);
          // Handle the access token as needed (e.g., save it to sessionStorage)
          setLoading(false);
          if (email) {
            sessionStorage.setItem("method", "github");
            sessionStorage.setItem("code", accessToken);

            setLoginData({
              firstName: data.name || "",
              email: data?.email,
            });
            // Additional handling logic...

            setSocialMediaApply(true);
          } else {
            toast.error("Please try other methods to apply");
          }
        });
    } catch (error) {
      console.error("Error exchanging GitHub code for access token:", error);
      setLoading(false);
    }
  };

  return (
    <div
      className={
        isFrom === "jobDetails"
          ? "main_signup_container"
          : "main_signup_container"
      }
    >
      {loading && <Loader />}
      <div className="main_apply_container_apply main_apply_container_signup">
        <p style={{ fontWeight: "bold", fontSize: "10px" }}>
          Unlock future Possibilities - Sign up Now!
        </p>
        <Row className="d-flex justify-content-around w-100 mx-auto ">
          <Col className="text-center">
            <button className="btn btn-sm btn-secondary font-18 border-0">
              {" "}
              <FaLinkedin
                onClick={() => LinkedinApply()}
                className="pointer"
                size={20}
                style={{ color: "#0077b5" }}
              />
            </button>
          </Col>

          <Col className="text-center">
            <button className="btn btn-sm btn-secondary font-18  border-0">
              <img
                onClick={() => googleLogin()}
                className="pointer"
                src="https://upload.wikimedia.org/wikipedia/commons/7/7e/Gmail_icon_%282020%29.svg"
                alt="Gmail Icon"
                style={{ width: 20, height: 20 }}
              />
            </button>
          </Col>
          {/* <Col className="text-center">
            <FacebookLoginButton facebookLogin={facebookLogin} />
          </Col> */}
          <Col className="text-center ">
            <GitHubLogin
              className="text-black btn btn-sm btn-secondary font-18 border-0"
              clientId={client_id} // GitHub App
              // clientId="03e704e88953067267af"    // GitHub OAuth App
              redirectUri={redirect_uri}
              onSuccess={onSuccessGit}
              onFailure={onFailureGit}
            >
              {" "}
              <FaGithub />
            </GitHubLogin>
          </Col>
        </Row>

        <hr className="line"></hr>
      </div>
    </div>
  );
};

export default SignUp;
