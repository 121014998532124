import React, { useEffect, useState } from "react";
import InfoCard from "./InfoCard.js";
import UserTable from "./UserTable.js";
import { get } from "../../../../../service/ApiService.js";
import { toast } from "react-toastify";
import Loader from "../../../../Loader/Loader.js";

const Users = () => {
  const [role, setRole] = useState("csm");
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataSize, setDataSize] = useState(10);

  const onTabClick = (type, result = null) => {
    setCurrentPage(1);
    setDataSize(10);
    if (type === "csm") {
      setRole("csm");
      if (result) {
        setUserData(result.filter((item) => item.type === "High5Coordinator"));
      } else {
        setUserData(users.filter((item) => item.type === "High5Coordinator"));
      }
    }
    if (type === "mp") {
      setRole("mp");
      if (result) {
        setUserData(result.filter((item) => item.type === "CommunityManager"));
      } else {
        setUserData(users.filter((item) => item.type === "CommunityManager"));
      }
    }

    if (type === "ea") {
      setRole("ea");
      if (result) {
        setUserData(result.filter((item) => item.type === "CustomerSuccess"));
      } else {
        setUserData(users.filter((item) => item.type === "CustomerSuccess"));
      }
    }
  };

  useEffect(() => {
    getusersData(role);
  }, []);

  const getusersData = async (type) => {
    try {
      setLoading(true);
      const res = await get("/users/adminUsers");
      if (res.status === 200) {
        const result = res.data.sort(
          (a, b) => new Date(b.createddatetime) - new Date(a.createddatetime)
        );
        setUsers(result);
        onTabClick(type, result);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing admin Details");
    }
  };
  return (
    <div class="p-3 mt-4">
      {loading && <Loader />}
      <div className="d-flex justify-content-between">
        <div className="d-flex justify-content-between mt-3">
          <ul class="nav nav-tabs  ">
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("csm");
                }}
                type="button"
                class={role == "csm" ? "nav-link pb-3 active" : "nav-link pb-3"}
              >
                <div>
                  <span className="tag">
                    {users.filter((item) => item.type === "High5Coordinator")
                      ?.length ?? 0}
                  </span>
                </div>
                <div>Customer Success Manager</div>
              </button>
            </li>
            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("mp");
                }}
                type="button"
                class={role == "mp" ? "nav-link pb-3 active" : "nav-link pb-3"}
              >
                {" "}
                <div>
                  <span className="tag">
                    {users.filter((item) => item.type === "CommunityManager")
                      ?.length ?? 0}
                  </span>
                </div>
                <div>Marketplace Manager</div>
              </button>
            </li>

            <li class="nav-item">
              <button
                onClick={() => {
                  onTabClick("ea");
                }}
                type="button"
                class={role == "ea" ? "nav-link pb-3 active" : "nav-link pb-3"}
              >
                <div>
                  <span className="tag">
                    {users.filter((item) => item.type === "CustomerSuccess")
                      ?.length ?? 0}
                  </span>
                </div>
                <div>High5 Admin</div>
              </button>
            </li>
          </ul>
        </div>
      </div>
      <hr className="mt-0" />
      <div>
        <div class="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end justify-content-center  border-bottom-gray2">
          <div class="d-flex align-items-center justify-content-lg-start"></div>
        </div>
        <UserTable
          userList={[]}
          userData={userData}
          getusersData={getusersData}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          setDataSize={setDataSize}
          dataSize={dataSize}
        />
      </div>
    </div>
  );
};

export default Users;
