import React, { useEffect } from "react";
import { FaEnvelope, FaPaperPlane, FaPencilAlt, FaUser } from "react-icons/fa";
import { RiInboxFill } from "react-icons/ri";

const Tabs = ({ activeTab, setActiveTab, from, isFrom, method }) => {
  // useEffect(() => {
  //   if (isFrom === "topNavbar") {
  //     setActiveTab("sent");
  //   }
  // }, []);

  return (
    <>
      <div className="d-flex" style={{ borderBottom: "1px solid #ccc" }}>
        <ul className="nav nav-tabs  ">
          <li className="nav-item">
            <button
              type="button"
              className={
                `${activeTab}` === "compose" ? "nav-link active" : "nav-link"
              }
              onClick={() => {
                setActiveTab("compose");
              }}
            >
              <span>
                <FaPencilAlt />
                {from === "SMS"
                  ? " Compose New Message"
                  : from === "whatsApp"
                  ? " Compose WhatsApp Message"
                  : " Compose New Mail"}
              </span>
            </button>
          </li>
          <li className="nav-item">
            <button
              type="button"
              className={
                `${activeTab}` === "sent" ? "nav-link active" : "nav-link "
              }
              onClick={() => {
                setActiveTab("sent");
              }}
            >
              <span>
                <FaPaperPlane /> Sent
              </span>
            </button>
          </li>
        </ul>
      </div>

      {/* <div className="menu-options-row"> */}
      {/* {isFrom === "topNavbar" ? null : ( */}

      {/* )} */}
      {/* {method !== "email" && (
          <div
            className={`menu-option ${activeTab === "inbox" ? "active" : ""}`}
            onClick={() => {
              setActiveTab("inbox");
            }}
            style={{ fontSize: "14px" }}
          >
            <RiInboxFill size={16} /> Inbox
          </div>
        )} */}

      {/* <div
          className={`menu-option ${activeTab === "templates" ? "active" : ""}`}
          onClick={() => {
            setActiveTab("templates");
          }}
          style={{ fontSize: "14px" }}
        >
          <FaEnvelope /> Templates
        </div>{" "} */}
      {/* <div
          className={`menu-option ${
            activeTab === "recipients" ? "active" : ""
          }`}
          onClick={() => {
            setActiveTab("recipients");
          }}
          style={{ fontSize: "14px" }}
        >
          <FaUser /> Recipients
        </div> */}
      {/* </div> */}
    </>
  );
};

export default Tabs;
