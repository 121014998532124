import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import UserOverview from "./UserOverview";
import Loader from "../../../commoncomponents/Loader/Loader";
import {
  getClientUserData,
  getClientUserDataCount,
} from "../../../../../actions/dashboard/ClientUserData";

const UsersData = ({ clientList }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [dataTotal, setDataTotal] = useState(0);
  const [dataSize, setDataSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const isFirstRender = useRef(true);
  const [sortArray, setSortArray] = useState([]);

  useEffect(() => {
    fetchClientData(1, 10, "", sortArray);
    const payload = {
      searchText: "",
    };
    dispatch(getClientUserDataCount(payload));
  }, []);

  const { usersData, usersCount, loading, countLoading } = useSelector(
    (state) => ({
      usersData: state.getClientUserData.clientData,
      usersCount: state.getClientUserData.userDataCount,
      loading: state.getClientUserData.loading,
      countLoading: state.getClientUserData.countLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    setCurrentPage(1);
    usersCount.length > 0 && setDataTotal(usersCount[0].Count);
  }, [usersCount]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchData.length === 0 || searchData.length >= 2) {
        fetchClientData(1, dataSize, searchData, sortArray);
        const payload = {
          searchText: searchData,
        };
        dispatch(getClientUserDataCount(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchData]);

  const fetchClientData = (pageNumber, limit, searchVal, sortArray) => {
    const payload = {
      currentPage: pageNumber,
      limit: limit,
      searchText: searchVal,
      sortQuery: sortArray,
    };
    dispatch(getClientUserData(payload));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchClientData(pageNumber, dataSize, searchData, sortArray);
  };

  const handleSizeChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
    fetchClientData(1, ds, searchData, sortArray);
  };

  const getTenantData = () => {
    fetchClientData(1, dataSize, searchData, sortArray);
    dispatch(getClientUserDataCount());
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
  };
  const applySort = (sortedData) => {
    setCurrentPage(1);
    fetchClientData(1, dataSize, searchData, sortedData);
  };
  return (
    <div>
      {(countLoading || loading) && <Loader />}
      <UserOverview
        clientList={usersData}
        getTenantData={getTenantData}
        isFrom={"clients"}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        handleSizeChange={handleSizeChange}
        dataTotal={dataTotal}
        dataSize={dataSize}
        handleSearch={handleSearch}
        searchData={searchData}
        sortArray={sortArray}
        setSortArray={setSortArray}
        applySort={applySort}
        fetchData={fetchClientData}
      />
    </div>
  );
};

export default UsersData;
