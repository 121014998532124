import React, { useState, useEffect, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import LearningListView from "./LearningListView";
import LearningGridView from "./LearningGridView";
import LearningTableView from "./LearningTableView";
import TopNavBar from "../../layout/TopNavBar";
import SideNav from "../../layout/SideNavBar";
import { getTenantType, getUserEmail } from "../../../service/AuthService";
import _ from "lodash";
import { get, post } from "../../../service/ApiService";
import moment from "moment";
import Loader from "../../Loader/Loader";
import Sort from "../../common/Sort";
import { useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import Footer from "../../layout/Footer";

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(
    initialIsVisible
  );

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return { ref, isComponentVisible, setIsComponentVisible };
}

const LearningModule = () => {
  const [learningList, setLearningList] = useState([]);

  let learningDifficulties = {};
  let uniqueDifficulties = [...new Set(learningList.map((i) => i.difficulty))];
  uniqueDifficulties.forEach((i) => (learningDifficulties[i] = false));

  let learningDurations = {};
  let uniqueDurations = [...new Set(learningList.map((i) => i.duration))];
  uniqueDurations.forEach((i) => (learningDurations[i] = false));

  const [listView, setListView] = useState(true);
  const [gridView, setGridview] = useState(false);
  const [tableView, setTableView] = useState(false);
  const [allTabView, setAllTabView] = useState(true);
  const [assignedTabView, setAssignedTabView] = useState(false);
  const [inprogressTabView, setInProgressTabView] = useState(false);
  const [completedTabView, setCompletedTabView] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [sortModal, setSortModal] = useState(false);
  const [sortBy, setSortBy] = useState([]);
  const [selectSortOrder, setSelectSortOrder] = useState([false, false, false]);
  const [addSort, setAddSort] = useState([false, false, false]);
  const [filterBy, setFilterBy] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [lastUpdated, setLastUpdated] = useState("");
  const [lastUpdatedDate, setLastUpdatedDate] = useState(null);
  const [lastUpdatedDateBetween, setLastUpdatedDateBetween] = useState(
    new Array(2).fill(null)
  );
  const [learningDifficulty, setLearningDifficulty] = useState(
    learningDifficulties
  );
  const [learningDuration, setLearningDuration] = useState(learningDurations);
  const [filterDifficulty, setFilterDifficulty] = useState([]);
  const [filterDuration, setFilterDuration] = useState([]);
  const [toggle, setToggle] = useState(true);
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible,
  } = useComponentVisible(false);
  const [loading, setLoading] = useState(false);
  const [tovutiId, setTovutiId] = useState("");

  const sortObject = [
    {
      id: 1,
      label: "Title",
      value: "CourseName",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Progress",
      value: "PercentComplete",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Last updated",
      value: "LastActivityDate",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  // Get data from reducers
  const { tovutiLoading } = useSelector(
    (state) => ({
      tovutiLoading: state.tovutiReducer.loading,
    }),
    shallowEqual
  );

  const toggleDiv = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    !tovutiId && fetchTovutiId();
    fetchCourses();
  }, [tovutiId]);

  const fetchTovutiId = async () => {
    setLoading(true);
    try {
      let res = await get("/candidateProfile/getTovutiUserFromIndex");
      if (res.status === 200) {
        res.data.length > 0 && setTovutiId(res.data[0].id);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Some Error Occurred while Fetching TovutiID");
      }
    } catch (err) {
      setLoading(false);
      toast.error("Failed to get TovutiID");
    }
  };

  const fetchCourses = async () => {
    setLoading(true);
    if (tovutiId) {
      const res = await post("/tovuti/getUsersTovutiCourses", {
        userId: tovutiId,
      });
      setLearningList(res.data);
      setFilterBy(res.data);
      setFilterData(res.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const setStatusView = (type) => {
    if (type === "all") {
      setFilterData(filterBy);
      if (allTabView === false) {
        setAllTabView(true);
      } else {
        setAllTabView(false);
      }
      setAssignedTabView(false);
      setInProgressTabView(false);
      setCompletedTabView(false);
    }

    if (type === "notstarted") {
      setFilterData(filterBy.filter((i) => i.PercentComplete === 0));
      if (assignedTabView === false) {
        setAssignedTabView(true);
      } else {
        setAssignedTabView(false);
      }
      setAllTabView(false);
      setInProgressTabView(false);
      setCompletedTabView(false);
    }

    if (type === "inprogress") {
      setFilterData(
        filterBy.filter(
          (i) => i.PercentComplete > 0 && i.PercentComplete !== 100
        )
      );
      if (inprogressTabView === false) {
        setInProgressTabView(true);
      } else {
        setInProgressTabView(false);
      }
      setAllTabView(false);
      setAssignedTabView(false);
      setCompletedTabView(false);
    }

    if (type === "completed") {
      setFilterData(filterBy.filter((i) => i.PercentComplete === 100));
      if (completedTabView === false) {
        setCompletedTabView(true);
      } else {
        setCompletedTabView(false);
      }
    }
  };

  const toggleView = (type) => {
    if (type === "list") {
      if (listView === false) {
        setListView(true);
      } else {
        setListView(false);
      }
      setGridview(false);
      setTableView(false);
    }

    if (type === "grid") {
      if (gridView === false) {
        setGridview(true);
      } else {
        setGridview(false);
      }
      setListView(false);
      setTableView(false);
    }

    if (type === "table") {
      if (tableView === false) {
        setTableView(true);
      } else {
        setTableView(false);
      }
      setListView(false);
      setGridview(false);
    }
  };

  const learningStatus = (data) => {
    if (allTabView) {
      setFilterData(data);
    } else if (assignedTabView) {
      setFilterData(data.filter((i) => i.PercentComplete === 0));
    } else if (inprogressTabView) {
      setFilterData(
        data.filter((i) => i.PercentComplete > 0 && i.PercentComplete !== 100)
      );
    } else {
      setFilterData(data.filter((i) => i.PercentComplete === 100));
    }
  };

  const addSortValues = (i) => {
    let a = [...addSort];
    a[i] ? (a[i] = false) : (a[i] = true);
    setAddSort(a);
  };

  const addSortOrder = (i) => {
    let order = [...selectSortOrder];
    order[i] ? (order[i] = false) : (order[i] = true);
    setSelectSortOrder(order);
  };

  const applySort = (sortedData, close) => {
    close && setIsComponentVisible(false);
    setFilterBy(sortedData);
    learningStatus(sortedData);
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(learningList, true);
  };

  const openFilterModal = () => {
    setStatusView("all");
    setAllTabView(true);
    setFilterModal(true);
  };

  const difficultyHandler = (checked, difficulty) => {
    if (checked) {
      setFilterCount((count) => count + 1);
      setLearningDifficulty({ ...learningDifficulty, [difficulty]: true });
      let f = [...filterDifficulty];
      f.push(difficulty);
      setFilterDifficulty(f);
    } else {
      setFilterCount((count) => count - 1);
      setLearningDifficulty({ ...learningDifficulty, [difficulty]: false });
      setFilterDifficulty(
        [...filterDifficulty].filter((i) => i !== difficulty)
      );
    }
  };

  const clearDifficulty = () => {
    let f = { ...learningDifficulty };
    Object.entries(f).forEach((i) => {
      if (i[1]) {
        f[i[0]] = false;
        setFilterCount((count) => count - 1);
      }
    });
    setLearningDifficulty(f);
    setFilterDifficulty([]);
  };

  const durationHandler = (checked, duration) => {
    if (checked) {
      setFilterCount((count) => count + 1);
      setLearningDuration({ ...learningDuration, [duration]: true });
      let f = [...filterDuration];
      f.push(duration);
      setFilterDuration(f);
    } else {
      setFilterCount((count) => count - 1);
      setLearningDuration({ ...learningDuration, [duration]: false });
      setFilterDuration([...filterDuration].filter((i) => i !== duration));
    }
  };

  const clearDuration = () => {
    let f = { ...learningDuration };
    Object.entries(f).forEach((i) => {
      if (i[1]) {
        f[i[0]] = false;
        setFilterCount((count) => count - 1);
      }
    });
    setLearningDuration(f);
    setFilterDuration([]);
  };

  const clearAll = () => {
    setLastUpdated("");
    clearDifficulty();
    clearDuration();
  };

  const difficultyArray = (difficulty) => {
    return filterDifficulty.length > 0
      ? filterDifficulty.includes(difficulty)
      : true;
  };

  const durationArray = (d) => {
    return filterDuration.length > 0 ? filterDuration.includes(d) : true;
  };

  const applyFilter = () => {
    sortClearAll();
    setFilterModal(false);
    if (lastUpdated !== "") {
      let filtered;
      let day;
      let day1;
      let day2;
      if (lastUpdated === "after" || lastUpdated === "before") {
        day = +lastUpdatedDate.split(" ")[0];
      } else {
        day1 = +lastUpdatedDateBetween[0].split(" ")[0];
        day2 = +lastUpdatedDateBetween[1].split(" ")[0];
      }
      if (lastUpdated === "after") {
        filtered = learningList.filter((i) => {
          return (
            +moment(i.LastActivityDate, "YYYYMMDD")
              .fromNow()
              .split(" ")[0] < day
          );
        });
      } else if (lastUpdated === "before") {
        filtered = learningList.filter((i) => {
          return (
            +moment(i.LastActivityDate, "YYYYMMDD")
              .fromNow()
              .split(" ")[0] > day
          );
        });
      } else {
        filtered = learningList.filter((i) => {
          return (
            +moment(i.LastActivityDate, "YYYYMMDD")
              .fromNow()
              .split(" ")[0] < day1 &&
            +moment(i.LastActivityDate, "YYYYMMDD")
              .fromNow()
              .split(" ")[0] > day2
          );
        });
      }
      setFilterBy(filtered);
      setFilterData(filtered);
    } else {
      setFilterBy(learningList);
      setFilterData(learningList);
    }
  };

  return (
    <>
      <div>
        <body
          className={toggle ? "layout1 layout1-closed close-menu" : "layout1"}
        >
          <div className="body-decorator body-decorator-top"></div>
          <div className="body-decorator body-decorator-bottom"></div>
          <TopNavBar handleToggale={toggleDiv} />
          <SideNav />
          {loading && <Loader />}
          <div id="Content">
            <div id="AssessmentsApp" className="section section-sm">
              <div className="hero">
                <div className="section pt-5">
                  <div className="container">
                    <div className="row justify-content-center mb-5">
                      <div className="d-flex justify-content-between align-items-center col-10 font-white">
                        <h5 className="mb-0">Learning Modules</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <div className="card translate-up-100">
                      <div className="card-body">
                        <div className="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end mb-3 justify-content-between border-bottom-gray2">
                          <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                            <div className="d-none d-xl-flex nav nav-tabs justify-content-between">
                              <div className="nav-item d-flex justify-content-center">
                                <button
                                  onClick={() => {
                                    toggleView("list");
                                  }}
                                  type="button"
                                  className={
                                    listView
                                      ? "nav-link pb-3 bg-transparent active"
                                      : "nav-link pb-3 bg-transparent"
                                  }
                                >
                                  <i className="fas fa-list"></i>
                                </button>
                              </div>
                              <div className="nav-item d-flex justify-content-center">
                                <button
                                  onClick={() => {
                                    toggleView("grid");
                                  }}
                                  type="button"
                                  className={
                                    gridView
                                      ? "nav-link pb-3 bg-transparent active"
                                      : "nav-link pb-3 bg-transparent"
                                  }
                                >
                                  <i className="fas fa-th-large"></i>
                                </button>
                              </div>
                              <div className="nav-item d-flex justify-content-center">
                                <button
                                  onClick={() => {
                                    toggleView("table");
                                  }}
                                  type="button"
                                  className={
                                    tableView
                                      ? "nav-link pb-3 bg-transparent active"
                                      : "nav-link pb-3 bg-transparent"
                                  }
                                >
                                  <i className="fas fa-table"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                          <ul className="nav nav-tabs nav-tabs-tablet pt-1 justify-content-center justify-content-lg-start">
                            <li className="nav-item">
                              <button
                                type="button"
                                onClick={() => {
                                  setStatusView("all");
                                }}
                                className={
                                  allTabView
                                    ? "nav-link pb-3 mx-2 bg-transparent active"
                                    : "nav-link pb-3 mx-2 bg-transparent"
                                }
                              >
                                <span className="tag mb-1">
                                  {filterBy.length}
                                </span>
                                <div>All</div>
                              </button>
                            </li>
                            <li className="nav-item">
                              <button
                                type="button"
                                className={
                                  assignedTabView
                                    ? "nav-link pb-3 mx-2 bg-transparent active"
                                    : "nav-link pb-3 mx-2 bg-transparent"
                                }
                                onClick={() => {
                                  setStatusView("notstarted");
                                }}
                              >
                                <span class="tag mb-1">
                                  {
                                    filterBy.filter(
                                      (i) => i.PercentComplete === 0
                                    ).length
                                  }
                                </span>
                                <div>Assigned</div>
                              </button>
                            </li>
                            <li className="nav-item">
                              <button
                                type="button"
                                className={
                                  inprogressTabView
                                    ? "nav-link pb-3 mx-2 bg-transparent active"
                                    : "nav-link pb-3 mx-2 bg-transparent"
                                }
                                onClick={() => {
                                  setStatusView("inprogress");
                                }}
                              >
                                <span className="tag mb-1">
                                  {
                                    filterBy.filter(
                                      (i) =>
                                        i.PercentComplete > 0 &&
                                        i.PercentComplete !== 100
                                    ).length
                                  }
                                </span>
                                <div>In Progress</div>
                              </button>
                            </li>
                            <li className="nav-item">
                              <button
                                type="button"
                                onClick={() => {
                                  setStatusView("completed");
                                }}
                                className="nav-link bg-transparent pb-3 mx-2"
                              >
                                <span className="tag mb-1">
                                  {
                                    filterBy.filter(
                                      (i) => i.PercentComplete === 100
                                    ).length
                                  }
                                </span>
                                <div>Completed</div>
                              </button>
                            </li>
                          </ul>
                          <div className="my-2 d-none d-block-1200"></div>
                          <div class="d-flex justify-content-end justify-content-even-400">
                            <div class="dropdown-sort dd-sort">
                              <button
                                type="button"
                                className="btn btn-sm btn-text-accent"
                                onClick={() =>
                                  isComponentVisible
                                    ? setIsComponentVisible(false)
                                    : setIsComponentVisible(true)
                                }
                              >
                                <i className="fad fa-sort-down font-16 mr-1"></i>{" "}
                                Sort / {sortCount}
                              </button>
                              <div ref={ref}>
                                {isComponentVisible && (
                                  <OutsideClickHandler
                                    onOutsideClick={() =>
                                      setIsComponentVisible(false)
                                    }
                                  >
                                    <Sort
                                      sortData={learningList}
                                      orderArray={orderList}
                                      sortObjects={sortObject}
                                      applySort={applySort}
                                      setSortCount={setSortCount}
                                      sortCount={sortCount}
                                      setSortArray={setSortArray}
                                      sortArray={sortArray}
                                      sortingFor={
                                        "Sort by Title, Progress and Last updated of Learning Modules"
                                      }
                                    />
                                  </OutsideClickHandler>
                                )}
                              </div>
                            </div>
                            <div className="dropdown-sort dd-filter ml-1">
                              <button
                                type="button"
                                className="btn btn-sm btn-text-accent"
                                onClick={openFilterModal}
                              >
                                <i className="fas fa-filter font-14 mr-1"></i>{" "}
                                Filter / {filterCount}
                              </button>
                            </div>
                          </div>
                        </div>
                        {!tovutiLoading ? (
                          <>
                            {listView && (
                              <LearningListView learningList={filterData} />
                            )}

                            {gridView && (
                              <LearningGridView learningList={filterData} />
                            )}

                            {tableView && (
                              <LearningTableView learningList={filterData} />
                            )}
                          </>
                        ) : (
                          <h6 className="m-5">
                            Please wait while we load courses for you...
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {getTenantType() == 1 && <Footer />}
          </div>
        </body>
        {filterModal && (
          <>
            <div className="view-panel show">
              <div className="view-panel-header view-panel-header-sm">
                <div className="p-relative">
                  <div className="px-3 py-3 d-flex">
                    <button
                      onClick={applyFilter}
                      type="button"
                      className="btn btn-sm btn-primary"
                    >
                      <i className="fal fa-check mr-2 fa-fw"></i>Done
                    </button>
                  </div>
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div className="px-3">
                    <h6>Filter / {filterCount}</h6>
                  </div>
                  <div className="px-3">
                    <button type="button" className="btn btn-text">
                      <span className="font-bold font-14" onClick={clearAll}>
                        Clear All
                      </span>
                    </button>
                  </div>
                </div>
              </div>
              <div className="view-panel-body view-panel-body-sm">
                <div className="m-3">
                  <details>
                    <summary className="font-16">Last updated</summary>
                    <div className="d-flex mt-2">
                      <select
                        onChange={(e) => {
                          setLastUpdated(e.target.value);
                        }}
                        className="form-select w-50 m-3"
                      >
                        <option selected value="">
                          {lastUpdated === "" && "Select Range"}
                        </option>
                        <option value="after">After</option>
                        <option value="before">Before</option>
                        <option value="between">Between</option>
                      </select>
                      {lastUpdated !== "" ? (
                        lastUpdated === "after" || lastUpdated === "before" ? (
                          <input
                            type="date"
                            onChange={(e) =>
                              setLastUpdatedDate(
                                moment("2021-11-08", "YYYYMMDD").fromNow()
                              )
                            }
                            style={{ maxHeight: "41px", margin: "14px 0px" }}
                            className="form-control date"
                            required
                          />
                        ) : (
                          <>
                            <input
                              type="date"
                              style={{ maxHeight: "41px", margin: "14px 0px" }}
                              className="form-control date mr-2"
                              onChange={(e) => {
                                let date = [...lastUpdatedDateBetween];
                                date[0] = moment(
                                  e.target.value,
                                  "YYYYMMDD"
                                ).fromNow();
                                setLastUpdatedDateBetween(date);
                              }}
                            />
                            <input
                              type="date"
                              style={{ maxHeight: "41px", margin: "14px 0px" }}
                              className="form-control date"
                              onChange={(e) => {
                                let date = [...lastUpdatedDateBetween];
                                date[1] = moment(
                                  e.target.value,
                                  "YYYYMMDD"
                                ).fromNow();
                                setLastUpdatedDateBetween(date);
                              }}
                            />
                          </>
                        )
                      ) : null}
                    </div>
                    <div className="d-flex justify-content-end py-2 px-1">
                      <button
                        className="btn btn-text"
                        onClick={() => setLastUpdated("")}
                      >
                        <span className="font-12">Clear Last updated</span>
                      </button>
                    </div>
                    <hr className="p-0" />
                  </details>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LearningModule;
