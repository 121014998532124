import { toast } from "react-toastify";

export const validate = (groupData) => {
    if (groupData?.groupname === "") {
      toast.error("Please Enter Community Name");
      return false;
    }
    if (groupData?.purpose === "") {
      toast.error("Please Enter Purpose");
      return false;
    }
    return true;
  };