import React, { useState } from "react";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import CandidateProfileOverview from "../../modal/talentprofile/CandidateProfileOverview"
import CandidateAssessments from './CandidateAssessments'

function TalentSideDetails(props) {
    const [ loading, setLoading ] = useState(false)

    return (
        <>
            {loading && <Loader />}
            <div className="col-xl-4">
                <div className="card card-flat card-lg card-top-straight" style={{ borderTop: 'none' }}>
                    <div className="card-body">
                        {props.tabStatus.Profile && (
                            <CandidateProfileOverview candidateData={props.candidateData} />
                        )}
                        {props.tabStatus.Assessments && (                         
                            <CandidateAssessments candidateData={props.candidateData} setLoading={setLoading} />
                        )}                                            
                    </div>
                </div>
            </div>
        </>
    )
}

export default TalentSideDetails
