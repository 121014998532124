// import React, { useEffect, useState } from "react";
// import { post } from "../../../../../../service/ApiService";
// import { getUserTenantID } from "../../../../../../service/AuthService";
// import Loader from "../../../../../Loader/Loader";

// const Activity = ({ jobData }) => {
//   const [loading, setLoading] = useState(false);
//   const [activity, setActivity] = useState([]);

//   useEffect(() => {
//     getActivity();
//   }, []);

//   const getActivity = async () => {
//     setLoading(true);
//     const payload = {
//       poolId: jobData?.poolId,
//       tenantId: getUserTenantID(),
//     };
//     await post(`/talent/getActivityDetails`, payload).then((res) => {
//       let setData = res?.data;
//       const countByDateWithFields = getCountByDateWithFieldsList(setData);
//       setActivity(countByDateWithFields);
//       setLoading(false);
//     });
//   };

//   function getCountByDateWithFieldsList(list) {
//     const dateFields = [
//       "invitedDate",
//       "submittedDate",
//       "joiningDate",
//       "shortlistedDate",
//       "offeredDate",
//       "offerRejectedDate",
//       "offerAcceptedDate",
//       "disqualifiedDate",
//       "automatchDate",
//     ];

//     const countByDateWithFields = {};

//     // Iterate over each date field
//     dateFields.forEach((dateField) => {
//       // Iterate over each object in the list
//       list.forEach((item) => {
//         const date = item[dateField];
//         if (date) {
//           // If the date is not null or undefined
//           if (!countByDateWithFields[date]) {
//             countByDateWithFields[date] = [];
//           }
//           // Check if an entry with the same field already exists for the date
//           const existingEntryIndex = countByDateWithFields[date].findIndex(
//             (entry) => entry.field === dateField
//           );
//           if (existingEntryIndex !== -1) {
//             // If exists, increment the count
//             countByDateWithFields[date][existingEntryIndex].count++;
//           } else {
//             // If not, add a new entry
//             countByDateWithFields[date].push({
//               date: date,
//               field: dateField,
//               count: 1,
//             });
//           }
//         }
//       });
//     });

//     return countByDateWithFields;
//   }
//   return (
//     <>
//       {loading && <Loader />}
//       <div class="timeline-wrap">
//       {activity?.map(([date, entries]) => (
//           <div key={date} className="timeline">
//             <i aria-hidden="true" className="fad fa-circle fa-fw icon"></i>
//             <div>
//               <small>{Object.entries(date)}</small>
//               {data.map((entry, index) => (
//                 <p key={index}>
//                   <strong>{entry.field}</strong> Count: {entry.count}
//                 </p>
//               ))}
//             </div>
//           </div>
//         ))}
//       </div>
//     </>
//   );
// };

// export default Activity;

import React, { useEffect, useState } from "react";
import { post } from "../../../../../../service/ApiService";
import { getUserTenantID } from "../../../../../../service/AuthService";
import Loader from "../../../../../Loader/Loader";
import moment from "moment";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";

const Activity = ({ jobData }) => {
  const [loading, setLoading] = useState(false);
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    getActivity();
  }, []);

  const getActivity = async () => {
    setLoading(true);
    const payload = {
      poolId: jobData?.poolId,
      tenantId: getUserTenantID(),
    };
    await post(`/talent/getActivityDetails`, payload).then((res) => {
      let setData = res?.data;
      const countByDateWithFields = getCountByDateWithFieldsList(setData);
      setActivity(countByDateWithFields);
      setLoading(false);
    });
  };

  function getCountByDateWithFieldsList(list) {
    const dateFields = [
      "invitedDate",
      "submittedDate",
      "joiningDate",
      "shortlistedDate",
      "vettedDate",
      "offeredDate",
      "offerRejectedDate",
      "offerAcceptedDate",
      "disqualifiedDate",
      "automatchDate",
    ];

    const countByDateWithFields = {};

    // Iterate over each date field
    dateFields.forEach((dateField) => {
      // Iterate over each object in the list
      list.forEach((item) => {
        const date = item[dateField];
        if (date) {
          // If the date is not null or undefined
          if (!countByDateWithFields[date]) {
            countByDateWithFields[date] = [];
          }
          // Check if an entry with the same field already exists for the date
          const existingEntryIndex = countByDateWithFields[date].findIndex(
            (entry) => entry.field === dateField
          );
          if (existingEntryIndex !== -1) {
            // If exists, increment the count
            countByDateWithFields[date][existingEntryIndex].count++;
          } else {
            // If not, add a new entry
            countByDateWithFields[date].push({
              date: date,
              field: dateField,
              count: 1,
            });
          }
        }
      });
    });

    // Sort the keys (dates) of the countByDateWithFields object
    const sortedDates = Object.keys(countByDateWithFields).sort((a, b) => new Date(b) - new Date(a));

    // Create a new object with sorted dates
    const sortedCountByDateWithFields = {};
    sortedDates.forEach((date) => {
      sortedCountByDateWithFields[date] = countByDateWithFields[date];
    });

    return sortedCountByDateWithFields;
  }

  return (
    <>
      {loading && <Loader />}
      <div className="timeline-wrap">
        {Object.keys(activity).length === 0 ? (
          <NoRecord />
        ) : (
          Object.entries(activity).map(([date, entries]) => (
            <div key={date} className="timeline">
              <i aria-hidden="true" className="fad fa-circle fa-fw icon"></i>
              <div>
                <small>{moment(date).fromNow()}</small>
                {entries.map((entry, index) => (
                  <p key={index}>
                    <strong>
                      {entry.field === "automatchDate"
                        ? "Automatched"
                        : entry.field === "invitedDate"
                        ? "Invited"
                        : entry.field === "submittedDate"
                        ? "Engaged"
                        : entry?.field === "vettedDate"
                        ? "Vetted"
                        : entry?.field === "offeredDate"
                        ? "Ready To Hire"
                        : "Ineligible"}
                    </strong>{" "}
                    {entry.count} {entry.count == 1 ? "candidate" : "candidates"}
                  </p>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default Activity;
