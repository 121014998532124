import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const GET_SAVED_CAREER_COUNT_BEGIN = "GET_SAVED_CAREER_COUNT_BEGIN";
export const GET_SAVED_CAREER_COUNT_SUCCESS = "GET_SAVED_CAREER_COUNT_SUCCESS";
export const GET_SAVED_CAREER_COUNT_FAILURE = "GET_SAVED_CAREER_COUNT_FAILURE";

export const getSavedCareerCount = (payloadObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_SAVED_CAREER_COUNT_BEGIN,
    });
    const res = await post("/career/getfilteredsavedjobsCount", payloadObj);
    if (res.status === 200) {
      dispatch({
        type: GET_SAVED_CAREER_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_SAVED_CAREER_COUNT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
