import React from "react";

const ScreeningQuestions = ({ QandA, setQandA, QandAValidation }) => {
  const handleRadioChange = (index, checked) => {
    let current = [...QandA];
    current[index] = { ...current[index], answer: checked ? "Yes" : "No" };
    setQandA(current);
  };

  const handleChange = (value, index) => {
    let current = [...QandA];
    current[index] = { ...current[index], answer: value };
    setQandA(current);
  };

  return (
    <div className="card card-flat bg-gray4 m-3">
      <div className="card-body">
        <div className="d-flex">
          <label className="ml-auto">*Denotes Mandatory Question</label>
        </div>
        {QandA.map((item, index) => (
          <div className="d-flex m-3">
            <div>
              <span>{index + 1}</span>
            </div>
            <div className="ml-3 mb-3">
              {item.question}
              {item.isMandatory ? "*" : null}
              {["Industry Experience", "Education"].includes(item.name) && (
                <div className="m-1">
                  <small>
                    <strong>{item.input}</strong>
                  </small>
                </div>
              )}
              <div className="row mt-2">
                {![
                  "Industry Experience",
                  "Work Experience",
                  "Custom Question",
                  "Language",
                ].includes(item.name) && (
                  <div className="col-lg-12 d-flex m-2">
                    <div className="form-check font-14 form-switch d-flex align-items-center">
                      <input
                        name="isHotJob"
                        className={
                          QandAValidation[index]
                            ? "border border-danger"
                            : "form-check-input mr-2"
                        }
                        type="checkbox"
                        id="hotJobSwitch"
                        //checked={item.answer === 'Yes'}
                        onChange={(e) =>
                          handleRadioChange(index, e.target.checked)
                        }
                      />
                      <label className="form-check-label pt-0">Yes</label>
                      {QandAValidation[index] && (
                        <small className="ml-1 mt-1 validation">
                          {QandAValidation[index]}
                        </small>
                      )}
                    </div>
                  </div>
                )}
                {item.name === "Custom Question" && (
                  <div>
                    {item.input === "Yes/No" && (
                      <div className="d-flex m-2">
                        <div className="form-check font-14 form-switch d-flex align-items-center">
                          <label className="form-check-label pt-0">Yes</label>
                          <input
                            name="isHotJob"
                            className="form-check-input ml-2"
                            type="checkbox"
                            id="hotJobSwitch"
                            checked={item.answer === "Yes"}
                            onChange={(e) =>
                              handleRadioChange(index, e.target.checked, "Yes")
                            }
                          />
                        </div>
                        <div className="form-check font-14 form-switch d-flex align-items-center">
                          <label className="form-check-label pt-0">No</label>
                          <input
                            name="isHotJob"
                            className="form-check-input ml-2"
                            type="checkbox"
                            id="hotJobSwitch"
                            checked={item.answer === "No"}
                            onChange={(e) =>
                              handleRadioChange(index, e.target.checked, "No")
                            }
                          />
                        </div>
                      </div>
                    )}
                    {item.input === "Numeric" && (
                      <div className="d-flex" style={{ width: "7rem" }}>
                        <div>
                          <input
                            type="number"
                            min="0"
                            className={
                              QandAValidation[index]
                                ? "border border-danger"
                                : ""
                            }
                            placeholder="Years"
                            onChange={(e) =>
                              handleChange(e.target.value, index)
                            }
                            value={item.answer}
                          />
                        </div>
                      </div>
                    )}
                    {item.input === "Text" && (
                      <div>
                        <div className="form-floating">
                          <input
                            type="text"
                            className={
                              QandAValidation[index]
                                ? "form-control border border-danger"
                                : "form-control"
                            }
                            value={item.answer}
                            placeholder="Answer"
                            onChange={(e) =>
                              handleChange(e.target.value, index)
                            }
                          />
                          <label>Answer</label>
                          {QandAValidation[index] && (
                            <small className="ml-1 mt-1 validation">
                              {QandAValidation[index]}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                    {item.input === "Long Text" && (
                      <div>
                        <div className="form-floating">
                          <textarea
                            className={
                              QandAValidation[index]
                                ? "form-control border border-danger"
                                : "form-control"
                            }
                            value={item.answer}
                            onChange={(e) =>
                              handleChange(e.target.value, index)
                            }
                          />
                          {QandAValidation[index] && (
                            <small className="ml-1 mt-1 validation">
                              {QandAValidation[index]}
                            </small>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
                {["Industry Experience", "Work Experience"].includes(
                  item.name
                ) && (
                  <>
                    <div className="d-flex" style={{ width: "7rem" }}>
                      <div>
                        <input
                          type="number"
                          min="0"
                          className={
                            QandAValidation[index]
                              ? "border border-danger"
                              : "form-contro"
                          }
                          placeholder="Years"
                          onChange={(e) => handleChange(e.target.value, index)}
                          value={item.answer}
                        />
                      </div>
                    </div>
                    {QandAValidation[index] && (
                      <small className="ml-1 mt-1 validation">
                        {QandAValidation[index]}
                      </small>
                    )}
                  </>
                )}
                {item.name === "Language" && (
                  <div className="col-lg-12">
                    {item.answer.map((item2, index2) => (
                      <div className="row m-2 d-flex align-items-center">
                        <div className="col-lg-4">
                          <strong>{item2.name}</strong>
                        </div>
                        <div className="col-lg-7">
                          <div className="form-floating w-100">
                            <select
                              className="form-control"
                              onChange={(e) => {
                                let QA = [...QandA];
                                let ans = [...item.answer];
                                ans[index2] = {
                                  ...ans[index2],
                                  proficiency: e.target.value,
                                };
                                QA[index] = { ...QA[index], answer: ans };
                                setQandA(QA);
                              }}
                            >
                              <option
                                selected={item2.proficiency === ""}
                                disabled
                              >
                                Select
                              </option>
                              {["Beginner", "Intermediate", "Expert"].map(
                                (val) => (
                                  <option
                                    selected={val === item2.proficiency}
                                    value={val}
                                  >
                                    {val}
                                  </option>
                                )
                              )}
                            </select>
                            <label>Proficiency</label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScreeningQuestions;
