import React, { Fragment, useEffect, useState, useRef } from "react";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  LoginAppLogo,
  domain,
  redirectTologinRtR,
} from "../../constants/Constants";
import { CommonRegister } from "../../actions/auth/Register";
import { useHistory } from "react-router-dom";
import { post } from "../../service/ApiService";
import StarRatings from "react-star-ratings";

function Register() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    redirectTologinRtR: "",
    LinkedinURL: "",
    skillSetList: [],
    countryList: [],
  });

  const {
    email,
    password,
    confirmPassword,
    firstName,
    lastName,
    LinkedinURL,
  } = formData;
  const [skillSet, setSkillSet] = useState([]);
  const [skillSetList, setSkillSetList] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [errors, setErrors] = useState({});
  const [load, setLoading] = useState(false);
  const onVChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  // Get data from reducers
  const { loading, loginDetails } = useSelector(
    (state) => ({
      loading: state.Register.loading,
      loginDetails: state.Register.loginDetails,
    }),
    shallowEqual
  );

  const findFormErrors = () => {
    const {
      email,
      firstName,
      lastName,
      password,
      confirmPassword,
      LinkedinURL,
    } = formData;
    const newErrors = {};
    // name errors

    if (!password || password === "")
      newErrors.password = "Password cannot be Blank";
    if (password.length < 6)
      newErrors.password = "minimum 6 digit password required ";
    if (!confirmPassword || confirmPassword === "")
      newErrors.confirmPassword = "Password cannot be Blank";
    if (confirmPassword.length < 6)
      newErrors.confirmPassword = "minimum 6 digit password required";
    if (!firstName || firstName === "")
      newErrors.firstName = "First Name  cannot be blank!";
    if (!lastName || lastName === "")
      newErrors.lastName = "Last Name  cannot be blank!";
    if (!email || email === "") newErrors.email = "Email id cannot be Blank";
    if (LinkedinURL != "" && !LinkedinURL.includes("www.linkedin.com"))
      newErrors.LinkedinURL = "Invalid URL found";
    else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(email))
      newErrors.email = "Invalid Email id !";
    //  if (password !== confirmPassword ) newErrors.password = 'Password does not match'
    if (password && confirmPassword) {
      if (password !== confirmPassword)
        newErrors.password = "Password does not match";
    }

    return newErrors;
  };

  // Call Action Login
  const onSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
      //  alert("please fill all details ")
    } else {
      // dispatch(commonLogin(params, history));
      setErrors(newErrors);
      const params = {
        ...formData,
        countryList,
        skillSetList,
        domain,
      };
      // dispatch(CommonRegister(params, history));

      try {
        const res = await post("/register", params);
        if (res.status === 200) {
          if (res.data.status === "Created") {
            toast.success("Created Profile Successfully");
            history.push("/login");
          }
        } else {
          toast.error("Someting went Wrong ");
        }
      } catch (error) {
        toast.error("Something went wrong ");
      }
    }
  };

  // Loader img
  // const Loader = () => <div><img src={LoaderImg} alt="Loader"/></div>;

  const handleChange = (tags) => {
    if (tags)
      setSkillSetList([...skillSetList, { skill: tags[0], proficiency: 1 }]);
  };

  const handleCountryChange = (tags) => {
    if (tags) setCountryList([...countryList, { StateName: tags[0] }]);
  };

  const handleChangeInput = (skillSet) => {
    setSkillSet(skillSet);
  };
  const handleCountryChangeInput = (country) => {
    setCountry(country);
  };

  const changeProficiency = (newProficiency, value, index) => {
    let rating = [...skillSetList];
    rating[index] = { skill: value.skill, proficiency: newProficiency };
    setSkillSetList(rating);
  };

  const deleteCountry = (index) => {
    const result = [...countryList];
    result.splice(index, 1);
    setCountryList(result);
  };
  const deleteSkills = (index) => {
    const result = [...skillSetList];
    result.splice(index, 1);
    setSkillSetList(result);
  };

  useEffect(async () => {
    try {
      setLoading(true);
      let Id = window.location.href.toLowerCase().split("id:")[1];
      if (Id) {
        const uuid = { uuid: Id };
        // check user exists or not
        const result = await post("/uuidValidate/getVettingReqDetails", uuid);

        //  got email and id of canidate
        // need to check candidate permanet id is present or not if present then redirect to login otherwise prepopulate to register page
        if (result.status === 200) {
          //  check with email  permanent user  exists or not
          const data = {
            email: result.data.candidateDetails[0].candidateemail,
            CandidateID: result.data.candidateDetails[0].CandidateID,
          };
          const Validcandidate = await post("/uuidValidate/UserExists", data);
          // const candidateData = JSON.parse(Validcandidate.data.tempcandidate.data)
          if (Validcandidate.data.PermanentCandidate == "NotExists") {
            // populate values in textbox
            const candidateData = JSON.parse(
              Validcandidate.data.tempcandidate.data
            ).value;
            // firstname , lastname  , email ,
            const result = candidateData[0];
            setFormData({
              ...formData,
              firstName: result.fullText.firstName,
              lastName: result.fullText.lastName,
              email: result.email,
            });
          } else {
            //  redirect to login page
            history.push("/login");
          }
        }
      }
      setLoading(false);
    } catch (error) {
      toast.error("Something went wrong ");
    }
  }, []);

  return (
    <Fragment>
      {loading && <Loader />}
      {load && <Loader />}
      <div id="Login" className="login-layout">
        <div className="col-left d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-11 col-lg-11">
                <a className="logo" href="/">
                  <img src={LoginAppLogo} alt="Login logo" />
                </a>
                <div className="section section-sm">
                  <div class="elementor-heading-title elementor-size-default text-danger">
                    Contact Us
                  </div>
                  <h2>
                    We’re excited to meet talented candidates like you{" "}
                    {formData.firstName} !{" "}
                  </h2>
                  <p>Welcome! Register yoruself to get started using High5</p>
                  <p>
                    Finding work you enjoy and a job that fits into your
                    lifestyle can be easier than you may think.&nbsp;
                  </p>
                  <p>
                    If you’re not sure how to start your tech job search, or
                    you’re a talented biochemist looking for a project, or
                    anything in-between…we can help.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-left d-flex align-items-center">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-12 col-lg-12">
                <div className="section section-sm">
                  <h2>Registration</h2>
                  <p>Welcome! Register yoruself to get started using High5</p>
                  <form className="login-form " onSubmit={(e) => onSubmit(e)}>
                    <div class="row g-2">
                      <div className=" col-md form-floating mb-2">
                        <input
                          type="text"
                          className="form-control "
                          name="firstName"
                          aria-describedby="firsthelp"
                          placeholder="Enter first Name"
                          value={firstName}
                          onChange={(e) => onVChange(e)}
                          autoComplete="new-firstName"
                        />
                        <label>First Name*</label>
                        <small className="validation">{errors.firstName}</small>
                      </div>
                      <div className=" col-md      form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          name="lastName"
                          aria-describedby="lasthelp"
                          placeholder="Enter last Name"
                          value={lastName}
                          onChange={(e) => onVChange(e)}
                          autoComplete="new-lastName"
                        />
                        <label>Last Name*</label>
                        <small className="validation">{errors.lastName}</small>
                      </div>
                    </div>
                    <div class="row g-2">
                      <div className="col-md form-floating mb-2">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          aria-describedby="emailHelp"
                          placeholder="Enter email"
                          value={email}
                          onChange={(e) => onVChange(e)}
                          autoComplete="new-email"
                        />
                        <label>Email Address*</label>
                        <small className="validation">{errors.email}</small>
                      </div>
                      <div className="col-md form-floating mb-2">
                        <input
                          type="text"
                          className="form-control"
                          name="LinkedinURL"
                          aria-describedby="linkedhelp"
                          placeholder="Enter URL"
                          value={LinkedinURL}
                          onChange={(e) => onVChange(e)}
                          autoComplete="new-LinkedinURL"
                        />
                        <label>Linkedin Profile URL</label>
                        <small className="validation">
                          {errors.LinkedinURL}
                        </small>
                      </div>
                    </div>
                    <div class="row g-2">
                      <div className="col-md form-floating mb-2">
                        <input
                          type="password"
                          className="form-control"
                          name="password"
                          placeholder="password"
                          value={password}
                          onChange={(e) => onVChange(e)}
                          autoComplete="new-password"
                        />
                        <label>Password</label>
                        <small className="validation">{errors.password}</small>
                      </div>

                      <div className="col-md form-floating mb-2">
                        <input
                          type="password"
                          className="form-control"
                          name="confirmPassword"
                          placeholder="confirm Password"
                          value={confirmPassword}
                          onChange={(e) => onVChange(e)}
                          autoComplete="new-Confirm-password"
                        />
                        <label>Confirm Password</label>
                        <small className="validation">
                          {errors.confirmPassword}
                        </small>
                      </div>
                    </div>
                    <p>
                      we can search jobs better for you if you fill below
                      details
                    </p>
                    <div class="row g-2">
                      <div className=" col-md form-floating mb-2">
                        <div class="w-100 mb-4">
                          <label className="mb-1">Select Your Skills </label>
                          <TagsInput
                            onChange={handleChange}
                            inputValue={skillSet}
                            onChangeInput={handleChangeInput}
                            value={[]}
                            placeholder="Enter a skill and hit enter"
                            inputProps={{ placeholder: "Enter skills" }}
                          />
                        </div>

                        {skillSetList.length != 0 && (
                          <div>
                            <div className="ml-2 d-flex">
                              <div className="">Skill </div>
                              <div className="ml-auto"> Rating</div>
                              <div className="ml-5"> Activity</div>
                            </div>

                            {skillSetList.map((skill, index) => (
                              <div className="ml-2">
                                <p className="d-flex">
                                  <span className="mr-2 mt-1">
                                    {skill.skill}
                                  </span>
                                  <div className="ml-auto">
                                    <StarRatings
                                      rating={skill.proficiency}
                                      starRatedColor="red"
                                      changeRating={(proficiency) =>
                                        changeProficiency(
                                          proficiency,
                                          skill,
                                          index
                                        )
                                      }
                                      numberOfStars={5}
                                      starDimension="20px"
                                      starSpacing="2px"
                                      name="rating"
                                      className="mb-1"
                                    />
                                  </div>

                                  <button
                                    type="button"
                                    class="ml-2 btn btn-icon"
                                    onClick={(e) => deleteSkills(index)}
                                  >
                                    <i
                                      class="fal fa-times"
                                      aria-hidden="true"
                                    ></i>
                                  </button>
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <div className=" col-md form-floating mb-2">
                        <div class="w-100 mb-4">
                          <label className="mb-1">
                            Select Preferred Location{" "}
                          </label>
                          <TagsInput
                            onChange={handleCountryChange}
                            inputValue={country}
                            onChangeInput={handleCountryChangeInput}
                            value={[]}
                            inputProps={{ placeholder: "Enter Location" }}
                          />
                        </div>

                        {countryList.length != 0 && (
                          <div>
                            <div className="ml-2 d-flex">
                              <div className="">Location </div>
                              <div className="ml-auto"> Activity </div>
                            </div>

                            {countryList.map((country, index) => (
                              <div className="ml-2">
                                <p className="d-flex">
                                  <span className="mr-2 mt-1">
                                    {country.StateName}
                                  </span>
                                  <div className="ml-auto">
                                    <button
                                      type="button"
                                      class=" btn btn-icon"
                                      onClick={(e) => deleteCountry(index)}
                                    >
                                      <i
                                        class="fal fa-times"
                                        aria-hidden="true"
                                      ></i>
                                    </button>
                                  </div>
                                </p>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* <p className="text-right mb-4">
                                            <a href="/forgot-password">Forgot Password?</a>
                                        </p> */}
                    <button
                      type="submit"
                      className="btn btn-dark w-100"
                      disabled={loading}
                    >
                      Register
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </Fragment>
  );
}

export default Register;
