import React, { useEffect } from "react";
import { Modal } from "react-bootstrap";

function ReadyToHireQA(props ) {
  const { QandA, setQNA, qna } = props;
  return (
    <Modal size="lg" show={qna}>
      <Modal.Header>
        <h6 class="">Ready To Hire Screening</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setQNA(false)}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
      {QandA?.length > 0 ? (
        <div className="d-flex mb-2 pl-1 ml-4">
          <ul>
            {QandA.map((item, index) => (
              <li className="d-flex m-3">
                <div className="">
                  <span className="col-lg-1">{index + 1}</span>
                </div>
                <div className="ml-3 mb-2">
                  <span className="col-lg-11 mb-1">{item?.question}</span>
                  <br />
                    <small>
                      Answer:{" "}
                      <strong>{item.answer == "" ? "NA" : item.answer}</strong>
                    </small>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="mb-3">
          <div className="text-center p-3">
            <div className="avatar avatar-lg">
              <i className="fad fa-users-slash"></i>
            </div>
            <div className="mt-2">No Screening Questions Added</div>
          </div>
        </div>
      )}
      </Modal.Body>
      <Modal.Footer>
        <button variant="secondary" onClick={() => setQNA(false)}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ReadyToHireQA;
