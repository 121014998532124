import { toast } from "react-toastify";
import { post } from "../../service/ApiService";
export const UPDATE_JOB = "UPDATE_JOB";
export const FETCH_ALL_JOB_BEGIN = "FETCH_ALL_JOB_BEGIN";
export const FETCH_ALL_JOB_SUCCESS = "FETCH_ALL_JOB_SUCCESS";
export const FETCH_ALL_JOB_FAILURE = "FETCH_ALL_JOB_FAILURE";

export const FETCH_FILTERS_BEGIN = "FETCH_FILTERS_BEGIN";
export const FETCH_FILTERS_SUCCESS = "FETCH_FILTERS_SUCCESS";
export const FETCH_FILTERS_FAILURE = "FETCH_FILTERS_FAILURE";

export const FETCH_JOB_TITLE_FILTERS_BEGIN = "FETCH_JOB_TITLE_FILTERS_BEGIN";
export const FETCH_JOB_TITLE_FILTERS_SUCCESS =
  "FETCH_JOB_TITLE_FILTERS_SUCCESS";
export const FETCH_JOB_TITLE_FILTERS_FAILURE =
  "FETCH_JOB_TITLE_FILTERS_FAILURE";

export const FETCH_JOB_ID_FILTERS_BEGIN = "FETCH_JOB_ID_FILTERS_BEGIN";
export const FETCH_JOB_ID_FILTERS_SUCCESS = "FETCH_JOB_ID_FILTERS_SUCCESS";
export const FETCH_JOB_ID_FILTERS_FAILURE = "FETCH_JOB_ID_FILTERS_FAILURE";

export const FETCH_JOB_LOCATION_FILTERS_BEGIN =
  "FETCH_JOB_LOCATION_FILTERS_BEGIN";
export const FETCH_JOB_LOCATION_FILTERS_SUCCESS =
  "FETCH_JOB_LOCATION_FILTERS_SUCCESS";
export const FETCH_JOB_LOCATION_FILTERS_FAILURE =
  "FETCH_JOB_LOCATION_FILTERS_FAILURE";

export const EXISTING_JOB_TITLE_FILTERS_BEGIN =
  "EXISTING_JOB_TITLE_FILTERS_BEGIN";
export const EXISTING_JOB_TITLE_FILTERS_SUCCESS =
  "EXISTING_JOB_TITLE_FILTERS_SUCCESS";
export const EXISTING_JOB_TITLE_FILTERS_FAILURE =
  "EXISTING_JOB_TITLE_FILTERS_FAILURE";

export const EXISTING_JOB_LOCATION_FILTERS_BEGIN =
  "EXISTING_JOB_LOCATION_FILTERS_BEGIN";
export const EXISTING_JOB_LOCATION_FILTERS_SUCCESS =
  "EXISTING_JOB_LOCATION_FILTERS_SUCCESS";
export const EXISTING_JOB_LOCATION_FILTERS_FAILURE =
  "EXISTING_JOB_LOCATION_FILTERS_FAILURE";

export const fetchAllJobs = (params) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_ALL_JOB_BEGIN,
    });
    const res = await post("/broadCast/getJobListHM", params);
    if (res.status === 200) {
      dispatch({
        type: FETCH_ALL_JOB_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_ALL_JOB_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};

export const fetchJobTitleFilters = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_JOB_TITLE_FILTERS_BEGIN,
    });
    const res = await post("/filterData/fetchJobTitlesFilterData", data);
    if (res.status === 200) {
      dispatch({
        type: FETCH_JOB_TITLE_FILTERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_JOB_TITLE_FILTERS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};

// "/fetchJobIdFilterData",

export const fetchJobIdFilterData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_JOB_ID_FILTERS_BEGIN,
    });
    const res = await post("/filterData/fetchJobIdFilterData", data);
    if (res.status === 200) {
      dispatch({
        type: FETCH_JOB_ID_FILTERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_JOB_ID_FILTERS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};
///fetchJobLocationFilterData

export const fetchJobLocationFilterData = (data) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_JOB_LOCATION_FILTERS_BEGIN,
    });
    const res = await post("/filterData/fetchJobLocationFilterData", data);
    if (res.status === 200) {
      dispatch({
        type: FETCH_JOB_LOCATION_FILTERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_JOB_LOCATION_FILTERS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};

export const fetchJobLocationFilterDataExistingJobs = (data) => async (
  dispatch
) => {
  try {
    dispatch({
      type: EXISTING_JOB_LOCATION_FILTERS_BEGIN,
    });
    const res = await post("/filterData/fetchJobLocationFilterData", data);
    if (res.status === 200) {
      dispatch({
        type: EXISTING_JOB_LOCATION_FILTERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXISTING_JOB_LOCATION_FILTERS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};
export const fetchJobTitleFiltersExistingJobs = (data) => async (dispatch) => {
  try {
    dispatch({
      type: EXISTING_JOB_TITLE_FILTERS_BEGIN,
    });
    const res = await post("/filterData/fetchJobTitlesFilterData", data);
    if (res.status === 200) {
      console.log("@40 :", res.data);
      dispatch({
        type: EXISTING_JOB_TITLE_FILTERS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: EXISTING_JOB_TITLE_FILTERS_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};
export const updateJob = (data) => async (dispatch) => {
  dispatch({
    type: UPDATE_JOB,
    payload: data,
  });
};
