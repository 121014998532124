import React, { useState, useEffect, useLayoutEffect, ref } from "react";
import moment from "moment";
import Modal from "react-bootstrap/Modal";
import GigRecSidePanel from "./GigRecSidePanel";
import Loader from "../Loader/Loader";
import GigFilter from "./GigFilter";
import Sort from "../common/Sort";
import OutsideClickHandler from "react-outside-click-handler";
import { CSVLink, CSVDownload } from "react-csv";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import { post } from "../../service/ApiService";
import EditRecruiterMail from "./EditRecruiterMail";

function GigRecruiterList({
  data,
  pageLimit,
  dataLimit,
  fetchGigRecruiters,
  getTenantData,
  fetchEmpDetails,
  onChangeSelect,
  name,
  userType,
  source,
  status,
  country,
  filterData,
  setEmailStatus,
  setEmailData,
  city,
}) {
  const [showEditModal, setShowEditEmailModal] = useState(false);
  const [recruiterData, setRecruiterData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [sidePanel, setSidePanel] = useState(false);
  const [details, setDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [selectedName, setSelectedName] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [filterBy, setFilterBy] = useState(filteredData);
  const [csvData, setCSVData] = useState([
    "Name",
    "Email",
    "UserType",
    "Source",
    "Location",
    "LastLoggedIn",
    "Status",
  ]);

  const sortObject = [
    {
      id: 1,
      label: "Name",
      value: "firstName",
      icon: "fal fa-fw fa-signature  mr-2 dd-sort",
    },
    {
      id: 2,
      label: "User Type",
      value: "companyName",
      icon: "fal fa-fw fa-signature mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Source",
      value: "source",
      icon: "fal fa-fw fa-calendar-alt mr-2 dd-sort",
    },
    {
      id: 4,
      label: "Status",
      value: "status",
      icon: "fal fa-fw fa-signature mr-2 dd-sort",
    },
    {
      id: 5,
      label: "Location",
      value: "country",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  useEffect(() => {
    if (filteredData?.length > 0) {
      searchValue
        ? setPaginationArray(
            new Array(Math.ceil(searchData?.length / dataLimit))
              .fill()
              .map((_, i) => i + 1)
          )
        : setPaginationArray(
            new Array(Math.ceil(filteredData?.length / dataLimit))
              .fill()
              .map((_, i) => i + 1)
          );
      searchValue
        ? setPaginationGroup(
            new Array(Math.ceil(searchData?.length / dataLimit))
              .fill()
              .map((_, i) => i + 1)
              .slice(0, pageLimit)
          )
        : setPaginationGroup(
            new Array(Math.ceil(filteredData?.length / dataLimit))
              .fill()
              .map((_, i) => i + 1)
              .slice(0, pageLimit)
          );
      setCurrentPage(1);
    }
  }, [filteredData, dataLimit, searchData]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    } else {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  useEffect(() => {
    setFilteredData(
      data.map((item) => ({
        ...item,
        userType: item.companyName
          ? "Recruitment Firm"
          : "Individual Recruiter",
      }))
    );
  }, [data]);

  const applySort = (sortedData, close) => {
    close && setSort(false);
    setFilterBy(sortedData);
    setFilteredData(sortedData);
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(filteredData, true);
  };

  const openFilter = () => {
    setFilter(true);
  };

  const applyFilter = (
    data,
    selectedName,
    selectedUserType,
    selectedSource,
    selectedStatus,
    selectedCountry
  ) => {
    sortClearAll();
    setFilter(false);
    setSelectedName(selectedName);
    setSelectedUserType(selectedUserType);
    setSelectedSource(selectedSource);
    setSelectedStatus(selectedStatus);
    setSelectedCountry(selectedCountry);
    setSelectedCity(selectedCountry);
    setFilteredData(data);
    setFilterBy(data);
  };

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const openSidePanel = async (item) => {
    setDetails(item);
    setSidePanel(true);
  };

  const handleSearch = (value) => {
    let val = value?.trim().toLowerCase();
    setSearchValue(val);
    setSearchData(
      filteredData.filter(
        (item) =>
          item.firstName.toLowerCase().includes(val) ||
          item.LastName.toLowerCase().includes(val) ||
          item.email.toLowerCase().includes(val) ||
          item.userType.toLowerCase().includes(val) ||
          (item.firstName + " " + item.LastName).toLowerCase().includes(val)
      )
    );
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : filteredData.slice(startIndex, endIndex);
    //return filteredData
  };
  let gigTabData = [];
  const headers = [
    { label: "Name", key: "name" },
    { label: "Email", key: "email" },
    { label: "User Type", key: "companyName" },
    { label: "Source", key: "source" },
    { label: "Location", key: "country" },
    { label: "Last Logged In", key: "LastLogin" },
    { label: "Status", key: "status" },
  ];

  const newdata = getPaginatedData().map((item) => {
    const dataObj = {
      name: item.firstName + " " + item.LastName,
      email: item.email,
      companyName: item.companyName
        ? "Recruitment Firm"
        : "Individual Recruiter",
      source: item.source === "Invitation" ? "Invite" : "Website",
      country: item.country !== null ? item.country : "No Record",
      LastLogin: item.LastLogin
        ? moment(new Date(item.LastLogin)).format("MM/DD/YYYY")
        : item.status === "Approved"
        ? "Not yet logged in"
        : "Not Approved",
      status: item.status,
    };
    gigTabData.push(dataObj);
  });

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="p-3 mb-2">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <h6 className="m-0">Staffing Partners</h6>
            <div className="d-flex bd-highlight ml-2.5">
              <div className="py-2 align-self-end bd-highlight">
                {getPaginatedData() && getPaginatedData()?.length > 0 && (
                  <CSVLink
                    data={gigTabData}
                    headers={headers}
                    className="btn btn-sm"
                    filename="GigRecruiterListExcel"
                    target="_blank"
                  >
                    <span>
                      <i className="fas fa-file-download mr-2"></i>
                      Download
                    </span>
                  </CSVLink>
                )}
              </div>
              <div class="py-2 align-self-end">
                <div class="dropdown-sort dd-sort">
                  <button
                    type="button"
                    onClick={() => setSort(!sort)}
                    class="btn btn-sm w-auto btn-text-accent dd-sort"
                  >
                    <i
                      aria-hidden="true"
                      class="fad fa-sort-down font-16 dd-sort mr-1"
                    ></i>
                    Sort / <span class="dd-sort">{sortCount}</span>
                  </button>
                  {
                    <OutsideClickHandler onOutsideClick={() => setSort(false)}>
                      {sort && (
                        <Sort
                          sortData={filteredData}
                          orderArray={orderList}
                          sortObjects={sortObject}
                          applySort={applySort}
                          setSortCount={setSortCount}
                          sortCount={sortCount}
                          setSortArray={setSortArray}
                          sortArray={sortArray}
                          sortingFor={
                            "Sort by Name, User Type, Source, Status, Location"
                          }
                        />
                      )}
                    </OutsideClickHandler>
                  }
                </div>
              </div>
              <div class="py-2 align-self-end">
                <div class="dropdown position-relative">
                  <button
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Filter"
                    onClick={openFilter}
                    type="button"
                    class="btn btn-sm btn-text-accent w-auto"
                  >
                    <i class="fas fa-filter"></i>
                    <span>Filter</span> /{" "}
                    <span>
                      {selectedName?.length +
                        selectedUserType?.length +
                        selectedSource?.length +
                        selectedStatus?.length +
                        selectedCity?.length}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div>
              <select
                className="form-select font-14"
                onChange={onChangeSelect}
                defaultValue={10}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="All">All</option>
              </select>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12 col-12">
              <input
                type="text"
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search"
                className="form-control small font-14"
              />
            </div>
          </div>
          <hr></hr>
        </div>
      </div>
      <div className="px-3 pt-0 table-cards-1024 mt-3">
        <table className="table table-hoverable-cells table-hoverabletable-cards-1024 font-14">
          <thead>
            <tr>
              <th>Name</th>
              <th scope="col">Email</th>
              <th scope="col">Email status</th>
              <th scope="col">Source</th>
              <th scope="col">Location</th>
              <th scope="col" className="ml-2">
                Last Logged In
              </th>
              <th scope="col">
                <span className="ml-5">Status</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {getPaginatedData().map((item, index) => (
              <tr key={index}>
                <td onClick={() => openSidePanel(item)}>
                  <Avatar
                    size="35"
                    name={item.firstName + " " + item.LastName}
                    round="80px"
                  />
                  <span className="ml-3">
                    <span className="font-bold d-inline-1024 d-none">
                      Name:
                    </span>
                    <strong
                    // dangerouslySetInnerHTML={{
                    //   __html: splitMatchedText(
                    //     item.firstName + " " + item.LastName
                    //   ),
                    // }}
                    >
                      {item.firstName + " " + item.LastName}
                    </strong>
                  </span>
                </td>
                <td>
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      Email:
                    </span>
                    <span
                    // dangerouslySetInnerHTML={{
                    //     __html: splitMatchedText(
                    //       item.email
                    //     ),
                    //   }}
                    >
                      {item.email}
                    </span>
                    {item.isInvalid && (
                      <>
                        <span className="ml-2">
                          <i
                            data-bs-toggle="tooltip"
                            data-bs-placement="left"
                            title="Invalid email address !"
                            class="fa fa-exclamation-circle"
                            aria-hidden="true"
                            style={{ color: "red" }}
                          ></i>
                        </span>
                        <span
                          className="ml-2"
                          onClick={() => {
                            setRecruiterData(item);
                            setShowEditEmailModal(true);
                          }}
                        >
                          <i
                            class="fa fa-pencil"
                            aria-hidden="true"
                            style={{ color: "grey" }}
                          ></i>
                        </span>
                      </>
                    )}
                  </span>
                </td>
                <td className="no-hover">
                  <div
                    className="d-inline-block"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="View Email Status"
                  >
                    <button
                      type="button"
                      className="btn btn-square btn-gray"
                      onClick={() => {
                        setEmailData(item?.email);
                        setEmailStatus(true);
                        // setSelectedTalent(item);
                      }}
                    >
                      <i class="fa fa-envelope-o" />
                    </button>
                  </div>
                </td>
                {/* <td>
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      User Type:
                    </span>
                    <span
                    // dangerouslySetInnerHTML={{
                    //     __html: splitMatchedText(
                    //       item.userType
                    //     ),
                    //   }}
                    >
                      {item.companyName
                        ? "Recruitment Firm"
                        : "Individual Recruiter"}
                    </span>
                  </span>
                </td> */}
                <td>
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      Source:
                    </span>
                    {item.source === "Invitation" ? "Invite" : "Website"}
                  </span>
                </td>
                <td>
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      Location:
                    </span>
                    {item.country !== "" ? item.country : "No Record"}
                  </span>
                </td>
                <td className="ml-2">
                  <span>
                    <span className="font-bold d-inline-1024 d-none">
                      Last Login Date:{" "}
                    </span>
                    {item.LastLogin
                      ? moment(new Date(item.LastLogin)).format("MM/DD/YYYY")
                      : item.status === "Approved"
                      ? "Not yet logged in"
                      : "Not Approved"}
                  </span>
                </td>
                <td className="text-center">
                  {item.status === "Invited" && (
                    <div
                      style={{
                        marginLeft: "2rem",
                        marginRight: "2rem",
                        width: "50%",
                      }}
                    >
                      <span className="w-100 tag tag-blue3">
                        <span className="mx-auto my-0">
                          <strong>{item.status}</strong>
                        </span>
                      </span>
                    </div>
                  )}
                  {(item.status === "Rejected" ||
                    item.status === "Deactivate") && (
                    <div
                      style={{
                        marginLeft: "2rem",
                        marginRight: "2rem",
                        width: "50%",
                      }}
                    >
                      <span className="w-100 tag tag-red1">
                        <span className="mx-auto my-0">
                          <strong>
                            {item.status === "Deactive"
                              ? "Deactivate"
                              : "Deactivated"}
                          </strong>
                        </span>
                      </span>
                    </div>
                  )}
                  {item.status === "Pending" && (
                    <div
                      style={{
                        marginLeft: "2rem",
                        marginRight: "2rem",
                        width: "50%",
                      }}
                    >
                      <span className="w-100 tag tag-orange1">
                        <span className="mx-auto my-0">
                          <strong>Pending</strong>
                        </span>
                      </span>
                    </div>
                  )}
                  {item.status === "Approved" && (
                    <div
                      style={{
                        marginLeft: "2rem",
                        marginRight: "2rem",
                        width: "50%",
                      }}
                    >
                      <span
                        className={
                          item.LastLogin
                            ? "w-100 tag tag-blue_primary"
                            : "w-100 tag tag-green4"
                        }
                      >
                        <span className="mx-auto my-0">
                          <strong>
                            {item.LastLogin ? "Active" : item.status}
                          </strong>
                        </span>
                      </span>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {getPaginatedData()?.length === 0 && (
        <div>
          <div class="text-center p-3">
            <div class="avatar avatar-lg">
              <i class="fad fa-users-slash"></i>
            </div>
            <div class="mt-2">
              No records found {searchValue && `for '${searchValue}'`}
            </div>
          </div>
        </div>
      )}
      <hr />
      {getPaginatedData()?.length > 0 && (
        <div className="d-flex p-2">
          <div className="w-100-370 mb-point5-370">
            Showing{" "}
            {currentPage === paginationArray.slice(-1)[0]
              ? getPaginatedData()?.length
              : searchValue
              ? searchData?.length < dataLimit
                ? searchData?.length
                : dataLimit
              : dataLimit}{" "}
            of {filteredData?.length > 0 ? filteredData?.length : data?.length}
          </div>
          {getPaginatedData()?.length > 0 && (
            <div class="ml-auto mx-auto-370 d-flex align-items-center">
              <button
                onClick={() => {
                  setPageType("down");
                  setCurrentPage((page) => page - 1);
                }}
                type="button"
                disabled={
                  currentPage === 1 || getPaginatedData()?.length === 0
                    ? true
                    : false
                }
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-left"></i>
              </button>

              {paginationGroup.map((item, index) => (
                <button
                  key={index}
                  type="button"
                  className={
                    currentPage === item
                      ? "btn btn-text px-2 bg-secondary text-white"
                      : "btn btn-text px-2"
                  }
                  onClick={changePage}
                >
                  {item}
                </button>
              ))}

              <button
                onClick={() => {
                  setPageType("up");
                  setCurrentPage((page) => page + 1);
                }}
                type="button"
                disabled={
                  currentPage === paginationArray.slice(-1)[0] ||
                  getPaginatedData()?.length === 0
                    ? true
                    : false
                }
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-right"></i>
              </button>
            </div>
          )}
        </div>
      )}
      {sidePanel && (
        <GigRecSidePanel
          details={details}
          closePanel={() => setSidePanel(false)}
          fetchGigRecruiters={fetchGigRecruiters}
          getTenantData={getTenantData}
          fetchEmpDetails={fetchEmpDetails}
        />
      )}
      {filter && (
        <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
          <GigFilter
            reportData={data}
            selectedName={selectedName}
            candidateName={name}
            selectedUserType={selectedUserType}
            candidateUserType={["Individual Recruiter", "Recruitment Firm"]}
            selectedSource={selectedSource}
            candidateSource={["Invite", "Website"]}
            selectedStatus={selectedStatus}
            candidateStatus={status}
            selectedCountry={selectedCountry}
            candidateCountry={country}
            candidateCity={city}
            selectedCity={selectedCity}
            applyFilter={applyFilter}
          />
        </OutsideClickHandler>
      )}

      <Modal
        size="lg"
        show={showEditModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="bl-modal-header">
          <h6 className="bl-modal-title">{"Update Email"}</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowEditEmailModal(false)}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <EditRecruiterMail
            close={() => setShowEditEmailModal(false)}
            recruiterData={recruiterData}
            fetchGigRecruiters={fetchGigRecruiters}
            setShowEditEmailModal={setShowEditEmailModal}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default GigRecruiterList;
