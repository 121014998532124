import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import UserOverview from "./UserOverview";
import {
  getRecruiteRregistration,
  getRecruiteRregistrationCount,
} from "../../../../../actions/dashboard/RecruiterRegistration";
import Loader from "../../../commoncomponents/Loader/Loader";

const RegistrationOverview = ({ getRecruiters }) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [active, setActive] = useState("Invited");
  const [dataTotal, setDataTotal] = useState(0);
  const [dataSize, setDataSize] = useState(10);
  const [searchData, setSearchData] = useState("");
  const isFirstRender = useRef(true);

  //sort

  const [sortArray, setSortArray] = useState([]);

  useEffect(() => {
    fetchRecruiterData("Invited", 1, 10, "", sortArray);
    const payload = {
      searchText: "",
    };
    dispatch(getRecruiteRregistrationCount(payload));
  }, []);

  const {
    registrationData,
    registrationCount,
    loading,
    countLoading,
  } = useSelector(
    (state) => ({
      registrationData: state.getRecruiterRegistration.recruiterData,
      registrationCount: state.getRecruiterRegistration.registrationCount,
      loading: state.getRecruiterRegistration.loading,
      countLoading: state.getRecruiterRegistration.countLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    setCurrentPage(1);
    if (active == "Invited") {
      registrationCount.length > 0 &&
        setDataTotal(registrationCount[0]["Invited"]);
      setDataSize(10);
    } else if (active == "Approved") {
      registrationCount.length > 0 &&
        setDataTotal(registrationCount[0]["Approved"]);
      setDataSize(10);
    } else {
      registrationCount.length > 0 &&
        setDataTotal(registrationCount[0]["Pending"]);
      setDataSize(10);
    }
  }, [registrationCount, active]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchData.length === 0 || searchData.length >= 2) {
        fetchRecruiterData(active, 1, dataSize, searchData, sortArray);
        const payload = {
          searchText: searchData,
        };
        dispatch(getRecruiteRregistrationCount(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchData]);

  const tabChange = (status) => {
    setCurrentPage(1);
    setActive(status);
    fetchRecruiterData(status, 1, 10, searchData, []);
  };

  const fetchRecruiterData = (tab, pageNumber, limit, searchVal, sortArray) => {
    const payload = {
      status: tab,
      currentPage: pageNumber,
      limit: limit,
      searchText: searchVal,
      sortQuery: sortArray,
    };
    dispatch(getRecruiteRregistration(payload));
  };

  const getCount = (status) => {
    let count = 0;
    if (registrationCount.length > 0) {
      count = registrationCount[0][status];
    }
    return count;
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchRecruiterData(active, pageNumber, dataSize, searchData, sortArray);
  };

  const handleSizeChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
    fetchRecruiterData(active, 1, ds, searchData, sortArray);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
  };

  const applySort = (sortedData) => {
    setCurrentPage(1);
    fetchRecruiterData(active, 1, dataSize, searchData, sortedData);
  };

  return (
    <div>
      {(countLoading || loading) && <Loader />}
      <div className=" card ">
        <div className="card-body  p-4 ">
          <div className="d-flex justify-content-between mb-4 border-bottom">
            <h6>Registration Overview</h6>
          </div>
          <div className="d-flex overflow-auto gap-5">
            <a
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Invited" ? "" : " font-light font-black"
              }`}
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="No. of invited recruiter to join High5Hire and participate in the recruitment process."
              style={{ textAlign: "center" }}
              onClick={(e) => {
                e.preventDefault();
                tabChange("Invited");
              }}
            >
              <div>
                <div>
                  <span className="tag">{getCount("Invited")}</span>
                </div>
              </div>
              <span className="">Invited</span>
            </a>
            <a
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Approved" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Recruiters who have successfully met the criteria set by the High5Hire platform and approved by Marketplace Manager. These recruiters are authorized to actively participate in the recruitment process, including sourcing, screening, and recommending candidates for consideration."
              onClick={(e) => {
                e.preventDefault();
                tabChange("Approved");
              }}
            >
              <div>
                <span className="tag">{getCount("Approved")}</span>
              </div>
              <span className="">Approved</span>
            </a>
            <a
              href="#"
              className={`d-flex flex-column gap-1 ${
                active === "Pending" ? "" : " font-light font-black"
              }`}
              style={{ textAlign: "center" }}
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="Recruiters who have submitted their application via the High5Hire website and are currently awaiting approval from the Marketplace Manager of the High5Hire platform. Their status is under review, and their eligibility is assessed before granting them access to the recruitment pipeline."
              onClick={(e) => {
                e.preventDefault();
                tabChange("Pending");
              }}
            >
              <div>
                <span className="tag">{getCount("Pending")}</span>
              </div>
              <span className="">Pending Approval</span>{" "}
            </a>
          </div>
        </div>
      </div>
      <UserOverview
        isFrom={"RegistrationOverview"}
        userData={registrationData}
        prefix={active}
        getRecruiters={getRecruiters}
        handlePageChange={handlePageChange}
        currentPage={currentPage}
        handleSizeChange={handleSizeChange}
        dataTotal={dataTotal}
        dataSize={dataSize}
        handleSearch={handleSearch}
        searchData={searchData}
        fetchRecruiterData={fetchRecruiterData}
        sortArray={sortArray}
        setSortArray={setSortArray}
        applySort={applySort}
      />
    </div>
  );
};

export default RegistrationOverview;
