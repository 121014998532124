import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import {
  getUserId,
  getUserTenant,
  getUserRole,
  getCandidateName,
} from "../../../../service/AuthService";
import Avatar from "react-avatar";
import ScheduleInterview from "../../../modal/interview/ScheduleInterview";
import { apiDomain, domain } from "../../../../constants/Constants";
import Loader from "../../../Loader/Loader";

function ShortListPopup(props) {
  // const [interviewType, setinterviewType] = useState('')
  const [interviewer, setinterviewer] = useState("");
  const [loading, setLoading] = useState(false);
  const [parent, setParent] = useState("");
  const [change, setchange] = useState(false);
  const [checkedFn, setcheckedFn] = useState(true);
  const [interviewDd, setInterviewTypeDd] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [commentText, setCommentText] = useState("");
  const userTenant = getUserTenant();
  const userName = getCandidateName();
  const [talentData, setTalentData] = useState({});
  const [jobData, setJobData] = useState({});
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [errors, setErrors] = useState({});

  const [interviewScheduleData, setInterviewScheduleData] = useState({
    interviewType: "",
    platform: "",
    assessment: "",
    templateReqNumber: "",
    reqTitle: "",
    MontageRequisitionId: "",
    interviewer: "",
    firstName: "",
    lastName: "",
    email: "",
    duration: "",
    candidateID: "",
    monjinJobId: "",
    jobTitle: "",
    assignCandidateId: "",
    Title: "",
    candidateTenant: "",
    candidateid: "",
    course: "",
    Interview: "",
    tovutiCourseTitle: "",
    tovutiCourseId: "",
    tovutiCourseUrl: "",
    monjinJobId: "",
    jobId: "",
    monjinJobTitle: "",
    courseId: "",
    createdBy: "",
    tovutiListNot: "",
    assignEmail: "",
    codeSignalCourseId: "",
    codeSignalCourseTitle: "",
    vettingtestID: "",
    vettingTestName: "",
    vettingExpiredDays: "",
    monjinTwowayData: {
      interviewerfirstName: "",
      interviewerlastName: "",
      intervieweremail: "",
      interviewdate: "",
      interviewduration: "",
      selectedTimezone: {},
    },
    TwowayData: {
      interviewerfirstName: "",
      interviewerlastName: "",
      intervieweremail: "",
    },
  });

  useEffect(() => {
    setTalentData(props.selectCandidate);
  }, [props]);

  const close = () => {
    props.handleSuccess();
  };

  const handleInterviewTypeDd = (e) => {
    if (e.target.checked) {
      setIsChecked(true);
      setInterviewTypeDd(false);
    } else {
      setIsChecked(false);
      setInterviewTypeDd(true);
    }
  };
  const tenant = userTenant;
  const jobId = jobData.jobId;
  const jobTitle = jobData.jobTitle;
  const jobTenant = jobData.jobTenant;
  const candidateFname = talentData.candidateFname;
  const candidateLname = talentData.candidateLname;
  const candidateEmail = talentData.candidateEmail;
  const candidateTenant = talentData.candidateTenant;
  const role = getUserRole();
  const MailOfferAPI = async (e) => {
    const params = {
      tenant,
      jobId,
      jobTitle,
      candidateFname,
      candidateLname,
      candidateEmail,
      vettingAssignment: isChecked,
      jobTenant: jobTenant,
      candidateTenant: candidateTenant,
    };
    try {
      const res = await post("/makeanoffer/getdataforshortlistmail", params);
      props.refreshPage();
      if (res.status === 200) {
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const submitShortList = async (e) => {
    const payload = {
      vettingAssignment: isChecked,
      jobId: jobData.jobId,
      interviewComments: commentText,
      candidateId: talentData.candidateID,
      shortlistedRole: role,
      shortlistedBy: userName,
    };
    
    try {
      setLoading(true);
      const shortListData = await post(
        "/jobcandidate/updateshortlistedstatus",
        payload
      );
      setLoading(false);
      if (shortListData.status === 200) {
        toast.success("Candidate Shortlisted successfully");
        if (props.isFrom === "candidateProfile") props.handleClose();
        // props.setSuccessFormOpen(true)
      }
      props.refreshPage();
      props.handleSuccess();
    } catch (error) {
      toast.error("An error occurred");
      close();
    }
    const tenant = { tenant: props.userTenant };
    MailOfferAPI(tenant);
  };

  useEffect(() => {
    setJobData(props.jobData);
    // email , firstname
    const talent = props.selectCandidate;
    // props.talentdata[0].candidateFname
    // props.talentdata[0].candidateLname
    setInterviewScheduleData({
      ...interviewScheduleData,
      firstName: talent.candidateFname,
      lastName: talent.candidateLname,
      email: talent.candidateEmail,
      candidateID: talentData.candidateId,
      createdBy: getUserId(),
      candidateTenant: talent.candidateTenant,
    });
    setParent(props.parent);
  }, [props]);

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="bl-modal-body ">
          <div>
            <div className="text-center">
              {/* <img className="avatar avatar-lg" src="https://randomuser.me/api/portraits/women/75.jpg" alt='' /> */}
              <Avatar
                size="55"
                name={
                  talentData.candidateFname + " " + talentData.candidateLname
                }
                round="80px"
              />
            </div>
          </div>
          <div>
            <div className="text-center">
              <h6 className="mt-3">
                {talentData?.candidateFname} {talentData?.candidateLname}
              </h6>
              <p>
                {" "}
                {jobData?.isRemote ? "Remote" : jobData?.location?.city} /{" "}
                {jobData?.jobTenant} /{" "}
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: jobData?.placementCurrency
                    ? `${jobData?.placementCurrency}`
                    : "USD",
                  maximumSignificantDigits: 3,
                }).format(jobData?.maximumPay)}
              </p>
            </div>
          </div>

          {props?.vettingStatus?.length > 0 && (
            <div className="text-center" style={{ padding: " 1rem 1.5rem" }}>
              <table style={{ width: "100%" }}>
                <tr style={{ background: "lightgray" }}>
                  <th>Interview Type</th>
                  <th>Name</th>
                  <th>Duration</th>
                  <th>Status</th>
                  <th>Assigned by</th>
                </tr>
                {props.vettingStatus?.map((vetting) => (
                  <tr style={{ background: "aliceblue" }}>
                    <td>{vetting?.testAssign?.testCategory}</td>
                    <td>{vetting?.testAssign?.testName}</td>
                    <td>{vetting?.testAssign?.details?.duration} mins</td>
                    <td>
                      {vetting?.testAssign?.testCategory !== "MCQ"
                        ? vetting?.isReviewed
                          ? "Completed"
                          : vetting?.testCompleted
                          ? "Under Review"
                          : "Invited"
                        : vetting?.testCompleted
                        ? "Completed"
                        : "Invited"}
                    </td>
                    <td>{vetting?.companyInfo?.companyName}</td>
                  </tr>
                ))}
              </table>
            </div>
          )}
          <hr></hr>

          {/* <div v-if="!isInterviewReschedule">
            {parent === "Shortlist" && (
              <p className="text-center">
                {" "}
                Please complete the form below to setup your interview.
              </p>
            )}
          </div> */}

          {(parent === "Submitted" || parent === "Disqualified") && (
            <div>
              <div className="card-body">
                <div className="d-flex align-items-center justify-content-between ">
                  <div>
                    <label className="p-1 pl-2">
                      {/* Skip Interview and Shortlist Candidate */}
                      Do you want to schedule the interview ?
                    </label>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      onChange={handleInterviewTypeDd}
                      // style={{ width: "20px" }}
                      checked={isChecked}
                    />
                  </div>
                </div>
                <div>
                  {!isChecked ? (
                    <div className="form-floating mt-3">
                      <textarea
                        className="form-control"
                        maxLength={250}
                        placeholder="Leave a comment here"
                        id="floatingTextarea2"
                        style={{ height: "100px" }}
                        value={commentText}
                        onChange={(e) => setCommentText(e.target.value)}
                      ></textarea>
                      <label for="floatingTextarea2">Enter Comments</label>
                    </div>
                  ) : (
                    <div className="card card-flat card-borderless ">
                      <ScheduleInterview
                        candidateData={props?.talentdata}
                        jobData={jobData}
                        handleSuccess={props?.handleSuccess}
                        interviewScheduleData={interviewScheduleData}
                        setInterviewScheduleData={setInterviewScheduleData}
                        setErrors={setErrors}
                        parent={parent}
                        submitShortList={submitShortList}
                        refreshPage={props?.refreshPage}
                        candidateID={talentData.candidateId}
                        high5hireCandidateId={props?.talentdata[0]?.candidateID}
                      />
                    </div>
                  )}
                </div>
              </div>

              {!isChecked && (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    data-dismiss="modal"
                    onClick={close}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary btn-sm"
                    onClick={submitShortList}
                    disabled={commentText.length === 0}
                  >
                    Shortlist
                  </button>
                </div>
              )}
            </div>
          )}
          {parent === "Shortlist" && (
            <div>
              <div className="card-body">
                <ScheduleInterview
                  candidateData={props?.talentdata}
                  jobData={jobData}
                  handleSuccess={props?.handleSuccess}
                  interviewScheduleData={interviewScheduleData}
                  setInterviewScheduleData={setInterviewScheduleData}
                  setErrors={setErrors}
                  parent={parent}
                  submitShortList={submitShortList}
                  refreshPage={props?.refreshPage}
                  candidateID={talentData?.candidateId}
                  high5hireCandidateId={props?.talentdata[0]?.candidateID}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ShortListPopup;
