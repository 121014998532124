import {
  FETCH_ALL_TAGS_BEGIN,
  FETCH_ALL_TAGS_SUCCESS,
  FETCH_ALL_TAGS_FAILURE,
} from "../../actions/tagmanagement/adminFetchAllTags";

const initialState = {
  tagLoading: true,
  tagsData: null,
};

const adminTagReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ALL_TAGS_BEGIN:
      return {
        ...state,
        tagLoading: true,
        tagsData: null,
      };
    case FETCH_ALL_TAGS_SUCCESS:
      return {
        ...state,
        tagsData: payload,
        tagLoading: false,
      };
    case FETCH_ALL_TAGS_FAILURE:
      return {
        ...state,
        tagsData: payload,
        tagLoading: false,
      };
    default:
      return state;
  }
};

export default adminTagReducer;
