import React, { useState } from "react";
import sparkles from "../../../assets/lottie/sparkles.json";
import checkMark from "../../../assets/lottie/checkmark.json";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";
import moment from "moment";
const { Anime } = ReactAnime;

function AddJobSuccess(props) {
  const [loop] = useState(false);

  const closeAddJobSucces = () => {
    props.closeModal(false);
  };

  console.log(props);

  return (
    <>
      <div class="section section-sm pb-0">
        <div class="success-animation">
          <div class="checkmark">
            <Lottie loop={loop} animationData={checkMark} play></Lottie>
          </div>
          <Anime
            initial={[
              {
                targets: "#LottieSparkles",
                opacity: 0,
                delay: 1000,
              },
            ]}
          >
            <div class="sparkles">
              <Lottie
                loop={loop}
                animationData={sparkles}
                id="LottieSparkles"
                play
              ></Lottie>
            </div>
          </Anime>
        </div>
        <Anime
          initial={[
            {
              targets: ".success-message",
              opacity: 1,
              delay: 800,
              easing: "linear",
            },
          ]}
        >
          <div className="success-message" style={{ opacity: "0" }}>
            <div className="container">
              <div className="col-lg-12">
                <div className="col-lg-12">
                  <h4 className="text-center">Job Created</h4>
                  <p className="text-center">
                    Your
                    <br />
                    <strong>{props.successData.title}</strong> <br />
                    Job has been created
                  </p>
                  <div className="card card-flat card-lg">
                    <div className="card-body">
                      <div className="mb-2">
                        <label className="d-block font-bold pt-0">
                          Number of Openings
                        </label>
                        {props.successData.position}
                      </div>
                      <div className="mb-2">
                        <label className="d-block font-bold pt-0">
                          Start Date
                        </label>
                        {moment(props.successData.startDate).format(
                          "MM/DD/YYYY"
                        )}
                      </div>
                      <div className="mb-2">
                        <label className="d-block font-bold pt-0">
                          Location
                        </label>
                        {props.successData.isRemote ? (
                          <span>Remote</span>
                        ) : (
                          <span>
                            {props?.successData?.city},{" "}
                            {props?.successData?.state},{" "}
                            {props?.successData?.country}
                          </span>
                        )}
                      </div>
                      <div className="mb-2">
                        <label className="d-block font-bold pt-0">
                          {props?.successData?.jobType === "Full Time"
                            ? "Maximum Annual Salary"
                            : "Maximum Hourly Salary"}
                        </label>
                        {props?.successData?.salary}
                        {/* {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency:  "USD",
                          maximumSignificantDigits: 3,
                        }).format(props?.successData?.salary)} */}
                      </div>
                      {props?.successData?.skills && (
                        <div className="mb-2">
                          <label className="d-block font-bold pt-0">
                            Required Skills
                          </label>
                          <div className="mt-1">
                            <span className="tag tag-blue3">
                              {props?.successData?.skills?.join(", ")}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="text-center my-4">
                    <button
                      type="button"
                      className="btn btn-secondary mr-2"
                      data-dismiss="modal"
                      onClick={closeAddJobSucces}
                    >
                      Exit
                    </button>
                    <a
                      href={`/high5Hire/requests/${props?.successData?.jobId}`}
                      className="btn btn-primary"
                    >
                      View Job
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Anime>
      </div>
    </>
  );
}

export default AddJobSuccess;
