import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { get, post } from "../../../../../../service/ApiService";
import Avatar from "react-avatar";
import Loader from "../../../../../Loader/Loader";
import imageLoader from "../../../../../../images/button-loader.gif";
import {
  getUserRole,
  getUserName,
  getCandidateName,
  getTenantType,
} from "../../../../../../service/AuthService";
import { emailDomain } from "../../../../../../constants/Constants";
import axios from "axios";
const DisqualifyPopUp = (props) => {
  const {
    disqualifyPopup,
    setDisqualifyPopup,
    disqualifyData,
    jobData,
    reloadDisposition,
  } = props;
  const [loading, setLoading] = useState(false);
  const [dropDown, setDropdown] = useState([]);
  const [commentText, setCommentText] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [CreatedBY, setCreatedBY] = useState("");
  const tenantType = getTenantType();
  useEffect(() => {
    GetDropdownList();
  }, []);

  let getCreatedBy = async () => {
    try {
      await post("/msgtwoway/getrecruiterId", {
        recruiterId: disqualifyData.recruiterId,
      }).then((res) => {
        let Iddd = Number(res.data[0].FK_useraccessdetails);
        setCreatedBY(Iddd);
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCreatedBy();
  }, []);
  const GetDropdownList = async () => {
    setLoading(true);
    try {
      const url =
        props.isFrom === "invite"
          ? "/disposition/getInviteDisqualifyreasonlist"
          : "/disposition/getdisqualifyreasonlist";
      const res = await get(url);
      if (res.status === 200) {
        const result = res.data;
        setDropdown(result);
      }
    } catch (error) {
      toast.error("Error Occured While fetching dropdown list");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setSelectedValue(value);
  };

  const sendSmsforDisqualification = async () => {
    let name = disqualifyData?.firstName;
    let jobName = jobData?.jobTitle;
    let jobId = jobData.jobId;
    let tenant = {
      tenant: disqualifyData?.fk_tenant,
      createdBy: CreatedBY,
      // tenant: JSON.parse(localStorage.getItem("userToken"))?.userTenant,
    };
    setLoading(true);
    // Function to make a request to the URL shortening API
    async function shortenUrl(longUrl) {
      const headers = {
        Accept: "application/json",
        Authorization:
          "Bearer WJwUfBqQhE8ORpWMhoyae6IlvSPq2eIc3rS21kjth1Q8tY4yi3hkY9Zfvpqv",
        "Content-Type": "application/json",
      };
      const requestBody = {
        url: longUrl,
        domain: "high5hire.live",
      };

      try {
        const response = await axios.post(
          "https://api.tinyurl.com/create",
          requestBody,
          { headers }
        );
        if (
          response.data &&
          response.data.data &&
          response.data.data.tiny_url
        ) {
          const shortenedUrl = response.data.data.tiny_url;
          return shortenedUrl;
        } else {
          return "";
        }
      } catch (error) {
        console.error("Error shortening URL:", error);
      }
    }
    const numbers = await post("/msgtwoway/getAllNumbers", tenant);
    const link = `${emailDomain}/high5Hire/requests/${jobId}`;
    const shortenedUrl = await shortenUrl(link);
    const data = numbers.data?.filter((x) => x.fk_Role !== 4);
    if (data.length === 0) return;
    const recepientsDataSms = [];
    const recepientsDataWhatsApp = [];
    await data.map((obj) => {
      const recepientDataSms = [];
      const recepientDataWhatsApp = [];

      let home = obj.homePhone ? obj.homePhone : "";
      let mobile = obj.mobilePhone ? obj.mobilePhone : "";
      let work = obj.workPhone ? obj.workPhone : "";

      if (home.charAt(0) != "+") home = `+${obj.homePhone}`;
      if (mobile.charAt(0) != "+") mobile = `+${obj.mobilePhone}`;
      if (work.charAt(0) != "+") work = `+${obj.workPhone}`;

      if (home.length > 10)
        if (home.charAt(0) == "+" && home.charAt(1) != "1")
          recepientDataWhatsApp.push(home.replaceAll(" ", "")?.substring(1));
      if (mobile.length > 10)
        if (mobile.charAt(0) == "+" && mobile.charAt(1) != "1")
          recepientDataWhatsApp.push(mobile.replaceAll(" ", "")?.substring(1));
      if (work.length > 10)
        if (work.charAt(0) == "+" && work.charAt(1) != "1")
          recepientDataWhatsApp.push(work.replaceAll(" ", "")?.substring(1));

      if (home.charAt(0) == "+" && home.charAt(1) == "1")
        recepientDataSms.push(home.replaceAll(" ", ""));
      if (mobile.charAt(0) == "+" && mobile.charAt(1) == "1")
        recepientDataSms.push(mobile.replaceAll(" ", ""));
      if (work.charAt(0) == "+" && work.charAt(1) == "1")
        recepientDataSms.push(work.replaceAll(" ", ""));

      if (recepientDataSms.length > 0)
        recepientsDataSms.push({
          recieverNumber: recepientDataSms,
          userId: Math.floor(Math.random() * 1000000),
          message: `Hi ${obj.firstName}, Just letting you know ${name} has been disqualified for the ${jobName} role.\nClick below to see details.\n${shortenedUrl}\nThe High5 Team `,
        });

      if (recepientDataWhatsApp.length > 0)
        recepientsDataWhatsApp.push({
          recieverNumber: recepientDataWhatsApp,
          message: `Hi ${obj.firstName}, Just letting you know ${name} has been disqualified for the ${jobName} role.\nClick below to see details.\n${emailDomain}/high5Hire/requests/${jobId}\nThe High5 Team `,
        });
    });

    try {
      if (recepientsDataSms.length > 0)
        await post("/msgtwoway/telSms", recepientsDataSms);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("An error occurred");
    }
    try {
      if (recepientsDataWhatsApp.length > 0)
        await post("/massages/sentMassageWhatsapp", recepientsDataWhatsApp);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("An error occurred");
    }
  };

  const handleDisqualify = async () => {
    setLoading(true);
    try {
      const payload = {
        status: 12,
        id_disposition: disqualifyData.id_candidateTalentPool,
        disqualifyComments: selectedValue,
        comments: commentText,
        jobId: jobData.jobId || jobData.poolId,
        jobTitle: jobData.jobTitle,
        candidateEmail: disqualifyData?.email,
        candidateFname: disqualifyData?.firstName,
        candidateLname: disqualifyData?.lastName,
        recruiterEmail: disqualifyData.recruiterEmail,
        recruiterId: disqualifyData.recruiterId,
        recruiterName: disqualifyData.recruiterName,
        tenantname: disqualifyData.tenantName,
        userName: getCandidateName(),
        tenantType: tenantType,
        createdBy: disqualifyData.createdBy,
        withdraw:
          props.isFrom == "invite" || props.isFrom == "automatch"
            ? true
            : false,
      };

      const res = await post("/disposition/poolupdatedisposition", payload);
      if (res.status === 200) {
        // setLoading(false);
        await sendSmsforDisqualification();
        toast.success(
          `Candidate ${
            props.isFrom == "invite" || props.isFrom == "automatch"
              ? "Withdrawn"
              : "Disqualified"
          } Successfully!!!`
        );
        if (props.isFrom == "invite" || props.isFrom == "automatch") {
          props.setCandidateProfile(false);
        }
        reloadDisposition();
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        size="lg"
        show={disqualifyPopup}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="interviewPopUpModal"
      >
        <Modal.Body>
          <div className="bl-modal-header">
            <h6 className="bl-modal-title">
              {disqualifyData.status == 32 ||
              disqualifyData.dispositionStatus == 38
                ? "Withdraw Candidate "
                : "Disqualify Candidate"}
            </h6>
            <button
              type="button"
              onClick={() => setDisqualifyPopup(false)}
              className="close p-0 bl-modal-close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <div className="text-center">
              {
                <Avatar
                  size="35"
                  name={
                    disqualifyData?.firstName + " " + disqualifyData?.lastName
                  }
                  round="80px"
                />
              }
            </div>
          </div>
          <div>
            <div className="text-center">
              <h6 className="mt-3">
                {disqualifyData.firstName} {disqualifyData.lastName}
              </h6>
              <p>
                <span className="mb-1 mr-1">
                  {jobData?.isCountry
                    ? jobData?.country
                    : jobData?.city + ", " + jobData?.country}
                </span>
              </p>
            </div>
          </div>

          <div style={{ padding: "1px 2px 35px 0px" }}></div>

          <div>
            <div className="bl-modal-body pt-0">
              <div className="text-right mb-2"> *Denotes mandatory field</div>
              <div className="form-floating">
                <select
                  onChange={handleChange}
                  placeholder={
                    props.isFrom == "invite" || props.isFrom == "automatch"
                      ? "Please provide the reason for withdrawal*"
                      : "Please provide the reason for disqualification*"
                  }
                >
                  <option value={""} selected>
                    {props.isFrom == "invite" || props.isFrom == "automatch"
                      ? "Please provide the reason for withdrawal*"
                      : "Please provide the reason for disqualification*"}
                  </option>
                  {dropDown.map((data) => (
                    <option
                      key={data.Id}
                      value={data.ReasonForDisqualification}
                    >
                      {data.ReasonForDisqualification}
                    </option>
                  ))}
                </select>
              </div>
              <div className="form-floating mt-3">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  maxLength={250}
                  id="floatingTextarea2"
                  style={{ height: "100px" }}
                  value={commentText}
                  onChange={(e) => setCommentText(e.target.value)}
                />
                <label htmlFor="floatingTextarea2">
                  Additional comments or feedback for candidate
                </label>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                onClick={() => setDisqualifyPopup(false)}
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                disabled={!selectedValue && !commentText}
                className="btn btn-primary btn-sm"
                onClick={handleDisqualify}
              >
                <span>
                  {props.isFrom == "invite" || props.isFrom == "automatch"
                    ? "Withdraw"
                    : "Disqualify"}
                </span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DisqualifyPopUp;
