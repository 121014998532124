import React, { useEffect } from "react";

function QandAResponse({ QandA }) {
  return (
    <>
      {QandA.length > 0 ? (
        <div className="d-flex mb-2 pl-1 ml-4">
          <ul>
            {QandA.map((item, index) => (
              <li className="d-flex m-3">
                <div className="">
                  <span className="col-lg-1">{index + 1}</span>
                </div>

                <div className="ml-3 mb-2">
                  <span className="col-lg-11 mb-1">{item.question}</span>
                  <br />

                  {item?.input === "Numeric" &&
                    item.name !== "Custom Question" && (
                      <small>
                        Answer:{" "}
                        <strong>
                          {item.answer == ""
                            ? "NA"
                            : item.answer == 1
                            ? `${item.answer} year`
                            : `${item.answer} years`}
                        </strong>
                      </small>
                    )}
                  {item?.input === "Numeric" && item.name == "Custom Question" && (
                    <small>
                      Answer:{" "}
                      <strong>{item.answer == "" ? "NA" : item.answer}</strong>
                    </small>
                  )}
                  {!item?.input && (
                    <small>
                      Answer:{" "}
                      <strong>{item.answer == "" ? "NA" : item.answer}</strong>
                    </small>
                  )}
                  {item?.name === "Industry Experience" && (
                    <small>
                      Industry: <strong className="mr-1"> {item.input} </strong>
                      <br />
                      Answer:{" "}
                      <strong>
                        {item.answer == ""
                          ? "NA"
                          : item.answer >= 1
                          ? `${item.answer} years`
                          : `${item.answer} year`}
                      </strong>
                    </small>
                  )}
                  {item?.name === "Education" && Array.isArray(item?.answer) && (
                    <small>
                      Answer:{" "}
                      <strong>
                        {Array.isArray(item?.answer)
                          ? item?.answer?.length > 0
                            ? item?.answer
                                ?.map((value) => {
                                  return value === "" ? "NA" : value;
                                })
                                .join(", ")
                            : "NA"
                          : item?.answer}
                      </strong>
                    </small>
                  )}
                  {item?.name === "Certification" && (
                    <small>
                      Answer:{" "}
                      <strong>
                        {item?.answer?.filter((item) => item !== "Other")
                          .length > 0
                          ? item?.answer
                              ?.filter((item) => item !== "Other")
                              ?.join(", ")
                          : "NA"}
                      </strong>
                      {item?.other?.filter((value) => value !== "")?.length >
                        0 && (
                        <>
                          <br />
                          Other:{" "}
                          <strong>
                            {item?.other?.length > 0
                              ? item?.other?.join(", ")
                              : "NA"}
                          </strong>
                        </>
                      )}
                    </small>
                  )}
                  {(item?.name === "Shift Preference" ||
                    item?.name === "Preferred Location" ||
                    item.name === "Work Preference" ||
                    item.name === "Willing to relocate" ||
                    item.name === "Nursing License" ||
                    item.name === "Nursing Specialty" ||
                    item?.name === "License State") &&
                    Array.isArray(item.input) && (
                      <small>
                        Answer:{" "}
                        <strong>
                          {item?.answer.length > 0
                            ? item?.answer?.join(", ")
                            : "NA"}
                        </strong>
                      </small>
                    )}
                  {(item.input == "Drop-down" ||
                    item.input == "Drop-down(Multiselect)") && (
                    <small>
                      Answer:{" "}
                      <strong>
                        {item?.answer?.length > 0
                          ? item?.answer?.join(", ")
                          : "NA"}
                      </strong>
                    </small>
                  )}
                  {item?.name === "Visa Requirement" && item.answer !== "" && (
                    <div>
                      {item?.answer.map((item, key) => (
                        <div key={key} value={key}>
                          <small>
                            <strong>{item}</strong>
                          </small>
                        </div>
                      ))}
                    </div>
                  )}
                  {item?.input === "Yes/No" && (
                    <small>
                      Answer:{" "}
                      <strong>
                        {item.answer
                          ? item.answer == ""
                            ? "NA"
                            : item.answer
                          : "NA"}
                      </strong>
                    </small>
                  )}
                  {item?.input === "Text" && (
                    <small>
                      Answer:{" "}
                      <strong>{item.answer == "" ? "NA" : item.answer}</strong>
                    </small>
                  )}
                  {item?.name === "Custom Question" &&
                    item?.input === "Long Text" && (
                      <small>
                        Answer:{" "}
                        <strong>
                          {item.answer == "" ? "NA" : item.answer}
                        </strong>
                      </small>
                    )}

                  {item?.name === "Language" &&
                    item.answer?.map((item, index) => {
                      const [language, proficiencyLevel] = Object.entries(
                        item
                      )[0];
                      return (
                        <small key={index}>
                          <strong className="mr-1">{language}</strong> Answer:{" "}
                          <strong>
                            {proficiencyLevel == "" ? "NA" : proficiencyLevel}
                          </strong>
                          <br />
                        </small>
                      );
                    })}
                  <div></div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div className="mb-3">
          <div className="text-center p-3">
            <div className="avatar avatar-lg">
              <i className="fad fa-users-slash"></i>
            </div>
            <div className="mt-2">No Screening Questions Added</div>
          </div>
        </div>
      )}
    </>
  );
}

export default QandAResponse;
