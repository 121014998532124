import {
  GET_COMMON_DROPDOWN_BEGIN,
  GET_COMMON_DROPDOWN_SUCCESS,
  GET_COMMON_DROPDOWN_FAILURE,
} from "../../actions/common/GetDropdownValues";

const initialState = {
  dropdownLoading: true,
  dropdownData: [],
};

const dropdownValuesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_COMMON_DROPDOWN_BEGIN:
      return {
        ...state,
        dropdownLoading: true,
      };
    case GET_COMMON_DROPDOWN_SUCCESS:
      return {
        ...state,
        dropdownData: payload,
        dropdownLoading: false,
      };
    case GET_COMMON_DROPDOWN_FAILURE:
      return {
        ...state,
        dropdownLoading: false,
      };
    default:
      return state;
  }
};

export default dropdownValuesReducer;
