import React, { useState } from "react";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";
import { post } from "../../../../service/ApiService";
import {
  getUserEmail,
  getCandidateName,
  getUserTenant,
} from "../../../../service/AuthService";

const DeactivateEmployee = (props) => {
  const { employeeData, close } = props;
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    const email = getUserEmail();
    const clientName = getCandidateName();
    const tenantName = getUserTenant();
    const userid = employeeData.userId;
    const id_user = employeeData.id_useraccessdetail;
    const designation = employeeData.designation;
    const role = employeeData.role;
    const name = employeeData.name;
    const empEmail = employeeData.email;
    const paramters = {
      userid,
      id_user,
      email,
      clientName,
      tenantName,
      designation,
      role,
      name,
      empEmail,
    };
    const res = await post("/employee/deactivateemployee", paramters);
    if (res.status === 200) {
      setLoading(false);
      const empdata = {
        name: employeeData.firstName + " " + employeeData.lastName,
        email: employeeData.email,
        designation: employeeData.role,
      };
      props.submitfun(empdata);
    } else {
      setLoading(false);
      toast.error("Error occured while updating record");
    }
  };
  return (
    <>
      {loading && <Loader />}
      <div>
        {props.employeeDelete !== "employeedelete" ? (
          <div className="bl-modal-body  text-center">
            <div className="avatar avatar-lg avatar-red2 mr-3">
              <i className="fas fa-exclamation-triangle fa-fw"></i>
            </div>
            <p className="text-center lead mt-3">
              Do you want to deactivate this User?
            </p>
            <div className="text-center my-4">
              <button
                type="button"
                className="btn btn-secondary mr-3"
                data-dismiss="modal"
                onClick={close}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Confirm Deactivate
              </button>
            </div>
          </div>
        ) : (
          <div className="bl-modal-body">
            <div className="text-center">
              <div className="avatar avatar-lg avatar-red2">
                <i
                  className="fas fa-exclamation-triangle fa-fw"
                  aria-hidden="true"
                ></i>
              </div>
              <p className="text-center lead mt-3">
                Do you want to deactivate this user?
              </p>

              <p>Name: {employeeData.name}</p>
            </div>
            <div className="text-center my-4">
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-secondary mr-2"
                onClick={close}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleSubmit}
              >
                <span>Deactivate</span>
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DeactivateEmployee;
