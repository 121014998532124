import React, { useEffect, useState } from "react";
import { get } from "../../../service/ApiService";
import CandidateInterviewDetails from "../../candidate/interviews/CandidateInterviewDetails";

function CandidateInterviews(props) {
  const [interviewDetails, setInterviewDetails] = useState(false);
  const [interview, setInterview] = useState([]);
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(false);

  const openInterviewDetails = (item) => {
    setInterviewDetails(true);
    setInterview(item);
  };

  const closePopUP = () => {
    setInterviewDetails(false);
  };

  useEffect(() => {
    fetchInterviews();
  }, []);

  const fetchInterviews = async () => {
    setLoading(true);
    try {
      const res = await get(`/CandidateDashboard/getModernHireInterviews`);
      setInterviews(res.data.filter((item) => item.Status !== "Completed"));
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <div id="CandidateInterviews" className="card mb-2">
        <div className="card-body">
          <div className="mb-2 mt-1">
            <label className="pt-0 mb-2">
              <strong>Upcoming Interviews</strong>
            </label>
            <a href="/high5Hire/candidateinterviews">
              <i className="fas fa-external-link-square ml-1"></i>
            </a>
          </div>
          {loading ? (
            <h6 className="m-5">Fetching Interviews...</h6>
          ) : interviews.length !== 0 ? (
            interviews.map((item) => (
              <a
                onClick={(e) => {
                  e.preventDefault();
                  window.open(item.SchedulingUrl);
                }}
                className="card card-flat w-100 mb-1"
              >
                <div className="card-body">
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="mb-1">
                        <strong>{item.reqTitle}</strong>
                      </p>
                    </div>
                    <div>
                      {/* <div className="mb-1">
                                                <span className="font-regular font-primary font-gray1">{item.location}</span>
                                            </div> */}
                      <div className="mb-1">
                        <span className="font-regular">
                          Scheduled On:{" "}
                          <strong>
                            {new Date(item.created_date).toLocaleString()}
                          </strong>
                        </span>
                      </div>
                      <div className="mb-1">
                        <span className="font-regular">
                          Assigned By:{" "}
                          <strong>
                            {item.RecruiterFname + " " + item.RecruiterLname}
                          </strong>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            ))
          ) : (
            <div>
              <div className="text-center p-3">
                <div className="avatar avatar-lg">
                  <i className="fad fa-comment-slash"></i>
                </div>
                <div className="mt-2">No Inteviews Scheduled at this time</div>
              </div>
            </div>
          )}
        </div>
        {interviewDetails && (
          <CandidateInterviewDetails
            interviewDetails={interview}
            closeModal={closePopUP}
          />
        )}
      </div>
    </>
  );
}

export default CandidateInterviews;
