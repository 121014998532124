import React, { useState } from "react";
import { post } from "../../../../../../service/ApiService";
import { toast } from "react-toastify";
import {
  getUserTenantID,
  getUserTenant,
  getCandidateName,
  getCandidateId,
} from "../../../../../../service/AuthService";
import { emailDomain } from "../../../../../../constants/Constants";
import Loader from "../../../../../Loader/Loader";
import axios from "axios";

const ResendMail = (props) => {
  const { talentData, jobData } = props;

  const [loading, setLoading] = useState(false);
  // Function to make a request to the URL shortening API
  async function shortenUrl(longUrl) {
    const headers = {
      Accept: "application/json",
      Authorization:
        "Bearer WJwUfBqQhE8ORpWMhoyae6IlvSPq2eIc3rS21kjth1Q8tY4yi3hkY9Zfvpqv",
      "Content-Type": "application/json",
    };
    const requestBody = {
      url: longUrl,
      domain: "high5hire.live",
    };

    try {
      const response = await axios.post(
        "https://api.tinyurl.com/create",
        requestBody,
        { headers }
      );
      if (response.data && response.data.data && response.data.data.tiny_url) {
        const shortenedUrl = response.data.data.tiny_url;
        console.log("Shortened URL:", shortenedUrl);
        return shortenedUrl;
      } else {
        console.log("Response does not contain a valid shortened URL.");
        return "";
      }
    } catch (error) {
      console.error("Error shortening URL:", error);
    }
  }

  const resendMail = async () => {
    try {
      const userid = getCandidateId();
      const tenant_id = getUserTenantID();
      const sendMailLink = `${emailDomain}/career/jobs/${jobData.jobId}/recruiter/${userid}`;
      const shortenedUrl = await shortenUrl(sendMailLink);
      const usertenant = getUserTenant();
      const loggedinusername = getCandidateName();

      const inviteReq = {
        event: "Reminder Invite (Manual - After 48 Hours auto reminder)",
        recipient: '["Candidate"]',
      };
      const res = await post("/msgtwoway/getSmsContent", inviteReq);
      const inviteContent = res.status === 200 ? res?.data : [];

      if (inviteContent && inviteContent?.length !== 0) {
        let content = inviteContent[0]?.smsContent;
        content = content?.replace("[First Name]", talentData?.firstName);
        content = content?.replace("[Job Name]", jobData?.jobTitle);
        content = content?.replace("[CLICK HERE]", shortenedUrl);
        inviteContent[0].smsContent = content;
      }

      const payloadWaSms = [
        {
          recieverNumber: [
            `${talentData.homePhoneCode?.substring(1)}${talentData.homePhone}`,
          ],
          message: `${inviteContent[0]?.smsContent} \n ${inviteContent[0]?.footer}`,
        },
      ];
      const payloadSms = [
        {
          recieverNumber: [
            `${talentData.homePhoneCode}${talentData.homePhone}`,
          ],
          message: `${inviteContent[0]?.smsContent} \n ${inviteContent[0]?.footer}`,
        },
      ];
      const resendMailPayload = {
        sendMailLink,
        jobTitle: jobData.jobTitle,
        candidateEmail: talentData.email,
        candidateFname: talentData.firstName,
        candidateLname: talentData.lastName,
        candidateId:talentData.userId,
        jobId: jobData.jobId,
        candidateTenant: usertenant,
        recruiterName: loggedinusername,
        recruiterId: userid,
        isFrom: "JobDisposition",
      };

      const promises = [post("/disposition/poolresendmail", resendMailPayload)];

      if (talentData.homePhone && talentData.homePhoneCode) {
        promises.push(
          post("/msgtwoway/telSms", payloadSms),
          post("/massages/sentMassageWhatsapp", payloadWaSms)
        );
      }

      await Promise.allSettled(promises);

      setLoading(false);
      toast.success(`Reminder email has been sent successfully`);
    } catch (error) {
      setLoading(false);
      toast.error("Oops! Something went wrong. Please try again later");
    }
  };
  return (
    <>
      {loading && <Loader />}
      <button type="button" onClick={() => resendMail()} className="btn btn-sm">
        Resend
      </button>
    </>
  );
};

export default ResendMail;
