import React, { useEffect, useState } from "react";
import OffersCard from "./OffersCard";
import Assessments from "./Assessments";
import Interviews from "./Interviews";
import OutsideClickHandler from "react-outside-click-handler";
import Sort from "../../../common/Sort";
import Filter from "./Filter";

const TodoCard = ({ candidateData }) => {
  const [tabType, setTabType] = useState({
    all: true,
    offers: false,
    assessments: false,
    interviews: false,
  });
  const [offered, setOffered] = useState([]);
  const [sort, setSort] = useState(false);
  const [sortArray, setSortArray] = useState([]);
  const [sortCount, setSortCount] = useState(0);
  // FIlter
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [requestStatus, setRequestStatus] = useState([]);
  const [requestTenant, setRequestTenant] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState([]);
  const [requestJobDate, setRequestJobDate] = useState([]);
  const [selectedJobDate, setSelectedJobDate] = useState([]);
  const [requestJobLocation, setRequestJobLocation] = useState([]);
  const [selectedJobLocation, setSelectedJobLocation] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  const sortObject = [
    // {
    //   id: 1,
    //   label: "Job Status",
    //   value: "statusname",
    //   icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    // },
    {
      id: 1,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Client",
      value: "jobTenant",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
  ];
  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  const applySort = (sortedData, close) => {
    close && setSort(false);
    setOffered(sortedData);
    // setFilteredData(sortedData);
  };
  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(filteredData, true);
  };

  const applyFilter = (
    filteredData,
    selectedTitles,
    selectedTypes,
    selectedTenant,
    selectedJobDate,
    selectedJobLocation,
    daysLeft,
    daysLeftValue
  ) => {
    setFilterCount(
      selectedTitles.length +
        selectedTypes.length +
        selectedTenant.length +
        selectedJobDate.length +
        selectedJobLocation.length
    );

    // sortClearAll();
    setSelectedTitles(selectedTitles);
    setSelectedTypes(selectedTypes);
    setSelectedTenant(selectedTenant);
    setSelectedJobDate(selectedJobDate);
    setSelectedJobLocation(selectedJobLocation);
    // setDaysLeft(daysLeft);
    // setDaysLeftValue(daysLeftValue);
    // setFilterBy(filteredData); candidateData?.dispositionData?.filter((f) => f.statusname == "Offered")
    setOffered(
      selectedTitles.length +
        selectedTypes.length +
        selectedTenant.length +
        selectedJobDate.length +
        selectedJobLocation.length >
        0
        ? filteredData
        : candidateData?.dispositionData?.filter(
            (f) => f.statusname == "Offered" || f.statusname == "Ready to Hire"
          )
    );
    setFilterData(filteredData);
    setFilter(false);
  };

  const onChangeClick = (type) => {
    if (type == "all") {
      setTabType({
        all: true,
        offers: false,
        assessments: false,
        interviews: false,
      });
    }
    if (type == "offers") {
      setTabType({
        all: false,
        offers: true,
        assessments: false,
        interviews: false,
      });
    }
    if (type == "assessments") {
      setTabType({
        all: false,
        offers: false,
        assessments: true,
        interviews: false,
      });
    }
    if (type == "interviews") {
      setTabType({
        all: false,
        offers: false,
        assessments: false,
        interviews: true,
      });
    }
  };
  useEffect(() => {
    setOffered(
      candidateData?.dispositionData?.filter(
        // (f) => f.statusname == "Offered" || f.statusname == "Ready to Hire"
        (f) => f.statusname == "Offered"
      )
    );
  }, [candidateData]);

  useEffect(() => {
    let title = [];
    let type = [];
    let tenant = [];
    let addedon = [];
    let location = [];
    candidateData?.dispositionData.forEach((job) => {
      if (job.jobTitle && !title.includes(job.jobTitle)) {
        title.push(job.jobTitle);
      }
      if (job.jobType && !type.includes(job.jobType)) {
        type.push(job.jobType);
      }
      if (job.jobTenant && !tenant.includes(job.jobTenant)) {
        tenant.push(job.jobTenant);
      }
      // if (job.jobPostDate && !addedon.includes(job.jobPostDate)) {
      //   addedon.push(job.jobPostDate);
      // }
      if (job.workplaceType && !location.includes(job.workplaceType)) {
        location.push(job.workplaceType);
      }
      setRequestTitles(title);
      setRequestStatus(type);
      setRequestTenant(tenant);
      // setRequestJobDate(addedon);
      setRequestJobLocation(location);
    });
  }, [candidateData]);
  return (
    <div className="col-xxl-4 mb-2">
      <div className="card">
        <div className="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <div>
              <label class="pt-0 mb-2">
                <strong>My Todos</strong>
              </label>{" "}
              {/* <a href="/candidate/jobs">
                <i aria-hidden="true" class="fas fa-external-link-square"></i>
              </a> */}
            </div>
            <div class="d-flex align-items-center justify-content-center justify-content-lg-start">
              <div class="mb-2 d-flex align-items-center justify-content-center">
                <div class="d-flex justify-content-end w-100">
                  <div class="dropdown-sort dd-sort">
                    <button
                      type="button"
                      class="btn btn-sm w-auto btn-text-accent dd-sort"
                      onClick={() => setSort(true)}
                    >
                      <i
                        aria-hidden="true"
                        class="fad fa-sort-down font-16 dd-sort"
                      ></i>{" "}
                      <span class="dd-sort d-none-1024">Sort</span> /
                      <span class="dd-sort"> {sortCount}</span>
                    </button>{" "}
                    {
                      <OutsideClickHandler
                        onOutsideClick={() => setSort(false)}
                      >
                        {sort && (
                          <Sort
                            isFrom="candidateprofile"
                            sortData={candidateData?.dispositionData?.filter(
                              (e) =>
                                e.statusname == "Offered" ||
                                e.statusname == "Ready to Hire"
                            )}
                            orderArray={orderList}
                            sortObjects={sortObject}
                            applySort={applySort}
                            setSortCount={setSortCount}
                            sortCount={sortCount}
                            setSortArray={setSortArray}
                            sortArray={sortArray}
                            sortingFor={"Sort by Job Title, Job Client"}
                          />
                        )}
                      </OutsideClickHandler>
                    }
                  </div>{" "}
                  <div class="dropdown-sort dd-filter mx-1">
                    {candidateData?.vettingData.filter((obj, index) => {
                      return (
                        index ===
                        candidateData.vettingData.findIndex(
                          (o) =>
                            obj.jobTitle === o.jobTitle &&
                            obj.uniqueCode === o.uniqueCode
                        )
                      );
                    })?.length > 1 && (
                      <button
                        type="button"
                        class="btn btn-sm w-auto btn-text-accent dd-filter"
                        onClick={() => setFilter(true)}
                      >
                        <i
                          aria-hidden="true"
                          class="fas fa-filter font-14 dd-filter"
                        ></i>{" "}
                        <span class="dd-filter d-none-1024">Filter</span> /
                        <span class="dd-filter"> {filterCount}</span>
                      </button>
                    )}
                    {candidateData !== null &&
                      Object.keys(candidateData)?.length > 0 &&
                      filter && (
                        <OutsideClickHandler
                          onOutsideClick={() => setFilter(false)}
                        >
                          {/* 30 red   70 orange 70 green */}
                          <Filter
                            requestTitles={requestTitles}
                            requestStatus={requestStatus}
                            requestTenant={requestTenant}
                            // requestJobDate={requestJobDate}
                            requestJobLocation={requestJobLocation}
                            selectedTitles={selectedTitles}
                            selectedTypes={selectedTypes}
                            selectedTenant={selectedTenant}
                            selectedJobDate={selectedJobDate}
                            selectedJobLocation={selectedJobLocation}
                            applyFilter={applyFilter}
                            // daysLeft={daysLeft}
                            // daysLeftValue={daysLeftValue}
                            // jobData={jobListData}
                            setJobData={offered}
                            setRequestStatus={setRequestStatus}
                            setRequestTenant={setRequestTenant}
                            jobData={candidateData?.dispositionData?.filter(
                              (f) =>
                                f.statusname == "Offered" ||
                                f.statusname == "Ready to Hire"
                            )}
                            candidateData={candidateData}
                          />
                        </OutsideClickHandler>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="m-0"></hr>

          <div class="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end mb-3 border-bottom-gray2">
            <ul class="nav nav-tabs nav-tabs-tablet pt-1 w-100 justify-content-center">
              <li class="nav-item">
                <button
                  type="button"
                  class={
                    tabType.all
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("all")}
                >
                  <span class="tag mb-1">
                    {offered?.length +
                      candidateData?.vettingData.filter((obj, index) => {
                        return (
                          index ===
                          candidateData.vettingData.findIndex(
                            (o) =>
                              obj.jobTitle === o.jobTitle &&
                              obj.uniqueCode === o.uniqueCode
                          )
                        );
                      }).length}
                    {/* <span class="jewel"></span> */}
                  </span>{" "}
                  <div>All</div>
                </button>
              </li>{" "}
              <li class="nav-item">
                <button
                  type="button"
                  // class="nav-link pb-3 mx-2 bg-transparent"
                  class={
                    tabType.assessments
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("assessments")}
                >
                  <span class="tag mb-1">
                    {
                      candidateData?.vettingData.filter((obj, index) => {
                        return (
                          index ===
                          candidateData.vettingData.findIndex(
                            (o) =>
                              obj.jobTitle === o.jobTitle &&
                              obj.uniqueCode === o.uniqueCode
                          )
                        );
                      }).length
                    }
                  </span>{" "}
                  <div>Assessments</div>
                </button>
              </li>{" "}
              {/* <li class="nav-item">
                <button
                  type="button"
                  // class="nav-link pb-3 mx-2 bg-transparent"
                  class={
                    tabType.interviews
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("interviews")}
                >
                  <span class="tag mb-1">0</span> <div>Interviews</div>
                </button>
              </li> */}
              <li class="nav-item">
                <button
                  type="button"
                  // class="nav-link pb-3 mx-2 bg-transparent"
                  class={
                    tabType.offers
                      ? "nav-link pb-3 mx-2 bg-transparent active"
                      : "nav-link pb-3 mx-2 bg-transparent"
                  }
                  onClick={() => onChangeClick("offers")}
                >
                  <span class="tag mb-1">
                    {offered?.length}
                    {/* <span class="jewel"></span> */}
                  </span>{" "}
                  <div>Offers</div>
                </button>
              </li>{" "}
            </ul>
          </div>
          <div className="col">
            {tabType.all && (
              <>
                {offered?.map((offer) => (
                  <OffersCard offer={offer} candidateData={candidateData} />
                ))}
                <Assessments candidateData={candidateData} />
                {/* <Interviews /> */}
              </>
            )}
            {tabType.offers &&
              offered?.map((offer) => (
                <OffersCard offer={offer} candidateData={candidateData} />
              ))}

            {tabType.assessments && (
              <Assessments candidateData={candidateData} />
            )}

            {/* {tabType.interviews && <Interviews />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodoCard;
