import React from "react";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import moment from "moment";
import { getUserRole } from "../../../../service/AuthService";

const Request = (props) => {
  const statusData = props.statusData.filter((s) =>
    s.status == 42 ? getUserRole() === "Recruiter" : true
  );
  return (
    <>
      {statusData && statusData.length > 0 ? (
        <div class="view-panel-body">
          {statusData.map((item) => (
            <div class="card card-flat mb-2">
              <div class="d-flex card-body">
                <div class="d-flex align-items-center d-block-600">
                  <div class="mr-2">
                    <span>{item.jobTitle}</span>
                  </div>
                  <div class="mr-2 w-60">
                    <span class="tag tag-blue3 font-white ml-1 mt-2">
                      {item.statusname === "Offered"
                        ? item?.jobId
                          ? "Offered"
                          : "Ready To Hire"
                        : item.statusname === "Offer Rejected"
                        ? "Offer Declined"
                        : item.statusname === "Rejected"
                        ? "Disqualified"
                        : item.statusname === "Submitted"
                        ? item?.jobId
                          ? "Submitted"
                          : "Engaged"
                        : item?.statusname === "Vetting"
                        ? "Vetted"
                        : item?.statusname}
                    </span>
                    <span>
                      ID: {item.jobId ? item.jobId : item.fk_talentPool}
                    </span>
                  </div>
                  <div>
                    Last Updated:{" "}
                    {moment(new Date(item.updatedDateTime), "YYYYMMDD").format(
                      "MM/DD/YYYY"
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <NoRecord />
      )}
    </>
  );
};

export default Request;
