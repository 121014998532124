import {
  GET_SC_ANALYTICS_BEGIN,
  GET_SC_ANALYTICS_SUCCESS,
  GET_SC_ANALYTICS_FAILURE,
} from "../../../actions/directsourcing/talentcommunity/Analytics";

const initialState = {
  loading: false,
  analyticsData: null,
};

const getSCAnalyticsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SC_ANALYTICS_BEGIN:
      return {
        ...state,
        loading: true,
        analyticsData: null,
      };
    case GET_SC_ANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsData: payload,
        loading: false,
      };
    case GET_SC_ANALYTICS_FAILURE:
      return {
        ...state,
        analyticsData: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default getSCAnalyticsReducer;
