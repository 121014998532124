import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import HereMap from "../../location/HereMapSourceTalent";
import TagsInput from "react-tagsinput";
import { get, post } from "../../../service/ApiService";
import Autosuggest from "react-autosuggest";
import Loader from "../../Loader/Loader";
import "react-tagsinput/react-tagsinput.css";
import { ButtonLoader } from "../../../constants/Constants";
import {
  getUserName,
  getCandidateId,
  getCandidateName,
  getUserTenant,
} from "../../../service/AuthService";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import { isValidUrl } from "../../function/CommonFunction";
import { domain } from "../../../constants/Constants";
import Dropzone from "react-dropzone";
import ScreeningQuestions from "./ScreeningQuestions";
import Modal from "react-bootstrap/Modal";
import { FaCheckCircle } from "react-icons/fa";
import axios from "axios";

function SubmitTalentModal({
  candidate,
  job,
  closeModal,
  setScore,
  component,
}) {
  const [contact, setContact] = useState(true);
  const [skills, setSkills] = useState(true);
  const [submittal, setSubmittal] = useState(false);
  const [screening, setScreening] = useState(true);
  const [resume, setResume] = useState(true);
  const [vetting, setVetting] = useState(true);
  const [vettingDetails, setVettingDetails] = useState([]);
  const [contactInfo, setContactInfo] = useState({
    firstName: candidate.fullText.firstName,
    lastName: candidate.fullText.lastName,
    email: candidate.email,
    LinkedInURL: candidate.linkedIn,
    candidatePhone: candidate.mobilePhone,
    location: candidate.addressCity,
    workAuthorization: candidate.visaStatus || "Not Required",
    currentlyEmployed: "true",
    oneWayInterviewDate: "",
    oneWayInterviewLink: "",
    offersInPipeline: "No",
    noticePeriod: "",
    payRate: "",
    payRateCurrency: "",
    phoneValidation: true,
  });
  const [addPrimarySkill, setAddPrimarySkill] = useState(false);
  const [addSecondarySkill, setAddSecondarySkill] = useState(false);
  const [temporaryPrimarySkill, setTemporaryPrimarySkill] = useState({
    skill: "",
    years: "1",
    months: "0",
    candidateResponse: "",
  });
  const [temporarySecondarySkill, setTemporarySecondarySkill] = useState({
    skill: "",
    years: "1",
    months: "0",
    candidateResponse: "",
  });
  const [form, setForm] = useState(null);
  const [primarySkillInput, setPrimarySkillInput] = useState("");
  const [primarySkillFinalInput, setPrimarySkillFinalInput] = useState([]);
  const [secondarySkillInput, setSecondarySkillInput] = useState("");
  const [secondarySkillFinalInput, setSecondarySkillFinalInput] = useState([]);
  const [dropdownList, setDropdownList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [primarySkills, setPrimarySkills] = useState([]);
  const [secondarySkills, setSecondarySkills] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [noticePeriod, setNoticePeriod] = useState("30");
  const [noticePeriodDuration, setNoticePeriodDuration] = useState("Day/s");
  const [profileValidation, setProfileValidation] = useState({
    firstNameError: "",
    lastNameError: "",
    phoneError: "",
    linkedInError: "",
    interviewLinkError: "",
    noticePeriodError: "",
    noticePeriodDurationError: "",
    payRateError: "",
    payRateCurrencyError: "",
  });
  const [preliminary, setPreliminary] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [technical, setTechnical] = useState(false);
  const [client, setClient] = useState(false);
  const [oneWay, setOneWay] = useState(false);
  // screeningQuestions Child Component
  const [screeningQuestions, setScreeningQuestions] = useState({
    preliminary: [],
    admin: [],
    technical: [],
    client: [],
    oneWay: [],
  });
  const [QandA, setQandA] = useState([]);
  const [displayQandA, setDisplayQandA] = useState(false);
  const [QandAValidation, setQandAValidation] = useState([]);
  const [resumeLoading, setResumeLoading] = useState(false);
  const [addPrimarySkillValidation, setAddPrimarySkillValidation] = useState({
    skill: "",
    months: "",
  });
  const [
    addSecondarySkillValidation,
    setAddSecondarySkillValidation,
  ] = useState({
    skill: "",
    months: "",
  });
  const [primarySkillValidation, setPrimarySkillValidation] = useState([]);
  const [secondarySkillValidation, setSecondSkillValidation] = useState([]);
  const [resumeFileName, setResumeFileName] = useState("");
  const [resumeFileURL, setResumeFileURL] = useState("");
  const [resumeBase64, setResumeBase64] = useState("");
  const [resumeModal, setResumeModal] = useState(false);
  const [inEligible, setInEligible] = useState(false);
  const [confirmSource, setConfirmSource] = useState(false);
  const [vettingDB, setVettingDB] = useState([]);
  const [vettingAssign, setVettingAssign] = useState([]);

  const visaStatus = [
    "Green Card",
    "H1B",
    "Citizen",
    "H4 EAD",
    "GC EAD",
    "L2EAD",
    "OPT EAD",
    "TN1",
    "TN2",
    "Not Required",
  ];

  const fetchCurrency = async () => {
    // setLoading(true)
    let res = await get("/sourcecandidate/ratedropdown");
    setCurrencyList(res.data.map((i) => i.CurrencyNameTime));
    setLoading(false);
  };

  const fetchDropDown = async () => {
    // setLoading(true)
    const res = await get("/talent/dropdownlist");
    setDropdownList(res.data.CandidateSkills.map((skill) => skill.SourceName));
    setLoading(false);
  };

  const _onChangeText = (value, country) => {
    console.log(value, country);
    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    const formattedNumber = asYouType.input(
      value.replace(country.dialCode, "")
    );
    const isValid = asYouType.isValid();
    setContactInfo({
      ...contactInfo,
      candidatePhone: value,
      phoneValidation: isValid,
    });
  };

  const fetchResume = async () => {
    setResumeLoading(true);
    const payload = {
      candidateID: candidate.candidateID,
      tenant: getUserTenant(),
    };
    try {
      let res = await post("/candidateProfile/getResumeTC", payload);
      if (res.data.length > 0) {
        setResumeFileName(res.data[0].fileName);
        setResumeFileURL(res.data[0].fileURL);
      }
      setResumeLoading(false);
    } catch (err) {
      console.log(err);
      setResumeLoading(false);
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  const onUploadHandler = (file) => {
    setFileForSending(file[0]);
  };

  const setFileForSending = (file) => {
    const { name } = file;
    const type = name.split(".")[1];
    if (type === "pdf" || type === "docx" || type === "doc") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        setResumeFileName(name);
        setResumeFileURL("");
        setResumeBase64(event.target.result);
      };
    } else {
      toast.error("Please Upload PDF or WORD Document");
    }
  };

  const fetchVetting = async (vetting) => {
    setLoading(true);
    const res = await get(`/vetting/tests/all`);
    // const res = await axios.get(
    //   "https://https://high5vettinguat-api.azurewebsites.net/test/all",
    //   {
    //     headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
    //   }
    // );
    let vett = res.data.filter((i) => i.skills.length === 1);
    setVettingDB(vett);
    let vettings = [];
    let skills = vett.map((i) => i.skills[0]);
    let DB = [...vetting].map((i) => ({
      ...i,
      duration: i.duration?.split(" ")[0],
      type: i.type === "OneWay" ? "OneWay" : i.type,
    }));
    DB.map((item) => {
      if (skills.includes(item.name)) {
        let obj = vett.find((i) => i.skills.includes(item.name));
        if (
          obj.testCategory === item.type &&
          obj.details.duration === item.duration
        ) {
          vettings.push(obj._id);
        }
      }
    });
    setVettingAssign(vettings);
    setLoading(false);
  };

  useEffect(() => {
    if (job.QandA) {
      let QnA = JSON.parse(job.QandA);
      let finalQA = QnA.map((item) => {
        if (
          ![
            "Industry Experience",
            "Work Experience",
            "Custom Question",
            "Language",
          ].includes(item.name)
        ) {
          return { ...item, answer: "No" };
        } else if (item.name === "Language") {
          return {
            ...item,
            answer: JSON.parse(item.input).map((i) => ({
              name: i,
              proficiency: "Beginner",
            })),
          };
        } else if (item.name === "Custom Question") {
          return { ...item, answer: item.input === "Yes/No" ? "No" : "" };
        } else {
          return item;
        }
      });
      setQandA(finalQA);
      setQandAValidation(finalQA.map((i) => ""));
    }
    setPrimarySkills(
      job.primarySkills.map((skill) => ({
        skill,
        years: "1",
        months: "0",
        candidateResponse: "",
      }))
    );
    setSecondarySkills(
      job.secondarySkills.map((skill) => ({
        skill,
        years: "1",
        months: "0",
        candidateResponse: "",
      }))
    );
    fetchDropDown();
    fetchCurrency();
    fetchResume();
    if (job.vettingDetails && job.vettingDetails.length > 0) {
      let vettingData =
        typeof job.vettingDetails === "string"
          ? JSON.parse(job.vettingDetails)
          : job.vettingDetails;
      setVettingDetails(vettingData);
      fetchVetting(vettingData);
    }
  }, []);

  const handleChange = (name, value) => {
    let finalValue;
    let temporaryData = { ...contactInfo };
    if (name === "currentlyEmployed") {
      if (value) {
        finalValue = "true";
      } else {
        finalValue = "false";
      }
      temporaryData[name] = finalValue;
    } else {
      temporaryData[name] = value;
    }
    setContactInfo(temporaryData);
  };

  const handleTemporaryPrimarySkill = (name, value) => {
    if (name === "months" && +value > 11) {
      toast.error(`Months Cannot Be Greater Than '11'`);
    } else {
      setTemporaryPrimarySkill({ ...temporaryPrimarySkill, [name]: value });
    }
  };

  const handleTemporarySecondarySkill = (name, value) => {
    if (name === "months" && +value > 11) {
      toast.error(`Months Cannot Be Greater Than '11'`);
    } else {
      setTemporarySecondarySkill({ ...temporarySecondarySkill, [name]: value });
    }
  };

  const deleteTemporaryPrimarySkill = () => {
    setPrimarySkillFinalInput([]);
    setTemporaryPrimarySkill({
      skill: "",
      years: "1",
      months: "0",
      candidateResponse: "",
    });
    setAddPrimarySkill(false);
  };

  const deleteTemporarySecondarySkill = () => {
    setSecondarySkillFinalInput([]);
    setTemporarySecondarySkill({
      skill: "",
      years: "1",
      months: "0",
      candidateResponse: "",
    });
    setAddSecondarySkill(false);
    setAddSecondarySkillValidation({ skill: "", years: "" });
  };

  const saveTemporaryPrimarySkill = () => {
    if (primarySkillFinalInput.length === 0) {
      setAddPrimarySkillValidation({
        ...addPrimarySkillValidation,
        skill: "*Required Field",
      });
    } else if (
      (temporaryPrimarySkill.years === "0" ||
        temporaryPrimarySkill.years === "") &&
      (temporaryPrimarySkill.months === "0" ||
        temporaryPrimarySkill.months === "")
    ) {
      setAddPrimarySkillValidation({
        ...addPrimarySkillValidation,
        skill: "",
        months:
          temporaryPrimarySkill.months === "0"
            ? "Cannot Be Zero"
            : "Cannot Be Null",
      });
    } else {
      setAddPrimarySkillValidation({ skill: "", months: "" });
      let t = [...primarySkills];
      t.push({ ...temporaryPrimarySkill, skill: primarySkillFinalInput[0] });
      setPrimarySkills(t);
      setPrimarySkillFinalInput([]);
      setTemporaryPrimarySkill({
        skill: "",
        years: "",
        months: "",
        candidateResponse: "",
      });
      setAddPrimarySkill(false);
    }
  };

  const saveTemporarySecondarySkill = () => {
    if (secondarySkillFinalInput.length === 0) {
      setAddSecondarySkillValidation({
        ...addSecondarySkillValidation,
        skill: "*Required Field",
      });
    } else if (
      (temporarySecondarySkill.years === "0" ||
        temporarySecondarySkill.years === "") &&
      (temporarySecondarySkill.months === "0" ||
        temporarySecondarySkill.months === "")
    ) {
      setAddSecondarySkillValidation({
        ...addSecondarySkillValidation,
        skill: "",
        months:
          temporarySecondarySkill.months === "0"
            ? "Cannot Be Zero"
            : "Cannot Be Null",
      });
    } else {
      setAddSecondarySkillValidation({ skill: "", months: "" });
      let t = [...secondarySkills];
      t.push({
        ...temporarySecondarySkill,
        skill: secondarySkillFinalInput[0],
      });
      setSecondarySkills(t);
      setSecondarySkillFinalInput([]);
      setTemporarySecondarySkill({
        skill: "",
        years: "",
        months: "",
        candidateResponse: "",
      });
      setAddSecondarySkill(false);
    }
  };

  const handleChangePrimarySkills = (index, name, value) => {
    if (name === "months" && +value > 11) {
      toast.error(`Months Cannot Be Greater Than '11'`);
    } else {
      let temporaryData = [...primarySkills];
      temporaryData[index] = { ...temporaryData[index], [name]: value };
      setPrimarySkills(temporaryData);
    }
  };

  const handleChangeSecondarySkills = (index, name, value) => {
    if (name === "months" && +value > 11) {
      toast.error(`Months Cannot Be Greater Than '11'`);
    } else {
      let temporaryData = [...secondarySkills];
      temporaryData[index] = { ...temporaryData[index], [name]: value };
      setSecondarySkills(temporaryData);
    }
  };

  const onCollapsibleClick = (type) => {
    if (type === "contact") {
      !contact ? setContact(true) : setContact(false);
    } else if (type === "resume") {
      setResume(!resume);
    } else if (type === "skills") {
      setSkills(!skills);
    } else if (type === "screening") {
      !screening ? setScreening(true) : setScreening(false);
    } else if (type === "admin") {
      setAdmin(!admin);
    } else if (type === "preliminary") {
      setPreliminary(!preliminary);
    } else if (type === "technical") {
      setTechnical(!technical);
    } else if (type === "client") {
      setClient(!client);
    } else if (type === "oneWay") {
      setOneWay(!oneWay);
    } else {
      submittal ? setSubmittal(false) : setSubmittal(true);
    }
  };

  const deletePrimarySkills = (index) => {
    let s = [...primarySkills];
    s.splice(index, 1);
    setPrimarySkills(s);
  };

  const deleteSecondarySkills = (index) => {
    let s = [...secondarySkills];
    s.splice(index, 1);
    setSecondarySkills(s);
  };

  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.PositionName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropdownList.filter((item) =>
              item.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion}</div>;

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        theme={theme}
      />
    );
  };

  const checkEligiblity = () => {
    let validationArray = [];
    let names = QandA.map((i) => i.name);
    if (
      names.includes("Background Check") &&
      job.backgroundCheck &&
      QandA.find((item) => item.name === "Background Check").answer === "No"
    ) {
      validationArray.push("Background Check Required");
    }
    if (
      names.includes("Drug Check") &&
      job.drugTest &&
      QandA.find((item) => item.name === "Drug Check").answer === "No"
    ) {
      validationArray.push("Drug Test Required");
    }
    if (
      names.includes("Urgent Hiring Need") &&
      QandA.find((item) => item.name === "Urgent Hiring Need").answer === "No"
    ) {
      validationArray.push(
        "Candidate is required to start immediately for the job"
      );
    }
    if (validationArray.length > 0) {
      toast.error(validationArray[0]);
    }
  };

  const submitTalent = async () => {
    setSubmit(true);
    let validationObj = [];
    let validationObj2 = [];
    let validationObj3 = [];
    let resumeValidation = "";
    let linkedIn = "";
    let interviewLink = "";
    let phone = "";
    let notice = "";
    let noticeDuration = "";
    let payRate = "";
    let payRateCurrency = "";
    let primarySkillsValidation = [];
    let secondarySkillsValidation = [];
    let firstName = "";
    let lastName = "";
    let QnAVal = QandA.map((i) => "");
    primarySkills.forEach((skill) => {
      if (
        (skill.years === "0" || skill.years === "") &&
        (skill.months === "0" || skill.months === "")
      ) {
        primarySkillsValidation.push(skill.skill);
        let errMsg = skill.months === "0" ? "Zero" : "Null";
        validationObj2.push(
          `Months of Experience Cannot Be ${errMsg} for '${skill.skill}' (Primary Skills)`
        );
      }
    });
    secondarySkills.forEach((skill) => {
      if (
        (skill.years === "0" || skill.years === "") &&
        (skill.months === "0" || skill.months === "")
      ) {
        secondarySkillsValidation.push(skill.skill);
        let errMsg = skill.months === "0" ? "Zero" : "Null";
        validationObj2.push(
          `Months of Experience Cannot Be ${errMsg} for '${skill.skill}' (Secondary Skills)`
        );
      }
    });
    // if(contactInfo.LinkedInURL && !isValidUrl(contactInfo.LinkedInURL)) {
    //     validationObj.push('Please Enter Valid LinkedIn URL')
    //     linkedIn = 'Invalid URL'
    // }
    if (contactInfo.firstName === "") {
      firstName = "*Required Field";
      validationObj.push("Please Enter First Name");
    }
    if (contactInfo.lastName === "") {
      lastName = "*Required Field";
      validationObj.push("Please Enter Last Name");
    }
    // if(contactInfo.oneWayInterviewLink && !isValidUrl(contactInfo.oneWayInterviewLink)) {
    //     validationObj.push('Please Enter Valid Interview Link')
    //     interviewLink = 'Invalid URL'
    // }
    // if(contactInfo.candidatePhone && !contactInfo.candidatePhone.match(/^\d+$/)) {
    //     validationObj.push('Please Enter Valid Phone')
    //     phone = 'Invalid Phone'
    // }
    if (contactInfo.candidatePhone === "") {
      phone = "Required Field";
      validationObj.push("Please Enter Phone");
    }
    if (contactInfo.candidatePhone && !contactInfo.phoneValidation) {
      phone = "Invalid Phone";
      validationObj.push("Please Enter Valid Phone");
    }
    if (noticePeriod && noticePeriodDuration === "") {
      validationObj.push("Please Enter Notice Period");
      noticeDuration = "*Required Field";
    }
    if (noticePeriodDuration && noticePeriod === "") {
      validationObj.push("Please Enter Notice Period Duration");
      notice = "*Required Field";
    }
    if (contactInfo.payRate === "") {
      validationObj.push("Please Enter Pay Rate");
      payRate = "*Required Field";
    }
    if (contactInfo.payRateCurrency === "") {
      validationObj.push("Please Enter Pay Rate Currency");
      payRateCurrency = "*Required Field";
    }
    QandA.forEach((item, index) => {
      if (
        ["Industry Experience", "Work Experience", "Custom Question"].includes(
          item.name
        )
      ) {
        if (item.isMandatory && !item.answer) {
          console.log(item.answer);
          QnAVal[index] = "*Required Field";
        }
        if (item.name === "Work Experience") {
          let exp = QandA.find((item2) => item2.name === "Industry Experience");
          if (exp) {
            if (exp && +item.answer < +exp) {
              QnAVal[index] =
                "Total experience should be greater than Industry Experience";
            }
          }
        }
      }
    });
    if (QnAVal.some((i) => i)) {
      validationObj3.push("Please Fill All the Mandatory Screening Questions");
    }
    if (resumeFileName === "") {
      resumeValidation = "Please Upload Resume";
    }
    setProfileValidation({
      payRateError: payRate,
      payRateCurrencyError: payRateCurrency,
      phoneError: phone,
      linkedInError: linkedIn,
      interviewLinkError: interviewLink,
      noticePeriodError: notice,
      noticePeriodDurationError: noticeDuration,
      firstNameError: firstName,
      lastNameError: lastName,
    });
    setPrimarySkillValidation(primarySkillsValidation);
    setSecondSkillValidation(secondarySkillsValidation);
    setQandAValidation(QnAVal);
    if (validationObj.length > 0) {
      setSubmit(false);
      toast.error(validationObj[0]);
      setContact(true);
      setSkills(false);
      setResume(false);
      setDisplayQandA(false);
    } else if (validationObj2.length > 0) {
      setSubmit(false);
      toast.error(validationObj2[0]);
      setContact(false);
      setSkills(true);
      setResume(false);
      setDisplayQandA(false);
    } else if (validationObj3.length > 0) {
      console.log(QnAVal);
      setSubmit(false);
      toast.error(validationObj3[0]);
      setContact(false);
      setSkills(false);
      setResume(false);
      setDisplayQandA(true);
    } else if (resumeValidation) {
      setSubmit(false);
      setContact(false);
      setSkills(false);
      setDisplayQandA(false);
      setResume(true);
      toast.error(resumeValidation);
    } else if (false) {
      setSubmit(false);
      toast.success("Validation Success");
    } else {
      let errorMsg = false;
      if (vettingAssign.length > 0) {
        let body = {
          reviewer: {
            name: "",
            email: "",
          },
          createdBy: {
            id: "62fb9ad4cb6714dd6540e2cf",
            name: "John Wang",
            role: "user",
          },
          modifiedBy: {
            id: "62fb9ad4cb6714dd6540e2cf",
            name: "John Wang",
            role: "user",
          },
          name: candidate.firstName,
          email: candidate.email,
          recruiterEmail: "",
          high5hireCandidateId: job.jobId,
          testAssign: {
            id: vettingAssign[0],
          },
          testInvited: true,
          expiryDays: 40,
          takesForTest: 3,
        };
        const res = await post(`/vetting/candidate/add`, body);
        // const res = await axios.post(
        //   "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
        //   body,
        //   {
        //     headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
        //   }
        // );
        if (vettingAssign.length > 1) {
          const res2 = await post(`/vetting/candidate/add`, {
            ...body,
            testAssign: { id: vettingAssign[1] },
          });
          // const res2 = await axios.post(
          //   "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
          //   { ...body, testAssign: { id: vettingAssign[1] } },
          //   {
          //     headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
          //   }
          // );
        }
        if (vettingAssign.length > 2) {
          const res2 = await post(`/vetting/candidate/add`, {
            ...body,
            testAssign: { id: vettingAssign[2] },
          });
          // const res2 = await axios.post(
          //   "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
          //   { ...body, testAssign: { id: vettingAssign[2] } },
          //   {
          //     headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
          //   }
          // );
        }
      }

      let finalResumeURL;
      if (resumeBase64) {
        const params = {
          jobId: job.jobId,
          candidateId: candidate.candidateID,
          base64: resumeBase64,
          fileName: resumeFileName,
        };
        await post("/submitcandidate/uploadResume", params)
          .then(async (_) => {
            await post("/submitcandidate/getResume", params)
              .then((res) => {
                finalResumeURL = res.data[0].ImageURL;
              })
              .catch((err) => {
                console.log(err);
                errorMsg = true;
                toast.error(
                  "Some Error Occurred while Uploading Resume. Please Try Again"
                );
              });
          })
          .catch((err) => {
            console.log(err);
            errorMsg = true;
            toast.error(
              "Some Error Occurred while Uploading Resume. Please Try Again"
            );
          });
      } else {
        finalResumeURL = resumeFileURL;
      }
      if (!errorMsg) {
        setSubmit(true);
        setProfileValidation({
          phoneError: "",
          linkedInError: "",
          interviewLinkError: "",
          noticePeriodDurationError: "",
          noticePeriodError: "",
          payRateError: "",
          payRateCurrencyError: "",
          firstNameError: "",
          lastNameError: "",
        });
        setPrimarySkillValidation([]);
        setSecondSkillValidation([]);
        let recruiter = {
          name: getCandidateName(),
          email: getUserName(),
          id: getCandidateId(),
          tenant: getUserTenant(),
        };
        let {
          candidatePhone,
          LinkedInURL,
          oneWayInterviewDate,
          oneWayInterviewLink,
          currentlyEmployed,
          workAuthorization,
          offersInPipeline,
        } = contactInfo;
        const params = {
          domain,
          job,
          candidate,
          primarySkills: primarySkills.map((s) => ({
            skills: [s.skill],
            yearsOfExp: +s.years,
            monthsOfExp: +s.months,
            candidateResponse: s.candidateResponse,
          })),
          secondarySkills: secondarySkills.map((s) => ({
            skills: [s.skill],
            yearsOfExp: +s.years,
            monthsOfExp: +s.months,
            candidateResponse: s.candidateResponse,
          })),
          contactInfo,
          recruiter,
          userInfo: {
            candidatePhone,
            LinkedInURL,
            oneWayInterviewDate,
            oneWayInterviewLink,
            currentlyEmployed,
            workAuthorization,
            offersInPipeline,
            noticePeriod: `${noticePeriod} ${noticePeriodDuration}`,
          },
          QandA,
          resumeURL: [{ fileName: resumeFileName, resumeURL: finalResumeURL }],
        };
        try {
          setLoading(true);
          const res = await post("/submitcandidate", params);
          if (res.status === 200) {
            toast.success("Invited Successfully");
            setLoading(false);
            setSubmit(false);
            closeModal();
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          } else {
            toast.error("Some Error Occurred. Please Try Again");
            setSubmit(false);
          }
        } catch (err) {
          setSubmit(false);
          toast.error("Some Error Occurred. Please Try Again");
        }
      }
    }
  };

  const handleResumeSelect = (checked, name, URL) => {
    if (checked) {
      setResumeBase64("");
      setResumeFileName(name);
      setResumeFileURL(URL);
    } else {
      setResumeFileName("");
      setResumeFileURL("");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <button
          onClick={() => {
            onCollapsibleClick("contact");
          }}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          {contact ? (
            <i className="fal fa-angle-up fa-fw mt-1"></i>
          ) : (
            <i className="fal fa-angle-down fa-fw"></i>
          )}
          <span>
            <small>Candidate Details</small>
          </span>
        </button>
        <div className={contact ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
          <div className="card card-flat ml-4 ml-0-600">
            <div className="card-body">
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={
                        profileValidation.firstNameError
                          ? "form-control font-14 border border-danger"
                          : "form-control font-14"
                      }
                      name="firstName"
                      value={contactInfo.firstName}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      // style={{
                      //     fontSize: '12px'
                      // }}
                    />
                    <label>First Name*</label>
                    {profileValidation.firstNameError && (
                      <small className="validation ml-1">
                        {profileValidation.firstNameError}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className={
                        profileValidation.lastNameError
                          ? "form-control font-14 border border-danger"
                          : "form-control font-14"
                      }
                      name="lastName"
                      placeholder="Last Name*"
                      value={contactInfo.lastName}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                    <label>Last Name*</label>
                    {profileValidation.lastNameError && (
                      <small className="validation ml-1">
                        {profileValidation.lastNameError}
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control font-14"
                      id="email"
                      name="email"
                      value={contactInfo.email}
                      disabled
                    />
                    <label>Email*</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <PhoneInput
                    inputClass={
                      profileValidation.phoneError
                        ? "form-control font-14 w-100 border border-danger"
                        : "form-control font-14 w-100"
                    }
                    required="true"
                    placeholder="Primary Phone*"
                    country={"us"}
                    value={contactInfo.candidatePhone}
                    name="mobilePhone"
                    // onChange={(value) => setContactInfo({ ...contactInfo, ['candidatePhone']: value })}
                    onChange={(value, country) => {
                      _onChangeText(value, country);
                    }}
                  />
                  {profileValidation.phoneError && (
                    <small className="validation ml-1">
                      {profileValidation.phoneError}
                    </small>
                  )}
                </div>
                {/* <div className="col-lg-6">
                                <div className="form-floating font-14">
                                    <input 
                                        type="url" 
                                        className={ profileValidation.linkedInError ? "form-control border border-danger" : 'form-control'}
                                        id="LinkedInURL" 
                                        name="LinkedInURL"
                                        value={contactInfo.LinkedInURL}
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}                                        
                                    />
                                    <label>LinkedIn</label>
                                    { profileValidation.linkedInError && <small className="validation ml-1">{profileValidation.linkedInError}</small>}
                                </div>                               
                            </div>                                             */}
              </div>
              <div className="row mb-2">
                <div class="col-lg-6">
                  <div class="form-floating">
                    <select
                      class="form-select font-14"
                      name="workAuthorization"
                      onChange={(e) =>
                        handleChange(e.target.value, "workAuthorization")
                      }
                    >
                      {/* <option value='' disabled>Select Status</option> */}
                      {visaStatus.map((item) => (
                        <option
                          selected={item === contactInfo.workAuthorization}
                          value={item}
                        >
                          {item}
                        </option>
                      ))}
                    </select>
                    <label>Visa Status Requirement*</label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-floating">
                    <input
                      type="number"
                      min="1"
                      className={
                        profileValidation.payRateError
                          ? "form-control font-14 border border-danger"
                          : "form-control font-14"
                      }
                      value={contactInfo.payRate}
                      id="noticePeriod"
                      name="payRate"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                    <label>Pay Rate*</label>
                    {profileValidation.payRateError && (
                      <small className="validation ml-1">
                        {profileValidation.payRateError}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-floating w-100">
                    <select
                      className={
                        profileValidation.payRateCurrencyError
                          ? "form-select font-14 border border-danger"
                          : "form-select font-14"
                      }
                      id="payrateCurrency"
                      name="payRateCurrency"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                    >
                      <option value>Select</option>
                      {currencyList.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <label>Currency*</label>
                    {profileValidation.payRateCurrencyError && (
                      <small className="validation ml-1">
                        {profileValidation.payRateCurrencyError}
                      </small>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-3">
                  <div className="form-floating">
                    <input
                      type="number"
                      className={
                        profileValidation.noticePeriodError
                          ? "form-control font-14 border border-danger"
                          : "form-control font-14"
                      }
                      min="1"
                      value={noticePeriod}
                      onChange={(e) => setNoticePeriod(e.target.value)}
                    />
                    <label>Notice Period</label>
                    {profileValidation.noticePeriodError && (
                      <small className="validation ml-1">
                        {profileValidation.noticePeriodError}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="form-floating">
                    <select
                      className={
                        profileValidation.noticePeriodDurationError
                          ? "form-select border border-danger"
                          : "form-select font-14"
                      }
                      onChange={(e) => setNoticePeriodDuration(e.target.value)}
                    >
                      <option value disabled>
                        Select
                      </option>
                      <option selected value="Day/s">
                        Day/s
                      </option>
                      <option value="Week/s">Week/s</option>
                      <option value="Month/s">Month/s</option>
                    </select>
                    <label>Select Duration</label>
                    {profileValidation.noticePeriodDurationError && (
                      <small className="validation ml-1">
                        {profileValidation.noticePeriodDurationError}
                      </small>
                    )}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control font-14"
                      value={contactInfo.offersInPipeline}
                      id="offers"
                      name="offersInPipeline"
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                    />
                    <label>Offers In Pipeline</label>
                  </div>
                </div>
                {/* <div className="col-lg-6 d-flex align-items-center">
                                <div className='mr-2 ml-2 mt-3'>
                                    <p>Currently Employed? </p>
                                </div>
                                <div className='form-check form-switch'>
                                    <input 
                                        type='checkbox' 
                                        className='form-check-input'
                                        name="currentlyEmployed"
                                        checked={contactInfo.currentlyEmployed === 'true' ? true : false}
                                        onChange={(event) => handleChange(event.target.name, event.target.checked)}
                                    />
                                </div>
                            </div>                                                                    */}
              </div>
              {/* <div className="row mb-2">
                            <div className="col-lg-6">
                                <div className="form-floating">
                                    <input 
                                        type="date" 
                                        className="form-control font-14"
                                        value={contactInfo.oneWayInterviewDate} 
                                        id="oneWayInterviewDate" 
                                        name="oneWayInterviewDate"
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                    <label>Interview Date</label>
                                </div>                               
                            </div>                                                                                             
                        </div>
                        <div className="row mb-2">
                            <div className="col-lg-12">
                                <div className="form-floating">
                                    <input 
                                        type="text" 
                                        className={ profileValidation.interviewLinkError ? "form-control border border-danger" : 'form-control font-14'}
                                        value={contactInfo.oneWayInterviewLink}
                                        id="oneWayInterviewLink" 
                                        name="oneWayInterviewLink"
                                        onChange={(e) => handleChange(e.target.name, e.target.value)}
                                    />
                                    <label>Interview Link</label>
                                    { profileValidation.interviewLinkError && <small className="validation ml-1">{profileValidation.interviewLinkError}</small>}
                                </div>                               
                            </div>                                                                       
                        </div> */}
              <div className="row mb-2 mt-4">
                <div className="col-lg-7">
                  <div className="form-floating">
                    <h6 className="ml-2 mb-0">Candidate Location</h6>
                    <HereMap
                      setForm={setForm}
                      form={form}
                      setCity={(city) =>
                        setContactInfo({ ...contactInfo, location: city })
                      }
                      // component={'Candidate'}
                    />
                  </div>
                </div>
                {contactInfo.location && (
                  <div className="col-lg-5 mt-1">
                    <p className="ml-2 mb-0">Selected Location</p>
                    <span className="tag tag-blue3 mt-4 ml-3">
                      {contactInfo.location}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => {
            onCollapsibleClick("skills");
          }}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          {skills ? (
            <i className="fal fa-angle-up fa-fw mt-1"></i>
          ) : (
            <i className="fal fa-angle-down fa-fw"></i>
          )}
          <span>
            <small>Skills Matrix</small>
          </span>
        </button>
        <div className={skills ? "ml-3 ml-0-600 mt-2 mb-4" : "d-none"}>
          <div className="table-cards">
            <table className="table table-hoverable">
              <thead>
                <tr>
                  <th>
                    <h5 className="mb-1">Primary Skills</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                {primarySkills.map((q, index) => (
                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-lg-8">
                          <p className="mb-3 mt-2">{q.skill}</p>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex">
                            <div className="mr-2 form-floating">
                              <input
                                type="number"
                                className="form-control"
                                min="0"
                                style={{
                                  height: "45px",
                                  border: "1px solid #ced4da",
                                  fontSize: "12px",
                                }}
                                value={q.years}
                                name="years"
                                onChange={(e) =>
                                  handleChangePrimarySkills(
                                    index,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                placeholder="Year*"
                              />
                              <label>
                                Years<small>*</small>
                              </label>
                            </div>
                            <div className="mr-2 form-floating">
                              <input
                                type="number"
                                className={
                                  primarySkillValidation.includes(q.skill)
                                    ? "form-control border border-danger"
                                    : "form-control"
                                }
                                min="0"
                                style={{
                                  height: "45px",
                                  border: "1px solid #ced4da",
                                  fontSize: "12px",
                                }}
                                name="months"
                                value={q.months}
                                onChange={(e) =>
                                  handleChangePrimarySkills(
                                    index,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                placeholder="Months*"
                              />
                              <label>
                                Months<small>*</small>
                              </label>
                              {primarySkillValidation.includes(q.skill) && (
                                <small className="validation ml-2">
                                  Cannot Be {q.months === "0" ? "Zero" : "Null"}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className='form-floating w-100 mt-2'>
                                            <textarea 
                                                className='mb-2 form-control'
                                                value={q.candidateResponse}
                                                name='candidateResponse'
                                                onChange={(e) => handleChangePrimarySkills(index, e.target.name, e.target.value)}
                                            >
                                            </textarea>    
                                            <label>Response</label>         
                                        </div> */}
                    </td>
                    <td className="align-top no-hover">
                      <button
                        type="button"
                        className="btn btn-icon"
                        onClick={() => deletePrimarySkills(index)}
                      >
                        <i
                          className="fal fa-fw fa-trash-alt"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </td>
                  </tr>
                ))}
                {addPrimarySkill && (
                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="form-group">
                            <TagsInput
                              renderInput={autocompleteRenderInput}
                              inputClass="mb-1"
                              containerClass=""
                              value={primarySkillFinalInput}
                              onChange={(skills) => {
                                setPrimarySkillFinalInput(skills);
                              }}
                              inputValue={primarySkillInput}
                              onChangeInput={(skill) => {
                                setPrimarySkillInput(skill);
                              }}
                              disabled={primarySkillFinalInput.length > 0}
                              maxTags={1}
                              inputProps={{
                                placeholder:
                                  primarySkillFinalInput.length > 0
                                    ? ""
                                    : "Enter Skill*",
                              }}
                            />
                            {addPrimarySkillValidation.skill && (
                              <small className="validation ml-2">
                                {addPrimarySkillValidation.skill}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex">
                            <div className="mr-2 form-floating">
                              <input
                                type="number"
                                className="form-control"
                                min="0"
                                style={{
                                  height: "45px",
                                  border: "1px solid #ced4da",
                                  fontSize: "12px",
                                }}
                                value={temporaryPrimarySkill.years}
                                onChange={(e) => {
                                  handleTemporaryPrimarySkill(
                                    e.target.name,
                                    e.target.value
                                  );
                                  // console.log(e.target.value)
                                }}
                                name="years"
                                placeholder="Years*"
                              />
                              <label>
                                Years<small>*</small>
                              </label>
                            </div>
                            <div className="mr-2 form-floating">
                              <input
                                type="number"
                                min="0"
                                style={{
                                  height: "45px",
                                  border: "1px solid #ced4da",
                                  fontSize: "12px",
                                }}
                                className={
                                  addPrimarySkillValidation.months
                                    ? "form-control border border-danger"
                                    : "form-control"
                                }
                                name="months"
                                onChange={(e) =>
                                  handleTemporaryPrimarySkill(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                value={temporaryPrimarySkill.months}
                                placeholder="Months*"
                              />
                              <label>
                                Months<small>*</small>
                              </label>
                              {addPrimarySkillValidation.months && (
                                <small className="validation ml-2">
                                  {addPrimarySkillValidation.months}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <div className='form-floating w-100'>
                                            <textarea 
                                                className='form-control font-14' 
                                                name='candidateResponse'
                                                onChange={(e) => handleTemporaryPrimarySkill(e.target.name, e.target.value)}
                                                value={temporaryPrimarySkill.candidateResponse} 
                                            >
                                            </textarea>    
                                            <label>Response</label>         
                                        </div>                                          */}
                    </td>
                    <td className="align-top no-hover">
                      <button
                        type="button"
                        className="btn btn-icon"
                        onClick={deleteTemporaryPrimarySkill}
                      >
                        <i
                          className="fal fa-fw fa-trash-alt"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </td>
                  </tr>
                )}
                <div className="d-flex align-items-center mt-3 mb-5 wrap-reverse-370 flex-column-370 justify-content-center-370 justify-content-between-400">
                  <button
                    type="button"
                    onClick={() => setAddPrimarySkill(true)}
                    className="btn btn-sm btn-text ml-2 mr-2"
                    disabled={addPrimarySkill}
                  >
                    <i className="fal fa-plus mr-1"></i>
                    Add Skill
                  </button>

                  <div>
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={saveTemporaryPrimarySkill}
                      disabled={!addPrimarySkill}
                    >
                      <span>Save</span>
                    </button>
                  </div>
                </div>
              </tbody>
            </table>
          </div>
          <div className="table-cards">
            <table className="table table-hoverable">
              <thead>
                <tr>
                  <th>
                    <h5 className="mb-1">Secondary Skills</h5>
                  </th>
                </tr>
              </thead>
              <tbody>
                {secondarySkills.map((q, index) => (
                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-lg-8">
                          <p className="mb-3 mt-2">{q.skill}</p>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex">
                            <div className="mr-2 form-floating">
                              <input
                                type="number"
                                min="0"
                                className="form-control"
                                style={{
                                  height: "45px",
                                  border: "1px solid #ced4da",
                                  fontSize: "12px",
                                }}
                                value={q.years}
                                name="years"
                                onChange={(e) =>
                                  handleChangeSecondarySkills(
                                    index,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                placeholder="Years*"
                              />
                              <label>
                                Years<small>*</small>
                              </label>
                            </div>
                            <div className="mr-2 form-floating">
                              <input
                                type="number"
                                min="0"
                                className={
                                  secondarySkillValidation.includes(q.skill)
                                    ? "form-control border border-danger"
                                    : "form-control"
                                }
                                style={{
                                  height: "45px",
                                  border: "1px solid #ced4da",
                                  fontSize: "12px",
                                }}
                                name="months"
                                value={q.months}
                                onChange={(e) =>
                                  handleChangeSecondarySkills(
                                    index,
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                placeholder="Months*"
                              />
                              <label>
                                Months<small>*</small>
                              </label>
                              {secondarySkillValidation.includes(q.skill) && (
                                <small className="validation ml-2">
                                  Cannot Be {q.months === "0" ? "Zero" : "Null"}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="align-top no-hover">
                      <button
                        type="button"
                        className="btn btn-icon"
                        onClick={() => deleteSecondarySkills(index)}
                      >
                        <i
                          className="fal fa-fw fa-trash-alt"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </td>
                  </tr>
                ))}
                {addSecondarySkill && (
                  <tr>
                    <td>
                      <div className="row">
                        <div className="col-lg-8">
                          <div className="form-group">
                            <TagsInput
                              renderInput={autocompleteRenderInput}
                              value={secondarySkillFinalInput}
                              onChange={(skills) => {
                                setSecondarySkillFinalInput(skills);
                              }}
                              disabled={secondarySkillFinalInput.length > 0}
                              inputValue={secondarySkillInput}
                              onChangeInput={(skill) => {
                                setSecondarySkillInput(skill);
                              }}
                              maxTags={1}
                              inputProps={{
                                placeholder:
                                  secondarySkillFinalInput.length > 0
                                    ? ""
                                    : "Enter Skill*",
                              }}
                            />
                            {addSecondarySkillValidation.skill && (
                              <small className="validation ml-2">
                                {addSecondarySkillValidation.skill}
                              </small>
                            )}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="d-flex">
                            <div className="mr-2 form-floating">
                              <input
                                type="number"
                                min="0"
                                className="form-control"
                                style={{
                                  height: "45px",
                                  border: "1px solid #ced4da",
                                  fontSize: "12px",
                                }}
                                value={temporarySecondarySkill.years}
                                name="years"
                                onChange={(e) => {
                                  handleTemporarySecondarySkill(
                                    e.target.name,
                                    e.target.value
                                  );
                                }}
                                placeholder="Years*"
                              />
                              <label>
                                Years<small>*</small>
                              </label>
                            </div>
                            <div className="mr-2 form-floating">
                              <input
                                type="number"
                                min="0"
                                className={
                                  addSecondarySkillValidation.months
                                    ? "form-control border border-danger"
                                    : "form-control"
                                }
                                style={{
                                  height: "45px",
                                  border: "1px solid #ced4da",
                                  fontSize: "12px",
                                }}
                                name="months"
                                value={temporarySecondarySkill.months}
                                onChange={(e) =>
                                  handleTemporarySecondarySkill(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                placeholder="Months*"
                              />
                              <label>
                                Months<small>*</small>
                              </label>
                              {addSecondarySkillValidation.months && (
                                <small className="validation ml-2">
                                  {addSecondarySkillValidation.months}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="align-top no-hover">
                      <button
                        type="button"
                        className="btn btn-icon"
                        onClick={deleteTemporarySecondarySkill}
                      >
                        <i
                          className="fal fa-fw fa-trash-alt"
                          aria-hidden="true"
                        ></i>
                      </button>
                    </td>
                  </tr>
                )}
                <div className="d-flex align-items-center mt-3 mb-5 wrap-reverse-370 flex-column-370 justify-content-center-370 justify-content-between-400">
                  <button
                    type="button"
                    onClick={() => setAddSecondarySkill(true)}
                    className="btn btn-sm btn-text ml-2 mr-2"
                    disabled={addSecondarySkill}
                  >
                    <i className="fal fa-plus mr-1"></i>
                    Add Skill
                  </button>

                  <div>
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={saveTemporarySecondarySkill}
                      disabled={!addSecondarySkill}
                    >
                      <span>Save</span>
                    </button>
                  </div>
                </div>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* SCREENING QUESTIONS          */}
      {QandA.length > 0 && (
        <button
          onClick={() => setDisplayQandA(!displayQandA)}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          {displayQandA ? (
            <i className="fal fa-angle-up fa-fw mt-1"></i>
          ) : (
            <i className="fal fa-angle-down fa-fw"></i>
          )}
          <span>
            <small>Screening Questions</small>
          </span>
        </button>
      )}
      <div className={displayQandA ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
        <ScreeningQuestions
          QandA={QandA}
          setQandA={setQandA}
          QandAValidation={QandAValidation}
        />
      </div>

      {vettingDetails.length > 0 && (
        <button
          onClick={() => setVetting(!vetting)}
          type="button"
          className="btn btn-collapsible mb-2"
        >
          {vetting ? (
            <i className="fal fa-angle-up fa-fw mt-1"></i>
          ) : (
            <i className="fal fa-angle-down fa-fw"></i>
          )}
          <span>
            <small>Vetting Requirement</small>
          </span>
        </button>
      )}
      <div
        className={
          vetting && vettingDetails.length > 0
            ? "ml-2 ml-0-600 mt-2 mb-6"
            : "d-none"
        }
      >
        <div className="card card-flat bg-gray4 m-3">
          <div className="card-body">
            {vettingDetails.length > 0 && (
              <>
                <div className="row mb-3 px-3">
                  <div className="col-lg-2">
                    <strong>Skill</strong>
                  </div>
                  <div className="col-lg-3">
                    <strong>Type</strong>
                  </div>
                  <div className="col-lg-3">
                    <strong>Difficulty</strong>
                  </div>
                  <div className="col-lg-3">
                    <strong>Duration</strong>
                  </div>
                </div>
                <hr />
                {vettingDetails.map((skill, index) => (
                  <div className="row mb-3 px-3">
                    <div className="col-lg-2">{skill.name}</div>
                    <div className="col-lg-3">
                      <select className="form-select font-14" disabled>
                        <option value="MCQ" selected={skill.type === "MCQ"}>
                          MCQ
                        </option>
                        <option
                          value="OneWay"
                          selected={skill.type === "OneWay"}
                        >
                          OneWay
                        </option>
                      </select>
                    </div>
                    <div className="col-lg-3">
                      <select className="form-select font-14" disabled>
                        {["Simple", "Medium", "Advanced"].map((item) => (
                          <option
                            value={item}
                            selected={
                              skill.difficulty === item ||
                              (item === "Simple" && !skill.difficulty)
                            }
                          >
                            {item}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-lg-3 d-flex">
                      <select className="form-select font-14" disabled>
                        {["15 mins", "30 mins", "45 mins", "60 mins"].map(
                          (item) => (
                            <option
                              value={item}
                              selected={
                                skill.duration === item ||
                                (item === "15 mins" && !skill.duration)
                              }
                            >
                              {item}
                            </option>
                          )
                        )}
                      </select>
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          onCollapsibleClick("resume");
        }}
        type="button"
        className="btn btn-collapsible mb-2"
      >
        {resume ? (
          <i className="fal fa-angle-up fa-fw mt-1"></i>
        ) : (
          <i className="fal fa-angle-down fa-fw"></i>
        )}
        <span>
          <small>Resume *</small>
        </span>
      </button>
      <div className={resume ? "ml-2 ml-0-600 mt-2 mb-6" : "d-none"}>
        {resumeFileURL && (
          <div className="card card-flat ml-4 ml-0-600">
            <div className="card-body">
              <div className="ml-4 mr-5 mb-2">
                <div className="mb-2 mt-2" style={{ marginTop: "10px" }}>
                  <div>
                    {/* <a 
                                            onClick={(e) => {
                                                e.preventDefault()
                                                window.open(resumeFileURL, '_blank')
                                            }} 
                                            href='' 
                                            title='View Resume'
                                        >
                                            Resume
                                        </a>   */}
                    <button
                      className="btn btn-sm btn-secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(resumeFileURL, "_blank");
                      }}
                    >
                      View Resume
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="ml-4 mt-3">
          {resumeBase64 && (
            <span className="d-flex">
              Uploaded
              <FaCheckCircle
                className="ml-2"
                style={{ color: "green", height: "1.2rem", width: "1.2rem" }}
              />
              <button
                type="button"
                onClick={() => {
                  setResumeFileName("");
                  setResumeBase64("");
                }}
                className="btn btn-icon ml-auto"
              >
                <i className="fa fa-trash mr-3"></i>
              </button>
            </span>
          )}
          <div className="ml-4 mt-3">
            {!resumeBase64 && (
              <Dropzone onDrop={onUploadHandler} multiple={false}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()}>
                    <button type="button" className="btn btn-text-accent mr-1">
                      <i
                        aria-hidden="true"
                        className="far fa-cloud-upload mr-1 fa-fw"
                      ></i>
                      <span>
                        {" "}
                        {resumeFileURL ? "Replace/Upload New Resume" : "Upload"}
                      </span>
                    </button>
                    <input
                      type="file"
                      {...getInputProps()}
                      accept=".docx, .pdf, application/msword"
                    />
                  </div>
                )}
              </Dropzone>
            )}
          </div>
        </div>
        {/* <h6 className='ml-5 mt-5'>Instructions:</h6>
            <div className="ml-2 ml-0-600 mt-2 mb-4" >
                <div className="card card-flat ml-4 ml-0-600">
                    <div className="card-body">
                        <ol className='m-3' style={{
                            fontSize: '12px'
                        }}>
                            <li>
                                The skills matrix will be to capture simple, easy to ask and easy to document candidate answers to interview questions asked by the recruiters.
                            </li>
                            <li>
                                The recruiter screening questions and candidate responses, both which align to must have skills captured during the intake process, are not meant to be technical questions or responses.
                            </li>
                            <li>
                                Years of experience within each skill area and when and where the candidates used the skills is the primary focus of the skills matrix.
                            </li>
                            <li>
                                Open-ended questions, compound questions, and complex technical questions, which typically require longer duration responses, will be captured in 1-way or 2-way interview processes.
                            </li>
                            <li>
                            Please do not copy and paste information from the resume as it is obvious to the clients when they review.
                            </li>
                            <li>
                                Please provide a complete 1-2 sentence response in the candidate response column when applicable.
                            </li>
                        </ol>
                    </div>
                </div>
            </div> */}
      </div>
      {inEligible && (
        <div>
          <hr />
          <div className="text-center mt-2 px-2">
            <div className="avatar avatar-lg avatar-red2 mr-3">
              <i class="fas fa-exclamation-triangle fa-fw"></i>
            </div>
            <p className="text-center mt-3">
              This Candidate does not fullfill all the requirements of the job.
              <br />
              Click 'Confirm' if you still want to source
            </p>
            <div class="text-center my-4">
              <button
                type="button"
                className="btn btn-secondary mr-3"
                onClick={() => {
                  setInEligible(false);
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  setInEligible(false);
                  setConfirmSource(true);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
      <hr />
      <div className="d-flex justify-content-end">
        <button className="btn btn-secondary mr-1" onClick={closeModal}>
          Close
        </button>
        {component === "Automatch" ? null : (
          <button
            className="btn btn-info mr-1"
            onClick={() => {
              let { location, workAuthorization } = contactInfo;
              let jobDetails = {
                ...job,
                primarySkills: primarySkills.map((i) => i.skill),
                secondarySkills: secondarySkills.map((i) => i.skill),
              };
              let candidateDetails = {
                ...candidate,
                addressCity: location,
                visaStatus: workAuthorization,
              };
              setScore(jobDetails, candidateDetails);
            }}
          >
            Check Score
          </button>
        )}
        <button
          className="btn btn-primary"
          disabled={submit}
          onClick={() => {
            submitTalent();
          }}
          // onClick={() => console.log(job)}
        >
          {submit ? "Submitting" : "Submit"}
          {submit && <img src={ButtonLoader} width="20px" alt="loading" />}
        </button>
      </div>
    </>
  );
}

export default SubmitTalentModal;
