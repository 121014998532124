import React, { useState, useEffect } from "react";
import _ from "lodash";
import { toast } from "react-toastify";

function FullDataSort({
  sortData,
  sortObjects,
  applySort,
  sortingFor,
  orderArray,
  setSortCount,
  sortCount,
  setSortArray,
  sortArray,
  isFrom,
}) {
  const [sortType, setSorttype] = useState([]);
  const [id, setId] = useState(sortCount);

  useEffect(() => {
    const tempArray = sortObjects.map((item) => item.value);
    setSorttype(tempArray);
  }, []);

  const addSortHandle = () => {
    const tempId = id + 1;
    const sortObj = {
      id: tempId,
      sortValue: "",
      order: "asc",
    };
    let newArray = [...sortArray, sortObj];
    setSortArray(newArray);
    let array3 = sortObjects.filter(
      (entry1) => !newArray.some((entry2) => entry1.value === entry2.sortValue)
    );
    let tempArray = array3.map((item) => item.value);
    setSorttype(tempArray);
    setId(tempId);
  };

  const handleSortValue = (e, i) => {
    let newArray = [...sortArray];
    newArray[i] = {
      ...newArray[i],
      [e.target.id]: e.target.value,
    };
    setSortArray(newArray);
    let tempArray = sortType.filter((item) => item !== e.target.value);
    setSorttype(tempArray);
  };

  const deleteSort = (data) => {
    let tempArray = sortArray.filter((item) => item.id != data.id);
    setSortArray(tempArray);
    setSorttype([...sortType, data.sortValue]);
    setSortCount(sortCount - 1);
  };

  const sortClearAll = () => {
    setSortArray([]);
    const tempArray = sortObjects.map((item) => item.value);
    setSorttype(tempArray);
    setSortCount(0);
    applySort(sortData, true);
  };

  const apply = () => {
    let val = sortArray.filter((item) => !item.sortValue);

    if (val.length !== 0) {
      toast.error("Please fill all the fields");
    } else {
      let sort = _.orderBy(
        sortData,
        sortArray.map((item) => item.sortValue),
        sortArray.map((item) => item.order)
      );
      applySort(sort, true);
      setSortCount(sortArray.length);
    }
  };

  return (
    <div class="dropdown-sort-menu dd-sort">
      <div class="dd-sort-header d-flex justify-content-between align-items-center dd-sort">
        <div>
          <h6>Sort</h6>
        </div>
        <div>
          <button
            type="button"
            className="btn btn-text dd-sort"
            onClick={sortClearAll}
          >
            {sortArray.length > 0 && (
              <strong className="dd-sort">Clear All</strong>
            )}
          </button>
        </div>
      </div>
      <div className="dd-sort-body">
        {sortArray.length > 0 ? (
          sortArray.map((item, i) => (
            <div className="d-flex align-items-center justify-content-end mt-3">
              <div className="dropdown dropdown-select mr-2 dd-sort w-100">
                <div class="row mb-2">
                  <div class="col-lg-6">
                    <div class="form-floating-sm">
                      <select
                        class="form-select"
                        id="sortValue"
                        value={item.sortValue}
                        onChange={(e) => handleSortValue(e, i)}
                      >
                        <option value="" selected>
                          Select
                        </option>
                        {sortObjects.map((val) => (
                          <option
                            value={val.value}
                            hidden={sortType && !sortType?.includes(val.value)}
                          >
                            {val.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="form-floating-sm">
                      <select
                        class="form-select"
                        id="order"
                        value={item.order}
                        onChange={(e) => handleSortValue(e, i)}
                      >
                        {orderArray.map((val) => (
                          <>
                            <option value={val.value}>{val.label}</option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-1" style={{ padding: "8px 0px" }}>
                    <button
                      type="button"
                      className="btn btn-text dd-sort"
                      onClick={() => deleteSort(item)}
                    >
                      <strong className="dd-sort font-muted">
                        <i className="fas fa-times-circle dd-sort"></i>
                      </strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="mb-0 py-3 dd-sort">{sortingFor}</p>
        )}
      </div>
      <div className="d-flex">
        <button
          type="button"
          className="btn btn-text mt-4 dd-sort dd-hide-1024"
          onClick={addSortHandle}
          disabled={
            sortArray.length === sortObjects.length ||
            (isFrom == "candidateprofile" && sortArray.length == 1)
          }
        >
          <i className="fal fa-plus mr-2 dd-sort" aria-hidden="true"></i>
          <strong className="dd-sort">Add Sort</strong>
        </button>
        {sortArray.length > 0 && (
          <div className="p-relative ml-auto mt-4">
            <div className="d-flex">
              <button
                onClick={apply}
                type="button"
                className="btn btn-sm btn-primary"
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FullDataSort;
