import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const JoinTalentBtn = ({ setCheckEmail }) => {
  const { recruiterid } = useParams();
  return (
    <div
      className={`${
        sessionStorage.getItem("token") == null
          ? "refer_container"
          : "join_our_community_container"
      } `}
    >
      <div className="main_apply_container_apply">
        <p style={{ fontWeight: "bold", fontSize: "10px" }}>
          Not the right fit? Stay connected for future opportunities. Join Now!
        </p>
        <button
          className="main_apply_container_apply_btn1"
          style={{ border: "none" }}
          // onClick={() => {
          //   setCheckEmail(true);
          // }}
          onClick={() => {
            window.open(
              `/careers/8/recruiter/${recruiterid}/newjoincommunity`,
              "_blank"
            );
          }}
        >
          <span>Talent Community</span>
        </button>
      </div>
    </div>
  );
};

export default JoinTalentBtn;
