import React from "react";
import { useSelector, shallowEqual } from "react-redux";

function Education(props) {
  const inputDataObj = {
    graduatedYear: "",
    educationType: "",
    educationProgram: "",
    school: "",
    major: "",
  };
  const educationTypes = [
    "Bachelor's Degree",
    "Certification",
    "Doctorate",
    "Highschool or Equivalent",
    "Master's Degree",
    "NA",
    "Professional",
    "Some College Coursework Completed",
    "Some Highschool Course Work",
    "Vocational",
  ];

  const inputValObj = {
    graduatedYear: "",
    educationType: "",
    educationProgram: "",
    school: "",
    major: "",
  };

  const { dropDownData } = useSelector(
    (state) => ({
      dropDownData: state.AddCandidate.dropDownData,
    }),
    shallowEqual
  );

  const newDate = new Date();
  let currentYear = newDate.getFullYear();
  const min = currentYear - 50;
  const max = currentYear;
  const allYears = [];
  for (let x = min; x <= max; x++) {
    allYears.push(x);
  }

  const handleChange = (e, i) => {
    const newArray = [...props.educations];
    newArray[i] = { ...newArray[i], [e.target.id]: e.target.value };
    props.setEducations(newArray);
  };

  // Delete Education
  const handleDelete = (e, i) => {
    props.setEducations(props.educations.filter((item, index) => index !== i));
    props.setEducationValidation(
      props.educationValidation.filter((item, index) => index !== i)
    );
  };

  // Add Education
  const handleAdd = () => {
    props.setEducations([...props.educations, inputDataObj]);
    props.setEducationValidation([...props.educationValidation, inputValObj]);
  };

  return (
    <div>
      <div class="education">
        {props.educations.map((education, i) => (
          <div class="d-flex" v-for="item in list">
            <div class="mr-3">
              <div class="avatar avatar-sm">{i + 1}</div>
            </div>
            <div>
              <div class="row mb-2">
                <div class="col-lg-6">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="graduatedYear"
                      style={{
                        border:
                          props.educationValidation[i]?.graduatedYear &&
                          "2px solid #ff0000",
                      }}
                      value={education.graduatedYear}
                      onChange={(e) => handleChange(e, i)}
                    >
                      <option value="" selected>
                        Select Year
                      </option>
                      {allYears.map((x) => (
                        <option value={x}>{x}</option>
                      ))}
                    </select>
                    <label>Year Graduated</label>
                  </div>
                  <small className="validation">
                    {props.educationValidation[i]?.graduatedYear}
                  </small>
                </div>
                <div class="col-lg-6">
                  <div class="form-floating">
                    <select
                      class="form-select"
                      id="educationType"
                      value={education.educationType}
                      style={{
                        border:
                          props.educationValidation[i]?.educationType &&
                          "2px solid #ff0000",
                      }}
                      onChange={(e) => handleChange(e, i)}
                    >
                      <option value="" selected>
                        Select Type
                      </option>
                      {educationTypes.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    <label>Type of Education</label>
                  </div>
                  <small className="validation">
                    {props.educationValidation[i]?.educationType}
                  </small>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Education Program"
                      id="educationProgram"
                      style={{
                        border:
                          props.educationValidation[i]?.educationProgram &&
                          "2px solid #ff0000",
                      }}
                      value={education.educationProgram}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <label>Education Program</label>
                  </div>
                  <small className="validation">
                    {props.educationValidation[i]?.educationProgram}
                  </small>
                </div>
                <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="School"
                      id="school"
                      style={{
                        border:
                          props.educationValidation[i]?.school &&
                          "2px solid #ff0000",
                      }}
                      value={education.school}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <label>School</label>
                  </div>
                  <small className="validation">
                    {props.educationValidation[i]?.school}
                  </small>
                </div>
                <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Major"
                      id="major"
                      style={{
                        border:
                          props.educationValidation[i]?.major &&
                          "2px solid #ff0000",
                      }}
                      value={education.major}
                      onChange={(e) => handleChange(e, i)}
                    />
                    <label>Major</label>
                  </div>
                  <small className="validation">
                    {props.educationValidation[i]?.major}
                  </small>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <hr />
                </div>
              </div>
            </div>
            <div class="ml-3">
              {props.educations.length !== 1 && (
                <button
                  type="button"
                  class="btn btn-icon"
                  onClick={(e) => handleDelete(e, i)}
                >
                  <i class="fal fa-fw fa-trash-alt"></i>
                </button>
              )}
            </div>
          </div>
        ))}

        <div>
          <button type="button" onClick={handleAdd} class="btn btn-sm btn-text">
            <i class="fal fa-plus mr-1" aria-hidden="true"></i>
            <strong>Add Education</strong>
          </button>
        </div>
      </div>
    </div>
  );
}
export default Education;
