import React from "react";
import Modal from "react-bootstrap/esm/Modal";

const AdditionalQuestions = (props) => {
  const { jobData, setQNA, qna } = props;
  return (
    <Modal size="lg" show={qna}>
      <Modal.Header>
        <h6 class="">Additional Info</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setQNA(false)}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {jobData?.additionalInfo &&
        JSON.parse(jobData?.additionalInfo).length > 0 ? (
          <div class="d-flex mb-2 pl-1 ml-4">
            <ul>
              {JSON.parse(jobData?.additionalInfo).map((item, index) => (
                <li className="d-flex m-3">
                  <div className="">
                    <span className="col-lg-1">{index + 1}</span>
                  </div>
                  <div className="ml-3 mb-2">
                    <span className="col-lg-11 mb-1">
                      {item.question.replace("[X]", item?.selectedOption)}
                    </span>
                    <br />
                    <small>
                      Response Type:{" "}
                      {item?.input?.length > 0 ? (
                        <strong> Selective</strong>
                      ) : item?.name === "Salary Requirements" ? (
                        <strong> Range</strong>
                      ) : (
                        <strong> Text</strong>
                      )}
                    </small>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="mb-3">
            <div class="text-center p-3">
              <div class="avatar avatar-lg">
                <i class="fad fa-users-slash"></i>
              </div>
              <div class="mt-2">No Additional Info</div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button variant="secondary" onClick={() => setQNA(false)}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AdditionalQuestions;
