import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import { useHistory, withRouter } from "react-router-dom";
import Loader from "../../Loader/Loader";

const AdditionalQuestions = ({
  screeningData,
  idCandidate,
  candidateDetails,
  dispositionID,
  job,
  InviteTalentWithVet,
  setIsScreening,
  setIsRTHScreening,
  submitCandidate,
  screeningQuestions,
  setScreeningQuestions,
  salaryValues,
  setSalaryValues,
  errorData,
}) => {
  const history = useHistory();

  const [answers, setAnswers] = useState(
    screeningQuestions.map((q) => q.answer)
  );

  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [visaValue, setVisaValue] = useState("")

  useEffect(() => {
    const data = screeningData.map((x) => ({
      ...x,
      name: x.name,
      question: x?.question.replace("[X]", x?.selectedOption),
      answer: "",
    }));
    setScreeningQuestions(data);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize); // Add event listener
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, []);

  const handleAnswerChange = (index, answer, item) => {
    if (item?.name === "Salary Requirements") {
      const [minValue, maxValue] = answer.split(",");
      setSalaryValues({ ...salaryValues, min: minValue, max: maxValue });
    }
    const newAnswers = [...screeningQuestions];
    newAnswers[index]["answer"] = answer;
    setScreeningQuestions(newAnswers);
    console.log("newAnswers", newAnswers);
  };

  const handleVisaStatusChange = (index, value) => {
    setVisaValue(value)
    const newAnswers = [...screeningQuestions];
    if (value === "Other") {
      newAnswers[index]["answer"] = ""; // Clear the answer
    } else {
      newAnswers[index]["answer"] = value; // Set the answer to the selected option
    }
    console.log("newAnswers", newAnswers);
    setScreeningQuestions(newAnswers);
  };

  const screeningSubmit = async () => {
    let status = 42; // Engaged

    if (screeningQuestions.some((x) => x.answer === ""))
      return toast.error("Please answer all Additional Info questions");
    else if (screeningQuestions.some((x) => x.answer === "No")) status = 12; //disqualified
    await submitCandidate(status);
  };

  return (
    <div className=" mt-5">
      {loading && <Loader />}

      <div
        className="questions"
        style={{
          display: "grid",
          gap: "20px",
          gridTemplateColumns: "1fr",
          gridTemplateRows: isMobile ? "auto" : "1fr",
        }}
      >
        {screeningQuestions.map((question, index) => (
          <div
            key={index}
            style={{
              display: "grid",
              gridTemplateColumns: isMobile ? "1fr" : "auto auto",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <p style={{ marginBottom: isMobile ? "10px" : "0" }}>
              <span className="mr-2">{index + 1}.</span> {question.question}
            </p>
            {question?.input?.length > 0 && question?.name !== "Visa Status" && (
              <div className=" d-flex  justify-content-end   w-100">
                <select
                  className="form-select"
                  value={screeningQuestions[index]["answer"]}
                  onChange={(e) =>
                    handleAnswerChange(index, e.target.value, question)
                  }
                  style={{ width: isMobile ? "100%" : "300px" }}
                >
                  <option value="">Select</option>
                  {question?.input?.map((e, index) => (
                    <option key={index}>{e}</option>
                  ))}
                </select>
              </div>
            )}
            {question?.name === "Visa Status" && (
              <div className="d-flex justify-content-end w-100">
                <select
                  className="form-select"
                  value={visaValue}
                  onChange={(e) =>
                    handleVisaStatusChange(index, e.target.value)
                  }
                  style={{ width: isMobile ? "100%" : "300px" }}
                >
                  <option value="">Select</option>
                  {question?.input?.map((e, index) => (
                    <option key={index}>{e}</option>
                  ))}
                </select>
                {visaValue =='Other' && (
                  <input
                    className="form-control ml-3"
                    type="text"
                    value={screeningQuestions[index]["answer"]}
                    placeholder="Enter visa"
                    onChange={(e) =>
                      handleAnswerChange(index, e.target.value, question)
                    }
                    style={{ width: isMobile ? "100%" : "300px" }}
                  />
                )}
              </div>
            )}
            {question?.name === "Salary Requirements" && (
              <div className=" d-flex  justify-content-end   w-100">
                <div>
                  <input
                    className="form-control mr-3"
                    type="text"
                    value={screeningQuestions[index]["answer"].split(",")[0]} // Get the first part (min value)
                    placeholder="min"
                    onChange={(e) => {
                      const newValue = e.target.value.trim(); // Trim any leading or trailing spaces
                      const newMinValue = parseInt(newValue); // Parse the entered value as an integer
                      if (
                        newValue === "" ||
                        (!isNaN(newMinValue) && newMinValue > 0)
                      ) {
                        // Check if the value is empty or a positive integer
                        const newAnswer = `${newValue},${screeningQuestions[
                          index
                        ]["answer"].split(",")[1] || ""}`;
                        handleAnswerChange(index, newAnswer, question);
                      }
                    }}
                    style={{ width: isMobile ? "100%" : "140px" }}
                  />
                </div>

                <div>
                  <input
                    className="form-control"
                    type="text"
                    min={0}
                    value={screeningQuestions[index]["answer"].split(",")[1]} // Get the second part (max value)
                    placeholder="max"
                    onChange={(e) => {
                      const newValue = e.target.value.trim(); // Trim any leading or trailing spaces
                      const newMinValue = parseInt(newValue); // Parse the entered value as an integer
                      if (
                        newValue === "" ||
                        (!isNaN(newMinValue) && newMinValue > 0)
                      ) {
                        const newAnswer = `${screeningQuestions[index][
                          "answer"
                        ].split(",")[0] || ""},${newValue}`;
                        handleAnswerChange(index, newAnswer, question);
                      }
                    }}
                    style={{ width: isMobile ? "100%" : "140px" }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AdditionalQuestions;
