import {
    GET_SC_LS_FILTER_BEGIN,
    GET_SC_LS_FILTER_SUCCESS,
    GET_SC_LS_FILTER_FAILURE,
  } from "../../../actions/directsourcing/talentcommunity/LicenseState";
  
  const initialState = {
    loading: false,
    licenseStateData: null,
  };
  
  const getSCTalentLicenseStateDataReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_SC_LS_FILTER_BEGIN:
        return {
          ...state,
          loading: true,
          licenseStateData: null,
        };
      case GET_SC_LS_FILTER_SUCCESS:
        return {
          ...state,
          licenseStateData: payload,
          loading: false,
        };
      case GET_SC_LS_FILTER_FAILURE:
        return {
          ...state,
          licenseStateData: payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default getSCTalentLicenseStateDataReducer;
  