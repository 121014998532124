import { userToken, adminUserToken } from "../constants/Constants";

export const isAuthenticatedUser = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  if (Object.entries(userTkn).length === 0 || ifTokenExpired(userTkn)) {
    return false;
  }
  return true;
};

const ifTokenExpired = (userToken) => {
  const expiry = Date.parse(userToken.expiration);
  if (expiry > Date.now()) {
    return false;
  }
  return true;
};

export const loginUserName = () => {
  const adminUserTkn = JSON.parse(localStorage.getItem(adminUserToken)) || {};
  const adminUserName =
    Object.entries(adminUserTkn).length !== 0 && !ifTokenExpired(adminUserTkn)
      ? adminUserTkn.candidateName
      : "";
  const loginUserName =
    (adminUserName?.length > 1
      ? adminUserName
      : localStorage.getItem("user")) || "";
  return loginUserName;
};

export const loginUserEmail = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userEmail =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.userName
      : "";
  const adminUserTkn = JSON.parse(localStorage.getItem(adminUserToken)) || {};
  const adminUserEmail =
    Object.entries(adminUserTkn).length !== 0 && !ifTokenExpired(adminUserTkn)
      ? adminUserTkn.userName
      : "";
  const loginUserName = adminUserEmail?.length > 1 ? adminUserEmail : userEmail;
  return loginUserName || userEmail;
};

export const getUserToken = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const token =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn
      : "";
  return token;
};

export const getUserTokenFromSession = () => {
  const userTkn = sessionStorage.getItem("token") || {};
  // const token =
  //   Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
  //     ? userTkn
  //     : "";
  const token = {
    token: userTkn,
  };
  return token;
};

export const getOwner = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const owner =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.isOwner
      : false;
  return owner;
};
export const getUserLastUpdated = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userLastUpdated =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.lastUpdated
      : "";
  return userLastUpdated;
};
export const getUserRole = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userRole =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.role
      : "";
  return userRole;
};

export const getActualRole = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userRole =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.actualRole
      : "";
  return userRole;
};

export const getActualName = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const actualName =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.actualName
      : "";
  return actualName;
};

export const getActualEmail = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const actualEmail =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.actualEmail
      : "";
  return actualEmail;
};

export const getUserName = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userName =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.userName
      : "";
  return userName;
};

export const getCandidateName = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const candidateName =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.candidateName
      : "";
  return candidateName;
};
export const getUserId = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userId =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.userId
      : "";
  return userId;
};
export const getCandidateId = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userId =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.candidateId
      : "";
  return userId;
};
export const getUserTenant = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userTenant =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.userTenant
      : "";
  return userTenant;
};

export const getTenantType = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const tenantType =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.tenantType
      : "";
  return tenantType;
};

export const getUserTenantID = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userTenantid =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.tenantid
      : "";
  return userTenantid;
};

export const getUserEmail = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const userEmail =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.userName
      : "";
  return userEmail;
};

export const getTenantLogo = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const logo =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.logo
      : "";
  return logo;
};

export const updateLastUpdated = () => {
  let userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  if (Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)) {
    userTkn = {
      ...userTkn,
      lastUpdated: new Date(),
    };
    localStorage.setItem(userToken, JSON.stringify(userTkn));
    return true;
  }
  return false;
};

export const isCurator = () => {
  const userTkn = JSON.parse(localStorage.getItem(userToken)) || {};
  const curator =
    Object.entries(userTkn).length !== 0 && !ifTokenExpired(userTkn)
      ? userTkn.curator
      : "";
  return curator;
};
