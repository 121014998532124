import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import AddTenant from "../../../../superadmin/dashboard/AddTenant";
import TenantDetailsPopUp from "../../../../superadmin/popups/TenantDetailsPopUp";
import ActivateTenant from "../../../../superadmin/popups/ActivateTenant";
import DeleteTenant from "../../../../superadmin/popups/DeleteTenant";
import EditTenant from "../../../../superadmin/dashboard/EditTenant";
import { getUserRole } from "../../../../../service/AuthService";
import Pagination from "../../../../components_v3/pagination/pagination";
import { SidebarLogo } from "../../../../../constants/Constants";
import AssignCurator from "../../../curator/assigncurator/Index";
import EditCurator from "../../../curator/EditCurator/EditCurators";
import Sort from "../../../commoncomponents/sort/Index";
import { clientUsersObj, orderList } from "../../../pagedata/PageData";

const UserOverview = ({
  isFrom,
  prefix,
  getClients,
  getTenantData,
  handlePageChange,
  currentPage,
  handleSizeChange,
  dataTotal,
  dataSize,
  clientList,
  handleSearch,
  searchData,
  fetchData,
  sortArray,
  setSortArray,
  applySort,
}) => {
  const [editTenant, setEditTenant] = useState(false);
  const [tenantObj, settenantObj] = useState({});
  const [selectedTenantObj, setSelectedTenantObj] = useState({});
  const [DeleteRequestValue, setDeleteRequestButton] = useState(false);
  const [addTenant, setAddTenant] = useState(false);
  const [selectedtenant, setSelectedTenant] = useState();
  const [selecteduser, setSelectedUser] = useState();
  const [activateUserValue, setActivateUserValue] = useState(false);
  const [openTenantPopUP, setopenTenantPopUP] = useState(false);
  const [tenantEmail, setTenantEmail] = useState();
  const [tenantType, setTenantType] = useState("");
  const [assignCuratorShow, setAssignCuratorShow] = useState(false);

  //sort
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);
  const [showMore, setShowMore] = useState({});
  const maxItemsToShow = 2;

  const toggleShowMore = (index) => {
    setShowMore((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  useEffect(() => {
    const tempArray = clientUsersObj.map((item) => item.value);
    setSorttype(tempArray);
  }, []);

  const openAddTenant = () => {
    setAddTenant(true);
  };

  const closePopUP = () => {
    setAddTenant(false);
  };

  const openedittenant = (item) => {
    settenantObj(item);
    setEditTenant(true);
  };

  const closeEditPopUp = () => {
    setEditTenant(false);
  };

  const openTenantPopUPFun = (item) => {
    setopenTenantPopUP(true);
    settenantObj(item);
  };
  const closeTenantPopUP = () => {
    setopenTenantPopUP(false);
  };

  const DeleteRequestFun = (e, item) => {
    setTenantEmail(item.useradminaccount);
    setSelectedTenant(item.tenantname);
    setSelectedTenantObj(item);
    setSelectedUser(item.id_useraccessdetail);
    setTenantType(item.typename);
    setDeleteRequestButton(true);
  };

  const EnabletenantFun = (e, tenantName, item, userid, useradminaccount) => {
    setTenantEmail(useradminaccount);
    setSelectedTenant(tenantName);
    setSelectedTenantObj(item);
    setSelectedUser(userid);
    setActivateUserValue(true);
  };

  const DeleteRequestClose = (e) => {
    setDeleteRequestButton(false);
  };

  const EnableTenantClose = (e) => {
    setActivateUserValue(false);
  };

  const role = getUserRole();

  const sortClearAll = () => {
    setSortArray([]);
    const tempArray = clientUsersObj?.map((item) => item.value);
    setSorttype(tempArray);
    setSortCount(0);
    setSortApplied(false);
    setSort(false);
    fetchData(1, dataSize, searchData, []);
  };

  return (
    <div className="card mt-3">
      <div className="card-body">
        <div class="p-3">
          <div class=" mb-2 ">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h6 class="m-0">{!isFrom && prefix} Clients</h6>
              <div class="d-flex align-items-center justify-content-between">
                {getUserRole() == "CustomerSuccess" && (
                  <button
                    type="button"
                    class="btn btn-sm mr-2"
                    onClick={(e) => {
                      setAssignCuratorShow(true);
                    }}
                  >
                    <i class="fa fa-user mr-2" aria-hidden="true"></i> Manage
                    Curators
                  </button>
                )}
                {getUserRole() !== "High5Coordinator" && (
                  <button
                    onClick={openAddTenant}
                    type="button"
                    class="btn btn-sm"
                  >
                    <i class="fal fa-user-plus mr-2" aria-hidden="true"></i>Add
                    Client
                  </button>
                )}
              </div>
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <select
                  class="form-select mb-2"
                  onChange={handleSizeChange}
                  value={dataSize}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 d-flex justify-content-end">
                <div class="mb-2 mr-1 d-flex align-items-center justify-content-end col-md-8">
                  <div className="search-input-container">
                    <input
                      type="text"
                      placeholder="Search Clients"
                      className="form-control small font-14"
                      value={searchData}
                      onChange={handleSearch}
                    />
                  </div>
                  <Sort
                    sort={sort}
                    setSort={setSort}
                    sortCount={sortCount}
                    sortData={clientList}
                    orderArray={orderList}
                    sortObjects={clientUsersObj}
                    applySort={applySort}
                    setSortCount={setSortCount}
                    setSortArray={setSortArray}
                    sortArray={sortArray}
                    sortingFor={"Sort by Client, Admin, and Created On"}
                    sortType={sortType}
                    setSorttype={setSorttype}
                    sortApplied={sortApplied}
                    setSortApplied={setSortApplied}
                    sortClearAll={sortClearAll}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="pt-0 mb-3 table-cards-1024">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Client</th>
                  <th scope="col">Admin</th>
                  <th scope="col">Hiring Managers</th>
                  <th scope="col">Created on</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {clientList.map((item, i) => (
                  <tr key={i}>
                    <td>
                      <div className="mr-3 m-1 ml-0">
                        <img
                          src={`${item.logourl ||
                            SidebarLogo}?${new Date().getTime()}`}
                          width="75"
                          height="75"
                          className="p-2"
                          style={{
                            borderRadius: "50%",
                            objectFit: "contain",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                        />
                      </div>
                    </td>
                    <td
                      onClick={() => openTenantPopUPFun(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <div class="avatar-name-candidatestableview d-flex align-items-center">
                        <div>
                          <strong class="mb-0 me-2">
                            {item.tenantname.replace("GIG - ", "")}
                          </strong>
                        </div>
                      </div>
                    </td>
                    <td>{item.firstName + " " + item.lastName}</td>
                    <td>
                      {item?.hiringManagers &&
                        JSON.parse(item.hiringManagers)
                          ?.slice(
                            0,
                            showMore[i]
                              ? JSON.parse(item.hiringManagers)?.length
                              : maxItemsToShow
                          )
                          .map((manager, managerIndex) => (
                            <tr key={managerIndex}>
                              <td>
                                <div key={managerIndex}>{manager.email}</div>
                              </td>
                            </tr>
                          ))}
                      {JSON.parse(item.hiringManagers)?.length >
                        maxItemsToShow && (
                        <button
                          type="button"
                          className="btn btn-text"
                          onClick={() => toggleShowMore(i)}
                        >
                          {showMore[i] ? "Show Less" : "Show More"}
                        </button>
                      )}
                    </td>
                    <td>
                      {moment(new Date(item.createddatetime)).format(
                        "MM/DD/YYYY"
                      )}
                    </td>
                    <td>
                      {item.statusname === "Active" ? (
                        <span class="tag tag-blue2">Active</span>
                      ) : (
                        <span class="tag tag-red2">Deactivated</span>
                      )}
                    </td>
                    {role !== "Sales" && (
                      <td class="text-right no-hover">
                        <div class="d-flex align-items-center justify-content-end">
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="right"
                            overlay={
                              <div class="dropdown">
                                <div
                                  class="dropdown-menu dropdown-menu-right"
                                  style={{
                                    display: "revert",
                                    right: "0px",
                                    top: "10px",
                                  }}
                                >
                                  <button
                                    onClick={() => {
                                      openedittenant(item);
                                    }}
                                    type="button"
                                    class="dropdown-item"
                                    disabled={item.statusname !== "Active"}
                                  >
                                    <i class="fas fa-edit mr-2"></i> Edit
                                    <a />
                                  </button>
                                  {item.statusname === "Active" ? (
                                    <button
                                      type="button"
                                      class="dropdown-item"
                                      onClick={(e) => {
                                        DeleteRequestFun(e, item);
                                      }}
                                    >
                                      <i
                                        class="fa fa-trash mr-2"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Deactivate
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="dropdown-item"
                                      onClick={(e) => {
                                        EnabletenantFun(
                                          e,
                                          item.tenantname,
                                          item,
                                          item.id_useraccessdetail,
                                          item.useradminaccount
                                        );
                                      }}
                                    >
                                      <i
                                        class="fa fa-key mr-2"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Activate
                                    </button>
                                  )}
                                </div>
                              </div>
                            }
                          >
                            <button
                              type="button"
                              class="btn btn-icon dropdown-toggle"
                            >
                              <i class="far fa-ellipsis-v"></i>
                            </button>
                          </OverlayTrigger>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
            {clientList.length === 0 && (
              <div>
                <div class="text-center p-3">
                  <div class="avatar avatar-lg">
                    <i aria-hidden="true" class="fad fa-folder-open"></i>
                  </div>{" "}
                  <div class="mt-6">No records found</div>
                </div>
              </div>
            )}
            <hr class="m-0" />
          </div>
        </div>
      </div>{" "}
      <div className="mt-2 ml-3 mr-3">
        {clientList?.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalItems={dataTotal}
            itemsPerPage={dataSize}
            onPageChange={handlePageChange}
            limit={10}
          />
        )}
      </div>
      <Modal show={addTenant} size="lg">
        <AddTenant closeAdd={closePopUP} getTenantData={getTenantData} />
      </Modal>
      <Modal show={editTenant} onHide={closeEditPopUp} size="lg">
        <EditTenant
          closeAdd={closeEditPopUp}
          tenantlist={tenantObj.length !== 0 ? tenantObj : null}
          getTenantData={getTenantData}
          setopenTenantPopUP={setopenTenantPopUP}
        />
      </Modal>
      <Modal
        show={DeleteRequestValue === true}
        onHide={DeleteRequestClose}
        size="delete"
      >
        <div class="bl-modal-header pb-4" onClick={DeleteRequestClose}>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="close p-0 bl-modal-close-btn mb-1"
            style={{ marginTop: "-6px" }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <DeleteTenant
            handleSuccess={DeleteRequestClose}
            employeeDelete={"employeedelete"}
            tenantName={selectedtenant}
            userid={selecteduser}
            users={"users"}
            tenantEmail={tenantEmail}
            tenantObj={selectedTenantObj}
            tenantType={tenantType}
            getTenantData={getTenantData}
            isFrom={isFrom}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={activateUserValue === true}
        onHide={EnableTenantClose}
        size="active"
      >
        <div class="bl-modal-header pb-4">
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="close p-0 bl-modal-close-btn"
            style={{ marginTop: "-6px" }}
            onClick={EnableTenantClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <ActivateTenant
            handleSuccess={EnableTenantClose}
            employeeenable={"employeeenable"}
            tenantName={selectedtenant}
            userid={selecteduser}
            users={"users"}
            tenantEmail={tenantEmail}
            tenantObj={selectedTenantObj}
            tenantType={tenantType}
            getTenantData={getTenantData}
            isFrom={isFrom}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={openTenantPopUP}
        onHide={closeTenantPopUP}
        dialogclassName="view-panel show"
      >
        <TenantDetailsPopUp
          employee={tenantObj}
          closeModal={closeTenantPopUP}
          //handleSuccess={editFun}
          isFrom={isFrom}
          getTenantData={getTenantData}
          getClients={getClients}
          closeEditPopUp={closeEditPopUp}
          editTenant={editTenant}
          setopenTenantPopUP={setopenTenantPopUP}
        />
      </Modal>
      {assignCuratorShow && (
        <AssignCurator
          assignCuratorShow={assignCuratorShow}
          setAssignCuratorShow={setAssignCuratorShow}
          getTenantData={getTenantData}
        />
      )}
    </div>
  );
};

export default UserOverview;
