import React, { useEffect, useState } from "react";
import "../../css/custom.css";
import ReactToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import TopNavBar from "../layout/TopNavBar";
import SideNavBar from "../layout/SideNavBar";
import { get } from "../../service/ApiService";
import moment from "moment";
import ReportTopNavBar from "./ReportTopNavBar";
import { getTenantType } from "../../service/AuthService";
import Footer from "../layout/Footer";

function GigRecruiterReport() {
  const reportHeaders = [
    { id: 1, name: "Date of Source" },
    { id: 1, name: "Date of Submission" },
    { id: 2, name: "Job Title" },
    { id: 3, name: "Candidate Name" },
    { id: 4, name: "Candidate Email" },
    { id: 5, name: "Location" },
    { id: 6, name: "Rate" },
    { id: 7, name: "Gig Recruiter Name" },
  ];
  const [pageLimit, setPageLimit] = useState(3);
  const [toggle, setToggle] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [columns, setColumns] = useState([]);
  const [dateSearch, setDateSearch] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [mounted, setMounted] = useState(false);
  const [toDate, setToDate] = useState(new Date());
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  const fetchReportData = async () => {
    setLoading(true);
    try {
      let res = await get("/report/gigRecruiterReport");
      if (res.status === 200) {
        let gigRec = JSON.parse(localStorage.getItem("GIG RECRUITERS"));
        const tableData = res.data;
        const finalData = tableData.filter(
          (i) =>
            (i.rtrAcceptedDate !== "" || i.sourcedDate !== "") &&
            gigRec.includes(i.candidateTenant)
        );
        //console.log('RSPONSE')
        //console.log('finalData', finalData)
        setReportData(finalData);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again");
    }
  };

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  //! Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const handleDateSearch = () => {
    setMounted(true);
    let startDate = moment(new Date(fromDate)).format("MM/DD/YYYY");
    let endDate = moment(new Date(toDate)).format("MM/DD/YYYY");
    if (new Date(startDate) > new Date()) {
      toast.error(`'From Date' cannot be future date`);
    } else if (new Date(endDate) > new Date()) {
      toast.error(`'To Date' cannot be future date`);
    } else if (new Date(startDate) > new Date(endDate)) {
      toast.error(`'From Date' should not be greater than 'To Date'`);
    } else {
      const result = reportData.filter((item) => {
        let FilteredDate = moment(new Date(item.sourcedDate)).format(
          "MM/DD/YYYY"
        );
        return (
          new Date(startDate) <= new Date(FilteredDate) &&
          new Date(FilteredDate) <= new Date(endDate)
        );
      });
      console.log(new Date(toDate));
      setDateSearch(result);
      if (result.length == 0) {
        toast.error("No Record Found. Please select another date range");
      }
      setPaginationArray(
        new Array(Math.ceil(result.length / perPage))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(result.length / perPage))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageLimit)
      );
    }
  };

  const onChangeSelect = (e) => {
    if (e.target.value === "All") {
      setPerPage(dateSearch.length);
      setPaginationArray([1]);
      setPaginationGroup([1]);
    } else {
      const pageGroup = new Array(Math.ceil(dateSearch.length / e.target.value))
        .fill()
        .map((_, i) => i + 1)
        .slice(0, pageLimit);
      setPaginationArray(pageGroup);
      setPaginationGroup(pageGroup);
      setPerPage(Number(e.target.value));
    }
  };

  const getPaginatedData = () => {
    const startIndex = currentPage * perPage - perPage;
    const endIndex = startIndex + +perPage;
    return dateSearch.slice(startIndex, endIndex);
  };

  return (
    <div>
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <ReportTopNavBar handleToggale={toggleDiv} />
        <SideNavBar handleToggale={toggleDiv} />

        <div id="Content">
          <div className="section section-sm">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="card card-flat card-borderless p-4 mb-3">
                    <div>
                      {/* <div className='d-flex justify-content-end'>
                        <button className='btn btn-sm' onClick={() => window.open('/high5hire', '_self')}>Go To Dashboard</button>
                      </div> */}
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="ml-2.5">Submission Status Report</h5>
                      </div>
                      <div className="d-flex bd-highlight ml-2.5 mb-4">
                        <div
                          className="p-2 bd-highlight"
                          style={{ zIndex: "999" }}
                        >
                          <label htmlFor="start-date">
                            <h6 className="m-0 mb-1">From</h6>
                          </label>
                          <DatePicker
                            className="form-control"
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            onChangeRaw={(e) => e.preventDefault()}
                          />
                        </div>
                        <div className="p-2 bd-highlight">
                          <label htmlFor="end-date">
                            <h6 className="m-0 mb-1">To</h6>
                          </label>
                          <DatePicker
                            className="form-control"
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            onChangeRaw={(e) => e.preventDefault()}
                          />
                        </div>
                        <div className="p-2 align-self-end bd-highlight mt-10">
                          <button
                            className="btn btn-primary btn-sm"
                            type="submit"
                            onClick={() => handleDateSearch()}
                          >
                            Search
                          </button>
                        </div>
                        <div className="py-2 align-self-end bd-highlight">
                          {getPaginatedData().length > 0 && (
                            <ReactToExcel
                              className="btn btn-sm"
                              table="table-to-xls"
                              filename="GigReportExcel"
                              sheet="sheet 1"
                              buttonText={
                                <span>
                                  <i className="fas fa-file-download mr-2"></i>
                                  Download
                                </span>
                              }
                            ></ReactToExcel>
                          )}
                        </div>
                        <div
                          className="bd-highlight ml-auto mt-4"
                          style={{ width: "15rem", marginLeft: "0.6rem" }}
                        >
                          <h6 className="mt-1">Records</h6>
                          <select
                            className="form-select"
                            onChange={(e) => onChangeSelect(e)}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="All">All</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {getPaginatedData().length > 0 ? (
                      <div className="px-3 pt-0 table-cards-1024 mt-3">
                        <table
                          className="table table-hoverabletable-cards-1024"
                          id="table-to-xls"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <thead>
                            <tr>
                              {reportHeaders.map((header) => (
                                <th>{header.name}</th>
                              ))}
                            </tr>
                          </thead>
                          {getPaginatedData().map((item) => (
                            <tbody>
                              <tr>
                                <td>
                                  {moment(item.sourcedDate).format(
                                    "MM/DD/YYYY"
                                  )}
                                </td>
                                <td>
                                  {item.rtrAcceptedDate
                                    ? moment(item.rtrAcceptedDate).format(
                                        "MM/DD/YYYY"
                                      )
                                    : null}
                                </td>
                                <td>{item.jobTitle}</td>
                                <td>
                                  {item.candidateFname +
                                    " " +
                                    item.candidateLname}
                                </td>
                                <td>{item.candidateEmail}</td>
                                <td>
                                  {item.candidateLocation || "Data Not Found"}
                                </td>
                                <td>
                                  {item.payRate.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ","
                                  ) +
                                    " " +
                                    item.payRateCurrency}
                                </td>
                                <td>{item.recruiterName}</td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    ) : (
                      <div className="mt-3" style={{ visibility: "hidden" }}>
                        <div class="text-center p-5">
                          <div class="avatar avatar-lg">
                            <i aria-hidden="true"></i>
                          </div>
                          <div class="mt-2">{/* No Records Found  */}</div>
                        </div>
                      </div>
                    )}
                    {getPaginatedData().length > 0 && (
                      <div className="d-flex justify-content-end pt-4 px-1">
                        <div
                          className="ml-auto mx-auto-370 d-flex align-items-center"
                          // style={{ position: 'absolute', left: '85%' }}
                        >
                          <button
                            onClick={() => {
                              setPageType("down");
                              setCurrentPage((page) => page - 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === 1 ||
                              getPaginatedData().length === 0
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-left"></i>
                          </button>

                          {getPaginatedData().length > 0
                            ? paginationGroup.map((item) => (
                                <button
                                  type="button"
                                  className={
                                    currentPage === item
                                      ? "btn btn-text px-2 bg-dark text-white"
                                      : "btn btn-text px-2"
                                  }
                                  onClick={changePage}
                                >
                                  {item}
                                </button>
                              ))
                            : ""}

                          <button
                            onClick={() => {
                              setPageType("up");
                              setCurrentPage((page) => page + 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === paginationArray.slice(-1)[0] ||
                              getPaginatedData().length === 0
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-right"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </div>
  );
}

export default GigRecruiterReport;
