import {
    GET_CLIENT_LIST_BEGIN,
    GET_CLIENT_LIST_SUCCESS,
    GET_CLIENT_LIST_FAILURE
  } from "../../actions/executiveadmin/GetClient";
  
  const initialState = {
    clientListLoading: true,
    clientList: [],
  };
  
  const clientListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_CLIENT_LIST_BEGIN:
        return {
          ...state,
          clientListLoading: true,
          clientList: [],
        };
      case GET_CLIENT_LIST_SUCCESS:
        return {
          ...state,
          clientList: payload,
          clientListLoading: false,
        };
      case GET_CLIENT_LIST_FAILURE:
        return {
          ...state,
          clientList: [],
          clientListLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default clientListReducer;
  