import axios from "axios";
import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const GET_ASSESSMENT_BEGIN = "GET_ASSESSMENT_BEGIN";
export const GET_ASSESSMENT_SUCCESS = "GET_ASSESSMENT_SUCCESS";
export const GET_ASSESSMENT_FAILURE = "GET_ASSESSMENT_FAILURE";

export const getAssessments = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_ASSESSMENT_BEGIN,
    });
    const res = await post("/vetIntegration/getAssessments", params);

    if (res.status === 200) {
      dispatch({
        type: GET_ASSESSMENT_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_ASSESSMENT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
