import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import ContactInfo from "../manualentry/ContactInfo";
import ProfileInfo from "../manualentry/ProfileInfo";
import Experience from "../manualentry/Experience";
import Education from "../manualentry/Education";
import Certifications from "../manualentry/Certification";
import License from "../manualentry/License";
import imageLoader from "../../../../images/button-loader.gif";
import {
  contactInfoValidation,
  profileInfoValidation,
  experienceValidation,
  educationValidation,
  certificationValidation,
  licenseValidation,
} from "../../../validation/AddTalent";
import { checkEmptyObject } from "../../../function/CommonFunction";
import { domain } from "../../../../constants/Constants";
import Loader from "../../../Loader/Loader";
import {
  getCandidateName,
  getUserName,
  getUserTenant,
} from "../../../../service/AuthService";

function AddTalentManualEntry(props) {
  const { existingCandidateList } = props;
  const [contact, setContact] = useState(true);
  const [profileInfo, setProfileInfo] = useState(false);
  const [experience, setExperience] = useState(false);
  const [education, setEducation] = useState(false);
  const [certification, setCertification] = useState(false);
  const [license, setLicense] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [validationDone, setValidationDone] = useState({
    contact: true,
    profile: true,
    experience: true,
    certificate: true,
    education: true,
    license: true,
  });
  const recruiterName = getCandidateName();
  const recruiterEmail = getUserName();
  const tenantName = getUserTenant();
  const [isFormValid, setIsFormValid] = useState(false);

  const calculateExperience = [
    { name: "Intern", range: [0] },
    { name: "1-3 years", range: [1, 2] },
    { name: "3-5 years", range: [3, 4] },
    { name: "5-10 years", range: [5, 6, 7, 8, 9] },
    { name: "10+ years", range: [] },
  ];

  const findRange = (years, months) => {
    let totalMonths = Number(years) * 12 + Number(months);
    if (totalMonths >= 120) {
      return "10+ years";
    } else if (totalMonths < 12) {
      if (totalMonths === 0) {
        return "Intern";
      } else {
        return "Less than one year";
      }
    } else {
      let yearsExperience = Math.floor(totalMonths / 12);
      let finalValue = calculateExperience.find((i) =>
        i.range.includes(yearsExperience)
      ).name;
      return finalValue;
    }
  };

  const [load, setLoading] = useState(false);
  const { loading, dropDownData } = useSelector(
    (state) => ({
      loading: state?.AddCandidate?.loading,
      dropDownData: state?.AddCandidate?.dropDownData,
    }),
    shallowEqual
  );

  const onCollapsibleClick = (type) => {
    if (type === "contact") {
      if (contact === false) {
        setContact(true);
      } else {
        setContact(false);
      }
    }

    if (type === "profile") {
      if (profileInfo === false) {
        setProfileInfo(true);
      } else {
        setProfileInfo(false);
      }
    }

    if (type === "experience") {
      if (experience === false) {
        setExperience(true);
      } else {
        setExperience(false);
      }
    }

    if (type === "education") {
      if (education === false) {
        setEducation(true);
      } else {
        setEducation(false);
      }
    }

    if (type === "certification") {
      if (certification === false) {
        setCertification(true);
      } else {
        setCertification(false);
      }
    }

    if (type === "license") {
      if (license === false) {
        setLicense(true);
      } else {
        setLicense(false);
      }
    }
  };

  // Experience child Component
  const [experiences, setExperiences] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      isSelect: false,
    },
  ]);

  const [experienceVal, setExperienceVal] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    },
  ]);

  // Education Child Component
  const [educations, setEducations] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);

  const [educationVal, setEducationVal] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);

  // Certificate child  Component
  const [certifications, setCertifications] = useState([
    { certificationName: "", certificate: "", issueDate: "", expiryDate: "" },
  ]);

  const [certificationVal, setCertificationVal] = useState([
    { certificationName: "", certificate: "", issueDate: "", expiryDate: "" },
  ]);

  // License child  Component
  const [licenses, setLicenses] = useState([
    { licenseName: "", license: "", state: "", expiryDate: "" },
  ]);

  const [licenseVal, setLicenseVal] = useState([
    { licenseName: "", license: "", state: "", expiryDate: "" },
  ]);

  // Contact Info child  Component
  const [contactInfo, setContactInfo] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    zipcode: "",
    address: "",
    addressCity: "",
    addressState: "",
    country: "",
    workPhoneCode: "",
    workPhone: "",
    homePhoneCode: "",
    homePhone: "",
    mobilePhoneCode: "",
    mobilePhone: "",
    phoneValidation: true,
    phoneValidation2: true,
  });

  const [contactVal, setContactVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zipcode: "",
    addressCity: "",
    addressState: "",
    country: "",
  });

  // Profile Info child  Component
  const [profileData, setProfileData] = useState({
    facebook: "",
    twitter: "",
    linkedIn: "",
    preferredSalary: "",
    preferredSalaryCurrency: "",
    minContractRate: "",
    minContractRateCurrency: "",
    description: "",
    fileName: "",
    base64: "",
    positionTypes: [],
    preferredLocation: [],
    primarySkills: [],
    secondarySkills: [],
    skillSet: [],
    source: "",
    visaStatus: "",
    designation: "",
    currentEmployer: "",
    experienceLevel: "",
    experienceYear: 0,
    experienceMonth: 0,
    currentJobTitle: "",
    isFresher: false,
    isRemote: false,
  });

  const [profileVal, setProfileVal] = useState({
    preferredSalaryCurrency: "",
    minContractRateCurrency: "",
    preferredLocation: "",
    primarySkills: "",
    experienceYear: "",
    experienceMonth: "",
    currentJobTitle: "",
  });

  // Submit Add talent
  const submitAddTalent = async () => {
    setIsFormValid(true);
    let contactInfoVal =
      validationDone.contact && contactInfoValidation(contactInfo);
    let profileInfoVal =
      validationDone.profile && profileInfoValidation(profileData);
    let experienceVal =
      validationDone.experience && experienceValidation(experiences);
    let educationVal =
      validationDone.education && educationValidation(educations);
    let certificationVal =
      validationDone.certificate && certificationValidation(certifications);
    let licenseVal = validationDone.license && licenseValidation(licenses);

    if (
      validationDone.contact &&
      !checkEmptyObject(contactInfoVal.validation)
    ) {
      setContactVal(contactInfoVal.validation);
      setContact(true);
      setLoading(false);
      toast.error(contactInfoVal.validationObj[0]);
    } else if (contactInfo.mobilePhone == "") {
      toast.error("Please Enter Phone Number");
    } else if (
      profileData.experienceYear == null ||
      profileData.experienceYear == 0
    ) {
      setProfileInfo(true);
      toast.error("Please Enter Your Experience");
    } else if (!checkEmptyObject(profileInfoVal.validation)) {
      if (validationDone.contact) {
        setValidationDone({ ...validationDone, contact: false });
        setContact(false);
        setContactVal(contactInfoVal.validation);
      }
      setProfileVal(profileInfoVal.validation);
      setProfileInfo(true);
      setLoading(false);
      toast.error(profileInfoVal.validationObj[0]);
    } else if (
      !checkEmptyObject(experienceVal.validation && experienceVal.validation[0])
    ) {
      if (validationDone.profile) {
        setValidationDone({ ...validationDone, profile: false });
        setProfileInfo(false);
        setProfileVal(profileInfoVal.validation);
      }
      setExperienceVal(experienceVal.validation);
      setExperience(true);
      setLoading(false);
      toast.error(experienceVal.validationObj[0]);
    } else if (
      !checkEmptyObject(educationVal.validation && educationVal.validation[0])
    ) {
      if (validationDone.profile) {
        setValidationDone({ ...validationDone, experience: false });
        setExperience(false);
        setExperienceVal(experienceVal.validation);
      }
      setEducationVal(educationVal.validation);
      setEducation(true);
      setLoading(false);
      toast.error(educationVal.validationObj[0]);
    } else if (
      !checkEmptyObject(
        certificationVal.validation && certificationVal.validation[0]
      )
    ) {
      if (validationDone.profile) {
        setValidationDone({ ...validationDone, education: false });
        setEducation(false);
        setEducationVal(educationVal.validation);
      }
      setCertificationVal(certificationVal.validation);
      setCertification(true);
      setLoading(false);
      toast.error(certificationVal.validationObj[0]);
    } else if (
      !checkEmptyObject(licenseVal.validation && licenseVal.validation[0])
    ) {
      if (validationDone.profile) {
        setValidationDone({ ...validationDone, certificate: false });
        setCertification(false);
        setCertificationVal(certificationVal.validation);
      }
      setLicenseVal(licenseVal.validation);
      setLicense(true);
      toast.error(licenseVal.validationObj[0]);
      setLoading(false);
    } else if (!contactInfo.phoneValidation || !contactInfo.phoneValidation2) {
      toast.error("Please Enter Valid Phone");
    } else {
      let isExists = existingCandidateList.filter(
        (row) => row.email == contactInfo.email
      );
      if (isExists.length > 0) {
        toast.error("Talent already exists");
      } else {
        setLoading(true);
        setSubmitClicked(true);
        let talentData = {
          ...contactInfo,
          ...profileData,
        };
        let experience =
          experiences.length > 1
            ? experiences.filter((item) => checkEmptyObject(item) === false)
            : experiences;
        let education =
          educations.length > 1
            ? educations.filter((item) => checkEmptyObject(item) === false)
            : educations;
        let certification =
          certifications.length > 1
            ? certifications.filter((item) => checkEmptyObject(item) === false)
            : certifications;
        let license =
          licenses.length > 1
            ? licenses.filter((item) => checkEmptyObject(item) === false)
            : licenses;
        talentData.experiences = experience;
        talentData.educations = education;
        talentData.certifications = certification;
        talentData.licenses = license;
        talentData.domainName = domain;
        talentData.experienceLevel = findRange(
          profileData.experienceYear,
          profileData.experienceMonth
        );
        try {
          const res = await post("/talent/addtalent", {
            ...talentData,
            experienceYear: profileData.experienceYear?.toString(),
            experienceMonth: profileData.experienceMonth?.toString(),
            preferredLocation: profileData.isRemote
              ? [...profileData.preferredLocation, "Remote"]
              : profileData.preferredLocation,
          });
          if (res.status === 200) {
            if (res.data.value) {
              props.fetchCandidate();
              const successData = {
                name: `${contactInfo?.firstName} ${contactInfo?.lastName}`,
                email: contactInfo?.email,
              };
              props.handleSuccessForm(successData);
              setTimeout(() => {
                window.location.reload();
              }, 5000);
              setLoading(false);
              // props.closeModal()
            } else {
              setSubmitClicked(false);
              //toast.error(res.data.ReturnText)
            }
          }
        } catch (error) {
          setSubmitClicked(false);
          console.log(error);
          toast.error("Some Error Occurred. Please Try Again");
        }
      }
    }
    setSubmitClicked(false);
  };

  useEffect(() => {
    // check if props data is present then set to values
    if (props.parsed) {
      //  profileInfo
      let location = props?.parsedData?.location || "";
      if (props?.parsedData?.isAddedByCrintell) {
        if (props?.parsedData?.profile?.Locations?.length) {
          location = props?.parsedData?.profile?.Locations[0];
        }
      } else if (props?.parsedData?.h5h) {
        if (props?.parsedData?.location?.length) {
          location = props?.parsedData?.location;
        }
      } else {
        if (props.parsedData?.location) {
          location = props?.parsedData?.location;
        }
      }

      // if added by crintell we are checking this data else previous data
      let name1 = props.parsedData?.name?.split(" ");
      const contactInfoObject = props?.parsedData?.h5h
        ? {
            email: props.parsedData?.email,
            address: location ? location?.address : "",
            country: location ? location?.country : "",
            addressState: location ? location?.state : "",
            addressCity: location ? location?.city : "",
            zipcode: "",
            currentJobTitle: props?.parsedData?.designation,
            firstName: props.parsedData?.name ? name1[0] : "",
            lastName: props.parsedData?.name ? name1[1] : "",
            mobilePhone: props.parsedData?.phone ? props.parsedData?.phone : "",
          }
        : {
            address: location?.rawInput,
            email: props?.parsedData?.data?.emails.toString(),
            country: location?.country ? location?.country : "",
            addressState: location?.state ? location?.state : "",
            addressCity: location?.city ? location?.city : "",
            zipcode: "",
            firstName: props?.parsedData?.data?.name?.first,
            lastName: props?.parsedData?.data?.name?.last,
            mobilePhone: props?.parsedData?.data?.phoneNumbers[0],
            homePhone: props?.parsedData?.data?.phoneNumbers[1],
          };
      // : [];
      setContactInfo({
        ...contactInfo,
        ...contactInfoObject,
      });
      let Skill = props.parsedData.h5h
        ? props.parsedData?.primary_skills?.length
          ? props?.parsedData?.primary_skills?.map((item) => {
              return item;
            })
          : []
        : props?.parsedData?.data?.skills?.length
        ? props?.parsedData?.data?.skills?.map((item) => {
            return item.name;
          })
        : [];

      Skill = Skill.filter((SkillItem) => SkillItem);
      // if addedby crintell we are adding this below
      const profileInfoObject = props?.parsedData?.h5h
        ? {
            primarySkills: props.parsedData?.primary_skills,
            secondarySkills: props.parsedData?.secondary_skills,
            linkedIn: props.parsedData?.linkedin || "",
            facebook: props.parsedData?.websites || "",
            skillSet: [],
            description: "",
            experienceYear: props.parsedData?.experience,
            fileName: props.parsedData?.fileName,
            base64: props.parsedData?.base64,
            currentJobTitle: props?.parsedData?.designation,
          }
        : {
            primarySkills: Skill,
            linkedIn: props.parsedData.data?.linkedin,
            facebook: props.parsedData.data?.websites[0],
            skillSet: [props.parsedData.data?.profession],
            description: props.parsedData.data?.summary,
            experienceYear: props.parsedData.data?.totalYearsExperience?.toString(),
            fileName: props.parsedData?.fileName,
            base64: props.parsedData?.base64,
          };

      setProfileData(Skill);
      setProfileData({
        ...profileData,
        ...profileInfoObject,
      });

      let experience = [];
      let experienceVal2 = [];
      if (!props.parsedData.h5h) {
        experience =
          props.parsedData.data?.workExperience &&
          props.parsedData.data?.workExperience.map((item) => ({
            employerName: item.organization,
            jobTitle: item.jobTitle,
          }));
        experienceVal2 =
          props.parsedData.data?.workExperience &&
          props.parsedData.data?.workExperience.map((item) => ({
            employerName: "",
            industry: "",
            jobTitle: "",
            startDate: "",
            endDate: "",
          }));
      }

      experienceVal2 && setExperienceVal(experienceVal2);
      experience && setExperiences(experience);

      let education = [];
      let educationVal2 = [];
      let objArray = [];

      if (props.parsedData?.education !== (null || undefined)) {
        Object.keys(props.parsedData?.education[0])?.forEach((key) =>
          objArray.push({
            index: key,
            edu: props.parsedData?.education[0][key],
          })
        );
      }

      if (props.parsedData?.h5h) {
        education =
          props.parsedData?.education?.length > 0
            ? objArray?.map((item) => ({
                graduatedYear: item?.edu?.year,
                educationProgram: item?.edu?.program,
                school: item.edu?.school,
                major: item.edu?.major,
              }))
            : [];
        educationVal2 =
          props.parsedData?.education?.length > 0 &&
          props.objArray?.map((item) => ({
            graduatedYear: "",

            program: "",
            school: "",
            major: "",
          }));
      } else {
        education =
          props.parsedData.data?.education &&
          props.parsedData.data?.education.map((item) => ({
            educationProgram: item.accreditation?.education,
            school: item.organization,
            major: item.accreditation?.inputStr,
          }));
        educationVal2 =
          props.parsedData?.data?.education &&
          props.parsedData?.data?.education.map((item) => ({
            graduatedYear: "",
            educationType: "",
            educationProgram: "",
            school: "",
            major: "",
          }));
      }

      educationVal2 && setEducationVal(educationVal2);
      education && setEducations(education);
    }
  }, [props]);

  return (
    <>
      {loading || load ? (
        <Loader />
      ) : (
        <div>
          <div class="bl-modal-body" style={{ padding: "0px 30px 30px 30px" }}>
            <div class="mb-2 mt-3">
              <div class="card mb-2">
                <div class="card-body">
                  {props.fileName && (
                    <div
                      v-if="item.filename"
                      class="d-flex align-items-center justify-content-between hoverable"
                    >
                      <div class="d-flex align-items-center">
                        <div class="avatar avatar-coral avatar-sm">
                          <i class="fad fa-user"></i>
                        </div>
                        <p class="mb-0 ml-2">File Name</p>
                      </div>
                      <div>
                        <i
                          v-if="item.isExpanded"
                          class="fal fa-fw fa-chevron-square-down"
                        ></i>
                        <i class="fal fa-fw fa-chevron-square-up"></i>
                      </div>
                    </div>
                  )}
                  <div v-if="item.isExpanded">
                    <div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("contact");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {contact === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Contact Info</small>
                        </span>
                      </button>
                      <div
                        className={
                          contact === true
                            ? "ml-2 ml-0-600 mt-2 mb-4"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat bg-gray4 ml-4 ml-0-600">
                          <ContactInfo
                            contactInfo={contactInfo}
                            setContactInfo={setContactInfo}
                            contactInfoValidation={contactVal}
                            profileInfo={profileData}
                            setProfileInfo={setProfileData}
                            isFormValid={isFormValid}
                          />
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("profile");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {profileInfo === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Profile Info</small>
                        </span>
                      </button>
                      <div
                        className={
                          profileInfo === true
                            ? "ml-2 ml-0-600 mt-2 mb-4"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat bg-gray4 ml-4 ml-0-600">
                          <ProfileInfo
                            profileInfo={profileData}
                            setProfileInfo={setProfileData}
                            profileInfoValidation={profileVal}
                            changePreferredLocation={(location) => {
                              setProfileData({
                                ...profileData,
                                preferredLocation: location,
                              });
                            }}
                            isFormValid={isFormValid}
                          />
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("experience");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {experience === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Experience</small>
                        </span>
                      </button>
                      <div
                        className={
                          experience === true
                            ? "ml-2 ml-0-600 mt-2 mb-4"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat card-lg ml-4 ml-0-600">
                          <div class="card-body">
                            <Experience
                              experiences={experiences}
                              setExperiences={setExperiences}
                              profileInfo={profileData}
                              setProfileInfo={setProfileData}
                              experienceValidation={experienceVal}
                              setExperienceValidation={setExperienceVal}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("education");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {education === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Education</small>
                        </span>
                      </button>
                      <div
                        className={
                          education === true
                            ? "ml-2 ml-0-600 mt-2 mb-4"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat card-lg ml-4 ml-0-600">
                          <div class="card-body">
                            <Education
                              educations={educations}
                              setEducations={setEducations}
                              educationValidation={educationVal}
                              setEducationValidation={setEducationVal}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("certification");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {certification === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Certifications</small>
                        </span>
                      </button>
                      <div
                        className={
                          certification === true
                            ? "ml-2 ml-0-600 mt-2 mb-4"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat card-lg ml-4 ml-0-600">
                          <div class="card-body">
                            <Certifications
                              certifications={certifications}
                              setCertifications={setCertifications}
                              certificationValidation={certificationVal}
                              setCertificationValidation={setCertificationVal}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("license");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {license === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Licenses</small>
                        </span>
                      </button>
                      <div
                        className={
                          license === true
                            ? "ml-2 ml-0-600 mt-2 mb-4"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat card-lg ml-4 ml-0-600">
                          <div class="card-body">
                            <License
                              licenses={licenses}
                              setLicenses={setLicenses}
                              setCertifications={setCertifications}
                              licenseValidation={licenseVal}
                              setLicenseValidation={setLicenseVal}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              disabled={submitClicked}
              onClick={() => {
                setIsFormValid(false);
                props.closeModal();
              }}
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary btn-sm">
              {submitClicked === false ? (
                <span
                  onClick={() => {
                    if (
                      existingCandidateList
                        .map((i) => i.email)
                        .includes(contactInfo.email)
                    ) {
                      toast.error("Talent Already Exists");
                    } else {
                      submitAddTalent();
                    }
                  }}
                >
                  Add
                </span>
              ) : (
                <span v-if="submittingForm">
                  Adding Talent
                  <img width="20px" src={imageLoader} alt="" />
                </span>
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
}
export default AddTalentManualEntry;
