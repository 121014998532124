import React, { useEffect, useState } from "react";
import "../../css/custom.css";
import ReactToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { get } from "../../service/ApiService";
import moment from "moment";
import HotlistFilter from "./HotlistFilter";
import Sort from "./HotlistSort";
import OutsideClickHandler from "react-outside-click-handler";
import SideNavBar from "../layout/SideNavBar";
import TopNavBar from "../layout/TopNavBar";
import ReportTopNavBar from "./ReportTopNavBar";
import { getTenantType } from "../../service/AuthService";
import Footer from "../layout/Footer";

function HotlistReport() {
  const reportHeaders = [
    { id: 0, name: "Location" },
    { id: 1, name: "Active" },
    { id: 2, name: "Approval Pending" },
    { id: 4, name: "Approved" },
    { id: 5, name: "Invited" },
    { id: 6, name: "Grand Total" },
  ];
  const [pageLimit, setPageLimit] = useState(3);
  const [toggle, setToggle] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [columns, setColumns] = useState([]);
  const [dateSearch, setDateSearch] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [mounted, setMounted] = useState(false);
  const [toDate, setToDate] = useState(new Date());
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [vetting, setVetting] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [primarySkills, setPrimarySkills] = useState([]);
  const [candidateLocations, setCandidateLocations] = useState([]);
  const [candidateTitles, setCandidateTitles] = useState([]);
  const [candidateSkills, setCandidateSkills] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [filterBy, setFilterBy] = useState(reportData);

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };

  // useEffect(() => {
  //     fetchHotlistData()
  // }, [])

  // const fetchHotlistData = async () => {

  //     setLoading(true);
  //     try {
  //         let res = await get('/report/gethotlistcm');
  //         if (res.status === 200) {

  //             console.log('res', res);
  //             const talentData = res.data.candidateData;
  //             setTableData(talentData);
  //             const finalData = talentData.filter(i => (i.typeName === 'tc'))
  //             console.log('finalData', finalData.map(i => i.createdDate))
  //             setReportData(finalData)
  //             setFilterBy(finalData)
  //             //setFilteredData(finalData)
  //             setLoading(false);
  //         } else {
  //             setLoading(false);
  //             toast.error('Oops! something went wrong. please try again later');
  //         }
  //     } catch (error) {
  //         setLoading(false);
  //         toast.error('Oops! something went wrong. please try again');
  //     }
  // };
  // useEffect(() => {
  //     setDataArray(filteredData)
  // })

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  //! Pagination
  // function changePage(event) {
  //     const pageNumber = Number(event.target.textContent)
  //     setCurrentPage(pageNumber)
  // }

  // const handleDateSearch = () => {
  //     setSelectedLocations([])
  //     setSelectedTitles([])
  //     setPrimarySkills([])
  //     setVetting(false)
  //     //setMounted(true)
  //     let startDate = moment(new Date(fromDate))
  //         .format('MM/DD/YYYY')
  //     let endDate = moment(new Date(toDate))
  //         .format('MM/DD/YYYY')
  //     if (new Date(startDate) > new Date()) {
  //         toast.error(`'From Date' cannot be future date`)
  //     }
  //     else if (new Date(endDate) > new Date()) {
  //         toast.error(`'To Date' cannot be future date`)
  //     }
  //     else if (new Date(startDate) > new Date(endDate)) {
  //         toast.error(`'From Date' should not be greater than 'To Date'`)
  //     }
  //     else {
  //         const result = reportData.filter((item) => {
  //             let FilteredDate = moment(new Date(item.createdDate))
  //                 .format('MM/DD/YYYY')
  //             return (new Date(startDate) <= new Date(FilteredDate) && new Date(FilteredDate) <= new Date(endDate))
  //         })
  //         // console.log(new Date(toDate))
  //         if (result.length == 0) {
  //             toast.error('No Record Found. Please select another date range')
  //         }
  //         let title = []
  //         let location = []
  //         let skills = []
  //         result.forEach(item => {
  //             if (item.experience[0].jobTitle) {
  //                 if (!title.includes(item.experience[0].jobTitle)) {
  //                     title.push(item.experience[0].jobTitle.trim())
  //                 }
  //             }
  //             if (item.primarySkills) {
  //                 if (!skills.includes(item.primarySkills)) {
  //                     skills.push(...item.primarySkills)
  //                 }
  //             }
  //             if (item.addressState && item.country) {
  //                 if (!location.includes(`${item.addressState}, ${item.country}`)) {
  //                     location.push(`${item.addressState}, ${item.country}`)
  //                 }
  //             }
  //         })
  //         setCandidateTitles(title)
  //         setCandidateSkills([...new Set(skills)])
  //         setCandidateLocations(location)
  //         setDateSearch(result)
  //         setFilteredData(result)

  //         setPaginationArray(
  //             new Array(Math.ceil(result.length / perPage))
  //                 .fill()
  //                 .map((_, i) => i + 1)
  //         )

  //         setPaginationGroup(
  //             new Array(Math.ceil(result.length / perPage))
  //                 .fill()
  //                 .map((_, i) => i + 1)
  //                 .slice(0, pageLimit)
  //         )

  //     }
  // }

  // const onChangeSelect = (e) => {
  //     if (e.target.value === 'All') {
  //         setPerPage(dateSearch.length)
  //         setPaginationArray([1])
  //         setPaginationGroup([1])
  //     } else {
  //         const pageGroup = new Array(Math.ceil(dateSearch.length / e.target.value))
  //             .fill()
  //             .map((_, i) => i + 1)
  //             .slice(0, pageLimit)
  //         setPaginationArray(pageGroup)
  //         setPaginationGroup(pageGroup)
  //         setPerPage(Number(e.target.value))
  //     }
  // }

  // const getPaginatedData = () => {
  //     const startIndex = currentPage * perPage - perPage
  //     const endIndex = startIndex + +perPage

  //     return filteredData.slice(startIndex, endIndex)
  // }

  // const applySort = (sortedData, close) => {
  //     close && setSort(false)
  //     setFilterBy(sortedData)
  //     setFilteredData(sortedData)
  // }

  // const openFilter = () => {
  //     setFilter(true)
  // }

  // const applyFilter = (finalData, selectedTitles, selectedLocations, vetted, primarySkills) => {

  //     setFilter(false)
  //     setVetting(vetted)
  //     setSelectedTitles(selectedTitles)
  //     setSelectedLocations(selectedLocations)
  //     // setReportData(finalData)
  //     setFilteredData(finalData)
  //     setFilterBy(finalData)
  //     setPrimarySkills(primarySkills)
  //     if(finalData.length === 0){
  //         toast.error('No Record Found')
  //     }
  // }

  return (
    <div>
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <ReportTopNavBar handleToggale={toggleDiv} />
        <SideNavBar handleToggale={toggleDiv} />
        <div id="Content">
          <div className="section section-s">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="card card-flat card-borderless p-4 mb-3">
                    <div>
                      {/* <div className='d-flex justify-content-end'>
                                                <button className='btn btn-sm' onClick={() => window.open('/high5hire', '_self')}>Go To Dashboard</button>
                                            </div> */}
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="ml-2.5">Geo Report</h5>
                      </div>
                      <div className="d-flex bd-highlight ml-2.5 mb-4">
                        <div
                          className="p-2 bd-highlight "
                          style={{ zIndex: "999" }}
                        >
                          <label htmlFor="start-date">
                            <h6 className="m-0 mb-1">From</h6>
                          </label>
                          <DatePicker
                            className="form-control"
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            onChangeRaw={(e) => e.preventDefault()}
                          />
                        </div>
                        <div className="p-2 bd-highlight">
                          <label htmlFor="end-date">
                            <h6 className="m-0 mb-1">To</h6>
                          </label>
                          <DatePicker
                            className="form-control"
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            onChangeRaw={(e) => e.preventDefault()}
                          />
                        </div>
                        <div className="p-2 align-self-end bd-highlight mt-10">
                          <button
                            className="btn btn-primary btn-sm"
                            type="submit"
                            //onClick={() => handleDateSearch()}
                          >
                            Search
                          </button>
                        </div>
                        {/* <div className="py-2 align-self-end bd-highlight">
                                                    {getPaginatedData().length > 0 && (
                                                        <ReactToExcel
                                                            className="btn btn-sm"
                                                            table="table-to-xls"
                                                            filename="CMHotlistReportExcel"
                                                            sheet="sheet 1"
                                                            buttonText={
                                                                <span>
                                                                    <i className="fas fa-file-download mr-2"></i>
                                                                    Download
                                                                </span>
                                                            }
                                                        ></ReactToExcel>
                                                    )}
                                                </div> */}

                        <div class="py-2 align-self-end">
                          <div class="dropdown position-relative">
                            <button
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Filter"
                              //onClick={openFilter}
                              type="button"
                              class="btn btn-sm btn-text-accent w-auto"
                            >
                              <i class="fas fa-filter"></i>
                              <span>Filter</span> /
                              {/* <span>
                                                                {selectedTitles.length + selectedLocations.length + (vetting ? 1 : 0) + (primarySkills.length)}
                                                            </span> */}
                            </button>
                          </div>
                        </div>

                        <div class="py-2 align-self-end">
                          <div class="dropdown-sort dd-sort">
                            <button
                              type="button"
                              //onClick={() => setSort(!sort)}
                              class="btn btn-sm w-auto btn-text-accent dd-sort"
                            >
                              <i
                                aria-hidden="true"
                                class="fad fa-sort-down font-16 dd-sort mr-1"
                              ></i>
                              Sort /
                              {/* <span class="dd-sort">{sortCount}</span> */}
                            </button>
                            {/* {
                                                            <OutsideClickHandler
                                                                onOutsideClick={() => setSort(false)}
                                                            >
                                                                {sort && (
                                                                    <Sort
                                                                        candidateData={filteredData}
                                                                        sortArray={sortArray}
                                                                        count={sortCount}
                                                                        sortOrder={sortOrder}
                                                                        sortValues={[
                                                                            "experience[0].jobTitle",
                                                                            "addressState",
                                                                            "primarSkills"
                                                                        ]}
                                                                        sortObjects={{
                                                                            "experience[0].jobTitle": [
                                                                                "Job Title",
                                                                                "fal fa-fw fa-signature  mr-2 dd-sort",
                                                                            ],

                                                                            "addressState": [
                                                                                "Location",
                                                                                "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
                                                                            ],
                                                                            "primarSkills": [
                                                                                "Primary Skill",
                                                                                "fal  fa-fw fa-light fa-book-open mr-2 dd-sort",
                                                                            ]

                                                                        }}
                                                                        changeSortArray={(data) => setSortArray(data)}
                                                                        changeSortCount={(count) => setSortCount(count)}
                                                                        changeSortOrder={(order) => setSortOrder(order)}
                                                                        applySort={applySort}
                                                                        sortingFor={
                                                                            "Sort by Job Title, Location, Skill"
                                                                        }
                                                                    />
                                                                )}
                                                            </OutsideClickHandler>
                                                        } */}
                          </div>
                        </div>

                        <div
                          className="bd-highlight ml-auto mt-4"
                          style={{ width: "15rem", marginLeft: "0.6rem" }}
                        >
                          <h6 className="mt-1">Records</h6>
                          <select
                            className="form-select"
                            // onChange={(e) => onChangeSelect(e)}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="All">All</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    {/* {getPaginatedData().length > 0 ? */}
                    <div className="px-3 pt-0 table-cards-1024 mt-3">
                      <table
                        className="table table-hoverabletable-cards-1024"
                        id="table-to-xls"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        <thead>
                          <tr>
                            {reportHeaders.map((header) => (
                              <th>{header.name}</th>
                            ))}
                          </tr>
                        </thead>
                        {/* {getPaginatedData().map(item => (
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    {item.candidateID}
                                                                </td>
                                                                {item.experience.length > 0 &&
                                                                    <td>{(item.experience[0].jobTitle !== '' ? `${item.experience[0].jobTitle}` : 'Data Not Found')}</td>}
                                                                <td>{(item.skillSet.length > 0 ? `${item.skillSet}` : 'Data Not Found')}
                                                                </td>
                                                                <td>
                                                                    {(item.primarySkills.length > 0 ? `${item.primarySkills.slice(0, 10)}` : 'Data Not Found')}
                                                                </td>
                                                                <td>
                                                                    {(item.secondarySkills.length > 0 ? `${item.secondarySkills.slice(0, 5)}` : 'Data Not Found')}
                                                                </td>
                                                                {(item.experienceYear !== null || '') || (item.experienceMonth !== null || '') ? (
                                                                    <td>
                                                                        {(item.experienceYear !== '' || null ? `${item.experienceYear + 'yrs'}` : 'Data Not Found')}  {(item.experienceMonth !== '' || null ? `${item.experienceMonth + 'months'}` : '')}

                                                                    </td>) : (
                                                                    <td>Data Not Found</td>

                                                                )}
                                                                {item.addressState !== '' || item.country !== '' ? (
                                                                    <td>
                                                                        {(item.addressState !== null || '' ? `${item.addressState + ','}` : '')} {' '} {(item.country !== null || '' ? `${item.country}` : 'Data Not Found')}
                                                                    </td>) : (
                                                                    <td>Data Not Found</td>
                                                                )}
                                                                <td>{(item.isVetted === true ? 'Yes' : 'No')}</td>

                                                            </tr>
                                                        </tbody>
                                                    ))} */}
                      </table>
                    </div>{" "}
                    :
                    <div className="mt-3" style={{ visibility: "hidden" }}>
                      <div class="text-center p-5">
                        <div className="avatar avatar-lg">
                          <i aria-hidden="true"></i>
                        </div>
                        <div class="mt-2"></div>
                      </div>
                    </div>
                    {/* } */}
                    {/* {getPaginatedData().length > 0 &&
                                            <div className="d-flex justify-content-end pt-4 px-1">
                                                <div
                                                    className='ml-auto mx-auto-370 d-flex align-items-center'
                                                // style={{ position: 'absolute', left: '85%' }}
                                                >
                                                    <button
                                                        onClick={() => {
                                                            setPageType('down')
                                                            setCurrentPage((page) => page - 1)
                                                        }}
                                                        type="button"
                                                        disabled={
                                                            currentPage === 1 || getPaginatedData().length === 0
                                                                ? true
                                                                : false
                                                        }
                                                        className="btn btn-icon px-2"
                                                    >
                                                        <i className="fal fa-chevron-circle-left"></i>
                                                    </button>

                                                    {getPaginatedData().length > 0
                                                        ? paginationGroup.map((item) => (
                                                            <button
                                                                type="button"
                                                                className={
                                                                    currentPage === item
                                                                        ? 'btn btn-text px-2 bg-dark text-white'
                                                                        : 'btn btn-text px-2'
                                                                }
                                                                onClick={changePage}
                                                            >
                                                                {item}
                                                            </button>
                                                        ))
                                                        : ''}

                                                    <button
                                                        onClick={() => {
                                                            setPageType('up')
                                                            setCurrentPage((page) => page + 1)
                                                        }}
                                                        type="button"
                                                        disabled={
                                                            currentPage === paginationArray.slice(-1)[0] ||
                                                                getPaginatedData().length === 0
                                                                ? true
                                                                : false
                                                        }
                                                        className="btn btn-icon px-2"
                                                    >
                                                        <i className="fal fa-chevron-circle-right"></i>
                                                    </button>
                                                </div>
                                            </div>} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>

      {/* {
                filter &&
                <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
                    <HotlistFilter
                        reportData={dateSearch}
                        selectedTitles={selectedTitles}
                        candidateTitles={candidateTitles}
                        selectedLocations={selectedLocations}
                        candidateLocations={candidateLocations}
                        primarySkills={primarySkills}
                        candidateSkills={candidateSkills}
                        vetting={vetting}
                        fetchHotlistData={fetchHotlistData}
                        applyFilter={applyFilter}
                    />
                </OutsideClickHandler>
            } */}
    </div>
  );
}

export default HotlistReport;
