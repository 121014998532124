import React from "react";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import Sort from "../../../common/FullDataSort";

const ReportHeader = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  handleDateSearch,
  setSort,
  sort,
  filteredData,
  sortCount,
  orderList,
  sortObject,
  applySort,
  setSortCount,
  setSortArray,
  onChangeSelect,
  sortArray,
  selectedTitles,
  setFilter,
  selectedTypes,
  selectedPrimarySkills,
  selectedJobLocation,
  dataa,
}) => {
  const exportToExcel = () => {
    const downloadData = filteredData.map((item) => {
      return {
        "Job posted on": moment(new Date(item?.jobPostDate)).format(
          "MM/DD/YYYY"
        ),
        "Job Title": item?.jobTitle,
        "Job Type": item?.jobType,
        "Job expired on": moment(new Date(item?.expiresOn)).format(
          "MM/DD/YYYY"
        ),
        "Job Location": item?.jobLocation,
        "Job Company": item?.jobTenant,
        "Job posted by": item?.jobPostedBy,
        "Job posted by company name": item?.jobTenant,
        "Candidate Name": item?.candidateName,
        "Candidate Location":
          item.city != null || item.country !== null
            ? `${item.city}, ${item.country}`
            : "",
        "Candidate Email ID": item?.email,
        "Candidate Phone Number":
          item?.homePhone !== null ? item?.homePhone : "",
        "Candidate Primary Skills":
          item.primarySkills !== null &&
          JSON.parse(item?.primarySkills).length !== 0
            ? JSON.parse(item?.primarySkills)
                .map((skill) => skill)
                .join(", ")
            : "",
        "Good to have skills":
          item.secondarySkills !== null &&
          JSON.parse(item.secondarySkills).length !== 0
            ? JSON.parse(item.secondarySkills)
                .map((skill) => skill)
                .join(", ")
            : "",
        "Invited on": !item?.invitedDate
          ? ""
          : moment(new Date(item?.invitedDate)).format("MM/DD/YYYY"),
        "Invited by recruiter": item?.invitedBy,
        "Submitted on": !item?.submittedDate
          ? ""
          : moment(new Date(item?.submittedDate)).format("MM/DD/YYYY"),
        "Submitted by recruiter": item?.submittedDate && item?.submittedby,
        "Shortlisted on": !item?.shortlistedDate
          ? ""
          : moment(new Date(item?.shortlistedDate)).format("MM/DD/YYYY"),
        "Shortlisted by": item?.shortlistedBy,
        "Shortlisted by role":
          item?.shortlistedbyRole == 3
            ? "Recruiter"
            : item?.shortlistedbyRole == 4
            ? "Hiring Manager"
            : item?.shortlistedbyRole == 8
            ? "Customer Success Manager"
            : item?.shortlistedbyRole == 6
            ? "Super Admin"
            : item?.shortlistedbyRole == 9
            ? "Marketplace Manager"
            : item?.shortlistedbyRole == 11
            ? "High5 Admin"
            : "",
        "Shortlisted by company": item?.shortlistedDate ? item?.jobTenant : "",
        "Offered on": !item?.offeredDate
          ? ""
          : moment(new Date(item?.offeredDate)).format("MM/DD/YYYY"),
        "Offered by": item?.offeredBy,
        "Offered by role":
          item?.offerredByRole == 3
            ? "Recruiter"
            : item?.offerredByRole == 4
            ? "Hiring Manager"
            : item?.offerredByRole == 8
            ? "Customer Success Manager"
            : item?.offerredByRole == 6
            ? "Super Admin"
            : item?.offerredByRole == 9
            ? "Marketplace Manager"
            : item?.offerredByRole == 11
            ? "High5 Admin"
            : "",
        "Hired on": !item?.hiredOn
          ? ""
          : moment(new Date(item?.hiredOn)).format("MM/DD/YYYY"),
        "Hired by": item?.hiredOn ? item?.offeredBy : "",
        "Hired by role": item?.hiredOn
          ? item?.offerredByRole == 3
            ? "Recruiter"
            : item?.offerredByRole == 4
            ? "Hiring Manager"
            : item?.offerredByRole == 8
            ? "Customer Success Manager"
            : item?.offerredByRole == 6
            ? "Super Admin"
            : item?.offerredByRole == 9
            ? "Marketplace Manager"
            : item?.offerredByRole == 11
            ? "High5 Admin"
            : ""
          : "",
        "Disqualified on": !item?.disqualifiedDate
          ? ""
          : moment(new Date(item?.disqualifiedDate)).format("MM/DD/YYYY"),
        "Disqualified by": item?.disqualifiedDate ? item?.disqualifiedBy : "",
        "Disqualified by role": item?.disqualifiedDate
          ? item?.disqualifiedByRole == 3
            ? "Recruiter"
            : item?.disqualifiedByRole == 4
            ? "Hiring Manager"
            : item?.disqualifiedByRole == 8
            ? "Customer Success Manager"
            : item?.disqualifiedByRole == 6
            ? "Super Admin"
            : item?.disqualifiedByRole == 9
            ? "Marketplace Manager"
            : item?.disqualifiedByRole == 11
            ? "High5 Admin"
            : ""
          : "",
        "Reason for disqualification": item?.reasonForDisqualification,
      };
    });
    const ws = XLSX.utils.json_to_sheet(downloadData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "GenericReportExcel.xlsx");
  };

  return (
    <div>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="ml-2.5">Master Report</h5>
        </div>
        <div className="d-flex bd-highlight ml-2.5">
          <div className="p-2 bd-highlight" style={{ width: "10em" }}>
            <label htmlFor="start-date">
              <h6 className="m-0">From</h6>
            </label>
            <DatePicker
              className="form-control"
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              onChangeRaw={(e) => e.preventDefault()}
              style={{}}
            />
          </div>
          <div className="p-2 bd-highlight" style={{ width: "10em" }}>
            <label htmlFor="end-date">
              <h6 className="m-0">To</h6>
            </label>
            <DatePicker
              className="form-control"
              selected={toDate}
              onChange={(date) => setToDate(date)}
              onChangeRaw={(e) => e.preventDefault()}
            />
          </div>
          <div className="p-2 align-self-end bd-highlight mt-10 ">
            <button
              className="btn btn-sm btn-primary"
              type="submit"
              onClick={() => handleDateSearch()}
            >
              Search
            </button>
          </div>

          <div className="py-2 align-self-end bd-highlight">
            {filteredData.length > 0 && (
              <button className="btn btn-sm" onClick={exportToExcel}>
                <span>
                  <i className="fas fa-file-download mr-2"></i>
                  Download
                </span>
              </button>
            )}
          </div>

          {/* SORT */}
          <div class="py-2 align-self-end">
            <div class="dropdown-sort dd-sort">
              <button
                type="button"
                onClick={() => setSort(!sort)}
                class="btn btn-sm w-auto btn-text-accent dd-sort"
                disabled={filteredData.length === 0}
              >
                <i
                  aria-hidden="true"
                  class="fad fa-sort-down font-16 dd-sort mr-1"
                ></i>
                Sort / <span class="dd-sort">{sortCount}</span>
              </button>
              {
                <OutsideClickHandler onOutsideClick={() => setSort(false)}>
                  {sort && (
                    <Sort
                      sortData={dataa.map((i) => ({
                        ...i,
                        jobLocation: i.jobLocation
                          ? i.jobLocation.trim()
                          : "Remote",
                      }))}
                      orderArray={orderList}
                      sortObjects={sortObject}
                      applySort={applySort}
                      setSortCount={setSortCount}
                      sortCount={sortCount}
                      setSortArray={setSortArray}
                      sortArray={sortArray}
                      sortingFor={
                        "Sort by Job Title, Job Type, Candidate Name and Job Location"
                      }
                    />
                  )}
                </OutsideClickHandler>
              }
            </div>
          </div>

          {/* FILTER */}
          <div class="py-2 align-self-end">
            <div class="dropdown position-relative">
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Filter"
                onClick={() => setFilter(true)}
                type="button"
                class="btn btn-sm btn-text-accent w-auto"
                disabled={filteredData.length === 0}
              >
                <i class="fas fa-filter"></i>
                <span>Filter</span> /{" "}
                <span>
                  {selectedTitles.length +
                    selectedTypes.length +
                    selectedPrimarySkills.length +
                    selectedJobLocation.length}
                </span>
              </button>
            </div>
          </div>

          <div
            className="bd-highlight ml-auto mt-4"
            style={{ width: "15rem", marginLeft: "0.6rem" }}
          >
            <h6 className="mt-1">Records</h6>
            <select className="form-select" onChange={(e) => onChangeSelect(e)}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="All">All</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
