import React, { useState } from "react";
import Avatar from "react-avatar";
import { Modal, Button } from "react-bootstrap";

const Emails = () => {
  // Dummy email data
  const emails = [
    {
      id: 1,
      sender: "info@example.com",
      subject: "Meeting Reminder",
      timestamp: "2 hours ago",
      content: "Don't forget about our meeting tomorrow.",
    },
    {
      id: 2,
      sender: "noreply@example.com",
      subject: "Latest Updates",
      timestamp: "1 day ago",
      content:
        "Here's the latest updates on the project. We have made significant progress, and I would appreciate your feedback on the latest changes. Looking forward to hearing from you.",
    },
    {
      id: 3,
      sender: "support@example.com",
      subject: "Action Required",
      timestamp: "3 days ago",
      content:
        "Please take action on the pending tasks. Your prompt attention to these matters is crucial for the success of the project.",
    },
    {
      id: 4,
      sender: "marketing@example.com",
      subject: "Newsletter Draft",
      timestamp: "4 days ago",
      content:
        "Attached is the draft for the upcoming newsletter. Kindly review and provide your input on the content. We aim to send it out by the end of the week.",
    },
    {
      id: 5,
      sender: "ceo@example.com",
      subject: "Quarterly Report",
      timestamp: "5 days ago",
      content:
        "As we approach the end of the quarter, I would like to request your department's quarterly report. Please compile the necessary data and submit it by the specified deadline.",
    },
  ];

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  // Function to open modal
  const openModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  // Function to close modal
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container mt-4">
      {emails.map((email) => (
        <div key={email.id} className="card shadow mb-3">
          <div className="card-header d-flex justify-content-between">
            <div className="d-flex gap-3">
              <Avatar name={email.sender} size="30" round="80px" />
              <h5 className="mb-0">{email.sender}</h5>
            </div>
            <small className="text-muted">{email.timestamp}</small>
          </div>
          <div className="card-body">
            <h6 className="card-subtitle mb-2">{email.subject}</h6>
            <p className="card-text">{email.content}</p>
            {/* <button
              className="btn btn-primary"
              onClick={() => openModal(email.content)}
            >
              View
            </button> */}
          </div>
        </div>
      ))}
      {/* Modal */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Full Email</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Emails;
