import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const CandidateLoginAnalytics = (props) => {
  const {
    Candidates_daily,
    Candidates_monthly,
    Candidates_total,
    Candidates_weekly,
  } = props.data;
  const [candidatesTimeInterval, setCandidatesTimeInterval] = useState("daily");

  const [loggedInCandidates, setLoggedInCandidates] = useState(0);
  const [loggedInCount, setLoggedInCount] = useState(0);
  const [chartSeries, setChartSeries] = useState([
    {
      name: "Talents Login",
      data: [],
      color: "#55c3c3",
    },
  ]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
    },
    dataLabels: {
      enabled: false,
    },
  });
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day} ${month}`;
  }

  const generateChartData = (timeInterval) => {
    let categories = [];
    let data = [];
    let count = 0;
    if (timeInterval === "daily") {
      categories = Candidates_daily?.map((item) => formatDate(item.category));
      data = Candidates_daily?.map((item) => item.data);
      Candidates_daily?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInCandidates(
        Candidates_daily && Candidates_daily[Candidates_daily?.length - 1].data
      );
    } else if (timeInterval === "weekly") {
      categories = Candidates_weekly?.map((item) => formatDate(item.category));
      data = Candidates_weekly?.map((item) => item.data);
      Candidates_weekly?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInCandidates(
        Candidates_weekly &&
          Candidates_weekly[Candidates_weekly?.length - 1].data
      );
    } else if (timeInterval === "monthly") {
      categories = Candidates_monthly?.map((item) => item.category);
      data = Candidates_monthly?.map((item) => item.data);
      Candidates_monthly?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInCandidates(
        Candidates_weekly &&
          Candidates_monthly[Candidates_monthly?.length - 1].data
      );
    }

    setChartSeries([
      {
        name: "Talents Login",
        data: data,
      },
    ]);
    setChartOptions({
      ...chartOptions,
      xaxis: {
        categories: categories,
      },
    });
  };
  useEffect(() => {
    generateChartData(candidatesTimeInterval);
  }, [candidatesTimeInterval]);

  useEffect(() => {
    generateChartData("daily");
  }, []);
  return (
    <>
      {/* Talents Login Dashboard */}
      <div className="card analyticCards custom-card">
        <h6 className="text-center mt-3 mb-3">Talents Login</h6>
        <div className="d-flex flex-wrap justify-content-center">
          <button
            onClick={() => setCandidatesTimeInterval("daily")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                candidatesTimeInterval === "daily" ? "#17a2b8" : "white",
              color: candidatesTimeInterval === "daily" ? "white" : "#17a2b8",
            }}
          >
            Daily
          </button>
          <button
            onClick={() => setCandidatesTimeInterval("weekly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                candidatesTimeInterval === "weekly" ? "#17a2b8" : "white",
              color: candidatesTimeInterval === "weekly" ? "white" : "#17a2b8",
            }}
          >
            Weekly
          </button>
          <button
            onClick={() => setCandidatesTimeInterval("monthly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                candidatesTimeInterval === "monthly" ? "#17a2b8" : "white",
              color: candidatesTimeInterval === "monthly" ? "white" : "#17a2b8",
            }}
          >
            Monthly
          </button>
        </div>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={250}
        />
        <div className="card m-2 align-items-center">
          <p className="mt-2 fw-light">
            {`Total Talent${
              Candidates_total !== 1 ? "s" : ""
            }: ${Candidates_total}`}
          </p>
          <p className="fw-light">
            {`Logged In Talent${
              loggedInCount !== 1 ? "s" : ""
            }: ${loggedInCount}`}
          </p>
        </div>
      </div>
    </>
  );
};

export default CandidateLoginAnalytics;
