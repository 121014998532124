import React, { useState } from "react";
import CandateAssessmentFullView from "./CandidateAssessmentFullView";
import Modal from "react-bootstrap/Modal";

function CandidateAssesmentTableView(props) {
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [openAssessmentDetailsModel, setOpenAssessmentDetailsModel] = useState(
    false
  );

  const openAssessmentDetails = (module) => {
    setAssessmentDetails(module);
    setOpenAssessmentDetailsModel(true);
  };

  const closeAssessmentModal = () => {
    setOpenAssessmentDetailsModel(false);
  };

  return (
    <>
      <div
        class="row d-none d-none-1200 d-block-force"
        // v-bind:class="{ 'd-block-force': toggleView.tableview }"
      >
        <div class="col">
          <table class="table table-sortable  table-hoverable">
            <thead>
              <tr>
                <th class="no-hover pt-0">
                  <div class="d-flex align-items-center">Title</div>
                </th>
                <th class="no-hover pt-0">
                  <div class="d-flex align-items-center">Difficulty</div>
                </th>
                <th class="no-hover pt-0">
                  <div class="d-flex align-items-center">Duration</div>
                </th>
                <th class="no-hover pt-0">
                  <div class="d-flex align-items-center">Last updated</div>
                </th>
                <th>
                  <div class="d-flex align-items-center">Progress</div>
                </th>
              </tr>
            </thead>
            {props.assessmentList.map((module) => (
              <tbody>
                <tr>
                  <td class="p-relative">
                    <a
                      href="#top"
                      onClick={() => {
                        openAssessmentDetails(module);
                      }}
                      class="font-primary no-hover"
                    >
                      {module.title}
                    </a>
                  </td>
                  <td>
                    <div>
                      <a
                        href="/candidate/assessments/123123"
                        class="font-primary no-hover font-regular"
                      >
                        {module.difficulty}
                      </a>
                    </div>
                  </td>
                  <td>
                    <div>
                      <a
                        href="/candidate/assessments/123123"
                        class="font-primary no-hover font-regular"
                      >
                        {(module.difficulty === "1 Way Video" ||
                          module.difficulty === "beginner") && (
                          <span class="mr-2 tag font-bold tag-blue1">
                            {module.difficulty}
                          </span>
                        )}

                        {(module.difficulty === "2 Way Video" ||
                          module.difficulty === "intermediate") && (
                          <span class="mr-2 tag font-bold tag-orange1">
                            {module.difficulty}
                          </span>
                        )}

                        {(module.difficulty === "MCQ" ||
                          module.difficulty === "advanced") && (
                          <span class="mr-2 tag font-bold tag-red1">
                            {module.difficulty}
                          </span>
                        )}

                        {module.difficulty === "expert" && (
                          <span class="mr-2 tag font-bold tag-coral">
                            {module.difficulty}
                          </span>
                        )}
                      </a>
                    </div>
                  </td>
                  <td style={{ width: "150px;" }}>
                    <div>
                      <a
                        href="/candidate/assessments/123123"
                        class="font-primary no-hover font-regular"
                      >
                        <span>{module.lastUpdated}</span> &nbsp;
                        <span v-if="module.lastUpdated > 1">days</span> &nbsp;
                        {/* <span v-if="module.lastUpdated == 1">day</span> uncomment */}
                        ago
                      </a>
                    </div>
                  </td>
                  <td style={{ width: "150px;" }}>
                    <div>
                      {module.status === "inprogress" && (
                        <div>
                          <small>20% Complete</small>
                          <div
                            style={{ position: "revert" }}
                            class="alert-progress alert-progress-static bg-gray3"
                          >
                            <div
                              style={{ transform: "translateX(-80%)" }}
                              class="alert-progress-bar"
                            ></div>
                          </div>
                        </div>
                      )}
                      {module.status === "completed" && (
                        <div>
                          <small>100% Complete</small>
                          <div
                            style={{ position: "revert" }}
                            class="alert-progress alert-progress-static bg-green1"
                          ></div>
                        </div>
                      )}
                      {module.status !== "completed" &&
                        module.status !== "inprogress" && (
                          <div>
                            <small>Not Started</small>
                            <div
                              style={{ position: "revert" }}
                              class="alert-progress alert-progress-static bg-gray3"
                            >
                              <div
                                style={{ width: "0%" }}
                                class="alert-progress-bar bg-green1"
                              ></div>
                            </div>
                          </div>
                        )}
                    </div>
                  </td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      </div>
      <Modal
        size="lg"
        show={openAssessmentDetailsModel === true}
        // dialogClassName="view-panel show"
      >
        <CandateAssessmentFullView
          assessmentDetails={assessmentDetails}
          closePopUP={closeAssessmentModal}
        />
      </Modal>
    </>
  );
}
export default CandidateAssesmentTableView;
