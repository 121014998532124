import React, { useState } from "react";
import Overview from "./overview/Index";
import Details from "./details/Index";
import Screening from "./screening/Index";
import Notes from "./notes/Index";
import Document from "./document/Index"

const Index = (props) => {
  const { jobData, sideView } = props;

  return (
    <div>
      <div className=" card card-flat card-lg card-top-straight">
        <div className="card-body">
          {sideView == "overview" && <Overview jobData={jobData} />}
          {sideView == "details" && <Details jobData={jobData} />}
          {sideView == "qna" && <Screening jobData={jobData} />}
          {sideView == "notes" && <Notes jobData={jobData} />}
          {sideView == "document" && <Document jobData={jobData} />}
        </div>
      </div>
    </div>
  );
};

export default Index;
