import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import { useHistory, useParams, withRouter } from "react-router-dom";
import Loader from "../../Loader/Loader";
import logo from "../../../assets/images/High5Logo.png";
const RTH_Screening = ({
  idCandidate,
  dispositionID,
  job,
  InviteTalentWithVet,
  setIsScreening,
  setIsRTHScreening,
}) => {
  const { id, recruiterid, dispositionId, candidateId } = useParams();
  const [jobData, setJobData] = useState({});
  const [screeningData, setScreeningData] = useState([]);
  const history = useHistory();
  const [screeningQuestions, setScreeningQuestions] = useState([
    {
      question: "",
      answer: "",
    },
  ]);
  const [answers, setAnswers] = useState(
    screeningQuestions.map((q) => q.answer)
  );
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [screeningStatus, setScreeningStatus] = useState(false);

  const getPoolById = async () => {
    const params = { poolId: id };
    setLoading(true);
    await post("/talent/getPoolInfo", params).then(async (res) => {
      // setTenantData(res.data[0]);
      setJobData(res.data[0]);
      const screening = res?.data[0]?.readyToHireQA
        ? JSON.parse(res?.data[0]?.readyToHireQA)
        : [];
      setScreeningData(screening);
      setLoading(false);
    });
  };

  const getScreeningStatus = async () => {
    const params = { dispositionId: dispositionId };
    setLoading(true);
    await post("/talent/getscreeningstatus", params).then(async (res) => {
      const screeningQuestion = res.data[0]?.screeningQuestions;
      if (screeningQuestion && JSON.parse(screeningQuestion).length > 0) {
        setScreeningStatus(true);
      }
      getPoolById();
    });
  };

  useEffect(() => {
    getScreeningStatus();
  }, []);

  useEffect(() => {
    const data = screeningData.map((x) => ({
      question: x?.question,
      input: x?.responseType,
      answer: "",
    }));
    setScreeningQuestions(data);

    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    handleResize(); // Set initial value
    window.addEventListener("resize", handleResize); // Add event listener
    return () => window.removeEventListener("resize", handleResize); // Cleanup
  }, [screeningData]);

  const handleAnswerChange = (index, answer) => {
    const newAnswers = [...screeningQuestions];
    newAnswers[index]["answer"] = answer;
    setScreeningQuestions(newAnswers);
  };

  const screeningSubmit = async () => {
    let status = 40; // Ready to hire

    if (screeningQuestions.some((x) => x.answer === ""))
      return toast.error("Please answer all ready To hire screening questions.");
    // else if (screeningQuestions.some((x) => x.answer === "No")) status = 40; //Ready to hire
    const data = {
      screeningQuestions: JSON.stringify(screeningQuestions),
      dispositionID: dispositionId,
      status,
    };
    setLoading(true);
    await post("/careerspool/updateRTHScreening", data);
    setLoading(false);
    history.push(`/career/home`);
  };

  return (
    <div className="mx-4 mt-5">
      {loading && <Loader />}
      <div className="my-2">
        <img
          className="mb-2 my-auto"
          style={{ height: "40px", width: "75px" }}
          src={logo}
          alt="logo"
        />
      </div>
      <hr />
      <h6 className="m-2">
        You are almost there, please answer the following Ready To Hire
        Screening question(s) and complete your application.
      </h6>
      <div className="px-5 mx-5">
        <div className="card  mt-3 ">
          <div className="card-body ">
            <h6 className="m-2">Ready To Hire Screening*</h6>
            <hr />
            <div className="p-3">
              {/* <h6>Screening Questions</h6> */}
              <div
                className="questions"
                style={{
                  display: "grid",
                  gap: "20px",
                  gridTemplateColumns: "1fr",
                  gridTemplateRows: isMobile ? "auto" : "1fr",
                }}
              >
                {screeningQuestions.map((question, index) => (
                  <div
                    key={index}
                    style={{
                      display: "grid",
                      gridTemplateColumns: isMobile ? "1fr" : "auto auto",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ marginBottom: isMobile ? "10px" : "0" }}>
                      <span className="mr-2">{index + 1}.</span>{" "}
                      {question.question}
                    </p>
                    {question?.input === "Yes/No" && (
                      <div className=" d-flex  justify-content-end   w-100">
                        <select
                          className="form-select"
                          value={screeningQuestions[index]["answer"]}
                          onChange={(e) =>
                            handleAnswerChange(index, e.target.value)
                          }
                          style={{ width: isMobile ? "100%" : "150px" }}
                        >
                          <option value="">Select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </div>
                    )}
                    {question?.input === "Text" && (
                      <div className=" d-flex  justify-content-end   w-100">
                        <input
                          className="form-control"
                          type="text"
                          value={screeningQuestions[index]["answer"]}
                          onChange={(e) =>
                            handleAnswerChange(index, e.target.value)
                          }
                          style={{ width: isMobile ? "100%" : "150px" }}
                        />
                      </div>
                    )}
                    {question?.input === "Numeric" && (
                      <div className=" d-flex  justify-content-end   w-100">
                        <input
                          className="form-control"
                          type="number"
                          value={screeningQuestions[index]["answer"]}
                          onChange={(e) =>
                            handleAnswerChange(index, e.target.value)
                          }
                          style={{ width: isMobile ? "100%" : "150px" }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-end  px-3">
              <button onClick={screeningSubmit} className="btn btn-md">
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RTH_Screening;
