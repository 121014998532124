import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getSubmittedTalentList } from "../../../../../actions/talent/cmSubmittedTalents";
import { getTalentList } from "../../../../../actions/talent/talentList";
import Loader from "../../../../Loader/Loader";
import TalentGridView from "../../../talent/talentcommunity/gridview/TalentGridView";
import { get } from "../../../../../service/ApiService";
import { toast } from "react-toastify";
import { getTalentListCount } from "../../../../../actions/talent/talentList";
import Pagination from "../../../../components_v3/pagination/pagination";

function Index() {
  const [pageCount, setPageCount] = useState(1);
  const [candidateData, setCandidateData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [selectedData, setSelectedData] = useState({
    candidateSkills: [],
    currentJobTitle: [],
    candidateTags: [],
    candidateLocation: [],
    sourceChannel: [],
    currentEmployer: [],
    workType: [],
    workAuthorization: [],
    requestTitle: [],
    jobWorkType: [],
    jobWorkAuthorization: [],
    jobLocation: [],
    jobId: [],
    hotJob: [],
    recruiterName: [],
    formType: [],
    status: [],
    dateRangeType: "",
    dateRange: ["", ""],
  });
  const dispatch = useDispatch();

  const { talentData, loading, talentCount, talentCountloading } = useSelector(
    (state) => ({
      talentData: state.TalentList.talentData,
      loading: state.TalentList.loading,
      talentCount: state.TalentCount.talentCount,
      talentCountloading: state.TalentCount.loading,
    }),
    shallowEqual
  );

  const fetchTalentCount = () => {
    let payload = {
      tab: "All",
      filterObject: getFilterParams(),
    };
    dispatch(getTalentListCount(payload));
  };

  const getFilterParams = () => {
    const filterParam = {
      primarySkills: selectedData.candidateSkills,
      designation: selectedData.currentJobTitle,
      candidateTags: selectedData.candidateTags,
      currentEmployer: selectedData.currentEmployer,
      address: selectedData.candidateLocation,
      source: selectedData.sourceChannel,
      positionTypes: selectedData.workType,
      visaStatus: selectedData.workAuthorization,
      recruiterName: selectedData.recruiterName,
    };
    return filterParam;
  };

  const fetchData = (currentPage) => {
    let payload = {
      currentPage: currentPage,
      limit: 3,
      status: null,
      sortParam: [],
      tab: "All",
      filterObject: getFilterParams(),
    };
    dispatch(getTalentList(payload));
  };

  useEffect(() => {
    fetchData(1);
    fetchTalentCount();
  }, []);

  useEffect(() => {
    if (talentData && talentData.length > 0) {
      setCandidateData(talentData);
    }
    if (talentCount && talentCount?.length > 0) {
      setTotalCount(talentCount?.[0]?.all);
    }
  }, [talentData, talentCount]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber);
  };

  return (
    <>
      {(loading || talentCountloading) && <Loader />}
      <div className="card card-flat card-borderless bg-gray4 p-4 mb-3">
        <div className="d-flex align-items-centermb-3 mb-3">
          <div className="d-md-flex align-items-center">
            <h6 className="mr-3 mb-0">Candidates </h6>
            <a href="/high5Hire/talents/submissions">Go To Full View</a>
          </div>
        </div>
        {loading || candidateData.length === 0 ? (
          <div>
            <div class="text-center p-3">
              <div class="avatar avatar-lg">
                <i class="fad fa-users-slash"></i>
              </div>
              <div class="mt-2">No data found</div>
            </div>
          </div>
        ) : (
          <>
            <TalentGridView
              currentPage={pageCount}
              setCurrentPage={setPageCount}
              arrayList={candidateData}
              parent="hmdashboard"
              freshness={0}
            />
            {totalCount > 0 && (
              <>
                <Pagination
                  currentPage={currentPage}
                  totalItems={totalCount}
                  itemsPerPage={3}
                  onPageChange={handlePageChange}
                  limit={3}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default Index;
