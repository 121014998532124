import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useHistory } from "react-router";

const Index = () => {
  const history = useHistory();
  return (
    <li>
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="bottom-end"
        overlay={
          <div
            className="dropdown-menu dropdown-menu-right"
            aria-labelledby="requestMoreMenu"
          >
            <button
              type="button"
              className="dropdown-item"
              onClick={() => {
                history.push("/high5Hire/performancereport");
              }}
            >
              <i className="fa fa-user mr-2"></i>Performance Report
            </button>
          </div>
        }
      >
        <a
          href="#"
          data-bs-toggle="tooltip"
          data-bs-placement="left"
          title="Performance"
        >
          <i className="fa fa-bar-chart mr-3"></i>
          <span className="nav-text">Performance</span>
        </a>
      </OverlayTrigger>
    </li>
  );
};

export default Index;
