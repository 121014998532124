import React, { useState } from "react";
import SideNavBar from "../../../layout/SideNavBar";
import TopNavBar from "../../../layout/TopNavBar";
import { getCandidateName } from "../../../../service/AuthService";
import Footer from "../../../layout/Footer";
import Users from "./Users.js";
import Curators from "../../curator/CuratorsList/CuratorsList.js";
import TalentPool from "./TalentPool.js";
import TalentCommunity from "./TalentCommunity.js";
import Jobs from "./Jobs.js";

const Index = () => {
  const [toggle, setToggle] = useState(true);
  const [view, setView] = useState("user");

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <SideNavBar />
        <TopNavBar handleToggale={toggleDiv} />
        <div id="Content">
          <div className="section section-sm pt-0-768">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div class="mb-5 text-center">
                    <h3>
                      <span className="wave">👋</span>Hi,{" "}
                      {getCandidateName().split(" ")[0]}. Welcome!{" "}
                    </h3>
                  </div>
                </div>
              </div>
              <div class="row justify-content-center">
                <div class="col-lg-11">
                  <div id="TenantDashboard" class="row mb-3">
                    <div class="col-lg-12">
                      <div class="card">
                        <div class="card-body">
                          <ul class="nav nav-tabs pt-1">
                            <li class="nav-item">
                              <button
                                type="button"
                                class={
                                  view == "user"
                                    ? "nav-link pb-3 active"
                                    : "nav-link pb-3"
                                }
                                onClick={() => setView("user")}
                              >
                                Users
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                type="button"
                                class={
                                  view == "curator"
                                    ? "nav-link pb-3 active"
                                    : "nav-link pb-3"
                                }
                                onClick={() => setView("curator")}
                              >
                                Curators
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                type="button"
                                class={
                                  view == "talent"
                                    ? "nav-link pb-3 active"
                                    : "nav-link pb-3"
                                }
                                onClick={() => setView("talent")}
                              >
                                Talent Pool
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                type="button"
                                class={
                                  view == "community"
                                    ? "nav-link pb-3 active"
                                    : "nav-link pb-3"
                                }
                                onClick={() => setView("community")}
                              >
                                Communities
                              </button>
                            </li>
                            <li class="nav-item">
                              <button
                                type="button"
                                class={
                                  view == "job"
                                    ? "nav-link pb-3 active"
                                    : "nav-link pb-3"
                                }
                                onClick={() => setView("job")}
                              >
                                Jobs
                              </button>
                            </li>
                          </ul>
                          <hr class="mt-0" />
                          {view == "user" && <Users />}
                          {view == "curator" && <Curators />}
                          {view == "talent" && <TalentPool />}
                          {view == "community" && <TalentCommunity />}
                          {view == "job" && <Jobs />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </body>
    </>
  );
};

export default Index;
