import React, { useState } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { Modal } from "react-bootstrap";
import { post } from "../../../../../../service/ApiService";
import Loader from "../../../../../Loader/Loader";

const EditJoiningDate = (props) => {
  let curDate = new Date();
  const {
    candidateData,
    showEditJoiningDate,
    setShowEditJoiningDate,
    reloadDisposition,
  } = props;
  const [loading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(candidateData?.joiningDate);

  const updateDateHandler = async (e) => {
    setLoading(true);
    try {
      let payload = {
        joiningDate: moment(startDate).format("YYYY-MM-DD"),
        id_disposition: candidateData.id_disposition,
      };

      const res = await post("/disposition/editjoiningdate", payload);
      if (res.status === 200) {
        setLoading(false);
        reloadDisposition();
        toast.success("Date updated successfully");
      } else {
        setLoading(false);
        toast.error("Error occurred while updating talent details");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again later");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        size="lg"
        show={showEditJoiningDate}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="bl-modal-header">
          <h6 className="bl-modal-title">{"Edit Start Date"}</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowEditJoiningDate(false)}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div>
            <div className="bl-modal-body text-center">
              <div className="card card-flat bg-gray4 mb-5 ml-2  mr-2">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-floating form-date">
                        <label>Candidate Name</label>
                        <input
                          type="text"
                          className="form-control"
                          name="currencyType"
                          defaultValue={
                            candidateData.firstName +
                            " " +
                            candidateData.lastName
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-floating form-date">
                        <label>Start Date</label>
                        <input
                          type="date"
                          name="startDate"
                          value={moment(startDate).format("YYYY-MM-DD")}
                          onChange={(e) => setStartDate(e.target.value)}
                          className="form-control"
                          min={moment(new Date()).format("YYYY-MM-DD")}
                          max={moment(
                            new Date(curDate.setDate(curDate.getDate() + 90))
                          ).format("YYYY-MM-DD")}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary btn-sm"
                  data-dismiss="modal"
                  onClick={() => setShowEditJoiningDate(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={updateDateHandler}
                >
                  <span>Update</span>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditJoiningDate;
