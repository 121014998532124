import React, { useState, useEffect } from "react";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import Dropzone from "react-dropzone";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import Modal from "react-bootstrap/Modal";
import { countryObj, domain } from "../../../constants/Constants";

function CandidateProfileWidget({ details, resume }) {
  const [resumeUpload, setResumeUpload] = useState(false);
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [resumeInfo, setResumeInfo] = useState({
    fileName: "",
    base64: "",
    fileType: "",
  });
  const [loading, setLoading] = useState(false);
  const [deleteResume, setDeleteResume] = useState(false);
  const [deleteFile, setDeleteFile] = useState("");
  const [resumeList, setResumeList] = useState([]);
  const [profileImg, setImg] = useState("");
  let inputRef = null;

  useEffect(() => {
    setResumeList(JSON.parse(localStorage.getItem("ResumeURL")) || []);
    fetchImg();
  }, []);

  const fetchImg = async () => {
    setLoading(true);
    const params = {
      domain: domain,
      candidateId: details.candidateID,
    };

    let getRes = await post("/CandidateDashboard/getProfileImage", params);
    let userProfileImg = getRes.data.length > 0 ? getRes.data[0].ImageURL : "";
    if (userProfileImg) {
      setImg(userProfileImg);
    }
    setLoading(false);
  };

  const chooseFile = (e) => {
    if (e.target.files[0]) {
      setImageForSending(e.target.files[0]);
    }
  };

  const resumeUploadClick = () => {
    setResumeUpload(true);
  };

  const edit = () => {
    setReadOnlyMode(false);
  };

  const close = () => {
    setResumeInfo({ fileName: "", base64: "" });
    setReadOnlyMode(true);
    setResumeUpload(false);
  };

  const uploadResume = async () => {
    if (resumeInfo.fileName) {
      setLoading(true);
      let params = {
        ...resumeInfo,
        candidateId: details.candidateID,
      };
      const res = await post("/CandidateDashboard/uploadResume", params);
      if (res.status === 200) {
        setLoading(false);
        toast.success("Resume Uploaded Successfully");
        setTimeout(() => {
          window.location.reload();
        }, 5000);
      } else {
        toast.error("Some Error Occurred. Please Try Again");
      }
    } else {
      toast.error("Please Upload Resume");
    }
  };

  //File upload block
  const setFileForSending = (file) => {
    const { name } = file[0];
    let type = name.split(".")[1];
    if (type === "pdf" || type === "docx") {
      const reader = new FileReader();
      reader.readAsDataURL(file[0]);
      reader.onload = (event) => {
        setResumeInfo({
          fileName: name,
          base64: event.target.result,
          fileType: type,
        });
        // toast.success("File uploaded successfully")
      };
    } else {
      toast.error("Accpted Files are pdf and .docx");
    }
  };

  const setImageForSending = async (file) => {
    setLoading(true);
    const name = file.name;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const data = new FormData();
    data.append("file", file);

    let res = await post("/CandidateDashboard/profileImageUpload", data);
    const params = {
      domain: domain,
      candidateId: details.candidateID,
    };
    let getRes = await post("/CandidateDashboard/getProfileImage", params);
    let userProfileImg = getRes.data.length > 0 ? getRes.data[0].ImageURL : "";
    setImg(userProfileImg);
    setLoading(false);
  };

  const resumeDelete = async () => {
    setLoading(true);
    try {
      const params = {
        candidateId: details.candidateID,
        fileName: deleteFile,
      };
      const res = await post("/CandidateDashboard/resumeDelete", params);
      if (res.status === 200) {
        setResumeList(resumeList.filter((r) => r.fileName !== deleteFile));
        setDeleteResume(false);
        setLoading(false);
        toast.success("Resume Deleted Successfully");
      }
    } catch (err) {
      setDeleteResume(false);
      setLoading(false);
      toast.error(
        "Some Error Occurred While Deleting Resume. Please Try Again"
      );
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div id="CandidateProfileWidget" className="card mb-2">
        <div className="card-body">
          {!resume ? (
            <>
              <div className="d-flex align-items-center">
                <div
                  type="button"
                  className="btn btn-text avatar avatar-lg avatar-edit"
                  //onClick={() => inputRef.click()}
                >
                  <Avatar
                    size="60"
                    src={profileImg}
                    name={details.firstName + details.lastName}
                    round="90px"
                  />
                  <span className="edit-icon" onClick={() => inputRef.click()}>
                    <i className="fad fa-pencil-alt" aria-hidden="true"></i>
                  </span>
                  <input
                    type="file"
                    ref={(fileInput) => (inputRef = fileInput)}
                    onChange={chooseFile}
                    className="d-none"
                    accept=".jpg, .jpeg, .png"
                  />
                </div>
                <div className="ml-2 w-100">
                  <div className="w-100 d-flex align-items-center justify-content-between">
                    <h6 className="mb-0">
                      {details.firstName + " " + details.lastName}
                    </h6>
                    <a
                      href="/high5Hire/myprofile"
                      type="button"
                      className="btn btn-icon"
                    >
                      <i className="fal fa-fw fa-edit"></i>
                    </a>
                  </div>
                  <p className="mb-0">
                    {details.experience.length > 0
                      ? details.experience[0].jobTitle
                      : "Designation Not Updated"}
                  </p>
                  {details?.addressCity &&
                  details?.addressState &&
                  details?.country ? (
                    <p className="mb-0">
                      <small>{`${details.addressCity}, ${details.addressState}, ${details.country}`}</small>
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              <div class="d-flex align-items-center justify-content-between mx-2 mt-2">
                <h6 class="mb-0">Resumes</h6>
                {readOnlyMode ? (
                  <>
                    <div>
                      {resumeUpload === false ? (
                        <div>
                          <button
                            type="button"
                            onClick={resumeUploadClick}
                            class="btn btn-text mr-3"
                          >
                            <i class="far fa-cloud-upload mr-1"></i>
                            Upload New
                          </button>
                          <button
                            type="button"
                            onClick={edit}
                            class="btn btn-text"
                          >
                            <i class="fas fa-pencil-alt mr-1"></i>
                            Edit
                          </button>
                        </div>
                      ) : (
                        <div className="d-flex">
                          <button
                            type="button"
                            onClick={close}
                            class="btn btn-text mr-3"
                          >
                            Close Uploader
                          </button>
                          <button
                            className="btn btn-primary btn-sm mr-3"
                            onClick={uploadResume}
                          >
                            Submit
                            {/* { loading ? 'Submitting' : 'Submit' }
                                                        { loading && <img width='20px' alt='loading' src={ButtonLoader} />} */}
                          </button>
                        </div>
                      )}
                    </div>
                  </>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={close}
                      class="btn btn-sm btn-secondary"
                    >
                      Close
                    </button>
                  </div>
                )}
              </div>
              <hr />
              {readOnlyMode ? (
                resumeUpload ? (
                  <div class="row">
                    <div class="col-12">
                      <div class="p-5 p-0-600">
                        <h6 class="mt-2">Upload Resumes</h6>
                        <p>Accepted File Type: .docx, .pdf</p>
                        <div class="col-sm-12">
                          <Dropzone onDrop={setFileForSending}>
                            {({ getRootProps, getInputProps }) => (
                              <section>
                                <div {...getRootProps()}>
                                  <div class="talentuploadmaindiv">
                                    <div class="talentuploadicon">
                                      <label>
                                        <i class="fas fa-cloud-upload-alt"></i>
                                      </label>
                                      <input
                                        type="file"
                                        name="myfile"
                                        {...getInputProps()}
                                      />
                                    </div>
                                    {resumeInfo.fileName ? (
                                      <div class="talentuploadtext">
                                        {resumeInfo.fileName}
                                      </div>
                                    ) : (
                                      <div class="talentuploadtext">
                                        Click or Drag and Drop To Upload
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </section>
                            )}
                          </Dropzone>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>
                    <div class="mb-1 ml-2">
                      {resumeList.length === 0 ? (
                        <h6>No Resumes Found</h6>
                      ) : (
                        <div>
                          <div>
                            <ul class="list-unstyled mt-2">
                              {resumeList.map((item) => (
                                <li class="d-flex align-items-center justify-content-between mb-2">
                                  <strong>{item.fileName}</strong>
                                  <br />
                                  <br />
                                  <a
                                    onClick={(e) => {
                                      e.preventDefault();
                                      window.open(item.fileURL, "_blank");
                                    }}
                                    className="btn btn-sm btn-secondary d-inline-block"
                                  >
                                    <i className="fal fa-cloud-download-alt mr-2"></i>
                                    Download
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )
              ) : (
                <div>
                  {resumeList.length === 0 ? (
                    <h6>No Resumes Found</h6>
                  ) : (
                    <div>
                      <div>
                        <div>
                          {resumeList.map((item) => (
                            <div class="hoverable d-flex justify-content-between p-1">
                              <span>{item.fileName}</span>
                              <button
                                type="button"
                                class="btn btn-text"
                                onClick={() => {
                                  setDeleteFile(item.fileName);
                                  setDeleteResume(true);
                                }}
                              >
                                <i
                                  aria-hidden="true"
                                  class="fas fa-trash-alt"
                                ></i>
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {
        <Modal show={deleteResume}>
          <div>
            <div className="bl-modal-body text-center">
              <div className="avatar avatar-lg avatar-red2 mr-3">
                <i class="fas fa-exclamation-triangle fa-fw"></i>
              </div>
              <p className="text-center lead mt-3">
                You're about to delete <h6 className="m-2">{deleteFile}</h6>
              </p>
              <div class="text-center my-4">
                <button
                  type="button"
                  className="btn btn-secondary mr-3"
                  data-dismiss="modal"
                  onClick={() => setDeleteResume(false)}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="close p-0 bl-modal-close-btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setDeleteResume(false)}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={resumeDelete}
                >
                  Confirm Delete
                </button>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
}

export default CandidateProfileWidget;
