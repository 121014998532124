import React, { useState } from "react";
import Select from "react-select";
import { publishJob } from "../../../../../constants/Constants";

const PublishJob = (props) => {
  const { addJobData, setAddJobData } = props;
  return (
    <div className="card card-flat bg-gray4 m-3">
      <div className="card-body py-0">
        <div class="mb-2 d-flex">
          <label>Publish Job*</label>
        </div>

        <div
          className="form-floating Hover-Dropdown mb-2"
          style={{ zIndex: "1000" }}
        >
          <Select
            options={publishJob}
            name="publishJob"
            onChange={(e) => {
              setAddJobData({
                ...addJobData,
                publishJob: e.value,
              });
            }}
            isSearchable={false}
            value={publishJob?.filter(
              (item) => addJobData?.publishJob == item.value
            )}
          ></Select>
        </div>
      </div>
    </div>
  );
};

export default PublishJob;
