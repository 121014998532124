import React from "react";
import ReactQuill from "react-quill";

const EditTemplate = (props) => {
  const {
    selectedSmsData,
    editedValues,
    handleUpdateClick,
    createNew,
    isValid,
    setEditedValues,
    editMode,
    handleCancelClick,
  } = props;

  const handleInputChange = (field, value) => {
    setEditedValues({ ...editedValues, [field]: value });
  };
  const handleEditorChange = (value, field) => {
    if (editedValues[field] !== value) {
      setEditedValues({ ...editedValues, [field]: value });
    }
  };
  return (
    <>
      <form onSubmit={handleUpdateClick}>
        {createNew && (
          <div>
            <label htmlFor="editName" className="form-label">
              <b>
                Template Name <start style={{ color: "red" }}>*</start>
              </b>
            </label>
            <input
              type="text"
              className="form-control"
              id="editName"
              style={{
                border:
                  isValid &&
                  selectedSmsData?.recipients[0] === "" &&
                  "2px solid #ff0000",
                // backgroundColor: "#F6F6F6",
                borderRadius: "0px",
                fontSize: "0.9em",
              }}
              // value={selectedSmsData?.recipients[0]}
              // disabled={createNew ? false : true}
            />
          </div>
        )}

        <div className="mb-2">
          {" "}
          <label htmlFor="editSubject" className="form-label">
            <b>
              Recipient Persona <start style={{ color: "red" }}>*</start>
            </b>
          </label>
          <input
            type="text"
            className="form-control"
            id="editSubject"
            style={{
              border:
                isValid &&
                selectedSmsData?.recipients[0] === "" &&
                "2px solid #ff0000",
              // backgroundColor: "#F6F6F6",
              borderRadius: "0px",
              fontSize: "0.9em",
            }}
            value={selectedSmsData?.recipients[0]}
            disabled={createNew ? false : true}
          />
        </div>
        <div className="mb-2">
          <label htmlFor="editContent" className="form-label">
            <b>
              Content <start style={{ color: "red" }}>*</start>
            </b>
          </label>
          <ReactQuill
            value={editedValues.smsContent}
            style={{
              border:
                isValid &&
                editedValues.smsContent === "" &&
                "2px solid #ff0000",
            }}
            onChange={(value) => handleEditorChange(value, "smsContent")}
            theme="snow"
            readOnly={!editMode}
          />
          {isValid && editedValues.smsContent === "" && (
            <small className="validation">*Required Field</small>
          )}
        </div>

        {!createNew && (
          <div className="mb-2">
            <button type="submit" className="btn btn-primary btn-sm">
              Update
            </button>
            <button
              type="button"
              className="btn btn-secondary ms-2 btn-sm"
              onClick={() => handleCancelClick()}
            >
              Cancel
            </button>
          </div>
        )}
      </form>
    </>
  );
};

export default EditTemplate;
