import React, { useState } from "react";
import ExportPDF from "./ExportPDF";
import ShareJob from "./sharejob/Index";
import EditJob from "./EditJob";
import DeactivateJob from "./deactivatejob/Index";
import ActivateJob from "./activatejob/Index";
import {
  getUserRole,
  getUserTenantID,
} from "../../../../../service/AuthService";
import ReopenJob from "./ReopenJob";

const Index = (props) => {
  const { sideView, setSideView } = props;
  const handleSidePanelView = (view) => {
    setSideView(view);
  };

  return (
    <div className="col-xl-4">
      <div className="hero-card-header card card-flat card-lg card-bottom-straight">
        <div className="card-body pb-0">
          <div className="d-flex align-items-start">
            <div>
              <p className="mb-0">Job ID</p>
              <p>
                <strong>{props.jobData.jobId}</strong>
              </p>
            </div>
            <div className="ml-auto">
              {/* <ExportPDF /> */}
              {getUserRole() == "Recruiter" &&
                props.jobData.jobStatus !== 23 &&
                props.jobData.jobStatus !== 26 &&
                props.jobData.jobStatus !== 39 && (
                  <ShareJob jobData={props.jobData} />
                )}

              {getUserRole() == "HiringManager" &&
                props.jobData.fk_tenant == getUserTenantID() &&
                props.jobData.jobStatus !== 23 &&
                props.jobData.jobStatus !== 26 &&
                props.jobData.jobStatus !== 39 && (
                  <EditJob jobData={props.jobData} />
                )}

              {getUserRole() == "HiringManager" &&
                props.jobData.fk_tenant === getUserTenantID() &&
                props.jobData.jobStatus !== 23 &&
                props.jobData.jobStatus !== 39 && (
                  <DeactivateJob jobData={props.jobData} />
                )}

              {getUserRole() == "HiringManager" &&
                props.jobData.fk_tenant === getUserTenantID() &&
                props.jobData.jobStatus == 23 &&
                props.jobData.jobStatus !== 39 && (
                  <ActivateJob jobData={props.jobData} />
                )}
                {getUserRole() == "HiringManager" &&
                props.jobData.fk_tenant == getUserTenantID() &&
                props.jobData.jobStatus == 39 && (
                  <ReopenJob jobData={props.jobData} />
                )}
            </div>
          </div>
          <hr className="m-0 hr-white" />
          <ul className="nav nav-tabs" style={{ overflowX: "scroll" }}>
            <li className="nav-item">
              <button
                type="button"
                className={
                  sideView == "overview"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
                onClick={() => handleSidePanelView("overview")}
              >
                Overview
              </button>
            </li>
            <li className="nav-item">
              <button
                type="button"
                className={
                  sideView == "details"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
                onClick={() => handleSidePanelView("details")}
              >
                Details
              </button>
            </li>
            <li className="nav-item">
              <button
                type="button"
                className={
                  sideView == "qna" ? "nav-link pb-3 active" : "nav-link pb-3"
                }
                onClick={() => handleSidePanelView("qna")}
              >
                Screening
              </button>
            </li>
            <li className="nav-item">
              <button
                type="button"
                className={
                  sideView == "notes" ? "nav-link pb-3 active" : "nav-link pb-3"
                }
                onClick={() => handleSidePanelView("notes")}
              >
                Notes
              </button>
            </li>
            <li className="nav-item">
              <button
                type="button"
                className={
                  sideView == "document"
                    ? "nav-link pb-3 active"
                    : "nav-link pb-3"
                }
                onClick={() => handleSidePanelView("document")}
              >
                Document
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Index;
