import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Select from "react-select";
import { toast } from "react-toastify";
import { languages } from "../../../constants/Constants";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Loader from "../../Loader/Loader";
import { useLocation } from "react-router";
// import 'react-circular-progressbar/dist/styles.css'

const AutoMatchScoreCardDS = (props) => {
  const [jobData, setJobData] = useState(props.jobData);
  const [poolId, setPoolId] = useState(props.poolId);
  const [loading, setLoading] = useState(false);
  const [screening, setScreening] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState(["English"]);
  const [isVetting, setIsVetting] = useState(false);
  const [isVetting2, setIsVetting2] = useState(false);
  let education = JSON.stringify(jobData?.education);
  const [candidateData, setCandidateData] = useState(props.candidateData);
  const [vettingDB, setVettingDB] = useState([]);
  const [vettingAssign, setVettingAssign] = useState([]);
  const [vettingNow, setVettingNow] = useState(false);
  const [vettingLater, setVettingLater] = useState(false);
  // useEffect(() => {
  //   setJobData({
  //     ...jobData,
  //     secondarySkills: ["HTML", "CSS"],
  //     location: { city: "New York" },
  //   });
  //   setCandidateData({ ...candidateData, secondarySkills: ["HTML", "CSS"] });
  // }, []);
  let secondarySkills = ["HTML", "CSS"];
  let location = { city: "New York" };
  let column1 = "col-lg-3 text-left";
  let column1Skills = "col-lg-6 text-left";
  let column2Skills = "col-lg-6 text-left";
  let column2 = "col-lg-4 text-left";
  let column3 = "d-none col-lg-2 text-center";
  let column3General = "col-lg-4 text-left";
  const [automatch, setAutomatch] = useState(false);

  const [general, setGeneral] = useState(false);
  const [overallTotal, setOverallTotal] = useState();
  const [matched, setMatched] = useState({
    primary: 0,
    // secondary: 0,
    // industry: 0,
    location: 0,
  });
  const [average, setAverage] = useState([
    { name: "Overall", score: 0 },
    { name: "Mandatory Skills", score: 0 },
    { name: "Good To Have Skills", score: 0 },
    { name: "Location", score: 0 },
    { name: "Industry", score: 0 },
    { name: "Education", score: 0 },
    { name: "Experience", score: 0 },
    { name: "Job Title", score: 0 },
  ]);

  const search = useLocation().recruiter;
  const id = new URLSearchParams(search).get("recruiter");
  console.log("-------------->", id);

  let skillSetOption = [
    {
      id_candidateSource: 1,
      SourceName: "Accounting/Finance",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 2,
      SourceName: "Marketing",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 3,
      SourceName: "Administrative/Clerical",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 4,
      SourceName: "Business Professional",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 5,
      SourceName: "Call Center",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 6,
      SourceName: "Clinical",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 7,
      SourceName: "Communications",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 8,
      SourceName: "Creative",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 9,
      SourceName: "Engineering",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 10,
      SourceName: "Hardware",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 11,
      SourceName: "Healthcare",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 12,
      SourceName: "Human Resources",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 13,
      SourceName: "Information Technology",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 14,
      SourceName: "Legal",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 15,
      SourceName: "Medical",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 16,
      SourceName: "Operations",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 17,
      SourceName: "Procurement",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 18,
      SourceName: "Professional",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 19,
      SourceName: "R&D",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 20,
      SourceName: "Sales",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 21,
      SourceName: "Scientific",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 22,
      SourceName: "Technical (Non-IT)",
      TypeName: "skillSet",
    },
    {
      id_candidateSource: 23,
      SourceName: "Technical Support",
      TypeName: "skillSet",
    },
  ];

  const calculateScore = (jobData, candidateData) => {
    let primary = 0;
    let location = 0;
    let education = 0;
    let jobTitle = 0;
    let experience = 0;
    let sum = 0;
    let count = poolId
      ? JSON.parse(jobData?.skills).length
      : jobData?.primarySkills?.length + poolId
      ? 1
      : jobData?.education?.length + 3;
    (poolId ? JSON.parse(jobData?.skills) : jobData?.primarySkills)?.forEach(
      (skill) => {
        if (candidateData?.primarySkills?.includes(skill)) {
          sum++;
          primary++;
        }
      }
    );
    if (
      poolId === undefined &&
      jobData?.education.includes(props.candidateDetails.highestEducation)
    ) {
      sum++;
      education++;
    }
    if (
      props.candidateDetails.highestEducation === "Master's Degree" ||
      props.candidateDetails.highestEducation === "Doctorate"
    ) {
      sum++;
      education++;
    }
    if (
      [
        candidateData?.addressCity,
        ...candidateData?.preferredLocation,
      ].includes(jobData?.location?.city)
    ) {
      sum++;
      location++;
    }
    if (jobData?.isRemote && candidateData?.isRemote === true) {
      sum++;
      location++;
    }
    if (poolId) {
      if (jobData?.workplaceType == 1 && candidateData?.isRemote === true) {
        sum++;
        location++;
      }
    }
    if (candidateData?.currentJobTitle === jobData?.jobTitle) {
      sum++;
      jobTitle++;
    }
    if (
      candidateData?.experienceLevel ===
      (poolId ? jobData?.requiredExperience : jobData?.experienceLevel)
    ) {
      sum++;
      experience++;
    } else if (
      candidateData?.experienceYear >=
      (poolId ? jobData?.requiredExperience : jobData?.experienceLevel)?.charAt(
        0
      )
    ) {
      sum++;
      experience++;
    }
    let primaryAverage =
      (poolId ? JSON.parse(jobData?.skills) : jobData?.primarySkills)?.length >
      0
        ? Math.round(
            (primary * 100) /
              (poolId ? JSON.parse(jobData?.skills) : jobData?.primarySkills)
                .length
          )
        : 0;
    // console.log('sum',sum, 'count', count,primaryAverage,primary,JSON.parse(jobData?.skills).length)
    let locationAverage = location ? 100 : 0;
    let jobTitleAverage = jobTitle ? 100 : 0;
    let experienceAvg = experience ? 100 : 0;
    let educationAvg = education ? 100 : 0;

    let total = Math.round(
      (primaryAverage +
        locationAverage +
        jobTitleAverage +
        experienceAvg +
        educationAvg) /
        5
    );

    // let total = Math.round((sum * 100) / 5);
    setOverallTotal(total);
    setMatched({
      primary,
      location,
      education,
      experience,
      jobTitle,
    });
    setAverage([
      { name: "Overall", score: total },
      { name: "Mandatory", score: primaryAverage },
      { name: "Location", score: locationAverage },
      { name: "Education", score: educationAvg },
      { name: "Experience", score: experienceAvg },
      { name: "Job Title", score: jobTitleAverage },
    ]);
  };

  useEffect(() => {
    calculateScore(jobData, candidateData);
  }, []);

  const circularProgress = (value) => {
    let a = (
      <div className="col-lg-3">
        <div className="mt-4 align-items-center justify-content-center">
          {/* <p className='ml-1 mb-4'>Average:</p>                                              
                <div style={{ width: '5rem' }}>
                    <CircularProgressbar value={value} text={`${value}%`} />
                </div>                       */}
        </div>
      </div>
    );
    return a;
  };

  const hr = () => {
    let line = (
      <div className="row">
        <div className="col-lg-12">
          <hr className="m-1" />
        </div>
      </div>
    );
    return line;
  };

  // getVetting

  const onClickLater = (event) => {
    vettingMail(event);
  };
  const fetchVetting = async (vetting) => {
    setLoading(true);
    const res = await axios.get(
      "https://https://high5vettinguat-api.azurewebsites.net/test/all",
      {
        headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
      }
    );
    let vett = res.data.filter((i) => i.skills.length === 1);
    setVettingDB(vett);
    let vettings = [];
    let skills = vett?.map((i) => i.skills[0]);
    let DB = [...vetting]?.map((i) => ({
      ...i,
      duration: i.duration?.split(" ")[0],
      type: i.type === "Video MCQ" ? "Video" : i.type,
    }));
    DB.map((item) => {
      if (skills.includes(item.name)) {
        let obj = vett.find((i) => i.skills.includes(item.name));
        if (
          obj.testCategory === item.type &&
          obj.details.duration === item.duration
        ) {
          vettings.push(obj._id);
        }
      }
    });
    setVettingAssign(vettings);
    props.setFetchVettingData(vettings);
    setLoading(false);
    // vettingMail(event);
    // if (vettingAssign.length > 0) {
    //   let body = {
    //     reviewer: {
    //       name: "",
    //       email: "",
    //     },
    //     createdBy: {
    //       id: "62fb9ad4cb6714dd6540e2cf",
    //       name: "John Wang",
    //       role: "user",
    //     },
    //     modifiedBy: {
    //       id: "62fb9ad4cb6714dd6540e2cf",
    //       name: "John Wang",
    //       role: "user",
    //     },
    //     name: candidateData?.firstName,
    //     email: candidateData?.email,
    //     recruiterEmail: "",
    //     high5hireCandidateId: jobData?.jobId,
    //     testAssign: {
    //       id: vettingAssign[0],
    //     },
    //     testInvited: true,
    //     expiryDays: 40,
    //     takesForTest: 3,
    //   };
    //   const res = await axios.post(
    //     "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
    //     body,
    //     {
    //       headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
    //     }
    //   );
    //   if (vettingAssign.length > 1) {
    //     const res2 = await axios.post(
    //       "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
    //       { ...body, testAssign: { id: vettingAssign[1] } },
    //       {
    //         headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
    //       }
    //     );
    //   }
    //   if (vettingAssign.length > 2) {
    //     const res2 = await axios.post(
    //       "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
    //       { ...body, testAssign: { id: vettingAssign[2] } },
    //       {
    //         headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
    //       }
    //     );
    //   }
    //   console.log(res.data);
    //   console.log("sent");
    //   setLoading(false);
    //   event === "now" ? setVettingNow(true) : setVettingLater(true);
    // }
  };
  useEffect(() => {
    if (jobData?.vettingRequired !== false) {
      let vettingData =
        typeof jobData?.vettingDetails === "string"
          ? jobData?.vettingDetails
          : jobData?.vettingDetails;
      // setVettingDetails(vettingData)
      if (poolId) {
      } else {
        fetchVetting(vettingData);
      }
    }
  }, []);

  const vettingMail = async (event) => {
    setLoading(true);
    if (vettingAssign.length > 0) {
      let body = {
        reviewer: {
          name: "",
          email: "",
        },
        createdBy: {
          id: "62fb9ad4cb6714dd6540e2cf",
          name: "John Wang",
          role: "user",
        },
        modifiedBy: {
          id: "62fb9ad4cb6714dd6540e2cf",
          name: "John Wang",
          role: "user",
        },
        name: candidateData?.firstName,
        email: candidateData?.email,
        recruiterEmail: "",
        high5hireCandidateId: jobData?.jobId,
        testAssign: {
          id: vettingAssign[0],
        },
        testInvited: true,
        expiryDays: 40,
        takesForTest: 3,
      };
      const res = await axios.post(
        "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
        body,
        {
          headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
        }
      );
      if (vettingAssign.length > 1) {
        const res2 = await axios.post(
          "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
          { ...body, testAssign: { id: vettingAssign[1] } },
          {
            headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
          }
        );
      }
      if (vettingAssign.length > 2) {
        const res2 = await axios.post(
          "https://https://high5vettinguat-api.azurewebsites.net/candidate/add",
          { ...body, testAssign: { id: vettingAssign[2] } },
          {
            headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
          }
        );
      }
      setLoading(false);
      event === "now" ? setVettingNow(true) : setVettingLater(true);
    }
  };

  return (
    <>
      {loading && <Loader />}
      {!screening && (
        <div>
          <div className="mb-2">
            {/* <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setAutomatch(!automatch)}
            >
              <i
                className={
                  automatch
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              ></i>
              <span>
                <small>Automatching Parameters</small>
              </span>
            </button> */}
            <p className="ml-3">Automatching Parameters</p>
          </div>

          <div className={automatch ? "ml-4 d-none mb-4" : "ml-4 mr-4"}>
            <div className="">
              <table className="w-100">
                <tr>
                  {average.map((item) => (
                    <td className="p-3 vertical-align: top">
                      <div className="d-flex flex-column align-items-center justify-content-end">
                        {item.name !== "Overall" ? (
                          <div style={{ width: "3rem", marginLeft: -20 }}>
                            <CircularProgressbar
                              strokeWidth={3}
                              value={item.score}
                              text={`${item.score}%`}
                              styles={buildStyles({
                                textColor: item.score >= 50 ? "green" : "black",
                                alignItems: "top",
                                pathColor:
                                  item.score >= 50 ? "green" : "orange",
                              })}
                            />
                          </div>
                        ) : (
                          <div style={{ width: "4rem", marginLeft: -20 }}>
                            <CircularProgressbar
                              value={item.score}
                              text={`${item.score}%`}
                              background
                              backgroundPadding={6}
                              styles={buildStyles({
                                backgroundColor:
                                  item.score >= 50 ? "green" : "orange",
                                textColor: "#fff",
                                pathColor: "#fff",
                                trailColor: "transparent",
                              })}
                            />
                          </div>
                        )}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            whiteSpace: "wrap",
                            marginLeft: -5,
                          }}
                        >
                          <small
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                              marginLeft: -5,
                              fontSize: 9,
                            }}
                          >
                            {item.name}
                          </small>
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              </table>
            </div>

            {/*  */}
            <p className="mt-1  ">
              <strong>General: </strong>
            </p>
            <div className="row mt-1">
              <div className={column1}>Parameter</div>
              <div className="col-lg-4 text-left ml-1">Matched</div>
              <div className="col-lg-4 text-left ml-1">Gap</div>
            </div>
            {hr()}
            <div className="row mb-1 font-14">
              <div className={column1}>
                <small>Mandatory Skills</small>
              </div>
              <div className={column2}>
                {jobData?.primarySkills?.map(
                  (skill) =>
                    // <small className="ml-1">
                    candidateData?.primarySkills.includes(skill) && (
                      <small className="ml-1 mb-1 tag tag-green1">
                        {" "}
                        {skill}{" "}
                      </small>
                    )
                  // </small>
                )}
              </div>
              <div
                className="col-lg-4 text-left"
                style={{ width: "40%", marginLeft: "8px" }}
              >
                {jobData?.primarySkills.map(
                  (skill) =>
                    // <small className="ml-1">
                    !candidateData?.primarySkills?.includes(skill) && (
                      <small class="ml-1  tag tag-red1 ">{skill}</small>
                    )
                  // </small>
                )}
              </div>
            </div>

            <div className="row mb-1 font-14">
              <div className={column1}>
                <small>Location</small>
              </div>
              <div className={column2}>
                <small>
                  {jobData?.isRemote
                    ? (candidateData?.isRemote === true && (
                        <small className="ml-1 tag tag-green1"> Remote </small>
                      ) /*: (*/)
                    : // : (
                      //   <small class="ml-1 tag tag-red2">Remote</small>
                      // )
                      [
                        ...candidateData?.preferredLocation,
                        candidateData?.addressCity,
                      ].includes(jobData?.location.city) && (
                        <small class="ml-1 tag tag-green1 ">
                          {" "}
                          {jobData?.location.city}{" "}
                        </small>
                      )
                  //  (
                  //   <small class="ml-1 ">{jobData.location.city}</small>
                  // )
                  }
                </small>
              </div>
              <div className={column2}>
                <small className="ml-1">
                  {jobData?.isRemote ? (
                    candidateData?.isRemote !== true && (
                      <small class="ml-1  tag tag-red1">Remote</small>
                    )
                  ) : [
                      ...candidateData?.preferredLocation,
                      candidateData?.addressCity,
                    ].includes(jobData?.location.city) ? null : (
                    <small class="ml-1 tag tag-red1">
                      {jobData?.location?.city}
                    </small>
                  )}
                </small>
              </div>
            </div>

            <div className="row mb-1 font-14">
              <div className={column1}>
                <small>Job Title</small>
              </div>
              <div className={column2}>
                <small className="ml-1 ">
                  {candidateData?.currentJobTitle?.toLowerCase() ===
                    jobData?.jobTitle?.toLowerCase() && (
                    <small className="tag tag-green1">
                      {jobData?.jobTitle}
                    </small>
                  )}
                </small>
              </div>
              <div className={column2}>
                <small className="ml-1 ">
                  {candidateData?.currentJobTitle?.toLowerCase() !==
                    jobData?.jobTitle?.toLowerCase() && (
                    <small class="ml-1  tag tag-red1">
                      {jobData?.jobTitle}
                    </small>
                  )}
                </small>
              </div>
            </div>

            {jobData?.education?.length > 0 && (
              <div className="row mb-1 font-14 ">
                <div className={column1}>
                  <small>Education</small>
                </div>

                <div className={column2}>
                  {jobData?.education.map((skill) => (
                    <small className="">
                      {props.candidateDetails.highestEducation.includes(
                        skill
                      ) ? (
                        <small className="ml-1 tag tag-green1 ">
                          {" "}
                          {skill}{" "}
                        </small>
                      ) : (
                        props.candidateDetails.highestEducation ===
                          "Master's Degree" && (
                          <small className="ml-1 tag tag-green1 ">
                            {" "}
                            {skill}{" "}
                          </small>
                        )
                      )}
                    </small>
                  ))}
                  {props?.candidateDetails?.highestEducation === "Doctorate" &&
                    jobData?.education?.map((skill) => (
                      <small className="ml-1">
                        {props?.candidateDetails?.highestEducation?.includes(
                          skill
                        ) ? (
                          <small className="tag tag-green1 "> {skill} </small>
                        ) : (
                          <small className="tag tag-green1 "> {skill} </small>
                        )}
                      </small>
                    ))}
                </div>
                {/* <div className={column2}>
                  {jobData.education.map((skill) => (
                    <small className="ml-1">
                      {(props.candidateDetails.highestEducation !==
                        "Master's Degree" ||
                        props.candidateDetails.highestEducation !==
                          "Doctorate") &&
                        !props.candidateDetails.highestEducation.includes(
                          skill
                        ) && <small class="ml-1 tag tag-red1 ">{skill}</small>}
                       {props.candidateDetails.highestEducation !==
                        "Doctorate" &&
                        !props.candidateDetails.highestEducation.includes(
                          skill
                        ) && <small class="ml-1 tag tag-red1 ">{skill}</small>} 
                    </small>
                  ))}
                </div> */}
                <div className={column2}>
                  {props.candidateDetails.highestEducation !==
                    "Master's Degree" &&
                    props.candidateDetails.highestEducation !== "Doctorate" &&
                    jobData?.education.map((skill) => (
                      <small className="ml-1">
                        {!props.candidateDetails.highestEducation.includes(
                          skill
                        ) && <small class="ml-1 tag tag-red1 ">{skill}</small>}
                      </small>
                    ))}
                </div>
              </div>
            )}

            {jobData?.experienceLevel && (
              <div className="row mb-1 font-14">
                <div className={column1}>
                  <small>Experience Level</small>
                </div>
                {/* <div className={column2}>
              <small>{jobData.experienceLevel}</small>
            </div> */}
                <div className={column2}>
                  <small className="ml-1 ">
                    {candidateData?.experienceYear >=
                      jobData?.experienceLevel?.charAt(0) && (
                      <small className="tag tag-green1">
                        {jobData?.experienceLevel}
                      </small>
                    )}
                    {/* {candidateData?.experienceLevel?.includes(
                      jobData.experienceLevel
                    ) && (
                      <small className="tag tag-green1">
                        {jobData.experienceLevel}
                      </small>
                    )} */}
                  </small>
                </div>
                <div className={column2}>
                  <small className="ml-1 ">
                    {candidateData?.experienceYear <
                      jobData?.experienceLevel?.charAt(0) && (
                      <small class="ml-1 tag tag-red1">
                        {jobData?.experienceLevel}
                      </small>
                    )}
                    {/* {!candidateData?.experienceLevel?.includes(
                      jobData.experienceLevel
                    ) && (
                      <small class="ml-1 tag tag-red1">
                        {jobData.experienceLevel}
                      </small>
                    )} */}
                  </small>
                </div>
              </div>
            )}
            {/*  */}

            {/* <div className="row mt-3">
          <div className="col-lg-9 w-100">
            <p className="mt-4 mb-3">
              <strong>Mandatory Skills: </strong>
              <span className="ml-2 tag tag-blue3">
                Matched {matched.primary} of {jobData.primarySkills.length}
              </span>{" "}
            </p>
            <div className="row mb-1 mt-3">
              <div className={column1Skills}>Skills Mentioned in Job</div>
              <div className={column2}>Candidate Skills</div>
              <div className={column3}>Result</div>
            </div>
            {hr()}
            {jobData.primarySkills.map((skill) => (
              <div className="row mb-1">
                <div className={column1Skills}>
                  <small>{skill}</small>
                </div>
                <div className={column2Skills}>
                  <small className="ml-1">
                    {candidateData?.primarySkills.includes(skill) ? (
                      skill
                    ) : (
                      <small class="tag tag-red2">No</small>
                    )}
                  </small>
                </div>
                <div className={column3}>
                  <small>
                    {candidateData?.primarySkills.includes(skill)
                      ? "100%"
                      : "0%"}
                  </small>
                </div>
              </div>
            ))}
            {candidateData?.primarySkills
              .filter((i) => !jobData.primarySkills.includes(i))
              .map((skill) => (
                <div className="row mb-1">
                  <div className={column1Skills}>
                    <small>
                      <span class="tag tag-red2">No</span>
                    </small>
                  </div>
                  <div className={column2Skills}>
                    <small className="ml-1">{skill}</small>
                  </div>
                  <div className={column3}>
                    <small>{"0%"}</small>
                  </div>
                </div>
              ))}
          </div>
        </div> */}

            {/* <div className="row mt-3">
          <div className="col-lg-9">
            <p className="mt-4 mb-2">
              <strong>Good to have Skills:</strong>
              <span className="ml-2 tag tag-blue3">
                Matched {matched.secondary} of {secondarySkills.length}
              </span>
            </p>
            <div className="row mb-1 font-14">
              <div className={column1Skills}>Skills Mentioned in Job</div>
              <div className={column2}>Candidate Skills</div>
              <div className={column3}>Result</div>
            </div>
            {hr()}
            {secondarySkills.map((skill) => (
              <div className="row mb-1">
                <div className={column1Skills}>
                  <small>{skill}</small>
                </div>
                <div className={column2Skills}>
                  <small className="ml-1">
                    {secondarySkills.includes(skill) ? (
                      skill
                    ) : (
                      <small class="tag tag-red2">No</small>
                    )}
                  </small>
                </div>
                <div className={column3}>
                  <small>
                    {secondarySkills.includes(skill) ? "100%" : "0%"}
                  </small>
                </div>
              </div>
            ))}
            {secondarySkills
              .filter((i) => !secondarySkills.includes(i))
              .map((skill) => (
                <div className="row mb-1">
                  <div className={column1Skills}>
                    <small>{<small class="tag tag-red2">No</small>}</small>
                  </div>
                  <div className={column2Skills}>
                    <small className="ml-1">{skill}</small>
                  </div>
                  <div className={column3}>
                    <small>{"0%"}</small>
                  </div>
                </div>
              ))}
            {secondarySkills.length === 0 && secondarySkills.length === 0 && (
              <div className="row mb-1">
                <div className={column1Skills}>
                  <small>{"No Data Found"}</small>
                </div>
                <div className="col-lg-4">
                  <small className="ml-1">{"No Data Found"}</small>
                </div>
                <div className={column3}>
                  <small>{<small class="tag tag-red2">No</small>}</small>
                </div>
              </div>
            )}
          </div>
          {circularProgress(average.secondary)}
        </div> */}

            {/* <div className="row mt-3">
          <div className="col-lg-9 w-100">
            <p className="mt-4 mb-2">
              <strong>Industry: </strong>
              <span className="ml-2 tag tag-blue3">
                Matched {matched.industry} of{" "}
                {JSON.parse(jobData.skillSet).length}
              </span>
            </p>
            <div className="row mb-1 font-14">
              <div className={column1Skills}>Job</div>
              <div className={column2Skills}>Candidate</div>
              <div className={column3}>Result</div>
            </div>
            {hr()}
            {JSON.parse(jobData.skillSet).map((skill) => (
              <div className="row mb-1">
                <div className={column1Skills}>
                  <small>{skill}</small>
                </div>
                <div className={column2Skills}>
                  <small className="ml-1">
                    {candidateData?.skillSet.includes(skill) ? (
                      skill
                    ) : (
                      <small class="tag tag-red2">No</small>
                    )}
                  </small>
                </div>
                <div className={column3}>
                  <small>
                    {candidateData?.skillSet.includes(skill) ? "100%" : "0%"}
                  </small>
                </div>
              </div>
            ))}
            {candidateData?.skillSet
              .filter((i) => !JSON.parse(jobData.skillSet).includes(i))
              .map((skill) => (
                <div className="row mb-1">
                  <div className={column1Skills}>
                    <small>{<small class="tag tag-red2">No</small>}</small>
                  </div>
                  <div className={column2Skills}>
                    <small className="ml-1">{skill}</small>
                  </div>
                  <div className={column3}>
                    <small>{"0%"}</small>
                  </div>
                </div>
              ))}
            {JSON.parse(jobData.skillSet).length === 0 &&
              candidateData?.skillSet.length === 0 && (
                <div className="row mb-1">
                  <div className={column1Skills}>
                    <small>{"No Data Found"}</small>
                  </div>
                  <div className="col-lg-4">
                    <small className="ml-1">{"No Data Found"}</small>
                  </div>
                  <div className={column3}>
                    <small>{<small class="tag tag-red2">No</small>}</small>
                  </div>
                </div>
              )}
          </div>
        </div> */}

            {/* <div className="row mt-3 mb-4">
          <div className="col-lg-9 w-100">
            <p className="mt-4 mb-2">
              <strong>Location: </strong>
              <span className="ml-2 tag tag-blue3">
                Matched {matched.location} of 1
              </span>
            </p>
            <div className="row mb-1 font-14">
              <div className={column1Skills}>Job</div>
              <div className={column2Skills}>Candidate</div>
              <div className={column3}>Result</div>
            </div>
            {hr()}
            <div className="row mb-1">
              <div className={column1Skills}>
                <small>{jobData.isRemote ? "Remote" : location.city}</small>
              </div>
              <div className={column2Skills}>
                <small>
                  {jobData.isRemote ? (
                    candidateData?.preferredLocation.includes("Remote") ? (
                      "Remote"
                    ) : (
                      <small class="tag tag-red2">No</small>
                    )
                  ) : [
                      ...candidateData?.preferredLocation,
                      candidateData?.addressCity,
                    ].includes(location.city) ? (
                    location.city
                  ) : (
                    <small class="tag tag-red2">No</small>
                  )}
                </small>
              </div>
              <div className={column3}>
                <small>{false ? "100%" : "0%"}</small>
              </div>
            </div>

          </div>
        </div> */}

            {/* //<div className={general ? "ml-4 d-none mb-4" : "ml-4"}>  */}
            {/* <p className="mt-4 mb-2 ">
          <strong>General: </strong>
        </p> */}
            {/* <div className="row mt-3">
          <div className={column1}>Parameter</div>
          <div className={column2}>Job</div>
          <div className={column3General}>Candidate</div>
        </div> */}
            {/* {hr()} */}
            {/* <div className="row mb-1 font-14">
          <div className={column1}>
            <small>Job Title</small>
          </div>
          <div className={column2}>
            <small>{jobData.jobTitle}</small>
          </div>
          <div className={column3General}>
            <small>
              {candidateData?.currentJobTitle?.toLowerCase() ===
              jobData.jobTitle.toLowerCase() ? (
                jobData.jobTitle
              ) : (
                <small class="tag tag-red2">No</small>
              )}
            </small>
          </div>
        </div> */}
            {/* {JSON.parse(jobData.education).length > 0 && (
          <div className="row mb-1 font-14 ">
            <div className={column1}>
              <small>Education</small>
            </div>
            <div className={column2}>
              <small>{JSON.parse(jobData.education)[0]}</small>
            </div>
            <div className={column3General}>
              <small>
                {matched.education ? (
                  JSON.parse(jobData.education)[0]
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )} */}
            {/* {JSON.parse(jobData.certifications).length > 0 && (
          <div className="row mb-1 font-14 ">
            <div className={column1}>
              <small>Certification</small>
            </div>
            <div className={column2}>
              <small>{JSON.parse(jobData.certifications)[0]}</small>
            </div>
            <div className={column3General}>
              <small>
                {candidateData?.certifications.filter((i) => i).length > 0 ? (
                  candidateData?.certifications
                    .map((i) => i.certificationName)
                    .includes(JSON.parse(jobData.certifications)[0]) ? (
                    JSON.parse(jobData.certifications)[0]
                  ) : (
                    <small class="tag tag-red2">No</small>
                  )
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )} */}
            {/* {jobData.visaRequirement && (
          <div className="row mb-1 font-14">
            <div className={column1}>
              <small>Visa Requirement</small>
            </div>
            <div className={column2}>
              <small>
                {jobData.visaRequirement.charAt(0) === "["
                  ? JSON.parse(jobData.visaRequirement).join(", ")
                  : jobData.visaRequirement}
              </small>
            </div>
            <div className={column3General}>
              <small>
                {(jobData.visaRequirement.charAt(0) === "[" ? (
                  JSON.parse(jobData.visaRequirement).includes(
                    candidateData?.visaStatus
                  )
                ) : (
                  candidateData?.visaStatus === jobData.visaRequirement
                )) ? (
                  candidateData?.visaStatus
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )} */}
            {/* <div className="row mb-1 font-14 ">
          <div className={column1}>
            <small>Position Type</small>
          </div>
          <div className={column2}>
            <small>{jobData.jobType}</small>
          </div>
          <div className={column3General}>
            <small>
              {candidateData?.positionTypes
                .map((i) => i.slice(0, 3).toLowerCase())
                .includes(jobData.jobType.slice(0, 3).toLowerCase()) ? (
                jobData.jobType
              ) : (
                <small class="tag tag-red2">No</small>
              )}
            </small>
          </div>
        </div> */}
            {/* {jobData.experienceLevel && (
          <div className="row mb-1 font-14">
            <div className={column1}>
              <small>Experience Level</small>
            </div>
            <div className={column2}>
              <small>{jobData.experienceLevel}</small>
            </div>
            <div className={column3General}>
              <small>
                {candidateData?.experienceLevel?.includes(
                  jobData.experienceLevel
                ) ? (
                  jobData.experienceLevel
                ) : (
                  <small class="tag tag-red2">No</small>
                )}
              </small>
            </div>
          </div>
        )} */}
            <div className="mb-8"></div>
            <div className="m-3">
              <h6 className="p-2">{null}</h6>
            </div>
            <hr />
            {overallTotal >= 50 ? (
              <p className=" mt-4 mb-3 d-flex align-items-center justify-content-between">
                <strong>{`Great! You have made it. Your profile has matched, proceed to apply for the job.`}</strong>
                <button
                  className="btn btn-sm btn-secondary mr-3"
                  onClick={() => {
                    if (poolId) {
                      props.applyJob(setIsVetting2);
                    } else if (JSON.parse(props.jobData?.QandA).length === 0) {
                      props.applyJob(setIsVetting2);
                    } else {
                      props.setScreeningQuestions(true);
                      setScreening(true);
                    }
                  }}
                >
                  Proceed
                </button>
              </p>
            ) : (
              <>
                <p className="mt-4 mb-1 ">
                  <strong>
                    Unfortunately, your resume do not match with the job. You
                    can update your resume by adding the recommended parameters
                    and apply again or you can{" "}
                    {/* <a href="" onClick={() => props.joinCommunityScoreCard()}> */}
                    join our community.
                    {/* </a>{" "} */}
                  </strong>
                </p>
                <button
                  className="btn btn-sm btn-secondary mr-3 float-end mb-2"
                  onClick={() => {
                    props.setScoreCard(false);
                    props.joinCommunityScoreCard();
                    // props.setAskResume(true);
                    // props.setLogin(true);
                  }}
                >
                  Join our community
                </button>
                <button
                  className="btn btn-sm btn-secondary mr-3 float-end mb-2"
                  onClick={() => {
                    props.setScoreCard(false);
                    props.setAskResume(true);
                    props.setLogin(true);
                    props.setIsChecked(false);
                  }}
                >
                  Update Resume
                </button>
              </>
            )}
          </div>
        </div>
      )}

      {screening && (
        <div className="mb-4">
          <div className="row mx-3">
            <h4>
              You are almost there, please answer the following screening
              questions and complete your application
            </h4>
          </div>
          {JSON.parse(jobData?.QandA).map((item, idx) => (
            <>
              {item.name === "Background Check" ||
              item.name === "Drug Check" ||
              item.name === "Education" ||
              item.name === "Driving Licence" ||
              item.name === "Location" ||
              item.name === "Remote Work" ||
              item.name === "Urgent Hiring Need" ? (
                <div className="row mx-3 mt-4">
                  <span className=""></span>
                  <div className="col-md-8">
                    {`${idx === 0 ? idx + 1 : idx}. ${item.question}`}
                  </div>
                  <div className="col-md-4">
                    <div className="form-check form-switch ">
                      <input
                        className="form-check-input ml-3"
                        type="checkbox"
                        id="isFresher"
                        // checked={props.profileInfo.isRemote}
                        // onChange={(e) =>
                        //   props.setProfileInfo({
                        //     ...props.profileInfo,
                        //     isRemote: e.target.checked,
                        //   })
                        // }
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {item.name === "Industry Experience" && (
                <div className="row mx-3 mt-4">
                  <div className="col-md-12">{`${idx + 1}. ${
                    item.question
                  }`}</div>
                  <div className="form-floating w-40 mt-2">
                    <select
                      className="form-select font-14"
                      name="referralCurrency"
                      // onChange={(e) => handleChange(e.target.value, item.name)}
                    >
                      {skillSetOption
                        .map((i) => i.SourceName)
                        .map((item2) => (
                          <option selected={item2 === item.input} value={item2}>
                            {item2}
                          </option>
                        ))}
                    </select>
                    <label className="ml-1">Industry</label>
                  </div>
                </div>
              )}

              {item.name === "Language" && (
                <div className="row mx-3 mt-4">
                  <div className="col-md-12">{`${idx + 1}. ${
                    item.question
                  }`}</div>
                  <div className="col-md-4 mt-2">
                    <div className=" ">
                      {/* <label className="mb-2">Language</label> */}
                      <div className="form-floating font-14 Hover-Dropdown">
                        <Select
                          isMulti
                          options={languages.map((lang) => ({
                            value: lang,
                            label: lang,
                          }))}
                          value={selectedLanguages.map((i) => ({
                            value: i,
                            label: i,
                          }))}
                          name="Qualification"
                          onChange={(e) => {
                            setSelectedLanguages(
                              Array.isArray(e) ? e.map((x) => x.value) : []
                            );
                          }}
                          isSearchable={true}
                          defaultValue={{ value: "English" }}
                        ></Select>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {item.name === "Work Experience" && (
                <div className="row mx-3 mt-4">
                  <div className="col-md-12">{`${idx + 1}. ${
                    item.question
                  }`}</div>
                  <div className="col-md-2 mt-2">
                    <input
                      class="form-control"
                      type="number"
                      // placeholder="Description"
                      id="description"
                      // value={props.profileInfo.description}
                      // onChange={handleChange}
                    />
                  </div>
                </div>
              )}

              {item.name === "Custom Question" && item.input === "Yes/No" ? (
                <div className="row mx-3 mt-4">
                  <div className="col-md-8">{`${idx + 1}. ${
                    item.question
                  }`}</div>
                  <div className="col-md-4">
                    <div className="form-check form-switch ">
                      <input
                        className="form-check-input ml-3"
                        type="checkbox"
                        id="isFresher"
                        // checked={props.profileInfo.isRemote}
                        // onChange={(e) =>
                        //   props.setProfileInfo({
                        //     ...props.profileInfo,
                        //     isRemote: e.target.checked,
                        //   })
                        // }
                      />
                    </div>
                  </div>
                </div>
              ) : item.input === "Numeric" ? (
                <div className="row mx-3 mt-4">
                  <div className="col-md-12">{`${idx + 1}. ${
                    item.question
                  }`}</div>
                  <div className="col-md-2 mt-2">
                    <input
                      class="form-control"
                      type="number"
                      // placeholder="Description"
                      id="description"
                      // value={props.profileInfo.description}
                      // onChange={handleChange}
                    />
                  </div>
                </div>
              ) : (
                item.input === "Long Text" ||
                (item.input === "Text" && (
                  <div className="row mx-3 mt-4">
                    <div className="col-md-12">{`${idx + 1}. ${
                      item.question
                    }`}</div>
                    <div className="col-md-8 mt-2">
                      <textarea
                        class="form-control"
                        // placeholder="Description"
                        id="description"
                        // value={props.profileInfo.description}
                        // onChange={handleChange}
                      />
                    </div>
                  </div>
                ))
              )}
            </>
          ))}
          {/* <div className="row mx-3 mt-4">
            <div className="col-md-8">
              1. Are you willing to undergo a background check, in accordance
              with local law/regulations?
            </div>
            <div className="col-md-4">
              <div className="form-check form-switch ">
                <input
                  className="form-check-input ml-3"
                  type="checkbox"
                  id="isFresher"
                  // checked={props.profileInfo.isRemote}
                  // onChange={(e) =>
                  //   props.setProfileInfo({
                  //     ...props.profileInfo,
                  //     isRemote: e.target.checked,
                  //   })
                  // }
                />
              </div>
            </div>
          </div> */}

          {/* <div className="row mx-3 mt-4">
            <div className="col-md-12">2.Open for outsource project ?</div>
            <div className="col-md-8 mt-2">
              <textarea
                class="form-control"
                // placeholder="Description"
                id="description"
                // value={props.profileInfo.description}
                // onChange={handleChange}
              />
            </div>
          </div> */}

          {/* <div className="row mx-3 mt-4">
            <div className="col-md-8">
              3. Are you comfortable commuting to job's location?
            </div>
            <div className="col-md-4">
              <div className="form-check form-switch ">
                <input
                  className="form-check-input ml-3"
                  type="checkbox"
                  id="isFresher"
                  // checked={props.profileInfo.isRemote}
                  // onChange={(e) =>
                  //   props.setProfileInfo({
                  //     ...props.profileInfo,
                  //     isRemote: e.target.checked,
                  //   })
                  // }
                />
              </div>
            </div>
          </div> */}
          {/* <div className="row mx-3 mt-4">
            <div className="col-md-12">
              4. What is your level of proficiency in following Languages?
            </div>
            <div className="col-md-4 mt-2">
              <div className=" ">
                <div className="form-floating font-14 Hover-Dropdown">
                  <Select
                    isMulti
                    options={languages.map((lang) => ({
                      value: lang,
                      label: lang,
                    }))}
                    value={selectedLanguages.map((i) => ({
                      value: i,
                      label: i,
                    }))}
                    name="Qualification"
                    onChange={(e) => {
                      setSelectedLanguages(
                        Array.isArray(e) ? e.map((x) => x.value) : []
                      );
                    }}
                    isSearchable={true}
                    defaultValue={{ value: "English" }}
                  ></Select>
                </div>
              </div>
            </div>
          </div> */}

          <div className="float-end my-3 mr-4">
            <button
              className="btn btn-sm btn-secondary "
              onClick={() => {
                // toast.success("You have successfully applied for the job!");
                // setIsVetting(true);
                props.applyJob();
                // props.setScoreCard(false)
              }}
            >
              Apply
            </button>
          </div>

          {/* Vettinog */}
          <Modal show={isVetting} size="lg">
            <Modal.Header>
              <h6 class="">Vetting Requirement</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setIsVetting(false);
                  props.setCandidateDetails(props.data);
                }}
                class="close p-0 bl-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            {vettingNow === false && vettingLater === false && (
              <Modal.Body>
                <div className="mx-4 my-4">
                  <div className="row">
                    <h4 className="text-center " style={{ color: "#FF5F57" }}>
                      {" "}
                      Congratulations!
                      <br />
                    </h4>
                    <br />
                    <h5 className="text-center mt-2">
                      {" "}
                      You have successfully applied for the job.
                    </h5>
                    <br />
                    <br />
                    <strong className="text-center mt-2">
                      One last step! The hiring manager is looking for the
                      vetted talent.
                      <br /> If you want to take the assessment now
                      <br />
                      <button
                        className="btn btn-sm btn-primary mx-2 my-2"
                        onClick={() => onClickLater("now")}
                      >
                        Press Here
                      </button>{" "}
                      or
                      <button
                        className="btn btn-sm btn-secondary ml-2 mx-2 my-2"
                        onClick={() => onClickLater("later")}
                      >
                        Skip
                      </button>
                      <br />
                      to receive email with the assessment detail, however you
                      must complete the assessment in 24 hours.
                    </strong>
                  </div>
                  {/* <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-center">
                      <strong>Want to give assessment ?</strong>
                      <br />
                      <button className="btn btn-sm btn-primary mx-2">
                        Now
                      </button>
                      <strong>or</strong>
                      <button className="btn btn-sm btn-secondary ml-2 mx-2">
                        Later
                      </button>
                    </div>
                  </div>
                </div> */}
                </div>
              </Modal.Body>
            )}
            {vettingNow && (
              <Modal.Body>
                <div className="mx-4 my-4">
                  <div className="row">
                    <strong className="text-center mt-2">
                      We have sent you the link of assessment along with unique
                      code for assessment on your registered email.
                      <br /> Please check them and complete the assessment.
                      <br />
                      <button
                        className="btn btn-sm btn-primary mx-2"
                        onClick={() => {
                          setVettingNow(false);
                          setVettingLater(false);
                        }}
                      >
                        Close
                      </button>{" "}
                    </strong>
                  </div>
                </div>
              </Modal.Body>
            )}
            {vettingLater && (
              <Modal.Body>
                <div className="mx-4 my-4">
                  <div className="row">
                    <strong className="text-center mt-2">
                      We have sent you the link of assessment along with unique
                      code for assessment on your registered email.
                      <br /> Please check them and complete the assessment
                      within 24 hours.
                      <br />
                      <button
                        className="btn btn-sm btn-primary mx-2"
                        onClick={() => {
                          setVettingNow(false);
                          setVettingLater(false);
                        }}
                      >
                        Close
                      </button>{" "}
                    </strong>
                  </div>
                </div>
              </Modal.Body>
            )}
          </Modal>

          {/* Vetting without screening */}
          <Modal show={isVetting2} size="lg">
            <Modal.Header>
              <h6 class="">Vetting Requirement</h6>
              <button
                type="button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setIsVetting2(false);
                  props.setCandidateDetails(props.data);
                }}
                class="close p-0 bl-modal-close-btn"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            {vettingNow === false && vettingLater === false && (
              <Modal.Body>
                <div className="mx-4 my-4">
                  <div className="row">
                    <h4 className="text-center " style={{ color: "#FF5F57" }}>
                      {" "}
                      Congratulations!
                      <br />
                    </h4>
                    <br />
                    <h5 className="text-center mt-2">
                      {" "}
                      You have successfully applied for the job.
                    </h5>
                    <br />
                    <br />
                    <strong className="text-center mt-2">
                      One last step! The hiring manager is looking for the
                      vetted talent.
                      <br /> If you want to take the assessment now
                      <br />
                      <button
                        className="btn btn-sm btn-primary mx-2"
                        onClick={() => onClickLater("now")}
                      >
                        Press Here
                      </button>{" "}
                      or
                      <button
                        className="btn btn-sm btn-secondary ml-2 mx-2"
                        onClick={() => onClickLater("later")}
                      >
                        Skip
                      </button>
                      <br />
                      to receive email with the assessment detail, however you
                      must complete the assessment in 24 hours.
                    </strong>
                  </div>
                  {/* <div className="row mt-5">
                  <div className="col-md-12">
                    <div className="d-flex align-items-center justify-content-center">
                      <strong>Want to give assessment ?</strong>
                      <br />
                      <button className="btn btn-sm btn-primary mx-2">
                        Now
                      </button>
                      <strong>or</strong>
                      <button className="btn btn-sm btn-secondary ml-2 mx-2">
                        Later
                      </button>
                    </div>
                  </div>
                </div> */}
                </div>
              </Modal.Body>
            )}
            {vettingNow && (
              <Modal.Body>
                <div className="mx-4 my-4">
                  <div className="row">
                    <strong className="text-center mt-2">
                      We have sent you the link of assessment and unique code
                      for assessment on your registered email.
                      <br /> Please check them and complete the assessment.
                      <br />
                      <button
                        className="btn btn-sm btn-primary mx-2"
                        onClick={() => {
                          setVettingNow(false);
                          setVettingLater(false);
                        }}
                      >
                        Close
                      </button>{" "}
                    </strong>
                  </div>
                </div>
              </Modal.Body>
            )}
            {vettingLater && (
              <Modal.Body>
                <div className="mx-4 my-4">
                  <div className="row">
                    <strong className="text-center mt-2">
                      We have sent you the link of assessment and unique code
                      for assessment on your registered email.
                      <br /> Please check them and complete the assessment
                      within 24 hours.
                      <br />
                      <button
                        className="btn btn-sm btn-primary mx-2"
                        onClick={() => {
                          setVettingNow(false);
                          setVettingLater(false);
                        }}
                      >
                        Close
                      </button>{" "}
                    </strong>
                  </div>
                </div>
              </Modal.Body>
            )}
          </Modal>
        </div>
      )}
    </>
  );
};

export default AutoMatchScoreCardDS;
