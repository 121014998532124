// #####################
// Routes List
// #####################

const common = [
  {
    component: "SessionTimeOut",
    url: "/sessionTimeOut",
    title: "SessionTimeOut",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "AdminConsole",
    url: "/adminConsole",
    title: "AdminConsole",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "MarketplaceOverview",
    url: "/marketoverview",
    title: "MarketplaceOverview",
    role: ["HiringManager"],
  },
  {
    component: "MyProfile",
    url: "/myprofile",
    title: "MyProfile",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "UpdateProfileInfo",
    url: "/updateProfile",
    title: "UpdateProfileInfo",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "AuditLog",
    url: "/auditLog",
    title: "AuditLog",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "ParsingComparison",
    url: "/parsingComparison",
    title: "ParsingComparison",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "CommonTalentList",
    url: "/talents",
    title: "commonTalent",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "CommonTalentList",
    url: "/talents/:parent",
    title: "commonTalent",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "CandidateInterviews",
    url: "/candidateinterviews",
    title: "Candidate Interviews",
    role: ["Candidate"],
  },
  {
    component: "CandidateAssesmentList",
    url: "/candidateassesments",
    title: "Candidate Assessment List",
    role: ["Candidate"],
  },
  {
    component: "CandidateRequestList",
    url: "/candidatejobs",
    title: "Candidate Request List",
    role: ["Candidate"],
  },
  {
    component: "ViewJobDetails",
    url: "/requests/:Id",
    title: "View Job Details",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "ViewJobDetails",
    url: "/requests/:Id/:Tab",
    title: "View Job Details",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "JobList",
    url: "/requests",
    title: "Request List",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "CmMasterReport",
    url: "/masterreport",
    title: "Master Report",
    role: ["CommunityManager", "HiringManager"],
  },
  {
    component: "HotlistReport",
    url: "/hotlistreport",
    title: "Hotlist Report",
    role: ["Recruiter", "CommunityManager"],
  },
  {
    component: "CandidateReport",
    url: "/cmcandidatereport",
    title: "Candidate Report",
    role: ["CommunityManager"],
  },
  {
    component: "RecruiterReport",
    url: "/cmrecruiterreport",
    title: "Candidate Report",
    role: ["CommunityManager"],
  },
  {
    component: "JobReport",
    url: "/jobreport",
    title: "Job Report",
    role: ["HiringManager", "CommunityManager"],
  },
  {
    component: "HotlistReport",
    url: "/cmhotlistreport",
    title: "Cm Hotlist Report",
    role: ["CommunityManager"],
  },
  {
    component: "GigRecruiterReport",
    url: "/gigreport",
    title: "Gig Recruiter Report",
    role: ["CommunityManager"],
  },
  {
    component: "CmMasterReport",
    url: "/cmmasterreport",
    title: "Cm Master Report",
    role: ["CommunityManager"],
  },
  {
    component: "SearchJobs",
    url: "/searchjobs",
    title: "Search Jobs",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "PageNotFound",
    url: "/404",
    title: "Page Not Found",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "Users",
    url: "/users",
    title: "users",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "EmailBroadCast",
    url: "/broadcast",
    title: "broadcast",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "Performance",
    url: "/performancereport",
    title: "Performance",
    role: ["CommunityManager"],
  },
  {
    component: "UnsubUserReport",
    url: "/unsubUsers",
    title: "UnsubUserReport",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "UnsubTalentReport",
    url: "/unsubTalents",
    title: "UnsubTalentReport",
    role: ["Recruiter", "CommunityManager"],
  },
  {
    component: "FAQSetup",
    url: "/faqSetup",
    title: "FAQSetup",
    role: [
      "Admin",
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "JobTemplate",
    url: "/jobTemplate",
    title: "JobTemplate",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "EmailTemplate",
    url: "/emailTemplate",
    title: "Email Template",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "smsTemplate",
    url: "/smsTemplate",
    title: "SMS Template",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "CronJobs",
    url: "/cronjobs",
    title: "Cron Jobs",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "PerformanceTesting",
    url: "/performancetesting",
    title: "Performance",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
  {
    component: "TalentCommunity",
    url: "/talentcommunity/groups",
    title: "TalentCommunity",
    state: "group",
    role: [
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "OpenGroup",
    url: "/talentcommunity/groups/:groupId",
    title: "Talent Community",
    role: [
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
    ],
  },
  {
    component: "CommunityList",
    url: "/talentcommunity/client/:client",
    title: "Talent Pool",
    role: ["Recruiter"],
  },
  {
    component: "TalentPool",
    url: "/talentcommunity/pools",
    title: "Talent Pool",
    role: ["Recruiter", "HiringManager", "Admin"],
  },

  {
    component: "TalentPool",
    url: "/talentcommunity/pools?view=${viewParam}",
    title: "Talent Pool",
    role: [
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
      "Admin",
    ],
  },
  {
    component: "PoolCard",
    url: "/talentcommunity/pools/:poolId",
    title: "Talent Pool",
    role: [
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
      "Admin",
    ],
  },
  {
    component: "PoolCard",
    url: "/talentcommunity/pools/:poolId/:Tab",
    title: "Talent Pool",
    role: [
      "Recruiter",
      "HiringManager",
      "SuperAdmin",
      "High5Coordinator",
      "CommunityManager",
      "CustomerSuccess",
      "Admin",
    ],
  },
  {
    component: "TagsTemplate",
    url: "/tagsTemplate",
    title: "Tags Template",
    role: ["SuperAdmin", "CustomerSuccess"],
  },
];

const dashboard = {
  HiringManager: {
    component: "HiringManagerDashboard",
    url: "/",
    title: "HiringManager",
  },
  SalesAdmin: {
    component: "SADashboard",
    url: "/",
    title: "SalesAdmin",
  },
  Sales: {
    component: "SADashboard",
    url: "/",
    title: "SalesAdmin",
  },
  SuperAdmin: {
    component: "Dashboard",
    url: "/",
    title: "SuperAdmin",
  },
  Candidate: {
    component: "CandidateDashboard",
    url: "/",
    title: "Candidate",
  },
  Recruiter: {
    component: "RecruiterDashboard",
    url: "/",
    title: "Recruiter",
  },
  Admin: {
    component: "TenantDashboard",
    url: "/",
    title: "Admin",
  },
  High5Coordinator: {
    component: "H5CDashboard",
    url: "/",
    title: "High5Coordinator",
  },
  CommunityManager: {
    component: "Dashboard",
    url: "/",
    title: "CommunityManagerDashboard",
  },
  CustomerSuccess: {
    component: "ExecutiveAdminDashboard",
    url: "/",
    title: "SuperAdmin",
  },
};

export { dashboard, common };
