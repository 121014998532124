import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import { countryObj } from "../../../../constants/Constants";
import { CurrencySymbols } from "../../../config/CurrencySymbols";
import StatusData from "./StatusData";
import Pagination from "../../../components_v3/pagination/pagination";
import { SidebarLogo } from "../../../../constants/Constants";

const GridView = (props) => {
  const { parent } = props;
  const history = useHistory();
  const { currentPage, setCurrentPage } = props;
  const [testId, setTestId] = useState({});
  const removeItemFromArray = (item) => {
    const temp = { ...testId };
    delete temp[item];
    setTestId(temp);
  };
  useEffect(() => {
    setTestId({});
  }, [currentPage]);
  return (
    <div className="row">
      {props.jobList?.map((job, i) => (
        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 d-flex">
          <div
            onClick={(event) => {
              if (
                event.target.className.includes("tag") ||
                event.target.className.includes("tag-blue3")
              ) {
                return;
              } else {
                history.push(`/high5Hire/requests/${job.jobId}`);
              }
            }}
            className="card col card-tag-bottom mb-4 card-lg"
            style={{ cursor: "pointer" }}
          >
            <div className="card-body pb-0">
              <div className="row d-flex justify-content-between align-items-center ">
                <div className="col-md-3">
                  <img
                    src={job.logourl || SidebarLogo}
                    width="60"
                    height="60"
                    className="p-2"
                    style={{
                      borderRadius: "50%",
                      objectFit: "contain",
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                    }}
                  />
                </div>
                <div className="col-md-9">
                  <span className="">
                    <h6>
                      {job.jobTitle} (Job Id: {job.jobId})
                    </h6>
                  </span>
                  {job.isHotJob && (
                    <span className="tag tag-coral mr-9">HOT</span>
                  )}
                  <div className="mb-2">
                    {job.jobStatus === 13 && (
                      <span className="tag tag-green1 mr-3">Open</span>
                    )}
                    {job.jobStatus === 21 && (
                      <span className="tag tag-orange1 mr-3">Active</span>
                    )}
                    {job.jobStatus === 39 && (
                      <span className="tag tag-font_accent mr-3">Halted</span>
                    )}
                    {job.jobStatus === 23 && (
                      <span className="tag tag-font_accent mr-3">Closed</span>
                    )}
                    <i className="ml-1 far fa-suitcase me-2 font-brandBlue"></i>
                    <small className="font-regular font-primary">
                      {"  "}
                      {job.jobType}
                    </small>
                    <div className="d-flex mt-1">
                      <span className="d-flex align-items-center tag tag-blue2 font-regular font-primary font-gray1 mb-2">
                        <span className="font-regular font-primary font-gray1 mr-1">
                          Placement Fee:
                        </span>
                        {"  "}{" "}
                        {job.isFeePercentage
                          ? `${job.placementFee}%`
                          : `${
                              CurrencySymbols[job.placementCurrency]
                                ? CurrencySymbols[job.placementCurrency]
                                : job.placementCurrency
                            }${Number(job.placementFee).toLocaleString()}`}
                        {/* </small> */}
                      </span>
                    </div>
                    {job.jobDivaId && (
                      <div className="d-flex">
                        <span className=" d-flex align-items-center tag tag-orange1 font-regular font-primary font-gray1 mb-2">
                          <span className="font-regular font-primary font-gray1 mr-1">
                            JobDiva Id:
                          </span>
                          {"  "} <strong>{job.jobDivaId}</strong>
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <hr className="mt-0 mb-2" />
              <p className="mb-1">
                <i className="fal fa-fw fa-map-marker-alt mr-2"></i>
                {job.isRemote === true ? (
                  <span>Remote</span>
                ) : (
                  <span>
                    {job?.city && job?.state && job?.country && (
                      <>
                        <span className="font-regular font-primary font-gray1">
                          {job.city.length > 10
                            ? job.city.slice(0, 10) + ".."
                            : job.city}
                          , {job?.state}, {job?.country}
                        </span>
                      </>
                    )}
                  </span>
                )}
              </p>
              {job?.visaStatus && (
                <div className="mt-1 d-block">
                  <i class="far fa-plane-departure mr-1"></i>{" "}
                  {JSON.parse(job?.visaStatus).map(
                    (item, index) =>
                      (index < 2 || testId[i] == "visastatus") && (
                        <span className="tag tag-blue3 mr-1 pt-1 px-2 mt-1">
                          {item}
                        </span>
                      )
                  )}
                  {job?.visaStatus &&
                    (testId[i] == "visastatus" ? (
                      <div
                        className="tag"
                        onClick={() => removeItemFromArray(i)}
                        style={{ margin: "2px", cursor: "pointer" }}
                      >
                        more
                      </div>
                    ) : (
                      JSON.parse(job?.visaStatus).length > 2 && (
                        <div
                          className="tag"
                          onClick={() => {
                            setTestId({ ...testId, [i]: "visastatus" });
                          }}
                          style={{ margin: "2px", cursor: "pointer" }}
                        >
                          +{JSON.parse(job?.visaStatus).length - 2} Show less
                        </div>
                      )
                    ))}
                </div>
              )}
            </div>

            <div className="card-body py-2">
              <StatusData job={job} />
            </div>

            {job.totalSubmisions === 0 ? (
              <div className="tag tag-footer font-regular justify-content-center">
                No Candidate Available
              </div>
            ) : (
              <div className="tag tag-footer tag-green3 justify-content-center">
                {job.totalSubmisions === 1
                  ? `${job.totalSubmisions} New Candidate`
                  : `${job.totalSubmisions}  New Candidates`}
              </div>
            )}
          </div>
        </div>
      ))}
      {props.jobList.length > 0 && (
        <Pagination
          currentPage={currentPage}
          totalItems={props.total}
          itemsPerPage={parent == "HMdashboard" ? 3 : 10}
          onPageChange={props.handlePageChange}
          limit={parent == "HMdashboard" ? 3 : 10}
        />
      )}
      {props.jobList.length === 0 && <NoRecord />}
    </div>
  );
};

export default GridView;
