import React, { useState } from "react";
import Avatar from "react-avatar";
import { Modal, Button } from "react-bootstrap";

const Messages = () => {
  // Dummy message data
  const messages = [
    {
      id: 1,
      sender: "John Doe",
      timestamp: "2 minutes ago",
      content: "Hey, how's it going?",
    },
    {
      id: 2,
      sender: "Jane Smith",
      timestamp: "5 minutes ago",
      content: "Can you review the latest draft?",
    },
    {
      id: 3,
      sender: "Alex Johnson",
      timestamp: "10 minutes ago",
      content:
        "Reminder: Team meeting tomorrow at 10 AM. Don't forget to bring your notes and be prepared for the presentation.",
    },
    {
      id: 4,
      sender: "Sarah Thompson",
      timestamp: "15 minutes ago",
      content:
        "Hi there! I wanted to remind you about the upcoming deadline for the project. We need to finalize the details by the end of the week.",
    },
    {
      id: 5,
      sender: "Michael Brown",
      timestamp: "20 minutes ago",
      content:
        "Good morning! Just wanted to check in and see how everything is going with the project. Let me know if you need any assistance.",
    },
  ];

  // Modal state
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  // Function to open modal
  const openModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  // Function to close modal
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="container mt-4">
      {messages.map((message) => (
        <div key={message.id} className="card shadow mb-3">
          <div className="card-header d-flex justify-content-between">
            <div className="d-flex gap-3">
              <Avatar name={message.sender} size="30" round="80px" />{" "}
              <h5 className="mb-0 ">{message.sender}</h5>
            </div>
            <small className="text-muted">{message.timestamp}</small>
          </div>
          <div className="card-body">
            <p className="card-text">{message.content}</p>
            {/* <button
              className="btn btn-primary"
              onClick={() => openModal(message.content)}
            >
              View
            </button> */}
          </div>
        </div>
      ))}
      {/* Modal */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Full Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Messages;
