import React, { useState, useRef, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import ActivateTenant from "./ActivateTenant";
import DeleteTenant from "./DeleteTenant";
import EditTenant from "../dashboard/EditTenant";
import useOutsideClick from "../../function/useOutsideClick";
import moment from "moment";
import { parsePhoneNumber } from "libphonenumber-js";
import NoRecord from "../../components_v3/commoncomponents/norecord/NoRecord";

function TenantDetailsPopUp(props) {
  const [openDropDownDiv, setOpenDropDownDiv] = useState(false);
  const [editTenant, setEditTenant] = useState(false);
  const [DeleteRequestValue, setDeleteRequestButton] = useState(false);
  const [activateUserValue, setActivateUserValue] = useState(false);
  const [tenantType, setTenantType] = useState("");
  const [tenantEmail, setTenantEmail] = useState();
  const [general, setGeneral] = useState(false);
  const [curator, setCurator] = useState(false);

  //tab

  const [curatorShow, setCuratorShow] = useState(false);
  const [generalShow, setGeneralShow] = useState(true);
  //tab

  let curatorData = props?.employee?.curators
    ? JSON.parse(props?.employee?.curators)
    : [];

  const onCollapsibleClick = (val) => {
    if (val === "general") {
      general ? setGeneral(false) : setGeneral(true);
    }
    if (val === "curator") {
      curator ? setCurator(false) : setCurator(true);
    }
  };

  const ref = useRef();
  useOutsideClick(ref, () => setOpenDropDownDiv(false));

  const close = () => {
    props.closeModal(true);
  };

  const openDropDown = () => {
    openDropDownDiv ? setOpenDropDownDiv(false) : setOpenDropDownDiv(true);
  };

  const opentenantEdit = () => {
    setEditTenant(true);
  };

  const closetenantEdit = () => {
    setEditTenant(false);
  };

  const DeleteRequestFun = (e, item) => {
    setTenantEmail(item.useradminaccount);
    setTenantType(item.typename);
    setDeleteRequestButton(true);
  };

  const EnabletenantFun = (e, item) => {
    setTenantEmail(item.useradminaccount);
    setTenantType(item.typename);
    setActivateUserValue(true);
  };

  const DeleteRequestClose = (e) => {
    props.setopenTenantPopUP(false);
    close();
    setDeleteRequestButton(false);
    props.setopenTenantPopUP(false);
    props.getTenantData();
  };

  const EnableTenantClose = (e) => {
    props.setopenTenantPopUP(false);
    close();
    setActivateUserValue(false);
    props.setopenTenantPopUP(false);
    props.getTenantData();
  };

  const onTabClick = (type) => {
    if (type === "curator") {
      setCuratorShow(true);
      setGeneralShow(false);
    }
    if (type === "general") {
      setCuratorShow(false);
      setGeneralShow(true);
    }
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header">
          <div className="px-3 py-2">
            <button type="button" onClick={close} className="btn btn-icon">
              <i aria-hidden="true" className="fal fa-times"></i>
            </button>
          </div>
          <div className="px-3 mb-3">
            <div className="d-flex align-items-start flex-wrap-reverse">
              <div className="d-flex">
                <div className="ml-3">
                  {props.employee.firstName || props.employee.lastName ? (
                    <h5 className="mr-2 mb-2">
                      {props.employee.firstName} {props.employee.lastName}
                    </h5>
                  ) : (
                    <h5 className="mr-2 mb-2">{props.employee.tenantname}</h5>
                  )}
                  <div className="mb-2">
                    {props.employee.statusname === "Active" ? (
                      <span className="tag tag-blue2">
                        {props.employee.statusname}
                      </span>
                    ) : props.employee.statusname ===
                      ("Deactivated" || "InActive") ? (
                      <span className="tag tag-red2">Deactivated</span>
                    ) : (
                      <span className="tag tag-green2">
                        {props.employee.statusname}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              {props.employee.firstName || props.employee.lastName ? (
                <div className="ml-auto d-flex align-items-center">
                  {props.employee.statusname === "Active" ? (
                    <div className="btn-group btn-group-sm btn-split" ref={ref}>
                      <button
                        type="button"
                        onClick={opentenantEdit}
                        className="btn btn-sm"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={openDropDown}
                        className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <span className="sr-only">Toggle Dropdown</span>
                      </button>
                      {openDropDownDiv && (
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          style={{
                            display: "revert",
                            right: "0px",
                            top: "30px",
                          }}
                        >
                          {props.employee.statusname === "Active" ? (
                            <button
                              type="button"
                              class="dropdown-item"
                              onClick={(e) => {
                                DeleteRequestFun(e, props.employee);
                              }}
                            >
                              <i
                                class="fa fa-trash mr-2"
                                aria-hidden="true"
                              ></i>{" "}
                              Deactivate
                            </button>
                          ) : (
                            <button
                              type="button"
                              class="dropdown-item"
                              onClick={(e) => {
                                EnabletenantFun(e, props.employee);
                              }}
                            >
                              <i class="fa fa-key mr-2" aria-hidden="true"></i>{" "}
                              Activate
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <button
                      type="button"
                      class="btn btn-sm"
                      onClick={(e) => {
                        EnabletenantFun(e, props.employee);
                      }}
                    >
                      <i class="fa fa-key mr-2" aria-hidden="true"></i> Activate
                    </button>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className="view-panel-body">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <button
                className={generalShow ? "nav-link active" : "nav-link"}
                onClick={() => onTabClick("general")}
                type="button"
              >
                General
              </button>
            </li>

            <li className="nav-item">
              <button
                onClick={() => onTabClick("curator")}
                type="button"
                className={curatorShow ? "nav-link active" : "nav-link"}
              >
                Curators
              </button>
            </li>
          </ul>
          {curatorShow && (
            <div className="mt-3">
              {curatorData && curatorData?.length > 0 ? (
                <div>
                  <Table hover>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Community</th>
                        <th>Assigned on</th>
                      </tr>
                    </thead>
                    <tbody style={{ overflow: "auto" }}>
                      {curatorData.map((item, index) => (
                        <tr key={index}>
                          <td>
                            {item?.firstName} {item?.lastName}
                          </td>

                          <td>
                            <span className="tag tag-blue3">
                              {item?.poolname}
                            </span>
                          </td>
                          <td>
                            {moment(item?.curatorCreatedDate).format(
                              "MM/DD/YYYY"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div>
                  <NoRecord />
                </div>
              )}
            </div>
          )}
          {generalShow && (
            <div className="mt-3">
              {props.employee.tenantname ? (
                <div className="d-flex mb-2 pl-1">
                  <div>
                    <i
                      className="fas  fa-fw fa-building"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="ml-2">
                    <p className="mb-0">
                      {props.employee.tenantname.replace("GIG - ", "")}{" "}
                    </p>
                    <p className="mb-0">
                      <small>Company</small>
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {props.employee.mobilePhone ? (
                <div className="d-flex mb-2 pl-1">
                  <div>
                    <i
                      className="fas fa-fw fa-phone-alt"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="ml-2">
                    <p className="mb-0">
                      {parsePhoneNumber(
                        props.employee.mobilePhone[0] === "+"
                          ? props.employee.mobilePhone
                          : `+${props.employee.mobilePhone}`
                      )?.formatInternational()}{" "}
                    </p>
                    <p className="mb-0">
                      <small>Contact no</small>
                    </p>
                  </div>
                </div>
              ) : (
                " "
              )}{" "}
              {props.employee.useradminaccount ? (
                <div className="d-flex mb-2 pl-1">
                  <div>
                    <i className="fas fa-fw fa-envelope" aria-hidden="true"></i>
                  </div>
                  <div className="ml-2">
                    <p className="mb-0">{props.employee.useradminaccount} </p>
                    <p className="mb-0">
                      <small>Email</small>
                    </p>
                  </div>
                </div>
              ) : (
                " "
              )}
              {props.employee.typename ? (
                <div className="d-flex mb-2 pl-1">
                  <div>
                    <i className="fas  fa-fw fa-shapes" aria-hidden="true"></i>
                  </div>
                  <div className="ml-2">
                    <p className="mb-0">
                      {" "}
                      {props.employee.tenantname.includes("GIG - ")
                        ? "Gig Recruiter"
                        : props.employee.typename}
                    </p>
                    <p className="mb-0">
                      <small>Type</small>
                    </p>
                  </div>
                </div>
              ) : (
                " "
              )}
              {!props.employee.firstName || !props.employee.lastName ? (
                <div className="d-flex mb-2 pl-1">
                  <div>
                    <i className="far fa-id-badge" aria-hidden="true"></i>
                  </div>
                  <div className="ml-2">
                    <p className="mb-0">{props.employee.createdBy} </p>
                    <p className="mb-0">
                      <small>Created by</small>
                    </p>
                  </div>
                </div>
              ) : (
                " "
              )}
              {!props.employee.firstName || !props.employee.lastName ? (
                <div className="d-flex mb-2 pl-1">
                  <div>
                    <i className="fa fa-clock-o" aria-hidden="true"></i>
                  </div>
                  <div className="ml-2">
                    <p className="mb-0">{} </p>
                    <p className="mb-0">
                      {moment(props.employee.createddatetime).format(
                        "MM/DD/YYYY"
                      )}{" "}
                    </p>

                    <p className="mb-0">
                      <small>Created On</small>
                    </p>
                  </div>
                </div>
              ) : (
                " "
              )}
              {(props.employee.addressCity ||
                props.employee.addressState ||
                props.employee.country) && (
                <div className="d-flex mb-2 pl-1">
                  <div>
                    <i
                      className="fad fa-fw fa-map-marked-alt"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="ml-2">
                    <p className="mb-0">
                      {props.employee.addressCity &&
                        props.employee.addressCity + ", "}
                      {props.employee.addressState &&
                        props.employee.addressState + ", "}
                      {props.employee.country && props.employee.country}{" "}
                    </p>
                    <p className="mb-0">
                      <small>Location</small>
                    </p>
                  </div>
                </div>
              )}
            </div>
          )}
          {/* <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => onCollapsibleClick("general")}
            >
              <i
                className={
                  general ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"
                }
              ></i>
              <span>
                <small>General</small>
              </span>
            </button>
          </div> */}
        </div>
        <Modal show={editTenant} onHide={props.closeEditPopUp} size="lg">
          <EditTenant
            closeAdd={closetenantEdit}
            tenantlist={props.employee}
            getTenantData={props.getTenantData}
            setopenTenantPopUP={props.setopenTenantPopUP}
          />
        </Modal>

        <Modal
          show={DeleteRequestValue === true}
          onHide={DeleteRequestClose}
          size="delete"
        >
          <div class="bl-modal-header">
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={DeleteRequestClose}
              class="close p-0 bl-modal-close-btn"
              style={{ marginTop: "-6px" }}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            <DeleteTenant
              handleSuccess={DeleteRequestClose}
              employeeDelete={"employeedelete"}
              tenantName={props.employee.tenantname}
              userid={props.employee.id_useraccessdetail}
              users={"users"}
              isFrom={props.isFrom}
              getTenantData={props.getTenantData}
              setDeleteRequestValue={setDeleteRequestButton}
              setOpenDropDownDiv={setOpenDropDownDiv}
              closeModal={close}
              parent="tenantDetailsPopUp"
              tenantObj={props.employee}
              setopenTenantPopUP={props.setopenTenantPopUP}
              tenantType={tenantType}
              tenantEmail={tenantEmail}
            />
          </Modal.Body>
        </Modal>
      </div>

      <Modal
        show={activateUserValue === true}
        onHide={EnableTenantClose}
        size="active"
      >
        <div class="bl-modal-header">
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={EnableTenantClose}
            class="close p-0 bl-modal-close-btn"
            style={{ marginTop: "-6px" }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Modal.Body>
          <ActivateTenant
            tenantEmail={tenantEmail}
            tenantType={tenantType}
            handleSuccess={EnableTenantClose}
            employeeenable={"employeeenable"}
            tenantName={props.employee.tenantname}
            userid={props.employee.id_useraccessdetail}
            users={"users"}
            tenantObj={props.employee}
            isFrom={props.isFrom}
            getTenantData={props.getTenantData}
            setActivateUserValue={setActivateUserValue}
            setOpenDropDownDiv={setOpenDropDownDiv}
            closeModal={close}
            parent="tenantDetailsPopUp"
            setopenTenantPopUP={props.setopenTenantPopUP}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default TenantDetailsPopUp;
