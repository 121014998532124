import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ReactPaginate from "react-paginate";
import Modal from "react-bootstrap/Modal";
import DeactiveUser from "../popups/DeactiveUser";
import ActivateUser from "../popups/ActivateUser";
import { toast } from "react-toastify";
import { get } from "../../../service/ApiService";
import UserDetails from "../popups/UserDetails";
import AssignUsers from "../popups/AssignUsers";
import RemoveUser from "../popups/RemoveUser";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import UserDetailsPopUp from "../popups/UserDetailsPopUp";
import Loader from "../../Loader/Loader";
import { getUserRole } from "../../../service/AuthService";
import moment from "moment";
import EmailStatus from "../../components_v3/EmailStatus/EmailStatus";
function Users(props) {
  const [DeleteRequestValue, setDeleteRequestButton] = useState(false);
  const [enableUserValue, setEnableUserButton] = useState(false);
  const [approving, setApporving] = useState(false);
  const [approved, setApproved] = useState(false);
  const [addusers, setAddUsers] = useState(false);
  const [editusers, seteditUsers] = useState(false);
  const [userObj, setuserObj] = useState([]);
  const [addusersCondition, setAddUsersCondition] = useState(false);
  //const [openUserPopUP, setopenUserPopUP] = useState(false);
  const [assignUsers, setassignUsers] = useState(false);
  const [removeUsers, setremoveUsers] = useState(false);
  const [userList, setuserList] = useState([]);
  const [userData, setUserData] = useState([]);
  const [selecteduser, setSelectedUser] = useState();
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [showingCount, setShowingCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [totalUser, setTotalUser] = useState(0);
  const [pageShowCount, setPageShowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [assignTenantData, setAssignTenantData] = useState({});
  const [openUserPopUP, setopenUserPopUP] = useState(false);
  const [searchTenant, setSearchTenant] = useState("");
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState({});

  useEffect(() => {
    // const userrole = getUserRole()
    const role = getUserRole();
    getusersData();
  }, []);

  // get data from
  const getusersData = async () => {
    try {
      const userRole = getUserRole();
      setLoading(true);
      const res = await get("/users/adminUsers");
      if (res.status === 200) {
        const result = res.data.sort(
          (a, b) => new Date(b.createddatetime) - new Date(a.createddatetime)
        );
        // if loggedin user is not superadmin then show only
        if (userRole === "SalesAdmin") {
          const filterResult = result.filter(
            (eachUser) =>
              eachUser.type === "High5Coordinator" || eachUser.type === "Sales"
          );
          setSearchTenant([...filterResult]);
          setuserList(filterResult.slice(offset, offset + pageShowCount));
          setUserData(filterResult);
          setPageCount(Math.ceil(filterResult.length / pageShowCount));
          setTotalUser(filterResult.length);
          const showingCount =
            filterResult.length > pageShowCount
              ? pageShowCount
              : filterResult.length;
          setShowingCount(showingCount);
        } else if (
          userRole === "SuperAdmin" ||
          userRole === "CustomerSuccess"
        ) {
          setSearchTenant([...result]);
          setuserList(result.slice(offset, offset + pageShowCount));
          setUserData(result);
          setPageCount(Math.ceil(result.length / pageShowCount));
          setTotalUser(result.length);
          const showingCount =
            result.length > pageShowCount ? pageShowCount : result.length;
          setShowingCount(showingCount);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing admin   Details");
    }
  };

  const addUsers = () => {
    setAddUsers(true);
    setAddUsersCondition(true);
  };

  const openeditusers = (item) => {
    setuserObj(item);
    seteditUsers(true);
  };

  const closePopUP = () => {
    setAddUsers(false);
    setopenUserPopUP(false);
  };
  const closeEditPopUp = () => {
    seteditUsers(false);
  };

  const DeleteRequestFun = (item) => {
    setuserObj(item);
    //     setSelectedUser(userid)
    setopenUserPopUP(false);
    setDeleteRequestButton(true);
  };

  const EnabletenantFun = (item) => {
    //   setSupplierDetails(tenantName);
    setuserObj(item);
    setopenUserPopUP(false);
    setEnableUserButton(true);
    //setActivateUserValue(true)
  };

  const DeleteRequestClose = (e) => {
    setDeleteRequestButton(false);
    setopenUserPopUP(false);
  };

  const EnableUserClose = (e) => {
    setEnableUserButton(false);
    setopenUserPopUP(false);
  };

  const openUserPopUPFun = (item) => {
    setopenUserPopUP(true);
    setuserObj(item);
  };

  const closeUserPopUP = () => {
    setopenUserPopUP(false);
  };

  const assignUsersFun = (e, type) => {
    setAssignTenantData(type);
    setassignUsers(true);
  };

  const assignUsersClose = (e) => {
    setassignUsers(false);
  };

  const removeUsersFun = (e, type) => {
    setAssignTenantData(type);
    setremoveUsers(true);
  };

  const removeUsersClose = (e) => {
    setremoveUsers(false);
  };

  const handlePageChange = (selectedObject) => {
    const currPage = selectedObject.selected;
    setCurrentPage(currPage);
    const offsetValue = currPage * pageShowCount;
    setPageCount(Math.ceil(totalUser / pageShowCount));
    setuserList(userData.slice(offsetValue, offsetValue + pageShowCount));
    setOffset(offsetValue);
    const count =
      totalUser > (currPage + 1) * pageShowCount
        ? (currPage + 1) * pageShowCount
        : totalUser;
    setShowingCount(count);
  };

  const handleChange = (e) => {
    let showCount = 0;
    if (e.target.value === "All") {
      showCount = totalUser;
    } else {
      showCount = Number(e.target.value);
    }
    setPageShowCount(showCount);
    const offsetValue = currentPage * showCount;
    setPageCount(Math.ceil(totalUser / showCount));
    setuserList(userData.slice(offsetValue, offsetValue + showCount));
    setOffset(offsetValue);
    const count =
      totalUser > (currentPage + 1) * e.target.value
        ? (currentPage + 1) * e.target.value
        : totalUser;
    setShowingCount(count);
  };

  const role = getUserRole();

  const filterCompanies = (e) => {
    const filteredCompanies = searchTenant.filter((val) => {
      if (e.target.value == "") {
        return val;
      } else if (
        val.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val.type.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val.status.toLowerCase() === e.target.value.toLowerCase()
      ) {
        return val;
      }
    });
    setUserData(filteredCompanies);
    const finalData = filteredCompanies.slice(0, pageShowCount);
    setuserList(finalData);
    setPageCount(Math.ceil(filteredCompanies.length / pageShowCount));
    setShowingCount(finalData.length);
    setTotalUser(filteredCompanies.length);
  };
  return (
    <>
      {loading && <Loader />}
      <div>
        <div class="px-3">
          <div class=" mb-2">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h6 class="m-0">Users</h6>
              {role !== "Sales" && (
                <button type="button" class="btn btn-sm" onClick={addUsers}>
                  <i class="fal fa-user-plus mr-2" aria-hidden="true"></i>Add
                  User
                </button>
              )}
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <select class="form-select mb-2" onChange={handleChange}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="All">All</option>
                </select>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <div class="mb-2">
                  <input
                    type="text"
                    placeholder="Search Users"
                    onChange={filterCompanies}
                    class="form-control small font-14"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="pt-0 mb-3 table-cards-1024">
            <table class="table table-hoverable-cells">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  {/* <th scope="col">Email</th> */}
                  <th scope="col">User Type</th>
                  <th scope="col">Email Status</th>

                  <th scope="col">Created on</th>

                  <th scope="col">Status</th>
                  <th scope="col">Details</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {userList.map((item) => (
                  <tr>
                    <td onClick={() => openUserPopUPFun(item)}>
                      <span>{item.name}</span>
                    </td>

                    {/* <td onClick={() => openUserPopUPFun(item)}
                    >
                      {item.email}
                    </td> */}

                    <td onClick={() => openUserPopUPFun(item)}>
                      {item.typename == "High5 Coordinator"
                        ? "Customer Success Manager"
                        : item.typename == "Community Manager"
                        ? "Marketplace Manager"
                        : item.typename == "Customer Success"
                        ? "High5 Admin"
                        : item.typename}
                    </td>
                    <td className="no-hover">
                      <div
                        className="d-inline-block"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="View Email Status"
                      >
                        <button
                          type="button"
                          className="btn btn-square btn-gray"
                          onClick={() => {
                            setEmailData(item?.email);
                            setEmailStatus(true);
                            setSelectedTalent(item);
                          }}
                        >
                          <i class="fa fa-envelope-o" />
                        </button>
                      </div>
                    </td>
                    <td onClick={() => openUserPopUPFun(item)}>
                      <span>
                        {moment(new Date(item.createddatetime)).format(
                          "MM/DD/YYYY"
                        )}
                      </span>
                    </td>

                    <td onClick={() => openUserPopUPFun(item)}>
                      {item.status === "Active" ? (
                        <span class="tag tag-green2" v-if="item.isActive">
                          Active
                        </span>
                      ) : (
                        <span class="tag tag-red2">Deactivated</span>
                      )}
                    </td>
                    <td class="text-right no-hover">
                      <div class="d-flex align-items-center justify-content-left">
                        {/* {item.type !== "SalesAdmin" && item.type !== "Sales" && (
                          <button
                            type="button"
                            class="btn btn-secondary btn-sm"
                            onClick={(e) => assignUsersFun(e, item)}
                            disabled={
                              item.typename !== "High5 Coordinator" &&
                              item.status === "Active"
                                ? false
                                : true
                            }
                          >
                            Assign
                          </button>
                        )} */}
                        &nbsp;&nbsp;
                        {console.log(item.type)}
                        {item.type !== "SalesAdmin" &&
                          item.type !== "Sales" &&
                          item.type !== "CustomerSuccess" && (
                            <button
                              type="button"
                              class="btn btn-secondary btn-sm"
                              onClick={(e) => removeUsersFun(e, item)}
                              disabled={item.status === "Active" ? false : true}
                            >
                              Details
                            </button>
                          )}
                      </div>
                    </td>
                    <td class="text-right no-hover">
                      <div class="dropdown ml-4">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="left"
                          overlay={
                            <div className="dropdown-menu dropdown-menu-left">
                              <div className="admindeleteedit">
                                <ul>
                                  {item.status === "Active" && (
                                    <button
                                      href="#"
                                      class="dropdown-item"
                                      onClick={() => openeditusers(item)}
                                    >
                                      <i
                                        class="fas fa-edit mr-2"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Edit
                                      <a />
                                    </button>
                                  )}
                                  {item.status === "Active" ? (
                                    <button
                                      type="button"
                                      class="dropdown-item"
                                      onClick={(e) => {
                                        DeleteRequestFun(item);
                                      }}
                                      getusersData={getusersData}
                                    >
                                      <i
                                        class="fa fa-trash mr-2"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Deactivate
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="dropdown-item"
                                      onClick={(e) => {
                                        EnabletenantFun(item);
                                      }}
                                      getusersData={getusersData}
                                    >
                                      <i
                                        class="fa fa-key mr-2"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Activate
                                    </button>
                                  )}{" "}
                                </ul>
                              </div>
                            </div>
                          }
                        >
                          <button
                            type="button"
                            class="btn btn-icon dropdown-toggle"
                          >
                            <i class="far fa-ellipsis-v"></i>
                          </button>
                        </OverlayTrigger>
                      </div>
                      {/* <div class="dropdown ml-2">
                              <button type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-icon dropdown-toggle">
                                  <i class="far fa-ellipsis-v" aria-hidden="true"></i>
                              </button>
                                    <div class="dropdown-menu dropdown-menu-right" >
                                  
                                          <button type="button" class="dropdown-item">
                                              <i class="fas fa-edit mr-2" aria-hidden="true"></i> Edit
                                    
                                          </button>
                                          <button type="button" class="dropdown-item">
                                              <i aria-hidden="true" class="fa fa-trash mr-2"></i> Delete
                                          </button>
                                  </div>
                          </div> */}
                      {/* </div> */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {userList.length === 0 && (
              <div>
                <div class="text-center p-3">
                  <div class="avatar avatar-lg">
                    <i aria-hidden="true" class="fad fa-folder-open"></i>
                  </div>{" "}
                  <div class="mt-6">No records found</div>
                </div>
              </div>
            )}
            <hr class="m-0" />
            <div class="d-flex align-items-center mt-3 mb-2 flex-wrap-370">
              <div class="text-center-370 w-100-370 mb-point5-370">
                Showing {showingCount} of {totalUser}
              </div>
              <div class="ml-auto mx-auto-370 d-flex align-items-center">
                <ReactPaginate
                  pageCount={pageCount}
                  pageRange={2}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName={"paginationContainer"}
                  previousLinkClassName={"paginationPage"}
                  breakClassName={"paginationPage"}
                  nextLinkClassName={"paginationPage"}
                  pageClassName={"paginationPage1"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  previousLabel={
                    <i
                      className="fal fa-chevron-circle-left"
                      style={{ "font-size": "17px" }}
                    ></i>
                  }
                  nextLabel={
                    <i
                      className="fal fa-chevron-circle-right"
                      style={{ "font-size": "17px" }}
                    ></i>
                  }
                />
              </div>
            </div>
          </div>
        </div>
        {emailStatus && (
          <EmailStatus
            email={emailData}
            emailStatus={emailStatus}
            setEmailStatus={setEmailStatus}
            isFrom="SuperAdminUsers"
            invitedDate={selectedTalent?.createddatetime}
            user="SA"
          />
        )}
        {/* add user */}
        <Modal show={addusers} size="lg">
          <AddUser
            closeAdd={closePopUP}
            userlist={userObj.length !== 0 ? userObj : null}
            addusersCondition={addusersCondition}
            getusersData={getusersData}
          />
        </Modal>
        {/* edit user  */}
        <Modal show={editusers} size="lg">
          <EditUser
            closeAdd={closeEditPopUp}
            userlist={userObj.length !== 0 ? userObj : null}
            getusersData={getusersData}
          />
        </Modal>

        {/* disable user  */}
        <Modal
          show={DeleteRequestValue === true}
          onHide={DeleteRequestClose}
          size="delete"
        >
          <div class="bl-modal-header">
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={DeleteRequestClose}
              class="close p-0 bl-modal-close-btn"
            >
              {/* <button v-if="showModalToggleClose" type="button" v-on:click="close" class="close p-0 bl-modal-close-btn" data-dismiss="modal" aria-label="Close"> */}
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <Modal.Body>
            <DeactiveUser
              handleSuccess={DeleteRequestClose}
              employeeDelete={"employeedelete"}
              employee={userObj.length !== 0 ? userObj : null}
              users={"users"}
              getusersData={getusersData}
              setopenUserPopUP={setopenUserPopUP}
              closePopUP={closePopUP}
            />
          </Modal.Body>
        </Modal>

        {/* enable user  */}
        <Modal
          show={enableUserValue === true}
          onHide={EnableUserClose}
          size="delete"
        >
          <div class="bl-modal-header">
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={EnableUserClose}
              class="close p-0 bl-modal-close-btn"
            >
              {/* <button v-if="showModalToggleClose" type="button" v-on:click="close" class="close p-0 bl-modal-close-btn" data-dismiss="modal" aria-label="Close"> */}
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <Modal.Body>
            <ActivateUser
              handleSuccess={EnableUserClose}
              employeeDelete={"employeedelete"}
              employee={userObj.length !== 0 ? userObj : null}
              users={"users"}
              getusersData={getusersData}
              setopenUserPopUP={setopenUserPopUP}
            />
          </Modal.Body>
        </Modal>

        {/* user details */}
        <Modal
          // size="lg"
          show={openUserPopUP}
          dialogClassName="view-panel show"
        >
          <UserDetails
            closeModal={closePopUP}
            // handleSuccess={editFun}
            users={"users"}
            employee={userObj.length !== 0 ? userObj : null}
          />
        </Modal>

        {/* assign users to tenant  */}
        <Modal show={assignUsers}>
          <AssignUsers
            handleClose={assignUsersClose}
            userData={assignTenantData}
          />
        </Modal>

        {/* remove assign tenant*/}
        <Modal show={removeUsers}>
          <RemoveUser
            userData={assignTenantData}
            handleClose={removeUsersClose}
          />
        </Modal>

        {/* user details pop up SidePanel */}
        <Modal
          show={openUserPopUP}
          onHide={closeUserPopUP}
          dialogclassName="view-panel show"
        >
          <UserDetailsPopUp
            employee={userObj}
            closeModal={closeUserPopUP}
            updateEmployee={userData}
            getusersData={getusersData}
            parent={"popUp"}
            setopenUserPopUP={setopenUserPopUP}
          />
        </Modal>
      </div>
    </>
  );
}
export default Users;
