import React, { useState } from "react"
import { ButtonLoader } from "../../../constants/Constants"
import { toast } from 'react-toastify'
import { post } from '../../../service/ApiService'
import { getCandidateId } from "../../../service/AuthService"
import { isValidUrl } from "../../function/CommonFunction"

function SocialMedia({ linkedIn, website }) {

  const [ readOnlyMode, setReadOnlyMode ] = useState(true)
  const [ submittingForm, setSubmittingFrom ] = useState(false)
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false)
  const [ saveClicked, setSaveClicked ] = useState(true)
  const [ linkedInURL, setLinkedInURL ] = useState(linkedIn)
  const [ websiteURL, setWebsiteURL ] = useState(website)
  const [ tempLinkedIn, setTempLinkedIn ] = useState(linkedIn)
  const [ tempWebsite, setTempWebsite ] = useState(website)

  const editMediaInfo = () => {
    setReadOnlyMode(false)
    setSaveClicked(true)
  }

  const cancelSocialMediaInfo = () => {
    setLinkedInURL(tempLinkedIn)
    setWebsiteURL(website)
    setReadOnlyMode(true)
    setShowSuccessMessage(false)
    setSubmittingFrom(false)
    setSaveClicked(true)
  }

  const saveSocialMediaInfo = async () => {
    setTempLinkedIn(linkedInURL)
    if(linkedInURL && !isValidUrl(linkedInURL)) {
      toast.error('Invalid LinkedIn URL')
    }
    else if(websiteURL && !isValidUrl(websiteURL)) {
      toast.error('Invalid Website URL')
    }
    else {
      setSaveClicked(false)
      setSubmittingFrom(true)
      try {
        const parameters = {
            candidateID: getCandidateId(),
            clickName: 'socialmedia',
            userDetailslist: {
              linkedIn: linkedInURL,
              website: websiteURL
            }
          }
          // console.log('Parameters', parameters)
          const res = await post('/candidateProfile/postSocialMediaData', parameters)
          // console.log(res)
          if (res.status === 200) {
            setSubmittingFrom(false)
            setShowSuccessMessage(true)      
            setTimeout(() => { 
              setReadOnlyMode(true)
              setShowSuccessMessage(false)
            }, 1000)
          }
      } catch (error) {
        toast.error("Error Occured")
      }
    }
  }

  return (
    <>
      <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
        <div className="card card-lg">
          <div className="card-body">
            <div className="d-flex align-items-center justify-content-between">
              <h6 className="mb-0">Social Media</h6>
              {readOnlyMode ? (
                <button
                  type="button"
                  onClick={editMediaInfo}
                  className="btn btn-text"
                >
                  <i className="fas fa-pencil-alt mr-1"></i>
                  Edit
                </button>
              ) : (
                <div>
                  <button 
                    onClick={cancelSocialMediaInfo} 
                    type="button" 
                    className="btn btn-sm btn-secondary mr-1"
                  >
                    Cancel
                  </button>
                  <button
                      type="button"
                      onClick={saveSocialMediaInfo}
                      className="btn btn-sm btn-primary"
                    >
                      {saveClicked && <span>Save Changes</span>}

                      {submittingForm && (
                        <span>
                          Saving Changes
                          <img width="20px" src={ButtonLoader} alt='' />
                        </span>
                      )}
                      {showSuccessMessage && <span>Changes Saved!</span>}
                    </button>
                </div>
              )}
            </div>
            <hr />
            <div>          
                <div className="row">
                  <div className="col-lg-10">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Paste URL"
                        value={linkedInURL}
                        disabled={readOnlyMode}
                        onChange={(e) => setLinkedInURL(e.target.value)}
                      />
                      <label>LinkedIn ID</label>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div class={ readOnlyMode ? "d-none" : "ml-3"}>
                      <button type="button" class="btn btn-icon" onClick={() => setLinkedInURL("")}>
                        <i class="fal fa-fw fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>              
                </div>                                   
                <div className="row">
                  <div className="col-lg-10">
                    <div className="form-floating mb-2">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Paste URL"
                        value={websiteURL}
                        disabled={readOnlyMode}
                        onChange={(e) => setWebsiteURL(e.target.value)}
                      />
                      <label>Website</label>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div class={ readOnlyMode ? "d-none" : "ml-3"}>
                      <button type="button" class="btn btn-icon" onClick={() => setWebsiteURL("")}>
                        <i class="fal fa-fw fa-trash-alt"></i>
                      </button>
                    </div>
                  </div>              
                </div>             
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SocialMedia
