import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import DisqualifyPopUp from "../disqualified/DisqualifyPopUp";
import ResumeDownload from "../resume/Index";
import TalentProfile from "../../../../talent/talentprofile/TalentProfile";
import EmailStatus from "../../../../EmailStatus/EmailStatus";
import { getPoolDispositionDetails } from "../../../../../../actions/directsourcing/Disposition";
import { getPoolDispositionCount } from "../../../../../../actions/directsourcing/PoolDispositionCount";
import { getPoolDispositionDisqualify } from "../../../../../../actions/directsourcing/PoolDispositionDisqualify";
import ProfileImage from "../../../../commoncomponents/profileimage/ProfileImage";
import {
  getTenantType,
  getUserRole,
  getUserTenantID,
} from "../../../../../../service/AuthService";
import { FaCommentAlt } from "react-icons/fa";
import { Col, Row } from "react-bootstrap";

const Automatch = (props) => {
  const { jobData, automatchData, view } = props;
  const dispatch = useDispatch();
  const { poolId } = useParams();
  const [disqualifyPopup, setDisqualifyPopup] = useState(false);
  const [disqualifyData, setDisqualifyData] = useState(false);
  const [resume, setResume] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [emailData, setEmailData] = useState({});
  const [selectedTalent, setSelectedTalent] = useState({});
  const [isCandidateFrom, setIsCandidateFrom] = useState("");

  const handleResume = (data) => {
    setResumeData(data);
    setResume(true);
  };

  const handleDisqualify = (data) => {
    setDisqualifyData(data);
    setDisqualifyPopup(true);
  };

  const reloadDisposition = () => {
    const payloadDisposition = {
      poolId: poolId,
      status: view,
      limit: 15,
      currentPage: 1,
      filterObject: props?.filterParams,
      searchText: props?.value,
    };
    dispatch(getPoolDispositionDetails(payloadDisposition));
    const payload = {
      jobId: poolId,
      filterObject: props?.filterParams,
      searchText: props?.value,
    };
    dispatch(getPoolDispositionCount(payload));
    let payloadDisqualify = {
      currentPage: 1,
      limit: 15,
      status: "disqualified",
      poolId: poolId,
      filterObject: props?.filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionDisqualify(payloadDisqualify));
    setDisqualifyPopup(false);
  };
  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };
  const jobBoard = [
    "Facebook",
    "LinkedIn",
    "Indeed",
    "CareerBuilder",
    "Dice",
    "Twitter",
  ];
  return (
    <>
      {automatchData.map((item, i) => (
        <tr key={i}>
          <td onClick={() => onProfileClick(item)} className="no-hover">
            <div className="d-flex flex-inline w-70">
              <ProfileImage
                imageSrc={item?.profileImagePath}
                imageWidth={"35"}
                imageHeight={"35"}
                avatarSize={"35"}
                avatarRound={"70px"}
                firstName={item?.firstName}
                lastName={item?.lastName}
              />

              <span className="ml-2 font-16">
                {item?.firstName.charAt(0).toUpperCase() +
                  item?.firstName.slice(1)}{" "}
                {item?.lastName.charAt(0).toUpperCase() +
                  item?.lastName.slice(1)}
              </span>
            </div>
            <div className="d-flex justify-content-start">
              {!item?.readStatus && (
                <button
                  type="button"
                  title="Send SMS"
                  class="btn btn-text"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    setCandidateProfile(true);
                    setIsCandidateFrom("sms");
                  }}
                >
                  <div className="notification-icon">
                    <FaCommentAlt />
                    <span className="message-status"></span>
                  </div>
                </button>
              )}
            </div>
          </td>
          <td className="no-hover">
            <span className="">{moment(item.automatchDate).fromNow()}</span>
          </td>
          <td className="no-hover">
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Resume Download"
              style={{ padding: "2px" }}
            >
              <button
                type="button"
                data-toggle="modal"
                onClick={() => handleResume(item)}
                data-target="#ViewResumeDocModal"
                className="btn btn-square btn-gray"
              >
                <i className="fas fa-file-user" />
              </button>
            </div>
          </td>
          <td className="no-hover">
            <span className="ml-2">
              {item.URLOpenCount ? item.URLOpenCount : 0}
            </span>
          </td>
          <td className="no-hover">
            <span className="ml-2">
              {item.RecruiterName && item.RecruiterName.length > 0
                ? item.RecruiterName[0]
                : " "}
            </span>
          </td>
          <td className="no-hover">
            <div className="d-flex mb-2 pl-1">
              <div className="ml-2">
                <p className="mb-0">
                  {getTenantType() == 2
                    ? getUserTenantID() == 5788
                      ? jobBoard.includes(item.source)
                        ? "High5 Job Board"
                        : item.source == "Job Board"
                        ? "Crintell"
                        : item.source == "JobDiva"
                        ? "JobDiva"
                        : "Internal"
                      : item.source == "Job Board"
                      ? "Crintell"
                      : item.source == "JobDiva"
                      ? "JobDiva"
                      : "Internal"
                    : getTenantType() == 1
                    ? item.tenantId &&
                      item.tenantId.map(Number).includes(getUserTenantID())
                      ? "Internal"
                      : item.fk_tenant && item.fk_tenant == getUserTenantID()
                      ? "Internal"
                      : "External"
                    : item.source == "Job Board"
                    ? "Crintell"
                    : item.source == "JobDiva"
                    ? "JobDiva"
                    : "Internal"}
                </p>
              </div>
            </div>
          </td>

          <td className={"text-right"}>
            {jobData?.jobStatus !== 23 &&
              jobData?.jobStatus !== 26 &&
              jobData?.jobStatus !== 39 &&
              (getUserRole() == "Recruiter" ||
                getUserRole() == "CommunityManager") && (
                <button
                  type="button"
                  onClick={() => handleDisqualify(item)}
                  className="btn btn-sm"
                >
                  Withdraw
                </button>
              )}
          </td>
        </tr>
      ))}
      {disqualifyPopup && (
        <DisqualifyPopUp
          disqualifyPopup={disqualifyPopup}
          setDisqualifyPopup={setDisqualifyPopup}
          disqualifyData={disqualifyData}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
          setCandidateProfile={setCandidateProfile}
          isFrom="automatch"
        />
      )}
      {resume && (
        <ResumeDownload
          resume={resume}
          setResume={setResume}
          resumeData={resumeData}
        />
      )}{" "}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="communityAutoMatched"
          invitedDate={selectedTalent?.automatchDate}
          jobData={jobData}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          candidateData={selectedCandidate}
          handleSuccess={handleModel}
          onDisqualifyClick={handleDisqualify}
          jobData={jobData}
          parent="autoTab"
          isFrom="autoTab"
          component="TalentPool"
          poolId={poolId}
          isCandidateFrom={isCandidateFrom}
          setIsCandidateFrom={setIsCandidateFrom}
        />
      )}
    </>
  );
};

export default Automatch;
