import React, { useEffect, useState } from "react";
import Resume from "./Resume";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { getAllJobs } from "../../../actions/job/JobList";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import AutomatchScore from "./AutomatchScore";
import { post } from "../../../service/ApiService";
import { Modal } from "react-bootstrap";
import MainAddJob from "../job/addjob";
import { toast } from "react-toastify";
import Highlighter from "react-highlight-words";
import { template } from "../../../actions/job/AddJob";

const ParsingComparison = () => {
  const dispatch = useDispatch();
  const { jobListLoading, jobList } = useSelector(
    (state) => ({
      jobListLoading: state.JobList.jobListLoading,
      jobList: state.JobList.jobList,
    }),
    shallowEqual
  );

  // States
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [successData, setSuccessData] = useState({
    jobId: "",
    position: "",
    startDate: "",
    location: "",
    salary: "",
    skills: "",
  });

  const [tempId, setTempId] = useState("");
  const [selectedJobId, setSelectedJobId] = useState("");
  const [h5Paresed, setH5Parsed] = useState(false);
  const [h5PareseData, setH5ParseData] = useState(false);
  const [affParsed, setAffparsed] = useState(false);
  const [affParseData, setAffParseData] = useState({});
  const [gptParsed, setGptParsed] = useState(false);
  const [selectedJob, setSelectedJob] = useState({});
  const [resumeFile, setResumeFile] = useState("");
  const [addJobNewModal, setAddJobNewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [weightage, setWeightage] = useState({});
  const [required, setRequired] = useState({});
  const [matchedH5, setMatchedH5] = useState({});
  const [matchedAff, setMatchedAff] = useState({});
  const [h5Total, setH5Total] = useState(0);
  const [affTotal, setAffTotal] = useState(0);

  const [average, setAverage] = useState([
    // { name: "Overall", score: 0 },
    { name: "Skills", score: 100 },
    { name: "Location", score: 100 },
    { name: "Education", score: 100 },
    { name: "Experience", score: 100 },
    { name: "Job Title", score: 0 },
  ]);
  const [averageAff, setAverageAff] = useState([
    // { name: "Overall", score: 100 },
    { name: "Skills", score: 0 },
    { name: "Location", score: 0 },
    { name: "Education", score: 0 },
    { name: "Experience", score: 0 },
    { name: "Job Title", score: 0 },
  ]);
  const [averageh5, setAverageh5] = useState([
    // { name: "Overall", score: 100 },
    { name: "Skills", score: 0 },
    { name: "Location", score: 0 },
    { name: "Education", score: 0 },
    { name: "Experience", score: 0 },
    { name: "Job Title", score: 0 },
  ]);

  // UseEffects

  const getJobList = async () => {
    dispatch(getAllJobs());
  };

  useEffect(() => {
    tempId && setSelectedJobId(tempId);
  }, [jobList]);

  useEffect(() => {
    let paramObj = {
      sortObject: [],
      currentPage: 1,
      tab: "all",
      filterQuery: {
        address: [],
        isHotJob: 0,
        jobId: [],
        jobTitle: [],
        jobType: [],
        visaStatus: [],
      },
    };
    dispatch(getJobList(paramObj));
  }, []);

  useEffect(() => {
    if (selectedJobId) {
      let job = jobList.filter((j) => j.jobId == selectedJobId)[0];
      let w = JSON.parse(job?.weightage);
      let avg = [
        // { name: "Overall", score: 0 },
        {
          name: "Skills",
          score: w?.primarySkills?.length > 0 ? 100 : 0,
        },
        { name: "Location", score: w?.location ? 100 : 0 },
        {
          name: "Education",
          score: w?.education?.length > 0 ? 100 : 0,
        },
        { name: "Experience", score: w?.experienceLevel ? 100 : 0 },
        { name: "Job Title", score: w?.jobTitle ? 100 : 0 },
      ];

      let required = {
        skills: avg[0]?.score ? true : false,
        location: avg[1]?.score ? true : false,
        education: avg[2]?.score ? true : false,
        experience: avg[3]?.score ? true : false,
        jobTitle: avg[4]?.score ? true : false,
      };
      console.log(required, w, avg);
      setRequired(required);
      setSelectedJob(job);
      setWeightage(w);
      setAverage(avg);
    }
  }, [selectedJobId]);

  // Functions

  const closeAddJobModal = () => {
    setAddJobNewModal(false);
    dispatch(template(false));
  };
  const h5Parsing = async () => {
    try {
      const payload = {
        filename: resumeFile.fileName,
        base64: resumeFile.base64,
      };
      const resultPy = await post("/talent/parseuploadresume", payload);
      const finalresultPy = {
        ...resultPy.data,
        ...resumeFile,
        h5h: true,
      };
      console.log(finalresultPy, "finallll");
      Object.keys(finalresultPy).length > 3
        ? h5Automatch(finalresultPy)
        : toast.error("Could not parse data from resume");
      console.log("h5 parsed data", finalresultPy);
      setH5ParseData(finalresultPy);
      setH5Parsed(true);
    } catch (err) {
      throw err;
    }
  };
  const affParsing = async () => {
    try {
      const resultPy = await post("/talent/resume", resumeFile);
      const finalresultPy = {
        ...resultPy?.data?.data,
        ...resumeFile,
        h5h: true,
      };
      Object.keys(finalresultPy).length > 3
        ? affAutomatch(finalresultPy)
        : toast.error("Could not parse data from resume");
      console.log("aff Parsed data", finalresultPy);
      setAffParseData(finalresultPy);
      setAffparsed(true);
    } catch (err) {
      throw err;
    }
  };

  const h5Automatch = (data) => {
    let primary = 0;
    let location = 0;
    let education = 0;
    let jobTitle = 0;
    let experience = 0;
    let sum = 0;

    weightage?.primarySkills?.forEach((skill) => {
      if (
        data?.primary_skills
          ?.map((Skill) => Skill?.toLowerCase())
          ?.includes(skill?.toLowerCase())
      ) {
        sum++;
        primary++;
      }
    });
    if (weightage?.education.includes(data?.education[0][0].program)) {
      sum++;
      education++;
    }
    if (
      data?.education[0][0].program === "Master's Degree" ||
      data?.education[0][0].program === "Doctorate"
    ) {
      sum++;
      education++;
    }
    if ([data?.location?.city].includes(selectedJob?.location?.city)) {
      sum++;
      location++;
    }

    if (
      data?.designation?.toLowerCase()?.trim() ===
      selectedJob?.jobTitle?.toLowerCase()?.trim()
    ) {
      sum++;
      jobTitle++;
    }

    let primaryAverage =
      weightage?.primarySkills?.length > 0
        ? Math.round((primary * 100) / (weightage?.primarySkills).length)
        : 0;

    let locationAverage = location ? 100 : 0;
    let jobTitleAverage = jobTitle ? 100 : 0;
    let experienceAvg = experience ? 100 : 0;
    let educationAvg = education ? 100 : 0;

    let d = 0;

    for (let key in required) {
      if (required[key]) d++;
    }

    let total = Math.round(
      (primaryAverage +
        locationAverage +
        jobTitleAverage +
        experienceAvg +
        educationAvg) /
        (d ? d : 1)
    );
    setH5Total(total);
    const matched = {
      skills: primary,
      location,
      education,
      experience,
      jobTitle,
    };
    setMatchedH5(matched);
    console.log(matched, "matched...");
    setAverageh5([
      // { name: "Overall", score: total },
      { name: "Skills", score: primaryAverage },
      { name: "Location", score: locationAverage },
      { name: "Education", score: educationAvg },
      { name: "Experience", score: experienceAvg },
      { name: "Job Title", score: jobTitleAverage },
    ]);
  };
  const affAutomatch = (data) => {
    let primary = 0;
    let location = 0;
    let education = 0;
    let jobTitle = 0;
    let experience = 0;
    let sum = 0;

    console.log(data?.skills?.map((Skill) => Skill?.name?.toLowerCase()));
    weightage?.primarySkills?.forEach((skill) => {
      data?.skills
        ?.map((Skill) => Skill?.name?.toLowerCase())
        .forEach((s) => {
          if (skill?.toLowerCase() === s.split(" ")[0]?.toLowerCase()) {
            console.log(
              skill?.toLowerCase(),
              "This matched",
              s.split(" ")[0]?.toLowerCase()
            );
            sum++;
            primary++;
          }
          console.log(
            skill?.toLowerCase(),

            s.split(" ")[0]?.toLowerCase()
          );
        });
    });
    if (
      data?.totalYearsExperience > selectedJob?.experienceLevel.split("-")[0]
    ) {
      sum++;
      experience++;
    }
    if (
      data?.profession?.toLowerCase()?.trim() ===
      selectedJob?.jobTitle?.toLowerCase().trim()
    ) {
      sum++;
      jobTitle++;
    }
    data.education.forEach((education) => {
      if (
        weightage?.education?.includes(education?.accreditation?.educationLevel)
      ) {
        sum++;
        education++;
      }
      if (
        education?.accreditation?.educationLevel === "Master's Degree" ||
        education?.accreditation?.educationLevel === "Doctorate"
      ) {
        sum++;
        education++;
      }
    });

    if ([data?.location?.city].includes(selectedJob?.location?.city)) {
      sum++;
      location++;
    }

    let primaryAverage =
      weightage?.primarySkills?.length > 0
        ? Math.round((primary * 100) / (weightage?.primarySkills).length)
        : 0;

    let locationAverage = location ? 100 : 0;
    let jobTitleAverage = jobTitle ? 100 : 0;
    let experienceAvg = experience ? 100 : 0;
    let educationAvg = education ? 100 : 0;

    let d = 0;

    for (let key in required) {
      if (required[key]) d++;
    }

    let total = Math.round(
      (primaryAverage +
        locationAverage +
        jobTitleAverage +
        experienceAvg +
        educationAvg) /
        (d ? d : 1)
    );
    setAffTotal(total);
    const matched = {
      skills: primary,
      location,
      education,
      experience,
      jobTitle,
    };
    setMatchedAff(matched);
    console.log(matched, "matched...");
    setAverageAff([
      // { name: "Overall", score: total },
      { name: "Skills", score: primaryAverage },
      { name: "Location", score: locationAverage },
      { name: "Education", score: educationAvg },
      { name: "Experience", score: experienceAvg },
      { name: "Job Title", score: jobTitleAverage },
    ]);
  };

  const Parse = async (method) => {
    try {
      setLoading(true);
      console.log("will Parse");
      let result = method === "h5" ? await h5Parsing() : await affParsing();
      console.log("Parsed");
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error("something went wrong");
    }
  };
  return (
    <div>
      {(jobListLoading || loading) && <Loader />}
      <div className="fontSizeChange px-3">
        <div className="mb-2 mt-3">
          <div className="d-flex align-items-center gap-1 mb-4">
            <h6 className="m-0">Parsing Comparison</h6>{" "}
            <span
              data-bs-toggle="tooltip"
              data-bs-html="true"
              title="

For executing Parsing Comparison, 

Step 1: Select the job from the drop-down or click +New Job.

Step 2: Select the resume you want to perform a parsing comparison for. 

Step 3: High5 Parsing and Affinda Parsing buttons will appear once you select the resume. 

Step 4: Now, Press High5 Parsing and Affinda parsing to compare. 

 "
            >
              <i class="fal fa-info-circle"></i>
            </span>
          </div>

          <table class="table table-hoverabletable-cards-1024 ">
            <thead>
              <tr>
                <th className="col-3 " scope="col">
                  <div class="form-floating w-75 mt-3 mb-2 ">
                    <select
                      class="form-select"
                      id="selectJob"
                      name="selectJob"
                      value={selectedJobId}
                      disabled={h5Paresed || affParsed || gptParsed}
                      onChange={(e) => {
                        setSelectedJobId(e.target.value);
                      }}
                    >
                      <option value="" selected>
                        Select Job
                      </option>
                      {jobList.map((job) => (
                        <option value={job.jobId}>{job.jobTitle}</option>
                      ))}
                    </select>
                    <label>Select Job</label>
                  </div>
                </th>
                <th className="col-3 " scope="col">
                  <div class="d-flex justify-content-between">
                    {" "}
                    <div className="">
                      <button
                        style={{ width: "150px" }}
                        className="mb-3"
                        disabled={h5Paresed || affParsed || gptParsed}
                        onClick={() => {
                          setSelectedJobId("");
                          setAddJobNewModal(true);
                        }}
                      >
                        <i className="fas fa-plus fa-fw-dropdown"></i> New Job
                      </button>
                    </div>
                  </div>
                </th>
                <th className="col-3" scope="col">
                  {" "}
                  <div class="mt-2">
                    <Resume
                      resumeFile={resumeFile}
                      setResumeFile={setResumeFile}
                      setH5Parsed={setH5Parsed}
                      setAffparsed={setAffparsed}
                      setGptParsed={setGptParsed}
                      selectedJobId={selectedJobId}
                    />
                  </div>
                </th>
              </tr>
              {selectedJobId && resumeFile.fileName && (
                <tr>
                  <th className="col-3 border-end" scope="col"></th>
                  <th className="col-3 border-end" scope="col">
                    <div className="d-flex justify-content-between">
                      <div>
                        <button
                          style={{ width: "150px" }}
                          className="mb-3"
                          disabled={!selectedJobId || !resumeFile.fileName}
                          onClick={() => Parse("h5")}
                        >
                          High5 Parsing
                        </button>
                      </div>
                      {h5Paresed && (
                        <div className="d-flex flex-column align-items-center justify-content-end">
                          <div style={{ width: "3rem", marginLeft: -10 }}>
                            <CircularProgressbar
                              value={h5Total}
                              text={`${h5Total}%`}
                              background
                              backgroundPadding={6}
                              styles={buildStyles({
                                backgroundColor:
                                  h5Total >= 50 ? "green" : "orange",
                                textColor: "#fff",
                                pathColor: "#fff",
                                trailColor: "transparent",
                              })}
                            />
                          </div>{" "}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              whiteSpace: "wrap",
                              marginLeft: -5,
                            }}
                          >
                            <small
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                                marginLeft: -5,
                                fontSize: 10,
                              }}
                            >
                              Overall
                            </small>
                          </div>
                        </div>
                      )}
                    </div>
                  </th>
                  <th className="col-3" scope="col">
                    <div className="d-flex justify-content-between ">
                      <div>
                        {" "}
                        <button
                          style={{ width: "150px" }}
                          className="mb-3"
                          disabled={!selectedJobId || !resumeFile.fileName}
                          onClick={() => Parse("aff")}
                        >
                          Affinda Parsing
                        </button>
                      </div>{" "}
                      {affParsed && (
                        <div className="d-flex flex-column align-items-center justify-content-end">
                          <div style={{ width: "3rem", marginLeft: -10 }}>
                            <CircularProgressbar
                              value={affTotal}
                              text={`${affTotal}%`}
                              background
                              backgroundPadding={6}
                              styles={buildStyles({
                                backgroundColor:
                                  affTotal >= 50 ? "green" : "orange",
                                textColor: "#fff",
                                pathColor: "#fff",
                                trailColor: "transparent",
                              })}
                            />
                          </div>{" "}
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              whiteSpace: "wrap",
                              marginLeft: -5,
                            }}
                          >
                            <small
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                whiteSpace: "nowrap",
                                marginLeft: -5,
                                fontSize: 10,
                              }}
                            >
                              Overall
                            </small>
                          </div>
                        </div>
                      )}
                    </div>
                  </th>
                  {/* <th className="col-3" scope="col">
                  <div className=" d-flex justify-content-between">
                    <button style={{width: "150px"}}
                      className="mb-3"
                      disabled={true || !selectedJobId || !resumeFile.fileName}
                      onClick={() => setGptParsed(true)}
                    >
                      ChatGPT Parsing
                    </button>
                    {gptParsed && (
                      <div className="d-flex flex-column align-items-center justify-content-end">
                        <div style={{ width: "3rem", marginLeft: -10 }}>
                          <CircularProgressbar
                            strokeWidth={3}
                            value={`100`}
                            text={`${100}%`}
                            styles={buildStyles({
                              textColor: "green",
                              alignItems: "top",
                              pathColor: "green",
                            })}
                          />
                        </div>{" "}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            whiteSpace: "wrap",
                            marginLeft: -5,
                          }}
                        >
                          <small
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              whiteSpace: "nowrap",
                              marginLeft: -5,
                              fontSize: 9,
                            }}
                          >
                            Score
                          </small>
                        </div>
                      </div>
                    )}
                  </div>
                </th> */}
                </tr>
              )}
            </thead>
            <tbody>
              {(h5Paresed || affParsed || gptParsed) && (
                <tr>
                  {/* <td scope="col" className="border-end">
                    {(h5Paresed || affParsed || gptParsed) && (
                      <div>
                        <p>
                          <strong> Automatch Parameters</strong>{" "}
                          <span>(Green = matched)</span>
                        </p>
                      </div>
                    )}
                  </td> */}
                  <td scope="col" className=" border-end">
                    {(h5Paresed || affParsed || gptParsed) && (
                      <>
                        <strong> Automatch Parameters: </strong>
                        <div className="mb-2 ">
                          {weightage?.primarySkills?.length > 0 && (
                            <span className="tag tag-green3">Skills</span>
                          )}{" "}
                          {weightage?.location && (
                            <span className="tag tag-green3">Location</span>
                          )}{" "}
                          {weightage?.experienceLevel && (
                            <span className="tag tag-green3">Experience</span>
                          )}{" "}
                          {weightage?.education?.length > 0 && (
                            <span className="tag tag-green3">Education</span>
                          )}{" "}
                          {weightage?.jobTitle && (
                            <span className="tag tag-green3">Job title</span>
                          )}
                        </div>
                        {/* <div className="mb-2">
                          <span className="tag tag-red2">Skills</span>{" "}
                          <span className="tag tag-red2">Location</span>{" "}
                          <span className="tag tag-red2">Experience</span>{" "}
                          <span className="tag tag-red2">Education</span>{" "}
                          <span className="tag tag-red2">Job title</span>
                        </div> */}
                      </>
                    )}
                  </td>{" "}
                  <td scope="col" className=" border-end">
                    {h5Paresed && (
                      <>
                        <div className="mb-2 ">
                          {required.skills && matchedH5.skills > 0 && (
                            <span className="tag tag-green3">Skills</span>
                          )}{" "}
                          {required.location && matchedH5.location > 0 && (
                            <span className="tag tag-green3">Location</span>
                          )}{" "}
                          {required.experience && matchedH5.experience > 0 && (
                            <span className="tag tag-green3">Experience</span>
                          )}{" "}
                          {required.education && matchedH5.education > 0 && (
                            <span className="tag tag-green3">Education</span>
                          )}{" "}
                          {required.jobTitle && matchedH5.jobTitle > 0 && (
                            <span className="tag tag-green3">Job title</span>
                          )}
                        </div>
                        <div className="mb-2">
                          {required.skills && matchedH5.skills === 0 && (
                            <span className="tag tag-red2">Skills</span>
                          )}{" "}
                          {required.location && matchedH5.location === 0 && (
                            <span className="tag tag-red2">Location</span>
                          )}{" "}
                          {required.experience &&
                            matchedH5.experience === 0 && (
                              <span className="tag tag-red2">Experience</span>
                            )}{" "}
                          {required.education && matchedH5.education === 0 && (
                            <span className="tag tag-red2">Education</span>
                          )}{" "}
                          {required.jobTitle && matchedH5.jobTitle === 0 && (
                            <span className="tag tag-red2">Job title</span>
                          )}
                        </div>
                      </>
                    )}
                  </td>
                  <td scope="col" className=" border-end ">
                    {affParsed && (
                      <>
                        <div className="mb-2 ">
                          {required.skills && matchedAff.skills > 0 && (
                            <span className="tag tag-green3">Skills</span>
                          )}{" "}
                          {required.location && matchedAff.location > 0 && (
                            <span className="tag tag-green3">Location</span>
                          )}{" "}
                          {required.experience && matchedAff.experience > 0 && (
                            <span className="tag tag-green3">Experience</span>
                          )}{" "}
                          {required.education && matchedAff.education > 0 && (
                            <span className="tag tag-green3">Education</span>
                          )}{" "}
                          {required.jobTitle && matchedAff.jobTitle > 0 && (
                            <span className="tag tag-green3">Job title</span>
                          )}
                        </div>
                        <div className="mb-2">
                          {required.skills && matchedAff.skills === 0 && (
                            <span className="tag tag-red2">Skills</span>
                          )}{" "}
                          {required.location && matchedAff.location === 0 && (
                            <span className="tag tag-red2">Location</span>
                          )}{" "}
                          {required.experience &&
                            matchedAff.experience === 0 && (
                              <span className="tag tag-red2">Experience</span>
                            )}{" "}
                          {required.education && matchedAff.education === 0 && (
                            <span className="tag tag-red2">Education</span>
                          )}{" "}
                          {required.jobTitle && matchedAff.jobTitle === 0 && (
                            <span className="tag tag-red2">Job title</span>
                          )}
                        </div>
                      </>
                    )}
                  </td>{" "}
                  {/* <td scope="col" className=" ">
                    {gptParsed && (
                      <>
                        <div className="mb-2">
                          <span className="tag tag-green3">Skills</span>{" "}
                          <span className="tag tag-green3">Location</span>{" "}
                          <span className="tag tag-green3">Experience</span>{" "}
                          <span className="tag tag-green3">Education</span>{" "}
                        </div>
                        <div className="mb-2">
                          <span className="tag tag-red2">Job title</span>{" "}
                        </div>
                      </>
                    )}
                  </td> */}
                </tr>
              )}
              {(h5Paresed || affParsed || gptParsed) && (
                <tr>
                  <td className=" border-end ">
                    <strong>Automatch Score: </strong>
                    {(h5Paresed || affParsed || gptParsed) && (
                      <AutomatchScore average={average} />
                    )}
                  </td>
                  <td className="border-end ">
                    {h5Paresed && <AutomatchScore average={averageh5} />}
                  </td>
                  <td className=" border-end ">
                    {affParsed && <AutomatchScore average={averageAff} />}
                  </td>
                  {/* <td className="">
                    {gptParsed && <AutomatchScore average={average} />}
                  </td> */}
                </tr>
              )}{" "}
              {(h5Paresed || affParsed || gptParsed) && (
                <tr>
                  <td style={{ verticalAlign: "top" }} className=" border-end">
                    {(h5Paresed || affParsed || gptParsed) && (
                      <div className="">
                        <p>
                          <strong>Job title:</strong> {selectedJob?.jobTitle}
                        </p>
                        <p>
                          <strong>Job Location:</strong>{" "}
                          {selectedJob?.workPlaceType === "Remote"
                            ? selectedJob?.workPlaceType
                            : `${selectedJob?.city}, ${selectedJob?.state}, ${selectedJob?.country}`}
                        </p>
                        <p>
                          <strong>Education:</strong>{" "}
                          {selectedJob && JSON.parse(selectedJob?.education)[0]}
                        </p>
                        <p>
                          <strong>Experience:</strong>{" "}
                          {selectedJob?.experienceLevel}
                        </p>
                        <p>
                          <strong>Skills:</strong>{" "}
                          {selectedJob &&
                            JSON.parse(selectedJob?.primarySkills)
                              ?.toString()
                              ?.replaceAll(",", ", ")}
                        </p>

                        <p
                          dangerouslySetInnerHTML={{
                            __html: `<strong>Job description:</strong>${" "}${
                              selectedJob?.jobDescription
                            }`,
                          }}
                        ></p>
                      </div>
                    )}
                  </td>
                  <td style={{ verticalAlign: "top" }} className=" border-end">
                    {h5Paresed && (
                      <div className="mt-0">
                        {h5PareseData?.name && (
                          <p>
                            <strong>Name:</strong>{" "}
                            <Highlighter
                              className="lh-base"
                              highlightStyle={{
                                fontWeight: "normal",
                                backgroundColor: "#f48f42",
                              }}
                              highlightClassName="YourHighlightClass"
                              searchWords={[]}
                              autoEscape={true}
                              textToHighlight={h5PareseData?.name}
                            />
                          </p>
                        )}
                        {h5PareseData?.designation && (
                          <p>
                            <strong>Job Title:</strong>{" "}
                            <Highlighter
                              className="lh-base"
                              highlightStyle={{
                                fontWeight: "normal",
                                backgroundColor: "#f48f42",
                              }}
                              highlightClassName="YourHighlightClass"
                              searchWords={selectedJob?.jobTitle.split(" ")}
                              autoEscape={true}
                              textToHighlight={h5PareseData?.designation}
                            />
                          </p>
                        )}
                        {h5PareseData?.location && (
                          <p>
                            <strong>Location:</strong>{" "}
                            <Highlighter
                              className="lh-base"
                              highlightStyle={{
                                fontWeight: "normal",
                                backgroundColor: "#f48f42",
                              }}
                              highlightClassName="YourHighlightClass"
                              searchWords={
                                selectedJob?.workPlaceType === "Remote"
                                  ? [selectedJob?.workPlaceType]
                                  : [
                                      selectedJob?.city,
                                      selectedJob?.state,
                                      selectedJob?.country,
                                    ]
                              }
                              autoEscape={true}
                              textToHighlight={h5PareseData?.location?.address}
                            />
                          </p>
                        )}
                        {h5PareseData?.education?.length > 0 &&
                          h5PareseData?.education[0]?.program && (
                            <p>
                              <strong>Education:</strong>{" "}
                              <Highlighter
                                className="lh-base"
                                highlightStyle={{
                                  fontWeight: "normal",
                                  backgroundColor: "#f48f42",
                                }}
                                highlightClassName="YourHighlightClass"
                                searchWords={JSON.parse(selectedJob?.education)}
                                autoEscape={true}
                                textToHighlight={
                                  h5PareseData?.education[0]?.program
                                }
                              />
                            </p>
                          )}
                        {h5PareseData?.experience && (
                          <p>
                            <strong>Experience:</strong>{" "}
                            <Highlighter
                              className="lh-base"
                              highlightStyle={{
                                fontWeight: "normal",
                                backgroundColor: "#f48f42",
                              }}
                              highlightClassName="YourHighlightClass"
                              searchWords={[]}
                              autoEscape={true}
                              textToHighlight={h5PareseData?.experience}
                            />
                          </p>
                        )}
                        {h5PareseData?.primary_skills &&
                          h5PareseData?.primary_skills?.length > 0 && (
                            <p>
                              <strong>Skills:</strong>{" "}
                              <Highlighter
                                className="lh-base"
                                highlightStyle={{
                                  fontWeight: "normal",
                                  backgroundColor: "#f48f42",
                                }}
                                highlightClassName="YourHighlightClass"
                                searchWords={
                                  selectedJob &&
                                  JSON.parse(selectedJob?.primarySkills)
                                }
                                autoEscape={true}
                                textToHighlight={h5PareseData?.primary_skills
                                  ?.toString()
                                  ?.replaceAll(",", ", ")}
                              />
                            </p>
                          )}
                        {h5PareseData?.resume_text &&
                          h5PareseData?.resume_text?.length > 0 && (
                            <p>
                              <strong>Summary</strong>{" "}
                              <span>{h5PareseData?.resume_text}</span>
                              {/* <Highlighter
                                className="lh-base"
                                highlightStyle={{
                                  fontWeight: "normal",
                                  backgroundColor: "#f48f42",
                                }}
                                highlightClassName="YourHighlightClass"
                                searchWords={
                                  selectedJob &&
                                  JSON.parse(selectedJob?.primarySkills)
                                }
                                autoEscape={true}
                                textToHighlight={h5PareseData?.primary_skills
                                  ?.toString()
                                  ?.replaceAll(",", ", ")}
                              /> */}
                            </p>
                          )}
                      </div>
                    )}
                  </td>{" "}
                  <td style={{ verticalAlign: "top" }} className="border-end">
                    {affParsed && (
                      <div>
                        {affParseData?.name?.first && (
                          <p>
                            <strong>Name:</strong> {affParseData?.name?.first}{" "}
                            <Highlighter
                              className="lh-base"
                              highlightStyle={{
                                fontWeight: "normal",
                                backgroundColor: "#f48f42",
                              }}
                              highlightClassName="YourHighlightClass"
                              searchWords={[]}
                              autoEscape={true}
                              textToHighlight={affParseData?.name?.last}
                            />
                          </p>
                        )}
                        {affParseData?.profession && (
                          <p>
                            <strong>Job Title:</strong>{" "}
                            <Highlighter
                              className="lh-base"
                              highlightStyle={{
                                fontWeight: "normal",
                                backgroundColor: "#f48f42",
                              }}
                              highlightClassName="YourHighlightClass"
                              searchWords={selectedJob?.jobTitle.split(" ")}
                              autoEscape={true}
                              textToHighlight={affParseData?.profession}
                            />
                          </p>
                        )}
                        {affParseData?.location && (
                          <p>
                            <strong>Location:</strong>{" "}
                            <Highlighter
                              className="lh-base"
                              highlightStyle={{
                                fontWeight: "normal",
                                backgroundColor: "#f48f42",
                              }}
                              highlightClassName="YourHighlightClass"
                              searchWords={
                                selectedJob?.workPlaceType === "Remote"
                                  ? [selectedJob?.workPlaceType]
                                  : [
                                      selectedJob?.city,
                                      selectedJob?.state,
                                      selectedJob?.country,
                                    ]
                              }
                              autoEscape={true}
                              textToHighlight={
                                affParseData?.location?.formatted
                              }
                            />
                          </p>
                        )}
                        {affParseData?.education?.length > 0 &&
                          affParseData?.education.map(
                            (ed, index) =>
                              ed?.accreditation?.educationLevel && (
                                <p>
                                  <strong>{`Education ${
                                    affParseData?.education?.filter(
                                      (e) => e?.accreditation?.educationLevel
                                    ).length > 1
                                      ? index + 1
                                      : ""
                                  }:`}</strong>{" "}
                                  <Highlighter
                                    className="lh-base"
                                    highlightStyle={{
                                      fontWeight: "normal",
                                      backgroundColor: "#f48f42",
                                    }}
                                    highlightClassName="YourHighlightClass"
                                    searchWords={JSON.parse(
                                      selectedJob?.education
                                    )}
                                    autoEscape={true}
                                    textToHighlight={`${ed?.accreditation?.educationLevel} in ${ed?.accreditation?.education}`}
                                  />
                                </p>
                              )
                          )}
                        {console.log(
                          affParseData?.workExperience?.length,
                          affParseData?.workExperience
                        )}
                        {affParseData?.workExperience?.length > 0 &&
                          affParseData?.workExperience.map(
                            (ex, index) =>
                              ex?.occupation?.jobTitleNormalized && (
                                <p>
                                  <strong>{`Experience ${
                                    affParseData?.workExperience?.filter(
                                      (e) => e?.occupation?.jobTitleNormalized
                                    ).length > 1
                                      ? index + 1
                                      : ""
                                  }:`}</strong>{" "}
                                  <Highlighter
                                    className="lh-base"
                                    highlightStyle={{
                                      fontWeight: "normal",
                                      backgroundColor: "#f48f42",
                                    }}
                                    highlightClassName="YourHighlightClass"
                                    searchWords={[]}
                                    autoEscape={true}
                                    textToHighlight={`${ex?.occupation?.jobTitleNormalized} at ${ex?.organization}`}
                                  />
                                </p>
                              )
                          )}
                        {affParseData?.totalYearsExperience && (
                          <p>
                            <strong>Total years of experience:</strong>{" "}
                            <Highlighter
                              className="lh-base"
                              highlightStyle={{
                                fontWeight: "normal",
                                backgroundColor: "#f48f42",
                              }}
                              highlightClassName="YourHighlightClass"
                              searchWords={[]}
                              autoEscape={true}
                              textToHighlight={
                                affParseData?.totalYearsExperience
                              }
                            />
                          </p>
                        )}
                        {affParseData?.skills &&
                          affParseData?.skills?.length > 0 && (
                            <p>
                              <strong>Skills:</strong>{" "}
                              <Highlighter
                                className="lh-base"
                                highlightStyle={{
                                  fontWeight: "normal",
                                  backgroundColor: "#f48f42",
                                }}
                                highlightClassName="YourHighlightClass"
                                searchWords={
                                  selectedJob &&
                                  JSON.parse(selectedJob?.primarySkills)
                                }
                                autoEscape={true}
                                textToHighlight={affParseData?.skills
                                  ?.map((s) => s?.name)
                                  ?.toString()
                                  ?.replaceAll(",", ", ")}
                              />
                            </p>
                          )}
                        {affParseData?.linkedin && (
                          <p>
                            <strong>linkedin:</strong>{" "}
                            <Highlighter
                              className="lh-base"
                              highlightStyle={{
                                fontWeight: "normal",
                                backgroundColor: "#f48f42",
                              }}
                              highlightClassName="YourHighlightClass"
                              searchWords={[]}
                              autoEscape={true}
                              textToHighlight={affParseData?.linkedin}
                            />
                          </p>
                        )}{" "}
                        {affParseData?.summary && (
                          <p>
                            <strong>Summary:</strong>{" "}
                            <Highlighter
                              className="lh-base"
                              highlightStyle={{
                                fontWeight: "normal",
                                backgroundColor: "#f48f42",
                              }}
                              highlightClassName="YourHighlightClass"
                              searchWords={[]}
                              autoEscape={true}
                              textToHighlight={affParseData?.summary}
                            />
                          </p>
                        )}
                      </div>
                    )}
                  </td>
                  {/* <td className="">
                    {gptParsed && (
                      <div>
                        <p>
                          <strong>Name:</strong> Smith Thomas
                        </p>
                        <p>
                          <strong>Job Title:</strong> React Developer
                        </p>
                        <p>
                          <strong>Location:</strong> {selectedJob?.location}
                        </p>
                        <p>
                          <strong>Education:</strong> {selectedJob?.Education}
                        </p>
                        <p>
                          <strong>Experience:</strong> {selectedJob?.Experience}
                        </p>
                        <p>
                          <strong>Skills:</strong>{" "}
                          {selectedJob?.skills
                            ?.toString()
                            ?.replaceAll(",", ", ")}
                        </p>
                      </div>
                    )}
                  </td> */}
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <Modal show={addJobNewModal} size="lg" onHide={() => closeAddJobModal()}>
        <Modal.Header>
          <h6 className="">New Job</h6>
          <button
            //style={{ width: "150px" }}
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => closeAddJobModal()}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <MainAddJob
            setSuccessData={setSuccessData}
            setSuccessFormOpen={setSuccessFormOpen}
            closePanel={() => closeAddJobModal()}
            isFrom={"parsingComparison"}
            getAllJobs={getJobList}
            setLoadingPC={setLoading}
            setSelectedJobId={setTempId}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ParsingComparison;
