import React, { useEffect, useState } from "react";
import { post } from "../../../../../service/ApiService";
import { toast } from "react-toastify";
import FileUpload from "../../../commoncomponents/fileupload/FileUpload";
import buttonLoader from "../../../../../images/button-loader.gif";
import Dropzone from "react-dropzone";
import Loader from "../../../../Loader/Loader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import BulkResumeUpload from "../bulkupload/BulkResumeUpload";
import { Button, Modal, ProgressBar } from "react-bootstrap";

const ResumeUploadAddTalent = (props) => {
  const [resumeFile, setResumeFile] = useState({ fileName: "", base64: "" });
  const [valid, setValid] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [bulkLoading, setBulkLoading] = useState(false);
  const [hideResumeBtn, setHideResumeBtn] = useState(false);

  const uploadResume = async () => {
    try {
      if (valid) {
        setSubmitClicked(true);
        setLoading(true);
        // QA/Dev parsing Issue
        // const payload = {
        //   filename: resumeFile.fileName,
        //   base64: resumeFile.base64,
        // };
        // const resultPy = await post("/talent/parseuploadresume", payload);

        /*resume extract
        var re = /(?:\.([^.]+))?$/;
        var ext = re.exec(resumeFile.fileName)[1]; // "
        let param = {
          filename: resumeFile.fileName,
          base64: resumeFile.base64.split(",")[1],
          fileType: ext,
        };
        const extractedRes = await post("/textExtract/resumeExtract", param);
        props.setExtractedResumeText(extractedRes.data);
        */

        // if (resultPy.status === 200) {
        //   const finalresultPy = {
        //     ...resultPy.data,
        //     ...resumeFile,
        //     h5h: true,
        //   };
        //   props.updateParsedData(finalresultPy, true, true);
        //   setSubmitClicked(false);
        //   setLoading(false);
        // } else {
        //   setLoading(false);
        //   toast.error("Something went wrong");
        // }
        // End

        const result = await post("/talent/resume", resumeFile);
        if (result.status === 200) {
          if (result?.data?.data) {
            const finalresult = {
              ...result.data,
              ...resumeFile,
              h5h: false,
            };
            props.updateParsedData(finalresult, true, false);
            setSubmitClicked(false);
            setLoading(false);
          } else {
            toast.warn(
              "Our primary parsing engine is busy, we are taking you to secondary parsing engine."
            );
            const payload = {
              filename: resumeFile.fileName,
              base64: resumeFile.base64,
            };
            const resultPy = await post("/talent/parseuploadresume", payload);

            const finalresultPy = {
              ...resultPy.data,
              ...resumeFile,
              h5h: true,
            };
            props.updateParsedData(finalresultPy, true, true);
            setSubmitClicked(false);
            setLoading(false);
          }
        }
      } else {
        toast.error("Please Upload resume first");
        setSubmitClicked(false);
        setLoading(false);
      }
    } catch (error) {
      toast.error("Something went wrong");
      // setSubmitClicked(false);
    }
    //incrementUploadValue(1)
  };

  useEffect(() => {
    setSubmitClicked(false);
    setLoading(false);
  }, []);

  const onRemove = (e) => {
    setResumeFile(undefined);
    setHideResumeBtn(true);
    toast.success("File removed successfully");
    setHideResumeBtn(false);
  };

  const setFileUpload = (file) => {
    console.log("file", file);
    var re = /(?:\.([^.]+))?$/;
    const { name, size } = file;
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
    var ext = re.exec(file.name)[1]; // "
    if (size > maxSizeInBytes) {
      toast.error("File size exceeds the maximum limit of 2MB.");
    } else if (ext === "pdf" || ext === "docx" || ext === "doc") {
      setValid(true);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        setResumeFile({ fileName: name, base64: event.target.result });
        setHideResumeBtn(true);
      };
    } else {
      toast.error(
        "Invalid file types. Only .doc, .docx, and .pdf files are allowed."
      );
      setValid(false);
    }
  };

  const onDragDropChangeHandler = (e) => {
    e.length === 1 && setFileUpload(e[0]);
  };

  const getShortenedName = (fileName) => {
    if (fileName?.length > 30) {
      const extension = fileName.split(".").pop(); // Get the file extension
      const shortenedName = fileName.substring(0, 25) + "..." + extension; // Shorten the name to 17 characters
      return shortenedName;
    } else {
      return fileName;
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div class="ml-2">
        <div class="ml-4">
          <p>
            <strong>Add Talent</strong>
            <p className="mt-2">
              Click on select file and upload resume to add talent right away.
            </p>
          </p>{" "}
        </div>
        <div class="row align-items-center mb-2">
          <div class="col-sm-3 ml-4">
            <div className="">
              <Dropzone
                onDrop={(file) => onDragDropChangeHandler(file)}
                multiple={false}
                acceptedFiles={(".docx", ".doc", ".pdf")}
                disabled={hideResumeBtn}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="">
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary"
                      disabled={hideResumeBtn}
                    >
                      <i className="far fa-cloud-upload mr-1 fa-fw"></i>
                      Select File
                    </button>
                    <input
                      type="file"
                      {...getInputProps()}
                      accept=".docx, .pdf"
                    />
                  </div>
                )}
              </Dropzone>
            </div>
          </div>
          <div class="col-sm-3">
            <p className="mt-2">
              <small>
                {/* <strong>{resumeFile?.fileName}</strong> */}
                <strong>{getShortenedName(resumeFile?.fileName)}</strong>
              </small>
            </p>
          </div>
          <div class="col-sm-2">
            {resumeFile && resumeFile?.fileName && submitClicked === false && (
              <div className="mb-2 ">
                <button
                  type="button"
                  className="btn btn-icon ml-2 mt-1"
                  aria-hidden="true"
                  style={{ cursor: "default" }}
                >
                  <i
                    class="fal fa-fw fa-trash-alt"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      onRemove();
                    }}
                  ></i>
                  <i
                    class="fa fa-check-circle"
                    style={{ color: "green", marginLeft: 30 }}
                  ></i>
                </button>
              </div>
            )}
          </div>
          <div class="col-sm-2">
            {resumeFile && resumeFile.fileName && (
              <button type="button" class="btn btn-primary btn-sm ml-2">
                {submitClicked === false ? (
                  <span onClick={uploadResume}>Upload</span>
                ) : (
                  <span v-if="submittingForm">
                    Uploading
                    <img width="20px" alt="" src={buttonLoader} />
                  </span>
                )}
              </button>
            )}
          </div>
        </div>
        <div class="dividerSpace ml-2 mr-2"></div>

        <div class="col ml-4 mt-5">
          <p>
            <strong>Add Bulk Talent</strong>
          </p>{" "}
          <p className="mr-3">
            Please note that Bulk talent upload is an offline process that may
            take several minutes to several hours, depending on resume quantity
            and system availability.
          </p>
        </div>
        <BulkResumeUpload
          // setLoading={setLoading}
          // loading={loading}
          setBulkLoading={setBulkLoading}
          bulkLoading={bulkLoading}
          closeModal={props.closeModal}
        />

        {/* <div className="row">
          <div className="col-12">
            <div className="p-5 p-0-600">
              <h6> Upload Resumes</h6>{" "}
              <p className={valid ? "text-primary" : ""}>
                Accepted File Type: .docx, .pdf{" "}
              </p>
              <p className="m-0 mr-2">{resumeFile?.fileName}</p>
              <Dropzone
                onDrop={(file) => onDragDropChangeHandler(file)}
                multiple={false}
                // acceptedFiles = {'.docx','.pdf'}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps()} className="">
                    <button
                      type="button"
                      className="btn btn-sm btn-secondary"
                      //disabled={hideResumeBtn}
                    >
                      <i className="far fa-cloud-upload mr-1 fa-fw"></i>
                      Upload Resume
                    </button>
                    <input
                      type="file"
                      {...getInputProps()}
                      accept=".docx, .pdf"
                    />
                  </div>
                )}
              </Dropzone>
            </div>
            <div className="col-md-3">
              {resumeFile && resumeFile?.fileName && submitClicked === false && (
                <div className="mb-2 ">
                  <button
                    type="button"
                    className="btn btn-icon ml-2 mt-1"
                    aria-hidden="true"
                    style={{ cursor: "default" }}
                  >
                    <i
                      class="fal fa-fw fa-trash-alt"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onRemove();
                      }}
                    ></i>
                    <i
                      class="fa fa-check-circle"
                      style={{ color: "green", marginLeft: 30 }}
                    ></i>
                  </button>
                </div>
              )}
              <div className="col-sm-12">
                <div class="modal-footer">
                  {valid && (
                    <div class="">
                      <button
                        type="button"
                        class="btn btn-secondary btn-sm "
                        disabled={submitClicked}
                        onClick={props.closeModal}
                      >
                        Cancel
                      </button>
                      {resumeFile && resumeFile.fileName && (
                        <button
                          type="button"
                          class="btn btn-primary btn-sm ml-2"
                        >
                          {submitClicked === false ? (
                            <span onClick={uploadResume}>Upload</span>
                          ) : (
                            <span v-if="submittingForm">
                              Uploading
                              <img width="20px" alt="" src={buttonLoader} />
                            </span>
                          )}
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
export default ResumeUploadAddTalent;
