import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { uniqBy } from "lodash";
import { dashboard, common } from "./PrivateRoles";
import { getUserToken } from "../service/AuthService";
import {
  getUserRole,
  getActualRole,
  getUserLastUpdated,
} from "../service/AuthService";
import * as Routes from "./PrivateComponentList";

class PrivateRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inActive: false,
      allowedRoutes: [],
      interval: null,
    };
  }

  updateLastActiveTime = () => {
    const currentTime = Date.now();
    localStorage.setItem("lastActiveTime", currentTime.toString());
  };

  checkForInactivity = () => {
    try {
      const lastActiveTime = parseInt(localStorage.getItem("lastActiveTime"));
      const sessionTime = 6 * 60 * 60 * 1000; // 6 hours in milliseconds
      // const sessionTime = 15 * 60 * 1000;  // 15 minutes
      if (Date.now() - lastActiveTime >= sessionTime) {
        this.setState({ inActive: true });
        this.props.history.push("/high5Hire/sessionTimeOut");
      }
    } catch (err) {
      console.error("Error while checking for inactivity:", err);
    }
  };

  handleActivity = () => {
    this.updateLastActiveTime();
  };

  componentDidMount() {
    if (
      !["/high5Hire/sessionTimeOut", "high5Hire/updateProfile"].includes(
        window.localStorage.pathname
      )
    ) {
      localStorage.setItem("previousRoute", window.location.pathname);
    }

    this.checkForInactivity();
    const interval = setInterval(this.checkForInactivity, 5000);
    this.setState({ interval });
    const eventListeners = ["click", "keypress", "scroll", "mousemove"];
    this.listenerCallback = () => {
      this.handleActivity();
      this.checkForInactivity(); // Check for inactivity on every activity
    };
    eventListeners.forEach((eventType) => {
      window.addEventListener(eventType, this.listenerCallback);
    });

    const userUpdatedProfile = getUserLastUpdated();
    const userRole = getUserRole();
    const actualRole = getActualRole();
    const periodInMinutes = 180 * 24 * 60; // 6 months in minutes
    const currentTime = new Date();
    const lastUpdatedTime = new Date(userUpdatedProfile);
    const timeDifferenceMinutes = (currentTime - lastUpdatedTime) / (1000 * 60); // Difference in minutes
    if (
      userUpdatedProfile &&
      timeDifferenceMinutes >= periodInMinutes &&
      userRole === "Recruiter" &&
      !actualRole
    ) {
      this.props.history.push("/high5Hire/updateProfile");
    }

    let allowedRoutes = [];
    const roles = getUserToken().role;
    if (roles) {
      let routes = common?.filter((item) => item?.role?.includes(roles));
      allowedRoutes.push(dashboard[roles]);
      allowedRoutes = [...allowedRoutes, ...routes];
      allowedRoutes = uniqBy(allowedRoutes, "url");
      this.setState({ allowedRoutes });
    } else {
      if (window.location.href.includes("/requests")) {
        const jobId = window.location.href.split("/requests/")[1];
        localStorage.setItem("jobId", jobId);
      }
      this.props.history.push("/");
    }
  }

  componentWillUnmount() {
    if (getUserRole() === "Recruiter" && !getActualRole()) {
      this.checkForInactivity();
    }
    if (this.state.interval) {
      clearInterval(this.state.interval);
    }

    const eventListeners = ["click", "keypress", "scroll", "mousemove"];
    eventListeners.forEach((eventType) => {
      window.removeEventListener(eventType, this.listenerCallback);
    });
  }

  render() {
    return (
      <>
        <Switch>
          {this.state.allowedRoutes.map((route, index) => (
            <Route
              exact
              key={index}
              component={Routes[route.component]}
              path={`${this.props.match.path}${route.url}`}
            />
          ))}
          <Route />
        </Switch>
      </>
    );
  }
}

export default PrivateRoutes;
