import React, { useState, useEffect } from "react";
import Qualified from "./qualified/Index";
import Invite from "./invite/Index";
import Automatch from "./automatch/Index";
import Submitted from "./submitted/Index";
import Hired from "./hired/Index";
import Disqualified from "./disqualified/Index";
import {
  Automatched_Recruiter_SubComm,
  Engaged_Q_SubComm,
  Invited_Recruiter_SubComm,
  ReadyToHire_Q_SubComm,
} from "../../../EmailStatus/EmailStatusQueries";
import { post } from "../../../../../service/ApiService";
import Loader from "../../../../Loader/Loader";
import Vetting from "./vetting/Index";

const Index = (props) => {
  const {
    jobData,
    view,
    //dispositionDetails,
    dispositionDisqualify,
    setFilterData,
    filterData,
    filterParams,
    currentPage,
    setCurrentPage,
    filterLength,
    selectedTalents,
    setSelectedTalents,
  } = props;
  const [loading, setLoading] = useState(false);

  const [jobDetailsData, setJobDetailsData] = useState({});
  useEffect(() => {
    if (jobData && Object.keys(jobData).length != 0) {
      setJobDetailsData({
        ...jobData,
        primarySkills: jobData.primarySkills
          ? JSON.parse(jobData.primarySkills)
          : [],
        education: jobData.education ? JSON.parse(jobData.education) : [],
      });
    }
  }, [jobData]);

  const getEmailStatus = async (params) => {
    try {
      const response = await post("/sendGrid/filterByRecipient", params);
      let d = response.data;
      return d;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const fetchLatestEmailStatus = async (data, setData, tab) => {
    let finalData = data.map((can) => ({
      ...can,
      latestEmailStatus: "noMail",
    }));

    const getQuery = () => {
      if (tab === "Invited") return Invited_Recruiter_SubComm;
      if (tab === "communityAutoMatched") return Automatched_Recruiter_SubComm;
      if (tab === "Engaged") return Engaged_Q_SubComm;
      if (tab === "ReadyToHire") return ReadyToHire_Q_SubComm;
    };

    setLoading(true);

    try {
      const promises = data.map(async (x) => {
        try {
          const params = {
            queryObj: {
              limit: 10000,
              query: `${getQuery()} AND (to_email="${x.email}")`,
            },
            jobId: jobData?.jobId,
          };

          const response = await getEmailStatus(params);

          return response;
        } catch (error) {
          console.error(`Error for email ${x.email}:`, error);
          // Handle the error if needed, or just return a default value
          return { error: "An error occurred" };
        }
      });

      const results = await Promise.all(promises);

      setLoading(false);
      if (results.length > 0) {
        results
          .filter((r) => Array.isArray(r))
          .forEach((emails) => {
            emails.forEach((email) => {
              const { status, to_email } = email;

              finalData = finalData.map((can) => {
                console.log(
                  can?.email.toLowerCase(),
                  to_email.toLowerCase(),
                  can?.email.toLowerCase() === to_email.toLowerCase()
                );
                let d = can;
                if (can?.email.toLowerCase() === to_email.toLowerCase()) {
                  d.latestEmailStatus = status;
                }
                return d;
              });
            });
          });
      } else {
        console.log("No emails found in the array.");
      }

      setData(finalData);
      setLoading(false);
    } catch (error) {
      setData(finalData);
      setLoading(false);
      console.error("An error occurred during API calls", error);
    }
  };

  const calculateScore = (jobData, candidateData) => {
    let primary = 0;
    let location = 0;
    let education = 0;
    let jobTitle = 0;
    let experience = 0;
    let sum = 1;
    let count = jobData?.primarySkills?.length + jobData?.education?.length + 3;

    const candidateCountry = [
      candidateData?.country,
      candidateData?.city,
      candidateData?.state,
    ];
    let primaryAverage =
      (jobData?.primarySkills ? jobData?.primarySkills : [])?.length > 0
        ? Math.round(
            (primary * 100) /
              (jobData?.primarySkills ? jobData?.primarySkills : []).length
          )
        : 0;
    let locationAverage = location ? 100 : 0;
    let jobTitleAverage = jobTitle ? 100 : 0;
    let experienceAvg = experience ? 100 : 0;
    let educationAvg = education ? 100 : 0;
    let total = Math.round(
      (primaryAverage +
        locationAverage +
        jobTitleAverage +
        experienceAvg +
        educationAvg) /
        5
    );

    return 100;
  };

  return (
    <div class="col-xl-8">
      {loading && <Loader />}
      <div
        className="card card-flat card-lg card-top-straight"
        style={{ borderTop: "none" }}
      >
        <div className="card-body">
          {view == "automatched" && (
            <Automatch
              jobData={jobDetailsData}
              calculateScore={calculateScore}
              //dispositionDetails={filterData}
              fetchLatestEmailStatus={fetchLatestEmailStatus}
              view={view}
              filterParams={filterParams}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
          {view == "invite" && (
            <Invite
              jobData={jobDetailsData}
              //dispositionDetails={filterData}
              fetchLatestEmailStatus={fetchLatestEmailStatus}
              view={view}
              filterParams={filterParams}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
          {view == "qualified" && (
            <Qualified
              jobData={jobDetailsData}
              //dispositionDetails={filterData}
              view={view}
              filterParams={filterParams}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          )}
          {view == "submitted" && (
            <Submitted
              jobData={jobDetailsData}
              calculateScore={calculateScore}
              // dispositionDetails={filterData}
              fetchLatestEmailStatus={fetchLatestEmailStatus}
              view={view}
              filterParams={filterParams}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              filterLength={filterLength}
              selectedTalents={selectedTalents}
              setSelectedTalents={setSelectedTalents}
            />
          )}
          {view == "vetting" && (
            <Vetting
              jobData={jobDetailsData}
              calculateScore={calculateScore}
              dispositionDetails={filterData}
              fetchLatestEmailStatus={fetchLatestEmailStatus}
              view={view}
              filterParams={filterParams}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              filterLength={filterLength}
              selectedTalents={selectedTalents}
              setSelectedTalents={setSelectedTalents}
            />
          )}
          {view == "hired" && (
            <Hired
              jobData={jobDetailsData}
              calculateScore={calculateScore}
              //dispositionDetails={filterData}
              fetchLatestEmailStatus={fetchLatestEmailStatus}
              view={view}
              filterParams={filterParams}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              filterLength={filterLength}
              selectedTalents={selectedTalents}
              setSelectedTalents={setSelectedTalents}
            />
          )}
        </div>
      </div>
      <Disqualified
        jobData={jobDetailsData}
        calculateScore={calculateScore}
        //dispositionDetails={dispositionDisqualify}
        view={view}
        filterParams={filterParams}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default Index;
