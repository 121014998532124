import React, { useState } from "react";
import GenaralDetails from "./GenaralDetails";
import SkillDetails from "./SkillDetails";
import DescriptionDetails from "./DescriptionDetails";
import DescriptionPopUp from "./DescriptionPopUp";
import IndustriesDetails from "../details/IndustriesDetails";
import SubCategory from "../details/SubCategory";

const Index = (props) => {
  const { jobData } = props;
  const [generalCollapse, setGeneralCollapse] = useState(false);
  const [skillCollapse, setSkillCollapse] = useState(false);
  const [descriptionCollapse, setDescriptionCollapse] = useState(false);
  const [show, setShow] = useState(false);

  return (
    <div>
      <div className="mb-2">
        <button
          type="button"
          className="btn btn-collapsible"
          onClick={() => setGeneralCollapse(!generalCollapse)}
        >
          <i
            aria-hidden="true"
            className={
              generalCollapse
                ? "fal fa-angle-up fa-fw"
                : "fal fa-angle-down fa-fw"
            }
          />
          <span>
            <small>General</small>
          </span>
        </button>
      </div>
      {generalCollapse === true && <GenaralDetails jobData={jobData} />}
      <div className="mb-2">
        <button
          type="button"
          className="btn btn-collapsible"
          onClick={() => setSkillCollapse(!skillCollapse)}
        >
          <i
            aria-hidden="true"
            className={
              skillCollapse
                ? "fal fa-angle-up fa-fw"
                : "fal fa-angle-down fa-fw"
            }
          />
          <span>
            <small>Required Skills</small>
          </span>
        </button>
      </div>
      {skillCollapse && <SkillDetails jobData={jobData} />}

      {jobData?.description && (
        <div className="mb-2">
          <button
            type="button"
            className="btn btn-collapsible"
            onClick={() => setDescriptionCollapse(!descriptionCollapse)}
          >
            <i
              aria-hidden="true"
              className={
                descriptionCollapse
                  ? "fal fa-angle-up fa-fw"
                  : "fal fa-angle-down fa-fw"
              }
            />
            <span>
              <small>Description</small>
              <span className="ml-1">
                <i
                  className="fas fa-external-link-square"
                  style={{ fontSize: "small", color: "#FF5F57" }}
                  onClick={() => setShow(true)}
                />
              </span>
            </span>
          </button>
        </div>
      )}
      {descriptionCollapse && <DescriptionDetails jobData={jobData} />}
      {show && (
        <DescriptionPopUp jobData={jobData} setShow={setShow} show={show} />
      )}
      <IndustriesDetails jobData={jobData} />
      <SubCategory jobData={jobData} />
    </div>
  );
};

export default Index;
