import React, { useState, useEffect, useRef } from "react";
import { Modal, Form, Button, Table, Row, Col } from "react-bootstrap";
// import Pagination from "../../commoncomponents/pagination/Pagination";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { post } from "../../../../service/ApiService";
import Loader from "../../commoncomponents/Loader/Loader";
import { toast } from "react-toastify";
import SkillsSearchable from "../../commoncomponents/skills/SkillsSearchable";
import GoogleLocationCountryAPI from "../../../location/GoogleLocationCountryAPI";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

function AddCandContactsModal(props) {
  const { selectedJob } = useSelector(
    (state) => ({
      selectedJob: state.getJobListReducer.selectedJob,
    }),
    shallowEqual
  );
  const [candFilter, setCandFilter] = useState({
    createdDateFrom: "",
    createdDateTo: "",
    firstName: "",
    lastName: "",
    skills: [],
    city: "",
    email: "",
    country: "",
    experience: "",
  });
  const [tableData, setTableData] = useState([]);
  const [selectCandAll, setSelectCandAll] = useState(false);
  const [location, setLocation] = useState("");
  const [noData, setNoData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [totalRecipients, setTotalRecipients] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationArray, setPaginationArray] = useState([]);
  const [primarySkillInput, setPrimarySkillInput] = useState("");
  const [apiCanCalled, setApiCanCalled] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [confirm1000, setConfirm1000] = useState(false);
  const dataSize = 10;
  const pageSize = 5;
  const searchInput = useRef(null);

  // Calculate the index range of jobs to display on the current page
  // const indexOfLastJob = currentPage * dataSize;
  // const indexOfFirstJob = indexOfLastJob - dataSize;
  const startIndex = (currentPage - 1) * dataSize;
  const endIndex = startIndex + dataSize;

  const currentData = tableData.slice(startIndex, endIndex);
  // const currentData = tableData.slice(indexOfFirstJob, indexOfLastJob);

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const closeModal = () => {
    props.setShowModal(false);
  };

  const handleCancel = () => {
    closeModal();
  };

  const handleDone = (e) => {
    e.preventDefault();
    // if (selectCandAll) {
    //   props.setContactSelected(tableData);
    // } else {
    const selectedContacts = tableData.filter(
      (item) => item.isChecked === true
    );
    props.setContactSelected(selectedContacts);
    // }
    props.setShowModal(false);
  };
  const handleCheckBoxClear = (e) => {
    e.preventDefault();
    setTableData([]);
    setPaginationArray([]);
    setApiCanCalled(false);
    setTotalRecipients(0);
    setSelectedCount(0);
  };

  const handleCheckBox = (e, item, index) => {
    let d = tableData.map((x, i) => {
      return item.email === x.email ? { ...x, isChecked: !x.isChecked } : x;
    });

    const selectedContacts = d.filter((item) => item.isChecked === true);
    // const uncheckedStatus = d.some((item) => item.isChecked === false);
    // if (uncheckedStatus) {
    //   setSelectCandAll(false);
    // } else {
    //   setSelectCandAll(true);
    // }
    setTableData(d);
    setSelectedCount(selectedContacts.length);
    props.setContactSelected(selectedContacts);
  };

  const handleAllRecipients = async (e) => {
    e.preventDefault();

    try {
      let data = {};

      data = {
        createdDateFrom: candFilter.createdDateFrom || null,
        createdDateTo: candFilter.createdDateTo || null,
        firstName: candFilter.firstName,
        lastName: candFilter.lastName,
        skills: candFilter.skills,
        city: candFilter.city,
        email: candFilter.email,
        country: candFilter.country,
        experience: candFilter.experience,
        userType: props.filterType,
        jobId: selectedJob.jobId,
        selectAll: true,
      };
      setIsLoading(true);
      setConfirm1000(false);
      const response = await post("/smsbroadcast/fetchRecipientsForSms", data);
      if (response.status === 200) {
        if (response?.data?.result?.length > 0) {
          let data2 = response.data.result.map((item, index) => {
            return {
              ...item,
              isChecked: index < 100 ? true : false,
            };
          });

          let selectedItems = data2.filter((item) => item.isChecked === true);

          setTotalRecipients(response.data.total);
          setTableData(data2);
          setSelectedCount(selectedItems.length);
          setIsLoading(false);
          setApiCanCalled(true);
          setNoData(false);
          setSelectCandAll(true);
          setSelectAllChecked(true);
        } else {
          setTotalRecipients(response.data.total);
          setTableData(response.data.result);
          setApiCanCalled(true);
          setIsLoading(false);
          setNoData(true);
          setSelectCandAll(true);
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const validationFunction = () => {
    let isValid = true;
    if (
      candFilter.createdDateFrom === "" &&
      candFilter.createdDateTo === "" &&
      candFilter.firstName === "" &&
      candFilter.lastName === "" &&
      candFilter.email === "" &&
      candFilter.skills.length === 0 &&
      candFilter.city === "" &&
      candFilter.country === "" &&
      candFilter.experience === ""
    ) {
      isValid = false;
      toast.error("Select at least one filter");
    }
    if (candFilter.createdDateFrom !== "" && candFilter.createdDateTo === "") {
      isValid = false;
      toast.error("Select the To Date");
    }
    if (candFilter.createdDateFrom === "" && candFilter.createdDateTo !== "") {
      isValid = false;
      toast.error("Select the From Date");
    }
    if (
      (candFilter.createdDateFrom === "" && candFilter.createdDateTo !== "") ||
      (candFilter.createdDateFrom !== "" && candFilter.createdDateTo === "")
    ) {
      isValid = false;
      toast.error(
        "Please provide both From and To dates for created date range."
      );
    } else if (
      candFilter.createdDateFrom !== "" &&
      candFilter.createdDateTo !== "" &&
      candFilter.createdDateFrom > candFilter.createdDateTo
    ) {
      isValid = false;
      toast.error("Created from date cannot be greater than Created to date.");
    }
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validationFunction();
    try {
      if (isValid) {
        setTotalRecipients(0);
        let data = {};

        data = {
          createdDateFrom:
            candFilter.createdDateFrom === ""
              ? null
              : candFilter.createdDateFrom,
          createdDateTo:
            candFilter.createdDateTo === "" ? null : candFilter.createdDateTo,
          firstName: candFilter.firstName,
          lastName: candFilter.lastName,
          skills: candFilter.skills,
          city: candFilter.city,
          email: candFilter.email,
          country: candFilter.country,
          experience: candFilter.experience,
          userType: props.filterType,
          jobId: selectedJob.jobId,
          selectAll: true,
          // pageNumber: 2,
          // pageSize: 10,
        };
        setIsLoading(true);
        setTableData([]);
        const response = await post(
          "/smsbroadcast/fetchRecipientsForSms",
          data
        );
        if (response.status === 200) {
          if (response?.data?.result?.length > 0) {
            setTotalRecipients(response.data.total);
            setTableData(response.data.result);
            setIsLoading(false);
            setApiCanCalled(true);
            setNoData(false);
            setSelectCandAll(false);
          } else {
            setTotalRecipients(response.data.total);
            setTableData(response.data.result);
            setApiCanCalled(true);
            setIsLoading(false);
            setNoData(true);
            setSelectCandAll(false);
          }
        }
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const handleExperienceChange = (e) => {
    setCandFilter({
      ...candFilter,
      experience: e.target.value,
    });
  };
  const handleChangePrimarySkills = (s) => {
    setCandFilter({
      ...candFilter,
      skills: s,
    });
  };
  const handleChangeInputPrimarySkills = (skill) => {
    setPrimarySkillInput(skill);
  };
  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.value = location;
    }
  }, [location]);

  const setLocations = (address) => {
    setCandFilter({
      ...candFilter,
      city: address.city,
      country: address.country,
    });
    setLocation(address.city + (address.city ? ", " : "") + address.country);
  };
  const onSkillClick = (e, val) => {
    setTableData(
      tableData.map((item) => {
        if (item?.email === val?.email) {
          item.ViewAllSkill = !item.ViewAllSkill;
        }
        return item;
      })
    );
  };

  const handleClearFilter = () => {
    let temp = { ...candFilter };
    temp["createdDateFrom"] = "";
    temp["createdDateTo"] = "";
    temp["firstName"] = "";
    temp["lastName"] = "";
    temp["skills"] = [];
    temp["city"] = "";
    temp["email"] = "";
    temp["country"] = "";
    temp["experience"] = "";
    setCandFilter(temp);
  };

  return (
    <>
      {isLoading && <Loader />}

      <Modal
        size={"xl"}
        fullscreen={"xl-down"}
        show={props.showModal}
        onHide={closeModal}
      >
        <div
          class="bl-modal-header"
          id="recipientsModal"
          style={{ fontSize: "14px" }}
          backdrop="static"
          keyboard={false}
        >
          <h6 class="bl-modal-title">Select Candidate</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={closeModal}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body className="py-1 px-3">
          <Form onSubmit={handleSubmit}>
            <Row>
              <Row>
                <Col md={3}>
                  <Form.Group controlId="formCreatedDateFrom">
                    <Form.Label>Created From</Form.Label>
                    <Form.Control
                      type="date"
                      value={candFilter.createdDateFrom}
                      style={{
                        height: "43px",
                        fontSize: "14px",
                      }}
                      onChange={(e) =>
                        setCandFilter({
                          ...candFilter,
                          createdDateFrom: e.target.value,
                        })
                      }
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formCreatedDateTo">
                    <Form.Label>Created To</Form.Label>
                    <Form.Control
                      type="date"
                      value={candFilter.createdDateTo}
                      style={{
                        height: "43px",
                        fontSize: "14px",
                      }}
                      onChange={(e) =>
                        setCandFilter({
                          ...candFilter,
                          createdDateTo: e.target.value,
                        })
                      }
                      max={new Date().toISOString().split("T")[0]}
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <p>Or</p>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formFirstName">
                    <Form.Label>Candidate Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      style={{
                        height: "43px",
                        fontSize: "14px",
                      }}
                      value={candFilter.firstName}
                      onChange={(e) =>
                        setCandFilter({
                          ...candFilter,
                          firstName: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <p>Or</p>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Email"
                      value={candFilter.email}
                      style={{
                        height: "43px",
                        fontSize: "14px",
                      }}
                      onChange={(e) =>
                        setCandFilter({
                          ...candFilter,
                          email: e.target.value,
                        })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col
                  md={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <p>Or</p>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formExperience">
                    <Form.Label>Experience</Form.Label>

                    <select
                      className="form-control small font-14 mr-2"
                      id="experience"
                      value={candFilter.experience}
                      onChange={(e) => handleExperienceChange(e)}
                      style={{
                        height: "43px",
                        fontSize: "14px",
                        padding: "2px",
                        paddingLeft: "15px",
                      }}
                    >
                      <option value="">Select Experience</option>
                      <option value="Intern">Intern</option>
                      <option value="Less than one year">
                        Less than one year
                      </option>
                      <option value="1-3 years">1-3 years</option>
                      <option value="3-5 years">3-5 years</option>
                      <option value="5-10 years">5-10 years</option>
                      <option value="10+ years">10+ years</option>
                    </select>
                  </Form.Group>
                </Col>
                <Col
                  md={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <p>Or</p>
                </Col>
                <Col md={3}>
                  <Form.Label>Skills</Form.Label>
                  <SkillsSearchable
                    onChange={handleChangePrimarySkills}
                    inputArr={candFilter.skills}
                    skillinput={primarySkillInput}
                    onChangeInput={handleChangeInputPrimarySkills}
                    inputProps={{
                      placeholder: "Enter skills",
                    }}
                    isFrom="addCandidate"
                  />
                </Col>
                <Col
                  md={1}
                  className="d-flex justify-content-center align-items-center"
                >
                  <p>Or</p>
                </Col>
                <Col md={3}>
                  <Form.Group controlId="formCountry">
                    <Form.Label>Location</Form.Label>
                    <div
                      className="d-flex"
                      style={{
                        height: "43px",
                        fontSize: "14px",
                      }}
                    >
                      <GoogleLocationCountryAPI
                        setLocation={setLocations}
                        searchInput={searchInput}
                        onClear={() => {
                          setCandFilter({
                            ...candFilter,
                            city: "",
                            country: "",
                          });
                          setLocation("");
                        }}
                        className="form-control"
                      />
                    </div>
                  </Form.Group>
                </Col>

                <Col className="d-flex justify-content-end pt-5 pb-5">
                  <button
                    type="button"
                    className="btn-outline-primary py-1 mx-3"
                    onClick={() => {
                      handleClearFilter();
                    }}
                  >
                    Clear
                  </button>

                  <Button variant="primary" type="submit">
                    Search
                  </Button>
                </Col>
              </Row>
            </Row>
            <Row>
              {totalRecipients && totalRecipients > 1 ? (
                <>
                  <p>Total Recipients: {totalRecipients}</p>
                </>
              ) : null}
              <>
                {totalRecipients && totalRecipients > 1 ? (
                  <button
                    type="button"
                    className="mb-4"
                    style={{
                      width: "200px",
                      marginLeft: "15px",
                      marginBottom: "15px",
                    }}
                    onClick={(e) => {
                      totalRecipients > 100
                        ? setConfirm1000(true)
                        : handleAllRecipients(e);
                    }}

                    // onClick={handleAllRecipients}
                  >
                    Select all Recipients
                  </button>
                ) : null}
                {tableData.length > 0 && (
                  <p>
                    {selectedCount > 1
                      ? `Selected Recipients: ${selectedCount}`
                      : `Selected Recipient: ${selectedCount}`}
                  </p>
                )}
              </>
            </Row>
          </Form>

          <div style={{ maxHeight: "450px", overflow: "scroll" }}>
            <Table bordered hover responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>First name</th>
                  <th>Last name</th>
                  <th style={{ width: "15%" }}>Contact number</th>
                  <th style={{ width: "25%" }}>Email</th>
                  <th>Skills</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody style={{ maxHeight: "300px", overflow: "scroll" }}>
                {!selectCandAll && currentData && currentData?.length === 0 ? (
                  noData && apiCanCalled ? (
                    <tr className="text-center">
                      <td colSpan="7">
                        <h6>No records found</h6>
                      </td>
                    </tr>
                  ) : (
                    <tr className="text-center">
                      <td colSpan="7">
                        <h6>Select any filters & Search.</h6>
                      </td>
                    </tr>
                  )
                ) : (
                  !selectCandAll &&
                  currentData &&
                  currentData?.map((item, index) => (
                    <tr key={index}>
                      <td
                      // className="d-flex justify-content-center align-content-center"
                      // height={"auto"}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{
                            width: "20px",
                            height: "20px",
                            boxShadow: "none",
                          }}
                          checked={item?.isChecked}
                          onChange={(e) => handleCheckBox(e, item, index)}
                        />
                      </td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td style={{ width: "15%" }}>
                        {item?.homePhoneCode !== null || ""
                          ? item?.homePhoneCode + " " + item?.homePhone
                          : item?.homePhone}
                      </td>
                      <td style={{ width: "25%" }}>{item.email}</td>
                      <td>
                        {!item.primarySkills && (
                          <span className="mb-1 mr-1">NA</span>
                        )}
                        {item.primarySkills &&
                          JSON.parse(item.primarySkills)
                            .slice(0, 2)
                            .map((item) => (
                              <span className="tag tag-blue3 mb-1 mr-1">
                                {item}
                              </span>
                            ))}
                        {JSON.parse(item.primarySkills)?.length > 2 &&
                          item.ViewAllSkill === true &&
                          JSON.parse(item.primarySkills)
                            .slice(2, JSON.parse(item.primarySkills)?.length)
                            .map((item) => (
                              <>
                                <span class="tag tag-blue3 mb-1 mr-1">
                                  {item}
                                </span>
                              </>
                            ))}
                        {JSON.parse(item.primarySkills)?.length > 2 && (
                          <button
                            onClick={(e) => onSkillClick(e, item)}
                            type="button"
                            class="btn btn-text"
                          >
                            {!item.ViewAllSkill ? (
                              <span>View all skills</span>
                            ) : (
                              <span>Show Less</span>
                            )}
                          </button>
                        )}
                      </td>
                      <td>
                        {item.city && item.country
                          ? item?.city + "," + item?.country
                          : "NA"}
                      </td>
                    </tr>
                  ))
                )}
                {selectCandAll && tableData && tableData?.length === 0 ? (
                  noData && apiCanCalled ? (
                    <tr className="text-center">
                      <td colSpan="7">
                        <h6>No records found</h6>
                      </td>
                    </tr>
                  ) : (
                    <tr className="text-center">
                      <td colSpan="7">
                        <h6>Select any filters & Search.</h6>
                      </td>
                    </tr>
                  )
                ) : (
                  selectCandAll &&
                  tableData &&
                  tableData?.map((item, index) => (
                    <tr key={index}>
                      <td
                      // className="d-flex justify-content-center align-content-center"
                      // height={"auto"}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          style={{
                            width: "20px",
                            height: "20px",
                            boxShadow: "none",
                          }}
                          checked={item?.isChecked}
                          onChange={(e) => handleCheckBox(e, item, index)}
                        />
                      </td>
                      <td>{item.firstName}</td>
                      <td>{item.lastName}</td>
                      <td style={{ width: "15%" }}>
                        {item?.homePhoneCode !== null || ""
                          ? item?.homePhoneCode + " " + item?.homePhone
                          : item?.homePhone}
                      </td>
                      <td style={{ width: "25%" }}>{item.email}</td>
                      <td>
                        {!item.primarySkills && (
                          <span className="mb-1 mr-1">NA</span>
                        )}
                        {item.primarySkills &&
                          JSON.parse(item.primarySkills)
                            .slice(0, 2)
                            .map((item) => (
                              <span className="tag tag-blue3 mb-1 mr-1">
                                {item}
                              </span>
                            ))}
                        {JSON.parse(item.primarySkills)?.length > 2 &&
                          item.ViewAllSkill === true &&
                          JSON.parse(item.primarySkills)
                            .slice(2, JSON.parse(item.primarySkills)?.length)
                            .map((item) => (
                              <>
                                <span class="tag tag-blue3 mb-1 mr-1">
                                  {item}
                                </span>
                              </>
                            ))}
                        {JSON.parse(item.primarySkills)?.length > 2 && (
                          <button
                            onClick={(e) => onSkillClick(e, item)}
                            type="button"
                            class="btn btn-text"
                          >
                            {!item.ViewAllSkill ? (
                              <span>View all skills</span>
                            ) : (
                              <span>Show Less</span>
                            )}
                          </button>
                        )}
                      </td>
                      <td>
                        {item.city && item.country
                          ? item?.city + "," + item?.country
                          : "NA"}
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
            {/* {!selectCandAll && tableData.length > 0 ? (
              <Pagination
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                setPaginationArray={setPaginationArray}
                data={tableData}
                dataSize={dataSize}
                getTab=""
                pageSize={pageSize}
              />
            ) : null}  */}
            {!selectCandAll &&
              currentData?.length > 0 &&
              currentData.length !== totalRecipients && (
                <div className="d-flex justify-content-between">
                  <div className="mt-3 px-2">
                    Showing{" "}
                    {Math.min((currentPage - 1) * 10 + 1, totalRecipients)} -{" "}
                    {Math.min(currentPage * 10, totalRecipients)} of{" "}
                    {totalRecipients}
                  </div>
                  <div className="mt-3 px-2">
                    <Pagination
                      currentPage={currentPage}
                      totalSize={totalRecipients}
                      sizePerPage={10}
                      firstPageText="|⟨"
                      lastPageText="⟩|"
                      showFirstLastPages={true}
                      changeCurrentPage={handlePageChange}
                      theme="border-bottom"
                    />
                  </div>
                </div>
              )}
          </div>
          <hr />
          <div className="ml-4 mb-5">
            <div className="d-flex justify-content-end mt-3">
              <button
                type="button"
                className="btn-outline-primary py-1 mx-2"
                onClick={handleCancel}
              >
                Cancel
              </button>
              <button
                type="button mr-2"
                className="btn-outline-primary py-1 mx-2"
                onClick={handleCheckBoxClear}
              >
                Clear
              </button>
              <Button
                variant="primary"
                type="button"
                className="mr-2 mx-1"
                disabled={selectedCount === 0}
                onClick={handleDone}
              >
                Done
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {confirm1000 && (
        <Modal show={true} size="md" animation={true}>
          <Modal.Header>
            <button
              type="button"
              onClick={(e) => {
                setConfirm1000(false);
                handleAllRecipients(e);
              }}
              className="close p-0 bl-modal-close-btn closable"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i className="fal fa-times closable"></i>
            </button>
          </Modal.Header>

          <Modal.Body>
            <div className="m-5">
              <div class="text-center">
                <div class="avatar avatar-lg avatar-red2">
                  <i
                    class="fas fa-exclamation-triangle fa-fw"
                    aria-hidden="true"
                  ></i>
                </div>
                <p class="text-center lead mt-3">
                  Broadcast Limitation: Maximum 100 candidates per SMS.
                </p>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div class="d-flex align-items-center justify-content-center">
              <Button variant="secondary" onClick={() => setConfirm1000(false)}>
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={handleAllRecipients}
                className="ml-2"
              >
                Proceed
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default AddCandContactsModal;
