export const sourceTalentSuccess = (candidate, payrateData, jobData) => {
    return (
        `<div class="mb-2">
            <label class="d-block font-bold pt-0">Position Title</label>
            <div><a href="/high5Hire/viewjobdetails/${jobData.jobId}">${jobData.fullText.jobTitle}</a></div>
        </div>
        <div class="mb-2">
            <label class="d-block font-bold pt-0">Position ID</label>
            <div><a href="/high5Hire/viewjobdetails/${jobData.jobId}">${jobData.jobId}</a></div>
        </div>
        <div class="mb-2">
            <label class="d-block font-bold pt-0">Talent Name</label>
            <div><a href="#">${candidate.fullText.firstName} ${candidate.fullText.middleName} ${candidate.fullText.lastName}</a></div>
            <p></p>
        </div>
        <div class="mb-2">
            <label class="d-block font-bold pt-0">Rate</label>
            <p>${payrateData.payrate} ${payrateData.payrateCurrency}</p>
        </div>
        <div class="mb-2">
            <label class="d-block font-bold pt-0">Date Submitted</label>
            <p>${new Date().toString()}</p>
        </div>`
    )
}

export const sourceTalentBulkSuccess = (candidateList, payrateData, jobData) => {
    let tableData = ''
    candidateList.map((candidate) => (
        tableData += `<td>${candidate.fullText.firstName} ${candidate.fullText.middleName} ${candidate.fullText.lastName}</td>
                      <td>${candidate.designation}</td>`
    ))
    return (
        `<div class="mb-2">
            <label class="d-block font-bold pt-0">Position Title</label>
            <div><a href="/high5Hire/viewjobdetails/${jobData.jobId}">${jobData.fullText.jobTitle}</a></div>
        </div>
        <div class="mb-2">
            <label class="d-block font-bold pt-0">Position ID</label>
            <div><a href="/high5Hire/viewjobdetails/${jobData.jobId}">${jobData.jobId}</a></div>
        </div>
        <div class="mb-2">
            <label class="d-block font-bold pt-0">Rate</label>
            <p>${payrateData.payrate} ${payrateData.payrateCurrency}</p>
        </div>
        <div class="mb-2">
            <label class="d-block font-bold pt-0">Date Submitted</label>
            <p>${new Date().toString()}</p>
        </div>
        <div class="mb-2">
            <label class="d-block font-bold pt-0">Submitted Talent</label>
            <div class="card card-flat">
                <div class="card-body">
                    <table class="table">
                        <thead>
                            <tr>
                                <th>Talent Name</th>
                                <th>Title</th>
                            </tr>
                        </thead>
                        <tbody>
                            ${ tableData }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        `
    )
}