import React, { useEffect, useState } from "react";
import AutoSuggest from "react-autosuggest";
import { post } from "../../../../../service/ApiService";
import Loader from "../../../commoncomponents/Loader/Loader";
import orImage from "../../../../../assets/images/orIcon.svg";

const Skills = ({
  autocompleteRenderInput,
  addJobData,
  addJobVal,
  setAddJobData,
  setPrimarySkillInput,
  primarySkillinput,
  edit,
  inputList,
  setInputList,
  skills,
}) => {
  const [value, setValue] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const delay = 500; // Adjust this value to set the debounce time in milliseconds
    const debounceTimer = setTimeout(() => {
      if (value?.length > 1) fetchSuggestions(value);
    }, delay);

    // Cleanup the previous timer on each render to avoid multiple requests
    return () => clearTimeout(debounceTimer);
  }, [value]);

  const fetchSuggestions = async (value) => {
    setLoading(true);
    try {
      await post("/searchSkills/search", { text: value.toUpperCase() }).then(
        (data) => {
          setSuggestions(data.data);
          setLoading(false);
        }
      );
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const handleInputChange = (e, i, j) => {
    let newArray = [...inputList];
    newArray[i][j] = e;
    setInputList(newArray);
  };

  const handleRemoveClick = (i, j) => {
    if (inputList.length === 1 && inputList[0].length === 1) {
      return; // Prevent removing the last input box
    }

    let newArray = [...inputList];
    newArray[i].splice(j, 1);
    setInputList(newArray);

    // Remove the entire card if the sub-array's length becomes 0
    if (newArray[i].length === 0) {
      newArray.splice(i, 1);
      setInputList(newArray);
    }
  };

  const handleAddClick = (i) => {
    let newArray = [...inputList];
    newArray[i].push("");
    setInputList(newArray);
  };

  const handleSubArrayAddClick = () => {
    setInputList([...inputList, [""]]);
  };
  const getSuggestions = (value) => {
    const filteredSuggestions = suggestions.filter(
      (suggestion) => !inputList.flat().includes(suggestion)
    );
    return filteredSuggestions;
  };

  return (
    <>
      {loading && <Loader />}
      {inputList.map((arr, i) => (
        <div className="card card-flat bg-gray4 m-3" key={i}>
          <div className="card-body py-0">
            <div
              className="form-floating mb-2"
              style={{ zIndex: `${i + 1}*999` }}
            >
              <div className="">
                {i > 0 && (
                  <span style={{ color: "#4b9ae6", fontWeight: "900" }}>
                    AND
                    <br />
                  </span>
                )}

                <label className="mb-1">Skills*</label>
                <div className="row mb-2">
                  <div className="col-10">
                    {arr.map((value, j) => (
                      <div key={j} className="d-flex align-items-center m-1">
                        {/* <input
                          type="text"
                          name={`skill-${i}-${j}`}
                          className="form-control me-2"
                          placeholder="Skill"
                          value={value}
                          onChange={(e) => handleInputChange(e, i, j)}
                        /> */}
                        {j > 0 && (
                          <img
                            src={orImage}
                            style={{ height: "15px", marginLeft: "4px" }}
                          ></img>
                        )}
                        <div>
                          <AutoSuggest
                            suggestions={getSuggestions(value)}
                            onSuggestionsClearRequested={() =>
                              setSuggestions([])
                            }
                            onSuggestionsFetchRequested={({ value }) => {
                              setValue(value);
                              setSuggestions(getSuggestions(value));
                            }}
                            onSuggestionSelected={(_, { suggestionValue }) =>
                              console.log("Selected: " + suggestionValue)
                            }
                            getSuggestionValue={(suggestion) => suggestion}
                            renderSuggestion={(suggestion) => (
                              <span>{suggestion}</span>
                            )}
                            inputProps={{
                              placeholder: "Type Skill",
                              value: value,
                              onChange: (_, { newValue }) =>
                                handleInputChange(newValue, i, j),
                            }}
                            highlightFirstSuggestion={true}
                          />
                        </div>

                        {inputList.length > 1 || arr.length > 1 ? ( // Hide delete icon if it's the only input box
                          <i
                            onClick={() => handleRemoveClick(i, j)}
                            style={{
                              marginLeft: "10px",
                              color: "red",
                              zIndex: "999999",
                            }}
                            className="fal fa-fw fa-trash-alt"
                            aria-hidden="true"
                          ></i>
                        ) : null}
                      </div>
                    ))}
                  </div>
                  <div className="col-2">
                    <button
                      className="btn btn-sm btn-success ms-2"
                      onClick={() => handleAddClick(i)}
                      disabled={arr.some((value) => value.trim() === "")}
                    >
                      OR
                    </button>
                  </div>
                </div>

                <div className=" mb-2">
                  {i === inputList.length - 1 && (
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={handleSubArrayAddClick}
                      disabled={arr.every((value) => value.trim() === "")}
                    >
                      Add Skill
                    </button>
                  )}
                </div>
              </div>
              {addJobVal?.primary && (
                <small className="validation ml-2">{addJobVal?.primary}</small>
              )}
            </div>
          </div>
        </div>
      ))}

      {/* <div>{inputList?.map(e=>e)}</div> */}
    </>
  );
};

export default Skills;
