import React, { useState, useEffect } from "react";
import Loader from "../../Loader/Loader";
import moment from "moment";

function TalentSummaryCard(props) {
  const [talentPercentage, setTalentPercentage] = useState(false);

  useEffect(() => {
    CalculateTalentPercent();
  }, [props]);

  //today's date
  let todayDate = moment(new Date()).format("YYYY-MM-DD");
  //Last Month Date
  let lastMonthDate = moment(
    new Date(new Date().setDate(new Date().getDate() - 30))
  ).format("YYYY-MM-DD");

  const CalculateTalentPercent = () => {
    //Find talent created between last month and today's date
    let talentInLastMonth = props.talentData?.filter(
      (talent) =>
        moment(talent?.createdDateTime).format("YYYY-MM-DD") > lastMonthDate &&
        moment(talent?.createdDateTime).format("YYYY-MM-DD") <= todayDate
    );
    let percentage = 0;
    if (props.talentData?.length !== 0 && talentInLastMonth?.length !== 0) {
      percentage = Math.round(
        (talentInLastMonth?.length / props.talentData?.length) * 100
      );
    } else {
      percentage = 0;
    }
    setTalentPercentage(percentage);
  };

  return (
    <>
      <div className="row">
        <div className="col-6">
          <div className="w-100 d-flex align-items-center">
            <div>
              <h2 className="font-light mb-0">{props.talentData?.length}</h2>
              <p className="mb-0">
                Talent Count{" "}
                <span
                  className=""
                  data-bs-toggle="tooltip"
                  data-bs-html="true"
                  title="No. of all candidates added to the talent community in H5."
                >
                  <i class="fal fa-info-circle"></i>
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="col-6">
          {talentPercentage > 0 && (
            <div className="row">
              <div className="col-12">
                <div className="w-70 text-right font-green">
                  <h2 className="mb-1 ml-5 text-left">
                    <i className="fad fa-sm fa-arrow-up" />
                  </h2>
                  <p className="mb-0" style={{ width: "120px" }}>
                    <small>{talentPercentage}% in last 30 days</small>
                  </p>
                </div>
              </div>
            </div>
          )}
          {talentPercentage === 0 && (
            <div className="row">
              <div className="col-12">
                <div className="w-70 text-center font-red mb-1">
                  <p className="mb-0" style={{ width: "120px" }}>
                    <small>No change in last 30 days</small>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default TalentSummaryCard;
