import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { IoLocationOutline } from "react-icons/io5";
import { IoGridOutline } from "react-icons/io5";
import { AiOutlineFolder } from "react-icons/ai";

const Landing = ({ job, category }) => {
  const getCategoryClass = () => {
    return `landingSec_${category.split(" ")[0]}`;
  };

  return (
    <div
      className={` ${getCategoryClass()} container-fluid p-0 d-flex justify-content-center align-items-center`}
    >
      <Row
        className={`landingContent text-center d-flex justify-content-center align-items-center`}
      >
        <Col md={12}>
          <h2>{job.jobTitle}</h2>
          <p style={{ fontSize: "16px" }}>
            <>
              <IoLocationOutline color="white" size={20} />
              {job.city || job.country ? (
                <strong className="m-2">{`${job.city}${
                  job.city && job.country ? ", " : ""
                } ${job.country}`}</strong>
              ) : (
                <strong className="m-2">Remote</strong>
              )}
            </>{" "}
            <IoGridOutline color="white" size={16} />
            {job.industries && JSON.parse(job.industries).length > 0 && (
              <strong className="m-2">
                {JSON.parse(job.industries).join(", ")}
              </strong>
            )}
            <AiOutlineFolder color="white" size={20} />
            <strong className="m-2">{job.jobType}</strong>
          </p>
          {/* <p style={{ fontSize: "14px" }}>
            Discover yours with a career at High5Hire. Sign-up for our talent
            community and get updates on job opportunities, news, and recruiting
            events
          </p> */}
        </Col>
      </Row>
    </div>
  );
};

// Export your component
export default Landing;
