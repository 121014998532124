import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import ReactQuill from "react-quill";
import { post } from "../../../../service/ApiService";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";

const EmailModal = ({ showjobModal, setShowJobModal, selectedTalents ,setSelectedTalents}) => {
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState("");
  const [emailContent, setEmailContent] = useState({
    subject: "Sample Mail",
    body: `<p>Hi [First name],</p>
           <p>This is a sample email.</p>
           <p>You can customize this part to include your specific message.</p>`,
    footer: `<p>Regards,</p>
             <p>The Team High5</p>
           `,
  });
  const [smsContent, setSmsContent] = useState(`Hello [Recipient],
This is a sample SMS message. You can customize this part to include your specific message.
Best regards, 
[Your Name]`);

  const handleEmailContentChange = (newContent, key) => {
    if (emailContent[key] !== newContent) {
      setEmailContent({ ...emailContent, [key]: newContent });
    }
  };

  const sendMail = async () => {
    try {
      setLoading(true);
      let payload = {
        mailTo: selectedTalents.map((t) => ({ email: t.email, newSletter: t.newSletter })),
        mailSubject: emailContent.subject,
        mailContent: emailContent.body + emailContent.footer,
      };      
      await post("/emailtemplate/send_mail", payload).then((res) => {
        toast.success("Email sent Successfully.");
        setSelectedTalents([]);
        setShowJobModal(false);
        setLoading(false);
        
      });
    } catch (error) {
      setLoading(false);
    }
  };
  const sendSMS = async () => {
    console.log(selectedTalents);
    try {
      setLoading(true);
      const payload = [
        {
          recieverNumber: selectedTalents
            .filter((t) => t.homePhoneCode === "+1" && t.newSletter !== "Unsubscribed")
            .map((t) => t.homePhoneCode + t.homePhone),
          message: smsContent,
        },
      ];
      await post("/bulkTalentAction/bulkSms", payload).then((res) => {
        toast.success("SMS sent Successfully.");
        setSelectedTalents([]);
        setLoading(false);
        setShowJobModal(false);
        
      });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleBulkCommunication = () => {
    // Logic to handle sending email or SMS
    method === "email" ? sendMail() : sendSMS();
    console.log(emailContent, smsContent, selectedTalents);
  };

  return (
    <Modal show={showjobModal} size="lg">
      <div className="modal-header" id="NewCandidateModal">
        <h6>{!method ? "Email/SMS" : method === "email" ? "Email" : "SMS"}</h6>
        {showjobModal && (
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowJobModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
      <Modal.Body className="p-4">
        {loading && <Loader />}
        {!method && (
          <div className="">
            <div className="container ">
              <div className="row">
              <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">Email</h5>
                      <p className="card-text">
                        Choose Email as your communication method.
                      </p>
                      <button
                        onClick={() => setMethod("email")}
                        className="btn btn-primary"
                      >
                        Select Email
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <h5 className="card-title">SMS</h5>
                      <p className="card-text">
                        Choose SMS as your communication method.
                      </p>
                      <button
                        onClick={() => setMethod("sms")}
                        className="btn btn-primary"
                      >
                        Select SMS
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {method === "email" && (
          <div className="card-flat">
            <>
              <div className="mb-3">
                <label htmlFor="editSubject" className="form-label">
                  Subject
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="editSubject"
                  style={{
                    borderRadius: 0,
                    fontSize: "0.9em",
                  }}
                  value={emailContent.subject}
                  onChange={(e) =>
                    handleEmailContentChange(e.target.value, "subject")
                  }
                />
              </div>
              <div className="mb-3">
                <label htmlFor="editContent" className="form-label">
                  Content
                </label>
                <ReactQuill
                  theme="snow"
                  value={emailContent.body}
                  onChange={(value) => handleEmailContentChange(value, "body")}
                />
              </div>
              <div className="mb-3">
                <label htmlFor="editFooter" className="form-label">
                  Footer
                </label>
                <ReactQuill
                  theme="snow"
                  value={emailContent.footer}
                  onChange={(value) =>
                    handleEmailContentChange(value, "footer")
                  }
                />
              </div>
            </>
          </div>
        )}
        {method === "sms" && (
          <div className="card-flat">
            <div className="bg-white">
              {/* <ReactQuill
                theme="snow"
                value={smsContent}
                onChange={setSmsContent}
              /> */}
              <textarea
                className="form-control"
                value={smsContent}
                onChange={(e) => setSmsContent(e.target.value)}
                rows={4}
              />
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => setShowJobModal(false)}
        >
          Cancel{" "}
        </button>
        <button onClick={handleBulkCommunication}>Send</button>
      </Modal.Footer>
    </Modal>
  );
};
export default EmailModal;
