import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const GET_JOB_DETAILS_BEGIN = "GET_JOB_DETAILS_BEGIN";
export const GET_JOB_DETAILS_SUCCESS = "GET_JOB_DETAILS_SUCCESS";
export const GET_JOB_DETAILS_FAILURE = "GET_JOB_DETAILS_FAILURE";

export const getJobDetails = (payloadObj) => async (dispatch) => {
  try {
    dispatch({
      type: GET_JOB_DETAILS_BEGIN,
    });
    const res = await post("/job/getjobdetails", payloadObj);
    if (res.status === 200) {
      dispatch({
        type: GET_JOB_DETAILS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_JOB_DETAILS_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
