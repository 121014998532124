import { Fragment, useState, useEffect, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";
import { Provider } from "react-redux";
import { getUserName, isAuthenticatedUser } from "./service/AuthService";
import SystemMaintainance from "./components/components_v3/commoncomponents/error/SystemMaintainance";
import store from "./store";
import PublicRoutes from "./routes/PublicRoute";
import PrivateRoutes from "./routes/PrivateRoute";
import ReactGa from "react-ga";
import { createBrowserHistory } from "history";
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  ApolloProvider,
  InMemoryCache,
} from "@apollo/client";
import { getNotificationList } from "../src/actions/notification/notifications";
const httpLink = new HttpLink({ uri: "https://app.codesignal.com/graphql" });
const authLink = new ApolloLink((operation, forward) => {
  const token =
    "ZVJMM25vM0tEbVp1YkVhRHK8Ec03N4mEBEHcWY9PcgkZbMndMtq+w+PZl6E4zaTtmQ==";
  // Use the setContext method to set the HTTP headers
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  // Call the next link in the middleware chain.
  return forward(operation);
});
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
const authentication = () =>
  isAuthenticatedUser() ? <Redirect to="/high5Hire" /> : <PublicRoutes />;
// const authentication = () =>
//   isAuthenticatedUser() ? <SystemMaintainance /> : <SystemMaintainance />;

const customHistory = createBrowserHistory();
function initializeAnalytics() {
  ReactGa.initialize("UA-269504981-2", {
    debug: true,
    titleCase: false,
    gaOptions: {
      userId: 123234324234,
    },
  });
  ReactGa.pageview(window.location.pathname + window.location.search);
  customHistory.listen((location) => {
    const idVal = getUserName();
    ReactGa.set({ page: `userId:${idVal}/${location.pathname}` }); // Update the user's current page
    ReactGa.pageview(`userId:${idVal}/${location.pathname} hi22`); // Record a pageview for the given page
  });
}
initializeAnalytics();

function App() {
  const dispatch = useDispatch();
  const [isFetching, setIsFetching] = useState(false);
  const { notificationData, notificationLoading } = useSelector(
    (state) => ({
      notificationData: state.getNotificationsReducer.notificationData,
    }),
    shallowEqual
  );
  useEffect(() => {
    const fetchNotifications = async () => {
      if (!notificationData?.notificationPref || isFetching) {
        return;
      }
      try {
        setIsFetching(true);
        dispatch(getNotificationList());
      } catch (error) {
        console.log(error);
      } finally {
        setIsFetching(false);
      }
    };
    const unlisten = customHistory.listen(() => {
      // Call the API when the route changes
      fetchNotifications();
    });

    // Initial API call when the component mounts
    fetchNotifications();

    // Clean up the listener when the component unmounts
    return () => {
      unlisten();
    };
  }, [customHistory]);

  return (
    <ApolloProvider client={client}>
      <Router history={customHistory}>
        <Fragment>
          <ToastContainer position="top-center" autoClose={"5000"} />
          <Switch>
            <Route path="/high5Hire" component={PrivateRoutes} />
            <Route path="" render={authentication} />
          </Switch>
        </Fragment>
      </Router>
    </ApolloProvider>
  );
}

export default App;
