import React from "react";

const DocumentDetails = (props) => {
  const { jobData } = props;
  return (
    <div className="row mb-2">
      <div className="col-lg-12">
        {
          <div className="mb-2">
            <div className="mb-2">
              <div>
                <div className="mt-1">
                  <span
                    className="underlined ml-4"
                    title="View Document"
                    style={{ color: "gray" }}
                  >
                    {jobData.documentName}
                    <a
                      href="/"
                      onClick={(e) => {
                        e.preventDefault();
                        window.open(jobData.filePath, "_blank");
                      }}
                    >
                      <i
                        className="fas fa-external-link-square ml-1"
                        style={{ fontSize: "small", color: "#FF5F57" }}
                      />
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default DocumentDetails;
