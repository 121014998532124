import ReactGa from "react-ga";

/**
 * Event - Add custom tracking event.

 * @param {string} category

 * @param {string} action

 * @param {string} label

 */

const Event = (category, action, label) => {

  console.log("//////15",category, action, label)
  
  ReactGa.event({
    category,
    action,
    label
  });
};

export default Event;
