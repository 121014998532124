import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { Container, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { post, get } from "../../service/ApiService";
import Loader from "../Loader/Loader";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import { isValidUrl, isValidFname } from "../function/CommonFunction";
import sparkles from "../../assets/lottie/sparkles.json";
import checkMark from "../../assets/lottie/checkmark.json";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";
import Validator from "email-validator";
import GoogleLocationCityAPI from "../location/GoogleLocationCityAPI";
import { skillOptions } from "../../constants/Constants";
import { getDropdownValues } from "../../actions/common/GetDropdownValues";
import AddJobNewGoogleMaps from "../location/AddJobNewGoogleMaps";

const { Anime } = ReactAnime;

const RecruiterMarketPlace = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const searchInput = useRef(null);
  const [loop] = useState(false);
  const [check, setCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [status, setStatus] = useState("");
  const [data, setData] = useState({
    phoneCode: "",
    yearsOfExperience: "",
    isreferred: "",
    jobTitle: { title1: "", title2: "", title3: "" },
    skills: [],
    firstName: "",
    lastName: "",
    email: "",
    linkedIn: "",
    phone: "",
    industries: [],
    subCategory: [],
    regions: [],
    languages: [],
    education: [],
    certification: [],
    companyName: "",
    recFirstName: "",
    recLastName: "",
    recEmail: "",
    recPhoneCode: "",
    recPhoneNumber: "",
    country: "",
    recCountry: "",
    location: {
      city: "",
      state: "",
      country: "",
    },
  });

  const [phoneValidation, setPhoneValidation] = useState(true);
  const [recPhoneValidation, setRecPhoneValidation] = useState(true);
  const [agree, setAgree] = useState(true);
  const [validation, setValidation] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    title: "",
    experience: "",
    phone: "",
    linkedIn: "",
    recEmail: "",
    recPhoneNumber: "",
    recFirstName: "",
    recLastName: "",
    city: "",
    state: "",
    country: "",
    experience: "",
    industry: "",
    subCategory: "",
    skill: "",
    region: "",
    language: "",
    education: "",
    certification: "",
  });
  const [success, setSuccess] = useState(false);
  const [skillsOptions, setSkillsOption] = useState(skillOptions);
  const [industriesOptions, setIndustriesOptions] = useState([]);
  const [industriesOptionsAll, setIndustriesOptionsAll] = useState([]);
  const [languageOptions, setLanguageOptions] = useState([]);
  const [regionOption, setRegionOption] = useState([]);
  const [experienceOptions, setExperienceOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCategoryOptionsAll, setSubCategoryOptionsAll] = useState([]);
  const [educationsOptions, setEducationsOption] = useState([]);
  const [certificationOptions, setCertificationOptions] = useState([]);

  const { dropdownLoading, dropdownData } = useSelector(
    (state) => ({
      dropdownLoading: state.dropdownValuesReducer.dropdownLoading,
      dropdownData: state.dropdownValuesReducer.dropdownData,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getDropdownValues());
  }, []);

  useEffect(() => {
    if (dropdownData.length > 0) {
      let industriesData = [];
      let regionData = [];
      let languageData = [];
      let experienceData = [];
      let educationData = [];
      let certificationData = [];
      dropdownData.forEach((element) => {
        if (element.typeName == "skillSet") {
          industriesData = [
            ...industriesData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "certification") {
          certificationData = [
            ...certificationData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "languages") {
          languageData = [
            ...languageData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "regions") {
          regionData = [
            ...regionData,
            {
              value: element.name,
              label: element.name,
              disabled: false,
            },
          ];
        }
        if (element.typeName == "experienceYear") {
          experienceData = [
            ...experienceData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "qualification") {
          educationData = [
            ...educationData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
        if (element.typeName == "certifications") {
          certificationData = [
            ...certificationData,
            {
              value: element.name,
              label: element.name,
            },
          ];
        }
      });
      setIndustriesOptionsAll([
        ...industriesData,
        {
          value: "All",
          label: "All",
        },
      ]);
      setIndustriesOptions([
        ...industriesData,
        {
          value: "All",
          label: "All",
        },
      ]);
      setLanguageOptions(languageData);
      setRegionOption(regionData);
      setExperienceOptions(experienceData);
      setEducationsOption(educationData);
      setCertificationOptions(certificationData);
    }
  }, [dropdownData]);

  const handleKeyDown = (event) => {
    // Prevent typing space in the Select input
    if (event.key === ' ') {
      event.preventDefault();
    }
  };
  const fetchStatus = async (email) => {
    try {
      setLoading(true);
      const res = await post("/gigrecruiter/fetchByEmail", { email });
      if (res.status === 200) {
        let { firstName, LastName, email, status } = res.data[0];
        setStatus(status);
        setData({
          ...data,
          createdby: res.data[0].createdby,
          firstName: firstName,
          lastName: LastName,
          email: email,
        });
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error("Some Error Occurred while Fetching Data");
    }
  };

  useEffect(() => {
    let email = window.location.pathname.split("/").reverse()[0];
    fetchStatus(email);
  }, []);

  const handleChange = (e) => {
    let id = e.target.id;
    if (id === "firstName" && validation.firstName) {
      setValidation({ ...validation, firstName: "" });
    }
    if (id === "lastName" && validation.lastName) {
      setValidation({ ...validation, lastName: "" });
    }
    if (id === "experience" && validation.experience) {
      setValidation({ ...validation, experience: "" });
    }
    if (id === "education" && validation.experience) {
      setValidation({ ...validation, experience: "" });
    }
    if (id === "industry" && validation.industry) {
      setValidation({ ...validation, industry: "" });
    }
    if (id === "skill" && validation.skill) {
      setValidation({ ...validation, skill: "" });
    }
    if (id === "region" && validation.region) {
      setValidation({ ...validation, region: "" });
    }
    if (id === "language" && validation.language) {
      setValidation({ ...validation, language: "" });
    }

    if (id === "recEmail") {
      if (validation.recEmail === "*Same Email Can not Be Added") {
        setValidation({ ...validation, recEmail: "" });
      }
      if (validation.recEmail === "*Invalid Email") {
        Validator.validate(e.target.value) &&
          setValidation({ ...validation, recEmail: "" });
      }
    }
    setData({ ...data, [id]: e.target.value });
  };

  const handleLocation = (e) => {
    let id = e.target.id;
    setData({
      ...data,
      location: {
        ...data.location,
        [id]: e.target.value,
      },
    });
  };

  const setLocation = (address, searchInput) => {
    const city = address.locality || address.city;
    setData({
      ...data,
      location: {
        ...data.location,
        city: city,
        state: address.state,
        country: address.country,
      },
    });
  };

  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country?.countryCode?.toUpperCase());
    asYouType.input(value.replace(country?.dialCode, ""));
    const isValid = asYouType.isValid();
    if (validation.phone && isValid) {
      setValidation({ ...validation, phone: "" });
    }
    setPhoneValidation(isValid);
  };

  const handleChangeMultiSelect = (id, value) => {
    if (id === "yearsOfExperience" && validation.experience) {
      setValidation({ ...validation, experience: "" });
    }
    if (id === "education" && validation.education) {
      setValidation({ ...validation, education: "" });
    }
    if (id === "industry" && validation.industry) {
      setValidation({ ...validation, industry: "" });
    }
    if (id === "skills") {
      if (value.includes("All")) {
        setData({
          ...data,
          skills: skillOptions
            .filter((item) => item.value !== "All")
            .map((i) => i.label),
        });
        setSkillsOption([]);
      } else {
        setSkillsOption(
          skillOptions.filter((item) => !value.includes(item.value))
        );
        setData({ ...data, [id]: value });
      }
    } else if (id === "regions") {
      if (value.includes("Global - GL")) {
        setData({ ...data, [id]: ["Global - GL"] });
        let temp = regionOption.map((item) => {
          item.disabled = true;
          return item;
        });
        setRegionOption(temp);
      } else if (value.length == 0) {
        setData({ ...data, [id]: value });
        let temp = regionOption.map((item) => {
          item.disabled = false;
          return item;
        });
        setRegionOption(temp);
      } else {
        setData({ ...data, [id]: value });
        let temp = regionOption.map((item) => {
          if (item.value == "Global - GL") {
            item.disabled = true;
          } else {
            item.disabled = false;
          }
          return item;
        });
        setRegionOption(temp);
      }
    } else if (id === "subCategory") {
      if (value.includes("All")) {
        setData({
          ...data,
          subCategory: subCategoryOptionsAll
            .filter((item) => item.value !== "All")
            .map((i) => i.label),
        });
        setSubCategoryOptions([]);
      } else {
        setSubCategoryOptions(
          subCategoryOptionsAll.filter((item) => !value.includes(item.value))
        );
        setData({ ...data, [id]: value });
      }
    } else {
      setData({ ...data, [id]: value });
    }
  };

  const submit = async () => {
    let validationObj = {
      companyName: "",
      firstName: "",
      lastName: "",
      title: "",
      experience: "",
      phone: "",
      linkedIn: "",
      recEmail: "",
      recPhoneNumber: "",
      recFirstName: "",
      recLastName: "",
      education: "",
      certification: "",
    };
    let errorMsg = [];
    if (data.firstName.trim() === "") {
      errorMsg.push("Please Enter First Name");
      validationObj = { ...validationObj, firstName: "*Required Field" };
    } else if (data.firstName && !isValidFname(data.firstName)) {
      errorMsg.push("Please Enter Valid Name");
      validationObj = { ...validationObj, firstName: "*Invalid First Name" };
    }
    if (data.lastName.trim() === "") {
      errorMsg.push("Please Enter Last Name");
      validationObj = { ...validationObj, lastName: "*Required Field" };
    } else if (data.lastName && !isValidFname(data.lastName)) {
      errorMsg.push("Please Enter Valid Name");
      validationObj = { ...validationObj, lastName: "*Invalid Last Name" };
    }
    if (!data.phone) {
      errorMsg.push("Please Enter Phone No");
      validationObj = { ...validationObj, phone: "*Required Field" };
    }
    if (!data.location.city || data.location.city.trim() === "") {
      errorMsg.push("Please Enter City");
      validationObj = { ...validationObj, city: "*Required Field" };
    }
    if (!data.location.state || data.location.state.trim() === "") {
      errorMsg.push("Please Enter State");
      validationObj = { ...validationObj, state: "*Required Field" };
    }
    if (!data.location.country || data.location.country.trim() === "") {
      errorMsg.push("Please Enter Country");
      validationObj = { ...validationObj, country: "*Required Field" };
    }
    if (data.linkedIn && !isValidUrl(data.linkedIn)) {
      errorMsg.push("Please Enter Valid LinkedIn URL");
      validationObj = { ...validationObj, linkedIn: "*Invalid URL" };
    }

    if (data.recEmail && Validator.validate(data.recEmail) === false) {
      errorMsg.push("Please Enter Valid Email");
      validationObj = { ...validationObj, recEmail: "*Invalid Email" };
    }
    if (data.yearsOfExperience === "") {
      errorMsg.push("Please enter Years Of Experience");
      validationObj = { ...validationObj, experience: "*Required Field" };
    }
    if (data.education.length === 0) {
      errorMsg.push("Please enter Education");
      validationObj = { ...validationObj, education: "*Required Field" };
    }
    if (data.certification.length === 0) {
      errorMsg.push("Please enter Certifications");
      validationObj = { ...validationObj, certification: "*Required Field" };
    }
    if (data.industries.length === 0) {
      errorMsg.push("Please enter in which Industry do you recruit");
      validationObj = { ...validationObj, industry: "*Required Field" };
    }
    if (data.subCategory.length === 0) {
      errorMsg.push("Please enter in which category do you recruit");
      validationObj = { ...validationObj, subCategory: "*Required Field" };
    }
    if (data.skills.length === 0) {
      errorMsg.push("Please enter types of skills ");
      validationObj = { ...validationObj, skill: "*Required Field" };
    }
    if (data.regions.length === 0) {
      errorMsg.push("Please enter regions ");
      validationObj = { ...validationObj, region: "*Required Field" };
    }
    if (data.languages.length === 0) {
      errorMsg.push("Please enter languages");
      validationObj = { ...validationObj, language: "*Required Field" };
    }
    if (data.jobTitle.title1.trim() === "") {
      errorMsg.push("Please Enter Job Title 1");
      validationObj = { ...validationObj, title: "*Required Field" };
    }
    if (data.recPhoneNumber && !recPhoneValidation) {
      errorMsg.push("Please Enter Valid Phone for Recruiter");
      validationObj = { ...validationObj, recPhoneNumber: "*Invalid Phone" };
    }
    if (errorMsg.length > 0) {
      if (errorMsg.length > 1) {
        toast.error("Please fill all the mandatory fields");
      } else {
        toast.error(errorMsg[0]);
      }
      setValidation(validationObj);
    } else {
      setValidation({
        companyName: "",
        firstName: "",
        lastName: "",
        title: "",
        experience: "",
        phone: "",
        linkedIn: "",
        recEmail: "",
        recPhoneNumber: "",
        recFirstName: "",
        recLastName: "",
        education: "",
        certification: "",
      });
      try {
        setLoading(true);
        let res;
        res = await post("/gigrecruiter/insert", {
          ...data,
          companyName: "",
        });
        if (res.data.ReturnText === "Registered Successfully") {
          setSuccess(true);
          setTimeout(() => {
            history.push("/login"); // Redirects to the '/login' route
          }, 5000); // 5000 milliseconds = 5 seconds
        } else {
          if (res.data.ReturnText === "Email ID Already Exists") {
            setValidation({
              ...validation,
              recEmail: "This Recruiter Email Already Exists",
            });
            toast.error(
              "Recruiter Email ID Already Exists. Please Try Another Email"
            );
          }
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error("Some Error Occurred while Saving Data. Please Try Again");
      }
    }
  };

  const handleChangeIndustry = async (val) => {
    if (val.length > 0) {
      let industry = [];
      if (val.includes("All")) {
        industry = dropdownData.filter((item) => item.typeName == "skillSet");
        industry = industry.map((item) => item.id);
        setIndustriesOptions([]);
      } else {
        industry = dropdownData.filter(
          (item) => val.includes(item.name) && item.typeName == "skillSet"
        );
        industry = industry.map((item) => item.id);
        setIndustriesOptions(
          industriesOptionsAll.filter((item) => !val.includes(item.value))
        );
        setData({ ...data, industries: val, subCategory: [] });
      }
      const payload = {
        industry,
      };
      try {
        setCategoryLoading(true);
        const res = await post("/common/subcategory", payload);
        setCategoryLoading(false);
        if (res.status == 200) {
          let subCategoryData = [
            {
              value: "All",
              label: "All",
            },
          ];
          res.data.map((item) => {
            subCategoryData = [
              ...subCategoryData,
              {
                value: item.categoryName,
                label: item.categoryName,
              },
            ];
          });
          setSubCategoryOptions(subCategoryData);
          setSubCategoryOptionsAll(subCategoryData);
          if (val.includes("All")) {
            setData({
              ...data,
              industries: industriesOptionsAll
                .filter((item) => item.value !== "All")
                .map((i) => i.label),
              subCategory: res.data.map((item) => item.categoryName),
            });
          }
        } else {
          toast.error("Oops! something went wrong. please try again");
        }
      } catch (error) {
        toast.error("Oops! something went wrong. please try again");
        setCategoryLoading(false);
      }
    } else {
      setIndustriesOptions(industriesOptionsAll);
      setSubCategoryOptions([]);
      setSubCategoryOptionsAll([]);
      setData({ ...data, subCategory: val, industries: val });
    }
  };

  const setLocationRegion = (address, searchInput) => {
    const city = address.locality || address.city;
    setData({
      ...data,
      location: {
        ...data.location,
        city: city || "",
        state: address?.state || "",
        country: address?.country || "",
      },
    });
  };

  return (
    <>
      {!success ? (
        <>
          {(loading || dropdownLoading || categoryLoading) && <Loader />}
          {status === "Invited" && (
            <div
              className="card-body shadow p-3 mb-5 bg-white rounded"
              style={{
                boxSizing: "border-box",
                height: "auto",
                width: "50rem",
                position: "relative",
                left: "20%",
                right: "20%",
              }}
            >
              <h4 style={{ textAlign: "center" }}>SIGN UP FOR THE</h4>
              <h4 style={{ textAlign: "center", color: "#ff8f73" }}>
                HIGH5 RECRUITER MARKETPLACE
              </h4>
              <h2 style={{ textAlign: "center" }}>
                Step 1. Complete Online Recruiting Form
              </h2>
              <h2 style={{ textAlign: "center" }}>Step 2. Make More Money</h2>
              <p style={{ textAlign: "center" }}>
                <span style={{ fontWeight: "bold" }}>
                  It takes less than two minutes
                </span>{" "}
                to begin your expertise and monetize your worth.
              </p>
              <div className="form-floating">
                <Container fluid>
                  <Row>
                    <Col>
                      <div className="form-floating">
                        <input
                          type="text"
                          className={
                            validation.firstName
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                          id="firstName"
                          placeholder="First Name*"
                          value={data.firstName}
                          required
                          onChange={handleChange}
                        />
                        <label>First Name*</label>
                      </div>
                      {validation.firstName && (
                        <small className="validation ml-2">
                          {validation.firstName}
                        </small>
                      )}
                    </Col>
                    <Col>
                      <div className="form-floating">
                        <input
                          type="text"
                          className={
                            validation.lastName
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                          id="lastName"
                          placeholder="Last Name*"
                          value={data.lastName}
                          onChange={handleChange}
                          required
                        />
                        <label>Last Name*</label>
                      </div>
                      {validation.lastName && (
                        <small className="validation ml-2">
                          {validation.lastName}
                        </small>
                      )}
                    </Col>
                    {/* </Row>
                  <Row> */}
                    <Col>
                      <div className="form-floating ">
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Email*"
                          value={data.email}
                          disabled
                        />
                        <label>Email*</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="">
                      {" "}
                      <label>Phone*</label>
                      <div className="form-floating" style={{ zIndex: "999" }}>
                        <PhoneInput
                          value={data.phone}
                          name="mobilePhone"
                          country={"us"}
                          onChange={(value, country, e) => {
                            setData({
                              ...data,
                              phone: value,
                              phoneCode: country.dialCode,
                              country: country?.name,
                            });
                            _onChangeText(value, country);
                          }}
                          placeholder="Phone Number"
                          inputClass={
                            validation.phone
                              ? "form-control border border-danger w-100"
                              : "form-control w-100"
                          }
                          enableSearch={true}
                        />
                      </div>
                      {validation.phone ? (
                        <small className="validation ml-2">
                          {validation.phone}
                        </small>
                      ) : !phoneValidation ? (
                        <small className="validation">Invalid Phone</small>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Row>
                  <Row>
                    <AddJobNewGoogleMaps
                      setLocation={setLocationRegion}
                      searchInput={searchInput}
                    />
                  </Row>
                  <Row>
                    <Col className="mt-3">
                      <div className="form-floating">
                        <input
                          type="url"
                          className={
                            validation.city
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                          placeholder="City*"
                          id="city"
                          onChange={handleLocation}
                          value={data.location.city}
                        />
                        <label>City*</label>
                      </div>
                      {validation.city && (
                        <small className="validation ml-2">
                          {validation.city}
                        </small>
                      )}
                    </Col>
                    <Col className="mt-3">
                      <div className="form-floating">
                        <input
                          type="url"
                          className={
                            validation.state
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                          placeholder="State*"
                          id="state"
                          onChange={handleLocation}
                          value={data.location.state}
                        />
                        <label>State*</label>
                      </div>
                      {validation.state && (
                        <small className="validation ml-2">
                          {validation.state}
                        </small>
                      )}
                    </Col>
                    <Col className="mt-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className={
                            validation.country
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                          placeholder="Country*"
                          id="country"
                          onChange={handleLocation}
                          value={data.location.country}
                        />
                        <label>Country*</label>
                      </div>
                      {validation.country && (
                        <small className="validation ml-2">
                          {validation.country}
                        </small>
                      )}
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <label>Education*</label>
                      <div
                        className="form-floating Hover-Dropdown"
                        style={{ zIndex: "999" }}
                      >
                        <Select
                          options={educationsOptions}
                          name="Education"
                          className={
                            validation.education && "border border-danger"
                          }
                          isSearchable={true}
                          onChange={(val) =>
                            handleChangeMultiSelect("education", val.value)
                          }
                        ></Select>
                        <small className="validation ml-2">
                          {validation.education}
                        </small>
                      </div>
                    </Col>
                    <Col>
                      <label>Certification*</label>
                      <div
                        className="form-floating Hover-Dropdown"
                        style={{ zIndex: "999" }}
                      >
                        <Select
                          isMulti
                          options={certificationOptions}
                          name="Certification"
                          className={
                            validation.certification && "border border-danger"
                          }
                          isSearchable={true}
                          onChange={(value) =>
                            handleChangeMultiSelect(
                              "certification",
                              value?.map((i) => i.value)
                            )
                          }
                        ></Select>

                        <small className="validation ml-2">
                          {validation.certification}
                        </small>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="form-floating mt-3">
                        <input
                          type="url"
                          className={
                            validation.linkedIn
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                          placeholder="Linkedin URL*"
                          id="linkedIn"
                          onChange={handleChange}
                          value={data.linkedIn}
                        />
                        <label>Linkedin URL</label>
                      </div>
                      {validation.linkedIn && (
                        <small className="validation ml-2">
                          {validation.linkedIn}
                        </small>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>
                        How many years of experience do you have in recruiting?*
                      </label>
                      <div
                        className="form-floating Hover-Dropdown"
                        style={{ zIndex: "998" }}
                      >
                        <Select
                          options={experienceOptions}
                          name="Education"
                          className={
                            validation.experience && "border border-danger"
                          }
                          isSearchable={true}
                          onChange={(val) =>
                            handleChangeMultiSelect(
                              "yearsOfExperience",
                              val.value
                            )
                          }
                        ></Select>
                        <small className="validation ml-2">
                          {validation.experience}
                        </small>
                      </div>
                    </Col>
                    <Col>
      <label htmlFor="industrySelect">In which job category do you recruit?*</label>
      <div className="form-floating hover-Dropdown" style={{ zIndex: "998" }}>
        <Select
          id="industrySelect"
          isMulti
          options={industriesOptions}
          value={
            data.industries
              ? industriesOptionsAll.length - 1 === data.industries.length
                ? [{ label: "All", value: "All" }]
                : industriesOptionsAll.filter((f) =>
                    data.industries.includes(f.value)
                  )
              : []
          }
          name="Industry"
          isSearchable={true}
          className={validation.industry && "border border-danger"}
          onKeyDown={handleKeyDown} // Attach the key down handler
          onChange={(value) =>
            handleChangeIndustry(value?.map((i) => i.value))
          }
        />
        <small className="validation ml-2">
          {validation.industry}
        </small>
      </div>
    </Col>
  
                    <Col>
                      <label style={{ paddingTop: "38px" }}>
                        Sub category?*
                      </label>
                      <div
                        className="form-floating hover-Dropdown"
                        style={{ zIndex: "998" }}
                        disabled={true}
                      >
                        <Select
                          isMulti
                          options={subCategoryOptions.sort(
                            (a, b) => b.label - a.label
                          )}
                          name="subCategory"
                          isSearchable={true}
                          className={
                            validation.subCategory && "border border-danger"
                          }
                          value={
                            data.subCategory
                              ? subCategoryOptionsAll.length - 1 ==
                                data.subCategory.length
                                ? [{ label: "All", value: "All" }]
                                : subCategoryOptionsAll.filter((f) =>
                                    data.subCategory.includes(f.value)
                                  )
                              : []
                          }
                          onChange={(value) =>
                            handleChangeMultiSelect(
                              "subCategory",
                              value?.map((i) => i.value)
                            )
                          }
                          isDisabled={
                            data.industries.length == 0 ||
                            industriesOptionsAll.length - 1 ==
                              data.industries.length
                          }
                        ></Select>
                        <small className="validation ml-2">
                          {validation.subCategory}
                        </small>
                      </div>
                    </Col>
                  </Row>
                  <Row style={{ fontSize: 13 }}>
                    <Col>
                      <label>Which types of roles do you recruit?*</label>
                      <div className="form-floating Hover-Dropdown">
                        <Select
                          isMulti
                          options={skillsOptions}
                          value={
                            data.skills
                              ? skillOptions.length - 1 == data.skills.length
                                ? [{ label: "All", value: "All" }]
                                : skillOptions.filter((f) =>
                                    data.skills.includes(f.value)
                                  )
                              : []
                          }
                          name="placementSkill"
                          isSearchable={true}
                          className={validation.skill && "border border-danger"}
                          onChange={(value) =>
                            handleChangeMultiSelect(
                              "skills",
                              value?.map((i) => i.value)
                            )
                          }
                        ></Select>
                        <small className="validation ml-2">
                          {validation.skill}
                        </small>
                      </div>
                    </Col>
                    <Col>
                      <label>What regions have you recruited?*</label>
                      <div className="form-floating Hover-Dropdown">
                        <Select
                          isMulti
                          options={regionOption}
                          isOptionDisabled={(regionOption) =>
                            regionOption.disabled
                          }
                          name="regionsRecruited"
                          isSearchable={true}
                          className={
                            validation.region && "border border-danger"
                          }
                          onChange={(value) =>
                            handleChangeMultiSelect(
                              "regions",
                              value?.map((i) => i.value)
                            )
                          }
                        ></Select>
                        <small className="validation ml-2">
                          {validation.region}
                        </small>
                      </div>
                    </Col>
                    <Col>
                      <label>What languages can you speak?*</label>
                      <div className="form-floating Hover-Dropdown">
                        <Select
                          isMulti
                          options={languageOptions}
                          name="LanguageSkill"
                          isSearchable={true}
                          className={
                            validation.language && "border border-danger"
                          }
                          onChange={(value) =>
                            handleChangeMultiSelect(
                              "languages",
                              value?.map((i) => i.value)
                            )
                          }
                        ></Select>
                        <small className="validation ml-2">
                          {validation.language}
                        </small>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <label>
                        List the most common job titles you've filled in the
                        last 12 months:
                      </label>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col>
                      <div className="form-floating">
                        <input
                          type="text"
                          className={
                            validation.title
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                          placeholder="Job Title 1*"
                          value={data.jobTitle.title1}
                          onChange={(e) => {
                            validation.title &&
                              setValidation({ ...validation, title: "" });
                            setData({
                              ...data,
                              jobTitle: {
                                ...data.jobTitle,
                                title1: e.target.value,
                              },
                            });
                          }}
                        />
                        <label>Job Title 1*</label>
                      </div>
                      {validation.title && (
                        <small className="validation ml-2">
                          {validation.title}
                        </small>
                      )}
                    </Col>
                    <Col>
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Job Title 2*"
                          value={data.jobTitle.title2}
                          onChange={(e) =>
                            setData({
                              ...data,
                              jobTitle: {
                                ...data.jobTitle,
                                title2: e.target.value,
                              },
                            })
                          }
                        />
                        <label>Job Title 2</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-floating mt-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Job Title 3*"
                          value={data.jobTitle.title3}
                          onChange={(e) =>
                            setData({
                              ...data,
                              jobTitle: {
                                ...data.jobTitle,
                                title3: e.target.value,
                              },
                            })
                          }
                        />
                        <label>Job Title 3</label>
                      </div>
                    </Col>
                    <Col></Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-check">
                        <input
                          style={{ width: "1.2rem", height: "1.2rem" }}
                          className="form-check-input mt-3"
                          type="checkbox"
                          checked={check}
                          onChange={(e) => setCheck(e.target.checked)}
                          id="referredCheck"
                          required
                        />
                        <label
                          className="form-check-label ml-3"
                          for="flexCheckDefault"
                        >
                          Check if you are referred to High5.
                        </label>
                        {check ? (
                          <Row>
                            <Col>
                              <label className="form-check-label">
                                Who referred you?
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="isreferred"
                                onChange={handleChange}
                              />
                            </Col>
                          </Row>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-check">
                        <input
                          style={{ width: "1.2rem", height: "1.2rem" }}
                          className="form-check-input mt-3"
                          type="checkbox"
                          id="referredCheck"
                          checked={agree}
                          onChange={(e) => setAgree(e.target.checked)}
                        />
                        <label
                          className="form-check-label ml-3"
                          for="receiveCommunications"
                        >
                          I agree to receive communications from High5.*
                        </label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-5">
                      We're committed to your privacy. High5 uses the
                      information you provide to us to contact you about our
                      relevant content, products, and services. You may
                      unsubscribe from these communications at any time.
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mt-5">
                      <button
                        type="button"
                        className="btn btn-danger mt-50"
                        style={{ backgroundColor: "#ff8f73" }}
                        onClick={submit}
                        disabled={!agree}
                      >
                        Send Now
                      </button>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          )}
          {status && status === "Pending" && (
            <>
              <div className="bl-modal reveal-overlay reveal-card">
                <div className="bl-modal-overlay"></div>
                <div className="bl-modal-container">
                  <div className="bl-modal-card bl-modal-card-md">
                    <div className="bl-modal-body text-center">
                      <div className="avatar avatar-lg avatar-red2 mr-3 mb-3">
                        <i class="fas fa-exclamation-triangle fa-fw"></i>
                      </div>
                      <div className="mb-3">
                        <h4>You are already a registered user</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {status && status === "Deleted" && (
            <>
              <div className="bl-modal reveal-overlay reveal-card">
                <div className="bl-modal-overlay"></div>
                <div className="bl-modal-container">
                  <div className="bl-modal-card bl-modal-card-md">
                    <div className="bl-modal-body text-center">
                      <div className="avatar avatar-lg avatar-red2 mr-3 mb-3">
                        <i class="fas fa-exclamation-triangle fa-fw"></i>
                      </div>
                      <div className="mb-3">
                        <h4>
                          Unfortunately, your invitation has been cancelled.{" "}
                        </h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <>
          <div className="bl-modal reveal-overlay reveal-card">
            <div className="bl-modal-overlay"></div>
            <div className="bl-modal-container">
              <div className="bl-modal-card bl-modal-card-md">
                <div className="bl-modal-body">
                  <div className="success-animation">
                    <div className="checkmark mt-3">
                      <Lottie
                        loop={loop}
                        animationData={checkMark}
                        play
                      ></Lottie>
                    </div>
                    <Anime
                      initial={[
                        {
                          targets: "#LottieSparkles",
                          opacity: 0,
                          delay: 1000,
                        },
                      ]}
                    >
                      <div className="sparkles">
                        <Lottie
                          loop={loop}
                          animationData={sparkles}
                          id="LottieSparkles"
                          play
                        ></Lottie>
                      </div>
                    </Anime>
                  </div>
                  <div className="mb-3">
                    <h4 className="text-center">Registered Successfully</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RecruiterMarketPlace;
