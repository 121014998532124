import React, { useState } from "react";

const ReportTable = ({ getPaginatedData, reportHeaders }) => {
  const [showAllRows, setShowAllRows] = useState(
    Array(getPaginatedData().length).fill(false)
  );

  const toggleShowAll = (index) => {
    setShowAllRows((prevShowAllRows) => {
      const newShowAllRows = [...prevShowAllRows];
      newShowAllRows[index] = !newShowAllRows[index];
      return newShowAllRows;
    });
  };
  return (
    <div>
      {getPaginatedData().length > 0 ? (
        <div
          className="px-3 pt-0 table-cards-1024 mt-3"
          style={{ overflowX: "scroll" }}
        >
          <table
            className="table table-hoverabletable-cards-1024"
            id="table-to-xls"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <thead>
              <tr>
                {reportHeaders.map((header, index) => (
                  <th className="w-100" key={index}>
                    {header.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {getPaginatedData().map((item, index) => (
                <tr key={index}>
                  <td>{item.userId}</td>
                  <td>{item.designation}</td>
                  <td>
                    {item?.industries && JSON.parse(item.industries)?.length > 0
                      ? JSON.parse(item.industries)[0]
                      : "NA"}
                  </td>
                  <td>
                    {item.primarySkills !== null &&
                    item.primarySkills !== undefined &&
                    item.primarySkills.length !== 0 &&
                    JSON.parse(item.primarySkills).length <= 1
                      ? JSON.parse(item.primarySkills)
                          .map((skill) => skill)
                          .join(", ")
                      : item.primarySkills !== null &&
                        item.primarySkills !== undefined &&
                        item.primarySkills.length !== 0 &&
                        JSON.parse(item.primarySkills).length > 1 &&
                        !showAllRows[index]
                      ? JSON.parse(item.primarySkills)
                          .map((skill) => skill)
                          .slice(0, 1)
                      : item.primarySkills !== null &&
                        item.primarySkills !== undefined &&
                        item.primarySkills.length !== 0 &&
                        JSON.parse(item.primarySkills).length > 1 &&
                        showAllRows[index]
                      ? JSON.parse(item.primarySkills)
                          .map((skill) => skill)
                          .join(", ")
                      : null}
                    {item.primarySkills !== null &&
                      item.primarySkills !== undefined &&
                      item.primarySkills.length !== 0 &&
                      JSON.parse(item.primarySkills).length > 1 && (
                        <button
                          onClick={() => toggleShowAll(index)}
                          type="button"
                          className="btn btn-text"
                        >
                          {!showAllRows[index] ? (
                            <span>View all skills</span>
                          ) : (
                            <span>Show less</span>
                          )}
                        </button>
                      )}
                  </td>

                  <td>
                    {item.secondarySkills !== null &&
                    item.secondarySkills !== undefined &&
                    JSON.parse(item.secondarySkills).length > 0
                      ? `${JSON.parse(item.secondarySkills).slice(0, 5)}`
                      : "Data Not Found"}
                  </td>

                  {item.experienceYear !== null ||
                  "" ||
                  item.experienceMonth !== null ||
                  "" ? (
                    <td>
                      {item.experienceYear !== "" || null
                        ? `${item.experienceYear + "yrs"}`
                        : "Data Not Found"}{" "}
                      {item.experienceMonth !== "" || null
                        ? `${item.experienceMonth + "months"}`
                        : ""}
                    </td>
                  ) : (
                    <td>Data Not Found</td>
                  )}
                  {item.city !== "" ||
                  item.state !== "" ||
                  item.country !== "" ? (
                    <td>
                      {item.city !== null && item.city !== ""
                        ? `${item.city + ", "}`
                        : ""}
                      {item.state !== null && item.state !== ""
                        ? `${item.state + ", "}`
                        : ""}
                      {item.country !== null || ""
                        ? `${item.country}`
                        : "Data Not Found"}
                    </td>
                  ) : (
                    <td>Data Not Found</td>
                  )}
                  {/* <td>{item.isVetted === true ? "Yes" : "No"}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="mt-3" style={{ visibility: "hidden" }}>
          <div class="text-center p-5">
            <div className="avatar avatar-lg">
              <i aria-hidden="true"></i>
            </div>
            <div class="mt-2"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportTable;
