import { toast } from "react-toastify";
import { post } from "../../service/ApiService";
export const FETCH_SAVED_CAREER_FILTER_JOB_LIST_BEGIN = "FETCH_SAVED_CAREER_FILTER_JOB_LIST_BEGIN";
export const FETCH_SAVED_CAREER_FILTER_JOB_LIST_SUCCESS = "FETCH_SAVED_CAREER_FILTER_JOB_LIST_SUCCESS";
export const FETCH_SAVED_CAREER_FILTER_JOB_LIST_FAILURE = "FETCH_SAVED_CAREER_FILTER_JOB_LIST_FAILURE";


export const fetchCareerFilterSavedJobList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_SAVED_CAREER_FILTER_JOB_LIST_BEGIN,
    });
    const res = await post("/career/getfilteredsavedjobs", params);
    if (res.status === 200) {
      dispatch({
        type: FETCH_SAVED_CAREER_FILTER_JOB_LIST_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_SAVED_CAREER_FILTER_JOB_LIST_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error.response?.data?.errMessage);
  }
};
