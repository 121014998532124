import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Hired from "./Hired";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";
import Pagination from "../../../../commoncomponents/pagination/Pagination";
import { getUserRole } from "../../../../../../service/AuthService";
import PaginationComponent from "../../../../pagination/pagination";
import { getDispositionDetails } from "../../../../../../actions/disposition/Disposition";
import { useParams } from "react-router";

const Index = (props) => {
  const { jobData, fetchLatestEmailStatus, value, view } = props;
  const [hiredData, setHiredData] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { Id } = useParams();
  const dispatch = useDispatch();

  const {
    dispositionDetails,
    dispositionCount,
    dispositionCountLoading,
  } = useSelector(
    (state) => ({
      dispositionDetails: state.dispositionDetails.dispositionDetails,
      dispositionCount: state.dispositionCount.dispositionCount,
      dispositionCountLoading: state.dispositionCount.dispositionCountLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let isCancelled = false;

    const timeoutId = setTimeout(() => {
      if (!isCancelled) {
        if (
          paginationArray.length > 0 &&
          !paginationArray[0].hasOwnProperty("latestEmailStatus")
        ) {
          fetchLatestEmailStatus(paginationArray, setPaginationArray, "Hired");
        }
      }
    }, 1000); // 1 seconds timeout

    return () => {
      isCancelled = true;
      clearTimeout(timeoutId);
    };
  }, [paginationArray]);

  useEffect(() => {
    if (dispositionDetails.length > 0) {
      if (value !== "" && value !== null) {
        let data = dispositionDetails.map((e) => ({
          ...e,
          name: e.firstName.toLowerCase() + " " + e.lastName.toLowerCase(),
        }));
        const regex = new RegExp(value, "i");
        let filter = data
          .filter(
            (item) => item.dispositionStatus == 11 && regex.test(item.name)
          )
          .sort((a, b) =>
            b.offerAcceptedDate.localeCompare(a.offerAcceptedDate)
          );
        setHiredData(filter);
      } else {
        setHiredData(
          dispositionDetails
            .filter((item) => item.dispositionStatus == 11)
            .sort((a, b) =>
              b.offerAcceptedDate.localeCompare(a.offerAcceptedDate)
            )
        );
      }
    }
  }, [dispositionDetails, value]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: view,
      jobId: Id,
    };
    dispatch(getDispositionDetails(payload));
  };

  return (
    <div>
      {hiredData.length > 0 ? (
        <>
          <table className="table table-hoverable table-sortable table-cards-1024">
            <thead>
              <tr>
                <th className="d-flex align-items-start">Candidate</th>
                <th>Acceptance Date</th>
                <th>Start Date</th>
                <th>Resumes </th>
                {getUserRole() === "HiringManager" && (
                  <th className="no-hover">Email Status </th>
                )}
                {/* <th>Interviews</th> */}
                <th>Score </th>
              </tr>
            </thead>
            <tbody>
              <Hired
                jobData={jobData}
                hiredData={hiredData}
                calculateScore={props.calculateScore}
                view={view}
              />
            </tbody>
          </table>
          {/* <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setPaginationArray={setPaginationArray}
            data={hiredData}
            dataSize={15}
            getTab=""
            pageSize={5}
          /> */}
          <PaginationComponent
            currentPage={currentPage}
            totalItems={
              dispositionCount?.filter((e) => e.Status == "Hired")[0]?.Count
            }
            itemsPerPage={15}
            onPageChange={handlePageChange}
            limit={15}
          />
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

export default Index;
