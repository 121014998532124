import React, { useEffect, useState } from "react";

import { useHistory, useParams } from "react-router";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import { emailDomain } from "../../../../constants/Constants";
import ShortUniqueId from "short-unique-id";
import Loader from "../../../Loader/Loader";
import Event from "../../../../googleanalytic/Tracking";
import { browserName } from "react-device-detect";
import axios from "axios";
import Login from "./Login";
import JobApplyForm from "./newtalentform/Index";
import { error } from "jquery";
import { getCandidateId, getUserRole } from "../../../../service/AuthService";

const uid = new ShortUniqueId({ length: 10 });

const Index = ({
  job,
  setModalSize,
  setApply,
  loggedIn,
  isFrom,
  loginData,
  component,
  socialMediaApply,
  setSocialMediaApply,
}) => {
  const { tenant, recruiterid } = useParams();
  const [CreatedBY, setCreatedBY] = useState("");
  const [ip, setIp] = useState("");
  const [tenantType, setTenantType] = useState();
  const [tenantId, setTenantId] = useState();
  const [radiusValue, setRadiusValue] = useState(1000);
  const [dispositionID, setDispositionID] = useState({
    dispositionID: "",
    candidateID: "",
  });

  // Experience child Component
  const [experiences, setExperiences] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      isSelect: false,
    },
  ]);
  const [experienceVal, setExperienceVal] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    },
  ]);

  // Education Child Component
  const [educations, setEducations] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);
  const [educationVal, setEducationVal] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);

  const history = useHistory();
  const data = {
    longitude: "",
    latitude: "",
    firstName: isFrom == "socialMediaApply" ? loginData?.firstName : "",
    lastName: isFrom == "socialMediaApply" ? loginData?.lastName : "",
    email: isFrom == "socialMediaApply" ? loginData?.email : "",
    address: "",
    city: "",
    state: "",
    country: "",
    zipcode: "",
    homePhoneCode: "",
    homePhone: "",
    phoneValidation: true,
    workPhone: "",
    workPhoneCode: "",
    facebook: "",
    twitter: "",
    linkedIn: "",
    preferredSalary: 0,
    preferredSalaryCurrency: "",
    minContractRate: 0,
    minContractRateCurrency: "",
    description: "",
    positionTypes: [],
    preferredLocation: [],
    primarySkills: [],
    secondarySkills: [],
    skillSet: [],
    subCategory: [],
    source: "",
    visaStatus: "",
    designation: "",
    currentEmployer: "",
    experienceMonth: "",
    experienceYear: "",
    experienceLevel: "",
    isFresher: false,
    isRemote: false,
    isHybrid: false,
    isOnsite: false,
    legallyAuthorized: false,
    requireSponsorship: false,
    fileName: "",
    filePath: "",
    experiences: [],
    educations: [],
    certifications: [],
    licenses: [],
    skillMatrix: [],
    highestEducation: "Select",
    base64: "",
    newsLetter: "",
    location: {},
  };
  const [candidateStatus, setCandidateStatus] = useState({});
  const [candidateDetails, setCandidateDetails] = useState(data);
  const [candidateData, setCandidateData] = useState(data);
  const [newsLetterStatus, setNewsLetterStatus] = useState("");
  const [email, setEmail] = useState(
    isFrom == "socialMediaApply" ? loginData?.email : ""
  );
  const [emailExist, setEmailExist] = useState(false);
  const [parsedData, setParsedData] = useState({});
  const [parsed, setParsed] = useState(false);
  const [isScreening, setIsScreening] = useState(false);
  const [screeningAnswers, setScreeningAnswers] = useState([]);
  const [isVetting, setIsVetting] = useState(false);
  const [vettingNow, setVettingNow] = useState(false);
  const [applyNewTalent, setApplyNewTalent] = useState(
    isFrom == "socialMediaApply" ? false : true
  );
  const [applyWithNewTalent, setApplyWithNewTalent] = useState(
    isFrom == "socialMediaApply" ? true : false
  );
  const [editTalent, setEditTalent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingCandidateStatus, setLoadingCandidateStatus] = useState(false);
  const [addTalent, setAddTalent] = useState(false);

  const [trackedData, setTrackedData] = useState({
    jobId: job.jobId,
    poolId: job.poolId,
    recruiterId: recruiterid,
    tenantId: tenant,
    userType: "Candidate",
    navigatedPages: window.location.href, //+ "apply" + "screening" + "success",
    action: "applyJob",
    automatched: false,
    firstName: "",
    lastName: "",
    emailId: sessionStorage.getItem("userName"),
    data: { ...candidateDetails, resumeFileName: "" },
  });
  const [resumeFile, setResumeFile] = useState({ fileName: "", base64: "" });
  const [weightageLocation] = useState(
    job?.weightage ? JSON.parse(job?.weightage).location : false
  );
  useEffect(() => {
    if (loggedIn) setApplyNewTalent(false);
    getCreatedBy();
  }, []);

  useEffect(() => {
    if (resumeFile?.fileName) {
      post("/auditLog/candidateData", {
        ...trackedData,
        navigatedPages: trackedData.navigatedPages + "/apply",
        jobId: job.jobId,
        poolId: job.poolId,
        formType: "A",
        data: JSON.stringify({
          ...candidateDetails,
          resume: true,
        }),
        resume: true,
        firstName: candidateDetails?.firstName,
        lastName: candidateDetails?.lastName,
      });

      setTrackedData({
        ...trackedData,
        navigatedPages: trackedData.navigatedPages + "/apply",
        jobId: job.jobId,
        poolId: job.poolId,
        data: JSON.stringify({
          ...candidateDetails,
          resume: true,
        }),
        resume: true,
        firstName: candidateDetails?.firstName,
        lastName: candidateDetails?.lastName,
      });
    }
  }, [resumeFile]);

  useEffect(() => {
    const fun = async () => {
      if (addTalent) {
        addTalentFunction();
      }
    };
    fun();
  }, [addTalent]);

  useEffect(() => {
    const fun = async () => {
      if (loggedIn) {
        setLoading(true);
        let email = { email: sessionStorage.getItem("userName") };
        await post("/candidate/getcandidatebyemail", email).then((data) => {
          setCandidateDetails(data?.data);
          setCandidateData(data?.data);
          setLoading(false);
        });
      }
    };
    fun();
  }, []);

  useEffect(() => {
    if (editTalent) {
      let edu =
        candidateStatus?.educations !== null
          ? JSON.parse(candidateStatus.educations)
          : educations;
      let eduval = edu.map((e) => educationVal);
      let exp =
        candidateStatus?.experiences !== null
          ? JSON.parse(candidateStatus.experiences)
          : experiences;
      let expval = exp.map((e) => experienceVal);
      setCandidateDetails({
        ...candidateStatus,
        positionTypes:
          candidateStatus?.positionTypes !== null
            ? JSON.parse(candidateStatus.positionTypes)
            : [],
        primarySkills:
          candidateStatus?.primarySkills !== null
            ? JSON.parse(candidateStatus?.primarySkills)
            : [],
        preferredLocation:
          candidateStatus?.preferredLocation !== null
            ? JSON.parse(candidateStatus?.preferredLocation)
            : [],
        secondarySkills:
          candidateStatus?.secondarySkills !== null
            ? JSON.parse(candidateStatus.secondarySkills)
            : [],
        skillSet:
          candidateStatus?.skillSet !== null
            ? JSON.parse(candidateStatus.skillSet)
            : [],
        experiences:
          candidateStatus?.experiences !== null
            ? JSON.parse(candidateStatus.experiences)
            : [],
        educations:
          candidateStatus?.educations !== null
            ? JSON.parse(candidateStatus.educations)
            : [],
        certifications:
          candidateStatus?.certifications !== null
            ? JSON.parse(candidateStatus.certifications)
            : [],
        licenses:
          candidateStatus?.licenses !== null
            ? JSON.parse(candidateStatus.licenses)
            : [],
        location:
          candidateStatus?.location !== null
            ? JSON.parse(candidateStatus.location)
            : {},

        preferredSalary:
          candidateStatus?.preferredSalary !== null
            ? candidateStatus?.preferredSalary
            : 0,

        preferredSalaryCurrency:
          candidateStatus?.preferredSalaryCurrency !== null
            ? candidateStatus?.preferredSalaryCurrency
            : "USD",

        minContractRate:
          candidateStatus?.minContractRate !== null
            ? candidateStatus?.minContractRate
            : 0,
        minContractRateCurrency:
          candidateStatus?.minContractRateCurrency !== null
            ? candidateStatus?.minContractRateCurrency
            : "USD",
        salaryType:
          candidateStatus?.salaryType !== null
            ? candidateStatus?.salaryType
            : "Hourly",
      });

      setEducations(edu);
      setEducationVal(eduval);
      setExperiences(exp);
      setExperienceVal(expval);
    }
  }, [editTalent]);

  useEffect(() => {
    getIpData();
  }, []);
  useEffect(() => {
    (isFrom == "socialMediaApply" ||
      sessionStorage.getItem("token") !== null) &&
      getCandidateStatus();
  }, []);

  let getCreatedBy = async () => {
    try {
      await post("/msgtwoway/getrecruiterId", {
        recruiterId: recruiterid,
      }).then((res) => {
        let Iddd = Number(res.data[0]?.FK_useraccessdetails);
        setCreatedBY(Iddd);
        setTenantType(res.data[0]?.fk_tenanttype);
        setTenantId(res?.data[0]?.id_tenant);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const addTalentFunction = async () => {
    setLoading(true);
    const talentData = { ...candidateDetails };
    let paramObj = {
      longitude: talentData?.longitude ?? "",
      latitude: talentData?.latitude ?? "",
      workAuthCountry: talentData.workAuthCountry,
      isPoolCandidate: false,
      userId:
        sessionStorage.getItem("userId") !== null
          ? sessionStorage.getItem("userId")
          : candidateStatus?.userId,
      firstName: talentData.firstName,
      lastName: talentData.lastName,
      FK_tenant: job.fk_tenant,
      jobTitle: job.jobTitle,
      jobId: job.jobId,
      createdBy: talentData.createdBy,
      email: talentData.email,
      address: talentData.city,
      city: talentData.city,
      state: talentData.state,
      country: talentData.country,
      zipcode: talentData.zipcode ?? "",
      homePhoneCode: talentData.homePhoneCode,
      homePhone: talentData.homePhone,
      workPhone: talentData.mobilePhone,
      workPhoneCode: talentData.mobilePhoneCode,
      facebook: talentData.facebook,
      twitter: talentData.twitter,
      linkedIn: talentData.linkedIn,
      preferredSalary:
        talentData.salaryType === "Hourly"
          ? candidateData?.preferredSalary
          : talentData.preferredSalary,

      preferredSalaryCurrency: talentData.preferredSalaryCurrency,
      minContractRate:
        talentData.salaryType === "Hourly"
          ? talentData.preferredSalary
          : talentData.minContractRate,
      minContractRateCurrency: talentData.minContractRateCurrency,
      description: talentData.description,
      positionTypes: JSON.stringify(talentData.positionTypes),
      preferredLocation: JSON.stringify(
        talentData.isRemote
          ? [...talentData.preferredLocation, "Remote"]
          : talentData.preferredLocation
      ),
      primarySkills: JSON.stringify(talentData.primarySkills),
      secondarySkills: JSON.stringify(talentData.secondarySkills),
      skillSet: JSON.stringify(talentData.skillSet),
      source: talentData.source,
      visaStatus: talentData.visaStatus,
      designation: talentData.designation,
      currentEmployer: talentData.currentEmployer,
      experienceMonth: talentData.experienceMonth?.toString(),
      experienceYear: Math.max(
        Math.max(
          ...talentData.skillMatrix.map((obj) => Number(obj.experience))
        ),
        talentData.experienceYear
      ).toString(),
      isFresher: talentData.isFresher,
      isRemote: talentData.isRemote,
      experiences: JSON.stringify(talentData.experiences),
      educations: JSON.stringify(talentData.educations),
      certifications: JSON.stringify(talentData.certifications),
      licenses: JSON.stringify(talentData.licenses),
      legallyAuthorized: talentData.legallyAuthorized,
      requireSponsorship: talentData.requireSponsorship,
      experienceLevel: talentData.experienceLevel,
      recruiterId: recruiterid,
      candidateId: candidateStatus?.id_user,
      tenantName: job.tenantName,
      highestEducation: talentData.highestEducation,
      oldFileName: data.fileName,
      fileName:
        resumeFile.fileName == ""
          ? candidateDetails.fileName
          : resumeFile.fileName,
      base64: resumeFile.base64,
      filePath: candidateDetails.filePath,
      skillMatrix: JSON.stringify(talentData.skillMatrix),
      salaryType: talentData.salaryType,
      location: JSON.stringify(talentData.location),
      dispositionStatus:
        weightageLocation && job?.isRadius
          ? radiusValue <= job?.radiusValue
            ? 6
            : 12
          : 6,
    };
    if (newsLetterStatus === "Unsubscribed") {
      const resData = await post("/applyjob/updateSubscriptionStatus", {
        email: talentData.email,
      });
    }

    const applyURL =
      candidateStatus.candidateStatus === "New Talent"
        ? "/applyjob/addtalent"
        : "/applyjob/updatetalent";
    const dispositionURL =
      candidateStatus.candidateStatus === "New Talent"
        ? "/applyjob/insertsubmit"
        : candidateStatus.candidateStatus === "Existing Talent" &&
          candidateStatus.id_disposition == null
        ? "/applyjob/insertsubmit"
        : candidateStatus.candidateStatus === "Login Talent" &&
          candidateStatus.id_disposition == null
        ? "/applyjob/insertsubmit"
        : "/applyjob/updatesubmit";
    const poolDispositionURL =
      candidateStatus.candidateStatus === "New Talent"
        ? "/careerspool/insertsubmitpool"
        : candidateStatus.candidateStatus === "Existing Talent" &&
          candidateStatus.id_disposition == null
        ? "/careerspool/insertsubmitpool"
        : candidateStatus.candidateStatus === "Login Talent" &&
          candidateStatus.id_disposition == null
        ? "/careerspool/insertsubmitpool"
        : "/careerspool/updatesubmitpool";
    try {
      const resp = await post(applyURL, paramObj).then(async (data) => {
        setLoading(true);
        if (data.status === 200) {
          const payload = {
            workAuthCountry: talentData.workAuthCountry,
            userId:
              sessionStorage.getItem("userId") !== null
                ? sessionStorage.getItem("userId")
                : candidateStatus.status === 21
                ? candidateStatus.userId
                : data.data.candidateId,
            candidateStatus: candidateStatus?.candidateStatus,
            jobId: job?.jobId,
            poolId: job?.poolId,
            jobTitle: job.jobTitle,
            recruiterId: recruiterid,
            candidateStatus: candidateStatus.candidateStatus,
            status:
              weightageLocation && job?.isRadius
                ? radiusValue <= job?.radiusValue
                  ? 6
                  : 12
                : 6,
            candidateStatus: candidateStatus.candidateStatus,
            id_disposition: candidateStatus?.id_disposition,
            screeningQuestions: JSON.stringify(screeningAnswers),
            disqualifyComments:
              weightageLocation && job?.isRadius
                ? radiusValue <= job?.radiusValue
                  ? null
                  : "Talent's location not within radius limit"
                : null,
            fk_tenant: tenant,
            tenantName: job.tenantName,
            firstName: talentData.firstName,
            lastName: talentData.lastName,
            email: talentData.email.trim(),
            browser: browserName,
            ipAddress: ip,
            activity: `${
              weightageLocation && job?.isRadius
                ? radiusValue <= job?.radiusValue
                  ? "Applied"
                  : "Disqualify"
                : "Applied"
            } for job ${job.jobTitle}(${
              component == "SubCommunity" ? job?.poolId : job?.jobId
            })`,
            applyJob:
              weightageLocation && job?.isRadius
                ? radiusValue <= job?.radiusValue
                  ? false
                  : true
                : false,
          };

          let candidateId =
            candidateStatus.candidateStatus === "New Talent"
              ? data.data.candidateId
              : candidateStatus.userId;

          const automatchedTalent = {
            primarySkills: talentData.primarySkills,
            keywords: talentData.keywords,
            educations: [talentData.highestEducation],
            city: talentData.city,
            preferredLocation: talentData.preferredLocation,
            designation: talentData.currentJobTitle,
            experienceLevel: talentData.experienceLevel,
            userId: candidateId,
            createdBy: data.data.createdBy,
            tenantType: tenantType,
            tenantId: tenantId,
          };
          let automatch = post(
            "/automatch/automatchedTalentForJobs",
            automatchedTalent
          );
          if (job?.vettingRequired) {
            if (weightageLocation) {
              if (job?.isRadius && radiusValue <= job?.radiusValue) {
                await InviteTalentWithVet(candidateId);
              }
            } else {
              await InviteTalentWithVet(candidateId);
            }
          }

          await post(
            component == "SubCommunity" ? poolDispositionURL : dispositionURL,
            payload
          ).then(async (res) => {
            if (res.status === 200) {
              Event(
                "Job Appiled",
                `${talentData.firstName} ${talentData.lastName},${job.jobTitle}`,
                window.location.pathname
              );
              if (component !== "SubCommunity") {
                setApply(false);
                toast.success("Job applied successfully");
                await post("/auditLog/candidateData", {
                  ...trackedData,
                  jobId: job.jobId,
                  formType: "A",
                  poolId: job.poolId,
                  navigatedPages: trackedData.navigatedPages + "/success",
                  resume: resumeFile.fileName ? true : false,
                  data: JSON.stringify({
                    ...candidateDetails,
                  }),
                  status: "success",
                });

                setTrackedData({
                  ...trackedData,
                  jobId: job.jobId,
                  poolId: job.poolId,
                  navigatedPages: trackedData.navigatedPages + "/success",
                  resume: resumeFile.fileName ? true : false,
                  data: JSON.stringify({
                    ...candidateDetails,
                  }),
                  status: "success",
                });

                if (sessionStorage.getItem("token") == null) {
                  let method = sessionStorage.getItem("method");
                  let code = socialMediaApply
                    ? sessionStorage.getItem("code")
                    : "";
                  let baseLoginUrl = "/candidateLogin/";

                  let loginUrl = socialMediaApply
                    ? `${baseLoginUrl}${method}`
                    : baseLoginUrl;
                  const response = await post(loginUrl, {
                    password: data.data.password,
                    code: code,
                    userName: talentData.email,
                  }).then((res) => {
                    sessionStorage.setItem("token", res.data.token);
                    sessionStorage.setItem("name", res.data.name);
                    sessionStorage.setItem("userId", res.data.userId);
                    sessionStorage.setItem("userName", res.data.userName);

                    setEmailExist(false);
                    setTimeout(() => {
                      setLoading(false);
                      // window.location.reload();
                      history.push(`/career/home`);
                    }, 5000);
                  });
                } else {
                  // history.push(`/careers/joblist`);
                  setTimeout(() => {
                    //setLoading(false);
                    // window.location.reload();
                    history.push(`/career/home`);
                    // history.push(`/careers/joblist`);
                  }, 5000);
                }
                if (component !== "SubCommunity") {
                  await sendSmsforSubmission(data.data.password);
                }
              } else {
                let dispositionId =
                  poolDispositionURL === "/careerspool/insertsubmitpool"
                    ? res?.data[0].id_candidateTalentPool
                    : candidateStatus?.id_disposition;

                setDispositionID({
                  ...dispositionID,
                  dispositionID: dispositionId,
                  candidateID: candidateId,
                });
                toast.success("Job applied successfully");
                await post("/auditLog/candidateData", {
                  ...trackedData,
                  jobId: job.jobId,
                  formType: "A",
                  poolId: job.poolId,
                  navigatedPages: trackedData.navigatedPages + "/success",
                  resume: resumeFile.fileName ? true : false,
                  data: JSON.stringify({
                    ...candidateDetails,
                  }),
                  status: "success",
                });

                setTrackedData({
                  ...trackedData,
                  jobId: job.jobId,
                  poolId: job.poolId,
                  navigatedPages: trackedData.navigatedPages + "/success",
                  resume: resumeFile.fileName ? true : false,
                  data: JSON.stringify({
                    ...candidateDetails,
                  }),
                  status: "success",
                });
                if (sessionStorage.getItem("token") == null) {
                  const response = await post("/candidateLogin/", {
                    password: data.data.password,
                    userName: talentData.email,
                  }).then((res) => {
                    sessionStorage.setItem("token", res.data.token);
                    sessionStorage.setItem("name", res.data.name);
                    sessionStorage.setItem("userId", res.data.userId);
                    sessionStorage.setItem("userName", res.data.userName);

                    setEmailExist(false);
                    setTimeout(() => {
                      setLoading(false);
                      //   window.location.reload();
                      history.push(`/career/home`);
                    }, 5000);
                  });
                } else {
                  // history.push(`/careers/joblist`);
                  // setTimeout(() => {
                  //   //setLoading(false);
                  //   window.location.reload();
                  //   // history.push(`/careers/joblist`);
                  // }, 5000);
                }
              }
            }
          });

          // setLoading(false);
        } else {
          setLoading(false);
          toast.error("Something went wrong, please try again ");
        }
      });
    } catch {
      setLoading(false);
      toast.error("Something went wrong, please try again ");
    }
  };

  const InviteTalentWithVet = async (userId) => {
    try {
      const talentData = { ...candidateDetails };
      var date = new Date();
      let vettingDetails = JSON.parse(job.vettingDetails);

      let paramObj = {
        isPoolCandidate: false,
        tenantName: job.tenantName,
        jobTitle: job.jobTitle,
        candidateInfo: {
          firstName: talentData.firstName,
          lastName: talentData.lastName,
          email: talentData.email,
          phone: talentData.homePhone,
        },
        expiryDate: date.setDate(date.getDate() + 7),
        createdFrom: component == "SubCommunity" ? "talentPool" : "High5hire",
        testAssign: vettingDetails._id,
        vettingDetails: vettingDetails,
        uniqueCode: uid(),
        testStatus: "Invited",
        recruiterId: recruiterid,
        candidateId: userId,
        jobId: component == "SubCommunity" ? job?.poolId : job?.jobId,
        fk_tenant: tenant,
        reviewerFirstName: job?.reviewerFirstName,
        reviewerLastName: job?.reviewerLastName,
        reviewerEmail: job?.reviewerEmail,
        poolowner: job.poolowner,
      };

      const vetRes = await post("/vetIntegration/candidate/Invite", paramObj);
      if (vetRes.status === 200) {
      }
    } catch (err) {
      setLoading(false);
    }
  };
  const sendSmsforSubmission = async (pass) => {
    try {
      const name = candidateDetails.firstName;
      const jobName = job.jobTitle;
      const jobId = job.jobId;

      const getSmsContent = async (event, recipient) => {
        try {
          const response = await post("/msgtwoway/getSmsContent", {
            event,
            recipient: JSON.stringify(recipient),
          });

          if (response.status === 200) {
            return response.data;
          }
        } catch (error) {
          console.error("Error fetching SMS content:", error);
        }

        return null;
      };

      const formatPhoneNumbers = (obj) => {
        const formatNumber = (number) =>
          number.charAt(0) !== "+" && number.length > 10
            ? `+${number}`
            : number;

        const home = formatNumber(obj.homePhone || "");
        const mobile = formatNumber(obj.mobilePhone || "");
        const work = formatNumber(obj.workPhone || "");

        const addPhoneNumber = (number, array) => {
          if (number.charAt(0) === "+" && number.charAt(1) !== "1") {
            array.push(number.replace(/ /g, "").substring(1));
          }

          if (number.charAt(0) === "+" && number.charAt(1) === "1") {
            array.push(number.replace(/ /g, ""));
          }
        };

        const recepientDataSms = [];
        const recepientDataWhatsApp = [];

        addPhoneNumber(home, recepientDataWhatsApp);
        addPhoneNumber(mobile, recepientDataWhatsApp);
        addPhoneNumber(work, recepientDataWhatsApp);
        addPhoneNumber(home, recepientDataSms);
        addPhoneNumber(mobile, recepientDataSms);
        addPhoneNumber(work, recepientDataSms);

        return { recepientDataSms, recepientDataWhatsApp };
      };

      const getSmsContentAndReplace = async (event, role, recipientData) => {
        const submitContent = await getSmsContent("Submit", [role]);

        if (submitContent && submitContent.length !== 0) {
          let content = submitContent[0].smsContent;
          content = content.replace("[FirstName]", recipientData.firstName);
          content = content.replace("[CandidateName]", name);
          content = content.replace("[JobTitle]", jobName);
          content = content.replace(
            "[LinkForDetails]",
            `${emailDomain}/high5Hire/requests/${jobId}`
          );

          submitContent[0].smsContent = content;

          return {
            recieverNumber: recipientData.recepientDataSms,
            userId: Math.floor(Math.random() * 1000000),
            message: `${submitContent?.[0]?.smsContent} \n ${submitContent?.[0]?.footer}`,
          };
        }

        return null;
      };
      const getSmsContentAndReplace2 = async (event, role, recipientData) => {
        const submitContent = await getSmsContent("Submit", [role]);

        if (submitContent && submitContent.length !== 0) {
          let content = submitContent[0].smsContent;

          content = content.replace(
            "[Candidate Name]",
            recipientData.firstName
          );
          content = content.replace("[Job Title]", jobName);
          content = content.replace("[Username]", candidateDetails.email);
          content = content.replace("[Password]", pass);
          content = content.replace(
            "[CarrierPageLinkToLogin]",
            recipientData.CarrierPageLinkToLogin
          );

          submitContent[0].smsContent = content;

          return {
            recieverNumber: recipientData.recepientDataSms,
            userId: getCandidateId(),
            message: `${submitContent?.[0]?.smsContent} \n ${submitContent?.[0]?.footer}`,
          };
        }

        return null;
      };
      const payloadTelSms = [];

      const smsContentCand = await getSmsContentAndReplace2(
        "Submit",
        "Candidate",
        {
          firstName: candidateDetails.firstName,
          CarrierPageLinkToLogin: `${emailDomain}/careers/jobList`,
          recepientDataSms: [
            candidateDetails.homePhone
              ? `${
                  candidateDetails.homePhoneCode
                }${candidateDetails.homePhone.replace(/ /g, "")}`
              : "",
          ],
        }
      );

      if (smsContentCand && Object.keys(smsContentCand).length > 0) {
        payloadTelSms.push({
          recieverNumber: smsContentCand.recieverNumber,
          message: smsContentCand.message,
        });
      }

      setLoading(true);

      const numbers = await post("/msgtwoway/getNumbersForOffer", {
        createdBy: CreatedBY,
        jobId: job.jobId,
      });

      const data = numbers.data;

      if (data.length === 0) {
        return;
      }

      const recepientsDataSms = [];
      const recepientsDataWhatsApp = [];

      const roles = {
        3: "Recruiter",
        4: "Hiring Manager",
        8: "Customer Success Manager",
        9: "Marketplace Manager",
      };

      await Promise.all(
        data.map(async (obj) => {
          const {
            recepientDataSms,
            recepientDataWhatsApp,
          } = formatPhoneNumbers(obj);

          let submitContent = await getSmsContentAndReplace(
            "Submit",
            roles[obj.fk_Role],
            {
              firstName: obj.firstName,
              recepientDataSms,
            }
          );

          if (submitContent) {
            if (recepientDataSms.length > 0) {
              recepientsDataSms.push(submitContent);
            }

            if (recepientDataWhatsApp.length > 0) {
              recepientsDataWhatsApp.push({
                recieverNumber: recepientDataWhatsApp,
                message: submitContent.message,
              });
            }
          }
        })
      );

      if (payloadTelSms.length > 0) {
        await post("/msgtwoway/telSms", payloadTelSms);
      }

      if (recepientsDataSms.length > 0) {
        await post("/msgtwoway/telSms", recepientsDataSms);
      }

      if (recepientsDataWhatsApp.length > 0) {
        await post("/massages/sentMassageWhatsapp", recepientsDataWhatsApp);
      }
    } catch (error) {
      console.error("An error occurred", error);
    } finally {
      setLoading(false);
    }
  };

  //   console.log("@669 :", candidateDetails);
  //   try {
  //     const name = candidateDetails.firstName;
  //     const jobName = job.jobTitle;
  //     const jobId = job.jobId;
  //     const getSmsContentRec = async (event) => {
  //       try {
  //         const response = await post("/msgtwoway/getSmsContent", {
  //           event,
  //           recipient: '["Recruiter"]',
  //         });

  //         if (response.status === 200) {
  //           return response.data;
  //         }
  //       } catch (error) {
  //         console.error("Error fetching SMS content:", error);
  //       }

  //       return null;
  //     };
  //     const getSmsContentHM = async (event) => {
  //       try {
  //         const response = await post("/msgtwoway/getSmsContent", {
  //           event,
  //           recipient: '["Hiring Manager"]',
  //         });

  //         if (response.status === 200) {
  //           return response.data;
  //         }
  //       } catch (error) {
  //         console.error("Error fetching SMS content:", error);
  //       }

  //       return null;
  //     };
  //     const getSmsContentCSM = async (event) => {
  //       try {
  //         const response = await post("/msgtwoway/getSmsContent", {
  //           event,
  //           recipient: '["Customer Success Manager"]',
  //         });

  //         if (response.status === 200) {
  //           return response.data;
  //         }
  //       } catch (error) {
  //         console.error("Error fetching SMS content:", error);
  //       }

  //       return null;
  //     };
  //     const getSmsContentMPM = async (event) => {
  //       try {
  //         const response = await post("/msgtwoway/getSmsContent", {
  //           event,
  //           recipient: '["Marketplace Manager"]',
  //         });

  //         if (response.status === 200) {
  //           return response.data;
  //         }
  //       } catch (error) {
  //         console.error("Error fetching SMS content:", error);
  //       }

  //       return null;
  //     };
  //     const getSmsContentCand = async (event) => {
  //       try {
  //         const response = await post("/msgtwoway/getSmsContent", {
  //           event,
  //           recipient: '["Candidate"]',
  //         });

  //         if (response.status === 200) {
  //           return response.data;
  //         }
  //       } catch (error) {
  //         console.error("Error fetching SMS content:", error);
  //       }

  //       return null;
  //     };
  //     const formatPhoneNumbers = (obj) => {
  //       const formatNumber = (number) =>
  //         number.charAt(0) != "+" && number.length > 10 ? `+${number}` : number;

  //       const home = formatNumber(obj.homePhone || "");
  //       const mobile = formatNumber(obj.mobilePhone || "");
  //       const work = formatNumber(obj.workPhone || "");

  //       const recepientDataSms = [];
  //       const recepientDataWhatsApp = [];

  //       if (home.length > 10) {
  //         if (home.charAt(0) == "+" && home.charAt(1) != "1") {
  //           recepientDataWhatsApp.push(home.replaceAll(" ", "")?.substring(1));
  //         }
  //       }

  //       if (mobile.length > 10) {
  //         if (mobile.charAt(0) == "+" && mobile.charAt(1) != "1") {
  //           recepientDataWhatsApp.push(
  //             mobile.replaceAll(" ", "")?.substring(1)
  //           );
  //         }
  //       }

  //       if (work.length > 10) {
  //         if (work.charAt(0) == "+" && work.charAt(1) != "1") {
  //           recepientDataWhatsApp.push(work.replaceAll(" ", "")?.substring(1));
  //         }
  //       }

  //       if (home.charAt(0) == "+" && home.charAt(1) == "1") {
  //         recepientDataSms.push(home.replaceAll(" ", ""));
  //       }

  //       if (mobile.charAt(0) == "+" && mobile.charAt(1) == "1") {
  //         recepientDataSms.push(mobile.replaceAll(" ", ""));
  //       }

  //       if (work.charAt(0) == "+" && work.charAt(1) == "1") {
  //         recepientDataSms.push(work.replaceAll(" ", ""));
  //       }

  //       return { recepientDataSms, recepientDataWhatsApp };
  //     };

  //     let smsContentCand = getSmsContentCand("Submit");
  //     let payloadTelSms = [];
  //     if (smsContentCand && Object.keys(smsContentCand).length > 0) {
  //       smsContentCand =
  //         smsContentCand &&
  //         smsContentCand.replace(
  //           "[Candidate Name]",
  //           candidateDetails.firstName
  //         );
  //       smsContentCand =
  //         smsContentCand && smsContentCand.replace("[Job Title]", job.jobTitle);
  //       smsContentCand =
  //         smsContentCand &&
  //         smsContentCand.replace("[Username]", candidateDetails.email);
  //       smsContentCand =
  //         smsContentCand && smsContentCand.replace("[Password]", pass);
  //       smsContentCand =
  //         smsContentCand &&
  //         smsContentCand.replace(
  //           "[CarrierPageLinkToLogin]",
  //           `${emailDomain}/careers/jobList`
  //         );

  //       payloadTelSms = [
  //         // {
  //         //   recieverNumber: [`${candidatePhone}`],
  //         //   message: `Hello ${candidateDetails.firstName}, Your application for the ${job.jobTitle} role has now been successfully submitted. We’ll be in touch ASAP to discuss the next steps.  Here are your login credentials \nUsername : ${candidateDetails.email}\nPassword :  ${pass}\n Click here to login ${emailDomain}/careers/jobList.\nThe High5 Team`,
  //         // },
  //         {
  //           recieverNumber: [
  //             candidateDetails?.candidatePhone
  //               ? `${
  //                   candidateDetails.candidatePhoneCode
  //                 }${candidateDetails.candidatePhone.replaceAll(" ", "")}`
  //               : "",
  //           ],
  //           message: smsContentCand,
  //         },
  //       ];
  //     }

  //     setLoading(true);

  //     const numbers = await post("/msgtwoway/getNumbersForOffer", {
  //       createdBy: CreatedBY,
  //       jobId: job.jobId,
  //     });

  //     const data = numbers.data;

  //     if (data.length === 0) {
  //       // setLoading(false);
  //       return;
  //     }

  //     const recepientsDataSms = [];
  //     const recepientsDataWhatsApp = [];

  //     const submitContentRec = await getSmsContentRec("Submit");
  //     const submitContentHM = await getSmsContentHM("Submit");
  //     const submitContentCSM = await getSmsContentCSM("Submit");
  //     const submitContentMPM = await getSmsContentMPM("Submit");
  //     await Promise.all(
  //       data.map(async (obj) => {
  //         const {
  //           recepientDataSms,
  //           recepientDataWhatsApp,
  //         } = formatPhoneNumbers(obj);

  //         if (
  //           obj.fk_Role === 3 &&
  //           submitContentRec &&
  //           submitContentRec?.length !== 0
  //         ) {
  //           let content = submitContentRec[0].smsContent;
  //           content = content.replace("[FirstName]", obj.firstName);
  //           content = content.replace("[CandidateName]", name);
  //           content = content.replace("[JobTitle]", jobName);
  //           content = content.replace(
  //             "[LinkForDetails]",
  //             `${emailDomain}/high5Hire/requests/${jobId}`
  //           );
  //           submitContentRec[0].smsContent = content;
  //           if (recepientDataSms.length > 0) {
  //             recepientsDataSms.push({
  //               recieverNumber: recepientDataSms,
  //               userId: Math.floor(Math.random() * 1000000),
  //               // message: ` Hi ${obj.firstName}, Just letting you know ${name} has been submitted for the ${jobName} role.\nClick below to see details.\n${emailDomain}\nThe High5 Team `,
  //               message: `${submitContentRec?.[0]?.smsContent} \n ${submitContentRec?.[0]?.footer}`,
  //             });
  //           }

  //           if (recepientDataWhatsApp.length > 0) {
  //             recepientsDataWhatsApp.push({
  //               recieverNumber: recepientDataWhatsApp,
  //               message: `${submitContentRec?.[0]?.smsContent} \n ${submitContentRec?.[0]?.footer}`,
  //             });
  //           }
  //         }

  //         if (
  //           obj.fk_Role === 4 &&
  //           submitContentHM &&
  //           submitContentHM?.length !== 0
  //         ) {
  //           let content = submitContentHM[0].smsContent;
  //           content = content.replace("[FirstName]", obj.firstName);
  //           content = content.replace("[CandidateName]", name);
  //           content = content.replace("[JobTitle]", jobName);
  //           content = content.replace(
  //             "[LinkForDetails]",
  //             `${emailDomain}/high5Hire/requests/${jobId}`
  //           );
  //           submitContentHM[0].smsContent = content;
  //           if (recepientDataSms.length > 0) {
  //             recepientsDataSms.push({
  //               recieverNumber: recepientDataSms,
  //               userId: Math.floor(Math.random() * 1000000),
  //               // message: ` Hi ${obj.firstName}, Just letting you know ${name} has been submitted for the ${jobName} role.\nClick below to see details.\n${emailDomain}\nThe High5 Team `,
  //               message: `${submitContentHM?.[0]?.smsContent} \n ${submitContentHM?.[0]?.footer}`,
  //             });
  //           }

  //           if (recepientDataWhatsApp.length > 0) {
  //             recepientsDataWhatsApp.push({
  //               recieverNumber: recepientDataWhatsApp,
  //               message: `${submitContentHM?.[0]?.smsContent} \n ${submitContentHM?.[0]?.footer}`,
  //             });
  //           }
  //         }
  //         if (
  //           obj.fk_Role === 8 &&
  //           submitContentCSM &&
  //           submitContentCSM?.length !== 0
  //         ) {
  //           let content = submitContentCSM[0].smsContent;
  //           content = content.replace("[FirstName]", obj.firstName);
  //           content = content.replace("[CandidateName]", name);
  //           content = content.replace("[JobTitle]", jobName);
  //           content = content.replace(
  //             "[LinkForDetails]",
  //             `${emailDomain}/high5Hire/requests/${jobId}`
  //           );
  //           submitContentCSM[0].smsContent = content;
  //           if (recepientDataSms.length > 0) {
  //             recepientsDataSms.push({
  //               recieverNumber: recepientDataSms,
  //               userId: Math.floor(Math.random() * 1000000),
  //               // message: ` Hi ${obj.firstName}, Just letting you know ${name} has been submitted for the ${jobName} role.\nClick below to see details.\n${emailDomain}\nThe High5 Team `,
  //               message: `${submitContentCSM?.[0]?.smsContent} \n ${submitContentCSM?.[0]?.footer}`,
  //             });
  //           }

  //           if (recepientDataWhatsApp.length > 0) {
  //             recepientsDataWhatsApp.push({
  //               recieverNumber: recepientDataWhatsApp,
  //               message: `${submitContentCSM?.[0]?.smsContent} \n ${submitContentCSM?.[0]?.footer}`,
  //             });
  //           }
  //         }
  //         if (
  //           obj.fk_Role === 9 &&
  //           submitContentMPM &&
  //           submitContentMPM?.length !== 0
  //         ) {
  //           let content = submitContentMPM[0].smsContent;
  //           content = content.replace("[FirstName]", obj.firstName);
  //           content = content.replace("[CandidateName]", name);
  //           content = content.replace("[JobTitle]", jobName);
  //           content = content.replace(
  //             "[LinkForDetails]",
  //             `${emailDomain}/high5Hire/requests/${jobId}`
  //           );
  //           submitContentMPM[0].smsContent = content;
  //           if (recepientDataSms.length > 0) {
  //             recepientsDataSms.push({
  //               recieverNumber: recepientDataSms,
  //               userId: Math.floor(Math.random() * 1000000),
  //               // message: ` Hi ${obj.firstName}, Just letting you know ${name} has been submitted for the ${jobName} role.\nClick below to see details.\n${emailDomain}\nThe High5 Team `,
  //               message: `${submitContentMPM?.[0]?.smsContent} \n ${submitContentMPM?.[0]?.footer}`,
  //             });
  //           }

  //           if (recepientDataWhatsApp.length > 0) {
  //             recepientsDataWhatsApp.push({
  //               recieverNumber: recepientDataWhatsApp,
  //               message: `${submitContentMPM?.[0]?.smsContent} \n ${submitContentMPM?.[0]?.footer}`,
  //             });
  //           }
  //         }
  //       })
  //     );
  //     console.log("@1001 :", payloadTelSms);
  //     if (payloadTelSms.length > 0) {
  //       await post("/msgtwoway/telSms", payloadTelSms);
  //     }

  //     if (recepientsDataSms.length > 0) {
  //       await post("/msgtwoway/telSms", recepientsDataSms);
  //     }

  //     if (recepientsDataWhatsApp.length > 0) {
  //       await post("/massages/sentMassageWhatsapp", recepientsDataWhatsApp);
  //     }

  //     setLoading(false);
  //   } catch (error) {
  //     // setLoading(false);
  //     console.error("An error occurred", error);
  //     // toast.error("An error occurred");
  //   }
  // };
  const getCandidateStatus = async () => {
    const params =
      component == "SubCommunity"
        ? {
            poolId: job.poolId,
            email:
              sessionStorage.getItem("token") !== null
                ? sessionStorage.getItem("userName")
                : email?.trim(),
            recruiterId: recruiterid,
          }
        : {
            jobId: job?.jobId,
            email:
              sessionStorage.getItem("token") !== null
                ? sessionStorage.getItem("userName")
                : email?.trim(),
            recruiterId: recruiterid,
          };
    setLoadingCandidateStatus(true);
    const url =
      component == "SubCommunity"
        ? "/careerspool/isemailexistpool"
        : "/applyjob/isemailexist";
    await post(url, params).then((res) => {
      setCandidateStatus(res.data);
      if (res.data.candidateStatus === "Login Talent") {
        setEditTalent(true);
      }
      if (res.data.message === "Already applied for this job ") {
        toast.success("Already applied for this job");
        setApply(false);
        setSocialMediaApply(false);
      }
      setLoadingCandidateStatus(false);
    });
  };
  const getIpData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setIp(res.data.ip);
  };

  return (
    <>
      {(loading || loadingCandidateStatus) && <Loader />}
      {applyNewTalent && (
        <Login
          job={job}
          email={email}
          setEmail={setEmail}
          emailExist={emailExist}
          setEmailExist={setEmailExist}
          setApplyNewTalent={setApplyNewTalent}
          setModalSize={setModalSize}
          setEditTalent={setEditTalent}
          setApplyWithNewTalent={setApplyWithNewTalent}
          setCandidateDetails={setCandidateDetails}
          candidateDetails={candidateDetails}
          setCandidateStatus={setCandidateStatus}
          setTrackedData={setTrackedData}
          trackedData={trackedData}
          setNewsLetterStatus={setNewsLetterStatus}
          component={component}
        />
      )}
      {(!applyNewTalent || loggedIn || editTalent) && (
        <JobApplyForm
          job={job}
          setModalSize={setModalSize}
          parsed={parsed}
          parsedData={parsedData}
          candidateDetails={candidateDetails}
          setCandidateDetails={setCandidateDetails}
          resumeFile={resumeFile}
          setResumeFile={setResumeFile}
          setTrackedData={setTrackedData}
          trackedData={trackedData}
          isFrom={isFrom}
          isScreening={isScreening}
          setIsScreening={setIsScreening}
          setScreeningAnswers={setScreeningAnswers}
          setIsVetting={setIsVetting}
          setAddTalent={setAddTalent}
          isVetting={isVetting}
          vettingNow={vettingNow}
          setVettingNow={setVettingNow}
          setApply={setApply}
          loadding={loading}
          component={component}
          setRadiusValue={setRadiusValue}
          dispositionID={dispositionID}
          setSocialMediaApply={setSocialMediaApply}
          editTalent={editTalent}
          educations={educations}
          setEducations={setEducations}
          educationVal={educationVal}
          setEducationVal={setEducationVal}
          experiences={experiences}
          setExperiences={setExperiences}
          experienceVal={experienceVal}
          setExperienceVal={setExperienceVal}
        />
      )}
    </>
  );
};

export default Index;
