import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const MPMDashboardAnalytics = (props) => {
  const { MPM_daily, MPM_monthly, MPM_total, MPM_weekly } = props.data;
  const [mpmTimeInterval, setMpmTimeInterval] = useState("daily");
  const [loggedInMpm, setLoggedInMpm] = useState(0);
  const [loggedInCount, setLoggedInCount] = useState(0);
  const [chartSeries, setChartSeries] = useState([
    {
      name: "Market Place Managers Login",
      data: [],
      color: "#55c3c3",
    },
  ]);

  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
    },
    dataLabels: {
      enabled: false,
    },
  });

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day} ${month}`;
  }

  const generateChartData = (timeInterval) => {
    let categories = [];
    let data = [];
    let count = 0;
    if (timeInterval === "daily") {
      categories = MPM_daily?.map((item) => formatDate(item.category));
      data = MPM_daily?.map((item) => item.data);
      MPM_daily?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInMpm(MPM_daily && MPM_daily[MPM_daily?.length - 1].data);
    } else if (timeInterval === "weekly") {
      categories = MPM_weekly?.map((item) => formatDate(item.category));
      data = MPM_weekly?.map((item) => item.data);
      MPM_weekly?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInMpm(MPM_weekly && MPM_weekly[MPM_weekly?.length - 1].data);
    } else if (timeInterval === "monthly") {
      categories = MPM_monthly?.map((item) => item.category);
      data = MPM_monthly?.map((item) => item.data);
      MPM_monthly?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInMpm(MPM_monthly && MPM_monthly[MPM_monthly?.length - 1].data);
    }

    setChartSeries([
      {
        name: "Market Place Managers Login",
        data: data,
      },
    ]);
    setChartOptions({
      ...chartOptions,
      xaxis: {
        categories: categories,
      },
    });
  };

  useEffect(() => {
    // Generate charts based on the selected time intervals
    generateChartData(mpmTimeInterval);
  }, [mpmTimeInterval]);
  useEffect(() => {
    generateChartData("daily");
  }, []);
  return (
    <>
      {/* Market Place Manager Login */}

      <div className="card analyticCards custom-card">
        <h6 className="text-center mt-3 mb-3">Market Place Manager Login</h6>
        <div className="d-flex flex-wrap justify-content-center">
          <button
            onClick={() => setMpmTimeInterval("daily")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                mpmTimeInterval === "daily" ? "#17a2b8" : "white",
              color: mpmTimeInterval === "daily" ? "white" : "#17a2b8",
            }}
          >
            Daily
          </button>
          <button
            onClick={() => setMpmTimeInterval("weekly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                mpmTimeInterval === "weekly" ? "#17a2b8" : "white",
              color: mpmTimeInterval === "weekly" ? "white" : "#17a2b8",
            }}
          >
            Weekly
          </button>
          <button
            onClick={() => setMpmTimeInterval("monthly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                mpmTimeInterval === "monthly" ? "#17a2b8" : "white",
              color: mpmTimeInterval === "monthly" ? "white" : "#17a2b8",
            }}
          >
            Monthly
          </button>
        </div>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={250}
        />
        <div className="card m-2 align-items-center">
          <p className="mt-2 fw-light">
            {`Total Marketplace Manager${
              MPM_total !== 1 ? "s" : ""
            }: ${MPM_total}`}
          </p>
          <p className="fw-light">
            {`Logged In Marketplace Manager${
              loggedInCount !== 1 ? "s" : ""
            }: ${loggedInCount}`}
          </p>
        </div>
      </div>
    </>
  );
};

export default MPMDashboardAnalytics;
