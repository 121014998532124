import React, { useState } from "react";
import { domain } from "../../../../constants/Constants";
import { post } from "../../../../service/ApiService";
import {
  getUserId,
  getUserEmail,
  getCandidateName,
  getUserTenant,
} from "../../../../service/AuthService";
import { toast } from "react-toastify";
import Loader from "../../../Loader/Loader";

const tenant = getUserTenant();
function DeleteRequest(props) {
  const [loading, setLoading] = useState(false);
  const jobData = props.jobData;
  const userid = getUserId();
  const email = getUserEmail();
  const name = getCandidateName();
  console.log(email, name);

  const close = () => {
    props.handleSuccess(true);
  };

  const UpdateJobStatus = async () => {
    const payload = {
      jobId: jobData.jobId,
      updatedBy: userid,
    };
    try {
      setLoading(true);
      const res = await post("/update/updatejobstatus", payload);
      if (res.status === 200) {
        props.handleSuccess(true);
        props.fetchJobDetails();
        // toast.success("Job Deactivate updated in database Successfully")
        setLoading(false);
      }
    } catch (error) {
      toast.error("An error occurred");
      setLoading(false);
    }
  };

  const handleDeactivate = async (e) => {
    const payload = {
      jobId: jobData.jobId,
      jobTitle: jobData.jobTitle,
      email,
      name,
      domain: domain,
      jobStatus: jobData.jobStatus,
      tenant: tenant,
    };
    try {
      setLoading(true);
      const res = await post("/job/deactivaterequestbyId", payload);
      if (res.status === 200) {
        props.handleSuccess(true);
        props.fetchJobDetails();
        toast.success("Job Deactivated Successfully");
        setLoading(false);
      }
    } catch (error) {
      toast.error("An error occurred");
      setLoading(false);
    }
    UpdateJobStatus();
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="bl-modal-body  text-center">
          <div className="avatar avatar-lg avatar-red2 mr-3">
            <i class="fas fa-exclamation-triangle fa-fw"></i>
          </div>
          <p className="text-center lead mt-3">
            You're about to deactivate this request, and all of its data.
          </p>
          <div class="text-center my-4">
            <button
              type="button"
              className="btn btn-secondary mr-3"
              data-dismiss="modal"
              onClick={close}
            >
              Cancel
            </button>
            <button
              type="button"
              className="close p-0 bl-modal-close-btn"
              data-dismiss="modal"
              aria-label="Close"
              onClick={close}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleDeactivate}
            >
              Confirm Deactivate
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default DeleteRequest;
