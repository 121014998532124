import React, { useEffect, useState } from "react";
import { getDifferenceInDays } from "../../function/CommonFunction";
import { countryObj, monthNames } from "../../../constants/Constants";
import DisplayResume from "../../common/Jobs/ViewJobDetails/DisplayResume";
import { Modal } from "react-bootstrap";
import { parsePhoneNumber } from "libphonenumber-js";

function CandidateProfileOverview({
  candidateData,
  disposition,
  resumeList,
  isFrom,
}) {
  const [genaral, setGenaral] = useState(false);
  const [skills, setSkills] = useState(true);
  const [workHistory, setWorkHistory] = useState(true);
  const [certification, setCertifications] = useState(true);
  const [education, setEducation] = useState(true);
  const [preferredLocation, setPreferredLocation] = useState(true);
  const [industryExperience, setIndustryExperience] = useState(true);
  const [license, setLicense] = useState(true);
  const [resumes, setResumes] = useState(true);
  const [ViewAllSkill, setViewAllSkill] = useState(false);
  const [social, setSocial] = useState(true);
  const [resumeModal, setResumeModal] = useState(false);
  const onCollapsibleClick = (val) => {
    if (val === "general") {
      genaral ? setGenaral(false) : setGenaral(true);
    }

    if (val === "preferredLocation") {
      preferredLocation
        ? setPreferredLocation(false)
        : setPreferredLocation(true);
    }

    if (val === "industryExperience") {
      industryExperience
        ? setIndustryExperience(false)
        : setIndustryExperience(true);
    }

    if (val === "skills") {
      skills ? setSkills(false) : setSkills(true);
    }

    if (val === "workHistory") {
      workHistory ? setWorkHistory(false) : setWorkHistory(true);
    }

    if (val === "certification") {
      certification ? setCertifications(false) : setCertifications(true);
    }

    if (val === "education") {
      education ? setEducation(false) : setEducation(true);
    }

    if (val === "license") {
      license ? setLicense(false) : setLicense(true);
    }

    if (val === "social") {
      social ? setSocial(false) : setSocial(true);
    }

    if (val === "resumes") {
      resumes ? setResumes(false) : setResumes(true);
    }
  };

  const onSkillClick = () => {
    setViewAllSkill(!ViewAllSkill);
  };

  let positionType = "";

  if (candidateData.preferredPositionType !== undefined) {
    candidateData.preferredPositionType.forEach((item) => {
      positionType = positionType + "/" + item;
    });
  }

  let industry = [];

  if (candidateData.experience !== undefined) {
    industry = [
      ...new Set(candidateData.experience.map((x) => x.employerName)),
    ];
  }

  return (
    <>
      <div>
        <div className="mb-2">
          <button
            type="button"
            className="btn btn-collapsible"
            onClick={() => onCollapsibleClick("general")}
          >
            <i
              className={
                genaral ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"
              }
            ></i>

            <span>
              <small>General</small>
            </span>
          </button>
        </div>

        <div className={genaral ? "ml-4 d-none" : "ml-4"}>
          <div className="d-flex mb-2 pl-1">
            {candidateData.createdByName && (
              <>
                <div>
                  <img
                    src="https://logo.clearbit.com/talentlyft.com"
                    class="avatar avatar-sm"
                    alt=""
                  />
                </div>
                <div class="ml-2">
                  <p class=" mb-0">{candidateData.createdByName}</p>
                  <p class="mb-0">
                    <small>Created by</small>
                  </p>
                </div>
              </>
            )}
          </div>
          {candidateData.mobilePhone && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-phone"></i>
                </div>
                <div class="ml-2">
                  <p class=" mb-0">
                    {parsePhoneNumber(
                      candidateData?.mobilePhone[0] === "+"
                        ? candidateData?.mobilePhone
                        : `+1${candidateData?.mobilePhone}`
                    ).formatInternational()}
                  </p>
                  <p class="mb-0">
                    <small>Contact No</small>
                  </p>
                </div>
              </div>
            </>
          )}
          {candidateData.homePhone && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-phone"></i>
                </div>
                <div class="ml-2">
                  <p class=" mb-0">
                    {candidateData?.homePhone &&
                      candidateData?.homePhone.length > 5 &&
                      parsePhoneNumber(
                        candidateData?.homePhone[0] === "+"
                          ? candidateData?.homePhone
                          : `+1${candidateData?.homePhone}`
                      ).formatInternational()}
                  </p>
                  <p class="mb-0">
                    <small>Contact no</small>
                  </p>
                </div>
              </div>
            </>
          )}
          {(candidateData.email || candidateData.candidateEmail) && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-envelope"></i>
                </div>
                <div class="ml-2">
                  <p class=" mb-0">
                    {candidateData.email
                      ? candidateData.email
                      : candidateData.candidateEmail}
                  </p>
                  <p class="mb-0">
                    <small>Email</small>
                  </p>
                </div>
              </div>
            </>
          )}

          {(candidateData.addressCity || candidateData.addressState) && (
            <div className="d-flex mb-2 pl-1">
              <div className="avatar avatar-sm">
                <i className="fad fa-fw fa-map-marked-alt"></i>
              </div>
              <div className="ml-2">
                {candidateData?.addressCity &&
                candidateData?.addressState &&
                candidateData?.country ? (
                  <p className=" mb-0">
                    {candidateData.addressCity}
                    {candidateData.addressState !== "NA"
                      ? `, ${candidateData.addressState}`
                      : ""}
                    , {candidateData.country}
                  </p>
                ) : (
                  ""
                )}
                <p className="mb-0">
                  <small>Location</small>
                </p>
              </div>
            </div>
          )}
          {positionType && (
            <div className="d-flex mb-2 pl-1">
              <div className="avatar avatar-sm">
                <i className="fad fa-fw fa-piggy-bank"></i>
              </div>
              <div className="ml-2">
                <p className=" mb-0">
                  {candidateData.preferredPositionType.join(", ")}
                </p>
                <p className="mb-0">
                  <small>Work Type</small>
                </p>
              </div>
            </div>
          )}
          {candidateData.experienceYear && candidateData.experienceMonth && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i class="fas fa-user-visor"></i>
                </div>
                <div class="ml-2">
                  {candidateData.experienceYear
                    ? candidateData.experienceYear
                    : "0"}
                  {"."}
                  {candidateData.experienceMonth != 0
                    ? candidateData.experienceMonth
                    : "0"}{" "}
                  {candidateData.experienceYear ? "Years" : ""}
                  <p class="mb-0">
                    <small>Experience</small>
                  </p>
                </div>
              </div>
            </>
          )}
          {candidateData.visaStatus && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fas fa-user"></i>
                </div>
                <div class="ml-2">
                  <p class=" mb-0">
                    {candidateData.visaStatus || "Not Updated"}
                  </p>
                  <p class="mb-0">
                    <small>Visa Status</small>
                  </p>
                </div>
              </div>
            </>
          )}
          {candidateData.recruiterName &&
            (isFrom === "QualifiedCandidates" || isFrom === "inviteTalent") && (
              <>
                <div className="d-flex mb-2 pl-1">
                  <div className="avatar avatar-sm">
                    <i className="fa fa-user"></i>
                  </div>
                  <div class="ml-2">
                    <p class=" mb-0">{candidateData.recruiterName}</p>
                    <p class="mb-0">
                      <small>Invited by</small>
                    </p>
                  </div>
                </div>
              </>
            )}
        </div>
        {candidateData.preferedLocations &&
          candidateData.preferedLocations.length !== 0 && (
            <>
              <div className="mb-2">
                <button
                  type="button"
                  className="btn btn-collapsible"
                  onClick={() => onCollapsibleClick("preferredLocation")}
                >
                  <i
                    className={
                      preferredLocation
                        ? "fal fa-angle-down fa-fw"
                        : "fal fa-angle-up fa-fw"
                    }
                  ></i>
                  <span>
                    <small>Preferred Locations</small>
                  </span>
                </button>
              </div>
              <div
                className={
                  preferredLocation === true
                    ? "mb-4 ml-4 pl-1 d-none"
                    : "mb-4 ml-4 pl-1"
                }
              >
                {candidateData.preferedLocations.map((item) => {
                  return item.length > 1 ? (
                    <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
                  ) : (
                    ""
                  );
                })}
              </div>
            </>
          )}

        {candidateData.skillSet && candidateData.skillSet.length !== 0 && (
          <>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => onCollapsibleClick("industryExperience")}
              >
                <i
                  className={
                    industryExperience
                      ? "fal fa-angle-down fa-fw"
                      : "fal fa-angle-up fa-fw"
                  }
                ></i>
                <span>
                  <small>Industry Experience</small>
                </span>
              </button>
            </div>
            <div
              className={
                industryExperience === true
                  ? "mb-4 ml-4 pl-1 d-none"
                  : "mb-4 ml-4 pl-1"
              }
            >
              {candidateData.skillSet.map((item) => (
                <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
              ))}
            </div>
          </>
        )}

        {/* <div className="mb-2">
        
          <button
            type="button"
            className="btn btn-collapsible"
            onClick={() => onCollapsibleClick("skills")}
          >
            <i
              className={
                skills ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"
              }
            ></i>
            <span>
              <small>Top Skills</small>
            </span>
          </button>
        </div> */}
        {candidateData.primarySkills &&
          candidateData.primarySkills.length !== 0 && (
            <div
              className={
                skills === true ? "mb-4 ml-4 pl-1 d-none" : "mb-4 ml-4 pl-1"
              }
            >
              {candidateData.primarySkills.slice(0, 10).map((item) => (
                <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
              ))}
              {candidateData.primarySkills.length > 10 &&
                ViewAllSkill === true &&
                candidateData.primarySkills
                  .slice(11, candidateData.primarySkills.length)
                  .map((item) => <span class="tag  mb-1 mr-1">{item}</span>)}
              {candidateData.primarySkills.length > 10 && (
                <div class="mt-2">
                  <button
                    onClick={onSkillClick}
                    type="button"
                    class="btn btn-text"
                  >
                    {ViewAllSkill === false ? (
                      <span>View all skills</span>
                    ) : (
                      <span>View Top 10 Skills</span>
                    )}
                  </button>
                </div>
              )}
            </div>
          )}
        {candidateData.experience &&
          candidateData.experience.length > 0 &&
          candidateData.experience[0].employerName && (
            <>
              <div className="mb-2">
                <button
                  type="button"
                  className="btn btn-collapsible"
                  onClick={() => onCollapsibleClick("workHistory")}
                >
                  <i
                    className={
                      workHistory
                        ? "fal fa-angle-down fa-fw"
                        : "fal fa-angle-up fa-fw"
                    }
                  ></i>
                  <span>
                    <small>Work History</small>
                  </span>
                </button>
              </div>

              <div
                className={
                  workHistory ? "mb-4 ml-4 pl-1 d-none" : "mb-4 ml-4 pl-1"
                }
              >
                {candidateData.experience
                  .filter((item) => item.employerName !== "")
                  .map((item) => (
                    <div className="d-flex">
                      <div className="mr-3 d-flex flex-column align-items-center">
                        <i className="fad fa-fw fa-briefcase mt-1"></i>
                        <div className="view-panel-spacer mt-1"></div>
                      </div>

                      <div className="pb-3">
                        <p className="mb-1">
                          <strong>
                            {item.employerName}{" "}
                            <span className="font-regular">
                              ({item.industry})
                            </span>
                          </strong>
                        </p>
                        <p className="mb-0">
                          <small>
                            <strong>{item.jobTitle}</strong>
                          </small>
                        </p>
                        <p>
                          {item.endDate !== "" ? (
                            <small>
                              {monthNames[new Date(item.startDate).getMonth()]}{" "}
                              {new Date(item.startDate).getFullYear()} -{" "}
                              {monthNames[new Date(item.endDate).getMonth()]}{" "}
                              {new Date(item.endDate).getFullYear()} (
                              {getDifferenceInDays(
                                item.startDate,
                                item.endDate
                              )}
                              )
                            </small>
                          ) : (
                            <small>
                              {monthNames[new Date(item.startDate).getMonth()]}{" "}
                              {new Date(item.startDate).getFullYear()} - (
                              {getDifferenceInDays(
                                item.startDate,
                                item.endDate
                              )}
                              )
                            </small>
                          )}
                        </p>
                        {item.description && <p>{item.description}</p>}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        {candidateData.certificates &&
        candidateData.certificates.length > 0 &&
        candidateData.certificates[0].certificate !== "" ? (
          <>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => onCollapsibleClick("certification")}
              >
                <i
                  className={
                    certification
                      ? "fal fa-angle-down fa-fw"
                      : "fal fa-angle-up fa-fw"
                  }
                ></i>
                <span>
                  <small>Certifications</small>
                </span>
              </button>
            </div>
            <div
              className={
                certification === true
                  ? "mb-4 ml-4 pl-1 d-none"
                  : "mb-4 ml-4 pl-1"
              }
            >
              {candidateData.certificates
                .filter((item) => item.certificationName !== "")
                .map((item) => (
                  <div className="d-flex">
                    <div className="mr-3 d-flex flex-column align-items-center">
                      <i className="fad fa-award fa-fw mt-1"></i>
                    </div>
                    <div>
                      <p className="mb-1">
                        <strong>{item.certificationName}</strong>
                      </p>
                      <p>
                        <small>
                          {monthNames[new Date(item.issueDate).getMonth()]}{" "}
                          {new Date(item.issueDate).getFullYear()}
                        </small>
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          ""
        )}
        {candidateData.education &&
          candidateData.education.length > 0 &&
          candidateData.education[0].educationProgram !== "" && (
            <>
              <div className="mb-2">
                <button
                  type="button"
                  className="btn btn-collapsible"
                  onClick={() => onCollapsibleClick("education")}
                >
                  <i
                    className={
                      education
                        ? "fal fa-angle-down fa-fw"
                        : "fal fa-angle-up fa-fw"
                    }
                  ></i>
                  <span>
                    <small>Education Level</small>
                  </span>
                </button>
              </div>

              <div
                className={
                  education === true
                    ? "mb-4 ml-4 pl-1 d-none"
                    : "mb-4 ml-4 pl-1"
                }
              >
                {candidateData.education
                  .filter((item) => item.school !== "")
                  .map((item) => (
                    <div className="d-flex">
                      <div className="mr-3 d-flex flex-column align-items-center">
                        <i className="fad fa-graduation-cap fa-fw mt-1"></i>
                      </div>

                      <div>
                        <p className="mb-1">
                          <strong>{item.school}</strong>
                        </p>
                        <p>
                          <small>
                            {item.graduatedYear}-{item.educationProgram}-
                            {item.major}
                          </small>
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        {candidateData.license &&
          candidateData.license.length > 0 &&
          candidateData.license[0].licenseName !== "" && (
            <>
              <div className="mb-2">
                <button
                  type="button"
                  className="btn btn-collapsible"
                  onClick={() => onCollapsibleClick("license")}
                >
                  <i
                    className={
                      license
                        ? "fal fa-angle-down fa-fw"
                        : "fal fa-angle-up fa-fw"
                    }
                  ></i>
                  <span>
                    <small>License</small>
                  </span>
                </button>
              </div>
              <div
                className={
                  license === true ? "mb-4 ml-4 pl-1 d-none" : "mb-4 ml-4 pl-1"
                }
              >
                {candidateData.license
                  .filter((item) => item.licenseName !== "")
                  .map((item) => (
                    <div className="d-flex">
                      <div className="mr-3 d-flex flex-column align-items-center">
                        <i className="fad fa-award fa-fw mt-1"></i>
                      </div>
                      <div>
                        <p className="mb-1">
                          <strong>{item.licenseName}</strong>
                        </p>
                        <p>
                          <small>
                            {item.state}-
                            {monthNames[new Date(item.expiryDate).getMonth()]}{" "}
                            {new Date(item.expiryDate).getFullYear()}
                          </small>
                        </p>
                        {/* <p>
                          <small>{monthNames[new Date(item.issueDate).getMonth()]} {new Date(item.issueDate).getFullYear()}</small>
                        </p> */}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          )}
        {(candidateData.facebook ||
          candidateData.twitter ||
          candidateData.linkedIn) && (
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => onCollapsibleClick("social")}
            >
              <i
                className={
                  social ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"
                }
              ></i>
              <span>
                <small>Social</small>
              </span>
            </button>
          </div>
        )}
        <div className={social ? "ml-4 d-none" : "ml-4"}>
          {candidateData.facebook && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-facebook"></i>
                </div>
                <div class="ml-2">
                  <a
                    href={
                      candidateData.facebook.slice(0, 8) === "https://"
                        ? candidateData.facebook
                        : "https://" + candidateData.facebook
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <small>
                      <span className="font-14">{candidateData.facebook}</span>{" "}
                      <br />
                    </small>
                  </a>
                  <p class="mb-0">
                    <small>
                      <span> Facebook Id</span>
                    </small>
                  </p>
                </div>
              </div>
            </>
          )}
          {candidateData.twitter && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-twitter"></i>
                </div>
                <div class="ml-2">
                  <a
                    href={
                      candidateData.twitter.slice(0, 8) === "https://"
                        ? candidateData.twitter
                        : "https://" + candidateData.twitter
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <small>
                      <span className="font-14">{candidateData.twitter}</span>{" "}
                      <br />
                    </small>
                  </a>
                  <p class="mb-0">
                    <small>
                      <span> Twitter Id</span>
                    </small>
                  </p>
                </div>
              </div>
            </>
          )}
          {candidateData.linkedIn && (
            <>
              <div className="d-flex mb-2 pl-1">
                <div className="avatar avatar-sm">
                  <i className="fa fa-linkedin"></i>
                </div>
                <div class="ml-2">
                  <a
                    href={
                      candidateData.linkedIn.slice(0, 8) === "https://"
                        ? candidateData.linkedIn
                        : "https://" + candidateData.linkedIn
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <small>
                      <span className="font-14">{candidateData.linkedIn}</span>{" "}
                      <br />
                    </small>
                  </a>
                  <p class="mb-0">
                    <small>
                      <span> LinkedIn Id</span>
                    </small>
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        {/* {candidateData.resumeURL && (
          <>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-collapsible"
                onClick={() => {
                  disposition
                    ? setResumeModal(true)
                    : onCollapsibleClick("resumes");
                }}
              >
                <i
                  className={
                    resumes
                      ? "fal fa-angle-down fa-fw"
                      : "fal fa-angle-up fa-fw"
                  }
                ></i>
                <span>
                  <small>Resumes</small>
                </span>
              </button>
            </div>
            <div
              className={
                resumes === true ? "mb-4 ml-4 pl-1 d-none" : "mb-4 ml-4 pl-1"
              }
            >
              <ul class="list-unstyled">
                {candidateData.resumeURL.map((item) => (
                  <li class="py-1">
                    <a
                      target="_blank"
                      href={item.resumeURL}
                      download={item.fileName}
                    >
                      {item.fileName}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </>
        )} */}
        <div className="mb-5"></div>
      </div>
      <Modal
        size="lg"
        show={resumeModal}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="bl-modal-header">
          <h6 className="bl-modal-title">{"Resume Download"}</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setResumeModal(false)}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <DisplayResume
            close={() => setResumeModal(false)}
            candidateData={candidateData}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default CandidateProfileOverview;
