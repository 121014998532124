import React from "react";
import moment from "moment";

const LearningFullView = (props) => {
  let learningDetails = props.learningDetails;

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header">
          <div className="p-relative">
            <div className="px-3 py-2 d-flex">
              <button
                onClick={() => props.closeModal()}
                type="button"
                className="btn btn-icon"
              >
                <i className="fal fa-times" aria-hidden="true"></i>
              </button>
              {/* <button onClick={getCourseDetails}>Get</button> */}
            </div>
          </div>
          <div className="px-2 mb-3">
            <div className="d-flex align-items-start justify-content-between flex-wrap-reverse">
              <div className="d-flex ">
                <div className="ml-2">
                  <h5 className="mr-2 mt-2 mb-1">
                    {learningDetails.CourseName}
                  </h5>
                  <div className="mb-2">
                    <span className="mr-2">
                      Last updated:{" "}
                      {learningDetails.LastActivityDate
                        ? moment(
                            learningDetails.LastActivityDate,
                            "YYYYMMDD"
                          ).fromNow()
                        : "Not Yet Started"}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                {learningDetails.ProgressStatus !== "Completed" && (
                  <a
                    href={learningDetails.CourseUrl}
                    target="_blank"
                    className="btn btn-primary"
                  >
                    {learningDetails.PercentComplete > 0 && <span>Resume</span>}

                    {learningDetails.PercentComplete === 0 && (
                      <span>Start</span>
                    )}
                  </a>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="view-panel-body">
          <div className="mt-2">
            <p className="mb-1">
              <strong>Progress</strong>
            </p>
            {learningDetails.ProgressStatus === "In Progress" ? (
              learningDetails.PercentComplete === 0 ? (
                <div>
                  <small>Not Started</small>
                  <div className="alert-progress alert-progress-static bg-gray3">
                    <div
                      className="alert-progress-bar bg-green1"
                      style={{ position: "revert" }}
                    ></div>
                  </div>
                </div>
              ) : (
                <div>
                  <small>
                    {learningDetails.PercentComplete + "%"} Completed
                  </small>
                  <div
                    className="alert-progress alert-progress-static bg-gray3"
                    style={{ position: "revert" }}
                  >
                    <div
                      style={{
                        transform: "translateX(-80%)",
                        width: learningDetails.PercentComplete,
                      }}
                      className="alert-progress-bar"
                    ></div>
                  </div>
                </div>
              )
            ) : (
              <div>
                <small>100% Completed</small>
                <div
                  style={{ position: "revert" }}
                  className="alert-progress alert-progress-static bg-green1"
                ></div>
              </div>
            )}
            {/* {learningDetails.ProgressStatus === "Completed" && (
                    <div>
                      <small>100% Completed</small>
                      <div className="alert-progress alert-progress-static bg-green1"></div>
                    </div>
                  )} */}
          </div>
          {/* <div className="mt-4">
            <p>
              <strong>Description</strong>
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default LearningFullView;
