import React, { useEffect, useState } from "react";
import { getInitial, dataDiff } from "../../function/CommonFunction";
import vettedIcon from "../../../images/high5-mark-circle-dark.svg";
import CandidateView from "../../modal/talentprofile/CandidateProfile";
import Avatar from "react-avatar";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/esm/Modal";
import { countryObj } from "../../../constants/Constants";
import CallNote from "../../modal/talentprofile/CallNotes";
import { CurrencySymbols } from "../../config/CurrencySymbols";
import { parsePhoneNumber } from "libphonenumber-js";

function CandidateListView(props) {
  const [showCandidateProfile, setShowCandidateProfile] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const [candidateData, setCandidateData] = useState({});
  const [canId, setCanId] = useState(null);
  // const [paginatedData, setPaginatedData] = useState([]);
  // const [ViewAllSkill, setViewAllSkill] = useState(false);
  const [callNoteData, setCallNoteData] = useState(false);
  const [candidate, setCandidate] = useState("");
  const [newRequestList, setnewRequestList] = useState([]);
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [requestTitleArray, setRequestTitleArray] = useState([]);
  const [pageType, setPageType] = useState("");
  let pageSize = 10;
  let dataSize = 10;
  // useEffect(() => {
  //   setPaginatedData(
  //     getPaginatedData().map((item) => {
  //       return { ...item, isAdded: false, ViewAllSkill: false };
  //     })
  //   );
  // }, [newRequestList]);
  // useEffect(() => {
  //   setPaginatedData(getPaginatedData());
  // }, [props.currentPage]);

  // const StatusTable = [
  //   { id: "0", value: "automatched" },
  //   { id: "1", value: "invite" },
  //   { id: "2", value: "submitted" },
  //   { id: "3", value: "shortlisted" },
  //   { id: "4", value: "offered" },
  //   { id: "5", value: "Hired" },
  // ];

  useEffect(() => {
    if (pageType === "up") {
      if ((props.currentPage - 1) % pageSize === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(
            props.currentPage - 1,
            props.currentPage + (pageSize - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (props.currentPage % pageSize === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(
            props.currentPage - pageSize,
            props.currentPage
          )
        );
      }
    }
  }, [props.currentPage]);
  useEffect(() => {
    let arr = props.arrayList.map((item) => {
      return { ...item, isAdded: false, ViewAllSkill: false };
    });
    setnewRequestList(arr);
    setRequestTitleArray(
      new Array(Math.ceil(arr.length / dataSize)).fill().map((_, i) => i + 1)
    );
    setPaginationGroup(
      new Array(Math.ceil(arr.length / dataSize))
        .fill()
        .map((_, i) => i + 1)
        .slice(0, pageSize)
    );
  }, [props.arrayList]);
  const handleModel = () => {
    setShowCandidateProfile(false);
  };
  const onProfileClick = (candidate) => {
    setCandidateData(candidate);
    setShowCandidateProfile(true);
  };
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    props.setCurrentPage(pageNumber);
  }
  const getPaginatedData = () => {
    const startIndex = props.currentPage * dataSize - dataSize;
    const endIndex = startIndex + +dataSize;
    return newRequestList.slice(startIndex, endIndex);
  };
  const findStatusNameFromList = (status, value) => {
    let result = false;
    status.forEach((item) => {
      if (item.statusId === value) {
        result = true;
      }
    });
    return result;
  };
  const onSkillClick = (e, candidate) => {
    let arr = newRequestList.map((item) => {
      if (item.candidateID === candidate.candidateID) {
        item.ViewAllSkill = !item.ViewAllSkill;
      }
      return item;
    });
    setnewRequestList(arr);
    // setPaginatedData(
    //   paginatedData.map((item) => {
    //     if (item.candidateID === candidate.candidateID) {
    //       item.ViewAllSkill = !item.ViewAllSkill;
    //     }
    //     return item;
    //   })
    // );
    // console.log(candidate.ViewAllSkill);
  };
  const onCallNote = (candidate) => {
    setCandidate(candidate);
    setCallNoteData(true);
  };
  const handleCopy = (candidate) => {
    navigator.clipboard.writeText(candidate.email);
    toast.success("Email Copied Successfully!!!");
  };
  return (
    <>
      <div className="row d-block-force d-none-1200">
        <div className="col">
          {getPaginatedData()?.map((candidate, i) => (
            <a
              href
              key={i}
              onClick={(e) => e.preventDefault()}
              className="card mb-1"
              // className="card d-flex flex-row align-items-stretch justify-content-between mb-1 p-relative"
            >
              <div className="d-flex flex-row align-items-stretch justify-content-between  p-relative">
                {/* {(candidate.MCQ === true ||
                  candidate.onewayvetting === true ||
                  candidate.twowayvetting === true ||
                  candidate.codesignalvetting === true) && (
                  <div className="tag tag-coral tag-vertical-status"></div>
                )} */}
                <div className="card-body d-flex align-items-center col-8">
                  <div className="mx-2">
                    {candidate.ImageURL ? (
                      <span className="avatar avatar-bordered">
                        <img alt="" src={candidate.ImageURL} />
                        {findStatusNameFromList(candidate.statusData, "6") && (
                          <span className="jewel"></span>
                        )}
                      </span>
                    ) : (
                      <span>
                        <Avatar
                          size="55"
                          name={
                            candidate.fullText.firstName +
                            " " +
                            candidate.fullText.lastName
                          }
                          round="80px"
                        />
                        {/* {getInitial(`${candidate.fullText.firstName} ${candidate.fullText.lastName}`)} */}
                        {findStatusNameFromList(candidate.statusData, "6") && (
                          <span></span>
                        )}
                      </span>
                    )}
                  </div>
                  <div className="w-100 ml-2">
                    <div
                      className="d-flex align-items-center mb-1"
                      onClick={() => onProfileClick(candidate)}
                    >
                      <h6 className="mb-0 me-2">
                        {candidate.fullText.firstName
                          ?.charAt(0)
                          ?.toUpperCase() +
                          candidate.fullText.firstName?.slice(1)}{" "}
                        {candidate.fullText.middleName
                          ?.charAt(0)
                          ?.toUpperCase() +
                          candidate.fullText.middleName?.slice(1)}{" "}
                        {candidate.fullText.lastName?.charAt(0)?.toUpperCase() +
                          candidate.fullText.lastName?.slice(1)}
                      </h6>
                      {/* {candidate.isVetted && (
                        <span className="tag tag-coral d-inline-flex">
                          <img width="16px" src={vettedIcon} alt="" />
                          <span className="mx-2">Vetted</span>
                        </span>
                      )} */}
                    </div>
                    <div
                      className="font-regular font-primary font-gray1"
                      // style={{ whiteSpace: "nowrap" }}
                    >
                      {candidate.currentJobTitle && (
                        <span>{candidate.currentJobTitle}</span>
                      )}
                      {candidate.currentJobTitle &&
                        (candidate.fullText.cityName ||
                          candidate.fullText.stateName) &&
                        "/"}
                      {candidate?.addressCity &&
                      candidate?.addressState &&
                      candidate?.country ? (
                        <span>
                          {candidate.addressCity},{" "}
                          {candidate.addressState !== "NA" &&
                            candidate.addressState + ","}{" "}
                          {candidate.country}
                        </span>
                      ) : (
                        ""
                      )}
                      <div className="mt-1 d-flex">
                        {candidate.experience[0] !== undefined ? (
                          <span>
                            {candidate.experience[0].jobTitle
                              ? candidate.experience[0].jobTitle
                              : ""}{" "}
                            {candidate.experience[0].jobTitle ? "at" : ""}{" "}
                            {candidate.experience[0]
                              ? candidate.experience[0].employerName
                              : ""}{" "}
                            {candidate.experience[0].employerName ? " " : ""}{" "}
                          </span>
                        ) : (
                          ""
                        )}
                        {console.log("candidate data :", candidate)}
                        {candidate.experienceYear !== "0" ? (
                          <span
                            className={
                              candidate?.experience[0]?.employerName
                                ? "ml-1"
                                : ""
                            }
                          >
                            {candidate.experienceYear
                              ? candidate.experienceYear
                              : "0"}
                            {"."}
                            {candidate.experienceMonth != 0
                              ? candidate.experienceMonth
                              : "0"}{" "}
                            {candidate.experienceYear ? "Years" : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      {/* <div className="mt-1 d-flex">
                      {<span>Talent Pool: Pool will appear here</span>}
                    </div> */}
                      <div className="mt-1 d-flex">
                        <div className="mt-1 d-block">
                          {candidate.primarySkills.slice(0, 2).map((item) => (
                            <span className="tag tag-blue3 mb-1 mr-1">
                              {item}
                            </span>
                          ))}
                          {candidate.primarySkills.length > 2 &&
                            candidate.ViewAllSkill === true &&
                            candidate.primarySkills
                              .slice(2, candidate.primarySkills.length)
                              .map((item) => (
                                <span className="tag tag-blue3  mb-1 mr-1">
                                  {item}
                                </span>
                              ))}
                          {candidate.primarySkills.length > 2 && (
                            <button
                              onClick={(e) => onSkillClick(e, candidate)}
                              type="button"
                              className="btn btn-text"
                            >
                              {candidate.ViewAllSkill === false ? (
                                <span>View all skills</span>
                              ) : (
                                <span>Show Less</span>
                              )}
                            </button>
                          )}
                        </div>
                      </div>

                      <div className="mt-1 d-block ">
                        {console.log("candidate.statusData", candidate)}
                        {candidate.statusData.map((item, index) => {
                          if (index < 2 || candidate.candidateID === canId)
                            if (
                              item.statusId === "Auto" ||
                              item.statusId == "33"
                            ) {
                              return;
                            } else if (item.statusId === "6")
                              return (
                                <Link
                                  to={`requests/${item?.jobId}/${
                                    item.statusId !== "Auto" ? item.statusId : 0
                                  }`}
                                >
                                  {item.statusName !== "Invited" && (
                                    <span className="tag tag-green3 mr-2 mb-1">
                                      <strong>
                                        {item.statusName === "Pending"
                                          ? "Offered"
                                          : item.statusname === "Offer Rejected"
                                          ? "Disqualified"
                                          : item.statusName}
                                        : {item.jobTitle}
                                      </strong>
                                    </span>
                                  )}
                                </Link>
                              );
                            else if (item.statusId === "Auto") {
                              return (
                                <Link
                                  to={`requests/${item.jobId}/${
                                    item.statusId !== "Auto" ? item.statusId : 0
                                  }`}
                                >
                                  <div class="mb-1">
                                    <div class="tag tag-green1 mr-1">
                                      <strong>
                                        {item.statusName === "Pending"
                                          ? "Offered"
                                          : item.statusname === "Offer Rejected"
                                          ? "Disqualified"
                                          : item.statusName}
                                        : {item.jobTitle}
                                      </strong>
                                    </div>
                                  </div>
                                </Link>
                              );
                            } else
                              return (
                                <Link
                                  to={`requests/${item?.jobId}/${
                                    item.statusId !== "Auto" ? item.statusId : 0
                                  }`}
                                >
                                  <span className="tag tag-blue3 mr-2 mb-1">
                                    <strong>
                                      {item.statusName === "Pending"
                                        ? "Offered"
                                        : item.statusname === "Offer Rejected"
                                        ? "Disqualified"
                                        : item.statusName}
                                      : {item.jobTitle}
                                    </strong>
                                  </span>
                                </Link>
                              );
                          else return true;
                        })}
                        {candidate.candidateID !== canId ? (
                          candidate.statusData.filter(
                            (elem) =>
                              elem.statusId !== "Auto" && elem.statusId !== "33"
                          ).length > 2 && (
                            <div
                              className="tag"
                              onClick={() => setCanId(candidate.candidateID)}
                            >
                              +
                              {candidate.statusData.filter(
                                (elem) =>
                                  elem.statusId !== "Auto" &&
                                  elem.statusId !== "33"
                              ).length - 2}{" "}
                              more
                            </div>
                          )
                        ) : candidate.candidateID == canId ? (
                          <div className="tag" onClick={() => setCanId(null)}>
                            show less
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body font-regular d-flex flex-column align-items-end justify-content-center col-4">
                  {/* {candidate.candidateTenant !== "" &&
                  candidate.candidateTenant && (
                    <div className="mb-1">
                      Company name: {candidate.candidateTenant}
                    </div>
                  )} */}
                  {/* {candidate.source !== "" && candidate.source && (
                    <div className="mb-1"> 
                   {candidate.source}{" "}
                  
                  </div>
               )}*/}
                  {candidate.workPhone && (
                    <div class="mb-1">
                      Contact no:{" "}
                      {parsePhoneNumber(
                        candidate.workPhone[0] === "+"
                          ? candidate.workPhone
                          : `+${candidate.workPhone}`
                      )?.formatInternational()}{" "}
                    </div>
                  )}{" "}
                  {candidate.email !== "" && candidate.email && (
                    <div className="mb-1">
                      Email: {candidate.email}{" "}
                      {/* <button
                        type="button"
                        title="Copy Email"
                        className="btn btn-text"
                        onClick={() => {
                          handleCopy(candidate);
                        }}
                      >
                        <i className="fa fa-copy"></i>
                      </button> */}
                    </div>
                  )}
                  {/* <div className="mb-1">Tags: Tags will appear here</div> */}
                  {candidate.preferredSalary !== "" &&
                    candidate.preferredSalary && (
                      <div class="mb-1">
                        Preferred annual salary:{" "}
                        {CurrencySymbols[
                          candidate.preferredSalaryCurrency.slice(0, 3)
                        ]
                          ? CurrencySymbols[
                              candidate.preferredSalaryCurrency.slice(0, 3)
                            ]
                          : ""}
                        {Number(candidate.preferredSalary).toLocaleString()}{" "}
                        {CurrencySymbols[
                          candidate.preferredSalaryCurrency.slice(0, 3)
                        ]
                          ? ""
                          : candidate.preferredSalaryCurrency.slice(0, 3)}
                      </div>
                    )}
                  {candidate.minContractRate !== "" &&
                    candidate.minContractRate && (
                      <div class="mb-1">
                        Preferred hourly salary:{" "}
                        {CurrencySymbols[
                          candidate.minContractRateCurrency.slice(0, 3)
                        ]
                          ? CurrencySymbols[
                              candidate.minContractRateCurrency.slice(0, 3)
                            ]
                          : candidate.minContractRateCurrency.slice(0, 3)}
                        {Number(candidate.minContractRate).toLocaleString()}
                        {CurrencySymbols[
                          candidate.minContractRateCurrency.slice(0, 3)
                        ]
                          ? ""
                          : candidate.minContractRateCurrency.slice(0, 3)}
                      </div>
                    )}
                  <div className="mb-1">
                    Source channel:
                    {candidate.sourceChannel === "Career Page" ? (
                      <span
                        className=""
                        data-toggle="tooltip"
                        data-placement="top"
                        title={`${candidate.sourceChannel}`}
                      >
                        {" "}
                        Recruiter market place
                      </span>
                    ) : (
                      <span
                        className=""
                        data-toggle="tooltip"
                        data-placement="top"
                        title={`${candidate.sourceChannel}`}
                      >
                        {" "}
                        Internal
                      </span>
                    )}
                    {/* {
                      candidate.reffererName ? (
                      <span
                        className=""
                        data-toggle="tooltip"
                        data-placement="top"
                        title={`${candidate.reffererName}`}
                      >
                        {" "}
                        {`Refferer`}
                      </span>
                    ) : candidate.sourceChannel &&
                      candidate.sourceChannel === "Career Page" ? (
                      candidate.sourceChannel
                    ) : (
                      <span
                        className=""
                        data-toggle="tooltip"
                        data-placement="top"
                        title={`${candidate.sourceChannel}`}
                      >
                        {" "}
                        {`Agency(${candidate.recruiterAgency})`}
                      </span>
                    )} */}
                  </div>
                  {candidate.createdByName !== "" && candidate.createdByName && (
                    <div className="mb-1">
                      Created by: {candidate.createdByName}
                      {/* {", "}
                      {candidate.candidateTenant} */}
                    </div>
                  )}
                </div>
              </div>
              <div className="p-2 border-top text-end">
                {candidate.updatedDate !== "" &&
                  new Date() > new Date(candidate.updatedDate) && (
                    <div class="text-muted my-auto p-0">
                      Last updated: &nbsp;
                      <i class="far fa-clock"></i>&nbsp;
                      <span>
                        {moment(new Date(candidate.updatedDate), "MMDDYYYY")
                          .fromNow()
                          .charAt(0) === "a"
                          ? moment(new Date(candidate.updatedDate), "MMDDYYYY")
                              .fromNow()
                              .charAt(0)
                              .toLocaleUpperCase() +
                            moment(new Date(candidate.updatedDate), "MMDDYYYY")
                              .fromNow()
                              .slice(1)
                          : moment(
                              new Date(candidate.updatedDate),
                              "MMDDYYYY"
                            ).fromNow()}
                      </span>
                      &nbsp;
                      {/* {dataDiff(candidate.updatedDate, new Date()) > 1 ? 'Days' : 'Day'} &nbsp;ago */}
                    </div>
                  )}
              </div>
            </a>
          ))}
          <div className="d-flex justify-content-end pt-4 px-1">
            <div className="d-flex">
              <div className="ml-auto mx-auto-370 d-flex align-items-center">
                <button
                  onClick={() => {
                    setPageType("down");
                    props.setCurrentPage((page) => page - 1);
                  }}
                  type="button"
                  disabled={props.currentPage === 1 ? true : false}
                  className="btn btn-icon px-2"
                >
                  <i className="fal fa-chevron-circle-left"></i>
                </button>
                {paginationGroup.map((item) => (
                  <button
                    type="button"
                    className={
                      props.currentPage === item
                        ? "btn btn-text px-2 bg-secondary text-white"
                        : "btn btn-text px-2"
                    }
                    onClick={changePage}
                  >
                    {item}
                  </button>
                ))}
                <button
                  onClick={() => {
                    setPageType("up");
                    props.setCurrentPage((page) => page + 1);
                  }}
                  type="button"
                  disabled={
                    props.currentPage === requestTitleArray.slice(-1)[0]
                      ? true
                      : false
                  }
                  className="btn btn-icon px-2"
                >
                  <i className="fal fa-chevron-circle-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showCandidateProfile && (
        <CandidateView
          candidateId={candidateId}
          candidateData={candidateData}
          handleSuccess={handleModel}
        />
      )}
      <Modal show={callNoteData} size="lg">
        <Modal.Header>
          <button
            type="button"
            className="close p-0 bl-modal-close-btn"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setCallNoteData(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body className="mt-2">
          <CallNote candidateData={candidate} />
        </Modal.Body>
      </Modal>
    </>
  );
}
export default CandidateListView;
