import React from "react";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <div
      class="col-xl-3 col-lg-6 col-md-6 col-sm-12 d-flex"
      data-bs-toggle="tooltip"
      data-bs-placement="left"
    >
      <a
        href="/high5Hire/jobTemplate"
        class="card col card-tag-bottom mb-4 card-lg card-candidates-grid p-relative"
      >
        <div class="card-body">
          <div class="d-flex mb-2 align-items-center">
            <div class="mr-3">
              <div class=" sb-avatar sb-avatar--text">
                <div class=" sb-avatar__text">
                  <i class="far fa-clipboard-list"></i>
                </div>
              </div>
            </div>
            <div>
              <h6 class="mb-0">Job Templates</h6>
            </div>
          </div>
          <hr />
          <div class="designation-city-status font-regular font-primary font-gray1 ht-60">
            Job Templates are used to create jobs quickly. Explore the available
            templates to review, edit, or create new ones.
          </div>
          <div
            class="candidates-grid-request font-regular mt-2"
            data-bs-toggle="tooltip"
            data-bs-placement="left"
            title="Coming Soon"
          >
            <Link> Manage Templates</Link>
          </div>
        </div>
      </a>
    </div>
  );
};

export default Index;
