import React, { useState } from "react";
import { post } from "../../service/ApiService";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import moment from "moment";
import imageLoader from "../../images/button-loader.gif";

function EditRecruiterMail({
  close,
  recruiterData,
  fetchGigRecruiters,
  setShowEditEmailModal,
}) {
  const [loading, setLoading] = useState(false);
  const [recruiterMail, setRecruiterEmail] = useState(recruiterData.email);
  const [submitClicked, setSubmitClicked] = useState(false);

  const updateDateHandler = async (e) => {
    setSubmitClicked(true);
    let payload = {
      currentEmail: recruiterData.email,
      updatedEmail: recruiterMail,
      firstName: recruiterData.firstName,
      status: recruiterData.status,
    };

    const res = await post("/gigrecruiter/updateRecruiterMail", payload);
    if (res.status === 200) {
      toast.success("Email updated successfully");
      setLoading(false);
      setShowEditEmailModal(false);
      fetchGigRecruiters();
    } else {
      setLoading(false);
      toast.error("Error occurred while updating email");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="bl-modal-body text-center">
          <div className="card card-flat bg-gray4 mb-5 ml-2  mr-2">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-floating form-date">
                    <label>Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="recruiterName"
                      defaultValue={
                        recruiterData.firstName + " " + recruiterData.LastName
                      }
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-floating form-date">
                    <label>Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="recruiterEmail"
                      defaultValue={recruiterMail}
                      //   onChange={handleInputChange}
                      onChange={(e) => setRecruiterEmail(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              data-dismiss="modal"
              onClick={() => setShowEditEmailModal(false)}
              disabled={submitClicked}
            >
              Cancel
            </button>
            <button type="button" className="btn btn-primary btn-sm">
              {submitClicked === false ? (
                <span onClick={updateDateHandler}>Update</span>
              ) : (
                <span>
                  Updating
                  <img width="20px" src={imageLoader} alt="blank" />
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditRecruiterMail;
