import React, { useEffect, useState } from "react";
import Card from "./Card";
import Talents from "../../talent/talentcommunity/Index";
import Clients from "./Clients/Clients";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getDashboardCount } from "../../../../actions/dashboard/DashboardCount";
import Loader from "../../../Loader/Loader";

const CSMDashboard = () => {
  const dispatch = useDispatch();
  const cardStyle = {
    minWidth: "640px",
  };
  const [view, setView] = useState({
    talents: true,
    recruiters: false,
    clients: false,
    users: false,
  });

  const { loading, dashboardCount } = useSelector(
    (state) => ({
      loading: state.getDashboardCountReducer.loading,
      dashboardCount: state.getDashboardCountReducer.dashboardCount,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getDashboardCount());
  }, []);

  const getCount = (type) => {
    const count =
      dashboardCount && dashboardCount.filter((item) => item.Type == type)[0];
    return count?.TotalCount;
  };

  const getPercentage = (type) => {
    const count =
      dashboardCount && dashboardCount.filter((item) => item.Type == type)[0];
    if (count) {
      return Math.round(
        (count?.NumberOfUsersCreated / count?.TotalCount) * 100
      );
    } else {
      return 0;
    }
  };

  return (
    <div className="px-5">
      {loading && <Loader />}
      <div className="gap-3 overflow">
        <div className="d-flex gap-4 mb-2 mb-xl-0 py-4">
          <Card
            view={view}
            cardStyle={cardStyle}
            setView={setView}
            elem={{
              heading: "Talent",
              title: "Talent",
              count: getCount("TotalTalentCount") || 0,
              changes: `${getPercentage("TotalTalentCount") || 0}% `,
              tooltip:
                "Total number of talents registered in High5Hire. This does not include the talents invited to jobs or those who have received invitations but not register on the platform.",
            }}
            isFrom="MPMDashboard"
          />
          <Card
            view={view}
            cardStyle={cardStyle}
            setView={setView}
            elem={{
              heading: "Clients",
              title: "Clients",
              count: getCount("ClientCount") || 0,
              changes: `${getPercentage("ClientCount") || 0}% `,
              tooltip: "Total number of clients registered on High5Hire.",
            }}
            isFrom="MPMDashboard"
          />
        </div>
      </div>{" "}
      <hr className="m-0 p-0" />
      <div>
        {view?.talents && <Talents />}
        {view?.clients && <Clients clientList={[]} />}
      </div>
    </div>
  );
};

export default CSMDashboard;
