import React, { useState } from "react";
import Skills from "./Skills";
import { Modal } from "react-bootstrap";

const PrimarySkills = ({
  addJobData,
  setAddJobData,
  autocompleteRenderInput,
  primarySkillinput,
  setPrimarySkillInput,
  edit,
  addJobVal,
}) => {
  const [showSkills, setShowSkills] = useState(false);
  const [skills, setSkills] = useState(
    addJobData?.skills ? addJobData?.skills : ""
  );
  const [inputList, setInputList] = useState(
    addJobData?.primarySkills.length !== 0 ? addJobData?.primarySkills : [[""]]
  );

  const handleSkills = (e) => {
    setShowSkills(false);
    const filteredArray = inputList
      ?.map((sublist) => sublist?.filter((item) => item?.trim()))
      ?.filter((sublist) => sublist?.length > 0);
    // const filteredArray = cleanedList.filter((subarray) =>
    //   subarray.some((item) => item !== "")
    // );
    const formattedString = convertToFormattedString(inputList);
    setSkills(formattedString);
    setAddJobData({
      ...addJobData,
      skills: formattedString,
      primarySkills: filteredArray,
      weightage: {
        ...addJobData.weightage,
        primarySkills: filteredArray,
      },
    });
  };
  const convertToFormattedString = (inputList) => {
    const formattedStrings = inputList
      .map((subArray) => {
        const filteredSubArray = subArray.filter(
          (item) => item && item.trim() !== ""
        );
        if (filteredSubArray.length === 1) {
          return filteredSubArray[0];
        } else if (filteredSubArray.length > 1) {
          return `(${filteredSubArray.join(" OR ")})`;
        } else {
          return null;
        }
      })
      .filter((item) => item !== null);

    return formattedStrings.length > 1
      ? `${formattedStrings.join(" AND ")}`
      : formattedStrings[0] || "";
  };
  return (
    <>
      <div
        className={`card card-flat bg-gray4 ml-3 mr-3 mt-3 mb-0 ${
          addJobVal?.primary && skills.trim() === ""
            ? "border border-danger"
            : ""
        }`}
      >
        <div className="card-body py-0">
          <div className="row mb-2">
            <label>Skills*</label>
            {addJobData?.skills && (
              <div className="">
                <input
                  type={"text"}
                  className="form-control"
                  placeholder="Enter Job Title"
                  value={addJobData?.skills}
                  disabled
                />
              </div>
            )}
            <div>
              <button
                className="btn btn-sm mt-1"
                onClick={() => {
                  setShowSkills(true);
                  setInputList(
                    addJobData?.primarySkills.length !== 0
                      ? addJobData?.primarySkills
                      : [[""]]
                  );
                }}
                disabled={edit}
              >
                Add Skills
              </button>
            </div>
          </div>
        </div>
      </div>
      {addJobVal?.primary && skills.trim() === "" ? (
        <div className="validation ml-4" style={{ fontSize: "11px" }}>
          *Required field
        </div>
      ) : null}
      <Modal show={showSkills} size="lg">
        <Modal.Header className="bg-gray4">
          <h6>Skills</h6>
          <buttons
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowSkills(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </buttons>
        </Modal.Header>
        <Modal.Body className="mt-3 p-2">
          <Skills
            addJobData={addJobData}
            setAddJobData={setAddJobData}
            autocompleteRenderInput={autocompleteRenderInput}
            primarySkillinput={primarySkillinput}
            setPrimarySkillInput={setPrimarySkillInput}
            setSkills={setSkills}
            inputList={inputList}
            setInputList={setInputList}
            skills={skills}
          />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm btn-secondary"
            data-dismiss="modal"
            onClick={() => setShowSkills(false)}
          >
            Cancel
          </button>
          <button
            className="btn btn-sm"
            onClick={(e) => handleSkills(e)}
            disabled={inputList.every((arr) =>
              arr.every((value) => value.trim() === "")
            )}
          >
            Confirm Skills
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PrimarySkills;
