import { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import FromA from "../../disposition/applyJob_v2/index"; //_________________________________Form A
import FromB from "../../disposition/applyJob_v2_Parsing/index"; //______________________Form B
const ApplyNow = ({
  job,
  loginData,
  socialMediaApply,
  setSocialMediaApply,
}) => {
  const FROMTYPES = ["A", "A"];
  const [RANDOM, setRANDOM] = useState(
    FROMTYPES[Math.floor(Math.random() * FROMTYPES.length)]
  );
  const [apply, setApply] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [modalSize, setModalSize] = useState("login");

  useEffect(() => {
    console.log("apply", apply);
  }, [apply]);

  useEffect(() => {
    setApply(socialMediaApply);
    console.log("socialMediaApply", socialMediaApply);
  }, [socialMediaApply]);
  const handleLogin = () => {
    setModalSize("login");
    setApply(true);
    setLoggedIn(true);
  };
  const handleCancel = () => {
    //if(sessionStorage.getItem("token") == null ){
    setApply(false);
    setModalSize("apply");
    setSocialMediaApply(false);
  };
  const renderForm = () => {
    return RANDOM === "A" ? (
      <FromA
        job={job}
        setModalSize={setModalSize}
        setApply={setApply}
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
        loginData={loginData}
        isFrom={socialMediaApply ? "socialMediaApply" : ""}
        socialMediaApply={socialMediaApply}
        setSocialMediaApply={setSocialMediaApply}
      />
    ) : (
      <FromB
        job={job}
        setModalSize={setModalSize}
        setApply={setApply}
        setLoggedIn={setLoggedIn}
        loggedIn={loggedIn}
        loginData={loginData}
        isFrom={socialMediaApply ? "socialMediaApply" : ""}
        socialMediaApply={socialMediaApply}
        setSocialMediaApply={setSocialMediaApply}
      />
    );
  };
  return (
    <div className="main_apply_container">
      <div className="main_apply_container_apply">
        <div>
          <p style={{ fontWeight: "bold", fontSize: "10px" }}>
            Apply now to explore this exciting job opportunity.
          </p>
          <button
            className="main_apply_container_apply_btn1 btn"
            style={{ border: "none" }}
            onClick={() => {
              sessionStorage.getItem("userId") == null
                ? setApply(true)
                : handleLogin();
            }}
          >
            <span>Apply Now</span>
          </button>
        </div>
      </div>

      <Modal
        show={apply}
        size={modalSize == "apply" ? "xl" : "lg"}
        onHide={() => {
          setApply(false);
        }}
      >
        <Modal.Header>
          <div style={{ zIndex: "1000" }}>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                handleCancel(false);
              }}
              className="close p-0 bl-modal-close-btn mx-1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>{renderForm(socialMediaApply)}</Modal.Body>
      </Modal>
    </div>
  );
};

export default ApplyNow;
