import moment from "moment";
import React, { useState } from "react";

const ReportTable = ({ getPaginatedData, reportHeaders }) => {
  const [showAllRows, setShowAllRows] = useState(
    Array(getPaginatedData().length).fill(false)
  );

  const toggleShowAll = (index) => {
    setShowAllRows((prevShowAllRows) => {
      const newShowAllRows = [...prevShowAllRows];
      newShowAllRows[index] = !newShowAllRows[index];
      return newShowAllRows;
    });
  };

  return (
    <div>
      {getPaginatedData().length > 0 ? (
        <div
          className="px-3 pt-0 table-cards-1024 mt-3"
          style={{
            overflowX: "scroll",
          }}
        >
          <table
            className="table table-hoverabletable-cards-1024"
            id="table-to-xls"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <thead>
              <tr>
                {reportHeaders.map((header, index) => (
                  <th className="w-100" key={index}>
                    {header.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {getPaginatedData().map((item, index) => (
                <tr key={index}>
                  <td>
                    {moment(new Date(item?.jobPostDate)).format("MM/DD/YYYY")}
                  </td>
                  <td>{item?.jobTitle}</td>
                  <td>{item?.jobType}</td>
                  <td>
                    {moment(new Date(item?.expiresOn)).format("MM/DD/YYYY")}
                  </td>
                  <td>{item?.jobLocation}</td>
                  <td>{item?.jobTenant}</td>
                  <td>{item?.jobPostedBy}</td>
                  <td>{item?.jobTenant}</td>
                  {/* <td>{"EndClientCompany"}</td> */}
                  <td>{item?.candidateName}</td>
                  <td>
                    {(item.city != null || item.country !== null) &&
                      `${item.city}, ${item.country} `}
                  </td>
                  <td>{item?.email}</td>
                  <td>{item?.homePhone !== null && item?.homePhone}</td>
                  <td>
                    {item.primarySkills !== null &&
                    JSON.parse(item?.primarySkills).length !== 0 &&
                    JSON.parse(item?.primarySkills).length <= 1
                      ? JSON.parse(item?.primarySkills)
                          .map((skill) => skill)
                          .join(", ")
                      : item.primarySkills !== null &&
                        JSON.parse(item?.primarySkills).length !== 0 &&
                        JSON.parse(item?.primarySkills).length > 1 &&
                        !showAllRows[index]
                      ? JSON.parse(item?.primarySkills)
                          .map((skill) => skill)
                          .slice(0, 1)
                      : item.primarySkills !== null &&
                        JSON.parse(item?.primarySkills).length !== 0 &&
                        JSON.parse(item?.primarySkills).length > 1 &&
                        showAllRows[index]
                      ? JSON.parse(item?.primarySkills)
                          .map((skill) => skill)
                          .join(", ")
                      : null}
                    {item.primarySkills !== null &&
                      JSON.parse(item?.primarySkills).length !== 0 &&
                      JSON.parse(item?.primarySkills).length > 1 && (
                        <button
                          onClick={() => toggleShowAll(index)}
                          type="button"
                          className="btn btn-text"
                        >
                          {!showAllRows[index] ? (
                            <span>View all skills</span>
                          ) : (
                            <span>Show less</span>
                          )}
                        </button>
                      )}
                  </td>
                  <td>
                    {item.secondarySkills !== null &&
                      JSON.parse(item.secondarySkills).length !== 0 &&
                      JSON.parse(item.secondarySkills)
                        .map((skill) => skill)
                        .join(", ")}{" "}
                  </td>
                  {!item?.invitedDate ? (
                    <td>{""}</td>
                  ) : (
                    <td>
                      {moment(new Date(item?.invitedDate)).format("MM/DD/YYYY")}
                    </td>
                  )}

                  <td>{item?.invitedBy}</td>
                  {!item?.submittedDate ? (
                    <td>{""}</td>
                  ) : (
                    <td>
                      {item?.submittedDate
                        ? moment(new Date(item?.submittedDate)).format(
                            "MM/DD/YYYY"
                          )
                        : null}
                    </td>
                  )}
                  {/* <td>{item?.submittedDate && item?.candidateTenant}</td> */}
                  <td>{item?.submittedDate && item?.submittedby}</td>
                  {!item?.shortlistedDate ? (
                    <td>{""}</td>
                  ) : (
                    <td>
                      {moment(new Date(item?.shortlistedDate)).format(
                        "MM/DD/YYYY"
                      )}
                    </td>
                  )}
                  <td>{item?.shortlistedBy}</td>
                  <td>
                    {item?.shortlistedbyRole == 3
                      ? "Recruiter"
                      : item?.shortlistedbyRole == 4
                      ? "Hiring Manager"
                      : item?.shortlistedbyRole == 8
                      ? "Customer Success Manager"
                      : item?.shortlistedbyRole == 6
                      ? "Super Admin"
                      : item?.shortlistedbyRole == 9
                      ? "Marketplace Manager"
                      : item?.shortlistedbyRole == 11
                      ? "High5 Admin"
                      : ""}
                  </td>
                  <td>{item?.shortlistedDate && item?.jobTenant}</td>
                  {!item?.offeredDate ? (
                    <td>{""}</td>
                  ) : (
                    <td>
                      {moment(new Date(item?.offeredDate)).format("MM/DD/YYYY")}
                    </td>
                  )}
                  <td>{item?.offeredBy}</td>
                  <td>
                    {item?.offerredByRole == 3
                      ? "Recruiter"
                      : item?.offerredByRole == 4
                      ? "Hiring Manager"
                      : item?.offerredByRole == 8
                      ? "Customer Success Manager"
                      : item?.offerredByRole == 6
                      ? "Super Admin"
                      : item?.offerredByRole == 9
                      ? "Marketplace Manager"
                      : item?.offerredByRole == 11
                      ? "High5 Admin"
                      : ""}
                  </td>

                  {!item?.hiredOn ? (
                    <td>{""}</td>
                  ) : (
                    <td>
                      {moment(new Date(item?.hiredOn)).format("MM/DD/YYYY")}
                    </td>
                  )}
                  <td>{item?.hiredOn && item?.offeredBy}</td>
                  <td>
                    {item?.hiredOn &&
                      (item?.offerredByRole == 3
                        ? "Recruiter"
                        : item?.offerredByRole == 4
                        ? "Hiring Manager"
                        : item?.offerredByRole == 8
                        ? "Customer Success Manager"
                        : item?.offerredByRole == 6
                        ? "Super Admin"
                        : item?.offerredByRole == 9
                        ? "Marketplace Manager"
                        : item?.offerredByRole == 11
                        ? "High5 Admin"
                        : "")}
                  </td>
                  {!item?.disqualifiedDate ? (
                    <td>{""}</td>
                  ) : (
                    <td>
                      {moment(new Date(item?.disqualifiedDate)).format(
                        "MM/DD/YYYY"
                      )}
                    </td>
                  )}
                  <td>{item?.disqualifiedDate && item?.disqualifiedBy}</td>
                  <td>
                    {item?.disqualifiedDate &&
                      (item?.disqualifiedByRole == 3
                        ? "Recruiter"
                        : item?.disqualifiedByRole == 4
                        ? "Hiring Manager"
                        : item?.disqualifiedByRole == 8
                        ? "Customer Success Manager"
                        : item?.disqualifiedByRole == 6
                        ? "Super Admin"
                        : item?.disqualifiedByRole == 9
                        ? "Marketplace Manager"
                        : item?.disqualifiedByRole == 11
                        ? "High5 Admin"
                        : "")}
                  </td>
                  <td>{item?.reasonForDisqualification}</td>
                  {/* ...other table cells... */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div style={{ visibility: "hidden" }}>
          <div className="text-center">
            <div className="avatar avatar-lg">
              <i
                aria-hidden="true"
                // className="fad fa-folder-open"
              ></i>
            </div>{" "}
            <div className="mt-6"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportTable;
