import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { getUserRole } from "../../../../../../service/AuthService";
import ResumeDownload from "../resume/Index";
import EditJoiningDate from "./EditJoiningDate";
import { getDispositionDetails } from "../../../../../../actions/disposition/Disposition";
import TalentProfile from "../../../../talent/talentprofile/TalentProfile";
import EmailStatus from "../../../../EmailStatus/EmailStatus";
import { getDispositionCount } from "../../../../../../actions/disposition/DispositionCount";
import ProfileImage from "../../../../commoncomponents/profileimage/ProfileImage";

const Hired = (props) => {
  const { jobData, hiredData, view } = props;
  const dispatch = useDispatch();
  const { Id } = useParams();
  const [resume, setResume] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const [showEditJoiningDate, setShowEditJoiningDate] = useState(false);
  const [candidateData, setCandidateData] = useState(false);
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState({});
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState({});

  const handleResume = (data) => {
    setResumeData(data);
    setResume(true);
  };

  const reloadDisposition = () => {
    const payload = {
      jobId: Id,
    };
    const payloadDisposition = {
      jobId: Id,
      status: view,
      limit: 15,
      currentPage: 1,
    };
    dispatch(getDispositionDetails(payloadDisposition));
    dispatch(getDispositionCount(payload));
    setShowEditJoiningDate(false);
  };

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setSelectedCandidate(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };

  return (
    <>
      {hiredData.map((item, i) => (
        <tr key={i}>
          <td
            onClick={() => onProfileClick(item)}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ProfileImage
              imageSrc={item?.profileImagePath}
              imageWidth={"35"}
              imageHeight={"35"}
              avatarSize={"35"}
              avatarRound={"80px"}
              firstName={item?.firstName}
              lastName={item?.lastName}
            />
            <span className="ml-2 font-16">
              {item?.firstName.charAt(0).toUpperCase() +
                item?.firstName.slice(1)}{" "}
              {item?.lastName.charAt(0).toUpperCase() + item?.lastName.slice(1)}
            </span>
          </td>

          <td>{moment(item?.offerAcceptedDate).format("MM/DD/YYYY")}</td>
          <td>
            {moment(item?.joiningDate).format("MM/DD/YYYY")}
            <div
              className="dropdown px-1"
              style={{
                fontSize: "13px",
                cursor: "pointer",
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Edit"
              aria-haspopup="true"
              aria-expanded="false"
              onClick={() => {
                setCandidateData(item);
                setShowEditJoiningDate(true);
              }}
            >
              {item?.joiningDate &&
                getUserRole() === "HiringManager" &&
                jobData.jobStatus !== 23 &&
                jobData.jobStatus !== 26 &&
                jobData.jobStatus !== 39 && (
                  <i className="fal fa-pencil  fa-fw ml-2"></i>
                )}
            </div>
          </td>
          {/* Resume and interview */}
          <td>
            <label className="d-none d-block-1024">
              <strong>Resumes</strong>
            </label>
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Resume Download"
              style={{ padding: "2px" }}
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewResumeDocModal"
                className="btn btn-square btn-gray"
                onClick={() => handleResume(item)}
              >
                <i className="fas fa-file-user" />
              </button>
            </div>
          </td>
          {getUserRole() === "HiringManager" && (
            <td className="no-hover">
              <div
                className="d-inline-block"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View Email Status"
              >
                <div className="btn-container">
                  <button
                    type="button"
                    className="btn btn-square btn-gray"
                    onClick={() => {
                      setEmailData(item?.email);
                      setEmailStatus(true);
                      setSelectedTalent(item);
                    }}
                  >
                    <i className="fa fa-envelope-o" />
                  </button>

                  {item?.latestEmailStatus &&
                    item?.latestEmailStatus !== "noMail" && (
                      <span
                        className={`${
                          item?.latestEmailStatus === "delivered"
                            ? "dot-success"
                            : "dot-failure"
                        }`}
                      />
                    )}
                </div>
              </div>
            </td>
          )}
          {/* <td>
            <label className="d-none d-block-1024">
              <strong>Interviews</strong>
            </label>
          </td> */}
          <td className="no-hover">
            {
              <span className="">
                {props.calculateScore(jobData, item) + "%"}
              </span>
            }
          </td>
        </tr>
      ))}{" "}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="Hired"
          invitedDate={selectedTalent?.invitedDate}
          jobData={jobData}
        />
      )}
      {resume && (
        <ResumeDownload
          resume={resume}
          setResume={setResume}
          resumeData={resumeData}
        />
      )}
      {showEditJoiningDate && (
        <EditJoiningDate
          candidateData={candidateData}
          showEditJoiningDate={showEditJoiningDate}
          setShowEditJoiningDate={setShowEditJoiningDate}
          reloadDisposition={reloadDisposition}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          candidateData={selectedCandidate}
          handleSuccess={handleModel}
          jobData={jobData}
          parent="hiredTab"
          isFrom="hiredTab"
        />
      )}
    </>
  );
};

export default Hired;
