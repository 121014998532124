import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import { toast } from "react-toastify";
import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import {
  LoginSocialGoogle,
  LoginSocialLinkedin,
  LoginSocialFacebook,
} from "reactjs-social-login";
import GoogleLogin from "react-google-login";

const TalentCommunity = (props) => {
  const { id, tenant } = useParams();
  const [checkEmail, setCheckEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [applyWithEmail, setApplyWithEmail] = useState(false);
  const [disableCommunity, setDisableCommunity] = useState(false);

  const validate = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(mailformat) ? true : false;
  };

  const handleLogin = (name, url, email) => {
    toast.success("Logged In Successfully");

    localStorage.setItem("isLoggedIn", "Yes");

    localStorage.setItem("name", name);

    localStorage.setItem("url", url);

    localStorage.setItem("email", email);

    props.setPageReRender(!props.pageReRender);

    setCheckEmail(false);
  };
  const emailExists = async () => {
    if (email == "") {
      toast.error("Please enter your email");
    } else if (!validate(email)) {
      toast.error("Enter a valid email address");
    } else {
      const params = { tenantId: tenant, email: email };

      setLoading(true);
      const res = await post("/checkEmail/isEmailExist", params).then((res) => {
        console.log("res.data", res.data);

        if (res.data.isExist === true) {
          toast.success("You already have joined our talent community");
          setLoading(false);
          setCheckEmail(false);
          setApplyWithEmail(false);
        } else if (res.data.isExist === false) {
          setCheckEmail(false);
          sessionStorage.setItem("email", email);
          window.open(`/careers/${tenant}/joincommunity`, "_self");
          setLoading(false);
          setApplyWithEmail(false);
        }
      });
    }
  };

  // useEffect(() => {
  //   let token = sessionStorage.getItem("token") === null ? true : false;
  //   setDisableCommunity(token);
  // }, []);
  // useEffect(() => {}, [disableCommunity]);
  return (
    <>
      {loading && <Loader />}
      <div>
        <h5 className="text-secondary text-center mt-4 mb-1">
          We're always on the lookout for talent
        </h5>
        <div className="row mb-3">
          <div className="col-md-7 mx-auto">
            <div className="card p-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-7">
                    <span>
                      If you are thinking about a future with us, sign up to
                      stay connected and be informed of any new opportunities
                    </span>
                  </div>
                  <div className="col-md-5 ">
                    {/* <Link to={`/careers/${tenant}/joincommunity`}> */}
                    <button
                      className="btn btn-sm float-end"
                      onClick={() => {
                        setCheckEmail(true);
                      }}
                      // disabled={disableCommunity}
                    >
                      Join our Talent community
                    </button>
                    {/* </Link> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={checkEmail}>
        <Modal.Header>
          <div>
            <h6>Join With</h6>
          </div>
          <div>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setCheckEmail(false);
                setApplyWithEmail(false);
              }}
              class="close p-0 bl-modal-close-btn mx-1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          {/* <div className="d-flex flex-column mx-5 mb-3 "> */}
          {/* Ask for email */}
          <div className="d-flex flex-column mx-5 mb-3 ">
            <GoogleLogin
              clientId="519239579073-tt3kushg6kpichbf8m7u84reocsq14oh.apps.googleusercontent.com"
              onSuccess={(res) => {
                let { name, imageUrl, email } = res.profileObj;
                handleLogin(name, imageUrl, email);
              }}
              onFailure={(res) => console.log(res)}
              render={(renderProps) => (
                <button
                  className="btn btn-secondary m-2 w-100"
                  onClick={renderProps.onClick}
                >
                  <FcGoogle /> Join with Google
                </button>
              )}
            />
            <LoginSocialFacebook
              appId="631575145234560"
              onLoginStart={() => console.log("Starting")}
              onResolve={({ provider, data }) => {
                let { email, name, picture } = data;
                handleLogin(name, picture.data.url, email);
              }}
              onReject={(err) => {
                console.log("Error", err);
              }}
              autoLoad={true}
            >
              <button className="btn btn-secondary m-2 w-100">
                <BsFacebook /> Join with Facebook
              </button>
            </LoginSocialFacebook>
            {/* <LoginSocialLinkedin client_id="86hjfnxy6nfook">
              <button className="btn btn-secondary m-2 w-100" disabled>
                <BsLinkedin /> Apply with Linkedin
              </button>
            </LoginSocialLinkedin> */}
            <button
              className="btn btn-secondary m-2 w-100"
              onClick={() => {
                // setLoginWithEmail(false);
                setApplyWithEmail(true);
                // setEmailExist(false);
              }}
            >
              <MdEmail className="" /> Join with Email
            </button>
          </div>
          {applyWithEmail && (
            <div>
              <div className="d-flex flex-column mx-5 my-3">
                <div className="form-floating ml-1">
                  <input
                    type="text"
                    className={"form-control"}
                    name="groupName"
                    placeholder="Enter a group name"
                    onChange={(e) => setEmail(e.target.value)}
                    // value={groupData?.poolName}
                  />
                  <label>Email*</label>
                </div>
              </div>

              {/* <div className="row mx-5">
                <button className="btn btn-secondary m-2 w-100">Login</button>
              </div> */}

              <div className="row mx-5 mb-2">
                <button
                  className="btn btn-secondary m-2 w-100"
                  onClick={
                    () => emailExists()
                    // window.open(`/careers/${tenant}/joincommunity`, "_self")
                  }
                  disabled={email == "" && true}
                >
                  Submit
                </button>
              </div>
            </div>
          )}
          {/* </div> */}

          {/* Login */}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TalentCommunity;
