import React from "react";
import Avatar from "react-avatar";
import {
  getUserRole,
  getCandidateName,
} from "../../../../../service/AuthService";
import moment from "moment";
import { useState } from "react";
import Loader from "../../../../Loader/Loader";
import Modal from "react-bootstrap/Modal";

const Notes = ({
  jobCategoryOptions,
  notesDetails,
  notesValue,
  setNotesValue,
  setNotesDetails,
  saveTemplate,
  setSaveTemplate,
  setOverWrite,
  setTemplateName,
  setTemplateCategory,
  addJobVal,
  setAddJobVal,
  skillSetOption,
  templateDetails,
  overWrite,
  edit,
  addTemplate,
  editTemplate,
  templateName,
  templateCategory,
}) => {
  const [deletePopUp, setDeletePopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createdDate, setCreatedDate] = useState("");
  const handleClose = () => {
    setDeletePopUp(false);
  };
  const deleteButtonpopUp = () => {
    let arr = notesDetails.filter((f) => f.createdDate != createdDate);
    setNotesDetails(arr);
    setDeletePopUp(false);
  };

  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
              <h6>Add Notes</h6>
            </div>
          </div>
          <hr></hr>
          <div className="form-floating">
            <textarea
              onChange={(e) => setNotesValue(e.target.value)}
              value={notesValue}
              className="form-control"
            />
          </div>
          <div className="d-flex justify-content-end">
            <div>
              <button
                className="btn btn-sm ml-auto mt-2 mr-2"
                disabled={notesValue ? false : true}
                onClick={() => {
                  setNotesValue("");
                }}
              >
                Delete
              </button>
              <button
                className="btn btn-sm ml-auto mt-2"
                disabled={notesValue ? false : true}
                onClick={() => {
                  let obj = {
                    comments: notesValue,
                    name: getCandidateName(),
                    role: getUserRole(),
                    createdDate: new Date(),
                    updatedDate: "",
                  };
                  setNotesDetails([...notesDetails, obj]);
                  setNotesValue("");
                }}
              >
                Add
              </button>
            </div>
          </div>
          <div className="row m-2">
            <div className="col-lg-12">
              {notesDetails.length > 0 &&
                notesDetails.map((item, id) => (
                  <div className="d-flex mb-2">
                    <Avatar size="30" name={item.name} round="60px" />
                    <div className="d-flex justify-content-between w-100">
                      <div className="ml-2">
                        <p className="m-0">
                          <strong>{item.name}</strong>
                          <small className="ml-2">
                            {moment(item.createdDate).format("MM/DD/YYYY")}
                          </small>
                          <p className="">
                            <small>{item.comments}</small>
                          </p>
                        </p>
                      </div>
                      {/* <div>
                        <button
                          className="btn btn-sm ml-auto mt-2 mr-2"
                          onClick={() => {
                            setDeletePopUp(true);
                            setCreatedDate(item.createdDate);
                          }}
                        >
                          Delete
                        </button>
                      </div> */}
                      <div title="Delete" style={{ display: "contents" }}>
                        <button
                          type="button"
                          onClick={() => {
                            setDeletePopUp(true);
                            setCreatedDate(item.createdDate);
                          }}
                          className="btn btn-text-accent mr-2"
                          title="Delete"
                        >
                          <i
                            className="fal fa-trash-alt fa-fw"
                            aria-hidden="true"
                            style={{ color: "#ff2600" }}
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>

      {/* SAVE TEMPLATE */}
      {!edit && !(addTemplate || editTemplate) && (
        <>
          <hr />
          <div className="m-3">
            <div className="form-check form-switch d-flex align-items-center">
              <label className="form-check-label pt-0">
                Do you want to save this job as a template for future use?
              </label>
              <input
                className="form-check-input ml-2"
                type="checkbox"
                checked={saveTemplate}
                onChange={(e) => {
                  setSaveTemplate(e.target.checked);
                  setOverWrite(false);
                  setTemplateName("");
                }}
              />
            </div>
            {saveTemplate && (
              <div className="card card-flat bg-gray4 m-3">
                <div className="card-body">
                  <div className="row font-14">
                    <div className="col-lg-7">
                      <div className="form-floating">
                        <input
                          type="text"
                          className={
                            addJobVal.template
                              ? "form-control border border-danger"
                              : "form-control"
                          }
                          name="Template"
                          placeholder="Enter a job title"
                          onChange={(e) => {
                            if (addJobVal.template) {
                              setAddJobVal({ ...addJobVal, template: "" });
                            }
                            setTemplateName(e.target.value);
                            setOverWrite(false);
                          }}
                          value={templateName}
                        />
                        <label>Template Name*</label>
                        <small className="validation ml-2">
                          {addJobVal.template}
                        </small>
                      </div>
                    </div>
                    <div className="col-lg-5">
                      <div className="form-floating ml-1">
                        <select
                          className="form-select"
                          onChange={(e) => setTemplateCategory(e.target.value)}
                          value={templateCategory}
                        >
                          <option value="">Select</option>
                          {jobCategoryOptions
                            .map((i) => i.value)
                            .map((item) => (
                              <option
                                // selected={item === templateCategory}
                                value={item}
                              >
                                {item}
                              </option>
                            ))}
                        </select>
                        <label>Template Category</label>
                      </div>
                    </div>
                  </div>
                  {!overWrite &&
                    templateDetails.templateType === "User" &&
                    addJobVal.template === "This name already exists" && (
                      <div>
                        <button
                          className="btn btn-sm mt-2"
                          onClick={() => {
                            if (addJobVal.template) {
                              setAddJobVal({ ...addJobVal, template: "" });
                            }
                            setOverWrite(true);
                          }}
                        >
                          Overwrite with existing Template
                        </button>
                      </div>
                    )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {loading && <Loader />}
      <Modal
        show={deletePopUp}
        //onHide={() => handleClose(false)}
        size="delete"
      >
        <Modal.Body>
          <div>
            <div className="bl-modal-body  text-center">
              <div className="avatar avatar-lg avatar-red2 mr-3">
                <i class="fas fa-exclamation-triangle fa-fw"></i>
              </div>
              <p className="text-center lead mt-3">
                Are you sure you want to delete?
              </p>
              <div class="text-center my-4">
                <button
                  type="button"
                  className="btn btn-secondary mr-3"
                  data-dismiss="modal"
                  onClick={() => handleClose()}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="close p-0 bl-modal-close-btn"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => handleClose()}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => deleteButtonpopUp()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Notes;
