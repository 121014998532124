import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";

const DDMS = ({
  handleFilterToggle,
  handleAPICalls,
  filterEnabled,
  filterStatus,
  filterName,
  options,
  isMulti,
  askCountry,
  selectedData,
  setSelectedData,
  applyFilter,
  fetchOptions,
  hideDropdown,
  isLoading,
  countries,
  loading,
  talentCountloading,
}) => {
  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),

    dropdownIndicator: (provided) =>
      hideDropdown
        ? {
            ...provided,
            display: "none",
          }
        : { ...provided },
    indicatorSeparator: () => ({
      display: "none", // Hide the indicator separator
    }),
  };

  const handleChange = (val) => {
    setSelectedData({
      ...selectedData,
      [filterStatus]:
        filterStatus === "sourceChannel" ||
        filterStatus === "status" ||
        filterStatus === "subscriptionStatus"
          ? [val]
          : filterStatus === "jobId"
          ? !Array.isArray(val)
            ? [val]
            : val
          : val,
      workAuthCountry:
        filterStatus === "workAuthorisation" &&
        [val].some(
          (x) =>
            x.label === "Permanent Resident" ||
            x.label === "Citizen" ||
            x.label === "Authorised to work"
        )
          ? selectedData.workAuthCountry
          : "",
    });
    applyFilter(val, filterStatus);
  };
  const handleCountry = (val) => {
    setSelectedData({
      ...selectedData,
      workAuthCountry: val,
    });
    applyFilter(val, "workAuthCountry");
  };

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    let timeoutId;

    const fetchData = async () => {
      try {
        await fetchOptions(inputValue, filterStatus);
      } catch (error) {
        console.error("Error fetching options:", error);
      } finally {
      }
    };

    const delay = 800;

    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fetchData();
    }, delay);
    return () => clearTimeout(timeoutId);
  }, [inputValue]);

  const handleInputChange = (searchValue) => {
    setInputValue(searchValue);
  };

  const handleKeyDown = (event) => {
    if (
      event.key === "Enter" &&
      filterStatus !== "sourceChannel" &&
      filterStatus !== "status" &&
      filterStatus !== "subscriptionStatus"
    ) {
      event.preventDefault(); // Prevent form submission or any default behavior
      const inputValue = event.target.value.toLowerCase().trim();
      const matchedOptions = options.filter((option) =>
        option.label.toLowerCase().includes(inputValue)
      );
      let data =
        (selectedData[filterStatus] && selectedData[filterStatus]?.slice()) ||
        [];
      matchedOptions.forEach((option) => {
        if (
          !data.some((existingOption) => existingOption.value === option.value)
        ) {
          data.push(option); // Add only if it's not already present
        }
      });
      setSelectedData({
        ...selectedData,
        [filterStatus]: data,
      });
      applyFilter(data, filterStatus);
    }
  };

  const getOptionLabel = (option) => {
    return selectedData[filterStatus].includes(option) &&
      ["jobTitle", "sourceChannel"].includes(filterStatus)
      ? option.value
      : option.label; // Display the value instead of the label
  };

  return (
    <div className="mb-2">
      <button
        type="button"
        className="btn btn-collapsible"
        onClick={() => handleFilterToggle(filterStatus)}
      >
        <i
          className={
            filterEnabled ? "fal fa-angle-up fa-fw" : "fal fa-angle-down fa-fw"
          }
        />
        <span className="p-relative">
          <small>{filterName}</small>
          {(Array.isArray(selectedData[filterStatus])
            ? selectedData[filterStatus].length > 0
            : selectedData[filterStatus] !== "") && (
            <span className="jewel"></span>
          )}
        </span>
      </button>
      {filterEnabled && (
        <div className="d-flex flex-column gap-2 p-2">
          <div className="d-flex flex-column gap-2 p-2">
            <Select
              options={isLoading ? [] : options}
              isSearchable={true}
              onChange={handleChange}
              onInputChange={handleInputChange}
              styles={styleSheet}
              value={selectedData[filterStatus]}
              isMulti={isMulti}
              isLoading={isLoading}
              onKeyDown={handleKeyDown}
              getOptionLabel={getOptionLabel}
              onMenuOpen={() => {
                handleAPICalls(filterStatus);
              }}
              isDisabled={loading || talentCountloading}
              placeholder={filterName}
            />
            {askCountry &&
              selectedData[filterStatus].some(
                (x) =>
                  x.value === "Permanent Resident" ||
                  x.value === "Citizen" ||
                  x.value === "Authorised to work"
              ) && (
                <Select
                  options={countries}
                  isSearchable={true}
                  placeholder="Country"
                  onChange={handleCountry}
                  styles={styleSheet}
                  value={selectedData.workAuthCountry}
                  isMulti={false}
                  isClearable
                />
              )}
          </div>
          <div class="d-flex justify-content-end mt-2 px-1">
            <button
              disabled={selectedData[filterStatus].length === 0}
              className="btn btn-text"
              onClick={() => {
                setSelectedData({
                  ...selectedData,
                  [filterStatus]: [],
                });
                applyFilter([], filterStatus);
              }}
            >
              <span className="font-12">
                <small>Clear {filterName}</small>
              </span>
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DDMS;
