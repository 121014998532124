import {
  GET_CURATOR_INVITE_DETAILS_BEGIN,
  GET_CURATOR_INVITE_DETAILS_SUCCESS,
  GET_CURATOR_INVITE_DETAILS_FAILURE,
  UPDATE_CURATOR_DATA_BEGIN,
  UPDATE_CURATOR_DATA_SUCCESS,
  UPDATE_CURATOR_DATA_FAILURE,
  GET_CURATOR_DATA_BEGIN,
  GET_CURATOR_DATA_SUCCESS,
  GET_CURATOR_DATA_FAILURE,
} from "../../actions/curator/CuratorInvite.js";

const initialState = {
  inviteDetailsLoading: true,
  inviteDetails: [],
  cronJobUpdated: false,
  totalCount: 0,
  cronJobLog: [],
  curatorData: [],
  curatorDataLoading: false,
  curatorUpdateLoading: false,
  curatorUpdate: "",
};

const curatorInviteReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CURATOR_INVITE_DETAILS_BEGIN:
      return {
        ...state,
        inviteDetailsLoading: true,
        inviteDetails: [],
      };
    case GET_CURATOR_INVITE_DETAILS_SUCCESS:
      return {
        ...state,
        inviteDetailsLoading: false,
        inviteDetails: payload,
      };
    case GET_CURATOR_INVITE_DETAILS_FAILURE:
      return {
        ...state,
        inviteDetailsLoading: false,
        inviteDetails: [],
      };
    case UPDATE_CURATOR_DATA_BEGIN:
      return {
        ...state,
        curatorUpdateLoading: true,
        curatorUpdate: "",
      };
    case UPDATE_CURATOR_DATA_SUCCESS:
      return {
        ...state,
        curatorUpdateLoading: false,
        curatorUpdate: "success",
      };
    case UPDATE_CURATOR_DATA_FAILURE:
      return {
        ...state,
        curatorUpdateLoading: false,
        curatorUpdate: "",
      };
    case GET_CURATOR_DATA_BEGIN:
      return {
        ...state,
        curatorDataLoading: true,
        curatorData: [],
      };
    case GET_CURATOR_DATA_SUCCESS:
      return {
        ...state,
        curatorDataLoading: false,
        curatorData: payload,
      };
    case GET_CURATOR_DATA_FAILURE:
      return {
        ...state,
        curatorDataLoading: false,
        curatorData: [],
      };
    default:
      return state;
  }
};

export default curatorInviteReducer;
