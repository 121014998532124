import React, { useState } from "react";

const IndustriesDetails = (props) => {
  const { jobData } = props;
  const [industriesCollapse, setIndustriesCollapse] = useState(false);
  const [viewAll, setViewAll] = useState(false);
  return (
    <>
      {jobData?.industry && JSON.parse(jobData?.industry).length !== 0 && (
        <div className="mb-2">
          <button
            type="button"
            className="btn btn-collapsible"
            onClick={() => setIndustriesCollapse(!industriesCollapse)}
          >
            <i
              aria-hidden="true"
              className={
                industriesCollapse
                  ? "fal fa-angle-up fa-fw"
                  : "fal fa-angle-down fa-fw"
              }
            />
            <span>
              <small>Job Category</small>
            </span>
          </button>
        </div>
      )}

      {industriesCollapse === true && (
        <div className="mb-3 ml-4">
          {JSON.parse(jobData?.industry).map(
            (item, index) =>
              (index < 2 || viewAll) && (
                <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
              )
          )}
          {jobData?.industry &&
            (viewAll ? (
              <div
                className="tag"
                onClick={() => setViewAll(false)}
                style={{ margin: "2px", cursor: "pointer" }}
              >
                Show less
              </div>
            ) : (
              JSON.parse(jobData?.industry)?.length > 2 && (
                <div
                  className="tag"
                  onClick={() => setViewAll(true)}
                  style={{ margin: "2px", cursor: "pointer" }}
                >
                  +{JSON.parse(jobData?.industry).length - 2} more
                </div>
              )
            ))}
        </div>
      )}
    </>
  );
};

export default IndustriesDetails;
