import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Autosuggest from "react-autosuggest";
import currencyCode from '../config/CurrencyCode';
import getSymbolFromCurrency from 'currency-symbol-map'

const hereMapAPIKey = 'DZm9Ia3wXJqJ5I34OnYcH4p_r1G3uOuvnHfTb5QsQwo';
const hereMapAutoCompleteURL = 'https://autocomplete.geocoder.ls.hereapi.com/6.2/suggest.json';

function AddJobHereMap(props) {
    const [autoCompleteData, setAutoCompleteData] = useState([]);
    const [value, setValue] = React.useState("");
    const [suggestions, setSuggestions] = React.useState([]);

    const theme = {
        suggestionsContainerOpen: {
            display: 'block',
            position: 'absolute',
            width: '100%',
            border: '1px solid #aaa',
            listStyle: 'none',
            zIndex: 10,
            backgroundColor: 'rgb(255, 255, 255)',
            fontSize: 14,
            fontFamily: 'sans-serif',
            maxHeight: '250px',
            overflow: 'auto',
            padding: '5px 15px'
        },
        suggestionsList: {
            listStyleType: 'none',
        },
        suggestion: {
            cursor: 'pointer',
            padding: '5px 0px'
        },
        suggestionHighlighted: {
            backgroundColor: 'rgba(114, 112, 112, 0.125)'
        },
        input: {
            border: '1px solid #ced4da',
            padding: '1rem .75rem',
            height: 'calc(3.5rem + 2px)',
            borderRadius: '5px'
        }
    };

    const getSuggestionValue = suggestion => suggestion.name;

    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const suggestValues = inputLength === 0 ? []
            : autoCompleteData.filter(
                item => item.name.toLowerCase().includes(inputValue)
            );
        return suggestValues
    };

    const renderSuggestion = suggestion => <div>{suggestion.name}</div>;

    const onChange = (event, { newValue }) => {
        setValue(newValue);
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => setSuggestions([]);

    const inputProps = {
        placeholder: 'Enter location',
        value,
        onChange,
    };

    const params = {
        apiKey: hereMapAPIKey,
        query: value,
        maxresults: 10,
    }

    const onRemoteSwitch = (e) => {

        // props.setIsRemote
        // (e.target.checked
        //     )
        // if(!e.target.checked) {
        //     props.setAddJobData({ ...props.addJobData, jobLocation: "Remote" })
        // }    
        props.setAddJobData({...props.addJobData , isRemote :e.target.checked})    
    }

    useEffect(() => {
        if (value.length > 3) {
            axios.get(hereMapAutoCompleteURL, { params })
                .then(function (response) {
                    const suggestData = response.data.suggestions;
                    setAutoCompleteData(suggestData.map((item) => { return { name: item.label } }));
                    if (undefined !== suggestData && suggestData.length > 0) {
                        const address = suggestData[0].address;
                        const address2 = address.street ? address.street : '';
                        const zipCode = address.postalCode ? address.postalCode : ''
                        const city = address.city ? address.city : ''
                        let countryCode = suggestData[0].countryCode.slice(0, 2);
                        let currCode = currencyCode.filter((item)=>item.countryCode === countryCode)[0].currencyCode;
                        let currSymbol = getSymbolFromCurrency(currCode);
                        props.setAddJobData({
                            ...props.addJobData,
                            location: {
                                address: address2,
                                postalCode: zipCode,
                                city: city,
                                state: address.state,
                                country: address.country,
                                currSymbol: currSymbol
                            }
                        });
                    }
                });
        }
    }, [value]);

    return (
        <>
            <div className="card card-flat card-borderless bg-gray4 mb-3">
                <div className="card-body">
                    <div className="row">
                        <div className="col-lg-4 d-flex align-items-center mb-2 mb-lg-0 justify-content-end justify-content-lg-start">
                            <div className="form-check form-switch d-flex align-items-center">
                                <label className="form-check-label pt-0" htmlFor="remoteSwitch">Is Remote?</label>
                                <input name="isRemote" className="form-check-input ml-3"   checked={props.addJobData.isRemote} type="checkbox" id="remoteSwitch"
                                    onChange={(e) => onRemoteSwitch(e)}
                                />
                            </div>
                        </div>
                        {props.addJobData.isRemote ? (
                            <div className="col-lg-8">
                                <div className="form-floating">
                                    <input name="location" disabled={true} type="text" className="form-control" placeholder="Enter a job location" />
                                    <label>Onsite Location</label>
                                </div>
                            </div>) : (
                            <div className="col-lg-8">
                                <div className="form-floating">
                                    <Autosuggest
                                        suggestions={suggestions}
                                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                                        getSuggestionValue={getSuggestionValue}
                                        renderSuggestion={renderSuggestion}
                                        inputProps={inputProps}
                                        theme={theme}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddJobHereMap
