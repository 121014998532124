import React, { useState, useEffect } from "react";
import { getInitial, dataDiff } from "../function/CommonFunction";
import vettedIcon from "../../images/high5-mark-circle-dark.svg";
import CandidateView from "../modal/talentprofile/CandidateProfile";
import Loader from "../Loader/Loader";
import Avatar from "react-avatar";
import moment from "moment";
import { countryObj } from "../../constants/Constants";
import { Link } from "react-router-dom";

function CandidateGridview(props) {
  const [showCandidateProfile, setShowCandidateProfile] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [newRequestList, setnewRequestList] = useState([]);
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [requestTitleArray, setRequestTitleArray] = useState([]);
  const [pageType, setPageType] = useState("");
  const [canId, setCanId] = useState(null);
  let pageSize = 5;
  let dataSize = 6;

  const findStatusNameFromList = (status, value) => {
    let result = false;
    status.forEach((item) => {
      if (item.statusId === value) {
        result = true;
      }
    });
    return result;
  };

  const handleModel = () => {
    setShowCandidateProfile(false);
  };

  const onProfileClick = (data) => {
    setShowCandidateProfile(true);
    setCandidateData(data);
  };

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    props.setCurrentPage(pageNumber);
  }

  useEffect(() => {
    if (pageType === "up") {
      if ((props.currentPage - 1) % pageSize === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(
            props.currentPage - 1,
            props.currentPage + (pageSize - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (props.currentPage % pageSize === 0 && props.currentPage !== 1) {
        setPaginationGroup(
          requestTitleArray.slice(
            props.currentPage - pageSize,
            props.currentPage
          )
        );
      }
    }
  }, [props.currentPage]);

  useEffect(() => {
    setnewRequestList(props.arrayList);
    setRequestTitleArray(
      new Array(Math.ceil(props.arrayList.length / dataSize))
        .fill()
        .map((_, i) => i + 1)
    );
    setPaginationGroup(
      new Array(Math.ceil(props.arrayList.length / dataSize))
        .fill()
        .map((_, i) => i + 1)
        .slice(0, pageSize)
    );
  }, [props.arrayList]);

  const getPaginatedData = () => {
    const startIndex = props.currentPage * dataSize - dataSize;
    const endIndex = startIndex + dataSize;
    return newRequestList.slice(startIndex, endIndex);
  };

  return (
    <>
      <div className="row d-flex-1200">
        {getPaginatedData().map((candidate, i) => (
          <>
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-12 d-flex">
              <a
                onClick={(e) => e.preventDefault()}
                className="card col card-tag-bottom mb-4 card-lg card-candidates-grid p-relative"
              >
                {/* {candidate.isVetted && (
                  <div class="tag tag-coral tag-horizontal-status">
                    <img width="16px" alt="" class="mr-1" src={vettedIcon} />
                    Vetted
                  </div>
                )} */}
                <div className="card-body">
                  <div className="d-flex mb-2 align-items-center">
                    <div className="mr-3">
                      {candidate.ImageURL ? (
                        <span class="avatar avatar-bordered">
                          <img alt="" src={candidate.ImageURL} />
                          {findStatusNameFromList(
                            candidate.statusData,
                            "6"
                          ) && <span class="jewel"></span>}
                        </span>
                      ) : (
                        <span>
                          {/* {getInitial(`${candidate.fullText.firstName} ${candidate.fullText.lastName}`)} */}
                          <Avatar
                            size="55"
                            name={
                              candidate.fullText.firstName +
                              " " +
                              candidate.fullText.lastName
                            }
                            round="80px"
                          />
                          {findStatusNameFromList(
                            candidate.statusData,
                            "6"
                          ) && <span></span>}
                        </span>
                      )}
                    </div>
                    <div onClick={() => onProfileClick(candidate)}>
                      <h6 className="mb-0">
                        {candidate.fullText.firstName}{" "}
                        {candidate.fullText.lastName}
                      </h6>
                    </div>
                  </div>
                  <div className="designation-city-status font-regular font-primary font-gray1 mt-1">
                    {candidate.currentJobTitle && (
                      <div class="mb-2">
                        <i class="far fa-suitcase me-2"></i>{" "}
                        {candidate.currentJobTitle}{" "}
                        {candidate.experienceYear !== "0" ? (
                          <span
                            className={
                              candidate?.experience[0]?.employerName
                                ? "ml-1 tag"
                                : "tag"
                            }
                          >
                            {candidate.experienceYear
                              ? candidate.experienceYear * 1
                              : "0"}
                            {"."}
                            {candidate.experienceMonth !== 0
                              ? candidate.experienceMonth * 1
                              : "0"}{" "}
                            {candidate.experienceYear ? "Years" : ""}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {candidate?.addressCity &&
                    candidate?.addressState &&
                    candidate?.country ? (
                      <div class="mb-2">
                        <i class="far fa-map-marker-alt me-2"></i>
                        {candidate.addressCity},{" "}
                        {candidate.addressState == "NA"
                          ? ""
                          : `${candidate.addressState},`}{" "}
                        {candidate.country}
                      </div>
                    ) : (
                      <span>Remote</span>
                    )}

                    {candidate.updatedDate !== "" &&
                      new Date() > new Date(candidate.updatedDate) && (
                        <div class="mb-2">
                          <i class="far fa-clock me-2"></i>Last updated: &nbsp;
                          <span>
                            {moment(
                              new Date(candidate.updatedDate),
                              "MMDDYYYY"
                            ).format("MM/DD/YYYY")}
                          </span>
                          &nbsp;
                          {/* {dataDiff(candidate.updatedDate, new Date()) > 1 ? 'Days' : 'Day'} &nbsp;ago */}
                        </div>
                      )}
                    {candidate.createdByName !== "" && candidate.createdByName && (
                      <div className="mb-1">
                        <i class="far fa-user me-2"></i>Created by:{" "}
                        {candidate.createdByName}
                        {/* {", "}
                      {candidate.candidateTenant} */}
                      </div>
                    )}
                  </div>
                  <div className="candidates-grid-request font-regular">
                    {/* <div className="font-small font-primary mb-2">Request(s)</div> */}
                    {candidate.statusData.length !== 0 && (
                      <>
                        {/* <div class="font-small font-primary mb-2">Requests</div> */}
                        <div>
                          {/* {candidate.statusData.map((item, index) => {
                            if (index < 2 || candidate.candidateID === canId) {
                              if (item.statusId === "6") {
                                return (
                                  <div class="mb-1">
                                    <div class="tag tag-green3">
                                      <strong>
                                        {item.statusName}: {item.jobTitle}
                                      </strong>
                                    </div>
                                  </div>
                                );
                              } else if (item.statusId === "Auto") {
                                return (
                                  <div class="mb-1">
                                    <div class="tag tag-green1 mr-1">
                                      <strong>
                                        {item.statusName}: {item.jobTitle}
                                      </strong>
                                    </div>
                                  </div>
                                );
                              } else {
                                return (
                                  <div class="mb-1">
                                    <div class="tag tag-blue3">
                                      <strong>
                                        {item.statusName}: {item.jobTitle}
                                      </strong>
                                    </div>
                                  </div>
                                );
                              }
                            }
                          })} */}
                          {candidate.statusData.map((item, index) => {
                            if (index < 2 || candidate.candidateID === canId) {
                              if (item.statusId === "6") {
                                return (
                                  <Link
                                    to={`high5hire/requests/${item.jobId}/${
                                      item.statusId !== "Auto"
                                        ? item.statusId
                                        : 0
                                    }`}
                                  >
                                    <div class="mb-1">
                                      <div class="tag tag-green3">
                                        <strong>
                                          {item.statusName === "Pending"
                                            ? "Offered"
                                            : item.statusname ===
                                              "Offer Rejected"
                                            ? "Disqualified"
                                            : item.statusName}
                                          : {item.jobTitle}
                                        </strong>
                                      </div>
                                    </div>
                                  </Link>
                                );
                              } else if (item.statusId === "Auto") {
                                return (
                                  <Link
                                    to={`high5hire/requests/${item.jobId}/${
                                      item.statusId !== "Auto"
                                        ? item.statusId
                                        : 0
                                    }`}
                                  >
                                    <div class="mb-1">
                                      <div class="tag tag-green1 mr-1">
                                        <strong>
                                          {item.statusName === "Pending"
                                            ? "Offered"
                                            : item.statusname ===
                                              "Offer Rejected"
                                            ? "Disqualified"
                                            : item.statusName}
                                          : {item.jobTitle}
                                        </strong>
                                      </div>
                                    </div>
                                  </Link>
                                );
                              } else {
                                return (
                                  <Link
                                    to={`high5hire/requests/${item.jobId}/${
                                      item.statusId !== "Auto"
                                        ? item.statusId
                                        : 0
                                    }`}
                                  >
                                    <div class="mb-1">
                                      <div class="tag tag-blue3">
                                        <strong>
                                          {item.statusName === "Pending"
                                            ? "Offered"
                                            : item.statusname ===
                                              "Offer Rejected"
                                            ? "Disqualified"
                                            : item.statusName}
                                          : {item.jobTitle}
                                        </strong>
                                      </div>
                                    </div>
                                  </Link>
                                );
                              }
                            }
                          })}

                          {candidate.candidateID !== canId ? (
                            candidate.statusData.length > 2 && (
                              <div
                                class="tag"
                                onClick={() => setCanId(candidate.candidateID)}
                              >
                                +{candidate.statusData.length - 2} more
                              </div>
                            )
                          ) : candidate.candidateID == canId ? (
                            <div class="tag" onClick={() => setCanId(null)}>
                              show less
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </a>
            </div>
          </>
        ))}
        <div className="d-flex justify-content-end pt-4 px-1">
          <div className="d-flex">
            <div class="ml-auto mx-auto-370 d-flex align-items-center">
              <button
                onClick={() => {
                  setPageType("down");
                  props.setCurrentPage((page) => page - 1);
                }}
                type="button"
                disabled={props.currentPage === 1 ? true : false}
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-left"></i>
              </button>
              {paginationGroup.map((item) => (
                <button
                  type="button"
                  className={
                    props.currentPage === item
                      ? "btn btn-text px-2 bg-secondary text-white"
                      : "btn btn-text px-2"
                  }
                  onClick={changePage}
                >
                  {item}
                </button>
              ))}
              <button
                onClick={() => {
                  setPageType("up");
                  props.setCurrentPage((page) => page + 1);
                }}
                type="button"
                disabled={
                  props.currentPage === requestTitleArray.slice(-1)[0]
                    ? true
                    : false
                }
                className="btn btn-icon px-2"
              >
                <i className="fal fa-chevron-circle-right"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      {showCandidateProfile && (
        <CandidateView
          handleSuccess={handleModel}
          candidateData={candidateData}
        />
      )}
    </>
  );
}

export default CandidateGridview;
