import React, { useState, useEffect } from "react";
// import TopNavBar from "../layout/TopNavbar";
import SideNavBar from "../../../../layout/SideNavBar";
import "react-tabs/style/react-tabs.css";
import ReportTopNavBar from "../../../../report/ReportTopNavBar";
import { getTenantType, getUserRole } from "../../../../../service/AuthService";
import TopNavBar from "../../../../layout/TopNavBar";
import { useHistory, useLocation, useParams } from "react-router";
import { post } from "../../../../../service/ApiService";
import TalentView from "../TalentView";
import Modal from "react-bootstrap/Modal";
import Loader from "../../../../Loader/Loader";
import AddGroup from "../addgroup/Index";
import AddPool from "../addpool/Index";
import Pagination from "../../../commoncomponents/pagination/Pagination";
import NoRecord from "../../../commoncomponents/norecord/NoRecord";
import OptionModal from "../addpoolmodal/OptionModal";
import Footer from "../../../../layout/Footer";
import FormSubmitSuccess from "../../../../common/Jobs/ViewJobDetails/FormSubmitSuccess";

const TalentCommunity = () => {
  const [toggle, setToggle] = useState(true);
  const [groupData, setGroupData] = useState({});
  const role = getUserRole();
  const history = useHistory();
  const { groupId } = useParams();
  const [editGroup, setEditGroup] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPoolById, setLoadingPoolById] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const [showAddPool, setShowAddPool] = useState(false);
  const [poolList, setPoolList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const viewParam = searchParams.get("view");
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [successData, setSuccessData] = useState({
    title: `Community ${
      getTenantType() === 1 ? "Published" : "Created"
    } Successfully!`,
    subTitle: ``,
    htmlTag: "",
    poolId: "",
  });
  const [currentPage, setCurrentPage] = useState(1);

  const shareClose = (e) => {
    setSuccessFormOpen(false);
    setShowAddPool(false);
    window.location.reload();
  };

  const filteredPools = poolList.filter((pool) =>
    pool.poolname.toLowerCase().includes(searchValue.toLowerCase())
  );

  useEffect(() => {
    getgroupbyid();
    getPoolByGroupId();
  }, []);

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };

  const getgroupbyid = async () => {
    setLoading(true);
    let param = { groupId: groupId };
    await post("/talent/getgroupbyid", param).then((res) => {
      setGroupData(res.data[0]);
      setLoading(false);
    });
  };

  const getPoolByGroupId = async () => {
    setLoadingPoolById(true);
    let param = { groupId: groupId };
    await post("/talent/getpoolsbygroupid", param).then((res) => {
      let poolData = res.data.sort((a, b) => +b.id_pool - +a.id_pool);
      setPoolList(poolData);
      setLoadingPoolById(false);
    });
  };

  const clearSearch = () => {
    setSearchValue("");
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleView = (view) => {
    history.push(`?view=${view}`);
  };

  return (
    <>
      {(loading || groupLoading || loadingPoolById) && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <SideNavBar handleToggale={setToggle} />
        {role === "CommunityManager" ? (
          <ReportTopNavBar handleToggale={toggleDiv} />
        ) : (
          <TopNavBar handleToggale={toggleDiv} />
        )}
        <div id="Content">
          <div className="section section-sm">
            <div class="container">
              <div class="row justify-content-center">
                <div class="col-lg-12">
                  <div className="row ">
                    <div className="col-xl-8">
                      <div className="ml-4 text-capitalize">
                        {groupData?.groupname && (
                          <h4
                            title="Community Name"
                            style={{ cursor: "default" }}
                          >
                            {groupData?.groupname}
                          </h4>
                        )}
                        <ul className="dotsbetween">
                          <li title="Community" style={{ cursor: "default" }}>
                            <i className="fad fa-fw fa-users mr-2"></i>Community
                          </li>
                          <li title="Created By" style={{ cursor: "default" }}>
                            {groupData?.groupowner}
                          </li>
                          <li
                            title="Community Id"
                            style={{ cursor: "default" }}
                          >
                            {groupData?.groupId}
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-xl-4" style={{ textAlign: "end" }}>
                      <div className=" text-capitalize">
                        <button
                          className=" btn-outline-secondary btn-sm m-1"
                          onClick={() => setEditGroup(true)}
                        >
                          <i class="fas fa-pencil"></i> Edit
                        </button>
                        <button
                          className="btn-outline-primary btn-sm m-1"
                          onClick={() => setShowAddPool(true)}
                        >
                          <i class="fas fa-plus"></i> New Community
                        </button>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mb-3 mt-3 border-bottom-gray2">
                    <div class="d-flex align-items-center justify-content-center justify-content-lg-start col-md-2">
                      <div class=" d-none d-xl-flex nav nav-tabs justify-content-between">
                        <div class="nav-itemd-flex justify-content-center">
                          <button
                            type="button"
                            class={
                              !viewParam || viewParam == "grid"
                                ? "nav-link pb-3 bg-transparent active"
                                : "nav-link pb-3 bg-transparent"
                            }
                            onClick={() => handleView("grid")}
                          >
                            <i class="fas fa-th-large" aria-hidden="true"></i>
                          </button>
                        </div>
                        <div class="nav-itemd-flex justify-content-center">
                          <button
                            class={
                              viewParam == "list"
                                ? "nav-link pb-3 bg-transparent active"
                                : "nav-link pb-3 bg-transparent"
                            }
                            type="button"
                            onClick={() => handleView("list")}
                          >
                            <i class="fas fa-list" aria-hidden="true"></i>
                          </button>
                        </div>
                        <div class="nav-itemd-flex justify-content-center">
                          <button
                            class={
                              viewParam == "table"
                                ? "nav-link pb-3 bg-transparent active"
                                : "nav-link pb-3 bg-transparent"
                            }
                            type="button"
                            onClick={() => handleView("table")}
                          >
                            <i class="fas fa-table" aria-hidden="true"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex justify-content-start ml-3">
                        <input
                          type="text"
                          value={searchValue}
                          onChange={handleSearchChange}
                          className="form-control"
                          placeholder="Search by Community Name"
                          style={{
                            fontSize: "14px",
                            boxShadow: "none",
                            borderRightColor: searchValue
                              ? "transparent"
                              : "#ced4da",
                            borderTopRightRadius: searchValue ? "0px" : "4px",
                            borderBottomRightRadius: searchValue
                              ? "0px"
                              : "4px",
                          }}
                        />

                        {searchValue && (
                          <button
                            className="btn btn-sm btn-success ml-0"
                            onClick={clearSearch}
                            aria-label="Clear"
                            style={{
                              height: "35px",
                              borderColor: searchValue
                                ? "#ced4da"
                                : "transparent",
                              borderTopLeftRadius: searchValue ? "0px" : "4px",
                              borderBottomLeftRadius: searchValue
                                ? "0px"
                                : "4px",
                            }}
                          >
                            <i className="fas fa-times"></i>
                          </button>
                        )}
                      </div>{" "}
                    </div>

                    <div className="col-md-3">
                      <div className="d-flex justify-content-end row">
                        <div className="col-md-10 mr-1">
                          {/* <select
                          className="font-14 form-select "
                          name="salaryCurrency"
                          onChange={(e) => handleOnChange(e)}
                        >
                          <option value="pools">Sub Community</option>
                          <option value="groups">Community</option>
                        </select> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <TalentView
                    pool={filteredPools}
                    view={viewParam}
                    isFrom={"CommunityGroup"}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                  />
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
      <Modal show={editGroup} size="lg">
        <Modal.Header className="bg-gray4">
          <buttons
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setEditGroup(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </buttons>
        </Modal.Header>
        <Modal.Body className="mt-3 p-2">
          <AddGroup
            edit={true}
            setShowAddGroup={setEditGroup}
            groupData={groupData}
          />
        </Modal.Body>
      </Modal>
      <Modal show={showAddPool} size="lg">
        <Modal.Header className="bg-gray4">
          <buttons
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={shareClose}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </buttons>
        </Modal.Header>
        <Modal.Body className="mt-3 p-2">
          {successFormOpen ? (
            <FormSubmitSuccess
              successData={successData}
              handleSuccess={shareClose}
              isFrom="CreateSubCommunity"
            />
          ) : (
            <OptionModal
              setSuccessData={setSuccessData}
              setSuccessFormOpen={setSuccessFormOpen}
              setShowAddPool={setShowAddPool}
              groupId={groupId}
            ></OptionModal>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TalentCommunity;
