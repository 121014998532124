import React, { useState } from "react";
import HereMape from "../../location/HereMapAddtalent";
import phoneCode from "../../config/PhoneCode";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";
import ApplyJob from "./ApplyJob";

function ContactCand(props) {
  const [isRemote, setIsRemote] = useState(true);
  console.log("candidateDetails", props.candidateDetails);
  const phCode = Object.entries(phoneCode).map((item) => item[1].code);

  const handleChange = (e) => {
    props.setContactInfo({
      ...props.contactInfo,
      [e.target.id]: e.target.value,
    });
    console.log("props.contactInfo", props.contactInfo);
  };

  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    const formattedNumber = asYouType.input(
      value.replace(country.dialCode, "")
    );
    const isValid = asYouType.isValid();
    props.setContactInfo({
      ...props.contactInfo,
      mobilePhone: value,
      phoneValidation: isValid,
    });
  };

  const _onChangeText2 = (value, country) => {
    console.log(value, country);
    const asYouType = new AsYouType(country.countryCode.toUpperCase());
    const formattedNumber = asYouType.input(
      value.replace(country.dialCode, "")
    );
    const isValid = asYouType.isValid();
    props.setContactInfo({
      ...props.contactInfo,
      homePhone: value,
      phoneValidation2: isValid,
    });
  };

  return (
    <>
      <div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="firstName"
                  placeholder="First Name*"
                  style={{
                    border:
                      props.contactInfoValidation.firstName &&
                      "2px solid #ff0000",
                  }}
                  value={props.contactInfo.firstName}
                  // value={props?.candidateDetails?.fName}
                  // onChange={handleChange}
                  onChange={(e) =>
                    props.setContactInfo({
                      ...props.contactInfo,
                      firstName: e.target.value.replace(/[^a-zA-Z\s]/, ""),
                    })
                  }
                />
                <label>First Name*</label>
              </div>
              <small className="validation">
                {props.contactInfoValidation.firstName}
              </small>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="lastName"
                  placeholder="Last Name*"
                  style={{
                    border:
                      props.contactInfoValidation.lastName &&
                      "2px solid #ff0000",
                  }}
                  value={props.contactInfo.lastName}
                  // value={props?.candidateDetails?.lName}
                  // onChange={handleChange}
                  onChange={(e) =>
                    props.setContactInfo({
                      ...props.contactInfo,
                      lastName: e.target.value.replace(/[^a-zA-Z\s]/, ""),
                    })
                  }
                />
                <label>Last Name*</label>
              </div>
              <small className="validation">
                {props.contactInfoValidation.lastName}
              </small>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  placeholder="Email*"
                  style={{
                    border:
                      props.contactInfoValidation.email && "2px solid #ff0000",
                  }}
                  value={
                    props.message == "JoinCommunity"
                      ? sessionStorage.getItem("email")
                      : props.contactInfo.email
                  }
                  // value={sessionStorage.getItem("email")}
                  onChange={handleChange}
                />
                <label>Email*</label>
              </div>
              <small className="validation">
                {props.contactInfoValidation.email}
              </small>
            </div>
          </div>
          <div className="row mb-2" style={{ marginTop: "-6px" }}>
            <div className="col-lg-6">
              <div className="mb-2">
                <label className="pt-1">Your preferred phone*</label>
              </div>
              <div className="d-flex">
                <PhoneInput
                  // classsName="form-control"
                  containerClass="w-100"
                  inputClass="w-100"
                  value={props.contactInfo.mobilePhone}
                  // value={props?.candidateDetails?.phone}
                  name="mobilePhone"
                  country={"us"}
                  onChange={(value, country) => {
                    _onChangeText(value, country);
                  }}
                />
              </div>
              {!props.contactInfo.phoneValidation && (
                <small className="validation">Invalid Phone</small>
              )}
            </div>
            <div className="col-lg-6">
              <div className="mb-2">
                <label style={{ marginTop: "-14px" }}>Alternative Phone</label>
              </div>
              <div className="d-flex">
                <PhoneInput
                  // classsName="form-control"
                  containerClass="w-100"
                  inputClass="w-100"
                  name="homePhone"
                  country={"us"}
                  value={props.contactInfo.homePhone}
                  onChange={(value, country) => {
                    _onChangeText2(value, country);
                  }}
                />
              </div>
              {!props.contactInfo.phoneValidation2 && (
                <small className="validation">Invalid Phone</small>
              )}
            </div>
            {/* <div className="col-lg-4"></div> */}
          </div>

          {/* Resume */}

          {props.message === "JoinCommunity" && (
            <div className="row">
              <div className="col-md-12">
                <ApplyJob
                  updateParsedData={(data, value) =>
                    props.updatedParsed(data, value)
                  }
                  message={props.message}
                />
              </div>
            </div>
          )}
          {/* <div class="row mb-2"></div> */}
          {/* <HereMape
            isRemote={isRemote}
            setIsRemote={setIsRemote}
            contactInfo={props.contactInfo}
            setContactInfo={props.setContactInfo}
            profileInfo={props.profileInfo}
            setProfileInfo={props.setProfileInfo}
          /> */}
          {isRemote && (
            <>
              {/* <div class="row mb-2">
                <div class="col-lg-12">
                  <label>Primary Address*</label>
                </div>
              </div> */}

              {/* <HereMape
                isRemote={isRemote}
                setIsRemote={setIsRemote}
                contactInfo={props.contactInfo}
                setContactInfo={props.setContactInfo}
                profileInfo={props.profileInfo}
                setProfileInfo={props.setProfileInfo}
              /> */}
              {/* <div class="row mb-2">
                <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="zipcode"
                      placeholder="Zipcode"
                      style={{
                        border:
                          props.contactInfoValidation.zipcode &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo.zipcode}
                      onChange={handleChange}
                    />
                    <label>Zipcode*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation.zipcode}
                  </small>
                </div>
                <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="address"
                      placeholder="Address"
                      value={props.contactInfo.address}
                      onChange={handleChange}
                    />
                    <label>Address*</label>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="addressCity"
                      placeholder="Address City"
                      style={{
                        border:
                          props.contactInfoValidation.addressCity &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo.addressCity}
                      onChange={handleChange}
                    />
                    <label>City*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation.addressCity}
                  </small>
                </div>
              </div> */}
              <div class="row mb-2">
                {/* <div class="col-lg-4">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="addressState"
                      placeholder="Address State"
                      style={{
                        border:
                          props.contactInfoValidation.addressState &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo.addressState}
                      onChange={handleChange}
                    />
                    <label>State*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation.addressState}
                  </small>
                </div> */}
                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="addressCity"
                      placeholder="Address City"
                      style={{
                        border:
                          props.contactInfoValidation.addressCity &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo.addressCity}
                      // value={props?.candidateDetails?.city}
                      onChange={handleChange}
                    />
                    <label>Your city*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation.addressCity}
                  </small>
                </div>
                <div class="col-lg-6">
                  <div class="form-floating">
                    <input
                      type="text"
                      class="form-control"
                      id="country"
                      placeholder="Address Country"
                      style={{
                        border:
                          props.contactInfoValidation.country &&
                          "2px solid #ff0000",
                      }}
                      value={props.contactInfo.country}
                      // value={props?.candidateDetails?.country}
                      onChange={handleChange}
                    />
                    <label>Your country*</label>
                  </div>
                  <small className="validation">
                    {props.contactInfoValidation.country}
                  </small>
                </div>
                {/* <div class="col-lg-4">
                                        <div class="form-floating">
                                            <select class="form-select" id="country" onChange={handleChange}>
                                                <option value="" selected>Select Country</option>
                                                <option value="US">US</option>
                                            </select>
                                            <label >Address Country</label>
                                        </div>
                                    </div> */}
              </div>
            </>
          )}
          {/* <div className="row mb-2">
            <div className="col-lg-6">
              <div className="mb-2">
                <label>Primary Phone*</label>
              </div>
              <div className="d-flex">
                <PhoneInput
                  classsName="form-control"
                  value={props.contactInfo.mobilePhone}
                  name="mobilePhone"
                  country={"us"}
                  onChange={(value, country) => {
                    _onChangeText(value, country);
                  }}
                />
              </div>
              {!props.contactInfo.phoneValidation && (
                <small className="validation">Invalid Phone</small>
              )}
            </div>
            <div className="col-lg-6">
              <div className="mb-2">
                <label>Alternative Phone</label>
              </div>
              <div className="d-flex">
                <PhoneInput
                  classsName="form-control"
                  name="homePhone"
                  country={"us"}
                  value={props.contactInfo.homePhone}
                  onChange={(value, country) => {
                    _onChangeText2(value, country);
                  }}
                />
              </div>
              {!props.contactInfo.phoneValidation2 && (
                <small className="validation">Invalid Phone</small>
              )}
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}
export default ContactCand;
