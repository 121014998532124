import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import { FaPaperPlane } from "react-icons/fa";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { FetchEmailsByUserToCandidate } from "../../../../actions/communication/Communication";
import { getCandidateId } from "../../../../service/AuthService";
import Loader from "../../../Loader/Loader";

const Emails = ({
  handleCommunicationModal,
  handleClose,
  candidateData,
  messageIndicatorStyle,
  indicatorTextStyle,
  indicatorBoxStyle,
  poolId,
  setIsCandidateFrom,
}) => {
  const dispatch = useDispatch();
  const { sendEmailCandidateLoading, sendEmailCandidateList } = useSelector(
    (state) => ({
      sendEmailCandidateLoading:
        state.communicationReducer.sendEmailCandidateLoading,
      sendEmailCandidateList: state.communicationReducer.sendEmailCandidateList,
    }),
    shallowEqual
  );

  useEffect(() => {
    let payload = {};
    if (poolId) {
      payload = {
        userId: getCandidateId(),
        candidateEmail: candidateData?.email,
        poolId: poolId,
      };
    } else {
      payload = {
        userId: getCandidateId(),
        candidateEmail: candidateData?.email,
        poolId: poolId,
      };
    }

    dispatch(FetchEmailsByUserToCandidate(payload));
  }, []);

  // Group emails by date
  const groupedEmails =
    sendEmailCandidateList &&
    sendEmailCandidateList?.reduce((acc, email) => {
      const date = moment(email?.createdDateTime).format("MM/DD/YYYY");
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(email);
      return acc;
    }, {});

  // Function to toggle email content visibility
  const [openEmailId, setOpenEmailId] = useState(null);

  const toggleEmail = (id) => {
    setOpenEmailId(openEmailId === id ? null : id);
  };
  useEffect(() => {}, [sendEmailCandidateList]);
  const emailContentRef = useRef(null);

  const handleClick = (event) => {
    if (event.target.tagName.toLowerCase() === "a") {
      event.preventDefault();
    }
  };

  return (
    <>
      {sendEmailCandidateLoading && <Loader />}
      <div
        className="d-flex justify-content-start align-content-start"
        style={{
          // position: "sticky",
          // top: "-15px",
          marginBottom: "10px",

          backgroundColor: "#fff",
          // zIndex: "999",
        }}
      >
        <button
          type="button"
          // style={{ height: "20px" }}

          className="btn btn-sm btn-secondary"
          onClick={() => {
            setIsCandidateFrom("email");
            handleCommunicationModal();
          }}
        >
          <FaPaperPlane size={14} className="mr-2" />
          Compose Email
        </button>
      </div>
      {/* {sendEmailCandidateList?.length > 0 && (
        <div>
          <div style={messageIndicatorStyle}>
            <div
              style={{ ...indicatorBoxStyle, backgroundColor: "#007bff" }}
            ></div>
            <span style={indicatorTextStyle}>Sent</span>
          </div>
        </div>
      )} */}

      {sendEmailCandidateList && sendEmailCandidateList?.length === 0 && (
        <>
          <div style={dateHeadingStyle}></div>
          <div class="text-center p-5">
            <div class="avatar avatar-lg">
              <i aria-hidden="true" class="fad fa-users-slash"></i>
            </div>
            <div class="mt-2">No records found</div>
          </div>
        </>
      )}
      <div className="w-100">
        {Object.keys(groupedEmails).map((date) => (
          <div key={date} className="email-date d-flex flex-column gap-2">
            {/* <div style={dateHeadingStyle}>{date}</div> */}
            {groupedEmails[date].map((email, index) => (
              <>
                <div style={dateHeadingStyle}></div>
                <span>
                  <small className="tag tag-blue3">
                    Sent On :{" "}
                    {moment(email?.createdDateTime).format("MM/DD/YYYY")}
                  </small>
                </span>
                <div key={index} className="email" style={sentEmailStyle}>
                  <div
                    className="email-header"
                    style={emailHeaderStyle}
                    onClick={() => toggleEmail(index)}
                  >
                    <span>{email?.MailSubject?.replace(/<[^>]*>/g, "")}</span>
                    <span style={emailHeaderTimestampStyle}>
                      {moment(email?.createdDateTime).format("hh:mm A")}
                    </span>
                    {/* <span style={emailHeaderSenderStyle}>{email.sender}</span> */}
                  </div>
                  {/* {openEmailId === index && ( */}
                  <div
                    ref={emailContentRef}
                    className="email-content"
                    style={emailContentStyle}
                    dangerouslySetInnerHTML={{
                      __html: email?.MailContent,
                    }}
                  ></div>
                  {/* )} */}
                </div>
              </>
            ))}
          </div>
        ))}
      </div>
    </>
  );
};

// Inline CSS objects
const emailHeaderStyle = {
  backgroundColor: "#f0f0f0",
  padding: "10px",
  cursor: "pointer",
  display: "grid",
  gridTemplateColumns: "1fr auto auto",
  gap: "10px",
  alignItems: "center",
};

const emailHeaderTimestampStyle = {
  fontSize: "12px",
  color: "#6c757d",
};

const emailHeaderSenderStyle = {
  fontWeight: "bold",
};

const emailContentStyle = {
  //   backgroundColor: "#e1e1e1",
  padding: "10px",
  borderRadius: "5px",
};

const sentEmailStyle = {
  // borderLeft: "5px solid #007bff",
  // borderLeft: "5px solid #009eff",
};

const receivedEmailStyle = {
  borderLeft: "5px solid #e1e1e1",
};

const dateHeadingStyle = {
  //   backgroundColor: "#f0f0f0",
  borderRadius: "0px",
  borderBottom: "1px solid #009eff",
  padding: "5px",
  marginBottom: "10px",
  textAlign: "center",
};
export default Emails;
