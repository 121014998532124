// ! Queries for mails sent to Candidates

let currentDate = new Date();
let month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
let day = currentDate
  .getDate()
  .toString()
  .padStart(2, "0");
let year = currentDate.getFullYear().toString();
let formattedDate = month + day + year;

export const Automatched_Recruiter = `(subject LIKE "%You’ve Been Matched to%")`;
export const Invited_Recruiter = `(subject LIKE "%Job Reminder -%" OR subject LIKE "%As per our conversation%" OR subject LIKE "%Exciting Job Opportunity: Apply Now!%" OR subject LIKE "%Reminder to Apply job%" OR subject LIKE "%You've been invited to apply for a job!%" OR subject LIKE "%Journey Starts Now%" OR subject LIKE "%Join Our Exclusive%")`;
export const Invited_HM = `(subject LIKE "%Reminder to Apply job%" OR subject LIKE "%You've been invited to apply for a job!%" OR subject LIKE "%Application Successfully Submitted!%" OR subject LIKE "%You’ve Been Matched to%")`;
export const Submitted = `(subject LIKE "%Application Successfully Submitted!%" OR subject LIKE "%Ready for Your Next Step? Start Your Assessment Now!%" OR subject LIKE "%Congratulations! on Your High5 Application Submission!%")`;
export const Shortlisted = `(subject LIKE "%Your profile has been shortlisted%")`;
export const Offered = `(subject LIKE "%Congratulations! You have been offered%")`;
export const Hired = `(subject LIKE "%Welcome to High5!%" OR subject LIKE "%Congratulations! You have Been Moved%")`;
export const jobPublished = `(subject LIKE "%New job added to the High5 Recruiter Market Place%")`;

export const Invited_Recruiter_SubComm = `(subject LIKE "%As per our conversation%" OR subject LIKE "%Join Our Exclusive Talent Community for%" OR  subject LIKE "%Reminder to Apply job%" OR subject LIKE "%You've been invited to apply for a job!%" OR subject LIKE "%Journey Starts Now%")`;
export const Automatched_Recruiter_SubComm = `(subject LIKE "%You’ve Been Matched to%")`;
export const Engaged_Q_SubComm = `(subject LIKE "%Congratulations! on Your High5 Application Submission!%")`;
export const ReadyToHire_Q_SubComm = `(subject LIKE "%Congratulations! You have Been Moved%")`;

// ! Queries for mails sent to user
export const SuperAdminUsers = `(subject LIKE "%Welcome to High5hire%")`;
export const SuperAdminTenants = `(subject LIKE "%Welcome to High5!%")`;
export const CSM_Users = `(subject LIKE "%Welcome to High5!!%")`;
export const CMRecInvite = `(subject LIKE "%Your Exclusive Invitation to Join the High5 Marketplace%" OR subject LIKE "%Rediscover Success: Unveil Extraordinary Opportunities and Rewards on Our Platform%" OR subject LIKE "%Welcome to the High5 marketplace! access you account now%" OR subject LIKE "%Welcome to High5!!%")`;
