import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  getCandidateId,
  getUserRole,
  getUserTenantID,
} from "../../../../service/AuthService";
import { get, post } from "../../../../service/ApiService";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import CreatableSelect from "react-select/creatable";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getAllTags } from "../../../../actions/tagmanagement/getAllTags";
import { toast } from "react-toastify";
import Loader from "../../commoncomponents/Loader/Loader";

const TagModal = ({
  showjobModal,
  setShowJobModal,
  selectedTalents,
  fetchData,
  setSelectedTalents,
}) => {
  const dispatch = useDispatch();
  const role = getUserRole();
  const [pools, setPools] = useState([]);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  const { tagsData, tagsLoading } = useSelector(
    (state) => ({
      tagsData: state.tagReducer.tagsData,
      tagsLoading: state.tagReducer.loading,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getAllTags());
  }, []);

  useEffect(() => {
    if (tagsData) {
      const tagOptions = tagsData
        .filter((item) => item.tagStatus === 21)
        .map((item) => ({
          label: item.tagName,
          value: item.tagName,
        }));
      setLoading(false);
      setOptions(tagOptions);
    }
  }, [tagsData]);

  const handleCreateOption = async (inputValue) => {
    setLoading(true);
    const newOption = { value: inputValue, label: inputValue };
    let paramObj = {
      tagName: inputValue,
      createdBy: getCandidateId(),
      createdDateTime: new Date(),
      tagStatus: 21,
      fk_tenant: getUserTenantID(),
    };

    let updatedTagList = selectedTags?.map((item) => item.label);
    updatedTagList.push(inputValue);

    let newTagObj = {
      tags: updatedTagList,
      // candidateId: talentData.userId,
      createdBy: getCandidateId(),
      updatedBy: getCandidateId(),
      fk_tenant: getUserTenantID(),
    };

    try {
      // Make an API call to add the new tag to the database
      await post("/tags/createTag", paramObj);
      // await post("/tags/updateCandidateTags", newTagObj);
      // toast.success("Tag assigned successfully");
      setOptions([...options, newOption]);
      setSelectedTags([...selectedTags, newOption]);
      // setNewlyAddedTags([...newlyAddedTags, newOption]);
      // let payload = {
      //   currentPage: 1,
      //   limit: 10,
      //   status: null,
      //   tab: "All",
      //   sortParam: [],
      // };
      // dispatch(props.getTalentList(payload));
    } catch (error) {
      console.error("Error adding tag:", error);
    } finally {
      setLoading(false);
    }
    return newOption;
  };

  const handleChange = async (selectedOptions) => {
    // setLoading(true);
    setSelectedTags(selectedOptions);
    // let selectedTagList = selectedOptions.map((item) => item.label);
    // let payloadTag = {
    //   tags: selectedTagList,
    //   // candidateId: talentData.userId,
    //   createdBy: getCandidateId(),
    //   updatedBy: getCandidateId(),
    //   fk_tenant: getUserTenantID(),
    // };
    try {
      // await post("/tags/updateCandidateTags", payloadTag);
      // if (selectedTagList?.length > 0)
      //   toast.success("Tags updated successfully");
      // else toast.success("Tags removed successfully");
      // let payload = {
      //   currentPage: 1,
      //   limit: 10,
      //   status: null,
      //   tab: "All",
      //   sortParam: [],
      // };
      // dispatch(props.getTalentList(payload));
      // setLoading(false);
      //props.handleClose();
    } catch (err) {
      // setLoading(false);
    }
  };
  const styleSheet = {
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        fontSize: 13,
      },
    }),
  };

  const handleTag = async () => {
    let selectedTagList = selectedTags.map((item) => item.label);
    const candidateIds = selectedTalents.map((item) => item.userId);
    let payloadTag = {
      tags: selectedTagList,
      candidateId: candidateIds,
      createdBy: getCandidateId(),
      updatedBy: getCandidateId(),
      fk_tenant: getUserTenantID(),
    };
    setLoading(true);
    try {
      await post("/tags/updateCandidateListTags", payloadTag).then((res) => {
        toast.success("Tags updated successfully");
        setSelectedTalents([]);
        fetchData(1, true);
        setLoading(false);
        setShowJobModal(false);
      });
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <Modal show={showjobModal} size="lg">
      <div className="modal-header" id="NewCandidateModal">
        <h6>Tags</h6>
        {showjobModal && (
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowJobModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
      <Modal.Body className="p-4">
        {(loading || tagsLoading) && <Loader />}
        <div className=" mx-auto  px-4">
          <CreatableSelect
            isMulti
            options={options}
            value={selectedTags}
            onChange={handleChange}
            onCreateOption={handleCreateOption}
            isSearchable
            allowCreateWhileLoading={false}
            formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
            placeholder="Search"
            styles={styleSheet}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => setShowJobModal(false)}
        >
          Cancel{" "}
        </button>
        <button onClick={handleTag}>Tag</button>
      </Modal.Footer>
    </Modal>
  );
};

export default TagModal;
