import React, { useState, useEffect } from "react";
import "react-tagsinput/react-tagsinput.css";
import { post } from '../../../service/ApiService';
import { toast } from 'react-toastify'
import { getUserId } from '../../../service/AuthService'
function TermOverride(props) {


  const [showEditJobs, setShowEditJobs] = useState(false);
  const [showEditTalent, setShowEditTalents] = useState(false);
  const [Jobs, SetJobs] = useState();
  const [Talent, SetTalent] = useState();



  const GetTermOverride = async (params) => {
    try {

      const res = await post('/common/termOverride', params)

      console.log(res)
      if (res.status === 200) {
        console.log(res.data)
        const result = res.data[0]
        console.log(result)
        SetJobs(result.Jobs)
        SetTalent(result.Talent)
      }
    } catch (error) {
      toast.error("Error Occured while accessing Term Override")
    }

  }

  const SetTermOverride = async (params,type) => {
    try {

      const res = await post('/common/termOverride/Update', params)

      console.log(res)
      if (res.status === 200) {

        console.log(res.data)
        const result = res.data[0]
        console.log(result)
        toast.success(`Updated ${type} Successfully`)
      }
    } catch (error) {
      toast.error("Error Occured while saving  Term Override")
    }

  }

  useEffect(() => {
    
    const userid = getUserId();
    

    const params = {
      userid
    };

    GetTermOverride(params)

    //  
  }, []);



  const toggleEditState = (type) => {
    if (type === "job") {
      if (showEditJobs === false) {
        setShowEditJobs(true);

      } else {
        setShowEditJobs(false);
      }
    }

    if (type === "talent") {
      if (showEditTalent === false) {
        setShowEditTalents(true);
      } else {
        setShowEditTalents(false);
      }
    }

  };

  const done = (type) => {

    // validation 
    if (Jobs === '' || Talent === '') {
      toast.error("Error Occured while saving  Term Override")

    } else {

      if (type === "job") {
        setShowEditJobs(false);
        // store data 

      }

      if (type === "talent") {
        setShowEditTalents(false);

        //  save data 
      }
    
      const userid = getUserId()


      const params = {
        Jobs,
        Talent,
        userid


      }
      SetTermOverride(params,type)
    }


    //  call API to store both values in a

  };

  return (
    <>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <h6>Term Overrides</h6>
          <p>
            In this section you can override the systems default values for
            specific industry terms
          </p>
          <div class="d-flex d-block-768 align-items-center justify-content-between py-3">
            <div>
              <p class="p-0 mb-0">
                <strong>Requests</strong>
              </p>
              <p class="mb-1">
                Common alternatives "Jobs, Job Post, Work Order"
              </p>
            </div>
            <div class="w-50 w-100-768 text-right">
              <div class="form-inline-edit">
                {showEditJobs === false ? (
                  <div
                    // v-on:click="toggleEditState"
                    onClick={() => {
                      toggleEditState("job");
                    }}
                    // v-bind:class="{ 'justify-content-start': leftaligned, 'p-relative': showediticon }"
                    class="toggle"
                    v-if="!showEdit"
                  >
                    <div

                    // v-bind:class="{'pr-3': showediticon }"
                    >
                      {/* <div v-if="isplaintext" class="foo">
                        Job
                      </div> */}
                      <span  class="tag tag-blue3">
                        {Jobs}
                      </span>
                    </div>
                    <div  v-html="emptystate"></div>
                  </div>
                ) : (
                  <div v-if="showEdit">
                    <div>
                      <input

                        class="form-control"
                        // :placeholder="placeholder"
                        placeholder="Enter Your Preferrred Term"
                        // v-model="value"
                        value={Jobs}
                        onChange={e => SetJobs(e.target.value)}
                        id="Job"
                        type="text"
                      />
                    </div>
                    <div class="p-2 bg-gray3">
                      {/* <button v-on:click="toggleEditState" type="button" class="btn btn-sm btn-primary"> */}
                      <button
                        onClick={() => {
                          done("job");
                        }}
                        type="button"
                        class="btn btn-sm btn-primary"
                      >
                        <i class="fal fa-fw fa-check mr-1"></i>
                        Done
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <hr class="m-0" />
          <div class="d-flex d-block-768 align-items-center justify-content-between py-3">
            <div>
              <p class="p-0 mb-0">
                <strong>Talent</strong>
              </p>
              <p class="mb-1">Common alternatives "Talent, Workers"</p>
            </div>
            <div class="w-50 w-100-768 text-right">
              <div class="form-inline-edit">
                {showEditTalent === false ? (
                  <div
                    // v-on:click="toggleEditState"
                    onClick={() => {
                      toggleEditState("talent");
                    }}
                    // v-bind:class="{ 'justify-content-start': leftaligned, 'p-relative': showediticon }"
                    class="toggle"
                    v-if="!showEdit"
                  >
                    <div
                    // v-if="value.length"
                    // v-bind:class="{'pr-3': showediticon }"
                    >
                      {/* <div v-if="isplaintext" class="foo">
                        Job
                      </div> */}
                      <span  class="tag tag-blue3">
                        {Talent}
                      </span>
                    </div>
                    <div  v-html="emptystate"></div>
                  </div>
                ) : (
                  <div v-if="showEdit">
                    <div>
                      <input
                        v-if="!isrichtextarea"
                        class="form-control"
                        // :placeholder="placeholder"
                        placeholder="Enter Your Preferrred Term"
                        value={Talent}
                        id="Talent"
                        onChange={e => SetTalent(e.target.value)}
                        // v-model="value"
                        type="text"

                      />
                    </div>
                    <div class="p-2 bg-gray3">
                      {/* <button v-on:click="toggleEditState" type="button" class="btn btn-sm btn-primary"> */}
                      <button
                        onClick={() => {
                          done("talent");
                        }}
                        type="button"
                        class="btn btn-sm btn-primary"
                      >
                        <i class="fal fa-fw fa-check mr-1"></i>
                        Done
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TermOverride;
