import React, { useState, useEffect } from "react";
import Loader from "../../../Loader/Loader";
import { post } from "../../../../service/ApiService";
import moment from "moment";
import { Card, Button, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/esm/Modal";
import {
  getUserTenant,
  getUserRole,
  getTenantType,
} from "../../../../service/AuthService";
import RichTextEditor from "react-rte";
import { countryObj } from "../../../../constants/Constants";
import Avatar from "react-avatar";
import SubmittalDetailsInfo from "../../../modal/addjob/SubmittalDetailsInfo";

function RequestSideBar(props) {
  const [lgShow, setLgShow] = useState(false);
  const [stats, setStatus] = useState(false);
  const [skills, setSkills] = useState(false);
  const [description, setDescription] = useState(false);
  const [education, setEducation] = useState(false);
  const [certifications, setCertifications] = useState(false);
  const [licences, setLicences] = useState(false);
  const [visa, setVisa] = useState(false);
  const [experience, setExperience] = useState(false);
  const [industries, setIndustries] = useState(false);
  const [workHours, setWorkHours] = useState(false);
  const [travel, setTravel] = useState(false);
  const [drugTest, setDrugTest] = useState(false);
  const [backgroundCheck, setBackgroundCheck] = useState(false);
  const [securityClearance, setSecurityClearance] = useState(false);
  const [submittal, setSubmittal] = useState(false);
  const [clientName, setClientName] = useState(false);
  const [interviewProcessSteps, setInterviewProcessSteps] = useState(false);
  const [employerValueProp, setEmployerValueProp] = useState(false);
  const [teamStructure, setTeamStructure] = useState(false);
  const [expectations, setExpectations] = useState(false);
  const [responsibilities, setResponsibilities] = useState(false);
  const [callRecording, setCallRecording] = useState(false);
  const [documents, setdocuments] = useState(false);
  const [document, setdocument] = useState(false);
  const [jDocument, setjDocument] = useState(false);
  const [docs, setDocs] = useState([]);
  const [jdDocument, setJdDocument] = useState([]);
  const [loading, setLoading] = useState(false);
  const [placementDisplay, setPlacement] = useState(false);
  const [notesValue, setNotesValue] = useState("Sample Notes");
  const [done, setDone] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const [qAndA, setQandA] = useState(false);
  const [clientData, setClientData] = useState(false);
  const [workSchedule, setWorkSchedule] = useState(false);
  const [customInfo, setCustomInfo] = useState(false);
  const [screeningRequirement, setScreeningRequirement] = useState(false);
  const [videoPitch, setVideoPitch] = useState(false);
  const [prevettedTalants, setPrevettedTalants] = useState(false);
  const [automatchData, setAutomatchData] = useState(false);

  const onCollapsibleClick = (val) => {
    if (val === "stats") {
      setStatus(true);
    }
    if (val === "statsReset") {
      setStatus(false);
    }
    if (val === "skills") {
      setSkills(true);
    }
    if (val === "skillsReset") {
      setSkills(false);
    }
    if (val === "description") {
      setDescription(true);
    }
    if (val === "descriptionReset") {
      setDescription(false);
    }
    if (val === "education") {
      setEducation(true);
    }
    if (val === "educationReset") {
      setEducation(false);
    }
    if (val === "certifications") {
      setCertifications(true);
    }
    if (val === "certificationsReset") {
      setCertifications(false);
    }
    if (val === "experience") {
      setExperience(true);
    }
    if (val === "experienceReset") {
      setExperience(false);
    }
    if (val === "industries") {
      setIndustries(true);
    }
    if (val === "industriesReset") {
      setIndustries(false);
    }
    if (val === "workHours") {
      setWorkHours(true);
    }
    if (val === "workHoursReset") {
      setWorkHours(false);
    }
    if (val === "travel") {
      setTravel(true);
    }
    if (val === "travelReset") {
      setTravel(false);
    }
    if (val === "clientData") {
      setClientData(true);
    }
    if (val === "clientDataReset") {
      setClientData(false);
    }
    if (val === "workSchedule") {
      setWorkSchedule(true);
    }
    if (val === "workScheduleReset") {
      setWorkSchedule(false);
    }
    if (val === "drugTest") {
      setDrugTest(true);
    }
    if (val === "drugTestReset") {
      setDrugTest(false);
    }
    if (val === "backgroundCheck") {
      setBackgroundCheck(true);
    }
    if (val === "backgroundCheckReset") {
      setBackgroundCheck(false);
    }
    if (val === "securityClearance") {
      setSecurityClearance(true);
    }
    if (val === "securityClearanceReset") {
      setSecurityClearance(false);
    }
    if (val === "submittalReset") {
      setSubmittal(false);
    }
    if (val === "submittal") {
      setSubmittal(true);
    }
    if (val === "clientName") {
      setClientName(true);
    }
    if (val === "clientNameReset") {
      setClientName(false);
    }
    if (val === "interviewProcessSteps") {
      setInterviewProcessSteps(true);
    }
    if (val === "interviewProcessStepsReset") {
      setInterviewProcessSteps(false);
    }
    if (val === "employerValueProp") {
      setEmployerValueProp(true);
    }
    if (val === "employerValuePropReset") {
      setEmployerValueProp(false);
    }
    if (val === "teamStructure") {
      setTeamStructure(true);
    }
    if (val === "teamStructureReset") {
      setTeamStructure(false);
    }
    if (val === "expectations") {
      setExpectations(true);
    }
    if (val === "expectationsReset") {
      setExpectations(false);
    }
    if (val === "responsibilities") {
      setResponsibilities(true);
    }
    if (val === "responsibilitiesReset") {
      setResponsibilities(false);
    }
    if (val === "customInfo") {
      setCustomInfo(true);
    }
    if (val === "customInfoReset") {
      setCustomInfo(false);
    }

    if (val === "screeningRequirement") {
      setScreeningRequirement(true);
    }
    if (val === "screeningRequirementReset") {
      setScreeningRequirement(false);
    }
    if (val === "videoPitch") {
      setVideoPitch(true);
    }
    if (val === "videoPitchReset") {
      setVideoPitch(false);
    }
    if (val === "prevettedTalants") {
      setPrevettedTalants(true);
    }
    if (val === "prevettedTalantsReset") {
      setPrevettedTalants(false);
    }
    if (val === "automatchData") {
      setAutomatchData(true);
    }
    if (val === "automatchDataReset") {
      setAutomatchData(false);
    }
    if (val === "callRecording") {
      setCallRecording(true);
    }
    if (val === "callRecordingReset") {
      setCallRecording(false);
    }
    if (val === "documents") {
      setdocuments(true);
    }
    if (val === "documentsReset") {
      setdocuments(false);
    }
    if (val === "document") {
      setdocument(true);
    }
    if (val === "documentReset") {
      setdocument(false);
    }
    if (val === "jDocument") {
      setjDocument(!jDocument);
    }
  };

  const fetchDocuments = async () => {
    if (props.intakeData.documents.length > 0) {
      setLoading(true);
      const params = {
        intakeId: props.intakeData.intakeId,
        documents: props.intakeData.documents,
      };
      try {
        const res = await post(`/intake/getInTakeDocs`, params);
        setDocs(res.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    setJdDocument(props.jdDocument);
  }, [props.jdDocument]);

  return (
    <>
      {/* { loading && <Loader /> }  */}
      <div>
        <div
          className={
            props.component === "Submit"
              ? ""
              : "hero-card-header card card-flat card-lg card-top-straight"
          }
          style={{ borderTop: "none" }}
        >
          <div className={props.component === "Submit" ? "" : "card-body"}>
            {props.overview === true && (
              <div>
                {getUserRole() !== "CommunityManager" &&
                  getUserRole() !== "SuperAdmin" &&
                  getUserTenant().slice(0, 6) === "GIG - " &&
                  2 > 3 && (
                    <div className="mb-2">
                      <button
                        type="button"
                        className="btn btn-collapsible"
                        onClick={() => setPlacement(!placementDisplay)}
                        // onClick={fetchDocuments}
                      >
                        {placementDisplay ? (
                          <i
                            aria-hidden="true"
                            className="fal fa-angle-up fa-fw"
                          />
                        ) : (
                          <i
                            aria-hidden="true"
                            className="fal fa-angle-down fa-fw"
                          />
                        )}
                        <span>
                          <small>Placement Fee</small>
                        </span>
                      </button>
                    </div>
                  )}
                {placementDisplay && (
                  <div className="mb-4 ml-4">
                    Fee:<span> {props.jobData.placementFee || "NA"}</span>
                    <br />
                    Currency:
                    <span> {props.jobData.placementFeeCurrency || "NA"}</span>
                  </div>
                )}
                <div className="mb-2">
                  <button
                    type="button"
                    className="btn btn-collapsible"
                    onClick={() => {
                      stats
                        ? onCollapsibleClick("statsReset")
                        : onCollapsibleClick("stats");
                    }}
                    // onClick={fetchDocuments}
                  >
                    {stats === true ? (
                      <i
                        if="!collapsible.stats"
                        aria-hidden="true"
                        className="fal fa-angle-up fa-fw"
                        onClick={() => {
                          onCollapsibleClick("statsReset");
                        }}
                      />
                    ) : (
                      <i
                        aria-hidden="true"
                        className="fal fa-angle-down fa-fw"
                        onClick={() => {
                          onCollapsibleClick("stats");
                        }}
                      />
                    )}
                    <span>
                      <small>General</small>
                    </span>
                  </button>
                </div>
                {stats === true ? (
                  <div className="mb-4 ml-4">
                    <div className="d-flex mb-2 pl-1">
                      <div>
                        <i className="fad fa-fw fa-piggy-bank" />
                      </div>
                      <div className="ml-2">
                        <p className=" mb-0">
                          {props.jobData.jobType.charAt(0).toUpperCase() +
                            props.jobData.jobType.slice(1)}
                        </p>
                        <p className="mb-0">
                          <small>Job Type</small>
                        </p>
                      </div>
                    </div>

                    <div className="d-flex mb-2 pl-1">
                      <div>
                        <i className="fad fa-fw fa-calendar-day" />
                      </div>
                      <div className="ml-2">
                        <p className="mb-0">
                          {moment(props.jobData.prefferedStartDate).format(
                            "MM/DD/YYYY"
                          )}
                        </p>
                        <p className="mb-0">
                          <small>Target Start</small>
                        </p>
                      </div>
                    </div>

                    {props.jobData.activeFrom && (
                      <>
                        <div className="d-flex mb-2 pl-1">
                          <div className="avatar avatar-sm">
                            <i className="far fa-calendar-check"></i>
                          </div>
                          <div class="ml-2">
                            <p className="mb-0">
                              {moment(props.jobData.activeFrom).format(
                                "MM/DD/YYYY"
                              )}
                            </p>
                            <p class="mb-0">
                              <small>Active From</small>
                            </p>
                          </div>
                        </div>
                      </>
                    )}

                    {props.jobData.expiresOn && (
                      <>
                        <div className="d-flex mb-2 pl-1">
                          <div className="avatar avatar-sm">
                            <i className="far fa-calendar-times"></i>
                          </div>
                          <div class="ml-2">
                            <p className="mb-0">
                              {moment(props.jobData.expiresOn).format(
                                "MM/DD/YYYY"
                              )}
                            </p>
                            <p class="mb-0">
                              <small>Expire On</small>
                            </p>
                          </div>
                        </div>
                      </>
                    )}

                    {
                      <div className="d-flex mb-2 pl-1">
                        <div>
                          <i className="fad fa-fw fa-map-marked-alt" />
                        </div>
                        <div className="ml-2">
                          <p className=" mb-0">
                            {props.jobData.isRemote
                              ? "Remote"
                              : props.jobData.location.city +
                                ", " +
                                props.jobData.location.state +
                                ", " +
                                props.jobData.location.country}
                          </p>
                          <p className="mb-0">
                            <small>Location</small>
                          </p>
                        </div>
                      </div>
                    }

                    {props.jobData.workPlaceType &&
                      props.jobData.workPlaceType !== "Remote" && (
                        <div className="d-flex mb-2 pl-1">
                          <div>
                            <i className="fad fa-fw fa-map-marked-alt" />
                          </div>
                          <div className="ml-2">
                            <p className="mb-0">
                              {props.jobData.workPlaceType}{" "}
                              {props.jobData.workPlaceType === "Hybrid" &&
                                " - " + props.jobData.onsiteWorkDays}
                            </p>
                            <p className="mb-0">
                              <small>Work Place Type</small>
                            </p>
                          </div>
                        </div>
                      )}

                    <div className="d-flex mb-2 pl-1">
                      <div>
                        <i className="fad fa-fw fa-piggy-bank" />
                      </div>
                      <div className="ml-2">
                        <p className="mb-0">
                          {Number(props.jobData.minimumPay).toLocaleString() +
                            " - " +
                            Number(props.jobData.maximumPay).toLocaleString() +
                            " " +
                            props.jobData.salaryCurrency}
                        </p>
                        <p className="mb-0">
                          <small>Salary</small>
                        </p>
                      </div>
                    </div>

                    {props.jobData.positionCount !== 0 && (
                      <div className="d-flex mb-2 pl-1">
                        <div>
                          <i className="fad fa-users fa-fw"></i>
                        </div>
                        <div className="ml-2">
                          <p className=" mb-0">{props.jobData.positionCount}</p>
                          <p className="mb-0">
                            <small>Positions Count</small>
                          </p>
                        </div>
                      </div>
                    )}

                    {props.jobData.experienceLevel !== 0 && (
                      <div className="d-flex mb-2 pl-1">
                        <div>
                          <i className="fad fa-file-user"></i>
                        </div>
                        <div className="ml-2">
                          <p className=" mb-0">
                            {props.jobData.experienceLevel}
                          </p>
                          <p className="mb-0">
                            <small>Experience</small>
                          </p>
                        </div>
                      </div>
                    )}

                    {props.jobData.jobTenant && (
                      <div className="d-flex mb-2 pl-1">
                        <div>
                          <i className="fad fa-building fa-fw"></i>
                        </div>
                        <div className="ml-2">
                          <p className=" mb-0">{props.jobData.jobTenant}</p>
                          <p className="mb-0">
                            <small>Client</small>
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : null}
                <div className="mb-2">
                  <button
                    type="button"
                    className="btn btn-collapsible"
                    onClick={() => {
                      skills
                        ? onCollapsibleClick("skillsReset")
                        : onCollapsibleClick("skills");
                    }}
                  >
                    {skills === true ? (
                      <i
                        if="!collapsible.skills"
                        className="fal fa-angle-up fa-fw"
                        onClick={() => {
                          onCollapsibleClick("skillsReset");
                        }}
                      />
                    ) : (
                      <i
                        className="fal fa-angle-down fa-fw"
                        onClick={() => {
                          onCollapsibleClick("skills");
                        }}
                      />
                    )}
                    <span>
                      <small>Required Skills</small>
                    </span>
                  </button>
                </div>
                {skills && (
                  <div className="mb-4 ml-4">
                    <div className="d-flex mb-2 pl-1">
                      <div className="ml-2">
                        {props.fullText.primarySkills.map((item) => (
                          <span className="tag tag-blue3 mb-1 mr-1">
                            {item}
                          </span>
                        ))}
                        <p className="mb-0">
                          <small>Mandatory Skills</small>
                        </p>
                      </div>
                    </div>
                    {props.jobData.secondarySkills &&
                      props.jobData.secondarySkills.length > 0 && (
                        <div className="d-flex mb-2 pl-1">
                          <div className="ml-2">
                            {props.jobData.secondarySkills.map((item) => (
                              <span className="tag tag-blue3 mb-1 mr-1">
                                {item}
                              </span>
                            ))}
                            <p className="mb-0">
                              <small>Good to have skills</small>
                            </p>
                          </div>
                        </div>
                      )}
                  </div>
                )}

                {props.jobData.jobDescription && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() => {
                        description
                          ? onCollapsibleClick("descriptionReset")
                          : onCollapsibleClick("description");
                      }}
                    >
                      {description ? (
                        <i
                          if="!collapsible.description"
                          aria-hidden="true"
                          className="fal fa-angle-up fa-fw"
                          onClick={() => {
                            onCollapsibleClick("descriptionReset");
                          }}
                        />
                      ) : (
                        <i
                          aria-hidden="true"
                          className="fal fa-angle-down fa-fw"
                          onClick={() => {
                            onCollapsibleClick("description");
                          }}
                        />
                      )}
                      <span>
                        <small>Description</small>
                        <i
                          className="fas fa-external-link-square"
                          onClick={() => setLgShow(true)}
                          style={{
                            fontSize: "14px",
                            color: "#FF5F57",
                            marginLeft: "5px",
                          }}
                        ></i>
                      </span>
                    </button>
                  </div>
                )}

                {description && (
                  <div className="mb-3 ml-4">
                    <p
                      className="m-3"
                      dangerouslySetInnerHTML={{
                        __html: props.jobData.jobDescription,
                      }}
                    ></p>
                  </div>
                )}
              </div>
            )}

            {props.details === true && (
              <div>
                {props.jobData.education &&
                  props.jobData.education.length !== 0 && (
                    <div className="mb-2">
                      <button
                        type="button"
                        className="btn btn-collapsible"
                        onClick={() => {
                          education
                            ? onCollapsibleClick("educationReset")
                            : onCollapsibleClick("education");
                        }}
                      >
                        {education === true ? (
                          <i
                            if="!collapsible.education"
                            className="fal fa-angle-up fa-fw"
                            onClick={() => {
                              onCollapsibleClick("educationReset");
                            }}
                          />
                        ) : (
                          <i
                            className="fal fa-angle-down fa-fw"
                            onClick={() => {
                              onCollapsibleClick("education");
                            }}
                          />
                        )}
                        <span>
                          <small>Education</small>
                        </span>
                      </button>
                    </div>
                  )}

                {education === true ? (
                  <div className="mb-3 ml-4">
                    {props.jobData.education.map((item) => (
                      <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
                    ))}
                  </div>
                ) : null}

                {props.jobData.certifications &&
                  props.jobData.certifications.length !== 0 && (
                    <div className="mb-2">
                      <button
                        type="button"
                        className="btn btn-collapsible"
                        onClick={() => {
                          certifications
                            ? onCollapsibleClick("certificationsReset")
                            : onCollapsibleClick("certifications");
                        }}
                      >
                        {!certifications === true ? (
                          <i
                            if="!collapsible.certifications"
                            className="fal fa-angle-down fa-fw"
                            onClick={() => {
                              onCollapsibleClick("certificationsReset");
                            }}
                          />
                        ) : (
                          <i
                            className="fal fa-angle-up fa-fw"
                            onClick={() => {
                              onCollapsibleClick("certifications");
                            }}
                          />
                        )}
                        <span>
                          <small>Certifications</small>
                        </span>
                      </button>
                    </div>
                  )}
                {certifications && (
                  <div className="mb-3 ml-4">
                    {props.jobData.certifications &&
                      props.jobData.certifications.map((item) => (
                        <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
                      ))}
                  </div>
                )}

                {props.jobData.licenceRequirement && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() => {
                        setLicences(!licences);
                      }}
                    >
                      <i
                        className={
                          !licences
                            ? "fal fa-angle-down fa-fw"
                            : "fal fa-angle-up fa-fw"
                        }
                      />
                      <span>
                        <small>Licence Requirement</small>
                      </span>
                    </button>
                  </div>
                )}

                {licences && (
                  <div className="mb-3 ml-4">
                    {props.jobData.licenceRequirement.charAt(0) === "[" ? (
                      JSON.parse(
                        props.jobData.licenceRequirement
                      ).map((item) => (
                        <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
                      ))
                    ) : (
                      <span className="tag tag-blue3 mb-1 mr-1">
                        {props.jobData.licenceRequirement}
                      </span>
                    )}
                  </div>
                )}

                {props.jobData.visaRequirement && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() => {
                        setVisa(!visa);
                      }}
                    >
                      <i
                        className={
                          !visa
                            ? "fal fa-angle-down fa-fw"
                            : "fal fa-angle-up fa-fw"
                        }
                      />
                      <span>
                        <small>Visa Requirement</small>
                      </span>
                    </button>
                  </div>
                )}

                {visa && (
                  <div className="mb-3 ml-4">
                    {props.jobData.visaRequirement.charAt(0) === "[" ? (
                      JSON.parse(props.jobData.visaRequirement).map((item) => (
                        <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
                      ))
                    ) : (
                      <span className="tag tag-blue3 mb-1 mr-1">
                        {props.jobData.visaRequirement}
                      </span>
                    )}
                  </div>
                )}

                {props.jobData.industries.length !== 0 && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() =>
                        industries
                          ? onCollapsibleClick("industriesReset")
                          : onCollapsibleClick("industries")
                      }
                    >
                      {!industries === true ? (
                        <i
                          if="!collapsible.industries"
                          className="fal fa-angle-down fa-fw"
                          onClick={() => {
                            onCollapsibleClick("industriesReset");
                          }}
                        />
                      ) : (
                        <i
                          className="fal fa-angle-up fa-fw"
                          onClick={() => {
                            onCollapsibleClick("industries");
                          }}
                        />
                      )}
                      <span>
                        <small>Industry</small>
                      </span>
                    </button>
                  </div>
                )}

                {industries === true ? (
                  <div className="mb-3 ml-4">
                    {props.jobData.industries.map((item) => (
                      <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
                    ))}
                  </div>
                ) : null}

                {props.jobData.travel && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() =>
                        travel
                          ? onCollapsibleClick("travelReset")
                          : onCollapsibleClick("travel")
                      }
                    >
                      {travel === true ? (
                        <i
                          if="!collapsible.travel"
                          className="fal fa-angle-up fa-fw"
                          onClick={() => {
                            onCollapsibleClick("travelReset");
                          }}
                        />
                      ) : (
                        <i
                          className="fal fa-angle-down fa-fw"
                          onClick={() => {
                            onCollapsibleClick("travel");
                          }}
                        />
                      )}
                      <span>
                        <small>Travel</small>
                      </span>
                    </button>
                  </div>
                )}
                {travel === true ? (
                  <div className="mb-3 ml-4">
                    <p>{props.jobData.travel}</p>
                  </div>
                ) : null}

                {(props.jobData.workStart || props.jobData.workEnd) && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() =>
                        workHours
                          ? onCollapsibleClick("workHoursReset")
                          : onCollapsibleClick("workHours")
                      }
                    >
                      {!workHours === true ? (
                        <i
                          if="!collapsible.workHours"
                          className="fal fa-angle-up fa-fw"
                          onClick={() => {
                            onCollapsibleClick("workHoursReset");
                          }}
                        />
                      ) : (
                        <i
                          className="fal fa-angle-down fa-fw"
                          onClick={() => {
                            onCollapsibleClick("workHours");
                          }}
                        />
                      )}
                      <span>
                        <small>Work Hours</small>
                      </span>
                    </button>
                  </div>
                )}

                {workHours && (
                  <div className="mb-3 ml-4">
                    <p>
                      {props.jobData.workStart + " to " + props.jobData.workEnd}
                    </p>
                  </div>
                )}
                {props.jobData.drugTest && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() => setDrugTest(!drugTest)}
                    >
                      {drugTest ? (
                        <i className="fal fa-angle-up fa-fw" />
                      ) : (
                        <i className="fal fa-angle-down fa-fw" />
                      )}
                      <span>
                        <small>Drug Test</small>
                      </span>
                    </button>
                  </div>
                )}
                {drugTest ? (
                  <div className="mb-3 ml-4">
                    <span>
                      {props.jobData.drugTest === true ? (
                        <span className="tag tag-green2">Yes</span>
                      ) : (
                        <span className="tag tag-red1">No</span>
                      )}
                    </span>
                  </div>
                ) : null}
                {props.jobData.backgroundCheck === true && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() =>
                        backgroundCheck
                          ? onCollapsibleClick("backgroundCheckReset")
                          : onCollapsibleClick("backgroundCheck")
                      }
                    >
                      {backgroundCheck === true ? (
                        <i
                          if="!collapsible.backgroundCheck"
                          className="fal fa-angle-up fa-fw"
                          onClick={() => {
                            onCollapsibleClick("backgroundCheckReset");
                          }}
                        />
                      ) : (
                        <i
                          className="fal fa-angle-down fa-fw"
                          onClick={() => {
                            onCollapsibleClick("backgroundCheck");
                          }}
                        />
                      )}
                      <span>
                        <small>Background Check</small>
                      </span>
                    </button>
                  </div>
                )}
                {backgroundCheck === true ? (
                  <div className="mb-3 ml-4">
                    <span>
                      {props.jobData.backgroundCheck === true ? (
                        <span className="tag tag-green2">Yes</span>
                      ) : (
                        <span className="tag tag-red1">No</span>
                      )}
                    </span>
                  </div>
                ) : null}

                {props.jobData.securityClearance !== "" && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() =>
                        securityClearance
                          ? onCollapsibleClick("securityClearanceReset")
                          : onCollapsibleClick("securityClearance")
                      }
                    >
                      {securityClearance === true ? (
                        <i
                          if="!collapsible.securityClearance"
                          className="fal fa-angle-up fa-fw"
                          onClick={() => {
                            onCollapsibleClick("securityClearanceReset");
                          }}
                        />
                      ) : (
                        <i
                          className="fal fa-angle-down fa-fw"
                          onClick={() => {
                            onCollapsibleClick("securityClearance");
                          }}
                        />
                      )}
                      <span>
                        <small>Security Clearance</small>
                      </span>
                    </button>
                  </div>
                )}
                {securityClearance === true ? (
                  <div className="mb-3 ml-4">
                    <p>{props.jobData.securityClearance}</p>
                  </div>
                ) : null}
                {props.jobData.submittal !== "" && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() =>
                        submittal
                          ? onCollapsibleClick("submittalReset")
                          : onCollapsibleClick("submittal")
                      }
                    >
                      {submittal === true ? (
                        <i
                          className="fal fa-angle-up fa-fw"
                          onClick={() => {
                            onCollapsibleClick("submittalReset");
                          }}
                        />
                      ) : (
                        <i
                          className="fal fa-angle-down fa-fw"
                          onClick={() => {
                            onCollapsibleClick("submittal");
                          }}
                        />
                      )}
                      <span>
                        <small>Submittal Instructions</small>
                      </span>
                    </button>
                  </div>
                )}
                {submittal && (
                  <div className="mb-3 ml-4">
                    <p>{props.jobData.submittal}</p>
                  </div>
                )}
              </div>
            )}

            {props.qnA && (
              <>
                {props.jobData.screeningRequired && (
                  <div className="mb-3 ml-4">
                    <p>{props.jobData.screeningRequired}</p>
                  </div>
                )}

                {//props.jobData.screeningRequired &&
                // <button type="button" className="btn btn-collapsible" onClick={() => customInfo ? onCollapsibleClick('customInfoReset') : onCollapsibleClick('customInfo')}></button>

                props?.QandADetails?.QandA?.length > 0 && (
                  <button
                    onClick={() => setQandA(!qAndA)}
                    type="button"
                    className="btn btn-collapsible mb-2"

                    // onClick={()=> !qAndA ? (
                    //     <i className="fal fa-angle-up fa-fw mt-1"></i>
                    // ) : (
                    //     <i className="fal fa-angle-down fa-fw"></i>
                    // )}
                  >
                    {qAndA ? (
                      <i className="fal fa-angle-up fa-fw mt-1"></i>
                    ) : (
                      <i className="fal fa-angle-down fa-fw"></i>
                    )}
                    <span>
                      <small>
                        Screening Questions
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            setQandA(!qAndA);
                          }}
                        >
                          &nbsp;
                          <i className="fas fa-external-link-square"></i>
                        </a>
                      </small>
                    </span>
                  </button>
                )}

                {/* {props.jobData.secondarySkills.length > 0 && (
                                            <div className="d-flex mb-2 pl-1">
                                                <div className="ml-2">
                                                    {
                                                        props.fullText.secondarySkills.map((item) => (
                                                            <span className="tag tag-blue3 mb-1 mr-1">{item}</span>
                                                        ))
                                                    }
                                                    <p className="mb-0"><small>Secondary Skills</small></p>
                                                </div>
                                            </div>
                                        )}   */}
                {props.jobData.vettingDetails && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() =>
                        prevettedTalants
                          ? onCollapsibleClick("prevettedTalantsReset")
                          : onCollapsibleClick("prevettedTalants")
                      }
                    >
                      {prevettedTalants === true ? (
                        <i
                          className="fal fa-angle-up fa-fw"
                          onClick={() => {
                            onCollapsibleClick("prevettedTalantsReset");
                          }}
                        />
                      ) : (
                        <i
                          className="fal fa-angle-down fa-fw"
                          onClick={() => {
                            onCollapsibleClick("prevettedTalants");
                          }}
                        />
                      )}
                      <span>
                        <small>
                          Vetting Requirement
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              setPrevettedTalants(true);
                            }}
                          >
                            &nbsp;
                            <i className="fas fa-external-link-square"></i>
                          </a>
                        </small>
                      </span>
                    </button>
                  </div>
                )}

                {/* {  prevettedTalants &&
                    props.jobData.vettingDetails.map((skill, index) => 
                        <div className='row mb-3'>
                            <div className='col-lg-2'>
                                <small>{skill.name}</small>
                            </div>  
                            <div className='col-lg-3'>
                              <small>{skill.type}</small>
                            </div>
                            <div className='col-lg-3'>
                              <small>{skill.difficulty || 'Simple'}</small>              
                            </div>
                            <div className='col-lg-3 d-flex'>
                              <small>{skill.duration || '15 mins'}</small>                                   
                            </div>                                                         
                        </div>
                    )
                }  */}

                {
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() =>
                        automatchData
                          ? onCollapsibleClick("automatchDataReset")
                          : onCollapsibleClick("automatchData")
                      }
                    >
                      <span>
                        <i className="fal fa-angle-down fa-fw" />
                        <small>
                          Automatched Parameters
                          <a
                            href="/"
                            onClick={(e) => {
                              e.preventDefault();
                              setAutomatchData(true);
                            }}
                          >
                            &nbsp;
                            <i className="fas fa-external-link-square"></i>
                          </a>
                        </small>
                      </span>
                    </button>
                  </div>
                }
              </>
            )}

            {props.notes && (
              <div className="row mb-2">
                <div className="col-lg-12">
                  {props.jobData.notes ? (
                    JSON.parse(props.jobData.notes).map((item) => (
                      <div className="d-flex mb-2">
                        <Avatar size="30" name={item.name} round="60px" />
                        <div className="ml-2">
                          <p className="m-0">
                            <strong>{item.name}</strong>
                            <small className="ml-2">
                              {moment(item.createdDate).format("MM/DD/YYYY")}
                            </small>
                            <p className="">
                              <small>{item.comments}</small>
                            </p>
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="mb-3">
                      <div className="text-center p-3">
                        <div className="avatar avatar-lg">
                          <i className="fad fa-users-slash"></i>
                        </div>
                        <div className="mt-2">No Record Found</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            {props.document && (
              <>
                {props.jdDocument.length !== 0 ? (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() =>
                        document
                          ? onCollapsibleClick("documentReset")
                          : onCollapsibleClick("document")
                      }
                    >
                      {document === true ? (
                        <i
                          className="fal fa-angle-up fa-fw"
                          onClick={() => {
                            onCollapsibleClick("documentReset");
                          }}
                        />
                      ) : (
                        <i
                          className="fal fa-angle-down fa-fw"
                          onClick={() => {
                            onCollapsibleClick("document");
                          }}
                        />
                      )}
                      <span>
                        <small>Document </small>
                      </span>
                    </button>
                  </div>
                ) : (
                  <div className="mb-3">
                    <div className="text-center p-3">
                      <div className="avatar avatar-lg">
                        <i className="fad fa-users-slash"></i>
                      </div>
                      <div className="mt-2">No Record Found</div>
                    </div>
                  </div>
                )}
                {document && (
                  <>
                    <div className="row mb-2">
                      <div className="col-lg-12">
                        {
                          <div className="mb-2">
                            {
                              <div className="mb-2">
                                {jdDocument.map(
                                  (eachFile, i) =>
                                    eachFile.type !== "Video" && (
                                      <div>
                                        {/* <div className="mr-3 ml-1">
                                                                    <div className="avatar avatar-sm">{i + 1}</div>
                                                                </div> */}
                                        <div className="mt-1">
                                          <span
                                            className="underlined ml-4"
                                            title="View Document"
                                            style={{ color: "gray" }}
                                          >
                                            {/* {eachFile.fileName} */}
                                            {eachFile.category ||
                                              "Job Description"}
                                            <a
                                              href="/"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                window.open(
                                                  eachFile.fileURL,
                                                  "_blank"
                                                );
                                              }}
                                            >
                                              <i className="fas fa-external-link-square ml-1"></i>
                                            </a>
                                          </span>
                                          {/* <a href='eachFile.fileName'  style="background: #0c99d5; color: #fff; text-decoration: none; border: 10px solid #0c99d5; border-left-width: 20px; border-right-width: 20px; text-transform: uppercase; display: inline-flex;">
                                                                        Click Here</a> */}
                                        </div>
                                      </div>
                                    )
                                )}
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <Modal
        size="lg"
        show={lgShow}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <h6 className="pt-o mt-3">Job Description</h6>
        </Modal.Header>
        <Modal.Body>
          <Card className="m-3">
            <Card.Text className="p-3">
              <p
                className=""
                dangerouslySetInnerHTML={{
                  __html: props.jobData.jobDescription,
                }}
              ></p>
            </Card.Text>
          </Card>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="secondary" onClick={() => setLgShow(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={qAndA}>
        <Modal.Header>
          <h6 class="">Screening Questions</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setQandA(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {props.QandADetails && props.QandADetails.QandA.length > 0 ? (
            <div class="d-flex mb-2 pl-1 ml-4">
              <ul>
                {props.QandADetails.QandA.map((item, index) => (
                  <li className="d-flex m-3">
                    <div className="">
                      <span className="col-lg-1">{index + 1}</span>
                      {/* <span className='col-lg-11'>{item.question}</span> */}
                    </div>
                    <div className="ml-3 mb-2">
                      <span className="col-lg-11 mb-1">{item.question}</span>
                      <br />
                      <small>
                        {item.name === "Language" ||
                        item.name === "Industry Experience" ||
                        item.name === "Education"
                          ? "Response: "
                          : "Response Type:"}{" "}
                        {item.name === "Language" ? (
                          <strong>{JSON.parse(item.input).join(", ")}</strong>
                        ) : (
                          <strong>{item.input || "Yes/No"}</strong>
                        )}
                      </small>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="mb-3">
              <div class="text-center p-3">
                <div class="avatar avatar-lg">
                  <i class="fad fa-users-slash"></i>
                </div>
                <div class="mt-2">No Screening Questions Added</div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="secondary" onClick={() => setQandA(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={additionalInfo}>
        <Modal.Header>
          <h6 class="">Additional Info</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setAdditionalInfo(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          {props.QandADetails && props.QandADetails.additionalInfo ? (
            <div class="d-flex mt-3 mb-2 pl-1 ml-4">
              <ul>
                {props.QandADetails.additionalInfo.map((item, index) => (
                  <li className="d-flex mb-3">
                    <div className="">
                      <span>{index + 1}</span>
                    </div>
                    <div className="ml-3">
                      <div className="mb-1">{item.question}</div>
                      <div className="d-flex">
                        <small>Response:</small>
                        <small className="ml-2 mr-4">
                          <p>
                            <strong>{item.answer}</strong>
                          </p>
                        </small>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <div className="mb-3">
              <div class="text-center p-3">
                <div class="avatar avatar-lg">
                  <i class="fad fa-users-slash"></i>
                </div>
                <div class="mt-2">No AdditionalInfo Added</div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="secondary" onClick={() => setAdditionalInfo(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={automatchData}
        onHide={() => setAutomatchData(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header closeButton>
          <h6 className="pt-o mt-3">Automatched Parameters</h6>
        </Modal.Header>
        <Modal.Body>
          <SubmittalDetailsInfo
            addJobData={props.jobData}
            weightage={
              props.jobData.weightage || {
                primarySkills: [],
                secondarySkills: [],
                jobTitle: true,
                location: true,
                experienceLevel: true,
                education: [],
                industries: [],
              }
            }
            setWeightage={(data) => null}
            component={"SideBar"}
          />
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="secondary" onClick={() => setAutomatchData(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="lg"
        show={prevettedTalants}
        onHide={() => setLgShow(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <Modal.Header>
          <h6 className="pt-o mt-3">Vetting Requirement</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setPrevettedTalants(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="card card-flat bg-gray4 m-3">
            <div className="card-body">
              {props.jobData.vettingDetails?.length > 0 && (
                <>
                  <div className="row mb-3 px-3">
                    <div className="col-lg-2">
                      <strong>Skill</strong>
                    </div>
                    <div className="col-lg-3">
                      <strong>Type</strong>
                    </div>
                    <div className="col-lg-3">
                      <strong>Difficulty</strong>
                    </div>
                    <div className="col-lg-3">
                      <strong>Duration</strong>
                    </div>
                  </div>
                  <hr />
                  {props.jobData.vettingDetails?.map((skill, index) => (
                    <div className="row mb-3 px-3">
                      <div className="col-lg-2">{skill.name}</div>
                      <div className="col-lg-3">
                        <select className="form-select font-14" disabled>
                          <option value="MCQ" selected={skill.type === "MCQ"}>
                            MCQ
                          </option>
                          <option
                            value="OneWay"
                            selected={skill.type === "OneWay"}
                          >
                            OneWay
                          </option>
                          <option
                            value="General"
                            selected={skill.type === "General"}
                          >
                            General
                          </option>
                        </select>
                      </div>
                      <div className="col-lg-3">
                        <select className="form-select font-14" disabled>
                          {["Simple", "Medium", "Advanced"].map((item) => (
                            <option
                              value={item}
                              selected={
                                skill.difficulty === item ||
                                (item === "Simple" && !skill.difficulty)
                              }
                            >
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-lg-3 d-flex">
                        <select className="form-select font-14" disabled>
                          <option>{skill.duration} mins</option>
                          {/* {["10", "15", "30", "45", "60"].map((item) => (
                            <option
                              value={item}
                              selected={
                                skill.duration === item ||
                                (item === "15 mins" && !skill.duration)
                              }
                            >
                              {item} mins
                            </option>
                          ))} */}
                        </select>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="secondary"
            onClick={() => setPrevettedTalants(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RequestSideBar;
