import React, { useState, useEffect } from "react";
import MPMDashboardAnalytics from "../Analytics/MPMDashboardAnalytics";
import EADashboardAnalytics from "../Analytics/EADashboardAnalytics";
import CSMDashboardAnalytics from "../Analytics/CSMDashboardAnalaytics";
import RecruiterLoginAnalytics from "../Analytics/RecruiterLoginDashboard";
import CandidateLoginAnalytics from "../Analytics/CandidateLoginDashboardAnalytics";
import AddJobAnalytics from "../Analytics/AddJobAnalytics";
import AddTalentAnalytics from "../Analytics/AddTalentAnalytics";
import AddRecruiterAnalytics from "../Analytics/AddRecruiterAnalytics";
import FormAnalytics from "../Analytics/growthexpansion/FormAnalytics";
import RecruiterDashboardAnalytics from "../Analytics/RecruitmentDashboardAnalytics";
import Loader from "../../../Loader/Loader";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  getAnalyticsData,
  getGrowthExpansionAnalytics,
  getFormAnalytics,
  getRecruiterAnalytics,
} from "../../../../actions/analytics/getAnalyticsData";

const Dashboard = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState({});
  const [growthData, setGrowthData] = useState({});
  const [formData, setFormData] = useState({});
  const [recruiterData, setRecruiterData] = useState({});
  const [api1Checked, setApi1Checked] = useState(false);
  const [api2Checked, setApi2Checked] = useState(false);
  const [api3Checked, setApi3Checked] = useState(false);
  const [api4Checked, setApi4Checked] = useState(false);

  const {
    analyticsData,
    growthExpansionAnalyticsData,
    formAnalyticsData,
    recruiterAnalyticsData,
  } = useSelector(
    (state) => ({
      analyticsData: state.getAnalyticsReducer.analyticsData,
      growthExpansionAnalyticsData:
        state.getGrowthExpansionReducer.growthExpansionAnalyticsData,
      formAnalyticsData: state.getFormReducer.formAnalyticsData,
      recruiterAnalyticsData:
        state.getRecruiterAnalyticsReducer.recruiterAnalyticsData,
    }),
    shallowEqual
  );
  useEffect(() => {
    dispatch(getAnalyticsData());
    dispatch(getGrowthExpansionAnalytics());
    dispatch(getFormAnalytics());
    dispatch(getRecruiterAnalytics());
  }, []);

  useEffect(() => {
    if (analyticsData) {
      setGraphData(analyticsData);
      setApi1Checked(true);
    }
    if (growthExpansionAnalyticsData) {
      setGrowthData(growthExpansionAnalyticsData);
      setApi2Checked(true);
    }
    if (formAnalyticsData) {
      setFormData(formAnalyticsData);
      setApi3Checked(true);
    }
    if (recruiterAnalyticsData) {
      setRecruiterData(recruiterAnalyticsData);
      setApi4Checked(true);
    }
  }, [
    analyticsData,
    growthExpansionAnalyticsData,
    formAnalyticsData,
    recruiterAnalyticsData,
  ]);

  useEffect(() => {
    if (api1Checked && api2Checked && api3Checked && api4Checked) {
      setLoading(false);
    }
  }, [api1Checked, api2Checked, api3Checked, api4Checked]);
  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="analytics-container">
            <RecruiterLoginAnalytics data={graphData} />
            <CandidateLoginAnalytics data={graphData} />
            <MPMDashboardAnalytics data={graphData} />
            <EADashboardAnalytics data={graphData} />
            <CSMDashboardAnalytics data={graphData} />
          </div>
          {/* <Tabs
            defaultActiveKey="userEngagement"
            id="analyticTabs"
            className="mb-3"
          >
            <Tab eventKey="userEngagement" title="User Engagement"></Tab>
            <Tab eventKey="growthAndExpansion " title="Growth and Expansion ">
              <div className="analytics-container">
                <AddJobAnalytics data={growthData} />
                <AddRecruiterAnalytics data={growthData} />
                <AddTalentAnalytics data={growthData} />
              </div>
            </Tab>
          </Tabs> */}
        </>
      )}
    </>
  );
};

export default Dashboard;
