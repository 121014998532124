import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Qualified from "./Qualified";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";
import { getUserRole } from "../../../../../../service/AuthService";
import { getDispositionDetails } from "../../../../../../actions/disposition/Disposition";
import { useParams } from "react-router";
import PaginationComponent from "../../../../pagination/pagination";

const Index = (props) => {
  const {
    jobData,
    fetchLatestEmailStatus,
    calculateScore,
    value,
    view,
  } = props;
  const [qualifiedData, setQualifiedData] = useState([]);
  const { Id } = useParams();
  const dispatch = useDispatch();
  const [paginationArray, setPaginationArray] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    dispositionDetails,
    dispositionCount,
    dispositionCountLoading,
  } = useSelector(
    (state) => ({
      dispositionDetails: state.dispositionDetails.dispositionDetails,
      dispositionCount: state.dispositionCount.dispositionCount,
      dispositionCountLoading: state.dispositionCount.dispositionCountLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let isCancelled = false;

    const timeoutId = setTimeout(() => {
      if (!isCancelled) {
        if (
          paginationArray.length > 0 &&
          !paginationArray[0].hasOwnProperty("latestEmailStatus")
        ) {
          fetchLatestEmailStatus(
            paginationArray,
            setPaginationArray,
            "Qualified"
          );
        }
      }
    }, 1000); // 1 seconds timeout

    return () => {
      isCancelled = true;
      clearTimeout(timeoutId);
    };
  }, [paginationArray]);

  useEffect(() => {
    setQualifiedData(
      dispositionDetails.filter(
        (item) => item.dispositionStatus == 33 || item.dispositionStatus == 38
      )
    );
  }, [dispositionDetails, value]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: view,
      jobId: Id,
    };
    dispatch(getDispositionDetails(payload));
  };

  return (
    <div className="table-wrap">
      {qualifiedData.length > 0 ? (
        <>
          <table className="table table-sortable table-cards-1024">
            <thead>
              <tr className="d-none d-lg-table-row">
                <th className="align-items-start">Candidate</th>
                <th>Location</th>
                <th>Invited on</th>
                <th>Invited by</th>
                {getUserRole() === "HiringManager" && (
                  <th className="no-hover">Email Status </th>
                )}
                <th>Score</th>
                <th className="ml-2">Status</th>
              </tr>
            </thead>
            <tbody>
              <Qualified
                jobData={jobData}
                qualifiedData={qualifiedData}
                calculateScore={calculateScore}
                view={view}
              />
            </tbody>
          </table>
          {/* <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setPaginationArray={setPaginationArray}
            data={qualifiedData}
            dataSize={15}
            getTab=""
            pageSize={5}
          /> */}
          <PaginationComponent
            currentPage={currentPage}
            totalItems={
              dispositionCount?.filter((e) => e.Status == "Qualified")[0]?.Count
            }
            itemsPerPage={15}
            onPageChange={handlePageChange}
            limit={15}
          />
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

export default Index;
