import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import SearchHistory from "../modal/SearchHistory";
import OutsideClickHandler from "react-outside-click-handler";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { domain, apiDomain } from "../../constants/Constants";
import {
  getCandidateName,
  getUserId,
  getUserRole,
  getUserName,
  loginUserName,
} from "../../service/AuthService";
import { get, post } from "../../service/ApiService";
import axios from "axios";
import SupportModal from "../layout/SupportModal";
import Loader from "../Loader/Loader";
import { SidebarLogo, ga_tracking_id } from "../../constants/Constants";
import Avatar from "react-avatar";
import Event from "../../googleanalytic/Tracking";
import ReactGa from "react-ga";
import Notifications from "../layout/Notifications";
import { getNotificationList } from "../../actions/notification/notifications";
import BulkCommunicationModal from "../components_v3/talent/talentcommunity/communication/BulkCommunicationModal";

function TopNavBar(props) {
  ReactGa.initialize(ga_tracking_id);
  const dispatch = useDispatch();
  const history = useHistory();
  const [toggle, setToggle] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toggleProfile, setToggleProfile] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const [toggleNotification, setToggleNotification] = useState(false);
  const [openSearchHistory, setOpenSearchHistory] = useState(false);
  const [serchInput, setSerchInput] = useState("");
  const [isFetching, setIsFetching] = useState(false);

  const [userprofile, setProfile] = useState({ name: "" });
  const { name } = userprofile;
  const [support, setSupport] = useState([]);
  const [supportModal, setSupportModal] = useState(false);
  const [supportType, setSupportType] = useState(0);
  const [enableSupportSearch, setEnableSupportSearch] = useState(false);
  const [supportData, setSupportData] = useState([]);
  const [empData, setEmpData] = useState([]);
  const [toggleRole, setToggleRole] = useState(false);
  const [roleModel, setRoleModel] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedRole, setSelectedRole] = useState("");
  const [selectedTenant, setSelectedTenant] = useState("");
  const [supportSearchValue, setSupportSearchValue] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [isFrom, setIsFrom] = useState("");
  const { notificationData } = useSelector(
    (state) => ({
      notificationData: state.getNotificationsReducer.notificationData,
    }),
    shallowEqual
  );

  // useEffect(() => {
  //   const fetchNotifications = async () => {
  //     if (!notificationData?.notificationPref || isFetching) {
  //       // If notificationPref is false or a fetch is already in progress, return early
  //       return;
  //     }

  //     try {
  //       setIsFetching(true); // Set the flag to indicate that a fetch is in progress

  //       // Dispatch an action to request notifications
  //       dispatch(getNotificationList());

  //       // Check if the API response sets notificationPref to false
  //       if (notificationData?.notificationPref === false) {
  //         clearInterval(interval); // Stop the interval if notificationPref becomes false
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     } finally {
  //       setIsFetching(false); // Reset the flag after the fetch is complete
  //     }
  //   };

  //   // Initialize the interval to fetch notifications every 60 seconds
  //   const interval = setInterval(fetchNotifications, 60000);

  //   // Fetch notifications immediately
  //   fetchNotifications();

  //   // Clean up the interval when the component unmounts
  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, [notificationData?.notificationPref]);
  useEffect(() => {
    const username = getCandidateName();
    username.substr(0, username.indexOf(" "));
    setProfile({ name: username });
    fetchEmpDetails();
    fetchSupportAPI();
  }, []);

  useEffect(() => {
    const adminTokenDetails = localStorage.getItem("adminUserToken");
    if (!adminTokenDetails) {
      const adminUserToken = localStorage.getItem("userToken");
      localStorage.setItem("adminUserToken", adminUserToken);
    }
  }, []);

  const fetchSupportAPI = async () => {
    setLoading(true);
    let id = JSON.parse(localStorage.getItem("userToken")).roleId;
    const res = await get("/support");
    setSupport(res.data.filter((s) => s.fk_role.includes(id)));
    setLoading(false);
  };

  const fetchEmpDetails = async () => {
    try {
      //const userId = getUserId;
      let res = await get("/h5coordinator/fetchemployee");
      if (res.status === 200) {
        setEmpData(res.data[0]);
      } else {
        toast.error("Some Error Occured while accessing Employee Details");
      }
    } catch (err) {
      toast.error("Some Error Occured while accessing Employee Details");
    }
  };

  const clientChange = async (e) => {
    setUserRoles([]);
    setSelectedRole("");
    setUsers([]);
    setSelectedUser("");
    if (e.target.value) {
      setSelectedTenant(e.target.value);
      const roles = new Set(
        empData
          .filter((eachdata) => eachdata.tenantid === e.target.value)
          .map((eachdata) => eachdata.rolename)
      );
      setUserRoles([...roles]);
    } else {
      setSelectedTenant(e.target.value);
    }
  };

  const handleRoleChange = async (e) => {
    setUsers([]);
    setSelectedUser("");
    if (e.target.value) {
      setSelectedRole(e.target.value);
      const employees = empData.filter((item) => {
        if (
          item.rolename === e.target.value &&
          item.tenantid === selectedTenant
        ) {
          return item;
        }
      });
      setUsers(employees);
    } else {
      setSelectedRole(e.target.value);
    }
  };

  const updateSelectedUser = async (e) => {
    if (e.target.value) {
      setSelectedUser(e.target.value);
      const employees = empData.filter((item) => {
        if (item.userId == e.target.value) {
          return item;
        }
      });
      if (employees.length > 0) {
        setSelectedEmail(employees[0].email);
      }
    } else {
      setSelectedUser(e.target.value);
    }
  };

  const toggleProfileDropdown = () => {
    if (toggleProfile === false) {
      setToggleProfile(true);
      setToggleHelp(false);
      setToggleNotification(false);
    } else {
      setToggleProfile(false);
    }
  };

  const openHelp = () => {
    if (toggleHelp === false) {
      setToggleHelp(true);
      setToggleProfile(false);
      setToggleNotification(false);
    } else {
      setToggleHelp(false);
    }
  };
  const openRole = () => {
    if (toggleRole === false) {
      setToggleRole(true);
      setToggleProfile(false);
      setToggleNotification(false);
    } else {
      setToggleRole(false);
    }
  };

  const openNotification = () => {
    if (toggleNotification === false) {
      setToggleNotification(true);
      setToggleHelp(false);
      setToggleProfile(false);
    } else {
      setToggleNotification(false);
    }
  };

  const searchCliked = () => {
    setOpenSearchHistory(true);
  };

  const handleClickOutside = () => {
    setOpenSearchHistory(false);
  };

  const handleSearch = (e) => {
    setSerchInput(e.target.value);
    setOpenSearchHistory(true);
  };

  const onClickLogout = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    const headers = { authorization: token };
    dispatch(handleLogout({ headers }));
  };

  const handleLogout = (header) => async (dispatch) => {
    try {
      const res = await axios.put(`${apiDomain}/logout`, null, header);
      let role = getUserRole();
      let name = loginUserName();
      let result = `${name},${role}`;
      if (res.status === 200) {
        Event("Logout", result);
        localStorage.clear();
        history.push("/login");
      }
    } catch (error) {
      toast.error("Unble to Logout!");
    }
  };

  const openSupportModal = (e) => {
    e.preventDefault();
    setSupportType(+Object.values(e.target)[0].key);
    setSupportModal(true);
  };

  const handleSupportSearch = (e) => {
    setSupportSearchValue(e.target.value);
    setEnableSupportSearch(true);
    setSupportData(support.filter((i) => i.Title.includes(e.target.value)));
  };

  const closeSupportModal = () => {
    setSupportModal(false);
  };

  const handleSubmit = async () => {
    if (selectedUser) {
      const payload = {
        userName: selectedEmail,
        domain: domain,
        role: getUserRole(),
        actualUserName: getUserName(),
        candidateName: getCandidateName(),
        userId: getUserId(),
      };
      try {
        setLoading(true);
        let res = await post("/login/dashboardlogin", payload);
        if (res.status === 200) {
          localStorage.setItem("userToken", JSON.stringify(res.data));
          window.location.reload(true);
        }
        setLoading(false);
      } catch {
        toast.error("Error!!");
        setLoading(false);
      }
    } else {
      toast.error("please select employee .");
    }
  };

  const splitMatchedText = (str) => {
    const regExp = new RegExp(supportSearchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='background: blue; color: white;'>${match}</span>`
    );
  };
  const clearAll = () => {
    setSelectedTenant();
    setSelectedRole();
  };

  return (
    <>
      {loading && <Loader />}
      <div id="TopBar" style={{ left: 0, width: "100%" }}>
        <div className="nav-toggle-wrap pl-1" style={{ width: "20%" }}>
          <div className="brand-logo">
            <img src={SidebarLogo} alt="" width="35" height="35" />
          </div>
          <div className="title px-2 d-none-600">
            <h6 className="mb-0">{getCandidateName()}</h6>
            <small className="mb-0">Customer Success Manager</small>
          </div>
        </div>
        <div className="flex-grow-1">
          <div className="container-fluid">
            <Row className="row align-items-center justify-content-center">
              <Col>
                <div
                  className="form-floating d-none-1024"
                  style={{ height: "100%", width: "130px" }}
                >
                  <select
                    className="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    value={selectedTenant}
                    onChange={(e) => clientChange(e)}
                    style={{
                      fontSize: "14px",
                      height: "calc(3.2rem)",
                      borderRadius: "0px",
                      paddingTop: "1.2rem",
                    }}
                  >
                    <option value="" selected>
                      Select Client
                    </option>
                    {props.tenantData.map((item) => (
                      <option value={item.id_tenant}>{item.tenantname}</option>
                    ))}
                  </select>
                  <label for="floatingSelect">Client</label>
                </div>
              </Col>
              <Col>
                <div
                  className="form-floating d-none-1024"
                  style={{ height: "100%", width: "150px" }}
                >
                  <select
                    className="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    value={selectedRole}
                    onChange={(e) => handleRoleChange(e)}
                    style={{
                      fontSize: "14px",
                      height: "calc(3.2rem)",
                      width: "calc(10rem)",
                      borderRadius: "0px",
                      paddingTop: "1.2rem",
                    }}
                  >
                    <option value="" selected>
                      Select Role
                    </option>
                    {userRoles.map((item) => (
                      <option value={item}>
                        {item === "HiringManager" ? "Hiring Manager" : item}
                      </option>
                    ))}
                  </select>
                  <label for="floatingSelect">Role</label>
                </div>
              </Col>
              <Col>
                <div
                  className="form-floating  d-none-1024"
                  style={{ height: "100%", width: "160px" }}
                >
                  <select
                    className="form-select"
                    id="floatingSelect"
                    aria-label="Floating label select example"
                    value={selectedUser}
                    onChange={(e) => updateSelectedUser(e)}
                    style={{
                      fontSize: "14px",
                      height: "calc(3.2rem)",
                      borderRadius: "0px",
                      paddingTop: "1.2rem",
                    }}
                  >
                    <option value="" selected>
                      Select Employee
                    </option>
                    {users.map((item) => (
                      <option value={item.userId}>
                        {item.firstName + " " + item.lastName}
                      </option>
                    ))}
                  </select>
                  <label for="floatingSelect">Employee</label>
                </div>
              </Col>
              <Col>
                  <button
                    type="Submit"
                    className="btn btn-primary btn-sm d-none-1024"
                    onClick={() => handleSubmit()}
                    style={{
                      width: "52px",
                      marginTop: "auto", 
                      marginLeft: "auto",
                    }}
                    disabled={selectedUser === ""}
                  >
                    Go
                  </button>
              </Col>
            </Row>
          </div>
        </div>
        <div className="extras ml-auto text-right">
          {/* EmailBroadcast div started  */}
          {/* <span
            title="Broadcast"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
          >
            <a href="/high5hire/broadcast" className="EmailBroadcastIcon">
              <i class="fa fa-comments-o" aria-hidden="true"></i>
            </a>{" "}
          </span> */}
          {/* EmailBroadcast div ended  */}
          <button
            className="btn btn-action-panel btn-icon btn-nav"
            id="roleSelection"
            type="button"
            onClick={openRole}
          >
            <i className="fal fa-fw fa-user-secret" />
          </button>
          {toggleRole && (
            <div
              className="action-panel"
              style={{
                display: "revert",
                right: "0px",
                top: "50px",
                textAlign: "left",
              }}
            >
              <OutsideClickHandler
                onOutsideClick={() => !roleModel && setToggleRole(false)}
              >
                <>
                  <Row>
                    <Col>
                      <div className="form-floating">
                        <select
                          className="font-14 form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          onChange={(e) => clientChange(e)}
                          style={{
                            fontSize: "14px",
                            height: "calc(3.2rem)",
                            borderRadius: "0px",
                            paddingTop: "1.2rem",
                          }}
                          //disabled={selectedTenant !== undefined}
                        >
                          <option value="" selected>
                            Select Client
                          </option>
                          {props.tenantData.map((item) => (
                            <option value={item.id_tenant}>
                              {item.tenantname.replace("GIG - ", "")}
                            </option>
                          ))}
                        </select>
                        <label for="floatingSelect">Client</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-floating">
                        <select
                          className="font-14 form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          onChange={(e) => handleRoleChange(e)}
                          style={{
                            fontSize: "14px",
                            height: "calc(3.2rem)",
                            borderRadius: "0px",
                            paddingTop: "1.2rem",
                          }}
                        >
                          <option value="selectedRole" selected>
                            Select Role
                          </option>
                          {userRoles.map((item) => (
                            <option value={item}>{item}</option>
                          ))}
                        </select>
                        <label for="floatingSelect">Role</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="font-14 form-floating">
                        <select
                          className="form-select"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          // onChange={(e) => props.handleChange(e)}
                          onChange={(e) => updateSelectedUser(e)}
                          style={{
                            fontSize: "14px",
                            height: "calc(3.2rem)",
                            borderRadius: "0px",
                            paddingTop: "1.2rem",
                          }}
                        >
                          <option value="" selected>
                            Select Employee
                          </option>
                          {users.map((item) => (
                            <option value={item.userId}>
                              {item.firstName + " " + item.lastName}
                            </option>
                          ))}
                        </select>
                        <label for="floatingSelect">Employee</label>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="font-green">
                      <div className="">
                        <input
                          type="Submit"
                          className="btn btn-primary btn-sm"
                          value={"Go"}
                          onClick={() => handleSubmit()}
                          style={{
                            width: "52px",
                            marginTop: "12px",
                            marginLeft: "20px",
                          }}
                        />
                      </div>
                    </Col>
                    <Col>
                      <button
                        type="button"
                        style={{ padding: "5px", marginTop: "12px" }}
                        onClick={clearAll}
                        className="btn btn-xs"
                      >
                        Clear all
                      </button>
                    </Col>
                  </Row>
                </>
              </OutsideClickHandler>
            </div>
          )}
          <button
            type="button"
            title="Communication"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
            onClick={() => {
              setShowEmailModal(true);
              setIsFrom("topNavbar");
            }}
          >
            <div class="notification-icon">
              {" "}
              <i class="fal fa-fw fa-envelope"></i>
            </div>
          </button>
          {/* Notification div started  */}
          {
            <button
              type="button"
              title="Notifications"
              className="btn btn-action-panel btn-icon btn-nav ml-3"
              onClick={() => setToggleNotification(!toggleNotification)}
            >
              {/* <i className="fal fa-fw fa-bell"></i>
            <span class="jewel"></span> */}

              <div class="notification-icon">
                {" "}
                <i class="fal fa-fw fa-bell"></i>{" "}
                {notificationData?.data?.filter(
                  (n) => n.notificationStatus === "unread"
                )?.length > 0 && (
                  <span class="notification-count">
                    {
                      notificationData?.data?.filter(
                        (n) => n.notificationStatus === "unread"
                      )?.length
                    }
                  </span>
                )}
              </div>
            </button>
          }
          {toggleNotification && (
            <Notifications
              supportModal={supportModal}
              notificationData={notificationData}
              setToggleNotification={setToggleNotification}
            />
          )}{" "}
          {/* Notification div ended  */}
          {/* Help div started  */}
          <button
            type="button"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
            title="Support"
            onClick={openHelp}
          >
            <i className="fal fa-fw fa-life-ring"></i>
          </button>
          {toggleHelp && (
            <div
              className="action-panel"
              style={{
                display: "revert",
                right: "0px",
                top: "50px",
                textAlign: "left",
              }}
            >
              <OutsideClickHandler
                onOutsideClick={() => !supportModal && setToggleHelp(false)}
              >
                <div
                  className="action-panel"
                  style={{
                    display: "revert",
                    right: "0px",
                    top: "50px",
                    textAlign: "left",
                  }}
                >
                  <div className="py-3 px-4">
                    <p className="lead mb-0">Hello {getCandidateName()}</p>
                    <strong>What can we help you with today?</strong>
                    <div className="search-field mb-3 mt-2">
                      <div className="field-wrap">
                        <input
                          type="search"
                          onChange={handleSupportSearch}
                          placeholder="Search Support"
                        />
                        <span className="icon">
                          <i aria-hidden="true" className="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                    <strong>
                      <small>Common Questions</small>
                    </strong>
                    <ul className="mt-1 ml-0 list-unstyled">
                      {enableSupportSearch
                        ? supportData.length !== 0
                          ? supportData.map((s, i) => (
                              <li>
                                <a
                                  key={i}
                                  onClick={openSupportModal}
                                  href=""
                                  dangerouslySetInnerHTML={{
                                    __html: splitMatchedText(s.Title),
                                  }}
                                ></a>
                              </li>
                            ))
                          : "No Questions available "
                        : support.length !== 0
                        ? support.map((s, i) => (
                            <li>
                              <a key={i} onClick={openSupportModal} href="">
                                {s.Title}
                              </a>
                            </li>
                          ))
                        : "No Questions available "}
                    </ul>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          )}{" "}
          {/* Help div ended  */}
          {/* Profile div started  */}
          <div className="dropdown ml-3">
            <button
              className="btn btn-icon dropdown-toggle"
              type="button"
              onClick={toggleProfileDropdown}
            >
              <Avatar
                className="avatar avatar-sm "
                name={userprofile.name}
                round="80px"
              />
            </button>

            {toggleProfile && (
              <div
                className="dropdown-menu"
                style={{ display: "revert", right: "-14px", top: "30px" }}
              >
                <a href="/high5hire/myprofile" className="dropdown-item">
                  <i className="fal fa-user-circle mr-3"></i>Profile
                </a>
                {/* <a href="/high5hire/myprofile" className="dropdown-item" ><i className="fal fa-user-circle mr-3"></i>Profile</a> */}
                {/* <a href="#top" className="dropdown-item" ><i className="fal fa-cog mr-3"></i>Preferences</a> */}
                <hr />
                <a
                  href="#top"
                  className="dropdown-item"
                  onClick={onClickLogout}
                >
                  <i className="fal fa-sign-out mr-3"></i> Logout{" "}
                </a>
              </div>
            )}
            {/* Profile div ended */}
          </div>
        </div>
      </div>
      {showEmailModal && (
        <BulkCommunicationModal
          showjobModal={showEmailModal}
          setShowJobModal={setShowEmailModal}
          isFrom={isFrom}
        />
      )}
      <OutsideClickHandler onOutsideClick={handleClickOutside}>
        {openSearchHistory && <SearchHistory serchInput={serchInput} />}
      </OutsideClickHandler>
      {supportModal && (
        <SupportModal
          supportData={support[supportType]}
          closeSupportModal={closeSupportModal}
        />
      )}
    </>
  );
}

export default TopNavBar;
