import React, { useState, useEffect, useRef } from "react";
import SearchHistory from "../modal/SearchHistory";
import OutsideClickHandler from "react-outside-click-handler";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiDomain, BotImage, ChatImage } from "../../constants/Constants";
import {
  getCandidateName,
  getUserToken,
  getUserRole,
  getOwner,
  getActualRole,
} from "../../service/AuthService";
import { get } from "../../service/ApiService";
import SupportModal from "./SupportModal";
import useOutsideClick from "../function/useOutsideClick";
import Avatar from "react-avatar";
import Modal from "react-bootstrap/Modal";
import Event from "../../googleanalytic/Tracking";
import { loginUserName } from "../../service/AuthService";
import ReactGa from "react-ga";
import Notifications from "./Notifications";
import BulkCommunicationModal from "../components_v3/talent/talentcommunity/communication/BulkCommunicationModal";

function TopNavBar(props) {
  const {
    showSearch,
    isSearchExpanded,
    setIsSearchExpanded,
    searchInput,
    handleSearchType,
    handleToggale,
  } = props;
  const TRACKING_ID = "UA-223748254-1";
  ReactGa.initialize(TRACKING_ID);
  const dispatch = useDispatch();

  const history = useHistory();
  const [toggle, setToggle] = useState(true);
  const [toggleProfile, setToggleProfile] = useState(false);
  const [toggleHelp, setToggleHelp] = useState(false);
  const [toggleNotification, setToggleNotification] = useState(false);
  const [userprofile, setProfile] = useState({ name: "" });
  const [support, setSupport] = useState([]);
  const [supportModal, setSupportModal] = useState(false);
  const [supportType, setSupportType] = useState(0);
  const [supportSearchValue, setSupportSearchValue] = useState("");
  const [enableSupportSearch, setEnableSupportSearch] = useState(false);
  const [supportData, setSupportData] = useState([]);
  const [openDropDownDiv, setOpenDropDownDiv] = useState(false);
  const [isAdminUserLoggedIn, setIsAdminUserLoggedIn] = useState();
  const [adminUserDetails, setAdminUserDetails] = useState({});
  const [userRole, setUserRole] = useState("");
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [bot, setBot] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [isFrom, setIsFrom] = useState("");

  const profileImagePath = JSON.parse(localStorage.getItem("userToken"))
    .profileImagePath;

  // ####################
  // main toggle function
  // ####################
  //  for autosearch close suggesstions
  const ref = useRef();
  const ref1 = useRef();
  const ref2 = useRef();
  useOutsideClick(ref, () => {
    setOpenDropDownDiv(false);
  });
  useOutsideClick(ref1, () => {
    setToggleProfile(false);
  });
  useOutsideClick(ref2, () => {
    setToggleHelp(false);
  });

  const { notificationData, notificationLoading } = useSelector(
    (state) => ({
      notificationData: state.getNotificationsReducer.notificationData,
    }),
    shallowEqual
  );

  const toglleDiv = () => {
    if (toggle === false) {
      handleToggale(true);
      setToggle(true);
    } else {
      handleToggale(false);
      setToggle(false);
    }
  };

  const toggleProfileDropdown = () => {
    if (toggleProfile === false) {
      setToggleProfile(true);
      setToggleHelp(false);
      setToggleNotification(false);
    } else {
      setToggleProfile(false);
    }
  };

  const openHelp = () => {
    setToggleHelp(true);
    setToggleProfile(false);
    setToggleNotification(false);
    setToggleHelp(false);
  };

  const onClickLogout = (e) => {
    e.preventDefault();
    const token = getUserToken();
    const headers = { authorization: token };
    dispatch(handleLogout({ headers }));
  };

  const handleLogout = (header) => async (dispatch) => {
    try {
      const res = await axios.put(`${apiDomain}/logout`, null, header);
      let role = getUserRole();
      let name = loginUserName();
      let result = `${name},${role}`;
      if (res.status === 200) {
        Event("Logout", result, window.location.pathname);
        localStorage.clear();
        history.go("/login");
      }
    } catch (error) {
      toast.error("Unable to Logout!");
      console.log(error);
    }
  };

  const searchIconStyle = {
    borderRadius: "50%",
  };
  const expandSearch = () => {
    setIsSearchExpanded(!isSearchExpanded);
  };
  const ChangeUser = (e) => {
    let actRole = getActualRole();
    localStorage.setItem("userToken", adminUserDetails);
    // redirect to dashboard page
    if (
      actRole === "SuperAdmin" ||
      actRole === "CustomerSuccess" ||
      actRole === "CommunityManager"
    )
      history.push({ pathname: "/high5Hire", state: { tab: "users" } });
    else history.push("/high5Hire");
    window.location.reload(true);
  };

  const fetchSupportAPI = async (isAdmin) => {
    setLoading(true);
    let id = JSON.parse(localStorage.getItem("userToken")).roleId;
    await get("/support").then((data) => {
      setSupport(data.data.filter((s) => s.fk_role.includes(id)));
      setLoading(false);
    });
  };

  useEffect(() => {
    if (
      getUserRole() === "CustomerSuccess" ||
      getUserRole() === "SuperAdmin" ||
      getUserRole() === "CommunityManager"
    ) {
      const adminTokenDetails = localStorage.getItem("adminUserToken");
      if (!adminTokenDetails) {
        const adminUserToken = localStorage.getItem("userToken");
        localStorage.setItem("adminUserToken", adminUserToken);
      }
    }
  }, []);
  useEffect(() => {
    const username = getCandidateName();
    username.substr(0, username.indexOf(" "));
    setProfile({ name: username });
    const role = getUserRole();
    setUserRole(role);
  }, []);

  useEffect(() => {
    //  change value
    const adminTokenDetails = localStorage.getItem("adminUserToken");
    if (adminTokenDetails) {
      setIsAdminUserLoggedIn(true);
      setAdminUserDetails(adminTokenDetails);
      fetchSupportAPI(true);
    } else {
      setAdminUserDetails("");
      setIsAdminUserLoggedIn(false);
      fetchSupportAPI(false);
    }
  }, []);

  const openSupportModal = (e) => {
    e.preventDefault();
    setSupportType(+Object.values(e.target)[0].key);
    setSupportModal(true);
  };

  const roundedInputStyle = {
    borderRadius: "15px",
    transition: "width 0.5s",
  };

  const handleInputFocus = () => {
    setIsSearchExpanded(true);
  };

  const handleSupportSearch = (e) => {
    setSupportSearchValue(e.target.value);
    setEnableSupportSearch(true);
    setSupportData(
      support.filter((i) =>
        i.Title.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const closeSupportModal = () => {
    setSupportModal(false);
  };

  const splitMatchedText = (str) => {
    const regExp = new RegExp(supportSearchValue, "gi");
    return str.replace(
      regExp,
      (match) => `<span style='background: blue; color: white;'>${match}</span>`
    );
  };

  const avatarOptions = {
    botAvatarInitials: "BF",
    userAvatarInitials: "WC",
  };

  return (
    <>
      <div id="TopBar">
        {/* **** toggle div for main menu  ***** */}
        <div class="nav-toggle-wrap">
          <button
            id="MainMenuToggle"
            onClick={toglleDiv}
            type="button"
            class="btn btn-icon nav-toggle btn-nav"
          >
            <i class="fal fa-angle-left" aria-hidden="true"></i>
          </button>
          <div class="divider"></div>
        </div>

        {/* SEARCH JOB by Candidates */}
        {false && userRole === "Candidate" && (
          <div className="container-fluid">
            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-lg-6">
                <div className="search-field">
                  <div className="field-wrap">
                    <input
                      type="text"
                      placeholder="Search Jobs"
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          if (e.target.value.length < 3) {
                            toast.error("Please type atleast 3 characters");
                          } else {
                            localStorage.setItem("search", e.target.value);
                            //window.open('/high5Hire/searchjobs', '_self')
                          }
                        }
                      }}
                    ></input>
                  </div>
                </div>
              </div>
              <div className="col-lg-2">
                <button
                  title="Search"
                  onClick={() => {
                    if (search.length < 3) {
                      toast.error("Please type atleast 3 characters");
                    } else {
                      localStorage.setItem("search", search);
                    }
                  }}
                  className="btn btn-icon"
                >
                  <i aria-hidden="true" className="fas fa-search"></i>
                </button>
              </div>
            </div>
          </div>
        )}
        {showSearch && (
          <div className="d-flex w-100 d-block-768 justify-content-end align-items-center gap-3 align-self-center">
            {" "}
            <div
              className="d-flex justify-content-end align-items-center gap-2"
              style={{ maxWidth: "500px", minWidth: "250px" }}
            >
              <div>
                <span
                  className="rounded-circle"
                  style={searchIconStyle}
                  onClick={expandSearch}
                >
                  <i className="fa fa-search "></i>
                </span>
              </div>
              <input
                type="text"
                className={`form-control ${
                  isSearchExpanded ? "w-100" : "w-50"
                }`}
                style={{ ...roundedInputStyle }}
                placeholder="Search"
                onFocus={handleInputFocus}
                value={searchInput}
                onChange={handleSearchType}
              />
            </div>
          </div>
        )}
        {/* search bar div ended */}
        <div
          style={{ maxWidth: 250 }}
          className="extras ml-auto text-right  mr-3"
        >
          {/* Back div started  */}
          {isAdminUserLoggedIn &&
            userRole !== "CommunityManager" &&
            userRole !== "High5Coordinator" &&
            userRole !== "CustomerSuccess" &&
            userRole !== "SuperAdmin" && (
              <button
                type="button"
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Back"
                className="mbtn btn-sm w-auto btn-text-accent dd-sort  "
                onClick={(e) => ChangeUser()}
              >
                Back
              </button>
            )}
          {/* Back div ended  */}
          {/* EmailBroadcast div started  */}
          {/* <button
            title="Broadcast"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
            onClick={() => {
              history.replace({ ...location, state: null });
            }}
          >
            <a href="/high5hire/broadcast" className="EmailBroadcastIcon">
              <i className="fa fa-comments-o" aria-hidden="true"></i>
            </a>
          </button> */}
          {/* EmailBroadcast div ended  */}
          {/* Notification div started  */}
          {/* {props.isFrom === "SubCommDisp" && (
            <button
              type="button"
              title="Details"
              className="btn btn-action-panel btn-icon btn-nav ml-3"
              onClick={() => props?.setShowDetails(true)}
            >
              <i class="fal fa-fw fa-eye"></i>{" "}
            </button>
          )} */}
          <button
            type="button"
            title="Communication"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
            onClick={() => {
              setShowEmailModal(true);
              setIsFrom("topNavbar");
            }}
          >
            <div class="notification-icon">
              {" "}
              <i class="fal fa-fw fa-envelope"></i>
            </div>
          </button>
          <button
            type="button"
            title="Notifications"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
            onClick={() => setToggleNotification(!toggleNotification)}
          >
            <div class="notification-icon">
              {" "}
              <i class="fal fa-fw fa-bell"></i>{" "}
              {notificationData?.data?.filter(
                (n) => n.notificationStatus === "unread"
              )?.length > 0 && (
                <span class="notification-count">
                  {
                    notificationData?.data?.filter(
                      (n) => n.notificationStatus === "unread"
                    )?.length
                  }
                </span>
              )}
            </div>
          </button>
          {toggleNotification && (
            <Notifications
              notificationLoading={notificationLoading}
              supportModal={supportModal}
              notificationData={notificationData}
              setToggleNotification={setToggleNotification}
            />
          )}{" "}
          {/* Notification div ended  */} {/* Help div started  */}
          <button
            type="button"
            title="Support"
            className="btn btn-action-panel btn-icon btn-nav ml-3"
            onClick={() => setToggleHelp(!toggleHelp)}
          >
            <i className="fal fa-fw fa-life-ring"></i>
          </button>
          {toggleHelp && (
            <div
              className="action-panel"
              style={{
                display: "revert",
                right: "0px",
                top: "50px",
                textAlign: "left",
              }}
            >
              <OutsideClickHandler
                onOutsideClick={() => !supportModal && setToggleHelp(false)}
              >
                <div></div>
                <div
                  className="action-panel"
                  style={{
                    display: "revert",
                    right: "0px",
                    top: "50px",
                    textAlign: "left",
                  }}
                >
                  <div className="py-3 px-4">
                    <p className="lead mb-0">Hello {getCandidateName()}</p>
                    <strong>What can we help you with today?</strong>
                    <div className="search-field mb-3 mt-2">
                      <div className="field-wrap">
                        <input
                          type="search"
                          onChange={handleSupportSearch}
                          placeholder="Search Support"
                        />
                        <span className="icon">
                          <i aria-hidden="true" className="fas fa-search"></i>
                        </span>
                      </div>
                    </div>
                    <strong>
                      <small>Common Questions</small>
                    </strong>
                    <ul className="mt-1 ml-0 list-unstyled">
                      {enableSupportSearch
                        ? supportData.length !== 0
                          ? supportData.map((s, i) => (
                              <li>
                                <a
                                  key={i}
                                  onClick={openSupportModal}
                                  href=""
                                  dangerouslySetInnerHTML={{
                                    __html: splitMatchedText(s.Title),
                                  }}
                                ></a>
                              </li>
                            ))
                          : "No Questions available "
                        : support.length !== 0
                        ? support.map((s, i) => (
                            <li>
                              <a key={i} onClick={openSupportModal} href="">
                                {s.Title}
                              </a>
                            </li>
                          ))
                        : "No Questions available "}
                    </ul>
                  </div>
                </div>
              </OutsideClickHandler>
            </div>
          )}{" "}
          {/* Help div ended  */}
          {/* Profile div started  */}
          <div className="dropdown ml-3">
            <button
              className="btn btn-icon dropdown-toggle"
              type="button"
              ref={ref1}
              onClick={toggleProfileDropdown}
            >
              {profileImagePath ? (
                <img
                  src={profileImagePath}
                  alt=""
                  width="30"
                  height="30"
                  style={{
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Avatar
                  className="avatar avatar-sm "
                  name={userprofile.name}
                  round="80px"
                />
              )}
            </button>
            <div>
              {toggleProfile && (
                <div
                  className="dropdown-menu"
                  style={{ display: "revert", right: "-14px", top: "30px" }}
                >
                  {!window.location.href.includes("/myprofile") && (
                    <>
                      {" "}
                      <a href="/high5hire/myprofile" className="dropdown-item">
                        <i className="fal fa-user-circle mr-3"></i>Profile
                      </a>
                      <hr />
                    </>
                  )}
                  {getOwner() &&
                    !window.location.href.includes("/users") &&
                    userRole !== "HiringManager" && userRole !== "Recruiter" && (
                      <>
                        <a href="/high5hire/users" className="dropdown-item">
                          <i className="fal fa-user-circle mr-3"></i>Users
                        </a>
                        <hr />
                      </>
                    )}{" "}
                  <a
                    href="#top"
                    className="dropdown-item"
                    onClick={onClickLogout}
                  >
                    <i className="fal fa-sign-out mr-3"></i> Logout{" "}
                  </a>
                </div>
              )}
            </div>
            {/* Profile div ended */}
          </div>
        </div>
      </div>

      {openDropDownDiv && <SearchHistory searchInput={searchInput} />}

      {supportModal && (
        <SupportModal
          supportData={support[supportType]}
          closeSupportModal={closeSupportModal}
        />
      )}
      {showEmailModal && (
        <BulkCommunicationModal
          isModalOpen={showEmailModal}
          setModalClose={setShowEmailModal}
          modalSize={"xl"}
          isFrom={isFrom}
        />
      )}
      <Modal show={bot}>
        <Modal.Header>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => {
              setBot(false);
            }}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="bl-modal-body  text-center">
            <div className="avatar avatar-lg avatar-green1 mr-3">
              <img src={ChatImage} />
            </div>
          </div>
          <iframe
            src="https://webchat.botframework.com/embed/tesktmaker-bot?s=93Oks9s9wyE.ftP4MgM9ZhhjGjWkhvwhHqZcK7mklyKAxoDi5XeGIKs"
            width="100%"
            height="350px"
            styleOptions="avatarOptions"
            //frameBorder='10px'
          ></iframe>
        </Modal.Body>
        <div className="d-flex align-items-center justify-content-center m-3"></div>
      </Modal>
    </>
  );
}

export default TopNavBar;
