import {
    GET_SAVED_CAREER_COUNT_BEGIN,
    GET_SAVED_CAREER_COUNT_SUCCESS,
    GET_SAVED_CAREER_COUNT_FAILURE,
  } from "../../actions/jobList/CareerFilterSavedJLCount";
  
  const initialState = {
    careerCountLoading: true,
    careerCount: [],
  };
  
  const SavedCareerCountReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_SAVED_CAREER_COUNT_BEGIN:
        return {
          ...state,
          careerCountLoading: true,
          careerCount: [],
        };
      case GET_SAVED_CAREER_COUNT_SUCCESS:
        return {
          ...state,
          careerCount: payload,
          careerCountLoading: false,
        };
      case GET_SAVED_CAREER_COUNT_FAILURE:
        return {
          ...state,
          careerCount: [],
          careerCountLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default SavedCareerCountReducer;
  