import React, { useState, useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { template, jobDetail } from "../../../../../actions/job/AddJob";
import Pagination from "../../../../components_v3/pagination/pagination";

const SelectJob = ({
  data,
  getJobs,
  searchValue,
  setTemplateSelected,
  jobListCounts,
  sortArray,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();

  const setJobDetails = (item) => {
    dispatch(template(true));
    dispatch(jobDetail(item));
    if (setTemplateSelected) {
      setTemplateSelected(true);
    }
  };

  const getJobCount = (val) => {
    return jobListCounts && jobListCounts[0] && jobListCounts[0][val];
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getJobs(pageNumber, sortArray);
  };

  return (
    <>
      <div className="m-3">
        {data?.map((request, i) => (
          <a
            key={i}
            onClick={() => setJobDetails(request)}
            className="card card-flat bg-gray4 m-1 p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
          >
            <div className="card-body">
              <div className="d-flex align-items-center">
                <span className="mr-3">
                  <h6>{request?.jobTitle} </h6>
                </span>
                {request.isHotJob && (
                  <span className="tag tag-coral mr-9">HOT</span>
                )}
              </div>
              <div className="d-flex align-items-center">
                <span className="d-flex align-items-center me-3">
                  <i className="far fa-suitcase me-2 font-brandBlue"></i>
                  <small className="font-regular font-primary font-gray1">
                    {request.jobType}
                  </small>
                </span>
                <span className="d-flex align-items-center me-3">
                  <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                  <span className="font-regular font-primary font-gray1">
                    {request?.workPlaceType}
                  </span>
                </span>
              </div>
            </div>
          </a>
        ))}
        {data.length === 0 && (
          <div>
            <div class="text-center p-3">
              <div class="avatar avatar-lg">
                <i class="fad fa-users-slash"></i>
              </div>
              <div class="mt-2">
                No records found {searchValue && "for " + searchValue}
              </div>
            </div>
          </div>
        )}
        <hr />
        {data.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalItems={getJobCount("AllJobs")}
            itemsPerPage={10}
            onPageChange={handlePageChange}
            limit={5}
          />
        )}
      </div>
    </>
  );
};

export default SelectJob;
