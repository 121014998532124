import React, { useState, useEffect, useRef } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Submitted from "./Submitted";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";
import Pagination from "../../../../commoncomponents/pagination/Pagination";
import { getUserRole } from "../../../../../../service/AuthService";
import PaginationComponent from "../../../../pagination/pagination";
import { getPoolDispositionCount } from "../../../../../../actions/directsourcing/PoolDispositionCount";
import { useParams } from "react-router";
import { getPoolDispositionDetails } from "../../../../../../actions/directsourcing/Disposition";
import Loader from "../../../../commoncomponents/Loader/Loader";
import { post } from "../../../../../../service/ApiService";
import { toast } from "react-toastify";

const Index = (props) => {
  const {
    jobData,
    //dispositionDetails,
    fetchLatestEmailStatus,
    view,
    currentPage,
    setCurrentPage,
    filterLength,
    selectedTalents,
    setSelectedTalents,
  } = props;

  const selectAllRef = useRef(null);
  const dropdownRef = useRef(null);
  const [submittedData, setSubmittedData] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [toggleActions, setToggleActions] = useState(false);
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [dataTotal, setDataTotal] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { poolId } = useParams();

  /*** Sort Param  * */
  const [nameSortSelected, setNameSortSelected] = useState(null);
  const [dateSortSelected, setDateSortSelected] = useState(null);
  const [sortSelected, setSortSelected] = useState({
    firstName: false,
    date: false,
  });
  const [sortArray, setSortArray] = useState([]);
  /*** Sort Param  * */

  const {
    dispositionDetails,
    dispositionLoading,
    dispositionDisqualifyLoading,
    dispositionDisqualify,
    dispositionCount,
    dispositionCountLoading,
  } = useSelector(
    (state) => ({
      dispositionDetails: state.poolDisposition.dispositionDetails,
      dispositionLoading: state.poolDisposition.dispositionDetailsLoading,
      dispositionDisqualifyLoading:
        state.poolDispositionDisqualify.dispositionDisqualifyLoading,
      dispositionDisqualify:
        state.poolDispositionDisqualify.dispositionDisqualify,
      dispositionCount: state.poolDispositionCount.dispositionCount,
      dispositionCountLoading:
        state.poolDispositionCount.dispositionCountLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    if (
      paginationArray.length > 0 &&
      !paginationArray[0].hasOwnProperty("latestEmailStatus")
    ) {
      fetchLatestEmailStatus(paginationArray, setPaginationArray, "Engaged");
    }
  }, [paginationArray]);

  useEffect(() => {
    const filteredData = dispositionDetails.filter((item) =>
      [6, 42].includes(item.dispositionStatus)
    );
    setSubmittedData(filteredData);
    setCandidateProfile(false);
  }, [dispositionDetails]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      // candidateProfile &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setToggleActions(false);
    }
    if (selectAllRef.current && !selectAllRef.current.contains(event.target)) {
      setToggleActions(false);
    }
  };

  const handleSelectAll = () => {
    try {
      let payload = {
        currentPage: currentPage,
        limit: 15,
        status: view,
        poolId: poolId,
        filterObject: props.filterParams,
      };
      setIsLoading(true);
      post("/bulkTalentAction/getpooldispositionfilterdetails", payload).then(
        (res) => {
          let data = Array.isArray(res.data) ? res.data : [];
          setSelectedTalents(data);
          setIsLoading(false);
        }
      );
    } catch (error) {
      toast.error("Something went wrong");
      setIsLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: view,
      poolId: poolId,
      filterObject: props.filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionDetails(payload));
  };
  const toggleActionsDropdown = () => {
    if (toggleActions === false) {
      setToggleActions(true);
    } else {
      setToggleActions(false);
    }
  };

  /*** Sort Param  * */
  useEffect(() => {
    dispatch(
      getPoolDispositionDetails({
        currentPage,
        limit: 15,
        status: view,
        poolId,
        filterObject: props.filterParams,
        searchText: props.value,
        sortParam: sortArray.length > 0 ? sortArray : [],
      })
    );
  }, [sortArray, sortSelected]);

  const handleSort = (sortValue) => {
    let sortType = "";
    let temp = { ...sortSelected };
    if (sortValue === "firstName") {
      sortType = !nameSortSelected
        ? "ASC"
        : nameSortSelected === "ASC"
        ? "DESC"
        : "ASC";
      setNameSortSelected(sortType);
      setDateSortSelected(null);
      temp = {
        firstName: true,
        date: false,
      };
      setSortSelected(temp);
    } else {
      sortType = !dateSortSelected
        ? "ASC"
        : nameSortSelected === "ASC"
        ? "DESC"
        : "ASC";
      setDateSortSelected(sortType);
      setNameSortSelected(null);
      temp = {
        firstName: false,
        date: true,
      };
      setSortSelected(temp);
    }
    setSortArray(sortValue ? [{ sortValue, sortType }] : []);
  };

  /*** Sort Param  * */

  return (
    <div>
      {(dispositionLoading ||
        dispositionCountLoading ||
        dispositionDisqualifyLoading ||
        isLoading) && <Loader />}
      {submittedData.length > 0 ? (
        <>
          <table className="table table-hoverable-cells table-sortable table-cards-1024">
            <thead>
              <tr>
                <th>
                  {dispositionCount?.filter((e) => e.Status == "Submitted")[0]
                    ?.Count > 0 && (
                    // filterLength > 0 &&
                    <div
                      className="d-flex align-items-start mb-1 justify-content-center h-100 dropdown"
                      ref={selectAllRef}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        style={{ width: "1.5rem", borderRadius: "100" }}
                        checked={
                          selectedTalents.length ===
                          dispositionCount?.filter(
                            (e) => e.Status == "Submitted"
                          )[0]?.Count
                        }
                        onClick={toggleActionsDropdown}
                      />
                      {toggleActions && (
                        <div
                          className="dropdown-menu"
                          style={{
                            display: "revert",
                            left: "-4px",
                            top: "32px",
                            zIndex: 89,
                          }}
                        >
                          <div>
                            <button
                              onClick={() => {
                                handleSelectAll();
                              }}
                              disabled={
                                selectedTalents.length ===
                                dispositionCount?.filter(
                                  (e) => e.Status == "Submitted"
                                )[0]?.Count
                              }
                              className="dropdown-item btn-xs"
                            >
                              Select All
                            </button>
                            <hr className="p-0 m-1" />
                          </div>{" "}
                          <div>
                            <button
                              onClick={() => {
                                setSelectedTalents([]);
                              }}
                              disabled={selectedTalents.length === 0}
                              className="dropdown-item btn-xs"
                            >
                              Deselect All
                            </button>
                          </div>{" "}
                        </div>
                      )}
                    </div>
                  )}
                </th>
                <th className="align-middle">
                  <div
                    class="d-flex align-items-center ml-2"
                    onClick={() => {
                      handleSort("firstName");
                    }}
                  >
                    Candidate{" "}
                    {!nameSortSelected ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "grey" }}
                        ></i>
                      </>
                    ) : nameSortSelected === "ASC" ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    ) : (
                      <>
                        <i
                          className="fad fa-sort-up font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    )}
                  </div>
                </th>
                {/* <th className="align-middle hover">Vetted</th> */}
                <th className="align-middle">
                  <div
                    class="d-flex align-items-center ml-2"
                    onClick={() => {
                      handleSort("submittedDate");
                    }}
                  >
                    Date{" "}
                    {!dateSortSelected ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "grey" }}
                        ></i>
                      </>
                    ) : dateSortSelected === "ASC" ? (
                      <>
                        <i
                          className="fad fa-sort-down ml-2 font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    ) : (
                      <>
                        <i
                          className="fad fa-sort-up font-16"
                          style={{ color: "orange" }}
                        ></i>
                      </>
                    )}
                  </div>
                </th>
                <th className="align-middle">Screening</th>
                <th className="align-middle no-hover">Resumes </th>
                <th className="align-middle">Open Link</th>
                {/* {getUserRole() == "Recruiter" && (
                  <th className="no-hover">Email Status </th>
                )} */}
                {jobData?.vettingDetails && (
                  <th className="align-middle no-hover">Status</th>
                )}
                {/* <th style={{ textAlign: "center" }}>Status</th> */}
                {/* <th className="no-hover">Score </th> */}
                {/* <th className="no-hover" /> */}
              </tr>
            </thead>
            <tbody>
              <Submitted
                jobData={jobData}
                submittedData={submittedData}
                calculateScore={props.calculateScore}
                filterParams={props?.filterParams}
                view={view}
                setCandidateProfile={setCandidateProfile}
                candidateProfile={candidateProfile}
                selectedTalents={selectedTalents}
                setSelectedTalents={setSelectedTalents}
                dispositionCount={dispositionCount}
                filterLength={filterLength}
              />
            </tbody>
          </table>
          {/* <Pagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setPaginationArray={setPaginationArray}
            data={submittedData}
            dataSize={15}
            getTab=""
            pageSize={5}
          /> */}
          <PaginationComponent
            currentPage={currentPage}
            totalItems={
              dispositionCount?.filter((e) => e.Status == "Submitted")[0]?.Count
            }
            itemsPerPage={15}
            onPageChange={handlePageChange}
            limit={15}
          />
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

export default Index;
