import React from "react";

const TemplateName = (props) => {
  return (
    <>
      <div className="card card-flat bg-gray4 ml-3 mr-3 mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="form-floating">
                <input
                  type="text"
                  className={
                    props?.isFormValid && props?.addJobVal.template === ""
                      ? "form-control border border-danger"
                      : "form-control"
                  }
                  name="Template"
                  placeholder="Enter a job title"
                  onChange={(e) => props?.setTemplateName(e.target.value)}
                  value={props?.templateName}
                  disabled={props?.editTemplate}
                />
                <label>Template Name*</label>
                {props?.isFormValid && props?.addJobVal.template === "" && (
                  <small className="ml-2 validation">*Required field</small>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateName;
