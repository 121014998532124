import React, { useState } from 'react'
import { ButtonLoader } from '../../../constants/Constants'
import { toast } from 'react-toastify';
import { post } from '../../../service/ApiService';
import { getCandidateId } from "../../../service/AuthService";

function Languages(props) {

  const [ readOnlyMode, setReadOnlyMode ] = useState(true)
  const [ submittingForm, setSubmittingFrom ] = useState(false)
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false)
  const [ saveClicked, setSaveClicked ]  = useState(true)
  const [ languages, setLanguages ] = useState(props.languages)
  const [ tempLanguages, setTempLanguages ] = useState(props.languages)

  const addLanguages = () => {
    let l = [...languages]
    l.push({
      languagename: "",
      read: "No",
      write: "No",
      speak: "No"
    })
    setLanguages(l)
  }

  const deleteLanguages = (index) => {
    let l = [...languages]
    l.splice(index, 1)
    setLanguages(l)
  }

  const editLanguages = () => {
    setReadOnlyMode(false)
    setSaveClicked(true)
  }

  const cancelLanguages = () => {
    setLanguages(tempLanguages)
    setReadOnlyMode(true)
    setShowSuccessMessage(false)
    setSubmittingFrom(false)
    setSaveClicked(true)
  }

  const saveLanguages = async () => {
    let condition1 = true
    let condition2 = true
    languages.forEach(language => {
      if(language.languagename === "") {
        condition1 = false
        return false
      }
    })
    languages.forEach(language => {
      if(language.read === "No" && language.write === "No" && language.speak === "No") {
        condition2 = false
        return false
      }
    })
    if(!condition1) {
      toast.error("Language Name is Mandatory")
    }
    else if(!condition2) {
      toast.error("Atleast one among Read, Write and Speak should be selected")
    }
    else {
      setSaveClicked(false)
      setSubmittingFrom(true)
      try {
        const parameters = {
          candidateID: getCandidateId(),
          clickName: 'language',
          userlanguagelist: languages
        }
        const res = await post('/candidateProfile/postLanguageData', parameters)
        if (res.status === 200) {
            setTempLanguages(languages)
            setSubmittingFrom(false)
            setShowSuccessMessage(true)      
            setTimeout(() => { 
              setReadOnlyMode(true)
              setShowSuccessMessage(false)
            }, 1000)
        }
      } catch (error) {
        toast.error("Error Occured. Please Try Again")
        setSaveClicked(true)
        setSubmittingFrom(false)
      }
    }
  }

  const handleChange = (name, value, index) => {
    let a = [...languages]
    if(name === 'languagename') {
      a[index] = { ...a[index], [name]: value }
    }
    else {
      let checked = value === true ? "Yes" : "No"
      a[index] = { ...a[index], [name]: checked }
    }  
    setLanguages(a)
  }

  return (
    <>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Languages</h6>
            {readOnlyMode === true ? (
              <button
                type="button"
                class="btn btn-text"
                onClick={editLanguages}
              >
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={cancelLanguages}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={saveLanguages}
                  class="btn btn-sm btn-primary"
                >
                  {saveClicked && <span>Save Changes</span>}

                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img width="20px" src={ButtonLoader} alt='' />
                    </span>
                  )}
                  {showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          <hr />
          {readOnlyMode ? (
            <div>
              <div class="d-flex">
                <div class="mr-3 d-flex flex-column align-items-center">
                  <i class="far fa-language fa-fw mt-1" aria-hidden="true"></i>
                </div>
                <div>
                  <p class="mb-1">
                    {
                      languages.map(language => 
                      <span class="tag tag-blue3 mr-1">{language.languagename}</span>)
                    }
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              { languages.map((language, index) => <div class="d-flex">
                <div class="mr-3">
                  <div class="avatar avatar-sm">{index+1}</div>
                </div>
                <div class="flex-grow-1">
                  <div class="row mb-2">
                    <div class="col-lg-12">
                      <div class="form-floating">
                        <input 
                          type="text" 
                          class="form-control" 
                          value={language.languagename} 
                          name="languagename"
                          onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                        />
                        <label>Add Language*</label>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div className='d-flex align-items-center m-1'>
                        <div>
                          Read
                        </div>
                        <div className='ml-auto'>
                          <div className='form-check form-switch'>
                            <input 
                              checked={language.read === 'Yes' ? true : false} 
                              type='checkbox' 
                              className='form-check-input' 
                              name='read'
                              onChange={(event) => handleChange(event.target.name, event.target.checked, index)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div className='d-flex align-items-center m-1'>
                        <div>
                          Write
                        </div>
                        <div className='ml-auto'>
                          <div className='form-check form-switch'>
                            <input 
                              checked={language.write === 'Yes' ? true : false} 
                              type='checkbox' 
                              className='form-check-input' 
                              name="write"
                              onChange={(event) => handleChange(event.target.name, event.target.checked, index)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div className='d-flex align-items-center m-1'>
                        <div>
                          Speak
                        </div>
                        <div className='ml-auto'>
                          <div className='form-check form-switch'>
                            <input 
                              checked={language.speak === 'Yes' ? true : false} 
                              type='checkbox' 
                              className='form-check-input' 
                              name="speak"
                              onChange={(event) => handleChange(event.target.name, event.target.checked, index)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div class="col-12">
                      <hr />
                    </div>
                  </div>
                </div>
                <div class="ml-3">
                  <button 
                    type="button" 
                    class="btn btn-icon"
                    onClick={() => deleteLanguages(index)}  
                  >
                    <i class="fal fa-fw fa-trash-alt"></i>
                  </button>
                </div>
              </div>)}

              <div className='ml-5'>
                <button
                  type="button"
                  class="btn btn-sm btn-text"
                  onClick={addLanguages}
                >
                  <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                  <strong>Add Language</strong>
                </button>
              </div>

            </div>
          )}


        </div>
      </div>
    </>
  )
}

export default Languages
