import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaPencilAlt,
  FaInbox,
  FaPaperPlane,
  FaEnvelope,
  FaCommentAlt,
} from "react-icons/fa"; // Import the icons
import { CgTranscript } from "react-icons/cg";
const TopMenu = ({
  onSelectOption,
  getAllSentEmails,
  activeTab,
  setActiveTab,
}) => {
  return (
    <div className="menu-container mt-3">
      <div className="menu-options-row">
        <div
          className={`menu-option ${activeTab === "compose" ? "active" : ""}`}
          onClick={() => {
            onSelectOption("compose");
            setActiveTab("compose");
          }}
        >
          <FaPencilAlt />{" "}
          <small className="broadCastTabIcons">Create New SMS</small>
        </div>
        {/* <div
          className={`menu-option ${activeTab === "inbox" ? "active" : ""}`}
          onClick={() => {
            onSelectOption("inbox");
            setActiveTab("inbox");
          }}
        >
          <FaInbox /> <small>Inbox</small>
        </div> */}
        <div
          className={`menu-option ${activeTab === "sent" ? "active" : ""}`}
          onClick={() => {
            onSelectOption("sent");
            setActiveTab("sent");
          }}
        >
          <FaPaperPlane /> <small className="broadCastTabIcons">Sent</small>
        </div>
        <div
          className={`menu-option ${activeTab === "templates" ? "active" : ""}`}
          onClick={() => {
            onSelectOption("templates");
            setActiveTab("templates");
          }}
        >
          <CgTranscript size={20} />{" "}
          <small className="broadCastTabIcons">Templates</small>
          {/* <FaCommentAlt /> <small>Templates</small> */}
        </div>
      </div>
    </div>
  );
};

export default TopMenu;
