import {
  GET_DISPOSITION_BEGIN,
  GET_DISPOSITION_SUCCESS,
  GET_DISPOSITION_FAILURE,
} from "../../actions/disposition/Disposition";

const initialState = {
  dispositionDetailsLoading: true,
  dispositionDetails: [],
};

const dispositionDetailsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_DISPOSITION_BEGIN:
      return {
        ...state,
        dispositionDetailsLoading: true,
        dispositionDetails: [],
      };
    case GET_DISPOSITION_SUCCESS:
      return {
        ...state,
        dispositionDetails: payload,
        dispositionDetailsLoading: false,
      };
    case GET_DISPOSITION_FAILURE:
      return {
        ...state,
        dispositionDetails: [],
        dispositionDetailsLoading: false,
      };
    default:
      return state;
  }
};

export default dispositionDetailsReducer;
