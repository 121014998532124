import React, { useState, useEffect } from "react";
import { Modal, Button, Table, Form, Col } from "react-bootstrap";
import Pagination from "react-pagination-js";
import "react-pagination-js/dist/styles.css";
import { post } from "../../../../service/ApiService";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";
import NoRecord from "../norecord/NoRecord";

const ReminderBroadcast = ({
  showModal,
  setShowModal,
  dispositionDetails,
  jobData,
}) => {
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const dataSize = 10;
  const startIndex = (currentPage - 1) * dataSize;
  const endIndex = startIndex + dataSize;
  const currentData = data.slice(startIndex, endIndex);
  const [isTableDataAvailable, setIsTableDataAvailable] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [searchEmail, setSearchEmail] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleClearClick = () => {
    setSelectedRows([]);
    setData([]);
    setSelectedCategory("");
    setSearchName("");
    setSearchEmail("");
  };
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSearchClick = () => {
    const filteredData = data.filter((row) => {
      const fullName = `${row.firstName} ${row.lastName}`.toLowerCase();
      const email = row.email.toLowerCase();

      return (
        fullName.includes(searchName.toLowerCase()) &&
        email.includes(searchEmail.toLowerCase())
      );
    });

    setData(filteredData);
    setCurrentPage(1);
  };
  const selectedEmailCountText =
    selectedRows.length > 0
      ? `${selectedRows.length}   ${
          selectedRows.length === 1 ? "Email" : "Emails"
        }  Selected`
      : "";

  const fetchScreeningData = () => {
    const screeningData = dispositionDetails.filter((item) => {
      try {
        const screeningQuestions = JSON.parse(item.screeningQuestions);
        const hasScreeningQuestions =
          screeningQuestions &&
          screeningQuestions.length === 0 &&
          item.newSletter !== "Unsubscribed";
        return hasScreeningQuestions;
      } catch (error) {
        console.error("Error parsing screeningQuestions:", error);
        return false;
      }
    });
    setData(screeningData);
    setIsTableDataAvailable(screeningData.length > 0);
  };

  const fetchVettingData = () => {
    const vettingData = dispositionDetails.filter(
      (item) =>
        item.vettingDetails.length > 0 &&
        item.newSletter !== "Unsubscribed" &&
        item.vettingDetails[0]?.status === "Invited"
    );
    setData(vettingData);
    setIsTableDataAvailable(vettingData.length > 0);
  };

  const handleCategoryChange = (e) => {
    setSelectedCategory(e.target.value);
    setSelectedRows([]);
    if (e.target.value === "Screening") {
      fetchScreeningData();
    } else if (e.target.value === "Assessment") {
      fetchVettingData();
    } else {
      handleClearClick();
    }
  };

  const handleCheckboxChange = (email) => {
    if (email === "all") {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.length === data.length
          ? []
          : data.map((row) => row.email)
      );
    } else {
      setSelectedRows((prevSelectedRows) => {
        const index = prevSelectedRows.indexOf(email);
        return index === -1
          ? [...prevSelectedRows, email]
          : prevSelectedRows.filter((rowEmail) => rowEmail !== email);
      });
    }
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleDoneClick = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmModalClose = () => {
    setShowConfirmModal(false);
    handleClearClick();
  };
  //Remind request send successfully
  const handleConfirmSelection = async () => {
    try {
      const dataToApi = dispositionDetails.filter((obj) =>
        selectedRows.includes(obj.email)
      );
      let paramObj = {
        selectedData: dataToApi,
      };

      if (selectedCategory === "Screening") {
        setLoading(true);
        setShowConfirmModal(false);

        const res1 = await post("/reminderBroadcast/screening", paramObj);
        if (res1.status === 200) {
          toast.success("Screening reminder sent successfully!");
          setLoading(false);
          setShowModal(false);
        }
      } else {
        paramObj.candidateEmails = selectedRows;
        setLoading(true);

        setShowConfirmModal(false);
        const res2 = await post("/reminderBroadcast/vetting", paramObj);
        if (res2.status === 200) {
          toast.success("Assessment reminder sent successfully!");
          setLoading(false);
          setShowModal(false);
        }
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <Modal show={showModal} size="xl" centered onHide={handleClose}>
        <div className="bl-modal-header">
          <h5 className="bl-modal-title">Reminder</h5>
          <div className="bl-modal-header">
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={handleClose}
              className="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body className="p-5">
            <label>
              Send reminder mails to talent(s) to complete their assessment and
              screening.
            </label>
            <Form className="mb-5">
              <Form.Group className="d-flex" style={{ fontSize: "14px" }}>
                <Col md={3} className="mr-2">
                  <Form.Label for="selectCategory">Category</Form.Label>
                  <select
                    as="select"
                    className="p-2 form-select"
                    name="selectCategory"
                    value={selectedCategory}
                    style={{ fontSize: "14px" }}
                    onChange={handleCategoryChange}
                  >
                    <option value="">Select</option>
                    <option value="Assessment">Assessment</option>
                    <option value="Screening">Screening</option>
                  </select>
                </Col>
                <Col md={3} className="mr-2">
                  <Form.Label for="searchByName">Search by Name</Form.Label>
                  <Form.Control
                    type="text"
                    className="p-2"
                    name="searchByName"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                    disabled={!isTableDataAvailable}
                  />
                </Col>
                <Col md={3}>
                  <Form.Label for="searchByEmail">Search by Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="searchByEmail"
                    className="p-2"
                    value={searchEmail}
                    onChange={(e) => setSearchEmail(e.target.value)}
                    disabled={!isTableDataAvailable}
                  />
                </Col>
                <Col md={3} className="mt-auto">
                  <Button
                    variant="secondary"
                    className="ms-2"
                    onClick={handleClearClick}
                  >
                    Clear
                  </Button>
                  <Button
                    variant="primary"
                    className="ms-2"
                    onClick={handleSearchClick}
                  >
                    Search
                  </Button>
                </Col>
              </Form.Group>
            </Form>
            <div className="mb-3">{selectedEmailCountText}</div>
            {
              <Table striped bordered hover>
                {currentData.length > 0 && (
                  <thead>
                    <tr>
                      <th className="d-flex justify-content-center align-content-center">
                        <div
                          className="form-check form-check-square font-14 d-flex align-items-center"
                          style={{ border: "none", background: "none" }}
                        >
                          <input
                            type="checkbox"
                            className="form-check-input"
                            onChange={() => handleCheckboxChange("all")}
                            checked={selectedRows.length === data.length}
                          />
                        </div>
                      </th>
                      <th>Name</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                )}
                <tbody>
                  {currentData.length > 0 &&
                    currentData.map((row) => (
                      <tr key={row?.email}>
                        <td className="d-flex justify-content-center align-content-center">
                          <div
                            className="form-check form-check-square  font-14 d-flex align-items-center"
                            style={{ border: "none", background: "none" }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input"
                              onChange={() => handleCheckboxChange(row?.email)}
                              checked={selectedRows.includes(row?.email)}
                            />
                          </div>
                        </td>
                        <td>{row?.firstName + " " + row?.lastName}</td>
                        <td>{row?.email}</td>
                      </tr>
                    ))}

                  {selectedCategory !== "" && currentData.length === 0 && (
                    <tr className="text-center">
                      <td colSpan={6}>
                        <NoRecord />
                      </td>
                    </tr>
                  )}
                  {/* {selectedCategory === "" && (
                <tr className="text-center">
                  <td colSpan={6}>
                    <h6>Select a category</h6>
                  </td>
                </tr>
              )} */}
                </tbody>
              </Table>
            }
            {currentData?.length > 0 && (
              <div className="d-flex justify-content-between">
                <div className="mt-3 px-2">
                  Showing {Math.min((currentPage - 1) * 10 + 1, data.length)} -{" "}
                  {Math.min(currentPage * 10, data.length)} of {data.length}
                </div>
                <div className="mt-3 px-2">
                  <Pagination
                    currentPage={currentPage}
                    totalSize={data.length}
                    sizePerPage={10}
                    firstPageText="|⟨"
                    lastPageText="⟩|"
                    showFirstLastPages={true}
                    changeCurrentPage={handlePageChange}
                    theme="border-bottom"
                  />
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleDoneClick}
              disabled={selectedRows.length === 0}
            >
              Send
            </Button>
          </Modal.Footer>
        </div>
      </Modal>
      <Modal
        show={showConfirmModal}
        centered
        onHide={handleConfirmModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Selection</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5 text-center">
          <p>Are you sure you want to proceed?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleConfirmModalClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirmSelection}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ReminderBroadcast;
