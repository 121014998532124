import React from "react";
import DispositionHeader from "./DispositionHeader";

const Index = (props) => {
  const {
    view,
    setView,
    isFrom,
    jobData,
    dispositionDetails,
    setFilterData,
    filterData,
    dispositionCount,
    dispositionCountLoading,
    fetchData,
    filterLength,
    setSelectedTalents,
    selectedTalents,
    Tab,
  } = props;
  return (
    <div className="col-xl-8">
      <div className="hero-card-header card card-lg card-flat card-bottom-straight">
        <DispositionHeader
          view={view}
          setView={setView}
          isFrom={isFrom}
          jobData={jobData}
          dispositionDetails={dispositionDetails}
          setFilterData={setFilterData}
          filterData={filterData}
          dispositionCount={dispositionCount}
          dispositionCountLoading={dispositionCountLoading}
          fetchData={fetchData}
          filterLength={filterLength}
          selectedTalents={selectedTalents}
          setSelectedTalents={setSelectedTalents}
          Tab={Tab}
        />
      </div>
    </div>
  );
};

export default Index;
