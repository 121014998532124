import React, { useEffect, useState } from "react";
import { post } from "../../../../../../service/ApiService";
import Loader from "../../../../../Loader/Loader";
import { toast } from "react-toastify";

const ScheduleInterview = (props) => {
  const {
    jobData,
    assessmentPlatform,
    setAssessmentPlatform,
    vettingData,
    setVettingData,
    formData,
    setFormData,
    vetSelectedSkill,
    setVetSelectedSkill,
    duration,
    setDuration,
    vettingDifficulty,
    setVettingDifficulty,
  } = props;

  const [loading, setLoading] = useState(false);

  const vettingMCQ = vettingData
    ?.filter((x) => x.testCategory === "MCQ")
    .map((i) => {
      return { testName: i.testName, id: i._id };
    });

  let arrDuration = [];
  vettingData
    .filter((x) => x.testCategory === "MCQ")
    .forEach((item) => {
      let obj = {
        duration: item?.details?.duration,
        testName: item.testName,
        difficulty: item.difficulty,
        id: item._id,
      };
      arrDuration.push(obj);
      return arrDuration;
    });

  const testDifficulty = arrDuration.filter((x) => x.id === vetSelectedSkill);
  const testDuration = arrDuration.filter((x) => x.id === vetSelectedSkill);

  const handleInterviewTypeChange = async (e) => {
    try {
      let tmpData = { ...formData };
      tmpData[e.target.name] = e.target.value;
      setFormData(tmpData);
      setAssessmentPlatform("vettingPlatform");

      console.log("tempData", tmpData);
      if (tmpData.testCategory !== "Select") {
        setLoading(true);
        const vetRes = await post("/vetIntegration/get/assessments", tmpData);
        if (vetRes.status === 200) {
          setVettingData(vetRes.data);
          setLoading(false);
        }
        {
          setLoading(false);
        }
      }
    } catch (err) {
      setLoading(false);
    }
  };

  const handleVettingAssessmentChange = (e) => {
    if (e.length !== 0) {
      const testNameVet = vettingData.find((x) => x._id === e.target.value);

      // setInterviewScheduleData({
      //   ...interviewScheduleData,
      //   jobId: e.jobId,
      //   vettingTestName: testNameVet.testName,
      //   vettingtestID: testNameVet._id,

      // });
      setVetSelectedSkill(e.target.value);

      setDuration("");
      setVettingDifficulty("");
    }
  };

  function getUnique(vettingMCQ) {
    console.log("vettingMCQ", vettingMCQ);

    const uniqueArr = [
      ...new Map(vettingMCQ.map((item) => [item.testName, item])).values(),
    ];

    return uniqueArr;
  }

  return (
    <>
      {loading && <Loader />}
      <div className="modal-body ">
        <div className="card card-flat card-borderless ">
          <div className="card-body">
            <span>Please complete the form below to setup your interview.</span>
            <div className="form-floating">
              <select
                className="form-select"
                id="interview-type"
                name="testCategory"
                aria-label="Floating label select example"
                onChange={handleInterviewTypeChange}
                defaultValue={formData.testCategory}
              >
                <option selected value="Select">
                  Select Type
                </option>
                <option value="MCQ">Multiple Choice Questionaire(MCQ)</option>
              </select>
              <label for="floatingSelect">
                Please select the interview type *
              </label>
            </div>

            {/* Vetting assessment selection*/}
            <div className="row mt-3">
              {formData.testCategory === "MCQ" &&
                assessmentPlatform === "vettingPlatform" && (
                  <div className="col-lg-12 mb-2 mb-9">
                    <div className="card p-2">
                      <div className="d-flex">
                        <div className="col-4 ">
                          {console.log("vettingData", vettingData)}
                          <div className="form-floating">
                            <select
                              className={`form-select font-14`}
                              id="category"
                              value={vetSelectedSkill}
                              onChange={handleVettingAssessmentChange}
                            >
                              <option value="" selected>
                                Select
                              </option>
                              {getUnique(vettingMCQ).map((item) => (
                                <option key={item?.id} value={item?.id}>
                                  {item?.testName}
                                </option>
                              ))}
                            </select>
                            <label for="category">Select Assessment*</label>
                          </div>
                        </div>
                        <div className="col-4 ps-2">
                          <div className="form-floating">
                            <select
                              className={`form-select font-14`}
                              id="category"
                              value={vettingDifficulty}
                              onChange={(e) => {
                                setVettingDifficulty(e?.target?.value);
                              }}
                            >
                              <option value="" selected>
                                Select
                              </option>
                              {testDifficulty.map((item) => (
                                <option key={item.id} value={item.difficulty}>
                                  {item.difficulty}
                                </option>
                              ))}
                            </select>
                            <label for="category">Difficulty Level*</label>
                          </div>
                        </div>
                        <div className="col-4 ps-2">
                          <div className="form-floating">
                            <select
                              className={`form-select font-14`}
                              id="category"
                              value={duration}
                              onChange={(e) => {
                                setDuration(e?.target?.value);
                              }}
                            >
                              <option value="" selected>
                                Select
                              </option>
                              {testDuration.map((item) => (
                                <option
                                  key={item.id}
                                  value={item.duration}
                                  label={`${item.duration} mins`}
                                >
                                  {item.duration}
                                </option>
                              ))}
                            </select>
                            <label for="category">Duration*</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              {formData.testCategory === "OneWay" &&
                assessmentPlatform === "vettingPlatform" && (
                  <div>
                    <div className="card p-2">
                      <div className="d-flex">
                        <div className="col-4 ">
                          <div className="form-floating">
                            <select
                              className={`form-select font-14`}
                              id="category"
                              // value={vetSelectedSkill}
                              // onChange={handleVettingAssessmentChange}
                            >
                              <option value="" selected>
                                Select
                              </option>
                              {/* {getUnique(vettingOneWay).map((item) => (
                                  <option key={item?.id} value={item?.id}>
                                    {item?.testName}
                                  </option>
                                ))} */}
                            </select>
                            <label for="category">Select Assessment*</label>
                          </div>
                        </div>
                        <div className="col-4 ps-2">
                          <div className="form-floating">
                            <select
                              className={`form-select font-14`}
                              id="category"
                              // value={vettingDifficulty}
                              // onChange={(e) => {
                              //   setVettingDifficulty(e?.target?.value);
                              // }}
                            >
                              <option value="" selected>
                                Select
                              </option>
                              {/* {testDifficultyV.map((item) => (
                                  <option key={item.id} value={item.difficulty}>
                                    {item.difficulty}
                                  </option>
                                ))} */}
                            </select>
                            <label for="category">Difficulty Level*</label>
                          </div>
                        </div>
                        <div className="col-4 ps-2">
                          <div className="form-floating">
                            <select
                              className={`form-select font-14`}
                              id="category"
                              // value={duration}
                              // onChange={(e) => {
                              //   setDuration(e?.target?.value);
                              // }}
                            >
                              <option value="" selected>
                                Select
                              </option>
                              {/* {testDurationV.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.duration}
                                    label={`${item.duration} mins`}
                                  >
                                    {item.duration}
                                  </option>
                                ))} */}
                            </select>
                            <label for="category">Duration*</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <p style={{ color: "red" }}>{notification}</p> */}
                    <div className="form-floating col-5">
                      <p className="ps-1" style={{ paddingTop: 25 }}>
                        <strong>Select Reviewer</strong>
                      </p>
                    </div>
                    <hr />
                    <div className="card p-2">
                      <div className="d-flex">
                        <div className="col-12 ">
                          <div className="form-floating   ">
                            <select
                              className="font-14 form-select"
                              id="category"
                              // value={reviewerPresent}
                              // onChange={(e) => {
                              //   handleReviewerChange(e);
                              // }}
                            >
                              <option value={false}>
                                I will be the reviewer.
                              </option>
                              <option value={true}>
                                I suggest a colleague to review.
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* {reviewerPresent === "true" && ( */}
                    <>
                      <div className="form-floating col-5"></div>
                      <hr />{" "}
                      <div className="card p-2">
                        <div className="d-flex justify-content-between mb-2">
                          <div className="form-floating w-50 mr-2">
                            <input
                              type="text"
                              placeholder="test"
                              className="form-control"
                              // value={reviewer.name}
                              // onChange={(e) => {
                              //   setReviewer({
                              //     ...reviewer,
                              //     name: e.target.value.replace(
                              //       /^[ \t]+|[ \t]+$/gm,

                              //       ""
                              //     ),
                              //   });
                              // }}
                            />
                            <label htmlFor="">First Name*</label>
                          </div>
                          <div className="form-floating w-50">
                            <input
                              type="text"
                              placeholder="test"
                              className="form-control"
                              // value={reviewer.reviewerEmail}
                              // onChange={(e) => {
                              //   setReviewer({
                              //     ...reviewer,
                              //     reviewerEmail: e.target.value.replace(
                              //       /^[ \t]+|[ \t]+$/gm,

                              //       ""
                              //     ),
                              //   });
                              // }}
                            />
                            <label htmlFor="">Email*</label>
                          </div>
                        </div>
                        <div className="form-floating">
                          <textarea
                            type="text"
                            rows="3"
                            placeholder="test"
                            className="form-control"
                            //   onChange={(e) => {
                            //     setReviewer({
                            //       ...reviewer,
                            //       instructions: e.target.value,
                            //     });
                            //   }}
                          />
                          <label htmlFor="">Instructions to reviewer</label>
                        </div>
                      </div>
                    </>
                    {/* )} */}
                  </div>
                )}

              {formData.testCategory === "General" &&
                assessmentPlatform === "vettingPlatform" && (
                  <div>
                    <div className="card p-2">
                      <div className="d-flex">
                        <div className="col-4 ">
                          <div className="form-floating">
                            <select
                              className={`form-select font-14`}
                              id="category"
                              // value={vetSelectedSkill}
                              // onChange={handleVettingAssessmentChange}
                            >
                              <option value="" selected>
                                Select
                              </option>
                              {/* {getUnique(vettingGeneral).map((item) => (
                                  <option key={item?.id} value={item?.id}>
                                    {item?.testName}
                                  </option>
                                ))} */}
                            </select>
                            <label for="category">Select Assessment*</label>
                          </div>
                        </div>
                        <div className="col-4 ps-2">
                          <div className="form-floating">
                            <select
                              className={`form-select font-14`}
                              id="category"
                              // value={vettingDifficulty}
                              // onChange={(e) => {
                              //   setVettingDifficulty(e?.target?.value);
                              // }}
                            >
                              <option value="" selected>
                                Select
                              </option>
                              {/* {testDifficultyG.map((item) => (
                                  <option key={item.id} value={item.difficulty}>
                                    {item.difficulty}
                                  </option>
                                ))} */}
                            </select>
                            <label for="category">Difficulty Level*</label>
                          </div>
                        </div>
                        <div className="col-4 ps-2">
                          <div className="form-floating">
                            <select
                              className={`form-select font-14`}
                              id="category"
                              // value={duration}
                              // onChange={(e) => {
                              //   setDuration(e?.target?.value);
                              // }}
                            >
                              <option value="" selected>
                                Select
                              </option>
                              {/* {testDurationG.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.duration}
                                    label={`${item.duration} mins`}
                                  >
                                    {item.duration}
                                  </option>
                                ))} */}
                            </select>
                            <label for="category">Duration*</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <p style={{ color: "red" }}>{notification}</p> */}
                    <div className="form-floating col-5">
                      <p className="ps-1" style={{ paddingTop: 25 }}>
                        <strong>Select Reviewer</strong>
                      </p>
                    </div>
                    <hr />
                    <div className="card p-2">
                      <div className="d-flex">
                        <div className="col-12">
                          <div className="form-floating">
                            <select
                              className="font-14 form-select"
                              id="category"
                              //   value={reviewerPresent}
                              //   onChange={(e) => {
                              //     handleReviewerChange(e);
                              //   }}
                            >
                              <option value={false}>
                                I will be the reviewer.
                              </option>
                              <option value={true}>
                                I suggest a colleague to review.
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* {reviewerPresent === "true" && ( */}
                    <>
                      <div className="form-floating col-5"></div>
                      <hr />{" "}
                      <div className="card p-2">
                        <div className="d-flex justify-content-between mb-2">
                          <div className="form-floating w-50 mr-2">
                            <input
                              type="text"
                              placeholder="test"
                              className="form-control"
                              // value={reviewer.name}
                              //   onChange={(e) => {
                              //     setReviewer({
                              //       ...reviewer,
                              //       name: e.target.value.replace(
                              //         /^[ \t]+|[ \t]+$/gm,

                              //         ""
                              //       ),
                              //     });
                              //   }}
                            />
                            <label htmlFor="">First Name*</label>
                          </div>
                          <div className="form-floating w-50">
                            <input
                              type="text"
                              placeholder="test"
                              className="form-control"
                              // value={reviewer.reviewerEmail}
                              //   onChange={(e) => {
                              //     setReviewer({
                              //       ...reviewer,
                              //       reviewerEmail: e.target.value.replace(
                              //         /^[ \t]+|[ \t]+$/gm,

                              //         ""
                              //       ),
                              //     });
                              //   }}
                            />
                            <label htmlFor="">Email*</label>
                          </div>
                        </div>
                        <div className="form-floating">
                          <textarea
                            type="text"
                            rows="3"
                            placeholder="test"
                            className="form-control"
                            // value=""
                            // onChange={(e) => {
                            //   setReviewer({
                            //     ...reviewer,
                            //     instructions: e.target.value,
                            //   });
                            // }}
                          />
                          <label htmlFor="">Instructions to reviewer</label>
                        </div>
                      </div>
                    </>
                    {/* )} */}
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ScheduleInterview;
