import React from "react";
import DispositionHeader from "./DispositionHeader";

const Index = (props) => {
  const { view, setView, isFrom, value, Tab } = props;
  return (
    <div className="col-xl-8">
      <div className="hero-card-header card card-lg card-flat card-bottom-straight">
        <DispositionHeader
          view={view}
          setView={setView}
          isFrom={isFrom}
          value={value}
          Tab={Tab}
        />
      </div>
    </div>
  );
};

export default Index;
