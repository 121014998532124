import React, { useState, useEffect } from "react";
import { get, post } from "../../../service/ApiService";
import Avatar from "react-avatar";
import axios from "axios";
import {
  getUserName,
  getUserTenant,
  getCandidateId,
  getCandidateName,
  getUserEmail,
  getUserTenantID,
  getUserRole,
} from "../../../service/AuthService";
import { assignAssessmentValidation } from "../../validation/Common";
import { checkEmptyObject } from "../../function/CommonFunction";
import { toast } from "react-toastify";
import Select from "react-dropdown-select";
import { emailDomain, domain } from "../../../constants/Constants";
// import TimezoneSelect from "react-timezone-select";
import DatePicker from "react-datepicker";
import AssessmentHistory from "./AssessmentHistory";

import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../Loader/Loader";
import { gql, useMutation, useQuery } from "@apollo/client";

/**Code Signal */
const GET_COMPANYTESTS = gql`
  query {
    companyTests(first: 100) {
      tests {
        id
        title
        internalDescription
        company {
          name
        }
      }
    }
  }
`;

const ADD_COMPANYTESTS = gql`
  mutation createCompanyTestSession(
    $testId: ID!
    $candidateFirstName: String!
    $candidateLastName: String!
    $candidateEmail: String!
  ) {
    createCompanyTestSession(
      sessionFields: {
        testId: $testId
        candidateFirstName: $candidateFirstName
        candidateLastName: $candidateLastName
        candidateEmail: $candidateEmail
      }
    ) {
      id
      invitationUrl
      testTaker {
        email
        firstName
        lastName
      }
      status
      createDate
      startDate
      finishDate
      result {
        score
        solvedTaskCount
        url
      }
      test {
        id
        title
        duration
      }
    }
  }
`;

const GET_COMPANYTESTS_RESULT = gql`
  query {
    companyTestSession(id: ID)
  }
`;
function AssignAssessment(props) {
  const { error, data } = useQuery(GET_COMPANYTESTS);
  const [createCompanyTestSession] = useMutation(ADD_COMPANYTESTS);
  //console.log(data, 'data')
  const [interviewType, setinterviewType] = useState("");
  const [assessmentPlatform, setAssessmentPlatform] = useState("");
  const [interviewer, setinterviewer] = useState("");
  const [change, setchange] = useState(false);
  const [checkedFn, setcheckedFn] = useState(true);
  const [populatePlatform, setPopulatePlatform] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  let [modernHireData, setModernHireData] = useState([]);
  let [monjinOnewayData, setMonjinOnewayData] = useState([]);
  let [monjinTwowayData, setMonjinTwowayData] = useState([]);
  let [tovutiData, setTovutiData] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedTimezone, setSelectedTimezone] = useState({});
  const [duration, setDuration] = useState({});
  const [testDataList, setTestDataList] = useState([]);
  const [reviewerPresent, setReviewerPresent] = useState("false");
  const [expiryRange, setExpiryRange] = useState(7);
  const [notification, setNotification] = useState("");
  const expiryData = [
    { SourceName: "1 day", value: 1 },
    { SourceName: "2 days", value: 2 },
    { SourceName: "3 days", value: 3 },
    { SourceName: "4 days", value: 4 },
    { SourceName: "5 days", value: 5 },
    { SourceName: "1 week", value: 7 },
    { SourceName: "2 weeks", value: 14 },
    { SourceName: "3 weeks", value: 21 },
    { SourceName: "1 month", value: 30 },
  ];
  const [reviewer, setReviewer] = useState({
    name: getCandidateName().split(" ")[0],
    reviewerEmail: getUserEmail(),
    instructions: "",
  });
  //// console.log(props,'props')
  const [interviewScheduleData, setInterviewScheduleData] = useState({
    interviewType: "",
    platform: "",
    assessment: "",
    templateReqNumber: "",
    reqTitle: "",
    MontageRequisitionId: "",
    interviewer: "",
    firstName: props.candidateData.firstName,
    lastName: props.candidateData.lastName,
    email: props.candidateData.email,
    duration: "",
    candidateID: props.candidateData.candidateID,
    monjinJobId: "",
    jobTitle: "",
    assignCandidateId: props.candidateData.candidateID,
    Title: "",
    candidateTenant: "",
    candidateid: "",
    course: "",
    Interview: "",
    tovutiCourseTitle: "",
    tovutiCourseId: "",
    tovutiCourseUrl: "",
    monjinJobId: "",
    jobId: "",
    monjinJobTitle: "",
    courseId: "",
    createdBy: props.candidateData.createdBy,
    tovutiListNot: "",
    assignEmail: "",
    codeSignalCourseId: "",
    codeSignalCourseTitle: "",
    vettingtestID: "",
    vettingTestName: "",
    vettingExpiredDays: "",
    monjinTwowayData: {
      interviewerfirstName: "",
      interviewerlastName: "",
      intervieweremail: "",
      interviewdate: "",
      interviewduration: "",
      selectedTimezone: {},
    },
    TwowayData: {
      interviewerfirstName: "",
      interviewerlastName: "",
      intervieweremail: "",
    },
  });
  const [difficultyMCQ, setDifficultyMCQ] = useState([]);
  const [durationMCQ, setDurationMCQ] = useState([]);
  const [difficultyV, setDifficultyV] = useState([]);
  const [durationV, setDurationV] = useState([]);
  const [tovutiUsersList, setTovutiUsersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vettingData, setVettingData] = useState([]);
  const mordernTovuti = [
    { type: "modernHire", name: "Modern Hire" },
    // { type: "tovuti", name: "Tovuti" },
  ];
  const mordernMonjin = [
    { type: "modernHire", name: "Modern Hire" },
    // { type: "monjin", name: "Monjin" },
  ];
  const [vettingDifficulty, setVettingDifficulty] = useState("");
  const [assessmentData, setAssessmentData] = useState([]);
  const [csAssessmentData, setCsAssessmentData] = useState([]);
  const [vettingAssessmentData, setVettingAssessmentData] = useState([]);
  const [selectedVetting, setSelectedVetting] = useState({
    name: "",
    difficulty: "",
    duration: "",
  });
  const Header = ["title", "AssessmentType", "status", "assignedBy"];
  const [vettingDB, setVettingDB] = useState([]);
  const [vetSelectedSkill, setVetSelectedSkill] = useState();
  const findtwoWayErrors = () => {
    const {
      interviewerfirstName,
      interviewerlastName,
      intervieweremail,
      interviewdate,
      interviewduration,
      selectedTimezone,
    } = interviewScheduleData.monjinTwowayData;
    const newErrors = {};
    // name errors
    //(Object.keys(selectedTimezone).length  === 0)
    const todayDate = new Date();
    //console.log(interviewer);
    if (interviewer == "none" || interviewer == "")
      newErrors.interviewerType = "please select Interviewer details ";
    if (!interviewdate || interviewdate === "")
      newErrors.interviewdate = "Interview Date and time cannot be Blank";
    if (Object.keys(selectedTimezone).length === 0)
      newErrors.selectedTimezone = "Timezone cannot be blank";
    if (!interviewduration || interviewduration === "select")
      newErrors.interviewduration = "Interview Duration cannot be blank";
    //  first name , last name ,email and
    if (!interviewerfirstName || interviewerfirstName === "")
      newErrors.interviewerfirstName = "First name cannot be Blank";
    if (!interviewerlastName || interviewerlastName === "")
      newErrors.interviewerlastName = "Last name cannot be Blank";
    if (!intervieweremail || intervieweremail === "")
      newErrors.intervieweremail = "Email cannot be blank";
    else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(intervieweremail))
      newErrors.intervieweremail = "Invalid Email id !";

    if (!interviewdate || interviewdate < todayDate)
      newErrors.interviewdate = "Interview Date and time cannot be old Date";
    console.log(newErrors);
    return newErrors;
  };

  const updatetwowaySlot = (date) => {
    const scheduleddata = { ...interviewScheduleData };
    scheduleddata.monjinTwowayData.interviewdate = new Date(date);
    //console.log(date, new Date(date));
    setInterviewScheduleData(scheduleddata);
    const newErrors = findtwoWayErrors();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
  };

  const updatetwowayTimeZone = (timezone) => {
    const scheduleddata = { ...interviewScheduleData };
    scheduleddata.monjinTwowayData.selectedTimezone = timezone;
    setInterviewScheduleData(scheduleddata);
    const newErrors = findtwoWayErrors();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
  };
  const updatetwowayDuration = (e) => {
    const scheduleddata = { ...interviewScheduleData };
    scheduleddata.monjinTwowayData.interviewduration = e.target.value;
    setInterviewScheduleData(scheduleddata);
    const newErrors = findtwoWayErrors();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
  };

  const handletwoWayChange = (e) => {
    const scheduleddata = { ...interviewScheduleData };
    const id = e.target.id;
    scheduleddata.monjinTwowayData[id] = e.target.value;
    scheduleddata.TwowayData[id] = e.target.value;
    setInterviewScheduleData(scheduleddata);
    const newErrors = findtwoWayErrors();
    if (Object.keys(newErrors).length > 0) setErrors(newErrors);
  };
  const handleChange = (e) => {
    setDisableSubmit(false);
    //(e.target.value)
    const interviewer = e.target.value;
    if (interviewer == "self") {
      // set interviwer first name  last name and email
      const scheduleddata = { ...interviewScheduleData };

      const recruiterNames = getCandidateName().split(" ");
      const recruiterEmail = getUserEmail();
      scheduleddata.monjinTwowayData.interviewerfirstName = recruiterNames[0];
      scheduleddata.monjinTwowayData.interviewerlastName = recruiterNames[1];
      scheduleddata.monjinTwowayData.intervieweremail = recruiterEmail;
      scheduleddata.TwowayData.interviewerfirstName = recruiterNames[0];
      scheduleddata.TwowayData.interviewerlastName = recruiterNames[1]
        ? recruiterNames[1]
        : "LNU";
      scheduleddata.TwowayData.intervieweremail = recruiterEmail;
      // console.log(scheduleddata.TwowayData)
      setInterviewScheduleData(scheduleddata);
    } else {
      const scheduleddata = { ...interviewScheduleData };
      //console.log(scheduleddata);
      scheduleddata.monjinTwowayData.interviewerfirstName = "";
      scheduleddata.monjinTwowayData.interviewerlastName = "";
      scheduleddata.monjinTwowayData.intervieweremail = "";
      scheduleddata.TwowayData.interviewerfirstName = "";
      scheduleddata.TwowayData.interviewerlastName = "";
      scheduleddata.TwowayData.intervieweremail = "";
      setInterviewScheduleData(scheduleddata);
    }

    if (e.target.type === "select-one") {
      if (e.target.id === "floatingSelect") {
        //setinterviewType(e.target.value);
      }
      if (e.target.id === "floatingSelectinterviewer") {
        setinterviewer(e.target.value);
      }
      setchange(false);
    } else {
      if (e.target.type === "checkbox") {
        setcheckedFn(e.target.checked);
      } else {
        [e.target.id] = e.target.value;
      }
    }
  };

  const handleAssessmentChange = (e) => {
    //(e.length)
    if (e.length != 0) {
      let title = e[0].Title ? e[0].Title : "";

      setDisableSubmit(false);
      ////(e[0].title);
      setInterviewScheduleData({
        ...interviewScheduleData,
        assessment: title,
        templateReqNumber: e[0].RequisitionNumber,
        modernHireCourseTitle: e[0].Title,
        modernHireCourseId: e[0].MontageRequisitionId,
        tovutiCourseTitle: e[0].Title,
        tovutiCourseId: e[0].id,
        tovutiCourseUrl: e[0].Url,
        monjinJobId: e[0].monjinJobId,
        jobId: e[0].jobId,
        monjinJobTitle: e[0].title,
      });
    }
  };

  /**Code Signal */
  const handleCodeSignalAssessmentChange = (e) => {
    //console.log("inside", e);
    if (e.length !== 0) {
      setDisableSubmit(false);
      setInterviewScheduleData({
        ...interviewScheduleData,
        jobId: e[0].jobId,
        codeSignalCourseId: e[0].id,
        codeSignalCourseTitle: e[0].title,
      });
    }
  };

  /**Vetting Platform*/
  // const handleVettingAssessmentChange = (e) => {
  //   //console.log("finalAssessmentData.title", finalAssessmentData.title);
  //   if (e.length !== 0) {
  //     setDisableSubmit(false);
  //     setInterviewScheduleData({
  //       ...interviewScheduleData,
  //       jobId: e[0].jobId,
  //       vettingTestName: e[0].value,
  //       vettingtestID: vettingData
  //         .filter((x) => x.testName === e[0].value)
  //         .map((i) => i._id),

  //       //vettingExpiredDays :JSON.parse(vettingData.filter(x => x.testName === e[0].value).map((i)=> i.expired)),
  //     });
  //     setVetSelectedSkill(e[0].value);
  //     const vetDuration = e[0].value;
  //     // console.log("e[0]", vetSelectedSkill);
  //   }
  //   // console.log("interview", interviewScheduleData)

  //   // if (finalAssessmentData.title === e.target) {
  //   //   setNotification(" * Assessment is already taken");
  //   // }
  // };
  const handleVettingAssessmentChange = (e) => {
    //console.log("finalAssessmentData.title", finalAssessmentData.title);
    console.log("TArget0", vettingData);
    if (e.length !== 0) {
      const testNameVet = vettingData.find((x) => x._id === e.target.value);
      console.log("TTTTTTT", testNameVet);
      setDisableSubmit(false);
      setInterviewScheduleData({
        ...interviewScheduleData,
        jobId: e.jobId,
        vettingTestName: testNameVet.testName,
        vettingtestID: testNameVet._id,
        // .filter(
        //   (x) => x.id === e.target.value
        //   //&& x.testCategory === interviewType
        // ),
        // .map((i) => i._id),
      });
      setVetSelectedSkill(e.target.value);

      setDuration("");
      setVettingDifficulty("");
    }
  };

  const handleInterviewTypeChange = (e) => {
    setinterviewType(e.target.value);
    setDisableSubmit(false);
    ///console interview and target
    const listArray = ["2Way"];
    const listArray_new = ["CodeSignal"];
    const listArray_vetting = ["MCQ", "OneWay"];

    if (listArray_vetting.includes(e.target.value)) {
      setAssessmentPlatform("vettingPlatform");
      setInterviewScheduleData({
        ...interviewScheduleData,
        interviewType: e.target.value,
        platform: "vettingPlatform",
      });
      //getVettingAssessment();
    }

    if (listArray.includes(e.target.value)) {
      setAssessmentPlatform("modernHire");
      setInterviewScheduleData({
        ...interviewScheduleData,
        interviewType: e.target.value,
        platform: "modernHire",
      });
      getMordernHireAssessment();
    } else if (listArray_new.includes(e.target.value)) {
      setAssessmentPlatform("codeSignal");
      setInterviewScheduleData({
        ...interviewScheduleData,
        interviewType: e.target.value,
        platform: "codeSignal",
      });
    }

    if (e.target.value === "MCQ") {
      setPopulatePlatform(mordernTovuti);
      getTovutiAssessment();
    }

    if (e.target.value !== "MCQ") {
      setPopulatePlatform(mordernMonjin);
      getMonjinOnewayAssessment();
      getMonjinTwowayAssessment();
    }
  };

  const handleAssessmentPlatformChange = (e) => {
    setAssessmentPlatform(e.target.value);
    //(e.target)
    setDisableSubmit(false);
    setInterviewScheduleData({
      ...interviewScheduleData,
      platform: e.target.value,
    });

    if (e.target.value === "modernHire") {
      getMordernHireAssessment();
    }
    if (e.target.value === "monjin" && interviewType === "1Way") {
      getMonjinOnewayAssessment();
    }
    if (e.target.value === "monjin" && interviewType === "2Way") {
      getMonjinTwowayAssessment();
    }
    if (e.target.value === "tovuti") {
      getTovutiAssessment();
    }
  };

  // getMordernHireAssessment
  const getMordernHireAssessment = async () => {
    try {
      const res = await get(`/modernhire/requisition`);
      //("mordern hire list ...", res);
      setModernHireData(res.data);
    } catch (error) {
      toast.error(error);
    }
  };

  const fetchVetting = async () => {
    const res = await get(`/vetting/tests/all`);
    // const res = await axios.get(
    //   "https://https://high5vettinguat-api.azurewebsites.net/test/all",
    //   {
    //     headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
    //   }
    // );
    setVettingDB(res.data.filter((i) => i.testName.length === 1));
  };

  // getVettingAssessment
  const getVettingAssessment = async () => {
    setLoading(true);
    try {
      const res = await get(`/vetting/assessment`);
      setVettingData(res.data);
      setLoading(false);
      //console.log("vetting", res);
    } catch (error) {
      toast.error(error);
    }
  };

  // getMonjinOnewayAssessment
  const getMonjinOnewayAssessment = async () => {
    try {
      const res = await get(`/monjin/getJObList`);
      // console.log("Monjin one way...", res);
      setMonjinOnewayData(res.data);
    } catch (error) {
      toast.error(error);
    }
  };

  // getMonjinTwowayAssessment
  const getMonjinTwowayAssessment = async () => {
    try {
      const res = await get(`/monjin/getJObListTwoWay`);
      // console.log("Monjin two way...", res);
      setMonjinTwowayData(res.data);
    } catch (error) {
      toast.error(error);
    }
  };

  // getTovutiAssessment
  const getTovutiAssessment = async () => {
    try {
      const res = await get(`/tovuti/getCourse`);
      // console.log("Tovuti Course List", res);
      setTovutiData(res.data);
    } catch (error) {
      toast.error(error);
    }
  };

  const modernHireMCQ = modernHireData.filter(function(e) {
    return e.RequisitionNumber.split("-")[1] === "MCQ";
  });

  const modernHireOneWay = modernHireData.filter(function(e) {
    return e.RequisitionNumber.split("-")[1] === "OneWay";
  });

  const modernHireTwoWay = modernHireData.filter(function(e) {
    return e.RequisitionNumber.split("-")[1] === "TwoWay";
  });

  const vettingMCQ = vettingData
    .filter((x) => x.testCategory === "MCQ")
    .map((i) => {
      return { testName: i.testName, id: i._id };
    }); //add diffulty, duration
  const vettingOneWay = vettingData
    .filter((x) => x.testCategory === "OneWay")
    .map((i) => {
      return { testName: i.testName, id: i._id };
    });
  console.log("TESTID", vettingMCQ);

  let arrDuration = [];
  const vettingMCQDurations = vettingData
    .filter((x) => x.testCategory === "MCQ")
    .forEach((item) => {
      let obj = {
        duration: item?.details?.duration,
        testName: item.testName,
        id: item._id,
        difficulty: item.difficulty,
      };
      // console.log("OBJ", obj);
      arrDuration.push(obj);
      return arrDuration;

      setDurationMCQ(duration);
      setDifficultyMCQ(difficultyMCQ);
    });

  const testDifficulty = arrDuration.filter((x) => x.id === vetSelectedSkill);
  // .map((i) => {
  //   return i.difficulty;
  // });
  const testDuration = arrDuration.filter((x) => x.id === vetSelectedSkill);
  // .map((i) => {
  //   return i.duration;
  // });

  let arrVideo = [];
  const vettingVideo = vettingData
    .filter((x) => x.testCategory === "OneWay")
    .forEach((item) => {
      let obj = {
        duration: item?.details?.duration,
        testName: item.testName,
        id: item._id,
        difficulty: item.difficulty,
      };

      arrVideo.push(obj);
      return arrVideo;
    });

  const testDifficultyV = arrVideo.filter((x) => x.id === vetSelectedSkill);
  // .map((i) => {
  //   return i.difficulty;
  // });
  const testDurationV = arrVideo.filter((x) => x.id === vetSelectedSkill);
  // .map((i) => {
  //   return i.duration;
  // });

  //console.log("DIFFIC OneWay", testDurationV, testDifficultyV);

  //console.log("duration",vettingMCQDuration )
  const changeFn = (e) => {
    setchange(true);
  };
  //console.log("vettingMCQ", vettingMCQ);

  const close = () => {
    props.handleSuccess(true);
  };
  //ModernHire Assessement Insert
  const moderHireInsertData = async (url, body) => {
    setDisableSubmit(true);
    setLoading(true);
    try {
      // console.log("sda" + body)
      const resultsModernhire = await post(url, body);
      if (resultsModernhire.status === 200) {
        setLoading(false);
        toast.success("ModernHire Assessment assigned successfully");
      }
      close();
    } catch (error) {
      toast.error(error);
      setLoading(false);
      close();
    }
  };

  //Vetting Assessement Insert
  const vettingPlatformInsertData = async (url, body) => {
    setDisableSubmit(true);
    setLoading(true);
    try {
      const resultsVetting = await post(url, body);
      if (resultsVetting.status === 200) {
        setLoading(false);
        toast.success("Vetting Assessment assigned successfully");
      }
      close();
    } catch (error) {
      toast.error(error);
      setLoading(false);
      close();
    }
  };

  //Tovuti Assessement Insert
  const tovutiAssinedData = async (
    tovutiAssignedUsersApiUrl,
    urlAssignAssessment,
    assignObj,
    dataAssignAssessment
  ) => {
    setDisableSubmit(true);
    setLoading(true);
    try {
      // console.log('Tovuti Assessment Test')
      // console.log(dataAssignAssessment)
      //
      // const res = await post(tovutiAssignedUsersApiUrl, urlAssignAssessment, assignObj, dataAssignAssessment);
      // const results = res;
      // if (results.status === 200) {
      //    setLoading(false)
      //     toast.success("Tovuti Assessment assigned successfully");
      // }
    } catch (error) {
      toast.error(error);
    }
  };

  //Monjin Assessment
  const monjinOneWayDataSubmit = async (
    monjinAssessementOneWay,
    urlOneWay,
    body
  ) => {
    setDisableSubmit(true);
    setLoading(true);
    // console.log('test here', monjinAssessementOneWay, urlOneWay, body)
    try {
      const monjinResult = await post(monjinAssessementOneWay, body);

      // console.log('results', monjinResult)

      // // console.log('Results test', assessmentResult)
      let monjinData = monjinResult.data;

      // if(monjinData.Exception){

      // }
      if (monjinData.monjinJobId) {
        // api call

        const bodyData = {
          ...body,
          ...monjinData,
        };
        console.log(bodyData);

        // monjinData.candidateID =   interviewScheduleData.candidateID

        const assignassessmentresult = await post(urlOneWay, bodyData);
        // console.log(assignassessmentresult)

        if (assignassessmentresult.status == 200) {
          setLoading(false);
          if (assignassessmentresult.data.text === "Title Exist") {
            toast.error("Already Assigned assessment to candidate ");
          } else {
            toast.success("Assessment assigned to candidate");
          }
        }
      } else if (monjinData.status === "alreay data present") {
        // console.log("error")
        setLoading(false);
        toast.error("Already Assigned course ");
      }
    } catch (error) {
      // if(error.status)
      setLoading(false);
      console.log(error.status);
      toast.error("Error ");
    }
  };

  const [assessmentVal, setAssessmentVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const findMHtwoWayErrors = () => {
    const {
      interviewerfirstName,
      interviewerlastName,
      intervieweremail,
    } = interviewScheduleData.TwowayData;
    // console.log(interviewScheduleData);
    const newErrors = {};
    // name errors
    //(Object.keys(selectedTimezone).length  === 0)
    const todayDate = new Date();
    //console.log(interviewer);
    if (interviewer == "none" || interviewer == "")
      newErrors.interviewerType = "please select Interviewer details ";
    //  first name , last name ,email and
    if (!interviewerfirstName || interviewerfirstName === "")
      newErrors.interviewerfirstName = "First name cannot be Blank";
    if (!interviewerlastName || interviewerlastName === "")
      newErrors.interviewerlastName = "Last name cannot be Blank";
    if (!intervieweremail || intervieweremail === "")
      newErrors.intervieweremail = "Email cannot be blank";
    else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(intervieweremail))
      newErrors.intervieweremail = "Invalid Email id !";
    console.log(newErrors);
    return newErrors;
  };

  /**Code Signal */
  const assignCodeSignalAssessment = async (e) => {
    let recruiterFirstName = getCandidateName().split(" ")[0];
    let recruiterLastName = getCandidateName()
      .split(" ")
      .slice(-1)[0];
    const recruiterEmail = getUserEmail();
    const recruiterId = getCandidateId();

    try {
      setLoading(true);

      let testSessionCreated = await createCompanyTestSession({
        variables: {
          testId: interviewScheduleData.codeSignalCourseId,
          candidateFirstName: interviewScheduleData.firstName,
          candidateLastName: interviewScheduleData.lastName,
          candidateEmail: interviewScheduleData.email,
        },
      });

      var testDate = new Date(
        testSessionCreated.data.createCompanyTestSession.createDate
      );
      const assignObj = {
        InterviewId: testSessionCreated.data.createCompanyTestSession.id,
        JobId: interviewScheduleData.jobId,
        CandidateId: interviewScheduleData.candidateID,
        RecruiterId: recruiterId,
        InterviewType:
          testSessionCreated.data.createCompanyTestSession.__typename,
        InvitationUrl:
          testSessionCreated.data.createCompanyTestSession.invitationUrl,
        AssignedTo: interviewScheduleData.email,
        CreatedBy: recruiterEmail,
        Status: testSessionCreated.data.createCompanyTestSession.status,
        created_date: testDate,
        CandidateFname: interviewScheduleData.firstName,
        CandidateLname: interviewScheduleData.lastName,
        RecruiterFname: recruiterFirstName,
        RecruiterLname: recruiterLastName,
        reqTitle: testSessionCreated.data.createCompanyTestSession.test.id,
        reqNumber: testSessionCreated.data.createCompanyTestSession.test.title,
      };

      setLoading(true);
      try {
        let res = await post("/codesignal/csAssessmentInsert", assignObj);
        //console.log("response", res);
        if (res.status === 200) {
          setLoading(false);
          toast.success("Code Signal Assessment assigned successfully");
        }
        close();
      } catch (error) {
        toast.error(error);
        setLoading(false);
        close();
      }

      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };
  const validate = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    return email.match(mailformat) ? true : false;
  };

  const onSubmitAssignAssessment = async (e) => {
    if (!validate(reviewer.reviewerEmail))
      return toast.error("Please enter a valid reviwer email");

    if (
      assessmentPlatform === "monjin" &&
      interviewScheduleData.monjinJobId !== ""
    ) {
      const monjinAssessementOneWay = "/monjin/inviteOneway";
      if (interviewType === "1Way") {
        setDisableSubmit(true);
        setLoading(true);
        const body = {
          firstName: interviewScheduleData.firstName,
          lastName: interviewScheduleData.lastName,
          email: interviewScheduleData.email,
          monjinJobId: interviewScheduleData.monjinJobId,
          jobTitle: interviewScheduleData.monjinJobTitle,
          interviewType: "1-way",
          visibility: "",
          candidateid: interviewScheduleData.assignCandidateId,
          vettingType: "one-way",
          urlLink: emailDomain,
          InterviewInviteId: "",
          title: interviewScheduleData.monjinJobTitle,
          tenant: interviewScheduleData.candidateTenant,
        };
        let urlOneWay = "/assignAssessment/oneWay";
        // console.log(body, 'dfs')
        monjinOneWayDataSubmit(monjinAssessementOneWay, urlOneWay, body);
      } else if (
        interviewType === "2Way" &&
        interviewScheduleData.monjinJobId !== ""
      ) {
        // console.log("two way ");
        // console.log(twoWaySlot,)
        // validate through function for two way
        // console.log(interviewScheduleData.interviewer)
        setDisableSubmit(true);
        setLoading(true);
        const newErrors = findtwoWayErrors();
        if (Object.keys(newErrors).length > 0) {
          // We got errors!

          setErrors(newErrors);
          //  alert("please fill all details ")
          // console.log(newErrors, errors)
          // setDisableSubmit(true);
        } else {
          setErrors(newErrors);
          // no erros so call api
          const twowaydata = {
            ...interviewScheduleData,
            monjinJobId: interviewScheduleData.monjinJobId,
            monjinJobTitle: interviewScheduleData.monjinJobTitle,
            firstName: interviewScheduleData.firstName,
            lastName: interviewScheduleData.lastName,
            email: interviewScheduleData.email,
            interviewType: "2-way",
            visibility: "",
            candidateid: interviewScheduleData.assignCandidateId,
            vettingType: "two-way",
            urlLink: emailDomain,
            InterviewInviteId: "",
            title: interviewScheduleData.monjinJobTitle,
            tenant: interviewScheduleData.candidateTenant,
            ...interviewScheduleData.monjinTwowayData,
          };
          //
          //console.log(twowaydata);
          // monjin twoway api
          // then assignassessment
          const twoWayResult = await post("/monjin/inviteTwoway", twowaydata);
          // console.log(twoWayResult);

          if (twoWayResult.data.monjinScheduleId) {
            setLoading(false);
            // call schedule api
            toast.success("Interview Assigned Successfully.");
          } else if (twoWayResult.data.status === "alreay data present") {
            setLoading(false);
            toast.error("Already Course Assigned .");
            //
          } else {
            setLoading(false);
            toast.error("error Occured While assigning interview.");
          }
          props.handleSuccess(true);
        }
      } else {
        setLoading(false);
        toast.error("Please Select Assessment ");
      }
    } else if (assessmentPlatform === "tovuti" && interviewType === "MCQ") {
      setDisableSubmit(true);
      setLoading(true);
      if (interviewScheduleData.tovutiCourseId) {
        // check if user exists in tovuti

        try {
          let tovutiUserId = "";
          let tovutiuser = tovutiUsersList.find(
            (record) => record.Email === interviewScheduleData.email
          );
          let tovutiList = [];
          if (tovutiuser) {
            tovutiUserId = tovutiuser.id;
          } else {
            let { firstName, lastName, email } = interviewScheduleData;
            let res = await post("/tovuti/createUserForTenant", {
              FirstName: firstName,
              LastName: lastName,
              Email: email,
            });
            if (res.status === 200) {
              tovutiUserId = res.data.tovuitId;
              //console.log("id", tovutiUserId);
              let {
                Firstname,
                Lastname,
                Email,
                tovuitId,
                RegisterDate,
              } = res.data;
              const indexData = [
                {
                  Name: `${Firstname} ${Lastname}`,
                  Email,
                  UserGroups: ["high5hire"],
                  id: tovuitId.toString(),
                  RegisterDate,
                },
              ];
              //console.log(indexData);
              await post("/tovuti/updateTovutiUsers", indexData)
                .then((res) => console.log(res.data))
                .catch((err) => console.log(err));
            }
          }

          // api call /tovuti/assignedUsers
          const tovutiuserDataWithId = {
            firstName: interviewScheduleData.firstName,
            lastName: interviewScheduleData.lastName,
            email: interviewScheduleData.email,
            id: tovutiUserId,
          };
          tovutiList.push(tovutiuserDataWithId);

          const assignObj = {
            tovutiList: tovutiList,
            courseId: interviewScheduleData.tovutiCourseId,
            courseName: interviewScheduleData.tovutiCourseTitle,
            courseUrl: interviewScheduleData.tovutiCourseUrl,
            domain: domain,
            candidateid: interviewScheduleData.candidateID,
            firstName: interviewScheduleData.firstName,
            lastName: interviewScheduleData.lastName,
            email: interviewScheduleData.email,
          };
          // console.log(assignObj);
          const assignedUserUrl = "/tovuti/assignedUsers";
          const monjinResult = await post(assignedUserUrl, assignObj);
          // // console.log(monjinResult)
          if (monjinResult.status === 200) {
            //  store in database
            const assignObj = {
              tovutiList: tovutiList,
              courseId: interviewScheduleData.tovutiCourseId,
              courseName: interviewScheduleData.tovutiCourseTitle,
              candidateId: interviewScheduleData.candidateID,
              courseUrl: interviewScheduleData.tovutiCourseUrl,
              domain: domain,
            };
            const MCQTovutiURL = "/assignassessment"; //Tovuti Assigned users assessment
            // console.log(assignObj)
            const assignAssessmentTovuti = await post(MCQTovutiURL, assignObj);
            if (assignAssessmentTovuti.status === 200) {
              setLoading(false);
              toast.success("assessment assigned Successfully");
              props.handleSuccess(true);
            }
          }
        } catch (error) {
          setLoading(false);
          toast.error("Something went wrong");
          props.handleSuccess(true);
        }
      } else {
        setLoading(false);
        toast.error("Please select assessment details ");
      }
    } else if (assessmentPlatform === "modernHire") {
      const url = "/modernhire/workflow"; //ModernHire Assessment Insert api
      const twoWayUrl = "/modernhire/workflow/TwoWay";
      let body = "";
      let recruiterFirstName = getCandidateName().split(" ")[0];
      let recruiterLastName = getCandidateName()
        .split(" ")
        .slice(-1)[0];
      let recruiterEmail = getUserEmail();

      if (
        (interviewType === "MCQ" || interviewType === "1Way") &&
        interviewScheduleData.templateReqNumber !== ""
      ) {
        body = {
          candidateFirstName: interviewScheduleData.firstName,
          candidateLastName: interviewScheduleData.lastName,
          candidateEmail: interviewScheduleData.email,
          recruiterFirstName: recruiterFirstName,
          recruiterLastName: recruiterLastName,
          recruiterEmail: recruiterEmail,
          reqNumber: `${getUserTenantID()}_${
            interviewScheduleData.templateReqNumber
          }`,
          reqTitle: interviewScheduleData.modernHireCourseTitle,
          templateReqNumber: interviewScheduleData.templateReqNumber,
          candidateId: interviewScheduleData.candidateID,
          interviewType: interviewType,
          recruiterId: getCandidateId(),
          urlLink: emailDomain,
          tenantName: getUserTenant(),
          recruiter: getUserName(),
          JobId: null,
        };
        moderHireInsertData(url, body);
      } else if (
        interviewType === "2Way" &&
        interviewScheduleData.templateReqNumber !== ""
      ) {
        //  interview type
        const newErrors = findMHtwoWayErrors();
        setErrors(newErrors);
        if (Object.keys(newErrors).length === 0) {
          body = {
            candidateFirstName: interviewScheduleData.firstName,
            candidateLastName: interviewScheduleData.lastName,
            candidateEmail: interviewScheduleData.email,
            recruiterFirstName:
              interviewScheduleData.TwowayData.interviewerfirstName,
            recruiterLastName:
              interviewScheduleData.TwowayData.interviewerlastName,
            recruiterEmail: interviewScheduleData.TwowayData.intervieweremail,
            reqNumber: `${getUserTenantID()}_${
              interviewScheduleData.templateReqNumber
            }`,
            reqTitle: interviewScheduleData.assessment,
            templateReqNumber: interviewScheduleData.templateReqNumber,
            candidateId: interviewScheduleData.candidateID,
            interviewType: interviewType,
            recruiterId: getCandidateId(),
            urlLink: `${window.location.protocol}${window.location.host}`,
            tenantName: getUserTenant(),
            recruiter: getUserName(),
            JobId: null,
          };
          moderHireInsertData(twoWayUrl, body);
        }
      } else {
        body = {
          candidateFirstName: interviewScheduleData.firstName,
          candidateLastName: interviewScheduleData.lastName,
          candidateEmail: interviewScheduleData.email,
          recruiterFirstName: recruiterFirstName,
          recruiterLastName: recruiterLastName,
          recruiterEmail: getUserEmail(),
          reqNumber: `${interviewScheduleData.assessment}_${
            getUserName().split("@")[0]
          }_${interviewScheduleData.templateReqNumber}`,
          reqTitle: interviewScheduleData.assessment,
          templateReqNumber: interviewScheduleData.templateReqNumber,
          candidateId: interviewScheduleData.candidateID,
          interviewType: interviewType,
          recruiterId: getCandidateId(),
          urlLink: `${window.location.protocol}${window.location.host}`,
          tenantName: getUserTenant(),
          recruiter: getUserName(),
        };
        toast.error("Please Select Interview");
      }
    } else if (
      /**Code Signal */
      assessmentPlatform === "codeSignal" &&
      interviewScheduleData.codeSignalCourseId !== ""
    ) {
      assignCodeSignalAssessment();
    } else if (assessmentPlatform === "vettingPlatform") {
      /**Vetting Platform*/
      const url = "/vetting/workflow";
      let body = "";
      let recruiterFirstName = getCandidateName().split(" ")[0];
      let recruiterLastName = getCandidateName()
        .split(" ")
        .slice(-1)[0];
      let recruiterEmail = getUserEmail();

      if (interviewType === "MCQ" || interviewType === "OneWay") {
        body = {
          candidateFirstName: interviewScheduleData.firstName,
          candidateLastName: interviewScheduleData.lastName,
          candidateEmail: interviewScheduleData.email,
          recruiterFirstName: recruiterFirstName,
          recruiterLastName: recruiterLastName,
          recruiterEmail: recruiterEmail,
          candidateId: interviewScheduleData.candidateID,
          interviewType: interviewType,
          recruiterId: getCandidateId(),
          urlLink: emailDomain,
          tenantName: getUserTenant(),
          recruiter: getUserName(),
          JobId: null,
          testID: interviewScheduleData?.vettingtestID,
          testName: interviewScheduleData.vettingTestName,
          expired: expiryRange,
          reviewer: { ...reviewer },
        };
        vettingPlatformInsertData(url, body);
      }
      // else {
      //    if(testDifficulty?.length === 0){
      //     toast.error("Please select difficulty level")
      //    }
      //    if (testDuration?.length === 0){
      //     toast.error("Please select duration of assessment")
      //    }
      // }

      // }
    } else {
      //console.log("DSFdfs", interviewScheduleData);
      toast.error("Please select assessment details ");
    }
  };

  //  get assessment history
  const getAssessments = async () => {
    try {
      setLoading(true);
      const candidateID = props.candidateData.candidateID;
      const candidateEmail = props.candidateData.email;
      const assessmentResult = await get(
        `/assignAssessment/view/${candidateID}&${candidateEmail}`
      );
      //console.log(assessmentResult);
      setLoading(false);
      //console.log(assessmentResult);
      setAssessmentData([
        // ...assessmentData,
        ...assessmentResult.data.tovuti,
        ...assessmentResult.data.oneWay,
        ...assessmentResult.data.twoWay,
      ]);
      //console.log(
      //     ...assessmentData,
      //     ...assessmentResult.data.tovuti,
      //     ...assessmentResult.data.oneWay,
      //     ...assessmentResult.data.twoWay
      //   );
    } catch (error) {
      toast.error(error);
    }
  };

  //fetch code signal history
  const fetchCodeSignalAssessmentDetail = async () => {
    try {
      setLoading(true);
      const candidateEmail = props.candidateData.email;
      const candidateID = props.candidateData.candidateID;
      let payload = {
        email: candidateEmail,
        id: candidateID,
      };
      let response = await post("/codesignal/csViewAssessment", payload);
      //console.log('responsefromcodesignal', response)
      if (response.status === 200) {
        setLoading(false);
        setCsAssessmentData([
          ...response.data,
          //codeSignal: response.data
        ]);
      }
    } catch (error) {
      toast.error(error);
    }
  };
  //console.log('codesignal', csAssessmentData)

  const fetchVettingAssessmentDetail = async () => {
    try {
      setLoading(true);
      const candidateEmail = props.candidateData.email;
      const candidateID = props.candidateData.candidateID;
      var payload = {
        email: candidateEmail,
        id: candidateID,
        difficulty: "",
        duration: "",
      };
      let response = await post("/view/vettingViewAssessment", payload);
      //console.log("DATA", response);
      if (response.status === 200) {
        setLoading(false);
        setVettingAssessmentData([
          ...response.data.mcq,
          ...response.data.oneWay,
        ]);
      }
    } catch (error) {
      toast.error(error);
    }

    console.log("VALIDATION", payload);
  };

  let finalAssessmentData = [
    ...assessmentData,
    ...csAssessmentData,
    ...vettingAssessmentData,
  ];
  // console.log(finalAssessmentData);

  useEffect(() => {
    //fetchTovutiUsersFromIndex();
    fetchCodeSignalAssessmentDetail();
    getAssessments();
    getVettingAssessment();
    fetchVettingAssessmentDetail();
    //fetchVetting()
    //console.log("vettingMCQ", vettingMCQ)
  }, [selectedTimezone]);

  // useEffect(() => {
  //     console.log(csAssessmentData)
  // }, [csAssessmentData])

  const fetchTovutiUsersFromIndex = async () => {
    setLoading(true);
    await get("/tovuti/getTovutiUsersFromIndex")
      .then((res) => {
        setTovutiUsersList(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };
  function getUnique(vettingMCQ) {
    // let uniqueArr = [];
    // for (let i of vettingMCQ) {
    //   if (uniqueArr.indexOf(i) === -1) {
    //     uniqueArr.push(i);
    //   }
    // }
    // console.log("UNIQUE", uniqueArr);
    const uniqueArr = [
      ...new Map(vettingMCQ.map((item) => [item.testName, item])).values(),
    ];
    return uniqueArr;
  }
  // console.log("finalAssessmentData", finalAssessmentData);

  const handleReviewerChange = (e) => {
    setReviewerPresent(e.target.value);
    if (e.target.value === "true") {
      setReviewer({
        name: "",
        reviewerEmail: "",
        instructions: "",
      });
    } else {
      setReviewer({
        name: getCandidateName().split(" ")[0],
        reviewerEmail: getUserEmail(),
        instructions: "",
      });
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="bl-modal-body ">
          <div>
            <div className="text-center">
              <Avatar
                size="55"
                name={
                  props.candidateData.firstName +
                  " " +
                  props.candidateData.lastName
                }
                round="80px"
              />
            </div>
          </div>
          <div>
            <div className="text-center">
              <h6 className="mt-3">
                {props.candidateData.firstName} {props.candidateData.lastName}
              </h6>
            </div>
          </div>

          <div className="card card-flat card-borderless bg-gray4">
            <div className="card-body">
              <div className="card p-2">
                <div className="d-flex justify-content-between">
                  <div
                    className={
                      // interviewType === "OneWay" ? "col-6" :
                      "col-12"
                    }
                  >
                    <div className="form-floating ">
                      <select
                        className="form-select"
                        id="interview-type"
                        name="interviewType"
                        aria-label="Floating label select example"
                        onChange={(e) => handleInterviewTypeChange(e)}
                        defaultValue={interviewType}
                      >
                        <option selected value="-1">
                          Select Type
                        </option>
                        <option value="MCQ">
                          Multiple Choice Questionaire(MCQ)
                        </option>
                        <option value="OneWay">OneWay Interview(1-way)</option>
                        <option value="2Way">
                          Expert Vetted Interview(2-way)
                        </option>
                        <option value="CodeSignal">Coding Test</option>
                      </select>
                      <label for="floatingSelect">
                        Please select the interview type *
                      </label>
                    </div>
                  </div>
                  {/* {interviewType === "OneWay" && (
                    <div className="col-6 ps-2">
                      <div className="form-floating">
                        <select
                          className="font-14 form-select"
                          id="category"
                          value={expiryRange}
                          onChange={(event) => {
                            setExpiryRange(event.target.value);
                          }}
                          required
                        >
                          {expiryData.map((item, index) => (
                            <option
                              key={index}
                              value={item.value}
                              selected={item.value === 7 ? true : false}
                            >
                              {item.SourceName}
                            </option>
                          ))}
                        </select>
                        <label for="category">Expires In</label>
                      </div>
                    </div>
                  )} */}
                </div>
              </div>

              {/* Vetting assessment selection*/}

              <div className="row mt-3">
                {interviewType === "MCQ" &&
                  assessmentPlatform === "vettingPlatform" && (
                    <div className="col-lg-12 mb-2 mb-9">
                      <div className="card p-2">
                        <div className="d-flex">
                          <div className="col-4 ">
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={vetSelectedSkill}
                                onChange={handleVettingAssessmentChange}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {getUnique(vettingMCQ).map((item) => (
                                  <option key={item?.id} value={item?.id}>
                                    {item?.testName}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Select Assessment*</label>
                            </div>
                          </div>
                          <div className="col-4 ps-2">
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={vettingDifficulty}
                                onChange={(e) => {
                                  setVettingDifficulty(e?.target?.value);
                                }}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {testDifficulty.map((item) => (
                                  <option key={item.id} value={item.difficulty}>
                                    {item.difficulty}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Difficulty Level*</label>
                            </div>
                          </div>
                          <div className="col-4 ps-2">
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={duration}
                                onChange={(e) => {
                                  setDuration(e?.target?.value);
                                }}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {testDuration.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.duration}
                                    label={`${item.duration} mins`}
                                  >
                                    {item.duration}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Duration*</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                {interviewType === "OneWay" &&
                  assessmentPlatform === "vettingPlatform" && (
                    <div>
                      <div className="card p-2">
                        <div className="d-flex">
                          <div className="col-4 ">
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={vetSelectedSkill}
                                onChange={handleVettingAssessmentChange}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {getUnique(vettingOneWay).map((item) => (
                                  <option key={item?.id} value={item?.id}>
                                    {item?.testName}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Select Assessment*</label>
                            </div>
                          </div>
                          <div className="col-4 ps-2">
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={vettingDifficulty}
                                onChange={(e) => {
                                  console.log("VIDEO DURA", testDifficultyV);
                                  setVettingDifficulty(e?.target?.value);
                                }}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {testDifficultyV.map((item) => (
                                  <option key={item.id} value={item.difficulty}>
                                    {item.difficulty}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Difficulty Level*</label>
                            </div>
                          </div>
                          <div className="col-4 ps-2">
                            <div className="form-floating">
                              <select
                                className={`form-select font-14`}
                                id="category"
                                value={duration}
                                onChange={(e) => {
                                  setDuration(e?.target?.value);
                                }}
                              >
                                <option value="" selected>
                                  Select
                                </option>
                                {testDurationV.map((item) => (
                                  <option
                                    key={item.id}
                                    value={item.duration}
                                    label={`${item.duration} mins`}
                                  >
                                    {item.duration}
                                  </option>
                                ))}
                              </select>
                              <label for="category">Duration*</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p style={{ color: "red" }}>{notification}</p>
                      <div className="form-floating col-5">
                        <p className="ps-1" style={{ paddingTop: 25 }}>
                          <strong>Select Reviewer</strong>
                        </p>
                      </div>
                      <hr />
                      <div className="card p-2">
                        <div className="d-flex">
                          {
                            <div className="col-12 ">
                              <div className="form-floating   ">
                                <select
                                  className="font-14 form-select"
                                  id="category"
                                  value={reviewerPresent}
                                  onChange={(e) => {
                                    handleReviewerChange(e);

                                    //console.log(typeof reviewerPresent);
                                  }}
                                >
                                  <option value={false}>
                                    I will be the reviewer.
                                  </option>
                                  <option value={true}>
                                    I suggest a colleague to review.
                                  </option>
                                </select>
                              </div>
                            </div>
                          }{" "}
                        </div>
                      </div>

                      {reviewerPresent === "true" && (
                        <>
                          <div className="form-floating col-5">
                            {/* <p className="ps-1" style={{ paddingTop: 25 }}>
                          <strong>Reviewer</strong>
                        </p> */}
                          </div>
                          <hr />{" "}
                          <div className="card p-2">
                            <div className="d-flex justify-content-between mb-2">
                              <div className="form-floating w-50 mr-2">
                                <input
                                  type="text"
                                  placeholder="test"
                                  className="form-control"
                                  value={reviewer.name}
                                  onChange={(e) => {
                                    setReviewer({
                                      ...reviewer,
                                      name: e.target.value.replace(
                                        /^[ \t]+|[ \t]+$/gm,

                                        ""
                                      ),
                                    });
                                  }}
                                />
                                <label htmlFor="">Name*</label>
                              </div>
                              <div className="form-floating w-50">
                                <input
                                  type="text"
                                  placeholder="test"
                                  className="form-control"
                                  value={reviewer.reviewerEmail}
                                  onChange={(e) => {
                                    setReviewer({
                                      ...reviewer,
                                      reviewerEmail: e.target.value.replace(
                                        /^[ \t]+|[ \t]+$/gm,

                                        ""
                                      ),
                                    });
                                  }}
                                />
                                <label htmlFor="">Email*</label>
                              </div>
                            </div>
                            <div className="form-floating">
                              <textarea
                                type="text"
                                rows="3"
                                placeholder="test"
                                className="form-control"
                                // value=""
                                onChange={(e) => {
                                  setReviewer({
                                    ...reviewer,
                                    instructions: e.target.value,
                                  });
                                }}
                              />
                              <label htmlFor="">Instructions to reviewer</label>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
              </div>

              {interviewType !== "F2F" && interviewType !== "Telephone" && (
                <div className="row mt-3">
                  {interviewType === "MCQ" &&
                    assessmentPlatform === "modernHire" && (
                      <div className="col-lg-12 mb-2 mb-lg-0">
                        <div className="form-floating">
                          <Select
                            options={modernHireMCQ}
                            className="assessment-interview"
                            name="modernHireMCQ"
                            placeholder="Select Skill *"
                            searchBy="Title"
                            labelField="Title"
                            //  searchable={true}
                            valueField="RequisitionNumber"
                            noDataLabel="No matches found"
                            onChange={handleAssessmentChange}
                            id="modernHireMCQ"
                          />
                        </div>
                      </div>
                    )}

                  {interviewType === "1Way" &&
                    assessmentPlatform === "modernHire" && (
                      <div className="col-lg-12 mb-2 mb-lg-0">
                        <div className="form-floating">
                          <Select
                            options={modernHireOneWay}
                            className="assessment-interview"
                            name="modernHireOneWay"
                            placeholder="Select Skill *"
                            searchBy="Title"
                            labelField="Title"
                            searchable={true}
                            valueField="RequisitionNumber"
                            noDataLabel="No matches found"
                            onChange={handleAssessmentChange}
                            id="modernHireOneWay"
                          />
                        </div>
                      </div>
                    )}

                  {interviewType === "2Way" &&
                    assessmentPlatform === "modernHire" && (
                      <div className="col-lg-12 mb-2 mb-lg-0">
                        <div className="form-floating">
                          <Select
                            options={modernHireTwoWay}
                            className="assessment-interview"
                            name="modernHireTwoWay"
                            placeholder="Select Skill *"
                            searchBy="Title"
                            labelField="Title"
                            searchable={true}
                            valueField="RequisitionNumber"
                            noDataLabel="No matches found"
                            onChange={handleAssessmentChange}
                            id="modernHireTwoWay"
                          />
                        </div>
                      </div>
                    )}
                  {/* assessmentPlatform === "monjin" && */}
                  {interviewType === "1Way" && assessmentPlatform === "monjin" && (
                    <div className="col-lg-6 mb-2 mb-lg-0">
                      <div className="form-floating">
                        <Select
                          options={monjinOnewayData}
                          className="assessment-interview"
                          name="monjinOnewayData"
                          placeholder="Select Interview"
                          searchBy="title"
                          labelField="title"
                          searchable={true}
                          valueField="monjinJobId"
                          noDataLabel="No matches found"
                          onChange={handleAssessmentChange}
                          id="monjinOnewayData"
                        />
                      </div>
                    </div>
                  )}
                  {/* assessmentPlatform === "monjin" && */}
                  {interviewType === "2Way" && assessmentPlatform === "monjin" && (
                    <div className="col-lg-6 mb-2 mb-lg-0">
                      <div className="form-floating">
                        <Select
                          options={monjinTwowayData}
                          className="assessment-interview"
                          name="monjinTwowayData"
                          placeholder="Select Interview"
                          searchBy="title"
                          labelField="title"
                          searchable={true}
                          valueField="monjinJobId"
                          noDataLabel="No matches found"
                          onChange={handleAssessmentChange}
                          id="monjinTwowayData"
                        />
                      </div>
                    </div>
                  )}
                  {/* && assessmentPlatform === "tovuti" */}
                  {interviewType === "MCQ" && assessmentPlatform === "tovuti" && (
                    <div className="col-lg-6 mb-2 mb-lg-0">
                      <div className="form-floating">
                        <Select
                          options={tovutiData}
                          searchBy="Title"
                          className="assessment-interview"
                          name="course"
                          placeholder="Select Interview"
                          labelField="Title"
                          searchable={true}
                          valueField="id"
                          noDataLabel="No matches found"
                          onChange={handleAssessmentChange}
                          id="tovutiData"
                        />
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* CodeSignal assessment selection*/}
              {interviewType === "CodeSignal" && (
                <div className="col-lg-12 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <Select
                      options={data.companyTests.tests}
                      searchBy="title"
                      className="assessment-interview"
                      name="course"
                      placeholder="Select Skill *"
                      labelField="title"
                      searchable={true}
                      valueField="id"
                      noDataLabel="No matches found"
                      onChange={handleCodeSignalAssessmentChange}
                      id="CodeSignalData"
                    />
                  </div>
                </div>
              )}

              {interviewType === "2Way" && (
                <div className="form-floating mt-3">
                  <select
                    name="interviewer"
                    className="form-select"
                    id="floatingSelectinterviewer"
                    aria-label="Floating label select example"
                    value={interviewer}
                    onChange={handleChange}
                  >
                    {/* <option selected disabled value="-1">Select The Interviewer</option> */}
                    <option selected value="none">
                      Select The Interviewer
                    </option>
                    {/* <option value="expert">A High5 Expert</option> */}
                    <option value="self">
                      I will be doing the interviewing
                    </option>
                    <option value="suggested">
                      I would like to suggest a colleague
                    </option>
                    {/* <option value="group">This is a group interview</option> */}
                  </select>
                  <label for="floatingSelect">Who is the interviewer?</label>
                  <small className="validation">{errors.interviewerType}</small>
                </div>
              )}

              {interviewType &&
              interviewer === "expert" &&
              interviewer !== "Select The Interviewer" ? (
                <p v-if="shortListForm.isExpert" className="mt-1">
                  <strong>Vendor A</strong> is set as your preferred High5
                  Expert.
                  <button
                    type="button"
                    className="btn btn-link"
                    onClick={changeFn}
                  >
                    Change This
                  </button>
                  {/* <button type="button" v-on:click="toggleExpertVettedOptions(true)" className="btn btn-link">Change This</button> */}
                </p>
              ) : null}
              {interviewType && change && interviewer === "expert" ? (
                <div
                  v-if="shortListForm.showExpertVendorOptions"
                  className="form-floating mt-3"
                >
                  <select
                    className="form-select"
                    id="expert"
                    aria-label="Floating label select example"
                  >
                    <option selected value="A">
                      Vendor A
                    </option>
                    <option value="B">Vendor B</option>
                    <option value="C">Vendor C</option>
                  </select>
                  <label for="floatingSelect">Select Expert Vendor</label>
                </div>
              ) : null}
            </div>
          </div>
          {interviewer !== "self" &&
          interviewer === "suggested" &&
          assessmentPlatform === "modernHire" ? (
            <div className="card card-flat card-borderless bg-gray4">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="firstName"
                        id="interviewerfirstName"
                        placeholder="Enter a job title"
                        className="form-control"
                        onChange={handletwoWayChange}
                      />
                      <label>First Name*</label>
                    </div>
                    <small className="validation">
                      {errors.interviewerfirstName}
                    </small>
                  </div>
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="lastName"
                        id="interviewerlastName"
                        placeholder="Enter  last Name"
                        className="form-control"
                        onChange={handletwoWayChange}
                      />
                      <label>Last Name*</label>
                    </div>
                    <small className="validation">
                      {errors.interviewerlastName}
                    </small>
                  </div>
                </div>
                <div className="form-floating mt-3">
                  <input
                    type="email"
                    name="intervieweremail"
                    id="intervieweremail"
                    placeholder="Enter a job title"
                    className="form-control"
                    onChange={handletwoWayChange}
                  />
                  <label>Enter your colleagues email*</label>
                </div>
                <small className="validation">{errors.intervieweremail}</small>
              </div>
            </div>
          ) : null}

          {interviewer !== "self" &&
          interviewer === "suggested" &&
          interviewType === "Telephone" &&
          interviewType !== "F2F" &&
          interviewType !== "MCQ" &&
          interviewType !== "1Way" &&
          interviewType !== "2Way" ? (
            <div className="card card-flat card-borderless bg-gray4">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Enter a job title"
                        className="form-control"
                        onChange={handleChange}
                      />
                      <label> Phone number </label>
                    </div>
                    <small className="validation"></small>
                  </div>
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="voiceCall"
                        placeholder="Enter a job title"
                        className="form-control"
                        onChange={handleChange}
                      />
                      <label>Voice Call Link</label>
                    </div>
                    <small className="validation"></small>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {interviewer !== "self" &&
          interviewer === "suggested" &&
          interviewType === "F2F" &&
          interviewType !== "MCQ" &&
          interviewType !== "1Way" &&
          interviewType !== "2Way" &&
          interviewType !== "Telephone" ? (
            <div className="card card-flat card-borderless bg-gray4">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="phoneNumber"
                        placeholder="Enter a job title"
                        className="form-control"
                        onChange={handleChange}
                      />
                      <label> Phone number </label>
                    </div>
                    <small className="validation"></small>
                  </div>
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    <div className="form-floating">
                      <input
                        type="text"
                        name="address"
                        placeholder="Enter a job title"
                        className="form-control"
                        onChange={handleChange}
                      />
                      <label>Address</label>
                    </div>
                    <small className="validation"></small>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {interviewer === "group" ? (
            <div className="card card-flat card-borderless bg-gray4">
              <div className="card-body">
                <div className="form-check form-switch d-flex align-items-center mt-5">
                  {checkedFn === true ? (
                    <input
                      className="form-check-input"
                      name="isHMAttending"
                      type="checkbox"
                      id="hmattend"
                      onChange={handleChange}
                      checked
                    />
                  ) : (
                    <input
                      className="form-check-input"
                      name="isHMAttending"
                      type="checkbox"
                      id="hmattend"
                      onChange={handleChange}
                    />
                  )}

                  <label className="form-check-label pt-0 ml-3" for="hmattend">
                    Will you be attending?
                    {checkedFn === true ? (
                      <span v-if="isHMAttending" className="tag tag-green2">
                        YES
                      </span>
                    ) : (
                      <span v-else className="tag">
                        NO
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
          ) : null}

          {(interviewer === "self" || interviewer === "suggested") &&
          assessmentPlatform === "monjin" &&
          interviewType === "2Way" ? (
            <div className="card card-flat card-borderless bg-gray4 mt-3">
              <div className="card-body">
                <p>
                  Please update available timeslot for this interview based on
                  your current availability
                </p>
                <div className="row mb-2">
                  <div className="col-lg-12">Select Timezone </div>
                  <div className="col-lg-12 mb-2">
                    {/* <TimezoneSelect
                      id="selectedTimezone"
                      value={
                        interviewScheduleData.monjinTwowayData.selectedTimezone
                      }
                      onChange={updatetwowayTimeZone}
                      labelStyle={"abbrev"}
                    /> */}
                    <small className="validation">
                      {errors.selectedTimezone}
                    </small>
                  </div>

                  <div className="col-lg-12 d-flex flex-column flex-md-row">
                    <div
                      className="form-floating form-date w-60"
                      style={{ zIndex: "999" }}
                      showTimeSelect
                    >
                      <span>Select date and time</span>
                      <DatePicker
                        className="form-control"
                        dateFormat="MMMM d, yyyy h:mm aa"
                        selected={
                          interviewScheduleData.monjinTwowayData.interviewdate
                        }
                        onChange={(date) => {
                          updatetwowaySlot(date);
                        }}
                        placeholderText="Choose  date and time"
                      />
                      <small className="validation">
                        {errors.interviewdate}
                      </small>
                    </div>
                    <div className=" d-flex">
                      <div className=" w-2 ml-2">
                        <span>Select Duration</span>
                        <select
                          className="form-control w-100"
                          id="floatingSelect"
                          aria-label="Floating label select example"
                          onChange={updatetwowayDuration}
                        >
                          <option value="select">Select Duration</option>
                          <option value="15">15 Min</option>
                          <option value="30">30 Min</option>
                          <option value="45">45 Min</option>
                          <option value="60">1 Hrs</option>
                          <option value="90">1.5 Hrs</option>
                          <option value="120">2 Hrs </option>
                        </select>
                        <small className="validation">
                          {errors.interviewduration}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="row mb-2">
                                    <div className="col-lg-12 d-flex flex-column flex-md-row">
                                        <div className="form-floating form-date w-100">
                                            <label>Available Date Option 2*</label>{" "}
                                            <input
                                                type="date"
                                                placeholder="Enter Date"
                                                className="form-control date"
                                            />
                                        </div>
                                        <div className="w-100 d-flex">
                                            <div className="form-floating w-50">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Time"
                                                    className="form-control"
                                                />{" "}
                                                <label>Time</label>
                                            </div>
                                            <div className="form-floating w-50">
                                                <select
                                                    className="form-select"
                                                    id="floatingSelect"
                                                    aria-label="Floating label select example"
                                                >
                                                    <option selected value="AM">
                                                        AM
                                                    </option>
                                                    <option value="PM">PM</option>
                                                </select>
                                                <label for="floatingSelect">AM/PM</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-12 d-flex flex-column flex-md-row">
                                        <div className="form-floating form-date w-100">
                                            <label>Available Date Option 3*</label>{" "}
                                            <input
                                                type="date"
                                                placeholder="Enter Date"
                                                className="form-control date"
                                            />
                                        </div>
                                        <div className="w-100 d-flex">
                                            <div className="form-floating w-50">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Time"
                                                    className="form-control"
                                                />{" "}
                                                <label>Time</label>
                                            </div>
                                            <div className="form-floating w-50">
                                                <select
                                                    className="form-select"
                                                    id="floatingSelect"
                                                    aria-label="Floating label select example"
                                                >
                                                    <option selected value="AM">
                                                        AM
                                                    </option>
                                                    <option value="PM">PM</option>
                                                </select>
                                                <label for="floatingSelect">AM/PM</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-2">
                                    <div className="col-lg-12 d-flex flex-column flex-md-row">
                                        <div className="form-floating form-date w-100">
                                            <label>Available Date Option 4*</label>{" "}
                                            <input
                                                type="date"
                                                placeholder="Enter Date"
                                                className="form-control date"
                                            />
                                        </div>
                                        <div className="w-100 d-flex">
                                            <div className="form-floating w-50">
                                                <input
                                                    type="text"
                                                    placeholder="Enter Time"
                                                    className="form-control"
                                                />{" "}
                                                <label>Time</label>
                                            </div>
                                            <div className="form-floating w-50">
                                                <select
                                                    className="form-select"
                                                    id="floatingSelect"
                                                    aria-label="Floating label select example"
                                                >
                                                    <option selected value="AM">
                                                        AM
                                                    </option>
                                                    <option value="PM">PM</option>
                                                </select>
                                                <label for="floatingSelect">AM/PM</label>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
              </div>
            </div>
          ) : null}
          {interviewer === "group" && checkedFn === false ? (
            <>
              <div
                v-if="isHMAttending == false && shortListForm.isGroup == true"
                className="card card card-flat card-borderless bg-gray4 mt-3"
              >
                <div className="card-body">
                  <div className="form-floating">
                    <input
                      type="text"
                      placeholder="Enter POC"
                      className="form-control"
                    />
                    <label>
                      Enter The Email for the point of contact for this
                      interview?
                    </label>
                  </div>
                </div>
              </div>
            </>
          ) : null}
          {interviewer === "group" ? (
            <div
              v-if="shortListForm.isGroup"
              className="card card-flat card-borderless bg-gray4"
            >
              <div className="card-body">
                <div className="form-floating">
                  <div className="form-group">
                    <label className="mb-2">
                      Enter the Emails of the <strong>required</strong>{" "}
                      attendes*
                    </label>
                    <input
                      type="text"
                      className="inputEditRequest"
                      placeholder="Enter emails and hit enter"
                    />
                    <vueemailsinput placeholder="Enter emails and hit enter"></vueemailsinput>
                  </div>
                </div>
                <div className="form-floating">
                  <div className="form-group">
                    <label className="mb-2">
                      Enter the Emails of <strong>optional</strong> attendees
                    </label>
                    <input
                      type="text"
                      className="inputEditRequest"
                      placeholder="Enter emails and hit enter"
                    />
                    {/* <vueemailsinput placeholder="Enter emails and hit enter"></vueemailsinput> */}
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>

        <div v-if="!formComplete" className="modal-footer">
          {/* <div v-if="hasSubmitError" className="modal-body py-2">
                            <div className="alert alert-danger d-block mt-2">
                                <div className="inner d-flex">
                                    <div className="mr-2">
                                        <i className="far fa-exclamation-circle font-red font-18"></i>
                                    </div>
                                    <div>
                                        <h6 className="title mb-0">Unable To Complete Request</h6>
                                        <p className="description">We're sorry but we were unable to complete this request at this time.  Please try again in a few minutes and if the problem still persists, please <a href="/support">contact support</a></p>
                                    </div>
                                </div>
                            </div>
                        </div> */}

          {/* <button type="button" v-on:click="close" :disabled="submittingForm" className="btn btn-secondary btn-sm" data-dismiss="modal">Cancel</button> */}
          <button
            type="button"
            className="btn btn-secondary btn-sm"
            data-dismiss="modal"
            onClick={close}
          >
            Cancel
          </button>
          {interviewer !== "-1" && disableSubmit === false ? (
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={(e) => onSubmitAssignAssessment(e)}
              disabled={
                vettingDifficulty === "" || duration === ""
                  ? true
                  : reviewer.name !== "" && reviewer.reviewerEmail !== ""
                  ? false
                  : true
              }
            >
              Assign
            </button>
          ) : (
            <button
              type="button"
              disabled="disableSubmit"
              className="btn btn-primary btn-sm"
            >
              Assign
            </button>
          )}
        </div>
      </div>
    </>
  );
}

export default AssignAssessment;
