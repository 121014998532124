import React, { useState, useEffect } from "react";
import TopNavBar from "../../../layout/TopNavBar";
import ReportTopNavBar from "../../../report/ReportTopNavBar";
import SideNavBar from "../../../layout/SideNavBar";
import { getTenantType, getUserRole } from "../../../../service/AuthService";
import JobDetails from "./JobDetails";
import Footer from "../../../layout/Footer";

const Index = () => {
  const [toggle, setToggle] = useState(true);
  const [userRole, setUserRole] = useState("");

  useEffect(() => {
    const role = getUserRole();
    setUserRole(role);
  }, []);

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  return (
    <body
      className={
        toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
      }
      id="body"
    >
      <div className="body-decorator body-decorator-top"></div>
      <div className="body-decorator body-decorator-bottom"></div>
      {/* {userRole === "CommunityManager" ? (
        <ReportTopNavBar handleToggale={toggleDiv} />
      ) : ( */}
      <TopNavBar handleToggale={toggleDiv} />
      {/* )} */}
      <SideNavBar />
      <div id="Content">
        <JobDetails />
        {getTenantType() == 1 && <Footer />}
      </div>
    </body>
  );
};

export default Index;
