import React, { useEffect } from "react";
import _ from "lodash";

const Experience = (props) => {
  const experienceLevel = [
    "Intern",
    "Less than one year",
    "1-3 years",
    "3-5 years",
    "5-10 years",
    "10+ years",
  ];

  let QualificationType =
    Object.keys(props?.dropdownList).length > 0
      ? props?.dropdownList.QualificationType.filter(
          (item) => item.PositionName !== "Select" && item.PositionName !== "NA"
        ).map((item) => {
          return item.PositionName;
        })
      : [];

  QualificationType = _.sortBy(QualificationType);

  useEffect(() => {}, [props]);

  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body m-0">
          <div className="row">
            <div className="col-lg-6">
              <div className="form-floating">
                <select
                  className={
                    props?.isFormValid &&
                    props?.addJobData.experienceLevel === ""
                      ? "font-14 form-select border border-danger"
                      : "font-14 form-select"
                  }
                  name="experienceLevel"
                  onChange={(e) => props?.handleChange(e)}
                  disabled={props?.edit}
                >
                  <option value="" selected>
                    Select
                  </option>
                  {experienceLevel?.map((e, i) => (
                    <option
                      key={i}
                      selected={e === props?.addJobData?.experienceLevel}
                      value={e}
                    >
                      {e}
                    </option>
                  ))}
                </select>
                <label>Required experience*</label>

                {props?.isFormValid &&
                  props?.addJobData.experienceLevel == "" && (
                    <small className="ml-2 validation">*Required field</small>
                  )}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-floating">
                <select
                  className={
                    props?.isFormValid &&
                    props?.addJobData.education.length === 0
                      ? "font-14 form-select border border-danger"
                      : "font-14 form-select"
                  }
                  onChange={(e) =>
                    props?.setAddJobData({
                      ...props?.addJobData,
                      education: [e.target.value],
                    })
                  }
                  disabled={props?.edit}
                >
                  <option value="" selected>
                    Select
                  </option>
                  {QualificationType?.map((e, i) => (
                    <option
                      key={i}
                      selected={props?.addJobData?.education[0] === e}
                    >
                      {e}
                    </option>
                  ))}
                  <option>NA</option>
                </select>
                <label>Required education*</label>
                {props?.isFormValid && props?.addJobData.education == "" && (
                  <small className="ml-2 validation">*Required field</small>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Experience;
