import React, { Fragment, useEffect, useState } from "react";
import PendingApproval from "./dashboard/PendingApproval";
import ManageTenants from "./dashboard/ManageTenants";
import Users from "./dashboard/Users";
import DashBoard from "../salesadmin/dashboard";
import {
  getCandidateName,
  getUserName,
  getUserRole,
} from "../../service/AuthService";
import Settings from "./dashboard/Settings";
function Activity() {
  const [userdetails, setUser] = useState({ FirstName: "" });
  const { FirstName } = userdetails;
  const [toggle, setToggle] = useState(true);
  const [approvals, setapprovals] = useState(false);
  const [tenants, settenants] = useState(true);
  const [users, setusers] = useState(false);
  const [settings, setSettings] = useState(false);

  const getProfile = () => {
    const username = getCandidateName();
    const FirstName = username.substr(0, username.indexOf(" "));
    setUser({ FirstName: FirstName });
  };

  useEffect(() => {
    getProfile();
  }, []);

  const onTabClick = (category, type) => {
    if (type === "approvals") {
      setapprovals(true);
      settenants(false);
      setusers(false);
      setSettings(false);
    }
    if (type === "tenants") {
      setapprovals(false);
      settenants(true);
      setusers(false);
      setSettings(false);
    }
    if (type === "users") {
      setapprovals(false);
      settenants(false);
      setusers(true);
      setSettings(false);
    }
    if (type === "settings") {
      setapprovals(false);
      settenants(false);
      setusers(false);
      setSettings(true);
    }
  };

  const role = getUserRole();

  return (
    <Fragment>
      <div class="col-lg-9">
        <div id="SuperAdminDashboard" class="row mb-3">
          <div class="col-lg-12">
            <div class="card">
              <div class="card-body">
                <ul class="nav nav-tabs pt-1">
                  {/* <li class="nav-item">
                    <button
                      onClick={() => {
                        onTabClick("infoTabs", "approvals");
                      }}
                      type="button"
                      class={
                        approvals ? "nav-link pb-3 active" : "nav-link pb-3"
                      }
                    >
                      {role !== "SalesAdmin" ? "Suppliers" : "Intake Forms"}
                    </button>
                  </li> */}
                  <li class="nav-item">
                    <button
                      onClick={() => {
                        onTabClick("infoTabs", "tenants");
                      }}
                      type="button"
                      class={tenants ? "nav-link pb-3 active" : "nav-link pb-3"}
                    >
                      Tenants
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      onClick={() => {
                        onTabClick("infoTabs", "users");
                      }}
                      type="button"
                      class={users ? "nav-link pb-3 active" : "nav-link pb-3"}
                    >
                      Users
                    </button>
                  </li>
                  <li class="nav-item">
                    <button
                      onClick={() => {
                        onTabClick("infoTabs", "settings");
                      }}
                      type="button"
                      class={
                        settings ? "nav-link pb-3 active" : "nav-link pb-3"
                      }
                    >
                      Settings
                    </button>
                  </li>
                </ul>
                <hr class="mt-0" />
                {tenants && (
                  <div v-if="tabs.settings">
                    <ManageTenants />
                  </div>
                )}
                {/* {approvals === true &&
                  (role !== "SalesAdmin" ? <PendingApproval /> : <DashBoard />)} */}
                {users === true && <Users />}
                {settings && <Settings />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Activity;
