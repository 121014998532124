import React from "react";
import Modal from "react-bootstrap/esm/Modal";

const ScreeningQuestions = (props) => {
  const { jobData, setQNA, qna } = props;
  return (
    <Modal size="lg" show={qna}>
      <Modal.Header>
        <h6 class="">Screening Questions</h6>
        <button
          type="button"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setQNA(false)}
          class="close p-0 bl-modal-close-btn"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        {jobData.screeningQuestions &&
        JSON.parse(jobData.screeningQuestions).length > 0 ? (
          <div class="d-flex mb-2 pl-1 ml-4">
            <ul>
              {JSON.parse(jobData.screeningQuestions).map((item, index) => (
                <li className="d-flex m-3">
                  <div className="">
                    <span className="col-lg-1">{index + 1}</span>
                  </div>
                  <div className="ml-3 mb-2">
                    <span className="col-lg-11 mb-1">{item.question}</span>
                    <br />
                    <small>
                      {item.name == "Language" ? (
                        <>
                          Language:{" "}
                          <strong>{JSON.parse(item?.input)?.join(", ")}</strong>
                        </>
                      ) : item.name == "Industry Experience" ? (
                        <>
                          Industry: <strong>{item.input}</strong>
                          <br />
                          Response Type: <strong> Numeric </strong>
                        </>
                      ) : item.name == "Education" ||
                        item.name == "Certification" ||
                        item.name == "Shift Preference" ||
                        item.name == "Preferred Location" ||
                        item.name == "Work Preference" ||
                        item.name == "Willing to relocate" ||
                        item.name == "Nursing Specialty" ||
                        item.name == "Nursing License" ||
                        item.name == "License State" ||
                        item.input == "Drop-down" ||
                        item.input == "Drop-down(Multiselect)" ? (
                        <>
                          Response Type: <strong>Selective</strong>
                        </>
                      ) : (
                        ""
                      )}{" "}
                      {item.input == "Numeric" ? (
                        <>
                          Response Type: <strong> Numeric </strong>
                        </>
                      ) : item.name === "Language" ? (
                        <>
                          <br />
                          Response Type: <strong> Selective </strong>
                        </>
                      ) : item.input == "Yes/No" ||
                        item.input == "Text" ||
                        item.input == "Long Text" ? (
                        <>
                          Response Type: <strong> {item.input} </strong>
                        </>
                      ) : (
                        ""
                      )}
                    </small>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="mb-3">
            <div class="text-center p-3">
              <div class="avatar avatar-lg">
                <i class="fad fa-users-slash"></i>
              </div>
              <div class="mt-2">No Screening Questions Added</div>
            </div>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button variant="secondary" onClick={() => setQNA(false)}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ScreeningQuestions;
