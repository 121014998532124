import {
  GET_TALENT_COMMUNITY_BEGIN,
  GET_TALENT_COMMUNITY_SUCCESS,
  GET_TALENT_COMMUNITY_FAILURE,
} from "../../../actions/directsourcing/talentcommunity/TalentCommunity";

const initialState = {
  loading: false,
  talentPoolData: null,
  talentPoolDataLoading: false,
};

const getTalentCommunityReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TALENT_COMMUNITY_BEGIN:
      return {
        ...state,
        loading: true,
        talentPoolData: null,
      };
    case GET_TALENT_COMMUNITY_SUCCESS:
      return {
        ...state,
        talentPoolData: payload,
        loading: false,
      };
    case GET_TALENT_COMMUNITY_FAILURE:
      return {
        ...state,
        talentPoolData: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default getTalentCommunityReducer;
