import React, { Fragment } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const AutomatchScore = ({ average }) => {
  return (
    <Fragment>
      {average.map(
        (item) =>
          item.score > 0 && (
            <td className="p-3 vertical-align: top">
              <div className="d-flex flex-column align-items-center justify-content-end">
                {item.name !== "Overall" ? (
                  <div style={{ width: "3rem", marginLeft: -10 }}>
                    <CircularProgressbar
                      strokeWidth={3}
                      value={item.score}
                      text={`${item.score}%`}
                      styles={buildStyles({
                        textColor: item.score >= 50 ? "green" : "black",
                        alignItems: "top",
                        pathColor: item.score >= 50 ? "green" : "orange",
                      })}
                    />
                  </div>
                ) : (
                  <div style={{ width: "4rem", marginLeft: -20 }}>
                    <CircularProgressbar
                      value={item.score}
                      text={`${item.score}%`}
                      background
                      backgroundPadding={6}
                      styles={buildStyles({
                        backgroundColor: item.score >= 50 ? "green" : "orange",
                        textColor: "#fff",
                        pathColor: "#fff",
                        trailColor: "transparent",
                      })}
                    />
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    whiteSpace: "wrap",
                    marginLeft: -5,
                  }}
                >
                  <small
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                      marginLeft: -5,
                      fontSize: 10,
                    }}
                  >
                    {item.name}
                  </small>
                </div>
              </div>
            </td>
          )
      )}
    </Fragment>
  );
};

export default AutomatchScore;
