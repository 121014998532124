import {
  GET_RECRUITER_LIST_BEGIN,
  GET_RECRUITER_LIST_SUCCESS,
  GET_RECRUITER_LIST_FAILURE,
} from "../../actions/recruiter/RecruiterList";

const initialState = {
  loading: true,
  recruiterList: null,
};

const getRecruiterListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_RECRUITER_LIST_BEGIN:
      return {
        ...state,
        loading: true,
        recruiterList: null,
      };
    case GET_RECRUITER_LIST_SUCCESS:
      return {
        ...state,
        recruiterList: payload,
        loading: false,
      };
    case GET_RECRUITER_LIST_FAILURE:
      return {
        ...state,
        recruiterList: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default getRecruiterListReducer;
