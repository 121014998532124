import {
  GET_SUBCOMMUNITY_BEGIN,
  GET_SUBCOMMUNITY_SUCCESS,
  GET_SUBCOMMUNITY_FAILURE,
} from "../../actions/common/GetSubCommunity";

const initialState = {
  loading: true,
  subCommunity: [],
};

const getSubCommunityReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SUBCOMMUNITY_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case GET_SUBCOMMUNITY_SUCCESS:
      return {
        ...state,
        subCommunity: payload,
        loading: false,
      };
    case GET_SUBCOMMUNITY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

export default getSubCommunityReducer;
