import React from "react";
import { PageNotFoundImage } from "../../constants/Constants";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { updateLastLogin } from "../../actions/auth/LastLogin";

const SessionTimeOut = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSession = () => {
    dispatch(updateLastLogin())
      .then((result) => {
        history.push(
          localStorage.getItem("previousRoute")
            ? localStorage.getItem("previousRoute")
            : "/high5Hire"
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <section
        className="section section-sm bg-gray3 text-center bg-cover bg-cover notFound"
        style={{ height: "100vh" }}
      >
        <div className="container-fluid h-100">
          <div className="row h-100 align-items-center justify-content-center">
            <div className="col-lg-7">
              <div className="notFoundIcon">
                <img
                  height="60px"
                  width="132px"
                  src={PageNotFoundImage}
                  alt=""
                />
                {/* <lottie-player src="/lottie/404.json" background="transparent" speed="1" className="comingSoonImg notFoundGif" loop autoplay></lottie-player> */}
              </div>
              <h4 className="font-bold brand_blue">
                <span className="font-coral">Session Expired</span>
              </h4>
              <p>
                Don't worry! Your work is safely saved. Simply refresh the
                session, and you'll be right back where you left off.
              </p>
              <button
                className="btn btn-coral btn-rounded mt-3 mb-5"
                onClick={() => handleSession()}
              >
                Refresh
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SessionTimeOut;
