import { checkEmptyObject } from "../function/CommonFunction";
import Validator from 'email-validator';

export const contactInfoValidation = (contactInfo, setContactVal) => {
    let firstNameError = ''; let lastNameError = ''; let emailError = '';
    let zipcodeError = ''
    let addressCityError = ''
    let addressStateError = ''
    let countryError = ''
    if (contactInfo.firstName.trim().length === 0) {
        firstNameError = '*Required Field'
    }
    if (contactInfo.lastName.trim().length === 0) {
        lastNameError = '*Required Field'
    }
    if (contactInfo.email.trim().length === 0) {
        emailError = '*Required Field'
    } else if(!Validator.validate(contactInfo.email)) {
        emailError = '*Enter Valid Email'
    }
    if (contactInfo.zipcode.trim().length === 0) {
        zipcodeError = '*Required Field'
        
    }
    if (contactInfo.addressCity.trim().length === 0) {
        addressCityError = '*Required Field'
        
    }
    if (contactInfo.addressState.trim().length === 0) {
        addressStateError = '*Required Field'
       
    }
    if (contactInfo.country.trim().length === 0) {
        countryError = '*Required Field'
       
    }

    const validation = {
        firstName: firstNameError,
        lastName: lastNameError,
        email: emailError,
        zipcode: zipcodeError,
        addressCity: addressCityError,
        addressState: addressStateError,
        country: countryError,
    }
    return validation;
}

export const profileInfoValidation = (profileData) => {
    let preferredSalaryCurrencyError = ''; let minContractRateCurrencyError = '';
    let preferredLocationError = ''; let primarySkillsError = '';
    let currentJobTitleError = ''; let experienceMonthError = '';
    let experienceYearError = '';
    if (profileData.isFresher === false) {
        if (profileData.experienceYear === '') {
            experienceYearError = '*Required Field'
            
        }
    }
    if (profileData.isFresher === false) {
        if (profileData.experienceMonth === '') {
            experienceMonthError = '*Required Field'
            
        }
    }
    if (profileData.isFresher === false) {
        if (profileData.currentJobTitle === '') {
            currentJobTitleError = '*Required Field'
            
        }
    }
    if( profileData.preferedLocations.length === 0 ) {
        preferredLocationError = '*Required Field'
    }
    if(profileData.primarySkills.length === 0 ) {
        primarySkillsError = '*Required Field'
    }
    // if (profileData.minContractRate.trim().length !== 0 && profileData.minContractRateCurrency.trim().length === 0) {
    //     minContractRateCurrencyError='*Field cannot be empty'
    // }
    if (profileData.preferredSalary !== 0 && profileData.preferredSalaryCurrency === 0) {
        preferredSalaryCurrencyError='*Field cannot be empty'
    }
    const validation =  {
        preferredSalaryCurrency: preferredSalaryCurrencyError,
        minContractRateCurrency: minContractRateCurrencyError,
        preferredLocation: preferredLocationError,
        primarySkills: primarySkillsError,
        currentJobTitle: currentJobTitleError,
        experienceYear: experienceYearError,
        experienceMonth: experienceMonthError,
    }
    return validation;
}

export const experienceValidation = (experiences) => {
    let validation = []
    experiences.forEach((item)=>{
        let validationObj = { employerName: '', industry: '', jobTitle: '', startDate: '', endDate: '' }
        if(checkEmptyObject(item)) {
            validation.push(validationObj)
        } else {
            if(item.employerName.trim().length === 0) {
                validationObj = { ...validationObj, employerName: '*Required Field' }
            }
            if(item.industry.trim().length === 0) {
                validationObj = { ...validationObj, industry: '*Required Field' }
            }
            if(item.jobTitle.trim().length === 0) {
                validationObj = { ...validationObj, jobTitle: '*Required Field' }
            }
            if(item.startDate.trim().length === 0) {
                validationObj = { ...validationObj, startDate: '*Required Field' }
            }
            if(item.endDate.trim().length === 0 && item.isSelect === false) {
                validationObj = { ...validationObj, endDate: '*Required Field' }
            }
            validation.push(validationObj)
        }
    })
    return validation
}

export const educationValidation = (educations) => {
    let validation = []
    educations.forEach((item)=>{
        let validationObj = { graduatedYear: '', educationType: '', educationProgram: '', school: '', major: '' }
        if(checkEmptyObject(item)) {
            validation.push(validationObj)
        } else {
            if(item.graduatedYear.trim().length === 0) {
                validationObj = { ...validationObj, graduatedYear: '*Required Field' }
            }
            if(item.educationType.trim().length === 0) {
                validationObj = { ...validationObj, educationType: '*Required Field' }
            }
            if(item.educationProgram.trim().length === 0) {
                validationObj = { ...validationObj, educationProgram: '*Required Field' }
            }
            if(item.school.trim().length === 0) {
                validationObj = { ...validationObj, school: '*Required Field' }
            }
            if(item.major.trim().length === 0) {
                validationObj = { ...validationObj, major: '*Required Field' }
            }
            validation.push(validationObj)
        }
    })
    return validation
}

export const certificationValidation = (certifications) => {
    let validation = []
    certifications.forEach((item)=>{
        let validationObj = { certificationName: '', certificate: '', issueDate: '', expiryDate: '' }
        if(checkEmptyObject(item)) {
            validation.push(validationObj)
        } else {
            if(item.certificationName.trim().length === 0) {
                validationObj = { ...validationObj, certificationName: '*Required Field' }
            }
            if(item.certificate.trim().length === 0) {
                validationObj = { ...validationObj, certificate: '*Required Field' }
            }
            if(item.issueDate.trim().length === 0) {
                validationObj = { ...validationObj, issueDate: '*Required Field' }
            }
            if(item.expiryDate.trim().length === 0) {
                validationObj = { ...validationObj, expiryDate: '*Required Field' }
            }
            validation.push(validationObj)
        }
    })
    return validation
}

export const licenseValidation = (licenses) => {
    let validation = []
    licenses.forEach((item)=>{
        let validationObj = { licenseName: '', license: '', state: '', expiryDate: '' }
        if(checkEmptyObject(item)) {
            validation.push(validationObj)
        } else {
            if(item.licenseName.trim().length === 0) {
                validationObj = { ...validationObj, licenseName: '*Required Field' }
            }
            if(item.license.trim().length === 0) {
                validationObj = { ...validationObj, license: '*Required Field' }
            }
            if(item.state.trim().length === 0) {
                validationObj = { ...validationObj, state: '*Required Field' }
            }
            if(item.expiryDate.trim().length === 0) {
                validationObj = { ...validationObj, expiryDate: '*Required Field' }
            }
            validation.push(validationObj)
        }
    })
    return validation
}
