import React, { useState, useEffect } from "react";

import { toast } from "react-toastify";
import TopNavBar from "./TopNavBar";
import { get } from "../../service/ApiService";
import Loader from "../Loader/Loader";
import CSMDashboard from "../components_v3/superAdmin/Dashboard/CSMDashboard";
import { getTenantType } from "../../service/AuthService";
import Footer from "../layout/Footer";

const H5CDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [tenantData, setTenantData] = useState([]);

  useEffect(() => {
    getTenantData();
  }, []);

  const getTenantData = async () => {
    try {
      setLoading(true);
      const res = await get("/h5coordinator/fetchtenantfromusername");
      if (res.status === 200 && res.data[0]) {
        const result = res.data[0];
        setTenantData(result);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Error Occured While accessing Tenant Details");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing Tenant Details");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <body className="layout2" style={{ overflowX: "visible" }}>
        <TopNavBar tenantData={tenantData} />
        <div id="Content">
          <div className="section section-sm ">
            <div class="container ">
              <div class="row justify-content-center">
                <CSMDashboard isFrom="CSM" getTenantData={getTenantData} />
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
    </>
  );
};

export default H5CDashboard;
