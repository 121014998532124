import React, { useEffect, useState } from "react";
import DatePicker from "../../../../common/input/DatePicker";
import { toast } from "react-toastify";
import moment from "moment";

const PositionAndDates = (props) => {
  const [expiresOnCalendar, setExpiresOnCalendar] = useState(false);
  const handleExpiresOnDateChange = (days) => {
    const date = new Date();
    date.setDate(date.getDate() + days);
    props.setAddJobData({
      ...props.addJobData,
      expiresOn: date,
    });
    setExpiresOnCalendar(true);
  };
  useEffect(() => {
    console.log(props.addJobData.expiresOn, "llllll");
    if (props.addJobData.expiresOn === "") setExpiresOnCalendar(false);
    else setExpiresOnCalendar(true);
  }, [props.addJobData.expiresOn]);
  return (
    <>
      <div className="card card-flat bg-gray4 m-3">
        <div className="card-body">
          <div className="row mt-2">
            <div className="col-lg-3">
              <div className="form-floating">
                <input
                  name="numberOfPositions"
                  type="number"
                  min="1"
                  className="form-control"
                  value={props?.addJobData?.numberOfPositions}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value) {
                      if (+value === 0) {
                        toast.error("Cannot Be Zero");
                      } else if (+value < 0 || +value === -0) {
                        toast.error("Negative numbers are not allowed");
                      } else {
                        props.setAddJobData({
                          ...props?.addJobData,
                          numberOfPositions: e.target.value,
                          allowedSubmittals: e.target.value * 5,
                        });
                      }
                    } else {
                      props.setAddJobData({
                        ...props?.addJobData,
                        numberOfPositions: e.target.value,
                        allowedSubmittals: e.target.value * 5,
                      });
                    }
                  }}
                />
                <label>Total no. of openings</label>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-floating">
                <input
                  type="number"
                  min="5"
                  className="form-control"
                  value={props?.addJobData?.allowedSubmittals}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (value) {
                      if (+value === 0) {
                        toast.error("Cannot Be Zero");
                      } else if (+value < 0 || +value === -0) {
                        toast.error("Negative numbers are not allowed");
                      } else {
                        props?.setAddJobData({
                          ...props?.addJobData,
                          allowedSubmittals: e.target.value,
                        });
                      }
                    } else {
                      props?.setAddJobData({
                        ...props?.addJobData,
                        allowedSubmittals: e.target.value,
                      });
                    }
                  }}
                />
                <label>Allowed submittals</label>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-floating form-date">
                <DatePicker
                  type="date"
                  text="Active From*"
                  name="activeFrom"
                  value={moment(new Date(props?.addJobData.activeFrom)).format(
                    "YYYY-MM-DD"
                  )}
                  onChange={props?.handleChange}
                  className={
                    props?.addJobVal?.activeFromError
                      ? "form-control border border-danger"
                      : "form-control"
                  }
                  minRange={moment(new Date()).format("YYYY-MM-DD")}
                  maxRange="9999-12-31"
                />
              </div>
              {props?.addJobVal?.activeFromError && (
                <small className="validation">
                  {props?.addJobVal?.activeFromError}
                </small>
              )}
            </div>
            <div className="col-lg-3">
              <div className="form-floating form-date">
                {expiresOnCalendar && (
                  <DatePicker
                    type="date"
                    text="Expires on*"
                    name="expiresOn"
                    value={moment(new Date(props?.addJobData.expiresOn)).format(
                      "YYYY-MM-DD"
                    )}
                    onChange={props?.handleChange}
                    className={
                      props?.isFormValid && props?.addJobData?.expiresOn === ""
                        ? "form-control border border-danger"
                        : "form-control"
                    }
                    minRange={moment(new Date()).format("YYYY-MM-DD")}
                    maxRange="9999-12-31"
                  />
                )}
                {!expiresOnCalendar && (
                  <>
                    <label>Expires on*</label>
                    <select
                      type="text"
                      name="expiresOn"
                      className={
                        props?.isFormValid &&
                        props?.addJobData?.expiresOn === ""
                          ? "form-select border border-danger"
                          : "form-select"
                      }
                      value={moment(
                        new Date(props?.addJobData.expiresOn)
                      ).format("YYYY-MM-DD")}
                      onChange={(e) => {
                        const selectedValue = parseInt(e.target.value);
                        if (selectedValue === 15) {
                          handleExpiresOnDateChange(15);
                        } else if (selectedValue === 30) {
                          handleExpiresOnDateChange(30);
                        } else if (selectedValue === 60) {
                          handleExpiresOnDateChange(60);
                        } else if (selectedValue === 0) {
                          setExpiresOnCalendar(true);
                        }
                      }}
                    >
                      <option value="" selected>
                        Select
                      </option>
                      <option value="15">15 days</option>
                      <option value="30">30 days</option>
                      <option value="60">60 days</option>
                      <option value="0">Choose Date</option>
                    </select>
                  </>
                )}
              </div>
              {props?.isFormValid && props?.addJobData?.expiresOn === "" && (
                <small className="validation">*Required field</small>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PositionAndDates;
