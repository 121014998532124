import React, { useState } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { FaLayerGroup } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import { getUserRole } from "../../../../service/AuthService";
import AddGroup from "./addgroup/Index";
import { useHistory } from "react-router-dom"; // Import useHistory hook for navigation
import moment from "moment";

const GroupsCard = (props) => {
  const history = useHistory(); // Initialize useHistory hook
  const role = getUserRole();
  const [editGroup, setEditGroup] = useState(false);
  const [editGroupDetails, setEditGroupDetails] = useState({});

  // Function to handle navigation to new page
  const navigateToPage = (groupId) => {
    history.push(`/high5hire/talentcommunity/groups/${groupId}`);
  };

  // Function to handle navigation to pool page
  const navigateToPagePool = (poolId) => {
    history.push(`/high5hire/talentcommunity/pools/${poolId}`);
  };

  // Function to open edit modal
  const openEditModal = (details) => {
    setEditGroupDetails(details);
    setEditGroup(true);
  };

  const handleView = (item) => {
    props?.onSkillClick(item);
  };

  return (
    <>
      <div class="row d-block-force d-none-1200">
        <div class="col">
          <div class="card d-flex flex-row align-items-stretch justify-content-between mb-1 p-relative">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 d-flex ">
                  <div className="my-auto mx-2">
                    <span>
                      <FaLayerGroup color="gray" size="20" />
                    </span>
                  </div>

                  <div className="ml-2 my-auto">
                    <h6
                      className="text-dark"
                      title="Community name"
                      style={{ cursor: "pointer" }}
                      onClick={() => navigateToPage(props.groups.id_group)} // Navigate to new page on heading click
                    >
                      {props?.groups?.groupname}{" "}
                      <i
                        title="Edit"
                        className="fad fa-pencil-alt ml-2"
                        aria-hidden="true"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent event bubbling to parent div
                          openEditModal(props.groups);
                        }}
                      ></i>
                    </h6>
                    <div class="mt-1 d-flex" style={{ flexWrap: "wrap" }}>
                      {!props?.groups?.viewAll &&
                        props?.groups?.poolnames

                          ?.slice(0, 3)
                          ?.map((item, index) => (
                            <div key={index} class="mb-1 d-block">
                              <div
                                class="tag tag-blue3 mr-2"
                                style={{ cursor: "pointer" }}
                                onClick={() => navigateToPagePool(item.poolId)} // Navigate to new page on heading click
                              >
                                <strong title="Sub Community">
                                  {item.poolname}
                                </strong>
                              </div>
                            </div>
                          ))}
                      {props?.groups?.poolnames.length > 3 &&
                        props?.groups?.viewAll &&
                        props?.groups?.poolnames.map((item, index) => (
                          <div key={index} class="mb-1 d-block">
                            <div
                              class="tag tag-blue3 mr-2"
                              style={{ cursor: "pointer" }}
                              onClick={() => navigateToPagePool(item.poolId)} // Navigate to new page on heading click
                            >
                              <strong title="Sub Community">
                                {item.poolname}
                              </strong>
                            </div>
                          </div>
                        ))}
                      {props?.groups?.poolnames.length > 3 && (
                        <button
                          onClick={() => handleView(props?.groups?.id_group)}
                          type="button"
                          class="btn btn-text"
                        >
                          {!props?.groups?.viewAll ? (
                            <span>View all</span>
                          ) : (
                            <span>Show Less</span>
                          )}
                        </button>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="font-regular d-flex flex-column align-items-end justify-content-center">
                    <div class="mb-1">
                      Number of sub communities :{" "}
                      {new Intl.NumberFormat().format(
                        props?.groups?.poolnames?.length ?? 0
                      )}
                    </div>
                    <div class="mb-1">
                      Number of candidates :{" "}
                      {new Intl.NumberFormat().format(
                        props?.groups?.candidateCount ?? 0
                      )}
                    </div>
                    {/* <div class="mb-1">
                      Number of vetted candidates :{" "}
                      {new Intl.NumberFormat().format(
                        props?.groups?.candidateCount ?? 0
                      )}
                    </div> */}
                    <div class="mb-1">
                      Created By : {props?.groups?.groupowner}
                    </div>
                    <div class="mb-1">
                      Created On :{" "}
                      {moment(props?.groups?.createddatetime).format(
                        "MM/DD/YYYY"
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={editGroup} size="lg">
        <Modal.Header className="bg-gray4">
          <buttons
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setEditGroup(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </buttons>
        </Modal.Header>
        <Modal.Body className="mt-3 p-2">
          <AddGroup
            edit={true}
            setShowAddGroup={setEditGroup}
            groupData={editGroupDetails}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GroupsCard;
