import React, { useEffect, useState } from "react";
import { post } from "../../../service/ApiService";
import { toast } from "react-toastify";
import { ButtonLoader } from "../../../constants/Constants";
import PhoneInput from "react-phone-input-2";
import { getCandidateId } from "../../../service/AuthService";
import { AsYouType } from "libphonenumber-js";
import HereMap from "../../location/HereMapAddtalent";

function ContactInfo({ basicInfo, getProfile }) {
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [contactInfo, setContactInfo] = useState({
    firstName: basicInfo.firstName,
    lastName: basicInfo.lastName,
    email: basicInfo.email,
    zipCode: basicInfo.zipCode,
    address: basicInfo.address,
    addressCity: basicInfo.addressCity,
    addressState: basicInfo.addressState,
    country: basicInfo.country,
    mobilePhone: basicInfo.mobilePhone,
    homePhone: basicInfo.homePhone,
  });
  const [tempContactInfo, setTempContactInfo] = useState({
    firstName: basicInfo.firstName,
    lastName: basicInfo.lastName,
    email: basicInfo.email,
    zipCode: basicInfo.zipCode,
    address: basicInfo.address,
    addressCity: basicInfo.addressCity,
    addressState: basicInfo.addressState,
    country: basicInfo.country,
    mobilePhone: basicInfo.mobilePhone,
    homePhone: basicInfo.homePhone,
  });
  const [phoneValidation, setPhoneValidation] = useState({});

  const handleChange = (e) => {
    setContactInfo({ ...contactInfo, [e.target.name]: e.target.value });
  };

  const editContactInfo = () => {
    setReadOnlyMode(false);
  };

  const cancelContactInfo = () => {
    setContactInfo(tempContactInfo);
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
  };

  const _onChangeText = (value, code) => {
    const asYouType = new AsYouType(code);
    const formattedNumber = asYouType.input(value);
    const isValid = asYouType.isValid();
    setPhoneValidation(isValid);
  };

  const saveContactInfo = async () => {
    if (
      !contactInfo.firstName ||
      !contactInfo.lastName ||
      !contactInfo.addressState ||
      !contactInfo.addressCity ||
      !contactInfo.country
    ) {
      toast.error("Fields marked with *(Asterisk) are Mandatory");
    } else if (!phoneValidation) {
      toast.error("Invalid Phone");
    } else {
      setSaveClicked(false);
      setSubmittingFrom(true);
      try {
        const parameters = {
          candidateID: getCandidateId(),
          firstName: contactInfo.firstName,
          middleName: "",
          lastName: contactInfo.lastName,
          zipCode: contactInfo.zipCode,
          address: contactInfo.address,
          addressCity: contactInfo.addressCity,
          addressState: contactInfo.addressState,
          stateName: "",
          country: contactInfo.country,
          mobilePhone: contactInfo.mobilePhone,
          homePhone: contactInfo.homePhone,
          workPhone: "",
        };
        const res = await post("/candidateProfile/postProfile", parameters);
        let localStorageData = JSON.parse(localStorage.getItem("userToken"));
        if (res.status === 200) {
          let finalData = {
            ...localStorageData,
            candidateName: contactInfo.firstName + " " + contactInfo.lastName,
          };
          setTempContactInfo(contactInfo);
          localStorage.setItem("userToken", JSON.stringify(finalData));
          getProfile();
          setSubmittingFrom(false);
          setShowSuccessMessage(true);
          setTimeout(() => {
            setReadOnlyMode(true);
            setShowSuccessMessage(false);
            setSaveClicked(true);
          }, 1000);
        }
      } catch (error) {
        console.log(error);
        toast.error("Error Occured");
      }
    }
  };

  return (
    <>
      <div>
        <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
          <div className="card card-lg">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className="mb-0">Contact Info</h6>
                {readOnlyMode ? (
                  <button
                    type="button"
                    className="btn btn-text"
                    onClick={editContactInfo}
                  >
                    <i className="fas fa-pencil-alt mr-1"></i>
                    Edit
                  </button>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={cancelContactInfo}
                      className="btn btn-sm btn-secondary mr-1"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={saveContactInfo}
                      className="btn btn-sm btn-primary"
                    >
                      {saveClicked && <span>Save Changes</span>}

                      {submittingForm && (
                        <span>
                          Saving Changes
                          <img width="20px" src={ButtonLoader} alt="" />
                        </span>
                      )}
                      {showSuccessMessage && <span>Changes Saved!</span>}
                    </button>
                  </div>
                )}
              </div>
              <hr />

              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="firstName"
                      value={contactInfo.firstName}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                    />
                    <label>First Name*</label>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="lastName"
                      value={contactInfo.lastName}
                      onChange={handleChange}
                      disabled={readOnlyMode}
                    />
                    <label>Last Name*</label>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-12">
                  <div className="form-floating">
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={contactInfo.email}
                      onChange={handleChange}
                      disabled
                    />
                    <label>Email*</label>
                  </div>
                </div>
              </div>

              {!readOnlyMode && (
                <HereMap
                  contactInfo={contactInfo}
                  setContactInfo={setContactInfo}
                  component={"PC"}
                />
              )}

              <div className="row">
                <div className="col-lg-8 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="address"
                      value={contactInfo.address}
                      disabled={readOnlyMode}
                      placeholder=""
                    />
                    <label>Address*</label>
                  </div>
                </div>
                <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="addressCity"
                      value={contactInfo.addressCity}
                      disabled={readOnlyMode}
                      placeholder=""
                    />
                    <label>City*</label>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="addressState"
                      value={contactInfo.addressState}
                      disabled={readOnlyMode}
                      placeholder=""
                    />
                    <label>State*</label>
                  </div>
                </div>
                <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="zipCode"
                      value={contactInfo.zipCode}
                      disabled={readOnlyMode}
                      placeholder=""
                    />
                    <label>Postal*</label>
                  </div>
                </div>
                <div className="col-lg-4 mb-2 mb-lg-0">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control"
                      name="country"
                      value={contactInfo.country}
                      disabled={readOnlyMode}
                      placeholder=""
                    />
                    <label>Country*</label>
                  </div>
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-lg-6">
                  <div className="mb-2">
                    <label>Primary Phone*</label>
                  </div>
                  <div className="d-flex">
                    <PhoneInput
                      classsName="form-control"
                      required="true"
                      placeholder="Primary Phone"
                      country={"us"}
                      value={contactInfo.mobilePhone}
                      name="mobilePhone"
                      onChange={(value, country) => {
                        setContactInfo({
                          ...contactInfo,
                          ["mobilePhone"]: value,
                        });
                        _onChangeText(
                          value.replace(country.dialCode, ""),
                          country.countryCode.toUpperCase()
                        );
                        // console.log(country)
                      }}
                      defaultErrorMessage="It doesn't works, why?"
                      disabled={readOnlyMode}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mb-2">
                    <label>Alternative Phone</label>
                  </div>
                  <div className="d-flex">
                    <PhoneInput
                      classsName="form-control"
                      name="homePhone"
                      country={"us"}
                      value={contactInfo.homePhone}
                      onChange={(value) =>
                        setContactInfo({ ...contactInfo, ["homePhone"]: value })
                      }
                      disabled={readOnlyMode}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactInfo;
