import { toast } from "react-toastify";
import { get, post } from "../../service/ApiService";

export const GET_RECRUITER_FOR_CURATOR_BEGIN =
  "GET_RECRUITER_FOR_CURATOR_BEGIN";
export const GET_RECRUITER_FOR_CURATOR_SUCCESS =
  "GET_RECRUITER_FOR_CURATOR_SUCCESS";
export const GET_RECRUITER_FOR_CURATOR_FAILURE =
  "GET_RECRUITER_FOR_CURATOR_FAILURE";

export const GET_RECRUITER_FOR_CURATOR_COUNT_BEGIN =
  "GET_RECRUITER_FOR_CURATOR_COUNT_BEGIN";
export const GET_RECRUITER_FOR_CURATOR_COUNT_SUCCESS =
  "GET_RECRUITER_FOR_CURATOR_COUNT_SUCCESS";
export const GET_RECRUITER_FOR_CURATOR_COUNT_FAILURE =
  "GET_RECRUITER_FOR_CURATOR_COUNT_FAILURE";

export const GET_CLIENT_TALENT_COMMUNITY_BEGIN =
  "GET_CLIENT_TALENT_COMMUNITY_BEGIN";
export const GET_CLIENT_TALENT_COMMUNITY_SUCCESS =
  "GET_CLIENT_TALENT_COMMUNITY_SUCCESS";
export const GET_CLIENT_TALENT_COMMUNITY_FAILURE =
  "GET_CLIENT_TALENT_COMMUNITY_FAILURE";

export const getRecruiterDataForCurator = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECRUITER_FOR_CURATOR_BEGIN,
    });
    const res = await post("/curator/getrecruiterdataforcurator", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_RECRUITER_FOR_CURATOR_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RECRUITER_FOR_CURATOR_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getRecruiterCountForCurator = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_RECRUITER_FOR_CURATOR_COUNT_BEGIN,
    });
    const res = await post("/curator/getrecruitercountforcurator", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_RECRUITER_FOR_CURATOR_COUNT_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_RECRUITER_FOR_CURATOR_COUNT_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};

export const getClientTalentCommunity = (payload) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CLIENT_TALENT_COMMUNITY_BEGIN,
    });
    const res = await post("/curator/getclienttalentcommunity", payload);
    if (res.status === 200) {
      dispatch({
        type: GET_CLIENT_TALENT_COMMUNITY_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CLIENT_TALENT_COMMUNITY_FAILURE,
      payload: error.response.data,
    });
    toast.error(error.response.data.errMessage);
  }
};
