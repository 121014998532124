import React, { useEffect, useState } from "react";
import TopNavBar from "../TopNavBar";
import JobList from "../JobList";
import { post } from "../../../../service/ApiService";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import Loader from "../../../Loader/Loader";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getCandidateDetails } from "../../../../actions/candidate/getCandidateDetails";
import { fetchCareerFilterJobList } from "../../../../actions/jobList/CareerFiterJobList";
import { getCareerCount } from "../../../../actions/jobList/CareerFilterJLCount";
import { fetchCareerFilterSavedJobList } from "../../../../actions/jobList/CareerFiterSavedJobList";
import { getSavedCareerCount } from "../../../../actions/jobList/CareerFilterSavedJLCount";

const AllJobs = ({}) => {
  const [jobs, setJobs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [category, setCategory] = useState("");
  const [filterParams, setFilterParams] = useState({}); //only use for page change
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");
  const location = useLocation();
  const [savedJobs, setSavedJobs] = useState([]);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [selectedData, setSelectedData] = useState({
    industries: [],
    jobLocation: [],
    jobType: [],
  });
  let path = window.location.pathname.split("/").pop();

  const {
    candidateDetailsLoading,
    candidateData,
    jobFilterList,
    jobFilterListLoading,
    careerCount,
    careerCountLoading,
    savedJobFilterList,
    savedJobFilterListLoading,
    savedCareerCount,
    savedCareerCountLoading,
  } = useSelector(
    (state) => ({
      candidateDetailsLoading:
        state.candidateDetailsReducer.candidateDetailsLoading,
      candidateData: state.candidateDetailsReducer.candidateData,
      jobFilterList: state.careerFilterJobList.jobFilterList,
      jobFilterListLoading: state.careerFilterJobList.loading,
      careerCount: state.careerCount.careerCount,
      careerCountLoading: state.careerCount.careerCountLoading,
      savedJobFilterList: state.careerFilterSavedJobList.savedJobFilterList,
      savedJobFilterListLoading: state.careerFilterSavedJobList.loading,
      savedCareerCount: state.SavedCareerCount.careerCount,
      savedCareerCountLoading: state.SavedCareerCount.careerCountLoading,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  useEffect(() => {
    getCandidateData();
  }, []);

  const getCandidateData = () => {
    const payload = {
      candidateId: sessionStorage.getItem("userId"),
    };
    dispatch(getCandidateDetails(payload));
  };

  const industryCategories = [
    { jobName: "All jobs", path: "all-jobs" },
    { jobName: "Information Technology", path: "Information-Technology" },
    { jobName: "Healthcare", path: "Healthcare" },
    { jobName: "Accounting and Finance", path: "Accounting-and-Finance" },
    {
      jobName: "Administrative and Office Support",
      path: "Administrative-and-Office-Support",
    },
    { jobName: "Clinical and Scientific", path: "Clinical-and-Scientific" },
    {
      jobName: "Customer Service and Call Center",
      path: "Customer-Service-and-Call-Center",
    },
    { jobName: "Data Entry and Clerical", path: "Data-Entry-and-Clerical" },
    { jobName: "Engineering", path: "Engineering" },
    { jobName: "Human Resources (HR)", path: "Human-Resources-HR" },
    { jobName: "Legal", path: "Legal" },
    {
      jobName: "Manufacturing and Production",
      path: "Manufacturing-and-Production",
    },
    { jobName: "Marketing and Creative", path: "Marketing-and-Creative" },
    { jobName: "Sales", path: "Sales" },
  ];

  useEffect(() => {
    const delay = 500; // Adjust this value to set the debounce time in milliseconds
    const debounceTimer = setTimeout(() => {
      // if (value?.length > 1)\
      fetchSuggestions(value);
    }, delay);

    // Cleanup the previous timer on each render to avoid multiple requests
    return () => clearTimeout(debounceTimer);
  }, [value]);

  const fetchSuggestions = async (value) => {
    try {
      applySearch();
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    fetchData(1, true);
  }, []);

  useEffect(() => {
    fetchJobs();
  }, [location.state?.category, candidateData]);

  // useEffect(() => {
  //   if (sessionStorage.getItem("userId")) {
  //     let data = candidateData?.favouriteJobs
  //       ? JSON.parse(candidateData?.favouriteJobs)
  //       : [];
  //     console.log("data", data, candidateData?.favouriteJobs);
  //     setSavedJobs(data);
  //   }
  // }, [candidateData]);

  const fetchJobs = () => {
    if (candidateData) {
      let savedJobIds = [];
      if (sessionStorage.getItem("userId") == null) {
        const savedJobsJSON = localStorage.getItem("savedJobIds");
        savedJobIds = savedJobsJSON ? JSON.parse(savedJobsJSON) : [];
        setSavedJobs(savedJobIds);
      }
      if (sessionStorage.getItem("userId")) {
        savedJobIds = candidateData?.favouriteJobs
          ? JSON.parse(candidateData?.favouriteJobs)
          : [];
        setSavedJobs(savedJobIds);
      }
      // Function to get jobs
      const getJob = async () => {
        const candidate = sessionStorage.getItem("userId") || "";
        try {
          let industriesArray = industryCategories?.filter(
            (x) => x.path === location.state?.category
          );
          const payload = {
            candidateId: candidate,
            category:
              industriesArray.length > 0 &&
              location.state?.category !== "all-jobs"
                ? industriesArray[0].jobName
                : "",
            currentPage: 1,
            limit: 5,
            filterObjects: [],
            searchText: value,
          };
          if (candidate && path === "savedjobs") {
            dispatch(fetchCareerFilterSavedJobList(payload));
            dispatch(getSavedCareerCount(payload));
          } else {
            dispatch(fetchCareerFilterJobList(payload));
            dispatch(getCareerCount(payload));
          }
          // dispatch(fetchCareerFilterJobList(payload));
          // dispatch(getCareerCount(payload));
          setJobs(
            path === "savedjobs"
              ? jobFilterList?.filter((j) => savedJobIds.includes(j.jobId))
              : jobFilterList
          );
        } catch (error) {
          setLoading(false);
          console.log(error);
        }
      };

      // Update category when URL changes
      const updateCategoryFromURL = () => {
        const currentCategory = window.location.pathname.split("/").pop();
        setCategory(currentCategory);
      };

      // Initial call to get jobs
      getJob();

      // Listen for URL changes
      window.addEventListener("popstate", updateCategoryFromURL);

      // Clean up the event listener on component unmount
      return () => {
        window.removeEventListener("popstate", updateCategoryFromURL);
      };
    }
  };

  const fetchData = (currentPage, type) => {
    const candidate = sessionStorage.getItem("userId") || "";
    let industriesArray = industryCategories?.filter(
      (x) => x.path === location.state?.category
    );
    const payload = {
      candidateId: candidate,
      category:
        industriesArray.length > 0 && location.state?.category !== "all-jobs"
          ? industriesArray[0].jobName
          : "",
      currentPage: currentPage,
      limit: 5,
      filterObjects: selectedData,
      searchText: value,
    };
    dispatch(fetchCareerFilterJobList(payload));
  };

  const addJobToLocalStorage = (jobId) => {
    setSavedJobs((prevSavedJobs) => {
      const isJobSaved = prevSavedJobs.includes(jobId);
      if (!isJobSaved) {
        const updatedJobs = [...prevSavedJobs, jobId];
        localStorage.setItem("savedJobIds", JSON.stringify(updatedJobs));
        return updatedJobs;
      } else {
        const updatedJobs = prevSavedJobs.filter(
          (savedJobId) => savedJobId !== jobId
        );
        localStorage.setItem("savedJobIds", JSON.stringify(updatedJobs));
        return updatedJobs;
      }
    });
    setJobs(jobFilterList?.filter((j) => savedJobs.includes(j.jobId)));
  };

  const addFavouriteJob = async (jobId) => {
    let jobArray = [];
    if (candidateData.favouriteJobs == null) {
      let idString = jobId.toString();
      jobArray = [jobId];
    } else {
      let favJobArray = JSON.parse(candidateData?.favouriteJobs);
      if (favJobArray?.includes(jobId)) {
        jobArray = favJobArray.filter((f) => f != jobId);
      } else {
        jobArray = [...favJobArray, jobId];
      }
    }
    const params = {
      candidateId: sessionStorage.getItem("userId"),
      favouriteJobs: JSON.stringify(jobArray),
    };
    setLoading(true);
    await post("/favourites/favouritejobs", params)
      .then((res) => {
        getCandidateData();
        setLoading(false);
        !candidateData?.favouriteJobs?.includes(jobId)
          ? toast.success("Job added to favorites!")
          : toast.success("Job removed from favorites!");
      })
      .catch((err) => {
        setLoading(false);
        console.log("err", err);
      });
  };

  const handleSaveJob = (jobId) => {
    if (sessionStorage.getItem("userId") == null) addJobToLocalStorage(jobId);
    else addFavouriteJob(jobId);
  };

  const applyFilter = (val, status) => {
    setCurrentPage(1);
    const candidate = sessionStorage.getItem("userId") || "";
    let industriesArray = industryCategories?.filter(
      (x) => x.path === location.state?.category
    );
    const payload = {
      candidateId: candidate,
      category:
        industriesArray.length > 0 && location.state?.category !== "all-jobs"
          ? industriesArray[0].jobName
          : "",
      currentPage: 1,
      limit: 5,
      filterObjects: getFilterParamsNew(val, status),
      searchText: value,
    };
    if (candidate && path === "savedjobs") {
      dispatch(fetchCareerFilterSavedJobList(payload));
      dispatch(getSavedCareerCount(payload));
    } else {
      dispatch(fetchCareerFilterJobList(payload));
      dispatch(getCareerCount(payload));
    }
  };
  const applySearch = (val, status) => {
    setCurrentPage(1);
    const candidate = sessionStorage.getItem("userId") || "";
    let industriesArray = industryCategories?.filter(
      (x) => x.path === location.state?.category
    );
    const payload = {
      candidateId: candidate,
      category:
        industriesArray.length > 0 && location.state?.category !== "all-jobs"
          ? industriesArray[0].jobName
          : "",
      currentPage: 1,
      limit: 5,
      filterObjects: Object.keys(filterParams).length > 0 ? filterParams : "",
      searchText: value,
    };
    if (candidate && path === "savedjobs") {
      dispatch(fetchCareerFilterSavedJobList(payload));
      dispatch(getSavedCareerCount(payload));
    } else {
      dispatch(fetchCareerFilterJobList(payload));
      dispatch(getCareerCount(payload));
    }
  };

  const getFilterParamsNew = (val, status) => {
    const filterParam = {
      industries:
        status == ""
          ? []
          : status == "industries"
          ? val
          : selectedData.industries,
      jobType:
        status == "" ? [] : status == "jobType" ? val : selectedData.jobType,
    };

    setFilterParams(filterParam);
    return filterParam;
  };

  return (
    <>
      {(loading ||
        candidateDetailsLoading ||
        (careerCountLoading && savedCareerCountLoading) ||
        (jobFilterListLoading && savedJobFilterListLoading)) && <Loader />}
      <TopNavBar
        savedJobs={savedJobs}
        setSavedJobs={setSavedJobs}
        isLoginModal={isLoginModal}
        setIsLoginModal={setIsLoginModal}
        isFrom="AllJobs"
      />
      <JobList
        savedJobs={savedJobs}
        path={path}
        jobs={
          path === "savedjobs"
            ? sessionStorage.getItem("userId") == null
              ? jobs
              : savedJobFilterList
            : jobFilterList
        }
        handleSaveJob={handleSaveJob}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        applyFilter={applyFilter}
        careerCount={
          path === "savedjobs"
            ? sessionStorage.getItem("userId") == null
              ? [{count:jobs?.length}]
              : savedCareerCount
            : careerCount
        }
        fetchData={fetchData}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        value={value}
        setValue={setValue}
      />
    </>
  );
};

export default AllJobs;
