import React, { useState, useRef } from "react";
import { Button, Modal, Container, Row, Col } from "react-bootstrap";
import AvatarEditor from "react-avatar-editor";

const CropperModal = ({
  imageData,
  setImageData,
  showCropper,
  setShowCropper,
  handleSave,
}) => {
  const [scale, setScale] = useState(1);
  const editorRef = useRef();

  const handleSaveClick = (e) => {
    e.preventDefault();

    if (editorRef.current) {
      const canvasScaled = editorRef.current.getImageScaledToCanvas();
      handleSave(canvasScaled.toDataURL());
      setShowCropper(false);
    }
  };

  return (
    <Modal show={showCropper} onHide={() => setShowCropper(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row className="justify-content-md-center">
            <Col
              md="12"
              className="d-flex justify-content-md-center align-items-md-center"
            >
              <div
                style={{
                  width: "250px",
                  height: "250px",
                  borderRadius: "50%",
                  overflow: "hidden",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                }}
              >
                <AvatarEditor
                  ref={editorRef}
                  image={imageData.base64}
                  width={250}
                  height={250}
                  border={0}
                  color={[255, 255, 255, 0.6]}
                  scale={scale}
                  onWheel={(e) => {
                    e.preventDefault();
                    if (e.deltaY > 0) {
                      setScale((prevScale) => Math.max(0.1, prevScale - 0.1));
                    } else if (e.deltaY < 0) {
                      setScale((prevScale) => Math.min(10, prevScale + 0.1));
                    }
                  }}
                />
              </div>
            </Col>
            <Col
              md="12"
              className="d-flex justify-content-md-center align-items-md-center"
            >
              <div className="mt-3 mb-3">
                <input
                  type="range"
                  className="form-range"
                  min="0.1"
                  max="10"
                  step="0.1"
                  value={scale}
                  onChange={(e) => setScale(parseFloat(e.target.value))}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowCropper(false)}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSaveClick}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CropperModal;
