import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import Qualified from "./Qualified";
import NoRecord from "../../../../commoncomponents/norecord/NoRecord";
import Pagination from "../../../../commoncomponents/pagination/Pagination";
import { useParams } from "react-router";
import PaginationComponent from "../../../../pagination/pagination";
import { getPoolDispositionDetails } from "../../../../../../actions/directsourcing/Disposition";
import Loader from "../../../../../Loader/Loader";

const Index = (props) => {
  const { jobData, view, currentPage, setCurrentPage } = props;
  const [qualifiedData, setQualifiedData] = useState([]);
  const dispatch = useDispatch();
  const { poolId } = useParams();

  const {
    dispositionDetails,
    dispositionLoading,
    dispositionDisqualifyLoading,
    dispositionDisqualify,
    dispositionCount,
    dispositionCountLoading,
  } = useSelector(
    (state) => ({
      dispositionDetails: state.poolDisposition.dispositionDetails,
      dispositionLoading: state.poolDisposition.dispositionDetailsLoading,
      dispositionDisqualifyLoading:
        state.poolDispositionDisqualify.dispositionDisqualifyLoading,
      dispositionDisqualify:
        state.poolDispositionDisqualify.dispositionDisqualify,
      dispositionCount: state.poolDispositionCount.dispositionCount,
      dispositionCountLoading:
        state.poolDispositionCount.dispositionCountLoading,
    }),
    shallowEqual
  );

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  const fetchData = (currentPage, type) => {
    let payload = {
      currentPage: currentPage,
      limit: 15,
      status: view,
      poolId: poolId,
      filterObject: props.filterParams,
    };
    dispatch(getPoolDispositionDetails(payload));
  };

  useEffect(() => {
    setQualifiedData(dispositionDetails);
  }, [dispositionDetails]);

  return (
    <div className="table-wrap">
      {(dispositionLoading ||
        dispositionCountLoading ||
        dispositionDisqualifyLoading) && <Loader />}
      {qualifiedData.length > 0 ? (
        <>
          <table className="table table-sortable table-cards-1024">
            <thead>
              <tr className="d-none d-lg-table-row">
                <th className="d-flex align-items-start">Candidate</th>

                <th>Invited on</th>
                <th className="ml-2">Status</th>
                <th>Link Open</th>
              </tr>
            </thead>
            <tbody>
              <Qualified jobData={jobData} qualifiedData={qualifiedData} />
            </tbody>
          </table>
          <PaginationComponent
            currentPage={currentPage}
            totalItems={
              dispositionCount?.filter((e) => e.Status == "Qualified")[0]?.Count
            }
            itemsPerPage={15}
            onPageChange={handlePageChange}
            limit={15}
          />
        </>
      ) : (
        <NoRecord />
      )}
    </div>
  );
};

export default Index;
