import React, { useState } from "react";
import { toast } from 'react-toastify';
import { ButtonLoader } from "../../../constants/Constants";
import { post } from '../../../service/ApiService';
import { getCandidateId } from "../../../service/AuthService";
import { monthNames } from "../../../constants/Constants";

function Experience(props) {

  const [ readOnlyMode, setReadOnlyMode ] = useState(true)
  const [ submittingForm, setSubmittingFrom ] = useState(false)
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false)
  const [ saveClicked, setSaveClicked ] = useState(true)
  const [ experience, setExperience ] = useState(props.experience)
  const [ tempExperience, setTempExperience ] = useState(props.experience)

  const addExperience = () => {
    let ex = [...experience]
    let addCondition = true
    // let { employerName, jobTitle, startDate, endDate, isSelect } = ex.slice(-1)[0]
    // if(isSelect) {
    //   if(!employerName || !jobTitle || !startDate) {
    //     addCondition = false
    //   }
    // }
    // if(!isSelect) {
    //   if(!employerName || !jobTitle || !startDate || !endDate) {
    //     addCondition = false
    //   }
    // }
    // if(!addCondition) {
    //   toast.error('Please Fill the Previous Experience')
    // }
    if(true) {
      ex.push({
        employerName: "",
        industry: "",
        jobTitle: "",
        startDate: "",
        endDate: "",
        description: "",
        isSelect: false
      })
      setExperience(ex)
    }
  }

  const postExperienceInfo = async () => {
    setTempExperience(experience)
    let emptyFieldsCondition = true
    let dateCondition = true

    experience.forEach(e => {
      if(e.isSelect) {
        if(!e.employerName || !e.startDate || !e.jobTitle ) {
          emptyFieldsCondition = false
          return false
        }
      }
      else {
        if(!e.employerName || !e.startDate || !e.jobTitle || !e.endDate ) {
          emptyFieldsCondition = false
          return false
        }
      }
    })
    
    experience.forEach(e => { 
      let from = new Date(e.startDate) 
      let to = new Date(e.endDate)
      if(!e.isSelect && from > to) {
        dateCondition = false
        return false
      }
    })
    
    if(!emptyFieldsCondition) {
      toast.error('Fields Marked with *(Asterisk) are mandatory')
    }
    else if (!dateCondition) {
      toast.error(`'Duration From' should not be greater than 'Duration To'`)
    }
    else {
      setSaveClicked(false)
      setSubmittingFrom(true)
      try {
        const parameters = {
          candidateID: getCandidateId(),
          clickName: 'experience',
          userExperiencesInfo: experience
        }
        // console.log('Parameters', parameters)
        const res = await post('/candidateProfile/postExperienceInfo', parameters)
        // console.log(res)
        if (res.status === 200) {
          setSubmittingFrom(false)
          setShowSuccessMessage(true)      
          setTimeout(() => { 
            setReadOnlyMode(true)
            setShowSuccessMessage(false)
          }, 1000)
        }
      } catch (error) {
        toast.error("Error Occured")
      }
    }
    
  }

  const deleteExperience = (index) => {
    let ex = [...experience]
    ex.splice(index, 1)
    setExperience(ex)
  }

  const editExperience = () => {
    setReadOnlyMode(false)
    setSaveClicked(true)
  }

  const cancelExperience = () => {
    setExperience(tempExperience)
    setReadOnlyMode(true)
    setShowSuccessMessage(false)
    setSubmittingFrom(false)
    setSaveClicked(true)
  }

  const handleChange = (name, value, index) => {
    let ex = [...experience]
    if(name === 'isSelect' && value) {
      ex = experience.map((e, i) => {
        return index === i ? {...e, isSelect: true} : {...e, isSelect: false}
      })
    }
    else {
      ex[index] = { ...ex[index], [name]: value }
    }   
    setExperience(ex)
  }

  return (
    <>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Work Experience</h6>
            { readOnlyMode === true ? (
              <button
                type="button"              
                class="btn btn-text"
                onClick={editExperience}
              >
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={cancelExperience}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={postExperienceInfo}
                  class="btn btn-sm btn-primary"
                >
                  {saveClicked && <span>Save Changes</span>}

                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img
                        width="20px"
                        src={ButtonLoader}
                        alt=""
                      />
                    </span>
                  )}
                  {showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          <hr />
          {readOnlyMode ? (
            <div>
              {
                (readOnlyMode && experience.length === 0) ? 
                <div>
                  <div className="text-center p-3">
                      <div className="avatar avatar-lg">
                          <i className="fad fa-comment-slash"></i>
                      </div>
                      <div className="mt-2">
                          No Record Found
                      </div>
                  </div>
              </div> :
                experience.map(e =>
                  <div class="d-flex">
                    <div class="mr-3 d-flex flex-column align-items-center">
                      <i class="fad fa-fw fa-briefcase mt-1" aria-hidden="true"></i>
                      <div class="view-panel-spacer mt-1"></div>
                    </div>
                    <div class="pb-3">
                      <p class="mb-1">
                        <strong>
                          {e.employerName}
                          <span class="font-regular ml-1">{ e.industry ? `(${e.industry})` : null}</span>
                        </strong>
                      </p>
                      <p class="mb-0">
                        <small>
                          <strong>{e.jobTitle}</strong>
                        </small>
                      </p>
                      <p>
                        <small>
                          From: {monthNames[new Date(e.startDate).getMonth()] + " " + new Date(e.startDate).getFullYear()} 
                          { !e.isSelect ? `   To: ${monthNames[new Date(e.endDate).getMonth()]} ${new Date(e.endDate).getFullYear()}` : null}
                        </small>
                      </p>
                      <p>
                        {e.description}
                      </p>
                    </div>
                  </div>
                )
              }
            </div>
          ) : (
            <div class="work-experience">
              {
                experience.length === 0 ? <h6 className='m-5'>Experience Not Yet Added.<br /> To add, click 'Add Experience' below</h6> :
                experience.map((e, j) =>
                <div class="d-flex">
                  <div class="mr-3">
                    <div class="avatar avatar-sm">{j+1}</div>
                  </div>
                  <div>
                    <div class="row mb-2">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            name="employerName"
                            placeholder="Employer Name"
                            value={e.employerName}
                            onChange={(event) => handleChange(event.target.name, event.target.value, j)}
                          />
                          <label>Employer Name*</label>
                        </div>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <div className='mr-2 mt-3'>
                          <p>Currently Employer? </p>
                        </div>
                        <div className='form-check form-switch'>
                          <input 
                            type='checkbox' 
                            className='form-check-input'
                            name="isSelect"
                            checked={e.isSelect}
                            onChange={(event) => handleChange(event.target.name, event.target.checked, j)}
                           />
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-lg-12 mb-2">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            name="jobTitle"
                            value={e.jobTitle}
                            onChange={(event) => handleChange(event.target.name, event.target.value, j)}
                            placeholder="Designation"
                          />
                          <label>Designation*</label>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating form-date">
                          <label>Duration From*</label>
                          <input
                            type="date"
                            class="form-control date"
                            placeholder="Start Date"
                            name="startDate"
                            value={e.startDate}
                            onChange={(event) => handleChange(event.target.name, event.target.value, j)}
                          />
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating form-date">
                          <label>Duration To { e.isSelect ? null : '*'}</label>
                          <input
                            type="date"
                            class="form-control date"
                            placeholder="End Date"
                            name="endDate"
                            value={e.endDate}
                            disabled={e.isSelect}
                            onChange={(event) => handleChange(event.target.name, event.target.value, j)}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-floating">
                          <textarea
                            class="form-control"
                            placeholder="Experience Description"
                            value={e.description}
                            name="description"
                            onChange={(event) => handleChange(event.target.name, event.target.value, j)}
                          ></textarea>
                          <label>Experience Description</label>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="ml-3">
                    <button type="button" class="btn btn-icon" onClick={() => deleteExperience(j)}>
                      <i class="fal fa-fw fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
                )}
              <div className="ml-5">
                <button
                  type="button"
                  class="btn btn-sm btn-text"
                  onClick={addExperience}
                >
                  <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                  <strong>Add Experience</strong>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default Experience
