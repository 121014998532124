// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector, shallowEqual } from "react-redux";
// import SideNavBar from "../../../layout/SideNavBar";
// import TopNavBar from "../../../layout/TopNavBar";
// import SMSTemplateFullView from "./SMSTemplateFullView";
// import Loader from "../../../Loader/Loader";
// import { getSMSTemplates } from "../../../../actions/adminconsole/SMSTemplate";
// import Pagination from "../../commoncomponents/pagination/Pagination";
// import NoRecord from "../../commoncomponents/norecord/NoRecord";
// import { post } from "../../../../service/ApiService";

// function SMSTemplate() {
//   const dispatch = useDispatch();
//   const [SMSFullView, setSMSFullView] = useState(false);
//   const [selectedSMSData, setSelectedSMSData] = useState({});
//   const [toggle, setToggle] = useState(true);
//   const [currentPage, setCurrentPage] = useState(1);
//   const [paginationArray, setPaginationArray] = useState([]);
//   const [SMSEvents, setSMSEvents] = useState([]);
//   const [searchValue, setSearchValue] = useState("");

//   const { SMSLoading, SMSData, SMSUpdated } = useSelector(
//     (state) => ({
//       SMSLoading: state.smsTemplateReducer.SMSLoading,
//       SMSData: state.smsTemplateReducer.SMSData,
//       SMSUpdated: state.smsTemplateReducer.SMSUpdated,
//     }),
//     shallowEqual
//   );

//   useEffect(() => {
//     dispatch(getSMSTemplates());
//   }, []);
//   useEffect(() => {
//     dispatch(getSMSTemplates());
//   }, [SMSUpdated]);
//   useEffect(() => {
//     if (SMSData.length > 0) {
//       setSMSEvents(SMSData.sort((a, b) => a.id_SMSTemplate - b.id_SMSTemplate));
//     }
//   }, [SMSData]);

//   const toggleDiv = (type) => {
//     if (type === true) {
//       setToggle(true);
//     } else {
//       setToggle(false);
//     }
//   };
//   const handleClearInput = () => {
//     setSearchValue("");
//     setSMSEvents(SMSData);
//   };
//   const handleSearch = (e) => {
//     setSearchValue(e.target.value);
//     setCurrentPage(1);
//     if (e.target.value) {
//       const filterData = SMSData.filter((item) =>
//         item.events.toLowerCase().includes(e.target.value.toLowerCase())
//       );
//       setSMSEvents(filterData);
//     } else {
//       setSMSEvents(SMSData);
//     }
//   };

//   return (
//     <>
//       {SMSLoading && <Loader />}
//       <body
//         className={
//           toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
//         }
//       >
//         <div className="body-decorator body-decorator-top"></div>
//         <div className="body-decorator body-decorator-bottom"></div>
//         <TopNavBar parent="adminConsole" handleToggale={toggleDiv} />
//         <SideNavBar />
//         <div id="Content">
//           <div className="section section-sm">
//             <div className="container">
//               <div className="row justify-content-center">
//                 <div className="col-lg-12 px-5">
//                   <h5 className="mb-4">SMS Template</h5>
//                   <div className="d-flex justify-content-between">
//                     <h6 className="mt-2">
//                       {SMSData && SMSData?.length} Templates
//                     </h6>
//                     <div>
//                       <form className="d-flex">
//                         <div className="search-input-container">
//                           <input
//                             className="form-control me-2"
//                             type="text"
//                             placeholder="Search Events"
//                             aria-label="Search"
//                             value={searchValue}
//                             onChange={handleSearch}
//                           />
//                           {searchValue && (
//                             <button
//                               className="clear-button"
//                               onClick={handleClearInput}
//                             >
//                               <i className="fas fa-times"></i>
//                             </button>
//                           )}
//                         </div>
//                       </form>
//                     </div>
//                   </div>
//                   <hr />
//                   {SMSEvents.length > 0 ? (
//                     <div className="col">
//                       <table className="table table-sortable table-hoverable">
//                         <thead>
//                           <tr>
//                             <th className="no-hover pt-0">
//                               <div className="d-flex align-items-center">
//                                 Event Name
//                               </div>
//                             </th>
//                             {/* <th className="no-hover pt-0">
//                               <div className="d-flex align-items-center">
//                                 Sender
//                               </div>
//                             </th> */}
//                             <th className="no-hover pt-0">
//                               <div className="d-flex align-items-center">
//                                 Recipient
//                               </div>
//                             </th>
//                             {/* <th className="no-hover pt-0">
//                               <div className="d-flex align-items-center">
//                                 Sender
//                               </div>
//                             </th> */}
//                             <th>
//                               <div className="d-flex align-items-center">
//                                 Frequency
//                               </div>
//                             </th>
//                             <th>
//                               <div className="d-flex align-items-center">
//                                 Time
//                               </div>
//                             </th>
//                           </tr>
//                         </thead>
//                         {paginationArray.map((x, i) => (
//                           <tbody key={i} className="table-bordered">
//                             <tr
//                               onClick={() => {
//                                 setSMSFullView(true);
//                                 setSelectedSMSData(x);
//                               }}
//                             >
//                               <td className="p-relative">{x.events}</td>
//                               {/* <td>{x.sender}</td> */}

//                               <td>
//                                 {JSON.parse(x.recipients).map((item) => (
//                                   <span className="tag tag-blue3 mb-1 mr-1 pt-1 px-2">
//                                     {item}
//                                   </span>
//                                 ))}
//                               </td>
//                               {/* <td>{x.sender}</td> */}
//                               <td>{x.smsFrequency}</td>
//                               <td>{x.time}</td>
//                               {/* <Action /> */}
//                             </tr>
//                           </tbody>
//                         ))}
//                       </table>
//                       <Pagination
//                         currentPage={currentPage}
//                         setCurrentPage={setCurrentPage}
//                         setPaginationArray={setPaginationArray}
//                         data={SMSEvents}
//                         dataSize={5}
//                         getTab=""
//                         pageSize={5}
//                       />
//                     </div>
//                   ) : (
//                     <NoRecord />
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {SMSFullView && (
//           <SMSTemplateFullView
//             SMSData={selectedSMSData}
//             setSMSFullView={setSMSFullView}
//           />
//         )}
//       </body>
//     </>
//   );
// }

// export default SMSTemplate;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import SideNavBar from "../../../layout/SideNavBar";
import TopNavBar from "../../../layout/TopNavBar";
import SMSTemplateFullView from "./SMSTemplateFullView";
import Loader from "../../../Loader/Loader";
import { getSMSTemplates } from "../../../../actions/adminconsole/SMSTemplate";
import Pagination from "../../../components_v3/pagination/pagination";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import { post } from "../../../../service/ApiService";
import moment from "moment";
import Filter from "./TemplatesFilter/index";
import OutsideClickHandler from "react-outside-click-handler";
import { getTenantType } from "../../../../service/AuthService";
import Footer from "../../../layout/Footer";

function SMSTemplate() {
  const dispatch = useDispatch();
  const [smsFullView, setSmsFullView] = useState(false);
  const [createNew, setCreateNew] = useState(false);
  const [selectedSMSData, setSelectedSMSData] = useState({});
  const [toggle, setToggle] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [tempData, setTempData] = useState([]);
  const [smsEvents, setSmsEvents] = useState({});
  const [loading, setLoading] = useState(false);

  const [recipientPersona, setRecipientPersona] = useState(null);
  const [eventTemplates, setEventTemplates] = useState(null);
  const [frequency, setFrequency] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [searchValue, setSearchValue] = useState("");
  const [limit, setLimit] = useState(20);
  const [filter, setFilter] = useState(false);
  const [filterCount, setFilterCount] = useState(0);
  const [filterData, setFilterData] = useState({});
  const [filterKeys, setFilterKeys] = useState({
    recipients: [],
    events: [],
    frequency: [],
  });
  const [filterInfo, setFilterInfo] = useState({
    memberFromType: "",
    memberFrom: ["", ""],
    recipients: [],
    events: [],

    frequency: [],
  });

  const [sortCount, setSortCount] = useState(0);
  const [sort, setSort] = useState(false);
  const [sortArray, setSortArray] = useState([]);

  const { SMSLoading, SMSData, SMSUpdated } = useSelector(
    (state) => ({
      SMSLoading: state.smsTemplateReducer.SMSLoading,
      SMSData: state.smsTemplateReducer.SMSData,
      SMSUpdated: state.smsTemplateReducer.SMSUpdated,
    }),
    shallowEqual
  );

  const closeModal = () => {
    setFilter(false);
  };

  useEffect(() => {
    dispatch(getSMSTemplates());
  }, []);
  useEffect(() => {
    dispatch(getSMSTemplates());
  }, [SMSUpdated]);
  useEffect(() => {
    setTempData(SMSData);
    const removeDuplicate = (arr) => {
      const uniqueArray = [...new Set(arr)];

      const sortedArray = uniqueArray.sort((a, b) => a - b);
      return sortedArray;
    };
    const groupFilters = () => {
      if (SMSData && SMSData.length > 0) {
        let recipients = [];
        let events = [];

        let frequency = [];
        SMSData?.map((item) => {
          recipients.push(item?.recipients[0]);
          events.push(item?.events);

          frequency.push(item?.smsFrequency);
        });
        recipients = removeDuplicate(recipients);
        events = removeDuplicate(events);

        frequency = removeDuplicate(frequency);
        setFilterKeys({
          ...filterKeys,
          recipients: [...recipients],
          events: [...events],

          frequency: [...frequency],
        });
      }
    };
    const groupSMSTemplates = () => {
      // const sortedData =
      //   SMSData &&
      //   SMSData?.slice().sort((a, b) =>
      //     a.recipients[0].localeCompare(b.recipients[0])
      //   );

      const customSort = (a, b) => {
        // First, sort by recipients
        const recipientsComparison = a.recipients[0].localeCompare(
          b.recipients[0]
        );

        // If recipients are the same, sort by events
        return recipientsComparison === 0
          ? a.events.localeCompare(b.events)
          : recipientsComparison;
      };

      const sortedData = SMSData.sort(customSort);

      const startIndex = (currentPage - 1) * limit;
      const slicedData =
        sortedData && sortedData.slice(startIndex, startIndex + limit);
      const groupedData = {};

      slicedData.forEach((template) => {
        const recipients = template.recipients || [];

        if (recipients.length > 0) {
          const recipientKey = recipients[0];
          if (!groupedData[recipientKey]) {
            groupedData[recipientKey] = [];
          }

          groupedData[recipientKey].push(template);
        }
      });
      for (const key in groupedData) {
        if (groupedData.hasOwnProperty(key)) {
          groupedData[key].sort((a, b) => a.events.localeCompare(b.events));
        }
      }

      setSmsEvents(groupedData);
    };
    groupSMSTemplates();
    groupFilters();

    if (
      filterInfo?.memberFrom[0] !== "" ||
      filterInfo?.memberFrom[1] !== "" ||
      filterInfo?.memberFromType !== "" ||
      filterInfo?.recipients?.length > 0 ||
      filterInfo?.events?.length > 0 ||
      filterInfo?.frequency?.length > 0
    ) {
      sendFilter(filterInfo);
    }

    let latestUpdate;

    if (
      SMSData &&
      SMSData.length > 0 &&
      selectedSMSData &&
      Object.keys(selectedSMSData).length > 0
    ) {
      latestUpdate = SMSData.find(
        (item) => item.id_smsTemplate === selectedSMSData.id_smsTemplate
      );
    }

    if (latestUpdate) {
      setSelectedSMSData(latestUpdate);
    }
  }, [SMSData]);
  useEffect(() => {
    setLoading(true);
    const sortedData = tempData.sort((a, b) =>
      a.recipients[0].localeCompare(b.recipients[0])
    );

    const startIndex = (currentPage - 1) * limit;
    const slicedData =
      sortedData && sortedData.slice(startIndex, startIndex + limit);

    const groupedData = {};

    slicedData &&
      slicedData.forEach((template) => {
        const recipients = template.recipients || [];

        if (recipients.length > 0) {
          const recipientKey = recipients[0];
          if (!groupedData[recipientKey]) {
            groupedData[recipientKey] = [];
          }

          groupedData[recipientKey].push(template);
        }
      });
    for (const key in groupedData) {
      if (groupedData.hasOwnProperty(key)) {
        groupedData[key].sort((a, b) => a.events.localeCompare(b.events));
      }
    }
    setSmsEvents(groupedData);
    setLoading(false);
  }, [tempData, currentPage, limit]);

  useEffect(() => {
    let count = 0;
    count =
      (filterInfo?.memberFrom[0] || filterInfo?.memberFrom[1] ? 1 : 0) +
      filterInfo?.recipients?.length +
      filterInfo?.events.length +
      filterInfo?.frequency?.length;
    setFilterCount(parseInt(count));
  }, [filterInfo]);

  const resetFilter = () => {
    setFilterInfo({
      memberFromType: "",
      memberFrom: ["", ""],
      recipients: [],
      events: [],

      frequency: [],
    });
    setTempData(SMSData);
  };

  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  const handleClearInput = () => {
    setCurrentPage(1);
    setSearchValue("");
    setTempData(SMSData);
    setRecipientPersona(null);
    setEventTemplates(null);

    setFrequency(null);
    setFromDate(null);
    setToDate(null);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const clearAll = () => {
    setSortArray([]);
    setSortCount(0);
    setSort(false);
    setTempData(SMSData);
  };

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(parseInt(event.target.value));
  };
  const sortData = (data, sortOptions) => {
    const sortedData = data.slice().sort((a, b) => {
      let result = 0;

      for (const sortOption of sortOptions) {
        const { sortValue, order } = sortOption;

        switch (sortValue) {
          // case "Recipients":
          //   result = a.recipients[0].localeCompare(b.recipients[0]);
          //   break;
          case "events":
            result =
              order === "asc"
                ? a.events.localeCompare(b.events)
                : b.events.localeCompare(a.events);
            break;

          case "smsFrequency":
            result =
              order === "asc"
                ? a.smsFrequency.localeCompare(b.smsFrequency)
                : b.smsFrequency.localeCompare(a.smsFrequency);
            break;
          case "updatedDateTime":
            result =
              order === "asc"
                ? new Date(a.updatedDateTime).getTime() -
                  new Date(b.updatedDateTime).getTime()
                : new Date(b.updatedDateTime).getTime() -
                  new Date(a.updatedDateTime).getTime();
            break;
          default:
            break;
        }

        if (result !== 0) {
          break;
        }
      }
      return result;
    });

    return sortedData;
  };

  const applySort = (close) => {
    setLoading(true);
    close && setSort(false);
    setCurrentPage(1);
    const sortedData = sortData(tempData, sortArray);
    setTempData(sortedData);
    setLoading(false);
  };
  // const sendFilter = (data) => {
  //   console.log("tempdata :", tempData);
  //   console.log("filterInfo :", data);
  // };
  const sendFilter = (filterInfo) => {
    setCurrentPage(1);
    const filterAfter = (item) => {
      return filterInfo?.memberFromType === "after"
        ? new Date(item.updatedDateTime) >= new Date(filterInfo?.memberFrom[0])
        : true;
    };
    const filterBefore = (item) => {
      return filterInfo?.memberFromType === "before"
        ? new Date(item.updatedDateTime) <= new Date(filterInfo?.memberFrom[1])
        : true;
    };
    const filterBetween = (item) => {
      if (filterInfo?.memberFromType === "between") {
        const lowerLimit = new Date(filterInfo?.memberFrom[0]);
        const upperLimit = new Date(filterInfo?.memberFrom[1]);

        // Set the upper limit to the end of the day
        upperLimit.setHours(23, 59, 59, 999);

        return (
          new Date(item.updatedDateTime) >= lowerLimit &&
          new Date(item.updatedDateTime) <= upperLimit
        );
      }

      return true;
    };

    const filterRecipients = (item) => {
      return filterInfo?.recipients && filterInfo?.recipients.length > 0
        ? filterInfo?.recipients.includes(item.recipients[0])
        : true;
    };
    const filterEvents = (item) => {
      return filterInfo?.events && filterInfo?.events.length > 0
        ? filterInfo?.events.includes(item.events)
        : true;
    };

    const filterFrequency = (item) => {
      return filterInfo?.frequency && filterInfo?.frequency.length > 0
        ? filterInfo?.frequency.includes(item.smsFrequency)
        : true;
    };
    const filteredData = SMSData.filter((item) => {
      // Check memberFromType
      return (
        filterAfter(item) &&
        filterBefore(item) &&
        filterBetween(item) &&
        filterRecipients(item) &&
        filterEvents(item) &&
        filterFrequency(item)
      );
    });

    setTempData([...filteredData]);
    setFilter(false);
  };
  const handleSearch = (e) => {
    setCurrentPage(1);
    let filteredData = SMSData;
    setSearchValue(e.target.value);
    if (e.target.value === "") {
      setTempData(SMSData);
    } else {
      const searchTermLower = e.target.value.toLowerCase();
      filteredData = tempData.filter((template) => {
        const recipientsMatch = template.recipients.some((recipient) =>
          recipient.toLowerCase().includes(searchTermLower)
        );
        const eventsMatch = template.events
          .toLowerCase()
          .includes(searchTermLower);
        return recipientsMatch || eventsMatch;
      });
      setTempData(filteredData);
    }
  };

  return (
    <>
      {SMSLoading && <Loader />}
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar parent="adminConsole" handleToggale={toggleDiv} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12 px-5">
                  {/* <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5>Templates ({`${SMSData?.length}`})</h5>

                    <div className="col-md-6 d-flex justify-content-between gap-2">
                      <div>
                        <form className="d-flex">
                          <div className="search-input-container">
                            <input
                              className="form-control me-2"
                              type="text"
                              placeholder="Search by Recipient or Template"
                              aria-label="Search"
                              value={searchValue}
                              style={{
                                fontSize: "14px",
                                width: "280px",
                              }}
                              onChange={handleSearch}
                            />
                            {searchValue && (
                              <button
                                className="clear-button btn-sm"
                                style={{
                                  backgroundColor: "#ffff",
                                  border: "1px solid #ffff",
                                }}
                                onClick={handleClearInput}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>
                      <div className="d-flex align-items-center text-left text-nowrap">
                        <span className="me-2">Show Records:</span>
                        <select
                          className="form-select form-select-sm"
                          value={limit}
                          onChange={handleLimitChange}
                        >
                          <option value={SMSData && SMSData?.length}>
                            All
                          </option>
                          <option value={10}>10</option>
                          <option value={20}>20</option>
                        </select>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="btn btn-sm w-auto btn-text-accent dd-filter"
                          onClick={() => {
                            setFilter(true);
                          }}
                        >
                          <i
                            aria-hidden="true"
                            class="fas fa-filter font-14 dd-filter"
                          ></i>{" "}
                          <span class="dd-filter d-none-1024">Filter</span> /
                          <span class="dd-filter"> {filterCount}</span>
                        </button>
                      </div>
                    </div>
                  </div> */}
                  <div className="mt-3">
                    <div className="row align-items-center justify-content-between">
                      <div className="col-md-4 col-sm-12">
                        <h5 className="text-left">
                          SMS Templates ({`${SMSData?.length}`})
                        </h5>
                      </div>

                      {/* <div className="col-md-3 col-sm-12 mb-2"> */}
                      {/* <form className="d-flex w-100">
                          <div
                            className="input-group"
                            style={{ border: "0.5px solid #ddd" }}
                          >
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search by Recipient or Template"
                              aria-label="Search"
                              value={searchValue}
                              style={{ fontSize: "14px" }}
                              onChange={handleSearch}
                            />
                            {searchValue && (
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={handleClearInput}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            )}
                          </div>
                        </form> */}
                      {/* </div> */}
                      <div className="col-md-2"></div>
                      <div className="col-md-3 col-sm-12 d-flex justify-content-end align-items-center mb-2">
                        <form className="d-flex w-100">
                          <div
                            className="input-group"
                            style={{ border: "0.5px solid #ddd" }}
                          >
                            <input
                              className="form-control"
                              type="text"
                              placeholder="Search by Recipient or Template"
                              aria-label="Search"
                              value={searchValue}
                              style={{ fontSize: "14px" }}
                              onChange={handleSearch}
                            />
                            {searchValue && (
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={handleClearInput}
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            )}
                          </div>
                        </form>
                      </div>

                      {/* <div className="col-md-1 col-sm-12 d-flex justify-content-end align-items-center mb-2">
                        <button
                          onClick={() => {
                            setCreateNew(true);
                            setSelectedSMSData({
                              id_smsTemplate: "",
                              events: "Create SMS Template",
                              smsSubject: "",
                              smsContent: "",
                              footer: "",
                              sender: "Sample Sender",
                              sendSms: "Yes",
                              recipients: [],
                              time: "",
                              smsLogo: null,
                              history: null,
                              smsFrequency: "",
                              createdBy: 1,
                              updatedBy: 1,
                              createdDateTime: "",
                              updatedDateTime: "",
                              operatingSystem: "",
                              createdByName: "Super  Admin",
                              updatedByName: "Super Admin",
                              updatedByEmail: "Admin@high5hire.com",
                              logDetails: "",
                            });
                            setSmsFullView(true);
                          }}
                        >
                          Create
                        </button>
                      </div> */}
                      <div className="col-md-2 col-sm-12 d-flex justify-content-md-end align-items-center mb-2">
                        <div className="d-flex align-items-center text-left text-nowrap me-2">
                          <span>Show Records:</span>
                          <select
                            className="form-select form-select-sm mx-2"
                            value={limit}
                            onChange={handleLimitChange}
                          >
                            <option value={SMSData && SMSData?.length}>
                              All
                            </option>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                          </select>
                        </div>

                        {/* <div>
                          <button
                            type="button"
                            className="btn btn-sm btn-text-accent dd-filter"
                            onClick={() => {
                              setFilter(true);
                            }}
                          >
                            <i className="fas fa-filter font-14 me-1"></i>
                            <span className="d-none d-md-inline">
                              Filter /{" "}
                            </span>
                            <span className="">{filterCount}</span>
                          </button>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <hr />

                  {Object.keys(smsEvents).length > 0 ? (
                    <>
                      <div className="col mt-3 ">
                        <table className="table table-bordered">
                          {/* table-sortable table-hoverable-cells  */}
                          <thead>
                            <tr>
                              <th className="no-hover pt-0">
                                <div className="d-flex align-items-center">
                                  Recipient Persona
                                </div>
                              </th>
                              <th className="no-hover pt-0">
                                <div className="d-flex align-items-center">
                                  Template Name
                                </div>
                              </th>
                              <th>
                                <div className="d-flex align-items-center">
                                  Frequency
                                </div>
                              </th>
                              <th>
                                <div className="d-flex align-items-center">
                                  Scheduled Send Time
                                </div>
                              </th>{" "}
                              {/* <th>
                                <div className="d-flex align-items-center">
                                  Last Modified
                                </div>
                              </th>
                              <th>
                                <div className="d-flex align-items-center">
                                  Last Modified By
                                </div>
                              </th> */}
                              <th>
                                <div className="d-flex align-items-center">
                                  Modified Status
                                </div>
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          {Object.keys(smsEvents).map((item, m) => (
                            <tbody
                              key={m}
                              className="table-bordered table responsiveTable"
                            >
                              {smsEvents[item].map((x, i) => (
                                <>
                                  <tr
                                    key={i}
                                    style={{
                                      backgroundColor:
                                        m % 2 === 0 ? "#F6F6F6" : "",
                                    }}
                                  >
                                    {i === 0 ? (
                                      <td
                                        rowSpan={smsEvents[item].length}
                                        className="text-start"
                                      >
                                        <span className="tag tag-blue3 mb-1 mr-1 pt-1 px-2">
                                          {item}
                                        </span>
                                      </td>
                                    ) : null}

                                    <td
                                      className="p-relative"
                                      // style={{ cursor: "pointer" }}
                                      // onClick={() => {
                                      //   setSmsFullView(true);
                                      //   setSelectedSMSData(x);
                                      // }}
                                    >
                                      {x.events}
                                    </td>

                                    <td>{x.smsFrequency}</td>
                                    <td>{x.time}</td>
                                    <td>
                                      {x.updatedByEmail &&
                                      x.updatedDateTime !== ""
                                        ? "Updated by " +
                                          x.updatedByEmail +
                                          " on " +
                                          moment(x.updatedDateTime).format(
                                            "MM/DD/YYYY"
                                          )
                                        : ""}
                                    </td>
                                    <td className="d-flex justify-content-center align-items-center mv-auto">
                                      <button
                                        type="button"
                                        style={{
                                          width: "70px",
                                          // height: "30px",
                                          fontSize: "12px",
                                          textAlign: "center",
                                        }}
                                        className="btn btn-sm btn-secondary ml-2"
                                        onClick={() => {
                                          setSmsFullView(true);
                                          setSelectedSMSData(x);
                                        }}
                                      >
                                        View
                                      </button>
                                    </td>
                                    {/* <td>
                                      {x.updatedByName}
                                      {x.updatedByEmail &&
                                        ", " + x.updatedByEmail}
                                      {x.operatingSystem && ", "}
                                      <span>
                                        {x.operatingSystem &&
                                          "IP Address : " + x.operatingSystem}
                                      </span>
                                    </td> */}
                                  </tr>
                                </>
                              ))}
                            </tbody>
                          ))}
                        </table>
                      </div>
                      {SMSData && SMSData?.length !== 0 && (
                        <Pagination
                          currentPage={currentPage}
                          totalItems={tempData?.length}
                          itemsPerPage={limit}
                          onPageChange={handlePageChange}
                          limit={limit}
                        />
                      )}
                    </>
                  ) : (
                    <>
                      <NoRecord />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>

        {smsFullView && (
          <SMSTemplateFullView
            smsFullView={smsFullView}
            setSmsFullView={setSmsFullView}
            selectedSMSData={selectedSMSData}
            setSelectedSMSData={setSelectedSMSData}
            createNew={createNew}
            setCreateNew={setCreateNew}
          />
        )}
        {filter && (
          <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
            <Filter
              onClose={closeModal}
              filterKeys={filterKeys}
              filterInfo={filterInfo}
              setFilterInfo={setFilterInfo}
              sendFilter={sendFilter}
              resetFilter={resetFilter}
              setFilterCount={setFilterCount}
            />
          </OutsideClickHandler>
        )}
      </body>
    </>
  );
}

export default SMSTemplate;
