import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { useParams, useHistory } from "react-router";
// import { post } from "../../../../service/ApiService";
import ContactInfo from "../../modal/addtalent/manualentry/ContactInfo";
import ProfileInfo from "../../modal/addtalent/manualentry/ProfileInfo";
import Experience from "../../modal/addtalent/manualentry/Experience";
import Education from "../../modal/addtalent/manualentry/Education";
// import Certifications from "../manualentry/Certification";
import License from "../../modal/addtalent/manualentry/License";
// import imageLoader from "../../../../images/button-loader.gif";
import {
  contactInfoValidation,
  profileInfoValidation,
  experienceValidation,
  educationValidation,
  certificationValidation,
  licenseValidation,
} from "../../validation/AddTalent";
import {
  checkEmptyObject,
  checkListOfEmptyObject,
} from "../../function/CommonFunction";
import { domain } from "../../../constants/Constants";
// import { getCandidateDropdown } from "../../../../actions/modal/Addcandidate";
// import Loader from "../../../Loader/Loader";
import { isNonEmptyArray } from "@apollo/client/utilities";

import moment from "moment";
import {
  getCandidateName,
  getUserName,
  getUserTenant,
} from "../../../service/AuthService";
import Loader from "../../Loader/Loader";
import Certifications from "../../modal/addtalent/manualentry/Certification";
import ContactCand from "./ContactCand";
import ProfileInfoDS from "./ProfileInfoDS";
import { get, post } from "../../../service/ApiService";
import AutoMatchScoreCard from "../../common/Jobs/ViewTalentDetails/AutoMatchScoreCard";
import AutoMatchScoreCardDS from "./AutoMatchScoreCardDS";
import Preferences from "./Preferences";
import EducationDS from "./EducationDS";
import ExperienceDS from "./ExperienceDS";

function CandidateProfile(props) {
  const { id, tenant, recruiterid } = useParams();
  const reffererId = localStorage.getItem("reffererid");

  const history = useHistory();
  const dispatch = useDispatch();
  const [contact, setContact] = useState(true);
  const [profileInfo, setProfileInfo] = useState(true);
  const [experience, setExperience] = useState(true);
  const [education, setEducation] = useState(true);
  const [certification, setCertification] = useState(true);
  const [preferences, setPreferences] = useState(true);
  const [license, setLicense] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [sourceChannel, setSourceChannel] = useState("");
  const [recruiterAgency, setRecruiterAgency] = useState("");
  const [reffererName, setReffererName] = useState("");
  const [scoreCard, setScoreCard] = useState(false);
  const [candidateData, setCandidateData] = useState("");
  const [screeningQuestions, setScreeningQuestions] = useState(false);
  const [clientAdmin, setClientAdmin] = useState({});
  const [validationDone, setValidationDone] = useState({
    contact: true,
    profile: true,
    experience: true,
    certificate: true,
    education: true,
    license: true,
  });
  const recruiterName = getCandidateName();
  const recruiterEmail = getUserName();
  // const tenantName = getUserTenant();
  const [tenantName, setTenantName] = useState("");

  const calculateExperience = [
    { name: "Intern", range: [0] },
    { name: "1-3 years", range: [1, 2] },
    { name: "3-5 years", range: [3, 4] },
    { name: "5-10 years", range: [5, 6, 7, 8, 9] },
    { name: "10+ years", range: [] },
  ];

  const findRange = (years, months) => {
    let totalMonths = Number(years) * 12 + Number(months);
    if (totalMonths >= 120) {
      return "10+ years";
    } else if (totalMonths < 12) {
      if (totalMonths === 0) {
        return "Intern";
      } else {
        return "Less than one year";
      }
    } else {
      let yearsExperience = Math.floor(totalMonths / 12);
      let finalValue = calculateExperience.find((i) =>
        i.range.includes(yearsExperience)
      ).name;
      return finalValue;
    }
  };

  const [load, setLoading] = useState(false);
  const { loading, dropDownData } = useSelector(
    (state) => ({
      loading: state.AddCandidate.loading,
      dropDownData: state.AddCandidate.dropDownData,
    }),
    shallowEqual
  );

  // getTenantDetails
  useEffect(() => {
    getTenant();
  }, []);

  const getTenant = async () => {
    const params = { tenantId: tenant };
    setLoading(true);
    const res = await post("/career/gettenant", params).then((res) => {
      setTenantName(res.data[0].tenantname);
      setLoading(false);
    });
  };

  // Recruiter Details
  const getRecruiter = () => {
    if (recruiterid === undefined) {
      setSourceChannel("Career Page");
    } else {
      getRecruiterDetails();
    }
  };
  const getRecruiterDetails = async () => {
    const params = { recruiterid: recruiterid };
    const res = await post("/career/getrecruiterbyid", params).then((res) => {
      let name = `${res.data[0].firstName} ${res.data[0].lastName}`;

      setSourceChannel(name);
      setRecruiterAgency(res.data[0].tenantname[0].tenantname);
    });
    // Agency(LIC) Oliver Davis
  };
  useEffect(() => {
    getRecruiter();
  }, []);

  // Reffer Details
  const getRefferer = () => {
    if (reffererId === "undefined") {
      console.log("first");
    } else {
      getRefferDetails();
    }
  };
  const getRefferDetails = async () => {
    const params = { reffererId: reffererId };
    const res = await post("/career/getrefferbyid", params).then((res) => {
      let name = `${res.data[0].refFirstName} ${res.data[0].refLastName}`;
      setReffererName(name);
    });
    // Agency(LIC) Oliver Davis
  };
  useEffect(() => {
    getRefferer();
  }, []);

  //get client admin details
  const getClientAdmin = async () => {
    const params = { tenantId: tenant };
    setLoading(true);
    const res = await post("/career/getclientadmin", params).then((res) => {
      setClientAdmin(res.data[0]);
      setLoading(false);
    });
  };

  useEffect(() => {
    getClientAdmin();
  }, []);

  const onCollapsibleClick = (type) => {
    if (type === "contact") {
      if (contact === false) {
        setContact(true);
      } else {
        setContact(false);
      }
    }

    if (type === "profile") {
      if (profileInfo === false) {
        setProfileInfo(true);
      } else {
        setProfileInfo(false);
      }
    }

    if (type === "experience") {
      if (experience === false) {
        setExperience(true);
      } else {
        setExperience(false);
      }
    }

    if (type === "education") {
      if (education === false) {
        setEducation(true);
      } else {
        setEducation(false);
      }
    }

    if (type === "certification") {
      if (certification === false) {
        setCertification(true);
      } else {
        setCertification(false);
      }
    }

    if (type === "license") {
      if (license === false) {
        setLicense(true);
      } else {
        setLicense(false);
      }
    }
    if (type === "preferences") {
      if (preferences === false) {
        setPreferences(true);
      } else {
        setPreferences(false);
      }
    }
  };

  // Experience child Component
  const [experiences, setExperiences] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      isSelect: false,
    },
  ]);

  const [experienceVal, setExperienceVal] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    },
  ]);

  // Education Child Component
  const [educations, setEducations] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);

  const [educationVal, setEducationVal] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);

  // Certificate child  Component
  const [certifications, setCertifications] = useState([
    { certificationName: "", certificate: "", issueDate: "", expiryDate: "" },
  ]);

  const [certificationVal, setCertificationVal] = useState([
    { certificationName: "", certificate: "", issueDate: "", expiryDate: "" },
  ]);

  // License child  Component
  const [licenses, setLicenses] = useState([
    { licenseName: "", license: "", state: "", expiryDate: "" },
  ]);

  const [licenseVal, setLicenseVal] = useState([
    { licenseName: "", license: "", state: "", expiryDate: "" },
  ]);

  // Contact Info child  Component
  const [contactInfo, setContactInfo] = useState({
    firstName: props.candidateDetails?.fName
      ? props.candidateDetails?.fName
      : "",
    middleName: "",
    lastName: props.candidateDetails?.lName
      ? props.candidateDetails?.lName
      : "",
    email: props.candidateDetails?.email ? props.candidateDetails?.email : "",
    zipcode: "234234",
    address: "NA",
    addressCity: props.candidateDetails?.city
      ? props.candidateDetails?.city
      : "",
    addressState: "NA",
    country: props.candidateDetails?.country
      ? props.candidateDetails?.country
      : "",
    workPhoneCode: "",
    workPhone: "",
    homePhoneCode: "",
    homePhone: props.candidateDetails?.alternatePhone
      ? props.candidateDetails?.alternatePhone
      : "",
    mobilePhoneCode: "",
    mobilePhone: props.candidateDetails?.phone
      ? props.candidateDetails?.phone
      : "",
    phoneValidation: true,
    phoneValidation2: true,
  });

  const [contactVal, setContactVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zipcode: "",
    addressCity: "",
    addressState: "",
    country: "",
  });

  // Profile Info child  Component
  const [profileData, setProfileData] = useState({
    facebook: "",
    twitter: "",
    linkedIn: "",
    preferredSalary: "",
    preferredSalaryCurrency: "USD",
    minContractRate: "",
    minContractRateCurrency: "USD",
    description: "",
    fileName: "",
    base64: "",
    positionTypes: [],
    preferredLocation: [],
    primarySkills: props.candidateDetails?.primarySkills,
    secondarySkills: [],
    skillSet: [],
    source: "Career Page",
    visaStatus: "",
    designation: "",
    currentEmployer: props.candidateDetails?.currentEmployer
      ? props.candidateDetails?.currentEmployer
      : "",

    experienceLevel: "",
    experienceYear: props.candidateDetails?.experienceYear
      ? props.candidateDetails?.experienceYear
      : "",
    experienceMonth: props.candidateDetails?.experienceMonth
      ? props.candidateDetails?.experienceMonth
      : "",
    currentJobTitle: props.candidateDetails?.currentPosition
      ? props.candidateDetails?.currentPosition
      : "",
    isFresher: false,
    isRemote: false,
    isHybrid: false,
    isOnsite: false,
  });

  const [profileVal, setProfileVal] = useState({
    preferredSalaryCurrency: "",
    minContractRateCurrency: "",
    preferredLocation: "",
    primarySkills: "",
    experienceYear: "",
    experienceMonth: "",
    currentJobTitle: "",
  });

  // Submit Add talent
  const submitAddTalent = async () => {
    let contactInfoVal =
      validationDone.contact && contactInfoValidation(contactInfo);
    let profileInfoVal =
      validationDone.profile && profileInfoValidation(profileData);
    let experienceVal =
      validationDone.experience && experienceValidation(experiences);
    let educationVal =
      validationDone.education && educationValidation(educations);
    let certificationVal =
      validationDone.certificate && certificationValidation(certifications);
    let licenseVal = validationDone.license && licenseValidation(licenses);

    if (
      validationDone.contact &&
      !checkEmptyObject(contactInfoVal.validation)
    ) {
      toast.error(
        "There are a few incomplete required fields. Please complete them to continue. "
      );
    }
    // else if (profileData.preferredSalary == "") {
    //   toast.error("Please provide your expected salary");
    // }
    else if (
      !checkEmptyObject(experienceVal.validation && experienceVal.validation[0])
    ) {
      if (validationDone.profile) {
        setValidationDone({ ...validationDone, profile: false });
        setProfileInfo(false);
        setProfileVal(profileInfoVal.validation);
      }
      setExperienceVal(experienceVal.validation);
      setExperience(true);
      //   setLoading(false);
      toast.error(experienceVal.validationObj[0]);
    } else if (
      !checkEmptyObject(educationVal.validation && educationVal.validation[0])
    ) {
      if (validationDone.profile) {
        setValidationDone({ ...validationDone, experience: false });
        setExperience(false);
        setExperienceVal(experienceVal.validation);
      }
      setEducationVal(educationVal.validation);
      setEducation(true);
      //   setLoading(false);
      toast.error(educationVal.validationObj[0]);
    } else if (
      !checkEmptyObject(
        certificationVal.validation && certificationVal.validation[0]
      )
    ) {
      if (validationDone.profile) {
        setValidationDone({ ...validationDone, education: false });
        setEducation(false);
        setEducationVal(educationVal.validation);
      }
      setCertificationVal(certificationVal.validation);
      setCertification(true);
      //   setLoading(false);
      toast.error(certificationVal.validationObj[0]);
    } else if (
      !checkEmptyObject(licenseVal.validation && licenseVal.validation[0])
    ) {
      if (validationDone.profile) {
        setValidationDone({ ...validationDone, certificate: false });
        setCertification(false);
        setCertificationVal(certificationVal.validation);
      }
      setLicenseVal(licenseVal.validation);
      setLicense(true);
      toast.error(licenseVal.validationObj[0]);
      //   setLoading(false);
    } else if (!contactInfo.phoneValidation || !contactInfo.phoneValidation2) {
      toast.error("Please Enter Valid Phone");
    } else {
      // let isExists = existingCandidateList.filter(
      //   (row) => row.email == contactInfo.email
      // );
      // if (isExists.length > 0) {
      //   toast.error("Talent already exists");
      // }
      // else {
      setLoading(true);
      setSubmitClicked(true);
      let talentData = {
        ...contactInfo,
        ...profileData,
      };
      let experience =
        experiences.length > 1
          ? experiences.filter((item) => checkEmptyObject(item) === false)
          : experiences;
      let education =
        educations.length > 1
          ? educations.filter((item) => checkEmptyObject(item) === false)
          : educations;
      let certification =
        certifications.length > 1
          ? certifications.filter((item) => checkEmptyObject(item) === false)
          : certifications;
      let license =
        licenses.length > 1
          ? licenses.filter((item) => checkEmptyObject(item) === false)
          : licenses;
      talentData.experiences = experience;
      talentData.educations = education;
      talentData.certifications = certification;
      // talentData.licenses = license;
      talentData.domainName = domain;
      talentData.experienceLevel = findRange(
        profileData.experienceYear,
        profileData.experienceMonth
      );
      // calculateAverage(props.job, talentData);
      setLoading(false);
      props.setCandidateData(talentData);
      props.setProfileData(profileData);
      props.setScoreCard(true);
      props.setCreateProfile(false);
    }
  };
  // Join Communityn apply
  const submitAddTalent2 = async () => {
    let contactInfoVal =
      validationDone.contact && contactInfoValidation(contactInfo);
    let profileInfoVal =
      validationDone.profile && profileInfoValidation(profileData);
    let experienceVal =
      validationDone.experience && experienceValidation(experiences);
    let educationVal =
      validationDone.education && educationValidation(educations);
    let certificationVal =
      validationDone.certificate && certificationValidation(certifications);
    let licenseVal = validationDone.license && licenseValidation(licenses);

    if (
      validationDone.contact &&
      !checkEmptyObject(contactInfoVal.validation)
    ) {
      toast.error(
        "There are a few incomplete required fields. Please complete them to continue. "
      );
    } else {
      // let isExists = existingCandidateList.filter(
      //   (row) => row.email == contactInfo.email
      // );
      // if (isExists.length > 0) {
      //   toast.error("Talent already exists");
      // }
      // else {
      setLoading(true);
      setSubmitClicked(true);
      let talentData = {
        ...contactInfo,
        ...profileData,
      };
      let experience =
        experiences.length > 1
          ? experiences.filter((item) => checkEmptyObject(item) === false)
          : experiences;
      let education =
        educations.length > 1
          ? educations.filter((item) => checkEmptyObject(item) === false)
          : educations;
      let certification =
        certifications.length > 1
          ? certifications.filter((item) => checkEmptyObject(item) === false)
          : certifications;
      let license =
        licenses.length > 1
          ? licenses.filter((item) => checkEmptyObject(item) === false)
          : licenses;
      talentData.experiences = experience;
      talentData.educations = education;
      talentData.certifications = certification;
      // talentData.licenses = license;
      talentData.domainName = domain;
      talentData.experienceLevel = findRange(
        profileData.experienceYear,
        profileData.experienceMonth
      );
      // calculateAverage(props.job, talentData);
      setLoading(false);
      setCandidateData(talentData);
      // setScoreCard(true);
      // props.setCreateProfile(false);
      // props.message === "JoinCommunity" &&
      try {
        setLoading(true);
        const res = await post("/dsTalent/addDsTalent", {
          ...talentData,
          FK_Tenant: parseInt(tenant),
          tenantName: tenantName,
          jobId: id,
          sourceChannel: sourceChannel,
          recruiterAgency: recruiterAgency,
          reffererName: reffererName,
          experienceYear: profileData.experienceYear.toString(),
          experienceMonth: profileData.experienceMonth.toString(),
          preferredLocation: profileData.isRemote
            ? [...profileData.preferredLocation, "Remote"]
            : profileData.preferredLocation,
        });
        if (res.status === 200) {
          sessionStorage.clear();
          if (res.data.value) {
            //   props.fetchCandidate();
            const successData = {
              name: `${contactInfo.firstName} ${contactInfo.lastName}`,
              email: contactInfo.email,
            };
            props.message === "JoinCommunity"
              ? toast.success(
                  "You have successfully joined our talent community"
                )
              : toast.success("Please check your email for password and email");
            try {
              const sendDataToAutoMatch = await post(
                "/automatch/autoMatchTalent",
                {
                  ...talentData,
                  candidateID: res.data.value[0].key,
                  recruiterName,
                  recruiterEmail,
                  tenantName,
                }
              );
            } catch (error) {
              setLoading(false);
            }
          } else {
            setSubmitClicked(false);
            //toast.error(res.data.ReturnText)
          }
        }

        if (reffererId !== "undefined") {
          addRefferedCand();
        }
        setSubmitClicked(false);
        props.message !== "JoinCommunity" && props.setCreateProfile(false);
        props.message === "JoinCommunity" && window.history.back();
        setLoading(false);
      } catch (error) {
        setSubmitClicked(false);
        toast.error("Some Error Occurred. Please Try Again");
      }

      // Add talent to Candidate login
      const res = await post("/dsTalent/candidateLogin", {
        tenantId: tenant,
        userDetails: 233,
        userName: contactInfo.email,
      }).then((res) => console.log("res", res));
    }
  };

  const applyJob = async (setIsVetting) => {
    const params = {
      jobId: props.job.jobId.toString(),
      jobTitle: props.job.title,
      jobTenant: props.job.FK_Tenant,
      jobLocation: props.job.isRemote === "true" ? "Remote" : null,
      recruiterId: clientAdmin.userId.toString(),
      recruiterName: `${clientAdmin.firstName} ${clientAdmin.lastName}`,
      recruiterEmail: clientAdmin.email,
      recruiterTenant: tenantName,
      candidateId: "", // id
      candidateFname: candidateData.firstName,
      candidateMname: "",
      candidateLname: candidateData.lastName,
      candidateEmail: candidateData.email,
      candidateLocation: `${candidateData.addressCity},${candidateData.country}`,
      candidateImageURL: "",
      candidateTenant: props.job.FK_Tenant,
      payRate: candidateData.preferredSalary,
      payRateCurrency: candidateData.preferredSalaryCurrency,
      jobStatus: "active", // jobStatus
      candidateStatus: "", // candidateStatus
    };
    setLoading(true);
    const res = await post("/applyJob/", params)
      .then((res) => {
        // setTenantData(res.data[0]);
        setLoading(false);
        if (props.job.vettingRequired === "false") {
          toast.success("Successfully applied for the job");
          props.setCreateProfile(false);
        } else {
          setIsVetting(true);
        }
      })
      .catch((err) => setLoading(false));
  };

  const addRefferedCand = async () => {
    setLoading(true);
    const params = {
      FirstName: contactInfo.firstName,
      LastName: contactInfo.lastName,
      Email: contactInfo.email,
      ref_candUniqueId: reffererId,
    };
    const res = await post("/canReferal/updateCandidate", params)
      .then((res) => {
        setLoading(false);
        toast.success("Referal Candidate added");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Unable to add candidate");
      });
  };

  const calculateAverage = (jobData, candidateData) => {
    let sum = 0;
    let count =
      jobData?.primarySkills.length +
      // jobData?.secondarySkills?.length +
      jobData?.skillSet?.length +
      jobData?.education?.length +
      3;
    jobData?.primarySkills?.forEach((skill) => {
      if (candidateData?.primarySkills?.includes(skill)) {
        sum++;
      }
    });
    // jobData?.secondarySkills?.forEach((skill) => {
    //   if (candidateData?.secondarySkills?.includes(skill)) {
    //     sum++;
    //   }
    // });
    jobData?.industries?.forEach((skill) => {
      if (candidateData?.skillSet?.includes(skill)) {
        sum++;
      }
    });
    if (
      candidateData?.educations
        .map((item) => item.educationType.toLowerCase())
        .includes(jobData?.education[0].toLowerCase())
    ) {
      sum++;
    }
    // if (
    //   [
    //     candidateData?.addressCity,
    //     ...candidateData?.preferedLocations,
    //   ].includes("Kansas")
    // ) {
    //   sum++;
    // }
    if (
      jobData?.isRemote &&
      candidateData?.preferedLocations?.includes("Remote")
    ) {
      sum++;
    }
    if (candidateData?.currentJobTitle === jobData?.title) {
      sum++;
    }
    if (candidateData?.experienceLevel === jobData?.experienceLevel) {
      sum++;
    }
    let total = Math.round((sum * 100) / count);
  };

  useEffect(() => {
    // dispatch(getCandidateDropdown());
    // check if props data is present then set to values
    if (props.parsed) {
      //  profileInfo
      let location;
      // if (props.parsedData.data.location) {
      //   location = props.parsedData.data.location;
      // } else {
      //   location = "";
      // }
      setContactInfo({
        ...contactInfo,
        // address: location.rawInput,
        // email: props.parsedData.data.emails.toString(),
        // country: location.country ? location.country : "",
        // addressState: location.state ? location.state : "",
        // addressCity: location.city ? location.city : "",
        // zipcode: location.postalCode ? location.postalCode : "",
        // firstName: props.parsedData.data.name.first,
        // lastName: props.parsedData.data.name.last,
        // mobilePhone: props.parsedData.data.phoneNumbers[0],
        // homePhone: props.parsedData.data.phoneNumbers[1],
      });

      const Skill = props.parsedData.data.skills.map((item) => {
        return item.name;
      });

      setProfileData(Skill);
      setProfileData({
        ...profileData,
        primarySkills: [...profileData?.primarySkills, ...Skill],
        linkedIn: props.parsedData.data.linkedin,
        facebook: props.parsedData.data.websites[0],
        skillSet: [props.parsedData.data.profession],
        description: props.parsedData.data.summary,
        // experienceYear: props.parsedData.data.totalYearsExperience.toString(),
        fileName: props.parsedData.fileName,
        base64: props.parsedData.base64,
      });

      // let experience =
      //   props.parsedData.data.workExperience &&
      //   props.parsedData.data.workExperience.map((item) => ({
      //     employerName: item.organization,
      //     jobTitle: item.jobTitle,
      //     industry: item.industry,
      //     startDate: moment(new Date(item.dates.startDate)).format(
      //       "YYYY-MM-DD"
      //     ),
      //     endDate: moment(new Date(item.dates.endDate)).format("YYYY-MM-DD"),
      //   }));
      // let experienceVal2 =
      //   props.parsedData.data.workExperience &&
      //   props.parsedData.data.workExperience.map((item) => ({
      //     employerName: "",
      //     industry: "",
      //     jobTitle: "",
      //     startDate: "",
      //     endDate: "",
      //   }));
      // {
      //   experienceVal2 && setExperienceVal(experienceVal2);
      // }
      // {
      //   experience && setExperiences(experience);
      // }

      let education =
        props.parsedData.data.education &&
        props.parsedData.data.education.map((item) => ({
          //graduatedYear: item.dates.completionDate,
          educationProgram: item.accreditation.education,
          school: item.organization,
          major: item.accreditation.inputStr,
        }));
      let educationVal2 =
        props.parsedData.data.education &&
        props.parsedData.data.education.map((item) => ({
          graduatedYear: "",
          educationType: "",
          educationProgram: "",
          school: "",
          major: "",
        }));
      {
        educationVal2 && setEducationVal(educationVal2);
      }
      {
        education && setEducations(education);
      }
    }
  }, [props]);

  return (
    <>
      {load ? (
        <Loader />
      ) : (
        <div
          // style={{`marginTop:${'-14px'}`}}
          style={{ marginTop: "-14px" }}
        >
          <div class="bl-modal-body" style={{ padding: "0px 0px 0px 0px" }}>
            {/* {props.message !== "JoinCommunity" && (
              <h4 className="modal-title mx-2" id="CreateQuestionModalLabel">
                Profile
              </h4>
            )} */}
            <div class="mb-2 mt-3">
              <div class="card mb-2">
                <div class="card-body">
                  {props.fileName && (
                    <div
                      v-if="item.filename"
                      class="d-flex align-items-center justify-content-between hoverable"
                    >
                      <div class="d-flex align-items-center">
                        <div class="avatar avatar-coral avatar-sm">
                          <i class="fad fa-user"></i>
                        </div>
                        <p class="mb-0 ml-2">File Name</p>
                      </div>
                      <div>
                        <i
                          v-if="item.isExpanded"
                          class="fal fa-fw fa-chevron-square-down"
                        ></i>
                        <i class="fal fa-fw fa-chevron-square-up"></i>
                      </div>
                    </div>
                  )}
                  <div v-if="item.isExpanded">
                    <div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("contact");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {contact === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Contact Info</small>
                        </span>
                      </button>
                      <div
                        className={
                          contact === true
                            ? "ml-2 ml-0-600 mt-2 mb-1"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat bg-gray4 ml-4 ml-0-600">
                          {props.parent === "JobDecription" ? (
                            <ContactCand
                              updatedParsed={props.updatedParsed}
                              contactInfo={contactInfo}
                              setContactInfo={setContactInfo}
                              contactInfoValidation={contactVal}
                              profileInfo={profileData}
                              setProfileInfo={setProfileData}
                              candidateDetails={props.candidateDetails}
                              message={props.message}
                            />
                          ) : (
                            <ContactInfo
                              contactInfo={contactInfo}
                              setContactInfo={setContactInfo}
                              contactInfoValidation={contactVal}
                              profileInfo={profileData}
                              setProfileInfo={setProfileData}
                            />
                          )}
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("profile");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {profileInfo === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Profile Info</small>
                        </span>
                      </button>
                      <div
                        className={
                          profileInfo === true
                            ? "ml-2 ml-0-600 mt-2 mb-1"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat bg-gray4 ml-4 ml-0-600">
                          <ProfileInfoDS
                            parent="DirectSourcing"
                            profileInfo={profileData}
                            setProfileInfo={setProfileData}
                            profileInfoValidation={profileVal}
                            changePreferredLocation={(location) => {
                              setProfileData({
                                ...profileData,
                                preferredLocation: location,
                              });
                            }}
                          />
                        </div>
                      </div>

                      <button
                        onClick={() => {
                          onCollapsibleClick("education");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {education === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Education</small>
                        </span>
                      </button>
                      <div
                        className={
                          education === true
                            ? "ml-2 ml-0-600 mt-2 mb-1"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat card-lg ml-4 ml-0-600">
                          <div class="card-body" style={{ padding: "10px" }}>
                            <EducationDS
                              parent="DirectSourcing"
                              educations={educations}
                              setEducations={setEducations}
                              educationValidation={educationVal}
                              setEducationValidation={setEducationVal}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("experience");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {experience === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Experience</small>
                        </span>
                      </button>
                      <div
                        className={
                          experience === true
                            ? "ml-2 ml-0-600 mt-2 mb-1"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat card-lg ml-4 ml-0-600">
                          <div class="card-body" style={{ padding: "10px" }}>
                            <Experience
                              parent="DirectSourcing"
                              experiences={experiences}
                              setExperiences={setExperiences}
                              profileInfo={profileData}
                              setProfileInfo={setProfileData}
                              experienceValidation={experienceVal}
                              setExperienceValidation={setExperienceVal}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Preferences */}
                      <button
                        onClick={() => {
                          onCollapsibleClick("preferences");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {preferences === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Preferences</small>
                        </span>
                      </button>
                      <div
                        className={
                          preferences === true
                            ? "ml-2 ml-0-600 mt-2 mb-1"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat card-lg ml-4 ml-0-600">
                          {/* <div class="card-body"> */}
                          <Preferences
                            parent="DirectSourcing"
                            profileInfo={profileData}
                            setProfileInfo={setProfileData}
                            profileInfoValidation={profileVal}
                            changePreferredLocation={(location) => {
                              setProfileData({
                                ...profileData,
                                preferredLocation: location,
                              });
                            }}
                            job={props.job}
                            message={props.message}
                          />
                          {/* </div> */}
                        </div>
                      </div>
                      {/* <button
                      onClick={() => {
                        onCollapsibleClick("certification");
                      }}
                      type="button"
                      class="btn btn-collapsible mb-2"
                    >
                      {certification === true ? (
                        <i class="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i class="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Certifications</small>
                      </span>
                    </button>
                    <div
                      className={
                        certification === true
                          ? "ml-2 ml-0-600 mt-2 mb-1"
                          : "d-none"
                      }
                    >
                      <div class="card card-flat card-lg ml-4 ml-0-600">
                        <div class="card-body">
                          <Certifications
                            certifications={certifications}
                            setCertifications={setCertifications}
                            certificationValidation={certificationVal}
                            setCertificationValidation={setCertificationVal}
                          />
                        </div>
                      </div>
                    </div> */}
                      {/* <button
                        onClick={() => {
                          onCollapsibleClick("license");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {license === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Licenses</small>
                        </span>
                      </button>
                      <div
                        className={
                          license === true
                            ? "ml-2 ml-0-600 mt-2 mb-4"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat card-lg ml-4 ml-0-600">
                          <div class="card-body">
                            <License
                              licenses={licenses}
                              setLicenses={setLicenses}
                              setCertifications={setCertifications}
                              licenseValidation={licenseVal}
                              setLicenseValidation={setLicenseVal}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary btn-sm"
              disabled={submitClicked}
              onClick={props.closeModal}
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary btn-sm">
              {submitClicked === false ? (
                <span
                  onClick={() => {
                    if (
                      existingCandidateList
                        .map((i) => i.email)
                        .includes(contactInfo.email)
                    ) {
                      toast.error("Talent Already Exists");
                    } else {
                      submitAddTalent();
                    }
                  }}
                >
                  Add
                </span>
              ) : (
                <span v-if="submittingForm">
                  Adding Talent
                  <img width="20px" src={imageLoader} alt="" />
                </span>
              )}
            </button>
          </div> */}
            <div className="row mx-auto my-3 ">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-sm btn-secondary mr-2"
                  onClick={() => {
                    props.setJoinCommunity(false);
                    props.setIsLogIn(false);
                    window.scrollTo(0, 0);
                    props.setCreateProfile(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-primary "
                  // onClick={() => calculateAverage(props.job, talentData)}
                  onClick={() => {
                    props.message === "JoinCommunity"
                      ? submitAddTalent2()
                      : submitAddTalent();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* AutoMatch Score Card */}
      {/* <Modal show={scoreCard} size="lg">
        <Modal.Header>
          <h6 class="">
            {screeningQuestions === true ? "Screening Questions" : "Score Card"}
          </h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setScoreCard(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <AutoMatchScoreCardDS
            jobData={props.job}
            candidateData={candidateData}
            setScreeningQuestions={setScreeningQuestions}
            applyJob={applyJob}
            setScoreCard={setScoreCard}
          />
        </Modal.Body>
      </Modal> */}
    </>
  );
}
export default CandidateProfile;
