import React, { useState } from "react";
import moment from "moment";
import { IoLocationOutline } from "react-icons/io5";

const GenaralDetails = (props) => {
  const [viewAll, setViewAll] = useState(false);
  const { jobData } = props;


  const removeDuplicates = (locations) => {
    const uniqueLocations = [];
    locations.forEach((location) => {
      if (!uniqueLocations.includes(location.location)) {
        uniqueLocations.push(location.location);
      }
    });
    return uniqueLocations;
  };

  const uniqueLocationList = jobData?.locationList
    ? removeDuplicates(JSON.parse(jobData?.locationList))
    : [];

  return (
    <div className="mb-4 ml-4">
      {jobData?.jobDivaId && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-fw fa-id-card"></i>
          </div>
          <div className="ml-2">
            <p className=" mb-0">{jobData?.jobDivaId}</p>
            <p className="mb-0">
              <small>JobDiva Id</small>
            </p>
          </div>
        </div>
      )}

      {props.isFrom !== "offerSidepanel" && (
        <>
          {jobData?.jobTitle && (
            <div className="d-flex mb-2 pl-1">
              <div>
                <i className="fad fa-fw fa-piggy-bank" />
              </div>
              <div className="ml-2">
                <p className=" mb-0">{jobData?.poolname}</p>
                <p className="mb-0">
                  <small>Community</small>
                </p>
              </div>
            </div>
          )}
          {jobData?.activeFrom && (
            <div className="d-flex mb-2 pl-1">
              <div className="avatar avatar-sm">
                <i className="far fa-calendar-check"></i>
              </div>
              <div class="ml-2">
                <p className="mb-0">
                  {moment(jobData?.activeFrom).format("MM/DD/YYYY")}
                </p>
                <p class="mb-0">
                  <small>Active From</small>
                </p>
              </div>
            </div>
          )}
          {jobData?.expiresOn && (
            <div className="d-flex mb-2 pl-1">
              <div className="avatar avatar-sm">
                <i className="far fa-calendar-times"></i>
              </div>
              <div class="ml-2">
                <p className="mb-0">
                  {moment(jobData?.expiresOn).format("MM/DD/YYYY")}
                </p>
                <p class="mb-0">
                  <small>Expire On</small>
                </p>
              </div>
            </div>
          )}{" "}
        </>
      )}

      <div className="d-flex mb-2 pl-1">
        <div>
          <IoLocationOutline color="#000" size={18} className="mr-1" />
        </div>
        <div className="ml-2">
          <p className=" mb-0">
            {jobData?.isCountry ? jobData?.country : jobData?.location}
          </p>
          <p className="mb-0">
            <small>Location</small>
          </p>
        </div>
      </div>

      {uniqueLocationList.length > 0 && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <IoLocationOutline color="#000" size={18} className="mr-1" />
          </div>
          <div className="ml-2">
            {uniqueLocationList.map((location, index) => (
              <li key={index} className="tag tag-blue3 mr-1 mt-1 pt-1 px-2">
                {location}
              </li>
            ))}
            <p className="mb-0">
              <small>Preferred Location for Talents</small>
            </p>
          </div>
        </div>
      )}

      {jobData?.workPlaceType && jobData?.workPlaceType !== "Remote" && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-fw fa-map-marked-alt" />
          </div>
          <div className="ml-2">
            <p className="mb-0">
              {jobData?.workPlaceType} {jobData?.workPlaceType === "Hybrid"}
            </p>
            <p className="mb-0">
              <small>Work Place Type</small>
            </p>
          </div>
        </div>
      )}

      {jobData?.experienceLevel !== 0 && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-file-user"></i>
          </div>
          <div className="ml-2">
            <p className=" mb-0">{jobData?.experienceLevel}</p>
            <p className="mb-0">
              <small>Experience</small>
            </p>
          </div>
        </div>
      )}
      {jobData?.highestEducation && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-file-user"></i>
          </div>
          <div className="ml-2">
            <p className=" mb-0">{jobData?.highestEducation}</p>
            <p className="mb-0">
              <small>Education</small>
            </p>
          </div>
        </div>
      )}

      {jobData?.tenantname && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-building fa-fw"></i>
          </div>
          <div className="ml-2">
            <p className=" mb-0">{jobData?.tenantname}</p>
            <p className="mb-0">
              <small>Client</small>
            </p>
          </div>
        </div>
      )}
      {jobData?.visaStatus && (
        <div className="d-flex mb-2 pl-1">
          <div>
            <i className="fad fa-plane-departure"></i>
          </div>
          <div className="ml-2">
            {jobData?.visaStatus && (
              <>
                <span className="d-flex align-items-center">
                  <ul style={{ marginBottom: "5px" }}>
                    {JSON.parse(jobData?.visaStatus).map(
                      (item, index) =>
                        (index < 2 || viewAll) && (
                          <li key={index} className="tag tag-blue3 mr-1 mt-1 pt-1 px-2">
                            {item}
                          </li>
                        )
                    )}
                    {jobData?.visaStatus &&
                      (viewAll ? (
                        <button
                          className="btn btn-text"
                          onClick={() => setViewAll(false)}
                          style={{ margin: "5px", cursor: "pointer" }}
                        >
                          View Less
                        </button>
                      ) : (
                        JSON.parse(jobData?.visaStatus).length > 2 && (
                          <button
                            className="btn btn-text"
                            onClick={() => setViewAll(true)}
                            style={{ margin: "5px", cursor: "pointer" }}
                          >
                            View All
                          </button>
                        )
                      ))}
                  </ul>
                </span>
                <p className="mb-0">
                  <small>Work Authorization Requirement</small>
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default GenaralDetails;
