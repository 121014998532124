import React, { useEffect, useState, useRef } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Loader";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";
import ClientCard from "./ClientCards";
import {
  getCuratorData,
  getCuratorDataCount,
} from "../../../actions/curationtasks/GetCuratorClients";
import { getUserId } from "../../../service/AuthService";
import NoRecord from "../commoncomponents/norecord/NoRecord";
import Sort from "../commoncomponents/sort/Index";
import { curatorClientSortObject, orderList } from "../pagedata/PageData";
import Pagination from "../pagination/pagination";

const CurationTask = () => {
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);

  const [curatorTaskData, setCuratorTaskData] = useState([]);
  const [total, setTotal] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState("");

  //sort
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortType, setSorttype] = useState([]);
  const [sortApplied, setSortApplied] = useState(false);

  const {
    curatorClientsData,
    loading,
    curatorClientsDataCount,
    curatorCountLoading,
  } = useSelector(
    (state) => ({
      curatorClientsData: state.getCuratorClientDataReducer.curatorClientsData,
      loading: state.getCuratorClientDataReducer.loading,
      curatorClientsDataCount:
        state.getCuratorClientDataReducer.curatorClientsDataCount,
      curatorCountLoading:
        state.getCuratorClientDataReducer.curatorCountLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    const tempArray = curatorClientSortObject.map((item) => item.value);
    setSorttype(tempArray);
  }, []);

  useEffect(() => {
    fetchData(currentPage, pageSize, searchData, sortArray);
  }, []);

  useEffect(() => {
    if (curatorClientsData) setCuratorTaskData(curatorClientsData);
  }, [curatorClientsData]);

  useEffect(() => {
    if (curatorClientsDataCount)
      setTotal(curatorClientsDataCount[0]?.curatorClientCount);
  }, [curatorClientsDataCount]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    const delay = 500;
    const debounceTimer = setTimeout(() => {
      if (searchData.length === 0 || searchData.length >= 2) {
        fetchData(1, pageSize, searchData, sortArray);
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchData]);

  const fetchData = (currentPage, pageSize, searchData, sortArray) => {
    let paramObj = {
      userId: getUserId(),
      sortQuery: sortArray,
      currentPage: currentPage,
      searchText: searchData,
      pageSize: pageSize,
    };
    dispatch(getCuratorData(paramObj));
    const payload = {
      userId: getUserId(),
      searchText: searchData,
    };
    dispatch(getCuratorDataCount(payload));
  };

  const handleClient = (type) => {
    // Implement client handling logic here
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchData(value);
  };

  const applySort = (sortedData) => {
    setCurrentPage(1);
    fetchData(1, pageSize, searchData, sortedData);
  };

  const sortClearAll = () => {
    setSortArray([]);
    const tempArray = curatorClientSortObject?.map((item) => item.value);
    setSorttype(tempArray);
    setSortCount(0);
    setSortApplied(false);
    fetchData(1, pageSize, searchData, []);
    setSort(false);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, pageSize, searchData, sortArray);
  };

  return (
    <>
      {loading && <Loader />}
      <div className="w-100" style={{ minHeight: "100vh" }}>
        <div className="d-flex align-items-center justify-content-between p-1 mb-1 border-bottom-gray2">
          <div className="d-flex">
            <h6>Clients</h6>
          </div>
          <div className="d-flex mb-2 align-items-center justify-content-center justify-content-even-400">
            <div className="d-flex justify-content-end justify-content-even-400">
              <div className="dropdown-sort dd-sort">
                <div>
                  <Sort
                    sort={sort}
                    setSort={setSort}
                    sortCount={sortCount}
                    sortData={curatorTaskData}
                    orderArray={orderList}
                    sortObjects={curatorClientSortObject}
                    applySort={applySort}
                    setSortCount={setSortCount}
                    setSortArray={setSortArray}
                    sortArray={sortArray}
                    sortingFor={"Sort by Client, Country, and created On"}
                    sortType={sortType}
                    setSorttype={setSorttype}
                    sortApplied={sortApplied}
                    setSortApplied={setSortApplied}
                    sortClearAll={sortClearAll}
                  />
                </div>
              </div>
              <div className="d-block mr-2">
                <div className="dropdown">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Client Name"
                    style={{ width: "275px" }}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {curatorTaskData.length > 0 ? (
          <div className="grid-container mt-3 mb-3">
            {curatorTaskData?.map((client) => (
              <ClientCard
                key={client.tenantname}
                client={client}
                handleClient={handleClient}
              />
            ))}
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            <NoRecord />
          </div>
        )}

        <hr className="m-0" />
        {curatorTaskData.length > 0 && (
          <Pagination
            currentPage={currentPage}
            totalItems={total}
            itemsPerPage={pageSize}
            onPageChange={handlePageChange}
            limit={10}
          />
        )}

        {getTenantType() === 1 && (
          <Footer style={{ position: "relative", bottom: "0px" }} />
        )}
      </div>
    </>
  );
};

export default CurationTask;
