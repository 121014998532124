import React, { useState, useEffect, useMemo } from "react";
import Select from "react-select";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import Validator from "email-validator";
import { AsYouType } from "libphonenumber-js";
import { Form, Container, Row, Col } from "react-bootstrap";
import {
  getUserTenantID,
  getTenantType,
} from "../../../../service/AuthService";
import Loader from "../../../Loader/Loader";
import { post } from "../../../../service/ApiService";
import { getSubcategoryDetails } from "../../../../actions/common/GetSubcategoryDetails";
import { getSubCommunity } from "../../../../actions/common/GetSubCommunity";

function AddEmployee(props) {
  const dispatch = useDispatch();
  const clientid = getUserTenantID();

  const [form, setForm] = useState({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    phoneCode: "",
    clientId: clientid,
    role: "",
    designation: "",
    hostedUrl: "",
    isAdmin: false,
    industries: null,
    subCategory: null,
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [activeRoles, setActiveRoles] = useState([]);
  const [phoneValidation, setPhoneValidation] = useState(true);
  const [industriesOptions, setIndustriesOptions] = useState([]);
  const [subCategoryOptions, setSubCategoryOptions] = useState([]);
  const [subCommunityValues, setSubCommunityValues] = useState([]);
  const [subCommunityOptions, setSubCommunityOptions] = useState([]);

  const {
    categoryLoading,
    subCategoryDetails,
    subCommunityLoading,
    subCommunityDetails,
  } = useSelector(
    (state) => ({
      categoryLoading: state.getSubcategoryReducer.categoryLoading,
      subCategoryDetails: state.getSubcategoryReducer.subCategory,
      subCommunityLoading: state.getSubCommunityReducer.loading,
      subCommunityDetails: state.getSubCommunityReducer.subCommunity,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getSubcategoryDetails());
    dispatch(getSubCommunity());
  }, []);

  const customStyles = {
    // Add custom styles here
    control: (provided) => ({
      ...provided,
      borderColor: errors.subCommunity ? "red" : provided.borderColor,
    }),
    input: (provided) => ({
      ...provided,
      zIndex: 1000, // Set z-index to -1000
    }),
  };

  useEffect(() => {
    if (subCommunityDetails && subCommunityDetails.length > 0) {
      let subCommunityOption = [
        { value: "0", label: "None", isDisabled: false },
      ];

      subCommunityOption = [
        ...subCommunityOption, // Spread the default option
        ...subCommunityDetails.map((item) => ({
          value: item.poolId,
          label: `${item.poolname} (${item.poolId})`,
          isDisabled: false,
        })),
      ];
      setSubCommunityOptions(subCommunityOption);
    }
  }, [subCommunityDetails]);

  useEffect(() => {
    if (
      subCategoryDetails &&
      subCategoryDetails?.industry &&
      subCategoryDetails?.industry?.length > 0
    ) {
      let industriesData = [];
      let subCategoryData = [];

      subCategoryDetails?.industry.forEach((element) => {
        industriesData = [
          ...industriesData,
          {
            value: element,
            label: element,
          },
        ];
        subCategoryData = [
          ...subCategoryData,
          {
            value: element,
            label: element,
          },
        ];
      });
      setForm({
        ...form,
        industries: subCategoryDetails?.industry,
        subCategory: subCategoryDetails?.subCategory,
      });
      setSubCategoryOptions(subCategoryData);
      setIndustriesOptions(industriesData);
    }
  }, [subCategoryDetails]);

  const fetchData = async () => {
    try {
      let Roles = [];
      if (getTenantType() == 1) {
        setForm({
          ...form,
          role: "HiringManager",
        });
        Roles = ["HiringManager"];
      }
      if (getTenantType() == 2) {
        setForm({
          ...form,
          role: "Recruiter",
        });
        Roles = ["Recruiter"];
      }
      setActiveRoles(Roles);
    } catch (error) {
      console.log(error);
    }
  };

  const findFormErrors = () => {
    const {
      lastName,
      email,
      firstName,
      role,
      designation,
      industries,
      subCategory,
      isAdmin,
    } = form;
    const newErrors = {};
    if (!firstName || firstName === "") newErrors.firstName = "*Required field";
    else if (!/^[A-Z a-z\s]+$/.test(firstName))
      newErrors.firstName = "Invalid First Name ";
    if (!designation || designation === "")
      newErrors.designation = "*Required field";
    else if (!/^[A-Z a-z\s]+$/.test(designation))
      newErrors.designation = "Invalid Designation ";
    if (!role || role === "") newErrors.role = "*Required field";
    if (!activeRoles.includes(role)) newErrors.role = "Role is not Active !";
    if (!lastName || lastName === "") newErrors.lastName = "*Required field";
    else if (!/^[A-Z a-z\s]+$/.test(lastName))
      newErrors.lastName = "Invalid Last Name ";
    if (!email || email === "") newErrors.email = "*Required field";
    else if (!Validator.validate(email)) newErrors.email = "Invalid Email id !";
    if (!phoneValidation) {
      newErrors.phone = "Invalid Phone";
    }
    if ((!industries || industries.length < 1) && getTenantType() == 2)
      newErrors.industries = "Please Select Job Category";
    if ((!subCategory || subCategory.length < 1) && getTenantType() == 2)
      newErrors.subCategory = "Please Select Sub Category";
    if (
      (!subCommunityValues || subCommunityValues.length < 1) &&
      getTenantType() == 2 &&
      !isAdmin
    )
      newErrors.subCommunity = "*Required field";

    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      try {
        setLoading(true);
        const paramters = {
          ...form,
          industries: JSON.stringify(form?.industries),
          subCategory: JSON.stringify(form?.subCategory),
          subCommuntity:
            getTenantType() == 2
              ? form?.isAdmin
                ? []
                : subCommunityValues.includes("0")
                ? []
                : JSON.stringify(subCommunityValues.map((item) => item.value))
              : [],
        };
        const res = await post("/employee/addemployee", paramters);
        setLoading(false);
        if (res.status === 200) {
          const result = res.data;
          if (result.ReturnText == "Successfully added") {
            toast.success("User added successfully ");
            const empdata = {
              name: form.firstName + " " + form.lastName,
              email: form.email,
              designation: form.role,
            };
            props.submitfun(empdata);
          } else if (result.MessageText === "User Exist") {
            toast.error("User alredy exist with this email id");
          } else {
            toast.error("Error Occured while saving User.");
          }
        }
      } catch (error) {
        setLoading(false);
        toast.error("Error Occured While saving User.");
      }
    }
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const setPhone = (phone, phoneCode) => {
    setForm({
      ...form,
      phone: phone,
      phoneCode: phoneCode,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const _onChangeText = (value, code) => {
    const asYouType = new AsYouType(code);
    asYouType.input(value);
    setPhoneValidation(asYouType.isValid());
  };

  const handleSubcummunity = (e) => {
    if (e.length > 0) {
      const updatedOptions = subCommunityOptions.map((element) => {
        if (e[0].value === "0") {
          if (element.value !== "0") {
            return {
              ...element,
              isDisabled: true,
            };
          }
        } else {
          if (element.value === "0") {
            return {
              ...element,
              isDisabled: true,
            };
          }
        }
        return element;
      });
      setSubCommunityOptions(updatedOptions);
      setSubCommunityValues(e);
    } else {
      const updatedOptions = subCommunityOptions.map((element) => {
        return {
          ...element,
          isDisabled: false,
        };
      });
      setSubCommunityOptions(updatedOptions);
      setSubCommunityValues(e);
    }
  };

  return (
    <>
      {(loading || categoryLoading || subCommunityLoading) && <Loader />}
      <div>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="form-floating mb-2">
              <Form.Control
                as="select"
                onChange={(e) => setField("role", e.target.value)}
                isInvalid={!!errors.role}
                value={form.role}
                disabled={activeRoles.includes("HiringManager")}
              >
                {activeRoles.includes("HiringManager") && (
                  <option value="HiringManager">Hiring Manager</option>
                )}
                {activeRoles.includes("Recruiter") && (
                  <option value="Recruiter">Recruiter</option>
                )}
              </Form.Control>
              <label>
                Role<span className="font-red font-small">*</span>
              </label>
              <Form.Control.Feedback>{errors.role}</Form.Control.Feedback>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
            <div className="form-floating">
              <input
                type="text"
                className="form-control mr-2 "
                style={{
                  border: errors.designation && "1px solid #ff0000",
                }}
                name="firstName"
                value={form.designation}
                onChange={(e) => setField("designation", e.target.value)}
                placeholder="First Name*"
              />
              <label>Designation*</label>
            </div>
            <small className="validation">{errors.designation}</small>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
            <div className="form-floating">
              <input
                type="text"
                className="form-control mr-2 "
                style={{
                  border: errors.firstName && "1px solid #ff0000",
                }}
                value={form.firstName}
                onChange={(e) => setField("firstName", e.target.value)}
                placeholder="First Name*"
              />
              <label>First Name*</label>
            </div>
            <small className="validation">{errors.firstName}</small>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
            <div className="form-floating">
              <input
                type="text"
                className="form-control mr-2 "
                style={{
                  border: errors.lastName && "1px solid #ff0000",
                }}
                value={form.lastName}
                onChange={(e) => setField("lastName", e.target.value)}
                placeholder="Last Name*"
              />
              <label>Last Name*</label>
            </div>
            <small className="validation">{errors.lastName}</small>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
            <div className="form-floating mb-2">
              <input
                type="text"
                className="form-control mr-2 "
                style={{
                  border: errors.email && "1px solid #ff0000",
                }}
                value={form.email}
                onChange={(e) => setField("email", e.target.value)}
                placeholder="Email ID*"
              />
              <label>Email ID*</label>
            </div>
            <small className="validation">{errors.email}</small>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 mb-2">
            <div className="form-floating">
              <PhoneInput
                classsName="form-control"
                placeholder="Phone"
                country={"us"}
                name="Phone No"
                onChange={(value, country, e) => {
                  setPhone(value, country.dialCode);
                  _onChangeText(
                    value.replace(country.dialCode, ""),
                    country.countryCode.toUpperCase()
                  );
                }}
                isInvalid={!!errors.phone}
                inputStyle={{
                  width: "260px",
                  height: "56px",
                }}
              />
            </div>
            {!phoneValidation && (
              <small className="validation">{"Invalid Phone"}</small>
            )}
          </div>
          {getTenantType() == 2 && (
            <Container>
              <Row>
                <Col>
                  <label className="recruitemantLabel">Job Category*</label>

                  <div
                    className="form-floating hover-Dropdown"
                    style={{ zIndex: "999" }}
                  >
                    <Select
                      isMulti
                      options={industriesOptions}
                      value={industriesOptions}
                      name="Industry"
                      isDisabled={true}
                    ></Select>
                  </div>
                </Col>
                <Col>
                  <label className="recruitemantLabel">Sub category*</label>

                  <div
                    className="form-floating hover-Dropdown"
                    style={{ zIndex: "999" }}
                  >
                    <Select
                      isMulti
                      options={subCategoryOptions}
                      value={subCategoryOptions}
                      name="subCategory"
                      isDisabled={true}
                    ></Select>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
          {getTenantType() == 2 && !form.isAdmin && (
            <Container>
              <Row>
                <Col>
                  <label className="recruitemantLabel">Community*</label>
                  <div
                    className="form-floating hover-Dropdown"
                    style={{ zIndex: "999" }}
                  >
                    <Select
                      isMulti
                      options={subCommunityOptions}
                      value={subCommunityValues}
                      isSearchable={true}
                      styles={customStyles}
                      name="subCommunity"
                      onChange={handleSubcummunity}
                    ></Select>
                  </div>
                  <small className="validation">{errors.subCommunity}</small>
                </Col>
              </Row>
            </Container>
          )}

          {/* <div
            class="form-switch d-flex align-items-center mt-2"
            style={{
              padding: "1em",
            }}
          >
            <label class="form-check-label pt-0">Can Add Users?</label>
            <input
              class="form-check-input ml-1"
              type="checkbox"
              onChange={(e) => setField("isAdmin", e.target.checked)}
            />
          </div> */}
        </div>

        <div className="row">
          <div className="modal-footer mt-4 mb-0">
            <button
              type="button"
              data-dismiss="modal"
              className="btn btn-secondary mr-2"
              onClick={() => props.close()}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
            >
              <span>Submit</span>{" "}
            </button>{" "}
          </div>
        </div>
      </div>
    </>
  );
}
export default AddEmployee;
