import React from "react";

function Sort(props) {
  const {
    orderArray,
    sortObjects,
    sortArray,
    sortingFor,
    apply,
    sortType,
    addSortHandle,
    handleSortValue,
    deleteSort,
    sortClearAll,
    sortApplied,
    setSortApplied,
  } = props;

  return (
    <div class="dropdown-sort-menu dd-sort">
      <div class="dd-sort-header d-flex justify-content-between align-items-center dd-sort">
        <div>
          <h6>Sort</h6>
        </div>
        <div>
          {sortArray.length > 0 && (
            <button
              type="button"
              // disabled={!sortApplied}
              className="btn btn-text dd-sort"
              onClick={sortClearAll}
            >
              <strong className="dd-sort">Clear All</strong>
            </button>
          )}
        </div>
      </div>

      <div className="dd-sort-body">
        {sortArray.length > 0 ? (
          sortArray.map((item, i) => (
            <div className="d-flex align-items-center justify-content-end mt-3">
              <div className="dropdown dropdown-select mr-2 dd-sort w-100">
                <div class="row mb-2">
                  <div class="col-lg-6 mb-2">
                    <div class="form-floating-sm">
                      <select
                        class="form-select"
                        id="sortValue"
                        value={item.sortValue}
                        onChange={(e) => handleSortValue(e, i)}
                      >
                        <option value="" selected>
                          Select
                        </option>
                        {sortObjects?.map((val) => (
                          <option
                            value={val.value}
                            hidden={sortType && !sortType?.includes(val.value)}
                          >
                            {val.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-5 mb-2">
                    <div class="form-floating-sm">
                      <select
                        class="form-select"
                        id="order"
                        value={item.order}
                        onChange={(e) => handleSortValue(e, i)}
                      >
                        {orderArray.map((val) => (
                          <>
                            <option value={val.value}>{val.label}</option>
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="col-lg-1 mb-2" style={{ padding: "8px 0px" }}>
                    <button
                      type="button"
                      className="btn btn-text dd-sort"
                      onClick={() => deleteSort(item)}
                    >
                      <strong className="dd-sort font-muted">
                        <i className="fas fa-times-circle dd-sort"></i>
                      </strong>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="mb-0 py-3 dd-sort">{sortingFor}</p>
        )}
      </div>
      <div className="d-flex">
        <button
          type="button"
          className="btn btn-text mt-1 dd-sort"
          onClick={addSortHandle}
          disabled={sortArray.length === sortObjects.length}
        >
          <i className="fal fa-plus mr-2 dd-sort" aria-hidden="true"></i>
          <strong className="dd-sort">Add Sort</strong>
        </button>
        {sortArray.length > 0 && (
          <div className="p-relative ml-auto mt-1 mr-2">
            <div className="d-flex">
              <button
                onClick={apply}
                type="button"
                className="btn btn-sm btn-primary"
              >
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Sort;
