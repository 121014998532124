import {
  FETCH_ALL_JOB_BEGIN,
  FETCH_ALL_JOB_SUCCESS,
  FETCH_ALL_JOB_FAILURE,
  UPDATE_JOB,
  FETCH_FILTERS_BEGIN,
  FETCH_FILTERS_SUCCESS,
  FETCH_FILTERS_FAILURE,
  FETCH_JOB_TITLE_FILTERS_BEGIN,
  FETCH_JOB_TITLE_FILTERS_SUCCESS,
  FETCH_JOB_TITLE_FILTERS_FAILURE,
  FETCH_JOB_ID_FILTERS_BEGIN,
  FETCH_JOB_ID_FILTERS_SUCCESS,
  FETCH_JOB_ID_FILTERS_FAILURE,
  FETCH_JOB_LOCATION_FILTERS_BEGIN,
  FETCH_JOB_LOCATION_FILTERS_SUCCESS,
  FETCH_JOB_LOCATION_FILTERS_FAILURE,
  EXISTING_JOB_TITLE_FILTERS_BEGIN,
  EXISTING_JOB_TITLE_FILTERS_SUCCESS,
  EXISTING_JOB_TITLE_FILTERS_FAILURE,
  EXISTING_JOB_LOCATION_FILTERS_BEGIN,
  EXISTING_JOB_LOCATION_FILTERS_SUCCESS,
  EXISTING_JOB_LOCATION_FILTERS_FAILURE,
} from "../../actions/jobList/jobList";

const initialState = {
  loading: false,
  jobList: null,
  selectedJob: [],
  filtersList: null,
  jobTitleFilters: null,
  jobIdFilters: null,
  jobLocationFilters: null,
  existingJobTitleFilter: null,
  existingJobLocationFilter: null,
};

const getJobListReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_JOB:
      return {
        ...state,
        selectedJob: payload,
      };
    case FETCH_ALL_JOB_BEGIN:
      return {
        ...state,
        loading: true,
        jobList: null,
      };
    case FETCH_ALL_JOB_SUCCESS:
      return {
        ...state,
        jobList: payload,
        loading: false,
      };
    case FETCH_ALL_JOB_FAILURE:
      return {
        ...state,
        jobList: payload,
        loading: false,
      };
    case FETCH_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        filtersList: null,
      };
    case FETCH_FILTERS_SUCCESS:
      return {
        ...state,
        filtersList: payload,
        loading: false,
      };
    case FETCH_FILTERS_FAILURE:
      return {
        ...state,
        filtersList: payload,
        loading: false,
      };
    case FETCH_JOB_TITLE_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        jobTitleFilters: null,
      };
    case FETCH_JOB_TITLE_FILTERS_SUCCESS:
      return {
        ...state,
        jobTitleFilters: payload,
        loading: false,
      };
    case FETCH_JOB_TITLE_FILTERS_FAILURE:
      return {
        ...state,
        jobTitleFilters: payload,
        loading: false,
      };
    case FETCH_JOB_ID_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        jobIdFilters: null,
      };
    case FETCH_JOB_ID_FILTERS_SUCCESS:
      return {
        ...state,
        jobIdFilters: payload,
        loading: false,
      };
    case FETCH_JOB_ID_FILTERS_FAILURE:
      return {
        ...state,
        jobIdFilters: payload,
        loading: false,
      };
    case FETCH_JOB_LOCATION_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        jobLocationFilters: null,
      };
    case FETCH_JOB_LOCATION_FILTERS_SUCCESS:
      return {
        ...state,
        jobLocationFilters: payload,
        loading: false,
      };
    case FETCH_JOB_LOCATION_FILTERS_FAILURE:
      return {
        ...state,
        jobLocationFilters: payload,
        loading: false,
      };

    //existing jobs
    case EXISTING_JOB_TITLE_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        existingJobTitleFilter: null,
      };
    case EXISTING_JOB_TITLE_FILTERS_SUCCESS:
      return {
        ...state,
        existingJobTitleFilter: payload,
        loading: false,
      };
    case EXISTING_JOB_TITLE_FILTERS_FAILURE:
      return {
        ...state,
        existingJobTitleFilter: payload,
        loading: false,
      };
    case EXISTING_JOB_LOCATION_FILTERS_BEGIN:
      return {
        ...state,
        loading: true,
        existingJobLocationFilter: null,
      };
    case EXISTING_JOB_LOCATION_FILTERS_SUCCESS:
      return {
        ...state,
        existingJobLocationFilter: payload,
        loading: false,
      };
    case EXISTING_JOB_LOCATION_FILTERS_FAILURE:
      return {
        ...state,
        existingJobLocationFilter: payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default getJobListReducer;
