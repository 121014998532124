import React, { useState } from "react";
import { toast } from "react-toastify";
import { Form } from "react-bootstrap";
import HereMape from "../../location/CommonHereMap";
import { post } from "../../../service/ApiService";
import { domain } from "../../../constants/Constants";
import { getUserRole } from "../../../service/AuthService";
import Loader from "../../Loader/Loader";
import Validator from "email-validator";
import PhoneInput from "react-phone-input-2";
import { AsYouType } from "libphonenumber-js";

function AddUser(props) {
  const [formComplete, setFormComplete] = useState(false);
  const [form, setForm] = useState({
    lastname: "",
    email: "",
    name: "",
    User: "",
    Usertype: "",
    stateName: "",
    CountryName: "",
    cityName: "",
    address: "",
    zipCode: "NA",
    phone: "",
    phoneCode: "",
  });
  const [errors, setErrors] = useState({});
  const [submitClicked, setSubmitClicked] = useState(false);
  let [type, settype] = useState("");
  const [loading, setLoading] = useState(false);
  const [phoneValidation, setPhoneValidation] = useState(true);
  const findFormErrors = () => {
    const {
      lastname,
      email,
      name,
      Usertype,
      zipCode,
      cityName,
      stateName,
      CountryName,
    } = form;

    const newErrors = {};
    if (!name || name === "") newErrors.name = "First Name  cannot be blank!";
    else if (!/^[A-Z a-z\s]+$/.test(name))
      newErrors.name = "Invalid First Name ";
    if (!Usertype || Usertype === "")
      newErrors.Usertype = "User Type cannot be Blank";
    if (!lastname || lastname === "")
      newErrors.lastname = "Last Name  cannot be blank!";
    else if (!/^[A-Z a-z\s]+$/.test(lastname))
      newErrors.lastname = "Invalid Last Name ";
    // if (!zipCode || zipCode === "")
    //   newErrors.zipCode = "ZipCode cannot be Blank";
    // else if (!/^[0-9a-zA-Z ]+$/.test(zipCode))
    //   newErrors.zipCode = "Invalid ZipCode";
    if (!cityName || cityName === "")
      newErrors.cityName = "City cannot be Blank";
    else if (!/^[A-Z a-z\s]+$/.test(cityName))
      newErrors.cityName = "Invalid City Name ";
    if (!stateName || stateName === "")
      newErrors.stateName = "State cannot be Blank";
    else if (!/^[A-Z a-z\s]+$/.test(stateName))
      newErrors.stateName = "Invalid State Name ";
    if (!CountryName || CountryName === "")
      newErrors.CountryName = "Country cannot be Blank";
    else if (!/^[A-Z a-z\s]+$/.test(CountryName))
      newErrors.CountryName = "Invalid Country Name ";
    if (!email || email === "") newErrors.email = "Email id cannot be Blank";
    else if (!Validator.validate(email)) newErrors.email = "Invalid Email id !";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // get our new errors
    const newErrors = findFormErrors();
    // Conditional logic:
    if (Object.keys(newErrors).length > 0) {
      // We got errors!
      setErrors(newErrors);
      //  alert("please fill all details ")
    } else if (
      !phoneValidation &&
      (form.Usertype === "HC" || form.Usertype === "CM")
    ) {
      toast.error("Please enter a valid phone number");
    } else {
      // No errors! Put any logic here for the form submission!
      try {
        setLoading(true);
        const paramters = {
          ...form,
          domainName: domain,
        };
        const res = await post("/users/saveadminuser", paramters);
        if (res.status === 200) {
          setLoading(false);
          const result = res.data;
          if (result.InsertDescription == "EmailId Already Exist") {
            toast.error("User is already present");
          } else if (result.userdata.RowsInserted == 1) {
            props.getusersData();
            props.closeAdd(true);
            toast.success("User added successfully ");
          } else {
            props.closeAdd(true);
            toast.error("Error Occured while saving User Details ");
          }
        }
      } catch (error) {
        setLoading(false);
        props.closeAdd(true);
        toast.error("Error Occured While saving  User  Details");
      }
    }
    // api call
  };
  const _onChangeText = (value, country) => {
    const asYouType = new AsYouType(country?.countryCode?.toUpperCase());
    asYouType.input(value.replace(country?.dialCode, ""));
    const isValid = asYouType.isValid();
    setPhoneValidation(isValid);
  };

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
    // Check and see if errors exist, and remove them from the error object:
    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };
  const close = () => {
    props.closeAdd(true);
  };

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.id]: e.target.value,
    });
    //const contactInfoVal = profileContactInfoValidation(contactInfo);
  };

  //console.log("props", props)
  if (props.userlist) {
    type = props.userlist.type;
  }

  const role = getUserRole();

  return (
    <>
      {loading && <Loader />}
      <div class="bl-modal reveal-overlay reveal-card">
        <div class="bl-modal-overlay"></div>
        <div class="bl-modal-container">
          <div class="bl-modal-card bl-modal-card-lg">
            <div class="bl-modal-header">
              <h6 v-if="title" class="bl-modal-title">
                {props.userlist
                  ? "Add User"
                  : props.addusersCondition === true
                  ? "Add User"
                  : "Add Tenant"}
              </h6>
              <button
                type="button"
                class="close p-0 bl-modal-close-btn"
                data-dismiss="modal"
                aria-label="Close"
                onClick={close}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="bl-modal-body pb-0">
              <div class="card card-flat card-borderless bg-gray4 text-center">
                <div class="card-body">
                  <div class="container">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            as="select"
                            onChange={(e) =>
                              setField("Usertype", e.target.value)
                            }
                            isInvalid={!!errors.Usertype}
                          >
                            <option id="" value="">
                              Select User Type
                            </option>
                            {role == "SuperAdmin" && (
                              <option id="CS" value="CS">
                                High5 Admin
                              </option>
                            )}
                            <option id="HC" value="HC">
                              Customer Success Manager
                            </option>
                            {role !== "SalesAdmin" && (
                              <option id="CM" value="CM">
                                Marketplace Manager
                              </option>
                            )}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {errors.Usertype}
                          </Form.Control.Feedback>
                          <label>User Type*</label>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter email "
                            onChange={(e) => setField("email", e.target.value)}
                            isInvalid={!!errors.email}
                          />
                          <label>Email ID*</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter First Name"
                            onChange={(e) => setField("name", e.target.value)}
                            isInvalid={!!errors.name}
                          />
                          <label>First Name*</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.name}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <Form.Control
                            type="text"
                            placeholder="Enter Last Name"
                            onChange={(e) =>
                              setField("lastname", e.target.value)
                            }
                            isInvalid={!!errors.lastname}
                          />
                          <label>Last Name*</label>
                          <Form.Control.Feedback type="invalid">
                            {errors.lastname}
                          </Form.Control.Feedback>
                        </div>
                      </div>
                    </div>{" "}
                    {(form.Usertype === "HC" ||
                      form.Usertype === "CM" ||
                      form.Usertype === "CS") && (
                      <div className="row mt-4 ">
                        <div className="col-lg-12">
                          <div className="">
                            <PhoneInput
                              inputClass={"form-control font-14 w-100"}
                              required="true"
                              placeholder="mobile"
                              id="homePhone"
                              country={"us"}
                              value={form.phone}
                              name="homePhone"
                              onChange={(value, country, e) => {
                                _onChangeText(value, country);
                                setForm({
                                  ...form,
                                  phone: value,
                                  phoneCode: country.dialCode,
                                });
                              }}
                            />
                          </div>
                          {!phoneValidation && (
                            <small className="validation">Invalid Phone</small>
                          )}
                        </div>
                      </div>
                    )}
                    <div class="row mt-4">
                      <div className="form-floating">
                        <HereMape setForm={setForm} form={form} />
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-12">
                          <label className="mb-2"> Address </label>
                        </div>
                      </div>
                      <div className="row mb-2">
                        {/* <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              placeholder="Enter zipcode "
                              id="zipCode"
                              value={form.zipCode}
                              onChange={(e) =>
                                setField("zipCode", e.target.value)
                              }
                              isInvalid={!!errors.zipCode}
                            />
                            <label>Zipcode* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.zipCode}
                            </Form.Control.Feedback>
                          </div>
                        </div> */}
                        <div className="col-lg-12">
                          <div className="form-floating">
                            <input
                              type="text"
                              className="form-control"
                              id="address"
                              placeholder="Address"
                              value={form.address}
                              onChange={handleChange}
                              isInvalid={!!errors.address}
                            />
                            <label>Address</label>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-2">
                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="addressCity"
                              placeholder="Address City"
                              value={form.cityName}
                              onChange={(e) =>
                                setField("cityName", e.target.value)
                              }
                              isInvalid={!!errors.cityName}
                            />
                            <label>City* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.cityName}
                            </Form.Control.Feedback>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="stateName"
                              placeholder="Address State"
                              value={form.stateName}
                              onChange={(e) =>
                                setField("stateName", e.target.value)
                              }
                              isInvalid={!!errors.stateName}
                            />
                            <label> State* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.stateName}
                            </Form.Control.Feedback>
                          </div>
                        </div>

                        <div className="col-lg-4">
                          <div className="form-floating">
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="CountryName"
                              placeholder="Address Country"
                              value={form.CountryName}
                              onChange={handleChange}
                              isInvalid={!!errors.CountryName}
                            />
                            <label> Country* </label>
                            <Form.Control.Feedback type="invalid">
                              {errors.CountryName}
                            </Form.Control.Feedback>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div v-bind:class="{ 'd-none': formComplete }" class="modal-footer mt-4 mb-0 d-none"> */}
            <div class="modal-footer mt-4 mb-0">
              <button
                type="button"
                class="btn btn-secondary mr-2"
                data-dismiss="modal"
                onClick={close}
              >
                Cancel
              </button>
              <button
                type="button"
                class="btn btn-primary"
                onClick={handleSubmit}
              >
                {!submitClicked && !formComplete && <span>Submit</span>}

                {submitClicked && (
                  <span>
                    <span>Adding Users</span>
                    <img width="20px" src="/images/button-loader.gif" />
                  </span>
                )}
                {formComplete && !submitClicked && (
                  <span>
                    <span>Users Added</span>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddUser;
