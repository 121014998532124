import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const SelectSMSTemplate = (props) => {
  const {
    currentTemp,
    setTemplate,
    setActiveTab,
    setSelectedOption,
    setSmsContent,
  } = props.data;
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [templateSubject, setTemplateSubject] = useState("");
  const [templateBody, setTemplateBody] = useState("");
  const [templateSignature, setTemplateSignature] = useState("");
  const [errorData, setErrorData] = useState({});

  useEffect(() => {
    setSelectedTemplate(currentTemp);
    setTemplateSubject(currentTemp?.subject);
    setTemplateBody(currentTemp?.mailContent + currentTemp?.signature);
    setTemplateSignature("");
  }, []);

  const onBodyChange = (newBody) => {
    setTemplateBody(newBody);
  };

  const validationFunction = () => {
    let errorObj = {};
    let isValid = true;
    // if (templateSignature === "" || templateSignature === "<p><br></p>") {
    //   errorObj["templateSignature"] = "Please enter signature";
    //   isValid = false;
    // }
    if (templateBody === "" || templateBody === "<p><br></p>") {
      errorObj["templateBody"] = "Please enter the message body ";
      isValid = false;
    }
    // if (templateSubject === "") {
    //   errorObj["templateSubject"] = "Please enter SMS subject ";
    //   isValid = false;
    // }
    setErrorData(errorObj);
    return isValid;
  };

  const handleApply = () => {
    let isValidObj = validationFunction();
    if (isValidObj) {
      setActiveTab("compose");
      setSelectedOption("compose");
      // setTemplate({
      //   Id_BroadcastTemplates: currentTemp.Id_BroadcastTemplates,
      //   subject: currentTemp.subject,
      //   mailContent: templateBody,
      //   signature: templateSignature,
      // });
      setSmsContent(templateBody);
      props.closeModal();
    } else {
      toast.error("Please  fill the mandatory fields");
    }
  };

  return (
    <div className="ml-3 mr-3">
      <div className="email-preview" style={{ overflowY: "auto" }}>
        {selectedTemplate && (
          <>
            <div>
              <h6>
                {" "}
                <strong>Select Template</strong>
              </h6>
            </div>
            {/* <div>
              <label>Subject*</label>
              <input
                type="text"
                value={templateSubject}
                disabled
                // onChange={(e) => onSubjectChange(e.target.value)}
              />
              <div className="color">{errorData["templateSubject"]}</div>
            </div> */}
            <div>
              <strong>Template Name: </strong>
              <span>{templateSubject}</span>
            </div>
            <div>
              <label>Message*</label>
              <ReactQuill value={templateBody} onChange={onBodyChange} />
              <div className="color">{errorData["templateBody"]}</div>
            </div>
          </>
        )}
      </div>
      <div className="mt-3 mb-2 text-right">
        <button
          onClick={() => {
            props.closeModal();
          }}
          className="button  mr-2"
        >
          Cancel
        </button>
        <button
          onClick={() => {
            handleApply();
          }}
          className="btn btn-primary mr-2"
        >
          Apply
        </button>
      </div>
    </div>
  );
};

export default SelectSMSTemplate;
