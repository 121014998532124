import React, { useEffect, useState } from "react";
import { Col, Modal, Nav, Row } from "react-bootstrap";
import { post } from "../../../../../service/ApiService";
import Loader from "../../../../Loader/Loader";
import { toast } from "react-toastify";
import { FaCommentAlt, FaEnvelope } from "react-icons/fa";
import { BsSpeedometer2, BsWhatsapp } from "react-icons/bs";
import { IoSpeedometerSharp } from "react-icons/io5";
import { BsSpeedometer } from "react-icons/bs";
import { IoLogoWhatsapp } from "react-icons/io";
import Email from "./Email/Email";
import SMS from "./SMS/SMS";
import ShortUniqueId from "short-unique-id";
import {
  getCandidateId,
  getUserRole,
  getUserTenantID,
} from "../../../../../service/AuthService";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  FetchEmailsByUserToCandidate,
  FetchLastMessageSentByUser,
  SendSms,
  sendWhatsAppMessage,
} from "../../../../../actions/communication/Communication";
import Overview from "./Overview";
import WhatsApp from "./WhatsApp/Compose";

const BulkCommunicationModal = ({
  modalSize,
  isModalOpen,
  setModalClose,
  parentModalClose,
  selectedTalents,
  isFrom,
  talentProfile,
  isCandidateFrom,
  poolId,
}) => {
  const dispatch = useDispatch();
  const { sendSMSLoading, whatsAppTemplateLoading } = useSelector(
    (state) => ({
      sendSMSLoading: state.communicationReducer.sendSMSLoading,
      whatsAppTemplateLoading:
        state.communicationReducer.whatsAppTemplateLoading,
    }),
    shallowEqual
  );
  const [loading, setLoading] = useState(false);
  const [method, setMethod] = useState(
    isCandidateFrom && isCandidateFrom !== "" ? isCandidateFrom : "overview"
  );
  const [previewEmail, setPreviewEmail] = useState(false);
  const [errorData, setErrorData] = useState({});
  const [emailContent, setEmailContent] = useState({
    subject: "",
    body: "",
    footer: "",
  });

  const [smsContent, setSmsContent] = useState(``);
  const [whatsAppContent, setWhatsAppContent] = useState(``);
  const [selectedRecipients, setSelectedRecipients] = useState([]);
  const uid = new ShortUniqueId({ length: 10 });

  const handleEmailContentChange = (newContent, key) => {
    if (emailContent[key] !== newContent) {
      setEmailContent({ ...emailContent, [key]: newContent });
    }
  };
  const handleMethodChnage = (type) => {
    setMethod(type);
  };

  const handleConfirmation = (text) => {
    let isValidObj = validationFunction();
    if (isValidObj) {
      text === "preview" ? setPreviewEmail(true) : handleBulkCommunication();
      setErrorData({});
    } else {
      toast.error("Please  fill the mandatory fields");
    }
  };

  const validationFunction = () => {
    let errorObj = {};
    let isValid = true;
    if (method === "email") {
      if (
        emailContent?.footer === "" ||
        emailContent?.footer === "<p><br></p>"
      ) {
        errorObj["footer"] = "Please enter email signature";
        isValid = false;
      }
      if (emailContent?.body === "" || emailContent?.body === "<p><br></p>") {
        errorObj["content"] = "Please enter the email body ";
        isValid = false;
      }
      if (emailContent?.subject === "") {
        errorObj["subject"] = "Please enter email subject ";
        isValid = false;
      }
    } else if (method === "sms") {
      if (smsContent === "" || smsContent === "<p><br></p>") {
        errorObj["msgContent"] = "Please enter the message content ";
        isValid = false;
      }
    } else {
      if (whatsAppContent === "" || whatsAppContent === "<p><br></p>") {
        errorObj["whatsAppContent"] = "Please enter the message content ";
        isValid = false;
      }
    }
    if (selectedRecipients?.length === 0) {
      errorObj["recipient"] = "Please enter at least one recipient";
      isValid = false;
    }
    setErrorData(errorObj);
    return isValid;
  };

  const sendMail = async () => {
    try {
      setLoading(true);
      let payload = {};
      if (poolId) {
        payload = {
          mailTo: selectedRecipients.map((t) => t.email),
          mailSubject: emailContent.subject,

          mailBody: emailContent.body + emailContent.footer,
          recipients: {
            recruiter: [],
            candidates: selectedRecipients.map((t) => t.email),
          },
          userId: getCandidateId(),
          broadCastId: uid(),
          poolId: poolId,
        };
      } else {
        payload = {
          mailTo: selectedRecipients.map((t) => t.email),
          mailSubject: emailContent.subject,

          mailBody: emailContent.body + emailContent.footer,
          recipients: {
            recruiter: [],
            candidates: selectedRecipients.map((t) => t.email),
          },
          userId: getCandidateId(),
          broadCastId: uid(),
        };
      }

      await post("/broadCast/sendEmail", payload).then((res) => {
        toast.success("Email sent Successfully.");
        onClose();
        setLoading(false);
      });
      if (talentProfile === "talentProfile") {
        const reqPayload = {
          userId: getCandidateId(),
          candidateEmail: selectedRecipients[0]?.email,
        };
        dispatch(FetchEmailsByUserToCandidate(reqPayload));
      }
    } catch (error) {
      setLoading(false);
    }
  };
  const sendSMS = async () => {
    if (smsContent === "") {
      toast.error("Message can not be empty!");
    }
    let recipientDetails = [];
    if (selectedRecipients?.length > 0) {
      let selectedData = selectedRecipients.filter(
        (t) => t.homePhoneCode === "+1" || t.homePhone.startsWith("+1")
      );
      selectedData?.map((item) => {
        recipientDetails.push({
          phone: item.homePhoneCode + item.homePhone,
          email: item.email,
        });
      });
    }
    // const updatedMailContent = editedValues.mailContent
    // .replace(/<p>/g, "<div>")
    // .replace(/<\/p>/g, "</div>");
    // Remove HTML content from smsContent
    console.log("hi");
    console.log("tenant id :", getUserTenantID());
    const updatedSmsContent = smsContent.replace(/<[^>]*>/g, "");
    let reqPayload = {
      recipients: recipientDetails,
      messageContent: updatedSmsContent,
      userId: getCandidateId(),
      broadCastId: uid(),
      messageSubject: "SMS Notification",
      userType: getUserRole(),
      fk_tenant: getUserTenantID(),
    };
    dispatch(SendSms(reqPayload));
    setSmsContent("");
  };
  console.log("talent profile :", talentProfile);
  const sendWhatsAppMessageAction = () => {
    if (whatsAppContent === "") {
      toast.error("Message can not be empty!");
    }

    let recipientDetails = [];
    let recipeintsData = [];
    if (selectedRecipients?.length > 0) {
      selectedRecipients?.map((item) => {
        let phone = item?.homePhoneCode + item.homePhone;
        phone = phone.split("+")[1];
        recipientDetails.push(phone);
        recipeintsData.push({ phone: phone, email: item.email });
      });
    }

    let reqPayload = {
      recipients: recipientDetails,
      message: whatsAppContent,
      userId: getCandidateId(),
      recipeintsData: recipeintsData,
    };
    dispatch(sendWhatsAppMessage(reqPayload));
    setWhatsAppContent("");
  };

  const handleBulkCommunication = () => {
    method === "email"
      ? sendMail()
      : method === "sms"
      ? sendSMS()
      : sendWhatsAppMessageAction();
  };
  const handleAddRecipients = (data) => {
    setSelectedRecipients([...data]);
  };
  const onClose = () => {
    setModalClose(false);

    if (isFrom === "TalentProfileEdit" && parentModalClose) {
      parentModalClose(false);
    }
  };

  return (
    <Modal
      size={"xl"}
      show={isModalOpen}
      id="communicationModal"
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <span id="communicationMainHeading">Communication</span>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="p-2 pt-0">
        {(loading || sendSMSLoading || whatsAppTemplateLoading) && <Loader />}

        <Row>
          <Col className=" col-sm-12 col-md-2">
            <Nav
              className="flex-column bg-white p-1 w-100 gap-0"
              //  style={{ width: "100%" }}
            >
              <Nav.Item>
                <Nav.Link
                  eventKey="overview"
                  onSelect={() => handleMethodChnage("overview")}
                  active={method === "overview"}
                  className={`tab-link ${
                    method === "overview" ? "active" : ""
                  }`}
                >
                  <BsSpeedometer size={20} className="tab-icon" /> Overview
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="email"
                  onSelect={() => handleMethodChnage("email")}
                  active={method === "email"}
                  className={`tab-link ${method === "email" ? "active" : ""}`}
                >
                  <FaEnvelope className="tab-icon" /> Email
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey="sms"
                  onSelect={() => handleMethodChnage("sms")}
                  active={method === "sms"}
                  className={`tab-link ${method === "sms" ? "active" : ""}`}
                >
                  <FaCommentAlt className="tab-icon" /> Message
                </Nav.Link>
              </Nav.Item>
              {/* <Nav.Item>
                <Nav.Link
                  eventKey="whatsApp"
                  onSelect={() => handleMethodChnage("whatsApp")}
                  active={method === "whatsApp"}
                  className={`tab-link ${
                    method === "whatsApp" ? "active" : ""
                  }`}
                >
                  <IoLogoWhatsapp size={18} className="tab-icon" />
                  WhatsApp
                </Nav.Link>
              </Nav.Item> */}
            </Nav>
          </Col>
          <Col className="col-sm-12 col-md-10">
            {method === "overview" && (
              <>
                <Overview setMethod={setMethod} />
              </>
            )}
            {method === "email" && (
              <Email
                selectedTalents={selectedTalents}
                emailContent={emailContent}
                setEmailContent={setEmailContent}
                setErrorData={setErrorData}
                errorData={errorData}
                handleConfirmation={handleConfirmation}
                previewEmail={previewEmail}
                setPreviewEmail={setPreviewEmail}
                isFrom={isFrom}
                method={method}
                setShowJobModal={setModalClose}
                handleBulkCommunication={handleBulkCommunication}
                selectedRecipients={selectedRecipients}
                setSelectedRecipients={setSelectedRecipients}
              />
            )}
            {method === "sms" && (
              <SMS
                selectedTalents={selectedTalents}
                smsContent={smsContent}
                setSmsContent={setSmsContent}
                setErrorData={setErrorData}
                errorData={errorData}
                handleConfirmation={handleConfirmation}
                previewEmail={previewEmail}
                setPreviewEmail={setPreviewEmail}
                isFrom={isFrom}
                setShowJobModal={setModalClose}
                handleBulkCommunication={handleBulkCommunication}
                handleAddRecipients={handleAddRecipients}
                selectedRecipients={selectedRecipients}
                setSelectedRecipients={setSelectedRecipients}
              />
            )}
            {/* {method === "whatsApp" && (
              <WhatsApp
                selectedTalents={selectedTalents}
                whatsAppContent={whatsAppContent}
                setWhatsAppContent={setWhatsAppContent}
                setErrorData={setErrorData}
                errorData={errorData}
                handleConfirmation={handleConfirmation}
                isFrom={isFrom}
                setShowJobModal={setModalClose}
                handleBulkCommunication={handleBulkCommunication}
                handleAddRecipients={handleAddRecipients}
                selectedRecipients={selectedRecipients}
                setSelectedRecipients={setSelectedRecipients}
              />
            )} */}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-secondary" onClick={onClose}>
          Close{" "}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
export default BulkCommunicationModal;
