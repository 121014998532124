import React, { useEffect, useState } from "react";
import { getUserRole } from "../../../../service/AuthService";

const JobListHeader = (props) => {
  const { setCurrentPage, viewJobType, setViewJobType, jobListCount } = props;

  const handleJobType = (jobTypeValue) => {
    setCurrentPage(1);
    setViewJobType(jobTypeValue);
  };

  return (
    <>
      <ul className="nav nav-tabs nav-tabs-tablet pt-1 justify-content-center justify-content-lg-start">
        {getUserRole() === "Recruiter" && (
          <li className="nav-item">
            <button
              type="button"
              className={
                viewJobType == "myjobs"
                  ? "nav-link pb-3 bg-transparent active"
                  : "nav-link pb-3 bg-transparent"
              }
              onClick={() => handleJobType("myjobs")}
            >
              <span className="tag mb-1">
                {jobListCount[0]?.MyJobCounts ?? 0}
              </span>
              <div>My Jobs</div>
            </button>
          </li>
        )}{" "}
        <li className="nav-item">
          <button
            type="button"
            className={
              viewJobType == "all"
                ? "nav-link pb-3 bg-transparent active"
                : "nav-link pb-3 bg-transparent"
            }
            onClick={() => handleJobType("all")}
          >
            <span className="tag mb-1">{jobListCount[0]?.AllJobs ?? 0}</span>
            <div>All</div>
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={
              viewJobType == "contract"
                ? "nav-link pb-3 bg-transparent active"
                : "nav-link pb-3 bg-transparent"
            }
            onClick={() => handleJobType("contract")}
          >
            <span className="tag mb-1">
              {jobListCount[0]?.ContractJobs ?? 0}
            </span>
            <div>Contract</div>
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={
              viewJobType == "fullTime"
                ? "nav-link pb-3 bg-transparent active"
                : "nav-link pb-3 bg-transparent"
            }
            onClick={() => handleJobType("fullTime")}
          >
            <span className="tag mb-1">
              {jobListCount[0]?.FullTimeJobs ?? 0}
            </span>
            <div>Full Time</div>
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={
              viewJobType == "expired"
                ? "nav-link pb-3 bg-transparent active"
                : "nav-link pb-3 bg-transparent"
            }
            onClick={() => handleJobType("expired")}
          >
            <span className="tag mb-1">
              {jobListCount[0]?.ExpiredJobs ?? 0}
            </span>
            <div>Halted</div>
          </button>
        </li>
        <li className="nav-item">
          <button
            type="button"
            className={
              viewJobType == "deactivated"
                ? "nav-link pb-3 bg-transparent active"
                : "nav-link pb-3 bg-transparent"
            }
            onClick={() => handleJobType("deactivated")}
          >
            <span className="tag mb-1">
              {jobListCount[0]?.DeactivatedJobs ?? 0}
            </span>
            <div>Closed</div>
          </button>
        </li>
      </ul>
    </>
  );
};

export default JobListHeader;
