import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";

const Refer = (props) => {
  const [referrerDetails, setReferrerDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    uniqRef: "",
  });
  const [loading, setLoading] = useState(false);

  const [shareLink, setShareLink] = useState("");
  const [generateLink, setGenerateLink] = useState(false);

  const onChange = (e) => {
    if (e.target.name !== "email")
      setReferrerDetails({
        ...referrerDetails,
        [e.target.name]: e.target.value.replace(/[^a-zA-Z\s]/, ""),
      });
    else
      setReferrerDetails({
        ...referrerDetails,
        [e.target.name]: e.target.value,
      });
  };

  const getUniqId = async (obj) => {
    setLoading(true);
    const params = {
      refFirstName: obj.firstName,
      refLastName: obj.lastName,
      refEmail: obj.email,
      referralUniqueCode: obj.uniqRef,
    };
    const res = await post("/canReferal/referToCan", params)
      .then((res) => {
        // setTenantData(res.data[0]);

        console.log("res", res);
        // res.data === ""
        //   ? setShareLink(window.location.href + `/${obj.uniqRef}`)
        //   : setShareLink(
        //       window.location.href + `/${res.data[0].referralUniqueCode}`
        //     );
        setShareLink(window.location.href + `/${res.data[0].candUniqueId}`);
        setLoading(false);
        setGenerateLink(true);
      })
      .catch((err) => {
        console.log("err", err);
        setLoading(false);
      });
  };

  const validate = (email) => {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return email.match(mailformat) ? true : false;
  };

  const getLink = (e) => {
    e.preventDefault();
    if (
      referrerDetails.firstName == "" ||
      referrerDetails.lastName == "" ||
      referrerDetails.email == ""
    ) {
      toast.error("Please provide all the mandatory fields");
    } else if (!validate(referrerDetails.email)) {
      toast.error("Enter a valid email");
    } else {
      let uniqId = Math.floor(Math.random() * 10000);
      const uniqRef = Math.floor(Math.random() * 10000);

      const obj = {
        ...referrerDetails,
        uniqRef: uniqRef,
      };
      setReferrerDetails(obj);
      console.log("obj", obj);
      getUniqId(obj);
    }
  };

  const handleCopy = (e) => {
    console.log("referrerDetails", referrerDetails);
    navigator.clipboard.writeText(shareLink);

    toast.success("Link Copied Successfully!!!");
  };

  return (
    <>
      {loading && <Loader />}
      <Modal
        show={props.isRefer}
        size="lg"
        className="modal fade center"
        data-backdrop="static"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-header" id="CreateQuestionModal">
          <h4 className="modal-title mx-3 ml-3" id="CreateQuestionModalLabel">
            Refer someone
          </h4>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => props.setIsRefer(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form
          onSubmit={(e) => {
            getLink(e);
          }}
          className="login-form "
        >
          <Modal.Body style={{ backgroundColor: "#F3F5F7", padding: "30px" }}>
            <div className="">
              <p className="ml-3" style={{ color: "gray" }}>
                You will be the referrer for anyone who applies using this link.
              </p>
              <hr />

              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-12 col-lg-12">
                    <div className="section-sm">
                      <div className="row g-2 mt-2">
                        <div className="col-md-6 form-floating">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First Name"
                            name="firstName"
                            value={referrerDetails.firstName}
                            onChange={(e) => onChange(e)}
                          />
                          <label>First Name *</label>
                        </div>
                        <div className="col-md-6 form-floating mb-2">
                          <input
                            type="text"
                            className="form-control"
                            name="lastName"
                            aria-describedby="emailHelp"
                            placeholder="Last Name"
                            value={referrerDetails.lastName}
                            onChange={(e) => onChange(e)}
                            autoComplete="new-email"
                          />
                          <label>Last Name *</label>
                        </div>
                      </div>

                      <div className="row g-2 mt-2">
                        <div className="col-md-6 form-floating">
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Email Address"
                            name="email"
                            value={referrerDetails.email}
                            onChange={(e) => onChange(e)}
                          />
                          <label>Email *</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer style={{ backgroundColor: "#F3F5F7" }}>
            <div className="d-flex w-100 p-3">
              <input type="text" value={shareLink} />

              {generateLink ? (
                <button
                  className="btn btn-secondary ml-3 mx-2"
                  style={{ width: "30%" }}
                  onClick={handleCopy}
                >
                  Copy
                </button>
              ) : (
                <button
                  className="btn btn-primary ml-3 mx-2"
                  type="submit"
                  style={{ width: "30%" }}
                  disabled={
                    referrerDetails.firstName === "" ||
                    referrerDetails.lastName === "" ||
                    referrerDetails.email === ""
                      ? true
                      : false
                  }
                  // onClick={getLink}
                >
                  Get Link
                </button>
              )}
            </div>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default Refer;
