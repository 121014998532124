import React from "react";

const SkillDetails = (props) => {
  const { jobData } = props;

  const filterSkills = (skills) => {
    return skills.filter((skill) => skill.trim() !== "");
  };

  return (
    <div className="mb-4 ml-4">
      <div className="d-flex mb-2 pl-1">
        <div
          className="ml-2"
          style={{ display: "inline-flex", flexWrap: "wrap" }}
        >
          {jobData?.primarySkills &&
            filterSkills(JSON.parse(jobData?.primarySkills).flat(1)).map(
              (item, index) => {
                if (item !== "") {
                  return (
                    <span key={index} className="tag tag-blue3 mb-1 mr-1">
                      {item}
                    </span>
                  );
                }
              }
            )}

          <p className="mb-0" style={{ width: "100%" }}>
            <small>Mandatory Skills</small>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SkillDetails;
