import React, { useState } from "react";

function CandidateResume(props) {

    let resumeURL = props.resumeURL
    const [ downloadResume, setDownloadResume ] = useState(false)

    const onCollapsibleClick = (val) => {
        if (val === 'downloadResume') {
            setDownloadResume(true)
        }

        if (val === 'downloadResumeReset') {
            setDownloadResume(false)
        }
    }

    return (
        <>
            <h6 className="mb-4 ml-2">Resume</h6><hr />
            {
                resumeURL.length === 0 ? <h6>No Resumes Found</h6> : (
                    <div>                    
                        <div className={downloadResume === true ? 'ml-4 mb-4 d-none' : 'ml-4 mb-4'}>
                            <ul class="list-unstyled mt-2">
                                {
                                    resumeURL.map((item, index) => (
                                        <li class="row ml-2 d-flex align-items-center">
                                            <span className="col-lg-1 avatar avatar-sm mr-1 col-sm-1">{index+1}</span>
                                            <strong className="col-lg-8 col-sm-3">{item.fileName}</strong><br /><br />                            
                                            { item.fileURL && <a 
                                                onClick={(e) => { 
                                                    e.preventDefault()
                                                    window.open(item.fileURL, '_blank')
                                                }} 
                                                className="btn btn-sm btn-secondary d-inline-block col-lg-3 col-sm-3"
                                            >
                                                <i className="fal fa-cloud-download-alt mr-2"></i>View
                                            </a>}
                                        </li>
                                    ))
                                }
                            </ul>
                            
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default CandidateResume;

