import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { post } from "../../../../service/ApiService";
import JobCard from "./JobCard";
import JobSearch from "../../candidatecareers/jobdetails/JobSearch";
import logo from "../../../../assets/images/Logo.png";
import SearchParameters from "../../candidatecareers/SearchParameters";
import Loader from "../../../Loader/Loader";
import PaginationComponent from "../../pagination/pagination";

const Index = (props) => {
  const { loading, setLoading, filterData } = props;

  const [jobExpArr, setJobExpArr] = useState([]);
  const [jobTenantArr, setJobTenantArr] = useState([]);
  const [jobTypeArr, setJobTypeArr] = useState([]);
  const [jobWorkplaceArr, setJobWorkplaceArr] = useState([]);
  const [selectedDate, setSelectedDate] = useState([]);
  const [selectedExp, setSelectedExp] = useState([]);
  const [selectedTenant, setSelectedTenant] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedWorkplace, setSelectedWorkplace] = useState([]);
  const [searchText, setSearchText] = useState("");

  const [matches768, setMatches768] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );
  const [matches400, setMatches400] = useState(
    window.matchMedia("(max-width: 400px)").matches
  );
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener("change", (e) => setMatches768(e.matches));
    window
      .matchMedia("(max-width: 400px)")
      .addEventListener("change", (e) => setMatches400(e.matches));
    props.setSearchJob([]);
  }, []);

  const setAlertFunction = async () => {
    let jobArr = props.jobData.map((elem) => elem.jobId);
    const payload = {
      candidateId: sessionStorage.getItem("userId"),
      keyword: props.parameterValue,
      isToggle: true,
      jobs: jobArr,
    };
    setLoading(true);
    await post("/candidate/insertJobAlert", payload)
      .then((res) => {
        setLoading(false);
        toast.success("Alert created successfully");
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Some error occured");
      });
  };

  const hideMore = () => {
    setShowMore(false);
  };

  const handlePageChange = (pageNumber) => {
    props.setCurrentPage(pageNumber);
    let filterObjects = {
      experienceLevel: selectedExp,
      jobType: selectedType,
      tenantname: selectedTenant,
      workPlaceType: selectedWorkplace,
      jobPostDate: selectedDate,
    };
    props.getJob(pageNumber, filterObjects, searchText);
  };

  return (
    <div>
      {loading && <Loader />}
      {props.isFrom !== "newCareerPage" && (
        <div className="Section">
          <div className="Container">
            <JobSearch
              setJobs={props.setJobs}
              setSearchJob={props.setSearchJob}
              filterJobs={props.setSearchJob}
              getJob={props.getJob}
            />
          </div>
        </div>
      )}
      <div
        className="careerPageNewBackground"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "500px",
        }}
      >
        <JobSearch
          searchText={searchText}
          setSearchText={setSearchText}
          getJob={props.getJob}
        />
      </div>
      <SearchParameters
        jobData={props.jobData}
        setJobs={props.setJobs}
        setCurrentPage={props.setCurrentPage}
        filterData={filterData}
        jobExpArr={jobExpArr}
        jobTenantArr={jobTenantArr}
        jobTypeArr={jobTypeArr}
        jobWorkplaceArr={jobWorkplaceArr}
        setJobExpArr={setJobExpArr}
        setJobTenantArr={setJobTenantArr}
        setJobTypeArr={setJobTypeArr}
        setJobWorkplaceArr={setJobWorkplaceArr}
        getJob={props.getJob}
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        selectedExp={selectedExp}
        setSelectedExp={setSelectedExp}
        selectedTenant={selectedTenant}
        setSelectedTenant={setSelectedTenant}
        selectedType={selectedType}
        setSelectedType={setSelectedType}
        selectedWorkplace={selectedWorkplace}
        setSelectedWorkplace={setSelectedWorkplace}
        searchText={searchText}
      />

      {/* Job Alert */}
      {sessionStorage.getItem("userId") !== null &&
        props.parameterValue !== undefined && (
          <>
            <div
              className=" d-flex align-items-center justify-content-end"
              style={{ width: "83%", marginTop: "10px", marginBottom: "-27px" }}
            >
              <span
                style={{
                  width: "214px",
                  marginLeft: "2px",
                }}
              >
                {`Set Job Alert for ${props?.parameterValue}`}
              </span>
              <div className="form-check form-switch col-1">
                <input
                  type="checkbox"
                  className="form-check-input"
                  name="isSelect"
                  onClick={() => setAlertFunction()}
                  // checked={toggle}
                  // onChange={(e) => setToggle(e.target.checked)}
                />
              </div>
            </div>
          </>
        )}
      <div
        className={matches768 ? "  w-100 mx-auto  " : "  w-75 mx-auto  px-5"}
      >
        <div className={matches768 ? "mx-auto" : "mx-auto  px-5"}>
          <div className={matches768 ? "mx-auto" : " mx-auto  px-2"}>
            <div className="mt-5 d-flex flex-column gap-2">
              {props.jobData.length > 0 ? (
                props.jobData.map((job) => (
                  <JobCard matches400={matches400} job={job} />
                ))
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              )}
              {/* pagination goes here */}
              {props.jobData && props.jobData?.length > 0 && (
                <PaginationComponent
                  currentPage={props.currentPage}
                  totalItems={props.total}
                  itemsPerPage={10}
                  onPageChange={handlePageChange}
                  limit={10}
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="d-none d-block-768"
        show={showMore}
        onHide={hideMore}
        size="lg"
      >
        <div class="bl-modal-header">
          {/* <h6 class="bl-modal-title">{"Apply"}</h6> */}
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={hideMore}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <Modal.Body>
          <div className="d-flex flex-column gap-2 px-3">
            <div>
              <span>
                <img
                  className=" my-auto"
                  style={{
                    height: "50px",
                    width: "100px",
                    marginLeft: "-25px",
                  }}
                  src={logo}
                  alt="logo"
                />
                <strong className="font-22"> High5</strong>
              </span>
            </div>
            <h5>We believe everyone deserves to live the career they love.</h5>
            <p>
              Every company has a mission. What's ours? To empower every person
              and every organization to achieve more. We believe technology can
              and should be a force for good and that meaningful innovation
              contributes to a brighter world in the future and today. Our
              culture doesn’t just encourage curiosity; it embraces it. Each day
              we make progress together by showing up as our authentic selves.
              We show up with a learn-it-all mentality. We show up cheering on
              others, knowing their success doesn't diminish our own. We show up
              every day open to learning our own biases, changing our behavior,
              and inviting in differences. When we show up, we achieve more
              together.
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="w-100 text-center">
            <button className="btn btn-md " onClick={hideMore}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Index;
