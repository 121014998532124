import React, { useEffect, useState } from "react";
import TopNavBar from "./TopNavBar";
import ApplyNow from "./ApplyNow/ApplyNow";
import SignUp from "./ApplyNow/SignUp";
import Footer from "./ApplyNow/Footer";
import Landing from "./Landing";
import JobDetails from "./ApplyNow/JobDetails";
import Refer from "./ApplyNow/Refer";
import JoinTalentBtn from "./ApplyNow/JoinTalentBtn";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { validate } from "email-validator";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { post } from "../../../service/ApiService";
import { domain } from "../../../constants/Constants";
import Loader from "../../Loader/Loader";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { getCandidateDetails } from "../../../actions/candidate/getCandidateDetails";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  const { tenant, id, recruiterId } = useParams();
  const [job, setJob] = useState({});
  const [category, setCategory] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  // const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [loginData, setLoginData] = useState({});
  const [socialMediaApply, setSocialMediaApply] = useState(false);
  const [isLoginModal, setIsLoginModal] = useState(false);
  const [savedJobs, setSavedJobs] = useState([]);
  const [expiryLoading, setExpiryLoading] = useState(false);

  const { candidateDetailsLoading, candidateData } = useSelector(
    (state) => ({
      candidateDetailsLoading:
        state.candidateDetailsReducer.candidateDetailsLoading,
      candidateData: state.candidateDetailsReducer.candidateData,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const checkLinkExpiry = async () => {
    try {
      // const path = window.location.pathname;

      // const jobId = path.split("/")[3];
      const payload = {
        jobId: id,
      };
      setExpiryLoading(true);
      const res = await post("/applyjob/isJobExpired", payload);
      if (res.status === 200) {
        setExpiryLoading(false);
        if (res?.data[0]?.jobStatus === 39 || res?.data[0]?.jobStatus === 23) {
          history.push("/linkExpired");
        } else {
          getCandidateData();
          const savedJobsJSON = localStorage.getItem("savedJobIds");
          const savedJobIds = savedJobsJSON ? JSON.parse(savedJobsJSON) : [];
          if (sessionStorage.getItem("userId") == null) {
            setSavedJobs(savedJobIds);
          }
          getJob();
        }
      }
    } catch (error) {
      console.error("Error checking link expiry:", error);
    }
  };

  useEffect(() => {
    checkLinkExpiry();
  }, []);

  const getCandidateData = () => {
    const payload = {
      candidateId: sessionStorage.getItem("userId"),
    };
    dispatch(getCandidateDetails(payload));
  };

  const emailExists = async () => {
    if (email == "") {
      toast.error("Please enter your email");
    } else if (!validate(email)) {
      toast.error("Enter a valid email address");
    } else {
      const params = { tenantId: tenant, email: email };

      setLoading(true);
      const res = await post("/applyjob/isemailexist", params).then((res) => {
        if (res.data.candidateStatus == "Login Talent") {
          toast.success("You already have joined our talent community");
          setLoading(false);
          setCheckEmail(false);
          setEmail("");
        } else if (
          res.data.candidateStatus === "Existing Talent" &&
          res.data.source !== "Join Community"
        ) {
          setCheckEmail(false);
          sessionStorage.setItem("email", email);
          sessionStorage.setItem("id_user", res.data.id_user);
          sessionStorage.setItem("candidateStatus", "Existing Talent");
          window.open(
            `/careers/${tenant}/recruiter/${recruiterId}/joincommunity`,
            "_self"
          );
          setLoading(false);
        } else if (res.data.candidateStatus === "New Talent") {
          setCheckEmail(false);
          sessionStorage.setItem("email", email);
          true
            ? window.open(
                `/careers/8/recruiter/698131846/joincommunity`,
                "_self"
              )
            : window.open(
                `/careers/${tenant}/recruiter/${recruiterId}/joincommunity`,
                "_self"
              );
          setLoading(false);
        } else {
          toast.success("You already have joined our talent community");
          setLoading(false);
          setCheckEmail(false);
          setEmail("");
        }
      });
    }
  };

  const getJob = async () => {
    setLoading(true);
    try {
      const params = { domain: domain, jobId: id, recruiterId: 698131846 };
      const res = await post("/career/getjobdetails", params);
      if (res.status === 200) {
        setJob(res?.data?.[0]);
        let c = JSON.parse(res?.data?.[0]?.industries)[0] || "";
        setCategory(c);
        // getAllJob(JSON.parse(res.data[0].primarySkills)[0]);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {(loading || expiryLoading) && <Loader />}
      {!expiryLoading && (
        <>
          <div className="mainSec">
            <TopNavBar
              savedJobs={savedJobs}
              setSavedJobs={setSavedJobs}
              isLoginModal={isLoginModal}
              setIsLoginModal={setIsLoginModal}
              isFrom="AllJobs"
            />
            <Landing category={category} job={job} />
          </div>
          <div className="mainSec2">
            <div className="mainSec2_job">
              <JobDetails
                job={job}
                setCheckEmail={setCheckEmail}
                setSocialMediaApply={setSocialMediaApply}
                setLoginData={setLoginData}
                isLoginModal={isLoginModal}
              />
            </div>
            <div>
              <ApplyNow
                loginData={loginData}
                socialMediaApply={socialMediaApply}
                job={job}
                setSocialMediaApply={setSocialMediaApply}
              />
              {/* <Refer /> */}
              {/* {sessionStorage.getItem("token") == null && (
            <SignUp
              setSocialMediaApply={setSocialMediaApply}
              setLoginData={setLoginData}
              isLoginModal={isLoginModal}
              isFrom="Home"
            />
          )} */}
              <JoinTalentBtn setCheckEmail={setCheckEmail} />
            </div>
            <Footer />
          </div>

          <Modal show={checkEmail}>
            <Modal.Header>
              <div>
                <h6>Join Talent Pool</h6>
              </div>
              <div>
                <button
                  type="button"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setCheckEmail(false);
                  }}
                  class="close p-0 bl-modal-close-btn mx-1"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex flex-column mx-5 mb-3 "></div>

              <div>
                <div className="d-flex flex-column mx-5 my-3">
                  <div className="form-floating ml-1">
                    <input
                      type="text"
                      className={"form-control"}
                      name="groupName"
                      placeholder="Enter a group name"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label>Email*</label>
                  </div>
                </div>
                <div className="row mx-5 mb-2">
                  <button
                    className="btn btn-secondary m-2 w-100"
                    onClick={() => emailExists()}
                    disabled={email == "" && true}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default Home;
