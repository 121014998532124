import React, { useEffect, useState } from "react";
import { get } from "../../service/ApiService";
import { countryObj, domain } from "../../constants/Constants";
import { toast } from "react-toastify";
import moment from "moment";
import Loader from "../Loader/Loader";
import { Row, Col } from "react-bootstrap";
import OutsideClickHandler from "react-outside-click-handler";
import Sort from "../common/Sort";
import ShareJobsFilter from "./ShareJobsFilter";

const SelectJobs = (props) => {
  const [list, setList] = useState([]);
  const [MasterChecked, setMasterChecked] = useState(false);
  // const [userChecked, setUserChecked] = useState([])
  const [selectedList, setSelectedList] = useState([]);
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortModal, setSortModal] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [jobFilter, setJobFilter] = useState(false);
  const [jobFilterBy, setJobFilterBy] = useState([]);
  const [pageType, setPageType] = useState("");
  const [requestTitles, setRequestTitles] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [requestTypes, setRequestTypes] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finalRequests, setFinalRequests] = useState([]);
  const [finalJobs, setFinalJobs] = useState();
  const dataLimit = 10;
  const pageLimit = 10;

  const sortObject = [
    {
      id: 1,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Type",
      value: "jobType",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Location",
      value: "",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  const onMasterCheck = async (e) => {
    if (e.target.checked) {
      props.setUserChecked([...finalRequests]);
      setMasterChecked(!MasterChecked);
    } else {
      props.setUserChecked([]);
      setMasterChecked(false);
    }
  };
  const onItemCheck = async (checked, users) => {
    if (checked) {
      props.setUserChecked([...props.userChecked, users]);
      setMasterChecked(false);
    } else {
      props.setUserChecked(
        props.userChecked.filter((t) => t.jobId !== users.jobId)
      );
      setMasterChecked(false);
    }
  };

  const getRequestList = async (skip) => {
    setLoading(true);
    const params = {
      skip: skip,
      domain: domain,
    };
    try {
      let title = [];
      let type = [];
      const res = await get(`/communitymanager/fetchjob`, params);
      const openActiveJob = res.data.filter(
        (f) => f.jobStatus == 21 || f.jobStatus == 13
      );
      const result = openActiveJob.sort((a, b) =>
        a.jobPostDate > b.jobPostDate ? 1 : -1
      );
      result.forEach((job) => {
        if (job.jobTitle && !title.includes(job.jobTitle)) {
          title.push(job.jobTitle);
        }
        if (job.jobType && !type.includes(job.jobType)) {
          type.push(job.jobType);
        }
      });
      setJobFilterBy(result);
      setFinalRequests(result);
      props.setJobCount(result.length);
      setRequestTitles(title);
      setRequestTypes(type);
      setLoading(false);
    } catch (error) {
      toast.error(error);
    }
  };

  useEffect(() => {
    getRequestList(0);
  }, []);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);
  // Update List Item's state and Master Checkbox State
  useEffect(() => {
    setPaginationArray(
      new Array(Math.ceil(finalRequests.length / dataLimit))
        .fill()
        .map((_, i) => i + 1)
    );
  }, [finalRequests]);

  const getPaginatedData = () => {
    const startIndex = currentPage * perPage - perPage;
    const endIndex = startIndex + +perPage;
    return jobFilterBy
      .filter(
        (x) =>
          moment(x.expiresOn, "YYYY-MM-DD").diff(
            moment(new Date(), "YYYY-MM-DD"),
            "days"
          ) >= 0
      )
      .slice(startIndex, endIndex);
  };

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }
  const openJobFilter = () => {
    setJobFilter(true);
  };
  const applyFilter = (data, selectedTitle, selectedType) => {
    setJobFilter(false);
    setSortCount(0);
    setSortArray([]);
    setSelectedTitles(selectedTitle);
    setSelectedTypes(selectedType);
    setJobFilterBy(data);
    if (data.length === 0) {
      toast.error("No Record Found Matching Your Filter Criteria");
    } else {
      const pageGroup = new Array(Math.ceil(data.length / perPage))
        .fill()
        .map((_, i) => i + 1);
      setPaginationArray(pageGroup);
      setPaginationGroup(pageGroup.slice(0, pageLimit));
    }
  };
  const applySort = (sortedData, close) => {
    close && setSortModal(false);
    setJobFilterBy(sortedData);
  };

  function formatDate(inputDate) {
    let date, month, year;
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
    date = date.toString().padStart(2, "0");
    month = month.toString().padStart(2, "0");
    return `${month}-${date}-${year}`;
  }

  return (
    <div>
      <Row>
        <Col>
          <div>
            <div className="row">
              <div className="col-lg-8">
                <strong className="flex-item mb-4">Job Requests</strong>
              </div>
              <div className="col-lg-2">
                <div class="dropdown-sort dd-sort">
                  <button
                    type="button"
                    className="btn btn-sm btn-text-accent"
                    onClick={() => setSortModal(true)}
                  >
                    <i className="fad fa-sort-down font-16 mr-1"></i> Sort /{" "}
                    {sortCount}
                  </button>
                  {sortModal && (
                    <OutsideClickHandler
                      onOutsideClick={() => setSortModal(false)}
                    >
                      <Sort
                        sortData={finalRequests}
                        orderArray={orderList}
                        sortObjects={sortObject}
                        applySort={applySort}
                        setSortCount={setSortCount}
                        sortCount={sortCount}
                        setSortArray={setSortArray}
                        sortArray={sortArray}
                        sortingFor={"Sort by Job Title, Job Type and Location"}
                      />
                    </OutsideClickHandler>
                  )}
                </div>
              </div>
              <div className="col-lg-2">
                <div className="dropdown position-relative">
                  <button
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Filter"
                    onClick={openJobFilter}
                    type="button"
                    className="btn btn-sm btn-text-accent w-auto"
                  >
                    <i className="fas fa-filter"></i>
                    Filter /
                    <span>
                      {" "}
                      {selectedTitles.length + selectedTypes.length}{" "}
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div className="form-check">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={MasterChecked}
                            id="mastercheck"
                            onChange={(e) => onMasterCheck(e)}
                            style={{ height: "1.2rem", width: "1.2rem" }}
                          />
                        </div>
                      </th>
                      <th scope="col">Job Title</th>
                      <th scope="col">Job Type</th>
                      <th scope="col">Location</th>
                      <th scope="col">End Date</th>
                      <th scope="col">Placement Fee</th>
                      {/* <th scope="col">Status</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {loading && <Loader />}
                    {getPaginatedData().map((job, id) => (
                      <tr key={id}>
                        <th scope="row">
                          <div className="form-check">
                            <input
                              type="checkbox"
                              checked={props.userChecked
                                .map((item) => item.jobId)
                                .includes(job.jobId)}
                              className="form-check-input"
                              style={{ height: "1.2rem", width: "1.2rem" }}
                              onChange={(e) =>
                                onItemCheck(e.target.checked, job)
                              }
                            />
                          </div>
                        </th>
                        <td>{job.jobTitle}</td>
                        <td>{job.jobType}</td>
                        {job?.city &&
                        job?.state &&
                        job?.country &&
                        !job.isRemote ? (
                          <td>
                            {job.city}, {job?.state},{job?.country}
                          </td>
                        ) : (
                          <td>{"Remote"}</td>
                        )}
                        {/* <td></td> */}
                        <td>
                          {moment(job.expiresOn).diff(
                            formatDate(new Date()),
                            "days"
                          ) < 0 && (
                            <span className="tag tag-red1">
                              <strong> Expired </strong>
                            </span>
                          )}
                          {moment(job.expiresOn).diff(
                            formatDate(new Date()),
                            "days"
                          ) === 0 && (
                            <span className="tag tag-red1">
                              <strong> Expires Today </strong>
                            </span>
                          )}
                          {moment(job.expiresOn).diff(
                            formatDate(new Date()),
                            "days"
                          ) === 1 && (
                            <span className="tag tag-red1">
                              <strong> 1 Day Left To Fill </strong>
                            </span>
                          )}
                          {moment(job.expiresOn).diff(
                            formatDate(new Date()),
                            "days"
                          ) > 1 &&
                            (moment(job.expiresOn).diff(
                              formatDate(new Date()),
                              "days"
                            ) <= 5 ? (
                              <span className="tag tag-red1">
                                <strong>
                                  {" "}
                                  {moment(job.expiresOn).diff(
                                    formatDate(new Date()),
                                    "days"
                                  )}{" "}
                                  Days Left To Fill{" "}
                                </strong>
                              </span>
                            ) : (
                              <span className="font-regular">
                                <strong>
                                  {" "}
                                  {moment(job.expiresOn).diff(
                                    formatDate(new Date()),
                                    "days"
                                  )}{" "}
                                  Days Left To Fill{" "}
                                </strong>
                              </span>
                            ))}
                        </td>
                        <td style={{ textAlign: "left" }}>
                          {job.isFeePercentage
                            ? `${job.placementFee} %`
                            : `${job.placementFee} ${job.placementCurrency}`}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                {getPaginatedData().length > 0 && (
                  <div className="d-flex justify-content-end pt-4 px-1">
                    <div className="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === 1 || getPaginatedData().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {getPaginatedData().length > 0
                        ? paginationArray.map((item) => (
                            <button
                              type="button"
                              className={
                                currentPage === item
                                  ? "btn btn-text px-2 bg-secondary text-white"
                                  : "btn btn-text px-2"
                              }
                              onClick={changePage}
                            >
                              {item}
                            </button>
                          ))
                        : ""}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === paginationArray.slice(-1)[0] ||
                          (getPaginatedData().length === 0 &&
                            getPaginatedData().length < 0)
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      {jobFilter && (
        <OutsideClickHandler onOutsideClick={() => setJobFilter(false)}>
          <ShareJobsFilter
            jobData={finalRequests}
            requestTitles={requestTitles}
            selectedTitles={selectedTitles}
            requestTypes={requestTypes}
            selectedTypes={selectedTypes}
            applyFilter={applyFilter}
          />
        </OutsideClickHandler>
      )}
    </div>
  );
};

export default SelectJobs;
