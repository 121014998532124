import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { useParams, Redirect } from "react-router-dom";
import { getJobDetails } from "../../../../actions/job/JobDetails";
import { getDispositionDetails } from "../../../../actions/disposition/Disposition";
import { getRecruiterData } from "../../../../actions/recruiter/RecruiterList";
import Loader from "../../../Loader/Loader";
import JobDetailsTitle from "./JobDetailsTitle";
import { getUserRole } from "../../../../service/AuthService";
import SidePanelHeader from "./sidepanelheader/Index";
import SidepanelDetails from "./sidepaneldetails/Index";
import DispositionHeader from "../../disposition/jobdetails/dispositionheader/Index";
import DispositionDetails from "../../disposition/jobdetails/dispositiondetails/Index";
import InviteTalent from "../../disposition/Invite/Index";
import SearchJob from "../../commoncomponents/search/SearchJob";
import { getDispositionCount } from "../../../../actions/disposition/DispositionCount";
import { getDispositionDisqualify } from "../../../../actions/disposition/DispositionDisqualified";

const JobDetails = () => {
  const dispatch = useDispatch();
  const { Id, Tab } = useParams();
  const [jobData, setJobData] = useState({});
  const [error, setError] = useState(false);
  const [invite, setInvite] = useState(false);
  const [sideView, setSideView] = useState("overview");
  const [value, setValue] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [dispositionView, setDispositionView] = useState("");
  const {
    jobDetailsLoading,
    jobDetails,
    dispositionDetailsLoading,
    dispositionCountLoading,
    dispositionDisqualifyLoading,
  } = useSelector(
    (state) => ({
      jobDetailsLoading: state.jobDetails.jobDetailsLoading,
      jobDetails: state.jobDetails.jobDetails,
      dispositionDetailsLoading:
        state.dispositionDetails.dispositionDetailsLoading,
      dispositionCountLoading: state.dispositionCount.dispositionCountLoading,
      dispositionDisqualifyLoading:
        state.dispositionDisqualify.dispositionDisqualifyLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    const payload = {
      jobId: Id,
      searchText: searchValue,
    };
    const payloadDisposition = {
      jobId: Id,
      status: dispositionView,
      limit: 15,
      currentPage: 1,
      searchText: searchValue,
    };
    dispatch(getJobDetails(payload));
    dispatch(getDispositionCount(payload));
    if (dispositionView) {
      dispatch(getDispositionDetails(payloadDisposition));
    }
    dispatch(getRecruiterData(payload));
  }, [dispositionView]);

  useEffect(() => {
    const delay = 1000;
    const debounceTimer = setTimeout(() => {
      if (searchValue.length === 0 || searchValue.length >= 2) {
        const payload = {
          jobId: Id,
          searchText: searchValue,
        };
        const payloadDisposition = {
          jobId: Id,
          status: dispositionView,
          limit: 15,
          currentPage: 1,
          searchText: searchValue,
        };
        const payloadDisqualify = {
          jobId: Id,
          status: "disqualified",
          limit: 15,
          currentPage: 1,
          searchText: searchValue,
        };
        dispatch(getDispositionDisqualify(payloadDisqualify));
        dispatch(getJobDetails(payload));
        dispatch(getDispositionCount(payload));
        if (dispositionView) {
          dispatch(getDispositionDetails(payloadDisposition));
        }
        dispatch(getRecruiterData(payload));
      }
    }, delay);
    return () => clearTimeout(debounceTimer);
  }, [searchValue]);

  useEffect(() => {
    setValue("");
  }, [dispositionView]);

  useEffect(() => {
    if (jobDetails.length > 0) {
      setJobData({
        ...jobDetails[0],
      });
    } else if (!jobDetailsLoading) {
      setError(true);
    }
  }, [jobDetails]);

  if (error) {
    return <Redirect to="/high5Hire/404" />;
  }

  const renderSidepanelDetails = () =>
    Object.keys(jobData).length > 0 && (
      <SidepanelDetails jobData={jobData} sideView={sideView} />
    );

  const renderDispositionDetails = () =>
    Object.keys(jobData).length > 0 && (
      <DispositionDetails
        view={dispositionView}
        jobData={jobData}
        value={value}
      />
    );
  return (
    <>
      {(jobDetailsLoading ||
        dispositionDetailsLoading ||
        dispositionCountLoading ||
        dispositionDisqualifyLoading) && <Loader />}
      <div id="RequestView">
        <div className="hero">
          <div className="section pb-0">
            <div className="container-fluid">
              {Object.keys(jobData).length > 0 && (
                <JobDetailsTitle jobData={jobData} />
              )}
              {getUserRole() == "Recruiter" &&
                jobData.jobStatus !== 23 &&
                jobData.jobStatus !== 26 &&
                jobData.jobStatus !== 39 && (
                  <div className="row">
                    <div
                      className="col-12 mb-1 d-flex"
                      style={{ justifyContent: "end" }}
                    >
                      <SearchJob
                        value={searchValue}
                        setSearchValue={setSearchValue}
                      />
                      <button
                        class="btn btn-info ml-1"
                        onClick={() => setInvite(true)}
                      >
                        <i
                          aria-hidden="true"
                          class="far fa-plus fa-fw mr-1"
                        ></i>
                        Invite Talent
                      </button>
                    </div>
                  </div>
                )}
              <div class="row justify-content-center">
                <div class="col-xl-12">
                  <div className="row">
                    {Object.keys(jobData).length > 0 && (
                      <SidePanelHeader
                        jobData={jobData}
                        setSideView={setSideView}
                        sideView={sideView}
                      />
                    )}
                    {
                      <div class=" d-none d-block-1200 mb-2">
                        {renderSidepanelDetails()}
                      </div>
                    }
                    {Object.keys(jobData).length > 0 && (
                      <DispositionHeader
                        view={dispositionView}
                        setView={setDispositionView}
                        value={value}
                        Tab={Tab}
                      />
                    )}{" "}
                    {
                      <div class=" d-none d-block-1200">
                        {renderDispositionDetails()}
                      </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pb-5 d-none-1200">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-xl-12 ">
                <div class="row">
                  <div class="col-xl-4">{renderSidepanelDetails()}</div>
                  {/* Disposition comes here */}
                  {renderDispositionDetails()}
                </div>
              </div>
            </div>
          </div>
        </div>
        {invite && (
          <InviteTalent
            shareButton={invite}
            setShareButton={setInvite}
            jobData={jobData}
          />
        )}
      </div>
    </>
  );
};

export default JobDetails;
