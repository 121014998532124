import { Route, Switch } from "react-router-dom";
import PublicRouteList from "./PublicComponentList";

const PublicRoutes = () => (
  <div>
    <Switch>
      {PublicRouteList.map((route, index) => (
        <Route
          key={index}
          exact={route.isExact}
          path={route.path}
          component={route.name}
        />
      ))}
    </Switch>
  </div>
);

export default PublicRoutes;
