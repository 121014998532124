import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";

const EADashboardAnalytics = (props) => {
  const { EA_daily, EA_monthly, EA_total, EA_weekly } = props.data;
  const [EATimeInterval, setEATimeInterval] = useState("daily");
  const [loggedInEA, setLoggedInEA] = useState(0);
  const [loggedInCount, setLoggedInCount] = useState(0);
  const [chartSeries, setChartSeries] = useState([
    {
      name: "High5 Admins Login",
      data: [],
      color: "#55c3c3",
    },
  ]);
  const [chartOptions, setChartOptions] = useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: [],
    },
    dataLabels: {
      enabled: false,
    },
  });
  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "short" });
    return `${day} ${month}`;
  }

  const generateChartData = (timeInterval) => {
    let categories = [];
    let data = [];
    let count = 0;
    if (timeInterval === "daily") {
      categories = EA_daily?.map((item) => formatDate(item.category));
      data = EA_daily?.map((item) => item.data);
      EA_daily?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInEA(EA_daily && EA_daily[EA_daily?.length - 1].data);
    } else if (timeInterval === "weekly") {
      categories = EA_weekly?.map((item) => formatDate(item.category));
      data = EA_weekly?.map((item) => item.data);
      EA_weekly?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInEA(EA_weekly && EA_weekly[EA_weekly?.length - 1].data);
    } else if (timeInterval === "monthly") {
      categories = EA_monthly?.map((item) => item.category);
      data = EA_monthly?.map((item) => item.data);
      EA_monthly?.map((item) => {
        count = count + item.data;
      });
      setLoggedInCount(count);
      setLoggedInEA(EA_monthly && EA_monthly[EA_monthly?.length - 1].data);
    }

    setChartSeries([
      {
        name: "High5 Admins Login",
        data: data,
      },
    ]);
    setChartOptions({
      ...chartOptions,
      xaxis: {
        categories: categories,
      },
    });
  };

  useEffect(() => {
    generateChartData(EATimeInterval);
  }, [EATimeInterval]);

  useEffect(() => {
    generateChartData("daily");
  }, []);
  return (
    <>
      {/* Executive Admin Login */}
      <div className="card analyticCards custom-card">
        <h6 className="text-center mt-3 mb-3">High5 Admin Login</h6>
        <div className="d-flex flex-wrap justify-content-center">
          <button
            onClick={() => setEATimeInterval("daily")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor: EATimeInterval === "daily" ? "#17a2b8" : "white",
              color: EATimeInterval === "daily" ? "white" : "#17a2b8",
            }}
          >
            Daily
          </button>
          <button
            onClick={() => setEATimeInterval("weekly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                EATimeInterval === "weekly" ? "#17a2b8" : "white",
              color: EATimeInterval === "weekly" ? "white" : "#17a2b8",
            }}
          >
            Weekly
          </button>
          <button
            onClick={() => setEATimeInterval("monthly")}
            className="analytics-button btn-xs m-1"
            style={{
              backgroundColor:
                EATimeInterval === "monthly" ? "#17a2b8" : "white",
              color: EATimeInterval === "monthly" ? "white" : "#17a2b8",
            }}
          >
            Monthly
          </button>
        </div>
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type="line"
          height={250}
        />
        <div className="card m-2 align-items-center">
          <p className="mt-2 fw-light">
            {`Total High5 Admin${EA_total !== 1 ? "s" : ""}: ${EA_total}`}
          </p>
          <p className="fw-light">
            {`Logged In High5 Admin${
              loggedInCount !== 1 ? "s" : ""
            }: ${loggedInCount}`}
          </p>
        </div>
      </div>
    </>
  );
};

export default EADashboardAnalytics;
