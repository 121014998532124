import React from "react";
import { Modal } from "react-bootstrap";

const SMSModal = ({ showjobModal, setShowJobModal }) => {
  return (
    <Modal show={showjobModal} size="lg">
      <div className="modal-header" id="NewCandidateModal">
        <h6>SMS</h6>
        {showjobModal && (
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowJobModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        )}
      </div>
      <Modal.Body>
        <div className="card-flat">
          <div className=" bg-white p-4 ">
            <p>Hello [Recipient],</p>

            <p>
              This is a sample SMS message. You can customize this part to
              include your specific message.
            </p>

            <p>Feel free to add more text or information to suit your needs.</p>

            <p>Thank you for your attention.</p>

            <p>Best regards, Your Name</p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn btn-secondary"
          onClick={() => setShowJobModal(false)}
        >
          Cancel{" "}
        </button>
        <button onClick={() => setShowJobModal(false)}>Send</button>
      </Modal.Footer>
    </Modal>
  );
};
export default SMSModal;
