import axios from 'axios';
import { toast } from 'react-toastify';
import { post } from '../../service/ApiService';
// import { tovutiUsers } from '../tovuti/tovuti';

export const GET_REGISTER_BEGIN = 'GET_REGISTER_BEGIN';
export const GET_REGISTER_SUCCESS = 'GET_REGISTER_SUCCESS';
export const GET_REGISTER_FAILURE = 'GET_REGISTER_FAILURE';

export const CommonRegister = (params, history) => async dispatch => {
    try {
        dispatch({
            type: GET_REGISTER_BEGIN,
        })
       const res = await post('/register', params)
             
       
        if (res.status === 200) {
            dispatch({
                type: GET_REGISTER_SUCCESS,
                payload: res.data
            })
            // localStorage.setItem('loginTime', res.data.loginTime)
            // localStorage.setItem('userToken', JSON.stringify(res.data));
            // localStorage.setItem('user',res.data.candidateName);   
            // localStorage.setItem('role',res.data.role)
            // localStorage.setItem('tenant',res.data.userTenant)
            // localStorage.setItem('userid',res.data.candidateId)
           // history.push('/login')
            // dispatch(tovutiUsers())
        }
    } catch (error) {
        dispatch({
            type: GET_REGISTER_FAILURE,
            payload: error.response.data
        })
        toast.error(error.response.data.errMessage)
    }
}
