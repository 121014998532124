import React, { useState } from "react";
import NewTalentForm from "../../disposition/applyjob/newtalentform/Index";
import high5Logo from "../../../../images/high5-logo.jpg";
const JoinCommunity = () => {
  const [parsedData, setParsedData] = useState({});
  const [parsed, setParsed] = useState(false);

  const updatedParsed = (data, value) => {
    setParsedData(data);
    setParsed(value);
  };

  return (
    <>
      <div className="">
        {/* <TopNavbar tenantData={tenantData} /> */}
        <div className="row mx-auto" style={{ width: "85%" }}>
          <div className="col-md-12 mx-auto mt-5 ">
            <h2 className="text-center">Join our talent pool</h2>

            <div className="row  mt-3">
              <div className="col-md-12 text-secondary ">
                <p className="text-center">
                  If you are thinking about a future with us, sign up to stay
                  connected and be informed of any new opportunities
                </p>
              </div>
            </div>
            <div className=" w-70 mt-3"></div>
          </div>
        </div>
        <div style={{ height: "50px" }}></div>
        <>
          <div className="row mx-5 mb-5 " style={{ width: "90%" }}>
            <NewTalentForm
              parent="JobDecription"
              message="JoinCommunity"
              updatedParsed={updatedParsed}
              parsedData={parsedData}
              parsed={parsed}
              isFrom="JoinTalentCommunity"
            />
          </div>
        </>
        <div className="row mx-auto mb-3" style={{ width: "85%" }}>
          <div className="col-md-7">
            <footer>
              <div>
                <span className="text-secondary">Powered by</span>
                <a>
                  <img
                    style={{
                      width: 40,
                      height: 35,
                      paddingBottom: 10,
                      marginLeft: 5,
                    }}
                    src={high5Logo}
                  />
                </a>
                <p className=" text-secondary">
                  Privacy Policy and Terms of Service
                </p>
              </div>
            </footer>
          </div>
        </div>
      </div>
    </>
  );
};

export default JoinCommunity;
