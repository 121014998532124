import React from "react";
import { Modal } from "react-bootstrap";

const EraseDataModal = (props) => {
  return (
    <>
      <div className="bg-gray3">
        <Modal show={true}>
          <Modal.Body>
            <div className="text-center mt-2 px-2">
              <div className="avatar avatar-lg avatar-red2 mr-3">
                <i class="fas fa-exclamation-triangle fa-fw"></i>
              </div>
              <p className="text-center lead mt-3">
                Existing data will be erased on changing Job Type
              </p>
              <div class="text-center my-4">
                <button
                  type="button"
                  className="btn btn-secondary mr-3"
                  onClick={() => {
                    props?.setShowConfirmJobType(false);
                    props?.setJobType("");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    props?.onTypeClick(props?.jobType, props?.initialJobData);
                    props?.setShowConfirmJobType(false);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default EraseDataModal;
