import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Redirect } from "react-router-dom";
import ReactPaginate from "react-paginate";
import moment from "moment";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {
  getUserTenantID,
  getOwner,
  getTenantType,
  getCandidateId,
  getUserRole,
} from "../../../service/AuthService";
import { getEmployeeDetails } from "../../../actions/employees/Employees";
import Loader from "../../Loader/Loader";
import SearchEmployee from "../../../reducers/employees/SearchEmployee";
import EmployeePopUp from "./EmployeePopUp";
import AddEmployee from "./addemployee/Index";
import EditEmployee from "./editemployee/Index";
import DeactivateEmployee from "./deactivateemployee/Index";
import ActivateEmployee from "./activateemployee/Index";
import { roleNames } from "../../../constants/Constants";
import EmailStatus from "../EmailStatus/EmailStatus";

const Employees = () => {
  const dispatch = useDispatch();
  const [selectedItem, setSelectedItem] = useState({});
  const [editPopup, setEditPopup] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [activatePopup, setActivatePopup] = useState(false);
  const [employeePopup, setEmployeePopup] = useState(false);
  const [addPopup, setAddPopup] = useState(false);
  const [employeesDetails, setEmployeesDetails] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [showingCount, setShowingCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageShowCount, setPageShowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchValue, setSearchValue] = useState(null);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [sortData, setSortData] = useState([]);
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);

  const { loading, employees } = useSelector(
    (state) => ({
      loading: state.employeesDetails.employeesDetailsLoading,
      employees: state.employeesDetails.employeesDetails,
    }),
    shallowEqual
  );

  useEffect(() => {
    const params = {
      tenantId: getUserTenantID(),
    };
    dispatch(getEmployeeDetails(params));
  }, []);

  useEffect(() => {
    if (employees.length > 0) {
      const result = employees.sort(
        (a, b) => new Date(b.createddatetime) - new Date(a.createddatetime)
      );
      setSortData(result);
      setEmployeesDetails(result);
      setEmployeesDetails(result.slice(offset, offset + pageShowCount));
      setPageCount(Math.ceil(result.length / pageShowCount));
      setTotalEmployee(result.length);
      const showingCount =
        result.length > pageShowCount ? pageShowCount : result.length;
      setShowingCount(showingCount);
    }
  }, [employees]);

  const closePopup = () => {
    setEditPopup(false);
    setDeletePopup(false);
    setActivatePopup(false);
    setEmployeePopup(false);
    setAddPopup(false);
  };

  const handleClick = (item, type) => {
    setSelectedItem(item);
    if (type == "edit") {
      setEditPopup(true);
    } else if (type == "delete") {
      setDeletePopup(true);
    } else if (type == "activate") {
      setActivatePopup(true);
    } else {
      setEmployeePopup(true);
    }
  };

  const handlePageChange = (selectedObject) => {
    const currPage = selectedObject.selected;
    setCurrentPage(currPage);
    const offsetValue = currPage * pageShowCount;
    setPageCount(Math.ceil(totalEmployee / pageShowCount));
    setEmployeesDetails(
      employees.slice(offsetValue, offsetValue + pageShowCount)
    );
    setOffset(offsetValue);
    const count =
      totalEmployee > (currPage + 1) * pageShowCount
        ? (currPage + 1) * pageShowCount
        : totalEmployee;
    setShowingCount(count);
  };

  const handleChange = (e) => {
    let showCount = 0;
    if (e.target.value === "All") {
      showCount = totalEmployee;
    } else {
      showCount = Number(e.target.value);
    }
    setPageShowCount(showCount);
    const offsetValue = currentPage * showCount;
    setPageCount(Math.ceil(totalEmployee / showCount));
    setEmployeesDetails(employees.slice(offsetValue, offsetValue + showCount));
    setOffset(offsetValue);
    const count =
      totalEmployee > (currentPage + 1) * e.target.value
        ? (currentPage + 1) * e.target.value
        : totalEmployee;
    setShowingCount(count);
  };
  if (!getOwner()) {
    return <Redirect to="/high5Hire/404" />;
  }
  return (
    <>
      {loading && <Loader />}
      <div>
        <div className="fontSizeChange px-3">
          <div className="mb-2 mt-4">
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h6 className="m-0">Users</h6>
              {getUserRole() !== "HiringManager" && (
                <button
                  type="button"
                  className="btn btn-sm"
                  onClick={() => setAddPopup(true)}
                >
                  <i className="fal fa-user-plus mr-2" aria-hidden="true"></i>
                  Add User
                </button>
              )}
            </div>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <select className="form-select mb-2" onChange={handleChange}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
              <div className="col-lg-6 col-md-8 col-sm-12 col-xs-12 d-flex align-items-start justify-content-end">
                <div className="flex-grow-1 me-1" style={{ maxWidth: "250px" }}>
                  <SearchEmployee
                    setSearchValue={setSearchValue}
                    searchValue={searchValue}
                    setEmployeesDetails={setEmployeesDetails}
                    sortData={sortData}
                    setPageCount={setPageCount}
                    setShowingCount={setShowingCount}
                    setTotalEmployee={setTotalEmployee}
                    pageShowCount={pageShowCount}
                    placeholder="Search Users by Name and Email"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="px-3 pt-0 table-cards-1024">
            <table class="table table-hoverabletable-cards-1024">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Email Status</th>
                  <th scope="col">Role</th>
                  <th scope="col">Designation</th>
                  <th scope="col">Last Logged In </th>
                  <th scope="col">Status </th>

                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {employeesDetails.map((item) => (
                  <tr>
                    <td
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(item, "employee")}
                    >
                      <a href="javascript:void(0)">{item.name}</a>
                    </td>
                    <td>{item.email}</td>
                    <td className="no-hover">
                      <div
                        className="d-inline-block"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="View Email Status"
                      >
                        <button
                          type="button"
                          className="btn btn-square btn-gray"
                          onClick={() => {
                            setEmailData(item?.email);
                            setEmailStatus(true);
                          }}
                        >
                          <i class="fa fa-envelope-o" />
                        </button>
                      </div>
                    </td>
                    <td>
                      {item.isOwner
                        ? getUserRole() == "Admin"
                          ? `Client Admin`
                          : `${roleNames[item.role]} Admin`
                        : roleNames[item.role]}
                    </td>
                    <td>{item.designation}</td>
                    <td>
                      {item.lastLogin
                        ? moment(new Date(item.lastLogin)).format("MM/DD/YYYY")
                        : "Never"}
                    </td>
                    <td>
                      {item.statusname === "Active" ? (
                        <span class="tag tag-green2">Active</span>
                      ) : (
                        <span class="tag tag-red2">Inactive</span>
                      )}
                    </td>

                    <td className="no-hover text-right">
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="right"
                        overlay={
                          <div
                            className="dropdown-menu dropdown-menu-right show"
                            style={{
                              display: "revert",
                              right: "0px",
                              top: "10px",
                            }}
                          >
                            <div className="admindeleteedit">
                              <ul>
                                <button
                                  href="#"
                                  className="dropdown-item"
                                  disabled={
                                    item.userId == getCandidateId() ||
                                    item.statusname === "InActive"
                                  }
                                  onClick={() => handleClick(item, "edit")}
                                >
                                  <i
                                    className="fas fa-edit mr-2"
                                    aria-hidden="true"
                                  ></i>{" "}
                                  Edit
                                </button>
                                {item.statusname === "Active" && (
                                  <button
                                    type="button"
                                    class="dropdown-item"
                                    disabled={item.userId == getCandidateId()}
                                    onClick={() => handleClick(item, "delete")}
                                  >
                                    <i
                                      class="fa fa-trash mr-2"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Deactivate
                                  </button>
                                )}
                                {item.statusname === "InActive" && (
                                  <button
                                    type="button"
                                    class="dropdown-item"
                                    onClick={() =>
                                      handleClick(item, "activate")
                                    }
                                    disabled={item.userId == getCandidateId()}
                                  >
                                    <i
                                      class="fa fa-key mr-2"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Activate
                                  </button>
                                )}
                              </ul>
                            </div>
                          </div>
                        }
                      >
                        <button
                          type="button"
                          className="btn btn-icon dropdown-toggle"
                        >
                          <i className="far fa-ellipsis-v"></i>
                        </button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {employeesDetails.length === 0 && (
              <div>
                <div class="text-center p-3">
                  <div class="avatar avatar-lg">
                    <i class="fad fa-users-slash"></i>
                  </div>

                  <div class="mt-2">
                    No records found {searchValue ? `for "${searchValue}"` : ""}
                  </div>
                </div>
              </div>
            )}
            <hr className="m-0" />{" "}
            <div className="d-flex align-items-center mt-3 mb-2">
              <div>
                Showing {showingCount} of {totalEmployee}
              </div>

              <div class="ml-auto mx-auto-370 d-flex align-items-center">
                <ReactPaginate
                  pageCount={pageCount}
                  pageRange={2}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName={"paginationContainer"}
                  previousLinkClassName={"paginationPage"}
                  breakClassName={"paginationPage"}
                  nextLinkClassName={"paginationPage"}
                  pageClassName={"paginationPage1"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  previousLabel={
                    <i
                      className="fal fa-chevron-circle-left"
                      style={{ "font-size": "17px" }}
                    ></i>
                  }
                  nextLabel={
                    <i
                      className="fal fa-chevron-circle-right"
                      style={{ "font-size": "17px" }}
                    ></i>
                  }
                />
              </div>
            </div>
          </div>
        </div>{" "}
        {emailStatus && (
          <EmailStatus
            email={emailData}
            emailStatus={emailStatus}
            setEmailStatus={setEmailStatus}
            isFrom="CSM_Users"
          />
        )}
        {employeePopup && (
          <EmployeePopUp
            roleNames={roleNames}
            employee={selectedItem}
            closeModal={closePopup}
            editFun={handleClick}
            DeleteRequestFun={handleClick}
            EnabletenantFun={handleClick}
          />
        )}
        {addPopup && (
          <AddEmployee
            roleNames={roleNames}
            handleSuccess={closePopup}
            addPopup={addPopup}
          />
        )}
        {editPopup && (
          <EditEmployee
            roleNames={roleNames}
            handleSuccess={closePopup}
            editPopup={editPopup}
            employeeData={selectedItem}
          />
        )}
        {deletePopup && (
          <DeactivateEmployee
            roleNames={roleNames}
            handleSuccess={closePopup}
            deletePopup={deletePopup}
            employeeData={selectedItem}
          />
        )}
        {activatePopup && (
          <ActivateEmployee
            roleNames={roleNames}
            handleSuccess={closePopup}
            activatePopup={activatePopup}
            employeeData={selectedItem}
          />
        )}
      </div>
    </>
  );
};

export default Employees;
