import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import IntakeForm from "../modal/intakeform/IntakeForm";
import { getUserId, getCandidateName } from "../../service/AuthService";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import { post } from "../../service/ApiService";
import SidePanel from "../modal/intakeform/EditIntake/SidePanel";
import EditIntakeForm from "../modal/intakeform/EditIntake/EditIntakeForm";
import Pagination from "./Pagination";
import AddJobNew from "../modal/addjob/AddJobNew";

function DashboardIntakeForm() {
  const [showIntakeFormModal, setShowIntakeFormModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [intakeform, setIntakeForm] = useState([]);
  const [jobStatus, setJobStatus] = useState(false);
  const userId = getUserId();
  const userName = getCandidateName();
  const [editIntake, setEditIntake] = useState(false);
  const [dataLimit, setDataLimit] = useState(10);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [intakeDetails, setIntakeDetails] = useState({});
  const [intakeSidePanel, setIntakeSidePanel] = useState(false);
  const [sidePanelLoading, setSidePanelLoading] = useState(false);
  const [addJobModal, setAddJobModal] = useState(false);
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [successData, setSuccessData] = useState({
    jobId: "",
    position: "",
    startDate: "",
    location: "",
    salary: "",
    skills: "",
  });

  useEffect(() => {
    fetchIntakeDetails();
  }, []);

  const fetchIntakeDetails = async () => {
    try {
      setLoading(true);
      const payload = {
        userId: userId,
      };
      let result = await post("/intakedetail/getintakedetailsbyId", payload);
      if (result.status === 200) {
        console.log(result.data.value);

        //console.log('IF', result.data.value)
        const distantFuture = new Date(0);

        const getLatest = result.data.value
          .sort((a, b) => {
            let dateA = a.createdDate ? new Date(a.createdDate) : distantFuture;
            let dateB = b.createdDate ? new Date(b.createdDate) : distantFuture;
            return dateB - dateA;
          })
          .map((each) => {
            each.createdDate = each.createdDate
              ? new Date(each.createdDate)
              : distantFuture;
            return each;
          });
        console.log(getLatest);
        setIntakeForm(getLatest);
        setLoading(false);
      } else {
        toast.error("Error Occured while accessing Intake Details");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error Occured while accessing Intake Details");
      setLoading(false);
    }
  };

  const closeIntakeFormModalPopup = () => {
    setShowIntakeFormModal(false);
  };

  const openIntakeFormModalPopup = () => {
    setShowIntakeFormModal(true);
  };

  const autoMatch = async (data) => {
    let result = await post("/automatch/autoMatchJob", data);
  };

  const onAddJobClick = async (intakedata) => {
    try {
      setLoading(true);
      let result = await post("/request/addRequestIntake", intakedata);
      if (result.status === 200) {
        UpdateJobStatusToIndex(intakedata.intakeId);
        setLoading(false);
        //autoMatch(intakedata.jobDetails[0])
      } else {
        toast.error("Error occured while creating Job");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      //toast.error('Error occured while creating Job')
      setLoading(false);
    }
  };

  const UpdateJobStatusToIndex = async (id) => {
    try {
      setLoading(true);
      const payload = {
        intakeId: id,
        name: userName,
      };
      let result = await post("/intakedetail/updatejobstatus", payload);
      if (result.status === 200) {
        //console.log('jobstatus', result)
        // setJobStatus(result.data.value[0]);
        fetchIntakeDetails();
        toast.success("Job Created Successfully!!!");

        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      //toast.error("Error Occured while updating job Status");
      setLoading(false);
    }
  };

  const openSidePanel = async (item) => {
    setSidePanelLoading(true);
    const params = { intakeId: item.intakeId, documents: item.documents };
    try {
      const res = await post(`/intake/getInTakeDocs`, params);
      setIntakeDetails({ ...item, documents: res.data });
      setIntakeSidePanel(true);
    } catch (error) {
      console.log(error);
    }
    setSidePanelLoading(false);
  };

  const onChangeSelect = (e) => {
    if (e.target.value === "all") {
      setDataLimit(intakeform.length);
    } else {
      setDataLimit(e.target.value);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    setSearchData(
      intakeform.filter((item) =>
        item.jobDetails[0].jobTitle.toLowerCase().includes(value.toLowerCase())
      )
    );
  };

  return (
    <>
      {(loading || sidePanelLoading) && <Loader />}
      <div class="card-body">
        <div>
          <div class="p-3 mb-2">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h6 class="m-0">Intake Forms</h6>
              <button
                type="button"
                class="btn btn-sm"
                // onClick={openIntakeFormModalPopup}
                onClick={() => setAddJobModal(true)}
              >
                <i class="fal fa-user-plus mr-2"></i>
                Add Intake Form
              </button>
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div class="col-lg-3 col-md-4 col-sm-12 col-12">
                <select class="form-select mb-2" onChange={onChangeSelect}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="all">All</option>
                </select>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-12 col-12">
                <input
                  type="text"
                  placeholder="Search Intake Forms"
                  class="form-control small font-14"
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
            </div>
          </div>
          {loading ? (
            <h6 className="m-5">Fetching Intake Details...</h6>
          ) : intakeform.length !== 0 ? (
            <Pagination
              data={intakeform}
              pageLimit={5}
              dataLimit={dataLimit}
              searchData={searchData}
              openSidePanel={openSidePanel}
              searchValue={searchValue}
              onAddJobClick={onAddJobClick}
            />
          ) : (
            <div class="px-3 pt-0 table-cards-1024">No data found</div>
          )}
        </div>
      </div>
      <hr class="m-0" />
      <Modal show={showIntakeFormModal} size="lg">
        <div className="modal-header" id="NewRequestModal">
          <div className="">
            <h6 className="">{"Intake Form"}</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeIntakeFormModalPopup}
              className="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <Modal.Body>
          <IntakeForm
            closeModal={closeIntakeFormModalPopup}
            fetchIntakeDetails={fetchIntakeDetails}
          />
        </Modal.Body>
      </Modal>
      {intakeSidePanel && (
        <SidePanel
          details={intakeDetails}
          closePanel={() => setIntakeSidePanel(false)}
          openEditModal={() => {
            setEditIntake(true);
          }}
          component={"SalesAdmin"}
        />
      )}
      <Modal show={editIntake} size="lg">
        <div className="modal-header" id="NewRequestModal">
          <div className="">
            <h6 className="">Edit Intake Form</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setEditIntake(false)}
              className="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </div>
        <Modal.Body>
          <EditIntakeForm
            details={intakeDetails}
            closeModal={() => setEditIntake(false)}
          />
        </Modal.Body>
      </Modal>

      <Modal show={addJobModal} size="lg">
        <Modal.Header>
          <h6 class="">Add Intake</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setAddJobModal(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <AddJobNew
            setSuccessData={setSuccessData}
            setSuccessFormOpen={setSuccessFormOpen}
            closePanel={() => setAddJobModal(false)}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DashboardIntakeForm;
