import moment from "moment";
import React, { useState } from "react";

const ReportTable = ({ getPaginatedData, reportHeaders }) => {
  const [showAllRows, setShowAllRows] = useState(
    Array(getPaginatedData().length).fill(false)
  );
  const fkRoleNames = {
    1: "Admin",
    2: "Candidate",
    3: "Recruiter",
    4: "Hiring Manager",
    5: "Employee",
    6: "Super Admin",
    7: "Sales Admin",
    8: "Customer Success Manager",
    9: "Marketplace Manager",
    10: "Sales",
    11: "High5 Admin",
  };

  const toggleShowAll = (index) => {
    setShowAllRows((prevShowAllRows) => {
      const newShowAllRows = [...prevShowAllRows];
      newShowAllRows[index] = !newShowAllRows[index];
      return newShowAllRows;
    });
  };

  return (
    <div>
      {getPaginatedData().length > 0 ? (
        <div
          className="px-3 pt-0 table-cards-1024 mt-3"
          style={{
            overflowX: "scroll",
          }}
        >
          <table
            className="table table-hoverabletable-cards-1024"
            id="table-to-xls"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <thead>
              <tr>
                {reportHeaders.map((header, index) => (
                  <th key={index}>{header.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {getPaginatedData().map((item, index) => (
                <tr key={index}>
                  <td>{item?.firstName}</td>
                  <td>{item?.lastName}</td>
                  <td>{item?.email}</td>
                  {/* <td>
                    {moment(new Date(item?.createddatetime)).format(
                      "MM/DD/YYYY"
                    )}
                  </td> */}
                  <td>{fkRoleNames[item?.fk_Role]}</td>

                  <td>
                    {item?.unsubscribedReason ? item?.unsubscribedReason : ""}
                  </td>

                  {/* ...other table cells... */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div style={{ visibility: "hidden" }}>
          <div className="text-center">
            <div className="avatar avatar-lg">
              <i
                aria-hidden="true"
                // className="fad fa-folder-open"
              ></i>
            </div>{" "}
            <div className="mt-6"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportTable;
