import {
  GET_ANALYTICS_BEGIN,
  GET_ANALYTICS_SUCCESS,
  GET_ANALYTICS_FAILURE,
} from "../../actions/analytics/getAnalyticsData";

const initialState = {
  analyticsData: null,
};

const getAnalyticsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_ANALYTICS_BEGIN:
      return {
        ...state,
        analyticsData: null,
      };
    case GET_ANALYTICS_SUCCESS:
      return {
        ...state,
        analyticsData: payload,
      };
    case GET_ANALYTICS_FAILURE:
      return {
        ...state,
        analyticsData: null,
      };
    default:
      return state;
  }
};

export default getAnalyticsReducer;
