import moment from "moment";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger } from "react-bootstrap";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import ActivateUser from "../../../../../components/superadmin/popups/ActivateUser";
import UserDetails from "../../../../../components/superadmin/popups/UserDetails";
import DeactiveUser from "../../../../../components/superadmin/popups/DeactiveUser";
import Pagination from "../../../../components_v3/pagination/pagination";
import Avatar from "react-avatar";

const UserTable = ({
  isFrom,
  prefix,
  userData,
  getusersData,
  currentPage,
  setCurrentPage,
  dataSize,
  setDataSize,
}) => {
  const [addusers, setAddUsers] = useState(false);
  const [openUserPopUP, setopenUserPopUP] = useState(false);
  const [userObj, setuserObj] = useState([]);
  const [addusersCondition, setAddUsersCondition] = useState(false);
  const [editusers, seteditUsers] = useState(false);
  const [enableUserValue, setEnableUserButton] = useState(false);
  const [DeleteRequestValue, setDeleteRequestButton] = useState(false);
  const [users, setUsers] = useState(userData);
  const [searchValue, setSearchValue] = useState("");
  const [dataTotal, setDataTotal] = useState(0);
  const [searchData, setSearchData] = useState([]);

  useEffect(() => {
    setCurrentPage(1);
    setSearchData(userData);
    setUsers(userData && userData.slice(0, 10));
    setDataTotal(userData.length);
  }, [userData]);

  const handleChange = (e) => {
    setCurrentPage(1);
    let ds = e.target.value === "All" ? 999999 : e.target.value * 1;
    setDataSize(ds);
    const slicedData = searchData && searchData.slice(0, ds);
    setUsers(slicedData);
  };

  const addUsers = () => {
    setAddUsers(true);
    setAddUsersCondition(true);
  };

  const closePopUP = () => {
    setAddUsers(false);
    setopenUserPopUP(false);
  };

  const openUserPopUPFun = (item) => {
    setopenUserPopUP(true);
    setuserObj(item);
  };

  const openeditusers = (item) => {
    setuserObj(item);
    seteditUsers(true);
  };

  const closeEditPopUp = () => {
    seteditUsers(false);
  };

  const EnabletenantFun = (item) => {
    setuserObj(item);
    setopenUserPopUP(false);
    setEnableUserButton(true);
  };

  const EnableUserClose = (e) => {
    setEnableUserButton(false);
    setopenUserPopUP(false);
  };

  const DeleteRequestFun = (item) => {
    setuserObj(item);
    setopenUserPopUP(false);
    setDeleteRequestButton(true);
  };

  const DeleteRequestClose = (e) => {
    setDeleteRequestButton(false);
    setopenUserPopUP(false);
  };
  const handleClearInput = () => {
    setSearchValue("");
    setUsers(userData);
    setSearchData(userData);
    setDataTotal(userData.length);
  };
  const searchDetails = (e) => {
    setSearchValue(e.target.value);
    setCurrentPage(1);
    const filteredCompanies = userData.filter((val) => {
      if (e.target.value == "") {
        return val;
      } else if (
        val.name.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val.email.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val.status.toLowerCase() === e.target.value.toLowerCase()
      ) {
        return val;
      }
    });
    setSearchData(filteredCompanies);
    setDataTotal(filteredCompanies.length);
    setUsers(filteredCompanies.slice(0, 10));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    const startIndex = (pageNumber - 1) * dataSize;
    const slicedData =
      searchData && searchData.slice(startIndex, startIndex + dataSize);
    setUsers(slicedData);
  };

  return (
    <div className="card mt-3">
      <div className="card-body">
        <div class="p-3">
          <div class=" mb-2 ">
            <div class="d-flex align-items-center justify-content-between mb-4">
              <h6 class="m-0">{!isFrom && prefix} Users</h6>

              <button onClick={addUsers} type="button" class="btn btn-sm">
                <i class="fal fa-user-plus mr-2" aria-hidden="true"></i>Add User
              </button>
            </div>
            <div class="d-flex align-items-center justify-content-between flex-wrap">
              <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <select
                  class="form-select mb-2"
                  value={dataSize}
                  onChange={handleChange}
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  {/* <option value="All">All</option> */}
                </select>
              </div>
              <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                <div class="mb-2">
                  <div className="search-input-container">
                    <input
                      type="text"
                      placeholder="Search Users"
                      className="form-control small font-14"
                      value={searchValue}
                      onChange={searchDetails}
                    />
                    {searchValue && (
                      <button
                        className="clear-button"
                        onClick={handleClearInput}
                      >
                        <i className="fas fa-times"></i>
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="pt-0 mb-3 table-cards-1024">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">User Type</th>
                  <th scope="col">Created on</th>
                  <th scope="col">Status</th>
                  <th scope="col"></th>
                </tr>
              </thead>

              <tbody>
                {users.map((item) => (
                  <tr>
                    <td
                      onClick={() => openUserPopUPFun(item)}
                      style={{ cursor: "pointer" }}
                    >
                      <div class="avatar-name-candidatestableview d-flex align-items-center">
                        <div>
                          <span>
                            <Avatar size="30" name={item.name} round="80px" />
                          </span>
                        </div>
                        <div class="ml-3">
                          <strong class="mb-0 me-2">{item.name}</strong>
                        </div>
                      </div>
                      {/* <span>{item.name}</span> */}
                    </td>

                    <td>{item.email}</td>

                    <td>
                      {item.type === "CustomerSuccess"
                        ? "High5 Admin"
                        : item.type === "High5Coordinator"
                        ? "Customer Success Manager"
                        : "Marketplace Manager"}
                    </td>
                    <td>
                      <span>
                        {moment(new Date(item.createddatetime)).format(
                          "MM/DD/YYYY"
                        )}
                      </span>
                    </td>

                    <td>
                      {item.status === "Active" ? (
                        <span class="tag tag-blue2" v-if="item.isActive">
                          Active
                        </span>
                      ) : (
                        <span class="tag tag-red2">Deactivated</span>
                      )}
                    </td>

                    <td class="text-right no-hover">
                      <div class="dropdown ml-4">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="left"
                          overlay={
                            <div className="dropdown-menu dropdown-menu-left">
                              <div className="admindeleteedit">
                                <ul className="m-0">
                                  {item.status === "Active" && (
                                    <button
                                      href="#"
                                      class="dropdown-item"
                                      onClick={() => openeditusers(item)}
                                    >
                                      <i
                                        class="fas fa-edit mr-2"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Edit
                                      <a />
                                    </button>
                                  )}
                                  {item.status === "Active" ? (
                                    <button
                                      type="button"
                                      class="dropdown-item"
                                      onClick={(e) => {
                                        DeleteRequestFun(item);
                                      }}
                                      getusersData={userData}
                                    >
                                      <i
                                        class="fa fa-trash mr-2"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Deactivate
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      class="dropdown-item"
                                      onClick={(e) => {
                                        EnabletenantFun(item);
                                      }}
                                      getusersData={userData}
                                    >
                                      <i
                                        class="fa fa-key mr-2"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Activate
                                    </button>
                                  )}{" "}
                                </ul>
                              </div>
                            </div>
                          }
                        >
                          <button
                            type="button"
                            class="btn btn-icon dropdown-toggle"
                          >
                            <i class="far fa-ellipsis-v"></i>
                          </button>
                        </OverlayTrigger>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {searchData.length === 0 && (
              <div>
                <div class="text-center p-3">
                  <div class="avatar avatar-lg">
                    <i aria-hidden="true" class="fad fa-folder-open"></i>
                  </div>{" "}
                  <div class="mt-6">No records found</div>
                </div>
              </div>
            )}
            <hr class="m-0" />
            {searchData.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalItems={dataTotal}
                itemsPerPage={dataSize}
                onPageChange={handlePageChange}
                limit={10}
              />
            )}
          </div>
        </div>
      </div>{" "}
      {/* add user */}
      <Modal show={addusers} size="lg">
        <AddUser
          closeAdd={closePopUP}
          userlist={userObj.length !== 0 ? userObj : null}
          addusersCondition={addusersCondition}
          getusersData={getusersData}
        />
      </Modal>
      {/* edit user  */}
      <Modal show={editusers} size="lg">
        <EditUser
          closeAdd={closeEditPopUp}
          userlist={userObj.length !== 0 ? userObj : null}
          getusersData={getusersData}
        />
      </Modal>
      {/* enable user  */}
      <Modal show={enableUserValue} size="delete">
        <ActivateUser
          handleSuccess={EnableUserClose}
          getusersData={getusersData}
          isFrom={"EADash"}
          employeeDelete={"employeedelete"}
          employee={userObj.length !== 0 ? userObj : null}
          users={"users"}
        />
      </Modal>
      {/* user details */}
      <Modal show={openUserPopUP} dialogClassName="view-panel show">
        <UserDetails
          closeModal={closePopUP}
          users={"users"}
          employee={userObj.length !== 0 ? userObj : null}
          getusersData={getusersData}
        />
      </Modal>
      {/* disable user  */}
      <Modal show={DeleteRequestValue} size="delete">
        <DeactiveUser
          handleSuccess={DeleteRequestClose}
          employeeDelete={"employeedelete"}
          employee={userObj.length !== 0 ? userObj : null}
          users={"users"}
          getusersData={getusersData}
        />
      </Modal>
    </div>
  );
};

export default UserTable;
