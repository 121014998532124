import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import CropperModal from "./CropperModal";

const ImageUpload = ({ imageData, setImageData }) => {
  const [showCropper, setShowCropper] = useState(false);

  const onDragDropChangeHandler = (acceptedFiles) => {
    if (acceptedFiles.length > 1) {
      toast.error("Please select only one file.");
      return;
    }

    const file = acceptedFiles[0];

    if (file.size > 2 * 1024 * 1024) {
      toast.error("File size exceeds 2MB limit.");
      return;
    }

    // Proceed with processing the selected file
    const reader = new FileReader();
    reader.onload = () => {
      const base64Data = reader.result;
      setImageData({ ...imageData, fileName: file.name, base64: base64Data });
    };
    reader.readAsDataURL(file);
    toast.success("Logo Added");
  };

  const handleDelete = () => {
    setImageData({ ...imageData, fileName: "", base64: "" });
    toast.success("Logo Removed");
  };

  const handleSaveCrop = (croppedImage) => {
    // Handle saving the cropped image data
    // For example, you can update the imageData state with the cropped image data
    setImageData({ ...imageData, base64: croppedImage });
    setShowCropper(false); // Close the CropperModal after saving
  };
  return (
    <div className="form-floating">
      <Dropzone
        onDrop={onDragDropChangeHandler}
        accept="image/jpeg, image/png"
        multiple={false} // Allow only one file to be selected
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <div class="talentuploadmaindiv p-5 m-1 mt-4">
                {imageData.base64 ? (
                  <div className="talentuploadtext">
                    {/* Display the image */}
                    <img
                      src={imageData?.base64}
                      alt="Uploaded"
                      width="85"
                      height="85"
                      className="p-2"
                      style={{
                        borderRadius: "50%",
                        objectFit: "contain",
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                      }}
                    />
                    <div className="mt-0 d-flex justify-content-center align-content-center">
                      <span
                        style={{
                          cursor: "pointer",
                          borderRadius: "50%",
                          width: "85px",
                          display: "flex",
                          justifyContent: "end",
                          transform: "translate(-0%, -100%)",
                        }}
                        onClick={() => {
                          setShowCropper(true);
                        }}
                      >
                        <i className="fa fa-edit" aria-hidden="true"></i>
                      </span>
                    </div>
                    <div className="mt-2">
                      <span>{imageData?.fileName}</span>
                      <span
                        class="ml-1"
                        style={{
                          bottom: 0,
                          left: 0,
                          cursor: "pointer",
                        }}
                        onClick={handleDelete}
                      >
                        <i class="fa fa-trash" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                ) : (
                  <>
                    <div class="talentuploadicon">
                      <label>
                        <i class="fas fa-cloud-upload-alt"></i>
                      </label>
                      <input type="file" name="myfile" {...getInputProps()} />
                    </div>
                    <div class="talentuploadtext">
                      Click or Drag and Drop Logo to Upload (JPEG or PNG only)
                      <p>Maximum size: 2 MB</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </section>
        )}
      </Dropzone>
      {showCropper && (
        <CropperModal
          imageData={imageData}
          setImageData={setImageData}
          showCropper={showCropper}
          setShowCropper={setShowCropper}
          handleSave={handleSaveCrop}
        />
      )}
    </div>
  );
};

export default ImageUpload;
