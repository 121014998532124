import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import Card from "../../superAdmin/Dashboard/Card";
import NewTalentPoolList from "../../../talent/NewTalentPoolList";
import NewJobList from "../../job/joblist/NewJobList";
import {
  getTenantType,
  getUserId,
  getUserTenant,
} from "../../../../service/AuthService";
import { getRecruiterDashboardData } from "../../../../actions/dashboard/recruiter/RecruiterDashboard";
import Index from "./talentcommunity/Index";
import CurationTask from "../../curationTasks/CurationTask";
import { isCurator } from "../../../../service/AuthService";

const DashboardCards = () => {
  const dispatch = useDispatch();
  const [dashboardCount, setDashboardCount] = useState({
    jobCount: 0,
    communityCount: 0,
    talentCount: 0,
    clientCount: 0,
    clientCommunity: 0,
  });
  const [view, setView] = useState({
    jobs: true,
    talents: false,
    publicTalentCommunity: false,
    subCommunity: false,
    curationTasks: false,
    talentCommunities: false,
  });

  const cardStyle = {
    minWidth: "15rem",
    maxWidth: "20rem",
    marginRight: "10px",
    flex: "1 1 0", // Ensure equal width distribution
  };

  const { recruiterCount } = useSelector(
    (state) => ({
      recruiterCount: state.getrecruiterDashboardReducer.recruiterCount,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getRecruiterDashboardData());
  }, []);

  useEffect(() => {
    if (recruiterCount && recruiterCount.length > 0) {
      const newDashboardCount = {
        ...dashboardCount,
        jobCount:
          recruiterCount.find((item) => item.type === "jobCount")?.count || 0,
        talentCount:
          recruiterCount.find((item) => item.type === "talentCount")?.count ||
          0,
        clientCount:
          getTenantType() === 2
            ? recruiterCount.find((item) => item.type === "clientCount")
                ?.count || 0
            : 0,
        clientCommunity:
          getTenantType() === 1
            ? recruiterCount.find((item) => item.type === "clientCommunity")
                ?.count || 0
            : 0,
      };

      if (getTenantType() === 2) {
        newDashboardCount.communityCount =
          recruiterCount.find((item) => item.type === "communityCount")
            ?.count || 0;
      }
      setDashboardCount(newDashboardCount);
    }
  }, [recruiterCount]);

  return (
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div className="d-flex gap-2 overflow" style={{ width: "100%" }}>
          <div className="d-flex justify-content-around  px-2 d-block-768  mb-2 mb-xl-0 w-100">
            <Card
              view={view}
              cardStyle={cardStyle}
              setView={setView}
              elem={{
                heading: "Jobs",
                title: "Jobs",
                count: dashboardCount.jobCount,
                tooltip: "Total number of jobs registered.",
              }}
              isFrom={"recDashboard"}
            />{" "}
            {getTenantType() === 2 && (
              <Card
                view={view}
                cardStyle={cardStyle}
                setView={setView}
                elem={{
                  heading: "Branded Talent Communities",
                  title: "Branded Talent Communities",
                  count: dashboardCount.communityCount,
                  tooltip:
                    "Total number of branded talent communities created by other companies.",
                }}
                isFrom={"recDashboard"}
              />
            )}
            <Card
              view={view}
              cardStyle={cardStyle}
              setView={setView}
              elem={{
                heading: "My Talent Pool",
                title: "Talent",
                count: dashboardCount.talentCount,
                tooltip: `Total number of talent registered in ${getUserTenant().replace(
                  "GIG - ",
                  ""
                )}. This does not include the talent invited to jobs or those who have received invitations but not register on the platform.`,
              }}
              isFrom={"recDashboard"}
            />
            {isCurator() && getTenantType() == 2 && (
              <Card
                view={view}
                cardStyle={cardStyle}
                setView={setView}
                elem={{
                  heading: "Curation Tasks",
                  title: "Curation Tasks",
                  count: dashboardCount.clientCount,
                  tooltip: `Total number of clients`,
                }}
                isFrom={"recDashboard"}
              />
            )}
            {getTenantType() === 1 && (
              <Card
                view={view}
                cardStyle={cardStyle}
                setView={setView}
                elem={{
                  heading: "Talent Communities",
                  title: "Talent Communities",
                  count: dashboardCount.clientCommunity,
                  tooltip: `Total number of talent community`,
                }}
                isFrom={"recDashboard"}
              />
            )}
          </div>
        </div>
        <hr />
        <div>
          {view.jobs && <NewJobList />}
          {view.publicTalentCommunity && <Index />}
          {view.talents && <NewTalentPoolList />}
          {view.curationTasks && <CurationTask />}
          {view?.talentCommunities && <Index />}
        </div>
      </div>
    </div>
  );
};

export default DashboardCards;
