import React, { useState, useEffect } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ReactPaginate from "react-paginate";
import AddTenant from "./AddTenant";
import Modal from "react-bootstrap/Modal";
import { get } from "../../../service/ApiService";
import { toast } from "react-toastify";
//import SupplierDetailsPopUP from '../../Tenant/TenantPopUps/SupplierDetailsPopUp'
import DeleteTenant from "../popups/DeleteTenant";
import ActivateTenant from "../popups/ActivateTenant";
import EditTenant from "./EditTenant";
import TenantDetailsPopUp from "../popups/TenantDetailsPopUp";
import Loader from "../../Loader/Loader";
import { getUserRole } from "../../../service/AuthService";
import moment from "moment";
import EmailStatus from "../../components_v3/EmailStatus/EmailStatus";
function ManageTenants(props) {
  const [tenantDetails, setTenantDetails] = useState([]);
  const [tenantData, setTenantData] = useState([]);
  const [editTenant, setEditTenant] = useState(false);
  const [tenantObj, settenantObj] = useState({});
  const [selectedTenantObj, setSelectedTenantObj] = useState({});
  const [openSupplierDetails, setOpenSuppliersDetails] = useState(false);
  const [supplierDetails, setSupplierDetails] = useState([]);
  const [DeleteRequestValue, setDeleteRequestButton] = useState(false);
  const [addTenant, setAddTenant] = useState(false);
  const [selectedtenant, setSelectedTenant] = useState();
  const [selecteduser, setSelectedUser] = useState();
  const [activateUserValue, setActivateUserValue] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [showingCount, setShowingCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [totalTenant, setTotalTenant] = useState(0);
  const [pageShowCount, setPageShowCount] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [openTenantPopUP, setopenTenantPopUP] = useState(false);
  const [searchTenant, setSearchTenant] = useState("");
  const [tenantEmail, setTenantEmail] = useState();
  const [tenantType, setTenantType] = useState("");
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState({});

  useEffect(() => {
    const role = getUserRole();
    GettenantData();
  }, []);
  // get data from
  const GettenantData = async () => {
    try {
      const userRole = getUserRole();
      setLoading(true);
      const res = await get("/tenant/gettenantlist");
      if (res.status === 200) {
        const result = res.data.sort(
          (a, b) => new Date(b.createddatetime) - new Date(a.createddatetime)
        );

        if (userRole === "SalesAdmin") {
          const filterResult = result.filter(
            (eachUser) => eachUser.typename === "Client"
          );
          setTenantData([...filterResult]);
          setSearchTenant([...result]);
          setTenantDetails(filterResult.slice(offset, offset + pageShowCount));
          setPageCount(Math.ceil(filterResult.length / pageShowCount));
          setTotalTenant(filterResult.length);
          const showingCount =
            filterResult.length > pageShowCount
              ? pageShowCount
              : filterResult.length;
          setShowingCount(showingCount);
        } else if (
          userRole === "SuperAdmin" ||
          userRole === "CustomerSuccess"
        ) {
          setTenantData(result);
          setSearchTenant([...result]);
          setTenantDetails(result.slice(offset, offset + pageShowCount));
          setPageCount(Math.ceil(result.length / pageShowCount));
          setTotalTenant(result.length);
          const showingCount =
            result.length > pageShowCount ? pageShowCount : result.length;
          setShowingCount(showingCount);
        } else if (userRole === "CommunityManager") {
          const filterResult = result.filter(
            (eachUser) => eachUser.typename === "Staffing Agency"
          );
          setTenantData([...filterResult]);
          setSearchTenant([...result]);
          setTenantDetails(filterResult.slice(offset, offset + pageShowCount));
          setPageCount(Math.ceil(filterResult.length / pageShowCount));
          setTotalTenant(filterResult.length);
          const showingCount =
            filterResult.length > pageShowCount
              ? pageShowCount
              : filterResult.length;
          setShowingCount(showingCount);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing tenant Details");
    }
  };
  const openAddTenant = () => {
    setAddTenant(true);
  };
  const closePopUP = () => {
    setAddTenant(false);
    setOpenSuppliersDetails(false);
  };
  const openedittenant = (item) => {
    settenantObj(item);
    setEditTenant(true);
  };
  const closeEditPopUp = () => {
    setEditTenant(false);
  };
  const openTenantPopUPFun = (item) => {
    //GettenantData();
    setopenTenantPopUP(true);
    settenantObj(item);
    //  setTenantDetails(item);
  };
  const closeTenantPopUP = () => {
    setopenTenantPopUP(false);
  };
  const openSuppliers = (item) => {
    setOpenSuppliersDetails(true);
    setSupplierDetails(item);
  };
  const DeleteRequestFun = (e, item) => {
    setTenantEmail(item.useradminaccount);
    setSelectedTenant(item.tenantname);
    setSelectedTenantObj(item);
    setSelectedUser(item.id_useraccessdetail);
    setTenantType(item.typename);
    setDeleteRequestButton(true);
  };
  const EnabletenantFun = (e, tenantName, item, userid, useradminaccount) => {
    //   setSupplierDetails(tenantName);
    setTenantEmail(useradminaccount);
    setSelectedTenant(tenantName);
    setSelectedTenantObj(item);
    setSelectedUser(userid);
    setActivateUserValue(true);
  };
  const DeleteRequestClose = (e) => {
    setDeleteRequestButton(false);
  };
  const EnableTenantClose = (e) => {
    setActivateUserValue(false);
  };
  const handlePageChange = (selectedObject) => {
    const currPage = selectedObject.selected;
    setCurrentPage(currPage);
    const offsetValue = currPage * pageShowCount;
    setPageCount(Math.ceil(totalTenant / pageShowCount));
    setTenantDetails(
      tenantData.slice(offsetValue, offsetValue + pageShowCount)
    );
    setOffset(offsetValue);
    const count =
      totalTenant > (currPage + 1) * pageShowCount
        ? (currPage + 1) * pageShowCount
        : totalTenant;
    setShowingCount(count);
  };
  const handleChange = (e) => {
    let showCount = 0;
    if (e.target.value === "All") {
      showCount = totalTenant;
    } else {
      showCount = Number(e.target.value);
    }
    setPageShowCount(showCount);
    const offsetValue = currentPage * showCount;
    setPageCount(Math.ceil(totalTenant / showCount));
    setTenantDetails(tenantData.slice(offsetValue, offsetValue + showCount));
    setOffset(offsetValue);
    const count =
      totalTenant > (currentPage + 1) * e.target.value
        ? (currentPage + 1) * e.target.value
        : totalTenant;
    setShowingCount(count);
  };
  const role = getUserRole();
  const filterCompanies = (e) => {
    const filteredCompanies = searchTenant.filter((val) => {
      if (e.target.value == "") {
        return val;
      } else if (
        val.tenantname.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val.useradminaccount
          .toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        val.typename.toLowerCase().includes(e.target.value.toLowerCase()) ||
        val.statusname.toLowerCase() === e.target.value.toLowerCase() ||
        val.firstName.toLowerCase().includes(e.target.value.toLowerCase())
      ) {
        return val;
      }
    });
    setTenantData(filteredCompanies);
    const finalData = filteredCompanies.slice(0, pageShowCount);
    setTenantDetails(finalData);
    setPageCount(Math.ceil(filteredCompanies.length / pageShowCount));
    setShowingCount(finalData.length);
    setTotalTenant(filteredCompanies.length);
  };
  return (
    <>
      {loading && <Loader />}
      <div class="px-3">
        <div class=" mb-2">
          <div class="d-flex align-items-center justify-content-between mb-4">
            <h6 class="m-0">Tenants</h6>
            {role !== "Sales" && (
              <button onClick={openAddTenant} type="button" class="btn btn-sm">
                <i class="fal fa-user-plus mr-2"></i>
                Add Tenant
              </button>
            )}
          </div>
          <div class="d-flex align-items-center justify-content-between flex-wrap">
            <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
              <select class="form-select mb-2" onChange={handleChange}>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="100">100</option>
                <option value="All">All</option>
              </select>
            </div>
            <div class="col-lg-3 col-md-4 col-sm-12 col-xs-12">
              <div class="mb-2">
                <input
                  type="text"
                  placeholder="Search Tenants"
                  class="form-control small font-14"
                  onChange={filterCompanies}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="pt-0 mb-3 table-cards-1024">
          <table class="table table-hoverable-cells">
            <thead>
              <tr>
                <th scope="col">Company</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Email Status</th>
                <th scope="col">Type</th>
                <th scope="col">Created on</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {tenantDetails.map((item) => (
                <tr>
                  <td onClick={() => openTenantPopUPFun(item)}>
                    <strong>{item.tenantname.replace("GIG - ", "")}</strong>
                  </td>
                  <td onClick={() => openTenantPopUPFun(item)}>
                    {item.firstName + " " + item.lastName}
                  </td>
                  <td onClick={() => openTenantPopUPFun(item)}>
                    {item.useradminaccount}
                  </td>
                  <td className="no-hover">
                    <div
                      className="d-inline-block"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View Email Status"
                    >
                      <button
                        type="button"
                        className="btn btn-square btn-gray"
                        onClick={() => {
                          setEmailData(item?.useradminaccount);
                          setEmailStatus(true);
                          setSelectedTalent(item);
                        }}
                      >
                        <i class="fa fa-envelope-o" />
                      </button>
                    </div>
                  </td>
                  <td onClick={() => openTenantPopUPFun(item)}>
                    {item.tenantname.includes("GIG - ")
                      ? "Gig Recruiter"
                      : item.typename}
                  </td>
                  <td onClick={() => openTenantPopUPFun(item)}>
                    {moment(new Date(item.createddatetime)).format(
                      "MM/DD/YYYY"
                    )}
                  </td>
                  <td onClick={() => openTenantPopUPFun(item)}>
                    {item.statusname === "Active" ? (
                      <span class="tag tag-green2" v-if="item.isActive">
                        Active
                      </span>
                    ) : (
                      <span class="tag tag-red2">Deactivated</span>
                    )}
                  </td>
                  {role !== "Sales" && (
                    <td class="text-right no-hover">
                      <div class="d-flex align-items-center justify-content-end">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="right"
                          overlay={
                            <div class="dropdown">
                              <div
                                class="dropdown-menu dropdown-menu-right"
                                style={{
                                  display: "revert",
                                  right: "0px",
                                  top: "10px",
                                }}
                              >
                                <button
                                  onClick={() => {
                                    openedittenant(item);
                                  }}
                                  type="button"
                                  class="dropdown-item"
                                  disabled={item.statusname !== "Active"}
                                >
                                  <i class="fas fa-edit mr-2"></i> Edit
                                  <a />
                                </button>
                                {item.statusname === "Active" ? (
                                  <button
                                    type="button"
                                    class="dropdown-item"
                                    onClick={(e) => {
                                      DeleteRequestFun(e, item);
                                    }}
                                  >
                                    <i
                                      class="fa fa-trash mr-2"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Deactivate
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    class="dropdown-item"
                                    onClick={(e) => {
                                      EnabletenantFun(
                                        e,
                                        item.tenantname,
                                        item,
                                        item.id_useraccessdetail,
                                        item.useradminaccount
                                      );
                                    }}
                                  >
                                    <i
                                      class="fa fa-key mr-2"
                                      aria-hidden="true"
                                    ></i>{" "}
                                    Activate
                                  </button>
                                )}
                              </div>
                            </div>
                          }
                        >
                          <button
                            type="button"
                            class="btn btn-icon dropdown-toggle"
                            //   onClick={editFun}
                          >
                            <i class="far fa-ellipsis-v"></i>
                          </button>
                          {/* <i class="fas fa-ellipsis-v"></i> */}
                        </OverlayTrigger>
                      </div>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {tenantDetails.length === 0 && (
            <div>
              <div class="text-center p-3">
                <div class="avatar avatar-lg">
                  <i aria-hidden="true" class="fad fa-folder-open"></i>
                </div>{" "}
                <div class="mt-6">No records found</div>
              </div>
            </div>
          )}
          <hr class="m-0" />
          {tenantDetails.length > 0 && (
            <div class="d-flex align-items-center mt-3 mb-2 flex-wrap-370">
              <div class="text-center-370 w-100-370 mb-point5-370">
                Showing {showingCount} of {totalTenant}
              </div>
              <div class="ml-auto mx-auto-370 d-flex align-items-center">
                <ReactPaginate
                  pageCount={pageCount}
                  pageRange={2}
                  marginPagesDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName={"paginationContainer"}
                  previousLinkClassName={"paginationPage"}
                  breakClassName={"paginationPage"}
                  nextLinkClassName={"paginationPage"}
                  pageClassName={"paginationPage1"}
                  disabledClassName={"paginationDisabled"}
                  activeClassName={"paginationActive"}
                  previousLabel={
                    <i
                      className="fal fa-chevron-circle-left"
                      style={{ "font-size": "17px" }}
                    ></i>
                  }
                  nextLabel={
                    <i
                      className="fal fa-chevron-circle-right"
                      style={{ "font-size": "17px" }}
                    ></i>
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>{" "}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="SuperAdminTenants"
          invitedDate={selectedTalent?.createddatetime}
          // jobData={jobData}
          user="SA"
        />
      )}
      <Modal show={addTenant} size="lg">
        <AddTenant closeAdd={closePopUP} GettenantData={GettenantData} />
      </Modal>
      <Modal show={editTenant} onHide={closeEditPopUp} size="lg">
        <EditTenant
          closeAdd={closeEditPopUp}
          tenantlist={tenantObj.length !== 0 ? tenantObj : null}
          GettenantData={GettenantData}
          setopenTenantPopUP={setopenTenantPopUP}
        />
      </Modal>
      <Modal
        // size="lg"
        show={openSupplierDetails}
        dialogClassName="view-panel show"
      >
        {/* <SupplierDetailsPopUP
          supplierDetails={supplierDetails}
          closeModal={closePopUP}
        /> */}
      </Modal>
      <Modal
        show={DeleteRequestValue === true}
        onHide={DeleteRequestClose}
        size="delete"
      >
        <div class="bl-modal-header pb-4" onClick={DeleteRequestClose}>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="close p-0 bl-modal-close-btn mb-1"
            style={{ marginTop: "-6px" }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <DeleteTenant
            handleSuccess={DeleteRequestClose}
            employeeDelete={"employeedelete"}
            tenantName={selectedtenant}
            userid={selecteduser}
            users={"users"}
            GettenantData={GettenantData}
            tenantEmail={tenantEmail}
            tenantObj={selectedTenantObj}
            tenantType={tenantType}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={activateUserValue === true}
        onHide={EnableTenantClose}
        size="active"
      >
        <div class="bl-modal-header pb-4">
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            class="close p-0 bl-modal-close-btn"
            style={{ marginTop: "-6px" }}
            onClick={EnableTenantClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <ActivateTenant
            handleSuccess={EnableTenantClose}
            employeeenable={"employeeenable"}
            tenantName={selectedtenant}
            userid={selecteduser}
            users={"users"}
            GettenantData={GettenantData}
            tenantEmail={tenantEmail}
            tenantObj={selectedTenantObj}
            tenantType={tenantType}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={openTenantPopUP}
        onHide={closeTenantPopUP}
        dialogclassName="view-panel show"
      >
        <TenantDetailsPopUp
          employee={tenantObj}
          closeModal={closeTenantPopUP}
          //handleSuccess={editFun}
          updateEmployee={tenantDetails}
          GettenantData={GettenantData}
          closeEditPopUp={closeEditPopUp}
          editTenant={editTenant}
          setopenTenantPopUP={setopenTenantPopUP}
        />
      </Modal>
    </>
  );
}
export default ManageTenants;
