import React, { useRef, useState, useEffect } from "react";
import Select from "react-select";
import { skillSetOption } from "../../../directsourcing/Edit Talent DS/dropDownData";
import GoogleLocationCityAPI from "../../../location/GoogleLocationCityAPI";
import { toast } from "react-toastify";
import { stateList } from "../../../../constants/Constants";

const PoolScreeningQuesRes = ({
  QandACompleted,
  onChangeFunc,
  setQandACompleted,
  count,
  setCount,
  disabledSelect,
  inputList,
  setInputList,
  certificationList,
  showTextBox,
  setShowTextBox,
}) => {
  const searchInput = useRef(null);
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [matches, setMatches] = useState(
    window.matchMedia("(max-width: 768px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(max-width: 768px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);
  useEffect(() => {
    if (disabledSelect) {
      setShowTextBox(false);
    }
  }, [disabledSelect, showTextBox]);
  const deleteLocation = (index, question, idx) => {
    let current = [...preferredLocation];
    current.splice(index, 1);
    setPreferredLocation(current);
    setQandACompleted(
      QandACompleted.map((item, id) => {
        if (idx === id) {
          if (question.name === "Preferred Location") {
            preferredLocation.length > 0
              ? setCount(count + 1)
              : setCount(count - 1);
            return { ...question, answer: current };
          }
        } else {
          return item;
        }
      })
    );
  };
  const proficientHandler = (e, language, index2, item, idx) => {
    const QA = [...QandACompleted];
    if (!QA[idx].answer) {
      QA[idx].answer = [];
    }
    const proficiencyObj = {
      [language]: e.target.value,
    };
    const existingIndex = QA[idx].answer.findIndex((obj) =>
      obj.hasOwnProperty(language)
    );
    if (existingIndex === -1) {
      QA[idx].answer.push(proficiencyObj);
    } else {
      QA[idx].answer[existingIndex] = proficiencyObj;
    }
    setCount(count + 1);
    setQandACompleted(QA);
  };

  const languageHandler = (item, idx) => {
    return (
      <>
        {JSON.parse(item.input).map((language, index2) => (
          <div
            className={matches ? "" : "row m-2 d-flex align-items-center"}
            style={{ zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}` }}
          >
            <div className="col-lg-4">
              <strong>{language}</strong>
            </div>
            <div className="col-lg-7">
              <div className="form-floating w-100">
                <select
                  className="form-select"
                  onChange={(e) => {
                    proficientHandler(e, language, index2, item, idx);
                    //onChangeFunc(e, item, idx);
                  }}
                  value={
                    item.answer[index2] ? item.answer[index2][language] : ""
                  }
                >
                  <option value={""}>Select</option>
                  {["Not Proficient", "Beginner", "Intermediate", "Expert"].map(
                    (val) => (
                      <option value={val}>{val}</option>
                    )
                  )}
                </select>
                <label>Proficiency</label>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  };

  const setLocation = (address, searchInput, question, idx) => {
    const city = address.locality || address.city;
    if (preferredLocation.includes(city)) {
      toast.error("You Have Already Added This Location");
    } else {
      setPreferredLocation([...preferredLocation, city]);
      setQandACompleted(
        QandACompleted.map((item, id) => {
          if (idx === id) {
            if (question.name === "Preferred Location") {
              preferredLocation.length > 0
                ? setCount(count + 1)
                : setCount(count - 1);
              return { ...question, answer: [...preferredLocation, city] };
            }
          } else {
            return item;
          }
        })
      );
      searchInput.current.value = "";
    }
  };
  //let answer = [];
  const handleRemoveClick = (index, question, idx) => {
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setQandACompleted(
      QandACompleted.map((item, id) => {
        if (idx === id) {
          if (question.name === "Certification") {
            setCount(count + 1);
            let arr = list.map((option) => option.value);
            return { ...question, other: [...arr] };
          }
        } else {
          return item;
        }
      })
    );
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setInputList([...inputList, { value: "" }]);
  };

  return (
    <>
      {QandACompleted.map((item, idx) => (
        <>
          {item.name === "Background Check" ||
          item.name === "Drug Check" ||
          item.name === "Driving License" ||
          item.name === "Location" ||
          item.name === "Remote Work" ||
          item.name === "Urgent Hiring Need" ? (
            <div className="row mx-3 mt-4">
              <span className=""></span>
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
              </div>
              <div
                className={matches ? "" : "col-md-4"}
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <select
                  className="form-select"
                  onChange={(e) => {
                    onChangeFunc(e, item, idx);
                  }}
                >
                  <option value={""}>Select</option>
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </select>
              </div>
            </div>
          ) : null}

          {item.name === "Industry Experience" && (
            <div className="row mx-3 mt-4">
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`}
                {item.isMandatory ? "*" : ""}
              </div>
              <div
                className="col-md-4 form-floating mt-2"
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <select
                  className="form-select font-14"
                  name="referralCurrency"
                  disabled
                >
                  {skillSetOption
                    .map((i) => i.SourceName)
                    .map((item2) => (
                      <option selected={item2 === item.input} value={item2}>
                        {item2}
                      </option>
                    ))}
                </select>
                <label className="ml-1">Industry</label>
              </div>
              <br/>
              <div
                className={matches ? "" : "col-md-2"}
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <input
                  // style={{ width: "61px", height: "33px" }}
                  style={
                    matches
                      ? {
                          margin: "10px 0px 0px 16px",
                        }
                      : {}
                  }
                  className="form-control"
                  type="number"
                  min={0}
                  max={60}
                  defaultValue={0}
                  id="description"
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^0-9]/g, "");
                    const numericValue = parseInt(inputValue, 10);
                    const sanitizedValue = isNaN(numericValue)
                      ? 0
                      : Math.min(numericValue, 60);
                    e.target.value = sanitizedValue;
                    onChangeFunc(e, item, idx);
                  }}
                />
              </div>
            </div>
          )}

          {item.name === "Language" && JSON.parse(item.input).length > 0 && (
            <div className="row mx-3 mt-4">
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
              </div>
              <div className="col-md-6 mt-2">
                <div className=" ">
                  <div className="form-floating font-14 Hover-Dropdown">
                    {languageHandler(item, idx)}
                  </div>
                </div>
              </div>
            </div>
          )}

          {item.name == "Education" && (
            <div className="row mx-3 mt-4">
              <span className=""></span>
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
              </div>
              <div
                className="col-md-4"
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <select
                  className="form-select"
                  onChange={(e) => {
                    onChangeFunc(e, item, idx);
                  }}
                >
                  <option value={""}>Select</option>
                  {Array.isArray(item?.input)
                    ? item?.input.map((val, index) => (
                        <option key={index} value={val}>
                          {val}
                        </option>
                      ))
                    : JSON.parse(item?.input).map((val, index) => (
                        <option key={index} value={val}>
                          {val}
                        </option>
                      ))}
                </select>
              </div>
            </div>
          )}
          {item.name === "Certification" && (
            <div className="row mx-3 mt-4">
              <span className=""></span>
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
                <br />
                <small
                  style={{
                    marginLeft: "16px",
                    zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                  }}
                >
                  You can select multiple choices
                </small>
              </div>
              <div
                className="col-md-4"
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <Select
                  isMulti
                  options={certificationList}
                  value={item.answer.map((i) => ({
                    value: i,
                    label: i,
                  }))}
                  disabled={disabledSelect}
                  name="Qualification"
                  onChange={(e) => onChangeFunc(e, item, idx)}
                  isSearchable={true}
                ></Select>
              </div>
              <div
                className="col-md-12"
                style={{
                  display: "flex",
                  alignItems: "end",
                  margin: "-10px 0px 0px 17px",
                }}
              ></div>
              {showTextBox &&
                inputList.map((x, i) => {
                  return (
                    <div className="row mt-2" style={{ marginLeft: "7px" }}>
                      <div className="col-md-4">
                        <input
                          className="form-control"
                          name="value"
                          placeholder="Certification"
                          value={x.value}
                          onChange={(e) =>
                            onChangeFunc(e, item, idx, i, "other")
                          }
                        />
                      </div>
                      <div className="col-md-4">
                        {inputList.length !== 1 && (
                          <i
                            class="fal fa-fw fa-trash-alt"
                            style={{ color: "red" }}
                            onClick={() => handleRemoveClick(i, item, idx)}
                          ></i>
                        )}
                        {inputList.length - 1 === i && (
                          <button
                            className="btn-outline-primary btn-sm m-1"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            title="Add Certification"
                            onClick={handleAddClick}
                          >
                            <i class="fas fa-plus"></i>
                          </button>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {item.name === "Shift Preference" && (
            <div className="row mx-3 mt-4">
              <span className=""></span>
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
                {item?.input.length > 1 && (
                  <>
                    <br />
                    <small style={{ marginLeft: "16px" }}>
                      You can select multiple choices
                    </small>
                  </>
                )}
              </div>
              <div
                className="col-md-4"
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <Select
                  isMulti
                  options={
                    Array.isArray(item.input)
                      ? item?.input.map((lang) => ({
                          value: lang,
                          label: lang,
                        }))
                      : JSON.parse(item?.input).map((lang) => ({
                          value: lang,
                          label: lang,
                        }))
                  }
                  value={item.answer.map((i) => ({
                    value: i,
                    label: i,
                  }))}
                  name="Qualification"
                  onChange={(e) => onChangeFunc(e, item, idx)}
                  isSearchable={true}
                ></Select>
              </div>
            </div>
          )}

          {item.name === "Nursing Specialty" && (
            <div className="row mx-3 mt-4">
              <span className=""></span>
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
                {item?.input.length > 1 && (
                  <>
                    <br />
                    <small style={{ marginLeft: "16px" }}>
                      You can select multiple choices
                    </small>
                  </>
                )}
              </div>
              <div
                className="col-md-4"
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <Select
                  isMulti
                  options={item?.input.map((lang) => ({
                    value: lang,
                    label: lang,
                  }))}
                  value={item.answer.map((i) => ({
                    value: i,
                    label: i,
                  }))}
                  name="Qualification"
                  onChange={(e) => onChangeFunc(e, item, idx)}
                  isSearchable={true}
                ></Select>
              </div>
            </div>
          )}

          {(item.name === "Work Preference" ||
            item.name === "Willing to relocate" ||
            item.name === "Nursing License" ||
            item.name == "License State") && (
            <div className="row mx-3 mt-4">
              <span className=""></span>
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
                {item?.input.length > 1 && (
                  <>
                    <br />
                    <small style={{ marginLeft: "16px" }}>
                      You can select multiple choices
                    </small>
                  </>
                )}
              </div>
              <div
                className="col-md-4"
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <Select
                  isMulti
                  options={stateList.map((lang) => ({
                    value: lang.name,
                    label: lang.name,
                  }))}
                  value={item.answer.map((i) => ({
                    value: i,
                    label: i,
                  }))}
                  name="Qualification"
                  onChange={(e) => onChangeFunc(e, item, idx)}
                  isSearchable={true}
                ></Select>
              </div>
            </div>
          )}

          {item.name === "Work Experience" && (
            <div className="row mx-3 mt-4">
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
              </div>
              <div
                className="col-md-4"
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <input
                  // style={{ width: "61px", height: "33px" }}
                  className="form-control"
                  type="number"
                  min={0}
                  max={60}
                  id="description"
                  defaultValue={0}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^0-9]/g, "");
                    const numericValue = parseInt(inputValue, 10);
                    const sanitizedValue = isNaN(numericValue)
                      ? 0
                      : Math.min(numericValue, 60);
                    e.target.value = sanitizedValue;
                    onChangeFunc(e, item, idx);
                  }}
                />
              </div>
            </div>
          )}

          {item.name === "Preferred Location" && (
            <div className="row mx-3 mt-4">
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
              </div>
              <div
                className="col-md-4  mt-2"
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <div className=" ">
                  {preferredLocation?.map((obj, index) => (
                    <>
                      <span className="tag tag-blue3 mb-1 mr-1">
                        {obj}
                        <button
                          className="btn btn-text"
                          onClick={() => deleteLocation(index, item, idx)}
                        >
                          <strong className="font-18 font-black">
                            &times;
                          </strong>
                        </button>
                      </span>
                    </>
                  ))}
                  <div
                    className="form-floating Hover-Dropdown m-2"
                    style={{ zIndex: "997" }}
                  >
                    <GoogleLocationCityAPI
                      setLocation={(a, b) => setLocation(a, b, item, idx)}
                      searchInput={searchInput}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {item.name === "Custom Question" &&
            (item.input === "Drop-down" ||
              item.input == "Drop-down(Multiselect)") && (
              <div className="row mx-3 mt-4">
                <div className="col-md-12">
                  {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
                </div>
                <div
                  className="col-md-4"
                  style={{
                    margin: "10px 0px 0px 16px",
                    zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                  }}
                >
                  <Select
                    isMulti={item.input === "Drop-down(Multiselect)"}
                    options={item?.dropdownOptions.map((lang) => ({
                      value: lang,
                      label: lang,
                    }))}
                    value={item.answer.map((i) => ({
                      value: i,
                      label: i,
                    }))}
                    name="Qualification"
                    onChange={(e) => onChangeFunc(e, item, idx)}
                    isSearchable={true}
                  ></Select>
                </div>
              </div>
            )}

          {item.name === "Custom Question" && item.input === "Yes/No" && (
            <div className="row mx-3 mt-4">
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
              </div>
              <div
                className="col-md-4"
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <select
                  className="form-select"
                  onChange={(e) => {
                    onChangeFunc(e, item, idx);
                  }}
                >
                  <option value={""}>Select</option>
                  <option value={"Yes"}>Yes</option>
                  <option value={"No"}>No</option>
                </select>
              </div>
            </div>
          )}

          {item.name === "Custom Question" && item.input === "Numeric" && (
            <div className="row mx-3 mt-4">
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
              </div>
              <div
                className="col-md-4"
                style={{
                  margin: "10px 0px 0px 16px",
                  zIndex: `${(QandACompleted?.length - (idx + 1)) * 999}`,
                }}
              >
                <input
                  className="form-control"
                  type="number"
                  min={0}
                  defaultValue={0}
                  id="description"
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^0-9]/g, "");
                    const numericValue = parseInt(inputValue, 10);
                    e.target.value = isNaN(numericValue) ? 0 : numericValue;
                    onChangeFunc(e, item, idx);
                  }}
                />
              </div>
            </div>
          )}

          {item.name === "Custom Question" && item.input === "Long Text" && (
            <div className="row mx-3 mt-4">
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`} {item.isMandatory ? "*" : ""}
              </div>
              <div className="col-md-4 mt-2" style={{ marginLeft: "16px" }}>
                <textarea
                  className="form-control"
                  id="description"
                  onChange={(e) => {
                    onChangeFunc(e, item, idx);
                  }}
                />
              </div>
            </div>
          )}

          {item.name === "Custom Question" && item.input === "Text" && (
            <div className="row mx-3 mt-4">
              <div className="col-md-12">
                {`${idx + 1}. ${item.question}`}
                {item.isMandatory ? "*" : ""}
              </div>
              <div className="col-md-4 mt-2" style={{ marginLeft: "16px" }}>
                <textarea
                  className="form-control"
                  id="description"
                  onChange={(e) => {
                    onChangeFunc(e, item, idx);
                  }}
                />
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
};

export default PoolScreeningQuesRes;
