import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { post } from "../../../service/ApiService";
import Modal from "react-bootstrap/Modal";
import { BsFacebook } from "react-icons/bs";
import { FcGoogle } from "react-icons/fc";
import { BsLinkedin } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import {
  LoginSocialGoogle,
  LoginSocialLinkedin,
  LoginSocialFacebook,
} from "reactjs-social-login";
import GoogleLogin from "react-google-login";
import Avatar from "react-avatar";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";

const TopNavbar = (props) => {
  console.log("local", localStorage.getItem("url"));
  const { tenantData } = props;
  const { tenant } = useParams();

  const [login, setLogin] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [details, setDetails] = useState({
    name: "",
    url: "",
    email: "",
  });
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const handleLogin = (name, url, email) => {
    setLogin(false);
    toast.success("Logged In Successfully");
    localStorage.setItem("isLoggedIn", "Yes");
    localStorage.setItem("name", name);
    localStorage.setItem("url", url);
    localStorage.setItem("email", email);
    setPageReRender(!pageReRender);
    setToggle(false);
  };
  const [pageReRender, setPageReRender] = useState(true); //use this state in to rerender the page , setPageReRender to !pageReRender and use in useEffect's dependency
  useEffect(() => {}, [pageReRender]);
  const isLoggedIn = () => localStorage.getItem("isLoggedIn");

  const handleLogout = () => {
    setToggle(false);
    sessionStorage.clear();
    localStorage.clear();
    // props.setIsLoggedIn(false);
  };

  const getName = () => sessionStorage.getItem("name");
  const getURL = () => sessionStorage.getItem("url");
  const getEmail = () => sessionStorage.getItem("email");

  // Login user

  const loginUser = async () => {
    const params = { userName: email, password: password };

    setLoading(true);
    const res = await post("/candidateLogin/", params)
      .then((res) => {
        console.log("res.data", res.data);
        setLoading(false);
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("name", res.data.name);
        sessionStorage.setItem("userId", res.data.userId);
        sessionStorage.setItem("id", res.data.id);
        // props.setIsLoggedIn(true);
        toast.success("Login Successfull !");
        setLogin(false);
      })
      .catch((err) => {
        setLoading(false);
        toast.error("There are a few incomplete required fields. Please complete them to continue. ");
      });
  };

  // forgot password
  const forgetPassword = async (e) => {
    e.preventDefault();
    console.log("first");
    if (email == "") {
      toast.error("Enter your email");
    } else {
      const params = {
        email: email,
        tenantName: props.tenantData.tenantname,
        tenantId: props.tenantData.id_tenant,
      };

      setLoading(true);
      const res = await post("/forgotpassword/candidate", params)
        .then((res) => {
          console.log("res", res);
          toast.success(
            `Reset Password Link has been successfully sent to email address '${email}'`
          );
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          toast.error("Email Not Found");
        });
      // if (res.status === 200) {
      //   toast.success(
      //     `Reset Password Link has been successfully sent to email address '${email}'`
      //   );
      //   // setTimeout(() => history.push('/login'), 8000)
      //   setLoading(false);
      // } else {
      //   setLoading(false);
      //   toast.error("Email Not Found");
      // }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className="header">
        <div
          className="row p-2"
          style={{
            background: "#FFFFFF",
            boxShadow: "0px 16px 30px rgba(0, 0, 0, 0.15)",
          }}
        >
          <div className="col-md-6">
            {/* <h4 className="text-secondary ml-5 my-auto">LOGO</h4> */}
            <img
              className="ml-5 my-auto"
              style={{ height: "40px", width: "50px" }}
              src={tenantData?.logourl}
            />
          </div>
          {props.parent !== "careerpage" && (
            <div className="col-md-6 text-end">
              {/* <h5 className="text-secondary mr-5 my-auto">LogIn</h5> */}

              {/* {sessionStorage.getItem("token") == null ? (
              <div className="ml-auto my-2 mx-4">
                <button
                  className="btn btn-sm mr-5 my-auto"
                  onClick={() => setLogin(true)}
                >
                  LogIn
                </button>
              </div>
            ) : (
              <div className="extras ml-auto text-right mr-3">
                <div className="dropdown ml-3">
                  <button
                    className="btn btn-icon dropdown-toggle"
                    type="button"
                    onClick={() => {
                      console.log(isLoggedIn());
                      setToggle(!toggle);
                    }}
                  >
                    <Avatar
                      className="avatar avatar-md mr-1"
                      // name={getName()}
                      name={sessionStorage.getItem("name")}
                      round="90px"
                      // src={getURL()}
                    />
                    <small>
                      <strong>{sessionStorage.getItem("name")}</strong>
                    </small>
                  </button>
                  {toggle && (
                    <div
                      className="dropdown-menu d-flex flex-column"
                      style={{
                        top: "45px",
                        // transform: "translate3d(-125px, 27px, 0px)",
                      }}
                    >
                      <Link
                        to={`/careers/${tenant}/profile`}
                        className="dropdown-item"
                      >
                        <i className="fal fa-user mr-3"></i> profile{" "}
                      </Link>
                      <hr />
                      <Link
                        to={`/careers/${tenant}/jobList`}
                        className="dropdown-item"
                      >
                        <i className="fal fa-briefcase mr-3"></i>Jobs{" "}
                      </Link>
                      <hr />
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleLogout();
                        }}
                        href="/"
                        className="dropdown-item"
                      >
                        <i className="fal fa-sign-out mr-3"></i> Logout{" "}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )} */}

              {sessionStorage.getItem("token") === null &&
              localStorage.getItem("url") === null ? (
                // (isLoggedIn() !== "Yes" ? (
                <div className="ml-auto my-2 mx-4">
                  <button
                    className="btn btn-sm mr-5 my-auto"
                    onClick={() => setLogin(true)}
                  >
                    LogIn
                  </button>
                </div>
              ) : (
                // )
                <div className="extras ml-auto text-right mr-3">
                  <div className="dropdown ml-3">
                    <button
                      className="btn btn-icon dropdown-toggle"
                      type="button"
                      onClick={() => {
                        console.log(isLoggedIn());
                        setToggle(!toggle);
                      }}
                    >
                      <Avatar
                        className="avatar avatar-md mr-1"
                        // name={getName()}
                        name={
                          sessionStorage.getItem("token") == null
                            ? localStorage.getItem("name")
                            : sessionStorage.getItem("name")
                        }
                        round="90px"
                        src={getURL()}
                      />
                      <small>
                        <strong>
                          {sessionStorage.getItem("token") == null
                            ? localStorage.getItem("name")
                            : sessionStorage.getItem("name")}
                        </strong>
                      </small>
                    </button>
                    {toggle && (
                      <div
                        className="dropdown-menu"
                        style={{
                          display: "flex",
                          top: "0px",
                          transform: "translate3d(-125px, 27px, 0px)",
                        }}
                      >
                        <a
                          onClick={(e) => {
                            e.preventDefault();
                            handleLogout();
                          }}
                          href="/"
                          className="dropdown-item"
                        >
                          <i className="fal fa-sign-out mr-3"></i> Logout{" "}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* googleLogin */}
              {/* {localStorage.getItem("url") === null ? (
              // (isLoggedIn() !== "Yes" ? (
              <div className="ml-auto my-2 mx-4">
                <button
                  className="btn btn-sm mr-5 my-auto"
                  onClick={() => setLogin(true)}
                >
                  LogIn
                </button>
              </div>
            ) : (
              // )
              <div className="extras ml-auto text-right mr-3">
                <div className="dropdown ml-3">
                  <button
                    className="btn btn-icon dropdown-toggle"
                    type="button"
                    onClick={() => {
                      console.log(isLoggedIn());
                      setToggle(!toggle);
                    }}
                  >
                    <Avatar
                      className="avatar avatar-md mr-1"
                      // name={getName()}
                      name={localStorage.getItem("name")}
                      round="90px"
                      src={getURL()}
                    />
                    <small>
                      <strong>{localStorage.getItem("name")}</strong>
                    </small>
                  </button>
                  {toggle && (
                    <div
                      className="dropdown-menu"
                      style={{
                        display: "flex",
                        top: "0px",
                        transform: "translate3d(-125px, 27px, 0px)",
                      }}
                    >
                      <a
                        onClick={(e) => {
                          e.preventDefault();
                          handleLogout();
                        }}
                        href="/"
                        className="dropdown-item"
                      >
                        <i className="fal fa-sign-out mr-3"></i> Logout{" "}
                      </a>
                    </div>
                  )}
                </div>
              </div>
            )} */}
            </div>
          )}
        </div>
      </div>

      {/* <Modal show={login}>
        <Modal.Header>
          <div>
            <h6>Welcome Back!</h6>
          </div>
          <div>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setLogin(false)}
              class="close p-0 bl-modal-close-btn mx-1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column mx-5"> */}
      {/* <LoginSocialGoogle
              client_id={
                "519239579073-tt3kushg6kpichbf8m7u84reocsq14oh.apps.googleusercontent.com"
              }
              onLoginStart={() => console.log("Started")}
              onResolve={({ provider, data }) => {
                console.log(data);
              }}
              access_type="offline"
              onReject={(err) => {
                console.log(err);
              }}
              scope="openid profile email"
              discoveryDocs="claims_supported"
            >
              <button className="btn btn-secondary m-2">
                <FcGoogle /> Login with Google
              </button>
            </LoginSocialGoogle> */}
      {/* <GoogleLogin
              clientId="519239579073-tt3kushg6kpichbf8m7u84reocsq14oh.apps.googleusercontent.com"
              onSuccess={(res) => {
                let { name, imageUrl, email } = res.profileObj;
                handleLogin(name, imageUrl, email);
              }}
              onFailure={(res) => console.log(res)}
              render={(renderProps) => (
                <button
                  className="btn btn-secondary m-2 w-100"
                  onClick={renderProps.onClick}
                >
                  <FcGoogle /> Login with Google
                </button>
              )}
            />
            <LoginSocialFacebook
              appId="631575145234560"
              onLoginStart={() => console.log("Starting")}
              onResolve={({ provider, data }) => {
                let { email, name, picture } = data;
                handleLogin(name, picture.data.url, email);
              }}
              onReject={(err) => {
                console.log("Error", err);
              }}
              autoLoad={true}
            >
              <button className="btn btn-secondary m-2 w-100">
                <BsFacebook /> Login with Facebook
              </button>
            </LoginSocialFacebook>
            <LoginSocialLinkedin client_id="86hjfnxy6nfook">
              <button className="btn btn-secondary m-2 w-100">
                <BsLinkedin /> Login with Linkedin
              </button>
            </LoginSocialLinkedin>
          </div>
          <div className="text-center">OR</div>
          <div className="mx-3">
            <label>Email</label>
            <input type="text"></input>
          </div>
          <div className="mx-3 mb-2">
            <label>Password</label>
            <input type="text"></input>
          </div>
          <div className="d-flex align-items-center mx-3 my-3">
            <div>
              <input type="checkbox"></input>
            </div>
            <div className="ml-2">Remember me</div>
            <div className="ml-auto">
              <a href="/">Forgot Password</a>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

      <Modal show={login}>
        <Modal.Header>
          <div>
            <h6>Login</h6>
          </div>
          <div>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => {
                setLogin(false);
              }}
              class="close p-0 bl-modal-close-btn mx-1"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column mx-5 mb-3 ">
            {/* <LoginSocialGoogle
              client_id={
                "519239579073-tt3kushg6kpichbf8m7u84reocsq14oh.apps.googleusercontent.com"
              }
              onLoginStart={() => console.log("Started")}
              onResolve={({ provider, data }) => {
                console.log(data);
              }}
              access_type="offline"
              onReject={(err) => {
                console.log(err);
              }}
              scope="openid profile email"
              discoveryDocs="claims_supported"
            >
              <button className="btn btn-secondary m-2 w-100">
                <FcGoogle /> Login with Google
              </button>
            </LoginSocialGoogle> */}
            {/* <GoogleLogin
              clientId="519239579073-tt3kushg6kpichbf8m7u84reocsq14oh.apps.googleusercontent.com"
              onSuccess={(res) => {
                let { name, imageUrl, email } = res.profileObj;
                handleLogin(name, imageUrl, email);
              }}
              onFailure={(res) => console.log(res)}
              render={(renderProps) => (
                <button
                  className="btn btn-secondary m-2 w-100"
                  onClick={renderProps.onClick}
                >
                  <FcGoogle /> Login with Google...
                </button>
              )}
            /> */}
            <GoogleLogin
              clientId="519239579073-tt3kushg6kpichbf8m7u84reocsq14oh.apps.googleusercontent.com"
              onSuccess={(res) => {
                let { name, imageUrl, email } = res.profileObj;

                handleLogin(name, imageUrl, email);
              }}
              onFailure={(res) => console.log(res)}
              render={(renderProps) => (
                <button
                  className="btn btn-secondary m-2 w-100"
                  onClick={renderProps.onClick}
                >
                  <FcGoogle /> Login with Google
                </button>
              )}
            />
            <LoginSocialFacebook
              appId="631575145234560"
              onLoginStart={() => console.log("Starting")}
              onResolve={({ provider, data }) => {
                let { email, name, picture } = data;
                handleLogin(name, picture.data.url, email);
              }}
              onReject={(err) => {
                console.log("Error", err);
              }}
              autoLoad={true}
            >
              <button className="btn btn-secondary m-2 w-100">
                <BsFacebook /> Login with Facebook
              </button>
            </LoginSocialFacebook>
            {/* <LoginSocialLinkedin client_id="86hjfnxy6nfook">
              <button className="btn btn-secondary m-2 w-100" disabled>
                <BsLinkedin /> Login with Linkedin
              </button>
            </LoginSocialLinkedin> */}
            {/* <button
              className="btn btn-secondary m-2 w-100"
              onClick={() => {
                setLoginWithEmail(false);
                setApplyWithEmail(true);
              }}
            >
              <MdEmail className="" /> Apply with Email
            </button>
            <button
              className="btn btn-secondary m-2 w-100"
              onClick={() => {
                setApplyWithEmail(false);
                setLoginWithEmail(true);
              }}
            >
              <MdEmail className="" /> Login with Email
            </button> */}
          </div>
          <div className="text-center">
            <p>OR</p>
          </div>

          {/* Ask for email */}
          <div>
            <div className="d-flex flex-column mx-5 my-3">
              <div className="form-floating ml-1">
                <input
                  type="text"
                  className={"form-control"}
                  name="groupName"
                  placeholder="Enter a group name"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label>Email*</label>
              </div>
            </div>
            {/* <div className="d-flex flex-column mx-5 mb-2">
            <label>Password</label>
            <input type="text"></input>
          </div> */}

            <div className="d-flex flex-column mx-5 my-3">
              <div className="form-floating ml-1">
                <input
                  type="password"
                  className={"form-control"}
                  name="groupName"
                  placeholder="Enter a group name"
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label>Password*</label>
              </div>
              <p className="text-right my-1">
                <a href="" onClick={forgetPassword}>
                  Forgot Password?
                </a>
              </p>
            </div>

            <div className="row mx-5 mb-2">
              <button
                className="btn btn-secondary m-2 w-100"
                onClick={loginUser}
              >
                Login
              </button>
            </div>

            <div className="d-flex align-items-center mx-5 my-3">
              {/* <div>
                <input type="checkbox"></input>
              </div> */}
              {/* <div className="ml-2">Remember me</div> */}
              {/* <div className="ml-auto">
                Not a member ?{" "}
                <a href={`/careers/${tenant}/addtalent`}>SignUp</a>
              </div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TopNavbar;
