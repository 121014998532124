import React, { useEffect, useState } from "react";
import { Card, Button, Col, Row } from "react-bootstrap";
import {
  FaBuilding,
  FaClock,
  FaMapMarker,
  FaHeart,
  FaLocationArrow,
} from "react-icons/fa";
import Filter from "./Filter/Filter";
import Pagination from "../commoncomponents/pagination/Pagination";
import ReactHtmlParser from "react-html-parser";
import PaginationComponent from "../pagination/pagination";
import NoRecord from "../commoncomponents/norecord/NoRecord";
const JobList = ({
  jobs,
  savedJobs,
  path,
  handleSaveJob,
  selectedData,
  setSelectedData,
  applyFilter,
  careerCount,
  fetchData,
  setCurrentPage,
  currentPage,
  value,
  setValue,
}) => {
  const [paginationArray, setPaginationArray] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [dataSize, setDataSize] = useState(10);
  const pageSize = 5;
  useEffect(() => {
    setFilteredData(jobs);
  }, [jobs]);

  const searchValueChange = (e) => {
    const inputValue = e.target.value;
    setValue(inputValue);
  };

  const returnDescription = (job) => {
    const maxCharacters = 430;

    const jobDescription = job?.jobDescription;

    // Check if job description exceeds the maximum allowed characters
    const isDescriptionTooLong = jobDescription?.length > maxCharacters;

    // If the description is too long, clip it
    const clippedDescription = isDescriptionTooLong
      ? `${jobDescription.substring(0, maxCharacters)}...`
      : jobDescription;

    return (
      <div>
        {ReactHtmlParser(
          isDescriptionTooLong ? clippedDescription : jobDescription
        )}
      </div>
    );
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    fetchData(pageNumber, true);
  };

  return (
    <div className=" mt-4 mx-0 px-0 w-100 pb-5">
      <div
        className={`jobListImage d-flex flex-column align-items-center justify-content-center`}
      >
        <h2 className="text-center mb-3">Search jobs at High5Hire</h2>
        <input
          type="text"
          placeholder="Search Jobs"
          className="form-control small font-14 w-25"
          onChange={(e) => searchValueChange(e)}
          value={value}
        />
      </div>

      <Row className="px-4 pb-5">
        <Col md={4}>
          <Filter
            setFilteredData={setFilteredData}
            jobs={jobs}
            selectedData={selectedData}
            setSelectedData={setSelectedData}
            applyFilter={applyFilter}
          />
        </Col>
        <Col md={8}>
          <h6>{path === "savedjobs" ? "Saved Jobs" : "Jobs"}</h6>
          <Row>
            {jobs?.length === 0 && <NoRecord />}
            {jobs?.map((job, index) => (
              <Col key={index} md={12} className="mb-4">
                <Card className="job-card">
                  <Card.Body>
                    <div
                      className="card-header"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Card.Title>
                        <a
                          className="font-black"
                          href="#"
                          onClick={() => {
                            window.open(
                              `/career/jobs/${job.jobId}/recruiter/698131846`,
                              "_blank"
                            );
                          }}
                        >
                          {job.jobTitle}
                        </a>
                      </Card.Title>
                      <a
                        href="#"
                        onClick={() => {
                          handleSaveJob(job.jobId);
                        }}
                      >
                        <FaHeart
                          className="heart-icon"
                          style={{
                            color: savedJobs.includes(job.jobId)
                              ? "#cd2a2a"
                              : "gray",
                          }}
                        />
                      </a>
                    </div>
                    <Card.Text>
                      {/* <span
                        dangerouslySetInnerHTML={{
                          __html: job.jobDescription,
                        }}
                      ></span> */}
                      {returnDescription(job)}
                    </Card.Text>
                    <div className="card-info">
                      <Card.Text>
                        <FaBuilding className="mr-2" />
                        {job.jobType}
                      </Card.Text>
                      <Card.Text>
                        <FaClock className="mr-2" />
                        {JSON.parse(job.industries).join(", ")}
                      </Card.Text>
                      {/* {job?.workPlaceType && (
                        <Card.Text>
                          <FaMapMarker className="mr-2" />
                          {job?.workPlaceType}
                        </Card.Text>
                      )}{" "} */}
                      <Card.Text>
                        <FaMapMarker className="mr-2" />
                        {job.city || job.state || job.country ? (
                          <span className="">{`${job.city}${
                            job.city && job.state ? ", " : ""
                          } ${job.state}${
                            job.state && job.country ? ", " : ""
                          } ${job.country}`}</span>
                        ) : (
                          job?.workPlaceType
                        )}
                      </Card.Text>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
          {/* <hr class="m-0" /> */}
          <PaginationComponent
            currentPage={currentPage}
            totalItems={careerCount[0]?.count}
            itemsPerPage={5}
            onPageChange={handlePageChange}
            limit={5}
          />
        </Col>
      </Row>
    </div>
  );
};

export default JobList;
