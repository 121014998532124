import {
  GET_NOTIFICATION_LIST_BEGIN,
  GET_NOTIFICATION_LIST_SUCCESS,
  GET_NOTIFICATION_LIST_FAILURE,
} from "../../actions/notification/notifications";

const initialState = {
  notificationLoading: true,
  notificationData: {
    data: [],
    notificationPref: true,
  },
};

const getNotificationsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_NOTIFICATION_LIST_BEGIN:
      return {
        ...state,
        notificationLoading: true,
        notificationData: {
          data: [],
          notificationPref: true,
        },
      };
    case GET_NOTIFICATION_LIST_SUCCESS:
      return {
        ...state,
        notificationData: payload,
        notificationLoading: false,
      };
    case GET_NOTIFICATION_LIST_FAILURE:
      return {
        ...state,
        notificationData: payload,
        notificationLoading: false,
      };
    default:
      return state;
  }
};

export default getNotificationsReducer;
