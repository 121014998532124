import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { useDispatch } from "react-redux";
import { SidebarLogo, roleNames } from "../../constants/Constants";
import AddJobSuccess from "../../components/modal/addjob/AddJobSuccess";
import AddTalent from "../components_v3/talent/addtalent/Index";
import { useHistory } from "react-router-dom";
import {
  getActualRole,
  getTenantType,
  loginUserName,
  getTenantLogo,
} from "../../service/AuthService";
import {
  getUserRole,
  getUserId,
  getCandidateName,
  getUserTenant,
  isCurator,
} from "../../service/AuthService";
import MainAddJob from "../components_v3/job/addjob/index";
import { template, jobDetail } from "../../actions/job/AddJob";
import { getOwner } from "../../service/AuthService";
import GigReport from "../components_v3/performancereport/index";
import Event from "../../googleanalytic/Tracking";
import TalentPool from "../modal/TalentPool/TalentPool";

function SideNavBar(props) {
  let path = window.location.href.split("/").slice(-1)[0];

  const [userdetails, setUser] = useState({
    user: "",
    role: "",
    tanent: "",
    userid: "",
  });
  const { user, role, tanent } = userdetails;
  const [successFormOpen, setSuccessFormOpen] = useState(false);
  const [showAddTalentModal, setShowAddTalentModal] = useState(false);
  const [talentPoolModal, setTalentPoolModal] = useState(false);
  const [successData, setSuccessData] = useState({
    jobId: "",
    position: "",
    startDate: "",
    location: "",
    salary: "",
    skills: "",
  });
  let history = useHistory();
  const [addJobNewModal, setAddJobNewModal] = useState(false);
  const tenantType = getTenantType();
  const dispatch = useDispatch();

  const closeAddJobSucces = () => {
    setSuccessFormOpen(false);
  };

  const closeAddJobModal = () => {
    dispatch(template(false));
    dispatch(jobDetail(null));
    setAddJobNewModal(false);
  };

  useEffect(() => {
    const username = getCandidateName();
    const userrole = getUserRole();
    const iduser = getUserId();
    const userTenant = getUserTenant();

    if (username && iduser && userrole) {
      setUser({
        user: username,
        role: userrole,
        userid: props.userid,
        tanent: userTenant,
      });
    }
  }, []);

  const routeChange = () => {
    Event(
      "Master Report Clicked",
      `${loginUserName()},${getUserRole()}`,
      window.location.pathname
    );
    history.push(`/high5Hire/masterreport`);
  };

  const changeRoute = () => {
    Event(
      "Hotlist Report Clicked",
      `${loginUserName()},${getUserRole()}`,
      window.location.pathname
    );
    history.push(`/high5Hire/hotlistreport`);
  };

  return (
    <>
      <div id="Nav">
        <div className="top">
          <div className="d-flex align-items-center">
            {getTenantLogo() ? (
              <img
                src={getTenantLogo()}
                width="50"
                height="50"
                className="p-2"
                style={{
                  borderRadius: "50%",
                  objectFit: "contain",
                  boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                  background: "white",
                }}
              />
            ) : (
              <div
                className="brand-logo"
                style={{ backgroundImage: `url(${SidebarLogo})` }}
              ></div>
            )}

            <div className="title px-2">
              <h6 className="mb-0">{user}</h6>
              <small className="mb-0">
                Role :{" "}
                {getOwner()
                  ? getUserRole() == "Admin"
                    ? "Client Admin"
                    : isCurator()
                    ? "Curator"
                    : `${roleNames[role]}`
                  : isCurator()
                  ? tenantType === 1
                    ? "Client Curator"
                    : "Curator"
                  : roleNames[role]}
              </small>
              <br />
              {tanent && (
                <small className="mb-0">
                  Company : {tanent.replace("GIG - ", "")}
                </small>
                // <small className="mb-0">Company : High5</small>
              )}
            </div>
          </div>
        </div>
        <div className="body">
          {(role === "HiringManager" || role === "Recruiter") && (
            <button
              type="button"
              className="btn w-100 px-0"
              onClick={
                role === "HiringManager"
                  ? () => {
                      setAddJobNewModal(true);
                    }
                  : role === "Recruiter"
                  ? () => {
                      setShowAddTalentModal(true);
                    }
                  : ""
              }
            >
              <i className="fas fa-plus fa-fw-dropdown  "></i>
              <span
                className="nav-text ml-2"
                onClick={() =>
                  role === "HiringManager"
                    ? Event(
                        "New Job Clicked",
                        `${loginUserName()},${getUserRole()}`,
                        window.location.pathname
                      )
                    : role == "Recruiter"
                    ? Event(
                        "Add Talent Clicked",
                        `${loginUserName()},${getUserRole()}`,
                        window.location.pathname
                      )
                    : ""
                }
              >
                {role === "HiringManager"
                  ? "New Job"
                  : role === "Recruiter"
                  ? "Add Talent"
                  : ""}
              </span>
            </button>
          )}
          {role === "Candidate" ? (
            <ul className="menu">
              <li
                onClick={() =>
                  Event(
                    "Dashboard Clicked",
                    `${loginUserName()},${getUserRole()}`,
                    window.location.pathname
                  )
                }
              >
                <a
                  href="/candidate/dashboard"
                  className={path === "high5Hire" && "active"}
                >
                  <i
                    className="fad fa-fw fa-home-lg-alt mr-3"
                    aria-hidden="true"
                  ></i>
                  <span className="nav-text">Dashboard</span>
                </a>
              </li>
              <li>
                <a
                  href="/high5Hire/candidatejobs"
                  className={path === "candidatejobs" && "active"}
                >
                  <i
                    className="fad fa-fw fa-briefcase mr-3"
                    aria-hidden="true"
                  ></i>
                  <span className="nav-text">Jobs</span>
                </a>
              </li>
              <li>
                <a
                  href="/high5Hire/candidateinterviews"
                  className={path === "candidateinterviews" && "active"}
                >
                  <i
                    className="fad  fa-fw fa-users-className mr-3"
                    aria-hidden="true"
                  ></i>
                  <span className="nav-text">Interviews</span>
                </a>
              </li>
              <li>
                <a
                  href="/high5Hire/candidateassesments"
                  className={path === "candidateassesments" && "active"}
                >
                  <i className="fad fa-fw fa-award mr-3" aria-hidden="true"></i>
                  <span className="nav-text">Assessments</span>
                </a>
              </li>
            </ul>
          ) : (
            <ul className="menu">
              <li
                onClick={() =>
                  Event(
                    "Dashboard Clicked",
                    `${loginUserName()},${getUserRole()}`,
                    window.location.pathname
                  )
                }
              >
                <a
                  href="/high5Hire"
                  className={path === "high5Hire" && "active"}
                  data-bs-toggle="tooltip"
                  data-bs-placement="left"
                  title="Dashboard"
                >
                  <i className="fad fa-fw fa-home-lg-alt mr-3"></i>
                  <span className="nav-text">Dashboard</span>
                </a>
              </li>{" "}
              {/* {(role === "SuperAdmin" || role === "CustomerSuccess") && (
                <li onClick={() => {}}>
                  <a
                    href="/high5Hire/FAQSetup"
                    className={path === "FAQSetup" && "active"}
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="FAQ Setup"
                  >
                    <i className="fa fa-question-circle mr-3"></i>
                    <span className="nav-text">FAQ Setup</span>
                  </a>
                </li>
              )} */}
              {role !== "High5Coordinator" &&
                role !== "CustomerSuccess" &&
                role !== "CommunityManager" &&
                role !== "SuperAdmin" && (
                  <li
                    onClick={() =>
                      Event(
                        "Talent Pool Clicked",
                        `${loginUserName()},${getUserRole()}`,
                        window.location.pathname
                      )
                    }
                  >
                    <a
                      href="/high5Hire/talents"
                      className={path === "talents" && "active"}
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title="Talent Pool"
                    >
                      <i className="fad fa-fw fa-users mr-3"></i>
                      <span className="nav-text">Talent Pool</span>
                    </a>
                  </li>
                )}
              {role !== "High5Coordinator" && (
                <li
                  onClick={() =>
                    Event(
                      "Requests Clicked",
                      `${loginUserName()},${getUserRole()}`,
                      window.location.pathname
                    )
                  }
                >
                  <a
                    href="/high5Hire/requests"
                    className={path === "requests" && "active"}
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Jobs"
                  >
                    <i className="fad fa-fw fa-briefcase mr-3"></i>
                    <span className="nav-text">Jobs</span>
                  </a>
                </li>
              )}
              {(role === "SuperAdmin" || role === "CustomerSuccess") && (
                <li>
                  <a
                    href="/high5Hire/auditLog"
                    className={path === "auditLog" && "active"}
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Analytics"
                  >
                    <i className="fad fa-fw fa-clipboard-list mr-3"></i>
                    <span className="nav-text">Analytics</span>
                  </a>
                </li>
              )}{" "}
              {/* {role === "Recruiter" && (
                <li>
                  <a
                    href="/high5Hire/auditLog"
                    className={path === "auditLog" && "active"}
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Audit Log"
                  >
                    <i className="fad fa-fw fa-clipboard-list mr-3"></i>
                    <span className="nav-text">Audit Log</span>
                  </a>
                </li>
              )}{" "} */}
              {(role == "SuperAdmin" || role === "CustomerSuccess") && (
                <li
                  onClick={() =>
                    Event(
                      "Performances Testing Clicked",
                      `${loginUserName()},${getUserRole()}`,
                      window.location.pathname
                    )
                  }
                >
                  <a
                    href="/high5Hire/performancetesting"
                    className={path === "performancetesting" && "active"}
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Performance Testing"
                  >
                    <i className="fad fa-fw fa-clock mr-3"></i>
                    <span className="nav-text">Performance Testing</span>
                  </a>
                </li>
              )}
              {/* {(role === "CustomerSuccess" || role=="SuperAdmin" )&& (
                <li>
                  <a
                    href="/high5Hire/parsingComparison"
                    className={path === "parsingComparison" && "active"}
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Parsing Comparison"
                  >
                    <i className="fad fa-fw fa-file-alt mr-3"></i>
                    <span className="nav-text">Parsing Comparison</span>
                  </a>
                </li>
              )} */}
              {(role === "HiringManager" ||
                (role === "Recruiter" && isCurator())) && (
                <li>
                  <a
                    href="/high5Hire/talentcommunity/pools"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Talent Community"
                  >
                    <i class="fad fa-layer-group mr-3"></i>
                    <span className="nav-text">Talent Community</span>
                  </a>
                </li>
              )}
              {/* Generic Reporting Section */}
              {role !== "CommunityManager" &&
                role !== "High5Coordinator" &&
                role !== "Recruiter" &&
                role !== "CustomerSuccess" &&
                role !== "SuperAdmin" &&
                role !== "Admin" && (
                  <li>
                    <OverlayTrigger
                      trigger="click"
                      rootClose
                      placement="bottom-end"
                      overlay={
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          aria-labelledby="requestMoreMenu"
                        >
                          <button
                            type="button"
                            onClick={() => routeChange()}
                            className="dropdown-item"
                          >
                            <i className="fa fa-file-excel-o mr-2"></i>Master
                            Report
                          </button>

                          {role !== "HiringManager" && (
                            <button
                              type="button"
                              onClick={() => changeRoute()}
                              className="dropdown-item"
                            >
                              <i className="fa fa-file-excel-o mr-2"></i>Hotlist
                              Report
                            </button>
                          )}
                          {role === "CustomerSuccess" && (
                            <button
                              type="button"
                              onClick={() =>
                                history.push(`/high5Hire/unsubUsers`)
                              }
                              className="dropdown-item"
                            >
                              <i className="fa fa-file-excel-o mr-2"></i>
                              Unsusbscribed Users
                            </button>
                          )}
                          {role === "CustomerSuccess" && (
                            <button
                              type="button"
                              onClick={() =>
                                history.push(`/high5Hire/unsubTalents`)
                              }
                              className="dropdown-item"
                            >
                              <i className="fa fa-file-excel-o mr-2"></i>
                              Unsusbscribed Talents
                            </button>
                          )}
                        </div>
                      }
                    >
                      <a
                        href="#"
                        data-bs-toggle="tooltip"
                        data-bs-placement="left"
                        title="Report"
                        onClick={() =>
                          Event(
                            "Report Clicked",
                            `${loginUserName()},${getUserRole()}`,
                            window.location.pathname
                          )
                        }
                      >
                        <i className="fad fa-fw fa-file mr-3"></i>
                        <span className="nav-text">Report</span>
                      </a>
                    </OverlayTrigger>
                  </li>
                )}
              {role === "CommunityManager" && (
                <li>
                  <OverlayTrigger
                    trigger="click"
                    rootClose
                    placement="bottom-end"
                    overlay={
                      <div
                        className="dropdown-menu dropdown-menu-right"
                        aria-labelledby="requestMoreMenu"
                      >
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() => {
                            window.open("/high5Hire/cmmasterreport", "_self");
                          }}
                        >
                          <i className="fa fa-file-excel-o mr-2"></i>Master
                          Report
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() => {
                            window.open("/high5Hire/cmhotlistreport", "_self");
                          }}
                        >
                          <i className="fa fa-file-spreadsheet mr-2"></i>Hotlist
                          Report
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() => {
                            window.open(
                              "/high5Hire/cmcandidatereport",
                              "_self"
                            );
                          }}
                        >
                          <i className="fa fa-file-spreadsheet mr-2"></i>
                          Candidate Report
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() => {
                            window.open(
                              "/high5Hire/cmrecruiterreport",
                              "_self"
                            );
                          }}
                        >
                          <i className="fa fa-file-spreadsheet mr-2"></i>
                          Recruiter Report
                        </button>
                        <button
                          type="button"
                          className="dropdown-item"
                          onClick={() => {
                            window.open("/high5Hire/jobreport", "_self");
                          }}
                        >
                          <i className="fa fa-file-spreadsheet mr-2"></i>
                          Job Report
                        </button>
                      </div>
                    }
                  >
                    <a
                      href="#"
                      data-bs-toggle="tooltip"
                      data-bs-placement="left"
                      title="Report"
                      onClick={() =>
                        Event(
                          "Report Clicked",
                          `${loginUserName()},${getUserRole()}`,
                          window.location.pathname
                        )
                      }
                    >
                      <i className="fad fa-fw fa-file mr-3"></i>
                      <span className="nav-text">Report</span>
                    </a>
                  </OverlayTrigger>
                </li>
              )}
              {role === "HiringManager" && (
                <li>
                  <a
                    href="/high5Hire/marketoverview"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Marketplace Overview"
                  >
                    <i class="fad  fa-fw fa-chart-line mr-3"></i>
                    <span className="nav-text">Marketplace Overview</span>
                  </a>
                </li>
              )}
              {role === "CommunityManager" && <GigReport />}
              {(role == "SuperAdmin" || role == "CustomerSuccess") && (
                <li>
                  <a
                    href="/high5Hire/adminConsole"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Admin Console"
                    onClick={() =>
                      Event(
                        "Report Clicked",
                        `${loginUserName()},${getUserRole()}`,
                        window.location.pathname
                      )
                    }
                  >
                    <i class="fa fa-envelope mr-3"></i>
                    <span className="nav-text">Admin Console</span>
                  </a>
                </li>
              )}
              {role == "SuperAdmin" && (
                <li>
                  <a
                    href="/high5Hire/tagsTemplate"
                    data-bs-toggle="tooltip"
                    data-bs-placement="left"
                    title="Tags"
                    onClick={() =>
                      Event(
                        "Report Clicked",
                        `${loginUserName()},${getUserRole()}`,
                        window.location.pathname
                      )
                    }
                  >
                    <i class="fa fa-tags mr-3"></i>
                    <span className="nav-text">Tags</span>
                  </a>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
      <Modal show={successFormOpen === true} onHide={closeAddJobSucces}>
        <div className="modal-content" style={{ width: "500px" }}>
          <div className="bl-modal-header">
            <h6 className="bl-modal-title">{"Job Created!"}</h6>
            <button
              type="button"
              data-dismiss="modal"
              aria-label="Close"
              onClick={closeAddJobSucces}
              className="close p-0 bl-modal-close-btn"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <Modal.Body>
            <AddJobSuccess
              successData={successData}
              closeModal={closeAddJobSucces}
            />
          </Modal.Body>
        </div>
      </Modal>

      <Modal show={addJobNewModal} size="lg">
        <Modal.Header>
          <h6 className="">New Job</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => closeAddJobModal()}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <MainAddJob
            setSuccessData={setSuccessData}
            setSuccessFormOpen={setSuccessFormOpen}
            closePanel={() => closeAddJobModal()}
          />
        </Modal.Body>
      </Modal>
      {showAddTalentModal && (
        <AddTalent
          showAddTalentModal={showAddTalentModal}
          setShowAddTalentModal={setShowAddTalentModal}
        />
      )}
      <Modal show={talentPoolModal} size="lg">
        <div className="modal-header" id="NewRequestModal">
          <h6 className="modal-title" id="exampleModalLabel">
            {/* Create Talent Pool */}
          </h6>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setTalentPoolModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <TalentPool />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SideNavBar;
