import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Modal } from "react-bootstrap";
import { getAllTalentEmails } from "../../../../actions/talent/getTalentEmail";
import { getCandidateDropdown } from "../../../../actions/talent/AddTalent";
import { getUserTenantID } from "../../../../service/AuthService";
import Loader from "../../../Loader/Loader";
import TalentTypes from "./talenttypes/TalentTypes";
import ResumeUploadAddTalent from "./resumeupload/ResumeUploadAddTalent";
import ExcelUpload from "./bulkupload/ExcelUpload";
//import BulkResumeUpload from "./bulkupload/BulkResumeUpload";

import AddTalentManualEntry from "./manualentry/Index";
import FormSubmitSuccess from "../../../modal/result/FormSubmitSuccess";
import { getTalentList } from "../../../../actions/talent/talentList";
// import ExcelUpload from "./bulkupload/BulkResumeUpload";

const AddTalent = (props) => {
  const {
    showAddTalentModal,
    setShowAddTalentModal,
    handleClear,
    isFrom,
  } = props;
  const dispatch = useDispatch();
  const [successTalentFormOpen, setSuccessTalentFormOpen] = useState(false);
  const [successTalentData, setSuccessTalentData] = useState({
    title: "",
    subTitle: "",
    htmlTag: "",
    name: "",
    experienceMonth: "",
    experienceYear: "",
    preferredLocation: [],
    primarySkills: [],
  });

  // const [successData,setSuccessData] = useState({
  //   name: ` `,
  //   email: ""
  // });

  const closeAddTalentModalPopUp = () => {
    setShowAddTalentModal(false);
    setSuccessTalentFormOpen(false);
    let payload = {
      currentPage: 1,
      limit: 10,
      status: null,
      tab: "All",
      sortParam: [],
    };
    dispatch(getTalentList(payload));
  };

  const tenantid = getUserTenantID();
  const {
    talentEmailsLoading,
    talentEmails,
    dropDownDataLoading,
    dropDownData,
  } = useSelector(
    (state) => ({
      talentEmailsLoading: state.getTalentEmailReducer.talentEmailsLoading,
      talentEmails: state.getTalentEmailReducer.talentEmails,
      dropDownDataLoading: state.AddCandidate.loading,
      dropDownData: state.AddCandidate.dropDownData,
    }),
    shallowEqual
  );

  const [selectedTab, setSelectedTab] = useState({
    //docSelected: true,
    docSelected: false,
    excelSelected: false,
    manualSelected: false,
    resumeBulkSelected: false,
  });

  const [parsed, setParsed] = useState(false);
  const [parsedData, setParsedData] = useState({});
  const [extractedResumeText, setExtractedResumeText] = useState([]);
  const [existingCandidateList, setExistingCandidateList] = useState([]);

  useEffect(() => {
    if (!talentEmails) {
      dispatch(getAllTalentEmails({ tenantid }));
    }
  }, [talentEmails]);
  useEffect(() => {
    if (talentEmails) {
      setExistingCandidateList(talentEmails);
    }
  }, [talentEmails]);

  useEffect(() => {
    dispatch(getCandidateDropdown());
  }, []);

  useEffect(() => {
    if (!dropDownData) dispatch(getCandidateDropdown());
  }, [dropDownData]);

  const updatedParsed = (data, value, h5h) => {
    setParsedData({ ...data, isAddedByHigh5: h5h, h5h });
    setParsed(value);
  };

  const [tabType, setTabType] = useState("");

  const onCandidateAddTypeClick = (type) => {
    setTabType(type);
    let tempData = { ...selectedTab };
    if (type === "doc") {
      tempData["docSelected"] = true;
      tempData["excelSelected"] = false;
      tempData["manualSelected"] = false;
      tempData["resumeBulkSelected"] = false;
      setSelectedTab(tempData);
    }
    if (type === "excel") {
      tempData["docSelected"] = false;
      tempData["excelSelected"] = true;
      tempData["manualSelected"] = false;
      tempData["resumeBulkSelected"] = false;
      setSelectedTab(tempData);
    }
    if (type === "manual") {
      tempData["docSelected"] = false;
      tempData["excelSelected"] = false;
      tempData["manualSelected"] = true;
      tempData["resumeBulkSelected"] = false;
      setSelectedTab(tempData);
    }
    if (type === "bulkResume") {
      tempData["docSelected"] = false;
      tempData["excelSelected"] = false;
      tempData["manualSelected"] = false;
      tempData["resumeBulkSelected"] = true;
      setSelectedTab(tempData);
    }
  };

  const closeModal = () => {
    setShowAddTalentModal(false);
  };

  return (
    <>
      {talentEmailsLoading && dropDownDataLoading && <Loader />}
      <Modal show={showAddTalentModal} size="lg">
        <div className="modal-header" id="NewCandidateModal">
          <h6>{successTalentFormOpen ? "Talent Created" : "Add Talent"}</h6>
          {showAddTalentModal && (
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => setShowAddTalentModal(false)}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          )}
        </div>
        <Modal.Body>
          {successTalentFormOpen ? (
            <FormSubmitSuccess
              successData={
                //selectedTab.excelSelected ? successData : successTalentData
                successTalentData
              }
              closeModal={closeAddTalentModalPopUp}
            />
          ) : (
            <div>
              <TalentTypes
                selectedTab={selectedTab}
                onCandidateAddTypeClick={onCandidateAddTypeClick}
              />
              {selectedTab.excelSelected && (
                <ExcelUpload
                  //successData={successData}
                  successTalentData={successTalentData}
                  //setSuccessData={setSuccessData}
                  setSuccessTalentData={setSuccessTalentData}
                  setSuccessTalentFormOpen={setSuccessTalentFormOpen}
                  closeModal={closeModal}
                  existingCandidateList={existingCandidateList}
                  talentEmailsLoading={talentEmailsLoading}
                  closeAddTalentModalPopUp={closeAddTalentModalPopUp}
                />
              )}
              {/* {selectedTab.resumeBulkSelected && (
                <BulkResumeUpload
                  //successData={successData}
                  successTalentData={successTalentData}
                  //setSuccessData={setSuccessData}
                  setSuccessTalentData={setSuccessTalentData}
                  setSuccessTalentFormOpen={setSuccessTalentFormOpen}
                  closeModal={closeModal}
                  existingCandidateList={existingCandidateList}
                  talentEmailsLoading={talentEmailsLoading}
                  closeAddTalentModalPopUp={closeAddTalentModalPopUp}
                />
              )} */}

              {selectedTab.docSelected && !parsed && (
                <ResumeUploadAddTalent
                  successTalentData={successTalentData}
                  setSuccessTalentData={setSuccessTalentData}
                  updateParsedData={(data, value, h5h) =>
                    updatedParsed(data, value, h5h)
                  }
                  parsedData={parsedData}
                  closeModal={closeModal}
                  extractedResumeText={extractedResumeText}
                  setExtractedResumeText={setExtractedResumeText}
                />
              )}
              {selectedTab.docSelected && parsed && (
                <AddTalentManualEntry
                  successTalentData={successTalentData}
                  setSuccessTalentData={setSuccessTalentData}
                  setSuccessTalentFormOpen={setSuccessTalentFormOpen}
                  parsedData={parsedData}
                  parsed={parsed}
                  closeModal={closeModal}
                  existingCandidateList={existingCandidateList}
                  tabType={tabType}
                  extractedResumeText={extractedResumeText}
                  setExtractedResumeText={setExtractedResumeText}
                />
              )}
              {selectedTab.manualSelected && (
                <AddTalentManualEntry
                  successTalentData={successTalentData}
                  setSuccessTalentData={setSuccessTalentData}
                  setSuccessTalentFormOpen={setSuccessTalentFormOpen}
                  parsedData={parsedData}
                  parsed={parsed}
                  closeModal={closeModal}
                  existingCandidateList={existingCandidateList}
                  extractedResumeText={extractedResumeText}
                  setExtractedResumeText={setExtractedResumeText}
                  handleClear={handleClear}
                  isFrom={isFrom}
                />
              )}
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddTalent;
