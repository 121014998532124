import React from "react";

const ResumeDownload = (props) => {
  const { setResume, resumeData } = props;
  return (
    <div>
      <div className="bl-modal-body text-center">
        {resumeData?.filePath?.length > 0 ? (
          <ul class="list-unstyled mt-2">
            <li class="d-flex align-items-center justify-content-between mb-2">
              <strong>{resumeData.fileName}</strong>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  window.open(resumeData.filePath, "_blank");
                }}
                className="btn btn-sm btn-secondary d-inline-block"
              >
                <i className="fal fa-cloud-download-alt mr-2"></i>Download
              </a>
            </li>
          </ul>
        ) : (
          <div className="bl-modal-body text-center">
            <strong>No Resume Found</strong>
          </div>
        )}
      </div>
      <div className="text-center my-4">
        <button
          type="button"
          className="btn btn-secondary mr-3"
          data-dismiss="modal"
          onClick={() => setResume(false)}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default ResumeDownload;
