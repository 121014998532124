import React from "react";
import Modal from "react-bootstrap/esm/Modal";
import SubmittalDetailsInfo from "../../../addjob/submittalsdetails/index";

const AutomatchedParameters = (props) => {
  const { jobData, setAutomatch, automatch } = props;
  let jobTempData = {
    ...jobData,
    primarySkills: jobData.primarySkills && JSON.parse(jobData.primarySkills),
    education: jobData.education && JSON.parse(jobData.education),
    location: {
      city: jobData?.city,
      state: jobData?.state,
      country: jobData?.country,
    },
  };

  return (
    <Modal
      size="lg"
      show={automatch}
      onHide={() => setAutomatch(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <h6 className="pt-o mt-3">Automatched Parameters</h6>
      </Modal.Header>
      <Modal.Body>
        <SubmittalDetailsInfo
          addJobData={jobTempData}
          weightage={JSON.parse(jobData.weightage)}
          setWeightage={(data) => null}
          component={"jobDetails"}
        />
      </Modal.Body>
      <Modal.Footer>
        <button variant="secondary" onClick={() => setAutomatch(false)}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AutomatchedParameters;
