import React, { useState, useEffect } from "react"
import TagsInput from "react-tagsinput"
import "react-tagsinput/react-tagsinput.css"
import StarRatings from 'react-star-ratings'
import { ButtonLoader } from "../../../constants/Constants"
import { toast } from 'react-toastify'
import { post } from '../../../service/ApiService'
import { getCandidateId } from "../../../service/AuthService"
import Autosuggest from "react-autosuggest";

function Skills(props) {

  let dropdownList = props.dropdownList
  const [ readOnlyMode, setReadOnlyMode ] = useState(true)
  const [ submittingForm, setSubmittingFrom ] = useState(false)
  const [ showSuccessMessage, setShowSuccessMessage ] = useState(false)
  const [ saveClicked, setSaveClicked ] = useState(true)
  const [ skillSetList, setSkillSetList ]= useState(props.skills)
  const [ tempSkillSetList, setTempSetSkillSetList ]= useState(props.skills)
  const [ skillSet, setSkillSet ] = useState('')
  const [ industry, setIndustry ] = useState(props.industry)
  const [ tempIndustry, setTempIndustry ] = useState(props.industry)
  const [ proficiency, setProficiency ] = useState(1)

  const changeProficiency = (newProficiency, value, index) => {
    let rating = [...skillSetList]
    rating[index] = { skill: value.skill, proficiency: newProficiency }
    setSkillSetList(rating)
  }
  
  const handleChangeInput = (skillSet) => {
    setSkillSet(skillSet)
  }

  const handleChange = (tags) => {
    setSkillSetList(tags)
  }

  const editSkills = () => {
    setReadOnlyMode(false)
    setSaveClicked(true)
  }

  const cancelSkills = () => {
    setSkillSetList(tempSkillSetList)
    setIndustry(tempIndustry)
    setReadOnlyMode(true)
    setShowSuccessMessage(false)
    setSubmittingFrom(false)
    setSaveClicked(true)
  }

  const saveSkills = async () => {
    setTempSetSkillSetList(skillSetList)
    setTempIndustry(industry)
    if(skillSetList.length === 0) {
      toast.error('Atleast one skill is mandatory')
    }
    else { 
      setSaveClicked(false)
      setSubmittingFrom(true)
      try {
        const parameters = {
            candidateID: getCandidateId(),
            clickName: 'candidateSkills',
            userSkillInfo: {
              skillSet: industry? [industry] : [],
              primarySkills: skillSetList
            }
          }
          const res = await post('/candidateProfile/postSkillData', parameters)
          if (res.status === 200) {
          setSubmittingFrom(false)
          setShowSuccessMessage(true)      
            setTimeout(() => { 
              setReadOnlyMode(true)
              setShowSuccessMessage(false)
            }, 1000)
          }
      } catch (error) {
          toast.error("Error Occured")
      }
    }
  }
  
  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "90%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropdownList.filter((item) =>
              item.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => (
      <div>{suggestion}</div>
    );

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion);
        }}
        theme={theme}
      />
    )
  }

  return (
    <>
      <div>     
        <div className={readOnlyMode ? "mb-2 read-only" : "mb-2"}>
          <div class="card card-lg">
            <div class="card-body">
              <div class="d-flex align-items-center justify-content-between">
                <h6 class="mb-0">Skills</h6>          
                {readOnlyMode ? (
                  <button
                    type="button"
                    class="btn btn-text"
                    onClick={editSkills}
                  >
                    <i class="fas fa-pencil-alt mr-1"></i>
                    Edit
                  </button>
                ) : (
                  <div>
                    <button
                      type="button"
                      onClick={cancelSkills}
                      class="btn btn-sm btn-secondary mr-1"
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      onClick={saveSkills}
                      class="btn btn-sm btn-primary"
                    >
                      {saveClicked && <span>Save Changes</span>}

                      {submittingForm && (
                        <span>
                          Saving Changes
                          <img
                            width="20px"
                            src={ButtonLoader}
                            alt=""
                          />
                        </span>
                      )}
                      {showSuccessMessage && <span>Changes Saved!</span>}
                    </button>
                  </div>
                )}
              </div>
              <hr />
              <div class="col-lg-12 col-md-12 col-sm-12">
                {
                  props.role === 'Recruiter' &&
                  <div class="col-xl-4 col-lg-12 col-md-12 col-sm-12">
                      <div class="form-floating mb-1 ">
                          <select
                          class="form-control"
                          aria-describedby="RecruiterPosition"
                          >
                          <option value="">Select Position</option>
                          <option value="Contract">Contract</option>
                          <option value="Temp To Hire">Temp To Hire</option>
                          <option value="Full Time">Full Time</option>
                          <option value="Part Time">Part Time</option>
                          <option value="Volunteer">Volunteer</option>
                          </select>
                          <label>Position Type</label>
                      </div>
                  </div>
                }
                {
                  props.role === 'Candidate' &&
                  <div class="col-lg-12">
                      {readOnlyMode ? (
                        <>
                          <div class="form-floating mb-4">
                            <label>Skills</label>
                            <div class="pt-5 px-3">
                              {
                                skillSetList.map(skill => <span class="tag tag-blue3 mr-1">{skill}</span>)
                              }                                                                  
                            </div>
                          </div>
                          <div class="form-floating mb-4">
                            <label>Industry</label>
                            <div class="pt-5 px-3">
                              <span class="tag tag-blue3 mr-1">{industry}</span>                                                               
                            </div>
                          </div>
                        </>
                      ) : (
                      <>
                          <div class="w-100 mb-4">
                              <label className='mb-1'>Skills*</label>
                              <TagsInput
                                renderInput={autocompleteRenderInput}
                                onChange={handleChange}
                                inputValue={skillSet}
                                onChangeInput={handleChangeInput}
                                value={skillSetList}
                                placeholder="Enter a skill and hit enter"
                                inputProps={{
                                  placeholder: 'Type Skill and Hit Enter',       
                                  className: 'w-50 border-0 mb-2 mt-0'
                                }}
                                
                              />
                          </div>
                          {/* <div className='d-flex m-1 mt-1 mb-2'>
                              <p className='mr-2'>Proficiency</p>
                              <div className='ml-auto'>
                                  <StarRatings
                                      rating={proficiency}
                                      starRatedColor="red"
                                      changeRating={changeProficiency}
                                      numberOfStars={5}
                                      starDimension="20px"
                                      starSpacing="2px"
                                      name='rating'
                                  />
                              </div>                            
                          </div>  */}
                          {/* <div>
                            {
                              skillSetList.map((skill, index) => 
                              <div className='ml-2'>
                                <p className='d-flex'>
                                  <span className='mr-2 mt-1'>{skill.skill}</span>
                                  <div className='ml-auto'>
                                    <StarRatings
                                      rating={skill.proficiency}
                                      starRatedColor="red"
                                      changeRating={(proficiency) => changeProficiency(proficiency, skill, index)}
                                      numberOfStars={5}
                                      starDimension="20px"
                                      starSpacing="2px"
                                      name='rating'
                                      className='mb-1'
                                    />  
                                  </div>                  
                                </p>                                                   
                              </div>)
                            }  
                          </div> */}
                          <div class="row mb-2 pt-3">
                            <div class="col-lg-12">
                              <div class="form-floating">
                                <select value={industry} class="form-select" onChange={(e) => setIndustry(e.target.value)}>
                                  <option disabled>Select Industry</option>
                                  {
                                    [
                                      "Accounting/Finance",
                                      "Marketing",
                                      "Administrative/Clerical",
                                      "Business Professional",
                                      "Call Center",                                       
                                      "Communications",
                                      "Creative",
                                      "Engineering",
                                      "Hardware",
                                      "Healthcare",
                                      "Human Resources",
                                      "Information Technology",
                                      "Legal",
                                      "Medical",
                                      "Operations",
                                      "Procurement",
                                      "Professional",
                                      "R&D",
                                      "Sales",
                                      "Scientific",
                                      "Technical (Non-IT)",
                                      "Technical Support"
                                  ].map(industry => <option>{industry}</option>)
                                  }                    
                                </select>
                                <label>Industry</label>
                              </div>
                            </div>
                          </div>                                                     
                      </>
                      )}
                  </div>
                }                
              </div>
            </div>
          </div>
        </div>{" "}
      </div>
    </>
  )
}

export default Skills
