import React, { useEffect, useState } from "react";
import "../../css/custom.css";
import ReactToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import Loader from "../Loader/Loader";
import { toast } from "react-toastify";
import TopNavBar from "../layout/TopNavBar";
import SideNavBar from "../layout/SideNavBar";
import { get } from "../../service/ApiService";
import moment from "moment";
import Filter from "./GenericFilter";
import Sort from "../common/Sort";
import OutsideClickHandler from "react-outside-click-handler";
import ReportTopNavBar from "./ReportTopNavBar";
import { getTenantType } from "../../service/AuthService";
import Footer from "../layout/Footer";

function GenericReport() {
  const reportHeaders = [
    { id: 0, name: "Job Posted On" },
    { id: 1, name: "Job Title" },
    { id: 2, name: "Job Type" },
    { id: 4, name: "Job Expired On" },
    { id: 5, name: "Job Location" },
    { id: 6, name: "Job Company" },
    { id: 49, name: "Job Posted By" },
    { id: 8, name: "Job Posted by company name" },
    { id: 10, name: "End client company name" },
    { id: 11, name: "Candidate Name" },
    { id: 12, name: "Candidate Location" },
    { id: 14, name: "Candidate Email ID" },
    // { id: 15, name: 'Candidate Phone Number' },
    { id: 42, name: "Candidate Primary Skills" },
    { id: 43, name: "Candidate Secondary Skills" },
    { id: 39, name: "Agreed Pay Rate" },
    { id: 46, name: "Invited Date" },
    { id: 47, name: "Invited By Company" },
    { id: 48, name: "Invited By Recruiter" },
    // { id: 45, name: "Sent Date" },
    // { id: 41, name: "Accepted Date" },
    // { id: 40, name: "Rejected Date" },
    { id: 18, name: "Submitted Date" },
    { id: 19, name: "Submitted By company" },
    { id: 20, name: "Submitted By recruiter" },
    { id: 21, name: "Shortlisted Date" },
    { id: 22, name: "Shortlisted By Name" },
    { id: 23, name: "Shortlisted By Role" },
    { id: 24, name: "Shortlisted By company" },
    { id: 27, name: "Offered By Date" },
    { id: 25, name: "Offered By Name" },
    { id: 28, name: "Offered By Role" },
    { id: 31, name: "Hired On" },
    { id: 32, name: "Hired By" },
    { id: 30, name: "Hired By Role" },
    { id: 35, name: "Disqualified On" },
    { id: 36, name: "Disqualified By" },
    { id: 34, name: "Disqualified By Role" },
    { id: 38, name: "Reason for Disqualification" },
  ];
  const pageLimit = 5;
  const [toggle, setToggle] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [columns, setColumns] = useState([]);
  const [dateSearch, setDateSearch] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [toDate, setToDate] = useState(new Date());
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedJobLocation, setSelectedJobLocation] = useState([]);
  const [selectedPrimarySkills, setSelectedPrimarySkills] = useState([]);
  const [candidateTitles, setCandidateTitles] = useState([]);
  const [candidateTypes, setCandidateTypes] = useState([]);
  const [candidatePrimarySkills, setCandidatePrimarySkills] = useState([]);
  const [candidateJobLocation, setCandidateJobLocation] = useState([]);
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );

  const sortObject = [
    {
      id: 1,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Type",
      value: "jobType",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Candidate Name",
      value: "candidateFname",
      icon: "fal fa-fw fa-signature  mr-2 dd-sort",
    },
    {
      id: 4,
      label: "Job Location",
      value: "jobLocation",
      icon: "fal fa-fw fa-signature  mr-2 dd-sort",
    },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  const toggleDiv = () => {
    setToggle(!toggle);
  };

  useEffect(() => {
    fetchReportData();
  }, []);

  useEffect(() => {
    //setItem(items)
    setColumns(columns);
  }, []);

  {
    /* Data Fetching */
  }
  const fetchReportData = async () => {
    setLoading(true);
    try {
      let res = await get("/report/getdataforreport");
      if (res.status === 200) {
        const tableData = res.data.totalList;
        setReportData(tableData);
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Oops! something went wrong. please try again later");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Oops! something went wrong. please try again");
    }
  };

  {
    /* Pagination */
  }
  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);

  const getPaginatedData = () => {
    const startIndex = currentPage * perPage - perPage;
    const endIndex = startIndex + +perPage;
    return filteredData.slice(startIndex, endIndex);
  };

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  {
    /* Date Search Functionality */
  }
  const handleDateSearch = () => {
    setSelectedTitles([]);
    setSelectedTypes([]);
    setSelectedJobLocation([]);
    setSelectedPrimarySkills([]);
    setSortCount(0);
    setSortArray([]);
    let startDate = moment(new Date(fromDate)).format("MM/DD/YYYY");
    //.toString();
    let endDate = moment(new Date(toDate)).format("MM/DD/YYYY");
    //.toString();
    if (new Date(startDate) > new Date()) {
      toast.error(`'From Date' cannot be future date`);
    } else if (new Date(endDate) > new Date()) {
      toast.error(`'To Date' cannot be future date`);
    } else if (new Date(startDate) > new Date(endDate)) {
      toast.error(`'From Date' should not be greater than 'To Date'`);
    } else {
      console.log(reportData);
      const result = reportData.filter((item) => {
        let FilteredDate = moment(
          new Date(item.rptJobData[0]?.jobPostDate)
        ).format("MM/DD/YYYY");

        return (
          new Date(startDate) <= new Date(FilteredDate) &&
          new Date(FilteredDate) <= new Date(endDate)
        );
      });
      //   return new Date(item.rptJobData[0].jobPostDate) >= new Date(fromDate) && new Date(toDate) >= new Date(item.rptJobData[0].jobPostDate);
      // })
      if (result.length === 0) {
        toast.error("No Record Found. Please select another date range");
        setFilteredData(result);
        setDateSearch(result);
      } else {
        setFilteredData(result);
        setDateSearch(result);
        const pageArray = new Array(Math.ceil(result.length / perPage))
          .fill()
          .map((_, i) => i + 1);
        setPaginationArray(pageArray);
        setPaginationGroup(pageArray.slice(0, pageLimit));
        let title = [];
        let type = [];
        let jobLocations = [];
        let Skill = [];
        result.forEach((item) => {
          if (item.jobTitle) {
            if (!title.includes(item.jobTitle)) {
              title.push(item.jobTitle.trim());
            }
          }
        });
        result.forEach((item) => {
          if (item.jobType) {
            if (!type.includes(item.jobType)) {
              type.push(item.jobType.trim());
            }
          }
        });
        result.forEach((item) => {
          if (item.jobLocation) {
            jobLocations.push(item.jobLocation);
          } else {
            jobLocations.push("Remote");
          }
        });
        result.forEach((item) => {
          if (item.primarySkills.map((skill) => skill.skills[0]).length > 1) {
            let SkillSet = item.primarySkills
              .map((skill) => skill.skills[0])
              .join("- ");
            Skill.push(...SkillSet.split("- "));
          }
        });
        setCandidateTitles(title);
        setCandidateTypes(type);
        setCandidateJobLocation([...new Set(jobLocations)]);
        setCandidatePrimarySkills([...new Set(Skill)]);
      }
    }
  };

  {
    /* No of Records Functionality */
  }
  const onChangeSelect = (e) => {
    if (e.target.value === "All") {
      setPerPage(dateSearch.length);
      setPaginationArray([1]);
      setPaginationGroup([1]);
    } else {
      // setPageLimit(filteredData.length < 10 ? 1 : 3)
      const pageGroup = new Array(
        Math.ceil(filteredData.length / Number(e.target.value))
      )
        .fill()
        .map((_, i) => i + 1);
      setPaginationArray(pageGroup);
      setPaginationGroup(pageGroup.slice(0, pageLimit));
      setPerPage(Number(e.target.value));
    }
  };

  {
    /* Sort and Filter */
  }
  const applySort = (sortedData, close) => {
    setSelectedTitles([]);
    setSelectedTypes([]);
    setSelectedJobLocation([]);
    setSelectedPrimarySkills([]);
    close && setSort(false);
    setFilteredData(sortedData);
    const pageGroup = new Array(Math.ceil(sortedData.length / perPage))
      .fill()
      .map((_, i) => i + 1);
    setPaginationArray(pageGroup);
    setPaginationGroup(pageGroup.slice(0, pageLimit));
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(
      dateSearch.map((i) => ({
        ...i,
        jobLocation: i.jobLocation ? i.jobLocation.trim() : "Remote",
      })),
      true
    );
  };

  const applyFilter = (finalData) => {
    //sortClearAll()
    setFilter(false);
    setSortCount(0);
    setSortArray([]);
    if (finalData.length === 0) {
      toast.error("No Record Found Matching Your Filter Criteria");
    } else {
      setFilteredData(finalData);
      const pageGroup = new Array(Math.ceil(finalData.length / perPage))
        .fill()
        .map((_, i) => i + 1);
      setPaginationArray(pageGroup);
      setPaginationGroup(pageGroup.slice(0, pageLimit));
    }
  };

  return (
    <div>
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <ReportTopNavBar handleToggale={toggleDiv} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="card card-flat card-borderless p-4 mb-3">
                    <div>
                      <div className="d-flex align-items-center justify-content-between">
                        <h5 className="ml-2.5">Master Report</h5>
                      </div>
                      <div className="d-flex bd-highlight ml-2.5">
                        {/* FROM DATE */}
                        <div
                          className="p-2 bd-highlight"
                          style={{ width: "10em" }}
                        >
                          <label htmlFor="start-date">
                            <h6 className="m-0">From</h6>
                          </label>
                          <DatePicker
                            className="form-control"
                            selected={fromDate}
                            onChange={(date) => setFromDate(date)}
                            onChangeRaw={(e) => e.preventDefault()}
                          />
                        </div>

                        {/* TO DATE */}
                        <div
                          className="p-2 bd-highlight"
                          style={{ width: "10em" }}
                        >
                          <label htmlFor="end-date">
                            <h6 className="m-0">To</h6>
                          </label>
                          <DatePicker
                            className="form-control"
                            selected={toDate}
                            onChange={(date) => setToDate(date)}
                            onChangeRaw={(e) => e.preventDefault()}
                          />
                        </div>

                        {/* SEARCH */}
                        <div className="p-2 align-self-end bd-highlight mt-10 ">
                          <button
                            className="btn btn-sm btn-primary"
                            type="submit"
                            onClick={() => handleDateSearch()}
                          >
                            Search
                          </button>
                        </div>

                        <div className="py-2 align-self-end bd-highlight">
                          {getPaginatedData().length > 0 && (
                            <ReactToExcel
                              className="btn btn-sm"
                              table="table-to-xls"
                              filename="GenericReportExcel"
                              sheet="sheet 1"
                              buttonText={
                                <span>
                                  <i className="fas fa-file-download mr-2"></i>
                                  Download
                                </span>
                              }
                            ></ReactToExcel>
                          )}
                        </div>

                        {/* FILTER */}
                        <div class="py-2 align-self-end">
                          <div class="dropdown position-relative">
                            <button
                              data-bs-toggle="tooltip"
                              data-bs-placement="left"
                              title="Filter"
                              onClick={() => setFilter(true)}
                              type="button"
                              class="btn btn-sm btn-text-accent w-auto"
                              disabled={filteredData.length === 0}
                            >
                              <i class="fas fa-filter"></i>
                              <span>Filter</span> /
                              <span>
                                {selectedTitles.length +
                                  selectedTypes.length +
                                  selectedPrimarySkills.length +
                                  selectedJobLocation.length}
                              </span>
                            </button>
                          </div>
                        </div>

                        {/* SORT */}
                        <div class="py-2 align-self-end">
                          <div class="dropdown-sort dd-sort">
                            <button
                              type="button"
                              onClick={() => setSort(!sort)}
                              class="btn btn-sm w-auto btn-text-accent dd-sort"
                              disabled={filteredData.length === 0}
                            >
                              <i
                                aria-hidden="true"
                                class="fad fa-sort-down font-16 dd-sort mr-1"
                              ></i>
                              Sort / <span class="dd-sort">{sortCount}</span>
                            </button>
                            {
                              <OutsideClickHandler
                                onOutsideClick={() => setSort(false)}
                              >
                                {sort && (
                                  <Sort
                                    sortData={dateSearch.map((i) => ({
                                      ...i,
                                      jobLocation: i.jobLocation
                                        ? i.jobLocation.trim()
                                        : "Remote",
                                    }))}
                                    orderArray={orderList}
                                    sortObjects={sortObject}
                                    applySort={applySort}
                                    setSortCount={setSortCount}
                                    sortCount={sortCount}
                                    setSortArray={setSortArray}
                                    sortArray={sortArray}
                                    sortingFor={
                                      "Sort by Job Title, Job Type, Candidate Name, and Job Location"
                                    }
                                  />
                                )}
                              </OutsideClickHandler>
                            }
                          </div>
                        </div>

                        <div
                          className="bd-highlight ml-auto mt-4"
                          style={{ width: "15rem", marginLeft: "0.6rem" }}
                        >
                          <h6 className="mt-1">Records</h6>
                          <select
                            className="form-select"
                            onChange={(e) => onChangeSelect(e)}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            <option value="All">All</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    {getPaginatedData().length > 0 ? (
                      <div
                        className="px-3 pt-0 table-cards-1024 mt-3"
                        style={{
                          overflowX: "scroll",
                        }}
                      >
                        <table
                          className="table table-hoverabletable-cards-1024"
                          id="table-to-xls"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          <thead>
                            <tr>
                              {reportHeaders.map((header) => (
                                <th className="w-100">{header.name}</th>
                              ))}
                            </tr>
                          </thead>
                          {getPaginatedData().map((item) => (
                            <tbody>
                              <tr>
                                <td>
                                  {moment(
                                    new Date(item.rptJobData[0].jobPostDate)
                                  ).format("MM/DD/YYYY")}
                                </td>
                                <td>{item.jobTitle}</td>
                                <td>{item.jobType}</td>
                                <td>
                                  {moment(
                                    new Date(item.rptJobData[0].expiresOn)
                                  ).format("MM/DD/YYYY")}
                                </td>
                                <td>{item.jobLocation || "Remote"}</td>
                                <td>{item.rptJobData[0].jobCompany}</td>
                                <td>{item.rptJobData[0].HiringManagerName}</td>
                                <td>{item.jobTenant}</td>
                                <td>{item.rptJobData[0].EndClientCompany}</td>
                                <td>
                                  {item.candidateFname +
                                    " " +
                                    item.candidateLname}
                                </td>
                                <td>{item.candidateLocation}</td>
                                <td>{item.candidateEmail}</td>
                                {/* {item.userInfo.candidatePhone ? (

                                  <td>{item.userInfo.candidatePhone}</td>
                                ) : ("")} */}
                                <td>
                                  {item.primarySkills
                                    .map((skill) => skill.skills[0])
                                    .join(", ")}{" "}
                                </td>
                                <td>
                                  {item.secondarySkills
                                    .map((skill) => skill.skills[0])
                                    .join(", ")}{" "}
                                </td>
                                <td>
                                  {item.payRate &&
                                    item.payRate.replace(
                                      /\B(?=(\d{3})+(?!\d))/g,
                                      ","
                                    ) +
                                      " " +
                                      item.payRateCurrency}
                                </td>
                                {item.sourcedDate === null ? (
                                  <td>{""}</td>
                                ) : (
                                  <td>
                                    {moment(new Date(item.sourcedDate)).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                )}
                                <td>{item.recruiterTenant}</td>
                                <td>{item.sourcedBy}</td>
                                {/* {item.sourcedDate === null ? (
                                  <td>{""}</td>
                                ) : (
                                  <td>
                                    {moment(new Date(item.sourcedDate)).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                )}
                                {item.rtrAcceptedDate === null ? (
                                  <td>{""}</td>
                                ) : (
                                  <td>
                                    {moment(
                                      new Date(item.rtrAcceptedDate)
                                    ).format("MM/DD/YYYY")}
                                  </td>
                                )}
                                {item.rtrRejectedDate == "" ? (
                                  <td>{""}</td>
                                ) : (
                                  <td>
                                    {moment(
                                      new Date(item.rtrRejectedDate)
                                    ).format("MM/DD/YYYY")}
                                  </td>
                                )} */}
                                {item.submittedToClientDate == "" ? (
                                  <td>{""}</td>
                                ) : (
                                  <td>
                                    {item.submittedToClientDate
                                      ? moment(
                                          new Date(item.submittedToClientDate)
                                        ).format("MM/DD/YYYY")
                                      : null}
                                  </td>
                                )}
                                <td>
                                  {item.recruiterTenant?.replace("GIG - ", "")}
                                </td>
                                <td>{item.recruiterName}</td>
                                {item.shortlistedDate == "" ? (
                                  <td>{""}</td>
                                ) : (
                                  <td>
                                    {moment(
                                      new Date(item.shortlistedDate)
                                    ).format("MM/DD/YYYY")}
                                  </td>
                                )}
                                <td>{item.shortlistedBy}</td>
                                <td>{item.shortlistedRole}</td>
                                <td>
                                  {item.shortlistedDate && item.jobTenant}
                                </td>
                                {item.OfferedDate == "" ? (
                                  <td>{""}</td>
                                ) : (
                                  <td>
                                    {moment(new Date(item.OfferedDate)).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                )}
                                <td>{item.offeredBy}</td>
                                <td>{item.offeredRole}</td>
                                {item.hiredDate == "" ? (
                                  <td>{""}</td>
                                ) : (
                                  <td>
                                    {moment(new Date(item.hiredDate)).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                )}
                                <td>{item.hiredBy}</td>
                                <td>{item.hiredRole}</td>
                                {item.rejectedDate == "" ? (
                                  <td>{""}</td>
                                ) : (
                                  <td>
                                    {moment(new Date(item.rejectedDate)).format(
                                      "MM/DD/YYYY"
                                    )}
                                  </td>
                                )}
                                <td>{item.rejectedByClientBy}</td>
                                <td>{item.rejectedRole}</td>
                                <td>{item.disqualifyComments}</td>
                              </tr>
                            </tbody>
                          ))}
                        </table>
                      </div>
                    ) : (
                      <div style={{ visibility: "hidden" }}>
                        <div className="text-center">
                          <div className="avatar avatar-lg">
                            <i
                              aria-hidden="true"
                              // className="fad fa-folder-open"
                            ></i>
                          </div>{" "}
                          <div className="mt-6"></div>
                        </div>
                      </div>
                    )}
                    {getPaginatedData().length > 0 && (
                      <div className="d-flex justify-content-end pt-4 px-1">
                        <div
                          className="ml-auto mx-auto-370 d-flex align-items-center"
                          // style={{ position: 'absolute', left: '85%' }}
                        >
                          <button
                            onClick={() => {
                              setPageType("down");
                              setCurrentPage((page) => page - 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === 1 ||
                              getPaginatedData().length === 0
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-left"></i>
                          </button>

                          {getPaginatedData().length > 0
                            ? paginationGroup.map((item) => (
                                <button
                                  type="button"
                                  className={
                                    currentPage === item
                                      ? "btn btn-text px-2 bg-secondary text-white"
                                      : "btn btn-text px-2"
                                  }
                                  onClick={changePage}
                                >
                                  {item}
                                </button>
                              ))
                            : ""}

                          <button
                            onClick={() => {
                              setPageType("up");
                              setCurrentPage((page) => page + 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === paginationArray.slice(-1)[0] ||
                              (getPaginatedData().length === 0 &&
                                getPaginatedData().length < 0)
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-right"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
        {filter && (
          <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
            <Filter
              reportData={dateSearch.map((item) => ({
                ...item,
                jobLocation: item.jobLocation ? item.jobLocation : "Remote",
              }))}
              selectedTitles={selectedTitles}
              setSelectedTitles={setSelectedTitles}
              candidateTitles={candidateTitles}
              selectedTypes={selectedTypes}
              setSelectedTypes={setSelectedTypes}
              candidateTypes={candidateTypes}
              selectedJobLocation={selectedJobLocation}
              setSelectedJobLocation={setSelectedJobLocation}
              candidateJobLocation={candidateJobLocation}
              candidatePrimarySkills={candidatePrimarySkills}
              selectedPrimarySkills={selectedPrimarySkills}
              setSelectedPrimarySkills={setSelectedPrimarySkills}
              applyFilter={applyFilter}
            />
          </OutsideClickHandler>
        )}
      </body>
    </div>
  );
}

export default GenericReport;
