import React, { useState } from "react";
import { Button, Row, Col } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import SelectJobs from "./SelectJobs";
import { get, post } from "../../service/ApiService";
import SelectRecruiters from "./SelectRecruiters";
import EmailPreview from "./EmailPreview";
import { toast } from "react-toastify";
import { useEffect } from "react";

const JobPromotion = (props) => {
  const [selectJobs, setSelectJobs] = useState(true);
  const [selectRecruiters, setSelectRecruiter] = useState(false);
  const [selectEmail, setSelectEmail] = useState(false);
  const [finalRequests, setFinalRequests] = useState([]);
  const [list, setList] = useState([]);
  const [userChecked, setUserChecked] = useState([]);
  const [recruiterChecked, setRecruiterChecked] = useState([]);
  const [backJobsChecked, setBackJobsChecked] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState([]);
  const [recruiters, setRecruiters] = useState([]);
  const [jobCount, setJobCount] = useState(0);
  const [recruiterCount, setRecruiterCount] = useState(0);
  const [emailCount, setEmailCount] = useState(0);
  const handleClose = async () => {
    props.setShow(false);
    setSelectJobs(true);
    setSelectRecruiter(false);
    setSelectEmail(false);
    setRecruiterChecked([]);
    setUserChecked([]);
  };

  const handleSave = async () => {
    if (userChecked.length > 0 && recruiterChecked.length > 0) {
      const payload = {
        userChecked,
        recruiterChecked,
      };
      try {
        setLoading(true);
        let res = await post("/requestlist/mailSendToRec", payload);
        if (res.status === 200) {
          setLoading(false);
          handleClose();
          setSelectJobs(true);
          setSelectRecruiter(false);
          setSelectEmail(false);
          setRecruiterChecked([]);
          setUserChecked([]);
          toast.success("Email sent successfully");
        }
      } catch {
        toast.error("Error!!");
        setLoading(false);
      }
    }
  };

  const handleNext = async () => {
    if (userChecked.length <= 0) {
      toast.error("Please select one or more jobs");
    }
    if (recruiterChecked.length <= 0 && selectRecruiters) {
      toast.error("Please select one or more recruiters");
    }
    if (userChecked.length > 0 && selectJobs) {
      setSelectRecruiter(true);
      setSelectJobs(false);
      setSelectEmail(false);
    }
    if (
      userChecked.length > 0 &&
      recruiterChecked.length > 0 &&
      selectRecruiters
    ) {
      setSelectRecruiter(false);
      setSelectJobs(false);
      setSelectEmail(true);
    }
  };
  const selectRecruiterClick = async () => {
    if (userChecked.length <= 0) {
      toast.error("Please select one or more jobs");
    }
    if (userChecked.length > 0) {
      // setRecruiterChecked([]);
      setSelectRecruiter(true);
      setSelectEmail(false);
      setSelectJobs(false);
    }
  };
  const selectJobsClick = async () => {
    setSelectJobs(true);
    setSelectRecruiter(false);
    setSelectEmail(false);
  };

  const onEmailClick = async () => {
    if (userChecked.length <= 0 && recruiterChecked.length <= 0) {
      toast.error("Please select atleast one job and recruiter");
    }
    if (userChecked.length > 0 && recruiterChecked.length > 0) {
      setSelectJobs(false);
      setSelectRecruiter(false);
      setSelectEmail(true);
    }
  };

  const handleBack = async () => {
    if (
      (selectEmail || selectRecruiters) &&
      recruiterChecked.length > 0 &&
      userChecked.length > 0
    ) {
      setSelectRecruiter(true);
      setSelectEmail(false);
    }
    if (selectRecruiters && !selectEmail) {
      setSelectJobs(true);
      setSelectRecruiter(false);
      setSelectEmail(false);
    }
  };

  return (
    <div>
      <Modal show={props.show} size="xl" onHide={handleClose}>
        <Modal.Header closeButton>
          <h6>Job Promotion</h6>
        </Modal.Header>
        <Modal.Body>
          <div className="mt-3">
            <div>
              <ul className="nav nav-tabs pt-1 ml-3">
                <li className="nav-item">
                  <div className="d-flex justify-content-center ">
                    <span className="tag text-center">{jobCount}</span>
                  </div>

                  <button
                    className={
                      selectJobs
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    onClick={selectJobsClick}
                  >
                    <div>Select Jobs</div>
                  </button>
                </li>
                <li className="nav-item">
                  <div className="d-flex justify-content-center ">
                    <span className="tag text-center">{recruiterCount}</span>
                  </div>
                  <button
                    className={
                      selectRecruiters
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    onClick={selectRecruiterClick}
                  >
                    <div>Select Recruiters</div>
                  </button>
                </li>
                <li className="nav-item">
                  <div className="d-flex justify-content-center ">
                    <span className="tag text-center">{emailCount}</span>
                  </div>
                  <button
                    className={
                      selectEmail
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                    onClick={() => onEmailClick()}
                  >
                    <div>Email</div>
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <hr></hr>
          <div className="card m-3 p-2">
            <div className="card-body">
              {/* <JobPromotion finalRequests={finalRequests} selectJobs={selectJobs} selectRecruiters={selectRecruiters} selectEmail={selectEmail} /> */}
              <Row className="mb-4">
                <Col>
                  {selectJobs && (
                    <SelectJobs
                      userChecked={userChecked}
                      setUserChecked={setUserChecked}
                      backJobsChecked={backJobsChecked}
                      setBackJobsChecked={setBackJobsChecked}
                      setJobCount={setJobCount}
                    />
                  )}
                  {selectRecruiters && (
                    <SelectRecruiters
                      recruiterChecked={recruiterChecked}
                      setRecruiterChecked={setRecruiterChecked}
                      setRecruiterCount={setRecruiterCount}
                    />
                  )}
                  {selectEmail && (
                    <EmailPreview
                      userChecked={userChecked}
                      recruiterChecked={recruiterChecked}
                      loading={loading}
                      setEmailCount={setEmailCount}
                    />
                  )}
                </Col>
              </Row>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {!selectJobs && (
            <Button variant="secondary" onClick={() => handleBack()}>
              Back
            </Button>
          )}
          {!selectEmail ? (
            <Button variant="primary" onClick={() => handleNext()}>
              Next
            </Button>
          ) : (
            <Button variant="primary" onClick={() => handleSave()}>
              Send
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default JobPromotion;
