import React, { useState, useEffect } from "react";
import CreatableSelect from "react-select/creatable";
import { toast } from "react-toastify";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { getAllTags } from "../../../../actions/tagmanagement/getAllTags";
import Loader from "../../../Loader/Loader";
import {
  getCandidateId,
  getUserTenantID,
} from "../../../../service/AuthService";
import { post } from "../../../../service/ApiService";

const TagInput = (props) => {
  const dispatch = useDispatch();
  const { talentData } = props;
  const [selectedTags, setSelectedTags] = useState([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  const [newlyAddedTags, setNewlyAddedTags] = useState([]);
  const [recruiterTags, setRecruiterTags] = useState([]);

  const { tagsData } = useSelector(
    (state) => ({
      tagsData: state.tagReducer.tagsData,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(getAllTags());
  }, [dispatch]);

  useEffect(() => {
    if (JSON.parse(talentData?.candidateTags)) {
      const tagSelected = JSON.parse(talentData?.candidateTags).map((item) => ({
        label: item,
        value: item,
      }));
      setSelectedTags(tagSelected);
    } else {
      setSelectedTags([]);
    }
  }, [talentData]);

  useEffect(() => {
    if (tagsData) {
      const tagOptions = tagsData
        .filter((item) => item.tagStatus === 21)
        .map((item) => ({
          label: item.tagName,
          value: item.tagName,
        }));
      setLoading(false);
      setOptions(tagOptions);
    }
  }, [tagsData]);

  const handleCreateOption = async (inputValue) => {
    setLoading(true);
    const newOption = { value: inputValue, label: inputValue };
    let paramObj = {
      tagName: inputValue,
      createdBy: getCandidateId(),
      createdDateTime: new Date(),
      tagStatus: 21,
      fk_tenant: getUserTenantID(),
    };

    let updatedTagList = selectedTags?.map((item) => item.label);
    updatedTagList.push(inputValue);

    let newTagObj = {
      tags: updatedTagList,
      candidateId: talentData.userId,
      createdBy: getCandidateId(),
      updatedBy: getCandidateId(),
      fk_tenant: getUserTenantID(),
    };

    try {
      // Make an API call to add the new tag to the database
      await post("/tags/createTag", paramObj);
      await post("/tags/updateCandidateTags", newTagObj);
      toast.success("Tag assigned successfully");
      setOptions([...options, newOption]);
      setSelectedTags([...selectedTags, newOption]);
      setNewlyAddedTags([...newlyAddedTags, newOption]);
      let payload = {
        currentPage: 1,
        limit: 10,
        status: null,
        tab: "All",
        sortParam: [],
      };
      dispatch(props.getTalentList(payload));
    } catch (error) {
      console.error("Error adding tag:", error);
    } finally {
      setLoading(false);
    }
    return newOption;
  };

  const handleChange = async (selectedOptions) => {
    setLoading(true);
    setSelectedTags(selectedOptions);
    let selectedTagList = selectedOptions.map((item) => item.label);
    let payload1 = {
      tags: selectedTagList,
      candidateId: talentData.userId,
      createdBy: getCandidateId(),
      updatedBy: getCandidateId(),
      fk_tenant: getUserTenantID(),
    };

    try {
      await post("/tags/updateCandidateTags", payload1);
      if (selectedTagList?.length > 0)
        toast.success("Tags updated successfully");
      else toast.success("Tags removed successfully");
      let payload = {
        currentPage: 1,
        limit: 10,
        status: null,
        tab: props.parentTab,
        sortParam: [],
      };
      dispatch(props.getTalentList(payload));
      setLoading(false);
      //props.handleClose();
    } catch (err) {
      setLoading(false);
    }
  };

  const customStyles = {
    multiValueRemove: (base, state) => ({
      ...base,
      display: "none", // Hide the close icon
    }),
  };

  return (
    <div>
      {loading && <Loader />}
      <h6 className="mb-4 ml-2">Tags</h6>
      <hr />
      <CreatableSelect
        isMulti
        options={options}
        value={selectedTags}
        onChange={handleChange}
        onCreateOption={handleCreateOption}
        isSearchable
        allowCreateWhileLoading={false}
        formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
        placeholder="Search"
        isDisabled={!talentData?.tenantId?.includes(String(getUserTenantID()))}
        styles={
          !talentData?.tenantId?.includes(String(getUserTenantID())) &&
          customStyles
        }
      />
    </div>
  );
};

export default TagInput;
