import {
  GET_CRONJOB_DATA_BEGIN,
  GET_CRONJOB_DATA_SUCCESS,
  GET_CRONJOB_DATA_FAILURE,
  UPDATE_CRONJOB_DATA_BEGIN,
  UPDATE_CRONJOB_DATA_SUCCESS,
  UPDATE_CRONJOB_DATA_FAILURE,
  GET_CRONJOBLOG_DATA_BEGIN,
  GET_CRONJOBLOG_DATA_SUCCESS,
  GET_CRONJOBLOG_DATA_FAILURE,
  TRIGGER_CRONJOB_DATA_BEGIN,
  TRIGGER_CRONJOB_DATA_SUCCESS,
  TRIGGER_CRONJOB_DATA_FAILURE,
} from "../../actions/adminconsole/CronJobs.js";

const initialState = {
  cronJobsLoading: true,
  cronJobsData: [],
  cronJobUpdated: false,
  totalCount: 0,
  cronJobLog: [],
};

const cronJobsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_CRONJOB_DATA_BEGIN:
      return {
        ...state,
        cronJobsLoading: true,
        cronJobsData: [],
        totalCount: 0,
      };
    case GET_CRONJOB_DATA_SUCCESS:
      return {
        ...state,
        cronJobsData: payload,
        cronJobsLoading: false,
      };
    case GET_CRONJOB_DATA_FAILURE:
      return {
        ...state,
        cronJobsData: [],
        cronJobsLoading: false,
      };
    case UPDATE_CRONJOB_DATA_BEGIN:
      return {
        ...state,
        cronJobsLoading: true,
        cronJobUpdated: false,
      };
    case UPDATE_CRONJOB_DATA_SUCCESS:
      return {
        ...state,
        cronJobsLoading: false,
        cronJobUpdated: true,
      };
    case UPDATE_CRONJOB_DATA_FAILURE:
      return {
        ...state,
        cronJobsLoading: false,
        cronJobUpdated: false,
      };
    case GET_CRONJOBLOG_DATA_BEGIN:
      return {
        ...state,
        cronJobsLoading: true,
        cronJobLog: [],
      };
    case GET_CRONJOBLOG_DATA_SUCCESS:
      return {
        ...state,
        cronJobLog: payload,
        cronJobsLoading: false,
      };
    case GET_CRONJOBLOG_DATA_FAILURE:
      return {
        ...state,
        cronJobLog: [],
        cronJobsLoading: false,
      };
    case TRIGGER_CRONJOB_DATA_BEGIN:
      return {
        ...state,
        cronJobsLoading: true,
        cronJobUpdated: false,
      };
    case TRIGGER_CRONJOB_DATA_SUCCESS:
      return {
        ...state,
        cronJobsLoading: false,
        cronJobUpdated: true,
      };
    case TRIGGER_CRONJOB_DATA_FAILURE:
      return {
        ...state,
        cronJobsLoading: false,
        cronJobUpdated: false,
      };
    default:
      return state;
  }
};

export default cronJobsReducer;
