import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import Select from "react-select";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import HereMap from "../../../location/CommonHereMap";
import Autosuggest from "react-autosuggest";
import PreferredLocation from "../../../location/PreferredLocation";
import _ from "lodash";
import { dropDownData } from "../../../directsourcing/Edit Talent DS/dropDownData";
// import { dropDownData }

const customStyles = {
  multiValue: (styles) => {
    return {
      ...styles,
      backgroundColor: "#dcedff",
    };
  },
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

function ProfileInfo(props) {
  const [preferredLocation, setPreferredLocation] = useState([]);
  const [form, setForm] = useState(null);
  const [primarySkillinput, setPrimarySkillInput] = useState("");
  const [secondarySkillsInput, setSecondarySkillSInput] = useState("");
  const [positionTypes, setPositionTypes] = useState([]);
  const [skillSet, setSkillSet] = useState([]);
  // const { dropDownData } = useSelector(
  //   (state) => ({
  //     dropDownData: state?.AddCandidate?.dropDownData,
  //   }),
  //   shallowEqual
  // );

  const visaStatus = [
    "Citizen",
    "Green Card",
    "GC EAD",
    "H1B",
    "H4 EAD",
    "L2EAD",
    "Not Required",
    "OPT EAD",
    "TN1",
    "TN2",
  ];

  let positionTypeOption = dropDownData.PositionType.map((item) => {
    const obj = { value: item.SourceName, label: item.SourceName };
    return obj;
  });
  positionTypeOption = _.sortBy(positionTypeOption, ["value"]);

  let skillSetOption = dropDownData.skillSet.map((item) => {
    const obj = { value: item.SourceName, label: item.SourceName };
    return obj;
  });
  skillSetOption = _.sortBy(skillSetOption, ["value"]);

  const deleteLocation = (index) => {
    let current = [...preferredLocation];
    current.splice(index, 1);
    setPreferredLocation(current);
    props.changePreferredLocation(current);
  };

  const handlePositiontype = (e) => {
    setPositionTypes(e);
    props.setProfileInfo({
      ...props.profileInfo,
      positionTypes: e.map((pType) => pType.value),
    });
  };

  const handleSkillSet = (e) => {
    setSkillSet(e);
    props.setProfileInfo({
      ...props.profileInfo,
      skillSet: e.map((skillSet) => skillSet.value),
    });
  };

  const handleChangePrimarySkills = (skills) => {
    props.setProfileInfo({ ...props.profileInfo, primarySkills: skills });
  };

  const handleChangeSecondarySkills = (skills) => {
    props.setProfileInfo({ ...props.profileInfo, secondarySkills: skills });
  };

  const handleChangeInputPrimarySkills = (skills) => {
    setPrimarySkillInput(skills);
  };

  const handleChangeInputSecondarySkills = (skills) => {
    setSecondarySkillSInput(skills);
  };

  const handleChange = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      [e.target.id]: e.target.value,
    });
  };

  const handleChangeExperienceYear = (e) => {
    let value = e.target.value === "" ? "0" : e.target.value;

    props.setProfileInfo({
      ...props.profileInfo,
      experienceYear: value,
    });
  };

  const handleChangeExperienceMonth = (e) => {
    let value = e.target.value === "" ? "0" : e.target.value;
    props.setProfileInfo({
      ...props.profileInfo,
      experienceMonth: value,
    });
  };

  const onRemoteSwitch = (e) => {
    props.setProfileInfo({
      ...props.profileInfo,
      [e.target.id]: e.target.checked,
    });
  };

  const onIsfresherSwitch = (e) => {
    if (e.target.id == "isFresher" && e.target.checked === true) {
      // disable experince tab
      console.log(e.target.id, e.target.checked);
      props.setProfileInfo({ ...props.profileInfo, experienceLevel: " " });
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceLevel: "Junior :0-3 years",
      });
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceYear: "",
        experienceMonth: "",
        currentJobTitle: "",
      });
    } else {
      // enable tab
      props.setProfileInfo({
        ...props.profileInfo,
        [e.target.id]: e.target.checked,
        experienceLevel: "",
        experienceYear: "",
        experienceMonth: "",
        currentJobTitle: "",
      });
    }
  };
  //File upload block
  const setFileForSending = (file) => {
    const { name } = file;
    const type = name.split(".")[1];
    if (type === "pdf" || type === "docx" || type === "doc") {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (event) => {
        props.setProfileInfo({
          ...props.profileInfo,
          fileName: name,
          base64: event.target.result,
        });
        toast.success("File uploaded successfully");
      };
    } else {
      toast.error("Please Upload PDF or WORD Document");
    }
  };

  const onRemove = (e) => {
    props.setProfileInfo({ ...props.profileInfo, fileName: "", base64: "" });
    toast.success("File removed successfully");
  };
  // File upload on drag
  const onDragDropChangeHandler = (e) => {
    setFileForSending(e[0]);
  };

  // Auto suggestion in tags input
  const autocompleteRenderInput = ({ addTag, ...props }) => {
    const theme = {
      suggestionsContainerOpen: {
        display: "block",
        position: "absolute",
        width: "95%",
        border: "1px solid #aaa",
        listStyle: "none",
        zIndex: 10,
        backgroundColor: "rgb(255, 255, 255)",
        fontSize: 14,
        fontFamily: "sans-serif",
        maxHeight: "250px",
        overflow: "auto",
        padding: "5px 15px",
      },
      suggestionsList: {
        listStyleType: "none",
      },
      suggestion: {
        cursor: "pointer",
        padding: "5px 0px",
      },
      suggestionHighlighted: {
        backgroundColor: "rgba(114, 112, 112, 0.125)",
      },
    };

    const handleOnChange = (e, { newValue, method }) => {
      if (method === "enter") {
        e.preventDefault();
      } else {
        props.onChange(e);
      }
    };

    const getSuggestionValue = (suggestion) => suggestion.SourceName;

    const getSuggestions = (value) => {
      const inputValue = value.trim().toLowerCase();
      const inputLength = inputValue.length;
      const suggestValues =
        inputLength === 0
          ? []
          : dropDownData.CandidateSkills.filter((item) =>
              item.SourceName.toLowerCase().includes(inputValue)
            );
      return suggestValues;
    };

    const renderSuggestion = (suggestion) => <div>{suggestion.SourceName}</div>;

    const value = (props.value && props.value.trim().toLowerCase()) || "";
    let suggestions = getSuggestions(value);

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={() => {}}
        onSuggestionsClearRequested={() => {}}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={{ ...props, onChange: handleOnChange }}
        onSuggestionSelected={(e, { suggestion }) => {
          addTag(suggestion.SourceName);
        }}
        theme={theme}
      />
    );
  };

  console.log("dropDownData", dropDownData);

  let yearArr = [];
  let dayArr = [];

  const filterCurrencyType = () => {
    dropDownData.CurrencyTime.map((x) => {
      let ans = x.SourceName.split("/")[1];

      if (ans === "Year") {
        yearArr.push(x);
      } else {
        dayArr.push(x);
      }
    });
  };
  filterCurrencyType();

  useEffect(() => {
    filterCurrencyType();
    console.log("dropDownData", yearArr, dayArr);
  }, [dropDownData]);

  return (
    <>
      <div>
        <div class="card-body">
          <div class="row mb-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <select
                  id="source"
                  class="form-select"
                  onChange={handleChange}
                  value={props.profileInfo.source}
                >
                  <option value="" selected>
                    Select Source
                  </option>
                  {dropDownData.Source.sort((a, b) =>
                    a.SourceName > b.SourceName ? 1 : -1
                  ).map((item) => (
                    <option value={item.SourceName}>{item.SourceName}</option>
                  ))}
                </select>
                <label>Source</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <select
                  id="visaStatus"
                  class="form-select"
                  onChange={handleChange}
                  value={props.profileInfo.visaStatus}
                >
                  <option value="" selected>
                    Select Status
                  </option>
                  {visaStatus.map((item) => (
                    <option value={item}>{item}</option>
                  ))}
                </select>
                <label>Visa Status</label>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-12">
              <label>Social </label>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Faceebook ID"
                  id="facebook"
                  value={props.profileInfo.facebook}
                  onChange={handleChange}
                />
                <label>Facebook ID</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Twitter ID"
                  id="twitter"
                  value={props.profileInfo.twitter}
                  onChange={handleChange}
                />
                <label>Twitter ID</label>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Linkedin ID"
                  id="linkedIn"
                  value={props.profileInfo.linkedIn}
                  onChange={handleChange}
                />
                <label>Linkedin ID</label>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-12">
              <label>Rates </label>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  placeholder="Preferred Salary"
                  id="preferredSalary"
                  value={props.profileInfo.preferredSalary}
                  //onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value === "0") {
                      toast.error("Cannot Be Zero");
                    } else if (+e.target.value < 0) {
                      toast.error("Negative numbers are not allowed");
                    } else {
                      handleChange(e);
                    }
                  }}
                />
                <label>Preferred Salary</label>
              </div>
              <small className="validation">
                {props.profileInfoValidation.preferredSalary}
              </small>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <select
                  class="form-select"
                  //disabled={!props.profileInfo.preferredSalary > 0}
                  id="preferredSalaryCurrency"
                  style={{
                    border:
                      props.profileInfoValidation.preferredSalaryCurrency &&
                      "2px solid #ff0000",
                  }}
                  value={props.profileInfo.preferredSalaryCurrency}
                  onChange={handleChange}
                >
                  <option value="" selected disabled>
                    Select Type
                  </option>
                  {/* {yearArr.sort((a, b) =>
                    a.SourceName > b.SourceName ? 1 : -1
                  ).map((item) => (
                    <option value={item.SourceName}>{item.SourceName}</option>
                  ))} */}
                  {console.log("_______", yearArr)}
                  {yearArr.map((item) => (
                    <option value={item.SourceName}>{item.SourceName}</option>
                  ))}
                </select>
                <label>
                  Currency Type{props.profileInfo.preferredSalary > 0 && "*"}
                </label>
              </div>
              <small className="validation">
                {props.profileInfoValidation.preferredSalaryCurrency}
              </small>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-4">
              <div class="form-floating">
                <input
                  type="number"
                  min="1"
                  class="form-control"
                  placeholder="Minimum Contract Rate"
                  id="minContractRate"
                  value={props.profileInfo.minContractRate}
                  //onChange={handleChange}
                  onChange={(e) => {
                    if (e.target.value === "0") {
                      toast.error("Cannot Be Zero");
                    } else if (+e.target.value < 0) {
                      toast.error("Negative numbers are not allowed");
                    } else {
                      handleChange(e);
                    }
                  }}
                />
                <label>Minimum Contract Rate</label>
              </div>
              <small className="validation">
                {props.profileInfoValidation.minContractRate}
              </small>
            </div>
            <div class="col-lg-4">
              <div class="form-floating">
                <select
                  //disabled={!props.profileInfo.minContractRate > 0}
                  class="form-select"
                  id="minContractRateCurrency"
                  style={{
                    border:
                      props.profileInfoValidation.minContractRateCurrency &&
                      "2px solid #ff0000",
                  }}
                  value={props.profileInfo.minContractRateCurrency}
                  onChange={handleChange}
                >
                  <option value="" selected disabled>
                    Select Type
                  </option>
                  {dayArr
                    .sort((a, b) => (a.SourceName > b.SourceName ? 1 : -1))
                    .map((item) => (
                      <option value={item.SourceName}>{item.SourceName}</option>
                    ))}
                </select>
                <label>
                  Currency Type{props.profileInfo.minContractRate > 0 && "*"}
                </label>
              </div>
              <small className="validation">
                {props.profileInfoValidation.minContractRateCurrency}
              </small>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-6 mt-2">
              <div
                class="form-floating ml-auto"
                style={{ margin: "15px 0px 0px 50px" }}
              >
                <div className="form-check form-switch d-flex align-items-center ">
                  <label
                    className="form-check-label pt-0"
                    htmlFor="remoteSwitch"
                  >
                    Is Fresher?
                  </label>
                  <input
                    className="form-check-input ml-3"
                    type="checkbox"
                    id="isFresher"
                    value={props.profileInfo.isFresher}
                    onChange={(e) => onIsfresherSwitch(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-12">
            <div class="col-lg-6">
              <label>Experience*</label>
            </div>
            <div class="col-lg-6">
              <label>Current Job Title* </label>
            </div>
          </div>
          <div class="row mb-12">
            <div class="col-lg-6">
              <div class="form-floating">
                <div className="d-flex">
                  <div className="mr-3 form-floating">
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      max="100"
                      style={{
                        height: "45px",
                        // border: "1px solid #ced4da",
                        fontSize: "15px",
                        border:
                          props.isFormValid &&
                          (props.profileInfo.experienceYear == null ||
                            props.profileInfo.experienceYear == 0)
                            ? "2px solid #ff0000"
                            : "1px solid #ced4da",
                      }}
                      name="years"
                      value={props.profileInfo.experienceYear}
                      onChange={(e) => {
                        if (Number(e.target.value) < 0) return;
                        // if (e.target.value === "0") {
                        //   toast.error("Cannot Be Zero");
                        // } else if (+e.target.value < 0) {
                        //   toast.error("Negative numbers are not allowed");
                        // }
                        else {
                          handleChangeExperienceYear(e);
                        }
                      }}
                      placeholder="Year"
                      disabled={props.profileInfo.isFresher}
                    />
                    <label>Years</label>
                    {props.isFormValid &&
                      props.profileInfo.experienceYear == null && (
                        <small className="validation">*Required Field</small>
                      )}
                    {console.log(
                      "props.profileInfo.experienceYear",
                      props.profileInfo.experienceYear
                    )}
                    {props.isFormValid &&
                      props.profileInfo.experienceYear == 0 && (
                        <small className="validation">*Required Field</small>
                      )}
                    {/* {props.profileInfo.isFresher === false && (
                      <small className="validation">
                        {props.profileInfoValidation.experienceYear}
                      </small>
                    )} */}
                  </div>

                  <div className="mr-3 form-floating">
                    <input
                      type="number"
                      className="form-control"
                      min="0"
                      style={{
                        height: "45px",
                        border: "1px solid #ced4da",
                        fontSize: "15px",
                      }}
                      name="months"
                      value={props.profileInfo.experienceMonth}
                      onChange={(e) => {
                        if (
                          Number(e.target.value) > 12 ||
                          Number(e.target.value) < 0
                        )
                          return;
                        // if (+e.target.value < 0) {
                        //   toast.error("Negative numbers are not allowed");
                        // }
                        else {
                          handleChangeExperienceMonth(e);
                        }
                      }}
                      placeholder="Months"
                      disabled={props.profileInfo.isFresher}
                    />
                    <label>Months</label>
                    {props.profileInfo.isFresher === false && (
                      <small className="validation">
                        {props.profileInfoValidation.experienceMonth}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="form-floating ">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Job Title"
                  id="jobtitle"
                  disabled={props.profileInfo.isFresher}
                  value={props.profileInfo.currentJobTitle}
                  onChange={(e) =>
                    props.setProfileInfo({
                      ...props.profileInfo,
                      currentJobTitle: e.target.value,
                    })
                  }
                />
                <label>Job Title</label>
              </div>
              {props.profileInfo.isFresher === false && (
                <small className="validation">
                  {props.profileInfoValidation.currentJobTitle}
                </small>
              )}
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-12">
              <div class="mb-2">
                <label>Position Types </label>
              </div>
              <Select
                isMulti
                value={positionTypes}
                onChange={handlePositiontype}
                options={positionTypeOption}
                styles={customStyles}
                isSearchable={false}
              />
            </div>
          </div>
          <div class="row mb-12 mt-2">
            <div class="col-12">
              <div class="mb-2 d-flex">
                <label>Preferred Locations* </label>
                <div
                  class="form-floating ml-auto"
                  style={{ margin: "15px 0px 0px 50px" }}
                >
                  <div className="form-check form-switch d-flex align-items-center">
                    <label
                      className="form-check-label pt-0"
                      htmlFor="remoteSwitch"
                    >
                      Open For Remote
                    </label>
                    <input
                      className="form-check-input ml-3"
                      type="checkbox"
                      id="isFresher"
                      checked={props.profileInfo.isRemote}
                      onChange={(e) =>
                        props.setProfileInfo({
                          ...props.profileInfo,
                          isRemote: e.target.checked,
                        })
                      }
                    />
                  </div>
                </div>
              </div>
              <div>
                <div class="form-inline-edit">
                  <div>
                    <div>
                      <div className="m-2">
                        {preferredLocation.map((item, index) => (
                          <>
                            <span className="tag tag-blue3 mb-1 mr-1">
                              {item}
                              <button
                                className="btn btn-text"
                                onClick={() => deleteLocation(index)}
                              >
                                <strong className="font-18 font-black">
                                  &times;
                                </strong>
                              </button>
                            </span>
                          </>
                        ))}
                      </div>
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <div className="form-floating w-100">
                          <PreferredLocation
                            setLocation={(loc) => {
                              if (preferredLocation.includes(loc)) {
                                toast.error(
                                  "You Have Already Added This Location"
                                );
                              } else {
                                setPreferredLocation((cur) => [...cur, loc]);
                                props.changePreferredLocation([
                                  ...preferredLocation,
                                  loc,
                                ]);
                              }
                            }}
                          />
                        </div>
                        <div className="ml-auto mr-1"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <small className="validation">
                {props.profileInfoValidation.preferredLocation}
              </small>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="mb-2">
                <label>Primary Skills* </label>
              </div>
              <TagsInput
                renderInput={autocompleteRenderInput}
                value={
                  props.profileInfo.primarySkills === (null || undefined)
                    ? []
                    : props.profileInfo.primarySkills
                }
                onChange={handleChangePrimarySkills}
                inputValue={primarySkillinput}
                onChangeInput={handleChangeInputPrimarySkills}
                placeholder="Type a location and hit enter"
                inputProps={{
                  placeholder: "Add a skill",
                }}
              />
            </div>
            <small className="validation">
              {props.profileInfoValidation.primarySkills}
            </small>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="mb-2">
                <label>Secondary Skills </label>
              </div>
              <TagsInput
                renderInput={autocompleteRenderInput}
                value={
                  props.profileInfo.secondarySkills === (null || undefined)
                    ? []
                    : props.profileInfo.secondarySkills
                }
                onChange={handleChangeSecondarySkills}
                inputValue={secondarySkillsInput}
                onChangeInput={handleChangeInputSecondarySkills}
                inputProps={{
                  placeholder: "Add a skill",
                }}
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="mb-2">
                <label>Industry </label>
                <button
                  onClick={() =>
                    console.log(dropDownData.skillSet.map((i) => i.SourceName))
                  }
                  className="btn btn-text"
                ></button>
              </div>
              <Select
                isMulti
                value={skillSet}
                options={skillSetOption}
                onChange={handleSkillSet}
                styles={customStyles}
                isSearchable={true}
              />
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <div class="mb-2">
                <label>Description</label>
                <div class="form-floating">
                  <textarea
                    class="form-control"
                    placeholder="Description"
                    id="description"
                    value={props.profileInfo.description}
                    onChange={handleChange}
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="p-5 p-0-600">
                <h6 class="mt-2">Upload Resumes</h6>
                <p>Accepted File Type: .docx, .pdf</p>
                <div class="col-sm-12">
                  <Dropzone onDrop={onDragDropChangeHandler}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()}>
                          <div class="talentuploadmaindiv">
                            <div class="talentuploadicon">
                              <label>
                                <i class="fas fa-cloud-upload-alt"></i>
                              </label>
                              <input
                                type="file"
                                name="myfile"
                                {...getInputProps()}
                              />
                            </div>
                            {props.profileInfo.fileName ? (
                              <div class="talentuploadtext">
                                {props.profileInfo.fileName}
                              </div>
                            ) : (
                              <div class="talentuploadtext">
                                Click or Drag and Drop to Upload
                              </div>
                            )}
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                  {props.profileInfo.fileName && (
                    <div class="modal-footer mt-4 mb-0">
                      <button
                        type="button"
                        className="btn btn-secondary mr-2"
                        onClick={onRemove}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileInfo;
