import React, { useState } from "react";
import LearningFullView from "./LearningFullView";
import Modal from "react-bootstrap/Modal";
import moment from 'moment'

const LearningTableView = (props) => {

  const [ learningDetails, setLearningDetails ] = useState([])
  const [ openLearningDetailsModal, setOpenLearningDetailsModal ] = useState(false)

  const openLearningDetails = (module) => {
    setLearningDetails(module)
    setOpenLearningDetailsModal(true)
  }

  const closeLearningModal = () => {
    setOpenLearningDetailsModal(false)
  }

  return (
    <>
      <div className="row d-block-force">
        <div className="col">
          <table className="table table-sortable table-hoverable">
            <thead>
              <tr>
                <th className="no-hover pt-0">
                  <div className="d-flex align-items-center">Title</div>
                </th>                
                <th className="no-hover pt-0">
                  <div className="d-flex align-items-center">Last Updated</div>
                </th>
                <th>
                  <div className="d-flex align-items-center">Progress</div>
                </th>
              </tr>
            </thead>
            {
              props.learningList.length === 0 ? <p className='m-2'>No Learning Modules at this time</p> :
              props.learningList.map((module) => (
              <tbody>
                {/* <button                 
                  onClick={(e) => {
                    e.preventDefault()
                    openAssessmentDetails(module)
                  }}
                  className=""
                > */}
                <tr id='details'>
                  <td className="p-relative">   
                    <strong>               
                      {module.CourseName}                   
                    </strong>
                  </td>                 
                  <td style={{ width: "150px" }}>
                    <div>                     
                      <span>{ module.LastActivityDate ? moment(module.LastActivityDate, "YYYYMMDD").fromNow() : "Not Yet Started" }</span>                                          
                    </div>
                  </td>
                  <td style={{ width: "200px" }}>
                    <div>
                      { module.ProgressStatus === "In Progress" ? module.PercentComplete === 0 ? 
                      (
                        <div>
                          <small>Not Started</small>
                          <div className="alert-progress alert-progress-static bg-gray3">
                            <div className="alert-progress-bar bg-green1"></div>
                          </div>
                        </div>
                      )
                    : (
                        <div>
                            <small>{module.PercentComplete+'%'} Completed</small>
                            <div className="alert-progress alert-progress-static bg-gray3">
                                <div
                                style={{ transform: "translateX(-80%)" }}
                                className="alert-progress-bar"
                                >
                                    
                                </div>
                            </div>
                        </div>
                    ) : 
                        <div>
                          <small>100% Completed</small>
                          <div 
                            style={{ position: "revert" }}
                            className="alert-progress alert-progress-static bg-green1"></div>
                        </div>
                    }                                           
                    </div>
                  </td>
                </tr>
                {/* </button> */}
              </tbody>
            ))}
          </table>
        </div>
      </div>
      <Modal
        size="lg"
        show={openLearningDetailsModal}
      >
        <LearningFullView
          learningDetails={learningDetails}
          closeModal={closeLearningModal}
        />
      </Modal>
    </>
  )
}

export default LearningTableView
