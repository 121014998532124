import React, { useEffect, useState } from "react";
import moment from "moment";
import { IoLocationOutline } from "react-icons/io5";
import { SidebarLogo } from "../../../../../constants/Constants";
import ProfileImage from "../../../commoncomponents/profileimage/ProfileImage";

const ListView = ({
  pool,
  setCurrentPage,
  currentPage,
  pageSize,
  setPageSize,
}) => {
  const [paginatedData, setPaginatedData] = useState([]);

  useEffect(() => {
    setPaginatedData(pool);
  }, [currentPage, pageSize, pool]);

  useEffect(() => {
    setPageSize(10);
  }, []);

  return (
    <>
      <div className="row d-flex pl-1">
        {paginatedData &&
          paginatedData?.map((item, index) => {
            return (
              <>
                <div className="col-12 mb-4" key={index}>
                  <div className="card card-tag-bottom">
                    <div className="card-body p-3 row w-100 d-flex justify-content-between">
                      <div className="col-md-1">
                        <img
                          src={item.logourl || SidebarLogo}
                          width="75"
                          height="75"
                          className="p-2"
                          style={{
                            borderRadius: "50%",
                            objectFit: "contain",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                          }}
                        />
                      </div>
                      <div className="col-md-11 d-flex align-items-center">
                        <div className="row w-100">
                          <div className="col-md-12 d-flex align-items-center justify-content-between mb-3">
                            <div className="text-left">
                              <p>
                                <a
                                  className="text-black"
                                  href={`/high5hire/talentcommunity/pools/${item.poolId}`}
                                >
                                  <strong title="Community Name">
                                    {item?.poolname}
                                  </strong>{" "}
                                </a>
                                <br />
                                <span style={{ cursor: "default" }}>
                                  <IoLocationOutline
                                    color="#009eff"
                                    size={18}
                                    className="mr-1"
                                  />
                                  <small>{item?.country}</small>
                                </span>
                              </p>
                              <div>
                                {item?.CountDetails?.totalCount == 0 ? (
                                  <span className="">
                                    <small>No talent pooled</small>
                                  </span>
                                ) : (
                                  <span
                                    className=""
                                    style={{ cursor: "default" }}
                                  >
                                    <div class="font-small font-primary mb-2">
                                      Talent(s)
                                    </div>
                                    <small title="Talent(s)">
                                      {item?.candidateName
                                        ?.slice(0, 3)
                                        ?.map((obj, i) => (
                                          <div
                                            key={i}
                                            className="avatar-name-candidatestableview d-inline-flex align-items-center"
                                          >
                                            <span
                                              style={{
                                                display: "inline-block",
                                              }}
                                            >
                                              <ProfileImage
                                                imageSrc={obj.profileUrl}
                                                imageWidth={"35"}
                                                imageHeight={"35"}
                                                avatarSize={"35"}
                                                avatarRound={"80px"}
                                                firstName={
                                                  obj.name.split(" ")[0]
                                                }
                                                lastName={
                                                  obj?.name.split(" ")[1]
                                                }
                                              />
                                            </span>
                                          </div>
                                        ))}
                                      {item?.CountDetails?.totalCount > 3 && (
                                        <span className="ml-1">
                                          +
                                          {new Intl.NumberFormat().format(
                                            item?.CountDetails?.totalCount - 3
                                          )}
                                        </span>
                                      )}
                                    </small>
                                  </span>
                                )}
                              </div>
                            </div>
                            <div
                              className="d-flex flex-column  gap-4 justify-content-end"
                              style={{ cursor: "default" }}
                            >
                              <div className="d-flex flex-column gap-2">
                                <span>
                                  Invited:{" "}
                                  <strong>
                                    {new Intl.NumberFormat().format(
                                      item?.CountDetails?.InvitedCount
                                    )}
                                  </strong>
                                </span>
                                <span>
                                  Automatched:{" "}
                                  <strong>
                                    {new Intl.NumberFormat().format(
                                      item?.CountDetails?.AutomatchCount
                                    )}
                                  </strong>
                                </span>
                                <span>
                                  Engaged:{" "}
                                  <strong>
                                    {new Intl.NumberFormat().format(
                                      item?.CountDetails?.EngagedCount
                                    )}
                                  </strong>
                                </span>
                              </div>
                              {/* <CustomProgressBar item={item} /> */}
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr className="" />
                      <div
                        className="d-flex justify-content-between"
                        style={{ cursor: "default" }}
                      >
                        <div className="text-muted my-auto p-0">
                          <span>Created by: {item?.poolowner}</span>
                        </div>

                        <div className="text-muted my-auto p-0">
                          Created on:{" "}
                          <span>
                            {moment(item.createddatetime).format("MM/DD/YYYY")}
                          </span>
                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </>
  );
};

export default ListView;
