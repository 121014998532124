import React, { useState } from "react";
import Preferred from "./Preferred";
import PreferredCandidate from "./PreferredCandidate";
import TermOverride from "./TermOverride";
import PrivacySettings from "./PrivacySettings";
// import RecruiterTermOverride from "../Preferences/RecruiterTermOverrides";
// import AddRecruiterTier from "../Preferences/RecruiterAddTier";
import { getUserRole } from "../../../service/AuthService";

function Preferences({ data, loading, setLoading, dropdownList, recData }) {
  return (
    <>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-8">
            {getUserRole() === "Candidate" ? (
              <>
                <PreferredCandidate data={data} />
                <PrivacySettings value={data.Privacy} />
              </>
            ) : (
              <Preferred
                dropdownList={dropdownList}
                loading={loading}
                setLoading={setLoading}
                recData={recData}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
export default Preferences;
