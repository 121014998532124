import {
  GET_TALENT_SUMMARY_BEGIN,
  GET_TALENT_SUMMARY_SUCCESS,
  GET_TALENT_SUMMARY_FAILURE,
  GET_TALENT_SUMMARY_COUNT_BEGIN,
  GET_TALENT_SUMMARY_COUNT_SUCCESS,
  GET_TALENT_SUMMARY_COUNT_FAILURE,
} from "../../actions/dashboard/TalentSummary";

const initialState = {
  loading: true,
  countLoading: true,
  talentData: [],
  talentSummaryCount: [],
};

const getTalentSummaryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TALENT_SUMMARY_BEGIN:
      return {
        ...state,
        loading: true,
        talentData: [],
      };
    case GET_TALENT_SUMMARY_SUCCESS:
      return {
        ...state,
        talentData: payload,
        loading: false,
      };
    case GET_TALENT_SUMMARY_FAILURE:
      return {
        ...state,
        talentData: [],
        loading: false,
      };
    case GET_TALENT_SUMMARY_COUNT_BEGIN:
      return {
        ...state,
        countLoading: true,
        talentSummaryCount: [],
      };
    case GET_TALENT_SUMMARY_COUNT_SUCCESS:
      return {
        ...state,
        talentSummaryCount: payload,
        countLoading: false,
      };
    case GET_TALENT_SUMMARY_COUNT_FAILURE:
      return {
        ...state,
        talentSummaryCount: [],
        countLoading: false,
      };
    default:
      return state;
  }
};

export default getTalentSummaryReducer;
