import {
    GET_JOB_SUMMARY_BEGIN,
    GET_JOB_SUMMARY_SUCCESS,
    GET_JOB_SUMMARY_FAILURE
  } from "../../actions/executiveadmin/JobSummary";
  
  const initialState = {
    jobSummaryLoading: true,
    jobSummary: [],
  };
  
  const jobSummaryListReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_JOB_SUMMARY_BEGIN:
        return {
          ...state,
          jobSummaryLoading: true,
          jobSummary: [],
        };
      case GET_JOB_SUMMARY_SUCCESS:
        return {
          ...state,
          jobSummary: payload,
          jobSummaryLoading: false,
        };
      case GET_JOB_SUMMARY_FAILURE:
        return {
          ...state,
          jobSummary: [],
          jobSummaryLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default jobSummaryListReducer;
  