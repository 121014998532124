import { formHelperTextClasses } from "@mui/material";
import { React, useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import { Modal, Button } from "react-bootstrap";

const TemplateSidePanel = (props) => {
  let details = props.details;
  console.log("details------------", details);
  const [generalDetails, setGeneralDetails] = useState(false);
  const [description, setDescription] = useState(false);
  const [primarySkills, setPrimarySkills] = useState(false);
  const [secondarySkills, setSecondarySkills] = useState(false);
  const [education, setEducation] = useState(false);
  const [notes, setNotes] = useState(false);
  const [submittal, setSubmittal] = useState(false);
  const [skillSet, setSkillSet] = useState(false);
  const [workHours, setWorkHours] = useState(false);
  const [travel, setTravel] = useState(false);
  const [drugTest, setDrugTest] = useState(false);
  const [backgroundCheck, setBackgroundCheck] = useState(false);
  const [securityClearance, setSecurityClearance] = useState(false);
  const [additionalInfo, setAdditionalInfo] = useState(false);
  const [qAndA, setQandA] = useState(false);

  const activateOrDeactivate = async (status) => {
    props.setLoading(true);
    try {
      const res = await post("/job/deactivateTemplate", {
        status,
        templateID: details?.templateID,
      });
      if (res.status === 200) {
        let value = status === "Active" ? "Activated" : "Deactivated";
        props.closePanel();
        toast.success(`Template ${value} Successfully`);
        setTimeout(() => {
          props.fetchTemplates();
        }, 5000);
      }
    } catch (err) {
      console.log(err);
      toast.error("Some Error Occurred");
    }
    props.setLoading(false);
  };

  return (
    <>
      {details && (
        <>
          <div class="view-panel show">
            <div class="view-panel-header">
              <div class="px-3 py-2">
                <button
                  type="button"
                  class="btn btn-icon"
                  onClick={props.closePanel}
                >
                  <i aria-hidden="true" class="fal fa-times"></i>
                </button>
              </div>
              <div class="px-3 mb-3">
                <div class="d-flex align-items-start flex-wrap-reverse">
                  <div class="d-flex">
                    <div class="ml-3">
                      <h5 class="mr-2">{details?.templateName}</h5>
                      <div class="d-flex">
                        <small class="mr-2">
                          Job Title: <strong>{details?.title}</strong>
                        </small>
                      </div>
                      <div class="d-flex">
                        <small class="mr-2">
                          Job Type: <strong>{details?.type}</strong>
                        </small>
                      </div>
                      {details?.category && (
                        <div class="mb-2 d-flex">
                          <small class="mr-2">
                            Template Category:{" "}
                            <strong>{details?.category}</strong>
                          </small>
                        </div>
                      )}
                      <div>
                        {details?.status === "Active" ? (
                          <span class="tag tag-green1">Active</span>
                        ) : (
                          <span class="tag tag-red1">Deactivate</span>
                        )}
                      </div>
                    </div>
                  </div>
                  {details?.createdBy == 1 && (
                    <div className="ml-auto">
                      <div className="d-flex">
                        {details?.status === "Active" && (
                          <button
                            className="btn btn-sm btn-secondary mr-2"
                            onClick={() => {
                              props.closePanel();
                              props.setEditTemplate(true);
                            }}
                          >
                            Edit
                          </button>
                        )}
                        {details?.status === "Active" ? (
                          <button
                            className="btn btn-sm btn-danger"
                            onClick={() => activateOrDeactivate("Deactive")}
                          >
                            Deactivate
                          </button>
                        ) : (
                          <button
                            className="btn btn-sm btn-primary"
                            onClick={() => activateOrDeactivate("Active")}
                          >
                            Activate
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div class="view-panel-body">
              <>
                {(details?.description || details?.maximumPay) && (
                  <button
                    onClick={() => setGeneralDetails(!generalDetails)}
                    type="button"
                    className="btn btn-collapsible mb-2"
                  >
                    {!generalDetails ? (
                      <i className="fal fa-angle-up fa-fw mt-1"></i>
                    ) : (
                      <i className="fal fa-angle-down fa-fw"></i>
                    )}
                    <span>
                      <small>General</small>
                    </span>
                  </button>
                )}
                <div
                  className={
                    generalDetails ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"
                  }
                >
                  {false && (
                    <div className="d-flex mb-2 pl-1 ml-2">
                      <div>
                        <i class="fad fa-fw fa-angles-right"></i>
                      </div>
                      <div className="ml-2">
                        <p className=" mb-0">{details?.type || "Full Time"}</p>
                        <p className="mb-0">
                          <small>Job Type</small>
                        </p>
                      </div>
                    </div>
                  )}
                  {details?.minimumPay && (
                    <div class="d-flex mb-2 pl-1 ml-2">
                      <div>
                        <i
                          class="fad fa-fw fa-piggy-bank"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="ml-2">
                        <p class=" mb-0">
                          {details?.minimumPay +
                            " " +
                            details?.minMaxCurrency +
                            (details?.minMaxPaymentType === "Annual"
                              ? "/Year"
                              : "/Hour")}
                        </p>
                        <p class="mb-0">
                          <small>
                            {details?.type === "Full Time"
                              ? "Annual Salary"
                              : "Hourly Salary"}
                          </small>
                        </p>
                      </div>
                    </div>
                  )}
                  {details?.maximumPay && (
                    <div class="d-flex mb-2 pl-1 ml-2">
                      <div>
                        <i
                          class="fad fa-fw fa-piggy-bank"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="ml-2">
                        <p class="mb-0">
                          {details?.maximumPay +
                            " " +
                            details?.minMaxCurrency +
                            (details?.minMaxPaymentType === "Annual"
                              ? "/Year"
                              : "/Hour")}
                        </p>
                        <p class="mb-0">
                          <small>
                            {details?.type === "Full Time"
                              ? "Annual Salary"
                              : "Hourly Salary"}
                          </small>
                        </p>
                      </div>
                    </div>
                  )}
                  {
                    <div class="d-flex mb-2 pl-1 ml-2">
                      <div>
                        <i
                          class="fad fa-plane-departure"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="ml-2">
                        <p class="mb-0">
                            {details?.visaStatus &&
                              JSON.parse(details?.visaStatus)?.map((skill) => (
                                <span className="tag tag-blue3 mr-1">
                                  {skill}
                                </span>
                              ))}
                          
                        </p>
                        <p class="mb-0">
                          <small>
                          Work Authorization Requirement
                          </small>
                        </p>
                      </div>
                    </div>
                  }
                </div>
                {details?.description && (
                  <button
                    onClick={() => setDescription(!description)}
                    type="button"
                    className="btn btn-collapsible mb-2"
                  >
                    {!description ? (
                      <i className="fal fa-angle-up fa-fw mt-1"></i>
                    ) : (
                      <i className="fal fa-angle-down fa-fw"></i>
                    )}
                    <span>
                      <small>
                        Description
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            setDescription(!description);
                          }}
                        >
                          &nbsp;
                          <i className="fas fa-external-link-square"></i>
                        </a>
                      </small>
                    </span>
                  </button>
                )}
                <div className={false ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
                  <div class="d-flex mb-2 pl-1 ml-4">
                    <div
                      dangerouslySetInnerHTML={{ __html: details?.description }}
                    ></div>
                  </div>
                </div>
                {details?.primarySkills &&
                  JSON.parse(details?.primarySkills).length > 0 && (
                    <button
                      onClick={() => setPrimarySkills(!primarySkills)}
                      type="button"
                      className="btn btn-collapsible mb-2"
                    >
                      {!primarySkills ? (
                        <i className="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i className="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Required Skills</small>
                      </span>
                    </button>
                  )}
                <div
                  className={
                    primarySkills ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"
                  }
                >
                  <div class="d-flex mb-2 pl-1 ml-4">
                    {details?.primarySkills &&
                      JSON.parse(details?.primarySkills)?.map((skill) => (
                        <span className="tag tag-blue3 mr-1">{skill}</span>
                      ))}
                  </div>
                </div>
                {(details?.secondarySkills).length > 0 && (
                  <button
                    onClick={() => {
                      setSecondarySkills(!secondarySkills);
                    }}
                    type="button"
                    className="btn btn-collapsible mb-2"
                  >
                    {!secondarySkills ? (
                      <i className="fal fa-angle-up fa-fw mt-1"></i>
                    ) : (
                      <i className="fal fa-angle-down fa-fw"></i>
                    )}
                    <span>
                      <small>Secondary Skills</small>
                    </span>
                  </button>
                )}
                <div
                  className={
                    secondarySkills ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"
                  }
                >
                  <div class="d-flex mb-2 pl-1 ml-4">
                    {(details?.secondarySkills).map((skill) => (
                      <span className="tag tag-blue3 mr-1">{skill}</span>
                    ))}
                  </div>
                </div>
                {details?.skillSet && JSON.parse(details?.skillSet).length > 0 && (
                  <button
                    onClick={() => setSkillSet(!skillSet)}
                    type="button"
                    className="btn btn-collapsible mb-2"
                  >
                    {!skillSet ? (
                      <i className="fal fa-angle-up fa-fw mt-1"></i>
                    ) : (
                      <i className="fal fa-angle-down fa-fw"></i>
                    )}
                    <span>
                      <small>Industry</small>
                    </span>
                  </button>
                )}
                <div
                  className={skillSet ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}
                >
                  <div class="d-flex mb-2 pl-1 ml-4">
                    {details?.skillSet &&
                      JSON.parse(details?.skillSet).length > 0 &&
                      JSON.parse(details?.skillSet).map((skill) => (
                        <span className="tag tag-blue3 mr-1">{skill}</span>
                      ))}
                  </div>
                </div>
                {details?.education &&
                  JSON.parse(details?.education).length > 0 && (
                    <button
                      onClick={() => setEducation(!education)}
                      type="button"
                      className="btn btn-collapsible mb-2"
                    >
                      {!education ? (
                        <i className="fal fa-angle-up fa-fw mt-1"></i>
                      ) : (
                        <i className="fal fa-angle-down fa-fw"></i>
                      )}
                      <span>
                        <small>Education</small>
                      </span>
                    </button>
                  )}
                <div
                  className={education ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}
                >
                  <div class="d-flex mb-2 pl-1 ml-4">
                    {details?.education &&
                      JSON.parse(details?.education).map((e) => (
                        <span className="tag tag-blue3 mr-1">{e}</span>
                      ))}
                  </div>
                </div>
                {details?.notes && JSON.parse(details?.notes).length > 0 && (
                  <button
                    onClick={() => setNotes(!notes)}
                    type="button"
                    className="btn btn-collapsible mb-2"
                  >
                    {!notes ? (
                      <i className="fal fa-angle-up fa-fw mt-1"></i>
                    ) : (
                      <i className="fal fa-angle-down fa-fw"></i>
                    )}
                    <span>
                      <small>Notes</small>
                    </span>
                  </button>
                )}
                <div className={notes ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
                  <div class="d-flex mb-2 pl-1 ml-4">
                    {details?.notes &&
                      JSON.parse(details?.notes).map((e) => (
                        <span className="tag tag-blue3 mr-1">{e.comments}</span>
                      ))}
                  </div>
                </div>
                {details?.workStart && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() => setWorkHours(!workHours)}
                    >
                      {!workHours ? (
                        <i className="fal fa-angle-up fa-fw" />
                      ) : (
                        <i className="fal fa-angle-down fa-fw" />
                      )}
                      <span>
                        <small>Work Hours</small>
                      </span>
                    </button>
                  </div>
                )}
                {workHours && (
                  <div className="mb-3 ml-4">
                    <p>
                      {details?.workStart} to {details?.workEnd}
                    </p>
                  </div>
                )}
                {details?.travel && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() => setTravel(!travel)}
                    >
                      {!travel ? (
                        <i className="fal fa-angle-up fa-fw" />
                      ) : (
                        <i className="fal fa-angle-down fa-fw" />
                      )}
                      <span>
                        <small>Travel</small>
                      </span>
                    </button>
                  </div>
                )}
                {travel && (
                  <div className="mb-3 ml-4">
                    <p>{details?.travel}</p>
                  </div>
                )}

                {/* <div className="mb-2">
                  <button
                    type="button"
                    className="btn btn-collapsible"
                    onClick={() => setDrugTest(!drugTest)}
                  >
                    {!drugTest ? (
                      <i className="fal fa-angle-up fa-fw" />
                    ) : (
                      <i className="fal fa-angle-down fa-fw" />
                    )}
                    <span>
                      <small>Drug Test</small>
                    </span>
                  </button>
                </div> 
                 {drugTest && (
                  <div className="mb-3 ml-4">
                    <span>
                      {details?.drugTest ? (
                        <span className="tag tag-green2">Yes</span>
                      ) : (
                        <span className="tag tag-red1">No</span>
                      )}
                    </span>
                  </div>
                )} 
				 <div className="mb-2">
                  <button
                    type="button"
                    className="btn btn-collapsible"
                    onClick={() => setBackgroundCheck(!backgroundCheck)}
                  >
                    {!backgroundCheck ? (
                      <i className="fal fa-angle-up fa-fw" />
                    ) : (
                      <i className="fal fa-angle-down fa-fw" />
                    )}
                    <span>
                      <small>Background Check</small>
                    </span>
                  </button>
                </div>
                {backgroundCheck && (
                  <div className="mb-3 ml-4">
                    <span>
                      {details?.backgroundCheck ? (
                        <span className="tag tag-green2">Yes</span>
                      ) : (
                        <span className="tag tag-red1">No</span>
                      )}
                    </span>
                  </div>
                )} */}
                {details?.securityClearance && (
                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-collapsible"
                      onClick={() => setSecurityClearance(!securityClearance)}
                    >
                      {!securityClearance ? (
                        <i className="fal fa-angle-up fa-fw" />
                      ) : (
                        <i className="fal fa-angle-down fa-fw" />
                      )}
                      <span>
                        <small>Security Clearance Required</small>
                      </span>
                    </button>
                  </div>
                )}
                {securityClearance && (
                  <div className="mb-3 ml-4">
                    <p>{details?.securityClearance}</p>
                  </div>
                )}
                {details?.submittal && (
                  <button
                    onClick={() => setSubmittal(!submittal)}
                    type="button"
                    className="btn btn-collapsible mb-2"
                  >
                    {!submittal ? (
                      <i className="fal fa-angle-up fa-fw mt-1"></i>
                    ) : (
                      <i className="fal fa-angle-down fa-fw"></i>
                    )}
                    <span>
                      <small>
                        Submittal Instructions
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            setSubmittal(!submittal);
                          }}
                        >
                          &nbsp;
                          <i className="fas fa-external-link-square"></i>
                        </a>
                      </small>
                    </span>
                  </button>
                )}
                <div className={false ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
                  <div class="mb-2 pl-1 ml-4 mt-1">
                    <div className="form-floating mb-4">
                      <p>{details?.submittal}</p>
                    </div>
                  </div>
                </div>
                {details?.QandA && JSON.parse(details?.QandA).length > 0 && (
                  <button
                    onClick={() => setQandA(!qAndA)}
                    type="button"
                    className="btn btn-collapsible mb-2"
                  >
                    {!qAndA ? (
                      <i className="fal fa-angle-up fa-fw mt-1"></i>
                    ) : (
                      <i className="fal fa-angle-down fa-fw"></i>
                    )}
                    <span>
                      <small>
                        Screening Questions
                        <a
                          href="/"
                          onClick={(e) => {
                            e.preventDefault();
                            setQandA(!description);
                          }}
                        >
                          &nbsp;
                          <i className="fas fa-external-link-square"></i>
                        </a>
                      </small>
                    </span>
                  </button>
                )}
                <div className={false ? "ml-2 ml-0-600 mt-2 mb-4" : "d-none"}>
                  <div class="d-flex mb-2 pl-1 ml-4">
                    <ul>
                      {details?.QandA &&
                        JSON.parse(details?.QandA).map((item, index) => (
                          <li className="m-3">
                            <div className="mb-1">
                              <span className="avatar avatar-sm mr-1">
                                {index + 1}
                              </span>
                              <span>{item.question}</span>
                            </div>
                            <div className="ml-3 mb-2">
                              <p>
                                <span>
                                  {item.name === "Language"
                                    ? JSON.parse(item.input).join(", ")
                                    : item.input}
                                </span>
                              </p>
                            </div>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </>
            </div>
          </div>
        </>
      )}
      <Modal size="lg" show={description}>
        <Modal.Header>
          <h6 class="">Description</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setDescription(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p
            className="m-3"
            dangerouslySetInnerHTML={{ __html: details?.description }}
          ></p>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="secondary" onClick={() => setDescription(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={submittal}>
        <Modal.Header>
          <h6 class="">Submittal Instructions</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setSubmittal(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <p
            className="m-3"
            dangerouslySetInnerHTML={{ __html: details?.submittal }}
          ></p>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="secondary" onClick={() => setSubmittal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" show={qAndA}>
        <Modal.Header>
          <h6 class="">Screening Questions</h6>
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setQandA(false)}
            class="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div class="d-flex mb-2 pl-1 ml-4">
            <ul>
              {details?.QandA &&
                JSON.parse(details?.QandA).map((item, index) => (
                  <li className="d-flex m-3">
                    <div className="">
                      <span className="col-lg-1">{index + 1}</span>
                    </div>
                    <div className="ml-3 mb-2">
                      <span className="col-lg-11 mb-1">{item.question}</span>
                      <br />
                      <small>
                        {item.name === "Language" ||
                        item.name === "Industry Experience" ||
                        item.name === "Education"
                          ? "Response: "
                          : "Response Type:"}{" "}
                        {item.name === "Language" ? (
                          <strong>{JSON.parse(item.input).join(", ")}</strong>
                        ) : (
                          <strong>{item.input || "Yes/No"}</strong>
                        )}
                      </small>
                      <br />
                    </div>
                  </li>
                ))}
            </ul>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button variant="secondary" onClick={() => setQandA(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TemplateSidePanel;
