import React from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import {
  getUserTenantID,
  getCandidateId,
} from "../../../../../../../service/AuthService";
import { emailDomain } from "../../../../../../../constants/Constants";

const ShareJob = (props) => {
  const { jobData, share, setShare } = props;
  const tenantId = jobData.createdby;

  const userId = props.isFrom === "favJobs" ? "698131846" : getCandidateId();

  const link = `${emailDomain}/careers/${tenantId}/joblist/pooldescription/${jobData.poolId}/recruiter/${userId}`;

  const handleClickCopy = () => {
    navigator.clipboard.writeText(link);
    toast.success("Link copied to clipboard.");
  };

  return (
    <Modal show={share} onHide={() => setShare(false)} size="md">
      <div className="bl-modal-header">
        <h5 className="bl-modal-title">{"Share Community"}</h5>
        <div className="bl-modal-header">
          <button
            type="button"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShare(false)}
            className="close p-0 bl-modal-close-btn"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Modal.Body>
          <div
            className="mb-3"
            style={{ display: "flex", flexDirection: "column", gap: "15px" }}
          >
            <div className="share-item">
              <i aria-hidden="true" className="fal fa-link" />
              <h6>Public Share Link</h6>
              <i className="fas fa-copy" onClick={handleClickCopy} />
            </div>
            <div className="share-item">
              <i aria-hidden="true" className="fa fa-linkedin" />
              <h6>Share With LinkedIn</h6>
              <i className="fas fa-copy" onClick={handleClickCopy} />
            </div>
            <div className="share-item">
              <i aria-hidden="true" className="fa fa-facebook" />
              <h6>Share With Facebook</h6>
              <i className="fas fa-copy" onClick={handleClickCopy} />
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ShareJob;
