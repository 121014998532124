import {
  GET_SUBCATEGORY_BEGIN,
  GET_SUBCATEGORY_SUCCESS,
  GET_SUBCATEGORY_FAILURE,
} from "../../actions/common/GetSubcategoryDetails";

const initialState = {
  categoryLoading: true,
  subCategory: {},
};

const getSubcategoryReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_SUBCATEGORY_BEGIN:
      return {
        ...state,
        categoryLoading: true,
      };
    case GET_SUBCATEGORY_SUCCESS:
      return {
        ...state,
        subCategory: payload,
        categoryLoading: false,
      };
    case GET_SUBCATEGORY_FAILURE:
      return {
        ...state,
        categoryLoading: false,
      };
    default:
      return state;
  }
};

export default getSubcategoryReducer;
