import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import ReactToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import Sort from "../../../common/Sort";

const ReportHeader = ({
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  handleDateSearch,
  getPaginatedData,
  setSort,
  sort,
  filteredData,
  sortCount,
  orderList,
  sortObject,
  applySort,
  setSortCount,
  setSortArray,
  openFilter,
  onChangeSelect,
  sortArray,
  selectedTitles,
  selectedLocations,
  vetting,
  primarySkills,
  setFilter,
  selectedTypes,
  selectedPrimarySkills,
  selectedJobLocation,
  dateSearch,
  dataa,
}) => {
  return (
    <div>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="ml-2.5">Unsubscribed users</h5>
        </div>
        <div className="d-flex bd-highlight ml-2.5">
          {/* <div className="p-2 bd-highlight" style={{ width: "10em" }}>
            <label htmlFor="start-date">
              <h6 className="m-0">From</h6>
            </label>
            <DatePicker
              className="form-control"
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              onChangeRaw={(e) => e.preventDefault()}
              style={{}}
            />
          </div>
          <div className="p-2 bd-highlight" style={{ width: "10em" }}>
            <label htmlFor="end-date">
              <h6 className="m-0">To</h6>
            </label>
            <DatePicker
              className="form-control"
              selected={toDate}
              onChange={(date) => setToDate(date)}
              onChangeRaw={(e) => e.preventDefault()}
            />
          </div>
          <div className="p-2 align-self-end bd-highlight mt-10 ">
            <button
              className="btn btn-sm btn-primary"
              type="submit"
              onClick={() => handleDateSearch()}
            >
              Search
            </button>
          </div> */}

          <div className="py-2 align-self-end bd-highlight">
            {getPaginatedData().length > 0 && (
              <ReactToExcel
                className="btn btn-sm"
                table="table-to-xls"
                filename="GenericReportExcel"
                sheet="sheet 1"
                buttonText={
                  <span>
                    <i className="fas fa-file-download mr-2"></i>
                    Download
                  </span>
                }
              ></ReactToExcel>
            )}
          </div>

          {/* FILTER */}
          {/* <div class="py-2 align-self-end">
            <div class="dropdown position-relative">
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Filter"
                onClick={() => setFilter(true)}
                type="button"
                class="btn btn-sm btn-text-accent w-auto"
                disabled={filteredData.length === 0}
              >
                <i class="fas fa-filter"></i>
                <span>Filter</span> /
                <span>
                  {selectedTitles.length +
                    selectedTypes.length +
                    selectedPrimarySkills.length +
                    selectedJobLocation.length}
                </span>
              </button>
            </div>
          </div> */}

          {/* SORT */}
          {/* <div class="py-2 align-self-end">
            <div class="dropdown-sort dd-sort">
              <button
                type="button"
                onClick={() => setSort(!sort)}
                class="btn btn-sm w-auto btn-text-accent dd-sort"
                disabled={filteredData.length === 0}
              >
                <i
                  aria-hidden="true"
                  class="fad fa-sort-down font-16 dd-sort mr-1"
                ></i>
                Sort / <span class="dd-sort">{sortCount}</span>
              </button>
              {
                <OutsideClickHandler onOutsideClick={() => setSort(false)}>
                  {sort && (
                    <Sort
                      sortData={dataa.map((i) => ({
                        ...i,
                        jobLocation: i.jobLocation
                          ? i.jobLocation.trim()
                          : "Remote",
                      }))}
                      orderArray={orderList}
                      sortObjects={sortObject}
                      applySort={applySort}
                      setSortCount={setSortCount}
                      sortCount={sortCount}
                      setSortArray={setSortArray}
                      sortArray={sortArray}
                      sortingFor={
                        "Sort by Job Title, Job Type, Candidate Name and Job Location"
                      }
                    />
                  )}
                </OutsideClickHandler>
              }
            </div>
          </div> */}

          <div
            className="bd-highlight ml-auto mt-4"
            style={{ width: "15rem", marginLeft: "0.6rem" }}
          >
            <h6 className="mt-1">Records</h6>
            <select className="form-select" onChange={(e) => onChangeSelect(e)}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;
