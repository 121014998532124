import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { commonFunction } from "./CommonFunction";
import { get, post } from "../../../service/ApiService";
import DatePicker from "react-datepicker";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import moment from "moment";

const TalentPerformance = (props) => {
  const { setLoading } = props;
  const [tempList, setTempList] = useState([]);
  const [talentStatus, setTalentStatus] = useState({});
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 27))
  );
  const [toDate, setToDate] = useState(new Date());

  const getTalentData = async () => {
    setLoading(true);
    let params = {
      toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
      fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
    };
    try {
      let res = await post("/reports/candidate", params);
      if (res.status === 200) {
        setLoading(false);
        let array = [];
        let temp = commonFunction(res.data);
        for (const i of temp) {
          let finalObj = {
            invited: 0,
            submitted: 0,
            shortlisted: 0,
            automatched: 0,
            hired: 0,
            disqualified: 0,
            offered: 0,
          };
          i.value.map((item) => {
            if (item.statusName == "Invited") {
              finalObj.invited = finalObj.invited + 1;
            }
            if (item.statusName == "Submitted") {
              finalObj.submitted = finalObj.submitted + 1;
            }
            if (item.statusName == "Automatched") {
              finalObj.automatched = finalObj.automatched + 1;
            }
            if (item.statusName == "Shortlisted") {
              finalObj.shortlisted = finalObj.shortlisted + 1;
            }
            if (item.statusName == "Offered") {
              finalObj.offered = finalObj.offered + 1;
            }
            if (
              item.statusName == "Rejected" ||
              item.statusName == "Offer Rejected"
            ) {
              finalObj.disqualified = finalObj.disqualified + 1;
            }
            if (item.statusName == "Hired") {
              finalObj.hired = finalObj.hired + 1;
            }
          });
          finalObj.date = i.date;
          finalObj.key = i.key;
          array = [...array, JSON.stringify(finalObj)];
        }
        let arrayList = array.map((e) => JSON.parse(e));
        let arr = arrayList.map((e) => ({
          ...e,
          total:
            e.invited +
            e.automatched +
            e.offered +
            e.hired +
            e.submitted +
            e.shortlisted +
            e.disqualified,
        }));
        setTempList(
          arr.sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
        );

        if (arr.length > 1) {
          let data = [];
          if (arr.length == 2) {
            data = [...arr];
          } else if (arr.length == 3) {
            data = arr
              .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
              .slice(1, 3);
          } else {
            data = arr
              .sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
              .slice(2, 4);
          }

          setTalentStatus((prev) => ({
            ...prev,
            invited:
              data[0].invited == 0
                ? data[1].invited > 0
                  ? 100
                  : 0
                : Math.round(
                    ((data[1].invited - data[0].invited) * 100) /
                      data[0].invited
                  ),
            automatched:
              data[0].automatched == 0
                ? data[1].automatched > 0
                  ? 100
                  : 0
                : Math.round(
                    ((data[1].automatched - data[0].automatched) * 100) /
                      data[0].automatched
                  ),
            offered:
              data[0].offered == 0
                ? data[1].offered > 0
                  ? 100
                  : 0
                : Math.round(
                    ((data[1].offered - data[0].offered) * 100) /
                      data[0].offered
                  ),
            hired:
              data[0].hired == 0
                ? data[1].hired > 0
                  ? 100
                  : 0
                : Math.round(
                    ((data[1].hired - data[0].hired) * 100) / data[0].hired
                  ),
            submitted:
              data[0].submitted == 0
                ? data[1].submitted > 0
                  ? 100
                  : 0
                : Math.round(
                    ((data[1].submitted - data[0].submitted) * 100) /
                      data[0].submitted
                  ),
            shortlisted:
              data[0].shortlisted == 0
                ? data[1].shortlisted > 0
                  ? 100
                  : 0
                : Math.round(
                    ((data[1].shortlisted - data[0].shortlisted) * 100) /
                      data[0].shortlisted
                  ),
            disqualified:
              data[0].disqualified == 0
                ? data[1].disqualified > 0
                  ? 100
                  : 0
                : Math.round(
                    ((data[1].disqualified - data[0].disqualified) * 100) /
                      data[0].disqualified
                  ),
          }));
        } else {
          setTalentStatus((prev) => ({
            ...prev,
            invited: 0,
            automatched: 0,
            offered: 0,
            hired: 0,
            submitted: 0,
            shortlisted: 0,
            disqualified: 0,
          }));
        }
      }
    } catch (err) {
      setLoading(false);
      toast.error("Some Error Occurred while Fetching Gig Recruiters");
    }
  };
  useEffect(() => {
    getTalentData();
  }, [fromDate]);

  const handleDate = (event) => {
    var d = new Date(event);
    let toDate = new Date(event);
    d.setDate(1);
    toDate.setDate(1);
    while (d.getDay() !== 1) {
      d.setDate(d.getDate() + 1);
      toDate.setDate(toDate.getDate() + 1);
    }
    toDate.setDate(toDate.getDate() + 27);
    setToDate(toDate);
    setFromDate(d);
  };

  return (
    <div className="card card-flat card-borderless p-4 mb-3">
      <div>
        <div
          className="d-flex align-items-center d-block-600"
          style={{ justifyContent: "space-between" }}
        >
          <div style={{ display: "flex" }}>
            <h5 className="ml-2.5">Talent Report</h5>
            <span
              className="ml-2"
              style={{
                cursor: "pointer",
                padding: "0px 0px 5px 0px",
              }}
              data-bs-toggle="tooltip"
              data-bs-placement="left"
            >
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn-sm"
                table="talent-table"
                filename="TalentReport"
                sheet="tablexls"
                buttonText={
                  <span>
                    <i className="fas fa-file-download mr-2"></i>
                    Download
                  </span>
                }
              />
            </span>
          </div>
          <div
            className="p-2 bd-highlight"
            style={{ display: "flex", width: "175px" }}
          >
            <span style={{ marginTop: "14px", width: "100px" }}>
              {/* <i className="fas fa-filter"></i> */}
              Month:
            </span>
            <div>
              <DatePicker
                dateFormat="MMM yyyy"
                showMonthYearPicker
                selected={fromDate}
                onChange={(e) => handleDate(e)}
              />
              <span
                className="fa fa-search "
                style={{
                  float: "right",
                  marginRight: "9px",
                  marginTop: "-28px",
                  position: "relative",
                  zIndex: "200",
                }}
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div className="px-3 pt-0  mt-3">
        <table
          className="table table-sortable table-hoverable"
          id="talent-table"
        >
          <thead
            style={{
              backgroundColor: "burlywood",
              pointerEvents: "none",
            }}
          >
            <tr>
              <th className="no-hover">
                <div className="d-flex align-items-center">Title</div>
              </th>
              {tempList.map((item, i) => (
                <th className="no-hover" key={i}>
                  <div
                    className="d-flex align-items-center"
                    style={{
                      display: "flex!important",
                      justifyContent: "center",
                    }}
                  >
                    {item.date}
                  </div>
                </th>
              ))}{" "}
              {tempList.length > 0 && (
                <th className="no-hover">
                  <div
                    className="d-flex align-items-center"
                    style={{
                      display: "flex!important",
                      justifyContent: "center",
                    }}
                  >
                    Status
                  </div>
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="p-relative">Automatched</td>
              {tempList.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.automatched}
                </td>
              ))}
              {tempList.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(talentStatus.automatched) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(talentStatus.automatched) + "%"
                  )}{" "}
                  {talentStatus.automatched > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {talentStatus.automatched < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Invited</td>
              {tempList.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.invited}
                </td>
              ))}
              {tempList.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(talentStatus.invited) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(talentStatus.invited) + "%"
                  )}{" "}
                  {talentStatus.invited > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {talentStatus.invited < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Submitted</td>
              {tempList.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.submitted}
                </td>
              ))}
              {tempList.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(talentStatus.submitted) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(talentStatus.submitted) + "%"
                  )}{" "}
                  {talentStatus.submitted > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {talentStatus.submitted < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Shortlisted</td>
              {tempList.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.shortlisted}
                </td>
              ))}
              {tempList.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(talentStatus.shortlisted) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(talentStatus.shortlisted) + "%"
                  )}{" "}
                  {talentStatus.shortlisted > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {talentStatus.shortlisted < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Offered</td>
              {tempList.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.offered}
                </td>
              ))}
              {tempList.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(talentStatus.offered) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(talentStatus.offered) + "%"
                  )}{" "}
                  {talentStatus.offered > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {talentStatus.offered < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Hired</td>
              {tempList.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.hired}
                </td>
              ))}
              {tempList.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(talentStatus.hired) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(talentStatus.hired) + "%"
                  )}{" "}
                  {talentStatus.hired > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {talentStatus.hired < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr>
              <td className="p-relative">Disqualified</td>
              {tempList.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.disqualified}
                </td>
              ))}
              {tempList.length > 0 && (
                <td style={{ textAlign: "center" }}>
                  {Math.abs(talentStatus.disqualified) == 0 ? (
                    <small style={{ color: "red" }}>
                      No Change in last 2 weeks
                    </small>
                  ) : (
                    Math.abs(talentStatus.disqualified) + "%"
                  )}{" "}
                  {talentStatus.disqualified > 0 && (
                    <span style={{ color: "green", fontSize: "20px" }}>
                      &#8593;
                    </span>
                  )}{" "}
                  {talentStatus.disqualified < 0 && (
                    <span style={{ color: "red", fontSize: "20px" }}>
                      &#8595;
                    </span>
                  )}
                </td>
              )}
            </tr>
            <tr style={{ fontWeight: "bold" }}>
              <td className="p-relative">Grand Total</td>
              {tempList.map((item, i) => (
                <td style={{ textAlign: "center" }} key={i}>
                  {item.total}
                </td>
              ))}
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TalentPerformance;
