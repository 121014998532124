import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import {
  getCandidateId,
  getUserId,
  getUserName,
} from "../../../service/AuthService";
import { ButtonLoader, domain } from "../../../constants/Constants";
import ScreeningQuestions from "../sourcecandidate/ScreeningQuestions";

function ApplyPopUp(props) {
  let details = props.details;
  const [comments, setComments] = useState("");
  const [loading, setLoading] = useState(false);
  const [resume, setResume] = useState("");
  const [resumeURL, setResumeURL] = useState([]);
  const [QandA, setQandA] = useState([]);
  const [QandAValidation, setQandAValidation] = useState([]);

  const applyJob = async () => {
    let QnAVal = QandA.map((i) => "");
    QandA.forEach((item, index) => {
      if (
        ["Industry Experience", "Work Experience", "Custom Question"].includes(
          item.name
        )
      ) {
        if (item.isMandatory && !item.answer) {
          QnAVal[index] = "*Required Field";
        }
        if (item.name === "Work Experience") {
          let exp = QandA.find((item2) => item2.name === "Industry Experience")
            ?.answer;
          if (exp && +item.answer < +exp) {
            QnAVal[index] =
              "Total experience should be greater than Industry Experience";
          }
        }
      }
    });
    if (resume === "") {
      toast.error("Please Select Resume");
    } else if (QnAVal.some((i) => i)) {
      setQandAValidation(QnAVal);
      toast.error("Please Fill All the Mandatory Screening Questions");
    } else if (false) {
      toast.success("Validation Success");
    } else {
      setQandAValidation(QandA.map((i) => ""));
      setLoading(true);
      const res1 = await post("/CandidateJobApply/fetchRecruiterByTenant", {
        tenantName: "High5Hire",
        domainName: "https://uat.high5hire.com",
      });
      let finalResume = resumeURL.find((r) => r.fileName === resume);
      const {
        id,
        jobId,
        jobTitle,
        jobTenant,
        candidateId,
        firstName,
        lastName,
        location,
        hourlyRate,
      } = details;
      const parameters = {
        id,
        comments,
        jobId,
        jobTitle,
        jobTenant,
        candidateId: getCandidateId(),
        candidateFname: firstName,
        payRate: hourlyRate,
        payRateCurrency: "",
        candidateLname: lastName,
        candidateEmail: getUserName(),
        jobLocation: location.address,
        resumeURL: [
          { fileName: finalResume.fileName, resumeURL: finalResume.fileURL },
        ],
        recruiterData: res1.data,
        QandA,
        type: props.type,
      };
      const res = await post(
        "/CandidateJobApply/selfJobApplyByCandidate",
        parameters
      );
      if (res.status === 200) {
        props.applyJob();
        setTimeout(() => {
          window.location.reload();
        }, 5000);
        setLoading(false);
      } else {
        toast("Some Error Occurred. Please Try Again");
      }
    }
  };

  useEffect(() => {
    if (details.QandA) {
      let QnA = JSON.parse(details.QandA);
      let finalQA = QnA.map((item) => {
        if (
          ![
            "Industry Experience",
            "Work Experience",
            "Custom Question",
            "Language",
          ].includes(item.name)
        ) {
          return { ...item, answer: "Yes" };
        } else if (item.name === "Language") {
          return {
            ...item,
            answer: JSON.parse(item.input).map((i) => ({
              name: i,
              proficiency: "Beginner",
            })),
          };
        } else if (item.name === "Custom Question") {
          return { ...item, answer: item.input === "Yes/No" ? "Yes" : "" };
        } else {
          return item;
        }
      });
      setQandA(finalQA);
      setQandAValidation(finalQA.map((i) => ""));
    }
    setResumeURL(JSON.parse(localStorage.getItem("ResumeURL")));
  }, []);

  return (
    <>
      <div>
        <div class="text-center mb-3">
          <h6>{details.jobTitle}</h6>
          <p className="overflow-auto m-2">{details.jobType}</p>
        </div>
      </div>
      <div class="bl-modal-body pt-0 m-3">
        <div class="card card-flat card-lg">
          <div class="card-body">
            <div class="form-floating">
              <textarea
                onChange={(e) => setComments(e.target.value)}
                placeholder="Why Me?"
                class="form-control"
                style={{ height: "100px" }}
              ></textarea>
              <label>Why are you a good fit for this position?</label>
            </div>
          </div>
        </div>
        <div class="card card-flat card-lg mt-3">
          <div class="card-body">
            <h6>Resume</h6>
            <br />
            <div class="row mb-2">
              <div class="col-lg-12">
                <div class="form-floating">
                  <select
                    class="form-select"
                    name="graduatedYear"
                    // value={e.graduatedYear}
                    onChange={(event) => setResume(event.target.value)}
                  >
                    <option selected disabled>
                      Select
                    </option>
                    {resumeURL.length > 0 &&
                      resumeURL.map((resume) => (
                        <option>{resume.fileName}</option>
                      ))}
                  </select>
                  <label>Choose Resume*</label>
                </div>
              </div>
            </div>
            <div></div>
          </div>
        </div>
        {QandA.length > 0 && (
          <>
            <h6 className="mt-5">Screening Questions</h6>
            <ScreeningQuestions
              QandA={QandA}
              setQandA={setQandA}
              QandAValidation={QandAValidation}
            />
          </>
        )}
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary mr-3 closable"
          onClick={props.handleSuccess}
        >
          Close
        </button>
        <button
          type="button"
          class="btn btn-primary"
          onClick={applyJob}
          disabled={loading}
        >
          <span>
            {loading ? "Submitting" : "Submit Application"}
            {loading && <img width="20px" src={ButtonLoader} alt="loading" />}
          </span>
        </button>
      </div>
    </>
  );
}

export default ApplyPopUp;
