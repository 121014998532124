import React, { useEffect, useState } from "react";
import Nav from "react-bootstrap/Nav";
import Email from "./Email";
import SMS from "./smsBroadCast/SMS";
import { FaEnvelope, FaCommentAlt } from "react-icons/fa"; // Import the icons
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Row, Col } from "react-bootstrap";

const EmailComposer = (props) => {
  const location = useLocation();
  const data = location.state;
  const [currentTab, setCurrentTab] = useState("email");

  useEffect(() => {
    if (data?.contactData && data?.contactData?.length > 0) {
      setCurrentTab("sms");
    }
    if (data?.talentData && data?.talentData?.length > 0) {
      setCurrentTab("email");
    }
  }, []);

  const getAttributeHandler = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <Row>
      <Col className="d-flex col-sm-12 col-md-2">
        {data && data?.for ? (
          data?.for === "email" ? (
            <>
              <Nav
                className="flex-column bg-light p-3 w-100"
                // style={{ width: "100%" }}
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="email"
                    onSelect={() => getAttributeHandler("email")}
                    active={currentTab === "email"}
                    className={`tab-link ${
                      currentTab === "email" ? "active" : ""
                    }`}
                  >
                    <FaEnvelope className="tab-icon" /> Email
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </>
          ) : (
            <>
              <Nav
                className="flex-column bg-light p-3 w-100"
                // style={{ width: "100%" }}
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey="sms"
                    onSelect={() => getAttributeHandler("sms")}
                    active={currentTab === "sms"}
                    className={`tab-link ${
                      currentTab === "sms" ? "active" : ""
                    }`}
                  >
                    <FaCommentAlt className="tab-icon" /> SMS
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </>
          )
        ) : (
          <Nav
            className="flex-column bg-light p-3 w-100"
            //  style={{ width: "100%" }}
          >
            <Nav.Item>
              <Nav.Link
                eventKey="email"
                onSelect={() => getAttributeHandler("email")}
                active={currentTab === "email"}
                className={`tab-link ${currentTab === "email" ? "active" : ""}`}
              >
                <FaEnvelope className="tab-icon" /> Email
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="sms"
                onSelect={() => getAttributeHandler("sms")}
                active={currentTab === "sms"}
                className={`tab-link ${currentTab === "sms" ? "active" : ""}`}
              >
                <FaCommentAlt className="tab-icon" /> SMS
              </Nav.Link>
            </Nav.Item>
          </Nav>
        )}
      </Col>
      <Col className="col-sm-12 col-md-10">
        {currentTab === "email" && (
          <Email
            broadCastData={props.broadCastData}
            getAllSentEmails={props.getAllSentEmails}
          />
        )}
        {currentTab === "sms" && <SMS />}
      </Col>
    </Row>
  );
};

export default EmailComposer;
