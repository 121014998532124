import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import DatePicker from "react-datepicker";
import Sort from "../../../common/FullDataSort";

const ReportHeaders = ({
  memberFrom,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  handleDateSearch,
  setSort,
  sort,
  filteredData,
  sortCount,
  orderList,
  sortObject,
  applySort,
  setSortCount,
  setSortArray,
  openFilter,
  onChangeSelect,
  sortArray,
  selectedName,
  selectedExp,
  selectedCandidatesAdded,
  selectedStatus,
  dataa,
}) => {
  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    saveAs(blob, "CMGigRecruiterReportExcel.xlsx");
  };
  return (
    <div>
      <div>
        <div className="d-flex align-items-center justify-content-between">
          <h5 className="ml-2.5">Recruiters</h5>
        </div>
        <div className="d-flex bd-highlight ml-2.5 mb-4">
          <div className="p-2 bd-highlight " style={{ width: "10em" }}>
            <label htmlFor="start-date">
              <h6 className="m-0 mb-1">From</h6>
            </label>
            <DatePicker
              className="form-control"
              selected={fromDate}
              onChange={(date) => setFromDate(date)}
              onChangeRaw={(e) => e.preventDefault()}
            />
          </div>
          <div className="p-2 bd-highlight" style={{ width: "10em" }}>
            <label htmlFor="end-date">
              <h6 className="m-0 mb-1">To</h6>
            </label>
            <DatePicker
              className="form-control"
              selected={toDate}
              onChange={(date) => setToDate(date)}
              onChangeRaw={(e) => e.preventDefault()}
            />
          </div>
          <div className="p-2 align-self-end bd-highlight mt-10">
            <button
              className="btn btn-primary btn-sm"
              type="submit"
              onClick={() => handleDateSearch()}
            >
              Search
            </button>
          </div>
          <div className="py-2 align-self-end bd-highlight">
            {filteredData.length > 0 && (
              <button className="btn btn-sm" onClick={exportToExcel}>
                <span>
                  <i className="fas fa-file-download mr-2"></i>
                  Download
                </span>
              </button>
            )}
          </div>
          <div class="py-2 align-self-end">
            <div class="dropdown-sort dd-sort">
              <button
                type="button"
                onClick={() => setSort(!sort)}
                class="btn btn-sm w-auto btn-text-accent dd-sort"
                disabled={filteredData.length === 0}
              >
                <i
                  aria-hidden="true"
                  class="fad fa-sort-down font-16 dd-sort mr-1"
                ></i>
                Sort / <span class="dd-sort">{sortCount}</span>
              </button>
              {
                <OutsideClickHandler onOutsideClick={() => setSort(false)}>
                  {sort && (
                    <Sort
                      sortData={dataa.map((e) => {
                        return {
                          ...e,
                          email:
                            e.email.charAt(0).toUpperCase() + e.email.slice(1),
                          firstName:
                            e.firstName &&
                            e.firstName.charAt(0).toUpperCase() + e.firstName &&
                            e.firstName.slice(1),
                        };
                      })}
                      orderArray={orderList}
                      sortObjects={sortObject}
                      applySort={applySort}
                      setSortCount={setSortCount}
                      sortCount={sortCount}
                      setSortArray={setSortArray}
                      sortArray={sortArray}
                      sortingFor={
                        "Sort by Name, Email, Added on, Status and No. of candidates"
                      }
                    />
                  )}
                </OutsideClickHandler>
              }
            </div>
          </div>
          <div class="py-2 align-self-end">
            <div class="dropdown position-relative">
              <button
                data-bs-toggle="tooltip"
                data-bs-placement="left"
                title="Filter"
                onClick={openFilter}
                type="button"
                class="btn btn-sm btn-text-accent w-auto"
                //disabled={filteredData.length === 0}
              >
                <i class="fas fa-filter"></i>
                <span>Filter</span> /{" "}
                <span>
                  {selectedName?.length +
                    selectedExp?.length +
                    (memberFrom[0] || memberFrom[1] ? 1 : 0) +
                    selectedCandidatesAdded?.length +
                    selectedStatus?.length}
                </span>
              </button>
            </div>
          </div>
          <div
            className="bd-highlight ml-auto mt-4"
            style={{ width: "15rem", marginLeft: "0.6rem" }}
          >
            <h6 className="mt-1">Records</h6>
            <select className="form-select" onChange={(e) => onChangeSelect(e)}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHeaders;
