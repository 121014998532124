import React, { useEffect, useState } from "react";
import ReportTopNavBar from "../../report/ReportTopNavBar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import TopNavBar from "../../layout/TopNavBar";
import SideNavBar from "../../layout/SideNavBar";
import { get, post } from "../../../service/ApiService";
import Modal from "react-bootstrap/Modal";
import Loader from "../../Loader/Loader";
import MatchedData from "./MatchingData";
import ExistingJob from "./ExistingJobs";
import { getTenantType } from "../../../service/AuthService";
import Footer from "../../layout/Footer";

const Index = () => {
  const dispatch = useDispatch();

  const [toggle, setToggle] = useState(true);
  const [candidateCount, setCandidateCount] = useState();
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [addCount, setAddCount] = useState(0);
  const [showJobs, setShowJobs] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [resultOne, setResultOne] = useState(0);
  const [resultTwo, setResultTwo] = useState(0);
  const [resultThree, setResultThree] = useState(0);
  const [talentResultOne, setTalentResultOne] = useState([]);
  const [talentResultTwo, setTalentResultTwo] = useState([]);
  const [talentResultThree, setTalentResultThree] = useState([]);
  const [jobDetailOne, setJobDetailOne] = useState();
  const [jobDetailTwo, setJobDetailTwo] = useState();
  const [jobDetailThree, setJobDetailThree] = useState();
  const [auto, setAuto] = useState("");
  const [timeOne, setTimeOne] = useState();
  const [timeTwo, setTimeTwo] = useState();
  const [timeThree, setTimeThree] = useState();

  const handleJobClose = () => setShowJobs(false);
  const handleJobShow = (flag) => {
    setShowJobs(true);
    setAuto(flag);
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    getTalentCount();
    getJobList();
  }, []);

  const getJobList = async () => {
    // let paramObj = {
    //   sortObject: [],
    //   currentPage: 1,
    //   tab: "all",
    //   filterQuery: {
    //     address: [],
    //     isHotJob: 0,
    //     jobId: [],
    //     jobTitle: [],
    //     jobType: [],
    //     visaStatus: [],
    //   },
    // };
    setLoading(true);
    // dispatch(getJobList(paramObj));

    await get("/job/getjoblist").then((data) => {
      setJobList(data.data);
      setLoading(false);
    });
  };

  const getTalentCount = async () => {
    setLoading(true);
    await get("/talent/candidateCount").then((data) => {
      setCandidateCount(data?.data.count);
      setLoading(false);
    });
  };
  const toggleDiv = (type) => {
    if (type === true) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const addTalent = async () => {
    let data = { count: addCount };
    setLoading(true);
    await post("/talent/candidateBulkUpload", data).then((data) => {
      setShow(false);
      setLoading(false);
      getTalentCount();
    });
  };
  const handleDelete = async () => {
    setLoading(true);
    await post("/talent/deleteCandidate").then((data) => {
      setLoading(false);
      getTalentCount();
    });
  };
  const handleChange = (e) => {
    setAddCount(e.target.value);
  };

  const setJobDetails = async (item) => {
    console.log(item);
    setLoading(true);
    let weightage = JSON.parse(item.weightage);
    let automatchedData = {
      jobId: item.jobId,
      jobTitle: item.jobTitle,
      primarySkills: JSON.parse(item.primarySkills),
      education: JSON.parse(item.education),
      experienceLevel: item.experienceLevel,
      JprimarySkills: weightage.primarySkills,
      JjobTitle: weightage.jobTitle,
      Jlocation: weightage.location,
      JexperienceLevel: weightage.experienceLevel,
      Jeducation: weightage.education,
      country: item.country,
    };

    var date1 = new Date();

    if (auto === "autoOne") {
      await post("/automatch/automatchPerformance", automatchedData).then(
        (data) => {
          setLoading(false);
          setShowJobs(false);
          setResultOne(data.data.count);
          setTalentResultOne(data?.data?.data);
          setJobDetailOne(item);
          var date2 = new Date();
          var diff = date2.getTime() - date1.getTime();
          var msec = diff;
          var hh = Math.floor(msec / 1000 / 60 / 60);
          msec -= hh * 1000 * 60 * 60;
          var mm = Math.floor(msec / 1000 / 60);
          msec -= mm * 1000 * 60;
          var ss = Math.floor(msec / 1000);
          msec -= ss * 1000;
          console.log(hh + ":" + mm + ":" + ss);
          setTimeOne(hh + ":" + mm + ":" + ss);
        }
      );
    } else if (auto === "autoTwo") {
      await post("/automatch/automatchPerformance", automatchedData).then(
        (data) => {
          setLoading(false);
          setShowJobs(false);
          setResultTwo(data.data.count);
          setTalentResultTwo(data?.data?.data);
          setJobDetailTwo(item);
          var date2 = new Date();
          var diff = date2.getTime() - date1.getTime();
          var msec = diff;
          var hh = Math.floor(msec / 1000 / 60 / 60);
          msec -= hh * 1000 * 60 * 60;
          var mm = Math.floor(msec / 1000 / 60);
          msec -= mm * 1000 * 60;
          var ss = Math.floor(msec / 1000);
          msec -= ss * 1000;
          console.log(hh + ":" + mm + ":" + ss);
          setTimeTwo(hh + ":" + mm + ":" + ss);
        }
      );
    } else if (auto === "autoThree") {
      await post("/automatch/automatchPerformance", automatchedData).then(
        (data) => {
          setLoading(false);
          setShowJobs(false);
          setResultThree(data.data.count);
          setTalentResultThree(data?.data?.data);
          setJobDetailThree(item);
          var date2 = new Date();
          var diff = date2.getTime() - date1.getTime();
          var msec = diff;
          var hh = Math.floor(msec / 1000 / 60 / 60);
          msec -= hh * 1000 * 60 * 60;
          var mm = Math.floor(msec / 1000 / 60);
          msec -= mm * 1000 * 60;
          var ss = Math.floor(msec / 1000);
          msec -= ss * 1000;
          console.log(hh + ":" + mm + ":" + ss);
          setTimeThree(hh + ":" + mm + ":" + ss);
        }
      );
    }
  };

  return (
    <>
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        {loading && <Loader />}
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <ReportTopNavBar handleToggale={toggleDiv} />
        <TopNavBar handleToggale={toggleDiv} />
        <SideNavBar />
        <div id="Content">
          <div className="section section-sm">
            <div className="container">
              <h6>Performance Testing</h6>
              {/* <div className="row">
                <div className="col" style={{ textAlign: "end" }}>
                  Total Talent Count : {candidateCount}
                  <br></br>
                  <button className="btn-sm m-1" onClick={handleShow}>
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button
                    className="btn-sm btn-danger m-1"
                    onClick={() => handleDelete()}
                  >
                    <i class="fa fa-trash" aria-hidden="true"></i>
                  </button>
                </div>
              </div> */}
              <br></br>
              <div className="row">
                <div className="col-4">
                  <button onClick={() => handleJobShow("autoOne")}>
                    Automatch-1
                  </button>
                  <br></br>
                  <strong className="mr-1">
                    Automatch count : {resultOne}/{candidateCount}
                  </strong>
                  <br></br>
                  {jobDetailOne && <strong>Time Taken: {timeOne}</strong>}
                  {jobDetailOne && (
                    <div className="row m-1">
                      <div className="card card-flat bg-gray4 m-1 p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <span className="mr-3">
                              <h6>{jobDetailOne?.jobTitle} </h6>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-suitcase me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {jobDetailOne?.jobTitle}{" "}
                                {JSON.parse(jobDetailOne.weightage).jobTitle
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-file-user me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {jobDetailOne?.experienceLevel}{" "}
                                {JSON.parse(jobDetailOne.weightage)
                                  ?.experienceLevel
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {!jobDetailOne?.isRemote
                                  ? jobDetailOne?.country
                                  : "Remote"}{" "}
                                {JSON.parse(jobDetailOne?.weightage)?.location
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-user-graduate me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {JSON.parse(jobDetailOne.education).join(",")}{" "}
                                {JSON.parse(jobDetailOne?.weightage)?.education
                                  ?.length > 0
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="align-items-center">
                            <i className="far fa-cogs me-2 font-brandBlue"></i>
                            {JSON.parse(jobDetailOne.primarySkills).map(
                              (items) => (
                                <span className="tag tag-blue3 mb-1 mr-1">
                                  {items}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {talentResultOne?.length > 0 && (
                    <div className="row m-1">
                      {talentResultOne?.map((item, i) => (
                        <>
                          <div className="card card-flat bg-gray4 m-1 p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <span className="mr-3">
                                  <h6>{item.email} </h6>
                                </span>
                              </div>
                              <div className="d-flex align-items-center">
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-suitcase me-2 font-brandBlue"></i>
                                  <small className="font-regular font-primary font-gray1">
                                    {item.designation}
                                  </small>
                                </span>
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-file-user me-2 font-brandBlue"></i>
                                  <span className="font-regular font-primary font-gray1">
                                    {item.experienceLevel}
                                  </span>
                                </span>
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                                  <span className="font-regular font-primary font-gray1">
                                    {item.country}
                                  </span>
                                </span>
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-user-graduate me-2 font-brandBlue"></i>
                                  <span className="font-regular font-primary font-gray1">
                                    {item.highestEducation}
                                  </span>
                                </span>
                              </div>
                              <div className="align-items-center">
                                <i className="far fa-cogs me-2 font-brandBlue"></i>
                                {JSON.parse(item.primarySkills).map((items) => (
                                  <span className="tag tag-blue3 mb-1 mr-1">
                                    {items}
                                  </span>
                                ))}
                              </div>
                              {jobDetailOne && (
                                <div>
                                  <MatchedData
                                    jobData={jobDetailOne}
                                    candidateData={item}
                                    candidateDetails={item}
                                  ></MatchedData>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col-4">
                  <button onClick={() => handleJobShow("autoTwo")}>
                    Automatch-2
                  </button>
                  <br></br>
                  <strong className="mr-1">
                    Automatch count : {resultTwo}/{candidateCount}
                  </strong>
                  <br></br>
                  {jobDetailTwo && <strong>Time Taken: {timeTwo}</strong>}
                  {jobDetailTwo && (
                    <div className="row m-1">
                      <div className="card card-flat bg-gray4 m-1 p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <span className="mr-3">
                              <h6>{jobDetailTwo?.jobTitle} </h6>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-suitcase me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {jobDetailTwo?.jobTitle}{" "}
                                {JSON.parse(jobDetailTwo.weightage).jobTitle
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-file-user me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {jobDetailTwo?.experienceLevel}{" "}
                                {JSON.parse(jobDetailTwo.weightage)
                                  ?.experienceLevel
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {!jobDetailTwo?.isRemote
                                  ? jobDetailTwo?.country
                                  : "Remote"}{" "}
                                {JSON.parse(jobDetailTwo?.weightage)?.location
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-user-graduate me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {JSON.parse(jobDetailTwo.education).join(",")}{" "}
                                {JSON.parse(jobDetailTwo?.weightage)?.education
                                  ?.length > 0
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="align-items-center">
                            <i className="far fa-cogs me-2 font-brandBlue"></i>
                            {JSON.parse(jobDetailTwo.primarySkills).map(
                              (items) => (
                                <span className="tag tag-blue3 mb-1 mr-1">
                                  {items}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {talentResultTwo?.length > 0 && (
                    <div className="row m-1">
                      {talentResultTwo?.map((item, i) => (
                        <>
                          <div className="card card-flat bg-gray4 m-1 p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <span className="mr-3">
                                  <h6>{item.email} </h6>
                                </span>
                              </div>
                              <div className="d-flex align-items-center">
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-suitcase me-2 font-brandBlue"></i>
                                  <small className="font-regular font-primary font-gray1">
                                    {item.designation}
                                  </small>
                                </span>
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-file-user me-2 font-brandBlue"></i>
                                  <span className="font-regular font-primary font-gray1">
                                    {item.experienceLevel}
                                  </span>
                                </span>
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                                  <span className="font-regular font-primary font-gray1">
                                    {item.country}
                                  </span>
                                </span>
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-user-graduate me-2 font-brandBlue"></i>
                                  <span className="font-regular font-primary font-gray1">
                                    {item.highestEducation}
                                  </span>
                                </span>
                              </div>
                              <div className="align-items-center">
                                <i className="far fa-cogs me-2 font-brandBlue"></i>
                                {JSON.parse(item.primarySkills).map((items) => (
                                  <span className="tag tag-blue3 mb-1 mr-1">
                                    {items}
                                  </span>
                                ))}
                              </div>
                              {jobDetailTwo && (
                                <div>
                                  <MatchedData
                                    jobData={jobDetailTwo}
                                    candidateData={item}
                                    candidateDetails={item}
                                  ></MatchedData>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </div>
                <div className="col-4">
                  <button onClick={() => handleJobShow("autoThree")}>
                    Automatch-3
                  </button>
                  <br></br>
                  <strong className="mr-1">
                    Automatch count : {resultThree}/{candidateCount}
                  </strong>
                  <br></br>
                  {jobDetailThree && <strong>Time Taken: {timeThree}</strong>}
                  {jobDetailThree && (
                    <div className="row m-1">
                      <div className="card card-flat bg-gray4 m-1 p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <span className="mr-3">
                              <h6>{jobDetailThree?.jobTitle} </h6>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-suitcase me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {jobDetailThree?.jobTitle}{" "}
                                {JSON.parse(jobDetailThree.weightage).jobTitle
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-file-user me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {jobDetailThree?.experienceLevel}{" "}
                                {JSON.parse(jobDetailThree.weightage)
                                  ?.experienceLevel
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {!jobDetailThree?.isRemote
                                  ? jobDetailThree?.country
                                  : "Remote"}{" "}
                                {JSON.parse(jobDetailThree?.weightage)?.location
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="d-flex align-items-center">
                            <span className="d-flex align-items-center me-3">
                              <i className="far fa-user-graduate me-2 font-brandBlue"></i>
                              <span className="font-regular font-primary font-gray1">
                                {JSON.parse(jobDetailThree.education).join(",")}{" "}
                                {JSON.parse(jobDetailThree?.weightage)
                                  ?.education?.length > 0
                                  ? "(Required)"
                                  : "(Nice to have)"}
                              </span>
                            </span>
                          </div>
                          <div className="align-items-center">
                            <i className="far fa-cogs me-2 font-brandBlue"></i>
                            {JSON.parse(jobDetailThree.primarySkills).map(
                              (items) => (
                                <span className="tag tag-blue3 mb-1 mr-1">
                                  {items}
                                </span>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {talentResultThree?.length > 0 && (
                    <div className="row m-1">
                      {talentResultThree?.map((item, i) => (
                        <>
                          <div className="card card-flat bg-gray4 m-1 p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1">
                            <div className="card-body">
                              <div className="d-flex align-items-center">
                                <span className="mr-3">
                                  <h6>{item.email} </h6>
                                </span>
                              </div>
                              <div className="d-flex align-items-center">
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-suitcase me-2 font-brandBlue"></i>
                                  <small className="font-regular font-primary font-gray1">
                                    {item.designation}
                                  </small>
                                </span>
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-file-user me-2 font-brandBlue"></i>
                                  <span className="font-regular font-primary font-gray1">
                                    {item.experienceLevel}
                                  </span>
                                </span>
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                                  <span className="font-regular font-primary font-gray1">
                                    {item.country}
                                  </span>
                                </span>
                                <span className="d-flex align-items-center me-3">
                                  <i className="far fa-user-graduate me-2 font-brandBlue"></i>
                                  <span className="font-regular font-primary font-gray1">
                                    {item.highestEducation}
                                  </span>
                                </span>
                              </div>
                              <div className="align-items-center">
                                <i className="far fa-cogs me-2 font-brandBlue"></i>
                                {JSON.parse(item.primarySkills).map((items) => (
                                  <span className="tag tag-blue3 mb-1 mr-1">
                                    {items}
                                  </span>
                                ))}
                              </div>
                              {jobDetailThree && (
                                <div>
                                  <MatchedData
                                    jobData={jobDetailThree}
                                    candidateData={item}
                                    candidateDetails={item}
                                  ></MatchedData>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Talent</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mt-2 m-3">
              <div className="col">
                <div className="form-floating">
                  <input
                    name="talentCount"
                    type="number"
                    min="1"
                    className="form-control"
                    onChange={(e) => handleChange(e)}
                  />
                  <label>Talent count</label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button className="btn-secondary" onClick={handleClose}>
              Close
            </button>
            <button className="btn-primary" onClick={() => addTalent()}>
              Add
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={showJobs}
          onHide={handleJobClose}
          backdrop="static"
          keyboard={false}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Select Job</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <div className="m-3">
              {jobList?.map((request, i) => (
                <a
                  key={i}
                  onClick={() => setJobDetails(request)}
                  className="card card-flat bg-gray4 m-1 p-0 d-flex w-100 align-items-center justify-content-between request-listview flex-row overflow-hidden mb-1"
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <span className="mr-3">
                        <h6>{request?.jobTitle} </h6>
                      </span>
                      {request.isHotJob && (
                        <span className="tag tag-coral mr-9">HOT</span>
                      )}
                    </div>
                    <div className="d-flex align-items-center">
                      <span className="d-flex align-items-center me-3">
                        <i className="far fa-suitcase me-2 font-brandBlue"></i>
                        <small className="font-regular font-primary font-gray1">
                          {request.jobType}
                        </small>
                      </span>
                      <span className="d-flex align-items-center me-3">
                        <i className="far fa-map-marker-alt me-2 font-brandBlue"></i>
                        <span className="font-regular font-primary font-gray1">
                          {request?.city || "Remote"}
                        </span>
                      </span>
                    </div>
                  </div>
                </a>
              ))}
            </div> */}
            <ExistingJob jobList={jobList} setJobDetails={setJobDetails} />
          </Modal.Body>
          <Modal.Footer>
            <button className="btn-secondary" onClick={handleJobClose}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </body>
    </>
  );
};

export default Index;
