import { toast } from "react-toastify";
import { post,get } from "../../service/ApiService";
import {
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  GET_RECRUITER_DATA_REQUEST,
  GET_RECRUITER_DATA_SUCCESS,
  GET_RECRUITER_DATA_FAILURE,
  GET_DROPDOWN_LIST_REQUEST,
  GET_DROPDOWN_LIST_SUCCESS,
  GET_DROPDOWN_LIST_FAILURE,
} from './actionTypes';
import { updateLastUpdated } from "../../service/AuthService";

const PROFILE_ENDPOINT = '/update-profile';

export const fetchProfileRequest = () => ({
  type: FETCH_PROFILE_REQUEST,
});

export const fetchProfileSuccess = (data) => ({
  type: FETCH_PROFILE_SUCCESS,
  payload: data,
});

export const fetchProfileFailure = (error) => ({
  type: FETCH_PROFILE_FAILURE,
  payload: error,
});
export const updateProfileRequest = () => ({
    type: UPDATE_PROFILE_REQUEST,
  });
  
  export const updateProfileSuccess = (data) => ({
    type: UPDATE_PROFILE_SUCCESS,
    payload: data,
  });
  
  export const updateProfileFailure = (error) => ({
    type: UPDATE_PROFILE_FAILURE,
    payload: error,
  });
  export const getRecrutierDataRequest = () => ({
    type: GET_RECRUITER_DATA_REQUEST,
  });
  
  export const getRecrutierDataSuccess = (data) => ({
    type: GET_RECRUITER_DATA_SUCCESS,
    payload: data,
  });
  
  export const getRecrutierDataFailure = (error) => ({
    type: GET_RECRUITER_DATA_FAILURE,
    payload: error,
  });
  export const getDropDownListRequest = () => ({
    type: GET_DROPDOWN_LIST_REQUEST,
  });
  
  export const getDropDownListSuccess = (data) => ({
    type: GET_DROPDOWN_LIST_SUCCESS,
    payload: data,
  });
  
  export const getDropDownListFailure = (error) => ({
    type: GET_DROPDOWN_LIST_FAILURE,
    payload: error,
  });
  
//initial fetch for profile data
export const fetchProfile = () => {
  return async (dispatch) => {
    dispatch(fetchProfileRequest());
    try {
      const res = await get(`${PROFILE_ENDPOINT}`);
      if(res.status === 200){
        dispatch(fetchProfileSuccess(res.data));
      }
      
    } catch (error) {
      dispatch(fetchProfileFailure(error.message));
      toast.error(error?.response?.data?.errMessage);
    }
  };
};
//update profile data
export const updateProfile = (param1,param2,flag) => {
  return async (dispatch) => {
    dispatch(updateProfileRequest());
    try {
      if(flag){
        const res = await post('/common/contactinfo/save', param1)
        const res2 = await post('/common/recruitementdetails/save',param2)
        if(res.status === 200 && res2.status === 200){
          dispatch(updateProfileSuccess(res.data));
           if(updateLastUpdated()){
            toast.success("Profile details have been successfully updated.");
          }
        }
      }else{
        const res = await post('/common/contactinfo/save', param1)
        if(res.status === 200){
          dispatch(updateProfileSuccess(res.data));
           if(updateLastUpdated()){
            toast.success("Profile details have been successfully updated.");
          }
        }
      }
     
    } catch (error) {
      dispatch(updateProfileFailure(error.message));
      toast.error(error?.response?.data?.errMessage);
    }
  };
};
// Action Creator to Fetch Recruiter Data
export const fetchRecruiterData = (params) => {
  return async (dispatch) => {
    dispatch(getRecrutierDataRequest());
    try {
      const res = await post('/common/contactinfo', params);
      if (res.status === 200) {
        dispatch(getRecrutierDataSuccess(res.data));
      }
    } catch (error) {
      dispatch(getRecrutierDataFailure(error.message));
      toast.error(error?.response?.data?.errMessage);
    }
  };
};
// Action Creator for fetch dropdwonlist
export const fetchDropDownList = () => {
  return async (dispatch) => {
    dispatch(getDropDownListRequest());
    try {
      const res = await get('/common/dropdownlist');
      if (res.status === 200) {
        dispatch(getDropDownListSuccess(res.data));
      }
    } catch (error) {
      dispatch(getDropDownListFailure(error.message));
      toast.error(error?.response?.data?.errMessage);
    }
  };
}  