import moment from "moment";
import { useEffect } from "react";
import { toast } from "react-toastify";

const CreatedOnFilter = ({
  handleFilterToggle,
  filterEnabled,
  filterStatus,
  filterName,
  selectedData,
  setSelectedData,
  applyFilter,
}) => {
  const applyRecordedOnFilter = () => {
    let startDate = moment(new Date(selectedData.memberFrom[0])).format(
      "MM/DD/YYYY"
    );
    let endDate = moment(new Date(selectedData.memberFrom[1])).format(
      "MM/DD/YYYY"
    );
    if (
      (selectedData.memberFromType === "after" &&
        selectedData.memberFrom[0] === "") ||
      (selectedData.memberFromType === "before" &&
        selectedData.memberFrom[1] === "")
    ) {
      toast.error(`Please select a valid date`);
    } else if (
      selectedData.memberFromType === "between" &&
      (selectedData.memberFrom[0] === "" || selectedData.memberFrom[1] === "")
    ) {
      if (
        selectedData.memberFrom[0] === "" &&
        selectedData.memberFrom[1] === ""
      ) {
        toast.error(`Please select valid 'From' and 'To' dates`);
      } else if (selectedData.memberFrom[0] === "") {
        toast.error(`Please select a valid 'From' date`);
      } else if (selectedData.memberFrom[1] === "") {
        toast.error(`Please select a valid 'To' date`);
      } else {
        toast.error(`Please select a valid date`);
      }
    } else if (
      new Date(startDate) > new Date() &&
      (selectedData.memberFromType === "after" ||
        selectedData.memberFromType === "before")
    ) {
      toast.error(`'Date' cannot be future date`);
    } else if (new Date(startDate) > new Date()) {
      toast.error(`'From Date' cannot be future date`);
    } else if (new Date(endDate) > new Date()) {
      toast.error(`'To Date' cannot be future date`);
    } else if (new Date(startDate) > new Date(endDate)) {
      toast.error(`'From Date' should not be greater than 'To Date'`);
    } else {
      applyFilter(selectedData, filterStatus);
    }
  };
  return (
    <div className="mb-2">
      <button
        type="button"
        className="btn btn-collapsible"
        onClick={() => handleFilterToggle(filterStatus)}
      >
        <i
          className={
            filterEnabled ? "fal fa-angle-up fa-fw" : "fal fa-angle-down fa-fw"
          }
        />
        <span className="p-relative">
          <small>{filterName}</small>
          {(selectedData.memberFrom[0] || selectedData.memberFrom[1]) && (
            <span className="jewel"></span>
          )}
        </span>
      </button>
      {filterEnabled && (
        <div className="ml-2 mr-2">
          <div className="d-flex mt-2">
            <select
              onChange={(e) => {
                setSelectedData({
                  ...selectedData,
                  memberFromType: e.target.value,
                  memberFrom: ["", ""],
                });
              }}
              className="form-select "
            >
              <option disabled selected={!selectedData.memberFromType}>
                Select Range
              </option>
              <option
                value="after"
                selected={selectedData.memberFromType === "after"}
              >
                After
              </option>
              <option
                value="before"
                selected={selectedData.memberFromType === "before"}
              >
                Before
              </option>
              <option
                value="between"
                selected={selectedData.memberFromType === "between"}
              >
                Between
              </option>
            </select>
          </div>
          <div className="d-flex mt-2">
            {selectedData.memberFromType !== "" ? (
              selectedData.memberFromType === "after" ||
              selectedData.memberFromType === "before" ? (
                <div class="col-12">
                  <input
                    type="date"
                    onChange={(e) => {
                      let a = ["", ""];
                      selectedData.memberFromType === "before"
                        ? (a[1] = e.target.value)
                        : (a[0] = e.target.value);
                      setSelectedData({
                        ...selectedData,
                        memberFrom: a,
                      });
                    }}
                    style={{ maxHeight: "41px", margin: "14px 0px" }}
                    className="form-control date"
                    value={
                      selectedData.memberFromType === "before"
                        ? selectedData.memberFrom[1]
                        : selectedData.memberFrom[0]
                    }
                    required
                    max={moment(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              ) : (
                <div class="col-12">
                  <label htmlFor="startDate">From</label>
                  <input
                    type="date"
                    style={{ maxHeight: "41px", margin: "14px 0px" }}
                    className="form-control date mr-2"
                    onChange={(e) => {
                      let a = [...selectedData.memberFrom];
                      a[0] = e.target.value;
                      setSelectedData({
                        ...selectedData,
                        memberFrom: a,
                      });
                    }}
                    value={selectedData.memberFrom[0]}
                    max={moment(new Date()).format("YYYY-MM-DD")}
                  />

                  <label htmlFor="endDate">To</label>
                  <input
                    type="date"
                    style={{ maxHeight: "41px", margin: "14px 0px" }}
                    className="form-control date"
                    onChange={(e) => {
                      let a = [...selectedData.memberFrom];
                      a[1] = e.target.value;
                      setSelectedData({
                        ...selectedData,
                        memberFrom: a,
                      });
                    }}
                    value={selectedData.memberFrom[1]}
                    max={moment(new Date()).format("YYYY-MM-DD")}
                  />
                </div>
              )
            ) : null}
          </div>
          <div className="d-flex justify-content-between py-2 px-1">
            <button
              disabled={selectedData.memberFromType === ""}
              onClick={applyRecordedOnFilter}
              type="button"
              className="btn btn-sm btn-primary"
            >
              Done
            </button>
            <button
              disabled={selectedData.memberFromType === ""}
              className="btn btn-text"
              onClick={() => {
                setSelectedData({
                  ...selectedData,
                  memberFromType: "",
                  memberFrom: ["", ""],
                });
                applyFilter({}, "");
              }}
            >
              <span className="font-12">
                <small>Clear recorded on</small>
              </span>
            </button>
          </div>
          {/* <hr className="p-0" /> */}
        </div>
      )}
    </div>
  );
};

export default CreatedOnFilter;
