import React from "react";


function SelfAssessmentPopUp(props) {
    
const close = () =>{
    props.hideAssessment(true)
}
  return (
    <>
      {/* <div class="bl-modal" v-bind:class="{ 'reveal-overlay': revealOverlay,'reveal-card':revealCard, 'remove-card': removeCard, 'bl-modal-fullscreen': isFullScreen }"> */}
      <div class="bl-modal reveal-overlay reveal-card">
        <div
          // v-on:click="close"
          class="bl-modal-overlay "
        ></div>
        <div  class="bl-modal-container ">
          <div class="container ">
            <div class="row justify-content-center closable">
              <div class="col-lg-10 closable">
                <div
                  class="bl-modal-card bl-modal-card-md"
                  // v-bind:class="{ 'bl-modal-card-lg': isLarge, 'bl-modal-card-md': isMedium}"
                >
                  <div v-if="!formComplete">
                    <div class="bl-modal-header">
                      <h6 v-if="title" class="bl-modal-title">
                      Self Assessment
                      </h6>
                      <button
                        type="button"
                        onClick={close}
                        class="close p-0 bl-modal-close-btn closable"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <i class="fal fa-times closable"></i>
                      </button>
                    </div>
                    <div class="bl-modal-body">
                      <p class="mb-1">Select the Assessment Type</p>
                      <div class="form-floating mb-3">
                        <select id="floatingSelect" class="form-select">
                          <option selected="selected" value="1way">
                            1 Way Video Interview
                          </option>
                          <option value="2way">2 Way Video Interview</option>
                          <option value="mcq">
                            Multiple Choice Questionaire
                          </option>
                        </select>
                        <label>Select Assessment Type</label>
                      </div>
                      <p class="mb-1">Select Skill</p>
                      <select2
                        single="true"
                        placeholder="Select Skill"
                        // :options="[{text: 'NodeJs'},{text: 'React'},{text: 'VueJs'}]"
                      >
                        <option disabled value="0">
                          Select one
                        </option>
                      </select2>
                    </div>
                    <div class="modal-footer">
                      <button
                        type="button"
                        onClick={close}
                        class="btn btn-secondary mr-3 closable"
                      >
                        Close
                        
                      </button>
                      <a
                        href="/candidate/assessments/12312312"
                        class="btn btn-primary"
                      >
                        Start Assessment
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SelfAssessmentPopUp;
