import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import logo from "../../../images/high5logo.png";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import { emailDomain } from "../../../constants/Constants";
const Index = () => {
  const { role, email } = useParams();
  const [subbed, setSubbed] = useState(true);
  const [end, setEnd] = useState(false);
  const [loading, setLoading] = useState(false);

  const findStatus = async () => {
    try {
      setLoading(true);
      const res = await post("/inviteunsubscribed/fetchStatus", {
        email: email,
      });
      let status = res.data.status === "Unsubscribed" ? false : true;
      setSubbed(status);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    findStatus();
  }, []);

  const handleSubmit = () => {
    try {
      let url = "/inviteunsubscribed";
      url = subbed ? `${url}/isUnsubscribed` : `${url}/isSubscribed`;

      setLoading(true);
      console.log(url);
      post(url, { email: email })
        .then((response) => {
          if (!subbed) setEnd(true);
          console.log(response);
          setSubbed(!subbed);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };
  return (
    <div>
      {loading && <Loader />}
      <Modal show={true} size="lg">
        <div className="bl-modal-header">
          <h5 className="bl-modal-title">{"Email preferences"}</h5>

          <div className="bl-modal-header"></div>
          <Modal.Body>
            <>
              <div className=" d-flex flex-column align-items-center justify-content-center mt-2">
                <div className=" d-flex flex-column justify-content-center w-75 gap-2">
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <img src={logo} width="150" className="high5logo" />
                  </div>
                  {end ? (
                    <p className="text-center">
                      You have subscribed for receiving job opportunities at{" "}
                      <strong>{`${email}`}</strong>.
                    </p>
                  ) : subbed ? (
                    <p className="text-center">
                      You are currently receiving job opportunities at{" "}
                      <strong>{`${email}`}</strong>. Clicking button below will
                      unsubscribe you from receiving email notifications.
                    </p>
                  ) : (
                    <p className="text-center">
                      You have successfully been unsubscribed from receiving job
                      opportunities from high5hire.
                    </p>
                  )}
                </div>
              </div>
            </>
          </Modal.Body>
        </div>
        <Modal.Footer>
          {end ? (
            <div className="w-100 d-flex flex-column justify-content-center">
              <div className=" d-flex justify-content-center">
                <a onClick={() => setLoading(true)} href={`${emailDomain}`}>
                  <button type="button" className="btn " data-dismiss="modal">
                    Close
                  </button>
                </a>
              </div>
            </div>
          ) : (
            <div className="w-100 d-flex flex-column justify-content-center">
              {!subbed && (
                <p className="text-center">Do you want to resubscribe</p>
              )}
              <div className=" d-flex justify-content-center">
                <button
                  type="button"
                  className="btn "
                  data-dismiss="modal"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  {subbed ? "Unsubscribe" : "Resubscribe"}
                </button>
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Index;
