import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Select from "react-select";
import Pagination from "../commoncomponents/pagination/Pagination";
import { fetchAllJobs } from "../../../actions/jobList/jobList";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import JobFilterHM from "./broadCastForHM/JobFilterHM";

const SelectJob = (props) => {
  const dispatch = useDispatch();
  const { jobListLoading, jobList } = useSelector(
    (state) => ({
      jobListLoading: state.getJobListReducer.loading,
      jobList: state.getJobListReducer.jobList,
    }),
    shallowEqual
  );
  const [searchJobValue, setSearchJobValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationArray, setPaginationArray] = useState([]);
  const [modalClose,setModalClose] = useState(false);
  const dataSize = 10;
  const pageSize = 5;
  useEffect(()=>{
    if(modalClose){
      props.closeModal();
    }
  },[modalClose])
  const handleClose = ()=>{
    setModalClose(!modalClose)
  }
  const handleSearchChange = (e) => {
    setSearchJobValue(e.target.value);
  };

  return <JobFilterHM handleClose={handleClose}/>;
};

export default SelectJob;
