import moment from "moment";
import React from "react";

const ReportTable = ({
  getPaginatedData,
  reportHeaders,
  currentPage,
  perPage,
}) => {
  return (
    <div>
      {getPaginatedData().length > 0 ? (
        <div
          className="px-3 pt-0 table-cards-1024 mt-3"
          style={{ overflowX: "scroll" }}
        >
          <table
            className="table table-hoverabletable-cards-1024"
            id="table-to-xls"
            style={{
              whiteSpace: "nowrap",
            }}
          >
            <thead>
              <tr>
                {reportHeaders.map((header) => (
                  <th>{header.name}</th>
                ))}
              </tr>
            </thead>
            {getPaginatedData().map((item, idx) => (
              <tbody>
                <tr>
                  <td>{++idx + (currentPage - 1) * perPage}</td>
                  <td>{moment(item.createddatetime).format("MM/DD/YY")}</td>
                  <td>{item.candidateName}</td>
                  <td>{!item.homePhone ? "NA" : item.homePhone}</td>
                  <td>{!item.email ? "NA" : item.email}</td>
                  <td>{!item.statusName ? "Added" : (item.statusName === "Rejected"?'Disqualified':item.statusName)}</td>
                  <td>{!item.jobTitle ? "NA" : item.jobTitle}</td>
                  <td>{!item.tenantname ? "NA" : item.tenantname}</td>
                  <td>{!item.recruiterName ? "NA" : item.recruiterName}</td>
                  <td>{!item?.country ? "NA" : item?.country}</td>
                </tr>
              </tbody>
            ))}
          </table>
        </div>
      ) : (
        <div className="mt-3" style={{ visibility: "hidden" }}>
          <div class="text-center p-5">
            <div className="avatar avatar-lg">
              <i aria-hidden="true"></i>
            </div>
            <div class="mt-2"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportTable;
