import {
    GET_JOB_DETAILS_BEGIN,
    GET_JOB_DETAILS_SUCCESS,
    GET_JOB_DETAILS_FAILURE,
  } from "../../actions/job/JobDetails";
  
  const initialState = {
    jobDetailsLoading: true,
    jobDetails: [],
  };
  
  const jobDetailsReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_JOB_DETAILS_BEGIN:
        return {
          ...state,
          jobDetailsLoading: true,
          jobDetails: [],
        };
      case GET_JOB_DETAILS_SUCCESS:
        return {
          ...state,
          jobDetails: payload,
          jobDetailsLoading: false,
        };
      case GET_JOB_DETAILS_FAILURE:
        return {
          ...state,
          jobDetails: [],
          jobDetailsLoading: false,
        };
      default:
        return state;
    }
  };
  
  export default jobDetailsReducer;
  