import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import EmailStatus from "../../../../EmailStatus/EmailStatus";
import { getRecruiterData } from "../../../../../../actions/recruiter/RecruiterList";
import Pagination from "../../../../commoncomponents/pagination/Pagination";

const RecruiterList = (props) => {
  const { jobData } = props;
  const dispatch = useDispatch();
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  // const [selectedRecruiter, setSelectedRecruiter] = useState({});
  const [paginationArray, setPaginationArray] = useState([]);
  const [dataSize, setDataSize] = useState(10);
  const [pageSize, setPageSize] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  const { recruiterList, loading } = useSelector(
    (state) => ({
      recruiterList: state.getRecruiterListReducer.recruiterList,
      loading: state.getRecruiterListReducer.loading,
    }),
    shallowEqual
  );

  // useEffect(async () => {
  //   dispatch(getRecruiterData({ jobId: jobData.jobId }));
  // }, []);

  return (
    <div className="mt-3">
      <div className="overflow">
        <table class="table table-sortable  table-hoverable">
          <thead>
            <tr>
              <th class="no-hover">
                <div class="d-flex align-items-center">Recruiter Name</div>
              </th>

              <th class="no-hover">
                <div class="d-flex align-items-center">Email</div>
              </th>
              <th class="no-hover">
                <div class="d-flex align-items-center">Email Status</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {paginationArray &&
              paginationArray?.map((item) => (
                <tr>
                  <td>
                    <span>{item.firstName + " " + item.lastName}</span>
                  </td>
                  <td>
                    <div>
                      <span>{item.email}</span>
                      &nbsp;
                    </div>
                  </td>
                  <td>
                    <div
                      className="d-inline-block"
                      data-bs-toggle="tooltip"
                      data-bs-placement="top"
                      title="View Email Status"
                    >
                      <button
                        type="button"
                        className="btn btn-square btn-gray"
                        onClick={() => {
                          setEmailData(item.email);
                          setEmailStatus(true);
                          //setSelectedRecruiter(item);
                        }}
                      >
                        <i class="fa fa-envelope-o" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {recruiterList && recruiterList.length === 0 && (
        <div>
          <div className="text-center p-3">
            <div className="avatar avatar-lg">
              <i class="fad fa-users-slash"></i>
            </div>
            <div className="mt-2">No records found</div>
          </div>
        </div>
      )}
      {recruiterList && recruiterList.length > 0 && (
        <Pagination
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setPaginationArray={setPaginationArray}
          data={recruiterList}
          dataSize={dataSize}
          getTab=""
          pageSize={pageSize}
        />
      )}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="RecruiterList"
          jobData={jobData}
          user="Recruiter"
        />
      )}
    </div>
  );
};

export default RecruiterList;
