import { toast } from "react-toastify";
import { post } from "../../service/ApiService";

export const GET_CHATGPT_RESPONSE_BEGIN = "GET_CHATGPT_RESPONSE_BEGIN";
export const GET_CHATGPT_RESPONSE_SUCCESS = "GET_CHATGPT_RESPONSE_SUCCESS";
export const GET_CHATGPT_RESPONSE_FAILURE = "GET_CHATGPT_RESPONSE_FAILURE";

export const getChatGptResponse = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_CHATGPT_RESPONSE_BEGIN,
    });
    const res = await post("/chatGpt/createJobDescription", params);
    if (res.status === 200) {
      dispatch({
        type: GET_CHATGPT_RESPONSE_SUCCESS,
        payload: res.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CHATGPT_RESPONSE_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};
