import React, { useEffect, useState } from "react";
import CandidateRequestDetails from "../../candidate/job/CandidateRequestDetails";
import moment from "moment";
import { toast } from "react-toastify";
import { ButtonLoader, countryObj } from "../../../constants/Constants";
import { get, post } from "../../../service/ApiService";
import { getCandidateName, getUserEmail } from "../../../service/AuthService";
import OutsideClickHandler from "react-outside-click-handler";
import Sort from "../../common/Sort";
import FilterJobs from "../job/FilterJobs";
import { domain } from "../../../constants/Constants";
import axios from "axios";

function CandidateTodos() {
  const [all, setAll] = useState(true);
  const [rtr, setRtr] = useState(false);
  const [rtrList, setRtrList] = useState([]);
  const [assessments, setAssessments] = useState(false);
  const [interviews, setInterviews] = useState(false);
  const [interviewDetails, setInterviewDetails] = useState([]);
  const [codingTests, setCodingTests] = useState([]);
  const [assessmentDetails, setAssessmentDetails] = useState([]);
  const [type, setType] = useState("");
  const [openJobPopUP, setOpenJobPopUp] = useState(false);
  const [jobDeatils, setJobDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rejectIndex, setRejectIndex] = useState("");
  const [loadingValue, setLoadingValue] = useState([]);
  const [jobListData, setJobListData] = useState([]);
  const [sortModal, setSortModal] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [matched, setMatched] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [RTR, setRTR] = useState([]);
  const [filteredRTR, setFilteredRTR] = useState([]);
  const [filter, setFilter] = useState(false);
  const [matchedTab, setMatchedTab] = useState(true);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [vetting, setVetting] = useState([]);
  const [vettingTest, setVettingTest] = useState([]);

  const sortObject = [
    {
      id: 1,
      label: "Job Title",
      value: "jobTitle",
      icon: "fal fa-fw fa-briefcase mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Job Type",
      value: "jobType",
      icon: "fal fa-fw fa-id-card-alt mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Location",
      value: "location.city",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },

    // {
    //     id: 4,
    //     label: "Preferred Start Date",
    //     value: "location.city",
    //     icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    // },
    // {
    //     id: 5,
    //     label: " New Candidates",
    //     value: "location.city",
    //     icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    // },
  ];

  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  const fetchVetting = async () => {
    setLoading(true);
    const res = await get(`/vetting/candidate/all`);
    // const res = await axios.get(
    //   "https://https://high5vettinguat-api.azurewebsites.net/candidate/all",
    //   {
    //     headers: { token: "df$rtu*om*xc:d11m05h5hzsAqrCh" },
    //   }
    // );
    setVetting(res.data);
    setLoading(false);
  };

  const fetchRTR = async () => {
    setLoading(true);
    try {
      let res = await post("/CandidateDashboard/fetchRTR", {
        filterValue: "Sourced",
      });
      setRtrList(res.data);
      setLoadingValue(new Array(res.data.length).map((_) => ""));
    } catch (err) {
      toast.error("Failed to load");
    }
    setLoading(false);
  };

  const getRequestList = async (automatch) => {
    setLoading(true);
    const params = {
      skip: 0,
      domain: domain,
    };
    try {
      const res = await post(`/requestlist/getallrequestslist`, params);
      const result = res.data.jobData
        .filter(
          (item) => ["open", "active"].includes(item.jobStatus) && item.isPublic
        )
        .sort((a, b) => {
          let fa = new Date(a.jobPostDate),
            fb = new Date(b.jobPostDate);
          if (fa > fb) {
            return -1;
          }
          if (fa < fb) {
            return 1;
          }
          return 0;
        });
      let finalResult = [...result];
      if (automatch.length > 0) {
        let ids = automatch.map((i) => i.jobId);
        finalResult = [...finalResult.filter((i) => !ids.includes(i.jobId))];
      }
      setJobListData(finalResult);
    } catch (error) {
      setLoading(false);
      toast.error(error);
    }
  };

  useEffect(() => {
    // fetchAssessments()
    fetchInterviews();
    fetchCodingTests();
    fetchRTR();
    fetchVetting();
    fetchVettingTests();
  }, []);

  const fetchAssessments = async () => {
    setLoading(true);
    try {
      const res = await get(`/CandidateDashboard/getModernHire`);
      setAssessmentDetails(
        res.data.filter((item) => item.Status !== "Completed")
      );
    } catch (err) {
      console.log(err);
      // toast.error('Failed to get Assessments')
    }
    setLoading(false);
  };

  const fetchCodingTests = async () => {
    setLoading(true);
    try {
      const res = await get(`/CandidateDashboard/getCodingTests`);
      setCodingTests(res.data.filter((item) => item.Status !== "Completed"));
    } catch (err) {
      console.log(err);
      // toast.error('Failed to get Assessments')
    }
    setLoading(false);
  };

  const fetchVettingTests = async () => {
    setLoading(true);
    try {
      const res = await get(`/CandidateDashboard/getVettingData`);
      setVettingTest(res.data.filter((item) => item.Status !== "Completed"));
    } catch (err) {
      console.log(err);
      // toast.error('Failed to get Assessments')
    }
    setLoading(false);
  };

  const fetchInterviews = async () => {
    setLoading(true);
    try {
      const res = await get(`/CandidateDashboard/getModernHireInterviews`);
      setInterviewDetails(
        res.data.filter((item) => item.Status !== "Completed")
      );
    } catch (err) {
      console.log(err);
      // toast.error('Failed to get Assessments')
    }
    setLoading(false);
  };

  const updateTab = (type) => {
    if (type === "all") {
      setAll(true);
      setRtr(false);
      setAssessments(false);
      setInterviews(false);
    }

    if (type === "rtr") {
      setAll(false);
      setRtr(true);
      setAssessments(false);
      setInterviews(false);
    }

    if (type === "assessments") {
      setAll(false);
      setRtr(false);
      setAssessments(true);
      setInterviews(false);
    }

    if (type === "interviews") {
      setAll(false);
      setRtr(false);
      setAssessments(false);
      setInterviews(true);
    }
  };

  const openPopUP = (item, type) => {
    setOpenJobPopUp(true);
    setJobDetails(item);
    setType(type);
  };

  const closePopUP = () => {
    setOpenJobPopUp(false);
  };
  const applySort = (sortedData, close) => {
    close && setSortModal(false);
    setFiltered(sortedData);
  };

  const applyFilter = (selectedTitle, selectedType) => {
    setSortArray([]);
    setSortCount(0);
    setSelectedTitles(selectedTitle);
    setSelectedTypes(selectedType);
    if (selectedTitle.length > 0 && selectedType.length > 0) {
      setFiltered(
        matched.filter(
          (job) =>
            selectedTitle.includes(job.jobTitle) &&
            selectedType.includes(job.jobType)
        )
      );
      setFilteredRTR(
        RTR.filter(
          (job) =>
            selectedTitle.includes(job.jobTitle) &&
            selectedType.includes(job.jobType)
        )
      );
    } else if (selectedTitle.length > 0 && selectedType.length === 0) {
      setFiltered(
        matched.filter((job) => selectedTitle.includes(job.jobTitle))
      );
      setFilteredRTR(RTR.filter((job) => selectedTitle.includes(job.jobTitle)));
    } else if (selectedType.length > 0 && selectedTitle.length === 0) {
      setFiltered(matched.filter((job) => selectedType.includes(job.jobType)));
      setFilteredRTR(RTR.filter((job) => selectedType.includes(job.jobType)));
    } else {
      setFiltered(matched);
      setFilteredRTR(RTR);
    }
    setFilter(false);
  };

  const acceptRTR = (details, index) => {
    setLoading(true);
    const submit = async () => {
      setRejectIndex(index);
      const {
        mapperId,
        candidateId,
        candidateLocation,
        candidateFname,
        candidateLname,
        candidateEmail,
        recruiterTenant,
        recruiterEmail,
        recruiterName,
        jobId,
        jobTitle,
        jobTenant,
        location,
        isRemote,
        jobType,
        minimumPay,
        maximumPay,
        salaryCurrency,
        salaryType,
        prefferedStartDate,
      } = details;
      const payload = {
        mapperId,
        candidateData: {
          candidateId,
          candidateLocation,
          firstName: candidateFname,
          candidateFname,
          candidateLname,
          candidateEmail,
        },
        recruiterData: {
          recruiterTenant,
          recruiterEmail,
          firstName: recruiterName.split(" ")[0],
          lastName: recruiterName.split(" ")[1],
          recruiterName: getCandidateName(),
        },
        jobData: {
          jobId,
          jobTitle,
          jobTenant,
          location,
          isRemote,
          jobType,
          minimumPay,
          maximumPay,
          salaryCurrency,
          salaryType,
          prefferedStartDate,
        },
      };
      const res = await post("/AcceptRejectRtr/rtrApproveCandidate", payload);
      if (res.status === 200) {
        toast.success("Accepted Successfully");
        setTimeout(() => {
          fetchRTR();
        }, 5000);
      } else {
        toast.error("Some Error Occurred. Please Try Again");
      }
    };
    let final = vetting.filter(
      (item) =>
        item.high5hireCandidateId === details.jobId &&
        item.email === getUserEmail()
    );
    if (final.length > 0) {
      if (final.map((i) => i.testCompleted).some((i) => i === true)) {
        submit();
      } else {
        let temp = final.map((i) => i.testAssign.skills[0]);
        toast.error(
          `Vetting Pending - ${temp.length > 1 ? temp.join(", ") : temp[0]}`
        );
      }
    } else {
      submit();
    }
    setLoading(false);
  };

  const rejectRTR = async (details, index) => {
    setRejectIndex(index);
    const {
      mapperId,
      candidateId,
      candidateLocation,
      candidateFname,
      candidateLname,
      candidateEmail,
      recruiterTenant,
      recruiterEmail,
      recruiterName,
      jobId,
      jobTitle,
      jobTenant,
      location,
      isRemote,
      jobType,
      minimumPay,
      maximumPay,
      salaryCurrency,
      salaryType,
      prefferedStartDate,
    } = details;
    const payload = {
      mapperId,
      candidateData: {
        candidateId,
        candidateLocation,
        firstName: candidateFname,
        candidateFname,
        candidateLname,
        candidateEmail,
      },
      recruiterData: {
        recruiterTenant,
        recruiterEmail,
        firstName: recruiterName.split(" ")[0],
        lastName: recruiterName.split(" ")[1],
      },
      jobData: {
        jobId,
        jobTitle,
        jobTenant,
        location,
        isRemote,
        jobType,
        minimumPay,
        maximumPay,
        salaryCurrency,
        salaryType,
        prefferedStartDate,
      },
    };
    const res = await post("/AcceptRejectRtr/rtrRejectCandidate", payload);
    if (res.status === 200) {
      toast.success("Rejected Successfully");
      setTimeout(() => {
        fetchRTR();
      }, 5000);
    } else {
      toast.error("Some Error Occurred. Please Try Again");
    }
  };

  return (
    <>
      <div id="CandidateRequests" className="col-xxl-4 mb-2">
        <div className="card">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <label className="pt-0 mb-2">
                  <strong>My TO DOs</strong>
                </label>
              </div>
              {/* <div className="d-flex align-items-center justify-content-center justify-content-lg-start">
                                <div className="mb-2 d-flex align-items-center justify-content-center">
                                    <div className="d-flex justify-content-end w-100">
                                        <div className="dropdown-sort dd-filter mx-1">
                                            <button type="button" className="btn btn-sm w-auto btn-text-accent dd-filter">
                                                <i aria-hidden="true" className="fas fa-filter font-14 dd-filter"></i> 
                                                <span className="dd-filter d-none-1024">Filter</span> /
                                                <span className="dd-filter"> 0</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
              <div class="d-flex mb-2 align-items-center justify-content-center justify-content-even-400">
                <div class="d-flex align-items-center justify-content-end w-100 justify-content-even-400">
                  {/* <div class="dropdown-sort dd-filter mx-1">
                                           
                                        <button
                                            type="button"
                                            className="btn btn-sm btn-text-accent"
                                            onClick={() => setSortModal(true)}
                                        >
                                            <i className="fad fa-sort-down font-16 mr-1"></i>{" "}
                                            Sort / {sortCount}
                                        </button>
                                        { sortModal &&
                                            <OutsideClickHandler
                                                onOutsideClick={() => setSortModal(false)}
                                            >
                                                <Sort
                                                    sortData={jobListData}
                                                    orderArray={orderList}
                                                    sortObjects={sortObject}
                                                    applySort={applySort}
                                                    setSortCount={setSortCount}
                                                    sortCount={sortCount}
                                                    setSortArray={setSortArray}
                                                    sortArray={sortArray}
                                                    sortingFor={
                                                    "Sort by Job Title, Type and Location of Requests"
                                                    }
                                                />  
                                            </OutsideClickHandler>                                               
                                        }                      
                                    </div> */}
                  {/* <div class="dropdown position-relative">
                              
                              <button
                                data-bs-toggle="tooltip"
                                data-bs-placement="left"
                                title="Filter"
                                onClick={() => {         
                                  setFilter(true)
                                  setMatchedTab(true)
                                }}
                                type="button"
                                class="btn btn-sm btn-text-accent w-auto"
                              >
                                <i class="fas fa-filter"></i>
                                <span>Filter</span> /<span>{ selectedTitles.length + selectedTypes.length }</span>
                              </button>
                            </div> */}
                </div>
              </div>
            </div>
            <hr className="m-0" />

            <div className="d-flex-column d-block-1200 d-lg-flex flex-lg-row align-items-end mb-3 border-bottom-gray2">
              <ul className="nav nav-tabs nav-tabs-tablet pt-1 w-100 justify-content-center">
                <li className="nav-item">
                  <button
                    type="button"
                    onClick={() => {
                      updateTab("all");
                    }}
                    className={
                      all
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                  >
                    <span className="tag mb-1">
                      {rtrList.length +
                        assessmentDetails.length +
                        codingTests.length +
                        interviewDetails.length +
                        vettingTest.length}
                    </span>
                    <div>All</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    onClick={() => {
                      updateTab("rtr");
                    }}
                    className={
                      rtr
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                  >
                    <span className="tag mb-1">
                      {rtrList.length}
                      {rtrList.length > 0 && <span className="jewel"></span>}
                    </span>
                    <div>Approval</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    onClick={() => {
                      updateTab("assessments");
                    }}
                    className={
                      assessments
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                  >
                    <span className="tag mb-1">
                      {assessmentDetails.length +
                        codingTests.length +
                        vettingTest.length}
                      {assessmentDetails.length +
                        codingTests.length +
                        vettingTest.length >
                        0 && <span className="jewel"></span>}
                    </span>
                    <div>Assessments</div>
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    type="button"
                    onClick={() => {
                      updateTab("interviews");
                    }}
                    className={
                      interviews
                        ? "nav-link pb-3 mx-2 bg-transparent active"
                        : "nav-link pb-3 mx-2 bg-transparent"
                    }
                  >
                    <span className="tag mb-1">
                      {interviewDetails.length}
                      {interviewDetails.length > 0 && (
                        <span className="jewel"></span>
                      )}
                    </span>
                    <div>Upcoming Interviews</div>
                  </button>
                </li>
              </ul>
            </div>
            {!loading ? (
              <div className="d-block-force d-flex-1200">
                {rtrList.length +
                  assessmentDetails.length +
                  codingTests.length +
                  interviewDetails.length ===
                  0 &&
                  all && (
                    <div>
                      <div className="text-center p-3">
                        <div className="avatar avatar-lg">
                          <i className="fad fa-comment-slash"></i>
                        </div>
                        <div className="mt-2">No records found</div>
                      </div>
                    </div>
                  )}
                {(all || rtr) &&
                  (rtrList.length > 0 ? (
                    <div className="d-block-force w-100">
                      <div className="">
                        {rtrList.map((item, index) => (
                          <>
                            <div className="card card-flat w-100 mb-1">
                              <div className="card-body">
                                <a
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    openPopUP(item, "rtr");
                                  }}
                                >
                                  <div>
                                    <div>
                                      <span className="tag tag-coral mr-1">
                                        Approval
                                      </span>
                                      <span className="tag tag-green3">
                                        New
                                      </span>
                                    </div>
                                    <h6 className="mt-2">{item.jobTitle}</h6>
                                  </div>
                                  <div className="d-flex align-items-center">
                                    <span className="d-flex align-items-center me-3">
                                      <i
                                        aria-hidden="true"
                                        className="far fa-suitcase me-2 font-brandBlue"
                                      ></i>
                                      <span className="font-regular font-primary font-gray1">
                                        {item.jobType}
                                      </span>
                                    </span>
                                    {item.fullText.location?.city &&
                                    item.fullText.location?.state &&
                                    item.fullText.location?.country ? (
                                      <span className="d-flex align-items-center me-3">
                                        <i
                                          aria-hidden="true"
                                          className="far fa-map-marker-alt me-2 font-brandBlue"
                                        ></i>
                                        <span className="font-regular font-primary font-gray1">{`${item.fullText.location.city}, ${item.fullText.location.state}, ${item.fullText.location?.country}`}</span>
                                      </span>
                                    ) : (
                                      <>
                                        <i
                                          aria-hidden="true"
                                          className="far fa-map-marker-alt me-2 font-brandBlue"
                                        ></i>
                                        <span className="font-regular font-primary font-gray1 me-2">
                                          {"Remote"}
                                        </span>
                                      </>
                                    )}
                                    {item.payRate && (
                                      <span className="d-flex align-items-center me-3">
                                        <i
                                          aria-hidden="true"
                                          className="far fa-piggy-bank me-2 font-brandBlue"
                                        ></i>
                                        <span className="font-regular font-primary font-gray1">
                                          {item.payRate +
                                            " " +
                                            item.payRateCurrency}
                                        </span>
                                      </span>
                                    )}
                                  </div>
                                  <div className="mt-1">
                                    <small className="font-regular">
                                      {moment(
                                        new Date(item.sourcedDate),
                                        "YYYYMMDD"
                                      ).fromNow()}{" "}
                                      By {item.sourcedBy} @
                                      {item.recruiterTenant}
                                    </small>
                                  </div>
                                </a>
                                <hr className="mb-2" />
                                <div className="ml-auto">
                                  <button
                                    type="button"
                                    className="btn btn-sm btn-blue-disabled mr-1"
                                    onClick={() => acceptRTR(item, index)}
                                    disabled={rejectIndex === index}
                                  >
                                    <span>
                                      {rejectIndex === index
                                        ? "Accepting"
                                        : "Accept"}{" "}
                                    </span>
                                    {rejectIndex === index && (
                                      <img
                                        width="20px"
                                        src={ButtonLoader}
                                        alt="loading"
                                      />
                                    )}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-secondary btn-sm btn-blue-disabled"
                                    onClick={() => rejectRTR(item, index)}
                                    disabled={rejectIndex === index}
                                  >
                                    <span>
                                      {(rejectIndex === index) === "Rejecting"
                                        ? "Rejecting"
                                        : "Reject"}{" "}
                                    </span>
                                    {(rejectIndex === index) ===
                                      "Rejecting" && (
                                      <img
                                        src={ButtonLoader}
                                        width="20px"
                                        alt="loading"
                                      />
                                    )}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </div>
                  ) : (
                    !all && (
                      <div>
                        <div className="text-center p-3">
                          <div className="avatar avatar-lg">
                            <i className="fad fa-comment-slash"></i>
                          </div>
                          <div className="mt-2">
                            No Approval Found at this time
                          </div>
                        </div>
                      </div>
                    )
                  ))}
                {(all || assessments) &&
                  (assessmentDetails.length > 0 ||
                  codingTests.length > 0 ||
                  vettingTest.length > 0 ? (
                    <div>
                      {assessmentDetails.map((item, index) => (
                        <div className="d-flex-force w-100 mb-1">
                          <div
                            // onClick={() =>{openPopUP(item,item.status)}}
                            className="card card-flat w-100"
                          >
                            <div className="card-body">
                              <div>
                                <div>
                                  <div>
                                    <span className="tag tag-orange1 mr-1">
                                      Assessment
                                    </span>
                                  </div>
                                  <h6 className="mt-2">{item.reqTitle}</h6>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="d-flex align-items-center me-3">
                                    <i
                                      aria-hidden="true"
                                      className="far fa-suitcase me-2 font-brandBlue"
                                    ></i>
                                    <span className="font-regular font-primary font-gray1">
                                      {item.AssessmentType}
                                    </span>
                                  </span>
                                </div>
                                <div className="mt-1">
                                  <small className="font-regular">
                                    {moment(
                                      new Date(item.created_date),
                                      "YYYYMMDD"
                                    ).format("MM/DD/YYYY")}{" "}
                                    By{" "}
                                    {item.RecruiterFname +
                                      " " +
                                      item.RecruiterLname}
                                  </small>
                                </div>
                                <div>
                                  {/* <p className="mb-1">
                                                                            <small>Progress</small>
                                                                            <p><strong>{ item.status === 'Open' ? 'Not Yet Started' : item.status}</strong></p>
                                                                        </p>       
                                                                    <div className="alert-progress alert-progress-static bg-gray3">
                                                                        <div className="alert-progress-bar" style={{ "width": "0%" }}></div>
                                                                    </div> */}
                                </div>
                              </div>
                              <div className="mt-2">
                                <button
                                  type="button"
                                  className="btn btn-sm mr-1"
                                  onClick={() =>
                                    window.open(item.InterviewUrl, "_blank")
                                  }
                                >
                                  <span>Start</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {codingTests.map((item, index) => (
                        <div className="d-flex-force w-100 mb-1">
                          <div
                            // onClick={() =>{openPopUP(item,item.status)}}
                            className="card card-flat w-100"
                          >
                            <div className="card-body">
                              <div>
                                <div>
                                  <div>
                                    <span className="tag tag-orange1 mr-1">
                                      Assessment
                                    </span>
                                  </div>
                                  <h6 className="mt-2">{item.reqNumber}</h6>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="d-flex align-items-center me-3">
                                    <i
                                      aria-hidden="true"
                                      className="far fa-suitcase me-2 font-brandBlue"
                                    ></i>
                                    <span className="font-regular font-primary font-gray1">
                                      Coding Test
                                    </span>
                                  </span>
                                </div>
                                <div className="mt-1">
                                  <small className="font-regular">
                                    {moment(
                                      new Date(item.created_date),
                                      "YYYYMMDD"
                                    ).format("MM/DD/YYYY")}{" "}
                                    By{" "}
                                    {item.RecruiterFname +
                                      " " +
                                      item.RecruiterLname}
                                  </small>
                                </div>
                                <div>
                                  {/* <p className="mb-1">
                                                                            <small>Progress</small>
                                                                            <p><strong>{ item.status === 'Open' ? 'Not Yet Started' : item.status}</strong></p>
                                                                        </p>       
                                                                    <div className="alert-progress alert-progress-static bg-gray3">
                                                                        <div className="alert-progress-bar" style={{ "width": "0%" }}></div>
                                                                    </div> */}
                                </div>
                              </div>
                              <div className="mt-2">
                                <button
                                  type="button"
                                  className="btn btn-sm mr-1"
                                  onClick={() =>
                                    window.open(item.InvitationUrl, "_blank")
                                  }
                                >
                                  <span>Start</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                      {vettingTest.map((item, index) => (
                        <div className="d-flex-force w-100 mb-1">
                          <div
                            // onClick={() =>{openPopUP(item,item.status)}}
                            className="card card-flat w-100"
                          >
                            <div className="card-body">
                              <div>
                                <div>
                                  <div>
                                    <span className="tag tag-orange1 mr-1">
                                      Assessment
                                    </span>
                                  </div>
                                  <h6 className="mt-2">{item.testName}</h6>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="d-flex align-items-center me-3">
                                    <i
                                      aria-hidden="true"
                                      className="far fa-suitcase me-2 font-brandBlue"
                                    ></i>
                                    <span className="font-regular font-primary font-gray1">
                                      {item.interviewType}
                                    </span>
                                  </span>
                                </div>
                                <div className="mt-1">
                                  <small className="font-regular">
                                    {moment(
                                      new Date(item.created_date),
                                      "YYYYMMDD"
                                    ).format("MM/DD/YYYY")}{" "}
                                    By{" "}
                                    {item.recruiterFirstName +
                                      " " +
                                      item.recruiterLastName}
                                  </small>
                                </div>
                                <div>
                                  <p className="mb-1">
                                    <p>
                                      Unique Key:{" "}
                                      <strong>{item.uniqueCode}</strong>
                                    </p>
                                  </p>
                                </div>
                              </div>
                              <div className="mt-2">
                                <button
                                  type="button"
                                  className="btn btn-sm mr-1"
                                  onClick={() =>
                                    window.open(
                                      "https://vetuat.high5hire.app/candidate",
                                      "_blank"
                                    )
                                  }
                                >
                                  <span>Start</span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : (
                    !all && (
                      <div>
                        <div className="text-center p-3">
                          <div className="avatar avatar-lg">
                            <i className="fad fa-comment-slash"></i>
                          </div>
                          <div className="mt-2">
                            No Assessments Found at this time
                          </div>
                        </div>
                      </div>
                    )
                  ))}
                {(all || interviews) &&
                  (interviewDetails.length > 0 ? (
                    <div className="">
                      {interviewDetails.map((item) => (
                        <a
                          // onClick={ () => openInterviewDetails(item) }
                          className="card card-flat w-100 mb-1"
                        >
                          <div className="card-body">
                            <div>
                              <div className="d-flex justify-content-between align-items-center">
                                <p className="mb-1">
                                  <strong>{item.reqTitle}</strong>
                                </p>
                              </div>
                              <div className="d-flex align-items-center my-1">
                                <span className="d-flex align-items-center me-3">
                                  <i
                                    aria-hidden="true"
                                    className="far fa-suitcase me-2 font-brandBlue"
                                  ></i>
                                  <span className="font-regular font-primary font-gray1">
                                    {item.AssessmentType}
                                  </span>
                                </span>
                              </div>
                              <div>
                                {/* <div className="mb-1">
                                                                        <span className="font-regular font-primary font-gray1">{item.location}</span>
                                                                    </div> */}
                                <div className="mb-1">
                                  <span className="font-regular">
                                    Scheduled On:{" "}
                                    <strong>
                                      {new Date(
                                        item.created_date
                                      ).toLocaleString()}
                                    </strong>
                                  </span>
                                </div>
                                <div className="mb-1">
                                  <span className="font-regular">
                                    Assigned By:{" "}
                                    <strong>
                                      {item.RecruiterFname +
                                        " " +
                                        item.RecruiterLname}
                                    </strong>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </a>
                      ))}
                    </div>
                  ) : (
                    !all && (
                      <div>
                        <div className="text-center p-3">
                          <div className="avatar avatar-lg">
                            <i className="fad fa-comment-slash"></i>
                          </div>
                          <div className="mt-2">
                            No Inteviews Scheduled at this time
                          </div>
                        </div>
                      </div>
                    )
                  ))}
              </div>
            ) : (
              <h6 className="m-5 text-center">Fetching Data...</h6>
            )}
          </div>
        </div>
        {openJobPopUP && (
          <CandidateRequestDetails
            type={type}
            job={jobDeatils}
            closeModal={closePopUP}
          />
        )}
      </div>
    </>
  );
}

export default CandidateTodos;
