import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import Avatar from "react-avatar";
import moment from "moment";
import { post } from "../../../../../../service/ApiService";
import Loader from "../../../../../Loader/Loader";
import { emailDomain } from "../../../../../../constants/Constants";
import { getUserName } from "../../../../../../service/AuthService";

function MakeAnOfferPopup(props) {
  const {
    jobData,
    makeAnOfferPopup,
    setMakeAnOfferPopup,
    selectedCandidate,
  } = props;

  const [submitClicked, setSubmitClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    expectedJoiningDate: new Date().setDate(new Date().getDate() + 30),
    salaryOffered: jobData.annualSalary,
    currencyType: jobData.placementCurrency,
    paymentType: jobData.jobType == "Full Time" ? "Annual" : "Per Hour",
  });
  const [errors, setError] = useState({});

  let curDate = new Date();

  const closeModal = () => {
    setMakeAnOfferPopup(false);
  };

  const handleChange = (e) => {
    if (e.target.name === "salaryOffered")
      setFormData({ ...formData, [e.target.name]: e.target.value.slice(0, 7) });
    else setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const isValidFunction = () => {
    let errorData = {};
    let formIsValid = true;

    if (formData.salaryOffered === "" || formData.salaryOffered == null) {
      errorData["salaryOffered"] = "Field cannot be empty";
      formIsValid = false;
    }
    if (formData.salaryOffered !== "" || formData.salaryOffered != null) {
      if (formData.salaryOffered > 1000000) {
        errorData["salaryOffered"] = "Maximum salary is 1000000";
        formIsValid = false;
      }
    }
    if (
      formData.expectedJoiningDate === "" ||
      formData.expectedJoiningDate === null
    ) {
      errorData["expectedJoiningDate"] = "Please select a date";
      formIsValid = false;
    }
    setError(errorData);
    return formIsValid;
  };

  const SMS = async () => {
    try {
      const phoneNumber =
        getPhone(
          props.selectedCandidate?.homePhone,
          props.selectedCandidate?.homePhoneCode
        ) ||
        getPhone(
          props.selectedCandidate?.workPhone,
          props.selectedCandidate?.workPhoneCode
        );

      if (!phoneNumber) return;

      const name = props.selectedCandidate?.firstName;
      const jobName = jobData.jobTitle;

      const getContent = async (event, recipient) => {
        try {
          const response = await post("/msgtwoway/getSmsContent", {
            event,
            recipient: JSON.stringify(recipient),
          });

          if (response.status === 200) {
            return response.data[0];
          }
          return "";
        } catch (error) {
          console.error("Error fetching content:", error.message);
          return "";
        }
      };

      const offeredContent = await getContent("Make an Offer/Re-Offer", [
        "Candidate",
      ]);

      if (offeredContent && Object.keys(offeredContent).length > 0) {
        let content = offeredContent.smsContent;
        content = content.replace("[First Name]", name);
        content = content.replace("[Job Title]", jobName);
        content = content.replace(
          "[CareerPageLinkToLogin]",
          `${emailDomain}/careers/jobList`
        );

        const payload = [
          {
            toUserName: name,
            userId: Math.floor(Math.random() * 1000000),
            recieverNumber: [phoneNumber.replace("+", "")],
            message: content,
          },
        ];

        const payloadTelSms = [
          {
            recieverNumber: [phoneNumber],
            message: content,
          },
        ];

        if (phoneNumber.includes("+1") && payloadTelSms.length > 0) {
          await post("/msgtwoway/telSms", payloadTelSms);
        } else {
          await post("/massages/sentMassageWhatsapp", payload);
        }
      }
    } catch (error) {
      console.error("An error occurred", error);
      toast.error("An error occurred");
    }
  };

  const SMS2 = async () => {
    try {
      let name = props.selectedCandidate.firstName;
      let jobName = jobData.jobTitle;
      let jobId = jobData.jobId;

      setLoading(true);

      const tenant = {
        tenant: JSON.parse(localStorage.getItem("userToken"))?.tenantid,
        createdBy: props.selectedCandidate.createdBy,
      };

      const numbers = await post("/msgtwoway/getAllNumbers", tenant);
      const data = numbers.data;

      if (data.length === 0) return;

      const getContent = async (event, recipient) => {
        try {
          const response = await post("/msgtwoway/getSmsContent", {
            event,
            recipient: JSON.stringify(recipient),
          });

          if (response.status === 200) {
            return response.data[0];
          }
          return "";
        } catch (error) {
          console.error("Error fetching content:", error);
          return "";
        }
      };

      const smsContentRec = await getContent("Make an Offer/Re-Offer", [
        "Recruiter",
      ]);
      const smsContentHM = await getContent("Make an Offer/Re-Offer", [
        "Hiring Manager",
      ]);
      const smsContentCSM = await getContent("Make an Offer/Re-Offer", [
        "Customer Success Manager",
      ]);
      const smsContentMPM = await getContent("Make an Offer/Re-Offer", [
        "Marketplace Manager",
      ]);

      const recepientsDataSms = [];
      const recepientsDataWhatsApp = [];

      const addRecipientData = (obj, role, smsContent) => {
        const recepientDataSms = [];
        const recepientDataWhatsApp = [];

        let home = obj.homePhone ? obj.homePhone : "";
        let mobile = obj.mobilePhone ? obj.mobilePhone : "";
        let work = obj.workPhone ? obj.workPhone : "";

        const addPhoneNumber = (number, array) => {
          if (number.charAt(0) !== "+" && number.length > 10) {
            array.push(`+${number}`);
          }

          if (number.charAt(0) === "+" && number.charAt(1) === "1") {
            array.push(number.replace(/ /g, ""));
          }
        };

        addPhoneNumber(home, recepientDataWhatsApp);
        addPhoneNumber(mobile, recepientDataWhatsApp);
        addPhoneNumber(work, recepientDataWhatsApp);
        addPhoneNumber(home, recepientDataSms);
        addPhoneNumber(mobile, recepientDataSms);
        addPhoneNumber(work, recepientDataSms);

        if (recepientDataSms.length > 0) {
          let content = smsContent;
          content = content.replace("[FirstName]", obj.firstName);
          content = content.replace("[CandidateName]", name);
          content = content.replace("[JobTitle]", jobName);
          content = content.replace("[CompanyName]", "");
          content = content.replace("[JobID]", jobId);

          recepientsDataSms.push({
            recieverNumber: recepientDataSms,
            userId: Math.floor(Math.random() * 1000000),
            message: content,
          });
        }

        if (recepientDataWhatsApp.length > 0) {
          recepientsDataWhatsApp.push({
            toUserName: name,
            userId: Math.floor(Math.random() * 1000000),
            recieverNumber: recepientDataWhatsApp,
            message: `Hi ${
              obj.firstName
            }, Just letting you know ${name} has been ${
              selectedCandidate?.disqualifyComments?.includes(
                "Offer Rejected :"
              )
                ? "reoffered"
                : "offered"
            } the ${jobName} job.\nClick below to see details.\n${emailDomain}/high5Hire/requests/${jobId}\nThe High5 Team`,
          });
        }
      };

      data.forEach((obj) => {
        if (
          obj.fk_Role === 3 &&
          smsContentRec &&
          Object.keys(smsContentRec).length > 0
        ) {
          addRecipientData(obj, "Recruiter", smsContentRec.smsContent);
        }
        if (
          obj.fk_Role === 4 &&
          smsContentHM &&
          Object.keys(smsContentHM).length > 0
        ) {
          addRecipientData(obj, "Hiring Manager", smsContentHM.smsContent);
        }
        if (
          obj.fk_Role === 8 &&
          smsContentCSM &&
          Object.keys(smsContentCSM).length > 0
        ) {
          addRecipientData(
            obj,
            "Customer Success Manager",
            smsContentCSM.smsContent
          );
        }
        if (
          obj.fk_Role === 9 &&
          smsContentMPM &&
          Object.keys(smsContentMPM).length > 0
        ) {
          addRecipientData(
            obj,
            "Marketplace Manager",
            smsContentMPM.smsContent
          );
        }
      });

      if (recepientsDataSms.length > 0) {
        await post("/msgtwoway/telSms", recepientsDataSms);
      }

      if (recepientsDataWhatsApp.length > 0) {
        await post("/massages/sentMassageWhatsapp", recepientsDataWhatsApp);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("An error occurred");
    } finally {
      setLoading(false);
    }
  };
  const submitMakeAnOffer = async (e) => {
    setLoading(true);
    const isValid = isValidFunction();

    if (isValid) {
      try {
        let path = `/careers/profile/?tab=Offers`;
        let candidateProfileLink = `${window.location.protocol}//${window.location.host}${path}`;
        const payload = {
          status: 37,
          id_disposition: selectedCandidate.id_disposition,
          joiningDate: moment(formData.expectedJoiningDate).format(
            "YYYY-MM-DD"
          ),
          offeredSalary: formData.salaryOffered,
          offeredCurrency: formData.currencyType,
          domainName: candidateProfileLink,
          jobId: jobData.jobId,
          jobTitle: jobData.jobTitle,
          candidateEmail: selectedCandidate.email,
          candidatefName: selectedCandidate.firstName,
          candidatelName: selectedCandidate.lastName,
          recruiterFName: selectedCandidate.recruiterFname,
          recruiterId: selectedCandidate.recruiterId,
          recruiterLName: selectedCandidate.recruiterLname,
          clientName: JSON.parse(localStorage.getItem("userToken"))?.userTenant,
          createdBy: props.selectedCandidate.createdBy,
        };

        const res = await post("/disposition/updatedisposition", payload);
        if (res.status === 200) {
          await SMS();
          await SMS2();
          toast.success("Job offered successfully");
          //setLoading(false);
          props.reloadDisposition();
          window.location.reload();
        }
      } catch (error) {
        setLoading(false);
        console.log(error, "err");
        toast.error("Oops! something went wrong. please try again later");
      }
    }
  };
  const getPhone = (phone, phoneCode) => {
    if (phoneCode) {
      let actualPhone = phone.replace("+", "");
      let actualPhoneCode = phoneCode.replace("+", "");
      let phoneNumber = "";
      if (actualPhone.substring(0, actualPhoneCode.length) == actualPhoneCode) {
        phoneNumber = phone
          .replace("+", "")
          .slice(phoneCode.replace("+", "").length);
      } else {
        phoneNumber = phone.replace("+", "");
      }
      return "+" + actualPhoneCode + phoneNumber;
    } else {
      let phoneNumber = phone.replace("+", "");
      return `+1${phoneNumber}`;
    }
  };
  return (
    <>
      {loading && <Loader />}
      <Modal
        size="lg"
        show={makeAnOfferPopup}
        aria-labelledby="example-modal-sizes-title-lg"
        dialogClassName="interviewPopUpModal"
      >
        <Modal.Body>
          <div className="bl-modal-header">
            <h6 className="bl-modal-title">
              {props.isFrom === "DisqualifiedCandidates" &&
              selectedCandidate.dispositionStatusName === "Offer Rejected"
                ? "Re-Offer"
                : selectedCandidate.prevStatus == "37"
                ? "Re-Offer"
                : "Make an Offer"}
            </h6>
            <button
              type="button"
              onClick={closeModal}
              className="close p-0 bl-modal-close-btn"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div>
            <div className="text-center">
              <span>
                <Avatar
                  className="mb-1"
                  size="55"
                  name={
                    selectedCandidate?.firstName +
                    " " +
                    selectedCandidate?.lastName
                  }
                  round="90px"
                />
              </span>
            </div>
          </div>
          <div>
            <div className="text-center">
              <h6 className="mt-3">
                {selectedCandidate?.firstName} {selectedCandidate?.lastName}{" "}
              </h6>
              <p>
                {jobData.isRemote === true ? (
                  <span className="mb-1 mr-1">Remote</span>
                ) : (
                  <span className="mb-1 mr-1">{props.jobData.city}</span>
                )}{" "}
                /<span className="mb-1 mr-1">{} </span>
                {<span className="mb-1 mr-1">{jobData.tenantName}</span>} /
                <span className="mb-1 mr-1">{} </span>
                {<span className="mb-1 mr-1">{jobData.jobType}</span>}
              </p>
            </div>
          </div>

          <div style={{ padding: "1px 2px 35px 0px" }}></div>

          <div v-if="showMakeAnOfferForm">
            <div className="bl-modal-body pt-0 makeAnOfferPopUp">
              <p className="text-center">
                <strong>
                  Please confirm that you wish to submit an offer to this
                  candidate.
                </strong>
              </p>
            </div>
            <div className="card card-flat bg-gray4 mb-5 ml-2  mr-2">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="form-floating form-date">
                      <label>Currency</label>
                      <input
                        type="text"
                        className="form-control"
                        name="currencyType"
                        defaultValue={formData.currencyType}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-floating form-date">
                      <label>Offered Salary*</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Enter the salary"
                        name="salaryOffered"
                        onChange={handleChange}
                        value={formData.salaryOffered}
                      />
                      <div className="color">{errors["salaryOffered"]}</div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-floating form-date">
                      <label>Payment Type</label>
                      <input
                        type="text"
                        className="form-control"
                        name="salaryType"
                        defaultValue={formData.paymentType}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-floating form-date">
                      <label>Joining Date*</label>
                      <input
                        type="date"
                        name="expectedJoiningDate"
                        value={moment(formData.expectedJoiningDate).format(
                          "YYYY-MM-DD"
                        )}
                        onChange={handleChange}
                        className="form-control"
                        min={moment(new Date()).format("YYYY-MM-DD")}
                        max={moment(
                          new Date(curDate.setDate(curDate.getDate() + 90))
                        ).format("YYYY-MM-DD")}
                      />

                      <div className="color">
                        {errors["expectedJoiningDate"]}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-floating form-date">
                      <label>Gig Recruiter/Recruitment Firm</label>
                      <input
                        type="text"
                        className="form-control"
                        name="recruiterName"
                        defaultValue={selectedCandidate?.tenantName.replace(
                          "GIG - ",
                          ""
                        )}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary btn-sm"
                data-dismiss="modal"
                onClick={closeModal}
                disabled={submitClicked}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary btn-sm">
                <span onClick={submitMakeAnOffer}>Submit Offer</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default MakeAnOfferPopup;
