import {
  GET_TENANT_LOGO_BEGIN,
  GET_TENANT_LOGO_SUCCESS,
  GET_TENANT_LOGO_FAILURE,
} from "../../actions/tenant/GetTenantLogo";

const initialState = {
  logoLoading: true,
  tenantLogo: null,
};

const getTenantLogoReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case GET_TENANT_LOGO_BEGIN:
      return {
        ...state,
        logoLoading: true,
        tenantLogo: null,
      };
    case GET_TENANT_LOGO_SUCCESS:
      return {
        ...state,
        tenantLogo: payload,
        logoLoading: false,
      };
    case GET_TENANT_LOGO_FAILURE:
      return {
        ...state,
        tenantLogo: null,
        logoLoading: false,
      };
    default:
      return state;
  }
};

export default getTenantLogoReducer;
