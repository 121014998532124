import React, { useState } from "react";
import { toast } from "react-toastify";
import { ButtonLoader } from "../../../../../constants/Constants";
import { post } from "../../../../../service/ApiService";

import { monthNames } from "../../../../../constants/Constants";
import moment from "moment";

function Experience({
  data,
  getData,
  setFlag,
  flag,
  updateDetails,
  setUpdateDetails,
}) {
  var today = new Date();
  const [readOnlyMode, setReadOnlyMode] = useState(true);
  const [submittingForm, setSubmittingFrom] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [saveClicked, setSaveClicked] = useState(true);
  const [experience, setExperience] = useState(
    data?.experiences
      ? JSON.parse(data?.experiences)?.filter(
          (experience) => experience.employerName !== ""
        )
      : []
  );

  const [tempExperience, setTempExperience] = useState(
    JSON.parse(data?.experiences)?.filter(
      (experience) => experience.employerName !== ""
    )
  );
  const [errorList, setErrorList] = useState([
    { errorStart: true, errorEnd: true, errorTitle: true, errorName: true },
  ]);

  const addExperience = () => {
    //alert(readOnlyMode);

    let ex = [...experience];
    let error = [...errorList];

    let addCondition = true;
    setReadOnlyMode(false);

    if (addCondition) {
      ex.push({
        employerName: "",
        industry: "",
        jobTitle: "",
        startDate: "",
        endDate: "",
        description: "",
        isSelect: false,
      });
      setExperience(ex);
      error.push({
        errorStart: true,
        errorEnd: true,
        errorTitle: true,
        errorName: true,
      });
      setErrorList(error);
    }
  };

  const postExperienceInfo = async () => {
    setTempExperience(experience);
    let emptyFieldsCondition = true;
    let dateCondition = true;

    experience?.forEach((e, i) => {
      if (e.isSelect) {
        if (!e.employerName || !e.startDate || !e.jobTitle) {
          emptyFieldsCondition = false;
          if (!e.employerName) {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorName = false;
            setErrorList(errorList);
          } else {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorName = true;
            setErrorList(errorList);
          }
          if (!e.startDate) {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorStart = false;
            setErrorList(errorList);
          } else {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorStart = true;
            setErrorList(errorList);
          }
          if (!e.jobTitle) {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorTitle = false;
            setErrorList(errorList);
          } else {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorTitle = true;
            setErrorList(errorList);
          }

          return false;
        }
      } else {
        if (!e.employerName || !e.startDate || !e.jobTitle || !e.endDate) {
          emptyFieldsCondition = false;
          if (!e.employerName) {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorName = false;
            setErrorList(errorList);
          } else {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorName = true;
            setErrorList(errorList);
          }
          if (!e.startDate) {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorStart = false;
            setErrorList(errorList);
          } else {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorStart = true;
            setErrorList(errorList);
          }
          if (!e.jobTitle) {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorTitle = false;
            setErrorList(errorList);
          } else {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorTitle = true;
            setErrorList(errorList);
          }
          if (!e.endDate) {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorEnd = false;
            setErrorList(errorList);
          } else {
            let objIndex = errorList.findIndex((obj, index) => index == i);
            errorList[objIndex].errorEnd = true;
            setErrorList(errorList);
          }
          return false;
        }
      }
    });

    experience?.forEach((e) => {
      let from = new Date(e.startDate);
      let to = new Date(e.endDate);
      if (!e.isSelect && from > to) {
        dateCondition = false;
        return false;
      }
    });

    // if (!emptyFieldsCondition) {
    //   toast.error("Fields Marked with *(Asterisk) are mandatory");
    // }
    // else if (!dateCondition) {
    //   toast.error(`'Duration From' should not be greater than 'Duration To'`);
    // }
    // else {
    setSaveClicked(false);
    setSubmittingFrom(true);
    try {
      const parameters = {
        ...data,
        zipcode: data.zipcode,
        educations: data.educations,
        experiences: JSON.stringify(experience),
        activity: "Experience updated",
      };
      // console.log('Parameters', parameters)
      const res = await post("/candidate/updatetalent", parameters);
      // console.log(res)
      if (res.status === 200) {
        setSubmittingFrom(false);
        setShowSuccessMessage(true);
        // getData();
        setUpdateDetails(!updateDetails);
        setTimeout(() => {
          setReadOnlyMode(true);
          setShowSuccessMessage(false);
        }, 1000);
        toast.success("Updated successfully!");
      }
    } catch (error) {
      toast.error("Error Occured");
    }
    // }
  };

  const deleteExperience = (index) => {
    let ex = [...experience];
    ex.splice(index, 1);
    setExperience(ex);
  };

  const editExperience = () => {
    setReadOnlyMode(false);
    setSaveClicked(true);
  };

  const cancelExperience = () => {
    setExperience(
      data?.experiences
        ? JSON.parse(data?.experiences)?.filter(
            (experience) => experience.employerName !== ""
          )
        : []
    );
    setReadOnlyMode(true);
    setShowSuccessMessage(false);
    setSubmittingFrom(false);
    setSaveClicked(true);
  };

  const handleChange = (name, value, index, startDate) => {
    let ex = [...experience];
    if (name === "endDate" && value < startDate) {
      toast.error("End Date Cannot Be Less Than Start Date");
      return;
    }
    if (name === "isSelect" && value) {
      ex = experience.map((e, i) => {
        return index === i
          ? { ...e, isSelect: true }
          : { ...e, isSelect: false };
      });
    } else {
      ex[index] = { ...ex[index], [name]: value };
    }
    setExperience(ex);
  };

  return (
    <>
      <div class="card card-lg mb-2">
        <div class="card-body">
          <div class="d-flex align-items-center justify-content-between">
            <h6 class="mb-0">Work Experience</h6>
            {readOnlyMode === true ? (
              <button
                type="button"
                class="btn btn-text"
                onClick={() => {
                  editExperience();
                }}
              >
                <i class="fas fa-pencil-alt mr-1"></i>
                Edit
              </button>
            ) : (
              <div>
                <button
                  type="button"
                  onClick={() => {
                    cancelExperience();
                  }}
                  class="btn btn-sm btn-secondary mr-1"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={postExperienceInfo}
                  class="btn btn-sm btn-primary"
                >
                  {saveClicked && <span>Save Changes</span>}

                  {submittingForm && (
                    <span>
                      Saving Changes
                      <img width="20px" src={ButtonLoader} alt="" />
                    </span>
                  )}
                  {showSuccessMessage && <span>Changes Saved!</span>}
                </button>
              </div>
            )}
          </div>
          <hr />
          {readOnlyMode ? (
            <div>
              {readOnlyMode && experience?.length === 0 ? (
                <div>
                  <div className="text-center p-3">
                    <div className="avatar avatar-lg">
                      <i className="fad fa-comment-slash"></i>
                    </div>
                    <div className="mt-2">No Record Found</div>
                  </div>
                </div>
              ) : (
                experience?.map((e) => (
                  <div class="d-flex">
                    <div class="mr-3 d-flex flex-column align-items-center">
                      <i
                        class="fad fa-fw fa-briefcase mt-1"
                        aria-hidden="true"
                      ></i>
                      <div class="view-panel-spacer mt-1"></div>
                    </div>
                    <div class="pb-3">
                      <p class="mb-1">
                        <strong>
                          {e.employerName}
                          {/* <span class="font-regular ml-1">
                            {e.industry ? `(${e.industry})` : null}
                          </span> */}
                        </strong>
                      </p>
                      <p class="mb-0">
                        <small>
                          <strong>{e.jobTitle}</strong>
                        </small>
                      </p>
                      {console.log(e)}
                      {e?.startDate && e?.startDate !== "" && (
                        <p>
                          <small>
                            From:{" "}
                            {monthNames[new Date(e.startDate).getMonth()] +
                              " " +
                              new Date(e.startDate).getFullYear()}
                            {!e.isSelect
                              ? `   To: ${
                                  monthNames[new Date(e.endDate).getMonth()]
                                } ${new Date(e.endDate).getFullYear()}`
                              : null}
                          </small>
                        </p>
                      )}
                      <p>{e.description}</p>
                    </div>
                  </div>
                ))
              )}
            </div>
          ) : (
            <div class="work-experience">
              {experience?.map((e, j) => (
                <div class="d-flex">
                  <div class="mr-3">
                    <div class="avatar avatar-sm">{j + 1}</div>
                  </div>
                  <div className="col-10">
                    <div class="row mb-2">
                      <div class="col-lg-6">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            name="employerName"
                            placeholder="Employer Name"
                            value={e.employerName}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                            // style={{
                            //   border: !e.employerName && "2px solid #ff0000",
                            // }}
                          />
                          <label>Employer Name</label>
                        </div>
                      </div>
                      <div class="col-lg-6 d-flex align-items-center">
                        <div className="mr-2 mt-3">
                          <p>Currently Employer? </p>
                        </div>
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            name="isSelect"
                            checked={e.isSelect}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.checked,
                                j
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row my-2">
                      <div class="col-lg-12 mb-2">
                        <div class="form-floating">
                          <input
                            type="text"
                            class="form-control"
                            name="jobTitle"
                            value={e.jobTitle}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                            placeholder="Designation"
                            // style={{
                            //   border: !e.jobTitle && "2px solid #ff0000",
                            // }}
                          />
                          <label>Designation</label>
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating form-date">
                          <label>Duration From</label>
                          <input
                            type="date"
                            class="form-control date"
                            placeholder="Start Date"
                            // style={{
                            //   border: !e.startDate && "2px solid #ff0000",
                            // }}
                            name="startDate"
                            max={moment(new Date()).format("YYYY-MM-DD")}
                            // max="2023-07-23"
                            value={e.startDate}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                          />
                          {/* {!e.startDate && (
                            <small className="validation">
                              *Required Field
                            </small>
                          )} */}
                        </div>
                      </div>
                      <div class="col-lg-6">
                        <div class="form-floating form-date">
                          <label>Duration To {e.isSelect ? null : ""}</label>
                          <input
                            type="date"
                            class="form-control date"
                            placeholder="End Date"
                            // style={{
                            //   border:
                            //     !e.isSelect &&
                            //     !e.endDate &&
                            //     "2px solid #ff0000",
                            // }}
                            name="endDate"
                            value={e.endDate}
                            max={moment().format("YYYY-MM-DD")}
                            disabled={e.isSelect}
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j,
                                e.startDate
                              )
                            }
                          />
                          {/* {!e.isSelect && !e.endDate && (
                            <small className="validation">
                              *Required Field
                            </small>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="form-floating">
                          <textarea
                            // type="textarea"
                            class="form-control"
                            placeholder="Experience Description"
                            value={e.description}
                            name="description"
                            onChange={(event) =>
                              handleChange(
                                event.target.name,
                                event.target.value,
                                j
                              )
                            }
                          ></textarea>
                          {/* {e?.description == "" && (
                            <label>Experience Description</label>
                          )} */}
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div class="ml-3 col-1">
                    <button
                      type="button"
                      class="btn btn-icon"
                      style={{ color: "red" }}
                      onClick={() => deleteExperience(j)}
                    >
                      <i class="fal fa-fw fa-trash-alt"></i>
                    </button>
                  </div>
                </div>
              ))}
              <div className="ml-5">
                <button
                  type="button"
                  class="btn btn-sm btn-text"
                  onClick={addExperience}
                >
                  <i class="fal fa-plus mr-1" aria-hidden="true"></i>
                  <strong>Add Experience</strong>
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Experience;
