import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import SessionTimeOut from "../components/common/SessionTimeOut";
import PageNotFound from "../components/components_v3/commoncomponents/error/404";
import ForgotPassword from "../components/common/ForgotPassword";
import ResetPassword from "../components/common/ResetPassword";
import MyProfile from "../components/common/profile/MyProfile";
import TermsAndConditions from "../components/common/TermsAndConditions";
import AcceptRejectRTR from "../components/common/AcceptRejectRTR";
import ShareJob from "../components/common/ShareJob";
import GigRecruiter from "../components/RecruiterMarketPlace/GigRecruiter";
import GigRecruiterPublic from "../components/RecruiterMarketPlace/GigRecruiterPublic";
import TermsConditions from "../components/common/TermsConditions";
// import JobTemplate from "../components/directsourcing/career/JobTemplate";
import JobList from "../components/common/CareerPage/CareerPage";
import JobDescription from "../components/directsourcing/career/JobDescription";
import CareerPage from "../components/common/CareerPage/JobApplyComponent";
// import JoinCommunity from "../components/directsourcing/career/JoinCommunity";
//import { TPOOL } from "./PrivateComponentList";
import AddTalentDS from "../components/directsourcing/career/AddTalentDS";
// import CandidateProfile from "../components/directsourcing/CandidateProfile/CandidateProfile";
import ResetPasswordDS from "../components/directsourcing/career/ResetPasswordDS";
import RTR from "../components/directsourcing/career/RightToRepresent";
import TermsAndConditionsWeb from "../components/directsourcing/career/TermsAndCondition";
import CandidateForgotPassword from "../components/auth/CandidateForgotPassword";
import Career from "../components/components_v3/careers/Index";
import JobDetails from "../components/components_v3/candidatecareers/jobdetails/Index";
import JoinCommunity from "../components/components_v3/careers/JobDetails/JoinCommunity";
import CandidateProfile from "../components/components_v3/candidate/profile/Index";
import Unsubscribe from "../components/components_v3/unsubscribe/unsubscribeNew";
import CandidateModule from "../components/components_v3/CandidateModule/Index";
import EditTalent from "../components/components_v3/CandidateModule/EditTalent/Index";
import CandidateJobList from "../components/components_v3/candidatecareers/Index";
import PoolDetails from "../components/components_v3/directsourcing/poolcareers/poolDetails/Index";
import Screening from "../components/components_v3/disposition/ApplyScreeningQuestion";
import ReadyToHireQA from "../components/components_v3/applyjob/RTH_Screening";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import HomeCareer from "../components/components_v3/NewApplyPage/Home";
import CareerJobList from "../components/components_v3/NewApplyPage/JobList";
import InformationTechnology from "../components/components_v3/NewApplyPage/JobListCategory/InformationTechnology";
import AllJobs from "../components/components_v3/NewApplyPage/JobListCategory/AllJobs";
import JoinCommunityNew from "../components/components_v3/joinCommunityNew/JoinCommunityNew";
import ExpiredLink from "../components/common/ExpiredLink";
import UpdateAdditionalInfo from "../components/common/UpdateAdditionalInfo";
import CuratorInvite from "../components/components_v3/curator/CuratorInvite/index";

const PublicRouteList = [
  { path: "/linkedin", name: LinkedInCallback, isExact: true },
  {
    path: "/",
    name: Login,
    isExact: true,
  },
  {
    path: "/register",
    name: Register,
    isExact: true,
  },
  {
    path: "/sessionTimeOut",
    name: SessionTimeOut,
    isExact: true,
  },
  {
    path: "/linkExpired",
    name: ExpiredLink,
    isExact: true,
  },
  {
    path: "/myprofile",
    name: MyProfile,
    isExact: true,
  },

  {
    path: "/login",
    name: Login,
    isExact: true,
  },
  {
    path: "/404",
    name: PageNotFound,
    isExact: true,
  },
  {
    path: "/forgot-password",
    name: ForgotPassword,
    isExact: true,
  },
  {
    path: "/resetpassword",
    name: ResetPassword,
    isExact: true,
  },
  {
    path: "/resetpasswordDS",
    name: ResetPasswordDS,
    isExact: true,
  },
  {
    path: "/termsandconditions",
    name: TermsAndConditions,
    isExact: true,
  },
  {
    path: "/terms&conditions",
    name: TermsConditions,
    isExact: true,
  },
  {
    path: "/acceptrejectrtr/:Id",
    name: AcceptRejectRTR,
    isExact: true,
  },
  {
    path: "/sharejob/:Id",
    name: ShareJob,
    isExact: true,
  },
  {
    path: "/gigrecruiter/:Id",
    name: GigRecruiter,
    isExact: true,
  },
  {
    path: "/RecruiterRegistrationForm/:Id",
    name: GigRecruiterPublic,
    isExact: true,
  },
  {
    path: "/curatorInvite/:recruiterId/:clientId/:communityId",
    name: CuratorInvite,
    isExact: true,
  },
  // {
  //   path: "/careers/:tenant/joblistOG",
  //   name: JobTemplate,
  //   isExact: true,
  // },
  {
    path: "/careers/:tenant/joblist/:mail",
    name: Career,
    isExact: true,
  },
  {
    path: "/careers/:tenant/recruiter/:recruiterid/joincommunity",
    name: JoinCommunity,
    isExact: true,
  },
  {
    path: "/careers/:tenant/addtalent",
    name: AddTalentDS,
    isExact: true,
  },
  {
    path: "/careers/:tenant/joblist",
    name: Career,
    isExact: true,
  },
  {
    path: "/careers/joblist",
    // name: Career,
    name: CandidateJobList,
    isExact: true,
  },
  {
    path: "/careers/:tenant/joblist/jobdescription/:id",
    name: JobDetails,
    isExact: true,
  },
  {
    path: "/careers/:tenant/joblist/jobdescription/:id/recruiter/:recruiterid",
    name: JobDetails,
    isExact: true,
  },
  {
    path: "/careers/:tenant/joblist/pooldescription/:id/recruiter/:recruiterid",
    name: PoolDetails,
    isExact: true,
  },
  {
    path: "/careers/:tenant/recruiter/:recruiterid/newjoincommunity",
    name: JoinCommunityNew,
    isExact: true,
  },
  {
    path:
      "/careers/:tenant/joblist/pooldescription/:id/recruiter/:recruiterid/screening/:candidateId/:dispositionId",
    name: Screening,
    isExact: true,
  },
  {
    path: "/careers/joblist/pooldescription/:id/rthQA/:dispositionId",
    name: ReadyToHireQA,
    isExact: true,
  },
  {
    path:
      "/careers/:tenant/joblist/jobdescription/recruiter/:recruiterid/:poolId",
    name: CareerPage,
    isExact: true,
  },
  {
    path: "/careers/:tenant/joblist/jobdescription/:id/:reffererid",
    name: JobDescription,
    isExact: true,
  },
  {
    path: "/careers/:tenant/joblist/jobdescription/:id/:uuid",
    name: JobDescription,
    isExact: true,
  },
  // {
  //   path: "/careers/:tenant/profile",
  //   name: CandidateProfile,
  //   isExact: true,
  // },
  {
    path: "/careers/profile",
    // name: CandidateProfile,
    name: CandidateModule,
    isExact: true,
  },

  {
    path: "/careers/profile/edittalent",
    name: EditTalent,
    isExact: true,
  },
  {
    path: "/righttorepresent",
    name: RTR,
    isExact: true,
  },
  {
    path: "/termsAndConditionsWeb",
    name: TermsAndConditionsWeb,
    isExact: true,
  },
  {
    path: "/careers/forgot-passwordDS",
    name: CandidateForgotPassword,
    isExact: true,
  },

  //   {
  //     path: "/staffingrtr",
  //     name: StaffingRTR,
  //     isExact: true,
  //   },
  {
    path: "/unsubscribe/:email",
    name: Unsubscribe,
    isExact: true,
  },
  {
    path: "/career/home",
    name: AllJobs,
    isExact: true,
  },
  {
    path: "/career/savedjobs",
    name: AllJobs,
    isExact: true,
  },
  {
    path: "/career/joblist",
    name: CareerJobList,
    isExact: true,
  },
  {
    path: "/jobs/:category",
    name: AllJobs,
    isExact: true,
  },
  {
    path: "/career/jobs/:id/recruiter/:recruiterid",
    name: HomeCareer,
    isExact: true,
  },
  {
    path: "/updateadditionalinfo/:poolId/pooldescription/:dispositionId",
    name: UpdateAdditionalInfo,
    isExact: true,
  },
];

export default PublicRouteList;
