import React, { useState } from "react";
import sparkles from '../../../assets/lottie/sparkles.json'
import checkMark from '../../../assets/lottie/checkmark.json'
import Lottie from 'react-lottie-player'
import ReactAnime from 'react-animejs'
const { Anime } = ReactAnime

function FormSubmitSuccess({ job, closeModal, reason }) {

    const [loop] = useState(false)

    return (
        <>
            <div class="section section-sm pb-0" >
                <div class="success-animation">
                    <div class="checkmark">
                        <Lottie
                            loop={loop}
                            animationData={checkMark}
                            play
                        ></Lottie>

                    </div>
                    <Anime
                        initial={[
                            {
                                targets: '#LottieSparkles',
                                opacity: 0,
                                delay: 1000
                            }
                        ]}
                    >
                        <div class="sparkles">
                            <Lottie
                                loop={loop}
                                animationData={sparkles}
                                id="LottieSparkles"
                                play></Lottie>
                        </div>
                    </Anime>
                </div>
                <Anime
                    initial={[
                        {
                            targets: '.success-message',
                            opacity: 1,
                            delay: 800,
                            easing: 'linear'
                        }
                    ]}
                >
                    <div class="success-message" style={{ opacity: "0" }}>
                        <div class="container">
                            <div class="col-lg-12">
                                <>                 
                                    <h4 class="text-center">Application Submitted!</h4>
                                    <div class="text-center">
                                        <p>Your application for {job.fullText.jobTitle} has been submitted</p>
                                    </div>
                                    <div className="card card-flat card-lg">
                                        <div class="card-body">
                                            <div class="mb-2">
                                                <label class="d-block font-bold pt-0">Title</label> 
                                                <div>{job.fullText.jobTitle}</div>
                                            </div>
                                            <div class="mb-2">
                                                <label class="d-block font-bold pt-0">Type</label> 
                                                <div>{job.jobType}</div>
                                            </div>
                                            { job.jobLocation && <div class="mb-2">
                                                <label class="d-block font-bold pt-0">Location</label> 
                                                <div>{job.jobLocation}</div>
                                            </div>}
                                            {/* <div class="mb-2">
                                                <label class="d-block font-bold pt-0">Rate</label> 
                                                <div>{job.hourlyRate}</div>
                                            </div> */}
                                            <div class="mb-2">
                                                <label class="d-block font-bold pt-0">Reason</label> 
                                                <div>
                                                    <p>{reason || 'NA'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                                <div class="text-center py-4">
                                    <button type="button" class="btn btn-secondary mr-2" data-dismiss="modal" onClick={closeModal}>Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Anime>
            </div>
        </>
    );
}

export default FormSubmitSuccess
