import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";
import ResendMail from "./ResendMail";
import DisqualifyPopUp from "../disqualified/DisqualifyPopUp";
import ResumeDownload from "../resume/Index";
import TalentProfile from "../../../../talent/talentprofile/TalentProfile";

import {
  getTenantType,
  getUserRole,
  getUserTenantID,
} from "../../../../../../service/AuthService";
import EmailStatus from "../../../../EmailStatus/EmailStatus";
import { getPoolDispositionDetails } from "../../../../../../actions/directsourcing/Disposition";
import { getPoolDispositionCount } from "../../../../../../actions/directsourcing/PoolDispositionCount";
import { getPoolDispositionDisqualify } from "../../../../../../actions/directsourcing/PoolDispositionDisqualify";
import ProfileImage from "../../../../commoncomponents/profileimage/ProfileImage";
import { FaCommentAlt } from "react-icons/fa";

const Invite = (props) => {
  const { jobData, invitedData, view, filterParams } = props;
  const dispatch = useDispatch();
  const { poolId } = useParams();
  const [openDropDown, setOpenDropDown] = useState(false);
  const [disqualifyPopup, setDisqualifyPopup] = useState(false);
  const [disqualifyData, setDisqualifyData] = useState({});
  const [emailData, setEmailData] = useState({});
  const [emailStatus, setEmailStatus] = useState(false);
  const [selectedTalent, setSelectedTalent] = useState({});
  const [resume, setResume] = useState(false);
  const [resumeData, setResumeData] = useState({});
  const [candidateProfile, setCandidateProfile] = useState(false);
  const [candidateData, setCandidateData] = useState({});
  const [isCandidateFrom, setIsCandidateFrom] = useState("");

  const diffDate = (someday) => {
    const today = moment();
    return today.diff(someday, "hours");
  };

  const reloadDisposition = () => {
    const payloadDisposition = {
      poolId: poolId,
      status: view,
      limit: 15,
      currentPage: 1,
      filterObject: filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionDetails(payloadDisposition));
    const payload = {
      jobId: poolId,
      filterObject: filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionCount(payload));
    let payloadDisqualify = {
      currentPage: 1,
      limit: 15,
      status: "disqualified",
      poolId: poolId,
      filterObject: filterParams,
      searchText: props.value,
    };
    dispatch(getPoolDispositionDisqualify(payloadDisqualify));
    setDisqualifyPopup(false);
  };

  const handleResume = (data) => {
    setResumeData(data);
    setResume(true);
  };

  const onDisqualifyClick = (data) => {
    setOpenDropDown(false);
    setDisqualifyPopup(true);
    setDisqualifyData(data);
  };

  const openDisqualify = (data) => {
    setDisqualifyData(data);
    setOpenDropDown(true);
  };

  const onProfileClick = (item) => {
    setCandidateProfile(true);
    setCandidateData(item);
  };

  const handleModel = () => {
    setCandidateProfile(false);
  };

  const jobBoard = [
    "Facebook",
    "LinkedIn",
    "Indeed",
    "CareerBuilder",
    "Dice",
    "Twitter",
  ];

  return (
    <>
      {invitedData.map((item, index) => (
        <tr key={index}>
          <td
            className="no-hover"
            onClick={() => onProfileClick(item)}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="d-flex flex-inline w-70">
              <ProfileImage
                imageSrc={item?.profileImagePath}
                imageWidth={"35"}
                imageHeight={"35"}
                avatarSize={"35"}
                avatarRound={"80px"}
                firstName={item?.firstName}
                lastName={item?.lastName}
              />
              <span className="ml-2 font-16">
                {item?.firstName.charAt(0).toUpperCase() +
                  item?.firstName.slice(1)}{" "}
                {item?.lastName.charAt(0).toUpperCase()}
              </span>
            </div>

            <div className="d-flex justify-content-start">
              {!item?.readStatus && (
                <button
                  type="button"
                  title="Send SMS"
                  class="btn btn-text"
                  style={{ marginLeft: "auto" }}
                  onClick={() => {
                    setCandidateProfile(true);
                    setIsCandidateFrom("sms");
                  }}
                >
                  <div className="notification-icon">
                    <FaCommentAlt />
                    <span className="message-status"></span>
                  </div>
                </button>
              )}
            </div>
          </td>
          {/* <td className="no-hover">
            <span class="tag tag-lg tag-gray">
              <img width="14px" src={vettedIcon} alt="" />
              <span class="mx-2">No</span>
            </span>
          </td> */}
          <td>
            {item?.invitedDate ? moment(item?.invitedDate).fromNow() : ""}
          </td>
          {/* <td class="no-hover">
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Screening"
              class="d-inline-block"
              data-original-title="Screening"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                class="btn btn-square btn-gray"
                disabled="disabled"
              >
                <i aria-hidden="true" class="fad fa-webcam"></i>
              </button>
            </div>{" "}
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Vetting"
              class="d-inline-block"
              data-original-title="Vetting"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                disabled="disabled"
                class="btn btn-square btn-gray"
              >
                <i aria-hidden="true" class="fas fa-walkie-talkie"></i>
              </button>
            </div>{" "}
            <div
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Ready To Hire"
              class="d-inline-block"
              data-original-title="Ready To Hire"
            >
              <button
                type="button"
                data-toggle="modal"
                data-target="#ViewVideoModal"
                disabled="disabled"
                class="btn btn-square btn-gray"
              >
                <i aria-hidden="true" class="fas fa-ballot-check"></i>
              </button>
            </div>
          </td> */}
          {/* <td className="no-hover">
            <div
              className="d-inline-block"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Resume Download"
            >
              <button
                type="button"
                data-toggle="modal"
                disabled={item.filePath == null && true}
                data-target="#ViewResumeDocModal"
                className="btn btn-square btn-gray"
                onClick={() => handleResume(item)}
              >
                <i className="fas fa-file-user" />
              </button>
            </div>
          </td>{" "} */}

          <td className="no-hover">
            {/* <div
                className="d-inline-block"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="View Email Status"
              >
                <div className="btn-container">
                  <button
                    type="button"
                    className="btn btn-square btn-gray"
                    onClick={() => {
                      setEmailData(item?.email);
                      setEmailStatus(true);
                      setSelectedTalent(item);
                    }}
                  >
                    <i class="fa fa-envelope-o" />
                  </button>
                  {item?.latestEmailStatus &&
                    item?.latestEmailStatus !== "noMail" && (
                      <span
                        className={`${
                          item?.latestEmailStatus === "delivered"
                            ? "dot-success"
                            : "dot-failure"
                        }`}
                      />
                    )}
                </div>
              </div> */}
            <span className="ml-2">
              {item.URLOpenCount ? item.URLOpenCount : 0}
            </span>
          </td>
          <td className="no-hover">
            <span className="ml-2">
              {item.RecruiterName && item.RecruiterName.length > 0
                ? item.RecruiterName[0]
                : " "}
            </span>
          </td>
          <td className="no-hover">
            <div className="d-flex mb-2 pl-1">
              <div className="ml-2">
                <p className="mb-0">
                  {getTenantType() == 2
                    ? getUserTenantID() == 5788
                      ? jobBoard.includes(item.source)
                        ? "High5 Job Board"
                        : item.source == "Job Board"
                        ? "Crintell"
                        : item.source == "JobDiva"
                        ? "JobDiva"
                        : "Internal"
                      : item.source == "Job Board"
                      ? "Crintell"
                      : item.source == "JobDiva"
                      ? "JobDiva"
                      : "Internal"
                    : getTenantType() == 1
                    ? item.tenantId &&
                      item.tenantId.map(Number).includes(getUserTenantID())
                      ? "Internal"
                      : item.fk_tenant && item.fk_tenant == getUserTenantID()
                      ? "Internal"
                      : "External"
                    : item.source == "Job Board"
                    ? "Crintell"
                    : item.source == "JobDiva"
                    ? "JobDiva"
                    : "Internal"}
                </p>
              </div>
            </div>
          </td>

          {getUserRole() !== "CustomerSuccess" && getUserRole() !== "Admin" && (
            <td className="no-hover text-right text-left-1024">
              <hr className="d-none d-block-1024 my-2" />
              {jobData.jobStatus !== 23 &&
                jobData.jobStatus !== 26 &&
                jobData.jobStatus !== 39 &&
                (diffDate(item?.invitedDate) > 48 &&
                getUserRole() === "Recruiter" ? (
                  <div className="btn-group btn-group-sm btn-split">
                    <ResendMail talentData={item} jobData={jobData} />
                    <button
                      type="button"
                      onClick={() => openDisqualify(item)}
                      className="btn btn-sm dropdown-toggle dropdown-toggle-split"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="true"
                    />
                    {disqualifyData.userId == item.userId && openDropDown && (
                      <OutsideClickHandler
                        onOutsideClick={() => setOpenDropDown(false)}
                      >
                        <div
                          className="dropdown-menu dropdown-menu-right"
                          style={{
                            display: "revert",
                            right: "0px",
                            top: "30px",
                          }}
                        >
                          <button
                            type="button"
                            onClick={() => onDisqualifyClick(disqualifyData)}
                            className="dropdown-item"
                          >
                            {getUserRole() == "Recruiter" ||
                            getUserRole() == "CommunityManager"
                              ? "Withdraw"
                              : "Disqualify"}
                          </button>
                        </div>
                      </OutsideClickHandler>
                    )}
                  </div>
                ) : (
                  <button
                    type="button"
                    onClick={() => onDisqualifyClick(item)}
                    className="btn btn-sm"
                  >
                    {getUserRole() == "Recruiter" ||
                    getUserRole() == "CommunityManager"
                      ? "Withdraw"
                      : "Disqualify"}
                  </button>
                ))}
            </td>
          )}
        </tr>
      ))}
      {disqualifyPopup && (
        <DisqualifyPopUp
          disqualifyPopup={disqualifyPopup}
          setCandidateProfile={setCandidateProfile}
          setDisqualifyPopup={setDisqualifyPopup}
          disqualifyData={disqualifyData}
          jobData={jobData}
          reloadDisposition={reloadDisposition}
          isFrom={"invite"}
        />
      )}{" "}
      {emailStatus && (
        <EmailStatus
          email={emailData}
          emailStatus={emailStatus}
          setEmailStatus={setEmailStatus}
          isFrom="Invited"
          invitedDate={selectedTalent?.invitedDate}
          jobData={jobData}
        />
      )}
      {resume && (
        <ResumeDownload
          resume={resume}
          setResume={setResume}
          resumeData={resumeData}
        />
      )}
      {candidateProfile && (
        <TalentProfile
          candidateData={candidateData}
          handleSuccess={handleModel}
          openDisqualify={openDisqualify}
          jobData={jobData}
          disqualifyData={disqualifyData}
          openDropDown={openDropDown}
          setOpenDropDown={setOpenDropDown}
          onDisqualifyClick={onDisqualifyClick}
          parent="inviteTab"
          isFrom="inviteTab"
          component="TalentPool"
          poolId={poolId}
          isCandidateFrom={isCandidateFrom}
          setIsCandidateFrom={setIsCandidateFrom}
        />
      )}
    </>
  );
};

export default Invite;
