import React, { useEffect, useState } from "react";

const ShareRecFilter = (props) => {
  let dataLimit = 4;
  let pageLimit = 5;
  const [candidateNameEnabled, setCandidateNameEnabled] = useState(false);
  const [candidateName, setCandidateName] = useState([]);
  const [selectedName, setSelectedName] = useState([]);
  const [candidateUserTypeEnabled, setCandidateUserTypeEnabled] = useState(
    false
  );
  const [candidateUserType, setCandidateUserType] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [candidateSourceEnabled, setCandidateSourceEnabled] = useState(false);
  const [candidateSource, setCandidateSource] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [candidateStatusEnabled, setCandidateStatusEnabled] = useState(false);
  const [candidateStatus, setCandidateStatus] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [candidateCountryEnabled, setCandidateCountryEnabled] = useState(false);
  const [candidateCountry, setCandidateCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchData, setSearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [currentPage1, setCurrentPage1] = useState(1);
  const [searchData1, setSearchData1] = useState([]);
  const [searchValue1, setSearchValue1] = useState("");
  const [currentPage2, setCurrentPage2] = useState(1);
  const [searchData2, setSearchData2] = useState([]);
  const [searchValue2, setSearchValue2] = useState("");
  const [currentPage3, setCurrentPage3] = useState(1);
  const [searchData3, setSearchData3] = useState([]);
  const [searchValue3, setSearchValue3] = useState("");
  const [currentPage4, setCurrentPage4] = useState(1);
  const [searchData4, setSearchData4] = useState([]);
  const [searchValue4, setSearchValue4] = useState("");
  const [pageType, setPageType] = useState("");
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [paginationGroup1, setPaginationGroup1] = useState([]);
  const [paginationArray1, setPaginationArray1] = useState([]);
  const [paginationGroup2, setPaginationGroup2] = useState([]);
  const [paginationArray2, setPaginationArray2] = useState([]);
  const [paginationGroup3, setPaginationGroup3] = useState([]);
  const [paginationArray3, setPaginationArray3] = useState([]);
  const [paginationGroup4, setPaginationGroup4] = useState([]);
  const [paginationArray4, setPaginationArray4] = useState([]);

  useEffect(() => {
    setSelectedName(props.selectedName);
    setCandidateName(props.candidateName);
    setSelectedUserType(props.selectedUserType);
    setCandidateUserType(props.candidateUserType);
    console.log(props);
  }, [props]);

  const titleHandler = (checked, name) => {
    if (checked) {
      setSelectedName([...selectedName, name]);
    } else {
      setSelectedName(selectedName.filter((t) => t !== name));
    }
  };

  const userTypeHandler = (checked, userType) => {
    if (checked) {
      setSelectedUserType([...selectedUserType, userType]);
    } else {
      setSelectedUserType(selectedUserType.filter((t) => t !== userType));
    }
  };

  // Pagination
  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  function changePage1(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage1(pageNumber);
  }

  const getPaginatedData = () => {
    const startIndex = currentPage * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue
      ? searchData.slice(startIndex, endIndex)
      : candidateName.slice(startIndex, endIndex);
  };

  const getPaginatedData1 = () => {
    const startIndex = currentPage1 * dataLimit - dataLimit;
    const endIndex = startIndex + +dataLimit;
    return searchValue1
      ? searchData1.slice(startIndex, endIndex)
      : candidateUserType.slice(startIndex, endIndex);
  };

  useEffect(() => {
    searchValue
      ? setPaginationArray(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray(
          new Array(Math.ceil(candidateName.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue
      ? setPaginationGroup(
          new Array(Math.ceil(searchData.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup(
          new Array(Math.ceil(candidateName.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );

    searchValue1
      ? setPaginationArray1(
          new Array(Math.ceil(searchData1.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        )
      : setPaginationArray1(
          new Array(Math.ceil(candidateUserType.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
        );
    searchValue1
      ? setPaginationGroup1(
          new Array(Math.ceil(searchData1.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        )
      : setPaginationGroup1(
          new Array(Math.ceil(candidateUserType.length / dataLimit))
            .fill()
            .map((_, i) => i + 1)
            .slice(0, pageLimit)
        );
    setCurrentPage(1);
    setCurrentPage1(1);
  }, [candidateName, searchData, candidateUserType, searchData1]);

  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      } else if ((currentPage1 - 1) % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(
            currentPage1 - 1,
            currentPage1 + (pageLimit - 1)
          )
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      } else if (currentPage1 % pageLimit === 0 && currentPage1 !== 1) {
        setPaginationGroup1(
          paginationArray1.slice(currentPage1 - pageLimit, currentPage1)
        );
      }
    }
  }, [currentPage, currentPage1]);

  const splitMatchedText = (str) => {
    let regExp;
    if (searchValue) {
      regExp = new RegExp(searchValue, "gi");
    } else if (searchValue1) {
      regExp = new RegExp(searchValue1, "gi");
    } else if (searchValue2) {
      regExp = new RegExp(searchValue2, "gi");
    } else if (searchValue3) {
      regExp = new RegExp(searchValue3, "gi");
    } else if (searchValue4) {
      regExp = new RegExp(searchValue4, "gi");
    }

    return str.replace(
      regExp,
      (match) => `<span style='color: #FF5F57'>${match}</span>`
    );
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    setSearchData(
      candidateName.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const handleSearch1 = (e) => {
    setSearchValue1(e.target.value);
    setSearchData1(
      candidateUserType.filter((i) =>
        i.toLowerCase().includes(e.target.value.toLowerCase())
      )
    );
  };

  const titleArray = (name) => {
    //console.log((selectedName.length > 0 ? selectedName.includes(name) : true))
    return selectedName.length > 0
      ? selectedName.includes(name.replace("GIG -", ""))
      : true;
  };

  const userTypeArray = (userType) => {
    //console.log((selectedUserType.length > 0 ? selectedUserType.includes(userType) : true),"usertype")
    if (selectedUserType.length === 2 || selectedUserType.length === 0) {
      return true;
    } else {
      if (selectedUserType[0] === "Individual Recruiter") {
        return userType ? false : true;
      } else {
        return userType ? true : false;
      }
    }
  };

  const clearAll = () => {
    setSelectedName([]);
    setSelectedUserType([]);
    setSearchValue("");
    setSearchValue1("");
  };

  const applyFilter = () => {
    let filtered = props.reportData.filter(
      (item) => item.fk_role === 1 || item.tenantname.includes("GIG -")
    );
    if (selectedName.length > 0) {
      filtered = filtered.filter((item) =>
        selectedName.includes(item.tenantname.replace("GIG -", "").trim())
      );
    }
    props.applyFilter(filtered, selectedName, selectedUserType);
  };

  return (
    <>
      <div className="view-panel show">
        <div className="view-panel-header view-panel-header-sm">
          <div className="p-relative">
            <div className="px-3 py-3 d-flex">
              <button
                onClick={applyFilter}
                type="button"
                className="btn btn-sm btn-primary"
              >
                <i className="fal fa-check mr-2 fa-fw"></i>Done
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <div className="px-3">
              <h6>Filter / {selectedName.length}</h6>
            </div>
            <div className="px-3">
              <button type="button" className="btn btn-text">
                <span className="font-bold font-14" onClick={clearAll}>
                  Clear All
                </span>
              </button>
            </div>
          </div>
        </div>
        <div className="view-panel-body view-panel-body-sm">
          <div className="mb-2">
            <button
              type="button"
              className="btn btn-collapsible"
              onClick={() => setCandidateNameEnabled(!candidateNameEnabled)}
            >
              <i
                className={
                  candidateName
                    ? "fal fa-angle-down fa-fw"
                    : "fal fa-angle-up fa-fw"
                }
              />
              <span className="p-relative">
                <small>Name</small>
                {selectedName.length > 0 && <span className="jewel"></span>}
              </span>
            </button>
            {candidateNameEnabled &&
              (candidateName.length > 0 ? (
                <div className="mb-4 ml-4 pl-1">
                  <input
                    type="text"
                    placeholder="Type to filter list"
                    className="form-control form-control-filter mt-2"
                    onChange={handleSearch}
                    value={searchValue}
                  />
                  {getPaginatedData().map((j) => (
                    <div className="d-flex align-items-center m-1">
                      <div>
                        {searchValue ? (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: splitMatchedText(j),
                            }}
                          ></span>
                        ) : (
                          j
                        )}
                      </div>
                      <div className="ml-auto">
                        <div className="form-check form-switch">
                          <input
                            type="checkbox"
                            checked={selectedName.includes(j)}
                            className="form-check-input"
                            onChange={(e) => titleHandler(e.target.checked, j)}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  {getPaginatedData().length === 0 && (
                    <div>
                      <div class="text-center p-3">
                        <div class="avatar avatar-lg">
                          <i class="fad fa-users-slash"></i>
                        </div>
                        <div class="mt-2">
                          No records found for `'${searchValue}'`
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="d-flex p-2 mt-2">
                    <div className="w-100-370 mb-point5-370">
                      Showing{" "}
                      {currentPage === paginationArray.slice(-1)[0]
                        ? getPaginatedData().length
                        : searchValue
                        ? searchData.length < dataLimit
                          ? searchData.length
                          : dataLimit
                        : dataLimit}{" "}
                      of {candidateName.length}
                    </div>
                    <div class="ml-auto mx-auto-370 d-flex align-items-center">
                      <button
                        onClick={() => {
                          setPageType("down");
                          setCurrentPage((page) => page - 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === 1 || getPaginatedData().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-left"></i>
                      </button>

                      {paginationGroup.map((item) => (
                        <button
                          type="button"
                          className={
                            currentPage === item
                              ? "btn btn-text px-2 bg-dark text-white"
                              : "btn btn-text px-2"
                          }
                          onClick={changePage}
                        >
                          {item}
                        </button>
                      ))}

                      <button
                        onClick={() => {
                          setPageType("up");
                          setCurrentPage((page) => page + 1);
                        }}
                        type="button"
                        disabled={
                          currentPage === paginationArray.slice(-1)[0] ||
                          getPaginatedData().length === 0
                            ? true
                            : false
                        }
                        className="btn btn-icon px-2"
                      >
                        <i className="fal fa-chevron-circle-right"></i>
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end mt-2 px-1">
                    <button
                      className="btn btn-text"
                      onClick={() => {
                        setSearchValue("");
                        setSelectedName([]);
                      }}
                    >
                      <small>Clear Name</small>
                    </button>
                  </div>
                  <hr className="p-0" />
                </div>
              ) : (
                <div>
                  <div class="text-center p-3">
                    <div class="avatar avatar-lg">
                      <i class="fad fa-users-slash"></i>
                    </div>
                    <div class="mt-2">No records found</div>
                  </div>
                </div>
              ))}
          </div>

          {/* <div className='mb-2'>
						<button type="button" className="btn btn-collapsible" onClick={() => setCandidateUserTypeEnabled(!candidateUserTypeEnabled)}>
							<i className={candidateUserType ? "fal fa-angle-down fa-fw" : "fal fa-angle-up fa-fw"} />
							<span className="p-relative">
								<small>User Type</small>
								{selectedUserType.length > 0 && <span className="jewel"></span>}
							</span>
						</button>
						{
							candidateUserTypeEnabled &&
							(candidateUserType.length > 0 ?
								<div className='mb-4 ml-4 pl-1'>
									<input
										type="text"
										placeholder="Type to filter list"
										className="form-control form-control-filter mt-2"
										onChange={handleSearch1}
										value={searchValue1}
									/>
									{
										getPaginatedData1().map((j) =>
											<div className='d-flex align-items-center m-1'>
												<div>
													{searchValue1 ? <span dangerouslySetInnerHTML={{ __html: splitMatchedText(j) }}></span> : j}
												</div>
												<div className='ml-auto'>
													<div className='form-check form-switch'>
														<input type='checkbox' checked={selectedUserType.includes(j)} className='form-check-input' onChange={(e) => userTypeHandler(e.target.checked, j)} />
													</div>
												</div>
											</div>
										)
									}
									{
										getPaginatedData1().length === 0 &&
										<div>
											<div class="text-center p-3">
												<div class="avatar avatar-lg">
													<i class="fad fa-users-slash"></i>
												</div>
												<div class="mt-2">
													No  Records found for `'${searchValue1}'`
												</div>
											</div>
										</div>
									}
									<div className='d-flex p-2 mt-2'>
										<div className="w-100-370 mb-point5-370">
											Showing {
												currentPage1 === paginationArray1.slice(-1)[0] ?
													getPaginatedData1().length :
													(searchValue1 ?
														(searchData1.length < dataLimit ? searchData1.length : dataLimit) :
														dataLimit)} {" "}
											of {candidateUserType.length}
										</div>
										<div class="ml-auto mx-auto-370 d-flex align-items-center">
											<button
												onClick={() => {
													setPageType('down')
													setCurrentPage1(page => page - 1)
												}}
												type="button"
												disabled={(currentPage1 === 1 || getPaginatedData1().length === 0) ? true : false}
												className="btn btn-icon px-2"
											>
												<i className="fal fa-chevron-circle-left"></i>
											</button>

											{
												paginationGroup1.map((item) => (
													<button
														type="button"
														className={currentPage1 === item ? "btn btn-text px-2 bg-dark text-white" : "btn btn-text px-2"}
														onClick={changePage1}
													>
														{item}
													</button>
												))
											}

											<button
												onClick={() => {
													setPageType('up')
													setCurrentPage1(page => page + 1)
												}}
												type="button"
												disabled={(currentPage1 === paginationArray1.slice(-1)[0] || getPaginatedData1().length === 0) ? true : false}
												className="btn btn-icon px-2"
											>
												<i className="fal fa-chevron-circle-right"></i>
											</button>
										</div>
									</div>
									<div className='d-flex justify-content-end mt-2 px-1'>
										<button className='btn btn-text' onClick={() => {
											setSearchValue1('')
											setSelectedUserType([])
										}}>
											<small>Clear User Type</small>
										</button>
									</div>
									<hr className='p-0' />
								</div> :
								<div>
									<div class="text-center p-3">
										<div class="avatar avatar-lg">
											<i class="fad fa-users-slash"></i>
										</div>
										<div class="mt-2">
											No  Records found
										</div>
									</div>
								</div>)
						}
					</div> */}
        </div>
      </div>
    </>
  );
};

export default ShareRecFilter;
