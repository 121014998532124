import React, { useEffect, useState } from "react";

const SkillMatrix = ({ primary, secondary }) => {

    return (
        <>
            <div className="ml-0-600 mt-2 mb-4">
                <div className="ml-2 ml-0-600">
                    <div className='row'>
                        <div className="col-lg-4">
                            <small>Skill</small>
                        </div>
                        <div className="col-lg-2">
                            <small>Years</small>
                        </div>
                        <div className="col-lg-2">
                            <small>Months</small> 
                        </div>
                        <div className="col-lg-4">                           
                            <small>Response</small>                                                     
                        </div>
                    </div><hr />
                    {
                        [...primary, ...secondary].map(skill => (
                            <div className='row mb-1'>
                                <div className="col-lg-4">
                                    <small>{skill.skills[0]}</small>
                                </div>
                                <div className="col-lg-2">
                                    <small>{skill.yearsOfExp}</small>
                                </div>
                                <div className="col-lg-2">
                                    <small>{skill.monthsOfExp}</small>
                                </div>
                                <div className="col-lg-4 d-flex">   
                                    {
                                        skill.candidateResponse ?
                                        <small>{skill.candidateResponse}</small> :
                                        <small>Response not Submitted</small>                                                                      
                                    }                                                            
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </>
    )
}

export default SkillMatrix
