import React, { useState } from "react";
import sparkles from "../../../assets/lottie/sparkles.json";
import checkMark from "../../../assets/lottie/checkmark.json";
import Lottie from "react-lottie-player";
import ReactAnime from "react-animejs";
import { toast } from "react-toastify";
import { post } from "../../../service/ApiService";
import Loader from "../../Loader/Loader";
import { getUserRole } from "../../../service/AuthService";

const { Anime } = ReactAnime;
function ActivateTenant(props) {
  const [enableValue, setenable] = useState(false);
  const [talentEmailChecked, setTalentEmailChecked] = useState(false);
  const [loop] = useState(false);
  const [loading, setLoading] = useState(false);
  props.parent == "tenantDetailsPopUp" && props?.setOpenDropDownDiv(false);

  const close = () => {
    props.parent == "tenantDetailsPopUp" && props.closeModal();
    props.handleSuccess(true);
    props.parent == "tenantDetailsPopUp" && props.setopenTenantPopUP(false);
  };
  const handleCheck = (e) => {
    if (e.target.checked) {
      setTalentEmailChecked(true);
    } else {
      setTalentEmailChecked(false);
    }
  };
  const enableFun = async () => {
    // api call to send data
    const clientName =
      props.tenantObj.firstName + " " + props.tenantObj.lastName;
    const tenantName = props.tenantName;
    const userid = props.userid;
    let email = "";
    if (talentEmailChecked === true) {
      email = props.tenantEmail;
    }
    const paramters = {
      tenantName,
      userid,
      email,
      clientName,
      emailAddress: props.tenantEmail,
      tenantType: props.tenantObj.typename,
    };
    try {
      setLoading(true);
      const res = await post("/tenant/enablebyname", paramters);
      if (res.status === 200) {
        setenable(true);
        setLoading(false);
        props.getTenantData();
      } else {
        toast.error("Error occured while updating record");
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error Occured While accessing Tenant Details");
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div>
        {props.employeeenable !== "employeeenable" ? (
          <div className="bl-modal-body  text-center">
            <div className="avatar avatar-lg avatar-red2 mr-3">
              <i className="fas fa-exclamation-triangle fa-fw"></i>
            </div>
            <p className="text-center lead mt-3">
              Are you sure you want to enable this Employee?
            </p>
            <div className="text-center my-4">
              <button
                type="button"
                className="btn btn-secondary mr-3"
                data-dismiss="modal"
                onClick={close}
              >
                Cancel
              </button>
              <button type="button" className="btn btn-primary">
                Confirm Enable
              </button>
            </div>
          </div>
        ) : enableValue === false ? (
          <div className="bl-modal-body">
            <div className="text-center">
              <div className="avatar avatar-lg avatar-red2">
                <i
                  className="fas fa-exclamation-triangle fa-fw"
                  aria-hidden="true"
                ></i>
              </div>
              {props.users === "users" ? (
                <p className="text-center lead mt-3">
                  Are you sure you want to activate this Client?
                </p>
              ) : (
                <p className="text-center lead mt-3">
                  Are you sure you want to enable this Employee?
                </p>
              )}

              <p>Name: {props.tenantName.replace("GIG - ", "")}</p>
              <div className="col-12 d-flex justify-content-center">
                <div className="form-check form-switch d-flex">
                  <p className="mr-2" style={{ marginTop: 3 }}>
                    Send Email To{" "}
                    {props?.tenantObj?.typename || props.tenantType}
                  </p>
                  <div>
                    <input
                      name="candidateEmail"
                      className="form-check-input ms-1"
                      type="checkbox"
                      onChange={(e) => handleCheck(e)}
                      checked={talentEmailChecked ? true : false}
                    />
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-8 d-flex align-items-center  mb-2 mb-lg-0 justify-content-end justify-content-lg-start">
                <div className="form-check form-switch d-flex align-items-center text-center">
                  <label className="form-check-label pt-3" htmlFor="mailSwitch">
                    <p> Send Email To {props.tenantType}</p>
                  </label>
                  <input
                    name="candidateEmail"
                    className="form-check-input ml-3"
                    type="checkbox"
                    onChange={(e) => handleCheck(e)}
                    checked={talentEmailChecked ? true : false}
                  />
                </div>
              </div> */}
            </div>
            <div className="text-center my-4">
              <button
                type="button"
                data-dismiss="modal"
                className="btn btn-secondary mr-2"
                onClick={close}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={enableFun}
              >
                <span>Activate</span>{" "}
              </button>
            </div>
          </div>
        ) : (
          <div
            className="section section-sm pb-0 d-block"
            style={{ display: "none" }}
          >
            <div
              id="EmployeeManageSuccessAnimation"
              className="success-animation"
            >
              <div className="checkmark">
                <Lottie loop={loop} animationData={checkMark} play></Lottie>
              </div>
              <Anime
                initial={[
                  {
                    targets: "#LottieSparkles",
                    opacity: 0,
                    delay: 1000,
                  },
                ]}
              >
                <div className="sparkles">
                  <Lottie
                    loop={loop}
                    animationData={sparkles}
                    id="LottieSparkles"
                    play
                  ></Lottie>
                </div>
              </Anime>
            </div>
            <div className="success-message" style={{ opacity: "1" }}>
              <div className="container">
                <div className="col-lg-12">
                  <h4 className="text-center">
                    {props.users === "users"
                      ? "Client Activated!"
                      : "Employee enabled!"}
                  </h4>
                  <div className="text-center">
                    {props.users === "users" ? (
                      <p>The selected client has been Activated</p>
                    ) : (
                      <p>The selected employee has been enabled</p>
                    )}
                  </div>
                  <div className="card card-flat card-lg">
                    <div className="card-body">
                      <div className="mb-2">
                        <label className="d-block font-bold pt-0">
                          {props.users !== "users" ? "Employee" : "Client"}
                        </label>
                        <div>{props.tenantName.replace("GIG - ", "")}</div>
                      </div>
                      {props.users !== "users" ? (
                        <>
                          <div className="mb-2">
                            <label className="d-block font-bold pt-0">
                              Email
                            </label>
                            <div>name@company.com</div>
                          </div>
                          <div className="mb-2">
                            <label className="d-block font-bold pt-0">
                              Role
                            </label>
                            <div>Recruiter</div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                  <div className="text-center py-4">
                    <button
                      type="button"
                      data-dismiss="modal"
                      className="btn btn-secondary mr-2"
                      onClick={close}
                    >
                      Close
                    </button>
                    <button
                      type="button"
                      class="close p-0 bl-modal-close-btn"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => close()}
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default ActivateTenant;
