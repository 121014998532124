import React, { useEffect, useState } from "react";
import ReactToExcel from "react-html-table-to-excel";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../../../Loader/Loader";
import { toast } from "react-toastify";
import { get, post } from "../../../../service/ApiService";
import moment from "moment";
import Sort from "../../../common/Sort";
import OutsideClickHandler from "react-outside-click-handler";
import SideNavBar from "../../../layout/SideNavBar";
import ReportTopNavBar from "../../../report/ReportTopNavBar";
import Filter from "./Filter";
import ReportHeaders from "./ReportHeaders";
import ReportTable from "./ReportTable";
import NoRecord from "../../commoncomponents/norecord/NoRecord";
import TopNavBar from "../../../layout/TopNavBar";
import { getTenantType } from "../../../../service/AuthService";
import Footer from "../../../layout/Footer";

function Index() {
  const reportHeaders = [
    { id: 0, name: "S.No" },
    { id: 1, name: "Name" },
    { id: 2, name: "Email" },
    { id: 3, name: "Phone no" },
    { id: 4, name: "Location" },
    { id: 5, name: "Experience" },
    { id: 6, name: "Language" },
    { id: 7, name: "Added on" },
    { id: 8, name: "Status" },
    { id: 9, name: "Candidates added" },
    { id: 10, name: "No. of candidates" },
    { id: 11, name: "Last login" },
  ];
  const [userId, setUserId] = useState({
    id: "",
    l: false,
    s: false,
    i: false,
    r: false,
  });
  const [pageLimit, setPageLimit] = useState(3);
  const [toggle, setToggle] = useState(true);
  const [reportData, setReportData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [columns, setColumns] = useState([]);
  const [dateSearch, setDateSearch] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageType, setPageType] = useState("");
  const [fromDate, setFromDate] = useState(
    new Date(new Date().setDate(new Date().getDate() - 7))
  );
  const [mounted, setMounted] = useState(false);
  const [toDate, setToDate] = useState(new Date());
  const [paginationGroup, setPaginationGroup] = useState([]);
  const [paginationArray, setPaginationArray] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [data, setData] = useState([]);
  const [filter, setFilter] = useState(false);
  const [vetting, setVetting] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedTitles, setSelectedTitles] = useState([]);
  const [primarySkills, setPrimarySkills] = useState([]);
  const [candidateLocations, setCandidateLocations] = useState([]);
  const [candidateTitles, setCandidateTitles] = useState([]);
  const [candidateSkills, setCandidateSkills] = useState([]);
  const [dataArray, setDataArray] = useState([]);
  const [sort, setSort] = useState(false);
  const [sortCount, setSortCount] = useState(0);
  const [sortArray, setSortArray] = useState([]);
  const [sortOrder, setSortOrder] = useState(
    new Array(3).fill().map((_) => "asc")
  );
  const [gigRecruiters, setGigRecruiters] = useState([]);
  const [filterBy, setFilterBy] = useState(reportData);

  //   FIlter
  const [selectedName, setSelectedName] = useState([]);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [selectedExp, setSelectedExp] = useState([]);
  const [selectedAddedOn, setSelectedAddedOn] = useState([]);
  const [selectedCandidatesAdded, setSelectedCandidatesAdded] = useState([]);
  const [selectedSource, setSelectedSource] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [memberFromType, setMemberFromType] = useState("");
  const [memberFrom, setMemberFrom] = useState(["", ""]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [country, setCountry] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [viewAllSkill, setViewAllSkill] = useState(false);
  //   const [recruiter, setRecruiters] = useState(registeredRecruiter);
  const [yearsRange, setYearsRange] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedIndustries, setSelectedIndustries] = useState([]);
  const [userType, setUserType] = useState([]);
  const [exp, setExp] = useState([]);
  const [addedOn, setAddedOn] = useState([]);
  const [ccandidatesAdded, setCcandidatesAdded] = useState([]);
  const [source, setSource] = useState([]);
  const [status, setStatus] = useState([]);
  const [name, setName] = useState([]);
  const [gigRecruiter, setGigRecruiter] = useState([]);
  const [allRec, setAllRec] = useState([]);
  const [dataa, setDataa] = useState([]);

  const sortObject = [
    {
      id: 1,
      label: "Name",
      value: "name",
      icon: "fal fa-fw fa-signature  mr-2 dd-sort",
    },
    {
      id: 2,
      label: "Email",
      value: "email",
      icon: "fal fa-fw fa-signature mr-2 dd-sort",
    },
    {
      id: 3,
      label: "Added on",
      value: "createddatetime",
      icon: "fal fa-fw fa-calendar-alt mr-2 dd-sort",
    },
    {
      id: 4,
      label: "Status",
      value: "status",
      icon: "fal fa-fw fa-signature mr-2 dd-sort",
    },
    {
      id: 5,
      label: "No. of candidates",
      value: "noOfCandidate",
      icon: "fal fa-fw fa-map-marker-alt mr-2 dd-sort",
    },
  ];
  const orderList = [
    {
      value: "asc",
      label: "Ascending",
      icon: "fal fa-sort-amount-down mr-2 dd-sort",
    },
    {
      value: "desc",
      label: "Descending",
      icon: "fal fa-sort-amount-up mr-2 dd-sort",
    },
  ];

  const toggleDiv = (type) => {
    setToggle(!toggle);
  };

  useEffect(() => {
    getRecruiterData();
  }, []);

  useEffect(() => {
    setDataArray(filteredData);
  });
  useEffect(() => {
    if (pageType === "up") {
      if ((currentPage - 1) % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - 1, currentPage + (pageLimit - 1))
        );
      }
    }
    if (pageType === "down") {
      if (currentPage % pageLimit === 0 && currentPage !== 1) {
        setPaginationGroup(
          paginationArray.slice(currentPage - pageLimit, currentPage)
        );
      }
    }
  }, [currentPage]);
  //! Pagination

  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const handleDateSearch = () => {
    setSelectedLocations([]);
    setSelectedTitles([]);
    setPrimarySkills([]);
    setVetting(false);
    //setMounted(true)
    let startDate = moment(new Date(fromDate)).format("MM/DD/YYYY");
    let endDate = moment(new Date(toDate)).format("MM/DD/YYYY");
    if (new Date(startDate) > new Date()) {
      toast.error(`'From Date' cannot be future date`);
    } else if (new Date(endDate) > new Date()) {
      toast.error(`'To Date' cannot be future date`);
    } else if (new Date(startDate) > new Date(endDate)) {
      toast.error(`'From Date' should not be greater than 'To Date'`);
    } else {
      setCurrentPage(1);
      getRecruiterData();
      let result = [];
      let title = [];
      let location = [];
      let skills = [];
      result.forEach((item) => {
        if (item.currentJobTitle) {
          if (!title.includes(item.currentJobTitle)) {
            title.push(item.currentJobTitle.trim());
          }
        }
        if (item.primarySkills) {
          if (!skills.includes(item.primarySkills)) {
            skills.push(...item.primarySkills);
          }
        }
        if (item.addressState && item.country) {
          if (!location.includes(`${item.addressState}, ${item.country}`)) {
            location.push(`${item.addressState}, ${item.country}`);
          }
        }
      });
      setCandidateTitles(title);
      setCandidateSkills([...new Set(skills)]);
      setCandidateLocations(location);
      setDateSearch(result);
      setFilteredData(result);
      setDataa(result);
      setPaginationArray(
        new Array(Math.ceil(result.length / perPage))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(result.length / perPage))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageLimit)
      );
    }
  };

  const getRecruiterData = async () => {
    let name = [];
    let exp = [];
    let addedOn = [];
    let ccandidatesAdded = [];
    let userType = [];
    let source = [];
    let status = [];
    let country = [];
    setLoading(true);
    let params = {
      toDate: moment(new Date(toDate)).format("YYYY-MM-DD"),
      fromDate: moment(new Date(fromDate)).format("YYYY-MM-DD"),
    };
    let res = await post("/reports/recruiter", params);
    if (res.status === 200) {
      let filtered = res.data;
      if (filtered.length == 0) {
        toast.error("No record found. Please select another date range");
      }
      filtered.forEach((item) => {
        item.createddatetime = moment(item.createddatetime).format(
          "MM/DD/YYYY"
        );
      });
      setFilteredData(filtered);
      setDataa(filtered);
      setData(filtered);
      filtered.forEach((item) => {
        if (item.name) {
          if (!name.includes(item.name)) {
            name.push(item.name.trim());
          }
        }
        if (item.yearsOfExperience) {
          if (!exp.includes(item.yearsOfExperience)) {
            exp.push(item.yearsOfExperience);
          }
        }
        if (item.createddatetime) {
          if (!addedOn.includes(item.createddatetime)) {
            addedOn.push(item.createddatetime);
          }
        }
        if (item.noOfCandidate > 0) {
          if (!ccandidatesAdded.includes("Yes")) {
            ccandidatesAdded.push("Yes");
          }
        }
        if (item.noOfCandidate === 0) {
          if (!ccandidatesAdded.includes("No")) {
            ccandidatesAdded.push("No");
          }
        }
        if (item.companyName) {
          if (!userType.includes(item.companyName)) {
            userType.push(item.companyName);
          }
        }
        if (item.source) {
          if (!source.includes(item.source)) {
            source.push(item.source);
          }
        }
        if (item.status) {
          if (!status.includes(item.status)) {
            status.push(item.status);
          }
        }
        if (item.country) {
          if (!country.includes(item.country)) {
            country.push(item.country);
          }
        }
      });
      setName(name);
      setUserType(userType);
      setExp(exp);
      setAddedOn(addedOn);
      setCcandidatesAdded(ccandidatesAdded);
      setSource(source);
      setStatus(status);
      setCountry(country);
      setPaginationArray(
        new Array(Math.ceil(filtered.length / perPage))
          .fill()
          .map((_, i) => i + 1)
      );
      setPaginationGroup(
        new Array(Math.ceil(filtered.length / perPage))
          .fill()
          .map((_, i) => i + 1)
          .slice(0, pageLimit)
      );
      setLoading(false);
    }
  };

  const onChangeSelect = (e) => {
    if (e.target.value === "All") {
      setPerPage(filteredData.length);
      setPaginationArray([1]);
      setPaginationGroup([1]);
      setCurrentPage(1);
    } else {
      const pageGroup = new Array(
        Math.ceil(filteredData.length / e.target.value)
      )
        .fill()
        .map((_, i) => i + 1);
      setPaginationArray(pageGroup);
      setPaginationGroup(pageGroup.slice(0, pageLimit));
      setPerPage(Number(e.target.value));
      setCurrentPage(1);
    }
  };
  const getPaginatedData = () => {
    const startIndex = currentPage * perPage - perPage;
    const endIndex = startIndex + +perPage;
    return filteredData.slice(startIndex, endIndex);
  };
  const applySort = (sortedData, close) => {
    close && setSort(false);
    setFilterBy(sortedData);
    setFilteredData(sortedData);
  };

  const sortClearAll = () => {
    setSortArray([]);
    setSortCount(0);
    applySort(filteredData, true);
  };

  const openFilter = () => {
    setFilter(true);
  };

  const applyFilter = (
    data,
    selectedName,
    selectedExp,
    selectedAddedOn,
    selectedCandidatesAdded,
    selectedStatus,
    memberFromType,
    memberFrom
  ) => {
    sortClearAll();
    setFilter(false);
    setSelectedName(selectedName);
    setSelectedUserType(selectedUserType);
    setSelectedExp(selectedExp);
    setSelectedAddedOn(selectedAddedOn);
    setSelectedCandidatesAdded(selectedCandidatesAdded);
    setSelectedSource(selectedSource);
    setSelectedStatus(selectedStatus);
    setSelectedCountry(selectedCountry);
    setSelectedLanguages(selectedLanguages);
    setSelectedIndustries(selectedIndustries);
    setMemberFromType(memberFromType);
    setMemberFrom(memberFrom);
    setFilteredData(data);
    setFilterBy(data);

    if (data.length === 0) {
      return toast.error("No Record Found");
    }
    setCurrentPage(1);
    setPaginationGroup(
      new Array(Math.ceil(data.length / perPage))
        .fill()
        .map((_, i) => i + 1)
        .slice(0, pageLimit)
    );
    setPaginationArray(
      new Array(Math.ceil(data.length / perPage)).fill().map((_, i) => i + 1)
      // .slice(0, pageLimit)
    );
  };

  const getCount = (email) => {
    let find = allRec.filter((f) => f.email == email);
    return find.length == 0 ? "NA" : find[0].count == 0 ? "NA" : find[0].count;
  };
  return (
    <div>
      {loading && <Loader />}
      <body
        className={
          toggle === true ? "layout1 layout1-closed close-menu" : "layout1"
        }
      >
        <div className="body-decorator body-decorator-top"></div>
        <div className="body-decorator body-decorator-bottom"></div>
        <TopNavBar isFrom={"Report"} handleToggale={toggleDiv} />
        {/* <ReportTopNavBar handleToggale={toggleDiv} /> */}
        <SideNavBar handleToggale={toggleDiv} />
        <div id="Content">
          <div className="section section-s">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-12">
                  <div className="card card-flat card-borderless p-4 mb-3">
                    <ReportHeaders
                      memberFrom={memberFrom}
                      dataa={dataa}
                      fromDate={fromDate}
                      setFromDate={setFromDate}
                      toDate={toDate}
                      setToDate={setToDate}
                      handleDateSearch={handleDateSearch}
                      setSort={setSort}
                      sort={sort}
                      filteredData={filteredData}
                      sortCount={sortCount}
                      orderList={orderList}
                      sortObject={sortObject}
                      applySort={applySort}
                      setSortCount={setSortCount}
                      setSortArray={setSortArray}
                      openFilter={openFilter}
                      onChangeSelect={onChangeSelect}
                      sortArray={sortArray}
                      selectedName={selectedName}
                      selectedUserType={selectedUserType}
                      selectedAddedOn={selectedAddedOn}
                      selectedCandidatesAdded={selectedCandidatesAdded}
                      selectedExp={selectedExp}
                      selectedSource={selectedSource}
                      selectedStatus={selectedStatus}
                      selectedCountry={selectedCountry}
                    />
                    {filteredData.length > 0 ? (
                      <ReportTable
                        getPaginatedData={getPaginatedData}
                        reportHeaders={reportHeaders}
                        currentPage={currentPage}
                        perPage={perPage}
                        userId={userId}
                        setUserId={setUserId}
                        getCount={getCount}
                      />
                    ) : (
                      <NoRecord />
                    )}
                    {getPaginatedData().length > 0 && (
                      <div className="d-flex justify-content-end pt-4 px-1">
                        <div className="w-100-370 mb-point5-370">
                          Showing{" "}
                          {(getPaginatedData().length === perPage
                            ? perPage * currentPage
                            : perPage * (currentPage - 1) +
                              getPaginatedData().length) + " "}
                          of {filteredData?.length}
                        </div>
                        <div
                          className="ml-auto mx-auto-370 d-flex align-items-center"
                          // style={{ position: 'absolute', left: '85%' }}
                        >
                          <button
                            onClick={() => {
                              setPageType("down");
                              setCurrentPage((page) => page - 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === 1 ||
                              getPaginatedData().length === 0
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-left"></i>
                          </button>
                          {getPaginatedData().length > 0
                            ? paginationGroup.map((item) => (
                                <button
                                  type="button"
                                  className={
                                    currentPage === item
                                      ? "btn btn-text px-2 bg-secondary text-white"
                                      : "btn btn-text px-2"
                                  }
                                  onClick={changePage}
                                >
                                  {item}
                                </button>
                              ))
                            : ""}
                          <button
                            onClick={() => {
                              setPageType("up");
                              setCurrentPage((page) => page + 1);
                            }}
                            type="button"
                            disabled={
                              currentPage === paginationArray.slice(-1)[0] ||
                              getPaginatedData().length === 0
                                ? true
                                : false
                            }
                            className="btn btn-icon px-2"
                          >
                            <i className="fal fa-chevron-circle-right"></i>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {getTenantType() == 1 && <Footer />}
        </div>
      </body>
      {filter && (
        <OutsideClickHandler onOutsideClick={() => setFilter(false)}>
          <Filter
            reportData={data}
            selectedName={selectedName}
            candidateName={name}
            candidateExp={exp}
            memberFrom={memberFrom}
            memberFromType={memberFromType}
            selectedExp={selectedExp}
            candidateAddedOn={addedOn}
            selectedAddedOn={selectedAddedOn}
            ccandidatesAdded={ccandidatesAdded}
            selectedCandidatesAdded={selectedCandidatesAdded}
            selectedUserType={selectedUserType}
            candidateUserType={["Individual Recruiter", "Recruitment Firm"]}
            selectedSource={selectedSource}
            candidateSource={["Invite", "Website"]}
            selectedStatus={selectedStatus}
            candidateStatus={["Active", ...status]}
            selectedCountry={selectedCountry}
            candidateCountry={country}
            applyFilter={applyFilter}
          />
        </OutsideClickHandler>
      )}
    </div>
  );
}
export default Index;
