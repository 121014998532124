import moment from "moment";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { vetStartUrl } from "../../../../constants/Constants";

const CandidateAssessments = ({ candidateData, component, jobData }) => {
  const { vettingDetails } = candidateData;
  const data = JSON.parse(vettingDetails[0].data);
  const testInfo = JSON.parse(vettingDetails[0].testInfo);
  const [MCQInterview, setMCQInterview] = useState(false);
  const onCollapsibleClick = (val) => {
    if (val === "MCQInterview") {
      setMCQInterview(true);
    }
    if (val === "MCQInterviewReset") {
      setMCQInterview(false);
    }
  };
  const handleCopy = (eachdata) => {
    navigator.clipboard.writeText(eachdata);
    toast.success("Link Copied Successfully!!!");
  };

  return (
    <div>
      {vettingDetails?.length > 0 && (
        <div>
          <div className="mb-2">
            <button type="button" className="btn btn-collapsible">
              {MCQInterview !== true ? (
                <i
                  className="fal fa-angle-down fa-fw"
                  onClick={() => onCollapsibleClick("MCQInterview")}
                ></i>
              ) : (
                <i
                  className="fal fa-angle-up fa-fw mt-1"
                  onClick={() => onCollapsibleClick("MCQInterviewReset")}
                ></i>
              )}
              <span>
                <small>General</small>
              </span>
            </button>
          </div>
          {vettingDetails?.map((elem, ind) => (
            <div
              className={
                MCQInterview === true
                  ? " card ml-4 mb-4 d-none"
                  : "card ml-4 mb-4"
              }
            >
              <div className="card-body">
                <table className="table table-hoverable">
                  <tbody>
                    <tr className="mb-1">
                      <td>
                        <div className="col-lg-12 d-flex justify-content-between">
                          <h6 className="mb-1">
                            {
                              JSON.parse(elem?.testInfo)?.testName?.split(
                                "_"
                              )[0]
                            }
                          </h6>
                          {/* {elem?.status === "completed" && (
                            <button
                              type="button"
                              className="btn btn-sm btn-gray mr-1 mb-1 ml-5"
                              onClick={() =>
                                handleCopy(
                                  `${vetStartUrl}/result/${
                                    data?.candidateId._id
                                      ? data?.candidateId._id
                                      : data?.candidateId
                                  }`
                                )
                              }
                            >
                              <i className="fal fa-share-alt fa-fw-dropdown mr-2" />
                              Share Result
                            </button>
                          )} */}
                          {elem?.status === "completed" && (
                            <button
                              type="button"
                              className="btn btn-sm btn-gray mb-1"
                              onClick={() =>
                                window.open(
                                  `${vetStartUrl}/result/${
                                    data?.candidateId._id
                                      ? data?.candidateId._id
                                      : data?.candidateId
                                  }`,
                                  "_blank"
                                )
                              }
                            >
                              <i className="fal fa-list-alt mr-2" />
                              View Result
                            </button>
                          )}
                        </div>
                        <div class="py-2 mt-2 w-100">
                          <span
                            class={
                              elem?.status === "completed"
                                ? "mr-2 tag font-bold tag-green1 mb-2"
                                : elem?.status === "underReview"
                                ? "mr-2 tag font-bold tag-lightBlue mb-2"
                                : "mr-2 tag font-bold tag-orange1 mb-2"
                            }
                          >
                            {" "}
                            Status :{" "}
                            {elem?.status === "completed"
                              ? "Completed"
                              : elem?.status === "underReview"
                              ? "Under Review"
                              : "Invited"}
                          </span>
                          <span class="tag mb-2">
                            <i
                              aria-hidden="true"
                              class="far fa-fw fa-stopwatch mr-2"
                            ></i>
                            Assigned by :{" "}
                            {`${data?.companyInfo?.companyName?.replace(
                              "GIG - ",
                              ""
                            )} `}
                            {"  "}
                          </span>
                          <span class="tag mb-2" style={{ marginLeft: "5px" }}>
                            <i
                              aria-hidden="true"
                              class="fal fa-fw fa-clock mr-1"
                            ></i>{" "}
                            Assigned on :{" "}
                            {elem.createdDate
                              ? moment(elem.createdDate)?.format("MM/DD/YYYY")
                              : "N/A"}{" "}
                          </span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          ))}
        </div>
      )}
      {vettingDetails?.length === 0 && (
        <div className="card card-lg card-borderless card-flat  w-100">
          <div className="card-body text-center">
            <div className="avatar avatar-lg">
              <i className="fad fa-fw fa-folder-open"></i>
            </div>
            <div className="mt-2">No Assessments Found</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CandidateAssessments;
