import React, { useState, useEffect } from "react";
import "react-quill/dist/quill.snow.css";
import ModalView from "../../../../commoncomponents/modal/Modal";
import { get } from "../../../../../../service/ApiService";
import Loader from "../../../../../Loader/Loader";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";

import Pagination from "../../../../../components_v3/pagination/pagination";
import EmailTemplateSelector from "../Email/EmailTemplateSelector";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { getCandidateId } from "../../../../../../service/AuthService";
import { CreateEmailTemplate } from "../../../../../../actions/communication/Communication";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

const EmailTemplates = (props) => {
  const {
    setActiveTab,
    setSelectedOption,
    selectedTalents,
    setSmsContent,
  } = props.data;
  const dispatch = useDispatch();

  const { createEmailTempLoading } = useSelector(
    (state) => ({
      createSMSEmailLoading: state.communicationReducer.createEmailTempLoading,
    }),
    shallowEqual
  );
  const [loading, setLoading] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);
  const [emailTemplates, setEmailTemplates] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageMyTemp, setCurrentPageMyTemp] = useState(1);
  const [currentTemp, setCurrentTemp] = useState(null);
  const [createTemplate, setCreateTemplate] = useState(false);

  // State variables for form fields
  const [eventName, setEventName] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [footer, setFooter] = useState("");
  const [time, setTime] = useState("");
  const [emailFrequency, setEmailFrequency] = useState("");
  const [dynamicValues, setDynamicValues] = useState("");

  const dataSize = 10;

  // Calculate the index range of jobs to display on the current page
  // const indexOfLastJob = currentPage * dataSize;
  // const indexOfFirstJob = indexOfLastJob - dataSize;

  const myTemplates = emailTemplates.filter(
    (item) => item.userId === getCandidateId()
  );
  const basicTemplates = emailTemplates.filter(
    (item) => item.userId !== getCandidateId()
  );
  const startIndex = (currentPage - 1) * dataSize;
  const endIndex = startIndex + dataSize;

  const currentData = basicTemplates.slice(startIndex, endIndex);

  const startIndexMyTemp = (currentPageMyTemp - 1) * dataSize;
  const endIndexMyTemp = startIndexMyTemp + dataSize;

  const currentDataMyTemp = myTemplates.slice(startIndex, endIndex);

  // const currentData = emailTemplates.slice(indexOfFirstJob, indexOfLastJob);

  const [selectedDynamicValues, setSelectedDynamicValues] = useState([]);
  const [dynamicValuesOptions, setDynamicValuesOptions] = useState([]);
  const [newDynamicValue, setNewDynamicValue] = useState("");
  const [previewNewEmail, setPreviewNewEmail] = useState(false);

  const handleDynamicValuesChange = (selectedOptions) => {
    setSelectedDynamicValues(selectedOptions);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && newDynamicValue.trim() !== "") {
      const newOption = { value: newDynamicValue, label: newDynamicValue };
      setDynamicValuesOptions((prevOptions) => [...prevOptions, newOption]);
      setSelectedDynamicValues((prevValues) => [...prevValues, newOption]);
      setNewDynamicValue("");
    }
  };

  // Function to handle page changes
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      setLoading(true);
      const response = await get("/broadCast/getEmailTemplates");
      if (response.status === 200) {
        setLoading(false);
        setEmailTemplates(response.data);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };

  const renderMailContent = (template) => {
    const maxContentLength = 160;
    const truncatedContent =
      template.mailContent.length > maxContentLength
        ? `${template.mailContent
            .replace(/<[^>]*>/g, "")
            .substring(0, maxContentLength)}...`
        : template.mailContent.replace(/<[^>]*>/g, "");

    return (
      <div className="card-text" style={{ fontWeight: "lighter" }}>
        {truncatedContent}
      </div>
    );
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Check if all required fields are filled
    if (!eventName || !time || !emailFrequency || !content) {
      alert("Please fill in all required fields.");
      return;
    }
    setPreviewNewEmail(true);
  };
  const createNewEmailTemplate = () => {
    // let reqPayload = {
    //   events: eventName,
    //   mailSubject: subject,
    //   mailContent: content.replace(/<[^>]*>/g, ""),
    //   footer: "",
    //   sender: "",
    //   sendSms: true,
    //   recipients: "Candidate",
    //   time: time,
    //   mailLogo: "",
    //   history: "",
    //   mailFrequency: emailFrequency,
    //   userId: getCandidateId(),
    //   // dynamicValues: selectedDynamicValues.map((option) => option.value),
    // };
    let reqPayload = {
      events: eventName,
      subject: subject,
      content: content,
      footer: footer,
      senderMail: "alerts@high5hire.com",
      recipients: '["Candidate"]',
      emailFrequency: emailFrequency,
      time: time,
      description: "",
      dynamicValues: selectedDynamicValues.map((option) => option.value),
      userId: getCandidateId(),
    };
    dispatch(CreateEmailTemplate(reqPayload));
    setEventName("");
    setSubject("");
    setTime("");
    setFooter("");
    setEmailFrequency("");
    setContent("");
    setSelectedDynamicValues([]);
    setNewDynamicValue("");
    fetchTemplates();
  };
  return (
    <>
      {(loading || createEmailTempLoading) && <Loader />}
      {!templateModal && !createTemplate && (
        <>
          <Container className="mt-4 vh-80" style={{ fontSize: "14px" }}>
            <h6>My Templates</h6>
            <Row className="d-flex mb-2">
              <Col md={4} lg={4} sm={10}>
                <Card
                  className="emailTempCards"
                  style={{
                    width: "100%",
                    minHeight: "200px",
                    margin: "10px",
                  }}
                >
                  <Card.Body>
                    <Button
                      variant="link"
                      onClick={() => {
                        setCreateTemplate(true);
                      }}
                    >
                      <i className="fas fa-plus-circle mr-2"></i>Create New
                      Template
                    </Button>
                  </Card.Body>
                  <Card.Body>
                    <p className="p-3 text-center">
                      Craft your own templates for future use.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              {currentDataMyTemp.map((template) => (
                <Col md={4} lg={4} sm={10}>
                  <Card
                    className="emailTempCards"
                    style={{
                      width: "100%",
                      minHeight: "200px",
                      margin: "10px",
                    }}
                  >
                    <Card.Header>{template.events}</Card.Header>
                    <Card.Body>
                      {renderMailContent(template)}
                      <Button
                        variant="primary"
                        size="sm"
                        style={{
                          position: "absolute",
                          bottom: "11px",
                          right: "11px",
                          padding: "6px",
                          // fontSize: "14px",
                        }}
                        onClick={() => {
                          setCurrentTemp(template);
                          setTemplateModal(true);
                        }}
                      >
                        View
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
            {myTemplates && myTemplates.length > 0 && (
              <>
                <Pagination
                  currentPage={currentPageMyTemp}
                  totalItems={myTemplates?.length}
                  itemsPerPage={dataSize}
                  onPageChange={handlePageChange}
                  limit={10}
                />
              </>
            )}
            <hr />
            <h6>Standard Templates</h6>

            <Row className="d-flex">
              {currentData.map((template) => (
                <Col md={4} lg={4} sm={10} key={template.id_smsTemplate}>
                  <Card
                    className="emailTempCards"
                    style={{
                      width: "100%",
                      minHeight: "200px",
                      margin: "10px",
                    }}
                  >
                    <Card.Header>{template.events}</Card.Header>
                    <Card.Body>
                      {renderMailContent(template)}
                      <Button
                        variant="primary"
                        size="sm"
                        style={{
                          position: "absolute",
                          bottom: "11px",
                          right: "11px",
                          padding: "6px",
                          // fontSize: "14px",
                        }}
                        onClick={() => {
                          setCurrentTemp(template);
                          setTemplateModal(true);
                        }}
                      >
                        View
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
          {basicTemplates && basicTemplates.length > 0 && (
            <>
              <Pagination
                currentPage={currentPage}
                totalItems={basicTemplates?.length}
                itemsPerPage={dataSize}
                onPageChange={handlePageChange}
                limit={10}
              />
            </>
          )}
        </>
      )
      // : (
      //   <EmailTemplateSelector
      //     modalSize={"lg"}
      //     modalTitle={"Select Template"}
      //     isModalOpen={templateModal}
      //     onClose={() => setTemplateModal(false)}
      //     isFrom="smsTemplate"
      //     modalFor="selectTemplate"
      //     setEmailContent={setSmsContent}
      //     setActiveTab={setActiveTab}
      //     template={currentTemp}
      //     selectedTalents={selectedTalents}
      //   />
      // )
      }
      {templateModal && (
        <ModalView
          modalSize="lg"
          //   modalTitle="Select Template"
          isModalOpen={templateModal}
          setModalClose={setTemplateModal}
          isFrom="EmailBroadcast"
          modalFor="selectTemplate"
          data={{
            currentTemp: currentTemp,
            setActiveTab: setActiveTab,
            setSelectedOption: setSelectedOption,
            setSmsContent: setSmsContent,
          }}
        />
      )}
      {!templateModal && createTemplate && (
        <>
          <Container className="mt-4 vh-80" style={{ fontSize: "14px" }}>
            <h6>Create New Template</h6>
            <Row>
              <Col md={12}>
                <form
                  onSubmit={handleSubmit}
                  className="row needs-validation w-100"
                  noValidate
                >
                  <div className="col-md-6">
                    <label htmlFor="eventName">Template Name *</label>
                    <input
                      type="text"
                      className="form-control font-13"
                      id="eventName"
                      value={eventName}
                      onChange={(e) => setEventName(e.target.value)}
                      required
                      style={{
                        fontSize: "13px",
                      }}
                    />

                    <div className="invalid-feedback">
                      Please enter an event name.
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="time">Schedule sent time *</label>
                    <input
                      type="text"
                      className="form-control font-13"
                      id="time"
                      value={time}
                      onChange={(e) => setTime(e.target.value)}
                      required
                      style={{
                        fontSize: "13px",
                      }}
                    />

                    <div className="invalid-feedback">Please enter a time.</div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="emailFrequency">Schedule frequency *</label>
                    <input
                      type="text"
                      className="form-control font-13"
                      id="emailFrequency"
                      value={emailFrequency}
                      onChange={(e) => setEmailFrequency(e.target.value)}
                      required
                      style={{
                        fontSize: "13px",
                      }}
                    />

                    <div className="invalid-feedback">
                      Please enter an Email frequency.
                    </div>
                  </div>

                  <div className="col-md-6">
                    <label htmlFor="dynamicValues">
                      Define Dynamic Values{" "}
                    </label>
                    <Select
                      options={dynamicValuesOptions}
                      value={selectedDynamicValues}
                      onChange={handleDynamicValuesChange}
                      isMulti
                    />
                    <input
                      type="text"
                      className="form-control mt-1"
                      placeholder="Add new value..."
                      style={{
                        fontSize: "13px",
                      }}
                      value={newDynamicValue}
                      onChange={(e) => setNewDynamicValue(e.target.value)}
                      onKeyPress={handleKeyPress}
                    />
                    <div className="invalid-feedback">
                      Please select dynamic values.
                    </div>
                  </div>
                  <div className="col-md-12">
                    <label htmlFor="subject">Template Subject *</label>
                    <input
                      type="text"
                      className="form-control font-13"
                      id="subject"
                      value={subject}
                      onChange={(e) => setSubject(e.target.value)}
                      required
                      style={{
                        fontSize: "13px",
                      }}
                    />

                    <div className="invalid-feedback">
                      Please enter Subject.
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="content">Content * </label>
                    <small style={{ marginLeft: "400px" }}>
                      <i>
                        For dynamic value follow the format of example - [First
                        Name]
                      </i>
                    </small>

                    <ReactQuill
                      value={content}
                      onChange={setContent}
                      style={{ overflow: "auto", fontWeight: "normal" }}
                      theme="snow"
                      required
                    />

                    <div className="invalid-feedback">
                      Please enter content.
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="content">Footer *</label>
                    <ReactQuill
                      value={footer}
                      onChange={setFooter}
                      style={{ overflow: "auto", fontWeight: "normal" }}
                      theme="snow"
                      required
                    />

                    <div className="invalid-feedback">Please enter footer.</div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      type="cancel"
                      className="btn btn-sm btn-success m-2"
                      onClick={() => setCreateTemplate(false)}
                    >
                      Back
                    </button>
                    <button
                      type="submit"
                      className="btn btn-sm btn-primary m-2"
                    >
                      Preview
                    </button>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </>
      )}
      {previewNewEmail && (
        <>
          <Modal
            show={previewNewEmail}
            onHide={() => setPreviewNewEmail(false)}
            size="md"
            className="p-2"
          >
            <Modal.Header closeButton>
              <h6>Template Preview</h6>
            </Modal.Header>
            <hr />
            <Modal.Body className="p-3 pt-0" style={{ fontSize: "13px" }}>
              <label htmlFor="eventName">Template Name</label>
              <input
                type="text"
                className="form-control font-13"
                id="eventName"
                value={eventName}
                disabled={true}
                style={{ fontSize: "13px" }}
              />
              <label htmlFor="time">Scheduled sent time</label>

              <input
                type="text"
                className="form-control font-13"
                id="time"
                value={time}
                disabled={true}
                style={{ fontSize: "13px" }}
              />
              <label htmlFor="emailFrequency">Scheduled frequency</label>
              <input
                type="text"
                className="form-control font-13"
                id="smsFrequency"
                value={emailFrequency}
                disabled={true}
                style={{ fontSize: "13px" }}
              />
              <label htmlFor="dynamicValues">Dynamic Values </label>
              <Select
                options={dynamicValuesOptions}
                value={selectedDynamicValues}
                disabled={true}
                isMulti
              />
              <label htmlFor="subject">Subject</label>
              <input
                type="text"
                className="form-control font-13"
                id="subject"
                value={subject}
                disabled={true}
                style={{
                  fontSize: "13px",
                }}
              />
              <label htmlFor="content">Content </label>
              <textarea
                value={content.replace(/<[^>]*>/g, "")}
                disabled={true}
                style={{
                  fontFamily: "Arial, sans-serif",
                  fontSize: "14px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "100%",
                  minHeight: "100px",
                  height: "auto", // Set height to auto
                  resize: "vertical",
                  boxSizing: "border-box",
                  background: "#e9ecef",
                }}
              />
              <label htmlFor="footer">Footer </label>
              <textarea
                value={footer.replace(/<[^>]*>/g, "")}
                disabled={true}
                style={{
                  fontFamily: "Arial, sans-serif",
                  fontSize: "14px",
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  width: "100%",
                  minHeight: "100px",
                  height: "auto", // Set height to auto
                  resize: "vertical",
                  boxSizing: "border-box",
                  background: "#e9ecef",
                }}
              />
              <br />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setPreviewNewEmail(false)}
              >
                Close
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setPreviewNewEmail(false);
                  createNewEmailTemplate();
                }}
              >
                Create Template
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
};

export default EmailTemplates;
