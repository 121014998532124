import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { toast } from "react-toastify";
import { union } from "lodash";
import {
  contactInfoValidation,
  profileInfoValidation,
  experienceValidation,
  educationValidation,
  certificationValidation,
  licenseValidation,
} from "../../../talent/addtalent/validations/ManualEntry";
import { domain } from "../../../../../constants/Constants";
import Contact from "./Contact";
import Loader from "../../../../Loader/Loader";
import { checkEmptyObject } from "../../../../function/CommonFunction";
import { post } from "../../../../../service/ApiService";
import { getCandidateDropdown } from "../../../../../actions/talent/AddTalent";
import Profile from "./Profile";
import Education from "./Education";
import Experience from "./Experience";
import Preferences from "./Preference";
import { parsePhoneNumber } from "libphonenumber-js";
import { useHistory } from "react-router-dom";
import Resume from "./Resume";
import { isValidUrl } from "../../../../function/CommonFunction";

const Index = (props) => {
  const { tenant, recruiterid } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const [tenantData, setTenantData] = useState({});
  const [contact, setContact] = useState(true);
  const [profileInfo, setProfileInfo] = useState(true);
  const [experience, setExperience] = useState(true);
  const [education, setEducation] = useState(true);
  const [certification, setCertification] = useState(false);
  const [license, setLicense] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [preferences, setPreferences] = useState(true);
  const [resume, setResume] = useState(false);
  const [resumeFile, setResumeFile] = useState(false);
  const [load, setLoading] = useState(false);
  const { loading, dropDownData } = useSelector(
    (state) => ({
      loading: state?.AddCandidate?.loading,
      dropDownData: state?.AddCandidate?.dropDownData,
    }),
    shallowEqual
  );
  // Experience child Component
  const [experiences, setExperiences] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
      description: "",
      isSelect: false,
    },
  ]);
  const [experienceVal, setExperienceVal] = useState([
    {
      employerName: "",
      industry: "",
      jobTitle: "",
      startDate: "",
      endDate: "",
    },
  ]);
  // Education Child Component
  const [educations, setEducations] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);
  const [educationVal, setEducationVal] = useState([
    {
      graduatedYear: "",
      educationType: "",
      educationProgram: "",
      school: "",
      major: "",
    },
  ]);
  // Certificate child  Component
  const [certifications, setCertifications] = useState([
    { certificationName: "", certificate: "", issueDate: "", expiryDate: "" },
  ]);
  const [certificationVal, setCertificationVal] = useState([
    { certificationName: "", certificate: "", issueDate: "", expiryDate: "" },
  ]);
  // License child  Component
  const [licenses, setLicenses] = useState([
    { licenseName: "", license: "", state: "", expiryDate: "" },
  ]);
  const [licenseVal, setLicenseVal] = useState([
    { licenseName: "", license: "", state: "", expiryDate: "" },
  ]);
  // Contact Info child  Component
  const [contactInfo, setContactInfo] = useState({
    firstName: props.candidateDetails?.firstName
      ? props.candidateDetails?.firstName
      : "",
    middleName: "",
    lastName: props.candidateDetails?.lastName
      ? props.candidateDetails?.lastName
      : "",
    email:
      props.message === "JoinCommunity"
        ? sessionStorage.getItem("email")
        : props.candidateDetails?.email
        ? props.candidateDetails?.email
        : "",
    zipCode: "",
    address: props.candidateDetails?.address
      ? props.candidateDetails?.address
      : "NA",
    city: props.candidateDetails?.city ? props.candidateDetails?.city : "",
    state: props.candidateDetails?.state ? props.candidateDetails?.state : "NA",
    country: props.candidateDetails?.country
      ? props.candidateDetails?.country
      : "",
    workPhoneCode: "",
    workPhone: props.candidateDetails?.workPhone
      ? parsePhoneNumber(
          props.candidateDetails.workPhone[0] === "+"
            ? props.candidateDetails.workPhone
            : `+${props.candidateDetails.workPhone}`
        ).formatInternational()
      : "",
    homePhoneCode: props.candidateDetails?.homePhoneCode,
    homePhone: props.candidateDetails?.homePhone,
    // ? parsePhoneNumber(
    //     props.candidateDetails.homePhone[0] === "+"
    //       ?
    //       props.candidateDetails.homePhone
    //       : `+${props.candidateDetails.homePhone}`
    //   ).formatInternational()
    // : ""
    phoneValidation: true,
    phoneValidation2: true,
  });
  const [contactVal, setContactVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    zipcode: "",
    address: "",
    city: "",
    state: "",
    country: "",
  });
  // Profile Info child  Component

  const [profileData, setProfileData] = useState({
    facebook: "",
    twitter: "",
    linkedIn: "",
    preferredSalary: props.profileData?.preferredSalary
      ? props.profileData?.preferredSalary
      : "",
    preferredSalaryCurrency: "USD",
    minContractRate: "",
    minContractRateCurrency: "USD",
    description: "",
    fileName: props?.candidateDetails?.fileName
      ? props?.candidateDetails?.fileName
      : "",
    base64: "",
    positionTypes: [],
    preferredLocation: sessionStorage.getItem("email") == null ? "" : "",
    primarySkills:
      props.candidateDetails?.primarySkills.length > 0
        ? props.candidateDetails?.primarySkills
        : [],
    secondarySkills: [],
    skillSet: [],
    source: "Career Page",
    visaStatus: "",
    legallyAuthorized: props.candidateDetails?.legallyAuthorized
      ? props.candidateDetails?.legallyAuthorized
      : false,
    requireSponsorship: props.candidateDetails?.requireSponsorship
      ? props.candidateDetails?.requireSponsorship
      : false,
    designation: props.candidateDetails?.designation
      ? props.candidateDetails?.designation
      : "",

    experienceLevel: "",
    experienceYear: props.candidateDetails?.experienceYear
      ? props.candidateDetails?.experienceYear
      : "0",
    experienceMonth: props.candidateDetails?.experienceMonth
      ? props.candidateDetails?.experienceMonth
      : "0",
    currentJobTitle: props.candidateDetails?.designation
      ? props.candidateDetails?.designation
      : "",
    isFresher: false,
    isRemote: props.candidateDetails?.isRemote
      ? props.candidateDetails.isRemote
      : false,
    isHybrid: props.candidateDetails?.isHybrid
      ? props.candidateDetails.isHybrid
      : false,
    isOnsite: props.candidateDetails?.isOnsite
      ? props.candidateDetails?.isOnsite
      : false,
    highestEducation: props.candidateDetails?.highestEducation
      ? props.candidateDetails?.highestEducation
      : "Bachelor's Degree",
  });
  const [profileVal, setProfileVal] = useState({
    preferredSalaryCurrency: "",
    minContractRateCurrency: "",
    //preferredLocation: [],
    primarySkills: "",
    experienceYear: "",
    experienceMonth: "",
    currentJobTitle: "",
  });

  useEffect(() => {
    getTenant();
  }, []);
  useEffect(() => {}, [contactInfo]);
  useEffect(() => {
    if (!dropDownData) {
      dispatch(getCandidateDropdown());
    }
  }, [dropDownData]);

  useEffect(() => {
    // check if props data is present then set to values
    if (props.parsed) {
      //  profileInfo
      let location = props?.parsedData?.location || "";
      if (props?.parsedData?.isAddedByCrintell) {
        if (props?.parsedData?.profile?.Locations?.length) {
          location = props?.parsedData?.profile?.Locations[0];
        }
      } else if (props?.parsedData?.h5h) {
        if (props?.parsedData?.location?.length) {
          location = props?.parsedData?.location;
        }
      } else {
        if (props.parsedData?.location) {
          location = props?.parsedData?.location;
        }
      }

      // if added by crintell we are checking this data else previous data
      let name1 = props.parsedData?.name?.split(" ");
      const contactInfoObject = props?.parsedData?.h5h
        ? {
            email: props.parsedData?.email,
            address: location ? location?.address : "",
            country: location ? location?.country : "",
            state: location ? location?.state : "",
            city: location ? location?.city : "",
            zipcode: "",
            currentJobTitle: props?.parsedData?.designation,
            firstName: props.parsedData?.name ? name1[0] : "",
            lastName: props.parsedData?.name ? name1[1] : "",
            homePhone: props.parsedData?.phone ? props.parsedData?.phone : "",
          }
        : {
            address: location?.rawInput,
            email: props?.parsedData?.data?.emails.toString(),
            country: location?.country ? location?.country : "",
            state: location?.state ? location?.state : "",
            city: location?.city ? location?.city : "",
            zipcode: "",
            firstName: props?.parsedData?.data?.name?.first,
            lastName: props?.parsedData?.data?.name?.last,
            workPhone: props?.parsedData?.data?.phoneNumbers[0],
            homePhone: props?.parsedData?.data?.phoneNumbers[1],
          };
      // : [];
      setContactInfo({
        ...contactInfo,
        ...contactInfoObject,
      });
      let Skill = props.parsedData.h5h
        ? props.parsedData?.primary_skills?.length
          ? props?.parsedData?.primary_skills?.map((item) => {
              return item;
            })
          : []
        : props?.parsedData?.data?.skills?.length
        ? props?.parsedData?.data?.skills?.map((item) => {
            return item.name;
          })
        : [];

      Skill = Skill.filter((SkillItem) => SkillItem);
      // if addedby crintell we are adding this below
      const profileInfoObject = props?.parsedData?.h5h
        ? {
            primarySkills: props.parsedData?.primary_skills,
            secondarySkills: props.parsedData?.secondary_skills,
            linkedIn: props.parsedData?.linkedin || "",
            facebook: props.parsedData?.websites || "",
            skillSet: [],
            description: "",
            experienceYear: props.parsedData?.experience,
            fileName: props.parsedData?.fileName,
            base64: props.parsedData?.base64,
            currentJobTitle: props?.parsedData?.designation,
          }
        : {
            primarySkills: Skill,
            linkedIn: props.parsedData.data?.linkedin,
            facebook: props.parsedData.data?.websites[0],
            skillSet: [props.parsedData.data?.profession],
            description: props.parsedData.data?.summary,
            experienceYear: props.parsedData.data?.totalYearsExperience?.toString(),
            fileName: props.parsedData?.fileName,
            base64: props.parsedData?.base64,
          };
      //setProfileData(Skill);
      setProfileData({
        ...profileData,
        ...profileInfoObject,
      });

      let experience = [];
      let experienceVal2 = [];
      if (!props.parsedData.h5h) {
        experience =
          props.parsedData.data?.workExperience &&
          props.parsedData.data?.workExperience.map((item) => ({
            employerName: item.organization,
            jobTitle: item.jobTitle,
          }));
        experienceVal2 =
          props.parsedData.data?.workExperience &&
          props.parsedData.data?.workExperience.map((item) => ({
            employerName: "",
            industry: "",
            jobTitle: "",
            startDate: "",
            endDate: "",
          }));
      }

      experienceVal2 && setExperienceVal(experienceVal2);
      experience && setExperiences(experience);

      let education = [];
      let educationVal2 = [];
      let objArray = [];

      if (props.parsedData?.education !== (null || undefined)) {
        Object.keys(props.parsedData?.education[0])?.forEach((key) =>
          objArray.push({
            index: key,
            edu: props.parsedData?.education[0][key],
          })
        );
      }

      if (props.parsedData?.h5h) {
        education =
          props.parsedData?.education?.length > 0
            ? objArray?.map((item) => ({
                graduatedYear: item?.edu?.year,
                educationProgram: item?.edu?.program,
                school: item.edu?.school,
                major: item.edu?.major,
              }))
            : [];
        educationVal2 =
          props.parsedData?.education?.length > 0 &&
          props.objArray?.map((item) => ({
            graduatedYear: "",

            program: "",
            school: "",
            major: "",
          }));
      } else {
        education =
          props.parsedData.data?.education &&
          props.parsedData.data?.education.map((item) => ({
            educationProgram: item.accreditation?.education,
            school: item.organization,
            major: item.accreditation?.inputStr,
          }));
        educationVal2 =
          props.parsedData?.data?.education &&
          props.parsedData?.data?.education.map((item) => ({
            graduatedYear: "",
            educationType: "",
            educationProgram: "",
            school: "",
            major: "",
          }));
      }

      educationVal2 && setEducationVal(educationVal2);
      education && setEducations(education);
    }
  }, [props]);

  useEffect(() => {
    setContactInfo({
      firstName: props.candidateDetails?.firstName
        ? props.candidateDetails?.firstName
        : "",
      middleName: "",
      lastName: props.candidateDetails?.lastName
        ? props.candidateDetails?.lastName
        : "",
      email:
        props.message === "JoinCommunity"
          ? sessionStorage.getItem("email")
          : props.candidateDetails?.email
          ? props.candidateDetails?.email
          : "",
      zipCode: props.candidateDetails?.zipCode
        ? props.candidateDetails?.zipCode
        : "",
      address: props.candidateDetails?.address
        ? props.candidateDetails?.address
        : "",
      city: props.candidateDetails?.city ? props.candidateDetails?.city : "",
      state: props.candidateDetails?.state ? props.candidateDetails?.state : "",
      country: props.candidateDetails?.country
        ? props.candidateDetails?.country
        : "",
      workPhoneCode: "",
      workPhone: props.candidateDetails?.workPhone
        ? parsePhoneNumber(
            props.candidateDetails.workPhone[0] === "+"
              ? props.candidateDetails.workPhone
              : `+${props.candidateDetails.workPhone}`
          ).formatInternational()
        : "",
      homePhoneCode: props.candidateDetails?.homePhoneCode,
      homePhone: props.candidateDetails?.homePhone,
      phoneValidation: true,
      phoneValidation2: true,
    });
    let Skill = props.parsedData.h5h
      ? props.parsedData?.primary_skills?.length
        ? props?.parsedData?.primary_skills?.map((item) => {
            return item;
          })
        : []
      : props?.parsedData?.data?.skills?.length
      ? props?.parsedData?.data?.skills?.map((item) => {
          return item.name;
        })
      : [];
    setProfileData({
      facebook: props.candidateDetails?.facebook
        ? props.candidateDetails?.facebook
        : "",
      twitter: props.candidateDetails?.twitter
        ? props.candidateDetails?.twitter
        : "",
      linkedIn: props.candidateDetails?.linkedIn
        ? props.candidateDetails?.linkedIn
        : "",
      preferredSalary: props.candidateDetails?.preferredSalary
        ? props.candidateDetails?.preferredSalary
        : "",
      preferredSalaryCurrency: props.candidateDetails?.preferredSalaryCurrency
        ? props.candidateDetails?.preferredSalaryCurrency
        : "USD",
      minContractRate: props.candidateDetails?.minContractRate
        ? props.candidateDetails?.minContractRate
        : "",
      minContractRateCurrency: "USD",
      description: "",
      fileName: props?.candidateDetails?.fileName
        ? props.candidateDetails?.fileName
        : "",
      base64: "",
      positionTypes:
        props.candidateDetails?.positionTypes?.length > 0
          ? props.candidateDetails?.positionTypes
          : [],
      preferredLocation:
        props.candidateDetails?.preferredLocation?.length > 0
          ? props.candidateDetails?.preferredLocation
          : sessionStorage.getItem("email") == null
          ? ""
          : "",
      primarySkills: union(props.candidateDetails?.primarySkills || [], Skill),
      secondarySkills:
        props.candidateDetails?.secondarySkills?.length > 0
          ? props.candidateDetails?.secondarySkills
          : [],
      skillSet:
        props.candidateDetails?.skillSet?.length > 0
          ? props.candidateDetails?.skillSet
          : [],
      source: props.candidateDetails?.source
        ? props.candidateDetails?.source
        : "Career Page",
      visaStatus: props.candidateDetails?.visaStatus
        ? props.candidateDetails?.visaStatus
        : "",
      legallyAuthorized: props.candidateDetails?.legallyAuthorized
        ? props.candidateDetails?.legallyAuthorized
        : false,
      requireSponsorship: props.candidateDetails?.requireSponsorship
        ? props.candidateDetails?.requireSponsorship
        : false,
      designation: props.candidateDetails?.designation
        ? props.candidateDetails?.designation
        : "",

      experienceLevel: props.candidateDetails?.experienceLevel
        ? props.candidateDetails?.experienceLevel
        : "",
      experienceYear: props.candidateDetails?.experienceYear
        ? props.candidateDetails?.experienceYear
        : "0",
      experienceMonth: props.candidateDetails?.experienceMonth
        ? props.candidateDetails?.experienceMonth
        : "0",
      currentJobTitle: props.candidateDetails?.designation
        ? props.candidateDetails?.designation
        : "",
      isFresher: props.candidateDetails?.isFresher
        ? props.candidateDetails?.isFresher
        : false,
      isRemote: props.candidateDetails?.isRemote
        ? props.candidateDetails.isRemote
        : false,
      isHybrid: props.candidateDetails?.isHybrid
        ? props.candidateDetails.isHybrid
        : false,
      isOnsite: props.candidateDetails?.isOnsite
        ? props.candidateDetails?.isOnsite
        : false,
      highestEducation: props.candidateDetails?.highestEducation
        ? props.candidateDetails?.highestEducation
        : "Bachelor's Degree",
    });
  }, [props.candidateDetails]);

  const onCollapsibleClick = (type) => {
    if (type === "contact") {
      if (contact === false) {
        setContact(true);
      } else {
        setContact(false);
      }
    }

    if (type === "profile") {
      if (profileInfo === false) {
        setProfileInfo(true);
      } else {
        setProfileInfo(false);
      }
    }

    if (type === "experience") {
      if (experience === false) {
        setExperience(true);
      } else {
        setExperience(false);
      }
    }

    if (type === "education") {
      if (education === false) {
        setEducation(true);
      } else {
        setEducation(false);
      }
    }

    if (type === "certification") {
      if (certification === false) {
        setCertification(true);
      } else {
        setCertification(false);
      }
    }

    if (type === "license") {
      if (license === false) {
        setLicense(true);
      } else {
        setLicense(false);
      }
    }
    if (type === "preferences") {
      if (preferences === false) {
        setPreferences(true);
      } else {
        setPreferences(false);
      }
    }
    if (type === "resume") {
      if (resumeFile === false) {
        setResumeFile(true);
      } else {
        setResumeFile(false);
      }
    }
  };

  const getTenant = async () => {
    const params = { tenantId: tenant };
    setLoading(true);
    const res = await post("/career/gettenant", params).then((res) => {
      setTenantData(res.data[0]);
      setLoading(false);
    });
  };

  const calculateExperience = [
    { name: "Intern", range: [0] },
    { name: "1-3 years", range: [1, 2] },
    { name: "3-5 years", range: [3, 4] },
    { name: "5-10 years", range: [5, 6, 7, 8, 9] },
    { name: "10+ years", range: [] },
  ];

  const findRange = (years, months) => {
    let totalMonths = Number(years) * 12 + Number(months);
    if (totalMonths >= 120) {
      return "10+ years";
    } else if (totalMonths < 12) {
      if (totalMonths === 0) {
        return "Intern";
      } else {
        return "Less than one year";
      }
    } else {
      let yearsExperience = Math.floor(totalMonths / 12);
      let finalValue = calculateExperience.find((i) =>
        i.range.includes(yearsExperience)
      ).name;
      return finalValue;
    }
  };

  const validationFun = () => {
    let isValid = true;
    let resumes = false;
    let isContactValidated = false;
    let isProfileValidated = false;
    let isExperienceValidated = false;
    let isEducationValidated = false;
    let isCertificationValidated = false;
    let isLicenseValidated = false;
    /*****************************ContactInfo ***********************/
    let contactInfoVal = contactInfoValidation(contactInfo);
    if (!checkEmptyObject(contactInfoVal.validation)) {
      setContactVal(contactInfoVal.validation);
      setContact(true);
      setLoading(false);
      isValid = false;
      let profileInfoVal = profileInfoValidation(profileData);
      if (!checkEmptyObject(profileInfoVal.validation)) {
        setProfileVal(profileInfoVal.validation);
        setProfileInfo(true);
        setLoading(false);
        isValid = false;
      }
      toast.error(contactInfoVal.validationObj[0]);
    } else if (contactInfo.homePhone == "") {
      isValid = false;
      toast.error("Please Enter Phone Number");
    } else if (!contactInfo.phoneValidation) {
      isValid = false;
      toast.error("Please Enter Valid Primary Phone");
    }
    // else if (
    //   profileData.preferredSalary == "" &&
    //   profileData.minContractRate == ""
    // ) {
    //   isValid = false;
    //   toast.error("Please Enter Expected Salary");
    // }
    else {
      setContactVal(contactInfoVal.validation);
      isContactValidated = true;
      setProfileInfo(true);
      setContact(true);
    }

    /*****************************ProfileInfo ***********************/

    if (isContactValidated) {
      let profileInfoVal = {};
      if (props.isFrom == "JoinTalentCommunity") {
        profileInfoVal = profileInfoValidation(
          profileData,
          props.job,
          "JoinTalentCommunity"
        );
      } else {
        profileInfoVal = profileInfoValidation(
          profileData,
          props.job,
          "ApplyJob"
        );
      }
      if (!checkEmptyObject(profileInfoVal.validation)) {
        setProfileVal(profileInfoVal.validation);
        setProfileInfo(true);
        setLoading(false);
        isValid = false;
        toast.error(profileInfoVal.validationObj[0]);
      } else if (
        profileData.preferredSalary == "" &&
        profileData.minContractRate == ""
      ) {
        isValid = false;
        toast.error("Please Enter Expected Salary....");
      } else if (
        props.isFrom &&
        props.isFrom !== "JoinTalentCommunity" &&
        ((props.job.workPlaceType == "Remote" && !profileData.isRemote) ||
          (props.job.workPlaceType == "Hybrid" && !profileData.isHybrid) ||
          (props.job.workPlaceType == "On-Site" && !profileData.isOnsite))
      ) {
        isValid = false;
        toast.error("Please Select Required Location");
      } else if (
        (sessionStorage.getItem("token") !== null ||
          props?.isFrom == "JoinTalentCommunity") &&
        profileData.fileName === ""
      ) {
        setResume(false);
        toast.error("Please Upload Your Resume In Profile Info");
      } else if (profileData.linkedIn && !isValidUrl(profileData.linkedIn)) {
        toast.error("Invalid LinkedIn URL");
      } else {
        isProfileValidated = true;
        setProfileVal(profileInfoVal.validation);
        setContact(true);
        setExperience(true);
        setResume(true);
        resumes = true;
      }
    }

    if (isContactValidated && isProfileValidated) {
      /*****************************Experience ***********************/

      let experienceVal = experienceValidation(experiences);

      if (!checkEmptyObject(experienceVal.validation[0])) {
        setExperienceVal(experienceVal.validation);
        setExperience(true);
        isValid = false;
        setLoading(false);
        toast.error(experienceVal.validationObj[0]);
      } else {
        isExperienceValidated = true;
        setExperienceVal(experienceVal.validation);
      }
      /*****************************Education***********************/

      let educationVal = educationValidation(educations);

      if (!checkEmptyObject(educationVal.validation[0])) {
        setEducationVal(educationVal.validation);
        setEducation(true);
        setLoading(false);
        isValid = false;
        toast.error(educationVal.validationObj[0]);
      } else {
        isEducationValidated = true;
        setEducationVal(educationVal.validation);
      }

      /*****************************Certificate***********************/

      let certificationVal = certificationValidation(certifications);

      if (!checkEmptyObject(certificationVal.validation[0])) {
        setCertificationVal(certificationVal.validation);
        setCertification(true);
        setLoading(false);
        isValid = false;
        toast.error(certificationVal.validationObj[0]);
      } else {
        isCertificationValidated = true;
        setCertificationVal(certificationVal.validation);
      }

      /*****************************License***********************/

      let licenseVal = licenseValidation(licenses);

      if (!checkEmptyObject(licenseVal.validation[0])) {
        setLicenseVal(licenseVal.validation);
        setLicense(true);
        toast.error(licenseVal.validationObj[0]);
        setLoading(false);
      } else {
        isLicenseValidated = true;
        setLicenseVal(licenseVal.validation);
      }
    }

    let obj = {
      isContactValidated,
      isProfileValidated,
      isExperienceValidated,
      isEducationValidated,
      isCertificationValidated,
      isLicenseValidated,
      isValid,
      resumes,
    };
    return obj;
  };

  // Submit Add talent
  const submitAddTalent = async () => {
    setIsFormValid(true);
    let validObj = validationFun();
    const {
      isContactValidated,
      isProfileValidated,
      isExperienceValidated,
      isEducationValidated,
      isCertificationValidated,
      isLicenseValidated,
      isValid,
      resumes,
    } = validObj;
    if (
      isContactValidated &&
      isProfileValidated &&
      isExperienceValidated &&
      isEducationValidated &&
      isCertificationValidated &&
      isLicenseValidated &&
      resumes
    ) {
      setContact(false);
      setProfileInfo(false);
      setExperience(false);
      setEducation(false);
      setCertification(false);
      setLicense(false);
      let talentData = {
        ...contactInfo,
        ...profileData,
        ...educations,
      };

      let experience =
        experiences.length > 1
          ? experiences.filter((item) => checkEmptyObject(item) === false)
          : experiences;
      let education =
        educations.length > 1
          ? educations.filter((item) => checkEmptyObject(item) === false)
          : educations;
      let certification =
        certifications.length > 1
          ? certifications.filter((item) => checkEmptyObject(item) === false)
          : certifications;
      let license =
        licenses.length > 1
          ? licenses.filter((item) => checkEmptyObject(item) === false)
          : licenses;
      talentData.experiences = experience;
      talentData.educations = education;
      talentData.certifications = certification;
      talentData.licenses = license;
      talentData.domainName = domain;
      talentData.experienceLevel = findRange(
        profileData.experienceYear,
        profileData.experienceMonth
      );
      talentData.currentEmployer = props.candidateDetails.currentEmployer;
      talentData.filePath = props?.candidateDetails?.filePath;
      props.setIsScoreCard(true);
      props.setCreateProfile(false);
      props.setLoggedIn(false);

      props.setCandidateDetails(talentData);

      post("/auditLog/candidateData", {
        ...props.trackedData,
        navigatedPages: props.trackedData.navigatedPages + "/autoMatchCard",
        jobId: props.job.jobId,
        data: JSON.stringify({
          ...props.candidateDetails,
        }),
      });

      props.setTrackedData({
        ...props.trackedData,
        navigatedPages: props.trackedData.navigatedPages + "/autoMatchCard",
        jobId: props.job.jobId,
        data: JSON.stringify({
          ...props.candidateDetails,
        }),
      });
    }
  };

  const joinCommunity = async () => {
    setIsFormValid(true);
    let validObj = validationFun();
    const {
      isContactValidated,
      isProfileValidated,
      isExperienceValidated,
      isEducationValidated,
      isCertificationValidated,
      isLicenseValidated,
      isValid,
    } = validObj;
    if (
      isContactValidated &&
      isProfileValidated &&
      isExperienceValidated &&
      isEducationValidated &&
      isCertificationValidated &&
      isLicenseValidated &&
      resume
    ) {
      const talentData = { ...props?.candidateDetails };
      let paramObj = {
        firstName: contactInfo.firstName,
        lastName: contactInfo.lastName,
        email: contactInfo.email,
        address: contactInfo.address,
        city: contactInfo.city,
        state: contactInfo.state,
        country: contactInfo.country,
        zipcode: contactInfo.zipcode ?? "",
        homePhoneCode: contactInfo.homePhoneCode,
        homePhone: contactInfo.homePhone,
        workPhone: contactInfo.workPhone,
        workPhoneCode: contactInfo.workPhoneCode,
        facebook: profileData.facebook,
        twitter: profileData.twitter,
        linkedIn: profileData.linkedIn,
        preferredSalary: profileData.preferredSalary,
        preferredSalaryCurrency: profileData.preferredSalaryCurrency,
        minContractRate: profileData.minContractRate,
        minContractRateCurrency: profileData.minContractRateCurrency,
        description: profileData.description,
        positionTypes: JSON.stringify(talentData.positionTypes),
        base64: profileData.base64,
        preferredLocation: JSON.stringify(
          contactInfo.isRemote
            ? [...contactInfo.preferredLocation, "Remote"]
            : contactInfo.preferredLocation
        ),
        primarySkills: JSON.stringify(profileData?.primarySkills),
        secondarySkills: JSON.stringify(profileData?.secondarySkills),
        skillSet: JSON.stringify(talentData.skillSet),
        source: talentData.source,
        visaStatus: talentData.visaStatus,
        designation: profileData.designation,
        currentEmployer: profileData.currentEmployer,
        experienceMonth: profileData.experienceMonth?.toString(),
        experienceYear: profileData.experienceYear?.toString(),
        isFresher: profileData.isFresher,
        isRemote: profileData.isRemote,
        fileName: profileData.fileName,
        experiences: JSON.stringify(experiences),
        educations: JSON.stringify(educations),
        experienceLevel: profileData.experienceLevel,
        recruiterId: recruiterid,
        tenantName: tenantData.tenantname,
        source: "Join Community",
        highestEducation: profileData.highestEducation,
      };

      if (sessionStorage.getItem("candidateStatus") === "Existing Talent") {
        paramObj.oldFileName = props.data?.fileName;
        paramObj.candidateId = sessionStorage.getItem("id_user");
        try {
          setLoading(true);
          const res = await post("/applyjob/updatetalent", paramObj);
          if (res.status === 200) {
            toast.success("Talent Community joined Successfully");
            candidateLogin(res);
            // dispatch(getTalentList());
          }
        } catch (error) {
          toast.error(error?.response?.data?.errMessage);
        }
      } else {
        try {
          setLoading(true);
          const res = await post("/applyjob/addtalent", paramObj);
          if (res.status === 200) {
            toast.success("Talent Community joined Successfully");
            candidateLogin(res);
            const automatchedTalent = {
              primarySkills: profileData.primarySkills,
              educations: [profileData.highestEducation],
              city: contactInfo.city,
              preferredLocation: contactInfo.isRemote
                ? [...contactInfo.preferredLocation, "Remote"]
                : contactInfo.preferredLocation,
              designation: profileData.designation,
              experienceLevel: profileData.experienceLevel,
              userId: res?.data?.candidateId,
            };
            let automatch = post(
              "/automatch/automatchedTalentForJobs",
              automatchedTalent
            );
          }
        } catch (error) {
          toast.error(error?.response?.data?.errMessage);
        }
      }
    }
  };

  const candidateLogin = async (resp) => {
    // setLoading(false);
    if (sessionStorage.getItem("token") == null) {
      const response = await post("/candidateLogin/", {
        password: resp.data.password,
        userName: contactInfo.email,
      }).then((res) => {
        sessionStorage.setItem("token", res.data.token);
        sessionStorage.setItem("name", res.data.name);
        sessionStorage.setItem("userId", res.data.userId);
        sessionStorage.setItem("userName", res.data.userName);

        setTimeout(() => {
          setLoading(false);
          history.push(`/careers/profile`);
        }, 5000);
        // window.location.reload();
      });
    } else {
      history.push(`/careers/profile`);
    }
  };

  return (
    <>
      {loading || load ? (
        <Loader />
      ) : (
        <div style={{ marginTop: "-14px" }}>
          <div class="bl-modal-body" style={{ padding: "0px 0px 0px 0px" }}>
            <div class="mb-2 mt-3">
              <div class="card mb-2">
                <div class="card-body">
                  {props.fileName && (
                    <div
                      v-if="item.filename"
                      class="d-flex align-items-center justify-content-between hoverable"
                    >
                      <div class="d-flex align-items-center">
                        <div class="avatar avatar-coral avatar-sm">
                          <i class="fad fa-user"></i>
                        </div>
                        <p class="mb-0 ml-2">File Name</p>
                      </div>
                      <div>
                        <i
                          v-if="item.isExpanded"
                          class="fal fa-fw fa-chevron-square-down"
                        ></i>
                        <i class="fal fa-fw fa-chevron-square-up"></i>
                      </div>
                    </div>
                  )}
                  <div v-if="item.isExpanded">
                    <div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("contact");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {contact === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Contact Info</small>
                        </span>
                      </button>
                      <div
                        className={
                          contact === true
                            ? "ml-2 ml-0-600 mt-2 mb-1"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat bg-gray4 ml-4 ml-0-600">
                          <Contact
                            updatedParsed={props.updatedParsed}
                            contactInfo={contactInfo}
                            setContactInfo={setContactInfo}
                            contactInfoValidation={contactVal}
                            profileInfo={profileData}
                            setProfileInfo={setProfileData}
                            candidateDetails={props.candidateDetails}
                            message={props.message}
                            isFormValid={isFormValid}
                          />
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("profile");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {profileInfo === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Profile Info</small>
                        </span>
                      </button>
                      <div
                        className={
                          profileInfo === true
                            ? "ml-2 ml-0-600 mt-2 mb-4"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat bg-gray4 ml-4 ml-0-600">
                          <Profile
                            profileInfo={profileData}
                            setProfileInfo={setProfileData}
                            profileInfoValidation={profileVal}
                            changePreferredLocation={(location) => {
                              setProfileData({
                                ...profileData,
                                preferredLocation: location,
                              });
                            }}
                            isFormValid={isFormValid}
                            dropDownData={dropDownData}
                            isFrom="JoinCommunity"
                            job={props.job}
                          />
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("education");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {education === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Education</small>
                        </span>
                      </button>
                      <div
                        className={
                          education === true
                            ? "ml-2 ml-0-600 mt-2 mb-4"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat card-lg ml-4 ml-0-600">
                          <div class="card-body">
                            <Education
                              educations={educations}
                              setEducations={setEducations}
                              educationValidation={educationVal}
                              setEducationValidation={setEducationVal}
                              dropDownData={dropDownData}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("experience");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {experience === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Work Experience</small>
                        </span>
                      </button>
                      <div
                        className={
                          experience === true
                            ? "ml-2 ml-0-600 mt-2 mb-4"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat card-lg ml-4 ml-0-600">
                          <div class="card-body">
                            <Experience
                              experiences={experiences}
                              setExperiences={setExperiences}
                              profileInfo={profileData}
                              setProfileInfo={setProfileData}
                              experienceValidation={experienceVal}
                              setExperienceValidation={setExperienceVal}
                              dropDownData={dropDownData}
                            />
                          </div>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          onCollapsibleClick("preferences");
                        }}
                        type="button"
                        class="btn btn-collapsible mb-2"
                      >
                        {preferences === true ? (
                          <i class="fal fa-angle-up fa-fw mt-1"></i>
                        ) : (
                          <i class="fal fa-angle-down fa-fw"></i>
                        )}
                        <span>
                          <small>Preferences</small>
                        </span>
                      </button>
                      <div
                        className={
                          preferences === true
                            ? "ml-2 ml-0-600 mt-2 mb-1"
                            : "d-none"
                        }
                      >
                        <div class="card card-flat card-lg ml-4 ml-0-600">
                          {/* <div class="card-body"> */}
                          <Preferences
                            parent="DirectSourcing"
                            profileInfo={profileData}
                            setProfileInfo={setProfileData}
                            profileInfoValidation={profileVal}
                            changePreferredLocation={(location) => {
                              setProfileData({
                                ...profileData,
                                preferredLocation: location,
                              });
                            }}
                            job={props.job}
                            message={props.message}
                            isFormValid={isFormValid}
                            isFrom={props.isFrom}
                          />
                          {/* </div> */}
                        </div>
                      </div>

                      {/* {/* Resume  */}
                      {(sessionStorage.getItem("token") !== null ||
                        props?.isFrom == "JoinTalentCommunity") && (
                        <>
                          <button
                            onClick={() => {
                              onCollapsibleClick("resume");
                            }}
                            type="button"
                            class="btn btn-collapsible mb-2"
                          >
                            {resumeFile === true ? (
                              <i class="fal fa-angle-up fa-fw mt-1"></i>
                            ) : (
                              <i class="fal fa-angle-down fa-fw"></i>
                            )}
                            <span>
                              <small>Resume</small>
                            </span>
                          </button>
                          <div
                            className={
                              resumeFile === true
                                ? "ml-2 ml-0-600 mt-2 mb-1"
                                : "d-none"
                            }
                          >
                            <div class="card card-flat card-lg ml-4 ml-0-600">
                              <Resume
                                profileInfo={profileData}
                                setProfileInfo={setProfileData}
                                setResume={setResume}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mx-auto my-3 ">
              <div className="col-md-12 d-flex justify-content-end">
                <button
                  className="btn btn-sm btn-secondary mr-2"
                  onClick={
                    props.message !== "JoinCommunity"
                      ? () => {
                          props.setApply(false);
                          setIsFormValid(false);
                        }
                      : () => {
                          window.history.back();
                          sessionStorage.clear();
                        }
                  }
                >
                  Cancel
                </button>
                <button
                  className="btn btn-sm btn-primary "
                  onClick={() => {
                    props.message === "JoinCommunity"
                      ? joinCommunity()
                      : submitAddTalent();
                  }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Index;
