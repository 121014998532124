import {
  FETCH_ALL_SENT_MAILS_BEGIN,
  FETCH_ALL_SENT_MAILS_SUCCESS,
  FETCH_ALL_SENT_MAILS_FAILURE,
} from "../../actions/broadcast/broadcast";

const initialState = {
  broadCastLoading: true,
  broadCastData: null,
};

const broadCastReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ALL_SENT_MAILS_BEGIN:
      return {
        ...state,
        broadCastLoading: true,
        broadCastData: null,
      };
    case FETCH_ALL_SENT_MAILS_SUCCESS:
      return {
        ...state,
        broadCastData: payload,
        broadCastLoading: false,
      };
    case FETCH_ALL_SENT_MAILS_FAILURE:
      return {
        ...state,
        broadCastData: payload,
        broadCastLoading: false,
      };
    default:
      return state;
  }
};

export default broadCastReducer;
