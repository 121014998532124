import {
    GET_NURSING_SPECIALTY_FILTER_BEGIN,
    GET_NURSING_SPECIALTY_FILTER_SUCCESS,
    GET_NURSING_SPECIALTY_FILTER_FAILURE,
  } from "../../actions/talent/NursingSpecialty";
  
  const initialState = {
    loading: false,
    nursingSpecialtyData: null,
  };
  
  const getTalentNursingSpecialtyReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case GET_NURSING_SPECIALTY_FILTER_BEGIN:
        return {
          ...state,
          loading: true,
          nursingSpecialtyData: null,
        };
      case GET_NURSING_SPECIALTY_FILTER_SUCCESS:
        return {
          ...state,
          nursingSpecialtyData: payload,
          loading: false,
        };
      case GET_NURSING_SPECIALTY_FILTER_FAILURE:
        return {
          ...state,
          nursingSpecialtyData: payload,
          loading: false,
        };
      default:
        return state;
    }
  };
  
  export default getTalentNursingSpecialtyReducer;
  