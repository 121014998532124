import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/esm/Modal";
import SubmittalDetailsInfo from "./SubmittalDetailsInfo";

const AutomatchedParameters = (props) => {
  const { jobData, setAutomatch, automatch } = props;
  const [addJobData, setAddJobData] = useState(jobData)
  const [weightage, setWeightage] = useState ({})

  useEffect(()=>{
    if(jobData)
    {setWeightage(JSON.parse(jobData?.weightage))}
  },[])
  let jobTempData = {
    ...jobData,
    primarySkills: jobData.primarySkills && JSON.parse(jobData.primarySkills),
    education: jobData.education && JSON.parse(jobData.education),
    location: {
      city: jobData?.city,
      state: jobData?.state,
      country: jobData?.country,
    },
  };

  return (
    <Modal
      size="lg"
      show={automatch}
      onHide={() => setAutomatch(false)}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <h6 className="pt-o mt-3">Automatched Parameters</h6>
      </Modal.Header>
      <Modal.Body>
        {/* <SubmittalDetailsInfo
          addJobData={jobTempData}
          weightage={JSON.parse(jobData?.weightage)}
          setWeightage={(data) => null}
          component={"jobDetails"}
          isFrom="TalentPool"
          edit= {true}
        /> */}
        <>
          <div className="card card-flat bg-gray4 m-3">
            <div className="card-body py-0">
              {(
                <div className="mx-1 mt-2 mb-3">
                  <h5 style={{ color: "#f45e57" }}>
                    Automatched Parameter
                  </h5>
                  
                </div>
              )}

              <div className="mb-3 ml-4">
                <div className="d-flex justify-content-start align-items-center">
                  <input
                    style={{ width: "2%" }}
                    className="mr-3"
                    checked={true}
                    type="checkbox"
                    disabled
                  />
                  <i
                    class="fas fa-user-cog"
                    style={{ color: "#f45e57", width: "20px" }}
                  ></i>
                  <span className="bold mr-1">Skills:</span>

                  <span className="">
                    {addJobData.skills}
                  </span>
                  <i
                    className="fa fa-info-circle mr-2 ml-2"
                    style={{ color: "grey" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Skills"
                  ></i>
                </div>
                {/* <small className="mb-2">
              - Candidate must possess the given skill(s).
            </small> */}

                <div className="d-flex justify-content-start align-items-center mt-2">
                  <input
                    style={{ width: "2%" }}
                    className="mr-3"
                    type="checkbox"
                    checked={weightage?.location}
                    disabled
                    // onChange={(e) =>
                    //   setWeightage({ ...weightage, location: e.target.checked })
                    // }
                  />
                  <i
                    class="fad fa-fw fa-map-marked-alt"
                    style={{ color: "#f45e57", width: "20px" }}
                  ></i>
                  <span className="bold mr-1">Location:</span>
                  {/* <span className="tag tag-orange1">{addJobData?.city}</span> */}
                  <span className="">
                    {addJobData?.isCountry
                      ? addJobData?.country
                      : addJobData?.city +', '+ addJobData?.state +', '+ addJobData?.country}
                  </span>
                  <i
                    className="fa fa-info-circle mr-2 ml-2"
                    style={{ color: "grey" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Location"
                  ></i>
                </div>
                {/* <small className="mb-2">- Preferred locations for candidate.</small> */}
                <div className="d-flex justify-content-start align-items-center mt-2">
                  <input
                    style={{ width: "2%" }}
                    className="mr-3"
                    type="checkbox"
                    checked={weightage?.education}
                    disabled
                    // onChange={(e) =>
                    //   setWeightage({
                    //     ...weightage,
                    //     education: e.target.checked,
                    //   })
                    // }
                  />
                  <i
                    class="fad fa-file-user"
                    style={{ color: "#f45e57", width: "20px" }}
                  ></i>
                  <span className="bold mr-1">Education:</span>
                  {/* <span className="tag tag-lightPink">{addJobData?.education}</span> */}
                  <span className="">{addJobData?.highestEducation}</span>
                  <i
                    className="fa fa-info-circle mr-2 ml-2"
                    style={{ color: "grey" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Education"
                  ></i>
                </div>
                {/* <small className="mb-2">
              - Minimum required education level for candidate.
            </small> */}
                <div className="d-flex justify-content-start align-items-center mt-2">
                  <input
                    style={{ width: "2%" }}
                    className="mr-3"
                    checked={weightage?.experienceLevel}
                    type="checkbox"
                    disabled
                    // onChange={(e) =>
                    //   setWeightage({
                    //     ...weightage,
                    //     experienceLevel: e.target.checked,
                    //   })
                    // }
                  />
                  <i
                    class="fad fa-user-visor"
                    style={{ color: "#f45e57", width: "20px" }}
                  ></i>
                  <span className="bold mr-1">Experience:</span>
                  {/* <span className="tag tag-greenf2">
                {addJobData?.experienceLevel}
              </span> */}
                  <span className="">{addJobData?.experienceLevel}</span>
                  <i
                    className="fa fa-info-circle mr-2 ml-2"
                    style={{ color: "grey" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Experience Level"
                  ></i>
                </div>
                {/* <small className="mb-2">
              - Minimum required years of experience for candidate.
            </small> */}
                <div className="d-flex justify-content-start align-items-center mt-2">
                  <input
                    style={{ width: "2%" }}
                    className="mr-3"
                    type="checkbox"
                    checked={weightage?.jobTitle}
                    disabled
                    // onChange={(e) => handleJobList(e)}
                  />
                  <i
                    class="fas fa-clipboard-list-check"
                    style={{ color: "#f45e57", width: "20px" }}
                  ></i>
                  <span className="bold mr-1">Job Title:</span>
                  {/* <span className="tag tag-lightYellow">
                {addJobData?.jobTitle}
              </span> */}
                  <span className="">{addJobData?.jobTitle}</span>{" "}
                  <i
                    className="fa fa-info-circle mr-2 ml-2"
                    style={{ color: "grey" }}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Job Title"
                  ></i>
                </div>
                {/* <small className="mb-2">
              - Select up to three job titles that candidates must match at
              least one.
            </small> */}
                
              </div>
            </div>
          </div>
        </>
      </Modal.Body>
      <Modal.Footer>
        <button variant="secondary" onClick={() => setAutomatch(false)}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default AutomatchedParameters;
