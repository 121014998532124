import { toast } from "react-toastify";
import { get } from "../../service/ApiService";

export const GET_USER_LIST_BEGIN = "GET_USER_LIST_BEGIN";
export const GET_USER_LIST_SUCCESS = "GET_USER_LIST_SUCCESS";
export const GET_USER_LIST_FAILURE = "GET_USER_LIST_FAILURE";


export const getUserList = (params) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_LIST_BEGIN,
    });
    const res = await get("/dashboard/csm/gethigh5users",params);
    if (res.status === 200) {
      dispatch({
        type: GET_USER_LIST_SUCCESS,
        payload: res?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_USER_LIST_FAILURE,
      payload: error?.response?.data,
    });
    toast.error(error?.response?.data?.errMessage);
  }
};